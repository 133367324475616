import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { PageHeaderRow, PreLoader, useFeathers } from '@inkcloud/components';
import { Segment, Button } from 'semantic-ui-react';

import { PromoCodeCampaignForm } from './PromoCodeCampaignForm';

import { connect } from 'react-redux';
import { Feathers } from './../../../../bootstrap/feathers/feathers';

interface DetailProps {}

export interface ConnectedProps {
  user?: any;
}

const { useState } = React;

const serviceName = '/promo-codes-campaigns';
const service = Feathers.service(serviceName);

const pathPrefix = '/settings/promo-codes/campaigns';

export const PreDetail: React.FunctionComponent<
  DetailProps & ConnectedProps & RouteComponentProps<{ id?: string }>
> = (props) => {
  const {
    match: {
      params: { id },
    },
    history,
    user,
  } = props;

  const commonParams = {
    query: {},
  };

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [state, reload] = useFeathers('get', serviceName, [id, commonParams], true);

  const isEdit = !!id;

  if (isEdit && !state.data) {
    return <PreLoader />;
  }

  const initialData = { ...state.data };

  const handleSubmit = async (values: any) => {
    const payload = {
      ...values,
    };

    setIsLoading(true);
    try {
      const res: any = isEdit
        ? await service.patch(id, values, commonParams)
        : await service.create(values, { query: {} });

      if (res.result !== undefined && res.result === false) {
        setErrorMessage(res.message || res.errorMessage);
      }
      history.push(pathPrefix);
    } catch (e) {
      e.code && e.code < 500
        ? setErrorMessage(e.message)
        : setErrorMessage('We are experiencing technical difficulties. Please try again');
    }
    setIsLoading(false);
  };

  return (
    <>
      <PageHeaderRow header={`${isEdit ? 'Edit' : 'New'} Promo Code Campaign`} subheader="Settings">
        <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
      </PageHeaderRow>
      <Segment>
        {(!isEdit || state.data) && (
          <PromoCodeCampaignForm
            initialValues={initialData}
            errorMessage={errorMessage}
            isSubmitting={isLoading}
            microsite={user?.mid || user?.microsite}
            onSubmit={handleSubmit}
          />
        )}
      </Segment>
    </>
  );
};

const mapStateToProps = (state): ConnectedProps => ({
  user: state.auth.user || {},
});

export const Detail = connect(mapStateToProps)(PreDetail as any);
