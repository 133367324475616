import * as React from 'react';
import { Radio } from 'semantic-ui-react';
import * as numeral from 'numeral';
interface ShippingRatesProps {
  rates: any;
  shippingService: string;
  onChangeRate: (v: string) => void;
}

export const ShippingRates: React.FunctionComponent<ShippingRatesProps> = (props: any) => {
  const { rates, onChangeRate, shippingService } = props;

  return (
    <>
      {rates?.map((rate: any, index: number) => (
        <div key={index} style={{ lineHeight: 2 }}>
          <Radio
            label={`${rate.description} - ${numeral(rate.amount).format('$0,0.00')}`}
            name="rates"
            value={rate.serviceKey}
            checked={rate.serviceKey === shippingService}
            onChange={(e, data) => onChangeRate(data.value)}
          />
        </div>
      )) ?? []}
    </>
  );
};
