import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
// import { Segment, Table } from 'semantic-ui-react';
import { Text, Box, Table, Tbody, Tr, Th, Td, Flex, Spinner, Button } from '@chakra-ui/react';
import { useList, PageHeaderRow, EmptyWrapper } from '@inkcloud/components';
// import moment from 'moment';
import format from 'date-fns/format';
import addYears from 'date-fns/addYears';
import { FormattedNumber } from 'react-intl';

import { useSelector } from 'react-redux';
import { CSVLink } from 'react-csv';

import * as numeral from 'numeral';
import FilterForm from '../FilterForm2';
import { Feathers } from '../../../bootstrap/feathers/feathers';

// interface ListProps {}

// const filterQueryMap = (values) => {
//   const filterMap: any = {
//     startDate: values.start ? moment(values.start).format('YYYY-MM-DD') : undefined,
//     endDate: values.end ? moment(values.end).format('YYYY-MM-DD') : undefined,
//   };

//   return [].reduce(
//     (m, field) => ({ ...m, [field]: values[field] ? values[field] : undefined }),
//     filterMap
//   );
// };

// const serviceName = 'reports';

const headers = [
  { label: 'Company Name', key: 'companyName' },
  { label: 'Sales Person', key: 'salesPerson' },
  { label: 'Revenue', key: 'revenue' },
];

// export const List: React.FunctionComponent<ListProps & RouteComponentProps<any>> = (props) => {
//   const {
//     match: { params },
//     location,
//     history,
//   } = props;
//   const {
//     state,
//     reload,
//     handleDelete,
//     selectedItems,
//     clearAll,
//     setChecked,
//     someSelected,
//     toggleAll,
//     filter,
//     setFilter,
//     sort,
//     handleSort,
//   } = useList({
//     feathers: ['find', serviceName, [{ query: { action: 'salesTeam' } }]],
//     filterMap: filterQueryMap,
//     debounce: 150,
//     location,
//     history,
//     sort: {},
//   });

//   const { queryResult, isLoading: isStateLoading } = state as any;

//   const mappedData =
//     queryResult?.data?.map((d) => ({
//       companyName: d.companyName,
//       salesPerson: d.salesPerson,
//       revenue: d.revenue,
//     })) || [];
//   const start = filter && filter.start ? moment(filter.start).format('YYYY-MM-DD') : '';
//   const end = filter && filter.end ? moment(filter.end).format('YYYY-MM-DD') : '';

//   return (
//     <>
//       <PageHeaderRow header="Sales Team" subheader="Report">
//         {mappedData?.length > 0 && (
//           <CSVLink
//             className="ui button mini"
//             data={mappedData}
//             filename={`${start}-${end}-sales-team-export.csv`}
//             headers={headers}
//             target="_blank"
//           >
//             Export Data
//           </CSVLink>
//         )}
//       </PageHeaderRow>

//       <FilterForm value={filter} onChange={(v) => setFilter(v)} onReset={() => setFilter({})} />
//       <EmptyWrapper queryResult={queryResult} isStateLoading={isStateLoading}>
//         <Segment loading={state.isLoading}>
//           <p style={{ textAlign: 'right' }}>* Totals do not include shipping or sales tax</p>
//           <Table size={'small'} sortable>
//             {/* <Table.Header>
//               <Table.Row>
//                 <Table.HeaderCell >
//                   Customer
//               </Table.HeaderCell>
//                 <Table.HeaderCell>
//                   Sales Person
//               </Table.HeaderCell>
//                 <Table.HeaderCell>
//                   Amount
//               </Table.HeaderCell>
//                 <Table.HeaderCell></Table.HeaderCell>
//               </Table.Row>
//             </Table.Header> */}
//             <Table.Body>
//               {queryResult &&
//                 (queryResult as any).data.map((r: any, index: number) => (
//                   <Table.Row key={index}>
//                     <Table.Cell>{r.companyName}</Table.Cell>
//                     <Table.Cell>{r.salesPerson}</Table.Cell>
//                     <Table.Cell textAlign="right">
//                       {numeral(r.revenue).format('$0,0.00')}
//                     </Table.Cell>
//                   </Table.Row>
//                 ))}
//             </Table.Body>
//           </Table>
//         </Segment>
//       </EmptyWrapper>
//     </>
//   );
// };

const initValue = { start: null, end: null };

export default function SalesTeam() {
  const history = useHistory();

  const tenant = useSelector(
    ({
      globals,
    }: {
      globals: { tenant: { internationalization: { locale: string; currency: string } } };
    }) => globals.tenant.internationalization
  );

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [filter, setFilter] = useState<{
    start: null | Date;
    end: null | Date;
  }>(initValue);

  const [data, setData] = useState<
    { companyName?: string; salesPerson?: string; revenue: string }[]
  >([]);
  const [errorMessage, setErrorMessage] = useState('');

  const start = filter.start ? format(filter.start, 'yyyy-MM-dd') : undefined;
  const end = filter.end ? format(filter.end, 'yyyy-MM-dd') : undefined;

  const loadData = async () => {
    setErrorMessage('');
    setIsSubmitting(true);
    setData([]);

    try {
      const res = await Feathers.service('/reports').find({
        query: {
          action: 'salesTeam',
          startDate: start,
          endDate: end,
        },
      });
      setData(res?.data);
    } catch (e) {
      const errMsg =
        e?.code < 500 ? e?.message : 'We are experiencing technical difficulties. Please try again';
      setErrorMessage(errMsg);
      setData([]);
    }

    setIsSubmitting(false);
  };

  const maxDate = addYears(filter?.start as Date, 1);
  const exceedDate = filter?.start && filter?.end ? filter.end > maxDate : false;

  useEffect(() => {
    if (filter?.start && filter?.end && !exceedDate) {
      loadData();
    }
  }, [filter]);

  const mappedData =
    data?.map((d) => ({
      companyName: d.companyName,
      salesPerson: d.salesPerson,
      revenue: d.revenue,
    })) || [];

  const handleReset = () => {
    setData([]);
    setFilter(initValue);
  };

  return (
    <>
      <PageHeaderRow header="Sales Team" subheader="Report">
        <Button onClick={() => history.goBack()} marginRight={start && end ? 2 : undefined}>
          Back
        </Button>
        {start && end && (
          <CSVLink
            className="ui button mini"
            data={mappedData}
            filename={`${start}-${end}-sales-team-export.csv`}
            headers={headers}
            target="_blank"
          >
            Export Data
          </CSVLink>
        )}
      </PageHeaderRow>
      <Box p={3} mb={3} borderWidth={1} borderRadius="md" bg="white">
        <FilterForm
          values={filter}
          isSubmitting={isSubmitting}
          errorMessage={errorMessage}
          onChange={(v) => {
            setErrorMessage('');
            setFilter(v);
          }}
          onReset={handleReset}
        />
      </Box>

      <EmptyWrapper
        queryResult={data && filter?.start && filter?.end}
        isStateLoading={isSubmitting}
      >
        <Box p={3} borderWidth={1} borderRadius="md" bg="white">
          <Text textAlign="right">* Totals do not include shipping or sales ta</Text>
          <Table>
            <Tbody>
              {data?.map((d, index: number) => (
                <Tr key={index}>
                  <Td>{d?.companyName}</Td>
                  <Td>{d?.salesPerson}</Td>
                  <Td isNumeric>
                    <FormattedNumber
                      currency={tenant?.currency}
                      value={Number(d?.revenue ?? 0)}
                      style="currency"
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </EmptyWrapper>
    </>
  );
}
