/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import {
  Accordion,
  // Button,
  Checkbox,
  Header,
  Grid,
  Label,
  Message,
  Segment,
  Dimmer,
  Loader,
  Table,
} from 'semantic-ui-react';
import { Alert, Box, Flex, Spinner, Button } from '@chakra-ui/react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import * as Scroll from 'react-scroll';
import { PageHeader } from '@inkcloud/components';
import type { ModelTypes } from '@inkcloud/icapi-types';
import { InvoiceList } from './InvoiceList';
import Payment from '../invoice-adjuster/PaymentComponent';
import {
  PaymentData,
  PaymentMethod,
  PaymentCreditCardTypes,
  NewPaymentData,
  PaymentCreditCard,
} from '../../types';
import { makeMultiplePayment } from './remote';
import { services } from '../../bootstrap/feathers';

import { DetailWrapper, DetailFeaturesProps } from '../../bootstrap/DetailWrapper';
import { Feathers } from '../../bootstrap/feathers/feathers';
import { paymentProcess } from './helper';

export interface ContainerProps {
  loadInvoices: Function;
}

export interface ContainerState {
  paymentData: PaymentData;
  invoices: any[];
  paymentAmount: Number;
  isFinalizing: boolean;
  isSubmitting: boolean;
  errorMessage?: string;
  successMessage?: string;
  isComplete: boolean;
}

// class Container extends React.Component<ContainerProps & DetailFeaturesProps, ContainerState> {
//   _isMounted = false

//   constructor(props: ContainerProps & DetailFeaturesProps) {
//     super(props)
//     this.state = {
//       paymentData: NewPaymentData(),
//       paymentAmount: 0.0,
//       isFinalizing: false,
//       errorMessage: '',
//       successMessage: '',
//       isComplete: false,
//       invoices: [],
//       isSubmitting: false,
//     }
//   }

//   componentDidMount() {
//     this._isMounted = true
//     this.loadInvoices().then((res) => {
//       const payload = {
//         ...this.state,
//         invoices: res.value.data.map((i) => ({ ...i, amount: i?.openBalance ?? 0.0 })),
//         paymentAmount: res.value.data.reduce(
//           (prev, cur) => prev + parseFloat(cur.openBalance),
//           0.0
//         ),
//       }

//       this.setState(payload)
//     })
//   }

//   componentWillUnmount() {
//     this._isMounted = false
//   }

//   handleAmountChange = (e, bla) => {
//     const { id } = e.target

//     const updatedInvoices = this.state.invoices.map((i) => {
//       if (i._id !== id) {
//         return i
//       }

//       let newValue = e.target.value.replace('$', '').replace(/\,+/g, '')

//       if (parseFloat(newValue) > parseFloat(i.openBalance)) {
//         newValue = i.openBalance
//       }
//       return { ...i, amount: newValue }
//     })

//     const paymentAmount = updatedInvoices.reduce((prev, cur) => prev + parseFloat(cur.amount), 0.0)

//     this.setState({
//       ...this.state,
//       invoices: updatedInvoices,
//       paymentData: { ...this.state.paymentData, amountDue: paymentAmount },
//     })
//   }

//   loadInvoices() {
//     return this.props.loadInvoices({
//       query: {
//         $populate: ['order'],
//         customer: this.props.match.params.id,
//         isClosed: { $ne: true },
//         $populateOpenBalance: 1,
//         isVoid: { $ne: true },
//         $sort: { createdAt: 1 },
//         $limit: 150,
//       },
//     })
//   }

//   handleSubmit = (e, data) => {
//     this.setState({
//       ...this.state,
//       errorMessage: '',
//       isSubmitting: true,
//     })
//     const invoices = this.state.invoices
//       .filter((i) => !!i.amount)
//       .map((i) => ({ invoiceId: i._id, amount: i.amount }))

//     makeMultiplePayment(invoices, this.state.paymentData, this.props.data)
//       .then((result: any) => {
//         if (result.result) {
//           Scroll.animateScroll.scrollToTop()
//           this.setState(
//             {
//               ...this.state,
//               isComplete: true,
//               invoices: [],
//               paymentAmount: 0.0,
//               isSubmitting: false,
//             },
//             () => this.loadInvoices()
//           )

//           this.initPaymentData(this.props.data, 0)

//           setTimeout(() => {
//             if (!this._isMounted) {
//               return
//             }

//             // this.loadInvoices();
//             this.setState({
//               ...this.state,
//               isComplete: false,
//             })
//           }, 5000)
//         } else {
//           this.setState({
//             ...this.state,
//             errorMessage: result.message,
//             isFinalizing: false,
//             isSubmitting: false,
//           })

//           Scroll.scroller.scrollTo('errorMessage', {})
//         }
//       })
//       .catch((e) =>
//         e.code && e.code < 500
//           ? this.setState({
//               ...this.state,
//               errorMessage: e.message,
//               isFinalizing: false,
//               isSubmitting: false,
//             })
//           : this.setState({
//               ...this.state,
//               errorMessage: 'We are experiencing technical difficulties. Please try again ',
//               isFinalizing: false,
//               isSubmitting: false,
//             })
//       )
//   }

//   initPaymentData(data, amountDue = 0) {
//     const updatedPaymentData = NewPaymentData()

//     const tokens = data.creditCardTokens || []
//     updatedPaymentData.creditCardType = PaymentCreditCardTypes.New
//     updatedPaymentData.creditCardToken = ''
//     updatedPaymentData.amountDue = amountDue || this.state.paymentAmount

//     if (tokens.length > 0) {
//       updatedPaymentData.creditCardType = PaymentCreditCardTypes.Stored
//       const foundDefault = tokens.find((t) => t.isDefault)
//       updatedPaymentData.creditCardToken = foundDefault
//         ? foundDefault.paymentProfileId
//         : tokens[0].paymentProfileId
//       updatedPaymentData.availableCreditCardTokens = tokens
//     }

//     return updatedPaymentData
//   }

//   componentWillReceiveProps(next) {
//     if (next.data) {
//       // const updatedPaymentData = NewPaymentData();

//       const amountDue = next.invoices?.queryResult?.data?.reduce(
//         (acc, cur) => acc + parseFloat(cur.openBalance),
//         0.0
//       )

//       const updatedPaymentData = this.initPaymentData(next.data, amountDue)

//       this.setState({
//         ...this.state,
//         paymentData: updatedPaymentData,
//       })
//     }

//     // console.log('about to', this.state.invoices.length )
//     // if (this.state.invoices.length === 0 && next.invoices.queryResult && next.invoices.queryResult.data) {
//     //   const payload = {
//     //     ...this.state,
//     //     invoices: next.invoices.queryResult.data.map(i => ({...i, amount: 0.00 })),
//     //   }
//     //   this.setState(payload);
//     // }
//   }

//   handlePaymentChange = (value) => {
//     this.setState({ ...this.state, paymentData: value })
//   }

//   render() {
//     if (!this.props.data) {
//       return (
//         <Segment>
//           <Dimmer active>
//             <Loader size="small">Loading</Loader>
//           </Dimmer>
//         </Segment>
//       )
//     }

//     return (
//       <div>
//         <Header as="h2" color="blue">
//           <Header.Content>
//             Make Payment
//             <Header.Subheader>
//               {this.props.data && this.props.data.organizationName
//                 ? this.props.data.organizationName
//                 : `${this.props.data.primaryContact.firstName} ${this.props.data.primaryContact.lastName}`}
//             </Header.Subheader>
//           </Header.Content>
//         </Header>
//         {this.state.isComplete && <Message success content={'Payment successfully applied'} />}

//         <InvoiceList invoices={this.state.invoices} handleAmountChange={this.handleAmountChange} />

//         {this.state.invoices && this.state.invoices.length > 0 && (
//           <div>
//             <Payment
//               {...this.state.paymentData}
//               customer={this.props.match.params.id}
//               onChange={this.handlePaymentChange}
//             />
//             {this.state.errorMessage !== '' && (
//               <Message
//                 name="errorMessage"
//                 error
//                 icon={'warning circle'}
//                 content={this.state.errorMessage}
//               />
//             )}

//             {this.state.isComplete && <Message success content={'Payment successfully applied'} />}
//             <Button
//               loading={this.state.isSubmitting}
//               disabled={this.state.isSubmitting}
//               style={{ marginTop: 10 }}
//               primary
//               content={'Apply Payment'}
//               onClick={this.handleSubmit}
//             />
//           </div>
//         )}
//       </div>
//     )
//   }
// }

// export default DetailWrapper(Container as any, 'customers', {
//   query: {},
//   mapStateToProps: (state: any) => ({
//     invoices: state.invoices,
//   }),
//   mapDispatchToProps: {
//     loadInvoices: services.invoices.find,
//   },
// })

export type InvoiceType = Array<
  ModelTypes.Invoices &
    Omit<ModelTypes.Invoices, 'order'> & {
      order: {
        _id: string;
        humanId: string;
      };
      openBalance: number;
      amount: number;
    }
>;

export default function Invoice(props) {
  const { id } = useParams<{ id: string }>();

  const [customer, setCustomer] = useState<ModelTypes.Customers>();

  const [invoices, setInvoices] = useState<InvoiceType>([]);

  const [paymentAmount, setPaymentAmount] = useState(0);

  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(PaymentMethod.CreditCard);
  const [creditCard, setCreditCard] = useState<PaymentCreditCard>({
    name: '',
    number: '',
    expMonth: '',
    expYear: '',
    cvv: '',
    address1: '',
    city: '',
    stateProvince: '',
    postalCode: '',
    saveCard: false,
  });
  const [creditCardType, setCreditCardType] = useState<PaymentCreditCardTypes>(
    PaymentCreditCardTypes.New
  );
  const [creditCardToken, setCreditCardToken] = useState<string | null>('');
  const [optionalDescription, setOptionalDescription] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [responseMessage, setResponseMessage] = useState('');

  useEffect(() => {
    const loadInvoices = async () => {
      setIsLoading(true);
      try {
        const res = await Feathers.service('invoices').find({
          query: {
            $populate: ['customer', 'order'],
            $populateOpenBalance: 1,
            $sort: {
              createdAt: 1,
            },
            $limit: 150,
            isClosed: {
              $ne: true,
            },
            isVoid: {
              $ne: true,
            },
            customer: id,
          },
        });
        // const updatedData = res?.data?.map((r) => ({ ...r, amount: r?.openBalance || 0.0 }));
        // const amountDue = res?.data?.reduce(
        //   (prev, cur) => prev + Number(cur.openBalance || 0),
        //   0.0
        // );
        // setInvoices(updatedData);
        setInvoices(res?.data);
        // setPaymentAmount(amountDue);

        setCustomer(res?.data?.[0]?.customer);
      } catch (e) {
        console.error(e);
      }
      setIsLoading(false);
    };

    loadInvoices();
  }, [id]);

  if (isLoading) {
    return (
      <Flex justifyContent="center" alignItems="center" p={5}>
        <Spinner />
      </Flex>
    );
  }

  //   handleSubmit = (e, data) => {
  //     this.setState({
  //       ...this.state,
  //       errorMessage: '',
  //       isSubmitting: true,
  //     })
  //     const invoices = this.state.invoices
  //       .filter((i) => !!i.amount)
  //       .map((i) => ({ invoiceId: i._id, amount: i.amount }))

  //     makeMultiplePayment(invoices, this.state.paymentData, this.props.data)
  //       .then((result: any) => {
  //         if (result.result) {
  //           Scroll.animateScroll.scrollToTop()
  //           this.setState(
  //             {
  //               ...this.state,
  //               isComplete: true,
  //               invoices: [],
  //               paymentAmount: 0.0,
  //               isSubmitting: false,
  //             },
  //             () => this.loadInvoices()
  //           )

  //           this.initPaymentData(this.props.data, 0)

  //           setTimeout(() => {
  //             if (!this._isMounted) {
  //               return
  //             }

  //             // this.loadInvoices();
  //             this.setState({
  //               ...this.state,
  //               isComplete: false,
  //             })
  //           }, 5000)
  //         } else {
  //           this.setState({
  //             ...this.state,
  //             errorMessage: result.message,
  //             isFinalizing: false,
  //             isSubmitting: false,
  //           })

  //           Scroll.scroller.scrollTo('errorMessage', {})
  //         }
  //       })
  //       .catch((e) =>
  //         e.code && e.code < 500
  //           ? this.setState({
  //               ...this.state,
  //               errorMessage: e.message,
  //               isFinalizing: false,
  //               isSubmitting: false,
  //             })
  //           : this.setState({
  //               ...this.state,
  //               errorMessage: 'We are experiencing technical difficulties. Please try again ',
  //               isFinalizing: false,
  //               isSubmitting: false,
  //             })
  //       )
  //   }

  const handleSubmit = async () => {
    setIsSubmitting(true);
    setResponseMessage('');
    setErrorMessage('');
    try {
      const filteredInvoices = invoices?.reduce(
        (acc: { invoiceId: string; amount: number }[], cur) => {
          if (cur?.amount) {
            acc.push({
              invoiceId: cur._id as string,
              amount: cur.amount,
            });
          }
          return acc;
        },
        []
      );

      const paymentProcessDetails = paymentProcess({
        paymentMethod,
        creditCardType,
        creditCard,
        description: optionalDescription,
        cardToken: creditCardToken,
      });

      const res = await Feathers.service('/invoices/make-payment-multiple').create({
        invoices: filteredInvoices,
        paymentProcessDetails,
        tenant: customer?.business,
        customer: customer?._id,
      });

      if (res?.result) {
        const updatedInvoices = invoices?.filter(
          (i) => !filteredInvoices?.find((f) => f.invoiceId === i._id)
        );
        setInvoices(updatedInvoices);
        setResponseMessage('Payment successfully applied');

        Scroll.animateScroll.scrollToTop();
      } else {
        setErrorMessage(res?.message);
      }
    } catch (e) {
      const errMsg = e?.code < 500 ? e?.message : 'Something went wrong. Please try again.';

      setErrorMessage(errMsg);
    }
    setIsSubmitting(false);
  };

  // console.log('invoices', invoices);
  // console.log('paymentAmount', paymentAmount);

  // console.log('customer container', customer);
  // console.log('creditCardType', creditCardType);
  if (invoices?.length === 0) {
    return <Alert status="info">No Open Invoices!</Alert>;
  }

  const name = `${customer?.primaryContact?.firstName ?? ''} ${
    customer?.primaryContact?.lastName ?? ''
  }`;

  return (
    <Box>
      <PageHeader header="Make Payment" subheader={name} />

      <Box mt={7}>
        <InvoiceList
          invoices={invoices}
          onInvoiceChange={setInvoices}
          onAmountChange={setPaymentAmount}
        />
      </Box>

      <Box mt={4}>
        <Payment
          paymentMethod={paymentMethod}
          creditCardType={creditCardType}
          creditCard={creditCard}
          creditCardToken={creditCardToken}
          amountDue={paymentAmount}
          customer={customer as any}
          onChange={(v) => {
            // console.log('v', v);
            setResponseMessage('');
            setErrorMessage('');

            setPaymentMethod(v.paymentMethod);
            setCreditCard(v.creditCard);
            setCreditCardType(v.creditCardType);
            setCreditCardToken(v?.creditCardToken ?? '');
            setOptionalDescription(v.optionalDescription);
          }}
          errorMessage={errorMessage}
          successMessage={responseMessage}
          optionalDescription=""
        />
      </Box>

      {responseMessage && (
        <Alert status="success" my={3}>
          {responseMessage}
        </Alert>
      )}
      {errorMessage && (
        <Alert status="error" my={3}>
          {errorMessage}
        </Alert>
      )}

      {invoices?.length > 0 && (
        <Button
          isDisabled={isSubmitting}
          isLoading={isSubmitting}
          mt={3}
          colorScheme="blue"
          onClick={handleSubmit}
        >
          Apply Payment
        </Button>
      )}
    </Box>
  );
}
