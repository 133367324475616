import * as React from 'react';
import { Button, Popup, Image, Label } from 'semantic-ui-react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { FormattedDate, FormattedTime } from 'react-intl';

import {
  useList,
  generateOperatorOptions,
  Operator,
  ListPage,
  basicQueryMap,
} from '@inkcloud/components';

interface ListProps {}

export async function getFilterDefinition() {
  const definition = [
    {
      label: 'Name',
      value: 'name',
      operators: generateOperatorOptions([Operator.Like, Operator.Is]),
    },
    {
      label: 'Internal Name',
      value: 'internalName',
      operators: generateOperatorOptions([Operator.Like, Operator.Is]),
    },
  ];

  return definition;
}

const filterQueryMap = (values) => {
  let filterRows = basicQueryMap(values);

  filterRows = {
    ...filterRows,
  };

  return filterRows;
};

const serviceName = 'products-legacy/categories';
const pathPrefix = '/products/categories';

const baseUrl = `https://storage.googleapis.com/icstorage/`;

export const List: React.FunctionComponent<ListProps & RouteComponentProps<any>> = (props) => {
  const {
    match: { params },
    location,
    history,
  } = props;

  const useListValues = useList({
    feathers: [
      'find',
      serviceName,
      [
        {
          query: {
            $populate: ['entityAttributes', 'parentCategory'],
            $sort: { name: 1 },
          },
        },
      ],
    ],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  const headerActions = (
    <>
      <Link to={`${pathPrefix}/add`}>
        <Button content="New" primary size={'mini'} />
      </Link>
    </>
  );

  const tableDefintion = [
    {
      key: 'createdAt',
      label: 'Date',
      sortable: true,
      cell: (v, r) => (
        <Popup
          size="mini"
          content={<FormattedTime value={r.createdAt} />}
          trigger={
            <div>
              <FormattedDate value={r.createdAt} />
            </div>
          }
        />
      ),
    },
    {
      key: 'name',
      label: 'Name',
      cell: (v, r) => v,
    },
    {
      key: 'internalName',
      label: 'Internal Name',
      cell: (v, r) => v,
    },
    {
      key: '',
      label: 'Type',
      cell: (v, r) => (
        <>
          {r?.entityAttributes?.map((ea) => (
            <Label key={ea._id} size={'mini'}>
              {ea.name}
            </Label>
          ))}
        </>
      ),
    },
    {
      key: '',
      label: 'Image',
      cell: (v, r) =>
        r?.images?.[0]?.url && <Image src={`${baseUrl}${r?.images?.[0]?.url}`} size="tiny" />,
    },
    {
      key: '',
      label: 'Parent Category',
      cell: (v, r) => r?.parentCategory?.name,
    },
    {
      key: '',
      label: '',
      cell: (v, r) => (
        <>
          <Popup
            size="mini"
            content="Edit"
            trigger={
              <Link to={`${pathPrefix}/${r._id}`}>
                <Button size={'mini'} icon={'edit'} />
              </Link>
            }
          />
        </>
      ),
      textAlign: 'right',
    },
  ];

  return (
    <>
      <ListPage
        header="Categories"
        subheader="Product"
        useListValues={useListValues}
        history={history}
        pathPrefix={pathPrefix}
        tableDefintion={tableDefintion}
        withCheckboxes={true}
        headerActions={headerActions}
        getFilterDefinition={getFilterDefinition}
      />
    </>
  );
};
