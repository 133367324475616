// import * as React from 'react';
// import { Button, Checkbox, Header, Segment, Table, Label, Popup } from 'semantic-ui-react';
// import { Link, RouteComponentProps } from 'react-router-dom';
// import { FormattedDate } from 'react-intl';
// import { useList, PageHeaderRow, PreLoader, PaginatorWrapper } from '@inkcloud/components';
// import { FilterForm } from './FilterForm';

// interface ListProps { }

// const serviceName = 'production/vendors';

// const filterQueryMap = (values) => {
//   const filterMap: any = {
//     companyName: values.companyName ? { $LIKE: values.companyName } : undefined,
//     city: values.city ? { $LIKE: values.city } : undefined,
//   };

//   return [].reduce((m, field) => ({ ...m, [field]: values[field] ? values[field] : undefined }), filterMap);
// };

// export const List: React.FunctionComponent<ListProps & RouteComponentProps> = (props) => {
//   const { match: { params }, location, history, } = props;
//   const { state, reload, handleDelete, selectedItems, clearAll, setChecked, someSelected, toggleAll, filter, setFilter, sort, handleSort } = useList({
//     feathers: ['find', serviceName, [{ query: {} }]],
//     filterMap: filterQueryMap,
//     debounce: 150,
//     location,
//     history,
//     sort: { name: 1 }
//   })

//   const { queryResult, isLoading: isStateLoading } = state as any;

//   if (!queryResult) {
//     return null;
//   }

//   const pathPrefix = `/settings/production/suppliers`;

//   return (
//     <div>
//       <PageHeaderRow header='Suppliers' subheader='Settings'>
//         <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
//         <Link to={`${pathPrefix}/add`}><Button content="New" primary size={'mini'} /></Link>
//       </PageHeaderRow>

//       <FilterForm value={filter} onChange={(v) => setFilter(v)} onReset={() => setFilter({})} />

//       <Segment loading={state.isLoading}>
//         <PaginatorWrapper.Top filter={filter} reload={reload} data={(queryResult as any)} pathPrefix={`${pathPrefix}/`} />
//         <Table size={'small'} sortable>
//           <Table.Header>
//             <Table.Row>
//               <Table.HeaderCell><input type="checkbox" onChange={toggleAll} /></Table.HeaderCell>
//               <Table.HeaderCell sorted={sort.createdAt ? sort.createdAt : null} onClick={() => handleSort('createdAt')}>
//                 Created
//               </Table.HeaderCell>
//               <Table.HeaderCell sorted={sort.createdAt ? sort.createdAt : null} onClick={() => handleSort('createdAt')}>
//                 Foreign ID
//               </Table.HeaderCell>
//               <Table.HeaderCell sorted={sort.companyName ? sort.companyName : null} onClick={() => handleSort('companyName')}>
//                 Company Name
//               </Table.HeaderCell>
//               <Table.HeaderCell sorted={sort.email ? sort.email : null} onClick={() => handleSort('email')}>
//                 Email
//               </Table.HeaderCell>
//               <Table.HeaderCell sorted={sort.phone ? sort.phone : null} onClick={() => handleSort('phone')}>
//                 Phone
//               </Table.HeaderCell>
//               <Table.HeaderCell sorted={sort.city ? sort.city : null} onClick={() => handleSort('city')}>
//                 City State
//               </Table.HeaderCell>
//               <Table.HeaderCell>
//                 Tags
//               </Table.HeaderCell>

//               <Table.HeaderCell></Table.HeaderCell>
//             </Table.Row>
//           </Table.Header>
//           <Table.Body>
//             {queryResult && (queryResult as any).data.map(r => (
//               <Table.Row key={r._id}>
//                 <Table.Cell>
//                   <input type="checkbox" value={r._id} checked={selectedItems[r._id] || false} onChange={setChecked} />
//                 </Table.Cell>
//                 <Table.Cell>
//                   <FormattedDate value={r.createdAt} />
//                 </Table.Cell>
//                 <Table.Cell>
//                   {r.foreignId}
//                 </Table.Cell>
//                 <Table.Cell>
//                   {r.companyName}
//                 </Table.Cell>
//                 <Table.Cell>
//                   <a href={`mailto:${r.email}`}>{r.email}</a>
//                 </Table.Cell>
//                 <Table.Cell>
//                   {r.phone}
//                 </Table.Cell>
//                 <Table.Cell>
//                   {r.city} {r.stateProvince}
//                 </Table.Cell>
//                 <Table.Cell>
//                   {r.tags && r.tags.map(t => <Label key={t} size={'mini'}>{t}</Label>)}
//                 </Table.Cell>
//                 <Table.Cell textAlign="right">
//                   <Popup
//                     size='mini'
//                     content="Edit"
//                     trigger={
//                       <Link to={`${pathPrefix}/${r._id}`}>
//                         <Button size={'mini'} icon={'edit'} />
//                       </Link>
//                     }
//                   />
//                 </Table.Cell>
//               </Table.Row>
//             ))}
//           </Table.Body>
//         </Table>
//         <PaginatorWrapper.Bottom reload={reload} data={(queryResult as any)} pathPrefix={`${pathPrefix}`}>
//           {someSelected && <Button primary type="button" content="Delete" onClick={handleDelete} />}
//         </PaginatorWrapper.Bottom>
//       </Segment>
//     </div>
//   );
// }

import React from 'react';

import { Link, RouteComponentProps } from 'react-router-dom';
import { FormattedDate, FormattedTime } from 'react-intl';
import { Button, Box, IconButton, HStack, Tag, Tooltip, ButtonGroup, Wrap } from '@chakra-ui/react';

import { FaEdit, FaUsers } from 'react-icons/fa';

import {
  useList,
  ListPage,
  basicQueryMap,
  generateOperatorOptions,
  Operator,
} from '@inkcloud/components';

interface ListProps {}

const serviceName = 'production/vendors';
const pathPrefix = '/settings/production/suppliers';

const filterQueryMap = (values) => basicQueryMap(values, {});

export async function getFilterDefinition() {
  const definition = [
    {
      label: 'Company Name',
      value: 'companyName',
      operators: generateOperatorOptions([Operator.Like]),
    },
    {
      label: 'City',
      value: 'city',
      operators: generateOperatorOptions([Operator.Like]),
    },
  ];

  return definition.sort((a: any, b: any) => a.label.localeCompare(b.label));
}

export default function List(props: ListProps & RouteComponentProps) {
  const {
    match: { params },
    location,
    history,
  } = props;

  const useListValues = useList({
    feathers: ['find', serviceName, [{ query: {} }]],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  const tableDefinition = [
    {
      key: 'createdAt',
      label: 'Date',
      sortable: true,
      cell: (v) => (
        <Tooltip hasArrow aria-label="time" label={<FormattedTime value={v} />}>
          <Box>
            <FormattedDate value={v} />
          </Box>
        </Tooltip>
      ),
    },
    {
      key: 'foreignId',
      label: 'Foreign ID',
      sortable: true,
      cell: (v, r) => v,
    },
    {
      key: 'companyName',
      label: 'Company Name',
      sortable: true,
      cell: (v, r) => v,
    },
    {
      key: 'email',
      label: 'Email',
      sortable: true,
      cell: (v, r) => v,
    },
    {
      key: 'phone',
      label: 'Phone',
      sortable: true,
      cell: (v, r) => v,
    },
    {
      key: '',
      label: 'City State',
      sortable: true,
      cell: (v, r) => `${r?.city ?? ''} ${r?.stateProvince ?? ''}`,
    },
    {
      key: 'tags',
      label: 'Tags',
      sortable: true,
      cell: (v, r) => (
        <Wrap spacing={2}>
          {v.map((t, index) => (
            <Tag size="sm" key={index} colorScheme={'blue'}>
              {t}
            </Tag>
          ))}
        </Wrap>
      ),
    },
    {
      key: '',
      label: '',
      cell: (v, r) => (
        <HStack spacing={1}>
          <Tooltip label="Manage Users">
            <Link to={`${pathPrefix}/${r._id}/users`}>
              <IconButton colorScheme="gray" aria-label="Edit" icon={<FaUsers />} />
            </Link>
          </Tooltip>
          <Tooltip label="Edit Details">
            <Link to={`${pathPrefix}/${r._id}`}>
              <IconButton colorScheme="gray" aria-label="Edit" icon={<FaEdit />} />
            </Link>
          </Tooltip>
        </HStack>
      ),
      textAlign: 'right',
    },
  ];

  const headerActions = (
    <ButtonGroup spacing={1}>
      <Button size="sm" onClick={() => history.goBack()}>
        Back
      </Button>
      <Link to={`${pathPrefix}/add`}>
        <Button size="sm" colorScheme="blue">
          New
        </Button>
      </Link>
    </ButtonGroup>
  );

  return (
    <div>
      <ListPage
        header={'Suppliers'}
        subheader="Settings"
        useListValues={useListValues}
        history={history}
        pathPrefix={pathPrefix}
        tableDefintion={tableDefinition}
        withCheckboxes={true}
        headerActions={headerActions}
        getFilterDefinition={getFilterDefinition}
      />
    </div>
  );
}
