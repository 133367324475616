import React, { useState } from 'react';
import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import type { ModelTypes } from '@inkcloud/icapi-types';
import { Select } from 'chakra-react-select';

import {
  IconButton,
  InputRightElement,
  InputGroup,
  Table as ChakraTable,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  Box,
  TableContainer,
  Input,
  Button,
  Tooltip,
  useToast,
  Flex,
} from '@chakra-ui/react';

import { BiNotepad, BiChat, BiMailSend } from 'react-icons/bi';

import {
  // Column,
  // Table,
  ColumnDef,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  flexRender,
  RowData,
  Cell,
  Row,
} from '@tanstack/react-table';

import { NumberInput } from '../../sales-orders/SalesOrder/LineItemGrid/Inputs';

import VendorResponseModal from '../SupplierResponseModal';

import { SendModal } from '../SendModal';

type optionsType = { label: string; value: string }[];

declare module '@tanstack/react-table' {
  interface TableMeta<TData extends RowData> {
    updatePaperCostM: (row: Row<EstimateRow>, id: string, value: number) => void;
    // updatePaperCostM2: (vendorId: string, itemId: string, quantity: number, componentId: string, cost: number) => void,
    updateData: (rowIndex: number, columnId: string, value: unknown) => void;
    updateEstimateField: (row: Row<EstimateRow>, id: string, value: number) => void;
    // updateAdditionalCostsDescription: (row: Row<EstimateRow>, id: string, value: string) => void
    // updateAdditionalCostsAmount: (row: Row<EstimateRow>, id: string, value: string) => void
    // addAdditionalCosts: (
    //   row: Row<EstimateRow>,
    //   id: string,
    //   value: string,
    //   includedInMarkup: boolean
    // ) => void
    // removeAdditionalCosts: (row: Row<EstimateRow>, id: string, value: string) => void
  }
}

type Response = Exclude<ModelTypes.Rfq['responses'], undefined>[number];
type Item = Exclude<Response['items'], undefined>[number];

const editableFields = ['Markup %', 'Markup $', 'Tax Rate'];

// const round4th = (v: any) => Math.round(v * 1000) / 1000

const round4th = (v: any) => Number.parseFloat(v).toFixed(4);

function isLowest(vendor: string, item: string, quantity: number, rfq: ModelTypes.Rfq) {
  const result = rfq.responses?.reduce(
    (acc, cur) => {
      // find the item
      const found = cur.items?.find((i) => i.item === item);
      const price = found?.quantities?.find((q) => q.quantity === quantity)?.price;

      if (cur.status !== 'responded') {
        return acc;
      }

      if (!price === undefined) {
        // console.log('its undefined');
        return acc;
      }

      if ((!acc.vendor || acc.price === null) && price) {
        return { vendor: (cur.vendor as ModelTypes.ProductionVendors)?._id, price };
      }

      if (price && (price as any) < acc.price) {
        return { vendor: (cur.vendor as ModelTypes.ProductionVendors)?._id, price };
      }

      return acc;
    },
    { vendor: '', price: 0 } as any
  );

  // console.log({ resultVendor: result?.vendor, passedVendor: vendor });

  return result?.vendor === vendor;
}

// function lowestTotalCost(
//   qty: number,
//   itemId: string,
//   vendorId: string,
//   totalCost: number,
//   rfq: ModelTypes.Rfq,
//   status: string,
//   estimate: NonNullable<ModelTypes.Rfq['estimate']>
// ) {
//   const filteredVendors = rfq.responses?.filter(
//     (res) =>
//       res.status === 'responded' &&
//       res.items?.find((i) => i.item === itemId)?.quantities?.find((q) => q.quantity === qty)?.price
//   )
//   if (qty === 100 && itemId === '64235e1d523e88abde804ce5') {
//     console.log('filteredVendors', filteredVendors)
//   }

//   // if (filteredVendors?.length === 1 && (filteredVendors[0].vendor as any)._id === vendorId) {
//   //   return
//   // }
//   const t = estimate?.items
//     ?.find((i) => i.item === itemId)
//     ?.quantities?.find((q) => q.quantity === qty)

//   const missingVendors = filteredVendors?.reduce((innerAcc: any, innerCur) => {
//     const foundMissing = t?.paperCostM?.find((p) => p.vendor === (innerCur.vendor as any)._id)

//     const basePrice =
//       innerCur?.items?.find((i) => i.item === itemId)?.quantities?.find((q) => q.quantity === qty)
//         ?.price ?? 0

//     if (qty === 100 && itemId === '64235e1d523e88abde804ce5') {
//       console.log('foundMissing', foundMissing)
//       console.log('innerCur', innerCur)
//       console.log('top basePrice', basePrice)
//     }

//     if (!foundMissing && basePrice !== 0) {
//       innerAcc.push({
//         vendor: (innerCur.vendor as any)._id,
//         basePrice,
//         costTotal: 0,
//       })
//     }

//     return innerAcc
//   }, []) as [{ vendor: string; basePrice: number; costTotal: number }]

//   const filteredPaperCostM = t?.paperCostM?.filter(
//     (p) => filteredVendors?.find((v) => (v.vendor as any)?._id === p.vendor)?.status === 'responded'
//   )

//   // console.log('filteredPaperCostM', filteredPaperCostM)

//   const temp = {
//     ...t,
//     paperCostM: [...(filteredPaperCostM || []), ...(missingVendors ?? [])],
//   }

//   const updatedPaperCostM = temp?.paperCostM?.reduce((acc2, cur2) => {
//     const otherVendors = cur2.vendor !== vendorId && status === 'responded'

//     if (otherVendors) {
//       const basePrice =
//         rfq?.responses
//           ?.find((res) => (res.vendor as any)._id === cur2.vendor)
//           ?.items?.find((i) => i.item === itemId)
//           ?.quantities?.find((q) => q.quantity === qty)?.price ?? 0.0

//       const missingVendorBasePrice = missingVendors?.find(
//         (m) => m.vendor === cur2.vendor
//       )?.basePrice

//       if (qty === 100 && itemId === '64235e1d523e88abde804ce5') {
//         console.log('missingVendors', missingVendors)
//         console.log('missingVendorBasePrice', missingVendorBasePrice)

//         console.log('basePrice', basePrice)
//       }

//       acc2[cur2?.vendor ?? ''] = {
//         basePrice: missingVendorBasePrice ?? basePrice,
//         costTotal: (acc2[cur2?.vendor ?? '']?.costTotal ?? 0.0) + cur2?.costTotal ?? 0.0,
//       }
//     }

//     return acc2
//   }, {}) as { [k: number]: { basePrice: number; costTotal: number } }
//   // ?.paperCostM?.reduce((acc2, cur2) => {
//   //   const otherVendors =
//   //     cur2.vendor !== vendorId && r.status === 'responded'

//   //   if (otherVendors) {
//   //     const basePrice =
//   //       rfq?.responses
//   //         ?.find((res) => (res.vendor as any)._id === cur2.vendor)
//   //         ?.items?.find((i) => i.item === itemId)
//   //         ?.quantities?.find((q) => q.quantity === (cur as any).quantity)?.price ??
//   //       0.0
//   //     console.log('basePrice', basePrice)

//   //     acc2[cur2?.vendor ?? ''] = {
//   //       basePrice,
//   //       costTotal:
//   //         (acc2[cur2?.vendor ?? '']?.costTotal ?? 0.0) + cur2?.costTotal ?? 0.0,
//   //     }
//   //   }

//   //   return acc2
//   // }, {}) as { [k: number]: { basePrice: number; costTotal: number } }

//   if (qty === 100 && itemId === '64235e1d523e88abde804ce5') {
//     console.log('updatedPaperCostM', updatedPaperCostM)
//   }

//   const lowestChecker = Object.keys(updatedPaperCostM)?.reduce((innerAcc, innerCur) => {
//     const total =
//       (updatedPaperCostM?.[innerCur]?.costTotal || 0) +
//       (updatedPaperCostM?.[innerCur]?.basePrice || 0)

//     if (innerAcc === 0 || total < innerAcc) {
//       innerAcc = total
//     } else if (totalCost < innerAcc && totalCost < total) {
//       innerAcc = totalCost
//     }

//     // console.log('totalCost', totalCost, 'total', total, 'innerAcc', innerAcc)

//     return innerAcc
//   }, 0)

//   if (qty === 100 && itemId === '64235e1d523e88abde804ce5') {
//     console.log('lowestChecker', lowestChecker)
//   }

//   return lowestChecker
// }

interface EstimateRow {
  vendor: {
    _id: string;
    name: string;
    rowSpan?: number;
  };
  item?: string;
  component?: string;
  description: string;
  componentDescription?: string;
  componentRowSpan?: number;
  firstVendorRow?: boolean;
  lastVendorRow?: boolean;
  additionalCostsIndex?: number;
  additionalCostsDescription?: string;
  bgColor?: string;
  paddingTop?: string;
  paddingBottom?: string;
  fontStyle?: string;
  fontWeight?: string;
  fontSize?: string;
  quantities: {
    [key: string]: {
      quantity: number;
      price: number;
      isLowest?: boolean;
    };
  };
  descriptionColumnSpan?: number;
  isSelectVendorRow?: boolean;
  estimateField?: string; //
  // vendor: string;
  // vendorName: string;
  // [key: string]: string;
}

interface IEstimateConversion2Props {
  rfq: ModelTypes.Rfq;
  rfqItemId: string;
  estimate: NonNullable<ModelTypes.Rfq['estimate']>;
  response?: Response;
  // vendors: optionsType
  vendors: any;
  isReadOnly?: boolean;
  onSubmit: (v: ModelTypes.Rfq['estimate']) => void;
  onChange: (v: ModelTypes.Rfq['estimate']) => void;
  onReload: () => void;
}

const spanStyle: React.CSSProperties = {
  textAlign: 'right',
  height: '100%',
  display: 'block',
  overflow: 'auto',
  padding: '0.5em 0.7em',
  background: '#f1f1f157',
  // position: 'absolute',
  // left: 0,
  // top: 0,
};

// Give our default column cell renderer editing superpowers!
const defaultColumn: Partial<ColumnDef<EstimateRow>> = {
  cell: ({ getValue, row, column: { id }, table }) => {
    const { index } = row;
    const value = getValue<string>();

    const bgColor = row.original.quantities[id]?.isLowest ? '#90ee90' : row.original.bgColor;
    return <span style={{ ...spanStyle, background: bgColor }}>{value}</span>;
  },
};

function currencyFormat(v, locale = 'en-US', currency = 'USD') {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  }).format(v);
}

function currencySymbol(locale = 'en-US', currency = 'USD') {
  return new Intl.NumberFormat(locale, { style: 'currency', currency })
    .formatToParts(1)
    .find((x) => x.type === 'currency').value;
}

function buildHeaders(
  rfq: ModelTypes.Rfq,
  item: Item,
  vendors: optionsType
): ColumnDef<EstimateRow>[] {
  // console.log('!!!!!!!!!!!!!!in build headers', vendors)

  const columns: ColumnDef<EstimateRow>[] = [
    {
      header: 'Vendor',
      id: 'vendor',
      accessorFn: (row) => row.vendor.name,
      cell: ({ getValue, row, column: { id }, table }) => <>{row.original.vendor.name}</>,
    },
    {
      header: 'Description',
      id: 'description',
      accessorFn: (row) => row.description,
    },
    {
      header: 'Comp Desc',
      id: 'componentDescription',
      accessorFn: (row) => row.componentDescription,
      cell: ({ getValue, row, column: { id }, table }) => {
        const { index } = row;
        let initialValue = getValue();
        initialValue = row.original.additionalCostsDescription;

        if (row.original.description === 'Additional Costs') {
          initialValue = row.original.additionalCostsDescription;
        }

        // We need to keep and update the state of the cell normally
        const [value, setValue] = React.useState<any>(initialValue);

        // If the initialValue is changed externally, sync it up with our state
        React.useEffect(() => {
          setValue(initialValue);
        }, [initialValue]);

        const onBlur = (e) => {
          table.options.meta?.updateAdditionalCostsDescription(row, id, value);
        };

        const onAddAdditionaCostClick =
          (includedInMarkup = true) =>
          (e) => {
            table.options.meta?.addAdditionalCosts(row, id, value, includedInMarkup);
          };

        const onRemoveAdditionaCostClick = (e) => {
          table.options.meta?.removeAdditionalCosts(row, id, value);
        };

        // console.log('@@!@!@!@!!@!@!@', {
        //   id,
        //   rowOriginal: row.original,
        //   initialValue,
        //   value,
        // })

        if (row.original.description === 'Additional Costs') {
          // console.log('(((((((( Rendering additional costs index', {
          //   index, id, value, initialValue, rowOriginal: row.original,
          // })

          return (
            <>
              <InputGroup size={'sm'}>
                <Input
                  type="text"
                  // size={'sm'}
                  pr="3.5rem"
                  placeholder={'Description...'}
                  value={value}
                  onBlur={onBlur}
                  onChange={(e) => setValue(e.target.value)}
                />

                {row.original.additionalCostsIndex === 0 && (
                  <InputRightElement width="3.5rem">
                    <IconButton
                      size={'xs'}
                      h="1.25rem"
                      aria-label="Add Line"
                      icon={<AddIcon />}
                      onClick={onAddAdditionaCostClick(false)}
                    />
                  </InputRightElement>
                )}
                {/* {row.original.additionalCostsIndex !== 0 && (
                <InputRightElement width='3.5rem'>
                  <IconButton
                    size={'xs'}
                    h='1.25rem'
                    aria-label='Remove Line'
                    icon={<MinusIcon />}
                    onClick={onRemoveAdditionaCostClick}
                  />
                </InputRightElement>
                )} */}
              </InputGroup>
            </>
          );
        }

        return (
          <span style={{ ...spanStyle, backgroundColor: undefined }}>
            {row.original.componentDescription ?? ''}
          </span>
        );
      },
    },
    {
      header: 'Quantities',
      accessorFn: (row) => row.vendor.name,
      columns:
        item?.quantities?.map((q) => ({
          id: String(q),
          header: String(q),
          meta: {
            quantity: true,
          },
          accessorFn: (row) => row.quantities[q as any]?.price,
          cell: ({ getValue, row, column: { id }, table }) => {
            const { index } = row;
            const initialValue = getValue();

            // We need to keep and update the state of the cell normally
            const [value, setValue] = React.useState(initialValue);

            // When the input is blurred, we'll call our table meta's updateData function
            const onBlur = (v) => {
              // console.log('we got a blur here', row.original, v)
              // console.log('row.original.description', row.original.description, row.original);
              if (row.original.componentDescription === 'Paper Cost M') {
                table.options.meta?.updatePaperCostM(row, id, value);

                // row.original.vendor._id,
                // row.original.item as string,
                // row.original.quantities[id].quantity,
                // row.original.component as string,
                // value as number
                // );
              }

              table.options.meta?.updateData(index, id, value);

              if (editableFields.includes(row.original.description as string)) {
                //  update

                table.options.meta?.updateEstimateField(row, id, v);
              }

              if (row.original.description === 'Select Vendor') {
                //  update
                table.options.meta?.updateEstimateField(row, id, v);
              }

              if (row.original.description === 'Additional Costs') {
                //  update
                table.options.meta?.updateAdditionalCostsAmount?.(row, id, v);
              }
            };

            // If the initialValue is changed external, sync it up with our state
            React.useEffect(() => {
              // console.log('here we got a cange to our initial value');
              setValue(initialValue);
            }, [initialValue]);

            const bgColor = row.original.quantities[id]?.isLowest
              ? '#90ee90'
              : row.original.bgColor;

            // console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!', { value, vendors: vendors })
            // console.log('row.original', row.original);

            // This is the select vendor row.
            if (row.original.isSelectVendorRow) {
              // console.log('$%$%%%%$%$', { value, vendors: vendors })

              return (
                <Select
                  size="sm"
                  options={vendors}
                  value={vendors.filter((v) => value && value.includes && value?.includes(v.value))}
                  placeholder="Select Supplier(s)..."
                  // isClearable={true}
                  isMulti={true}
                  isClearable
                  useBasicStyles
                  onChange={(v) => {
                    setValue(v);
                    onBlur(v.map((v2: any) => v2.value));
                  }}
                />
              );
            }

            if (
              row.original.componentDescription === 'Sheet Size' ||
              row.original.componentDescription === 'Num Sheets'
            ) {
              return <span style={{ ...spanStyle, background: bgColor }}>{value}</span>;
            }

            if (
              (id === 'vendor' ||
                id === 'description' ||
                row.original.description === 'Unit Cost' ||
                id === 'componentDescription') &&
              row.original.description !== 'Additional Costs'
            ) {
              return <span style={{ ...spanStyle, background: bgColor }}>{value}</span>;
            }

            if (row.original.description === 'Per Unit') {
              return (
                <span style={{ ...spanStyle }}>
                  {currencySymbol()}
                  {value}
                </span>
              );
            }

            if (
              !row.original.componentDescription?.startsWith('Paper Cost M') &&
              !editableFields.includes(row.original.description) &&
              row.original.description !== 'Additional Costs'
            ) {
              return (
                <span style={{ ...spanStyle, background: bgColor }}>
                  {value ? currencyFormat(Number(value)) : '-'}
                </span>
              );
            }

            return (
              <Box pr=".7em">
                <NumberInput
                  value={(value ?? 0.0) as any}
                  onChange={(v) => setValue(v)}
                  onBlur={onBlur}
                />
              </Box>
            );
          },
        })) || [],
    },
  ];

  return columns;
}

function buildData(rfq: ModelTypes.Rfq, estimate: ModelTypes.Rfq['estimate'], item: Item) {
  const data: EstimateRow[] = [];
  const bgColors = ['#F7FAFC', 'white'];

  // console.log('rfq in build data', rfq)
  // console.log('estimate in build data', estimate)

  // This loop goes through each vendor response. This is to render the cost part of the table
  rfq.responses
    ?.filter((r) => r?.status === 'responded' || r?.status === 'responded-with-changes')
    ?.filter((r) => r.items?.find((i) => i.item === item._id))
    .forEach((r, idx) => {
      const bgColor = bgColors[idx % 2];
      // console.log('r', r)
      let vendorRowSpan = 3;

      if (rfq.paperSuppliedBy === 'self') {
        vendorRowSpan += (item as any).components.length * 4;
      }

      // console.log('top r', r)

      // console.log('!@!@!@!@!@!@!@   vendorRowSpan', vendorRowSpan);
      const quantities =
        r.items
          ?.find((i) => i.item === item._id)
          ?.quantities?.reduce((acc, cur) => {
            acc[cur.quantity as any] = {
              quantity: cur.quantity as any,
              price: cur.price as any,
              // isLowest: isLowest((r.vendor as any)?._id, item._id as string, cur.quantity as number, rfq),
            };
            return acc;
          }, {}) ?? {};

      // console.log('quantities', quantities)

      data.push({
        vendor: {
          _id: (r.vendor as any)?._id,
          name: (r.vendor as any)?.companyName,
          // rowSpan: 2 + ((item as any).components.length * 4) + 1, // TODO: Calculate based on if paper is self and # components
          // This works if paper is not supplied by self
          rowSpan: vendorRowSpan, // TODO: Calculate based on if paper is self and # components
        },
        bgColor,
        firstVendorRow: idx === 0,
        description: 'Cost',
        componentDescription: '',
        descriptionColumnSpan: 1,
        quantities: quantities as any,
      });

      data.push({
        vendor: {
          _id: (r.vendor as any)?._id,
          name: (r.vendor as any)?.companyName,
        },
        bgColor,
        description: 'Unit Cost',
        descriptionColumnSpan: 1,
        quantities:
          r.items
            ?.find((i) => i.item === item._id)
            ?.quantities?.reduce((acc, cur) => {
              acc[cur.quantity as any] = {
                quantity: cur.quantity as any,
                price: cur.price ? round4th(cur.price / (cur.quantity ?? 1)) : ('-' as any),
              };
              return acc;
            }, {}) ?? ({} as any),
      });

      if (rfq.paperSuppliedBy === 'self') {
        (item as any).components?.forEach((c, innerIdx: number) => {
          data.push({
            vendor: {
              _id: (r.vendor as any)?._id,
              name: (r.vendor as any)?.companyName,
            },
            bgColor,
            description: `Component ${innerIdx + 1}`,
            componentDescription: 'Num Sheets',
            componentRowSpan: 4,
            quantities:
              r.items
                ?.find((i) => i.item === item._id)
                ?.quantities?.reduce((acc, cur) => {
                  acc[cur.quantity as any] = {
                    quantity: cur.quantity as any,
                    price: cur?.components?.find((cc) => cc.component === c._id)?.numSheets as any,
                  };

                  return acc;
                }, {}) ?? ({} as any),
          });

          data.push({
            vendor: {
              _id: (r.vendor as any)?._id,
              name: (r.vendor as any)?.companyName,
            },
            bgColor,
            description: `Component ${innerIdx + 1}`,
            componentDescription: 'Sheet Size',
            quantities:
              r.items
                ?.find((i) => i.item === item._id)
                ?.quantities?.reduce((acc, cur) => {
                  const sheetSize = cur?.components?.find((cc) => cc.component === c._id)
                    ?.sheetSize as any;
                  acc[cur.quantity as any] = {
                    quantity: cur.quantity as any,
                    price: sheetSize?.width ? `${sheetSize?.width} x ${sheetSize?.height}` : '-',
                  };

                  return acc;
                }, {}) ?? ({} as any),
          });

          data.push({
            vendor: {
              _id: (r.vendor as any)?._id,
              name: (r.vendor as any)?.companyName,
            },
            bgColor,
            description: `Component ${innerIdx + 1}`,
            item: item._id,
            component: c._id,
            componentDescription: 'Paper Cost M',
            quantities:
              r.items
                ?.find((i) => i.item === item._id)
                ?.quantities?.reduce((acc, cur) => {
                  // const sheetSize = cur?.components?.find(cc => cc.component === c._id)?.sheetSize as any
                  acc[cur.quantity as any] = {
                    quantity: cur.quantity as any,
                    price: estimate?.items
                      ?.find((i) => i.item === item._id)
                      ?.quantities?.find((q) => q.quantity === cur.quantity)
                      ?.paperCostM?.find(
                        (p) => p.component === c._id && p.vendor === (r.vendor as any)?._id
                      )?.cost as any,
                  };

                  return acc;
                }, {}) ?? ({} as any),
          });

          data.push({
            vendor: {
              _id: (r.vendor as any)?._id,
              name: (r.vendor as any)?.companyName,
            },
            bgColor,
            description: `Component ${innerIdx + 1}`,
            component: c._id,
            componentDescription: 'Paper Cost',
            quantities:
              r.items
                ?.find((i) => i.item === item._id)
                ?.quantities?.reduce((acc, cur) => {
                  const paperCost =
                    estimate?.items
                      ?.find((i) => i.item === item._id)
                      ?.quantities?.find((q) => q.quantity === cur.quantity)
                      ?.paperCostM?.find(
                        (p) => p.component === c._id && p.vendor === (r.vendor as any)?._id
                      )?.costTotal ?? 0.0;
                  acc[cur.quantity as any] = {
                    quantity: cur.quantity as any,
                    // price: estimate?.items?.find(i => i.item === item._id)?.quantities?.find(q => q.quantity === cur.quantity)?.paperCostM?.find(p => p.component === c._id)?.costTotal as any,
                    price: paperCost,
                  };

                  return acc;
                }, {}) ?? ({} as any),
          });
        });
      }

      data.push({
        vendor: {
          _id: (r.vendor as any)?._id,
          name: (r.vendor as any)?.companyName,
        },
        bgColor,
        description: 'Total Cost',
        componentDescription: '',
        descriptionColumnSpan: 1,
        lastVendorRow: true,

        quantities:
          r.items
            ?.find((i) => i.item === item._id)
            ?.quantities?.reduce((acc, cur) => {
              // Need to include Paper Cost here.

              // const paperCost = estimate?.items?.find(i => i.item === item._id)?.quantities?.find(q => q.quantity === cur.quantity)?.paperCost ?? 0.00;

              const totalPaperCost =
                estimate?.items
                  ?.find((i) => i.item === item._id)
                  ?.quantities?.find((q) => q.quantity === cur.quantity)
                  ?.paperCostM?.filter((c2) => c2.vendor === (r.vendor as any)?._id)
                  ?.reduce((acc2, cur2) => acc2 + (cur2?.costTotal ?? 0.0), 0) ?? 0.0;

              const totalCost = ((cur.price as number) ?? 0.0) + totalPaperCost;

              // if (cur.quantity === 1000 && item._id === '63c4ec77f4b3045b5499e593') {
              // console.log('totalPaperCost', totalPaperCost)
              // console.log('totalCost', totalCost)

              // const isLowest =
              //   totalCost > 0 &&
              //   totalCost <=
              //     lowestTotalCost(
              //       cur.quantity as number,
              //       item?._id as string,
              //       (r.vendor as any)?._id,
              //       totalCost,
              //       rfq,
              //       r.status as string,
              //       estimate as NonNullable<ModelTypes.Rfq['estimate']>
              //     ) &&
              //   r.status === 'responded'

              acc[cur.quantity as any] = {
                quantity: cur.quantity as any,
                price: (totalCost as any) ?? 0.0,
                // isLowest,
                isLowest: isLowest(
                  (r.vendor as any)?._id,
                  item._id as string,
                  cur.quantity as number,
                  rfq
                ),
              };
              return acc;
            }, {}) ?? ({} as any),
      });
    });

  return data;
}

const initVendorRes = { _id: '', name: '' };

export default function ItemEstimate(props: IEstimateConversion2Props) {
  const {
    rfq,
    rfqItemId,
    estimate,
    onSubmit,
    isReadOnly = false,
    vendors,
    onChange,
    onReload,
  } = props;

  const rfqItem = rfq?.items?.find((i) => i._id === rfqItemId);

  // console.log('top most vendors', vendors)

  // console.log({rfq, rfqItemId });
  if (!rfqItem) {
    return null;
  }
  // const rfqItem = rfq?.items[0];
  const [data, setData] = React.useState(() => buildData(rfq, estimate, rfqItem as any));
  const columns = React.useMemo<ColumnDef<EstimateRow>[]>(
    () => buildHeaders(rfq, rfqItem as any, vendors),
    [vendors]
  );

  React.useEffect(() => {
    setData(buildData(rfq, estimate, rfqItem as any));
  }, [estimate]);

  const table = useReactTable({
    data,
    columns,
    defaultColumn,
    getCoreRowModel: getCoreRowModel(),

    meta: {
      updatePaperCostM: (row: Row<EstimateRow>, id, cost: number) => {
        const { vendor, item, component, quantities } = row.original;
        const { quantity } = quantities[id];
        // console.log('!!!!! UPDATING PAPER COST', { rowOriginal: row.original, id, cost })
        const lookupItem = rfq.responses
          ?.find((r) => (r.vendor as any)._id === vendor._id)
          ?.items?.find((i) => i.item === item);
        if (estimate?.items) {
          const updatedEstimate = { ...estimate };
          updatedEstimate.items = updatedEstimate?.items?.map((estimateItem) => {
            // console.log('!!!!! UPDATING PAPER COST', `comparing: ${estimateItem.item} === ${item}`)
            if (estimateItem.item === item) {
              estimateItem.quantities = estimateItem.quantities?.map((q) => {
                if (q.quantity === quantity) {
                  const numSheets = lookupItem?.quantities
                    ?.find((q2) => q2.quantity === quantity)
                    ?.components?.find((c) => c.component === component)?.numSheets;

                  q.paperCostM =
                    q.paperCostM?.map((p) => {
                      // console.log('@#$#$#$#$#$# comparing', `${p.vendor} === ${vendor._id}`)
                      const a = '';
                      return {
                        ...p,
                        cost: p.component === component && p.vendor === vendor._id ? cost : p.cost,
                        costTotal:
                          p.component === component && p.vendor === vendor._id
                            ? (Number(cost) * Number(numSheets)) / 1000
                            : p.costTotal,
                      };
                    }) ?? [];
                }

                // console.log('q q ', q)
                // console.log('q.selectedVendors ', q.selectedVendors)

                if (q.selectedVendors && q.selectedVendors.length > 0) {
                  // console.log('SETTESING SUBTOTAL COSTS', {
                  //   qSelectedVendors: q.selectedVendors,
                  //   rfqResponses: rfq.responses,
                  // });

                  q.subtotalCosts = rfq.responses
                    ?.filter((r) => (q.selectedVendors as any).includes((r.vendor as any)._id))
                    .reduce((acc, cur) => {
                      // console.log('^^^^^^ in reduce', cur);
                      const thisItem = cur.items
                        ?.find((i2) => i2.item === rfqItem._id)
                        ?.quantities?.find((q2) => q2.quantity === q.quantity);
                      const totalPaperCost =
                        // q.paperCostM?.reduce(
                        //   (acc2, cur2) => acc2 + Number(cur2.costTotal ?? 0.0),
                        //   0
                        // ) ?? 0.0
                        q.paperCostM?.reduce((acc2, cur2) => {
                          const found = (cur.vendor as any)?._id === cur2.vendor;

                          if (found) {
                            acc2 += Number(cur2.costTotal ?? 0.0);
                          }
                          return acc2;
                        }, 0) ?? 0.0;

                      // console.log('q acc', acc)
                      // console.log('q thisItem', thisItem)
                      // console.log('q totalPaperCost', totalPaperCost)
                      return acc + (thisItem?.price ?? 0.0) + totalPaperCost;
                    }, 0);

                  q.paperCostTotal =
                    q.paperCostM?.reduce((acc, cur) => acc + Number(cur.costTotal ?? 0.0), 0) ??
                    0.0;
                  q.totalCosts =
                    (q.subtotalCosts ?? 0.0) +
                    (estimateItem.additionalCosts?.reduce(
                      (acc, cur) =>
                        acc +
                        Number(
                          cur?.quantities?.find((q3) => q3.quantity === q.quantity)?.amount ?? 0.0
                        ),
                      0
                    ) ?? 0.0);
                  q.subtotalPrice = Number(q.totalCosts ?? 0.0) + Number(q.markupAmount ?? 0.0);
                  q.totalPrice = q.subtotalPrice + Number(q.taxAmount ?? 0.0);
                }

                return q;
              });
            }

            return estimateItem;
          });
          // console.log('!!!!!!!!!!!!!!updatedEsstimate', updatedEstimate)
          // update the state.
          // setData(buildData(rfq, rfq?.items[0] as any));
          // setData({ ...data, ...rfq });
          onChange(updatedEstimate as any);
        }
      },
      updateData: (rowIndex, columnId, value) => {
        setData((old: any[]) =>
          old.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...old[rowIndex]!,
                [columnId]: value,
              };
            }
            return row;
          })
        );
      },
      updateEstimateField(row: Row<EstimateRow>, id, value: number) {
        // console.log('got an updateEstimateField update', { row, id, value });

        if (estimate?.items) {
          const updatedEstimate = { ...estimate };
          if (estimate) {
            updatedEstimate.items = estimate?.items?.map((i) => {
              if (i.item === rfqItem._id) {
                i.quantities = i.quantities?.map((q) => {
                  if (q.quantity === row.original.quantities[id as string].quantity) {
                    // console.log('********** Setting the value', value);
                    q[row.original.estimateField as string] = value as any;

                    if (row.original.estimateField === 'selectedVendors') {
                      q.subtotalCosts = rfq.responses
                        ?.filter((r) => (value as any).includes((r.vendor as any)?._id))
                        .reduce((acc, cur) => {
                          // console.log('asdasd cur', cur)

                          const thisItem = cur.items
                            ?.find((i2) => i2.item === rfqItem._id)
                            ?.quantities?.find((q2) => q2.quantity === q.quantity);

                          const totalPaperCost =
                            q.paperCostM?.reduce((acc2, cur2) => {
                              const found = (cur.vendor as any)?._id === cur2.vendor;

                              if (found) {
                                acc2 += Number(cur2.costTotal ?? 0.0);
                              }

                              // console.log('q acc2', acc2)
                              return acc2;
                            }, 0) ?? 0.0;

                          // console.log(
                          //   'q acc + (thisItem?.price ?? 0.0) + totalPaperCost',
                          //   acc + (thisItem?.price ?? 0.0) + totalPaperCost
                          // )

                          return acc + (thisItem?.price ?? 0.0) + totalPaperCost;
                        }, 0);

                      // @TODO: RECALCULATE total costs across all vendors
                      // q.totalCosts
                    }

                    q.totalCosts =
                      (q.subtotalCosts ?? 0.0) +
                      (i.additionalCosts?.reduce(
                        (acc, cur) =>
                          acc +
                          Number(
                            cur?.quantities?.find((q3) => q3.quantity === q.quantity)?.amount ?? 0.0
                          ),
                        0
                      ) ?? 0.0);

                    if (row.original.estimateField === 'markupPercentage') {
                      q.markupAmount = Number(q.totalCosts) * (Number(value) / 100);
                    }

                    if (row.original.estimateField === 'markupAmount') {
                      q.markupPercentage = (Number(value) / Number(q.totalCosts)) * 100;
                    }

                    if (row.original.estimateField === 'taxRate') {
                      q.taxAmount = Number(q.subtotalPrice) * (Number(value) / 100);
                    }

                    q.subtotalPrice = Number(q.totalCosts ?? 0.0) + Number(q.markupAmount ?? 0.0);
                    q.totalPrice = q.subtotalPrice + Number(q.taxAmount ?? 0.0);
                  }

                  return q;
                });
              }

              return i;
            });

            // console.log('updatedEstimate', updatedEstimate)

            onChange(updatedEstimate as any);
          }
        }
      },
      updateAdditionalCostsDescription: (row: Row<EstimateRow>, id, value: string) => {
        if (estimate?.items) {
          const updatedEstimate = { ...estimate };
          if (estimate) {
            updatedEstimate.items = estimate?.items?.map((i) => {
              if (i.item === rfqItem._id) {
                i.additionalCosts = i.additionalCosts?.map((q, idx) => {
                  if (row.original.additionalCostsIndex === idx) {
                    q.description = value;
                  }

                  return q;
                });
              }

              return i;
            });

            onChange(updatedEstimate as any);
          }
        }
      },

      updateAdditionalCostsAmount: (row: Row<EstimateRow>, id, value: string) => {
        // console.log('update on the update additional costs!!!');
        if (estimate?.items) {
          const updatedEstimate = { ...estimate };
          if (estimate) {
            updatedEstimate.items = estimate?.items?.map((i) => {
              if (i.item === rfqItem._id) {
                i.additionalCosts = i.additionalCosts?.map((q, idx) => {
                  if (row.original.additionalCostsIndex === idx) {
                    q.quantities =
                      q.quantities?.map((q2) => {
                        if (Number(q2.quantity) === Number(id)) {
                          q2.amount = Number(value);
                        }

                        return q2;
                      }) ?? [];
                  }

                  return q;
                });

                i.quantities = i.quantities?.map((q) => {
                  if (Number(q.quantity) === Number(id)) {
                    q.totalCosts =
                      (q.subtotalCosts ?? 0.0) +
                      (i.additionalCosts?.reduce(
                        (acc, cur) =>
                          acc +
                          Number(
                            cur?.quantities?.find((q3) => q3.quantity === q.quantity)?.amount ?? 0.0
                          ),
                        0
                      ) ?? 0.0);
                    q.subtotalPrice = Number(q.totalCosts ?? 0.0) + Number(q.markupAmount ?? 0.0);
                    q.totalPrice = q.subtotalPrice + Number(q.taxAmount ?? 0.0);
                  }

                  return q;
                });
              }

              return i;
            });

            onChange(updatedEstimate as any);
          }
        }
      },
      addAdditionalCosts(row: Row<EstimateRow>, id, value: string) {
        if (estimate?.items) {
          const updatedEstimate = { ...estimate };
          if (estimate) {
            updatedEstimate.items = estimate?.items?.map((i) => {
              if (i.item === rfqItem._id) {
                i.additionalCosts = i.additionalCosts ?? [];
                i.additionalCosts.push({
                  description: '',
                  quantities:
                    i.quantities?.map((q) => ({
                      quantity: q.quantity,
                      amount: 0,
                    })) ?? [],
                });
              }

              return i;
            });

            onChange(updatedEstimate as any);
          }
        }
      },
      removeAdditionalCosts: (row: Row<EstimateRow>, id, value: string) => {
        if (estimate?.items) {
          const updatedEstimate = { ...estimate };
          if (estimate) {
            updatedEstimate.items = estimate?.items?.map((i) => {
              const updatedItem = { ...i };
              if (i.item === rfqItem._id) {
                const updatedAdditionalCosts = [...(updatedItem.additionalCosts ?? [])];
                updatedItem.additionalCosts = updatedAdditionalCosts.filter(
                  (q, idx) => row.original.additionalCostsIndex !== idx
                );

                updatedItem.quantities = updatedItem.quantities?.map((q) => {
                  if (Number(q.quantity) === Number(id)) {
                    q.totalCosts =
                      (q.subtotalCosts ?? 0.0) +
                      (i.additionalCosts?.reduce(
                        (acc, cur) =>
                          acc +
                          Number(
                            cur?.quantities?.find((q3) => q3.quantity === q.quantity)?.amount ?? 0.0
                          ),
                        0
                      ) ?? 0.0);
                    q.subtotalPrice = Number(q.totalCosts ?? 0.0) + Number(q.markupAmount ?? 0.0);
                    q.totalPrice = q.subtotalPrice + Number(q.taxAmount ?? 0.0);
                  }

                  return q;
                });
              }

              return updatedItem;
            });

            onChange(updatedEstimate as any);
          }
        }
      },
    },
  });
  const toast = useToast();
  return (
    <div>
      <TableContainer overflow="unset">
        <ChakraTable size="sm" style={{ tableLayout: 'fixed' }}>
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  const placeholder = '';
                  // header.column.id
                  // console.log('header.column.id', header.column.id)
                  return (
                    <Th
                      // width={'1'}
                      key={header.id}
                      colSpan={header.column.id === 'Quantities' ? header.colSpan : undefined}
                      w={header.column.id === 'Quantities' ? 'auto' : '100px'}
                      style={{ textAlign: 'right' }}
                      bgColor={'gray.100'}
                    >
                      {header.isPlaceholder ? null : (
                        <div>{flexRender(header.column.columnDef.header, header.getContext())}</div>
                      )}
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.map((row) => {
              const placeholder = '';
              return (
                <Tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    let rowSpan;
                    let colSpan;
                    let note;

                    if (cell.column.id === 'vendor') {
                      note = rfq?.responses?.reduce((acc, cur) => {
                        // console.log('r', r)

                        if ((cur?.vendor as any)?._id === row.original.vendor._id) {
                          acc =
                            cur?.items?.find((i) => rfqItem?._id === i.item && i?.notes)?.notes ??
                            '';
                        }

                        return acc;
                      }, '');

                      rowSpan = cell.row.original.vendor.rowSpan;
                    }

                    if (cell.column.id === 'vendor' && !rowSpan) {
                      return null;
                    }

                    if (
                      cell.column.id === 'description' &&
                      cell.row.original.description?.startsWith('Component') &&
                      !cell.row.original.componentRowSpan
                    ) {
                      return null;
                    }

                    if (
                      cell.column.id === 'description' &&
                      cell.row.original.descriptionColumnSpan
                    ) {
                      colSpan = cell.row.original.descriptionColumnSpan;
                    }

                    if (
                      cell.column.id === 'description' &&
                      cell.row.original.componentRowSpan &&
                      cell.row.original.description.startsWith('Component')
                    ) {
                      rowSpan = cell.row.original.componentRowSpan;
                    }

                    let maxWidth;
                    if ((cell.column.columnDef.meta as any)?.quantity) {
                      maxWidth = '100px';
                    }

                    if (cell.column.id === 'vendor' || cell.column.id === 'description') {
                      maxWidth = '75px';
                    }

                    const toastId = cell.row.original.vendor._id;

                    const isCellVendor = cell.column.id === 'vendor';

                    let cellNote;

                    if (isCellVendor && !isReadOnly) {
                      cellNote = note ? (
                        <Tooltip label="Supplier's note">
                          <IconButton
                            variant="outline"
                            colorScheme="blue"
                            aria-label="Supplier's note"
                            size="xs"
                            mr="1"
                            icon={<BiNotepad />}
                            onClick={() => {
                              if (!toast.isActive(toastId)) {
                                toast({
                                  id: toastId,
                                  title: `${cell.row.original.vendor?.name ?? ''}'s Note`,
                                  description: note,
                                  status: 'info',
                                  duration: 10000,
                                  isClosable: true,
                                  icon: <BiNotepad />,
                                });
                              }
                            }}
                          />
                        </Tooltip>
                      ) : null;
                    }

                    return (
                      <Td
                        bgColor={cell.row.original.bgColor}
                        fontStyle={cell.row.original.fontStyle}
                        fontWeight={cell.row.original.fontWeight}
                        key={cell.id}
                        rowSpan={rowSpan}
                        colSpan={colSpan}
                        style={{
                          padding: 0,
                          background: cell.row.original.bgColor,
                          paddingTop: cell.row.original.paddingTop ?? 0,
                          paddingBottom: cell.row.original.paddingBottom ?? 0,
                          borderTop: cell.row.original.firstVendorRow
                            ? '1px solid #CBD5E0'
                            : undefined,
                          borderBottom: cell.row.original.lastVendorRow
                            ? '2px solid #CBD5E0'
                            : undefined,
                          maxWidth,
                        }}
                        // style={{ backgroundColor: isLowest(cell.row.original.vendor._id, itemLookup?._id as string, q.quantity as number, rfq) ? '#90ee90' : 'white' }}
                      >
                        <Box>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Box>
                        <Flex>{cellNote}</Flex>
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </ChakraTable>
      </TableContainer>
    </div>
  );
}
