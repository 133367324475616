/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  FormHelperText,
  Heading,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Switch,
  useDisclosure,
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Tag,
  Text,
  Checkbox,
  FormErrorMessage,
} from '@chakra-ui/react';
import { AsyncSelect, CreatableSelect, SingleValue } from 'chakra-react-select';
import { ActionMeta, OnChangeValue } from 'react-select';
import Cleave from 'cleave.js/react';
import { useHistory } from 'react-router-dom';
import { icapi } from '../../../../bootstrap/feathers';
import BackorderTable from './BackorderTable';
import { Feathers } from '../../../../bootstrap/feathers/feathers';
import { InputWrapper, validateEmail } from '../../../../common';

interface IReceiveModalProps {}

type ProductLocationType = {
  id: string;
  locationName: string;
  nonTrackable: boolean;
  priority: number;
  total: number;
};

const components = {
  DropdownIndicator: null,
};

const ReceiveModal: React.FunctionComponent<IReceiveModalProps> = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure({ isOpen: true });
  const [isLoading, setIsLoading] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<
    SingleValue<
      | {
          value: string;
          label: string;
          allocatedCount: number;
          backorderCount: number;
          inventoryCount: number;
        }
      | undefined
    >
  >();
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [price, setPrice] = useState(0);
  const [amount, setAmount] = useState('');
  const [isReceiveAdvanced, setIsReceiveAdvanced] = useState(false);
  const [orderItems, setOrderItems] = useState([]);
  const [selected, setSelected] = useState({});
  const [productLocations, setProductLocations] = useState<ProductLocationType[]>([]);
  const [filteredProdLocations, setFilteredProdLocations] = useState<ProductLocationType[]>([]);
  const [sendEmail, setSendEmail] = useState(false);
  const [email, setEmail] = useState('');

  const [emailInput, setEmailInput] = useState('');
  const [emailErrorMessage, setEmailErrorMessage] = useState('');

  const [emails, setEmails] = useState<string[]>([]);

  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const history = useHistory();

  useEffect(() => {
    const loadLocations = async () => {
      try {
        const res = await Feathers.service('inventory/operations').create({
          details: {
            productId: selectedProduct?.value,
          },

          operation: 'PRODUCT_TOTAL_STOCK_PER_LOCATION',
        });

        setProductLocations(res.productLocations);
        setFilteredProdLocations(res.productLocations);
      } catch (e) {
        console.error(e);
      }
    };

    if (selectedProduct?.value) {
      loadLocations();
    }
  }, [selectedProduct?.value]);

  const handleLoadLocations = async (inputValue) => {
    const { data } = await (icapi.service('inventory/locations') as any).find({
      query: {
        $select: ['name'],
        name: { $LIKE: inputValue },
        $sort: { name: 1 },
      },
    });

    return data.map((r) => ({ value: r._id, label: r.name }));
  };

  const handleLoadProducts = async (inputValue) => {
    const { data } = await (icapi.service('products-legacy') as any).find({
      query: {
        $select: ['key', 'staticName', 'allocatedCount', 'backorderCount', 'inventoryCount'],
        key: { $LIKE: inputValue },
        inventory: true,
        $sort: { key: 1 },
      },
    });

    return data.map((r) => ({
      value: r._id,
      label: `${r.key} ${r.staticName}`,
      allocatedCount: r.allocatedCount,
      backorderCount: r.backorderCount,
      inventoryCount: r.inventoryCount,
    }));
  };

  const handleLoadBackOrdered = async () => {
    if (!selectedProduct?.value) {
      return;
    }

    const { data } = await (icapi.service('order-items') as any).find({
      query: {
        $select: ['quantity', 'createdAt', 'humanId'],
        product: selectedProduct?.value,
        'productionStatus.jobStatus': 'BACK_ORDER',
        $sort: { createdAt: 1 },
        $limit: 100,
      },
    });

    setOrderItems(data);
  };

  const handleSubmit = async () => {
    const payload = {
      product: (selectedProduct as any)?.value,
      operation: 'RECEIVE',
      amount,
      price,
      orderItemIds: Object.keys(selected)
        .filter((x) => selected[x])
        .map((k) => k),
      tags: [],
      destination: (selectedLocation as any)?.value,
      notes: '',
      sendEmail,
      email,
      emails,
    };

    // console.log('submitting', payload);

    try {
      setHasSubmitted(false);
      setErrorMessage('');
      setIsLoading(true);
      const response = await (icapi.service('inventory') as any).create(payload);
      setAmount('');
      setSelectedProduct(null);
      setSelectedLocation(null);
      setIsReceiveAdvanced(false);
      setSelected({});
      setIsLoading(false);
      setHasSubmitted(true);
      setSendEmail(false);
      setEmail('');
      setPrice(0);
    } catch (error) {
      setErrorMessage(error.message);
      setIsLoading(false);
    }
  };

  const handleClose = async () => {
    onClose();
    history.push('/');
  };

  useEffect(() => {
    if (isReceiveAdvanced) {
      handleLoadBackOrdered();
    }
  }, [isReceiveAdvanced]);

  const totalSelected = Object.keys(selected)
    .filter((k) => selected[k])
    .reduce((acc, cur) => {
      const foundItem: any = orderItems.find((oi: any) => oi._id === cur);
      if (foundItem) {
        return acc + foundItem.quantity;
      }

      return acc;
    }, 0);

  // console.log('selectedProduct', selectedProduct);

  const handleFilterLocations = (toggle: boolean) => {
    const filtered = productLocations?.filter((p) => (toggle ? p.total > 0 : p));

    setFilteredProdLocations(filtered);
  };

  const handleChange = (newValue: OnChangeValue, actionMeta: ActionMeta) => {
    if (actionMeta.action === 'clear' || !newValue) {
      setEmails([]);
    }
    setEmails(newValue?.map((v) => v.value));
  };
  const handleKeyDown = (event) => {
    setEmailErrorMessage('');

    if (event.key === 'Enter' || event.key === 'Tab') {
      const isDuplicate = emails?.includes(emailInput);
      const isInvalidEmail = !validateEmail(emailInput);

      // console.log({ isInvalidEmail, emailInput });

      if (isDuplicate || isInvalidEmail) {
        setEmailErrorMessage(
          `${isDuplicate ? "You can't enter a duplicate value" : 'Enter a valid email'}`
        );
        return;
      }

      setEmails([...emails, emailInput]);
      setEmailInput('');
      event.preventDefault();
    }
  };
  const handleInputChange = (v: string, actionMeta: ActionMeta) => {
    setErrorMessage('');

    if (actionMeta.action === 'menu-close') return;

    // const isInvalidEmail = !validateEmail(emailInput);

    // console.log({ isInvalidEmail, emailInput, v });

    // if (isInvalidEmail || emailInput !== '') {
    //   setErrorMessage('Enter a valid quantity');

    //   return;
    // }

    setEmailInput(v);
  };

  const canSubmit =
    !!amount &&
    !!selectedProduct &&
    !!selectedLocation &&
    (!isReceiveAdvanced || (!!isReceiveAdvanced && totalSelected > 0));

  return (
    <>
      <Modal isOpen={isOpen} onClose={handleClose} size={'2xl'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Inventory Receive</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {hasSubmitted && (
              <Alert status="success" mb={8}>
                <AlertIcon />
                Inventory has been received
              </Alert>
            )}

            {errorMessage && (
              <Alert status="error" mb={8}>
                <AlertIcon />
                {errorMessage}
              </Alert>
            )}
            <form>
              <FormControl isRequired>
                <FormLabel>SKU</FormLabel>
                <AsyncSelect
                  useBasicStyles
                  cacheOptions
                  defaultOptions
                  loadOptions={handleLoadProducts}
                  onChange={setSelectedProduct}
                  placeholder=""
                  value={selectedProduct}
                />
                <FormHelperText>Enter or barcode scan the SKU</FormHelperText>
              </FormControl>{' '}
              {selectedProduct && (
                <Tabs isFitted variant="enclosed" mt={3}>
                  <TabList>
                    <Tab>Counts</Tab>
                    <Tab>Locations</Tab>
                  </TabList>
                  <TabPanels borderWidth="1px">
                    <TabPanel>
                      <Table size="sm" mt={3}>
                        <Thead>
                          <Tr>
                            <Th colSpan={2}>Counts</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          <Tr>
                            <Td>Physical</Td>
                            <Td>
                              {selectedProduct?.inventoryCount ??
                                0 + selectedProduct?.allocatedCount ??
                                0}
                            </Td>
                          </Tr>
                          <Tr>
                            <Td>Available</Td>
                            <Td>{selectedProduct?.inventoryCount ?? 0}</Td>
                          </Tr>
                          <Tr>
                            <Td>Allocated</Td>
                            <Td>{selectedProduct?.allocatedCount ?? 0}</Td>
                          </Tr>
                          <Tr>
                            <Td>Backorder</Td>
                            <Td>{selectedProduct?.backorderCount ?? 0}</Td>
                          </Tr>
                        </Tbody>
                      </Table>
                    </TabPanel>
                    <TabPanel>
                      {productLocations?.length > 0 && (
                        <Box p={3} mb={3} bg="white" borderRadius="md">
                          <Box mb={3} justifyContent="space-between">
                            <FormControl display="flex" alignItems="center">
                              <Switch
                                mr={2}
                                id="filterLocation"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                  handleFilterLocations(e.target.checked)
                                }
                              />
                              <FormLabel htmlFor="filterLocation" mb="0">
                                Hide Locations with zero count?
                              </FormLabel>
                            </FormControl>
                          </Box>
                          <TableContainer>
                            <Table variant="simple" size="sm">
                              <Thead>
                                <Tr>
                                  <Th w="50%">Location</Th>
                                  <Th w="50%">Count</Th>
                                </Tr>
                              </Thead>
                              <Tbody>
                                {filteredProdLocations?.map((i) => (
                                  <Tr key={i?.id}>
                                    <Td>{i.locationName}</Td>
                                    <Td>
                                      <Tag size={'small'}>{i.total}</Tag>
                                    </Td>
                                  </Tr>
                                ))}
                              </Tbody>
                            </Table>
                          </TableContainer>
                        </Box>
                      )}
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              )}
              <FormControl pt={4} size={'xl'}>
                <FormLabel>Price</FormLabel>

                <InputWrapper height="var(--chakra-sizes-9)">
                  <Cleave
                    style={{ textAlign: 'right' }}
                    className={'form-control-cleave'}
                    value={price}
                    onChange={(e) => setPrice(Number(e.target.rawValue) || 0)}
                    options={{
                      numeral: true,
                      rawValueTrimPrefix: true,
                      numeralPositiveOnly: true,
                      numeralThousandsGroupStyle: 'thousand',
                      prefix: '$',
                    }}
                  />
                </InputWrapper>
              </FormControl>
              <FormControl pt={4} size={'xl'}>
                <FormLabel>Amount</FormLabel>
                <NumberInput max={100000} min={1} value={amount} onChange={(v) => setAmount(v)}>
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </FormControl>
              <FormControl isRequired pt={4}>
                <FormLabel>Location</FormLabel>
                <AsyncSelect
                  useBasicStyles
                  cacheOptions
                  defaultOptions
                  placeholder=""
                  loadOptions={handleLoadLocations}
                  onChange={setSelectedLocation}
                  value={selectedLocation}
                />
                {/* <FormHelperText>Enter or barcode scan the SKU</FormHelperText> */}
              </FormControl>
              <FormControl display="flex" alignItems="center" pt={4}>
                <FormLabel htmlFor="email-alerts" mb="0">
                  Allocate to Specific Orders?
                </FormLabel>
                <Switch
                  id="email-alerts"
                  isChecked={isReceiveAdvanced}
                  onChange={(e) => setIsReceiveAdvanced(e.target.checked)}
                />
              </FormControl>
              {isReceiveAdvanced && (
                <Box padding={4} mt={4}>
                  <Divider mt={4} mb={4} />
                  <Heading as="h4" size="md">
                    Back Orders
                  </Heading>

                  <BackorderTable
                    orderItems={orderItems}
                    selected={selected}
                    setSelected={setSelected}
                  />

                  <div>
                    <span>Total Selected: {totalSelected}</span>
                  </div>
                </Box>
              )}
              <FormControl
                display="flex"
                alignItems="center"
                pt={4}
                isInvalid={!!emailErrorMessage}
              >
                <Checkbox isChecked={sendEmail} onChange={(e) => setSendEmail(e.target.checked)}>
                  Send email notification
                </Checkbox>
                {sendEmail && (
                  <>
                    <Box w="100%">
                      <CreatableSelect
                        components={components}
                        isClearable
                        isMulti
                        menuIsOpen={false}
                        inputValue={emailInput}
                        onChange={handleChange}
                        onInputChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        placeholder=""
                        value={emails?.map((v) => ({ value: v, label: v }))}
                      />

                      {emailErrorMessage && (
                        <FormErrorMessage>{emailErrorMessage}</FormErrorMessage>
                      )}
                    </Box>

                  </>
                  // <Input
                  //   placeholder="Email Address"
                  //   value={email}
                  //   onChange={(e) => setEmail(e.target.value)}
                  // />
                )}
              </FormControl>

              {sendEmail && (
                <Text fontSize="sm" color="gray.500">
                    Enter in one or more email addresses. To enter in multiple emails, hit enter after each email address
                  </Text>
              )}

            </form>
          </ModalBody>

          <ModalFooter>
            <Button
              isDisabled={!canSubmit}
              colorScheme="blue"
              mr={3}
              onClick={handleSubmit}
              isLoading={isLoading}
            >
              Receive Inventory
            </Button>
            <Button onClick={handleClose} variant="ghost">
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ReceiveModal;
