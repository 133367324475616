import * as React from 'react';
import * as Cleave from 'cleave.js/react';
import * as numeral from 'numeral';

import { Modal, Form, Table, Button, Message, Dimmer, Loader, Segment } from 'semantic-ui-react';
import { PreLoader } from '@inkcloud/components';
import { Feathers } from '../../../bootstrap/feathers/feathers';

import { CartDetailTable } from '../../../common/CartDetailTable';

interface PriceModalProps {
  cartId: string;
  item: any;
  isOpen: boolean;
  addOnsBase: any;
  onClose: () => void;
  onRefresh: () => void;
}

const { useState } = React;

export const PriceModal: React.FunctionComponent<PriceModalProps> = (props) => {
  const { cartId, item, isOpen, onClose, addOnsBase, onRefresh } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [value, setValue] = useState(item);
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (e) => {
    setValue({ ...value, ...e });
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      await Feathers.service('cart/set-item-custom-price').create({
        cartId,
        itemId: item._id,
        price: Number((value as any).price) || item.price,
        designPrice: Number((value as any).designPrice) || item.designPrice,
      });
      onRefresh();
      setIsSubmitting(false);
      onClose();
    } catch (e) {
      setIsSubmitting(false);
      e.code && e.code < 500
        ? setErrorMessage(e.message)
        : setErrorMessage('We are experiencing technical difficulties. Please try again');
    }
  };

  if (!item) {
    return <PreLoader />;
  }

  return (
    <Form loading={isSubmitting} error={!!errorMessage}>
      <Modal size="small" open={isOpen} onClose={onClose} closeIcon>
        <Modal.Header>Overrides Price</Modal.Header>
        <Modal.Content>
          <Table size="small">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Attributes</Table.HeaderCell>
                <Table.HeaderCell>Design Price</Table.HeaderCell>
                <Table.HeaderCell>Price</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  <CartDetailTable
                    item={item}
                    // attributes={item.attributes}
                    // isCustomTrim={item.sCustomTrim}
                    // customTrimWidth={item.customTrimWidth}
                    // customTrimHeight={item.customTrimHeight}
                    // quantity={item.quantity}
                    addOns={item.addOns}
                    addOnsBase={addOnsBase}
                  />
                </Table.Cell>
                <Table.Cell width={4}>
                  <div className={'ui tiny input fluid'}>
                    <Cleave
                      style={{ textAlign: 'right' }}
                      value={value.designPrice}
                      onChange={(e) => handleChange({ designPrice: e.target.rawValue })}
                      options={{
                        numeral: true,
                        rawValueTrimPrefix: true,
                        numeralPositiveOnly: true,
                        numeralThousandsGroupStyle: 'thousand',
                        prefix: '$',
                      }}
                    />
                  </div>
                </Table.Cell>
                <Table.Cell width={4}>
                  <div className={'ui tiny input fluid'}>
                    <Cleave
                      style={{ textAlign: 'right' }}
                      value={value.price}
                      onChange={(e) => handleChange({ price: e.target.rawValue })}
                      options={{
                        numeral: true,
                        rawValueTrimPrefix: true,
                        numeralPositiveOnly: true,
                        numeralThousandsGroupStyle: 'thousand',
                        prefix: '$',
                      }}
                    />
                  </div>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          {errorMessage && <Message error content={errorMessage} />}
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            size="tiny"
            content="Save"
            onClick={handleSubmit}
            loading={isSubmitting}
            disabled={isSubmitting}
          />
        </Modal.Actions>
      </Modal>
    </Form>
  );
};
