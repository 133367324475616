import * as React from 'react';
import {
  ChakraComponent,
  Button,
  ButtonProps,
  BoxProps,
  Box,
  Flex,
  Heading,
  Spacer,
  Text,
} from '@chakra-ui/react';

// TODO: This is a workaround for
type DivComponent = ChakraComponent<'div', {}>;

interface ISegmentCardProps {
  title: string;
  subtitle?: string;
  actionButtonLabel?: string;
  actionButtonClick?: () => void;
  children?: React.ReactNode;
  bgColor?: ButtonProps['bgColor'];
  borderColor?: ButtonProps['borderColor'];
  h?: BoxProps['h'];
}

const SegmentCard: React.FunctionComponent<ISegmentCardProps> = (props) => {
  const {
    children,
    title,
    subtitle,
    actionButtonLabel,
    actionButtonClick,
    bgColor = 'gray.100',
    borderColor = 'gray.300',
  } = props;

  return (
    <Box flex={1} borderWidth={1} borderColor={borderColor} h={props?.h ?? 'unset'}>
      <Flex flex={1} align={'center'} py={4} px={6} bgColor={bgColor}>
        <Box>
          <Heading size="sm">{title}</Heading>
          <Text fontSize={'sm'}>{subtitle}</Text>
        </Box>
        <Spacer flex={1} />
        {actionButtonLabel && actionButtonClick && (
          <Button size={'xs'} colorScheme={'blue'} onClick={actionButtonClick}>
            {actionButtonLabel}
          </Button>
        )}
      </Flex>
      <Box p={6} fontSize={'sm'}>
        {children}
      </Box>
    </Box>
  );
};

export default SegmentCard;
