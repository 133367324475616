import * as React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Table, Button, Header, Segment, Label, Icon } from 'semantic-ui-react';
import { Box, Heading } from '@chakra-ui/react';
import {
  PageHeaderRow,
  PaginatorWrapper,
  useList,
  EmptyWrapper,
  GenericFilter,
  basicQueryMap,
} from '@inkcloud/components';
import type { ModelTypes } from '@inkcloud/icapi-types';

import Feathers from '../../../../bootstrap/feathers';

import { MicrositePublishModal } from './MicrositePublishModal';
import { getFilterDefinition } from './FilterForm2';

interface ListProps {}

const filterQueryMap = (values) => {
  let filterRows = basicQueryMap(values, {
    name: 'staticName',
  });

  // Map attributes as needed by API
  const prefix = 'attributes:';
  const attributes = Object.keys(filterRows)
    .filter((r) => r.startsWith(prefix))
    .reduce((acc, cur) => ({ ...acc, [cur.slice(prefix.length)]: filterRows[cur]?.$in }), {});

  // Delete the original attribute valuess so they are not submitted
  Object.keys(filterRows)
    .filter((r) => r.startsWith(prefix))
    .forEach((r) => delete filterRows[r]);

  const stockTypes = ['stock', 'stock-parent', 'stock-child'];
  filterRows = {
    ...filterRows,
    addOns: filterRows.addOns
      ? { $elemMatch: { id: filterRows.addOns, isEnabled: true } }
      : undefined,
    attributes: attributes,
    hasVariants:
      filterRows.type === 'stock-parent'
        ? true
        : filterRows.type === 'stock-child'
        ? { $ne: true }
        : undefined,
    inventory: stockTypes.includes(filterRows.type)
      ? true
      : filterRows.type === 'on-demand'
      ? { $ne: true }
      : undefined,
    isComposite: filterRows.type === 'composite' ? true : undefined,
    // parent: filterRows.type === 'stock-child' ? true : filterRows.type === 'stock-parent' ? { $ne: true } : undefined,
    type: undefined,
  };

  return filterRows;
};

const serviceName = 'products-legacy';
const pathPrefix = '/products/stock-products';

const { useState, useEffect } = React;

export const List: React.FunctionComponent<ListProps & RouteComponentProps<any>> = (props) => {
  const {
    match: { params },
    location,
    history,
  } = props;
  const {
    state,
    reload,
    handleDelete,
    selectedItems,
    clearAll,
    setChecked,
    someSelected,
    toggleAll,
    filter,
    setFilter,
    sort,
    handleSort,
  } = useList({
    feathers: [
      'find',
      serviceName,
      [
        {
          query: {
            $populate: ['parent', 'category'],
            inventory: true,
          },
        },
      ],
    ],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  const [tempFilter, setTempFilter] = useState({ rows: [] });

  const [microsites, setMicrosites] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState<NonNullable<ModelTypes.ProductLegacy>>();
  const [micrositePublishModalOpen, setMicrositePublishModalOpen] = useState(false);

  useEffect(() => {
    const loadMicrosites = async () => {
      const res = await Feathers.service('/microsites').find({ query: { $np: 1 } });
      setMicrosites(res);
    };
    loadMicrosites();
  }, []);

  const { queryResult, isLoading: isStateLoading } = state as any;

  if (!queryResult) {
    return null;
  }

  return (
    <div>
      <PageHeaderRow subheader="" header="Stock Products">
        <Link to="/products/variant-types">
          <Button content="Variant Types" primary size={'mini'} />
        </Link>
        <Link to="/products/variant-values">
          <Button content="Variant Values" primary size={'mini'} />
        </Link>
        <Link to="/products/stock-products/add">
          <Button content="New Stock Product" primary size={'mini'} />
        </Link>
      </PageHeaderRow>

      <Box borderRadius="md" mb={4} borderWidth="1px" bg="white">
        <Box py={2} px={3} mb={5} bg="gray.100">
          <Heading as="h4" size="md" fontWeight="medium">
            Filter by the following
          </Heading>
        </Box>
        <Box py={3} px={5} mb={3}>
          <GenericFilter
            factDefinitionFunction={getFilterDefinition}
            value={filter}
            onChange={(v) => {
              setFilter({ ...v });
            }}
          />
        </Box>
      </Box>
      {/* <Header as="h5" attached="top" block>
        Filter by the following
      </Header>
      <Segment attached>
        <GenericFilter
          factDefinitionFunction={getFilterDefinition}
          value={filter}
          onChange={(v) => {
            setFilter({ ...v })
          }}
        />
      </Segment> */}

      {/* <div>{JSON.stringify(tempFilter)}</div> */}
      {/* <FilterForm value={filter} onChange={(v) => setFilter(v)} onReset={() => setFilter({})} /> */}
      <EmptyWrapper queryResult={queryResult} isStateLoading={isStateLoading}>
        <Segment>
          <PaginatorWrapper.Top
            filter={filter}
            reload={reload}
            data={queryResult as any}
            pathPrefix={`${pathPrefix}`}
          />
          <Table size="small">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  <input type="checkbox" onChange={toggleAll} />
                </Table.HeaderCell>
                <Table.HeaderCell>Key/Category</Table.HeaderCell>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Publish</Table.HeaderCell>
                <Table.HeaderCell>Image</Table.HeaderCell>
                <Table.HeaderCell>Inventory</Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {queryResult.data.length > 0 &&
                queryResult.data.map((r: any, index: number) => {
                  let thumbUrl = null;
                  if (r.images && r.images.length) {
                    const url = r.images[0].url;
                    const arr = url.split('/');
                    thumbUrl = `https://storage.googleapis.com/icstorage/${arr[0]}/thumbs/${
                      arr[2] ? arr[2] : arr[1]
                    }`;
                  }

                  return (
                    <Table.Row key={index} verticalAlign="top">
                      <Table.Cell>
                        <input
                          type="checkbox"
                          value={r._id}
                          checked={selectedItems[r._id] || false}
                          onChange={setChecked}
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <div>
                          {r.key}{' '}
                          {r.version && r.version > 1 && (
                            <Label
                              size="mini"
                              detail={r.version}
                              content={'Ver'}
                              color="green"
                            ></Label>
                          )}
                        </div>
                        <div>
                          <strong>
                            {r.category && (r.category.internalName || r.category.name)}
                          </strong>
                        </div>
                      </Table.Cell>
                      <Table.Cell>
                        {r.staticName || r.description}{' '}
                        {r.isHidden && (
                          <Label size={'mini'} color={'black'}>
                            Hidden
                          </Label>
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        {r.publishedToMicrosites?.map((pub: any, idx: number) => (
                          <div key={idx}>
                            <Icon name="check circle outline" /> {pub.micrositeName}
                          </div>
                        )) ?? null}
                      </Table.Cell>
                      <Table.Cell>{thumbUrl && <img src={thumbUrl} />}</Table.Cell>
                      <Table.Cell>
                        <Label size="mini" color="teal" content={r.inventoryCount} />
                        <Label size="mini" color="teal" as="a">
                          ${r.inventoryPrice}
                        </Label>
                      </Table.Cell>
                      <Table.Cell>
                        <Label content={r.inventory ? 'S' : 'OD'} size="small" basic />
                        {r.inventory && (
                          <Link to={`/products/${r._id}/inventory-ledger`}>
                            <Button icon={'warehouse'} size={'mini'} />{' '}
                          </Link>
                        )}

                        {microsites && microsites.length > 0 && (
                          <Button
                            icon="external alternate"
                            size={'mini'}
                            onClick={() => {
                              setSelectedProduct(r);
                              setMicrositePublishModalOpen(true);
                            }}
                          />
                        )}

                        <Link to={`/products/stock-products/edit/${r._id}`}>
                          <Button icon="edit" primary size={'mini'} />
                        </Link>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
            </Table.Body>
          </Table>
          <PaginatorWrapper.Bottom
            reload={reload}
            data={queryResult as any}
            pathPrefix={`${pathPrefix}`}
          >
            {someSelected && (
              <Button primary type="button" content="Delete" onClick={handleDelete} />
            )}
          </PaginatorWrapper.Bottom>
        </Segment>
        {micrositePublishModalOpen && (
          <MicrositePublishModal
            handleClose={() => {
              setMicrositePublishModalOpen(false);
              setSelectedProduct({});
              reload();
            }}
            handleSubmit={() => null}
            selectedProduct={selectedProduct as NonNullable<ModelTypes.ProductLegacy>}
            // productId={selectedProduct._id}
            // productKey={selectedProduct.key}
            // staticName={selectedProduct.staticName}
            microsites={microsites}
          />
        )}
      </EmptyWrapper>
    </div>
  );
};
