import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Segment, Table } from 'semantic-ui-react';
import { PageHeaderRow, PreLoader, useFeathers } from '@inkcloud/components';
import feathers from '../../../bootstrap/feathers';
import { GroupForm } from './GroupForm';

interface DetailProps {
  products: { value: string; text: string }[];
  categories: { value: string; text: string }[];
}

const serviceName = 'microsites/customer/groups';
const service = feathers.service(serviceName);
const commonParams = { query: {} };

const { useState } = React;

export const Detail: React.FunctionComponent<
  DetailProps & RouteComponentProps<{ id?: string; micrositeId: string }>
> = (props) => {
  const {
    match: {
      params: { id, micrositeId },
    },
    history,
  } = props;

  const pathPrefix = `/settings/microsites/${micrositeId}/groups`;

  const [errorMessage, setErrorMessage] = useState('');

  const isEdit = !!id;
  const [state, reload] = useFeathers('get', serviceName, [id, commonParams], true);
  const handleSubmit = async (values) => {
    try {
      values.allowedShippingServicesOnCart =
        values?.allowedShippingServicesOnCart?.map((s) => s.value) ?? [];

      if (isEdit) {
        await service.patch(id, values, commonParams);
      } else {
        await service.create(values, commonParams);
      }
      history.push(pathPrefix);
    } catch (e) {
      const errMsg =
        e?.code < 500 ? e.message : 'We are experiencing technical difficulties. Please try again';
      setErrorMessage(errMsg);
    }
  };

  const pageTitle = isEdit ? 'Edit' : 'New';

  if (isEdit && !state.data) {
    return <PreLoader />;
  }

  const initialData = { ...state.data, microsite: micrositeId };
  return (
    <div>
      <PageHeaderRow header={`${pageTitle} Groups`} subheader="Microsite" subheaderId={''}>
        <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
      </PageHeaderRow>

      {/* <DndProvider backend={HTML5Backend}>
        <TestContainer />
      </DndProvider> */}
      {(!isEdit || state.data) && (
        <GroupForm
          initialValues={initialData}
          micrositeId={micrositeId}
          onSubmit={handleSubmit}
          errorMessage={errorMessage}
        />
      )}
    </div>
  );
};
