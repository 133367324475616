import * as React from 'react';
import { Button, Input, Dropdown, Segment, Form, Checkbox } from 'semantic-ui-react';
import Feathers from '../../../bootstrap/feathers';

const { useState, useEffect } = React;

export interface FilterFormProps {
  value: any;
  onChange: Function;
  onReset: Function;
  microsite: string;
}

function initMapper(value) {
  const defaults = {
    name: '',
    sku: '',
    category: [],
    groupPermissions: [],
  };

  return Object.keys(defaults).reduce((p, cur) => {
    if (!p[cur]) {
      p[cur] = defaults[cur];
    }
    return p;
  }, value);
}

export function FilterForm(props: FilterFormProps) {
  const { value: preInitValue } = props;
  const [categories, setCategories] = useState([]);
  const [groups, setGroups] = useState([]);
  const [isSetup, setIsSetup] = useState(false);

  const value = initMapper(preInitValue);

  const onChange = (field, value) => {
    props.onChange && props.onChange({ ...props.value, [field]: value });
  };

  // const debouncedOnChange = debounce((field, value) => onChange(field, value), 200);

  const loadCategories = async () => {
    if (!isSetup) {
      const [categories, groups] = await Promise.all([
        Feathers.service('/products-legacy/categories').find({
          query: {
            $np: 1,
            $select: ['_id', 'name'],
            $sort: { name: 1 },
          },
        }) as any,
        Feathers.service('/microsites/customer/groups').find({
          query: {
            $np: 1,
            $select: ['_id', 'name'],
            $sort: { name: 1 },
            microsite: props.microsite,
          },
        }) as any,
      ]);

      // const categories = await Feathers.service('/products-legacy/categories').find({
      //   query: {
      //     $np: 1, $select: ['_id', 'name'], $sort: { name: 1 }
      //   }
      // }) as any[];

      // const groups = await Feathers.service('/microsites/customer/groups').find({
      //   query: {
      //     $np: 1, $select: ['_id', 'name'], $sort: { name: 1 }, microsite: props.microsite,
      //   }
      // }) as any[];

      setCategories(categories.map((c) => ({ value: c._id, text: c.name })));
      setGroups(groups.map((c) => ({ value: c._id, text: c.name })));
      setIsSetup(true);
    }
  };

  useEffect(() => {
    loadCategories();
  }, []);

  if (!isSetup) return null;
  return (
    <Segment>
      <Form size={'small'}>
        <Form.Group widths={'equal'}>
          <Form.Field>
            <Input
              placeholder="Name..."
              value={value.name}
              onChange={(e, data) => onChange('name', data.value)}
            />
          </Form.Field>
          <Form.Field>
            <Form.Input
              placeholder="SKU..."
              value={value.key}
              onChange={(e, data) => onChange('key', data.value)}
            />
          </Form.Field>
          <Form.Field>
            <Dropdown
              value={value.category}
              onChange={(e, data) => onChange('category', data.value)}
              options={categories}
              multiple
              placeholder={'Categories...'}
              selection
              search
            />
          </Form.Field>
        </Form.Group>
        <Form.Field>
          <Dropdown
            value={value.groupPermissions}
            onChange={(e, data) => onChange('groupPermissions', data.value)}
            options={groups}
            multiple
            placeholder={'Groups...'}
            selection
            search
          />
        </Form.Field>

        <Button content="Reset Form" onClick={() => props.onReset()} />
      </Form>
    </Segment>
  );
}
