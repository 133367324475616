// import * as React from 'react';
// import { Link, Switch, Route, RouteComponentProps } from 'react-router-dom';
// import { DetailWrapper, DetailWrappedProps } from './../../bootstrap/DetailWrapper';
// import { FormattedDate, FormattedNumber, FormattedRelative } from 'react-intl';
// import { Header, Segment, Table, Button } from 'semantic-ui-react'
// import { PortalProductsListComponent } from './MicrositeProductsListComponent';
// import { PageHeaderRow, PreLoader } from '@inkcloud/components';

// const infoRowStyle = { paddingTop: 2, paddingBottom: 2 };

// const renderInfo = (label, text) => (
//   <div className="ui two column grid">
//     <div className="row" style={infoRowStyle}>
//       <div className="six wide column"><label>{label}</label></div>
//       <div className="ten wide column">{text}</div>
//     </div>
//   </div>
// );

// type PortalDetailProps = DetailWrappedProps & RouteComponentProps<any>;

// interface DetailState { }

// class PortalDetailComponent extends React.Component<PortalDetailProps, DetailState> {
//   productList = null;

//   componentDidMount() {
//     if (this.props.match && this.props.match.params && this.props.match.params.id) {
//       this.loadProducts();
//     }
//   }

//   componentDidUpdate(prevProps: PortalDetailProps, prevState: PortalDetailProps) {
//     if (this.props.match) {
//       if (prevProps.match.params.id !== this.props.match.params.id) {
//         this.loadProducts();
//       }
//     }
//   }

//   loadProducts() {
//     this.productList = PortalProductsListComponent({
//       query: {
//         microsite: this.props.match.params.id,
//         $populate: ['product', 'category'],
//         $sort: { createdAt: -1 },
//         $limit: 25,
//       },
//       filterMapper: (filterIn) => {
//         const filterOut = Object.assign({}, filterIn);
//         if (filterOut.name) {
//           filterOut.name = { $like: filterOut.name };
//           delete filterOut.name;
//         }
//         return filterOut;
//       }
//     })
//   }

//   render() {
//     if (this.props.data === null) {
//       return <PreLoader />;
//     }

//     const { data } = this.props;
//     const { customer } = data;

//     let customerName = '';
//     if (customer && customer.organizationName) {
//       customerName = customer.organizationName;
//     } else if (customer && customer.primaryContact) {
//       const { primaryContact } = customer;
//       customerName = `${primaryContact.firstName} ${primaryContact.lastName}`
//     }
//     return (
//       <div>
//         <PageHeaderRow header={this.props.data.name} subheader='Microsite' subheaderId={this.props.data.humanId}>
//           <Link to={`/settings/microsites`}><Button content="Back" size={'mini'} /></Link>
//           <Link to={`/settings/microsites/${this.props.data._id}/edit`}><Button primary content="Edit" size={'mini'} /></Link>
//         </PageHeaderRow>

//         <Segment loading={this.props.isLoading} >
//           <div className="ui four column stackable padded grid ">
//             <div className="eight wide column">
//               {renderInfo('Name', data.name)}
//               {renderInfo('Key', data.key)}
//               {renderInfo('Customer', data.customer ? customerName : '-')}
//             </div>
//             <div className="eight wide column">
//               {renderInfo('Domain', data.domain)}
//               {renderInfo('Access', data.settings?.private ? 'Private' : 'Public')}
//             </div>
//           </div>
//         </Segment>

//         <Segment>
//           {this.productList ? <this.productList micrositeId={this.props.match.params.id} /> : null}
//         </Segment>
//       </div>

//     )
//   }
// }

// const portalProductServiceKey = '/products-legacy/portal-products';
// export default DetailWrapper(PortalDetailComponent as any, 'microsites', {
//   query: { $populate: ['customer'] },
//   mapDispatchToProps: (state) => ({
//     findProducts: state[portalProductServiceKey].queryResult,
//     isLoadingProducts: state[portalProductServiceKey].isLoading,
//     isErrorProducts: state[portalProductServiceKey].isError,
//     isFinishedProducts: state[portalProductServiceKey].isFinished,
//   })
// });

import * as React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Segment, Button } from 'semantic-ui-react';
import { PageHeaderRow } from '@inkcloud/components';
import type { ModelTypes } from '@inkcloud/icapi-types';

import { Table, Tbody, Tr, Th, Td, Spinner, Box } from '@chakra-ui/react';

import feathers from '../../bootstrap/feathers';

const infoRowStyle = { paddingTop: 2, paddingBottom: 2 };

// const renderInfo = (label, text) => (
//   <div className="ui two column grid">
//     <div className="row" style={infoRowStyle}>
//       <div className="six wide column">
//         <label>{label}</label>
//       </div>
//       <div className="ten wide column">{text}</div>
//     </div>
//   </div>
// )
const renderInfo = (label, text) => (
  <Table size="sm">
    <Tbody>
      <Tr>
        <Th pt={2} borderBottom="none" w={120}>
          {label}
        </Th>
        <Td borderBottom="none">{text}</Td>
      </Tr>
    </Tbody>
  </Table>
);

const { useEffect, useState } = React;

type MicrositeType = ModelTypes.Microsites & {
  customer?: {
    organizationName?: string;
    primaryContact?: {
      firstName: string;
      lastName: string;
    };
  };
};

export const PortalDetailComponent: React.FunctionComponent<RouteComponentProps<{ id: string }>> = (
  props
) => {
  const {
    match: {
      params: { id },
    },
  } = props;

  const [microsite, setMicrosite] = useState<MicrositeType>({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const loadMicrosite = async () => {
      setIsLoading(true);
      try {
        const res: any = await feathers.service('/microsites').get(id, {
          query: {
            $populate: ['customer'],
          },
        });

        setMicrosite(res);
      } catch (e) {
        console.error(e);
      }
      setIsLoading(false);
    };

    loadMicrosite();
  }, [id]);

  let customerName = '';
  if (microsite?.customer?.organizationName) {
    customerName = microsite?.customer?.organizationName;
  } else if (microsite?.customer?.primaryContact) {
    const { primaryContact } = microsite?.customer;
    customerName = `${primaryContact?.firstName ?? ''} ${primaryContact?.lastName ?? ''}`;
  }

  return (
    <>
      <PageHeaderRow header={microsite?.name ?? ''} subheader="Microsite">
        <Link to={`/settings/microsites`}>
          <Button content="Back" size={'mini'} />
        </Link>
        <Link to={`/settings/microsites/${id}/edit`}>
          <Button primary content="Edit" size={'mini'} />
        </Link>
      </PageHeaderRow>

      {isLoading ? (
        <Box textAlign="center" my={5}>
          <Spinner />
        </Box>
      ) : (
        <Box borderRadius="md" borderWidth="1px" bg="white" pt={2}>
          <Table variant="simple" size="sm">
            <Tbody>
              <Tr verticalAlign="top">
                <Td borderBottom="none">
                  {renderInfo('Name', microsite?.name)}
                  {renderInfo('Key', microsite?.key)}
                  {renderInfo('Customer', customerName ?? '-')}
                </Td>
                <Td borderBottom="none">
                  {renderInfo('Domain', microsite?.domain)}
                  {renderInfo('Access', microsite?.settings?.private ? 'Private' : 'Public')}
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </Box>
      )}
    </>
  );
};
