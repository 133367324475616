import * as React from 'react';
import { Button, Form, Message, Header, Segment } from 'semantic-ui-react';
import { Formik, Form as FormikForm, Field, FieldProps, ErrorMessage, FieldArray } from 'formik';
import * as Yup from 'yup';
import { SemanticField } from '../../../common/Form';

import { FeathersDropdown } from '@inkcloud/components';

const { useState } = React;

interface SalesPersonFormProps {
  initialValues: any;
  onSubmit: Function;
  isSubmitting?: boolean;
  errorMessage: string;
}

export const SalesPersonForm: React.FC<SalesPersonFormProps> = (props) => {
  const SalesPersonSchema = Yup.object().shape({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    commissionPercentage: Yup.number().typeError('Must be a number only.'),
  });

  const { errorMessage } = props;

  return (
    <Formik
      initialValues={props.initialValues}
      validationSchema={SalesPersonSchema}
      onSubmit={(values, { setSubmitting }) => {
        props.onSubmit(values);
      }}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
          setFieldValue,
          setFieldTouched,
        } = props;

        return (
          <FormikForm className={`ui form ${isSubmitting && !errorMessage && 'loading'}`}>
            <Form.Group widths="equal">
              <Form.Field>
                <SemanticField name={'firstName'} label="First Name" component={Form.Input} />
                <ErrorMessage
                  render={(msg) => <Message negative size="tiny" content={msg} />}
                  name="firstName"
                />
              </Form.Field>
              <Form.Field>
                <SemanticField name={'lastName'} label="Last Name" component={Form.Input} />
                <ErrorMessage
                  render={(msg) => <Message negative size="tiny" content={msg} />}
                  name="lastName"
                />
              </Form.Field>
              <Form.Field>
                <SemanticField name={'companyName'} label="Company Name" component={Form.Input} />
              </Form.Field>
            </Form.Group>

            <Form.Group widths="equal">
              <Form.Field>
                <SemanticField name={'email'} label="Email" component={Form.Input} />
              </Form.Field>
              <Form.Field>
                <SemanticField name={'phone'} label="Phone" component={Form.Input} />
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field>
                <SemanticField
                  name={'commissionPercentage'}
                  label="Commission Percentage"
                  component={Form.Input}
                />
                <ErrorMessage
                  render={(msg) => (
                    <Message
                      negative
                      size="tiny"
                      content={`${msg} ${
                        values.commissionPercentage.indexOf('%') >= 0
                          ? `You don't need to add %`
                          : ''
                      }`}
                    />
                  )}
                  name="commissionPercentage"
                />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field>
                <SemanticField
                  name={'linkToUser'}
                  label={`Link To a Dashboard User`}
                  component={Form.Checkbox}
                />
              </Form.Field>
            </Form.Group>

            {(values.linkToUser || values.user) && (
              <Form.Group widths="equal">
                <Form.Field>
                  <Field
                    name="user"
                    render={({ field }: FieldProps<any>) => (
                      <FeathersDropdown
                        search
                        selection
                        clearable
                        placeholder="User..."
                        serviceName="/users"
                        query={{ $sort: { firstName: 1 }, $np: 1 }}
                        resultMapper={(r) => ({
                          value: r._id,
                          text: `${r.firstName} ${r.lastName}`,
                        })}
                        {...field}
                        onChange={(e, data) =>
                          field.onChange({ target: { value: data.value, name: 'user' } } as any)
                        }
                      />
                    )}
                  />
                </Form.Field>
              </Form.Group>
            )}

            <Form.Group>
              <Form.Field>
                <SemanticField name={'isManager'} label={`Manager`} component={Form.Checkbox} />
              </Form.Field>
            </Form.Group>

            <Form.Group widths="equal">
              <Form.Field>
                <Field
                  name="manager"
                  render={({ field }: FieldProps<any>) => (
                    <FeathersDropdown
                      search
                      selection
                      clearable
                      placeholder="Managed By..."
                      serviceName="/sales-people"
                      query={{
                        isManager: true,
                        $sort: { firstName: 1 },
                        $np: 1,
                      }}
                      resultMapper={(r) => ({
                        key: r._id,
                        value: r._id,
                        text: `${r.firstName} ${r.lastName}`,
                      })}
                      // {...field}
                      defaultValue={
                        values.manager && !values.manager.isDeleted ? values.manager._id : null
                      }
                      onChange={(e, data) =>
                        field.onChange({ target: { value: data.value, name: 'manager' } } as any)
                      }
                    />
                  )}
                />
              </Form.Field>
            </Form.Group>

            <Header as="h5" attached="top">
              Metadata
            </Header>
            <Segment attached="bottom">
              <Form.Field>
                <FieldArray
                  name="metadata"
                  render={(arrayHelpers) => (
                    <div>
                      {values?.metadata?.map((custom, index) => (
                        <Form.Group widths="equal" key={index}>
                          <Form.Field>
                            <label>Key</label>
                            <Field name={`metadata[${index}].key`} />
                            {(errors?.metadata as any)?.[index]?.key && (
                              <Message
                                negative
                                size="tiny"
                                content={(errors?.metadata as any)?.[index]?.key}
                              />
                            )}
                          </Form.Field>
                          <Form.Field>
                            <label>Value</label>
                            <Field name={`metadata[${index}].value`} />
                          </Form.Field>
                          <div>
                            <div className="field">
                              <label>&nbsp;</label>
                              <Button
                                type="button"
                                icon="remove"
                                onClick={() => arrayHelpers.remove(index)}
                              />
                            </div>
                          </div>
                        </Form.Group>
                      ))}
                      <Button
                        type="button"
                        size="tiny"
                        content="Add"
                        onClick={() => arrayHelpers.push({ key: '' })}
                      />
                    </div>
                  )}
                />
              </Form.Field>
            </Segment>

            <Button primary type="submit">
              Save
            </Button>
          </FormikForm>
        );
      }}
    </Formik>
  );
};
