import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Center,
  Spinner,
  Alert,
  Button,
} from '@chakra-ui/react';
import { QRCodeSVG } from 'qrcode.react';

interface IQRModalProps {
  url: string;
  isLoading: boolean;
  errorMessage?: string;
  onClose: () => void;
}

export default function QRModal(props: IQRModalProps) {
  const { url, isLoading, errorMessage, onClose } = props;
  return (
    <Modal isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Log in to Mobile Device</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Center>
            {isLoading ? (
              <Spinner />
            ) : (
              <QRCodeSVG
                value={url}
                fgColor="#000"
                size={200}
                imageSettings={{
                  src: 'https://inkcloud9.com/static/ic-logo-clean-sm-dd7562a05ae8438f32f09ff676a0b9c5.png',
                  excavate: true,
                  width: 30,
                  height: 18,
                }}
              />
            )}
          </Center>
          {errorMessage && (
            <Alert my={4} status="error">
              {errorMessage}
            </Alert>
          )}
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
