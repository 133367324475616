import * as React from 'react';
import {
  Button,
  Dimmer,
  Checkbox,
  Header,
  Grid,
  Label,
  Icon,
  Loader,
  Segment,
  Table,
} from 'semantic-ui-react';
import { RouteComponentProps } from 'react-router-dom';
import { DetailWrapper, DetailFeaturesProps } from './../../../bootstrap/DetailWrapper';
import TranslatorForm from './TranslatorInviteForm';
import { Link } from 'react-router-dom';
import { TranslatorInfoTable } from './TranslatorInfoTable';
import { ListViewPagination, ListFilterWarning } from './../../../common';

import { services } from './../../../bootstrap/feathers';

export interface DetailProps extends DetailFeaturesProps {
  loadCustomerOrderItems: Function;
  orderItems: any;
}

export interface DetailState {}

class Detail extends React.Component<DetailProps, DetailState> {
  constructor(props: DetailProps) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (this.props.data) {
      this.loadOrderItems();
    }
  }

  componentWillReceiveProps(next) {
    if (!this.props.data && next.data) {
      this.loadOrderItems();
    }
  }

  loadOrderItems(mergeInQuery = {}) {
    this.props.loadCustomerOrderItems({
      query: {
        'translator.assigned': this.props.match.params.id,
        $populate: [
          'attributes.value',
          'category',
          'customer',
          'productionStatus.jobStatus',
          'translator.assigned',
        ],
        $sort: { createdAt: -1 },
        ...mergeInQuery,
      },
    });
  }

  handlePageChange = (query) => {
    this.loadOrderItems(query);
  };

  handleRemove = () => {};

  render() {
    const { data, orderItems } = this.props;

    if (!this.props.data) {
      return (
        <Segment>
          <Dimmer active>
            <Loader size="small">Loading</Loader>
          </Dimmer>
        </Segment>
      );
    }

    return (
      <div style={{ background: '#f3f3f3' }}>
        <Grid verticalAlign="top" padded stackable stretched>
          <Grid.Row stretched>
            <Grid.Column width={10} stretched>
              <Header as="h2" color="blue">
                <Header.Content>
                  {data && `${data.firstName} ${data.lastName}`}
                  <Header.Subheader>
                    ID: {this.props.data && this.props.data.humanId}
                  </Header.Subheader>
                </Header.Content>
              </Header>
            </Grid.Column>
            <Grid.Column width={6} textAlign={'right'}>
              <Button.Group size="mini" fluid secondary style={{ textAlign: 'right' }}>
                {/* <Button>Create Order</Button> */}
                <Link to={`/admin/translators/${this.props.data._id}/edit`}>
                  <Button>Edit Translator</Button>
                </Link>
                {/* <Button>Login As</Button> */}
              </Button.Group>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <TranslatorInfoTable translator={this.props.data} />
        {/* <ListViewPagination.Top
          label={'New Production Batch'}
          link={'/production/batches/add'}
          hideAddButton={true}
          {...this.props}
        /> */}
        <Table size={'small'}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>ID</Table.HeaderCell>
              <Table.HeaderCell>Customer</Table.HeaderCell>
              <Table.HeaderCell>Category</Table.HeaderCell>
              <Table.HeaderCell>Description</Table.HeaderCell>
              <Table.HeaderCell>Translator</Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {orderItems.queryResult &&
              orderItems.queryResult.data &&
              orderItems.queryResult.data.map((i) => {
                const sourceLang = i.metadata.find((a) => a.key == 'tr_source_name');
                const destinationLang = i.metadata.find((a) => a.key == 'tr_dest_name');
                const langMap = (
                  <div>
                    <Label size={'mini'} key={sourceLang?.key ?? ''}>
                      {sourceLang?.value ?? ''}
                    </Label>
                    <Icon name={'long arrow alternate right'} />
                    <Label size={'mini'} key={destinationLang?.key ?? ''}>
                      {destinationLang?.value ?? ''}
                    </Label>
                  </div>
                );
                return (
                  <Table.Row key={i._id}>
                    <Table.Cell>{`${i.humanId}`}</Table.Cell>
                    <Table.Cell>
                      <Link to={`/customers/${i.customer._id}`}>
                        {i.customer.organizationName
                          ? i.customer.organizationName
                          : `${i.customer.primaryContact.firstName} ${i.customer.primaryContact.lastName}`}
                      </Link>
                    </Table.Cell>

                    <Table.Cell>{i.category?.name ?? 'Custom'}</Table.Cell>
                    <Table.Cell width={'6'}>{langMap}</Table.Cell>
                    <Table.Cell>
                      {`${i.translator?.assigned?.firstName} ${i.translator?.assigned?.lastName}`}
                    </Table.Cell>
                    <Table.Cell>
                      {i.productionStatus && (
                        <Label size={'mini'} content={i.productionStatus?.jobStatus?.name ?? ''} />
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      <Link to={'/orders/' + i.order}>
                        <Button size={'mini'}>Details</Button>
                      </Link>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
          </Table.Body>
        </Table>
        {/* <ListViewPagination.Bottom
          hideDeleteButton={true}
          label={'Delete'}
          {...this.props}
        /> */}
      </div>
    );
  }
}

export default DetailWrapper(Detail, 'translators', {
  submitRedirect: '/admin/translators',
  mapStateToProps: (state: any) => ({
    orderItems: state.orderItems,
  }),
  mapDispatchToProps: {
    loadCustomerOrderItems: services.orderItems.find,
  },
});
