import * as React from 'react';
import { Button, Form, Message, Icon } from 'semantic-ui-react';
import { Formik, Form as FormikForm, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { SemanticField } from '../../../common/Form';
import { ImageUpload } from '../../products/products/stock-product/StockForm/ImageUpload';

export interface ImportsRequestFormProps {
  initialValues: any;
  onSubmit: (v: any) => void;
  errorMessage: string;
}

const ImportsRequestFormSchema = Yup.object().shape({
  importKey: Yup.string().required('Required'),
});

const importKeyOptions = [
  { text: 'Product', value: 'product' },
  { text: 'Product Category', value: 'category' },
  { text: 'Product Attribute', value: 'attribute' },
];

export const ImportsRequestForm: React.FunctionComponent<ImportsRequestFormProps> = (props) => {
  const { initialValues, onSubmit, errorMessage } = props;

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={ImportsRequestFormSchema}
        onSubmit={(values, { setSubmitting }) => {
          const foundImportKey = importKeyOptions.find((i) => i.value === values.importKey);
          onSubmit({
            ...values,
            importName: foundImportKey?.text,
          });
        }}
      >
        {(innerProps) => {
          const {
            values,
            touched,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            setFieldValue,
            setFieldTouched,
          } = innerProps;

          const fileName = values?.fileName || values?.uploadLog?.originalFilename;

          return (
            <FormikForm className={`ui form ${isSubmitting && !errorMessage && 'loading'}`}>
              <Form.Group widths="equal">
                <SemanticField
                  fluid
                  name="importKey"
                  label="Import Type"
                  component={Form.Dropdown}
                  selection
                  placeholder={'Select an import type'}
                  options={importKeyOptions}
                />
                <ErrorMessage
                  render={(msg) => <Message negative size="tiny" content={msg} />}
                  name="name"
                />
              </Form.Group>

              <ImageUpload
                onUpload={(file) => {
                  if (file) {
                    setFieldValue('file', file?.files?.files?.[0]?.uploadLog);
                    setFieldValue('fileName', file?.files?.files?.[0]?.originalFilename);
                  }
                }}
                showFiles={false}
                multiple={false}
                api="/uploads-misc"
              />

              <div>
                {fileName && (
                  <>
                    <div>
                      <Icon name="file outline" /> {fileName}{' '}
                    </div>
                    <br />
                  </>
                )}
              </div>

              <Button
                primary
                type="submit"
                disabled={isSubmitting && !errorMessage && !values.file}
              >
                Submit Import Request
              </Button>
            </FormikForm>
          );
        }}
      </Formik>
      {errorMessage && <Message error content={errorMessage} />}
    </>
  );
};
