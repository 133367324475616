import React from 'react';

import {
  Icon,
  Stack,
  StackDivider,
  Flex,
  Text,
  Box,
  Table,
  Tbody,
  Tr,
  Td,
  IconButton,
} from '@chakra-ui/react';

import { FaRegFile, FaTimes } from 'react-icons/fa';
import type { AttachmentType } from '../../Detail';

import { SegmentCard } from '../../../../common';

interface IAttachmentsProps {
  title: string;
  subTitle?: string;
  attachments: AttachmentType;
  actionButtonLabel?: string;
  onOpenUpload?: () => void;
  onChange?: (value: AttachmentType) => void;
}

const baseUrl = `https://storage.googleapis.com/icstorage/`;

export default function Attachments(props: IAttachmentsProps) {
  const {
    attachments,
    title,
    actionButtonLabel = 'Add file',
    subTitle,
    onOpenUpload,
    onChange,
  } = props;

  const handleRemove = (id: string) => {
    const newAttachments = attachments.filter((a) => a._id !== id);

    onChange?.(newAttachments);
  };

  return (
    <SegmentCard
      title={title}
      subtitle={subTitle}
      actionButtonLabel={actionButtonLabel}
      actionButtonClick={onOpenUpload}
      h="100%"
    >
      <Stack divider={<StackDivider />}>
        {attachments?.length ? (
          <Table size="sm" variant="simple">
            <Tbody>
              {attachments?.map((attachment, index) => (
                <Tr key={index}>
                  <Td>
                    <Text fontWeight="bold">{attachment?.label ?? ''}</Text>
                    <a href={`${baseUrl}${attachment.uploadLog?.cloudFilename}`} target="_blank">
                      <Flex alignItems="center">
                        <Icon as={FaRegFile} mr={1} /> {attachment?.filename ?? ''}
                      </Flex>
                    </a>
                  </Td>
                  {onChange && (
                    <Td textAlign="right">
                      <IconButton
                        aria-label="remove file"
                        icon={<FaTimes />}
                        onClick={() => handleRemove(attachment?._id ?? '')}
                      />
                    </Td>
                  )}
                </Tr>
              ))}
            </Tbody>
          </Table>
        ) : (
          <Flex alignItems="center" justifyContent="center">
            <Box p={5} textAlign="center">
              <Icon as={FaRegFile} boxSize={10} />
              <Text>There's no attachment yet</Text>
            </Box>
          </Flex>
        )}
      </Stack>
    </SegmentCard>
  );
}
