import * as React from "react";
import { Menu, Icon, Transition, Dropdown } from "semantic-ui-react";
import { Link } from "react-router-dom";

import { MenuItem, permissionsMenuItem } from "@inkcloud/ui-base";
import { Can } from "../../common/permissions";

import icLogo from "../../images/ic-logo.svg";

// declare var ENV;

const styleMenu = {
  position: "fixed",
  top: 0,
  bottom: 0,
  left: 0,
  width: 175,
  paddingBottom: "1em",
  // match menu background
  // prevents a white background when items are filtered out by search
  background: "#1B1C1D",
  overflowY: "scroll",
};

const HomeLink = permissionsMenuItem(["*"])("/", "Home", "home");

const CustomersLink = permissionsMenuItem(["*"])("/customers", "Customers");
const CustomersApprovalLink = permissionsMenuItem(["*"])(
  "/customers/approval",
  "Pending Approval"
);
const CustomersTaxExemptionReviewLink = permissionsMenuItem(["*"])(
  "/customers/tax-exemption-requests",
  "Tax Exemption Requests"
);
const CustomersContactFormSubmissionsLink = permissionsMenuItem(["*"])(
  "/customers/contact-form-submissions",
  "Contact Form Submissions"
);
const CustomersCustomQuotesLink = permissionsMenuItem(["*"])(
  "/customers/custom-quotes",
  "Custom Quotes"
);
const CustomersTestimonialsLink = permissionsMenuItem(["*"])(
  "/customers/testimonials",
  "Testimonials"
);

const OrdersLink = permissionsMenuItem(["*"])("/orders", "Orders");
const OrderBuilderLink = permissionsMenuItem(["*"])(
  "/order-builder",
  "Order Builder"
);
const PurchaseOrdersLink = permissionsMenuItem(["*"])(
  "/purchase-orders",
  "Purchase Orders"
);
const RFQsLink = permissionsMenuItem(["*"])("/rfqs", "RFQs");
const SupplierBillingReconciliationLink = permissionsMenuItem(["*"])(
  "/supplier-billing-reconciliation",
  "Billing Reconciliation"
);

const SalesOrdersLink = permissionsMenuItem(["*"])(
  "/sales-orders",
  "Sales Orders"
);
const OrderApprovalPendingLink = permissionsMenuItem(["*"])(
  "/order-approval",
  "Orders Approval"
);
const OrderVoidedLink = permissionsMenuItem(["*"])(
  "/order-voided",
  "Orders Voided"
);
const ProofsLink = permissionsMenuItem(["*"])("/proofs", "Proofs");
// const ManageDesignJobsLink = permissionsMenuItem(["*"])(
//   "/manage-design-jobs",
//   "Manage Design Jobs"
// );
const DesignJobsLink = permissionsMenuItem(["*"])(
  "/design-jobs",
  "Design Jobs"
);

const LateJobDetectorsLink = permissionsMenuItem(["*"])(
  "/late-job-detectors",
  "Late Job Detectors"
);

const EstimatesLink = permissionsMenuItem(["*"])("/estimates", "Estimates");

const AccountingInvoicesLink = permissionsMenuItem(["*"])(
  "/accounting/invoices",
  "Invoices"
);
const AccountingOpenInvoicesLink = permissionsMenuItem(["*"])(
  "/accounting/open-invoices",
  "Open Invoices"
);

const PreFlightHistoryLink = permissionsMenuItem(["*"])(
  "/pre-flights/history",
  "Pre Flight History"
);
const PendingProofsLink = permissionsMenuItem(["*"])(
  "/pending-proofs",
  "Pending Proofs"
);

const ProductionBatchesLink = permissionsMenuItem(["*"])(
  "/production/batches",
  "Batches"
);
const ProductionPressControlLink = permissionsMenuItem(["*"])(
  "/production/press-control",
  "Press Control"
);
const ProductionResourcesLink = permissionsMenuItem(["*"])(
  "/production/resources",
  "Resources"
);
const ProductionChannelsLink = permissionsMenuItem(["*"])(
  "/production/channels",
  "Channels"
);
const ProductionOutsourceLink = permissionsMenuItem(["*"])(
  "/production/outsource",
  "Outsource"
);
const ProductionVendorLink = permissionsMenuItem(["*"])(
  "/production/vendors",
  "Vendors"
);

const ProductionInProductionLink = permissionsMenuItem(["*"])(
  "/production/view/in-production",
  "Production View"
);
const ProductionDueDateLink = permissionsMenuItem(["*"])(
  "/production/view/due-date",
  "Due Date View"
);
const ProductionShortRunBarcode = permissionsMenuItem(["*"])(
  "/production/short-run-barcode",
  "Short Run Barcode"
);
const ProductionWorkflowStations = permissionsMenuItem(["*"])(
  "/production/workflow-stations",
  "Workflow Stations"
);

const ProductionStatusGrid = permissionsMenuItem(["*"])(
  "/production/status-grid",
  "Status Grid"
);

const TranslationOrdersLink = permissionsMenuItem(["*"])("/orders", "Orders");
const TranslationEstimatesLink = permissionsMenuItem(["*"])(
  "/translation/estimates",
  "Estimates"
);
const TranslationTranslatorsLink = permissionsMenuItem(["*"])(
  "/admin/translators",
  "Translators"
);
const TranslationAssignLink = permissionsMenuItem(["*"])(
  "/translation/assign",
  "Assign"
);
const TranslationPMApprovalLink = permissionsMenuItem(["*"])(
  "/translation/pm-approval",
  "PM Approval"
);

const ProductsAttributesLink = permissionsMenuItem(["*"])(
  "/products/attributes",
  "Attributes"
);
const ProductsCategoriesLink = permissionsMenuItem(["*"])(
  "/products/categories",
  "Categories"
);
const ProductsPriceGridEditorLink = permissionsMenuItem(["*"])(
  "/products/grid-editor-price",
  "Price Grid Editor"
);
const ProductsMassUpdaterLink = permissionsMenuItem(["*"])(
  "/products/mass-updater",
  "Mass Updater"
);
const ProductsProductsLink = permissionsMenuItem(["*"])(
  "/products/products",
  "Products"
);
// const ProductsStockProductsLink = permissionsMenuItem(["*"])(
//   "/products/stock-products",
//   "Stock Products"
// );
const ProductsAddOnPricing = permissionsMenuItem(["*"])(
  "/products/add-on-pricing",
  "Add On Pricing"
);

const InventoryLink = permissionsMenuItem(["*"])(
  "/products/inventory",
  "Inventory"
);

const InventoryLocationsLink = permissionsMenuItem(["*"])(
  "/products/inventory/locations",
  "Inventory Locations"
);

const InventoryReceiveLink = permissionsMenuItem(["*"])(
  "/products/inventory/receive",
  "Inventory Receive"
);

const InventoryIncomingLink = permissionsMenuItem(["*"])(
  "/products/inventory/incoming-tickets",
  "Inventory Incoming Notifications"
);
const FulfilmentStagingLocations = permissionsMenuItem(["*"])(
  "/products/fulfillment-staging-locations",
  "Fulfilment Staging Locations"
);

const ContentPagesLink = permissionsMenuItem(["*"])("/content/pages", "Pages");
const ContentPagesEditLink = permissionsMenuItem(["*"])(
  "/content/edit",
  "Content"
);

const ImageManagerLink = permissionsMenuItem(["*"])(
  "/image-manager",
  "Image Manager"
);

const ReportsMicrositeOrdersFlattenedLink = permissionsMenuItem(["*"])(
  "/reports/microsites/orders-flattened",
  "MS - Orders Flattened"
);
const ReportsMicrositeShippedOrdersFlattenedLink = permissionsMenuItem(["*"])(
  "/reports/microsites/shipped-orders-flattened",
  "MS - Shipped Orders Flattened"
);

const ReportsMicrositeInactiveCustomersLink = permissionsMenuItem(["*"])(
  "/reports/microsites/inactive-customers",
  "MS - Inactive Customers"
);

const ReportsMicrositeInventoryLink = permissionsMenuItem(["*"])(
  "/reports/microsites/inventory",
  "MS - Inventory"
);
const ReportsMicrositeInventoryMovements = permissionsMenuItem(["*"])(
  "/reports/microsites/inventory-movements",
  "MS - Inventory Movements"
);
const ReportsMicrositeInventorLocations = permissionsMenuItem(["*"])(
  "/reports/microsites/inventory-locations",
  "MS - Inventory Locations"
);
const ReportsMicrositeInventoryProductLedger = permissionsMenuItem(["*"])(
  "/reports/microsites/inventory-product-ledger",
  "MS - Inventory Product Ledger"
);
const ReportsMicrositeFulfillment = permissionsMenuItem(["*"])(
  "/reports/microsites/fulfillment",
  "MS - Fulfillment"
);
const ReportsMicrositeFulfillmentByOrder = permissionsMenuItem(["*"])(
  "/reports/microsites/fulfillment-by-order",
  "MS - Fulfillment By Order"
);

const Reports = permissionsMenuItem(["*"])("/reports", "Reports");

const ReportsResults = permissionsMenuItem(["*"])(
  "/reports/results",
  "Report Results"
);

const ReportsCustoms = permissionsMenuItem(["*"])(
  "/reports/customs",
  "Custom Reports"
);

const ReportsSales = permissionsMenuItem(["*"])("/reports/sales", "Sales");
const ReportsSalesLight = permissionsMenuItem(["*"])(
  "/reports/sales-light",
  "Sales Light"
);

const ReportsSalesTax = permissionsMenuItem(["*"])(
  "/reports/sales-tax",
  "Sales Tax"
);
const ReportsSalesTeam = permissionsMenuItem(["*"])(
  "/reports/sales-team",
  "Sales Team"
);
const ReportsCustomerSales = permissionsMenuItem(["*"])(
  "/reports/customer-sales",
  "Customer Sales"
);
const ReportsCustomerGrowth = permissionsMenuItem(["*"])(
  "/reports/customer-growth",
  "Customer Growth"
);
const ReportsAging = permissionsMenuItem(["*"])(
  "/reports/aging",
  "Aging Report"
);
const ReportsPayment = permissionsMenuItem(["*"])(
  "/reports/payment",
  "Payment Report"
);

const ReportsInventoryBackOrder = permissionsMenuItem(["*"])(
  "/reports/microsites/inventory-backorders",
  "Inventory Backorder"
);

const StatusUpdate = permissionsMenuItem(["*"])(
  "/status-update",
  "Status Update"
);

const BadgerBrandsLink = permissionsMenuItem(["*"])("/badger/brands", "Brands");
const BadgerCategoriesLink = permissionsMenuItem(["*"])(
  "/badger/categories",
  "Categories"
);
const BadgerDrinkRecipesLink = permissionsMenuItem(["*"])(
  "/badger/drink-recipes",
  "Drink Recipes"
);
const BadgerFlavorsLink = permissionsMenuItem(["*"])(
  "/badger/flavors",
  "Flavors"
);
const BadgerJobDataLink = permissionsMenuItem(["*"])(
  "/badger/job-data",
  "Job Data"
);
const BadgerProductsLink = permissionsMenuItem(["*"])(
  "/badger/products",
  "Products"
);
const BadgerProgramsLink = permissionsMenuItem(["*"])(
  "/badger/programs",
  "Programs"
);
const BadgerSuppliersLink = permissionsMenuItem(["*"])(
  "/badger/suppliers",
  "Suppliers"
);
const BadgerSupplierUsersLink = permissionsMenuItem(["*"])(
  "/badger/supplier/users",
  "Supplier Users"
);
const BadgerBillbackLink = permissionsMenuItem(["*"])(
  "/badger/billback",
  "Billback"
);
const BadgerBillbackReportLink = permissionsMenuItem(["*"])(
  "/badger/billback/generate-report",
  "Billback Report"
);
const BadgerBillbackInvoiceReportLink = permissionsMenuItem(["*"])(
  "/badger/billback/invoice-report",
  "Billback Invoice Report"
);
const BadgerReportLink = permissionsMenuItem(["*"])(
  "/badger/report/print-cost-report",
  "Report - Print Cost Report"
);

const FlexLiveChatLink = permissionsMenuItem(["*"])(
  "/flex/live-chat",
  "Live Chat"
);

const SettingsAddressBookLink = permissionsMenuItem(["*"])(
  "/admin/address-book",
  "Address Book"
);
const SettingsDashboardUsersLink = permissionsMenuItem(["*"])(
  "/admin/dashboard-users",
  "Dashboard Users"
);
const SettingsUserRolesLink = permissionsMenuItem(["*"])(
  "/admin/user-roles",
  "User Roles"
);
const SettingsSalesPeopleLink = permissionsMenuItem(["*"])(
  "/admin/sales-people",
  "Sales People"
);
const SettingsTenant = permissionsMenuItem(["*"])(
  "/admin/tenant-settings",
  "Tenant Setting"
);
const SettingsPortalsLink = permissionsMenuItem(["*"])(
  "/settings/portals",
  "Portals"
);
const SettingsMicrositesLink = permissionsMenuItem(["*"])(
  "/settings/microsites",
  "Microsites"
);

const SettingsLink = permissionsMenuItem(["*"])("/settings", "Settings");

const AdminNotificationLog = permissionsMenuItem(["*"])(
  "/admin/notification-log",
  "Notification Log"
);

const ArtworkTransporter = permissionsMenuItem(["*"])(
  "/admin/artwork-transporter",
  "Artwork Transporter"
);

const AdminEmailParseLog = permissionsMenuItem(["*"])(
  "/admin/email-parse/log",
  "Email Parse Log"
);

const SettingsDesignTemplatesLink = permissionsMenuItem(["*"])(
  "/admin/design-templates",
  "Design Templates"
);

const JobSchedulerLink = permissionsMenuItem(["*"])(
  "/job-scheduler",
  "Job Scheduler"
);

const PickListScannerLink = permissionsMenuItem(["*"])(
  "/pick-list-scanner",
  "Pick List Scanner"
);

const DocumentPrinterLink = permissionsMenuItem(["*"])(
  "/document-printer",
  "Document Printer"
);

const ShipmentLog = permissionsMenuItem(["*"])("/shipment-log", "Shipment Log");

const baseUrl = "https://storage.googleapis.com/icstorage";

const tenantLogoStyle = {
  display: "inline-block",
  maxWidth: "120px",
  maxHeight: "50px",
};

const logo = (props) => (
  <>
    {!props.usecustomlogo ? (
      <object width="100" type="image/svg+xml" data={icLogo}>
        SVG not supported
      </object>
    ) : (
      <div style={{ textAlign: "center" }}>
        <img
          src={`${baseUrl}${props.tenantlogo}`}
          alt=""
          style={tenantLogoStyle}
        />
      </div>
    )}
  </>
);

const LanguageFishMenu = (props) => (
  <Menu {...props}>
    <Menu.Header>
      <Menu.Item>{logo(props)}</Menu.Item>
    </Menu.Header>
    <HomeLink />

    <Menu.Item>
      <Icon name="address book outline" />
      Customers
      <Menu.Menu>
        <CustomersLink />
        <CustomersContactFormSubmissionsLink />
        <CustomersCustomQuotesLink />
        <CustomersTestimonialsLink />
      </Menu.Menu>
    </Menu.Item>

    <Menu.Item>
      <Icon name="factory" />
      Translation
      <Menu.Menu>
        <OrderBuilderLink />
        <TranslationOrdersLink />
        <TranslationEstimatesLink />
        <TranslationTranslatorsLink />
        <TranslationAssignLink />
        <TranslationPMApprovalLink />
      </Menu.Menu>
    </Menu.Item>
    <Menu.Item>
      <Icon name="cube" />
      Products
      <Menu.Menu>
        <ProductsAttributesLink />
        <ProductsCategoriesLink />
        <ProductsPriceGridEditorLink />
        <ProductsMassUpdaterLink />
        <ProductsProductsLink />
      </Menu.Menu>
    </Menu.Item>
    <Can do={"read"} on="dashboard.settings">
      {() => (
        <Menu.Item>
          <Icon name="settings" />
          Settings
          <Menu.Menu>
            <ContentPagesLink />
            <ImageManagerLink />
            <SettingsDashboardUsersLink />
            {/* <SettingsUserRolesLink /> */}
            {/* <SettingsSalesPeopleLink /> */}
            <SettingsTenant />
            <SettingsDesignTemplatesLink />
            <AdminNotificationLog />
            <ArtworkTransporter />
            <SettingsLink />
            {/* <SettingsDesignTemplatesLink /> */}
          </Menu.Menu>
        </Menu.Item>
      )}
    </Can>
  </Menu>
);

const VendorMenu = (props) => (
  <Menu {...props}>
    <Menu.Header>
      <Menu.Item>{logo(props)}</Menu.Item>
    </Menu.Header>
    <HomeLink />

    <Menu.Item>
      <Icon name="factory" />
      Vendor Menu
      <Menu.Menu>
        <OrdersLink />
        <ProofsLink />
        <StatusUpdate />
        {/* This probably should never be shown *]}
        {/* <PurchaseOrdersLink /> */}
        <RFQsLink />
        <SupplierBillingReconciliationLink />
        <ProductionWorkflowStations />
        <ProductionStatusGrid />
        <DocumentPrinterLink />
        <ArtworkTransporter />
        <FulfilmentStagingLocations />
      </Menu.Menu>
    </Menu.Item>
  </Menu>
);

export const DefinedMenu = (props) => {
  const { user } = props;

  const isVendor = !!user.vendor;
  if (props.domainname === "languagefish.com") {
    return LanguageFishMenu(props);
  }

  if (isVendor) {
    return VendorMenu(props);
  }

  const isBadger =
    props.domainname === "badger.inkcloud9.com" ||
    props.domainname === "oecgraphics.com";
  return (
    <Menu {...props}>
      <Menu.Header>
        <Menu.Item>
          {logo(props)}

          {/* <img
            className="logo"
            src="https://res.cloudinary.com/inkcloud/image/upload/c_scale,h_60/a_0/v1463506479/InkCloud_Logo_wbib4r.png"
          /> */}
        </Menu.Item>
      </Menu.Header>
      <HomeLink />
      {isBadger && (
        <Menu.Item>
          <Icon name="address book outline" />
          Badger
          <Menu.Menu>
            <Can do={"read"} on="dashboard.bl">
              <BadgerBrandsLink />
              <BadgerBillbackLink />
              <BadgerBillbackReportLink />
              <BadgerBillbackInvoiceReportLink />
              <BadgerCategoriesLink />
              <BadgerDrinkRecipesLink />
              <BadgerFlavorsLink />
              <BadgerJobDataLink />
              <BadgerProductsLink />
              <BadgerProgramsLink />
              <BadgerReportLink />
              <BadgerSuppliersLink />
            </Can>
            <Can do={"read"} on="dashboard.bl-supplier-users">
              <BadgerSupplierUsersLink />
            </Can>
          </Menu.Menu>
        </Menu.Item>
      )}

      <Can do={"read"} on={"dashboard.orders"}>
        {() => (
          <Menu.Item>
            <Icon name="cubes" />
            Orders
            <Menu.Menu>
              <OrdersLink />
              <ProofsLink />
              {!isVendor && (
                <React.Fragment>
                  <OrderBuilderLink />
                  {/* <SalesOrdersLink /> */}
                  <OrderApprovalPendingLink />
                  <OrderVoidedLink />
                  {/* <Can do={"create"} on={"dashboard.design-jobs.assign"}>
                {() => <ManageDesignJobsLink />}
              </Can> */}

                  <Can do={"list"} on={"dashboard.orders.design-jobs"}>
                    <DesignJobsLink />
                  </Can>

                  <Can do={"list"} on={"dashboard.orders.late-job-detectors"}>
                    <LateJobDetectorsLink />
                  </Can>
                </React.Fragment>
              )}
              <StatusUpdate />
              {!isVendor && (
                <React.Fragment>
                  <ShipmentLog />
                  <PickListScannerLink />
                  <DocumentPrinterLink />
                </React.Fragment>
              )}
              <JobSchedulerLink />
            </Menu.Menu>
          </Menu.Item>
        )}
      </Can>

      <Can do={"read"} on={"dashboard.purchasing"}>
        {() => (
          <Menu.Item>
            <Icon name="shopping basket" />
            Purchasing
            <Menu.Menu>
              <Can do={"list"} on={"dashboard.rfqs"}>
                <RFQsLink />
              </Can>
              <Can do={"list"} on={"dashboard.purchase-orders"}>
                <PurchaseOrdersLink />
              </Can>

              <Can do={"list"} on={"dashboard.supplier-billing-reconciliation"}>
                <SupplierBillingReconciliationLink />
              </Can>
            </Menu.Menu>
          </Menu.Item>
        )}
      </Can>

      <Can do={"read"} on="dashboard.customers">
        {() => (
          <Menu.Item>
            <Icon name="address book outline" />
            Customers
            <Menu.Menu>
              <CustomersLink />
              <CustomersApprovalLink />
              <CustomersTaxExemptionReviewLink />
              <CustomersContactFormSubmissionsLink />
              <CustomersCustomQuotesLink />
              <CustomersTestimonialsLink />
            </Menu.Menu>
          </Menu.Item>
        )}
      </Can>

      <Can do={"read"} on="dashboard.accounting">
        {() => (
          <Menu.Item>
            <Icon name={"money"} />
            Accounting
            <Menu.Menu>
              <AccountingInvoicesLink />
              <AccountingOpenInvoicesLink />
              <ReportsAging />
            </Menu.Menu>
          </Menu.Item>
        )}
      </Can>

      <Can do={"read"} on="dashboard.production">
        {() => (
          <Menu.Item>
            <Icon name="factory" />
            Production
            <Menu.Menu>
              <ProductionBatchesLink />
              <ProductionPressControlLink />
              {/* <ProductionChannelsLink /> */}
              {/* <ProductionResourcesLink /> */}
              <ProductionOutsourceLink />
              {/* <ProductionVendorLink /> */}
              <ProductionInProductionLink />
              <ProductionDueDateLink />
              <ProductionShortRunBarcode />
              <ProductionWorkflowStations />
              <ProductionStatusGrid />
              <FulfilmentStagingLocations />
            </Menu.Menu>
          </Menu.Item>
        )}
      </Can>

      <Can do={"read"} on="dashboard.reports">
        {() => (
          <Menu.Item>
            <Icon name="chart bar" /> Reports
            <Menu.Menu>
              <Reports />
              {/* <ReportsMicrositeOrdersFlattenedLink />
              <ReportsMicrositeShippedOrdersFlattenedLink />
              <ReportsMicrositeInactiveCustomersLink />
              <ReportsMicrositeInventoryLink />
              <ReportsMicrositeInventoryMovements />
              <ReportsMicrositeInventorLocations />
              <ReportsMicrositeInventoryProductLedger />
              <ReportsMicrositeFulfillment />
              <ReportsMicrositeFulfillmentByOrder />
              <ReportsInventoryBackOrder />
              <ReportsSales />
              <ReportsSalesLight />
              <ReportsSalesTax />
              <ReportsSalesTeam />
              <ReportsCustomerSales />
              <ReportsCustomerGrowth />
              <ReportsPayment />
              <ReportsCustoms />
              <ReportsResults /> */}
            </Menu.Menu>
          </Menu.Item>
        )}
      </Can>

      <Can do={"read"} on="dashboard.products">
        {() => (
          <Menu.Item>
            <Icon name="cube" />
            Products
            <Menu.Menu>
              <ProductsAttributesLink />
              <ProductsCategoriesLink />
              <ProductsPriceGridEditorLink />
              <ProductsMassUpdaterLink />
              <ProductsProductsLink />
              {/* <ProductsStockProductsLink /> */}
              <InventoryLink />
              <InventoryLocationsLink />
              <InventoryReceiveLink />
              <InventoryIncomingLink />
              <ProductsAddOnPricing />
            </Menu.Menu>
          </Menu.Item>
        )}
      </Can>

      <Can do={"read"} on="dashboard.flex">
        {() => (
          <Menu.Item>
            <Icon name="chat" />
            Flex
            <Menu.Menu>
              <FlexLiveChatLink />
            </Menu.Menu>
          </Menu.Item>
        )}
      </Can>

      <Menu.Item>
        <Icon name="settings" />
        Settings
        <Menu.Menu>
          <Can do={"read"} on="dashboard.settings">
            {() => (
              <React.Fragment>
                <SettingsLink />
                <SettingsAddressBookLink />
                <SettingsMicrositesLink />
              </React.Fragment>
            )}
          </Can>

          <Can do={"read"} on="dashboard.design-templates">
            {() => <SettingsDesignTemplatesLink />}
          </Can>
          <Can do={"read"} on="dashboard.settings">
            {() => (
              <React.Fragment>
                <AdminNotificationLog />
                <AdminEmailParseLog />
                <ArtworkTransporter />
              </React.Fragment>
            )}
          </Can>
        </Menu.Menu>
      </Menu.Item>
      {/* <Menu.Item>
        <a href={`https://www.${props.domainname}/admin/v3.5`}>
          <Icon name="chevron left" /> v3.5 Dashboard
        </a>
      </Menu.Item> */}
    </Menu>
  );
};
