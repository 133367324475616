import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { PageHeaderRow, PreLoader, useFeathers } from '@inkcloud/components';
import { Segment, Button } from 'semantic-ui-react';

import { PromoCodeForm } from './PromoCodeForm';

import { Feathers } from '../../../../bootstrap/feathers/feathers';

interface DetailProps {}

export interface ConnectedProps {
  user?: any;
}

const { useState } = React;

const serviceName = '/promo-codes';
const service = Feathers.service(serviceName);
const commonParams = { query: {} };

const pathPrefix = '/settings/promo/codes';

export const PreDetail: React.FunctionComponent<
  DetailProps & ConnectedProps & RouteComponentProps<{ id?: string }>
> = (props) => {
  const {
    match: {
      params: { id },
    },
    history,
    user,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [state, reload] = useFeathers('get', serviceName, [id, commonParams], true);

  const isEdit = !!id;

  if (isEdit && !state.data) {
    return <PreLoader />;
  }

  const initialData = { ...state.data };



  const handleSubmit = async (values: any) => {
    const maxCartItemsObj = values.maxCartItemsRestricted ? { maxCartItems: values.maxCartItems } : { $unset: { maxCartItems: 1}, maxCartItems: undefined };

    const payload = {
      ...values,
      promoCode: values?.promoCode?.trim() || null,
      campaign: values?.campaign || null,
      type: values?.type ?? 'percentage',
      tenant: user?.business,
      startDate: !isEdit ? values?.startDate?.toISOString() || undefined : values.startDate,
      expirationDate: !isEdit
        ? values?.expirationDate?.toISOString() || undefined
        : values.expirationDate,
      ...maxCartItemsObj,
    };

    setIsLoading(true);
    try {
      const res: any = isEdit
        ? await service.patch(id, payload, commonParams)
        : await service.create(payload, { query: {} });

      if (res.result !== undefined && res.result === false) {
        setErrorMessage(res.message || res.errorMessage);
      }
      history.push(pathPrefix);
    } catch (e) {
      const errMsg =
        e?.code < 500 ? e.message : 'We are experiencing technical difficulties. Please try again';
      setErrorMessage(errMsg);
    }
    setIsLoading(false);
  };

  return (
    <>
      <PageHeaderRow header={`${isEdit ? 'Edit' : 'New'} Promo Code`} subheader="Settings">
        <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
      </PageHeaderRow>
      <Segment>
        {(!isEdit || state.data) && (
          <PromoCodeForm
            initialValues={isEdit ? initialData : { ...initialData, appliesTo: 'entire-order' }}
            errorMessage={errorMessage}
            isSubmitting={isLoading}
            onSubmit={handleSubmit}
          />
        )}
      </Segment>
    </>
  );
};

const mapStateToProps = (state): ConnectedProps => ({
  user: state.auth.user || {},
});

export const Detail = connect(mapStateToProps)(PreDetail as any);
