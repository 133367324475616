import * as React from 'react';
import { Button, Segment, Table, Popup } from 'semantic-ui-react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { FormattedDate, FormattedTime } from 'react-intl';
import { humanize } from 'underscore.string';
import {
  useList,
  PageHeaderRow,
  PaginatorWrapper,
  EmptyWrapper,
  ListTable,
  FeathersDropdown,
} from '@inkcloud/components';
import { DetailTable } from './../../common/DetailTable';

import { FilterForm } from './FilterForm';

import Feathers from '../../bootstrap/feathers';

import { AssignDesignerModal } from './AssignDesignerModal';

interface ListProps {}

const serviceName = 'design-jobs';
const pathPrefix = `/design-jobs`;

const filterQueryMap = (values) => {
  const filterMap: any = {
    status: values.status ? values.status : undefined,
    assignedDesigner: values.assignedDesigner ? values.assignedDesigner : undefined,
    searchOrderItemHumanId: values.searchOrderItemHumanId
      ? values.searchOrderItemHumanId
      : undefined,
  };

  return [].reduce(
    (m, field) => ({
      ...m,
      [field]: values[field] ? values[field] : undefined,
    }),
    filterMap
  );
};

const { useState } = React;

export const List: React.FunctionComponent<ListProps & RouteComponentProps<any>> = (props) => {
  const {
    match: { params },
    location,
    history,
  } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [id, setId] = useState(null);
  const [assignedDesignerId, setAssignedDesigner] = useState(null);

  const [selectedDesigner, setSelectedDesigner] = useState(null);
  const [selectedHumandId, setSelectedHumandId] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const handleOpenModal = (id: string, design: string, humanId: string) => {
    setId(id);
    setAssignedDesigner(design);
    setSelectedHumandId(humanId);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setId(null);
    setAssignedDesigner(null);
  };

  const tableDefinition = [
    {
      key: 'createdAt',
      label: 'Date',
      sortable: true,
      cell: (v) =>
        v && (
          <Popup
            size="mini"
            content={<FormattedTime value={v} />}
            trigger={
              <div>
                <FormattedDate value={v} />
              </div>
            }
          />
        ),
    },
    {
      key: 'humanId',
      label: 'ID',
      sortable: true,
      cell: (v, r) => <>{r?.orderItem?.humanId}</>,
    },
    {
      key: '',
      label: 'Order Item Status',
      sortable: true,
      cell: (v, r) => <>{r?.orderItem?.productionStatus?.description}</>,
    },
    {
      key: 'status',
      label: 'Status',
      sortable: true,
      cell: (v, r) => <>{humanize(v)}</>,
    },
    {
      key: '',
      label: 'Reference',
      sortable: true,
      cell: (v, r) => <>{r?.orderItem?.customerJobName}</>,
    },
    {
      key: '',
      label: 'Description',
      sortable: true,
      cell: (v, r) => (
        <>
          {r.orderItem && (
            <>
              <DetailTable
                quantity={r.orderItem.quantity}
                attributes={r.orderItem.attributes}
                addOns={r.orderItem.addOns}
                description={r.orderItem.description}
              />
              <Table size="small" basic="very" compact style={{ marginTop: '-1em' }}>
                <Table.Body>
                  {r.orderItem.customTrimWidth && (
                    <Table.Row>
                      <Table.Cell collapsing style={{ borderTop: 0 }}>
                        <strong>Custom width</strong>
                      </Table.Cell>
                      <Table.Cell style={{ borderTop: 0 }}>
                        {r.orderItem.customTrimWidth}
                      </Table.Cell>
                    </Table.Row>
                  )}
                  {r.orderItem.customTrimHeight && (
                    <Table.Row>
                      <Table.Cell collapsing style={{ borderTop: 0 }}>
                        <strong>Custom height</strong>
                      </Table.Cell>
                      <Table.Cell style={{ borderTop: 0 }}>
                        {r.orderItem.customTrimHeight}
                      </Table.Cell>
                    </Table.Row>
                  )}
                </Table.Body>
              </Table>
            </>
          )}
        </>
      ),
    },
    {
      key: '',
      label: 'Customer',
      sortable: true,
      cell: (v, r) => (
        <Link to={`customers/${r?.customer?._id}`}>
          <div>{r?.customer?.organizationName}</div>
          {r?.customer?.primaryContact?.firstName} {r?.customer?.primaryContact?.lastName}
        </Link>
      ),
    },
    {
      key: '',
      label: 'Assigned Designer',
      sortable: true,
      cell: (v, r) => (
        <>{`${r?.assignedDesigner?.firstName ?? ''} ${r?.assignedDesigner?.lastName ?? ''}`}</>
      ),
    },
    {
      key: '',
      label: '',
      sortable: false,
      cell: (v, r) => (
        <>
          <Popup
            size="mini"
            content="View Order"
            trigger={
              <Link to={'/orders/' + r.orderItem.order}>
                <Button size={'mini'} icon={'arrow circle right'} />
              </Link>
            }
          />

          <Popup
            size="mini"
            content="Assign a Designer"
            trigger={
              <Button
                size={'mini'}
                icon={'user outline'}
                onClick={() =>
                  handleOpenModal(r.orderItem._id, r?.assignedDesigner?._id, r?.orderItem?.humanId)
                }
              />
            }
          />
          <Popup
            size="mini"
            content="Edit"
            trigger={
              <Link to={`${pathPrefix}/${r._id}`}>
                <Button size={'mini'} icon={'edit'} />
              </Link>
            }
          />
        </>
      ),
      textAlign: 'right',
    },
  ];

  const {
    state,
    reload,
    handleDelete,
    selectedItems,
    clearAll,
    setChecked,
    someSelected,
    toggleAll,
    filter,
    setFilter,
    sort,
    handleSort,
  } = useList({
    feathers: [
      'find',
      serviceName,
      [
        {
          query: {
            $populate: ['orderItem', 'customer', 'assignedDesigner'],
            $populateOrderItemAttributes: 1,
          },
        },
      ],
    ],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  }) as any;

  const { queryResult, isLoading: isStateLoading } = state as any;

  const resolvedQueryResult = queryResult?.data ? queryResult.data : queryResult;

  const handleAssignDesigner = async () => {
    setIsLoading(true);
    try {
      const filteredItems = Object.keys(selectedItems)?.filter((key) => selectedItems[key]);

      const res = filteredItems.map(async (f) =>
        Feathers.service('order-items/assign-designer').create(
          {
            orderItemId: resolvedQueryResult?.find((r) => r?._id === f)?.orderItem?._id,
            userId: selectedDesigner,
          },
          { query: {} }
        )
      );

      await Promise.all(res);

      reload();
    } catch (e) {}
    setIsLoading(false);
  };

  return (
    <div>
      <PageHeaderRow header="Design Jobs" subheader="" />

      <FilterForm value={filter} onChange={(v) => setFilter(v)} onReset={() => setFilter({})} />

      <Segment>
        <EmptyWrapper queryResult={resolvedQueryResult} isStateLoading={isStateLoading}>
          <PaginatorWrapper.Top
            filter={filter}
            reload={reload}
            data={queryResult as any}
            pathPrefix={`${pathPrefix}`}
          />
          <ListTable
            data={resolvedQueryResult}
            definition={tableDefinition}
            selectedItems={selectedItems}
            setChecked={setChecked}
            sort={sort}
            sortClickFunction={handleSort}
            toggleAll={toggleAll}
            withCheckboxes={true}
          />

          <PaginatorWrapper.Bottom
            reload={reload}
            data={queryResult as any}
            pathPrefix={pathPrefix}
          >
            {someSelected && (
              <>
                <FeathersDropdown
                  selection
                  fluid
                  search
                  clearable
                  placeholder="Designers..."
                  serviceName="/users/get-by-role"
                  query={{
                    $sort: { firstName: 1 },
                    $np: 1,
                    key: 'designer',
                  }}
                  resultMapper={(r) => ({
                    value: r._id,
                    text: `${r?.firstName ?? ''} ${r?.lastName ?? ''}`,
                  })}
                  value={selectedDesigner}
                  onChange={(e, data) => setSelectedDesigner(data.value)}
                />
                <br />
                <Button
                  primary
                  type="button"
                  content="Assign a Designer"
                  loading={isLoading}
                  disabled={isLoading}
                  onClick={handleAssignDesigner}
                />
              </>
            )}
          </PaginatorWrapper.Bottom>
          {isModalOpen && (
            <AssignDesignerModal
              id={id}
              humanId={selectedHumandId}
              assignedDesignerId={assignedDesignerId}
              isModalOpen={isModalOpen}
              handleClose={handleCloseModal}
              onReload={reload}
            />
          )}
        </EmptyWrapper>
      </Segment>
    </div>
  );
};
