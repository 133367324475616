import * as React from 'react';
import {
  Accordion,
  Button,
  Label,
  Dropdown,
  Checkbox,
  Header,
  Icon,
  Image,
  Grid,
  Modal,
  Popup,
  Message,
  Segment,
  Table,
  Input,
} from 'semantic-ui-react';
import type { CheckboxProps } from 'semantic-ui-react';
import { FormattedDate, FormattedTime } from 'react-intl';
import { humanize } from 'underscore.string';
import { Link, RouteComponentProps } from 'react-router-dom';
import { useList, PageHeaderRow, PaginatorWrapper, EmptyWrapper } from '@inkcloud/components';

import feathers, { socket } from '../../../bootstrap/feathers';

import {
  printModalWrapper,
  InjectedProps as PrintModalInjectedProps,
  PrintTypes,
} from '../../../common/PrintModal';

import { FilterForm } from './FilterForm';
import { ModalStatus } from './ModalStatus';
import { ImpositionModal } from './ImpositionModal';
import { GenerateModal } from './GenerateModal';

interface ListProps {}

const { useEffect, useState, useRef } = React;

const serviceName = 'production/batches';
const pathPrefix = `/production/press-control`;

const filterQueryMap = (values: any) => {
  const filterMap: any = {
    status: values.status ? values.status : undefined,
    paper: values.paper ? values.paper : undefined,
    'items.numUps': values.numUps ? values.numUps : undefined,
    searchOrderItemHumanId: values.searchOrderItemHumanId
      ? values.searchOrderItemHumanId
      : undefined,
  };

  return [].reduce(
    (m, field) => ({ ...m, [field]: values[field] ? values[field] : undefined }),
    filterMap
  );
};

const statusChange = async (status: any, impositionId) =>
  feathers.service('/production/batches').patch(impositionId, {
    status,
  });

const messageStyle = {
  maxWidth: '550px',
  marginLeft: 'auto',
  marginRight: 'auto',
};

export const PreList: React.FunctionComponent<
  ListProps & PrintModalInjectedProps & RouteComponentProps<any>
> = (props) => {
  const {
    match: { params },
    location,
    history,
  } = props;
  const {
    state,
    reload,
    handleDelete,
    selectedItems,
    clearAll,
    setChecked,
    someSelected,
    toggleAll,
    filter,
    setFilter,
    sort,
    handleSort,
  } = useList({
    feathers: [
      'find',
      serviceName,
      [
        {
          query: { $populate: ['paper', 'coating', 'color', 'items.orderItem', 'requestedBy'] },
        },
      ],
    ],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  const [isOpen, setIsOpen] = useState(false);
  const [impositionId, setImpositionId] = useState(null);
  const [statusValue, setStatusValue] = useState(null);
  const [selectedHumanId, setSelectedHumanId] = useState(null);
  const [isScanCompleteEnabled, setIsScanCompleteEnabled] = useState(false);
  const [isGenerateModalOpen, setIsGenerateModalOpen] = useState(false);

  const [isRequestImpositionOpen, setIsRequestImpositionOpen] = useState(false);

  const [humanId, setHumanId] = useState('');
  const [barcodeInput, setBarcodeInput] = useState('');
  const [errorMessage, setErrorMessage] = useState({
    barcode: '',
    status: '',
    generate: '',
  });

  const [responseMessage, setResponseMessage] = useState({
    barcode: '',
  });

  const [isLoading, setIsLoading] = useState({
    barcode: false,
  });

  const [isSubmitting, setIsSubmitting] = useState({
    generate: false,
  });

  // Real-time: Subscribe to any changes and reload
  React.useEffect(() => {
    const handler = (msg) => reload();
    socket.service('production/batches').on('patched', handler);
    socket.service('production/batches').on('created', handler);
    return () => {
      socket.service('production/batches').removeListener('patched', handler);
      socket.service('production/batches').removeListener('created', handler);
    };
  }, []);

  useEffect(() => {
    const load = async () => {
      let unmounted = false;
      setErrorMessage({ ...errorMessage, barcode: '' });
      setIsLoading({ ...isLoading, barcode: true });
      setResponseMessage({ ...responseMessage, barcode: '' });

      try {
        /*
          We should be searching by both order item Human ID and imposition ID
          However searchOrderItemHumanId does not work in an $or
          so only searching by item human ID for now.
          Must fix on backend OR make two separate calls instead of one $or call
        */
        const res = await feathers.service('/production/batches').find({
          query: {
            status: { $ne: 'print-complete' },
            searchOrderItemHumanId: humanId,
            // $or: [
            //   // { humanId: parseInt(humanId) },
            //   { searchOrderItemHumanId: humanId }
            // ]
          },
        });

        if (!unmounted && res?.data?.length > 0) {
          try {
            const resChange = await statusChange('print-complete', res.data[0]._id);

            if (resChange) {
              setIsLoading({ ...isLoading, barcode: false });
              setResponseMessage({
                ...responseMessage,
                barcode: `Imposition ${humanId} marked complete`,
              });
              setHumanId('');
              setBarcodeInput('');
              setTimeout(() => {
                setResponseMessage({ ...responseMessage, barcode: '' });
              }, 6000);
            }
          } catch (e) {
            setIsLoading({ ...isLoading, barcode: false });
            setErrorMessage({
              ...errorMessage,
              barcode:
                e.code && e.code < 500
                  ? e.message || e.errorMessage
                  : 'We are experiencing technical difficulties.',
            });
          }

          setHumanId('');
        } else {
          setIsLoading({ ...isLoading, barcode: false });
          setErrorMessage({
            ...errorMessage,
            barcode: `Imposition ${humanId} could not be found or might already be marked complete`,
          });
        }
      } catch (e) {
        setIsLoading({ ...isLoading, barcode: false });
        setErrorMessage({
          ...errorMessage,
          barcode:
            e.code && e.code < 500
              ? e.message || e.errorMessage
              : 'We are experiencing technical difficulties.',
        });
        setHumanId('');
      }

      return () => {
        unmounted = true;
      };
    };

    if (humanId) {
      load();
    }
  }, [humanId]);

  const handleSendToPressClick = async (id: string) => {
    const result = await feathers
      .service('/production/batches/send-to-press')
      .create({ impositionId: id });

    if (result) {
      reload();
    }
  };

  const handleChangeStatus = async (status: string) => {
    try {
      setErrorMessage({ ...errorMessage, status: '' });

      const result = await statusChange(status, impositionId);

      if (result) {
        reload();
      }

      setImpositionId(null);
      setStatusValue(null);
      setIsOpen(false);
    } catch (e) {
      setErrorMessage({
        ...errorMessage,
        status:
          e.code && e.code < 500
            ? e.message || e.errorMessage
            : 'We are experiencing some difficulties.',
      });
    }
  };

  const handleScan = async (e: any) => {
    if (e.key === 'Enter') {
      const { value } = e.target;

      setHumanId(value);
    }
  };

  const handleGerenateSubmit = async () => {
    setIsSubmitting({
      ...isSubmitting,
      generate: true,
    });
    setErrorMessage({
      ...errorMessage,
      generate: '',
    });

    try {
      const res = await feathers
        .service('/production/batches/trigger-render')
        .create({ impositionId });

      setImpositionId(null);
      setIsGenerateModalOpen(false);
    } catch (e) {
      const errMsg = e?.code < 500 ? e.message : 'We are experiencing some difficulties.';
      setErrorMessage({
        ...errorMessage,
        generate: errMsg,
      });
    }

    setIsSubmitting({
      ...isSubmitting,
      generate: true,
    });
  };

  const { queryResult, isLoading: isStateLoading } = state as any;

  if (!queryResult) {
    return null;
  }

  return (
    <>
      <Segment basic floated="right">
        <Button
          size="tiny"
          content="Request Imposition"
          onClick={() => setIsRequestImpositionOpen(true)}
          style={{ marginRight: '10px' }}
        />
        <Checkbox
          toggle
          label="Enable Scan Complete"
          onChange={(e, data: CheckboxProps) => {
            setErrorMessage({ ...errorMessage, barcode: '' });
            setIsScanCompleteEnabled(Boolean(data.checked));
          }}
        />
      </Segment>
      <PageHeaderRow header="Press Control" subheader="Production" />

      {isScanCompleteEnabled && (
        <Segment textAlign="center">
          <Header icon textAlign="center">
            <Icon name="barcode" />
            Scan imposition to mark complete.
          </Header>
          {responseMessage.barcode && (
            <Message success content={responseMessage.barcode} style={messageStyle} />
          )}
          {errorMessage.barcode && (
            <Message error content={errorMessage.barcode} style={messageStyle} />
          )}
          <Input
            size={'large'}
            disabled={isLoading.barcode}
            loading={isLoading.barcode}
            onKeyPress={handleScan}
            onChange={(e, data) => setBarcodeInput(data.value)}
            value={barcodeInput}
            autoFocus
          />
        </Segment>
      )}
      <FilterForm value={filter} onChange={(v) => setFilter(v)} onReset={() => setFilter({})} />

      <EmptyWrapper queryResult={queryResult} isStateLoading={isStateLoading}>
        <Segment loading={state.isLoading}>
          <PaginatorWrapper.Top
            filter={filter}
            reload={reload}
            data={queryResult as any}
            pathPrefix={`${pathPrefix}/`}
          />
          <Table size={'small'} sortable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>ID</Table.HeaderCell>
                <Table.HeaderCell
                  sorted={sort.createdAt ? sort.createdAt : null}
                  onClick={() => handleSort('createdAt')}
                >
                  Created
                </Table.HeaderCell>
                <Table.HeaderCell />
                <Table.HeaderCell>Paper</Table.HeaderCell>
                <Table.HeaderCell>Coating</Table.HeaderCell>
                <Table.HeaderCell>Color</Table.HeaderCell>
                <Table.HeaderCell>Sheets</Table.HeaderCell>
                <Table.HeaderCell>Items(Ups)</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {queryResult &&
                (queryResult as any).data.map((r: any) => (
                  <Table.Row key={r._id}>
                    <Table.Cell>{r.humanId}</Table.Cell>
                    <Table.Cell>
                      <div>
                        <FormattedDate value={r.createdAt} />
                      </div>
                      <div>
                        <FormattedTime value={r.createdAt} />
                      </div>
                      {`${r?.requestedBy?.firstName ?? ''} ${r?.requestedBy?.lastName ?? ''}`}
                    </Table.Cell>
                    <Table.Cell width="3">
                      {r.thumbnailStorageUrl && (
                        <Modal
                          trigger={
                            <Image
                              fluid
                              src={`https://storage.googleapis.com/icstorage/${r.thumbnailStorageUrl[0]}`}
                            />
                          }
                        >
                          <Modal.Header>Imposition</Modal.Header>
                          <Modal.Content>
                            {r.thumbnailStorageUrl.map((t, idx) => (
                              <Image
                                key={idx}
                                fluid
                                src={`https://storage.googleapis.com/icstorage/${t}`}
                              />
                            ))}
                          </Modal.Content>
                        </Modal>
                      )}
                    </Table.Cell>
                    <Table.Cell>{r.paper ? r.paper.name : ''}</Table.Cell>
                    <Table.Cell>{r.coating ? r.coating.name : ''}</Table.Cell>
                    <Table.Cell>{r.color ? r.color.hrCode : ''}</Table.Cell>
                    <Table.Cell>{r.finalSheetCount}</Table.Cell>
                    <Table.Cell>
                      {r?.items?.map((oi) => (
                        <Label key={oi?.orderItem?._id} size={'mini'}>
                          {oi?.orderItem?.humanId} <Label.Detail>{oi?.numUps}</Label.Detail>
                        </Label>
                      ))}
                    </Table.Cell>
                    <Table.Cell>
                      <p>{humanize(r.status)}</p>
                      {r?.metadata?.find((m) => m.key === 'potentialDuplicateWarning') && (
                        <>
                          <div>
                            <strong>Possible Duplicate Warning!</strong>
                          </div>
                          {r.metadata.map((m) => (
                            <Label key={m?.value} size={'mini'}>
                              {m?.value}
                            </Label>
                          ))}
                        </>
                      )}
                    </Table.Cell>
                    <Table.Cell style={{ width: '200px' }}>
                      {r?.status !== 'canceled' && r?.status !== 'print-complete' && (
                        <Popup
                          content="Send to Press"
                          trigger={
                            <Button
                              size={'tiny'}
                              icon={'send'}
                              onClick={() => handleSendToPressClick(r._id)}
                            />
                          }
                        />
                      )}

                      <Popup
                        content="Change Status"
                        trigger={
                          <Button
                            size={'tiny'}
                            icon={'exchange'}
                            onClick={() => {
                              setIsOpen(true);
                              setImpositionId(r._id);
                              setStatusValue(r.status);
                              setSelectedHumanId(r?.humanId);
                            }}
                          />
                        }
                      />

                      {(r?.status === 'queued-for-generate' ||
                        r?.status === 'generate-failure') && (
                        <Popup
                          size="mini"
                          content="Re-Generate"
                          trigger={
                            <Button
                              size={'mini'}
                              icon={'refresh'}
                              onClick={() => {
                                setIsGenerateModalOpen(true);
                                setImpositionId(r._id);
                              }}
                            />
                          }
                        />
                      )}

                      <Popup
                        content="Download"
                        trigger={
                          <Button
                            as={'a'}
                            target="_blank"
                            href={`https://storage.googleapis.com/icstorage/${r.artworkStorageUrl}`}
                            size={'tiny'}
                            icon={'download'}
                          />
                        }
                      />

                      {r?.status !== 'canceled' && (
                        <Popup
                          content="Print"
                          trigger={
                            <Button
                              size={'tiny'}
                              // type={PrintTypes.JobTicketSingle}
                              payload={{
                                orderItemId: r?.items?.[0]?.orderItem?._id,
                                orderId: r?.items?.[0]?.orderItem?.order,
                              }}
                              onClick={(e, data) => props.printHandleOpen(data.type, data.payload)}
                              icon="print"
                            />
                          }
                        />
                      )}
                    </Table.Cell>
                  </Table.Row>
                ))}
            </Table.Body>
          </Table>
          <PaginatorWrapper.Bottom
            reload={reload}
            data={queryResult as any}
            pathPrefix={`${pathPrefix}`}
          >
            {someSelected && (
              <Button primary type="button" content="Delete" onClick={handleDelete} />
            )}
          </PaginatorWrapper.Bottom>
        </Segment>

        {isGenerateModalOpen && (
          <GenerateModal
            isOpen={isGenerateModalOpen}
            onClose={() => {
              setIsGenerateModalOpen(false);
              setImpositionId(null);
              setErrorMessage({
                ...errorMessage,
                generate: '',
              });
            }}
            onSubmit={handleGerenateSubmit}
            isSubmitting={isSubmitting.generate}
            errorGenerate={errorMessage.generate}
          />
        )}

        {isOpen && (
          <ModalStatus
            humanId={selectedHumanId}
            statusValue={statusValue}
            errorMessage={errorMessage.status}
            onClose={() => {
              setIsOpen(false);
              setImpositionId(null);
              setStatusValue(null);
              setSelectedHumanId(null);
            }}
            onChangeStatus={handleChangeStatus}
          />
        )}

        {isRequestImpositionOpen && (
          <ImpositionModal
            isOpen={isRequestImpositionOpen}
            onClose={() => setIsRequestImpositionOpen(false)}
            onReload={reload}
          />
        )}
      </EmptyWrapper>
    </>
  );
};

export const List = printModalWrapper(PreList);
