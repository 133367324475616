import React, { useEffect, useState } from 'react';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Alert,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Input,
} from '@chakra-ui/react';
import feathers from '../../../../bootstrap/feathers';

interface IQuantityModalProps {
  rfqId: string;
  itemId: string;
  quantities: number[];
  onReload?: () => void;
  onClose: () => void;
}

export default function QuantityModal(props: IQuantityModalProps) {
  const { rfqId, itemId, quantities, onReload, onClose } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [qty, setQty] = useState<{ now: number; new: number | undefined }[]>([]);

  useEffect(() => {
    setQty(quantities?.map((q) => ({ now: q, new: undefined })));
  }, []);

  const handleSave = async () => {
    setIsSubmitting(true);
    setErrorMessage('');

    try {
      const res = await feathers.service('rfqs/quantities').create({
        rfqId,
        itemId,
        quantities: qty,
      });

      onReload?.();
      onClose();
    } catch (e) {
      const errMsg = e?.code < 500 ? e?.message : 'Something went wrong. Please try again later.';

      setErrorMessage(errMsg);
    }
    setIsSubmitting(false);
  };

  // eslint-disable-next-line no-undef
  const handleChange = (currentQty: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    const updatedQty = qty?.map((q) => (q.now === currentQty ? { ...q, new: Number(value) } : q));
    setQty(updatedQty);
  };

  return (
    <>
      <Modal isOpen={true} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Quantities</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Now</Th>
                  <Th>New</Th>
                </Tr>
              </Thead>
              <Tbody>
                {quantities.map((q, i) => (
                  <Tr key={i}>
                    <Td>{q}</Td>
                    <Td>
                      <Input value={qty[i]?.new} onChange={handleChange(q)} />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
            {errorMessage && <Alert status="error">{errorMessage}</Alert>}
          </ModalBody>

          <ModalFooter>
            <Button isDisabled={isSubmitting} mr={3} onClick={onClose}>
              Close
            </Button>
            <Button
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
              colorScheme="blue"
              onClick={handleSave}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
