import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import {
  Modal,
  Icon,
  Message,
  Button,
  List,
  Table,
  Label,
  Input,
  Header,
  Segment,
} from 'semantic-ui-react';
import feathers, { Application } from '@feathersjs/feathers';
import socketio from '@feathersjs/socketio-client';
import * as io from 'socket.io-client';
import { asyncify, forEachLimit } from 'async';
import { ModelTypes } from '@inkcloud/icapi-types';

import Feathers from '../../bootstrap/feathers';
import { BarcodeScanner } from '../pick-list-scanner/BarcodeScanner';
import Settings, { SettingsValue } from './Settings';

interface DocumentPrinterProps {
  user: any;
}

declare let localStorage;

const { useState, useEffect, useRef } = React;

const DocumentPrinter: React.FunctionComponent<DocumentPrinterProps & RouteComponentProps> = (
  props
) => {
  const { user, history } = props;

  const settingString = localStorage.getItem('documentPrinterConfig');
  const documentPrinterConfig: SettingsValue = settingString ? JSON.parse(settingString) : {};
  const isConfigured = documentPrinterConfig;

  // const [humanId, setHumanId] = useState('')
  const [pickList, setPickList] = useState([]);
  const [temp, setTemp] = useState([]);
  const [errorMessageScanLog, setErrorMessageScanLog] = useState('');
  const [errorMessageOrder, setErrorMessageOrder] = useState('');
  const [errorMessagePickList, setErrorMessagePickList] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isItemsLoading, setIsItemsLoading] = useState(false);

  const [isSettingOpen, setIsSettingsOpen] = useState(!isConfigured);
  const [settings, setSettings] = useState(documentPrinterConfig);

  const scanInput = useRef(null);
  const [inputValue, setInputValue] = useState('');

  const socketClient = useRef<any>(null);
  const [printers, setPrinters] = useState([]);
  const [isToolConnected, setIsToolConnected] = useState(false);

  const [printableItems, setPrintableItems] = useState<{ url: string; name: string }[]>([]);

  useEffect(() => {
    const socketConnection = `http://localhost:8097`;

    // const socket = feathers();
    // console.log('!!!!!!!!!!!!!!!!!!!!we are here');
    // console.log('socketConnection', socketConnection);

    const socket = io(socketConnection);

    socket.on('connect', () => {
      // console.log('Socket connected!!!')
      setIsToolConnected(true);
    });

    socket.on('disconnect', () => {
      // console.log('Socket disconnected!!!')
      setIsToolConnected(false);
    });

    socket.on('printer-list', (data: any) => {
      // console.log('got a pritners list', data);
      setPrinters(data);
    });
    socketClient.current = socket;

    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    (scanInput as any)?.current?.focus();
  }, []);

  const handleScan = async (e: any) => {
    setErrorMessageOrder('');
    if (e.key === 'Enter') {
      const { value } = e.target;
      const humanId = value?.split('-')[0] ?? value;

      if (!humanId) {
        setErrorMessageOrder('Invalid order id');
      } else {
        setInputValue(humanId);

        try {
          setIsLoading(true);

          const res = await Feathers.service('/orders').find({
            query: {
              humanId,
            },
          });

          const orderId = res?.data?.[0]?._id;
          const order: ModelTypes.Orders = res?.data?.[0];

          if (!orderId) {
            setErrorMessageOrder('Invalid order id');
            setIsLoading(false);
            return;
          }

          // The OR is for PT hack. If that metadata field exists, print the invoice
          if (
            (settings.isInvoiceEnabled || order.metadata?.find((m) => m.key === 'CustomerRef1')) &&
            order.intakeChannel !== '63159bb08c57b6755d7a7d95'
          ) {
            const invoiceRes = await Feathers.service('/printable/invoice').create({
              orderId,
            });

            socketClient.current.emit('print', [
              {
                url: invoiceRes?.url,
                // name: settings.printerA
                name: settings[settings.invoicePrinter],
              },
            ]);

            setPrintableItems((prev) => [
              ...prev,
              {
                url: invoiceRes?.url,
                name: settings.printerA,
                // name: invoiceRes?.url?.split("/").pop(),
              },
            ]);
          }

          if (settings.isPackingSlipEnabled) {
            const packingRes = await Feathers.service('/printable/packing-slip').create({
              orderId,
            });

            socketClient.current.emit('print', [
              {
                url: packingRes?.url,
                // name: settings.printerA,
                name: settings[settings.packingSlipPrinter],
              },
            ]);

            setPrintableItems((prev) => [
              ...prev,
              {
                url: packingRes?.url,
                name: packingRes?.url?.split('/').pop(),
              },
            ]);
          }
          let orderItems;
          if (settings.isChangeStatusEnabled || settings.isOrderItemLabelEnabled) {
            orderItems = await Feathers.service('order-items').find({
              query: {
                order: orderId,
              },
            });
          }
          if (settings.isOrderItemLabelEnabled) {
            forEachLimit(
              orderItems.data,
              1,
              asyncify(async (orderItem) => {
                const orderItemRes = await Feathers.service('/printable/order-item-label').create({
                  orderItemId: orderItem._id,
                });

                socketClient.current.emit('print', [
                  {
                    url: orderItemRes?.url,
                    // name: settings.printerA,
                    // name: orderItemRes?.url?.split("/").pop(),
                    name: settings[settings.orderItemLabelPrinter],
                  },
                ]);
              })
            );

            // setPrintableItems(prev => [
            //   ...prev,
            //   {
            //     url: orderItemRes?.url,
            //     name: orderItemRes?.url?.split("/").pop(),
            //   }
            // ])
          }

          if (settings.isMiscDocumentsEnabled) {
            const miscDocuments = await Feathers.service('misc-documents/resolver').create({
              orderId,
            });

            forEachLimit(miscDocuments.documents, 1, (doc: any) => {
              if (doc.publicUrl) {
                socketClient.current.emit('print', [
                  {
                    url: doc?.publicUrl,
                    // name: settings.printerA,
                    // name: orderItemRes?.url?.split("/").pop(),
                    name: settings[doc.designatedPrinterKey],
                  },
                ]);
              }
            });
          }

          if (settings.isChangeStatusEnabled) {
            forEachLimit(
              orderItems.data,
              1,
              asyncify(async (orderItem) => {
                await Feathers.service('order-items/status-changer').update(orderItem._id, {
                  status: settings.orderItemStatus,
                });
              })
            );
          }

          setInputValue('');
        } catch (e) {
          console.error(e);
        }

        setIsLoading(false);
      }
    }
  };

  return (
    <Modal open={true} onClose={() => history.push('/')} closeIcon>
      <Modal.Header>
        {!isSettingOpen && (
          <Button
            style={{ float: 'right' }}
            size="tiny"
            content="Settings"
            icon="setting"
            onClick={() => setIsSettingsOpen(true)}
          />
        )}
        <Label
          style={{ float: 'right', marginRight: 8 }}
          color={isToolConnected ? 'green' : 'yellow'}
          icon="bolt"
          content={`Document Printer Tool ${isToolConnected ? 'Connected' : 'Not Detected'}`}
        />
        Document Printer
      </Modal.Header>
      <Modal.Content>
        {isSettingOpen && (
          <>
            <Message content="To use the document printer, map your local printers to the printer defintions in the system." />
            <Settings
              onChange={(v) => {
                localStorage.setItem('documentPrinterConfig', JSON.stringify(v));
                setSettings(v);
              }}
              printers={printers}
              // value={{ printerA: '', printerB: '', printerC: '', printerD: '', isInvoiceEnabled: false, isMiscDocumentsEnabled: false, isPackingSlipEnabled: false}}
              value={settings}
            />
            <Button content="Save Settings" onClick={() => setIsSettingsOpen(false)} />
          </>
        )}

        {!isSettingOpen && (
          <>
            <div style={{ textAlign: 'center' }}>
              <Header as="h4">Scan barcode or enter Order ID</Header>
              <Icon name="barcode" size="massive" />
            </div>
            {successMessage && <Message success content={successMessage} />}
            <div style={{ textAlign: 'center' }}>
              <Input
                size={'large'}
                onKeyPress={handleScan}
                onChange={(e) => setInputValue(e.target.value)}
                ref={scanInput}
                value={inputValue}
                loading={isLoading}
              />
            </div>

            {(errorMessageScanLog || errorMessageOrder) && (
              <Message error content={errorMessageScanLog || errorMessageOrder} />
            )}
          </>
        )}
      </Modal.Content>
    </Modal>
  );
};

const mapStateToProps = (state): DocumentPrinterProps => ({
  user: state.auth.user,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentPrinter as any);
