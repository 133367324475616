import * as React from 'react';
import {
  Button,
  Box,
  IconButton,
  Tooltip,
  Badge,
  Icon,
  Text,
  TabList,
  Tab,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';

export const statusToColor = (status: string) => {
  switch (status) {
    case 'rfq-received':
    case 'supplier-response-received':
      return 'yellow';
    case 'estimate-sent':
    case 'order-awarded':
      return 'green';
    case 'sent-to-suppliers':
      return 'blue';
    default:
      return 'gray';
  }
};

export const orderStatusToColor = (status: string) => {
  switch (status) {
    case 'not-started':
      return 'yellow';
    case 'partial':
    case 'in-progress':
    case 'complete':
      return 'green';
    default:
      return 'gray';
  }
};

export const TruncatedText: React.FunctionComponent<{ text: string; limit?: number }> = ({
  text,
  limit = 17,
}) => {
  const truncatedText = text;

  if (text?.length < limit) {
    return <>{text}</>;
  }
  return (
    <Tooltip label={text} placement="top">
      <span>
        {truncatedText?.substring(0, limit) ? `${truncatedText?.substring(0, limit)}...` : ''}
      </span>
    </Tooltip>
  );
};

const statuses = [
  'draft',
  'open',
  'rfq-received',
  'sent-to-supplier',
  'supplier-response-received',
];

export function isEstimatePastDue(value) {
  return (
    statuses.find((s) => s === value.status) &&
    value.estimateDueDate &&
    new Date(value.estimateDueDate) < new Date()
  );
}
