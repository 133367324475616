import * as React from 'react';
import {
  Button,
  Divider,
  Form,
  Segment,
  Message,
  Label,
  Input,
  Checkbox,
  Grid,
} from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import countries from 'world-countries';
import Select, { components, OptionProps } from 'react-select';
import feathers from '../../../bootstrap/feathers';
import { LineItemGrid } from './LineItemGrid';
import { CustomerPicker, ICustomerPickerProps } from './CustomerPicker';
import { BillTo, ShipTo } from './AddressComponents';
import { IBillTo, IShipTo } from './primitives';
import { Summary } from './Summary';
import { initMapper, calculateSummary } from './helpers';
import { groupByKey } from '../../../common';
import { NumberInput } from './LineItemGrid/Inputs';

const emtpyAddress = {
  address1: '',
  address2: '',
  city: '',
  stateProvince: '',
  postalCode: '',
  countryCode: '',
};

const emptyBillTo = {
  company: '',
  name: '',
  ...emtpyAddress,
};

const emptyShipTo = {
  name: '',
  careOf: '',
  ...emtpyAddress,
};

interface OtherInfo {
  csr: string;
  shipDate?: any;
  customerPo: string;
  soDescription?: string;
  rfqNum: string;
}

interface IProduction {
  oversPercentage: number;
  undersPercentage: number;
}

interface IPayment {
  isTaxExempt: boolean;
  doesNeedDeposit: boolean;
  depositType: string;
  deposit: number;
}

interface IShipping {
  type: string;
  account: string;
  countryCode: string;
  postalCode: string;
  isInsurance: boolean;
  shippingService: string;
}

export const shippingMethodOptions = [
  { label: 'Shipping ship out', value: 'SHIPPING_SHIP_OUT' },
  { label: 'Shipping will call', value: 'SHIPPING_WILL_CALL' },
  { label: 'Shipping local delivery', value: 'SHIPPING_LOCAL_DELIVERY' },
];

export const shipmentPaymentTypes = [
  { label: 'Sender', value: 'SENDER' },
  { label: '3rd Party', value: 'THIRD_PARTY' },
  { label: 'Receiver', value: 'RECEIVER' },
  { label: 'Collect', value: 'COLLECT' },
];

const countryOptions = countries.map((c) => ({ label: c.name.official, value: c.cca2 }));

const initLineItem = {
  amount: 0,
  quantity: 0,
  total: 0,
  description: '',
  product: '',
  isTaxable: false,
};

export interface ISalesOrderProps {
  user?: any;
  initialValues: any;
  errorMessage: string;
  onSubmit: (v: any) => void;
}

export function SalesOrder(props: ISalesOrderProps) {
  const { initialValues = {}, errorMessage, onSubmit } = props;

  const tenant = useSelector(
    ({ globals }: { globals: { tenant: { internationalization: { countryCode: string } } } }) =>
      globals.tenant.internationalization
  );

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [lineItems, setLineItems] = React.useState(initMapper(initialValues, 'items', 'sales'));

  const [customerPicker, setCustomerPicker] = React.useState(
    initMapper(
      {
        ...initialValues,
        orderType: 'sales',
      },
      'customerPicker'
    )
  );

  const [microsites, setMicrosites] = React.useState([]);
  const [users, setUsers] = React.useState([]);

  const [billTo, setBillTo] = React.useState<IBillTo>(initialValues?.billTo);
  const [shipTo, setShipTo] = React.useState<IShipTo>(initialValues?.shipTo);
  const [otherInfo, setOtherInfo] = React.useState<OtherInfo>(
    initMapper(initialValues, 'otherInfo')
  );
  const [production, setProduction] = React.useState<IProduction>(initialValues?.production);
  const [payment, setPayment] = React.useState<IPayment>(initialValues?.payment);
  const [shipping, setShipping] = React.useState<IShipping>(initialValues?.shipping);

  const [taxRate, setTaxRate] = React.useState(initialValues?.taxRate ?? 0.0);
  const [shippingServicesOptions, setShippingServicesOptions] = React.useState([]);

  const [glAccounts, setGlAccounts] = React.useState([]);

  React.useEffect(() => {
    setOtherInfo(initMapper(initialValues, 'otherInfo'));
  }, [initialValues?._id]);

  React.useEffect(() => {
    (async () => {
      const res: any = await feathers.service('general-ledger-accounts').find({
        query: {
          accountType: 'income',
        },
      });

      setGlAccounts(
        res?.data?.map((d) => ({ text: `${d.accountNumber} ${d.name} `, value: d._id }))
      );
    })();

    const loadMicrosites = async () => {
      const results = await feathers
        .service('microsites')
        .find({ query: { $np: 1, $sort: { name: 1 }, $select: ['name'] } });
      setMicrosites(results.map((m) => ({ label: m.name, value: m._id })));
    };

    const loadUsers = async () => {
      const results = await feathers
        .service('users')
        .find({ query: { $np: 1, $sort: { firstName: 1 } } });
      setUsers(results.map((m) => ({ label: `${m.firstName} ${m.lastName}`, value: m._id })));

      setOtherInfo((prev) => ({
        ...prev,
        csr: initialValues?.csr || results?.find((r) => r._id === props?.user?._id)?._id,
      }));
    };

    const loadShippingServicesOptions = async () => {
      const result: any = await feathers.service('tenant-settings/shipping-services-options').find({
        query: {
          $np: 1,
          $sort: { name: 1 },
          all: 1,
        },
      });

      const mappedResult = () =>
        result?.map((r) => ({ ...r, value: r.service, label: r.serviceLabel }));

      setShippingServicesOptions(mappedResult);
    };

    loadShippingServicesOptions();
    loadMicrosites();
    loadUsers();
  }, []);

  const loadCustomers = async () => {
    const results = await feathers
      .service('customers')
      .find({ query: { $np: 1, $sort: { name: 1 } } });
    return results.map((m) => ({
      ...m,
      label: `${m.primaryContact?.firstName} ${m.primaryContact?.lastName}`,
      value: m._id,
    }));
  };

  const loadMicrositeCustomers = async () => {
    const results = await feathers
      .service('microsites/customers')
      .find({ query: { microsite: customerPicker.microsite, $np: 1, $sort: { firstName: 1 } } });
    return results.map((m) => ({ ...m, label: `${m.firstName} ${m.lastName}`, value: m._id }));
  };

  const loadProducts = async (inputValue) => {
    const baseQuery = { $np: 1, key: { $LIKE: inputValue }, $sort: { key: 1 } };
    if (customerPicker.mode === 'microsite') {
      const results = await feathers.service('microsites/products').find({
        query: { ...baseQuery, $populate: ['product'], microsite: customerPicker.microsite },
      });
      return results.map((m) => ({
        label: m.key,
        value: m._id,
        product: m.product?._id,
        name: m.name,
        glAccount: m?.product?.glIncomeAccount,
        amount: m?.product?.price || 0,
      }));
    }

    const results = await feathers.service('products-legacy').find({ query: { ...baseQuery } });
    return results.map((m) => ({
      label: m.key,
      value: m._id,
      name: m.staticName,
      amount: m.price,
    }));
  };

  const handlePickedCustomer = (v: ICustomerPickerProps['value'] & { customerData: any }) => {
    setCustomerPicker(v);

    if (v.mode === 'microsite' && v.microsite) {
      if (v?.customerData?._id === initialValues?.micrositeUser?._id) return;
      const p = {
        name: v.customerData?.companyName,
        careOf: v.customerData?.companyName
          ? `${v.customerData?.firstName ?? ''} ${v.customerData?.lastName ?? ''}`
          : '',
        address1: v.customerData?.address?.address1,
        address2: v.customerData?.address?.address2,
        city: v.customerData?.address?.city,
        stateProvince: v.customerData?.address?.stateProvince,
        postalCode: v.customerData?.address?.postalCode,
        countryCode: v.customerData?.address?.countryCode,
      };

      setBillTo(p);
      setShipTo(p);
    } else {
      if (v?.customer === initialValues?.customer?._id) return;
      const p = {
        name: v.customerData?.organizationName,
        careOf: v.customerData?.organizationName
          ? `${v.customerData?.primaryContact?.firstName ?? ''} ${
              v.customerData?.primaryContact?.lastName ?? ''
            }`
          : '',
        address1: v.customerData?.address1,
        address2: v.customerData?.address2,
        city: v.customerData?.city,
        stateProvince: v.customerData?.stateProvince,
        postalCode: v.customerData?.postalCode,
        countryCode: v.customerData?.countryCode,
      };
      setBillTo(p);
      setShipTo(p);
    }
  };

  const isCustomerSelected =
    (customerPicker.mode === 'microsite' &&
      customerPicker.microsite &&
      customerPicker.micrositeUser) ||
    (customerPicker.mode === 'customer' && customerPicker.customer);

  const handleAddLine = () => setLineItems(lineItems.concat(initLineItem));

  const summary = calculateSummary(lineItems, Number(taxRate));

  const handleSubmit = () => {
    setIsSubmitting(true);
    const payload = {
      ...(customerPicker || {}),
      production,
      payment,
      taxRate,
      shipping: {
        ...(shipping || {}),
        shippingService: shipping?.shippingService ?? shippingServicesOptions?.[0]?.service,
      },
      billTo,
      shipTo,
      items:
        lineItems?.map((i) => ({
          ...i,
          product: i?.product?.product || i?.product?.value,
          glAccount: i?.product?.glAccount || i?.glAccount,
        })) ?? [],
      ...(otherInfo || {}),
    };

    if (!payment?.doesNeedDeposit) {
      delete payload?.payment?.depositType;
      delete payload.payment?.deposit;
    }

    if (shipping?.type !== 'THIRD_PARTY') {
      delete shipping?.account;
      delete shipping?.postalCode;
      delete shipping?.countryCode;
    }

    onSubmit(payload);

    setIsSubmitting(false);
  };

  // console.log('customerPicker', customerPicker)

  // console.log('otherInfo', otherInfo)
  // console.log('production', production)
  // console.log('payment', payment)
  // console.log('shipping', shipping)

  return (
    <Segment>
      <div
        style={{
          display: 'flex',
          alignItems: 'stretch',
          alignContent: 'stretch',
          gap: 14,
        }}
      >
        <Segment
          style={{ flexGrow: 4, marginBottom: 0 }}
          color={!isCustomerSelected ? 'blue' : undefined}
        >
          <div style={{}}>
            <CustomerPicker
              style={{}}
              value={customerPicker}
              onChange={(v) => handlePickedCustomer(v)}
              microsites={microsites}
              loadCustomers={loadCustomers}
              loadMicrositeCustomers={loadMicrositeCustomers}
            />
            <Divider />
            {Object.keys(customerPicker?.customerData)?.length > 0 && (
              <>
                <Segment size="tiny">
                  <Label attached="top" content="Contact Info"></Label>
                  <Grid columns="equal">
                    <Grid.Column>
                      <strong>Phone: </strong>
                      {customerPicker?.customerData?.phone}
                    </Grid.Column>
                    <Grid.Column>
                      <strong>Email: </strong>
                      <a href={`mailto:${customerPicker?.customerData?.email}`}>
                        {customerPicker?.customerData?.email}
                      </a>
                    </Grid.Column>
                  </Grid>
                </Segment>
                <Divider />
              </>
            )}

            {isCustomerSelected && (
              <>
                <div
                  style={{
                    display: 'flex',
                    gap: 20,
                    alignItems: 'stretch',
                    alignContent: 'stretch',
                  }}
                >
                  <BillTo value={billTo} onChange={setBillTo} />
                  <ShipTo value={shipTo} onChange={setShipTo} />
                </div>
                <Form
                  size="mini"
                  style={{
                    display: 'flex',
                    gap: 20,
                    alignItems: 'stretch',
                    alignContent: 'stretch',
                    marginTop: 20,
                  }}
                >
                  <Segment
                    style={{ margin: 0, flexGrow: 1, display: 'flex', flexDirection: 'column' }}
                  >
                    <Label content={'Production'} attached={'top'} />
                    <Form.Field>
                      <label>% Overs</label>
                      <Input
                        fluid
                        size="small"
                        name="oversPercentage"
                        value={production?.oversPercentage}
                        onChange={(e, data: any) =>
                          setProduction({ ...production, [data.name]: data.value })
                        }
                      />
                    </Form.Field>
                    <Form.Field>
                      <label>% Unders</label>
                      <Input
                        fluid
                        size="small"
                        name="undersPercentage"
                        value={production?.undersPercentage}
                        onChange={(e, data: any) =>
                          setProduction({ ...production, [data.name]: data.value })
                        }
                      />
                    </Form.Field>
                  </Segment>
                  <Segment
                    style={{ margin: 0, flexGrow: 1, display: 'flex', flexDirection: 'column' }}
                  >
                    <Label content={'Payment'} attached={'top'} />
                    <Form.Field>
                      <Checkbox
                        label={'Tax Exempt'}
                        name="isTaxExempt"
                        checked={payment?.isTaxExempt}
                        onChange={(e, data: any) =>
                          setPayment({ ...payment, [data.name]: data.checked })
                        }
                      />
                    </Form.Field>
                    <Form.Field>
                      <Checkbox
                        label={'Needs Deposit'}
                        name="doesNeedDeposit"
                        checked={payment?.doesNeedDeposit}
                        onChange={(e, data: any) =>
                          setPayment({ ...payment, [data.name]: data.checked })
                        }
                      />
                    </Form.Field>
                    {payment?.doesNeedDeposit && (
                      <Form.Group widths={'equal'}>
                        <Form.Field inline>
                          <Checkbox
                            radio
                            label="Percent"
                            name="depositType"
                            value="percent"
                            checked={payment?.depositType === 'percent'}
                            onChange={(e, data: any) =>
                              setPayment({ ...payment, [data.name]: data.value })
                            }
                          />
                          <Checkbox
                            radio
                            label="Amount"
                            name="depositType"
                            value="amount"
                            checked={payment?.depositType === 'amount'}
                            onChange={(e, data: any) =>
                              setPayment({ ...payment, [data.name]: data.value })
                            }
                          />
                          <Form.Field>
                            <label>Deposit</label>
                            <Input
                              fluid
                              size="small"
                              name="deposit"
                              value={payment?.deposit}
                              onChange={(e, data: any) =>
                                setPayment({ ...payment, [data.name]: data.value })
                              }
                            />
                          </Form.Field>
                        </Form.Field>
                      </Form.Group>
                    )}
                  </Segment>
                  <Segment
                    style={{ margin: 0, flexGrow: 1, display: 'flex', flexDirection: 'column' }}
                  >
                    <Label content={'Shipping'} attached={'top'} />
                    <Form.Field>
                      <Select
                        onChange={(data: any) => setShipping({ ...shipping, type: data.value })}
                        placeholder="Shipping payment type..."
                        options={shipmentPaymentTypes}
                        defaultValue={
                          shipmentPaymentTypes?.find((s) => s.value === shipping?.type) ||
                          shipmentPaymentTypes[0]
                        }
                      />
                    </Form.Field>
                    {shipping?.type === 'THIRD_PARTY' && (
                      <>
                        <Form.Group widths={'equal'}>
                          <Form.Field>
                            <label>Account #</label>
                            <Input
                              fluid
                              name="account"
                              size="small"
                              value={shipping?.account}
                              onChange={(e, data: any) =>
                                setShipping({ ...shipping, [data.name]: data.value })
                              }
                            />
                          </Form.Field>
                          <Form.Field>
                            <label>Postal Code</label>
                            <Input
                              fluid
                              name="postalCode"
                              size="small"
                              value={shipping?.postalCode}
                              onChange={(e, data: any) =>
                                setShipping({ ...shipping, [data.name]: data.value })
                              }
                            />
                          </Form.Field>
                        </Form.Group>
                        <Form.Group widths={'equal'}>
                          <Form.Field>
                            <label>Country</label>
                            <Select
                              onChange={(v) => {
                                setShipping({ ...shipping, countryCode: v.value });
                              }}
                              options={countryOptions ?? []}
                              value={countryOptions?.find(
                                (c) => c.value === (shipping?.countryCode ?? tenant.countryCode)
                              )}
                            />
                          </Form.Field>
                        </Form.Group>
                      </>
                    )}
                    <Form.Field>
                      <Checkbox
                        label="Insurance"
                        name="isInsurance"
                        checked={shipping?.isInsurance}
                        onChange={(e, data: any) =>
                          setShipping({ ...shipping, [data.name]: data.checked })
                        }
                      />
                    </Form.Field>
                    <Form.Field>
                      <Select
                        onChange={(v) => {
                          setShipping({ ...shipping, shippingService: v.value });
                        }}
                        placeholder="Ship Service..."
                        options={groupByKey(shippingServicesOptions, 'carrier', 'carrierLabel')}
                        value={
                          shippingServicesOptions?.find(
                            (s) => s.value === shipping?.shippingService
                          ) || shippingServicesOptions[0]
                        }
                      />
                    </Form.Field>
                  </Segment>
                </Form>
              </>
            )}
          </div>
        </Segment>
        <Segment style={{ flexGrow: 1, marginTop: 0 }}>
          <Form size="mini">
            <Form.Field>
              <label>CSR</label>
              <Select
                onChange={(e) => setOtherInfo({ ...otherInfo, csr: e.value })}
                placeholder="Search CSR..."
                options={users}
                value={users?.find((u) => u?.value === otherInfo?.csr) || undefined}
                styles={{
                  container: (provided, state) => ({
                    ...provided,
                  }),
                  valueContainer: (provided, state) => ({
                    ...provided,
                  }),
                  control: (provided, state) => ({
                    ...provided,
                  }),
                }}
              />
            </Form.Field>
            <Form.Field>
              <label>Ship Date</label>
              <DatePicker
                onChange={(date) => setOtherInfo({ ...otherInfo, shipDate: date })}
                selected={otherInfo?.shipDate && new Date(otherInfo?.shipDate || null)}
                dateFormat="MM/dd/yyyy"
              />
            </Form.Field>
            <Form.Field>
              <label>RFQ</label>
              <Input
                value={otherInfo?.rfqNum}
                onChange={(e, data) => setOtherInfo({ ...otherInfo, rfqNum: data.value })}
              />
            </Form.Field>
            <Form.Field>
              <label>Customer PO</label>
              <Input
                value={otherInfo?.customerPo}
                onChange={(e, data) => setOtherInfo({ ...otherInfo, customerPo: data.value })}
              />
            </Form.Field>
            <Form.Field>
              <label>SO Description</label>
              <Input
                value={otherInfo?.soDescription}
                onChange={(e, data) => setOtherInfo({ ...otherInfo, soDescription: data.value })}
              />
            </Form.Field>
          </Form>
        </Segment>
      </div>

      <Segment disabled={!isCustomerSelected}>
        <LineItemGrid
          lineItems={lineItems}
          onChange={(v) => {
            setLineItems(v);
          }}
          glAccounts={glAccounts}
          productLoad={loadProducts}
          isDisabled={!isCustomerSelected}
          mode="sales-order"
          summary={summary}
          onAddLine={handleAddLine}
        />
      </Segment>
      <Segment>
        <label>Tax Rate </label>
        <NumberInput value={taxRate} onChange={(v) => setTaxRate(v)} />
      </Segment>
      {/* <Summary value={summary} /> */}
      <br />
      {errorMessage && <Message error content={errorMessage} />}
      <Button
        disabled={!isCustomerSelected || isSubmitting}
        loading={isSubmitting}
        primary
        content="Save"
        onClick={handleSubmit}
      />
    </Segment>
  );
}
