import * as React from 'react';
import { humanize } from 'underscore.string';
import { FormattedDate, FormattedTime } from 'react-intl';
import Feathers from './../../bootstrap/feathers';

import { EmptyWrapper } from '@inkcloud/components';

import {
  Button,
  Message,
  Label,
  Image,
  Table,
  Divider,
  Popup,
  Segment,
  Modal,
  Dimmer,
  Loader,
} from 'semantic-ui-react';
import { ErrorMessage } from 'formik';

interface PreFlightProps {
  id: string;
  attached?: any;
}

const { useState, useEffect } = React;

export const PreFlight: React.FunctionComponent<PreFlightProps> = (props) => {
  const { id, attached } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [preFlight, setPreFlight] = useState(null);

  const [isModalOpen, setModalOpen] = useState(false);
  const [url, setUrl] = useState(null);
  const [isLoadingArtwork, setIsLoadingArtwork] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [isSubmitting, setIsSubmitting] = useState({
    cancel: false,
  });

  useEffect(() => {
    const loadPreFlight = async () => {
      try {
        setIsLoading(true);
        const res: any = await Feathers.service('pre-flights').find({
          query: {
            order: id,
            $populate: ['slots.upload'],
          },
        });
        setPreFlight(res.data);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    loadPreFlight();
  }, [reload, id]);

  const handleDownloadArtwork = async (preFlightId: string) => {
    setModalOpen(true);
    setIsLoadingArtwork(true);
    try {
      const result: any = await Feathers.service('pre-flights/download-artwork').create({
        preFlightId,
      });

      setIsLoadingArtwork(false);
      setUrl(result.url);
    } catch (e) {
      setIsLoadingArtwork(false);
      e.code && e.code < 500
        ? setErrorMessage(e.message)
        : setErrorMessage('We are experiencing technical difficulties. Please try again');
    }
  };

  const handleCancelPreflight = async (preFlightId: string) => {
    setIsSubmitting({
      ...isSubmitting,
      cancel: true,
    });
    setReload(true);
    try {
      const result: any = await Feathers.service('pre-flights').patch(preFlightId, {
        status: 'canceled',
      });
    } catch (e) {
      const errMsg =
        e?.code < 500 ? e.message : 'We are experiencing technical difficulties. Please try again';
      setErrorMessage(errMsg);
    }

    setIsSubmitting({
      ...isSubmitting,
      cancel: false,
    });
    setReload(false);
  };

  const handlePrintAsIs = async (preFlightId: string) => {
    setIsLoading(true);
    try {
      const res: any = await Feathers.service('pre-flights/print-as-is').create({
        preFlightId,
      });

      setReload(!reload);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  if (!preFlight) {
    return null;
  }

  // const thumbUrl = 'https://ic-thumbs-inkcloud.netdna-ssl.com/ic-artwork-thumbs/reg/'
  const thumbUrl = 'https://storage.googleapis.com/artwork-thumbs/reg/';

  return (
    <Segment attached={attached}>
      <EmptyWrapper queryResult={preFlight} isStateLoading={isLoading}>
        <Table size="small" striped compact>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>ID</Table.HeaderCell>
              <Table.HeaderCell>Created</Table.HeaderCell>
              <Table.HeaderCell>Checked At</Table.HeaderCell>
              <Table.HeaderCell width={5}>Results</Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.HeaderCell>Result</Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {preFlight.map((item: any, index: number) => (
              <Table.Row key={index} verticalAlign="top">
                <Table.Cell>{item.orderItemHumanId}</Table.Cell>
                <Table.Cell>
                  <FormattedDate value={item.createdAt} />, <FormattedTime value={item.createdAt} />
                </Table.Cell>
                <Table.Cell>
                  <FormattedDate value={item.slaBy} />, <FormattedTime value={item.slaBy} />
                </Table.Cell>
                <Table.Cell>
                  {item.result === 'rejected' &&
                    item.slots &&
                    item.slots.map((i: any, idx: number) => (
                      <div key={idx}>
                        {i.sequence && <Label color="blue" size="tiny" content={i.sequence} />}
                        <Message size="tiny" content={i.message} />
                        {i.messageImages &&
                          i.messageImages.map((m: any, mi: number) => (
                            <Image
                              key={mi}
                              size="medium"
                              src={`https://storage.googleapis.com/icstorage/${m.url}`}
                            />
                          ))}
                        <Divider hidden />
                      </div>
                    ))}
                </Table.Cell>
                <Table.Cell>{humanize(item.status)}</Table.Cell>
                <Table.Cell>{humanize(item.result)}</Table.Cell>
                <Table.Cell textAlign="right">
                  <>
                    <Popup
                      content="Download Artwork"
                      trigger={
                        <Button
                          size="mini"
                          icon="download"
                          onClick={() => handleDownloadArtwork(item._id)}
                        />
                      }
                    />
                    {item.status === 'pending' && (
                      <Popup
                        content="Cancel"
                        trigger={
                          <Button
                            size="mini"
                            icon="close"
                            loading={isSubmitting.cancel}
                            disabled={isSubmitting.cancel}
                            onClick={() => handleCancelPreflight(item._id)}
                          />
                        }
                      />
                    )}

                    {item.allowPrintAsIs && !item.printAsIs && (
                      <Popup
                        content="Print As-is This Version"
                        trigger={
                          <Button
                            size="mini"
                            icon="clipboard check"
                            onClick={() => handlePrintAsIs(item._id)}
                          />
                        }
                      />
                    )}
                  </>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </EmptyWrapper>
      {isModalOpen && (
        <Modal open={isModalOpen} onClose={() => setModalOpen(false)} closeIcon>
          <Modal.Header>Download Artwork</Modal.Header>
          <Modal.Content style={{ position: 'relative', minHeight: '70px' }}>
            {isLoadingArtwork && (
              <Dimmer active inverted>
                <Loader inverted content="" />
              </Dimmer>
            )}
            {errorMessage && <Message error content={errorMessage} />}
            {url && (
              <Button size="tiny" content="Download" onClick={() => window.open(url, '_blank')} />
            )}
          </Modal.Content>
        </Modal>
      )}
    </Segment>
  );
};
