// import * as React from 'react';
// import * as ReactDOM from 'react-dom';
// import { connect } from 'react-redux';
// import { Button, Dropdown, Popup, Form, Search } from 'semantic-ui-react'

// export interface QuickSearchProps {
//   tenantInput: boolean;
//   feathers: any;
//   history: any;
//   microsite?: boolean;
// }

// export interface QuickSearchConnectedProps {
//   tenants?: any[];
// }

// export interface QuickSearchState {
//   tenant: string;
//   tenants: any[];
//   loading: boolean;
//   results: any[];
//   searchValue: string;
//   isOpen: boolean;
// }

// export class QuickSearch extends React.Component<QuickSearchProps & QuickSearchConnectedProps, QuickSearchState> {
//   constructor(props: QuickSearchProps & QuickSearchConnectedProps) {
//     super(props);

//     this.state = {
//       tenant: '',
//       tenants: [],
//       loading: false,
//       results: [],
//       isOpen: false,
//       searchValue: '',
//     }
//   }

//   initTenants(tenants) {
//     this.setState({ ...this.state, tenants: tenants.map(t => ({ value: t._id, text: t.domainName })) });
//   }

//   componentWillMount() {
//     if (this.props?.tenants?.length > 0) {
//       this.initTenants(this.props.tenants);
//     }
//   }

//   componentWillReceiveProps(next: QuickSearchProps & QuickSearchConnectedProps) {
//     this.initTenants(next.tenants);
//   }

//   handleTenantChange = (e, data) => {
//     this.setState({ ...this.state, tenant: data.value, searchValue: '', results: [], });
//   }

//   handleSearchChange = (e, data) => {
//     this.setState({ ...this.state, loading: true, searchValue: data.value });
//     if (!this.props.microsite) {
//       const tenant = this.state.tenant !== '' && this.props.tenantInput ? this.state.tenant : undefined;
//       this.props.feathers.service('/customers').find({
//         query: {
//           business: tenant,
//           $select: ['_id', 'humanId', 'organizationName', 'primaryContact', 'city', 'stateProvince', 'postalCode'],
//           $or: [
//             { organizationName: { $LIKE: data.value } },
//             { 'primaryContact.firstName': { $LIKE: data.value } },
//             { 'primaryContact.lastName': { $LIKE: data.value } },
//           ],
//         }
//       })
//         .then((results) => {
//           const mappedResults = results.data.map(r => {
//             let custName = r.organizationName;
//             if (r.primaryContact) {
//               if (!custName) {
//                 custName = `${custName || ''} ${r.primaryContact.firstName} ${r.primaryContact.lastName}`;
//               } else {
//                 custName = `${custName || ''} [${r.primaryContact.firstName} ${r.primaryContact.lastName}]`;
//               }
//             }
//             return ({
//               id: r._id, title: custName, description: `${r.city ? `${r.city}, ` : ''} ${r?.stateProvince ?? ''} ${r.humanId ? `[${r.humanId}]` : ''}`, key: r._id,
//             })
//           })
//           this.setState({ ...this.state, loading: false, results: mappedResults });
//         })
//     }

//   }

//   handleKeyPress = async (e) => {
//     if (e.key === 'Enter') {
//       const tenant = this.state.tenant !== '' && this.props.tenantInput ? this.state.tenant : undefined;
//       this.setState({ ...this.state, loading: true, results: [] });
//       let humanId = this.state.searchValue;

//       if (this.state.searchValue.indexOf('-') !== -1) {
//         const orderIdArr = this.state.searchValue.split('-');
//         humanId = orderIdArr[0];
//       }

//       try {
//         const res = await this.props.feathers.service('/orders').find({
//           query: {
//             business: tenant,
//             humanId,
//           }
//         })
//         if (res) {

//           console.log('test')
//           if (res.data.length > 0) {
//             (ReactDOM.findDOMNode(this) as any).querySelector('input').blur();
//             this.props.history.push(`/orders/${res.data[0]._id}`);
//           }
//         }
//       } catch (err) {
//         console.error(err)
//       }

//       this.setState({ ...this.state, loading: false, searchValue: '', results: [] })
//     }
//   }

//   handleResultSelect = (e, data) => {
//     this.props.history.push(`/customers/${data.result.id}`);
//     (ReactDOM.findDOMNode(this) as any).querySelector('input').blur();
//     this.setState({ ...this.state, searchValue: '', loading: false, results: [] });
//   }

//   handleToggleFocus = () => {
//     this.setState(prevstate => ({ ...this.state, isOpen: !prevstate.isOpen }))
//   }

//   render() {
//     return (
//       <Form size={'tiny'}>
//         <Form.Group inline style={{ margin: 0 }}>
//           {this.props.tenantInput && <Dropdown selection search options={this.state.tenants} placeholder={'Tenant...'} onChange={this.handleTenantChange} />}

//           <Popup
//             content={
//               <span>Searching for a customer? Start typing
//                 <br /><br />
//                 Looking for an order? Type the Order # and hit enter.</span>
//             }
//             header={'Tip'}
//             on='click'
//             position='bottom left'
//             inverted
//             open={!this.state.searchValue && this.state.isOpen}
//             onOpen={this.handleToggleFocus}
//             onClose={this.handleToggleFocus}
//             trigger={<Search
//               loading={this.state.loading}
//               onResultSelect={this.handleResultSelect}
//               onSearchChange={this.handleSearchChange}
//               results={this.state.results}
//               value={this.state.searchValue}
//               minCharacters={2}
//               onBlur={() => this.setState({ ...this.state, isOpen: false })}
//               disabled={this.props.tenantInput && this.state.tenant === ''}
//               placeholder={'Search...'}
//               onKeyPress={this.handleKeyPress}
//             />}
//           />

//         </Form.Group>
//       </Form>
//     );
//   }
// }

// const mapStateToProps = (state): QuickSearchConnectedProps => ({
//   tenants: state.globals.tenants,
// });

// const mapDispatchToProps = {};

// export default connect(mapStateToProps, mapDispatchToProps)(QuickSearch);

import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'

import { useDebounce } from '@inkcloud/components'

import {
  Box,
  Input,
  InputRightElement,
  InputGroup,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  Text,
  Spinner,
} from '@chakra-ui/react'
import { FaSearch } from 'react-icons/fa'

// import feathers from '../../bootstrap/feathers'

interface QuickSearchProps {
  feathers: any
}

export default function QuickSearch(props: QuickSearchProps) {
  const { feathers } = props

  const tenant = useSelector(
    ({ globals }: { globals: { tenant: { _id: string } } }) => globals.tenant
  )
  // eslint-disable-next-line no-undef
  const initRef = useRef<HTMLInputElement>(null)
  const history = useHistory()

  const [searchValue, setSearchValue] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [results, setResults] = useState<any[]>([])

  const debounceValue = useDebounce(searchValue, 350)

  // console.log('tenant', tenant)
  const loadSearch = async (value) => {
    setIsLoading(true)
    try {
      const res = await feathers.service('/customers').find({
        query: {
          // business: tenant._id,
          $select: [
            'humanId',
            'organizationName',
            'primaryContact',
            'city',
            'stateProvince',
            'postalCode',
          ],
          $or: [
            { organizationName: { $LIKE: value } },
            { 'primaryContact.firstName': { $LIKE: value } },
            { 'primaryContact.lastName': { $LIKE: value } },
          ],
        },
      })

      setResults(res.data)

      if (res?.data?.length) {
        setIsOpen(true)
      }
    } catch (e) {
      console.error(e)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    if (debounceValue) {
      loadSearch(debounceValue)
    }
  }, [debounceValue])

  const handleClose = () => {
    setSearchValue('')
    setIsOpen(false)
    initRef!.current!.value = ''
  }

  const handleKeyPress = async (e) => {
    if (e.key === 'Enter') {
      handleClose()

      try {
        const res = await feathers.service('/orders').find({
          query: {
            business: tenant,
            humanId: searchValue.indexOf('-') !== -1 ? searchValue.split('-')[0] : searchValue,
          },
        })

        setIsOpen(false)
        initRef?.current?.focus()

        if (res.data.length > 0) {
          history.push(`/orders/${res.data[0]._id}`)
        }
      } catch (err) {
        console.error(err)
      }
    }
  }

  return (
    <Box pos="relative" maxW="300px" w="100%">
      <InputGroup>
        <Input
          placeholder="Search..."
          ref={initRef}
          onBlur={handleClose}
          autoFocus
          onChange={(e) => setSearchValue(e.target.value)}
          onKeyPress={handleKeyPress}
        />
        <InputRightElement
          pointerEvents="none"
          children={isLoading ? <Spinner size="xs" /> : <FaSearch color="gray.300" />}
        />
      </InputGroup>
      {(debounceValue || isOpen) && (
        <Box
          pos="absolute"
          top="100%"
          left={0}
          right={0}
          zIndex="popover"
          overflowY="auto"
          maxH="350px"
          mt={2}
          p={3}
          borderWidth="1px"
          borderRadius="md"
          bg="white"
        >
          {results?.length
            ? results.map((r, index) => (
                <Box
                  key={r._id}
                  mb={2}
                  pt={index === 0 ? 0 : 2}
                  borderTop={index === 0 ? 0 : '1px solid var(--chakra-colors-gray-200)'}
                >
                  <Link to={`/customers/${r._id}`} onClick={handleClose}>
                    <Text color="black" fontWeight="bold">
                      {r?.organizationName ?? ''} [{r?.primaryContact?.firstName ?? ''}
                      {r?.primaryContact?.lastName ?? ''}]
                    </Text>
                    <Text fontSize="sm" color="gray.500">
                      {r?.city ?? ''} {r?.stateProvince ?? ''} {r?.humanId ?? ''}
                    </Text>
                  </Link>
                </Box>
              ))
            : !isLoading && (
                <Text>
                  {isNaN(Number(searchValue.split('-')[0]))
                    ? 'No results found'
                    : 'Hit "Enter" to search for an order'}
                </Text>
              )}
        </Box>
      )}
      {/* <Popover
        // returnFocusOnClose={false}
        // initialFocusRef={initRef}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        placement="bottom"
        closeOnBlur
      >
        <PopoverTrigger>
          
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverBody>
            
          </PopoverBody>
        </PopoverContent>
      </Popover>  */}
    </Box>
  )
}
