import * as React from 'react';
import moment from 'moment';
import { Button, Segment, Table, Popup } from 'semantic-ui-react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { FormattedDate, FormattedTime } from 'react-intl';
import { connect } from 'react-redux';

// import {
//   useList,
//   PageHeaderRow,
//   PreLoader,
//   PaginatorWrapper
// } from "@inkcloud/components";

import {
  useList,
  ListPage,
  basicQueryMap,
  generateOperatorOptions,
  Operator,
} from '@inkcloud/components';

import { FilterForm } from './FilterForm';
import { ExportModal } from '../reports/ExportModal';
import { Feathers } from '../../bootstrap/feathers/feathers';
import { countryStateProvinces } from '../../common';
// const filterMapper = filter => {
//   return {
//     organizationName: { $LIKE: filter && filter.name }
//     // 'primaryContact.firstName': { $LIKE: filter && filter.name },
//     // 'primaryContact.lastName': { $LIKE: filter && filter.name }
//   };
// };

interface ListProps {}

const serviceName = 'customers';
const pathPrefix = '/customers';

const { useEffect, useState } = React;

export const filterQueryMap = (values) => {
  const filterMap: any = {
    'primaryContact.firstName': values.firstName ? { $LIKE: values.firstName } : undefined,
    'primaryContact.lastName': values.lastName ? { $LIKE: values.lastName } : undefined,
    organizationName: values.organizationName ? { $LIKE: values.organizationName } : undefined,
    stateProvince: values.stateProvince ? values.stateProvince : undefined,
    postalCode: values.postalCode ? values.postalCode : undefined,
    email: values.email ? { $LIKE: values.email } : undefined,
    createdAt: {
      $gte: values.start ? moment(values.start).format('YYYY-MM-DD') : undefined,
      $lte: values.end ? moment(values.end).format('YYYY-MM-DD') : undefined,
    },
  };

  return [].reduce(
    (m, field) => ({
      ...m,
      [field]: values[field] ? values[field] : undefined,
    }),
    filterMap
  );
};

const countryCodes = ['US', 'AU', 'CA'];

const newFilterQueryMap = (values) => {
  let filterRows = basicQueryMap(values, {});

  filterRows = {
    ...filterRows,
    stateProvince: filterRows?.stateProvince?.$elemMatch.value,
  };

  return filterRows;
};
export interface ConnectedProps {
  user?: any;
}

export const PreList = (props: ListProps & ConnectedProps & RouteComponentProps) => {
  const {
    match: { params },
    location,
    history,
    user,
  } = props;

  const useListValues = useList({
    feathers: [
      'find',
      serviceName,
      [
        {
          query: {},
        },
      ],
    ],
    filterMap: newFilterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  const [isSubmitting, setSubmitting] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [exportUrl, setExportUrl] = useState('');

  // const { queryResult, isLoading: isStateLoading } = state as any;

  // const handleExport = async (all: string) => {
  //   setSubmitting(true);

  //   let newFilter: any = filter;
  //   let value: any;

  //   if (all) {
  //     value = {
  //       all: true
  //     };
  //   } else {
  //     value = {
  //       start: moment(newFilter.start).format("YYYY-MM-DD"),
  //       end: moment(newFilter.end).format("YYYY-MM-DD"),
  //       state: newFilter.stateProvince,
  //       postalCode: newFilter.postalCode
  //     };

  //     !newFilter.stateProvince && delete value.state;
  //     !newFilter.postalCode && delete value.postalCode;
  //   }

  //   const res: any = await Feathers.service(
  //     "/customers/export?$export=true"
  //   ).create(value);

  //   if (res) {
  //     setIsModalOpen(true);
  //     setExportUrl(res.url);
  //     setSubmitting(false);
  //   }
  // };
  const handleExport = async (payload: { [key: string]: string | boolean }) => {
    setSubmitting(true);
    try {
      const res: any = await Feathers.service('/customers/export?$export=true').create(payload);

      if (res) {
        setIsModalOpen(true);
        setExportUrl(res.url);
        setSubmitting(false);
      }
    } catch (e) {
      console.error(e);
    }

    setSubmitting(false);
  };

  const [countryCode, setCountryCode] = useState('');

  useEffect(() => {
    const loadTenant = async () => {
      try {
        const res = await Feathers.service('/tenant-settings').get(user.business, {
          query: {
            $select: ['internationalization'],
          },
        });

        setCountryCode(res?.internationalization?.countryCode || 'US');
      } catch (e) {
        console.error(e);
      }
    };

    loadTenant();
  }, [user?.business]);

  let stateProvince = 'State/Province';

  if (countryCode === 'AU') {
    stateProvince = 'State/Territory';
  } else if (countryCode === 'CA') {
    stateProvince = 'Province';
  } else if (countryCode === 'US') {
    stateProvince = 'State';
  }

  const isCountryIncluded = countryCodes.includes(countryCode);

  async function getFilterDefinition() {
    const definition = [
      {
        label: 'First Name',
        value: 'primaryContact.firstName',
        operators: generateOperatorOptions([Operator.Like]),
      },
      {
        label: 'Last Name',
        value: 'primaryContact.lastName',
        operators: generateOperatorOptions([Operator.Like]),
      },
      {
        label: 'Company Name',
        value: 'organizationName',
        operators: generateOperatorOptions([Operator.Like]),
      },
      {
        label: 'Foreign ID',
        value: 'foreignId',
        operators: generateOperatorOptions([Operator.Is]),
      },
      {
        label: 'ID',
        value: 'humanId',
        operators: generateOperatorOptions([Operator.Is]),
      },
      {
        label: 'Platform',
        value: 'platform',
        operators: generateOperatorOptions([Operator.Like]),
      },
      {
        label: 'Tags',
        value: 'tags',
        operators: generateOperatorOptions([Operator.Is]),
        valueOptions: [],
        type: 'multi-select-create',
      },
      {
        label: 'Metadata',
        value: 'metadata',
        operators: generateOperatorOptions([Operator.Is]),
        type: 'metadata',
      },
      {
        label: 'Email',
        value: 'email',
        operators: generateOperatorOptions([Operator.Like]),
      },
      {
        label: 'Date',
        value: 'createdAt',
        operators: generateOperatorOptions([Operator.Between]),
        type: 'date',
      },
      {
        label: stateProvince,
        value: 'stateProvince',
        operators: generateOperatorOptions([Operator.Is]),
        valueOptions: countryStateProvinces(countryCode)?.map((i: any) => ({
          value: i.value,
          label: i.text,
        })),
        type: 'select',
      },
      {
        label: 'Zip',
        value: 'postalCode',
        operators: generateOperatorOptions([Operator.Like]),
      },
    ];

    const updateDefinition = definition.map((d) => {
      if (d.value === 'stateProvince') {
        if (isCountryIncluded) {
          return d;
        }

        delete d.type;
        delete d.valueOptions;

        return {
          ...d,
          operators: generateOperatorOptions([Operator.Like]),
        };
      }
      return d;
    });

    return updateDefinition.sort((a: any, b: any) => a.label.localeCompare(b.label));
  }
  const tableDefintion = [
    {
      key: 'createdAt',
      label: 'Date',
      sortable: true,
      cell: (v, r) => (
        <Popup
          size="mini"
          content={<FormattedTime value={r.createdAt} />}
          trigger={
            <div>
              <FormattedDate value={r.createdAt} />
            </div>
          }
        />
      ),
    },
    {
      key: 'humanId',
      label: 'ID',
      cell: (v) => v,
    },
    {
      key: 'foreignId',
      label: 'Foreign ID',
      cell: (v) => v,
    },
    {
      key: '',
      label: 'First Name',
      cell: (v, r) => <>{r?.primaryContact?.firstName}</>,
    },
    {
      key: '',
      label: 'Last Name',
      cell: (v, r) => <>{r?.primaryContact?.lastName}</>,
    },
    {
      key: 'organizationName',
      label: 'Company Name',
    },
    {
      key: 'phone',
      label: 'Phone',
    },
    {
      key: 'email',
      label: 'Email',
      cell: (v, r) => <a href={`mailto:${v}`}>{v}</a>,
    },
    {
      key: '',
      label: '',
      cell: (v, r) => (
        <>
          <Popup
            size="mini"
            content="View Details"
            align
            trigger={
              <Link to={`${pathPrefix}/${r._id}`}>
                <Button size="mini" icon="edit" />
              </Link>
            }
          />
        </>
      ),
      textAlign: 'right',
    },
  ];

  const { filter = {} } = useListValues;

  const foundCreated = filter?.rows?.find((r) => r?.selectedFact === 'createdAt');
  const isDateHasValues = foundCreated?.value?.startValue && foundCreated?.value?.endValue;

  const stateValue = filter?.rows?.find((r) => r?.selectedFact === 'stateProvince')?.value;
  const state = isCountryIncluded ? stateValue?.value?.value : stateValue;

  const headerActions = (
    <>
      {isDateHasValues && (
        <Button
          type="button"
          primary
          size="mini"
          content="Export"
          loading={isSubmitting}
          disabled={isSubmitting}
          onClick={() =>
            handleExport({
              start: moment(foundCreated?.value?.startValue).format('YYYY-MM-DD'),
              end: moment(foundCreated?.value?.endValue).format('YYYY-MM-DD'),
              state,
              postalCode: filter?.rows?.find((r) => r?.selectedFact === 'postalCode')?.value,
            })
          }
        />
      )}
      <Button
        type="button"
        size="mini"
        content="Export All"
        loading={isSubmitting}
        disabled={isSubmitting}
        onClick={() => handleExport({ all: true })}
      />
      <Link to={`${pathPrefix}/add`}>
        <Button size="mini" primary content="New" />
      </Link>
    </>
  );

  // console.log('useListValues', useListValues.filter);

  return (
    <div>
      {/* <PageHeaderRow header="Customers" subheader="List">
          <Link to="/customers/add">
            <Button content="New" primary size={"mini"} />
          </Link>
        </PageHeaderRow>

        <FilterForm
          value={filter}
          onChange={v => setFilter(v)}
          onReset={() => setFilter({})}
          onExport={handleExport}
          isSubmitting={isSubmitting}
        />

        <Segment loading={state.isLoading}>
          <PaginatorWrapper.Top
            filter={filter}
            reload={reload}
            data={queryResult as any}
            pathPrefix={`${pathPrefix}`}
          />
          <Table size={"small"} sortable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  <input type="checkbox" onChange={toggleAll} />
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={sort.createdAt ? sort.createdAt : null}
                  onClick={() => handleSort("createdAt")}
                >
                  Date
              </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={sort.humanId ? sort.humanId : null}
                  onClick={() => handleSort("humanId")}
                >
                  ID
              </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={
                    sort["primaryContact.firstName"]
                      ? sort["primaryContact.firstName"]
                      : null
                  }
                  onClick={() => handleSort("primaryContact.firstName")}
                >
                  First Name
              </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={
                    sort["primaryContact.lastName"]
                      ? sort["primaryContact.lastName"]
                      : null
                  }
                  onClick={() => handleSort("primaryContact.lastName")}
                >
                  Last Name
              </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={sort.organizationName ? sort.organizationName : null}
                  onClick={() => handleSort("organizationName")}
                >
                  Company Name
              </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={sort.email ? sort.email : null}
                  onClick={() => handleSort("email")}
                >
                  Email
              </Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {queryResult &&
                (queryResult as any).data.map(r => (
                  <Table.Row key={r._id} verticalAlign="top">
                    <Table.Cell>
                      <input
                        type="checkbox"
                        value={r._id}
                        checked={selectedItems[r._id] || false}
                        onChange={setChecked}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <Popup
                        size="mini"
                        content={<FormattedTime value={r.createdAt} />}
                        trigger={
                          <div>
                            <FormattedDate value={r.createdAt} />
                          </div>
                        }
                      />
                    </Table.Cell>
                    <Table.Cell>{r.humanId}</Table.Cell>
                    <Table.Cell>{r?.primaryContact?.firstName}</Table.Cell>
                    <Table.Cell>{r?.primaryContact?.lastName}</Table.Cell>
                    <Table.Cell>{r.organizationName}</Table.Cell>
                    <Table.Cell>{r.email}</Table.Cell>
                    <Table.Cell>
                      <Popup
                        size="mini"
                        content="Edit"
                        trigger={
                          <Link to={`${pathPrefix}/${r._id}`}>
                            <Button size={"mini"} icon={"edit"} />
                          </Link>
                        }
                      />
                    </Table.Cell>
                  </Table.Row>
                ))}
            </Table.Body>
          </Table>
          <PaginatorWrapper.Bottom
            reload={reload}
            data={queryResult as any}
            pathPrefix={`${pathPrefix}`}
          >
            {someSelected && (
              <Button
                primary
                type="button"
                content="Delete"
                onClick={handleDelete}
              />
            )}
          </PaginatorWrapper.Bottom>
        </Segment> */}

      <ListPage
        header="Customers"
        subheader="List"
        useListValues={useListValues}
        history={history}
        pathPrefix={pathPrefix}
        headerActions={headerActions}
        tableDefintion={tableDefintion}
        withCheckboxes={true}
        getFilterDefinition={getFilterDefinition}
      />

      {exportUrl && (
        <ExportModal
          isModalOpen={isModalOpen}
          exportUrl={exportUrl}
          handleClose={() => {
            setIsModalOpen(false);
            setExportUrl('');
          }}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state): ConnectedProps => ({
  user: state.auth.user || {},
});

export const List = connect(mapStateToProps)(PreList as any);
