import * as React from 'react';
import { Field, reduxForm, change, formValueSelector, FormProps } from 'redux-form';
import { Link } from 'react-router-dom';
import { slugify } from 'underscore.string';
import { validateRequired, usStateProvinces } from '../../../common';
import { Form, Checkbox, Radio, Input, Dropdown, Button } from 'semantic-ui-react';
import { semanticFormField } from '../semantic-form-field';

const validate = (values) => {
  if (Object.keys(values).length === 0) {
    return;
  }

  return validateRequired(['secretKey', 'publishableKey'])(values);
};

const required = (value) => (value ? undefined : 'Required');

export default (props: FormProps) => {
  const { onSubmit, handleSubmit, pristine, reset, submitting } = props;
  return (
    <div>
      <Field
        name="name"
        component={semanticFormField}
        as={Form.Input}
        type="text"
        label="Name"
        placeholder="Name..."
        validate={required}
      />
      {/* <div className="two fields">

         <Field
          name='careOf'
          component={semanticFormField}
          as={Form.Input}
          type='text'
          label='Care of'
          placeholder='Care of...'
        />
      </div> */}
      <div className="two fields">
        <Field
          name="address1"
          component={semanticFormField}
          as={Form.Input}
          type="text"
          label="Address 1"
          placeholder="Address 1..."
        />

        <Field
          name="address2"
          component={semanticFormField}
          as={Form.Input}
          type="text"
          label="Address 2"
          placeholder="Address 2..."
        />
      </div>
      <div className="three fields">
        <Field
          name="city"
          component={semanticFormField}
          as={Form.Input}
          type="text"
          label="City"
          placeholder="City..."
        />
        <Field
          name="stateProvince"
          component={semanticFormField}
          options={usStateProvinces}
          as={Form.Dropdown}
          fluid
          selection
          label="State Province"
          placeholder="State Province..."
        />

        <Field
          name="postalCode"
          component={semanticFormField}
          as={Form.Input}
          type="text"
          label="Postal Code"
          placeholder="Postal Code..."
        />
      </div>
    </div>
  );
};
