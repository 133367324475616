import * as React from 'react';
import { FormattedDate } from 'react-intl';
import { humanize } from 'underscore.string';
import {
  Form,
  Input,
  Checkbox,
  Popup,
  Modal,
  Image,
  List,
  Button,
  Divider,
  Table,
  Grid,
  Segment,
  Message,
  TextArea,
} from 'semantic-ui-react';
import * as numeral from 'numeral';

import { DetailTable } from '../../common/DetailTable';
import { Feathers } from '../../bootstrap/feathers/feathers';

import { OrderitemModal } from './OrderItemModal';

import { useDebounce } from '@inkcloud/components';

interface OrderApprovalModalProps {
  isModalOpen: boolean;
  values: any;
  errorMessage: string;
  onClose: () => void;
  onChangeStatus: (v: string, r?: string) => void;
  onReload?: () => void;
  isSubmitting: boolean;
  onToggleAll?: (e: any, data: any) => void;
  onToApproveItems?: (item: any) => void;
  toApproveItems?: any[];
}

const { useState, useEffect } = React;

const baseUrl = `https://storage.googleapis.com/artwork-thumbs/reg/`;

export const OrderApprovalModal: React.FunctionComponent<OrderApprovalModalProps> = (props) => {
  const {
    isModalOpen,
    onClose,
    onChangeStatus,
    onReload,
    isSubmitting,
    values,
    onToggleAll,
    onToApproveItems,
    toApproveItems,
  } = props;

  const [costCenter, setCostCenter] = useState(values?.invoice?.costCenter ?? '');

  const [isLoading, setIsLoading] = useState({ costCenter: false });
  const [responseMessage, setRespostMessage] = useState({ costCenter: '' });
  const [errorMessage, setErrorMessage] = useState({ artwork: '', costCenter: '' });
  const [isChanging, setIsChanging] = useState(false);

  const [isOrderItemModalOpen, setIsOrderItemModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const [isRejectSelected, setIsRejectSelected] = useState(false);
  const [rejectionReason, setRejectionReason] = useState('');

  const debounceValue = useDebounce(costCenter, 350);

  const handleCostCenterSubmit = async () => {
    setRespostMessage({ ...responseMessage, costCenter: '' });
    setErrorMessage({ ...errorMessage, costCenter: '' });
    setIsLoading({ ...isLoading, costCenter: true });
    setIsChanging(false);

    try {
      const res: any = await Feathers.service('/invoices').patch(values.invoice?._id, {
        costCenter,
      });

      if (res?.result) {
        setErrorMessage({ ...errorMessage, costCenter: res.message || res.errorMessage });
      }

      setRespostMessage({ ...responseMessage, costCenter: 'Cost Center Updated' });
      onReload();
    } catch (e) {
      e.code && e.code < 500
        ? setErrorMessage({ ...errorMessage, costCenter: e.message })
        : setErrorMessage({
            ...errorMessage,
            costCenter: 'We are experiencing technical difficulties. Please try again',
          });
    }

    setIsLoading({ ...isLoading, costCenter: false });
  };

  useEffect(() => {
    if (isChanging) {
      handleCostCenterSubmit();
    }
  }, [debounceValue]);

  useEffect(() => {
    setCostCenter(values?.invoice?.costCenter);
  }, [values]);

  if (!values) {
    return null;
  }

  let shipMethod = values.shipment.providerName
    ? values.shipment.providerName + ' - ' + humanize(values.shipment.serviceKey)
    : humanize(values.shipment.serviceKey);

  const handleArtwork = async (orderItemId: string, sequence: string) => {
    setErrorMessage({ ...errorMessage, artwork: '' });
    try {
      const res: any = await Feathers.service('/artwork-uploads/download-artwork-v2').find({
        query: {
          orderItemId,
          sequence,
        },
      });

      if (res) {
        window.open(res.url, '_blank');
      }
    } catch (e) {
      e.code && e.code < 500
        ? setErrorMessage({ ...errorMessage, artwork: e.message })
        : setErrorMessage({
            ...errorMessage,
            artwork: 'We are experiencing technical difficulties. Please try again',
          });
    }
  };

  return (
    <Modal size={'large'} open={isModalOpen} onClose={onClose} closeIcon>
      <Modal.Header as="h6">Order Approval Status</Modal.Header>
      <Modal.Content>
        <Table basic="very">
          <Table.Body>
            <Table.Row>
              <Table.Cell collapsing verticalAlign="top">
                <strong>ID</strong>
              </Table.Cell>
              <Table.Cell>{values?.humanId}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell collapsing verticalAlign="top">
                <strong>Name</strong>
              </Table.Cell>
              <Table.Cell>
                {values.microsite?.name
                  ? `${values.microsite?.name} / ${values.micrositeUser?.firstName ?? ''} ${
                      values.micrositeUser?.lastName ?? ''
                    }`
                  : `${values.customer?.primaryContact?.firstName ?? ''} ${
                      values.customer?.primaryContact?.lastName ?? ''
                    }`}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell collapsing verticalAlign="top">
                <strong>Created</strong>
              </Table.Cell>
              <Table.Cell>
                <FormattedDate value={values.createdAt} />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell collapsing verticalAlign="top">
                <strong>Company</strong>
              </Table.Cell>
              <Table.Cell>{values?.customer?.organizationName}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell collapsing verticalAlign="top">
                <strong>Email</strong>
              </Table.Cell>
              <Table.Cell>{values?.customer?.email || 'N/A'}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell collapsing verticalAlign="top">
                <strong>Phone</strong>
              </Table.Cell>
              <Table.Cell>{values?.customer?.phone || 'N/A'}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell collapsing verticalAlign="top">
                <strong>Ship Method</strong>
              </Table.Cell>
              <Table.Cell>{shipMethod ? shipMethod : 'N/A'}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell collapsing verticalAlign="top">
                <strong>Ship To</strong>
              </Table.Cell>
              <Table.Cell>
                <List>
                  <List.Item>{values?.shipment?.shipToName}</List.Item>
                  <List.Item>{values?.shipment?.shipToCareOf}</List.Item>
                  <List.Item>{values?.shipment?.shipToAddress1}</List.Item>
                  <List.Item>{values?.shipment?.shipToAddress2}</List.Item>
                  <List.Item>
                    {values?.shipment?.shipToCity} {values?.shipment?.shipToStateProvince}{' '}
                    {values?.shipment?.shipToPostalCode}
                  </List.Item>
                </List>
              </Table.Cell>
            </Table.Row>
            {values?.po && (
              <Table.Row>
                <Table.Cell collapsing verticalAlign="top">
                  <strong>PO</strong>
                </Table.Cell>
                <Table.Cell>{values.po}</Table.Cell>
              </Table.Row>
            )}
            {values?.invoice?.costCenter && (
              <Table.Row>
                <Table.Cell collapsing verticalAlign="top">
                  <strong>Cost Center</strong>
                </Table.Cell>
                <Table.Cell>
                  <Form onSubmit={handleCostCenterSubmit}>
                    <Form.Field>
                      <Input
                        onChange={(e, data) => {
                          setCostCenter(data.value);
                          setIsChanging(true);
                        }}
                        value={costCenter}
                        loading={isLoading.costCenter}
                      />
                    </Form.Field>
                  </Form>
                  {errorMessage.costCenter && (
                    <Message error size="tiny" content={errorMessage.costCenter} />
                  )}
                  {responseMessage.costCenter && (
                    <Message success size="tiny" content={responseMessage.costCenter} />
                  )}
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                Order Item
                {values.orderItems && values.orderItems.length > 1 && 's'}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                <Table basic="very" size="small" unstackable>
                  <Table.Header>
                    <Table.Row>
                      {onToggleAll && values?.orderItems?.length > 1 ? (
                        <Table.HeaderCell>
                          <Checkbox onChange={onToggleAll} />
                        </Table.HeaderCell>
                      ) : null}
                      <Table.HeaderCell>Description</Table.HeaderCell>
                      <Table.HeaderCell>Details</Table.HeaderCell>
                      <Table.HeaderCell>Ref Name</Table.HeaderCell>
                      <Table.HeaderCell>Quantity</Table.HeaderCell>
                      <Table.HeaderCell>Artwork</Table.HeaderCell>
                      <Table.HeaderCell>Design Price</Table.HeaderCell>
                      <Table.HeaderCell>Unit Price</Table.HeaderCell>
                      <Table.HeaderCell textAlign="right">Line Total</Table.HeaderCell>
                      <Table.HeaderCell />
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {values.orderItems &&
                      (values.orderItems as any).map((item: any, index: string) => (
                        <Table.Row key={index} verticalAlign="top">
                          {onToApproveItems && values?.orderItems?.length > 1 ? (
                            <Table.Cell>
                              <div
                                className={`ui ${
                                  toApproveItems?.find((a) => a._id === item._id) ? 'checked' : ''
                                } fitted checkbox`}
                                onClick={() => onToApproveItems(item)}
                              >
                                <input
                                  type="checkbox"
                                  className="hidden"
                                  checked={!!toApproveItems?.find((a) => a._id === item._id)}
                                />
                                <label></label>
                              </div>
                            </Table.Cell>
                          ) : null}
                          <Table.Cell>
                            <div>{item.humanId}</div>
                            {item.product &&
                              item.product.staticName &&
                              humanize(item.product.staticName)}
                            {item?.productKey && <div>{item.productKey}</div>}
                          </Table.Cell>
                          <Table.Cell>
                            <DetailTable attributes={item.attributes} />
                            {item.addOns && item.addOns.length > 0 && (
                              <Table basic="very" size="small" compact="very">
                                <Table.Body>
                                  <Table.Row>
                                    <Table.Cell width={5}>
                                      <strong>Add Ons</strong>
                                    </Table.Cell>
                                    <Table.Cell>
                                      {item.addOns
                                        .reduce((acc, cur) => {
                                          return `${acc}${cur.id},`;
                                        }, '')
                                        .slice(0, -1)}
                                    </Table.Cell>
                                  </Table.Row>
                                </Table.Body>
                              </Table>
                            )}
                            {item?.customFields?.length > 0 && (
                              <Table basic="very" size="small" compact="very">
                                <Table.Body>
                                  {item.customFields.map(
                                    (c: any, idx: number) =>
                                      c?.value && (
                                        <Table.Row key={idx}>
                                          <Table.Cell>{c?.label}: </Table.Cell>
                                          <Table.Cell>{c.value}</Table.Cell>
                                        </Table.Row>
                                      )
                                  )}
                                </Table.Body>
                              </Table>
                            )}
                            {item?.designTemplateFields?.length > 0 && (
                              <Table basic="very" size="small" compact="very">
                                <Table.Body>
                                  {item.designTemplateFields.map(
                                    (d: any, idx: number) =>
                                      d?.value && (
                                        <Table.Row key={idx}>
                                          <Table.Cell>
                                            <strong>{d?.label}</strong>{' '}
                                          </Table.Cell>
                                          <Table.Cell>{d.value}</Table.Cell>
                                        </Table.Row>
                                      )
                                  )}
                                </Table.Body>
                              </Table>
                            )}
                          </Table.Cell>
                          <Table.Cell>{item.customerJobName}</Table.Cell>
                          <Table.Cell>{item.quantity}</Table.Cell>
                          <Table.Cell>
                            {errorMessage.artwork && (
                              <Message error size="mini" content={errorMessage.artwork} />
                            )}
                            <Image.Group size="tiny">
                              {item?.uploadSlots?.map((slot: any, index: number) => (
                                <Popup
                                  key={index}
                                  size="tiny"
                                  content="Click to view full size"
                                  trigger={
                                    slot.isUploaded && (
                                      <a
                                        href="#"
                                        role="button"
                                        style={{ display: 'inline-block' }}
                                        onClick={() => handleArtwork(item._id, slot.sequence)}
                                      >
                                        <Image src={`${baseUrl}${slot.upload}.jpg`} />
                                      </a>
                                    )
                                  }
                                />
                              ))}
                            </Image.Group>
                          </Table.Cell>
                          <Table.Cell>
                            {numeral(item.designPrice || 0).format('$0,0.00', Math.floor)}
                          </Table.Cell>
                          <Table.Cell>
                            {numeral(item.unitPrice || 0).format('$0,0.00', Math.floor)}
                          </Table.Cell>
                          <Table.Cell textAlign="right">
                            {numeral(item.revenue).format('$0,0.00', Math.floor)}
                          </Table.Cell>
                          <Table.Cell textAlign="right">
                            <Popup
                              size="mini"
                              content="Edit"
                              trigger={
                                <Button
                                  size={'mini'}
                                  icon={'edit'}
                                  onClick={() => {
                                    setSelectedItem(item);
                                    setIsOrderItemModalOpen(true);
                                  }}
                                />
                              }
                            />
                            {isOrderItemModalOpen && (
                              <OrderitemModal
                                isOpen={isOrderItemModalOpen}
                                onClose={() => {
                                  setIsOrderItemModalOpen(false);
                                  setSelectedItem(null);
                                }}
                                onReload={() => {
                                  onClose();
                                  onReload();
                                }}
                                item={selectedItem}
                              />
                            )}
                          </Table.Cell>
                        </Table.Row>
                      ))}
                  </Table.Body>
                </Table>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>

        {values.extraFees && values.extraFees.length > 0 && (
          <Table>
            <Table.Body>
              {values.extraFees.map((ef: any, index: number) => (
                <Table.Row key={index} verticalAlign="top">
                  <Table.Cell>{ef.description}</Table.Cell>
                  <Table.Cell textAlign="right">
                    {numeral(ef.fee).format('$0,0.00', Math.floor)}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        )}

        <Table>
          <Table.Body>
            {values.discount > 0 && (
              <Table.Row>
                <Table.Cell>
                  <strong>Discount</strong>
                </Table.Cell>
                <Table.Cell textAlign="right">
                  {numeral(values.discount).format('$0,0.00', Math.floor)}
                </Table.Cell>
              </Table.Row>
            )}
            <Table.Row>
              <Table.Cell>
                <strong>Sales Tax</strong>
              </Table.Cell>
              <Table.Cell textAlign="right">
                {numeral(values.salesTax || 0).format('$0,0.00', Math.floor)}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <strong>Total</strong>
              </Table.Cell>
              <Table.Cell textAlign="right">
                {numeral(values.invoiceTotal || 0).format('$0,0.00', Math.floor)}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        {values?.approvalStatus === 'pending' ? (
          <Checkbox
            toggle
            label="Reject Order"
            onChange={(e, data) => {
              setIsRejectSelected(!isRejectSelected);
              if (!data.checked) {
                setRejectionReason('');
              }
            }}
          />
        ) : (
          values?.rejectionReason && <Message warning content={values.rejectionReason} />
        )}

        {isRejectSelected && (
          <Form>
            <br />
            <Form.Field>
              <TextArea
                placeholder="Reason for rejection"
                onChange={(e, data: any) => setRejectionReason(data.value)}
              />
            </Form.Field>
          </Form>
        )}
        {props.errorMessage && <Message error content={props.errorMessage} />}
      </Modal.Content>
      <Modal.Actions>
        <Segment basic padded={false} loading={isSubmitting}>
          {isRejectSelected ? (
            <Button
              onClick={() =>
                values?.approvalStatus === 'pending'
                  ? onChangeStatus('rejected', rejectionReason)
                  : onClose()
              }
              primary
              fluid
              content="Reject"
            />
          ) : (
            <Button
              onClick={() =>
                onChangeStatus(values?.approvalStatus === 'pending' ? 'approved' : 'pending')
              }
              primary
              fluid
              content={values?.approvalStatus === 'pending' ? 'Approve' : 'Pending'}
            />
          )}
        </Segment>
      </Modal.Actions>
    </Modal>
  );
};
