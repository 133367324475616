import * as React from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import {
  Input,
  Button as ChakraButton,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Radio,
  RadioGroup,
  Card,
  CardHeader,
  CardBody,
  Text,
  Heading,
  Stack,
  Textarea,
  Checkbox,
  FormHelperText,
  Box,
  IconButton,
  StackDivider,
  Center,
  Image as ChakraImage,
  SimpleGrid,
  Alert,
} from '@chakra-ui/react';
import {
  Button,
  Form,
  Header,
  Grid,
  Segment,
  Image,
  Message,
  CardContent,
} from 'semantic-ui-react';
import { Formik, Form as FormikForm, Field, ErrorMessage, FieldProps, FieldArray } from 'formik';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FeathersDropdown, useDebounce } from '@inkcloud/components';
import { AsyncSelect } from 'chakra-react-select';
import { FaTimes } from 'react-icons/fa';
import { slugify } from 'underscore.string';
import type { ModelTypes } from '@inkcloud/icapi-types';
import { SemanticField } from '../../../common/Form';
import { slugify2 as slugifyKey } from '../../../common';
import { EntityDropdownWrapper2 } from '../../EntityDropdown';
import { ImageUpload } from '../products/stock-product/StockForm/ImageUpload';
import UploadMisc from '../../../common/UploadMisc';
import { Feathers } from '../../../bootstrap/feathers/feathers';

export const initialMapper = (values) => ({
  ...values,
  name: values.name || '',
  shortDescription: values.shortDescription || '',
  shortDescription2: values.shortDescription2 || '',
  description: values.description || '',
  hrCode: values.hrCode || '',
  microsite: { label: values.microsite?.name, value: values.microsite?._id } || {},
  entityAttributes:
    values?.entityAttributes?.map((ea) => ({ label: ea?.name, value: ea?._id })) || [],
  isSizesFixed: values.pricingMethod === '1' || values.pricingMethod === '2' || false,
  isQuantitiesFixed: values.pricingMethod === '1' || false,
  type: values.type || 'basic',
  globalPermissions: values.globalPermissions || false,
  images: values.images || [],
  parentCategory: { label: values.parentCategory?.name, value: values.parentCategory?._id } || {},
  isHidden: values.isHidden || false,
  isSubCategory: values.parentCategory || false,
  isMicrositeSpecific: values.microsite || false,
});

interface CategoryFormProps {
  isEdit: boolean;
  initialValues: any;
  onSubmit: Function;
  errorMessage?: string;
  isSubmitting?: boolean;
}

export const CategoryForm: React.FC<CategoryFormProps> = (outerProps) => {
  const CustomerContactSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    hrCode: Yup.string().required('Required'),
  });

  return (
    <Formik
      initialValues={initialMapper(outerProps.initialValues)}
      validationSchema={CustomerContactSchema}
      onSubmit={(values, { setSubmitting }) => {
        // if (!values.parentCategory) {
        //   delete values.parentCategory;
        // }
        outerProps.onSubmit(values);
      }}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
          setFieldValue,
          setFieldTouched,
        } = props;

        const handleSlugify = (v) => setFieldValue('hrCode', slugifyKey(v));

        return (
          <FormikForm
            className={`ui form ${outerProps.isSubmitting && 'loading'} ${
              !!outerProps.errorMessage && 'error'
            }`}
          >
            <div
              style={{ marginTop: '1em' }}
              className={`${outerProps.isSubmitting ? 'ui segment' : ''}`}
            >
              <Header as="h4" content="General" attached="top" />
              <Segment attached="bottom">
                <Segment secondary>
                  <Form.Field>
                    <label>Defintion Type</label>
                    <Form.Group inline>
                      <Form.Radio
                        label="General"
                        name="isMicrositeSpecific"
                        // value={'true'}
                        checked={!values.isMicrositeSpecific}
                        onBlur={handleBlur}
                        onChange={() => {
                          setFieldValue('isMicrositeSpecific', false);
                          setFieldValue('microsite', undefined);
                        }}
                        disabled={outerProps.isEdit}
                      />
                      <Form.Radio
                        label="Microsite Specific"
                        name="isMicrositeSpecific"
                        // value={'false'}
                        checked={values.isMicrositeSpecific}
                        onBlur={handleBlur}
                        onChange={() => setFieldValue('isMicrositeSpecific', true)}
                        disabled={outerProps.isEdit}
                      />
                    </Form.Group>
                    {values.isMicrositeSpecific && (
                      <Form.Field>
                        <Field
                          name="microsite"
                          render={({ field }: FieldProps<any>) => (
                            <>
                              {/* <label>Microsite</label> */}
                              <FeathersDropdown
                                search
                                selection
                                fluid
                                clearable
                                placeholder="Select Microsite..."
                                serviceName="/microsites"
                                disabled={outerProps.isEdit}
                                query={{
                                  $np: 1,
                                  $sort: { name: 1 },
                                  $select: ['_id', 'name'],
                                }}
                                resultMapper={(r) => ({ value: r._id, text: r.name })}
                                {...field}
                                onChange={(e, data) =>
                                  field.onChange({
                                    target: { value: data.value, name: 'microsite' },
                                  } as any)
                                }
                              />
                            </>
                          )}
                        />
                      </Form.Field>
                    )}
                  </Form.Field>
                </Segment>
                <Form.Group widths="equal">
                  <Form.Field>
                    <SemanticField
                      name={'name'}
                      label="Name"
                      component={Form.Input}
                      onChange={(v) => {
                        setFieldValue('hrCode', slugify(v));
                      }}
                    />
                    <ErrorMessage
                      render={(msg) => <Message negative size="tiny" content={msg} />}
                      name="name"
                    />
                  </Form.Field>
                  <Form.Field>
                    <SemanticField
                      name={'hrCode'}
                      label="Key"
                      component={Form.Input}
                      onChange={handleSlugify}
                    />
                    <ErrorMessage
                      render={(msg) => <Message negative size="tiny" content={msg} />}
                      name="hrCode"
                    />
                  </Form.Field>
                  <Form.Field>
                    <SemanticField
                      name={'internalName'}
                      label="Internal Name"
                      component={Form.Input}
                    />
                    <ErrorMessage
                      render={(msg) => <Message negative size="tiny" content={msg} />}
                      name="internalName"
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <SemanticField
                      name={'shortDescription'}
                      label="Short Description"
                      component={Form.TextArea}
                    />
                    <ErrorMessage
                      render={(msg) => <Message negative size="tiny" content={msg} />}
                      name="shortDescription"
                    />
                  </Form.Field>
                  <Form.Field>
                    <SemanticField
                      name={'shortDescription2'}
                      label="Short Description 2"
                      component={Form.TextArea}
                    />
                    <ErrorMessage
                      render={(msg) => <Message negative size="tiny" content={msg} />}
                      name="shortDescription2"
                    />
                  </Form.Field>
                  <Form.Field>
                    <SemanticField
                      name={'description'}
                      label="Description"
                      component={Form.TextArea}
                    />
                    <ErrorMessage
                      render={(msg) => <Message negative size="tiny" content={msg} />}
                      name="description"
                    />
                  </Form.Field>
                </Form.Group>

                <Form.Group widths="equal">
                  <Form.Field>
                    <SemanticField
                      name={'description2'}
                      label="Description 2"
                      component={Form.TextArea}
                    />
                    <ErrorMessage
                      render={(msg) => <Message negative size="tiny" content={msg} />}
                      name="description2"
                    />
                  </Form.Field>
                  <Form.Field>
                    <SemanticField
                      name={'description3'}
                      label="Description 3"
                      component={Form.TextArea}
                    />
                    <ErrorMessage
                      render={(msg) => <Message negative size="tiny" content={msg} />}
                      name="description3"
                    />
                  </Form.Field>
                </Form.Group>

                <Form.Field>
                  <label>Images</label>
                  {/* {values?.images?.length > 0 && ( */}
                  <Segment>
                    <Image.Group size="small">
                      <FieldArray
                        name="images"
                        render={(arrayHelpers) => (
                          <div>
                            {values.images.map((img, index) => (
                              <Image
                                key={index}
                                bordered
                                src={`https://storage.googleapis.com/icstorage/${img.url}`}
                                label={{
                                  as: 'a',
                                  color: 'grey',
                                  corner: 'right',
                                  icon: 'delete',
                                  onClick: () => arrayHelpers.remove(index),
                                }}
                              />
                            ))}

                            <ImageUpload
                              onUpload={(files) => {
                                files?.map((f) =>
                                  arrayHelpers.push({ url: f.publicUrl, contentImage: f._id })
                                );
                              }}
                            />
                          </div>
                        )}
                      />
                      {/* {values.images.map((i: any) => (
                          <Image
                            bordered
                            src={`https://storage.googleapis.com/icstorage/${i.url}`}
                            label={{
                              as: 'a',
                              color: 'grey',
                              corner: 'right',
                              icon: 'delete',
                              onClick: () => {
                                setFieldValue(
                                  'images',
                                  values.images.filter(
                                    (image) => i.contentImage !== image.contentImage
                                  )
                                );
                              },
                            }}
                          />
                        ))} */}
                    </Image.Group>
                  </Segment>
                  {/* )} */}
                  {/* <ImageUpload
                    onUpload={(files) => {
                      setFieldValue(
                        'images',
                        (values.images = values.images.concat(
                          files.map((f) => ({ url: f.publicUrl, contentImage: f._id }))
                        ))
                      );
                    }}
                  /> */}
                </Form.Field>

                <Form.Field>
                  <label>Media</label>
                  <Segment>
                    <Image.Group size="small">
                      <FieldArray
                        name="media"
                        render={(arrayHelpers) => (
                          <div>
                            {values?.media?.map((media, index) =>
                              media.type === 'image' ? (
                                <Image
                                  key={index}
                                  bordered
                                  src={`https://storage.googleapis.com/icstorage/${media.url}`}
                                  label={{
                                    as: 'a',
                                    color: 'grey',
                                    corner: 'right',
                                    icon: 'delete',
                                    onClick: () => arrayHelpers.remove(index),
                                  }}
                                />
                              ) : (
                                <>Video</>
                              )
                            )}

                            <ImageUpload
                              onUpload={(files) => {
                                files?.map((f) =>
                                  arrayHelpers.push({ url: f.publicUrl, contentImage: f._id })
                                );
                              }}
                            />
                          </div>
                        )}
                      />
                      {/* {values.images.map((i: any) => (
                          <Image
                            bordered
                            src={`https://storage.googleapis.com/icstorage/${i.url}`}
                            label={{
                              as: 'a',
                              color: 'grey',
                              corner: 'right',
                              icon: 'delete',
                              onClick: () => {
                                setFieldValue(
                                  'images',
                                  values.images.filter(
                                    (image) => i.contentImage !== image.contentImage
                                  )
                                );
                              },
                            }}
                          />
                        ))} */}
                    </Image.Group>
                  </Segment>
                </Form.Field>

                <Form.Field>
                  <SemanticField
                    name={'isSubCategory'}
                    label="This is a subcategory"
                    component={Form.Checkbox}
                  />
                  <ErrorMessage component="div" name="isSubCategory" />
                </Form.Field>

                {values.isSubCategory && (
                  <Form.Field>
                    <Field
                      name="parentCategory"
                      render={({ field }: FieldProps<any>) => (
                        <>
                          <label>Parent Category</label>
                          <FeathersDropdown
                            search
                            selection
                            fluid
                            clearable
                            placeholder="Parent Category"
                            serviceName="/products-legacy/categories"
                            query={{
                              $np: 1,
                              $sort: { name: 1 },
                            }}
                            resultMapper={(r) => ({ value: r._id, text: r.name })}
                            {...field}
                            onChange={(e, data) =>
                              field.onChange({
                                target: { value: data.value, name: 'parentCategory' },
                              } as any)
                            }
                          />
                        </>
                      )}
                    />
                  </Form.Field>
                )}

                <Form.Field>
                  <SemanticField name={'isHidden'} label="Hidden" component={Form.Checkbox} />
                  <ErrorMessage component="div" name="isHidden" />
                  <div>
                    This does not hide on the store front. It hides the category so that products
                    cannot be put into it
                  </div>
                </Form.Field>
              </Segment>

              <Header as="h4" attached="top">
                Metadata
              </Header>
              <Segment attached="bottom">
                {!values?.metadata?.length && (
                  <Message content={'Metadata not defined. To add metadata click Add'} />
                )}
                <Form.Field>
                  <FieldArray
                    name="metadata"
                    render={(arrayHelpers) => (
                      <div>
                        {values?.metadata?.map((custom, index) => (
                          <Form.Group widths="equal" key={index}>
                            <Form.Field>
                              <label>Key</label>
                              <Field name={`metadata[${index}].key`} />
                              {(errors?.metadata as any)?.[index]?.key && (
                                <Message
                                  negative
                                  size="tiny"
                                  content={(errors?.metadata as any)?.[index]?.key}
                                />
                              )}
                            </Form.Field>
                            <Form.Field>
                              <label>Value</label>
                              <Field name={`metadata[${index}].value`} />
                            </Form.Field>
                            <div>
                              <div className="field">
                                <label>&nbsp;</label>
                                <Button
                                  type="button"
                                  icon="remove"
                                  onClick={() => arrayHelpers.remove(index)}
                                />
                              </div>
                            </div>
                          </Form.Group>
                        ))}
                        <Button
                          type="button"
                          size="tiny"
                          content="Add"
                          onClick={() => arrayHelpers.push({ key: '' })}
                        />
                      </div>
                    )}
                  />
                </Form.Field>
              </Segment>

              <Header as="h4" content="On Demand Product Settings" attached="top" />
              <Segment attached="bottom">
                <Segment secondary>
                  The following settings only apply to on-demand products within this category
                </Segment>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label>Size Type</label>
                    <Form.Group inline>
                      <Form.Radio
                        label="Fixed Size"
                        name="isSizesFixed"
                        // value={'true'}
                        checked={values.isSizesFixed}
                        onBlur={handleBlur}
                        onChange={() => setFieldValue('isSizesFixed', true)}
                      />
                      <Form.Radio
                        label="Variable Size"
                        name="isSizesFixed"
                        // value={'false'}
                        checked={!values.isSizesFixed}
                        onBlur={handleBlur}
                        onChange={() => setFieldValue('isSizesFixed', false)}
                      />
                      <ErrorMessage component="div" name="isSizesFixed" />
                    </Form.Group>
                  </Form.Field>

                  <Form.Field>
                    <label>Quantity Type</label>
                    <Form.Group inline>
                      <Form.Radio
                        label="Fixed Quantities"
                        name="isQuantitiesFixed"
                        // value={'true'}
                        checked={values.isQuantitiesFixed}
                        onBlur={handleBlur}
                        onChange={() => setFieldValue('isQuantitiesFixed', true)}
                      />
                      <Form.Radio
                        label="Variable Quantities"
                        name="isQuantitiesFixed"
                        // value={'false'}
                        checked={!values.isQuantitiesFixed}
                        onBlur={handleBlur}
                        onChange={() => setFieldValue('isQuantitiesFixed', false)}
                      />
                      <ErrorMessage component="div" name="isQuantitiesFixed" />
                    </Form.Group>
                  </Form.Field>
                </Form.Group>

                <Form.Field>
                  <label>Attribute Types</label>

                  <Field
                    name="entityAttributes"
                    render={({ field }: FieldProps<any>) => (
                      <FeathersDropdown
                        search
                        selection
                        multiple
                        placeholder="Attribute Types..."
                        serviceName="/products-legacy/attribute-types"
                        query={{
                          $np: 1,
                          $sort: { name: 1 },
                          _id: { $ne: 'QUANTITY' },
                        }}
                        resultMapper={(r) => ({ value: r._id, text: r.name })}
                        {...field}
                        onChange={(e, data) =>
                          field.onChange({
                            target: { value: data.value, name: 'entityAttributes' },
                          } as any)
                        }
                      />
                    )}
                  />
                </Form.Field>

                <Form.Field>
                  <SemanticField
                    name={'isOrientationEnabled'}
                    label="Enable Orientation"
                    component={Form.Checkbox}
                  />
                  <ErrorMessage component="div" name="isOrientationEnabled" />
                </Form.Field>
              </Segment>
              <Form.Field>
                <SemanticField name={'priority'} label="Priority" component={Form.Input} />
                <ErrorMessage
                  render={(msg) => <Message negative size="tiny" content={msg} />}
                  name="priority"
                />
              </Form.Field>

              {outerProps.errorMessage && <Message error content={outerProps.errorMessage} />}

              <Button primary type="submit" disabled={outerProps.isSubmitting}>
                Save
              </Button>
            </div>
          </FormikForm>
        );
      }}
    </Formik>
  );
};

export async function micrositeOptions(e, callback): Promise<any> {
  try {
    const res = await Feathers.service('microsites').find({
      query: {
        $np: 1,
        $sort: { name: 1 },
        name: { $LIKE: e },
        $select: ['name'],
      },
    });
    callback(res?.map((r) => ({ label: r?.name, value: r?._id })));
  } catch (er) {
    console.error(er);
  }
}
export async function categoryOptions(e, callback): Promise<any> {
  try {
    const res = await Feathers.service('products-legacy/categories').find({
      query: {
        $np: 1,
        $sort: { name: 1 },
        name: { $LIKE: e },
        $ne: { _id: 'QUANTITY' },
        $select: ['name', 'hrCode'],
      },
    });
    callback(res?.map((r) => ({ label: `${r?.name} (${r?.hrCode})`, value: r?._id })));
  } catch (er) {
    console.error(er);
  }
}
export async function entityAttributesOptions(e, callback): Promise<any> {
  try {
    const res = await Feathers.service('products-legacy/attribute-types').find({
      query: {
        $np: 1,
        $sort: { name: 1 },
        name: { $LIKE: e },
        $ne: { _id: 'QUANTITY' },
      },
    });
    callback(res?.map((r) => ({ label: r?.name, value: r?._id })));
  } catch (er) {
    console.error(er);
  }
}

interface ICategoryFormProps {
  initialValues: ModelTypes.ProductCategoryLegacy;
  onSubmit: (v: any) => void;
  isSubmitting?: boolean;
  errorMessage?: string;
  isEdit?: boolean;
}

const CustomerContactSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  hrCode: Yup.string().required('Required'),
});

export default function CategoryForm2(props: ICategoryFormProps) {
  const { initialValues, onSubmit, isSubmitting, errorMessage } = props;
  const {
    control,
    register,
    watch,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm({
    mode: 'onBlur',
    defaultValues: initialMapper(initialValues),
    resolver: yupResolver(CustomerContactSchema),
  });
  const {
    fields: images,
    append: appendImage,
    remove: removeImage,
    update: updateImage,
  } = useFieldArray({
    control,
    name: 'images',
  });
  const {
    fields: media,
    append: appendMedia,
    remove: removeMedia,
    update: updateMedia,
  } = useFieldArray({
    control,
    name: 'media',
  });

  const {
    fields: metadata,
    append: appendMetadata,
    remove: removeMetadata,
  } = useFieldArray({
    control,
    name: 'metadata',
  });
  // console.log({ errors });

  const watchIsMicrositeSpecific = watch('isMicrositeSpecific');

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card mb={3}>
        <CardHeader>
          <Heading as="h4" size="md">
            General
          </Heading>
        </CardHeader>
        <CardBody>
          <RadioGroup
            mb={3}
            onChange={(e) => {
              if (e === 'general') {
                setValue('isMicrositeSpecific', false);
                setValue('microsite', undefined);
              } else {
                setValue('isMicrositeSpecific', true);
              }
            }}
            value={watchIsMicrositeSpecific ? 'specific' : 'general'}
          >
            <Stack direction="row">
              <Radio value="general" isDisabled={props.isEdit}>
                General
              </Radio>
              <Radio value="specific" isDisabled={props.isEdit}>
                Microsite Specific
              </Radio>
            </Stack>
          </RadioGroup>
          {watchIsMicrositeSpecific && (
            <FormControl mb={3}>
              <AsyncSelect
                useBasicStyles
                isClearable
                value={watch('microsite')}
                onChange={(e) => setValue('microsite', e)}
                loadOptions={micrositeOptions}
                isDisabled={props.isEdit}
              />
            </FormControl>
          )}
          <Stack direction="row" mb={3}>
            <FormControl isInvalid={!!errors?.name}>
              <FormLabel>Name</FormLabel>
              <Input {...register('name')} />
              {errors?.name && <FormErrorMessage>Required</FormErrorMessage>}
            </FormControl>
            <FormControl isInvalid={!!errors?.hrCode}>
              <FormLabel>Key</FormLabel>
              <Input {...register('hrCode')} />
              {errors?.hrCode && <FormErrorMessage>Required</FormErrorMessage>}
            </FormControl>
            <FormControl>
              <FormLabel>Internal Name</FormLabel>
              <Input {...register('internalName')} />
            </FormControl>
          </Stack>
          <Stack direction="row" mb={3}>
            <FormControl>
              <FormLabel>Short Description</FormLabel>
              <Textarea {...register('shortDescription')} />
            </FormControl>
            <FormControl>
              <FormLabel>Short Description</FormLabel>
              <Textarea {...register('shortDescription2')} />
            </FormControl>
            <FormControl>
              <FormLabel>Description</FormLabel>
              <Textarea {...register('description')} />
            </FormControl>
          </Stack>
          <Stack direction="row" mb={3}>
            <FormControl>
              <FormLabel>Description 2</FormLabel>
              <Textarea {...register('description2')} />
            </FormControl>
            <FormControl>
              <FormLabel>Description 3</FormLabel>
              <Textarea {...register('description3')} />
            </FormControl>
          </Stack>

          <FormControl mb={3}>
            <Checkbox
              {...register('isSubCategory')}
              onChange={(e) => {
                setValue('isSubCategory', e.target.checked);
                if (!e.target.checked) {
                  setValue('parentCategory', undefined);
                }
              }}
            >
              This is a subcategory
            </Checkbox>
          </FormControl>
          {watch('isSubCategory') && (
            <FormControl mb={3}>
              <AsyncSelect
                useBasicStyles
                isClearable
                value={watch('parentCategory')}
                onChange={(e) => setValue('parentCategory', e)}
                loadOptions={categoryOptions}
              />
            </FormControl>
          )}
          <FormControl mb={3}>
            <Checkbox {...register('isHidden')}>Hidden</Checkbox>
            <FormHelperText>
              This does not hide on the store front. It hides the category so that products cannot
              be put into it
            </FormHelperText>
          </FormControl>
        </CardBody>
      </Card>
      <Card mb={3}>
        <CardHeader>
          <Heading as="h4" size="md">
            Image
          </Heading>
        </CardHeader>
        <CardBody>
          <SimpleGrid columns={[1, 3, 4]} spacing={3}>
            {images?.map((field, index) => {
              let imgUrl;

              if (watch(`images.${index}.url`)) {
                imgUrl = `https://storage.googleapis.com/icstorage/${watch(`images.${index}.url`)}`;
              }

              return (
                <Box key={field.id} mb={3}>
                  <Stack direction="row" justifyContent="end" mb={3}>
                    <IconButton
                      aria-label="Delete"
                      icon={<FaTimes />}
                      onClick={() => removeImage(index)}
                    />
                  </Stack>

                  {imgUrl && (
                    <Center mb={3}>
                      <ChakraImage boxSize={150} objectFit="cover" src={imgUrl} />
                    </Center>
                  )}
                </Box>
              );
            })}
          </SimpleGrid>
          <UploadMisc
            onUpload={(file) => {
              // console.log({ file });
              appendImage({
                url: file?.cloudFilename,
                contentImage: file?.uploadId,
              });
            }}
            showFiles={false}
            btnLabel="Upload Images"
          />
        </CardBody>
      </Card>
      <Card mb={3}>
        <CardHeader>
          <Heading as="h4" size="md">
            Media
          </Heading>
        </CardHeader>
        <CardBody>
          <SimpleGrid columns={[1, 3, 4]} spacing={3}>
            {media?.map((field, index) => {
              const watchMediaType = watch(`media.${index}.type`);

              let imgUrl;

              if (watchMediaType === 'image' && watch(`media.${index}.url`)) {
                imgUrl = `https://storage.googleapis.com/icstorage/${watch(`media.${index}.url`)}`;
              }

              return (
                <Box key={field.id} mb={3}>
                  <Stack direction="row" justifyContent="space-between" mb={3}>
                    <RadioGroup
                      onChange={(e) => setValue(`media.${index}.type`, e)}
                      value={watchMediaType || 'image'}
                    >
                      <Stack direction="row">
                        <Radio value="image">Image</Radio>
                        <Radio value="video">Video</Radio>
                      </Stack>
                    </RadioGroup>

                    <IconButton
                      aria-label="Delete"
                      icon={<FaTimes />}
                      onClick={() => removeMedia(index)}
                    />
                  </Stack>

                  {imgUrl && (
                    <Center mb={3}>
                      <ChakraImage boxSize={150} objectFit="cover" src={imgUrl} />
                    </Center>
                  )}

                  {watchMediaType === 'image' ? (
                    <UploadMisc
                      onUpload={(file) => {
                        // console.log({ file });
                        updateMedia(index, {
                          type: 'image',
                          url: file?.cloudFilename,
                          contentImage: file?.uploadId,
                        });
                      }}
                      isMultiple={false}
                      isDraggable={false}
                      btnLabel="Upload Image"
                    />
                  ) : (
                    // <ImageUpload
                    //   onUpload={(files) => {
                    //     files?.map((f) =>
                    //       updateMedia(index, {
                    //         type: 'image',
                    //         url: f.publicUrl,
                    //         contentImage: f._id,
                    //       })
                    //     );
                    //   }}
                    // />
                    <FormControl>
                      <FormLabel>Video URL</FormLabel>
                      <Input {...register(`media.${index}.url`)} />
                    </FormControl>
                  )}
                </Box>
              );
            })}
          </SimpleGrid>
          <ChakraButton type="button" onClick={() => appendMedia({ type: 'image' })}>
            Add
          </ChakraButton>
        </CardBody>
      </Card>
      <Card mb={3}>
        <CardHeader>
          <Heading as="h4" size="md">
            Metadata
          </Heading>
        </CardHeader>
        <CardBody>
          <Stack direction="row" mb={3}>
            <Box flex={1}>
              <Heading as="h4" size="sm">
                Key
              </Heading>
            </Box>
            <Box flex={1}>
              <Heading as="h4" size="sm">
                Value
              </Heading>
            </Box>
            <Box w="28px"></Box>
          </Stack>
          {metadata?.map((field, index) => (
            <Stack key={field.id} direction="row" mb={3}>
              <FormControl>
                <Input {...register(`metadata.${index}.key`)} />
              </FormControl>
              <FormControl>
                <Input {...register(`metadata.${index}.value`)} />
              </FormControl>
              <Box>
                <IconButton
                  aria-label="Delete"
                  icon={<FaTimes />}
                  onClick={() => removeMetadata(index)}
                />
              </Box>
            </Stack>
          ))}
          <ChakraButton type="button" onClick={() => appendMetadata({ key: '', value: '' })}>
            Add
          </ChakraButton>
        </CardBody>
      </Card>
      <Card mb={3}>
        <CardHeader>
          <Heading as="h4" size="md">
            On Demand Product Settings
          </Heading>
        </CardHeader>
        <CardBody>
          <Alert status="info" mb={3}>
            The following settings only apply to on-demand products within this category
          </Alert>

          <SimpleGrid columns={[1, 2]} spacing={3} mb={3}>
            <Stack direction="row" justifyContent="space-between" mb={3}>
              <RadioGroup
                onChange={(e) => setValue('isSizesFixed', e === '1')}
                value={watch('isSizesFixed') ? '1' : '0'}
              >
                <Stack direction="row">
                  <Radio value="1">Fixed Size</Radio>
                  <Radio value="0">Variable Size</Radio>
                </Stack>
              </RadioGroup>
            </Stack>

            <Stack direction="row" justifyContent="space-between" mb={3}>
              <RadioGroup
                onChange={(e) => setValue('isQuantitiesFixed', e === '1')}
                value={watch('isQuantitiesFixed') ? '1' : '0'}
              >
                <Stack direction="row">
                  <Radio value="1">Fixed Quantities</Radio>
                  <Radio value="0">Variable Quantities</Radio>
                </Stack>
              </RadioGroup>
            </Stack>
          </SimpleGrid>

          <FormControl mb={3}>
            <AsyncSelect
              useBasicStyles
              isClearable
              isMulti
              name="entityAttributes"
              value={watch('entityAttributes')}
              onChange={(e) => setValue('entityAttributes', e)}
              loadOptions={entityAttributesOptions}
              defaultOptions
            />
          </FormControl>

          <FormControl mb={3}>
            <Checkbox {...register('isOrientationEnabled')}>Enable Orientation</Checkbox>
          </FormControl>
        </CardBody>
      </Card>
      <FormControl mb={3}>
        <FormLabel>Priority</FormLabel>
        <Input {...register('priority')} />
      </FormControl>

      {errorMessage && (
        <Alert status="error" mb={3}>
          {errorMessage}
        </Alert>
      )}
      <ChakraButton
        colorScheme="blue"
        type="submit"
        disabled={isSubmitting}
        isLoading={isSubmitting}
      >
        Save
      </ChakraButton>
    </form>
  );
}
