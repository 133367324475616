import * as React from 'react';
import { Switch, Route } from 'react-router-dom';

import { OrderBuilder } from './OrderBuilder';

const routes = (props) => (
  <Switch>
    <Route exact path="/order-builder" component={OrderBuilder} />
  </Switch>
);

export default routes;
