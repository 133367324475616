import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Form, Checkbox, Message, Segment, Header, Grid, Input } from 'semantic-ui-react';
import { PageHeaderRow } from '@inkcloud/components';

import Feathers from '../../../bootstrap/feathers';

import { connect } from 'react-redux';

interface CartFormProps {}

export interface ConnectedProps {
  user?: any;
}

const { useState, useEffect } = React;

export const PreCartForm: React.FunctionComponent<
  CartFormProps & ConnectedProps & RouteComponentProps
> = (props) => {
  const { user, history } = props;
  const [cart, setCart] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const loadCart = async () => {
      setIsLoading(true);
      try {
        const res: any = await Feathers.service('tenant-settings').get(user.business, {
          query: {
            $select: ['cart'],
          },
        });

        setCart(res?.cart);
      } catch (e) {}
      setIsLoading(false);
    };
    loadCart();
  }, []);

  const handleChange = (data: any) =>
    setCart({ ...cart, [data.name]: data?.checked ?? data.value });

  const handleSubmit = async (value: any) => {
    setIsLoading(true);
    try {
      const res: any = await Feathers.service('tenant-settings/cart').patch(user.business, value, {
        query: {},
      });

      history.push('/settings');
    } catch (e) {
      e.code && e.code < 500
        ? setErrorMessage(e.message)
        : setErrorMessage('We are experiencing technical difficulties. Please try again');
    }
    setIsLoading(false);
  };

  return (
    <>
      <PageHeaderRow header={`Cart`} subheader="Settings">
        <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
      </PageHeaderRow>
      <Header as="h4" content="Settings" subheader="" attached="top" />
      <Form className={`${isLoading ? 'loading' : ''} ${errorMessage ? 'error' : ''}`}>
        <Segment secondary attached="bottom">
          <Grid divided="vertically">
            <Grid.Row>
              <Grid.Column width="3">Allow Saved Items</Grid.Column>
              <Grid.Column width="13">
                <Checkbox
                  name={'allowSavedItems'}
                  label={'Allow Saved Items'}
                  toggle
                  checked={cart?.allowSavedItems}
                  onChange={(e, data) => handleChange(data)}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width="3">Consolidate Items</Grid.Column>
              <Grid.Column width="13">
                <Checkbox
                  name={'consolidateCartItems'}
                  label={'Enable Consolidate Items'}
                  toggle
                  checked={cart?.consolidateCartItems}
                  onChange={(e, data) => handleChange(data)}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width="3">Order Miscellaneous Info Enabled</Grid.Column>
              <Grid.Column width="13">
                <Form.Field>
                  <Checkbox
                    name={'isOrderMiscInfoEnabled'}
                    label={'Enable Order Miscellaneous Info'}
                    toggle
                    checked={cart?.isOrderMiscInfoEnabled}
                    onChange={(e, data) => {
                      if (!data.checked) {
                        setCart({ ...cart, orderMiscInfoLabel: '', isOrderMiscInfoEnabled: false });
                      } else {
                        setCart({ ...cart, isOrderMiscInfoEnabled: true });
                      }
                    }}
                  />
                </Form.Field>
                {cart?.isOrderMiscInfoEnabled && (
                  <Form.Field>
                    <label>Order Misc Info Label</label>
                    <Input
                      name="orderMiscInfoLabel"
                      value={cart?.orderMiscInfoLabel ?? ''}
                      onChange={(e, data) => handleChange(data)}
                    />
                  </Form.Field>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        {errorMessage && <Message error content={errorMessage} />}

        <Button primary type="button" onClick={() => handleSubmit(cart)}>
          Save
        </Button>
      </Form>
    </>
  );
};

const mapStateToProps = (state): ConnectedProps => ({
  user: state.auth.user || {},
});

export const CartForm = connect(mapStateToProps)(PreCartForm as any);
