import * as React from 'react';
import { Grid, Table } from 'semantic-ui-react';

interface ShippingInfoProps {
  values: any;
}

export const ShippingInfo: React.FunctionComponent<ShippingInfoProps> = (props: any) => {
  const { values } = props;

  const { selectedShippingMethod, selectedShippingService, availableShippingRates } = values;

  if (
    selectedShippingMethod !== 'SHIPPING_SHIP_OUT' &&
    selectedShippingMethod !== 'SHIPPING_SHIPOUT_AND_DIGITAL_DELIVERY'
  ) {
    if (selectedShippingMethod === 'SHIPPING_DIGITAL_DELIVERY') {
      return (
        <div>
          <strong>Service</strong>: Digital Delivery
        </div>
      );
    } else {
      return (
        <div>
          <strong>Service</strong>: Will Call
        </div>
      );
    }
  } else {
    const rates = availableShippingRates.find((a) => a.serviceKey === selectedShippingService);

    return (
      <>
        <Table basic="very" size="small">
          <Table.Body>
            <Table.Row verticalAlign="top">
              <Table.Cell collapsing style={{ border: 0, paddingTop: 0 }}>
                <strong>Ship To</strong>
              </Table.Cell>
              <Table.Cell style={{ border: 0, paddingTop: 0 }}>
                <div>{values.shipTo.name}</div>
                {values.shipTo.careOf && <div>{values.shipTo.careOf}</div>}
                <div>{values.shipTo.address1}</div>
                <div>{values.shipTo.address2}</div>
                <div>
                  {values.shipTo.city} {values.shipTo.stateProvince} {values.shipTo.postalCode}
                </div>
                <div>{values.shipTo.countryCode}</div>
              </Table.Cell>
            </Table.Row>
            {rates?.carrier && (
              <Table.Row verticalAlign="top">
                <Table.Cell collapsing style={{ border: 0, paddingTop: 0 }}>
                  <strong>Carrier</strong>
                </Table.Cell>
                <Table.Cell style={{ border: 0, paddingTop: 0 }}>{rates?.carrier ?? ''}</Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </>
    );
  }
};
