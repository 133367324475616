import * as React from 'react';
import { Table, Input, Popup, Button } from 'semantic-ui-react';
import * as numeral from 'numeral';
import Feathers from './../../../bootstrap/feathers';

import { CartDetailTable } from '../../../common/CartDetailTable';

import { Reference } from './Reference';
import { PriceModal } from './PriceModal';

interface ICartContentsProps {
  items: any[];
  cartId: string;
  onSelectedItemsChange: (ids: string[]) => void;
  onRefresh: () => void;
  selectedItems: any;
  onSelectAll: (e: any) => void;
  isSelectAll: boolean;
}

const { useState, useEffect } = React;

export const CartContents: React.FunctionComponent<ICartContentsProps> = (props) => {
  const {
    items,
    cartId,
    selectedItems,
    onSelectedItemsChange,
    isSelectAll,
    onSelectAll,
    onRefresh,
  } = props;

  const [isPriceModalOpen, setIsPriceModalOpen] = useState(false);
  const [item, setItem] = useState('');
  const [addOns, setAddOns] = useState([]);

  if (!items || items.length === 0) {
    return null;
  }

  useEffect(() => {
    const loadAddOns = async () => {
      try {
        const res = await Feathers.service('/products-legacy/add-ons-base').find({
          query: {
            $np: 1,
            $sort: { name: 1 },
          },
        });
        setAddOns(res);
      } catch (error) {}
    };

    loadAddOns();
  }, []);

  return (
    <div>
      <Table striped size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              {items && items.length > 0 && (
                <input type="checkbox" checked={isSelectAll} onChange={onSelectAll} />
              )}
            </Table.HeaderCell>
            <Table.HeaderCell>Category</Table.HeaderCell>
            <Table.HeaderCell>Attributes</Table.HeaderCell>
            <Table.HeaderCell>Reference Name</Table.HeaderCell>
            <Table.HeaderCell>Design Price</Table.HeaderCell>
            <Table.HeaderCell>Add on Total</Table.HeaderCell>
            <Table.HeaderCell>Price</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {items &&
            items.map((i) => (
              <Table.Row key={i._id}>
                <Table.Cell>
                  <input
                    type="checkbox"
                    value={i._id}
                    checked={!!selectedItems[i._id]}
                    onChange={(e) =>
                      onSelectedItemsChange({
                        ...selectedItems,
                        [i._id]: (e.target as any).checked,
                      })
                    }
                  />
                </Table.Cell>
                <Table.Cell>{i.category ? i.category.name : 'N/A'}</Table.Cell>
                <Table.Cell>
                  <Table basic="very">
                    <CartDetailTable
                      item={i}
                      // attributes={i.attributes}
                      // isCustomTrim={i.sCustomTrim}
                      // customTrimWidth={i.customTrimWidth}
                      // customTrimHeight={i.customTrimHeight}
                      // quantity={i.quantity}
                      addOns={i.addOns}
                      // metadata={i.metadata}
                      addOnsBase={addOns}
                    />
                  </Table>
                </Table.Cell>
                <Table.Cell>
                  <Reference
                    cartId={cartId}
                    itemId={i._id}
                    customerReferenceName={i.customerReferenceName}
                  />
                </Table.Cell>
                <Table.Cell>{numeral(i.designPrice).format('$0,0.00')}</Table.Cell>
                <Table.Cell>{numeral(i.addOnTotal).format('$0,0.00')}</Table.Cell>
                <Table.Cell>{numeral(i.price).format('$0,0.00')}</Table.Cell>
                <Table.Cell textAlign="right">
                  <Popup
                    content="Overrides Price"
                    trigger={
                      <Button
                        size="tiny"
                        icon="edit"
                        onClick={() => {
                          setIsPriceModalOpen(true);
                          setItem(i);
                        }}
                      />
                    }
                  />
                </Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
      {isPriceModalOpen && (
        <PriceModal
          cartId={cartId}
          item={item}
          isOpen={isPriceModalOpen}
          onRefresh={onRefresh}
          addOnsBase={addOns}
          onClose={() => setIsPriceModalOpen(false)}
        />
      )}
    </div>
  );
};
