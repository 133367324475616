import * as React from 'react';
import { Style } from '@react-pdf/types';
import { Text } from '@react-pdf/renderer';
import { ShipTo as ShipToGeneral, ShipToValue } from '..';
import { InvoiceContext } from './InvoiceContext';

export interface IShipToProps {
  style?: Style;
  textStyle?: Style;
}

export function ShipTo(props: IShipToProps) {
  const ctx = React.useContext(InvoiceContext);
  const { style, textStyle } = props;
  const {
    dataCtx: { shipment },
  } = ctx;

  const value: ShipToValue = {
    name: shipment.shipToName,
    careOf: shipment.shipToCareOf,
    address1: shipment.shipToAddress1,
    address2: shipment.shipToAddress2,
    city: shipment.shipToCity,
    stateProvince: shipment.shipToStateProvince,
    postalCode: shipment.shipToPostalCode,
    countryCode: shipment.shipToCountryCode,
  };

  return <ShipToGeneral value={value} style={style} textStyle={textStyle} />;
}
