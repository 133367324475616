import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Segment, Message } from 'semantic-ui-react';
import { PageHeaderRow, PreLoader, useFeathers } from '@inkcloud/components';
import feathers from '../../../bootstrap/feathers';
import { SalesPersonForm } from './SalesPersonForm';

interface DetailProps {}

const pathPrefix = `/settings/sales-team`;
const serviceName = 'sales-people';
const service = feathers.service(serviceName);
const commonParams = { query: { $populate: ['manager'] } };

const { useState } = React;

export const Detail: React.FunctionComponent<DetailProps & RouteComponentProps<{ id?: string }>> = (
  props
) => {
  const {
    match: {
      params: { id },
    },
    history,
  } = props;

  const [errorMessage, setErrorMessage] = useState('');

  const isEdit = !!id;
  const [state, reload] = useFeathers('get', serviceName, [id, commonParams], true);
  const handleSubmit = async (values: any) => {
    if (values.user === '') {
      values.user = null;
    }

    if (values.manager === '') {
      values.manager = null;
    }

    try {
      isEdit
        ? await service.patch(id, values, commonParams)
        : await service.create(values, commonParams);
      history.push(pathPrefix);
    } catch (e) {
      e.code && e.code < 500
        ? setErrorMessage(e.message)
        : setErrorMessage('We are experiencing technical difficulties. Please try again');
    }
  };

  const pageTitle = isEdit ? 'Edit' : 'New';

  if (isEdit && !state.data) {
    return <PreLoader />;
  }

  const initialData = { ...state.data };

  if (initialData.user) {
    initialData.linkToUser = true;
  }

  return (
    <div>
      <PageHeaderRow header={`${pageTitle} Sales Team`} subheader="Settings" subheaderId={''}>
        <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
      </PageHeaderRow>
      <Segment>
        {(!isEdit || state.data) && (
          <SalesPersonForm
            initialValues={initialData}
            onSubmit={handleSubmit}
            errorMessage={errorMessage}
          />
        )}
        {errorMessage && <Message error content={errorMessage} />}
      </Segment>
    </div>
  );
};
