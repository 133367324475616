import * as React from 'react';
import { Style } from '@react-pdf/types';
import { Table, ITableProps } from '../shared/Table';
import { PurchaseOrderContext } from './PurchaseOrderContext';

export interface IItemsProps {
  style?: Style;
  textStyle?: Style;
}

const definition: ITableProps<any>['definition'] = [
  {
    key: 'image',
    type: 'image',
    width: '15%',
    header: 'Image',
  },
  {
    key: 'sku',
    width: '25%',
    header: 'SKU',
  },
  {
    key: 'name',
    width: '25%',
    header: 'Name',
  },
  {
    key: 'quantity',
    width: '25%',
    header: 'Quantity',
  },
];

export function Items(props: IItemsProps) {
  const ctx = React.useContext(PurchaseOrderContext);
  const {
    dataCtx: { items, tenantInfo },
  } = ctx;
  const { style } = props;

  return (
    <div>
      <Table
        // style={style}
        definition={definition}
        items={items}
        locale={tenantInfo.locale}
        currency={tenantInfo.currency}
      />
    </div>
  );
}
