import * as React from 'react';
import Select from 'react-select';

export interface TurnaroundSelectProps {
  options: any[];
  selected: any;
  onChange: Function;
}

export interface TurnaroundSelectState {}

export class TurnaroundSelect extends React.Component<
  TurnaroundSelectProps,
  TurnaroundSelectState
> {
  constructor(props: TurnaroundSelectProps) {
    super(props);

    this.state = {};
  }

  handleChange = (value) => {
    this.props.onChange(value);
  };

  public render() {
    return (
      <div>
        <Select
          options={this.props.options}
          defaultValue={this.props.options.find((o) => o.value === this.props.selected)}
          value={this.props.selected}
          onChange={this.handleChange}
        />
      </div>
    );
  }
}
