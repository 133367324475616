import * as React from 'react';
import Feathers from '../../../bootstrap/feathers';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Modal, Form, Message, Button } from 'semantic-ui-react';
import { slugify2 as slugifyKey } from './../../../common';
interface IDuplicateProductModalProps {
  product: any;
  isOpen: boolean;
  onClose: () => void;
}

const { useState } = React;

const PreDuplicateProductModal: React.FunctionComponent<
  IDuplicateProductModalProps & RouteComponentProps
> = (props) => {
  const { product, isOpen, onClose } = props;

  const [key, setSku] = useState(product?.key ?? '');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async () => {
    setIsSubmitting(true);
    setErrorMessage('');
    try {
      const payload = {
        ...product,
        key,
        _id: undefined,
        metadata: undefined,
        notes: [],
      };

      const res: any = await Feathers.service('/products-legacy').create(payload, { query: {} });

      props.history.push(
        `/products/${product.inventory ? 'stock-products/edit' : 'products'}/${res._id}`
      );
      onClose();
    } catch (e) {
      const errMsg =
        e?.code < 500 ? e.message : 'We are experiencing technical difficulties. Please try again';

      setErrorMessage(errMsg);
    }

    setIsSubmitting(false);
  };

  return (
    <Modal size="tiny" open={isOpen} onClose={onClose} closeIcon>
      <Modal.Header>
        You are going to duplicate product - {product?.staticName || product?.name || product?.sku}
      </Modal.Header>
      <Modal.Content>
        <Form error={!!errorMessage}>
          <Form.Group widths="equal">
            <Form.Input
              label="Sku"
              placeholder="sku"
              value={key}
              onChange={(e, data) => setSku(slugifyKey(data.value))}
            />
          </Form.Group>
          {errorMessage && <Message error content={errorMessage} />}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          content="Submit"
          primary
          disabled={isSubmitting}
          loading={isSubmitting}
          onClick={handleSubmit}
        />
        <Button content="Cancel" onClick={onClose} />
      </Modal.Actions>
    </Modal>
  );
};

export const DuplicateProductModal = withRouter(PreDuplicateProductModal);
