import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Box, Button } from '@chakra-ui/react';
import { PageHeaderRow, PreLoader, useFeathers } from '@inkcloud/components';
import type { ModelTypes } from '@inkcloud/icapi-types';

import feathers from '../../../../../bootstrap/feathers';

import UserForm from './UserForm';
import PasswordModal from './PasswordModal';

const serviceName = 'user';
const service = feathers.service(serviceName);
const commonParams = { query: { $populate: ['roles2'] } };

export default function Detail(props: RouteComponentProps<{ supplierId: string; id: string }>) {
  const {
    match: {
      params: { supplierId, id },
    },
    history,
  } = props;

  const pathPrefix = `/settings/production/suppliers/${supplierId}/users`;

  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isEdit = !!id;

  const [state, reload] = useFeathers('get', serviceName, [id, commonParams], true);

  const handleSubmit = async (values) => {
    setErrorMessage('');
    setIsSubmitting(true);

    const updatedValue = {
      ...values,
      vendor: supplierId,
    };

    try {
      if (isEdit) {
        await service.patch(id, updatedValue, commonParams);
      } else {
        await service.create(updatedValue, commonParams);
      }

      history.push(pathPrefix);
    } catch (e) {
      const errMsg =
        e?.code < 500 ? e?.message : 'We are experiencing technical difficulties. Please try again';
      setErrorMessage(errMsg);
    }

    setIsSubmitting(false);
  };

  if (isEdit && !state.data) {
    return <PreLoader />;
  }

  const initialData: ModelTypes.User = { ...state.data };

  return (
    <Box>
      <PageHeaderRow
        header={`${isEdit ? 'Edit' : 'New'} Supplier's User`}
        subheader="Settings"
        subheaderId={''}
      >
        {isEdit && (
          <Button variant="solid" size="sm" mr={2} onClick={() => setIsModalOpen(true)}>
            Update Password
          </Button>
        )}
        <Link to={pathPrefix}>
          <Button variant="solid" size="sm" onClick={() => history.goBack()}>
            Back
          </Button>
        </Link>
      </PageHeaderRow>

      <Box mt={3}>
        <UserForm
          initialValues={initialData}
          isSubmitting={isSubmitting}
          errorMessage={errorMessage}
          onSubmit={handleSubmit}
          isEdit={isEdit}
        />
      </Box>

      {isModalOpen && (
        <PasswordModal
          onClose={() => setIsModalOpen(false)}
          data={initialData}
          isSubmitting={isSubmitting}
          errorMessage={errorMessage}
          onSubmit={handleSubmit}
        />
      )}
    </Box>
  );
}
