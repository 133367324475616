import * as React from 'react';
import { Button, Checkbox, Header, Segment, Table, Popup, Label } from 'semantic-ui-react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { FormattedDate } from 'react-intl';
import { useList, PageHeaderRow, PreLoader, PaginatorWrapper } from '@inkcloud/components';
import { FilterForm } from './FilterForm';
import { StatusModal } from './StatusModal';
import { Feathers } from '../../../bootstrap/feathers/feathers';
import { forEachLimit, asyncify } from 'async';

interface ListProps {}

const { useState } = React;
const serviceName = 'production/outsource';
const pathPrefix = `/production/outsource`;

const filterQueryMap = (values) => {
  const filterMap: any = {
    vendor: values.vendor || undefined,
    status: values.status || undefined,
  };

  return [].reduce(
    (m, field) => ({ ...m, [field]: values[field] ? values[field] : undefined }),
    filterMap
  );
};

export const List: React.FunctionComponent<ListProps & RouteComponentProps> = (props) => {
  const {
    match: { params },
    location,
    history,
  } = props;
  const {
    state,
    reload,
    handleDelete,
    selectedItems,
    clearAll,
    setChecked,
    someSelected,
    toggleAll,
    filter,
    setFilter,
    sort,
    handleSort,
  } = useList({
    feathers: [
      'find',
      serviceName,
      [{ query: { $sort: { createdAt: -1 }, $populate: ['vendor', 'items.orderItem'] } }],
    ],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { name: 1 },
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const { queryResult, isLoading: isStateLoading } = state as any;

  const handleModalSubmit = async (value) => {
    await Feathers.service(serviceName).patch(selectedItem._id, {
      status: value.status,
    });

    if (value.updateOrderItemStatus) {
      forEachLimit(
        selectedItem.items,
        2,
        asyncify(async (item: any) => {
          await Feathers.service('/job-status').create({
            orderItem: item.orderItem,
            status: value.orderItemStatus,
          });
        }),
        (err) => {
          if (err) {
            throw err;
          }
          setIsModalOpen(false);
          setSelectedItem(null);
          reload();
        }
      );
    } else {
      setIsModalOpen(false);
      setSelectedItem(null);
      reload();
    }
  };

  if (!queryResult) {
    return null;
  }

  return (
    <div>
      <PageHeaderRow header="Outsource" subheader="Production">
        <Link to={pathPrefix}>
          <Button content="Back" size={'mini'} />
        </Link>
        <Link to={`${pathPrefix}/add`}>
          <Button content="New" primary size={'mini'} />
        </Link>
      </PageHeaderRow>

      <FilterForm value={filter} onChange={(v) => setFilter(v)} onReset={() => setFilter({})} />

      <Segment loading={state.isLoading}>
        <PaginatorWrapper.Top
          filter={filter}
          reload={reload}
          data={queryResult as any}
          pathPrefix={`${pathPrefix}/`}
        />
        <Table size={'small'} sortable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                <input type="checkbox" onChange={toggleAll} />
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={sort.createdAt ? sort.createdAt : null}
                onClick={() => handleSort('createdAt')}
              >
                Created
              </Table.HeaderCell>
              <Table.HeaderCell>Vendor</Table.HeaderCell>
              <Table.HeaderCell>Order Items</Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {queryResult &&
              (queryResult as any).data.map((r) => (
                <Table.Row key={r._id}>
                  <Table.Cell>
                    <input
                      type="checkbox"
                      value={r._id}
                      checked={selectedItems[r._id] || false}
                      onChange={setChecked}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <FormattedDate value={r.createdAt} />
                  </Table.Cell>
                  <Table.Cell>{r.vendor && r.vendor.companyName}</Table.Cell>
                  <Table.Cell>
                    <Label.Group color="blue" size="mini">
                      {r.items &&
                        r.items.map((i) => (
                          <Link key={i._id} to={`/orders/${i.orderItem.order}`}>
                            <Label basic style={{ marginRight: 2 }}>
                              {i.orderItem.humanId}
                            </Label>
                          </Link>
                        ))}
                    </Label.Group>
                  </Table.Cell>
                  <Table.Cell>
                    <Label size="small">{r.status}</Label>
                  </Table.Cell>

                  <Table.Cell textAlign="right">
                    <Popup
                      size="mini"
                      content="Update Status"
                      trigger={
                        <Button
                          size={'mini'}
                          content={'Update Status'}
                          onClick={() => {
                            setSelectedItem(r);
                            setIsModalOpen(true);
                          }}
                        />
                      }
                    />

                    <Popup
                      size="mini"
                      content="Edit"
                      trigger={
                        <Link to={`${pathPrefix}/${r._id}`}>
                          <Button size={'mini'} icon={'edit'} />
                        </Link>
                      }
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
        <PaginatorWrapper.Bottom
          reload={reload}
          data={queryResult as any}
          pathPrefix={`${pathPrefix}/`}
        >
          {someSelected && <Button primary type="button" content="Delete" onClick={handleDelete} />}
        </PaginatorWrapper.Bottom>
      </Segment>
      {isModalOpen && (
        <StatusModal
          open={isModalOpen}
          handleClose={() => setIsModalOpen(false)}
          item={selectedItem}
          handleSubmit={handleModalSubmit}
        />
      )}
    </div>
  );
};
