import * as React from 'react';
import { useHistory, useLocation } from 'react-router';

import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Sector,
} from 'recharts';

import {
  Box,
  Heading,
  Flex,
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
  Button,
  Table as ChakraTable,
  TableContainer,
  Tag,
  HStack,
  VStack,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  Divider,
  Alert,
  AlertIcon,
  Spinner,
} from '@chakra-ui/react';
import { humanize } from 'underscore.string';
import { Feathers } from '../../../bootstrap/feathers/feathers';
import DateRangePicker from '../../../prototypes/common/form/DateRangePicker';

import { StatCard } from './StatCard';
import { ActiveRfqByStatus } from './ActiveRfqByStatus';
import { RfqAssignedLoad } from './RfqAssignedLoad';

interface IRfqKPIsReportProps {}

const colors = [
  // '#3182CE', '#E53E3E', '#38A169', '#DD6B20', '#805AD5', '#00B5D8', '#D69E2E',
  // '#1A365D', '#2A4365', '#2C5282', '#2B6CB0', '#3182CE', '#4299E1', '#63B3ED', '#90CDF4', '#BEE3F8', '#EBF8FF'
  '#2B6CB0',
  '#6B46C1',
  '#2C7A7B',
  '#68D391',
  '#B7791F',
  '#C05621',
  '#C53030',
];

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const RfqKPIsReport: React.FunctionComponent<IRfqKPIsReportProps> = (props) => {
  const query = useQuery();
  const [isLoading, setIsLoading] = React.useState(false);
  const [data, setData] = React.useState<any>();
  const [headers, setHeaders] = React.useState<
    { label: string; key: string; isNumeric?: boolean }[]
  >([]);
  const [start, setStart] = React.useState(new Date());
  const [end, setEnd] = React.useState(new Date());

  const reportKey = query.get('reportKey') ?? '';

  const loadStats = async () => {
    setIsLoading(true);
    try {
      const res = await Feathers.service('/reports/v3/rfq').find({
        query: {
          reportKey: 'rfq-kpis',
          startDate: start,
          endDate: end,
        },
      });
      setData(res);
    } catch (e) {
      console.error(e);
    }
    setIsLoading(false);
  };

  React.useEffect(() => {
    loadStats();
  }, [reportKey]);

  return (
    <Box>
      <Flex mb={16} alignItems={'center'}>
        <Box>
          <Heading fontWeight={'normal'} size="xl">
            Purchasing - RFQ KPIs
          </Heading>
        </Box>
      </Flex>

      <Box backgroundColor={'white'} borderWidth={1} borderColor={'gray.200'} p={8}>
        <Flex
          mb={4}
          p={4}
          bgColor={'gray.50'}
          borderWidth={1}
          borderColor={'gray.200'}
          alignContent={'baseline'}
          alignItems={'flex-end'}
        >
          <DateRangePicker
            startDate={start}
            endDate={end}
            onChange={(v, name) => {
              if (name === 'startDate') {
                setStart(v);
              } else {
                setEnd(v);
              }
            }}
          />
          <Button ml={4} size={'md'} colorScheme="blue" onClick={() => loadStats()}>
            Submit
          </Button>
        </Flex>
        <Box>
          <Box
            pos="relative"
            bgColor={'white'}
            p={8}
            borderWidth={1}
            borderColor={'gray.200'}
            mb={8}
          >
            {isLoading && (
              <Box
                pos="absolute"
                left={0}
                top={0}
                display="flex"
                alignItems="center"
                justifyContent="center"
                w="100%"
                h="100%"
                zIndex={2}
                bg="white"
              >
                <Spinner size="lg" />
              </Box>
            )}
            <Box>
              <Heading as={'h5'} size={'md'} mb={4}>
                Purchasing
              </Heading>
              <Divider />
              <HStack mt={4}>
                <Box w="25%">
                  <VStack divider={<Divider />} align={'flex-start'}>
                    <StatCard
                      label="RFQs Active"
                      number={
                        data?.activeRfqsByStatus?.reduce((acc, cur) => cur.count + acc, 0) ?? 0
                      }
                      helpText="Now"
                    />
                    <StatCard
                      label="RFQs Created"
                      number={data?.rfqsCreated ?? 0}
                      helpText="This Month"
                    />
                  </VStack>
                </Box>
                {/* <StatCard label='Created' number={35} helpText='This Month'  /> */}
                <Box w="75%" textAlign={'center'}>
                  <Heading as={'h5'} size={'sm'}>
                    Active RFQ Status Breakdown
                  </Heading>
                  <ActiveRfqByStatus data={data?.activeRfqsByStatus ?? []} />
                </Box>
              </HStack>
              <Divider />
              <HStack mt={4}>
                <Box w="25%">
                  <VStack divider={<Divider />} align={'flex-start'}>
                    <StatCard
                      label="RFQ Avg Response"
                      number={data?.rfqAvgResponseTime ?? 0}
                      helpText="This Month (Days)"
                    />
                    <StatCard
                      label="RFQ Time to Award"
                      number={`${
                        data?.rfqTimeToAward === 0 ? 'N/A' : `${data?.rfqTimeToAward ?? 0} days`
                      }`}
                      helpText={data?.rfqTimeToAward === 0 ? '' : 'This Month (Days)' ?? ''}
                    />
                  </VStack>
                </Box>
                <Box w="75%" textAlign={'center'}>
                  <Heading as={'h5'} size={'sm'}>
                    RFQ Load
                  </Heading>
                  <RfqAssignedLoad data={data?.rfqCountByAssigned ?? []} />
                </Box>
              </HStack>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default RfqKPIsReport;
