import * as React from 'react';
import { Button, Card, Grid, Header, Menu, Message, Segment, Table } from 'semantic-ui-react';
import { FormattedDate } from 'react-intl';
import * as moment from 'moment';

export interface TranslatorInfoTableProps {
  translator: any;
  raised?: boolean;
}

export function TranslatorInfoTable(props: TranslatorInfoTableProps) {
  const { translator } = props;
  return (
    <Segment raised={props.raised} size={'small'} loading={translator === null}>
      {translator && (
        <Grid columns={'2'} divided>
          <Grid.Row>
            <Grid.Column>
              <Table basic="very" celled size={'small'}>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>ID</Table.Cell>
                    <Table.Cell>{translator.humanId}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Name</Table.Cell>
                    <Table.Cell>{`${translator.firstName} ${translator.lastName}`}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Contact</Table.Cell>
                    <Table.Cell></Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Sign Up</Table.Cell>
                    <Table.Cell>
                      <FormattedDate value={translator.createdAt} />
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Grid.Column>
            <Grid.Column>
              <Table basic="very" celled size={'small'}>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>Email</Table.Cell>
                    <Table.Cell>{translator.email}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Phone</Table.Cell>
                    <Table.Cell>{translator.phone}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Address</Table.Cell>
                    <Table.Cell>
                      <div>{translator.address1}</div>
                      <div>{translator.address2}</div>
                      <div>
                        {translator.city} {translator.stateProvince}, {translator.postalCode}
                      </div>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
    </Segment>
  );
}
