import * as React from 'react';
import { Form, Checkbox, Icon, Popup } from 'semantic-ui-react';
import { Select } from 'chakra-react-select';

export interface IVendorPickerProps {
  style: React.CSSProperties;
  value: {
    vendor: string;
    vendorData: any;
  };
  vendors: { label: string; value: string }[];
  onChange: (value: any) => void;
}

export function VendorPicker(props: IVendorPickerProps) {
  const { value, onChange, vendors, style } = props;

  const foundVendor = vendors?.find((v) => v.value === value.vendor);

  return (
    <Select
      onChange={(v) =>
        onChange({
          vendor: v.value,
          vendorData: vendors.find((ven) => ven.value === v.value),
        })
      }
      placeholder="Search vendors..."
      isClearable={true}
      options={vendors}
      // defaultValue={vendors ? vendors[0] : undefined}
      value={foundVendor}
      styles={{
        container: (provided, state) => ({
          ...provided,
          flexGrow: 1,
        }),
        valueContainer: (provided, state) => ({
          ...provided,
        }),
        control: (provided, state) => ({
          ...provided,
        }),
      }}
    />
  );
}
