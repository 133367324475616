import React from 'react';
import { Box } from '@chakra-ui/react';
import type { ModelTypes } from '@inkcloud/icapi-types';

import { SegmentCard } from '../../../../common';

export type IAddressProps = NonNullable<Pick<ModelTypes.Rfq, 'billTo' | 'shipTo'>>;

interface IBillToShipToProps {
  data: IAddressProps;
  type: 'billTo' | 'shipTo';
  title: string;
  subTitle: string;
  actionButtonLabel?: string;
  onEditClick?: () => void;
}

export default function BillToShipTo(props: IBillToShipToProps) {
  const { data, type, title, subTitle, actionButtonLabel = 'Edit', onEditClick } = props;

  return (
    <>
      {data?.[type]?.name ? <Box>{data?.[type]?.name}</Box> : null}
      {data?.[type]?.careOf ? <Box>{data?.[type]?.careOf}</Box> : null}
      {data?.[type]?.address1 ? <Box>{data?.[type]?.address1}</Box> : null}
      {data?.[type]?.address2 ? <Box>{data?.[type]?.address2}</Box> : null}
      <Box>
        {data?.[type]?.city} {data?.[type]?.stateProvince} {data?.[type]?.postalCode}
      </Box>
      <Box>{data?.[type]?.countryCode}</Box>
    </>
  );
}
