import React, { useState, useEffect } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { PageHeaderRow, EmptyWrapper } from '@inkcloud/components';
// import moment from 'moment';
import format from 'date-fns/format';
import addYears from 'date-fns/addYears';

// import * as numeral from 'numeral';
import { Box, Table, Tbody, Tr, Th, Td, Flex, Spinner, Button } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { FormattedNumber } from 'react-intl';

import Feathers from '../../../bootstrap/feathers';

// import { FilterForm } from '../FilterForm';
import FilterForm from '../FilterForm2';

interface ListProps {}

// export const List: React.FunctionComponent<ListProps & RouteComponentProps<any>> = (props) => {
//   const {
//     match: { params },
//     location,
//     history,
//   } = props;

//   const [filter, setFilter] = useState({}) as any;
//   const [data, setData] = useState({}) as any;
//   const [isLoading, setIsLoading] = useState(false);

//   const loadReport = async (values: any, isExport: boolean) => {
//     const queryParams = {
//       startDate: values.start ? moment(values.start).format('YYYY-MM-DD') : undefined,
//       endDate: values.end ? moment(values.end).format('YYYY-MM-DD') : undefined,
//       microsite: values.microsites ? values.microsites : undefined,
//     };

//     return await Feathers.service('/reports').find({
//       query: {
//         action: 'salesTaxComprehensive',
//         ...queryParams,
//       },
//     });
//   };

//   useEffect(() => {
//     const loadData = async () => {
//       setIsLoading(true);
//       try {
//         const res = await loadReport(filter, false);
//         setData(res);
//         setIsLoading(false);
//       } catch (e) {
//         setIsLoading(false);
//         setData([]);
//       }
//     };

//     if (Object.keys(filter).length) {
//       loadData();
//     }
//   }, [filter]);

//   return (
//     <>
//       <PageHeaderRow header="Sales Tax Advanced" subheader="Report" />

//       <FilterForm
//         value={filter}
//         hasMicrosites={true}
//         onChange={(v) => setFilter(v)}
//         onReset={() => setFilter({})}
//       />
//       <EmptyWrapper queryResult={data && Object.keys(filter).length} isStateLoading={isLoading}>
//         <Segment>
//           <Table definition>
//             <Table.Body>
//               <Table.Row>
//                 <Table.Cell>Sales Tax Collected</Table.Cell>
//                 <Table.Cell>{numeral(data.salesTaxCollected).format('$0,0.00')}</Table.Cell>
//               </Table.Row>
//               <Table.Row>
//                 <Table.Cell>Taxable Sales</Table.Cell>
//                 <Table.Cell>{numeral(data.taxableSales).format('$0,0.00')}</Table.Cell>
//               </Table.Row>
//               <Table.Row>
//                 <Table.Cell>Tax Exempt - Interstate Sales</Table.Cell>
//                 <Table.Cell>{numeral(data.taxExemptInterstateSales).format('$0,0.00')}</Table.Cell>
//               </Table.Row>
//               <Table.Row>
//                 <Table.Cell>Tax Exempt - Shipping</Table.Cell>
//                 <Table.Cell>{numeral(data.taxExemptShipping).format('$0,0.00')}</Table.Cell>
//               </Table.Row>
//               <Table.Row>
//                 <Table.Cell>Tax Exempt - In State Reseller</Table.Cell>
//                 <Table.Cell>{numeral(data.taxExemptInStateReseller).format('$0,0.00')}</Table.Cell>
//               </Table.Row>
//               <Table.Row>
//                 <Table.Cell>Sales (others)</Table.Cell>
//                 <Table.Cell>{numeral(data.salesOthers).format('$0,0.00')}</Table.Cell>
//               </Table.Row>
//               <Table.Row>
//                 <Table.Cell>Total</Table.Cell>
//                 <Table.Cell>{numeral(data.total).format('$0,0.00')}</Table.Cell>
//               </Table.Row>
//             </Table.Body>
//           </Table>
//         </Segment>
//       </EmptyWrapper>
//     </>
//   );
// };

const initValue = { start: null, end: null, microsites: null };

export default function SalesTax() {
  const history = useHistory();

  const tenant = useSelector(
    ({
      globals,
    }: {
      globals: { tenant: { internationalization: { locale: string; currency: string } } };
    }) => globals.tenant.internationalization
  );

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [filter, setFilter] = useState<{
    start: null | Date;
    end: null | Date;
    microsites: { [key: string]: string } | null;
  }>(initValue);

  const [data, setData] = useState<any>({});
  const [errorMessage, setErrorMessage] = useState('');

  const loadData = async () => {
    setErrorMessage('');
    setIsSubmitting(true);
    setData([]);

    try {
      const res = await Feathers.service('/reports').find({
        query: {
          action: 'salesTaxComprehensive',
          startDate: filter.start ? format(filter.start, 'yyyy-MM-dd') : undefined,
          endDate: filter.end ? format(filter.end, 'yyyy-MM-dd') : undefined,
          microsite: filter.microsites ? filter.microsites?.value : undefined,
        },
      });
      setData(res);
    } catch (e) {
      const errMsg =
        e?.code < 500 ? e?.message : 'We are experiencing technical difficulties. Please try again';
      setErrorMessage(errMsg);
      setData([]);
    }

    setIsSubmitting(false);
  };

  const maxDate = addYears(filter?.start as Date, 1);
  const exceedDate = filter?.start && filter?.end ? filter.end > maxDate : false;

  useEffect(() => {
    if (filter?.start && filter?.end && !exceedDate) {
      loadData();
    }
  }, [filter]);

  const handleReset = () => {
    setData([]);
    setFilter(initValue);
  };

  return (
    <>
      <PageHeaderRow header="Sales Tax Advanced" subheader="Report">
        <Button onClick={() => history.goBack()}>Back</Button>
      </PageHeaderRow>
      <Box p={3} mb={3} borderWidth={1} borderRadius="md" bg="white">
        <FilterForm
          values={filter}
          hasMicrosites={true}
          isSubmitting={isSubmitting}
          errorMessage={errorMessage}
          onChange={(v) => {
            setErrorMessage('');
            setFilter(v);
          }}
          onReset={handleReset}
        />
      </Box>

      <EmptyWrapper
        queryResult={data && filter?.start && filter?.end}
        isStateLoading={isSubmitting}
      >
        <Box p={3} borderWidth={1} borderRadius="md" bg="white">
          <Table>
            <Tbody>
              <Tr>
                <Th>Sales Tax Collected</Th>
                <Td isNumeric>
                  <FormattedNumber
                    currency={tenant?.currency}
                    value={data?.salesTaxCollected ?? 0}
                    style="currency"
                  />
                </Td>
              </Tr>
              <Tr>
                <Th>Taxable Sales</Th>
                <Td isNumeric>
                  <FormattedNumber
                    currency={tenant?.currency}
                    value={data?.taxableSales ?? 0}
                    style="currency"
                  />
                </Td>
              </Tr>
              <Tr>
                <Th>Tax Exempt - Interstate Sales</Th>
                <Td isNumeric>
                  <FormattedNumber
                    currency={tenant?.currency}
                    value={data?.taxExemptInterstateSales ?? 0}
                    style="currency"
                  />
                </Td>
              </Tr>
              <Tr>
                <Th>Tax Exempt - Shipping</Th>
                <Td isNumeric>
                  <FormattedNumber
                    currency={tenant?.currency}
                    value={data?.taxExemptShipping ?? 0}
                    style="currency"
                  />
                </Td>
              </Tr>
              <Tr>
                <Th>Tax Exempt - In State Reseller</Th>
                <Td isNumeric>
                  <FormattedNumber
                    currency={tenant?.currency}
                    value={data?.taxExemptInStateReseller ?? 0}
                    style="currency"
                  />
                </Td>
              </Tr>
              <Tr>
                <Th>Sales (others)</Th>
                <Td isNumeric>
                  <FormattedNumber
                    currency={tenant?.currency}
                    value={data?.salesOthers ?? 0}
                    style="currency"
                  />
                </Td>
              </Tr>
              <Tr>
                <Th>Total</Th>
                <Td isNumeric>
                  <FormattedNumber
                    currency={tenant?.currency}
                    value={data?.total ?? 0}
                    style="currency"
                  />
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </Box>
      </EmptyWrapper>
    </>
  );
}
