import * as React from 'react';
import { Switch, Route } from 'react-router-dom';

import { List } from './List';
// import Detail from './Detail';
// import Edit from './Edit';

const routes = (props) => (
  <Switch>
    <Route exact path="/translation/estimates" component={List} />
    {/* <Route exact path='/estimates/add' component={Edit} />
    <Route exact path='/estimates/:id' component={Detail} /> */}
  </Switch>
);

export default routes;
