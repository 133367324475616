import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Box, Button } from '@chakra-ui/react';
import { PageHeaderRow, PreLoader, useFeathers } from '@inkcloud/components';
import { useSelector } from 'react-redux';

import type { ModelTypes } from '@inkcloud/icapi-types';

import ContactForm from './ContactForm';
import feathers from '../../../bootstrap/feathers';

interface DetailProps {
  products: { value: string; text: string }[];
  categories: { value: string; text: string }[];
}

const pathPrefix = `/settings/contact-information`;
const serviceName = '/tenant-settings';
const service = feathers.service(serviceName);
const commonParams = {
  query: {
    $select: ['publishedContactInfo'],
  },
};

type contactType = ModelTypes.TenantSettings;

export default function Detail(props: DetailProps & RouteComponentProps) {
  const { history } = props;

  const user = useSelector((state: any) => state.auth.user);

  const tenant = useSelector(
    ({ globals }: { globals: { tenant: { internationalization: { countryCode: string } } } }) =>
      globals.tenant.internationalization
  );

  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [state, reload] = useFeathers('get', serviceName, [user.business, commonParams], true);

  const handleSubmit = async (values) => {
    setIsSubmitting(true);
    try {
      setErrorMessage('');
      await feathers
        .service('tenant-settings/contact-info')
        .patch(user.business, values, { query: {} });
      history.push('/settings');
    } catch (e) {
      const errMsg =
        e?.code < 500 ? e.message : 'We are experiencing technical difficulties. Please try again';
      setErrorMessage(errMsg);
    }
    setIsSubmitting(false);
  };

  if (!state.data) {
    return <PreLoader />;
  }

  const initialData: contactType = {
    ...state.data,
    countryCode: state.data?.publishedContactInfo?.address?.countryCode ?? tenant.countryCode,
  };

  return (
    <div>
      <PageHeaderRow header="Contact Information" subheader="Settings">
        <Button size="sm" onClick={() => history.goBack()}>
          Back
        </Button>
      </PageHeaderRow>

      <Box p={3} mb={3} borderRadius="md" borderWidth="1px">
        <ContactForm
          initialValues={initialData?.publishedContactInfo}
          errorMessage={errorMessage}
          isSubmitting={isSubmitting}
          onSubmit={handleSubmit}
        />
      </Box>
    </div>
  );
}
