import * as React from 'react';
import { Formik, Form as FormikForm, Field, FieldArray, ErrorMessage } from 'formik';
import { Button, Form, Grid, Popup, Message, Segment, Header } from 'semantic-ui-react';
import { SemanticField } from '../../../common/Form';

interface GeneralFormProps {
  initialValues: any;
  errorMessage: string;
  isLoading: boolean;
  onSubmit: (v: any) => void;
}

const { useState } = React;

export const GeneralForm: React.FunctionComponent<GeneralFormProps> = (props) => {
  const { initialValues, errorMessage, isLoading, onSubmit } = props;

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values);
        }}
      >
        {(innerProps) => {
          const {
            values,
            touched,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            setFieldValue,
            setFieldTouched,
          } = innerProps;

          return (
            <>
              <Header as="h4" content="Settings" subheader="" attached="top" />
              <FormikForm
                className={`ui form ${isLoading && !errorMessage && 'loading'}`}
                style={{ height: '100%' }}
              >
                <Segment secondary attached="bottom">
                  <Grid divided="vertically">
                    <Grid.Row>
                      <Grid.Column width="3">Show cost center</Grid.Column>
                      <Grid.Column width="13">
                        <SemanticField
                          fluid
                          name="costCenter.showCostCenter"
                          toggle
                          component={Form.Checkbox}
                          label="Show cost center"
                        />

                        {values?.costCenter?.showCostCenter && (
                          <FieldArray
                            name={'costCenter.costCenterOptions'}
                            render={(arrayHelpers) => (
                              <div>
                                {values.costCenter &&
                                  values.costCenter.costCenterOptions &&
                                  values.costCenter.costCenterOptions.length > 0 &&
                                  values.costCenter.costCenterOptions.map((f, index) => (
                                    <div key={index}>
                                      {
                                        <Form.Group widths="equal">
                                          <Form.Field>
                                            <SemanticField
                                              fluid
                                              name={`costCenter.costCenterOptions[${index}].code`}
                                              component={Form.Input}
                                              label="Code"
                                            />
                                          </Form.Field>
                                          <Form.Field>
                                            <SemanticField
                                              fluid
                                              name={`costCenter.costCenterOptions[${index}].description`}
                                              component={Form.Input}
                                              label="Description"
                                            />
                                          </Form.Field>
                                          <Form.Field>
                                            <label>&nbsp;</label>
                                            <Popup
                                              content="Remove recipient"
                                              trigger={
                                                <Button
                                                  type="button"
                                                  onClick={() => arrayHelpers.remove(index)}
                                                  icon="remove"
                                                />
                                              }
                                            />
                                          </Form.Field>
                                        </Form.Group>
                                      }
                                    </div>
                                  ))}
                                <Button
                                  type="button"
                                  size="tiny"
                                  onClick={() =>
                                    arrayHelpers.push({
                                      code: '',
                                      description: '',
                                    })
                                  }
                                  content={'Add cost center'}
                                />
                              </div>
                            )}
                          />
                        )}
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                      <Grid.Column width="3">Sales People Public Access</Grid.Column>
                      <Grid.Column width="13">
                        <SemanticField
                          fluid
                          name="salesPeoplePublicAccess"
                          toggle
                          component={Form.Checkbox}
                          label="Sales People Public Access"
                        />
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                      <Grid.Column width="3">Sync Customer Details To Billing Info</Grid.Column>
                      <Grid.Column width="13">
                        <SemanticField
                          fluid
                          name="syncCustomerDetailsToBillingInfo"
                          toggle
                          component={Form.Checkbox}
                          label="Sync Customer Details To Billing Info"
                        />
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                      <Grid.Column width="3">Hide Prices</Grid.Column>
                      <Grid.Column width="13">
                        <SemanticField
                          fluid
                          name="hidePrices"
                          toggle
                          component={Form.Checkbox}
                          label="Hide Prices"
                        />
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                      <Grid.Column width="3">Required cost center for checkout</Grid.Column>
                      <Grid.Column width="13">
                        <SemanticField
                          fluid
                          name="costCenter.requireCostCenterForCheckout"
                          toggle
                          component={Form.Checkbox}
                          label="Required cost center for checkout"
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>

                  <Grid divided="vertically">
                    <Grid.Row>
                      <Grid.Column width="3">Enable Custom Domain</Grid.Column>
                      <Grid.Column width="13">
                        <SemanticField
                          fluid
                          name="customDomain.enable"
                          toggle
                          component={Form.Checkbox}
                          label="Enable Custom Domain"
                        />
                        {values?.customDomain?.enable && (
                          <Form.Field>
                            <SemanticField
                              fluid
                              name="customDomain.domainName"
                              component={Form.Input}
                              label="Custom Domain"
                            />
                          </Form.Field>
                        )}
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                      <Grid.Column width="3">Restrict to Approved Users</Grid.Column>
                      <Grid.Column width="13">
                        <SemanticField
                          fluid
                          name="restrictToApprovedUsers"
                          toggle
                          component={Form.Checkbox}
                          label="Restrict to Approved Users"
                        />
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                      <Grid.Column width="3">Signup Required Captcha</Grid.Column>
                      <Grid.Column width="13">
                        <SemanticField
                          fluid
                          name="signUpRequiredCaptcha"
                          toggle
                          component={Form.Checkbox}
                          label="Signup Required Captcha"
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>

                  <Form.Field>
                    <Button primary type="submit" disabled={isLoading && !errorMessage}>
                      Save
                    </Button>
                  </Form.Field>
                </Segment>
              </FormikForm>
            </>
          );
        }}
      </Formik>

      {errorMessage && <Message error content={errorMessage} />}
    </>
  );
};
