import React, { useEffect, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';

import { Select } from 'chakra-react-select';

import {
  Box,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Stack,
  Button,
  Alert,
} from '@chakra-ui/react';

import type { ModelTypes } from '@inkcloud/icapi-types';
import { useSelector } from 'react-redux';
import Feathers, { icapi } from '../../../bootstrap/feathers';

type Values = {
  name: string;
  accountType: string;
  accountNumber: string;
  qbOnlineAccount?: string;
  integrations?: any[];
};

interface GLAccountFormProps {
  initialValues: Values;
  isSubmitting: boolean;
  errorMessage: string;
  onSubmit: (v: any) => void;
}

const accountTypeOptions = [
  { value: 'income', label: 'Income' },
  { value: 'cost-of-goods', label: 'Cost of goods' },
];

const initalMapper = (values: Values) => ({
  ...values,
  accountType: values?.accountType || accountTypeOptions[0]?.value,
});

export default function GLAccountForm(props: GLAccountFormProps) {
  const { initialValues, onSubmit, isSubmitting, errorMessage: errorMessageSubmit } = props;

  const [values, setValues] = useState<Values>(initialValues);
  const authedUser = useSelector((state: any) => state.auth.user);
  const [integrationLabel, setIntegrationLabel] = useState('');
  const [integrationKey, setIntegrationKey] = useState('');

  const [errorMessage, setErrorMessage] = useState('');

  const [isAccountingIntegrationsEnable, setIsAccountingIntegrationsEnable] = useState(false);

  const [qbOnlineAccounts, setQbOnlineAccounts] = useState<
    { id: string; name: string; type: string }[]
  >([]);

  useEffect(() => {
    setValues(initalMapper(initialValues));
  }, [initialValues]);

  useEffect(() => {
    const loadSettings = async () => {
      const { accountingIntegrations } = await icapi
        .service('tenant-settings')
        .get(authedUser?.tid);
      setIsAccountingIntegrationsEnable((accountingIntegrations?.qbOnline?.isEnabled || accountingIntegrations?.xero?.isEnabled) ?? false);
      if (accountingIntegrations?.qbOnline?.isEnabled) {
        try {
          setErrorMessage('');
          const accounts = await (icapi.service('integrations/qb-online/accounts') as any).find({});
          setQbOnlineAccounts(accounts);
          setIntegrationLabel('QB Online Account');
          setIntegrationKey('qb-online');
        } catch (e) {
          const errMsg =
            e?.code < 500
              ? e.message
              : 'We were unable to retrieve your accounts through accounting integration.';

          setErrorMessage(errMsg);
        }
      }

      if (accountingIntegrations?.xero?.isEnabled) {
        try {
          setErrorMessage('');
          const accounts = await (icapi.service('integrations/xero/accounts') as any).find({});
          setQbOnlineAccounts(accounts);
          setIntegrationLabel('Xero Account');
          setIntegrationKey('xero');

        } catch (e) {
          const errMsg =
            e?.code < 500
              ? e.message
              : 'We were unable to retrieve your accounts through accounting integration.';

          setErrorMessage(errMsg);
        }
      }
    };

    loadSettings();
  }, []);

  const handleChange = (data) => {
    const { name, value } = data;
    const updatedValue = { ...values, [name]: value };

    if (name === integrationKey) {
      if (!updatedValue.integrations) {
        updatedValue.integrations = [];
      }

      const nameLookup = qbOnlineAccounts.find((a: any) => a.id === data.value)?.name;
      const isFound = updatedValue.integrations.find((i: any) => i.type === integrationKey);
      if (!isFound) {
        updatedValue.integrations.push({ type: integrationKey });
      }

      updatedValue.integrations = updatedValue.integrations.map((a: any) =>
        a.type === integrationKey ? { ...a, foreignId: value, foreignName: nameLookup } : a
      );
    }

    setValues(updatedValue);
  };

  const foundQb = qbOnlineAccounts?.find(
    (qb) => values?.integrations?.find((i: any) => i.type === integrationKey)?.foreignId === qb.id
  );

  return (
    <>
      <Stack spacing={7} mb={3} direction="row">
        <FormControl isInvalid={!values?.name?.trim()}>
          <FormLabel>Account Name</FormLabel>
          <Input
            type="text"
            name="name"
            value={values.name}
            onChange={(e) => handleChange(e.target)}
          />
          {!values?.name?.trim() && <FormErrorMessage>Required</FormErrorMessage>}
        </FormControl>
        <FormControl isInvalid={!values?.accountNumber?.trim()}>
          <FormLabel>Account Number</FormLabel>
          <Input
            type="text"
            name="accountNumber"
            value={values.accountNumber}
            onChange={(e) => handleChange(e.target)}
          />
          {!values?.accountNumber?.trim() && <FormErrorMessage>Required</FormErrorMessage>}
        </FormControl>
        {isAccountingIntegrationsEnable && (
          <FormControl isInvalid={!!errorMessage}>
            <FormLabel>{integrationLabel}</FormLabel>
            {qbOnlineAccounts?.length > 0 && (
              <Select
                defaultValue={({ label: foundQb?.name, value: foundQb?.id } as any) || null}
                onChange={(e) => handleChange({ name: integrationKey, value: e?.value })}
                options={qbOnlineAccounts.map((a: any) => ({
                  value: a.id,
                  label: `${a.name} (${a.id})`,
                  // label: {
                  //   text: 'hah',
                  //   content: a.type,
                  //   color: 'blue',
                  //   empty: false,
                  //   circular: false,
                  // },
                }))}
              />
            )}
            {errorMessage && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
          </FormControl>
        )}
        <FormControl>
          <FormLabel>Account Type</FormLabel>
          <Select
            defaultValue={
              accountTypeOptions?.find((a) => a.value === values?.accountType) ??
              accountTypeOptions[0]
            }
            onChange={(e) => handleChange({ name: 'accountType', value: e?.value })}
            options={accountTypeOptions}
          />
        </FormControl>
      </Stack>

      {errorMessageSubmit && (
        <Alert status="error" mb={3}>
          {errorMessageSubmit}
        </Alert>
      )}

      <Button
        colorScheme="blue"
        isDisabled={
          isSubmitting || values?.name?.trim() === '' || values?.accountNumber?.trim() === ''
        }
        isLoading={isSubmitting}
        onClick={() => onSubmit(values)}
      >
        Save
      </Button>

    </>
  );
}
