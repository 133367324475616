import * as React from 'react';
import { Switch, Route } from 'react-router-dom';

import List from './List';
import Detail from './Detail';

const routes = (props) => (
  <Switch>
    <Route exact path="/cost-estimating" component={List} />
    <Route path="/cost-estimating/add" component={Detail} />
    <Route path="/cost-estimating/:id" component={Detail} />
  </Switch>
);

export default routes;
