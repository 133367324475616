import * as React from 'react';
import { Button, Input, Dropdown, Segment, Form } from 'semantic-ui-react';
import { debounce } from 'lodash-es';

const { useState, useEffect } = React;

export interface FilterFormProps {
  value: any;
  onChange: Function;
  onReset: Function;
}

function initMapper(value) {
  const defaults = {
    name: '',
    city: '',
    stateProvince: '',
    postalCode: [],
  };

  return Object.keys(defaults).reduce((p, cur) => {
    if (!p[cur]) {
      p[cur] = defaults[cur];
    }
    return p;
  }, value);
}

export function FilterForm(props: FilterFormProps) {
  const { value: preInitValue } = props;
  const [isSetup, setIsSetup] = useState(false);

  const value = initMapper(preInitValue);

  const onChange = (field, value) => {
    props.onChange && props.onChange({ ...props.value, [field]: value });
  };

  return (
    <Segment>
      <Form size={'small'}>
        <Form.Group widths={'equal'}>
          <Form.Field>
            <Input
              placeholder="Name..."
              value={value.staticName}
              onChange={(e, data) => onChange('name', data.value)}
            />
          </Form.Field>
          <Form.Field>
            <Form.Input
              placeholder="City..."
              value={value.key}
              onChange={(e, data) => onChange('city', data.value)}
            />
          </Form.Field>
          <Form.Field>
            <Form.Input
              placeholder="State..."
              value={value.key}
              onChange={(e, data) => onChange('stateProvince', data.value)}
            />
          </Form.Field>
          <Form.Field>
            <Form.Input
              placeholder="Postal Code..."
              value={value.key}
              onChange={(e, data) => onChange('postalCode', data.value)}
            />
          </Form.Field>
        </Form.Group>
        <Form.Field>
          <Button size={'tiny'} content="Reset Form" onClick={() => props.onReset()} />
        </Form.Field>
      </Form>
    </Segment>
  );
}
