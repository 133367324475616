import * as React from 'react';
import {
  Button,
  Checkbox,
  Grid,
  Segment,
  Table,
  Label,
  Popup,
  Divider,
  Modal,
  Tab,
} from 'semantic-ui-react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { FormattedDate } from 'react-intl';
import { IndexWrapper, IndexWrappedProps } from './../../bootstrap/IndexWrapper';
import {
  OrderItemComponent2,
  connectOrderItemModals,
  OrderItemModalsProps,
} from './../../common/OrderItemCard2';
import { useList, PageHeaderRow, PaginatorWrapper, EmptyWrapper } from '@inkcloud/components';

import Feathers from './../../bootstrap/feathers';

import { FeathersDropdown } from '@inkcloud/components';

interface ListProps {}

const filterQueryMap = (values) => {};

const serviceName = 'order-items';
const pathPrefix = '/order-voided';

const PreList: React.FunctionComponent<
  ListProps & OrderItemModalsProps & RouteComponentProps<any>
> = (props) => {
  const {
    match: { params },
    location,
    history,
  } = props;
  const {
    state,
    reload,
    handleDelete,
    selectedItems,
    clearAll,
    setChecked,
    someSelected,
    toggleAll,
    filter,
    setFilter,
    sort,
    handleSort,
  } = useList({
    feathers: [
      'find',
      serviceName,
      [
        {
          query: {
            'productionStatus.jobStatus': { $in: 'VOID' },
            $populate: [
              'attributes.attribute',
              'attributes.value',
              'category',
              'customer',
              'micrositeUser',
              'productionStatus.jobStatus',
            ],
            // $sort: { createdAt: -1 }
          },
        },
      ],
    ],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  const handleArtworkManagementClick = (e, data) => {
    props.artworkHandleOpen(data.orderitem);
  };

  const printHandleOpen = (e, data) => {
    props.printHandleOpen(data.type, data.payload);
  };

  const preflightHandleOpen = (e, data) => {
    props.preflightHandleOpen(data.orderitem);
  };

  const statusChangeHandleOpen = (e, data) => {};

  const { queryResult, isLoading: isStateLoading } = state as any;

  if (!queryResult) {
    return null;
  }

  // const sortedOrderItems = queryResult?.data
  //   ?.map((i: any) => ({
  //     ...i,
  //     priority: parseInt(i.humanId),
  //   }))
  //   ?.sort((a, b) => a.priority - b.priority);

  return (
    <>
      <PageHeaderRow header="Items" subheader="Order Voided" />

      <Segment>
        <EmptyWrapper queryResult={queryResult} isStateLoading={isStateLoading}>
          <PaginatorWrapper.Top
            filter={filter}
            reload={reload}
            data={queryResult as any}
            pathPrefix={`${pathPrefix}/`}
          />
          <Divider hidden />

          {queryResult?.data?.map((i: any, index: number) => (
            <OrderItemComponent2
              key={index}
              orderItem={i}
              handleArtworkManagementClick={handleArtworkManagementClick}
              printHandleOpen={printHandleOpen}
              preflightHandleOpen={preflightHandleOpen}
              statusChangeHandleOpen={statusChangeHandleOpen}
              onReload={reload}
            />
          ))}

          <Divider hidden />

          <PaginatorWrapper.Bottom
            reload={reload}
            data={queryResult as any}
            pathPrefix={`${pathPrefix}`}
          />
        </EmptyWrapper>
      </Segment>
    </>
  );
};

export const List = connectOrderItemModals<IndexWrappedProps>(PreList);
