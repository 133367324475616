import * as React from 'react';
import { Modal, Form, Button, List, Label, Icon, Message } from 'semantic-ui-react';
import { Feathers } from '../../../bootstrap/feathers/feathers';

interface StatusFilterModalProps {
  id: string;
  isOpen: boolean;
  onClose: () => void;
}

const { useEffect, useState } = React;

export const StatusFilterModal: React.FunctionComponent<StatusFilterModalProps> = (props) => {
  const { id, isOpen, onClose } = props;

  const [list, setList] = useState([]);
  const [selected, setSelected] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const loadStatus = async () => {
      const list: any = await Feathers.service('/order-item-statuses').find({
        query: { $np: 1, $all: 1 },
      });
      const res: any = await Feathers.service('/tenant-settings/status').get(id, {
        query: { $np: 1, $all: 1 },
      });

      const newList = list.filter((l) => !res.jobStatus.find((j) => j === l._id));
      const newSelected = list.filter((l) => res.jobStatus.find((j) => j === l._id));

      setSelected(newSelected);
      setList(newList);
    };

    loadStatus();
  }, []);

  const handleSelection = (value: any, index?: number) => {
    const found = selected.find((s) => s._id === value._id);

    if (!found) {
      setSelected([...selected, { ...value, index }]);
      const f = list.filter((s) => s._id !== value._id);
      setList(f);
    } else {
      const filtered = selected.filter((s) => s._id !== value._id);
      const newList = [...list];

      if (found.index !== undefined) {
        newList.splice(found.index, 0, found);
      } else {
        newList.splice(list.length, 0, found);
      }

      setSelected(filtered);
      setList(newList);
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const newSelected = selected.map((s) => s._id);
    try {
      const res = await Feathers.service('/tenant-settings/status').patch(
        id,
        { jobStatus: newSelected },
        { query: {} }
      );
      setIsLoading(false);
      setErrorMessage('');
      onClose();
    } catch (error) {
      setErrorMessage(error.message);
      setIsLoading(false);
    }
  };

  return (
    <Modal open={isOpen} onClose={onClose} closeIcon>
      <Modal.Header>Status List</Modal.Header>
      <Modal.Content scrolling>
        {errorMessage && <Message error content={errorMessage} />}
        <Form loading={isLoading}>
          <Form.Group widths="equal">
            <Form.Field>
              <label>List:</label>
              <List>
                {list.length > 0 &&
                  list.map((l: any, index: number) => (
                    <List.Item key={index}>
                      <Label
                        onClick={() => handleSelection(l, index)}
                        style={{ cursor: 'pointer' }}
                      >
                        {l.name}
                      </Label>
                    </List.Item>
                  ))}
              </List>
            </Form.Field>
            <Form.Field>
              <label>Selected:</label>
              <List>
                {selected.length > 0 &&
                  selected.map((l: any, index: number) => (
                    <List.Item key={index}>
                      <Label onClick={() => handleSelection(l)} style={{ cursor: 'pointer' }}>
                        {l.name} <Icon name="delete" />
                      </Label>
                    </List.Item>
                  ))}
              </List>
            </Form.Field>
          </Form.Group>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button primary onClick={handleSubmit} content="Submit" />
      </Modal.Actions>
    </Modal>
  );
};
