// import * as React from 'react';
// import { Button, Header, Message, Segment } from 'semantic-ui-react';
// import { RouteComponentProps } from 'react-router-dom';
// import { DetailWrapper, DetailFeaturesProps } from './../../../bootstrap/DetailWrapper';
// import ResourceForm from './ResourceForm';

// export function Detail (props: DetailFeaturesProps ) {
//   const { data } = props;
//   return (
//     <div>
//       <Header as={'h1'}>Production Resource Detail
//         <Header.Subheader>
//           {data && `${data.name}`}
//         </Header.Subheader>
//       </Header>
//       <Segment attached>
//         <ResourceForm
//           initialValues={data}
//           onSubmit={props.handleSubmit}
//           isEdit={!!props.match.params.id}
//         />
//       </Segment>
//     </div>
//   );
// }

// export default DetailWrapper(Detail, 'productionResources', {
//   submitRedirect: '/production/batches'
// });

import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Message, Segment } from 'semantic-ui-react';
import { PageHeaderRow, useFeathers } from '@inkcloud/components';

import Feathers from '../../../../bootstrap/feathers';
import { ResourceForm } from './ResourceForm';

interface DetailProps {
  vendorOptions: Array<{ value: string; text: string }>;
}

const serviceName = 'production/resources';
const service = Feathers.service(serviceName);
const commonParams = { query: {} };

const pathPrefix = '/settings/production/resources';

const { useState } = React;

export const Detail: React.FunctionComponent<DetailProps & RouteComponentProps<{ id?: string }>> = (
  props
) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const {
    match: {
      params: { id },
    },
    history,
    vendorOptions,
  } = props;

  const isEdit = !!id;
  const [state, reload] = useFeathers('get', serviceName, [id, commonParams], true);

  const handleSubmit = async (value: any) => {
    setIsLoading(true);
    try {
      const res: any = isEdit
        ? await service.patch(id, value, commonParams)
        : await service.create(value, commonParams);
      history.push(pathPrefix);
    } catch (e) {
      e.code && e.code < 500
        ? setErrorMessage(e.message)
        : setErrorMessage('We are experiencing technical difficulties. Please try again');
    }
    setIsLoading(false);
  };

  const initialData = { ...state.data };

  return (
    <>
      <PageHeaderRow header={`${isEdit ? 'Edit' : 'New'} Resource`} subheader="Settings">
        <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
      </PageHeaderRow>
      <Segment>
        {errorMessage && <Message error content={errorMessage} />}
        {(!isEdit || state.data) && (
          <ResourceForm
            initialValues={initialData}
            vendorOptions={vendorOptions}
            errorMessage={errorMessage}
            isLoading={isLoading}
            onSubmit={handleSubmit}
          />
        )}
      </Segment>
    </>
  );
};
