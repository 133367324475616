import * as React from 'react';
import { Button, Input, Dropdown, Segment, Form } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
const { useState, useEffect } = React;

export interface FilterFormProps {
  value?: any;
  onChange?: Function;
  onReset?: Function;
  onSubmit?: any;
  handleClearFilter?: any;
  isFiltered?: any;
}

function initMapper(value) {
  const defaults = {
    // lineAmount: '',
  };

  return Object.keys(defaults).reduce((p, cur) => {
    if (!p[cur]) {
      p[cur] = defaults[cur];
    }
    return p;
  }, value);
}

export function FilterForm(props: FilterFormProps) {
  const { value: preInitValue } = props;

  const value = initMapper(preInitValue);
  const { start, end } = value;

  const onChange = (field, value) => {
    props.onChange && props.onChange({ ...props.value, [field]: value });
  };

  return (
    <Segment>
      <Form size={'small'}>
        <Form.Group widths={'equal'}>
          <Form.Field>
            <DatePicker
              dateFormat="MM/dd/yyyy"
              selected={start}
              selectsStart
              placeholderText="Start Date"
              startDate={start}
              endDate={end}
              onChange={(date) => onChange('start', date)}
            />
          </Form.Field>
          <Form.Field>
            <DatePicker
              dateFormat="MM/dd/yyyy"
              selected={end}
              selectsEnd
              placeholderText="End Date"
              startDate={start}
              endDate={end}
              onChange={(date) => onChange('end', date)}
            />
          </Form.Field>
        </Form.Group>
        <Form.Field>
          <Button size={'tiny'} content="Reset Form" onClick={() => props.onReset()} />
        </Form.Field>
      </Form>
    </Segment>
  );
}
