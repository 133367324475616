import * as React from 'react';
import { Box } from '@chakra-ui/react';
import { DndProvider, useDrop } from 'react-dnd';
import JobCard, { JobCardData } from './JobCard';
import JobSchedulingColumnHeader from './JobSchedulerColumnHeader';

interface IJobSchedulingColumnProps {
  id: string;
  isGlobal: boolean;
  index: number;
  columnTitle: string;
  jobs: JobCardData[];
  updateJobColumn: (_id, column: string) => void;
  onClick?: (_id: string, data: any) => void;
}

const JobSchedulingColumn: React.FunctionComponent<IJobSchedulingColumnProps> = (props) => {
  const { id, isGlobal, index, columnTitle, jobs = [], updateJobColumn, onClick } = props;

  const [{ isOver, canDrop }, drop] = useDrop(() => ({
    accept: 'BOX',
    collect(monitor) {
      return {
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      };
    },
    hover() {
      // console.log('hover', item, monitor)
    },
    canDrop(item: any) {
      if (isGlobal) {
        return true;
      }

      const canDragCard = item?.steps?.find((s) => s?.column === id);

      return !!canDragCard;
    },

    drop(item: any, monitor) {
      // const t = item?.steps?.find((s) => s?.column === id);
      // console.log({ t, item, monitor });
      updateJobColumn(item._id, id);
      // if (t) {
      // }
    },
  }));

  // console.log('canDrop', canDrop);

  let borderColor = 'gray.200';

  if (isOver && canDrop) {
    borderColor = 'blue.500';
  } else if (isOver && !canDrop) {
    borderColor = 'red.500';
  }

  return (
    <Box
      boxShadow={'inner'}
      py={2}
      px={1}
      marginInlineStart={index === 0 ? 0 : 4}
      width={300}
      borderWidth={isOver ? 2 : 1}
      borderColor={borderColor}
      borderStyle={isOver && canDrop ? 'dashed' : 'solid'}
      backgroundColor={'gray.50'}
      minH={150}
      ref={drop}
    >
      <JobSchedulingColumnHeader title={columnTitle} count={jobs.length} />
      {jobs?.map((j, idx) => (
        <JobCard key={j._id} data={j} onClick={(_id, data) => {
          if (onClick) {
            onClick(_id, data);
          }
        }}
        />
      ))}
    </Box>
  );
};

export default JobSchedulingColumn;
