import React, { useMemo } from 'react';
import {
  Box,
  Heading,
  Text,
  Spacer,
  HStack,
  Tag,
  IconButton,
  Menu,
  MenuList,
  MenuButton,
  MenuItem,
  TableContainer,
  Table,
  Tr,
  Th,
  Td,
  Tbody,
  SimpleGrid,
  Flex,
  Checkbox,
  FormControl,
  Wrap,
  WrapItem,
  Icon,
  Divider,
  Stack,
} from '@chakra-ui/react';

import { FaEllipsisV, FaEdit, FaTrash, FaCheckCircle, FaRegCircle } from 'react-icons/fa';
import type { ModelTypes } from '@inkcloud/icapi-types';

import ItemResponseForm from './ItemResponseForm';

import type { AttributeType, ResponseItemsType } from '../common/types';
import { attributeFinder } from './Manage/Specs';

interface IItemResponse {
  responseItems: ResponseItemsType;
  item: any;
  attributes?: AttributeType[];
  index: number;
  isReadOnly?: boolean;
  isMenuHidden?: boolean;
  onChange: (item: ResponseItemsType) => void;
}

export default function ItemResponse(props: IItemResponse) {
  const { item, responseItems, attributes, index, isReadOnly, isMenuHidden, onChange } = props;

  const foundItem = responseItems?.find(
    (i: ResponseItemsType[number]) => i?.item === item._id
  ) as ResponseItemsType[number];

  const handleChange = (updatedItem: ResponseItemsType[number]) => {
    const newItems = responseItems?.map((i) => {
      const allQtyAreOptedOut = updatedItem?.quantities?.every((q) => q?.isOptedOut);
      // console.log('allQtyAreOptedOut', allQtyAreOptedOut)

      return i.item === updatedItem.item
        ? {
            ...i,
            ...updatedItem,
            isOptedOut: allQtyAreOptedOut,
          }
        : i;
    });

    onChange(newItems);
  };

  const attributeTypes = useMemo(
    () =>
      attributes?.reduce((acc, cur) => {
        if (!acc[cur.typeId]) {
          acc[cur.typeId] = {
            name: cur.typeName,
            attributes: [{ value: 'OTHER', label: 'Other...' }],
          };
        }

        acc[cur.typeId].attributes = acc[cur.typeId].attributes.concat([
          { value: cur._id, label: cur.name },
        ]);

        return acc;
      }, {}),
    [attributes]
  );

  return (
    <>
      <Flex>
        <Heading size={'xs'}>Item {index + 1}</Heading>
        <Spacer />
        {isReadOnly || isMenuHidden ? null : (
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label="Options"
              icon={<FaEllipsisV />}
              variant="outline"
            />
            <MenuList>
              <MenuItem icon={<FaEdit />}>Edit Item</MenuItem>
              <MenuItem icon={<FaTrash />}>Remove Item</MenuItem>
            </MenuList>
          </Menu>
        )}
      </Flex>
      <Divider />
      <Stack direction={{ base: 'column', lg: 'row' }} spacing={8}>
        <Box w={{ base: '100%', lg: '50%' }}>
          <Box>
            <TableContainer whiteSpace="unset">
              <Table size="sm">
                <Tbody>
                  <Tr>
                    <Th>SKU</Th>
                    <Td>{item?.sku ?? ''}</Td>
                  </Tr>
                  <Tr>
                    <Th>Quantities</Th>
                    <Td>
                      <HStack>
                        {item?.quantities?.map((q, i) => (
                          <Tag size={'sm'} key={i}>
                            {q}
                          </Tag>
                        ))}
                      </HStack>
                    </Td>
                    {/* <Td isNumeric>25.4</Td> */}
                  </Tr>
                  <Tr>
                    <Th># of Hard Proof</Th>
                    <Td>{item.numHardProof}</Td>
                  </Tr>
                  <Tr>
                    <Th>Proof</Th>
                    <Td>
                      <Wrap>
                        <WrapItem>
                          <Icon
                            as={item?.eProof ? FaCheckCircle : FaRegCircle}
                            color={item?.eProof ? 'green' : ''}
                            mr="1"
                          />
                          Electronic{' '}
                        </WrapItem>
                        <WrapItem>
                          <Icon
                            as={item?.hardProof ? FaCheckCircle : FaRegCircle}
                            color={item?.hardProof ? 'green' : ''}
                            mr="1"
                          />
                          Hard
                        </WrapItem>
                        <WrapItem>
                          <Icon
                            as={item?.liveSample ? FaCheckCircle : FaRegCircle}
                            color={item?.liveSample ? 'green' : ''}
                            mr="1"
                          />
                          Live Sample
                        </WrapItem>
                        <WrapItem>
                          <Icon
                            as={item?.pressCheck ? FaCheckCircle : FaRegCircle}
                            color={item?.pressCheck ? 'green' : ''}
                            mr="1"
                          />
                          Press Check
                        </WrapItem>
                      </Wrap>
                    </Td>
                  </Tr>
                  <Tr>
                    <Th>Description</Th>
                    <Td>{item?.description ?? ''}</Td>
                    {/* <Td isNumeric>30.48</Td> */}
                  </Tr>
                  <Tr>
                    <Th>Packaging Instructions</Th>
                    <Td>{item?.packagingInstructions ?? ''}</Td>
                  </Tr>
                  {item?.components?.map((c, i) => (
                    <Tr key={i}>
                      <Th bgColor={'gray.50'} verticalAlign={'top'}>
                        Component {i + 1} Specs
                      </Th>
                      <Td bgColor={'gray.50'}>
                        <Tr>
                          <Th>Description</Th>
                          <Td>{c?.description ?? ''}</Td>
                        </Tr>
                        <Tr>
                          <Th>Notes</Th>
                          <Td>
                            <Text>{c?.notes ?? ''}</Text>
                          </Td>
                        </Tr>
                        <Tr>
                          <Th>Sizes</Th>
                          <Td>
                            <Tr>
                              <Td>
                                <Heading
                                  style={{ textTransform: 'uppercase' }}
                                  color={'gray.600'}
                                  letterSpacing={'wider'}
                                  colorScheme={'gray'}
                                  fontSize={10}
                                >
                                  Flat
                                </Heading>
                                <Text>
                                  {c?.flatSize?.width ?? ''} x {c?.flatSize?.height ?? ''}
                                </Text>
                              </Td>
                              <Td>
                                <Heading
                                  style={{ textTransform: 'uppercase' }}
                                  color={'gray.600'}
                                  letterSpacing={'wider'}
                                  colorScheme={'gray'}
                                  fontSize={10}
                                >
                                  Finished
                                </Heading>
                                <Text>
                                  {c?.finishSize?.width ?? ''} x {c?.finishSize?.height ?? ''}
                                </Text>
                              </Td>
                            </Tr>
                          </Td>
                        </Tr>
                        <Tr>
                          <Th>Ink Specs</Th>
                          <Td>{c?.inkSpecs}</Td>
                        </Tr>
                        {c.attributes
                          ?.sort((a, b) => {
                            if (a.type && b.type) {
                              return a.type > b.type ? 1 : -1;
                            }

                            return 0;
                          })
                          ?.map((a, innerIdx: number) => {
                            const t = attributeFinder(attributeTypes, a);
                            const { name = '', typeName = '' } = t || {};
                            return (
                              <Tr key={innerIdx}>
                                <Th>{a.otherType ?? typeName}</Th>
                                <Td>{a?.otherValue ?? name}</Td>
                              </Tr>
                            );
                          })}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
            <FormControl as="fieldset" my={3}>
              <Checkbox
                isChecked={foundItem?.isOptedOut}
                isDisabled={
                  isReadOnly || responseItems?.length < 2 || foundItem?.quantities?.length < 2
                }
                onChange={(e) =>
                  handleChange({
                    ...foundItem,
                    isOptedOut: e.target.checked,
                    quantities: foundItem?.quantities?.map((qty) => ({
                      ...qty,
                      isOptedOut: e.target.checked,
                    })),
                  })
                }
              >
                Opt-out of item {index + 1}
              </Checkbox>
            </FormControl>
          </Box>

          <Box>
            {isReadOnly || isMenuHidden ? null : (
              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label="Options"
                  icon={<FaEllipsisV />}
                  variant="outline"
                />
                <MenuList>
                  <MenuItem icon={<FaEdit />}>Edit Item</MenuItem>
                  <MenuItem icon={<FaTrash />}>Remove Item</MenuItem>
                </MenuList>
              </Menu>
            )}
          </Box>
        </Box>

        <Box w={{ base: '100%', lg: '50%' }} mb={8}>
          <ItemResponseForm response={foundItem} onChange={handleChange} isReadOnly={isReadOnly} />
        </Box>
      </Stack>
    </>
  );
}
