import * as React from 'react';
import { Route, RouteComponentProps, useLocation } from 'react-router-dom';
import * as ReactGA from 'react-ga';

declare var ENV;

if (ENV === 'production') {
  ReactGA.initialize('UA-32722925-4');
  ReactGA.pageview(window.location.pathname + window.location.search);
}

interface IAnalytics2Props {
}

export const usePageViews = () => {
  const location = useLocation();
  React.useEffect(() => {
    if (ENV === 'production' && location.pathname !== '/lia') {
      ReactGA.pageview(location.pathname + location.search);
    }
  }, [location]);
}
