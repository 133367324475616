import * as React from 'react';
import { Switch, Route } from 'react-router-dom';

import PurchasingReport from './report';

const routes = (props) => (
  <Switch>
    <Route path="/reports/purchasing" component={PurchasingReport} />
  </Switch>
);

export default routes;
