import * as React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, change, FormSection, formValueSelector, FormProps } from 'redux-form';
import { Link } from 'react-router-dom';
import { validateRequired, usStateProvinces } from '../../../common';
import { Form, Checkbox, Radio, Input, Dropdown, Button, Header, Segment } from 'semantic-ui-react';
import { semanticFormField, semanticFormToggleField } from '../semantic-form-field';

import ShipFromAddress from './ShipFromAddress';
import UPSForm from './UPSForm';
import FedexForm from './FedExForm';
import Divider from 'semantic-ui-react/dist/commonjs/elements/Divider/Divider';

const validate = (values) => {
  if (Object.keys(values).length === 0) {
    return;
  }

  return validateRequired(['secretKey', 'publishableKey'])(values);
};

const MainForm: any = (props: FormProps) => {
  const { onSubmit, handleSubmit, pristine, reset, submitting } = props;
  return (
    <Form size={'small'} onSubmit={handleSubmit}>
      <Header as="h5" attached="top">
        Air Cargo
      </Header>
      <Segment attached>
        <Field
          name="isAirCargoEnabled"
          component={semanticFormToggleField}
          as={Form.Checkbox}
          label={'Enable Air Cargo'}
          type="checkbox"
        />

        {props.isAirCargoEnabled ? (
          <Form.Group widths="equal">
            <Field
              name="isAirCargoCombined"
              component={semanticFormToggleField}
              as={Form.Checkbox}
              label={'Combine Shipping for Air Cargo'}
              type="checkbox"
            />

            {props.isAirCargoCombined ? (
              <Field
                name="shippingMaxWeightPerBox"
                component={semanticFormField}
                as={Form.Input}
                label="Maximum Weight Per Box"
                type="input"
              />
            ) : null}
          </Form.Group>
        ) : null}
      </Segment>
      <Header as="h5" attached>
        Combined Shipping
      </Header>
      <Segment attached>
        <Field
          name="isShippingCombined"
          component={semanticFormToggleField}
          as={Form.Checkbox}
          label="Combine Shipping"
          type="checkbox"
        />

        {props.isShippingCombined ? (
          <Form.Group widths="equal">
            <Field
              name="shippingMaxWeightPerBox"
              component={semanticFormField}
              as={Form.Input}
              label="Maximum Weight Per Box"
              type="input"
            />
            <Field
              name="combineThreshold"
              component={semanticFormField}
              as={Form.Input}
              label="Combined Threshold"
              type="input"
            />
          </Form.Group>
        ) : null}
      </Segment>
      <Header as="h5" attached>
        Parcel Carriers
      </Header>
      <Segment attached>
        <FormSection name="shippingStrategy.ups">
          <UPSForm ups={props.ups} />
        </FormSection>
        <Divider />
        <FormSection name="shippingStrategy.fedex">
          <FedexForm fedex={props.fedex} />
        </FormSection>
      </Segment>
      <Header as="h5" attached>
        Will Call
      </Header>
      <Segment attached>
        <div className="two fields">
          <Field
            name="isWillCallEnabled"
            component={semanticFormToggleField}
            as={Form.Checkbox}
            label="Enable Will Call"
            type="checkbox"
          />
        </div>
      </Segment>

      <Header as="h5" attached>
        Ship From Address
      </Header>
      <Segment attached={'bottom'}>
        <FormSection name="shipFromAddress">
          <ShipFromAddress />
        </FormSection>
      </Segment>

      <Form.Button primary>Update</Form.Button>
    </Form>
  );
};

const reduxedForm = reduxForm({
  validate,
})(MainForm);

const mapStateToProps = (state, ownProps: FormProps) => ({
  isShippingCombined: formValueSelector(ownProps.form)(state, 'isShippingCombined'),
  isAirCargoEnabled: formValueSelector(ownProps.form)(state, 'isAirCargoEnabled'),
  isAirCargoCombined: formValueSelector(ownProps.form)(state, 'isAirCargoCombined'),

  ups: formValueSelector(ownProps.form)(state, 'shippingStrategy.ups'),
  fedex: formValueSelector(ownProps.form)(state, 'shippingStrategy.fedex'),
});

export default connect(mapStateToProps)(reduxedForm) as any;
