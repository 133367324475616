import * as React from 'react';
import { Button, Input, Dropdown, Segment, Form } from 'semantic-ui-react';
import { FeathersDropdown } from '@inkcloud/components';
import { usStateProvinces } from '../../common';
import DatePicker from 'react-datepicker';

const { useState, useEffect } = React;

export interface FilterFormProps {
  value: any;
  isSubmitting?: boolean;
  onChange: Function;
  onReset: Function;
  onExport?: Function;
}

function initMapper(value) {
  const defaults = {
    firstName: '',
    lastName: '',
    organizationName: '',
    stateProvince: '',
    postalCode: '',
    email: '',
  };

  return Object.keys(defaults).reduce((p, cur) => {
    if (!p[cur]) {
      p[cur] = defaults[cur];
    }
    return p;
  }, value);
}

export function FilterForm(props: FilterFormProps) {
  const { value: preInitValue, onExport, isSubmitting } = props;

  const value = initMapper(preInitValue);
  const { start, end } = value;

  const onChange = (field, value) => {
    props.onChange && props.onChange({ ...props.value, [field]: value });
  };

  return (
    <Segment>
      <Form size={'small'}>
        <Form.Group widths={'equal'}>
          <Form.Field>
            <Input
              placeholder="First name"
              value={value.firstName}
              onChange={(e, data) => onChange('firstName', data.value)}
              autoComplete={'off'}
            />
          </Form.Field>
          <Form.Field>
            <Input
              placeholder="Last name"
              value={value.lastName}
              onChange={(e, data) => onChange('lastName', data.value)}
              autoComplete={'off'}
            />
          </Form.Field>
          <Form.Field>
            <Input
              placeholder="Company Name"
              value={value.organizationName}
              onChange={(e, data) => onChange('organizationName', data.value)}
              autoComplete={'off'}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group widths={'equal'}>
          <Form.Dropdown
            search
            selection
            clearable
            placeholder="State"
            options={usStateProvinces}
            value={value.stateProvince}
            onChange={(e, data) => onChange('stateProvince', data.value)}
          />
          <Form.Field>
            <Input
              placeholder="Zip"
              value={value.postalCode}
              onChange={(e, data) => onChange('postalCode', data.value)}
              autoComplete={'off'}
            />
          </Form.Field>
          <Form.Field>
            <Input
              placeholder="Email"
              value={value.email}
              onChange={(e, data) => onChange('email', data.value)}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group widths={'equal'}>
          <Form.Field>
            <DatePicker
              dateFormat="MM/dd/yyyy"
              placeholderText="Start"
              selected={start}
              selectsStart
              startDate={start}
              endDate={end}
              onChange={(date) => onChange('start', date)}
            />
          </Form.Field>
          <Form.Field>
            <DatePicker
              dateFormat="MM/dd/yyyy"
              placeholderText="End"
              selected={end}
              selectsEnd
              startDate={start}
              endDate={end}
              onChange={(date) => onChange('end', date)}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group widths={'equal'}>
          <Form.Field>
            <Button size={'tiny'} content="Reset Form" onClick={() => props.onReset()} />
          </Form.Field>
          <Form.Field style={{ textAlign: 'right' }}>
            {start && end && (
              <Button
                type="button"
                primary
                size={'tiny'}
                loading={isSubmitting}
                disabled={isSubmitting}
                content="Export"
                onClick={() => onExport()}
              />
            )}
            <Button
              type="button"
              size={'tiny'}
              loading={isSubmitting}
              disabled={isSubmitting}
              content="Export All"
              onClick={() => onExport('all')}
            />
          </Form.Field>
        </Form.Group>
      </Form>
    </Segment>
  );
}
