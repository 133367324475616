import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Form, Checkbox, Message, Segment } from 'semantic-ui-react';
import { PageHeaderRow } from '@inkcloud/components';

import Feathers from '../../../bootstrap/feathers';
import { GeneralForm } from './GeneralForm';

import { connect } from 'react-redux';

interface DetailProps {}

export interface ConnectedProps {
  user?: any;
}

const { useState, useEffect } = React;

export const PreDetail: React.FunctionComponent<
  DetailProps & ConnectedProps & RouteComponentProps
> = (props) => {
  const { user, history } = props;
  const [general, setGeneral] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const loadGeneral = async () => {
      setIsLoading(true);
      try {
        const res: any = await Feathers.service('tenant-settings/general').get(user.business, {
          query: {},
        });
        setIsLoading(false);
        if (res) {
          setGeneral(res);
        }
      } catch (e) {
        setIsLoading(false);
      }
    };
    loadGeneral();
  }, []);

  const handleSubmit = async (values: any) => {
    setIsLoading(true);

    const payload = {
      ...values,
    };

    if (!values?.customDomain?.enable) {
      delete payload.customDomain;
    }

    try {
      const res: any = await Feathers.service('tenant-settings/general').patch(
        user.business,
        payload,
        { query: {} }
      );
      setIsLoading(false);
      history.push('/settings');
    } catch (e) {
      setIsLoading(false);
      e.code && e.code < 500
        ? setErrorMessage(e.message)
        : setErrorMessage('We are experiencing technical difficulties. Please try again');
    }
  };

  return (
    <>
      <PageHeaderRow header="General" subheader="Settings">
        <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
      </PageHeaderRow>
      {errorMessage && <Message error content={errorMessage} />}
      <GeneralForm
        initialValues={general}
        errorMessage={errorMessage}
        isLoading={isLoading}
        onSubmit={handleSubmit}
      />
    </>
  );
};

const mapStateToProps = (state): ConnectedProps => ({
  user: state.auth.user || {},
});

export const Detail = connect(mapStateToProps)(PreDetail as any);
