import * as React from 'react';
import { Button, Form, Checkbox, Message } from 'semantic-ui-react';

import { To } from '@inkcloud/components';

import Feathers from '../../../bootstrap/feathers';

interface CartFormProps {
  id: string;
  cart: any;
}

const { useState } = React;

export const CartForm: React.FunctionComponent<CartFormProps> = (props) => {
  const { id } = props;
  const [cart, setCart] = useState(props.cart || {});
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (data: any) => setCart({ ...cart, [data.name]: data.checked });

  const handleSubmit = async (value: any) => {
    setIsLoading(true);
    const [error, res] = await To(
      Feathers.service('tenant-settings/cart').patch(id, value, { query: {} })
    );
    setIsLoading(false);
    error && setErrorMessage(error.message);
  };

  return (
    <>
      {errorMessage && <Message error content={errorMessage} />}
      <Form loading={isLoading} onSubmit={() => handleSubmit(cart)}>
        <Form.Field>
          <Checkbox
            name={'allowSavedItems'}
            label={'Allow Saved Items'}
            checked={cart ? cart.allowSavedItems : false}
            onChange={(e, data) => handleChange(data)}
          />
        </Form.Field>

        <Form.Field>
          <Button primary type="submit">
            Save
          </Button>
        </Form.Field>
      </Form>
    </>
  );
};
