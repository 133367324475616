import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Feathers from './../../bootstrap/feathers';
import { PageHeaderRow, To } from '@inkcloud/components';

import { Button, Message, Segment, Header } from 'semantic-ui-react';
import { Formik, Form as FormikForm } from 'formik';
import * as Yup from 'yup';

import { OrderDetails } from './OrderDetailsComponent';
import { ShippingMethods } from './ShippingMethodsComponent';
import { ShipOut } from './ShipOutComponent';

interface AdjusterComponentProps {}

const { useState, useEffect } = React;

const initialState = {
  order: null as any,
  availableShippingMethods: [
    { description: 'Ship Out', key: 'SHIPPING_SHIP_OUT' },
    { description: 'Will Call', key: 'SHIPPING_WILL_CALL' },
  ],
  shippingMethod: '',
  shippingDetails: {
    shipTo: {
      address1: '',
      city: '',
      stateProvince: '',
      postalCode: '',
      countryCode: '',
      name: '',
      careOf: '',
    },
    shipFrom: {
      countryCode: '',
      postalCode: '',
      stateProvince: '',
      city: '',
      address2: '',
      address1: '',
      name: '',
    },
  },
  rateOptions: [],
  dryRun: false,
};

export const AdjusterComponent: React.FunctionComponent<
  AdjusterComponentProps & RouteComponentProps<any>
> = (props) => {
  const {
    match: {
      params: { orderId },
    },
    history,
  } = props;

  const [state, setState] = useState(initialState);
  const [errorMessage, setErrorMessage] = useState({ order: '', address: '', submit: '' });
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingAddress, setIsLoadingAddress] = useState(false);

  useEffect(() => {
    const loadOrder = async () => {
      setErrorMessage({ ...errorMessage, order: '' });
      setIsLoading(true);

      try {
        const res = await Feathers.service('/orders').get(orderId, {
          query: {
            $populate: ['customer', 'invoice'],
          },
        });

        const loadShippingRate = async (res: any) => {
          const payload = {
            orderId,
            tenant: res.business,
            customer: res.customer._id,
            shippingMethod:
              res.shipment && res.shipment.providerKey === 'SHIPPING_WILL_CALL'
                ? 'SHIPPING_WILL_CALL'
                : 'SHIPPING_SHIP_OUT',
            shippingDetails: {
              shipTo: {
                address1: res.shipment && res.shipment.shipToAddress1,
                city: res.shipment && res.shipment.shipToCity,
                stateProvince: res.shipment && res.shipment.shipToStateProvince,
                postalCode: res.shipment && res.shipment.shipToPostalCode,
                countryCode: res.shipment && res.shipment.shipToCountryCode,
                name: res.shipment && res.shipment.shipToName,
                careOf: res.shipment && res.shipment.shipToCareOf,
              },
              shipFrom: {
                countryCode: res.shipment && res.shipment.shipFromCountryCode,
                postalCode: res.shipment && res.shipment.shipFromPostalCode,
                stateProvince: res.shipment && res.shipment.shipFromStateProvince,
                city: res.shipment && res.shipment.shipFromCity,
                address2: res.shipment && res.shipment.shipFromAddress2,
                address1: res.shipment && res.shipment.shipFromAddress1,
                name: res.shipment && res.shipment && res.shipment.shipFromName,
              },
            },
            dryRun: true,
          };

          try {
            const rates = await Feathers.service('/invoices/adjust-shipping/v2').create(payload, {
              query: {},
            });
            setIsLoading(false);
            setState({
              ...state,
              order: res,
              shippingMethod:
                res.shipment && res.shipment.providerKey === 'SHIPPING_WILL_CALL'
                  ? 'SHIPPING_WILL_CALL'
                  : 'SHIPPING_SHIP_OUT',
              shippingDetails: {
                shipTo: {
                  address1: res.shipment && res.shipment.shipToAddress1,
                  city: res.shipment && res.shipment.shipToCity,
                  stateProvince: res.shipment && res.shipment.shipToStateProvince,
                  postalCode: res.shipment && res.shipment.shipToPostalCode,
                  countryCode: res.shipment && res.shipment.shipToCountryCode,
                  name: res.shipment && res.shipment.shipToName,
                  careOf: res.shipment && res.shipment.shipToCareOf,
                },
                shipFrom: {
                  countryCode: res.shipment && res.shipment.shipFromCountryCode,
                  postalCode: res.shipment && res.shipment.shipFromPostalCode,
                  stateProvince: res.shipment && res.shipment.shipFromStateProvince,
                  city: res.shipment && res.shipment.shipFromCity,
                  address2: res.shipment && res.shipment.shipFromAddress2,
                  address1: res.shipment && res.shipment.shipFromAddress1,
                  name: res.shipment && res.shipment.shipFromName,
                },
              },
              rateOptions: rates.rateOptions,
            });
          } catch (e) {
            setState({
              ...initialState,
              order: res,
            });
            setIsLoading(false);
            setErrorMessage({
              ...errorMessage,
              order:
                e.code && e.code < 500
                  ? e.message
                  : 'We are experiencing technical difficulties. Please try again',
            });
          }
        };

        if (res) {
          loadShippingRate(res);
        }
      } catch (e) {
        setIsLoading(false);
        setErrorMessage({
          ...errorMessage,
          order:
            e.code && e.code < 500
              ? e.message
              : 'We are experiencing technical difficulties. Please try again',
        });
      }

      // const [error, res] = await To(Feathers.service('/orders').get(orderId, {
      //   query: {
      //     $populate: ['customer', 'invoice'],
      //   }
      // }))

      // setIsLoading(false)
      // if (!error) {
      //   setState({
      //     ...state,
      //     order: res,
      //     shippingMethod: res.shipment.providerKey === 'SHIPPING_WILL_CALL' ? res.shipment.providerKey : 'SHIPPING_SHIP_OUT',
      //     shippingDetails: {
      //       shipTo: {
      //         ...state.shippingDetails.shipTo,
      //         address1: res.shipment.shipToAddress1,
      //         city: res.shipment.shipToCity,
      //         stateProvince: res.shipment.shipToStateProvince,
      //         postalCode: res.shipment.shipToPostalCode,
      //         name: res.shipment.shipToName,
      //         careOf: res.shipment.shipToCareOf,
      //       },
      //       shipFrom: {
      //         ...state.shippingDetails.shipFrom,
      //         postalCode: res.shipment.shipFromPostalCode,
      //         stateProvince: res.shipment.shipFromStateProvince,
      //         city: res.shipment.shipFromCity,
      //         address2: res.shipment.shipFromAddress2,
      //         address1: res.shipment.shipFromAddress1,
      //         name: res.shipment.shipFromName,
      //       }
      //     }
      //   })

      //   const value = {
      //     shippingMethod: res.shipment.providerKey === 'SHIPPING_WILL_CALL' ? res.shipment.providerKey : 'SHIPPING_SHIP_OUT',
      //     shippingDetails: res.shipment.shipToPostalCode
      //   }

      // } else {
      //   setErrorMessage({ ...errorMessage, order: error.message })
      // }
    };

    if (orderId) {
      loadOrder();
    }
  }, [orderId]);

  const handleSaveAddress = async (value: any) => {
    setIsLoadingAddress(true);
    setErrorMessage({ ...errorMessage, address: '' });

    const payload = {
      orderId,
      tenant: state.order.business,
      customer: state.order.customer._id,
      shippingMethod: value.shippingMethod,
      shippingDetails: value.shippingDetails,
      dryRun: true,
    };

    // const [error, res] = await To(Feathers.service('/invoices/adjust-shipping/v2').create(payload, { query: {} }))

    try {
      const res = await Feathers.service('/invoices/adjust-shipping/v2').create(payload, {
        query: {},
      });
      setState({
        ...state,
        shippingMethod: res.shippingMethod,
        shippingDetails: res.shippingDetails,
        rateOptions: res.rateOptions,
        dryRun: res.dryRun,
      });
      setIsLoadingAddress(false);
    } catch (e) {
      setIsLoadingAddress(false);
      setState({
        ...state,
        rateOptions: [],
        dryRun: false,
      });
      setErrorMessage({
        ...errorMessage,
        address:
          e.code && e.code < 500
            ? e.message
            : 'We are experiencing technical difficulties. Please try again',
      });
    }

    // if (res) {
    //   setState({
    //     ...state,
    //     shippingMethod: res.shippingMethod,
    //     shippingDetails: res.shippingDetails,
    //     rateOptions: res.rateOptions,
    //     dryRun: res.dryRun
    //   })
    // } else {
    //   setState({
    //     ...state,
    //     rateOptions: [],
    //     dryRun: false,
    //   })
    //   setErrorMessage({ ...errorMessage, address: error.message })
    // }
  };

  const handleChangeShipMethod = async (value: any) => {
    setErrorMessage({ ...errorMessage, address: '' });

    if (value === 'SHIPPING_WILL_CALL') {
      const payload = {
        orderId,
        tenant: state.order.business,
        customer: state.order.customer._id,
        shippingMethod: value,
        dryRun: true,
      };

      const [error, res] = await To(
        Feathers.service('/invoices/adjust-shipping/v2').create(payload, { query: {} })
      );

      if (res) {
        setState({
          ...state,
          shippingMethod: res.shippingMethod,
          rateOptions: res.rateOptions,
          dryRun: true,
        });
      } else {
        setState({
          ...state,
          dryRun: false,
        });
        setErrorMessage({
          ...errorMessage,
          address:
            error.code && error.code < 500
              ? error.message
              : 'We are experiencing technical difficulties. Please try again',
        });
      }
    } else {
      setState({
        ...state,
        rateOptions: [],
        dryRun: false,
      });
    }
  };

  const handleSubmitShip = async (value: any) => {
    setErrorMessage({ ...errorMessage, submit: '' });

    const found = value.rateOptions.find(
      (r) => r.serviceKey === value.serviceKey || r.serviceKey === state.order.shipment.serviceKey
    );

    const serviceKey =
      value.serviceKey ||
      state.order?.shipment?.serviceKey ||
      (value.shippingMethod === 'SHIPPING_SHIP_OUT' && value.rateOptions[0].serviceKey);

    const payload = {
      orderId,
      tenant: state.order.business,
      customer: state.order.customer._id,
      shippingMethod: value.shippingMethod,
      shippingDetails: value.shippingDetails,
      serviceKey: serviceKey,
      carrierRate: found ? found.carrierRates : value.rateOptions[0].carrierRates,
      provider: found ? found.carrier : value.rateOptions[0].carrier,
      description: found ? found.description : value.rateOptions[0].description,
      dryRun: false,
    };

    if (value.shippingMethod === 'SHIPPING_WILL_CALL') {
      delete payload.shippingDetails;
      delete payload.serviceKey;
      delete payload.provider;
      delete payload.description;
    }

    const [error, res] = await To(
      Feathers.service('/invoices/adjust-shipping/v2').create(payload, { query: {} })
    );

    if (res) {
      history.goBack();
    } else {
      setErrorMessage({
        ...errorMessage,
        submit:
          error.code && error.code < 500
            ? error.message
            : 'We are experiencing technical difficulties. Please try again',
      });
    }
  };

  const ShippingAdjusterSchema = Yup.object().shape({
    shippingDetails: Yup.object().shape({
      shipTo: Yup.object().shape({
        name: Yup.string().required('Required'),
        address1: Yup.string().required('Required'),
        city: Yup.string().required('Required'),
        stateProvince: Yup.string().required('Required'),
        postalCode: Yup.string().required('Required'),
      }),
      shipFrom: Yup.object().shape({
        name: Yup.string().required('Required'),
        address1: Yup.string().required('Required'),
        city: Yup.string().required('Required'),
        stateProvince: Yup.string().required('Required'),
        postalCode: Yup.string().required('Required'),
      }),
    }),
  });

  return (
    <>
      <PageHeaderRow header={`Shipment Adjuster `} subheader="Order ">
        <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
      </PageHeaderRow>
      <Segment loading={isLoading}>
        {(errorMessage.order || errorMessage.address || errorMessage.submit) && (
          <Message
            error
            content={errorMessage.order || errorMessage.address || errorMessage.submit}
          />
        )}
        <OrderDetails order={state.order} />

        <Formik
          initialValues={state}
          enableReinitialize={true}
          validationSchema={ShippingAdjusterSchema}
          onSubmit={(values, { setSubmitting }) => {
            // handleSubmitShip(values)
          }}
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              dirty,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset,
              setFieldValue,
              setFieldTouched,
            } = props as any;

            return (
              <FormikForm className={`ui form ${isLoading && 'loading'} ${errors && 'error'}`}>
                <Header as="h5" attached="top">
                  Select a shipping method
                </Header>
                <Segment attached="bottom">
                  <ShippingMethods
                    shippingMethods={state.availableShippingMethods}
                    value={values.shippingMethod}
                    onChangeShipMethod={handleChangeShipMethod}
                    setFieldValue={setFieldValue}
                  />
                </Segment>
                {values.shippingMethod === 'SHIPPING_SHIP_OUT' && (
                  <ShipOut
                    values={values}
                    onSaveAddress={handleSaveAddress}
                    setFieldValue={setFieldValue}
                    isLoadingAddress={isLoadingAddress}
                    errors={errors}
                  />
                )}
                <br />

                <Button
                  primary
                  content="Submit"
                  onClick={() => handleSubmitShip(values)}
                  type="submit"
                />
              </FormikForm>
            );
          }}
        </Formik>
      </Segment>
    </>
  );
};
