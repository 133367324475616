import * as React from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Box,
  Center,
  Switch,
  FormControl,
  FormLabel,
  NumberInput,
  Input,
  FormHelperText,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from '@chakra-ui/react';

import ProductLabel from './ProductLabel';

interface IProductLabelModalProps {
  sku: string;
  productName: string;
  isOpen: boolean;
  onClose: () => void;
}

const ProductLabelModal: React.FunctionComponent<IProductLabelModalProps> = (props) => {
  const { sku, productName: productNameProp, isOpen, onClose } = props;

  const [width, setWidth] = React.useState('6');
  const [height, setHeight] = React.useState('2');
  const [fontSize, setFontSize] = React.useState('12');
  const [content, setContent] = React.useState(sku);
  const [showProductName, setShowProductName] = React.useState(true);
  const [productName, setProductName] = React.useState(productNameProp);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size={'full'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Product Label Generator</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              <form>
                <FormControl>
                  <FormLabel>Label Width (inches)</FormLabel>
                  <NumberInput
                    min={0.5}
                    defaultValue={width}
                    precision={2}
                    step={0.2}
                    onChange={(v) => setWidth(v)}
                  >
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </FormControl>
                <FormControl>
                  <FormLabel>Label Height (inches)</FormLabel>
                  <NumberInput
                    min={0.5}
                    defaultValue={height}
                    precision={2}
                    step={0.2}
                    onChange={(v) => setHeight(v)}
                  >
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </FormControl>
                <FormControl>
                  <FormLabel>SKU</FormLabel>
                  <Input type="text" value={content} onChange={(e) => setContent(e.target.value)} />
                </FormControl>

                <FormControl display="flex" alignItems="center">
                  <FormLabel>Show Product Name</FormLabel>
                  <Switch
                    defaultChecked={showProductName}
                    isChecked={showProductName}
                    onChange={(e) => setShowProductName(e.target.checked)}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Product Name</FormLabel>
                  <Input
                    type="text"
                    value={productName}
                    onChange={(e) => setProductName(e.target.value)}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Product Name Font Size</FormLabel>
                  <Input
                    type="text"
                    value={fontSize}
                    onChange={(e) => setFontSize(e.target.value)}
                  />
                </FormControl>
              </form>
            </Box>
            <Box mt={12} p={6} border={'1px solid #ddd'}>
              <Center>
                <ProductLabel
                  size={[Number(width) * 72, Number(height) * 72]}
                  fontSize={Number(fontSize)}
                  content={content}
                  showProductName={showProductName}
                  productName={productName}
                />
              </Center>
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ProductLabelModal;
