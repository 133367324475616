import * as React from 'react';
import {
  TableContainer,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  Link,
  Text,
} from '@chakra-ui/react';
import { InfoIcon } from '@chakra-ui/icons';
import { EmptyState, Button } from '@saas-ui/react';

import type { ModelTypes } from '@inkcloud/icapi-types';
import { FormattedDate } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';

import { SegmentCard } from '../../../../common';

interface IConvertedEntitiesProps {
  data: {
    po?: NonNullable<
      ModelTypes.PurchaseOrder & { type: string; vendor?: { companyName: string } }
    >[];
    so?: NonNullable<
      ModelTypes.SalesOrder & { type: string; customer?: { organizationName: string } }
    >[];
  };

  title: string;
  subTitle: string;
}

const ConvertedEntities: React.FunctionComponent<IConvertedEntitiesProps> = (props) => {
  const { data, title, subTitle } = props;

  const sortedData = [...(data?.po ?? []), ...(data?.so ?? [])].sort(
    (a, b) => new Date(b.createdAt as string).valueOf() - new Date(a.createdAt as string).valueOf()
  );

  return (
    <SegmentCard title={title} subtitle={subTitle}>
      {sortedData.length ? (
        <TableContainer>
          <Table size="sm">
            <Thead>
              <Tr>
                <Th>Date</Th>
                <Th>#</Th>
                <Th>Type</Th>
                <Th>Vendor</Th>
              </Tr>
            </Thead>
            <Tbody>
              {sortedData?.map((p, index) => (
                <Tr key={index}>
                  <Td>{p?.createdAt && <FormattedDate value={p.createdAt} />}</Td>
                  <Td>
                    {p?.humanId ? (
                      <Link
                        as={RouterLink}
                        color="blue.500"
                        to={`/${p.type === 'po' ? 'purchase-orders' : 'orders'}/${p?._id}`}
                      >
                        {p.humanId}
                      </Link>
                    ) : (
                      ''
                    )}
                  </Td>
                  <Td>{p.type === 'po' ? 'PO' : 'Sales Order'}</Td>
                  <Td>{p.type === 'po' ? (p as any)?.vendor?.companyName ?? '' : ''}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      ) : (
        <EmptyState
          colorScheme="primary"
          icon={InfoIcon}
          title="No POs"
          description="You haven't created any POs and Sales Orders"
          actions={
            <>
              {/* <Button label="Create PO" colorScheme="primary" /> */}
              {/* <Button label="Create customer" /> */}
            </>
          }
        />
      )}
    </SegmentCard>
  );
};

export default ConvertedEntities;

// {data?.[type]?.length ? (
//   <TableContainer>
//     <Table size="sm">
//       <Thead>
//         <Tr>
//           <Th>Date</Th>
//           <Th>#</Th>
//           <Th>Vendor</Th>
//         </Tr>
//       </Thead>
//       <Tbody>
//         {data?.[type]?.map((p, index) => (
//           <Tr key={index}>
//             <Td>{p?.createdAt && <FormattedDate value={p.createdAt} />}</Td>
//             <Td>
//               {p?.humanId ? (
//                 <Link
//                   as={RouterLink}
//                   color="blue.500"
//                   to={`/${type === 'po' ? 'purchase-orders' : 'sales-orders'}/${p?._id}`}
//                 >
//                   {p.humanId}
//                 </Link>
//               ) : (
//                 ''
//               )}
//             </Td>
//             <Td>
//               {type === 'po'
//                 ? p?.vendor?.companyName ?? ''
//                 : p?.customer?.organizationName ?? ''}
//             </Td>
//           </Tr>
//         ))}
//       </Tbody>
//     </Table>
//   </TableContainer>
// ) : (
//   <EmptyState
//     colorScheme="primary"
//     icon={InfoIcon}
//     title="No POs"
//     description={`You haven't created any ${type === 'po' ? 'POs' : 'Sales Orders'} `}
//     actions={
//       <>
//         {/* <Button label="Create PO" colorScheme="primary" /> */}
//         {/* <Button label="Create customer" /> */}
//       </>
//     }
//   />
// )}
