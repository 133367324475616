import * as React from 'react';
import { Segment, Button, Message } from 'semantic-ui-react';
import Feathers from './../../../bootstrap/feathers';
import { PageHeaderRow, To } from '@inkcloud/components';

import { connect } from 'react-redux';

const { useState, useEffect } = React;

export interface ConnectedProps {
  user?: any;
}

declare var ENV;

export const PreDetail: React.FunctionComponent = (props: any) => {
  const { user } = props;
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [urlPrefix, setUrlPrefix] = useState({ key: '', domainName: '' });

  useEffect(() => {
    const loadTenant = async () => {
      const [error, res] = await To(Feathers.service('/tenants').get(user.business, { query: {} }));

      if (res) {
        setUrlPrefix({
          key: res.key,
          domainName: res.domain,
        });
      } else {
        setErrorMessage(error.message);
      }
    };

    loadTenant();
  }, [user.business]);

  const handleLiveEdit = async () => {
    setIsLoading(true);
    setErrorMessage('');
    const [error, res] = await To(
      Feathers.service('/store-front/content/pages/edit').find({ query: {} })
    );
    setIsLoading(false);

    if (res) {
      if (ENV === 'development' || ENV === 'staging') {
        window.open(`http://${urlPrefix.key}.localdev.inkcloud9.com:3000${res.url}`, '_blank');
      } else {
        window.open(`https://www.${urlPrefix.domainName}${res.url}`, '_blank');
      }
    } else {
      setErrorMessage(error.message);
    }
  };

  return (
    <>
      <PageHeaderRow header="Page Edit" subheader="Content" subheaderId={''} />
      <Segment textAlign="center" padded="very">
        {errorMessage && <Message error content={errorMessage} style={{ textAlign: 'left' }} />}
        <Button
          loading={isLoading}
          primary
          content="Launch Live Edit Mode"
          onClick={handleLiveEdit}
        />
      </Segment>
    </>
  );
};

const mapStateToProps = (state): ConnectedProps => ({
  user: state.auth.user || {},
});

export const Detail = connect(mapStateToProps)(PreDetail as any);
