import * as React from 'react';
import { Form, Modal, Button, Message } from 'semantic-ui-react';
import Feathers from './../../../bootstrap/feathers';

import { FeathersDropdown } from '@inkcloud/components';

interface ITransferBackorderModalProps {
  isOpen: boolean;
  onClose: () => void;
  product: any;
  history?: any;
  redirect?: string;
}

const { useState } = React;

export const TransferBackorderModal: React.FunctionComponent<ITransferBackorderModalProps> = (
  props
) => {
  const { isOpen, onClose, product, redirect, history } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    submit: '',
  });

  const [selectedProduct, setSelectedProduct] = useState(null);

  const handleClick = async () => {
    setIsSubmitting(true);
    setErrorMessage({
      ...errorMessage,
      submit: '',
    });

    try {
      let backorderMovementIds = [];

      const res = await Feathers.service('/inventory/movements').find({
        query: {
          $sort: { name: 1 },
          $np: 1,
          operation: 'BACK_ORDER',
          isVoid: { $ne: true },
          product: product?._id,
        },
      });

      backorderMovementIds = res?.map((r) => r._id);

      await Feathers.service('/inventory/operations').create({
        operation: 'TRANSFER_BACKORDER',
        details: {
          productId: selectedProduct,
          backorderMovementIds,
        },
      });

      onClose();
    } catch (e) {
      setErrorMessage({
        ...errorMessage,
        submit:
          e?.code < 500
            ? e.message
            : 'We are experiencing technical difficulties. Please try again',
      });
    }

    setIsSubmitting(false);
  };

  return (
    <Modal size="mini" open={isOpen} onClose={onClose} closeIcon>
      <Modal.Header>
        {product?.staticName ?? ''} {product?.key ? ` (${product.key})` : ''}
      </Modal.Header>
      <Modal.Content>
        <Form.Field>
          <FeathersDropdown
            search
            selection
            clearable
            fluid
            placeholder="Product..."
            serviceName="/products-legacy"
            query={{
              $np: 1,
              $sort: { staticName: 1 },
              $select: ['staticName', 'key'],
            }}
            resultMapper={(r) => ({
              key: r._id,
              value: r._id,
              text: `${r?.staticName ?? ''} ${r.staticName && r.key ? ' - ' : ''} ${r?.key ?? ''}`,
            })}
            onChange={(e, data) => setSelectedProduct(data?.value)}
          />
        </Form.Field>
        {errorMessage.submit && <Message error content={errorMessage.submit} />}
      </Modal.Content>
      <Modal.Actions>
        <Button
          primary
          disabled={isSubmitting}
          loading={isSubmitting}
          onClick={handleClick}
          content="Submit"
        />
        <Button onClick={onClose} content="Cancel" />
      </Modal.Actions>
    </Modal>
  );
};
