import { Ability } from '@casl/ability';

const data: any[] = [
  {
    actions: ['manage'],
    subject: 'all',
  },
];

export const ability = new Ability(data);

export function rolesToPermissionsList(roles: any[] = []) {
  /*
    Since these roles are just being introduced, we'll treat a user having none the same as an administrator.
    Once the tenants have had enough time to setup roles and assign them to users, this logic can be removed.
  */
  if (roles.length === 0) {
    return [{ subject: 'all', action: 'manage' }];
  }
  // End

  return roles.reduce(
    (acc, p) => acc.concat(p.permissions.map((p) => ({ subject: p.resource, action: p.action }))),
    []
  );
}
