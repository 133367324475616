import * as React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Attributes from './attributes';
import Categories from './categories';
import Products from './products';
import MassUpdater from './mass-updater';
import GridEditorPrice from './grid-editor-price';
import Inventory from './inventory';
import InventoryLocations from './inventory/locations';
import InventoryIncomingTickets from './inventory/incoming-tickets';
import StockProducts from './products/stock-product';
import VariantTypes from './variant-types';
import VariantValues from './variant-values';

import AddOnPricing from './add-on-pricing';
import FulfilmentStagingLocations from './fulfillment-staging-locations';

import OrderLimitTester from './products/OrderLimitTester';

const routes = (props) => (
  <Switch>
    <Route path="/products/attributes" component={Attributes} />
    <Route path="/products/categories" component={Categories} />
    <Route path="/products/grid-editor-price" component={GridEditorPrice} />
    <Route path="/products/mass-updater" component={MassUpdater} />
    <Route path="/products/products" component={Products} />
    {/* <Redirect from="/products/stock-products" to={'/products/products'} /> */}
    <Route path="/products/stock-products" render={StockProducts} />
    <Route path="/products/variant-types" component={VariantTypes} />

    <Route path="/products/variant-values" component={VariantValues} />

    <Route path="/products/add-on-pricing" component={AddOnPricing} />
    <Route path="/products/fulfillment-staging-locations" component={FulfilmentStagingLocations} />

    <Route path="/products/order-limit-tester" component={OrderLimitTester} />

    <Route path="/products/inventory" component={Inventory} />
    <Route path="/products/inventory/locations" component={InventoryLocations} />
    <Route path="/products/inventory/incoming-tickets" component={InventoryIncomingTickets} />
    <Route exact path="/products/:productId/inventory-ledger" component={Inventory} />
  </Switch>
);

export default routes;
