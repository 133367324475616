import * as React from 'react';
import { connect } from 'react-redux';
import {
  InjectedFormProps,
  Field,
  FieldArray,
  reduxForm,
  change,
  formValueSelector,
  FormProps,
} from 'redux-form';
import { Link } from 'react-router-dom';
import { slugify } from 'underscore.string';
import Feathers from './../../bootstrap/feathers';
import { services } from './../../bootstrap/feathers';
import { EntityDropdownWrapper2 } from './../EntityDropdown';
import { semanticFormDropdownField, validateRequired } from './../../common';

const validate = validateRequired(['name', 'key']);

type ProductFilterProps = {} & InjectedFormProps;

interface ProductFilterState {
  categoryServiceParams: any;
  attributeComponents: any;
}

const categoriesDropdown = EntityDropdownWrapper2('/products-legacy/categories', null, {
  resultMap: (row) => ({ value: row._id, text: row.name }),
  searchMap: (qs) => ({ name: { $LIKE: qs } }),
});

class ProductFilterComponent extends React.Component<ProductFilterProps, ProductFilterState> {
  constructor(props) {
    super(props);
    this.state = {
      categoryServiceParams: { query: { $sort: { name: 1 }, isDisabled: { $ne: true } } },
      attributeComponents: [],
    };
  }

  componentDidMount() {
    Feathers.service<any>('products-legacy/attribute-types')
      .find({
        query: {
          _id: { $ne: 'QUANTITY' },
        },
      })
      .then((result: any) => {
        const attributeComponents = result.data.map((attributeType) => {
          return {
            component: EntityDropdownWrapper2(
              '/products-legacy/attributes',
              {
                query: {
                  entityAttribute: attributeType._id,
                },
              },
              {
                resultMap: (row) => ({ value: row._id, text: row.name }),
                searchMap: (qs) => ({ name: { $LIKE: qs } }),
              }
            ),
            label: attributeType.name,
            placeholder: attributeType.name,
            id: attributeType._id,
          };
        });

        this.setState({ ...this.state, attributeComponents });
      });
  }

  render() {
    const { pristine, submitting } = this.props;

    const attributeSelectors = this.state.attributeComponents.map((attributeComponent) => {
      return (
        <div className="five wide column" key={attributeComponent.id}>
          {/* <Field
            name='category'
            component={semanticFormDropdownField}
            as={categoriesDropdown}

            multiple={true}
            label='Category'
            placeholder='Types...'
            serviceParams={this.state.categoryServiceParams}
          /> */}

          <Field
            name={`attributes[${attributeComponent.id}]`}
            component={semanticFormDropdownField}
            as={attributeComponent.component}
            multiple={true}
            label={attributeComponent.label}
            placeholder={attributeComponent.label}
          />
        </div>
      );
    });

    const attributeSelectorsRender = () => attributeSelectors;

    return (
      <form className="ui segment top attached tiny form" onSubmit={this.props.handleSubmit}>
        <Field
          name="category"
          component={semanticFormDropdownField}
          as={categoriesDropdown}
          multiple={true}
          label="Category"
          placeholder="Types..."
          serviceParams={this.state.categoryServiceParams}
        />

        <div className="ui grid">{attributeSelectors}</div>
        <Link className="ui button" to="/settings/portals/">
          Back
        </Link>
        <button className="ui button primary" disabled={pristine || submitting} type="submit">
          Submit
        </button>
      </form>
    );
  }
}

const formName = 'productFilterForm';
const reduxedForm: any = reduxForm({
  form: formName,
  enableReinitialize: true,
  validate,
})(ProductFilterComponent);

const mapDispatchToProps = (dispatch) => ({
  changeFieldValue: (field, value) => dispatch(change(formName, field, value)),
});
export default connect(null, mapDispatchToProps)(reduxedForm) as any;
