import * as React from 'react';
import { Modal, Form, Button, TextArea, Input, Message } from 'semantic-ui-react';
import { Feathers } from '../../bootstrap/feathers/feathers';

interface IEmailModalProps {
  purchaseOrderId: string;
  onClose: () => void;
  onReload?: () => void;
}

export const EmailModal = (props: IEmailModalProps) => {
  const { purchaseOrderId, onClose, onReload } = props;

  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    setErrorMessage('');

    try {
      const payload = {
        to: {
          email,
          name,
        },
        message,
        purchaseOrderId,
      };

      const res: any = await Feathers.service('/purchase-orders/emails').create(payload);
      onReload?.();
      onClose();
    } catch (e) {
      const errMsg = e?.code < 500 ? e.message : 'Something went wrong. Please try again later.';
      setErrorMessage(errMsg);
    }
    setIsSubmitting(false);
  };

  return (
    <Modal size="tiny" open={true} onClose={onClose} closeIcon>
      <Modal.Header>Email Purchase Order</Modal.Header>
      <Modal.Content className={`ui form ${errorMessage ? 'error' : ''}`}>
        <Form.Field>
          <label>Name</label>
          <Input onChange={(e, data) => setName((data as any).value)} />
        </Form.Field>
        <Form.Field>
          <label>Email</label>
          <Input onChange={(e, data) => setEmail((data as any).value)} />
        </Form.Field>

        <Form.Field>
          <label>Message</label>
          <TextArea onChange={(e, data) => setMessage((data as any).value)} />
        </Form.Field>
        {errorMessage && <Message error>{errorMessage}</Message>}
        <Button primary onClick={handleSubmit} loading={isSubmitting}>
          Send Email
        </Button>
      </Modal.Content>
    </Modal>
  );
};
