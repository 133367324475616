import * as React from 'react';
import { Button, Segment, Popup, Label } from 'semantic-ui-react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { FormattedDate, FormattedTime } from 'react-intl';

import {
  useList,
  ListPage,
  basicQueryMap,
  generateOperatorOptions,
  Operator,
} from '@inkcloud/components';

const { useState } = React;

interface ListProps {}

const serviceName = '/late-job-detectors';
const pathPrefix = '/late-job-detectors';

const filterQueryMap = (values) => basicQueryMap(values, {});

export async function getFilterDefinition() {
  const definition = [
    {
      label: 'Name',
      value: 'name',
      operators: generateOperatorOptions([Operator.Like]),
    },
  ];

  return definition.sort((a: any, b: any) => a.label.localeCompare(b.label));
}

export const List: React.FunctionComponent<ListProps & RouteComponentProps<any>> = (props) => {
  const {
    match: { params },
    location,
    history,
  } = props;

  const tableDefinition = [
    {
      key: 'createdAt',
      label: 'Date',
      sortable: true,
      cell: (v) =>
        v && (
          <Popup
            size="mini"
            content={<FormattedTime value={v} />}
            trigger={
              <div>
                <FormattedDate value={v} />
              </div>
            }
          />
        ),
    },
    {
      key: 'name',
      label: 'Name',
      sortable: true,
      cell: (v, r) => v,
    },
    {
      key: 'status',
      label: 'Status',
      sortable: true,
      cell: (v, r) => <Label content={r.isEnabled ? 'Active' : 'Inactive'} />,
    },
    {
      key: '',
      label: '',
      sortable: false,
      cell: (v, r) => (
        <Popup
          size="mini"
          content="Edit"
          trigger={
            <Link to={`${pathPrefix}/${r._id}`}>
              <Button size={'mini'} icon={'edit'} />
            </Link>
          }
        />
      ),
      textAlign: 'right',
    },
  ];

  const useListValues = useList({
    feathers: ['find', serviceName, [{ query: { $populate: ['orderItem'] } }]],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  const headerActions = (
    <>
      <Link to={`${pathPrefix}/add`}>
        <Button content="New" primary size={'mini'} />
      </Link>
    </>
  );

  return (
    <div>
      <ListPage
        header={'Late Job Detector'}
        subheader="Order"
        useListValues={useListValues}
        history={history}
        pathPrefix={pathPrefix}
        tableDefintion={tableDefinition}
        withCheckboxes={true}
        headerActions={headerActions}
        getFilterDefinition={getFilterDefinition}
      />
    </div>
  );
};
