import * as React from 'react';
import { Message } from 'semantic-ui-react';

export interface SizeCustomTrimProps {
  checked: boolean;
  width: number;
  height: number;
  selectedSize: any;
  onChange: (values: any) => void;
}

export interface SizeCustomTrimState {}

export class SizeCustomTrim extends React.Component<SizeCustomTrimProps, SizeCustomTrimState> {
  constructor(props: SizeCustomTrimProps) {
    super(props);

    this.state = {
      width: props.width,
      height: props.height,
    };
  }

  componentDidUpdate(prevProps: SizeCustomTrimProps) {
    if (
      prevProps.checked !== this.props.checked ||
      prevProps.width !== this.props.width ||
      prevProps.height !== this.props.height
    ) {
      this.setState({
        ...this.state,
        width: this.props.width,
        height: this.props.height,
      });
    }
  }

  handleChangeWidth = (e) =>
    this.setState({ width: e.target.value }, () =>
      this.props.onChange({ ...this.state, checked: this.props.checked })
    );
  handleChangeHeight = (e) =>
    this.setState({ height: e.target.value }, () =>
      this.props.onChange({ ...this.state, checked: this.props.checked })
    );
  handleCheckChange = (e) => this.props.onChange({ ...this.state, checked: e.target.checked });

  roundToNearestEigth = (val) => {
    if (parseFloat(val) % 0.125 != 0) {
      var m = Math.floor(parseFloat(val) / 0.125);
      return 0.125 * m;
    }

    return val;
  };

  forceIntoSizeBounds(val, dimField, selectedSize) {
    if (val > selectedSize[dimField]) {
      return selectedSize[dimField];
    } else if (val < 1.5) {
      return 1.5;
    }

    return val;
  }

  handleWidthBlur = (e) => {
    let val = e.target.value;
    let roundedVal = this.roundToNearestEigth(val);
    roundedVal = this.forceIntoSizeBounds(roundedVal, 'width', this.props.selectedSize);
    this.setState({ ...this.state, width: roundedVal }, () =>
      this.props.onChange({ ...this.state, checked: this.props.checked })
    );
  };

  handleHeightBlur = (e) => {
    let val = e.target.value;
    let roundedVal = this.roundToNearestEigth(val);
    roundedVal = this.forceIntoSizeBounds(roundedVal, 'height', this.props.selectedSize);
    this.setState({ ...this.state, height: roundedVal }, () =>
      this.props.onChange({ ...this.state, checked: this.props.checked })
    );
  };

  public render() {
    return (
      <div style={{ paddingTop: 4 }}>
        <input
          type="checkbox"
          checked={this.props.checked}
          onChange={this.handleCheckChange}
          value={''}
        />
        <label style={{ paddingLeft: 5 }}>Custom Size</label>
        {this.props.checked && (
          <React.Fragment>
            <div className="ui input" style={{ paddingTop: 12 }}>
              <div>
                <div className="ui input">
                  <input
                    type="input"
                    name="width"
                    value={this.props.width}
                    onChange={this.handleChangeWidth}
                    onBlur={this.handleWidthBlur}
                  />
                </div>
                {!Number(this.props.width) && (
                  <Message error size="tiny" content="Please enter a valid numeric value" />
                )}
              </div>
              <label style={{ paddingLeft: 5, paddingRight: 5, paddingTop: 7 }}>X</label>
              <div>
                <div className="ui input">
                  <input
                    type="input"
                    name="height"
                    value={this.props.height}
                    onChange={this.handleChangeHeight}
                    onBlur={this.handleHeightBlur}
                  />
                </div>
                {!Number(this.props.height) && (
                  <Message error size="tiny" content="Please enter a valid numeric value" />
                )}
              </div>
            </div>

            <Message
              size="tiny"
              content="Please select next larger size to add custom dimensions"
            />
          </React.Fragment>
        )}
      </div>
    );
  }
}
