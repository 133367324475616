import * as React from 'react';

import {
  Button,
  Segment,
  Grid,
  Input,
  Message,
  Divider,
  Form,
  Checkbox,
  Dropdown,
} from 'semantic-ui-react';
import Feathers from './../../../bootstrap/feathers';

import * as Cleave from 'cleave.js/react';

import { MessageModal } from './MessageModal';
import { InfoTable } from './InfoTable';
import { ShippingRates } from './ShippingRates';
import { MessageThread } from './MessageThread';
import { Items } from './Items';

export interface EditProps {
  id: string;
}

const serviceName = '/custom-quotes';
const commonParams = {
  query: {
    $populate: [
      'items.miscUploads',
      'items.messageThread.attachments',
      'messageThread.attachments',
      'customer',
      'messageThread.author.user',
    ],
  },
};

// style
const messageWrapperStyle = {
  minHeight: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
};

const { useState, useEffect } = React;

export const Edit: React.FunctionComponent<EditProps> = (props) => {
  const { id } = props;

  const [data, setData] = useState(null);
  const [isReload, setIsReload] = useState(true);

  const [addOns, setAddOns] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [itemId, setItemId] = useState('');

  const [isLoading, setIsLoading] = useState({
    salesTax: false,
    rates: false,
    service: false,
    estimate: false,
    finalize: false,
  });

  const [excluded, setExcluded] = useState([]);

  const [shipMethod, setShipMethod] = useState('');
  const [shipping, setShipping] = useState(0);
  const [shippingService, setShippingService] = useState('');
  const [salesTax, setSalesTax] = useState(0);
  const [po, setPO] = useState('');
  const [initialStatus, setInitialStatus] = useState('open');
  const [canCheckoutWithoutPayment, setCanCheckoutWithoutPayment] = useState(false);

  const [errorMessage, setErrorMessage] = useState({
    rates: '',
    estimate: '',
    salesTax: '',
    service: '',
    finalize: '',
  });
  const [responseMessage, setResponseMessage] = useState({
    rates: '',
    estimate: '',
    salesTax: '',
    service: '',
    finalize: '',
  });

  useEffect(() => {
    const loadCustomQuote = async () => {
      setIsReload(false);
      try {
        const res = await Feathers.service(serviceName).get(id, commonParams);

        if (res) {
          setData(res);
          setPO(res?.po ?? '');
          setSalesTax(res.salesTax);
          setShippingService(res.selectedShippingService);
          setCanCheckoutWithoutPayment(res?.canCheckoutWithoutPayment);
          setInitialStatus(res?.status);

          if (!res.shippingOverriden) {
            const found = res.availableShippingRates.find(
              (ship) => ship.serviceKey === res.selectedShippingService
            );
            if (found) {
              setShipping(found.amount);
            }
          } else {
            setShipping(res.shipping);
          }
        }
      } catch (error) {}
    };

    if (isReload) {
      loadCustomQuote();
    }
  }, [isReload]);

  useEffect(() => {
    const loadAddOns = async () => {
      try {
        const res = await Feathers.service('/products-legacy/add-ons-base').find({
          query: {
            $sort: { name: 1 },
          },
        });
        setAddOns(res);
      } catch (error) {}
    };

    loadAddOns();
  }, []);

  const handleToggleModal = (id: string) => {
    setItemId(id);
    setIsOpenModal(!isOpenModal);
  };

  const handleChange = (value: any, idx: number) => {
    if (data) {
      const newItems =
        data.items?.map((item: any, ind: number) => {
          if (idx === ind) {
            return { ...value };
          } else {
            return item;
          }
        }) ?? [];
      setData({ ...data, items: newItems });
    }
  };

  const handleChangeExcluded = (data: any) => {
    const { checked, value } = data;

    if (checked) {
      setExcluded([...excluded, value]);
    } else {
      const newExcluded = excluded.filter((e) => e !== value);
      setExcluded(newExcluded);
    }
  };

  const handleGetRate = async () => {
    setIsLoading({ ...isLoading, rates: true });
    setErrorMessage({ ...errorMessage, rates: '', estimate: '' });
    try {
      const res = await Feathers.service('/custom-quotes/set-shipping-rates').create({
        customQuoteId: data._id,
      });
      setIsReload(true);
      setIsLoading({ ...isLoading, rates: false });
    } catch (e) {
      setIsLoading({ ...isLoading, rates: false });
      let msg;

      if (e.code && e.code < 500) {
        msg = e.message;
      } else {
        msg = 'We are experiencing technical difficulties. Please try again';
      }
      setErrorMessage({ ...errorMessage, rates: msg });
    }
  };

  const handleFinalize = async () => {
    setIsLoading({ ...isLoading, finalize: true });
    setResponseMessage({ ...responseMessage, finalize: '' });
    setErrorMessage({ ...errorMessage, rates: '', estimate: '', finalize: '' });
    try {
      const res = await Feathers.service('/custom-quotes/mark-final').create({
        customQuoteId: data._id,
      });
      setIsReload(true);
      setIsLoading({ ...isLoading, finalize: false });
      setResponseMessage({ ...responseMessage, finalize: 'Finalized' });
    } catch (e) {
      let msg;
      setIsLoading({ ...isLoading, finalize: false });

      if (e.code && e.code < 500) {
        msg = e.message;
      } else {
        msg = 'We are experiencing technical difficulties. Please try again';
      }
      setErrorMessage({ ...errorMessage, finalize: msg });
    }
  };

  const handleChangeRate = (v: string) => {
    setShippingService(v);

    const found = data.availableShippingRates.find((ship) => ship.serviceKey === v);

    if (found) {
      setShipping(found.amount);
    }
  };

  const handleEstimate = async () => {
    setIsLoading({ ...isLoading, estimate: true });
    setErrorMessage({ ...errorMessage, rates: '', estimate: '' });
    let mappedItems = [];
    if (data) {
      mappedItems =
        data.items?.reduce((acc, cur) => {
          const found = excluded.find((e) => e === cur._id);

          if (!found) {
            acc.push({
              ...cur,
              itemId: cur._id,
              price: parseFloat(cur.price),
              weight: parseFloat(cur.weight),
              customerReferenceName: cur.customerReferenceName,
            });
          }
          return acc;
        }, []) ?? [];
    }

    try {
      const service = Feathers.service('/custom-quotes');
      const commonParams = { query: {} };

      const res: any = await service.patch(
        data._id,
        {
          shipping,
          salesTax,
          po,
          canCheckoutWithoutPayment,
          items: mappedItems,
        },
        commonParams
      );

      // const test = await Feathers.service('/custom-quotes/set-estimate-values').create({
      //   customQuoteId: data._id,
      //   shipping,
      //   salesTax,
      //   po,
      //   canCheckoutWithoutPayment,
      //   items: mappedItems
      // })

      if (res) {
        setIsReload(true);
        setIsLoading({ ...isLoading, estimate: false });
        if (!res.shippingOverriden) {
          const found = res.availableShippingRates.find(
            (ship) => ship.serviceKey === res.selectedShippingService
          );
          if (found) {
            setShipping(found.amount);
          }
        } else {
          setShipping(res.shipping);
        }
      }
    } catch (e) {
      let msg;
      setIsLoading({ ...isLoading, estimate: false });
      if (e.code && e.code < 500) {
        msg = e.message;
      } else {
        msg = 'We are experiencing technical difficulties. Please try again';
      }
      setErrorMessage({ ...errorMessage, estimate: msg });
    }
  };

  const handleSalesTax = async () => {
    setErrorMessage({ ...errorMessage, salesTax: '' });
    setIsLoading({ ...isLoading, salesTax: true });
    try {
      const res = await Feathers.service('/custom-quotes/set-sales-tax').create({
        customQuoteId: data._id,
        salesTax,
      });

      if (res) {
        setIsLoading({ ...isLoading, salesTax: false });
        // setData({ ...data, availableShippingRates: res.availableShippingRates })
        // setSalesTax(res.salesTax)
        // setShippingService(res.selectedShippingService)

        // const found = res.availableShippingRates.find(ship => ship.serviceKey === res.selectedShippingService)
        // if (found) {
        //   setShipping(found.amount)
        // }
      }
    } catch (e) {
      let msg;
      setIsLoading({ ...isLoading, salesTax: false });
      if (e.code && e.code < 500) {
        msg = e.message;
      } else {
        msg = 'We are experiencing technical difficulties. Please try again';
      }
      setErrorMessage({ ...errorMessage, salesTax: msg });
    }
  };
  const handleShipService = async () => {
    setErrorMessage({ ...errorMessage, service: '' });
    setIsLoading({ ...isLoading, service: true });
    try {
      const res = await Feathers.service('/custom-quotes/set-ship-service').create({
        customQuoteId: data._id,
        shippingService,
        shipping,
      });

      if (res) {
        setIsLoading({ ...isLoading, service: false });
      }
    } catch (e) {
      let msg;
      setIsLoading({ ...isLoading, service: false });
      if (e.code && e.code < 500) {
        msg = e.message;
      } else {
        msg = 'We are experiencing technical difficulties. Please try again';
      }
      setErrorMessage({ ...errorMessage, service: msg });
    }
  };

  if (!data) {
    return null;
  }

  return (
    <>
      <Segment>
        <InfoTable shipMethod={shipMethod} data={data} />
      </Segment>

      <Grid>
        <Grid.Column width={10}>
          <Segment style={messageWrapperStyle}>
            <div style={{ maxHeight: '300px', marginBottom: '1em', overflowY: 'auto' }}>
              <MessageThread messageThread={data.messageThread} customer={data.customer} />
            </div>
            <div>
              <Button
                size="mini"
                primary
                onClick={() => handleToggleModal('')}
                content="Send Message"
              />
            </div>
          </Segment>
        </Grid.Column>
        <Grid.Column width={6}>
          <Segment>
            {
              <ShippingRates
                shippingService={shippingService}
                onChangeRate={handleChangeRate}
                rates={data.availableShippingRates}
              />
            }
            {data.selectedShippingMethod !== 'SHIPPING_WILL_CALL' && (
              <>
                <div className="ui form tiny">
                  <div className="equal width fields inline">
                    <div className="four wide field">
                      <label>Shipping </label>
                    </div>
                    <div className="six wide field">
                      <div className="ui input">
                        <Cleave
                          options={{
                            numeral: true,
                            rawValueTrimPrefix: true,
                            numeralPositiveOnly: true,
                            numeralThousandsGroupStyle: 'thousand',
                            prefix: '$',
                          }}
                          value={shipping}
                          onChange={(e) => setShipping(e.target.rawValue)}
                        />
                      </div>
                    </div>
                    <div className="six wide field">
                      <Button
                        size="tiny"
                        style={{ marginLeft: '.5em' }}
                        loading={isLoading.service}
                        disabled={isLoading.service}
                        content="Set Ship Service"
                        onClick={handleShipService}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="ui form tiny">
              <div className="fields inline">
                <div className="four wide field">
                  <label>Sales Tax </label>
                </div>
                <div className="six wide field">
                  <div className="ui input">
                    <Cleave
                      options={{
                        numeral: true,
                        rawValueTrimPrefix: true,
                        numeralPositiveOnly: true,
                        numeralThousandsGroupStyle: 'thousand',
                        prefix: '$',
                      }}
                      value={salesTax}
                      onChange={(e) => setSalesTax(e.target.rawValue)}
                    />{' '}
                  </div>
                </div>
                <div className="six wide field">
                  <Button
                    size="tiny"
                    style={{ marginLeft: '.5em' }}
                    loading={isLoading.salesTax}
                    disabled={isLoading.salesTax}
                    content="Set Sales Tax"
                    onClick={handleSalesTax}
                  />
                </div>
              </div>
            </div>
            <Form.Group className="ui form tiny">
              <Form.Group inline>
                <Form.Field width={4}>
                  <label>PO</label>
                </Form.Field>
                <Form.Field width={12}>
                  <Input value={po} onChange={(e, data) => setPO(data.value)} />
                </Form.Field>
              </Form.Group>
            </Form.Group>
            <Form.Group className="ui form tiny">
              <Form.Field inline>
                <Checkbox
                  label="Can Checkout without Payment"
                  checked={canCheckoutWithoutPayment}
                  onChange={(e, data) => setCanCheckoutWithoutPayment(data.checked)}
                />
              </Form.Field>
            </Form.Group>
            <br />
            {(errorMessage.rates || errorMessage.estimate || errorMessage.finalize) && (
              <Message
                error
                content={errorMessage.rates || errorMessage.estimate || errorMessage.finalize}
              />
            )}
            {responseMessage.finalize && <Message success content={responseMessage.finalize} />}
            <Button
              size="tiny"
              content="Get Rates"
              disabled={
                (data.selectedShippingMethod !== 'SHIPPING_SHIP_OUT' &&
                  data.selectedShippingMethod !== 'SHIPPING_SHIPOUT_AND_DIGITAL_DELIVERY') ||
                data.status === 'final'
              }
              loading={isLoading.rates}
              onClick={handleGetRate}
            />
            <Button
              size="tiny"
              primary
              disabled={isLoading.estimate || data.status === 'final'}
              loading={isLoading.estimate}
              content="Save Draft"
              onClick={handleEstimate}
            />
            <Button
              size="tiny"
              primary
              disabled={
                isLoading.finalize ||
                data.total === undefined ||
                data.total === 0 ||
                data.status === 'final'
              }
              loading={isLoading.finalize}
              content="Finalize Response"
              onClick={handleFinalize}
            />
          </Segment>
        </Grid.Column>
      </Grid>
      <Divider hidden />
      <Items
        data={data}
        id={id}
        status={data.status}
        onReload={() => setIsReload(true)}
        addOns={addOns}
        onChange={handleChange}
        onChangeExcluded={handleChangeExcluded}
      />

      {isOpenModal && (
        <MessageModal
          isOpen={isOpenModal}
          onReload={() => setIsReload(true)}
          id={id}
          onClose={() => handleToggleModal('')}
          itemId={itemId}
        />
      )}
    </>
  );
};
