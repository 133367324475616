import * as React from 'react';
import { Link } from 'react-router-dom';
import { humanize } from 'underscore.string';
import { FormattedDate } from 'react-intl';
import { Grid, Table, Label } from 'semantic-ui-react';

import { ShippingInfo } from './ShippingInfo';

interface InfoTableProps {
  data: any;
  shipMethod: string;
}

export const InfoTable: React.FunctionComponent<InfoTableProps> = (props) => {
  const { data, shipMethod } = props;

  let name;
  if (data && data.customer) {
    name = `${data.customer.primaryContact?.firstName ?? ''} ${
      data.customer.primaryContact?.lastName ?? ''
    }`;
  }

  return (
    <Grid columns="equal">
      <Grid.Column>
        <Table basic="very" size="small">
          <Table.Body>
            {data.humanId && (
              <Table.Row verticalAlign="top">
                <Table.Cell collapsing style={{ border: 0, paddingTop: 0 }}>
                  {' '}
                  <strong>ID</strong>{' '}
                </Table.Cell>
                <Table.Cell style={{ border: 0, paddingTop: 0 }}>{data.humanId}</Table.Cell>
              </Table.Row>
            )}
            <Table.Row verticalAlign="top">
              <Table.Cell collapsing style={{ border: 0, paddingTop: 0 }}>
                {' '}
                <strong>Status</strong>{' '}
              </Table.Cell>
              <Table.Cell style={{ border: 0, paddingTop: 0 }}>
                <Label
                  size="small"
                  color={data.status === 'final' ? 'green' : 'orange'}
                  content={humanize(data.status)}
                />
              </Table.Cell>
            </Table.Row>
            {data.createdAt && (
              <Table.Row verticalAlign="top">
                <Table.Cell collapsing style={{ border: 0, paddingTop: 0 }}>
                  {' '}
                  <strong>Date</strong>{' '}
                </Table.Cell>
                <Table.Cell style={{ border: 0, paddingTop: 0 }}>
                  <FormattedDate value={data.createdAt} />
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </Grid.Column>
      <Grid.Column>
        <Table basic="very" size="small">
          <Table.Body>
            {data.customer?.organizationName && (
              <Table.Row verticalAlign="top">
                <Table.Cell collapsing style={{ border: 0, paddingTop: 0 }}>
                  <strong>Company</strong>
                </Table.Cell>
                <Table.Cell style={{ border: 0, paddingTop: 0 }}>
                  {data.customer.organizationName}
                </Table.Cell>
              </Table.Row>
            )}
            {name && (
              <Table.Row verticalAlign="top">
                <Table.Cell collapsing style={{ border: 0, paddingTop: 0 }}>
                  <strong>Contact</strong>
                </Table.Cell>
                <Table.Cell style={{ border: 0, paddingTop: 0 }}>
                  <Link to={`/customers/${data.customer._id}`}>{name}</Link>
                </Table.Cell>
              </Table.Row>
            )}
            {data.customer?.email && (
              <Table.Row verticalAlign="top">
                <Table.Cell collapsing style={{ border: 0, paddingTop: 0 }}>
                  <strong>Email</strong>
                </Table.Cell>
                <Table.Cell style={{ border: 0, paddingTop: 0 }}>
                  <a href={`mailto:${data.customer.email}`}>{data.customer.email}</a>
                </Table.Cell>
              </Table.Row>
            )}
            {data.customer?.phone && (
              <Table.Row verticalAlign="top">
                <Table.Cell collapsing style={{ border: 0, paddingTop: 0 }}>
                  <strong>Phone</strong>
                </Table.Cell>
                <Table.Cell style={{ border: 0, paddingTop: 0 }}>{data.customer.phone}</Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </Grid.Column>
      <Grid.Column>
        {shipMethod && (
          <p>
            <strong>Ship Method</strong>: {shipMethod}
          </p>
        )}
        <ShippingInfo values={data} />
      </Grid.Column>
    </Grid>
  );
};
