// import * as React from 'react';
// import { Accordion, Button, Checkbox, Header, Grid, Label, Segment, Table } from 'semantic-ui-react';
// import { Link } from 'react-router-dom';
// import { FormattedDate, FormattedNumber, FormattedRelative } from 'react-intl';
// import { IndexWrapper, IndexWrappedProps } from './../../../bootstrap/IndexWrapper';
// import { ListViewPagination, ListFilterWarning } from './../../../common';
// // import FilterForm from './FilterForm';

// const filterMapper = (filter) => {
//   return {
//     organizationName: { $LIKE: filter && filter.name },
//     // 'primaryContact.firstName': { $LIKE: filter && filter.name },
//     // 'primaryContact.lastName': { $LIKE: filter && filter.name }
//   }
// }

// export function Container (props: IndexWrappedProps) {
//   const { queryResult } = props;
//   return (
//     <div>
//       <Header as='h1'>Estimates</Header>
//       <Header attached={'top'} as='h5'>
//       Filter
//       </Header>
//       <Segment attached secondary>
//         {/* <FilterForm
//           onSubmit={props.handleFilterSubmit}
//           handleClearFilter={props.handleClearFilter}
//           isFiltered={props.isFiltered}
//           /> */}
//       </Segment>
//       <ListFilterWarning isFiltered={props.isFiltered} />
//       <Segment attached loading={props.isLoading}>
//         <ListViewPagination.Top
//           label={'New Estimate'}
//           link={'/estimates/add'}
//           {...props}
//           hideAddButton={true}
//         />
//         <Table size={'small'}>
//           <Table.Header>
//             <Table.Row>
//               <Table.HeaderCell>
//                 <Checkbox
//                   onChange={props.handleSelectAllClick}
//                 />
//               </Table.HeaderCell>
//               <Table.HeaderCell>Date</Table.HeaderCell>
//               <Table.HeaderCell>ID</Table.HeaderCell>
//               <Table.HeaderCell>Name</Table.HeaderCell>
//               <Table.HeaderCell></Table.HeaderCell>
//             </Table.Row>
//           </Table.Header>
//           <Table.Body>
//             { queryResult && queryResult.data.map( i => {
//               let name = i.name;
//               let email = i.email;
//               if (i.customer) {
//                 email = i.customer.email;
//                 if (i.customer.organizationName) {
//                   name = i.customer.organizationName;
//                 } else if (i.customer.primaryContact) {
//                   const {primaryContact} = i.customer;
//                   name = `${primaryContact.firstName} ${primaryContact.firstName}`;
//                 }
//               }
//               return (
//               <Table.Row key={i._id}>
//                 <Table.Cell>
//                   <Checkbox
//                     value={i._id}
//                     checked={props.selected[i._id] || false}
//                     onChange={props.handleSemanticCheckboxClick}
//                   />
//                 </Table.Cell>
//                 <Table.Cell>
//                 <FormattedDate value={i.createdAt}/>
//                 </Table.Cell>
//                 <Table.Cell>
//                   {`${i.humanId}` }
//                 </Table.Cell>
//                 <Table.Cell>
//                   { name }
//                 </Table.Cell>
//                 <Table.Cell>

//                     <Button size={'mini'}>Details</Button>

//                 </Table.Cell>
//               </Table.Row>
//             )})}
//           </Table.Body>
//         </Table>
//         <ListViewPagination.Bottom
//           label={'Delete'}
//           {...props}
//           hideDeleteButton
//         />
//       </Segment>
//     </div>
//   );
// }

// export default IndexWrapper(Container, 'estimates', {
//   filterMapper,
//   query: {
//     $sort: { createdAt: -1 },
//     $populate: ['customer'],
//   }
// });

import * as React from 'react';
import { Button, Segment, Popup } from 'semantic-ui-react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { FormattedDate, FormattedTime } from 'react-intl';

import { useList, ListTable, EmptyWrapper, PaginatorWrapper } from '@inkcloud/components';

interface ListProps {}

const serviceName = 'estimates';
const pathPrefix = '/estimates';

const filterQueryMap = (values) => {};

export async function getFilterDefinition() {}

export const List: React.FunctionComponent<ListProps & RouteComponentProps<any>> = (props) => {
  const {
    match: { params },
    location,
    history,
  } = props;

  const {
    state,
    reload,
    handleDelete,
    selectedItems,
    clearAll,
    setChecked,
    someSelected,
    toggleAll,
    filter,
    setFilter,
    sort,
    handleSort,
  } = useList({
    feathers: [
      'find',
      serviceName,
      [
        {
          query: {
            isApproved: { $ne: true },
          },
        },
      ],
    ],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  }) as any;

  const tableDefinition = [
    {
      key: 'createdAt',
      label: 'Date',
      sortable: true,
      cell: (v) =>
        v && (
          <Popup
            size="mini"
            content={<FormattedTime value={v} />}
            trigger={
              <div>
                <FormattedDate value={v} />
              </div>
            }
          />
        ),
    },
    {
      key: 'humanId',
      label: 'ID',
      sortable: true,
      cell: (v, r) => v,
    },
    {
      key: '',
      label: 'name',
      cell: (v, r) => (
        <Link to={`/customers/${r.customer?._id}`}>
          {r.customer?.organizationName ||
            `${r.customer?.primaryContact?.firstName ?? ''} ${
              r.customer?.primaryContact?.lastName ?? ''
            }`}
        </Link>
      ),
    },
    // {
    //   key: '',
    //   label: '',
    //   sortable: false,
    //   cell: (v, r) => <Popup
    //     size="mini"
    //     content="Edit"
    //     trigger={
    //       <Link to={`${pathPrefix}/${r._id}`}>
    //         <Button size={"mini"} icon={"edit"} />
    //       </Link>
    //     }
    //   />,
    //   textAlign: 'right'
    // },
  ];

  const { queryResult, isLoading: isStateLoading } = state as any;

  const resolvedQueryResult = queryResult?.data ? queryResult.data : queryResult;

  return (
    <div>
      <Segment>
        <EmptyWrapper queryResult={resolvedQueryResult} isStateLoading={isStateLoading}>
          <PaginatorWrapper.Top
            filter={filter}
            reload={reload}
            data={queryResult as any}
            pathPrefix={`${pathPrefix}`}
          />
          <ListTable
            data={resolvedQueryResult}
            definition={tableDefinition}
            selectedItems={selectedItems}
            setChecked={setChecked}
            sort={sort}
            sortClickFunction={handleSort}
            toggleAll={toggleAll}
            withCheckboxes={false}
          />

          <PaginatorWrapper.Bottom
            reload={reload}
            data={queryResult as any}
            pathPrefix={`${pathPrefix}`}
          />
        </EmptyWrapper>
      </Segment>
    </div>
  );
};
