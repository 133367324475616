import * as React from 'react';
import { Switch, Route } from 'react-router-dom';

import { List } from './List';
import { Detail } from './Detail';

const basePath = '/store-front-content/hero-stacks';

const routes = (props) => (
  <Switch>
    <Route exact path={`${basePath}`} component={List} />
    <Route exact path={`${basePath}/add`} component={Detail} />
    <Route exact path={`${basePath}/:id`} component={Detail} />
  </Switch>
);

export default routes;
