// import * as React from 'react'
// import { Link, RouteComponentProps } from 'react-router-dom';
// import { useList, PageHeaderRow, PaginatorWrapper, EmptyWrapper } from '@inkcloud/components';
// import { Button, Form, Table, Message, Popup, Segment } from 'semantic-ui-react'

// export interface ListProps { }

// const serviceName = '/production/channels';
// const pathPrefix = `/settings/production/production-channels`;

// const filterQueryMap = (values) => { };

// const { useState, useEffect } = React
// export const List: React.FunctionComponent<ListProps & RouteComponentProps> = props => {

//   const { match: { params }, location, history, } = props;

//   const { state, reload, handleDelete, selectedItems, clearAll, setChecked, someSelected, toggleAll, filter, setFilter, sort, handleSort } = useList({
//     feathers: ['find', serviceName, [{ query: {} }]],
//     filterMap: filterQueryMap,
//     debounce: 150,
//     location,
//     history,
//     sort: { createdAt: -1 }
//   })

//   const { queryResult, isLoading: isStateLoading } = state as any;

//   if (!queryResult) {
//     return null;
//   }

//   return <>
//     <PageHeaderRow header='Production Channels' subheader='Settings'>
//       <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
//       <Link to={`${pathPrefix}/add`}><Button content="New" primary size={'mini'} /></Link>
//     </PageHeaderRow>
//     <div></div>
//     <Segment >
//       <PaginatorWrapper.Top filter={filter} reload={reload} data={(queryResult as any)} pathPrefix={`${pathPrefix}/`} />
//       <Table size={'small'}>
//         <Table.Header>
//           <Table.Row>
//             <Table.HeaderCell><input type="checkbox" onChange={toggleAll} /></Table.HeaderCell>
//             <Table.HeaderCell>Name</Table.HeaderCell>
//             <Table.HeaderCell>Key</Table.HeaderCell>
//             <Table.HeaderCell />
//           </Table.Row>
//         </Table.Header>
//         <Table.Body>
//           {queryResult && (queryResult as any).data.map((r: any, index: number) => (
//             <Table.Row verticalAlign='top' key={index}>
//               <Table.Cell>
//                 <input type="checkbox" value={r._id} checked={selectedItems[r._id] || false} onChange={setChecked} />
//               </Table.Cell>
//               <Table.Cell>
//                 {r.name}
//               </Table.Cell>
//               <Table.Cell>
//                 {r.key}
//               </Table.Cell>
//               <Table.Cell textAlign="right">
//                 <Popup
//                   size='mini'
//                   content="Edit Pick-up Location"
//                   trigger={
//                     <Link to={`${pathPrefix}/${r._id}`}>
//                       <Button size={'mini'} icon={'edit'} />
//                     </Link>
//                   }
//                 />
//               </Table.Cell>
//             </Table.Row>
//           ))}
//         </Table.Body>
//       </Table>
//       <PaginatorWrapper.Bottom reload={reload} data={(queryResult as any)} pathPrefix={`${pathPrefix}`}>
//         {someSelected && <Button primary type="button" content="Delete" onClick={handleDelete} />}
//       </PaginatorWrapper.Bottom>
//     </Segment>
//   </>
// }

import * as React from 'react';
import { Button, Box, IconButton, Tooltip } from '@chakra-ui/react';

import { Link, RouteComponentProps } from 'react-router-dom';

import { FaEdit } from 'react-icons/fa';

import { useList, ListPage } from '@inkcloud/components';

interface ListProps {}

const serviceName = '/production/channels';
const pathPrefix = `/settings/production/production-channels`;

const filterQueryMap = (values) => {};

export default function List(props: ListProps & RouteComponentProps) {
  const {
    match: { params },
    location,
    history,
  } = props;

  const useListValues = useList({
    feathers: ['find', serviceName, [{ query: {} }]],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  const tableDefintion = [
    {
      key: 'name',
      label: 'Name',
      cell: (v) => v,
    },
    {
      key: 'key',
      label: 'Key',
      cell: (v) => v,
    },

    {
      key: '',
      label: '',
      cell: (v, r) => (
        <Tooltip label="Edit Details">
          <Link to={`${pathPrefix}/${r._id}`}>
            <IconButton colorScheme="gray" aria-label="Edit" icon={<FaEdit />} />
          </Link>
        </Tooltip>
      ),
      textAlign: 'right',
    },
  ];

  const headerActions = (
    <>
      <Link to={`${pathPrefix}/add`}>
        <Button colorScheme="blue">New</Button>
      </Link>
    </>
  );

  return (
    <Box>
      <ListPage
        header="Production Channels"
        subheader="Settings"
        useListValues={useListValues}
        history={history}
        pathPrefix={pathPrefix}
        headerActions={headerActions}
        tableDefintion={tableDefintion}
        withCheckboxes={true}
        getFilterDefinition={null}
      />
    </Box>
  );
}
