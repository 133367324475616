import * as React from 'react';
import { Button, Modal, Message } from 'semantic-ui-react';

export interface ResetPasswordModalProps {
  isOpen: boolean;
  onClose: any;
  onSubmit: () => void;
  errorMessage: string;
  isSubmitting?: boolean;
}

export const ResetPasswordModal: React.FunctionComponent<ResetPasswordModalProps> = (props) => {
  return (
    <Modal open={props.isOpen} onClose={props.onClose} closeIcon size="mini">
      <Modal.Header>Customer Password Reset</Modal.Header>
      <Modal.Content>
        <p>Send reset password email?</p>
        {props.errorMessage && <Message error content={props.errorMessage} />}
      </Modal.Content>
      <Modal.Actions>
        <Button negative onClick={props.onClose}>
          No
        </Button>
        <Button
          loading={props.isSubmitting}
          positive
          icon="checkmark"
          labelPosition="right"
          content="Yes"
          onClick={props.onSubmit}
        />
      </Modal.Actions>
    </Modal>
  );
};
