import React from 'react';

import {
  Box,
  Heading,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Input,
  Checkbox,
  Textarea,
  Text,
} from '@chakra-ui/react';

import type { ResponseItemsType } from '../common/types';

interface IItemResponseFormProps {
  response: ResponseItemsType[number];
  isReadOnly?: boolean;
  onChange: (v: any) => void;
}

export default function ItemResponseForm(props: IItemResponseFormProps) {
  const { response, isReadOnly = false, onChange } = props;

  // console.log('response', response)

  return (
    <Box
      bgColor={'yellow.50'}
      mb={16}
      p={8}
      borderWidth={'1px'}
      borderStyle={'solid'}
      borderColor={'yellow.300'}
    >
      <Heading size={'xs'}>Enter your prices here:</Heading>
      <TableContainer mt={8} mb={8}>
        <Table size="sm">
          <Thead>
            <Tr>
              <Th>Quantities</Th>
              <Th>Item Price</Th>
            </Tr>
          </Thead>
          <Tbody>
            {response?.quantities?.map((q, i) => (
              <Tr key={i}>
                <Th>{q.quantity}</Th>
                <Td>
                  <Input
                    isDisabled={isReadOnly}
                    bgColor={'white'}
                    value={q.price}
                    placeholder={'Enter price...'}
                    onChange={(e) =>
                      onChange({
                        ...response,
                        quantities: response?.quantities?.map((innerQ, j) =>
                          i === j
                            ? {
                                ...innerQ,
                                price: e.target.value?.replace(/[^0-9.]/g, ''),
                                isOptedOut: innerQ?.isOptedOut ?? false,
                              }
                            : innerQ
                        ),
                      })
                    }
                  />
                </Td>
                <Td>
                  <Checkbox
                    isDisabled={isReadOnly}
                    variant="filled"
                    isChecked={q?.isOptedOut}
                    onChange={(e) =>
                      onChange({
                        ...response,
                        quantities: response?.quantities?.map((innerQ, j) =>
                          i === j ? { ...innerQ, isOptedOut: e.target.checked } : innerQ
                        ),
                      })
                    }
                  >
                    Opt-out
                  </Checkbox>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Text>Note any additional costs below</Text>
      <Textarea
        bgColor={'white'}
        isDisabled={isReadOnly}
        value={response?.notes}
        placeholder="Notes..."
        onChange={(e) =>
          onChange({
            ...response,
            notes: e.target.value,
          })
        }
      />
    </Box>
  );
}
