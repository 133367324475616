import * as React from 'react';
import {
  Box,
  Flex,
  Checkbox,
  FormControl,
  FormLabel,
  Heading,
  Input,
  SimpleGrid,
  Button,
  Divider,
  Table,
  Tbody,
  Tr,
  Th,
  Td,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { Step, Steps, useSteps } from 'chakra-ui-steps';

import Cleave from 'cleave.js/react';
import { humanize } from 'underscore.string';
// import { FormControl } from '../../common/v3/Form/FormControl';
import { SegmentCard } from '../../common';
import TurnedEdge from './TurnedEdge';
import MaterialCostTable, { MaterialCostRow } from './MaterialCost';
import CostTable from './CostTable';
import { currencyFormatted } from './helper';

interface ICostingProps {}

const initialSelections = {
  binderCovers: true,
  binderLiners: false,
  secondLiner: false,
  pocketCover: false,
  slipcaseCovers: false,
  other: false,
  panelChip: false,
  spines: false,
  capper: false,
};

const steps = [
  { label: 'Quantity', description: undefined },
  { label: 'Components Specs', description: '' },
  { label: 'Material', description: '' },
  { label: 'Operation', description: '' },
  { label: 'Markup', description: '' },
];

interface ComponentValue {
  key: string;
  inputs: {
    cutSize: number;
    length: number;
    times: number;
    rollSize: number;
    type: any; // TODO: Not sure what type
    color: any; // TODO: Not sure what type
  };
  outputs: {
    out: number;
    pull: number;
    sheets: number;
    yards: number;
  };
}

interface MaterialCost {
  key: string;
  name: string;
  units: string;
  cost: number;
}

interface State {
  quantity: number;
  componentSelections: { [key: string]: boolean };
  componentValues: ComponentValue[];
  materialCosts: MaterialCostRow[];
  resourceCosts: any;
  markup: number;
}

function calculateComponentValues(
  componentSelections: { [key: string]: boolean },
  componentValues: ComponentValue[]
): ComponentValue[] {
  const updatedComponentValues = Object.keys(componentSelections).reduce((acc, key) => {
    let updatedAcc = [...acc];

    // if enabled, check if it exists and if not, add it
    if (componentSelections[key]) {
      if (!updatedAcc.find((c) => c.key === key)) {
        updatedAcc.push({
          key,
          inputs: {
            cutSize: 0,
            length: 0,
            times: 0,
            rollSize: 0,
            type: null,
            color: null,
          },
          outputs: {
            out: 0,
            pull: 0,
            sheets: 0,
            yards: 0,
          },
        });
      }
    } else {
      // if its not enabled, remove any entries for it.
      updatedAcc = updatedAcc.filter((c) => c.key !== key);
    }

    return updatedAcc;
  }, componentValues);

  return updatedComponentValues;
}

function calculateMaterialCosts(
  componentValues: ComponentValue[],
  materialCosts: MaterialCostRow[],
  quantity
): MaterialCostRow[] {
  // Remove any material costs that are not in the component values
  let updatedMaterialCosts = materialCosts.filter((m) =>
    componentValues.find((c) => c.key === m.key)
  );

  // add any missing material costs that are in component values
  const missingMaterialCosts = componentValues
    .filter((c) => !materialCosts.find((m) => m.key === c.key))
    .map((c) => ({
      key: c.key,
      name: humanize(c.key),
      units: 'yards',
      totalUnits: 0.0,
      costPerUnit: 0.0,
    }));

  updatedMaterialCosts = [...updatedMaterialCosts, ...missingMaterialCosts].map((m) => {
    const foundMaterialCost = componentValues.find((c) => m.key === c.key);

    // console.log('foundMaterialCost ', foundMaterialCost);
    if (foundMaterialCost) {
      let temp = Math.ceil(quantity / foundMaterialCost.outputs.out);
      if (!isFinite(temp)) {
        temp = 0;
      }
      return {
        ...m,
        totalUnits: Math.ceil(temp * (foundMaterialCost.outputs.pull / 36)),
      };
    }

    return m;
  });

  return updatedMaterialCosts;
}

const initialComponentValues = calculateComponentValues({ ...initialSelections }, []);
const initialState = {
  quantity: 1,
  componentSelections: { ...initialSelections },
  componentValues: [...initialComponentValues],
  materialCosts: calculateMaterialCosts(initialComponentValues, [], 1),
  resourceCosts: {},
  markup: 0,
};

function reducer(state: State, action): State {
  switch (action.type) {
    case 'setQuantity':
      return { ...state, quantity: parseInt(action.value, 10) };
    case 'setComponentSelections':
      // recalculate component values
      return {
        ...state,
        componentSelections: action.value,
        componentValues: calculateComponentValues(action.value, state.componentValues),
      };
    case 'setComponentValues':
      return {
        ...state,
        componentValues: action.value,
        materialCosts: calculateMaterialCosts(action.value, state.materialCosts, state.quantity),
      };
    case 'setComponentValueByKey': {
      const updatedComponentValues = state.componentValues.map((c) =>
        c.key === action.key ? { ...c, inputs: action.inputValue, outputs: action.value } : c
      );
      return {
        ...state,
        componentValues: updatedComponentValues,
        materialCosts: calculateMaterialCosts(
          updatedComponentValues,
          state.materialCosts,
          state.quantity
        ),
      };
    }
    case 'setMaterialCosts':
      return { ...state, materialCosts: action.value };

    case 'setResourceCosts':
      return { ...state, resourceCosts: action.value };

    case 'setMarkup':
      return { ...state, markup: action.value };

    default:
      throw new Error(`Unknown action type: ${action.type}`);
  }
}

const Costing: React.FunctionComponent<ICostingProps> = (props) => {
  // const { activeStep, setActiveStep } = useSteps({
  //   index: 0,
  //   count: steps.length,
  // })

  const [activeStep, setActiveStep] = React.useState(0);

  const tenant = useSelector(
    ({
      globals,
    }: {
      globals: { tenant: { internationalization: { currency: string; locale: string } } };
    }) => globals.tenant.internationalization
  );
  // const { nextStep, prevStep, reset, activeStep } = useSteps({
  //   initialStep: 0,
  // })

  const [state, dispatch] = React.useReducer(reducer, initialState);

  const totalMaterialCost = state.materialCosts.reduce(
    (acc, m) => acc + m.totalUnits * m.costPerUnit,
    0
  );
  const totalResourceCost = Object.keys(state.resourceCosts)?.reduce(
    (acc, key) => acc + Number(state.resourceCosts[key].total),
    0
  );
  console.log('state', state);
  return (
    <Box>
      <Box mb={8}>
        <Steps activeStep={activeStep}>
          {steps.map(({ label, description }, index) => (
            <Step label={label} key={label} description={description}></Step>
          ))}
        </Steps>
      </Box>

      {/* <Stepper size={'sm'} index={activeStep} mb={24} mt={16}>
        {steps.map((step, index) => (
          <Step key={index}>
            <StepIndicator>
              <StepStatus
                complete={<StepIcon />}
                incomplete={<StepNumber />}
                active={<StepNumber />}
              />
            </StepIndicator>

            <Box flexShrink='0'>
              <StepTitle>{step.title}</StepTitle>
              <StepDescription>{step.description}</StepDescription>
            </Box>

            <StepSeparator />
          </Step>
        ))}
      </Stepper> */}
      {/* <pre>
            {JSON.stringify(state, null, 2)}
          </pre> */}

      {activeStep === 0 && (
        <SegmentCard title="Quantity">
          <FormControl>
            <Input
              type="number"
              value={state.quantity}
              onChange={(e) => dispatch({ type: 'setQuantity', value: e.target.value })}
            />
          </FormControl>
          <br />
          <Button
            colorScheme={'blue'}
            onClick={() => {
              if (Number.isNaN(state.quantity) || state.quantity < 1) {
                dispatch({ type: 'setQuantity', value: 1 });
              }
              setActiveStep(activeStep + 1);
            }}
          >
            Next
          </Button>
        </SegmentCard>
      )}

      {activeStep === 1 && (
        <SegmentCard title="Components">
          <Heading size={'sm'}>Select applicable components</Heading>
          <Box textAlign={'right'} mr={16}>
            <SimpleGrid columns={4} spacing={8}>
              {Object.keys(state.componentSelections).map((key, idx) => {
                const a = '';
                return (
                  <FormControl key={idx} display="flex" alignItems="center" alignContent={'center'}>
                    <Checkbox
                      mr={2}
                      isChecked={state.componentSelections[key]}
                      onChange={(e) =>
                        dispatch({
                          type: 'setComponentSelections',
                          value: { ...state.componentSelections, [key]: e.target.checked },
                        })
                      }
                    />
                    <FormLabel htmlFor="email-alerts" mb="0" width={130}>
                      {humanize(key)}
                    </FormLabel>
                  </FormControl>
                );
              })}
            </SimpleGrid>
          </Box>

          <Divider my={4} />
          <Box width={400}>
            {Object.keys(state.componentSelections).map((key, idx) => {
              if (!state.componentSelections[key]) return null;
              return (
                <Box key={key} mb={4}>
                  <SegmentCard title={humanize(key)}>
                    <TurnedEdge
                      componentKey={key}
                      quantity={state.quantity}
                      values={state.componentValues.find((c) => c.key === key)?.inputs || undefined}
                      onChange={(v) => dispatch({ type: 'setComponentValueByKey', ...v })}
                    />
                  </SegmentCard>
                </Box>
              );
            })}
          </Box>

          <Button mr={2} colorScheme={'blue'} onClick={() => setActiveStep(activeStep - 1)}>
            Previous
          </Button>
          <Button colorScheme={'blue'} onClick={() => setActiveStep(activeStep + 1)}>
            Next
          </Button>
        </SegmentCard>
      )}

      {activeStep === 2 && (
        <SegmentCard title="Material Costs">
          <MaterialCostTable
            materialCosts={state.materialCosts}
            quantity={state.quantity}
            onChange={(v) => dispatch({ type: 'setMaterialCosts', value: v })}
          />

          <Button mr={2} colorScheme={'blue'} onClick={() => setActiveStep(activeStep - 1)}>
            Previous
          </Button>
          <Button colorScheme={'blue'} onClick={() => setActiveStep(activeStep + 1)}>
            Next
          </Button>
        </SegmentCard>
      )}

      {activeStep === 3 && (
        <SegmentCard title="Resource Costs">
          <CostTable
            quantity={state.quantity}
            values={state.resourceCosts}
            onChange={(v) => dispatch({ type: 'setResourceCosts', value: v })}
          />

          <Button mr={2} colorScheme={'blue'} onClick={() => setActiveStep(activeStep - 1)}>
            Previous
          </Button>
          <Button colorScheme={'blue'} onClick={() => setActiveStep(activeStep + 1)}>
            Next
          </Button>
        </SegmentCard>
      )}

      {activeStep === 4 && (
        <SegmentCard title="Markup">
          <Table>
            <Tbody>
              <Tr>
                <Th>Quantity</Th>
                <Td isNumeric>{state.quantity}</Td>
              </Tr>
              <Tr>
                <Th>Material Cost</Th>
                <Td isNumeric>
                  {currencyFormatted(totalMaterialCost, tenant?.locale, tenant?.currency)}
                </Td>
              </Tr>
              <Tr>
                <Th>Resource Cost</Th>
                <Td isNumeric>
                  {currencyFormatted(totalResourceCost, tenant?.locale, tenant?.currency)}
                </Td>
              </Tr>
              <Tr>
                <Th>Total</Th>
                <Td isNumeric>
                  {currencyFormatted(
                    totalMaterialCost + totalResourceCost,
                    tenant?.locale,
                    tenant?.currency
                  )}
                </Td>
              </Tr>
              <Tr>
                <Th>Markup</Th>
                <Td isNumeric>
                  <Box
                    sx={{
                      '.form-control-cleave': {
                        width: '100%',
                        outline: '2px solid transparent',
                        pos: 'relative',
                        fontSize: 'var(--chakra-fontSizes-sm)',
                        height: 'var(--chakra-sizes-8)',
                        border: '1px solid',
                        borderColor: 'var(--chakra-colors-gray-300)',
                        background: 'inherit',
                        paddingInlineStart: 'var(--chakra-sizes-3)',
                        paddingInlineEnd: 'var(--chakra-sizes-3)',
                        borderRadius: 'var(--chakra-radii-md)',
                      },
                    }}
                  >
                    <Cleave
                      style={{ textAlign: 'right' }}
                      className={'form-control-cleave'}
                      value={state.markup}
                      onChange={(e) => {
                        dispatch({ type: 'setMarkup', value: Number(e.target.rawValue) });
                      }}
                      options={{
                        numeral: true,
                        rawValueTrimPrefix: true,
                        numeralPositiveOnly: true,
                        numeralThousandsGroupStyle: 'thousand',
                        prefix: '$',
                      }}
                    />
                  </Box>
                </Td>
              </Tr>
              <Tr>
                <Th>Price</Th>
                <Td isNumeric>
                  {currencyFormatted(
                    totalMaterialCost + totalResourceCost * (1 + state?.markup),
                    tenant?.locale,
                    tenant?.currency
                  )}
                </Td>
              </Tr>
            </Tbody>
          </Table>

          <Button mr={2} colorScheme={'blue'} onClick={() => setActiveStep(activeStep - 1)}>
            Previous
          </Button>
          {/* <Button colorScheme={'blue'} onClick={() => setActiveStep(activeStep + 1)}>Next</Button> */}
        </SegmentCard>
      )}
    </Box>
  );
};

export default Costing;
