import * as React from 'react';
import { connect } from 'react-redux';
import {
  InjectedFormProps,
  Field,
  reduxForm,
  change,
  formValueSelector,
  FormProps,
} from 'redux-form';
import { Link } from 'react-router-dom';
import { Button, Form } from 'semantic-ui-react';
import { slugify } from 'underscore.string';
import {
  semanticFormField,
  semanticFormToggleField,
  semanticFormDropdownField,
  validateRequired,
} from './../../../../common';

type UpdateTurnaroundFormProps = {
  isEdit: boolean;
} & InjectedFormProps;

const validate = validateRequired(['turnaround']);

export function UpdateTurnaroundForm(props: UpdateTurnaroundFormProps) {
  const { handleSubmit, pristine, reset, submitting, typeValue } = props;

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group widths={'equal'}>
        <Field
          name="turnaround"
          component={semanticFormField}
          as={Form.Input}
          type="text"
          label="Turnaround Time"
          placeholder="Turnaround Time..."
        />
      </Form.Group>
      <Button primary disabled={pristine || submitting}>
        Submit
      </Button>
      <Link to={'/'}>
        <Button type="button">Back</Button>
      </Link>
    </Form>
  );
}

const formName = 'orderUpdateTurnaroundTime';
const reduxedForm: any = reduxForm({
  form: formName,
  validate,
})(UpdateTurnaroundForm as any);

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(reduxedForm) as any;
