import * as React from 'react';
import { Switch, Route } from 'react-router-dom';

import { Detail } from './Detail';
import { List } from './List';

const routes = (props) => (
  <Switch>
    <Route exact path="/settings/microsites/:micrositeId/notification-rules" component={List} />
    <Route
      exact
      path="/settings/microsites/:micrositeId/notification-rules/add"
      component={Detail}
    />
    <Route
      exact
      path="/settings/microsites/:micrositeId/notification-rules/:id"
      component={Detail}
    />
  </Switch>
);

export default routes;
