import * as React from 'react';
import { Document, Page, View, Text, Image, StyleSheet } from '@react-pdf/renderer';

const addressesStyles = StyleSheet.create({
  item: {
    lineHeight: 1.3,
    fontSize: 11,
    padding: 12,
  },
});

interface IItemLineItemsProps {
  quantities: number[];
  lineItems: {
    label: string;
    isSum: boolean;
    quantities: { quantity: number; amount: number }[];
  }[];
  locale: string;
  currency: string;
}

const ItemLineItems: React.FunctionComponent<IItemLineItemsProps> = (props) => {
  const { lineItems, quantities, locale, currency } = props;

  return (
    <View>
      <View style={addressesStyles.item}>
        <View
          style={{
            flexDirection: 'row',
            fontSize: 10,
            justifyContent: 'space-between',
            fontFamily: 'Helvetica-Bold',
            alignContent: 'flex-end',
            marginBottom: 4,
            paddingBottom: 4,
            borderBottom: '1px solid #ccc',
          }}
        >
          <View>
            <Text style={{ width: 150, textAlign: 'right', fontWeight: 'bold' }}>Quantity</Text>
          </View>

          {quantities.map((q) => (
            <Text key={q} style={{ width: 50, textAlign: 'right' }}>
              {q}
            </Text>
          ))}
        </View>

        {lineItems.map(({ label, isSum, quantities: lineQuantities }) => (
          <View
            style={{
              flexDirection: 'row',
              fontSize: 10,
              justifyContent: 'space-between',
              alignContent: 'flex-end',
              marginBottom: 4,
              paddingBottom: 4,
              borderBottom: '1px solid #ccc',
              fontFamily: isSum ? 'Helvetica-Bold' : undefined,
              textTransform: isSum ? 'uppercase' : undefined,
            }}
          >
            <Text style={{ width: 150, textAlign: 'right' }}>{label}</Text>
            {quantities.map((q) => {
              const lineQuantity = lineQuantities.find((lq) => lq.quantity === q);
              return (
                <Text style={{ width: 50, textAlign: 'right' }}>
                  {new Intl.NumberFormat(locale, {
                    style: 'currency',
                    currency: currency ?? 'USD',
                  }).format(lineQuantity?.amount ?? 0)}
                </Text>
              );
            })}
          </View>
        ))}
      </View>
    </View>
  );
};

export default ItemLineItems;
