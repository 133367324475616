import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Feathers from './../../../bootstrap/feathers';
import { PageHeaderRow } from '@inkcloud/components';
import { Form, Button, Checkbox, Segment, Message } from 'semantic-ui-react';

import { Heading } from './../Heading';

interface DetailProps {}

const { useEffect, useState } = React;

export const Detail: React.FunctionComponent<DetailProps & RouteComponentProps> = (props) => {
  const {
    match: { params },
  } = props as any;

  const [menu, setMenu] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [message, setMessage] = useState('');

  useEffect(() => {
    const loadMicrositeMenu = async () => {
      setIsLoading(true);
      const res: any = await Feathers.service('microsites/menu-settings').get(params.micrositeId, {
        query: {},
      });
      setMenu(res);
      setIsLoading(false);
    };

    loadMicrositeMenu();
  }, []);

  const handleCheckChange = (data, index) => {
    const newMenu = menu.map((m, idx) => (idx === index ? { ...m, enabled: data.checked } : m));
    setMenu(newMenu);
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const res: any = await Feathers.service('microsites/menu-settings').create({
        micrositeId: params.micrositeId,
        menu,
      });
      setIsLoading(false);
      props.history.goBack();
    } catch (e) {
      e.code && e.code < 500
        ? setMessage(e.message)
        : setMessage('We are experiencing technical difficulties. Please try again');
      setIsLoading(false);
    }
  };

  return (
    <>
      <PageHeaderRow header={`Microsites`} subheader="Menu" />
      <Heading id={params.micrositeId} />
      <Segment loading={isLoading}>
        <Form>
          {menu &&
            menu.length > 0 &&
            menu.map((m: any, index: number) => (
              <Form.Field key={index}>
                <Checkbox
                  name={m.key}
                  checked={m.enabled}
                  label={m.label}
                  onChange={(e, data) => handleCheckChange(data, index)}
                />
              </Form.Field>
            ))}
          {message && <Message error content={message} />}
          <br />
          <Button primary type="submit" disabled={isLoading} onClick={handleSubmit}>
            Save
          </Button>
        </Form>
      </Segment>
    </>
  );
};
