import React, { useState, useEffect } from 'react';
import { SimpleGrid, Box, Radio, Stack, RadioGroup } from '@chakra-ui/react';

import { Select } from 'chakra-react-select';

import { initType } from '../Create';

interface ICustomerPickerProps {
  data?: initType;
  microsites: { label: string; value: string }[];
  users: { label: string; value: string }[];
  onChange: (value: any) => void;
}

export default function CustomerPicker(props: ICustomerPickerProps) {
  const { data, microsites, users, onChange } = props;

  let t;

  if (data?.customerType === 'microsite') {
    t = (
      <SimpleGrid columns={2} spacing={5}>
        <Box>
          <Select
            useBasicStyles
            placeholder="Search microsites..."
            isClearable={true}
            options={microsites}
            onChange={(v) => {
              const newValue =
                typeof v === 'object' && v
                  ? { microsite: v.value }
                  : {
                      microsite: { label: '', value: '' },
                      user: { label: '', value: '' },
                    };
              onChange(newValue);
            }}
            value={microsites?.find((m) => m.value === data?.microsite) || ''}
            styles={{
              container: (provided, state) => ({
                ...provided,
                flexGrow: 1,
              }),
              valueContainer: (provided, state) => ({
                ...provided,
              }),
              control: (provided, state) => ({
                ...provided,
              }),
            }}
          />
        </Box>
        <Box>
          <Select
            useBasicStyles
            placeholder="Search customers..."
            isClearable={true}
            options={users}
            isDisabled={!data?.microsite}
            onChange={(v) => {
              onChange({ user: v });
            }}
            value={users?.find((c) => c.value === data?.user?.value) || ''}
            styles={{
              container: (provided, state) => ({
                ...provided,
                flexGrow: 1,
              }),
              valueContainer: (provided, state) => ({
                ...provided,
              }),
              control: (provided, state) => ({
                ...provided,
              }),
            }}
          />
        </Box>
      </SimpleGrid>
    );
  } else {
    t = (
      <Select
        useBasicStyles
        placeholder="Search customers..."
        isClearable={true}
        options={users}
        onChange={(v) => {
          onChange({ user: v });
        }}
        value={users?.find((c) => c.value === data?.user?.value) || ''}
        styles={{
          container: (provided, state) => ({
            ...provided,
            flexGrow: 1,
          }),
          valueContainer: (provided, state) => ({
            ...provided,
          }),
          control: (provided, state) => ({
            ...provided,
          }),
        }}
      />
    );
  }

  return (
    <>
      {microsites?.length > 0 && (
        <RadioGroup
          onChange={(v) => {
            onChange({ microsite: '', user: { label: '', value: '' }, customerType: v });
          }}
          value={data?.customerType}
        >
          <Stack py={3} spacing={7} direction="row">
            <Radio value="microsite">Microsite</Radio>
            <Radio value="customer">Top Level Customer</Radio>
          </Stack>
        </RadioGroup>
      )}

      {t}
    </>
  );
}
