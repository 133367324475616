import * as React from 'react';
import { Table, Icon } from 'semantic-ui-react';
import Feathers from './../bootstrap/feathers';

import { humanize } from 'underscore.string';

import { readableAddOns } from './OrderItemCard2';

import {
  addOnFields,
  rcOptions,
  dynamicAddOns,
  bcslitOptions,
} from './../components/order-builder/calculator/AddOnSet';

interface CartDetailTableProps {
  item: any;
  // description?: string;
  // attributes?: any;
  // quantity?: number;
  addOns?: any[];
  addOnsBase?: any[];
  // isCustomTrim?: boolean;
  // customTrimWidth?: number;
  // customTrimHeight?: number;
  // metadata?: any[];
}

const allAddOns = [
  ...addOnFields,
  ...dynamicAddOns,
  { key: 'rc', options: rcOptions },
  { key: 'bcslit', options: bcslitOptions },
];

const baseUrl = `https://storage.googleapis.com/`;

export const CartDetailTable: React.FunctionComponent<CartDetailTableProps> = (props) => {
  // const {
  //   description,
  //   attributes,
  //   quantity,
  //   addOns,
  //   isCustomTrim,
  //   customTrimWidth,
  //   customTrimHeight,
  //   metadata
  // } = props;

  const { item, addOns } = props;

  return (
    <>
      <Table basic="very" size="small" compact="very">
        <Table.Body>
          {item?.attributes?.map((a: any, idx: number) => {
            let val = a.name;
            if (a.typeKey === 'SIZE' && item?.isCustomTrim) {
              val = `${item?.customTrimWidth} x ${item?.customTrimHeight}`;
            }
            return (
              <Table.Row key={idx} style={{ background: 'none' }}>
                <Table.Cell width={8} style={{ borderTop: 0 }}>
                  <strong>{a.typeName}</strong>
                </Table.Cell>
                <Table.Cell style={{ borderTop: 0 }}> {val}</Table.Cell>
              </Table.Row>
            );
          })}
          {addOns && addOns.length > 0 && (
            <Table.Row style={{ background: 'none' }}>
              <Table.Cell width={8} style={{ borderTop: 0 }}>
                <strong>Add Ons</strong>
              </Table.Cell>
              <Table.Cell style={{ borderTop: 0 }}>
                {addOns.map((a: any, idx: number) => {
                  let readable =
                    a.addOnId &&
                    readableAddOns(props.addOnsBase, allAddOns, a.addOnId, a.primaryData);
                  return (
                    <span key={idx}>
                      {readable}
                      {idx < addOns.length - 1 && ', '}
                    </span>
                  );
                })}
              </Table.Cell>
            </Table.Row>
          )}
          {item?.quantity >= 0 && (
            <Table.Row style={{ background: 'none' }}>
              <Table.Cell width={8} style={{ borderTop: 0 }}>
                <strong>Quantity</strong>
              </Table.Cell>
              <Table.Cell style={{ borderTop: 0 }}>{item.quantity}</Table.Cell>
            </Table.Row>
          )}
          {item?.description && (
            <Table.Row style={{ background: 'none' }}>
              <Table.Cell width={8} style={{ borderTop: 0 }}>
                <strong>Description</strong>
              </Table.Cell>
              <Table.Cell style={{ borderTop: 0 }}>{item.description}</Table.Cell>
            </Table.Row>
          )}
          {item?.metadata?.map((meta: any, index: number) => {
            if (meta?.key === 'tr_source_name' && meta?.value) {
              return (
                <Table.Row key={index} style={{ background: 'none' }}>
                  <Table.Cell width={8} style={{ borderTop: 0 }}>
                    <strong>Source Language</strong>
                  </Table.Cell>
                  <Table.Cell style={{ borderTop: 0 }}>{meta?.value ?? ''}</Table.Cell>
                </Table.Row>
              );
            }

            if (meta?.key === 'tr_dest_name' && meta?.value) {
              return (
                <Table.Row key={index} style={{ background: 'none' }}>
                  <Table.Cell width={8} style={{ borderTop: 0 }}>
                    <strong>Destination Language</strong>
                  </Table.Cell>
                  <Table.Cell style={{ borderTop: 0 }}>{meta?.value ?? ''}</Table.Cell>
                </Table.Row>
              );
            }
          })}

          {item?.miscUploads?.map((m: any, index: number) => (
            <Table.Row key={index} style={{ background: 'none' }}>
              <Table.Cell style={{ borderTop: 0 }}>
                <a href={`${baseUrl}${m.cloudBucket}/${m.cloudFilename}`} target="_blank">
                  <Icon name="file alternate outline" /> {m.originalFilename}
                </a>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
};
