import * as React from 'react';
import { Modal, Button, Message } from 'semantic-ui-react';
import Feathers from './../../../bootstrap/feathers';

interface IProductTypeModalProps {
  isOpen: boolean;
  onClose: () => void;
  product: any;
  history?: any;
  redirect?: string;
}

const { useState } = React;

export const ProductTypeModal: React.FunctionComponent<IProductTypeModalProps> = (props) => {
  const { isOpen, onClose, product, redirect, history } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    submit: '',
  });

  const handleClick = async () => {
    setIsSubmitting(true);
    setErrorMessage({
      ...errorMessage,
      submit: '',
    });

    const payload = {
      ...product,
      inventory: !product.inventory,
    };

    try {
      const res: any = await Feathers.service('/products-legacy').patch(product?._id, payload, {
        query: {},
      });

      history.push(redirect);
    } catch (e) {
      setErrorMessage({
        ...errorMessage,
        submit:
          e?.code < 500
            ? e.message
            : 'We are experiencing technical difficulties. Please try again',
      });
    }

    setIsSubmitting(false);
  };

  return (
    <>
      <Modal size="tiny" open={isOpen} onClose={onClose} closeIcon>
        <Modal.Content>
          Are you sure you want to change type to {product?.inventory ? 'On-Demand' : 'Stock'}
          {errorMessage.submit && <Message error content={errorMessage.submit} />}
        </Modal.Content>
        <Modal.Actions>
          <Button primary disabled={isSubmitting} onClick={handleClick} content="Save" />
          <Button onClick={onClose} content="Cancel" />
        </Modal.Actions>
      </Modal>
    </>
  );
};
