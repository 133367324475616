import * as React from 'react';
import {
  Button,
  Header,
  Checkbox,
  Form,
  Segment,
  Input,
  Label,
  Modal,
  Message,
  Divider,
} from 'semantic-ui-react';

interface ConvertMemoModalProps {
  open: boolean;
  order: any;
  onClose: any;
  onSubmit: (isClose: boolean) => void;
}

export const ConvertMemoModal: React.FunctionComponent<ConvertMemoModalProps> = (props) => {
  const { open, onClose } = props;

  return (
    <>
      <Modal open={open} onClose={onClose} size={'tiny'} closeIcon>
        <Modal.Header>Credit Memo</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <Input labelPosition="left" type="text" placeholder="Amount">
                <Label basic>$</Label>
                <input />
              </Input>
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button primary content="Convert" />
        </Modal.Actions>
      </Modal>
    </>
  );
};
