import * as React from 'react';
import { Button, Input, Dropdown, Segment, Form, Search } from 'semantic-ui-react';
import Feathers from './../../bootstrap/feathers';
import { FeathersDropdown, useDebounce } from '@inkcloud/components';

import styled from '@emotion/styled';

const SearchWrapper = styled.div`
  .ui.search {
    & > .results {
      overflow-y: auto;
      width: 100%;
      max-height: 25em;
      margin-top: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
`;

const { useState, useEffect } = React;

export interface FilterFormProps {
  value: any;
  onChange: Function;
  onReset: Function;
}

function initMapper(value) {
  const defaults = {
    organizationName: '',
    initInput: '',
  };

  return Object.keys(defaults).reduce((p, cur) => {
    if (!p[cur]) {
      p[cur] = defaults[cur];
    }
    return p;
  }, value);
}

export function FilterForm(props: FilterFormProps) {
  const { value: preInitValue } = props;

  const value = initMapper(preInitValue);
  const [initValue, setInitValue] = useState('');

  const debouncedFilter = useDebounce(initValue, 250);

  const [organization, setOrganization] = useState(null);
  const [isLoading, setIsloading] = useState(null);

  useEffect(() => {
    if (debouncedFilter) {
      const loadCustomer = async () => {
        setIsloading(true);
        const result: any = await Feathers.service('customers').find({
          query: {
            organizationName: { $LIKE: debouncedFilter },
            $select: ['_id', 'organizationName'],
            $sort: { organizationName: 1 },
            $limit: 100,
          },
        });

        setIsloading(false);
        // setOrganization(result && result.data.map(r => ({ text: r.organizationName, value: r._id })))
        setOrganization(
          result && result.data.map((r) => ({ key: r._id, title: r.organizationName, id: r._id }))
        );
      };
      loadCustomer();
    }
  }, [debouncedFilter]);

  const handleSearchChange = (v: any) => {
    setInitValue(v);
  };

  const handleResultSelect = (e, { result }) => {
    setInitValue(result.title);
    props.onChange &&
      props.onChange({
        ...props.value,
        organizationName: result.id,
      });
  };

  const handleReset = () => {
    setInitValue('');
    props.onReset && props.onReset();
  };

  return (
    <Segment>
      <Form size={'small'}>
        <Form.Group widths={'equal'}>
          <Form.Field>
            <SearchWrapper>
              <Search
                fluid
                placeholder="Organization Name..."
                loading={isLoading}
                value={initValue}
                onResultSelect={handleResultSelect}
                results={organization}
                onSearchChange={(e, data) => handleSearchChange(data.value)}
              />
            </SearchWrapper>
          </Form.Field>
        </Form.Group>
        <Form.Field>
          <Button size={'tiny'} content="Reset Form" onClick={handleReset} />
        </Form.Field>
      </Form>
    </Segment>
  );
}
