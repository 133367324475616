import * as React from 'react';
import { Button, Form, Icon, Popup, Label, Message } from 'semantic-ui-react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { FormattedDate, FormattedTime } from 'react-intl';

import { connect } from 'react-redux';

import {
  useList,
  ListPage,
  basicQueryMap,
  generateOperatorOptions,
  Operator,
} from '@inkcloud/components';

import Feathers from '../../bootstrap/feathers';

import { countryStateProvinces } from '../../common';

const filterQueryMap = (values) => {
  let filterRows = basicQueryMap(values, {});

  filterRows = {
    ...filterRows,
    stateProvince: filterRows?.stateProvince?.$elemMatch.value,
  };

  return filterRows;
};

interface ListProps {}

export interface ConnectedProps {
  user?: any;
}

// countryStateProvinces

const serviceName = 'customers';
const pathPrefix = '/customers/approval';

const { useEffect, useState } = React;

const countryCodes = ['US', 'AU', 'CA'];

const PreApproval: React.FunctionComponent<
  ListProps & ConnectedProps & RouteComponentProps<any>
> = (props) => {
  const {
    match: { params },
    location,
    history,
    user,
  } = props;

  const useListValues = useList({
    feathers: [
      'find',
      serviceName,
      [
        {
          query: {
            isApproved: { $ne: true },
          },
        },
      ],
    ],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  const [countryCode, setCountryCode] = useState('');

  useEffect(() => {
    const loadTenant = async () => {
      try {
        const res = await Feathers.service('/tenant-settings').get(user.business, {
          query: {
            $select: ['internationalization'],
          },
        });

        setCountryCode(res?.internationalization?.countryCode || 'US');
      } catch (e) {
        console.error(e);
      }
    };

    loadTenant();
  }, [user?.business]);

  let stateProvince = 'State/Province';

  if (countryCode === 'AU') {
    stateProvince = 'State/Territory';
  } else if (countryCode === 'CA') {
    stateProvince = 'Province';
  } else if (countryCode === 'US') {
    stateProvince = 'State';
  }

  async function getFilterDefinition() {
    const definition = [
      {
        label: 'First Name',
        value: 'primaryContact.firstName',
        operators: generateOperatorOptions([Operator.Like]),
      },
      {
        label: 'Last Name',
        value: 'primaryContact.lastName',
        operators: generateOperatorOptions([Operator.Like]),
      },
      {
        label: 'Company Name',
        value: 'organizationName',
        operators: generateOperatorOptions([Operator.Like]),
      },
      {
        label: 'Email',
        value: 'email',
        operators: generateOperatorOptions([Operator.Like]),
      },
      {
        label: 'Date',
        value: 'createdAt',
        operators: generateOperatorOptions([Operator.Between]),
        type: 'date',
      },
      {
        label: stateProvince,
        value: 'stateProvince',
        operators: generateOperatorOptions([Operator.Is]),
        valueOptions: countryStateProvinces(countryCode)?.map((i: any) => ({
          value: i.value,
          label: i.text,
        })),
        type: 'select',
      },
      {
        label: 'Zip',
        value: 'postalCode',
        operators: generateOperatorOptions([Operator.Like]),
      },
    ];

    const updateDefinition = definition.map((d) => {
      if (d.value === 'stateProvince') {
        if (countryCodes.includes(countryCode)) {
          return d;
        }

        delete d.type;
        delete d.valueOptions;

        return {
          ...d,
          operators: generateOperatorOptions([Operator.Like]),
        };
      }
      return d;
    });

    return updateDefinition.sort((a: any, b: any) => a.label.localeCompare(b.label));
  }

  const [errorMessage, setErrorMessage] = useState('');

  const tableDefintion = [
    {
      key: 'createdAt',
      label: 'Date',
      sortable: true,
      cell: (v, r) => (
        <Popup
          size="mini"
          content={<FormattedTime value={r.createdAt} />}
          trigger={
            <div>
              <FormattedDate value={r.createdAt} />
            </div>
          }
        />
      ),
    },
    {
      key: 'humanId',
      label: 'ID',
      cell: (v) => v,
    },
    {
      key: '',
      label: 'Name',
      cell: (v, r) => (
        <Link to={`/customers/${r._id}`}>
          {`${r?.primaryContact?.firstName ?? ''} ${r?.primaryContact?.lastName ?? ''}`}
        </Link>
      ),
    },
    {
      key: 'organizationName',
      label: 'Company Name',
      cell: (v) => v,
    },
    {
      key: '',
      label: 'Address',
      cell: (v, r) => (
        <>
          <div>{r.address1}</div>
          <div>{r.address2}</div>
          <div>
            {r.city} {r.stateProvince} {r.postalCode}
          </div>
        </>
      ),
    },
    {
      key: 'phone',
      label: 'Phone',
      cell: (v) => v,
    },
    {
      key: '',
      label: '',
      cell: (v, r) => (
        <>
          <Popup
            size="mini"
            content="View Details"
            align
            trigger={
              <Link to={`/customers/${r._id}`}>
                <Button size="mini" icon="edit" />
              </Link>
            }
          />
        </>
      ),
      textAlign: 'right',
    },
  ];

  const handleApproval = async (selected) => {
    setErrorMessage('');
    const customers = Object.keys(selected)
      .map((k) => ({ id: k, selected: selected[k] }))
      .filter((r) => r.selected)
      .map((r) => r.id);

    try {
      const res: any = await Feathers.service('/customers/approve').create({
        customers,
      });

      if (res) {
        useListValues.reload();
      }
    } catch (e) {
      const errMsg = e?.code < 500 ? e.message : 'An error occurred while approving customers';
      setErrorMessage(errMsg);
    }
  };

  return (
    <>
      <ListPage
        header="Pending Approval"
        subheader="Customers"
        useListValues={useListValues}
        history={history}
        pathPrefix={pathPrefix}
        tableDefintion={tableDefintion}
        withCheckboxes={true}
        selectedItemsBtnLabel="Approve"
        handleSelectedItems={handleApproval}
        getFilterDefinition={getFilterDefinition}
      />
      {errorMessage && <Message error content={errorMessage} />}
    </>
  );
};

const mapStateToProps = (state): ConnectedProps => ({
  user: state.auth.user || {},
});

export const Approval = connect(mapStateToProps)(PreApproval as any);
