import * as React from 'react';
import { Box, Button, Select, TableContainer, Table, Tbody, Thead, Tr, Td, Th, Text, Divider, Heading, Alert } from '@chakra-ui/react';
import Papa from 'papaparse';
import FilePicker from '../FilePicker/FilePicker';
import CSVUploadPreviewRecap from './CSVUploadPreviewRecap';

export interface ReturnData {
  data: any[];
  filename: string;
  mapping: { [key: string]: string };
}

export interface ICSVUploadPreviewProps {
  fields: ColumnFields[];
  onSubmit(data: ReturnData): void;
  maxPreviewRows?: number;
  maxParse?: number;
}

export interface ColumnFields {
  label: string;
  key: string;
  isRequired?: boolean;
  isDisabled?: boolean;
  alias?: string;
}

const availableOptions = (key: string, fields: ColumnFields[], selectedColumns: { [key: string]: string }) => {
  const options = fields.map(c => ({
    ...c,
    isDisabled: c.key !== key && Object.values(selectedColumns).includes(c.key) && !!key
  }));

  return options;
}

const CSVUploadPreview: React.FunctionComponent<ICSVUploadPreviewProps> = (props) => {
  const { fields, onSubmit, maxPreviewRows = 100, maxParse = 2000 } = props;

  const [parsedResult, setParsedResult] = React.useState<Papa.ParseResult<unknown>>();
  const [selectedColumns, setSelectedColumns] = React.useState<any>({});
  const [filename, setFilename] = React.useState<string>();

  const handleFilePickerOnChange = (file: File) => {
    Papa.parse(file, {
      header: true,
      preview: maxParse,
      complete: (results) => {
        setParsedResult(results);
        setSelectedColumns(results.meta.fields?.reduce((acc, f) => {
          // Try to map fields that have matching keys or alias
          const foundField = fields.find(ff => ff.key === f || ff.alias === f );
          console.log('we working ', f, 'and found', foundField);

          return ({
            ...acc,
            [f]: foundField ? foundField.key : undefined,
          })
        }, {}) ?? {});
        setFilename(file.name);
      }
    });
  }

  const handleSubmit = () => {
    onSubmit({
      filename: filename ?? '',
      mapping: selectedColumns,
      data: parsedResult?.data.map((d: any) => Object.keys(selectedColumns).filter(sc => selectedColumns[sc]).reduce((acc, key) => ({ ...acc, [selectedColumns[key]]: d[key] }), {})) ?? [],
    })
  }

  const handleSetColumn = (key: string, value: string) => {
    setSelectedColumns({ ...selectedColumns, [key]: value });
  }

  const isInvalid = fields.filter(c => c.isRequired).some(c => !Object.values(selectedColumns).includes(c.key));
  const showValidationMessage = isInvalid && Object.values(selectedColumns).filter(f => f).length > 0;
  const validationFields = fields.filter(c => c.isRequired && !Object.values(selectedColumns).includes(c.key))

  const optionsWithIgnore = [{ label: '[Ignore Or Select...]', key: '' }, ...fields,];

  const mappedColumns = fields.map(f => {
    const selectedColumn = Object.keys(selectedColumns).find(sc => selectedColumns[sc] === f.key);
    return {
      ...f,
      isSelected: !!selectedColumn,
      selectedColumn
    }
  })

  return (
    <Box borderWidth={1} borderColor={'gray.200'} bgColor={'gray.50'} p={8}>
      <Box>
        <Heading size={'sm'}>Select CSV</Heading>
        <Divider mt={4} mb={4} />
        <FilePicker
          accept={'.csv'}
          onChange={(file) => {
            if (file) {
              handleFilePickerOnChange(file);
            }
          }}
        />
      </Box>
      {parsedResult?.data.length && (
        <>
          <Divider my={8} />
          <Box >
            <Heading size={'sm'}>Review and map columns</Heading>
            <Divider mt={4} mb={4} />
            <Box height={375} p={0} borderWidth={1} borderColor={'gray.200'}>
              <TableContainer height={'100%'} overflowY={'scroll'} overflowWrap={'break-word'} >
                <Table size={'sm'} variant={'simple'} >
                  <Thead bgColor={'gray.100'} position="sticky" top={0} zIndex="docked">
                    <Tr bgColor={'gray.100'}  >
                      {parsedResult?.meta.fields?.map((field) => (
                        <Th bgColor={selectedColumns[field] ? 'green.100' : 'gray.100'} maxWidth={150} key={field}>{field}</Th>
                      ))}
                    </Tr>
                    <Tr bgColor={'white'} >
                      {parsedResult?.meta.fields?.map((field) => (
                        <Th py={4} bgColor={'white'} key={field}  >
                          <Select value={selectedColumns[field]} maxWidth={150} size={'sm'} onChange={e => handleSetColumn(field, e.target.value)}>
                            {availableOptions(field, optionsWithIgnore, selectedColumns).map(c => <option key={c.key} value={c.key} disabled={c.isDisabled} >{c.label} {c.isRequired && <Text color={'red'}>*</Text>} </option>)}
                          </Select>
                        </Th>
                      ))}
                    </Tr>
                  </Thead>
                  <Tbody bgColor={['white']}>
                    {parsedResult?.data?.slice(0, maxPreviewRows).map((row, idx) => (
                      <Tr key={idx}>
                        {parsedResult?.meta.fields?.map((field) => (
                          <Td maxWidth={150} key={field}><Text noOfLines={3} >{row ? row[field] : null}</Text></Td>
                        ))}
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
          <Box mt={8}>
            <Heading size={'sm'}>Recap</Heading>
            <Divider mt={4} mb={4} />
            <CSVUploadPreviewRecap
              filename={filename ?? ''}
              numRows={parsedResult?.data.length ?? 0}
              mappedColumns={mappedColumns}
            />
          </Box>
          <Box mt={8}>
            {showValidationMessage && (
              <Alert status={'warning'} mb={8}>
                <Text>Field{validationFields.length > 1 ? 's' : ''} {validationFields.map(c => `"${c.label}"`).join(', ')} {validationFields.length > 1 ? 'are' : 'is'} required.</Text>
              </Alert>
            )}
            <Button colorScheme={'blue'} disabled={isInvalid} onClick={handleSubmit} >Finalize</Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export default CSVUploadPreview;
