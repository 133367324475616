import * as React from 'react';
import { Input } from '@chakra-ui/react';
import { useForm, FormProvider, Controller, useController } from 'react-hook-form';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

interface DatePickerInputProps extends Partial<ReactDatePickerProps> {
  dateFormat: string;
  onChange: (data: any) => void;
  onBlur: () => void;
  value: string;
  name: string;
  size?: string;
  isInvalid?: boolean;
}

const DatePickerInput: React.FunctionComponent<DatePickerInputProps> = React.forwardRef(
  (props, ref: any) => {
    const { name, size, isInvalid, value, onChange, onBlur, dateFormat } = props;

    return (
      <DatePicker
        onChange={(data, e) => {
          let convertedValue;
          if (Array.isArray(data)) {
            convertedValue = data[0].toISOString();
          } else if (data instanceof Date) {
            convertedValue = data.toISOString();
          }

          onChange(convertedValue);
          onBlur();
        }}
        onBlur={() => {
          onBlur();
        }}
        dateFormat={dateFormat}
        selected={value ? new Date(value) : null}
        ref={(elem) => {
          if (elem) {
            ref((elem as any).input);
          }
        }}
        customInput={<Input size={size} isInvalid={isInvalid} onBlur={onBlur} />}
      />
    );
  }
);

export default DatePickerInput;
