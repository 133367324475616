// import * as React from 'react'
// import { Button, Input, Dropdown, Form } from 'semantic-ui-react';
// import { FeathersDropdown } from '@inkcloud/components';
// import DatePicker from 'react-datepicker';
// import Feathers from '../../../../bootstrap/feathers';
// import { values } from 'redux-form';

// const { useState, useEffect } = React;

// export interface FilterFormProps {
//   value: any;
//   onChange: Function;
//   onReset: Function;
// }

// function initMapper(value) {

//   let defaults = {
//     filename: '',
//     mimeType: [],
//   }

//   return Object.keys(defaults).reduce((p, cur) => {
//     if (!p[cur]) {
//       p[cur] = defaults[cur];
//     }
//     return p;
//   }, value)
// }

// const mimeTypeOptions = [
//   { value: 'image/png', text: 'png' },
//   { value: 'image/jpeg', text: 'jpg/jpeg' },
//   { value: 'application/pdf', text: 'pdf' },
//   { value: 'image/gif', text: 'gif' },
// ]

// export function FilterForm(props: FilterFormProps) {

//   const { value: preInitValue } = props;

//   let value = initMapper(preInitValue);

//   const { start, end } = value;

//   const onChange = (field, value) => {
//     props.onChange && props.onChange({ ...props.value, [field]: value })
//   };

//   return (
//     <>
//       <Form size={'small'}>
//         <Form.Group widths={'equal'}>
//           <Form.Field>
//             <Input
//               placeholder='File name...'
//               value={value.filename}
//               onChange={(e, data) => onChange('filename', data.value)} />
//           </Form.Field>
//           <Form.Field>
//             <Dropdown
//               selection fluid multiple
//               placeholder='Extention...'
//               value={value.mimeType}
//               options={mimeTypeOptions}
//               onChange={(e, data) => onChange('mimeType', data.value)} />
//           </Form.Field>
//           <Form.Field>
//             <DatePicker
// dateFormat="MM/dd/yyyy"
//               selected={start}
//               selectsStart
//               placeholderText="Start Date"
//               startDate={start}
//               endDate={end}
//               onChange={(date) => onChange('start', date)}
//             />
//           </Form.Field>
//           <Form.Field>
//             <DatePicker
// dateFormat="MM/dd/yyyy"
//               selected={end}
//               selectsEnd
//               placeholderText="End Date"
//               startDate={start}
//               endDate={end}
//               onChange={(date) => onChange('end', date)}
//             />
//           </Form.Field>
//         </Form.Group>
//         <Form.Field>
//           <Button size={'tiny'} content="Reset Form" onClick={() => props.onReset()} />
//         </Form.Field>
//       </Form>
//     </>
//   );
// }

import {
  useList,
  ListPage,
  basicQueryMap,
  generateOperatorOptions,
  Operator,
} from '@inkcloud/components';

import Feathers from '../../../../bootstrap/feathers';

const pageTypeOptions = [
  { value: 'basic', label: 'Basic' },
  { value: 'category', label: 'Category' },
  { value: 'product', label: 'Product' },
];

export default async function getFilterDefinition() {
  const definition = [
    {
      label: 'Name',
      value: 'name',
      operators: generateOperatorOptions([Operator.Like]),
    },
    {
      label: 'URL',
      value: 'url',
      operators: generateOperatorOptions([Operator.Like]),
    },
    {
      label: 'Template',
      value: 'template',
      operators: generateOperatorOptions([Operator.Like]),
    },
    {
      label: 'Category',
      value: 'category',
      operators: generateOperatorOptions([Operator.Is]),
      valueOptions: [],
      asyncValueOptions: async () =>
        Feathers.service('products-legacy/categories')
          .find({
            query: {
              $sort: { name: 1 },
              $np: 1,
              $select: ['_id', 'name', 'hrCode'],
            },
          })
          .then((results) => results.map((r) => ({ value: r._id, label: r.name }))),
      type: 'multi-select',
    },
    {
      label: 'Product',
      value: 'Product',
      operators: generateOperatorOptions([Operator.Is]),
      valueOptions: [],
      asyncValueOptions: async () =>
        Feathers.service('products-legacy/categories')
          .find({
            query: {
              $sort: { name: 1 },
              $np: 1,
              $select: ['_id', 'staticName', 'key'],
            },
          })
          .then((results) =>
            results.filter(
              (r) =>
                (r?.key || r?.staticName) && {
                  value: r._id,
                  label: `${r?.staticName} ${r?.key ? `- ${r.key}` : ''}`,
                }
            )
          ),
      type: 'multi-select',
    },
    {
      label: 'Page Type',
      value: 'type',
      operators: generateOperatorOptions([Operator.Is]),
      valueOptions: pageTypeOptions,
      type: 'select',
    },
  ];

  return definition.sort((a: any, b: any) => a.label.localeCompare(b.label));
}
