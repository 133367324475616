import * as React from 'react';
import { Form as FormikForm, FastField } from 'formik';

export const SemanticField = ({ component, ...fieldProps }) => (
  <FastField
    {...fieldProps}
    render={({
      field: { value, onBlur, ...field },
      form: { setFieldValue, setFieldTouched },
      ...props
    }) =>
      React.createElement(component, {
        ...fieldProps,
        ...field,
        ...props,
        ...(typeof value === 'boolean' ? { checked: value } : { value }),
        onChange: (e, { value: newValue, checked }) => {
          setFieldValue(fieldProps.name, newValue || checked);
          fieldProps.onChange && fieldProps.onChange(newValue || checked);
        },
        onBlur: (e, blurProps) =>
          blurProps ? setFieldTouched(fieldProps.name, blurProps.value) : onBlur(e),
      })
    }
  />
);
