import * as React from 'react';
import { Box, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';

interface ICSVUploadPreviewRecapProps {
  mappedColumns: any;
  filename: string;
  numRows: number;
}

const CSVUploadPreviewRecap: React.FunctionComponent<ICSVUploadPreviewRecapProps> = (props) => {
  const { mappedColumns, filename, numRows } = props;

  return (
    <Box borderWidth={1} borderColor={'gray.200'} bgColor={'white'} p={4}>
      <Table variant={'simple'} size={'sm'}>
        <Tbody>
          <Tr>
            <Th width={125}>File</Th><Td>{filename ?? ''}</Td>
          </Tr>
          <Tr>
            <Th width={125}>Num Rows</Th><Td>{numRows}</Td>
          </Tr>
          <Tr>
            <Th verticalAlign={'top'} width={125}>Mapping</Th>
            <Td>
              <Table variant={'simple'} size={'sm'}>
                <Thead>
                  <Tr>
                    <Th width={150}>Field</Th>
                    <Th>CSV Column</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {mappedColumns.map(mc => (
                    <Tr key={mc.key}>
                      <Td width={150}>{mc.label}</Td>
                      <Td>{mc.selectedColumn ?? '-'}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Td>
          </Tr>
        </Tbody>
      </Table>

    </Box>

  );
};

export default CSVUploadPreviewRecap;
