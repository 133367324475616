import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Radio,
  RadioGroup,
  Stack,
  Checkbox,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Switch,
  Input,
} from '@chakra-ui/react';

import { Select } from 'chakra-react-select';
import countries from 'world-countries';

import { countryStateProvinces } from '../../common';

const countryOptions = countries.map((country) => ({
  label: country.name.official,
  value: country.cca2,
}));

const countryInclude = ['US', 'CA', 'AU'];

export const initValues = {
  name: '',
  careOf: '',
  address1: '',
  address2: '',
  city: '',
  stateProvince: '',
  postalCode: '',
  countryCode: '',
  phone: '',
};

const schema = Yup.object()
  .shape({
    name: Yup.string().required('Name is equired').trim(),
    countryCode: Yup.string().required('Country is required').trim(),
    address1: Yup.string().required('Address 1 is required').trim(),
    city: Yup.string().required('City is required').trim(),
    stateProvince: Yup.string().required('State/Province is required').trim(),
    postalCode: Yup.string().required('Postal code is equired').trim(),
  })
  .required();

type initType = NonNullable<typeof initValues>;

interface ILocalDeliveryProps {
  tenant?: { countryCode: string };
  onChange: (v: initType) => void;
}

const LocalDelivery = forwardRef((props: ILocalDeliveryProps, ref) => {
  const { tenant, onChange } = props;

  const submitRef = useRef<HTMLFormElement>(null);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<initType>({
    defaultValues: {
      ...initValues,
      countryCode: tenant?.countryCode,
    },
    resolver: yupResolver(schema),
  });

  const handleSave: SubmitHandler<initType> = (data) => onChange(data);

  useImperativeHandle(ref, () => ({
    handleSubmit: () => submitRef?.current?.requestSubmit(),
  }));

  const watchCountry = watch('countryCode') as string;

  return (
    <form ref={submitRef} onSubmit={handleSubmit(handleSave)}>
      <Stack mb={3} spacing={7} direction="row">
        <FormControl isInvalid={!!errors.name}>
          <FormLabel>Name</FormLabel>
          <Input {...register('name')} />
          {errors?.name && <FormErrorMessage>{errors.name?.message}</FormErrorMessage>}
        </FormControl>
        <FormControl>
          <FormLabel>Care of</FormLabel>
          <Input {...register('careOf')} />
        </FormControl>
      </Stack>
      <Stack mb={3} spacing={7} direction="row">
        <FormControl>
          <FormLabel>Country</FormLabel>
          <Select
            options={countryOptions}
            value={countryOptions.find((o) => o.value === watchCountry)}
            onChange={(e) => setValue('countryCode', e?.value as string)}
          />
        </FormControl>
      </Stack>
      <Stack mb={3} spacing={7} direction="row">
        <FormControl isInvalid={!!errors?.address1}>
          <FormLabel>Address 1</FormLabel>
          <Input {...register('address1')} />
          {errors?.address1 && <FormErrorMessage>{errors.address1?.message}</FormErrorMessage>}
        </FormControl>
        <FormControl>
          <FormLabel>Address 2</FormLabel>
          <Input {...register('address2')} />
        </FormControl>
      </Stack>
      <Stack mb={3} spacing={7} direction="row">
        <FormControl isInvalid={!!errors?.city}>
          <FormLabel>City</FormLabel>
          <Input {...register('city')} />
          {errors?.city && <FormErrorMessage>{errors.city?.message}</FormErrorMessage>}
        </FormControl>
        <FormControl isInvalid={!!errors?.stateProvince}>
          <FormLabel>State/Province</FormLabel>
          {countryInclude.includes(watchCountry) ? (
            <Select
              options={countryStateProvinces(watchCountry)?.map(
                (c: { text: string; value: string }) => ({ label: c.text, value: c.value })
              )}
              onChange={(e) => setValue('stateProvince', e?.value as string)}
            />
          ) : (
            <Input type="text" {...register('stateProvince')} />
          )}
          {errors?.stateProvince && (
            <FormErrorMessage>{errors.stateProvince?.message}</FormErrorMessage>
          )}
        </FormControl>
        <FormControl isInvalid={!!errors?.postalCode}>
          <FormLabel>Postal Code</FormLabel>
          <Input {...register('postalCode')} />
          {errors?.postalCode && <FormErrorMessage>{errors.postalCode?.message}</FormErrorMessage>}
        </FormControl>
        <FormControl>
          <FormLabel>Phone</FormLabel>
          <Input {...register('phone')} />
        </FormControl>
      </Stack>
    </form>
  );
});

export default LocalDelivery;
