import React, { useState, useEffect } from 'react';

import { Select, ActionMeta } from 'chakra-react-select';
import {
  FormControl,
  FormLabel,
  Checkbox,
  FormErrorMessage,
  FormHelperText,
  Alert,
  Button,
  Stack,
} from '@chakra-ui/react';

import { PreLoader, To, PageHeaderRow } from '@inkcloud/components';
import type { ModelTypes } from '@inkcloud/icapi-types';

import Feathers from '../../../bootstrap/feathers';

interface LocalizationFormProps {
  localizationProp: any;
  isLoading: boolean;
  errorMessage: string;
  onSubmit: (v: any) => void;
}

const mapKeys = (obj) =>
  obj ? Object.keys(obj).map((key) => ({ value: key, label: obj[key] })) : [];

type optionType = { label: string; value: string };
export const LocalizationForm: React.FunctionComponent<LocalizationFormProps> = (props) => {
  const { localizationProp, onSubmit, isLoading, errorMessage } = props;

  const [localization, setLocalization] = useState(localizationProp);
  const [internationalization, setInternationalization] = useState<
    ModelTypes.TenantSettings['internationalization']
  >({});

  const [isLoadingOptions, setIsLoadingOptions] = useState(false);

  useEffect(() => {
    const loadInternationalization = async () => {
      setIsLoadingOptions(true);
      try {
        const res = await Feathers.service('tenant-settings/intenationalization-options').find({
          query: {},
        });
        setInternationalization(res);
      } catch (e) {
        console.error(e);
      }

      setIsLoadingOptions(false);
    };

    loadInternationalization();
  }, []);

  const handleChange = (e: optionType | null, d: ActionMeta<optionType>) => {
    const { value } = e as optionType;
    setLocalization({ ...localization, [d?.name as string]: value });
  };

  return (
    <>
      <Stack spacing={7} mb={3} direction="row">
        <FormControl>
          <FormLabel>Locale</FormLabel>
          <Select
            name="locale"
            isLoading={isLoadingOptions}
            options={internationalization?.locale ? mapKeys(internationalization.locale) : []}
            onChange={handleChange}
            value={mapKeys(internationalization?.locale)?.find(
              (i) => i.value === localization?.locale
            )}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Currency</FormLabel>
          <Select
            name="currency"
            isLoading={isLoadingOptions}
            options={internationalization?.currency ? mapKeys(internationalization.currency) : []}
            onChange={handleChange}
            value={mapKeys(internationalization?.currency)?.find(
              (i) => i.value === localization?.currency
            )}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Country Code</FormLabel>
          <Select
            name="countryCode"
            isLoading={isLoadingOptions}
            options={
              internationalization?.countryCode ? mapKeys(internationalization.countryCode) : []
            }
            onChange={handleChange}
            value={mapKeys(internationalization?.countryCode)?.find(
              (i) => i.value === localization?.countryCode
            )}
          />
        </FormControl>
      </Stack>

      {/* <Form loading={isLoading} onSubmit={() => onSubmit(localization)}>
        <Form.Field>
          <label>Locale</label>
          <Dropdown
            selection
            name='locale'
            loading={isLoadingOptions}
            value={localization && localization.locale}
            options={internationalization && internationalization.locale ? mapKeys(internationalization.locale) : []}
            placeholder='Locale...'
            onChange={(e, data) => handleChange(data)}
          />
        </Form.Field>
        <Form.Field>
          <label>Currency</label>
          <Dropdown
            selection
            name='currency'
            loading={isLoadingOptions}
            value={localization && localization.currency}
            options={internationalization && internationalization.currency ? mapKeys(internationalization.currency) : []}
            placeholder='Currency...'
            onChange={(e, data) => handleChange(data)}
          />
        </Form.Field>
        <Form.Field>
          <label>Country Code</label>
          <Dropdown
            selection
            name='countryCode'
            loading={isLoadingOptions}
            value={localization && localization.countryCode}
            options={internationalization && internationalization.countryCode ? mapKeys(internationalization.countryCode) : []}
            placeholder='Country Code'
            onChange={(e, data) => handleChange(data)}
          />
        </Form.Field>
        <Button primary disabled={isLoading} content="Save" />
      </Form> */}
      {errorMessage && (
        <Alert mb={3} status="error">
          {errorMessage}
        </Alert>
      )}

      <Button
        colorScheme="blue"
        isLoading={isLoading}
        isDisabled={isLoading}
        onClick={() => onSubmit(localization)}
      >
        Save
      </Button>
    </>
  );
};
