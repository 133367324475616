import * as React from 'react';
import { Button, Form, Input, Message } from 'semantic-ui-react';
import { Formik, Form as FormikForm, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { SemanticField } from '../../../common/Form';
import { slugify2 as slugifyKey } from '../../../common';
import { EntityDropdownWrapper2 } from '../../EntityDropdown';

interface VariantValueFormProps {
  initialValues: any;
  onSubmit: Function;
  isSubmitting?: boolean;
  errorMessage: string;
}

const typeDropdown = EntityDropdownWrapper2(
  '/products-legacy/variant-types',
  {
    query: {
      $np: 1,
      $sort: { name: 1 },
      $select: ['_id', 'name'],
    },
  },
  {
    resultMap: (row) => {
      return { value: row._id, text: row.name };
    },
  }
);

export const VariantValueForm: React.FC<VariantValueFormProps> = (props) => {
  const { errorMessage } = props;
  const CustomerContactSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
  });

  return (
    <Formik
      initialValues={props.initialValues}
      validationSchema={CustomerContactSchema}
      onSubmit={(values, { setSubmitting }) => {
        props.onSubmit(values);
      }}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
          setFieldValue,
          setFieldTouched,
        } = props;

        const handleSlugify = (v) => setFieldValue('key', slugifyKey(v));

        return (
          <FormikForm
            className={`ui form ${props.isSubmitting && !errorMessage ? 'loading' : ''} ${
              errorMessage ? 'error' : ''
            }`}
          >
            <div className="field">
              <label>Type</label>
              <SemanticField
                name={'type'}
                component={typeDropdown}
                fluid
                selection
                label="Type"
                placeholder="Type..."
              />
            </div>

            <Form.Group widths="equal">
              <Form.Field>
                <SemanticField
                  name={'name'}
                  label="Name"
                  component={Form.Input}
                  onChange={(v) => {
                    if (!values?._id) {
                      setFieldValue('key', slugifyKey(v));
                    }
                  }}
                />
                <ErrorMessage
                  render={(msg) => <Message negative size="tiny" content={msg} />}
                  name="name"
                />
              </Form.Field>
              <Form.Field>
                <SemanticField
                  name={'key'}
                  label="Key"
                  component={Form.Input}
                  onChange={handleSlugify}
                />
                <ErrorMessage
                  render={(msg) => <Message negative size="tiny" content={msg} />}
                  name="key"
                />
              </Form.Field>
              <Form.Field>
                <SemanticField name={'priority'} label="Priority" component={Form.Input} />
                <ErrorMessage component="div" name="priority" />
              </Form.Field>
            </Form.Group>
            {errorMessage && <Message error content={errorMessage} />}

            <Button primary type="submit" disabled={isSubmitting && !errorMessage}>
              Save
            </Button>
          </FormikForm>
        );
      }}
    </Formik>
  );
};
