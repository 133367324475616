import * as React from 'react';
import { connect } from 'react-redux';
import {
  InjectedFormProps,
  change,
  Field,
  reduxForm,
  formValueSelector,
  FormProps,
} from 'redux-form';
import { Button, Divider, Form, Input, Message, Radio } from 'semantic-ui-react';
import Feathers from './../../../bootstrap/feathers';
import {
  semanticFormField,
  semanticFormToggleField,
  semanticFormRadioGroupField,
  semanticFormCheckboxGroupField,
  semanticFormDropdownField,
} from './../../../common';
import { EntityDropdownWrapper2 } from './../../EntityDropdown';
import Dropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown';
import * as validator from 'validator';

export interface AppState {
  addOns: any[];
}

const validate = (values) => {
  let errors = {};
  if (values.fieldType === 'price') {
    if (values.valueType === 'fixed') {
      if (!validator.isCurrency(`${values.value}`, { allow_negatives: false })) {
        errors = { ...errors, value: 'Enter a valid, positive amount' };
      }
    } else if (values.valueType === 'percentage') {
      if (!validator.isFloat(`${values.value}`, { min: 0.01, max: 1000 })) {
        errors = { ...errors, value: 'Enter a valid percentage' };
      }
    }
  } else if (values.fieldType === 'add-on') {
    if (
      (!values.enabledAddOns && !values.disabledAddOns) ||
      (values?.enabledAddOns?.length === 0 && values?.disabledAddOns?.length === 0)
    ) {
      errors = {
        ...errors,
        enabledAddOns: 'You must select at least one add on to enable or disable',
        disabledAddOns: 'You must select at least one add on to enable or disable',
      };
    }
  } else if (values.fieldTpye === 'turnaround') {
  } else if (values.fieldTpye === 'add-on') {
  } else if (values.fieldTpye === 'weight') {
  } else if (values.fieldTpye === 'design') {
  } else if (values.fieldType === 'artworkSlots') {
  }

  return errors;
};

const artworkSequenceOptions = [
  { label: 'Front', value: 'FRONT' },
  { label: 'Back', value: 'BACK' },
  { label: 'Spot UV Front', value: 'SPOTUVFRONT' },
  { label: 'Spot UV Back', value: 'SPOTUVBACK' },
  { label: 'Multi-page PDF', value: 'MULTIPAGEPDF' },
  { label: 'Die Line', value: 'DIELINE' },
];

export class ActionForm extends React.Component<InjectedFormProps, AppState> {
  constructor(props: InjectedFormProps) {
    super(props);
    this.state = {
      addOns: [],
    };
  }

  componentDidMount() {
    Feathers.service('/products-legacy/add-ons-base')
      .find({})
      .then((addOns: any) => {
        this.setState({ ...this.state, addOns });
      });
  }

  render() {
    const { handleSubmit, pristine, reset, submitting } = this.props;

    let fieldAction = null;
    // { this.props.fieldType }
    if (this.props.fieldType === 'price') {
      fieldAction = (
        <Form.Group inline>
          {/* Product Update */}
          <Field
            name="actionType"
            component={semanticFormDropdownField}
            as={Dropdown}
            label={''}
            inline
            options={[
              { text: 'Increase', value: 'increase' },
              { text: 'Decrease', value: 'decrease' },
            ]}
          />
          <span style={{ marginRight: 15 }}>Prices by</span>

          <Field
            name="valueType"
            component={semanticFormDropdownField}
            as={Dropdown}
            label={''}
            inline
            options={[
              { text: 'Fixed amount', value: 'fixed' },
              { text: 'Percentage', value: 'percentage' },
            ]}
          />
          <span style={{ marginRight: 15 }}>of</span>

          <Field
            name="value"
            component={semanticFormField}
            as={Input}
            // size={'mini'}
            inline
            placeholder={'Amount...'}
          />

          {this.props.actionType === 'increase' && (
            <Field
              name="method"
              component={semanticFormDropdownField}
              as={Dropdown}
              inline
              options={[
                { text: 'No rounding', value: 'no' },
                { text: 'Round to nearest whole number', value: 'USE_CEILING_VALUE' },
                { text: 'Round to nearest tenth', value: 'USE_TENTH_OF_A_DOLLAR' },
              ]}
            />
          )}
        </Form.Group>
      );
    } else if (this.props.fieldType === 'add-on') {
      fieldAction = (
        <div>
          <Form.Field>
            <label>Choose all the add-ons you want to add/enable</label>
            <Field
              name="enabledAddOns"
              component={semanticFormDropdownField}
              as={Dropdown}
              selection
              multiple={true}
              options={this.state.addOns.map((a) => ({ text: a.name, value: a._id }))}
              fluid
              placeholder="Enabled Add Ons..."
            />
          </Form.Field>
          <Form.Field>
            <label>Choose all the add-ons you want to remove/disable</label>
            <Field
              name="disabledAddOns"
              component={semanticFormDropdownField}
              as={Dropdown}
              selection
              multiple={true}
              options={this.state.addOns.map((a) => ({ text: a.name, value: a._id }))}
              fluid
              placeholder="Disabled Add Ons..."
            />
          </Form.Field>
        </div>
      );
    } else if (this.props.fieldType === 'turnaround') {
      fieldAction = (
        <Form.Group>
          <Field
            name="timeMin"
            component={semanticFormField}
            as={Form.Input}
            label={'Minimum Time'}
            inline
            placeholder={'Amount...'}
          />
          <Field
            name="timeMax"
            component={semanticFormField}
            as={Form.Input}
            label={'Maximum Time'}
            inline
            placeholder={'Amount...'}
          />
        </Form.Group>
      );
    } else if (this.props.fieldType === 'weight') {
    } else if (this.props.fieldType === 'rush-service') {
      fieldAction = (
        <Form.Group>
          <Field
            name="isRushEnabled"
            component={semanticFormToggleField}
            as={Form.Checkbox}
            label={'Enable Rush Service'}
            inline
          />
        </Form.Group>
      );
    } else if (this.props.fieldType === 'design') {
      fieldAction = (
        <Form.Group>
          <Field
            name="isDesignEnabled"
            component={semanticFormToggleField}
            as={Form.Checkbox}
            label={'Enable Design'}
            inline
          />

          <Field
            name="designPrice"
            component={semanticFormField}
            as={Input}
            label={'Minimum Time'}
            inline
            placeholder={'Amount...'}
          />
        </Form.Group>
      );
    } else if (this.props.fieldType === 'artworkSlots') {
      fieldAction = (
        <Form.Group>
          <Field
            name="artworkSlots"
            component={semanticFormCheckboxGroupField}
            options={artworkSequenceOptions}
            label={'Set slots'}
          />
        </Form.Group>
      );
    }

    return (
      <Form onSubmit={handleSubmit}>
        <Form.Field>
          <label>What do you want to update?</label>
        </Form.Field>
        <Field
          name="fieldType"
          component={semanticFormRadioGroupField}
          label={''}
          options={[
            { label: 'Prices', value: 'price' },
            { label: 'Add Ons', value: 'add-on' },
            { label: 'Turnaround Time', value: 'turnaround' },
            // { label: 'Weights', value: 'weight'},
            { label: 'Design', value: 'design' },
            { label: 'Rush Service', value: 'rush-service' },
            { label: 'Artwork Slots', value: 'artworkSlots' },
          ]}
        />
        <Divider />
        {fieldAction}
        <br />
        <Form.Button primary>Next Step</Form.Button>
      </Form>
    );
  }
}

const formName = 'massUpdatorAction';
const selector = formValueSelector(formName);

const mapStateToProps = (state) => ({
  fieldType: selector(state, 'fieldType'),
  actionType: selector(state, 'actionType'),
});

const reduxedForm = reduxForm({
  form: formName,
  validate,
})(ActionForm);

const mapDispatchToProps = (dispatch, ownProps) => ({
  changeFieldValue: (field, value) => dispatch(change(formName, field, value)),
});
export default connect(mapStateToProps, mapDispatchToProps)(reduxedForm) as any;
