import * as React from 'react';
import { Style } from '@react-pdf/types';
import { Text } from '@react-pdf/renderer';

import { BillTo as BillToGeneral, BillToValue } from '..';
import { InvoiceContext } from './InvoiceContext';

export interface IBillToProps {
  style?: Style;
  textStyle?: Style;
}

export function BillTo(props: IBillToProps) {
  const ctx = React.useContext(InvoiceContext);
  const {
    dataCtx: {
      invoice: { billingAddress },
    },
  } = ctx;
  const { style, textStyle } = props;

  const value: BillToValue = billingAddress;

  return <BillToGeneral value={value} style={style} textStyle={textStyle} />;
}
