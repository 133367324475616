import * as React from 'react';
import { Switch, Route } from 'react-router-dom';
import feathers from '../../../../bootstrap/feathers';

import { List } from './List';
import { Detail } from './Detail';

const { useState, useEffect } = React;

const routes = (props) => {
  const [vendorOptions, setVendorOptions] = useState([]);
  useEffect(() => {
    const loadVendors = async () => {
      const results = await (feathers.service('production/vendors') as any).find({
        query: { $np: 1, $sort: { companyName: 1 } },
      });
      setVendorOptions(results.map((m) => ({ ...m, text: m.companyName, value: m._id })));
    };

    loadVendors();
  }, []);

  return (
    <Switch>
      <Route exact path="/settings/production/resources" component={List} />
      <Route
        exact
        path="/settings/production/resources/add"
        component={(innerProps) => <Detail {...innerProps} vendorOptions={vendorOptions} />}
      />
      <Route
        exact
        path="/settings/production/resources/:id"
        component={(innerProps) => <Detail {...innerProps} vendorOptions={vendorOptions} />}
      />
    </Switch>
  );
};

export default routes;
