import * as React from 'react';
import { Button, Card, Grid, Header, Menu, Message, Segment, Table } from 'semantic-ui-react';
import { FormattedDate } from 'react-intl';
import * as moment from 'moment';

export interface OrderInfoTableProps {
  order: any;
  raised: boolean;
}

export default class OrderInfoTable extends React.PureComponent<OrderInfoTableProps, any> {
  render() {
    const { order } = this.props;
    let customer = null;
    if (order) {
      customer = order.customer;
    }

    const filteredTrackingNumber =
      order.actualShipments &&
      order.actualShipments.length > 0 &&
      order.actualShipments.filter((a) => !a.isVoid);

    return (
      <Segment raised={this.props.raised} size={'small'} loading={order === null}>
        {order && (
          <Grid columns={'2'} divided>
            <Grid.Row>
              <Grid.Column>
                <Table basic="very" celled size={'small'}>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>ID</Table.Cell>
                      <Table.Cell>{order.customer.humanId}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Created</Table.Cell>
                      <Table.Cell>
                        <FormattedDate value={order.createdAt} />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Company</Table.Cell>
                      <Table.Cell>
                        {customer.organizationName
                          ? customer.organizationName
                          : customer.primaryContact &&
                            `${customer.primaryContact.firstName} ${customer.primaryContact.lastName}`}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Contact</Table.Cell>
                      <Table.Cell>
                        {customer.primaryContact &&
                          `${customer.primaryContact.firstName} ${customer.primaryContact.lastName}`}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Email</Table.Cell>
                      <Table.Cell>{customer.email}</Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Grid.Column>
              <Grid.Column>
                <Table basic="very" celled size={'small'}>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>Ship Method</Table.Cell>
                      <Table.Cell>
                        {order.shipment
                          ? `${order.shipment.providerName || 'N/A'} ${
                              order.shipment.serviceDescription || 'N/A'
                            }`
                          : 'Pickup'}
                      </Table.Cell>
                    </Table.Row>

                    {order.shipment && (
                      <Table.Row>
                        <Table.Cell>Ship To</Table.Cell>
                        <Table.Cell>
                          <div>{order.shipment.shipToName}</div>
                          <div>{order.shipment.shipToCareOf}</div>
                          <div>{order.shipment.shipToAddress1}</div>
                          <div>{order.shipment.shipToAddress2}</div>
                          <div>
                            {order.shipment.shipToCity} {order.shipment.shipToStateProvince}{' '}
                            {order.shipment.shipToPostalCode} {order.shipment.shipToCountryCode ?? ''}
                          </div>
                        </Table.Cell>
                      </Table.Row>
                    )}
                    {filteredTrackingNumber.length > 0 && (
                      <Table.Row>
                        <Table.Cell>Tracking Number</Table.Cell>
                        <Table.Cell>
                          {filteredTrackingNumber.map((a: any, idx: number, arr: any[]) => (
                            <React.Fragment key={idx}>
                              {`${a.trackingNumber}${idx < arr.length - 1 ? ', ' : ''}`}
                            </React.Fragment>
                          ))}
                        </Table.Cell>
                      </Table.Row>
                    )}
                  </Table.Body>
                </Table>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
      </Segment>
    );
  }
}
