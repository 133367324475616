import React from 'react';
import { Box } from '@chakra-ui/react';

interface IInputWrapperProps {
  height?: string;
  children: React.ReactNode;
}

export default function InputWrapper(props: IInputWrapperProps) {
  const { height = 'var(--chakra-sizes-8)' } = props;
  return (
    <Box
      sx={{
        '.form-masked, .form-control-cleave': {
          width: '100%',
          outline: '2px solid transparent',
          pos: 'relative',
          fontSize: 'var(--chakra-fontSizes-sm)',
          height,
          border: '1px solid',
          borderColor: 'var(--chakra-colors-gray-300)',
          background: 'inherit',
          paddingInlineStart: 'var(--chakra-sizes-3)',
          paddingInlineEnd: 'var(--chakra-sizes-3)',
          borderRadius: 'var(--chakra-radii-md)',
        },
      }}
    >
      {props.children}
    </Box>
  );
}
