import * as React from 'react';
import { Modal, Button, Message } from 'semantic-ui-react';

export interface IDuplicateModalProps {
  isOpen: boolean;
  isSubmitting: boolean;
  onClose: () => void;
  onSubmit: () => void;
  errorMessage: string;
  name?: string;
}

export const DuplicateModal: React.FunctionComponent<IDuplicateModalProps> = (props) => {
  const { isOpen, isSubmitting, onClose, onSubmit, errorMessage, name } = props;

  return (
    <Modal open={isOpen} onClose={onClose} closeIcon size="tiny">
      <Modal.Header>Duplicate Design Template {name ? `- ${name}` : ''}</Modal.Header>
      <Modal.Content>
        <p>Are you sure you want to duplicate this design template?</p>
        {errorMessage && <Message error content={errorMessage} />}
      </Modal.Content>
      <Modal.Actions>
        <Button
          primary
          disabled={isSubmitting}
          loading={isSubmitting}
          onClick={onSubmit}
          content="Confirm"
        />
        <Button onClick={onClose} content="Cancel" />
      </Modal.Actions>
    </Modal>
  );
};
