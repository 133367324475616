import * as React from 'react';
import {
  Button,
  Checkbox,
  Dimmer,
  Loader,
  Header,
  Grid,
  Message,
  Menu,
  Table,
  Segment,
} from 'semantic-ui-react';

export interface ItemsProps {
  items: any[];
  selectedToDelete: string[];
  // selectedToDeleteAll: boolean;
  handleRemoveCheckboxClick: any;
}

export interface ItemsState {}

export default class Items extends React.Component<ItemsProps, ItemsState> {
  constructor(props: ItemsProps) {
    super(props);
    this.state = {};
  }

  render() {
    const items = this.props.items.map((i) => {
      return (
        <Table.Row>
          <Table.Cell>
            <Checkbox
              checked={this.props.selectedToDelete.includes(i._id)}
              value={i._id}
              onClick={this.props.handleRemoveCheckboxClick}
            />
          </Table.Cell>
          <Table.Cell>Business Cards</Table.Cell>
          <Table.Cell>
            14pt Cover UV Both 4/4 2 x 3.5 1000 pieces
            <br />
            Round Corners
          </Table.Cell>
          <Table.Cell textAlign={'right'}>$39.99</Table.Cell>
        </Table.Row>
      );
    });

    return (
      <div>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                <Checkbox onClick={this.props.handleRemoveCheckboxClick} />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{items}</Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.Cell>
                <Button primary content={'Delete'} />
              </Table.Cell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </div>
    );
  }
}
