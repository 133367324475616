import * as React from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';

export interface DesignSelectProps {
  selected: any;
  onChange: Function;
}

const style = { margin: '12px 0' };

export interface DesignSelectState {}

const options = [
  { value: 0, label: 'No Design' },
  { value: 1, label: 'Wants Design' },
];

export class DesignSelect extends React.Component<DesignSelectProps, DesignSelectState> {
  constructor(props: DesignSelectProps) {
    super(props);

    this.state = {};
  }

  handleChange = (value) => {
    this.props.onChange(value);
  };

  public render() {
    return (
      <div style={style}>
        <label>Design</label>
        <Select
          options={options}
          defaultValue={options.find((o) => o.value === this.props.selected)}
          value={this.props.selected}
          onChange={this.handleChange}
        />
      </div>
    );
  }
}
