export const apiServices = {
  'address-book': 'addressBook',
  'customers': 'customers',
  'users': 'users',
  'translators': 'translators',
  '/translators/assign-order-item': 'translatorsAssignOrderItem',
  'tenants/portals': 'tenants/portals',

  'estimates': 'estimates',
  'invoices': 'invoices',
  'orders': 'orders',
  'order-items': 'orderItems',

  '/customers/notes': 'customersNotes',
  '/orders/notes': 'ordersNotes',
  '/payments-gateway/create-card': 'paymentsGatewayCreateCard',
  'printable/job-ticket': 'printableJobTicket',

  '/inventory/ledger': 'inventoryLedger',
  '/inventory/locations': 'inventoryLocations',
  '/inventory/incoming-tickets': 'incomingTickets',

  '/inventory/movements': 'inventoryMovements',
  '/inventory/operations': 'inventoryOperations',

  'microsites': 'microsites',
  'microsites/customers': 'micrositeCustomers',
  'microsites/products': 'micrositeProducts',
  'microsites/customer/groups': 'micrositeGroups',


  '/notification/v2/log': 'notificationLog',

  '/design-templates': 'designTemplates',
  'design-templates/fonts': 'designTemplateFonts',

  '/microsites/address-books': 'micrositeAddressBook',

  '/store-front/content/pages': 'contentPages',
  '/store-front/microsites/content/pages': 'micrositeContentPages',
  '/store-front/microsites/content/menus': 'micrositeContentMenus',

  'production/batches': 'productionBatches',
  'production/outsource': 'productionOutsource',
  'production/vendors': 'productionVendors',
  '/production-batches-status': 'productionBatchesStatus',
  'production/channels': 'productionChannels',

  'production/resources': 'productionResources',
  '/production/pending-order-items': 'productionPendingOrderItems',
  'products-legacy': 'products',
  'products-legacy/categories': 'productsCategories',
  'products-legacy/attributes': 'productsAttributes',
  'products-legacy/attribute-types': 'productsAttributeTypes',
  'products-legacy/variant-types': 'productsVariantTypes',
  'products-legacy/variant-type-values': 'productsVariantValues',
  'products-legacy/notes': 'productNotes',

  '/payments-gateway/delete-card': 'paymentGatewayDeleteCard',

  // 'products-legacy': 'products-legacy',
  // 'products-legacy/categories': 'products-legacy/categories',
  // 'products-legacy/attributes': 'products-legacy/attributes',
  // 'products-legacy/attribute-types': 'products-legacy/attribute-types',
  'products-legacy/portal-products': 'products-legacy/portal-products',
  'sales-people': 'salesPeople',
  '/sales-tax/tax-exemption-review': 'salesTaxExemptionReview',
  'tenant-settings/calculator': 'tenantSettingsCalculator',
  'tenant-settings/contact-info': 'tenantSettingsContactInfo',
  'tenant-settings/shipping': 'tenantSettingsShipping',
  'tenant-settings/payment': 'tenantSettingsPayment',
  'tenant-settings/tax': 'tenantSettingsTax',
  'xmpie/stores': 'xmpie/stores',
  'xmpie/products': 'xmpie/products',
};
