import * as React from 'react';
import { Modal, ModalProps, Table } from 'semantic-ui-react';
import Feathers from '../../../../bootstrap/feathers';

export interface EmptyModalProps {
  handleClose: ModalProps['onClose'];
  open: boolean;
}

const initialState = { locations: [] };

type State = typeof initialState;
export class EmptyModal extends React.Component<EmptyModalProps, State> {
  state = initialState;
  async componentDidMount() {
    const locations = (await Feathers.service('/inventory/operations').create({
      operation: 'GET_EMPTY_LOCATIONS',
    })) as any[];

    this.setState({ ...this.state, locations });
  }
  public render() {
    const { props } = this;

    return (
      <Modal size={'mini'} open={props.open} onClose={props.handleClose}>
        <Modal.Header>Empty Locations</Modal.Header>
        <Modal.Content>
          <Table size={'small'}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.state.locations &&
                this.state.locations.map((location: any) => {
                  return (
                    <Table.Row>
                      <Table.Cell>{location.locationName}</Table.Cell>
                    </Table.Row>
                  );
                })}
            </Table.Body>
          </Table>
        </Modal.Content>
      </Modal>
    );
  }
}
