import * as React from 'react';
import { Button, Form, Input, Dropdown, Segment } from 'semantic-ui-react';
import { Formik, Form as FormikForm, Field, ErrorMessage, FieldProps } from 'formik';
import * as Yup from 'yup';
import { SemanticField } from '../../../common/Form';
import { FeathersDropdown } from '@inkcloud/components';

interface DashboardUserFormProps {
  initialValues: any;
  onSubmit: Function;
  isEdit: boolean;
  isSubmitting?: boolean;
}

export const DashboardUserForm: React.FC<DashboardUserFormProps> = (props) => {
  const { isEdit } = props;
  const DashboardUserSchema = Yup.object().shape({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    password: isEdit ? Yup.string() : Yup.string().required('Required'),
    email: Yup.string().required('Required'),
  });

  return (
    <Formik
      initialValues={props.initialValues}
      validationSchema={DashboardUserSchema}
      onSubmit={(values, { setSubmitting }) => {
        props.onSubmit(values);
      }}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
          setFieldValue,
          setFieldTouched,
        } = props;

        return (
          <FormikForm className={`ui form ${isSubmitting && 'loading'}`}>
            <Form.Group widths="equal">
              <Form.Field>
                <SemanticField name={'firstName'} label="First Name" component={Form.Input} />
                <ErrorMessage component="div" name="firstName" />
              </Form.Field>
              <Form.Field>
                <SemanticField name={'lastName'} label="Last Name" component={Form.Input} />
                <ErrorMessage component="div" name="lastName" />
              </Form.Field>
            </Form.Group>

            <Form.Group widths="equal">
              <Form.Field>
                <SemanticField name={'email'} label="Email" component={Form.Input} />
                <ErrorMessage component="div" name="email" />
              </Form.Field>
              {!isEdit && (
                <Form.Field>
                  <SemanticField
                    type="password"
                    name={'password'}
                    label="Password"
                    component={Form.Input}
                  />
                  <ErrorMessage component="div" name="password" />
                </Form.Field>
              )}
            </Form.Group>

            <Form.Field>
              <Field
                name="roles2"
                render={({ field }: FieldProps<any>) => (
                  <FeathersDropdown
                    search
                    selection
                    clearable
                    multiple
                    placeholder="Roles..."
                    serviceName="/user-roles"
                    query={{
                      $sort: { name: 1 },
                      $np: 1,
                    }}
                    resultMapper={(r) => ({ key: r._id, value: r._id, text: `${r.name}` })}
                    {...field}
                    // defaultValue={values.manager && !values.manager.isDeleted ? values.manager._id : null}
                    onChange={(e, data) =>
                      field.onChange({ target: { value: data.value, name: 'roles2' } } as any)
                    }
                  />
                )}
              />
            </Form.Field>
            <Segment size="mini">
              A user without any assigned roles will have unrestricted access.
            </Segment>

            <Button primary type="submit" disabled={isSubmitting}>
              Save
            </Button>
          </FormikForm>
        );
      }}
    </Formik>
  );
};
