import * as React from 'react';
import { Button, Popup, Image, Label, Modal, Table, Message } from 'semantic-ui-react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { FormattedDate, FormattedTime } from 'react-intl';

import {
  useList,
  generateOperatorOptions,
  Operator,
  ListPage,
  basicQueryMap,
} from '@inkcloud/components';

import Feathers from '../../../bootstrap/feathers';

interface ListProps {}

export async function getFilterDefinition() {
  const definition = [
    {
      label: 'Name',
      value: 'name',
      operators: generateOperatorOptions([Operator.Like, Operator.Is]),
    },
  ];

  return definition;
}

const filterQueryMap = (values) => {
  let filterRows = basicQueryMap(values);

  filterRows = {
    ...filterRows,
  };

  return filterRows;
};

const serviceName = 'fulfillment-staging-locations';
const pathPrefix = '/settings/fulfillment-staging-locations';

export const List: React.FunctionComponent<ListProps & RouteComponentProps<any>> = (props) => {
  const {
    match: { params },
    location,
    history,
  } = props;

  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState<any>(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');

  const useListValues = useList({
    feathers: [
      'find',
      serviceName,
      [
        {
          query: {
            $populate: ['contains.orderItem', 'vendor'],
            $sort: { createdAt: -1 },
          },
        },
      ],
    ],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  const headerActions = (
    <>
      <Link to={`${pathPrefix}/add`}>
        <Button content="New" primary size={'mini'} />
      </Link>
    </>
  );

  const tableDefintion = [
    {
      key: 'name',
      label: 'Name',
      cell: (v, r) => v,
    },
    {
      key: 'vendor',
      label: 'Vendor',
      cell: (v, r) => v?.companyName,
    },

    {
      key: '',
      label: 'Contents',
      cell: (v, r) => (
        <>
          {r?.contains?.length
            ? r.contains.map((item, index: number) => (
                <Label key={index} size="mini">
                  {item?.orderItem?.humanId ?? ''}
                </Label>
              ))
            : 'Empty'}
        </>
      ),
    },
    {
      key: '',
      label: '',
      cell: (v, r) => (
        <>
          <Popup
            size="mini"
            content="Edit"
            trigger={
              <Link to={`${pathPrefix}/${r._id}`}>
                <Button size="mini" icon={'edit'} />
              </Link>
            }
          />
        </>
      ),
      textAlign: 'right',
    },
  ];

  const handleRemove = async (item) => {
    setErrorMessage('');
    try {
      setIsLoading(true);
      const res: any = await Feathers.service(`${serviceName}/staged`).remove(item._id);

      useListValues.reload();

      const filtered = selectedItem.contains.filter((i) => i._id !== item._id);

      setSelectedItem((prev) => ({ ...prev, contains: filtered }));
    } catch (e) {
      const errMsg = e?.code < 500 ? e?.message : 'An error occurred while removing the item';
      setErrorMessage(errMsg);
    }

    setIsLoading(false);
  };

  return (
    <>
      <ListPage
        header="Fulfillment Staging Locations"
        subheader="Settings"
        useListValues={useListValues}
        history={history}
        pathPrefix={pathPrefix}
        tableDefintion={tableDefintion}
        withCheckboxes={true}
        headerActions={headerActions}
        getFilterDefinition={getFilterDefinition}
      />

      {isModalOpen && (
        <Modal
          open
          onClose={() => {
            setIsModalOpen(false);
            setSelectedItem(null);
          }}
          closeIcon
        >
          <Modal.Header>Location {selectedItem?.name ?? ''} Contents</Modal.Header>
          <Modal.Content>
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Added At</Table.HeaderCell>
                  <Table.HeaderCell>Item</Table.HeaderCell>
                  <Table.HeaderCell>Quantity</Table.HeaderCell>
                  <Table.HeaderCell>Notes</Table.HeaderCell>
                  <Table.HeaderCell />
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {selectedItem?.contains?.map((item, index: number) => (
                  <Table.Row key={index}>
                    <Table.Cell>
                      <FormattedDate value={item?.addedAt} />
                    </Table.Cell>
                    <Table.Cell>{item?.orderItem?.humanId}</Table.Cell>
                    <Table.Cell>{item?.quantity}</Table.Cell>
                    <Table.Cell>{item?.notes}</Table.Cell>
                    <Table.Cell textAlign="right">
                      <Button
                        size="mini"
                        icon="trash"
                        loading={isLoading}
                        onClick={() => handleRemove(item)}
                      />
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
            {errorMessage && <Message error content={errorMessage} />}
            <Button content="Close" onClick={() => setIsModalOpen(false)} />
          </Modal.Content>
        </Modal>
      )}
    </>
  );
};
