import React, { useEffect, useState } from 'react';
import { Switch, Route, NavLink, useHistory } from 'react-router-dom';
import {
  Flex,
  Box,
  Text,
  Spinner,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  Alert,
} from '@chakra-ui/react';
import { PageHeaderRow } from '@inkcloud/components';

import Detail from './Detail';
import { Feathers } from '../../../../bootstrap/feathers/feathers';

const pathPrefix = '/settings/content-management/templates';

function helper(name: string) {
  const index = name?.indexOf('.');
  const indexOfSlash = name?.indexOf('/');
  const newString = name?.substring(indexOfSlash ? indexOfSlash + 1 : 0, index);
  return newString;
}

export default function routes() {
  const history = useHistory();

  const [templates, setTemplates] = useState<any>([]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [fileName, setFileName] = useState('');

  useEffect(() => {
    const loadTemplates = async () => {
      try {
        const res = await Feathers.service('store-front/theme-templates').find();
        setTemplates(res);
      } catch (e) {
        console.error(e);
      }
      setIsLoading(false);
    };

    if (isLoading) {
      loadTemplates();
    }
  }, [isLoading]);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    setErrorMessage('');
    try {
      const res = await Feathers.service('store-front/theme-templates').patch(fileName, {
        content: '',
      });
      setIsLoading(true);
      setIsModalOpen(false);
      history.push(`${pathPrefix}/${helper(fileName)}`);
    } catch (e) {
      const errMsg = e?.code < 500 ? e?.message : 'Something went wrong. Please try again later.';
      setErrorMessage(errMsg);
    }
    setIsSubmitting(false);
  };

  return (
    <Box>
      <PageHeaderRow header="Templates" subheader="Theme">
        <Button mr={3} onClick={() => history.goBack()}>
          Back
        </Button>
        <Button colorScheme="blue" onClick={() => setIsModalOpen(true)}>
          New
        </Button>
      </PageHeaderRow>

      {isLoading ? (
        <Flex justifyContent="center">
          <Spinner />
        </Flex>
      ) : (
        <Flex>
          <Switch>
            <>
              <Box w="200" h="100vh" overflow="auto">
                {templates?.map((template, index) => (
                  <Box key={index} py={2}>
                    <NavLink
                      to={{
                        pathname: `${pathPrefix}/${helper(template?.file)}`,
                        search: template?.path ? `?path=${template.path}` : '',
                      }}
                      activeStyle={{
                        fontWeight: 'bold',
                      }}
                    >
                      <Text>{template?.file}</Text>
                    </NavLink>
                  </Box>
                ))}
              </Box>
              <Box flex="1" minH="100vh">
                <Route exact path="/settings/content-management/templates/:id" component={Detail} />
              </Box>
            </>
          </Switch>
        </Flex>
      )}
      {isModalOpen && (
        <Modal isOpen={true} onClose={() => setIsModalOpen(false)}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Add New Template</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Box>
                <form onSubmit={handleSubmit}>
                  <Input
                    placeholder="Enter file name..."
                    onChange={(e) => setFileName(e.target.value)}
                  />
                </form>
              </Box>{' '}
              {errorMessage && (
                <Alert my={3} status="error">
                  {errorMessage}
                </Alert>
              )}
            </ModalBody>

            <ModalFooter>
              <Button mr={3} onClick={() => setIsModalOpen(false)}>
                Close
              </Button>
              <Button
                isDisabled={isSubmitting}
                isLoading={isSubmitting}
                colorScheme="blue"
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </Box>
  );
}
