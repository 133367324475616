// import * as React from 'react';
// import moment from 'moment';
// import { Button, Header, Segment, Table, Popup, Icon } from 'semantic-ui-react';
// import { Link, RouteComponentProps } from 'react-router-dom';
// import { FormattedDate, FormattedTime } from 'react-intl';
// import { FilterForm } from './FilterForm';

// import Feathers from "./../../../bootstrap/feathers";

// import { ExportModal } from "./../../reports/ExportModal";

// import { useList, PageHeaderRow, ListTable, EmptyWrapper, PaginatorWrapper } from '@inkcloud/components';
// import { ReAssignModal } from './ReAssignModal';

// interface ListProps { }

// const serviceName = 'sales-people';
// const pathPrefix = '/settings/sales-team';

// const filterQueryMap = (values) => {

//   const isManager = (values.isManager === 0 || values.isManager === undefined) ? undefined : (values.isManager === 1 ? true : false)

//   const filterMap: any = {
//     firstName: values.firstName ? { $LIKE: values.firstName } : undefined,
//     lastName: values.lastName ? { $LIKE: values.lastName } : undefined,
//     email: values.email ? { $LIKE: values.email } : undefined,
//     isManager,
//     manager: values.manager ? values.manager : undefined,
//   };

//   return [].reduce((m, field) => ({ ...m, [field]: values[field] ? values[field] : undefined }), filterMap);
// };

// const { useState } = React

// export const List: React.FunctionComponent<ListProps & RouteComponentProps<any>> = (props) => {
//   const { match: { params }, location, history, } = props;
//   const { state, reload, handleDelete, selectedItems, clearAll, setChecked, someSelected, toggleAll, filter, setFilter, sort, handleSort } = useList({
//     feathers: ['find', serviceName, [{ query: { $populate: ['manager', 'defaultAddress'] } }]],
//     filterMap: filterQueryMap,
//     debounce: 150,
//     location,
//     history,
//     sort: { createdAt: -1 }
//   }) as any

//   const [isSubmitting, setSubmitting] = useState(false);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [isReAssignModalOpen, setIsReAssignModalOpen] = useState(false);
//   const [salesPersonId, setSalesPersonId] = useState('');
//   const [exportUrl, setExportUrl] = useState("");

//   const { queryResult, isLoading: isStateLoading } = state as any;

//   const handleExport = async (all: string) => {
//     setSubmitting(true);

//     const res: any = await Feathers.service('report/v2/sales-team-export?$export=1').create({
//       all: true
//     });

//     if (res) {
//       setIsModalOpen(true);
//       setExportUrl(res.url);
//       setSubmitting(false);
//     }
//   };

//   const tableDefinition = [
//     {
//       key: 'createdAt', label: 'Created', cell: v => <Popup
//         size='mini'
//         content={<FormattedTime value={v} />}
//         trigger={
//           <div><FormattedDate value={v} /></div>
//         }
//       />
//     },
//     {
//       key: 'firstName', label: 'First Name', cell: (v, r) => <>
//         {r.firstName} {r.isManager && <Popup
//           size='mini'
//           content="Manager"
//           trigger={
//             <Icon name='user circle' color='green' />
//           }
//         />}
//       </>
//     },
//     {
//       key: 'lastName', label: 'Last Name', cell: v => v
//     },
//     {
//       key: 'email', label: 'Email', cell: v => v
//     },
//     {
//       key: 'defaultAddress', label: 'Default Address', cell: (v, r) => <>
//         {r?.defaultAddress && (
//           <>
//             <div>{r.defaultAddress?.address1 ?? ''}</div>
//             <div>{r.defaultAddress?.address2 ?? ''}</div>
//             <div>{r.defaultAddress?.city ?? ''} {r.defaultAddress?.stateProvince ?? ''} {r.defaultAddress?.postalCode ?? ''}</div>
//           </>
//         )}
//       </>
//     },
//     {
//       key: 'companyName', label: 'Company Name', cell: v => v
//     },
//     {
//       key: 'phone', label: 'Phone', cell: v => v
//     },
//     {
//       key: 'manager', label: 'Manager', cell: (v, r) => <>
//         {r.manager && !r.manager.isDeleted && `${r.manager?.firstName ?? ''} ${r.manager?.lastName ?? ''}`}
//       </>
//     },
//     {
//       key: '', label: '', cell: (v, r) => <>

//         <Popup
//           size='mini'
//           content="Re-Assign"
//           trigger={
//             <Button size="mini" icon="exchange" onClick={() => {
//               setIsReAssignModalOpen(true)
//               setSalesPersonId(r._id)
//             }} />
//           }
//         />
//         <Popup
//           size='mini'
//           content="Edit"
//           trigger={
//             <Link to={`${pathPrefix}/${r._id}`}>
//               <Button size={'mini'} icon={'edit'} />
//             </Link>
//           }
//         />
//       </>,
//       textAlign: "right"
//     },
//   ]

//   const resolvedQueryResult = queryResult?.data ? queryResult.data : queryResult;

//   return (
//     <div>
//       <PageHeaderRow header="Sales Team " subheader="Settings">
//         <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
//         <Link to={`${pathPrefix}/add`}>
//           <Button content="New" primary size={'mini'} />
//         </Link>
//       </PageHeaderRow>

//       <FilterForm
//         value={filter}
//         onChange={(v) => setFilter(v)}
//         onExport={handleExport}
//         isSubmitting={isSubmitting}
//         onReset={() => setFilter({})} />

//       <EmptyWrapper queryResult={queryResult} isStateLoading={isStateLoading}>

//         <PaginatorWrapper.Top filter={filter} reload={reload} data={(queryResult as any)} pathPrefix={`${pathPrefix}`} />
//         <Segment>
//           <ListTable
//             data={resolvedQueryResult}
//             definition={tableDefinition}
//             selectedItems={selectedItems}
//             setChecked={setChecked}
//             sort={sort}
//             sortClickFunction={handleSort}
//             toggleAll={toggleAll}
//             withCheckboxes={true}
//           />
//         </Segment>

//         <PaginatorWrapper.Bottom
//           reload={reload}
//           data={queryResult as any}
//           pathPrefix={pathPrefix}>
//           {someSelected && (
//             <Button
//               primary
//               type="button"
//               content="Delete"
//               onClick={handleDelete}
//             />
//           )}
//         </PaginatorWrapper.Bottom>

//         {exportUrl && (
//           <ExportModal
//             isModalOpen={isModalOpen}
//             exportUrl={exportUrl}
//             handleClose={() => {
//               setIsModalOpen(false);
//               setExportUrl("");
//             }}
//           />
//         )}

//         {
//           isReAssignModalOpen && <ReAssignModal
//             isOpen={isReAssignModalOpen}
//             salesPersonId={salesPersonId}
//             onReload={reload}
//             onClose={() => {
//               setIsReAssignModalOpen(false)
//               setSalesPersonId('')
//             }}

//           />
//         }

//       </EmptyWrapper>
//     </div>
//   );
// }

import React, { useState } from 'react';

import { Link, RouteComponentProps } from 'react-router-dom';
import { FormattedDate, FormattedTime } from 'react-intl';
import { Button, Box, IconButton, Icon, Tooltip, ButtonGroup } from '@chakra-ui/react';

import { FaEdit, FaExchangeAlt, FaUserAlt } from 'react-icons/fa';

import {
  useList,
  ListPage,
  basicQueryMap,
  generateOperatorOptions,
  Operator,
} from '@inkcloud/components';

import feathers from '../../../bootstrap/feathers';

import { ExportModal } from '../../reports/ExportModal';

import { ReAssignModal } from './ReAssignModal';

interface ListProps {}

const serviceName = 'sales-people';
const pathPrefix = '/settings/sales-team';

const filterQueryMap = (values) => {
  let filterRows = basicQueryMap(values, {});

  const isManagerValue = filterRows?.isManager?.$elemMatch?.value;

  filterRows = {
    ...filterRows,
    isManager: isManagerValue === 1 ? true : isManagerValue === 2 ? false : undefined,
  };

  return filterRows;
};

export async function getFilterDefinition() {
  const definition = [
    {
      label: 'First Name',
      value: 'firstName',
      operators: generateOperatorOptions([Operator.Like]),
    },
    {
      label: 'Last Name',
      value: 'lastName',
      operators: generateOperatorOptions([Operator.Like]),
    },
    {
      label: 'Email',
      value: 'email',
      operators: generateOperatorOptions([Operator.Like]),
    },
    {
      label: 'Sales Person:Manager',
      value: 'manager',
      operators: generateOperatorOptions([Operator.Is, Operator.IsNot]),
      valueOptions: [],
      asyncValueOptions: async () =>
        feathers
          .service('sales-people')
          .find({
            query: {
              $np: '1',
              isManager: true,
              $sort: { name: 1 },
            },
          })
          .then((results) =>
            results.map((r) => ({
              value: r._id,
              label: `${r?.firstName ?? ''} ${r?.lastName ?? ''}`,
            }))
          ),
      type: 'select',
    },
    {
      label: 'Type',
      value: 'isManager',
      operators: generateOperatorOptions([Operator.Is]),
      valueOptions: [
        { value: 1, label: 'Managers' },
        { value: 2, label: 'Non-managers' },
      ],
      type: 'select',
    },
  ];

  return definition.sort((a: any, b: any) => a.label.localeCompare(b.label));
}

export default function List(props: ListProps & RouteComponentProps) {
  const {
    match: { params },
    location,
    history,
  } = props;

  const [isSubmitting, setSubmitting] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isReAssignModalOpen, setIsReAssignModalOpen] = useState(false);
  const [salesPersonId, setSalesPersonId] = useState('');
  const [exportUrl, setExportUrl] = useState('');

  const handleExport = async () => {
    setSubmitting(true);

    try {
      const res: any = await feathers.service('report/v2/sales-team-export?$export=1').create({
        all: true,
      });

      if (res) {
        setIsModalOpen(true);
        setExportUrl(res.url);
      }
    } catch (e) {
      console.error(e);
    }

    setSubmitting(false);
  };

  const useListValues = useList({
    feathers: ['find', serviceName, [{ query: { $populate: ['manager', 'defaultAddress'] } }]],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  const tableDefinition = [
    {
      key: 'createdAt',
      label: 'Created',
      sortable: true,
      cell: (v) => (
        <Tooltip hasArrow aria-label="time" label={<FormattedTime value={v} />}>
          <Box as="span">
            <FormattedDate value={v} />
          </Box>
        </Tooltip>
      ),
    },
    {
      key: 'firstName',
      label: 'First Name',
      sortable: true,
      cell: (v, r) => (
        <>
          {r?.firstName ?? ''}{' '}
          {r.isManager && (
            <Tooltip label="Manager">
              <Box as="span">
                <Icon as={FaUserAlt} color="green" />
              </Box>
            </Tooltip>
          )}
        </>
      ),
    },
    {
      key: 'lastName',
      label: 'Last Name',
      sort: true,
    },
    {
      key: 'email',
      label: 'Email',
      sortable: true,
    },
    {
      key: 'defaultAddress',
      label: 'Default Address',
      cell: (v, r) => (
        <>
          {r?.defaultAddress && (
            <>
              <div>{r.defaultAddress?.address1 ?? ''}</div>
              <div>{r.defaultAddress?.address2 ?? ''}</div>
              <div>
                {r.defaultAddress?.city ?? ''} {r.defaultAddress?.stateProvince ?? ''}{' '}
                {r.defaultAddress?.postalCode ?? ''}
              </div>
            </>
          )}
        </>
      ),
    },
    {
      key: 'companyName',
      label: 'Company Name',
      cell: (v) => v,
    },
    {
      key: 'phone',
      label: 'Phone',
      cell: (v) => v,
    },
    {
      key: 'manager',
      label: 'Manager',
      cell: (v, r) => (
        <>
          {r.manager &&
            !r.manager.isDeleted &&
            `${r.manager?.firstName ?? ''} ${r.manager?.lastName ?? ''}`}
        </>
      ),
    },
    {
      key: '',
      label: '',
      cell: (v, r) => (
        <ButtonGroup spacing={1}>
          <Tooltip label="Re-Assign">
            <IconButton
              colorScheme="gray"
              aria-label="re-assign"
              icon={<FaExchangeAlt />}
              onClick={() => {
                setIsReAssignModalOpen(true);
                setSalesPersonId(r._id);
              }}
            />
          </Tooltip>

          <Tooltip label="Edit Details">
            <Link to={`${pathPrefix}/${r._id}`}>
              <IconButton colorScheme="gray" aria-label="Edit" icon={<FaEdit />} />
            </Link>
          </Tooltip>
        </ButtonGroup>
      ),
      textAlign: 'right',
    },
  ];

  const headerActions = (
    <ButtonGroup spacing={1}>
      <Button
        isDisabled={isSubmitting}
        isLoading={isSubmitting}
        type="button"
        colorScheme="blue"
        size="sm"
        onClick={handleExport}
      >
        Export All
      </Button>
      <Button type="button" size="sm" onClick={() => history.goBack()}>
        Back
      </Button>
      <Link to={`${pathPrefix}/add`}>
        <Button size="sm" colorScheme="blue">
          New
        </Button>
      </Link>
    </ButtonGroup>
  );

  return (
    <div>
      <ListPage
        header={'Sales Team'}
        subheader="Settings"
        useListValues={useListValues}
        history={history}
        pathPrefix={pathPrefix}
        tableDefintion={tableDefinition}
        withCheckboxes={true}
        headerActions={headerActions}
        getFilterDefinition={getFilterDefinition}
      />

      {exportUrl && (
        <ExportModal
          isModalOpen={isModalOpen}
          exportUrl={exportUrl}
          handleClose={() => {
            setIsModalOpen(false);
            setExportUrl('');
          }}
        />
      )}

      {isReAssignModalOpen && (
        <ReAssignModal
          isOpen={isReAssignModalOpen}
          salesPersonId={salesPersonId}
          onReload={useListValues.reload}
          onClose={() => {
            setIsReAssignModalOpen(false);
            setSalesPersonId('');
          }}
        />
      )}
    </div>
  );
}
