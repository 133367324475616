import React, { useState } from 'react';

import Cleave from 'cleave.js/react';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Button,
  Input,
  Stack,
} from '@chakra-ui/react';

import { useSelector } from 'react-redux';
import type { Option } from 'react-select';
import type { ModelTypes } from '@inkcloud/icapi-types';
import { AsyncSelect } from 'chakra-react-select';
import { FormattedNumber } from 'react-intl';

import { Feathers } from '../../../bootstrap/feathers/feathers';
import { InputWrapper, currencySymbol } from '../../../common';

interface IOverrideProductModalProps {
  onClose: () => void;
  onSave: (v: { product: ModelTypes.ProductLegacy; price: number }) => void;
}

const mapOptionsToValue = (options: any) =>
  options?.map((o) => ({
    label: o?.key,
    value: o,
  }));

export default function OverrideProductModal(props: IOverrideProductModalProps) {
  const { onClose, onSave } = props;

  const tenant = useSelector(
    ({
      globals,
    }: {
      globals: { tenant: { internationalization: { currency: string; locale: string } } };
    }) => globals.tenant.internationalization
  );

  const [product, setProduct] = useState<{ label: string; value: ModelTypes.ProductLegacy }>();
  const [price, setPrice] = useState(0);

  const handleProductOptions: any = async (e: string, callback) => {
    try {
      if (e) {
        const res = await Feathers.service('products-legacy').find({
          query: {
            $select: ['key', 'description', 'price'],
            $sort: { key: 1 },
            $or: [{ key: { $LIKE: e } }],
          },
        });
        callback(mapOptionsToValue(res?.data));
      }
    } catch (er) {
      console.error(er);
    }
  };

  return (
    <Modal size="2xl" isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>New Product Override</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={7} mb={3} direction="row">
            <FormControl>
              <FormLabel>Product</FormLabel>
              <AsyncSelect
                useBasicStyles
                cacheOptions
                isClearable
                onChange={(e: Option) => setProduct(e)}
                placeholder=""
                loadOptions={handleProductOptions}
                noOptionsMessage={(v) =>
                  !v.inputValue ? 'Start typing product to search...' : 'No options'
                }
              />
            </FormControl>
            <FormControl textAlign="right">
              <FormLabel textAlign="right" mr={0}>
                Price
              </FormLabel>
              <FormattedNumber
                style="currency"
                value={product?.value?.price ?? 0}
                currency={tenant?.currency ?? 'USD'}
              />
            </FormControl>

            <FormControl textAlign="right">
              <FormLabel textAlign="right" mr={0}>
                Override Price
              </FormLabel>

              <InputWrapper>
                <Cleave
                  style={{ textAlign: 'right' }}
                  className={'form-control-cleave'}
                  options={{
                    numeral: true,
                    rawValueTrimPrefix: true,
                    numeralPositiveOnly: true,
                    numeralThousandsGroupStyle: 'thousand',
                    prefix: currencySymbol(tenant?.currency, tenant?.locale),
                  }}
                  onChange={(e) => setPrice(Number(e.target.rawValue) || 0)}
                />
              </InputWrapper>
            </FormControl>
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={() => onSave({ product: product?.value as ModelTypes.ProductLegacy, price })}
          >
            Save
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
