import * as React from 'react';
import { Modal, Message, Input, Button } from 'semantic-ui-react';
import Feathers from '../../../../bootstrap/feathers';

interface IMassUpdateModalProps {
  filter: any;
  onClose: () => void;
  onReload: () => void;
}

const { useEffect, useState } = React;

export const MassUpdateModal = (props: IMassUpdateModalProps) => {
  const { filter, onClose, onReload } = props;

  const [value, setValue] = useState(null);
  const [locations, setLocations] = useState([]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const loadLocations = async () => {
      try {
        const res: any = await Feathers.service('/inventory/locations').find({
          query: {
            name: { $LIKE: filter?.name },
            $np: 1,
          },
        });

        setLocations(res);
      } catch (e) {}
    };

    loadLocations();
  }, []);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      const res = locations?.map(async (l) =>
        Feathers.service('/inventory/locations').patch(
          l._id,
          { ...l, priority: Number(value) },
          { query: {} }
        )
      );

      await Promise.all(res);

      onReload();
      onClose();
    } catch (e) {
      const errMsg = e?.code < 500 ? e.message : 'Some locations failed to update';
      setErrorMessage(errMsg);
    }

    setIsSubmitting(false);
  };

  return (
    <Modal size="tiny" open onClose={onClose} closeIcon>
      <Modal.Header>Mass Update</Modal.Header>
      <Modal.Content>
        <label>Priority</label>
        <Input fluid onChange={(e, data) => setValue(data.value)} />

        {errorMessage && <Message error content={errorMessage} />}
      </Modal.Content>
      <Modal.Actions>
        <Button content="Close" onClick={onClose} />
        <Button
          primary
          disabled={isSubmitting || !value}
          loading={isSubmitting}
          content="Save"
          onClick={handleSubmit}
        />
      </Modal.Actions>
    </Modal>
  );
};
