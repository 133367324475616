import * as React from 'react';
import { Link, Switch, Route, RouteComponentProps } from 'react-router-dom';
import { Segment, Button, Message, Table, Header, Label, Grid } from 'semantic-ui-react';
import * as Scroll from 'react-scroll';
import { PageHeaderRow, PreLoader } from '@inkcloud/components';
import feathers from '../../../bootstrap/feathers';
import { DetailWrapper, DetailWrappedProps } from '../../../bootstrap/DetailWrapper';
import { ShipToForm } from './ShipToAddressForm';

export interface IndexComponentPropsPre {}

type IndexComponentProps = DetailWrappedProps & RouteComponentProps<any>;

export interface IndexComponentState {
  gotValidationResult: boolean;
  result: boolean;
  distance: number | null;
  shipToAddress: any;
}

class IndexComponent extends React.Component<IndexComponentProps, IndexComponentState> {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleUpdateDelivery = this.handleUpdateDelivery.bind(this);
    this.handleCopyClick = this.handleCopyClick.bind(this);

    this.state = {
      gotValidationResult: false,
      result: false,
      distance: 0.0,
      shipToAddress: {
        name: '',
        careOf: '',
        address1: '',
        address2: '',
        city: '',
        stateProvince: '',
        postalCode: '',
        countryCode: 'US',
      },
    };
  }

  // componentWillReceiveProps(nextProps) {
  //   if (this.props.data === null && nextProps.data !== null) {

  //   }
  // }

  handleSubmit(values) {
    return feathers
      .service('/local-delivery/validator')
      .create({
        shipToAddress: values,
      })
      .then(({ result, distance }) => {
        this.setState(
          {
            ...this.state,
            gotValidationResult: true,
            result,
            distance,
            shipToAddress: values,
          },
          () => Scroll.animateScroll.scrollToBottom()
        );
      });
  }

  handleUpdateDelivery() {
    const shipToAddress = { ...this.state.shipToAddress, countryCode: 'US' };
    this.props
      ?.patch(this.props.data._id, {
        isLocalDeliveryApproved: true,
        localDeliveryAddress: shipToAddress,
      })
      .then((customer) => {
        this.setState({
          ...this.state,
          gotValidationResult: false,
          result: false,
          distance: null,
          shipToAddress: null,
        });
      });
  }

  handleCopyClick() {
    const { primaryContact } = this.props.data;
    const contactName = `${primaryContact.firstName} ${primaryContact.lastName}`;
    const name = this.props.data.organizationName ? this.props.data.organizationName : contactName;

    this.setState({
      ...this.state,
      shipToAddress: {
        name,
        careOf: this.props.data.organizationName ? this.props.data.organizationName : contactName,
        address1: this.props.data.address1,
        address2: this.props.data.address2,
        city: this.props.data.city,
        stateProvince: this.props.data.stateProvince,
        postalCode: this.props.data.postalCode,
        countryCode: 'US',
      },
    });
  }

  render() {
    if (!this.props.data) {
      return <PreLoader />;
    }
    const shipToAddress = this.state.shipToAddress
      ? Object.keys(this.state.shipToAddress).reduce(
          (prev, cur) => `${prev} ${this.state.shipToAddress[cur]}`,
          ''
        )
      : '';

    const { primaryContact } = this.props.data;
    const name = this.props.data.organizationName
      ? this.props.data.organizationName
      : `${primaryContact.firstName} ${primaryContact.lastName}`;
    const localDeliveryAddress = this.props.data.localDeliveryAddress;

    const formInitiaValues = {
      name,
      address1: this.props.data.address1,
      address2: this.props.data.address2,
      city: this.props.data.city,
      stateProvince: this.props.data.stateProvince,
      postalCode: this.props.data.postalCode,
    };

    let afterMessage;
    if (this.state.gotValidationResult) {
      if (this.state.result) {
        afterMessage = (
          <div>
            <Message attached="bottom" positive>
              <Message.Header>Address is qualified</Message.Header>
              <p>
                <span style={{ fontStyle: 'italic' }}>{shipToAddress}</span> is{' '}
                {this.state.distance.toFixed(1)} miles away and qualifies for local delivery.
              </p>
            </Message>
            <Segment>
              <Button primary onClick={this.handleUpdateDelivery}>
                Enable Local Delivery
              </Button>
            </Segment>
          </div>
        );
      } else {
        afterMessage = (
          <Message attached="bottom" negative>
            <Message.Header>Address is not qualified</Message.Header>
            <p>
              <span style={{ fontStyle: 'italic' }}>{shipToAddress}</span> is{' '}
              {this.state.distance.toFixed(1)} miles away and does not qualify for local delivery.
            </p>
          </Message>
        );
      }
    }

    return (
      <div>
        <PageHeaderRow
          header="Local Delivery"
          subheader="Customer"
          subheaderId={this.props.data.humanId}
        >
          <Link to={`/customers/${this.props.data._id}`}>
            <Button content="Back" size={'mini'} />
          </Link>
        </PageHeaderRow>
        <Segment>
          <Grid columns={'equal'} divided>
            <Grid.Column>
              <Table basic="very">
                <Table.Body>
                  <Table.Row>
                    <Table.Cell width={2}>ID</Table.Cell>
                    <Table.Cell width={14}>{this.props.data.humanId}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width={2}>Name</Table.Cell>
                    <Table.Cell width={14}>{name}</Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Grid.Column>
            <Grid.Column>
              <Table basic="very">
                <Table.Body>
                  <Table.Row>
                    <Table.Cell width={4}>Delivery Status</Table.Cell>
                    <Table.Cell width={12}>
                      <Label
                        size="small"
                        color={this.props.data.isLocalDeliveryApproved ? 'green' : 'red'}
                      >
                        {this.props.data.isLocalDeliveryApproved ? 'Enabled' : 'Disabled'}
                      </Label>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width={4} verticalAlign={'top'}>
                      Delivery Address
                    </Table.Cell>
                    <Table.Cell width={12} verticalAlign={'top'}>
                      {localDeliveryAddress ? (
                        <div>
                          <div>{localDeliveryAddress.name}</div>
                          <div>{localDeliveryAddress.careOf}</div>
                          <div>{localDeliveryAddress.address1}</div>
                          <div>{localDeliveryAddress.address2}</div>
                          <div>
                            {localDeliveryAddress.city} {localDeliveryAddress.stateProvince}{' '}
                            {localDeliveryAddress.postalCode}
                          </div>
                        </div>
                      ) : null}
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid>
        </Segment>
        <Header as="h5" attached="top">
          <Button floated="right" onClick={this.handleCopyClick} size="mini" compact>
            Copy from customer address
          </Button>
          Local Delivery Address
        </Header>
        <Segment attached>
          <br />
          <ShipToForm initialValues={this.state.shipToAddress} handleSubmit={this.handleSubmit} />
          {afterMessage}
        </Segment>
      </div>
    );
  }
}

export default DetailWrapper(IndexComponent, 'customers', {});
