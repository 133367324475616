import * as React from 'react';
import { Button, Segment, Popup, Label } from 'semantic-ui-react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { FormattedDate, FormattedTime } from 'react-intl';
import {
  useList,
  ListPage,
  basicQueryMap,
  generateOperatorOptions,
  Operator,
} from '@inkcloud/components';

const { useState } = React;

interface ListProps {}

const serviceName = '/rules-engine/rules';
const pathPrefix = '/rules-engine';

const filterQueryMap = (values) => basicQueryMap(values, {});

export async function getFilterDefinition() {
  const definition = [];

  return definition.sort((a: any, b: any) => a.label.localeCompare(b.label));
}

export const List: React.FunctionComponent<ListProps & RouteComponentProps<any>> = (props) => {
  const {
    match: { params },
    location,
    history,
  } = props;

  const tableDefinition = [
    {
      key: 'humanId',
      label: 'ID',
      sortable: true,
      cell: (v, r) => v,
    },
    {
      key: 'createdAt',
      label: 'Date',
      sortable: true,
      cell: (v) =>
        v && (
          <Popup
            size="mini"
            content={<FormattedTime value={v} />}
            trigger={
              <div>
                <FormattedDate value={v} />
              </div>
            }
          />
        ),
    },
    {
      key: '',
      label: 'Customer',
      sortable: true,
      cell: (v, r) => {
        if (r?.micrositeUser) {
          return r?.micrositeUser?.companyName
            ? r?.micrositeUser?.companyName
            : `${r?.micrositeUser?.firstName ?? ''} ${r?.micrositeUser?.lastName ?? ''}`;
        }

        return `${r?.customer?.primaryContact?.firstName ?? ''} ${
          r?.customer?.primaryContact?.lastName ?? ''
        }`;
      },
    },
    {
      key: '',
      label: '',
      sortable: false,
      cell: (v, r) => (
        <Popup
          size="mini"
          content="Edit"
          trigger={
            <Link to={`${pathPrefix}/${r._id}`}>
              <Button size={'mini'} icon={'edit'} />
            </Link>
          }
        />
      ),
      textAlign: 'right',
    },
  ];

  const useListValues = useList({
    feathers: [
      'find',
      serviceName,
      [
        {
          // query: {
          //   $populate: ['customer', 'micrositeUser']
          // }
        },
      ],
    ],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  const headerActions = (
    <>
      <Link to={`${pathPrefix}/add`}>
        <Button content="New" primary size={'mini'} />
      </Link>
    </>
  );

  return (
    <div>
      <ListPage
        header={'Rules Engine'}
        subheader="List"
        useListValues={useListValues}
        history={history}
        pathPrefix={pathPrefix}
        tableDefintion={tableDefinition}
        withCheckboxes={false}
        headerActions={headerActions}
        // getFilterDefinition={getFilterDefinition}
      />
    </div>
  );
};
