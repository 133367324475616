import * as React from 'react';
import { Button, Segment } from 'semantic-ui-react';
import { DetailWrapper, DetailFeaturesProps } from '../../../bootstrap/DetailWrapper';
import { VariantValueForm } from './VariantValueForm';
import { PageHeaderRow, PreLoader, useFeathers } from '@inkcloud/components';
import { RouteComponentProps, Link } from 'react-router-dom';

import Feathers from './../../../bootstrap/feathers';

const initialState = { microsite: null };
type State = typeof initialState;

const serviceName = '/products-legacy/variant-type-values';
const pathPrefix = `/products/variant-values`;
const service = Feathers.service(serviceName);
const commonParams = {};

const { useState } = React;

export const Detail: React.FunctionComponent<
  RouteComponentProps<{ id?: string; micrositeId?: string }>
> = (props) => {
  const {
    match: {
      params: { id, micrositeId },
    },
    history,
  } = props;
  // class Detail extends React.Component<DetailFeaturesProps, State> {
  //   state: State = { microsite: null };

  // render() {
  //   const { data } = this.props;
  //   if ((this.props.match.params.id && !this.props.data)) {
  //     return <PreLoader />
  //   }

  //   const initialData = { ...data, microsite: this.props.match.params.micrositeId };
  //   const pageTitle = data && data._id ? 'Edit' : 'New';

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    submit: '',
  });

  const isEdit = !!id;
  const [state, reload] = useFeathers('get', serviceName, [id, commonParams], true);

  if (isEdit && !state.data) {
    return <PreLoader />;
  }

  const initialValues = { ...state.data, microsite: micrositeId };

  const handleSubmit = async (values: any) => {
    setErrorMessage({ ...errorMessage, submit: '' });

    values = {
      ...values,
      key: values?.key?.replace(/-+$/, ''), // Trim (-) hyphen from end of text
    };

    try {
      setIsSubmitting(true);
      const res = isEdit
        ? await service.patch(id, values, commonParams)
        : await service.create(values, commonParams);

      if (res.result !== undefined && !res.result) {
        setErrorMessage(res.message || res.errorMessage);
      }

      history.push(pathPrefix);
      setIsSubmitting(false);
    } catch (e) {
      setIsSubmitting(false);
      const errMsg =
        e.code && e.code < 500
          ? e.message
          : 'We are experiencing technical difficulties. Please try again';
      setErrorMessage({ ...errorMessage, submit: errMsg });
    }
  };

  return (
    <div>
      <PageHeaderRow header={`Variant Value`} subheader="Products">
        <Link to={pathPrefix}>
          <Button content="Back" size={'mini'} />
        </Link>
      </PageHeaderRow>
      <Segment>
        <VariantValueForm
          initialValues={initialValues}
          onSubmit={handleSubmit}
          isSubmitting={isSubmitting}
          errorMessage={errorMessage.submit}
        />
      </Segment>
    </div>
  );
  // }
};

// export default DetailWrapper(Detail, 'productsVariantValues', {
//   submitRedirect: (props) => `/products/variant-values`,
// });
