import * as React from 'react';
import { Switch, Route } from 'react-router-dom';

import HeroStacks from './hero-stacks';

const basePath = '/store-front-content';

const routes = (props) => (
  <Switch>
    <Route exact path={`${basePath}/hero-stacks`} component={HeroStacks} />
  </Switch>
);

export default routes;
