import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import type { ModelTypes } from '@inkcloud/icapi-types';
import { AdjustmentForm } from './AdjustmentForm';
import Feathers from '../../../bootstrap/feathers';

export interface IAdjustmentModalProps {
  handleSubmit: Function;
  handleClose: () => void;
  open: boolean;
  sources?: any[];
  prodLocations?: any[];
  lastLocation: string | null;
  lastPrice: string | null;
  lastTags?: number | string[];
  errorMessage?: string;
}

const initialState = { locations: [] };
type State = typeof initialState;

// export class AdjustmentModal extends React.Component<AdjustmentModalProps, State> {
//   state = initialState;
//   async componentDidMount() {
//     const locations = await Feathers.service('/inventory/locations').find({ query: { $np: 1 } }) as any[];
//     this.setState({ ...this.state, locations: locations.map(l => ({ value: l._id, text: l.name })) });
//   }
//   public render() {
//     const { props } = this;

//     return (
//       <Modal size={'mini'} open={props.open} onClose={props.handleClose}>
//         <Modal.Header>Inventory Changes</Modal.Header>
//         <Modal.Content>
//           <AdjustmentForm
//             initialValues={{ amount: 0, price: this.props.lastPrice, tags: this.props.lastTags || 0.00, destination: this.props.lastLocation || '' }}
//             handleSubmit={props.handleSubmit}
//             handleCloseClick={this.props.handleClose}
//             locations={this.state.locations}
//             sources={this.props.sources}
//             prodLocations={this.props.prodLocations}
//             errorMessage={props.errorMessage}
//           />
//         </Modal.Content>
//         <Modal.Actions>
//         </Modal.Actions>
//       </Modal>
//     );
//   }
// }

export default function AdjustmentModal(props: IAdjustmentModalProps) {
  const {
    handleClose,
    handleSubmit,
    open,
    sources,
    prodLocations,
    lastLocation,
    lastPrice,
    lastTags,
    errorMessage,
  } = props;

  const [locations, setLocations] = useState<ModelTypes.InventoryLocations[]>([]);

  const loadLocations = async () => {
    try {
      const res = await Feathers.service('/inventory/locations').find({ query: { $np: 1 } });
      setLocations(res?.map((l) => ({ value: l._id, text: l.name })));
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    loadLocations();
  }, []);

  return (
    <Modal isOpen={true} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Inventory Changes</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <AdjustmentForm
            initialValues={{
              amount: 0,
              price: lastPrice,
              tags: lastTags,
              destination: lastLocation || '',
            }}
            handleSubmit={handleSubmit}
            handleCloseClick={handleClose}
            locations={locations}
            sources={sources}
            prodLocations={prodLocations}
            errorMessage={errorMessage}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
