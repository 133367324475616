import * as React from 'react';
import * as Cleave from 'cleave.js/react';
import { Form } from 'semantic-ui-react';

interface ItemPriceProps {
  onChange: (id: string, fieldName: string, v: string) => void;
  itemId: string;
  fieldName: string;
  price: number;
}

export const ItemPrice: React.FunctionComponent<ItemPriceProps> = (props) => {
  const { itemId, fieldName, price, onChange } = props;

  return (
    <Form>
      <div className={'ui small input fluid'}>
        <Cleave
          options={{
            numeral: true,
            rawValueTrimPrefix: true,
            numeralPositiveOnly: true,
            numeralThousandsGroupStyle: 'thousand',
            prefix: '$',
          }}
          value={price}
          onChange={(e) => onChange(itemId, fieldName, e.target.rawValue)}
        />
      </div>
    </Form>
  );
};
