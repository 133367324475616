import * as React from 'react';
import { Button, Form, Icon, Popup, Label } from 'semantic-ui-react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { FormattedDate, FormattedTime } from 'react-intl';

import { useList, ListPage } from '@inkcloud/components';
import { connect } from 'react-redux';

export interface ConnectedProps {
  user?: any;
}

interface ListProps {}

const filterQueryMap = (values) => {};

const serviceName = '/promo-codes-campaigns';
const pathPrefix = `/settings/promo-codes/campaigns`;

export const PreList: React.FunctionComponent<ListProps & RouteComponentProps<any>> = (props) => {
  const {
    match: { params },
    location,
    history,
  } = props;

  const useListValues = useList({
    feathers: [
      'find',
      serviceName,
      [
        {
          query: {
            $sort: { createdAt: -1 },
          },
        },
      ],
    ],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  const headerActions = (
    <>
      <Link to={`${pathPrefix}/add`}>
        <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
        <Button size="mini" primary content="New" />
      </Link>
    </>
  );

  const tableDefintion = [
    {
      key: 'createdAt',
      label: 'Date',
      sortable: true,
      cell: (v, r) => (
        <Popup
          size="mini"
          content={<FormattedTime value={r.createdAt} />}
          trigger={
            <div>
              <FormattedDate value={r.createdAt} />
            </div>
          }
        />
      ),
    },
    {
      key: 'name',
      label: 'Name',
      cell: (v) => v,
    },
    {
      key: 'codePrefix',
      label: 'Code Prefix',
      cell: (v) => v,
    },
    {
      key: '',
      label: '',
      cell: (v, r) => (
        <>
          <Popup
            size="mini"
            content="View Details"
            align
            trigger={
              <Link to={`${pathPrefix}/${r._id}`}>
                <Button size="mini" icon="edit" />
              </Link>
            }
          />
        </>
      ),
      textAlign: 'right',
    },
  ];

  return (
    <>
      <ListPage
        header="Promo Code Campaigns"
        subheader="List"
        useListValues={useListValues}
        history={history}
        pathPrefix={pathPrefix}
        tableDefintion={tableDefintion}
        withCheckboxes={true}
        headerActions={headerActions}
      />
    </>
  );
};

const mapStateToProps = (state): ConnectedProps => ({
  user: state.auth.user || state.auth.user2 || {},
});

export const List = connect(mapStateToProps)(PreList as any);
