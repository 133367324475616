import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Modal, Image, Segment, Button, Grid, Dropdown, SemanticWIDTHS } from 'semantic-ui-react';

import { Box } from '@chakra-ui/react';

import {
  useList,
  EmptyWrapper,
  PaginatorWrapper,
  GenericFilter,
  basicQueryMap,
} from '@inkcloud/components';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { serviceName } from './List';
import getFilterDefinition from './FilterForm';

import Feathers from '../../../bootstrap/feathers';
import { Card } from '../../../common/Card';

const { useState, useEffect } = React;

interface DesignTemplateModalProps {}

const optionsGrid: { text: string; value: SemanticWIDTHS }[] = [];

for (let i = 2; i <= 12; i += 1) {
  optionsGrid.push({ text: `${i} columns`, value: i as SemanticWIDTHS });
}

const filterQueryMap = (values) => basicQueryMap(values, {});

const PreDesignTemplateModal: React.FunctionComponent<
  DesignTemplateModalProps & RouteComponentProps
> = (props) => {
  const { history, location } = props as any;

  const isOpen = location?.state?.isDesignTemplateModalOpen;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [numColumns, setNumColumns] = useState(optionsGrid[0].value);

  const {
    state,
    reload,
    handleDelete,
    selectedItems,
    clearAll,
    setChecked,
    someSelected,
    toggleAll,
    filter,
    setFilter,
    sort,
    handleSort,
  } = useList({
    feathers: [
      'find',
      serviceName,
      [
        {
          query: {
            $sort: { priority: 1, name: 1 },
            $select: ['templateUpload', 'previews', 'name'],
            $limit: 250,
            $includeFieldData: 1,
            $populate: ['templateUpload', 'previews'],
          },
        },
      ],
    ],
    filterMap: filterQueryMap,
    debounce: 150,
    location: props.location,
    history: props.history,
    sort: { priority: 1, name: 1 },
  });

  const [templates, setTemplates] = useState([]);

  const search = new URLSearchParams(location.search);
  let page = Number(search.get('page'));

  page = page || 1;

  const pathPrefix = `/admin/design-templates`;

  useEffect(() => {
    if (state.isFinished) {
      setTemplates((state as any)?.queryResult?.data);
    }
  }, [state.isFinished]);

  const handleReOrder = (dragIndex: number, hoverIndex: number) => {
    const newTemplates = [...templates];
    // let temp = newTemplates[dragIndex];
    // newTemplates[dragIndex] = newTemplates[hoverIndex]
    // newTemplates[hoverIndex] = temp
    const element = newTemplates[dragIndex];
    newTemplates.splice(dragIndex, 1);
    newTemplates.splice(hoverIndex, 0, element);
    setTemplates(newTemplates);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);

    try {
      const res: any = templates?.map(async (template: any, index: number) =>
        Feathers.service('design-templates').patch(template._id, {
          priority: page > 1 ? (page - 1) * 24 + index : index,
        })
      );

      await Promise.all(res);

      history.push(pathPrefix, { isDesignTemplateModalOpen: false });
    } catch (e) {
      console.error(e);
    }

    setIsSubmitting(false);
  };

  const { queryResult, isLoading: isStateLoading } = state as any;

  return (
    <Modal
      size={'large'}
      open={isOpen}
      onClose={() => history.push(pathPrefix, { isDesignTemplateModalOpen: false })}
      closeIcon
    >
      <Modal.Header as="h5">Design Templates</Modal.Header>
      <Modal.Content>
        <Box p={3} mb={3} borderRadius="md" borderWidth="1px">
          <GenericFilter
            isAutoFocus={true}
            factDefinitionFunction={getFilterDefinition}
            value={filter}
            onChange={(v) => setFilter({ ...v })}
          />
        </Box>
        <EmptyWrapper queryResult={templates} isStateLoading={isStateLoading}>
          <Grid>
            <Grid.Column floated="right" width={5} textAlign="right">
              <Dropdown
                selection
                value={numColumns}
                options={optionsGrid}
                onChange={(e, data: any) => setNumColumns(data.value)}
              />
            </Grid.Column>
          </Grid>
          <PaginatorWrapper.Top
            filter={filter}
            reload={reload}
            data={queryResult as any}
            pathPrefix={`${pathPrefix}/set-order`}
          />
          <Grid>
            <Grid.Row columns={numColumns}>
              <DndProvider backend={HTML5Backend}>
                {templates?.map((r: any, index: number) => (
                  <Grid.Column key={r._id}>
                    <Card index={index} id={r._id} move={handleReOrder}>
                      <Segment>
                        {r?.previews?.length > 0 && (
                          <Image.Group>
                            {r.previews.map((p, idx) => (
                              <Image
                                key={idx}
                                bordered
                                src={`https://storage.googleapis.com/icstorage/${p.cloudFilename}`}
                              />
                            ))}
                          </Image.Group>
                        )}
                        {r?.name}
                      </Segment>
                    </Card>
                    <br />
                  </Grid.Column>
                ))}
              </DndProvider>
            </Grid.Row>
          </Grid>
          <PaginatorWrapper.Bottom
            reload={reload}
            data={queryResult as any}
            pathPrefix={`${pathPrefix}/set-order`}
          />
        </EmptyWrapper>
      </Modal.Content>
      <Modal.Actions>
        <Button
          type="button"
          disabled={isSubmitting}
          primary
          content="Save"
          onClick={handleSubmit}
        />
        <Button
          type="button"
          disabled={isSubmitting}
          content="Close"
          onClick={() => history.push(pathPrefix, { isDesignTemplateModalOpen: false })}
        />
      </Modal.Actions>
    </Modal>
  );
};

export const DesignTemplateModal = withRouter(PreDesignTemplateModal);
