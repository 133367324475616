import * as React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { FormattedDate, FormattedTime } from 'react-intl';
import { useList, ListPage } from '@inkcloud/components';
import { Button, Label, Icon, Popup, Loader } from 'semantic-ui-react';
import { socket } from '../../../bootstrap/feathers';

export interface ListProps {
  statusOptions: Array<{ value: string; text: string }>;
}

const serviceName = '/imports';
const pathPrefix = `/settings/imports`;

const filterQueryMap = (values) => {};

export const List: React.FunctionComponent<ListProps & RouteComponentProps> = (props) => {
  const {
    match: { params },
    location,
    history,
    statusOptions,
  } = props;

  const useListValues = useList({
    feathers: [
      'find',
      serviceName,
      [
        {
          query: {
            $sort: { createdAt: -1 },
            $populate: ['requestedBy'],
          },
        },
      ],
    ],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  // Real-time: Subscribe to any changes and reload
  React.useEffect(() => {
    const handler = (msg) => useListValues.reload();
    socket.service('imports').on('patched', handler);
    socket.service('imports').on('created', handler);
    return () => {
      socket.service('imports').removeListener('patched', handler);
      socket.service('imports').removeListener('created', handler);
    };
  }, []);

  const tableDefinition = [
    {
      key: 'createdAt',
      label: 'Date',
      cell: (v, r) => (
        <>
          <FormattedDate value={r.createdAt} /> <FormattedTime value={r.createdAt} />
        </>
      ),
    },
    { key: 'importName', label: 'Name' },
    {
      key: 'requestedBy',
      label: 'Requested By',
      cell: (v, r) => (
        <span>
          {v?.firstName} {v?.lastName}
        </span>
      ),
    },
    {
      key: 'status',
      label: 'Status',
      cell: (v, r) => (
        <>
          <Label content={v} />
          {r.status === 'in-progress' && <Loader active inline size="small" />}
        </>
      ),
    },
    {
      key: '',
      label: 'Execution',
      cell: (v, r) => (
        <>
          {r.startedAt && (
            <Label size="tiny">
              Start
              <Label.Detail>
                <FormattedDate value={r.startedAt} /> <FormattedTime value={r.startedAt} />
              </Label.Detail>
            </Label>
          )}
          {r.completedAt && (
            <Label size="tiny">
              Complete
              <Label.Detail>
                <FormattedDate value={r.completedAt} /> <FormattedTime value={r.completedAt} />
              </Label.Detail>
            </Label>
          )}
        </>
      ),
    },
    {
      key: 'actions',
      label: '',
      cell: (v, r) =>
        r.status === 'complete' ? (
          <a target={'_blank'} href={r.resultUrl}>
            <Button size={'mini'} icon={'download'} />
          </a>
        ) : (
          <span></span>
        ),
      textAlign: 'right',
    },
  ];

  const headerActions = (
    <>
      <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
      <Link to={`${pathPrefix}/add`}>
        <Button size="mini" primary content="New" />
      </Link>
    </>
  );

  return (
    <>
      <ListPage
        header="Imports"
        subheader="Settings"
        useListValues={useListValues}
        history={history}
        pathPrefix={pathPrefix}
        tableDefintion={tableDefinition}
        withCheckboxes={true}
        headerActions={headerActions}
        getFilterDefinition={null}
      />
    </>
  );
};
