import * as React from 'react';
import { Icon, Message } from 'semantic-ui-react';

interface ListFilterWarning {
  isFiltered: boolean;
}

export default (props: ListFilterWarning) => {
  if (!props.isFiltered) {
    return null;
  }

  return (
    <Message attached warning>
      <Icon name="filter" />
      You are viewing filtered results
    </Message>
  );
};
