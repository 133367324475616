import * as React from 'react';
import {
  Button,
  Form,
  Image,
  Menu,
  Grid,
  Header,
  Segment,
  Message,
  Divider,
  Dropdown,
} from 'semantic-ui-react';
import { Formik, Form as FormikForm, Field, ErrorMessage, FieldProps, FieldArray } from 'formik';
import * as Yup from 'yup';
import { FeathersDropdown } from '@inkcloud/components';
import Select from 'react-select';

import { ModelTypes } from '@inkcloud/icapi-types';

import countries from 'world-countries';
import Feathers from '../../bootstrap/feathers';
import { SemanticField } from '../../common/Form';
import { countryStateProvinces, slugify2 as slugifyKey, groupByKey } from '../../common';

import { ImageUploadModal } from '../settings/content-management/banners/ImageUploadModal';

import { CustomerFormModal } from './CustomerFormModal';

type EasypostCarrierAccounts = Exclude<ModelTypes.EasypostCarrierAccounts, undefined>;
type Microsites = Exclude<ModelTypes.Microsites, undefined>;
// type TenantSettings = Exclude<ModelTypes.sett, undefined>

interface ISettingsProps {
  settings: {
    logo: {
      thumbUrl: string;
      publicUrl: string;
    };
    favicon: {
      thumbUrl: string;
      publicUrl: string;
    };
  };
}
interface MicrositeFormComponentProps {
  initialValues: ModelTypes.Microsites & ISettingsProps;
  onSubmit: Function;
  errorMessage?: string;
  isSubmitting?: boolean;
}

interface IShippingServiceOptions {
  carrier: string;
  carrierLabel: string;
  service: string;
  serviceLabel: string;
  text: string;
  value: string;
}

const { useState, useEffect } = React;

const shipOutOptions = [
  { text: 'Open', value: 'open' },
  { text: 'Address Book', value: 'address-book' },
  { text: 'Read Only', value: 'read-only' },
];

const addressVerificationOptions = [
  { text: 'Verify', value: 'verify' },
  { text: 'Verify Strict', value: 'verify-strict' },
];

const shippingMethodOptions = [
  { text: 'Shipping will call', value: 'SHIPPING_WILL_CALL' },
  { text: 'Shipping local delivery', value: 'SHIPPING_LOCAL_DELIVERY' },
  { text: 'Shipping ship out', value: 'SHIPPING_SHIP_OUT' },
];

const paymentMethodOptions = [
  { text: 'Cash', value: 'CASH' },
  { text: 'Company Paid', value: 'COMPANY_PAID' },
  { text: 'Credit Card', value: 'CREDIT_CARD' },
  { text: 'Credit Memo', value: 'CREDIT_MEMO' },
  { text: 'Check', value: 'CHECK' },
  { text: 'Invoice', value: 'INVOICE' },
  { text: 'Gift Card', value: 'GIFT_CARD' },
  { text: 'Offline', value: 'OFFLINE' },
  { text: 'No Payment', value: 'NO_PAYMENT' },
];

const paymentSelectionOptions = [
  { text: 'Stripe', value: 'stripe' },
  { text: 'Authorize.net', value: 'authorizeNet' },
];

const selectStyles = {
  menu: (styles) => ({ ...styles, zIndex: 99999 }),
};

export const MicrositeFormComponent = (props: MicrositeFormComponentProps) => {
  const [costCenters, setCostCenters] = useState<{ text: string; value: string; key: string }[]>(
    []
  );
  const [carriers, setCarriers] = useState<ModelTypes.EasypostCarrierAccounts[]>([]);
  const [servicesOptions, setServicesOptions] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenNewCustomerModal, setIsOpenNewCustomerModal] = useState(false);
  const { errorMessage, initialValues } = props;

  const [shippingServicesOptions, setShippingServicesOptions] = useState<IShippingServiceOptions[]>(
    []
  );

  const [customers, setCustomers] = useState([]);
  const [newCustomer, setNewCustomer] = useState('');

  const [isReload, setIsReload] = useState({
    customer: false,
  });

  const loadCustomer = async () => {
    const res: any = await Feathers.service('/customers').find({
      query: {
        $sort: { organizationName: 1 },
        $select: ['_id', 'organizationName', 'primaryContact', 'humanId'],
        $np: 1,
      },
    });

    const mappedCustomers = res?.map((r) => {
      const companyName = r.organizationName || '';
      const contactName =
        r.primaryContact && `${r.primaryContact.firstName} ${r.primaryContact.lastName}`;
      return {
        value: r._id,
        text: `${companyName} ${contactName} - ${r.humanId}`,
      };
    });

    setCustomers(mappedCustomers);
  };

  useEffect(() => {
    const loadEasyPostCarrier = async () => {
      try {
        const res: any = await Feathers.service('/shipping/v2/easypost-carrier-accounts').find({
          query: {},
        });
        if (res) {
          setCarriers(res.data);
        }
      } catch (e) {
        console.log(e);
      }
    };

    const loadShippingServicesOptions = async () => {
      const result: any = await Feathers.service('tenant-settings/shipping-services-options').find({
        query: {
          $np: 1,
          $sort: { name: 1 },
          all: 1,
        },
      });

      const mappedResult = () =>
        result?.map((r) => ({ ...r, value: r.service, label: r.serviceLabel }));

      setShippingServicesOptions(mappedResult);
    };

    loadShippingServicesOptions();
    loadEasyPostCarrier();
    loadCustomer();
  }, []);

  useEffect(() => {
    if (isReload.customer) {
      loadCustomer();
    }
  }, [isReload]);

  const initMapper = (values: ModelTypes.Microsites) => {
    const isBuiltInDomain = values?.domain === `${values?.key}.inkcloud9.site`;

    const mappedValues = {
      ...values,
      isBuiltInDomain,
      customer: values?.customer || newCustomer,
      domainType: isBuiltInDomain ? 'builtInDomain' : 'customDomain',
      settings: {
        ...values.settings,
        compositeTopLevelLimit: Boolean(values?.settings?.compositeTopLevelLimit),
        easypostCarrierAccounts: values?.settings?.easypostCarrierAccounts ?? [],
        allowedShippingServicesOnCart: values.settings?.allowedShippingServicesOnCart?.map((s) =>
          shippingServicesOptions?.find((o) => o.service === s)
        ),
      },
    };

    return mappedValues;
  };

  const MicrositeSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    key: Yup.string()
      .required('Required')
      .test('is-not-used', `Key is already in use`, function (value) {
        if (props.initialValues && props.initialValues._id && props.initialValues.key === value) {
          return Promise.resolve(true);
        }
        return Feathers.service<any>('/microsites')
          .find({ query: { key: value } })
          .then((results: any) => results.data?.length === 0);
      }),
    domain: Yup.string().required('Required'),
    settings: Yup.object().shape({
      fulfillmentFees: Yup.object().shape({
        perOrder: Yup.number()
          .typeError('Must be a number')
          .positive()
          .moreThan(-1, 'Must be positive a number'),
        perItem: Yup.number()
          .typeError('Must be a number')
          .positive()
          .moreThan(-1, 'Must be positive a number'),
        perItemQuantity: Yup.number()
          .typeError('Must be a number')
          .positive()
          .moreThan(-1, 'Must be positive a number'),
        perItemFirstQuantity: Yup.number()
          .typeError('Must be a number')
          .positive()
          .moreThan(-1, 'Must be positive a number'),
        perShippedBoxDomestic: Yup.number()
          .typeError('Must be a number')
          .positive()
          .moreThan(-1, 'Must be positive a number'),
        perShippedBoxInternational: Yup.number()
          .typeError('Must be a number')
          .positive()
          .moreThan(-1, 'Must be positive a number'),
      }),
      handlingFees: Yup.object().shape({
        perOrder: Yup.number()
          .typeError('Must be a number')
          .positive()
          .moreThan(-1, 'Must be positive a number'),
        perItem: Yup.number()
          .typeError('Must be a number')
          .positive()
          .moreThan(-1, 'Must be positive a number'),
      }),
      easypostCarrierAccounts: Yup.array().min(1, 'at least 1').required('required'),
    }),
  });

  const handleAddition = (e, data: any) => {
    const foundCostCenter = costCenters.find((c) => c.value === data.value);

    if (!foundCostCenter) {
      setCostCenters([...costCenters, { text: data.value, value: data.value, key: data.value }]);
    }
  };

  const themeLogo = props?.initialValues?.settings?.logo;
  const themeFavicon = props?.initialValues?.settings?.favicon;

  return (
    <React.Fragment>
      {themeLogo && (
        <>
          <div>
            <strong>Logo</strong>
          </div>
          <Image
            bordered
            size="medium"
            label={{
              as: 'a',
              corner: 'left',
              icon: 'check circle outline',
            }}
            src={`https://storage.googleapis.com/icstorage${
              themeLogo?.thumbUrl ?? `/${themeLogo?.publicUrl}`
            }`}
          />
          <br />
          <br />
        </>
      )}
      {themeFavicon && (
        <>
          <div>
            <strong>Favicon</strong>
          </div>
          <Image
            bordered
            size="medium"
            label={{
              as: 'a',
              corner: 'left',
              icon: 'check circle outline',
            }}
            src={`https://storage.googleapis.com/icstorage${
              themeFavicon?.thumbUrl ?? `/${themeFavicon?.publicUrl}`
            }`}
          />
          <br />
          <br />
        </>
      )}
      <Formik
        initialValues={initMapper({
          ...props.initialValues,
          customer: newCustomer || props?.initialValues?.customer,
        })}
        validationSchema={MicrositeSchema}
        enableReinitialize={true}
        onSubmit={(values, { setSubmitting }) => {
          props.onSubmit(values);
        }}
      >
        {(innerProps) => {
          const {
            values,
            touched,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            setFieldValue,
            setFieldTouched,
          } = innerProps;

          const hasCarrierAccounts = values?.settings?.easypostCarrierAccounts?.find(
            (acc) => acc.isEnabled === true
          );

          const handleSlugify = (v) => setFieldValue('key', slugifyKey(v));

          const shippingServiceMessage =
            values.settings.allowedShippingServicesOnCart?.find(
              (c) => c?.service === 'FEDEX_GROUND'
            ) &&
            !values.settings.allowedShippingServicesOnCart?.find(
              (c) => c?.service === 'GROUND_HOME_DELIVERY'
            );

          return (
            <FormikForm className={`ui small form ${isSubmitting && !errorMessage && 'loading'}`}>
              <Segment>
                <Form.Group widths="equal">
                  <Form.Field>
                    <SemanticField
                      name={'name'}
                      label="Name"
                      component={Form.Input}
                      onChange={(v) => {
                        if (!values?._id) {
                          setFieldValue('key', slugifyKey(v));
                        }
                      }}
                    />
                    <ErrorMessage
                      render={(msg) => <Message negative size="tiny" content={msg} />}
                      name="name"
                    />
                  </Form.Field>
                  <Form.Field>
                    <SemanticField
                      name={'key'}
                      label="Key"
                      component={Form.Input}
                      onChange={handleSlugify}
                    />
                    <ErrorMessage
                      render={(msg) => <Message negative size="tiny" content={msg} />}
                      name="key"
                    />
                  </Form.Field>
                </Form.Group>

                <>
                  <Menu attached="top" pointing secondary>
                    <Menu.Item name="Domain" active={true} />
                  </Menu>
                  <Segment attached="bottom">
                    <Form.Group>
                      <Form.Radio
                        name="domainType"
                        label="Built-in Domain"
                        value="builtInDomain"
                        component={Form.Radio}
                        checked={values.domainType === 'builtInDomain'}
                        onBlur={handleBlur}
                        onChange={() => {
                          setFieldValue('domainType', 'builtInDomain');
                          setFieldValue('domain', `${values.key}.inkcloud9.site`);
                        }}
                      />
                      <Form.Radio
                        name="domainType"
                        label="Custom Domain"
                        value="customDomain"
                        component={Form.Radio}
                        checked={values.domainType === 'customDomain'}
                        onBlur={handleBlur}
                        onChange={() => setFieldValue('domainType', 'customDomain')}
                      />
                    </Form.Group>
                    {values?.domainType === 'builtInDomain' ? (
                      <Message content={`${values.key}.inkcloud9.site`} />
                    ) : (
                      <>
                        <Form.Group widths="equal">
                          <Form.Field>
                            <SemanticField name="domain" label="Name" component={Form.Input} />
                            <ErrorMessage
                              render={(msg) => <Message negative size="tiny" content={msg} />}
                              name="domain"
                            />
                          </Form.Field>
                        </Form.Group>
                        {values?.domain !== `${values?.key}.inkcloud9.site` && (
                          <Message
                            info
                            content={
                              <>
                                Remember to point {values?.domain ?? ''} to our global IP address{' '}
                                <strong>35.226.98.243</strong>
                              </>
                            }
                          />
                        )}
                      </>
                    )}
                  </Segment>
                </>

                <Form.Group>
                  <div className="field" style={{ flex: 'auto' }}>
                    <label>Customer</label>

                    <Field
                      name="customer"
                      render={({ field }: FieldProps<any>) => (
                        <Dropdown
                          fluid
                          selection
                          search
                          options={customers}
                          {...field}
                          onChange={(e, data) =>
                            field.onChange({
                              target: { value: data.value, name: 'customer' },
                            } as any)
                          }
                        />
                        // <FeathersDropdown
                        //   search
                        //   selection
                        //   fluid
                        //   label='Customer'
                        //   serviceName='/customers'
                        //   query={{
                        //     $sort: { organizationName: 1 },
                        //     $select: ["_id", "organizationName", "primaryContact", "humanId"],
                        //     $np: 1
                        //   }}
                        //   resultMapper={(r) => {
                        //     const companyName = r.organizationName || "";
                        //     const contactName =
                        //       r.primaryContact &&
                        //       `${r.primaryContact.firstName} ${r.primaryContact.lastName}`;
                        //     return ({
                        //       value: r._id,
                        //       text: `${companyName} ${contactName} - ${r.humanId}`
                        //     })
                        //   }}
                        //   {...field}
                        //   onChange={(e, data) => field.onChange({ target: { value: data.value, name: 'customer' } } as any)}
                        // />
                      )}
                    />
                  </div>
                  <Form.Field>
                    <label>&nbsp;</label>
                    <Button
                      type="button"
                      content="New Customer"
                      onClick={() => setIsOpenNewCustomerModal(true)}
                    />
                  </Form.Field>
                </Form.Group>

                <Form.Group widths="equal">
                  <Form.Field>
                    <SemanticField
                      component={Form.Input}
                      fluid
                      name={'settings.theme'}
                      label="Theme"
                    />
                  </Form.Field>
                </Form.Group>

                <Form.Group widths="equal">
                  <Form.Field>
                    <SemanticField
                      component={Form.Dropdown}
                      fluid
                      selection
                      multiple
                      search
                      allowAdditions
                      name={'costCenters'}
                      options={
                        values.costCenters
                          ? values.costCenters.map((c) => ({ value: c, text: c }))
                          : []
                      }
                      label="Cost Centers"
                      onAddItem={handleAddition}
                    />
                  </Form.Field>
                </Form.Group>

                {/* <Form.Group widths="equal">
              <Form.Field>
                <SemanticField name={'requireCostCenterForCheckout'} label='Require Valid Cost Center for Checkout' component={Form.Checkbox} />
              </Form.Field>
            </Form.Group>
 */}
                {/* <Form.Group widths="equal">
              <Form.Field>
                <SemanticField name={'settings.showCostCenter'} label='Show Cost Center' component={Form.Checkbox} />
              </Form.Field>
            </Form.Group> */}

                {values.settings && values.settings.useAddressAsShipFrom && (
                  <>
                    <Menu attached="top" pointing secondary>
                      <Menu.Item name="Ship From Address" active={true} />
                    </Menu>
                    <Segment attached="bottom">
                      <SemanticField name={'address.name'} label="Name" component={Form.Input} />
                      <SemanticField
                        name="address.countryCode"
                        label="Country"
                        selection
                        search
                        component={Form.Dropdown}
                        options={
                          countries?.map((c: any) => ({ text: c.name.official, value: c.cca2 })) ??
                          []
                        }
                        fluid
                      />
                      <Form.Group widths="equal">
                        <SemanticField
                          name={'address.address1'}
                          label="Address 1"
                          component={Form.Input}
                        />
                        <SemanticField
                          name={'address.address2'}
                          label="Address 2"
                          component={Form.Input}
                        />
                      </Form.Group>

                      <Form.Group widths="equal">
                        <Form.Field>
                          <SemanticField
                            name={'address.city'}
                            label="City"
                            component={Form.Input}
                          />
                        </Form.Field>
                        <Form.Field>
                          {values?.address?.countryCode === 'US' ||
                          values?.address?.countryCode === 'CA' ||
                          values?.address?.countryCode === 'AU' ? (
                            <>
                              <label>
                                {values?.address?.countryCode === 'AU'
                                  ? 'State/Territory'
                                  : 'State/Province'}
                              </label>
                              <Dropdown
                                name={'address.stateProvince'}
                                options={countryStateProvinces(values?.address?.countryCode)}
                                fluid
                                selection
                                search
                                value={values?.address?.stateProvince ?? ''}
                                onChange={(e, data) => setFieldValue(data.name, data.value)}
                              />
                            </>
                          ) : (
                            <SemanticField
                              name={'address.stateProvince'}
                              component={Form.Input}
                              fluid
                              label="State/Province"
                              placeholder="State/Province..."
                            />
                          )}
                        </Form.Field>
                        <Form.Field>
                          <SemanticField
                            name={'address.postalCode'}
                            label="Postal Code"
                            component={Form.Input}
                          />
                        </Form.Field>
                      </Form.Group>
                    </Segment>
                  </>
                )}

                <Menu attached="top" pointing secondary>
                  <Menu.Item name="Cost Centers 2" active={true} />
                </Menu>
                <Segment attached="bottom">
                  <FieldArray
                    name="costCenters2"
                    render={(arrayHelpers) => (
                      <>
                        {values?.costCenters2?.map((cc, index) => (
                          <Form.Group widths="equal" key={index}>
                            <SemanticField
                              component={Form.Input}
                              label="Code"
                              name={`costCenters2.${index}.code`}
                            />
                            <SemanticField
                              component={Form.Input}
                              label="Description"
                              name={`costCenters2.${index}.description`}
                            />
                            <div>
                              <div className="field">
                                <label>&nbsp;</label>
                              </div>
                              <Button
                                type="button"
                                icon="remove"
                                onClick={() => arrayHelpers.remove(index)}
                              />
                            </div>
                          </Form.Group>
                        ))}
                        <Form.Group>
                          <div className="field">
                            <Button type="button" size="tiny" onClick={() => arrayHelpers.push('')}>
                              Add New
                            </Button>
                          </div>
                        </Form.Group>
                      </>
                    )}
                  />
                </Segment>

                <Menu attached="top" pointing secondary>
                  <Menu.Item name="Signup - Approved Domains" active={true} />
                </Menu>
                <Segment attached="bottom">
                  <FieldArray
                    name="settings.approvedDomains"
                    render={(arrayHelpers) => (
                      <>
                        {values.settings &&
                          values.settings.approvedDomains &&
                          values.settings.approvedDomains.length > 0 &&
                          values.settings.approvedDomains.map((cc, index) => (
                            <Form.Group widths="equal" key={index}>
                              <SemanticField
                                component={Form.Input}
                                label="Domain"
                                name={`settings.approvedDomains.${index}.domain`}
                              />

                              <div>
                                <div className="field">
                                  <label>&nbsp;</label>
                                </div>
                                <Button
                                  type="button"
                                  icon="remove"
                                  onClick={() => arrayHelpers.remove(index)}
                                />
                              </div>
                            </Form.Group>
                          ))}
                        <Form.Group>
                          <div className="field">
                            <Button
                              type="button"
                              size="tiny"
                              onClick={() => arrayHelpers.push({ domain: '' })}
                            >
                              Add New
                            </Button>
                          </div>
                        </Form.Group>
                      </>
                    )}
                  />
                </Segment>

                <Menu attached="top" pointing secondary>
                  <Menu.Item name="Handling Fees" active={true} />
                </Menu>
                <Segment attached="bottom">
                  <Form.Group widths="equal">
                    <Form.Field>
                      <SemanticField
                        component={Form.Input}
                        label="Per Order"
                        name="settings.handlingFees.perOrder"
                      />
                      <ErrorMessage
                        render={(msg) => <Message negative size="tiny" content={msg} />}
                        name="settings.handlingFees.perOrder"
                      />
                    </Form.Field>
                    <Form.Field>
                      <SemanticField
                        component={Form.Input}
                        label="Per Item "
                        name="settings.handlingFees.perItem"
                      />
                      <ErrorMessage
                        render={(msg) => <Message negative size="tiny" content={msg} />}
                        name="settings.handlingFees.perItem"
                      />
                    </Form.Field>
                  </Form.Group>
                </Segment>

                <Menu attached="top" pointing secondary>
                  <Menu.Item name="Fulfillment Fees" active={true} />
                </Menu>
                <Segment attached="bottom">
                  <Form.Group widths="equal">
                    <Form.Field>
                      <SemanticField
                        component={Form.Input}
                        label="Per Order"
                        name="settings.fulfillmentFees.perOrder"
                      />
                      <ErrorMessage
                        render={(msg) => <Message negative size="tiny" content={msg} />}
                        name="settings.fulfillmentFees.perOrder"
                      />
                    </Form.Field>
                    <Form.Field>
                      <SemanticField
                        component={Form.Input}
                        label="Per Item"
                        name="settings.fulfillmentFees.perItem"
                      />
                      <ErrorMessage
                        render={(msg) => <Message negative size="tiny" content={msg} />}
                        name="settings.fulfillmentFees.perItem"
                      />
                    </Form.Field>
                    <Form.Field>
                      <SemanticField
                        component={Form.Input}
                        label="Per Item Quantity"
                        name="settings.fulfillmentFees.perItemQuantity"
                      />
                      <ErrorMessage
                        render={(msg) => <Message negative size="tiny" content={msg} />}
                        name="settings.fulfillmentFees.perItemQuantity"
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Field>
                      <SemanticField
                        component={Form.Input}
                        label="Per Item First Quantity"
                        name="settings.fulfillmentFees.perItemFirstQuantity"
                      />
                      <ErrorMessage
                        render={(msg) => <Message negative size="tiny" content={msg} />}
                        name="settings.fulfillmentFees.perItemFirstQuantity"
                      />
                    </Form.Field>
                    <Form.Field>
                      <SemanticField
                        component={Form.Input}
                        label="Per Domestic Shipped Box"
                        name="settings.fulfillmentFees.perShippedBoxDomestic"
                      />
                      <ErrorMessage
                        render={(msg) => <Message negative size="tiny" content={msg} />}
                        name="settings.fulfillmentFees.perShippedBoxDomestic"
                      />
                    </Form.Field>
                    <Form.Field>
                      <SemanticField
                        component={Form.Input}
                        label="Per International Shipped Box"
                        name="settings.fulfillmentFees.perShippedBoxInternational"
                      />
                      <ErrorMessage
                        render={(msg) => <Message negative size="tiny" content={msg} />}
                        name="settings.fulfillmentFees.perShippedBoxInternational"
                      />
                    </Form.Field>
                  </Form.Group>
                </Segment>

                <Menu attached="top" pointing secondary>
                  <Menu.Item name="Shipping Carrier Accounts" active={true} />
                </Menu>
                <Segment attached="bottom">
                  <FieldArray
                    name="settings.easypostCarrierAccounts"
                    render={(arrayHelpers) => (
                      <>
                        {carriers?.map((carrier, index) => (
                          <Form.Group widths="equal" key={index}>
                            <Form.Checkbox
                              label={`${carrier.name} ${carrier.easypostObject?.credentials?.account_number}`}
                              value={carrier._id}
                              checked={values?.settings?.easypostCarrierAccounts?.some(
                                (c) => c.id === carrier._id
                              )}
                              onChange={(e, data) => {
                                const idx = values?.settings?.easypostCarrierAccounts?.findIndex(
                                  (c) => c.id === carrier._id
                                );

                                const changeValue = () =>
                                  arrayHelpers.replace(idx, {
                                    id: carrier._id,
                                    isEnabled: data.checked,
                                  });

                                if (data.checked) {
                                  if (idx >= 0) {
                                    changeValue();
                                  } else {
                                    arrayHelpers.push({ id: carrier._id, isEnabled: data.checked });
                                  }
                                } else {
                                  // changeValue()
                                  arrayHelpers.remove(idx);
                                }
                              }}
                            />
                          </Form.Group>
                        ))}
                      </>
                    )}
                  />
                  <ErrorMessage
                    render={(msg) => <Message negative size="tiny" content={msg} />}
                    name="settings.easypostCarrierAccounts"
                  />
                </Segment>

                {hasCarrierAccounts && (
                  <>
                    <Menu attached="top" pointing secondary>
                      <Menu.Item name="Shipping - 3rd Party Billing" active={true} />
                    </Menu>
                    <Segment attached="bottom">
                      <Form.Group widths="equal">
                        <Form.Field>
                          <SemanticField
                            name={'settings.shipping3rdParty.isEnabled'}
                            label="Enabled"
                            component={Form.Checkbox}
                          />
                        </Form.Field>
                      </Form.Group>
                      {values?.settings?.shipping3rdParty?.isEnabled && (
                        <>
                          <Message content="Ensure the 3rd party account entered is for the same carrier type being used. For example, do not enter a UPS 3rd party account and have a FedEx account carrier enabled" />
                          <Form.Group widths="equal">
                            <SemanticField
                              component={Form.Input}
                              label="Account"
                              name="settings.shipping3rdParty.payment.account"
                            />
                          </Form.Group>
                          <Form.Group widths="equal">
                            <SemanticField
                              component={Form.Input}
                              label="Country Code"
                              name="settings.shipping3rdParty.payment.countryCode"
                            />
                          </Form.Group>
                          <Form.Group widths="equal">
                            <SemanticField
                              component={Form.Input}
                              label="Postal Code"
                              name="settings.shipping3rdParty.payment.postalCode"
                            />
                          </Form.Group>
                        </>
                      )}
                    </Segment>
                  </>
                )}

                <Menu attached="top" pointing secondary>
                  <Menu.Item name="Payment Gateway" active={true} />
                </Menu>
                <Segment attached="bottom">
                  <Form.Group widths="equal">
                    <SemanticField
                      name="settings.payment.methods"
                      component={Form.Dropdown}
                      label="Methods"
                      selection
                      multiple
                      options={paymentMethodOptions}
                    />
                  </Form.Group>
                  <Form.Group widths="equal">
                    <SemanticField
                      name="settings.payment.merchantGateway.selectedAdapter"
                      component={Form.Dropdown}
                      label="Merchant"
                      selection
                      clearable
                      options={paymentSelectionOptions}
                      onChange={(v) => {
                        if (v === 'stripe') {
                          setFieldValue(
                            'settings.payment.merchantGateway.adapters.stripe.key',
                            'stripe'
                          );
                          setFieldValue(
                            'settings.payment.merchantGateway.adapters.authorizeNet',
                            undefined
                          );
                        } else if (v === 'authorizeNet') {
                          setFieldValue(
                            'settings.payment.merchantGateway.adapters.authorizeNet.key',
                            'authorizeNet'
                          );
                          setFieldValue(
                            'settings.payment.merchantGateway.adapters.stripe',
                            undefined
                          );
                        } else {
                          setFieldValue('settings.payment.merchantGateway', null);
                        }
                      }}
                    />
                  </Form.Group>
                  {values?.settings?.payment?.merchantGateway?.selectedAdapter === 'stripe' ? (
                    <Form.Group widths="equal" key="stripe">
                      <SemanticField
                        name="settings.payment.merchantGateway.adapters.stripe.secret"
                        component={Form.Input}
                        label="Secret Key"
                      />
                      <SemanticField
                        name="settings.payment.merchantGateway.adapters.stripe.publishable"
                        component={Form.Input}
                        label="Publishable Key"
                      />
                    </Form.Group>
                  ) : (
                    values?.settings?.payment?.merchantGateway?.selectedAdapter ===
                      'authorizeNet' && (
                      <Form.Group widths="equal" key="authorizeNet">
                        <SemanticField
                          name="settings.payment.merchantGateway.adapters.authorizeNet.login"
                          component={Form.Input}
                          label="Login"
                        />
                        <SemanticField
                          name="settings.payment.merchantGateway.adapters.authorizeNet.transaction"
                          component={Form.Input}
                          label="Transaction Key"
                        />
                        <SemanticField
                          name="settings.payment.merchantGateway.adapters.authorizeNet.clientKey"
                          component={Form.Input}
                          label="Client Key"
                        />
                      </Form.Group>
                    )
                  )}
                  <Form.Group widths="equal">
                    <SemanticField
                      name="settings.payment.currency"
                      component={Form.Input}
                      label="Currency"
                    />
                  </Form.Group>
                </Segment>

                {isOpenModal && (
                  <ImageUploadModal
                    isOpen={isOpenModal}
                    onClose={() => setIsOpenModal(false)}
                    setFieldValue={setFieldValue}
                    fieldName="settings.logo"
                  />
                )}
              </Segment>

              <Header
                as="h4"
                content="Store Front"
                subheader="Access and User Policies"
                attached="top"
              />
              <Segment secondary attached="bottom">
                <Grid divided="vertically">
                  <Grid.Row>
                    <Grid.Column width="3">Approved User Login Only</Grid.Column>
                    <Grid.Column width="13">
                      <SemanticField
                        component={Form.Checkbox}
                        toggle
                        name={'settings.restrictToApprovedUsers'}
                        label="Only approved users can log in to the site"
                      />
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column width="3">User Signup Enabled</Grid.Column>
                    <Grid.Column width="13">
                      <SemanticField
                        component={Form.Checkbox}
                        toggle
                        name={'settings.allowSiteSignup'}
                        label="New users are able to signup for access. If not enabled, users can only be invited or onboarded by an admin"
                      />
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column width="3">Signup reCAPTCHA</Grid.Column>
                    <Grid.Column width="13">
                      <SemanticField
                        component={Form.Checkbox}
                        toggle
                        name="settings.signUpRequiredCaptcha"
                        label="Display and require use to click a reCAPTCHA in the site signup form"
                      />
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column width="3">Customer Profile Read Only</Grid.Column>
                    <Grid.Column width="13">
                      <SemanticField
                        component={Form.Checkbox}
                        toggle
                        name={'settings.profileReadOnly'}
                        label="Users can see cannot change their profile info"
                      />
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column width="3">Private Site</Grid.Column>
                    <Grid.Column width="13">
                      <SemanticField
                        component={Form.Checkbox}
                        toggle
                        name={'settings.private'}
                        label="Site is protected behind a login and only authenticated users can use the site"
                      />
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column width="3">Microsite Ship From Address</Grid.Column>
                    <Grid.Column width="13">
                      <SemanticField
                        component={Form.Checkbox}
                        toggle
                        name={'settings.useAddressAsShipFrom'}
                        label="Use Address as Ship From"
                      />
                      {values.settings && values.settings.useAddressAsShipFrom && (
                        <>
                          <Segment attached="bottom">
                            <Form.Group widths="equal">
                              <SemanticField
                                name={'address.name'}
                                label="Name"
                                component={Form.Input}
                              />
                            </Form.Group>
                            <Form.Group widths="equal">
                              <SemanticField
                                name="address.countryCode"
                                label="Country"
                                selection
                                search
                                component={Form.Dropdown}
                                options={
                                  countries?.map((c: any) => ({
                                    text: c.name.official,
                                    value: c.cca2,
                                  })) ?? []
                                }
                                fluid
                              />
                            </Form.Group>
                            <Form.Group widths="equal">
                              <SemanticField
                                name={'address.address1'}
                                label="Address 1"
                                component={Form.Input}
                              />
                              <SemanticField
                                name={'address.address2'}
                                label="Address 2"
                                component={Form.Input}
                              />
                            </Form.Group>

                            <Form.Group widths="equal">
                              <Form.Field>
                                <SemanticField
                                  name={'address.city'}
                                  label="City"
                                  component={Form.Input}
                                />
                              </Form.Field>
                              <Form.Field>
                                {values?.address?.countryCode === 'US' ||
                                values?.address?.countryCode === 'CA' ||
                                values?.address?.countryCode === 'AU' ? (
                                  <>
                                    <label>
                                      {values?.address?.countryCode === 'AU'
                                        ? 'State/Territory'
                                        : 'State/Province'}
                                    </label>
                                    <Dropdown
                                      name={'address.stateProvince'}
                                      options={countryStateProvinces(values?.address?.countryCode)}
                                      fluid
                                      selection
                                      search
                                      value={values?.address?.stateProvince ?? ''}
                                      onChange={(e, data) => setFieldValue(data.name, data.value)}
                                    />
                                  </>
                                ) : (
                                  <SemanticField
                                    name={'address.stateProvince'}
                                    component={Form.Input}
                                    fluid
                                    label="State/Province"
                                    placeholder="State/Province..."
                                  />
                                )}
                              </Form.Field>
                              <Form.Field>
                                <SemanticField
                                  name={'address.postalCode'}
                                  label="Postal Code"
                                  component={Form.Input}
                                />
                              </Form.Field>
                            </Form.Group>
                          </Segment>
                        </>
                      )}
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column width="3">User Signup Default Groups</Grid.Column>
                    <Grid.Column width="13">
                      <Form.Field>
                        <Field
                          name="settings.userSignupDefaultGroups"
                          render={({ field }: FieldProps<any>) => (
                            <FeathersDropdown
                              search
                              selection
                              fluid
                              multiple
                              label="Customer Groups"
                              serviceName="/microsites/customer/groups"
                              query={{
                                $sort: { name: 1 },
                                $select: ['name'],
                                microsite: values._id,
                                $np: 1,
                              }}
                              resultMapper={(r) => ({
                                value: r._id,
                                text: r.name,
                              })}
                              {...field}
                              onChange={(e, data) =>
                                field.onChange({
                                  target: { value: data.value, name: field.name },
                                } as any)
                              }
                            />
                          )}
                        />
                      </Form.Field>
                      <span>
                        When a new user signs up, they will automatically be placed in these groups
                      </span>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>

              <Header
                as="h4"
                content="Store Front"
                subheader="Cart and Checkout Policies"
                attached="top"
              />
              <Segment secondary attached="bottom">
                <Grid divided="vertically">
                  {values?.settings?.shipOutAddressInputMethod === 'open' && (
                    <Grid.Row>
                      <Grid.Column width="3">Enable Cart Address Book</Grid.Column>
                      <Grid.Column width="13">
                        <Form.Group widths="equal">
                          <Form.Field>
                            <SemanticField
                              component={Form.Checkbox}
                              toggle
                              name={'settings.isCartAddressBookEnabled'}
                              label="Enable address book on the cart"
                            />
                          </Form.Field>
                        </Form.Group>
                      </Grid.Column>
                    </Grid.Row>
                  )}

                  <Grid.Row>
                    <Grid.Column width="3">Enable Cost Center Input</Grid.Column>
                    <Grid.Column width="13">
                      <SemanticField
                        component={Form.Checkbox}
                        toggle
                        name={'settings.showCostCenter'}
                        onChange={(e, data) => {
                          // NOTE: We have new payment method "Invoice" where a user enters in
                          // a cost center. This is not the same as the cost center that is
                          // used for the cart. So we need to separate the two. For now I will disable the defaulting
                          // on "isCostCenterDropdown"


                          // setFieldValue('settings.isCostCenterDropdown', false);
                          setFieldValue('requireCostCenterForCheckout', false);
                          setFieldValue('settings.useCostCenterFromUser', false);
                        }}
                        label="Show Cost Center input on the checkout page"
                      />
                    </Grid.Column>
                  </Grid.Row>

                  {values.settings.showCostCenter && (
                    <>
                      <Grid.Row>
                        <Grid.Column width="3"></Grid.Column>
                        <Grid.Column width="13">
                          <SemanticField
                            component={Form.Checkbox}
                            toggle
                            name={'settings.isCostCenterDropdown'}
                            label="Show Cost Center as a Dropdown"
                          />
                          <SemanticField
                            component={Form.Checkbox}
                            toggle
                            name={'requireCostCenterForCheckout'}
                            label="Require Cost Center for Checkout"
                          />
                          <SemanticField
                            component={Form.Checkbox}
                            toggle
                            name={'settings.isCostCenterValidationEnabled'}
                            label="
                          Validate Cost Centers againsts real values
                            "
                          />
                          <SemanticField
                            component={Form.Checkbox}
                            toggle
                            name={'settings.useCostCenterFromUser'}
                            label="User's cost center will be applied to the order. Cost Center input becomes read-only"
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </>
                  )}

                  <Grid.Row>
                    <Grid.Column width="3">Enable Promo Code Input</Grid.Column>
                    <Grid.Column width="13">
                      <SemanticField
                        component={Form.Checkbox}
                        toggle
                        name={'settings.promoCodeInput'}
                        label="Show Promo Code input on the checkout page"
                      />
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column width="3">Recent Addresses Enabled</Grid.Column>
                    <Grid.Column width="13">
                      <SemanticField
                        component={Form.Checkbox}
                        toggle
                        name={'settings.isUseRecentAddressEnabled'}
                        label="Enables users to pick from recently used addresses on the cart page"
                      />
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column width="3">Guest Checkout Enabled</Grid.Column>
                    <Grid.Column width="13">
                      <SemanticField
                        component={Form.Checkbox}
                        toggle
                        name={'settings.isGuestCheckoutEnabled'}
                        label="Enable Guest Checkout"
                      />
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column width="3">PO Enabled</Grid.Column>
                    <Grid.Column width="13">
                      <SemanticField
                        component={Form.Checkbox}
                        toggle
                        name={'settings.isPOInputEnabled'}
                        label="Enable PO Input on Checkout"
                      />
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column width="3">Overlimit and Approval Enabled</Grid.Column>
                    <Grid.Column width="13">
                      <SemanticField
                        component={Form.Checkbox}
                        toggle
                        name={'settings.allowOverLimitAndTriggerApproval'}
                        label="Allow users to place orders exceeding   ined limits but trigger an order approval"
                      />
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column width="3">Composite Order Limit Policy</Grid.Column>
                    <Grid.Column width="13">
                      <SemanticField
                        component={Form.Checkbox}
                        toggle
                        name={'settings.compositeTopLevelLimit'}
                        onChange={(data) =>
                          setFieldValue('settings.compositeTopLevelLimit', Boolean(data))
                        }
                        defaultChecked={values?.settings?.compositeTopLevelLimit}
                        label="Enable to make composite count at the top level instead of exploded item level"
                      />
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column width="3">Backorders Enabled</Grid.Column>
                    <Grid.Column width="13">
                      <SemanticField
                        component={Form.Checkbox}
                        toggle
                        name={'settings.allowBackorders'}
                        label="Allow users to add products to cart even if the items are out of stock. Items will be in backorder status"
                      />
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column width="3">Saved Cart Items Enabled</Grid.Column>
                    <Grid.Column width="13">
                      <SemanticField
                        component={Form.Checkbox}
                        toggle
                        name={'settings.allowSavedItems'}
                        label='Users can save cart items "for later"'
                      />
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column width="3">Ship From Input</Grid.Column>
                    <Grid.Column width="13">
                      <SemanticField
                        component={Form.Checkbox}
                        toggle
                        name={'settings.shipFromControl'}
                        label='Allow users to enter in a ship from address. Typically used for blind shipping"'
                      />
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column width="3">Pricing Visiblity</Grid.Column>
                    <Grid.Column width="13">
                      <SemanticField
                        component={Form.Checkbox}
                        toggle
                        name={'settings.hidePrices'}
                        label="Hide prices on products, in the cart, and on checkout"
                      />
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column width="3">Orders Require Admin Approval</Grid.Column>
                    <Grid.Column width="13">
                      <SemanticField
                        component={Form.Checkbox}
                        toggle
                        name={'settings.orderNeedsAdminApproval'}
                        label="All orders will automatically trigger a required admin approval"
                      />
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column width="3">Additional Email Recipients Enabled</Grid.Column>
                    <Grid.Column width="13">
                      <SemanticField
                        component={Form.Checkbox}
                        toggle
                        name={'settings.isAdditionalEmailRecipientsEnabled'}
                        label="Enables additional email recipients for email notifications for the order"
                      />
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column width="3">Order Misc Info Enabled</Grid.Column>
                    <Grid.Column width="13">
                      <SemanticField
                        component={Form.Checkbox}
                        toggle
                        name={'settings.isOrderMiscInfoEnabled'}
                        label="Enables order miscellaneous information"
                        onChange={(e, data) => setFieldValue('settings.orderMiscInfoLabel', '')}
                      />

                      {values?.settings?.isOrderMiscInfoEnabled && (
                        <>
                          <Segment attached="bottom">
                            <SemanticField
                              component={Form.Input}
                              name={'settings.orderMiscInfoLabel'}
                              label="Order Misc Info Label"
                            />
                          </Segment>

                          <SemanticField
                            component={Form.Checkbox}
                            toggle
                            name={'settings.isOrderMiscInfoRequired'}
                            label="Required order miscellaneous information"
                          />
                        </>
                      )}
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column width="3">Consolidate Cart Items</Grid.Column>
                    <Grid.Column width="13">
                      <SemanticField
                        component={Form.Checkbox}
                        toggle
                        name={'settings.consolidateCartItems'}
                        label="Consolidate quantities when same product is added to cart"
                      />
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column width="3">3rd Party Billing</Grid.Column>
                    <Grid.Column width="13">
                      <SemanticField
                        component={Form.Checkbox}
                        toggle
                        name={'settings.isSFShipBill3PEnabled'}
                        label="Enable 3rd Party Billing Input"
                      />
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column width="3">Arrive By Date Enabled</Grid.Column>
                    <Grid.Column width="13">
                      <SemanticField
                        component={Form.Checkbox}
                        toggle
                        name={'settings.isArriveByDayEnabled'}
                        label="Enables arrive by date"
                      />

                      {values?.settings.isArriveByDayEnabled && (
                        <Segment attached="bottom">
                          <SemanticField
                            component={Form.Input}
                            name={'settings.arriveByDateLabel'}
                            label="Arrive by Date Label"
                          />
                        </Segment>
                      )}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>

              <Header as="h4" content="Store Front" subheader="Shipping Policies" attached="top" />
              <Segment secondary attached="bottom">
                <Grid divided="vertically">
                  <Grid.Row>
                    <Grid.Column width="3">Shipping Methods</Grid.Column>
                    <Grid.Column width="13">
                      <Form.Field>
                        <SemanticField
                          component={Form.Dropdown}
                          fluid
                          selection
                          multiple
                          search
                          name={'settings.shippingMethods'}
                          options={shippingMethodOptions}
                        />
                      </Form.Field>
                      <span>Shipping methods that users can select from when ordering</span>
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column width="3">Shipping Services</Grid.Column>
                    <Grid.Column width="13">
                      {/* <SemanticField
                      component={Form.Dropdown}
                      fluid selection multiple search
                      name={'settings.allowedShippingServicesOnCart'}
                      options={servicesOptions?.map((service) => ({ text: humanize(service), value: service }))}
                    /> */}
                      <Form.Field>
                        <Field
                          name="settings.allowedShippingServicesOnCart"
                          render={({ field }: FieldProps<any>) => (
                            <Select
                              isMulti
                              {...field}
                              placeholder=""
                              options={groupByKey(
                                shippingServicesOptions,
                                'carrier',
                                'carrierLabel'
                              )}
                              styles={selectStyles}
                              onChange={(e) =>
                                field.onChange({ target: { value: e, name: field.name } } as any)
                              }
                            />
                            // <FeathersDropdown
                            //   fluid selection multiple search
                            //   serviceName='/tenant-settings/shipping-services-options'
                            //   query={{}}
                            //   resultMapper={(r) => ({ text: humanize(r), value: r })}
                            //   {...field}
                            //   onChange={(e, data) => field.onChange({ target: { value: data.value, name: field.name } } as any)}
                            // />
                          )}
                        />
                      </Form.Field>
                      <span>Allow Shipping Services On Cart</span>
                      {shippingServiceMessage && (
                        <Message
                          info
                          content="Reminder: Do you also want to enable FedEx Home delivery? FedEx splits commercial And residential into two separate services. You most likely want both enabled."
                        />
                      )}
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column width="3">Free Shipping Enabled</Grid.Column>
                    <Grid.Column width="13">
                      <SemanticField
                        name={'settings.isFreeShippingEnabled'}
                        toggle
                        component={Form.Checkbox}
                        label={'Enable Free shipping'}
                      />
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column width="3">Show Negotiated Shipping Rates</Grid.Column>
                    <Grid.Column width="13">
                      <SemanticField
                        name={'settings.showNegotiatedRates'}
                        toggle
                        component={Form.Checkbox}
                        label={'Show negotiated shipping rates instead of published rates'}
                      />
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column width="3">Display Shipping Service Prices</Grid.Column>
                    <Grid.Column width="13">
                      <SemanticField
                        name={'settings.showShippingRates'}
                        toggle
                        component={Form.Checkbox}
                        label={
                          'Show shipping rate prices on the cart and order details. Toggle off to hide shipping rates'
                        }
                      />
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column width="3">International Shipping Enabled</Grid.Column>
                    <Grid.Column width="13">
                      <SemanticField
                        name={'settings.isIntlShippingEnabled'}
                        toggle
                        component={Form.Checkbox}
                        label={'Enable International Shipping'}
                      />
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column width="3">Ship To Address Restrictions</Grid.Column>
                    <Grid.Column width="13">
                      <SemanticField
                        component={Form.Dropdown}
                        fluid
                        selection
                        search
                        name={'settings.shipOutAddressInputMethod'}
                        options={shipOutOptions}
                        placeholder={'Select an option...'}
                      />
                      <ul>
                        <li>Open allows a user to enter in any ship to address</li>
                        <li>
                          Address book allows the user to only select an address from the address
                          book which is read-only
                        </li>
                        <li>
                          Read-only does not allow the user to update the ship to address which is
                          populated from the user's profile
                        </li>
                      </ul>
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column width="3">Address Verification</Grid.Column>
                    <Grid.Column width="13">
                      <SemanticField
                        component={Form.Dropdown}
                        fluid
                        selection
                        name={'settings.addressVerification'}
                        value={
                          values?.settings?.addressVerification ??
                          addressVerificationOptions[0].value
                        }
                        defaultValue={
                          values?.settings?.addressVerification ??
                          addressVerificationOptions[0].value
                        }
                        options={addressVerificationOptions}
                        placeholder={'Select an option...'}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>

              <Segment basic>
                <Button primary type="submit" disabled={isSubmitting && !errorMessage}>
                  Save
                </Button>
              </Segment>
            </FormikForm>
          );
        }}
      </Formik>
      {errorMessage && (
        <>
          <Message error content={errorMessage} />
          <Divider hidden />
        </>
      )}

      {isOpenNewCustomerModal && (
        <CustomerFormModal
          selectNewCustomer={(v: string) => setNewCustomer(v)}
          onReload={() =>
            setIsReload({
              ...isReload,
              customer: true,
            })
          }
          onClose={() => setIsOpenNewCustomerModal(false)}
        />
      )}
    </React.Fragment>
  );
};
