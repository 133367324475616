import React from 'react';
import {
  Font,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Image,
} from '@react-pdf/renderer';
/* eslint-disable no-new-func */

import * as Babel from '@babel/standalone';

export interface ScopeDeps {
  [key: string]: any;
}

export function stringToReactElement(code: string, scopeDeps: ScopeDeps): React.ElementType {
  // console.log({ code, scopeDeps });

  if (!scopeDeps.initialState) {
    return () => null;
  }

  try {
    const injectedCode = `() => {
      return React.createElement(
        Injector, { initialState: initialState }, ${code.slice(0, -1)}
      );
    }`;

    const transformResult = Babel.transform(injectedCode, {
      presets: ['es2015', 'react'],
      comments: false,
    }).code;

    const functionParams = Object.keys(scopeDeps);
    const functionArgs = Object.values(scopeDeps);
    const functionBody = `return ${transformResult.slice(15)}`;

    const functionInstance = new Function(...functionParams, functionBody);

    const t = functionInstance(...functionArgs);
    return t;
  } catch (e) {
    // console.log('caught this one!!!!', e.message)
    // TODO: Capture errors so they can be shown somehwere on the UI for the user

    return () => (
      <Document>
        <Page>
          <View>
            <Text style={{ fontSize: 10, color: 'red' }}>{e.message}</Text>
          </View>
        </Page>
      </Document>
    );

    // return () => null;
  }
}
