import React from 'react';
import { View, Text, Image, StyleSheet } from '@react-pdf/renderer';

const dtStyles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    marginBottom: 6,
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  row: {
    flexDirection: 'column',
    fontSize: 10,
    width: '33.33%',
  },
  label: {
    textTransform: 'uppercase',
    fontWeight: 'semibold',
    fontSize: '8pt',
    marginRight: 8,
    letterSpacing: '1.1',
    color: '#4A5568',

    textAlign: 'left',
  },
});

interface IDefinitionTableProps {
  data: { label: string; value: string | number }[][];
}

export default function DefinitionTable(props: IDefinitionTableProps) {
  const { data } = props;

  return (
    <>
      {data?.map((row, index) => (
        <View key={index} style={dtStyles.container}>
          {row.map((item, idx2) => (
            <View key={idx2} style={dtStyles.row}>
              <Text style={dtStyles.label}>{item.label}</Text>
              <Text>{item.value}</Text>
            </View>
          ))}
        </View>
      ))}
    </>
  );
}
