// import * as React from 'react';
// import { connect } from 'react-redux';
// import { InjectedFormProps, Field, reduxForm, formValueSelector, FormProps, FieldArray } from 'redux-form';
// import { Link } from 'react-router-dom';
// import { Button, Divider, Form, Header, Segment } from 'semantic-ui-react';
// import { semanticFormField, usStateProvinces, semanticFormDropdownField, Languages } from './../../../common';
// import Feathers from './../../../bootstrap/feathers';
// import { EntityDropdownWrapper2 } from './../../../components/EntityDropdown';
// import { optionLanguages } from './../../../common/translations/languages';
// import Label from 'semantic-ui-react/dist/commonjs/elements/Label/Label';

// type TranslatorFormProps = {
//   isEdit: boolean;
// } & InjectedFormProps

// const validate = (values, props) => {
//   return null;
// }

// const languagesDropdown = EntityDropdownWrapper2('/products-legacy/attributes', {
//   query: {
//     $np: 1,
//     $sort: { name: 1 },
//     entityAttribute: { $eq: 'SRC_LANG' }
//   }
// }, {
//   resultMap: (row) => ({ value: row._id, text: row.name }),
//   searchMap: (qs) => ({ name: { $LIKE: qs } })
// });

// const languagesDropdownSrc = EntityDropdownWrapper2('/products-legacy/attributes', {
//   query: {
//     $np: 1,
//     $sort: { name: 1 },
//     entityAttribute: { $eq: 'SRC_LANG' }
//   }
// }, {
//   resultMap: (row) => ({ value: row._id, text: row.name }),
//   searchMap: (qs) => ({ name: { $LIKE: qs } })
// });

// const languagesDropdownDest = EntityDropdownWrapper2('/products-legacy/attributes', {
//   query: {
//     $np: 1,
//     $sort: { name: 1 },
//     entityAttribute: { $eq: 'DEST_LANG' }
//   }
// }, {
//   resultMap: (row) => ({ value: row._id, text: row.name }),
//   searchMap: (qs) => ({ name: { $LIKE: qs } })
// });

// const renderLanguageFields = (name, index, fields) => (
//   <div key={`langFields${index}`}>
//     <Divider />
//     <Form.Group widths={'equal'}>
//       <Field
//         name={`${name}.source`}
//         component={semanticFormDropdownField}
//         as={languagesDropdownSrc}
//         multiple={false}
//         label='Source Language'
//         placeholder='Source Language...'
//       />
//       <Field
//         name={`${name}.destinations`}
//         component={semanticFormDropdownField}
//         as={languagesDropdownDest}
//         multiple={true}
//         label='Destination Languages'
//         placeholder='Destination Languages...'
//       />

//       <Button icon={'remove'} primary onClick={() => fields.remove(index)} />
//     </Form.Group>
//   </div>
// );

// const renderLanguages = ({ fields }) => (
//   <div>
//     <Button type="button" onClick={() => fields.push({ source: null, destinations: [] })} content='Add Language Skills' />
//     {fields.map(renderLanguageFields)}
//   </div>
// );

// const asyncValidate = (values, dispatch, props: TranslatorFormProps, blurredField) => {
//   if (blurredField === 'email') {
//     if (!props.isEdit || (props.initialValues && props.initialValues.email !== values.email)) {
//       const { email } = values;
//       return Feathers.service<any>('/translators')
//         .find({ query: { email } })
//         .then((results: any) => {
//           if (results.data.length > 0) {
//             throw { email: 'Email is already used' };
//           }
//         });
//     }
//   }
//   return Promise.resolve();
// }

// export function TranslatorForm(props: TranslatorFormProps) {
//   const { handleSubmit, pristine, reset, submitting } = props;
//   return (
//     <Form onSubmit={handleSubmit}>

//       <Segment>
//         <Label size={'large'} attached={'top'} content={'Contact'} />
//         <Form.Group widths={'equal'}>
//           <Field
//             name='firstName'
//             component={semanticFormField}
//             as={Form.Input}
//             label='First Name'
//           />
//           <Field
//             name='lastName'
//             component={semanticFormField}
//             as={Form.Input}
//             label='Last Name'
//           />
//         </Form.Group>

//         <Form.Group widths={'equal'}>
//           <Field
//             name='address1'
//             component={semanticFormField}
//             as={Form.Input}
//             label='Address 1'
//           />
//           <Field
//             name='address2'
//             component={semanticFormField}
//             as={Form.Input}
//             label='Address 2'
//           />
//         </Form.Group>
//         <Form.Group widths={'equal'}>
//           <Field
//             name='city'
//             component={semanticFormField}
//             as={Form.Input}
//             type='text'
//             label='City'
//             placeholder='City...'
//           />
//           <Field
//             name='stateProvince'
//             component={semanticFormField}
//             options={usStateProvinces}
//             as={Form.Dropdown}
//             fluid selection
//             label='State Province'
//             placeholder='State Province...'
//           />

//           <Field
//             name='postalCode'
//             component={semanticFormField}
//             as={Form.Input}
//             type='text'
//             label='Postal Code'
//             placeholder='Postal Code...'
//           />
//         </Form.Group>
//       </Segment>

//       <Segment >
//         <Label size={'large'} attached={'top'} content={'Rates'} />
//         <Form.Group widths={'equal'}>
//           <Field
//             name='ratePerWord'
//             component={semanticFormField}
//             as={Form.Input}
//             label='Rate Per Word'
//           />
//           <Field
//             name='ratePerHour'
//             component={semanticFormField}
//             as={Form.Input}
//             label='Rate Per Hour'
//           />
//         </Form.Group>
//       </Segment>

//       <Segment>
//         <Label size={'large'} attached={'top'} content={'Skills'} />
//         <div>Native Language</div>
//         <Field
//           name='nativeLanguage'
//           component={semanticFormDropdownField}
//           as={languagesDropdown}
//           multiple={false}
//           label='Native Language'
//           placeholder='Native Language...'
//         />

//         <FieldArray
//           name='languages'
//           component={renderLanguages}
//         />
//       </Segment>

//       {/* <Segment>
//           <Label size={'large'} attached={'top'} content={'Credentials'} />
//           <Form.Group widths={'equal'}>
//             <Field
//               name='password'
//               component={semanticFormField}
//               as={Form.Input}
//               type={'password'}
//               label='Password'
//             />
//             <Field
//               name='passwordRetype'
//               component={semanticFormField}
//               as={Form.Input}
//               type={'password'}
//               label='Retype Password'
//             />
//           </Form.Group>
//         </Segment> */}

//       <Button primary disabled={pristine || submitting}>Save</Button>
//     </Form>
//   );
// }

// const mapStateToProps = (state, ownProps: FormProps) => ({
//   // linkToUser: formValueSelector(ownProps.form)(state, 'linkToUser'),
// });

// const reduxedForm = reduxForm({
//   validate,
//   // asyncValidate,
//   // asyncBlurFields: ['email'],
// })(TranslatorForm);

// export default connect(mapStateToProps)(reduxedForm);

import * as React from 'react';
import { Button, Form, Menu, Dropdown, Message, Segment } from 'semantic-ui-react';
import { Formik, Form as FormikForm, Field, FieldArray, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { SemanticField } from './../../../common/Form';
import { countryStateProvinces } from './../../../common';
import Feathers from './../../../bootstrap/feathers';

import countries from 'world-countries';

import { optionLanguages } from './../../../common/translations/languages';

interface TranslatorFormProps {
  initialValues: any;
  onSubmit: (v) => void;
  errorMessage: string;
}

export const TranslatorForm: React.FC<TranslatorFormProps> = (props) => {
  const { errorMessage } = props;
  const TranslatorSchema = Yup.object().shape({
    email: Yup.string()
      .email('Invalid email')
      .required('Required')
      .trim()
      .test('is-not-used', `Email is already in use`, function (value) {
        if (props.initialValues && props.initialValues._id && props.initialValues.email === value) {
          return Promise.resolve(true);
        }

        return Feathers.service<any>('/translators')
          .find({ query: { email: value } })
          .then((results: any) => results.data.length === 0);
      }),
    address1: Yup.string().trim(),
    address2: Yup.string().trim(),
    city: Yup.string().trim(),
    postalCode: Yup.string().trim(),
    stateProvince: Yup.string().trim(),
    firstName: Yup.string().required('Required').trim(),
    lastName: Yup.string().required('Required').trim(),
    ratePerWord: Yup.number().typeError('Must be a number'),
    ratePerHour: Yup.number().typeError('Must be a number'),
    languages: Yup.array().of(
      Yup.object().shape({
        source: Yup.string().required('Required'),
        destinations: Yup.array().of(Yup.string().required('Required')),
      })
    ),
  });

  return (
    <Formik
      initialValues={props.initialValues}
      validationSchema={TranslatorSchema}
      onSubmit={(values, { setSubmitting }) => {
        const castValues = TranslatorSchema.cast(values);
        props.onSubmit(castValues);
      }}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
          setFieldValue,
          setFieldTouched,
        } = props;

        return (
          <FormikForm
            className={`ui form ${isSubmitting ? 'loading' : ''} ${errorMessage ? 'error' : ''}`}
          >
            <Menu attached="top" pointing secondary>
              <Menu.Item name="Contact" active={true} />
            </Menu>
            <Segment attached="bottom">
              <Form.Group widths="equal">
                <Form.Field>
                  <SemanticField name="firstName" label="First Name" component={Form.Input} />
                  <ErrorMessage
                    render={(msg) => <Message negative size="tiny" content={msg} />}
                    name="firstName"
                  />
                </Form.Field>
                <Form.Field>
                  <SemanticField name="lastName" label="Last Name" component={Form.Input} />
                  <ErrorMessage
                    render={(msg) => <Message negative size="tiny" content={msg} />}
                    name="lastName"
                  />
                </Form.Field>
              </Form.Group>

              {!values._id ? (
                <>
                  <Form.Group widths="equal">
                    <Form.Field>
                      <SemanticField name={'email'} label="Email" component={Form.Input} />
                      <ErrorMessage
                        render={(msg) => <Message negative size="tiny" content={msg} />}
                        name="email"
                      />
                    </Form.Field>
                  </Form.Group>
                </>
              ) : (
                <>
                  <Form.Group widths="equal">
                    <Form.Field>
                      <SemanticField
                        name="countryCode"
                        label="Country"
                        selection
                        search
                        component={Form.Dropdown}
                        options={
                          countries?.map((c: any) => ({ text: c.name.official, value: c.cca2 })) ??
                          []
                        }
                        fluid
                      />
                    </Form.Field>
                  </Form.Group>

                  <Form.Group widths="equal">
                    <Form.Field>
                      <SemanticField name="address1" label="Address 1" component={Form.Input} />
                    </Form.Field>
                    <Form.Field>
                      <label>Address 2</label>
                      <Field name="address2" />
                    </Form.Field>
                  </Form.Group>

                  <Form.Group widths="equal">
                    <Form.Field>
                      <SemanticField name="city" label="City" component={Form.Input} />
                      <ErrorMessage
                        render={(msg) => <Message negative size="tiny" content={msg} />}
                        name="city"
                      />
                    </Form.Field>

                    <Form.Field>
                      {values?.countryCode === 'US' ||
                      values?.countryCode === 'CA' ||
                      values?.countryCode === 'AU' ? (
                        <>
                          <label>
                            {values?.countryCode === 'AU' ? 'State/Territory' : 'State/Province'}
                          </label>
                          <Dropdown
                            name={'stateProvince'}
                            options={countryStateProvinces(values?.countryCode)}
                            fluid
                            selection
                            search
                            value={values?.stateProvince ?? ''}
                            onChange={(e, data) => setFieldValue(data.name, data.value)}
                          />
                        </>
                      ) : (
                        <SemanticField
                          name={'stateProvince'}
                          component={Form.Input}
                          fluid
                          label="State/Province"
                          placeholder="State/Province..."
                        />
                      )}
                      <ErrorMessage
                        render={(msg) => <Message negative size="tiny" content={msg} />}
                        name="stateProvince"
                      />
                    </Form.Field>
                    <Form.Field>
                      <SemanticField name="postalCode" label="Postal Code" component={Form.Input} />
                      <ErrorMessage
                        render={(msg) => <Message negative size="tiny" content={msg} />}
                        name="postalCode"
                      />
                    </Form.Field>
                    <Form.Field>
                      <SemanticField name={'phone'} label="Phone" component={Form.Input} />
                      <ErrorMessage
                        render={(msg) => <Message negative size="tiny" content={msg} />}
                        name="phone"
                      />
                    </Form.Field>
                  </Form.Group>
                </>
              )}
            </Segment>

            {values._id && (
              <>
                <Menu attached="top" pointing secondary>
                  <Menu.Item name="Rate" active={true} />
                </Menu>
                <Segment attached="bottom">
                  <Form.Group widths="equal">
                    <Form.Field>
                      <SemanticField
                        name="ratePerWord"
                        label="Rate Per Word"
                        component={Form.Input}
                      />
                      <ErrorMessage
                        render={(msg) => <Message negative size="tiny" content={msg} />}
                        name="ratePerWord"
                      />
                    </Form.Field>
                    <Form.Field>
                      <SemanticField
                        name="ratePerHour"
                        label="Rate Per Word"
                        component={Form.Input}
                      />
                      <ErrorMessage
                        render={(msg) => <Message negative size="tiny" content={msg} />}
                        name="ratePerHour"
                      />
                    </Form.Field>
                  </Form.Group>
                </Segment>
              </>
            )}

            <Menu attached="top" pointing secondary>
              <Menu.Item name="Skills" active={true} />
            </Menu>
            <Segment attached="bottom">
              <Form.Group widths="equal">
                <Form.Field>
                  <SemanticField
                    name="nativeLanguage"
                    label="Native Language"
                    component={Form.Dropdown}
                    fluid
                    selection
                    search
                    options={optionLanguages?.map((l) => ({ text: l.text, value: l.value }))}
                  />
                  <ErrorMessage
                    render={(msg) => <Message negative size="tiny" content={msg} />}
                    name="nativeLanguage"
                  />
                </Form.Field>
              </Form.Group>
              <FieldArray
                name="languages"
                render={(arrayHelpers) => (
                  <div className="ui form">
                    {values?.languages?.map((language: string, idx: number) => (
                      <Form.Group widths="equal" key={idx}>
                        <Form.Field>
                          <SemanticField
                            name={`languages.${idx}.source`}
                            placeholder="Source Language..."
                            fluid
                            selection
                            search
                            component={Form.Dropdown}
                            options={optionLanguages?.map((l) => ({
                              text: l.text,
                              value: l.value,
                            }))}
                          />
                          <ErrorMessage
                            render={(msg) => <Message negative size="tiny" content={msg} />}
                            name={`languages.${idx}.source`}
                          />
                        </Form.Field>
                        <Form.Field>
                          <SemanticField
                            name={`languages.${idx}.destinations`}
                            placeholder="Destination Language..."
                            fluid
                            selection
                            search
                            multiple
                            component={Form.Dropdown}
                            options={optionLanguages?.map((l) => ({
                              text: l.text,
                              value: l.value,
                            }))}
                          />
                          <ErrorMessage
                            render={(msg) => <Message negative size="tiny" content={msg} />}
                            name={`languages.${idx}.destinations`}
                          />
                        </Form.Field>

                        <div>
                          <div className="field">
                            <Button
                              type="button"
                              icon="remove"
                              onClick={() => arrayHelpers.remove(idx)}
                            />
                          </div>
                        </div>
                      </Form.Group>
                    ))}
                    <Button
                      type="button"
                      size="tiny"
                      onClick={() => arrayHelpers.push({ source: '', destination: '' })}
                      content="Add Language Skills"
                    />
                  </div>
                )}
              />
            </Segment>

            <Button primary type="submit" disabled={isSubmitting}>
              Save
            </Button>
          </FormikForm>
        );
      }}
    </Formik>
  );
};
