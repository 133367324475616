import React, { useState, useRef } from 'react';
// import {
//   Sidebar,
//   Segment,
//   Button,
//   Menu,
//   Image,
//   Icon,
//   Header,
//   Modal,
//   Transition,
// } from 'semantic-ui-react'
import {
  Alert,
  Box,
  Text,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react';
import { ErrorBoundary } from 'react-error-boundary';
import { useHistory } from 'react-router-dom';
// import feathers from '../bootstrap/feathers';
import { LeftNav, TopNav } from './nav';
// import { ErrorBoundary } from './ErrorBoundary'

const innerContainerStyle = {
  paddingTop: '15px',
};

const leftMargin = 210;

const appContentStyle = {
  // leftMargin,
  // background: '#f3f3f3',
  marginTop: '1.5em',
  marginRight: '1.5em',
  marginBottom: '0',
  // marginLeft: leftMargin,
  paddingBottom: '1.5em',
  paddingLeft: '2em',
};

const sidebarStyle = {
  background: '#1B1C1D',
};

// export interface DesktopContainerProps {
//   leftMargin: any
//   history: any
//   children?: React.ReactNode
// }

declare const ENV;

export type DesktopContainerProps = {
  leftMargin: any;
  // history: any
  children?: React.ReactNode;
};

export interface DesktopContainerState {}

// const rightPanelStyle = {
//   width: 400,
//   height: '100%',
//   background: 'blue',
//   position: 'fixed',
//   zIndex: 150,
//   top: 50,
//   right: 0,
// }

// export default class DesktopContainer extends React.Component<
//   DesktopContainerProps,
//   DesktopContainerState
// > {
//   constructor(props: DesktopContainerProps) {
//     super(props)

//     this.state = {}
//   }

//   render() {
//     return (
//       <div style={{ minHeight: '100vh' }}>
//         <TopNav leftMargin={leftMargin} history={this.props.history} />
//         <LeftNav />
//         <div id="appContent" className="" style={appContentStyle}>
//           <div style={innerContainerStyle}>
//             <div id="main">
//               <ErrorBoundary>
//                 {this.props.children}
//               </ErrorBoundary>
//             </div>
//           </div>
//         </div>
//         {/* <div id="rightPanel" style={rightPanelStyle}>
//           content of the right panel here
//         </div> */}
//       </div>
//     )
//   }
// }

export default function DesktopContainer(props: DesktopContainerProps) {
  const { children } = props;
  const history = useHistory();

  // const [isLargerThanTablet] = useMediaQuery('(min-width: 768px)')
  const isLargerThanTablet = window.innerWidth > 768;

  const [isOpen, setIsOpen] = useState(false);

  function fallbackRender({ error, resetErrorBoundary }) {
    return <Alert status="error">Oops! It looks like something went wrong. Please try again</Alert>;
  }

  const onClose = () => setIsOpen(false);

  const marginLeft = isLargerThanTablet ? leftMargin : 0;

  const hostName = window.location.hostname?.split('.')?.slice(1)?.join('.');

  let environment;
  let color;
  let bannerContent;

  if (hostName === 'localdev.inkcloud9.com') {
    environment = 'development';
    bannerContent = `You are viewing the IC dev ${ENV} environment`;
    color = 'red.100';
  }

  return (
    <Box style={{ minHeight: '100vh' }} bgColor={'gray.50'}>
      {environment && (
        <Box bg={color} paddingLeft={marginLeft} py={2}>
          <Text textAlign="center">{bannerContent}</Text>
        </Box>
      )}
      <TopNav onOpen={() => setIsOpen(true)} leftMargin={marginLeft} history={history} />
      {isLargerThanTablet && <LeftNav />}
      {!isLargerThanTablet && (
        <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>
              <h2>LOGO</h2>
            </DrawerHeader>

            <DrawerBody>
              <LeftNav width={isLargerThanTablet ? leftMargin : 'var(--chakra-sizes-xs)'} />
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      )}
      <div id="appContent" className="" style={{ ...appContentStyle, marginLeft }}>
        <div style={innerContainerStyle}>
          <div id="main">
            <ErrorBoundary fallbackRender={fallbackRender} resetKeys={[history.location.key]}>
              {children}
            </ErrorBoundary>
          </div>
        </div>
      </div>
    </Box>
  );
}
