import React, { useEffect, useState } from 'react';
import { Checkbox, Divider, Dropdown, Form, Label, Segment } from 'semantic-ui-react';
import { Feathers } from '../../bootstrap/feathers/feathers';

export interface SettingsValue {
  printerA: string;
  printerB: string;
  printerC: string;
  printerD: string;

  invoicePrinter: 'printerA' | 'printerB' | 'printerC' | 'printerD';
  packingSlipPrinter: 'printerA' | 'printerB' | 'printerC' | 'printerD';
  orderItemLabelPrinter: 'printerA' | 'printerB' | 'printerC' | 'printerD';
  orderItemStatus: string;

  isInvoiceEnabled: boolean;
  isPackingSlipEnabled: boolean;
  isOrderItemLabelEnabled: boolean;
  isMiscDocumentsEnabled: boolean;
  isChangeStatusEnabled: boolean;
}

interface ISettingsProps {
  value: SettingsValue;
  printers: any[];
  onChange: (v: any) => void;
}

const printerOptions = [
  { value: 'printerA', text: 'Printer A' },
  { value: 'printerB', text: 'Printer B' },
  { value: 'printerC', text: 'Printer C' },
  { value: 'printerD', text: 'Printer D' },
];

const Settings: React.FunctionComponent<ISettingsProps> = (props) => {
  const { onChange, value, printers } = props;
  const [statusOptions, setStatusOptions] = useState([]);

  useEffect(() => {
    const loadStatusOptions = async () => {
      try {
        const res = await Feathers.service('order-item-statuses').find();
        setStatusOptions(res?.data?.map((s) => ({ value: s._id, text: s.name })));
      } catch (e) {
        console.error(e);
      }
    };

    loadStatusOptions();
  }, []);
  return (
    <Segment>
      <Label content="Document Printer Settings" attached="top" />

      <Form>
        <Form.Field>
          <label>Printer A</label>
          <Dropdown
            selection
            options={printers.map((p) => ({ value: p, text: p }))}
            onChange={(e, { value: v }) => onChange({ ...props.value, printerA: v })}
            value={value.printerA}
          />
        </Form.Field>
        <Form.Field>
          <label>Printer B</label>
          <Dropdown
            selection
            options={printers.map((p) => ({ value: p, text: p }))}
            onChange={(e, { value: v }) => onChange({ ...props.value, printerB: v })}
            value={value.printerB}
          />
        </Form.Field>
        <Form.Field>
          <label>Printer C</label>
          <Dropdown
            selection
            options={printers.map((p) => ({ value: p, text: p }))}
            onChange={(e, { value: v }) => onChange({ ...props.value, printerC: v })}
            value={value.printerC}
          />
        </Form.Field>
        <Form.Field>
          <label>Printer D</label>
          <Dropdown
            selection
            options={printers.map((p) => ({ value: p, text: p }))}
            onChange={(e, { value: v }) => onChange({ ...props.value, printerD: v })}
            value={value.printerD}
          />
        </Form.Field>
        <Divider content="Documents" section />
        <Form.Field>
          <label>Print Invoice</label>
          <Checkbox
            toggle
            checked={props.value.isInvoiceEnabled}
            onChange={(e, { checked }) => onChange({ ...props.value, isInvoiceEnabled: checked })}
          />
          {props.value.isInvoiceEnabled && (
            <Dropdown
              selection
              options={printerOptions}
              onChange={(e, { value: v }) => onChange({ ...props.value, invoicePrinter: v })}
              value={value.invoicePrinter}
            />
          )}
        </Form.Field>
        <Form.Field>
          <label>Print Packing Slip</label>
          <Checkbox
            toggle
            checked={props.value.isPackingSlipEnabled}
            onChange={(e, { checked }) =>
              onChange({ ...props.value, isPackingSlipEnabled: checked })
            }
          />
          {props.value.isPackingSlipEnabled && (
            <Dropdown
              selection
              options={printerOptions}
              onChange={(e, { value: v }) => onChange({ ...props.value, packingSlipPrinter: v })}
              value={value.packingSlipPrinter}
            />
          )}
        </Form.Field>
        <Form.Field>
          <label>Print Order Item Label</label>
          <Checkbox
            toggle
            checked={props.value.isOrderItemLabelEnabled}
            onChange={(e, { checked }) =>
              onChange({ ...props.value, isOrderItemLabelEnabled: checked })
            }
          />
          {props.value.isOrderItemLabelEnabled && (
            <Dropdown
              selection
              options={printerOptions}
              onChange={(e, { value: v }) => onChange({ ...props.value, orderItemLabelPrinter: v })}
              value={value.orderItemLabelPrinter}
            />
          )}
        </Form.Field>

        <Form.Field>
          <label>Print Order Miscellanous Docs</label>
          <Checkbox
            toggle
            checked={props.value.isMiscDocumentsEnabled}
            onChange={(e, { checked }) =>
              onChange({ ...props.value, isMiscDocumentsEnabled: checked })
            }
          />
        </Form.Field>
        <Form.Field>
          <label>Change Status</label>
          <Checkbox
            toggle
            checked={props.value.isChangeStatusEnabled}
            onChange={(e, { checked }) =>
              onChange({ ...props.value, isChangeStatusEnabled: checked })
            }
          />
          {props.value.isChangeStatusEnabled && (
            <Dropdown
              selection
              options={statusOptions}
              onChange={(e, { value: v }) => onChange({ ...props.value, orderItemStatus: v })}
              value={value.orderItemStatus}
            />
          )}
        </Form.Field>
      </Form>
    </Segment>
  );
};

export default Settings;
