import * as React from 'react';
import { Switch, Route } from 'react-router-dom';

import IndexComponent from './IndexComponent';

const routes = (props) => (
  <Switch>
    <Route path="/settings" component={IndexComponent} />
  </Switch>
);

export default routes;
