import * as React from 'react';
import { Link } from 'react-router-dom';
import { Button, Segment } from 'semantic-ui-react';
import { RouteComponentProps } from 'react-router-dom';
import { PageHeaderRow, PreLoader, useFeathers } from '@inkcloud/components';
import { LateJobDetectorForm } from './LateJobDetectorForm';
import { Feathers } from '../../bootstrap/feathers/feathers';

export interface ConnectedProps {
  user?: any;
}

interface DetailProps {}

const serviceName = '/late-job-detectors';
const pathPrefix = `/late-job-detectors`;
const service = Feathers.service(serviceName);
const commonParams = {
  query: {},
};

const { useState } = React;

export const Detail: React.FunctionComponent<
  DetailProps & RouteComponentProps<{ id?: string }> & ConnectedProps
> = (props) => {
  const {
    match: {
      params: { id },
    },
    history,
    location,
    user,
  } = props;

  const [errorMessage, setErrorMessage] = useState({
    submit: '',
  });

  const isEdit = !!id;
  const [state] = useFeathers('get', serviceName, [id, commonParams], true);

  const [filter, setFilter]: any = useState({});

  // const isFiltered = Object.keys(filter)?.reduce(
  //   (prev, field) =>
  //     prev ||
  //     !!(Array.isArray(filter[field]) ? filter[field]?.some((f) => f.value) : filter[field]),
  //   false
  // )

  // let queryFilter
  // if (isFiltered) {
  //   queryFilter = filter?.rows?.reduce((acc, cur) => {
  //     let normalizeValue

  //     if (Array.isArray(cur.value)) {
  //       normalizeValue = cur.value?.map((v) => v.value)
  //     } else {
  //       normalizeValue = cur.selectedOperator === 'between' ? cur.value : cur.value?.value
  //     }

  //     return Object.assign(acc, {
  //       [cur.selectedFact?.replace(/\./g, '')]: normalizeValue,
  //     })
  //   }, {})
  // }

  const handleSubmit = async (values: any) => {
    setErrorMessage({ ...errorMessage, submit: '' });

    const payload = {
      ...values,
      // criteria: isFiltered ? [queryFilter] : values.criteria
      criteria: filter,
    };

    try {
      const res = isEdit
        ? await service.patch(id, payload, commonParams)
        : await service.create(payload, commonParams);

      if (res.result !== undefined && !res.result) {
        setErrorMessage(res.message || res.errorMessage);
      }

      history.push(pathPrefix);
    } catch (e) {
      const errMsg =
        e.code && e.code < 500
          ? e.message
          : 'We are experiencing technical difficulties. Please try again';
      setErrorMessage({ ...errorMessage, submit: errMsg });
    }
  };

  if (isEdit && !state.data) {
    return <PreLoader />;
  }

  const pageTitle = isEdit ? 'Edit' : 'New';

  const initialData = { ...state.data };

  return (
    <>
      <PageHeaderRow header={`Late Job Detector`} subheader={pageTitle} subheaderId={''}>
        <Link to={pathPrefix}>
          <Button content="Back" size={'mini'} />
        </Link>
      </PageHeaderRow>
      <br />
      <LateJobDetectorForm
        initialValues={{
          timeThreshold: {
            sinceCreated: 0,
            sinceStatusChange: 0,
          },
          ...initialData,
        }}
        errorMessage={errorMessage.submit}
        onSubmit={handleSubmit}
        filter={filter}
        onChangeFilter={(v) => setFilter({ ...v })}
      />
    </>
  );
};
