import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Segment, Table, Message } from 'semantic-ui-react';
import { PageHeaderRow, PreLoader, useFeathers } from '@inkcloud/components';
import Feathers from './../../../../../bootstrap/feathers';
import { ContentForm } from './ContentForm';

const serviceName = '/store-front/content/pages';
const service = Feathers.service(serviceName);
const commonParams = { query: {} };
const pathPrefix = `/settings/content-management/pages`;

const { useState } = React;

export const Detail: React.FunctionComponent<RouteComponentProps<{ id?: string }>> = (props) => {
  const {
    match: {
      params: { id },
    },
    history,
  } = props;
  const [errorMessage, setErrorMessage] = useState('');

  const isEdit = !!id;
  const [state, reload] = useFeathers('get', serviceName, [id, commonParams], true);

  const handleSubmit = async (values) => {
    try {
      setErrorMessage('');
      await service.patch(id, values, commonParams);
      history.push(pathPrefix);
    } catch (e) {
      e.code && e.code < 500
        ? setErrorMessage(e.message)
        : setErrorMessage('We are experiencing technical difficulties. Please try again');
    }
  };

  const pageTitle = isEdit ? 'Edit' : 'New';

  if (isEdit && !state.data) {
    return <PreLoader />;
  }

  const initialData = { ...state.data };

  return (
    <div>
      <PageHeaderRow header={`${pageTitle} Page Content`} subheader="Settings" subheaderId={''}>
        <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
      </PageHeaderRow>

      <Segment>
        {(!isEdit || state.data) && (
          <ContentForm
            errorMessage={errorMessage}
            initialValues={initialData}
            onSubmit={handleSubmit}
          />
        )}
        {errorMessage && <Message error content={errorMessage} />}
      </Segment>
    </div>
  );
};
