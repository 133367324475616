import * as React from 'react';
import { Button, Label, Segment, Table, Popup } from 'semantic-ui-react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { FormattedDate, FormattedTime } from 'react-intl';
import { FilterForm } from './FilterForm';

import { useList, PageHeaderRow, PreLoader, PaginatorWrapper } from '@inkcloud/components';

interface ListProps {}

const serviceName = 'users';
const pathPrefix = '/admin/dashboard-users';

const filterQueryMap = (values) => {
  const filterMap: any = {
    firstName: values.firstName ? { $LIKE: values.firstName } : undefined,
    lastName: values.lastName ? { $LIKE: values.lastName } : undefined,
    email: values.email ? { $LIKE: values.email } : undefined,
  };

  return [].reduce(
    (m, field) => ({ ...m, [field]: values[field] ? values[field] : undefined }),
    filterMap
  );
};

export const List: React.FunctionComponent<ListProps & RouteComponentProps<any>> = (props) => {
  const {
    match: { params },
    location,
    history,
  } = props;
  const {
    state,
    reload,
    handleDelete,
    selectedItems,
    clearAll,
    setChecked,
    someSelected,
    toggleAll,
    filter,
    setFilter,
    sort,
    handleSort,
  } = useList({
    feathers: ['find', serviceName, [{ query: { $populate: ['roles2'] } }]],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  const { queryResult, isLoading: isStateLoading } = state as any;

  if (!queryResult) {
    return null;
  }

  return (
    <div>
      <PageHeaderRow header="Users" subheader="Dashboard">
        <Link to={`${pathPrefix}/add`}>
          <Button content="New" primary size={'mini'} />
        </Link>
      </PageHeaderRow>

      <FilterForm value={filter} onChange={(v) => setFilter(v)} onReset={() => setFilter({})} />

      <Segment loading={state.isLoading}>
        <PaginatorWrapper.Top
          filter={filter}
          reload={reload}
          data={queryResult as any}
          pathPrefix={`${pathPrefix}`}
        />
        <Table size={'small'} sortable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                <input type="checkbox" onChange={toggleAll} />
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={sort.createdAt ? sort.createdAt : null}
                onClick={() => handleSort('createdAt')}
              >
                Created
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={sort.firstName ? sort.firstName : null}
                onClick={() => handleSort('firstName')}
              >
                First Name
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={sort.lastName ? sort.lastName : null}
                onClick={() => handleSort('lastName')}
              >
                Last Name
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={sort.email ? sort.email : null}
                onClick={() => handleSort('email')}
              >
                Email
              </Table.HeaderCell>
              <Table.HeaderCell>Roles</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {queryResult &&
              (queryResult as any).data.map((r: any, index: number) => (
                <Table.Row key={index} verticalAlign="top">
                  <Table.Cell>
                    <input
                      type="checkbox"
                      value={r._id}
                      checked={selectedItems[r._id] || false}
                      onChange={setChecked}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <Popup
                      size="mini"
                      content={<FormattedTime value={r.createdAt} />}
                      trigger={
                        <div>
                          <FormattedDate value={r.createdAt} />
                        </div>
                      }
                    />
                  </Table.Cell>
                  <Table.Cell>{r.firstName}</Table.Cell>
                  <Table.Cell>{r.lastName}</Table.Cell>
                  <Table.Cell>{r.email}</Table.Cell>
                  <Table.Cell>
                    {r.roles2 && r.roles2.map((r) => <Label size="mini" content={r.name} />)}
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    <Popup
                      size="mini"
                      content="Edit"
                      trigger={
                        <Link to={`${pathPrefix}/${r._id}`}>
                          <Button size={'mini'} icon={'edit'} />
                        </Link>
                      }
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
        <PaginatorWrapper.Bottom
          reload={reload}
          data={queryResult as any}
          pathPrefix={`${pathPrefix}`}
        >
          {someSelected && <Button primary type="button" content="Delete" onClick={handleDelete} />}
        </PaginatorWrapper.Bottom>
      </Segment>
    </div>
  );
};
