import React, { useEffect, useState, forwardRef, useRef, useImperativeHandle } from 'react';
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Textarea,
  Stack,
  IconButton,
  Box,
} from '@chakra-ui/react';

import { Select, AsyncSelect, MultiValue } from 'chakra-react-select';

import { RiCloseLine, RiAddLine } from 'react-icons/ri';
import { FaEdit } from 'react-icons/fa';

import type { ModelTypes } from '@inkcloud/icapi-types';

import type { AttributeType } from '../types';
import type { ItemsType } from '../../Detail';
import feathers from '../../../../bootstrap/feathers';

import { booleanOptions, optionsFinder } from '../EditableInfo';

import Creatable from './Creatable';
import Components, { regex } from './Components';

import QuantityModal from './QuantityModal';

interface IEditableSpecProps {
  rfqId?: string;
  items: ItemsType;
  attributes: AttributeType[];
  onReload?: () => void;
  onNext?: () => void;
  onChange: (value: any) => void;
  onErrorMessage?: (value: string) => void;
  isSubmitting?: boolean;
  isEdit?: boolean;
  selectedItemIndex?: number;
}

const initialComponennt = {
  attributes: [],
  description: '',
  notes: '',
  flatSize: { width: undefined, height: undefined },
  finishSize: { width: undefined, height: undefined },
  // id: String(Math.floor(Date.now() / 1000)),
};

const handleCategoryOptions: any = async (e: string, callback) => {
  try {
    const res = await feathers.service('/products-legacy/categories').find({
      query: {
        $sort: { name: 1 },
        $select: ['name', 'hrCode'],
        $or: [{ name: { $LIKE: e }, hrCode: { $LIKE: e } }],
      },
    });
    callback(
      res?.data?.map((d: ModelTypes.ProductCategoryLegacy) => ({
        label: `${d?.name ? `${d.name} - ` : ''}${d?.hrCode ?? ''}`,
        value: d?._id,
      }))
    );
  } catch (er) {
    console.error(er);
  }
};

const EditableSpec = forwardRef((props: IEditableSpecProps, ref) => {
  const {
    rfqId,
    items,
    attributes,
    onReload,
    onChange,
    onNext,
    onErrorMessage,
    isEdit = false,
    selectedItemIndex = 0,
  } = props;

  // const submitRef = useRef<HTMLFormElement>(null)

  const [tabIndex, setTabIndex] = useState(0);

  const [defaultOptions, setDefaultOptions] = useState<{ label: string; value: string }[]>([]);

  const [isModalOpen, setIsModalOpen] = useState({
    quantity: false,
  });

  const [selectedItem, setSelectedItem] = useState('');
  const [selectedQty, setSelectedQty] = useState<number[]>([]);

  const [values, setValues] = useState(items);

  useImperativeHandle(ref, () => ({
    handleSave: () => {
      const updatedValues = values?.map((v) => ({
        ...v,
        category: v.category?.value,
        hardProof: v?.hardProof ?? booleanOptions[0]?.value,
        eProof: v?.eProof ?? booleanOptions[0]?.value,
        liveSample: v?.liveSample ?? booleanOptions[0]?.value,
        pressCheck: v?.pressCheck ?? booleanOptions[0]?.value,
        numHardProof: !v?.hardProof ? null : v?.numHardProof,
      }));

      const noQuantities = values.some((v) => !v?.quantities?.length);

      const t = values?.some((i) =>
        i?.components?.some(
          (c) =>
            (c?.flatSize?.width && !regex.test(String(c?.flatSize?.width)?.trim())) ||
            (c?.flatSize?.height && !regex.test(String(c?.flatSize?.height)?.trim())) ||
            (c?.finishSize?.width && !regex.test(String(c?.finishSize?.width)?.trim())) ||
            (c?.finishSize?.height && !regex.test(String(c?.finishSize?.height)?.trim()))
        )
      );

      if (t) {
        onErrorMessage?.('Please enter valid value');
      } else if (noQuantities) {
        onErrorMessage?.('Please add quantities');
      } else {
        onErrorMessage?.('');

        onChange(updatedValues);
        onNext?.();
      }
    },
  }));

  useEffect(() => {
    const updatedItems = items?.map((item) => ({
      ...item,
      hardProof: item.hardProof ?? booleanOptions[0].value,
    }));
    setValues(updatedItems);

    handleCategoryOptions('', (v) => setDefaultOptions(v));
  }, [items]);

  const handleAddItem = () => {
    setValues([...(values || []), { components: [initialComponennt] }]);
    setTabIndex(values?.length || 0);
  };
  const handleRemoveItem = (idx: number) => {
    let index = 0;
    const valuesLength = values?.length - 1 || 0;
    if (idx === valuesLength) {
      index = idx - 1;
    } else if (idx < valuesLength) {
      index = idx;
    }
    setTabIndex(index);
    setValues(values?.filter((_, indx: number) => indx !== idx));
  };

  const handleAddItemComponent = (idx: number) => {
    const newValues = [...(values || [])];
    newValues[idx].components = [...(newValues[idx].components || []), initialComponennt];
    setValues(newValues);
  };

  const handleChange = (idx: number, keyValue: any) => {
    setValues(values?.map((s, index) => (index === idx ? { ...s, ...keyValue } : s)));
  };

  // const handleSave = () => onChange(values)

  // console.log('values', values)

  // console.log('values', values)

  return (
    <>
      <Tabs index={selectedItemIndex || tabIndex} onChange={(index) => setTabIndex(index)}>
        <Stack
          spacing={7}
          mb={3}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box overflowX="auto" overflowY="unset">
            <TabList>
              {values?.map((_, index) => (
                <Tab key={index}>
                  Item {index + 1}{' '}
                  {values?.length > 1 && (
                    <IconButton
                      ml={2}
                      variant="ghost"
                      onClick={() => handleRemoveItem(index)}
                      aria-label="Remove Item"
                      icon={<RiCloseLine />}
                    />
                  )}
                </Tab>
              ))}
            </TabList>
          </Box>
          <Box>
            <Button size="sm" leftIcon={<RiAddLine />} onClick={handleAddItem}>
              Item
            </Button>
          </Box>
        </Stack>
        <TabPanels>
          {values?.map((item, index) => (
            <TabPanel key={index}>
              <Stack spacing={7} mb={3} direction="row">
                <FormControl>
                  <FormLabel>Product Category</FormLabel>
                  <AsyncSelect
                    cacheOptions
                    isClearable
                    useBasicStyles
                    value={
                      (item?.category?._id || item?.category?.value) && {
                        label:
                          item?.category?.label ??
                          `${item?.category?.name ? `${item?.category.name} - ` : ''}${
                            item?.category?.hrCode ?? ''
                          }`,
                        value: item?.category?._id ?? item?.category?.value,
                      }
                    }
                    onChange={(e) => {
                      handleChange(index, { ...item, category: e });
                    }}
                    placeholder=""
                    loadOptions={handleCategoryOptions}
                    defaultOptions={defaultOptions}
                    noOptionsMessage={(v) =>
                      !v.inputValue ? 'Start typing to search...' : 'No options'
                    }
                  />
                </FormControl>
              </Stack>
              <Stack spacing={7} mb={3} direction="row">
                <FormControl>
                  <FormLabel>SKU</FormLabel>
                  <Input
                    name="sku"
                    value={item?.sku}
                    onChange={(e) => handleChange(index, { ...item, sku: e.target.value })}
                  />
                </FormControl>
                <FormControl isInvalid={item?.quantities?.length === 0}>
                  <FormLabel>
                    Quantities{' '}
                    {isEdit && (item?.quantities ?? [])?.length > 0 && (
                      <IconButton
                        ml={1}
                        aria-label="edit quantities"
                        icon={<FaEdit />}
                        onClick={() => {
                          setIsModalOpen({
                            ...isModalOpen,
                            quantity: true,
                          });
                          setSelectedQty(item?.quantities ?? []);
                          setSelectedItem(item?._id ?? '');
                        }}
                      />
                    )}
                  </FormLabel>

                  <Creatable
                    values={item?.quantities}
                    onChange={(quantities) => {
                      // console.log('quantities', quantities)
                      handleChange(index, { ...item, quantities });
                    }}
                  />
                  {item?.quantities?.length === 0 && (
                    <FormErrorMessage>Please add at least one quantity</FormErrorMessage>
                  )}
                </FormControl>
              </Stack>

              <Stack spacing={7} mb={3} direction="row">
                <FormControl>
                  <Stack spacing={7} mb={3} direction="row">
                    <FormControl>
                      <FormLabel> Hard Proof</FormLabel>
                      <Select
                        useBasicStyles
                        onChange={(v) =>
                          handleChange(index, { ...item, hardProof: Boolean(v?.value) })
                        }
                        options={booleanOptions}
                        defaultValue={
                          optionsFinder(booleanOptions, item.hardProof) ?? booleanOptions[0]
                        }
                      />
                    </FormControl>
                    {item.hardProof && (
                      <FormControl>
                        <FormLabel># of Hard Proof</FormLabel>
                        <Input
                          name="numHardProof"
                          value={item?.numHardProof ?? 1}
                          onChange={(e) =>
                            handleChange(index, { ...item, numHardProof: Number(e.target.value) })
                          }
                        />
                      </FormControl>
                    )}
                  </Stack>
                </FormControl>

                <FormControl>
                  <FormLabel>E Proof</FormLabel>
                  <Select
                    useBasicStyles
                    onChange={(v) => handleChange(index, { ...item, eProof: Boolean(v?.value) })}
                    options={booleanOptions}
                    defaultValue={optionsFinder(booleanOptions, item.eProof) ?? booleanOptions[0]}
                  />
                </FormControl>
              </Stack>
              <Stack spacing={7} mb={3} direction="row">
                <FormControl>
                  <FormLabel>Live Sample</FormLabel>
                  <Select
                    useBasicStyles
                    onChange={(v) =>
                      handleChange(index, { ...item, liveSample: Boolean(v?.value) })
                    }
                    options={booleanOptions}
                    defaultValue={
                      optionsFinder(booleanOptions, item.liveSample) ?? booleanOptions[0]
                    }
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Press Check</FormLabel>
                  <Select
                    useBasicStyles
                    onChange={(v) =>
                      handleChange(index, { ...item, pressCheck: Boolean(v?.value) })
                    }
                    options={booleanOptions}
                    defaultValue={
                      optionsFinder(booleanOptions, item.pressCheck) ?? booleanOptions[0]
                    }
                  />
                </FormControl>
              </Stack>
              <Stack spacing={7} mb={3} direction="row">
                <FormControl>
                  <FormLabel>Description</FormLabel>
                  <Textarea
                    name="description"
                    value={item?.description}
                    onChange={(e) =>
                      handleChange(index, { ...item, [e.target.name]: e.target.value })
                    }
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Packaging Instructions</FormLabel>
                  <Textarea
                    name="packagingInstructions"
                    value={item?.packagingInstructions}
                    onChange={(e) =>
                      handleChange(index, { ...item, [e.target.name]: e.target.value })
                    }
                  />
                </FormControl>
              </Stack>

              <Stack spacing={7} mb={3} direction="row">
                <FormControl>
                  <FormLabel>Note</FormLabel>
                  <Textarea
                    name="notes"
                    value={item?.notes}
                    onChange={(e) =>
                      handleChange(index, { ...item, [e.target.name]: e.target.value })
                    }
                  />
                </FormControl>
              </Stack>

              <Stack direction="row" justifyContent="end">
                <Button
                  size="sm"
                  mb={4}
                  leftIcon={<RiAddLine />}
                  onClick={() => handleAddItemComponent(index)}
                >
                  Component
                </Button>
              </Stack>

              <Components
                attributes={attributes}
                values={item?.components ?? []}
                onChange={(components) => {
                  // console.log('components components', components);
                  handleChange(index, { ...item, components });
                }}
              />
            </TabPanel>
          ))}
          {/* <Button
            size="sm"
            my={4}
            type="button"
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
            onClick={handleSave}>Save</Button> */}
        </TabPanels>
      </Tabs>
      {isModalOpen.quantity && (
        <QuantityModal
          rfqId={rfqId ?? ''}
          itemId={selectedItem}
          onReload={onReload}
          quantities={selectedQty}
          onClose={() => {
            setIsModalOpen({
              ...isModalOpen,
              quantity: false,
            });
            setSelectedQty([]);
            setSelectedItem('');
          }}
        />
      )}
    </>
  );
});

export default EditableSpec;
