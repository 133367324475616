import * as React from 'react';
import { Button, Form, Message } from 'semantic-ui-react';
import { Formik, Form as FormikForm, Field, FieldProps, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { SemanticField } from '../../../common/Form';

import { FeathersDropdown } from '@inkcloud/components';

interface ProductPermissionsFormProps {
  initialValues: any;
  onSubmit: Function;
  isSubmitting?: boolean;
  micrositeId?: string;
  errorMessage?: string;
}

export const ProductPermissionsForm: React.FC<ProductPermissionsFormProps> = (outerProps) => {
  return (
    <Formik
      initialValues={outerProps.initialValues}
      enableReinitialize={true}
      onSubmit={(values, { setSubmitting }) => {
        outerProps.onSubmit(values);
      }}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
          setFieldValue,
          setFieldTouched,
        } = props;

        return (
          <FormikForm
            className={`ui form ${
              outerProps.isSubmitting && !outerProps.errorMessage ? 'loading' : ''
            } ${outerProps.errorMessage ? 'error' : ''}`}
          >
            <Form.Group widths="equal">
              <Form.Field>
                <Field
                  name="groupPermissions"
                  render={({ field }: FieldProps<any>) => (
                    <FeathersDropdown
                      search
                      selection
                      clearable
                      multiple
                      placeholder="Groups..."
                      serviceName="/microsites/customer/groups"
                      query={{
                        microsite: outerProps.micrositeId,
                        $np: 1,
                      }}
                      resultMapper={(r) => ({ value: r._id, text: r.name })}
                      {...field}
                      value={values?.groupPermissions || []}
                      onChange={(e, data) =>
                        field.onChange({
                          target: { value: data.value, name: 'groupPermissions' },
                        } as any)
                      }
                    />
                  )}
                />
              </Form.Field>
            </Form.Group>
            {outerProps.errorMessage && <Message error content={outerProps.errorMessage} />}
            <Button primary type="submit" disabled={outerProps.isSubmitting}>
              Save
            </Button>
          </FormikForm>
        );
      }}
    </Formik>
  );
};
