import * as React from 'react';
import { Modal, Divider, Grid, Table, Button, Form, Input, Message } from 'semantic-ui-react';

import Feathers from './../bootstrap/feathers';

interface TurnAroundModalProps {
  open: boolean;
  onClose: any;
  orderItem: any;
  onReload?: () => void;
}

const { useState } = React;

export const TurnAroundModal: React.FunctionComponent<TurnAroundModalProps> = (props) => {
  const { open, onClose, orderItem, onReload } = props;

  const [timeMax, setTimeMax] = useState(orderItem.timeMax);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (data) => {
    if (!isNaN(data.value)) {
      setTimeMax(data.value);
      setError('');
    } else {
      setError('Must be a number.');
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    await Feathers.service('order-items/turnaround').create(
      {
        timeMax,
        orderItemId: orderItem._id,
      },
      { query: {} }
    );
    onReload();
    setIsLoading(false);
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose} closeIcon>
      <Modal.Header>Update Turnaround Time</Modal.Header>
      <Modal.Content>
        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Table celled size="small">
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>
                      <strong>ID</strong>
                    </Table.Cell>
                    <Table.Cell>{orderItem.humanId}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      <strong>Customer</strong>
                    </Table.Cell>
                    <Table.Cell>{orderItem.customer.organizationName}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      <strong>Turnaround</strong>
                    </Table.Cell>
                    <Table.Cell>{orderItem.timeMax}</Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Grid.Column>
            <Grid.Column>
              <Table celled size="small">
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>
                      <strong>Category</strong>
                    </Table.Cell>
                    <Table.Cell>{orderItem.category?.name ?? 'Custom'}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      <strong>Description</strong>
                    </Table.Cell>
                    <Table.Cell>{orderItem.description}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      <strong>Status</strong>
                    </Table.Cell>
                    <Table.Cell>{orderItem.productionStatus.description}</Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider hidden />
        <Form onSubmit={handleSubmit} loading={isLoading}>
          <Form.Field>
            <label htmlFor="timeMax">Turnaround Time</label>
            <Input
              fluid
              name="timeMax"
              value={timeMax}
              onChange={(e, data) => handleChange(data)}
            />
            {error && <Message negative size="tiny" content={error} />}
          </Form.Field>
          <Button type="submit" primary content="Save" />
        </Form>
      </Modal.Content>
    </Modal>
  );
};
