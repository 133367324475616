import * as React from 'react';
import { Style } from '@react-pdf/types';
import { BillTo as BillToGeneral } from '..';
import { PurchaseOrderContext } from './PurchaseOrderContext';

export interface IBillToProps {
  style?: Style;
  textStyle?: Style;
}

export function BillTo(props: IBillToProps) {
  const ctx = React.useContext(PurchaseOrderContext);
  const { style, textStyle } = props;

  return <BillToGeneral value={ctx.dataCtx.billTo} style={style} textStyle={textStyle} />;
}
