import React, { useEffect, useState } from 'react';
import { useForm, useFieldArray, SubmitHandler } from 'react-hook-form';
import type { ModelTypes } from '@inkcloud/icapi-types';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { GenericFilter } from '@inkcloud/components';

import {
  Box,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Stack,
  Button,
  Alert,
} from '@chakra-ui/react';

import { AsyncSelect } from 'chakra-react-select';
import feathers from '../../../bootstrap/feathers';

interface IUserFormProps {
  initialValues: ModelTypes.User;
  onSubmit: (v: ModelTypes.User) => void;
  errorMessage: string;
  isSubmitting?: boolean;
  isEdit?: boolean;
}

const schema = Yup.object().shape({
  firstName: Yup.string().required('First name is required').trim(),
  lastName: Yup.string().required('Last name is required').trim(),
  email: Yup.string().required('Email is required').trim(),
});

const mapOptionsToValue = (options: any) =>
  options?.map((o) => ({
    label: o?.name || o?.companyName,
    value: o?._id,
  }));

const handleProductOptions: any = async (e: string, callback) => {
  try {
    let res;
    if (e) {
      res = await feathers.service('/user-roles').find({
        query: {
          $sort: { name: 1 },
          $or: [{ name: { $LIKE: e } }, { key: { $LIKE: e } }],
        },
      });
      callback(mapOptionsToValue(res?.data));
    }
  } catch (er) {
    console.error(er);
  }
};

const handleVendorOptions: any = async (e: string, callback) => {
  try {
    let res;
    if (e) {
      res = await feathers.service('/production/vendors').find({
        query: {
          $sort: { companyName: 1 },
          $or: [{ companyName: { $LIKE: e } }],
        },
      });
      callback(mapOptionsToValue(res?.data));
    }
  } catch (er) {
    console.error(er);
  }
};

export default function UserForm(props: IUserFormProps) {
  const { initialValues, errorMessage, isEdit, isSubmitting, onSubmit } = props;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    control,
    watch,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IUserFormProps['initialValues']>({
    defaultValues: initialValues,
    resolver: yupResolver(schema),
  });

  const handSave: SubmitHandler<ModelTypes.User> = (data) => {
    const updateData = {
      ...data,
      roles2: data?.roles2?.map(({ value }: any) => value) ?? [],
    };
    onSubmit(updateData);
  };

  const watchRoles = watch('roles2');
  const watchVendor = watch('vendor') as any;

  return (
    <Box p={3} mb={3} borderRadius="md" borderWidth="1px">
      <Stack spacing={7} mb={3} direction="row">
        <FormControl isInvalid={!!errors?.firstName}>
          <FormLabel>First Name</FormLabel>
          <Input {...register('firstName')} />
          {errors?.firstName && <FormErrorMessage>{errors.firstName.message}</FormErrorMessage>}
        </FormControl>
        <FormControl isInvalid={!!errors?.lastName}>
          <FormLabel>Last Name</FormLabel>
          <Input {...register('lastName')} />
          {errors?.lastName && <FormErrorMessage>{errors.lastName.message}</FormErrorMessage>}
        </FormControl>
      </Stack>

      <Stack spacing={7} mb={3} direction="row">
        <FormControl isInvalid={!!errors?.email}>
          <FormLabel>Email</FormLabel>
          <Input {...register('email')} />
          {errors?.email && <FormErrorMessage>{errors.email.message}</FormErrorMessage>}
        </FormControl>
        {!isEdit && (
          <FormControl isInvalid={!!errors?.password}>
            <FormLabel>Password</FormLabel>
            <Input type="password" {...register('password')} />
            {errors?.password && <FormErrorMessage>{errors.password.message}</FormErrorMessage>}
          </FormControl>
        )}
      </Stack>

      <Stack spacing={7} mb={3} direction="row">
        <FormControl isInvalid={!!errors?.firstName}>
          <FormLabel>Phone</FormLabel>
          <Input {...register('phone')} />
          {errors?.phone && <FormErrorMessage>{errors.phone.message}</FormErrorMessage>}
        </FormControl>
        <FormControl isInvalid={!!errors?.lastName}>
          <FormLabel>Phone Extension</FormLabel>
          <Input {...register('phoneExt')} />
          {errors?.phoneExt && <FormErrorMessage>{errors.phoneExt.message}</FormErrorMessage>}
        </FormControl>
      </Stack>

      <Stack spacing={7} mb={3}>
        <FormControl>
          <FormLabel>Roles</FormLabel>
          <AsyncSelect
            cacheOptions
            isClearable
            isMulti
            useBasicStyles
            value={watchRoles?.map((r: any) => ({
              value: r?._id || r?.value,
              label: r?.name || r?.label,
            }))}
            {...register('roles2')}
            onChange={(e) => setValue('roles2', e as any)}
            placeholder=""
            defaultOptions={!!watchRoles?.length}
            loadOptions={handleProductOptions}
            noOptionsMessage={(v) =>
              !v.inputValue ? 'Start typing role to search...' : 'No options'
            }
          />{' '}
          <FormHelperText>
            A user without any assigned roles will have unrestricted access.
          </FormHelperText>
        </FormControl>
      </Stack>
      {isEdit && (
        <Stack spacing={7} mb={3}>
          <FormControl>
            <FormLabel>Supplier</FormLabel>
            <AsyncSelect
              cacheOptions
              isClearable
              useBasicStyles
              value={
                watchVendor
                  ? {
                      value: watchVendor?._id || watchVendor?.value,
                      label: watchVendor?.companyName || watchVendor?.label,
                    }
                  : null
              }
              {...register('vendor')}
              onChange={(e) => setValue('vendor', e as any)}
              placeholder=""
              loadOptions={handleVendorOptions}
              noOptionsMessage={(v) =>
                !v.inputValue ? 'Start typing supplier to search...' : 'No options'
              }
            />
            <FormHelperText>
              Setting a supplier restricts the user to the vendor dashboard and to only data
              assigned to the vendor
            </FormHelperText>
          </FormControl>
        </Stack>
      )}

      {errorMessage && (
        <Alert status="error" mb={3}>
          {errorMessage}
        </Alert>
      )}

      <Button
        colorScheme="blue"
        isDisabled={isSubmitting}
        isLoading={isSubmitting}
        onClick={handleSubmit(handSave)}
      >
        Save
      </Button>
    </Box>
  );
}
