import * as React from 'react';
import { Button, Form, Message } from 'semantic-ui-react';
import { Formik, Form as FormikForm, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { SemanticField } from '../../../../common/Form';
import { EntityDropdownWrapper2 } from '../../../EntityDropdown';

interface VirtualFormProps {
  initialValues: any;
  handleSubmit: Function;
  isSubmitting?: boolean;
  handleCloseClick: any;
}

export const VirtualForm: React.FC<VirtualFormProps> = (props) => {
  const { handleCloseClick } = props;
  const inventoryBinSchema = Yup.object().shape({
    name: Yup.string().required(),
  });

  return (
    <Formik
      initialValues={{ virtual: true, ...props.initialValues }}
      validationSchema={inventoryBinSchema}
      onSubmit={(values, { setSubmitting }) => {
        props.handleSubmit({ ...values });
      }}
    >
      {(props) => {
        const { values, errors, isSubmitting } = props;

        return (
          <FormikForm className={`ui form ${isSubmitting && 'loading'}`}>
            <Form.Field>
              <SemanticField name={'name'} label="Name" component={Form.Input} />
              <ErrorMessage
                render={(msg) => <Message negative size="tiny" content={msg} />}
                name="name"
              />
            </Form.Field>

            <Button primary type="submit" disabled={isSubmitting}>
              Save
            </Button>
            <Button type="button" disabled={isSubmitting} onClick={handleCloseClick}>
              Cancel
            </Button>
          </FormikForm>
        );
      }}
    </Formik>
  );
};
