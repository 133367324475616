import * as React from 'react';
// import { Modal, Table, Checkbox, Input, Dropdown, TextArea, Message, Button } from 'semantic-ui-react'

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Button,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Table,
  Thead,
  Tr,
  Td,
  Th,
  Tbody,
  Badge,
  Text,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  FormControl,
  FormLabel,
  Textarea,
  Alert,
} from '@chakra-ui/react';

import { Select } from 'chakra-react-select';
import type { ModelTypes } from '@inkcloud/icapi-types';

import { EmptyWrapper } from '@inkcloud/components';

import Feathers from '../../bootstrap/feathers';

interface IReceivedModalProps {
  onClose: () => void;
  onReload: () => void;
  receiving;
}

const { useEffect, useState } = React;

export const ReceivedModal = (props: IReceivedModalProps) => {
  const {
    onClose,
    receiving: { purchaseOrder, items: itemsProps, notes: notesProps },
    onReload,
  } = props;

  const [items, setItems] = useState(itemsProps);
  const [locations, setLocations] = useState<ModelTypes.InventoryLocations[]>([]);
  const [isLoading, setIsLoading] = useState({
    locations: false,
    submit: false,
  });

  const [notes, setNotes] = useState(notesProps);

  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const loadLocations = async () => {
      setIsLoading({
        ...isLoading,
        locations: true,
      });
      try {
        const res: any = await Feathers.service('inventory/locations').find({
          query: {
            $np: 1,
          },
        });

        setLocations(res?.map((r) => ({ name: 'location', label: r.name, value: r._id })));
      } catch (e) {
        console.log('e');
      }

      setIsLoading({
        ...isLoading,
        locations: false,
      });
    };

    if (items?.length) {
      loadLocations();

      const preSelectItems = items.map((i) => ({ ...i, checked: true }));
      setItems(preSelectItems);
    }
  }, []);

  // const handleSelect = (data, item) => {
  //   const { checked } = data

  //   const updatedItems = items.map((i) =>
  //     i.purchaseOrderItem === item.purchaseOrderItem ? { ...i, checked } : i
  //   )

  //   setItems(updatedItems)
  // }

  const handleChange = (data, index: number) => {
    const { name, value } = data;
    const updatedItems = items?.map((i, idx: number) =>
      idx === index
        ? {
            ...i,
            [name]: value,
          }
        : i
    );

    setItems(updatedItems);
  };

  // const handleToggle = (e, data) => {
  //   const { checked } = data
  //   const updatedItems = items.map((i) => ({ ...i, checked }))

  //   setItems(updatedItems)
  // }

  const handleSubmit = async () => {
    setIsLoading({
      ...isLoading,
      submit: true,
    });
    setErrorMessage('');

    const updatedItems = items?.reduce((acc, cur) => {
      let location;
      const isStock = cur?.inventory;

      if (isStock) {
        if (typeof cur.location === 'string') {
          location = cur?.location;
        } else if (typeof cur.location === 'object') {
          location = cur?.location?._id;
        }
      }

      if (Number(cur.amount) !== 0) {
        acc.push({
          amount: Number(cur.amount),
          product: cur.product?._id,
          location,
          purchaseOrderItem: cur.purchaseOrderItem,
        });
      }

      return acc;
    }, []);

    try {
      const res = await Feathers.service('purchase-orders/receivings').create({
        purchaseOrder,
        items: updatedItems,
        notes,
      });

      onReload();
      onClose();
    } catch (e) {
      console.log('e', e);

      const errMsg =
        e?.code < 500 ? e.message : 'We are experiencing technical difficulties. Please try again';

      setErrorMessage(errMsg);
    }
    setIsLoading({
      ...isLoading,
      submit: false,
    });
  };

  return (
    <Modal size="5xl" isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Receive Purchase Order</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <EmptyWrapper isStateLoading={false} queryResult={items} content="All Items Received">
            <Text>
              Enter in the quantities you are receiving for each SKU. If you are receiving a SKU
              that is of type stock, select an inventory location to receive the items into.
              On-demand items are not tracked and an inventory location is not needed.
            </Text>
            <br />
            <br />

            <Table size={'sm'}>
              <Thead>
                <Tr>
                  <Th>SKU</Th>
                  <Th>Type</Th>
                  <Th>Ordered</Th>
                  <Th>Received</Th>
                  <Th>Receiving Now</Th>
                  <Th>Inventory Location</Th>
                </Tr>
              </Thead>
              <Tbody>
                {items?.map((item, index) => {
                  const isStock = item?.inventory;

                  return (
                    <Tr key={index}>
                      <Td>{item?.key ?? ''}</Td>
                      <Td>
                        {isStock ? (
                          <Badge colorScheme={'green'}>Stock</Badge>
                        ) : (
                          <Badge colorScheme={'gray'}>On-Demand</Badge>
                        )}
                      </Td>
                      <Td>{item?.quantity ?? 0}</Td>
                      <Td>{item?.shippedOrReceived}</Td>
                      <Td>
                        <NumberInput
                          defaultValue={item?.remainingQuantity}
                          min={0}
                          max={item?.remainingQuantity}
                          onChange={(v) => handleChange({ name: 'amount', value: v }, index)}
                        >
                          <NumberInputField />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>
                      </Td>
                      <Td>
                        {isStock ? (
                          <Select
                            placeholder="Select inventory location..."
                            options={locations}
                            onChange={(e) => handleChange(e, index)}
                          />
                        ) : (
                          <Text ml={2}>Not Applicable</Text>
                        )}
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>

            <FormControl mt={8}>
              <FormLabel>Notes</FormLabel>
              <Textarea
                value={notes}
                placeholder="Notes..."
                onChange={(e) => setNotes(e.target.value)}
              />
            </FormControl>
          </EmptyWrapper>

          {errorMessage && (
            <Alert mt={3} status="error">
              {errorMessage}
            </Alert>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            isDisabled={isLoading.submit}
            isLoading={isLoading.submit}
            colorScheme="blue"
            mr={3}
            onClick={handleSubmit}
          >
            Receive
          </Button>
          <Button isDisabled={isLoading.submit} variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
      {/* <ModalContent>
          <ModalHeader>Modal Title</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
          <EmptyWrapper
          isStateLoading={false}
          queryResult={items}
          content="All Items Received"
        >

          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  <Checkbox
                    onChange={handleToggle}
                    checked={countChecked === items?.length}
                  />
                </Table.HeaderCell>
                <Table.HeaderCell>SKU</Table.HeaderCell>
                <Table.HeaderCell>Ordered</Table.HeaderCell>
                <Table.HeaderCell>Received</Table.HeaderCell>
                <Table.HeaderCell>Inventory Location</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {
                items?.map((item, index: number) => <Table.Row key={index}>
                  <Table.Cell>
                    <Checkbox
                      onChange={(e, data) => handleSelect(data, item)}
                      checked={item?.checked}
                    />
                  </Table.Cell>
                  <Table.Cell>{item?.key}</Table.Cell>
                  <Table.Cell>{item?.quantity}</Table.Cell>
                  <Table.Cell>
                    <Input
                      name="amount"
                      value={item?.amount}
                      onChange={(e, data) => data?.value <= item?.remainingQuantity ? handleChange(data, index) : null}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    {
                      item?.inventory && <Dropdown
                        fluid selection search
                        name="location"
                        loading={isLoading.locations}
                        onChange={(e, data) => handleChange(data, index)}
                        value={typeof item?.location === "string" ? item?.location : item?.location?._id}
                        options={locations?.map(l => ({ text: l?.name, value: l._id }))}
                      />
                    }

                  </Table.Cell>
                </Table.Row>)
              }

            </Table.Body>
          </Table>

          <div className="ui form">
            <label>Notes</label>
            <TextArea
              fluid
              content={notes}
              onChange={(e, data) => setNotes(data.value)}
            />
          </div>
          <br />
          {
            errorMessage && <Message error content={errorMessage} />
          }
          <div>
            <Button
              content="Save"
              disabled={isLoading.submit || !countChecked}
              loading={isLoading.submit}
              onClick={handleSubmit}
            />
          </div>
        </EmptyWrapper>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>
              Close
            </Button>
            <Button variant='ghost'>Secondary Action</Button>
          </ModalFooter>
        </ModalContent> */}
    </Modal>
  );
};
