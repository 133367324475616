import * as React from 'react';
import { Button, Modal, ModalProps, Form, Divider } from 'semantic-ui-react';
import { FeathersDropdown } from '@inkcloud/components';

interface StatusModalProps {
  handleSubmit: Function;
  handleClose: ModalProps['onClose'];
  open: boolean;
  item: any;
}

const { useState } = React;

export const StatusModal: React.FunctionComponent<StatusModalProps> = (props) => {
  const { item, handleSubmit } = props;
  const [value, setValue] = useState<any>({ status: item.status, updateOrderItemStatus: false });

  return (
    <Modal size={'mini'} open={props.open} onClose={props.handleClose}>
      <Modal.Header>Outsource - Change Status</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Dropdown
            search
            selection
            fluid
            placeholder="Status..."
            options={[
              { value: 'draft', text: 'Draft' },
              { value: 'dispatched', text: 'Dispatched' },
              { value: 'in-progress', text: 'In Progress' },
              { value: 'received', text: 'Received' },
            ]}
            value={value.status}
            onChange={(e, data) => setValue({ ...value, status: data.value })}
          />

          <Form.Checkbox
            label="Update Status of all Order Items"
            toggle
            checked={value.updateOrderItemStatus}
            onChange={(e, data) => setValue({ ...value, updateOrderItemStatus: data.checked })}
          />

          {value.updateOrderItemStatus && (
            <FeathersDropdown
              search
              selection
              fluid
              placeholder="Order Item Status..."
              serviceName="order-item-statuses"
              query={{ $np: 1, $sort: { name: 1 } }}
              resultMapper={(r) => ({ value: r._id, text: r.name })}
              value={value.vendor}
              onChange={(e, data) => setValue({ ...value, orderItemStatus: data.value })}
            />
          )}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button type="submit" primary content="Update" onClick={() => handleSubmit(value)} />
      </Modal.Actions>
    </Modal>
  );
};
