import * as React from 'react';
import { Formik, Form as FormikForm, Field, FieldArray, FieldProps, ErrorMessage } from 'formik';
import { Segment, Form, Radio, Popup, Menu, Button, Message } from 'semantic-ui-react';

interface NotificationFormProps {
  values: any;
  action: string;
  title: string;
  setFieldValue: (name: string, value: any) => void;
}

export const NotificationForm: React.FunctionComponent<NotificationFormProps> = (props) => {
  const { values, action, withEmail, title, setFieldValue } = props as any;

  return (
    <>
      <Menu attached="top" pointing secondary>
        <Menu.Item name={title} active={true} />
      </Menu>
      <Segment attached="bottom">
        <FieldArray
          name={`${action}`}
          render={(arrayHelpers) => (
            <div>
              <br />
              {values &&
                values.length > 0 &&
                values.map((f, index) => (
                  <div key={index}>
                    {
                      <Form.Group>
                        <Field
                          name={`${action}.${index}.email`}
                          render={({ field }: FieldProps<any>) => (
                            <div className="field">
                              <Form.Input
                                placeholder="Enter an email"
                                fluid
                                style={{ minWidth: '320px' }}
                                {...field}
                                onChange={(e, data) =>
                                  setFieldValue(`${action}.${index}.email`, data.value)
                                }
                                autoComplete={`off-email${index}`}
                              />
                              <ErrorMessage
                                render={(msg) => <Message negative size="tiny" content={msg} />}
                                name={`${action}.${index}.email`}
                              />
                            </div>
                          )}
                        />
                        <Field
                          name={`${action}.${index}.name`}
                          render={({ field }: FieldProps<any>) => (
                            <div className="field">
                              <Form.Input
                                placeholder="Enter a name"
                                fluid
                                style={{ minWidth: '320px' }}
                                {...field}
                                onChange={(e, data) =>
                                  setFieldValue(`${action}.${index}.name`, data.value)
                                }
                                autoComplete={`off-email${index}`}
                              />
                              <ErrorMessage
                                render={(msg) => <Message negative size="tiny" content={msg} />}
                                name={`${action}.${index}.name`}
                              />
                            </div>
                          )}
                        />

                        <div className="field">
                          <Popup
                            content="Remove recipient"
                            trigger={
                              <Button
                                type="button"
                                onClick={() => arrayHelpers.remove(index)}
                                icon="remove"
                              />
                            }
                          />
                        </div>
                      </Form.Group>
                    }
                  </div>
                ))}
              <Popup
                content={'Add Email'}
                trigger={
                  <Button
                    type="button"
                    size="tiny"
                    onClick={() =>
                      arrayHelpers.push({
                        email: '',
                        name: '',
                      })
                    }
                    content={'Add Email'}
                  />
                }
              />
            </div>
          )}
        />
      </Segment>
    </>
  );
};
