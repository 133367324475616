import * as React from 'react';
import { useSelector } from 'react-redux';
import type { ModelTypes } from '@inkcloud/icapi-types';
import { FormattedDate } from 'react-intl';
import { Link } from 'react-router-dom';
import { Label, Icon } from 'semantic-ui-react';

const infoRowStyle = { paddingTop: 2, paddingBottom: 2 };

const renderInfo = (label, text) => (
  <div className="ui two column grid">
    <div className="row" style={infoRowStyle}>
      <div className="six wide column">
        <label>{label}</label>
      </div>
      <div className="ten wide column">{text}</div>
    </div>
  </div>
);

export interface InfoTableProps {
  // data: ModelTypes.Orders
  data: any;
}

export function InfoTable(props: InfoTableProps) {
  const { data } = props;
  let customer;
  if (data) {
    customer = data.customer;
  }
  const tenant = useSelector(
    ({
      globals,
    }: {
      globals: {
        tenant: {
          internationalization: { countryCode: string };
          labelMaps: { [key: string]: string };
        };
      };
    }) => globals.tenant
  );

  // console.log('tenant', tenant);

  const primaryContact = customer?.primaryContact || { firstName: '', lastName: '' };

  let shipMethod = '';
  if (data.shipment && data.shipment.providerName) {
    shipMethod = `${data.shipment.providerName} ${
      data.shipment.serviceDescription ? data.shipment.serviceDescription : ''
    }`;
  } else if (!data.shipment || (data.shipment && data.shipment.type === 'pickup')) {
    shipMethod = 'Will Call';
  } else if (
    data?.shipment?.type === 'local-delivery' ||
    (data?.shipment?.type === 'shipment' && !data?.shipment?.providerKey)
  ) {
    shipMethod = 'Local Delivery';
  } else if (data?.shipment?.type === 'digital') {
    shipMethod = 'Digital Delivery';
  }

  // const awHide = data.business === '633f78a0cbbf6916101874f2'
  const awHide = data.createdFromRfq;

  // somehow both values are missing
  if (!shipMethod) {
    shipMethod = 'N/A';
  }

  const filteredTrackingNumber = data?.actualShipments?.filter((a) => !a.isVoid);

  return (
    <div className="ui four column stackable padded grid ">
      <div className="eight wide column">
        {renderInfo('ID', data.humanId)}
        {renderInfo('Created', <FormattedDate value={props.data.createdAt} />)}
        {props.data.createdFromRfq &&
          renderInfo(
            'RFQ',
            <Link to={`/rfqs/${props.data.createdFromRfq}`}>{props.data.rfqNum}</Link>
          )}
        {renderInfo(
          'Company',
          <Link to={`/customers/${props.data.customer._id}`}>
            {props.data.customer.organizationName}
          </Link>
        )}
        {renderInfo(
          'Contact',
          primaryContact ? (
            <Link to={`/customers/${props.data.customer._id}`}>
              {primaryContact?.firstName ?? ''} {primaryContact?.lastName ?? ''}
            </Link>
          ) : (
            ''
          )
        )}
        {props.data.micrositeUser &&
          renderInfo(
            'Microsite User',
            `${props.data.micrositeUser.firstName} ${props.data.micrositeUser.lastName}`
          )}
        {renderInfo('Email', (data?.micrositeUser?.email ?? '') || (data?.customer?.email ?? ''))}
        {renderInfo('Phone', (data?.micrositeUser?.phone ?? '') || (data?.customer?.phone ?? ''))}
        {props.data.invoice &&
          props.data.invoice.costCenter &&
          renderInfo('Cost Center', props.data.invoice.costCenter)}

        {props.data.foreignId &&
          renderInfo(tenant?.labelMaps?.orderForeignId || 'Foreign ID', props.data.foreignId)}

        {props.data.intakeChannel &&
          renderInfo('Intake Channel', props.data.intakeChannel?.name ?? '')}

        {data.approvedByTenantUser &&
          renderInfo(
            'Approved By',
            `${data.approvedByTenantUser.firstName} ${data.approvedByTenantUser.lastName}`
          )}
      </div>
      {!awHide && (
        <div className="eight wide column">
          {data.salesPerson &&
            renderInfo(
              'Sales Person',
              `${data.salesPerson.firstName} ${data.salesPerson.lastName}`
            )}
          {data.salesPerson &&
            renderInfo(
              'Sales Person Email',
              <a href={`mailto:{data.salesPerson.email}`}>{data.salesPerson.email}</a>
            )}

          {renderInfo('Ship Method', data.shipment ? shipMethod : 'Will Call')}

          {!data.shipment || (data.shipment && data.shipment.type === 'pickup')
            ? data.pickupLocation &&
              renderInfo(
                'Pickup Location',
                <React.Fragment>
                  <div>{data.pickupLocation.name}</div>
                  <div>{data.pickupLocation.address1}</div>
                  <div>{data.pickupLocation.address2}</div>
                  <div>
                    {data.pickupLocation.city} {data.pickupLocation.stateProvince},{' '}
                    {data.pickupLocation.postalCode}
                  </div>
                </React.Fragment>
              )
            : data.shipment &&
              data.shipment.providerKey !== 'SHIPPING_WILL_CALL' &&
              data?.shipment?.type !== 'digital' &&
              renderInfo(
                'Ship To',
                <React.Fragment>
                  <div>{data.shipment.shipToName}</div>
                  <div>{data.shipment.shipToCareOf}</div>
                  <div>{data.shipment.shipToAddress1}</div>
                  <div>{data.shipment.shipToAddress2}</div>
                  <div>
                    {data.shipment.shipToCity} {data.shipment.shipToStateProvince},{' '}
                    {data.shipment.shipToPostalCode} {data.shipment.shipToCountryCode}
                  </div>
                  <div>{data.shipment.shipToPhone && `Phone: ${data.shipment.shipToPhone}`}</div>
                  <div>
                    {(data.shipment?.payment?.type === 'THIRD_PARTY' && (
                      <Label>
                        <Icon name="payment" />
                        3rd Party
                        <Label.Detail>
                          #{data.shipment?.payment?.account ?? ''} -{' '}
                          {data.shipment?.payment?.postalCode ?? ''}
                        </Label.Detail>
                      </Label>
                    )) ||
                      ''}
                  </div>
                </React.Fragment>
              )}

          {data.shipment &&
            data.shipment.deliveryInstructions &&
            renderInfo('Delivery Instruction', <div>{data.shipment.deliveryInstructions}</div>)}
          <div className="ui two column grid">
            {filteredTrackingNumber?.map((a: any, index: number) => (
              <div className="row" key={index} style={infoRowStyle}>
                <div className="six wide column">
                  <label>Shipments</label>
                </div>
                <div className="ten wide column">
                  {a.trackingNumber} - <FormattedDate value={a.createdAt} />
                </div>
              </div>
            ))}
          </div>
          {data.foreignId &&
            renderInfo(
              'Foreign ID',
              <React.Fragment>
                <div>{data.foreignId}</div>
              </React.Fragment>
            )}
          {data.po &&
            renderInfo(
              'Client PO',
              <React.Fragment>
                <div>{data.po}</div>
              </React.Fragment>
            )}
          {data.approvedAt &&
            renderInfo(
              'Approved At',
              <React.Fragment>
                <div>
                  <FormattedDate value={data.approvedAt} />
                </div>
              </React.Fragment>
            )}
          {data.arriveBy &&
            renderInfo(
              'Requested Arrive By',
              <React.Fragment>
                <div>
                  <FormattedDate value={data.arriveBy} />
                </div>
              </React.Fragment>
            )}
          {data.miscInfo &&
            renderInfo(
              'Misc Info',
              <React.Fragment>
                <div>{data.miscInfo}</div>
              </React.Fragment>
            )}
        </div>
      )}
    </div>
  );
}
