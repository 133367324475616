import React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Stack,
  Input,
  Textarea,
} from '@chakra-ui/react';

import DatePicker from 'react-datepicker';
import { Select } from 'chakra-react-select';

import type { PurchaseOrderType } from '../PurchaseOrder';

interface IEditableInfoProps {
  info: PurchaseOrderType;
  onClose: () => void;
  onChange: (v: PurchaseOrderType) => void;

  usersOptions: { label: string; value: string }[];
}

export default function EditableInfo(props: IEditableInfoProps) {
  const { info, onClose, onChange, usersOptions } = props;
  const {
    register,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<Omit<IEditableInfoProps, 'onClose'>>({
    defaultValues: { info },
  });

  const watchPODueDate = watch('info.poDueDate');
  const watchSOShipDate = watch('info.soShipDate');

  const handleChange: SubmitHandler<Omit<IEditableInfoProps, 'onClose'>> = (values, e) => {
    e?.preventDefault();

    onChange({ ...values.info });
    onClose();
  };

  return (
    <form onSubmit={handleSubmit(handleChange)}>
      <Modal isOpen={true} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Info</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={7} mb={3} direction="row">
              <FormControl>
                <FormLabel>Project Name</FormLabel>
                <Input type="text" {...register('info.projectName')} />
              </FormControl>
            </Stack>
            <Stack spacing={7} mb={3} direction="row">
              <FormControl>
                <FormLabel>CSR</FormLabel>
                <Select
                  onChange={(v) => setValue('info.csr', v?.value)}
                  options={usersOptions}
                  defaultValue={usersOptions?.find(
                    (o) => o.value === ((info.csr as any)?._id || info.csr)
                  )}
                />
              </FormControl>
            </Stack>
            <Stack spacing={7} mb={3} direction="row">
              <FormControl>
                <FormLabel>RFQ</FormLabel>
                <Input type="text" {...register('info.rfqNum')} />
              </FormControl>
            </Stack>
            <Stack spacing={7} mb={3} direction="row">
              <FormControl>
                <FormLabel>Attn</FormLabel>
                <Input type="text" {...register('info.attn')} />
              </FormControl>
            </Stack>
            {/* <Stack spacing={7} mb={3} direction="row">
              <FormControl>
                <FormLabel>Client PO</FormLabel>
                <Input type="text" {...register('info.clientPO')} />
              </FormControl>
            </Stack> */}
            {/* <Stack spacing={7} mb={3} direction="row">
              <FormControl>
                <FormLabel>Sales Order</FormLabel>
                <Input type="text" {...register('info.salesOrderNum')} />
              </FormControl>
            </Stack> */}
            <Stack spacing={7} mb={3} direction="row">
              <FormControl>
                <FormLabel>Notes to Vendor</FormLabel>
                <Textarea {...register('info.notesToVendor')} />
              </FormControl>
            </Stack>
            <Stack spacing={7} mb={3} direction="row">
              {/* <FormControl>
                <FormLabel>PO Due Date</FormLabel>
                <DatePicker
                  selected={watchPODueDate ? new Date(watchPODueDate) : null}
                  onChange={(v) => setValue('info.poDueDate', String(v))}
                  dateFormat="MM/dd/yyyy"
                  className="formMinHeight"
                />
              </FormControl> */}
            </Stack>
            <Stack spacing={7} mb={3} direction="row">
              <FormControl>
                <FormLabel>Delivery Date</FormLabel>
                <DatePicker
                  selected={watchSOShipDate ? new Date(watchSOShipDate) : null}
                  onChange={(v) => setValue('info.soShipDate', String(v))}
                  dateFormat="MM/dd/yyyy"
                  className="formMinHeight"
                />
              </FormControl>
            </Stack>
            {/* <Stack spacing={7} mb={3} direction="row">
              <FormControl>
                <FormLabel>Foreign ID</FormLabel>
                <Input type="text" {...register('info.foreignId')} />
              </FormControl>
            </Stack> */}

            <Stack spacing={7} mb={3} direction="row">
              <FormControl>
                <FormLabel>Cost Center</FormLabel>
                <Input type="text" {...register('info.costCenter')} />
              </FormControl>
            </Stack>

            <Stack spacing={7} mb={3} direction="row">
              <FormControl>
                <FormLabel>Job Number</FormLabel>
                <Input type="text" {...register('info.jobNumber')} />
              </FormControl>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button mr={3} onClick={onClose}>
              Close
            </Button>
            <Button colorScheme="blue" type="submit" onClick={handleSubmit(handleChange)}>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </form>
  );
}
