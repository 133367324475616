import * as React from 'react';
// import { Button, Segment, Popup, Label, Dropdown, Icon } from 'semantic-ui-react'

import {
  Button,
  Box,
  ButtonGroup,
  IconButton,
  Tooltip,
  Tag,
  Icon,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  HStack,
} from '@chakra-ui/react';

import {
  FaEdit,
  FaEllipsisV,
  FaCheckSquare,
  FaUpload,
  FaRegEnvelope,
  FaRegCopy,
  FaPrint,
  FaExclamationTriangle,
} from 'react-icons/fa';

import { Link, RouteComponentProps } from 'react-router-dom';
import { FormattedDate, FormattedTime } from 'react-intl';
import { humanize } from 'underscore.string';
import {
  useList,
  ListPage,
  GenericFilter,
  basicQueryMap,
  generateOperatorOptions,
  Operator,
} from '@inkcloud/components';
import { useSelector } from 'react-redux';

import type { ServiceTypes, ModelTypes } from '@inkcloud/icapi-types';
import { ReceivedModal } from './ReceivedModal';
import { SendModal } from './SendModal';
import DuplicateModal from './DuplicateModal';

import { PrintTypes } from '../../common/PrintModal';

import { IndexWrappedProps } from '../../bootstrap/IndexWrapper';

import { connectOrderItemModals } from '../../common/OrderItemCard2';

import Feathers from '../../bootstrap/feathers';

type ICEntity<T extends keyof ServiceTypes> = Awaited<ReturnType<ServiceTypes[T]['get']>>;
// type Vendor = ICEntity<'production/vendors'>  TODO
type Vendor = {
  _id: string;
  companyName: string;
};
type PurchaseOrder = ICEntity<'purchase-orders'> & { vendor: Vendor };

const { useState } = React;

interface ListProps {}

const serviceName = '/purchase-orders';
const pathPrefix = '/purchase-orders';

const filterQueryMap = (values) => basicQueryMap(values, {});

const statusOptions = [
  { label: 'Draft', value: 'draft' },
  { label: 'Pending Approval', value: 'pending-approval' },
  { label: 'Rejected', value: 'rejected' },
  { label: 'Open', value: 'open' },
  { label: 'Partial Received', value: 'partial-received' },
  { label: 'Received', value: 'received' },
];

export async function getFilterDefinition() {
  const definition = [
    {
      label: 'Vendor',
      value: 'vendor',
      operators: generateOperatorOptions([Operator.Is]),
      valueOptions: [],
      asyncValueOptions: async () =>
        Feathers.service('production/vendors')
          .find({
            query: {
              $sort: { companyName: 1 },
              $np: 1,
            },
          })
          .then((results) => results.map((r) => ({ value: r._id, label: r.companyName }))),
      type: 'select',
    },
    {
      label: 'CSR',
      value: 'csr',
      operators: generateOperatorOptions([Operator.Is]),
      valueOptions: [],
      asyncValueOptions: async () =>
        Feathers.service('users')
          .find({
            query: {
              $sort: { firstName: 1 },
              $np: 1,
            },
          })
          .then((results) =>
            results.map((r) => ({
              value: r._id,
              label: `${r?.firstName ?? ''} ${r?.lastName ?? ''}`,
            }))
          ),
      type: 'select',
    },
    {
      label: 'Status',
      value: 'status',
      operators: generateOperatorOptions([Operator.Is]),
      valueOptions: statusOptions,
      type: 'select',
    },
    {
      label: 'Is Vendor Acknowledge',
      value: 'isVendorAcknowledged',
      operators: generateOperatorOptions([Operator.Is]),
      valueOptions: [
        { value: true, label: 'True' },
        { value: 'false', label: 'False' },
      ],
      type: 'select',
    },
    {
      label: 'Is Approved',
      value: 'isApproved',
      operators: generateOperatorOptions([Operator.Is]),
      valueOptions: [
        { value: true, label: 'True' },
        { value: 'false', label: 'False' },
      ],
      type: 'select',
    },
    {
      label: 'Is Sent',
      value: 'isSent',
      operators: generateOperatorOptions([Operator.Is]),
      valueOptions: [
        { value: true, label: 'True' },
        { value: 'false', label: 'False' },
      ],
      type: 'select',
    },
    {
      label: 'Date:Created',
      value: 'createdAt',
      operators: generateOperatorOptions([Operator.Between]),
      type: 'date',
    },
    {
      label: 'ID',
      value: 'humanId',
      operators: generateOperatorOptions([Operator.Is]),
    },
    {
      label: 'Job Number',
      value: 'jobNumber',
      operators: generateOperatorOptions([Operator.Like, Operator.Is]),
    },
    {
      label: 'Project Name',
      value: 'projectName',
      operators: generateOperatorOptions([Operator.Like, Operator.Is]),
    },
  ];

  return definition.sort((a: any, b: any) => a.label.localeCompare(b.label));
}

export const PreList: React.FunctionComponent<ListProps & RouteComponentProps<any>> = (props) => {
  const {
    match: { params },
    location,
    history,
  } = props;
  const authUser = useSelector((state: any) => state.auth.user);
  const isVendor = !!authUser?.vendor;

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isSendModalOpen, setIsSendModalOpen] = useState(false);

  const [selectedItem, setSelectedItem] = useState<PurchaseOrder>();

  const [isDuplicateModalOpen, setIsDuplicateModalOpen] = useState(false);

  const printHandleOpenOrderLevel = (modaltype: PrintTypes, payload: { _id: string }) => {
    (props as any).printHandleOpen(modaltype, payload);
  };

  const tableDefinition = [
    {
      key: 'humanId',
      label: 'ID',
      sortable: true,
    },
    {
      key: 'createdAt',
      label: 'Date',
      sortable: true,
      cell: (v) => (
        <Tooltip hasArrow aria-label="time" label={<FormattedTime value={v} />}>
          <Box>
            <FormattedDate value={v} />
          </Box>
        </Tooltip>
      ),
    },
    {
      key: '',
      label: 'Vendor',
      sortable: true,
      cell: (v, r: PurchaseOrder) => <> {r?.vendor?.companyName ?? ''}</>,
    },
    {
      key: '',
      label: 'Job Number',
      sortable: true,
      cell: (v, r: PurchaseOrder) => <> {r?.jobNumber ?? ''}</>,
    },
    {
      key: 'status',
      label: 'Status',
      sortable: true,
      cell: (v, r: PurchaseOrder) => {
        let msg = r.isVendorAcknowledged ? 'Vendor Acknowledged' : 'Not Acknowledged By Vendor';
        if (isVendor) {
          msg = r.shouldRequestVendorAcknowledge ? 'Acknowledged' : 'Requires Acknowledgement';
        }

        const shouldIconBeDisplayed = isVendor
          ? r.shouldRequestVendorAcknowledge
          : r.isVendorAcknowledged !== undefined;

        return (
          <HStack spacing={1} alignItems="center">
            {shouldIconBeDisplayed && (
              <Tooltip hasArrow aria-label="status" label={msg}>
                <Box as="span">
                  {isVendor ? (
                    <Icon as={FaExclamationTriangle} color="orange" />
                  ) : (
                    <Icon as={FaCheckSquare} color={r.isVendorAcknowledged ? 'green' : 'grey'} />
                  )}
                </Box>
              </Tooltip>
            )}
            {v && <Tag>{humanize(v)}</Tag>}
          </HStack>
        );
      },
    },
    {
      key: '',
      label: '',
      sortable: false,
      cell: (v, r: PurchaseOrder) => {
        const editLink = isVendor
          ? `${pathPrefix}/vendor-detail/${r._id}`
          : `${pathPrefix}/${r._id}`;
        return (
          <React.Fragment>
            <ButtonGroup spacing={1}>
              {!isVendor && (
                <Menu>
                  <MenuButton
                    as={IconButton}
                    aria-label="Options"
                    icon={<FaEllipsisV />}
                    variant="outline"
                  />
                  <MenuList>
                    <MenuItem
                      onClick={() => {
                        setIsOpenModal(true);
                        setSelectedItem(r);
                      }}
                      icon={<FaUpload />}
                    >
                      Receive
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setSelectedItem(r);
                        setIsSendModalOpen(true);
                      }}
                      icon={<FaRegEnvelope />}
                    >
                      Send
                    </MenuItem>

                    <MenuItem
                      onClick={() => {
                        setSelectedItem(r);
                        setIsDuplicateModalOpen(true);
                      }}
                      icon={<FaRegCopy />}
                    >
                      Duplicate
                    </MenuItem>

                    <MenuItem
                      onClick={() =>
                        printHandleOpenOrderLevel(PrintTypes.PurchaseOrder, {
                          _id: r?._id as string,
                        })
                      }
                      icon={<FaPrint />}
                    >
                      Print
                    </MenuItem>
                  </MenuList>
                </Menu>
              )}

              <Tooltip label="Edit Details">
                <Link to={editLink}>
                  <IconButton colorScheme="gray" aria-label="Edit" icon={<FaEdit />} />
                </Link>
              </Tooltip>
            </ButtonGroup>
          </React.Fragment>
        );
      },
      textAlign: 'right',
    },
  ];

  const statusFilter = isVendor ? { status: { $ne: 'draft' } } : {};

  const useListValues = useList({
    feathers: [
      'find',
      serviceName,
      [{ query: { $populate: ['vendor', 'items.product'], ...statusFilter } }],
    ],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  const headerActions = (
    <>
      {!isVendor && (
        <Link to={`${pathPrefix}/add`}>
          <Button colorScheme="blue" size="sm">
            New
          </Button>
        </Link>
      )}
    </>
  );

  const receiveItems = selectedItem?.items
    ?.map((item) => {
      const remainingQuantity =
        item.quantity -
        selectedItem?.receivings?.reduce(
          (acc, cur) =>
            acc + (cur.items?.find((i) => i.purchaseOrderItem === item._id)?.amount || 0),
          0
        );

      const itemLocation = selectedItem?.receivings?.find(
        (r) => r?.items?.find((i) => i.purchaseOrderItem === item?._id)?.location
      )?.items?.[0]?.location;

      return {
        purchaseOrderItem: item._id,
        product: item?.product,
        quantity: item?.quantity,
        amount: remainingQuantity,
        location: itemLocation,
        remainingQuantity,
      };
    })
    .filter((item) => item.remainingQuantity > 0);

  const receiving = {
    purchaseOrder: selectedItem?._id,
    items: receiveItems,
    notes: selectedItem?.notesToVendor,
  };

  return (
    <div>
      <ListPage
        header={'Purchase Orders'}
        subheader="List"
        useListValues={useListValues}
        history={history}
        pathPrefix={pathPrefix}
        tableDefintion={tableDefinition}
        withCheckboxes={true}
        headerActions={headerActions}
        getFilterDefinition={getFilterDefinition}
      />

      {isOpenModal && (
        <ReceivedModal
          onClose={() => setIsOpenModal(false)}
          onReload={() => useListValues.reload()}
          receiving={receiving}
        />
      )}

      {isDuplicateModalOpen && (
        <DuplicateModal
          data={selectedItem as NonNullable<ModelTypes.PurchaseOrder>}
          onClose={() => {
            setSelectedItem(undefined);
            setIsDuplicateModalOpen(false);
          }}
        />
      )}

      {isSendModalOpen && (
        <SendModal
          open={isSendModalOpen}
          onClose={() => setIsSendModalOpen(false)}
          purchaseOrderId={selectedItem?._id as string}
          purchaseOrder={selectedItem as ModelTypes.PurchaseOrder & { vendor?: { _id: string } }}
          contact={{
            email: (selectedItem?.vendor as any)?.email,
            name: `${(selectedItem?.vendor as any)?.firstName ?? ''} ${
              (selectedItem?.vendor as any)?.lastName ?? ''
            }`,
          }}
        />
      )}
    </div>
  );
};
export const List = connectOrderItemModals<IndexWrappedProps>(PreList);
