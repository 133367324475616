import * as React from 'react';
import { AlertIcon, Box, AbsoluteCenter, Center, Flex, Heading, Text, Input, InputGroup, InputRightElement, Button, Alert } from '@chakra-ui/react';
import { FaBarcode } from 'react-icons/fa';

interface IPickListScannerPromptProps {
  lastMessage?: string;
  errorMessage?: string | undefined;
  onSubmit(value: string): void;
}

const PickListScannerPrompt: React.FunctionComponent<IPickListScannerPromptProps> = (props) => {
  const { onSubmit, lastMessage, errorMessage } = props;
  const scanInput = React.useRef(null);
  const [value, setValue] = React.useState('');

  React.useEffect(() => {
    if (scanInput.current) {
      (scanInput as any).current.focus();
    }
  }, []);

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSubmit((scanInput as any).current.value.trim());
    }
  };

  const handleSubmitClick = () => {
    onSubmit((scanInput as any).current.value.trim());
  }

  return (
    <Box position='relative' h='600px' bgColor={'gray.50'}>
      <AbsoluteCenter width={'80%'} axis='both' borderWidth={1} bgColor={'white'} borderColor={'gray.200'} px={16} py={16} >
        <Flex alignContent={'center'} alignItems={'center'}>
          <Box mr={4} >
            <FaBarcode size={52} />
          </Box>
          <Box>
            <Heading textTransform={'uppercase'} color={'blue.700'} as={'h3'} size={'md'}>Pick List Scanner</Heading>
          </Box>

        </Flex>

        <Box mt={8}>
          {lastMessage && (
            <Alert mb={4} status='success' variant={'left-accent'}>
              <AlertIcon />
              {lastMessage}
            </Alert>
          )}
          <Input ref={scanInput} onKeyUp={handleKeyPress} onChange={(e) => setValue(e.target.value)} placeholder={'Scan or enter Order #...'} size={'md'} />
          {
            errorMessage && <Alert status="error" mt={3}>{errorMessage}</Alert>
          }
          <Button width={'100%'} mt={4} size='md' isDisabled={!!errorMessage && value.length > 0} onClick={handleSubmitClick}>
            Submit
          </Button>

        </Box>

        <Box mt={8} bgColor={'blue.50'} borderColor={'gray.200'} p={4}>
          <Text fontSize={'sm'}>Scan order or enter order ID and hit enter</Text>
        </Box>

        <Box>
        </Box>
      </AbsoluteCenter>
    </Box>
  );
};

export default PickListScannerPrompt;
