import * as React from 'react';
import { Button, Checkbox, Header, Segment, Table, Label, Popup } from 'semantic-ui-react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { FormattedDate } from 'react-intl';
import { useList, PageHeaderRow, PreLoader, PaginatorWrapper } from '@inkcloud/components';
import { FilterForm } from './FilterForm';

interface ListProps {
  products: { value: string; text: string }[];
  categories: { value: string; text: string }[];
}

const serviceName = 'store-front/content/pages';

const filterQueryMap = (values) => {
  const filterMap: any = {
    name: values.name ? { $LIKE: values.name } : undefined,
    url: values.url ? { $LIKE: values.url } : undefined,
    template: values.template ? { $LIKE: values.template } : undefined,
  };

  return ['type', 'product', 'category'].reduce(
    (m, field) => ({ ...m, [field]: values[field] ? values[field] : undefined }),
    filterMap
  );
};

export const List: React.FunctionComponent<ListProps & RouteComponentProps<any>> = (props) => {
  const {
    match: { params },
    location,
    history,
    products,
    categories,
  } = props;
  const {
    state,
    reload,
    handleDelete,
    selectedItems,
    clearAll,
    setChecked,
    someSelected,
    toggleAll,
    filter,
    setFilter,
    sort,
    handleSort,
  } = useList({
    feathers: [
      'find',
      serviceName,
      [
        {
          query: {
            microsite: params.micrositeId,
            $sort: { url: 1 },
            $select: ['createdAt', 'name', 'url', 'type', 'template'],
          },
        },
      ],
    ],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  const { queryResult, isLoading: isStateLoading } = state as any;

  if (!queryResult) {
    return null;
  }

  const pathPrefix = `/content/pages`;

  return (
    <div>
      <PageHeaderRow header="Pages" subheader="Content">
        <Link to={`${pathPrefix}/add`}>
          <Button content="New" primary size={'mini'} />
        </Link>
      </PageHeaderRow>

      <FilterForm
        products={products}
        categories={categories}
        value={filter}
        onChange={(v) => setFilter(v)}
        onReset={() => setFilter({})}
      />

      <Segment loading={state.isLoading}>
        <PaginatorWrapper.Top
          filter={filter}
          reload={reload}
          data={queryResult as any}
          pathPrefix={`${pathPrefix}`}
        />
        <Table size={'small'} sortable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                <input type="checkbox" onChange={toggleAll} />
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={sort.createdAt ? sort.createdAt : null}
                onClick={() => handleSort('createdAt')}
              >
                Created
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={sort.name ? sort.name : null}
                onClick={() => handleSort('name')}
              >
                Name
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={sort.url ? sort.url : null}
                onClick={() => handleSort('url')}
              >
                URL
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={sort.template ? sort.template : null}
                onClick={() => handleSort('template')}
              >
                Template
              </Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {queryResult &&
              (queryResult as any).data.map((r) => (
                <Table.Row key={r._id}>
                  <Table.Cell>
                    <input
                      type="checkbox"
                      value={r._id}
                      checked={selectedItems[r._id] || false}
                      onChange={setChecked}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <FormattedDate value={r.createdAt} />
                  </Table.Cell>
                  <Table.Cell>{r.name}</Table.Cell>

                  <Table.Cell>
                    <Label size="tiny" basic>
                      {decodeURIComponent(r.url)}
                    </Label>
                  </Table.Cell>
                  <Table.Cell>
                    <Label size="tiny">{r.template}</Label>
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    <Popup
                      size="mini"
                      content="Edit"
                      trigger={
                        <Link to={`/content/pages/${r._id}`}>
                          <Button size={'mini'} icon={'edit'} />
                        </Link>
                      }
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
        <PaginatorWrapper.Bottom
          reload={reload}
          data={queryResult as any}
          pathPrefix={`${pathPrefix}`}
        >
          {someSelected && <Button primary type="button" content="Delete" onClick={handleDelete} />}
        </PaginatorWrapper.Bottom>
      </Segment>
    </div>
  );
};
