import React, { useRef, useState } from 'react';
import { Document, Page } from '@react-pdf/renderer';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Alert,
  Box,
  Flex,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Tfoot,
  Text,
} from '@chakra-ui/react';

import { currencyFormat } from './helpers';
import type { itemsType } from './Detail';

interface IReviewModalProps {
  data: {
    items: itemsType;
    totalChange: number;
    total: number;
    hasChanges: boolean;
  };
  pdfUrl: string;
  isSubmitting: boolean;
  errorMessage: string;
  onApprove: () => void;
  onClose: () => void;
}

export default function ReviewModal(props: IReviewModalProps) {
  const {
    data: { items, totalChange, total, hasChanges },
    pdfUrl,
    isSubmitting,
    errorMessage,
    onApprove,
    onClose,
  } = props;

  return (
    <Modal size="full" isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Review Invoice Document</ModalHeader>
        <ModalCloseButton />
        <ModalBody height={'100%'}>
          <Flex h="100%">
            <Box w="50%">{pdfUrl && <iframe src={pdfUrl} width="100%" height="100%" />}</Box>
            <Box h="100%" w="50%">
              <TableContainer maxHeight={550} overflowY={'unset'}>
                <Table size="sm">
                  <Thead position={'sticky'} top={0} bgColor={'white'} zIndex={2}>
                    <Tr>
                      <Th>Order</Th>
                      <Th>Item</Th>
                      <Th>Description</Th>
                      <Th isNumeric>Quantity</Th>
                      <Th isNumeric>Amount </Th>
                      <Th isNumeric>Change Amount</Th>
                      <Th>Explanation</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {items?.map((item, index) => (
                      <Tr key={index}>
                        <Td
                          bg={(item as any)?.changeAmount ? 'yellow.100' : 'white'}
                          style={{ width: 85 }}
                        >
                          <Text
                            as={item?.isDeleted ? 'del' : 'p'}
                            color={item?.isDeleted ? 'gray.300' : 'inherit'}
                          >
                            {item?.orderHumanId ?? '-'}
                          </Text>
                        </Td>
                        <Td
                          bg={(item as any)?.changeAmount ? 'yellow.100' : 'white'}
                          style={{ width: 100 }}
                        >
                          <Text
                            as={item?.isDeleted ? 'del' : 'p'}
                            color={item?.isDeleted ? 'gray.300' : 'inherit'}
                          >
                            {item?.orderItemHumanId ?? '-'}
                          </Text>
                        </Td>
                        <Td bg={(item as any)?.changeAmount ? 'yellow.100' : 'white'}>
                          <Text
                            as={item?.isDeleted ? 'del' : 'p'}
                            color={item?.isDeleted ? 'gray.300' : 'inherit'}
                          >
                            {item?.type === 'item'
                              ? (item as any)?.orderItem?.productKey
                              : (item as any)?.description}
                          </Text>
                        </Td>
                        <Td
                          bg={(item as any)?.changeAmount ? 'yellow.100' : 'white'}
                          style={{ width: 75 }}
                          isNumeric
                        >
                          <Text
                            as={item?.isDeleted ? 'del' : 'p'}
                            color={item?.isDeleted ? 'gray.300' : 'inherit'}
                          >
                            {(item as any)?.orderItem?.quantity ?? '-'}
                          </Text>
                        </Td>
                        <Td bg={(item as any)?.changeAmount ? 'yellow.100' : 'white'} isNumeric>
                          <Text
                            as={(item as any)?.changeAmount || item?.isDeleted ? 'del' : 'p'}
                            color={item?.isDeleted ? 'gray.300' : 'inherit'}
                          >
                            {currencyFormat(item.amount ?? 0.0)}
                          </Text>
                        </Td>
                        <Td bg={(item as any)?.changeAmount ? 'yellow.100' : 'white'} isNumeric>
                          <Text
                            as={item?.isDeleted ? 'del' : 'p'}
                            color={item?.isDeleted ? 'gray.300' : 'inherit'}
                          >
                            {(item as any)?.changeAmount
                              ? currencyFormat((item as any)?.changeAmount ?? 0)
                              : '-'}
                          </Text>
                        </Td>
                        <Td bg={(item as any)?.changeAmount ? 'yellow.100' : 'white'}>
                          <Text
                            as={item?.isDeleted ? 'del' : 'p'}
                            color={item?.isDeleted ? 'gray.300' : 'inherit'}
                          >
                            {(item as any)?.changeExplanation ?? '-'}
                          </Text>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                  <Tfoot>
                    <Tr>
                      <Th colSpan={5}>Total</Th>
                      <Th isNumeric>
                        <Text as={hasChanges ? 'del' : 'p'}>{currencyFormat(total ?? 0.0)}</Text>
                        {hasChanges && <Text>{currencyFormat(totalChange ?? 0.0)}</Text>}
                      </Th>
                    </Tr>
                  </Tfoot>
                </Table>
              </TableContainer>
            </Box>
          </Flex>
          {errorMessage && (
            <Alert status="error" mt={3}>
              {errorMessage}
            </Alert>
          )}
        </ModalBody>

        <ModalFooter>
          <Button
            isDisabled={isSubmitting}
            isLoading={isSubmitting}
            colorScheme="blue"
            onClick={onApprove}
            mr={3}
          >
            Approve
          </Button>
          <Button isDisabled={isSubmitting} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
