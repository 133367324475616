import feathers  from './../../bootstrap/feathers';
import { PaymentData, PaymentMethod, PaymentCreditCardTypes } from './../../types';

export function initLoad(orderId) {
  return feathers.service<any>('/orders').get(orderId, {
    query: {
      $populate: ['customer', 'invoice'],
    }
  })
  .then((order) => {
    return feathers.service<any>('/invoice-line-items/unpage').get(order.invoice._id)
    .then((results: any) => {
      return { order, lineItems: results };
    });
  });
}

export function adjustInvoice(invoiceId, lineItems, dryRun=true) {
  return  feathers.service('/invoices/adjuster').create({
    invoiceId: invoiceId,
    lineItems: lineItems,
    dryRun: dryRun
  })
}

export function paymentAndAdjust(invoiceId, lineItems, paymentProcessDetails, paymentData: PaymentData) {
  const { paymentMethod, creditCard, optionalDescription, creditCardType } = paymentData;
  paymentProcessDetails.paymentMethod = paymentMethod.toUpperCase();
  paymentProcessDetails.description = optionalDescription

  paymentProcessDetails.paymentDetails = {};
  if (paymentMethod === PaymentMethod.CreditCard) {
    if (creditCardType === PaymentCreditCardTypes.New) {
      let names = ['', ''];
      if (creditCard.name) {
        names = creditCard.name.split(' ');
      }
      paymentProcessDetails.card = {
        firstName: names[0],
        lastName: names[1],
        number: creditCard.number,
        expiryMonth: creditCard.expMonth,
        expiryYear: creditCard.expYear,
        cvv: creditCard.cvv,
        billTo: {
          address: creditCard.address1,
          city: creditCard.city,
          state: creditCard.stateProvince,
          zip: creditCard.postalCode,
        },
        saveCard: creditCard.saveCard,
      };
    } else if (creditCardType === PaymentCreditCardTypes.Stored) {
      paymentProcessDetails.cardToken = paymentData.creditCardToken;
    }
  }

  return feathers.service('/invoices/adjust-with-payment').create({
    invoiceId: invoiceId,
    lineItems: lineItems,
    paymentProcessDetails: paymentProcessDetails,
  })
}
