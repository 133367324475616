import * as React from 'react';
import { Dropdown } from 'semantic-ui-react';

export interface VariantValueDropdownProps {
  // variantType: string;
  variant: { value: string; text: string; valueSortBy: string };
  options: { text: string; value: string; type: string; priority: number }[];
  loading: boolean;
  value: string | string[] | any;
  multiple?: boolean;
  onAddItem: (type: string, name: string) => void;
  onChange: (value: string) => void;
  isDisabled?: boolean;
}
export const VariantValueDropdown = (props: VariantValueDropdownProps) => {
  const {
    variant,
    options,
    loading,
    value,
    onAddItem,
    onChange,
    multiple = false,
    isDisabled = false,
  } = props;

  if (!variant?.value) {
    return null;
  }

  const filteredOptions = options.filter((o) => o.type === variant.value);
  let newOptions =
    variant.valueSortBy === 'name'
      ? filteredOptions
      : filteredOptions?.sort((a, b) => a.priority - b.priority);

  return (
    <>
      <Dropdown
        icon={isDisabled ? <></> : 'dropdown'}
        placeholder={`Variant Value${multiple ? 's' : ''}...`}
        selection
        fluid
        search
        clearable
        options={newOptions}
        loading={loading}
        value={value}
        allowAdditions={true}
        multiple={multiple}
        onAddItem={(e, data) => {
          onAddItem(props.variant.value, data.value.toString());
        }}
        disabled={isDisabled}
        onChange={(e, data) => onChange(data.value as any)}
      />
    </>
  );
};
