import * as React from 'react';
import { Button, Input, Dropdown, Segment, Form } from 'semantic-ui-react';
import { FeathersDropdown } from '@inkcloud/components';

export interface FilterFormProps {
  value: any;
  onChange: Function;
  onReset: Function;
  products: any[];
  categories: any[];
}

function initMapper(value) {
  const defaults = {
    name: '',
    url: '',
    template: '',
    types: [],
    type: '',
    product: '',
  };

  return Object.keys(defaults).reduce((p, cur) => {
    if (!p[cur]) {
      p[cur] = defaults[cur];
    }
    return p;
  }, value);
}

const pageTypeOptions = [
  { value: 'basic', text: 'Basic' },
  { value: 'category', text: 'Category' },
  { value: 'product', text: 'Product' },
];

export const FilterForm: React.FunctionComponent<FilterFormProps> = (props) => {
  const { value: preInitValue, onChange, onReset } = props;
  const value = initMapper(preInitValue);
  const handleChange = (field, value) => onChange({ ...props.value, [field]: value });

  return (
    <Segment>
      <Form size={'small'}>
        <Form.Group widths={'equal'}>
          <Form.Field>
            <Input
              placeholder="Name..."
              value={value.name}
              onChange={(e, data) => handleChange('name', data.value)}
            />
          </Form.Field>
          <Form.Field>
            <Form.Input
              placeholder="URL..."
              value={value.url}
              onChange={(e, data) => handleChange('url', data.value)}
            />
          </Form.Field>
          <Form.Field>
            <Form.Input
              placeholder="Template..."
              value={value.template}
              onChange={(e, data) => handleChange('template', data.value)}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group widths={'equal'}>
          <Form.Field>
            <Form.Dropdown
              selection
              search
              clearable
              placeholder="Page Type..."
              options={pageTypeOptions}
              value={value.type}
              onChange={(e, data) => handleChange('type', data.value)}
            />
          </Form.Field>
          <Form.Field>
            <Form.Dropdown
              selection
              search
              clearable
              lazyLoad
              placeholder="Category"
              options={props.categories}
              value={value.category}
              onChange={(e, data) => handleChange('category', data.value)}
            />
          </Form.Field>
          <Form.Field>
            <Form.Dropdown
              selection
              search
              clearable
              lazyLoad
              placeholder="Product"
              options={props.products}
              value={value.product}
              onChange={(e, data) => handleChange('product', data.value)}
            />
          </Form.Field>
        </Form.Group>
        <Form.Field>
          <Button size="mini" content="Reset Form" onClick={() => onReset()} />
        </Form.Field>
      </Form>
    </Segment>
  );
};
