import React, { useEffect, useState, useId } from 'react';
import { Box, Button, ButtonGroup, Flex, Heading, Spacer, Switch,FormControl, FormLabel } from '@chakra-ui/react';
import { Link, useHistory } from 'react-router-dom';
import { Select, AsyncSelect, MultiValue } from 'chakra-react-select';
import { basicQueryMap } from '@inkcloud/components';
import JobSchedulingBoard from './JobSchedulerBoard';
import JobSchedulerDetailDrawer from './JobSchedulerDetailDrawer';

import { Feathers } from '../../bootstrap/feathers/feathers';

interface IJobSchedulingListProps {}

const JobSchedulingList: React.FunctionComponent<IJobSchedulingListProps> = (props) => {
  const id = useId();
  const history = useHistory();

  const [hideEmptyColumns, setHideEmptyColumns] = useState(false);
  const [selectedView, setSelectedView] = useState<{
    _id: string;
    value: string;
    columns: string[];
    filter: any;
  } | null>(null);
  const [views, setViews] = useState([]);
  const [columns, setColumns] = useState([]);
  const [jobs, setJobs] = useState([]);

  const [selectedJob, setSelectedJob] = useState<any>();

  const [isLoading, setIsLoading] = useState({
    views: false,
    columns: false,
    jobs: false,
  });

  useEffect(() => {
    const loadViews = async () => {
      setIsLoading({
        ...isLoading,
        views: true,
      });
      try {
        const res = await Feathers.service('job-scheduler/views').find({
          query: {
            $sort: { createdAt: -1 },
          },
        });

        const updatedViews =
          res?.data?.map((view) => ({
            label: view.name,
            value: view._id,
            columns: view?.columns,
            filter: view?.filter,
          })) || [];
        setViews(updatedViews);

        if (selectedView?.columns) {
          setSelectedView(updatedViews.find((v) => v._id === selectedView?._id));
        }
      } catch (e) {
        console.error(e);
      }
      setIsLoading({
        ...isLoading,
        views: false,
      });
    };

    if (history.location.pathname === '/job-scheduler') {
      loadViews();
    }
  }, [history.location.key]);

  useEffect(() => {
    let filterRow = {} as any;

    if (selectedView?.filter?.rows) {
      filterRow = basicQueryMap(selectedView?.filter);

      console.log('filterRow', filterRow);
      filterRow = {
        ...filterRow,
        // workflowTemplate: filterRow?.workflowTemplate?.$elemMatch?.value,
      };
    }

    const loadJobs = async () => {
      try {
        const res = await Feathers.service('job-scheduler/jobs').find({
          query: {
            ...(filterRow || {}),
            $populate: ['workflowTemplate'],
            isComplete: { $ne: true },
            $np: 1,
          },
        });

        setJobs(
          res?.map((d) => ({
            ...d,
            workflowTemplateName: d?.workflowTemplate?.name ?? '',
          })) || []
        );
      } catch (e) {
        console.error(e);
      }
    };

    const loadColumns = async () => {
      try {
        const res = await Feathers.service('job-scheduler/columns').find({
          query: {
            $or: [
              {
                isGlobal: selectedView ? undefined : { $exists: false },
              },
              {
                isGlobal: !selectedView ? { $exists: true } : undefined,
              },
              {
                isGlobal: true,
              },
              {
                _id: {
                  $in: selectedView?.columns || [],
                },
              },
            ],
            $np: 1,
          },
        });

        setColumns(res || []);
      } catch (e) {
        console.error(e);
      }
    };

    loadColumns();
    loadJobs();
  }, [selectedView?.columns, selectedView?.filter, history.location.key]);

  // console.log({ selectedView, id, columns, jobs });

  return (
    <>
      <Box>
        <Flex mb={4}>
          <Heading>Job Scheduler</Heading>
          <Spacer />
          <ButtonGroup spacing={2}>
            <Link to={'/job-scheduler/columns'}>
              <Button variant={'outline'}>Columns</Button>
            </Link>
            <Link to={'/job-scheduler/workflow-templates'}>
              <Button variant={'outline'}>Workflow Templates</Button>
            </Link>
            <Link to={'/job-scheduler/workflow-template-types'}>
              <Button variant={'outline'}>Workflow Template Types</Button>
            </Link>
            {/* <Button onClick={onOpen} >Workflow Templates</Button> */}
            <Link to="/job-scheduler/views">
              <Button variant={'outline'}>Views</Button>
            </Link>
            <Link to="/job-scheduler/job/add">
              <Button colorScheme="blue">New Job</Button>
            </Link>
          </ButtonGroup>
        </Flex>

      <Flex p={8} bgColor={'white'} borderColor={'gray.200'} borderWidth={1} alignItems={'end'} alignContent={'end'}>
        <Box width={'50%'}>
          <Heading size={'sm'}>Select a view:</Heading>
          <Box maxWidth={300}>
            <Select
                useBasicStyles
                isClearable
                isLoading={isLoading.views}
                placeholder="Select View"
                onChange={(e) => setSelectedView(e)}
                options={views}
              />
          </Box>
        </Box>
        <Spacer />
        <Box>
        <FormControl display='flex' alignItems='center'>
          <FormLabel htmlFor='email-alerts' mb='0'>
            Hide Empty Columns
          </FormLabel>
          <Switch id='hideEmpty' onChange={(e) => (setHideEmptyColumns(e.target.checked))} />
        </FormControl>
        </Box>
      </Flex>

        <JobSchedulingBoard
          columns={columns}
          jobs={jobs}
          hideEmptyColumns={hideEmptyColumns}
          onJobSelected={(_id: string, data: any) => {
            setSelectedJob(data);
          }}

        />
      </Box>

      <JobSchedulerDetailDrawer
        isOpen={!!selectedJob}
        onClose={() => setSelectedJob(undefined)}
        job={selectedJob}
        />
    </>
  );
};

export default JobSchedulingList;
