import * as React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Checkbox, Header, Segment, Table, Label, Message, Popup } from 'semantic-ui-react';
import { useList, PageHeaderRow, EmptyWrapper, PaginatorWrapper } from '@inkcloud/components';
import { FilterForm } from './FilterForm';

import Feathers from '../../../../bootstrap/feathers';

import { AddModal } from './AddModal';
import { EmptyModal } from './EmptyModal';
import { ContentsModal } from './ContentsModal';
import { MassUpdateModal } from './MassUpdateModal';

export interface ListProps {}

const filterQueryMap = (values) => {
  let type;

  if (values.type === '1') {
    type = {
      parentLocation: { $exists: true },
      virtual: { $ne: true },
    };
  } else if (values.type === '2') {
    type = {
      parentLocation: { $exists: false },
      virtual: { $ne: true },
    };
  } else if (values.type === '3') {
    type = { virtual: true };
  } else {
    type = { virtual: undefined };
  }

  const filterMap: any = {
    name: values.name ? { $LIKE: values.name } : undefined,
    parentLocation: values.warehouse ? values.warehouse : undefined,
    ...type,
  };

  return [].reduce(
    (m, field) => ({ ...m, [field]: values[field] ? values[field] : undefined }),
    filterMap
  );
};

const serviceName = '/inventory/locations';
const pathPrefix = `/products/inventory/locations`;

const { useState } = React;

export const List: React.FunctionComponent<ListProps & RouteComponentProps> = (props) => {
  const {
    match: { params },
    location,
    history,
  } = props;

  const [isContentsModalOpen, setIsContentsModalOpen] = useState(null);
  const [isEmptyModalOpen, setIsEmptyModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState({
    massUpdate: false,
  });
  const [modalType, setModalType] = useState('');
  const [contentLocation, setContentLocation] = useState(null);
  const [contentLocationName, setContentLocationName] = useState('');
  const [modalAction, setModalAction] = useState('add');
  const [editData, setEditData] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorDeleteMessage, setErrorDeleteMessage] = useState('');

  const {
    state,
    reload,
    selectedItems,
    clearAll,
    setChecked,
    someSelected,
    toggleAll,
    filter,
    setFilter,
    sort,
    handleSort,
  } = useList({
    feathers: ['find', serviceName, [{ query: { $populate: 'parentLocation' } }]],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { name: 1 },
  });

  const { queryResult, isLoading: isStateLoading } = state as any;

  if (!queryResult) {
    return null;
  }

  const handleAddClose = () => {
    setIsModalOpen(false);
    setEditData({});
  };

  const handleEditClick = (item) => {
    const type = item.virtual ? 'virtual' : item.parentLocation ? 'bin' : 'warehouse';

    setModalAction('edit');
    setIsModalOpen(true);
    setModalType(type);
    setEditData(item);
  };

  const handleAddSubmit = async (values) => {
    const isEdit = !!values?._id;

    try {
      if (isEdit) {
        await Feathers.service(serviceName).patch(values._id, values);
      } else {
        await Feathers.service(serviceName).create(values);
      }
      handleAddClose();
      reload();
    } catch (e) {
      const errMsg =
        e?.code < 500 ? e?.message : 'We are experiencing technical difficulties. Please try again';
      setErrorMessage(errMsg);
    }
  };

  const handleDelete = async (id: string) => {
    setIsLoading(true);
    setErrorDeleteMessage('');
    try {
      await Feathers.service(serviceName).remove(id, {
        query: {},
      });
      reload();
    } catch (e) {
      const errMsg =
        e?.code < 500 ? e?.message : 'We are experiencing technical difficulties. Please try again';
      setErrorDeleteMessage(errMsg);
    }

    setIsLoading(false);
  };

  let isFiltered = false;
  if (filter) {
    isFiltered = Object.keys(filter).reduce(
      (prev, field) =>
        prev ||
        !!(Array.isArray(filter[field]) ? filter[field]?.some((f) => f.value) : filter[field]),
      false
    );
  }

  return (
    <div>
      <PageHeaderRow header="Locations" subheader="Inventory">
        <Button
          content="View Empty Locations"
          secondary
          size={'mini'}
          onClick={() => {
            setIsEmptyModalOpen(true);
          }}
        />

        <Button
          content="New Warehouse"
          primary
          size={'mini'}
          onClick={() => {
            setIsModalOpen(true);
            setModalAction('add');
            setModalType('warehouse');
          }}
        />
        <Button
          content="New Bin"
          primary
          size={'mini'}
          onClick={() => {
            setIsModalOpen(true);
            setModalAction('add');
            setModalType('bin');
          }}
        />
        <Button
          content="New Virtual"
          primary
          size={'mini'}
          onClick={() => {
            setIsModalOpen(true);
            setModalAction('add');
            setModalType('virtual');
          }}
        />
      </PageHeaderRow>

      <FilterForm value={filter} onChange={(v) => setFilter(v)} onReset={() => setFilter({})} />

      <EmptyWrapper isStateLoading={isStateLoading} queryResult={queryResult}>
        <Segment loading={state.isLoading}>
          <PaginatorWrapper.Top
            filter={filter}
            reload={reload}
            data={queryResult as any}
            pathPrefix={`${pathPrefix}`}
          >
            {isFiltered && (
              <Button
                content="Mass Update"
                size="small"
                onClick={() => setIsOpenModal({ ...isOpenModal, massUpdate: true })}
              />
            )}
          </PaginatorWrapper.Top>
          <Table size={'small'} sortable>
            <Table.Header>
              <Table.Row>
                {/* <Table.HeaderCell><input type="checkbox" onChange={toggleAll} /></Table.HeaderCell> */}
                <Table.HeaderCell>Type</Table.HeaderCell>
                <Table.HeaderCell
                  sorted={sort.name ? sort.name : null}
                  onClick={() => handleSort('name')}
                >
                  Name
                </Table.HeaderCell>
                <Table.HeaderCell />
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {queryResult &&
                (queryResult as any).data.map((r) => (
                  <Table.Row key={r._id}>
                    {/* <Table.Cell>
                    <input type="checkbox" value={r._id} checked={selectedItems[r._id] || false} onChange={setChecked} />
                  </Table.Cell> */}
                    <Table.Cell>
                      {r.virtual ? 'Virtual' : r.parentLocation ? 'Bin' : 'Warehouse'}
                    </Table.Cell>
                    <Table.Cell>
                      {r.parentLocation ? `${r.parentLocation.name}/${r.name}` : r.name}
                    </Table.Cell>

                    <Table.Cell>
                      <Button
                        content="View Contents"
                        secondary
                        size={'mini'}
                        onClick={() => {
                          setIsContentsModalOpen(true);
                          setContentLocation(r._id);
                          setContentLocationName(
                            r.parentLocation ? `${r.parentLocation.name}/${r.name}` : r.name
                          );
                        }}
                      />
                    </Table.Cell>
                    <Table.Cell textAlign="right">
                      <Popup
                        size="mini"
                        content="Edit"
                        trigger={
                          <Button icon="edit" size={'mini'} onClick={() => handleEditClick(r)} />
                        }
                      />
                      <Popup
                        size="mini"
                        content="Delete"
                        trigger={
                          <Button
                            icon="trash"
                            loading={isLoading}
                            size={'mini'}
                            onClick={() => handleDelete(r._id)}
                          />
                        }
                      />
                    </Table.Cell>
                  </Table.Row>
                ))}
            </Table.Body>
          </Table>
          {errorDeleteMessage && <Message negative>{errorDeleteMessage}</Message>}
          <PaginatorWrapper.Bottom
            reload={reload}
            data={queryResult as any}
            pathPrefix={`${pathPrefix}`}
          >
            {/* {someSelected && <Button primary type="button" content="Delete" onClick={handleDelete} />} */}
          </PaginatorWrapper.Bottom>
        </Segment>
      </EmptyWrapper>

      {isOpenModal.massUpdate && (
        <MassUpdateModal
          filter={filter}
          onReload={reload}
          onClose={() => setIsOpenModal({ ...isOpenModal, massUpdate: false })}
        />
      )}

      {isModalOpen && (
        <AddModal
          open={isModalOpen}
          handleSubmit={handleAddSubmit}
          handleClose={handleAddClose}
          type={modalType}
          initialValues={editData}
          action={modalAction}
          errorMessage={errorMessage}
        />
      )}

      {isEmptyModalOpen && (
        <EmptyModal open={isEmptyModalOpen} handleClose={() => setIsEmptyModalOpen(false)} />
      )}

      {isContentsModalOpen && (
        <ContentsModal
          open={isContentsModalOpen}
          handleClose={() => {
            setIsContentsModalOpen(false);
            setContentLocation(null);
          }}
          onReload={reload}
          location={contentLocation}
          locationName={contentLocationName}
        />
      )}
    </div>
  );
};
