import * as React from 'react';
import { Form, Segment, Header, Label, Message } from 'semantic-ui-react';
import Feathers from './../../../bootstrap/feathers';
import printJS from 'print-js';

export interface ShortRunBarcodeProps {}

export interface ShortRunBarcodeState {
  numBoxes: string;
  orderItemHumanId: string;
  isLoading: boolean;
  errorMessage: string;
  pdfUrl: string;
}

export default class ShortRunBarcode extends React.Component<
  ShortRunBarcodeProps,
  ShortRunBarcodeState
> {
  printWindow: null;
  constructor(props: ShortRunBarcodeProps) {
    super(props);

    this.state = {
      numBoxes: '1',
      orderItemHumanId: '',
      isLoading: false,
      errorMessage: null,
      pdfUrl: '',
    };
  }

  handleSubmit = (e, data) => {
    // Lookup the orderItemId
    this.setState({ ...this.state, isLoading: true });

    const humanId = this.state.orderItemHumanId;
    Feathers.service('/order-items')
      .find({
        query: { humanId: humanId },
      })
      .then((orderItems: any) => {
        if (orderItems.data.length === 0) {
          this.setState({ ...this.state, errorMessage: 'Order Item not found', isLoading: false });
          return;
        } else {
          const orderItemId = orderItems.data[0]._id;
          Feathers.service('/printable/order-item-box-ticket')
            .create({
              orderItemId: orderItemId,
              numBoxes: this.state.numBoxes,
            })
            .then((result: any) => {
              printJS(result.url);
              this.setState({
                ...this.state,
                errorMessage: '',
                isLoading: false,
                pdfUrl: result.url,
              });
            });
        }
      });
  };

  render() {
    return (
      <div>
        <Header as="h1">Short Run Barcode</Header>
        <Segment loading={this.state.isLoading}>
          <Label attached="top" content="Short Run Barcode Label Generator" />
          <Form>
            <Form.Group inline>
              <Form.Input
                value={this.state.orderItemHumanId}
                onChange={(e, data) =>
                  this.setState({ ...this.state, orderItemHumanId: data.value })
                }
                placeholder="Order Item ID..."
              />
              <Form.Input
                value={this.state.numBoxes}
                onChange={(e, data) => this.setState({ ...this.state, numBoxes: data.value })}
                placeholder="Num of boxes..."
              />
              <Form.Button
                type="button"
                primary
                content="Print Barcode Labels"
                onClick={this.handleSubmit}
              />
            </Form.Group>
            {this.state.errorMessage && <Message warning>{this.state.errorMessage}</Message>}

            {this.state.pdfUrl !== '' && (
              <a target="_blank" href={this.state.pdfUrl} className="ui button primary">
                Print PDF
              </a>
            )}
          </Form>
        </Segment>
      </div>
    );
  }
}
