// import * as React from 'react';
// import { Form, Segment, Modal, Button, Message } from 'semantic-ui-react';

// import { RouteComponentProps } from 'react-router-dom';
// import { PageHeaderRow } from '@inkcloud/components';
// import Feathers from '../../../../bootstrap/feathers';
// import { FilterForm } from './FilterForm';

// const { useState } = React;

// interface InventoryReportProps extends RouteComponentProps<any> {}

// function loadData(values) {
//   const params: any = { query: {} };

//   return Feathers.service('/reports/v3/results').create(
//     {
//       query: {
//         includeHidden: values.includeHidden,
//       },
//       microsite: values.microsite,
//       reportKey: 'inventory',
//       reportName: 'inventory',
//     },
//     params
//   );
// }

// const initValues = {
//   includeHidden: false,
//   microsite: '',
// };

// const InventoryReport: React.FunctionComponent<InventoryReportProps> = (props) => {
//   const [isLoading, setIsLoading] = useState(false);
//   const [micrositeList, setMicrositeLists] = useState(null || []);

//   const [values, setValues] = useState(initValues);

//   const [errorMessage, setErrorMessage] = useState('');

//   let columnApi;
//   let gridApi;

//   const handleSubmit = async () => {
//     try {
//       setIsLoading(true);
//       const result = await loadData(values);
//       // setData(result);
//       setIsLoading(false);
//       props.history.push('/reports/results');
//     } catch (e) {
//       setIsLoading(false);
//       const errMsg =
//         e?.code < 500 ? e.message : 'We are experiencing technical difficulties. Please try again';
//       setErrorMessage(errMsg);
//     }
//   };

//   React.useEffect(() => {
//     const loadMicrosites = async () => {
//       const list = (await Feathers.service('/microsites').find({
//         query: { $sort: { name: 1 }, $np: 1 },
//       })) as any;
//       setMicrositeLists(list.map((m) => ({ value: m._id, text: m.name })));
//     };

//     loadMicrosites();
//   }, []);

//   return (
//     <div>
//       <PageHeaderRow header="Inventory" subheader="Reports"></PageHeaderRow>
//       <FilterForm
//         onSubmit={handleSubmit}
//         values={values}
//         onChange={(v) => setValues(v)}
//         micrositeList={micrositeList}
//       />
//       <Segment loading={isLoading}>
//         {!errorMessage && <Message info content="Submit Form Above to view report data" />}
//         {errorMessage && <Message error content={errorMessage} />}
//       </Segment>
//     </div>
//   );
// };

// export default InventoryReport;

import React, { useState } from 'react';
import { Box, Button } from '@chakra-ui/react';

import { useHistory } from 'react-router-dom';
import { PageHeaderRow } from '@inkcloud/components';
import Feathers from '../../../bootstrap/feathers';
import FilterForm from '../FilterForm2';

function loadData(values) {
  const params: any = { query: {} };

  return Feathers.service('/reports/v3/results').create(
    {
      query: {
        includeHidden: values.includeHidden ?? false,
      },
      microsite: values?.microsites?.value,
      reportKey: 'inventory',
      reportName: 'inventory',
    },
    params
  );
}

export default function Inventory() {
  const history = useHistory();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [values, setValues] = useState({ start: null, end: null, microsites: [] });

  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async () => {
    setErrorMessage('');
    try {
      setIsSubmitting(true);
      const res = await loadData(values);

      history.push('/reports/results');
    } catch (e) {
      const errMsg =
        e?.code < 500 ? e?.message : 'We are experiencing technical difficulties. Please try again';
      setErrorMessage(errMsg);
    }
    setIsSubmitting(false);
  };

  return (
    <div>
      <PageHeaderRow header="Orders Flattened" subheader="Reports">
        <Button onClick={() => history.goBack()}>Back</Button>
      </PageHeaderRow>

      <Box p={3} borderWidth={1} borderRadius="md" bg="white">
        <FilterForm
          values={values}
          isSubmitting={isSubmitting}
          hasMicrosites={true}
          hasHidden
          isDateRequired={false}
          errorMessage={errorMessage}
          onChange={(v) => {
            setErrorMessage('');
            setValues(v);
          }}
          onSubmit={handleSubmit}
        />
      </Box>
    </div>
  );
}
