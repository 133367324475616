import * as React from 'react';
import { Switch, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { List } from './List';
import { SupplierList } from './SupplierList';
import { Detail } from './Detail';
import { SupplierDetail } from './SupplierDetail';

export default function routes(props) {
  const authUser = useSelector((state: any) => state.auth.user);
  const isVendor = !!authUser?.vendor;

  return (
    <Switch>
      <Route
        exact
        path="/supplier-billing-reconciliation"
        component={isVendor ? SupplierList : List}
      />
      {/* <Route path='/supplier-billing-review/add' component={Detail} /> */}
      <Route
        path="/supplier-billing-reconciliation/:id"
        component={isVendor ? SupplierDetail : Detail}
      />
    </Switch>
  );
}
