import * as React from 'react';
import { Switch, Route } from 'react-router-dom';

import { List } from './List';
import Detail from './Detail';

const routes = (props) => (
  <Switch>
    <Route exact path="/translation/pm-approval" component={List} />
    <Route exact path="/translation/pm-approval/:id" component={Detail} />
  </Switch>
);

export default routes;
