import * as React from 'react';
import {
  Button,
  Header,
  Checkbox,
  Form,
  Segment,
  Input,
  Image,
  Modal,
  Message,
  Divider,
} from 'semantic-ui-react';

interface InvoiceStatusModalProps {
  open: boolean;
  order: any;
  onClose: any;
  onSubmit: (isClose: boolean) => void;
  errorMessage: string;
}

const { useState } = React;

export const InvoiceStatusModal: React.FunctionComponent<InvoiceStatusModalProps> = (props) => {
  const { open, onClose, onSubmit, order, errorMessage } = props;

  const {
    invoice: { isClosed },
    humanId,
  } = order;
  const [statusClose, setStatusClose] = useState(isClosed);

  return (
    <>
      <Modal open={open} onClose={onClose} size={'tiny'} closeIcon>
        <Modal.Header>Invoice Status #{humanId}</Modal.Header>
        <Modal.Content>
          {errorMessage && <Message error content={errorMessage} />}
          <Form>
            <Form.Field>
              <Checkbox
                toggle
                label={`Closed`}
                defaultChecked={isClosed}
                onChange={(e, data) => setStatusClose(data.checked)}
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button primary content="Update" onClick={() => onSubmit(statusClose)} />
        </Modal.Actions>
      </Modal>
    </>
  );
};
