// declare module 'pagination';
import * as React from 'react';
import * as paginator from 'pagination';

export interface PaginatorProps {
  prelink: string;
  // current: string;
  rowsPerPage: string;
  skip: string;
  totalResult: string;
  onClick: Function;
  className?: string;
}

export interface PaginatorState {}

export class Paginator extends React.Component<PaginatorProps, PaginatorState> {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(page) {
    this.props.onClick(this.getPagination(page));
  }

  getPagination(page = 0) {
    return {
      $skip: (page - 1) * parseInt(this.props.rowsPerPage),
      $limit: this.props.rowsPerPage,
    };
  }

  render() {
    if (!this.props.totalResult) {
      return null;
    }

    let current = parseInt(this.props.skip) / parseInt(this.props.rowsPerPage) + 1;
    let paginationConfig = {
      totalResult: this.props.totalResult,
      current: current,
      rowsPerPage: this.props.rowsPerPage,
      prelink: this.props.prelink,
    };

    let p = new paginator.SearchPaginator(paginationConfig);
    let data = p.getPaginationData();
    if (data.range.length === 0) {
      data.range.push(1);
    }

    let pages = data.range.map((page) => {
      return (
        <a
          key={page}
          className={'item' + (+data.current === +page ? ' active' : '')}
          onClick={() => this.handleClick(page)}
        >
          {page}
        </a>
      );
    });

    if (data.previous) {
      pages.unshift(
        <a key="prev" className="item" onClick={() => this.handleClick(data.previous)}>
          <i className="icon caret left"></i>
        </a>
      );
    } else {
      pages.unshift(
        <a key="prev" className="disabled item">
          <i className="icon caret left"></i>
        </a>
      );
    }

    if (+data.current !== 1) {
      pages.unshift(
        <a key="first" className="item" onClick={() => this.handleClick(1)}>
          First
        </a>
      );
    } else {
      pages.unshift(
        <a key="first" className="disabled item">
          First
        </a>
      );
    }

    if (data.next) {
      pages.push(
        <a key="next" className="item" onClick={() => this.handleClick(data.next)}>
          <i className="icon caret right"></i>
        </a>
      );
    } else {
      pages.push(
        <a key="next" className="disabled item">
          <i className="icon caret right"></i>
        </a>
      );
    }

    let lastPage: number = Math.ceil(
      parseInt(this.props.totalResult) / parseInt(this.props.rowsPerPage)
    );

    if (+data.current !== lastPage) {
      pages.push(
        <a key="last" className="item" onClick={() => this.handleClick(lastPage)}>
          Last
        </a>
      );
    } else {
      pages.push(
        <a key="last" className="disabled item">
          Last
        </a>
      );
    }

    let displayedIndex: any = parseInt(this.props.skip) + parseInt(this.props.rowsPerPage);
    if (parseInt(displayedIndex) > parseInt(this.props.totalResult)) {
      displayedIndex = parseInt(this.props.totalResult);
    }

    pages.unshift(
      <div key="label" className="header item">
        {this.props.skip + 1} - {displayedIndex} of {this.props.totalResult}{' '}
      </div>
    );

    return (
      <div
        className={
          'ui ' + (this.props.className ? this.props.className : ' ') + ' pagination mini menu '
        }
      >
        {pages}
      </div>
    );
  }
}
