import * as React from 'react';
import { Tab, Divider, Grid, Button, Header, Segment } from 'semantic-ui-react';

import { ShipAddressForm } from './ShipAddressForm';
import { ShippingRates } from './ShippingRatesComponent';

interface ShipOutProps {
  values: any;
  onSaveAddress: (v: any) => void;
  setFieldValue: (name: string, value: any) => void;
  errors: any;
  isLoadingAddress: boolean;
}

export const ShipOut: React.FunctionComponent<ShipOutProps> = (props) => {
  const { setFieldValue, values, errors, onSaveAddress, isLoadingAddress } = props;
  const originalShipTo = 'ACME Printing, John Doe, 12345 Main St, Los Angeles CA 90013';

  const isDisabled = errors && Object.keys(errors).length === 0 && errors.constructor === Object;

  const errorMessage = errors && errors.shippingDetails;

  const panes = [
    {
      menuItem: 'Ship To',
      render: () => {
        return (
          <Tab.Pane attached={false} key={'shipTo'}>
            <Divider horizontal>Update address to:</Divider>
            <ShipAddressForm
              values={values}
              setFieldValue={setFieldValue}
              errorMessage={errorMessage}
              isLoadingAddress={isLoadingAddress}
              isDisabled={isDisabled}
              onSaveAddress={onSaveAddress}
              address={'shipTo'}
            />
          </Tab.Pane>
        );
      },
    },
    {
      menuItem: 'Ship From',
      render: () => {
        return (
          <Tab.Pane attached={false} key={'shipFrom'}>
            <Divider horizontal>Update address from:</Divider>

            <ShipAddressForm
              values={values}
              setFieldValue={setFieldValue}
              errorMessage={errorMessage}
              isLoadingAddress={isLoadingAddress}
              isDisabled={isDisabled}
              onSaveAddress={onSaveAddress}
              address={'shipFrom'}
            />
          </Tab.Pane>
        );
      },
    },
  ];

  return (
    <Grid columns={2}>
      <Grid.Column width={10}>
        {values.shippingMethod === 'SHIPPING_SHIP_OUT' && (
          <Tab panes={panes} menu={{ secondary: true, pointing: true }} />
        )}
      </Grid.Column>
      <Grid.Column width={6}>
        <Header as="h5" attached="top">
          Select a shipping rate
        </Header>
        <Segment attached="bottom" loading={isLoadingAddress}>
          <ShippingRates
            rates={values.rateOptions}
            value={values.serviceKey || values.order?.shipment?.serviceKey}
            setFieldValue={setFieldValue}
          />
        </Segment>
      </Grid.Column>
    </Grid>
  );
};
