import * as React from 'react';
import { Button, Header, Message, Segment } from 'semantic-ui-react';
import { RouteComponentProps, Link } from 'react-router-dom';
import { PageHeaderRow, PreLoader, useFeathers } from '@inkcloud/components';
import { FulfillmentForm } from './FulfillmentForm';

import Feathers from '../../../bootstrap/feathers';

const { useState, useEffect } = React;

const serviceName = 'fulfillment-staging-locations/staged';
const pathPrefix = `/products/fulfillment-staging-locations`;
const service = Feathers.service(serviceName);
const commonParams = {
  query: {
    $populate: ['contains.orderItem'],
  },
};

export const Detail = ({ id, onDone }: { id: string; onDone: () => void }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (values: any) => {
    const payload = {
      ...values,
      fulfillmentStagingLocation: id,
    };

    setErrorMessage('');
    setIsSubmitting(true);
    try {
      await service.create(payload, commonParams);
      onDone();
    } catch (e) {
      const errMsg = e?.code < 500 ? e?.message : 'Something went wrong. Please try again later.';
      setErrorMessage(errMsg);
    }
    setIsSubmitting(false);
  };

  return (
    <FulfillmentForm
      initialValues={{ quantity: '', orderItem: '' }}
      onSubmit={handleSubmit}
      errorMessage={errorMessage}
      isSubmitting={isSubmitting}
    />
  );
};
