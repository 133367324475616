import * as React from 'react';
import { Button, Form, Image, Grid, Message, Icon } from 'semantic-ui-react';
import { Formik, Form as FormikForm, Field, ErrorMessage, FieldProps } from 'formik';
import * as Yup from 'yup';
import { SemanticField } from '../../../common/Form';
import { FeathersDropdown } from '@inkcloud/components';

import { ImageUpload } from './../../products/products/stock-product/StockForm/ImageUpload';

interface StarterTemplatesFormProps {
  initialValues: any;
  onSubmit: Function;
  errorMessage: string;
}

const initialMapper = (values) => {
  const isSameWidth = (values?.finishSize?.width ?? 0) === (values?.flatSize?.width ?? 0);
  const isSameHeight = (values?.finishSize?.height ?? 0) === (values?.flatSize?.height ?? 0);

  return {
    products: values.products || [],
    desc: values.desc || '',
    finishSize: {
      width: values?.finishSize?.width ?? '',
      height: values?.finishSize?.height ?? '',
    },
    flatSize: {
      width: values?.flatSize?.width ?? '',
      height: values?.flatSize?.height ?? '',
    },
    sameAsFinish: isSameWidth && isSameHeight,
    upload: values?.upload ?? '',
  };
};

export const StarterTemplatesForm: React.FC<StarterTemplatesFormProps> = (props) => {
  const { errorMessage } = props;

  const DashboardUserSchema = Yup.object().shape({
    desc: Yup.string().required('Required'),
    // products: Yup.array().of(Yup.string()).required('Required'),
    finishSize: Yup.object().shape({
      width: Yup.number().required('Required').typeError('Must a number'),
      height: Yup.number().required('Required').typeError('Must a number'),
    }),
    sameAsFinish: Yup.boolean(),
    // flatSize: Yup.object().shape({
    width: Yup.number().when('sameAsFinish', {
      is: false,
      then: Yup.number().required('Required').typeError('Must be a number.'),
    }),
    height: Yup.number().when('sameAsFinish', {
      is: false,
      then: Yup.number().required('Required').typeError('Must be a number.'),
    }),
    // })
    upload: Yup.string().required('Required'),
  });

  return (
    <Formik
      initialValues={initialMapper(props.initialValues)}
      validationSchema={DashboardUserSchema}
      onSubmit={(values, { setSubmitting }) => {
        props.onSubmit(values);
      }}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
          setFieldValue,
          setFieldTouched,
        } = props;

        return (
          <FormikForm
            className={`ui form ${isSubmitting && !errorMessage && 'loading'} ${
              errorMessage && 'error'
            }`}
          >
            <Form.Field>
              <Field
                name="products"
                render={({ field }: FieldProps<any>) => (
                  <FeathersDropdown
                    search
                    selection
                    clearable
                    lazyLoad
                    multiple
                    placeholder="Products..."
                    serviceName="/products-legacy"
                    query={{
                      // $sort: { name: 1 },
                      $np: 1,
                    }}
                    resultMapper={(r) => ({
                      key: r._id,
                      value: r._id,
                      text: `${r.staticName || r.description}`,
                    })}
                    {...field}
                    onChange={(e, data) =>
                      field.onChange({ target: { value: data.value, name: 'products' } } as any)
                    }
                  />
                )}
              />
              <ErrorMessage
                render={(msg) => <Message negative size="tiny" content={msg} />}
                name="products"
              />
            </Form.Field>
            <Form.Field>
              <SemanticField name="desc" label="Description" component={Form.Input} />
              <ErrorMessage
                render={(msg) => <Message negative size="tiny" content={msg} />}
                name="desc"
              />
            </Form.Field>
            <Grid columns={2}>
              <Grid.Column>
                <label>Finished</label>
                <Form.Group widths="equal">
                  <Form.Field>
                    <SemanticField name="finishSize.width" label="Width" component={Form.Input} />
                    <ErrorMessage
                      render={(msg) => <Message negative size="tiny" content={msg} />}
                      name="finishSize.width"
                    />
                  </Form.Field>
                  <Form.Field>
                    <SemanticField name="finishSize.height" label="Height" component={Form.Input} />
                    <ErrorMessage
                      render={(msg) => <Message negative size="tiny" content={msg} />}
                      name="finishSize.height"
                    />
                  </Form.Field>
                </Form.Group>
              </Grid.Column>
              <Grid.Column>
                <label>Flat</label>
                <Form.Group widths="equal">
                  {!values.sameAsFinish && (
                    <>
                      <Form.Field>
                        <SemanticField name="width" label="Width" component={Form.Input} />
                        <ErrorMessage
                          render={(msg) => <Message negative size="tiny" content={msg} />}
                          name="width"
                        />
                      </Form.Field>
                      <Form.Field>
                        <SemanticField name="height" label="Height" component={Form.Input} />
                        <ErrorMessage
                          render={(msg) => <Message negative size="tiny" content={msg} />}
                          name="height"
                        />
                      </Form.Field>
                    </>
                  )}
                  <Form.Field>
                    <label>&nbsp;</label>
                    <SemanticField
                      name="sameAsFinish"
                      label="Same As Finish"
                      component={Form.Checkbox}
                    />
                  </Form.Field>
                </Form.Group>
              </Grid.Column>
            </Grid>
            <Form.Group widths="equal">
              <Form.Field>
                {values.upload?.fileType === 'image/jpeg' ||
                values.upload?.fileType === 'image/jpg' ||
                values.upload?.fileType === 'image/png' ||
                values.upload?.fileType === 'image/gif' ? (
                  <Image
                    bordered
                    size="small"
                    src={`https://storage.googleapis.com/${values.upload?.cloudBucket ?? ''}/${
                      values.upload?.cloudFilename ?? ''
                    }`}
                  />
                ) : (
                  values.upload?.cloudBucket && (
                    <a
                      href={`https://storage.googleapis.com/${values.upload?.cloudBucket ?? ''}/${
                        values.upload?.cloudFilename ?? ''
                      }`}
                      target="_blank"
                    >
                      <Icon name="file outline" size="big" /> {values.upload?.originalFilename}
                    </a>
                  )
                )}
                <ImageUpload
                  onUpload={(file) => {
                    if (file) {
                      setFieldValue('fileType', file?.files[0]?.contentType ?? '');
                      setFieldValue('upload', file?.files[0]?.uploadLog ?? '');
                      setFieldValue('tenant', file?.tenantId ?? '');
                    }
                  }}
                  showFiles
                  multiple={false}
                  api="/starter-templates/upload"
                />
                {(errors as any).upload && (
                  <Message negative size="tiny" content={(errors as any).upload} />
                )}
              </Form.Field>
            </Form.Group>

            <Button primary type="submit" disabled={isSubmitting && !errorMessage}>
              Save
            </Button>
          </FormikForm>
        );
      }}
    </Formik>
  );
};
