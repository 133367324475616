import * as React from 'react';
import { Style } from '@react-pdf/types';
import { Text } from '@react-pdf/renderer';

import { BillTo as BillToGeneral, BillToValue } from '..';
import { PackingSlipContext } from './PackingSlipContext';

export interface IBillToProps {
  value: BillToValue;
  style?: Style;
  textStyle?: Style;
}

export function BillTo(props: IBillToProps) {
  const ctx = React.useContext(PackingSlipContext);
  const {
    dataCtx: {
      invoice: { billingAddress },
    },
  } = ctx;
  const { value, style, textStyle } = props;

  // const value: BillToValue = billingAddress;

  return <BillToGeneral value={billingAddress || value} style={style} textStyle={textStyle} />;
}
