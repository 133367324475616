import * as React from 'react';
import { Formik, Form as FormikForm, Field, FieldArray, FieldProps } from 'formik';
import { Segment, Form, Radio, Popup, Menu, Button } from 'semantic-ui-react';

interface EmailFormProps {
  values: any;
  action: string;
  users: any;
  title: string;
  setFieldValue: (name: string, value: any) => void;
}

export const EmailForm: React.FunctionComponent<EmailFormProps> = (props) => {
  const { values, action, users, title, setFieldValue } = props as any;

  return (
    <>
      <Menu attached="top" pointing secondary>
        <Menu.Item name={title} active={true} />
      </Menu>
      <Segment attached="bottom">
        <FieldArray
          name={`email.${action}`}
          render={(arrayHelpers) => (
            <div>
              {values &&
                values.length > 0 &&
                values.map((f, index) => (
                  <div key={index}>
                    <Form.Group>
                      <Field
                        name={`email.${action}.${index}.user`}
                        render={({ field }: FieldProps<any>) => (
                          <Form.Dropdown
                            placeholder="Select a user"
                            fluid
                            search
                            style={{ minWidth: '320px' }}
                            selection
                            {...field}
                            options={
                              (users.length > 0 &&
                                users.map((u) => ({
                                  value: u._id,
                                  text: `${u.firstName} ${u.lastName}`,
                                }))) ||
                              []
                            }
                            onChange={(e, data) =>
                              setFieldValue(`email.${action}.${index}.user`, data.value)
                            }
                          />
                        )}
                      />
                      <Popup
                        content="Remove recipient"
                        trigger={
                          <Button
                            type="button"
                            onClick={() => arrayHelpers.remove(index)}
                            content={'-'}
                          />
                        }
                      />
                    </Form.Group>
                  </div>
                ))}
              <Popup
                content="Add recipient"
                trigger={
                  <Button
                    type="button"
                    onClick={() =>
                      arrayHelpers.push({
                        type: 'email',
                        user: '',
                      })
                    }
                    content={'+'}
                    disabled={values && values.length >= users.length}
                  />
                }
              />
            </div>
          )}
        />
      </Segment>
    </>
  );
};
