import * as React from 'react';
import { Switch, Route } from 'react-router-dom';

import List from './List';
import { Detail } from './Detail';
import { DesignTemplateModal } from './DesignTemplateModal';

const route = (props) => (
  <Switch>
    <Route exact path="/admin/design-templates" component={List} />
    <Route exact path="/admin/design-templates/set-order" component={DesignTemplateModal} />
    <Route exact path="/admin/design-templates/add" component={Detail} />
    <Route exact path="/admin/design-templates/:id" component={Detail} />
  </Switch>
);

export default route;
