import * as React from 'react';
import { Alert, Button, Input, Radio, Stack, RadioGroup, Textarea } from '@chakra-ui/react';
import { AsyncSelect } from 'chakra-react-select';
import { FormProvider, useForm } from 'react-hook-form';
import FormControl from '../../../common/v3/Form/FormControl';
import { Feathers } from '../../../bootstrap/feathers/feathers';
import { loadProductOptions } from './policies/AllottmentPolicyForm';
import DatePickerInput from '../../../common/v3/Form/DatePickerInput';

type AllottmentFormProps = {
  initialValues: any;
  onSubmit: (v: any) => void;
  isSubmitting?: boolean;
  errorMessage?: string;
};

export default function AllottmentForm(props: AllottmentFormProps) {
  const { initialValues, onSubmit, isSubmitting, errorMessage } = props;
  const methods = useForm({
    defaultValues: initialValues,
  });
  async function loadUserOptions(e): Promise<any> {
    try {
      const res = await Feathers.service('microsites/customers').find({
        query: {
          $np: 1,
          $sort: { name: 1 },
          $select: ['firstName', 'lastName', 'companyName'],
          $or: [{ firstName: { $LIKE: e } }, { lastName: { $LIKE: e } }],
          $limit: 20,
          microsite: initialValues?.microsite,
        },
      });

      return res?.map((r) => ({
        label: `${r?.companyName ?? ''} - ${r?.firstName ?? ''} ${r?.lastName ?? ''}`,
        value: r?._id,
      }));
    } catch (er) {
      console.error(er);
      throw er;
    }
  }

  async function loadAllotmentOptions(e): Promise<any> {
    try {
      const res = await Feathers.service('user-allotment-policies').find({
        query: {
          $np: 1,
          $sort: { name: 1 },
          $select: ['name'],
          name: e ? { $LIKE: e } : undefined,
          microsite: initialValues?.microsite,
          $limit: 20,
        },
      });

      return res?.map((r) => ({ label: r?.name, value: r?._id }));
    } catch (er) {
      console.error(er);
      throw er;
    }
  }
  const { register, handleSubmit, setValue, watch } = methods;
  return (
    <>
      <FormProvider {...methods}>
        <FormControl
              field="$type"
              label="Type"
              required={true}
              render={(fieldProps) =>
                <RadioGroup   { ...fieldProps }>
                  <Stack direction='row'>
                    <Radio value='all'>All Microsite Users</Radio>
                    <Radio value='specific'>Specific User</Radio>
                  </Stack>
                </RadioGroup>
              }
            />


        {watch('$type') === 'specific' && (
        <FormControl
          field="micrositeUser"
          label="User"
          render={(fieldProps) => (
            <AsyncSelect
              useBasicStyles
              isClearable
              {...fieldProps}
              placeholder=""
              cacheOptions
              defaultOptions
              loadOptions={loadUserOptions}
            />
          )}
        />
        )}
        {!watch('products')?.length && (
          <FormControl
            field="allotmentPolicy"
            label="Policy"
            render={(fieldProps) => (
              <AsyncSelect
                useBasicStyles
                isClearable
                {...fieldProps}
                placeholder=""
                cacheOptions
                defaultOptions
                loadOptions={loadAllotmentOptions}
              />
            )}
          />
        )}

        {!watch('allotmentPolicy') && (
          <>
            <FormControl
              field="products"
              label="Products"
              render={(fieldProps) => (
                <AsyncSelect
                  useBasicStyles
                  isClearable
                  isMulti
                  {...fieldProps}
                  placeholder=""
                  cacheOptions
                  defaultOptions
                  loadOptions={loadProductOptions}
                />
              )}
            />

            <FormControl
              field="amount"
              label="Amount"
              required={true}
              render={(fieldProps) => <Input {...fieldProps} />}
            />

            <FormControl
              field="expiresAt"
              label="Expires At"
              required={true}
              render={(fieldProps) => <DatePickerInput {...fieldProps} />}
            />
          </>
        )}

        {errorMessage && (
          <Alert my={4} status="error">
            {errorMessage}
          </Alert>
        )}
        <Button
          isDisabled={isSubmitting}
          isLoading={isSubmitting}
          colorScheme="blue"
          onClick={handleSubmit(onSubmit)}
        >
          Save
        </Button>
      </FormProvider>
    </>
  );
}
