// import * as React from 'react';
// import { Header, Segment, Message } from 'semantic-ui-react';
// import "../../../../../../node_modules/ag-grid/dist/styles/ag-grid.css";
// // import "../../../../../../node_modules/ag-grid/dist/styles/theme-fresh.css";
// import Feathers from './../../../bootstrap/feathers'
// import ProductFilterForm from './../ProductFilterForm';
// import { filterMapper } from './../products/List';
// import { PageHeaderRow } from '@inkcloud/components';

// import * as numeral from 'numeral'

// import { AgGridReact } from "ag-grid-react";
// import { ColumnApi, GridApi } from "ag-grid";

// export interface AppProps {
// }

// export interface AppState {
//   columns: any[];
//   data: any[];
//   attributeColumns: any[];
//   filter: any;
// }
// function MyRenderer(params) {
//   return (
//     <span className="my-renderer">
//       <img src="https://d1yk6z6emsz7qy.cloudfront.net/static/images/loading.gif" className="my-spinner" />
//       {params.value}
//     </span>
//   );
// }
// const InventoryLowEmailNotifyRenderer = props => {
//   console.log('inventoryLowEmailNotify params', props)

//   return (
//     <span>
//       <span>
//         <input type="text" value={props?.value?.email} />
//       </span>
//       <span>
//         <input type="text" value={props?.value?.name} />
//       </span>
//     </span>
//   )

//   // return props?.value?.email
// }

// export default class App extends React.Component<AppProps, AppState> {
//   private gridApi: GridApi;

//   private columnApi: ColumnApi;

//   constructor(props: AppProps) {
//     super(props);
//     this.state = {
//       filter: null,
//       attributeColumns: [],
//       columns: [],
//       data: []
//     }
//   }

// handleFilterSubmit = (values, another) => {
//   this.setState({ ...this.state, filter: values }, async () => {
//     await this.loadData();
//   });
// }

//   handleFilterClear = () => {
//     this.setState({ ...this.state, filter: null });
//   }

//   test = (props) => {
//     console.log('test props', props)

//     return {
//       component: InventoryLowEmailNotifyRenderer,
//       params: { props }
//     }

//   }

//   async loadData() {
//     const rightAlignStyle = { 'text-align': 'right' };
//     await Feathers.service('/products-legacy/price-grid')
//       .find({ query: filterMapper(this.state.filter) })
//       .then((results: any) => {
//         // map headers
//         const attributeColumns = Object.keys(results.headers)
//           ?.map(k => ({ field: results.headers[k].id, headerName: results.headers[k].name }));

//         const updatedData = results?.data?.map(d => ({
//           ...d,
//           inventoryLowThreshold: d?.product?.inventoryLowThreshold,
//           inventoryLowEmailNotify: d?.product?.inventoryLowEmailNotify,
//           rfqNum: d?.product?.rfqNum,
//           shouldExcludeFromHandlingFees: d?.product?.shouldExcludeFromHandlingFees,
//           weightUnit: d?.product?.weightUnit,
//           weightBasis: d?.product?.weightBasis,
//           unitOfMeasure: d?.product?.unitOfMeasure,
//           unitOfMeasureConversion: d?.product?.unitOfMeasureConversion,
//           tags: d?.product?.tags?.join(', ') ?? [],
//           costPerUnit: (d?.cost ?? 0) / d?.quantity,
//           pricePerUnit: (d?.price ?? 0) / d?.quantity,
//           price: numeral(d?.price).format('$0,0.000')
//         }))

//         const headers = [{ field: 'category', headerName: 'Category' }, { field: 'key', headerName: 'SKU' }]
//           .concat(attributeColumns)
//           .concat([{ field: 'name', headerName: 'Name' }] as any)
//           .concat([{ field: 'inventoryLowThreshold', headerName: 'Inventory Low Threshold', editable: true, minWidth: 75, cellStyle: { ...rightAlignStyle, 'background': '#f8ffff' } }] as any)
//           .concat([{
//             field: 'inventoryLowEmailNotify', headerName: 'Inventory Low Email Notify', editable: true, minWidth: 75, cellStyle: { 'background': '#f8ffff' },
//           }] as any)
//           .concat([{ field: 'rfqNum', headerName: 'RFQ Number', editable: true, cellStyle: { ...rightAlignStyle, 'background': '' } }] as any)
//           .concat([{
//             field: 'shouldExcludeFromHandlingFees', headerName: 'Exclude From Handling Fees', editable: true, minWidth: 75, cellStyle: { 'background': '', }
//           }] as any)
//           .concat([{ field: 'weightUnit', headerName: 'weight Unit', editable: true, minWidth: 75, cellStyle: { ...rightAlignStyle, 'background': '' } }] as any)
//           .concat([{ field: 'weightBasis', headerName: 'weight Basis', editable: true, minWidth: 75, cellStyle: { ...rightAlignStyle, 'background': '' } }] as any)
//           .concat([{ field: 'unitOfMeasure', headerName: 'Unit of Measure', editable: true, minWidth: 75, cellStyle: { 'background': '' } }] as any)
//           .concat([{ field: 'unitOfMeasureConversion', headerName: 'Unit of Measure Conversion', editable: true, minWidth: 75, cellStyle: { ...rightAlignStyle, 'background': '' } }] as any)
//           .concat([{ field: 'tags', headerName: 'Tags', editable: true, minWidth: 75, cellStyle: { 'background': '' } }] as any)
//           .concat([{ field: 'costPerUnit', headerName: 'Cost Per Unit', pinned: 'right', editable: true, minWidth: 100, cellStyle: { ...rightAlignStyle, 'background': '#f8ffff' } }] as any)
//           .concat([{ field: 'cost', headerName: 'Cost', pinned: 'right', editable: true, minWidth: 100, cellStyle: { ...rightAlignStyle, 'background': '#f8ffff' } }] as any)
//           .concat([{ field: 'originalPrice', headerName: 'Original Price', pinned: 'right', editable: true, minWidth: 100, cellStyle: { ...rightAlignStyle, 'background': '#f8ffff' } }] as any)
//           .concat([{ field: 'quantity', headerName: 'Quantity', pinned: 'right', cellStyle: rightAlignStyle }] as any)
//           .concat([{ field: 'pricePerUnit', headerName: 'Price Per Unit', pinned: 'right', editable: true, minWidth: 100, cellStyle: { ...rightAlignStyle, 'background': '#f8ffff' } }] as any)
//           .concat([{ field: 'price', headerName: 'Price', pinned: 'right', editable: true, minWidth: 100, cellStyle: { ...rightAlignStyle, 'background': '#f8ffff' } }] as any);

//         console.log('headers', headers)

//         this.setState({ ...this.state, data: updatedData, columns: headers, attributeColumns }, () => {
//           this.columnApi.autoSizeColumns(['category', 'quantity', 'cost', 'originalPrice', 'price']);
//         });
//       })
//   }

//   onGridReady = (params: any) => {
//     this.gridApi = params.api;
//     this.columnApi = params.columnApi;
//   }

//   onCellEdit = async (event) => {
//     const { value, data: { id, quantity, originalPrice, costPerUnit, pricePerUnit }, colDef: { field } } = event;

//     console.log('event', event)
//     let { data: { cost, price } } = event
//     if (field === 'costPerUnit') {
//       cost = quantity * costPerUnit
//     }

//     if (field === 'pricePerUnit') {
//       price = quantity * pricePerUnit
//     }

//     try {
//       await Feathers.service(`/products-legacy/price`)
//         .create({
//           id,
//           quantity,
//           price,
//           cost,
//           originalPrice,
//         })

//       await this.loadData()
//     } catch (e) {
//       console.log(e)
//     }
//   }

//   render() {
//     const containerStyle = {
//       height: 500,
//       // /width: 500
//     };
//     return (
//       <div>
//         <PageHeaderRow header='Price Editor' subheader='Product' />
//         <Segment>
//           <Message>
//             Set your filter to edit prices
//           </Message>

//           <Header attached={'top'} as='h5'>
//             1. Set a Filter
//           </Header>
//           <Segment attached secondary>
//             <ProductFilterForm
//               initialValues={this.state.filter}
//               enableReinitialize={true}
//               isFiltered={this.state.filter !== null}
//               handleClearFilter={this.handleFilterClear}
//               onSubmit={this.handleFilterSubmit}
//             />
//           </Segment>
//           {this.state.filter &&
//             <div>
//               <Header attached as='h5'>
//                 2. Edit Price Values
//               </Header>
//               <Segment attached>
//                 <Message info>
//                   <Message.Header>Editing Tip</Message.Header>
//                   <p>Click on a cell in the price column and start typing to edit.</p>
//                 </Message>
//                 <div style={containerStyle} className="ag-fresh">
//                   <AgGridReact
//                     columnDefs={this.state.columns}
//                     rowData={this.state.data}
//                     onGridReady={this.onGridReady}

//                     onCellEditingStopped={this.onCellEdit}
//                   />
//                 </div>
//               </Segment>
//             </div>
//           }
//         </Segment>
//       </div>
//     );
//   }
// }

import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import * as numeral from 'numeral';
import { PreLoader } from '@inkcloud/components';
import { Header, Segment, Message } from 'semantic-ui-react';

import Feathers from '../../../bootstrap/feathers';

import ProductFilterForm from '../ProductFilterForm';
import { filterMapper } from '../products/List';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

const loadData = async (filter: any) =>
  Feathers.service('/products-legacy/price-grid').find({
    query: filterMapper(filter),
  });

const { useState, useMemo, memo } = React;

const CheckboxProductRenderer = (props) => {
  const { data, colDef, value } = props;

  const handleChange = async (e) => {
    const payload = {
      ...(data.product || {}),
      [colDef.field]: e.target.checked,
    };
    try {
      const res: any = await Feathers.service('/products-legacy').patch(
        data?.product?._id,
        payload,
        {}
      );
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      <input type="checkbox" checked={value} onChange={handleChange} />
    </div>
  );
};

const initDefCol = [{ field: 'category', headerName: 'Category' }];

const CustomHeaderEditable = memo((props: any) => (
  <span className="ag-react-container ag-header-cell-text">
    <i aria-hidden="true" className="edit icon"></i> {props.displayName}
  </span>
));

const App = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState(null);

  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState(initDefCol);

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      width: 130,
    }),
    []
  );

  const colDef = [
    { field: 'name', headerName: 'Name' },
    {
      field: 'shouldExcludeFromHandlingFees',
      headerName: 'Exclude From Handling Fees',
      editable: true,
      headerComponentFramework: CustomHeaderEditable,
      cellRendererFramework: CheckboxProductRenderer,
    },
    {
      field: 'rfqNum',
      headerName: 'RFQ Number',
      editable: true,
      headerComponentFramework: CustomHeaderEditable,
      cellStyle: { background: '' },
      type: 'rightAligned',
    },
    {
      field: 'weightUnit',
      headerName: 'Weight Unit',
      editable: true,
      headerComponentFramework: CustomHeaderEditable,
      cellStyle: { background: '' },
      type: 'rightAligned',
    },
    {
      field: 'weightBasis',
      headerName: 'Weight Basis',
      editable: true,
      headerComponentFramework: CustomHeaderEditable,
      cellStyle: { background: '' },
      type: 'rightAligned',
    },
    {
      field: 'unitOfMeasure',
      headerName: 'Unit Of Measure',
      editable: true,
      headerComponentFramework: CustomHeaderEditable,
      cellStyle: { background: '' },
    },
    {
      field: 'unitOfMeasureConversion',
      headerName: 'Unit Of Measure Conversion',
      editable: true,
      headerComponentFramework: CustomHeaderEditable,
      cellStyle: { background: '' },
      type: 'rightAligned',
    },
    {
      field: 'inventoryLowThreshold',
      headerName: 'Inventory Low Threshold',
      editable: true,
      headerComponentFramework: CustomHeaderEditable,
      minWidth: 75,
      cellStyle: { background: '#f8ffff' },
    },
    {
      field: 'tags',
      headerName: 'Tags',
      editable: true,
      headerComponentFramework: CustomHeaderEditable,
    },
    {
      field: 'costPerUnit',
      headerName: 'Cost Per Unit',
      pinned: 'right',
      editable: true,
      headerComponentFramework: CustomHeaderEditable,
      cellStyle: { background: '#f8ffff' },
      type: 'rightAligned',
    },
    {
      field: 'cost',
      headerName: 'Cost',
      pinned: 'right',
      editable: true,
      headerComponentFramework: CustomHeaderEditable,
      cellStyle: { background: '#f8ffff' },
      type: 'rightAligned',
    },
    {
      field: 'originalPrice',
      headerName: 'Original Price',
      pinned: 'right',
      editable: true,
      headerComponentFramework: CustomHeaderEditable,
      cellStyle: { background: '#f8ffff' },
      type: 'rightAligned',
    },
    {
      field: 'quantity',
      headerName: 'Quantity',
      cellStyle: { background: '#f8ffff' },
      pinned: 'right',
    },
    {
      field: 'pricePerUnit',
      headerName: 'Price Per Unit',
      pinned: 'right',
      editable: true,
      headerComponentFramework: CustomHeaderEditable,
      cellStyle: { background: '#f8ffff' },
      type: 'rightAligned',
    },
    {
      field: 'price',
      headerName: 'Price',
      pinned: 'right',
      editable: true,
      headerComponentFramework: CustomHeaderEditable,
      cellStyle: { background: '#f8ffff' },
      type: 'rightAligned',
    },
  ];

  const handleFilterSubmit = async (values) => {
    setIsLoading(true);
    setColumnDefs(initDefCol);
    setFilter(values);
    try {
      const res: any = await loadData(values);

      const attributeColumns = Object.keys(res.headers)?.map((k) => ({
        field: res.headers[k].id,
        headerName: res.headers[k].name,
      }));

      const mappedData = res?.data?.map((d) => ({
        ...d,
        inventoryLowThreshold: d?.product?.inventoryLowThreshold,
        rfqNum: d?.product?.rfqNum,
        shouldExcludeFromHandlingFees: d?.product?.shouldExcludeFromHandlingFees,
        weightUnit: d?.product?.weightUnit,
        weightBasis: d?.product?.weightBasis,
        unitOfMeasure: d?.product?.unitOfMeasure,
        unitOfMeasureConversion: d?.product?.unitOfMeasureConversion,
        tags: d?.product?.tags?.join(', '),
        costPerUnit: (d?.cost ?? 0) / d?.quantity,
        pricePerUnit: (d?.price ?? 0) / d?.quantity,
        price: numeral(d?.price).format('$0,0.000'),
      }));

      setColumnDefs([...initDefCol, ...(attributeColumns || []), ...colDef]);
      setRowData([...attributeColumns, ...mappedData]);
    } catch (e) {
      console.log('e', e);
    }

    setIsLoading(false);
  };

  const handleCellEditing = async (event: any) => {
    const {
      oldValue,
      value,
      data: { id, quantity, originalPrice, costPerUnit, pricePerUnit, product },
      colDef: { field },
    } = event;

    const productFieldList = [
      'inventoryLowThreshold',
      'rfqNum',
      'weightUnit',
      'weightBasis',
      'unitOfMeasure',
      'unitOfMeasureConversion',
      'tags',
    ];

    const isInProduct = productFieldList.find((f) => f === field);

    if (isInProduct) {
      let payloadValue = value;

      if (field === 'tags') {
        payloadValue = value?.split(', ').map((tag) => tag.trim());
      }

      const payload = {
        ...(product || {}),
        [field]: payloadValue,
      };

      try {
        const res: any = await Feathers.service('/products-legacy').patch(
          product?._id,
          payload,
          {}
        );
      } catch (e) {
        console.log(e);
      }
    } else {
      let {
        data: { cost, price },
      } = event;

      if (field === 'costPerUnit') {
        cost = quantity * costPerUnit;
      }

      if (field === 'pricePerUnit') {
        price = quantity * pricePerUnit;
      }

      price = Number(price?.replace(/\s|\$|,/g, '') ?? oldValue?.replace(/\s|\$|,/g, ''));

      try {
        await Feathers.service(`/products-legacy/price`).create({
          id,
          quantity,
          price,
          cost,
          originalPrice,
        });

        handleFilterSubmit(filter);
      } catch (e) {
        console.log(e);
      }
    }
  };

  return (
    <>
      <Header attached="top" as="h5">
        1. Set a Filter
      </Header>
      <Segment attached="bottom">
        <ProductFilterForm
          initialValues={filter}
          enableReinitialize={true}
          isFiltered={filter !== null}
          handleClearFilter={() => setFilter(null)}
          onSubmit={handleFilterSubmit}
        />
      </Segment>
      {filter && (
        <>
          {isLoading ? (
            <PreLoader />
          ) : (
            <>
              {' '}
              <Header attached="top" as="h5">
                2. Edit Price Values
              </Header>
              <Segment attached="bottom">
                <Message info>
                  <Message.Header>Editing Tip</Message.Header>
                  <p>Click on a cell in the price column and start typing to edit.</p>
                </Message>
                <div className="ag-theme-alpine" style={{ height: 400 }}>
                  <AgGridReact
                    rowData={rowData}
                    defaultColDef={defaultColDef}
                    columnDefs={columnDefs}
                    onCellEditingStopped={handleCellEditing}
                  />
                </div>
              </Segment>
            </>
          )}
        </>
      )}
    </>
  );
};

export default App;
