import React, { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import {
  Box,
  Button,
  Input,
  Textarea,
  Alert,
  Checkbox,
  Radio,
  RadioGroup,
  Stack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import type { ModelTypes } from '@inkcloud/icapi-types';
import * as yup from 'yup';

import { Select } from 'chakra-react-select';
import { GenericFilter } from '@inkcloud/components';

import { FormControl } from '../../../common/v3/Form/FormControl';
import { Feathers } from '../../../bootstrap/feathers/feathers';
import FilterDefinition from './FilterDefinition';

const yupSchema = yup.object().shape({
  name: yup.string().required('Required'),
  columns: yup.array().min(1, 'Required'),
});

interface IJobManagementFormProps {
  initialValues: ModelTypes.JobSchedulerViews;
  isSubmitting: boolean;
  errorMessage: string;
  onSubmit: (data: any) => void;
}

export default function JobSchedulingColumnForm(props: IJobManagementFormProps) {
  const { initialValues, isSubmitting, errorMessage, onSubmit } = props;

  const [templates, setTemplates] = useState<any[]>([]);
  const [columns, setColumns] = useState<any[]>([]);

  const useFormReturn = useForm({
    mode: 'onBlur',
    defaultValues: initialValues,
    resolver: yupResolver(yupSchema),
  });

  useEffect(() => {
    const loadWorkflowTemplates = async () => {
      try {
        const res = await Feathers.service('job-scheduler/workflow-templates').find({
          query: {},
        });

        setTemplates(res?.data?.map((t) => ({ ...t, value: t._id, label: t.name })));
      } catch (e) {
        console.error(e);
      }
    };

    const loadColumns = async () => {
      try {
        const res = await Feathers.service('job-scheduler/columns').find({
          query: {
            isGlobal: {
              $ne: true,
            },
          },
        });

        setColumns(res?.data?.map((t) => ({ value: t._id, label: t.name })));
      } catch (e) {
        console.error(e);
      }
    };

    loadColumns();
    loadWorkflowTemplates();
  }, []);

  const {
    handleSubmit,

    register,
    setValue,
    watch,
  } = useFormReturn;

  const watchColumns = watch('columns');
  const watchFilter = watch('filter');

  // console.log({ watchFilter, watchColumns });

  return (
    <>
      <Box bgColor={'white'} p={8}>
        <FormProvider {...useFormReturn}>
          <Box width={360}>
            <React.Fragment key="ic-job">
              <FormControl
                field="name"
                label="Name"
                required
                render={(fieldProps) => <Input {...fieldProps} />}
              />
              <FormControl
                field="columns"
                label="Columns"
                required
                render={(fieldProps) => (
                  <Select
                    useBasicStyles
                    isClearable
                    isMulti
                    placeholder=""
                    {...fieldProps}
                    onChange={(e: any) => setValue('columns', e)}
                    // value={()}
                    options={columns}
                  />
                )}
              />
              {/* <FormControl
                field="filter"
                label="Job Criteria"
                render={(fieldProps) => (
                  <Select
                    useBasicStyles
                    isClearable
                    placeholder=""
                    {...fieldProps}
                    onChange={(e: any) => setValue('workflowTemplate', e.value)}
                    value={templates?.find((t) => t._id === watchWorkflow)}
                    options={templates}
                  />
                )}
              /> */}
            </React.Fragment>
          </Box>
          <Box mb={3}>
            <GenericFilter
              factDefinitionFunction={FilterDefinition}
              value={watchFilter}
              onChange={(v) => setValue('filter', v)}
            />
          </Box>
        </FormProvider>

        {errorMessage && (
          <Alert mb={3} status="error">
            {errorMessage}
          </Alert>
        )}

        <Button
          isDisabled={isSubmitting}
          isLoading={isSubmitting}
          colorScheme="blue"
          onClick={handleSubmit((data) => {
            // console.log('data', data);

            onSubmit(data);
          })}
        >
          Submit
        </Button>
      </Box>
    </>
  );
}
