import * as React from 'react';
import { Modal, Form, Input, Table, Button, Message } from 'semantic-ui-react';
import feathers from './../../bootstrap/feathers';
import { humanize } from 'underscore.string';

import { Formik, Form as FormikForm, Field, FieldArray, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { SemanticField } from './../../common/Form';

interface OrderItemModalProps {
  isOpen: boolean;
  onClose: () => void;
  onReload: () => void;
  item: any;
}

const { useState, useEffect } = React;

export const OrderitemModal: React.FunctionComponent<OrderItemModalProps> = (props) => {
  const { isOpen, onClose, item, onReload } = props;

  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [fields, setFields] = useState([]);

  useEffect(() => {
    const loadDesignTemplate = async () => {
      try {
        const res: any = await feathers
          .service('/design-templates')
          .get(item?.designTemplate, { query: {} });

        setFields(res?.fields);
      } catch (e) {}
    };

    loadDesignTemplate();
  }, []);

  item.designTemplateFields = item?.designTemplateFields?.map((d) => ({
    ...d,
    type: fields?.find((f) => f._id === d._id)?.type,
  }));

  const handleUpdate = async (values: any) => {
    setIsSubmitting(true);
    try {
      const res: any = await feathers
        .service('/order-items')
        .patch(values._id, values, { query: {} });

      const rerender = await feathers.service('/design-templates/send-to-renderer').create({
        orderItemId: values._id,
      });

      if (rerender.result) {
        onClose();
        onReload();
      } else {
        setErrorMessage(rerender.message || rerender.errorMessage);
      }
    } catch (e) {
      e.code && e.code < 500
        ? setErrorMessage(e.message)
        : setErrorMessage('We are experiencing technical difficulties. Please try again');
    }

    setIsSubmitting(false);
  };

  return (
    <Modal open={isOpen} onClose={onClose} closeIcon>
      <Modal.Header>
        Order Item - {item?.product?.staticName && humanize(item.product.staticName)}
      </Modal.Header>
      <Modal.Content>
        <Formik
          initialValues={item}
          enableReinitialize
          onSubmit={(values, { setSubmitting }) => {
            handleUpdate(values);
          }}
        >
          {(innerProps) => {
            const {
              values,
              touched,
              errors,
              dirty,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset,
              setFieldValue,
              setFieldTouched,
            } = innerProps;

            return (
              <FormikForm
                className={`ui form ${isSubmitting && !errorMessage && 'loading'} ${
                  errorMessage ? 'error' : ''
                }`}
              >
                <FieldArray
                  name="designTemplateFields"
                  render={(arrayHelpers) => (
                    <div className="ui form">
                      {values?.designTemplateFields?.map((design: any, idx: number) => (
                        <Form.Group widths="equal" key={idx}>
                          <Form.Field inline>
                            <label>{design.label}</label>
                            {design?.type === 'textarea' ? (
                              <>
                                <SemanticField
                                  name={`designTemplateFields.${idx}.value`}
                                  fluid
                                  component={Form.TextArea}
                                />
                              </>
                            ) : (
                              <SemanticField
                                name={`designTemplateFields.${idx}.value`}
                                fluid
                                component={Form.Input}
                              />
                            )}
                          </Form.Field>
                        </Form.Group>
                      ))}
                    </div>
                  )}
                />
                {errorMessage && <Message error content={errorMessage} />}
                <Button primary type="submit" disabled={isSubmitting}>
                  Save
                </Button>
              </FormikForm>
            );
          }}
        </Formik>
      </Modal.Content>
    </Modal>
  );
};
