import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import {
  Badge,
  Box,
  Button,
  Flex,
  Heading,
  Stack,
  Text,
  Spacer,
  RadioGroup,
  Radio,
  Textarea,
  Divider,
  HStack,
  Tag,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Grid,
  GridItem,
} from '@chakra-ui/react';

import { PreLoader, useFeathers } from '@inkcloud/components';
import type { ModelTypes } from '@inkcloud/icapi-types';

import { FormattedDate, FormattedTime } from 'react-intl';

import feathers, { icapi } from '../../bootstrap/feathers';

import { SegmentCard } from '../../common';

import Attachments from './RFQ2/Manage/Attachments';
import BillToShipTo from './RFQ2/Manage/BillToShipTo';

import ItemResponse from './RFQ2/ItemResponse';

import type { AttributeType, ResponseItemsType } from './common/types';

import type { AttachmentType } from './Detail';

const serviceName = `/rfqs`;
const pathPrefix = `/rfqs`;
const service = feathers.service(serviceName);
const commonParams = {
  query: {
    $populate: [
      'customer',
      'micrositeUser',
      'items.vendor',
      'privateNotes.createdBy',
      // 'items.components.attributes.type',
      // 'items.components.attributes.value',
      'attachments.uploadLog',
    ],
  },
};

function noQuantity(msg: string) {
  this.message = msg;
  this.code = 406;
}

const currentDate = new Date();

function dateCompare(dueDate: string) {
  return currentDate > new Date(dueDate) ? 'red' : 'yellow';
}

type Response = Exclude<ModelTypes.Rfq['responses'], undefined>[number];

const responses = ['no-bid', 'responded', 'responded-with-changes'];

export function Detail(props: RouteComponentProps<{ id: string }>) {
  const {
    match: {
      params: { id },
    },
    history,
  } = props;

  const isEdit = !!id;
  const [state, reload] = useFeathers('get', serviceName, [id, commonParams], true);

  const [resultData, setResultData] = React.useState<Response>({});

  const [attributes, setAttributes] = useState<AttributeType[]>([]);

  const [response, setResponse] = React.useState('responded');
  const [changeMessage, setChangeMessage] = React.useState('');
  const [isSubmitting, setIsSubmitting] = React.useState({
    draft: false,
    response: false,
  });
  const [errorMessage, setErrorMesssage] = React.useState('');

  useEffect(() => {
    const loadAttributes = async () => {
      try {
        const res = await feathers.service('products-legacy/attributes').find({
          query: {
            $np: 1,
            $sort: { name: 1 },
            $populate: ['entityAttribute'],
          },
        });

        setAttributes(
          res.map((r) => ({
            _id: r._id,
            name: r.name,
            typeId: r.entityAttribute._id,
            typeName: r.entityAttribute.name,
          }))
        );
      } catch (e) {
        console.error(e);
      }
    };

    loadAttributes();
  }, []);

  React.useEffect(() => {
    const dataResponse = state.data?.responses[0];
    if (dataResponse) {
      setResultData(dataResponse);

      const isPending = dataResponse.status === 'pending';
      const isRespondedWithChanges = dataResponse?.changeMessage
        ? 'responded-with-changes'
        : 'responded';

      const defaultResponse = responses.find((r) => r === dataResponse.status) || 'responded';
      setResponse(isPending ? isRespondedWithChanges : defaultResponse);
      setChangeMessage(dataResponse.changeMessage);
    }
  }, [state]);

  // console.log('state', state.data);

  if (isEdit && !state.data) {
    return <PreLoader />;
  }

  if (!resultData) {
    return null;
  }

  let isReadOnly = false;
  if (state?.data?.responses) {
    isReadOnly =
      state.data.responses[0]?.status !== 'pending' &&
      state.data.responses[0]?.status !== 'acknowledged';
  }

  const status = state.data.responses[0]?.status;

  const handleAcknowledgement = async () => {
    const res = await feathers.service('rfqs/responses').patch(id, {
      status: 'acknowledged',
    });

    reload();
  };

  const areAllItemsHasQty = resultData?.items
    ?.reduce((acc, cur) => {
      if (!cur?.isOptedOut) {
        const quantities = cur?.quantities?.filter((qty) => !qty?.isOptedOut);
        const hasQty = quantities?.every?.((qty) => (Number(qty?.price) ?? 0) > 0);
        // console.log('hasQty', hasQty)
        acc.push(!!hasQty);
      }

      return acc;
    }, [] as Boolean[])
    ?.every((v) => v);

  const handleSubmit = async (isDraft: boolean = false) => {
    let $saveDraft;

    setIsSubmitting({
      draft: isDraft,
      response: !isDraft,
    });

    setErrorMesssage('');

    if (isDraft) {
      $saveDraft = 1;
    }

    try {
      if (!areAllItemsHasQty && !isDraft && response !== 'no-bid') {
        // eslint-disable-next-line new-cap
        throw new noQuantity('Please input prices for each item');
      }

      const res = await feathers.service('rfqs/responses').patch(id, {
        ...resultData,
        status: isDraft ? 'pending' : response,
        changeMessage,
        $saveDraft,
      });

      history.push(pathPrefix);
    } catch (e) {
      const errMsg =
        e?.code < 500
          ? e?.message
          : `We are having trouble saving your ${
              isDraft ? 'draft' : 'response'
            }. Please try again later.`;
      setErrorMesssage(errMsg);
    }

    setIsSubmitting({
      draft: false,
      response: false,
    });
  };

  const initialData: ModelTypes.Rfq = { ...state.data };

  // console.log('initialData', initialData)

  const allItemsAreOptedOut = (resultData?.items as ResponseItemsType)?.every((i) => i?.isOptedOut);

  const isDisabledSubmit = allItemsAreOptedOut;

  const vendor: NonNullable<ModelTypes.Rfq['vendors']>[number] = state.data.vendors[0];

  const shipTo = {
    city: initialData?.shipTo?.city,
    stateProvince: initialData?.shipTo?.stateProvince,
    postalCode: initialData?.shipTo?.postalCode,
  };

  return (
    <>
      <Flex mt={8} mb={16} alignItems={'center'}>
        <Box>
          <Heading fontWeight={'normal'} size="xl">
            RFQ #{initialData?.humanId}
          </Heading>
        </Box>
        <Badge
          ml={4}
          colorScheme={initialData?.status === 'open' ? 'green' : 'orange'}
          fontSize={'large'}
        >
          {status}
        </Badge>

        <Spacer />

        {!isReadOnly && response !== 'no-bid' && (
          <>
            <Button
              colorScheme="blue"
              isDisabled={isReadOnly || isSubmitting.draft || isSubmitting.response}
              isLoading={isSubmitting.draft}
              mr={2}
              onClick={() => handleSubmit(true)}
            >
              Save Draft
            </Button>
            <Button
              colorScheme="green"
              isDisabled={
                isReadOnly ||
                isSubmitting.response ||
                isSubmitting.draft ||
                isDisabledSubmit ||
                !areAllItemsHasQty
              }
              isLoading={isSubmitting.response}
              onClick={() => handleSubmit()}
            >
              Submit Response
            </Button>
          </>
        )}
      </Flex>

      {errorMessage && (
        <Alert mb={3} status="error">
          {errorMessage}
        </Alert>
      )}

      {allItemsAreOptedOut && (
        <Alert mb={3} status="warning">
          <AlertIcon />
          <Box>
            <AlertDescription>
              You cannot opt-out of every item. Instead Decline to bid
            </AlertDescription>
          </Box>
        </Alert>
      )}

      <Box>
        {status === 'pending' && (
          <Alert status="warning" mb={4}>
            <AlertIcon />
            <Box>
              <AlertTitle>RFQ Acknowledgement</AlertTitle>
              <AlertDescription>
                Please acknoledge this RFQ by clicking the button below. You will be able to respond
                to the RFQ later if you choose to do so.
              </AlertDescription>
              <br />

              <Button colorScheme={'green'} mt={4} onClick={() => handleAcknowledgement()}>
                Acknowledge RFQ
              </Button>
            </Box>
          </Alert>
        )}

        <Box mb={8}>
          <Grid templateColumns={{ lg: 'repeat(2, 1fr)' }} gap={8}>
            <GridItem>
              <SegmentCard title="RFQ Info">
                <Text fontSize={'medium'}>
                  You have been requested to bid on this RFQ. Please review the RFQ and respond with
                  your best offer no later than{' '}
                  <Tag
                    colorScheme={
                      status === 'pending'
                        ? dateCompare(initialData?.estimateDueDate ?? '')
                        : 'green'
                    }
                  >
                    <FormattedDate value={initialData?.estimateDueDate} />
                  </Tag>
                  .
                </Text>

                <Divider />
                <br />
                <Text fontSize={'medium'}>How would you like to proceed?</Text>
                <br />
                <RadioGroup
                  onChange={(e) => {
                    setResponse(e);
                    setErrorMesssage('');
                    setChangeMessage('');
                  }}
                  value={response}
                  isDisabled={isReadOnly}
                >
                  <Stack direction="row">
                    <Radio value="responded">Respond with quote </Radio>
                    <Radio value="responded-with-changes">Respond with changes</Radio>
                    <Radio value="no-bid">Decline bid</Radio>
                  </Stack>
                </RadioGroup>

                {response === 'responded-with-changes' && (
                  <>
                    <br />
                    <Text>Describe any changes to specs and proceed to fill out costs below</Text>
                    <Textarea
                      isDisabled={isReadOnly}
                      placeholder="Describe changes to specs..."
                      value={changeMessage}
                      onChange={(e) => setChangeMessage(e.target.value)}
                    />
                    <br />
                  </>
                )}
                {response === 'no-bid' && (
                  <>
                    <br />
                    <Text>Optionally provide a reason for not bidding </Text>
                    <Textarea
                      isDisabled={isReadOnly}
                      placeholder="No-bid reason..."
                      value={changeMessage}
                      onChange={(e) => setChangeMessage(e.target.value)}
                    />
                    <br />
                  </>
                )}
                {response === 'no-bid' && (
                  <>
                    <Button
                      colorScheme="green"
                      mt={4}
                      mb={4}
                      mr={2}
                      onClick={() => handleSubmit()}
                      isDisabled={isReadOnly || isSubmitting.response || !areAllItemsHasQty}
                      isLoading={isSubmitting.response}
                    >
                      Submit Response
                    </Button>
                    {errorMessage && (
                      <Alert my={3} status="error">
                        {errorMessage}
                      </Alert>
                    )}
                  </>
                )}
              </SegmentCard>
            </GridItem>
            <GridItem>
              <Attachments
                title="Attachments"
                subTitle="Attach Files"
                actionButtonLabel=""
                attachments={initialData?.attachments as AttachmentType}
              />
            </GridItem>
          </Grid>
        </Box>

        {response !== 'no-bid' && (
          <>
            {/* <Grid templateColumns={{ lg: 'repeat(2, 1fr)' }} gap={8} mb={8}>
              <GridItem>
                <BillToShipTo
                  data={{ shipTo: shipTo as ModelTypes.Rfq['shipTo'] }}
                  type="shipTo"
                  title="Ship To"
                  subTitle="Ship To Address"
                  actionButtonLabel=""
                />
              </GridItem>
              <GridItem>
                <Attachments
                  title="Attachments"
                  subTitle="Attach Files"
                  actionButtonLabel=""
                  attachments={initialData?.attachments as AttachmentType}
                />
              </GridItem>
            </Grid> */}
            <SegmentCard title="RFQ Details">
              <Text fontSize={'medium'}>
                Below are the details of the RFQ on the left. Enter in your prices on the right
              </Text>

              <Box p={4} bgColor={'gray.50'} my={8}>
                <Text fontSize="medium">
                  Please quote for the following services:
                  <HStack display="inline-flex" spacing={1}>
                    {vendor?.services?.map((s) => (
                      <Tag colorScheme={'blue'} key={s}>
                        {s}
                      </Tag>
                    ))}
                  </HStack>
                </Text>
              </Box>

              <Flex mb={8}>
                <Text as="strong" mr={3}>
                  Ship To:
                </Text>
                <BillToShipTo
                  data={{ shipTo: shipTo as ModelTypes.Rfq['shipTo'] }}
                  type="shipTo"
                  title="Ship To"
                  subTitle="Ship To Address"
                  actionButtonLabel=""
                />
              </Flex>

              <Divider />

              <Box my={8}>
                {initialData?.items?.map((item, index) => (
                  <ItemResponse
                    index={index}
                    attributes={attributes}
                    responseItems={resultData.items as ResponseItemsType}
                    item={item}
                    key={index}
                    isReadOnly={isReadOnly}
                    isMenuHidden={true}
                    onChange={(data) => setResultData({ ...resultData, items: data })}
                  />
                ))}
              </Box>
            </SegmentCard>
          </>
        )}

        {allItemsAreOptedOut && (
          <Alert my={3} status="warning">
            <AlertIcon />
            <Box>
              <AlertDescription>
                You cannot opt-out of every item. Instead Decline to bid
              </AlertDescription>
            </Box>
          </Alert>
        )}

        {!isReadOnly && response !== 'no-bid' && (
          <>
            <Box alignContent={'flex-end'} my={3} textAlign={'right'}>
              <Button
                colorScheme="blue"
                mr={2}
                onClick={() => handleSubmit(true)}
                isDisabled={isReadOnly || isSubmitting.draft || isSubmitting.response}
                isLoading={isSubmitting.draft}
              >
                Save Draft
              </Button>
              <Button
                colorScheme="green"
                onClick={() => handleSubmit()}
                isDisabled={
                  isReadOnly ||
                  isSubmitting.response ||
                  isSubmitting.draft ||
                  isDisabledSubmit ||
                  !areAllItemsHasQty
                }
                isLoading={isSubmitting.response}
              >
                Submit Response
              </Button>
            </Box>
            {errorMessage && (
              <Alert mb={3} status="error">
                {errorMessage}
              </Alert>
            )}
          </>
        )}
      </Box>
    </>
  );
}
