import * as React from 'react';
declare var ENV;

interface ISlackInstallButtonProps {}

export const SlackInstallButton: React.FunctionComponent<ISlackInstallButtonProps> = (props) => {
  let host = '';
  if (ENV === 'development') {
    host = 'http://localhost:8032';
  } else if (ENV === 'staging') {
    host = 'https://api-stg.inkcloud9.com';
  } else if (ENV === 'production') {
    host = 'https://api.inkcloud9.com';
  }

  const slackRedirectUri = `${host}/slack/install`;

  const state = localStorage.getItem('feathers-jwt');

  return (
    <a
      href={`https://slack.com/oauth/v2/authorize?scope=incoming-webhook,commands,chat:write,users:read&client_id=3815021531.706323635638&redirect_uri=${slackRedirectUri}&state=${state}`}
    >
      <img
        alt="Add to Slack"
        height="40"
        width="139"
        src="https://platform.slack-edge.com/img/add_to_slack.png"
        srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
      />
    </a>
  );
};
