import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { Box, Text, Flex, Button, Alert, Spinner } from '@chakra-ui/react';
import type { ModelTypes } from '@inkcloud/icapi-types';
import AceEditor from 'react-ace';
import 'brace/mode/json';
import 'brace/theme/monokai';

import { Feathers } from '../../../../bootstrap/feathers/feathers';

export default function Detail(props) {
  const { id } = useParams<{ id: string }>();

  const location = useLocation();

  const search = new URLSearchParams(location.search);
  const path = search.get('path');

  // console.log({ path, id });

  const [content, setContent] = useState('');

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (id) {
      const loadFile = async () => {
        setIsLoading(true);
        try {
          const res = await Feathers.service('store-front/theme-templates').get(
            `${path ? `${path?.replace('/', '')}/` : ''}${id}.html`
          );
          setContent(res?.content ?? '');
        } catch (e) {
          console.error(e);
        }
        setIsLoading(false);
      };
      loadFile();
    }
  }, [id]);

  const handleSave = async () => {
    setIsSubmitting(true);
    setErrorMessage('');
    setResponseMessage('');
    try {
      const template = `${path ? `${path.replace('/', '')}/${id}` : id}.html`;
      const res = await Feathers.service('store-front/theme-templates').patch(template, {
        content,
      });

      setResponseMessage('Changes have been saved!');
      setTimeout(() => setResponseMessage(''), 1500);
    } catch (e) {
      const errMsg = e?.code < 500 ? e?.message : 'Something went wrong. Please try again later.';
      setErrorMessage(errMsg);
    }
    setIsSubmitting(false);
  };

  return (
    <Box pl={3} minH="100vh">
      {errorMessage && (
        <Alert mb={3} status="error">
          {errorMessage}
        </Alert>
      )}
      {responseMessage && (
        <Alert mb={3} status="success">
          {responseMessage}
        </Alert>
      )}
      <Flex justifyContent="space-between" mb={3}>
        <Text fontWeight="bold">{id}</Text>
        <Button
          isDisabled={isSubmitting}
          isLoading={isSubmitting}
          colorScheme="blue"
          onClick={handleSave}
          size="md"
        >
          Save
        </Button>
      </Flex>
      <Box pos="relative">
        {(isLoading || isSubmitting) && (
          <Box
            pos="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            zIndex={5}
            display="flex"
            alignItems="center"
            justifyContent="center"
            backgroundColor="whiteAlpha.700"
          >
            <Spinner />
          </Box>
        )}
        <AceEditor
          mode="html"
          theme="monokai"
          onChange={(data) => setContent(data)}
          name="data-editor"
          editorProps={{ $blockScrolling: true }}
          value={content}
          width={'100%'}
          height={'100vh'}
          showGutter
        />
      </Box>
      {errorMessage && (
        <Alert mt={3} status="error">
          {errorMessage}
        </Alert>
      )}
      {responseMessage && (
        <Alert mt={3} status="success">
          {responseMessage}
        </Alert>
      )}
      <Box mt={3} textAlign="right">
        <Button
          isDisabled={isSubmitting}
          isLoading={isSubmitting}
          colorScheme="blue"
          onClick={handleSave}
          size="md"
        >
          Save
        </Button>
      </Box>
    </Box>
  );
}
