import * as React from 'react';
import { Switch, Route } from 'react-router-dom';

import { List } from './List';
import { Detail } from './Detail';

const routes = (props) => (
  <Switch>
    <Route exact path="/sales-orders" component={List} />
    <Route path="/sales-orders/add" component={Detail} />
    <Route path="/sales-orders/:id" component={Detail} />
  </Switch>
);

export default routes;
