import * as React from 'react';

import {
  Box,
  Heading,
  Flex,
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
  Button,
  Table as ChakraTable,
  TableContainer,
  Tag,
  HStack,
  VStack,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  Divider,
  Alert,
  AlertIcon,
  Spinner,
} from '@chakra-ui/react';

export interface StatCardProps {
  label: string;
  number: number | string;
  helpText?: string;
}

export const StatCard: React.FunctionComponent<StatCardProps> = (props) => {
  const { label, number, helpText = '' } = props;

  return (
    <Stat>
      <StatLabel>{label}</StatLabel>
      <StatNumber>{number}</StatNumber>
      {helpText && <StatHelpText>{helpText}</StatHelpText>}
    </Stat>
  );
};
