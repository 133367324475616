import * as React from 'react';
import { Switch, Route } from 'react-router-dom';

import List from './List';
import { Detail } from './Detail';

const routes = (props) => (
  <Switch>
    <Route exact path="/settings/content-management/banners" component={List} />
    <Route exact path="/settings/content-management/banners/add" component={Detail} />
    <Route exact path="/settings/content-management/banners/:id" component={Detail} />
  </Switch>
);

export default routes;
