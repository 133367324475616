import type { ModelTypes } from '@inkcloud/icapi-types'
import { Components as InvoiceDocumentComponents } from './InvoiceDocument'
import { Components as PackingSlipDocumentComponents } from './PackingSlip'
import { Components as PurchaseOrderComponents } from './PurchaseOrder'
import { Components as OrderItemLabelDocumentComponents } from './OrderItemLabel'
import { Components as EstimateDocumentComponents } from './Estimate'
import { Components as SalesOrderDocumentComponents } from './SalesOrder'

export * from './shared/BillTo'
export * from './shared/ShipTo'
export * from './shared/Table'
export * from './shared/DefinitionTable'
export * from './shared'

type PrintableDocument = ModelTypes.PrintableDocument
type DocumentTypes = PrintableDocument['documentType']

interface LoadComponentsOptions {
  documentType: NonNullable<PrintableDocument['documentType']>
}

type ComponentMap = {
  [key in NonNullable<DocumentTypes>]: any
}

const componentMap: ComponentMap = {
  invoice: InvoiceDocumentComponents,
  'purchase-order': PurchaseOrderComponents,
  estimate: EstimateDocumentComponents,
  // TODO: implement the rest
  'job-ticket': {},
  'packing-slip': PackingSlipDocumentComponents,
  'pick-list': {},
  'sales-order': SalesOrderDocumentComponents,
  'order-item-label': OrderItemLabelDocumentComponents,
}

export function loadComponents(options: LoadComponentsOptions) {
  const { documentType } = options
  if (!componentMap[documentType] && documentType !== undefined) {
    throw Error(`${documentType} is not a valid document type. Unable to load components`)
  }

  return componentMap[documentType]
}
