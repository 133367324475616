import * as React from 'react';
import { Table, Button, Modal, Message, Popup, Segment } from 'semantic-ui-react';
import Feathers from '../../../bootstrap/feathers';
import { EmptyWrapper } from '@inkcloud/components';
import { ShipToForm } from './ShipToAddressForm';

interface LocalDeliveryListProps {
  id: string;
  isOpen: boolean;
  setIsOpen: (v: boolean) => void;
  onClose: (v?: boolean) => void;
}

const { useState, useEffect } = React;

export const LocalDeliveryList: React.FunctionComponent<LocalDeliveryListProps> = (props) => {
  const { id, isOpen, setIsOpen, onClose } = props;

  const [addresses, setAddresses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const loadAddresses = async () => {
      try {
        setIsLoading(true);
        const res: any = await Feathers.service('/customers/local-delivery-addresses/').get(id, {
          query: {},
        });
        if (res) {
          setAddresses(res);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    };
    loadAddresses();
  }, [isSubmitting]);

  const handleSubmit = async (values: any) => {
    const payload = {
      customer: id,
      localDeliveryAddress: {
        ...values,
      },
    };

    try {
      setErrorMessage('');
      setIsSubmitting(true);
      const res: any = await Feathers.service('/customers/local-delivery-addresses').create(
        payload,
        { query: {} }
      );
      setIsSubmitting(false);
      onClose();
    } catch (e) {
      setIsSubmitting(false);
      e.code && e.code < 500
        ? setErrorMessage(e.message)
        : setErrorMessage('We are experiencing technical difficulties. Please try again');
    }
  };

  const handleChangeStatus = async (value: string) => {
    try {
      setErrorMessage('');
      setIsSubmitting(true);
      const res: any = await Feathers.service(`/customers/local-delivery-addresses/`).patch(
        id,
        { status: value },
        {
          query: {
            localDeliveryAddressId: selectedAddress,
          },
        }
      );
      setIsSubmitting(false);
      setSelectedAddress(null);
      onClose();
    } catch (e) {
      setIsSubmitting(false);
      e.code && e.code < 500
        ? setErrorMessage(e.message)
        : setErrorMessage('We are experiencing technical difficulties. Please try again');
    }
  };

  const handleDelete = async (address_id: string) => {
    try {
      setErrorMessage('');
      setIsSubmitting(true);
      const res: any = await Feathers.service(`/customers/local-delivery-addresses/`).remove(id, {
        query: {
          localDeliveryAddressId: address_id,
        },
      });
      setIsSubmitting(false);
      onClose();
    } catch (e) {
      setIsSubmitting(false);
      e.code && e.code < 500
        ? setErrorMessage(e.message)
        : setErrorMessage('We are experiencing technical difficulties. Please try again');
    }
  };

  return (
    <>
      <EmptyWrapper
        attached="bottom"
        icon="map marker alternate"
        queryResult={!isLoading && addresses && addresses.length > 0}
        isStateLoading={isLoading}
      >
        <Segment attached="bottom">
          {errorMessage && <Message error content={errorMessage} />}

          <Table size="small">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Address</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {addresses?.map((a: any, index: number) => (
                <Table.Row key={index}>
                  <Table.Cell>{a.name}</Table.Cell>
                  <Table.Cell>
                    {a.address1} {a.address2} {a.city} {a.stateProvince}, {a.postalCode}
                  </Table.Cell>
                  <Table.Cell>
                    {a.status === 'pending-approval'
                      ? 'Pending Review'
                      : a.status === 'approved'
                      ? 'Approved'
                      : 'Not Approved'}
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    <Popup
                      size="mini"
                      content="Delete"
                      trigger={
                        <Button size={'mini'} icon={'trash'} onClick={() => handleDelete(a._id)} />
                      }
                    />

                    <Popup
                      size="mini"
                      content="Edit"
                      trigger={
                        <Button
                          size={'mini'}
                          icon={'edit'}
                          onClick={() => {
                            setErrorMessage('');
                            setIsOpen(true);
                            setSelectedAddress(a._id);
                          }}
                        />
                      }
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Segment>
      </EmptyWrapper>

      {isOpen && (
        <Modal
          size={selectedAddress ? 'mini' : 'large'}
          open={isOpen}
          onClose={() => {
            setErrorMessage('');
            onClose();
            setSelectedAddress(null);
          }}
          closeIcon
        >
          <Modal.Header> {selectedAddress ? 'Update Status' : 'New Delivery Address'}</Modal.Header>

          <Modal.Content>
            {errorMessage && <Message error content={errorMessage} />}

            {selectedAddress ? (
              <>
                <Button
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  content="Reject"
                  onClick={() => handleChangeStatus('rejected')}
                />
                <Button
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  primary
                  content="Approve"
                  onClick={() => handleChangeStatus('approved')}
                />
              </>
            ) : (
              <ShipToForm initialValues={{}} handleSubmit={handleSubmit} isLoading={isSubmitting} />
            )}
          </Modal.Content>
        </Modal>
      )}
    </>
  );
};
