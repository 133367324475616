import * as React from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button } from '@chakra-ui/react';
import CSVUploadPreview, { ColumnFields, ReturnData } from '../../../common/CSVUploadPreview/CSVUploadPreview';

// export { ReturnData } from '../../../common/CSVUploadPreview/CSVUploadPreview';

interface IImportFromCSVModalProps {
  onClose: () => void;
  isOpen: boolean;
  onSubmit: (data: ReturnData) => void;
}

const fields: ColumnFields[] = [
  // { key: 'name', label: 'Name', isRequired: true },
  // { key: 'description', label: 'Description', isRequired: true },
  { key: 'productId', label: 'Product ID', isRequired: true, alias: 'Product ID' },
  { key: 'sku', label: 'sku', isRequired: true, alias: 'Product ID' },
  { key: 'overridePrice', label: 'Override Price', isRequired: true , alias: 'Override Price'},
];

const ImportFromCSVModal: React.FunctionComponent<IImportFromCSVModalProps> = (props) => {
  const { onClose, isOpen, onSubmit } = props;
  const handleOnSubmit = (data: any) => {
    console.log('got this data', data);
    onSubmit(data)
  };
  return (
    <Modal size={'4xl'} isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>Import from CSV</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <CSVUploadPreview
          fields={fields}
          onSubmit={handleOnSubmit}
          maxPreviewRows={5}
          maxParse={1000}
        />
      </ModalBody>

      <ModalFooter>
        <Button colorScheme='blue' mr={3} onClick={onClose}>
          Close
        </Button>
        {/* <Button variant='ghost'>Secondary Action</Button> */}
      </ModalFooter>
    </ModalContent>
  </Modal>
  );
};

export default ImportFromCSVModal;
