import * as React from 'react';
import {
  Label,
  Button,
  Card,
  Header,
  Segment,
  Image,
  Grid,
  Icon,
  Progress,
  Modal,
  Message,
} from 'semantic-ui-react';
import { chunk } from 'lodash-es';
import Feathers from './../bootstrap/feathers';
import Dropzone from 'react-dropzone';
import * as superagent from 'superagent';
declare var APPENV;

// const thumbBaseUrl = 'http://dal05.objectstorage.softlayer.net/v1/AUTH_bdf10df7-94a2-42e6-9b4f-0c3020705c07/ic-artwork-thumbs/reg/';
// const thumbBaseUrl = 'https://ic-thumbs-inkcloud.netdna-ssl.com/ic-artwork-thumbs/reg/';
const thumbBaseUrl = 'https://storage.googleapis.com/artwork-thumbs/reg/';

const uploadUrl = '/artwork-uploads/upload';

export interface ArtworkFile extends File {
  sequence: string;
  orderItemId: string;
  isComplete: boolean;
  percentComplete: number;
  id: string;
}

const dropzoneStyle = {
  // width: 200,
  minHeight: 45,
  borderWidth: 1,
  borderColor: '#ddd',
  borderStyle: 'dashed',
  borderRadius: 1,
  width: '100%',
  marginBottom: 10,
};

export interface ExternalProps {}

export interface ArtworkModalState {
  isModalOpen: boolean;
  orderItem: any;
  isPreparingDownload: boolean;
  isDownloadLinkReady: boolean;
  downloadLink: string;
  isUploading: boolean;
  isDownloadingArtwork: boolean;
  artworkSequence: string;
  errorMessage: {
    artwork: string;
  };
  uploadFiles: ArtworkFile[];
}

export interface InjectedProps {
  artworkHandleOpen: any;
  artworkHandleClose: any;
  artworkHandleDownloadAll: any;
  artworkHandleDownloadSingle: any;
  artworkHandleReset: any;
}

export const artworkModalWrapper = function artworkModalWrapper<OriginalProps>(Component) {
  return class ArtworkModal extends React.Component<
    ExternalProps & OriginalProps,
    ArtworkModalState
  > {
    constructor(props: OriginalProps) {
      super(props);
      this.state = {
        isModalOpen: false,
        orderItem: null,
        isPreparingDownload: false,
        isDownloadLinkReady: false,
        downloadLink: null,
        isUploading: false,
        isDownloadingArtwork: false,
        artworkSequence: '',
        errorMessage: {
          artwork: '',
        },
        uploadFiles: [],
      };
    }

    handleOpen = (orderItem) => {
      this.setState({
        ...this.state,
        isModalOpen: true,
        isPreparingDownload: false,
        isDownloadLinkReady: false,
        downloadLink: null,
        isUploading: false,
        uploadFiles: [],
        orderItem,
      });
    };

    handleClose = (e) => {
      this.setState({ ...this.state, isModalOpen: false, orderItem: null });
    };

    handleDownloadAll = (e) => {
      this.setState({
        ...this.state,
        isPreparingDownload: true,
        isDownloadLinkReady: false,
      });

      Feathers.service('artwork-uploads/archive-url')
        .create({
          orderItemId: this.state.orderItem._id,
        })
        .then(({ url }) => {
          this.setState({
            ...this.state,
            isPreparingDownload: false,
            isDownloadLinkReady: true,
            downloadLink: url,
          });
        });
    };

    handleDownloadSingle = (e, data) => {};

    handleReset = (e, data) => {
      Feathers.service('artwork-uploads/reset')
        .create({
          orderItemId: this.state.orderItem._id,
        })
        .then((orderItem) => {
          this.setState({ ...this.state, orderItem });
        });
    };

    handleResetSingle = (e, data) => {
      Feathers.service('artwork-uploads/reset')
        .create({
          orderItemId: this.state.orderItem._id,
          sequences: [data.sequence],
        })
        .then((orderItem) => {
          this.setState({ ...this.state, orderItem });
        });
    };

    onDrop = (orderItemId: string, sequence: string) => (acceptedFiles: File[], rejectedFiles) => {
      if (acceptedFiles.length === 0) {
        return;
      }

      const file = acceptedFiles[0];
      const updatedUploadSlots = this.state.orderItem?.uploadSlots?.map((s, index) => {
        if (s.sequence !== sequence) {
          return s;
        }

        return {
          ...s,
          file: {
            ...file,
            isComplete: false,
            orderItemId,
            sequence,
            percentComplete: 0,
            id: `${file.name}${index}`,
            data: file,
          },
        };
      });

      const updatedOrderItem = {
        ...this.state.orderItem,
        uploadSlots: updatedUploadSlots,
      };

      this.setState({
        ...this.state,
        isUploading: true,
        orderItem: updatedOrderItem,
        // uploadFiles: this.state.uploadFiles.concat(filesToUpload)
      });

      const filesToUpload = updatedOrderItem.uploadSlots
        ?.filter((s) => s.file && !s.file.isComplete)
        ?.map((s) => s.file);

      Promise.all(filesToUpload.map((f) => this.uploadFile(f))).then((res: any[]) => {
        const afterUploadSlots = updatedOrderItem.uploadSlots?.map((s) => {
          const foundResult = res.find((r) => {
            return r.sequence === s.sequence;
          });
          if (!foundResult) {
            return s;
          }
          const updatedSlot = {
            ...s,
            isUploaded: true,
            originalFilename: foundResult.originalFilename,
          };
          updatedSlot.file.isComplete = true;
          updatedSlot.file.percentComplete = 100;
          return updatedSlot;
        });

        const latestOrderItem = { ...updatedOrderItem };
        latestOrderItem.uploadSlots = afterUploadSlots;

        this.setState({
          ...this.state,
          orderItem: latestOrderItem,
        });
      });
    };

    handleFileUpload = (sequence) => {
      return (files) => {};
    };

    uploadFile = (file) => {
      const request = superagent
        .post(`${APPENV.api.connection}${uploadUrl}`)
        .timeout(1000 * 60 * 10)
        .set('Authorization', localStorage.getItem('feathers-jwt'))
        // .field('tenantId', file.tenantId)
        .field('orderItemId', file.orderItemId)
        .field(`sequence[${file.sequence}]`, file.data)
        .on('progress', (event) => this.onUploadProgress(file.id, event.percent, event))
        .on('end', (event) => {
          this.onEnd(file.id, event);
        });

      return request.then((result) => {
        this.setState({ isUploading: false });
        // this.props.onComplete();
        if (result.body && result.body[0]) {
          return result.body[0];
        }
        return result.body;
      });
    };

    onUploadProgress = (id, percent = 0, e) => {
      const orderItem = { ...this.state.orderItem };
      orderItem?.uploadSlots?.map((s) => {
        if (!s.file || s.file.id !== id) {
          return s;
        }

        const updatedSlot = { ...s };
        updatedSlot.file.percentComplete = percent.toFixed(2);
        return updatedSlot;
      });

      this.setState({
        ...this.state,
        orderItem,
      });
    };

    onEnd = (id, event) => {
      this.setState({
        ...this.state,
        uploadFiles: this.state.uploadFiles?.map((f) => {
          if (f.id !== id) {
            return f;
          }

          return {
            ...f,
            isComplete: true,
          };
        }),
      });
    };

    handleDownloadArtwork = async (orderItemId: string, sequence: string) => {
      this.setState({
        ...this.state,
        isDownloadingArtwork: true,
        artworkSequence: sequence,
        errorMessage: {
          artwork: '',
        },
      });
      try {
        const res: any = await Feathers.service('/artwork-uploads/download-artwork-v2').find({
          query: {
            orderItemId,
            sequence,
          },
        });

        if (res) {
          window.open(res.url, '_blank');
        }
      } catch (e) {
        this.setState({
          ...this.state,
          errorMessage: {
            artwork:
              e.code && e.code < 500
                ? e.message
                : 'We are experiencing technical difficulties. Please try again',
          },
        });
      }

      this.setState({
        ...this.state,
        isDownloadingArtwork: false,
      });
    };

    render() {
      const { orderItem, errorMessage, artworkSequence, isDownloadingArtwork } = this.state;

      const hasAllUploadedFiles =
        orderItem?.uploadSlots?.length > 0 &&
        orderItem?.uploadSlots?.every((slot) => slot?.isUploaded);
      const hasUploadedFiles = orderItem?.uploadSlots?.some((slot) => slot?.isUploaded);

      const artworkOrder = ['FRONT', 'BACK'];

      const sortedArtwork = orderItem?.uploadSlots
        ?.map((slot, idx) => ({ idx, ...slot }))
        ?.sort(({ sequence: sequence1, idx: idx1 }, { sequence: sequence2, idx: idx2 }) => {
          return artworkOrder.indexOf(sequence1) - artworkOrder.indexOf(sequence2) || idx1 - idx2;
        });

      const artworkImages2 = sortedArtwork?.map((s) => (
        <Grid.Column key={s.sequence}>
          <Segment size={'tiny'} attached="top">
            {s.isUploaded && (
              <Button.Group floated={'right'} size={'tiny'}>
                <Button
                  type="button"
                  loading={artworkSequence === s.sequence && isDownloadingArtwork}
                  content="Download"
                  icon="download"
                  onClick={() => this.handleDownloadArtwork(orderItem._id, s.sequence)}
                />
                <Button
                  negative
                  icon={'remove circle'}
                  sequence={s.sequence}
                  onClick={this.handleResetSingle}
                  content={'Reset'}
                />
              </Button.Group>
            )}
            <strong>{s.sequence}</strong>
            {s.isUploaded && <div>{s.originalFilename}</div>}
            {artworkSequence === s.sequence && errorMessage?.artwork && (
              <Message error size="tiny" content={errorMessage?.artwork} />
            )}
          </Segment>
          <Segment attached>
            {s.isUploaded && (!s.file || !s.file.isComplete) && (
              <Image fluid bordered src={`${thumbBaseUrl}${s.upload}.jpg`} />
            )}
            {s.isUploaded && s.file && s.file.isComplete && (
              <Segment>Upload complete. Thumbnail is being generated</Segment>
            )}

            {!s.isUploaded && s.file && !s.file.isComplete && (
              <Segment>
                <Progress progress active percent={s.file.percentComplete} />
              </Segment>
            )}

            {!s.isUploaded && (!s.file || !s.file.isComplete) && (
              <div style={dropzoneStyle}>
                {
                  <Dropzone
                    key={`dz`}
                    multiple={false}
                    onDrop={this.onDrop(orderItem._id, s.sequence)}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps()}>
                        <Segment loading={false} basic textAlign={'center'}>
                          {s.sequence}
                          <Icon size={'large'} name="upload" />
                          <br />
                          <input {...getInputProps()} />
                          <div style={{ fontSize: 12 }}>Drag and drop or click to upload</div>
                        </Segment>
                      </div>
                    )}
                  </Dropzone>
                }
              </div>
            )}
          </Segment>
        </Grid.Column>
      ));

      return (
        <div>
          <Component
            artworkHandleOpen={this.handleOpen}
            artworkHandleClose={this.handleClose}
            artworkHandleDownloadAll={this.handleDownloadAll}
            artworkHandleDownloadSingle={this.handleDownloadSingle}
            artworkHandleReset={this.handleReset}
            {...this.props}
          />
          <Modal open={this.state.isModalOpen} onClose={this.handleClose} closeIcon>
            <Modal.Header>
              {this.state.orderItem && this.state.orderItem.humanId} Artwork Management
              <Button.Group size={'mini'} floated={'right'}>
                {!this.state.isDownloadLinkReady && hasAllUploadedFiles && (
                  <Button
                    onClick={this.handleDownloadAll}
                    icon={'download'}
                    content={
                      this.state.isPreparingDownload ? 'Preparing Download...' : 'Download All'
                    }
                    loading={this.state.isPreparingDownload}
                  />
                )}

                {this.state.isDownloadLinkReady && (
                  <a href={this.state.downloadLink} className="ui green button">
                    Download Ready - Download
                  </a>
                )}

                {hasUploadedFiles && (
                  <Button
                    negative
                    icon={'remove'}
                    content={'Delete All'}
                    onClick={this.handleReset}
                  />
                )}
              </Button.Group>
            </Modal.Header>
            <Modal.Content scrolling>
              {this.state.isDownloadLinkReady && <a href={this.state.downloadLink}>Download Zip</a>}

              <Grid stretched columns={2}>
                <Grid.Row>{artworkImages2}</Grid.Row>
              </Grid>
            </Modal.Content>
          </Modal>
        </div>
      );
    }
  };
};
