import JSZip from 'jszip';
import { saveAs } from 'file-saver';

const download = async (url: string) => fetch(url).then((resp) => resp.blob());

const downloadMany = (urls: Array<string>) => Promise.all(urls.map((url) => download(url)));

const exportZip = (blobs, zipName) => {
  const zip = new JSZip();

  blobs.forEach((blob, idx: number) => {
    const fileType = blob.type.split('/')[1];
    zip.file(`file-${idx}.${fileType}`, blob);
  });

  zip.generateAsync({ type: 'blob' }).then((zipFile) => saveAs(zipFile, zipName));
};

export const DownloadZip = (urls: Array<string>, zipName: string = 'download') =>
  downloadMany(urls).then((res) => exportZip(res, zipName));
