import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Box, Button } from '@chakra-ui/react';
import { PageHeaderRow, PreLoader, useFeathers } from '@inkcloud/components';
import feathers from '../../../bootstrap/feathers';
import PriceListsForm from './PriceListsForm';

interface DetailProps {
  products: { value: string; text: string }[];
  categories: { value: string; text: string }[];
}

const pathPrefix = `/settings/price-lists`;
const serviceName = '/products-legacy/price-lists';
const service = feathers.service(serviceName);
const commonParams = {
  query: {
    $populate: ['productOverrides.product'],
  },
};

export default function Detail(props: DetailProps & RouteComponentProps<{ id?: string }>) {
  const {
    match: {
      params: { id },
    },
    history,
  } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const isEdit = !!id;
  const [state, reload] = useFeathers('get', serviceName, [id, commonParams], true);

  const handleSubmit = async (values) => {
    setIsSubmitting(true);
    try {
      setErrorMessage('');
      if (isEdit) {
        await service.patch(id, values, commonParams);
      } else {
        await service.create(values, commonParams);
      }
      history.push(pathPrefix);
    } catch (e) {
      const errMsg =
        e?.code < 500 ? e?.message : 'We are experiencing technical difficulties. Please try again';
      setErrorMessage(errMsg);
    }

    setIsSubmitting(false);
  };

  const pageTitle = isEdit ? 'Edit' : 'New';

  if (isEdit && !state.data) {
    return <PreLoader />;
  }

  const initialData = { ...state.data };
  return (
    <div>
      <PageHeaderRow header={`${pageTitle} Price Lists`} subheader="Settings" subheaderId={''}>
        <Button size="sm" onClick={() => history.goBack()}>
          Back
        </Button>
      </PageHeaderRow>

      <Box p={3} mb={3} borderRadius="md" borderWidth="1px" bg="white">
        {(!isEdit || state.data) && (
          <PriceListsForm
            initialValues={initialData}
            errorMessage={errorMessage}
            onSubmit={handleSubmit}
            isSubmitting={isSubmitting}
          />
        )}
      </Box>
    </div>
  );
}
