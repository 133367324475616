import * as React from 'react';
import {
  Button,
  Card,
  Checkbox,
  Grid,
  Header,
  Form,
  Icon,
  Label,
  Message,
  Radio,
  Segment,
  Table,
  TextArea,
} from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { RouteComponentProps } from 'react-router-dom';
import { DetailWrapper, DetailFeaturesProps } from './../../../bootstrap/DetailWrapper';
import { services } from './../../../bootstrap/feathers';
import feathers from './../../../bootstrap/feathers';

import * as moment from 'moment';

type DetailProps = DetailFeaturesProps & {
  translators: any[];
  findTranslators: any;
  saveAssign: any;
};

// export interface DetailProps {
// }

export interface AppState {
  selectedTranslator: string;
  notes: string;
  updatedTurnaroundTime: number;
  dueDate: any;
  errorMessage: '';
}

class Detail extends React.Component<DetailProps, AppState> {
  constructor(props: DetailProps) {
    super(props);
    this.state = {
      selectedTranslator: null,
      notes: null,
      updatedTurnaroundTime: null,
      dueDate: null,
      errorMessage: '',
    };
  }

  componentDidMount() {
    if (this.props.data) {
      this.loadTranslators(this.props.data);
    }
  }

  componentWillReceiveProps(next: DetailFeaturesProps) {
    if (this.props.data === null && next.data) {
      this.loadTranslators(next.data);
    }
  }

  loadTranslators(orderItem) {
    const sourceLang = orderItem.metadata.find((a) => a.key == 'tr_source_name');
    const destinationLang = orderItem.metadata.find((a) => a.key == 'tr_dest_name');
    this.props.findTranslators({
      query: {
        // 'languages.source': { $in: [sourceLang._id] },
        // 'languages.destinations': {
        //   $elemMatch: {
        //     $in: [destinationLang._id]
        //   },
        // },
        $populate: ['languages.source', 'languages.destinations'],
        $np: 1,
      },
    });

    this.setState({ ...this.state, updatedTurnaroundTime: orderItem.timeMax });
  }

  handleTextAreaChange = (e, data) => {
    this.setState({ ...this.state, notes: data.value });
  };

  handleRadioCick = (e, data) => {
    this.setState({ ...this.state, selectedTranslator: data.value });
  };

  handleUpdatedTurnaroundTime = (e, data) => {
    this.setState({ ...this.state, updatedTurnaroundTime: data.value });
  };

  handleSubmitClick = (e, data) => {
    if (this.state.selectedTranslator === null) {
      // TODO: Display an error
      return;
    }

    this.props
      .saveAssign({
        orderItemId: this.props.data._id,
        translatorId: this.state.selectedTranslator,
        notes: this.state.notes,
        updatedTurnaroundTime: this.state.updatedTurnaroundTime,
        dueDate: this.state.dueDate,
      })
      .then(() => {
        this.props.history.push('/translation/assign');
      })
      .catch((e) =>
        this.setState({
          ...this.state,
          errorMessage:
            e.code && e.code < 500
              ? e.message
              : 'We are experiencing technical difficulties. Please try again',
        })
      );
  };

  renderInfoTable() {
    if (!this.props.data) {
      return null;
    }

    const { data } = this.props;
    return (
      <Segment size={'small'}>
        <Grid columns={'2'} divided>
          <Grid.Row>
            <Grid.Column>
              <Table basic="very" celled size={'small'}>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>ID</Table.Cell>
                    <Table.Cell>{data.humanId}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Customer</Table.Cell>
                    <Table.Cell>
                      {data.customer?.organizationName ??
                        `${data.customer?.primaryContact?.firstName ?? ''} ${
                          data.customer?.primaryContact?.lastName ?? ''
                        }`}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Turnaround</Table.Cell>
                    <Table.Cell>{data.timeMax}</Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Grid.Column>
            <Grid.Column>
              <Table basic="very" celled size={'small'}>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>Category</Table.Cell>
                    <Table.Cell>{data?.category?.name ?? 'Custom'}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Source Language</Table.Cell>
                    <Table.Cell>
                      {data?.metadata
                        ?.filter((a) => a.key == 'tr_source_name')
                        .reduce((prev, next) => `${prev} ${next.value}`, '') ?? ''}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Destination Language</Table.Cell>
                    <Table.Cell>
                      {data?.metadata
                        ?.filter((a) => a.key == 'tr_dest_name')
                        .reduce((prev, next) => `${prev} ${next.value}`, '') ?? ''}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>{data?.miscUploads?.length > 1 ? 'Files' : 'File'}</Table.Cell>
                    <Table.Cell>
                      {data.miscUploads?.map((m: any, index: number) => (
                        <a
                          href={`https://storage.googleapis.com/${m.cloudBucket}/${m.cloudFilename}`}
                          style={{ display: 'inline-block', marginRight: '.5rem' }}
                          key={index}
                          target="_blank"
                          // onClick={() => handleFile(`${m.cloudBucket}/${m.cloudFilename}`)}
                        >
                          <Icon name="file alternate outline" /> {m.originalFilename}
                        </a>
                      ))}
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }

  renderTranslatorsList() {
    return (
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>Languages</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {this.props.translators.map((i: any, index: number) => (
            <Table.Row key={index}>
              <Table.Cell>
                <Radio
                  value={i._id}
                  checked={this.state.selectedTranslator === i._id}
                  onChange={this.handleRadioCick}
                />
              </Table.Cell>
              <Table.Cell>{`${i.firstName} ${i.lastName}`}</Table.Cell>
              <Table.Cell>{i.email}</Table.Cell>
              <Table.Cell>
                {i.languages?.map((l) => (
                  <div>
                    <Label size={'mini'} key={l.source._id}>
                      {l.source.name}
                    </Label>
                    <Icon name={'long arrow alternate right'} />
                    {l.destinations.map((d) => (
                      <Label size={'mini'} key={d._id}>
                        {d.name}
                      </Label>
                    ))}
                  </div>
                )) ?? ''}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
  }

  render() {
    const { data } = this.props;
    return (
      <div>
        <Header as={'h1'}>
          Assign Translator
          <Header.Subheader></Header.Subheader>
        </Header>
        <Segment>
          {this.renderInfoTable()}

          <Segment>
            {this.props.translators &&
              this.props.translators.length > 0 &&
              this.renderTranslatorsList()}

            {this.props.translators && this.props.translators.length === 0 && (
              <Message>
                <Message.Header>Translators Not Found</Message.Header>
                <p>You don't have any translators that match the job</p>
              </Message>
            )}

            {this.props.translators && this.props.translators.length > 0 && (
              <Form>
                <Form.TextArea
                  onChange={this.handleTextAreaChange}
                  style={{ width: '100%' }}
                  autoHeight={true}
                  placeholder={'Optional notes...'}
                />

                {/* <label><strong>Due Date</strong></label> */}
                <DatePicker
                  dateFormat="MM/dd/yyyy h:mm a"
                  selected={this.state.dueDate}
                  selectsStart
                  showTimeSelect
                  timeCaption="time"
                  timeFormat="h:mm a"
                  placeholderText="Due Date"
                  popperClassName={'zTop'}
                  onChange={(date) => this.setState({ ...this.state, dueDate: date })}
                />

                <br />
                <br />

                <Button
                  type="button"
                  onClick={this.handleSubmitClick}
                  primary
                  content={'Assign Translator'}
                />
              </Form>
            )}
            {this.state.errorMessage && <Message error content={this.state.errorMessage} />}
          </Segment>
        </Segment>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  translators: state.translators.queryResult,
});

const mapDispatchToProps = {
  findTranslators: services.translators.find,
  saveAssign: services.translatorsAssignOrderItem.create,
};
export default DetailWrapper(Detail, 'orderItems', {
  submitRedirect: '/translation/assign',
  mapStateToProps,
  mapDispatchToProps,
  query: {
    $populate: ['customer', 'category', 'attributes.value', 'miscUploads'],
  },
});
