import * as React from 'react';
import { Button, Card, Grid, Header, Message, Segment, Table } from 'semantic-ui-react';

export interface BatchInfoTableProps {
  productionBatch: any;
}

export function BatchInfoTable(props: BatchInfoTableProps) {
  if (!props.productionBatch) {
    return null;
  }
  const {
    humanId,
    paper,
    resource,
    orientationType,
    batchType,
    finalSheetCount,
    alottedWasteCount,
    sheetWidth,
    sheetHeight,
  } = props.productionBatch;
  return (
    <Segment size={'small'}>
      <Grid columns={'2'} divided>
        <Grid.Row>
          <Grid.Column>
            <Table basic="very" celled size={'small'}>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>ID</Table.Cell>
                  <Table.Cell>{humanId}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Orientation</Table.Cell>
                  <Table.Cell>{orientationType}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Sheet Size</Table.Cell>
                  <Table.Cell>{sheetWidth && `${sheetWidth} x ${sheetHeight}`}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Paper</Table.Cell>
                  <Table.Cell>{paper && paper.name}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Resource</Table.Cell>
                  <Table.Cell>{resource && resource.name}</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Grid.Column>
          <Grid.Column>
            <Table basic="very" celled size={'small'}>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>Batch Type</Table.Cell>
                  <Table.Cell>{batchType}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Final Sheet Count</Table.Cell>
                  <Table.Cell>{finalSheetCount}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Paper</Table.Cell>
                  <Table.Cell>{paper && paper.name}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Alotted Waste Count</Table.Cell>
                  <Table.Cell>{alottedWasteCount}</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
}
