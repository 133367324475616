import * as React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Popup } from 'semantic-ui-react';
import { FormattedDate, FormattedTime } from 'react-intl';
import { useList, ListPage, basicQueryMap } from '@inkcloud/components';
import Feathers from './../../bootstrap/feathers';

import { OrderApprovalModal } from './../../components/order-approval/OrderApprovalModal';
import { getFilterDefinition } from './../orders/FilterForm2';

const { useState } = React;

interface ListProps {}

const checkValidValues = (filterRow, valueToCheck) => {
  const values =
    filterRow &&
    Object?.values(filterRow)
      ?.concat.apply([], filterRow)
      ?.filter((f) => f !== valueToCheck)
      ?.reduce(
        (m, field) => ({ ...m, [field]: values[field] ? values[field] : undefined }),
        filterRow
      );

  return values;
};

const filterQueryMap = (values) => {
  let filterRows = basicQueryMap(values, {
    name: 'staticName',
  });

  // Map attributes as needed by API
  const prefix = 'attributes:';
  const attributes = Object.keys(filterRows)
    .filter((r) => r.startsWith(prefix))
    .reduce((acc, cur) => ({ ...acc, [cur.slice(prefix.length)]: filterRows[cur]?.$in }), {});

  // Delete the original attribute valuess so they are not submitted
  Object.keys(filterRows)
    .filter((r) => r.startsWith(prefix))
    .forEach((r) => delete filterRows[r]);

  filterRows = {
    ...filterRows,
    microsite: checkValidValues(filterRows.microsite, 'all'),
    addOns: filterRows.addOns ? { $elemMatch: { addOnId: filterRows.addOns } } : undefined,
    attributes: attributes,
    type: undefined,
  };

  return filterRows;
};

const serviceName = 'orders';
const pathPrefix = `/order-rejected`;

export const List: React.FunctionComponent<ListProps & RouteComponentProps<any>> = (props) => {
  const {
    match: { params },
    location,
    history,
  } = props;
  // const { state, reload, handleDelete, selectedItems, clearAll, setChecked, someSelected, toggleAll, filter, setFilter, sort, handleSort } = useList({
  //   feathers: ['find', serviceName, [{
  //     query: {
  //       approvalStatus: 'rejected', $populate: ['category', 'customer', 'manager'], $populateOrderItems: 1, $orderItemRevenue: 1
  //     }
  //   }]],
  //   filterMap: undefined,
  //   debounce: 150,
  //   location,
  //   history,
  //   sort: { createdAt: -1 }
  // });

  const useListValues = useList({
    feathers: [
      'find',
      serviceName,
      [
        {
          query: {
            approvalStatus: 'rejected',
            $populate: ['category', 'customer', 'manager', 'invoice', 'microsite', 'micrositeUser'],
            $populateOrderItems: 1,
            $orderItemRevenue: 1,
          },
        },
      ],
    ],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [id, setId] = useState(null);

  const [orderValue, setOrderValue] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleOpenModal = (values: any) => {
    setId(values._id);
    setIsModalOpen(true);
    setOrderValue(values);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setId(null);
    setOrderValue(null);
  };

  const handleChangeStatus = async (status: string) => {
    setErrorMessage('');
    setIsSubmitting(true);
    try {
      const result = await Feathers.service('orders/approval').create(
        {
          orderId: id,
          approvalStatus: status,
        },
        { query: {} }
      );
      if (result) {
        handleCloseModal();
        useListValues.reload();
        setIsSubmitting(false);
      }
    } catch (e) {
      setIsSubmitting(false);
      e.code && e.code < 500
        ? setErrorMessage(e.message)
        : setErrorMessage('We are experiencing technical difficulties. Please try again');
    }
  };

  // const { queryResult, isLoading: isStateLoading } = state as any;

  // if (!queryResult) {
  //   return <PreLoader />
  // }

  const tableDefintion = [
    {
      key: 'createdAt',
      label: 'Date',
      sortable: true,
      cell: (v, r) => (
        <Popup
          size="mini"
          content={<FormattedTime value={r.createdAt} />}
          trigger={
            <div>
              <FormattedDate value={r.createdAt} />
            </div>
          }
        />
      ),
    },
    {
      key: '',
      label: 'ID',
      cell: (v, r) => <Link to={`/orders/${r?._id}`}>{r?.humanId}</Link>,
    },
    {
      key: '',
      label: 'Customer',
      cell: (v, r) => (
        <Link to={`/customers/${r.customer?._id}`}>
          {r.microsite?.name
            ? `${r.microsite?.name} / ${r?.micrositeUser?.firstName ?? ''} ${
                r.micrositeUser?.lastName ?? ''
              }`
            : r.customer?.primaryContact?.firstName ?? ''}{' '}
          {r.customer?.primaryContact?.lastName ?? ''}
        </Link>
      ),
    },
    {
      key: '',
      label: 'Manager',
      cell: (v, r) => (
        <>{r.manager?.isManager && `${r.manager?.firstName ?? ''} ${r.manager?.lastName ?? ''}`}</>
      ),
    },
    {
      key: '',
      label: '',
      cell: (v, r) => (
        <>
          <Popup
            size="mini"
            content="Open Modal"
            trigger={
              <Button
                size={'mini'}
                icon={'window restore outline'}
                onClick={() => handleOpenModal(r)}
              />
            }
          />
        </>
      ),
      textAlign: 'right',
    },
  ];

  return (
    <div>
      {/* 
      <PageHeaderRow header='Rejected' subheader='Order' />
      <EmptyWrapper queryResult={queryResult} isStateLoading={isStateLoading}>
        <Segment loading={state.isLoading}>
          <PaginatorWrapper.Top filter={filter} reload={reload} data={(queryResult as any)} pathPrefix={`${pathPrefix}/`} />
          <Table size={'small'} sortable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>ID</Table.HeaderCell>
                <Table.HeaderCell sorted={sort.createdAt ? sort.createdAt : null} onClick={() => handleSort('createdAt')}>
                  Created
                  </Table.HeaderCell>
                <Table.HeaderCell>Customer Name</Table.HeaderCell>
                <Table.HeaderCell>Manager</Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {queryResult && (queryResult as any).data.map(r => (
                <Table.Row key={r._id}>
                  <Table.Cell>
                    {r.humanId ? r.humanId : null}
                  </Table.Cell>
                  <Table.Cell>
                    <FormattedDate value={r.createdAt} />
                  </Table.Cell>
                  <Table.Cell>
                    {r.customer?.primaryContact?.firstName ?? ''} {r.customer?.primaryContact?.lastName ?? ''}
                  </Table.Cell>
                  <Table.Cell>
                    {r.manager?.isManager && `${r.manager?.firstName ?? ''} ${r.manager?.lastName ?? ''}`}
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    <Popup
                      size='mini'
                      content="Open Modal"
                      trigger={
                        <Button size={'mini'} icon={'window restore outline'} onClick={() => handleOpenModal(r)} />
                      }
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
          <PaginatorWrapper.Bottom reload={reload} data={(queryResult as any)} pathPrefix={`${pathPrefix}`}>
            {someSelected && <Button primary type="button" content="Delete" onClick={handleDelete} />}
          </PaginatorWrapper.Bottom>
        </Segment>

        

      </EmptyWrapper> */}

      <ListPage
        header="Rejected"
        subheader="Order"
        useListValues={useListValues}
        history={history}
        pathPrefix={pathPrefix}
        tableDefintion={tableDefintion}
        getFilterDefinition={getFilterDefinition}
        withCheckboxes={false}
      />

      {isModalOpen && (
        <OrderApprovalModal
          isModalOpen={isModalOpen}
          values={orderValue}
          onClose={handleCloseModal}
          errorMessage={errorMessage}
          onChangeStatus={handleChangeStatus}
          isSubmitting={isSubmitting}
        />
      )}
    </div>
  );
};
