import * as React from 'react';
// import { Button, Header, Form, Input, Image, Modal, ModalProps, Message } from 'semantic-ui-react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Center,
  Spinner,
  Alert,
} from '@chakra-ui/react';
import { QRCodeSVG } from 'qrcode.react';
import Feathers from '../../../../bootstrap/feathers';

declare const ENV;

const { useState, useEffect } = React;

export interface LoginAsModalProps {
  onClose: () => void;
  isOpen: boolean;
  microsite: any;
  micrositeCustomer: any;
}

const isSandbox = window?.location?.hostname?.endsWith('.app.inkcloud9.dev');

export const LoginAsModal: React.FunctionComponent<LoginAsModalProps> = (props) => {
  const { microsite, micrositeCustomer, isOpen, onClose } = props;

  const [url, setUrl] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const loadLogInAs = async () => {
      setIsLoading(true);
      try {
        const res = (await Feathers.service('/microsite/login-as-user').create({
          micrositeId: microsite._id,
          micrositeCustomerId: micrositeCustomer._id,
        })) as any;

        setUrl(res.url);
      } catch (e) {
        const errMsg =
          e?.code < 500
            ? e?.message
            : 'We are experiencing technical difficulties. Please try again';
        setErrorMessage(errMsg);
      }
      setIsLoading(false);
    };

    loadLogInAs();
  }, []);

  let link;
  if (ENV === 'development' || ENV === 'staging') {
    link = `http://${microsite.key}.localdev.inkcloud9.com:3000${url}`;
  } else if (isSandbox) {
    link = `https://${microsite.key}.site.inkcloud9.dev${url}`;
  } else {
    link = `https://${microsite.domain}${url}`;
  }

  // return (
  //   <Modal open={isOpen} onClose={onClose} closeIcon size="tiny">
  //     <Modal.Header>Microsite Customer Login As</Modal.Header>
  //     <Modal.Content>
  //       {errorMessage && <Message error content={errorMessage} />}
  //       <a rel="noopener noreferrer" target="_blank" href={link}>
  //         <Button
  //           content="Click to Log In As"
  //           primary
  //           disabled={isLoading}
  //           loading={isLoading}
  //         />
  //       </a>
  //     </Modal.Content>
  //   </Modal>
  // );
  return (
    <Modal isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Login As Customer</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Center>
            {isLoading ? (
              <Spinner />
            ) : (
              <></>
              // <QRCodeSVG
              //   value={link}
              //   fgColor="#000"
              //   imageSettings={{
              //     src: 'https://inkcloud9.com/static/ic-logo-clean-sm-dd7562a05ae8438f32f09ff676a0b9c5.png',
              //     excavate: true,
              //     width: 30,
              //     height: 18,
              //   }}
              // />
            )}
          </Center>
          {errorMessage && (
            <Alert my={4} status="error">
              {errorMessage}
            </Alert>
          )}
        </ModalBody>

        <ModalFooter>
          <Button onClick={onClose}>Close</Button>
          <Button
            isDisabled={isLoading}
            colorScheme="blue"
            ml={3}
            onClick={(e) => window.open(link, '_blank')}
          >
            Login
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
