// import * as React from 'react';
// import { Modal, Form, Input, Dropdown, Button, Message } from 'semantic-ui-react';
// import { Formik, Form as FormikForm, Field, ErrorMessage } from 'formik';
// import { SemanticField } from './../../../common/Form';
// import * as Cleave from 'cleave.js/react';
// import * as Yup from 'yup';

// import CreditCardForm from './../stored-cards/CreditCardForm';
// import { usStateProvinces } from './../../../common';

// interface CreditCardModalProps {
//   isSubmit: boolean;
//   open: boolean;
//   errorMessage?: string;
//   onClose: () => void;
//   onSubmit: (v: any) => void;
// }

// const expMonthOptions = [];

// for (let i = 1; i < 13; i++) {
//   expMonthOptions.push({
//     key: `${i}`,
//     text: `${i < 10 ? `0${i}` : i}`,
//     value: `${i < 10 ? `0${i}` : i}`,
//   });
// }

// const date = new Date();
// const nowYear = date.getFullYear();
// const max = nowYear + 12;
// const expYearsOptions = [];

// for (let i = nowYear; i < max; i++) {
//   expYearsOptions.push({ key: `${i}`, value: `${i - 2000}`, text: `${i}` });
// }

// export const CreditCardModal: React.FunctionComponent<CreditCardModalProps> = (props) => {
//   const { isSubmit, errorMessage, open, onClose, onSubmit } = props;

//   const CreditCardSchema = Yup.object().shape({
//     name: Yup.string().required('Field required'),
//     number: Yup.number().required('Field required'),
//     expMonth: Yup.string().required('Field required'),
//     expYear: Yup.string().required('Field required'),
//     cvv: Yup.string().required('Field required'),
//     address1: Yup.string().required('Field required'),
//     city: Yup.string().required('Field required'),
//     stateProvince: Yup.string().required('Field required'),
//     postalCode: Yup.string().required('Field required'),
//   });

//   const initialValues = {
//     name: '',
//     number: '',
//     expMonth: '',
//     expYear: '',
//     cvv: '',
//     address1: '',
//     city: '',
//     stateProvince: '',
//     postalCode: '',
//   };

//   return (
//     <>
//       <Modal size="tiny" open={open} onClose={onClose} closeIcon>
//         <Modal.Header>Add Credit Card</Modal.Header>
//         <Modal.Content>
//           {errorMessage && <Message error content={errorMessage} />}
//           <Formik
//             initialValues={initialValues}
//             validationSchema={CreditCardSchema}
//             onSubmit={(values) => onSubmit(values)}
//           >
//             {(props) => {
//               const {
//                 values,
//                 touched,
//                 errors,
//                 dirty,
//                 isSubmitting,
//                 handleChange,
//                 handleBlur,
//                 handleSubmit,
//                 handleReset,
//                 setFieldValue,
//                 setFieldTouched,
//               } = props;

//               return (
//                 <FormikForm className={`ui form ${isSubmit && 'loading'}`}>
//                   <Form.Field>
//                     <SemanticField component={Form.Input} fluid name={'name'} label="Card Name" />
//                     <ErrorMessage name="name" />
//                   </Form.Field>
//                   <Form.Field>
//                     <label>Card Number</label>
//                     <Field
//                       name="number"
//                       render={({ field }) => (
//                         <Cleave
//                           {...field}
//                           onChange={(e) => setFieldValue('number', e.target.rawValue)}
//                           options={{ creditCard: true }}
//                         />
//                       )}
//                     />
//                     <ErrorMessage name="number" />
//                   </Form.Field>
//                   <Form.Group widths="equal">
//                     <Form.Field>
//                       <SemanticField
//                         component={Form.Dropdown}
//                         fluid
//                         selection
//                         name={'expMonth'}
//                         options={expMonthOptions}
//                         label="Exp Month"
//                       />
//                       <ErrorMessage name="expMonth" />
//                     </Form.Field>
//                     <Form.Field>
//                       <SemanticField
//                         component={Form.Dropdown}
//                         fluid
//                         selection
//                         name={'expYear'}
//                         options={expYearsOptions}
//                         label="Exp Year"
//                       />
//                       <ErrorMessage name="expYear" />
//                     </Form.Field>
//                     <Form.Field>
//                       <SemanticField component={Form.Input} fluid name={'cvv'} label="CVV" />
//                       <ErrorMessage name="cvv" />
//                     </Form.Field>
//                   </Form.Group>
//                   <Form.Field>
//                     <SemanticField
//                       component={Form.Input}
//                       fluid
//                       name={'address1'}
//                       label="Address 1"
//                     />
//                     <ErrorMessage name="address1" />
//                   </Form.Field>
//                   <Form.Group widths="equal">
//                     <Form.Field>
//                       <SemanticField component={Form.Input} fluid name={'city'} label="City" />
//                       <ErrorMessage name="city" />
//                     </Form.Field>
//                     <Form.Field>
//                       <SemanticField
//                         component={Form.Dropdown}
//                         fluid
//                         selection
//                         name={'stateProvince'}
//                         options={usStateProvinces}
//                         label="State Province"
//                       />
//                       <ErrorMessage name="stateProvince" />
//                     </Form.Field>
//                     <Form.Field>
//                       <SemanticField
//                         component={Form.Input}
//                         fluid
//                         name={'postalCode'}
//                         label="Postal Code"
//                       />
//                       <ErrorMessage name="postalCode" />
//                     </Form.Field>
//                   </Form.Group>

//                   <Button primary type="submit" disabled={isSubmit} content="Add Card" />
//                 </FormikForm>
//               );
//             }}
//           </Formik>
//         </Modal.Content>
//       </Modal>
//     </>
//   );
// };

import React from 'react';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';

import {
  Alert,
  Box,
  Button,
  HStack,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
// import { Button, Form } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import countries from 'world-countries';
import { useSelector } from 'react-redux';
import { Select } from 'chakra-react-select';
import * as Cleave from 'cleave.js/react';
import { Elements } from '@stripe/react-stripe-js';

import { yupResolver } from '@hookform/resolvers/yup';
import type { ModelTypes } from '@inkcloud/icapi-types';
import * as yup from 'yup';

import { InputWrapper, countryStateProvinces } from '../../../common';
import { FormControl } from '../../../common/v3/Form/FormControl';
import StripeCheckoutForm from './StripeForm';

const countryInclude = ['US', 'CA', 'AU'];
const countryOptions = countries?.map((c) => ({ label: c.name?.official, value: c.cca2 }));

const stateProvinceOptions = (watchCountry: string) =>
  countryStateProvinces(watchCountry)?.map((c: { value: string; text: string }) => ({
    value: c.value,
    label: c.text,
  })) || [];
const expMonths: Record<string, string>[] = [];
for (let i = 1; i <= 12; i += 1) {
  expMonths.push({ value: `${i < 10 ? `0${i}` : i}`, label: `${i < 10 ? `0${i}` : i}` });
}

const date = new Date();
const nowYear = date.getFullYear();
const max = nowYear + 12;
const expYears: Record<string, string>[] = [];
for (let i = nowYear; i < max; i += 1) {
  expYears.push({ value: `${i - 2000}`, label: `${i}` });
}

interface CreditCardFormProps {
  isSubmitting: boolean;
  stripeClientSecret: string;
  stripePromise: any;
  errorMessage: string;
  onClose: () => void;
  onSubmit: (values: any) => void;
}

const schema = yup.object().shape({
  name: yup.string().required(),
  number: yup.string().required(),
  expYear: yup.string().required(),
  expMonth: yup.string().required(),
  cvv: yup.string().required(),
  countryCode: yup.string().required(),
  address1: yup.string().required(),
  city: yup.string().required(),
  stateProvince: yup.string().required(),
  postalCode: yup.string().required(),
});

export default function CreditCardModal(props: CreditCardFormProps) {
  const { stripeClientSecret, stripePromise, isSubmitting, errorMessage, onClose, onSubmit } =
    props;

  // const methods = useForm({
  //   resolver: yupResolver(schema),
  // });

  // const { register, handleSubmit, setValue, watch } = methods;

  // const tenant = useSelector(
  //   ({ globals }: { globals: { tenant: { internationalization: { countryCode: string } } } }) =>
  //     globals.tenant.internationalization
  // );

  // const watchCountry = watch('countryCode');

  // console.log('props', props);

  return (
    <Modal isOpen={true} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Credit Card</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {stripeClientSecret && stripePromise && onSubmit && (
            <Elements
              key={stripeClientSecret}
              stripe={stripePromise}
              options={{ clientSecret: stripeClientSecret }}
            >
              <StripeCheckoutForm onSubmit={onSubmit} />
            </Elements>
          )}

          {/* <Box>
            <FormProvider {...methods}>
              <FormControl
                field="name"
                label="Name"
                required={true}
                render={(fieldProps) => <Input {...fieldProps} />}
              />
              <FormControl
                field="number"
                label="Number"
                required={true}
                render={(fieldProps) => (
                  <InputWrapper>
                    <Cleave
                      className={'form-control-cleave'}
                      {...fieldProps}
                      onChange={(e) => setValue('number', e.target.rawValue)}
                      options={{ creditCard: true }}
                    />
                  </InputWrapper>
                )}
              />
              <HStack spacing={4}>
                <FormControl
                  field="expMonth"
                  label="Exp Month"
                  required={true}
                  render={(fieldProps) => (
                    <Select
                      useBasicStyles
                      placeholder=""
                      onChange={(e) => setValue('expMonth', e?.value)}
                      options={expMonths}
                    />
                  )}
                />
                <FormControl
                  field="expYear"
                  label="Exp Year"
                  required={true}
                  render={(fieldProps) => (
                    <Select
                      useBasicStyles
                      placeholder=""
                      onChange={(e) => setValue('expYear', e?.value)}
                      options={expYears}
                    />
                  )}
                />
                <FormControl
                  field="cvv"
                  label="CVV"
                  required={true}
                  render={(fieldProps) => <Input {...fieldProps} />}
                />
              </HStack>

              <FormControl
                field="countryCode"
                label="Country Code"
                required={true}
                render={(fieldProps) => (
                  <Select
                    {...register('countryCode')}
                    useBasicStyles
                    value={countryOptions?.find((c) => c.value === watchCountry)}
                    onChange={(e) => {
                      setValue('countryCode', e?.value);
                      setValue('stateProvince', '');
                    }}
                    placeholder=""
                    options={countryOptions}
                  />
                )}
              />
              <FormControl
                field="address1"
                label="Address 1"
                required={true}
                render={(fieldProps) => <Input {...fieldProps} />}
              />
              <HStack spacing={4}>
                <FormControl
                  field="city"
                  label="City"
                  required={true}
                  render={(fieldProps) => <Input {...fieldProps} />}
                />
                <FormControl
                  field="stateProvince"
                  label="State/Province"
                  required={true}
                  render={(fieldProps) =>
                    countryInclude.includes(watchCountry) ? (
                      <Select
                        {...register('stateProvince')}
                        useBasicStyles
                        onChange={(e) => setValue('stateProvince', e?.value)}
                        placeholder=""
                        options={stateProvinceOptions(watchCountry)}
                      />
                    ) : (
                      <Input type="text" {...register('stateProvince')} />
                    )
                  }
                />
                <FormControl
                  field="postalCode"
                  label="Postal Code"
                  required={true}
                  render={(fieldProps) => <Input {...fieldProps} />}
                />
              </HStack>
            </FormProvider>
          </Box> */}
          {errorMessage && (
            <Alert my={3} status="error">
              {errorMessage}
            </Alert>
          )}
        </ModalBody>

        <ModalFooter>
          <Button mr={3} onClick={onClose}>
            Close
          </Button>
          <Button
            colorScheme="blue"
            form="stripe-payment-form"
            type="submit"
            onClick={() => onSubmit}
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
