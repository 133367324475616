import * as React from 'react';
// import { DragSource } from 'react-dnd';
import {
  Accordion,
  Button,
  Card,
  Checkbox,
  Dropdown,
  Header,
  Icon,
  Label,
  Grid,
  Modal,
  Popup,
  Segment,
  Table,
} from 'semantic-ui-react';
import { FormattedDate, FormattedNumber } from 'react-intl';

const cardSource = {
  beginDrag(props) {
    // Return the data describing the dragged item
    const item = props.orderItem;
    return item;
  },

  endDrag(props, monitor, component) {
    if (!monitor.didDrop()) {
      return;
    }

    // When dropped on a compatible target, do something
    const item = monitor.getItem();
    const dropResult = monitor.getDropResult();
    // props.onDrop(item);
  },
};

/**
 * Specifies which props to inject into your component.
 */
function collect(connect, monitor) {
  return {
    // Call this function inside render()
    // to let React DnD handle the drag events:
    connectDragSource: connect.dragSource(),
    // You can ask the monitor about the current drag state:
    isDragging: monitor.isDragging(),
  };
}

interface ItemCardProps {
  id: any;
  orderItem: any;
  quantity: any;
  isDragging: Boolean;
  connectDragSource: any;
}

class ItemCard extends React.Component<ItemCardProps, any> {
  render() {
    const { orderItem } = this.props;

    // These two props are injected by React DnD,
    // as defined by your `collect` function above:
    const { isDragging, connectDragSource } = this.props;

    return connectDragSource(
      <div style={{ marginBottom: 10 }}>
        <Segment size={'small'} style={{ paddingTop: 45 }}>
          <Label attached="top">{orderItem.humanId}</Label>
          {orderItem.size.name}
          {orderItem.dueDate && (
            <Label size={'small'} attached="bottom right">
              {orderItem.dueDate}
            </Label>
          )}
        </Segment>
      </div>
    );
  }
}

// Export the wrapped version
// AFter upgrading to react-dnd 16.0.0, this no longer worked.
// export default DragSource('card', cardSource, collect)(ItemCard);
export default ItemCard;
