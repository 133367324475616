import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Segment, Form, Dropdown } from 'semantic-ui-react';
import { PageHeaderRow, PreLoader, useFeathers } from '@inkcloud/components';
import Feathers from '../../../bootstrap/feathers';
import NotificationRulesForm from './NotificationRulesForm';

interface DetailProps {
  products: { value: string; text: string }[];
  categories: { value: string; text: string }[];
}

type emailType = { name: string; email: string };

const initNotification = {
  bcc: [] as emailType[],
  cc: [] as emailType[],
  recipients: [] as emailType[],
  enable: true,
  templateKey: '',
  advanced: false,
  replyTo: '',
  defaultEmail: {
    name: '',
    email: '',
  },
};

type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;
type tType = typeof initNotification;
export type notificationTemplateType = Optional<
  tType,
  'bcc' | 'cc' | 'recipients' | 'replyTo' | 'defaultEmail' | 'advanced'
>;

const { useState, useEffect } = React;

export const Detail: React.FunctionComponent<DetailProps & RouteComponentProps<{ id?: string }>> = (
  props
) => {
  const {
    match: {
      params: { id },
    },
    history,
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState({ settings: '', tenant: '', keys: '' });
  const [setting, setSetting] = useState<notificationTemplateType>(initNotification);
  const [responseMessage, setResponseMessage] = useState('');
  const [templateKey, setTemplateKey] = useState('');
  const [templateKeys, setTemplateKeys] = useState([]);

  const isEdit = !!id;

  useEffect(() => {
    const loadSetting = async () => {
      try {
        setErrorMessage({ ...errorMessage, settings: '' });
        setIsLoading(true);
        const res: notificationTemplateType = await Feathers.service(
          '/tenant-settings/notification-settings'
        ).find({
          query: {
            templateKey: id,
          },
        });

        setSetting(res);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        setErrorMessage({
          ...errorMessage,
          settings:
            e.code && e.code < 500
              ? e.message
              : 'We are experiencing technical difficulties. Please try again',
        });
      }
    };

    loadSetting();
  }, []);

  const handleSubmit = async (values: notificationTemplateType) => {
    const payload = {
      templateKey: values.templateKey,
      templateDetails: {
        ...values,
      },
    };

    try {
      setResponseMessage('');
      setErrorMessage({ ...errorMessage, tenant: '' });
      setIsSubmitting(true);
      const res: any = await Feathers.service('/tenant-settings/notification-settings').create(
        payload,
        { query: {} }
      );
      setIsSubmitting(false);

      if (res.result) {
        setResponseMessage(res.message);
        history.push('/settings/notification-rules');
      }
    } catch (e) {
      setIsSubmitting(false);
      setErrorMessage({
        ...errorMessage,
        tenant:
          e.code && e.code < 500
            ? e.message
            : 'We are experiencing technical difficulties. Please try again',
      });
    }
  };

  if (isLoading) return <PreLoader />;

  return (
    <div>
      <PageHeaderRow header={`Notification Rules`} subheader="Settings" subheaderId={''}>
        <Form size="mini">
          <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
        </Form>
      </PageHeaderRow>

      <NotificationRulesForm
        initialValues={isEdit ? setting : { templateKey: '', enable: true }}
        onSubmit={handleSubmit}
        errorMessage={errorMessage.keys || errorMessage.tenant || errorMessage.settings}
        isSubmitting={isSubmitting}
      />
    </div>
  );
};
