import * as React from 'react';
import { Formik, Form as FormikForm, Field, ErrorMessage } from 'formik';
import { Button, Form } from 'semantic-ui-react';
import * as Yup from 'yup';

interface HeroStackFormProps {
  initialValues: any;
  onSubmit: Function;
  isSubmitting?: boolean;
}

const initialMapper = (v) => {
  if (!v) {
    v = { name: '', key: '' };
  }
  if (!v.questions) {
    v.questions = [{ title: '', subtitle: '', text: '' }];
  }

  return v;
};

export const HeroStackForm: React.FC<HeroStackFormProps> = (props) => {
  const EvaluationTypeSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
  });

  return (
    <Formik
      initialValues={initialMapper(props.initialValues)}
      validationSchema={EvaluationTypeSchema}
      onSubmit={(values, { setSubmitting }) => props.onSubmit(values)}
    >
      {(props) => {
        const { values, isSubmitting, setFieldValue, setFieldTouched } = props;

        return (
          <FormikForm className={`ui form ${isSubmitting && 'loading'}`}>
            <Form.Field>
              <Field type="text" name="name" placeholder="Name..." />
              <ErrorMessage component="div" name="name" />
            </Form.Field>
            <Button primary type="submit" disabled={isSubmitting}>
              Save
            </Button>
          </FormikForm>
        );
      }}
    </Formik>
  );
};
