import * as React from 'react';
import {
  Segment,
  Dropdown,
  Header,
  Icon,
  Modal,
  Divider,
  Button,
  Message,
  Label,
} from 'semantic-ui-react';
import { Box, Heading } from '@chakra-ui/react';

import { RouteComponentProps } from 'react-router-dom';
import { PaginatorWrapper, PreLoader, EmptyWrapper } from '@inkcloud/components';
import feathers from '../../../bootstrap/feathers';
import NoteContainer from '../../../common/notes2/index';

import { OrderItemComponent2, OrderItemModalsProps } from '../../../common/OrderItemCard2';

const { useState, useEffect } = React;

declare let localStorage;

export function NewError(msg: string) {
  this.message = msg;
  this.code = 406;
}

const WorkflowStations = (props: RouteComponentProps & OrderItemModalsProps) => {
  const { history } = props;

  const [workflowStations, setWorkflowStations] = useState<any>([]);
  const [selected, setSelected] = useState('');
  const [localValue, setLocalValue] = useState('');

  const [humanId, setHumanId] = useState('');
  const [orderItems, setOrderItems] = useState(null);

  const [updateDisabled, setUpdateDisabled] = useState(false);

  const [isLoading, setIsLoading] = useState({
    orderItems: false,
    statusChange: false,
  });

  const [isOrderLoading, setIsOrderLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState({
    scan: '',
    status: '',
  });

  const [isReloading, setIsReloading] = useState(false);

  const [notes, setNotes] = useState([]);

  useEffect(() => {
    const loadWorkflowStations = async () => {
      try {
        const res: any = await feathers.service('workflow-stations').find({
          query: {
            $sort: { name: 1 },
            // $np: 1
          },
        });

        setWorkflowStations(res?.data);

        const stationLocal = localStorage.getItem('workflowStation');

        setLocalValue(stationLocal);

        const name = res?.data?.find((w) => w._id === stationLocal)?.name;

        setSelected(name);
      } catch (e) {
        console.log(e);
      }
    };

    loadWorkflowStations();
  }, []);

  const loadOrder = async (id: string) => {
    setIsOrderLoading(true);
    try {
      const res = await feathers.service('orders').get(id, {
        query: {
          $populate: ['notes.user'],
          $select: ['notes'],
        },
      });

      setNotes(res?.notes);
    } catch (e) {
      console.error(e);
    }

    setIsOrderLoading(false);
  };

  const loadOrderItems = async (id: string = humanId) => {
    setIsLoading({
      ...isLoading,
      orderItems: true,
    });
    try {
      const res: any = await feathers.service('order-items').find({
        query: {
          humanId: id,
          $populate: [
            'attributes.attribute',
            'attributes.value',
            'category',
            'customer',
            'micrositeUser',
            'productionStatus.jobStatus',
            'miscUploads',
            'order',
            'order.notes.user',
          ],
        },
      });

      setOrderItems(res);

      if (res?.data[0]?.order?.notes?.length) {
        loadOrder(res?.data[0]?.order?._id);
      }
    } catch (err) {
      console.log(err);
    }

    setIsLoading({
      ...isLoading,
      orderItems: false,
    });

    setIsReloading(false);
  };

  useEffect(() => {
    if (isReloading) {
      loadOrderItems();
    }
  }, [isReloading]);

  const handleArtworkManagementClick = (e, data) => {
    props.artworkHandleOpen(data.orderitem);
  };

  const printHandleOpen = (e, data) => {
    props.printHandleOpen(data.type, data.payload);
  };

  const preflightHandleOpen = (e, data) => {
    props.preflightHandleOpen(data.orderitem);
  };

  const statusChangeHandleOpen = (e, data) => {
    // props.statusChangeHandleOpen(data.orderitem);
  };

  const handleScan = async (e: any) => {
    if (e.key === 'Enter') {
      const { value } = e.target;

      setErrorMessage({
        ...errorMessage,
        scan: '',
      });

      const normalizeValue = value?.trim();
      setHumanId(normalizeValue);

      const isOrderItem = normalizeValue?.indexOf('-') >= 0;

      try {
        if (!isOrderItem) {
          throw new NewError('Please scan again or enter a correct order item number');
        }
        loadOrderItems(normalizeValue);
        setUpdateDisabled(false);
      } catch (err) {
        const errMsg =
          err?.code < 500 ? err.message : 'Please scan again or enter a correct order item number';
        setErrorMessage({
          ...errorMessage,
          scan: errMsg,
        });
      }
    }
  };

  const handleReset = () => {
    if (!humanId) {
      setSelected('');
      setLocalValue('');
      localStorage.removeItem('workflowStation');
    } else if (orderItems) {
      setOrderItems(null);
      setHumanId('');
    }
  };

  const handleChangeStation = (e, data) => {
    localStorage.setItem('workflowStation', data.value);
    setLocalValue(data.value);

    const name = workflowStations?.find((w) => w._id === data.value)?.name;
    setSelected(name);
  };

  const status = workflowStations?.find((w) => w._id === localValue)?.transitionToStatus;

  const handleStastusChange = async () => {
    setIsLoading({
      ...isLoading,
      statusChange: true,
    });

    setErrorMessage({
      ...errorMessage,
      status: '',
    });
    try {
      const res: any = orderItems?.data?.map(async (oi) =>
        feathers.service('order-items/status-changer').update(oi._id, {
          status,
        })
      );

      await Promise.all(res);

      setIsLoading({
        ...isLoading,
        orderItems: true,
      });

      // loadOrderItems()
      setUpdateDisabled(true);

      setOrderItems(null);
      setHumanId('');
    } catch (e) {
      const errMsg =
        e?.code < 500 ? e.message : 'We are experiencing technical difficulties. Please try again';
      setErrorMessage({
        ...errorMessage,
        status: errMsg,
      });
    }

    setIsLoading({
      ...isLoading,
      statusChange: false,
    });
  };

  return (
    <Modal open onClose={() => history.push('/')} closeIcon>
      <Modal.Header>Workflow Station: {selected}</Modal.Header>
      <Modal.Content>
        <div style={{ textAlign: 'center' }}>
          {!selected ? (
            <Dropdown
              search
              selection
              options={workflowStations?.map((w) => ({ value: w._id, text: w.name }))}
              placeholder="Select workflow station..."
              onChange={handleChangeStation}
            />
          ) : (
            !orderItems && (
              <>
                <div>
                  <Header as="h4">Scan or enter order item number</Header>
                  <Icon name="barcode" size="massive" />
                </div>

                <div className="ui input">
                  <input type="text" autoFocus onKeyPress={handleScan} />
                </div>

                {errorMessage.scan && <Message error content={errorMessage.scan} />}
              </>
            )
          )}
        </div>

        {isLoading.orderItems ? (
          <PreLoader />
        ) : (
          orderItems && (
            <EmptyWrapper queryResult={orderItems} isStateLoading={isLoading.orderItems}>
              <Segment>
                {isOrderLoading ? (
                  <PreLoader />
                ) : (
                  <Box
                    p={3}
                    mb={2}
                    border="1px solid var(--chakra-colors-gray-200)"
                    borderRadius="md"
                  >
                    <Heading mb={3} size="sm">
                      Notes
                    </Heading>
                    <NoteContainer
                      isModalOpen={false}
                      notes={notes}
                      errorMessage={''}
                      onSubmit={() => {}}
                      handleNoteClose={() => {}}
                      maxWidth="100%"
                      hidePlaceholder={true}
                    />
                  </Box>
                )}

                {orderItems?.data?.[0]?.order?.shipment?.deliveryInstructions && (
                  <Segment secondary>
                    <Label attached="top" content="Delivery Instructions" />
                    <br />
                    {orderItems.data[0]?.order?.shipment?.deliveryInstructions ?? ''}
                  </Segment>
                )}
                {orderItems?.data?.[0]?.productionNotes && (
                  <Segment secondary>
                    <Label attached="top" content="Production Notes" />
                    <br />
                    {orderItems.data[0].productionNotes ?? ''}
                  </Segment>
                )}

                {orderItems?.data?.map((i, index: number) => (
                  <OrderItemComponent2
                    key={index}
                    orderItem={i}
                    showRightMenu={false}
                    handleArtworkManagementClick={handleArtworkManagementClick}
                    printHandleOpen={printHandleOpen}
                    preflightHandleOpen={preflightHandleOpen}
                    statusChangeHandleOpen={statusChangeHandleOpen}
                    highlightQuantity={i.business === '610c663d1d8c987ce3b881b3'}

                    // onTurnaroundSubmit={handleTurnaroundSubmit}
                  />
                ))}
              </Segment>
            </EmptyWrapper>
          )
        )}
      </Modal.Content>
      <Modal.Actions>
        <div style={{ textAlign: 'center' }}>
          <Button
            type="button"
            primary
            content="Update Status"
            disabled={!orderItems || updateDisabled || !humanId || isLoading.statusChange}
            loading={isLoading.statusChange}
            onClick={handleStastusChange}
          />
          <Button
            type="button"
            content="Switch Station"
            disabled={isLoading.statusChange}
            onClick={handleReset}
          />
        </div>
      </Modal.Actions>
    </Modal>
  );
};

export default WorkflowStations;
