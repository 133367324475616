import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Button, Grid, Image, Modal, Segment, Icon, Divider } from 'semantic-ui-react';

import { useList, PaginatorWrapper, EmptyWrapper } from '@inkcloud/components';

import { ImageUpload } from '../../products/products/stock-product/StockForm/ImageUpload';

interface ImageUploadModalProps {
  isOpen: boolean;
  onClose: () => void;
  setFieldValue: (fieldName: string, value: string) => void;
  fieldName?: string;
  micrositeId?: string;
  outerServiceName?: string;
}

declare var ENV;

const filterQueryMap = (values) => {};

const { useState } = React;

const serviceName = '/content';

export const PreImageUploadModal: React.FunctionComponent<
  ImageUploadModalProps & RouteComponentProps
> = (props) => {
  const {
    isOpen,
    onClose,
    outerServiceName = undefined,
    micrositeId = undefined,
    location,
    history,
  } = props;

  const pathPrefix = '';

  const {
    state,
    reload,
    handleDelete,
    selectedItems,
    clearAll,
    setChecked,
    someSelected,
    toggleAll,
    filter,
    setFilter,
    sort,
    handleSort,
  } = useList({
    feathers: ['find', outerServiceName || serviceName, [{ query: { microsite: micrositeId } }]],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: {},
  });

  const fieldName = props.fieldName ? props.fieldName : 'banner';

  const { queryResult, isLoading: isStateLoading } = state as any;
  return (
    <Modal open={isOpen} onClose={() => onClose()} closeIcon>
      <Modal.Header>Image Manager</Modal.Header>
      <Modal.Content>
        <ImageUpload
          onUpload={(files) => {
            props.setFieldValue(
              fieldName,
              fieldName !== 'banner' ? files[0]._id : files[0].publicUrl
            );
            if (fieldName !== 'banner') {
              props.setFieldValue('imgUrl', files[0].publicUrl);
            }
            onClose();
          }}
          showFiles={false}
          multiple={false}
        />
        <EmptyWrapper isStateLoading={isStateLoading} queryResult={queryResult}>
          <PaginatorWrapper.Top
            filter={{}}
            reload={reload}
            data={queryResult as any}
            pathPrefix={`${pathPrefix}`}
          />
          <Grid>
            <Grid.Row columns={4}>
              {(queryResult as any)?.data?.map((l: any, index: number) => (
                <Grid.Column key={index}>
                  <Segment style={{ height: '250px' }}>
                    <div>
                      <div style={{ height: '151px' }}>
                        {l.extension === 'pdf' ? (
                          <div style={{ margin: '.5em 0' }}>
                            <Icon name="file pdf" size="huge" />
                          </div>
                        ) : (
                          <Image
                            src={`https://storage.googleapis.com/icstorage/${
                              fieldName === 'banner' ? l.banner : l.publicUrl
                            }`}
                            style={{ maxHeight: '100%', margin: '.5em auto' }}
                            onClick={() => {
                              onClose();
                              props.setFieldValue(
                                fieldName,
                                fieldName !== 'banner' ? l._id : l.publicUrl
                              );
                              fieldName !== 'banner' && props.setFieldValue('imgUrl', l.publicUrl);
                            }}
                          />
                        )}
                      </div>
                      <div
                        title={l.filename}
                        style={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          margin: '.5em 0',
                        }}
                      >
                        {l.filename}
                      </div>
                      <div>
                        <Button
                          primary
                          size="tiny"
                          content="Select"
                          onClick={() => {
                            onClose();
                            props.setFieldValue(
                              fieldName,
                              fieldName !== 'banner' ? l._id : l.publicUrl
                            );
                            fieldName !== 'banner' && props.setFieldValue('imgUrl', l.publicUrl);
                          }}
                        />
                      </div>
                    </div>
                  </Segment>
                  <Divider hidden />
                </Grid.Column>
              ))}
            </Grid.Row>
          </Grid>
          <PaginatorWrapper.Bottom
            reload={reload}
            data={queryResult as any}
            pathPrefix={`${pathPrefix}`}
          />
        </EmptyWrapper>
      </Modal.Content>
    </Modal>
  );
};

export const ImageUploadModal = withRouter(PreImageUploadModal);
