import * as React from 'react';
import {
  Segment,
  Grid,
  Feed,
  Modal,
  Form,
  TextArea,
  Message,
  Button,
  Input,
  Divider,
} from 'semantic-ui-react';

import { Note } from './Note';

interface ApprovalModalProps {
  isOpen: boolean;
  isLoading: boolean;
  value: any;
  customer: any;
  isBadger: boolean;
  user: any;
  userType: string;
  onChangeStatus: (v: any) => void;
  onClose: () => void;
}

export const ApprovalModal: React.FunctionComponent<ApprovalModalProps> = (props) => {
  const { isOpen, isLoading, onClose, value, customer, userType, isBadger, user, onChangeStatus } =
    props;

  return (
    <>
      <Modal size="small" open={isOpen} onClose={onClose} closeIcon>
        <Modal.Content>
          <Feed>
            <Note
              values={value}
              name={customer}
              userType={userType}
              isBadger={isBadger}
              user={user}
            />
          </Feed>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={onClose} content="Reject" />
          <Button
            positive
            icon="checkmark"
            labelPosition="right"
            loading={isLoading}
            onClick={() => onChangeStatus('complete')}
            content="Approve"
          />
        </Modal.Actions>
      </Modal>
    </>
  );
};
