import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Box, Button } from '@chakra-ui/react';
import { PageHeaderRow, PreLoader, useFeathers } from '@inkcloud/components';
import { useSelector } from 'react-redux';
import feathers from '../../../bootstrap/feathers';
import { LocalizationForm } from './LocalizationForm';

interface DetailProps {}

const { useState } = React;

const pathPrefix = `/settings/localization`;
const serviceName = '/tenant-settings';
const commonParams = { query: { $select: ['internationalization'] } };

export default function Detail(props: DetailProps & RouteComponentProps) {
  const { history } = props;

  const user = useSelector((state: any) => state.auth.user);

  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [state, reload] = useFeathers('get', serviceName, [user.business, commonParams], true);

  const handleSubmit = async (values) => {
    try {
      setErrorMessage('');
      setIsLoading(true);
      await feathers
        .service('tenant-settings/internationalization')
        .patch(user.business, values, { query: {} });
      setIsLoading(false);
      history.push('/settings');
    } catch (error) {
      setIsLoading(false);
      setErrorMessage('We are experiencing technical difficulties. Please try again');
    }
  };

  if (!state.data) {
    return <PreLoader />;
  }

  const initialData = { ...state.data };
  return (
    <div>
      <PageHeaderRow header="Localization" subheader="Settings" subheaderId={''}>
        <Button size="sm" onClick={() => history.goBack()}>
          Back
        </Button>
      </PageHeaderRow>
      <Box p={3} mb={3} borderRadius="md" borderWidth="1px">
        {
          <LocalizationForm
            localizationProp={initialData && initialData.internationalization}
            isLoading={isLoading}
            errorMessage={errorMessage}
            onSubmit={handleSubmit}
          />
        }
      </Box>
    </div>
  );
}
