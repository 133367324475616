import * as React from 'react';
import { Checkbox, Segment, Table, Label, Popup, Form, Dropdown } from 'semantic-ui-react';

import { Link, RouteComponentProps } from 'react-router-dom';
// force deploy 3333
import { FormattedDate, FormattedTime } from 'react-intl';

import {
  Button,
  Box,
  ButtonGroup,
  IconButton,
  Tooltip,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Image,
  Stack,
  Tag,
  Text,
  Icon,
  Flex,
  Alert,
} from '@chakra-ui/react';

import {
  FaEdit,
  FaEllipsisV,
  FaUserAlt,
  FaWarehouse,
  FaExternalLinkSquareAlt,
  FaPrint,
  FaEye,
  FaCheckCircle,
  FaFlag,
  FaRegClock,
} from 'react-icons/fa';

import {
  useList,
  PageHeaderRow,
  PaginatorWrapper,
  GenericFilter,
  basicQueryMap,
  EmptyWrapper,
  ListTable,
} from '@inkcloud/components';

import type { ModelTypes } from '@inkcloud/icapi-types';

import { getFilterDefinition } from './FilterForm2';

import Feathers from '../../../bootstrap/feathers';

import { MicrositePublishModal } from './stock-product/MicrositePublishModal';

import { VisibilityModal } from './VisibilityModal';
import { MassUpdaterModal } from './MassUpdaterModal';
import { ImageModal } from './ImageModal';
import { TransferBackorderModal } from './TransferBackorderModal';
import ProductLabelModal from '../labels/ProductLabelModal';

interface ListProps {}
export const filterMapper = (filterIn) => {
  const filterOut = { ...filterIn };
  if (filterOut.entityAttribute) {
    filterOut.attributes = filterOut.entityAttribute;
    delete filterOut.entityAttribute;
  }

  if (filterOut.staticName) {
    filterOut.staticName = { $LIKE: filterOut.staticName };
  }

  if (filterOut.key) {
    filterOut.key = { $LIKE: filterOut.key };
  }

  if (filterOut.category) {
    filterOut.category = { $in: filterOut.category };
  }

  if (filterOut.quantities) {
    filterOut.prices = {
      $elemMatch: {
        unitValue: { $in: filterOut.quantities },
      },
    };
    delete filterOut.quantities;
  }

  if (filterOut.addOns) {
    filterOut.addOns = {
      $elemMatch: {
        id: { $in: filterOut.addOns },
        isEnabled: true,
      },
    };
  }

  if (filterOut.itemType) {
    if (filterOut.itemType && filterOut.itemType.length === 1) {
      filterOut.inventory = filterOut.itemType.includes('stock') ? true : { $ne: true };
    }
    delete filterOut.itemType;
  }

  return filterOut;
};

const filterQueryMap = (values) => {
  let filterRows = basicQueryMap(values, {
    name: 'staticName',
  });

  // Map attributes as needed by API
  const prefix = 'attributes:';
  const attributes = Object.keys(filterRows)
    .filter((r) => r.startsWith(prefix))
    .reduce((acc, cur) => ({ ...acc, [cur.slice(prefix.length)]: filterRows[cur]?.$in }), {});

  // Delete the original attribute valuess so they are not submitted
  Object.keys(filterRows)
    .filter((r) => r.startsWith(prefix))
    .forEach((r) => delete filterRows[r]);

  let hasVariants;

  if (filterRows.type === 'stock-parent') {
    hasVariants = true;
  } else if (filterRows.key) {
    hasVariants = undefined;
  }

  let isHidden;

  if (filterRows?.isHidden?.$elemMatch?.value === true) {
    isHidden = true;
  } else if (filterRows?.isHidden?.$elemMatch?.value === 'false') {
    isHidden = { $ne: true };
  }

  const stockTypes = ['stock', 'stock-parent', 'stock-child'];

  let inventory;

  if (stockTypes.includes(filterRows.type?.$elemMatch?.value)) {
    inventory = true;
  } else if (filterRows.type?.$elemMatch?.value === 'on-demand') {
    inventory = { $ne: true };
  } else {
    inventory = undefined;
  }

  filterRows = {
    ...filterRows,
    addOns: filterRows.addOns
      ? { $elemMatch: { id: filterRows.addOns, isEnabled: true } }
      : undefined,
    attributes,
    publishedToMicrosites: filterRows.publishedToMicrosites
      ? filterRows.publishedToMicrosites
      : undefined,
    // hasVariants: filterRows.type === 'stock-parent' ? true : filterRows.type === 'stock-child' ? { $ne: true } : undefined,
    hasVariants,
    inventory,
    isComposite: filterRows.type?.$elemMatch?.value === 'composite' ? true : undefined,
    // parent: filterRows.type === 'stock-child' ? true : filterRows.type === 'stock-parent' ? { $ne: true } : undefined,
    isHidden,
    type: undefined,
  };

  return filterRows;
};

const sortableByOptions = [
  { text: 'Date', value: 'createdAt' },
  { text: 'SKU', value: 'key' },
];

const sortByOptions = [
  { text: 'Descending', value: -1 },
  { text: 'Ascending', value: 1 },
];

const serviceName = 'products-legacy';
const pathPrefix = '/products/products';

const { useState, useRef, useEffect } = React;

export const List: React.FunctionComponent<ListProps & RouteComponentProps<any>> = (props) => {
  const {
    match: { params },
    location,
    history,
  } = props as any;
  const {
    state,
    reload,
    handleDelete,
    selectedItems,
    clearAll,
    setChecked,
    setSelectedItems,
    someSelected,
    toggleAll,
    filter,
    setFilter,
    sort,
    handleSort,
  } = useList({
    feathers: [
      'find',
      serviceName,
      [
        {
          query: {
            $populate: ['attributes.value', 'category', 'parent'],
            $populateAddOns: 1,
          },
        },
      ],
    ],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  }) as any;

  const [sortableBy, setSortableBy] = useState('createdAt');
  const [sortBy, setSortBy] = useState(sort[sortableBy] === 'ascending' ? 1 : -1);

  const [microsites, setMicrosites] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState<NonNullable<ModelTypes.ProductLegacy>>();
  const [micrositePublishModalOpen, setMicrositePublishModalOpen] = useState(false);
  const [isPrintProductLabelOpen, setIsPrintProductLabelOpen] = useState(false);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [isVisibilityModalOpen, setIsVisibilityModalOpen] = useState(false);
  const [imageThumb, setImageThumb] = useState({
    url: '',
    name: '',
  });

  const [isMassUpdaterOpen, setIsMassUpdaterOpen] = useState(false);
  const [isTransferBackorderModalOpen, setIsTransferBackorderModalOpen] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState({
    duplicate: false,
    export: false,
  });

  const [errorMessage, setErrorMessage] = useState({
    duplicate: '',
    export: '',
  });

  const [responseMessage, setResponseMessage] = useState({
    massUpdate: '',
  });

  useEffect(() => {
    const loadMicrosites = async () => {
      const res = await Feathers.service('/microsites').find({ query: { $np: 1 } });
      setMicrosites(res);
    };
    loadMicrosites();
  }, []);

  useEffect(() => {
    if (location?.state?.from === 'managed-inventory') {
      setFilter({
        ...filter,
        ...(location?.state?.filter || {}),
      });
    }
  }, [location?.state?.from]);

  // const [isLoading, setIsLoading] = useState(false)
  const [status, setStatus] = useState(null);
  const toggleAllCheck = useRef(null);

  const { queryResult, isLoading: isStateLoading } = state as any;

  // if (!queryResult) {
  //   return null;
  // }

  let isFiltered = false;
  if (filter) {
    isFiltered = Object.keys(filter).reduce(
      (prev, field) =>
        prev ||
        !!(Array.isArray(filter[field]) ? filter[field]?.some((f) => f.value) : filter[field]),
      false
    );
  }

  let queryFilter;
  if (isFiltered) {
    queryFilter = filter?.rows?.reduce(
      (acc, cur) =>
        Object.assign(acc, {
          [cur.selectedFact?.replace(/\./g, '')]: Array.isArray(cur.value)
            ? cur.value?.map((v) => v.value)
            : cur.value?.value,
        }),
      {}
    );
  }

  const handleExport = async () => {
    setErrorMessage({
      ...errorMessage,
      export: '',
    });
    setIsSubmitting({
      ...isSubmitting,
      export: true,
    });
    try {
      const res: any = await Feathers.service('/reports/v3/results').create({
        reportKey: 'products-export',
        reportName: 'Products Export',
        query: {
          ...queryFilter,
        },
      });

      history.push('/reports/results');
    } catch (e) {
      setErrorMessage({
        ...errorMessage,
        export: 'Error while exporting.',
      });
    }

    setIsSubmitting({
      ...isSubmitting,
      export: false,
    });
  };

  const tableDefinition = [
    {
      key: 'name',
      label: 'Name',
      sortable: true,
      cell: (v, r) => (
        <>
          <Text>{r?.staticName || r?.description}</Text>
          <Box>{r?.isHidden && <Tag colorScheme="blackAlpha">Hidden</Tag>}</Box>
        </>
      ),
    },
    {
      key: '',
      label: 'Key/Category',
      sortable: true,
      cell: (v, r) => (
        <>
          <Box>{r?.key} </Box>
          {r?.version > 1 && <Tag colorScheme="green">Ver {r?.version}</Tag>}
          <Box>{r?.category?.name || 'Custom'}</Box>
        </>
      ),
    },
    {
      key: 'tags',
      label: 'Type',
      sortable: true,
      cell: (v, r) => (
        <>
          <Flex direction="row" flexWrap="wrap">
            {r?.attributes?.map((ea) => (
              <Tag mb={1} mr={1} colorScheme="blue" variant="outline" key={ea?.value?._id}>
                {ea?.value?.name}
              </Tag>
            ))}
          </Flex>
          <Text>{r?.staticName}</Text>

          <Flex direction="row" flexWrap="wrap">
            {r?.prices
              ?.sort((a, b) => parseInt(a.unitValue) - parseInt(b.unitValue))
              .map((p) => (
                <Tag mb={1} mr={1} key={p.unitValue}>
                  {p.unitValue}
                </Tag>
              ))}
          </Flex>
          <Flex direction="row" flexWrap="wrap">
            {r?.addOns
              ?.filter((a) => a.isEnabled)
              ?.map((a) => (
                <Tag mb={1} mr={1} key={a._id}>
                  {a.name}
                </Tag>
              ))}
          </Flex>
        </>
      ),
    },
    {
      key: '',
      label: 'Publish',
      sortable: true,
      cell: (v, r) => (
        <Stack direction="column" spacing={1}>
          {r?.publishedToMicrosites?.map((pub: any, idx: number) => (
            <Box key={idx}>
              <Icon as={FaCheckCircle} color="green" />
              {pub?.micrositeName}
            </Box>
          ))}
        </Stack>
      ),
    },
    {
      key: '',
      label: 'Image',
      sortable: true,
      cell: (v, r) => {
        let thumbUrl;
        let imgUrl;
        if (r?.images?.length) {
          const { url } = r.images[0];
          const arr = url?.split('/');
          if (arr?.length) {
            thumbUrl = `https://storage.googleapis.com/icstorage/${arr[0]}/thumbs/${
              arr[2] ? arr[2] : arr[1]
            }`;
          }

          imgUrl = `https://storage.googleapis.com/icstorage/${url}`;
        }
        return (
          thumbUrl && (
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setImageThumb({
                  url: imgUrl,
                  name: r?.staticName ?? '',
                });
                setIsImageModalOpen(true);
              }}
            >
              <Image src={thumbUrl} />
            </div>
          )
        );
      },
    },
    {
      key: 'inventory',
      label: 'Inventory',
      sortable: true,
      cell: (v, r) => (
        <>
          <Box mb={1}>
            <Tag>{r?.inventoryCount}</Tag>
          </Box>
          <Tag colorScheme="green">${r?.inventoryPrice ?? 0}</Tag>
        </>
      ),
    },
    {
      key: '',
      label: 'Turnaround Time',
      sortable: true,
      cell: (v, r) => (
        <>
          <Box mb={1}>
            <Tooltip label="Turnaround time">
              <Tag>{r?.inventory ? 'S' : 'OD'}</Tag>
            </Tooltip>
          </Box>
          <Tooltip label="Turnaround time">
            <Tag>
              <Icon as={FaRegClock} mr={1} />
              {r?.timeMin === r?.timeMax ? r?.timeMax : `${r?.timeMin} - ${r?.timeMax}`}
            </Tag>
          </Tooltip>
          {r?.isRushEnabled && (
            <Tooltip label="Rush is enabled">
              <Icon as={FaFlag} ml={1} />
            </Tooltip>
          )}
        </>
      ),
    },
    {
      key: 'priority',
      label: 'Priority',
      sortable: true,
      cell: (v) => v,
    },
    {
      key: '',
      label: '',
      sortable: false,
      cell: (v, r) => (
        <>
          <ButtonGroup spacing={1}>
            <Tooltip label="Edit">
              <Link
                to={{
                  pathname: `${!r?.inventory ? pathPrefix : '/products/stock-products/edit'}/${
                    r._id
                  }`,
                  state: {
                    ...(location?.state || {}),
                  },
                }}
              >
                <IconButton colorScheme="gray" aria-label="Edit" icon={<FaEdit />} />
              </Link>
            </Tooltip>
            <Menu>
              <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<FaEllipsisV />}
                variant="outline"
              />
              <MenuList>
                {r?.inventory && (
                  <>
                    <MenuItem
                      as={Link}
                      to={{
                        pathname: `/products/${r._id}/inventory-ledger`,
                        search: location?.search,
                        key: location?.key,
                        state: {
                          ...(location?.state || {}),
                          from: 'product-list',
                        },
                      }}
                      icon={<FaWarehouse />}
                    >
                      Manage Inventory
                    </MenuItem>
                    <MenuItem
                      icon={<FaUserAlt />}
                      onClick={() => {
                        setSelectedProduct(r);
                        setIsTransferBackorderModalOpen(true);
                      }}
                    >
                      Transfer Backorder
                    </MenuItem>
                  </>
                )}
                {microsites?.length > 0 && (
                  <MenuItem
                    icon={<FaExternalLinkSquareAlt />}
                    onClick={() => {
                      setSelectedProduct(r);
                      setMicrositePublishModalOpen(true);
                    }}
                  >
                    Publish Microsite
                  </MenuItem>
                )}

                <MenuItem
                  icon={<FaPrint />}
                  onClick={() => {
                    setSelectedProduct(r);
                    setIsPrintProductLabelOpen(true);
                  }}
                >
                  Print Product Label
                </MenuItem>
                <MenuItem
                  icon={<FaEye />}
                  onClick={() => {
                    setSelectedProduct(r);
                    setIsVisibilityModalOpen(true);
                  }}
                >
                  Hide/Unhide
                </MenuItem>
              </MenuList>
            </Menu>
          </ButtonGroup>
        </>
      ),
      textAlign: 'right',
    },
  ];

  const resolvedQueryResult = queryResult?.data ? queryResult.data : queryResult;

  return (
    <>
      <PageHeaderRow header="Products" subheader="List">
        <ButtonGroup spacing={1}>
          <Button
            type="button"
            size="sm"
            onClick={handleExport}
            isLoading={isSubmitting.export}
            isDisabled={isSubmitting.export}
          >
            Export Products
          </Button>{' '}
          <Link to="/products/products/add">
            <Button colorScheme="blue" size="sm">
              Add On-Demand Product
            </Button>
          </Link>
          <Link to="/products/stock-products/add">
            <Button colorScheme="blue" size="sm">
              Add Stock Product
            </Button>
          </Link>
          <Link to="/products/variant-types">
            <Button colorScheme="blue" size="sm">
              Variant Types
            </Button>
          </Link>
          <Link to="/products/variant-values">
            <Button colorScheme="blue" size="sm">
              Variant Values
            </Button>
          </Link>
        </ButtonGroup>
      </PageHeaderRow>
      <Segment>
        <GenericFilter
          isAutoFocus={true}
          factDefinitionFunction={getFilterDefinition}
          value={filter}
          onChange={(v) => {
            setResponseMessage({
              ...responseMessage,
              massUpdate: '',
            });
            setFilter({ ...v });
          }}
        />
      </Segment>
      <Segment>
        <EmptyWrapper queryResult={queryResult} isStateLoading={isStateLoading}>
          {isFiltered && responseMessage?.massUpdate && (
            <Alert status="success" mb={3}>
              {responseMessage?.massUpdate}
            </Alert>
          )}
          <PaginatorWrapper.Top
            filter={filter}
            reload={reload}
            data={queryResult as any}
            pathPrefix={`${pathPrefix}/`}
          >
            {isFiltered && (
              <>
                <div>
                  <Button
                    size="sm"
                    onClick={() => {
                      setResponseMessage({
                        ...responseMessage,
                        massUpdate: '',
                      });
                      setIsMassUpdaterOpen(true);
                    }}
                  >
                    Mass Update
                  </Button>
                </div>
                <br />
              </>
            )}

            <Form size="tiny">
              <Form.Group inline>
                <Form.Field>
                  <Dropdown
                    options={sortableByOptions}
                    value={sortableBy}
                    selection
                    onChange={(e, data) => {
                      setSortableBy((data as any).value);
                      setSortBy(sort[(data as any).value] !== 'ascending' ? 1 : -1);
                      handleSort((data as any).value);
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <Dropdown
                    options={sortByOptions}
                    value={sortBy}
                    selection
                    onChange={(e, data) => {
                      setSortBy(sort[sortableBy] !== 'ascending' ? 1 : -1);
                      handleSort(sortableBy);
                    }}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          </PaginatorWrapper.Top>

          <ListTable
            data={resolvedQueryResult}
            definition={tableDefinition}
            selectedItems={selectedItems}
            setChecked={setChecked}
            sort={sort}
            sortClickFunction={handleSort}
            toggleAll={toggleAll}
            withCheckboxes={true}
          />

          <PaginatorWrapper.Bottom
            reload={reload}
            data={queryResult as any}
            pathPrefix={`${pathPrefix}`}
          >
            {someSelected && (
              <Button type="button" onClick={handleDelete}>
                Delete
              </Button>
            )}
          </PaginatorWrapper.Bottom>

          {isVisibilityModalOpen && (
            <VisibilityModal
              item={selectedProduct}
              onReload={reload}
              onClose={() => setIsVisibilityModalOpen(false)}
            />
          )}

          {isMassUpdaterOpen && (
            <MassUpdaterModal
              onReload={reload}
              isOpen={isMassUpdaterOpen}
              onClose={() => setIsMassUpdaterOpen(false)}
              onResponseMessage={(msg) =>
                setResponseMessage({
                  ...responseMessage,
                  massUpdate: msg,
                })
              }
              filter={filter}
              total={queryResult?.total}
            />
          )}

          {isImageModalOpen && (
            <ImageModal
              isOpen={isImageModalOpen}
              imgThumb={imageThumb}
              onClose={() => {
                setImageThumb({
                  url: '',
                  name: '',
                });
                setIsImageModalOpen(false);
              }}
            />
          )}

          {isTransferBackorderModalOpen && (
            <TransferBackorderModal
              isOpen={isTransferBackorderModalOpen}
              onClose={() => {
                setSelectedProduct({});
                setIsTransferBackorderModalOpen(false);
              }}
              product={selectedProduct}
            />
          )}
        </EmptyWrapper>

        {micrositePublishModalOpen && (
          <MicrositePublishModal
            handleClose={() => {
              setMicrositePublishModalOpen(false);
              setSelectedProduct({});
            }}
            onReload={reload}
            handleSubmit={() => null}
            selectedProduct={selectedProduct as NonNullable<ModelTypes.ProductLegacy>}
            // productId={selectedProduct._id}
            // productKey={selectedProduct.key}
            // staticName={selectedProduct.staticName || selectedProduct.name || selectedProduct.key}
            microsites={microsites}
          />
        )}

        {isPrintProductLabelOpen && (
          <ProductLabelModal
            onClose={() => {
              setIsPrintProductLabelOpen(false);
              setSelectedProduct({});
            }}
            isOpen={isPrintProductLabelOpen}
            sku={(selectedProduct as NonNullable<ModelTypes.ProductLegacy>).key as string}
            productName={
              (selectedProduct as NonNullable<ModelTypes.ProductLegacy>).staticName as string
            }

            // productId={selectedProduct._id}
            // productKey={selectedProduct.key}
            // staticName={selectedProduct.staticName || selectedProduct.name || selectedProduct.key}
          />
        )}
      </Segment>
    </>
  );
};
