import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Textarea,
  Box,
} from '@chakra-ui/react';
import { FormattedDate, FormattedTime } from 'react-intl';

import Cleave from 'cleave.js/react';
import { currencySymbol, InputWrapper } from '../../common';

interface ISupplierChangeModalProps {
  item: any;
  onChange: (v: any) => void;
  onClose: () => void;
}

export default function SupplierChangeModal(props: ISupplierChangeModalProps) {
  const { item: itemProps, onChange, onClose } = props;

  const [item, setItem] = useState(itemProps);

  // console.log('item', item);

  const handleSave = () => {
    onChange(item);
    onClose();
  };

  return (
    <Modal isOpen={true} size="2xl" onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Change</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <TableContainer maxHeight={500} overflowY={'unset'}>
            <Table size={'sm'}>
              <Thead>
                <Tr>
                  <Th w={120}></Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Th>Order</Th>
                  <Td>{item?.orderHumanId}</Td>
                </Tr>
                <Tr>
                  <Th>Item</Th>
                  <Td>{item?.orderItemHumanId}</Td>
                </Tr>
                <Tr>
                  <Th>Ship Date</Th>
                  <Td>{item.date && <FormattedDate value={(item as any)?.date} />}</Td>
                </Tr>
                <Tr>
                  <Th>Description</Th>
                  <Td>{item?.description}</Td>
                </Tr>
                <Tr>
                  <Th>Amount</Th>
                  <Td>
                    <InputWrapper>
                      <Cleave
                        style={{ textAlign: 'right' }}
                        className={'form-control-cleave'}
                        value={item?.changeAmount ?? item?.amount}
                        onChange={(e) => {
                          const value = Number(e.target.rawValue);
                          if (!isNaN(value)) {
                            setItem({ ...item, changeAmount: value });
                          }
                        }}
                        options={{
                          numeral: true,
                          rawValueTrimPrefix: true,
                          numeralPositiveOnly: true,
                          numeralThousandsGroupStyle: 'thousand',
                          prefix: currencySymbol(),
                        }}
                      />
                    </InputWrapper>
                  </Td>
                </Tr>
                <Tr>
                  <Th>Explanation</Th>
                  <Td>
                    <Textarea
                      onChange={(e) => setItem({ ...item, changeExplanation: e.target.value })}
                      value={item?.changeExplanation}
                    />
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        </ModalBody>

        <ModalFooter>
          <Button mr={3} onClick={onClose}>
            Close
          </Button>
          <Button colorScheme="blue" onClick={handleSave}>
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
