// import * as React from 'react';
// import { Accordion, Button, Checkbox, Header, Grid, Segment, Table } from 'semantic-ui-react';
// import { Link } from 'react-router-dom';
// import { FormattedDate, FormattedNumber, FormattedRelative } from 'react-intl';
// import { IndexWrapper, IndexWrappedProps } from '../../../../bootstrap/IndexWrapper';
// import { ListViewPagination, ListFilterWarning } from '../../../../common';
// import { PageHeaderRow, PreLoader } from '@inkcloud/components';
// import { ReceiveModal } from './ReceiveModal';

// import Feathers from '../../../../bootstrap/feathers';

// export interface ListProps extends IndexWrappedProps {
// }

// interface State {
//   isModalOpen: boolean,
//   selectedTicket: any;
// };
// const initialState: State = {
//   isModalOpen: false,
//   selectedTicket: null,
// };

// class List extends React.Component<ListProps, State> {
//   state = initialState;

//   handleAddSubmit = async (values) => {
//     await Feathers.service('/inventory').create({
//       product: this.state.selectedTicket.product._id,
//       operation: 'ADJUSTMENT',
//       ...values,
//     });

//     // Update the incoming ticket now
//     await Feathers.service('/inventory/incoming-tickets').patch(this.state.selectedTicket._id, {
//       status: 'received'
//     });

//     this.handleAddClose();
//     this.props.onPageChange();
//   }

//   handleAddClose = () => {
//     this.setState({ ...this.state, isModalOpen: false, selectedTicket: null });
//   }
//   public render() {
//     const { props } = this;
//     if (!props.queryResult) {
//       return <PreLoader />;
//     }
//     const { data, skip, limit, total } = props.queryResult;
//     return (
//       <div>
//         <PageHeaderRow header='Incoming Notifications' subheader='Inventory'>
//         </PageHeaderRow>
//         <ListFilterWarning isFiltered={props.isFiltered} />
//         <Segment loading={props.isLoading}>
//           <ListViewPagination.Top
//             label={'New Loc'}
//             link={'/products/attributes/add'}
//             {...props}
//             hideAddButton={true}
//           />
//           <Table size={'small'}>
//             <Table.Header>
//               <Table.Row>
//                 <Table.HeaderCell>
//                   <Checkbox
//                     onChange={props.handleSelectAllClick}
//                   />
//                 </Table.HeaderCell>
//                 <Table.HeaderCell>Microsite</Table.HeaderCell>
//                 <Table.HeaderCell>Product</Table.HeaderCell>
//                 <Table.HeaderCell>Units</Table.HeaderCell>
//                 <Table.HeaderCell>Arrival Date</Table.HeaderCell>
//                 <Table.HeaderCell>Status</Table.HeaderCell>
//               </Table.Row>
//             </Table.Header>
//             <Table.Body>
//               { data.map( i => (
//                 <Table.Row key={i._id}>
//                   <Table.Cell>
//                     <Checkbox
//                       value={i._id}
//                       checked={props.selected[i._id] || false}
//                       onChange={props.handleSemanticCheckboxClick}
//                     />
//                   </Table.Cell>
//                   <Table.Cell>
//                   { i.microsite ? i.microsite.name : 'N/A' }
//                   </Table.Cell>
//                   <Table.Cell>
//                     { i.product ? `${i.product.key} - ${i.product.staticName}` : '' }
//                   </Table.Cell>
//                   <Table.Cell>
//                     { i.units }
//                   </Table.Cell>
//                   <Table.Cell>
//                     { i.arrivalDate }
//                   </Table.Cell>
//                   <Table.Cell>
//                     { i.status }
//                   </Table.Cell>
//                   <Table.Cell textAlign="right">
//                     <Button content="Receive"
//                       primary size={'mini'}
//                       onClick={() => this.setState({ ...this.state, isModalOpen: true, selectedTicket: i })}
//                     />
//                   </Table.Cell>
//                 </Table.Row>
//               ))}
//             </Table.Body>
//           </Table>
//           <ListViewPagination.Bottom
//             label={'Delete'}
//             {...props}
//           />
//         </Segment>
//         { this.state.isModalOpen &&
//         <ReceiveModal
//           open={this.state.isModalOpen}
//           handleSubmit={this.handleAddSubmit}
//           handleClose={this.handleAddClose}
//           incomingTicket={this.state.selectedTicket}
//           // lastLocation={this.state.}
//           // initialValues={this.state.editData}
//           // action={this.state.modalAction}
//         />
//         }
//       </div>
//     );
//   }
// }

// export default IndexWrapper(List, 'incomingTickets', {
//   query: {
//     $populate: ['microsite', 'product'],
//     $sort: { createdAt: -1 }
//   }
// });

import * as React from 'react';
import { Button, Popup } from 'semantic-ui-react';
import { RouteComponentProps } from 'react-router-dom';

import { ReceiveModal } from './ReceiveModal';

import { useList, ListPage, basicQueryMap } from '@inkcloud/components';
import feathers from './../../../../bootstrap/feathers';

interface ListProps {}

const filterQueryMap = (values) => {
  let filterRows = basicQueryMap(values);

  filterRows = {
    ...filterRows,
  };

  return filterRows;
};

const serviceName = 'inventory/incoming-tickets';
const pathPrefix = '/products/inventory/incoming-tickets';

const { useState } = React;

export const List: React.FunctionComponent<ListProps & RouteComponentProps<any>> = (props) => {
  const {
    match: { params },
    location,
    history,
  } = props;

  const useListValues = useList({
    feathers: [
      'find',
      serviceName,
      [
        {
          query: {
            $populate: ['microsite', 'product'],
            $sort: { createdAt: -1 },
          },
        },
      ],
    ],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (values: any) => {
    setErrorMessage('');
    try {
      const res: any = await feathers.service('/inventory').create({
        product: selectedTicket?.product?._id,
        operation: 'ADJUSTMENT',
        ...values,
      });

      // Update the incoming ticket now
      await feathers.service('/inventory/incoming-tickets').patch(selectedTicket?._id, {
        status: 'received',
      });

      setIsModalOpen(false);

      useListValues.reload();
    } catch (e) {
      const errMsg =
        e?.code < 500 ? e.message : 'We are experiencing technical difficulties. Please try again';
      setErrorMessage(errMsg);
    }
  };

  const tableDefintion = [
    {
      key: '',
      label: 'Microsite',
      cell: (v, r) => r?.microsite?.name,
    },
    {
      key: '',
      label: 'Product',
      cell: (v, r) => (
        <>
          {r?.product?.key ? `${r?.product?.key} -` : ''} {r?.product?.staticName}
        </>
      ),
    },
    {
      key: 'units',
      label: 'Units',
      cell: (v, r) => v,
    },
    {
      key: 'arrivalDate',
      label: 'Arrival Date',
      cell: (v, r) => v,
    },
    {
      key: 'status',
      label: 'Status',
      cell: (v, r) => v,
    },
    {
      key: '',
      label: '',
      cell: (v, r) => (
        <>
          <Popup
            content="Receive"
            trigger={
              <Button
                size="mini"
                icon="window restore outline"
                onClick={() => {
                  setSelectedTicket(r);
                  setIsModalOpen(true);
                }}
              />
            }
          />
        </>
      ),
      textAlign: 'right',
    },
  ];

  return (
    <>
      <ListPage
        header="Incoming Notifications"
        subheader="Inventory"
        useListValues={useListValues}
        history={history}
        pathPrefix={pathPrefix}
        tableDefintion={tableDefintion}
        withCheckboxes={true}
        headerActions={null}
      />

      {isModalOpen && (
        <ReceiveModal
          open={isModalOpen}
          handleSubmit={handleSubmit}
          handleClose={() => {
            setSelectedTicket(null);
            setIsModalOpen(false);
          }}
          incomingTicket={selectedTicket}
          errorMessage={errorMessage}
        />
      )}
    </>
  );
};
