import React, { useState, KeyboardEventHandler } from 'react';
import { Button, Form, Input, Dropdown, Message, Header, Segment } from 'semantic-ui-react';
import { Formik, Form as FormikForm, FieldArray, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import countries from 'world-countries';
import Creatable from 'react-select/creatable';
import { useSelector } from 'react-redux';

import { SemanticField } from '../../common/Form';
import { countryStateProvinces } from '../../common';
import Feathers from '../../bootstrap/feathers';

interface ContactFormProps {
  initialValues: any;
  onSubmit: Function;
  isSubmitting?: boolean;
  errorMessage?: string;
}

const components = {
  DropdownIndicator: null,
};

const createOption = (label: string) => ({
  label,
  value: label,
});

const initialMapper = (values) => ({
  email: values.email || '',
  organizationName: values.organizationName || '',
  billingClientName: values.billingClientName || '',
  billingClientId: values.billingClientId || '',
  primaryContact: {
    firstName: (values.primaryContact && values.primaryContact.firstName) || '',
    lastName: (values.primaryContact && values.primaryContact.lastName) || '',
  },
  address1: values.address1 || '',
  address2: values.address2 || '',
  city: values.city || '',
  stateProvince: values.stateProvince || '',
  postalCode: values.postalCode || '',
  phone: values.phone || '',
  countryCode: values?.countryCode ?? 'US',

  abn: values?.abn || '',
  platform: values.platform || '',
  metadata: values?.metadata || [],
  tags: values?.tags?.map ? values?.tags?.map((t) => ({ label: t, value: t })) : [],
});

export const ContactForm: React.FC<ContactFormProps> = (outerProps) => {
  const { errorMessage } = outerProps;

  const [inputValue, setInputValue] = React.useState('');
  // const [value, setValue] = React.useState<readonly Option[]>([]);

  const CustomerContactSchema = Yup.object().shape({
    email: Yup.string()
      .email('Invalid email')
      .required('Required')
      .trim()
      .test('is-not-used', `Email is already in use`, function (value) {
        if (
          outerProps.initialValues &&
          outerProps.initialValues._id &&
          outerProps.initialValues.email === value
        ) {
          return Promise.resolve(true);
        }

        return Feathers.service<any>('/customers')
          .find({ query: { email: value } })
          .then((results: any) => results.data.length === 0);
      }),
    address1: Yup.string().required('Required').trim(),
    address2: Yup.string().trim(),
    organizationName: Yup.string().trim(),
    city: Yup.string().required('Required').trim(),
    postalCode: Yup.string().required('Required').trim(),
    stateProvince: Yup.string().required('Required').trim(),
    primaryContact: Yup.object().shape({
      firstName: Yup.string().required('Required').trim(),
      lastName: Yup.string().required('Required').trim(),
    }),
    phone: Yup.string().trim(),
  });

  const tenant = useSelector(
    ({ globals }: { globals: { tenant: { internationalization: { countryCode: string } } } }) =>
      globals.tenant.internationalization
  );

  return (
    <Formik
      initialValues={initialMapper({
        ...outerProps.initialValues,
        countryCode: outerProps.initialValues?.countryCode || tenant.countryCode,
      })}
      validationSchema={CustomerContactSchema}
      onSubmit={(values, { setSubmitting }) => {}}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
          setFieldValue,
          setFieldTouched,
        } = props;

        const handleKeyDown: KeyboardEventHandler = (event) => {
          if (!inputValue) return;
          switch (event.key) {
            case 'Enter':
            case 'Tab':
              setFieldValue('tags', [...values.tags, createOption(inputValue)]);
              setInputValue('');
              event.preventDefault();
              break;
            default:
          }
        };

        return (
          <FormikForm
            className={`ui form ${outerProps.isSubmitting && 'loading'} ${
              errorMessage ? 'error' : ''
            }`}
          >
            <Form.Field>
              <SemanticField name={'email'} label="Email" component={Form.Input} />
              <ErrorMessage
                render={(msg) => <Message negative size="tiny" content={msg} />}
                name="email"
              />
            </Form.Field>

            <Form.Group widths="equal">
              <Form.Field>
                <label>Company Name</label>
                <Field name="organizationName" />
                {/* <SemanticField name={'organizationName'} label='Company Name' component={Form.Input} /> */}
              </Form.Field>

              <Form.Field>
                <label>Billing Client Name</label>
                <Field name="billingClientName" />
                {/* <SemanticField name={'organizationName'} label='Billing Client Name' component={Form.Input} /> */}
              </Form.Field>
              <Form.Field>
                <label>Billing Client ID</label>
                <Field name="billingClientId" />
                {/* <SemanticField name={'organizationName'} label='Billing Client ID' component={Form.Input} /> */}
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field>
                <SemanticField
                  name={'primaryContact.firstName'}
                  label="First Name"
                  component={Form.Input}
                />
                <ErrorMessage
                  render={(msg) => <Message negative size="tiny" content={msg} />}
                  name="primaryContact.firstName"
                />
              </Form.Field>

              <Form.Field>
                <SemanticField
                  name={'primaryContact.lastName'}
                  label="Last Name"
                  component={Form.Input}
                />
                <ErrorMessage
                  render={(msg) => <Message negative size="tiny" content={msg} />}
                  name="primaryContact.lastName"
                />
              </Form.Field>
            </Form.Group>

            <Form.Group widths="equal">
              <Form.Field>
                <SemanticField
                  name="countryCode"
                  label="Country"
                  selection
                  search
                  component={Form.Dropdown}
                  options={
                    countries?.map((c: any) => ({ text: c.name.official, value: c.cca2 })) ?? []
                  }
                  fluid
                />
              </Form.Field>
            </Form.Group>

            <Form.Group widths="equal">
              <Form.Field>
                <SemanticField name={'address1'} label="Address 1" component={Form.Input} />
                <ErrorMessage
                  render={(msg) => <Message negative size="tiny" content={msg} />}
                  name="address1"
                />
              </Form.Field>
              <Form.Field>
                <label>Address 2</label>
                <Field name="address2" />
                {/* <SemanticField name={'address2'} label='Address 2' component={Form.Input} /> */}
              </Form.Field>
            </Form.Group>

            <Form.Group widths="equal">
              <Form.Field>
                <SemanticField name={'city'} label="City" component={Form.Input} />
                <ErrorMessage
                  render={(msg) => <Message negative size="tiny" content={msg} />}
                  name="city"
                />
              </Form.Field>

              <Form.Field>
                {values?.countryCode === 'US' ||
                values?.countryCode === 'CA' ||
                values?.countryCode === 'AU' ? (
                  <>
                    <label>
                      {values?.countryCode === 'AU' ? 'State/Territory' : 'State/Province'}
                    </label>
                    <Dropdown
                      name={'stateProvince'}
                      options={countryStateProvinces(values?.countryCode)}
                      fluid
                      selection
                      value={values?.stateProvince ?? ''}
                      onChange={(e, data) => setFieldValue(data.name, data.value)}
                    />
                  </>
                ) : (
                  <SemanticField
                    name={'stateProvince'}
                    component={Form.Input}
                    fluid
                    label="State/Province"
                    placeholder="State/Province..."
                  />
                )}
                <ErrorMessage
                  render={(msg) => <Message negative size="tiny" content={msg} />}
                  name="stateProvince"
                />
              </Form.Field>
              <Form.Field>
                <SemanticField name={'postalCode'} label="Postal Code" component={Form.Input} />
                <ErrorMessage
                  render={(msg) => <Message negative size="tiny" content={msg} />}
                  name="postalCode"
                />
              </Form.Field>
              <Form.Field>
                <SemanticField name={'phone'} label="Phone" component={Form.Input} />
                <ErrorMessage
                  render={(msg) => <Message negative size="tiny" content={msg} />}
                  name="phone"
                />
              </Form.Field>

              <Form.Field>
                <SemanticField name={'abn'} label="ABN" component={Form.Input} />
                <ErrorMessage
                  render={(msg) => <Message negative size="tiny" content={msg} />}
                  name="abn"
                />
              </Form.Field>
            </Form.Group>

            <Form.Group widths="equal">
              <Form.Field>
                <SemanticField name={'platform'} label="Platform" component={Form.Input} />
                <ErrorMessage
                  render={(msg) => <Message negative size="tiny" content={msg} />}
                  name="platform"
                />
              </Form.Field>
            </Form.Group>

            <Form.Group widths="equal">
              <Form.Field>
                <label>Tags</label>
                <Creatable
                  components={components}
                  isClearable
                  isMulti
                  menuIsOpen={false}
                  inputValue={inputValue}
                  onChange={(newValue) => setFieldValue('tags', newValue)}
                  onInputChange={(newValue) => setInputValue(newValue)}
                  onKeyDown={handleKeyDown}
                  placeholder=""
                  value={values.tags}
                />
              </Form.Field>
            </Form.Group>

            <Header as="h4" attached="top">
              Metadata
            </Header>
            <Segment attached="bottom">
              {!values?.metadata?.length && (
                <Message content={'Metadata not defined. To add metadata click Add'} />
              )}
              <Form.Field>
                <FieldArray
                  name="metadata"
                  render={(arrayHelpers) => (
                    <div>
                      {values?.metadata?.map((custom, index) => (
                        <Form.Group widths="equal" key={index}>
                          <Form.Field>
                            <label>Key</label>
                            <Field name={`metadata[${index}].key`} />
                            {(errors?.metadata as any)?.[index]?.key && (
                              <Message
                                negative
                                size="tiny"
                                content={(errors?.metadata as any)?.[index]?.key}
                              />
                            )}
                          </Form.Field>
                          <Form.Field>
                            <label>Value</label>
                            <Field name={`metadata[${index}].value`} />
                          </Form.Field>
                          <div>
                            <div className="field">
                              <label>&nbsp;</label>
                              <Button
                                type="button"
                                icon="remove"
                                onClick={() => arrayHelpers.remove(index)}
                              />
                            </div>
                          </div>
                        </Form.Group>
                      ))}
                      <Button
                        type="button"
                        size="tiny"
                        content="Add"
                        onClick={() => arrayHelpers.push({ key: '' })}
                      />
                    </div>
                  )}
                />
              </Form.Field>
            </Segment>
            {errorMessage && <Message error content={errorMessage} />}
            <Button
              primary
              type="button"
              disabled={outerProps.isSubmitting}
              onClick={() => {
                const castValues = CustomerContactSchema.cast(values);
                outerProps.onSubmit(castValues);
              }}
            >
              Save
            </Button>
          </FormikForm>
        );
      }}
    </Formik>
  );
};
