import * as React from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  Button,
  Alert,
} from '@chakra-ui/react';
import {
  Font,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Image,
} from '@react-pdf/renderer';
import type { ModelTypes } from '@inkcloud/icapi-types';

import { ErrorBoundary } from 'react-error-boundary';

import { icapi } from '../../../../bootstrap/feathers';
import { loadComponents } from '../render-components';
import { stringToReactElement } from '../helpers';

interface IResultProps {
  documentType: ModelTypes.PrintableDocument['documentType'];
  source: string;
  styles: any;
  humanId?: string;
}

function documentTypeToServiceName(documentType: ModelTypes.PrintableDocument['documentType']) {
  switch (documentType) {
    case 'invoice':
    case 'sales-order':
      return 'orders';
    case 'packing-slip':
      return 'orders';
    case 'order-item-label':
      return 'order-items';
    case 'estimate':
      return 'rfqs';
    case 'purchase-order':
      return 'purchase-orders';
    default:
      return 'orders';
  }
}

export const Injector = (props: any) =>
  React.cloneElement(props.children, { _initialState: props.initialState });

const defaultStyles = StyleSheet.create({
  page: {
    // fontFamily: 'Source Sans Pro',
    padding: 20,
    fontSize: 12,
    fontWeight: 400,
  },
  header: {
    flexDirection: 'row',
    // backgroundColor: '#E4E4E4',
    margin: 10,
  },

  // Grid/Flex
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  rowItem: {
    flexBasis: 0,
    flexGrow: 1,
    maxWidth: '100%',
  },

  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  justifyEnd: {
    justifyContent: 'flex-end',
  },

  /* Typography */
  // headings
  h1: {
    fontSize: 40,
    fontWeight: 'bold',
  },
  h2: {
    fontSize: 30,
    fontWeight: 'bold',
  },
  h3: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  h4: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  // copy
  txBold: {
    fontWeight: 'bold',
  },
  // margins
  my3: {
    marginTop: 16,
    marginBottom: 16,
  },
  mb1: {
    marginBottom: 8,
  },
  mb2: {
    marginBottom: 12,
  },
  mb3: {
    marginBottom: 16,
  },
  // paddings
  p2: {
    padding: 12,
  },
  p3: {
    padding: 16,
  },
  py3: {
    paddingTop: 16,
    paddingBottom: 16,
  },
  // line heights
  lh2: {
    lineHeight: 1.85,
  },
  // borders
  border: {
    border: '1px solid inheret',
  },
  borderYellow: {
    borderColor: '#faebcc',
  },
  borderRounded: {
    borderRadius: 3,
  },
  infoRow: {
    flexDirection: 'row',
    // backgroundColor: '#E4E4E4',
    // margin: 10,
    // padding: 10,
  },
  infoRowColumn: {
    flexGrow: 1,
    fontSize: 13,
    maxWidth: '33.33%',
    // lineHeight: 24,
  },
  infoRowColumnHeader: {
    fontSize: 16,
    fontWeight: 700,
    marginBottom: 6,
  },

  section: {
    // margin: 10,
    // padding: 10,
    flexGrow: 1,
    maxWidth: '50%',
    flexDirection: 'column',
  },
  headerRight: {
    // flexGrow: 1,
    maxWidth: '50%',
    textAlign: 'center',
    marginLeft: 'auto',
    flexDirection: 'column',
    // justifyContent: 'flex-end',
    // alignContent: 'flex-end',
    alignItems: 'flex-end',
    fontSize: 12,
  },
  txRight: {
    textAlign: 'right',
  },
  segment: {
    border: '1px solid #000',
  },

  segmentHeading: {
    backgroundColor: '#ccc',
  },

  segmentHeader: {
    fontSize: 14,
    fontWeight: 'bold',
  },
});

// interface ErrorBoundaryProps {
//   children?: React.ReactNode
//   setErrorMessage: (message: string) => void
// }

// class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
//   state: { hasError: boolean; error?: any; errorInfo?: any }

//   constructor(props) {
//     super(props)
//     this.state = { hasError: false }
//   }

//   static getDerivedStateFromError(error) {
//     // Update state so the next render will show the fallback UI.
//     return { hasError: true }
//   }

//   componentDidCatch(error, errorInfo) {
//     // You can also log the error to an error reporting service
//     // logErrorToMyService(error, errorInfo);
//     console.log('@########', error, errorInfo)
//     this.props.setErrorMessage(error.message)
//   }

//   render() {
//     console.log('this.state.', this.state)
//     if (this.state.hasError) {
//       // You can render any custom fallback UI
//       return null
//     }

//     return this.props.children
//   }
// }
// Font.register({
//   family: 'Source Sans Pro',
//   fonts: [
//     {
//       src: 'https://fonts.gstatic.com/s/sourcesanspro/v14/6xK3dSBYKcSV-LCoeQqfX1RYOo3aPw.ttf',
//       fontWeight: 400,
//     },
//     {
//       src: 'https://fonts.gstatic.com/s/sourcesanspro/v14/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rAkA.ttf',
//       fontWeight: 600,
//     },
//   ],
//   format: 'truetype',
// })

interface HeaderProps {
  content: string;
}

export const Header = (props: HeaderProps) => {
  const { content } = props;
  return <Text {...props}>{content}</Text>;
};

const ErrorFallback = () => null;

const Result: React.FunctionComponent<IResultProps> = (props) => {
  const { documentType, source, styles: stylesIn } = props;
  const [resolvedData, setResolvedData] = React.useState<any>(null);
  const [entity, setEntity] = React.useState<any>(null);
  const [humanId, setHumanId] = React.useState('');

  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [errorResolverMessage, setErrorResolverMessage] = React.useState('');
  const [renderErrorMessage, setRenderErrorMessage] = React.useState('');

  const handleSubmit = async () => {
    // lookup the entity
    setErrorMessage('');
    setIsLoading(true);

    try {
      const entityResult = await (
        icapi.service(documentTypeToServiceName(documentType)) as any
      ).find({
        query: {
          humanId,
          $findOne: '1',
        },
      });

      setEntity(entityResult);
    } catch (e) {
      const errMsg = e?.code < 500 ? e?.message : 'An error occurred';
      setErrorMessage(errMsg);
    }

    setIsLoading(false);
  };

  React.useEffect(() => {
    if (props.humanId) {
      (async () => {
        try {
          const entityResult = await (
            icapi.service(documentTypeToServiceName(documentType)) as any
          ).find({
            query: {
              humanId: props.humanId,
              $findOne: '1',
            },
          });

          setEntity(entityResult);
        } catch (e) {
          const errMsg = e?.code < 500 ? e?.message : 'An error occurred';
          setErrorMessage(errMsg);
        }
      })();
    }
  }, [props.humanId]);

  // const Header = (props: any) => <Text style={props.style}>{props.content}</Text>

  React.useEffect(() => {
    const resolveData = async () => {
      if (!entity) {
        return;
      }

      setErrorResolverMessage('');

      try {
        const resolved = await icapi.service('printable-docs/resolver').create({
          documentType,
          renderParams: {
            id: entity._id,
          },
        });

        setResolvedData(resolved);
      } catch (e) {
        const errMsg = e?.code < 500 ? e?.message : 'An error occurred';
        setErrorResolverMessage(errMsg);
      }
    };

    resolveData();
  }, [documentType, entity]);

  let label = 'Order';

  if (documentType === 'order-item-label') {
    label = 'Order Item';
  }

  if (documentType === 'estimate') {
    label = 'RFQ';
  }

  if (documentType === 'purchase-order') {
    label = 'Purchase Order';
  }

  const Components = loadComponents({ documentType });

  const initialState = resolvedData
    ? { ...resolvedData, dataCtx: resolvedData?.data, styles: { ...defaultStyles, ...stylesIn } }
    : {};

  const deps = {
    React,
    ...Components,
    Document,
    Page,
    View,
    Text,
    Image,
    styles: stylesIn,
    Injector,
    initialState,
    Header,
  };

  const MemoizedElement = React.useMemo(() => {
    try {
      setRenderErrorMessage('');
      return stringToReactElement(source || resolvedData?.template, deps);
    } catch (error) {
      setRenderErrorMessage(error.message);
      return null;
    }
  }, [source, resolvedData, stylesIn]);

  return (
    <Box border={'1px solid #eaeaea'} p={8} mb={8} mt={8}>
      {!props.humanId && (
        <>
          <FormControl mb={3}>
            <FormLabel>{label} ID</FormLabel>
            <Input
              type="text"
              value={humanId}
              placeholder="Enter in an ID"
              onChange={(e) => setHumanId(e.target.value)}
            />
            {/* <FormHelperText></FormHelperText> */}
          </FormControl>
          {(errorMessage || errorResolverMessage) && (
            <Alert status="error" mb={3}>
              {errorMessage || errorResolverMessage}
            </Alert>
          )}
          <FormControl mb={3}>
            <Button isLoading={isLoading} isDisabled={isLoading} onClick={handleSubmit}>
              Load {label}
            </Button>
          </FormControl>
        </>
      )}

      {resolvedData && MemoizedElement && (
        <>
          <hr />
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onError={(err) => setErrorMessage(err.message)}
            onResetKeysChange={() => setErrorMessage('')}
            resetKeys={[source]}
          >
            <PDFViewer width="100%" height={500}>
              <MemoizedElement />
            </PDFViewer>
          </ErrorBoundary>
        </>
      )}
    </Box>
  );
};

export default Result;
