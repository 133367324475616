import * as React from 'react';
import { Page, Text, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';

export interface ShipToValue {
  name?: string;
  careOf: string;
  address1: string;
  address2: string;
  city: string;
  stateProvince: string;
  postalCode: string;
  countryCode: string;
}

export interface IShipToProps {
  style?: Style;
  textStyle?: Style;
  value: ShipToValue;
}

export function ShipTo(props: IShipToProps) {
  const { style = {}, textStyle = {}, value } = props;

  const { name, careOf, address1, address2, city, stateProvince, postalCode, countryCode } = value;

  return (
    <View style={style}>
      <Text style={textStyle}>{name}</Text>
      {careOf && <Text style={textStyle}>{careOf}</Text>}
      <Text style={textStyle}>{address1}</Text>
      {address2 && <Text style={textStyle}>{address2}</Text>}
      <Text style={textStyle}>
        {city} {stateProvince} {postalCode}
      </Text>
      <Text style={textStyle}>{countryCode}</Text>
    </View>
  );
}
