import { Operator, generateOperatorOptions } from '@inkcloud/components';
import Feathers from './../../../bootstrap/feathers';
import { optionLanguages } from './../../../common/translations/languages';

export async function getFilterDefinition() {
  let definition = [
    {
      label: 'Source Languages...',
      value: 'languages.source',
      operators: generateOperatorOptions([Operator.Is, Operator.IsNot]),
      valueOptions: optionLanguages.map((r) => ({ value: r.value, label: r.text })),
      type: 'multi-select',
    },
    {
      label: 'Destination Languages...',
      value: 'languages.destinations',
      operators: generateOperatorOptions([Operator.Is, Operator.IsNot]),
      valueOptions: optionLanguages.map((r) => ({ value: r.value, label: r.text })),
      type: 'multi-select',
    },

    {
      label: 'Product Category...',
      value: 'category',
      operators: generateOperatorOptions([Operator.Is, Operator.IsNot]),
      valueOptions: [],
      asyncValueOptions: async () =>
        Feathers.service('/products-legacy/categories')
          .find({
            query: {
              $np: 1,
              $sort: { name: 1 },
            },
          })
          .then((results) => results.map((r) => ({ value: r._id, label: r.name }))),
      type: 'multi-select',
    },

    {
      label: 'Status...',
      value: 'productionStatus.jobStatus',
      operators: generateOperatorOptions([Operator.Is, Operator.IsNot]),
      valueOptions: [],
      asyncValueOptions: async () =>
        Feathers.service('/order-item-statuses')
          .find({
            query: {
              $np: 1,
              $sort: { name: 1 },
            },
          })
          .then((results) => results.map((r) => ({ value: r._id, label: r.name }))),
      type: 'multi-select',
    },
  ];

  return definition;
}
