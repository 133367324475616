import * as React from 'react';
import { Button, Segment, Label, LabelDetail } from 'semantic-ui-react';
import { RouteComponentProps, Link } from 'react-router-dom';
import { PageHeaderRow, PreLoader, useFeathers } from '@inkcloud/components';
import { FormattedDate, FormattedTime } from 'react-intl';

import { parseISO, isValid } from 'date-fns';

import ProductForm from './ProductForm';
import { ProductTypeModal } from './ProductTypeModal';
import { DuplicateProductModal } from './DuplicateProductModal';
import { CreateVariantsModal } from './stock-product/CreateVariantsModal';

import Feathers from '../../../bootstrap/feathers';

const mapSubmit = (values) => {
  const mappedValues = {
    ...values,
    quantities: values.quantities ? values.quantities.map((q) => parseInt(q)) : null,
    workflowTemplate: values?.workflowTemplate || undefined,
    impositionTemplate: values?.impositionTemplate || undefined,
    $unset: {
      workflowTemplate: values?.workflowTemplate ? undefined : 1,
      impositionTemplate: values?.impositionTemplate ? undefined : 1,
    },
  };
  mappedValues.addOns = values.addOns ? values.addOns.map((a) => ({ id: a, isEnabled: true })) : [];
  if (values.isArtworkRequired) {
    mappedValues.artworkSlots = values.artworkSlots.map((a) => ({ sequence: a }));
  } else {
    mappedValues.artworkSlots = [];
  }

  if (values.quantity) {
    values.quantities.forEach((q) => {
      mappedValues;
    });
  }
  /// TODO .. MAP ATTRIBUTES TO PROPER SHAPE!!!!!!!!!!!!!!!!!!!!!!!!

  if (mappedValues.attributes) {
    mappedValues.attributes = Object.keys(mappedValues.attributes).map((a) => ({
      attribute: a,
      value: mappedValues.attributes[a],
    }));
  }

  // mappedValues.workflowTemplate = mappedValues.workflowTemplate || null;
  // mappedValues.impositionTemplate = mappedValues.impositionTemplate || null;

  // Remove all entries that are not in the quantities array
  // mappedValues.prices = mappedValues.prices ? mappedValues.prices.filter(p => mappedValues.quantities.includes(p.unitValue)) : [];

  // Sort Prices
  mappedValues.prices = mappedValues?.prices?.sort((a, b) =>
    a.unitValue < b.unitValue ? -1 : a.unitValue > b.unitValue ? 1 : 0
  );

  // if (mappedValues.quantities) {
  //   const quantitiesToAdd = mappedValues.quantities
  //     .filter(q => undefined === (mappedValues.prices.find(mv => mv.unitValue === q)));

  //   quantitiesToAdd
  //     .forEach(q => {
  //       mappedValues.prices.push({ unitType: 1, unitValue: q, price: 0.00 });
  //     });
  // }
  return mappedValues;
};
const serviceName = '/products-legacy';
const pathPrefix = `/products/products`;
const service = Feathers.service(serviceName);
const commonParams = {
  query: {
    $populate: ['images.contentImage', 'linkedStockProducts.product', 'associatedProducts.product'],
  },
};

const { useState } = React;

export const Detail: React.FunctionComponent<RouteComponentProps<{ id?: string }>> = (props) => {
  const {
    match: {
      params: { id },
    },
    location,
    history,
  } = props as any;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    submit: '',
  });

  const [isProductTypeModalOpen, setIsProductTypeModalOpen] = useState(false);
  const [isProductDuplicateModalOpen, setIsProductDuplicateModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isEdit = !!id;
  const [state, reload] = useFeathers('get', serviceName, [id, commonParams], true);

  if (isEdit && !state.data) {
    return <PreLoader />;
  }

  const initialValues = {
    ...state.data,
  };

  if (state.data) {
    initialValues.isArtworkRequired = state.data.artworkSlots.length > 0;
    initialValues.attributes = state.data.attributes.reduce((prev, cur) => {
      prev[cur.attribute] = cur.value;
      return prev;
    }, {});
    (initialValues.artworkSlots = state.data.artworkSlots
      ? state.data.artworkSlots.map((a) => a.sequence)
      : []),
      (initialValues.quantities = state.data.prices
        ? state.data.prices.map((p) => String(p.unitValue))
        : []);
    initialValues.addOns = initialValues.addOns
      ? initialValues.addOns.filter((a) => a.isEnabled).map((a) => a.id)
      : [];
  }

  if (!props.match.params.id) {
    initialValues.isShippable = true;
    initialValues.isArtworkRequired = true;
    initialValues.artworkSlots = ['FRONT'];
    initialValues.isCustomTrimEnabled = false;
    initialValues.triggerOrderApproval = false;
  }

  const handleSubmit = async (values: any) => {
    setErrorMessage({ ...errorMessage, submit: '' });

    const payload = {
      ...values,
      key: values?.key?.replace(/-+$/, ''), // Trim (-) hyphen from end of text
      productionChannel: values?.productionChannel || null,
      glIncomeAccount: values?.glIncomeAccount || null,
      glExpenseAccount: values?.glExpenseAccount || null,
      isTaxable: values?.isTaxable ?? true,
      startAt: isValid(values?.startAt) ? values?.startAt : parseISO(values?.startAt) || null,
      expireAt: isValid(values?.expireAt) ? values?.expireAt : parseISO(values?.expireAt) || null,
      linkedStockProducts: values?.linkedStockProducts?.map((lsp) => ({
        ...lsp,
        product: lsp?.product?.value ?? lsp?.product,
      })),
      associatedProducts: values?.associatedProducts?.map((ap) => ({
        ...ap,
        product: ap?.product?.value ?? ap?.product,
      })),
    };

    try {
      setIsSubmitting(true);
      const res = isEdit
        ? await service.patch(id, payload, commonParams)
        : await service.create(payload, commonParams);

      if (res.result !== undefined && !res.result) {
        setErrorMessage(res.message || res.errorMessage);
      }

      history.replace({
        pathname: pathPrefix,
        state: {
          ...(location?.state || {}),
        },
      });
      setIsSubmitting(false);
    } catch (e) {
      setIsSubmitting(false);
      const errMsg =
        e.code && e.code < 500
          ? e.message
          : 'We are experiencing technical difficulties. Please try again';
      setErrorMessage({ ...errorMessage, submit: errMsg });
    }
  };

  return (
    <div>
      <PageHeaderRow
        header={isEdit ? 'Edit' : 'New'}
        subheader="Product"
        subheaderId={isEdit && state.data.humanId}
      >
        {isEdit && <>
          <Label name='Created'  outline size="small"  >
              Created
              <LabelDetail><FormattedDate value={initialValues?.createdAt} /></LabelDetail>
          </Label>
            <Button
            size="mini"
            onClick={() => setIsProductDuplicateModalOpen(true)}
            content="Duplicate Product"
          />


        </>}
        <Button
          size="mini"
          onClick={() => setIsProductTypeModalOpen(true)}
          content="Change Product Type"
        />

        <Button content="Create Variants" size={'mini'} onClick={() => setIsModalOpen(true)} />
        <Link
          to={{
            // pathname: '/products/products',
            state: {
              ...(location?.state || {}),
            },
          }}
          onClick={() => history.goBack()}
        >
          <Button content="Back" size={'mini'} />
        </Link>
      </PageHeaderRow>
      <Segment loading={isSubmitting}>
        <ProductForm
          initialValues={isEdit ? initialValues : { isTaxable: true }}
          onSubmit={(values) => handleSubmit(mapSubmit(values))}
          isEdit={!!props.match.params.id}
          errorMessage={errorMessage.submit}
        />
      </Segment>
      {isEdit && isProductTypeModalOpen && (
        <ProductTypeModal
          isOpen={isProductTypeModalOpen}
          onClose={() => setIsProductTypeModalOpen(false)}
          product={state.data}
          redirect={pathPrefix}
          history={history}
        />
      )}
      {isProductDuplicateModalOpen && (
        <DuplicateProductModal
          isOpen={isProductDuplicateModalOpen}
          onClose={() => setIsProductDuplicateModalOpen(false)}
          product={state.data}
        />
      )}
      {isEdit && isModalOpen && (
        <CreateVariantsModal
          open={isModalOpen}
          product={state.data}
          handleClose={() => setIsModalOpen(false)}
          handleSubmit={() => {
            setIsModalOpen(false);
            history.push('/products/products');
          }}
        />
      )}
    </div>
  );
};

// export default DetailWrapper(Detail, 'products', {
//   query: {
//     $populate: ["images.contentImage"]
//   },
//   submitRedirect: '/products/products',
// });
