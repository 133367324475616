import React, { useState, useEffect } from 'react';

import {
  Spinner,
  Flex,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Checkbox,
} from '@chakra-ui/react';
import type { ModelTypes } from '@inkcloud/icapi-types';

import { Feathers } from '../../../bootstrap/feathers/feathers';

interface IStatusModalProps {
  filledFields: { columnID?: string; name: string; priority: number }[];
  add: ({ id, name, columnID, priority }) => void;
  onClose: () => void;
}

export default function StatusModal(props: IStatusModalProps) {
  const { filledFields, add, onClose } = props;

  const [statuses, setStatuses] = useState<NonNullable<ModelTypes.JobStatuses>[]>([]);
  const [checkItems, setCheckItems] = useState<{ [k: string]: { id: string; name: string } }>({});

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const loadStatuses = async () => {
      setIsLoading(true);
      try {
        const res = await Feathers.service('order-item-statuses').find({
          query: {
            $np: '1',
            $sort: { priority: 1 },
          },
        });

        setStatuses(res);

        const t = res?.reduce((acc, cur) => {
          const found = filledFields?.find((f) => f.columnID === cur._id);
          if (found) {
            acc[cur._id] = { id: cur._id, name: cur.name };
          }
          return acc;
        }, {});

        setCheckItems(t);
      } catch (error) {
        console.error(error);
      }

      setIsLoading(false);
    };

    loadStatuses();
  }, []);

  const isAllChecked = Object.keys(checkItems).length === statuses.length;

  const handleToggleCheckAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    if (checked) {
      const t: {} = statuses?.reduce((acc, cur) => {
        acc[cur?._id ?? ''] = { id: cur?._id, name: cur.name };

        return acc;
      }, {});

      setCheckItems(t);
    } else {
      setCheckItems({});
    }
  };

  const handleToggleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, name, value } = e.target;
    if (checked) {
      setCheckItems((prev) => ({ ...prev, [value]: { id: value, name } }));
    } else {
      const newItems = { ...(checkItems ?? {}) };
      delete newItems[name];
      setCheckItems(newItems);
    }
  };

  const handleAdd = () =>
    Object.keys(checkItems)
      .filter((k) => !filledFields.find((f) => f.columnID === k))
      .map((k, index) =>
        add({
          id: checkItems[k].id,
          name: checkItems[k].name,
          columnID: k,
          priority: filledFields?.length + (index + 1),
        })
      );

  const statusesTemplate =
    statuses?.length > 0 ? (
      <>
        <Checkbox mb={3} isChecked={isAllChecked} onChange={handleToggleCheckAll}>
          <Text as="b">{isAllChecked ? 'Unselect' : 'Select'} All</Text>
        </Checkbox>
        {statuses.map((status, index: number) => (
          <Flex key={index} mb={3} justifyContent="space-between">
            <Checkbox
              name={status?.name ?? ''}
              value={status?._id ?? ''}
              isChecked={!!checkItems[status?._id ?? '']}
              onChange={handleToggleCheck}
            >
              {status.name}
            </Checkbox>
          </Flex>
        ))}
      </>
    ) : (
      <Text>No statuses found</Text>
    );

  return (
    <Modal isOpen onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Status Book</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {isLoading ? (
            <Flex justifyContent="center">
              <Spinner />
            </Flex>
          ) : (
            statusesTemplate
          )}
        </ModalBody>
        <ModalFooter>
          <Button type="button" mr={3} onClick={onClose}>
            Close
          </Button>
          <Button type="button" colorScheme="blue" onClick={handleAdd}>
            Add
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
