import * as React from 'react';
import { Label } from 'semantic-ui-react';

interface Product {
  humanId: string;
  inventory: number;
  attributes: any[];
  key: string;
  category: {
    name: string;
  };
  staticName: string;
}

export const ProductName: React.FunctionComponent<Product> = (item, hasLabel = false) => {
  const isStock = item.inventory;

  return (
    <>
      <Label basic color={'grey'} size={'mini'}>
        {item?.key}
      </Label>{' '}
      {item?.category?.name}{' '}
      {isStock
        ? item.staticName
        : item.attributes?.map((ea, index) =>
            hasLabel ? (
              <Label
                basic
                color={'blue'}
                key={ea?.value?._id}
                size={'mini'}
                style={{ marginBottom: '.1em' }}
              >
                {' '}
                {ea.value.name}{' '}
              </Label>
            ) : (
              <React.Fragment key={ea?.value?._id}>
                {ea?.value?.name} {item.attributes?.length - 1 === index && ' - '}
              </React.Fragment>
            )
          )}
    </>
  );
};
