import React from 'react';
import JsBarcode from 'jsbarcode';

import { Image } from '@react-pdf/renderer';

export default function BarCode({ value }) {
  const canvas = document.createElement('canvas');
  JsBarcode(canvas, value);
  const barcode = canvas.toDataURL();
  return <Image src={barcode} style={{ maxWidth: '100%' }} />;
}
