import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import { RouteComponentProps } from 'react-router-dom';
import {
  PageHeaderRow,
  PreLoader,
  GenericFilter,
  useList,
  basicQueryMap,
} from '@inkcloud/components';
import type { ServiceTypes } from '@inkcloud/icapi-types';
import { Segment, Icon } from 'semantic-ui-react';
import { CSVLink } from 'react-csv';

import { getFilterDefinition } from '../products/FilterForm2';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

type ICEntity<T extends keyof ServiceTypes> = Awaited<ReturnType<ServiceTypes[T]['get']>>;
type Category = {
  name?: string;
};
type ProductsLegacy = ICEntity<'products-legacy'> & { category: Category };

// todo extends types of category

// type ProductsLegacyCategory = Awaited<ReturnType<ServiceTypes['products-legacy/categories']['get']>>

const filterQueryMap = (values) => {
  let filterRows = basicQueryMap(values, {
    name: 'staticName',
  });

  // Map attributes as needed by API
  const prefix = 'attributes:';
  const attributes = Object.keys(filterRows)
    .filter((r) => r.startsWith(prefix))
    .reduce((acc, cur) => ({ ...acc, [cur.slice(prefix.length)]: filterRows[cur]?.$in }), {});

  Object.keys(filterRows)
    .filter((r) => r.startsWith(prefix))
    .forEach((r) => delete filterRows[r]);

  filterRows = {
    ...filterRows,
    addOns: filterRows.addOns
      ? { $elemMatch: { id: filterRows.addOns, isEnabled: true } }
      : undefined,
    attributes,

    type: undefined,
  };

  return filterRows;
};

const updatedFilterDefinition = async () => {
  const def = await getFilterDefinition();
  return def?.filter((d) => d.value !== 'type');
};

const { useState, useEffect, useMemo } = React;

const serviceName = 'products-legacy';

const Inventory = (props: RouteComponentProps) => {
  const {
    match: { params },
    location,
    history,
  } = props;
  const {
    state,
    reload,
    handleDelete,
    selectedItems,
    clearAll,
    setChecked,
    setSelectedItems,
    someSelected,
    toggleAll,
    filter,
    setFilter,
    sort,
    handleSort,
  } = useList({
    feathers: [
      'find',
      serviceName,
      [
        {
          query: {
            $populate: ['attributes.value', 'category', 'parent'],
            $populateAddOns: 1,
            inventory: true,
            $np: 1,
          },
        },
      ],
    ],
    filterMap: filterQueryMap,
    debounce: 250,
    location,
    history,
    sort: { createdAt: -1 },
  });

  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      width: 130,
    }),
    []
  );

  const colDef = [
    { field: 'key', headerName: 'SKU' },
    { field: 'staticName', headerName: 'Name' },
    { field: 'category', headerName: 'Category' },
    { field: 'type', headerName: 'Owned By' },
    {
      field: 'unitOfMeasure',
      headerName: 'UOM',
      cellStyle: { background: '' },
      type: '',
    },
    {
      field: 'physicalCount',
      headerName: 'On Hand',
      cellStyle: { background: '' },
      type: 'rightAligned',
    },
    {
      field: 'allocatedCount',
      headerName: 'Allocated SOs',
      cellStyle: { background: '' },
      type: 'rightAligned',
    },
    {
      field: 'openPOCount',
      headerName: 'Open POs',
      cellStyle: { background: '' },
      type: 'rightAligned',
    },
    {
      field: 'availableCount',
      headerName: 'Avail',
      cellStyle: { background: '' },
      type: 'rightAligned',
    },
    {
      field: 'inventoryLowThreshold',
      headerName: 'Low Qty',
      cellStyle: { background: '' },
      type: 'rightAligned',
    },
    {
      field: 'ro',
      headerName: 'RO?',
      cellStyle: { background: '' },
      type: 'rightAligned',
      // cellRendererFramework: (param) => (
      //   <span>{param?.value ? <Icon name="flag" color="red" /> : null}</span>
      // )
    },
    {
      field: 'isHidden',
      headerName: 'Active?',
    },
  ];

  const headers = colDef.map((c) => ({ key: c.field, label: c.headerName }));

  const { queryResult }: any = state || [];
  const { isLoading }: any = state;

  useEffect(() => {
    setRowData([]);

    if (queryResult?.length) {
      const mappedData = queryResult.map((d: ProductsLegacy) => ({
        ...d,
        type: d?.isCustomerOwned !== false ? 'Customer' : 'Self',
        category: d?.category?.name ?? '',
        physicalCount: d?.inventoryCount + d?.allocatedCount || 0,
        availableCount: d?.inventoryCount || 0,
        ro: d?.inventoryLowThreshold > d?.inventoryCount ? 'Yes' : null,
        isHidden: d.isHidden === true ? 'InActive' : 'Active',
      }));

      setColumnDefs(colDef);
      setRowData(mappedData);
    }
  }, [queryResult]);

  return (
    <>
      <PageHeaderRow header="Inventory" subheader="" />
      <Segment>
        <GenericFilter
          isAutoFocus={true}
          factDefinitionFunction={updatedFilterDefinition}
          value={filter}
          onChange={(v) => {
            setFilter({ ...v });
          }}
        />
      </Segment>
      {filter && (
        <>
          {isLoading ? (
            <PreLoader />
          ) : (
            <Segment>
              <div>
                <CSVLink
                  className="ui button small primary"
                  data={rowData}
                  filename="Inventory.csv"
                  headers={headers}
                  target="_blank"
                >
                  Download as CSV{' '}
                </CSVLink>
              </div>
              <br />
              <div className="ag-theme-alpine" style={{ height: 400 }}>
                <AgGridReact
                  rowData={rowData}
                  defaultColDef={defaultColDef}
                  columnDefs={columnDefs}
                />
              </div>
            </Segment>
          )}
        </>
      )}
    </>
  );
};

export default Inventory;
