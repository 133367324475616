import * as React from 'react';
import Feathers from './../../../bootstrap/feathers';
import { Link } from 'react-router-dom';
import { Table, Popup, Button } from 'semantic-ui-react';
import { EmptyWrapper } from '@inkcloud/components';

interface IPagePublishProps {
  productId: string;
  publishedToMicrosites: any[];
}

const { useEffect, useState } = React;

export const PagePublish: React.FunctionComponent<IPagePublishProps> = (props) => {
  const { productId, publishedToMicrosites } = props;

  const [pages, setPages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const loadPages = async () => {
      try {
        setIsLoading(true);
        const res = await Promise.all(
          publishedToMicrosites?.map((i) =>
            Feathers.service('/store-front/microsites/content/pages').find({
              query: {
                $np: '1',
                product: productId,
                microsite: i?.id,
              },
            })
          )
        );
        setPages(res);
      } catch (e) {}

      setIsLoading(false);
    };

    if (publishedToMicrosites) {
      loadPages();
    }
  }, [publishedToMicrosites]);

  return (
    <EmptyWrapper queryResult={pages} isStateLoading={isLoading}>
      <Table size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>URL</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {pages?.map((page) =>
            page?.map((p: any, index: number) => (
              <Table.Row key={index}>
                <Table.Cell>{p.name}</Table.Cell>
                <Table.Cell>{p.url}</Table.Cell>
                <Table.Cell textAlign="right">
                  <Popup
                    size="mini"
                    content="Edit"
                    trigger={
                      <Link to={`/settings/microsites/${p?.microsite}/content/pages/${p?._id}`}>
                        <Button size={'mini'} icon={'edit'} />
                      </Link>
                    }
                  />
                </Table.Cell>
              </Table.Row>
            ))
          )}
        </Table.Body>
      </Table>
    </EmptyWrapper>
  );
};
