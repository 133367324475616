import * as React from 'react';
import { Input, Form, Button, Message } from 'semantic-ui-react';
import Feathers from './../../../bootstrap/feathers';

interface CostCenterProps {
  cart: any;
}

const { useState, useEffect } = React;

export const CostCenter: React.FunctionComponent<CostCenterProps> = (props) => {
  const { cart } = props;
  const [costCenter, setCostCenter] = useState((cart && cart.costCenter) || '');
  const [costCenterOptions, setCostCenterOptions] = useState([]);
  const [errorMessage, setErrorMessage] = useState({ costCenter: '', settings: '' });
  const [responseMessage, setResponseMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const loadOptions = async () => {
      if (cart && cart.microsite) {
        const res: any = await Feathers.service('/microsites/').get(cart.microsite, { query: {} });
        setCostCenterOptions(res.costCenters);
      } else {
        if (cart && cart.tenant) {
          try {
            const res: any = await Feathers.service('/tenant-settings/general/').get(cart.tenant, {
              query: {},
            });
            if (res) {
              setCostCenterOptions(res.costCenter && res.costCenter.costCenterOptions);
            }
          } catch (e) {
            if (e.code && e.code < 500) {
              if (e.code === 404) {
                setErrorMessage({ ...errorMessage, settings: 'No Cost Center' });
              } else {
                setErrorMessage({ ...errorMessage, settings: e.message });
              }
            } else {
              setErrorMessage({
                ...errorMessage,
                settings: 'We are experiencing technical difficulties. Please try again',
              });
            }
          }
        }
      }
    };

    loadOptions();
  }, [cart]);

  const handleSubmit = async () => {
    const payload = {
      microsite: cart.microsite,
      cartId: cart._id,
      costCenter,
    };

    if (cart && !cart.microsite) {
      delete payload.microsite;
    }

    try {
      setErrorMessage({ ...errorMessage, costCenter: '' });
      setIsSubmitting(true);
      const res: any = await Feathers.service('/cart/apply-cost-center').create(payload, {
        query: {},
      });
      setIsSubmitting(false);
      if (res) {
        setResponseMessage(res.message);
      }
    } catch (e) {
      setIsSubmitting(false);
      if (e.code && e.code < 500) {
        setErrorMessage({ ...errorMessage, costCenter: e.message });
      } else {
        setErrorMessage({
          ...errorMessage,
          costCenter: 'We are experiencing technical difficulties. Please try again',
        });
      }
    }
  };

  const ccOptions =
    (costCenterOptions &&
      costCenterOptions.length > 0 &&
      costCenterOptions.map((c) => ({
        text: `${c.code ? c.code : c}`,
        value: `${c.code ? c.code : c}`,
      }))) ||
    [];

  return (
    <>
      {errorMessage.settings && <Message size="tiny" warning content={errorMessage.settings} />}
      <Form loading={isSubmitting}>
        <Form.Dropdown
          search
          selection
          clearable
          name="po"
          value={costCenter}
          options={ccOptions}
          onChange={(e, data) => setCostCenter(data.value as any)}
        />
        <Form.Button
          primary
          size="mini"
          disabled={!costCenter}
          onClick={handleSubmit}
          content="Submit"
        />
      </Form>
      {errorMessage.costCenter && <Message size="tiny" error content={errorMessage.costCenter} />}
      {responseMessage && <Message size="tiny" success content={responseMessage} />}
    </>
  );
};
