import * as React from 'react';
import { PageSize, Orientation } from '@react-pdf/types';
import { EstimateDocumentContext } from './EstimateDocument';

export const EstimateContext = React.createContext<EstimateContextInterface>(null);

interface IEstimateProviderProps {
  children?: React.ReactNode;
  initialState: any;
}

interface EstimateContextInterface {
  dataCtx: EstimateDocumentContext;
  styles: any;
  config: {
    size: PageSize;
    orientation: Orientation;
  };
}

const config: EstimateContextInterface['config'] = {
  size: 'A4',
  orientation: 'portrait',
};

export const EstimateProvider: React.FunctionComponent<IEstimateProviderProps> = (props) => {
  const { children, initialState } = props;

  const defaultCtx = initialState;
  return <EstimateContext.Provider value={defaultCtx}>{children}</EstimateContext.Provider>;
};
