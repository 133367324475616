import * as React from 'react';
import { Button, Input, Dropdown, Segment, Form } from 'semantic-ui-react';
import { FeathersDropdown } from '@inkcloud/components';
import Feathers from './../../../bootstrap/feathers';
const { useState, useEffect } = React;

export interface FilterFormProps {
  value: any;
  onChange: Function;
  onReset: Function;
}

function initMapper(value) {
  const defaults = {
    status: '',
    paper: '',
    numUps: '',
    searchOrderItemHumanId: '',
  };

  return Object.keys(defaults).reduce((p, cur) => {
    if (!p[cur]) {
      p[cur] = defaults[cur];
    }
    return p;
  }, value);
}

const statusOptions = [
  { text: 'Awaiting Artwork', value: 'awaiting-artwork' },
  { text: 'Canceled', value: 'canceled' },
  { text: 'Pending Dispatch', value: 'pending-dispatch' },
  { text: 'Queued At Press', value: 'queued-at-press' },
  { text: 'Received At Press', value: 'received-at-press' },
  { text: 'Printing Complete', value: 'print-complete' },
  { text: 'Printing Aborted', value: 'print-aborted' },
  { text: 'Cutting Complete', value: 'cutting-complete' },
  { text: 'In Progress', value: 'in-progress' },
  { text: 'Status Draft', value: 'STATUS_DRAFT' },
  { text: 'Status Final', value: 'STATUS_FINAL' },
  { text: 'Status Dispatched', value: 'STATUS_DISPATCHED' },
];

export function FilterForm(props: FilterFormProps) {
  const { value: preInitValue } = props;

  const value = initMapper(preInitValue);

  const [attributes, setAttributes] = useState([]);

  useEffect(() => {
    const loadAttributes = async () => {
      const res: any = await Feathers.service('/products-legacy/attributes').find({
        query: {
          $np: 1,
          $sort: { name: 1 },
          entityAttribute: { $ne: 'QUANTITY' },
          isDeleted: { $ne: true },
        },
      });
      setAttributes(res);
    };

    loadAttributes();
  }, []);

  const papers =
    attributes?.reduce((acc, cur) => {
      const found = acc.some((a) => a.name === cur.name);

      if (!found) {
        acc.push(cur);
      }

      return acc;
    }, []) ?? [];

  const onChange = (field, value) => {
    props.onChange && props.onChange({ ...props.value, [field]: value });
  };

  return (
    <Segment>
      <Form size={'small'}>
        <Form.Group widths={'equal'}>
          <Form.Field>
            <Dropdown
              selection
              fluid
              clearable
              placeholder="Select Job Data's Status..."
              options={statusOptions}
              value={value.status}
              onChange={(e, data) => onChange('status', data.value)}
            />
          </Form.Field>
          <Form.Field>
            <Dropdown
              selection
              fluid
              clearable
              search
              placeholder="Paper..."
              options={papers?.map((p) => ({ text: p.name, value: p._id })) ?? []}
              value={value.paper}
              onChange={(e, data) => onChange('paper', data.value)}
            />
          </Form.Field>
          <Form.Field>
            <Input
              value={value.searchOrderItemHumanId}
              placeholder="Order Item ID..."
              onChange={(e, data) => onChange('searchOrderItemHumanId', data.value)}
            />
          </Form.Field>
          <Form.Field>
            <Input
              value={value.numUps}
              placeholder="Num Ups..."
              onChange={(e, data) => onChange('numUps', data.value)}
            />
          </Form.Field>
        </Form.Group>

        <Form.Field>
          <Button size={'tiny'} content="Reset Form" onClick={() => props.onReset()} />
        </Form.Field>
      </Form>
    </Segment>
  );
}
