import React from 'react';
import Cleave from 'cleave.js/react';
import { Input } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { InputWrapper, currencySymbol } from '../../../../../common';

const inputStyle: React.CSSProperties = {
  // flex: '0 1 50px',
  width: '100%',
  border: '1px solid #eaeaea',
  display: 'block',
  padding: '.5em',
  textAlign: 'right',
};

export interface INumberInputProps {
  value: number;
  onChange: (value: string) => void;
  onBlur?: (value: string) => void;
  isMoney?: boolean;
  isDisabled?: boolean;
  decimal?: number;
}

const roundDecimal = (v: any, decimal: number = 2) => Number.parseFloat(v).toFixed(decimal);

const handleFocus = (event) => event.target.select();

export function NumberInput(props: INumberInputProps) {
  const { value, onChange, onBlur, isMoney = true, isDisabled = false, decimal = 2 } = props;
  const [isFocused, setIsFocused] = React.useState(false);
  const tenant = useSelector(
    ({
      globals,
    }: {
      globals: { tenant: { internationalization: { currency: string; locale: string } } };
    }) => globals.tenant.internationalization
  );

  return (
    <>
      <InputWrapper>
        <Cleave
          style={{
            width: '100%',
            padding: 5,
            textAlign: 'right',
            border: isFocused ? '1px solid var(--chakra-colors-gray-500)' : undefined,
            backgroundColor: isFocused ? 'white' : 'transparent',
            outline: 'none',
            borderRadius: 'var(--chakra-radii-md)',
          }}
          className=""
          options={{
            numeral: true,
            rawValueTrimPrefix: true,
            numeralPositiveOnly: true,
            numeralThousandsGroupStyle: 'thousand',
            prefix: isMoney ? currencySymbol(tenant?.currency, tenant?.locale) : undefined,
          }}
          value={value}
          isDisabled={isDisabled}
          onChange={(e) => onChange(e.target.rawValue || 0)}
          onFocus={(e) => {
            handleFocus(e);
            setIsFocused(true);
          }}
          onBlur={(e) => {
            let formattedValue: any = roundDecimal(value, isMoney ? decimal : 0);

            if (
              isMoney &&
              `${Number(formattedValue)}` !== `${roundDecimal(formattedValue, decimal)}`
            ) {
              formattedValue = roundDecimal(formattedValue, decimal);
            }

            const newValue = isNaN(Number(formattedValue)) ? 0 : formattedValue;
            setIsFocused(false);
            onChange(newValue);
            if (onBlur) {
              onBlur(newValue);
            }
          }}
        />
      </InputWrapper>
      {/* <Input
        style={{ paddingTop: 4, paddingBottom: 4 }}
        borderColor={isFocused ? 'gray.500' : undefined}
        borderWidth={isFocused ? 1 : undefined}
        bgColor={isFocused ? 'white' : undefined}
        size={'sm'}
        variant={'unstyled'}
        py={'10'}
        textAlign={'right'}
        type="text"
        value={value}
        // onFocus={handleFocus}
        disabled={isDisabled}
        onChange={(e) => onChange(e.target.value)}
        onFocus={(e) => {
          handleFocus(e);
          setIsFocused(true);
        }}
        onBlur={(e) => {
          let formattedValue: any = roundDecimal(value, isMoney ? decimal : 0);

          if (
            isMoney &&
            `${Number(formattedValue)}` !== `${roundDecimal(formattedValue, decimal)}`
          ) {
            formattedValue = roundDecimal(formattedValue, decimal);
          }

          const newValue = isNaN(Number(formattedValue)) ? 0 : formattedValue;
          setIsFocused(false);
          onChange(newValue);
          if (onBlur) {
            onBlur(newValue);
          }
        }}
      /> */}
    </>
  );
}
