import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Formik, Form as FormikForm, Field, FieldArray, FieldProps } from 'formik';
import { Segment, Form, Message, Button } from 'semantic-ui-react';
import { EmailForm } from './EmailForm';

interface ApprovalEmailFormProps {
  initialValue: any;
  users: any;
  errorMessage: any;
  isLoading: boolean;
  onSubmit: (v: any) => void;
}

export const ApprovalEmailForm: React.FunctionComponent<ApprovalEmailFormProps> = (props) => {
  const { initialValue, users, errorMessage, isLoading, onSubmit } = props as any;

  return (
    <Segment>
      {(errorMessage.customers || errorMessage.settings || errorMessage.microsites) && (
        <Message
          error
          content={errorMessage.customers || errorMessage.settings || errorMessage.microsites}
        />
      )}
      <Formik
        initialValues={initialValue || {}}
        enableReinitialize={true}
        onSubmit={(values: any) => {
          if (values.type === 'admins') {
            delete values.email;
          }
          onSubmit(values);
        }}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            setFieldValue,
            setFieldTouched,
          } = props as any;

          return (
            <FormikForm className={`ui form ${isLoading && 'loading'}`}>
              <Form.Group inline>
                <Form.Radio
                  label="All admin users"
                  name="type"
                  value={values && values.type}
                  checked={values && (values.type === undefined || values.type === 'admins')}
                  onBlur={handleBlur}
                  onChange={() => setFieldValue('type', 'admins')}
                />

                <Form.Radio
                  label="Selected users"
                  name="type"
                  value={values && values.type}
                  checked={values && values.type === 'explicit'}
                  onBlur={handleBlur}
                  onChange={() => setFieldValue('type', 'explicit')}
                />
              </Form.Group>
              {values && values.type === 'explicit' && (
                <>
                  <EmailForm
                    values={values && values.email && values.email.to}
                    action="to"
                    title="To Recipients"
                    users={users}
                    setFieldValue={setFieldValue}
                  />
                  <EmailForm
                    values={values && values.email && values.email.cc}
                    action="cc"
                    title="CC Recipients"
                    users={users}
                    setFieldValue={setFieldValue}
                  />
                  <EmailForm
                    values={values && values.email && values.email.bcc}
                    action="bcc"
                    title="BCC Recipients"
                    users={users}
                    setFieldValue={setFieldValue}
                  />
                </>
              )}
              <Button primary content="Submit" />
            </FormikForm>
          );
        }}
      </Formik>
    </Segment>
  );
};
