import * as React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { FormattedDate } from 'react-intl';
import { Segment, Table, Message } from 'semantic-ui-react';
import { useList, PageHeaderRow, EmptyWrapper } from '@inkcloud/components';

import * as numeral from 'numeral';
import { FilterForm } from '../FilterForm';

interface ListProps {}

const filterQueryMap = (values) => {};

const serviceName = '/reports';

export const List: React.FunctionComponent<ListProps & RouteComponentProps<any>> = (props) => {
  const {
    match: { params },
    location,
    history,
  } = props;
  const {
    state,
    reload,
    handleDelete,
    selectedItems,
    clearAll,
    setChecked,
    someSelected,
    toggleAll,
    filter,
    setFilter,
    sort,
    handleSort,
  } = useList({
    feathers: ['find', serviceName, [{ query: { action: 'agingBalances' } }]],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: {},
  });

  const { queryResult, isLoading: isStateLoading } = state as any;

  return (
    <>
      <PageHeaderRow header="Aging" subheader="Report" />

      <Message
        info
        content={'This report is limited to invoices created within two years of today'}
      />
      <EmptyWrapper
        queryResult={queryResult && queryResult.customers}
        isStateLoading={isStateLoading}
      >
        <Segment loading={state.isLoading}>
          <Table size={'small'} sortable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Customers</Table.HeaderCell>
                <Table.HeaderCell>0-30 Days</Table.HeaderCell>
                <Table.HeaderCell>31-60 Days</Table.HeaderCell>
                <Table.HeaderCell>61-90 Days </Table.HeaderCell>
                <Table.HeaderCell>91+ Days </Table.HeaderCell>
                <Table.HeaderCell>Total</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {queryResult &&
                (queryResult as any).customers.length > 0 &&
                (queryResult as any).customers
                  .filter((r) => !(r.totalBalances > -0.02 && r.totalBalances < 0.02))
                  .map((r: any, index: number) => (
                    <Table.Row key={index}>
                      <Table.Cell>
                        <Link to={`/customers/${r.customerId}`}>{r.name}</Link>
                      </Table.Cell>
                      <Table.Cell>
                        <Link
                          to={`/accounting/open-invoices/${r.customerId}?action=agingBalances&range=balance0To30days`}
                        >
                          {numeral(r.balance0To30days).format('$0,0.00')}
                        </Link>
                      </Table.Cell>
                      <Table.Cell>
                        <Link
                          to={`/accounting/open-invoices/${r.customerId}?action=agingBalances&range=balance31To60days`}
                        >
                          {numeral(r.balance31To60days).format('$0,0.00')}
                        </Link>
                      </Table.Cell>
                      <Table.Cell>
                        <Link
                          to={`/accounting/open-invoices/${r.customerId}?action=agingBalances&range=balance61To90days`}
                        >
                          {numeral(r.balance61To90days).format('$0,0.00')}
                        </Link>
                      </Table.Cell>
                      <Table.Cell>
                        <Link
                          to={`/accounting/open-invoices/${r.customerId}?action=agingBalances&range=balance91daysOrMore`}
                        >
                          {numeral(r.balance91daysOrMore).format('$0,0.00')}
                        </Link>
                      </Table.Cell>
                      <Table.Cell>{numeral(r.totalBalances).format('$0,0.00')}</Table.Cell>
                    </Table.Row>
                  ))}
              {queryResult && queryResult.balancesSummary && (
                <Table.Row>
                  <Table.Cell>
                    <strong>Total</strong>
                  </Table.Cell>
                  <Table.Cell>
                    {numeral(queryResult.balancesSummary.balance0To30days).format('$0,0.00')}
                  </Table.Cell>
                  <Table.Cell>
                    {numeral(queryResult.balancesSummary.balance31To60days).format('$0,0.00')}
                  </Table.Cell>
                  <Table.Cell>
                    {numeral(queryResult.balancesSummary.balance61To90days).format('$0,0.00')}
                  </Table.Cell>
                  <Table.Cell>
                    {numeral(queryResult.balancesSummary.balance91daysOrMore).format('$0,0.00')}
                  </Table.Cell>
                  <Table.Cell>
                    {numeral(queryResult.balancesSummary.totalBalances).format('$0,0.00')}
                    {}
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </Segment>
      </EmptyWrapper>
    </>
  );
};
