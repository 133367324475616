import * as React from 'react';
import { Button, Form, Input, Dropdown, Header, Table } from 'semantic-ui-react';
import {
  Formik,
  Form as FormikForm,
  Field,
  ErrorMessage,
  FormikProps,
  FieldProps,
  FieldArray,
  ArrayHelpers,
} from 'formik';
import * as Yup from 'yup';
import { FeathersDropdown, useFeathers } from '@inkcloud/components';
import DatePicker from 'react-datepicker';
import { SemanticField } from '../../../common/Form';

interface OutsourceFormProps {
  initialValues: any;
  onSubmit: Function;
  isSubmitting?: boolean;
}

const initialValuesMapper = (v) => {
  return {
    ...v,
    totalCost: v.totalCost || '',
    po: v.po || '',
    notes: v.notes || '',
    dueDate: v.dueDate || '',
    vendor: v.vendor || '',

    items: v.items ? v.items.map((i) => i.orderItem) : [],
  };
};

const submissionMapper = (v) => {
  return {
    ...v,
    items: v.items.map((i) => ({ orderItem: i })),
  };
};

export const OutsourceForm: React.FC<OutsourceFormProps> = (props) => {
  const CustomerContactSchema = Yup.object().shape({
    vendor: Yup.string().required('Required'),
    // key: Yup.string().required('Required'),
  });

  return (
    <Formik
      initialValues={initialValuesMapper(props.initialValues)}
      validationSchema={CustomerContactSchema}
      onSubmit={(values, { setSubmitting }) => {
        props.onSubmit(submissionMapper(values));
      }}
    >
      {(props: FormikProps<any>) => {
        const {
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
          setFieldValue,
          setFieldTouched,
        } = props;

        return (
          <FormikForm className={`ui form ${isSubmitting && 'loading'}`}>
            <Form.Field>
              <label>Vendor</label>
              <Field
                name="vendor"
                render={({ field }: FieldProps<any>) => (
                  <FeathersDropdown
                    search
                    selection
                    placeholder="Vendor..."
                    serviceName="/production/vendors"
                    query={{ $sort: { companyName: 1 } }}
                    resultMapper={(r) => ({ value: r._id, text: r.companyName })}
                    {...field}
                    onChange={(e, data) =>
                      field.onChange({ target: { value: data.value, name: 'vendor' } } as any)
                    }
                  />
                )}
              />
              <ErrorMessage component="div" name="vendor" />
            </Form.Field>
            <Form.Field>
              <SemanticField
                name={'totalCost'}
                label="Total Cost"
                component={Form.Input}
                className="ui fluid"
                inline={false}
                icon="money"
                iconPosition="left"
                fieldLabel={'Total Cost'}
                placeholderText="0.00..."
              />
            </Form.Field>

            <SemanticField
              name={'po'}
              label="PO/Reference"
              component={Form.Input}
              className="ui fluid"
              inline={false}
            />

            <SemanticField
              name={'notes'}
              label="Notes"
              component={Form.TextArea}
              className="ui fluid"
              placeholder="Leave additional notes here..."
            />

            <Header as="h5">Select one or more order items</Header>
            <OrderSelector
              value={values.items}
              onChange={(v) => {
                setFieldValue('items', v);
              }}
            />
            <Button primary type="submit" disabled={isSubmitting}>
              Save
            </Button>
          </FormikForm>
        );
      }}
    </Formik>
  );
};

interface OrderSelectorProps {
  value: any[];
  onChange: (value: any[]) => void;
}

export const OrderSelector: React.FunctionComponent<OrderSelectorProps> = (props) => {
  const { onChange, value } = props;
  const [state, reload] = useFeathers('find', 'order-items', [
    {
      query: {
        $populate: ['category', 'attributes.value'],
        'productionStatus.jobStatus': { $in: ['OK_TO_PRINT', 'FILES_RECEIVED'] },
        $limit: 100,
      },
    },
  ]);

  const sortedOrderItems = (state?.queryResult as any)?.data
    ?.map((i: any) => ({
      ...i,
      priority: parseInt(i.humanId),
    }))
    ?.sort((a, b) => a.priority - b.priority);

  return (
    <Table compact celled definition size="small">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell />
          <Table.HeaderCell>ID</Table.HeaderCell>
          <Table.HeaderCell>Category</Table.HeaderCell>
          <Table.HeaderCell>Description</Table.HeaderCell>
          <Table.HeaderCell>Job Name</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {sortedOrderItems?.map((r) => (
          <Table.Row key={r._id}>
            <Table.Cell collapsing>
              <input
                type="checkbox"
                value={r._id}
                checked={!!value.find((v) => v === r._id)}
                onChange={(e) => {
                  let updatedValue = [...value];
                  if (e.target.checked) {
                    if (!updatedValue.find((p) => p === r._id)) {
                      updatedValue = updatedValue.concat([r._id]);
                    }
                  } else {
                    updatedValue = updatedValue.filter((p) => p !== r._id);
                  }

                  onChange(updatedValue);
                }}
              />
            </Table.Cell>
            <Table.Cell>{r.humanId}</Table.Cell>
            <Table.Cell>{r.category ? r.category.name : 'Custom'}</Table.Cell>
            <Table.Cell>
              {r.attributes && r.attributes.map((a) => a.value.name).join(' ')} {r.quantity}{' '}
              {r.description}
            </Table.Cell>
            <Table.Cell>{r.customerJobName}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};
