import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Segment, Message } from 'semantic-ui-react';
import { PageHeaderRow, PreLoader, useFeathers } from '@inkcloud/components';
import feathers from './../../../bootstrap/feathers';
import { PageForm } from './PageForm';

interface DetailProps {
  products: { value: string; text: string }[];
  categories: { value: string; text: string }[];
}

const serviceName = 'store-front/microsites/content/pages';
const service = feathers.service(serviceName);
const commonParams = { query: {} };

const { useState } = React;

export const Detail: React.FunctionComponent<
  DetailProps & RouteComponentProps<{ id?: string; micrositeId?: string }>
> = (props) => {
  const {
    match: {
      params: { id, micrositeId },
    },
    history,
    products,
    categories,
  } = props;
  const isEdit = !!id;
  const [state, reload] = useFeathers('get', serviceName, [id, commonParams], true);

  const [errorMessage, setErrorMessage] = useState('');

  const pathPrefix = `/settings/microsites/${micrositeId}/content/pages`;

  const handleSubmit = async (values) => {
    // if (values.type === 'basic') {
    //   delete values.product;
    //   delete values.category;
    // }

    // if (values.type === 'category') {
    //   delete values.product;
    // }

    // if (values.type === 'product') {
    //   delete values.category;
    // }

    // if (values.type === 'product-calculator') {
    //   delete values.product;
    // }

    // if (values.type === 'product-tags') {
    //   delete values.product;
    //   delete values.category;
    // }

    // if (values.type !== 'product-tags' && values.type !== 'category') {
    //   values.querySort = []
    // }

    const url = values?.url?.replace(/-+$/, ''); // Trim (-) hyphen from end of text

    values.url = url?.charAt(0) === '/' ? url : `/${url}`;

    try {
      setErrorMessage('');
      isEdit
        ? await service.patch(id, values, commonParams)
        : await service.create(values, commonParams);
      history.push(pathPrefix);
    } catch (e) {
      e.code && e.code < 500
        ? setErrorMessage(e.message)
        : setErrorMessage('We are experiencing technical difficulties. Please try again');
    }
  };

  const pageTitle = isEdit ? 'Edit' : 'New';

  if (isEdit && !state.data) {
    return <PreLoader />;
  }

  const initialData = { ...state.data, microsite: micrositeId };
  return (
    <div>
      <PageHeaderRow header={`${pageTitle} Page`} subheader="Microsite" subheaderId={''}>
        <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
      </PageHeaderRow>
      <Segment>
        {(!isEdit || state.data) && (
          <PageForm
            micrositeId={micrositeId}
            products={products}
            errorMessage={errorMessage}
            categories={categories}
            initialValues={initialData}
            onSubmit={handleSubmit}
          />
        )}
        {errorMessage && <Message error content={errorMessage} />}
      </Segment>
    </div>
  );
};
