import React, { useState } from 'react';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Alert,
} from '@chakra-ui/react';
import type { ModelTypes } from '@inkcloud/icapi-types';

import { Feathers } from '../../../bootstrap/feathers/feathers';
import UploadMisc from '../../../common/UploadMisc';
import type { AttachmentType } from '../Detail';

interface IAttachDocModalProps {
  orderId: string;
  docType: string;
  toUpdate: boolean;
  attachments: ModelTypes.Orders['attachments'];
  onClose: () => void;
  onUpdateAttachment: (v: any) => void;
  // onReload: () => void;
}

export default function AttachDocModal(props: IAttachDocModalProps) {
  const { orderId, docType, attachments, toUpdate, onClose, onUpdateAttachment } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [uploadLog, setUploadLog] = useState('');

  const handleSubmit = async () => {
    setErrorMessage('');
    setIsSubmitting(true);

    try {
      // if (toUpdate) {
      //   const res = Feathers.service('orders/attachments').update(orderId, {
      //     uploadLog,
      //   });
      // } else {

      const res = await Feathers.service('orders').patch(
        orderId,
        {
          attachments: [
            ...(attachments || []),
            {
              label: docType,
              orderId,
              uploadLog,
            },
          ],
        },
        {
          query: {
            $populate: ['customer', 'attachments.uploadLog'],
          },
        }
      );
      // const res = await Feathers.service('orders/attachments').create(
      //   {
      //     label: docType,
      //     orderId,
      //     uploadLog,
      //   },
      //   {
      //     query: {
      //       $populate: ['customer', 'attachments.uploadLog'],
      //     },
      //   }
      // );
      // }
      onUpdateAttachment(res?.attachments);
      //  onReload();

      onClose();
    } catch (e) {
      const errMsg = e?.code < 500 ? e?.message : 'Something went wrong. Please try again.';
      setErrorMessage(errMsg);
    }
    setIsSubmitting(false);
  };

  return (
    <Modal isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Upload {docType} Document</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <UploadMisc
            onUpload={(file) => setUploadLog(file?.uploadLog)}
            isMultiple={false}
            isDraggable={false}
            btnLabel="Upload"
          />

          {errorMessage && (
            <Alert status="error" mt={3}>
              {errorMessage}
            </Alert>
          )}
        </ModalBody>

        <ModalFooter>
          <Button mr={3} isDisabled={isSubmitting} onClick={onClose}>
            Close
          </Button>
          <Button isDisabled={!uploadLog || isSubmitting} colorScheme="blue" onClick={handleSubmit}>
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
