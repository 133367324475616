import * as React from 'react';
import { Text, HStack } from '@chakra-ui/react';
import { Context } from './PropertyList';

interface IPropertyListPropertyProps {
  label: string;
  value: string | React.ReactNode;
}

const PropertyListProperty: React.FunctionComponent<IPropertyListPropertyProps> = (props) => {
  const { label, value } = props;
  const { fontSize } = React.useContext(Context);

  const isReact = React.isValidElement(value);
  return (
    <HStack spacing={4} mb={2}>
      <dt>
        <Text color={'gray.600'} fontSize={fontSize} textTransform={'uppercase'} width={'30%'} minWidth={'90px'}>{label}</Text>
      </dt>
      <dd style={{width:'100%'}}>
        { isReact ? value : <Text fontSize={fontSize}>{value}</Text> }
      </dd>
    </HStack>
  );
};

export default PropertyListProperty;
