import * as React from 'react';
import { Button, Form, Message, Dropdown } from 'semantic-ui-react';
import { Formik, Form as FormikForm, FieldArray, Field, FieldProps, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { SemanticField } from '../../../common/Form';
import { FeathersDropdown } from '@inkcloud/components';
import { slugify2 as slugifyUrl } from './../../../common';

interface PageFormProps {
  initialValues: any;
  onSubmit: Function;
  isSubmitting?: boolean;
  products: { value: string; text: string }[];
  categories: { value: string; text: string }[];
  errorMessage: string;
  micrositeId?: string;
}

declare var ENV;

export const initialMapper = (values) => ({
  ...values,
  name: values.name || '',
  title: values.title || '',
  template: values.template || '',
  url: values.url || '',
  type: values.type || 'basic',
  product: values.product || '',
  productTags: values.productTags || [],
});

const querySortFieldOptions = [
  { value: 'key', key: 'key', text: 'SKU' },
  { value: 'name', key: 'name', text: 'Name' },
  { value: 'priority', key: 'priority', text: 'Priority' },
];

const directionOptions = [
  { value: 1, key: 1, text: 'Ascending' },
  { value: -1, key: -1, text: 'Descending' },
];

// setFieldValue('category', undefined)
// setFieldValue('querySort', undefined)
// setFieldValue('productTags', undefined)
// setFieldValue('product', undefined)

const submitMapper = (values: any) => {
  switch (values.type) {
    case 'basic':
      values.category = null;
      values.querySort = [];
      values.productTags = [];
      values.product = null;
      break;
    case 'category':
      // values.productTags = []
      values.product = null;
      break;
    case 'product-tags':
      values.category = null;
      values.product = null;
      break;
    case 'category-or-tags':
      values.product = null;
      break;
    case 'product':
      values.productTags = [];
      values.category = null;
      values.querySort = [];
      break;
    default:
      values.productTags = [];
      values.product = null;
      values.querySort = [];
  }

  return values;
};

export const PageForm: React.FC<PageFormProps> = (outerProps) => {
  const { micrositeId } = outerProps;

  const PageScheme = Yup.object().shape({
    name: Yup.string().required('Required'),
    url: Yup.string().required('Required'),
    template: Yup.string().required('Required'),
    type: Yup.string(),
    // querySort: Yup.array().of(
    //   Yup.object().shape({
    //     field: Yup.string().when("type", {
    //       is: value => value === 'category',
    //       then: Yup.string().required(
    //         "Required"
    //       ),
    //       otherwise: Yup.string()
    //     }),
    //     direction: Yup.string().when("type", {
    //       is: value => value === 'category',
    //       then: Yup.string().required(
    //         "Required"
    //       ),
    //       otherwise: Yup.number()
    //     }),
    //   })
    // ) todo check validation
  });

  return (
    <Formik
      initialValues={initialMapper(outerProps.initialValues)}
      validationSchema={PageScheme}
      onSubmit={(values, { setSubmitting }) => {
        outerProps.onSubmit(submitMapper(values));
      }}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
          setFieldValue,
          setFieldTouched,
        } = props;

        const handleAddition = (e, data: any) => {
          const foundTag = values?.productTags?.find((tag) => tag === data.value);

          if (!foundTag) {
            setFieldValue('productTags', [...(values.productTags || []), data.value]);
          }
        };

        const handlePopulateCategory = (e: string) => {
          if (e) {
            const found: any = outerProps?.categories?.find((c: any) => c._id === e);

            setFieldValue('name', found?.name);
            setFieldValue('title', found?.name);
            setFieldValue('url', `/products/${found?.hrCode ?? ''}`);
          }
        };

        const handlePopulateProduct = (e: string) => {
          if (e) {
            const found: any = outerProps?.products?.find((c: any) => c._id === e);

            setFieldValue('name', found?.staticName);
            setFieldValue('title', found?.staticName);
            setFieldValue('url', `/product/${found?.key ?? ''}`);
          }
        };

        const handleSlugify = (v) => setFieldValue('url', slugifyUrl(v, true));

        return (
          <FormikForm
            className={`ui form ${isSubmitting && !outerProps.errorMessage && 'loading'}`}
          >
            <Form.Field>
              <label>Page Type</label>
              <Form.Group inline>
                <Form.Radio
                  label="Basic"
                  name="type"
                  value={values.type}
                  checked={values.type === 'basic'}
                  onBlur={handleBlur}
                  onChange={() => setFieldValue('type', 'basic')}
                />
                <Form.Radio
                  label="List - Category"
                  name="type"
                  value={values.type}
                  checked={values.type === 'category'}
                  onBlur={handleBlur}
                  onChange={() => setFieldValue('type', 'category')}
                />
                <Form.Radio
                  label="List - Product Tags"
                  name="type"
                  value={values.type}
                  checked={values.type === 'product-tags'}
                  onBlur={handleBlur}
                  onChange={() => setFieldValue('type', 'product-tags')}
                />
                <Form.Radio
                  label="List - Category OR Tags"
                  name="type"
                  value={values.type}
                  checked={values.type === 'category-or-tags'}
                  onBlur={handleBlur}
                  onChange={() => setFieldValue('type', 'category-or-tags')}
                />
                <Form.Radio
                  label="Product"
                  name="type"
                  value={values.type}
                  checked={values.type === 'product'}
                  onBlur={handleBlur}
                  onChange={() => setFieldValue('type', 'product')}
                />
                <Form.Radio
                  label="Calculator"
                  name="type"
                  value={values.type}
                  checked={values.type === 'product-calculator'}
                  onBlur={handleBlur}
                  onChange={() => setFieldValue('type', 'product-calculator')}
                />
                <ErrorMessage
                  render={(msg) => <Message negative size="tiny" content={msg} />}
                  name="type"
                />
              </Form.Group>
            </Form.Field>

            <Form.Group widths="equal">
              {(values.type === 'category' ||
                values.type === 'product-calculator' ||
                values.type === 'category-or-tags') && (
                <div className="field">
                  <SemanticField
                    label="Product Category"
                    name={'category'}
                    component={Form.Dropdown}
                    selection
                    search
                    clearable
                    lazyLoad
                    options={outerProps?.categories?.map((r: any) => ({
                      value: r?._id ?? '',
                      text: `${r?.name ?? ''}${r?.hrCode ? ` - ${r.hrCode}` : ''}`,
                    }))}
                    fluid
                    placeholder="Category..."
                    onChange={handlePopulateCategory}
                  />
                </div>
              )}
              {values.type === 'product' && (
                <div className="field">
                  <SemanticField
                    name={'product'}
                    component={Form.Dropdown}
                    selection
                    search
                    clearable
                    lazyLoad
                    options={outerProps?.products?.map((r: any) => ({
                      value: r?._id ?? '',
                      text: `${r?.staticName ?? ''}${r?.key ? ` - ${r.key}` : ''}`,
                    }))}
                    fluid
                    placeholder="Product..."
                    onChange={handlePopulateProduct}
                  />
                </div>
              )}

              {(values.type === 'category' ||
                values.type === 'product-tags' ||
                values.type === 'category-or-tags') && (
                <SemanticField
                  name={'productTags'}
                  label="Product Tags"
                  component={Form.Dropdown}
                  selection
                  search
                  clearable
                  multiple
                  allowAdditions
                  options={
                    values?.productTags?.map((tag: string) => ({
                      text: tag,
                      value: tag,
                      key: tag,
                    })) || []
                  }
                  onAddItem={handleAddition}
                  noResultsMessage="Not found"
                />
              )}
            </Form.Group>

            {(values.type === 'product-tags' ||
              values.type === 'category' ||
              values.type === 'category-or-tags') && (
              <FieldArray
                name="querySort"
                render={(arrayHelpers) => (
                  <div>
                    <label>Product Sort Order</label>
                    {values?.querySort && values?.querySort?.length > 0 ? (
                      values.querySort.map((querySort, index) => (
                        <Form.Group widths="equal" key={index}>
                          <div className="field">
                            <SemanticField
                              name={`querySort[${index}].field`}
                              component={Form.Dropdown}
                              selection
                              search
                              clearable
                              options={querySortFieldOptions}
                              fluid
                              placeholder="Sort by..."
                            />
                            <ErrorMessage
                              render={(msg) => <Message negative size="tiny" content={msg} />}
                              name={`querySort[${index}].field`}
                            />
                          </div>
                          <div className="field">
                            <SemanticField
                              name={`querySort[${index}].direction`}
                              component={Form.Dropdown}
                              selection
                              search
                              options={directionOptions}
                              fluid
                              placeholder="Sort..."
                            />
                            <ErrorMessage
                              render={(msg) => <Message negative size="tiny" content={msg} />}
                              name={`querySort[${index}].direction`}
                            />
                          </div>
                        </Form.Group>
                      ))
                    ) : (
                      <Form.Group widths="equal" key={0}>
                        <div className="field">
                          <SemanticField
                            name={`querySort[${0}].field`}
                            component={Form.Dropdown}
                            selection
                            search
                            clearable
                            options={querySortFieldOptions}
                            fluid
                            placeholder="Sort by..."
                          />
                          <ErrorMessage
                            render={(msg) => <Message negative size="tiny" content={msg} />}
                            name={`querySort[${0}].field`}
                          />
                        </div>
                        <div className="field">
                          <SemanticField
                            name={`querySort[${0}].direction`}
                            component={Form.Dropdown}
                            selection
                            search
                            options={directionOptions}
                            placeholder="Sort..."
                            fluid
                          />
                          <ErrorMessage
                            render={(msg) => <Message negative size="tiny" content={msg} />}
                            name={`querySort[${0}].direction`}
                          />
                        </div>
                      </Form.Group>
                    )}
                  </div>
                )}
              />
            )}

            {(values.type === 'category' || values.type === 'category-or-tags') && (
              <FieldArray
                name="subCategorySort"
                render={(arrayHelpers) => (
                  <div>
                    <label>Sub Category Sort Order</label>
                    {values?.subCategorySort && values?.subCategorySort?.length > 0 ? (
                      values.subCategorySort.map((subCategorySort, index) => (
                        <Form.Group widths="equal" key={index}>
                          <div className="field">
                            <SemanticField
                              name={`subCategorySort[${index}].field`}
                              component={Form.Dropdown}
                              selection
                              search
                              clearable
                              options={querySortFieldOptions}
                              fluid
                              placeholder="Product Sort By..."
                            />
                            <ErrorMessage
                              render={(msg) => <Message negative size="tiny" content={msg} />}
                              name={`subCategorySort[${index}].field`}
                            />
                          </div>
                          <div className="field">
                            <SemanticField
                              name={`subCategorySort[${index}].direction`}
                              component={Form.Dropdown}
                              selection
                              search
                              options={directionOptions}
                              fluid
                              placeholder="Product Sort Direction..."
                            />
                            <ErrorMessage
                              render={(msg) => <Message negative size="tiny" content={msg} />}
                              name={`subCategorySort[${index}].direction`}
                            />
                          </div>
                        </Form.Group>
                      ))
                    ) : (
                      <Form.Group widths="equal" key={0}>
                        <div className="field">
                          <SemanticField
                            name={`subCategorySort[${0}].field`}
                            component={Form.Dropdown}
                            selection
                            search
                            clearable
                            options={querySortFieldOptions}
                            fluid
                            placeholder="Sub Category Sort By..."
                          />
                          <ErrorMessage
                            render={(msg) => <Message negative size="tiny" content={msg} />}
                            name={`subCategorySort[${0}].field`}
                          />
                        </div>
                        <div className="field">
                          <SemanticField
                            name={`subCategorySort[${0}].direction`}
                            component={Form.Dropdown}
                            selection
                            search
                            options={directionOptions}
                            placeholder="Sub Category Sort Direction..."
                            fluid
                          />
                          <ErrorMessage
                            render={(msg) => <Message negative size="tiny" content={msg} />}
                            name={`subCategorySort[${0}].direction`}
                          />
                        </div>
                      </Form.Group>
                    )}
                  </div>
                )}
              />
            )}

            {values.type && (
              <>
                <Form.Group widths="equal">
                  <Form.Field>
                    <SemanticField name={'name'} label="Name" component={Form.Input} />
                    <ErrorMessage
                      render={(msg) => <Message negative size="tiny" content={msg} />}
                      name="name"
                    />
                  </Form.Field>
                  <Form.Field>
                    <SemanticField name={'title'} label="Title" component={Form.Input} />
                    <ErrorMessage
                      render={(msg) => <Message negative size="tiny" content={msg} />}
                      name="title"
                    />
                  </Form.Field>
                </Form.Group>

                <Form.Group widths="equal">
                  <Form.Field>
                    {ENV === 'development' || ENV === 'staging' ? (
                      <SemanticField name={'template'} label="Template" component={Form.Input} />
                    ) : (
                      <>
                        <label>Template</label>
                        <Field
                          name="template"
                          render={({ field }: FieldProps<any>) => (
                            <FeathersDropdown
                              fluid
                              search
                              selection
                              serviceName="/store-front/microsites/content/pages/list"
                              query={{ microsite: micrositeId, $sort: { name: 1 }, $np: 1 }}
                              resultMapper={(r) => ({ value: r, text: r })}
                              {...field}
                              onChange={(e, data: any) =>
                                field.onChange({
                                  target: { value: data.value, name: 'template' },
                                } as any)
                              }
                            />
                          )}
                        />
                      </>
                    )}
                    <ErrorMessage
                      render={(msg) => <Message negative size="tiny" content={msg} />}
                      name="template"
                    />
                  </Form.Field>
                  <Form.Field>
                    <SemanticField
                      name={'url'}
                      label="URL"
                      component={Form.Input}
                      onChange={handleSlugify}
                    />
                    <ErrorMessage
                      render={(msg) => <Message negative size="tiny" content={msg} />}
                      name="url"
                    />
                  </Form.Field>
                </Form.Group>
              </>
            )}
            <Form.Field>
              <SemanticField
                name="requireAuth"
                label="Require Authentication"
                toggle
                component={Form.Checkbox}
              />
            </Form.Field>

            <Button primary type="submit" disabled={isSubmitting && !outerProps.errorMessage}>
              Save
            </Button>
          </FormikForm>
        );
      }}
    </Formik>
  );
};
