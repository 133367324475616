import * as React from 'react';
import { Button, Form, Input, Dropdown } from 'semantic-ui-react';
import { Formik, Form as FormikForm, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { SemanticField } from '../../../common/Form';
import { usStateProvinces } from '../../../common';

interface AddressBookFormProps {
  initialValues: any;
  onSubmit: Function;
  isSubmitting?: boolean;
}

export const AddressBookForm: React.FC<AddressBookFormProps> = (props) => {
  const CustomerContactSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    address1: Yup.string().required('Required'),
    city: Yup.string().required('Required'),
    stateProvince: Yup.string().required('Required'),
    postalCode: Yup.string().required('Required'),
  });

  return (
    <Formik
      initialValues={props.initialValues}
      validationSchema={CustomerContactSchema}
      onSubmit={(values, { setSubmitting }) => {
        props.onSubmit(values);
      }}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
          setFieldValue,
          setFieldTouched,
        } = props;

        return (
          <FormikForm className={`ui form ${isSubmitting && 'loading'}`}>
            <Form.Group widths="equal">
              <Form.Field>
                <SemanticField name={'name'} label="Name" component={Form.Input} />
                <ErrorMessage component="div" name="name" />
              </Form.Field>
              <Form.Field>
                <SemanticField name={'careOf'} label="Care Of" component={Form.Input} />
                <ErrorMessage component="div" name="careOf" />
              </Form.Field>
            </Form.Group>

            <Form.Group widths="equal">
              <Form.Field>
                <SemanticField name={'address1'} label="Address 1" component={Form.Input} />
                <ErrorMessage component="div" name="address1" />
              </Form.Field>
              <Form.Field>
                <SemanticField name={'address2'} label="Address 2" component={Form.Input} />
                <ErrorMessage component="div" name="address2" />
              </Form.Field>
            </Form.Group>

            <Form.Group widths="equal">
              <Form.Field>
                <SemanticField name={'city'} label="City" component={Form.Input} />
                <ErrorMessage component="div" name="city" />
              </Form.Field>

              <Form.Field>
                <SemanticField
                  name={'stateProvince'}
                  component={Form.Dropdown}
                  options={usStateProvinces}
                  fluid
                  selection
                  label="State/Province"
                  placeholder="State/Province..."
                />
                <ErrorMessage component="div" name="stateProvince" />
              </Form.Field>
              <Form.Field>
                <SemanticField name={'postalCode'} label="Postal Code" component={Form.Input} />
                <ErrorMessage component="div" name="postalCode" />
              </Form.Field>
              <Form.Field>
                <SemanticField name={'phone'} label="Phone" component={Form.Input} />
                <ErrorMessage component="div" name="phone" />
              </Form.Field>
              <Form.Field>
                <SemanticField name={'fax'} label="Fax" component={Form.Input} />
                <ErrorMessage component="div" name="fax" />
              </Form.Field>
            </Form.Group>
            <Form.Field>
              <SemanticField name={'costCenter'} label="Cost Center" component={Form.Input} />
              <ErrorMessage component="div" name="costCenter" />
            </Form.Field>

            <Button primary type="submit" disabled={isSubmitting}>
              Save
            </Button>
          </FormikForm>
        );
      }}
    </Formik>
  );
};
