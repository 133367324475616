import React, { forwardRef, useState, useEffect, useRef, useImperativeHandle } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Text,
  Alert,
  Box,
  Input,
  Stack,
  Select,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Button,
} from '@chakra-ui/react';
import countries from 'world-countries';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { humanize } from 'underscore.string';
import type { ModelTypes } from '@inkcloud/icapi-types';

import { countryStateProvinces } from '../../../../common';

type BillToType = NonNullable<ModelTypes.PurchaseOrder['billTo']>;
type ShipToType = NonNullable<ModelTypes.PurchaseOrder['shipTo']>;

interface IEditableBillToShipToProps {
  address: BillToType | ShipToType;
  type: 'billTo' | 'shipTo';
  onChange: (value: IEditableBillToShipToProps['address']) => void;
  isSubmitting?: boolean;
}

const schema = Yup.object()
  .shape({
    address: Yup.object().shape({
      name: Yup.string().required('Name is equired').trim(),
      countryCode: Yup.string().required('Country is required').trim(),
      address1: Yup.string().required('Address 1 is required').trim(),
      city: Yup.string().required('City is required').trim(),
      stateProvince: Yup.string().required('State/Province is required').trim(),
      postalCode: Yup.string().required('Postal code is equired').trim(),
    }),
  })
  .required();

const countryInclude = ['US', 'CA', 'AU'];

const EditableBillToShipTo = forwardRef((props: IEditableBillToShipToProps, ref) => {
  const { address = {}, type = 'billTo', isSubmitting = false, onChange } = props;

  const tenant = useSelector(
    ({ globals }: { globals: { tenant: { locale: string; countryCode: string } } }) =>
      globals.tenant
  );

  const {
    register,
    watch,
    handleSubmit,
    reset,
    formState: { errors, submitCount, isValid },
  } = useForm<Omit<IEditableBillToShipToProps, 'onChange' | 'type'>>({
    defaultValues: { address },
    resolver: yupResolver(schema),
  });

  const submitRef = useRef<HTMLFormElement>(null);

  useImperativeHandle(ref, () => ({
    handleSave: () => submitRef?.current?.requestSubmit(),
  }));

  useEffect(() => {
    reset({
      address: {
        ...(address || {}),
        countryCode: address?.countryCode ?? tenant.countryCode,
      },
    });
  }, [tenant.countryCode]);

  const watchBilltoCountry = watch(`address.countryCode`) as string;

  const handleSave: SubmitHandler<Omit<IEditableBillToShipToProps, 'onChange' | 'type'>> = (
    values,
    e
  ) => {
    e?.preventDefault();

    onChange({ ...values.address });
  };

  return (
    <>
      <form ref={submitRef} onSubmit={handleSubmit(handleSave)}>
        <Stack spacing={7} mb={3} direction="row">
          <FormControl isInvalid={!!errors?.address?.name}>
            <FormLabel>{type === 'billTo' ? 'Company' : ''} Name</FormLabel>
            <Input type="text" {...register(`address.name`)} />
            {errors?.address?.name && (
              <FormErrorMessage>{errors?.address?.name.message}</FormErrorMessage>
            )}
          </FormControl>
        </Stack>
        <Stack spacing={7} mb={3} direction="row">
          <FormControl isInvalid={!!errors?.address?.name}>
            <FormLabel>{type === 'billTo' ? 'Name' : 'Care of'}</FormLabel>
            <Input type="text" {...register(`address.careOf`)} />
            {errors?.address?.careOf && (
              <FormErrorMessage>{errors?.address?.careOf.message}</FormErrorMessage>
            )}
          </FormControl>
        </Stack>

        <Stack spacing={7} mb={3} direction="row">
          <FormControl isInvalid={!!errors?.address?.countryCode}>
            <FormLabel>Country</FormLabel>
            <Select {...register(`address.countryCode`)}>
              {countries?.map((c: any) => (
                <option key={c.cca2} value={c.cca2}>
                  {c.name.official}
                </option>
              ))}
            </Select>
            {errors?.address?.countryCode && (
              <FormErrorMessage>{errors?.address?.countryCode.message}</FormErrorMessage>
            )}
          </FormControl>
        </Stack>
        <Stack spacing={7} mb={3} direction="row">
          <FormControl isInvalid={!!errors?.address?.address1}>
            <FormLabel> Address 1</FormLabel>
            <Input type="text" {...register(`address.address1`)} />
            {errors?.address?.address1 && (
              <FormErrorMessage>{errors?.address?.address1.message}</FormErrorMessage>
            )}
          </FormControl>
        </Stack>
        <Stack spacing={7} mb={3} direction="row">
          <FormControl>
            <FormLabel> Address 2</FormLabel>
            <Input type="text" {...register(`address.address2`)} />
          </FormControl>
        </Stack>

        <Stack spacing={7} mb={3} direction="row">
          <FormControl isInvalid={!!errors?.address?.city}>
            <FormLabel> City</FormLabel>
            <Input type="text" {...register(`address.city`)} />
            {errors?.address?.city && (
              <FormErrorMessage>{errors?.address?.city.message}</FormErrorMessage>
            )}
          </FormControl>
          <FormControl isInvalid={!!errors?.address?.stateProvince}>
            <FormLabel> State/Province</FormLabel>
            {countryInclude.includes(watchBilltoCountry) ? (
              <Select {...register(`address.stateProvince`)}>
                {countryStateProvinces(watchBilltoCountry)?.map(
                  (c: { value: string; text: string }) => (
                    <option key={c.value} value={c.value}>
                      {c.text}
                    </option>
                  )
                )}
              </Select>
            ) : (
              <Input type="text" {...register(`address.stateProvince`)} />
            )}
            {errors?.address?.stateProvince && (
              <FormErrorMessage>{errors?.address?.stateProvince.message}</FormErrorMessage>
            )}
          </FormControl>
          <FormControl isInvalid={!!errors?.address?.postalCode}>
            <FormLabel> Postal Code</FormLabel>
            <Input type="text" {...register(`address.postalCode`)} />
            {errors?.address?.postalCode && (
              <FormErrorMessage>{errors?.address?.postalCode.message}</FormErrorMessage>
            )}
          </FormControl>
        </Stack>
      </form>
    </>
  );
});

export default EditableBillToShipTo;
