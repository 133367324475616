import * as React from 'react';
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Svg,
  Canvas,
  Image,
} from '@react-pdf/renderer';
import * as JsBarcode from 'jsbarcode';

interface IProductLabelProps {
  size: [number, number];
  debug?: boolean;
  fontSize?: number;
  content: string;
  showProductName?: boolean;
  productName?: string;
}

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#FFFFFF',
    alignItems: 'center',
    justifyContent: 'center',
  },
  section: {
    margin: 5,
    padding: 5,
    maxHeight: '55%',
  },
});

const ProductLabel: React.FunctionComponent<IProductLabelProps> = (props) => {
  const { size, fontSize = 20, content, showProductName, productName } = props;
  const [blob, setBlob] = React.useState<any>(null);
  const canvas = document.createElement('canvas');

  const barcodeImageFactor = 0.9;
  const barcodeImageHeightFactor = size[1] * (showProductName ? 0.3 : barcodeImageFactor);
  React.useEffect(() => {
    // Can only pass valid string or JsBarcode will crash
    if (content) {
      JsBarcode(canvas, content, {
        background: '#ffffff',
        fontSize: showProductName ? fontSize * 0.6 : fontSize,
        height: showProductName ? 60 : 100,
      });
      canvas.toBlob((blog) => {
        setBlob(blog);
      });
    }
  }, [size, fontSize, content, showProductName, productName]);

  if (!size[0] || !size[1]) {
    return null;
  }

  return (
    <div style={{ width: '70%' }}>
      <PDFViewer showToolbar={true} style={{ width: '70%', height: '700px' }}>
        <Document>
          <Page size={size} style={styles.page} wrap>
            <View
              style={{
                ...styles.section,
                maxHeight: showProductName ? size[1] * 0.45 : size[1] * 0.9,
              }}
              wrap
            >
              {blob && size[1] && size[0] && (
                <Image
                  source={window.URL.createObjectURL(blob)}
                  style={{ width: size[0] * barcodeImageFactor, height: barcodeImageHeightFactor }}
                />
              )}
            </View>
            {showProductName && (
              <View
                style={{
                  height: '20%',
                  alignContent: 'center',
                  // textAnchor: 'middle',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                wrap
              >
                <Text
                  style={{
                    fontSize,
                  }}
                >
                  {productName}
                </Text>
              </View>
            )}
          </Page>
        </Document>
      </PDFViewer>
    </div>
  );
};

export default ProductLabel;
