// Shipping Related -------------------------------------------------------------
export enum ShippingMethod {
  ShipOut = 'ship_out',
  WillCall = 'will_call',
  LocalDelivery = 'local_delivery',
  AirCargo = 'air_cargo',
}

export interface ShippingAvailableMethod {
  key: string;
  description: string;
}

export interface ShippingAvailableRate {
  description: string;
  carrier: string;
  amount: number;
  key: string;
}

export interface ShippingData {
  shippingMethod: ShippingMethod;
  shippingRate: string;
  availableShippingMethods: ShippingAvailableMethod[];
  availableShippingRates: ShippingAvailableRate[];

  shipFrom: ShippingAddress;
  shipTo: ShippingAddress;
  airportCode: string;

  isBlindShip: boolean;
  errorMessage: string;
}

export interface Address {
  address1: string;
  address2: string;
  city: string;
  stateProvince: string;
  postalCode: string;
  countryCode: string;
}

export interface ShippingAddress extends Address {
  name: string;
  careOf: string;
}

export const NewShippingData = (): ShippingData => {
  return {
    shippingMethod: ShippingMethod.ShipOut,
    shippingRate: '',
    availableShippingMethods: [],
    availableShippingRates: [],

    shipFrom: NewShippingAddress(),
    shipTo: NewShippingAddress(),
    airportCode: '',

    isBlindShip: false,
    errorMessage: '',
  };
};

export const NewShippingAddress = (): ShippingAddress => {
  return {
    name: '',
    careOf: '',
    address1: '',
    address2: '',
    city: '',
    stateProvince: '',
    postalCode: '',
    countryCode: '',
  };
};

// Payment Related -------------------------------------------------------------

export enum PaymentMethod {
  CreditCard = 'credit_card',
  Check = 'check',
  Cash = 'cash',
  Offline = 'offline',
  CreditMemo = 'credit_memo',
  None = 'none',
}

export enum PaymentCreditCardTypes {
  New = 'new',
  Stored = 'stored',
}

export interface PaymentData {
  amountDue: Number;
  paymentMethod: PaymentMethod;
  errorMessage: string;
  successMessage: string;
  availableCreditCardTokens?: PaymentCreditCardToken[];
  creditCardToken?: string | null;
  creditCard: PaymentCreditCard;
  creditCardType: PaymentCreditCardTypes;
  optionalDescription: string;
}

export interface PaymentCreditCardToken {
  paymentProfileId: string;
  cardType: string;
  lastFour: string;
}

export interface PaymentCreditCard {
  name: string;
  number: string;
  expMonth: string;
  expYear: string;
  cvv: string;
  address1: string;
  city: string;
  stateProvince: string;
  postalCode: string;
  saveCard: boolean;
}

export const NewPaymentData = (): PaymentData => {
  return {
    creditCard: {
      name: '',
      number: '',
      cvv: '',
      expMonth: '',
      expYear: '',
      address1: '',
      city: '',
      stateProvince: '',
      postalCode: '',
      saveCard: false,
    },
    errorMessage: '',
    successMessage: '',
    paymentMethod: PaymentMethod.CreditCard,
    availableCreditCardTokens: [],
    creditCardToken: '',
    optionalDescription: '',
    creditCardType: PaymentCreditCardTypes.New,
    amountDue: 0.0,
  };
};

export enum PaymentAdapters {
  AuthorizeNet = 'authorizeNet',
  Stripe = 'stripe',
  FirstData = 'firstData',
}

export enum SalesTaxAdapters {
  Simple = 'simple',
  TaxCloud = 'taxCloud',
}
