// import * as React from 'react';
// import { Button, Input, Dropdown, Segment, Form } from 'semantic-ui-react';

// export interface FilterFormProps {
//   value: any;
//   onChange: Function;
//   onReset: Function;
// }

// function initMapper(value) {
//   const defaults = {
//     name: '',
//     key: '',
//   }

//   return Object.keys(defaults).reduce((p, cur) => {
//     if (!p[cur]) {
//       p[cur] = defaults[cur];
//     }
//     return p;
//   }, value)
// }

// export const FilterForm: React.FunctionComponent<FilterFormProps> = (props) => {
//   const { value: preInitValue, onChange, onReset } = props;
//   const value = initMapper(preInitValue);
//   const handleChange = (field, value) => onChange({ ...props.value, [field]: value});

//   return (

//     <Segment>
//       <Form size={'small'}>
//         <Form.Group widths={'equal'}>
//           <Form.Field>
//             <Input  placeholder="Name..." value={value.name} onChange={(e, data) => handleChange('name', data.value)} />
//           </Form.Field>
//           <Form.Field>
//             <Form.Input placeholder="Key..." value={value.url} onChange={(e, data) => handleChange('key', data.value)} />
//           </Form.Field>
//           <Form.Field>
//             <Form.Input placeholder="Domain..." value={value.template} onChange={(e, data) => handleChange('domain', data.value)} />
//           </Form.Field>
//         </Form.Group>
//         <Form.Group widths={'equal'}>
//       </Form.Group>
//       <Form.Field >
//         <Button size="mini" content="Reset Form" onClick={() => onReset()} />
//       </Form.Field>
//       </Form>
//     </Segment>
//   );
// };

import { generateOperatorOptions, Operator } from '@inkcloud/components';

export default async function getFilterDefinition() {
  const definition = [
    {
      label: 'Name',
      value: 'name',
      operators: generateOperatorOptions([Operator.Like]),
    },
    {
      label: 'Key',
      value: 'key',
      operators: generateOperatorOptions([Operator.Like]),
    },
    {
      label: 'Domain',
      value: 'domain',
      operators: generateOperatorOptions([Operator.Like]),
    },
  ];

  return definition.sort((a: any, b: any) => a.label.localeCompare(b.label));
}
