import * as React from 'react';
// import { DropTarget } from 'react-dnd';
import {
  Accordion,
  Button,
  Checkbox,
  Dropdown,
  Header,
  Icon,
  Label,
  Grid,
  Modal,
  Popup,
  Segment,
  Table,
} from 'semantic-ui-react';
import ItemCard from './ItemCard';

const ItemCardTypeless: any = ItemCard;

export interface DropSegmentProps {
  isOver?: Boolean;
  isOverCurrent?: Boolean;
  connectDropTarget?: Function;
  items: any[];
  type?: any;
  title: string;
  style?: any;
  onDrop?: (v: any) => void;
}

export interface DropSegmentState {}

class DropSegment extends React.Component<DropSegmentProps, DropSegmentState> {
  constructor(props: DropSegmentProps) {
    super(props);

    this.state = {};
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.isOver && nextProps.isOver) {
      // You can use this as enter handler
    }

    if (this.props.isOver && !nextProps.isOver) {
      // You can use this as leave handler
    }

    if (this.props.isOverCurrent && !nextProps.isOverCurrent) {
      // You can be more specific and track enter/leave
      // shallowly, not including nested targets
    }
  }
  render() {
    const { connectDropTarget, title } = this.props;
    const itemCards =
      this.props.items &&
      this.props.items
        .filter((oi) => oi)
        .map((oi, idx) => <ItemCardTypeless orderItem={oi} key={oi._id} />);

    return connectDropTarget(
      <div style={{ height: '100%' }}>
        <Segment style={{ minHeight: 300, ...this.props.style }}>
          <Header as="h5" content={title} />
          {itemCards}
        </Segment>
      </div>
    );
  }
}

const dropTarget = {
  canDrop(props, monitor) {
    // You can disallow drop based on props or item
    const item = monitor.getItem();
    return true;
  },

  hover(props, monitor, component) {
    // This is fired very often and lets you perform side effects
    // in response to the hover. You can't handle enter and leave
    // here—if you need them, put monitor.isOver() into collect() so you
    // can just use componentWillReceiveProps() to handle enter/leave.
    // You can access the coordinates if you need them
    const clientOffset = monitor.getClientOffset();
    // const componentRect = findDOMNode(component).getBoundingClientRect();

    // You can check whether we're over a nested drop target
    const isJustOverThisOne = monitor.isOver({ shallow: true });

    // You will receive hover() even for items for which canDrop() is false
    const canDrop = monitor.canDrop();
  },

  drop(props, monitor, component) {
    if (monitor.didDrop()) {
      // If you want, you can check whether some nested
      // target already handled drop
      return;
    }

    // Obtain the dragged item
    const item = monitor.getItem();
    props.onDrop(item);
    // You can do something with it
    // ChessActions.movePiece(item.fromPosition, props.position);

    // You can also do nothing and return a drop result,
    // which will be available as monitor.getDropResult()
    // in the drag source's endDrag() method
    // return { moved: true };
  },
};

function collect(connect, monitor) {
  return {
    // Call this function inside render()
    // to let React DnD handle the drag events:
    connectDropTarget: connect.dropTarget(),
    // You can ask the monitor about the current drag state:
    isOver: monitor.isOver(),
    isOverCurrent: monitor.isOver({ shallow: true }),
    canDrop: monitor.canDrop(),
    itemType: monitor.getItemType(),
  };
}

// export default DropTarget('card', dropTarget, collect)(DropSegment) as any;
export default DropSegment;
