import * as React from 'react';
import { Button, Form, Input, Dropdown, Popup } from 'semantic-ui-react';
import { Formik, Form as FormikForm, Field, FieldProps, FieldArray, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Feathers from '../../../bootstrap/feathers';
import { SemanticField } from '../../../common/Form';
import { FeathersDropdown } from '@inkcloud/components';

interface PageFormProps {
  initialValues: any;
  onSubmit: Function;
  isSubmitting?: boolean;
  products: { value: string; text: string }[];
  categories: { value: string; text: string }[];
  errorMessage: string;
}

declare var ENV;

export const initialMapper = (values) => {
  return {
    ...values,
    name: values.name || '',
    title: values.title || '',
    template: values.template || '',
    url: values.url || '',
    type: values.type || 'basic',
  };
};

export const PageForm: React.FC<PageFormProps> = (outerProps) => {
  const PageScheme = Yup.object().shape({
    name: Yup.string().required('Required'),
    product: Yup.string().notRequired(),
    category: Yup.string().notRequired(),
  });

  return (
    <Formik
      initialValues={initialMapper(outerProps.initialValues)}
      validationSchema={PageScheme}
      onSubmit={(values, { setSubmitting }) => {
        outerProps.onSubmit(values);
      }}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
          setFieldValue,
          setFieldTouched,
        } = props;

        return (
          <FormikForm
            className={`ui form ${isSubmitting && !outerProps.errorMessage && 'loading'}`}
          >
            <Form.Group widths="equal">
              <Form.Field>
                <SemanticField name={'name'} label="Name" component={Form.Input} />
                <ErrorMessage component="div" name="name" />
              </Form.Field>
              <Form.Field>
                <SemanticField name={'title'} label="Title" component={Form.Input} />
                <ErrorMessage component="div" name="title" />
              </Form.Field>
            </Form.Group>

            <Form.Group widths="equal">
              <Form.Field>
                {ENV === 'development' || ENV === 'staging' ? (
                  <SemanticField name={'template'} label="Template" component={Form.Input} />
                ) : (
                  <>
                    <label>Template</label>
                    <Field
                      name="template"
                      render={({ field }: FieldProps<any>) => (
                        <FeathersDropdown
                          fluid
                          search
                          selection
                          serviceName="/store-front/content/pages/list"
                          query={{ $sort: { name: 1 }, $np: 1 }}
                          resultMapper={(r) => ({ value: r, text: r })}
                          {...field}
                          onChange={(e, data: any) =>
                            field.onChange({
                              target: { value: data.value, name: 'template' },
                            } as any)
                          }
                        />
                      )}
                    />
                  </>
                )}

                <ErrorMessage component="div" name="template" />
              </Form.Field>
              <Form.Field>
                <SemanticField name={'url'} label="URL" component={Form.Input} />
                <ErrorMessage component="div" name="url" />
              </Form.Field>
            </Form.Group>

            <Form.Group>
              <FieldArray
                name="meta"
                render={(arrayHelpers) => (
                  <div style={{ width: '100%', padding: '0 .5em' }}>
                    <Form.Field>
                      <label>Meta</label>
                    </Form.Field>
                    {values.meta &&
                      values.meta.length > 0 &&
                      values.meta.map((m, index) => (
                        <div key={index}>
                          <Form.Group>
                            <Form.Field width={16}>
                              <Field name={`meta.${index}.key`} placeholder="Key" />
                            </Form.Field>
                            <Form.Field width={16}>
                              <Field name={`meta.${index}.value`} placeholder="Value" />
                            </Form.Field>
                            <Popup
                              content="Remove meta"
                              trigger={
                                <Button
                                  type="button"
                                  onClick={() => arrayHelpers.remove(index)}
                                  content={'-'}
                                />
                              }
                            />
                          </Form.Group>
                        </div>
                      ))}

                    <Popup
                      content="Add meta"
                      trigger={
                        <Button type="button" onClick={() => arrayHelpers.push({})} content={'+'} />
                      }
                    />
                  </div>
                )}
              />
            </Form.Group>

            <Form.Field>
              <label>Page Type</label>
              <Form.Group inline>
                <Form.Radio
                  label="Basic"
                  name="type"
                  value={values.type}
                  checked={values.type === 'basic'}
                  onBlur={handleBlur}
                  onChange={() => setFieldValue('type', 'basic')}
                />
                <Form.Radio
                  label="Category"
                  name="type"
                  value={values.type}
                  checked={values.type === 'category'}
                  onBlur={handleBlur}
                  onChange={() => setFieldValue('type', 'category')}
                />
                <Form.Radio
                  label="Product"
                  name="type"
                  value={values.type}
                  checked={values.type === 'product'}
                  onBlur={handleBlur}
                  onChange={() => setFieldValue('type', 'product')}
                />
                <Form.Radio
                  label="Calculator"
                  name="type"
                  value={values.type}
                  checked={values.type === 'product-calculator'}
                  onBlur={handleBlur}
                  onChange={() => setFieldValue('type', 'product-calculator')}
                />
                <ErrorMessage component="div" name="type" />
              </Form.Group>
            </Form.Field>

            <Form.Group widths="equal">
              {(values.type === 'category' || values.type === 'product-calculator') && (
                <div className="field">
                  <SemanticField
                    name={'category'}
                    component={Form.Dropdown}
                    selection
                    search
                    clearable
                    lazyLoad
                    options={outerProps.categories}
                    fluid
                    placeholder="Category..."
                  />
                </div>
              )}
              {values.type === 'product' && (
                <div className="field">
                  <SemanticField
                    name={'product'}
                    component={Form.Dropdown}
                    selection
                    search
                    clearable
                    lazyLoad
                    options={outerProps.products}
                    fluid
                    placeholder="Product..."
                  />
                </div>
              )}
            </Form.Group>
            <Button primary type="submit" disabled={isSubmitting && !outerProps.errorMessage}>
              Save
            </Button>
          </FormikForm>
        );
      }}
    </Formik>
  );
};
