import * as React from 'react';
import moment from 'moment';
import { Button, Box, ButtonGroup, IconButton, Tooltip, Image } from '@chakra-ui/react';

import { FaEdit, FaCheckCircle } from 'react-icons/fa';

import { Link, RouteComponentProps } from 'react-router-dom';
import { FormattedDate, FormattedTime } from 'react-intl';

import { useList, ListPage, basicQueryMap } from '@inkcloud/components';

import getFilterDefinition from './FilterForm';

// import { useList, PageHeaderRow, PaginatorWrapper, EmptyWrapper } from '@inkcloud/components';
// import { Segment, Grid, Image, Button, Divider, Label, Popup, Icon, Table } from 'semantic-ui-react'

interface ListProps {}

const filterQueryMap = (values) => {
  const filterMap: any = {
    filename: values.filename ? { $LIKE: values.filename } : undefined,
    mimeType: values.mimeType ? values.mimeType : undefined,
    createdAt: {
      $gte: values.start ? moment(values.start).format('YYYY-MM-DD') : undefined,
      $lte: values.end ? moment(values.end).format('YYYY-MM-DD') : undefined,
    },
  };

  return [].reduce(
    (m, field) => ({ ...m, [field]: values[field] ? values[field] : undefined }),
    filterMap
  );
};

const serviceName = '/store-front/content/banners';
const pathPrefix = `/settings/content-management/banners`;

const baseUrl = 'https://storage.googleapis.com/icstorage/';

export default function List(props: ListProps & RouteComponentProps) {
  const {
    match: { params },
    location,
    history,
  } = props;
  // const { state, reload, handleDelete, selectedItems, clearAll, setChecked, someSelected, toggleAll, filter, setFilter, sort, handleSort } = useList({
  //   feathers: ['find', serviceName, [{ query: {} }]],
  //   filterMap: filterQueryMap,
  //   debounce: 150,
  //   location,
  //   history,
  //   sort: {}
  // })

  const useListValues = useList({
    feathers: [
      'find',
      serviceName,
      [
        {
          query: {},
        },
      ],
    ],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  const tableDefintion = [
    {
      key: 'createdAt',
      label: 'Date',
      sortable: true,
      cell: (v) => (
        <Tooltip hasArrow aria-label="time" label={<FormattedTime value={v} />}>
          <Box>
            <FormattedDate value={v} />
          </Box>
        </Tooltip>
      ),
    },
    {
      key: 'banner',
      label: 'Banner Image',
      cell: (v, r) => <Image src={`${baseUrl}${v}`} alt={r?.altText ?? ''} />,
    },
    {
      key: 'name',
      label: 'Name',
      cell: (v) => v,
    },
    {
      key: 'altText',
      label: 'Alt Text',
      cell: (v) => v,
    },
    {
      key: 'url',
      label: 'Link',
      cell: (v, r) => v,
    },
    {
      key: 'isPublished',
      label: 'Published',
      cell: (v, r) => v && <FaCheckCircle color="green" />,
    },
    {
      key: 'startDate',
      label: 'Start Date',
      cell: (v) => <FormattedDate value={v} />,
    },
    {
      key: 'expireDate',
      label: 'Expire Date',
      cell: (v) => <FormattedDate value={v} />,
    },
    {
      key: 'priority',
      label: 'Priority',
      cell: (v) => v,
    },

    {
      key: '',
      label: '',
      cell: (v, r) => (
        <ButtonGroup gap={1}>
          <Tooltip label="Edit Details">
            <Link to={`${pathPrefix}/${r._id}`}>
              <IconButton colorScheme="gray" aria-label="Edit" icon={<FaEdit />} />
            </Link>
          </Tooltip>
        </ButtonGroup>
      ),
      textAlign: 'right',
    },
  ];

  const headerActions = (
    <>
      <Link to={`${pathPrefix}/add`}>
        <Button colorScheme="blue">New</Button>
      </Link>
    </>
  );

  return (
    <>
      <ListPage
        header="Banners"
        subheader="Settings"
        useListValues={useListValues}
        history={history}
        pathPrefix={pathPrefix}
        headerActions={headerActions}
        tableDefintion={tableDefintion}
        withCheckboxes={true}
        getFilterDefinition={getFilterDefinition}
      />

      {/* <PageHeaderRow header='Banners' subheader='Settings'>
      <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
      <Link to={`${pathPrefix}/add`}><Button content="New" primary size={'mini'} /></Link>
    </PageHeaderRow> */}
      {/* <EmptyWrapper queryResult={queryResult} isStateLoading={isStateLoading}>
      <Segment loading={state.isLoading}>
        <PaginatorWrapper.Top filter={filter} reload={reload} data={(queryResult as any)} pathPrefix={`${pathPrefix}`} />
        <Table size={'small'} compact>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell><input type="checkbox" onChange={toggleAll} /></Table.HeaderCell>
              <Table.HeaderCell>
                Created
              </Table.HeaderCell>
              <Table.HeaderCell>
                Banner Image
              </Table.HeaderCell>

              <Table.HeaderCell>
                Name
              </Table.HeaderCell>
              <Table.HeaderCell>
                Alt Text
              </Table.HeaderCell>
              <Table.HeaderCell>
                Link
              </Table.HeaderCell>
              <Table.HeaderCell>
                Published
              </Table.HeaderCell>
              <Table.HeaderCell>
                Start Date
              </Table.HeaderCell>
              <Table.HeaderCell>
                Expire Date
              </Table.HeaderCell>
              <Table.HeaderCell>
                Priority
              </Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body> */}
      {/* {(queryResult as any)?.data?.map((r: any, index: number) => (
              <Table.Row key={r._id}>
                <Table.Cell>
                  <input type="checkbox" value={r._id} checked={selectedItems[r._id] || false} onChange={setChecked} />
                </Table.Cell>
                <Table.Cell>
                  {r.createdAt && <Popup
                    size='mini'
                    content={<FormattedTime value={r.createdAt} />}
                    trigger={
                      <FormattedDate value={r.createdAt} />
                    }
                  />}
                </Table.Cell>
                <Table.Cell collapsing>
                  <Image
                    size='tiny'
                    src={`${baseUrl}${r.banner}`}
                  />
                </Table.Cell>

                <Table.Cell>
                  {r.name}
                </Table.Cell>
                <Table.Cell>
                  {r.altText}
                </Table.Cell>
                <Table.Cell>
                  {r.url}
                </Table.Cell>
                <Table.Cell>
                  {
                    r.isPublished && <Icon name="check circle outline" />
                  }
                </Table.Cell>
                <Table.Cell>
                  {r.startDate && <Popup
                    size='mini'
                    content={<FormattedTime value={r.startDate} />}
                    trigger={
                      <FormattedDate value={r.startDate} />
                    }
                  />}
                </Table.Cell>
                <Table.Cell>
                  {r.expireDate && <Popup
                    size='mini'
                    content={<FormattedTime value={r.expireDate} />}
                    trigger={
                      <FormattedDate value={r.expireDate} />
                    }
                  />}
                </Table.Cell>
                <Table.Cell>
                  {r.priority}
                </Table.Cell>


                <Table.Cell textAlign="right">
                  <Popup
                    size='mini'
                    content="Edit"
                    trigger={
                      <Link to={`${pathPrefix}/${r._id}`}>
                        <Button size={'mini'} icon={'edit'} />
                      </Link>
                    }
                  />
                </Table.Cell>
              </Table.Row>
            ))} */}
      {/* </Table.Body>
        </Table>
        <PaginatorWrapper.Bottom reload={reload} data={(queryResult as any)} pathPrefix={`${pathPrefix}`}>
          {someSelected && <Button primary type="button" content="Delete" onClick={handleDelete} />}
        </PaginatorWrapper.Bottom> */}

      {/* {
          isOpenModal && <ImageUploadModal
            isOpen={isOpenModal}
            onClose={() => { setIsOpenModal(false); setIsImageManager(false) }}
            onReload={reload}
            isImageManager={isImageManager}
            onIsImageManager={() => setIsImageManager(false)}
          />
        } */}
      {/* {
          isSelectOpenModal && <ImageSelectModal
            isOpen={isSelectOpenModal}
            onClose={() => setIsSelectOpenModal(false)}
            onReload={reload}
          />
        } */}

      {/* 
      </Segment>
    </EmptyWrapper> */}
    </>
  );
}
