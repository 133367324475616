import * as React from 'react';
import { Box, Heading } from '@chakra-ui/react';

interface IJobSchedulingColumnHeaderProps {
  title: string;
  count: number | string;
}

const JobSchedulingColumnHeader: React.FunctionComponent<IJobSchedulingColumnHeaderProps> = (props) => {
  const { title, count = 0 } = props;
  return (
    <Box width={298} p={2}>
      <Heading size={'sm'} textTransform={'uppercase'} >{title} ({count})</Heading>
    </Box>
  );
};

export default JobSchedulingColumnHeader;
