import { OrderItemLabelDocument } from './OrderItemLabelDocument';
import { OrderItemLabelPage } from './OrderItemLabelPage';
import { ShipTo } from './ShipTo';
import { OrderItemLabelContext as Context } from './OrderItemLabelContext';
import PropertyList from '../shared/PropertyList';

export * from './OrderItemLabelDocument';
export * from './OrderItemLabelPage';
export * from './ShipTo';

export const Components = {
  OrderItemLabelDocument,
  OrderItemLabelPage,
  ShipTo,
  Context,
  PropertyList,
};
