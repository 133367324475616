import * as React from 'react';
import { Formik, Form as FormikForm, FieldArray, Field, ErrorMessage } from 'formik';
import {
  Button,
  Form,
  Input,
  Dropdown,
  Grid,
  Checkbox,
  Segment,
  Menu,
  Divider,
  GridColumn,
} from 'semantic-ui-react';

import Feathers from '../../../bootstrap/feathers';
import { connect } from 'react-redux';

import { PaymentForm } from './PaymentForm';
import { ShippingForm } from './ShippingForm';
import { PackagingForm } from './PackagingForm';
import { ContactForm } from './ContactForm';
import { InternationalizationForm } from './InternationalizationForm';
import { CartForm } from './CartForm';
import { PickupLocationForm } from './PickupLocationForm';
import { GeneralForm } from './GeneralForm';

import { StatusFilterModal } from './StatusFilterModal';

const { useState, useEffect } = React;

export interface ConnectedProps {
  user?: any;
}

export interface TenantSettingProps {
  isOpenStatusFilterModal: boolean;
  onCloseStatusFilterModal: () => void;
}

const TenantSetting: React.FunctionComponent<TenantSettingProps & ConnectedProps> = (props) => {
  const { user, isOpenStatusFilterModal, onCloseStatusFilterModal } = props;
  const [cart, setCart] = useState({});
  const [payments, setPayments] = useState([]);
  const [shipping, setShipping] = useState(null);
  const [packaging, setPackaging] = useState(null);
  const [contact, setContact] = useState(null);
  const [internationalization, setInternationalization] = useState(null);
  const [general, setGeneral] = useState(null);

  useEffect(() => {
    const LoadMethods = async () => {
      const result: any = await Feathers.service('tenant-settings').get(user.business, {
        query: {
          $select: [
            'payment',
            'shipping',
            'packaging',
            'publishedContactInfo',
            'internationalization',
            'cart',
            'general',
          ],
        },
      });

      setCart(result.cart);
      setPackaging(result.packaging);
      setPayments(result.payment);
      setShipping(result.shipping);
      setContact(result.publishedContactInfo);
      setInternationalization(result.internationalization);
      setGeneral(result.general);
    };
    LoadMethods();
  }, []);

  if (!payments || !shipping) {
    return null;
  }

  return (
    <>
      <Divider hidden />
      <Grid>
        <Grid.Column>
          <Menu attached="top" pointing secondary>
            <Menu.Item name="Contact Info" active={true} />
          </Menu>
          <Segment attached="bottom">
            <ContactForm id={user.business} contact={contact} />
          </Segment>
        </Grid.Column>
      </Grid>

      <Grid>
        <Grid.Column>
          <Menu attached="top" pointing secondary>
            <Menu.Item name="General" active={true} />
          </Menu>
          <Segment attached="bottom">
            <GeneralForm id={user.business} general={general} />
          </Segment>
        </Grid.Column>
      </Grid>

      <Grid>
        <Grid.Column>
          <Menu attached="top" pointing secondary>
            <Menu.Item name="Pick-up Locations" active={true} />
          </Menu>
          <Segment attached="bottom">
            <PickupLocationForm />
          </Segment>
        </Grid.Column>
      </Grid>

      <Grid columns={3}>
        <Grid.Column>
          <Menu attached="top" pointing secondary>
            <Menu.Item name="Internalization" active={true} />
          </Menu>
          <Segment attached="bottom">
            <InternationalizationForm value={internationalization} id={user.business} />
          </Segment>
        </Grid.Column>
        <Grid.Column>
          <Menu attached="top" pointing secondary>
            <Menu.Item name="Shipping" active={true} />
          </Menu>
          <Segment attached="bottom">
            <ShippingForm id={user.business} shippingProps={shipping} />
          </Segment>
        </Grid.Column>
        <Grid.Column>
          <Menu attached="top" pointing secondary>
            <Menu.Item name="Packaging" active={true} />
          </Menu>
          <Segment attached="bottom">
            <PackagingForm packaging={packaging} id={user.business} />
          </Segment>
        </Grid.Column>
      </Grid>

      <Grid columns={3}>
        <Grid.Column>
          <Menu attached="top" pointing secondary>
            <Menu.Item name="Payment Method" active={true} />
          </Menu>
          <Segment attached="bottom">
            <PaymentForm payments={payments} />
          </Segment>
        </Grid.Column>
        <Grid.Column>
          <Menu attached="top" pointing secondary>
            <Menu.Item name="Cart" active={true} />
          </Menu>
          <Segment attached="bottom">
            <CartForm id={user.business} cart={cart} />
          </Segment>
        </Grid.Column>
      </Grid>

      {isOpenStatusFilterModal && (
        <StatusFilterModal
          id={user._id}
          isOpen={isOpenStatusFilterModal}
          onClose={onCloseStatusFilterModal}
        />
      )}
    </>
  );
};

const mapStateToProps = (state): ConnectedProps => ({
  user: state.auth.user || {},
});

export const Tenant = connect(mapStateToProps)(TenantSetting);
