import * as React from 'react';
import { Button, Form } from 'semantic-ui-react';

interface Tier {
  startQty: number;
  endQty: number;
  price: number;
}

export interface PriceTiersProps {
  value: Tier[];
  onChange: (data: any) => void;
}

export class PriceTiers extends React.Component<PriceTiersProps, any> {
  handleAdd = () => {
    const tiers = this.props.value;
    const nextMin = tiers.length > 0 ? tiers[tiers.length - 1].endQty + 1 : 1;
    this.props.onChange(
      this.props.value.concat({ startQty: nextMin, endQty: nextMin, price: 0.0 })
    );
  };

  handleDelete = (index) => {
    const updatedTiers = [...this.props.value];
    updatedTiers.splice(index, 1);
    this.props.onChange(updatedTiers);
  };

  handleChange = (value, index) => {
    const updatedTiers = [...this.props.value].map((t, i) => (i !== index ? t : { ...value }));
    this.props.onChange(updatedTiers);
  };

  public render() {
    const tiers = this.props.value;
    return (
      <div>
        {/* {tiers.length === 0 && } && <Button type="button" size="mini" icon="plus" onClick={this.handleAdd} /> */}
        {tiers.map((r, i) => {
          const { startQty, endQty, price } = r;
          return (
            <div key={i}>
              <Form.Group inline>
                <Form.Input
                  size={'mini'}
                  label="Minimum"
                  value={startQty}
                  onChange={(e, data) =>
                    this.handleChange({ ...r, startQty: parseInt(data.value || '0', 10) }, i)
                  }
                />
                <Form.Input
                  size={'mini'}
                  label="Maximum"
                  value={endQty}
                  onChange={(e, data) =>
                    this.handleChange({ ...r, endQty: parseInt(data.value || '0', 10) }, i)
                  }
                />
                <Form.Input
                  size={'mini'}
                  label="Price"
                  value={price}
                  onChange={(e, data) => {
                    this.handleChange({ ...r, price: data.value === '' ? '0' : data.value }, i);
                  }}
                />
                <Button
                  size={'mini'}
                  type="button"
                  icon="remove"
                  onClick={() => this.handleDelete(i)}
                />
                {/* {i === tiers.length - 1 && <Button size="tiny" type="button" icon="plus" onClick={this.handleAdd} />} */}
              </Form.Group>
            </div>
          );
        })}
        <Button type="button" size="mini" icon="plus" onClick={this.handleAdd} />
      </div>
    );
  }
}
