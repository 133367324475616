// import * as React from 'react';
// import { Button, Checkbox, Header, Message, Segment, Table, Label } from 'semantic-ui-react';
// import { Link } from 'react-router-dom';
// import { FormattedDate, FormattedNumber, FormattedRelative } from 'react-intl';
// import { IndexWrapper, IndexWrappedProps } from '../../../bootstrap/IndexWrapper';
// import { ListViewPagination, ListFilterWarning } from '../../../common';
// import { PageHeaderRow, PreLoader } from '@inkcloud/components';
// import { FilterForm } from './FilterForm';

// const initialState = { product: null, isInviteModalOpen: false, success: false };
// type State = typeof initialState;

// class VariantType extends React.Component<IndexWrappedProps, State> {
//   state = initialState;

//   closeModal = () => this.setState({ ...this.state, isInviteModalOpen: false });

//   public render() {
//     const { props } = this;
//     if (!props.queryResult) {
//       return <PreLoader />;
//     }
//     const { data, skip, limit, total } = props.queryResult;

//     return (
//       <div>
//         <PageHeaderRow header='Variant Types' subheader='Products'>
//           <Link to={`/products/products`}><Button content="Back" size={'mini'} /></Link>
//           <Link to={`/products/variant-types/add`}><Button content="New" primary size={'mini'} /></Link>
//         </PageHeaderRow>
//         <FilterForm
//           value={props.filter}
//           onChange={(values) => {
//             props.handleFilterSubmit(values);
//           }}
//           onReset={props.handleClearFilter}
//         />
//         <ListFilterWarning isFiltered={props.isFiltered} />

//         <Segment loading={props.isLoading}>
//           <ListViewPagination.Top
//             label={'New Page'}
//             link={`/products/variant-values/add`}
//             {...props}
//             hideAddButton={true}
//           />
//           <Table size={'small'}>
//             <Table.Header>
//               <Table.Row>
//                 <Table.HeaderCell></Table.HeaderCell>
//                 <Table.HeaderCell>Created</Table.HeaderCell>
//                 <Table.HeaderCell>Name</Table.HeaderCell>
//                 <Table.HeaderCell>Priority</Table.HeaderCell>

//                 <Table.HeaderCell></Table.HeaderCell>
//               </Table.Row>
//             </Table.Header>
//             <Table.Body>
//               {data.map(i => (
//                 <Table.Row key={i._id}>
//                   <Table.Cell>
//                     <Checkbox
//                       value={i._id}
//                       checked={props.selected[i._id] || false}
//                       onChange={props.handleSemanticCheckboxClick}
//                     />
//                   </Table.Cell>
//                   <Table.Cell>
//                     <FormattedDate value={i.createdAt} />
//                   </Table.Cell>
//                   <Table.Cell>
//                     {i.name}
//                   </Table.Cell>
//                   <Table.Cell>
//                     {i.priority || ''}
//                   </Table.Cell>

//                   <Table.Cell textAlign="right">
//                     <Link to={`/products/variant-types/${i._id}`}>
//                       <Button size={'mini'} primary icon={'edit'} />
//                     </Link>
//                   </Table.Cell>
//                 </Table.Row>
//               ))}
//             </Table.Body>
//           </Table>
//           <ListViewPagination.Bottom
//             label={'Delete'}
//             {...props}
//           />
//         </Segment>
//         {/* <InviteModal open={this.state.isInviteModalOpen} handleSubmit={this.handleInviteSubmit} handleClose={this.closeModal}  /> */}

//       </div>
//     );
//   }
// }

// export default IndexWrapper(VariantType, 'productsVariantTypes', {
//   query: {
//     $sort: { name: 1 },
//   },
//   filterMapper: (values) => {
//     return {
//       name: values.name && { $LIKE: values.name },
//     };
//   },
// });

import * as React from 'react';
import { Button, Popup, Label } from 'semantic-ui-react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { FormattedDate, FormattedTime } from 'react-intl';

import {
  useList,
  generateOperatorOptions,
  Operator,
  ListPage,
  basicQueryMap,
} from '@inkcloud/components';

interface ListProps {}

export async function getFilterDefinition() {
  let definition = [
    {
      label: 'Name',
      value: 'name',
      operators: generateOperatorOptions([Operator.Like, Operator.Is]),
    },
  ];

  return definition;
}

const filterQueryMap = (values) => {
  let filterRows = basicQueryMap(values);

  filterRows = {
    ...filterRows,
  };

  return filterRows;
};

const serviceName = 'products-legacy/variant-types';
const pathPrefix = '/products/variant-types';

export const List: React.FunctionComponent<ListProps & RouteComponentProps<any>> = (props) => {
  const {
    match: { params },
    location,
    history,
  } = props;

  const useListValues = useList({
    feathers: [
      'find',
      serviceName,
      [
        {
          query: {
            $sort: { createdAt: -1 },
          },
        },
      ],
    ],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  const headerActions = (
    <>
      <Link to={`/products/products`}>
        <Button content="Back" size={'mini'} />
      </Link>
      <Link to={`${pathPrefix}/add`}>
        <Button content="New" primary size={'mini'} />
      </Link>
    </>
  );

  const tableDefintion = [
    {
      key: 'createdAt',
      label: 'Date',
      sortable: true,
      cell: (v, r) => (
        <Popup
          size="mini"
          content={<FormattedTime value={r.createdAt} />}
          trigger={
            <div>
              <FormattedDate value={r.createdAt} />
            </div>
          }
        />
      ),
    },
    {
      key: 'name',
      label: 'Name',
      cell: (v, r) => v,
    },
    {
      key: 'priority',
      label: 'Priority',
      cell: (v, r) => v,
    },
    {
      key: '',
      label: '',
      cell: (v, r) => (
        <>
          <Popup
            size="mini"
            content="Edit"
            trigger={
              <Link to={`${pathPrefix}/${r._id}`}>
                <Button size={'mini'} icon={'edit'} />
              </Link>
            }
          />
        </>
      ),
      textAlign: 'right',
    },
  ];

  return (
    <>
      <ListPage
        header="Variant Types"
        subheader="Product"
        useListValues={useListValues}
        history={history}
        pathPrefix={pathPrefix}
        tableDefintion={tableDefintion}
        withCheckboxes={true}
        headerActions={headerActions}
        getFilterDefinition={getFilterDefinition}
      />
    </>
  );
};
