/* eslint-disable no-undef */
import React, { useEffect, useState, useRef } from 'react';
import {
  Button,
  Checkbox,
  Header,
  Message,
  Segment,
  Table,
  Label,
  Icon,
  Grid,
  TableCell,
} from 'semantic-ui-react';
import { Link, Redirect, RouteComponentProps } from 'react-router-dom';
import { FormattedDate, FormattedTime } from 'react-intl';
import {
  PageHeaderRow,
  PreLoader,
  basicQueryMap,
  useDebounce,
  GenericFilter,
  useList,
  ListPage,
} from '@inkcloud/components';
import { CSVLink } from 'react-csv';
import {
  Table as ChakraTable,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  Switch,
  FormControl,
  FormLabel,
  TableContainer,
  Box,
  Heading,
  HStack,
  Tag,
  TagLabel,
  Flex,
  Button as ChakraButton,
  Alert,
  AlertTitle,
  AlertDescription,
  Wrap,
  Card,
  CardBody,
  Tooltip,
} from '@chakra-ui/react';

import type { ModelTypes } from '@inkcloud/icapi-types';

import OpeningAmountForm from './OpeningAmountForm';
import AdjustmentModal from './AdjustmentModal';

import Feathers from '../../../bootstrap/feathers';
import Movement from './Movement';

const infoRowStyle = { paddingTop: 2, paddingBottom: 2 };

const renderInfo = (label, text) => (
  <div className="ui two column grid">
    <div className="row" style={infoRowStyle}>
      <div className="six wide column">
        <label>{label}</label>
      </div>
      <div className="ten wide column">{text}</div>
    </div>
  </div>
);

const headers = [
  { label: 'Location', key: 'locationName' },
  { label: 'Count', key: 'total' },
];

const initialState: any = {
  product: null,
  isAdjustModalOpen: false,
  productLocations: [],
  filteredProductLocations: [],
  fulfillmentStats: null,
  isLoading: {
    backorder: false,
  },
  errorMessage: {
    backorder: '',
    adjustment: '',
  },
  responseMessage: {
    backorder: '',
  },
};

export interface LedgerProps {}

const serviceName = 'inventory/movements';
const service = Feathers.service(serviceName);

interface ILedgerProps {}

const colorLookUp = {
  ADJUSTMENT: 'telegram',
  OPENING_AMOUNT: 'gray',
  RECEIVE: 'green',
  RETURN: 'orange',
  FULFILL: 'teal',
  MOVEMENT: 'blue',
  ALLOCATED: 'cyan',
  BACK_ORDER: 'purple',
  VOID_ADJUSTMENT: 'pink',
};

type OperationType = NonNullable<ModelTypes.InventoryMovement['operation']>;

const colorize = (operation: OperationType) => colorLookUp[operation];

const commonParams = (args) => ({
  query: {
    $populate: ['source', 'destination', 'order', 'requestedBy'],
    $np: 1,
    $limit: 2000,
    $sort: { createdAt: -1 },
    ...args,
  },
});

export default function Ledger(props: ILedgerProps & RouteComponentProps<{ productId: string }>) {
  const {
    match: {
      params: { productId },
    },
    location,
    history,
  } = props;

  const [filter, setFilter] = useState<{
    rows: { selectedFact: string; selectedOperator: string; value: undefined | string | {} }[];
  }>();

  const [product, setProduct] = useState<ModelTypes.ProductLegacy>({});
  const [fulfillment, setFulfillment] = useState<any>({});

  const [isReload, setIsReload] = useState(true);
  const [isLoadingProduct, setIsLoadingProduct] = useState(false);
  const [isLoadingProductLocation, setIsLoadingProductLocation] = useState(false);
  const [isLoadingBackorder, setIsLoadingBackorder] = useState(false);
  const [isLoadingAdjustment, setIsLoadingAdjustment] = useState(false);

  const [errorMessage, setErrorMessage] = useState({
    backOrder: '',
    adjustment: '',
  });

  const [responseMessage, setResponseMessage] = useState({
    backOrder: '',
    adjustment: '',
  });

  const [isAdjustModalOpen, setIsAdjustModalOpen] = useState(false);

  const [locationState, setLocationState] = useState<any>(null);

  const [inventoryMovements, setInventoryMovements] = useState<ModelTypes.InventoryMovement[]>([]);
  const [productLocations, setProductLocations] = useState<any[]>([]);
  const [filteredProdLocations, setFilteredProdLocations] = useState<any[]>([]);

  const loadInventoryMovements = async (args) => {
    // setIsLoadingProductLocation(true)
    try {
      const res = await service.find(commonParams(args));
      setInventoryMovements(res);
    } catch (e) {
      console.error(e);
    }
  };

  const loadProductLocations = async () => {
    try {
      const res = await Feathers.service('/inventory/operations').create({
        operation: 'PRODUCT_TOTAL_STOCK_PER_LOCATION',
        details: {
          productId,
        },
      });
      setProductLocations(res.productLocations);
      setFilteredProdLocations(res.productLocations);
    } catch (e) {
      console.error(e);
    }
  };

  const loadProduct = async () => {
    setIsLoadingProduct(true);
    try {
      const res = await Feathers.service('products-legacy').get(productId, { query: {} });
      setProduct(res);
    } catch (e) {
      console.error(e);
    }
    setIsLoadingProduct(false);
  };

  const loadFulfillmentStats = async () => {
    try {
      const res = await Feathers.service('products-legacy/fulfillment-stats').create({
        product: productId,
      });
      setFulfillment(res);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if ((location?.state as any)?.from) {
      setLocationState({
        ...(location || {}),
        pathname: '/products/products',
      });
    }
  }, [(location?.state as any)?.from]);

  // browser back
  useEffect(() => {
    window.addEventListener('popstate', (e) => {
      history.push('/products/products', {
        ...(locationState?.state || {}),
        from: 'managed-inventory',
      });
    });

    return window.removeEventListener('popstate', () => {});
  }, [history?.action]);

  useEffect(() => {
    if (productId && isReload) {
      loadProductLocations();
      loadProduct();
      loadFulfillmentStats();
    }
  }, [productId, isReload]);

  if (isLoadingProduct || isLoadingProductLocation) {
    return <PreLoader />;
  }

  const handleFilterLocations = (toggle: boolean) => {
    const filtered = productLocations?.filter((p) => (toggle ? p.total > 0 : p));

    setFilteredProdLocations(filtered);
  };

  const handleBackorderAllocation = async () => {
    setErrorMessage({
      ...errorMessage,
      backOrder: '',
    });

    setIsLoadingBackorder(true);
    try {
      const res: any = await Feathers.service('/inventory/operations').create({
        details: {
          productId,
        },
        operation: 'BACKORDER_ALLOCATION',
      });

      if (res.result === false) {
        setErrorMessage({
          ...errorMessage,
          backOrder: res.message || res.errorMessage,
        });
        return;
      }

      setResponseMessage({
        ...responseMessage,
        backOrder: res?.message,
      });
    } catch (e) {
      const errMsg =
        e?.code < 500 ? e?.message : 'We are experiencing technical difficulties. Please try again';
      setErrorMessage({
        ...errorMessage,
        backOrder: errMsg,
      });
    }

    setIsLoadingBackorder(false);
  };

  const handleAdjustSubmit = async (values) => {
    setIsReload(false);
    setErrorMessage({
      ...errorMessage,
      adjustment: '',
    });

    setIsLoadingAdjustment(true);

    try {
      let backorderMovementIds = [];

      if (values.transferBackorder) {
        const res = await Feathers.service('/inventory/movements').find({
          query: {
            $sort: { name: 1 },
            $np: 1,
            operation: 'BACK_ORDER',
            isVoid: { $ne: true },
            product: values.selectedProduct,
          },
        });

        backorderMovementIds = res?.map((r) => r._id);
      }

      delete values.selectedProduct;

      await Feathers.service('/inventory').create({
        product: productId,
        operation: 'ADJUSTMENT',
        ...values,
        backorderMovementIds: values.transferBackorder ? backorderMovementIds : undefined,
      });

      setIsReload(true);

      // this.props.onPageChange()
      // const product = await this.loadProduct()
      // const locations: any = await this.loadInventoryLocations()
      // this.setState({ ...this.state, product })
      // this.setState({ ...this.state, productLocations: locations.productLocations })

      setIsAdjustModalOpen(false);
    } catch (e) {
      const errMsg =
        e?.code < 500 ? e?.message : 'We are experiencing technical difficulties. Please try again';
      setErrorMessage({
        ...errorMessage,
        adjustment: errMsg,
      });
    }

    setIsLoadingAdjustment(false);
  };

  const lastLocation = productLocations?.length ? productLocations[0].id : null;

  const sources = productLocations?.reduce((ac, cr) => {
    if (cr.total > 0) {
      ac.push({ text: cr.locationName, value: cr.id });
    }

    return ac;
  }, []);

  let lastPrice = 0;
  let lastTags = [] as number | string[];
  if (inventoryMovements.length) {
    const sortedDescending = inventoryMovements?.sort(
      (a, b) => (new Date((b as any).createdAt) as any) - (new Date((a as any).createdAt) as any)
    );

    const foundPrice = sortedDescending.find((r) => r.price);
    const foundTags = sortedDescending.find((r) => r.tags);
    if (foundPrice) {
      lastPrice = foundPrice?.price ?? 0;
    }
    if (foundTags) {
      lastTags = foundTags.tags ?? [];
    }
  }

  return (
    <>
      <PageHeaderRow header="Inventory Ledger" subheader="Product">
        <ChakraButton
          isLoading={isLoadingBackorder}
          isDisabled={isLoadingBackorder}
          size="sm"
          colorScheme="blue"
          onClick={handleBackorderAllocation}
        >
          Backorder Allocation
        </ChakraButton>
        <ChakraButton
          isLoading={isLoadingAdjustment}
          isDisabled={isLoadingAdjustment}
          size="sm"
          mx={2}
          colorScheme="blue"
          onClick={() => setIsAdjustModalOpen(true)}
        >
          New Adjustment
        </ChakraButton>
        <Link to={locationState}>
          <ChakraButton size="sm">Back</ChakraButton>
        </Link>
      </PageHeaderRow>

      {errorMessage.backOrder && <Alert status="error">{errorMessage.backOrder}</Alert>}
      {responseMessage.backOrder && <Alert status="success">{responseMessage.backOrder}</Alert>}

      <Box p={3} my={3} bg="white" borderRadius="md">
        <Flex>
          <Box w="100%">
            <TableContainer>
              <ChakraTable variant="simple" size="sm">
                <Thead>
                  <Tr>
                    <Th colSpan={2}>Basics</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td>Product ID</Td>
                    <Td>{product?.humanId ?? ''}</Td>
                  </Tr>
                  <Tr>
                    <Td>SKU</Td>
                    <Td>{product?.key ?? ''}</Td>
                  </Tr>
                  <Tr>
                    <Td>Name</Td>
                    <Td>{product?.staticName ?? ''}</Td>
                  </Tr>
                </Tbody>
              </ChakraTable>
            </TableContainer>
          </Box>
          <Box w="100%">
            <TableContainer>
              <ChakraTable variant="simple" size="sm">
                <Thead>
                  <Tr>
                    <Th colSpan={2}>Current Counts</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td>Physical</Td>
                    <Td>{(product?.inventoryCount ?? 0) + (product?.allocatedCount ?? 0)}</Td>
                  </Tr>
                  <Tr>
                    <Td>Available</Td>
                    <Td>{product?.inventoryCount ?? 0}</Td>
                  </Tr>
                  <Tr>
                    <Td>Allocated</Td>
                    <Td>{product?.allocatedCount ?? 0}</Td>
                  </Tr>
                  <Tr>
                    <Td>Backorder</Td>
                    <Td>{product?.backorderCount ?? 0}</Td>
                  </Tr>
                </Tbody>
              </ChakraTable>
            </TableContainer>
          </Box>
          <Box w="100%">
            <TableContainer>
              <ChakraTable variant="simple" size="sm">
                <Thead>
                  <Tr>
                    <Th colSpan={2}>Fulfillment Stats</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td>Day</Td>
                    <Td>{fulfillment?.day?.count ?? 0}</Td>
                  </Tr>
                  <Tr>
                    <Td>Week</Td>
                    <Td>{fulfillment?.week?.count ?? 0}</Td>
                  </Tr>
                  <Tr>
                    <Td>Month</Td>
                    <Td>{fulfillment?.month?.count ?? 0}</Td>
                  </Tr>
                  <Tr>
                    <Td>Year</Td>
                    <Td>{fulfillment?.year?.count ?? 0}</Td>
                  </Tr>
                </Tbody>
              </ChakraTable>
            </TableContainer>
          </Box>
        </Flex>
      </Box>

      {productLocations?.length > 0 && (
        <Box p={3} mb={3} bg="white" borderRadius="md">
          <Flex mb={3} justifyContent="space-between">
            <FormControl display="flex" alignItems="center">
              <Switch
                mr={2}
                id="filterLocation"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleFilterLocations(e.target.checked)
                }
              />
              <FormLabel htmlFor="filterLocation" mb="0">
                Hide Locations with zero count?
              </FormLabel>
            </FormControl>

            <Box>
              <CSVLink
                className="ui button mini"
                data={filteredProdLocations}
                filename="Inventory Ledger.csv"
                headers={headers}
                target="_blank"
              >
                Download as CSV{' '}
              </CSVLink>
            </Box>
          </Flex>
          <TableContainer>
            <ChakraTable variant="simple" size="sm">
              <Thead>
                <Tr>
                  <Th w="50%">Location</Th>
                  <Th w="50%">Count</Th>
                </Tr>
              </Thead>
              <Tbody>
                {filteredProdLocations?.map((i) => (
                  <Tr key={i?.id}>
                    <Td>{i.locationName}</Td>
                    <Td>
                      <Label size={'small'}>{i.total}</Label>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </ChakraTable>
          </TableContainer>
        </Box>
      )}

      <Box p={3} mb={3} bg="white" borderRadius="md">
        <Movement productId={productId} />
      </Box>

      {isAdjustModalOpen && (
        <AdjustmentModal
          open={isAdjustModalOpen}
          handleSubmit={handleAdjustSubmit}
          handleClose={() => setIsAdjustModalOpen(false)}
          sources={sources}
          lastLocation={lastLocation}
          prodLocations={productLocations}
          lastPrice={`${lastPrice}`}
          lastTags={lastTags}
          errorMessage={errorMessage.adjustment}
        />
      )}
    </>
  );
}
