import * as React from 'react';
import { Button } from 'semantic-ui-react';
import {
  useFeathers,
  useCheckboxes,
  useDebounce,
  PageHeaderRow,
  PreLoader,
} from '@inkcloud/components';
import { feathers } from '../../../bootstrap';
import { FilterForm } from './FilterForm';
import { Link } from 'react-router-dom';

const pathPrefix = '/store-front-content/hero-stacks';
const serviceName = 'store-front/content/hero-stacks';

interface ListProps {}

const { useState } = React;

const filterQueryMap = (v) => ({ name: v.name ? { $like: `${v.name}%` } : undefined });

export const List: React.FunctionComponent<ListProps> = (props) => {
  const [filter, setFilter] = useState({});
  const debouncedFilter = useDebounce(filter, 250);
  const [state, reload] = useFeathers('find', serviceName, [
    { query: { ...filterQueryMap(debouncedFilter) } },
  ]);
  const { queryResult, isLoading: isStateLoading } = state as any;
  const { selectedItems, clearAll, setChecked, someSelected, toggleAll } = useCheckboxes(
    queryResult ? (queryResult as any).data : []
  );

  const handleDelete = async () => {
    const toDelete = Object.keys(selectedItems).filter((si) => selectedItems[si]);
    await Promise.all(toDelete.map((i) => feathers.service(serviceName).remove(i)));
    clearAll();
    reload();
  };

  return (
    <div>
      <PageHeaderRow header="Hero Stacks" subheader="Store Front Content">
        <Link to={`${pathPrefix}/add`}>
          <Button content="New" primary size={'mini'} />
        </Link>
      </PageHeaderRow>

      <Link to={{ pathname: pathPrefix, search: '?page=1' }}>Page 1</Link>
      <Link to={{ pathname: pathPrefix, search: '?page=2' }}>Page 2</Link>
      <Link to={{ pathname: pathPrefix, search: '?page=3' }}>Page 3</Link>

      <FilterForm value={filter} onChange={(v) => setFilter(v)} onReset={() => setFilter({})} />
      <Link to={`${pathPrefix}/add`}>
        <Button type="button" value="Add" />
      </Link>
      <table>
        <thead>
          <tr>
            <th>
              <input type="checkbox" onChange={toggleAll} />
            </th>
            <th>ID</th>
            <th>Name</th>
            <th>Key</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {queryResult &&
            (queryResult as any).data.map((r) => (
              <tr key={r.id}>
                <td>
                  <input
                    type="checkbox"
                    value={r.id}
                    checked={selectedItems[r.id] || false}
                    onChange={setChecked}
                  />
                </td>
                <td>{r.id}</td>
                <td>{r.name}</td>
                <td>{r.key}</td>
                <td>
                  <Link to={`${pathPrefix}/edit/${r.id}`}>Edit</Link>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      {someSelected && <Button type="button" value="Delete" onClick={handleDelete} />}
    </div>
  );
};
