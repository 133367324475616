import {
  withDetailFeaturesInit,
  FeathersReduxPropsInterface,
  DetailFeaturesProps as DetailFeaturesProp,
} from '@inkcloud/ui-base';
import { services } from './feathers';

export const DetailWrapper = withDetailFeaturesInit(services);
export type DetailWrappedProps = FeathersReduxPropsInterface<any>;
export type DetailFeaturesProps = DetailFeaturesProp;
