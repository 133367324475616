import * as React from 'react';
import {
  Box,
  // Box, Box, Box,
  Heading,
  Text,
  Stack,
  StackDivider,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  HStack,
  Flex,
  Tag,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  GridItem,
} from '@chakra-ui/react';

import { Select } from 'chakra-react-select';

import type { ModelTypes } from '@inkcloud/icapi-types';
import { icapi } from '../../../../bootstrap/feathers';
import RelatedProductsTable from './RelatedProductsTable';

type PopulatedMSCustomer = Omit<ModelTypes.MicrositeCustomers, 'groups'> & {
  groups: ModelTypes.MicrositeCustomerGroups[];
};
type PopulatedMSProduct = Omit<ModelTypes.MicrositeProducts, 'product'> & {
  product: ModelTypes.ProductLegacy;
};

interface IOrderLimitTesterProps {}

const OrderLimitTester: React.FunctionComponent<IOrderLimitTesterProps> = (props) => {
  const [microsites, setMicrosites] = React.useState<ModelTypes.Microsites[]>([]);
  const [micrositeUsers, setMicrositeUsers] = React.useState<PopulatedMSCustomer[]>([]);
  const [products, setProducts] = React.useState<PopulatedMSProduct[]>([]);
  const [relatedProducts, setRelatedProducts] = React.useState<ModelTypes.ProductLegacy[]>([]);
  const [selectedMicrosite, setSelectedMicrosite] = React.useState<{
    label: string;
    value: string;
  } | null>(null);
  const [selectedUser, setSelectedUser] = React.useState<{ label: string; value: string } | null>(
    null
  );
  const [selectedProduct, setSelectedProduct] = React.useState<{
    label: string;
    value: string;
  } | null>(null);

  const selectedProductFull = products.find((p) => p._id === selectedProduct?.value);
  const selectedUserFull = micrositeUsers.find((p) => p._id === selectedUser?.value);
  const selectedMicrositeFull = microsites.find((p) => p._id === selectedMicrosite?.value);

  const [orderLimit, setOrderLimit] = React.useState<any>();
  const [orderLimitV2, setOrderLimitV2] = React.useState<any>();

  const foundGroup = selectedUserFull?.groups.find((g) => g.orderLimitsPerUser?.length);

  let relatedProductIds: string[] = [];
  if (selectedProductFull) {
    relatedProductIds = (
      selectedProductFull?.product?.compositeProducts?.map((p) => p.product as string) ?? []
    ).concat([selectedProductFull.product._id as string]);
  }
  const foundGroupOrderLimit = foundGroup?.orderLimitsPerUser?.find(
    (o) => relatedProductIds.includes(o.product as string) && o.enabled
  );

  const loadMicrosites = async () => {
    const result = (await icapi.service('microsites').find({
      query: {
        $select: ['name', 'key', 'customer'],
        $np: '1',
        $sort: { name: 1 },
      },
    })) as ModelTypes.Microsites[];

    setMicrosites(result);
  };

  const loadMicrositesUsers = async () => {
    if (!selectedMicrosite) {
      return;
    }
    const result = (await icapi.service('microsites/customers').find({
      query: {
        microsite: selectedMicrosite.value,
        $populate: ['groups'],
        $select: ['firstName', 'lastName'],
        $sort: { lastName: 1 },
        $np: '1',
      },
    })) as PopulatedMSCustomer[];

    setMicrositeUsers(result);
  };

  const loadMicrositesProducts = async () => {
    const result = (await (icapi.service('microsites/products') as any).find({
      query: {
        microsite: selectedMicrosite?.value,
        $populate: ['product'],
        $select: ['key', 'product'],
        $sort: { key: 1 },
        $np: '1',
      },
    })) as PopulatedMSProduct[];
    setProducts(result);
  };

  const loadOrderLimit = async () => {
    const result = await icapi.service('products-legacy/order-limit').create(
      {
        productId: selectedProductFull?.product._id as string,
        customerId: selectedMicrositeFull?.customer,
        micrositeUser: selectedUser?.value,
        tenant: selectedUserFull?.tenant as string,
        // Passed for typing purposes but not used here. Meant for backend even if we are looking at a composite product.
        isComposite: false,
      },
      {}
    );

    setOrderLimit(result);
  };

  const loadOrderLimitV2 = async () => {
    const result = await (icapi.service('products-legacy/order-limit/v2') as any).create(
      {
        productId: selectedProductFull?.product._id as string,
        customerId: selectedMicrositeFull?.customer,
        micrositeUser: selectedUser?.value,
        tenant: selectedUserFull?.tenant as string,
        // Passed for typing purposes but not used here. Meant for backend even if we are looking at a composite product.
        isComposite: false,
      },
      {}
    );

    setOrderLimitV2(result);
  };

  const loadRelatedProducts = async (productId: string, compositedProductIds: string[] = []) => {
    const $or: any = [];

    if (compositedProductIds?.length) {
      // Get the items in the composite
      $or.push({ _id: { $in: compositedProductIds } });
    }

    $or.push({
      parent: {
        $in: compositedProductIds.concat([productId]),
      },
    });

    const result = (await icapi.service('products-legacy').find({
      query: {
        $select: ['key', 'name', 'parent', 'hasVariants', 'compositedProducts'],
        $or,
        $np: '1',
      },
    })) as ModelTypes.ProductLegacy[];

    setRelatedProducts(result);
  };



  React.useEffect(() => {
    loadMicrosites();
  }, []);

  React.useEffect(() => {
    setSelectedProduct(null);
    setSelectedUser(null);

    if (selectedMicrosite) {
      loadMicrositesUsers();
      loadMicrositesProducts();
    }
  }, [selectedMicrosite]);

  React.useEffect(() => {
    if (!selectedUser || !selectedProduct) {
      return;
    }

    loadOrderLimit();
    loadOrderLimitV2();
  }, [selectedUser, selectedProduct]);

  React.useEffect(() => {
    if (!selectedProduct) {
      return;
    }


    loadRelatedProducts(
      selectedProduct.value,
      selectedProductFull?.product?.compositeProducts?.map((p) => p.product as string) ?? []
    );
  }, [selectedProduct]);

  // console.log({
  //   selectedUserFull,
  //   foundGroup,
  //   foundGroupOrderLimit,
  //   relatedProducts,
  // });

  return (
    <div>
      <h2>Order Limit Tester</h2>
      <hr />
      <Box bg="white" padding={4}>
        <Flex>
          <Box w={'25%'} p={4}>
            <Box>
              <Box>
                <form>
                  <FormControl>
                    <FormLabel>Microsite</FormLabel>
                    <Select
                      options={microsites.map((m) => ({
                        value: m._id as string,
                        label: m.name as string,
                      }))}
                      placeholder="Select a microsite"
                      onChange={(e) => setSelectedMicrosite(e)}
                      value={selectedMicrosite}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Microsite User</FormLabel>
                    <Select
                      options={micrositeUsers.map((m) => ({
                        value: m._id as string,
                        label: `${m.firstName} ${m.lastName}`,
                      }))}
                      placeholder="Select a microsite user"
                      onChange={(e) => setSelectedUser(e)}
                      value={selectedUser}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Product</FormLabel>
                    <Select
                      options={products.map((m) => ({
                        value: m._id as string,
                        label: m.key as string,
                      }))}
                      placeholder="Select a microsite user"
                      onChange={(e) => setSelectedProduct(e)}
                      value={selectedProduct}
                    />
                    <FormHelperText>
                      This list only includes published products to the selected microsite.
                    </FormHelperText>
                  </FormControl>
                </form>
              </Box>
            </Box>

            {JSON.stringify({ selectedProduct, selectedUser })}
          </Box>
          <Box w={'75%'} p={4}>
            {selectedProductFull && (
              <Box>
                <Box>
                  <Heading size="md">Limits Report</Heading>
                </Box>

                <Box>
                  <Stack divider={<StackDivider />} spacing="4">
                    <Box>
                      <Heading size="xs" textTransform="uppercase">
                        Summary
                      </Heading>
                      <HStack spacing="24px">
                        <Box>
                          <Text pt="2" fontSize="sm">
                            <div>SKU: {selectedProductFull.key}</div>
                            <div>Name: {selectedProductFull.product.staticName}</div>
                            {selectedProductFull.product.isComposite && (
                              <div>
                                <Tag colorScheme="blue">Composite</Tag>
                              </div>
                            )}
                          </Text>
                        </Box>
                        <Box>
                          <Text pt="2" fontSize="sm">
                            <div>Available Quantity: {orderLimit?.availableQuantity}</div>
                            {/* <div>Name: {selectedProductFull.product.staticName}</div> */}
                          </Text>
                        </Box>
                      </HStack>
                    </Box>
                    <Box>
                      <Heading size="xs" textTransform="uppercase">
                        User's Groups
                      </Heading>
                      <Text pt="2" fontSize="sm">
                        {foundGroupOrderLimit && (
                          <Table>
                            <Tbody>
                              <Tr>
                                <Th>Limit</Th>
                                <Td>{foundGroupOrderLimit.value}</Td>
                                <Th>Period</Th>
                                <Td>{foundGroupOrderLimit.period}</Td>
                                <Th>Status</Th>
                                <Td>
                                  {foundGroupOrderLimit.enabled && (
                                    <Tag size={'sm'} colorScheme={'green'}>
                                      Limit Enabled
                                    </Tag>
                                  )}
                                </Td>
                              </Tr>
                            </Tbody>
                          </Table>
                        )}
                        {!foundGroupOrderLimit && (
                          <div>No applicable group limits for currently selected product</div>
                        )}
                      </Text>
                    </Box>
                    <Box>
                      <Heading size="xs" textTransform="uppercase">
                        Related Products
                      </Heading>
                      <Text pt="2" fontSize="sm">
                        <RelatedProductsTable products={relatedProducts} />
                      </Text>
                    </Box>
                    <Box>
                      <Heading size="xs" textTransform={'uppercase'}>
                        Order Limit V2 Results
                      </Heading>
                      <Box>
                          {orderLimitV2 && (

                            <Table>
                            <Tbody>
                              {Object.keys(orderLimitV2).map((k) => (
                              <Tr key={k}>
                                <Td>{k}</Td>
                                <Td>
                                  {Array.isArray(orderLimitV2[k])
                                    ? (<pre>{JSON.stringify(orderLimitV2[k], null, 2)}</pre>)
                                    : orderLimitV2[k]?._id ? (<pre>JSON.stringify(orderLimitV2[k]</pre>)
                                  : orderLimitV2[k] === true ? 'true' : orderLimitV2[k] === false ? 'false' : orderLimitV2[k]}
                                </Td>
                              </Tr>
                              ))}
                            </Tbody>
                            </Table>

                          )}
                      </Box>
                    </Box>
                  </Stack>
                </Box>
              </Box>
            )}
          </Box>
        </Flex>
      </Box>
    </div>
  );
};

export default OrderLimitTester;
