import * as React from 'react';
import { Box } from '@chakra-ui/react';
import { PageHeaderRow } from '@inkcloud/components';
import { startOfMonth, endOfMonth, subMonths } from 'date-fns';
import ReportInputs from './ReportInputs';
import NonSplit from './NonSplit';
import SplitTable from './SplitTable';
import Visualization from './Visualization';
import StackedBarVisualization from './StackedBarVisualization';
import { Feathers } from '../../../bootstrap/feathers/feathers';
import { icapi } from '../../../bootstrap/feathers';

interface IFinancialReportProps {}

type FilterType = {
  [key: string]: {
    value?: {
      label?: string;
      value?: string;
    };
    start?: Date | undefined;
    end?: Date;
    isActive: boolean;
  };
};

const FinancialReport: React.FunctionComponent<IFinancialReportProps> = (props) => {
  const [filters, setFilters] = React.useState<FilterType>({
    period: {
      start: subMonths(startOfMonth(new Date()), 1),
      end: subMonths(endOfMonth(new Date()), 1),
      isActive: true,
    },
  });

  const [splits, setSplits] = React.useState(['time-month', 'customer']) as any;
  const [results, setResults] = React.useState<any>();

  const loadData = async () => {
    const r = await icapi.service('reports/v3/financial').create({
      filters: {
        period: {
          start: filters.period?.start?.toISOString() as string,
          end: filters.period?.end?.toISOString() as string,
        },
        customer: filters?.customer?.value?.value,
        requestor: filters?.requestor?.value?.value,
        assignedTo: filters?.assignedTo?.value?.value,
      },
      splits,
    });

    setResults(r);
  };

  const handleSplitChange = (updatedSplitsFlags) => {
    const updatedSplits = Object.keys(updatedSplitsFlags)
      .filter((k) => updatedSplitsFlags[k])
      .map((k) => k);
    setSplits(updatedSplits);
  };

  React.useEffect(() => {
    loadData();
  }, [filters, splits]);

  return (
    <Box>
      <PageHeaderRow header="Financial" subheader="Report" />
      <Box borderWidth={1} borderColor={'gray.200'} bgColor={'white'} p={8} mb={8}>
        <ReportInputs
          filters={filters}
          // startDate={filters.period.start}
          // endDate={filters.period.end}
          splits={splits}
          onChangeFilter={(v) => setFilters((f) => ({ ...f, ...v }))}
          onResetFilter={() => setFilters({})}
          onChange={handleSplitChange}
        />
      </Box>

      <Box borderWidth={1} borderColor={'gray.200'} bgColor={'white'} p={8} mb={8}>
        {splits.length === 0 && results && <NonSplit results={results} />}


        {splits.length > 0 && results && <StackedBarVisualization results={results} />}
        {splits.length > 0 && results && <Visualization results={results} />}
        {splits.length > 0 && results && <SplitTable results={results} splits={splits} />}
      </Box>
    </Box>
  );
};

export default FinancialReport;
