import * as React from 'react';
import { Button, Divider, Form, Modal, Grid } from 'semantic-ui-react';
import { Fields } from './Fields';
import { Preview } from './Preview';

interface IPreviewModalProps {
  onClose: () => void;
  onSubmit: (value: { password: string; confirmPassword: string }) => void;
  designTemplate: any;
}

const { useState } = React;

const mappedFields = (fields: any) => {
  const spreadFields = [];

  const updatedFields = fields
    ?.map((f) => {
      const isPrepend = f?.concat?.prepend;
      const hasPrepend = isPrepend?.enabled;

      const isAppend = f?.concat?.append;
      const hasAppend = isAppend?.enabled;

      const prependValue = hasPrepend
        ? isPrepend.type === 'static'
          ? isPrepend.value
          : isPrepend?.selectOptions?.[0]?.value
        : undefined;
      const appendValue = hasAppend
        ? isAppend.type === 'static'
          ? isAppend.value
          : isAppend?.selectOptions?.[0]?.value
        : undefined;

      if (f.type === 'select') {
        spreadFields.push(f?.data?.[0]);
      }

      return { ...f, prependValue, appendValue, value: f?.data?.[0]?.label };
    })
    .map((f) => {
      const foundField = spreadFields.find((s) => s?.[f.key] !== undefined);

      if (foundField) {
        return { ...f, value: foundField[f.key] };
      }

      return f;
    });

  return updatedFields;
};

export const PreviewModal: React.FunctionComponent<IPreviewModalProps> = (props) => {
  const { onClose, onSubmit, designTemplate } = props;
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [fields, setFields] = useState(mappedFields(designTemplate.fields));

  const onChange = (v) => {
    setFields(v);
    setIsDirty(true);
    setIsPreviewOpen(false);
  };

  return (
    <Modal open onClose={onClose} closeIcon size="large">
      <Modal.Header>Design Template - {designTemplate?.name ?? ''} - Preview</Modal.Header>
      <Modal.Content>
        <Grid>
          <Grid.Row>
            <Grid.Column width={7}>
              <Fields fields={fields} onChange={onChange} />
              <Button
                type="button"
                content="Refresh Preview"
                onClick={() => setIsPreviewOpen(true)}
              />
            </Grid.Column>
            <Grid.Column width={9}>
              {isPreviewOpen && (
                <Preview
                  open={isPreviewOpen}
                  designTemplateId={designTemplate._id}
                  fields={fields}
                />
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button negative onClick={onClose}>
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
