import * as React from 'react';
import { humanize } from 'underscore.string';
import { FormattedDate, FormattedTime } from 'react-intl';
import { Segment, Grid, Feed, Divider, Image, Icon, Message } from 'semantic-ui-react';
import { EmptyWrapper } from '@inkcloud/components';

import { ImageModal } from './../../design-jobs/ImageModal';
import Feathers from '../../../bootstrap/feathers';

const { useEffect, useState } = React;

export const MessageThread = (props: any) => {
  const { messageThread, customer } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState('');

  const handleImageModal = (url: any) => {
    setIsOpen(!isOpen);
    setImageUrl(url);
  };

  let userName = null;

  if (customer) {
    userName = `${customer.primaryContact?.firstName ?? ''} ${
      customer.primaryContact?.lastName ?? ''
    } `;
  }

  const baseUrl = `https://storage.googleapis.com/icstorage/`;

  return (
    <>
      <EmptyWrapper
        queryResult={messageThread}
        isStateLoading={false}
        icon="envelope outline"
        content="No message"
      >
        {messageThread.map((msg: any, idx: number) => (
          <Segment key={idx}>
            <Feed>
              <Feed.Event>
                <Feed.Label icon="user circle" />
                <Feed.Content>
                  <Feed.Summary>
                    <Grid>
                      <Grid.Row columns={2}>
                        <Grid.Column>
                          <p>
                            <a>
                              {msg.author?.type === 'customer'
                                ? ` ${userName}`
                                : `${msg.author?.user?.firstName ?? ''} ${
                                    msg.author?.user?.lastName ?? ''
                                  }` ?? ''}
                            </a>
                          </p>
                        </Grid.Column>
                        <Grid.Column textAlign="right">
                          <small>
                            <Feed.Date>
                              {' '}
                              {msg.createdAt && (
                                <>
                                  <FormattedDate value={msg.createdAt} />{' '}
                                  <FormattedTime value={msg.createdAt} />
                                </>
                              )}
                            </Feed.Date>
                          </small>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Feed.Summary>
                  <p style={{ whiteSpace: 'pre-wrap' }}>{msg.content}</p>
                  <Image.Group size="tiny">
                    {msg.attachments?.map((attached: any, index: number) =>
                      attached.extension === 'jpg' ||
                      attached.extension === 'jpeg' ||
                      attached.extension === 'png' ? (
                        <Image
                          key={index}
                          src={`${baseUrl}${attached.filename}`}
                          onClick={() => handleImageModal(`${baseUrl}${attached.filename}`)}
                        />
                      ) : (
                        <a
                          key={index}
                          href={`${baseUrl}${attached.filename}`}
                          style={{ fontSize: '1rem' }}
                          target="_blank"
                        >
                          <Icon name="download" />
                          {attached.originalFilename}
                        </a>
                      )
                    ) ?? null}
                  </Image.Group>
                </Feed.Content>
              </Feed.Event>
            </Feed>

            {isOpen && (
              <ImageModal isOpen={isOpen} imageUrl={imageUrl} onClose={handleImageModal} />
            )}
          </Segment>
        ))}
      </EmptyWrapper>
    </>
  );
};
