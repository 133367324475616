import * as React from 'react';
import { Page } from '@react-pdf/renderer';
import { PageSize, Orientation } from '@react-pdf/types';
import { PurchaseOrderContext } from './PurchaseOrderContext';

export interface IPurchaseOrderPageProps {
  children: React.ReactNode;
}

export const PurchaseOrderPage: React.FunctionComponent<IPurchaseOrderPageProps> = (props) => {
  const ctx = React.useContext(PurchaseOrderContext);
  const {
    config: { size, orientation },
  } = ctx;
  const { children } = props;
  return (
    <Page size={size} orientation={orientation}>
      {children}
    </Page>
  );
};
