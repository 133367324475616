/* eslint-disable no-var,import/first */
declare var localStorage: any;
declare var APPENV;

import * as superagent from 'superagent';
import * as io from 'socket.io-client';
import feathers, { Application } from '@feathersjs/feathers';
import socketio from '@feathersjs/socketio-client';
import rest from '@feathersjs/rest-client';
import auth from '@feathersjs/authentication-client';
import { ServiceTypes } from '@inkcloud/icapi-types';

const Feathers = feathers<any>();

export const icapi: Application<ServiceTypes> = Feathers;

let url =
  localStorage.getItem('mode') === 'sandbox'
    ? APPENV['sandbox-api'].connection
    : APPENV.api.connection;

const socketPort = location.port !== '80' ? `:${location.port}` : '';
const { socketConnection = `${location.hostname}${socketPort}`, socketPath } = APPENV.api;

Feathers.configure(rest(url).superagent(superagent));
Feathers.configure(auth({ storage: localStorage }));

const socket = feathers();
// console.log('socketConnection', socketConnection)
socket
  // @ts-ignore
  .configure(
    socketio(
      io(socketConnection, {
        transports: ['websocket'],
        upgrade: false,
        // path: socketPath,
      }),
      { timeout: 5000 } as any
    )
  )
  .configure(auth({ storage: localStorage }));
(socket as any).on('reauthentication-error', console.log);

const errorHandler = (error) => {
  let jwt = localStorage.getItem('feathers-jwt');
  Feathers.authenticate({ strategy: 'jwt', accessToken: jwt } as any).then((response) => {});
};

function feathersModeSwitch(mode) {
  if (mode === 'live') {
    Feathers.configure(rest(APPENV.api.connection).superagent(superagent));
  } else if (mode === 'sandbox') {
    Feathers.configure(rest(APPENV['sandbox-api'].connection).superagent(superagent));
  }
}

export { Feathers, feathersModeSwitch, socket, url };
