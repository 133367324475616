import * as React from 'react';
import { Button, Popup } from 'semantic-ui-react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { PDFPopup } from './../../../common/PDFPopup';

import {
  useList,
  generateOperatorOptions,
  Operator,
  ListPage,
  basicQueryMap,
} from '@inkcloud/components';

interface ListProps {}

export async function getFilterDefinition() {
  let definition = [
    {
      label: 'Name',
      value: 'name',
      operators: generateOperatorOptions([Operator.Like, Operator.Is]),
    },
  ];

  return definition;
}

const filterQueryMap = (values) => {
  let filterRows = basicQueryMap(values);

  filterRows = {
    ...filterRows,
  };

  return filterRows;
};

const serviceName = 'production/batches';
const pathPrefix = '/production/batches';

export const List: React.FunctionComponent<ListProps & RouteComponentProps<any>> = (props) => {
  const {
    match: { params },
    location,
    history,
  } = props;

  const useListValues = useList({
    feathers: [
      'find',
      serviceName,
      [
        {
          query: {
            $populate: ['jobs', 'resource', 'paper'],
            $sort: { createdAt: -1 },
          },
        },
      ],
    ],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  const tableDefintion = [
    {
      key: 'humanId',
      label: 'ID',
      cell: (v, r) => v,
    },
    {
      key: '',
      label: 'Paper',
      cell: (v, r) => <>{r?.paper?.name}</>,
    },
    {
      key: '',
      label: 'Resource',
      cell: (v, r) => <>{r?.resource?.name ?? 'Not Assigned'}</>,
    },
    {
      key: 'finalSheetCount',
      label: 'Sheets',
      cell: (v, r) => v,
    },
    {
      key: '',
      label: 'Sheet Size',
      cell: (v, r) => (
        <>
          {r?.sheetWidth} x {r?.sheetHeight}
        </>
      ),
    },
    {
      key: '',
      label: '',
      cell: (v, r) => (
        <>
          <Popup
            size="mini"
            content="Edit"
            trigger={
              <Link to={`${pathPrefix}/${r._id}`}>
                <Button size="mini" icon="edit" />
              </Link>
            }
          />
          <Popup
            size="mini"
            content="Manage Items"
            trigger={
              <Link to={`${pathPrefix}/${r._id}/items`}>
                <Button size="mini" icon="box" />
              </Link>
            }
          />
          <Popup
            size="mini"
            content="Print Ticket"
            trigger={
              <PDFPopup
                payload={{ productionBatchId: r._id }}
                service={'/printable/production-batch-job-ticket'}
                trigger={<Button size="mini" icon="print" />}
              />
            }
          />
        </>
      ),
      textAlign: 'right',
    },
  ];

  return (
    <>
      <ListPage
        header="Batches"
        subheader="Production"
        useListValues={useListValues}
        history={history}
        pathPrefix={pathPrefix}
        tableDefintion={tableDefintion}
        withCheckboxes={true}
        headerActions={null}
        getFilterDefinition={getFilterDefinition}
      />
    </>
  );
};
