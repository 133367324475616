import * as React from 'react';
import { Modal, Message } from 'semantic-ui-react';
import { FeathersDropdown } from '@inkcloud/components';

interface IAssignSalesPersonModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (v) => void;
  isSubmitting: boolean;
  errorMessage: string;
}

export const AssignSalesPersonModal: React.FunctionComponent<IAssignSalesPersonModalProps> = (
  props
) => {
  const { open, onClose, onSubmit, errorMessage } = props;
  return (
    <Modal open onClose={onClose}>
      <Modal.Header>Assign a Sales Person</Modal.Header>
      <Modal.Content>
        <FeathersDropdown
          search
          selection
          clearable
          placeholder="Sales Person..."
          serviceName="/sales-people"
          query={{
            $sort: { name: 1 },
            $np: 1,
          }}
          resultMapper={(r) => ({ key: r._id, value: r._id, text: `${r.firstName} ${r.lastName}` })}
          onChange={(e, data) => onSubmit(data.value)}
        />
        {errorMessage && <Message error content={errorMessage} />}
      </Modal.Content>
    </Modal>
  );
};
