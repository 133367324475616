import * as React from 'react';
import TextareaAutosize from 'react-textarea-autosize';

const textareaStyle: React.CSSProperties = {
  padding: '.5em',
  width: '100%',
  border: '1px solid #eaeaea',
  resize: 'none',
};

export interface ITextAreaProps {
  value: string;
  onChange: (value: string) => void;
  isDisabled?: boolean;
}

export function TextArea(props: ITextAreaProps) {
  const { value, onChange, isDisabled = false } = props;
  return (
    <TextareaAutosize
      value={value}
      style={{ ...(textareaStyle as any), height: '100%', flexGrow: 2 }}
      onChange={(e) => onChange(e.target.value)}
      disabled={isDisabled}
    />
  );
}
