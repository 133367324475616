import * as React from 'react';
import {
  Button,
  Segment,
  Table,
  Grid,
  Input,
  Icon,
  Checkbox,
  Divider,
  Message,
} from 'semantic-ui-react';
import { humanize } from 'underscore.string';
import { ItemModal } from './ItemModal';

import * as Cleave from 'cleave.js/react';

import { useDebounce } from '@inkcloud/components';

import { readableAddOns } from './../../../common/OrderItemCard2';

import {
  addOnFields,
  rcOptions,
  dynamicAddOns,
  bcslitOptions,
} from './../../order-builder/calculator/AddOnSet';

import { payloadMapper } from './helper';

import { hasMetadataLang } from './List';
import { Feathers } from '../../../bootstrap/feathers/feathers';

const allAddOns = [
  ...addOnFields,
  ...dynamicAddOns,
  { key: 'rc', options: rcOptions },
  { key: 'bcslit', options: bcslitOptions },
];

interface ItemsProps {
  data: any;
  id: string;
  status: string;
  addOns: any[];
  onReload: () => void;
  onChange: (v: any, idx: number) => void;
  onChangeExcluded: (data: any) => void;
}
const baseUrl = `https://storage.googleapis.com/icstorage/`;

const { useState, useEffect } = React;
export const Items: React.FunctionComponent<ItemsProps> = (props) => {
  const { data, id, status, addOns, onReload, onChange, onChangeExcluded } = props;

  const [isOpenItemModal, setIsOpenItemModal] = useState(false);
  const [item, setItem] = useState(null);

  // get the value from item
  const [value, setValue] = useState(null);
  const [isChanging, setIsChanging] = useState(false);

  const debounceValue = useDebounce(value, 350);

  const [errorMessage, setErrorMessage] = useState({ duplicate: '', others: '' });
  const [isLoading, setIsLoading] = useState({ duplicate: false });

  const handleModify = (isOpenItemModal: boolean, item: any) => {
    setIsOpenItemModal(isOpenItemModal);
    setItem(item);
  };

  const handleDuplicate = async (itemId: string) => {
    setErrorMessage({ ...errorMessage, duplicate: '' });
    setIsLoading({ ...isLoading, duplicate: true });
    try {
      const res = await Feathers.service('/custom-quotes/duplicate-item').create(
        {
          customQuoteId: id,
          sourceItemId: itemId,
        },
        { query: {} }
      );

      if (res) {
        onReload();
        setIsLoading({ ...isLoading, duplicate: false });
      }
    } catch (e) {
      setIsLoading({ ...isLoading, duplicate: false });
      e.code && e.code < 500
        ? setErrorMessage({ ...errorMessage, duplicate: e.message })
        : setErrorMessage({
            ...errorMessage,
            duplicate: 'We are experiencing technical difficulties. Please try again',
          });
    }
  };

  const handleSubmitItem = async () => {
    setErrorMessage({ ...errorMessage, others: '' });
    const payload = payloadMapper(value, id);

    try {
      setIsChanging(false);
      const res: any = await Feathers.service('/custom-quotes/modify-item').create(payload, {
        query: {},
      });
    } catch (e) {
      const errMsg =
        e?.code < 500 ? e.message : 'We are experiencing technical difficulties. Please try again';
      setErrorMessage({ ...errorMessage, others: errMsg });
    }

    setValue(null);
  };

  useEffect(() => {
    isChanging && handleSubmitItem();
  }, [debounceValue]);

  const dimension = (productType: string, dimension: any, unitType: string, type?: string) => {
    if (!type) {
      return `${dimension?.width ?? 0} x ${dimension?.height ?? 0} ${
        unitType === 'inch' ? 'in' : unitType || ''
      }`;
    } else if (productType === 'packaging' && type === 'finished') {
      return `${dimension?.width ?? 0} x ${dimension?.length ?? 0} x ${dimension?.depth ?? 0} ${
        unitType === 'inch' ? 'in' : unitType || ''
      }`;
    }
  };

  return (
    <>
      {data?.items?.map((item: any, index: number) => (
        <div key={index}>
          <Segment attached="top">
            <Grid columns={3}>
              <Grid.Column>
                <Table compact size="small" basic="very">
                  <Table.Body>
                    {
                      <Table.Row>
                        <Table.Cell collapsing style={{ border: 0 }}>
                          <strong>Product Type</strong>
                        </Table.Cell>
                        <Table.Cell style={{ border: 0 }}>{humanize(item.type)}</Table.Cell>
                      </Table.Row>
                    }

                    {(!hasMetadataLang(item) && (
                      <Table.Row verticalAlign="top">
                        <Table.Cell collapsing style={{ border: 0 }}>
                          <strong>Dimension</strong>
                        </Table.Cell>
                        <Table.Cell style={{ border: 0 }}>
                          <Grid columns="equal">
                            {item.type !== 'wide-format' && item.finishedDimensions && (
                              <Grid.Column>
                                {dimension(
                                  item.type,
                                  item.finishedDimensions,
                                  item.finishedDimensions?.unitType ?? '',
                                  'finished'
                                )}
                                <div>Finished</div>
                              </Grid.Column>
                            )}
                            <Grid.Column>
                              {dimension(
                                item.type,
                                item.flatDimensions,
                                item.flatDimensions?.unitType ?? ''
                              )}
                              <div>Flat</div>
                            </Grid.Column>
                          </Grid>
                        </Table.Cell>
                      </Table.Row>
                    )) ||
                      null}
                    {(item.quantity && (
                      <Table.Row>
                        <Table.Cell collapsing style={{ border: 0 }}>
                          <strong>Quantity</strong>
                        </Table.Cell>
                        <Table.Cell style={{ border: 0 }}>{item.quantity}</Table.Cell>
                      </Table.Row>
                    )) ||
                      null}
                  </Table.Body>
                </Table>
              </Grid.Column>
              <Grid.Column>
                <Table compact size="small" basic="very">
                  <Table.Body>
                    {(item.attributes?.length > 0 && (
                      <Table.Row verticalAlign="top">
                        <Table.Cell collapsing textAlign="right" style={{ border: 0 }}>
                          <strong>Attributes</strong>
                        </Table.Cell>
                        <Table.Cell style={{ border: 0 }}>
                          {item.attributes.map((a: any, idx: number) => (
                            <div key={idx}>
                              {' '}
                              {humanize(a.type)} - {a.name ? a.name : a.others}
                            </div>
                          ))}
                        </Table.Cell>
                      </Table.Row>
                    )) ||
                      null}
                    {item?.metadata?.map((meta: any, index: number) => {
                      if (meta.key === 'tr_type') {
                        return (
                          <Table.Row verticalAlign="top" key={index}>
                            <Table.Cell collapsing width={8} style={{ border: 0 }}>
                              <strong>Type</strong>
                            </Table.Cell>
                            <Table.Cell style={{ border: 0 }}>{humanize(meta.value)}</Table.Cell>
                          </Table.Row>
                        );
                      }
                      if (meta.key === 'tr_category') {
                        return (
                          <Table.Row verticalAlign="top" key={index}>
                            <Table.Cell collapsing width={8} style={{ border: 0 }}>
                              <strong>Category</strong>
                            </Table.Cell>
                            <Table.Cell style={{ border: 0 }}>{meta.value}</Table.Cell>
                          </Table.Row>
                        );
                      }
                      if (meta.key === 'tr_source_name') {
                        return (
                          <Table.Row verticalAlign="top" key={index}>
                            <Table.Cell collapsing width={8} style={{ border: 0 }}>
                              <strong>Source Language</strong>
                            </Table.Cell>
                            <Table.Cell style={{ border: 0 }}>{meta.value}</Table.Cell>
                          </Table.Row>
                        );
                      }
                      if (meta.key === 'tr_dest_name') {
                        return (
                          <Table.Row verticalAlign="top" key={index}>
                            <Table.Cell collapsing width={8} style={{ border: 0 }}>
                              <strong>Destination Language</strong>
                            </Table.Cell>
                            <Table.Cell style={{ border: 0 }}>{meta.value}</Table.Cell>
                          </Table.Row>
                        );
                      }
                    }) ?? null}

                    {(item.addOns?.length > 0 && (
                      <Table.Row>
                        <Table.Cell collapsing textAlign="right" style={{ border: 0 }}>
                          <strong>Add Ons</strong>
                        </Table.Cell>
                        <Table.Cell style={{ border: 0 }}>
                          {item.addOns.map((a: any, index: number) => {
                            let readable =
                              a.addOnId &&
                              readableAddOns(addOns, allAddOns, a.addOnId, a.primaryData);

                            return (
                              <span key={index}>
                                {readable}
                                {index < item.addOns.length - 1 && ', '}
                              </span>
                            );
                          })}
                        </Table.Cell>
                      </Table.Row>
                    )) ||
                      null}

                    {(item.addOnsOther && (
                      <Table.Row verticalAlign="top">
                        <Table.Cell style={{ border: 0 }} textAlign="right">
                          <strong>Other Add Ons</strong>
                        </Table.Cell>
                        <Table.Cell style={{ border: 0 }}>{item.addOnsOther}</Table.Cell>
                      </Table.Row>
                    )) ||
                      null}

                    {((item?.timeMin || item?.timeMax) && (
                      <Table.Row verticalAlign="top">
                        <Table.Cell style={{ border: 0 }} textAlign="right">
                          <strong>Turnaround Time</strong>
                        </Table.Cell>
                        <Table.Cell style={{ border: 0 }}>
                          {item?.timeMin} - {item?.timeMax} day{`${item?.timeMax > 1 ? 's' : ''}`}
                        </Table.Cell>
                      </Table.Row>
                    )) ||
                      null}
                  </Table.Body>
                </Table>
              </Grid.Column>
              <Grid.Column>
                <Table compact size="small" basic="very">
                  <Table.Body>
                    {(item.additionalInfo && (
                      <Table.Row>
                        <Table.Cell style={{ border: 0 }}>
                          <strong>Additional Note:</strong>
                        </Table.Cell>
                      </Table.Row>
                    )) ||
                      null}
                    {(item.additionalInfo && (
                      <Table.Row>
                        <Table.Cell style={{ border: 0 }}>{item.additionalInfo}</Table.Cell>
                      </Table.Row>
                    )) ||
                      null}

                    {(item.miscUploads?.length > 0 && (
                      <Table.Row>
                        <Table.Cell style={{ border: 0 }}>
                          {item.miscUploads.map((upload: any, index: number) => (
                            <div key={index}>
                              <a href={`${baseUrl}${upload.cloudFilename}`} target="_blank">
                                <Icon name="file alternate outline" />
                                {upload.originalFilename}
                              </a>
                            </div>
                          ))}
                        </Table.Cell>
                      </Table.Row>
                    )) ||
                      null}
                  </Table.Body>
                </Table>
              </Grid.Column>
            </Grid>
          </Segment>
          <Segment attached="bottom">
            <Grid>
              <Grid.Column width={6}>
                <Checkbox
                  label="Exclude"
                  key={item._id}
                  value={item._id}
                  onChange={(e, data) => onChangeExcluded(data)}
                />{' '}
                <Button
                  size="tiny"
                  disabled={status === 'final'}
                  content="Modify"
                  onClick={() => handleModify(true, item)}
                />
                <Button
                  size="tiny"
                  loading={isLoading.duplicate}
                  disabled={isLoading.duplicate || status === 'final'}
                  content="Duplicate"
                  onClick={() => handleDuplicate(item._id)}
                />
              </Grid.Column>
              <Grid.Column width={10}>
                <Grid columns="equal">
                  <Grid.Column className="ui form tiny" style={{ textAlign: 'right' }}>
                    <div className="inline field">
                      <label>Reference </label>
                      <div className="ui input">
                        <Input
                          name="customerReferenceName"
                          value={item.customerReferenceName}
                          disabled={status === 'final'}
                          onChange={(e) => {
                            setIsChanging(true);
                            setValue({ ...item, customerReferenceName: e.target.value });
                            onChange({ ...item, customerReferenceName: e.target.value }, index);
                          }}
                        />
                      </div>
                    </div>
                  </Grid.Column>
                  {data.selectedShippingMethod !== 'SHIPPING_DIGITAL_DELIVERY' && (
                    <Grid.Column className="ui form tiny" style={{ textAlign: 'right' }}>
                      <div className="inline field">
                        <label>Weight </label>
                        <div className="ui input">
                          <Input
                            name="weight"
                            value={item.weight}
                            disabled={status === 'final'}
                            onChange={(e) => {
                              setIsChanging(true);
                              setValue({ ...item, weight: e.target.value });
                              onChange({ ...item, weight: e.target.value }, index);
                            }}
                          />
                        </div>
                      </div>
                    </Grid.Column>
                  )}
                  <Grid.Column className="ui form tiny" style={{ textAlign: 'right' }}>
                    <div className="inline field">
                      <label>Price </label>
                      <div className="ui input">
                        <Cleave
                          options={{
                            numeral: true,
                            rawValueTrimPrefix: true,
                            numeralPositiveOnly: true,
                            numeralThousandsGroupStyle: 'thousand',
                            prefix: '$',
                          }}
                          name="price"
                          value={item.price || ''}
                          disabled={status === 'final'}
                          onChange={(e) => {
                            setIsChanging(true);
                            setValue({ ...item, price: e.target.rawValue });
                            onChange({ ...item, price: e.target.rawValue }, index);
                          }}
                        />
                      </div>
                    </div>
                  </Grid.Column>
                </Grid>
              </Grid.Column>
            </Grid>
            {errorMessage.others && (
              <div style={{ textAlign: 'right' }}>
                <Message error content={errorMessage.others} />
              </div>
            )}
          </Segment>
          {(data?.items?.length > 1 && <Divider hidden />) || ''}
        </div>
      ))}

      {isOpenItemModal && (
        <ItemModal
          item={item}
          data={data}
          id={id}
          addOns={addOns}
          isOpen={isOpenItemModal}
          onReload={onReload}
          onClose={() => handleModify(false, null)}
        />
      )}
    </>
  );
};
