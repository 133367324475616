import * as React from 'react';
import { Modal, Button, Message, Loader, Dimmer } from 'semantic-ui-react';

interface PrintModalProps {
  isOpen: boolean;
  url: string;
  onClose: () => void;
  errorPrint: string;
}

export const PrintModal: React.FunctionComponent<PrintModalProps> = (props) => {
  const { isOpen, url, onClose, errorPrint } = props;

  return (
    <Modal size="tiny" open={isOpen} onClose={onClose} closeIcon>
      <Modal.Header>Print</Modal.Header>
      <Modal.Content>
        {!errorPrint && !url && (
          <Dimmer active inverted>
            <Loader inverted content="Loading" />
          </Dimmer>
        )}
        {errorPrint && <Message error content={errorPrint} />}
        {url && (
          <Button as="a" href={url} target="_blank" onClick={onClose} content="Download PDF" />
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button color="red" content="Cancel" onClick={onClose} />
      </Modal.Actions>
    </Modal>
  );
};
