import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { PageHeaderRow, PreLoader, useFeathers } from '@inkcloud/components';
import { humanize } from 'underscore.string';
import { FormattedDate, FormattedTime } from 'react-intl';
import { Formik, Form as FormikForm, Field, ErrorMessage } from 'formik';
import {
  Segment,
  Grid,
  Button,
  Feed,
  Header,
  Dropdown,
  Divider,
  Label,
  Table,
  Message,
  Modal,
  Input,
  Form,
  TextArea,
} from 'semantic-ui-react';
import feathers, { icapi } from '../../bootstrap/feathers';

import { Note } from './Note';
import { DesignJobsModal } from './DesignJobsModal';
import { ApprovalModal } from './ApprovalModal';

import { Info } from './Info';

import { connect } from 'react-redux';

interface DetailProps {}

export interface ConnectedProps {
  user?: any;
}

const { useState, useEffect } = React;

const serviceName = 'design-jobs';
const service = feathers.service(`${serviceName}/notes`);
const commonParams = { query: {} };

export const Detail: React.FunctionComponent<
  DetailProps & ConnectedProps & RouteComponentProps<{ id?: string }>
> = (props) => {
  const {
    match: {
      params: { id },
    },
    history,
    user,
  } = props;

  const [isOpenApproval, setIsOpenApproval] = useState(false);
  const [userType, setUserType] = useState('');
  const [orderItem, setOrderItem] = useState(null);
  const [salesRep, setSalesRep] = useState(null);

  let search = new URLSearchParams(location.search);
  let approval = search.get('approval');

  const [state, reload] = useFeathers(
    'get',
    serviceName,
    [
      id,
      {
        query: {
          $populate: [
            'customer',
            'notes.user',
            'notes.attachments',
            'user.roles2',
            'tenant',
            'order-item',
          ],
        },
      },
    ],
    true
  );

  useEffect(() => {
    if (approval === '1') {
      setIsOpenApproval(true);
    }
  }, [approval]);

  useEffect(() => {
    const loadUserRoles = async () => {
      const result: any = await feathers.service('users').get(user._id, {
        query: {
          $populate: ['roles2'],
          $select: ['roles2'],
        },
      });
      const userKey = result.roles2 && result.roles2.find((r: any) => r.key === 'sales-rep');

      userKey ? setUserType('customer') : setUserType('designer');
    };
    loadUserRoles();

    const loadOrderItem = async () => {
      const res: any = await feathers.service('order-items').get(state.data.orderItem, {
        query: { $populate: ['attributes.value', 'order'] },
      });

      /**
       * Note left by Badger Sales reps are showing as undefined.
       * This section is to get the names to show properly
       */
      const orderTemp = { ...res.order };
      const orderPopulated = await icapi
        .service('orders')
        .get(orderTemp._id, { query: { $populate: ['salesPerson'] } });
      // Restore Order ID in case it is used elsewhere
      res.order = res.order._id;
      setSalesRep(orderPopulated.salesPerson);
      // -----------------------------------------------------------------

      setOrderItem(res);
    };
    if (state.data) {
      loadOrderItem();
    }
  }, [state.data]);

  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [isStatusLoading, setIsStatusLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    message: '',
    status: '',
  });

  const handleModal = (title?: string) => {
    setIsOpen(!isOpen);
    setTitle(title);
  };

  const handleApprovalModal = () => {
    setIsOpenApproval(false);
    history.push(location.pathname);
  };

  const handleStatus = async (e) => {
    setIsStatusLoading(true);
    setErrorMessage({
      ...errorMessage,
      status: '',
    });

    try {
      const res: any = await feathers.service(`${serviceName}/status`).create(
        {
          orderItem: state.data.orderItem,
          sender: userType,
          status: e,
        },
        commonParams
      );

      reload();
      setIsStatusLoading(false);
      handleApprovalModal();
    } catch (e) {
      setErrorMessage({
        ...errorMessage,
        status:
          e.code && e.code < 500
            ? e.message
            : 'We are experiencing technical difficulties. Please try again',
      });
    }
  };

  const handleSubmit = async (values: any) => {
    setErrorMessage({
      ...errorMessage,
      message: '',
    });

    let payload: any = {
      ...values,
      orderItem: state.data.orderItem,
      sender: userType,
      user: user._id,
      isPrivate: title === 'Private Note' ? true : false,
      isRevision: title === 'Create Revision' ? true : false,
    };

    try {
      await feathers.service(`${serviceName}/notes`).create(payload, commonParams);

      setIsOpen(!isOpen);
      reload();
    } catch (e) {
      setErrorMessage({
        ...errorMessage,
        message:
          e.code && e.code < 500
            ? e.message
            : 'We are experiencing technical difficulties. Please try again',
      });
    }
  };

  const statuses = ['reviewing-reqs', 'pending-customer-approval', 'in-design', 'complete', 'void'];

  // if complete status then dropdown will be disable and cannot be change

  if (!state.data || !userType) {
    return null;
  }

  const customerPrimaryContact = state.data.customer.primaryContact;
  const isBadger =
    state.data.tenant.domainName === 'badger.inkcloud9.com' ||
    state.data.tenant.domainName === 'oecgraphics.com';

  const approvalRevision = state.data.notes.filter(
    (note: any) => note.revisionOrdinal === state.data.revision
  );

  const reversedNotes = state.data.notes.sort((a, b) => {
    return (new Date(b.createdAt) as any) - (new Date(a.createdAt) as any);
  });

  // Hack to show Sales rep names for Badger
  if (salesRep) {
    state.data.notes = state.data.notes.map((n) => ({
      ...n,
      user: n.sender === 'customer' ? { ...salesRep } : n.user,
    }));
  }

  let buttonTemplate = null;

  if (userType === 'designer') {
    buttonTemplate = (
      <>
        <Button
          primary
          onClick={() => handleModal('Message Customer')}
          content="Message Customer"
          icon="envelope outline"
          labelPosition="left"
          size="mini"
          style={{ marginBottom: '.5em' }}
        />
        <Button
          primary
          onClick={() => handleModal('Private Note')}
          content="Private Note"
          icon="sticky note outline"
          labelPosition="left"
          size="mini"
          style={{ marginBottom: '.5em' }}
        />
        <Button
          primary
          onClick={() => handleModal('Create Revision')}
          content="Create Revision"
          icon="add"
          labelPosition="left"
          size="mini"
          style={{ marginBottom: '.5em' }}
        />
      </>
    );
  } else {
    if (isBadger) {
      buttonTemplate = (
        <>
          {state.data.status !== 'complete' && state.data.revision > 0 && (
            <Button
              primary
              onClick={() => handleStatus('complete')}
              content="Approve Revision"
              icon="thumbs up outline"
              labelPosition="left"
              size="mini"
              style={{ marginBottom: '.5em' }}
            />
          )}

          <Button
            primary
            onClick={() => handleModal('Message Designer')}
            content="Message Designer"
            icon="envelope"
            labelPosition="left"
            size="mini"
            style={{ marginBottom: '.5em' }}
          />
        </>
      );
    } else {
      buttonTemplate = (
        <>
          {state.data.status !== 'complete' && state.data.revision > 0 && (
            <Button
              primary
              onClick={() => handleStatus('complete')}
              content="Approve Revision"
              icon="thumbs up outline"
              labelPosition="left"
              size="mini"
              style={{ marginBottom: '.5em' }}
            />
          )}
          <Button
            primary
            onClick={() => handleModal('Message Designer')}
            content="Message Designer"
            icon="envelope"
            labelPosition="left"
            size="mini"
            style={{ marginBottom: '.5em' }}
          />
          <Button
            primary
            onClick={() => handleModal('Private Note')}
            content="Private Note"
            icon="sticky note outline"
            labelPosition="left"
            size="mini"
            style={{ marginBottom: '.5em' }}
          />
          <Button
            primary
            onClick={() => handleModal('Create Revision')}
            content="Create Revision"
            icon="add"
            labelPosition="left"
            size="mini"
            style={{ marginBottom: '.5em' }}
          />
        </>
      );
    }
  }

  return (
    <>
      <Grid stackable>
        <Grid.Row>
          <Grid.Column>
            {!state.data.assignedDesigner && (
              <Message error size="small" content="There is no designer assigned to this job." />
            )}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={6}>
            <PageHeaderRow header="Design Jobs" subheader="" />
            <Segment padded style={{ marginTop: '.5em' }}>
              <Message size="small" color="blue">
                <Dropdown
                  text={humanize(state.data.status)}
                  fluid
                  loading={isStatusLoading}
                  disabled={
                    (state.data.status === 'complete' && userType !== 'designer') ||
                    (userType === 'customer' && isBadger)
                  }
                >
                  <Dropdown.Menu style={{ width: '100%' }}>
                    {statuses.map((s: string, index: number) => (
                      <Dropdown.Item key={index} onClick={() => handleStatus(s)}>
                        {humanize(s)}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </Message>
              {errorMessage.status && <Message error size="tiny" content={errorMessage.status} />}

              {orderItem && <Info orderItem={orderItem} revision={state.data.revision} />}
            </Segment>
          </Grid.Column>
          <Grid.Column width={10}>
            <Grid>
              <Grid.Column textAlign="right">{buttonTemplate}</Grid.Column>
            </Grid>
            <Segment padded>
              {state.data.notes &&
                state.data.notes.length > 0 &&
                reversedNotes.map((n: any, index: number) => (
                  <React.Fragment key={index}>
                    {n.isPrivate ? (
                      userType === 'designer' && user?._id === n.user?._id ? (
                        <Note
                          values={n}
                          name={customerPrimaryContact}
                          userType={userType}
                          isBadger={isBadger}
                          user={user}
                        />
                      ) : null
                    ) : (
                      <Note
                        values={n}
                        name={customerPrimaryContact}
                        isBadger={isBadger}
                        userType={userType}
                        user={user}
                      />
                    )}
                  </React.Fragment>
                ))}
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      {isOpen && (
        <DesignJobsModal
          errorMessage={errorMessage.message}
          title={title}
          isOpen={isOpen}
          tenantId={user && user.business}
          onChangeModal={handleModal}
          onSubmit={handleSubmit}
        />
      )}

      {
        <ApprovalModal
          isOpen={isOpenApproval}
          isLoading={isStatusLoading}
          value={approvalRevision[0]}
          customer={customerPrimaryContact}
          userType={userType}
          isBadger={isBadger}
          user={user}
          onChangeStatus={handleStatus}
          onClose={handleApprovalModal}
        />
      }
    </>
  );
};

const mapStateToProps = (state): ConnectedProps => ({
  user: state.auth.user || {},
});

const mapDispatchToProps = {};

export const DesignJob = connect(mapStateToProps, mapDispatchToProps)(Detail as any);
