import React, { useState } from 'react';
import { Button, Segment, Image, Popup } from 'semantic-ui-react';
import { Link, RouteComponentProps } from 'react-router-dom';
import {
  useList,
  ListTable,
  EmptyWrapper,
  PageHeaderRow,
  PaginatorWrapper,
} from '@inkcloud/components';
import Feathers from '../../../bootstrap/feathers';

import { AttributeCheckModal } from './AttributeCheckModal';
import { FilterForm } from './FilterForm2';

// const filterQueryMap = (values) => {

//   console.log('values', values);

//   let filterRows = basicQueryMap(values, {});

//   console.log('filterRows', filterRows);

//   // Map attributes as needed by API
//   const prefix = 'entityAttribute:';
//   const attributes = Object.keys(filterRows)
//     .filter(r => r.startsWith(prefix))
//     .reduce((acc, cur) => ({ ...acc, [cur]: filterRows[cur]?.$like }), {});

//   console.log('attributes', attributes);

//   // Delete the original attribute valuess so they are not submitted
//   // Object.keys(filterRows)
//   //   .filter(r => r.startsWith(prefix))
//   //   .forEach(r => delete filterRows[r]);

//   console.log('after ', filterRows);

//   filterRows = {
//     ...filterRows,
//     entityAttribute: attributes,
//   }

//   return filterRows
// }

const filterQueryMap = (values) => {
  const filterMap: any = {
    name: values.name ? { $LIKE: values.name } : undefined,
    entityAttribute: values?.entityAttribute || undefined,
  };

  return [].reduce(
    (m, field) => ({ ...m, [field]: values[field] ? values[field] : undefined }),
    filterMap
  );
};

const serviceName = '/products-legacy/attributes';
const pathPrefix = '/products/attributes';

const baseUrl = `https://storage.googleapis.com/icstorage/`;

// export async function getFilterDefinition() {
//   const attributeTypes: any = await Feathers.service('/products-legacy/attribute-types')
//     .find({
//       query: {
//         $np: 1,
//         $sort: { name: 1 },
//         isDeleted: { $ne: true },
//         _id: { $ne: 'QUANTITY' }
//       }
//     })

//   const attributeFilters = attributeTypes.map((attr) => ({
//     label: `Attribute:${attr.name}`,
//     value: attr._id,
//     operators: generateOperatorOptions([Operator.Like]),
//     valueOptions: [],
//     asyncValueOptions: async () =>
//       Feathers.service('products-legacy/attributes').find({
//         query: {
//           $np: '1',
//           $sort: { name: 1 },
//           entityAttribute: attr._id,
//         }
//       }).then(results => results.map(r => ({ value: r.name, label: r.name }))),
//     type: 'select',
//   }));

//   console.log('attributeFilters', attributeFilters);

//   return attributeFilters
// }

export const List: React.FunctionComponent<RouteComponentProps> = (props) => {
  const {
    match: { params },
    location,
    history,
  } = props;

  const [isCheckModalOpen, setIsCheckModalOpen] = useState(false);
  const [attribute, setAttribute] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState({
    duplicate: false,
    export: false,
  });

  const [errorMessage, setErrorMessage] = useState({
    duplicate: '',
    export: '',
  });

  const {
    state,
    reload,
    handleDelete,
    selectedItems,
    clearAll,
    setChecked,
    someSelected,
    toggleAll,
    filter,
    setFilter,
    sort,
    handleSort,
  } = useList({
    feathers: [
      'find',
      serviceName,
      [
        {
          query: {
            $populate: ['entityAttribute'],
          },
        },
      ],
    ],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  }) as any;

  const tableDefinition = [
    {
      key: 'entityAttribute',
      label: 'Type',
      cell: (v, r) => <span>{r?.entityAttribute?.name}</span>,
    },
    { key: 'name', label: 'Name', cell: (v, r) => r?.name },
    { key: 'key', label: 'Key', cell: (v, r) => r?.hrCode },
    {
      key: '',
      label: 'Image',
      cell: (v, r) =>
        r?.images?.[0]?.url && <Image src={`${baseUrl}${r?.images?.[0]?.url}`} size="tiny" />,
    },
    { key: 'priority', label: 'Priority', cell: (v) => v },
    {
      key: '',
      label: '',
      cell: (v, r) => (
        <Popup
          size="mini"
          content="Edit"
          trigger={
            <div>
              <Popup
                trigger={
                  <Button
                    type="button"
                    size="mini"
                    icon="check"
                    onClick={() => {
                      setIsCheckModalOpen(true);
                      setAttribute(r);
                    }}
                  />
                }
                content="Usage Check"
              />
              <Link to={`${pathPrefix}/${r._id}`}>
                <Button size={'mini'} icon={'edit'} />
              </Link>
            </div>
          }
        />
      ),
      textAlign: 'right',
    },
  ];

  let isFiltered = false;
  if (filter) {
    isFiltered = Object.keys(filter).reduce(
      (prev, field) =>
        prev ||
        !!(Array.isArray(filter[field]) ? filter[field]?.some((f) => f.value) : filter[field]),
      false
    );
  }

  let queryFilter;
  if (isFiltered) {
    queryFilter = filter?.rows?.reduce(
      (acc, cur) =>
        Object.assign(acc, {
          [cur.selectedFact?.replace(/\./g, '')]: Array.isArray(cur.value)
            ? cur.value?.map((v) => v.value)
            : cur.value?.value,
        }),
      {}
    );
  }

  const handleExport = async () => {
    setErrorMessage({
      ...errorMessage,
      export: '',
    });
    setIsSubmitting({
      ...isSubmitting,
      export: true,
    });
    try {
      const res: any = await Feathers.service('/reports/v3/results').create({
        reportKey: 'product-attributes-export',
        reportName: 'Product Attributes Export',
        query: {
          ...queryFilter,
        },
      });

      history.push('/reports/results');
    } catch (e) {
      setErrorMessage({
        ...errorMessage,
        export: 'Error while exporting.',
      });
    }

    setIsSubmitting({
      ...isSubmitting,
      export: false,
    });
  };

  const { queryResult, isLoading: isStateLoading } = state as any;

  const resolvedQueryResult = queryResult?.data ? queryResult.data : queryResult;

  return (
    <>
      {/* <ListPage
        header={'Attributes'}
        subheader="Product"
        useListValues={useListValues}
        history={history}
        pathPrefix={pathPrefix}
        tableDefintion={tableDefintion}
        withCheckboxes={true}
        headerActions={headerActions}
        getFilterDefinition={getFilterDefinition}
      /> */}

      <PageHeaderRow header="Attributes" subheader="Product">
        <Button
          type="button"
          content="Export Attributes"
          size="mini"
          onClick={handleExport}
          loading={isSubmitting.export}
          disabled={isSubmitting.export}
        />{' '}
        <Link to="/products/attributes/add">
          <Button content="New" primary size={'mini'} />
        </Link>
      </PageHeaderRow>

      <FilterForm value={filter} onChange={(v) => setFilter(v)} onReset={() => setFilter({})} />

      <Segment>
        <EmptyWrapper queryResult={resolvedQueryResult} isStateLoading={isStateLoading}>
          <PaginatorWrapper.Top
            filter={filter}
            reload={reload}
            data={queryResult as any}
            pathPrefix={`${pathPrefix}`}
          />
          <ListTable
            data={resolvedQueryResult}
            definition={tableDefinition}
            selectedItems={selectedItems}
            setChecked={setChecked}
            sort={sort}
            sortClickFunction={handleSort}
            toggleAll={toggleAll}
            withCheckboxes={true}
          />

          <PaginatorWrapper.Bottom
            reload={reload}
            data={queryResult as any}
            pathPrefix={`${pathPrefix}`}
          >
            {someSelected && (
              <Button primary type="button" content="Delete" onClick={handleDelete} />
            )}
          </PaginatorWrapper.Bottom>

          {isCheckModalOpen && (
            <AttributeCheckModal
              isOpen={isCheckModalOpen}
              onClose={() => {
                setIsCheckModalOpen(false);
                setAttribute(null);
              }}
              attribute={attribute}
            />
          )}
        </EmptyWrapper>
      </Segment>
    </>
  );
};
