import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Segment, Message } from 'semantic-ui-react';
import { PageHeaderRow, PreLoader, useFeathers } from '@inkcloud/components';
import feathers from '../../../bootstrap/feathers';
import TurnaroundHolidayForm from './TurnaroundHolidayForm';

interface DetailProps {}

const pathPrefix = `/settings/turnaround-holidays`;
const serviceName = 'tenants/holidays';
const service = feathers.service(serviceName);
const commonParams = { query: { $populate: ['manager'] } };

const { useState } = React;

export default function Detail(props: DetailProps & RouteComponentProps<{ id?: string }>) {
  const {
    match: {
      params: { id },
    },
    history,
  } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const isEdit = !!id;
  const [state, reload] = useFeathers('get', serviceName, [id, commonParams], true);
  const handleSubmit = async (values: any) => {
    setIsSubmitting(true);
    try {
      if (isEdit) {
        await service.patch(id, values, commonParams);
        history.push(pathPrefix);

        return;
      }
      await service.create(values, commonParams);
      history.push(pathPrefix);
      history.push(pathPrefix);
      return;
    } catch (e) {
      const errMsg =
        e?.code < 500 ? e?.message : 'We are experiencing technical difficulties. Please try again';
      setErrorMessage(errMsg);
    }

    setIsSubmitting(false);
  };

  const pageTitle = isEdit ? 'Edit' : 'New';

  if (isEdit && !state.data) {
    return <PreLoader />;
  }

  const initialData = { ...state.data };

  if (initialData.user) {
    initialData.linkToUser = true;
  }

  return (
    <div>
      <PageHeaderRow
        header={`${pageTitle} Turnaround Holiday`}
        subheader="Settings"
        subheaderId={''}
      >
        <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
      </PageHeaderRow>
      <Segment>
        <TurnaroundHolidayForm
          initialValues={initialData}
          isSubmitting={isSubmitting}
          onSubmit={handleSubmit}
          errorMessage={errorMessage}
        />
        {errorMessage && <Message error content={errorMessage} />}
      </Segment>
    </div>
  );
}
