import * as React from 'react';
import Feathers from '../../../bootstrap/feathers';
import { Segment, Table, Message, Button } from 'semantic-ui-react';
import { CartDetailTable } from '../../../common/CartDetailTable';

import { ItemPrice } from './ItemPrice';

interface CartItemProps {
  id: string;
  onClose: () => void;
}

const { useState, useEffect } = React;

export const CartItem: React.FunctionComponent<CartItemProps> = (props: any) => {
  const { id, onClose } = props;
  const [cartId, setCart] = useState('');
  const [items, setItems] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [productAddOns, setProductAddOns] = useState([]);

  useEffect(() => {
    const loadCartItems = async () => {
      try {
        const res: any = await Feathers.service('/cart/active-cart').create(
          {
            customer: id,
          },
          { query: {} }
        );

        if (res) {
          setCart(res._id);
          setItems(res.items);
        }
      } catch (e) {
        e.code && e.code < 500
          ? setErrorMessage(e.message)
          : setErrorMessage('We are experiencing technical difficulties. Please try again');
      }
    };

    const loadAddOns = async () => {
      try {
        const res = await Feathers.service('/products-legacy/add-ons-base').find({
          query: {
            $sort: { name: 1 },
          },
        });
        setProductAddOns(res);
      } catch (error) {}
    };

    loadAddOns();

    loadCartItems();
  }, [id]);

  const handleChange = (id: string, fieldName: string, value: string) => {
    const newItems = items.map((item) =>
      item._id === id ? { ...item, [fieldName]: value } : item
    );

    setItems(newItems);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);

    const strippedItems = items.map((item) => ({
      id: item._id,
      designPrice: Number(item.designPrice),
      price: Number(item.price),
    }));

    try {
      await Feathers.service('/cart/update-item-prices').create({
        cartId,
        items: strippedItems,
      });
      setIsSubmitting(false);
      onClose();
    } catch (e) {
      setIsSubmitting(false);
      e.code && e.code < 500
        ? setErrorMessage(e.message)
        : setErrorMessage('We are experiencing technical difficulties. Please try again');
    }
  };

  return (
    <>
      <Table size="small" loading={isSubmitting}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Category</Table.HeaderCell>
            <Table.HeaderCell width={5}>Attributes</Table.HeaderCell>
            <Table.HeaderCell>Design Price</Table.HeaderCell>
            <Table.HeaderCell>Price</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {items &&
            items.length > 0 &&
            items.map((i: any, index: number) => (
              <Table.Row key={index} verticalAlign="top">
                <Table.Cell>{i.category && i.category.name}</Table.Cell>
                <Table.Cell>
                  <CartDetailTable
                    // attributes={i.attributes}
                    // isCustomTrim={i.sCustomTrim}
                    // customTrimWidth={i.customTrimWidth}
                    // customTrimHeight={i.customTrimHeight}
                    // quantity={i.quantity}
                    item={i}
                    addOns={i.addOns}
                    addOnsBase={productAddOns}
                  />
                </Table.Cell>
                <Table.Cell>
                  <ItemPrice
                    itemId={i._id}
                    fieldName="designPrice"
                    price={i.designPrice}
                    onChange={handleChange}
                  />
                </Table.Cell>
                <Table.Cell>
                  <ItemPrice
                    itemId={i._id}
                    fieldName="price"
                    price={i.price}
                    onChange={handleChange}
                  />
                </Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
      {errorMessage && <Message error content={errorMessage} />}
      <Button
        primary
        content="Update Price(s)"
        onClick={handleSubmit}
        disabled={isSubmitting}
        loading={isSubmitting}
      />
    </>
  );
};
