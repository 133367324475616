// import * as React from 'react';
// import { Button, Checkbox, Header, Message, Segment, Table, Label } from 'semantic-ui-react';
// import { Link } from 'react-router-dom';
// import { FormattedDate, FormattedNumber, FormattedRelative } from 'react-intl';
// import { IndexWrapper, IndexWrappedProps } from '../../../bootstrap/IndexWrapper';
// import { ListViewPagination, ListFilterWarning } from '../../../common';
// import { PageHeaderRow, PreLoader } from '@inkcloud/components';
// import { FilterForm } from './FilterForm';

// const initialState = { product: null, isInviteModalOpen: false, success: false };
// type State = typeof initialState;

// class VariantValue extends React.Component<IndexWrappedProps, State> {
//   state = initialState;

//   closeModal = () => this.setState({ ...this.state, isInviteModalOpen: false });

//   public render() {
//     const { props } = this;
//     if (!props.queryResult) {
//       return <PreLoader />;
//     }
//     const { data, skip, limit, total } = props.queryResult;

//     return (
//       <div>
//         <PageHeaderRow header='Variant Values' subheader='Products'>
//           <Link to={`/products/products`}><Button content="Back" size={'mini'} /></Link>
//           <Link to={`/products/variant-values/add`}><Button content="New" primary size={'mini'} /></Link>
//         </PageHeaderRow>
//         <FilterForm
//           value={props.filter}
//           onChange={(values) => {
//             props.handleFilterSubmit(values);
//           }}
//           onReset={props.handleClearFilter}
//         />
//         <ListFilterWarning isFiltered={props.isFiltered} />

//         <Segment loading={props.isLoading}>
//           <ListViewPagination.Top
//             label={'New Page'}
//             link={`/products/variant-types/add`}
//             {...props}
//             hideAddButton={true}
//           />
//           <Table size={'small'}>
//             <Table.Header>
//               <Table.Row>
//                 <Table.HeaderCell></Table.HeaderCell>
//                 <Table.HeaderCell>Created</Table.HeaderCell>
//                 <Table.HeaderCell>Type</Table.HeaderCell>
//                 <Table.HeaderCell>Name</Table.HeaderCell>
//                 <Table.HeaderCell>Key</Table.HeaderCell>
//                 <Table.HeaderCell>Priority</Table.HeaderCell>

//                 <Table.HeaderCell></Table.HeaderCell>
//               </Table.Row>
//             </Table.Header>
//             <Table.Body>
//               {data.map(i => (
//                 <Table.Row key={i._id}>
//                   <Table.Cell>
//                     <Checkbox
//                       value={i._id}
//                       checked={props.selected[i._id] || false}
//                       onChange={props.handleSemanticCheckboxClick}
//                     />
//                   </Table.Cell>
//                   <Table.Cell>
//                     <FormattedDate value={i.createdAt} />
//                   </Table.Cell>
//                   <Table.Cell>
//                     {i.type && i.type.name}
//                   </Table.Cell>
//                   <Table.Cell>
//                     {i.name}
//                   </Table.Cell>
//                   <Table.Cell>
//                     {i.key}
//                   </Table.Cell>
//                   <Table.Cell>
//                     {i.priority || ''}
//                   </Table.Cell>

//                   <Table.Cell textAlign="right">
//                     <Link to={`/products/variant-values/${i._id}`}>
//                       <Button size={'mini'} primary icon={'edit'} />
//                     </Link>
//                   </Table.Cell>
//                 </Table.Row>
//               ))}
//             </Table.Body>
//           </Table>
//           <ListViewPagination.Bottom
//             label={'Delete'}
//             {...props}
//           />
//         </Segment>
//         {/* <InviteModal open={this.state.isInviteModalOpen} handleSubmit={this.handleInviteSubmit} handleClose={this.closeModal}  /> */}

//       </div>
//     );
//   }
// }

// export default IndexWrapper(VariantValue, 'productsVariantValues', {
//   query: {
//     $populate: ['type'],
//     $sort: { type: 1 },
//   },
//   filterMapper: (values) => {
//     return {
//       name: values.name && { $LIKE: values.name },
//       type: values?.type,
//     };
//   },
// });

import React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { FormattedDate, FormattedTime } from 'react-intl';

import { Button, Box, IconButton, Tooltip, ButtonGroup } from '@chakra-ui/react';

import { FaEdit } from 'react-icons/fa';
import {
  useList,
  ListPage,
  Operator,
  basicQueryMap,
  generateOperatorOptions,
} from '@inkcloud/components';

import Feathers from '../../../bootstrap/feathers';

const filterQueryMap = (values) => {
  let filterRows = basicQueryMap(values, {});

  filterRows = {
    ...filterRows,
    type: filterRows?.type?.$elemMatch?.value,
  };

  return filterRows;
};

export async function getFilterDefinition() {
  const definition = [
    {
      label: 'Name',
      value: 'name',
      operators: generateOperatorOptions([Operator.Like]),
    },
    {
      label: 'Type',
      value: 'type',
      operators: generateOperatorOptions([Operator.Is, Operator.IsNot]),
      valueOptions: [],
      asyncValueOptions: async () =>
        Feathers.service('/products-legacy/variant-types')
          .find({
            query: {
              $np: '1',
              $sort: { name: 1 },
            },
          })
          .then((results) => results.map((r) => ({ value: r._id, label: r.name }))),
      type: 'select',
    },
  ];

  return definition.sort((a: any, b: any) => a.label.localeCompare(b.label));
}

interface IListProps {}

const serviceName = 'products-legacy/variant-type-values';
const pathPrefix = '/products/variant-values';

export const List: React.FunctionComponent<IListProps & RouteComponentProps> = (props) => {
  const {
    match: { params },
    location,
    history,
  } = props;

  const useListValues = useList({
    feathers: ['find', serviceName, [{ query: { $populate: ['type'] } }]],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  const tableDefinition = [
    {
      key: 'createdAt',
      label: 'Date',
      sortable: true,
      cell: (v) => (
        <Tooltip hasArrow aria-label="time" label={<FormattedTime value={v} />}>
          <Box>
            <FormattedDate value={v} />
          </Box>
        </Tooltip>
      ),
    },
    {
      key: '',
      label: 'Type',
      cell: (v, r) => <>{r?.type?.name}</>,
    },
    {
      key: 'name',
      label: 'Name',
      cell: (v, r) => v,
    },
    {
      key: 'key',
      label: 'Key',
      cell: (v, r) => v,
    },
    {
      key: 'priority',
      label: 'Priority',
      cell: (v, r) => v,
    },
    {
      key: '',
      label: '',
      cell: (v, r) => (
        <Tooltip label="Editable">
          <Link to={`${pathPrefix}/${r._id}`}>
            <IconButton colorScheme="gray" aria-label="Edit" icon={<FaEdit />} />
          </Link>
        </Tooltip>
      ),
      textAlign: 'right',
    },
  ];

  const headerActions = (
    <ButtonGroup spacing={1}>
      <Link to={`/products/products`}></Link>
      <Button size="sm" onClick={() => history.goBack()}>
        Back
      </Button>
      <Link to={`${pathPrefix}/add`}>
        <Button size="sm" colorScheme="blue">
          New
        </Button>
      </Link>
    </ButtonGroup>
  );

  return (
    <ListPage
      header="Variant Values"
      subheader="Products"
      useListValues={useListValues}
      history={history}
      pathPrefix={pathPrefix}
      tableDefintion={tableDefinition}
      withCheckboxes={false}
      headerActions={headerActions}
      getFilterDefinition={getFilterDefinition}
    />
  );
};
