import React, { useState } from 'react';
import { Button, IconButton, Tag, Tooltip } from '@chakra-ui/react';
import { HiMagnifyingGlassPlus } from 'react-icons/hi2';
// import { Button, Checkbox, Header, Segment, Table, Label, Popup, Divider } from 'semantic-ui-react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { FormattedDate, FormattedTime } from 'react-intl';
import {
  useList,
  PageHeaderRow,
  ListPage,
  PaginatorWrapper,
  basicQueryMap,
  generateOperatorOptions,
  Operator,
} from '@inkcloud/components';
import { FilterForm } from './FilterForm';

import { ExtractedModal } from './ExtractedModal';

interface ListProps {}

const serviceName = 'email-parse/log';

// const filterQueryMap = (values) => {
//   const filterMap: any = {
//     from: values.from ? { $LIKE: values.from } : undefined,
//     result: values.result ? values.result : undefined,
//     subject: values.subject ? { $LIKE: values.subject } : undefined,
//   };

//   return [].reduce((m, field) => ({ ...m, [field]: values[field] ? values[field] : undefined }), filterMap);
// };

const filterQueryMap = (values) => basicQueryMap(values, {});

const initialSelected = {
  html: '',
  extractedContext: {},
};

export async function getFilterDefinition() {
  const definition = [
    {
      label: 'From',
      value: 'from',
      operators: generateOperatorOptions([Operator.Like]),
    },
    {
      label: 'Result',
      value: 'result',
      operators: generateOperatorOptions([Operator.Is]),
      valueOptions: [
        { value: 'failed', label: 'Failed' },
        { value: 'need-review', label: 'Need Review' },
        { value: 'pending', label: 'Pending' },
        { value: 'skipped', label: 'Skipped' },
        { value: 'success', label: 'Success' },
      ],
      type: 'multi-select',
    },
    {
      label: 'Subject',
      value: 'subject',
      operators: generateOperatorOptions([Operator.Like]),
    },
  ];

  return definition.sort((a: any, b: any) => a.label.localeCompare(b.label));
}

const pathPrefix = `/admin/email-parse/log`;

export const List: React.FunctionComponent<ListProps & RouteComponentProps> = (props) => {
  const {
    match: { params },
    location,
    history,
  } = props;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selected, setSelected] = useState(initialSelected);

  const useListValues = useList({
    feathers: ['find', serviceName, [{ query: {} }]],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  // const { state, reload, handleDelete, selectedItems, clearAll, setChecked, someSelected, toggleAll, filter, setFilter, sort, handleSort } = useList({
  //   feathers: ['find', serviceName, [{ query: {} }]],
  //   filterMap: filterQueryMap,
  //   debounce: 150,
  //   location,
  //   history,
  //   sort: { createdAt: -1 }
  // })

  // const { queryResult, isLoading: isStateLoading } = state as any;

  // if (!queryResult) {
  //   return null;
  // }

  const tableDefinition = [
    {
      key: 'createdAt',
      label: 'Date',
      sortable: true,
      cell: (v) =>
        v && (
          <Tooltip label={<FormattedTime value={v} />}>
            <FormattedDate value={v} />
          </Tooltip>
        ),
    },
    {
      key: 'inboundEmail',
      label: 'Inbound Email',
      sortable: true,
      cell: (v) => v?.slice(0, '@parse.inkcloud9.com'.length * -1),
    },
    {
      key: 'from',
      label: 'From',
      sortable: true,
      cell: (v) => v,
    },
    {
      key: 'to',
      label: 'To',
      sortable: true,
      cell: (v) => v?.map((to, idx) => <div key={idx}>{to}</div>),
    },
    {
      key: 'subject',
      label: 'Subject',
      sortable: true,
      cell: (v, r) => v,
    },
    {
      key: '',
      label: 'Order',
      sortable: true,
      cell: (v, r) => {
        const icOrderHumanId = r?.metadata?.find((m) => m.key === 'OrderNumber');
        const icOrderId = r?.metadata?.find((m) => m.key === 'OrderId');

        return <Link to={`/orders/${icOrderId?.value}`}>{icOrderHumanId?.value}</Link>;
      },
    },
    {
      key: 'result',
      label: 'Result',
      sortable: true,
      cell: (v, r) => {
        let color;
        let label;
        switch (r?.result) {
          case 'failed':
            color = 'red';
            label = r?.errorMessage;
            break;
          case 'success':
            color = 'green';
            break;
          default:
            color = 'yellow';
            label = r?.errorMessage;
            break;
        }
        return (
          <>
            <Tooltip label={label}>
              <Tag colorScheme={color}>{r?.result}</Tag>
            </Tooltip>
            <div style={{ fontFamily: '"Courier New", Courier, monospace', fontSize: 12 }}>
              {r.result === 'failed' &&
                r.metadata.length > 0 &&
                r.metadata.filter((m) => m.key === 'error').map((m) => <div>{m.value}</div>)}
            </div>
          </>
        );
      },
    },
    {
      key: '',
      label: '',
      sortable: false,
      cell: (v, r) => (
        <Tooltip label="View">
          <IconButton
            aria-label="View"
            icon={<HiMagnifyingGlassPlus />}
            onClick={() => {
              setSelected({
                html: r?.html,
                extractedContext: r?.extractedContext,
              });
              setIsModalOpen(true);
            }}
          />
        </Tooltip>
      ),
      textAlign: 'right',
    },
  ];

  return (
    <div>
      <ListPage
        header={'Log'}
        subheader="Email Parse"
        useListValues={useListValues}
        history={history}
        pathPrefix={pathPrefix}
        tableDefintion={tableDefinition}
        withCheckboxes={false}
        getFilterDefinition={getFilterDefinition}
      />

      {/*
      <PageHeaderRow header='Log' subheader='Email Parse'>
      </PageHeaderRow>
      <FilterForm value={filter} onChange={(v) => setFilter(v)} onReset={() => setFilter({})} />

      <Segment loading={state.isLoading}>
        <PaginatorWrapper.Top filter={filter} reload={reload} data={(queryResult as any)} pathPrefix={`${pathPrefix}`} />
        <Table size={'small'} sortable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell sorted={sort.createdAt ? sort.createdAt : null} onClick={() => handleSort('createdAt')}>
                Created
              </Table.HeaderCell>
              <Table.HeaderCell sorted={sort.inboundEmail ? sort.inboundEmail : null} onClick={() => handleSort('inboundEmail')}>
                Inbound Email
              </Table.HeaderCell>
              <Table.HeaderCell sorted={sort.from ? sort.from : null} onClick={() => handleSort('from')}>
                From
              </Table.HeaderCell>
              <Table.HeaderCell >
                To
              </Table.HeaderCell>
              <Table.HeaderCell sorted={sort.subject ? sort.subject : null} onClick={() => handleSort('subject')}>
                Subject
              </Table.HeaderCell>
              <Table.HeaderCell >
                Result
              </Table.HeaderCell>
              <Table.HeaderCell sorted={sort.status ? sort.status : null} onClick={() => handleSort('status')}>
                Status
              </Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {queryResult && (queryResult as any).data.map(r => {
              const icOrderHumanId = r.metadata?.find(m => m.key === 'icOrderHumanId');
              const icOrderId = r.metadata?.find(m => m.key === 'icOrderId');

              return (
                <Table.Row key={r._id} verticalAlign="top">
                  <Table.Cell>
                    <Popup
                      size='mini'
                      content={<FormattedTime value={r.createdAt} />}
                      trigger={
                        <div><FormattedDate value={r.createdAt} /></div>
                      }
                    />

                  </Table.Cell>
                  <Table.Cell>
                    {r.inboundEmail.slice(0, '@parse.inkcloud9.com'.length * -1)}
                  </Table.Cell>
                  <Table.Cell>
                    {r.from}
                  </Table.Cell>
                  <Table.Cell>
                    {r.to.map((to, idx) => <div key={idx}>{to}</div>)}
                  </Table.Cell>
                  <Table.Cell>
                    {r.subject}
                  </Table.Cell>
                  <Table.Cell>
                    {icOrderHumanId &&
                      <Link to={`/orders/${icOrderId.value}`}>
                        {icOrderHumanId.value}
                      </Link>
                    }
                  </Table.Cell>
                  <Table.Cell>
                    <Label size="mini" color={r.result === 'failed' ? 'red' : r.result === 'success' ? 'green' : 'yellow'}>{r.result}</Label>
                    <div style={{ fontFamily: '"Courier New", Courier, monospace', fontSize: 12 }}>
                      {r.result === 'failed' && r.metadata.length > 0 &&
                        r.metadata.filter(m => m.key === 'error').map(m => <div>{m.value}</div>)
                      }
                    </div>
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    <Button
                      size="mini"
                      icon="magnify"
                      onClick={() => {
                        setSelected({
                          html: r?.html,
                          extractedContext: r?.extractedContext
                        })
                        setIsModalOpen(true)
                      }}
                    />
                  </Table.Cell>
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>
        <PaginatorWrapper.Bottom reload={reload} data={(queryResult as any)} pathPrefix={`${pathPrefix}`} />
      </Segment> */}
      {isModalOpen && (
        <ExtractedModal
          html={selected.html}
          extractedContext={selected.extractedContext}
          onClose={() => {
            setIsModalOpen(false);
            setSelected(initialSelected);
          }}
        />
      )}
    </div>
  );
};
