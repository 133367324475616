import * as React from 'react';
import { Modal, Button, Message } from 'semantic-ui-react';

interface RefundModalProps {
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
  onSubmit: () => void;
  errorMessage: string;
}

export const RefundModal: React.FunctionComponent<RefundModalProps> = (props) => {
  const { isOpen, isLoading, onClose, onSubmit, errorMessage } = props;
  return (
    <Modal size="mini" open={isOpen} onClose={onClose} closeIcon>
      <Modal.Header>Refund</Modal.Header>
      <Modal.Content>
        <p>Are you sure you want to refund this?</p>
        {errorMessage && <Message error content={errorMessage} />}
      </Modal.Content>
      <Modal.Actions>
        <Button negative onClick={onClose}>
          No
        </Button>
        <Button
          positive
          icon="checkmark"
          labelPosition="right"
          content="Yes"
          loading={isLoading}
          onClick={onSubmit}
        />
      </Modal.Actions>
    </Modal>
  );
};
