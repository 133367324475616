import * as React from 'react';
import { Style } from '@react-pdf/types';
import { BillTo as BillToGeneral } from '..';
import { SalesOrderContext } from './SalesOrderContext';

export interface IBillToProps {
  style?: Style;
  textStyle?: Style;
}

export function BillTo(props: IBillToProps) {
  const ctx = React.useContext(SalesOrderContext);
  const { style, textStyle } = props;

  const value = {
    company: ctx.dataCtx.salesOrder.billTo?.company || '',
    name: ctx.dataCtx.salesOrder.billTo?.name || '',
    address1: ctx.dataCtx.salesOrder.billTo?.address1 || '',
    address2: ctx.dataCtx.salesOrder.billTo?.address2 || '',
    city: ctx.dataCtx.salesOrder.billTo?.city || '',
    stateProvince: ctx.dataCtx.salesOrder.billTo?.stateProvince || '',
    postalCode: ctx.dataCtx.salesOrder.billTo?.postalCode || '',
    countryCode: ctx.dataCtx.salesOrder.billTo?.countryCode || '',
  };

  return <BillToGeneral value={value} style={style} textStyle={textStyle} />;
}
