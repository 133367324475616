import * as React from 'react';
import { Modal, ModalProps, Message } from 'semantic-ui-react';
import { WarehouseForm } from './WarehouseForm';
import { BinForm } from './BinForm';
import { VirtualForm } from './VirtualForm';

export interface AddModalProps {
  handleSubmit: Function;
  handleClose: ModalProps['onClose'];
  open: boolean;
  type: string;
  initialValues: any;
  action: string;
  errorMessage?: string;
}

const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export class AddModal extends React.Component<AddModalProps> {
  public render() {
    const { props } = this;
    const { initialValues } = props;

    const data = { ...initialValues, type: 'internal', virtual: this.props.type === 'virtual' };
    if (initialValues && initialValues.parentLocation && initialValues.parentLocation._id) {
      data.parentLocation = initialValues.parentLocation._id;
    }

    return (
      <Modal size={'mini'} open={props.open} onClose={props.handleClose}>
        <Modal.Header>
          {capitalize(this.props.action)} {capitalize(this.props.type)}
        </Modal.Header>
        <Modal.Content>
          {this.props.type === 'warehouse' && (
            <WarehouseForm
              initialValues={data}
              handleSubmit={props.handleSubmit}
              handleCloseClick={this.props.handleClose}
            />
          )}
          {this.props.type === 'bin' && (
            <BinForm
              initialValues={data}
              handleSubmit={props.handleSubmit}
              handleCloseClick={this.props.handleClose}
            />
          )}
          {this.props.type === 'virtual' && (
            <VirtualForm
              initialValues={data}
              handleSubmit={props.handleSubmit}
              handleCloseClick={this.props.handleClose}
            />
          )}
          {this.props.errorMessage && <Message error content={this.props.errorMessage} />}
        </Modal.Content>
        <Modal.Actions></Modal.Actions>
      </Modal>
    );
  }
}
