import * as React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, change, formValueSelector, FormProps } from 'redux-form';
import { Link } from 'react-router-dom';
import { validateRequired, usStateProvinces } from '../../../common';
import { Form, Checkbox, Radio, Input, Dropdown, Button } from 'semantic-ui-react';
import {
  semanticFormField,
  semanticFormToggleField,
  semanticFormRadioField,
} from '../semantic-form-field';

const validate = (values) => {
  if (Object.keys(values).length === 0) {
    return;
  }

  return validateRequired(['secretKey', 'publishableKey'])(values);
};

const normalizeBoolean = (value) => (value === 'true' ? true : false);
const formatBoolean = (value) => (value === true ? 'true' : 'false');

export default (props: FormProps) => {
  const { onSubmit, handleSubmit, pristine, reset, submitting } = props;

  let customAccountFields = null;
  if (props.ups && props.ups.useCustomAccount) {
    customAccountFields = (
      <Form.Group widths="equal">
        <Field
          name="credentials.username"
          component={semanticFormField}
          as={Form.Input}
          label="Username"
          type="input"
        />
        <Field
          name="credentials.password"
          component={semanticFormField}
          as={Form.Input}
          label="Password"
          type="input"
        />
        <Field
          name="credentials.accessKey"
          component={semanticFormField}
          as={Form.Input}
          label="Access Key"
          type="input"
        />
      </Form.Group>
    );
  }

  return (
    <div>
      <Field
        name="isEnabled"
        component={semanticFormToggleField}
        as={Form.Checkbox}
        label="Enable UPS"
        type="checkbox"
      />
      {props.ups && props.ups.isEnabled ? (
        <div>
          <Form.Group widths="equal">
            <Field
              name="useCustomAccount"
              component={semanticFormRadioField}
              as={Form.Radio}
              value="false"
              label={'Use built in UPS account'}
              type="radio"
              normalize={normalizeBoolean}
              format={formatBoolean}
            />

            <Field
              name="useCustomAccount"
              component={semanticFormRadioField}
              as={Form.Radio}
              value="true"
              label={'Use your own UPS account'}
              type="radio"
              normalize={normalizeBoolean}
              format={formatBoolean}
            />
          </Form.Group>
          {customAccountFields}
        </div>
      ) : null}
    </div>
  );
};
