import * as React from 'react';
import { Dropdown, Menu } from 'semantic-ui-react';
import { RouteComponentProps } from 'react-router-dom';

interface SecondaryMenuProps {
  history: RouteComponentProps['history'];
  match: RouteComponentProps<{ id: string }>['match'];
}

export const SecondaryMenu: React.FunctionComponent<SecondaryMenuProps> = (props) => {
  const { history, match } = props;
  const id = match.params.id;
  const baseUrl = `/settings/microsites/${id}`;

  const contentItems = [
    {
      name: 'Images',
      key: `${baseUrl}/content/images`,
      url: `${baseUrl}/content/images`,
      active: match.url === `${baseUrl}/content/images`,
    },
    {
      name: 'Live Edit',
      key: `${baseUrl}/content/edit`,
      url: `${baseUrl}/content/edit`,
      active: match.url === `${baseUrl}/content/edit`,
    },
    {
      name: 'Banners',
      key: `${baseUrl}/content/banners`,
      url: `${baseUrl}/content/banners`,
      active: match.url === `${baseUrl}/content/banners`,
    },
    {
      name: 'Menus',
      key: `${baseUrl}/content/menus`,
      url: `${baseUrl}/content/menus`,
      active: match.url === `${baseUrl}/content/menus`,
    },
    {
      name: 'Pages',
      key: `${baseUrl}/content/pages`,
      url: `${baseUrl}/content/pages`,
      active: match.url === `${baseUrl}/content/pages`,
    },
  ];

  const items = [
    {
      name: 'Customers',
      key: `${baseUrl}/customers`,
      url: `${baseUrl}/customers`,
      active: match.url === `${baseUrl}/customers`,
    },
    {
      name: 'Groups',
      key: `${baseUrl}/groups`,
      url: `${baseUrl}/groups`,
      active: match.url === `${baseUrl}/groups`,
    },
    {
      name: 'Allotments',
      key: `${baseUrl}/allotments`,
      url: `${baseUrl}/allotments`,
      active: match.url === `${baseUrl}/allotments`,
    },
    {
      name: 'Products',
      key: `${baseUrl}/products`,
      url: `${baseUrl}/products`,
      active: match.url === `${baseUrl}/products`,
    },
    {
      name: 'Settings',
      key: `${baseUrl}/edit`,
      url: `${baseUrl}/edit`,
      active: match.url === `${baseUrl}/edit`,
    },
    {
      name: 'Notifications',
      key: `${baseUrl}/notifications`,
      url: `${baseUrl}/notifications`,
      active: match.url === `${baseUrl}/notifications`,
    },
  ];

  const handleClick = (e, data) => {
    history.push(data.url);
  };

  return (
    <Menu size="small" onItemClick={handleClick} style={{ marginBottom: 20 }}>
      {items.map((i) => (
        <Menu.Item
          name={i.name}
          key={i.key}
          url={i.url}
          onClick={(e, data) => history.push(i.url)}
        />
      ))}
      <Dropdown item text="Content">
        <Dropdown.Menu>
          {contentItems.map((i) => (
            <Dropdown.Item
              text={i.name}
              key={i.key}
              url={i.url}
              onClick={(e, data) => history.push(i.url)}
            />
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </Menu>
  );
};
