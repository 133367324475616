import * as React from 'react';
import { Switch, Route } from 'react-router-dom';

// import PortalComponent from './IndexComponent';
import { PortalDetailComponent } from './MicrositeDetailComponent';
// import PortalAddComponent from './MicrositeAddComponent';
// import PortalEditComponent from './MicrositeEditComponent';
import PortalProductAddComponent from './MicrositeProductAddComponent';
import PortalProductEditComponent from './MicrositeProductEditComponent';
import ContentEdit from './content-edit';
import ContentPages from './content-pages';
import ContentMenus from './content-menus';
import ContentImages from './content-images';
import ContentBanners from './content-banners';
import ContentEmailTemplates from './content-email-templates';
import Customers from './customers';
import ContactFormSubmissions from './contact-form-submissions';
import AddressBook from './address-book';
import Groups from './groups';
import Allotments from './allotments';
import AllotmentPolicies from './allotments/policies';

import NotificationRules from './notification-rules';
import Products from './products';
import List from './List';

import { Detail } from './Detail';

import NewProductNotification from './product-notification';
import ApprovalEmail from './approval-email';
import Menus from './menus';
import PickupLocations from './pickup-locations';

import { Heading } from './Heading';
import { SecondaryMenu } from './SecondaryMenu';

const MicrositeHeader = (props) => {
  const { match, history } = props;
  if (match?.url === '/settings/microsites/add') {
    return null;
  }

  const micrositeId = match?.params?.id;

  return (
    <div>
      <Heading id={micrositeId} />
      <SecondaryMenu match={match} history={history} />
    </div>
  );
};

const routes = (props) => (
  <>
    <Switch>
      <Route path="/settings/microsites/:id" component={MicrositeHeader} />
    </Switch>
    <Switch>
      <Route exact path="/settings/microsites" component={List} />
      <Route exact path="/settings/microsites/add" component={Detail} />
      <Route exact path="/settings/microsites/:id" component={PortalDetailComponent} />
      <Route
        exact
        path="/settings/microsites/:id/product-add"
        component={PortalProductAddComponent}
      />
      <Route exact path="/settings/microsites/:id/edit" component={Detail} />
      <Route
        exact
        path="/settings/microsites/:id/edit/:itemId"
        component={PortalProductEditComponent}
      />
      <Route path="/settings/microsites/:id/content/edit" component={ContentEdit} />
      <Route path="/settings/microsites/:id/content/pages" component={ContentPages} />
      <Route path="/settings/microsites/:id/content/menus" component={ContentMenus} />
      <Route path="/settings/microsites/:id/notifications" component={ContentEmailTemplates} />
      <Route path="/settings/microsites/:id/content/images" component={ContentImages} />
      <Route path="/settings/microsites/:id/content/banners" component={ContentBanners} />
      <Route
        path="/settings/microsites/:id/product-notification"
        component={NewProductNotification}
      />
      <Route path="/settings/microsites/:id/approval-email" component={ApprovalEmail} />
      <Route path="/settings/microsites/:id/pickup-locations" component={PickupLocations} />
      <Route path="/settings/microsites/:id/menus" component={Menus} />
      <Route path="/settings/microsites/:id/customers" component={Customers} />
      <Route
        path="/settings/microsites/:id/contact-form-submissions"
        component={ContactFormSubmissions}
      />
      <Route path="/settings/microsites/:id/address-book" component={AddressBook} />
      <Route path="/settings/microsites/:id/groups" component={Groups} />
      <Route path="/settings/microsites/:id/allotments" component={Allotments} />
      <Route path="/settings/microsites/:id/allotment-policies" component={AllotmentPolicies} />

      <Route path="/settings/microsites/:id/notification-rules" component={NotificationRules} />
      <Route path="/settings/microsites/:id/products" component={Products} />
    </Switch>
  </>
);

export default routes;
