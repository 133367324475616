import * as React from 'react';
import { Button, Checkbox, Header, Segment, Table, Label, Popup } from 'semantic-ui-react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { FormattedDate } from 'react-intl';
import {
  useList,
  useDebounce,
  PageHeaderRow,
  PreLoader,
  PaginatorWrapper,
  EmptyWrapper,
} from '@inkcloud/components';
import { FilterForm } from './FilterFormOpenInvoices';
import Feathers from './../../bootstrap/feathers';

import * as numeral from 'numeral';

const serviceName = 'invoices';
const pathPrefix = `/accounting/open-invoices`;

interface OpenInvoicesProps {}

const filterQueryMap = (values) => {
  const filterMap: any = {
    customer: values.organizationName ? values.organizationName : undefined,
  };

  return [].reduce(
    (m, field) => ({
      ...m,
      [field]: values[field] ? values[field] : undefined,
    }),
    filterMap
  );
};

export const OpenInvoices: React.FunctionComponent<OpenInvoicesProps & RouteComponentProps> = (
  props
) => {
  const {
    match: { params },
    location,
    history,
  } = props;
  const {
    state,
    reload,
    handleDelete,
    selectedItems,
    clearAll,
    setChecked,
    someSelected,
    toggleAll,
    filter,
    setFilter,
    sort,
    handleSort,
  } = useList({
    feathers: [
      'find',
      serviceName,
      [
        {
          query: {
            $populate: ['customer'],
            $populateOpenBalance: true,
            $sort: { createdAt: -1 },
            isVoid: { $ne: true },
            isClosed: { $ne: true },
          },
        },
      ],
    ],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  const { queryResult, isLoading: isStateLoading } = state as any;

  if (!queryResult) {
    return <PreLoader />;
  }

  return (
    <div>
      <PageHeaderRow header="Open Invoices" subheader="Accounting" />

      <FilterForm value={filter} onChange={(v) => setFilter(v)} onReset={() => setFilter({})} />
      <EmptyWrapper queryResult={queryResult} isStateLoading={isStateLoading}>
        <Segment loading={state.isLoading}>
          <PaginatorWrapper.Top
            filter={filter}
            reload={reload}
            data={queryResult as any}
            pathPrefix={`${pathPrefix}/`}
          />
          <Table size={'small'} sortable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  sorted={sort.createdAt ? sort.createdAt : null}
                  onClick={() => handleSort('createdAt')}
                >
                  Created
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={sort.humanId ? sort.humanId : null}
                  onClick={() => handleSort('humanId')}
                >
                  ID
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={sort.lineAmount ? sort.lineAmount : null}
                  onClick={() => handleSort('lineAmount')}
                >
                  Name
                </Table.HeaderCell>
                <Table.HeaderCell>Total</Table.HeaderCell>
                <Table.HeaderCell>Open</Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {queryResult &&
                (queryResult as any).data.map(
                  (
                    r: {
                      _id: string | number | string[];
                      createdAt: any;
                      humanId: string;
                      customer: any;
                      total: number;
                      openBalance: number;
                      order: string;
                    },
                    index: number
                  ) => (
                    <Table.Row key={index}>
                      <Table.Cell>
                        <FormattedDate value={r.createdAt} />
                      </Table.Cell>
                      <Table.Cell>
                        <Link to={`/orders/${r?.order}`}>{r?.humanId}</Link>
                      </Table.Cell>
                      <Table.Cell>
                        <Link to={`/customers/${r.customer?._id}`}>
                          {r.customer?.primaryContact
                            ? `${r.customer.primaryContact?.firstName} ${r.customer.primaryContact?.lastName}`
                            : r.customer?.organizationName}
                        </Link>
                      </Table.Cell>
                      <Table.Cell>{numeral(r.total).format('$0,0.00')}</Table.Cell>
                      <Table.Cell>{numeral(r.openBalance).format('$0,0.00')}</Table.Cell>
                      <Table.Cell>
                        {r.customer && (
                          <Link to={`/invoice/payments/${r.customer._id}`}>
                            <Button size={'tiny'} content={'Pay'} />
                          </Link>
                        )}
                      </Table.Cell>
                    </Table.Row>
                  )
                )}
            </Table.Body>
          </Table>
          <PaginatorWrapper.Bottom
            reload={reload}
            data={queryResult as any}
            pathPrefix={`${pathPrefix}`}
          >
            {someSelected && (
              <Button primary type="button" content="Delete" onClick={handleDelete} />
            )}
          </PaginatorWrapper.Bottom>
        </Segment>
      </EmptyWrapper>
    </div>
  );
};
