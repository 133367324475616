// import * as React from 'react';
// import { RouteComponentProps } from 'react-router-dom';
// import { FormattedDate } from 'react-intl';
// import { Segment, Table } from 'semantic-ui-react';
// import { useList, PageHeaderRow, EmptyWrapper } from '@inkcloud/components';
// import moment from 'moment';

// import Feathers from '../../../bootstrap/feathers';

// import { FilterForm } from '../FilterForm';

// interface ListProps {}

// const filterQueryMap = (values) => {
//   const filterMap: any = {
//     startDate: values.start ? moment(values.start).format('YYYY-MM-DD') : undefined,
//     endDate: values.end ? moment(values.end).format('YYYY-MM-DD') : undefined,
//   };

//   return [].reduce(
//     (m, field) => ({ ...m, [field]: values[field] ? values[field] : undefined }),
//     filterMap
//   );
// };

// const serviceName = 'reports';

// const { useState, useEffect } = React;

// export const List: React.FunctionComponent<ListProps & RouteComponentProps<any>> = (props) => {
//   // const { match: { params }, location, history, } = props;
//   // const { state, reload, handleDelete, selectedItems, clearAll, setChecked, someSelected, toggleAll, filter, setFilter, sort, handleSort } = useList({
//   //   feathers: ['find', serviceName, [{ query: { action: 'customerGrowth' } }]],
//   //   filterMap: filterQueryMap,
//   //   debounce: 150,
//   //   location,
//   //   history,
//   //   sort: {}
//   // })

//   // const { queryResult, isLoading: isStateLoading } = state as any;

//   const [filter, setFilter] = useState({}) as any;
//   const [data, setData] = useState([]);
//   const [isLoading, setIsLoading] = useState(false);
//   const [isExportLoading, setExportIsLoading] = useState(false);
//   const [errorMessage, setErrorMessage] = useState('');

//   const loadReport = async (values: any, isExport: boolean) => {
//     const queryParams = {
//       startDate: values.start ? moment(values.start).format('YYYY-MM-DD') : undefined,
//       endDate: values.end ? moment(values.end).format('YYYY-MM-DD') : undefined,
//     };

//     return await Feathers.service('/reports').find({
//       query: {
//         action: 'customerGrowth',
//         ...queryParams,
//       },
//     });
//   };

//   useEffect(() => {
//     const loadData = async () => {
//       setIsLoading(true);
//       try {
//         const res = await loadReport(filter, false);
//         setData(res.data);
//         setIsLoading(false);
//       } catch (e) {
//         setIsLoading(false);
//         setData([]);
//       }
//     };

//     loadData();
//   }, [filter]);

//   return (
//     <>
//       <PageHeaderRow header="Customer Growth" subheader="Report" />

//       <FilterForm value={filter} onChange={(v) => setFilter(v)} onReset={() => setFilter({})} />
//       <EmptyWrapper queryResult={data} isStateLoading={isLoading}>
//         <Segment loading={isLoading}>
//           <Table size={'small'} sortable>
//             <Table.Header>
//               <Table.Row>
//                 <Table.HeaderCell>Month</Table.HeaderCell>
//                 <Table.HeaderCell>Count</Table.HeaderCell>
//                 <Table.HeaderCell></Table.HeaderCell>
//               </Table.Row>
//             </Table.Header>
//             <Table.Body>
//               {data &&
//                 data.map((r: any, index: number) => (
//                   <Table.Row key={index}>
//                     <Table.Cell>{r.label}</Table.Cell>
//                     <Table.Cell>{r.runningTotal}</Table.Cell>
//                   </Table.Row>
//                 ))}
//             </Table.Body>
//           </Table>
//         </Segment>
//       </EmptyWrapper>
//     </>
//   );
// };

import React, { useEffect, useState } from 'react';
import {
  Text,
  Box,
  Table,
  Tbody,
  Tr,
  Th,
  Td,
  Flex,
  Spinner,
  Button,
  Thead,
} from '@chakra-ui/react';

import format from 'date-fns/format';
import addYears from 'date-fns/addYears';

import { Link, RouteComponentProps, useHistory } from 'react-router-dom';
import { EmptyWrapper, PageHeaderRow } from '@inkcloud/components';
import { useSelector } from 'react-redux';

import Feathers from '../../../bootstrap/feathers';
import FilterForm from '../FilterForm2';

const initValue = { start: null, end: null };

export default function SalesTeam() {
  const history = useHistory();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [filter, setFilter] = useState<{
    start: null | Date;
    end: null | Date;
  }>(initValue);

  const [data, setData] = useState<
    {
      label: string;
      runningTotal: number;
    }[]
  >([]);
  const [errorMessage, setErrorMessage] = useState('');

  const start = filter.start ? format(filter.start, 'yyyy-MM-dd') : undefined;
  const end = filter.end ? format(filter.end, 'yyyy-MM-dd') : undefined;

  const loadData = async () => {
    setErrorMessage('');
    setIsSubmitting(true);
    setData([]);

    try {
      const res = await Feathers.service('/reports').find({
        query: {
          action: 'customerGrowth',
          startDate: start,
          endDate: end,
        },
      });
      setData(res?.data);
    } catch (e) {
      const errMsg =
        e?.code < 500 ? e?.message : 'We are experiencing technical difficulties. Please try again';
      setErrorMessage(errMsg);
      setData([]);
    }

    setIsSubmitting(false);
  };

  const maxDate = addYears(filter?.start as Date, 1);
  const exceedDate = filter?.start && filter?.end ? filter.end > maxDate : false;

  useEffect(() => {
    if (filter?.start && filter?.end && !exceedDate) {
      loadData();
    }
  }, [filter]);

  const handleReset = () => {
    setData([]);
    setFilter(initValue);
  };

  return (
    <div>
      <PageHeaderRow header="Customer Growth" subheader="Reports">
        <Button onClick={() => history.goBack()}>Back</Button>
      </PageHeaderRow>

      <Box p={3} mb={3} borderWidth={1} borderRadius="md" bg="white">
        <FilterForm
          values={filter}
          isSubmitting={isSubmitting}
          errorMessage={errorMessage}
          onChange={(v) => {
            setErrorMessage('');
            setFilter(v);
          }}
          onReset={handleReset}
        />
      </Box>
      <EmptyWrapper queryResult={data} isStateLoading={isSubmitting}>
        <Box p={3} borderWidth={1} borderRadius="md" bg="white">
          <Table>
            <Thead>
              <Tr>
                <Th>Month</Th>
                <Th isNumeric>Count</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data?.map((d, index: number) => (
                <Tr key={index}>
                  <Td>{d?.label}</Td>
                  <Td isNumeric>{d?.runningTotal}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </EmptyWrapper>
    </div>
  );
}
