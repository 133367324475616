import { PaymentCreditCard, PaymentCreditCardTypes, PaymentMethod } from '../../types';

type PaymentProcessType = {
  paymentMethod: PaymentMethod;
  creditCardType: PaymentCreditCardTypes;
  description?: string;
  creditCard: PaymentCreditCard;
  cardToken: string | null;
};
export function paymentProcess({
  paymentMethod,
  creditCardType,
  creditCard,
  description,
  cardToken,
}: PaymentProcessType) {
  const paymentProcessDetails = {
    paymentMethod: paymentMethod.toUpperCase() as PaymentMethod,
    description,
    card: {} || null,
    cardToken: '' || (null as string | null),
  };

  // console.log(
  //   'paymentMethod',
  //   paymentMethod,
  //   'creditCardType',
  //   creditCardType,
  //   'creditCard',
  //   creditCard,
  //   'description',
  //   description,
  //   'cardToken',
  //   cardToken
  // );

  if (paymentMethod === PaymentMethod.CreditCard) {
    if (creditCardType === PaymentCreditCardTypes.New) {
      let names = ['', ''];
      if (creditCard.name) {
        names = creditCard.name.split(' ');
      }
      paymentProcessDetails.card = {
        firstName: names[0],
        lastName: names[1],
        number: creditCard.number,
        expiryMonth: creditCard.expMonth,
        expiryYear: creditCard.expYear,
        cvv: creditCard.cvv,
        billTo: {
          address: creditCard.address1,
          city: creditCard.city,
          state: creditCard.stateProvince,
          zip: creditCard.postalCode,
        },
        saveCard: creditCard.saveCard,
      };
    } else if (creditCardType === PaymentCreditCardTypes.Stored) {
      paymentProcessDetails.cardToken = cardToken;
      paymentProcessDetails.card = null;
    }
  } else {
    paymentProcessDetails.card = null;
    paymentProcessDetails.cardToken = null;
  }

  return paymentProcessDetails;
}
