import * as React from 'react';
import { Document, Page, View, Text, Image, StyleSheet } from '@react-pdf/renderer';

const addressesStyles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 16,
    fontSize: 12,
    fontWeight: 'normal',
  },
  item: {
    lineHeight: 1.3,
    fontSize: 11,
    padding: 1,
  },
  itemTitle: {
    fontSize: 10,
    paddingTop: 8,
    fontFamily: 'Helvetica-Bold',
    fontStyle: '',
    textTransform: 'uppercase',
    lineHeight: 1,
    marginBottom: 4,
  },
});

interface IPropertyListProps {
  items: { key: string; label: string; value: string }[];
}

const PropertyList: React.FunctionComponent<IPropertyListProps> = (props) => {
  const { items } = props;
  return (
    <View>
      {items.map(({ key, label, value }, idx) => (
        <View key={idx} style={addressesStyles.item}>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingTop: 2,
              paddingBottom: 2,
            }}
          >
            <View>
              <Text
                style={{
                  width: 80,
                  textAlign: 'left',
                  paddingRight: 8,
                  fontFamily: 'Helvetica-Bold',
                  fontSize: 9,
                  textTransform: 'uppercase',
                }}
              >
                {label || key}:
              </Text>
            </View>
            <View>
              <Text style={{ width: 120 }}>{value}</Text>
            </View>
          </View>
        </View>
      ))}
    </View>
  );
};

export default PropertyList;
