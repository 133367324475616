// import * as React from 'react'
// import { Button, Checkbox, Grid, Segment, Table, Label, Popup, Divider, Modal, Tab } from 'semantic-ui-react';
// import { Link, RouteComponentProps } from 'react-router-dom';
// import { FormattedDate } from 'react-intl';
// import moment from 'moment';
// import { connect } from 'react-redux';

// import { IndexWrapper, IndexWrappedProps } from './../../bootstrap/IndexWrapper';
// import { OrderItemComponent2, connectOrderItemModals, OrderItemModalsProps } from './../../common/OrderItemCard2';
// import { FilterForm } from '../orders/FilterForm';
// import { useList, PageHeaderRow, PaginatorWrapper, EmptyWrapper, FeathersDropdown } from '@inkcloud/components';

// import Feathers from './../../bootstrap/feathers';

// interface ListProps { }

// export interface ConnectedProps {
//   user?: any;
// }

// const filterQueryMap = (values) => {

//   let newValues: any = {}

//   if (values) {
//     newValues = {
//       ...values
//     }

//     delete newValues.customerJobName
//     delete newValues.category
//     delete newValues.itemType
//     delete newValues.productionStatus
//     delete newValues.quantity
//     delete newValues.start
//     delete newValues.end
//   }

//   const filterMap: any = {
//     attributes: {
//       ...newValues
//     },
//     category: values.category ? values.category : undefined,
//     itemType: values.itemType ? values.itemType : undefined,
//     'productionStatus.jobStatus': values.productionStatus ? values.productionStatus : undefined,
//     customerJobName: values.customerJobName ? { $LIKE: values.customerJobName } : undefined,
//     quantity: values.quantity ? values.quantity : undefined,
//     createdAt: {
//       $gte: values.start ? moment(values.start).format('YYYY-MM-DD') : undefined,
//       $lte: values.end ? moment(values.end).format('YYYY-MM-DD') : undefined
//     },
//   };

//   return [].reduce((m, field) => ({ ...m, [field]: values[field] ? values[field] : undefined }), filterMap);
// };

// const serviceName = 'order-items'
// const pathPrefix = '/status-update'

// const { useState, useRef } = React

// const PreList: React.FunctionComponent<ListProps & OrderItemModalsProps & ConnectedProps & RouteComponentProps<any>> = props => {
//   const { match: { params }, location, history, user } = props;
//   const isVendor = !!user.vendor;

//   const { state, reload, handleDelete, selectedItems, clearAll, setChecked, setSelectedItems, someSelected, toggleAll, filter, setFilter, sort, handleSort } = useList({
//     feathers: ['find', serviceName, [{
//       query: {
//         'productionStatus.jobStatus': { $ne: 'VOID' },
//         $populate: [
//           'attributes.attribute',
//           'attributes.value',
//           'category',
//           'customer',
//           'micrositeUser',
//           'productionStatus.jobStatus'
//         ],
//         $sort: { createdAt: -1 }
//       }
//     }]],
//     filterMap: filterQueryMap,
//     debounce: 150,
//     location,
//     history,
//     sort: { createdAt: -1 }
//   })

//   const [isLoading, setIsLoading] = useState(false)
//   const [status, setStatus] = useState(null)
//   const toggleAllCheck = useRef(null);

//   const handleArtworkManagementClick = (e, data) => {
//     props.artworkHandleOpen(data.orderitem);
//   }

//   const printHandleOpen = (e, data) => {
//     props.printHandleOpen(data.type, data.payload);
//   }

//   const preflightHandleOpen = (e, data) => {
//     props.preflightHandleOpen(data.orderitem);
//   }

//   const statusChangeHandleOpen = (e, data) => {
//     // props.statusChangeHandleOpen(data.orderitem);
//   }

//   const handleSubmitOrderItemStatus = async () => {
//     const orderItems = Object.keys(selectedItems).filter((k) => selectedItems[k] && selectedItems[k]);
//     setIsLoading(true)
//     await Feathers.service('order-items/bulk-status-change').create({
//       orderItems,
//       status
//     })
//     reload()
//     setIsLoading(false)
//     setSelectedItems({})

//     const statePage = (history as any).location.state.page

//     const backward = statePage > 0 ? statePage - 1 : statePage

//     history.replace(location.pathname, { ...(history as any).location.state, page: backward })

//     // (toggleAllCheck as any)?.current?.checked = false
//   }

//   const { queryResult, isLoading: isStateLoading } = state as any;

//   return (
//     <>
//       <PageHeaderRow header="Status" subheader="Update" />
//       <Segment>
//         <FilterForm value={filter} onChange={(v) => setFilter(v)} onReset={() => setFilter({})} />
//       </Segment>
//       <Segment loading={isLoading}>
//         <EmptyWrapper queryResult={queryResult} isStateLoading={isStateLoading}>
//           <PaginatorWrapper.Top filter={filter} reload={reload} data={(queryResult as any)} pathPrefix={`${pathPrefix}/`} />
//           <Divider hidden />
//           {/* <Checkbox onChange={(e, data) => handleAllCheckboxesChange(data.checked)} /> */}
//           <Table>
//             <Table.Header>
//               <Table.Row>
//                 <Table.HeaderCell>
//                   <input type="checkbox" ref={toggleAllCheck} onChange={toggleAll} />
//                 </Table.HeaderCell>
//                 <Table.HeaderCell />
//               </Table.Row>
//             </Table.Header>
//             <Table.Body>
//               {queryResult && queryResult.data && queryResult.data.map((i: any, index: number) =>
//                 <Table.Row key={index} verticalAlign='top'>
//                   <Table.Cell>
//                     <input
//                       type="checkbox"
//                       value={i._id}
//                       checked={selectedItems[i._id] || false}
//                       onChange={setChecked} />
//                   </Table.Cell>
//                   <Table.Cell>
//                     <Segment>
//                       <OrderItemComponent2
//                         orderItem={i}
//                         isVendor={isVendor}

//                         handleArtworkManagementClick={handleArtworkManagementClick}
//                         printHandleOpen={printHandleOpen}
//                         preflightHandleOpen={preflightHandleOpen}
//                         statusChangeHandleOpen={statusChangeHandleOpen}
//                         // onStatusChangerSubmit={handleStatusChangerSubmit}
//                         onReload={reload}
//                       // onTurnaroundSubmit={handleTurnaroundSubmit}
//                       />
//                     </Segment>
//                   </Table.Cell>
//                 </Table.Row>
//               )
//               }

//             </Table.Body>
//           </Table>

//           <Divider hidden />

//           <PaginatorWrapper.Bottom reload={reload} data={(queryResult as any)} pathPrefix={`${pathPrefix}`}>

//             <Grid stackable columns={2}>
//               <Grid.Row>
//                 <Grid.Column>
//                   <FeathersDropdown
//                     search selection fluid
//                     placeholder="Status..."
//                     serviceName='/order-item-statuses'
//                     query={{
//                       $sort: { name: 1 },
//                       $np: 1
//                     }}
//                     resultMapper={(r) => ({ key: r._id, value: r._id, text: r.name })}
//                     onChange={(e, data) => setStatus(data.value)}
//                   />
//                 </Grid.Column>
//                 <Grid.Column>
//                   <Button
//                     primary
//                     content='Update Statuses'
//                     onClick={handleSubmitOrderItemStatus}
//                     disabled={!status || Object.keys(selectedItems).length < 1} />
//                 </Grid.Column>
//               </Grid.Row>
//             </Grid>
//           </PaginatorWrapper.Bottom>

//         </EmptyWrapper>
//       </Segment>
//     </>
//   )
// }

// const mapStateToProps = (state): ConnectedProps => ({
//   user: state.auth.user || {},
// });

// export const List = connect(mapStateToProps, {})(connectOrderItemModals<IndexWrappedProps>(PreList) as any);
import React, { useState, useEffect } from 'react';
import { Header, Segment, Icon, Form, Popup, Divider, Dropdown, Message } from 'semantic-ui-react';

import {
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  Spinner,
  Flex,
  Checkbox,
  useCheckbox,
  Text,
  Button,
  Alert,
  FormLabel,
  Heading,
  // Icon,
} from '@chakra-ui/react';

import { Option } from 'react-select';
import { Select } from 'chakra-react-select';

import { Link, RouteComponentProps } from 'react-router-dom';
import {
  useList,
  PageHeaderRow,
  PaginatorWrapper,
  EmptyWrapper,
  GenericFilter,
  basicQueryMap,
  FeathersDropdown,
} from '@inkcloud/components';
import { connect } from 'react-redux';

import { IndexWrapper, IndexWrappedProps } from '../../bootstrap/IndexWrapper';
import {
  OrderItemComponent2,
  connectOrderItemModals,
  OrderItemModalsProps,
} from '../../common/OrderItemCard2';
import Feathers, { socket } from '../../bootstrap/feathers';

import { getFilterDefinition } from './FilterForm';

// simport { GenericFilter } from '@inkcloud/components'

interface ListProps {}

export interface ConnectedProps {
  user?: any;
}

// TODO: value should come from the row values
const checkValidValues = (filterRow, valueToCheck) => {
  const values =
    filterRow &&
    Object?.values(filterRow)
      ?.concat.apply([], filterRow)
      ?.filter((f) => f !== valueToCheck)
      ?.reduce(
        (m, field) => ({ ...m, [field]: values[field] ? values[field] : undefined }),
        filterRow
      );

  return values;
};

export const filterQueryMap = (values) => {
  let filterRows = basicQueryMap(values, {
    name: 'staticName',
  });

  // Map attributes as needed by API
  const prefix = 'attributes:';
  const attributes = Object.keys(filterRows)
    .filter((r) => r.startsWith(prefix))
    .reduce((acc, cur) => ({ ...acc, [cur.slice(prefix.length)]: filterRows[cur]?.$in }), {});

  // Delete the original attribute valuess so they are not submitted
  Object.keys(filterRows)
    .filter((r) => r.startsWith(prefix))
    .forEach((r) => delete filterRows[r]);

  let status;
  if (filterRows?.approvalStatus?.$elemMatch?.value === 'pending') {
    status = 'pending';
  } else if (filterRows?.approvalStatus?.$elemMatch?.value === 'rejected') {
    status = 'rejected';
  } else {
    status = { $ne: 'pending' };
  }

  filterRows = {
    ...filterRows,
    microsite: checkValidValues(filterRows.microsite, 'all'),
    addOns: filterRows.addOns ? { $elemMatch: { addOnId: filterRows.addOns } } : undefined,
    attributes,
    // parent: filterRows.type === 'stock-child' ? true : filterRows.type === 'stock-parent' ? { $ne: true } : undefined,
    approvalStatus: status,
    // metadata: filterRows.metadata ? { $elemMatch: { key: filterRows.metadata?.key, value: filterRows.metadata?.value }} : undefined,
    type: undefined,
  };

  return filterRows;
};

const sortableByOptions = [
  { text: 'Date', value: 'createdAt' },
  { text: 'Approval Date', value: 'approvedAt' },
  { text: 'Arrive By', value: 'arriveBy' },
  { text: 'Order Number', value: 'humanId' },
];

const sortByOptions = [
  { text: 'Descending', value: -1 },
  { text: 'Ascending', value: 1 },
];

const serviceName = 'order-items';
const pathPrefix = '/status-update';

const PreList: React.FunctionComponent<
  ListProps & OrderItemModalsProps & ConnectedProps & RouteComponentProps<any>
> = (props) => {
  const {
    match: { params },
    location,
    history,
    user,
  } = props;
  const isVendor = !!user.vendor;
  const {
    state,
    reload,
    handleDelete,
    selectedItems,
    clearAll,
    setChecked,
    someSelected,
    setSelectedItems,
    toggleAll,
    filter,
    setFilter,
    sort,
    handleSort,
  } = useList({
    feathers: [
      'find',
      serviceName,
      [
        {
          query: {
            'productionStatus.jobStatus': !isVendor ? { $ne: 'VOID' } : undefined,
            $populate: [
              { path: 'attributes.attribute', select: 'name' },
              { path: 'attributes.value', select: 'name' },
              { path: 'category', select: 'name' },
              { path: 'customer', select: 'organizationName primaryContact' },
              { path: 'micrositeUser', select: 'firstName lastName' },
              { path: 'productionChannel', select: 'name' },
              // 'productionStatus.jobStatus',
              'miscUploads',
            ],
            $populateProductImages: 1,
            $sort: { createdAt: -1 },
          },
        },
      ],
    ],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  const [gridViewOptions, setGridViewOptions] = useState<
    { label: string; value: string; filter: {} }[]
  >([]);

  const [sortableBy, setSortableBy] = useState('createdAt');
  const [sortBy, setSortBy] = useState(sort[sortableBy] === 'ascending' ? 1 : -1);

  const [exportFields, setExportFields] = useState([]);

  const [isSubmitting, setIsSubmitting] = useState({
    duplicate: false,
    export: false,
  });

  const [errorMessage, setErrorMessage] = useState({
    duplicate: '',
    export: '',
    update: '',
  });

  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState(null);
  const [statuses, setStatuses] = useState([]);

  useEffect(() => {
    const loadStatuses = async () => {
      try {
        const res = await Feathers.service('/order-item-statuses').find({
          query: {
            $sort: { name: 1 },
            $np: 1,
          },
        });

        setStatuses(res?.map((d) => ({ label: d.name, value: d._id })));
      } catch (e) {
        console.error(e);
      }
    };

    loadStatuses();
  }, []);

  const handleArtworkManagementClick = (e, data) => {
    props.artworkHandleOpen(data.orderitem);
  };

  const printHandleOpen = (e, data) => {
    props.printHandleOpen(data.type, data.payload);
  };

  const preflightHandleOpen = (e, data) => {
    props.preflightHandleOpen(data.orderitem);
  };

  const statusChangeHandleOpen = (e, data) => {
    // props.statusChangeHandleOpen(data.orderitem);
  };

  const handleSubmitOrderItemStatus = async () => {
    const orderItems = Object.keys(selectedItems).filter(
      (k) => selectedItems[k] && selectedItems[k]
    );
    setIsLoading(true);

    setErrorMessage({ ...errorMessage, update: '' });
    try {
      await Feathers.service('order-items/bulk-status-change').create({
        orderItems,
        status,
      });
      reload();
      setSelectedItems({});

      const statePage = (history as any).location.state.page;

      const backward = statePage > 0 ? statePage - 1 : statePage;

      history.replace(location.pathname, { ...(history as any).location.state, page: backward });
    } catch (e) {
      const errMsg = e?.code < 500 ? e?.message : 'Something went wrong. Please try again later.';
      setErrorMessage({ ...errorMessage, update: errMsg });
    }
    setIsLoading(false);
    // (toggleAllCheck as any)?.current?.checked = false
  };

  const isFiltered = Object.keys(filter)?.reduce(
    (prev, field) =>
      prev ||
      !!(Array.isArray(filter[field]) ? filter[field]?.some((f) => f.value) : filter[field]),
    false
  );

  let queryFilter;
  if (isFiltered) {
    queryFilter = filter?.rows?.reduce(
      (acc, cur) =>
        Object.assign(acc, {
          [cur.selectedFact?.replace(/\./g, '')]: Array.isArray(cur.value)
            ? cur.value?.map((v) => v.value)
            : cur.value?.value,
        }),
      {}
    );
  }

  const { queryResult, isLoading: isStateLoading } = state as any;

  const arrItems = Object.keys(selectedItems);
  const isAllChecked =
    arrItems.every((s) => selectedItems[s]) && arrItems?.length === queryResult?.data?.length;

  return (
    <>
      <PageHeaderRow header="Status" subheader="Update"></PageHeaderRow>
      <Box borderRadius="md" mb={4} borderWidth="1px" bg="white">
        <Box py={2} px={3} mb={5} bg="gray.100">
          <Heading as="h4" size="md" fontWeight="medium">
            Filter by the following
          </Heading>
        </Box>
        <Box py={3} px={5} mb={3}>
          <GenericFilter
            factDefinitionFunction={getFilterDefinition}
            value={filter}
            onChange={(v) => {
              setFilter({ ...v });
            }}
          />
        </Box>
      </Box>
      {/* <Header as="h5" attached="top" block>
        Filter by the following
      </Header>
      <Segment attached>
        <Box position="relative" zIndex="dropdown">
          {/* {gridViewOptions.length > 0 && (
            <Select
              options={gridViewOptions}
              placeholder="Select data view..."
              onChange={(v) => {
                setFilter({ ...(v?.filter ?? {}) })
                setExportFields(((v as any)?.exportFields as any) ?? [])
                history.replace({
                  search: `?page=1`,
                })
              }}
            />
          )} */}

      {/* <Box mt={4} mx={-3}>
            <GenericFilter
              factDefinitionFunction={getFilterDefinition}
              value={filter}
              onChange={(v) => {
                setFilter({ ...v })
              }}
            />
          </Box>
        </Box>
      </Segment> */}

      <Segment>
        <EmptyWrapper queryResult={queryResult} isStateLoading={isStateLoading}>
          <PaginatorWrapper.Top
            filter={filter}
            reload={reload}
            data={queryResult as any}
            pathPrefix={`${pathPrefix}/`}
          >
            <Form size="tiny">
              <Form.Group inline>
                <Form.Field>
                  <Dropdown
                    options={sortableByOptions}
                    value={sortableBy}
                    selection
                    onChange={(e, data) => {
                      setSortableBy((data as any).value);
                      setSortBy(sort[(data as any).value] !== 'ascending' ? 1 : -1);
                      handleSort((data as any).value);
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <Dropdown
                    options={sortByOptions}
                    value={sortBy}
                    selection
                    onChange={(e, data) => {
                      setSortBy(sort[sortableBy] !== 'ascending' ? 1 : -1);
                      handleSort(sortableBy);
                    }}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          </PaginatorWrapper.Top>
          {errorMessage.export && <Message error content={errorMessage.export} />}
          <Divider hidden />
          {/* <Checkbox onChange={(e, data) => handleAllCheckboxesChange(data.checked)} /> */}

          <Table>
            <Thead>
              <Tr>
                <Th colSpan={2}>
                  <FormLabel>
                    <Checkbox isChecked={isAllChecked} onChange={toggleAll} /> Select All
                  </FormLabel>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {queryResult?.data?.map((i: any, index: number) => (
                <Tr key={index}>
                  <Td verticalAlign="top">
                    <Checkbox
                      isChecked={selectedItems[i._id] || false}
                      value={i._id}
                      //  checked={selectedItems[i._id] || false}
                      onChange={setChecked}
                    />
                  </Td>
                  <Td>
                    <Box p={4} pb={0} borderRadius="md" borderWidth="1px">
                      <OrderItemComponent2
                        key={index}
                        orderItem={i}
                        isVendor={isVendor}
                        handleArtworkManagementClick={handleArtworkManagementClick}
                        printHandleOpen={printHandleOpen}
                        preflightHandleOpen={preflightHandleOpen}
                        statusChangeHandleOpen={statusChangeHandleOpen}
                        // onStatusChangerSubmit={handleStatusChangerSubmit}
                        onReload={reload}
                        // onTurnaroundSubmit={handleTurnaroundSubmit}
                      />
                    </Box>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>

          <Divider hidden />

          <PaginatorWrapper.Bottom
            reload={reload}
            data={queryResult as any}
            pathPrefix={`${pathPrefix}`}
          >
            <Flex>
              <Box w="50%" pr={4}>
                <Select
                  useBasicStyles
                  options={statuses}
                  onChange={(e: Option | null) => setStatus(e.value)}
                />
              </Box>
              <Box>
                <Button
                  isDisabled={!status || Object.keys(selectedItems).length < 1 || isLoading}
                  isLoading={isLoading}
                  colorScheme="blue"
                  onClick={handleSubmitOrderItemStatus}
                >
                  Update Status
                  {Object.keys(selectedItems)?.filter((s) => selectedItems[s]).length > 1 && 'es'}
                </Button>
              </Box>
            </Flex>
            {errorMessage.update && <Alert status="error">{errorMessage.update}</Alert>}
          </PaginatorWrapper.Bottom>
        </EmptyWrapper>
      </Segment>
    </>
  );
};

const mapStateToProps = (state): ConnectedProps => ({
  user: state.auth.user || {},
});

export const List = connect(
  mapStateToProps,
  {}
)(connectOrderItemModals<IndexWrappedProps>(PreList) as any);
