import React from 'react';
import { Switch, Route } from 'react-router-dom';

import List from './List';
import Detail from './Detail';

const routes = (props) => (
  <Switch>
    <Route exact path="/settings/data-views" component={List} />
    <Route exact path="/settings/data-views/add" component={Detail} />
    <Route exact path="/settings/data-views/:id" component={Detail} />
  </Switch>
);

export default routes;
