import * as React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { FormattedDate, FormattedTime } from 'react-intl';
import { useList, ListPage } from '@inkcloud/components';
import { Button, Label, Popup, SemanticCOLORS } from 'semantic-ui-react';

export interface ListProps {
  statusOptions: Array<{ value: string; text: string }>;
}

const serviceName = '/webhook-subscriptions';
const pathPrefix = `/settings/webhook-subscriptions`;

const filterQueryMap = (values) => {};

const statuses = [
  { text: 'Disabled', value: 'disabled' },
  { text: 'Active', value: 'active' },
  { text: 'Failing', value: 'failing' },
];

export const List: React.FunctionComponent<ListProps & RouteComponentProps> = (props) => {
  const {
    match: { params },
    location,
    history,
    statusOptions,
  } = props;

  const useListValues = useList({
    feathers: [
      'find',
      serviceName,
      [
        {
          query: {
            $sort: { createdAt: -1 },
            $populate: ['microsite'],
          },
        },
      ],
    ],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  const tableDefinition = [
    {
      key: 'createdAt',
      label: 'Date',
      sortable: true,
      cell: (v, r) => (
        <Popup
          size="mini"
          content={<FormattedTime value={r.createdAt} />}
          trigger={
            <div>
              <FormattedDate value={r.createdAt} />
            </div>
          }
        />
      ),
    },

    {
      key: 'name',
      label: 'Name',
      cell: (v, r) => v,
    },

    {
      key: 'description',
      label: 'Description',
      cell: (v, r) => v,
    },
    {
      key: 'url',
      label: 'URL',
      cell: (v, r) => v,
    },
    {
      key: 'microsite',
      label: 'Microsite',
      cell: (v, r) => r?.microsite?.name ?? '',
    },
    {
      key: 'status',
      label: 'Status',
      cell: (v, r) => {
        const text = statuses.find((s) => s.value === v)?.text ?? '';
        let c: SemanticCOLORS = 'grey';
        if (text === 'Active') {
          c = 'green';
        } else if (text === 'Failing') {
          c = 'orange';
        }

        return (
          <Label as="a" basic color={c}>
            {text}
          </Label>
        );
      },
    },
    {
      key: '',
      label: '',
      cell: (v, r) => (
        <>
          <Popup
            size="mini"
            content="Edit"
            align
            trigger={
              <Link to={`${pathPrefix}/${r._id}`}>
                <Button size="mini" icon="edit" />
              </Link>
            }
          />
        </>
      ),
      textAlign: 'right',
    },
  ];

  const headerActions = (
    <>
      <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
      <Link to={`${pathPrefix}/add`}>
        <Button size="mini" primary content="New" />
      </Link>
    </>
  );

  return (
    <>
      <ListPage
        header="Webhook Subscriptions"
        subheader="Settings"
        useListValues={useListValues}
        history={history}
        pathPrefix={pathPrefix}
        tableDefintion={tableDefinition}
        withCheckboxes={true}
        headerActions={headerActions}
        getFilterDefinition={null}
      />
    </>
  );
};
