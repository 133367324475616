import * as React from 'react';
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom'
// import { Menu, Icon, Transition } from 'semantic-ui-react'

import feathers from '../../bootstrap/feathers';

import { DefinedMenu } from './Menu';

export interface LeftNavProps {
  width?: any;
}

export interface ConnectedProps {
  user?: any;
  // className?: any;
  domainName?: string;
  tenantLogo?: string;
  // tenantId?: string;
}

// export interface LeftNavProps extends React.Props<LeftNavBase> {
//   // className?: any;
//   // noMobile: boolean;
// }

// export interface LeftNavState {
//   domainName: string;
//   useCustomLogo: boolean;
// }
// visible
// export class LeftNavBase extends React.Component<LeftNavProps & ConnectedProps,
//   LeftNavState> {
//   constructor(props) {
//     super(props);
//     this.state = {
//       domainName: null,
//       useCustomLogo: false
//     };
//   }
//   componentDidMount() {
//     this.loadDomainName(this.props.tenantId);
//   }

//   componentWillReceiveProps(nextProps) {
//     if (this.props.tenantId === null && nextProps !== null) {
//       this.loadDomainName(nextProps.tenantId);
//     }
//   }

//   // Hack to get domain name. Improve this later
//   loadDomainName(tenantId) {
//     if (tenantId) {
//       feathers.service<any>('/tenants').get(tenantId, { query: { $select: ['domainName'] } })
//         .then((tenant) => {
//           this.setState({ ...this.state, domainName: tenant.domainName });
//         })
//     }
//   }

//   render() {
//     console.log(this.props);

//     return (
//       <Transition visible={true} transitionOnMount={true} animation='slide right' duration={500}>
//         <DefinedMenu
//           vertical
//           fixed='left'
//           inverted
//           style={styleMenu}
//           domainname={this.state.domainName}
//           useCustomLogo={this.state.useCustomLogo} />
//       </Transition>
//     );

//   }
// };

const { useState, useEffect } = React;

export const LeftNavBase: React.FunctionComponent<ConnectedProps & LeftNavProps> = (props) => {
  const { user } = props;

  const [tenantSettings, setTenantSettings] = useState({
    logo: '',
    dashboardCustomLogo: false,
    domainName: '',
  });

  const styleMenu = {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    width: props?.width,
    paddingBottom: '1em',
    // match menu background
    // prevents a white background when items are filtered out by search
    background: '#1B1C1D',
    overflowY: 'scroll',
  };

  useEffect(() => {
    // const loadDomainName = async () => {
    //   try {
    //     const res = await feathers.service<any>('/tenants').get(user.business, {
    //       query: {
    //         $select: ['domainName'],

    //       }
    //     })

    //     if (res) {
    //       setDomainName(res.domainName)
    //     }
    //   } catch (e) {
    //     console.log('error');
    //   }
    // }

    const loadTenantSettings = async () => {
      try {
        const res = await feathers.service<any>('/tenant-settings').get(user.business, {
          query: {
            $populate: ['theme.logo'],
            $select: ['theme', 'publishedContactInfo'],
          },
        });

        if (res) {
          setTenantSettings({
            logo: res?.theme?.logo?.thumbUrl ?? '',
            dashboardCustomLogo: res?.theme?.dashboardCustomLogo ?? false,
            domainName: res?.publishedContactInfo?.domainName ?? '',
          });
        }
      } catch (e) {}
    };

    if (user.business) {
      // loadDomainName()
      loadTenantSettings();
    }
  }, [user]);

  return (
    // <Transition visible={true} transitionOnMount={true} animation="slide right" duration={500}>
    <DefinedMenu
      vertical
      fixed="left"
      inverted
      style={styleMenu}
      domainname={tenantSettings.domainName}
      tenantlogo={tenantSettings.logo}
      usecustomlogo={tenantSettings.dashboardCustomLogo ? 1 : 0}
      user={user}
    />
    // </Transition>
  );
};

const mapStateToProps = (state): ConnectedProps => ({
  user: state.auth.user || {},
});

export default connect(mapStateToProps, {})(LeftNavBase);
