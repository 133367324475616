import * as React from 'react';

import {
  Table as ChakraTable,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  Text,
} from '@chakra-ui/react';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { useSelector } from 'react-redux';

import { FormattedNumber } from 'react-intl';

import { ISemanticOptions } from '../primitives';

import { LineItemRow, ILineItemRowProps, rowStyle } from './LineItemRow';

const headerStyle: React.CSSProperties = {
  flexGrow: 1,
  borderRight: '1px solid #eaeaea',
  width: '100%',
  background: '#eaeaea',
  // marginTop: 'auto',
  verticalAlign: 'text-bottom',
  paddingTop: 8,
};

export interface ILineItemGridProps {
  lineItems: ILineItemRowProps['value'][];
  onChange: (value: ILineItemRowProps['value'][]) => void;
  productLoad: (inputValue: string) => Promise<{ label: string; value: string }[]>;
  isDisabled?: boolean;
  isTaxableDefault?: boolean;
  isGeneralLedgerHidden?: boolean;
  glAccounts: Array<{ label: string; value: string }>;
  mode?: 'purchase-order' | 'sales-order';
  hideTax?: boolean;
  orderType?: string;
  summary: any;
  onAddLine: () => void;
}

export const randomGenerator = () => {
  const rand = () => (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  return `${rand() + rand()}-${rand() + rand()}-${rand() + rand()}-${rand() + rand()}`;
};

const { useEffect } = React;

export function LineItemGrid(props: ILineItemGridProps) {
  const {
    lineItems,
    onChange,
    productLoad,
    glAccounts,
    isDisabled = false,
    isTaxableDefault = true,
    isGeneralLedgerHidden = false,
    mode = 'purchase-order',
    hideTax = false,
    summary,
    onAddLine,
  } = props;

  const tenant = useSelector((state: any) => state.globals.tenant?.internationalization);

  const handleRowChange = (idx) => (v) =>
    onChange(lineItems.map((l, index) => (idx === index ? v : l)));

  useEffect(() => {
    if (!lineItems || lineItems.length === 0) {
      onChange([
        {
          id: String(Math.floor(Date.now() / 1000)),
          amount: 0,
          total: 0,
          quantity: 0,
          receivedOrShipped: 0,
          glAccount: '',
          description: '',
          product: '',
          isTaxable: isTaxableDefault,
          taxRate: 0.0,
        },
      ]);
    }
  }, []);

  const handleRemove = (idx) => onChange(lineItems.filter((l, index) => idx !== index));

  const handleAddRow = () =>
    onChange(
      lineItems.concat({
        amount: 0,
        quantity: 0,
        total: 0,
        glAccount: '',
        description: '',
        product: '',
        isTaxable: false,
        taxRate: 0.0,
      })
    );

  return (
    <ChakraTable size="sm">
      <Thead>
        <Tr>
          {!isDisabled && <Th></Th>}
          <Th>Item</Th>
          <Th>Description</Th>
          {!isGeneralLedgerHidden && <Th>General Ledger</Th>}
          <Th textAlign="center">Qty</Th>
          {/* <Th textAlign="center"> {mode === 'purchase-order' ? 'Received' : 'Shipped'}</Th> */}
          <Th textAlign="center">{mode === 'purchase-order' ? 'Cost' : 'Price'}</Th>
          {/* <Th textAlign="center">Total</Th> */}
          {!hideTax && <Th>Tax</Th>}
          {!isDisabled && <Th style={{ width: 35 }}></Th>}
        </Tr>
      </Thead>
      <Tbody>
        <DndProvider backend={HTML5Backend}>
          {lineItems?.map((l: any, idx) => (
            <Tr key={idx}>
              <LineItemRow
                lineItems={lineItems}
                index={idx}
                value={l}
                onChange={handleRowChange(idx)}
                onReOrder={onChange}
                onRemove={() => (isDisabled ? null : handleRemove(idx))}
                onAddRow={() => (lineItems?.length - 1 === idx ? handleAddRow() : null)}
                isDisabled={isDisabled}
                glAccounts={glAccounts}
                isGeneralLedgerHidden={isGeneralLedgerHidden}
                productLoad={productLoad}
                hideTax={hideTax}
                mode={mode}
              />
            </Tr>
          ))}
        </DndProvider>
      </Tbody>
      <Tfoot>
        <Tr>
          {!isDisabled && <Td></Td>}
          <Td px={0}>
            {onAddLine && (
              <Button
                isDisabled={isDisabled}
                size={'sm'}
                variant={'solid'}
                colorScheme={'gray'}
                onClick={onAddLine}
              >
                Add Item
              </Button>
            )}
          </Td>
          {!isGeneralLedgerHidden && <Td></Td>}
          <Td></Td>
          {/* <Td></Td> */}
          <Td></Td>
          <Td></Td>
          <Td></Td>
        </Tr>
        {mode === 'sales-order' && (
          <Tr>
            {!isDisabled && <Td></Td>}
            {!isGeneralLedgerHidden && <Td></Td>}
            <Td></Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
            <Th isNumeric>
              <Text fontSize="sm">Taxable</Text>
            </Th>
            <Td isNumeric>
              <Text fontWeight={'medium'} fontSize="sm">
                <FormattedNumber
                  value={summary?.taxable}
                  style="currency"
                  currency={tenant?.currency ?? 'USD'}
                />
              </Text>
            </Td>
            <Td></Td>
          </Tr>
        )}
        {mode === 'sales-order' && (
          <Tr>
            {!isDisabled && <Td></Td>}
            {!isGeneralLedgerHidden && <Td></Td>}
            <Td></Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
            <Th isNumeric>
              <Text fontSize="sm">Non Taxable</Text>
            </Th>
            <Td isNumeric>
              <Text fontWeight={'medium'} fontSize="sm">
                <FormattedNumber
                  value={summary?.nonTaxable}
                  style="currency"
                  currency={tenant?.currency ?? 'USD'}
                />
              </Text>
            </Td>
            <Td></Td>
          </Tr>
        )}
        {mode === 'sales-order' && (
          <Tr>
            {!isDisabled && <Td></Td>}
            <Td></Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
            <Th isNumeric>
              <Text fontSize="sm">Sales Tax</Text>
            </Th>
            <Td isNumeric>
              <Text fontWeight={'medium'} fontSize="sm">
                <FormattedNumber
                  value={summary?.salesTax}
                  style="currency"
                  currency={tenant?.currency ?? 'USD'}
                />
              </Text>
            </Td>
            <Td></Td>
          </Tr>
        )}
        <Tr>
          {!isDisabled && <Td></Td>}
          {!isGeneralLedgerHidden && <Td></Td>}
          <Td></Td>
          <Td></Td>
          {/* <Td></Td> */}
          <Th isNumeric>
            <Text fontSize={'medium'}>Total</Text>
          </Th>
          <Td isNumeric>
            <Text fontWeight={'medium'} fontSize={'medium'}>
              <FormattedNumber
                value={summary?.total || 0}
                style="currency"
                currency={tenant?.currency ?? 'USD'}
              />
            </Text>
          </Td>
          <Td></Td>
        </Tr>
      </Tfoot>
    </ChakraTable>
  );
}
