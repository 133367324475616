import * as React from 'react';
import { Switch, Route } from 'react-router-dom';

import { Detail } from './Detail';
import OrderNotes from './order-notes';

const routes = (props) => (
  <Switch>
    <Route exact path="/settings/general" component={Detail} />
    <Route exact path="/settings/general/order-notes" component={OrderNotes} />
  </Switch>
);

export default routes;
