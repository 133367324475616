import * as React from 'react';
import { Switch, Route } from 'react-router-dom';

import { List } from './List';

const routes = (props) => (
  <Switch>
    <Route exact path="/admin/email-parse/log" component={List} />
  </Switch>
);

export default routes;
