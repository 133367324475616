import * as React from 'react';
import Select from 'react-select';

export const addOnFields = [
  {
    key: 'sc',
    options: [
      { value: '', label: 'No Scoring' },
      { value: '1', label: '1 Score Line' },
      { value: '2', label: '2 Score Lines' },
    ],
  },
  {
    key: 'pf',
    options: [
      { value: '', label: 'No Perforating' },
      { value: '1', label: '1 Perf Line' },
      { value: '2', label: '2 Perf Lines' },
    ],
  },
  {
    key: 'dc',
    options: [
      { value: '', label: 'No Die Cutting' },
      { value: '1', label: 'Die Cut' },
    ],
  },
  {
    key: 'pfet',
    options: [
      { value: '', label: 'No Perforating' },
      { value: '1', label: '1 Perf Line' },
    ],
  },
  {
    key: 'em',
    options: [
      { value: '', label: 'No Embossing' },
      { value: '1', label: '1 Emboss Line' },
    ],
  },
  {
    key: 'nb',
    options: [
      { value: '', label: 'No Numbering' },
      { value: '1', label: 'Numbering' },
    ],
  },
  {
    key: 'nbet',
    options: [
      { value: '', label: 'No Numbering' },
      { value: '1', label: 'Numbering' },
    ],
  },
  {
    key: 'pdfp',
    options: [
      { value: '', label: 'No PDF Proof - Run As Is' },
      { value: '1', label: 'PDF Proof' },
    ],
  },
  {
    key: 'h3',
    options: [
      { value: '', label: 'No Hole Drilling' },
      { value: '1', label: 'Hole Drilling' },
    ],
  },
  {
    key: 'hd3',
    options: [
      { value: '', label: 'No Hole Drilling' },
      { value: '1', label: 'Hole Drilling - 3-hole standard' },
    ],
  },
];

export const dynamicAddOns = [
  {
    key: 'folding',
    name: 'Folding',
    options: ['FAC', 'FDG', 'FHF', 'FQP', 'FTF', 'FZF'],
  },
  {
    key: 'scoring',
    name: 'Scoring',
    options: ['SFAC', 'SFDG', 'SFHF', 'SFQP', 'SFTF', 'SFZF'],
  },
  {
    key: 'grommeting',
    name: 'Grommeting',
    options: ['GRC', 'GR2'],
  },
  {
    key: 'hemming',
    name: 'Hemming',
    options: ['HA4', 'HTB', 'HLR'],
  },
  {
    key: 'polepockets',
    name: 'Pole Pockets',
    options: ['PP2TB', 'PP3TB', 'PP4TB', 'PP2A4', 'PP3A4', 'PP4A4'],
  },
  {
    key: 'shrinkwrapping',
    name: 'Shrink Wrapping',
    options: ['SW50', 'SW100', 'SW150'],
  },
];

export const fsOptions = [
  { value: '', label: 'No Foil Stamping' },
  { value: 'GOLD', label: 'Gold' },
  { value: 'SILVER', label: 'Silver' },
  { value: 'RED', label: 'Red' },
  { value: 'BLUE', label: 'Blue' },
  { value: 'GREEN', label: 'Green' },
];

export const bcslitOptions = [
  { value: '', label: 'No Card Slits' },
  { value: '1', label: '1 Card Slit' },
  { value: '2', label: '2 Card Slits' },
];

function generateDynamicAddOns(key, addOnSettings, addOns) {
  const foundAddOn = dynamicAddOns.find((a) => a.key === key);
  const options = foundAddOn.options;
  const foldingOptions = Object.keys(addOnSettings).reduce(
    (prev, cur) => {
      if (options.includes(cur.toUpperCase())) {
        if (addOns[cur] && addOns[cur].name) {
          prev.push({ value: cur, label: addOns[cur].name });
        }
      }

      return prev;
    },
    [{ value: '', label: `No ${foundAddOn.name}` }]
  );

  return foldingOptions;
}

function generateRCOptions() {
  const corners = [
    { label: 'Top left', value: 8 },
    { label: 'Top right', value: 4 },
    { label: 'Bottom right', value: 2 },
    { label: 'Bottom left', value: 1 },
  ];

  const rcOptions = [
    { value: '', label: 'No Round Corners' },
    { value: '1111', label: 'All Corners' },
  ];
  for (let i = 1; i < 15; i++) {
    const label = corners
      .reduce((prev, c) => {
        if (c.value & i) {
          prev.push(c.label);
        }
        return prev;
      }, [])
      .join(' / ');

    rcOptions.push({ value: `0000${i.toString(2)}`.slice(-4), label });
  }

  return rcOptions;
}

export const rcOptions = generateRCOptions();

export interface AddOnSetProps {
  addOnSettings: any;
  onChange: (value: object) => void;
  addOns: any;
  selectedAddOns: any;
}

export interface AddOnSetState {
  enabled: object;
}

export class AddOnSet extends React.Component<AddOnSetProps, AddOnSetState> {
  constructor(props: AddOnSetProps) {
    super(props);

    this.state = {
      enabled: {},
    };
  }

  _handleChange = (key, value) => {
    this.setState(
      {
        ...this.state,
        enabled: {
          ...this.state.enabled,
          [key]: value ? value : null,
        },
      },
      () => {
        this.props.onChange(this.state.enabled);
      }
    );
  };

  handleOtherChange = (field) => (value) => {
    const updatedEnabled = {
      ...this.state.enabled,
      [field]: value,
    };

    this.setState(
      {
        ...this.state,
        enabled: updatedEnabled,
      },
      () => this.props.onChange(this.state.enabled)
    );
  };

  handleDynamicChange = (field) => (value) => {
    const updatedEnabled = {
      ...this.state.enabled,
      [value.value]: value,
    };

    const fieldItem = dynamicAddOns.find((a) => a.key === field);

    const reset = fieldItem.options
      .filter((a) => a !== value.value)
      .forEach((a) => delete updatedEnabled[a]);

    this.setState(
      {
        ...this.state,
        enabled: updatedEnabled,
      },
      () => this.props.onChange(this.state.enabled)
    );
  };

  public render() {
    const { addOnSettings } = this.props;
    return (
      <div>
        {addOnSettings.FS && addOnSettings.FS.isEnabled && (
          <Select
            name={'fs'}
            options={fsOptions}
            defaultValue={fsOptions.find((o) => o.value === '')}
            onChange={this.handleOtherChange('fs')}
          />
        )}

        {addOnSettings.RC && addOnSettings.RC.isEnabled && (
          <Select
            name={'rc'}
            options={rcOptions}
            defaultValue={rcOptions.find((o) => o.value === '')}
            onChange={this.handleOtherChange('rc')}
          />
        )}

        {addOnFields.map((f) => {
          if (addOnSettings[f.key.toUpperCase()] && addOnSettings[f.key.toUpperCase()].isEnabled) {
            return (
              <Select
                key={f.key}
                name={f.key}
                options={f.options}
                defaultValue={f.options.find((o) => o.value === '')}
                onChange={this.handleOtherChange(f.key)}
              />
            );
          }

          return null;
        })}

        {dynamicAddOns.map((f) => {
          if (addOnSettings[f.key.toUpperCase()] && addOnSettings[f.key.toUpperCase()].isEnabled) {
            const options = generateDynamicAddOns(
              f.key,
              this.props.addOnSettings,
              this.props.addOns
            );
            return (
              <Select
                key={f.key}
                name={f.name}
                options={options}
                defaultValue={options.find((o) => o.value === '')}
                onChange={this.handleDynamicChange(f.key)}
              />
            );
          }

          return null;
        })}
      </div>
    );
  }
}
