import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Segment, Table } from 'semantic-ui-react';
import { PageHeaderRow, PreLoader, useFeathers } from '@inkcloud/components';
import feathers from '../../../bootstrap/feathers';
import { ProductionChannelsForm } from './IntakeChannelsForm';

interface DetailProps {
  products: { value: string; text: string }[];
  categories: { value: string; text: string }[];
}

const pathPrefix = `/settings/intake-channels`;
const serviceName = '/intake-channels';
const service = feathers.service(serviceName);
const commonParams = { query: {} };
const { useState } = React;
export const Detail: React.FunctionComponent<DetailProps & RouteComponentProps<{ id?: string }>> = (
  props
) => {
  const {
    match: {
      params: { id },
    },
    history,
  } = props;

  const [errorMessage, setErrorMessage] = useState('');
  const isEdit = !!id;
  const [state, reload] = useFeathers('get', serviceName, [id, commonParams], true);

  const handleSubmit = async (values) => {
    const payload = {
      ...values,
      key: values?.key?.replace(/-+$/, ''), // Trim (-) hyphen from end of text
    };

    try {
      setErrorMessage('');
      isEdit
        ? await service.patch(id, payload, commonParams)
        : await service.create(payload, commonParams);
      history.push(pathPrefix);
    } catch (e) {
      e.code && e.code < 500
        ? setErrorMessage(e.message)
        : setErrorMessage('We are experiencing technical difficulties. Please try again');
    }
  };

  const pageTitle = isEdit ? 'Edit' : 'New';

  if (isEdit && !state.data) {
    return <PreLoader />;
  }

  const initialData = { ...state.data };
  return (
    <div>
      <PageHeaderRow header={`${pageTitle} Intake Channels`} subheader="Settings" subheaderId={''}>
        <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
      </PageHeaderRow>

      <Segment>
        {(!isEdit || state.data) && (
          <ProductionChannelsForm
            initialValues={initialData}
            errorMessage={errorMessage}
            onSubmit={handleSubmit}
          />
        )}
      </Segment>
    </div>
  );
};
