import * as React from 'react';
import { Dropdown } from 'semantic-ui-react';

export interface VariantTypeDropdownProps {
  options: { text: string; value: string };
  loading: boolean;
  value: string;
  onAddItem: (name: string) => void;
  onChange: (value: string) => void;
  isDisabled?: boolean;
}

export const VariantTypeDropdown = (props: VariantTypeDropdownProps) => {
  const { options, loading, value, onAddItem, onChange, isDisabled = false } = props;

  return (
    <Dropdown
      placeholder={'Variant Type...'}
      selection
      fluid
      search
      options={options as any}
      loading={loading}
      value={value}
      allowAdditions={true}
      onAddItem={(e, data) => {
        onAddItem(data.value as any);
      }}
      onChange={(e, data) => onChange(data.value as any)}
      icon={isDisabled ? '' : `dropdown ${value ? 'clear' : ''}`}
      disabled={isDisabled}
    />
  );
};
