import { Operator, generateOperatorOptions } from '@inkcloud/components';
import Feathers from '../../../../bootstrap/feathers';

export async function getFilterDefinition() {
  const attributeTypes: any = await Feathers.service('/products-legacy/attribute-types').find({
    query: {
      $np: 1,
      $sort: { name: 1 },
      isDeleted: { $ne: true },
      _id: { $ne: 'QUANTITY' },
    },
  });

  const attributeFilters = attributeTypes.map((attr) => ({
    label: `Attribute:${attr.name}`,
    value: `attributes:${attr._id}`,
    operators: generateOperatorOptions([Operator.Is]),
    valueOptions: [],
    asyncValueOptions: async () =>
      Feathers.service('products-legacy/attributes')
        .find({
          query: {
            $np: '1',
            $sort: { name: 1 },
            entityAttribute: attr._id,
          },
        })
        .then((results) => results.map((r) => ({ value: r._id, label: r.name }))),
    type: 'multi-select',
  }));

  let definition = [
    {
      label: 'Add Ons',
      value: 'addOns',
      operators: generateOperatorOptions([Operator.Is]),
      valueOptions: [],
      asyncValueOptions: async () =>
        Feathers.service('products-legacy/add-ons-base')
          .find({
            query: {
              $sort: { name: 1 },
            },
          })
          .then((results) => results.map((r) => ({ value: r._id, label: r.name }))),
      type: 'multi-select',
    },
    {
      label: 'Category',
      value: 'category',
      operators: generateOperatorOptions([Operator.Is, Operator.IsNot]),
      valueOptions: [],
      asyncValueOptions: async () =>
        Feathers.service('products-legacy/categories')
          .find({
            query: {
              $np: '1',
              $sort: { name: 1 },
            },
          })
          .then((results) => results.map((r) => ({ value: r._id, label: r.name }))),
      type: 'multi-select',
    },
    {
      label: 'Date:Created',
      value: 'createdAt',
      operators: generateOperatorOptions([Operator.Between]),
      type: 'date',
    },
    {
      label: 'Date:Updated',
      value: 'updatedAt',
      operators: generateOperatorOptions([Operator.Between]),
      type: 'date',
    },
    {
      label: 'Description',
      value: 'description',
      operators: generateOperatorOptions([Operator.Like, Operator.Is, Operator.IsNot]),
    },
    {
      label: 'GL Code',
      value: 'glCode',
      operators: generateOperatorOptions([Operator.Is, Operator.IsNot]),
    },
    {
      label: 'Inventory Count',
      value: 'inventoryCount',
      operators: generateOperatorOptions([
        Operator.Is,
        Operator.IsNot,
        Operator.GreaterThan,
        Operator.LessThan,
      ]),
    },
    {
      label: 'Name',
      value: 'name',
      operators: generateOperatorOptions([Operator.Like, Operator.Is, Operator.IsNot]),
    },
    {
      label: 'Price',
      value: 'price',
      operators: generateOperatorOptions([
        Operator.Is,
        Operator.IsNot,
        Operator.GreaterThan,
        Operator.LessThan,
      ]),
    },
    {
      label: 'Sku',
      value: 'key',
      operators: generateOperatorOptions([Operator.Like, Operator.Is, Operator.IsNot]),
    },
    {
      label: 'Status',
      value: 'status',
      operators: generateOperatorOptions([Operator.Is, Operator.IsNot]),
      type: 'select',
    },
    {
      label: 'Tags',
      value: 'tags',
      operators: generateOperatorOptions([Operator.Is]),
      valueOptions: [],
      type: 'multi-select-create',
    },
    {
      label: 'Type',
      value: 'type',
      operators: generateOperatorOptions([Operator.Is, Operator.IsNot]),
      valueOptions: [
        { value: 'composite', label: 'Composite' },
        { value: 'on-demand', label: 'On-Demand' },
        { value: 'stock', label: 'Stock' },
        { value: 'stock-parent', label: 'Stock:Parent' },
        // { value: 'stock-child', label: 'Stock:Child' },
      ],
      type: 'select',
    },
    {
      label: 'UOM',
      value: 'unitOfMeasure',
      operators: generateOperatorOptions([Operator.Like, Operator.Is, Operator.IsNot]),
    },
    {
      label: 'UOM:Conversion',
      value: 'unitOfMeasureConversion',
      operators: generateOperatorOptions([
        Operator.Is,
        Operator.IsNot,
        Operator.GreaterThan,
        Operator.LessThan,
      ]),
    },
    {
      label: 'Version',
      value: 'version',
      operators: generateOperatorOptions([
        Operator.Is,
        Operator.IsNot,
        Operator.GreaterThan,
        Operator.LessThan,
      ]),
    },
    {
      label: 'Weight',
      value: 'weightBasis',
      operators: generateOperatorOptions([
        Operator.Is,
        Operator.IsNot,
        Operator.GreaterThan,
        Operator.LessThan,
      ]),
    },
  ];

  definition = definition
    .concat(attributeFilters)
    .sort((a: any, b: any) => a.label.localeCompare(b.label));

  return definition;
}
