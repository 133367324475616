import * as React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import {
  Segment,
  Dropdown,
  Button,
  Menu,
  Label,
  Divider,
  Header,
  Modal,
  Message,
  Icon,
} from 'semantic-ui-react';
import { useSelector } from 'react-redux';

import { FaThList } from 'react-icons/fa';
import { Box, Icon as ChakraIcon, Heading } from '@chakra-ui/react';
import type { ModelTypes } from '@inkcloud/icapi-types';
import { PageHeaderRow, EmptyWrapper, PaginatorWrapper, useList } from '@inkcloud/components';
import { IndexWrapper, IndexWrappedProps } from '../../bootstrap/IndexWrapper';
import {
  OrderItemComponent2,
  connectOrderItemModals,
  OrderItemModalsProps,
} from '../../common/OrderItemCard2';
import Feathers from '../../bootstrap/feathers';
import { InfoTable } from './Detail/InfoTable';
import NoteContainer from '../../common/notes2/index';

import { ConvertMemoModal } from './ConvertMemoModal';
import { InvoiceStatusModal } from './InvoiceStatusModal';
import { EmailModal } from './EmailModal';
import ShipOutModal from './ShipOutModal';
import { CostCenterUpdateModal } from './cost-center-update';
import ReadyForPickupModal from './ReadyForPickupModal';
import { PrintModal } from './PrintModal';

import { ActualShipment } from './ActualShipment';
import { BillShip } from './BillShip';
import { InvoiceItem } from './InvoiceItem';
import { AppliedPayment } from './AppliedPayment';
import { PreFlight } from './PreFlight';
import InvoiceHistoryModal from './InvoiceHistoryModal';
import { PrintTypes } from '../../common/PrintModal';
import OrderReview from '../rfqs/RFQ2/Manage/OrderReview';

declare const localStorage: any;

const lastCartKey = 'last-cart';

interface DetailProps {
  loadOrderItems: Function;
  saveOrderNotes: Function;
  orderItems: any;
}

const filterQueryMap = () => {};

const { useState, useEffect } = React;

const errorMessage500 = 'We are experiencing technical difficulties. Please try again';

const PreDetail: React.FunctionComponent<
  DetailProps & OrderItemModalsProps & RouteComponentProps<any>
> = (props) => {
  const {
    match: {
      params: { id },
    },
    location,
    history,
  } = props;

  const serviceName = 'order-items';
  const commonParams = {
    query: {
      order: id,
      $populate: [
        { path: 'attributes.attribute', select: 'name' },
        { path: 'attributes.value', select: 'name' },
        { path: 'category', select: 'name' },
        { path: 'customer', select: 'organizationName primaryContact' },
        { path: 'micrositeUser', select: 'firstName lastName' },
        { path: 'productionChannel', select: 'name' },
        { path: 'vendor', select: 'companyName' },
        // 'productionStatus.jobStatus',
        'miscUploads',
        'product',
      ],
      $sort: { createdAt: -1 },
    },
  };

  const tenant = useSelector(
    ({ globals }: { globals: { tenant: { locale: string; countryCode: string; _id: string } } }) =>
      globals.tenant
  );

  const awHide = tenant._id === '633f78a0cbbf6916101874f2';
  const { state, reload, handleDelete, someSelected, filter } = useList({
    feathers: ['find', serviceName, [commonParams]],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    // sort: { humandId: -1 }
  });

  const pathPrefix = `/orders/${id}`;

  const [isEmailInvoiceModalOpen, setIsEmailInvoiceModalOpen] = useState(false);
  const [isInvoiceStatusModalOpen, setIsInvoiceStatusModalOpen] = useState(false);
  const [isShipModalOpen, setIsShipModalOpen] = useState(false);
  const [isReadyForPickupModalOpen, setIsReadyForPickupModalOpen] = useState(false);
  const [isCostCenterUpdateModalOpen, setIsCostCenterUpdateModalOpen] = useState(false);
  const [isConvertMemoModalOpen, setIsConvertMemoModalOpen] = useState(false);
  const [isNotesModalOpen, setIsNotesModalOpen] = useState(false);
  const [isInvoiceHistoryOpen, setIsInvoiceHistoryOpen] = useState(false);

  const [isOrderItemReload, setIsOrderItemReload] = useState(false);
  const [isInvoiceReload, setIsInvoiceReload] = useState(true);

  const [orders, setOrders] = useState<
    ModelTypes.Orders & {
      invoice?: ModelTypes.Invoices;
    }
  >({});

  const [payments, setPayments] = useState([]);

  const [downloadLink, setDownloadLink] = useState('');

  const [isSubmitting, setIsSubmitting] = useState({
    costCenter: false,
  });

  const [errorMessage, setErrorMessage] = useState({
    note: '',
    print: '',
    reorder: '',
    invoiceStatus: '',
    markShipped: '',
    markPickup: '',
    costCenter: '',
  });

  const authUser = useSelector((state2: any) => state2.auth.user);

  const isVendor = !!authUser?.vendor;

  const [isReorderLoading, setIsReorderLoading] = useState(false);

  const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);

  useEffect(() => {
    const loadOrders = async () => {
      try {
        const res: any = await Feathers.service('orders').get(id, {
          query: {
            $populate: [
              'customer',
              'micrositeUser',
              'notes.user',
              'invoice',
              'approvedByTenantUser',
              'salesPerson',
              'salesPerson',
              'actualShipments.orderItems.orderItemId',
              'pickupLocation',
              'intakeChannel',
            ],
          },
        });
        if (res) {
          setOrders(res);

          if ((res.invoice && res.invoice._id) || res.invoice) {
            const resPayments: any = await Feathers.service('payments/applied').find({
              query: {
                invoice: (res.invoice && res.invoice._id) || res.invoice,
                $populate: ['payment'],
              },
            });

            setPayments(resPayments.data);
          }
        }
      } catch (error) {
        console.log(error);
      }

      setIsInvoiceReload(false);
    };

    if (isInvoiceReload || id) {
      loadOrders();
    }
  }, [isInvoiceReload, id]);

  const handleArtworkManagementClick = (e, data) => {
    props.artworkHandleOpen(data.orderitem);
  };

  const printHandleOpen = (e, data) => {
    props.printHandleOpen(data.type, data.payload);
  };

  const printHandleOpenOrderLevel = (e, data) => {
    props.printHandleOpen(data.modaltype, data.payload);
  };

  const preflightHandleOpen = (e, data) => {
    props.preflightHandleOpen(data.orderitem);
  };

  const statusChangeHandleOpen = (e, data) => {
    // props.statusChangeHandleOpen(data.orderitem);
  };

  const handleNoteSubmit = async (value) => {
    setIsInvoiceReload(true);
    setErrorMessage({ ...errorMessage, note: '' });
    try {
      await Feathers.service('orders/notes').create({
        orderId: id,
        content: value,
      });
      setIsInvoiceReload(false);
      setIsNotesModalOpen(false);
      reload();
    } catch (e) {
      setIsInvoiceReload(false);
      const message = e.code && e.code < 500 ? e.message : errorMessage500;
      setErrorMessage({ ...errorMessage, note: message });
    }
  };

  const handleInvoiceStatus = async (isClosed: boolean) => {
    setIsInvoiceReload(true);
    setErrorMessage({ ...errorMessage, invoiceStatus: '' });
    try {
      await Feathers.service('/invoices').patch(
        (orders.invoice && orders.invoice._id) || orders.invoice,
        {
          isClosed,
        }
      );

      setIsInvoiceReload(false);
      setIsInvoiceStatusModalOpen(false);
    } catch (e) {
      setIsInvoiceReload(false);
      const message = e.code && e.code < 500 ? e.message : errorMessage500;
      setErrorMessage({ ...errorMessage, invoiceStatus: message });
    }
  };

  // const handleMarkShipped = async (values) => {
  //   setIsInvoiceReload(true);

  //   const payload = {
  //     orderId: values?.orders._id,
  //     trackingNumber: values?.shipmentType === 'pickup' ? '' : values?.trackingNumber,
  //     actualCost: values?.shipmentType === 'pickup' ? null : values?.actualCost,
  //     actualCostAdjusted: values?.shipmentType === 'pickup' ? null : values?.actualCostAdjusted,
  //     orderItems: values?.orderItems || undefined,
  //     isReship: values?.shipmentType === 'pickup' ? false : values?.isReship,
  //     additionalEmail: values?.extraRecipient,
  //     shipmentType: values?.shipmentType,
  //   };

  //   if (values?.shipmentType === 'pickup') {
  //     delete payload.additionalEmail;
  //   }
  //   setErrorMessage({ ...errorMessage, markShipped: '' });
  //   try {
  //     const res = await Feathers.service('/shipping/mark-shipped').create(payload);
  //     reload();
  //     setIsInvoiceReload(false);
  //     setIsShipModalOpen(false);

  //     return res;
  //   } catch (e) {
  //     setIsInvoiceReload(false);
  //     const message = e.code && e.code < 500 ? e.message : errorMessage500;
  //     setErrorMessage({ ...errorMessage, markShipped: message });
  //   }
  // };

  const handleCostCenterUpdate = async (newCostCenter) => {
    if (!orders.invoice) {
      return;
    }

    setIsInvoiceReload(true);

    setIsSubmitting({
      ...isSubmitting,
      costCenter: true,
    });

    try {
      await Feathers.service('/invoices').patch(
        (orders.invoice && orders.invoice._id) || orders.invoice,
        {
          costCenter: newCostCenter,
        }
      );

      reload();
      setIsCostCenterUpdateModalOpen(false);
      setIsInvoiceReload(false);
      setIsSubmitting({
        ...isSubmitting,
        costCenter: false,
      });
    } catch (e) {
      setIsSubmitting({
        ...isSubmitting,
        costCenter: false,
      });
      setIsInvoiceReload(false);
      const message = e.code && e.code < 500 ? e.message : errorMessage500;
      setErrorMessage({ ...errorMessage, costCenter: message });
    }
  };

  const handleMarkReadyForPickup = async () => {
    setErrorMessage({ ...errorMessage, markPickup: '' });
    try {
      await Feathers.service('/shipping/mark-ready-for-pickup').create({
        orderId: orders._id,
      });
      reload();
      setIsReadyForPickupModalOpen(false);
    } catch (e) {
      const message = e.code && e.code < 500 ? e.message : errorMessage500;
      setErrorMessage({ ...errorMessage, markPickup: message });
    }
  };

  const handleReOrder = async () => {
    setIsReorderLoading(true);
    setErrorMessage({ ...errorMessage, reorder: '' });

    try {
      const response: any = await Feathers.service('/orders/reorder').create({
        tenant: orders.business,
        order: id,
      });

      localStorage.setItem(lastCartKey, response._id);
      history.push('/order-builder');
    } catch (e) {
      setIsReorderLoading(false);
      const message = e.code && e.code < 500 ? e.message : errorMessage500;
      setErrorMessage({ ...errorMessage, reorder: message });
    }
  };

  const handlePrintPackingSlip = async (actualShipmentId: string) => {
    setErrorMessage({ ...errorMessage, print: '' });
    try {
      const res: any = await Feathers.service('/printable/packing-slip').create({
        orderId: orders._id,
        actualShipmentId,
      });

      if (res) {
        setDownloadLink(res.url);
      }
    } catch (e) {
      const message = e.code && e.code < 500 ? e.message : errorMessage500;
      setErrorMessage({ ...errorMessage, print: message });
    }
  };

  const { queryResult, isLoading: isStateLoading } = state as any;

  if (!orders?._id) {
    return null;
  }

  const sortedOrderItems = queryResult?.data
    ?.map((i: any) => ({
      ...i,
      priority: parseInt(i.humanId.split('-')[1], 10),
    }))
    ?.sort((a, b) => a.priority - b.priority);

  return (
    <>
      <div className={isReorderLoading ? 'ui segment loading' : ''}>
        <PageHeaderRow
          header={
            <>
              Order{' '}
              {orders?.isVoid ? (
                <span style={{ color: '#db2828', fontSize: '24px' }}>VOIDED</span>
              ) : (
                ''
              )}
            </>
          }
          subheader="Order"
          subheaderId={orders && orders.humanId}
        >
          <Dropdown
            className="icon mini primary"
            position="left"
            direction="left"
            button
            icon="ellipsis horizontal"
          >
            <Dropdown.Menu>
              <Dropdown.Header icon="tags" content="Choose an order action" />
              <Dropdown.Divider />
              <Dropdown.Item
                icon="print"
                text="Print Ticket"
                modaltype={PrintTypes.JobTicketFull}
                payload={{ orderId: orders._id }}
                onClick={printHandleOpenOrderLevel}
              />
              {!isVendor && (
                <Dropdown.Item
                  icon="print"
                  text="Print Invoice"
                  modaltype={PrintTypes.Invoice}
                  payload={{ orderId: orders._id }}
                  onClick={printHandleOpenOrderLevel}
                />
              )}
              <Dropdown.Item
                icon="print"
                text="Print Packing Slip"
                modaltype={PrintTypes.PackingSlip}
                payload={{ orderId: orders._id }}
                onClick={printHandleOpenOrderLevel}
              />
              <Dropdown.Divider />
              {!isVendor && (
                <>
                  <Dropdown.Item
                    as={Link}
                    icon="edit"
                    content="Void Invoice"
                    to={`/accounting/void-invoices/${orders._id}`}
                  />
                  <Dropdown.Item
                    as={Link}
                    icon="edit"
                    content="Adjust Invoice"
                    to={`/invoice/adjuster/${orders._id}`}
                  />
                  <Dropdown.Item
                    as={Link}
                    icon="edit"
                    content="Adjust Shipping"
                    to={`/shipping/adjuster/${orders._id}`}
                  />
                  <Dropdown.Item
                    icon="envelope outline"
                    content={'Email Invoice'}
                    onClick={() => setIsEmailInvoiceModalOpen(true)}
                  />
                  <Dropdown.Item
                    icon="exchange"
                    content={'Invoice Status'}
                    onClick={() => {
                      setErrorMessage({ ...errorMessage, invoiceStatus: '' });
                      setIsInvoiceStatusModalOpen(true);
                    }}
                  />
                  <Dropdown.Item
                    icon="long arrow alternate right"
                    content={'Reorder'}
                    onClick={handleReOrder}
                  />
                </>
              )}
              {orders.shipment && (
                <Dropdown.Item
                  icon="box"
                  content={'Ship Order'}
                  onClick={() => {
                    setErrorMessage({ ...errorMessage, markShipped: '' });
                    setIsShipModalOpen(true);
                  }}
                />
              )}
              {!orders.shipment && (
                <Dropdown.Item
                  icon="box"
                  content={'Mark Ready For Pickup'}
                  onClick={() => {
                    setErrorMessage({ ...errorMessage, markPickup: '' });
                    setIsReadyForPickupModalOpen(true);
                  }}
                />
              )}
              {!isVendor && (
                <Dropdown.Item
                  icon="cc"
                  content={'Update Cost Center'}
                  onClick={() => setIsCostCenterUpdateModalOpen(true)}
                />
              )}
            </Dropdown.Menu>
          </Dropdown>
          <Button onClick={() => history.goBack()} content="Back" size={'mini'} />
        </PageHeaderRow>

        {errorMessage.reorder && <Message error content={errorMessage.reorder} />}

        <Menu pointing secondary size="small">
          <Menu.Item name="Info" active={true} />
          <Menu.Menu position="right">
            <Menu.Item name="Notes" active={true} />
            <Menu.Item icon={'add'} onClick={() => setIsNotesModalOpen(true)} />
          </Menu.Menu>
        </Menu>
        <div
          className="ui two column divided padded white grid"
          style={{ background: 'white', fontSize: 12 }}
        >
          {orders && <div className="ten wide column">{<InfoTable data={orders} />}</div>}
          <div className="six wide column">
            <NoteContainer
              isModalOpen={isNotesModalOpen}
              notes={orders.notes}
              errorMessage={errorMessage.note}
              onSubmit={handleNoteSubmit}
              handleNoteClose={() => setIsNotesModalOpen(false)}
            />
          </div>
        </div>

        {awHide && !isVendor && (
          <Box my={8}>
            <OrderReview title="Order Review" subTitle="" isReadOnly={false} orderId={orders._id} />
          </Box>
        )}

        <Menu attached="top" pointing secondary>
          <Menu.Item name="order items" active={true} />
        </Menu>
        <Segment attached="bottom" className={`${isOrderItemReload && 'loading'}`}>
          <EmptyWrapper queryResult={sortedOrderItems} isStateLoading={isStateLoading}>
            <PaginatorWrapper.Top
              filter={filter}
              reload={reload}
              data={queryResult as any}
              pathPrefix={`${pathPrefix}/`}
            />
            <Divider hidden />
            {sortedOrderItems &&
              sortedOrderItems.map((i: any, index: number) => (
                <OrderItemComponent2
                  key={index}
                  orderItem={i}
                  handleArtworkManagementClick={handleArtworkManagementClick}
                  printHandleOpen={printHandleOpen}
                  preflightHandleOpen={preflightHandleOpen}
                  statusChangeHandleOpen={statusChangeHandleOpen}
                  onReload={reload}
                  // onStatusChangerSubmit={handleStatusChangerSubmit}
                  // onTurnaroundSubmit={handleTurnaroundSubmit}
                />
              ))}
            <PaginatorWrapper.Bottom
              reload={reload}
              data={queryResult as any}
              pathPrefix={`${pathPrefix}`}
            >
              {someSelected && (
                <Button primary type="button" content="Delete" onClick={handleDelete} />
              )}
            </PaginatorWrapper.Bottom>
          </EmptyWrapper>
        </Segment>

        <Menu attached="top" pointing secondary>
          <Menu.Item name="Shipments" active={true} />
        </Menu>
        <Segment attached="bottom">
          <ActualShipment
            actualShipments={orders.actualShipments as []}
            isStateLoading={isStateLoading}
            setIsInvoiceReload={setIsInvoiceReload}
            onSubmit={handlePrintPackingSlip}
            onPrintModal={() => setIsPrintModalOpen(true)}
          />
        </Segment>

        {!awHide && (
          <>
            <Menu attached="top" pointing secondary>
              <Menu.Item name="Invoice" active={true} />
              <Menu.Menu position="right">
                <Menu.Item>
                  <div>
                    <Button
                      content="Invoice History"
                      size="tiny"
                      onClick={() => setIsInvoiceHistoryOpen(true)}
                    />
                  </div>
                </Menu.Item>
              </Menu.Menu>
            </Menu>
            <Segment attached="bottom">
              <Label color="blue" ribbon>
                {orders.humanId}
              </Label>
              <BillShip values={orders} />
              <Divider hidden />
              <InvoiceItem
                reload={isInvoiceReload}
                customer={orders.customer}
                business={orders.business}
                humanId={orders.humanId}
                invoiceId={(orders.invoice && orders.invoice._id) || (orders.invoice as string)}
                isAbleToRefund
              />
              <Divider hidden />
              {payments?.length > 0 && (
                <>
                  <Header as="h4">Applied Payments</Header>
                  <AppliedPayment payments={payments} onReload={() => setIsInvoiceReload(true)} />
                </>
              )}
              {/* <Button
              size="tiny"
              primary
              content="Convert to Credit Memo"
              onClick={() => setIsConvertMemoModalOpen(true)}
            /> */}
            </Segment>
          </>
        )}

        <Menu attached="top" pointing secondary>
          <Menu.Item name="Preflight History" active={true} />
        </Menu>
        <PreFlight id={orders._id} attached={'bottom'} />

        {isInvoiceStatusModalOpen && (
          <InvoiceStatusModal
            open={isInvoiceStatusModalOpen}
            order={orders}
            onClose={() => setIsInvoiceStatusModalOpen(false)}
            onSubmit={handleInvoiceStatus}
            errorMessage={errorMessage.invoiceStatus}
          />
        )}

        {isConvertMemoModalOpen && (
          <ConvertMemoModal
            open={isConvertMemoModalOpen}
            order={orders}
            onClose={() => setIsConvertMemoModalOpen(false)}
            onSubmit={() => {}}
          />
        )}

        {isShipModalOpen && (
          <ShipOutModal
            order={orders}
            onClose={() => setIsShipModalOpen(false)}
            // onSubmit={handleMarkShipped}
            onReload={() => {
              reload();
              setIsInvoiceReload(true);
            }}
            // errorMessage={errorMessage.markShipped}
          />
        )}

        {isEmailInvoiceModalOpen && (
          <EmailModal
            open={isEmailInvoiceModalOpen}
            type="invoice"
            customer={orders.customer}
            business={orders.business as string}
            humanId={orders.humanId as string}
            invoiceId={(orders.invoice && orders.invoice._id) || (orders.invoice as string)}
            onClose={() => setIsEmailInvoiceModalOpen(false)}
          />
        )}

        {isPrintModalOpen && (
          <PrintModal
            isOpen={isPrintModalOpen}
            errorPrint={errorMessage.print}
            url={downloadLink}
            onClose={() => {
              setErrorMessage({ ...errorMessage, print: '' });
              setDownloadLink('');
              setIsPrintModalOpen(false);
            }}
          />
        )}

        {isReadyForPickupModalOpen && (
          <ReadyForPickupModal
            open={isReadyForPickupModalOpen}
            order={orders}
            handleClose={() => setIsReadyForPickupModalOpen(false)}
            handleSubmit={handleMarkReadyForPickup}
            errorMessage={errorMessage.markPickup}
          />
        )}

        {isCostCenterUpdateModalOpen && (
          <CostCenterUpdateModal
            open={isCostCenterUpdateModalOpen}
            costCenter={orders.invoice ? (orders.invoice.costCenter as string) : ''}
            onClose={() => setIsCostCenterUpdateModalOpen(false)}
            onSubmit={handleCostCenterUpdate}
            isSubmitting={isSubmitting.costCenter}
            errorMessage={errorMessage.costCenter}
          />
        )}

        {isInvoiceHistoryOpen && (
          <InvoiceHistoryModal order={orders} onClose={() => setIsInvoiceHistoryOpen(false)} />
        )}
      </div>
    </>
  );
};

export const Detail = connectOrderItemModals<IndexWrappedProps>(PreDetail);
