import * as React from 'react';
import { Form, Input, Checkbox, Segment, Dropdown, Button, Message } from 'semantic-ui-react';
import brace from 'brace';
import AceEditor from 'react-ace';
import 'brace/mode/html';
import 'brace/theme/monokai';

interface ContentEmailFormProps {
  initialData: any;
  isSubmitting: boolean;
  isEdit: boolean;
  onChange: (v: any) => void;
  onSubmit: () => void;
  errorMessage: string;
  errorTemplateMessage: string;
}

export const ContentEmailForm: React.FunctionComponent<ContentEmailFormProps> = (props) => {
  const {
    initialData,
    onChange,
    onSubmit,
    isSubmitting,
    isEdit,
    errorMessage,
    errorTemplateMessage,
  } = props;

  const formTemplate = (
    <>
      <Form.Field>
        <label htmlFor="">Subject</label>
        <Input
          fluid
          name="subject"
          defaultValue={initialData && initialData.subject}
          onChange={(e, data) => onChange({ subject: data.value })}
        />
      </Form.Field>
      <Form.Field>
        <Checkbox
          label="Is MJML"
          checked={initialData.isMjml}
          onChange={(e, data) => onChange({ isMjml: data.checked })}
        />
      </Form.Field>

      <Form.Field>
        <label htmlFor="email-template-editor">Template</label>
        {errorTemplateMessage && <Message size="small" error content={errorTemplateMessage} />}
        <Segment inverted style={{ marginTop: 0 }}>
          <AceEditor
            mode="html"
            theme="monokai"
            onChange={(template) => onChange({ template })}
            name="email-template-editor"
            editorProps={{ $blockScrolling: true }}
            value={initialData && initialData.template ? initialData.template : ''}
            width={'100%'}
            showGutter={false}
          />
        </Segment>
      </Form.Field>
      <Button
        primary
        content="Save Template"
        onClick={onSubmit}
        disabled={initialData && !initialData.template}
      />
    </>
  );

  return (
    <Segment>
      <Form loading={isSubmitting && !errorMessage} className={errorTemplateMessage && 'error'}>
        {formTemplate}
      </Form>
    </Segment>
  );
};
