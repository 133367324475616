import * as React from 'react';
import { Segment, Table, Dropdown, Form, Button, Modal } from 'semantic-ui-react';
import { Formik, Form as FormikForm, Field, ErrorMessage, FieldProps } from 'formik';
import { FeathersDropdown } from '@inkcloud/components';
import feathers from './../../../bootstrap/feathers';

import * as Yup from 'yup';

interface AddOnPricingFormProps {
  initialValues: any;
  isEdit: boolean;
  onSubmit: (v: any) => void;
}

const AddOnPricingFormSchema = Yup.object().shape({
  addOnId: Yup.string().required('Add on ID must be selected'),
  turnaround: Yup.number().typeError('Turnaround Time must be a number'),
  setup: Yup.number().typeError('Setup must be a number'),
  minimum: Yup.number().typeError('Minimum must be a number'),
  rate: Yup.number().typeError('Rate must be a number'),
  sqFtRate: Yup.number().typeError('Rate/SqFt must be a number'),
});

const { useState, useEffect } = React;

export const AddOnPricingForm: React.FunctionComponent<AddOnPricingFormProps> = (props) => {
  const { initialValues, isEdit, onSubmit } = props;

  const [addOns, setAddOns] = useState([]);
  const [baseAddOns, setBaseAddOns] = useState([]);

  useEffect(() => {
    const loadAllAddOns = async () => {
      const res: any = await feathers.service('products-legacy/add-ons').find({
        query: {
          $sort: { name: 1 },
          $np: 1,
        },
      });
      setAddOns(res);
    };

    const loadAddOnsBase = async () => {
      const res: any = await feathers.service('products-legacy/add-ons-base').find({
        query: {
          $sort: { name: 1 },
        },
      });
      setBaseAddOns(res);
    };

    if (!isEdit) {
      loadAllAddOns();
      loadAddOnsBase();
    }
  }, []);

  const filteredAddOns =
    baseAddOns.length > 0 && baseAddOns.filter((b) => !addOns.find((a) => a.addOnId === b._id));

  let newInitialValues;

  if (!isEdit) {
    newInitialValues = {
      ...initialValues,
      addOnId: '',
    };
  }

  return (
    <Formik
      initialValues={!isEdit ? newInitialValues : initialValues}
      validationSchema={AddOnPricingFormSchema}
      onSubmit={(values) => onSubmit(values)}
    >
      {(props) => {
        const { values, isSubmitting } = props;

        return (
          <FormikForm className={`ui form ${isSubmitting && 'loading'} `}>
            <Form.Field>
              {!isEdit && (
                <>
                  <Field
                    name="addOnId"
                    render={({ field }: FieldProps<any>) => (
                      <Dropdown
                        search
                        selection
                        clearable
                        placeholder={'Select an add on...'}
                        options={
                          filteredAddOns.length > 0 &&
                          filteredAddOns.map((f) => ({ key: f._id, value: f._id, text: f.name }))
                        }
                        onChange={(e, data) =>
                          field.onChange({ target: { value: data.value, name: 'addOnId' } } as any)
                        }
                      />
                    )}
                  />
                  <ErrorMessage name="addOnId" />
                </>
              )}
            </Form.Field>
            <Form.Field>
              <label>Turnaround Time</label>
              <Field name="turnaround" />
              <ErrorMessage name="turnaround" />
            </Form.Field>
            <Form.Field>
              <label>Setup</label>
              <Field name="setup" />
              <ErrorMessage name="setup" />
            </Form.Field>
            <Form.Field>
              <label>Minimum</label>
              <Field name="minimum" />
              <ErrorMessage name="minimum" />
            </Form.Field>
            <Form.Field>
              <label>Rate</label>
              <Field name="rate" />
              <ErrorMessage name="rate" />
            </Form.Field>
            <Form.Field>
              <label>Rate/SqFt</label>
              <Field name="sqFtRate" />
              <ErrorMessage name="sqFtRate" />
            </Form.Field>
            <Button type="submit" primary content="Save" disabled={isSubmitting} />
          </FormikForm>
        );
      }}
    </Formik>
  );
};
