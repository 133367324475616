import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { Box, Image, Button, Tooltip, HStack, Tag, Alert, Heading } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import {
  ListPage,
  Operator,
  basicQueryMap,
  generateOperatorOptions,
  useList,
} from '@inkcloud/components';
import { useSelector } from 'react-redux';
import { FormattedDate, FormattedTime, FormattedNumber } from 'react-intl';
import type { ModelTypes } from '@inkcloud/icapi-types';

import { Feathers } from '../../../bootstrap/feathers/feathers';
import { ExportModal } from '../../reports/ExportModal';

const filterQueryMap = (values) => {
  let filterRows = basicQueryMap(values, {});

  filterRows = {
    ...filterRows,
    stateProvince: filterRows?.stateProvince?.$elemMatch.value,
  };

  return filterRows;
};
const serviceName = 'customer-product-library';

async function getFilterDefinition() {
  const definition = [
    {
      label: 'Name',
      value: 'name',
      operators: generateOperatorOptions([Operator.Like]),
    },
  ];

  return definition;
}

export default function List() {
  const history = useHistory();
  const location = useLocation();

  const { id } = useParams<{ id: string }>();

  const tenant = useSelector(
    ({ globals }: { globals: { tenant: { internationalization: { currency: string } } } }) =>
      globals.tenant.internationalization
  );

  const [customer, setCustomer] = useState<ModelTypes.Customers>();

  const [isExporting, setIsExporting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [exportUrl, setExportUrl] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const pathPrefix = `/customers/${id}/artwork-library`;

  useEffect(() => {
    const loadCustomer = async () => {
      try {
        const res = await Feathers.service('customers').get(id);
        setCustomer(res);
      } catch (e) {
        console.error(e);
      }
    };

    loadCustomer();
  }, [id]);

  const useListValues = useList({
    feathers: [
      'find',
      serviceName,
      [
        {
          query: {
            customer: id,
            $populate: [
              'artwork',
              'category',
              'product',
              'product.attributes',
              'productAttributes.value',
            ],
          },
        },
      ],
    ],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  const tableDefinition = [
    {
      key: 'createdAt',
      label: 'Date',
      sortable: true,
      cell: (v, r) => (
        <Tooltip hasArrow aria-label="Time" label={<FormattedTime value={r.createdAt} />}>
          <Box>
            <FormattedDate value={r.createdAt} />
          </Box>
        </Tooltip>
      ),
    },
    {
      key: '',
      label: 'Category',
      cell: (v, r) => r?.category?.name,
    },
    {
      key: '',
      label: 'SKU',
      cell: (v, r) => r?.product?.key,
    },
    {
      key: 'name',
      label: 'Name',
    },
    {
      key: '',
      label: 'Price',
      cell: (v, r) => (
        <FormattedNumber
          style="currency"
          value={r?.product?.price}
          currency={tenant?.currency ?? 'USD'}
        />
      ),
      textAlign: 'right',
    },
    {
      key: '',
      label: 'Weight Unit',
      cell: (v, r) => r?.product?.weightUnit,
      textAlign: 'right',
    },
    {
      key: '',
      label: 'Image',
      cell: (v, r) =>
        r?.artwork?.thumbnails?.[0]?.url && (
          <Image boxSize="100px" objectFit="contain" src={r?.artwork?.thumbnails?.[0]?.url} />
        ),
    },
    {
      key: '',
      label: 'Attribute List',
      cell: (v, r) => r?.productAttributes?.map((a) => a?.value?.name)?.join(', '),
    },
  ];

  const handleExport = async () => {
    setErrorMessage('');
    setIsExporting(true);
    try {
      const res = await Feathers.service(serviceName).find({
        query: {
          customer: id,
          $export: 1,
        },
      });
      setExportUrl(res?.url);
      setIsModalOpen(true);
    } catch (e) {
      const errMsg = e?.code < 500 ? e?.message : 'Something went wrong';
      setErrorMessage(errMsg);
    }
    setIsExporting(false);
  };

  const headerActions = (
    <>
      <HStack spacing={4}>
        <Button isDisabled={isExporting} isLoading={isExporting} size="sm" onClick={handleExport}>
          Export Customer Product Library
        </Button>
        <Link to={`/customers/${id}`}>
          <Button size="sm" colorScheme="blue">
            Back
          </Button>
        </Link>
      </HStack>
    </>
  );

  return (
    <>
      <Box mb={6} p={3} bg="gray.100" borderWidth={1} borderRadius="md">
        <Heading as="h2" size="md" mb={0} color="gray.700">
          {customer?.organizationName ?? ''} {customer?.humanId ? ` - ${customer?.humanId}` : ''}
        </Heading>
      </Box>
      {errorMessage && (
        <Alert status="error" mb={3}>
          {errorMessage}
        </Alert>
      )}
      <ListPage
        header="Customer Product Library"
        subheader="List"
        useListValues={useListValues}
        history={history}
        pathPrefix={pathPrefix}
        headerActions={headerActions}
        tableDefintion={tableDefinition}
        withCheckboxes={true}
        getFilterDefinition={getFilterDefinition}
      />

      {isModalOpen && (
        <ExportModal
          isModalOpen={isModalOpen}
          handleClose={() => setIsModalOpen(false)}
          exportUrl={exportUrl}
        />
      )}
    </>
  );
}
