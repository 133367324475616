import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useSelector } from 'react-redux';

import { Box, Button, Icon, IconButton, Text, Progress } from '@chakra-ui/react';

import { FaTimes, FaFileUpload } from 'react-icons/fa';

import axios from '../bootstrap/axios';

declare const APPENV;

interface ImageUploadProps {
  showFiles?: boolean;
  isMultiple?: boolean;
  api?: string;
  micrositeId?: string;
  onUpload: (files: any) => void;
  btnLabel?: string;
  isDraggable?: boolean;
  isFileRemovable?: boolean;
}

export default function UploadMisc(props: ImageUploadProps) {
  const {
    showFiles = true,
    isDraggable = true,
    isMultiple = true,
    isFileRemovable = false,
    btnLabel = 'Upload File',
  } = props;
  const [files, setFiles] = useState<any>([]);
  const [isUploading, setIsUploading] = useState(false);

  const [barValue, setBarValue] = useState(0);

  const api = props.api ? props.api : '/uploads-misc';

  const tenant = useSelector(
    ({
      globals,
    }: {
      globals: { tenant: { internationalization: { countryCode: string }; _id: string } };
    }) => globals.tenant
  );

  const onDrop = useCallback(async (acceptedFiles) => {
    setFiles(files.concat(acceptedFiles));

    acceptedFiles.map(async (f) => {
      const formData = new FormData();

      formData.append('file', f);
      formData.append('tenantId', tenant._id);

      if (props.micrositeId) {
        formData.append('microsite', props.micrositeId);
      }

      setIsUploading(true);
      try {
        const response = await axios.post(`${APPENV.api.connection}${api}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: localStorage.getItem('feathers-jwt'),
          },
          onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            let percent = Math.floor((loaded * 100) / total);

            if (percent < 100) {
              if (percent < 5) {
                percent = 5;
              }

              setBarValue(percent);
            }
          },
        });

        if (props.api) {
          props?.onUpload(response.data);
          return null;
        }

        props?.onUpload(response.data.files?.files[0]);
      } catch (e) {
        console.error(e);
      }

      setIsUploading(false);
    });
  }, []);

  const handleRemoveClick = (idx: number) => {
    const updatedFiles = [...files];
    updatedFiles.splice(idx, 1);
    setFiles(updatedFiles);
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const rootProps = getRootProps() as any;
  return (
    <Box p={3} mb={3} borderRadius="md" borderWidth="1px">
      <Box
        p={3}
        borderRadius="md"
        borderWidth="1px"
        borderStyle="dashed"
        borderColor="blue.400"
        textAlign="center"
        {...rootProps}
      >
        <input {...getInputProps({ multiple: isMultiple })} />
        {isDraggable && (
          <>
            <Box mb={3}>
              <Icon as={FaFileUpload} color="blue.400" boxSize={10} />
            </Box>
            {isDragActive ? (
              <Text fontWeight="bold">Drop the files here ... </Text>
            ) : (
              <Text fontWeight="bold">Drag 'n' drop some files here, or click to select files</Text>
            )}
          </>
        )}
        <Button
          my={3}
          isDisabled={isUploading}
          isLoading={isUploading}
          colorScheme="blue"
          type="button"
        >
          {btnLabel}
        </Button>
        {isUploading && <Progress hasStripe value={barValue} />}
      </Box>

      {showFiles && (
        <Box>
          {files.map((f, idx) => (
            <Box key={idx} mt={3}>
              {isFileRemovable && (
                <>
                  <IconButton
                    type="button"
                    aria-label="Remove file"
                    size="sm"
                    onClick={(e) => {
                      handleRemoveClick(idx);
                      e.stopPropagation();
                    }}
                    icon={<FaTimes />}
                  />{' '}
                </>
              )}
              {f.name}
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
}
