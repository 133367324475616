// import * as React from 'react';
// import { Accordion, Button, Checkbox, Header, Grid, Label, Segment, Table } from 'semantic-ui-react';
// import { Link } from 'react-router-dom';
// import { FormattedDate, FormattedNumber, FormattedRelative } from 'react-intl';
// import { IndexWrapper, IndexWrappedProps } from './../../../bootstrap/IndexWrapper';
// import { ListViewPagination, ListFilterWarning } from './../../../common';
// // import FilterForm from './FilterForm';

// const filterMapper = (filter) => {
//   return {
//     name: { $LIKE: filter && filter.name },
//   }
// }

// export function Container (props: IndexWrappedProps) {
//   if (!props.queryResult) {
//     return null;
//   }
//   const { data, skip, limit, total } = props.queryResult;
//   return (
//     <div>
//       <Header as='h1'>Production Resources</Header>
//       <Header attached={'top'} as='h5'>
//       Filter
//       </Header>
//       <Segment attached secondary>
//         {/* <FilterForm
//           onSubmit={props.handleFilterSubmit}
//           handleClearFilter={props.handleClearFilter}
//           isFiltered={props.isFiltered}
//           /> */}
//       </Segment>
//       <ListFilterWarning isFiltered={props.isFiltered} />
//       <Segment attached loading={props.isLoading}>
//         <ListViewPagination.Top
//           label={'New Production Resource'}
//           link={'/production/resources/add'}
//           {...props}
//         />
//         <Table size={'small'}>
//           <Table.Header>
//             <Table.Row>
//               <Table.HeaderCell>
//                 <Checkbox
//                   onChange={props.handleSelectAllClick}
//                 />
//               </Table.HeaderCell>
//               <Table.HeaderCell>Name</Table.HeaderCell>
//               <Table.HeaderCell>Types</Table.HeaderCell>
//               <Table.HeaderCell></Table.HeaderCell>
//             </Table.Row>
//           </Table.Header>
//           <Table.Body>
//             { data.map( i => (
//               <Table.Row key={i._id}>
//                 <Table.Cell>
//                   <Checkbox
//                     value={i._id}
//                     checked={props.selected[i._id] || false}
//                     onChange={props.handleSemanticCheckboxClick}
//                   />
//                 </Table.Cell>
//                 <Table.Cell>
//                   {`${i.name}` }
//                 </Table.Cell>
//                 <Table.Cell>
//                 <Label size={'mini'}>{i.type}</Label>
//                 </Table.Cell>
//                 <Table.Cell>
//                   <Link to={'/production/resources/' + i._id}>
//                     <Button size={'mini'}>Edit</Button>
//                   </Link>
//                 </Table.Cell>
//               </Table.Row>
//             ))}
//           </Table.Body>
//         </Table>
//         <ListViewPagination.Bottom
//           label={'Delete'}
//           {...props}
//         />
//       </Segment>
//     </div>
//   );
// }

// export default IndexWrapper(Container, 'productionResources', {
//   filterMapper,
//   query: {
//     $sort: { name: 1 }
//   }
// });

// import * as React from 'react';
// import { Accordion, Button, Checkbox, Header, Grid, Label, Segment, Table } from 'semantic-ui-react';
// import { Link } from 'react-router-dom';
// import { FormattedDate, FormattedNumber, FormattedRelative } from 'react-intl';
// import { IndexWrapper, IndexWrappedProps } from './../../../bootstrap/IndexWrapper';
// import { ListViewPagination, ListFilterWarning } from './../../../common';
// // import FilterForm from './FilterForm';

// const filterMapper = (filter) => {
//   return {
//     name: { $LIKE: filter && filter.name },
//   }
// }

// export function Container(props: IndexWrappedProps) {
//   if (!props.queryResult) {
//     return null;
//   }
//   const { data, skip, limit, total } = props.queryResult;
//   return (
//     <div>
//       <Header as='h1'>Production Channels</Header>
//       <Header attached={'top'} as='h5'>
//         Filter
//       </Header>
//       <Segment attached secondary>
//         {/* <FilterForm
//           onSubmit={props.handleFilterSubmit}
//           handleClearFilter={props.handleClearFilter}
//           isFiltered={props.isFiltered}
//           /> */}
//       </Segment>
//       <ListFilterWarning isFiltered={props.isFiltered} />
//       <Segment attached loading={props.isLoading}>
//         <ListViewPagination.Top
//           label={'New Production Channel'}
//           link={'/production/channels/add'}
//           {...props}
//         />
//         <Table size={'small'}>
//           <Table.Header>
//             <Table.Row>
//               <Table.HeaderCell>
//                 <Checkbox
//                   onChange={props.handleSelectAllClick}
//                 />
//               </Table.HeaderCell>
//               <Table.HeaderCell>Name</Table.HeaderCell>
//               <Table.HeaderCell>Key</Table.HeaderCell>
//               <Table.HeaderCell></Table.HeaderCell>
//             </Table.Row>
//           </Table.Header>
//           <Table.Body>
//             {data.map(i => (
//               <Table.Row key={i._id}>
//                 <Table.Cell>
//                   <Checkbox
//                     value={i._id}
//                     checked={props.selected[i._id] || false}
//                     onChange={props.handleSemanticCheckboxClick}
//                   />
//                 </Table.Cell>
//                 <Table.Cell>
//                   {`${i.name}`}
//                 </Table.Cell>
//                 <Table.Cell>
//                   <Label size={'mini'}>{i.key}</Label>
//                 </Table.Cell>
//                 <Table.Cell>
//                   <Link to={'/production/channels/' + i._id}>
//                     <Button size={'mini'}>Edit</Button>
//                   </Link>
//                 </Table.Cell>
//               </Table.Row>
//             ))}
//           </Table.Body>
//         </Table>
//         <ListViewPagination.Bottom
//           label={'Delete'}
//           {...props}
//         />
//       </Segment>
//     </div>
//   );
// }

// export default IndexWrapper(Container, 'productionChannels', {
//   filterMapper,
//   query: {
//     $sort: { name: 1 }
//   }
// });

import * as React from 'react';
import { Popup, Segment, Button } from 'semantic-ui-react';
import { Link, RouteComponentProps } from 'react-router-dom';

import {
  useList,
  ListTable,
  PageHeaderRow,
  EmptyWrapper,
  basicQueryMap,
  PaginatorWrapper,
} from '@inkcloud/components';

interface ListProps {}

const serviceName = 'production/resources';
const pathPrefix = '/settings/production/resources';

const filterQueryMap = (values) => {
  let filterRows = basicQueryMap(values);

  filterRows = {
    ...filterRows,
  };

  return filterRows;
};

export const List: React.FunctionComponent<ListProps & RouteComponentProps<any>> = (props) => {
  const {
    match: { params },
    location,
    history,
  } = props;

  const {
    state,
    reload,
    handleDelete,
    selectedItems,
    clearAll,
    setChecked,
    someSelected,
    toggleAll,
    filter,
    setFilter,
    sort,
    handleSort,
  } = useList({
    feathers: [
      'find',
      serviceName,
      [
        {
          query: {
            $populate: ['vendor'],
          },
        },
      ],
    ],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { name: 1 },
  }) as any;

  const tableDefinition = [
    { key: 'name', label: 'Name', cell: (v, r) => v },
    { key: 'type', label: 'Type', cell: (v, r) => v },
    { key: 'vendor', label: 'Vendor', cell: (v, r) => r.vendor?.companyName ?? '' },
    {
      key: '',
      label: '',
      cell: (v, r) => (
        <Popup
          size="mini"
          content="Edit"
          trigger={
            <Link to={`${pathPrefix}/${r._id}`}>
              <Button icon="edit" size="mini" />
            </Link>
          }
        />
      ),
      textAlign: 'right',
    },
  ];

  const { queryResult, isLoading: isStateLoading } = state as any;

  const resolvedQueryResult = queryResult?.data ? queryResult.data : queryResult;

  return (
    <div>
      <PageHeaderRow header="Resources" subheader="Settings">
        <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
        <Link to={`${pathPrefix}/add`}>
          <Button primary size="mini" content="New" />
        </Link>
      </PageHeaderRow>
      <Segment>
        <EmptyWrapper queryResult={resolvedQueryResult} isStateLoading={isStateLoading}>
          <PaginatorWrapper.Top
            filter={filter}
            reload={reload}
            data={queryResult as any}
            pathPrefix={`${pathPrefix}`}
          />
          <ListTable
            data={resolvedQueryResult}
            definition={tableDefinition}
            selectedItems={selectedItems}
            setChecked={setChecked}
            sort={sort}
            sortClickFunction={handleSort}
            toggleAll={toggleAll}
            withCheckboxes={true}
          />

          <PaginatorWrapper.Bottom
            reload={reload}
            data={queryResult as any}
            pathPrefix={pathPrefix}
          >
            {someSelected && (
              <Button primary type="button" content="Delete" onClick={handleDelete} />
            )}
          </PaginatorWrapper.Bottom>
        </EmptyWrapper>
      </Segment>
    </div>
  );
};
