import * as React from 'react';
import { Modal, Button, Label, Message } from 'semantic-ui-react';

import Feathers from './../../bootstrap/feathers';

interface IVoidModalProps {
  isOpen: boolean;
  setIsInvoiceReload: (v: boolean) => void;
  selectedShipment: any;
  onClose: () => void;
}

const { useState } = React;

export const VoidModal: React.FunctionComponent<IVoidModalProps> = (props) => {
  const { isOpen, onClose, setIsInvoiceReload, selectedShipment } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const humanIds = selectedShipment?.orderItems
    ?.sort(
      (a, b) =>
        a.orderItemId &&
        parseInt(a.orderItemId.humanId.split('-')[1]) -
          parseInt(b.orderItemId.humanId.split('-')[1])
    )
    .map((oi: any, idx: number) => (
      <Label key={idx}>{oi.orderItemId && oi.orderItemId.humanId}</Label>
    ));

  const handleVoid = async () => {
    setIsLoading(true);
    setErrorMessage('');
    setIsInvoiceReload(true);

    try {
      const res = await Feathers.service('/orders/void-shipment').create(
        {
          orderId: selectedShipment?.orderItems?.[0]?.orderItemId?.order,
          actualShipmentId: selectedShipment?._id,
          forceVoid: true,
        },
        { query: {} }
      );
    } catch (e) {
      setErrorMessage(
        e.code && e.code < 500
          ? e.message
          : 'We are experiencing technical difficulties. Please try again'
      );
    }

    setIsLoading(false);
    setIsInvoiceReload(false);
  };

  return (
    <Modal size="small" open={isOpen} onClose={onClose} closeIcon>
      <Modal.Header>Void Shipment</Modal.Header>
      <Modal.Content>
        Are you sure you want to void shipment with items {humanIds}?
        {errorMessage && <Message error content={errorMessage} />}
      </Modal.Content>
      <Modal.Actions>
        <Button
          type="button"
          size="tiny"
          primary
          content="Okay"
          disabled={isLoading}
          loading={isLoading}
          onClick={handleVoid}
        />
        <Button type="button" size="tiny" content="Cancel" onClick={onClose} />
      </Modal.Actions>
    </Modal>
  );
};
