import * as React from 'react';
import { useEffect } from 'react';
import { Modal, Form, Button, Message, Dropdown } from 'semantic-ui-react';
import { FeathersDropdown } from '@inkcloud/components';

import Feathers from '../bootstrap/feathers';

const filterOut = [
  'APPROVAL_PENDING',
  'ARTWORK_EXCEPTION',
  'BACK_ORDER',
  'BACK_ORDER_FULFILLMENT_PENDING',
  'DESIGN_APPROVED',
  'DESIGN_COMPLETE_PENDING_APPROVAL',
  'DESIGN_JOB_APPROVED_BY_CUSTOMER',
  'DESIGN_JOB_PENDING',
  'DESIGN_JOB_PENDING_APPROVED',
  'DESIGN_RENDER_PENDING',
  'DISPATCHED',
  'FILES_PARTIAL_RECEIVED',
  'FILES_RECEIVED',
  'FULFILLMENT_PENDING',
  'PENDING_CUSTOMER_APPROVAL',
  'PICKED_UP',
  'PICKED_UP_PARTIAL',
  // 'READY_FOR_PICKUP',
  'SHIPPED',
  'SHIPPED_COMPLETE',
  'SHIPPED_PARTIAL',
  'STATUS_PENDING_PROOF_APPROVAL',
  'VOID',
];
interface StatusChangerModalProps {
  open: boolean;
  onClose: () => void;
  orderItem: any;
  onReload?: () => void;
}

const { useState } = React;

export const StatusChangerModal: React.FunctionComponent<StatusChangerModalProps> = (props) => {
  const { open, onClose, orderItem, onReload } = props;
  const [status, setStatus] = useState('');
  const [statusOptions, setStatusOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const jobStatus =
    typeof orderItem?.productionStatus.jobStatus === 'string'
      ? orderItem?.productionStatus.jobStatus
      : orderItem?.productionStatus.jobStatus?._id;

  useEffect(() => {
    const loadStatuses = async () => {
      try {
        const res = await Feathers.service('/order-item-statuses').find({
          query: {
            $sort: { name: 1 },
            $np: 1,
          },
        });
        const filteredOptions = res?.reduce((acc, curr) => {
          if (!filterOut.includes(curr._id)) {
            acc.push({ key: curr._id, value: curr._id, text: curr.name });
          }
          return acc;
        }, []);
        setStatusOptions(filteredOptions);
        setStatus(jobStatus);
      } catch (e) {
        console.error(e);
      }
    };

    loadStatuses();
  }, []);

  const handleSubmit = async () => {
    setIsLoading(true);
    setErrorMessage('');
    try {
      await Feathers.service('/order-items/status-changer').update(orderItem._id, { status });
      onReload?.();
      onClose();
    } catch (e) {
      const errMsg = e?.code < 500 ? e.message : 'Something went wrong';

      setErrorMessage(errMsg);
    }
    setIsLoading(false);
  };

  return (
    <Modal open={open} size="small" onClose={onClose} closeIcon>
      <Modal.Header>Status Changer</Modal.Header>
      <Modal.Content>
        <strong>ID: {orderItem.humanId}</strong>
        <Form onSubmit={handleSubmit} loading={isLoading}>
          <Form.Field>
            <Dropdown
              search
              selection
              fluid
              placeholder="Status..."
              options={statusOptions}
              // serviceName="/order-item-statuses"
              // query={{
              //   $sort: { name: 1 },
              //   $np: 1,
              // }}
              // resultMapper={(r) => filterOut.includes(r._id) ? null : ({ key: r._id, value: r._id, text: r.name })}
              value={status}
              onChange={(e, data) => setStatus((data as any).value)}
            />
          </Form.Field>
          {errorMessage && <Message error content={errorMessage} />}
          <Button primary content="Update Order Item Status" />
        </Form>
      </Modal.Content>
    </Modal>
  );
};
