import React, { useEffect, useState, useContext, useRef } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Divider,
  Flex,
  Button,
  Progress,
  Stack,
  Spacer,
  Text,
  Heading,
  Alert,
} from '@chakra-ui/react';

import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import type { ModelTypes } from '@inkcloud/icapi-types';

import BasicInfo from './RFQ2/Manage/BasicInfo';
import BillToShipTo from './RFQ2/Manage/BillToShipTo_';
import ConvertedEntities from './RFQ2/Manage/ConvertedEntities';
import ParticipatingSuppliers from './RFQ2/Manage/ParticipatingSuppliers';

import feathers from '../../bootstrap/feathers';

import CustomerPicker from './common/CustomerPicker';
import EditableBillToShipTo from './common/EditableShipTo';
import Supplier from './common/Supplier';
import EditableInfo from './common/EditableInfo';
import EditableSpec from './common/EditableSpec';

import { fractionToNumber, numberToFraction } from '../../common';

import type { ItemsType } from './Detail';
import type { BasicInfoType, AttributeType } from './common/types';
// import { DashboardContext } from '../../common/hooks/DashboardContext'

interface IWizardFooter {
  prev?: React.ReactNode;
  next?: React.ReactNode;
}

const WizardFooter = (props: IWizardFooter) => (
  <Stack spacing={3} py={3} direction="row">
    {props.prev}
    <Spacer />
    {props.next}
  </Stack>
);

interface ICreateProps extends RouteComponentProps {}

// export enum CreateSteps {
//   SelectCustomer = 0,
//   BillToShipTo = 1,
//   SelectVendor = 2,
//   BuildSpec = 3,
// }

const initCustomerValue = {
  microsite: '',
  user: { label: '', value: '' },
  customerType: 'customer',
};

const initAddressValue = {
  billTo: {
    company: '',
    name: '',
    address1: '',
    address2: '',
    city: '',
    stateProvince: '',
    postalCode: '',
    countryCode: '',
  },
  shipTo: {
    name: '',
    careOf: '',
    address1: '',
    address2: '',
    city: '',
    stateProvince: '',
    postalCode: '',
    countryCode: '',
  },
};

const initVendorList = [];

export const initBasicInfo = {
  status: 'draft',
  projectName: '',
  csr: '',
  requestor: '',
  estimateDueDate: '',
  vendorShipDate: '',
  requestedCustomerShipDate: '',
  paperSuppliedBy: '',
  filesSupplied: '',
  hardProof: true,
  eProof: true,
  allowableUnderrunPercentage: '',
  allowableOverrunPercentage: '',
};

const initItem = [
  {
    sku: '',
    description: '',
    packagingInstructions: '',
    quantities: [],
    components: [{ description: '', notes: '', id: String(Math.floor(Date.now() / 1000)) }],
  },
];

const initTenant = {
  locale: 'en-us',
  currency: 'USD',
  countryCode: 'US',
};

type initBasicType = typeof initBasicInfo;
export type initBasicInfoType = Omit<initBasicType, 'hardProof' | 'eProof'>;
export type initType = typeof initCustomerValue;

export type initVendorType = typeof initVendorList;

type BillToType = NonNullable<ModelTypes.Rfq['billTo']>;
type ShipToType = NonNullable<ModelTypes.Rfq['shipTo']>;

type optionsType = { label: string; value: string }[];

type handlerRef = {
  handleSave: () => void | BasicInfoType;
  handleNext: () => void;
};

const Create: React.FunctionComponent<ICreateProps> = (props) => {
  const { history } = props;
  // const ctx = useContext(DashboardContext)

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const tenant = useSelector(
    ({ globals }: { globals: { tenant: { locale: string; countryCode: string } } }) =>
      globals.tenant
  );

  // const [tenant, setTenant] = useState<{ locale: string; currency: string; countryCode: string }>(initTenant)
  const [stepIndex, setStepIndex] = useState(0);

  const [microsites, setMicrosites] = useState<optionsType>([]);
  const [MSCustomers, setMSCustomers] = useState<optionsType>([]);
  const [customers, setCustomers] = useState<optionsType>([]);
  const [vendors, setVendors] = useState<optionsType>([]);
  const [users, setUsers] = useState<optionsType>([]);

  const [attributes, setAttributes] = useState<AttributeType[]>([]);

  const [customerInfo, setCustomerInfo] = useState<
    ModelTypes.Rfq & initType & { user: { _id?: string } }
  >(initCustomerValue);
  const [basicInfo, setBasicInfo] = useState<BasicInfoType>({});
  const [address, setAddress] = useState<{
    billTo?: BillToType;
    shipTo: ShipToType;
    isValid?: false;
  }>(initAddressValue);

  const [selectedVendors, setSelectedVendors] =
    useState<NonNullable<ModelTypes.Rfq['vendors']>>(initVendorList);

  const [items, setItems] = useState<ItemsType>(initItem);

  const addressRef = useRef<handlerRef>(null);
  const vendorRef = useRef<handlerRef>(null);
  const infoRef = useRef<handlerRef>(null);
  const specRef = useRef<handlerRef>(null);

  // console.log('customer picker customerInfo', customerInfo)
  // console.log('basicInfo', basicInfo)

  const isMicrosite = customerInfo?.customerType === 'microsite';

  // useEffect(() => {

  //   const loadTenantSettings = async () => {
  //     try {
  //       const res = await feathers.service('/tenant-settings').get(ctx.user?.business, {
  //         query: {
  //           $select: ['internationalization'],
  //         }
  //       })
  //       setTenant(res?.internationalization)

  //     } catch (e) {
  //       console.error(e)
  //     }
  //   }

  //   if (ctx.user) {
  //     loadTenantSettings()
  //   }
  // }, [ctx.user])

  useEffect(() => {
    const loadAttributes = async () => {
      try {
        const res = await feathers.service('products-legacy/attributes').find({
          query: {
            $np: 1,
            $sort: { name: 1 },
            $populate: ['entityAttribute'],
          },
        });

        setAttributes(
          res.map((r) => ({
            _id: r._id,
            name: r.name,
            typeId: r.entityAttribute._id,
            typeName: r.entityAttribute.name,
          }))
        );
      } catch (e) {
        console.error(e);
      }
    };

    const loadVendors = async () => {
      try {
        const res = await feathers.service('production/vendors').find({
          query: {
            $np: 1,
            $sort: { companyName: 1 },
          },
        });
        setVendors(res?.map((m) => ({ label: m.companyName, value: m._id })));
      } catch (e) {
        console.error(e);
      }
    };

    const loadUsers = async () => {
      try {
        const res = await feathers.service('users').find({
          query: {
            $np: 1,
            $sort: { firstName: 1 },
            vendor: { $exists: false },
            type: { $ne: 'vendor' },
          },
        });
        setUsers(res.map((m) => ({ label: `${m.firstName} ${m.lastName}`, value: m._id })));

        if (!basicInfo.csr) {
          setBasicInfo((prev) => ({ ...prev, csr: res?.[0]?._id ?? '' }));
        }

        if (!basicInfo.requestor) {
          // setBasicInfo((prev) => ({ ...prev, requestor: res?.[0]?._id ?? '' }))
        }
      } catch (e) {
        console.error(e);
      }
    };

    loadAttributes();
    loadVendors();
    loadUsers();
  }, []);

  useEffect(() => {
    const loadMicrosites = async () => {
      const res = await feathers.service('/microsites').get(customerInfo?.microsite, {
        query: {
          $select: ['_id', 'name'],
          $np: 1,
        },
      });

      if (!res?.length) {
        setCustomerInfo((prev) => ({ ...prev, customerType: 'customer' }));
        return;
      }
      setMicrosites(res?.map((ms) => ({ label: ms.name, value: ms._id })));
    };

    const loadCustomers = async () => {
      try {
        const res = await feathers.service('customers').find({
          query: {
            $np: 1,
            $sort: { organizationName: 1, billingClientName: 1, 'primaryContact.firstName': 1 },
          },
        });
        setCustomers(
          res
            .filter((c) => c?.organizationName)
            .map((c) => {
              let label = `${c?.organizationName ?? ''}`;
              if (c.billingClientName) {
                label = `${label} - ${c.billingClientName ?? ''}`;
                if (c.billingClientId) {
                  label = `${label} (${c.billingClientId ?? ''})`;
                }
              } else if (c?.primaryContact?.firstName && c?.primaryContact?.firstName !== '_') {
                label = `${label} - ${c?.primaryContact?.firstName ?? ''} ${
                  c?.primaryContact?.lastName ?? ''
                }`;
              }

              return {
                ...c,
                label,
                value: c._id,
              };
            })
        );
      } catch (e) {
        console.error(e);
      }
    };

    if (isMicrosite && !microsites?.length) {
      loadMicrosites();
    } else if (!customers?.length) {
      loadCustomers();
    }
  }, [customerInfo?.customerType]);

  useEffect(() => {
    const LoadMSCustomers = async () => {
      try {
        const res = await feathers.service('microsites/customers').find({
          query: {
            microsite: customerInfo?.microsite,
            $np: 1,
            $sort: { firstName: 1 },
          },
        });

        setMSCustomers(
          res?.map((m) => ({
            ...m,
            label: `${m.firstName} ${m.lastName}`,
            value: m._id,
          }))
        );
      } catch (e) {
        console.error(e);
      }
    };

    if (customerInfo?.microsite) {
      LoadMSCustomers();
    }
  }, [customerInfo?.microsite]);

  const handleCustomerInfoChange = (v: any) => {
    setCustomerInfo((prev) => ({ ...prev, ...v }));

    setBasicInfo((prev) => ({ ...prev, customer: v?.user ?? '' }));


    const add = {
      name: v?.user?.organizationName,
      careOf: `${v?.user?.primaryContact?.firstName ?? ''} ${
        v?.user?.primaryContact?.lastName ?? ''
      }`,
      address1: v?.user?.address?.address1 ?? v?.user?.address1,
      address2: v?.user?.address?.address2 ?? v?.user?.address2,
      city: v?.user?.address?.city ?? v?.user?.city,
      stateProvince: v?.user?.address?.stateProvince ?? v?.user?.stateProvince,
      postalCode: v?.user?.address?.postalCode ?? v?.user?.postalCode,
      countryCode: v?.user?.address?.countryCode ?? v?.user?.countryCode,
    };

    if (isMicrosite) {
      const updatedAdd = {
        ...add,
        name: v?.user?.companyName,
        careOf: `${v?.user?.firstName ?? ''} ${v?.user?.lastName ?? ''}`,
      };

      setAddress({
        billTo: updatedAdd,
        shipTo: updatedAdd,
      });
    } else {
      const updatedAdd = {
        ...add,
        name: v?.user?.organizationName,
        careOf: `${v?.user?.primaryContact?.firstName ?? ''} ${
          v?.user?.primaryContact?.lastName ?? ''
        }`,
      };

      setAddress({
        billTo: updatedAdd,
        shipTo: updatedAdd,
      });
    }
  };

  const handleAddressChange = (v: any) => {
    // console.log('v', v)
    setAddress((prev) => ({ ...prev, ...v }));
    setStepIndex(stepIndex + 1);
  };

  const handleVendorChange = (v: any) => setSelectedVendors(v);

  const handleBasicInfoChange = (v: any) => {
    // const step = stepIndex + 1
    setBasicInfo((prev) => ({ ...prev, ...v }));
    // setStepIndex(step)
  };

  const handleItemsChange = (newItems: ItemsType) => setItems(newItems);

  // console.log('items', items)

  const handleSubmit = async ({
    argVendors = [] as NonNullable<ModelTypes.Rfq['vendors']>,
    argBasicInfo = {} as BasicInfoType,
  }) => {
    // const payload = {
    //   ...(customerPicker || {}),
    //   billTo,
    //   shipTo,
    //   items: items.map(i => ({
    //     ...i,
    //     components: i.components.map(c => ({
    //       ...c,
    //       attributes: Object.keys(c.attributes ?? {}).length ? Object.keys(c.attributes ?? {}).map(k => ({
    //         // We cannot pass "OTHER" as value to the backend as it is not a valid ID
    //         type: k, value: c.attributes[k].value === 'OTHER' ? undefined : c.attributes[k].value,
    //         otherValue: c.attributes[k].otherValue
    //       })) : [],
    //     }))
    //   })),
    //   vendors: selectedVendorsList?.map(s => ({
    //     ...s,
    //     vendor: s?.vendor?.value || s?.vendor,
    //     services: s?.services?.map((ss: any) => ss?.value || ss)
    //   })) ?? [],
    //   ...(otherInfo || {}),
    //   status: undefined,
    // }

    // let user;

    // if (isMicrosite) {
    //   user = {
    //     microsite: customerInfo?.microsite,
    //     micrositeUser: customerInfo?.user?._id,
    //   };
    // } else {
    //   user = {
    //     customer: customerInfo?.user?._id,
    //   };
    // }

    setIsSubmitting(true);
    setErrorMessage('');

    try {
      const payload = {
        // ...user,
        customer: customerInfo?.user?._id,
        ...address,
        items:
          items?.map((i) => ({
            ...i,
            components: i?.components?.map((c) => ({
              ...c,
              flatSize: {
                width: c?.flatSize?.width
                  ? fractionToNumber(String(c?.flatSize?.width))
                  : undefined,
                height: c?.flatSize?.height
                  ? fractionToNumber(String(c?.flatSize?.height))
                  : undefined,
              },
              attributes: c?.attributes?.map((a) => ({
                ...a,
                value: a.value === 'OTHER' ? undefined : a.value,
                otherValue: a.value === 'OTHER' ? a.otherValue : undefined,
              })),
            })),
          })) ?? [],
        vendors:
          argVendors?.map((s) => ({
            ...s,
            vendor: s?.vendor,
          })) ?? [],
        ...(basicInfo || {}),
        ...(argBasicInfo || {}),
        status: undefined,
      };

      const res = await feathers.service('/rfqs').create(payload);

      history.push(`/rfqs/${res?._id}`);
    } catch (e) {
      const errMsg = e?.code < 500 ? e?.message : 'Something went wrong. Please try again later.';
      setErrorMessage(errMsg);
    }

    setIsSubmitting(false);
  };

  const progressValue = ((stepIndex + 1) / 5) * 100;

  let wizardTemplate;

  switch (stepIndex) {
    case 0:
      wizardTemplate = (
        <>
          <Heading size="md" mt={8} mb={8}>
            Select a client:
          </Heading>
          <Box width={['100%', '100%', 450]}>
            <CustomerPicker
              microsites={microsites}
              users={isMicrosite ? MSCustomers : customers}
              data={customerInfo}
              onChange={handleCustomerInfoChange}
            />
          </Box>
          <WizardFooter
            next={
              <Button
                colorScheme="blue"
                size={'md'}
                isDisabled={!customerInfo?.user?._id}
                onClick={() => {
                  setStepIndex(stepIndex + 1);
                }}
              >
                Next
              </Button>
            }
          />
        </>
      );

      break;
    case 1:
      wizardTemplate = (
        <>
          <Heading size="md" mt={8} mb={8}>
            Ship To:
          </Heading>

          <EditableBillToShipTo
            ref={addressRef}
            billTo={address.billTo as BillToType}
            shipTo={address.shipTo}
            onChange={handleAddressChange}
          />
          <WizardFooter
            prev={
              <Button
                size={'md'}
                onClick={() => {
                  setStepIndex(stepIndex - 1);
                }}
              >
                Previous
              </Button>
            }
            next={
              <Button
                colorScheme="blue"
                size={'md'}
                onClick={() => {
                  addressRef?.current?.handleSave();
                }}
              >
                Next
              </Button>
            }
          />
        </>
      );
      break;
    case 2:
      wizardTemplate = (
        <>
          <Heading size="md" mt={8} mb={8}>
            RFQ Info:
          </Heading>

          <EditableInfo
            ref={infoRef}
            basicInfo={basicInfo}
            usersOptions={users}
            onNext={(v) => {
              handleBasicInfoChange(v);
              setStepIndex(stepIndex + 1);
            }}
            onChange={handleBasicInfoChange}
            onChangeUserOptions={(v) => setUsers((prev) => [...prev, v])}
          />
          <WizardFooter
            prev={
              <Button
                size={'md'}
                onClick={() => {
                  setStepIndex(stepIndex - 1);
                }}
              >
                Previous
              </Button>
            }
            next={
              <>
                <Button
                  isDisabled={isSubmitting}
                  isLoading={isSubmitting}
                  onClick={() => {
                    const newValues = infoRef?.current?.handleSave();

                    if (newValues) {
                      handleSubmit({ argBasicInfo: newValues as BasicInfoType });
                    }
                  }}
                >
                  Save
                </Button>
                <Button
                  colorScheme="blue"
                  size={'md'}
                  onClick={() => {
                    infoRef?.current?.handleNext();
                    // setStepIndex(stepIndex + 1)
                  }}
                >
                  Next
                </Button>
              </>
            }
          />
        </>
      );
      break;
    case 3:
      wizardTemplate = (
        <>
          <Heading size="md" mt={8} mb={8}>
            RFQ Specs:
          </Heading>
          <EditableSpec
            ref={specRef}
            items={items}
            attributes={attributes}
            onNext={() => setStepIndex(stepIndex + 1)}
            onErrorMessage={(msg) => setErrorMessage(msg)}
            onChange={handleItemsChange}
          />{' '}
          {errorMessage && (
            <Alert status="error" mb={8}>
              {errorMessage}
            </Alert>
          )}
          <WizardFooter
            prev={
              <Button
                size={'md'}
                onClick={() => {
                  setStepIndex(stepIndex - 1);
                }}
              >
                Previous
              </Button>
            }
            next={
              <Button
                colorScheme="blue"
                size={'md'}
                onClick={() => {
                  specRef?.current?.handleSave();
                  // handleSubmit()
                }}
              >
                Next
              </Button>
            }
          />
        </>
      );
      break;

    default:
      wizardTemplate = (
        <>
          <Heading size="md" mt={8} mb={8}>
            Supplier Selection:
          </Heading>

          <Supplier
            ref={vendorRef}
            vendors={vendors}
            selectedVendors={selectedVendors}
            estimateDue={basicInfo?.estimateDueDate}
            onChange={(newValues) => {
              handleSubmit({ argVendors: newValues as NonNullable<ModelTypes.Rfq['vendors']> });
            }}
          />
          {errorMessage && (
            <Alert status="error" mb={8}>
              {errorMessage}
            </Alert>
          )}
          <WizardFooter
            prev={
              <Button
                size={'md'}
                onClick={() => {
                  setStepIndex(stepIndex - 1);
                }}
              >
                Previous
              </Button>
            }
            next={
              <Button
                colorScheme="blue"
                size={'md'}
                onClick={() => {
                  vendorRef?.current?.handleSave();
                }}
              >
                Submit
              </Button>
            }
          />
        </>
      );
      break;
  }

  const locale = tenant.locale?.replace('_', '-');

  return (
    <Flex>
      <Box width={0.7} flex="1" p="6" bg="white">
        <Flex py={3}>
          <Spacer />
          <Box>
            <Text>Step {stepIndex + 1} of 5</Text>
          </Box>
          <Spacer />
        </Flex>
        <Progress size="sm" value={progressValue} mb={4} />
        {wizardTemplate}
        {/* <Stack spacing={3} py={3} direction="row">
          {stepIndex > 0 ? <Button onClick={() => {
            setStepIndex(stepIndex - 1)
          }}>Previous</Button> : null}
          <Spacer />
          {stepIndex < 4 ? <Button

            onClick={() => {
              setStepIndex(stepIndex + 1)
              setTouch(prev => ({ ...prev, [stepIndex + 1]: true }))
            }}>Next</Button> : <Button
              isLoading={isSubmitting}
              isDisabled={isSubmitting}
              onClick={handleSubmit}
            >Submit</Button>}
        </Stack> */}
        {/* <Accordion index={stepIndex}>
          <AccordionItem>
            <h2>
              <AccordionButton onClick={() => setStepIndex(0)}>
                <Box>
                  Select a Customer
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <CustomerPicker customerType={customerType} onChange={setCustomerType} />
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton onClick={() => setStepIndex(1)}>
                <Box flex='1' textAlign='left'>
                  Bill To / Ship To
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
              tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
              veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
              commodo consequat.

              <Box><Button onClick={() => setStepIndex(2)}>Next</Button></Box>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton onClick={() => setStepIndex(2)}>
                <Box flex='1' textAlign='left'>
                  Choose Vendors
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
              tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
              veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
              commodo consequat.
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton onClick={() => setStepIndex(3)}>
                <Box flex='1' textAlign='left'>
                  Build Specs
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
              tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
              veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
              commodo consequat.
            </AccordionPanel>
          </AccordionItem>

        </Accordion> */}
      </Box>
      <Box width={0.3} p="6" bg="white">
        <BasicInfo
          title="Info"
          subTitle="RFQ Specifications"
          hideShipTo={true}
          isReadOnly={false}
          shipToEditClick={() => null}
          // shipTo={{ shipTo: address.shipTo as ModelTypes.Rfq['shipTo'] }}
          basicInfo={{
            ...basicInfo,
            csr: users.find((u) => u.value === basicInfo.csr)?.label ?? '',
            requestor: users.find((u) => u.value === basicInfo.requestor)?.label ?? '',
            assignedTo: users.find((u) => u.value === basicInfo.assignedTo)?.label ?? '',
            estimateDueDate: basicInfo.estimateDueDate,
            vendorShipDate:
              basicInfo.vendorShipDate &&
              new Intl.DateTimeFormat(locale).format(new Date(basicInfo.vendorShipDate)),
            requestedCustomerShipDate:
              basicInfo.requestedCustomerShipDate &&
              new Intl.DateTimeFormat(locale).format(new Date(basicInfo.requestedCustomerShipDate)),
          }}
        />

        <BillToShipTo billTo={address.billTo as BillToType} shipTo={address.shipTo} />
        <Divider mt="6" />

        {/* <ParticipatingSuppliers
          vendors={vendors.filter((v) => selectedVendors?.some((s) => s.vendor === v.value))}
          selectedVendors={selectedVendors}
        /> */}
      </Box>
    </Flex>
  );
};

export default Create;
