import * as React from 'react';
import { Input, Form, Button, Message } from 'semantic-ui-react';
import Feathers from './../../../bootstrap/feathers';

interface POProps {
  cart: any;
}

const { useState, useEffect } = React;

export const PO: React.FunctionComponent<POProps> = (props) => {
  const { cart } = props;
  const [po, setPo] = useState((cart && cart.po) || '');
  const [errorMessage, setErrorMessage] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async () => {
    try {
      setResponseMessage('');
      setIsSubmitting(true);
      const res: any = await Feathers.service('/cart/set-po').create(
        { cartId: cart._id, po },
        { query: {} }
      );
      setIsSubmitting(false);
      setResponseMessage('Successfully applied the PO.');
    } catch (e) {
      setResponseMessage('');
      setIsSubmitting(false);
      e.code && e.code < 500
        ? setErrorMessage(e.message)
        : setErrorMessage('We are experiencing technical difficulties. Please try again');
    }
  };

  return (
    <>
      <Form loading={isSubmitting}>
        <Form.Input name="po" value={po} onChange={(e, data) => setPo(data.value)} />
        <Form.Button primary size="mini" disabled={!po} onClick={handleSubmit} content="Submit" />
      </Form>
      {errorMessage && <Message size="tiny" error content={errorMessage} />}
      {responseMessage && <Message size="tiny" success content={responseMessage} />}
    </>
  );
};
