import * as React from 'react';
import {
  Accordion,
  Button,
  Checkbox,
  Header,
  Grid,
  Label,
  Segment,
  Table,
} from 'semantic-ui-react';
import EstimateForm from './EstimateForm';

export interface EditProps {}

export default class Edit extends React.PureComponent<EditProps, any> {
  render() {
    return (
      <div>
        <Header as="h1">Estimates</Header>
        <EstimateForm />
      </div>
    );
  }
}
