import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Segment, Table, Message } from 'semantic-ui-react';
import { PageHeaderRow, PreLoader, useFeathers } from '@inkcloud/components';
import feathers from '../../../bootstrap/feathers';
import { BannerForm } from './BannerForm';

interface DetailProps {}

const serviceName = '/store-front/microsites/content/banners';
const service = feathers.service(serviceName);

const { useState } = React;

export const Detail: React.FunctionComponent<
  DetailProps & RouteComponentProps<{ id?: string; micrositeId?: string }>
> = (props) => {
  const {
    match: {
      params: { micrositeId, id },
    },
    history,
  } = props;

  const commonParams = {
    query: {
      microsite: micrositeId,
    },
  };

  const [errorMessage, setErrorMessage] = useState('');

  const isEdit = !!id;
  const [state, reload] = useFeathers('get', serviceName, [id, commonParams], true);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const pathPrefix = `/settings/microsites/${micrositeId}/content/banners`;

  const handleSubmit = async (values: any) => {
    const payload = {
      ...values,
      microsite: micrositeId,
    };

    setIsSubmitting(true);
    try {
      setErrorMessage('');
      isEdit
        ? await service.patch(id, payload, commonParams)
        : await service.create(payload, { query: {} });
      history.push(pathPrefix);
    } catch (e) {
      setIsSubmitting(false);
      e.code && e.code < 500
        ? setErrorMessage(e.message)
        : setErrorMessage('We are experiencing technical difficulties. Please try again');
    }
  };

  const pageTitle = isEdit ? 'Edit' : 'New';

  if (isEdit && !state.data) {
    return <PreLoader />;
  }

  const initialData = { ...state.data, isPublished: isEdit ? state.data.isPublished : true };
  return (
    <div>
      <PageHeaderRow header={`${pageTitle} Banner`} subheader="Microsite">
        <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
      </PageHeaderRow>

      <Segment>
        {(!isEdit || state.data) && (
          <BannerForm
            errorMessage={errorMessage}
            initialValues={initialData}
            isSubmitting={isSubmitting}
            micrositeId={micrositeId}
            onSubmit={handleSubmit}
          />
        )}
        {errorMessage && <Message error content={errorMessage} />}
      </Segment>
    </div>
  );
};
