import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Header, Segment, Divider, Button, Form, Dropdown, Message } from 'semantic-ui-react';
import { services } from '../../../bootstrap/feathers';
import { ShippingAddress } from '../../../types';
import MainForm from './MainForm';
import { PageHeaderRow } from '@inkcloud/components';

export interface IndexComponentProps {
  history: any;
  tenant?: string;
  get?: Function;
  patch?: Function;
  settings?: {
    data: {
      isWillCallEnabled: boolean;
      isAirCargoEnabled: boolean;
      isShippingCombined: boolean;
      isAirCargoCombined: boolean;
      airCargoMaxWeightPerBox: Number;
      shippingMaxWeightPerBox: Number;
      combineThreshold: Number;
      isShipFromAddressEntered: boolean;
      shipFromAddress: ShippingAddress;
      isPickupLocationsEnabled: boolean;
      shippingStrategy: {
        ups: any;
        fedex: any;
      };
    };
  };
}

export interface IndexComponentState {
  updatedSuccessful: boolean;
  isLoading: boolean;
  erroMessage: string;
}

class IndexComponent extends React.Component<
  IndexComponentProps,
  IndexComponentState,
  RouteComponentProps
> {
  constructor(props: IndexComponentProps) {
    super(props);
    this.state = {
      updatedSuccessful: false,
      isLoading: false,
      erroMessage: '',
    };
  }

  componentDidMount() {
    this.props.get(this.props.tenant).then((result) => {});
  }

  handleSubmit = async (values) => {
    this.setState({ ...this.state, isLoading: true, erroMessage: '' });
    try {
      const payload = values;

      const res = await this.props.patch(this.props.tenant, payload);

      this.setState({ ...this.state, updatedSuccessful: true, isLoading: false, erroMessage: '' });
    } catch (e) {
      const message =
        e.code && e.code < 500
          ? e.message
          : 'We are experiencing technical difficulties. Please try again';
      this.setState({ ...this.state, isLoading: false, erroMessage: message });
    }
  };

  render() {
    if (!this.props.settings || !this.props.settings.data) {
      return null;
    }

    return (
      <div>
        <PageHeaderRow header="Shipping Carriers" subheader="Settings">
          <Button content="Back" size={'mini'} onClick={() => this.props.history.goBack()} />
        </PageHeaderRow>
        <Segment loading={this.state.isLoading}>
          {this.state.updatedSuccessful && (
            <Message positive>
              <Message.Header>Update Successful</Message.Header>
              <p>Your settings have been successfully updated</p>
            </Message>
          )}
          {this.state.erroMessage && <Message error content={this.state.erroMessage} />}
          <MainForm
            form="settingsMainForm"
            initialValues={this.props.settings.data}
            onSubmit={this.handleSubmit}
          />
          {this.state.updatedSuccessful && (
            <Message positive>
              <Message.Header>Update Successful</Message.Header>
              <p>Your settings have been successfully updated</p>
            </Message>
          )}
          {this.state.erroMessage && <Message error content={this.state.erroMessage} />}
        </Segment>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  settings: state.tenantSettingsShipping,
  tenant: state.auth.user.business,
});

const mapDispatchToProps = {
  get: services.tenantSettingsShipping.get,
  patch: services.tenantSettingsShipping.patch,
};

export default connect(mapStateToProps, mapDispatchToProps)(IndexComponent);
