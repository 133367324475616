/* Define all Ink Cloud services here for easy use */
import app, { services } from '../feathers';
import { extendWithFilter } from './extendWithFilter';

export const inkcloudReducers = {
  // General Ink Cloud services below
  'addressBook': extendWithFilter('addressBook', services),
  'customers': extendWithFilter('customers', services),
  'users': extendWithFilter('users', services),
  'translators': extendWithFilter('translators', services),
  'translatorsAssignOrderItem': extendWithFilter('translatorsAssignOrderItem', services),

  'paymentsGatewayCreateCard': services.paymentsGatewayCreateCard.reducer,

  'customersNotes': services.customersNotes.reducer,
  'ordersNotes': services.ordersNotes.reducer,

  'tenants/portals': extendWithFilter('tenants/portals', services),

  'microsites': extendWithFilter('microsites', services),
  'micrositeProducts': extendWithFilter('micrositeProducts', services),
  'micrositeCustomers': extendWithFilter('micrositeCustomers', services),
  'micrositeAddressBook': extendWithFilter('micrositeAddressBook', services),
  'micrositeGroups': extendWithFilter('micrositeGroups', services),

  'notificationLog': extendWithFilter('notificationLog', services),



  'designTemplates': extendWithFilter('designTemplates', services),
  'designTemplateFonts': extendWithFilter('designTemplateFonts', services),


  'contentPages': extendWithFilter('contentPages', services),
  'micrositeContentPages': extendWithFilter('micrositeContentPages', services),
  'micrositeContentMenus': extendWithFilter('micrositeContentMenus', services),


  'inventoryLedger': extendWithFilter('inventoryLedger', services),
  'inventoryLocations': extendWithFilter('inventoryLocations', services),
  'inventoryMovements': extendWithFilter('inventoryMovements', services),
  'inventoryOperations': extendWithFilter('inventoryOperations', services),

  'incomingTickets': extendWithFilter('incomingTickets', services),

  'estimates': extendWithFilter('estimates', services),
  'invoices': extendWithFilter('invoices', services),
  'orders': extendWithFilter('orders', services),
  'orderItems': extendWithFilter('orderItems', services),
  'printableJobTicket': extendWithFilter('printableJobTicket', services),

  'productionBatches': extendWithFilter('productionBatches', services),
  'productionOutsource': extendWithFilter('productionOutsource', services),
  'productionVendors': extendWithFilter('productionVendors', services),
  'productionBatchesStatus': extendWithFilter('productionBatchesStatus', services),
  'productionChannels': extendWithFilter('productionChannels', services),

  'productionResources': extendWithFilter('productionResources', services),
  'productionPendingOrderItems': extendWithFilter('productionPendingOrderItems', services),


  'products': extendWithFilter('products', services),
  'productsAttributes': extendWithFilter('productsAttributes', services),
  'productsAttributeTypes': extendWithFilter('productsAttributeTypes', services),
  'productsCategories': extendWithFilter('productsCategories', services),
  'productsVariantTypes': extendWithFilter('productsVariantTypes', services),
  'productsVariantValues': extendWithFilter('productsVariantValues', services),


  'paymentGatewayDeleteCard': extendWithFilter('paymentGatewayDeleteCard', services),

  'salesTaxExemptionReview': extendWithFilter('salesTaxExemptionReview', services),


  // 'products-legacy': extendWithFilter('products-legacy', services),
  // 'products-legacy/attributes': extendWithFilter('products-legacy/attributes', services),
  // 'products-legacy/attribute-types': extendWithFilter('products-legacy/attribute-types', services),
  // 'products-legacy/categories': extendWithFilter('products-legacy/categories', services),
  'products-legacy/portal-products': extendWithFilter('products-legacy/portal-products', services),
  'salesPeople': extendWithFilter('salesPeople', services),
  'tenantSettingsCalculator': services.tenantSettingsCalculator.reducer,
  'tenantSettingsContactInfo': services.tenantSettingsContactInfo.reducer,
  'tenantSettingsPayment': services.tenantSettingsPayment.reducer,
  'tenantSettingsShipping': services.tenantSettingsShipping.reducer,
  'tenantSettingsTax': services.tenantSettingsTax.reducer,

  'xmpie/stores': extendWithFilter('xmpie/stores', services),
  'xmpie/products': extendWithFilter('xmpie/products', services),

}
