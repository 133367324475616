import * as React from 'react';
import { Table, Button } from 'semantic-ui-react';

import { humanize } from 'underscore.string';

import { EmptyWrapper } from '@inkcloud/components';

interface SelectedCriteriasProps {
  criterias: any[];
  setFieldValue: (fieldName: string, value: string[]) => void;
}

export const SelectedCriterias: React.FunctionComponent<SelectedCriteriasProps> = (props) => {
  const { setFieldValue } = props;

  const handleRemove = (index: number) => {
    const filtered = props.criterias?.filter((c, idx) => index !== idx);
    setFieldValue('criteria', filtered);
  };

  return (
    <>
      <EmptyWrapper queryResult={props.criterias} isStateLoading={false}>
        <Table size="small">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Criteria</Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {props.criterias?.map((criteria, index) => (
              <Table.Row key={index}>
                <Table.Cell>
                  {humanize(criteria?.selectedFact?.replace(/attributes:/g, '') ?? '')} -{' '}
                  {Array.isArray(criteria?.value)
                    ? criteria?.value.map((v) => v.label).join(', ')
                    : criteria?.value}
                </Table.Cell>
                <Table.Cell textAlign="right">
                  <Button
                    type="button"
                    size="tiny"
                    icon="close"
                    onClick={() => handleRemove(index)}
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </EmptyWrapper>
    </>
  );
};
