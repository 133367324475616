import * as React from 'react';
import { Modal, ModalProps } from 'semantic-ui-react';
import { InviteForm } from './InviteForm';

export interface InviteModalProps {
  handleSubmit: Function;
  handleClose: ModalProps['onClose'];
  open: boolean;
}

export class InviteModal extends React.Component<InviteModalProps> {
  public render() {
    const { props } = this;
    return (
      <Modal size={'mini'} open={props.open} onClose={props.handleClose}>
        <Modal.Header>Customer Invite</Modal.Header>
        <Modal.Content>
          <InviteForm
            initialValues={{ amount: 0 }}
            handleSubmit={props.handleSubmit}
            handleCloseClick={this.props.handleClose}
          />
        </Modal.Content>
        <Modal.Actions></Modal.Actions>
      </Modal>
    );
  }
}
