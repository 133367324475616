// import * as React from 'react';
// import {InjectedFormProps, Field, reduxForm, formValueSelector, FormProps} from 'redux-form';
// import {Button, Form} from 'semantic-ui-react';
// import {semanticFormField, semanticFormToggleField, semanticFormDropdownField, semanticFormDatePickerField} from './../../../common';
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';

// export function FilterForm (props: InjectedFormProps) {
//   const {handleSubmit, pristine, reset, submitting} = props;
//   return (
//       <Form size={'tiny'} onSubmit={handleSubmit}>
//           <Form.Group inline >
//           <Field
//             key={'start'}
//             name='start'
//             component={semanticFormDatePickerField}
//             as={DatePicker}
//             className='ui fluid'
//             inline={false}
//             placeholderText='Due Date...'
//             />
//             <Form.Button inline size={'tiny'}  disabled={ pristine || submitting }>Filter</Form.Button>
//             <Form.Button inline size={'tiny'} type={'button'} disabled={!props.isFiltered}  onClick={() => props.reset() && props.handleClearFilter()}
//             >Clear Filter</Form.Button>
//           </Form.Group>
//       </Form>
//     );
// }

// export default reduxForm({
//   form: 'productionDueDateFilter'
// })(FilterForm);

import * as React from 'react';
import { Button, Segment, Form } from 'semantic-ui-react';

import DatePicker from 'react-datepicker';

export interface FilterFormProps {
  value: any;
  onChange: Function;
  onReset: Function;
}

function initMapper(value) {
  const defaults = {
    start: '',
  };

  return Object.keys(defaults).reduce((p, cur) => {
    if (!p[cur]) {
      p[cur] = defaults[cur];
    }
    return p;
  }, value);
}

export function FilterForm(props: FilterFormProps) {
  const { value: preInitValue } = props;

  const value = initMapper(preInitValue);

  const onChange = (field, value) => {
    props.onChange && props.onChange({ ...props.value, [field]: value });
  };

  return (
    <Segment>
      <Form size={'small'}>
        <Form.Group widths={'equal'}>
          <Form.Field>
            <DatePicker
              dateFormat="MM/dd/yyyy"
              selected={value.start}
              selectsStart
              placeholderText="Due Date"
              startDate={value.start}
              onChange={(date) => onChange('start', date)}
            />
          </Form.Field>
        </Form.Group>

        <Form.Field>
          <Button size={'tiny'} content="Reset Form" onClick={() => props.onReset()} />
        </Form.Field>
      </Form>
    </Segment>
  );
}
