import * as React from 'react';
import { Button, Input, Dropdown, Segment, Form } from 'semantic-ui-react';

const { useState, useEffect } = React;

export interface FilterFormProps {
  value: any;
  onChange: Function;
  onReset: Function;
}

function initMapper(value) {
  const defaults = {
    name: '',
  };

  return Object.keys(defaults).reduce((p, cur) => {
    if (!p[cur]) {
      p[cur] = defaults[cur];
    }
    return p;
  }, value);
}

export function FilterForm(props: FilterFormProps) {
  const { value: preInitValue } = props;

  const value = initMapper(preInitValue);

  const onChange = (field, value) => {
    props.onChange && props.onChange({ ...props.value, [field]: value });
  };

  return (
    <Segment>
      <Form size={'small'}>
        <Form.Group widths={'equal'}>
          <Form.Field>
            <Input
              placeholder="Name..."
              value={value.name}
              onChange={(e, data) => onChange('name', data.value)}
            />
          </Form.Field>
        </Form.Group>
        <Form.Field>
          <Button size={'tiny'} content="Reset Form" onClick={() => props.onReset()} />
        </Form.Field>
      </Form>
    </Segment>
  );
}
