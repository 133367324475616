import * as React from 'react';
import {
  Label,
  Button,
  Card,
  Header,
  Message,
  Segment,
  Image,
  Grid,
  Icon,
  Progress,
  Loader,
  Dimmer,
  Modal,
  Table,
} from 'semantic-ui-react';
import { FormattedDate, FormattedTime } from 'react-intl';
import Feathers from './../bootstrap/feathers';

// const preflightRejectAssets = 'https://dal05.objectstorage.softlayer.net/v1/AUTH_bdf10df7-94a2-42e6-9b4f-0c3020705c07';

const preflightRejectAssets = 'https://ic-thumbs-inkcloud.netdna-ssl.com';

// const thumbBaseUrl = 'https://ic-thumbs-inkcloud.netdna-ssl.com/ic-artwork-thumbs/reg/';

export interface InjectedProps {
  preflightHandleOpen: any;
}

export interface ExternalProps {}

export interface PreflightModalState {
  isModalOpen: boolean;
  orderItem: any;
  isLoading: boolean;
  preflights: any[];
}

export enum PrintTypes {
  JobTicketSingle,
  JobTicketFull,
  OrderReceipt,
  Invoice,
}
export const preflightModalWrapper = function PreflightModalWrapper<OriginalProps>(Component) {
  return class PreflightModal extends React.Component<
    ExternalProps & OriginalProps,
    PreflightModalState
  > {
    constructor(props: OriginalProps) {
      super(props);

      this.state = {
        isModalOpen: false,
        orderItem: null,
        isLoading: false,
        preflights: [],
      };
    }

    preflightHandleOpen = (orderItem) => {
      this.setState({ ...this.state, isModalOpen: true, isLoading: true, orderItem });

      Feathers.service(`/pre-flights`)
        .find({
          query: {
            orderItem: orderItem._id,
            $populate: ['orderItem'],
          },
        })
        .then((result: any) => {
          this.setState({
            ...this.state,
            isModalOpen: true,
            isLoading: false,
            preflights: result.data,
          });
        });
    };

    handleClose = (e, data) => {
      this.setState({ ...this.state, isModalOpen: false, orderItem: null, isLoading: false });
    };

    handleDownloadClick = (e, data) => {
      Feathers.service('/pre-flights/download-artwork')
        .create({
          preFlightId: data.preFlightId,
        })
        .then((result: any) => {
          // Show download link
          // this.setState({ ...this.state, isModalOpen: true, isLoading: false, preflights: result.data });
        });
    };

    handlePrintAsIs = (e, data) => {
      Feathers.service('/pre-flights/print-as-is')
        .create({
          preFlightId: data.preFlightId,
        })
        .then((result: any) => {
          this.setState({
            ...this.state,
            isModalOpen: false,
            orderItem: null,
            preflights: null,
            isLoading: false,
          });
        });
    };

    render() {
      return (
        <div>
          <Component preflightHandleOpen={this.preflightHandleOpen} {...this.props} />
          <Modal open={this.state.isModalOpen} onClose={this.handleClose} closeIcon>
            <Modal.Header>Preflight Management</Modal.Header>
            <Modal.Content scrolling>
              {this.state.isLoading && (
                <Segment>
                  <Dimmer active>
                    <Loader />
                  </Dimmer>
                </Segment>
              )}
              {!this.state.isLoading && (
                <div>
                  <Message size={'small'}>
                    Remember when using the "Print As-is" function that you are able to send any
                    version of the artwork to print. Usually you want to send the most recent one to
                    print but previous versions are available to you just in case you need it.
                  </Message>
                  <Table size={'small'}>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Created</Table.HeaderCell>
                        <Table.HeaderCell>Checked</Table.HeaderCell>
                        <Table.HeaderCell>Details</Table.HeaderCell>
                        <Table.HeaderCell>Status</Table.HeaderCell>
                        <Table.HeaderCell>Result</Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {this.state.preflights?.map((pf) => {
                        let details = null;
                        if (pf.result === 'rejected') {
                          const detailsRows =
                            pf.slots?.map((s) => {
                              <Table.Row>
                                <Table.Cell>{s.sequence}</Table.Cell>
                                <Table.Cell>
                                  {s.message}
                                  {s.messageImages &&
                                    s.messageImages.map((mi) => {
                                      <Image
                                        fluid
                                        src={`${preflightRejectAssets}/preflight-reject-assets/${mi.url}`}
                                      />;
                                    })}
                                </Table.Cell>
                                <Table.Cell>{s.sequence}</Table.Cell>
                              </Table.Row>;
                            }) ?? null;

                          details = (
                            <Table>
                              <Table.Body>{detailsRows}</Table.Body>
                            </Table>
                          );
                        }

                        return (
                          <Table.Row key={pf._id}>
                            <Table.Cell>
                              <FormattedDate value={pf.createdAt} />{' '}
                              <FormattedTime value={pf.createdAt} />
                            </Table.Cell>
                            <Table.Cell>
                              {pf.resultAt && (
                                <div>
                                  <FormattedDate value={pf.resultAt} />{' '}
                                  <FormattedTime value={pf.resultAt} />
                                </div>
                              )}
                            </Table.Cell>
                            <Table.Cell>{details}</Table.Cell>
                            <Table.Cell>{pf.status}</Table.Cell>
                            <Table.Cell>{pf.result}</Table.Cell>
                            <Table.Cell>
                              <Button size={'tiny'} onClick={this.handleDownloadClick}>
                                {' '}
                                Artwork
                              </Button>
                              {pf.orderItem.productionStatus.jobStatus === 'ARTWORK_EXCEPTION' && (
                                <Button size={'tiny'} onClick={this.handlePrintAsIs}>
                                  {' '}
                                  Print As-is
                                </Button>
                              )}
                            </Table.Cell>
                          </Table.Row>
                        );
                      }) ?? null}
                    </Table.Body>
                  </Table>
                </div>
              )}
            </Modal.Content>
          </Modal>
        </div>
      );
    }
  };
};
