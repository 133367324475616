import * as React from 'react';
import { FormattedDate, FormattedTime } from 'react-intl';
import {
  Checkbox,
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Tooltip,
} from '@chakra-ui/react';
import { ModelTypes } from '@inkcloud/icapi-types';

interface IBackorderTableProps {
  selected: any;
  setSelected: (v: any) => void;
  orderItems: Pick<ModelTypes.OrderItems, '_id' | 'humanId' | 'createdAt' | 'quantity'>[];
}

const BackorderTable: React.FunctionComponent<IBackorderTableProps> = (props) => {
  const { selected, setSelected, orderItems } = props;

  return (
    <TableContainer maxHeight={350} overflowY={'scroll'}>
      <Table>
        <Thead>
          <Tr>
            <Th></Th>
            <Th>Order Item #</Th>
            <Th isNumeric>Date</Th>
            <Th isNumeric>Quantity</Th>
          </Tr>
        </Thead>
        <Tbody>
          {orderItems.map((item: ModelTypes.OrderItems) => (
            <Tr key={item._id}>
              <Td>
                <Checkbox
                  checked={selected[item._id as string]}
                  onChange={(e) => {
                    setSelected({
                      ...selected,
                      [item._id as string]: !selected[item._id as string],
                    });
                  }}
                />
              </Td>
              <Td>{item.humanId}</Td>
              <Td>
                <Tooltip label={<FormattedTime value={item.createdAt} />}>
                  <span>
                    <FormattedDate value={item.createdAt} />
                  </span>
                </Tooltip>
              </Td>
              {/* <FormattedTime value={r.createdAt} /> */}
              <Td>{item.quantity}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default BackorderTable;
