/* eslint-disable no-nested-ternary */
/* eslint-disable no-undef */
import * as React from 'react';
// import { Checkbox, Header, Input, Label, Segment } from 'semantic-ui-react';
import { Table, Thead, Tbody, Tfoot, Tr, Th, Td, Box, Heading, Checkbox } from '@chakra-ui/react';
import { FormattedDate } from 'react-intl';
import * as numeral from 'numeral';
import { Link } from 'react-router-dom';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import { useSelector } from 'react-redux';

import { currencySymbol, InputWrapper } from '../../common';
import { InvoiceType } from './Container';

export interface InvoiceListProps {
  invoices: InvoiceType;
  onInvoiceChange: (invoices: InvoiceType) => void;
  onAmountChange: (amount: number) => void;
}

export function InvoiceList(props: InvoiceListProps) {
  const { invoices, onInvoiceChange, onAmountChange } = props;

  const tenant = useSelector(
    ({
      globals,
    }: {
      globals: { tenant: { currency: string; locale: string; countryCode: string } };
    }) => globals.tenant
  );

  const numberMask = createNumberMask({
    prefix: currencySymbol(tenant?.currency, tenant?.countryCode),
    allowDecimal: true,
  });

  const handleToggleAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    const updatedInvoices = invoices?.map((i) => ({ ...i, amount: checked ? i.openBalance : 0 }));
    onInvoiceChange(updatedInvoices);
  };

  const handleToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = e.target;

    const updatedInvoices = invoices?.map((i) =>
      i._id === id ? { ...i, amount: checked ? i.openBalance : 0 } : i
    );
    onInvoiceChange(updatedInvoices);

    const updatedAmount = updatedInvoices.reduce(
      (prev, cur) => prev + Number(cur?.amount ?? 0),
      0.0
    );

    onAmountChange(updatedAmount);
  };

  const handleAmountChange = (
    e: React.ChangeEvent<HTMLInputElement & { id: string; value: string }>
  ) => {
    const { id: invoiceId, value } = e.target;
    const updatedInvoices = invoices.map((i) => {
      if (i._id !== invoiceId) {
        return i;
      }

      let newValue = Number(value?.replace('$', '').replace(/,+/g, ''));

      if (newValue > i.openBalance) {
        newValue = i.openBalance;
      }

      return { ...i, amount: newValue };
    });

    const updatedAmount = updatedInvoices.reduce(
      (prev, cur) => prev + Number(cur?.amount ?? 0),
      0.0
    );

    onInvoiceChange(updatedInvoices);

    onAmountChange(updatedAmount);
  };

  const invoicesBody = invoices?.map((i, index) => (
    <Tr key={i._id}>
      <Td>
        <Checkbox
          isChecked={!!i.amount}
          id={i._id}
          onChange={handleToggle}
          isDisabled={!i.openBalance}
        />
      </Td>
      <Td>{i.order && <Link to={`/orders/${i.order?._id}`}>{i.order?.humanId}</Link>}</Td>
      <Td>
        <FormattedDate value={i.createdAt} />
      </Td>
      <Td isNumeric>{numeral(i.total).format('$0,0.00')}</Td>
      <Td isNumeric>{numeral(i.openBalance).format('$0,0.00')}</Td>
      <Td isNumeric>
        {i?.amount ? (
          i.openBalance < 0 ? (
            numeral(i.openBalance).format('$0,0.00')
          ) : (
            <InputWrapper>
              <MaskedInput
                className="form-masked"
                style={{ textAlign: 'right' }}
                mask={numberMask}
                value={i.amount}
                id={i._id}
                disabled={i.openBalance < 0}
                onChange={handleAmountChange}
              />
            </InputWrapper>
          )
        ) : (
          <Box minH="28px"></Box>
        )}
      </Td>
    </Tr>
  ));

  const filteredInvoices = invoices?.filter((i) => i.openBalance);
  const areAllSelected = filteredInvoices?.length > 0 && filteredInvoices.every((i) => i.amount);

  return (
    <>
      <Heading as="h4" size="md" mb={2} color="gray.600">
        Open Invoices
      </Heading>
      <Box borderRadius="md" borderWidth={1} p={3} bg="white">
        <Table size="sm">
          <Thead>
            <Tr>
              <Th>
                <Checkbox isChecked={areAllSelected} onChange={handleToggleAll} />
              </Th>
              <Th>ID</Th>
              <Th>Date</Th>
              <Th isNumeric>Total</Th>
              <Th isNumeric>Open Balance</Th>
              <Th isNumeric>Applied Amount</Th>
            </Tr>
          </Thead>
          <Tbody>{invoicesBody}</Tbody>
        </Table>
      </Box>
    </>
  );
}
