import * as React from 'react';
import { Button } from 'semantic-ui-react';
import { Feathers } from '../../bootstrap/feathers/feathers';

interface IQBInstallButtonProps {}

const QBInstallButton: React.FunctionComponent<IQBInstallButtonProps> = (props) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [intallUrl, setInstallUrl] = React.useState<string | null>(null);

  const handleInstall = async () => {
    setIsLoading(true);
    const result = await Feathers.service('integrations/qb-online/auth-url').create({
      r: window.location.href,
    });

    const { authUri } = result;
    setInstallUrl(authUri);
    setIsLoading(false);
  };

  return (
    <div>
      <Button loading={isLoading} content="Install QuickBooks Online" onClick={handleInstall} />

      {intallUrl && (
        <div>
          Click the following link to continue installation: <br /> <br />
          <a style={{ background: 'yellow' }} href={intallUrl}>
            {intallUrl}
          </a>
        </div>
      )}
    </div>
  );
};

export default QBInstallButton;
