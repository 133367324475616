import * as React from 'react';
import Feathers from '../../../bootstrap/feathers';
import { Segment, Label, Form, Radio, Message } from 'semantic-ui-react';
export interface PickupLocationProps {
  id: string;
  initLocation: string;
  micrositeCustomer?: any;
}

const { useState, useEffect } = React;
export const PickupLocation: React.FunctionComponent<PickupLocationProps> = (props) => {
  const { micrositeCustomer } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [locations, setLocations] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  const [selectedLocation, setSelectedLocation] = useState(props.initLocation);

  useEffect(() => {
    const loadLocations = async () => {
      try {
        let res: any;
        setErrorMessage('');
        if (micrositeCustomer) {
          res = await Feathers.service('/pickup-locations').find({
            query: { $np: 1, microsite: micrositeCustomer.microsite._id },
          });
        } else {
          res = await Feathers.service('/pickup-locations').find({ query: { $np: 1 } });
        }

        setLocations(res);
      } catch (e) {
        e.code && e.code < 500
          ? setErrorMessage(e.message)
          : setErrorMessage('We are experiencing technical difficulties. Please try again');
      }
    };

    loadLocations();
  }, [micrositeCustomer]);

  const handleChange = async (id: string) => {
    setSelectedLocation(id);

    try {
      setErrorMessage('');
      setIsLoading(true);
      const res: any = await Feathers.service('/cart/set-pickup-location').create({
        cartId: props.id,
        pickupLocationId: id,
      });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      e.code && e.code < 500
        ? setErrorMessage(e.message)
        : setErrorMessage('We are experiencing technical difficulties. Please try again');
    }
  };

  return (
    <>
      {errorMessage && <Message error content={errorMessage} />}
      {locations && locations.length > 1 && (
        <Segment>
          <Label attached="top">Pick-up Locations</Label>
          {}
          <Form loading={isLoading && !errorMessage ? true : false}>
            {locations.map((l: any, index: number) => (
              <Form.Field key={index}>
                <Radio
                  label={
                    <label>
                      <div>{l.name}</div>
                      {l.address1},{l.address2 ? ` ${l.address2}, ` : ' '}
                      {l.city} {l.stateProvince}, {l.postalCode}
                    </label>
                  }
                  name="locations"
                  value={l._id}
                  checked={selectedLocation === l._id}
                  onChange={() => handleChange(l._id)}
                />
              </Form.Field>
            ))}
          </Form>
        </Segment>
      )}
    </>
  );
};
