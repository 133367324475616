import * as React from 'react';
import { Button, Dimmer, Loader, Header, Grid, Message, Menu, Segment } from 'semantic-ui-react';
import { RouteComponentProps } from 'react-router-dom';
import { DetailWrapper, DetailFeaturesProps } from './../../../bootstrap/DetailWrapper';
import OrderInfoTable from './../../orders/OrderInfoTable';
// import { NotesComponent } from './../../../common/notes';
import NotesComponent from './../../../common/notes2/index';

import { ListViewPagination, ListFilterWarning } from './../../../common';
import { services } from './../../../bootstrap/feathers';
import { OrderItemComponent } from './../../../common/OrderItemCard';
import OrderItemCard from './../OrderItemCard';
import Feathers from './../../../bootstrap/feathers';

import MessageAdd from './../MessageAdd';
import MessageThreadModal from './../MessageThreadModal';
import PMApprovalModal from './../PMApprovalModal';
import CertificationModal from './../CertificationModal';

import * as superagent from 'superagent';
import Dropzone from 'react-dropzone';

export interface DetailProps {
  loadOrderItems: any;
  orderItems: any;
}

export interface DetailState {
  isMessageThreadModalOpen: boolean;
  isCertificationModalOpen: boolean;
  isPMApprovalModalOpen: boolean;
  messageThreadOrderItem: any;
}

function onDrop(acceptedFiles, rejectedFiles) {
  // do stuff with files...
}

class Detail extends React.PureComponent<DetailProps & DetailFeaturesProps, DetailState> {
  constructor(props: DetailProps & DetailFeaturesProps) {
    super(props);
    this.state = {
      isMessageThreadModalOpen: false,
      isCertificationModalOpen: false,
      isPMApprovalModalOpen: false,
      messageThreadOrderItem: null,
    };
  }
  componentDidMount() {
    this.loadOrderItems();
  }

  loadOrderItems(mergeInQuery = {}) {
    this.props.loadOrderItems({
      query: {
        order: this.props.match.params.id,
        $populate: [
          'attributes.value',
          'category',
          'customer',
          'productionStatus.jobStatus',
          'translator.assigned',
          'miscUploads',
        ],
        $sort: { createdAt: -1 },
        ...mergeInQuery,
      },
    });
  }

  handlePageChange = (query) => {
    this.loadOrderItems(query);
  };

  handleNoteSubmit = (values) => {
    Feathers.service('/translators/note-to-translator')
      .create({
        orderItemId: this.state.messageThreadOrderItem._id,
        content: values.content,
        attachemnts: values.attachments,
        authorType: 'tenant',
      })
      .then((result) => {
        this.setState({ ...this.state, isMessageThreadModalOpen: false });
        // this.props.reload();
        // this.props.loadOrderItems();
      });
  };

  handleCertficationSubmit = (values) => {
    Feathers.service('/translators/certification-complete')
      .create({
        orderItemId: this.state.messageThreadOrderItem._id,
        attachemnts: values.attachments,
      })
      .then((result) => {
        this.setState({ ...this.state, isCertificationModalOpen: false });
        // this.props.reload();
        // this.props.loadOrderItems();
      });
  };

  handlePMApprovalSubmit = (values) => {
    Feathers.service('/translators/pm-approved')
      .create({
        orderItemId: this.state.messageThreadOrderItem._id,
      })
      .then((result) => {
        this.setState({ ...this.state, isPMApprovalModalOpen: false });
        // this.props.reload();
        // this.props.loadOrderItems();
      });
  };

  handleNeedsRevision = (values) => {
    Feathers.service('/translators/pm-needs-revision')
      .create({
        orderItemId: this.state.messageThreadOrderItem._id,
        revisionComments: values.revisionComments,
      })
      .then((result) => {
        this.setState({ ...this.state, isPMApprovalModalOpen: false });
        // this.props.reload();
        // this.props.loadOrderItems();
      });
  };

  handleItemClick = () => {};

  render() {
    const { data } = this.props;
    const { orderItems } = this.props;

    if (!this.props.data) {
      return (
        <Segment>
          <Dimmer active>
            <Loader size="small">Loading</Loader>
          </Dimmer>
        </Segment>
      );
    }

    return (
      <div style={{ background: '#f3f3f3' }}>
        <MessageThreadModal
          orderItem={this.state.messageThreadOrderItem}
          open={this.state.isMessageThreadModalOpen}
          handleClose={() => this.setState({ ...this.state, isMessageThreadModalOpen: false })}
          handleSubmit={this.handleNoteSubmit}
        />

        <CertificationModal
          orderItem={this.state.messageThreadOrderItem}
          open={this.state.isCertificationModalOpen}
          handleClose={() => this.setState({ ...this.state, isCertificationModalOpen: false })}
          handleSubmit={this.handleCertficationSubmit}
        />

        <PMApprovalModal
          orderItem={this.state.messageThreadOrderItem}
          open={this.state.isPMApprovalModalOpen}
          handleClose={() => this.setState({ ...this.state, isPMApprovalModalOpen: false })}
          handleSubmit={this.handlePMApprovalSubmit}
          handleNeedsRevision={this.handleNeedsRevision}
        />

        {/* <MessageAdd tenantId={this.props.data.business} onSave={this.handleNoteSubmit} /> */}
        <Grid verticalAlign="top" padded stackable stretched>
          <Grid.Row stretched>
            <Grid.Column width={10} stretched>
              <Header as="h2" color="blue">
                <Header.Content>
                  Order Details
                  {this.props.data && this.props.data.organizationName
                    ? this.props.data.organizationName
                    : this.props.data.primaryContact &&
                      `${this.props.data.primaryContact.firstName} ${this.props.data.primaryContact.lastName}`}
                  <Header.Subheader>
                    ID: {this.props.data && this.props.data.humanId}
                  </Header.Subheader>
                </Header.Content>
              </Header>
            </Grid.Column>
            <Grid.Column width={6}>
              <Button.Group size="mini" fluid secondary>
                <Button>Create Order</Button>
                <Button>Edit Details</Button>
                <Button>Login As</Button>
              </Button.Group>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row stretched>
            <Grid.Column width={10} stretched>
              <OrderInfoTable raised order={this.props.data} />
            </Grid.Column>
            <Grid.Column width={6} stretched style={{ height: '100%' }}>
              <Segment raised style={{ height: '100%' }}>
                <NotesComponent
                  isModalOpen={false}
                  handleNoteClose={() => {}}
                  notes={[]}
                  fixedHeight={206}
                  onSubmit={this.handleNoteSubmit}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Menu attached="top" pointing secondary>
                <Menu.Item name="order items" active={true} onClick={this.handleItemClick} />
              </Menu>

              <Segment attached="bottom">
                <div style={{ marginBottom: 18 }}>
                  <ListViewPagination.Top
                    label={'New Customer'}
                    link={'/products/categories/add'}
                    {...orderItems}
                    onPageChange={this.handlePageChange}
                    hideAddButton={true}
                  />
                </div>

                {orderItems.queryResult &&
                  orderItems.queryResult.data &&
                  orderItems.queryResult.data.map((oi: any, index: number) => (
                    <div key={index}>
                      <OrderItemCard key={oi._id} orderItem={oi}>
                        <Button
                          style={{ marginBottom: 10 }}
                          basic
                          size={'tiny'}
                          onClick={(e, data) =>
                            this.setState({
                              ...this.state,
                              isMessageThreadModalOpen: true,
                              messageThreadOrderItem: data.orderitem,
                            })
                          }
                          content={'Add Note to Translator'}
                          orderitem={oi}
                        />
                        <Button
                          style={{ marginBottom: 10 }}
                          basic
                          size={'tiny'}
                          onClick={(e, data) =>
                            this.setState({
                              ...this.state,
                              isPMApprovalModalOpen: true,
                              messageThreadOrderItem: data.orderitem,
                            })
                          }
                          content={'PM Approval'}
                          orderitem={oi}
                        />
                        <Button
                          style={{ marginBottom: 10 }}
                          basic
                          size={'tiny'}
                          onClick={(e, data) =>
                            this.setState({
                              ...this.state,
                              isCertificationModalOpen: true,
                              messageThreadOrderItem: data.orderitem,
                            })
                          }
                          content={'Certification'}
                          orderitem={oi}
                        />
                      </OrderItemCard>
                    </div>
                  ))}
                {/* {orderItems.queryResult && orderItems.queryResult.data && orderItems.queryResult.data.map(oi => <OrderItemComponent key={oi._id} orderItem={oi} />)} */}
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

export default DetailWrapper(Detail, 'orders', {
  mapStateToProps: (state: any) => ({
    orderItems: state.orderItems,
  }),
  mapDispatchToProps: {
    loadOrderItems: services.orderItems.find,
  },
  query: {
    $populate: ['customer'],
  },
});
