import { Operator, generateOperatorOptions } from '@inkcloud/components';
import Feathers from '../../../bootstrap/feathers';

export async function getFilterDefinition() {
  let definition = [
    {
      label: 'Date',
      value: 'createdAt',
      operators: generateOperatorOptions([Operator.Between]),
      type: 'date',
    },
    {
      label: 'Operation',
      value: 'operation',
      operators: generateOperatorOptions([Operator.Is]),
      valueOptions: [
        { value: 'ADJUSTMENT', label: 'Adjustment' },
        { value: 'ALLOCATED', label: 'Allocated' },
        { value: 'BACK_ORDER', label: 'Back Order' },
        { value: 'FULFILL', label: 'Fulfill' },
        { value: 'MOVEMENT', label: 'Movement' },
        { value: 'OPENING_AMOUNT', label: 'Opening Amount' },
        { value: 'RECEIVE', label: 'Receive' },
        { value: 'RETURN', label: 'Return' },
        { value: 'VOID_ADJUSTMENT', label: 'Void Adjustment' },
      ],
      type: 'select',
    },
    {
      label: 'Order Number',
      value: 'order.humanId',
      operators: generateOperatorOptions([Operator.Is]),
    },
    {
      label: 'From',
      value: 'source.name',
      operators: generateOperatorOptions([Operator.Like, Operator.Is]),
    },
    {
      label: 'To',
      value: 'destination.name',
      operators: generateOperatorOptions([Operator.Like, Operator.Is]),
    },
    {
      label: 'Count',
      value: 'amount',
      operators: generateOperatorOptions([Operator.Is]),
    },
  ];

  definition = definition.sort((a: any, b: any) => a.label.localeCompare(b.label));

  return definition;
}
