import React, { useState } from 'react';
import { Box, Input, Table, Thead, Tr, Td, Th, Tbody, Tfoot, Checkbox } from '@chakra-ui/react';
import * as Cleave from 'cleave.js/react';
import { useSelector } from 'react-redux';

import { currencyFormatted } from './helper';

// force deploy

const initialCostRows = [
  {
    operation: 'Sheeting',
    units: 0,
    capacity: 0.0,
    rate: 0,
    total: 0,
  },
  {
    operation: 'Cutting',
    units: 0,
    capacity: 0,
    rate: 0,
    total: 0,
  },
  {
    operation: 'Cut Panel Chip',
    units: 0,
    capacity: 0,
    rate: 0,
    total: 0,
  },
  {
    operation: 'Cut Spine Chip',
    units: 0,
    capacity: 0,
    rate: 0,
    total: 0,
  },
  {
    operation: 'Cut Capper',
    units: 0,
    capacity: 0,
    rate: 0,
    total: 0,
  },
  {
    operation: 'Cut Corners',
    units: 0,
    capacity: 0,
    rate: 0,
    total: 0,
  },
  {
    operation: 'R/C Chipboard',
    units: 0,
    capacity: 0,
    rate: 0,
    total: 0,
  },
  {
    operation: 'R/C Liners',
    units: 0,
    capacity: 0,
    rate: 0,
    total: 0,
  },
  {
    operation: 'Silk Screening',
    units: 0,
    capacity: 0,
    rate: 0,
    total: 0,
  },
  {
    operation: 'Film',
    units: 0,
    capacity: 0,
    rate: 0,
    total: 0,
  },
  {
    operation: 'Screens',
    units: 0,
    capacity: 0,
    rate: 0,
    total: 0,
  },
  {
    operation: 'PMS Match',
    units: 0,
    capacity: 0,
    rate: 0,
    total: 0,
  },
  {
    operation: 'Debossing S/U',
    units: 0,
    capacity: 0,
    rate: 0,
    total: 0,
  },
  {
    operation: 'Debossing',
    units: 0,
    capacity: 0,
    rate: 0,
    total: 0,
  },
  {
    operation: 'Foil Stamping S/U',
    units: 0,
    capacity: 0,
    rate: 0,
    total: 0,
  },
  {
    operation: 'Foil Stamping',
    units: 0,
    capacity: 0,
    rate: 0,
    total: 0,
  },
  {
    operation: 'Binder S/U',
    units: 0,
    capacity: 0,
    rate: 0,
    total: 0,
  },
  {
    operation: 'Spot Covers',
    units: 0,
    capacity: 0,
    rate: 0,
    total: 0,
  },
  {
    operation: 'Wrap Covers',
    units: 0,
    capacity: 0,
    rate: 0,
    total: 0,
  },
  {
    operation: 'Spot/Set Liners',
    units: 0,
    capacity: 0,
    rate: 0,
    total: 0,
  },
  {
    operation: 'Corners',
    units: 0,
    capacity: 0,
    rate: 0,
    total: 0,
  },
  {
    operation: 'Cleaning',
    units: 0,
    capacity: 0,
    rate: 0,
    total: 0,
  },
  {
    operation: 'Slipcase S/U',
    units: 0,
    capacity: 0,
    rate: 0,
    total: 0,
  },
  {
    operation: 'Spot Covers',
    units: 0,
    capacity: 0,
    rate: 0,
    total: 0,
  },
  {
    operation: 'Wrap Covers',
    units: 0,
    capacity: 0,
    rate: 0,
    total: 0,
  },
  {
    operation: 'Thumb Notch',
    units: 0,
    capacity: 0,
    rate: 0,
    total: 0,
  },
  {
    operation: 'Cleaning ',
    units: 0,
    capacity: 0,
    rate: 0,
    total: 0,
  },
  {
    operation: 'Taping',
    units: 0,
    capacity: 0,
    rate: 0,
    total: 0,
  },
  {
    operation: 'Rivet Machine S/U',
    units: 0,
    capacity: 0,
    rate: 0,
    total: 0,
  },
  {
    operation: 'Riveting',
    units: 0,
    capacity: 0,
    rate: 0,
    total: 0,
  },
  {
    operation: 'Rivet/Helper',
    units: 0,
    capacity: 0,
    rate: 0,
    total: 0,
  },
  {
    operation: 'Hammer Rivets',
    units: 0,
    capacity: 0,
    rate: 0,
    total: 0,
  },
  {
    operation: 'Drill Liner',
    units: 0,
    capacity: 0,
    rate: 0,
    total: 0,
  },
];

interface CostRow {
  operation: string;
  capacity?: number;
  units?: number;
  rate?: number;
  total?: number;
}

interface ICostTableProps {
  quantity: number;
  onChange: (v: any) => void;
  values: any;
}

const CostTable: React.FunctionComponent<ICostTableProps> = (props) => {
  const { quantity, onChange, values } = props;

  const [costRows, setCostRows] = useState<CostRow[]>(initialCostRows);

  const tenant = useSelector(
    ({ globals }: { globals: { tenant: { currency: string; locale: string } } }) => globals.tenant
  );

  const total = Object.keys(values).reduce((acc, row) => acc + Number(values[row].total), 0);

  console.log('values', values);
  console.log('total', total);
  return (
    <Table size={'sm'}>
      <Thead>
        <Tr>
          <Th>
            <Checkbox></Checkbox>
          </Th>
          <Th>Operation</Th>
          <Th>Capacity</Th>
          <Th>Units</Th>
          <Th isNumeric>Rate</Th>
          <Th isNumeric>Total</Th>
        </Tr>
      </Thead>
      <Tbody>
        {costRows.map((row, index) => (
          <Tr key={index}>
            <Td>
              <Checkbox
                id={row.operation}
                defaultChecked={values[row.operation]}
                onChange={(e) => {
                  const hasValues = Object.values(values).length > 0;
                  if (hasValues) {
                    if (e.target.checked) {
                      onChange({ ...values, [row.operation]: row });
                    } else {
                      delete values[row.operation];

                      onChange({ ...values });
                    }
                  } else {
                    onChange({ [row.operation]: row });
                  }
                }}
              ></Checkbox>
            </Td>
            <Td>{row.operation}</Td>
            <Td>
              {values[row.operation] ? (
                <Box
                  sx={{
                    '.form-control-cleave': {
                      width: '100%',
                      outline: '2px solid transparent',
                      pos: 'relative',
                      fontSize: 'var(--chakra-fontSizes-sm)',
                      height: 'var(--chakra-sizes-8)',
                      border: '1px solid',
                      borderColor: 'var(--chakra-colors-gray-300)',
                      background: 'inherit',
                      paddingInlineStart: 'var(--chakra-sizes-3)',
                      paddingInlineEnd: 'var(--chakra-sizes-3)',
                      borderRadius: 'var(--chakra-radii-md)',
                    },
                  }}
                >
                  <Cleave
                    style={{ textAlign: 'right' }}
                    className={'form-control-cleave'}
                    value={values[row.operation]?.capacity ?? 0.0}
                    onBlur={(e) => {
                      const { rawValue: value } = e.target;
                      const newValues = Object.keys(values)?.reduce((acc, cur) => {
                        if (cur === row.operation) {
                          acc[cur] = {
                            ...values[cur],
                            capacity: Number(value),
                            rate: quantity / Number(value),
                          };
                        } else {
                          acc[cur] = values[cur];
                        }
                        return acc;
                      }, {});
                      onChange(newValues);
                    }}
                    options={{
                      numeral: true,
                      rawValueTrimPrefix: true,
                      numeralPositiveOnly: true,
                    }}
                  />
                </Box>
              ) : (
                currencyFormatted(
                  Number(values[row.operation]?.capacity ?? 0),
                  tenant?.locale,
                  tenant?.currency
                )
              )}
            </Td>
            <Td>{row.units}</Td>
            <Td isNumeric>
              {values[row.operation] ? (
                <Box
                  sx={{
                    '.form-control-cleave': {
                      width: '100%',
                      outline: '2px solid transparent',
                      pos: 'relative',
                      fontSize: 'var(--chakra-fontSizes-sm)',
                      height: 'var(--chakra-sizes-8)',
                      border: '1px solid',
                      borderColor: 'var(--chakra-colors-gray-300)',
                      background: 'inherit',
                      paddingInlineStart: 'var(--chakra-sizes-3)',
                      paddingInlineEnd: 'var(--chakra-sizes-3)',
                      borderRadius: 'var(--chakra-radii-md)',
                    },
                  }}
                >
                  <Cleave
                    style={{ textAlign: 'right' }}
                    className={'form-control-cleave'}
                    value={values[row.operation]?.rate ?? 0.0}
                    disabled={values[row.operation] === undefined}
                    onBlur={(e) => {
                      const { rawValue: value } = e.target;
                      const newValues = Object.keys(values)?.reduce((acc, cur) => {
                        if (cur === row.operation) {
                          acc[cur] = {
                            ...values[cur],
                            rate: Number(value),
                            total: Number(value) / (values[row.operation]?.capacity ?? 0.0),
                          };
                        } else {
                          acc[cur] = values[cur];
                        }
                        return acc;
                      }, {});

                      onChange(newValues);

                      // setCostRows(newRow)
                    }}
                    options={{
                      numeral: true,
                      rawValueTrimPrefix: true,
                      numeralPositiveOnly: true,
                      numeralThousandsGroupStyle: 'thousand',
                      prefix: '$',
                    }}
                  />
                </Box>
              ) : (
                currencyFormatted(
                  Number(values[row.operation]?.rate ?? 0),
                  tenant?.locale,
                  tenant?.currency
                )
              )}
            </Td>
            <Td isNumeric>
              {currencyFormatted(
                Number(values[row.operation]?.rate ?? 0) /
                  Number(values[row.operation]?.capacity ?? 0),
                tenant?.locale,
                tenant?.currency
              )}
            </Td>
          </Tr>
        ))}
      </Tbody>
      <Tfoot>
        <Tr>
          <Th colSpan={5}>Total</Th>
          <Th isNumeric>{currencyFormatted(total, tenant?.locale, tenant?.currency)}</Th>
        </Tr>
      </Tfoot>
    </Table>
  );
};

export default CostTable;
