import { PurchaseOrderDocument } from './PurchaseOrderDocument';
import { PurchaseOrderPage } from './PurchaseOrderPage';
import { BillTo } from './BillTo';
import { ShipTo } from './ShipTo';
import { Items } from './Items';
import { PurchaseOrderContext as Context } from './PurchaseOrderContext';
import PropertyList from '../shared/PropertyList';

export * from './PurchaseOrderDocument';
export * from './PurchaseOrderPage';
export * from './BillTo';
export * from './ShipTo';
export * from './Items';

export const Components = {
  PurchaseOrderDocument,
  PurchaseOrderPage,
  BillTo,
  ShipTo,
  Items,
  Context,
  PropertyList,
};
