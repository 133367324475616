import * as React from 'react';
import {
  Button,
  Flex,
  Heading,
  Box,
  IconButton,
  Tooltip,
  Badge,
  Icon,
  Text,
  TabList,
  Tab,
  TabPanel,
  TabPanels,
  Tabs,
  TabIndicator,
  Spacer,
  Divider,
  TableContainer, Table, Thead, Tbody, Tr, Th, Td, TableCaption, Tfoot
} from '@chakra-ui/react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Feathers from '../../bootstrap/feathers';
import Rfqs from './ListTabs/Rfqs';
import InProduction from './ListTabs/InProduction';
import InBilling from './ListTabs/InBilling';
import All from './ListTabs/All';
import Archived from './ListTabs/Archived';

interface ITabbledListProps {}
interface CountShape {
  rfqCount: number;
  inProductionCount: number;
  inBillingCount: number;
  rfqsEstimatePastDue: any[];

}

const urlToTab = {
  '/rfqs': 0,
  '/rfqs/in-production': 1,
  '/rfqs/in-billing': 2,
  '/rfqs/all': 3,
  '/rfqs/archived': 4,
};

function tabBorderStyle(active, current) {
  if (active !== current) {
    return {};
  }

  return {
    borderWidth: 1,
    borderColor: 'gray.200',
    borderBottomWidth: 0,
  };
}

const TabbledList: React.FunctionComponent<ITabbledListProps & RouteComponentProps<any>> = (
  props
) => {
  // console.log('!!!!!!!!!! we here');
  const {
    match: { params },
    location,
    history,
  } = props;

  const authUser = useSelector((state: any) => state.auth.user);
  const [counts, setCounts] = React.useState<CountShape>();

  React.useEffect(() => {
    const loadCounts = async () => {
      const countResults = (await Feathers.service('rfqs/counts').find()) as CountShape;
      setCounts(countResults);
    };

    loadCounts();
  }, []);

  const activeTab = urlToTab[location.pathname];

  return (
    <Box>
      <Box>
        <Flex mb={16} alignItems={'center'}>
          <Box>
            <Heading fontWeight={'normal'} size="xl">
              RFQs
            </Heading>
          </Box>
          <Spacer />
          <>
            <Link to={`/rfqs/add`}>
              {' '}
              <Button colorScheme="blue">New</Button>
            </Link>
          </>
        </Flex>
      </Box>


    { counts?.rfqsEstimatePastDue.length && (
      <Box borderColor={'gray.200'} borderWidth={1} bgColor={'white'} p={8} mb={8}>
      <Heading as={'h4'} size={'md'}>At a Glance</Heading>
      <Divider mb={8}  />
      <TableContainer>
        <Table variant='simple' size={'sm'}>
          <Thead>
            <Tr>
              <Th>RFQ #</Th>
              <Th>Issue</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
          { counts?.rfqsEstimatePastDue?.map((row => (
            <Tr key={row._id}>
            <Th><Link to={`rfqs/${row._id}`}>{row.humanId}</Link></Th>
            <Td>
              <Badge colorScheme='red'>Estimate Past Due</Badge>
            </Td>
          </Tr>
          )))}
          </Tbody>
        </Table>
      </TableContainer>

    </Box>

    )}

      <Tabs size="lg" variant={'unstyled'} colorScheme={'blue'} isLazy index={activeTab}>
        <TabList>
          <Tab
            {...tabBorderStyle(activeTab, 0)}
            bgColor={activeTab === 0 ? 'white' : undefined}
            as={Link}
            to={'/rfqs'}
          >
            RFQs{' '}
            {counts && counts.rfqCount && (
              <Badge ml={2} colorScheme="green">
                {counts.rfqCount}
              </Badge>
            )}{' '}
          </Tab>
          <Tab
            {...tabBorderStyle(activeTab, 1)}
            bgColor={activeTab === 1 ? 'white' : undefined}
            as={Link}
            to={'/rfqs/in-production'}
          >
            In Production{' '}
            {counts && counts.inProductionCount && (
              <Badge ml={2} colorScheme="green">
                {counts.inProductionCount}
              </Badge>
            )}
          </Tab>
          <Tab
            {...tabBorderStyle(activeTab, 2)}
            bgColor={activeTab === 2 ? 'white' : undefined}
            as={Link}
            to={'/rfqs/in-billing'}
          >
            Billing{' '}
            {counts && counts.inBillingCount && (
              <Badge ml={2} colorScheme="green">
                {counts.inBillingCount}
              </Badge>
            )}
          </Tab>
          <Tab
            {...tabBorderStyle(activeTab, 3)}
            bgColor={activeTab === 3 ? 'white' : undefined}
            as={Link}
            to={'/rfqs/all'}
          >
            All
          </Tab>
          <Tab
            {...tabBorderStyle(activeTab, 4)}
            bgColor={activeTab === 4 ? 'white' : undefined}
            as={Link}
            to={'/rfqs/archived'}
          >
            Archived
          </Tab>
        </TabList>
        <TabIndicator
          bgColor={'white'}
          // mt="-1.5px"
          height="2px"
          // bg="blue.500"
          borderRadius="1px"
          // borderColor={'gray.200'}
          // borderWidth={1}
        />
        <TabPanels bgColor={'white'} borderWidth={1} borderColor={'gray.200'}>
          <TabPanel>
            <Rfqs match={props.match} history={history} location={location} />
          </TabPanel>
          <TabPanel>
            <InProduction match={props.match} history={history} location={location} />
          </TabPanel>
          <TabPanel>
            <InBilling match={props.match} history={history} location={location} />
          </TabPanel>
          <TabPanel>
            <All match={props.match} history={history} location={location} />
          </TabPanel>
          <TabPanel>
            <Archived match={props.match} history={history} location={location} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default TabbledList;
