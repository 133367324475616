import * as React from 'react';
import { PageSize, Orientation } from '@react-pdf/types';
import { PackingSlipDocumentContext } from './PackingSlipDocument';

export const PackingSlipContext = React.createContext<PackingSlipContextInterface>(null);

interface IPackingSlipProviderProps {
  initialState: any;
  children: React.ReactNode;
}

interface PackingSlipContextInterface {
  dataCtx: PackingSlipDocumentContext;
  styles: any;
  config: {
    size: PageSize;
    orientation: Orientation;
  };
}

const config: PackingSlipContextInterface['config'] = {
  size: 'A4',
  orientation: 'portrait',
};

export const PackingSlipProvider: React.FunctionComponent<IPackingSlipProviderProps> = (props) => {
  const { children, initialState } = props;

  // console.log('initial state in this one!!!', initialState);
  const defaultCtx = initialState;
  return <PackingSlipContext.Provider value={defaultCtx}>{children}</PackingSlipContext.Provider>;
};
