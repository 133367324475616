import * as React from 'react';
import { useDropzone } from 'react-dropzone';
import { Button, Modal, Image } from 'semantic-ui-react';
import Cropper from 'react-cropper';
import axios from '../../../../bootstrap/axios';
import 'cropperjs/dist/cropper.css';
declare var APPENV;

function dataURItoBlob(dataURI) {
  var byteString = atob(dataURI.split(',')[1]);
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: 'image/jpeg' });
}

export interface IImageFieldProps {
  onChange: (v: any) => void;
  value: any;
  imageOptions?: {
    width?: number;
    height?: number;
  };
}

const endpoint = 'na/ctg/user-image-upload';
const { useCallback, useState, useEffect, useRef } = React;

export function ImageField(props: IImageFieldProps) {
  const { onChange, value, imageOptions } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);

  const onDrop = useCallback(async (acceptedFiles) => {
    setFiles(files.concat(acceptedFiles.map((f) => ({ ...f, preview: URL.createObjectURL(f) }))));
    setIsModalOpen(true);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  const rootProps = getRootProps();

  const handleSubmit = async (croppedImage) => {
    // Show some loading/upload indicator
    if (!files.length) {
      return;
    }

    let formData = new FormData();
    formData.append('file', dataURItoBlob(croppedImage), 'user-iamge-cropped.jpg');
    setIsUploading(true);
    setIsModalOpen(false);

    const response = await axios.post(`${APPENV.api.connection}/${endpoint}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: localStorage.getItem('feathers-jwt'),
      },
    });

    const [responseFile] = response.data.files;
    onChange(`https://storage.googleapis.com/design-template-user-images${responseFile.filename}`);
    setIsUploading(false);
  };

  let aspectRatio = 1;
  if (imageOptions) {
    const { width = 1, height = 1 } = imageOptions;
    if (width && height) {
      aspectRatio = width / height;
    }
  }

  return (
    <React.Fragment>
      <div {...rootProps}>
        <input {...getInputProps()} />
        {!value && (
          <Button
            icon={'upload'}
            content={'Upload'}
            primary
            type="button"
            size="mini"
            loading={isUploading}
          />
        )}

        {value && <Image src={value} size={'small'} />}
      </div>
      <CropperModal
        aspectRatio={aspectRatio}
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        imageSrc={files[0] && files[0].preview}
        onSubmit={handleSubmit}
      />
    </React.Fragment>
  );
}

export interface ICropperModalProps {
  open: boolean;
  onClose: () => void;
  imageSrc: any;
  aspectRatio?: Number;
  onSubmit: (croppedImage: any) => void;
}

export function CropperModal(props: ICropperModalProps) {
  const { open, onClose, imageSrc, onSubmit, aspectRatio } = props;
  const [croppedImage, setCroppedImage] = useState(null);
  const cropper = useRef();

  return (
    <Modal open={open} onClose={() => onClose}>
      <Cropper
        ref={cropper}
        src={imageSrc}
        style={{ height: 400, width: '100%' }}
        // Cropper.js options
        aspectRatio={aspectRatio}
        guides={false}
        crop={() => {
          setCroppedImage((cropper.current as any).getCroppedCanvas().toDataURL());
        }}
      />
      <Modal.Actions>
        <Button
          color="green"
          content="Save and Upload"
          type="button"
          onClick={() => onSubmit(croppedImage)}
        />
      </Modal.Actions>
    </Modal>
  );
}
