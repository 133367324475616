// import * as React from 'react';
// import { Button, Dimmer, Header, Grid, Loader, Segment } from 'semantic-ui-react';
// import { RouteComponentProps } from 'react-router-dom';
// import { DetailWrapper, DetailFeaturesProps } from './../../../bootstrap/DetailWrapper';
// import TranslatorForm from './TranslatorForm';
// import TranslatorInviteForm from './TranslatorInviteForm';

// import { Link } from 'react-router-dom';

// export interface EditProps {
// }

// export interface EditState {
// }

// class Edit extends React.Component<EditProps & DetailFeaturesProps, EditState> {
//   constructor(props: EditProps & DetailFeaturesProps) {
//     super(props);
//       this.state = {
//       }
//   }

//   render() {
//     const { data } = this.props;

//     if (this.props.match.params.id && !this.props.data) {
//       return (<Segment>
//                 <Dimmer active>
//                   <Loader size='small'>Loading</Loader>
//                 </Dimmer>
//               </Segment>
//       );
//     }

//     let initialValues = {};
//     if (this.props.match.params.id && this.props.data) {
//       initialValues = this.props.data;
//     }

//     return (
//       <div style={{background: "#f3f3f3"}}>
//         <Grid  verticalAlign='top' padded stackable stretched >
//           <Grid.Row  stretched>
//             <Grid.Column width={10} stretched>
//               <Header as='h2' color="blue" >
//               <Header.Content>
//                 {!data && `Invite Translator`}
//                 {data && `${data.firstName} ${data.lastName}`}
//                 { data && (<Header.Subheader>ID: {this.props.data && this.props.data.humanId}</Header.Subheader>)}
//               </Header.Content>

//             </Header>
//             </Grid.Column>
//             <Grid.Column width={6  }>
//             </Grid.Column>
//           </Grid.Row>
//         </Grid>
//         {this.props.match.params.id && this.props.data && <TranslatorForm onSubmit={this.props.handleSubmit} initialValues={initialValues} form={'translatorForm'} /> }
//         { !this.props.match.params.id && <TranslatorInviteForm onSubmit={this.props.handleSubmit} form={'translatorInvite'} /> }
//       </div>
//     );
//   }
// }

// export default DetailWrapper(Edit, 'translators', {
//   submitRedirect: '/admin/translators'
// });

import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Segment } from 'semantic-ui-react';
import { PageHeaderRow, PreLoader, useFeathers } from '@inkcloud/components';
import feathers from './../../../bootstrap/feathers';
import { TranslatorForm } from './TranslatorForm';

interface DetailFormProps {
  products: { value: string; text: string }[];
  categories: { value: string; text: string }[];
}

const serviceName = '/translators';
const pathPrefix = `/admin/translators`;
const service = feathers.service(serviceName);
const commonParams = { query: {} };

const { useState } = React;

export const DetailForm: React.FunctionComponent<
  DetailFormProps & RouteComponentProps<{ id?: string; micrositeId: string }>
> = (props) => {
  const {
    match: {
      params: { id },
    },
    history,
  } = props;

  const [errorMessage, setErrorMessage] = useState('');

  const isEdit = !!id;
  const [state, reload] = useFeathers('get', serviceName, [id, commonParams], true);
  const handleSubmit = async (values) => {
    setErrorMessage('');

    try {
      const res: any = isEdit
        ? await service.patch(id, values, commonParams)
        : await service.create(values, commonParams);

      if (!res?.result) {
        setErrorMessage(res?.message);
      }

      history.push(pathPrefix);
    } catch (e) {
      e.code && e.code < 500
        ? setErrorMessage(e.message)
        : setErrorMessage('We are experiencing technical difficulties. Please try again');
    }
  };

  if (isEdit && !state.data) {
    return <PreLoader />;
  }

  const initialData = { ...state.data, countryCode: state?.data?.countryCode ?? 'US' };

  return (
    <div>
      <PageHeaderRow
        header={`${
          isEdit
            ? `${initialData?.firstName ?? ''} ${initialData?.lastName ?? ''}`
            : 'Invite Translator'
        }`}
        subheader=""
        subheaderId={initialData?.humanId}
      >
        <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
      </PageHeaderRow>
      <Segment>
        {(!isEdit || state.data) && (
          <TranslatorForm
            initialValues={initialData}
            errorMessage={errorMessage}
            onSubmit={handleSubmit}
          />
        )}
      </Segment>
    </div>
  );
};
