import { extendTheme } from '@chakra-ui/react';
import { theme as defaultTheme } from '@saas-ui/theme';
import { StepsTheme as Steps } from 'chakra-ui-steps';


export default extendTheme({
  ...defaultTheme,
  components: {
    ...defaultTheme.components,
    Steps,
  },
});
