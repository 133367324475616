import * as React from 'react';
import { Button, Header, Form, Input, Image, Modal, Message } from 'semantic-ui-react';

export interface ReadyForPickupProps {
  open: boolean;
  order: any;
  handleClose: any;
  handleSubmit: any;
  errorMessage: string;
}

export interface ReadyForPickupState {}

export default class ReadyForPickup extends React.Component<
  ReadyForPickupProps,
  ReadyForPickupState
> {
  constructor(props: ReadyForPickupProps) {
    super(props);

    this.state = {};
  }

  handleSubmit = (e, data) => {
    this.props.handleSubmit();
  };

  render() {
    return (
      <Modal open={this.props.open} onClose={this.props.handleClose} closeIcon>
        <Modal.Header>Mark Ready For Pickup</Modal.Header>
        <Modal.Content>
          {this.props.errorMessage && <Message error content={this.props.errorMessage} />}
          <Form>
            <Button type="submit" onClick={this.handleSubmit}>
              Mark Ready For Pickup
            </Button>
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
}
