import * as React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { useList, PageHeaderRow, PaginatorWrapper, EmptyWrapper } from '@inkcloud/components';
import { Button, Form, Table, Message, Popup, Segment } from 'semantic-ui-react';

export interface ListProps {}

const serviceName = '/intake-channels';
const pathPrefix = `/settings/intake-channels`;

const filterQueryMap = (values) => {};

const { useState, useEffect } = React;
export const List: React.FunctionComponent<ListProps & RouteComponentProps> = (props) => {
  const {
    match: { params },
    location,
    history,
  } = props;

  const {
    state,
    reload,
    handleDelete,
    selectedItems,
    clearAll,
    setChecked,
    someSelected,
    toggleAll,
    filter,
    setFilter,
    sort,
    handleSort,
  } = useList({
    feathers: ['find', serviceName, [{ query: {} }]],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  const { queryResult, isLoading: isStateLoading } = state as any;

  if (!queryResult) {
    return null;
  }

  return (
    <>
      <PageHeaderRow header="Intake Channels" subheader="Settings">
        <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
        <Link to={`${pathPrefix}/add`}>
          <Button content="New" primary size={'mini'} />
        </Link>
      </PageHeaderRow>
      <div></div>
      <Segment>
        <PaginatorWrapper.Top
          filter={filter}
          reload={reload}
          data={queryResult as any}
          pathPrefix={`${pathPrefix}/`}
        />
        <Table size={'small'}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                <input type="checkbox" onChange={toggleAll} />
              </Table.HeaderCell>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Key</Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {queryResult &&
              (queryResult as any).data.map((r: any, index: number) => (
                <Table.Row verticalAlign="top" key={index}>
                  <Table.Cell>
                    <input
                      type="checkbox"
                      value={r._id}
                      checked={selectedItems[r._id] || false}
                      onChange={setChecked}
                    />
                  </Table.Cell>
                  <Table.Cell>{r.name}</Table.Cell>
                  <Table.Cell>{r.key}</Table.Cell>
                  <Table.Cell textAlign="right">
                    <Popup
                      size="mini"
                      content="Edit Intake Channel"
                      trigger={
                        <Link to={`${pathPrefix}/${r._id}`}>
                          <Button size={'mini'} icon={'edit'} />
                        </Link>
                      }
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
        <PaginatorWrapper.Bottom
          reload={reload}
          data={queryResult as any}
          pathPrefix={`${pathPrefix}`}
        >
          {someSelected && <Button primary type="button" content="Delete" onClick={handleDelete} />}
        </PaginatorWrapper.Bottom>
      </Segment>
    </>
  );
};
