/* eslint-disable no-restricted-properties */
/** @jsxImportSource @emotion/react */
import * as React from 'react';
import { Form, Input, Popup, PopupProps } from 'semantic-ui-react';
import * as isEmpty from 'validator/lib/isEmpty';
import camelize from 'underscore.string/camelize';
import slugify from 'underscore.string/slugify';
import { Paginator, type PaginatorProps } from './Paginator';
import * as ListViewPagination from './ListViewPagination';
import ListFilterWarning from './ListFilterWarning';
import DropdownTags from './DropdownTags';
import * as moment from 'moment';
import * as Shipping from './shipping';
import { Languages } from './translations';
import { jsx } from '@emotion/core';

import { FormattedDate, FormattedTime } from 'react-intl';

import styled from '@emotion/styled';
import css from '@emotion/css';

import provinces from 'provinces';
import Checkbox from 'semantic-ui-react/dist/commonjs/modules/Checkbox/Checkbox';
import Feathers from '../bootstrap/feathers';
import { DownloadZip } from './DownloadZip';

import SegmentCard from './SegmentCard';
import AddNote from './AddNote';
import UploadMisc from './UploadMisc';
import InputWrapper from './InputWrapper';

const { useState, useEffect } = React;

export interface IFormattedDateHoverProps {
  value?: any;
  size?: PopupProps['size'];
}

export const FormattedDateHover: React.FunctionComponent<IFormattedDateHoverProps> = (props) => {
  const { value, size = 'mini' } = props;

  if (!value) {
    return null;
  }

  return (
    <Popup
      size="mini"
      content={<FormattedTime value={value} />}
      trigger={
        <div>
          <FormattedDate value={value} />
        </div>
      }
    />
  );
};

export const validateRequired = (required: any[]) => {
  return (values) => {
    const errors: any = {};
    required.forEach((el) =>
      values[el] === undefined || isEmpty(String(values[el]))
        ? (errors[el] = 'Field is required')
        : null
    );
    return errors;
  };
};

export const passwordValidation = () => {
  return (values) => {
    let errors: Object = {};
    if (values.password && values.repeatPassword) {
      if (values.password !== values.repeatPassword) {
        return (errors = {
          password: 'Passwords are not the same',
          repeatPassword: 'Passwords are not the same',
        });
      }
    }
  };
};

export const normalizeSlugify = (refField) => {
  return (value, prevValue, allValues: any) => {
    if (value === prevValue) {
      return allValues[refField] && slugify(allValues[refField]);
    } else {
      return value;
    }
  };
};

export const normalizeWithDims = (value, prevValue, allValues: any, prevAllValues: any) => {
  if (value === prevValue) {
    let str = '';
    if (allValues['width']) {
      str += allValues['width'];
      if (allValues['height']) {
        str += ' x ' + allValues['height'];
      }
      return str;
    }
  }
  return value;
};

export const asyncKeyValidator = (service) => {
  return (values, dispatch, props) => {
    return new Promise<void>((resolve, reject) => {
      if (!props.initialValues || !values['key'] || values['key'] === props.initialValues.key) {
        resolve();
      }

      Feathers.service(service)
        .find({ query: { key: values['key'] } })
        .then((results: any) => {
          results.data.length ? reject({ key: 'That key is taken' }) : resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};

export let usStateProvinces = [];
usStateProvinces = provinces
  .filter((p) => p.country === 'US')
  .reduce((prev, next) => {
    prev = prev.concat([{ text: next.name, value: next.short }]);
    // prev[next.short] = next.name;
    return prev;
  }, usStateProvinces);

export const countryStateProvinces = (countryCode: string) => {
  return provinces
    .filter((p) => p.country === (countryCode?.toUpperCase() ?? 'US'))
    .reduce((prev, next) => {
      prev = prev.concat([{ text: next.name, value: next.short }]);
      // prev[next.short] = next.name;
      return prev;
    }, []);
};

export function semanticFormField({
  input,
  type,
  label,
  fieldLabel,
  placeholder,
  meta: { touched, error, warning },
  as: As = Input,
  ...props
}) {
  function handleChange(e, { value }) {
    return input.onChange(value);
  }
  return (
    <Form.Field error={error && error !== undefined}>
      {fieldLabel && <label>{fieldLabel}</label>}
      <As
        {...props}
        {...input}
        value={input.value}
        type={type}
        label={label}
        placeholder={placeholder}
        onChange={handleChange}
        error={error && error !== undefined}
      />
      {touched &&
        ((error && (
          <span>
            <i>{error}</i>
          </span>
        )) ||
          (warning && (
            <span>
              <i>{warning}</i>
            </span>
          )))}
    </Form.Field>
  );
}

export function semanticFormCheckboxField({
  input,
  type,
  label,
  fieldLabel,
  placeholder,
  meta: { touched, error, warning },
  as: As = Input,
  ...props
}) {
  function handleChange(e, { value }) {
    return input.onChange(value);
  }
  return (
    <Form.Field>
      {fieldLabel && <label>{fieldLabel}</label>}
      <As
        {...props}
        {...input}
        value={input.value}
        type={type}
        label={label}
        placeholder={placeholder}
        onClick={(event, data) => {
          input.onChange(data.checked);
        }}
      />
      {touched &&
        ((error && (
          <span>
            <i>{error}</i>
          </span>
        )) ||
          (warning && (
            <span>
              <i>{warning}</i>
            </span>
          )))}
    </Form.Field>
  );
}

export function semanticFormToggleField({
  input,
  type,
  label,
  fieldLabel,
  placeholder,
  meta: { touched, error, warning },
  as: As = Input,
  ...props
}) {
  function handleChange(e, { value }) {
    return input.onChange(value);
  }

  return (
    <Form.Field>
      {fieldLabel && <label>{fieldLabel}</label>}
      <As
        {...props}
        {...input}
        value={input.value}
        checked={input.value ? true : false}
        type={type}
        label={label}
        placeholder={placeholder}
        onClick={(event, data) => input.onChange(data.checked)}
      />
      {touched &&
        ((error && (
          <span>
            <i>{error}</i>
          </span>
        )) ||
          (warning && (
            <span>
              <i>{warning}</i>
            </span>
          )))}
    </Form.Field>
  );
}

export function semanticFormRadioField({
  input,
  type,
  label,
  fieldLabel,
  placeholder,
  meta: { touched, error, warning },
  as: As = Input,
  ...props
}) {
  function handleChange(e, { value }) {
    return input.onChange(value);
  }

  return (
    <Form.Field>
      {fieldLabel && <label>{fieldLabel}</label>}
      <As
        {...props}
        {...input}
        value={input.value}
        type={type}
        label={label}
        placeholder={placeholder}
        onClick={handleChange}
      />
      {touched &&
        ((error && (
          <span>
            <i>{error}</i>
          </span>
        )) ||
          (warning && (
            <span>
              <i>{warning}</i>
            </span>
          )))}
    </Form.Field>
  );
}

export function semanticFormDropdownField({
  input,
  type,
  label,
  fieldLabel,
  placeholder,
  fieldProps,
  meta: { touched, error, warning },
  as: As = Input,
  ...props
}) {
  function handleChange(e, { value }) {
    return input.onChange(value);
  }

  function handleBlur(e, { value }) {
    if (props.search) {
      return input.onChange(value);
    }
  }

  let defaultValue = input.value;
  if (props.multiple) {
    defaultValue = Array.isArray(input.value) ? defaultValue : [];
  }
  return (
    <Form.Field {...fieldProps}>
      {fieldLabel && <label>{fieldLabel}</label>}
      <As
        {...props}
        {...input}
        value={defaultValue}
        label={label}
        placeholder={placeholder}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      {touched &&
        ((error && (
          <span>
            <i>{error}</i>
          </span>
        )) ||
          (warning && (
            <span>
              <i>{warning}</i>
            </span>
          )))}
    </Form.Field>
  );
}

export function semanticFormCheckboxGroupField({
  input,
  type,
  options,
  label,
  placeholder,
  fieldProps,
  meta: { touched, error, warning },
  as: As = Input,
  ...props
}) {
  const { name, onChange } = input;
  const inputValue = input.value || [];

  const checkboxes = options.map(({ label, value }, index) => {
    const handleChange = (event, data) => {
      const arr = [...inputValue];
      if (data.checked) {
        arr.push(value);
      } else {
        arr.splice(arr.indexOf(value), 1);
      }
      return onChange(arr);
    };
    const checked = inputValue.includes(value);
    return (
      <Form.Checkbox
        key={`${value}${index}`}
        label={label}
        name={`${name}[${index}]`}
        value={value}
        disabled={props.disabled}
        checked={checked}
        onChange={handleChange}
      />
    );
  });

  return (
    <Form.Group>
      <label>{label}</label>
      {checkboxes}
      {touched &&
        ((error && (
          <span>
            <i>{error}</i>
          </span>
        )) ||
          (warning && (
            <span>
              <i>{warning}</i>
            </span>
          )))}
    </Form.Group>
  );
}

export function semanticFormRadioGroupField({
  input,
  type,
  options,
  label,
  placeholder,
  fieldProps,
  meta: { touched, error, warning },
  as: As = Input,
  ...props
}) {
  const { name, onChange } = input;
  const inputValue = input.value;

  const radios = options.map(({ label, value }, index) => {
    const handleChange = (event, data) => {
      // const arr = [...inputValue];
      // if (data.checked) {
      //   arr.push(value);
      // }
      // else {
      //   arr.splice(arr.indexOf(value), 1);
      // }
      return onChange(data.value);
    };
    const checked = inputValue === value;
    return (
      <Form.Radio
        key={`${value}${index}`}
        label={label}
        name={`${name}[${index}]`}
        value={value}
        checked={checked}
        disabled={props.disabled}
        onChange={handleChange}
      />
    );
  });

  return (
    <Form.Group>
      <label>{label}</label>
      {radios}
      {touched &&
        ((error && (
          <span>
            <i>{error}</i>
          </span>
        )) ||
          (warning && (
            <span>
              <i>{warning}</i>
            </span>
          )))}
    </Form.Group>
  );
}

export function semanticFormDatePickerField({
  input,
  type,
  options,
  label,
  fieldLabel,
  placeholder,
  fieldProps,
  meta: { touched, error, warning },
  as: As = Input,
  ...props
}) {
  function handleChange(value) {
    return input.onChange(moment(value).format('YYYY-MM-DD'));
  }

  return (
    <Form.Field error={error && error !== undefined}>
      {fieldLabel && <label>{fieldLabel}</label>}
      <As
        {...props}
        {...input}
        dateForm="YYYY-MM-DD"
        selected={input.value ? moment(input.value, 'YYYY-MM-DD') : null}
        label={label}
        placeholder={placeholder}
        onChange={handleChange}
        error={error && error !== undefined}
      />
      {touched &&
        ((error && (
          <span>
            <i>{error}</i>
          </span>
        )) ||
          (warning && (
            <span>
              <i>{warning}</i>
            </span>
          )))}
    </Form.Field>
  );
}

export const SemanticFormField = {
  Checkbox: semanticFormCheckboxField,
  CheckboxGroup: semanticFormCheckboxGroupField,
  DatePicker: semanticFormDatePickerField,
  Dropdown: semanticFormDropdownField,
  Input: semanticFormField,
  Radio: semanticFormRadioField,
  Toggle: semanticFormToggleField,
  RadioGroup: semanticFormRadioGroupField,
};

export const normalizeBoolean = (value) => (value === 'true' ? true : false);
export const formatBoolean = (value) => (value === true ? 'true' : 'false');

export const slugify2 = (value: string, isUrl?: boolean) => {
  let newValue = value
    ?.toString()
    ?.toLowerCase()
    ?.replace(/\s+/g, '-')
    ?.replace(/\-\-+/g, '-')
    ?.replace(/^-+/, '');

  if (isUrl) {
    return newValue?.replace(/\/\/+/g, '/')?.replace(/\-\/+/g, '/');
  }

  return newValue?.replace(/[^\w\-]+/g, '');
};

export const groupByKey = (arr: any[], key: string, label: string) => {
  const result = arr?.reduce(function (acc, a) {
    acc[a[key]] = acc[a[key]] || [];
    acc[a[key]].push(a);
    return acc;
  }, {});

  const mappedResult = Object.keys(result)?.map((key) => ({
    label: result[key]?.[0]?.[label] ?? '',
    options: result[key],
  }));

  return mappedResult;
};

export const groupBy = (arr) =>
  arr?.reduce((acc, cur) => {
    acc[cur.accountType] = acc[cur.accountType] || [];
    acc[cur.accountType].push(cur);

    return acc;
  }, {});
// Todo: merge top

const TruncateWrapper = css({
  display: 'flex',
  '&:hover': {
    div: {
      whiteSpace: 'pre-wrap',
      overflow: 'initial',
      textOverflow: 'initial',
    },
  },
});

const TruncateInner = css({
  flex: 1,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

// export const PreTruncate = ({ text }) => (
//   <div css={TruncateWrapper}>
//     <div css={TruncateInner}>{text}</div>
//   </div>

// )

export const PreTruncate = ({ text }) => <div>{text}</div>;

export const Truncate = styled(PreTruncate)``;

const TruncateText = (text, charLength) =>
  `${text.slice(0, charLength)}${text.length > charLength ? '...' : ''}`;

function currencySymbol(currency = 'USD', locale = 'en-US') {
  const newLocale = locale.replace(/_/g, '-');

  return new Intl.NumberFormat(newLocale, { style: 'currency', currency })
    ?.formatToParts(1)
    ?.find((x) => x.type === 'currency')?.value;
}

function reduceFraction(numerator: number, denominator: number) {
  const gcd = (a: number, b: number) => (b ? gcd(b, a % b) : a);

  const res = gcd(numerator, denominator);
  return [numerator / res, denominator / res];
}

function gcd2(a, b) {
  if (b < 0.0000001) return a;

  console.log('gcd', b, Math.floor(a % b));
  return gcd2(b, Math.floor(a % b));
}

function fractionToNumber(value: string) {
  const fractionalBarIndex = value.indexOf('/');

  const spaceIndex = value.indexOf(' ') === -1 ? 0 : value.indexOf(' ');

  // console.log('fractionalBarIndex', fractionalBarIndex);

  if (fractionalBarIndex !== -1) {
    const whole = value.slice(0, spaceIndex) || 0;
    const n = value.slice(spaceIndex, fractionalBarIndex);
    const d = value.slice(fractionalBarIndex + 1);

    if (Number(d) === 0) return '0';

    const numerator = Math.abs(Number(n));
    const denominator = Math.abs(Number(d));

    const integer = Math.floor(numerator / denominator);
    let rest = numerator % denominator;
    let decimal = '.';
    const hash = {};
    while (rest !== 0) {
      const curDecimal = Math.floor((rest * 10) / denominator);
      hash[rest] = decimal.length;
      rest = (rest * 10) % denominator;
      if (hash[rest] != null) {
        decimal = decimal.substring(0, hash[rest]) + decimal.substring(hash[rest]) + curDecimal;
        break;
      } else {
        decimal += curDecimal;
      }
    }
    // console.log('fractionToNumber', integer, decimal, '=================', decimal);
    return Number(whole) + (decimal === '.' ? '' : decimal);
  }

  return value;
}

function fractionToNumber2(value: string) {
  const fractionalBarIndex = value.indexOf('/');
  const spaceIndex = value.indexOf(' ') === -1 ? 0 : value.indexOf(' ');

  if (fractionalBarIndex !== -1) {
    const whole = value.slice(0, spaceIndex) || 0;
    const numerator = value.slice(spaceIndex, fractionalBarIndex);
    const denominator = value.slice(fractionalBarIndex + 1);

    if (denominator) {
      return Number(whole) + Number(numerator) / Number(denominator);
    }
  }

  return value;
}

function roundDecimal(value: string) {
  console.log('round decimal', value, '=================');
  if (value.length > 4) return Number(value).toFixed(2);

  return Number(value);
  // return Math.ceil(Number(value) * 10) / 10;
}

function numberToFraction(value: number) {
  // console.log('value', value, '=================');
  const decimalIndex = value.toString().indexOf('.');
  if (decimalIndex !== -1) {
    // const decimalValue = roundDecimal(String(value).slice(decimalIndex));
    const decimalValue = String(value).slice(decimalIndex);

    const whole = `${String(value).slice(0, decimalIndex)} `;

    console.log('decimalValue', decimalValue);
    const len = Number(decimalValue).toString().length - 2;
    console.log('len', len);

    let denominator = Math.pow(10, len);
    console.log('top denominator', denominator);
    let numerator = Number(decimalValue) * denominator;

    const divisor = gcd2(numerator, denominator);
    // console.log('divisor', divisor);

    // numerator /= divisor;
    // denominator /= divisor;

    console.log('numerator', numerator, 'denominator', denominator, 'divisor', divisor);

    const res = reduceFraction(numerator, denominator);
    // console.log('res', res, '=================');
    [numerator, denominator] = res;

    if (numerator && denominator) {
      return `${whole}${Math.floor(numerator)}/${Math.floor(denominator)}`;
    }
  }

  return value;
}

function stripPar(value: string) {
  return value.replace(/\(/g, '').replace(/\)/g, '');
}

function validateNumberFraction(value: string) {
  if (!value) return false;
  // const regex = new RegExp(/^(?!.*\d+(?:\.\d+){2})\d*\s\s+*\d*\/?\d*\.?\d*$/g);
  const regex = new RegExp(/^\d+((?=[ ]*)[ ]?\d+\/\d+|(?=.*)\.?\d*)$/);

  console.log('regex', regex.test(value), 'value', value);
  // const regex3 = new RegExp(/^\d+((?=[ ]*)[ ]?\d+\/\d+)*$/g);
  return regex.test(value);
}
function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export {
  Paginator,
  DropdownTags,
  PaginatorProps,
  ListViewPagination,
  ListFilterWarning,
  Shipping,
  Languages,
  DownloadZip,
  SegmentCard,
  AddNote,
  UploadMisc,
  TruncateText,
  InputWrapper,
  currencySymbol,
  fractionToNumber,
  numberToFraction,
  stripPar,
  validateNumberFraction,
  validateEmail,
};
