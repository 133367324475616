import { Operator, generateOperatorOptions } from '@inkcloud/components';
import { Feathers } from '../../../bootstrap/feathers/feathers';

export default async function getFilterDefinition() {
  const definition = [
    {
      label: 'Templates',
      value: 'workflowTemplate',
      operators: generateOperatorOptions([Operator.Is]),
      valueOptions: [],
      asyncValueOptions: async () =>
        Feathers.service('job-scheduler/workflow-templates')
          .find({
            query: {
              $sort: { name: 1 },
            },
          })
          .then((results) => results?.data?.map((r) => ({ value: r._id, label: r.name }))),
      type: 'multi-select',
    },
  ];

  return definition;
}
