import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { PageHeaderRow, PreLoader, useFeathers } from '@inkcloud/components';
import { connect } from 'react-redux';
import { CSVLink } from 'react-csv';

import {
  Badge,
  Box,
  Button,
  Flex,
  Heading,
  Text,
  Spacer,
  IconButton,
  Menu,
  MenuList,
  MenuButton,
  MenuItem,
  MenuDivider,
  Table as ChakraTable,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  TableContainer,
  ButtonGroup,
  Tooltip,
  Icon,
  Tag,
  HStack,
  Divider,
  Tfoot,
  Alert,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  AlertIcon,
} from '@chakra-ui/react';

import { FaTrash, FaEdit, FaRegFile } from 'react-icons/fa';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';

import { FormattedDate, FormattedTime } from 'react-intl';

import type { ServiceTypes, ModelTypes } from '@inkcloud/icapi-types';

import { currencyFormat } from './helpers';
import feathers from '../../bootstrap/feathers';

import { IndexWrappedProps } from '../../bootstrap/IndexWrapper';

import { TruncateText } from '../../common';
import { connectOrderItemModals } from '../../common/OrderItemCard2';
import SupplierChangeModal from './SupplierChangeModal';
import AttachDocModal from './AttachDocModal';
import { AttachmentType } from './Detail';

type ICEntity<T extends keyof ServiceTypes> = Awaited<ReturnType<ServiceTypes[T]['get']>>;

// const location: ModelTypes.InventoryLocations

// export type Location = ICEntity<''>
export type Product = ICEntity<'products-legacy'>;
export type Receiving = ICEntity<'purchase-orders/receivings'>;
export type PurchaseOrders = ICEntity<'purchase-orders'> & {
  receiving: Receiving;
  notes: string;
  // items: {
  //   location: Location
  // }[]
};

type PopulatedVendorBillingReconciliations = ModelTypes.VendorBillingReconciliations & {
  vendor: ModelTypes.ProductionVendors;
  status:
    | ModelTypes.VendorBillingReconciliations['status']
    | 'awaiting-supplier-response'
    | 'approved-ready-to-bill';
};

export type ItemsType = Array<
  NonNullable<ModelTypes.VendorBillingReconciliations['items']>[number] & {
    changeAmount: number;
  }
>;
export interface ConnectedProps {
  user?: any;
}

interface IDetailProps {}

function statusMapper(status: string) {
  if (status === 'awaiting-supplier-response') {
    return 'Awaiting Response';
  }

  if (status === 'pending-review') {
    return 'Submitted';
  }

  return status;
}

function csvData(items: ItemsType) {
  const data = items.map((item) => ({
    order: item?.orderHumanId || '-',
    date: (item as any)?.date || '-',
    type: item?.type || '-',
    item: item?.orderItemHumanId || '-',
    description:
      item?.type === 'item' ? (item as any)?.orderItem?.productKey : (item as any)?.description,
    qty: (item as any)?.orderItem?.quantity || '-',
    amount: currencyFormat(item.amount) as any,
    changeAmount: item?.changeAmount !== undefined ? currencyFormat(item?.changeAmount) : '-',
    explanation: (item as any)?.changeExplanation || '-',
  }));

  const total = items?.reduce((acc, item) => acc + (item?.amount ?? 0.0), 0);
  const totalChange = items?.reduce(
    (acc, item) =>
      acc + ((item?.changeAmount !== undefined ? item.changeAmount : item?.amount) ?? 0.0),
    0
  );

  data.push({
    order: 'Total',
    date: '',
    type: '',
    item: '',
    description: '',
    qty: '',
    amount: total ? (currencyFormat(total) as any) : undefined,
    changeAmount: totalChange ? currencyFormat(totalChange) : '',
    explanation: '',
  });
  return data;
}

const headers = [
  { label: 'Order', key: 'order' },
  { label: 'Ship Date', key: 'date' },
  { label: 'Type', key: 'type' },
  { label: 'Item', key: 'item' },
  { label: 'Description', key: 'description' },
  { label: 'Quantity', key: 'qty' },
  { label: 'Amount', key: 'amount' },
  { label: 'Change Amount', key: 'changeAmount' },
  { label: 'Explanation', key: 'explanation' },
];

const serviceName = `/vendor-billing-reconcillations`;
const pathPrefix = `/supplier-billing-reconciliation`;
const service = feathers.service(serviceName);
const commonParams = {
  query: {
    $populate: [
      'vendor',
      'items.orderItem',
      'attachments.uploadLog',
      // 'items.product',
      // 'receivings.receivedBy',
      // 'receivings.items.product',
      // 'receivings.items.location',
      // 'privateNotes.createdBy',
    ],
  },
};

const { useEffect, useState } = React;

const baseUrl = `https://storage.googleapis.com/icstorage/`;

const PreDetail: React.FunctionComponent<
  IDetailProps & ConnectedProps & RouteComponentProps<{ id?: string }>
> = (props) => {
  const {
    match: {
      params: { id },
    },
    history,
    user,
  } = props;

  const isEdit = !!id;
  // const [state, reload] = useFeathers('get', serviceName, [id, commonParams], true);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState({
    submit: false,
    save: false,
    deleteFile: false,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [data, setData] = useState<PopulatedVendorBillingReconciliations>();
  const [attachments, setAttachments] = useState<AttachmentType>([]);
  const [items, setItems] = useState<ItemsType>([]);

  const [isViewPDFModalOpen, setIsViewPDFModalOpen] = useState(false);
  const [isChangeModalOpen, setIsChangeModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<ItemsType[number]>();

  const loadData = async () => {
    try {
      const res = await service.get(id, commonParams);
      setItems(res?.items);
      setAttachments(res?.attachments);
      setData(res);
    } catch (e) {
      console.error(e);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (id && isLoading) {
      loadData();
    }
  }, [id, isLoading]);

  if (isEdit && !data) {
    return <PreLoader />;
  }

  const handleChange = (item: NonNullable<ItemsType>[number]) => {
    const newItems = items?.map((i) => (i._id === item._id ? item : i));
    setItems(newItems);
  };
  const initialData = data;

  // console.log('items', items);
  // console.log(' initialData', initialData);

  // const handleSubmit = async () => {
  // const total = initialData?.items?.reduce((acc, item) => acc + (item?.amount ?? 0.0), 0);

  const handleSubmit = async (isDraft = false) => {
    setIsSubmitting({
      ...isSubmitting,
      submit: !isDraft,
      save: isDraft,
    });
    setErrorMessage('');
    const payload = {
      ...initialData,
      items,
      status: isDraft ? undefined : 'pending-review',
    };

    try {
      await service.patch(id, payload, commonParams);
      history.push(pathPrefix);
    } catch (e) {
      const errMsg =
        e?.code < 500 ? e.message : 'We are experiencing technical difficulties. Please try again';
      setErrorMessage(errMsg);
    }
    setIsSubmitting({
      ...isSubmitting,
      submit: false,
      save: false,
    });
  };

  const handleDelete = async () => {
    setIsSubmitting({
      ...isSubmitting,
      deleteFile: true,
    });
    try {
      const res = await service.patch(
        id,
        {
          attachments: [],
        },
        commonParams
      );

      setAttachments(res?.attachments);
    } catch (e) {
      console.log(e);
    }
    setIsSubmitting({
      ...isSubmitting,
      deleteFile: false,
    });
  };

  const total = items?.reduce((acc, item) => acc + (item?.amount ?? 0.0), 0);
  const totalChange = items?.reduce(
    (acc, item) =>
      acc + ((item?.changeAmount !== undefined ? item.changeAmount : item?.amount) ?? 0.0),
    0
  );
  const hasChanges = items?.some((item) => item?.changeAmount !== undefined);

  // approved-ready-to-bill
  // supplier can now upload the invoice
  const invoiceDoc = attachments?.find((a) => a?.label === 'Invoice');

  const hasInvoiceDoc = !!invoiceDoc;

  const pdfUrl = `${baseUrl}${invoiceDoc?.uploadLog?.cloudFilename}`;

  return (
    <Box>
      <Flex mb={16} alignItems={'center'}>
        <Box>
          <Heading fontWeight={'normal'} size="xl">
            Supplier Billing Reconciliation
          </Heading>
        </Box>
        <Badge
          ml={4}
          colorScheme={(initialData as any)?.status !== 'open' ? 'orange' : 'green'}
          fontSize={'large'}
        >
          {initialData?.humanId ? statusMapper(initialData?.status ?? '') ?? 'Draft' : ''}
        </Badge>
        <Spacer />
      </Flex>

      {initialData?.rejectionReason && (
        <Alert status="warning" mb={3}>
          <AlertIcon />
          {initialData.rejectionReason}
        </Alert>
      )}

      <Box bgColor={'white'} padding={8} mb={8} borderWidth={1} borderColor={'gray.200'}>
        <HStack spacing={16}>
          <Box>Date Range: {initialData?.rangeDescription}</Box>
          <Box>Supplier: {initialData?.vendor?.companyName}</Box>
        </HStack>
      </Box>
      <Box maxWidth={800}>
        <Box bgColor={'white'} padding={8} mb={8} borderWidth={1} borderColor={'gray.200'}>
          <p>Review the following billing items for any discrepencies</p>
        </Box>
        {(initialData as any)?.status === 'approved-ready-to-bill' && (
          <Box p={8} mb={8} bg="white" borderWidth={1} borderRadius="md">
            {hasInvoiceDoc ? (
              <>
                <Heading size="sm" mb={4}>
                  Invoice Document
                </Heading>
                <Flex>
                  <Box w="100%">
                    <Tooltip
                      hasArrow
                      aria-label="time"
                      label={<FormattedTime value={invoiceDoc?.createdAt} />}
                    >
                      <Box display="inline-block">
                        <FormattedDate value={invoiceDoc?.createdAt} />
                      </Box>
                    </Tooltip>
                  </Box>
                  <Box w="100%">
                    <Button variant="ghost" onClick={() => setIsViewPDFModalOpen(true)}>
                      <Text noOfLines={1}>
                        <Icon as={FaRegFile} />{' '}
                        {TruncateText(invoiceDoc?.uploadLog?.originalFilename ?? '', 100)}
                      </Text>
                    </Button>
                  </Box>
                  <Box>
                    <Tooltip hasArrow aria-label="Delete" label={'Delete Invoice Document'}>
                      <IconButton
                        isDisabled={isSubmitting.deleteFile}
                        isLoading={isSubmitting.deleteFile}
                        aria-label="Delete Invoice"
                        icon={<FaTrash />}
                        onClick={handleDelete}
                      />
                    </Tooltip>
                  </Box>
                </Flex>
              </>
            ) : (
              <Alert status="info">
                <Text>
                  You can now upload your Invoice Document.{' '}
                  <Button onClick={() => setIsModalOpen(true)}>Upload Invoice</Button>
                </Text>
              </Alert>
            )}
          </Box>
        )}

        <Box bgColor={'white'} padding={8} mb={8} borderWidth={1} borderColor={'gray.200'}>
          <Flex mb={4}>
            <Heading size={'md'} mb={4}>
              Invoice Items
            </Heading>
            <Spacer />
            <Box>
              <CSVLink
                className="chakra-button ui button small"
                data={csvData(items)}
                filename="billing-reconciliation.csv"
                headers={headers}
                target="_blank"
              >
                Download as CSV{' '}
              </CSVLink>
            </Box>
          </Flex>
          <Divider />
          <ChakraTable size={'sm'}>
            <Thead>
              <Tr>
                <Th width={80}>Order</Th>
                <Th width={80}>Item</Th>
                <Th width={80}>Ship Date</Th>
                {/* <Th>Type</Th> */}

                <Th>Description</Th>
                <Th isNumeric>Quantity</Th>
                <Th></Th>
                <Th isNumeric>Amount</Th>
              </Tr>
            </Thead>
            <Tbody>
              {items?.map((item, idx) => (
                <Tr key={idx}>
                  <Td width={80}>{item?.orderHumanId}</Td>
                  <Td width={100}>{item?.orderItemHumanId ?? '-'}</Td>
                  <Td width={80}>
                    {(item as any)?.date && <FormattedDate value={(item as any)?.date} />}
                  </Td>
                  {/* <Td>{(item as any)?.type}</Td> */}
                  <Td>
                    {item?.type === 'item'
                      ? (item as any)?.orderItem?.productKey
                      : (item as any)?.description}
                  </Td>
                  <Td isNumeric>{(item as any)?.orderItem?.quantity}</Td>
                  <Td>
                    <HStack>
                      <Tooltip hasArrow aria-label="time" label={'Click to change an amount'}>
                        <IconButton
                          aria-label="edit"
                          size={'xs'}
                          colorScheme="gray"
                          mr={1}
                          icon={<EditIcon />}
                          onClick={() => {
                            setSelectedItem(item);
                            setIsChangeModalOpen(true);
                          }}
                        />
                      </Tooltip>
                      {/* <Tooltip hasArrow aria-label="time" label={'Click to delete'}>
                        <IconButton
                          aria-label="delete"
                          colorScheme={(item as any)?.isDeleted ? 'red' : 'gray'}
                          size={'xs'}
                          icon={<DeleteIcon />}
                          onClick={() => {
                            (item as any).isDeleted = !(item as any)?.isDeleted;
                            handleChange(item);
                          }}
                        />
                      </Tooltip> */}
                    </HStack>
                  </Td>
                  <Td isNumeric>
                    <Text
                      as={
                        item?.changeAmount !== undefined && item?.changeAmount !== item?.amount
                          ? 'del'
                          : 'p'
                      }
                    >
                      {currencyFormat(item.amount ?? 0.0)}
                    </Text>

                    {item?.changeAmount && <Text>{currencyFormat(item?.changeAmount ?? 0.0)}</Text>}
                  </Td>
                </Tr>
              ))}
            </Tbody>
            <Tfoot>
              <Tr>
                <Th colSpan={6}>Total</Th>
                <Th isNumeric>
                  <Text as={hasChanges ? 'del' : 'p'}>{currencyFormat(total ?? 0.0)}</Text>
                  {hasChanges && <Text>{currencyFormat(totalChange ?? 0.0)}</Text>}
                </Th>
              </Tr>
            </Tfoot>
          </ChakraTable>
        </Box>
        {errorMessage && (
          <Alert status="error" mb={3}>
            {errorMessage}
          </Alert>
        )}
        <HStack bgColor={'white'} padding={8} mb={8} borderWidth={1} borderColor={'gray.200'}>
          <Button
            isDisabled={isSubmitting.save}
            isLoading={isSubmitting.save}
            mr={4}
            onClick={() => handleSubmit(true)}
          >
            Save Draft
          </Button>
          <Button
            isDisabled={isSubmitting.save}
            isLoading={isSubmitting.submit}
            colorScheme="blue"
            mr={4}
            onClick={() => handleSubmit()}
          >
            Submit Reconciliation{' '}
          </Button>
        </HStack>
      </Box>
      {isChangeModalOpen && (
        <SupplierChangeModal
          key={selectedItem?._id}
          item={selectedItem}
          onChange={handleChange}
          onClose={() => {
            setSelectedItem(undefined);
            setIsChangeModalOpen(false);
          }}
        />
      )}
      {isModalOpen && id && (
        <AttachDocModal
          vendorReconciliationId={id}
          onClose={() => setIsModalOpen(false)}
          onReload={() => setIsLoading(true)}
        />
      )}
      {isViewPDFModalOpen && (
        <Modal isOpen={true} onClose={() => setIsViewPDFModalOpen(false)} size="full">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>PDF Viewer</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Box pos="relative" minH="calc(100vh - 10em)">
                <iframe
                  src={pdfUrl}
                  style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                  width="100%"
                  height="100vh"
                />
              </Box>
            </ModalBody>

            <ModalFooter>
              <Button onClick={() => setIsViewPDFModalOpen(false)}>Close</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </Box>
  );
};

const mapStateToProps = (state): ConnectedProps => ({
  user: state.auth.user || {},
});

export const SupplierDetail = connect(mapStateToProps)(
  connectOrderItemModals<IndexWrappedProps>(PreDetail)
);
