import * as React from 'react';
import { Segment } from 'semantic-ui-react';

import Feathers from './../../../bootstrap/feathers';

import { CustomQuoteForm } from './CustomQuoteForm';

export interface AddProps {
  goBack?: () => void;
}

const { useState } = React;

export const Add: React.FunctionComponent<AddProps> = (props) => {
  const { goBack } = props;
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (values: any) => {
    const fileIds = values.miscUploads?.map((f) => f?.uploadLog) ?? [];

    const dimension = values.isSameDimension
      ? values.flatDimensions
      : {
          ...values.finishedDimensions,
          unitType: values.flatDimensions.unitType,
        };

    let metadata = [];

    if (values?.sourceLanguage) {
      metadata.push({
        key: 'tr_source_name',
        value: values.sourceLanguage,
      });
    }

    if (values?.destinationLanguage) {
      metadata.push({
        key: 'tr_dest_name',
        value: values.destinationLanguage,
      });
    }

    const items = [
      {
        type: values.type,
        flatDimensions: values.flatDimensions,
        finishedDimensions: dimension,
        attributes: values.attributes,
        quantity: values.quantity || 1,
        additionalInfo: values.additionalInfo,
        miscUploads: fileIds,
        addOns: values.addOns,
        addOnsOther: values.addOnsOther,
        customerReferenceName: values.customerReferenceName,
        weight: parseFloat(values.weight || 0),
        price: parseFloat(values.price || 0),
        timeMin: values?.timeMin || 0,
        timeMax: values?.timeMax || 0,
        metadata,
        initialStatus: values?.initialStatus,
      },
    ];

    const payload = {
      customer: values.customer,
      shipTime: values.shipTime,
      shipByDate: values.shipByDate,
      selectedShippingMethod: values.selectedShippingMethod,
      shipTo: values.shipTo,
      items,
    };

    if (values.selectedTimeMethod === 'any') {
      delete payload.shipByDate;
    }

    try {
      const res: any = await Feathers.service('/custom-quotes').create(payload, { query: {} });
      goBack();
    } catch (e) {
      e.code && e.code < 500
        ? setErrorMessage(e.message)
        : setErrorMessage('We are experiencing technical difficulties. Please try again');
    }
  };

  const initialValues = {
    type: 'single-page',
    isSameDimension: true,
    uploadedAttachments: [],
    flatDimensions: {
      unitType: 'inch',
    },
    quantity: 1,
    selectedShippingMethod: [],
    customer: '',
    shipTo: {
      countryCode: '',
    },
  };

  return (
    <>
      <CustomQuoteForm
        id=""
        item={initialValues}
        onSubmit={handleSubmit}
        errorMessage={errorMessage}
      />
    </>
  );
};
