import React, { useState } from 'react';
import { Box, Button } from '@chakra-ui/react';
// import { Form, Segment, Modal, Button, Message } from 'semantic-ui-react';
// import { AgGridReact } from "ag-grid-react";
// import { ColumnApi, GridApi } from "ag-grid";

import { Link, RouteComponentProps, useHistory } from 'react-router-dom';
import { PageHeaderRow } from '@inkcloud/components';
import { ReportInputs } from '../ReportInputForm';
import Feathers from '../../../bootstrap/feathers';
import FilterForm from '../FilterForm2';

function loadData(values, reportKey = 'sales-report', reportName = 'Sales Report') {
  const params: any = { query: {} };

  return Feathers.service('/reports/v3/results').create(
    {
      query: {
        start: values.start,
        end: values.end,
        product: values.products,
      },
      reportKey,
      reportName,
    },
    params
  );
}

export default function Sales() {
  const history = useHistory();

  const isLight = history.location.pathname.includes('light');

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [values, setValues] = useState({ start: null, end: null, microsites: [] });

  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async () => {
    setErrorMessage('');
    try {
      setIsSubmitting(true);
      const res = await loadData(values, 'sales-report', 'Sales Report');

      history.push('/reports/results');
    } catch (e) {
      const errMsg =
        e?.code < 500 ? e?.message : 'We are experiencing technical difficulties. Please try again';
      setErrorMessage(errMsg);
    }
    setIsSubmitting(false);
  };

  return (
    <div>
      <PageHeaderRow header={`Sales ${isLight ? 'Light' : ''}`} subheader="Reports">
        <Button onClick={() => history.goBack()}>Back</Button>
      </PageHeaderRow>

      <Box p={3} borderWidth={1} borderRadius="md" bg="white">
        <FilterForm
          values={values}
          isSubmitting={isSubmitting}
          errorMessage={errorMessage}
          onChange={(v) => {
            setErrorMessage('');
            setValues(v);
          }}
          onSubmit={handleSubmit}
        />
      </Box>
    </div>
  );
}
