import * as React from 'react';
import { useDropzone } from 'react-dropzone';
import {
  Segment,
  Grid,
  Feed,
  Modal,
  Form,
  TextArea,
  Message,
  Button,
  Input,
  Divider,
} from 'semantic-ui-react';
import { Formik, Form as FormikForm, Field, FieldArray, ErrorMessage, FieldProps } from 'formik';

import axios from 'axios';

import * as Yup from 'yup';

import { SemanticField } from '../../common/Form';

declare var APPENV;

interface DesignJobsModalProps {
  errorMessage: string;
  title: string;
  tenantId: string;
  isOpen: boolean;
  onChangeModal: () => void;
  onSubmit: (v: any) => void;
}

const { useState, useCallback } = React;

export const DesignJobsModal: React.FunctionComponent<DesignJobsModalProps> = (props) => {
  const { errorMessage, title, tenantId, isOpen, onSubmit, onChangeModal } = props;

  const designJobSchema = Yup.object({
    content: Yup.string().required('Content must not be empty.'),
    timeSpent: Yup.number().notRequired().typeError('Must be number only.'),
  });

  const [files, setFiles] = useState([]);
  const [value, setValue] = useState({ content: '', timeSpent: '' });
  const [isUploading, setIsUploading] = useState(false);

  const [attachment, setAttachment] = useState([]);

  const onDrop = useCallback(async (acceptedFiles) => {
    setFiles(files.concat(acceptedFiles));

    const uploadedFile = acceptedFiles.map(async (f) => {
      const formData = new FormData();
      formData.append('file', f);
      formData.append('tenantId', tenantId);

      setIsUploading(true);

      const response = await axios.post(
        `${APPENV.api.connection}/design-jobs/attachment`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: localStorage.getItem('feathers-jwt'),
          },
        }
      );
      setIsUploading(false);
      return response.data;
    });

    const result = await Promise.all([...uploadedFile]);
    setAttachment(attachment.concat(...result));
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const rootProps = getRootProps() as any;

  const handleRemoveFile = (id: string) => {
    const newAttachment = attachment.filter((a: any) => a.uploadLog !== id);
    setAttachment(newAttachment);
  };

  const initialValues = {
    ...value,
    attachments: attachment && attachment.map((a: any) => a.uploadLog),
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize={attachment && true}
        validationSchema={designJobSchema}
        onSubmit={(values, { setSubmitting }) => onSubmit(values)}
      >
        {(innerProps) => {
          const {
            values,
            touched,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            setFieldValue,
            setFieldTouched,
          } = innerProps;

          return (
            <Modal onClose={() => onChangeModal()} open={isOpen} closeIcon>
              <Modal.Header>{title}</Modal.Header>
              <Modal.Content>
                {errorMessage && <Message error content={errorMessage} />}
                <Modal.Description className="ui form">
                  <FormikForm className={`ui form ${isSubmitting}`}>
                    <>
                      {setValue(values)}

                      <Form.Field>
                        <SemanticField
                          component={Form.TextArea}
                          name={'content'}
                          placeholder="Notes..."
                        />
                        <ErrorMessage name="content" />
                      </Form.Field>

                      <Form.Field>
                        {title !== 'Message Designer' && (
                          <>
                            <SemanticField
                              component={Form.Input}
                              name="timeSpent"
                              placeholder="Time Spent"
                            />
                            <ErrorMessage name="timeSpent" />
                          </>
                        )}
                      </Form.Field>

                      <Form.Field>
                        <div {...rootProps}>
                          <input {...getInputProps()} />
                          <Button
                            size={'mini'}
                            icon={'upload'}
                            content="Upload Artwork"
                            loading={isUploading}
                            primary
                            type="button"
                          />
                        </div>
                      </Form.Field>

                      <FieldArray
                        name="attachments"
                        render={(arrayHelpers) => (
                          <div>
                            {values.attachments.map((file, index) => (
                              <div key={index}>
                                {attachment?.map((a) =>
                                  a.uploadLog === file ? a.originalFilename : null
                                )}
                                <Button
                                  size="tiny"
                                  type="button"
                                  onClick={() => {
                                    arrayHelpers.remove(index);
                                    handleRemoveFile(file);
                                  }}
                                  icon={`close`}
                                  style={{ marginLeft: 8 }}
                                />
                              </div>
                            ))}
                          </div>
                        )}
                      />

                      <div style={{ textAlign: 'right' }}>
                        <Button positive type="submit" icon={'send'} content="Submit" />
                      </div>
                    </>
                  </FormikForm>
                </Modal.Description>
              </Modal.Content>
            </Modal>
          );
        }}
      </Formik>
    </>
  );
};
