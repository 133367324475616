import * as React from 'react';
import { Switch, Route, RouteComponentProps } from 'react-router-dom';

import { List } from './List';
import { Detail } from './Detail';

import feathers from '../../../bootstrap/feathers';
const { useEffect, useState } = React;

const routes: React.FunctionComponent<RouteComponentProps<{ micrositeId: string }>> = (props) => {
  const {
    match: {
      params: { micrositeId },
    },
  } = props;

  return (
    <Switch>
      <Route
        exact
        path="/settings/microsites/:micrositeId/notifications"
        render={(componentProps) => <List {...componentProps} />}
      />
      <Route
        exact
        path="/settings/microsites/:micrositeId/notifications/add"
        render={(componentProps) => <Detail {...componentProps} />}
      />
      <Route
        exact
        path="/settings/microsites/:micrositeId/notifications/:key"
        render={(componentProps) => <Detail {...componentProps} />}
      />
      <Route
        exact
        path="/settings/microsites/:micrositeId/notifications/:key/:id"
        render={(componentProps) => <Detail {...componentProps} />}
      />
    </Switch>
  );
};
export default routes;
