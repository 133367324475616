import * as React from 'react';
import {
  Button,
  Form,
  Modal,
  Popup,
  Header,
  Segment,
  Message,
  Grid,
  Dropdown,
  Radio,
  Table,
} from 'semantic-ui-react';
import { Formik, Form as FormikForm, Field, FieldArray, FieldProps, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { SemanticField } from '../../../../common/Form';
import Feathers from './../../../../bootstrap/feathers';
import { slugify2 as slugifyKey } from './../../../../common';
import { FeathersDropdown } from '@inkcloud/components';

interface MenuFormProps {
  initialValues: any;
  onSubmit: Function;
  isSubmitting?: boolean;
  errorMessage: string;
}

export const initialMapper = (values) => ({
  ...values,
  name: values?.name || '',
  key: values?.key || '',
  menuItems:
    values?.menuItems?.map((m) => ({
      ...m,
      typeOfLink: m?.page ? 'page' : 'external',
      menuItems: m?.menuItems?.map((sub) => ({
        ...sub,
        typeOfLink: sub?.page ? 'page' : 'external',
      })),
    })) || [],
});

export const submissionMapper = (values) => ({
  ...values,
  menuItems: values?.menuItems?.map((m) => ({
    ...m,
    key: m.label,
    url: (m?.typeOfLink === 'external' && m?.url) || '',
    page: (m?.typeOfLink === 'page' && m?.page) || null,
    htmlTarget: m?.htmlTarget === '_blank' ? '_blank' : '_self',
    menuItems: m.menuItems?.map((sub) => ({
      ...sub,
      key: sub.label,
      htmlTarget: sub?.htmlTarget === '_blank' ? '_blank' : '_self',
      url: (sub?.typeOfLink === 'external' && sub?.url) || '',
      page: (sub?.typeOfLink === 'page' && sub?.page) || null,
    })),
  })),
});

const targetOptions = [
  { text: 'Self', value: '_self' },
  { text: 'Open in New Browser Tab/Window', value: '_blank' },
];

const { useState, useEffect } = React;

export const MenuForm: React.FC<MenuFormProps> = (outerProps) => {
  const MenuSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    key: Yup.string()
      .required('Required')
      .test('is-not-used', `Key is already in use`, function (value) {
        if (
          outerProps.initialValues &&
          outerProps.initialValues._id &&
          outerProps.initialValues.key === value
        ) {
          return Promise.resolve(true);
        }

        return Feathers.service<any>('/store-front/content/menus')
          .find({ query: { key: value } })
          .then((results: any) => results.data.length === 0);
      }),
    menuItems: Yup.array().of(
      Yup.object().shape({
        label: Yup.string().required('Required'),
        priority: Yup.string().required('Required'),
        url: Yup.string(),
        menuItems: Yup.array().of(
          Yup.object().shape({
            label: Yup.string().required('Required'),
            priority: Yup.string().required('Required'),
            url: Yup.string(),
          })
        ),
      })
    ),
  });

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const [pages, setPages] = useState([]);

  useEffect(() => {
    const loadPages = async () => {
      try {
        const res: any = await Feathers.service('/store-front/content/pages').find({
          query: {
            $np: 1,
            $sort: { name: 1 },
            $select: ['_id', 'name', 'staticName'],
            $populate: ['category', 'product'],
          },
        });

        setPages(res);
      } catch (e) {}
    };

    loadPages();
  }, []);

  return (
    <Formik
      initialValues={initialMapper(outerProps.initialValues)}
      validationSchema={MenuSchema}
      enableReinitialize={true}
      onSubmit={(values, { setSubmitting }) => {
        outerProps.onSubmit(submissionMapper(values));
      }}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
          setFieldValue,
          setFieldTouched,
        } = props;

        const handleSlugify = (v) => setFieldValue('key', slugifyKey(v));

        const showNestedError = (subIndex: number, field: string) => {
          const errorMessage = errors?.menuItems?.[activeIndex]?.menuItems?.[subIndex]?.[field];
          return errorMessage;
        };

        // const hasSubMenuError = errors?.menuItems?.[activeIndex]?.menuItems?.length > 0

        const pageOptions = pages?.map((row) => {
          const name = row.category
            ? `Category(${row?.category?.name ?? ''})`
            : row.product
            ? `Product(${row?.product?.staticName ?? ''})`
            : '';
          return { value: row._id, text: `${name} - ${row.name}` };
        });

        return (
          <FormikForm
            className={`ui form ${isSubmitting && !outerProps.errorMessage && 'loading'}`}
          >
            <Form.Group widths="equal">
              <Form.Field>
                <SemanticField
                  size="tiny"
                  name={'name'}
                  label="Name"
                  component={Form.Input}
                  onChange={(v) => {
                    if (!values?._id) {
                      setFieldValue('key', slugifyKey(v));
                    }
                  }}
                />
                <ErrorMessage
                  render={(msg) => <Message negative size="tiny" content={msg} />}
                  name="name"
                />
              </Form.Field>
              <Form.Field>
                <SemanticField
                  size="tiny"
                  name={'key'}
                  label="Key"
                  component={Form.Input}
                  onChange={handleSlugify}
                />
                <ErrorMessage
                  render={(msg) => <Message negative size="tiny" content={msg} />}
                  name="key"
                />
              </Form.Field>
            </Form.Group>

            <FieldArray
              name="menuItems"
              render={({ move, swap, push, insert, unshift, pop, remove }) => (
                <React.Fragment>
                  {values?.menuItems?.length > 0 && (
                    <Header as="h5" attached="top">
                      Menu Items
                    </Header>
                  )}
                  {values.menuItems.map(({ label, page, priority }, index) => (
                    <Segment
                      className={`${
                        index === values?.menuItems?.length - 1 ? 'bottom' : ''
                      } attached`}
                      key={index}
                    >
                      <Form.Group inline>
                        <Form.Field>
                          <label>Type of Link</label>
                        </Form.Field>
                        <Form.Field>
                          <Form.Radio
                            label="Page"
                            size="tiny"
                            name={`menuItems.[${index}].typeOfLink`}
                            value="page"
                            checked={values.menuItems?.[index]?.typeOfLink === 'page'}
                            onChange={(e, data) => {
                              setFieldValue(data.name, data.value);
                              setFieldValue(`menuItems[${index}].url`, '');
                              setFieldValue(`menuItems[${index}].label`, '');
                            }}
                          />
                        </Form.Field>
                        <Form.Field>
                          <Form.Radio
                            label="External URL"
                            size="tiny"
                            name={`menuItems.[${index}].typeOfLink`}
                            value="external"
                            checked={values.menuItems?.[index]?.typeOfLink === 'external'}
                            onChange={(e, data) => {
                              setFieldValue(data.name, data.value);
                              setFieldValue(`menuItems[${index}].label`, '');
                              setFieldValue(`menuItems[${index}].page`, '');
                            }}
                          />
                        </Form.Field>
                      </Form.Group>
                      <Form.Group widths="equal">
                        {values.menuItems?.[index]?.typeOfLink === 'page' && (
                          <Form.Field>
                            <label>Page</label>
                            {/* <SemanticField
                          name={`menuItems[${index}].page`}
                          component={Dropdown}
                          options={pageOptions}
                          fluid selection clearable
                          label='Page'
                          search
                          onChange={(e) => setFieldValue(`menuItems[${index}].label`,
                            (pages?.find(p => p._id === e)?.name || pages?.find(p => p._id === e)?.staticName) ?? ''
                          )}
                        /> */}

                            <Field
                              name={`menuItems[${index}].page`}
                              render={({ field }: FieldProps<any>) => (
                                <Dropdown
                                  search
                                  selection
                                  clearable
                                  fluid
                                  size="tiny"
                                  options={pageOptions}
                                  {...field}
                                  onChange={(e, data) => {
                                    setFieldValue(
                                      `menuItems[${index}].label`,
                                      (pages?.find((p) => p._id === data?.value)?.name ||
                                        pages?.find((p) => p._id === data?.value)?.staticName) ??
                                        ''
                                    );

                                    field.onChange({
                                      target: { value: data.value, name: field.name },
                                    } as any);
                                  }}
                                />
                                // <FeathersDropdown
                                //   search selection clearable
                                //   fluid
                                //   serviceName='/store-front/content/pages'
                                //   query={{ $sort: { name: 1 }, $np: 1, $populate: ['category', 'product'] }}
                                //   resultMapper={(row) => {
                                //     const name = row.category ? `Category(${row.category.name})` : row.product ? `Product(${row.product.staticName})` : '';
                                //     return ({ value: row._id, text: `${name ? `${name} = ` : ''} ${row.name}` });
                                //   }}
                                //   {...field}
                                //   onChange={(e, data) => field.onChange({ target: { value: data.value, name: `menuItems[${index}].page` } } as any)}
                                // />
                              )}
                            />
                          </Form.Field>
                        )}
                        <Form.Field>
                          <label>Label</label>
                          <SemanticField
                            fluid
                            name={`menuItems[${index}].label`}
                            component={Form.Input}
                          />
                          <ErrorMessage
                            render={(msg) => <Message negative size="tiny" content={msg} />}
                            name={`menuItems[${index}].label`}
                          />
                        </Form.Field>

                        {values.menuItems?.[index]?.typeOfLink === 'external' && (
                          <Form.Field>
                            <label>URL</label>
                            <SemanticField
                              fluid
                              name={`menuItems[${index}].url`}
                              component={Form.Input}
                            />
                          </Form.Field>
                        )}

                        <Form.Field>
                          <label>Target</label>
                          <Field
                            name={`menuItems[${index}].htmlTarget`}
                            render={({ field }: FieldProps<any>) => (
                              <Dropdown
                                search
                                selection
                                clearable
                                fluid
                                options={targetOptions}
                                name={field.name}
                                onBlur={field.onBlur}
                                defaultValue={field?.value ?? targetOptions[0].value}
                                onChange={(e, data) =>
                                  field.onChange({
                                    target: { value: data.value, name: field.name },
                                  } as any)
                                }
                              />
                            )}
                          />
                        </Form.Field>

                        <Form.Field>
                          <label>Priority</label>
                          <SemanticField
                            fluid
                            name={`menuItems[${index}].priority`}
                            component={Form.Input}
                          />
                          <ErrorMessage
                            render={(msg) => <Message negative size="tiny" content={msg} />}
                            name={`menuItems[${index}].priority`}
                          />
                        </Form.Field>

                        <div style={{ padding: '0 1em' }}>
                          <label>Hidden</label>
                          <div style={{ marginTop: '.35em' }}>
                            <SemanticField
                              fluid
                              name={`menuItems[${index}].isHidden`}
                              component={Form.Checkbox}
                              toggle
                            />
                          </div>
                        </div>
                        {values?.menuItems?.[index]?.menuItems?.length < 1 && (
                          <div>
                            <label>&nbsp;</label>
                            <Popup
                              content="Add sub menu items"
                              position="top right"
                              trigger={
                                <Button
                                  icon="plus"
                                  type="button"
                                  style={{ marginTop: '.35rem' }}
                                  onClick={() => {
                                    setIsOpenModal(true);
                                    setActiveIndex(index);
                                  }}
                                />
                              }
                            />
                          </div>
                        )}

                        {isOpenModal && activeIndex === index && (
                          <Modal
                            open={isOpenModal}
                            size="large"
                            // closeOnDimmerClick={!hasSubMenuError}
                            // closeOnEscape={!hasSubMenuError}
                            onClose={() => {
                              // if (!hasSubMenuError) {
                              //   setIsOpenModal(false)
                              //   setActiveIndex(null)
                              // }
                              setIsOpenModal(false);
                              setActiveIndex(null);
                            }}
                            closeIcon
                          >
                            <Modal.Header></Modal.Header>
                            <Modal.Content>
                              <FieldArray
                                name={`menuItems.${index}.menuItems`}
                                render={(arrayHelpers) => (
                                  <div className="ui form">
                                    {values?.menuItems?.[index]?.menuItems?.map(
                                      (option: string, idx: number) => (
                                        <div key={idx}>
                                          <Form.Group inline>
                                            <Form.Field>
                                              <label>Type of Link</label>
                                            </Form.Field>
                                            <Form.Field>
                                              <Radio
                                                label="Page"
                                                name={`menuItems[${index}].menuItems.${idx}.typeOfLink`}
                                                value="page"
                                                checked={
                                                  values.menuItems?.[index]?.menuItems?.[idx]
                                                    ?.typeOfLink === 'page'
                                                }
                                                onChange={(e, data) => {
                                                  setFieldValue(data.name, data.value);
                                                  setFieldValue(
                                                    `menuItems[${index}].menuItems.${idx}.url`,
                                                    ''
                                                  );
                                                  setFieldValue(
                                                    `menuItems[${index}].menuItems.${idx}.label`,
                                                    ''
                                                  );
                                                }}
                                              />
                                            </Form.Field>
                                            <Form.Field>
                                              <Radio
                                                label="External URL"
                                                name={`menuItems[${index}].menuItems.${idx}.typeOfLink`}
                                                value="external"
                                                checked={
                                                  values.menuItems?.[index]?.menuItems?.[idx]
                                                    ?.typeOfLink === 'external'
                                                }
                                                onChange={(e, data) => {
                                                  setFieldValue(data.name, data.value);
                                                  setFieldValue(
                                                    `menuItems[${index}].menuItems.${idx}.label`,
                                                    ''
                                                  );
                                                  setFieldValue(
                                                    `menuItems[${index}].menuItems.${idx}.page`,
                                                    ''
                                                  );
                                                }}
                                              />
                                            </Form.Field>
                                          </Form.Group>
                                          <Form.Group widths="equal">
                                            {values.menuItems[index].menuItems?.[idx]
                                              ?.typeOfLink === 'page' && (
                                              <Form.Field>
                                                <label>Page</label>

                                                <Field
                                                  name={`menuItems[${index}].menuItems.${idx}.page`}
                                                  render={({ field }: FieldProps<any>) => (
                                                    <Dropdown
                                                      search
                                                      selection
                                                      clearable
                                                      fluid
                                                      options={pageOptions}
                                                      {...field}
                                                      onChange={(e, data) => {
                                                        setFieldValue(
                                                          `menuItems[${index}].menuItems.${idx}.label`,
                                                          (pages?.find((p) => p._id === data?.value)
                                                            ?.name ||
                                                            pages?.find(
                                                              (p) => p._id === data?.value
                                                            )?.staticName) ??
                                                            ''
                                                        );

                                                        field.onChange({
                                                          target: {
                                                            value: data.value,
                                                            name: field.name,
                                                          },
                                                        } as any);
                                                      }}
                                                    />
                                                    // <FeathersDropdown
                                                    //   search selection clearable
                                                    //   fluid
                                                    //   serviceName='/store-front/content/pages'
                                                    //   query={{ $sort: { name: 1 }, $np: 1, $populate: ['category', 'product'] }}
                                                    //   resultMapper={(row) => {
                                                    //     const name = row.category ? `Category(${row.category.name})` : row.product ? `Product(${row.product.staticName})` : '';
                                                    //     return ({ value: row._id, text: `${name ? `${name} = ` : ''} ${row.name}` });
                                                    //   }}
                                                    //   {...field}
                                                    //   onChange={(e, data) => field.onChange({ target: { value: data.value, name: field.name } } as any)}
                                                    // />
                                                  )}
                                                />
                                              </Form.Field>
                                            )}
                                            <Form.Field>
                                              <label>Label</label>
                                              <SemanticField
                                                name={`menuItems[${index}].menuItems.${idx}.label`}
                                                component={Form.Input}
                                              />
                                              {showNestedError(idx, 'label') && (
                                                <Message
                                                  negative
                                                  size="tiny"
                                                  content={showNestedError(idx, 'label')}
                                                />
                                              )}
                                            </Form.Field>

                                            {values.menuItems[index].menuItems?.[idx]
                                              ?.typeOfLink === 'external' && (
                                              <Form.Field>
                                                <label>URL</label>
                                                <SemanticField
                                                  fluid
                                                  name={`menuItems[${index}].menuItems.${idx}.url`}
                                                  component={Form.Input}
                                                />
                                              </Form.Field>
                                            )}

                                            <Form.Field>
                                              <label>Target</label>
                                              <Field
                                                name={`menuItems[${index}].menuItems.${idx}.htmlTarget`}
                                                render={({ field }: FieldProps<any>) => (
                                                  <Dropdown
                                                    search
                                                    selection
                                                    clearable
                                                    fluid
                                                    options={targetOptions}
                                                    name={field.name}
                                                    onBlur={field.onBlur}
                                                    defaultValue={
                                                      field?.value ?? targetOptions[0].value
                                                    }
                                                    onChange={(e, data) =>
                                                      field.onChange({
                                                        target: {
                                                          value: data.value,
                                                          name: field.name,
                                                        },
                                                      } as any)
                                                    }
                                                  />
                                                )}
                                              />
                                            </Form.Field>

                                            <Form.Field>
                                              <label>Priority</label>
                                              <SemanticField
                                                name={`menuItems[${index}].menuItems.${idx}.priority`}
                                                component={Form.Input}
                                              />
                                              {showNestedError(idx, 'priority') && (
                                                <Message
                                                  negative
                                                  size="tiny"
                                                  content={showNestedError(idx, 'priority')}
                                                />
                                              )}
                                            </Form.Field>

                                            <div style={{ padding: '0 1em' }}>
                                              <label>Hidden</label>
                                              <div style={{ marginTop: '.35em' }}>
                                                <SemanticField
                                                  name={`menuItems[${index}].menuItems.${idx}.isHidden`}
                                                  component={Form.Checkbox}
                                                  toggle
                                                />
                                              </div>
                                            </div>

                                            <div>
                                              <div className="field">
                                                <label>&nbsp;</label>
                                                <Button
                                                  type="button"
                                                  icon="remove"
                                                  style={{ marginTop: '.35rem' }}
                                                  onClick={() => arrayHelpers.remove(idx)}
                                                />
                                              </div>
                                            </div>
                                          </Form.Group>
                                        </div>
                                      )
                                    )}
                                    <Button
                                      type="button"
                                      size="tiny"
                                      onClick={() =>
                                        arrayHelpers.push({
                                          label: '',
                                          priority: '',
                                          typeOfLink: 'page',
                                        })
                                      }
                                      content="Add Item"
                                    />
                                  </div>
                                )}
                              />
                            </Modal.Content>
                            <Modal.Actions>
                              <Button
                                type="button"
                                size="tiny"
                                content="Done"
                                // disabled={hasSubMenuError}
                                onClick={() => {
                                  setIsOpenModal(false);
                                  setActiveIndex(null);
                                }}
                              />
                            </Modal.Actions>
                          </Modal>
                        )}

                        <div>
                          <label>&nbsp;</label>
                          <Button
                            icon="remove"
                            type="button"
                            style={{ marginTop: '.35rem' }}
                            onClick={() => remove(index)}
                          />
                        </div>
                      </Form.Group>
                      {values?.menuItems?.[index]?.menuItems?.length > 0 && (
                        <>
                          <Segment.Group size="tiny">
                            <Segment>
                              <Grid
                                columns={2}
                                padded="vertically"
                                style={{ alignItems: 'center' }}
                              >
                                <Grid.Column style={{ paddingTop: 0, paddingBottom: 0 }}>
                                  <strong>Sub Menu Items</strong>
                                </Grid.Column>
                                <Grid.Column
                                  textAlign="right"
                                  style={{ paddingTop: 0, paddingBottom: 0 }}
                                >
                                  <Popup
                                    content="Edit sub menu items"
                                    position="top right"
                                    trigger={
                                      <Button
                                        icon="edit"
                                        type="button"
                                        size="tiny"
                                        onClick={() => {
                                          setIsOpenModal(true);
                                          setActiveIndex(index);
                                        }}
                                      />
                                    }
                                  />
                                </Grid.Column>
                              </Grid>
                            </Segment>
                            <Segment secondary>
                              <Table>
                                <Table.Header>
                                  <Table.Row>
                                    <Table.HeaderCell>Label</Table.HeaderCell>
                                    <Table.HeaderCell>Hidden</Table.HeaderCell>
                                    <Table.HeaderCell>Priority</Table.HeaderCell>
                                  </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                  {values.menuItems[index].menuItems.map((mi) => (
                                    <Table.Row key={mi._id}>
                                      <Table.Cell>{mi.label}</Table.Cell>
                                      <Table.Cell>
                                        {mi.isHidden ? 'It is hidden' : 'Not hidden'}
                                      </Table.Cell>
                                      <Table.Cell>{mi.priority}</Table.Cell>
                                    </Table.Row>
                                  ))}
                                </Table.Body>
                              </Table>
                            </Segment>
                          </Segment.Group>
                        </>
                      )}
                    </Segment>
                  ))}

                  <Button
                    type="button"
                    content="Add menu item"
                    size={'mini'}
                    onClick={() => {
                      push({
                        label: '',
                        page: '',
                        priority: '',
                        typeOfLink: 'page',
                      });
                    }}
                  />
                </React.Fragment>
              )}
            />
            <br />
            <br />
            <Button primary type="submit" disabled={isSubmitting && !outerProps.errorMessage}>
              Save
            </Button>
          </FormikForm>
        );
      }}
    </Formik>
  );
};
