import * as React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import {
  Button,
  Box,
  IconButton,
  Tooltip,
  Badge,
  Icon,
  Text,
  TabList,
  Tab,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { FormattedDate, FormattedTime } from 'react-intl';
import { WarningTwoIcon } from '@chakra-ui/icons';
import { FaEdit } from 'react-icons/fa';

import {
  useList,
  ListPage,
  basicQueryMap,
  generateOperatorOptions,
  Operator,
  PaginatorWrapper,
  EmptyWrapper,
  ListTable,
  IListTableProps,
  GenericFilter,
} from '@inkcloud/components';

import { Can } from '../../../common/permissions';
import { statusToColor, TruncatedText } from './helpers';
import getFilterDefinition from './FilterForm';

interface IArchivedProps {}

const serviceName = '/rfqs';
const pathPrefix = '/rfqs/archived';
// const paginatorPrefix = '/rfqs/archived';

const filterQueryMap = (values: any, baseQuery: any = {}) => {
  let filterRows = basicQueryMap(values, {});

  const { requestor, assignedTo, customer, status, jobNumber } = filterRows;

  if (jobNumber) {
    filterRows = {
      ...filterRows,
      'estimate.externalJobNumber': jobNumber,
    };

    delete filterRows.jobNumber;
  }

  filterRows = {
    ...filterRows,
    customer: customer?.$elemMatch.value,
    status: status?.$elemMatch.value ?? baseQuery.status,
    assignedTo: assignedTo?.$elemMatch.value,
    requestor: requestor?.$elemMatch.value,
  };

  return filterRows;
};

const Archived: React.FunctionComponent<IArchivedProps & RouteComponentProps<any>> = (props) => {
  const {
    match: { params },
    location,
    history,
  } = props;

  const useListValues = useList({
    feathers: [
      'find',
      serviceName,
      [
        {
          query: {
            // 'estimate.createdOrder': { $exists: false },
            status: { $in: ['estimate-declined', 'order-awarded'] },
            // name: 'adfasfsf',
            $populate: [
              'assignedTo',
              'requestor',
              'customer',
              'micrositeUser',
              'estimate.createdOrder',
              { path: 'tenant', select: 'displayBusinessName' },
              { path: 'createdOrder', select: 'productionStatus' },
            ],
          },
        },
      ],
    ],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  const {
    state,
    reload,
    handleDelete,
    selectedItems,
    clearAll,
    setChecked,
    someSelected,
    toggleAll,
    filter,
    setFilter,
    sort,
    handleSort,
  } = useListValues;
  const { queryResult, isLoading: isStateLoading } = state as any;
  const resolvedQueryResult = queryResult?.data ? queryResult.data : queryResult;

  const tableDefinition = [
    {
      key: 'humanId',
      label: 'ID',
      sortable: true,
      cell: (v, r) => v,
    },
    {
      key: 'createdAt',
      label: 'Date',
      sortable: true,
      cell: (v) => (
        <Tooltip hasArrow aria-label="time" label={<FormattedTime value={v} />}>
          <Box as="span">
            <FormattedDate value={v} />
          </Box>
        </Tooltip>
      ),
    },
    {
      key: '',
      label: 'Customer',
      sortable: true,
      cell: (v, r) => {
        if (r?.micrositeUser) {
          return r?.micrositeUser?.companyName
            ? r?.micrositeUser?.companyName
            : `${r?.micrositeUser?.firstName ?? ''} ${r?.micrositeUser?.lastName ?? ''}`;
        }

        const name =
          r?.customer?.organizationName ??
          `${r?.customer?.primaryContact?.firstName ?? ''} ${
            r?.customer?.primaryContact?.lastName ?? ''
          }`;

        // return name
        return <TruncatedText text={name} />;
      },
    },
    {
      key: 'assignedTo',
      label: 'Assignee',
      sortable: true,
      cell: (v, r) => (
        <TruncatedText
          text={`${r?.assignedTo?.firstName ?? ''} ${r?.assignedTo?.lastName ?? ''}`}
        />
      ),
    },
    {
      key: 'requestor',
      label: 'Requestor',
      sortable: true,
      cell: (v, r) => (
        <TruncatedText text={`${r?.requestor?.firstName ?? ''} ${r?.requestor?.lastName ?? ''}`} />
      ),
    },
    {
      key: 'projectName',
      label: 'Project Name',
      sortable: true,
      cell: (v, r) => <TruncatedText text={v} />,
    },
    {
      key: 'jobNumber',
      label: 'Job #',
      sortable: true,
      cell: (v, r) => <TruncatedText limit={10} text={`${r.estimate?.externalJobNumber ?? ''}`} />,
    },

    {
      key: '',
      label: 'Order Status',
      sortable: true,
      cell: (v, r) =>
        r?.estimate?.createdOrder?.humanId && (
          <>
            <Link to={`/orders/${r?.estimate?.createdOrder?._id}`}>
              {/* {r?.estimate?.createdOrder?.humanId} */}
              <Badge variant="outline">{r?.estimate?.createdOrder?.productionStatus ?? ''}</Badge>
            </Link>
            {r?.estimate?.createdOrder?.supplierDocsRequireReview && (
              <Tooltip label="Supplier has uploaded documents. Click to Review">
                <Link to={`${pathPrefix}/${r._id}`}>
                  <Icon ml={2} boxSize={4} as={WarningTwoIcon} color="red.500" />
                </Link>
              </Tooltip>
            )}
          </>
        ),
    },
    {
      key: 'status',
      label: 'RFQ Status',
      sortable: true,
      cell: (v) => (
        <Badge colorScheme={statusToColor(v)} variant="outline">
          {v}
        </Badge>
      ),
    },

    {
      key: '',
      label: '',
      sortable: false,
      cell: (v, r) => (
        <Can do={'read'} on={'dashboard.rfqs'}>
          <Tooltip label="View RFQ">
            <Link to={`/rfqs/${r._id}`}>
              <IconButton colorScheme="gray" aria-label="Edit" icon={<FaEdit />} />
            </Link>
          </Tooltip>
        </Can>
      ),
      textAlign: 'right',
    },
  ];

  return (
    <Box bgColor={'white'}>
      {/* <EmptyWrapper queryResult={resolvedQueryResult} isStateLoading={isStateLoading}>
        <Segment >
          <PaginatorWrapper.Top filter={filter} reload={reload} data={(queryResult as any)} pathPrefix={`${paginatorPrefix}`} />
          <ListTable
            data={resolvedQueryResult}
            definition={tableDefinition}
            selectedItems={selectedItems as any[]}
            setChecked={setChecked}
            sort={sort}
            sortClickFunction={handleSort}
            toggleAll={toggleAll as any}
            withCheckboxes={true}
          />
          <PaginatorWrapper.Bottom reload={reload} data={(queryResult as any)} pathPrefix={`${paginatorPrefix}`}>
             {someSelected && <Button primary type="button" content={selectedItemsBtnLabel || "Delete"} onClick={handleSelectedItems ? () => handleSelectedItems(selectedItems) : handleDelete} />}
          </PaginatorWrapper.Bottom>
         </Segment>
      </EmptyWrapper> */}

      <ListPage
        header=""
        subheader=""
        useListValues={useListValues}
        history={history}
        pathPrefix={pathPrefix}
        tableDefintion={tableDefinition}
        withCheckboxes={true}
        getFilterDefinition={getFilterDefinition}
      />
    </Box>
  );
};

export default Archived;
