import * as React from 'react';
import { Button, Form, Message } from 'semantic-ui-react';
import { Formik, Form as FormikForm, Field, FieldProps, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { SemanticField } from '../../../common/Form';

import { usStateProvinces } from './../../../common';

interface SalesTaxFormProps {
  initialValues: any;
  onSubmit: Function;
  isSubmitting?: boolean;
  errorMessage: string;
}

const gateways = [
  { text: 'Simple Tax', value: 'simple' },
  { text: 'Tax Cloud', value: 'taxCloud' },
];

export const SalesTaxForm: React.FC<SalesTaxFormProps> = (props) => {
  const { errorMessage } = props;

  const SalesTaxSchema = Yup.object().shape({
    selectedAdapter: Yup.string().required('Required'),
  });

  return (
    <Formik
      initialValues={props.initialValues}
      enableReinitialize={true}
      validationSchema={SalesTaxSchema}
      onSubmit={(values, { setSubmitting }) => {
        props.onSubmit(values);
      }}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
          setFieldValue,
          setFieldTouched,
        } = props;

        return (
          <FormikForm className={`ui form ${props.isSubmitting && !errorMessage && 'loading'}`}>
            <Form.Group widths="equal">
              <Form.Field>
                <SemanticField
                  name={'selectedAdapter'}
                  component={Form.Dropdown}
                  selection
                  fluid
                  options={gateways}
                  placeholder="Select your sales tax adapter"
                />
                <ErrorMessage
                  render={(msg) => <Message negative size="tiny" content={msg} />}
                  name="selectedAdapter"
                />
              </Form.Field>
            </Form.Group>

            {values.selectedAdapter === 'taxCloud' && (
              <Form.Group widths="equal">
                <Form.Field>
                  <SemanticField
                    name={'adapters.taxCloud.apiId'}
                    label="API ID"
                    component={Form.Input}
                  />
                </Form.Field>
                <Form.Field>
                  <SemanticField
                    name={'adapters.taxCloud.apiKey'}
                    label="API Key"
                    component={Form.Input}
                  />
                </Form.Field>
              </Form.Group>
            )}
            {values.selectedAdapter === 'simple' && (
              <>
                <Form.Group widths="equal">
                  <Form.Field>
                    <SemanticField
                      name="adapters.simple.state"
                      component={Form.Dropdown}
                      options={usStateProvinces}
                      as={Form.Dropdown}
                      fluid
                      selection
                      label="State Province"
                      placeholder="State Province..."
                    />
                  </Form.Field>
                  <Form.Field>
                    <SemanticField
                      name={'adapters.simple.rate'}
                      label="Sales Tax Rate"
                      component={Form.Input}
                    />
                  </Form.Field>
                </Form.Group>
              </>
            )}

            {errorMessage && <Message error content={errorMessage} />}

            <Button primary type="submit">
              Save
            </Button>
          </FormikForm>
        );
      }}
    </Formik>
  );
};
