import * as React from 'react';
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';

import type { ModelTypes } from '@inkcloud/icapi-types';

// export interface IAddressProps extends Pick<ModelTypes.Rfq, 'billTo' | 'shipTo'> {
//   company?: string;
//   name: string;
//   careOf?: string;
//   address1: string;
//   address2?: string;
//   city: string;
//   stateProvince: string;
//   postalCode: string;
//   countryCode: string;
// }

export type IAddressProps = NonNullable<Pick<ModelTypes.Rfq, 'billTo' | 'shipTo'>>;

interface IBillToShipToProps {
  billTo: IAddressProps['billTo'] & { companyName?: string };
  shipTo: IAddressProps['shipTo'];
}

const BillToShipTo: React.FunctionComponent<IBillToShipToProps> = (props) => {
  // console.log('props', props)

  const { billTo, shipTo } = props;

  // <div>{data.shipment.shipToCity} {data.shipment.shipToStateProvince}, {data.shipment.shipToPostalCode}</div>
  return (
    <Tabs mt="6" size="sm" variant="enclosed-colored">
      <TabList>
        {/* <Tab>Bill To</Tab> */}
        <Tab>Ship To</Tab>
      </TabList>
      <TabPanels>
        {/* <TabPanel>
          {billTo?.name ? <div>{billTo.name}</div> : null}
          {billTo?.careOf ? <div>{billTo.careOf}</div> : null}
          {billTo?.address1 ? <div>{billTo.address1}</div> : null}
          {billTo?.address2 ? <div>{billTo.address2}</div> : null}
          <div>
            {billTo?.city} {billTo?.stateProvince} {billTo?.postalCode}
          </div>
          <div>{billTo?.countryCode}</div>
        </TabPanel> */}
        <TabPanel>
          {shipTo?.name ? <div>{shipTo.name}</div> : null}
          {shipTo?.careOf ? <div>{shipTo.careOf}</div> : null}
          {shipTo?.address1 ? <div>{shipTo.address1}</div> : null}
          {shipTo?.address2 ? <div>{shipTo.address2}</div> : null}
          <div>
            {shipTo?.city} {shipTo?.stateProvince} {shipTo?.postalCode}
          </div>
          <div>{shipTo?.countryCode}</div>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default BillToShipTo;
