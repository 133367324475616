import * as React from 'react';
import { Button, Modal, ModalProps, Table, Label, Dropdown, Tab } from 'semantic-ui-react';
import { useFeathers, useDebounce } from '@inkcloud/components';
import Feathers from '../../../bootstrap/feathers';

import { FeathersDropdown, EmptyWrapper } from '@inkcloud/components';

import { FilterForm } from '../../customers/FilterForm';
import { filterQueryMap } from '../../customers/List';

import { FilterForm as FilterFormMS } from './../../microsites/customers/FilterForm';
import { filterQueryMap as filterQueryMapMS } from './../../microsites/customers/List';

interface ISelectCustomerModalProps {
  handleSubmit: (v: string, m?: string, mUser?: string) => void;
  handleClose: ModalProps['onClose'] | any;
  open: boolean;
  microsites: any[];
}
const { useState, useEffect } = React;

const serviceName = 'customers';

export const SelectCustomerModal: React.FunctionComponent<ISelectCustomerModalProps> = (props) => {
  const { open, handleClose, handleSubmit, microsites } = props;

  const [filter, setFilter] = useState({});
  const debouncedFilter = useDebounce(filter, 150);
  // const [state, reload] = useFeathers('find', 'customers', [{ query: { ...filterQueryMap(debouncedFilter), $limit: 100 } }], true);

  const [selectedMicrosite, setSelectedMicrosite] = useState('');

  const [filterMS, setFilterMS] = useState({});
  const debouncedFilterMS = useDebounce(filterMS, 150);
  // const [stateMS, reloadMS] = useFeathers('find', '/microsites/customers', [{
  //   query: {
  //     ...filterQueryMap(debouncedFilterMS),
  //     microsite: selectedMicrosite,
  //     $populate: ['groups'],
  //     $limit: 100
  //   }
  // }], true);

  const [customers, setCustomers] = useState([]);
  const [customersMS, setCustomersMS] = useState([]);

  const [isLoadingCustomer, setIsLoadingCustomer] = useState(true);
  const [isLoadingCustomerMS, setIsLoadingCustomerMS] = useState(true);

  useEffect(() => {
    const loadCustomer = async () => {
      try {
        const res: any = await Feathers.service('/customers').find({
          query: {
            ...filterQueryMap(debouncedFilter),
            $limit: 100,
          },
        });
        setIsLoadingCustomer(false);
        setCustomers(res);
      } catch (e) {
        setIsLoadingCustomer(false);
      }
    };

    loadCustomer();
  }, [debouncedFilter]);

  useEffect(() => {
    const loadMicrosites = async () => {
      try {
        setIsLoadingCustomerMS(true);
        const res: any = await Feathers.service('/microsites/customers').find({
          query: {
            ...filterQueryMapMS(debouncedFilterMS),
            microsite: selectedMicrosite,
            $populate: ['groups', 'microsite'],
            $sort: { firstName: 1 },
            $limit: 100,
          },
        });

        setIsLoadingCustomerMS(false);
        setCustomersMS(res);
      } catch (e) {
        setIsLoadingCustomerMS(false);
      }
    };

    if (selectedMicrosite) {
      loadMicrosites();
    }
  }, [selectedMicrosite, debouncedFilterMS]);

  const handleMicrositeChange = (value: string) => setSelectedMicrosite(value);

  const customerRender = (customers) => {
    return (
      <>
        <FilterForm onChange={setFilter} onReset={() => setFilter({})} value={filter} />
        <EmptyWrapper isStateLoading={isLoadingCustomer} queryResult={customers && customers.data}>
          <Table size="small">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>ID</Table.HeaderCell>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>City State</Table.HeaderCell>
                <Table.HeaderCell>Email</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {customers &&
                customers.data &&
                customers.data.length > 0 &&
                customers.data.map((c) => (
                  <Table.Row key={c._id}>
                    <Table.Cell>{c.humanId}</Table.Cell>
                    <Table.Cell>
                      <div>{c.organizationName}</div>
                      <div>
                        {c.primaryContact
                          ? `${c.primaryContact.firstName} ${c.primaryContact.lastName}`
                          : ''}
                      </div>
                    </Table.Cell>
                    {/* <Table.Cell>{c.primaryContact ? `${c.primaryContact.firstName} ${c.primaryContact.lastName}` : '' }</Table.Cell> */}
                    <Table.Cell>
                      {c.city}, {c.stateProvince}
                    </Table.Cell>
                    <Table.Cell>{c.email}</Table.Cell>
                    <Table.Cell>
                      <Button size="mini" icon="checkmark" onClick={() => handleSubmit(c._id)} />
                    </Table.Cell>
                  </Table.Row>
                ))}
            </Table.Body>
          </Table>
        </EmptyWrapper>
      </>
    );
  };

  const customerMSRender = (customersMS) => {
    return (
      <>
        <FilterFormMS
          microsite={selectedMicrosite}
          value={filterMS}
          onChange={(v) => setFilterMS(v)}
          onReset={() => setFilterMS({})}
        />
        <EmptyWrapper
          isStateLoading={isLoadingCustomerMS}
          queryResult={customersMS && customersMS.data}
        >
          <Table size="small">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Email</Table.HeaderCell>
                <Table.HeaderCell>Groups</Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {customersMS &&
                customersMS.data &&
                customersMS.data.length > 0 &&
                (customersMS as any).data.map((r) => (
                  <Table.Row key={r._id}>
                    <Table.Cell>
                      {r.firstName} {r.lastName}
                    </Table.Cell>
                    <Table.Cell>{r.email}</Table.Cell>
                    <Table.Cell>
                      {r.groups &&
                        r.groups.map((g, idx) => (
                          <Label size="tiny" key={idx}>
                            {g.name}
                          </Label>
                        ))}
                    </Table.Cell>

                    <Table.Cell textAlign="right">
                      <Button
                        size="mini"
                        icon="checkmark"
                        onClick={() =>
                          handleSubmit(
                            r.microsite ? r.microsite.customer : '',
                            selectedMicrosite,
                            r._id
                          )
                        }
                      />
                    </Table.Cell>
                  </Table.Row>
                ))}
            </Table.Body>
          </Table>
        </EmptyWrapper>
      </>
    );
  };

  // const customers = state.queryResult as QueryResult
  // const customersMS = stateMS.queryResult as QueryResult

  const panes = [
    { menuItem: 'Customers', render: () => <Tab.Pane>{customerRender(customers)}</Tab.Pane> },
    {
      menuItem: 'Microsite Customers',
      render: () => (
        <Tab.Pane>
          <Dropdown
            fluid
            search
            selection
            clearable
            placeholder="Microsites"
            options={
              microsites &&
              microsites.length > 0 &&
              microsites.map((r) => ({ value: r._id, text: r.name }))
            }
            value={selectedMicrosite}
            onChange={(e, data: any) => handleMicrositeChange(data.value)}
          />
          {selectedMicrosite && customerMSRender(customersMS)}
        </Tab.Pane>
      ),
    },
  ];

  return (
    <Modal open={open} onClose={handleClose} closeIcon>
      <Modal.Header>Select Customer</Modal.Header>
      <Modal.Content>
        {microsites && microsites.length > 0 ? <Tab panes={panes} /> : customerRender(customers)}
      </Modal.Content>
    </Modal>
  );
};
