import React, { forwardRef, useState, useEffect, useRef, useImperativeHandle } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Text,
  Alert,
  Box,
  Input,
  Stack,
  Select,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Button,
} from '@chakra-ui/react';
import countries from 'world-countries';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { humanize } from 'underscore.string';
import type { ModelTypes } from '@inkcloud/icapi-types';

import { countryStateProvinces } from '../../../../common';

import AddressBookModal from './AddressBook';

type BillToType = NonNullable<ModelTypes.Rfq['billTo']> & { companyName?: string };
type ShipToType = NonNullable<ModelTypes.Rfq['shipTo']>;

interface IEditableBillToShipToProps {
  billTo?: BillToType;
  shipTo?: ShipToType;
  onChange: (value: { billTo?: BillToType; shipTo?: ShipToType }) => void;
  isSubmitting?: boolean;
  tabIndex?: number;
}

const schema = Yup.object()
  .shape({
    billTo: Yup.object().shape({
      // name: Yup.string().required('Name is equired').trim(),
      // countryCode: Yup.string().required('Country is required').trim(),
      // address1: Yup.string().required('Address 1 is required').trim(),
      // city: Yup.string().required('City is required').trim(),
      // stateProvince: Yup.string().required('State/Province is required').trim(),
      // postalCode: Yup.string().required('Postal code is equired').trim(),
    }),
    shipTo: Yup.object().shape({
      name: Yup.string().required('Name is equired').trim(),
      countryCode: Yup.string().required('Country is required').trim(),
      address1: Yup.string().required('Address 1 is required').trim(),
      city: Yup.string().required('City is required').trim(),
      stateProvince: Yup.string().required('State/Province is required').trim(),
      postalCode: Yup.string().required('Postal code is equired').trim(),
    }),
  })
  .required();

const countryInclude = ['US', 'CA', 'AU'];

const EditableBillToShipTo = forwardRef((props: IEditableBillToShipToProps, ref) => {
  const { billTo, shipTo, tabIndex: propTabIndex = 0, isSubmitting = false, onChange } = props;

  const tenant = useSelector(
    ({ globals }: { globals: { tenant: { _id: string; locale: string; countryCode: string } } }) =>
      globals.tenant
  );

  const [isOpenModal, setIsOpenModal] = useState(false);

  const [selectedAddress, setSelectedAddress] = useState<ModelTypes.AddressBook>({});

  // console.log('selectedAddress', selectedAddress)

  let filteredCountries = countries;
  if (tenant._id === '633f78a0cbbf6916101874f2') {
    filteredCountries = countries.filter(
      (country) => country.cca2 === 'US' || country.cca2 === 'CA' || country.cca2 === 'MX'
    );
  }

  const {
    register,
    watch,
    handleSubmit,
    reset,
    getValues,
    formState: { errors, submitCount, isValid },
  } = useForm<Omit<IEditableBillToShipToProps, 'onChange'>>({
    defaultValues: {
      billTo,
      shipTo: {
        ...shipTo,
        // countryCode: shipTo?.countryCode && shipTo?.countryCode !== ''  ? shipTo?.countryCode : tenant.countryCode,
        countryCode: 'US',
      },
    },
    resolver: yupResolver(schema),
  });

  // eslint-disable-next-line no-undef
  const submitRef = useRef<HTMLFormElement>(null);

  const [tabIndex, setTabIndex] = useState(() => 1);

  useEffect(() => {
    reset({
      billTo: {
        ...(billTo || {}),
        countryCode: billTo?.countryCode ?? tenant.countryCode,
      },
      shipTo: {
        ...(shipTo || {}),
        countryCode:
          shipTo?.countryCode && shipTo?.countryCode !== ''
            ? shipTo?.countryCode
            : tenant.countryCode,
      },
    });

    if (selectedAddress?.name) {
      reset({
        shipTo: selectedAddress,
      });
    }
  }, [tenant.countryCode, selectedAddress?.name]);

  useImperativeHandle(ref, () => ({
    handleSave: () => submitRef?.current?.requestSubmit(),
  }));

  useEffect(() => {
    if (!errors?.billTo && errors?.shipTo) {
      setTabIndex(1);
    } else if (errors?.billTo) {
      setTabIndex(0);
    }
  }, [submitCount]);

  const watchBilltoCountry = watch('billTo.countryCode') as string;
  const watchShiptoCountry = watch('shipTo.countryCode') as string;

  const handleSave: SubmitHandler<Omit<IEditableBillToShipToProps, 'onChange'>> = (values, e) => {
    e?.preventDefault();
    onChange(values);
  };

  const errMsg = Object.keys(errors)?.length ? (
    <Alert borderRadius={2} status="error" mb={3} alignItems="flex-start" flexDirection="column">
      {Object.keys(errors)?.map((e, index) => (
        <Box key={index}>
          <Text fontWeight="bold">{humanize(e)}: </Text>
          {Object.values(errors[e]).map((m: any, idx) => (
            <Text key={idx}>{m?.message}</Text>
          ))}
        </Box>
      ))}
    </Alert>
  ) : null;

  return (
    <>
      <form ref={submitRef} onSubmit={handleSubmit(handleSave)}>
        <Tabs index={tabIndex} onChange={(index) => setTabIndex(index)}>
          {/* <TabList>
            <Tab>Ship To</Tab>
          </TabList> */}
          <Stack justify="end">
            <Button size="sm" colorScheme="blue" onClick={() => setIsOpenModal(true)}>
              Address Book
            </Button>
          </Stack>
          <TabPanels>
            <TabPanel key="billTo">
              <Stack spacing={7} mb={3} direction="row">
                <FormControl isInvalid={!!errors.billTo?.name}>
                  <FormLabel>Company Name</FormLabel>
                  <Input type="text" {...register('billTo.name')} />
                  {errors.billTo?.name && (
                    <FormErrorMessage>{errors.billTo?.name.message}</FormErrorMessage>
                  )}
                </FormControl>
              </Stack>
              <Stack spacing={7} mb={3} direction="row">
                <FormControl isInvalid={!!errors.billTo?.name}>
                  <FormLabel>Name</FormLabel>
                  <Input type="text" {...register('billTo.careOf')} />
                </FormControl>
              </Stack>

              <Stack spacing={7} mb={3} direction="row">
                <FormControl isInvalid={!!errors.billTo?.countryCode}>
                  <FormLabel>Country</FormLabel>
                  <Select {...register('billTo.countryCode')}>
                    {filteredCountries?.map((c: any) => (
                      <option key={c.cca2} value={c.cca2}>
                        {c.name.common}
                      </option>
                    ))}
                  </Select>
                  {errors.billTo?.countryCode && (
                    <FormErrorMessage>{errors.billTo?.countryCode.message}</FormErrorMessage>
                  )}
                </FormControl>
              </Stack>
              <Stack spacing={7} mb={3} direction="row">
                <FormControl isInvalid={!!errors.billTo?.address1}>
                  <FormLabel> Address 1</FormLabel>
                  <Input type="text" {...register('billTo.address1')} />
                  {errors.billTo?.address1 && (
                    <FormErrorMessage>{errors.billTo?.address1.message}</FormErrorMessage>
                  )}
                </FormControl>
              </Stack>
              <Stack spacing={7} mb={3} direction="row">
                <FormControl>
                  <FormLabel> Address 2</FormLabel>
                  <Input type="text" {...register('billTo.address2')} />
                </FormControl>
              </Stack>

              <Stack spacing={7} mb={3} direction="row">
                <FormControl isInvalid={!!errors.billTo?.city}>
                  <FormLabel> City</FormLabel>
                  <Input type="text" {...register('billTo.city')} />
                  {errors.billTo?.city && (
                    <FormErrorMessage>{errors.billTo?.city.message}</FormErrorMessage>
                  )}
                </FormControl>
                <FormControl isInvalid={!!errors.billTo?.stateProvince}>
                  <FormLabel> State/Province</FormLabel>
                  {countryInclude.includes(watchBilltoCountry) ? (
                    <Select {...register('billTo.stateProvince')}>
                      {countryStateProvinces(watchBilltoCountry)?.map(
                        (c: { value: string; text: string }) => (
                          <option key={c.value} value={c.value}>
                            {c.text}
                          </option>
                        )
                      )}
                    </Select>
                  ) : (
                    <Input type="text" {...register('billTo.stateProvince')} />
                  )}
                  {errors.billTo?.stateProvince && (
                    <FormErrorMessage>{errors.billTo?.stateProvince.message}</FormErrorMessage>
                  )}
                </FormControl>
                <FormControl isInvalid={!!errors.billTo?.postalCode}>
                  <FormLabel> Postal Code</FormLabel>
                  <Input type="text" {...register('billTo.postalCode')} />
                  {errors.billTo?.postalCode && (
                    <FormErrorMessage>{errors.billTo?.postalCode.message}</FormErrorMessage>
                  )}
                </FormControl>
              </Stack>
            </TabPanel>
            <TabPanel key="shipTo">
              <Stack spacing={7} mb={3} direction="row">
                <FormControl isInvalid={!!errors.shipTo?.name}>
                  <FormLabel> Name</FormLabel>
                  <Input type="text" {...register('shipTo.name')} />
                  {errors.shipTo?.name && (
                    <FormErrorMessage>{errors.shipTo?.name.message}</FormErrorMessage>
                  )}
                </FormControl>
              </Stack>
              <Stack spacing={7} mb={3} direction="row">
                <FormControl>
                  <FormLabel>Care Of</FormLabel>
                  <Input type="text" {...register('shipTo.careOf')} />
                </FormControl>
              </Stack>

              <Stack spacing={7} mb={3} direction="row">
                <FormControl isInvalid={!!errors.shipTo?.countryCode}>
                  <FormLabel> Country</FormLabel>

                  <Select {...register('shipTo.countryCode')}>
                    {filteredCountries?.map((c: any) => (
                      <option key={c.cca2} value={c.cca2}>
                        {c.name.common}
                      </option>
                    ))}
                  </Select>
                  {errors.shipTo?.countryCode && (
                    <FormErrorMessage>{errors.shipTo?.countryCode.message}</FormErrorMessage>
                  )}
                </FormControl>
              </Stack>
              <Stack spacing={7} mb={3} direction="row">
                <FormControl isInvalid={!!errors.shipTo?.address1}>
                  <FormLabel> Address 1</FormLabel>
                  <Input type="text" {...register('shipTo.address1')} />
                  {errors.shipTo?.address1 && (
                    <FormErrorMessage>{errors.shipTo?.address1.message}</FormErrorMessage>
                  )}
                </FormControl>
              </Stack>
              <Stack spacing={7} mb={3} direction="row">
                <FormControl>
                  <FormLabel> Address 2</FormLabel>
                  <Input type="text" {...register('shipTo.address2')} />
                </FormControl>
              </Stack>
              <Stack spacing={7} mb={3} direction="row">
                <FormControl isInvalid={!!errors.shipTo?.city}>
                  <FormLabel> City</FormLabel>
                  <Input type="text" {...register('shipTo.city')} />
                  {errors.shipTo?.city && (
                    <FormErrorMessage>{errors.shipTo?.city.message}</FormErrorMessage>
                  )}
                </FormControl>
                <FormControl isInvalid={!!errors.shipTo?.stateProvince}>
                  <FormLabel> State/Province</FormLabel>
                  {countryInclude.includes(watchShiptoCountry) ? (
                    <Select {...register('shipTo.stateProvince')}>
                      {countryStateProvinces(watchShiptoCountry)?.map(
                        (c: { value: string; text: string }) => (
                          <option key={c.value} value={c.value}>
                            {c.text}
                          </option>
                        )
                      )}
                    </Select>
                  ) : (
                    <Input type="text" {...register('shipTo.stateProvince')} />
                  )}
                  {errors.shipTo?.stateProvince && (
                    <FormErrorMessage>{errors.shipTo?.stateProvince.message}</FormErrorMessage>
                  )}
                </FormControl>
                <FormControl isInvalid={!!errors.shipTo?.postalCode}>
                  <FormLabel> Postal Code</FormLabel>
                  <Input type="text" {...register('shipTo.postalCode')} />
                  {errors.shipTo?.postalCode && (
                    <FormErrorMessage>{errors.shipTo?.postalCode.message}</FormErrorMessage>
                  )}
                </FormControl>
              </Stack>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </form>

      {errMsg}
      {/*
    <Button
      ref={submitRef}
      isLoading={isSubmitting}
      isDisabled={isSubmitting}
      onClick={handleSubmit(handleSave)}>
      Save
    </Button> */}

      {isOpenModal && (
        <AddressBookModal
          onClose={() => setIsOpenModal(false)}
          onSelect={(v) => setSelectedAddress(v)}
        />
      )}
    </>
  );
});

export default EditableBillToShipTo;
