import React, { useState } from 'react';

import { Link, RouteComponentProps } from 'react-router-dom';
import { FormattedDate, FormattedTime } from 'react-intl';
import { Button, Box, IconButton, Icon, Tooltip, ButtonGroup } from '@chakra-ui/react';

import { FaEdit, FaExchangeAlt, FaUserAlt } from 'react-icons/fa';

import {
  useList,
  ListPage,
  basicQueryMap,
  generateOperatorOptions,
  Operator,
} from '@inkcloud/components';

import feathers from '../../../bootstrap/feathers';

interface ListProps {}

const serviceName = 'tenants/holidays';
const pathPrefix = '/settings/turnaround-holidays';

const filterQueryMap = (values) => {
  let filterRows = basicQueryMap(values, {});

  const isManagerValue = filterRows?.isManager?.$elemMatch?.value;

  filterRows = {
    ...filterRows,
  };

  return filterRows;
};

export async function getFilterDefinition() {
  const definition = [
    {
      label: 'Description',
      value: 'description',
      operators: generateOperatorOptions([Operator.Like]),
    },
    {
      label: 'Date',
      value: 'dateOf',
      type: 'date',
    },
  ];

  return definition.sort((a: any, b: any) => a.label.localeCompare(b.label));
}

export default function List(props: ListProps & RouteComponentProps) {
  const {
    match: { params },
    location,
    history,
  } = props;

  const useListValues = useList({
    feathers: ['find', serviceName, [{ query: { $populate: ['manager', 'defaultAddress'] } }]],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  const tableDefinition = [
    {
      key: 'createdAt',
      label: 'Created',
      sortable: true,
      cell: (v) => (
        <Tooltip hasArrow aria-label="time" label={<FormattedTime value={v} />}>
          <Box as="span">
            <FormattedDate value={v} />
          </Box>
        </Tooltip>
      ),
    },
    {
      key: 'description',
      label: 'Description',
      sortable: true,
      cell: (v) => <Box whiteSpace="pre-wrap">{v}</Box>,
    },
    {
      key: 'dateOf',
      label: 'Date',
      sortable: true,
      cell: (v) => (
        <Box as="span">
          <FormattedDate value={v} />
        </Box>
      ),
    },
    {
      key: '',
      label: '',
      cell: (v, r) => (
        <ButtonGroup spacing={1}>
          <Tooltip label="Edit Details">
            <Link to={`${pathPrefix}/${r._id}`}>
              <IconButton colorScheme="gray" aria-label="Edit" icon={<FaEdit />} />
            </Link>
          </Tooltip>
        </ButtonGroup>
      ),
      textAlign: 'right',
    },
  ];

  const headerActions = (
    <ButtonGroup spacing={1}>
      <Button type="button" size="sm" onClick={() => history.goBack()}>
        Back
      </Button>
      <Link to={`${pathPrefix}/add`}>
        <Button size="sm" colorScheme="blue">
          New
        </Button>
      </Link>
    </ButtonGroup>
  );

  return (
    <div>
      <ListPage
        header="Turnaround Holidays"
        subheader="Settings"
        useListValues={useListValues}
        history={history}
        pathPrefix={pathPrefix}
        tableDefintion={tableDefinition}
        withCheckboxes={true}
        headerActions={headerActions}
        getFilterDefinition={getFilterDefinition}
      />
    </div>
  );
}
