import * as React from 'react';
import {
  Accordion,
  Button,
  Dropdown,
  Header,
  Input,
  Menu,
  Icon,
  Segment,
  Step,
} from 'semantic-ui-react';
import { RouteComponentProps } from 'react-router-dom';
import ActionFrom from './ActionForm';
import ProductFilterForm from './../ProductFilterForm';
import Feathers from './../../../bootstrap/feathers';
import { filterMapper } from './../products/List';
import { PageHeaderRow } from '@inkcloud/components';

export interface MassUpdaterProps {}

interface InitialActionValues {
  fieldType: string;
  actionType: string;
  valueType: string;
  method: string;
}

export interface MassUpdaterState {
  activeIndex: Number;
  initialActionValues: InitialActionValues;
  actionValues: any;
  filterValues: any;
}

export default class MassUpdater extends React.Component<
  MassUpdaterProps & RouteComponentProps<any>,
  MassUpdaterState
> {
  constructor(props: MassUpdaterProps & RouteComponentProps<any>) {
    super(props);
    this.state = {
      activeIndex: 0,
      initialActionValues: {
        fieldType: 'price',
        actionType: 'increase',
        valueType: 'percentage',
        method: 'no',
      },
      actionValues: null,
      filterValues: null,
    };
  }

  handleActionSubmit = (values): any => {
    this.setState({ ...this.state, activeIndex: 1, actionValues: values });
  };

  handleFilterSubmit = (values): any => {
    this.setState({ ...this.state, activeIndex: 2, filterValues: values });
  };

  handleFinalSubmit = (values): any => {
    const { fieldType } = this.state.actionValues;
    const payload: any = { filter: filterMapper(this.state.filterValues), values: {} };
    if (fieldType === 'price') {
      const { valueType, actionType, value, method } = this.state.actionValues;

      if (valueType === 'fixed') {
        payload.action = `price_${actionType}_actual`;
        payload.values.price = value;
      } else if (valueType === 'percentage') {
        payload.action = `price_${actionType}_${valueType}`;
        payload.values.percentage = value;
      }

      if (actionType === 'increase') {
        if (method === 'no') {
          payload.values.method = undefined;
        }

        payload.values.method = method;
      }
    } else if (fieldType === 'add-on') {
      const { enabledAddOns = [], disabledAddOns = [] } = this.state.actionValues;
      payload.action = 'add_ons';
      payload.values.addOns = [];
      payload.values.addOns = payload.values.addOns.concat(
        enabledAddOns.map((a) => ({ id: a, isEnabled: true }))
      );
      payload.values.addOns = payload.values.addOns.concat(
        disabledAddOns.map((a) => ({ id: a, isEnabled: false }))
      );
    } else if (fieldType === 'turnaround') {
      const { timeMin, timeMax } = this.state.actionValues;
      payload.action = 'turnaround_time';
      payload.values.timeMin = timeMin;
      payload.values.timeMax = timeMax;
    } else if (fieldType === 'design') {
      const { isDesignEnabled, designPrice } = this.state.actionValues;
      payload.action = 'design_price';
      payload.values.designPrice = designPrice;
      payload.values.isDesignEnabled = isDesignEnabled;
    } else if (fieldType === 'rush-service') {
      const { isRushEnabled } = this.state.actionValues;
      payload.action = 'rush_service';
      payload.values.isRushEnabled = isRushEnabled;
    } else if (fieldType === 'artworkSlots') {
      const { artworkSlots } = this.state.actionValues;
      payload.action = 'artwork_slots';
      payload.values.artworkSlots = artworkSlots;
    }

    Feathers.service('/products-legacy/mass-update')
      .create(payload)
      .then((results: any) => {
        this.props.history.push('/products/products');
      });
    // this.setState({ ...this.state, activeIndex: 3 });
  };

  handleClick = () => {};

  render() {
    const { activeIndex } = this.state;

    return (
      <div>
        <PageHeaderRow header="Mass Updater" subheader="Product" />
        <Segment>
          <Step.Group size="mini" widths={3}>
            <Step active={this.state.activeIndex === 0} completed={this.state.activeIndex > 0}>
              <Icon name="options" />
              <Step.Content>
                <Step.Title>Action</Step.Title>
                <Step.Description>What do you want to update</Step.Description>
              </Step.Content>
            </Step>

            <Step active={this.state.activeIndex === 1} completed={this.state.activeIndex > 1}>
              <Icon name="filter" />
              <Step.Content>
                <Step.Title>Fiter</Step.Title>
                <Step.Description>Limit update to selected products</Step.Description>
              </Step.Content>
            </Step>

            <Step active={this.state.activeIndex === 2} completed={this.state.activeIndex > 2}>
              <Icon name="info" />
              <Step.Content>
                <Step.Title>Confirm and Execute</Step.Title>
                <Step.Description>Apply the mass update</Step.Description>
              </Step.Content>
            </Step>
          </Step.Group>
          <Accordion fluid styled>
            <Accordion.Title
              active={activeIndex === 0}
              index={0}
              onClick={() => this.setState({ ...this.state, activeIndex: 0 })}
            >
              <Icon name="options" />
              Action
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 0}>
              <ActionFrom
                initialValues={this.state.initialActionValues}
                onSubmit={this.handleActionSubmit}
              />
            </Accordion.Content>
            <Accordion.Title
              active={activeIndex === 1}
              index={1}
              onClick={() => this.setState({ ...this.state, activeIndex: 1 })}
            >
              <Icon name="filter" />
              Filter - Apply to products matching the following filter
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 1}>
              <ProductFilterForm onSubmit={this.handleFilterSubmit} />
            </Accordion.Content>
            <Accordion.Title
              active={activeIndex === 2}
              index={2}
              onClick={() => this.setState({ ...this.state, activeIndex: 2 })}
            >
              <Icon name="wizard" />
              Finalize and run mass update
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 2}>
              <Button primary onClick={this.handleFinalSubmit}>
                Run Mass Update
              </Button>
            </Accordion.Content>
          </Accordion>
        </Segment>
      </div>
    );
  }
}
