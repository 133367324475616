import React, { useMemo } from 'react';

import Fraction from 'fraction.js';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Stack,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Textarea,
  Checkbox,
  Button,
  IconButton,
} from '@chakra-ui/react';

import { RiCloseLine } from 'react-icons/ri';

import type { ModelTypes } from '@inkcloud/icapi-types';

import type { AttributeType } from '../types';

import Attributes from './Attributes';

import { validateNumberFraction } from '../../../../common';

interface IComponents {
  attributes: AttributeType[];
  values: Exclude<
    NonNullable<ModelTypes.Rfq['items']>[number]['components'] & { id?: string },
    undefined | null
  >;
  onChange: (value: IComponents['values']) => void;
}

export const regex = new RegExp(/^\d+((?=[ ]*)[ ]?\d+\/\d+|(?=.*)\.?\d*)$/);

export default function Components(props: IComponents) {
  const { attributes, values, onChange } = props;

  const handleChange = (idx: number, keyValue: any) => {
    onChange(values?.map((s, index) => (index === idx ? { ...s, ...keyValue } : s)));
  };

  const handleRemove = (idx: number) =>
    onChange(values?.filter((_, index: number) => index !== idx));

  // console.log('values', values);

  return (
    <Accordion defaultIndex={[0]} allowMultiple>
      {values?.map((c, idx: number) => (
        <AccordionItem
          key={idx}
          borderLeft="1px"
          borderLeftColor="gray.200"
          borderRight="1px"
          borderRightColor="gray.200"
        >
          <AccordionButton>
            <Box flex="1" textAlign="left">
              Component {idx + 1}
            </Box>
            {values?.length > 1 && (
              <IconButton
                mr={2}
                my={3}
                variant="ghost"
                onClick={() => handleRemove(idx)}
                aria-label="Remove Component"
                icon={<RiCloseLine />}
              />
            )}

            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4} style={{ overflow: 'auto', height: 'fit-content' }}>
            <Stack spacing={7} mb={3} direction="row">
              <FormControl>
                <FormLabel>Description</FormLabel>
                <Textarea
                  name="description"
                  value={c?.description}
                  onChange={(e) =>
                    handleChange(idx, {
                      ...c,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </FormControl>
              <FormControl>
                <FormLabel>Notes</FormLabel>
                <Textarea
                  name="notes"
                  value={c?.notes}
                  onChange={(e) =>
                    handleChange(idx, {
                      ...c,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </FormControl>
            </Stack>
            <Stack spacing={7} mb={3} direction="row">
              <FormControl isInvalid={!regex.test(String(c?.flatSize?.width))}>
                <FormLabel>Flat Size</FormLabel>
                <Stack spacing={7} mb={3} direction="row">
                  <FormControl>
                    <FormLabel>Width</FormLabel>
                    <Input
                      name="width"
                      value={c?.flatSize?.width}
                      onChange={(e) =>
                        handleChange(idx, {
                          ...c,
                          flatSize: {
                            ...c.flatSize,
                            [e.target.name]: e.target.value?.trim(),
                          },
                        })
                      }
                    />

                    {c?.flatSize?.width && !regex.test(String(c?.flatSize?.width)) && (
                      <FormHelperText>Invalid value</FormHelperText>
                    )}
                  </FormControl>
                  <FormControl
                    isInvalid={
                      c?.flatSize?.height !== undefined && !regex.test(String(c?.flatSize?.height))
                    }
                  >
                    <FormLabel>Height</FormLabel>
                    <Input
                      name="height"
                      value={c?.flatSize?.height}
                      onChange={(e) =>
                        handleChange(idx, {
                          ...c,
                          flatSize: {
                            ...c.flatSize,
                            [e.target.name]: e.target.value?.trim(),
                          },
                        })
                      }
                    />
                    {c?.flatSize?.height && !regex.test(String(c?.flatSize?.height)) && (
                      <FormHelperText>Invalid value</FormHelperText>
                    )}
                  </FormControl>
                </Stack>
              </FormControl>
            </Stack>
            <Stack spacing={7} mb={3} direction="row">
              <FormControl>
                <FormLabel>Finish Size</FormLabel>
                <Stack spacing={7} mb={3} direction="row">
                  <FormControl>
                    <FormLabel>Width</FormLabel>
                    <Input
                      name="width"
                      value={c?.finishSize?.width}
                      onChange={(e) =>
                        handleChange(idx, {
                          ...c,
                          finishSize: {
                            ...c.finishSize,
                            [e.target.name]: e.target.value?.trim(),
                          },
                        })
                      }
                    />
                    {c?.finishSize?.width && !regex.test(String(c?.finishSize?.width)) && (
                      <FormHelperText>Invalid value</FormHelperText>
                    )}
                  </FormControl>
                  <FormControl>
                    <FormLabel>Height</FormLabel>
                    <Input
                      name="height"
                      value={c?.finishSize?.height}
                      onChange={(e) =>
                        handleChange(idx, {
                          ...c,
                          finishSize: {
                            ...c.finishSize,
                            [e.target.name]: e.target.value?.trim(),
                          },
                        })
                      }
                    />
                    {c?.finishSize?.height && !regex.test(String(c?.finishSize?.height)) && (
                      <FormHelperText>Invalid value</FormHelperText>
                    )}
                  </FormControl>
                </Stack>
              </FormControl>
            </Stack>

            <br />
            <hr />
            <br />
            <Attributes
              attributes={attributes}
              values={c?.attributes}
              onChange={(v) => handleChange(idx, { attributes: v })}
            />

            <br />
            <FormControl>
              <FormLabel>Ink Specs</FormLabel>
              <Input
                name="inkSpecs"
                value={c?.inkSpecs}
                onChange={(e) =>
                  handleChange(idx, {
                    ...c,
                    inkSpecs: e.target.value,
                  })
                }
              />
            </FormControl>
          </AccordionPanel>
        </AccordionItem>
      ))}
    </Accordion>
  );
}
