import * as React from 'react';
import { Style } from '@react-pdf/types';
import { ShipTo as ShipToGeneral } from '..';
import { SalesOrderContext } from './SalesOrderContext';

export interface IShipToProps {
  style?: Style;
  textStyle?: Style;
}

export function ShipTo(props: IShipToProps) {
  const ctx = React.useContext(SalesOrderContext);
  const { style, textStyle } = props;

  return (
    <ShipToGeneral value={ctx.dataCtx.salesOrder.shipTo} style={style} textStyle={textStyle} />
  );
}
