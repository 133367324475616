import * as React from 'react';
import { Formik, Form as FormikForm, ErrorMessage } from 'formik';
import { Button, Form } from 'semantic-ui-react';
import { SemanticField } from '../../../common/Form';
import { usStateProvinces } from '../../../common';

import { PreLoader } from '@inkcloud/components';

import Feathers from '../../../bootstrap/feathers';

import * as Yup from 'yup';

interface ContactFormProps {
  id: any;
  contact: any;
}

const { useState } = React;

export const ContactForm: React.FunctionComponent<ContactFormProps> = (props) => {
  const { contact, id } = props;

  const [isLoading, setIsLoading] = useState(false);

  const ContactInfoSchema = Yup.object().shape({
    email: Yup.string().email(),
    businessName: Yup.string(),
    domainName: Yup.string(),
    phone: Yup.string().required(),
    fax: Yup.string(),
    address: Yup.object().shape({
      address1: Yup.string(),
      address2: Yup.string(),
      city: Yup.string(),
      stateProvince: Yup.string(),
      postalCode: Yup.number(),
    }),
  });

  const handleSubmit = async (value: any) => {
    setIsLoading(true);
    await Feathers.service('tenant-settings/contact-info').patch(id, value, { query: {} });
    setIsLoading(false);
  };

  if (!contact) {
    return <PreLoader />;
  }

  return (
    <>
      <Formik
        initialValues={contact}
        validationSchema={ContactInfoSchema}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            setFieldValue,
            setFieldTouched,
          } = props;

          return (
            <>
              <FormikForm
                className={`ui form ${isLoading && 'loading'}`}
                style={{ height: '100%' }}
              >
                <Form.Field>
                  <SemanticField
                    fluid
                    name="businessName"
                    component={Form.Input}
                    label="Business Name"
                  />
                </Form.Field>
                <Form.Field>
                  <SemanticField fluid name="email" component={Form.Input} label="Email" />
                  <ErrorMessage name="email" />
                </Form.Field>
                <Form.Field>
                  <SemanticField fluid name="phone" component={Form.Input} label="Phone Number" />
                  <ErrorMessage name="phone" />
                </Form.Field>
                <Form.Group widths="equal">
                  <Form.Field>
                    <SemanticField
                      fluid
                      name="address.address1"
                      component={Form.Input}
                      label="Address 1"
                    />
                  </Form.Field>
                  <Form.Field>
                    <SemanticField
                      fluid
                      name="address.address2"
                      component={Form.Input}
                      label="Address 2"
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <SemanticField
                      name={'address.stateProvince'}
                      component={Form.Dropdown}
                      options={usStateProvinces}
                      fluid
                      selection
                      label="State/Province"
                      placeholder="State/Province..."
                    />
                  </Form.Field>
                  <Form.Field>
                    <SemanticField fluid name="address.city" component={Form.Input} label="City" />
                  </Form.Field>
                  <Form.Field>
                    <SemanticField
                      fluid
                      name="address.postalCode"
                      component={Form.Input}
                      label="Zip"
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Field>
                  <Button type="submit" primary content="Save" />
                </Form.Field>
              </FormikForm>
            </>
          );
        }}
      </Formik>
    </>
  );
};
