import * as React from 'react';
import { Button, Icon, Popup, Label } from 'semantic-ui-react';
import { Link, RouteComponentProps } from 'react-router-dom';

import { useList } from '@inkcloud/components';

import { ListPage, basicQueryMap } from '@inkcloud/components';
import { getFilterDefinition } from './FilterForm2';

interface ListProps {}

const filterQueryMap = (values) => {
  let filterRows = basicQueryMap(values);

  filterRows = {
    ...filterRows,
  };

  return filterRows;
};

const serviceName = '/order-items';
const pathPrefix = '/translation/assign';

export const List: React.FunctionComponent<ListProps & RouteComponentProps<any>> = (props) => {
  const {
    match: { params },
    location,
    history,
  } = props;

  const useListValues = useList({
    feathers: [
      'find',
      serviceName,
      [
        {
          query: {
            $populate: ['customer', 'category', 'attributes.value', 'productionStatus.jobStatus'],
            $sort: { createdAt: -1 },
            'productionStatus.jobStatus': 'FILES_RECEIVED',
          },
        },
      ],
    ],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  const headerActions = <></>;

  const tableDefintion = [
    {
      key: 'humanId',
      label: 'ID',
      sortable: true,
      cell: (v, r) => <Link to={`/orders/${r.order}`}>{v}</Link>,
    },
    {
      key: 'customer',
      label: 'Customer',
      cell: (v, r) => (
        <Link to={`/customers/${r.customer?._id}`}>
          {r.customer?.organizationName ||
            `${r.customer?.primaryContact?.firstName ?? ''} ${
              r.customer?.primaryContact?.lastName ?? ''
            }`}
        </Link>
      ),
    },
    { key: 'category', label: 'Category', cell: (v, r) => r?.category?.name },
    {
      key: '',
      label: 'Description',
      cell: (v, r) => {
        const sourceLang = r.metadata?.find((a) => a.key == 'tr_source_name');
        const destinationLang = r.metadata?.find((a) => a.key == 'tr_dest_name');
        return (
          <div>
            <Label size={'mini'} key={sourceLang?.key ?? ''}>
              {sourceLang?.value ?? ''}
            </Label>
            <Icon name={'long arrow alternate right'} />
            <Label size={'mini'} key={destinationLang?.key ?? ''}>
              {destinationLang?.value ?? ''}
            </Label>
          </div>
        );
      },
    },

    { key: '', label: 'Status', cell: (v, r) => r?.productionStatus?.description },
    {
      key: '',
      label: '',
      cell: (v, r) => (
        <>
          <Popup
            size="mini"
            content="Assign"
            align
            trigger={
              <Link to={`${pathPrefix}/${r._id}`}>
                <Button size="mini" icon="user" />
              </Link>
            }
          />
        </>
      ),
      textAlign: 'right',
    },
  ];

  return (
    <ListPage
      header="Assign"
      subheader="Translator"
      useListValues={useListValues}
      history={history}
      pathPrefix={pathPrefix}
      tableDefintion={tableDefintion}
      withCheckboxes={true}
      headerActions={headerActions}
      getFilterDefinition={getFilterDefinition}
    />
  );
};
