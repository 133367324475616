import * as React from 'react';
import { Button, Input, Dropdown, Segment, Form } from 'semantic-ui-react';
import { debounce } from 'lodash-es';

const { useState, useEffect } = React;

export interface FilterFormProps {
  value: any;
  onChange: Function;
  onReset: Function;
}

function initMapper(value) {
  const defaults = {
    firstName: '',
    lastName: '',
    email: '',
  };

  return Object.keys(defaults).reduce((p, cur) => {
    if (!p[cur]) {
      p[cur] = defaults[cur];
    }
    return p;
  }, value);
}

export function FilterForm(props: FilterFormProps) {
  const { value: preInitValue } = props;
  const [isSetup, setIsSetup] = useState(false);

  const value = initMapper(preInitValue);

  const onChange = (field, value) => {
    props.onChange && props.onChange({ ...props.value, [field]: value });
  };

  return (
    <Segment>
      <Form size={'small'}>
        <Form.Group widths={'equal'}>
          <Form.Field>
            <Input
              placeholder="First Name"
              value={value.firstName}
              onChange={(e, data) => onChange('firstName', data.value)}
            />
          </Form.Field>
          <Form.Field>
            <Input
              placeholder="Last Name"
              value={value.lastName}
              onChange={(e, data) => onChange('lastName', data.value)}
            />
          </Form.Field>
          <Form.Field>
            <Input
              placeholder="Email"
              value={value.email}
              onChange={(e, data) => onChange('email', data.value)}
            />
          </Form.Field>
        </Form.Group>
        <Form.Field>
          <Button size={'tiny'} content="Reset Form" onClick={() => props.onReset()} />
        </Form.Field>
      </Form>
    </Segment>
  );
}
