import * as React from 'react';
import { Switch, Route } from 'react-router-dom';

import { DueDate } from './DueDate';
import { InProduction } from './InProduction';

const routes = (props) => (
  <Switch>
    <Route exact path="/production/view/due-date" component={DueDate} />
    <Route exact path="/production/view/in-production" component={InProduction} />
  </Switch>
);

export default routes;
