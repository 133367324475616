import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { Button, Segment } from 'semantic-ui-react';

import { Box } from '@chakra-ui/react';

import { PageHeaderRow, PreLoader, useFeathers } from '@inkcloud/components';

import feathers from '../../../bootstrap/feathers';

import DataViewForm from './DataViewForm';

const serviceName = '/data-views';
const pathPrefix = `/settings/data-views`;
const service = feathers.service(serviceName);
const commonParams = { query: {} };

export default function Detail(props: RouteComponentProps<{ id?: string }>) {
  const {
    history,
    match: {
      params: { id },
    },
  } = props;

  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [state, reload] = useFeathers('get', serviceName, [id, commonParams], true);

  const isEdit = !!id;

  const handleSubmit = async (values) => {
    setErrorMessage('');
    setIsSubmitting(true);
    try {
      const payload = values;
      if (isEdit) {
        await service.patch(id, payload, commonParams);
      } else {
        await service.create(payload, commonParams);
      }
      history.push(pathPrefix);
    } catch (e) {
      const errMsg = e?.code < 500 ? e.message : 'Something went wrong. Please try again later';
      setErrorMessage(errMsg);
    }

    setIsSubmitting(false);
  };

  if (isEdit && !state.data) {
    return <PreLoader />;
  }

  const initialData = { ...state.data };
  return (
    <div>
      <PageHeaderRow header="Data Views" subheader="Settings" subheaderId={''}>
        <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
      </PageHeaderRow>
      <Box bg="white" borderWidth="1px" p={3} mt={3}>
        <DataViewForm
          initValues={initialData}
          errorMessage={errorMessage}
          isSubmitting={isSubmitting}
          onSubmit={handleSubmit}
        />
      </Box>
    </div>
  );
}
