import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import {
  Badge,
  Box,
  Button,
  Flex,
  Heading,
  Text,
  Spacer,
  IconButton,
  Menu,
  MenuList,
  MenuButton,
  MenuItem,
  Grid,
  GridItem,
  AlertIcon,
  Alert,
} from '@chakra-ui/react';

import { FaEllipsisV, FaPrint, FaRegEnvelope, FaCopy } from 'react-icons/fa';

import { PageHeaderRow, PreLoader, useFeathers } from '@inkcloud/components';
import { FormattedDate } from 'react-intl';

import type { ServiceTypes } from '@inkcloud/icapi-types';
import type { PurchaseOrders } from './Detail';

import feathers from '../../bootstrap/feathers';
import { PurchaseOrder } from './PurchaseOrder';
import { LineItemGrid, randomGenerator } from '../sales-orders/SalesOrder/LineItemGrid';
import { Summary } from '../sales-orders/SalesOrder/Summary';

import { BillTo, ShipTo } from '../sales-orders/SalesOrder/AddressComponents';

import Info from './PurchaseOrder/Info';

import Note from './PurchaseOrder/Note';

import { initMapper, calculateSummary } from '../sales-orders/SalesOrder/helpers';

import NoteContainer from '../../common/notes2/index';

import { PrintTypes } from '../../common/PrintModal';

import { IndexWrappedProps } from '../../bootstrap/IndexWrapper';

import { connectOrderItemModals } from '../../common/OrderItemCard2';

type ICEntity<T extends keyof ServiceTypes> = Awaited<ReturnType<ServiceTypes[T]['get']>>;

export type Product = ICEntity<'products-legacy'>;
export type Receiving = ICEntity<'purchase-orders/receivings'>;

type csrType = { firstName: string; lastName: string };

type PurchaseOrdersType = PurchaseOrders & {
  csr: csrType;
};

interface IDetailProps {}

const serviceName = `/purchase-orders`;
const pathPrefix = `/purchase-orders`;
const service = feathers.service(serviceName);
const commonParams = {
  query: {
    $populate: ['csr', 'vendor', 'items.product', 'privateNotes.createdBy'],
  },
};

export const PreVendorDetail: React.FunctionComponent<
  IDetailProps & RouteComponentProps<{ id?: string }>
> = (props) => {
  const {
    match: {
      params: { id },
    },
    history,
  } = props;

  const isEdit = !!id;
  const [state, reload] = useFeathers('get', serviceName, [id, commonParams], true);

  const [isSubmitting, setIsSubmitting] = useState({
    acknowledge: false,
  });
  const [erroMessage, setErroMessage] = useState({
    acknowledge: '',
  });

  const lineItems = initMapper(state?.data, 'items', 'sales')?.map((item) => {
    const alreadyReceivedCount =
      state?.data?.receivings
        ?.filter((r) => !r.isVoid)
        .reduce((acc, cur) => {
          const foundReceiveItemsSum =
            cur.items
              .filter((i) => i.purchaseOrderItem === item._id)
              .reduce((receiveTotal, receiveItem) => receiveTotal + receiveItem.amount, 0) || 0;

          return acc + foundReceiveItemsSum;
        }, 0) || 0;

    return { ...item, receivedOrShipped: alreadyReceivedCount };
  });

  const [glAccounts, setGlAccounts] = React.useState([]);

  const billTo = state?.data?.billTo;
  const shipTo = state?.data?.shipTo;

  const initialData: PurchaseOrdersType = { ...state.data };

  React.useEffect(() => {
    (async () => {
      const res: any = await feathers.service('general-ledger-accounts').find({
        query: {
          accountType: 'cost-of-goods',
        },
      });

      setGlAccounts(
        res?.data?.map((d) => ({ text: `${d.accountNumber} ${d.name} `, value: d._id }))
      );
    })();
  }, []);

  const loadProducts = async (inputValue) => {
    const baseQuery = { $np: 1, key: { $LIKE: inputValue }, $sort: { key: 1 } };
    const results = await feathers.service('products-legacy').find({ query: { ...baseQuery } });
    return results.map((m) => ({
      label: m.key,
      value: m._id,
      name: m.staticName,
      glAccount: m?.glExpenseAccount,
      amount: m?.cost || 0,
    }));
  };

  const printHandleOpenOrderLevel = (modaltype: PrintTypes, payload: { _id: string }) => {
    (props as any).printHandleOpen(modaltype, payload);
  };

  const handleAcknowledge = async () => {
    setIsSubmitting({ acknowledge: true });
    setErroMessage({ acknowledge: '' });
    try {
      await feathers.service(serviceName).patch(initialData._id, {
        isVendorAcknowledged: true,
        shouldRequestVendorAcknowledge: false,
      });

      reload();
    } catch (e) {
      const errMsg = e?.code < 500 ? e?.message : 'Something went wrong';
      setErroMessage({ acknowledge: errMsg });
    }
    setIsSubmitting({ acknowledge: false });
  };

  const pageTitle = isEdit ? 'Edit' : 'New';

  if (isEdit && !state.data) {
    return <PreLoader />;
  }

  const summary = calculateSummary(lineItems);

  return (
    <Box>
      {initialData.shouldRequestVendorAcknowledge && (
        <Alert mb={3} status="info">
          <AlertIcon />
          Please acknowledge you have received this Purchase Order{' '}
          <Button
            isLoading={isSubmitting.acknowledge}
            isDisabled={isSubmitting.acknowledge}
            onClick={handleAcknowledge}
            ml={3}
          >
            Acknowledge
          </Button>
        </Alert>
      )}

      {erroMessage.acknowledge && (
        <Alert mb={3} status="error">
          {erroMessage.acknowledge}
        </Alert>
      )}

      <Flex mb={16} alignItems={'center'}>
        <Box>
          <Heading fontWeight={'normal'} size="xl">
            Purchase Order {initialData?.humanId ? `#${initialData?.humanId}` : '- New'}
          </Heading>
        </Box>
        <Badge
          ml={4}
          colorScheme={initialData?.status !== 'open' ? 'orange' : 'green'}
          fontSize={'large'}
        >
          {initialData?.status ?? 'Draft'}
        </Badge>
        <Spacer />
        {id && (
          <>
            <Menu>
              <MenuButton
                as={IconButton}
                aria-label="Actions"
                icon={<FaEllipsisV />}
                variant="solid"
                colorScheme={'blue'}
              />
              <MenuList>
                <MenuItem
                  icon={<FaPrint />}
                  onClick={() =>
                    printHandleOpenOrderLevel(PrintTypes.PurchaseOrder, { _id: state?.data?._id })
                  }
                >
                  Print
                </MenuItem>
              </MenuList>
            </Menu>
            <Button ml={2} size="sm" onClick={() => history.goBack()}>
              Back
            </Button>
          </>
        )}
      </Flex>

      <Grid templateColumns={{ lg: 'repeat(3, 1fr)' }} gap={8}>
        <GridItem colSpan={{ lg: 2 }}>
          <Box mb={8}>
            <Grid templateColumns={{ lg: 'repeat(2, 1fr)' }} gap={8}>
              <GridItem>
                <BillTo
                  value={billTo}
                  onChange={() => null}
                  actionButtonLabel=""
                  isEditable={false}
                />
              </GridItem>
              <GridItem>
                <ShipTo
                  value={shipTo}
                  onChange={() => null}
                  actionButtonLabel=""
                  isEditable={false}
                />
              </GridItem>
            </Grid>
          </Box>
          <Box mb={8}>
            <Info
              info={{
                ...initialData,
                csr: `${initialData?.csr?.firstName} ${initialData?.csr?.lastName}`,
              }}
              actionButtonLabel=""
              onEditClick={() => {}}
            />
          </Box>
        </GridItem>
        <GridItem colSpan={{ lg: 1 }}>
          <Note
            title="Notes"
            subTitle="Internal Notes"
            actionButtonLabel=""
            notes={initialData?.privateNotes ?? []}
            onOpenNoteModal={() => {}}
          />
        </GridItem>
      </Grid>

      <LineItemGrid
        isTaxableDefault={false}
        lineItems={lineItems}
        onChange={(v) => {}}
        productLoad={loadProducts}
        glAccounts={glAccounts}
        isDisabled={true}
        summary={summary}
        onAddLine={() => {}}
      />

      {/* <Segment>
        <div
          style={{
            display: 'flex',
            alignItems: 'stretch',
            alignContent: 'stretch',
            gap: 14,
          }}
        >
          <Segment style={{ flexGrow: 4, marginBottom: 0 }}>
            <Segment>
              <NoteContainer
                userField={'createdBy'}
                isModalOpen={false}
                notes={state?.data?.privateNotes ?? []}
                errorMessage={''}
                onSubmit={() => null}
                handleNoteClose={() => null}
              />
            </Segment>
            <div
              style={{ display: 'flex', gap: 20, alignItems: 'stretch', alignContent: 'stretch' }}
            >
              
            </div>
          </Segment>
          <div style={{ flexGrow: 1, marginTop: 0 }}>
            <Segment>
              <List relaxed size="tiny">
                <List.Item>
                  <List.Header>Status</List.Header>
                  <div style={{ marginTop: '.15em' }}>
                    <Label content={state?.data?.status} />
                  </div>
                </List.Item>
                <List.Item>
                  <List.Header>CSR</List.Header>
                  {state?.data?.csr?.firstName ?? ''} {state?.data?.csr?.lastName ?? ''}
                </List.Item>
                <List.Item>
                  <List.Header>RFQ</List.Header>
                  {state?.data?.rfqNum}
                </List.Item>
                <List.Item>
                  <List.Header>Sales Order</List.Header>
                  {state?.data?.salesOrder}
                </List.Item>
                <List.Item>
                  <List.Header>Project Name</List.Header>
                  {state?.data?.projectName}
                </List.Item>
                <List.Item>
                  <List.Header>Notes to Vendor</List.Header>
                  {state?.data?.notesToVendor}
                </List.Item>
                <List.Item>
                  <List.Header>PO Due Date</List.Header>
                  <FormattedDate value={state?.data?.poDueDate} />
                </List.Item>
                <List.Item>
                  <List.Header>Sales Order Ship Date</List.Header>
                  <FormattedDate value={state?.data?.soShipDate} />
                </List.Item>
              </List>
            </Segment>
          </div>
        </div>
        <br />
        
      </Segment> */}
    </Box>
  );
};

export const VendorDetail = connectOrderItemModals<IndexWrappedProps>(PreVendorDetail);
