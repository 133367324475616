import * as React from 'react';
import { Button, Form, Dropdown, Message, Divider } from 'semantic-ui-react';
import { Formik, Form as FormikForm, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import countries from 'world-countries';

import { useSelector } from 'react-redux';
import { SemanticField } from '../../../../common/Form';
import { countryStateProvinces } from '../../../../common';

interface WarehouseFormProps {
  initialValues: any;
  handleSubmit: Function;
  isSubmitting?: boolean;
  handleCloseClick: any;
}

export const WarehouseForm: React.FC<WarehouseFormProps> = (props) => {
  const { handleCloseClick } = props;
  const WarehouseLocationSchema = Yup.object().shape({
    name: Yup.string().required(),
    address: Yup.object().shape({
      address1: Yup.string().required('Address 1 is required'),
      city: Yup.string().required('City is required'),
      stateProvince: Yup.string().required('State/Province is required'),
      postalCode: Yup.string().required('Postal Code is required'),
    }),
    priority: Yup.number().typeError('Must be a number'),
  });

  const tenant = useSelector(
    ({ globals }: { globals: { tenant: { internationalization: { countryCode: string } } } }) =>
      globals.tenant.internationalization
  );

  return (
    <Formik
      initialValues={{
        ...props.initialValues,
        address: {
          ...props.initialValues?.address,
          countryCode: props.initialValues?.address?.countryCode || tenant.countryCode,
        },
      }}
      validationSchema={WarehouseLocationSchema}
      onSubmit={(values, { setSubmitting }) => {
        props.handleSubmit({ ...values });
      }}
    >
      {(innerProps) => {
        const { values, errors, isSubmitting, setFieldValue } = innerProps;

        return (
          <FormikForm className={`ui form ${isSubmitting && 'loading'}`}>
            <Form.Field>
              <SemanticField name={'name'} label="Name" component={Form.Input} />
              <ErrorMessage
                render={(msg) => <Message negative size="tiny" content={msg} />}
                name="name"
              />
            </Form.Field>
            <Form.Field>
              <SemanticField name={'priority'} label="Priority" component={Form.Input} />
              <ErrorMessage
                render={(msg) => <Message negative size="tiny" content={msg} />}
                name="priority"
              />
            </Form.Field>
            <Divider horizontal>Address</Divider>
            <Form.Field>
              <SemanticField
                name="address.countryCode"
                label="Country"
                selection
                search
                component={Form.Dropdown}
                options={
                  countries?.map((c: any) => ({ text: c.name.official, value: c.cca2 })) ?? []
                }
                fluid
              />
            </Form.Field>
            <Form.Field>
              <SemanticField name={'address.address1'} label="Address 1" component={Form.Input} />
              <ErrorMessage
                render={(msg) => <Message negative size="tiny" content={msg} />}
                name="address.address1"
              />
            </Form.Field>
            <Form.Field>
              <SemanticField name={'address.address2'} label="Address 2" component={Form.Input} />
            </Form.Field>
            <SemanticField
              name={'address.city'}
              label="City"
              component={Form.Input}
              className={'fluid'}
            />
            <ErrorMessage
              render={(msg) => <Message negative size="tiny" content={msg} />}
              name="address.city"
            />
            {values?.address?.countryCode === 'US' ||
            values?.address?.countryCode === 'CA' ||
            values?.address?.countryCode === 'AU' ? (
              <div className="field">
                <label>
                  {values?.address?.countryCode === 'AU' ? 'State/Territory' : 'State/Province'}
                </label>
                <Dropdown
                  name={'address.stateProvince'}
                  options={countryStateProvinces(values?.countryCode)}
                  fluid
                  selection
                  value={values?.address?.stateProvince ?? ''}
                  onChange={(e, data) => setFieldValue(data.name, data.value)}
                />
              </div>
            ) : (
              <SemanticField
                name={'address.stateProvince'}
                component={Form.Input}
                fluid
                label="State/Province"
              />
            )}
            <ErrorMessage
              render={(msg) => <Message negative size="tiny" content={msg} />}
              name="address.stateProvince"
            />
            <SemanticField name={'address.postalCode'} label="Postal Code" component={Form.Input} />
            <ErrorMessage component="div" name="address.postalCode" />
            <ErrorMessage
              render={(msg) => <Message negative size="tiny" content={msg} />}
              name="address.postalCode"
            />

            <Button primary type="submit" disabled={isSubmitting}>
              Save
            </Button>
            <Button type="button" disabled={isSubmitting} onClick={handleCloseClick}>
              Cancel
            </Button>
          </FormikForm>
        );
      }}
    </Formik>
  );
};
