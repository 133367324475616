import React from 'react';

import { Link, RouteComponentProps } from 'react-router-dom';
import { FormattedDate, FormattedTime } from 'react-intl';
import {
  Button,
  Box,
  List as ListChakra,
  IconButton,
  HStack,
  Tag,
  Tooltip,
  ButtonGroup,
} from '@chakra-ui/react';

import { FaEdit, FaTrash } from 'react-icons/fa';

import {
  useList,
  ListPage,
  basicQueryMap,
  generateOperatorOptions,
  Operator,
} from '@inkcloud/components';

interface ListProps {}

const serviceName = 'users';

const filterQueryMap = (values) => basicQueryMap(values, {});

export async function getFilterDefinition() {
  const definition = [
    {
      label: 'First Name',
      value: 'firstName',
      operators: generateOperatorOptions([Operator.Like]),
    },
    {
      label: 'Last Name',
      value: 'lastName',
      operators: generateOperatorOptions([Operator.Like]),
    },
    {
      label: 'Email',
      value: 'email',
      operators: generateOperatorOptions([Operator.Like]),
    },
  ];

  return definition.sort((a: any, b: any) => a.label.localeCompare(b.label));
}

export default function List(props: ListProps & RouteComponentProps<{ supplierId: string }>) {
  const {
    match: {
      params: { supplierId },
    },
    location,
    history,
  } = props;

  const pathPrefix = `/settings/production/suppliers/${supplierId}/users`;

  const useListValues = useList({
    feathers: [
      'find',
      serviceName,
      [
        {
          query: {
            vendor: supplierId,
            $populate: ['roles2'],
          },
        },
      ],
    ],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  const tableDefinition = [
    {
      key: 'createdAt',
      label: 'Date',
      sortable: true,
      cell: (v) => (
        <Tooltip hasArrow aria-label="time" label={<FormattedTime value={v} />}>
          <Box as="span">
            <FormattedDate value={v} />
          </Box>
        </Tooltip>
      ),
    },
    {
      key: 'firstName',
      label: 'First Name',
      cell: (v) => v,
    },
    {
      key: 'lastName',
      label: 'Last Name',
      cell: (v) => v,
    },
    {
      key: 'email',
      label: 'Email',
      cell: (v) => v,
    },
    {
      key: 'roles2',
      label: 'Roles',
      cell: (v, r) => (
        <HStack spacing={1}>
          {v.map((role, index) => (
            <Tag size="sm" key={index} variant="solid">
              {role?.name ?? ''}
            </Tag>
          ))}
        </HStack>
      ),
    },
    {
      key: '',
      label: '',
      cell: (v, r) => (
        <Tooltip label="Edit Details">
          <Link to={`${pathPrefix}/${r._id}`}>
            <IconButton colorScheme="gray" aria-label="Edit" icon={<FaEdit />} />
          </Link>
        </Tooltip>
      ),
      textAlign: 'right',
    },
  ];

  const headerActions = (
    <ButtonGroup spacing={1}>
      <Button type="button" size="sm" onClick={() => history.goBack()}>
        Back
      </Button>
      <Link to={`${pathPrefix}/add`}>
        <Button size="sm" colorScheme="blue">
          New
        </Button>
      </Link>
    </ButtonGroup>
  );

  return (
    <div>
      <ListPage
        header={"Supplier's Users"}
        subheader="Settings"
        useListValues={useListValues}
        history={history}
        pathPrefix={pathPrefix}
        tableDefintion={tableDefinition}
        withCheckboxes={true}
        headerActions={headerActions}
        getFilterDefinition={getFilterDefinition}
      />
    </div>
  );
}
