import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Segment, Table, Message } from 'semantic-ui-react';
import { PageHeaderRow } from '@inkcloud/components';
import { useSelector } from 'react-redux';

import Feathers from '../../bootstrap/feathers';
import { InvoiceItem } from '../orders/InvoiceItem';

interface PreDetailProps {}

export interface ConnectedProps {
  user?: any;
}

const { useState, useEffect } = React;

export const InvoiceDetail: React.FunctionComponent<
  ConnectedProps & RouteComponentProps<{ id?: string; location?: any }>
> = (props) => {
  const {
    match: {
      params: { id },
    },
    history,
    location,
  } = props;

  const authUser = useSelector((state: any) => state.auth.user);

  const search = new URLSearchParams(location.search);
  const customerId = search.get('customer');
  const humanId = search.get('humanId');

  const [customer, setCustomer] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const loadCustomer = async () => {
      try {
        const res: any = await Feathers.service('/customers').get(customerId, { query: {} });

        setCustomer(res);
      } catch (e) {
        const errMsg =
          e?.code < 500
            ? e?.message
            : 'We are experiencing technical difficulties. Please try again';
        setErrorMessage(errMsg);
      }
    };

    if (customerId) {
      loadCustomer();
    }
  }, [customerId]);

  return (
    <div>
      <PageHeaderRow header={humanId} subheader="Invoice" subheaderId={''}>
        <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
      </PageHeaderRow>

      <Segment>
        {errorMessage && <Message error content={errorMessage} />}
        {customer && (
          <InvoiceItem
            // reload={false}
            customer={customer}
            business={authUser?.business}
            humanId={humanId}
            invoiceId={id}
          />
        )}
      </Segment>
    </div>
  );
};

// const mapStateToProps = (state): ConnectedProps => ({
//   user: state.auth.user || {},
// });

// export const InvoiceDetail = connect(mapStateToProps)(PreDetail as any)
