import * as React from 'react';
import { Switch, Route } from 'react-router-dom';

import InvoicePayments from './Container';

const routes = (props) => (
  <Switch>
    <Route exact path="/invoice/payments/:id" component={InvoicePayments} />
  </Switch>
);

export default routes;
