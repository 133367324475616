import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { PageHeaderRow, PreLoader, useFeathers } from '@inkcloud/components';
import { Segment, Button } from 'semantic-ui-react';

import { PaymentProcessingForm } from './PaymentProcessingForm';

import { connect } from 'react-redux';
import { Feathers } from './../../../bootstrap/feathers/feathers';

interface DetailProps {}

export interface ConnectedProps {
  user?: any;
}

const { useState } = React;

const pathPrefix = '/settings';

export const PreDetail: React.FunctionComponent<
  DetailProps & ConnectedProps & RouteComponentProps
> = (props) => {
  const {
    match: { params },
    history,
    user,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const serviceName = '/tenant-settings/payment';
  const service = Feathers.service(serviceName);
  const commonParams = { query: {} };

  const [state, reload] = useFeathers('get', serviceName, [user.business, commonParams], true);

  const handleSubmit = async (values: any) => {
    const payload = {
      merchantGateway: {
        selectedAdapter: values.selectedAdapter,
        adapters: {
          [values.selectedAdapter]: {
            ...values,
            key: values.selectedAdapter,
          },
          paypal: {
            key: 'paypal',
            ...values.paypal,
          },
        },
      },
    };

    try {
      const res: any = await service.patch(user.business, payload, commonParams);

      if (res.result !== undefined && res.result === false) {
        setErrorMessage(res.message || res.errorMessage);
      }
      history.push(pathPrefix);
    } catch (e) {
      e.code && e.code < 500
        ? setErrorMessage(e.message)
        : setErrorMessage('We are experiencing technical difficulties. Please try again');
    }
  };

  if (!state.isFinished) {
    return <PreLoader />;
  }

  const initialData = { ...state.data?.merchantGateway };

  return (
    <>
      <PageHeaderRow header="Payment Processing" subheader="Settings">
        <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
      </PageHeaderRow>
      <Segment>
        <PaymentProcessingForm
          initialValues={initialData}
          errorMessage={errorMessage}
          onSubmit={handleSubmit}
        />
      </Segment>
    </>
  );
};

const mapStateToProps = (state): ConnectedProps => ({
  user: state.auth.user || {},
});

export const Detail = connect(mapStateToProps)(PreDetail as any);
