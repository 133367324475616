import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Button,
  Modal,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  Flex,
  Center,
  Spinner,
} from '@chakra-ui/react';

import JobSchedulingColumnForm from './JobSchedulerWorkflowColumnsForm';

import List from './List';
import { Feathers } from '../../../bootstrap/feathers/feathers';

interface IJobSchedulingWorkflowColumnsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const pathPrefix = '/job-scheduler/columns';

const JobSchedulingWorkflowColumnsModal: React.FunctionComponent<
  IJobSchedulingWorkflowColumnsModalProps
> = (props) => {
  const { isOpen, onClose } = props;

  const history = useHistory();

  const { id } = useParams<{ id: string }>();

  const isEdit = !!id;
  const isAdd = history.location.pathname.includes('add');

  const [column, setColumn] = useState({ name: '', sortOrder: undefined });

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const loadColumn = async () => {
      setIsLoading(true);
      try {
        const res = await Feathers.service('job-scheduler/columns').get(id);

        // console.log('template res', res);

        setColumn(res);
      } catch (e) {
        console.log('e');
      }
      setIsLoading(false);
    };

    if (id) {
      loadColumn();
    }
  }, [id]);

  const handleSubmit = async (v) => {
    setIsSubmitting(true);
    setErrorMessage('');

    const payload = v;

    try {
      if (isEdit) {
        const res = await Feathers.service('job-scheduler/columns').patch(id, payload);
      } else {
        const res = await Feathers.service('job-scheduler/columns').create(payload);
      }

      history.push(pathPrefix);
    } catch (e) {
      const errMsg = e?.code < 500 ? e?.message : 'Something went wrong. Please try again later.';
      setErrorMessage(errMsg);
    }
    setIsSubmitting(false);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'5xl'}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Job Scheduling Columns</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {isAdd || isEdit ? (
            <>
              <Flex justifyContent="end">
                <Button onClick={() => history.goBack()}>Back</Button>
              </Flex>
              {isLoading ? (
                <Center>
                  <Spinner />
                </Center>
              ) : (
                <JobSchedulingColumnForm
                  initialValues={column}
                  errorMessage={errorMessage}
                  isSubmitting={isSubmitting}
                  onSubmit={handleSubmit}
                />
              )}
            </>
          ) : (
            <List />
          )}
        </ModalBody>

        <ModalFooter>
          <Button mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default JobSchedulingWorkflowColumnsModal;
