/* eslint-disable no-undef */
import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  FormControl,
  FormLabel,
  Box,
  Input,
  Button,
  Flex,
  Text,
  Alert,
  Heading,
} from '@chakra-ui/react';
import { Feathers } from '../../bootstrap/feathers/feathers';
import icLogo from '../../images/ic-logo.svg';

const resetStyle = {
  height: '100%',
  backgroundImage:
    'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("https://storage.googleapis.com/inkcloud-assets/login-bg1.jpg")',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
};

const devHost = '.localdev.inkcloud9.com';
const sandboxHost = '.app.inkcloud9.dev';
const mainHost = '.inkcloud9.app';

export default function ForgotPassword() {
  const emailRef = useRef<HTMLInputElement>(null);

  const [responseMessage, setResponseMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleReset = async () => {
    setErrorMessage('');
    setResponseMessage('');
    setIsSubmitting(true);

    const email = emailRef.current?.value?.trim();

    const payload = { email, tenantKey: '' };
    if (window.location.hostname.endsWith(devHost)) {
      payload.tenantKey = window.location.hostname.slice(0, devHost.length * -1);
    } else if (window.location.hostname.endsWith(mainHost)) {
      payload.tenantKey = window.location.hostname.slice(0, mainHost.length * -1);
    } else if (window.location.hostname.endsWith(sandboxHost)) {
      payload.tenantKey = window.location.hostname.slice(0, sandboxHost.length * -1);
    }

    try {
      const res = await Feathers.service('/users/reset-password').create(payload);
      setResponseMessage("We've sent you an email with instructions to reset your password.");
    } catch (e) {
      const errMsg = e?.code < 500 ? e?.message : 'Something went wrong. Please try again later.';
      setErrorMessage(errMsg);
    }

    setIsSubmitting(false);
  };

  return (
    <Flex h="100vh" alignItems="center" justifyContent="center" style={resetStyle}>
      <Box w="100%" maxW="325px" bg="white" borderRadius="md" py={3} px={4} maxWidth="350px">
        <Box mb={6} textAlign="center">
          <object width="100" style={{ margin: 'auto' }} type="image/svg+xml" data={icLogo}>
            SVG not supported
          </object>
        </Box>
        <Heading size={'md'} my={8}>
          Reset Password
        </Heading>
        <Box mb={3}>
          <FormControl>
            <FormLabel fontWeight="bold">Email Address</FormLabel>
            <Input type="email" ref={emailRef} />
          </FormControl>
        </Box>
        <Box mb={3}>
          <Text fontSize="sm">
            <Link to="/login">Go back Login</Link>
          </Text>
        </Box>
        {responseMessage && (
          <Alert status="success" mb={3}>
            {responseMessage}
          </Alert>
        )}
        {errorMessage && (
          <Alert status="error" mb={3}>
            {errorMessage}
          </Alert>
        )}
        <Box mb={3}>
          <Button
            type="button"
            isDisabled={isSubmitting}
            isLoading={isSubmitting}
            onClick={handleReset}
            colorScheme="blue"
            w="full"
          >
            Reset
          </Button>
        </Box>
      </Box>
    </Flex>
  );
}
