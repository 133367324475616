import * as React from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react';
import { FormattedDate, FormattedNumber } from 'react-intl';
import { useSelector } from 'react-redux';
import { formatAsPercentage } from './helpers';

interface ISplitTableProps {
  results: any;
  splits: string[];
}

const SplitTable: React.FunctionComponent<ISplitTableProps> = (props) => {
  const { results, splits = [] } = props;
  if (!results) {
    return null;
  }
  const tenant = useSelector(
    ({
      globals,
    }: {
      globals: {
        tenant: { internationalization: { locale: string; currency: string; countryCode: string } };
      };
    }) => globals.tenant.internationalization
  );

  // in case tenant is not defined yet
  const { currency = 'USD' } = (tenant ?? { currency: 'USD' });

  const { data } = results;

  return (
    <TableContainer>
      <Table size={'sm'}>
        <TableCaption>Financial Metrics</TableCaption>
        <Thead>
          <Tr>
            {splits.find((s: string) => s.includes('time')) && <Th>Period</Th>}
            {splits.includes('customer') && <Th>Client</Th>}
            {splits.includes('requestor') && <Th>Requestor</Th>}
            {splits.includes('assigned-to') && <Th>PC</Th>}
            {splits.includes('supplier') && <Th>Supplier</Th>}
            <Th isNumeric>Count</Th>
            <Th isNumeric>Revenue</Th>
            <Th isNumeric>COGS</Th>
            <Th isNumeric>Profit</Th>
            <Th isNumeric>Margin</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((row: any, idx) => (
            <Tr key={idx}>
              {splits.find((s: string) => s.includes('time')) && (
                <Td>
                  <FormattedDate value={row?.date} />
                </Td>
              )}
              {splits.includes('customer') && <Td>{row.customer}</Td>}
              {splits.includes('requestor') && <Td>{row.requestor}</Td>}
              {splits.includes('assigned-to') && <Td>{row.assignedTo}</Td>}
              <Td isNumeric>{row.count}</Td>
              <Td isNumeric>
                <FormattedNumber
                  currency={currency}
                  value={Number(row?.revenue || 0)}
                  style="currency"
                />
              </Td>
              <Td isNumeric>
                <FormattedNumber
                  currency={currency}
                  value={Number(row?.cogs || 0)}
                  style="currency"
                />
              </Td>
              <Td isNumeric>
                <FormattedNumber
                  currency={currency}
                  value={Number(row?.profit || 0)}
                  style="currency"
                />
              </Td>
              <Td isNumeric>{formatAsPercentage(row?.profitMargin)}</Td>
            </Tr>
          ))}
        </Tbody>
        {/* <Tfoot>
        <Tr>
        <Td>Levis</Td>
          <Td isNumeric>345,670</Td>
          <Td isNumeric>325,670</Td>
          <Td isNumeric>45,670</Td>
          <Td isNumeric>10%</Td>
        </Tr>
      </Tfoot> */}
      </Table>
    </TableContainer>
  );
};

export default SplitTable;
