import React, { useState, useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  Alert,
  Checkbox,
  Stack,
  Text,
  List,
  ListItem,
  ListIcon,
  OrderedList,
  UnorderedList,
} from '@chakra-ui/react';

import type { ModelTypes } from '@inkcloud/icapi-types';

import feathers from '../../bootstrap/feathers';

interface IDuplicateModalProps {
  data: NonNullable<ModelTypes.PurchaseOrder>;
  onClose: () => void;
}

const fieldsToDuplicate = [
  { value: 'billTo', label: 'Bill To' },
  { value: 'shipTo', label: 'Ship To' },
  { value: 'csr', label: 'Customer Sales Representative' },
  { value: 'rfq', label: 'RFQ Number' },
  { value: 'salesOrderNum', label: 'Sales Order Number' },
  { value: 'projectName', label: 'Project Name' },
  { value: 'poDueDate', label: 'PO Due Date' },
  { value: 'soShipDate', label: 'Sales Order Ship Date' },
  { value: 'items', label: 'Items' },
];

const serviceName = `/purchase-orders`;
const service = feathers.service(serviceName);

const PreDuplicateModal = (props: IDuplicateModalProps & RouteComponentProps) => {
  const { data, onClose, history } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [checkField, setCheckField] = useState<Record<string, boolean>>({});

  useEffect(() => {
    const allFields = fieldsToDuplicate?.reduce((acc, cur) => {
      acc[cur.value] = true;

      return acc;
    }, {});

    setCheckField(allFields);
  }, []);

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = e.target;

    setCheckField({ ...checkField, [name]: checked });
  };

  const handleToggleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;

    const allFields = fieldsToDuplicate?.reduce((acc, cur) => {
      acc[cur.value] = checked;

      return acc;
    }, {});

    setCheckField(allFields);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    setErrorMessage('');
    try {
      const payload: ModelTypes.PurchaseOrder = {
        ...data,
        _id: undefined,
        createdAt: undefined,
        updatedAt: undefined,
        humanId: undefined,
        poDueDate: undefined,
        salesOrderNum: undefined,
        salesOrder: undefined,
        metadata: [],
        status: undefined,
        receivings: [],
        approvedAt: undefined,
        approvedBy: undefined,
        approvedByModel: undefined,
        createdFromRfq: undefined,
        isApproved: undefined,
        isSent: undefined,
        isVendorAcknowledged: undefined,
        vendorAcknowledgedAt: undefined,
        sentAt: undefined,
        items: data.items?.map((i) => ({ ...i, _id: undefined })),
      };
      const res = await service.create(payload, { query: {} });

      onClose();

      history.push(`/purchase-orders/${res?._id}`);
    } catch (e) {
      const errMsg = e?.code < 500 ? e?.message : 'Something went wrong. Please try again later.';
      setErrorMessage(errMsg);
    }
    setIsSubmitting(false);
  };

  const isAllChecked = Object.keys(checkField)?.every((c) => checkField[c]);
  const someIsChecked = Object.keys(checkField)?.some((c) => checkField[c]);

  return (
    <Modal size="2xl" isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Purchase Order {data?.humanId || ''}</ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <Stack spacing={5} mb={3} direction="column">
            <FormControl></FormControl>
            <div>
              This purchase order will be duplicated except for the following fields:
              <UnorderedList>
                <ListItem>PO Due Date</ListItem>
                <ListItem>Sales Order #</ListItem>
                <ListItem>Metadata</ListItem>
              </UnorderedList>
            </div>
          </Stack>

          {errorMessage && <Alert status="error">{errorMessage}</Alert>}
        </ModalBody>

        <ModalFooter>
          <Button
            isDisabled={isSubmitting || !someIsChecked}
            isLoading={isSubmitting}
            colorScheme="blue"
            mr={3}
            onClick={handleSubmit}
          >
            Submit
          </Button>
          <Button isDisabled={isSubmitting} mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
const DuplicateModal = withRouter(PreDuplicateModal);
export default DuplicateModal;
