import * as React from 'react';
import { Field } from 'redux-form';
import { semanticFormDropdownField, semanticFormField } from './../../../common';
import { Form, Table, Button } from 'semantic-ui-react';
import Feathers from './../../../bootstrap/feathers';

interface CompositeProps {
  fields: any;
}

const { useState, useEffect } = React;

export const Composite: React.FunctionComponent<CompositeProps> = (props) => {
  const { fields } = props;

  const [products, setProducts] = useState([]);

  useEffect(() => {
    const loadProducts = async () => {
      try {
        const res: any = await Feathers.service('products-legacy').find({
          query: {
            $np: 1,
            $sort: { key: 1 },
            $select: ['staticName', 'key'],
          },
        });

        const mappedProducts = res?.map((r) => ({ value: r._id, text: r.key })) ?? [];
        setProducts(mappedProducts);
      } catch (e) {}
    };

    loadProducts();
  }, []);

  return (
    <>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              <label>SKU</label>
            </Table.HeaderCell>
            <Table.HeaderCell>
              <label>Quantity</label>
            </Table.HeaderCell>
            <Table.HeaderCell>
              <label>Price</label>
            </Table.HeaderCell>
            <Table.HeaderCell />
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {fields.map((field, index) => (
            <Table.Row key={index}>
              <Table.Cell>
                <Field
                  name={`${field}.product`}
                  component={semanticFormDropdownField}
                  as={Form.Dropdown}
                  selection
                  search
                  options={products}
                />
              </Table.Cell>
              <Table.Cell>
                <Field name={`${field}.quantity`} component={semanticFormField} as={Form.Input} />
              </Table.Cell>
              <Table.Cell>
                <Field name={`${field}.price`} component={semanticFormField} as={Form.Input} />
              </Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell textAlign="right">
                <Button type="button" icon="remove" onClick={() => fields.remove(index)} />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

      <Button size="tiny" content="Add" type="button" onClick={() => fields.push({})} />
    </>
  );
};
