import * as React from 'react';
import { Switch, Route } from 'react-router-dom';

import { List } from './List';
import { Detail } from './Detail';

const routes = (props) => {
  return (
    <Switch>
      <Route exact path="/products/add-on-pricing" component={List} />
      <Route exact path="/products/add-on-pricing/add" component={Detail} />
      <Route exact path="/products/add-on-pricing/:id" component={Detail} />
    </Switch>
  );
};

export default routes;
