import * as React from 'react';
import { Form } from 'semantic-ui-react';
import countries from 'world-countries';
import provinces from 'provinces';

export interface ShippingAddress {
  name: string;
  careOf: string;
  address1: string;
  address2: string;
  city: string;
  stateProvince: string;
  postalCode: string;
  countryCode: string;
}

export interface ShippingAddressFormProps {
  onChange: any;
  address: ShippingAddress;
}

export class ShippingAddressForm extends React.Component<
  ShippingAddressFormProps,
  ShippingAddress
> {
  constructor(props: ShippingAddressFormProps) {
    super(props);

    this.state = {
      name: '',
      careOf: '',
      address1: '',
      address2: '',
      city: '',
      stateProvince: '',
      postalCode: '',
      countryCode: 'US',
    };
  }

  componentDidMount() {
    const { address } = this.props;

    if (!address) {
      return;
    }

    this.setState({
      ...this.state,
      name: address.name,
      careOf: address.careOf,
      address1: address.address1,
      address2: address.address2,
      city: address.city,
      stateProvince: address.stateProvince,
      postalCode: address.postalCode,
      countryCode: address.countryCode,
    });
  }

  handleChange = (e: any, data: any) => {
    this.setState({ ...this.state, [data.name]: data.value }, () =>
      this.props.onChange(this.state)
    );
  };

  render() {
    return (
      <Form size={'mini'}>
        <Form.Dropdown
          placeholder={'Country...'}
          value={this.state.countryCode}
          selection
          fluid
          options={countries.map((c: any) => ({ text: c.name.official, value: c.cca2 }))}
          onChange={(e, data: any) => this.setState({ ...this.state, countryCode: data.value })}
        />
        <Form.Input
          name="name"
          placeholder="Name"
          onChange={this.handleChange}
          value={this.state.name}
          fluid
        />
        <Form.Input
          name="careOf"
          placeholder={'Care Of'}
          onChange={this.handleChange}
          value={this.state.careOf}
          fluid
        />
        <Form.Input
          name="address1"
          placeholder={'Address 1'}
          onChange={this.handleChange}
          value={this.state.address1}
          fluid
        />
        <Form.Input
          name="address2"
          placeholder={'Address 2'}
          onChange={this.handleChange}
          value={this.state.address2}
          fluid
        />
        <Form.Input
          name={'city'}
          placeholder={'City'}
          onChange={this.handleChange}
          value={this.state.city}
          fluid
        />
        <Form.Group widths={'equal'}>
          <Form.Dropdown
            name={'stateProvince'}
            value={this.state.stateProvince}
            selection
            fluid
            placeholder={'Select One...'}
            options={provinces
              .filter((p) => p.country === this.state.countryCode)
              .map((c: any) => ({ text: c.name, value: c.short }))}
            onChange={this.handleChange}
          />
          <Form.Input
            name={'postalCode'}
            placeholder={'Postal Code'}
            fluid
            value={this.state.postalCode}
            onChange={this.handleChange}
          />
        </Form.Group>
      </Form>
    );
  }
}
