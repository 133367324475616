// import * as React from 'react'
// import { Link, RouteComponentProps } from 'react-router-dom';
// import { useList, PageHeaderRow, PaginatorWrapper, EmptyWrapper } from '@inkcloud/components';
// import { Button, Form, Table, Message, Popup, Segment } from 'semantic-ui-react'

// export interface ListProps { }

// const serviceName = '/products-legacy/price-lists';
// const pathPrefix = `/settings/price-lists`;

// const filterQueryMap = (values) => { };

// const { useState, useEffect } = React
// export const List: React.FunctionComponent<ListProps & RouteComponentProps> = props => {

//   const { match: { params }, location, history, } = props;

//   const { state, reload, handleDelete, selectedItems, clearAll, setChecked, someSelected, toggleAll, filter, setFilter, sort, handleSort } = useList({
//     feathers: ['find', serviceName, [{ query: {} }]],
//     filterMap: filterQueryMap,
//     debounce: 150,
//     location,
//     history,
//     sort: { createdAt: -1 }
//   })

//   const { queryResult, isLoading: isStateLoading } = state as any;

//   if (!queryResult) {
//     return null;
//   }

//   return <>
//     <PageHeaderRow header='Price Lists' subheader='Settings'>
//       <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
//       <Link to={`${pathPrefix}/add`}><Button content="New" primary size={'mini'} /></Link>
//     </PageHeaderRow>
//     <div></div>
//     <Segment >
//       <PaginatorWrapper.Top filter={filter} reload={reload} data={(queryResult as any)} pathPrefix={`${pathPrefix}/`} />
//       <Table size={'small'}>
//         <Table.Header>
//           <Table.Row>
//             <Table.HeaderCell><input type="checkbox" onChange={toggleAll} /></Table.HeaderCell>
//             <Table.HeaderCell>Name</Table.HeaderCell>
//             <Table.HeaderCell>Description</Table.HeaderCell>
//             <Table.HeaderCell>Percentage</Table.HeaderCell>
//             <Table.HeaderCell />
//           </Table.Row>
//         </Table.Header>
//         <Table.Body>
//           {queryResult && (queryResult as any).data.map((r: any, index: number) => (
//             <Table.Row verticalAlign='top' key={index}>
//               <Table.Cell>
//                 <input type="checkbox" value={r._id} checked={selectedItems[r._id] || false} onChange={setChecked} />
//               </Table.Cell>
//               <Table.Cell>
//                 {r.name}
//               </Table.Cell>
//               <Table.Cell>
//                 {r.description}
//               </Table.Cell>
//               <Table.Cell>
//                 {r.defaultPercentage}
//               </Table.Cell>
//               <Table.Cell textAlign="right">
//                 <Popup
//                   size='mini'
//                   content="Edit Price List"
//                   trigger={
//                     <Link to={`${pathPrefix}/${r._id}`}>
//                       <Button size={'mini'} icon={'edit'} />
//                     </Link>
//                   }
//                 />
//               </Table.Cell>
//             </Table.Row>
//           ))}
//         </Table.Body>
//       </Table>
//       <PaginatorWrapper.Bottom reload={reload} data={(queryResult as any)} pathPrefix={`${pathPrefix}`}>
//         {someSelected && <Button primary type="button" content="Delete" onClick={handleDelete} />}
//       </PaginatorWrapper.Bottom>
//     </Segment>
//   </>
// }

import React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { FormattedDate, FormattedTime } from 'react-intl';
import { Button, Box, IconButton, Tooltip, ButtonGroup } from '@chakra-ui/react';

import { FaEdit } from 'react-icons/fa';

import { useList, ListPage } from '@inkcloud/components';

interface ListProps {}

const serviceName = '/products-legacy/price-lists';
const pathPrefix = `/settings/price-lists`;

const filterQueryMap = (values) => {};

export default function List(props: ListProps & RouteComponentProps) {
  const {
    match: { params },
    location,
    history,
  } = props;

  const tableDefinition = [
    {
      key: 'createdAt',
      label: 'Date',
      sortable: true,
      cell: (v) => (
        <Tooltip hasArrow aria-label="time" label={<FormattedTime value={v} />}>
          <Box as="span">
            <FormattedDate value={v} />
          </Box>
        </Tooltip>
      ),
    },
    {
      key: 'name',
      label: 'Name',
    },
    {
      key: 'description',
      label: 'Description',
    },
    {
      key: 'defaultPercentage',
      label: 'Percentage',
    },
    {
      key: '',
      label: '',
      sortable: false,
      cell: (v, r) => (
        <Tooltip label="Edit Details">
          <Link to={`${pathPrefix}/${r._id}`}>
            <IconButton colorScheme="gray" aria-label="Edit" icon={<FaEdit />} />
          </Link>
        </Tooltip>
      ),
      textAlign: 'right',
    },
  ];

  const useListValues = useList({
    feathers: ['find', serviceName, [{ query: {} }]],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  const headerActions = (
    <>
      <ButtonGroup spacing={1}>
        <Button type="button" size="sm" onClick={() => history.goBack()}>
          Back
        </Button>
        <Link to={`${pathPrefix}/add`}>
          <Button size="sm" colorScheme="blue">
            New
          </Button>
        </Link>
      </ButtonGroup>
    </>
  );

  return (
    <div>
      <ListPage
        header="Price Lists"
        subheader="Settings"
        useListValues={useListValues}
        history={history}
        pathPrefix={pathPrefix}
        tableDefintion={tableDefinition}
        withCheckboxes={true}
        headerActions={headerActions}
        getFilterDefinition={null}
      />
    </div>
  );
}
