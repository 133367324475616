import * as React from 'react';
import { Form, Checkbox } from 'semantic-ui-react';

interface ShippingMethodProps {
  shippingMethods: any[];
  value: string;
  onChangeShipMethod: (v: any) => void;
  setFieldValue: (name: string, value: any) => void;
}

export const ShippingMethods: React.FunctionComponent<ShippingMethodProps> = (props) => {
  const { shippingMethods, onChangeShipMethod, value, setFieldValue } = props;

  return (
    <Form.Group inline>
      {shippingMethods &&
        shippingMethods.length > 0 &&
        shippingMethods.map((s: any, index: number) => (
          <Form.Field key={index}>
            <Checkbox
              radio
              label={s.description}
              name="shippingMethod"
              value={s.key}
              checked={value === s.key}
              onChange={(e, data) => {
                onChangeShipMethod(data.value);

                setFieldValue('shippingMethod', data.value);
              }}
            />
          </Form.Field>
        ))}
    </Form.Group>
  );
};
