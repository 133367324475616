import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Segment } from 'semantic-ui-react';
import { PageHeaderRow, PreLoader, useFeathers } from '@inkcloud/components';
import feathers from './../../../bootstrap/feathers';
import { UserRoleForm } from './UserRoleForm';

interface DetailProps {}

const { useState } = React;

const serviceName = 'user-roles';
const service = feathers.service(serviceName);
const commonParams = { query: {} };

export const Detail: React.FunctionComponent<DetailProps & RouteComponentProps<{ id?: string }>> = (
  props
) => {
  const {
    match: {
      params: { id },
    },
    history,
  } = props;

  const pathPrefix = `/admin/user-roles`;

  const [errorMessage, setErrorMessage] = useState('');

  const isEdit = !!id;
  const [state, reload] = useFeathers('get', serviceName, [id, commonParams], true);
  const handleSubmit = async (values) => {
    setErrorMessage('');

    values = {
      ...values,
      key: values?.key?.replace(/-+$/, ''), // Trim (-) hyphen from end of text
    };

    try {
      const res: any = isEdit
        ? await service.patch(id, values, commonParams)
        : await service.create(values, commonParams);

      if (!res?.result) {
        setErrorMessage(res?.message);
      }

      history.push(pathPrefix);
    } catch (e) {
      e.code && e.code < 500
        ? setErrorMessage(e.message)
        : setErrorMessage('We are experiencing technical difficulties. Please try again');
    }
  };

  const pageTitle = isEdit ? 'Edit' : 'New';

  if (isEdit && !state.data) {
    return <PreLoader />;
  }

  const initialData = { ...state.data };
  return (
    <div>
      <PageHeaderRow header={`${pageTitle} Role`} subheader="User" subheaderId={''}>
        <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
      </PageHeaderRow>
      <Segment>
        {(!isEdit || state.data) && (
          <UserRoleForm
            initialValues={initialData}
            errorMessage={errorMessage}
            onSubmit={handleSubmit}
          />
        )}
      </Segment>
    </div>
  );
};
