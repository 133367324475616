import * as React from 'react';
// import { Button, Input, Table } from 'semantic-ui-react';
import { Button, Input, Tr, Td, IconButton } from '@chakra-ui/react';
import AsyncSelect from 'react-select/async';
import { CloseIcon } from '@chakra-ui/icons';
import Select from 'react-select';
import { ModelTypes, ServiceTypes } from '@inkcloud/icapi-types';
import { icapi } from '../../bootstrap/feathers';

type ICEntity<T extends keyof ServiceTypes> = Awaited<ReturnType<ServiceTypes[T]['get']>>;

type RuleEngineDefinition = ICEntity<'rules-engine/definitions'>;

export interface RuleConditionValue {
  selectedCondition: string;
  selectedOperator: string;
  value: any;
}
interface IRuleEngineConditionProps {
  ruleDefinition: RuleEngineDefinition;
  value: RuleConditionValue;
  onChange: (v: RuleConditionValue) => void;
}

const RuleEngineCondition: React.FunctionComponent<IRuleEngineConditionProps> = (props) => {
  const { ruleDefinition, value, onChange } = props;
  const [loadedAsyncOptions, setLoadedAsyncOptions] = React.useState([]);

  // console.log('!!! rule definition', ruleDefinition);
  // console.log('value', value);
  const availableConditionsOptions = ruleDefinition.availableConditions.map((a) => ({
    label: a.name,
    value: a.id,
  }));

  const foundCondition = ruleDefinition.availableConditions.find(
    (ac) => ac.id === value.selectedCondition
  );
  const availableOperators = foundCondition?.operators.map((o) => ({ label: o, value: o }));

  let optionsAsync;
  if (foundCondition) {
    optionsAsync = foundCondition.optionsAsync;
  }

  return (
    <Tr>
      <Td>
        <Select
          value={
            value.selectedCondition
              ? availableConditionsOptions.find((ac) => ac.value === value.selectedCondition)
              : ''
          }
          placeholder="Select a condition..."
          options={availableConditionsOptions}
          onChange={(e: any) => onChange({ ...value, selectedCondition: e.value })}
        />
      </Td>
      <Td>
        <Select
          value={
            value.selectedOperator
              ? availableOperators.find((ao) => ao.value === value.selectedOperator)
              : ''
          }
          placeholder="Select a placeholder..."
          options={availableOperators}
          onChange={(e: any) => onChange({ ...value, selectedOperator: e.value })}
        />
      </Td>
      <Td>
        {optionsAsync && (
          <AsyncSelect
            onChange={(e: any) => onChange({ ...value, value: e.value })}
            placeholder="Search..."
            loadOptions={async (v) => {
              const results = await (icapi.service(optionsAsync.service) as any).find({
                query: {
                  ...optionsAsync.query,
                },
              });
              const { keyField, labelField } = optionsAsync;
              const r = results.data ? results.data : results;
              const options = r.map((r: any) => ({ label: r[labelField], value: r[keyField] }));
              setLoadedAsyncOptions(options);
              return options;
            }}
            // isDisabled={isDisabled}
            isClearable={true}
            value={loadedAsyncOptions.find((a) => a.value === value.value)}
            defaultOptions={true}
            noOptionsMessage={(v) => (!v.inputValue ? 'Start typing to search...' : 'No options')}
            // defaultValue={defaultValue}
            styles={{
              container: (provided, state) => ({
                ...provided,
                // border: '1px solid red',
                flexGrow: 2,
                // border: 'none',
                width: '100%',
                // flexGrow: 1,
                // borderRight: '1px solid #eaeaea',
                padding: 4,
              }),
              valueContainer: (provided, state) => ({
                ...provided,
                // border: '1px solid none',
              }),
              control: (provided, state) => ({
                ...provided,
                border: '1px solid #eaeaea',
              }),
            }}
          />
        )}

        {!optionsAsync && (
          <Input
            value={value.value}
            onChange={(e) => onChange({ ...value, value: e.target.value })}
          />
        )}
      </Td>
      <Td textAlign="right">
        <IconButton aria-label="Remove" icon={<CloseIcon />} />
      </Td>
    </Tr>
  );
};

export default RuleEngineCondition;
