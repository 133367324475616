import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Form, Checkbox, Message, Header, Icon, Segment } from 'semantic-ui-react';
import { PageHeaderRow } from '@inkcloud/components';
import { To } from '@inkcloud/components';

import Feathers from '../../../bootstrap/feathers';

import { connect } from 'react-redux';

interface BrandingFormProps {}

export interface ConnectedProps {
  user?: any;
}

const { useState, useEffect } = React;

export const PreBrandingForm: React.FunctionComponent<
  BrandingFormProps & ConnectedProps & RouteComponentProps
> = (props) => {
  const { user, history } = props;

  return (
    <>
      <PageHeaderRow header={`Branding`} subheader="Settings">
        <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
      </PageHeaderRow>
      <Segment placeholder>
        <Header icon>Branding</Header>
      </Segment>
    </>
  );
};

const mapStateToProps = (state): ConnectedProps => ({
  user: state.auth.user || {},
});

export const BrandingForm = connect(mapStateToProps)(PreBrandingForm as any);
