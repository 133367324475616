import * as React from 'react';
import { Document } from '@react-pdf/renderer';
import type { ModelTypes } from '@inkcloud/icapi-types';
import { OrderItemLabelContext, OrderItemLabelProvider } from './OrderItemLabelContext';

type OrderItemPopulated = Omit<ModelTypes.OrderItems, 'customer' | 'order'> & {
  customer: ModelTypes.ProductLegacy;
  order: ModelTypes.Orders;
};

export type OrderItemLabelDocumentContext = Omit<ModelTypes.OrderItems, 'order' | 'customer'> & {
  orderItem: OrderItemPopulated;

  tenantInfo: {
    locale: string;
    currency: string;
    timezone: string;
  };
};

interface InitialState {
  config: any;
  dataCtx: OrderItemLabelDocumentContext;
  styles: any;
}

export interface IOrderItemLabelDocumentProps {
  _initialState?: InitialState;
  render: (v: InitialState) => any;
  children: React.ReactNode;
}

export const mockOptions = {
  title: 'Order Item Label',
  creator: 'Ink Cloud',
  producer: 'Ink Cloud',
};

export const OrderItemLabelDocument: React.FunctionComponent<IOrderItemLabelDocumentProps> = (
  props
) => {
  const { title, creator, producer } = mockOptions;
  const { children, _initialState, render } = props;

  return (
    <OrderItemLabelProvider initialState={_initialState}>
      <Document title={title} creator={creator} producer={producer}>
        {render ? render(_initialState) : children}
      </Document>
    </OrderItemLabelProvider>
  );
};
