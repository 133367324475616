// import * as React from 'react';
// import { RouteComponentProps } from 'react-router-dom';
// import { Button, Segment } from 'semantic-ui-react';
// import { PageHeaderRow, PreLoader, useFeathers } from '@inkcloud/components';
// import feathers from '../../../bootstrap/feathers';
// import { DashboardUserForm } from './DashboardUserForm';
// import { UpdatePasswordModal } from './UpdatePassword';

// interface DetailProps {
//   products: { value: string, text: string }[];
//   categories: { value: string, text: string }[];
// }

// const { useState } = React;

// const pathPrefix = `/settings/team-members`;
// const serviceName = 'user';
// const service = feathers.service(serviceName);
// const commonParams = { query: {} };

// export const Detail: React.FunctionComponent<DetailProps & RouteComponentProps<{ id?: string, }>> = (props) => {
//   const { match: { params: { id } }, history } = props;

//   const isEdit = !!id;
//   const [state, reload] = useFeathers('get', serviceName, [id, commonParams], true);
//   const handleSubmit = async (values) => {
//     const updatedvalues = { ...values, vendor: values.vendor ? values.vendor : null };
//     isEdit ? await service.patch(id, updatedvalues, commonParams) : await service.create(updatedvalues, commonParams); history.push(pathPrefix);
//   }

//   const [isOpen, setIsOpen] = useState(false);

//   const handleUpdatePassword = async ({ password, confirmPassword }) => {

//     const response = await feathers.service('/user').patch(id, {
//       password, confirmPassword
//     });

//     setIsOpen(false)
//   }

//   const pageTitle = isEdit ? 'Edit' : 'New';

//   if (isEdit && !state.data) {
//     return <PreLoader />
//   }

//   const initialData = { ...state.data, };
//   return (
//     <div>
//       <PageHeaderRow header={`${pageTitle} Settings`} subheader='Team Members' subheaderId={''}>
//         <Button content="Update Password" size={'mini'} onClick={() => setIsOpen(true)} />
//         <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
//       </PageHeaderRow>
//       <Segment>
//         {(!isEdit || state.data) && <DashboardUserForm initialValues={initialData} isEdit={isEdit} onSubmit={handleSubmit} />}
//       </Segment>
//       {
//         isOpen && <UpdatePasswordModal
//           onClose={() => setIsOpen(false)}
//           onSubmit={handleUpdatePassword}
//         />
//       }
//     </div>
//   );
// };

import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Box, Button } from '@chakra-ui/react';
import { PageHeaderRow, PreLoader, useFeathers } from '@inkcloud/components';
import type { ModelTypes } from '@inkcloud/icapi-types';

import feathers from '../../../bootstrap/feathers';

import UserForm from './UserForm';
import PasswordModal from './PasswordModal';

const serviceName = 'user';
const service = feathers.service(serviceName);
const commonParams = { query: { $populate: ['roles2', 'vendor'] } };

const pathPrefix = '/settings/team-members';

export default function Detail(props: RouteComponentProps<{ supplierId: string; id: string }>) {
  const {
    match: {
      params: { supplierId, id },
    },
    history,
  } = props;

  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isEdit = !!id;

  const [state, reload] = useFeathers('get', serviceName, [id, commonParams], true);

  const handleSubmit = async (values) => {
    setErrorMessage('');
    setIsSubmitting(true);

    const updatedValue = {
      ...values,
      vendor: values?.vendor?.value,
      $unset: values?.vendor?.value === undefined ? { vendor: 1 } : undefined,
    };

    try {
      if (isEdit) {
        await service.patch(id, updatedValue, commonParams);
      } else {
        await service.create(updatedValue, commonParams);
      }

      history.push(pathPrefix);
    } catch (e) {
      const errMsg =
        e?.code < 500 ? e?.message : 'We are experiencing technical difficulties. Please try again';
      setErrorMessage(errMsg);
    }

    setIsSubmitting(false);
  };

  if (isEdit && !state.data) {
    return <PreLoader />;
  }

  const initialData: ModelTypes.User = { ...state.data };

  return (
    <Box>
      <PageHeaderRow
        header={`${isEdit ? 'Edit' : 'New'} Team Member`}
        subheader="Settings"
        subheaderId={''}
      >
        {isEdit && (
          <Button variant="solid" size="sm" mr={2} onClick={() => setIsModalOpen(true)}>
            Update Password
          </Button>
        )}
        <Link to={pathPrefix}>
          <Button variant="solid" size="sm" onClick={() => history.goBack()}>
            Back
          </Button>
        </Link>
      </PageHeaderRow>

      <Box mt={3}>
        <UserForm
          initialValues={initialData}
          isSubmitting={isSubmitting}
          errorMessage={errorMessage}
          onSubmit={handleSubmit}
          isEdit={isEdit}
        />
      </Box>

      {isModalOpen && (
        <PasswordModal
          onClose={() => setIsModalOpen(false)}
          data={initialData}
          isSubmitting={isSubmitting}
          errorMessage={errorMessage}
          onSubmit={handleSubmit}
        />
      )}
    </Box>
  );
}
