import * as React from 'react';
import { Button, Message, Segment, Grid, List, Divider } from 'semantic-ui-react';
import { PageHeaderRow } from '@inkcloud/components';
import { Switch, Route, Link, RouteComponentProps } from 'react-router-dom';

import { connect } from 'react-redux';
import feathers from '../../../bootstrap/feathers';

import Pages from './pages';
import ImageManager from './image-manager';
import Banners from './banners';
import Menus from './menus';
import Theme from './theme';
import Templates from './templates';

declare const ENV;

export interface ConnectedProps {
  user?: any;
}

const { useState, useEffect } = React;

const PreIndexComponent: React.FunctionComponent<ConnectedProps> = (props) => {
  const { user } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [urlPrefix, setUrlPrefix] = useState({ key: '', domainName: '' });

  useEffect(() => {
    const loadTenant = async () => {
      try {
        const res: any = await feathers.service('/tenants').get(user.business, { query: {} });
        if (res) {
          setUrlPrefix({
            key: res.key,
            domainName: res.domainName,
          });
        }
      } catch (e) {
        const errMsg =
          e?.code < 500
            ? e?.message
            : 'We are experiencing technical difficulties. Please try again';
        setErrorMessage(errMsg);
      }
    };

    if (user?.business ?? false) {
      loadTenant();
    }
  }, [user]);

  const handleLiveEdit = async () => {
    setIsLoading(true);
    setErrorMessage('');
    try {
      const res: any = await feathers
        .service('/store-front/content/pages/edit')
        .find({ query: {} });
      setIsLoading(false);
      if (res) {
        const isRootLevel = urlPrefix.domainName.split('.').length === 2;

        if (ENV === 'development' || ENV === 'staging') {
          window.open(`http://${urlPrefix.key}.localdev.inkcloud9.com:3000${res.url}`, '_blank');
        } else {
          window.open(
            `https://${isRootLevel ? 'www.' : ''}${urlPrefix.domainName}${res.url}`,
            '_blank'
          );
        }
      }
    } catch (e) {
      const errMsg =
        e?.code < 500 ? e?.message : 'We are experiencing technical difficulties. Please try again';
      setErrorMessage(errMsg);
    }
    setIsLoading(false);
  };

  return (
    <div>
      <PageHeaderRow header={`Content Management`} subheader="Settings" subheaderId={''}>
        <Button
          size="mini"
          loading={isLoading}
          primary
          content="Launch Live Edit Mode"
          onClick={handleLiveEdit}
        />
      </PageHeaderRow>

      {errorMessage && <Message error content={errorMessage} />}
      <Segment padded="very">
        <Grid stackable columns={3}>
          <Grid.Column>
            <List relaxed="very">
              <List.Item>
                <List.Header as="h3">
                  <Link style={{ color: '#21ba45' }} to="/settings/content-management/pages">
                    Pages
                  </Link>
                </List.Header>
                <List.Description>Manage Pages</List.Description>
              </List.Item>
              <List.Item>
                <List.Header as="h3">
                  <Link style={{ color: '#21ba45' }} to="/settings/content-management/banners">
                    Banners
                  </Link>
                </List.Header>
                <List.Description>Manage Banners</List.Description>
              </List.Item>
            </List>
          </Grid.Column>

          <Grid.Column>
            <List relaxed="very">
              <List.Item>
                <List.Header as="h3">
                  <Link
                    style={{ color: '#21ba45' }}
                    to="/settings/content-management/image-manager"
                  >
                    Image manger
                  </Link>
                </List.Header>
                <List.Description>Manage Images</List.Description>
              </List.Item>
              <List.Item>
                <List.Header as="h3">
                  <Link
                    style={{ color: '#21ba45' }}
                    to={`/settings/content-management/theme/${user?.business ?? ''}`}
                  >
                    Theme
                  </Link>
                </List.Header>
                <List.Description>Manage Theme Settings</List.Description>
              </List.Item>
            </List>
          </Grid.Column>

          <Grid.Column>
            <List relaxed="very">
              <List.Item>
                <List.Header as="h3">
                  <Link style={{ color: '#21ba45' }} to="/settings/content-management/menus">
                    Menus
                  </Link>
                </List.Header>
                <List.Description>Manage Menus</List.Description>
              </List.Item>
              <List.Item>
                <List.Header as="h3">
                  <Link style={{ color: '#21ba45' }} to="/settings/content-management/templates">
                    Theme Template Files
                  </Link>
                </List.Header>
                <List.Description>Theme Template Files</List.Description>
              </List.Item>
            </List>
          </Grid.Column>
        </Grid>
      </Segment>
    </div>
  );
};

const mapStateToProps = (state): ConnectedProps => ({
  user: state.auth.user || {},
});

export const IndexComponent = connect(mapStateToProps)(PreIndexComponent as any);

const routes = (props) => (
  <Switch>
    <Route exact path="/settings/content-management/" component={IndexComponent} />
    <Route path="/settings/content-management/pages" component={Pages} />
    <Route path="/settings/content-management/image-manager" component={ImageManager} />
    <Route path="/settings/content-management/banners" component={Banners} />
    <Route path="/settings/content-management/menus" component={Menus} />
    <Route path="/settings/content-management/theme" component={Theme} />
    <Route path="/settings/content-management/templates" component={Templates} />
  </Switch>
);

export default routes;
