import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Segment, Table } from 'semantic-ui-react';
import { PageHeaderRow, PreLoader, useFeathers } from '@inkcloud/components';
import feathers from '../../../bootstrap/feathers';
import { WebhookSubscriptionsForm } from './WebhookSubscriptionsForm';

interface DetailProps {
  events: Array<{ name: string; description: string }>;
}

const pathPrefix = `/settings/webhook-subscriptions`;
const serviceName = '/webhook-subscriptions';
const service = feathers.service(serviceName);
const commonParams = { query: {} };

const { useState } = React;
export const Detail: React.FunctionComponent<DetailProps & RouteComponentProps<{ id?: string }>> = (
  props
) => {
  const {
    match: {
      params: { id },
    },
    history,
    events,
  } = props;

  const [errorMessage, setErrorMessage] = useState('');
  const isEdit = !!id;
  const [state, reload] = useFeathers('get', serviceName, [id, commonParams], true);

  const handleSubmit = async (values) => {
    const payload = {
      ...values,
      events: values?.events?.filter((event) => event),
    };
    setErrorMessage('');

    try {
      if (isEdit) {
        await service.patch(id, payload, commonParams);
      } else {
        await service.create(payload, commonParams);
      }

      history.push(pathPrefix);
    } catch (e) {
      const errMsg = e?.code < 500 ? e?.message : 'Something went wrong. Please try again later.';
      setErrorMessage(errMsg);
    }
  };

  const pageTitle = isEdit ? 'Edit' : 'New';

  if (isEdit && !state.data) {
    return <PreLoader />;
  }

  const initialData = { ...state.data };
  return (
    <div>
      <PageHeaderRow
        header={`${pageTitle} Webhook Subscriptions`}
        subheader="Settings"
        subheaderId={''}
      >
        <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
      </PageHeaderRow>

      <Segment>
        {(!isEdit || state.data) && (
          <WebhookSubscriptionsForm
            initialValues={initialData}
            errorMessage={errorMessage}
            onSubmit={handleSubmit}
            events={events}
          />
        )}
      </Segment>
    </div>
  );
};
