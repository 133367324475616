import * as React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Button } from 'semantic-ui-react';

// import { Detail } from './Detail';
import Pages from './pages';
import ImageManager from './image-manager';
import Banners from './banners';
import Menus from './menus';
import Theme from './theme';

import IndexComponent from './IndexComponent';

const routes = (props) => (
  <Switch>
    <Route path="/settings/content-management/" component={IndexComponent} />
    {/* <Route path='/settings/content-management/pages' component={Pages} />
    <Route path='/settings/content-management/image-manager' component={ImageManager} />
    <Route path='/settings/content-management/banners' component={Banners} />
    <Route path='/settings/content-management/menus' component={Menus} />
    <Route path='/settings/content-management/theme' component={Theme} /> */}
  </Switch>
);

export default routes;
