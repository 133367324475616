import * as React from 'react';
import {
  Button,
  Input,
  Dimmer,
  Divider,
  Form,
  Header,
  Icon,
  Loader,
  Modal,
  ModalProps,
  Segment,
  Table,
  Message,
} from 'semantic-ui-react';
import { Feathers } from '../../../../bootstrap/feathers/feathers';
import { asyncify, forEachOfLimit } from 'async';
import { slugify } from 'underscore.string';

import { Variants } from './StockForm/variants/VariantsInput';

import { Field } from 'formik';

const { useState, useEffect } = React;

interface CreateVariantsModalProps {
  handleSubmit: Function;
  handleClose: ModalProps['onClose'];
  open: boolean;
  product: any;
}

const cartesian = function cartesian(arg) {
  let r = [],
    max = arg.length - 1;
  function helper(arr, i) {
    for (let j = 0, l = arg[i].length; j < l; j++) {
      const a = arr.slice(0); // clone arr
      a.push(arg[i][j]);
      if (i == max) {
        r.push(a);
      } else {
        helper(a, i + 1);
      }
    }
  }
  helper([], 0);
  return r;
};

function generateAndPopulate(mappedVariants, variantValues) {
  const generatedVariants = cartesian(mappedVariants);
  return generatedVariants.map((gv) => gv?.map((v) => variantValues.find((vv) => vv._id === v)));
}

const initialVariants = [{ type: '', value: [] }];

export const CreateVariantsModal: React.FunctionComponent<CreateVariantsModalProps> = (props) => {
  const { product } = props;
  const [variants, setVariants] = useState(initialVariants);
  const [variantValues, setVariantValues] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // const [errorMessage, setErrorMessage] = useState([])
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    async function loadVariantValues() {
      const vv = (await Feathers.service('/products-legacy/variant-type-values').find({
        query: {
          $sort: { name: 1 },
          $np: 1,
        },
      })) as any;

      setVariantValues(vv);
    }

    loadVariantValues();
  }, [variants]);

  const handleSubmit = async () => {
    if (!variantValues) {
      return;
    }
    setIsSubmitting(true);
    setErrorMessage('');

    const mappedVariants = variants.map((v) => v.value);

    const populatedGeneratedVariants = generateAndPopulate(mappedVariants, variantValues);

    try {
      const filteredPopulatedVariants = populatedGeneratedVariants?.filter((g) =>
        g?.find((g) => g !== null || g !== undefined)
      );

      const productVariants = await filteredPopulatedVariants?.map(async (item, index) => {
        const newProduct = { ...product };
        // Remove ID
        delete newProduct._id;

        delete newProduct.metadata;

        // set to false
        newProduct.hasVariants = false;

        // Generate New SKU
        newProduct.key = slugify(`${newProduct?.key ?? ''}-${item?.map((r) => r.key).join('-')}`);
        // Concat Name

        newProduct.staticName = `${newProduct?.staticName ?? ''} ${item
          .map((r) => r.name)
          .join(' ')}`;

        // Set parent ID
        newProduct.parent = product._id;

        newProduct.variantValues = item.map((r) => ({
          type: r.type,
          value: r._id,
        }));

        // Set Variant Info

        return Feathers.service('/products-legacy').create(newProduct);
      });

      // const result = await (Promise as any).allSettled(productVariants).then(result => {
      //   const rejectedPromises = result.filter(({ status }) => status === 'rejected')
      //   const errorMessages = rejectedPromises.map(({ reason }) => reason.message)
      //   if (!errorMessages?.length) {
      //     props.handleSubmit()
      //   }
      //   setErrorMessage(errorMessages)
      // })

      await Promise.all(productVariants);

      props?.handleSubmit();
    } catch (e) {
      console.log(e);

      setErrorMessage(e?.message);
    }

    setIsSubmitting(false);
    // const res: any = forEachOfLimit(filteredPopulatedVariants, 2, asyncify(async (item, idx) => {
    //   const newProduct = { ...product };
    //   // Remove ID
    //   delete newProduct._id;

    //   console.log('newProduct', newProduct);

    //   // set to false
    //   newProduct.hasVariants = false;

    //   // Generate New SKU
    //   newProduct.key = slugify(`${newProduct?.key ?? ''}-${item?.map(r => r.key).join('-')}`);
    //   // Concat Name

    //   newProduct.staticName = `${newProduct?.staticName ?? ''} ${item.map(r => r.name).join(' ')}`;

    //   // Set parent ID
    //   newProduct.parent = product._id;

    //   newProduct.variantValues = item.map(r => ({
    //     type: r.type,
    //     value: r._id,
    //   }));

    //   // Set Variant Info

    //   return Feathers.service('/products-legacy').create(newProduct);
    // }), (err) => {
    //   console.log('err', err)
    //   setIsSubmitting(false)

    //   if (err) {
    //     setErrorMessage(err.message)
    //   }
    //   props.handleSubmit()
    //   setIsSubmitting(false)

    // });
  };

  // Must have at least one item and make sure fields aren't empty
  const isValid =
    variants.length > 0 && variants.reduce((p, c) => p && c.type && c.value?.length > 0, true);

  return (
    <Modal size={'small'} open={props.open} onClose={props.handleClose}>
      <Modal.Header>Create Variants</Modal.Header>
      <Modal.Content>
        <Segment>
          <Dimmer active={isSubmitting} inverted>
            <Loader inverted>Creating Variants</Loader>
          </Dimmer>
          <Form>
            <Variants
              value={variants}
              onChange={(v) => setVariants(v as any)}
              multipleValues={true}
            />
            <hr />
            {variants.length === 0 && <div>Click button below to add variants</div>}
          </Form>
        </Segment>
        <Button
          type="button"
          content="Add Variant"
          onClick={() => setVariants(variants.concat([{ type: '', value: [] }]))}
        />

        {errorMessage && <Message error content={errorMessage} />}

        {/* {
          errorMessage?.length < 0 && <Message error>
            <Message.List>
              {
                errorMessage?.map((msg: string, idx: number) => <Message.Item key={idx}>{msg}</Message.Item>)
              }
            </Message.List>
          </Message>
        } */}
      </Modal.Content>
      <Modal.Actions>
        {isValid && (
          <Button primary type="button" content="Create Variants" onClick={() => handleSubmit()} />
        )}
      </Modal.Actions>
    </Modal>
  );
};
