import * as React from 'react';
import { Button, Segment } from 'semantic-ui-react';
import { DetailWrapper, DetailFeaturesProps } from './../../../bootstrap/DetailWrapper';
import { InventoryProductForm } from './InventoryProductForm';
import feathers from './../../../bootstrap/feathers';
import { PageHeaderRow, PreLoader } from '@inkcloud/components';
import { Link } from 'react-router-dom';

interface InventoryProductFeaturesProps {}

class InventoryProduct extends React.Component<DetailFeaturesProps> {
  render() {
    const { data } = this.props;

    if (this.props.match.params.id && !data) {
      return <PreLoader />;
    }

    const initialData = { ...data, inventory: true, weight: data ? data.weightBasis : 1 };
    const pageTitle = data && data._id ? 'Edit' : 'New';
    return (
      <div>
        <PageHeaderRow header={`Inventory Product`} subheader={pageTitle}>
          <Link to={`/products/products`}>
            <Button content="Back" size={'mini'} />
          </Link>
        </PageHeaderRow>
        <Segment>
          <InventoryProductForm
            initialValues={initialData}
            handleSubmit={this.props.handleSubmit}
          />
        </Segment>
      </div>
    );
  }
}

export default DetailWrapper(InventoryProduct, 'products', {
  submitRedirect: (props) => `/products/products`,
});
