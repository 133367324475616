import * as React from 'react';
import { Button, Form, Image, Modal, Segment, Message } from 'semantic-ui-react';
import { Formik, Form as FormikForm, Field, ErrorMessage, FieldArray, FieldProps } from 'formik';
import Feathers from './../../bootstrap/feathers';
import { To } from '@inkcloud/components';

import { ImageUpload } from './../products/products/stock-product/StockForm/ImageUpload';

interface ImageUploadModalProps {
  isOpen: boolean;
  onClose: () => void;
  onReload: () => void;
}

declare var APPENV;

export const ImageUploadModal: React.FunctionComponent<ImageUploadModalProps> = (props) => {
  const { isOpen, onClose, onReload } = props;

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        onClose();
        onReload();
      }}
      closeIcon
    >
      <Modal.Content>
        <Formik
          initialValues={{ images: [] }}
          onSubmit={(values, { setSubmitting }) => {
            // props.handleSubmit(submissionMapper(values));
          }}
        >
          {(formProps) => {
            const {
              values,
              touched,
              errors,
              dirty,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset,
              setFieldValue,
              setFieldTouched,
            } = formProps;

            return (
              <>
                <Form.Field>
                  <label>Images</label>
                  {values.images.length > 0 && (
                    <Message
                      success
                      content={`Image${values.images.length > 1 ? 's' : ''} uploaded`}
                    />
                  )}
                  {values.images && values.images.length > 0 && (
                    <Segment>
                      <Image.Group size="small">
                        {values.images.map((i: any, index: number) => {
                          return (
                            <Image
                              key={index}
                              bordered
                              src={`https://storage.googleapis.com/icstorage/${i.url}`}
                            />
                          );
                        })}
                      </Image.Group>
                    </Segment>
                  )}
                  <ImageUpload
                    onUpload={(files) => {
                      setFieldValue(
                        'images',
                        (values.images = values.images.concat(
                          files.map((f) => ({
                            url: f.publicUrl,
                            contentImage: f._id,
                          }))
                        ))
                      );
                    }}
                    showFiles={false}
                  />
                </Form.Field>
              </>
            );
          }}
        </Formik>
      </Modal.Content>
    </Modal>
  );
};
