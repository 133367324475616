import * as React from 'react';
import {
  Box,
  TableContainer,
  Table,
  Th,
  Tbody,
  Tr,
  Td,
  Divider,
  Button,
  Text,
} from '@chakra-ui/react';
import { FormattedDate, FormattedTime } from 'react-intl';

import { CheckCircleIcon, NotAllowedIcon, AttachmentIcon } from '@chakra-ui/icons';

import type { ModelTypes } from '@inkcloud/icapi-types';
import { SegmentCard } from '../../../../common';

import {
  statusOptions,
  paperSuppliedByOptions,
  filesSuppliedOptions,
  booleanOptions,
  optionsFinder,
} from '../../common/EditableInfo';
import { BasicInfoType } from '../../common/types';
import { isEstimatePastDue } from '../../ListTabs/helpers';

export type IAddressProps = NonNullable<Pick<ModelTypes.Rfq, 'billTo' | 'shipTo'>>;

interface IBasicInfoProps {
  shipTo?: IAddressProps['shipTo'];
  isReadOnly?: boolean;
  hideShipTo?: boolean;
  shipToEditClick?: () => void;
  // basicInfo: Omit<ModelTypes.Rfq, 'csr' | 'requestor'> & {
  //   csr: string | { firstName: string; lastName: string }
  //   requestor: string | { firstName: string; lastName: string }
  //   assignedTo?: string | { firstName: string; lastName: string }
  //   customer?: { organizationName?: string; billingClientName?: string }
  //   turnaroundTime?: number
  // }
  basicInfo: BasicInfoType;
  title: string;
  subTitle?: string;
  actionButtonLabel?: string;
  onEditClick?: () => void;
}

export default function BasicInfo(props: IBasicInfoProps) {
  const {
    basicInfo: {
      status = '',
      projectName = '',
      estimateDueDate = '',
      vendorShipDate = '',
      requestedCustomerShipDate = '',
      paperSuppliedBy = '',
      filesSupplied = '',
      // hardProof = true,
      // eProof = true,
      // allowableUnderrunPercentage = '',
      // allowableOverrunPercentage = '',
      turnaroundTime,
      turnaroundDescription = '',
    },
    title,
    subTitle,
    actionButtonLabel = 'Edit',
    onEditClick,
    shipTo,
    isReadOnly,
    hideShipTo = false,
  } = props;

  // const type = 'shipTo'

  // const csr =
  //   typeof props.basicInfo?.csr === 'string'
  //     ? props.basicInfo?.csr
  //     : `${props?.basicInfo?.csr?.firstName ?? ''} ${props?.basicInfo?.csr?.lastName ?? ''}`

  const requestor =
    typeof props.basicInfo?.requestor === 'string'
      ? props.basicInfo?.requestor
      : `${props?.basicInfo?.requestor?.firstName ?? ''} ${
          props?.basicInfo?.requestor?.lastName ?? ''
        }`;

  const assignedTo =
    typeof props.basicInfo?.assignedTo === 'string'
      ? props.basicInfo?.assignedTo
      : `${(props?.basicInfo?.assignedTo as any)?.firstName ?? ''} ${
          (props?.basicInfo?.assignedTo as any)?.lastName ?? ''
        }`;

  return (
    <SegmentCard
      title={title}
      subtitle={subTitle}
      actionButtonLabel={actionButtonLabel}
      actionButtonClick={onEditClick}
    >
      <TableContainer>
        <Table size="sm">
          <Tbody>
            <Tr>
              <Th>Client</Th>
              <Td>
                {props?.basicInfo?.customer?.organizationName ?? ''}
                {props?.basicInfo?.customer?.billingClientName
                  ? ` - ${props?.basicInfo?.customer?.billingClientName}`
                  : ''}
              </Td>
            </Tr>
            <Tr>
              <Th>Project Name</Th>
              <Td>{projectName}</Td>
            </Tr>
            <Tr>
              <Th>Requestor</Th>
              <Td>{requestor}</Td>
            </Tr>
            <Tr>
              <Th>Assigned To</Th>
              <Td>{assignedTo}</Td>
            </Tr>
            {/* <Tr>
              <Th>CSR</Th>
              <Td>{csr}</Td>
            </Tr> */}
            <Tr>
              <Th>Estimate Due</Th>
              <Td>
                {estimateDueDate && (
                  <>
                    <FormattedDate value={estimateDueDate} />{' '}
                    <FormattedTime value={estimateDueDate} />
                    {isEstimatePastDue(props.basicInfo) && (
                      <Text as="strong" color="red">
                        {' '}
                        Past Due
                      </Text>
                    )}
                  </>
                )}
              </Td>
            </Tr>
            {/* <Tr>
              <Th>Vendor Ship</Th>
              <Td>{vendorShipDate && <FormattedDate value={vendorShipDate} />}</Td>
            </Tr> */}
            <Tr>
              <Th>Delivery Date</Th>
              <Td>
                {requestedCustomerShipDate && <FormattedDate value={requestedCustomerShipDate} />}
              </Td>
            </Tr>
            <Tr>
              <Th>Paper Supplied By</Th>
              <Td>{optionsFinder(paperSuppliedByOptions, paperSuppliedBy)?.label}</Td>
            </Tr>

            {/* <Tr>
              <Th>Proof</Th>
              <Td>
                {eProof ? <CheckCircleIcon color={'green'} /> : <NotAllowedIcon />} Electronic{' '}
                {hardProof ? <CheckCircleIcon color={'green'} /> : <NotAllowedIcon />} Hard
              </Td>
            </Tr> */}
            <Tr>
              <Th>Files Supplied</Th>
              <Td>{optionsFinder(filesSuppliedOptions, filesSupplied)?.label}</Td>
            </Tr>
            <Tr>
              <Th>Turnaround Time Desc</Th>
              <Td>{turnaroundDescription}</Td>
            </Tr>
            {!hideShipTo && (
              <Tr>
                <Th verticalAlign={'top'}>Ship To</Th>
                <Td>
                  {shipTo?.name ? <Box>{shipTo?.name}</Box> : null}
                  {shipTo?.careOf ? <Box>{shipTo?.careOf}</Box> : null}
                  {shipTo?.address1 ? <Box>{shipTo?.address1}</Box> : null}
                  {shipTo?.address2 ? <Box>{shipTo?.address2}</Box> : null}
                  <Box>
                    {shipTo?.city} {shipTo?.stateProvince} {shipTo?.postalCode}
                  </Box>
                  <Box>{shipTo?.countryCode}</Box>
                  {!isReadOnly && (
                    <Button
                      ml={0}
                      pl={0}
                      size="sm"
                      colorScheme="blue"
                      variant={'link'}
                      onClick={props.shipToEditClick}
                    >
                      Edit
                    </Button>
                  )}
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
        <Divider />
        <></>
      </TableContainer>
    </SegmentCard>
  );
}

// export default BasicInfo;
