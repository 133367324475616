import * as React from 'react';
import { Switch, Route } from 'react-router-dom';
import feathers from '../../../bootstrap/feathers';
import { List } from './List';
import { Detail } from './Detail';

const { useState, useEffect } = React;

const routes = (props) => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const loadEvents = async () => {
      try {
        const res: any = await feathers.service('webhook-events').find({
          query: {
            $sort: { name: 1 },
            $np: 1,
          },
        });

        setEvents(res);
      } catch (e) {
        console.log(e);
      }
    };

    loadEvents();
  }, []);

  return (
    <Switch>
      <Route
        exact
        path="/settings/webhook-subscriptions"
        component={(innerProps) => <List {...innerProps} events={events} />}
      />
      <Route
        exact
        path="/settings/webhook-subscriptions/add"
        component={(innerProps) => <Detail {...innerProps} events={events} />}
      />
      <Route
        exact
        path="/settings/webhook-subscriptions/:id"
        component={(innerProps) => <Detail {...innerProps} events={events} />}
      />
    </Switch>
  );
};

export default routes;
