import * as React from 'react';
import { PageSize, Orientation } from '@react-pdf/types';
import { OrderItemLabelDocumentContext } from './OrderItemLabelDocument';

export const OrderItemLabelContext = React.createContext<OrderItemLabelContextInterface>(null);

interface IOrderItemLabelProviderProps {
  initialState: any;
  children: React.ReactNode;
}

interface OrderItemLabelContextInterface {
  dataCtx: OrderItemLabelDocumentContext;
  styles: any;
  config: {
    size: PageSize;
    orientation: Orientation;
  };
}

const config: OrderItemLabelContextInterface['config'] = {
  size: 'A4',
  orientation: 'portrait',
};

export const OrderItemLabelProvider: React.FunctionComponent<IOrderItemLabelProviderProps> = (
  props
) => {
  const { children, initialState } = props;

  // console.log('initial state in this one!!!', initialState);
  const defaultCtx = initialState;
  return (
    <OrderItemLabelContext.Provider value={defaultCtx}>{children}</OrderItemLabelContext.Provider>
  );
};
