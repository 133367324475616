import React, { useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Box, Button, Input, Checkbox, Alert, Switch } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import type { ModelTypes } from '@inkcloud/icapi-types';
import * as yup from 'yup';

import { FormControl } from '../../../common/v3/Form/FormControl';
import DatePickerInput from '../../../common/v3/Form/DatePickerInput';

const yupSchema = yup.object().shape({
  name: yup.string().required(),
  sortOrder: yup.number(),
});

interface JobSchedulingColumnFormProps {
  initialValues: {
    name: string;
    sortOrder?: number;
  };
  isSubmitting: boolean;
  errorMessage: string;
  onSubmit: (data: any) => void;
}

export default function JobSchedulingColumnForm(props: JobSchedulingColumnFormProps) {
  const { initialValues, isSubmitting, errorMessage, onSubmit } = props;

  const useFormReturn = useForm({
    mode: 'onBlur',
    defaultValues: initialValues,
    resolver: yupResolver(yupSchema),
  });

  useEffect(() => {
    useFormReturn.reset(initialValues);
  }, [initialValues]);

  const { handleSubmit } = useFormReturn;

  return (
    <>
      <Box bgColor={'white'} p={8}>
        <FormProvider {...useFormReturn}>
          <Box width={360}>
            <FormControl
              field={'name'}
              label="Name"
              required={true}
              render={(fieldProps) => <Input {...fieldProps} />}
            />
            <FormControl
              field={'sortOrder'}
              label="Sort Order"
              render={(fieldProps) => <Input {...fieldProps} />}
            />
            <FormControl
              field="isGlobal"
              label="Is Global"
              render={(fieldProps) => <Switch {...fieldProps} />}
            />
            <FormControl
              field="isFinal"
              label="Is Final"
              render={(fieldProps) => <Switch {...fieldProps} />}
            />
          </Box>
        </FormProvider>

        {errorMessage && (
          <Alert mb={2} status="error">
            {errorMessage}
          </Alert>
        )}

        <Button
          isDisabled={isSubmitting}
          isLoading={isSubmitting}
          colorScheme="blue"
          onClick={handleSubmit((data) => onSubmit(data))}
        >
          Submit
        </Button>
      </Box>
    </>
  );
}
