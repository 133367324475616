import React from 'react';
import { Input, Alert, Button } from '@chakra-ui/react';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import FormControl from '../../../common/v3/Form/FormControl';

interface ILabelFormProps {
  initialValues: {
    labelMaps: {
      [key: string]: string;
    };
  };
  errorMessage: string;
  isSubmitting?: boolean;
  onSubmit: (v: any) => void;
}

export default function LabelForm(props: ILabelFormProps) {
  const { initialValues, errorMessage, isSubmitting, onSubmit } = props;

  const useFormReturn = useForm<ILabelFormProps['initialValues']>({
    defaultValues: initialValues,
  });

  const { handleSubmit } = useFormReturn;
  // console.log('form', initialValues);

  const handSave: SubmitHandler<ILabelFormProps['initialValues']> = (data) => onSubmit(data);
  return (
    <FormProvider {...useFormReturn}>
      <FormControl
        field="labelMaps.orderForeignId"
        label="Order Foreign ID"
        render={(fieldProps) => <Input {...fieldProps} />}
      />
      {errorMessage && (
        <Alert status="error" my={3}>
          {errorMessage}
        </Alert>
      )}
      <Button
        isDisabled={isSubmitting}
        isLoading={isSubmitting}
        colorScheme="blue"
        onClick={handleSubmit(handSave)}
      >
        Submit
      </Button>
    </FormProvider>
  );
}
