import * as React from 'react';
import { Switch, Route } from 'react-router-dom';

import { Detail } from './Detail';

const routes = (props) => (
  <Switch>
    <Route
      exact
      path="/settings/microsites/:micrositeId/content/pages/editable/:id"
      component={Detail}
    />
  </Switch>
);

export default routes;
