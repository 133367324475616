import * as React from 'react';
import Feathers from '../../../bootstrap/feathers';
import { Button, Form, Table, Message, Popup } from 'semantic-ui-react';
import { PickupLocationModal } from './PickupLocationModal';
export interface PickupLocationFormProps {
  // id: string;
  // initLocation: string;
}

const { useState, useEffect } = React;
export const PickupLocationForm: React.FunctionComponent<PickupLocationFormProps> = (props) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [locations, setLocations] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  const [selectedLocation, setSelectedLocation] = useState('');

  useEffect(() => {
    const loadLocations = async () => {
      setErrorMessage('');
      try {
        setIsLoading(true);
        const res: any = await Feathers.service('/pickup-locations').find({ query: { $np: 1 } });
        setLocations(res);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setErrorMessage(error.message);
      }
    };

    loadLocations();
  }, [isOpenModal, isSubmitting]);

  const handleDelete = async (id: string) => {
    setIsSubmitting(true);
    try {
      const res: any = await Feathers.service('/pickup-locations').remove(id, { query: {} });
      setIsSubmitting(false);
    } catch (error) {
      setIsSubmitting(false);
      setErrorMessage(error.message);
    }
  };

  const handleToggleModal = (id: string) => {
    setIsOpenModal(!isOpenModal);
    setSelectedLocation(id);
  };

  return (
    <>
      <Form loading={isLoading || isSubmitting} style={{ minHeight: '30px' }}>
        {errorMessage && <Message error content={errorMessage} />}

        {locations &&
          locations.length > 0 &&
          locations.map((l: any, index: number) => (
            <Form.Field key={index}>
              <Table basic="very" compact>
                <Table.Body>
                  <Table.Row verticalAlign="top">
                    <Table.Cell collapsing>
                      <Popup
                        size="mini"
                        content="Delete"
                        trigger={
                          <Button size="mini" icon={'trash'} onClick={() => handleDelete(l._id)} />
                        }
                      />
                    </Table.Cell>
                    <Table.Cell>
                      {l.name}
                      <br />
                      {l.address1} <br />
                      {l.address2 ? `${l.address2}, ` : ' '}
                      {l.city} {l.stateProvince}, {l.postalCode}
                    </Table.Cell>
                    <Table.Cell textAlign="right">
                      <Popup
                        size="mini"
                        content="Edit Pick-up Location"
                        trigger={
                          <Button
                            size={'mini'}
                            icon={'edit'}
                            onClick={() => handleToggleModal(l._id)}
                          />
                        }
                      />
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Form.Field>
          ))}
      </Form>
      <br />

      <Button onClick={() => setIsOpenModal(true)} content="Add Location" />

      {isOpenModal && (
        <PickupLocationModal
          isOpen={isOpenModal}
          onClose={() => {
            setIsOpenModal(false);
            setSelectedLocation('');
          }}
          selectedLocation={selectedLocation}
        />
      )}
    </>
  );
};
