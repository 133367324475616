import * as React from 'react';
import { Button, Segment, Table } from 'semantic-ui-react';
import { DetailWrapper, DetailFeaturesProps } from './../../../../bootstrap/DetailWrapper';

export interface ProductionBatchStatusProps {}

export interface ProductionBatchStatusState {}

class ProductionBatchStatus extends React.Component<
  ProductionBatchStatusProps,
  ProductionBatchStatusState
> {
  constructor(props: ProductionBatchStatusProps) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div>
        <Segment>
          <Table>
            <Table.Body>
              <Table.Row>
                <Table.Cell>ID</Table.Cell>
                <Table.Cell>55955</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Segment>
      </div>
    );
  }
}

export default DetailWrapper(ProductionBatchStatus as any, 'productionBatches', {
  submitRedirect: '/production/batches',
});
