import * as React from 'react';
import type { Identifier, XYCoord } from 'dnd-core';
import { useDrag, useDrop } from 'react-dnd';

const { useRef } = React;

const ItemTypes = {
  CARD: 'card',
};

const style = {
  display: 'inline-block',
  margin: '0 .25em 1em',
};

interface CardProps {
  id: string;
  src: string;
  index: number;
  remove: () => void;
  move: (v1: number, v2: number) => void;
  contentImage?: any;
  fileName?: string;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

export const Card: React.FunctionComponent<CardProps> = (props) => {
  const { id, src, index, remove, move, contentImage, fileName } = props;
  const ref = useRef<any>(null);
  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: ItemTypes.CARD,
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      move(dragIndex, hoverIndex);
      // moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
      monitor.getItem().index = hoverIndex;
    },
  });
  const [{ opacity }, drag] = useDrag(
    () => ({
      item: () => ({ id, index }),
      type: ItemTypes.CARD,
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.5 : 1,
      }),
    }),
    []
  );
  drag(drop(ref));

  const isImage = ['jpg', 'jpeg', 'png', 'gif'].find((type) => type === contentImage?.extension);

  return (
    <div ref={ref} style={{ ...style, opacity, cursor: 'grab' }} className="ui fluid image">
      {isImage ? (
        <>
          <a className="ui right corner label" onClick={remove}>
            <i aria-hidden="true" className="times icon"></i>
          </a>
          <img src={src} alt={id} className="ui image" style={{ margin: 0 }} />
        </>
      ) : (
        <>
          {fileName} <i aria-hidden="true" className="times  icon" onClick={remove}></i>
        </>
      )}
    </div>
  );
};
