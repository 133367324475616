import * as React from 'react';
import { Switch, Route, RouteComponentProps } from 'react-router-dom';

import { List } from './List';
import { Detail } from './Detail';

import Editable from './editable';

import feathers from '../../../bootstrap/feathers';
const { useEffect, useState } = React;

const routes: React.FunctionComponent<RouteComponentProps<{ micrositeId: string }>> = (props) => {
  const {
    match: {
      params: { micrositeId },
    },
  } = props;
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const loadProducts = async () => {
      const res = (await feathers.service('products-legacy').find({
        query: {
          $np: 1,
          microsite: micrositeId,
          $sort: { staticName: 1 },
          $select: ['staticName', 'key', 'version'],
        },
      })) as any[];
      // setProducts(products.filter(p => p.staticName).map(p => ({ value: p._id, text: `${p.staticName} [${p.key}] ${p.version ? `[v${p.version}]` : ''}` })));
      setProducts(res);
    };

    loadProducts();
  }, []);

  useEffect(() => {
    const loadCategories = async () => {
      const res = (await feathers.service('products-legacy/categories').find({
        query: {
          $np: 1,
          microsite: micrositeId,
          $sort: { name: 1 },
          $select: ['name', 'hrCode', 'internalName'],
        },
      })) as any[];
      // setCategories(products.map(c => ({ value: c._id, text: `${c.name} ${c.internalName ? `(${c.internalName})` : ''}` })));
      setCategories(res);
    };

    loadCategories();
  }, []);

  return (
    <Switch>
      <Route
        exact
        path="/settings/microsites/:micrositeId/content/pages"
        render={(componentProps) => (
          <List {...componentProps} products={products} categories={categories} />
        )}
      />
      <Route
        exact
        path="/settings/microsites/:micrositeId/content/pages/add"
        render={(componentProps) => (
          <Detail {...componentProps} products={products} categories={categories} />
        )}
      />
      <Route
        exact
        path="/settings/microsites/:micrositeId/content/pages/:id"
        render={(componentProps) => (
          <Detail {...componentProps} products={products} categories={categories} />
        )}
      />
      <Route
        exact
        path="/settings/microsites/:micrositeId/content/pages/editable/:id"
        component={Editable}
      />
    </Switch>
  );
};
export default routes;
