/* eslint-disable no-undef */
import * as React from 'react';
import { Dropdown, Form, Checkbox, CheckboxProps, DropdownProps, Divider } from 'semantic-ui-react';
import * as Cleave from 'cleave.js/react';
import * as Scroll from 'react-scroll';
import { PaymentCreditCard, PaymentCreditCardToken, PaymentCreditCardTypes } from '../../types';
import { usStateProvinces } from '../../common';

const expMonths = [
  { value: '', text: 'Select One...' },
  { value: '01', text: '01' },
  { value: '02', text: '02' },
  { value: '03', text: '03' },
  { value: '04', text: '04' },
  { value: '05', text: '05' },
  { value: '06', text: '06' },
  { value: '07', text: '07' },
  { value: '08', text: '08' },
  { value: '09', text: '09' },
  { value: '10', text: '10' },
  { value: '11', text: '11' },
  { value: '12', text: '12' },
];

const date = new Date();
const nowYear = date.getFullYear();
const max = nowYear + 12;
const expYears = [{ value: '', text: 'Select One...' }];
for (let i = nowYear; i < max; i++) {
  expYears.push({ value: `${i - 2000}`, text: `${i}` });
}

export interface CreditCardFormDataProps {
  creditCard: PaymentCreditCard;
  availableCreditCardTokens: PaymentCreditCardToken[];
  creditCardToken: string;
  creditCardType: PaymentCreditCardTypes;
}

export interface CreditCardFormHandlerProps {
  onChange: {
    (data: CreditCardFormDataProps): void;
  };
}

export type CreditCardFormProps = CreditCardFormDataProps & CreditCardFormHandlerProps;

interface CreditCardFormComponentState {}

// export class CreditCardFormComponent extends React.Component<CreditCardFormProps, CreditCardFormComponentState> {
//   constructor(props) {
//     super(props);
//     this.handleInputChange = this.handleInputChange.bind(this);
//     this.handleDropdownChange = this.handleDropdownChange.bind(this);
//     this.handleRadioChange = this.handleRadioChange.bind(this);
//     this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
//   }

const { useEffect } = React;
export const CreditCardFormComponent: React.FunctionComponent<
  CreditCardFormProps & CreditCardFormComponentState
> = (props) => {
  const handleInputChange = (e) => {
    const elementValue = {};
    // credit card number needs raw value to strip out formatting
    elementValue[e.target.name] = e.target.rawValue ? e.target.rawValue : e.target.value;

    const creditCard = {
      ...props.creditCard,
      ...elementValue,
    };

    const { onChange, ...data } = props;

    props?.onChange?.({
      ...data,
      creditCard,
    });
  };

  const handleRadioChange = (
    event: React.FormEvent<HTMLInputElement>,
    data: CheckboxProps
  ): void => {
    const { onChange, ...dataProps } = props;
    props?.onChange?.({
      ...dataProps,
      creditCardType: data.value as PaymentCreditCardTypes,
    });
  };

  const handleCheckboxChange = (
    event: React.FormEvent<HTMLInputElement>,
    data: CheckboxProps
  ): void => {
    const { onChange, ...dataProps } = props;
    const creditCard = {
      ...props.creditCard,
      saveCard: data.checked,
    };

    const dataToDispatch = {
      ...dataProps,
      creditCard,
    };

    props?.onChange?.(dataToDispatch);
  };

  const handleDropdownChange = (
    event: React.SyntheticEvent<HTMLInputElement>,
    data: DropdownProps
  ): void => {
    let dataToDispatch;
    const elementValue = {};
    const { onChange, ...dataProps } = props;
    elementValue[data.name] = data.value;

    if (data.name === 'creditCardToken') {
      dataToDispatch = {
        ...dataProps,
        creditCardToken: data.value,
      };
    } else {
      const creditCard = {
        ...props.creditCard,
        ...elementValue,
      };

      dataToDispatch = {
        ...dataProps,
        creditCard,
      };
    }

    props?.onChange?.(dataToDispatch);

    if (data.name === 'cardType' && data.value === PaymentCreditCardTypes.Stored) {
      Scroll.animateScroll.scrollMore(30);
    }
  };

  // render() {
  const { availableCreditCardTokens } = props;

  const tokens =
    availableCreditCardTokens?.map((t) => ({
      value: t.paymentProfileId,
      text: `${t.cardType} x-${t.lastFour}`,
    })) || [];

  let tokensAvailable;
  if (tokens && tokens.length > 0) {
    tokensAvailable = (
      <div>
        <Form.Field>You can select between stored cards or entering a new one:</Form.Field>
        <Form.Group>
          <Form.Field>
            <Checkbox
              radio
              label="Use a stored card"
              name="cardType"
              value={PaymentCreditCardTypes.Stored}
              checked={props.creditCardType === PaymentCreditCardTypes.Stored}
              onChange={handleRadioChange}
            />
          </Form.Field>
          <Form.Field>
            <Checkbox
              radio
              label="Enter a new card"
              name="cardType"
              value={PaymentCreditCardTypes.New}
              checked={props.creditCardType === PaymentCreditCardTypes.New}
              onChange={handleRadioChange}
            />
          </Form.Field>
        </Form.Group>
        <Divider />
        {props.creditCardType === PaymentCreditCardTypes.Stored ? (
          <div>
            <Form.Field name="storedCard">Select a stored card to use for payment</Form.Field>
            <Form.Field>
              <Dropdown
                name="creditCardToken"
                placeholder="Select a card"
                fluid
                selection
                options={tokens}
                onChange={handleDropdownChange}
                defaultValue={props.creditCardToken || tokens?.[0]?.value}
              />
            </Form.Field>
          </div>
        ) : null}
      </div>
    );
  }

  return (
    <div>
      <form className="ui segment top attached tiny form">
        {tokensAvailable}

        {props.creditCardType !== PaymentCreditCardTypes.Stored ? (
          <div className="ui grid">
            <div className="eight wide column">
              <div className="field">
                <label>Name on Card</label>
                <input
                  type="text"
                  name="name"
                  placeholder="Name"
                  onChange={handleInputChange}
                  value={props.creditCard?.name}
                />
              </div>
              <div className="fields">
                <div className="ten wide field">
                  <label>Card Number</label>
                  <Cleave
                    name="number"
                    placeholder="Enter your credit card number"
                    options={{ creditCard: true }}
                    onChange={handleInputChange}
                    value={props.creditCard?.number}
                  />
                </div>
                <div className="six wide field">
                  <label>Card CVV</label>
                  <input
                    type="text"
                    name="cvv"
                    placeholder="CVV"
                    onChange={handleInputChange}
                    value={props.creditCard?.cvv}
                  />
                </div>
              </div>

              <div className="two fields">
                <div className="field">
                  <label>Exp Month</label>
                  <Dropdown
                    name="expMonth"
                    placeholder="Select a month"
                    fluid
                    selection
                    options={expMonths}
                    onChange={handleDropdownChange}
                    value={props.creditCard?.expMonth}
                  />
                </div>
                <div className="field">
                  <label>Exp Year</label>
                  <Dropdown
                    name="expYear"
                    placeholder="Select a year"
                    fluid
                    selection
                    options={expYears}
                    onChange={handleDropdownChange}
                    value={props.creditCard?.expYear}
                  />
                </div>
              </div>
              <div className="one fields">
                <div className="field">
                  <Checkbox
                    toggle
                    label={'Save Card'}
                    checked={props.creditCard?.saveCard === true}
                    value={props.creditCard?.saveCard ? 'true' : 'false'}
                    onChange={handleCheckboxChange}
                  />
                </div>
              </div>
            </div>
            <div className="eight wide column">
              <div className="field">
                <label>Address 1</label>
                <input
                  type="text"
                  name="address1"
                  placeholder="Address1"
                  onChange={handleInputChange}
                  value={props.creditCard?.address1}
                />
              </div>

              <div className="field">
                <label>City</label>
                <input
                  type="text"
                  name="city"
                  placeholder="City"
                  onChange={handleInputChange}
                  value={props.creditCard?.city}
                />
              </div>

              <div className="fields">
                <div className="ten wide field">
                  <label>State/Province</label>
                  <Dropdown
                    name="stateProvince"
                    placeholder="Select a state"
                    fluid
                    selection
                    options={usStateProvinces}
                    onChange={handleDropdownChange}
                    value={props.creditCard?.stateProvince}
                  />
                </div>
                <div className="six wide field">
                  <label>Postal Code</label>
                  <input
                    type="text"
                    name="postalCode"
                    placeholder="Postal Code"
                    onChange={handleInputChange}
                    value={props.creditCard?.postalCode}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </form>
    </div>
  );
  // }
};
