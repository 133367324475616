import * as React from 'react';
import { Form } from 'semantic-ui-react';

interface Setting {
  min: number;
  max: number;
  cost: number;
  step: number;
  includeOne: boolean;
}

export interface QuantitySettingsProps {
  value: { min: number; max: number; cost: number; step: number; includeOne: boolean };
  onChange: (data: any) => void;
}

export class QuantitySettings extends React.Component<QuantitySettingsProps, any> {
  handleChange = (value: Setting) => this.props.onChange && this.props.onChange(value);

  public render() {
    if (!this.props.value) {
      return null;
    }

    const { value } = this.props;
    const { min, max, cost, step, includeOne } = value;

    return (
      <>
        <Form.Group widths="equal">
          <Form.Input
            label="Minimum Quantity"
            value={min}
            onChange={(e, data) =>
              this.handleChange({ ...value, min: parseInt(data.value || '0', 10) })
            }
          />
          <Form.Input
            label="Maximum Quantity"
            value={max}
            onChange={(e, data) =>
              this.handleChange({ ...value, max: parseInt(data.value || '0', 10) })
            }
          />
          {/* <Form.Input
            label="Cost"
            value={cost}
            onChange={(e, data) =>
              this.handleChange({ ...value, cost: parseInt(data.value || '0', 10) })
            }
          /> */}
          <Form.Input
            label="Step"
            value={step}
            onChange={(e, data) => this.handleChange({ ...value, step: parseInt(data.value, 10) })}
          />
        </Form.Group>
        <Form.Field>
          <Form.Checkbox
            label="Include One"
            checked={!!includeOne}
            onChange={(e, data) => this.handleChange({ ...value, includeOne: data?.checked })}
          />
        </Form.Field>
      </>
    );
  }
}
