import * as React from 'react';
import { Switch, Route } from 'react-router-dom';

import { List } from './List';

const route = (props) => (
  <Switch>
    <Route exact path="/order-rejected" component={List} />
  </Switch>
);
export default route;
