import * as React from 'react';
import { Button, Header, Message, Segment } from 'semantic-ui-react';

import { RouteComponentProps, Link } from 'react-router-dom';

import { PageHeaderRow, PreLoader, useFeathers } from '@inkcloud/components';
import Feathers from '../../../bootstrap/feathers';
import { DetailWrapper, DetailFeaturesProps } from '../../../bootstrap/DetailWrapper';
// import { CategoryForm } from './CategoryForm';
import CategoryForm2 from './CategoryForm';

const { useState, useEffect } = React;

const serviceName = 'products-legacy/categories';
const pathPrefix = `/products/categories`;
const service = Feathers.service(serviceName);
const commonParams = {
  query: {
    $populate: ['entityAttributes', 'microsite', 'parentCategory'],
  },
};

export const Detail: React.FunctionComponent<RouteComponentProps<{ id?: string }>> = (props) => {
  const {
    match: {
      params: { id },
    },
    location,
    history,
  } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const isEdit = !!id;
  const [state, reload] = useFeathers('get', serviceName, [id, commonParams], true);

  const handleSubmit = async (values: any) => {
    const newValues = {
      ...values,
      entityAttributes: values?.entityAttributes?.map(
        (attr: { label: string; value: string }) => attr?.value
      ),
      parentCategory: values?.parentCategory?.value,
      // parentCategory: values.parentCategory || null,
      hrCode: values.hrCode?.replace(/-+$/, ''), // Trim (-) hyphen from end of text
      microsite: values?.microsite?.value,
      $unset: {
        parentCategory: !values.parentCategory?.value ? '1' : undefined,
      },
    };
    try {
      setIsSubmitting(true);

      if (isEdit) {
        await service.patch(id, newValues, commonParams);
      } else {
        await service.create(newValues, commonParams);
      }
      history.push(pathPrefix);
    } catch (e) {
      const errMsg =
        e?.code < 500 ? e?.message : 'We are experiencing technical difficulties. Please try again';
      setErrorMessage(errMsg);
    }

    setIsSubmitting(false);
  };

  const pageTitle = isEdit ? 'Edit' : 'New';

  if (isEdit && !state.data) {
    return <PreLoader />;
  }

  const initialData = { ...state.data };
  return (
    <div>
      <PageHeaderRow
        header={pageTitle}
        subheader="Product Category"
        subheaderId={initialData.data && initialData.data.humanId}
      >
        <Link
          to={{
            state: location.state,
          }}
          onClick={() => history.goBack()}
        >
          <Button content="Back" size={'mini'} />
        </Link>
        {/* <Link to="/products/categories">
          <Button content="Back" size={'mini'} />
        </Link> */}
      </PageHeaderRow>

      {(!isEdit || state.data) && (
        <CategoryForm2
          isEdit={isEdit}
          initialValues={initialData}
          onSubmit={handleSubmit}
          errorMessage={errorMessage}
          isSubmitting={isSubmitting}
        />
      )}

      {/* <Message warning attached={'bottom'}>
          <Message.Header>Please Note</Message.Header>
          Types cannot be edited once a category is created.
        </Message> */}
    </div>
  );
};
