import * as React from 'react';
import { Form, Radio } from 'semantic-ui-react';

import NumberFormat from 'react-number-format';
interface IShipRatesProps {
  availableShippingRates: any[];
  selectedShippingService: string;
  onChange: (v: string) => void;
}

export const ShipRates: React.FunctionComponent<IShipRatesProps> = (props) => {
  const { availableShippingRates, selectedShippingService, onChange } = props;

  const numberFormat = (value) =>
    new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(value);

  return (
    <Form>
      {availableShippingRates &&
        availableShippingRates.map((m, idx) => (
          <Form.Field key={idx}>
            <Radio
              label={`${m.description} ${numberFormat(m.amount)}`}
              name="shipMethods"
              value={m.serviceKey}
              checked={m.serviceKey === selectedShippingService}
              onChange={() => onChange(m.serviceKey)}
            />
          </Form.Field>
        ))}
    </Form>
  );
};
