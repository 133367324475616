import * as React from 'react';
import { connect } from 'react-redux';
import {
  InjectedFormProps,
  change,
  Field,
  reduxForm,
  formValueSelector,
  FormProps,
} from 'redux-form';
import { Button, Input, Dropdown, Form } from 'semantic-ui-react';
import { chunk } from 'lodash-es';
import {
  semanticFormField,
  semanticFormToggleField,
  semanticFormDatePickerField,
  semanticFormDropdownField,
  DropdownTags,
} from './../../common';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import Feathers from './../../bootstrap/feathers';
import { EntityDropdownWrapper2 } from './../EntityDropdown';

type ProductFilterFormProps = {
  isOrderItemMode: boolean;
  // isEdit: boolean;
} & InjectedFormProps;

export interface ProductFilterFormState {
  categoryDropdown: any;
  key: string;
  staticName: string;
  categories: any[];
  attributes: any[];
  quantities: any[];
  addOns: any[];
  statuses: any[];
}

export class ProductFilterForm extends React.Component<
  ProductFilterFormProps,
  ProductFilterFormState
> {
  constructor(props: ProductFilterFormProps) {
    super(props);

    this.state = {
      categoryDropdown: null,
      key: '',
      staticName: '',
      categories: [],
      attributes: [],
      quantities: [],
      addOns: [],
      statuses: [],
    };
  }

  componentDidMount() {
    Feathers.service('/products-legacy/attributes')
      .find({
        query: {
          $np: 1,
          $sort: { name: 1 },
          entityAttribute: { $ne: 'QUANTITY' },
          isDeleted: { $ne: true },
          $populate: ['entityAttribute'],
        },
      })
      .then((results: any) => {
        this.setState({ ...this.state, attributes: results });
      });

    Feathers.service('/products-legacy/categories')
      .find({
        query: {
          isDisabled: { $ne: true },
          $np: 1,
          $sort: { name: 1 },
          $populate: ['entityAttributes'],
        },
      })
      .then((results: any) => {
        this.setState({ ...this.state, categories: results });
      });

    Feathers.service('/products-legacy/add-ons-base')
      .find({
        query: {
          $sort: { name: 1 },
        },
      })
      .then((results: any) => {
        this.setState({ ...this.state, addOns: results });
      });

    if (this.props.isOrderItemMode) {
      Feathers.service('/order-item-statuses')
        .find({
          query: {
            $np: 1,
            $sort: { name: 1 },
          },
        })
        .then((results: any) => {
          this.setState({ ...this.state, statuses: results });
        });
    }
  }

  componentWillReceiveProps(next) {
    if (next.quantities && this.props.quantities !== next.quantities) {
      this.setState({
        ...this.state,
        quantities: next.quantities.map((q) => ({ text: q, value: q })),
      });
    }
  }

  onQuantityAdd = (e, value) => {
    const foundQty = this.state.quantities.find((q) => q.value === value.value);
    if (!foundQty) {
      const quantities = [...this.state.quantities];
      quantities.push({ text: value.value, value: value.value });
      this.setState({ ...this.state, quantities });
      let v = this.props.quantities;
      if (!Array.isArray(v)) {
        v = [];
        this.props.changeFieldValue('quantities', v);
      }
    }
  };

  renderFields() {
    let fields = [];
    fields.push(
      <Field
        key={'staticName'}
        name="staticName"
        component={semanticFormField}
        as={Input}
        fluid
        placeholder="Name..."
      />
    );
    fields.push(
      <Field
        key={'key'}
        name="key"
        component={semanticFormField}
        as={Input}
        fluid
        placeholder="SKU..."
      />
    );
    fields.push(
      <Field
        key={'category'}
        name="category"
        component={semanticFormDropdownField}
        as={Dropdown}
        options={this.state.categories.map((r) => ({
          value: r._id,
          text: r.internalName ? r.internalName : r.name,
        }))}
        multiple={true}
        search={true}
        selection
        fluid
        placeholder="Category..."
      />
    );

    fields.push(<Field key={'quantities'} name="quantities" component={DropdownTags} fluid />);

    // Build the attribute fields
    const groupedAttributeTypes = this.state.attributes.reduce((prev, cur) => {
      if (!prev.find((ea) => ea._id === cur.entityAttribute._id)) {
        if (cur.entityAttribute) {
          prev.push(cur.entityAttribute);
        }
      }
      return prev;
    }, []);

    const attributeFields = groupedAttributeTypes.map((ea) => (
      <Field
        key={ea._id}
        name={`entityAttribute[${ea._id}]`}
        component={semanticFormDropdownField}
        as={Dropdown}
        options={this.state.attributes
          .filter((a) => a.entityAttribute._id === ea._id)
          .map((c) => ({ text: c.name, value: c._id }))}
        multiple={true}
        search={true}
        selection
        fluid
        placeholder={ea.name}
      />
    ));

    fields = fields.concat(attributeFields);

    fields.push(
      <Field
        key={'addOns'}
        name="addOns"
        component={semanticFormDropdownField}
        as={Dropdown}
        options={this.state.addOns.map((c) => ({ text: c.name, value: c._id }))}
        multiple={true}
        selection
        fluid
        placeholder="Add Ons..."
      />
    );

    if (this.props.isOrderItemMode) {
      fields.push(
        <Field
          key={'start'}
          name="start"
          component={semanticFormDatePickerField}
          as={DatePicker}
          className="ui fluid"
          inline={false}
          placeholderText="Start Date..."
        />
      );

      fields.push(
        <Field
          key={'end'}
          name="end"
          component={semanticFormDatePickerField}
          as={DatePicker}
          className="ui fluid"
          inline={false}
          placeholderText="End Date..."
        />
      );

      fields.push(
        <Field
          key={'productionStatus'}
          name="productionStatus"
          component={semanticFormDropdownField}
          as={Dropdown}
          options={this.state.statuses.map((c) => ({ text: c.name, value: c._id }))}
          multiple={true}
          search={true}
          selection
          fluid
          placeholder="Production Status.."
        />
      );
    }

    fields.push(
      <Field
        key={'itemType'}
        name="itemType"
        component={semanticFormDropdownField}
        as={Dropdown}
        options={[
          { value: 'on-demand', text: 'On Demand' },
          { value: 'stock', text: 'Stock' },
        ]}
        multiple={true}
        search={true}
        selection
        fluid
        placeholder="Item Type.."
      />
    );

    fields.push(
      <Field
        key={'customerJobName'}
        name="customerJobName"
        component={semanticFormField}
        as={Input}
        fluid
        placeholder="Ref name..."
      />
    );

    const chunkedArr = chunk(fields, 4);
    return chunkedArr.map((chunk, index) => {
      return (
        <Form.Group key={`eaChunk${index}`} widths="equal">
          {chunk}
        </Form.Group>
      );
    });
  }

  render() {
    return (
      <Form size={'tiny'} onSubmit={this.props.handleSubmit}>
        {this.renderFields()}
        <Form.Group inline>
          <Form.Button inline size={'tiny'} disabled={this.props.pristine || this.props.submitting}>
            Filter
          </Form.Button>
          <Form.Button
            inline
            size={'tiny'}
            type={'button'}
            disabled={!this.props.isFiltered}
            onClick={() => this.props.reset() && this.props.handleClearFilter()}
          >
            Clear Filter
          </Form.Button>
        </Form.Group>
      </Form>
    );
  }
}

const formName = 'product';
const reduxedForm: any = reduxForm({
  form: formName,
})(ProductFilterForm as any);

const selector = formValueSelector(formName);

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  changeFieldValue: (field, value) => dispatch(change(formName, field, value)),
});
export default connect(mapStateToProps, mapDispatchToProps)(reduxedForm) as any;
