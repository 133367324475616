export const payloadMapper = (value: any, id: string) => {
  const addOns = value?.addOns?.filter(add => add.addOnId !== 'addOnsOther' && value.type === 'single-page')

  let metadata = []

  if (value?.metadata?.length) {

    metadata = value.metadata.map(meta => {

      if (meta.key === 'tr_source_name' && value?.sourceLanguage) {
        return ({ ...meta, key: 'tr_source_name', value: value.sourceLanguage })
      }

      if (meta.key === 'tr_dest_name' && value?.destinationLanguage) {
        return ({ ...meta, key: 'tr_dest_name', value: value.destinationLanguage })
      }

      return meta
    })

  }

  let finishedDimensions = {}
  let addOnsOther

  if (value.isSameDimension) {
    finishedDimensions = {
      width: value?.flatDimensions?.width ?? 0,
      height: value?.flatDimensions?.height ?? 0,
      unitType: value?.flatDimensions?.unitType ?? 0
    }
  } else {
    finishedDimensions = {
      ...value.finishedDimensions,
      unitType: value?.flatDimensions?.unitType
    }
  }

  if (value.type !== 'single-page') {
    addOnsOther = ''
  } else {
    addOnsOther = value?.addOnsOther
  }

  const fileIds = value?.miscUploads?.map(f => f?.uploadLog || f?._id) ?? []

  const payload = {
    customQuoteId: id,
    itemId: value?._id,
    values: {
      price: parseFloat(value.price),
      finishedDimensions,
      addOns,
      addOnsOther,
      quantity: value.quantity ?? 1,
      type: value.type,
      weight: value.weight,
      attributes: value?.attributes ?? [],
      customerReferenceName: value?.customerReferenceName ?? '',
      shipByType: value?.shipByType ?? '',
      additionalInfo: value?.additionalInfo ?? '',
      uploadedAttachments: value?.uploadedAttachments ?? [],
      timeMin: value?.timeMin || 0,
      timeMax: value?.timeMax || 0,
      miscUploads: fileIds,
      metadata,
      initialStatus: value?.initialStatus,
    }
  }

  if (value.type === 'wide-format') {
    delete payload.values.finishedDimensions
  }

  if (value.type === 'other') {
    delete payload.values.attributes
  }


  return payload
}