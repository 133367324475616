import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
  IconButton,
  Box,
  Tooltip,
  Spinner,
  Center,
  Icon,
  Heading,
} from '@chakra-ui/react';

import { FaThList } from 'react-icons/fa';

import type { ModelTypes } from '@inkcloud/icapi-types';
import { Feathers } from '../../bootstrap/feathers/feathers';
import { FormattedDateHover } from '../../common';
import { InvoiceItem } from './InvoiceItem';
import { BillShip } from './BillShip';

interface InvoiceHistoryModalProps {
  order: ModelTypes.Orders;
  onClose: () => void;
}
export default function InvoiceHistoryModal(props: InvoiceHistoryModalProps) {
  const { order, onClose } = props;

  const [isLoading, setIsloading] = useState(false);
  const [invoiceHistory, setInvoiceHistory] = useState<NonNullable<ModelTypes.Invoices[]>>();

  const [isViewInvoiceModalOpen, setIsViewInvoiceModalOpen] = useState(false);

  const [selectedInvoice, setSelectedInvoice] = useState<ModelTypes.Invoices>();

  useEffect(() => {
    const loadInvoiceHistory = async () => {
      setIsloading(true);
      try {
        const res = await Feathers.service('invoices').find({
          query: { order: order?._id, $sort: { updatedAt: -1 } },
        });
        setInvoiceHistory(res?.data);
      } catch (e) {
        console.error(e);
      }
      setIsloading(false);
    };

    if (order?._id) {
      loadInvoiceHistory();
    }
  }, [order?._id]);

  const defaultTemplate = isLoading ? (
    <Spinner />
  ) : (
    <Box
      p={8}
      background="gray.100"
      borderRadius="md"
      textAlign="center"
      w="100%"
      borderWidth="1px"
    >
      <Icon as={FaThList} size={8} />

      <Heading size="md" mt="0">
        No invoice history found
      </Heading>
    </Box>
  );

  return (
    <>
      <Modal isOpen={true} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Invoice History</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {invoiceHistory?.length && invoiceHistory.length > 1 ? (
              <Table size="sm">
                <Thead>
                  <Tr>
                    <Th>Updated At</Th>
                    <Th isNumeric>Amount</Th>
                    <Th></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {invoiceHistory?.map((invoice, index) => (
                    <Tr key={index}>
                      <Td>
                        <FormattedDateHover value={invoice?.updatedAt} />
                      </Td>
                      <Td isNumeric>{invoice?.total}</Td>
                      <Td isNumeric>
                        <Tooltip label="View Invoice">
                          <IconButton
                            aria-label="view invoice"
                            icon={<FaThList />}
                            size="sm"
                            onClick={() => {
                              setIsViewInvoiceModalOpen(true);

                              setSelectedInvoice(invoice);
                            }}
                          />
                        </Tooltip>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            ) : (
              <Center>{defaultTemplate}</Center>
            )}
          </ModalBody>

          <ModalFooter>
            <Button onClick={onClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {isViewInvoiceModalOpen && (
        <Modal isOpen={true} onClose={() => setIsViewInvoiceModalOpen(false)} size="7xl">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Invoice</ModalHeader>
            <ModalBody>
              <BillShip values={order} />
              <InvoiceItem invoiceId={selectedInvoice?._id as string} />
            </ModalBody>
            <ModalFooter>
              <Button onClick={() => setIsViewInvoiceModalOpen(false)}>Close</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
}
