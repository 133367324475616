import * as React from 'react';
import { Modal, Input, Form, Button, Message } from 'semantic-ui-react';
import { Feathers } from '../../../bootstrap/feathers/feathers';
import { useDebounce } from '@inkcloud/components';
interface ImpositionModalProps {
  isOpen: boolean;
  onClose: () => void;
  onReload: () => void;
}

const { useEffect, useState } = React;

export const ImpositionModal: React.FunctionComponent<ImpositionModalProps> = (props) => {
  const { isOpen, onClose, onReload } = props;

  const [humanId, setHumanId] = useState('');
  const [errorMessage, setErrorMessage] = useState({
    search: '',
    submit: '',
  });
  const [isSearching, setIsSearching] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [found, setFound] = useState(null);

  const debouncedFilter = useDebounce(humanId, 250);

  useEffect(() => {
    const searchOrderItem = async () => {
      setErrorMessage({
        ...errorMessage,
        search: '',
      });
      setIsSearching(true);
      setFound(null);
      try {
        const res: any = await Feathers.service('/order-items').find({
          query: {
            humanId,
            $findOne: '1',
          },
        });

        if (res) {
          setFound(res);
        }
      } catch (e) {
        setErrorMessage({
          ...errorMessage,
          search: 'Cannot find the item',
        });
        setFound(null);
      }

      setIsSearching(false);
    };

    if (debouncedFilter) {
      searchOrderItem();
    }
  }, [debouncedFilter]);

  const handleSubmit = async () => {
    setErrorMessage({
      ...errorMessage,
      submit: '',
    });
    setIsSubmitting(true);

    try {
      const res: any = await Feathers.service('/production/batches/request').create({
        orderItem: found?._id,
      });

      onReload();
      onClose();
    } catch (e) {
      const errMsg =
        e?.code < 500 ? e.message : 'We are experiencing technical difficulties. Please try again';
      setErrorMessage({
        ...errorMessage,
        submit: errMsg,
      });
    }

    setIsSubmitting(false);
  };

  const errMsg = errorMessage.search || errorMessage.submit;

  return (
    <Modal size="small" open={isOpen} onClose={onClose} closeIcon>
      <Modal.Header>Request Imposition</Modal.Header>
      <Modal.Content>
        <Form className={`${errMsg ? 'error' : ''}`}>
          <Input
            fluid
            placeholder="Enter Order Item ID..."
            loading={isSearching}
            onChange={(e, data) => setHumanId(data.value)}
          />
          <br />
          {errMsg && <Message error content={errMsg} />}

          <Button
            loading={isSubmitting}
            disabled={!found}
            primary
            content="Submit"
            onClick={handleSubmit}
          />
        </Form>
      </Modal.Content>
    </Modal>
  );
};
