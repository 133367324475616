// import * as React from 'react';
// import { Accordion, Button, Checkbox, Header, Grid, Label, Segment, Table } from 'semantic-ui-react';
// import { Link } from 'react-router-dom';
// import { FormattedDate, FormattedNumber, FormattedRelative } from 'react-intl';
// import { IndexWrapper, IndexWrappedProps } from './../../../bootstrap/IndexWrapper';
// import { ListViewPagination, ListFilterWarning } from './../../../common';
// import FilterForm from './DueDateFilterForm';
// import { PDFPopup } from './../../../common/PDFPopup';
// import * as moment from 'moment';

// const filterMapperPrintable = (filter) => {
//   const filterOut = {...filter};
//   if (filterOut.start) {
//     filterOut.dueDate = moment(filterOut.start).format('YYYY-MM-DD');
//     delete filterOut.start;
//   }
//   return filterOut;
// }

// const filterMapper = (filter) => {
//   const filterOut = {...filter};
//   if (filterOut.start) {
//     const startDate = moment(filterOut.start).format('YYYY-MM-DD');
//     filterOut.estimatedCompletionDate = startDate;
//     delete filterOut.start;
//   }
//   return filterOut;
// }

// export function Container (props: IndexWrappedProps) {
//   if (!props.queryResult) {
//     return null;
//   }

//   const { data, skip, limit, total } = props.queryResult;
//   return (
//     <div>
//       <Header as='h1'>Production View - Due Date Report</Header>
//       <Header attached={'top'} as='h5'>
//       Filter
//       </Header>
//       <Segment attached secondary>
//         <FilterForm
//           onSubmit={props.handleFilterSubmit}
//           handleClearFilter={props.handleClearFilter}
//           isFiltered={props.isFiltered}
//           />
//       </Segment>
//       { Object.keys(props.filter).length > 0 &&
//         <div>
//           <ListFilterWarning isFiltered={props.isFiltered} />
//           <Segment attached loading={props.isLoading}>
//             <ListViewPagination.Top
//               hideAddButton={true}
//               label={'New Production Resource'}
//               link={'/production/resources/add'}
//               leftButton={<PDFPopup service={'/printable/order-items-due'} payload={filterMapperPrintable(props.filter)} trigger={<Button primary content={'Print PDF'} />} />}
//               {...props}
//             />
//             <Table size={'small'}>
//               <Table.Header>
//                 <Table.Row>
//                   <Table.HeaderCell>
//                     <Checkbox
//                       onChange={props.handleSelectAllClick}
//                     />
//                   </Table.HeaderCell>
//                   <Table.HeaderCell>ID</Table.HeaderCell>
//                   <Table.HeaderCell>Customer</Table.HeaderCell>
//                   <Table.HeaderCell>Category</Table.HeaderCell>
//                   <Table.HeaderCell>Description</Table.HeaderCell>
//                   <Table.HeaderCell>Shipping</Table.HeaderCell>
//                 </Table.Row>
//               </Table.Header>
//               <Table.Body>
//                 { data.map( i => (
//                   <Table.Row key={i._id}>
//                     <Table.Cell>
//                       <Checkbox
//                         value={i._id}
//                         checked={props.selected[i._id] || false}
//                         onChange={props.handleSemanticCheckboxClick}
//                       />
//                     </Table.Cell>
//                     <Table.Cell>
//                       { i.humanId }
//                     </Table.Cell>
//                     <Table.Cell>
//                     { i.customer.organizationName ? i.customer.organizationName : `${i.customer.primaryContact.firstName} ${i.customer.primaryContact.lastName}` }

//                     </Table.Cell>
//                     <Table.Cell>
//                       { i.category ? i.category.name : 'Custom' }
//                     </Table.Cell>
//                     <Table.Cell>
//                     { i.attributes && i.attributes.reduce((prev, next) => `${prev} ${next.value.name}`, '')}
//                     </Table.Cell>
//                     <Table.Cell>
//                       {i.order.shipment !== undefined ? `${i.order.shipment.providerName} ${i.order.shipment.serviceDescription}` : 'Will-Call' }
//                     </Table.Cell>
//                     <Table.Cell>
//                       {/* <Link to={'/production/resources/' + i._id}>
//                         <Button size={'mini'}>Edit</Button>
//                       </Link> */}
//                     </Table.Cell>
//                   </Table.Row>
//                 ))}
//               </Table.Body>
//             </Table>
//             <ListViewPagination.Bottom
//               hideDeleteButton={true}
//               label={'Delete'}
//               {...props}
//             />
//           </Segment>
//         </div>
//       }
//       { Object.keys(props.filter).length === 0 && <Segment>Select a due date and click the filter button</Segment> }
//     </div>
//   );
// }

// export default IndexWrapper(Container, 'orderItems', {
//   filterMapper,
//   query: {
//     $sort: { createdAt: 1 },
//     $populate:  ['attributes.value', 'category', 'customer', 'productionStatus.jobStatus', 'order'],
//     'productionStatus.jobStatus': { $nin: [
//       'VOID',
//       'READY_FOR_PICKUP',
//       'PICKED_UP',
//       'APPROVAL_PENDING',
//       'ARTWORK_EXCEPTION',
//       'FILES_NOT_RECEIVED',
//       'FILES_PARTIAL_RECEIVED',
//       'FILES_RECEIVED',
//       'SHIPPED',
//       'SHIPMENT_PENDING',
//       'SHIPPED_COMPLETE',
//     ]},
//   }
// });

import * as React from 'react';
import { Button, Segment, Popup } from 'semantic-ui-react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { PDFPopup } from './../../../common/PDFPopup';

import { FilterForm } from './DueDateFilterForm';

import * as moment from 'moment';

import {
  useList,
  ListPage,
  ListTable,
  basicQueryMap,
  PageHeaderRow,
  generateOperatorOptions,
  Operator,
  EmptyWrapper,
  PaginatorWrapper,
} from '@inkcloud/components';

const { useState } = React;

interface ListProps {}

const serviceName = 'order-items';
const pathPrefix = '/production/view/due-date';

const filterQueryMap = (values) => {
  const filterMap: any = {
    estimatedCompletionDate: values.start ? moment(values.start).format('YYYY-MM-DD') : undefined,
  };

  return [].reduce(
    (m, field) => ({ ...m, [field]: values[field] ? values[field] : undefined }),
    filterMap
  );

  // return basicQueryMap(values, {});
};

const filterMapperPrintable = (filter) => {
  // const filterOut = { ...filter };
  // let filterOut = basicQueryMap(filter, {})
  // if (filterOut.start) {
  //   filterOut.dueDate = moment(filterOut.start).format('YYYY-MM-DD');
  //   delete filterOut.start;
  // }
  const filterOut = {
    dueDate: filter.start ? moment(filter.start).format('MM-DD-YYYY') : undefined,
  };

  return filterOut;
};

export async function getFilterDefinition() {
  let definition = [
    {
      label: 'Due Date',
      value: 'estimatedCompletionDate',
      operators: generateOperatorOptions([Operator.Between]),
      type: 'date',
    },
  ];

  return definition.sort((a: any, b: any) => a.label.localeCompare(b.label));
}

export const DueDate: React.FunctionComponent<ListProps & RouteComponentProps<any>> = (props) => {
  const {
    match: { params },
    location,
    history,
  } = props;

  // const useListValues = useList({
  //   feathers: ['find', serviceName, [{
  //     query: {
  //       $sort: { createdAt: 1 },
  //       $populate: ['attributes.value', 'category', 'customer', 'productionStatus.jobStatus', 'order'],
  //       'productionStatus.jobStatus': {
  //         $nin: [
  //           'VOID',
  //           'READY_FOR_PICKUP',
  //           'PICKED_UP',
  //           'APPROVAL_PENDING',
  //           'ARTWORK_EXCEPTION',
  //           'FILES_NOT_RECEIVED',
  //           'FILES_PARTIAL_RECEIVED',
  //           'FILES_RECEIVED',
  //           'SHIPPED',
  //           'SHIPMENT_PENDING',
  //           'SHIPPED_COMPLETE',
  //         ]
  //       },
  //     }
  //   }]],
  //   filterMap: filterQueryMap,
  //   debounce: 150,
  //   location,
  //   history,
  //   sort: { createdAt: -1 }
  // })

  // const { filter } = useListValues

  // console.log('filter', filter);
  // console.log('basicQueryMap', basicQueryMap(filter, {}));

  // const headerActions = <>
  //   <PDFPopup
  //     service={'/printable/order-items-due'}
  //     payload={filterMapperPrintable(filter)}
  //     trigger={<Button primary content={'Print PDF'} />} />

  // </>

  const tableDefinition = [
    {
      key: 'humanId',
      label: 'ID',
      sortable: true,
      cell: (v, r) => <Link to={`/orders/${r.order?._id}`}>{v}</Link>,
    },
    {
      key: '',
      label: 'Customer',
      sortable: true,
      cell: (v, r) => (
        <Link to={`/customers/${r.customer?._id}`}>
          {r.customer?.organizationName ||
            `${r.customer?.primaryContact?.firstName ?? ''} ${
              r.customer?.primaryContact?.lastName ?? ''
            }`}
        </Link>
      ),
    },
    {
      key: '',
      label: 'Category',
      sortable: true,
      cell: (v, r) => (r?.category ? r?.category?.name ?? '' : 'Custom'),
    },
    {
      key: '',
      label: 'Description',
      sortable: true,
      cell: (v, r) =>
        r?.order?.shipment !== undefined
          ? `${r.order.shipment?.providerName ?? ''} ${r.order.shipment?.serviceDescription ?? ''}`
          : 'Will-Call',
    },
  ];

  const {
    state,
    reload,
    handleDelete,
    selectedItems,
    clearAll,
    setChecked,
    someSelected,
    toggleAll,
    filter,
    setFilter,
    sort,
    handleSort,
  } = useList({
    feathers: [
      'find',
      serviceName,
      [
        {
          query: {
            $sort: { createdAt: 1 },
            $populate: [
              'attributes.value',
              'category',
              'customer',
              'productionStatus.jobStatus',
              'order',
            ],
            'productionStatus.jobStatus': {
              $nin: [
                'VOID',
                'READY_FOR_PICKUP',
                'PICKED_UP',
                'APPROVAL_PENDING',
                'ARTWORK_EXCEPTION',
                'FILES_NOT_RECEIVED',
                'FILES_PARTIAL_RECEIVED',
                'FILES_RECEIVED',
                'SHIPPED',
                'SHIPMENT_PENDING',
                'SHIPPED_COMPLETE',
              ],
            },
          },
        },
      ],
    ],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  }) as any;

  const { queryResult, isLoading: isStateLoading } = state as any;

  const resolvedQueryResult = filter.start
    ? queryResult?.data
      ? queryResult.data
      : queryResult
    : [];

  return (
    <div>
      <PageHeaderRow header="Due Date Report" subheader="Production View" subheaderId={''} />
      <FilterForm value={filter} onChange={(v) => setFilter(v)} onReset={() => setFilter({})} />
      <Segment>
        <EmptyWrapper queryResult={resolvedQueryResult} isStateLoading={isStateLoading}>
          <PaginatorWrapper.Top
            filter={filter}
            reload={reload}
            data={queryResult as any}
            pathPrefix={`${pathPrefix}`}
          >
            {filter.start && (
              <PDFPopup
                service={'/printable/order-items-due'}
                payload={filterMapperPrintable(filter)}
                trigger={<Button primary size="tiny" content={'Print PDF'} />}
              />
            )}
          </PaginatorWrapper.Top>
          <ListTable
            data={resolvedQueryResult}
            definition={tableDefinition}
            selectedItems={selectedItems}
            setChecked={setChecked}
            sort={sort}
            sortClickFunction={handleSort}
            toggleAll={toggleAll}
            withCheckboxes={true}
          />

          <PaginatorWrapper.Bottom
            reload={reload}
            data={queryResult as any}
            pathPrefix={`${pathPrefix}`}
          >
            {someSelected && (
              <Button primary type="button" content="Approve" onClick={handleDelete} />
            )}
          </PaginatorWrapper.Bottom>
        </EmptyWrapper>
      </Segment>

      {/* <ListPage
        header={'Due Date Report'}
        subheader="Production View"
        useListValues={useListValues}
        history={history}
        pathPrefix={pathPrefix}
        tableDefintion={tableDefinition}
        withCheckboxes={true}
        headerActions={headerActions}
        getFilterDefinition={getFilterDefinition}
      /> */}
    </div>
  );
};
