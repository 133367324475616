export const colors = [
  // '#3182CE', '#E53E3E', '#38A169', '#DD6B20', '#805AD5', '#00B5D8', '#D69E2E',
  // '#1A365D', '#2A4365', '#2C5282', '#2B6CB0', '#3182CE', '#4299E1', '#63B3ED', '#90CDF4', '#BEE3F8', '#EBF8FF'
  '#2B6CB0',
  '#6B46C1',
  '#2C7A7B',
  '#68D391',
  '#B7791F',
  '#C05621',
  '#C53030',
];
