import * as React from 'react';
import { Button, Form, Table } from 'semantic-ui-react';
import { EntityDropdownWrapper2 } from '../../../../EntityDropdown';
import { SemanticField } from '../../../../../common/Form';
import Feathers from './../../../../../bootstrap/feathers';

interface IncludedProducts {
  product: string;
  quantity: string;
  price: string;
}

export interface CompositeProps {
  value: IncludedProducts[];
  onChange: (data: any) => void;
}

const ProductDropdown = EntityDropdownWrapper2(
  'products-legacy',
  {
    query: {
      $np: 1,
      $sort: { name: 1 },
      $select: ['staticName', 'key'],
      // inventory: true
    },
  },
  {
    resultMap: (row) => ({ value: row._id, text: `${row?.staticName ?? ''} (${row.key})` }),
    searchMap: (qs) => ({
      $or: [{ name: { $LIKE: qs } }, { key: { $LIKE: qs } }],
    }),
  }
);

const initialState = {
  products: [],
};

type State = typeof initialState;

export class Composite extends React.Component<CompositeProps, State> {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
    };
  }
  async componentDidMount() {
    const products = await Feathers.service('/products-legacy').find({
      query: {
        $np: 1,
        $sort: { name: 1 },
        $select: ['inventoryCount'],
        // inventory: true
      },
    });

    this.setState({ ...this.state, products });
  }
  handleAdd = () => {
    this.props.onChange(this.props.value.concat({ product: '', quantity: '', price: '' }));
  };

  handleDelete = (index) => {
    const includedProducts = [...this.props.value];
    includedProducts.splice(index, 1);
    this.props.onChange(includedProducts);
  };

  handleChange = (value, index) => {
    const includedProducts = [...this.props.value].map((t, i) => (i !== index ? t : { ...value }));
    this.props.onChange(includedProducts);
  };

  public render() {
    const includedProducts = this.props.value;
    return (
      <>
        {includedProducts.length === 0 && (
          <Button type="button" size="tiny" icon="plus" onClick={this.handleAdd} />
        )}
        <Table size="small">
          {includedProducts.length > 0 && (
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell />
                <Table.HeaderCell>
                  <label>SKU</label>
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <label>Quantity</label>
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <label>Price</label>
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <label>Inventory Count</label>
                </Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
          )}
          <Table.Body>
            {includedProducts.map((r, i) => {
              const { product, quantity, price } = r;
              return (
                <Table.Row key={i}>
                  <Table.Cell>{i + 1}</Table.Cell>
                  <Table.Cell>
                    <ProductDropdown
                      fluid
                      selection
                      label="Product"
                      placeholder="Product..."
                      size={'mini'}
                      value={product}
                      onChange={(e, data) => this.handleChange({ ...r, product: data.value }, i)}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <Form.Input
                      placeholder="Quantity..."
                      value={quantity}
                      onChange={(e, data) =>
                        this.handleChange({ ...r, quantity: parseInt(data.value || '0', 10) }, i)
                      }
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <Form.Input
                      placeholder="Price..."
                      value={price}
                      onChange={(e, data) => this.handleChange({ ...r, price: data.value }, i)}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    {this.state.products &&
                      this.state.products.length > 0 &&
                      this.state.products.map((p: any) => p._id === product && p.inventoryCount)}
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    {includedProducts.length > 1 && (
                      <Button
                        size={'mini'}
                        type="button"
                        icon="trash"
                        onClick={() => this.handleDelete(i)}
                      />
                    )}
                    {i === includedProducts.length - 1 && (
                      <Button size="mini" type="button" icon="plus" onClick={this.handleAdd} />
                    )}
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </>
    );
  }
}
