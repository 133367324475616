import * as React from 'react';
import * as Scroll from 'react-scroll';
import { debounce } from 'lodash-es';
import { RouteComponentProps, withRouter, Link, Switch, Route } from 'react-router-dom';
import { FormattedDate, FormattedNumber } from 'react-intl';
import {
  Icon,
  Label,
  Menu,
  Table,
  Segment,
  Button,
  Dimmer,
  Loader,
  Message,
} from 'semantic-ui-react';
import feathers from './../../bootstrap/feathers';
import LineItems from './LineItemsComponent';
import Payment from './PaymentComponent';
import { initLoad, adjustInvoice, paymentAndAdjust } from './remote';
import { PaymentData, PaymentMethod, PaymentCreditCardTypes, NewPaymentData } from './../../types';
import { PageHeaderRow, PreLoader } from '@inkcloud/components';

interface AdjusterProps {}
type AdjusterPropsWithRoute = AdjusterProps & RouteComponentProps<any>;

interface PortalAddState {
  invoiceId?: string;
  invoice: any;
  lineItems?: any[];
  salesTax?: Number;
  discount?: Number;
  originalDiscount?: Number;
  originalTotal?: Number;
  originalSalesTax?: Number;
  amountDue?: Number;
  isTaxRecalculating?: boolean;
  isFinalizing: boolean;
  errorMessage?: string;
  successMessage?: string;
  paymentProcessDetails?: any;
  paymentData: PaymentData;
  orderHumanId?: string;
  orderId: string;
}

class AdjusterComponent extends React.Component<AdjusterPropsWithRoute, PortalAddState> {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleAmountChange = this.handleAmountChange.bind(this);
    this.handlePaymentChange = this.handlePaymentChange.bind(this);
    this.handleIsTaxableChange = this.handleIsTaxableChange.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    this.recalculateSalesTax = debounce(this.recalculateSalesTax.bind(this), 500, {
      leading: true,
    });
    this.state = {
      orderHumanId: '',
      invoice: null,
      lineItems: [],
      isTaxRecalculating: false,
      isFinalizing: false,
      errorMessage: '',
      successMessage: '',
      paymentData: NewPaymentData(),
      orderId: null,
    };
  }

  componentDidMount() {
    this.initialize();
  }

  public initialize() {
    initLoad(this.props.match.params.orderId).then(({ order, lineItems }: any) => {
      const updatedLineItems = lineItems.map((li) => {
        return {
          _id: li._id,
          description: li.description,
          isTaxable: li.isTaxable,
          originalAmount: li.amount,
          amount: li.amount || 0,
          referenceId: li.referenceId,
          referenceType: li.referenceType,
        };
      });

      const updatedPaymentData = NewPaymentData();

      const tokens = order.customer.creditCardTokens || [];
      updatedPaymentData.creditCardType = PaymentCreditCardTypes.New;
      updatedPaymentData.creditCardToken = '';
      if (tokens.length > 0) {
        updatedPaymentData.creditCardType = PaymentCreditCardTypes.Stored;
        const foundDefault = tokens.find((t) => t.isDefault);
        updatedPaymentData.creditCardToken = foundDefault
          ? foundDefault.paymentProfileId
          : tokens[0].paymentProfileId;
        updatedPaymentData.availableCreditCardTokens = tokens;
      }

      this.setState({
        ...this.state,
        orderHumanId: order.humanId,
        orderId: order._id,
        invoice: order.invoice,
        salesTax: order.invoice.salesTax || 0.0,
        discount: order.invoice.discount || 0.0,
        originalSalesTax: order.invoice.salesTax || 0.0,
        originalDiscount: order.invoice.discount || 0.0,
        originalTotal: order.invoice.total || 0.0,
        lineItems: updatedLineItems,
        errorMessage: '',
        isFinalizing: false,
        paymentData: updatedPaymentData,
      });
    });
  }

  recalculateSalesTax() {
    this.setState({
      ...this.state,
      isTaxRecalculating: true,
      successMessage: '',
    });

    adjustInvoice(this.state.invoice._id, this.state.lineItems).then((response: any) => {
      let updatedState = { ...this.state };

      if (response.result) {
        updatedState = {
          ...this.state,
          salesTax: response.invoice.salesTax,
          paymentProcessDetails: response.paymentProcessDetails,
        };

        const txType = response.paymentProcessDetails.transactionType === 'CHARGE' ? 1 : -1;
        updatedState.paymentData.amountDue = response.paymentProcessDetails.amount * txType;
      }

      this.setState({
        ...updatedState,
        isTaxRecalculating: false,
      });
    });
  }

  handleAmountChange(id) {
    return (e) => {
      const updatedLineItems = this.state.lineItems.map((li) => {
        if (li._id === id) {
          li.amount = e.target.rawValue;
        }

        return li;
      });

      this.setState({ ...this.state, lineItems: updatedLineItems }, () =>
        this.recalculateSalesTax()
      );
    };
  }

  handleIsTaxableChange(id) {
    return (e, data) => {
      const updatedLineItems = this.state.lineItems.map((li) => {
        if (li._id === id) {
          li.isTaxable = data.value === 'true' ? true : false;
        }

        return li;
      });

      this.setState({ ...this.state, lineItems: updatedLineItems }, () =>
        this.recalculateSalesTax()
      );
    };
  }

  handleDescriptionChange(id) {
    return (e, data) => {
      const updatedLineItems = this.state.lineItems.map((li) => {
        if (li._id === id) {
          li.description = data.value;
        }

        return li;
      });

      // no need to recalculate sales tax on description changes
      this.setState({ ...this.state, lineItems: updatedLineItems });
    };
  }

  handlePaymentChange(value) {
    this.setState({ ...this.state, paymentData: value });
  }

  handleSubmit() {
    this.setState({
      ...this.state,
      errorMessage: '',
      successMessage: '',
      isFinalizing: true,
    });

    if (this.state.amountDue === 0 || this.state.paymentData.paymentMethod === PaymentMethod.None) {
      adjustInvoice(this.state.invoice._id, this.state.lineItems, false)
        .then((response) => {
          this.setState({
            ...this.state,
            successMessage: 'Invoice has been successfully adjusted',
            isFinalizing: false,
          });

          Scroll.animateScroll.scrollToTop({ smooth: true });
          this.initialize();
        })
        .catch((e) => {
          const message =
            e.code && e.code < 500
              ? e.message
              : 'We are experiencing technical difficulties. Please try again ';
          this.setState({
            ...this.state,
            errorMessage: message,
            isFinalizing: false,
          });
        });
    } else {
      paymentAndAdjust(
        this.state.invoice._id,
        this.state.lineItems,
        this.state.paymentProcessDetails,
        this.state.paymentData
      )
        .then((response: any) => {
          if (!response.result) {
            this.setState({
              ...this.state,
              errorMessage: response.message,
              isFinalizing: false,
            });

            Scroll.scroller.scrollTo('errorMessage', {});
          } else {
            Scroll.animateScroll.scrollToTop({ smooth: true });
            this.setState({
              ...this.state,
              successMessage: 'Invoice has been successfully adjusted',
              isFinalizing: false,
            });

            this.initialize();
          }
        })
        .catch((e) => {
          const message =
            e.code && e.code < 500
              ? e.message
              : 'We are experiencing technical difficulties. Please try again ';
          this.setState({
            ...this.state,
            errorMessage: message,
            isFinalizing: false,
          });
        });
    }
  }
  render() {
    return (
      <div>
        <PageHeaderRow
          header={`Invoice Adjuster`}
          subheader="Order"
          subheaderId={this.state.orderHumanId && this.state.orderHumanId}
        >
          <Link to={`/orders/${this.state.orderId}`}>
            <Button content="Back" size={'mini'} />
          </Link>
        </PageHeaderRow>

        <Segment disabled={this.state.isFinalizing}>
          <Dimmer active={this.state.isFinalizing} inverted>
            <Loader inverted content="Finalizing Adjustment" />
          </Dimmer>
          {this.state.successMessage !== '' && (
            <Message success icon={'thumbs outline up'} content={this.state.successMessage} />
          )}

          <LineItems
            isTaxRecalculating={this.state.isTaxRecalculating}
            lineItems={this.state.lineItems}
            salesTax={this.state.salesTax}
            originalSalesTax={this.state.originalSalesTax}
            discount={this.state.discount}
            originalDiscount={this.state.originalDiscount}
            handleAmountChange={this.handleAmountChange}
            handleIsTaxableChange={this.handleIsTaxableChange}
            handleDescriptionChange={this.handleDescriptionChange}
          />
          <Segment basic style={{ padding: 0 }} loading={this.state.isTaxRecalculating}>
            <Payment
              {...this.state.paymentData}
              customer={this.state.invoice && this.state.invoice.customer}
              onChange={this.handlePaymentChange}
            />
          </Segment>
          {this.state.errorMessage !== '' && (
            <Message
              name="errorMessage"
              error
              icon={'warning circle'}
              content={this.state.errorMessage}
            />
          )}
          {!this.state.successMessage && this.state && this.state.paymentProcessDetails && (
            <Button
              primary
              loading={this.state.isTaxRecalculating}
              disabled={this.state.isTaxRecalculating}
              onClick={this.handleSubmit}
            >
              Finalize Adjustment
            </Button>
          )}
        </Segment>
      </div>
    );
  }
}

export default AdjusterComponent;
