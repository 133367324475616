import * as React from 'react';
import { Modal, Button, Divider, Form, Message } from 'semantic-ui-react';

const { useState } = React;

interface UpdatePasswordModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (value: { password: string; confirmPassword: string }) => void;
  errorMessage: string;
  isSubmitting?: boolean;
}

export const UpdatePasswordModal: React.FunctionComponent<UpdatePasswordModalProps> = ({
  open,
  onClose,
  isSubmitting,
  onSubmit,
  errorMessage,
}) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const innerOnClose = () => {
    setPassword('');
    setConfirmPassword('');
    onClose();
  };

  return (
    <Modal size={'mini'} open={open} onClose={onClose}>
      <Modal.Header>Customer - Update Password</Modal.Header>
      <Modal.Content>
        <Form as="div">
          <Form.Field>
            <Form.Input
              type="password"
              placeholder="New Password..."
              value={password}
              onChange={(e, data) => setPassword(data.value)}
            />
          </Form.Field>
          <Form.Field>
            <Form.Input
              type="password"
              placeholder="Confirm New Password..."
              value={confirmPassword}
              onChange={(e, data) => setConfirmPassword(data.value)}
            />
          </Form.Field>

          {password !== confirmPassword && (
            <Message content="The two password entered must match" negative />
          )}
        </Form>
        {errorMessage && <Message error content={errorMessage} />}
      </Modal.Content>
      <Modal.Actions>
        <Button negative onClick={onClose}>
          Cancel
        </Button>
        <Button
          positive
          icon="checkmark"
          labelPosition="right"
          content="Update"
          loading={isSubmitting}
          onClick={() => {
            onSubmit({ password, confirmPassword });
            setPassword('');
            setConfirmPassword('');
          }}
          disabled={password !== confirmPassword}
        />
      </Modal.Actions>
    </Modal>
  );
};
