import * as React from 'react';
import {
  Button,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react';
import { FormattedDate, FormattedNumber } from 'react-intl';
import { CSVLink } from 'react-csv';
import { useSelector } from 'react-redux';
import { formatAsPercentage } from './helpers';

interface ISplitTableProps {
  results: any;
  splits: string[];
}

const SplitTable: React.FunctionComponent<ISplitTableProps> = (props) => {
  const { results, splits = [] } = props;
  if (!results) {
    return null;
  }
  const tenant = useSelector(
    ({
      globals,
    }: {
      globals: {
        tenant: { internationalization: { locale: string; currency: string; countryCode: string } };
      };
    }) => globals.tenant.internationalization
  );

  // in case tenant is not defined yet
  const { currency = 'USD' } = (tenant ?? { currency: 'USD' });
  const { data } = results;

  const downloadHeaders = [
    { label: 'Period', key: 'date' },
    { label: 'Status', key: 'statusName' },
    { label: 'User', key: 'user.name' },
    { label: 'Count', key: 'count' },
    { label: 'Avg', key: 'avg' },
    { label: 'Min', key: 'min' },
    { label: 'Max', key: 'max' },
  ]

  return (
    <TableContainer>
      <Table size={'sm'}>
        <TableCaption>
          Production Metrics -
            {data?.length > 0 && (
              <Button variant="link" size="sm"
              as={CSVLink}
              colorScheme='blue'
              data={data}
              filename="ic-report-production.csv"
              headers={downloadHeaders}
              target="_blank"
              >
                Download CSV
              </Button>
            )}


              {/* <CSVLink

              data={data}
              filename="ic-report-production.csv"
              headers={downloadHeaders}
              target="_blank"
            >
              Download CSV{' '}
            </CSVLink> */}


          </TableCaption>
        <Thead>
          <Tr>
            {splits.find((s: string) => s.includes('time')) && <Th>Period</Th>}
            {splits.includes('status') && <Th>Status</Th>}
            {splits.includes('user') && <Th>User</Th>}
            <Th isNumeric>Count</Th>
            <Th isNumeric>Avg</Th>
            <Th isNumeric>Min</Th>
            <Th isNumeric>Max</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((row: any, idx) => (
            <Tr key={idx}>
              {splits.find((s: string) => s.includes('time')) && (
                <Td>
                  <FormattedDate value={row?.date} />
                </Td>
              )}
              {splits.includes('status') && <Td>{row.statusName}</Td>}
              {splits.includes('user') && <Td>{row.user?.name}</Td>}
              <Td isNumeric>{row.count}</Td>
              <Td isNumeric>{row.avg ? Math.round(row.avg) : 0}</Td>
              <Td isNumeric>{row.min}</Td>
              <Td isNumeric>{row.max}</Td>
            </Tr>
          ))}
        </Tbody>
        {/* <Tfoot>
        <Tr>
        <Td>Levis</Td>
          <Td isNumeric>345,670</Td>
          <Td isNumeric>325,670</Td>
          <Td isNumeric>45,670</Td>
          <Td isNumeric>10%</Td>
        </Tr>
      </Tfoot> */}
      </Table>
    </TableContainer>
  );
};

export default SplitTable;
