import * as React from 'react';
import {
  Menu,
  Dropdown,
  Header,
  Icon,
  Label,
  Image,
  Grid,
  Loader,
  Popup,
  Modal,
  Segment,
  Table,
  Message,
  Button,
} from 'semantic-ui-react';
import {
  Button as ChakraButton,
  Tag,
  TagLeftIcon,
  TagLabel,
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  Text,
  textDecoration,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';
import { Link, Redirect } from 'react-router-dom';
import { FormattedDate, FormattedTime } from 'react-intl';
import { chunk } from 'lodash-es';
import * as ReactCardFlip from 'react-card-flip';
import ContainerDimensions from 'react-container-dimensions';
import { connect } from 'react-redux';
import { humanize } from 'underscore.string';
import { Metadata } from '@inkcloud/components';
import type { ModelTypes } from '@inkcloud/icapi-types';

// import { PDFPopup } from './/PDFPopup';
import { artworkModalWrapper, InjectedProps as ArtworkModalInjectedProps } from './ArtworkModal';
import {
  printModalWrapper,
  InjectedProps as PrintModalInjectedProps,
  PrintTypes,
} from './PrintModal';
import {
  preflightModalWrapper,
  InjectedProps as PreflightModalInjectedProps,
} from './PreflightModal';
// import { statusChangeModalWrapper, InjectedProps as StatusChangeModalInjectedProps } from './StatusChangeModal';
import { StatusChangerModal } from './StatusChangerModal';
import { TurnAroundModal } from './TurnAroundModal';
import { RefNameModal } from './RefNameModal';
import { DesignTemplateRenderModal } from './DesignTemplateRenderModal';
import ProductionChannelModal from './ProductionChannelModal';
import AttachDocModal from './AttachDocModal';
import { Feathers } from '../bootstrap/feathers/feathers';

import {
  addOnFields,
  rcOptions,
  dynamicAddOns,
} from '../components/order-builder/calculator/AddOnSet';

import { OrderitemModal } from '../components/order-approval/OrderItemModal';
import UpdateSupplierModal from './UpdateSupplierModal';

export type OrderItemModalsProps = ArtworkModalInjectedProps &
  PrintModalInjectedProps &
  PreflightModalInjectedProps;
// & PrintModalInjectedProps & PreflightModalInjectedProps & StatusChangeModalInjectedProps;

export function connectOrderItemModals<T>(Component) {
  const ArtworkModalWrapped = artworkModalWrapper<T>(Component);
  const PrintModalWrapped = printModalWrapper<T>(ArtworkModalWrapped);
  const PreflightModalWrapped = preflightModalWrapper<T>(PrintModalWrapped);
  // const StatusChangeModalWrapped = statusChangeModalWrapper<T>(PreflightModalWrapped);
  // const TurnAroundModalWrapper = turnAroundModalWrapper<T>(StatusChangeModalWrapped);
  return PreflightModalWrapped;
}

const voidStyleWrapper: React.CSSProperties = {
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  zIndex: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const voidStyleInnerWrapper: React.CSSProperties = {
  padding: '1.2em',
  borderRadius: '.28571429rem',
  backgroundColor: 'rgba(242,5,37,0.2)',
  fontSize: '2em',
  color: '#fff',

  transform: 'rotate(-45deg)',
};

// const thumbBaseUrl = 'http://dal05.objectstorage.softlayer.net/v1/AUTH_bdf10df7-94a2-42e6-9b4f-0c3020705c07/ic-artwork-thumbs/reg/';
// const thumbBaseUrl = 'https://ic-thumbs-inkcloud.netdna-ssl.com/ic-artwork-thumbs/reg/';
const thumbBaseUrl = 'https://storage.googleapis.com/artwork-thumbs/reg/';
const baseUrl = 'https://storage.googleapis.com/icstorage/';

function truncate(str, n) {
  return str?.length > n ? `${str.substr(0, n - 4)}...` : str;
}

function isPast(dueDate: Date) {
  if (!dueDate) {
    return false;
  }

  const currentDate = new Date();
  return currentDate > new Date(dueDate);
}

export const readableAddOns = (addOns: any[], allAddOns: any, id: string, primaryData?: string) => {
  if (addOns && addOns.length > 0) {
    let found;

    if (id) {
      const keyToFind = id !== 'primaryData' ? id : primaryData;
      found = addOns.find(
        (a) => a._id === keyToFind?.toUpperCase() || a.addOnId === keyToFind?.toUpperCase()
      );
    }

    if (found) {
      if (primaryData) {
        const foundKey =
          allAddOns &&
          allAddOns.length > 0 &&
          allAddOns.find((a) => a.key.toUpperCase() === id.toUpperCase());

        if (foundKey) {
          const foundValue = foundKey.options.find((k) => k.value === primaryData);
          return `${found.name ? found.name : found.addOnId ? found.addOnId : ''} ${
            foundValue && foundValue.value === '1' ? '' : foundValue ? foundValue.label : ''
          }`;
        }

        return `${found?.name ?? found.addOnId} ${id === 'primaryData' ? '' : primaryData}`;
      }
      return found.name ? found.name : found.addOnId;
    }
  }

  return id;
};

const allAddOns = [...addOnFields, ...dynamicAddOns, { key: 'rc', options: rcOptions }];

export interface AppProps {
  orderItem: any;
  printHandleOpen: any;
  preflightHandleOpen: any;
  handleArtworkManagementClick: any;
  statusChangeHandleOpen: any;
  onReload?: () => void;
  globals?: any;
  showRightMenu?: boolean;
  isVendor?: boolean;
  highlightQuantity?: boolean;
  // onStatusChangerSubmit: (v: any, i: string) => void;
  // onTurnaroundSubmit: (v: any, i: string) => void;
}

export interface AppState {
  isFlipped: boolean;
  isMouseOver: boolean;
  isTurnaroundModalOpen: boolean;
  isRefNameModalOpen: boolean;
  isStatusChangerOpen: boolean;
  isAttachDocOpen: boolean;
  isModifyDataModalOpen: boolean;
  isDesignTemplateRenderModalOpen: boolean;
  isProductionChannelModalOpen: boolean;
  isSupplierModalOpen: boolean;
  activeItem: string;
  renderResult: boolean;
  renderMessage: string;
  orderItemLogs: any[];
  isLogsLoading: boolean;
  designJobId: string;
  isDesignJobModalOpen: boolean;
  isArtworkModalOpen: boolean;
  selectedHumanId: string;
  selectedItem: ModelTypes.OrderItems | null;
  addOns: { _id: string; name: string }[];
}

export class OrderItemComponent2Pre extends React.PureComponent<AppProps, AppState> {
  constructor(props) {
    super(props);
    this.state = {
      isFlipped: false,
      isMouseOver: false,
      activeItem: 'basics',
      isStatusChangerOpen: false,
      isAttachDocOpen: false,
      isTurnaroundModalOpen: false,
      isModifyDataModalOpen: false,
      isDesignTemplateRenderModalOpen: false,
      isProductionChannelModalOpen: false,
      isSupplierModalOpen: false,
      isRefNameModalOpen: false,
      renderResult: false,
      renderMessage: '',
      orderItemLogs: [],
      isLogsLoading: false,
      designJobId: '',
      isDesignJobModalOpen: false,
      isArtworkModalOpen: false,
      selectedHumanId: '',
      selectedItem: null,
      addOns: [],
    };
  }

  loadStatusLog = async (orderItemId: string) => {
    try {
      this.setState({ ...this.state, isLogsLoading: true });
      const res: any = await Feathers.service(`/production-status-log`).find({
        query: {
          printJob: orderItemId,
          $limit: 5,
          $populate: ['createdBy'],
          $sort: {
            createdAt: -1,
          },
        },
      });

      this.setState({
        ...this.state,
        orderItemLogs: res.data,
        isLogsLoading: false,
      });
    } catch (error) {
      this.setState({ ...this.state, isLogsLoading: false });
    }
  };

  handleMenuClick = (e, data) => {
    this.setState({ ...this.state, activeItem: data.name });
  };

  handleMouseEnter = (e) => {
    if (this.state.isFlipped) {
      return;
    }
    this.setState({ ...this.state, isFlipped: true });
  };

  handleHistoryEnter = (orderItemId: string) => {
    this.loadStatusLog(orderItemId);
  };

  handleHistoryLeave = () => {
    this.setState({ ...this.state, orderItemLogs: [] });
  };

  handleMouseLeave = (e) => {
    if (!this.state.isFlipped) {
      return;
    }
    this.setState({ ...this.state, isFlipped: false });
  };

  handleStatusChangerOpen = () => {
    this.setState({ ...this.state, isStatusChangerOpen: true });
  };

  handleTurnAroundOpen = () => {
    this.setState({ ...this.state, isTurnaroundModalOpen: true });
  };

  handleRefNameOpen = () => {
    this.setState({ ...this.state, isRefNameModalOpen: true });
  };

  handleDesignTemplateRender = async (orderItemId: string) => {
    this.setState({ ...this.state, isDesignTemplateRenderModalOpen: true });
    const res: any = await Feathers.service('/design-templates/send-to-renderer').create({
      orderItemId,
    });
    this.setState({
      ...this.state,
      renderMessage: res.message,
      renderResult: res.result,
    });
  };

  handleModifyDesignData = async (orderItemId: string) => {
    try {
      const re = await Feathers.service('/design-templates/send-to-renderer').create({
        orderItemId,
      });
    } catch (e) {}
  };

  handleDeisgnJobLink = async (id: string) => {
    const res: any = await Feathers.service('/design-jobs').find({
      query: {
        orderItem: id,
        $findOne: 1,
      },
    });
    if (res) {
      this.setState({ ...this.state, designJobId: res._id });
    } else {
      this.setState({ ...this.state, isDesignJobModalOpen: true });
    }
  };

  render() {
    const { orderItem: i, showRightMenu = true, isVendor = false } = this.props;
    const { orderItemLogs, isLogsLoading, selectedItem } = this.state;

    // console.log('selectedItem', selectedItem)

    const handleFile = (path: string) => {
      event.preventDefault();
      const baseUrl2 = `https://storage.googleapis.com/`;

      const file = `${baseUrl2}${path}`;
      window.open(file, '_blank');
    };

    const activeItem: string = 'home';
    let attributes: { _id: string; type: any; name?: any; element?: any; rowStyle?: any }[] = [];
    attributes.push({
      _id: 'category',
      type: 'Category',
      name: i?.category?.name ? i.category.name : 'Custom',
    });

    if (i.description) {
      let { description } = i;
      if (i?.productKey) {
        const productLink = i.product?.inventory
          ? `stock-products/edit/${i.product?._id}`
          : `products/${i.product?._id}`;
        description = (
          <div>
            <div>
              <Link to={`/products/${productLink}`}>{i.productKey}</Link>
            </div>
            <div>{i.description.split('\n').map((r, idx) => <div key={idx}>{r}</div>) || ''}</div>
          </div>
        );
      } else {
        const linesArr = i.description.split('\n').filter((f) => f);
        const hasSeeMore = linesArr.length > 4;
        if (hasSeeMore) {
          description = (
            <div>
              <>{linesArr.slice(0, 5).map((r, idx) => <div key={idx}>{r}</div>) || ''}</>
              <Popover trigger="hover" isLazy>
                <PopoverTrigger>
                  {/* <Text fontSize={'xx-small'} cursor={'grab'} textDecoration={'underline'}>See more...</Text> */}
                  <ChakraButton variant={'link'} color={'blue'}>
                    See more...
                  </ChakraButton>
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverHeader>Description</PopoverHeader>
                  <PopoverBody>
                    {linesArr.map((r, idx) => <div key={idx}>{r}</div>) || ''}
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </div>
          );
        } else {
          description = <div>{linesArr.map((r, idx) => <div key={idx}>{r}</div>) || ''}</div>;
        }
      }

      attributes.push({
        _id: 'description',
        type: 'Description',
        // name: i ? description.split('\n') : '',
        element: description,
      });
    }

    const sourceLang =
      i &&
      i.metadata &&
      i.metadata.length > 0 &&
      i.metadata.find((m) => m.key === 'tr_source_name');
    const destinationLang =
      i && i.metadata && i.metadata.length > 0 && i.metadata.find((m) => m.key === 'tr_dest_name');
    const langMap = (
      <div>
        <Label size={'mini'} key={sourceLang && sourceLang._id}>
          {sourceLang && sourceLang.value}
        </Label>
        <Icon name={'long arrow alternate right'} />
        <Label size={'mini'} key={destinationLang && destinationLang._id}>
          {destinationLang && destinationLang.value}
        </Label>
      </div>
    );

    if (i.metadata && i.metadata.length && (sourceLang || destinationLang)) {
      attributes.push({
        _id: 'translation',
        type: 'Translation',
        name: langMap,
      });
    }

    if (i.miscUploads && i.miscUploads.length) {
      attributes.push({
        _id: 'files',
        type: `File${i.miscUploads.length > 1 ? 's' : ''}`,
        name: i.miscUploads.map((m: any, index: number) => (
          <a
            href=""
            style={{ display: 'inline-block', marginRight: '.5rem' }}
            key={index}
            onClick={() => handleFile(`${m.cloudBucket}/${m.cloudFilename}`)}
          >
            <Icon name="file alternate outline" /> {m.originalFilename}
          </a>
        )),
      });
    }

    if (i.artworkVariantValues && i.artworkVariantValues.length) {
      attributes.push({
        _id: 'artworkVariants',
        type: 'Artwork Variants',
        name: i.artworkVariantValues.map((a) => `${a.type}: ${a.value}`).join(','),
      });
    }

    if (i.attributes && i.attributes.length) {
      attributes = attributes.concat(
        i.attributes.map((a) => {
          let val = a.value ? a.value.name : 'N/A';
          if (a.attribute?._id === 'SIZE' && i.isCustomTrim) {
            val = `${i.customTrimWidth} x ${i.customTrimHeight}`;
          }
          return {
            _id: a.attribute._id,
            type:
              typeof a.attribute === 'string' ? humanize(a.attribute) : a.attribute?.name || 'N/A',
            name: val,
          };
        })
      );

      if (i.quantity) {
        attributes.push({
          _id: 'quantity',
          type: 'Quantity',
          name: i.quantity,
          rowStyle:
            this.props.highlightQuantity && i.quantity > 1 ? { backgroundColor: 'yellow' } : {},
        });
      }

      if (!i.attributes.find((a) => a.attribute === 'SIZE' || a.attribute._id === 'SIZE')) {
        if (i.pricingMethod === 5) {
          attributes.push({
            _id: 'size',
            type: 'Size',
            name: `${
              i.cartonType ? `${i.width} x ${i.length} x ${i.depth}` : `${i.width} x ${i.height}`
            }`,
          });
        } else if (i.width && i.height) {
          attributes.push({
            _id: 'size',
            type: 'Size',
            name: `${i.width} x ${i.height}`,
          });
        }
      }
    } else {
      if (i.quantity) {
        attributes.push({
          _id: 'quantity',
          type: 'Quantity',
          name: i.quantity,
          rowStyle:
            this.props.highlightQuantity && i.quantity > 1 ? { backgroundColor: 'yellow' } : {},
        });
      }
      if (i.pricingMethod === 5) {
        attributes.push({
          _id: 'size',
          type: 'Size',
          name: `${
            i.cartonType ? `${i.width} x ${i.length} x ${i.depth}` : `${i.width} x ${i.height}`
          }`,
        });
      } else if (i.width && i.height) {
        attributes.push({
          _id: 'size',
          type: 'Size',
          name: `${i.width} x ${i.height}`,
        });
      }
    }

    if (i.addOns && i.addOns.length) {
      attributes.push({
        _id: 'addOns',
        type: 'Add Ons',
        name: i.addOns
          .map((a) => readableAddOns(this.props.globals.addOns, allAddOns, a.id, a.primaryData))
          .join(', '),
      });
    }

    if (i.cartonTypeName) {
      attributes.push({
        _id: 'cartonType',
        type: 'Carton Type',
        name: i.cartonTypeName,
      });
    }

    if (i.arriveBy) {
      attributes.push({
        _id: 'arriveBy',
        type: 'Arrive By',
        // name: i.arriveBy
        element: <FormattedDate value={i.arriveBy} />,
        // element: <div>asdfdfdsa</div>,
        rowStyle: { backgroundColor: 'yellow' },
      });
    }

    const numChunk = attributes.length > 8 ? 5 : 4;
    const chunked = chunk(attributes, numChunk, null);

    let customerName = 'N/A';
    if (i.customer?.organizationName && i.customer?.organizationName !== '') {
      customerName = i.customer.organizationName;
    } else {
      if (i.customer?.primaryContact) {
        customerName = `${i.customer.primaryContact?.firstName ?? ''} ${
          i.customer.primaryContact?.lastName ?? ''
        }`;
      }
    }

    let micrositeUser = null;
    if (i.micrositeUser) {
      micrositeUser = `${i.micrositeUser?.firstName ?? ''} ${i.micrositeUser?.lastName ?? ''}`;
    }

    const artworkImages = [];
    if (i.uploadSlots) {
    }
    const frontSlot =
      i.uploadSlots &&
      i.uploadSlots.find(
        (s) =>
          (s.sequence === 'FRONT' ||
            s.sequence === 'MULTI_PAGEPDF' ||
            s.sequence === 'MULTIPAGEPDF') &&
          (s.isUploaded || s.thumbnailUrl)
      );
    const backSlot =
      i.uploadSlots &&
      i.uploadSlots.find((s) => s.sequence === 'BACK' && (s.isUploaded || s.thumbnailUrl));

    let statusDescription =
      i.productionStatus && i.productionStatus.jobStatus && i.productionStatus.description;
    let statusStateRed = false;

    let productionStatusBgColor = 'orange';

    if (i.productionStatus.type === 'final') {
      productionStatusBgColor = 'green';
    }

    let approvalStatus;
    let approvalStatusBgColor = 'orange';
    if (i.approvalStatus && i.approvalStatus !== 'approved') {
      approvalStatus = 'Pending Approval';
      statusStateRed = true;

      if (i.approvalStatus === 'rejected') {
        approvalStatus = 'Approval Rejected';
        approvalStatusBgColor = 'red';
      }
    }

    let artworkView;
    if (frontSlot && backSlot) {
      const frontThumbUrl = frontSlot.thumbnailUrl
        ? frontSlot.thumbnailUrl
        : `${thumbBaseUrl}${frontSlot.upload}.jpg`;
      const backThumbUrl = backSlot.thumbnailUrl
        ? backSlot.thumbnailUrl
        : `${thumbBaseUrl}${backSlot.upload}.jpg`;

      artworkView = (
        <div onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}>
          <ReactCardFlip style={{ maxHeight: '100%' }} isFlipped={this.state.isFlipped}>
            <Image
              bordered
              fluid
              key={'front'}
              style={{ margin: 'auto', height: 110, width: 'auto', cursor: 'pointer' }}
              src={frontThumbUrl}
              onClick={() =>
                this.setState({
                  ...this.state,
                  isArtworkModalOpen: true,
                  selectedHumanId: i.humanId,
                })
              }
            />

            <Image
              bordered
              fluid
              key={'back'}
              style={{ margin: 'auto', height: 110, width: 'auto', cursor: 'pointer' }}
              src={backThumbUrl}
              onClick={() =>
                this.setState({
                  ...this.state,
                  isArtworkModalOpen: true,
                  selectedHumanId: i.humanId,
                })
              }
            />
          </ReactCardFlip>
        </div>
      );
    } else if (frontSlot) {
      const frontThumbUrl = frontSlot.thumbnailUrl
        ? frontSlot.thumbnailUrl
        : `${thumbBaseUrl}${frontSlot.upload}.jpg`;

      artworkView = (
        <Image
          bordered
          fluid
          key={'front'}
          style={{ margin: 'auto', height: 110, width: 'auto', cursor: 'pointer' }}
          src={frontThumbUrl}
          onClick={() =>
            this.setState({
              ...this.state,
              isArtworkModalOpen: true,
              selectedHumanId: i.humanId,
            })
          }
        />
      );
    } else if (i.uploadSlots?.length === 0) {
      artworkView = i.product?.images?.[0]?.url ? (
        <Image
          bordered
          fluid
          centered
          src={`${baseUrl}${i.product?.images?.[0]?.url}`}
          style={{ height: 110, width: 'auto', cursor: 'pointer' }}
          onClick={() =>
            this.setState({
              ...this.state,
              isArtworkModalOpen: true,
              selectedHumanId: i.humanId,
            })
          }
        />
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <Icon name="image outline" size="huge" />
        </div>
      );
    }

    const JobstatusId = i.productionStatus?.jobStatus._id ?? i.productionStatus?.jobStatus;
    // i.productionStatus && i.productionStatus.jobStatus && i.productionStatus.jobStatus._id
    const showDesginTemplateRender =
      ['FILES_NOT_RECEIVED', 'FILES_PARTIAL_RECEIVED', 'OK_TO_PRINT'].find(
        (a) => a === JobstatusId
      ) && i?.designTemplateFields?.length > 0;

    const showModifyDesignData =
      ['FILES_RECEIVED', 'OK_TO_PRINT'].find((a) => a === JobstatusId) &&
      i?.designTemplateFields?.length > 0;

    const noThumbnailStatuses = [
      'FILES_NOT_RECEIVED',
      'FILES_PARTIAL_RECEIVED',
      'ARTWORK_EXCEPTION',
    ];

    return (
      <div style={{ marginBottom: 20 }}>
        <Menu attached="top" size={'tiny'} style={{ height: 35 }}>
          <Label as="a" color="blue" size={'tiny'} style={{ height: 21, marginTop: 5 }} ribbon>
            {i.humanId}
          </Label>
          <Menu.Menu>
            <Menu.Item>
              {/* <Label
                color={statusStateRed ? 'red' : productionStatusBgColor }
                basic={!statusStateRed}
                size={'tiny'}
                style={{ marginRight: 10 }}
              >
                <Icon name="signal" />
                {statusDescription}
              </Label> */}
              <Tag
                size={'sm'}
                colorScheme={statusStateRed ? 'red' : productionStatusBgColor}
                variant={statusStateRed ? 'outline' : 'solid'}
                px={2}
                mr={2}
              >
                {/* <TagLeftIcon boxSize='12px' as={FaSignal} /> */}
                <TagLabel>{statusDescription}</TagLabel>
              </Tag>
              {approvalStatus && (
                <Tag
                  size={'sm'}
                  colorScheme={approvalStatusBgColor}
                  variant={'subtle'}
                  px={2}
                  mr={2}
                >
                  {/* <TagLeftIcon boxSize='12px' as={FaSignal} /> */}
                  <TagLabel>{approvalStatus}</TagLabel>
                </Tag>
              )}

              <Popup
                position="right center"
                flowing
                hoverable
                pinned={true}
                size="tiny"
                popperDependencies={[!!isLogsLoading]}
                trigger={
                  <Icon
                    name="clock outline"
                    onMouseEnter={() => this.handleHistoryEnter(i._id)}
                    onMouseLeave={this.handleHistoryLeave}
                  />
                }
              >
                <Header as="h6">Job History Status</Header>
                <Loader active={isLogsLoading} inline="centered" />
                {!isLogsLoading &&
                  orderItemLogs.length > 0 &&
                  orderItemLogs.map((log: any, index: number) => (
                    <div key={index}>
                      {log.status && log.status.description} <FormattedDate value={log.updatedAt} />
                      , <FormattedTime value={log.updatedAt} />
                      {log.createdByModel === 'user' &&
                        log.createdBy &&
                        ` - by ${log.createdBy?.firstName} ${log.createdBy?.lastName}`}
                    </div>
                  ))}
              </Popup>
              {i.metadata?.length > 0 && (
                <Popup
                  position="right center"
                  flowing
                  hoverable
                  pinned={true}
                  size="tiny"
                  popperDependencies={[!!isLogsLoading]}
                  trigger={
                    <Icon
                      name="tasks"
                      // onMouseEnter={() => this.handleHistoryEnter(i._id)}
                      // onMouseLeave={this.handleHistoryLeave}
                    />
                  }
                >
                  <Header as="h6">Metadata</Header>
                  <Loader active={isLogsLoading} inline="centered" />
                  <Metadata metadata={i.metadata} />
                </Popup>
              )}

              {i.customFields?.length > 0 && (
                <Popup
                  position="right center"
                  flowing
                  hoverable
                  pinned={true}
                  size="tiny"
                  popperDependencies={[!!isLogsLoading]}
                  trigger={<Icon name="wordpress forms" />}
                >
                  <Header as="h6">Custom Fields</Header>
                  <Loader active={isLogsLoading} inline="centered" />
                  <Metadata metadata={i.customFields.map((c) => ({ ...c, key: c?.label ?? '' }))} />
                </Popup>
              )}

              {(i.turnaround === 'next-day' || i.isPriority) && (
                <Popup
                  // position="right center"
                  // flowing
                  hoverable
                  pinned={true}
                  size="tiny"
                  popperDependencies={[!!isLogsLoading]}
                  trigger={<Icon name="shipping fast" color={'yellow'} />}
                >
                  <span>This is a priority job</span>
                </Popup>
              )}
              {i.isDigitalDelivery && (
                <Popup hoverable pinned={true} size="tiny" trigger={<Icon name="envelope" />}>
                  <span>Digital Delivery</span>
                </Popup>
              )}
              {i.customerNotes && i.customerNotes.length && (
                <Icon name="sticky note" color={'grey'} />
              )}
            </Menu.Item>
          </Menu.Menu>
          {showRightMenu && (
            <Menu.Menu position="right" size={'mini'}>
              <React.Fragment>
                <Popup
                  trigger={
                    <Link to={`/orders/${i.order}`}>
                      <Menu.Item color={'blue'} icon={'browser'} />
                    </Link>
                  }
                  content="View order details"
                />
              </React.Fragment>
              <Dropdown item icon="setting" simple>
                <Dropdown.Menu direction="right">
                  <Dropdown.Item onClick={this.props.handleArtworkManagementClick} orderitem={i}>
                    <Icon name={'file image outline'} /> Artwork Management
                  </Dropdown.Item>
                  {!isVendor && (
                    <React.Fragment>
                      <Dropdown.Item orderitem={i} onClick={this.props.preflightHandleOpen}>
                        <Icon name={'flag checkered'} /> Preflight Management
                      </Dropdown.Item>
                    </React.Fragment>
                  )}
                  <Dropdown.Item
                    orderitem={i}
                    onClick={() => this.setState({ ...this.state, isStatusChangerOpen: true })}
                  >
                    <Icon name={'signal'} /> Status Changer
                  </Dropdown.Item>
                  {isVendor && (
                    <Dropdown.Item
                      orderitem={i}
                      onClick={() =>
                        this.setState({ ...this.state, isAttachDocOpen: true, selectedItem: i })
                      }
                    >
                      <Icon name={'upload'} /> Attach Documents
                    </Dropdown.Item>
                  )}
                  {!isVendor && (
                    <React.Fragment>
                      <Dropdown.Item onClick={this.handleTurnAroundOpen}>
                        <Icon name={'calendar times'} /> Update Turnaround
                      </Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item
                        onClick={() =>
                          this.setState({
                            ...this.state,
                            isProductionChannelModalOpen: true,
                            selectedItem: i,
                          })
                        }
                      >
                        <Icon name={'sitemap'} /> Update Production Channel
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() =>
                          this.setState({
                            ...this.state,
                            isSupplierModalOpen: true,
                            selectedItem: i,
                          })
                        }
                      >
                        <Icon name={'user'} /> Update Supplier
                      </Dropdown.Item>
                      <Dropdown.Divider />

                      <Dropdown.Item onClick={this.handleRefNameOpen}>
                        <Icon name={'level up alternate'} /> Update Ref Name
                      </Dropdown.Item>
                      {showDesginTemplateRender && (
                        <Dropdown.Item onClick={() => this.handleDesignTemplateRender(i._id)}>
                          <Icon name={'pencil'} /> Trigger Design Template Render
                        </Dropdown.Item>
                      )}
                      {showModifyDesignData && (
                        <Dropdown.Item
                          onClick={() =>
                            this.setState({
                              ...this.state,
                              isModifyDataModalOpen: true,
                            })
                          }
                        >
                          <Icon name={'pencil'} /> Modify Design Data
                        </Dropdown.Item>
                      )}
                    </React.Fragment>
                  )}

                  <Dropdown.Divider />

                  <Dropdown.Item
                    type={PrintTypes.JobTicketFull}
                    payload={{ orderId: i.order }}
                    onClick={this.props.printHandleOpen}
                  >
                    <Icon name={'print'} /> Print Ticket
                  </Dropdown.Item>

                  <Dropdown.Item
                    type={PrintTypes.JobTicketSingle}
                    payload={{ orderItemId: i._id, orderId: i.order }}
                    onClick={this.props.printHandleOpen}
                  >
                    <Icon name={'print'} /> Print Single Job Ticket
                  </Dropdown.Item>
                  {!isVendor && (
                    <React.Fragment>
                      <Dropdown.Item
                        type={PrintTypes.PickList}
                        payload={{ orderId: i.order }}
                        onClick={this.props.printHandleOpen}
                      >
                        <Icon name={'print'} /> Print Pick List
                      </Dropdown.Item>

                      <Dropdown.Item
                        type={PrintTypes.OrderItemLabel}
                        payload={{ orderItemId: i._id, orderId: i.order }}
                        onClick={this.props.printHandleOpen}
                      >
                        <Icon name={'print'} /> Print Order Item Label
                      </Dropdown.Item>

                      <Dropdown.Item
                        type={PrintTypes.OrderReceipt}
                        payload={{ orderId: i.order }}
                        onClick={this.props.printHandleOpen}
                      >
                        <Icon name={'print'} /> Print Will-Call/Delivery Receipt
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => this.handleDeisgnJobLink(i._id)}>
                        <Icon name={'paint brush'} /> Design Job
                      </Dropdown.Item>
                    </React.Fragment>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Menu>
          )}
        </Menu>
        <Segment
          attached="bottom"
          style={{ position: 'relative', paddingLeft: 0, paddingRight: 0 }}
        >
          {statusDescription === 'Void' && (
            <div style={voidStyleWrapper}>
              <div style={voidStyleInnerWrapper}>VOID</div>
            </div>
          )}
          {this.state.activeItem === 'basics' && (
            <Grid>
              <Grid.Row style={{ paddingTop: 0, paddingBottom: 0 }}>
                <Grid.Column width={4} style={{ paddingRight: 0 }}>
                  <Table definition compact style={{ border: 0, fontSize: 11 }} size={'small'}>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell>Date</Table.Cell>
                        <Table.Cell>
                          <FormattedDate value={i.createdAt} />{' '}
                          <FormattedTime value={i.createdAt} />
                        </Table.Cell>
                      </Table.Row>
                      {!isVendor && (
                        <Table.Row>
                          <Table.Cell>Customer</Table.Cell>
                          <Table.Cell>
                            <Link to={`/customers/${i.customer?._id}`}>{customerName}</Link>
                          </Table.Cell>
                        </Table.Row>
                      )}
                      {i.micrositeUser && (
                        <Table.Row>
                          <Table.Cell>Microsite User</Table.Cell>
                          <Table.Cell>{micrositeUser}</Table.Cell>
                        </Table.Row>
                      )}

                      {!isVendor && i.vendor && (
                        <Table.Row>
                          <Table.Cell>Vendor</Table.Cell>
                          <Table.Cell>{truncate(i.vendor?.companyName ?? '', 15)}</Table.Cell>
                        </Table.Row>
                      )}

                      <Table.Row>
                        <Table.Cell>Ref</Table.Cell>
                        <Table.Cell>
                          {i.customerJobName ? i.customerJobName : '(Empty job name)'}
                        </Table.Cell>
                      </Table.Row>
                      {i.poHumanId && (
                        <Table.Row>
                          <Table.Cell>PO</Table.Cell>
                          <Table.Cell>{i.poHumanId ? i.poHumanId : ''}</Table.Cell>
                        </Table.Row>
                      )}
                      {i.dueDate && (
                        <Table.Row>
                          <Table.Cell>Due Date</Table.Cell>
                          <Table.Cell>
                            {i.dueDate && (
                              <div>
                                <Text
                                  as={
                                    i.productionStatus.type !== 'final' && isPast(i.dueDate)
                                      ? 'mark'
                                      : 'span'
                                  }
                                  color={
                                    i.productionStatus.type !== 'final' && isPast(i.dueDate)
                                      ? 'red'
                                      : undefined
                                  }
                                >
                                  <FormattedDate value={i.dueDate} />
                                </Text>
                                {/* <Icon name="exclamation triangle" color="red" size="small"/> */}
                              </div>
                            )}
                          </Table.Cell>
                        </Table.Row>
                      )}

                      {i.estimatedCompletionDate && (
                        <Table.Row>
                          <Table.Cell>Est Completion</Table.Cell>
                          <Table.Cell>
                            {i.estimatedCompletionDate && (
                              <div>
                                <Text
                                  color={
                                    i.productionStatus.type !== 'final' &&
                                    isPast(i.estimatedCompletionDate)
                                      ? 'red'
                                      : undefined
                                  }
                                >
                                  <FormattedDate value={i.estimatedCompletionDate} />
                                </Text>
                                {/* <Icon name="exclamation triangle" color="red" size="small"/> */}
                              </div>
                            )}
                          </Table.Cell>
                        </Table.Row>
                      )}
                      {i.productionChannel?.name && (
                        <Table.Row>
                          <Table.Cell>Production Channel</Table.Cell>
                          <Table.Cell>
                            {i.productionChannel ? i.productionChannel.name : ''}
                          </Table.Cell>
                        </Table.Row>
                      )}
                    </Table.Body>
                  </Table>
                </Grid.Column>

                {chunked.map((c, index) => (
                  <Grid.Column
                    stretched
                    key={`key${index}`}
                    width={4}
                    style={{ paddingLeft: 0, paddingRight: 0 }}
                  >
                    <Table
                      definition
                      compact
                      style={{ border: 0, fontSize: 11, height: '100%' }}
                      size={'small'}
                    >
                      <Table.Body>
                        {c.map((a, indexInner) => (
                          <Table.Row key={`attr${index}${indexInner}`} style={a.rowStyle}>
                            <Table.Cell>{a ? a.type : 'N/A'}</Table.Cell>
                            <Table.Cell>
                              {a ? a.name : a.element ? a.element : 'N/A'}
                              {a.element}
                            </Table.Cell>
                          </Table.Row>
                        ))}
                      </Table.Body>
                    </Table>
                  </Grid.Column>
                ))}
                <Grid.Column stretched width={4} style={{ paddingLeft: 0 }}>
                  <div
                    style={{
                      height: '100%',
                      padding: 10,
                      background: '#00000008',
                    }}
                  >
                    {artworkView}
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          )}

          {this.state.isArtworkModalOpen && (
            <Modal
              open
              onClose={() =>
                this.setState({
                  ...this.state,
                  isArtworkModalOpen: false,
                  selectedHumanId: '',
                })
              }
              closeIcon
              size="large"
            >
              <Modal.Header>{this.state.selectedHumanId}</Modal.Header>
              <Modal.Content>
                {frontSlot && backSlot ? (
                  <>
                    <div>
                      <Image
                        key={'front'}
                        centered
                        src={
                          frontSlot.thumbnailUrl
                            ? frontSlot.thumbnailUrl
                            : `${thumbBaseUrl}${frontSlot.upload}.jpg`
                        }
                      />
                    </div>
                    <div>
                      <Image
                        key={'back'}
                        centered
                        src={
                          backSlot.thumbnailUrl
                            ? backSlot.thumbnailUrl
                            : `${thumbBaseUrl}${backSlot.upload}.jpg`
                        }
                      />
                    </div>
                  </>
                ) : (
                  frontSlot && (
                    <Image
                      key={'front'}
                      centered
                      src={
                        frontSlot.thumbnailUrl
                          ? frontSlot.thumbnailUrl
                          : `${thumbBaseUrl}${frontSlot.upload}.jpg`
                      }
                    />
                  )
                )}

                {i?.product?.inventory && i.uploadSlots?.length === 0 && (
                  <Image fluid centered src={`${baseUrl}${i.product?.images?.[0]?.url}`} />
                )}
              </Modal.Content>
            </Modal>
          )}

          {this.state.isStatusChangerOpen && (
            <StatusChangerModal
              open={this.state.isStatusChangerOpen}
              orderItem={i}
              onReload={this.props.onReload}
              onClose={() => this.setState({ ...this.state, isStatusChangerOpen: false })}
            />
          )}

          {this.state.isTurnaroundModalOpen && (
            <TurnAroundModal
              open={this.state.isTurnaroundModalOpen}
              orderItem={i}
              onReload={this.props.onReload}
              onClose={() => this.setState({ ...this.state, isTurnaroundModalOpen: false })}
            />
          )}
          {this.state.isRefNameModalOpen && (
            <RefNameModal
              open={this.state.isRefNameModalOpen}
              orderItem={i}
              onReload={this.props.onReload}
              onClose={() => this.setState({ ...this.state, isRefNameModalOpen: false })}
            />
          )}

          {this.state.isDesignTemplateRenderModalOpen && (
            <DesignTemplateRenderModal
              open={this.state.isDesignTemplateRenderModalOpen}
              onClose={() =>
                this.setState({
                  ...this.state,
                  isDesignTemplateRenderModalOpen: false,
                })
              }
              renderResult={this.state.renderResult}
              renderMessage={this.state.renderMessage}
            />
          )}

          {this.state.isProductionChannelModalOpen && (
            <ProductionChannelModal
              item={selectedItem}
              onReload={this.props?.onReload}
              onClose={() =>
                this.setState({
                  ...this.state,
                  isProductionChannelModalOpen: false,
                  selectedItem: null,
                })
              }
            />
          )}

          {this.state.isModifyDataModalOpen && (
            <OrderitemModal
              isOpen={this.state.isModifyDataModalOpen}
              onClose={() =>
                this.setState({
                  ...this.state,
                  isModifyDataModalOpen: false,
                })
              }
              onReload={this.props.onReload}
              item={i}
            />
          )}

          {this.state.isDesignJobModalOpen && (
            <Modal
              open={this.state.isDesignJobModalOpen}
              onClose={() => this.setState({ ...this.state, isDesignJobModalOpen: false })}
              closeIcon
            >
              <Modal.Content>
                <Message error content="Design job not found" />
              </Modal.Content>
            </Modal>
          )}

          {this.state.isSupplierModalOpen && (
            <UpdateSupplierModal
              onClose={() =>
                this.setState({
                  ...this.state,
                  isSupplierModalOpen: false,
                })
              }
              onReload={this.props.onReload as () => void}
              item={i}
            />
          )}

          {this.state.isAttachDocOpen && (
            <AttachDocModal
              orderId={selectedItem?.order ?? ''}
              onClose={() =>
                this.setState({ ...this.state, isAttachDocOpen: false, selectedItem: null })
              }
              onReload={this.props.onReload}
            />
          )}

          {/* {this.state.designJobId && <Redirect to={`/design-jobs/${this.state.designJobId}`} />} */}
        </Segment>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  globals: state.globals,
});

const mapDispatchToProps = {};

export const OrderItemComponent2 = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderItemComponent2Pre);
