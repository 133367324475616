import React, { useState, useEffect, useRef } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { FormattedDate, FormattedTime } from 'react-intl';
import {
  PageHeaderRow,
  PreLoader,
  basicQueryMap,
  useDebounce,
  GenericFilter,
  useList,
  ListPage,
} from '@inkcloud/components';

import {
  Table as ChakraTable,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  Switch,
  FormControl,
  FormLabel,
  TableContainer,
  Box,
  Heading,
  HStack,
  Tag,
  TagLabel,
  Flex,
  Button as ChakraButton,
  Alert,
  AlertTitle,
  AlertDescription,
  Wrap,
  Card,
  CardBody,
  Tooltip,
} from '@chakra-ui/react';

import { CheckCircleIcon } from '@chakra-ui/icons'

import type { ModelTypes } from '@inkcloud/icapi-types';

import { getFilterDefinition } from './FilterForm';

const serviceName = 'inventory/movements';

const colorLookUp = {
  ADJUSTMENT: 'telegram',
  OPENING_AMOUNT: 'gray',
  RECEIVE: 'green',
  RETURN: 'orange',
  FULFILL: 'teal',
  MOVEMENT: 'blue',
  ALLOCATED: 'cyan',
  BACK_ORDER: 'purple',
  VOID_ADJUSTMENT: 'pink',
};

type OperationType = NonNullable<ModelTypes.InventoryMovement['operation']>;

const colorize = (operation: OperationType) => colorLookUp[operation];

interface IMovementProps {
  productId: string;
}

const filterQueryMap = (values) => {
  let filterRows = basicQueryMap(values, {});

  const { operation, order } = filterRows;

  filterRows = {
    ...filterRows,
    operation: operation?.$elemMatch?.value,
  };

  return filterRows;
};

export default function Movement(props: IMovementProps) {
  const { productId } = props;

  const history = useHistory();
  const location = useLocation();

  const pathPrefix = `/products/${productId}/inventory-ledger`;

  // eslint-disable-next-line no-undef
  const movementRef = useRef<HTMLDivElement>(null);

  // console.log('movementRef', movementRef?.current?.offsetTop)

  const scrollTop = movementRef?.current?.offsetTop;

  // console.log('movementRef?.current?.offsetTop]', movementRef?.current?.offsetTop)
  const useListValues = useList({
    feathers: [
      'find',
      serviceName,
      [
        {
          query: {
            $populate: [
              { path: 'source', select: ['name'] },
              { path: 'destination', select: ['name'] },
              { path: 'order', select: ['humanId'] },
              { path: 'requestedBy', select: ['firstName', 'lastName'] },
            ],
            product: productId,
          },
        },
      ],
    ],
    filterMap: filterQueryMap,
    debounce: 300,
    location,
    history,
    sort: { createdAt: -1 },
  });

  // console.log('useListValues', useListValues);

  const { filter } = (history.location.state as any) || {};

  useEffect(() => {
    // console.log('scrollTop', scrollTop)

    if (filter?.rows?.length || history.location.search) {
      history.push({
        ...(history.location || {}),
        state: {
          // ...(history.location.state || {}),
          top: scrollTop,
        },
      });
    }
  }, [history.location.search, filter?.rows?.length, scrollTop]);

  const tableDefinition = [
    {
      key: 'createdAt',
      label: 'Date',
      sortable: true,
      cell: (v) =>
        v && (
          <Tooltip hasArrow aria-label="time" label={<FormattedTime value={v} />}>
            <Box as="span">
              <FormattedDate value={v} />
            </Box>
          </Tooltip>
        ),
    },

    {
      key: 'operation',
      label: 'Operation',
      sortable: true,
      cell: (v, r) =>
        <>
          <Tag colorScheme={colorize(v as OperationType)}>{v}</Tag>
          {r.fulfillmentMovement && (
            <Tooltip hasArrow aria-label="time" label="Allocation has been fulfilled">
            <CheckCircleIcon
              color={'green.500'}
              style={{ marginLeft: 8 }}
            />
          </Tooltip>
          )}

        </>
        ,
    },
    {
      key: '',
      label: 'From',
      sortable: true,
      cell: (v, r) => (r?.source as any)?.name ?? '',
    },
    {
      key: '',
      label: 'To',
      sortable: true,
      cell: (v, r) => (r?.destination as any)?.name ?? '',
    },
    {
      key: '',
      label: 'Order',
      sortable: true,
      cell: (v, r) =>
        r.order && (
          <Link style={{ paddingLeft: 8 }} to={`/orders/${(r.order as any)._id}`}>
            <Tag size="sm" variant="outline" colorScheme="blue">
              <TagLabel>Order {(r.order as any).humanId}</TagLabel>
            </Tag>
          </Link>
        ),
    },

    {
      key: '',
      label: 'Requested By',
      sortable: true,
      cell: (v, r) => (
        <>
          {(r.requestedBy as any)?.firstName ?? ''} {(r.requestedBy as any)?.lastName ?? ''}
        </>
      ),
    },
    {
      key: 'notes',
      label: 'Notes',
      sortable: true,
      cell: (v, r) =>
        v && (
          <Card>
            <CardBody>{v}</CardBody>
          </Card>
        ),
    },
    {
      key: 'tags',
      label: 'Tags',
      sortable: true,
      cell: (v, r) => (
        <Wrap spacing={4}>
          {v?.map((tag: string, idx: number) => (
            <Tag size="sm" key={idx} variant="outline">
              <TagLabel>{tag}</TagLabel>
            </Tag>
          ))}
        </Wrap>
      ),
    },

    {
      key: 'amount',
      label: 'Count',
      sortable: true,
      cell: (v, r) => v,
    },
    {
      key: '',
      label: 'Cost',
      sortable: true,
      cell: (v, r) => r?.operation !== 'ADJUSTMENT' && <Tag size="tiny">{r?.price || '-'}</Tag>,
    },
  ];

  return (
    <Box ref={movementRef}>
      <ListPage
        header=""
        useListValues={useListValues}
        history={history}
        pathPrefix={pathPrefix}
        tableDefintion={tableDefinition}
        withCheckboxes={true}
        getFilterDefinition={getFilterDefinition}
      />
    </Box>
  );
}
