import * as React from 'react';
import { Button, Input, Dropdown, Segment, Form } from 'semantic-ui-react';
import { debounce } from 'lodash-es';
import Feathers from '../../../bootstrap/feathers';

const { useState, useEffect } = React;

export interface FilterFormProps {
  value: any;
  onChange: Function;
  onReset: Function;
  microsite: string;
}

function initMapper(value) {
  const defaults = {
    firstName: '',
    lastName: '',
    email: '',
    groups: [],
  };

  return Object.keys(defaults).reduce((p, cur) => {
    if (!p[cur]) {
      p[cur] = defaults[cur];
    }
    return p;
  }, value);
}

export function FilterForm(props: FilterFormProps) {
  const { value: preInitValue } = props;
  const [groups, setGroups] = useState([]);
  const [isSetup, setIsSetup] = useState(false);

  const value = initMapper(preInitValue);

  const onChange = (field, value) => {
    props.onChange && props.onChange({ ...props.value, [field]: value });
  };

  const loadCategories = async () => {
    if (!isSetup) {
      const [groups] = await Promise.all([
        Feathers.service('/microsites/customer/groups').find({
          query: {
            $np: 1,
            $select: ['_id', 'name'],
            $sort: { name: 1 },
            microsite: props.microsite,
          },
        }) as any,
      ]);

      setGroups(groups.map((c) => ({ value: c._id, text: c.name })));
      setIsSetup(true);
    }
  };

  useEffect(() => {
    loadCategories();
  }, []);

  if (!isSetup) return null;

  return (
    <Segment>
      <Form size={'small'}>
        <Form.Group widths={'equal'}>
          <Form.Field>
            <Input
              placeholder="First Name..."
              value={value.firstName}
              onChange={(e, data) => onChange('firstName', data.value)}
            />
          </Form.Field>
          <Form.Field>
            <Input
              placeholder="Last Name..."
              value={value.lastName}
              onChange={(e, data) => onChange('lastName', data.value)}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group widths={'equal'}>
          <Form.Field>
            <Form.Input
              placeholder="Email..."
              value={value.email}
              onChange={(e, data) => onChange('email', data.value)}
            />
          </Form.Field>
          <Form.Field>
            <Dropdown
              value={value.groups}
              onChange={(e, data) => onChange('groups', data.value)}
              options={groups}
              multiple
              placeholder={'Groups...'}
              selection
              search
            />
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field>
            <Button size={'tiny'} content="Reset Form" onClick={() => props.onReset()} />
          </Form.Field>
        </Form.Group>
      </Form>
    </Segment>
  );
}
