import * as React from 'react';
import { Button, Dropdown, Segment } from 'semantic-ui-react';
import {
  Button as ChakraButton,
  Input,
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  Th,
  Heading,
} from '@chakra-ui/react';
import { ModelTypes } from '@inkcloud/icapi-types';
import Select from 'react-select';
import { icapi } from '../../bootstrap/feathers';

import RulesEngineCondition, { RuleConditionValue } from './RuleEngineCondition';

interface IRulesEngineRuleProps {
  definition: any;
  onChange: (v: any) => void;
  value: any;
}

const initialConditions: RuleConditionValue[] = [
  {
    selectedCondition: '',
    selectedOperator: '',
    value: '',
  },
];

const MiscDocsActions: React.FunctionComponent = (props) => {
  const [selectedMiscDocs, setSelectedMiscDocs] = React.useState([] as ModelTypes.MiscDocuments[]);

  const [miscDocs, setMiscDocs] = React.useState([] as ModelTypes.MiscDocuments[]);

  React.useEffect(() => {
    const loadDocs = async () => {
      const results = (await icapi.service('misc-documents').find({})) as any;
      setMiscDocs(results.data);
    };

    loadDocs();
  }, []);

  const actionOptions = miscDocs.map((md) => ({ value: md._id, label: md.name }));
  const selectValues = miscDocs
    .filter((ac) => selectedMiscDocs.map((s: any) => s.value).includes(ac._id))
    .map((m) => ({ value: m._id, label: m.name }));

  const a = '';
  return (
    <Box>
      <Table>
        <Tbody>
          <Tr>
            <Td>Attach Miscellanous Document</Td>
            <Td>
              <Select
                isMulti
                value={selectValues}
                placeholder="Select misc doc..."
                options={actionOptions}
                onChange={(v: any) => {
                  setSelectedMiscDocs(v);
                }}
              />
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </Box>
  );
};

const RulesEngineRule: React.FunctionComponent<IRulesEngineRuleProps> = (props) => {
  const { definition: selectedEngineDefinition, onChange, value } = props;
  // const [ruleDefinitions, setRuleDefinitions] = React.useState(null);
  // const [selectedEngineType, setSelectedEngineType] = React.useState(null);

  const [rootOperator, setRootOperator] = React.useState('any');
  const [conditions, setConditions] = React.useState(initialConditions);

  const [actions, setActions] = React.useState([]);

  if (!selectedEngineDefinition) {
    return null;
  }

  return (
    <div>
      <Segment>
        <Heading size="md">Conditions</Heading> <br />
        <Dropdown
          selection
          value={rootOperator}
          options={[
            { value: 'any', text: 'Any' },
            { value: 'all', text: 'All' },
          ]}
          onChange={(e, data) => setRootOperator(data.value as string)}
          size={'tiny'}
        />
        <Button
          size={'tiny'}
          content="Add Rule"
          onClick={() => setConditions(conditions.concat([...initialConditions]))}
        />
        <Table>
          <Tbody>
            {selectedEngineDefinition &&
              conditions.map((c, idx) => (
                <RulesEngineCondition
                  key={idx}
                  ruleDefinition={selectedEngineDefinition}
                  value={c}
                  onChange={(v) => {
                    const updatedConditions = conditions.map((c2, idx2) => (idx === idx2 ? v : c2));
                    setConditions(updatedConditions);
                  }}
                />
              ))}
          </Tbody>
        </Table>
      </Segment>

      {selectedEngineDefinition && (
        <Segment>
          <Heading size="md">Actions</Heading> <br />
          {selectedEngineDefinition.key === 'misc-docs' && <MiscDocsActions />}
        </Segment>
      )}
    </div>
  );
};

export default RulesEngineRule;
