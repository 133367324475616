import * as React from 'react';
import { Modal, Header, Button, Icon, Message } from 'semantic-ui-react';

interface DesignTemplateRenderModalProps {
  open: boolean;
  renderResult: boolean;
  renderMessage: string;
  onClose: () => void;
}

export const DesignTemplateRenderModal: React.FunctionComponent<DesignTemplateRenderModalProps> = (
  props
) => {
  const { open, renderResult, renderMessage, onClose } = props;

  return (
    <>
      <Modal open={open} onClose={onClose} size="small">
        <Header content="Design Template Render" />
        <Modal.Content>
          {renderMessage && (
            <Message className={`${renderResult ? 'info' : 'error'}`} content={renderMessage} />
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button color="red" onClick={onClose} content="Close" />
        </Modal.Actions>
      </Modal>
    </>
  );
};
