import * as React from 'react';
import { Box, Button, Flex, Stack, Input, Divider, Heading } from '@chakra-ui/react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FormControl } from '../../common/v3/Form/FormControl';

interface Value {
  key: string;
  value: {
    out: number;
    pull: number;
    sheets: number;
    yards: number;
  };
}
interface ITurnedEdgeProps {
  quantity: number;
  componentKey: string;
  values?: {
    cutSize: number;
    length: number;
    times: number;
    rollSize: number;
    type: any;
    color: any;
  };
  onChange(value: any): void;
}
const schema = yup.object({
  cutSize: yup.number().required(),
  length: yup.number().nullable(true).min(0).typeError('must be a number'),
  times: yup.number().nullable(true).min(0).typeError('must be a number'),
  rollSize: yup.number().nullable(true).min(0).typeError('must be a number'),
  type: yup
    .number()
    .nullable(true)
    .min(0)
    .typeError('must be a number')
    .transform((_, val) => (val === Number(val) ? val : null)),
  color: yup
    .number()
    .nullable(true)
    .min(0)
    .typeError('must be a number')
    .transform((_, val) => (val === Number(val) ? val : null)),
});
// force on this one

const TurnedEdge: React.FunctionComponent<ITurnedEdgeProps> = (props) => {
  const { quantity, componentKey: key, onChange, values } = props;
  const useFormMethods = useForm({
    mode: 'onBlur',
    // values: { cutSize: 0, length: 0, times: 0, rollSize: 0 },
    defaultValues: values || {},
    resolver: yupResolver(schema),
  });
  const { handleSubmit, watch, getValues } = useFormMethods;

  const watchCutSize = Number(watch('cutSize')) || 0;
  const watchLength = Number(watch('length')) || 0;
  const watchTimes = Number(watch('times')) || 0;
  const watchRollSize = Number(watch('rollSize')) || 0;

  console.log({
    quantity,
    watchCutSize,
    watchLength,
    watchTimes,
    watchRollSize,
    values: getValues(),
  });

  // let out = 0
  // let pull = 0
  // let sheets = 0
  // const quantityNum = Number(props.quantity)
  const out = Math.floor(watchRollSize / watchCutSize) * watchTimes || 0;
  const pull = watchLength > 0 && watchTimes > 0 ? watchLength * watchTimes + 1 : 0;
  const sheets = isFinite(Math.ceil(Number(quantity) / out))
    ? Math.ceil(Number(quantity) / out)
    : 0;

  // What is 36?  36 inches in a yard
  const yards = Math.ceil((pull * sheets) / 36);

  console.log({ out, pull, sheets, yards });

  React.useEffect(() => {
    console.log('got a change here', {
      key,
      value: {
        out,
        pull,
        sheets,
        yards,
      },
    });

    if (onChange) {
      onChange({
        key,
        inputValue: getValues(),
        value: {
          out: Number.isNaN(out) ? 0 : out,
          pull: Number.isNaN(pull) ? 0 : pull,
          sheets: Number.isNaN(sheets) ? 0 : sheets,
          yards: Number.isNaN(yards) ? 0 : yards,
        },
      });
    }
  }, [out, pull, sheets, yards]);

  return (
    <Box>
      <form
        onSubmit={handleSubmit((v) => {
          console.log('got these values', v);
        })}
      >
        <FormProvider {...useFormMethods}>
          <Stack direction={'row'} gap={2} spacing={2}>
            <FormControl
              size={'sm'}
              label="Cut Size"
              field={'cutSize'}
              required={true}
              render={(field) => <Input width={75} {...field} htmlSize={4} />}
            />

            <FormControl
              size={'sm'}
              label="Length"
              field={'length'}
              render={(field) => <Input width={75} {...field} htmlSize={5} />}
            />

            <FormControl
              size={'sm'}
              label="Times"
              field={'times'}
              render={(field) => <Input width={75} {...field} htmlSize={3} />}
            />
          </Stack>
          <Stack direction={'row'} gap={2}>
            <FormControl
              size={'sm'}
              label="Roll Size"
              field={'rollSize'}
              render={(field) => <Input width={75} {...field} htmlSize={5} />}
            />

            <FormControl
              size={'sm'}
              label="Type"
              field={'type'}
              render={(field) => <Input width={75} {...field} htmlSize={5} />}
            />

            <FormControl
              size={'sm'}
              label="Color"
              field={'color'}
              render={(field) => <Input width={75} {...field} htmlSize={5} />}
            />
          </Stack>

          <Divider />

          <Stack direction={'row'} gap={4} bgColor={'gray.50'} padding={4}>
            <Heading mt="auto" size={'sm'}>
              Out: {out}
            </Heading>
            <Heading size={'sm'}>Yards: {yards || 0}</Heading>
            <Heading size={'sm'}>Sheets: {sheets || 0}</Heading>
            <Heading size={'sm'}>Pull: {pull}</Heading>
          </Stack>
        </FormProvider>
      </form>
    </Box>
  );
};

export default TurnedEdge;
