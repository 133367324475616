import * as React from 'react';
import { Switch, Route } from 'react-router-dom';

import List from './List';
import Detail from './Detail';

const routes = (props) => (
  <Switch>
    <Route exact path="/settings/production/suppliers/:supplierId/users" component={List} />
    <Route path="/settings/production/suppliers/:supplierId/users/add" component={Detail} />
    <Route path="/settings/production/suppliers/:supplierId/users/:id" component={Detail} />
  </Switch>
);

export default routes;
