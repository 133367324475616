import { Alert } from '@chakra-ui/react';
import { PaymentElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js';
import { PaymentMethod } from '@stripe/stripe-js';
import React, { useState } from 'react';

type CheckoutFormProps = {
  onSubmit: (v: { stripePaymentMethod: string | PaymentMethod | null }) => void;
};

export function StripeCheckoutForm(props: CheckoutFormProps) {
  const { onSubmit } = props;
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setMessage('');

    const { error, setupIntent } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: '',
      },
      redirect: 'if_required',
    });

    // console.log('setupIntent', setupIntent);

    if (setupIntent?.status === 'succeeded') {
      onSubmit({
        stripePaymentMethod: setupIntent.payment_method,
      });

      return;
    }

    if (error?.type === 'invalid_request_error' || error?.type === 'validation_error') {
      setMessage(
        error?.message ?? 'An unexpected error occured. Please try again in a few minutes.'
      );
    } else {
      setMessage('An unexpected error occured.');
    }
  };

  return (
    <form id="stripe-payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />

      {message && (
        <Alert my={3} status="error">
          {message}
        </Alert>
      )}
    </form>
  );
}

export default StripeCheckoutForm;
