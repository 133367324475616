import * as React from 'react';
import { Checkbox, Radio, Input, Form, Button, Modal } from 'semantic-ui-react';
// import { Formik, Form as FormikForm, Field, FieldArray, FieldProps, ErrorMessage } from 'formik';

import { connect, getIn, Field, FieldArray, FormikProps, FieldProps } from 'formik';

import { SemanticField } from '../../../common/Form';

interface iTypeFittingProps extends Partial<FormikProps<any>> {
  field: any;
  index: number;
}

const { useState } = React;

const optionsTypeFitting = [
  { value: 'font-size', text: 'Font Size' },
  { value: 'tracking', text: 'Tracking' },
];

export const TypeFitting = connect(({ field, index }: iTypeFittingProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Form.Group>
        <Form.Field>
          <Button
            type="button"
            size="tiny"
            content="Add type fitting"
            onClick={() => setIsModalOpen(true)}
          />
        </Form.Field>
      </Form.Group>
      <Modal size="tiny" open={isModalOpen} onClose={() => setIsModalOpen(false)} closeIcon>
        <Modal.Header>Type Fitting</Modal.Header>
        <Modal.Content>
          <FieldArray
            name={`fields[${index}].typeFitting`}
            render={(arrayHelpers) => (
              <div className="ui form">
                {field?.typeFitting?.map((option: string, idx: number) => (
                  <Form.Group widths="equal" key={idx}>
                    <Form.Field>
                      <label>Action</label>
                      <SemanticField
                        name={`fields[${index}].typeFitting[${idx}].action`}
                        fluid
                        selection
                        component={Form.Dropdown}
                        options={optionsTypeFitting}
                      />
                    </Form.Field>
                    <Form.Field>
                      <label>Amount</label>
                      <SemanticField
                        name={`fields[${index}].typeFitting[${idx}].amount`}
                        fluid
                        component={Form.Input}
                      />
                    </Form.Field>

                    <div>
                      <div className="field">
                        <label>&nbsp;</label>
                        <Button
                          type="button"
                          icon="remove"
                          onClick={() => arrayHelpers.remove(idx)}
                        />
                      </div>
                    </div>
                  </Form.Group>
                ))}
                <Button
                  type="button"
                  size="tiny"
                  onClick={() => arrayHelpers.push({ action: '', amount: '' })}
                  content="Add Field"
                />
              </div>
            )}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button type="button" size="tiny" content="Done" onClick={() => setIsModalOpen(false)} />
        </Modal.Actions>
      </Modal>
    </>
  );
});
