import * as React from 'react';
import { Button, Form, Dropdown } from 'semantic-ui-react';
import { Formik, Form as FormikForm, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import countries from 'world-countries';
import { SemanticField } from '../../../common/Form';

import { countryStateProvinces } from '../../../common';

interface AddressBookFormProps {
  initialValues: any;
  onSubmit: Function;
  isSubmitting?: boolean;
}

const CustomerContactSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  address1: Yup.string().required('Required'),
  city: Yup.string().required('Required'),
  stateProvince: Yup.string().required('Required'),
  postalCode: Yup.string().required('Required'),
});

export const AddressBookForm: React.FC<AddressBookFormProps> = (props) => {
  const tenant = useSelector(
    ({ globals }: { globals: { tenant: { internationalization: { countryCode: string } } } }) =>
      globals.tenant.internationalization
  );

  return (
    <Formik
      initialValues={{ ...props.initialValues, countryCode: tenant.countryCode }}
      validationSchema={CustomerContactSchema}
      onSubmit={(values, { setSubmitting }) => {
        props.onSubmit(values);
      }}
    >
      {(innerProps) => {
        const {
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
          setFieldValue,
          setFieldTouched,
        } = innerProps;

        return (
          <FormikForm className={`ui form ${isSubmitting && 'loading'}`}>
            <Form.Group widths="equal">
              <Form.Field>
                <SemanticField name={'name'} label="Name" component={Form.Input} />
                <ErrorMessage component="div" name="name" />
              </Form.Field>
              <Form.Field>
                <SemanticField name={'careOf'} label="Care Of" component={Form.Input} />
                <ErrorMessage component="div" name="careOf" />
              </Form.Field>
            </Form.Group>

            <Form.Group widths="equal">
              <Form.Field>
                <SemanticField
                  name="countryCode"
                  label="Country"
                  selection
                  search
                  component={Form.Dropdown}
                  options={
                    countries?.map((c: any) => ({ text: c.name.official, value: c.cca2 })) ?? []
                  }
                  fluid
                />
              </Form.Field>
            </Form.Group>

            <Form.Group widths="equal">
              <Form.Field>
                <SemanticField name={'address1'} label="Address 1" component={Form.Input} />
                <ErrorMessage component="div" name="address1" />
              </Form.Field>
              <Form.Field>
                <SemanticField name={'address2'} label="Address 2" component={Form.Input} />
                <ErrorMessage component="div" name="address2" />
              </Form.Field>
            </Form.Group>

            <Form.Group widths="equal">
              <Form.Field>
                <SemanticField name={'city'} label="City" component={Form.Input} />
                <ErrorMessage component="div" name="city" />
              </Form.Field>

              <Form.Field>
                {values?.countryCode === 'US' ||
                values?.countryCode === 'CA' ||
                values?.countryCode === 'AU' ? (
                  <>
                    <label>
                      {values?.countryCode === 'AU' ? 'State/Territory' : 'State/Province'}
                    </label>
                    <Dropdown
                      name={'stateProvince'}
                      options={countryStateProvinces(values?.countryCode)}
                      fluid
                      selection
                      search
                      value={values?.stateProvince ?? ''}
                      onChange={(e, data) => setFieldValue(data.name, data.value)}
                    />
                  </>
                ) : (
                  <SemanticField
                    name={'stateProvince'}
                    component={Form.Input}
                    fluid
                    label="State/Province"
                    placeholder="State/Province..."
                  />
                )}

                <ErrorMessage component="div" name="stateProvince" />
              </Form.Field>
              <Form.Field>
                <SemanticField name={'postalCode'} label="Postal Code" component={Form.Input} />
                <ErrorMessage component="div" name="postalCode" />
              </Form.Field>
            </Form.Group>
            <Button primary type="submit" disabled={isSubmitting}>
              Save
            </Button>
          </FormikForm>
        );
      }}
    </Formik>
  );
};
