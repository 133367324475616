import * as React from 'react';
import { Modal, Form, Input, Button, Message } from 'semantic-ui-react';
import * as Yup from 'yup';
import { Formik, Form as FormikForm, Field, ErrorMessage } from 'formik';

import Feathers from '../../bootstrap/feathers';

import { SemanticField } from '../../common/Form';

interface CreditMemoModalProps {
  customer: string;
  open: boolean;
  onClose: () => void;
  onReload: () => void;
}

export const CreditMemoModal: React.FunctionComponent<CreditMemoModalProps> = (props) => {
  const { customer, open, onClose, onReload } = props;

  const CreditMemoSchema = Yup.object().shape({
    amount: Yup.number()
      .typeError('Amount must be a number')
      .positive('Amount must be greater than zero')
      .required('Amount is required'),
  });

  const handleCreditMemo = async (values: any) => {
    await Feathers.service('credit-memo').create(
      {
        customer,
        ...values,
        transactionType: 'CREDIT',
      },
      { query: {} }
    );

    onReload();
    onClose();
  };

  return (
    <Modal size="tiny" open={open} onClose={onClose} closeIcon>
      <Modal.Header>Credit Memo</Modal.Header>
      <Modal.Content>
        <Formik
          initialValues={{ amount: '' }}
          validationSchema={CreditMemoSchema}
          onSubmit={(values) => handleCreditMemo(values)}
        >
          {(props) => {
            const { values, errors, isSubmitting } = props;

            return (
              <FormikForm className={`ui form ${isSubmitting && 'loading'}`}>
                <Form.Field>
                  <SemanticField fluid component={Form.Input} name="amount" placeholder="Amount" />
                  <ErrorMessage name="amount" />
                </Form.Field>
                <Form.Field>
                  <SemanticField
                    name={'notes'}
                    component={Form.TextArea}
                    placeholder="Leave additional notes here..."
                  />
                </Form.Field>
                <Button type="submit" content="Submit" />
              </FormikForm>
            );
          }}
        </Formik>
      </Modal.Content>
    </Modal>
  );
};
