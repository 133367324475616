import * as React from 'react';
import {
  Box,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  StatGroup,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { FormattedNumber } from 'react-intl';

import { formatAsPercentage } from './helpers';

interface INonSplitProps {
  results: any;
}

const NonSplit: React.FunctionComponent<INonSplitProps> = (props) => {
  const { results } = props;
  if (!results) {
    return null;
  }

  const tenant = useSelector(
    ({
      globals,
    }: {
      globals: {
        tenant: { internationalization: { locale: string; currency: string; countryCode: string } };
      };
    }) => globals.tenant.internationalization
  );

  const { currency = 'USD' } = tenant;

  const { data } = results;
  const row = data[0];
  if (!row) {
    return null;
  }
  const { revenue, cogs, cogsRate, profit, profitMargin, profitMarkup } = row;

  return (
    <Box>
      <StatGroup>
        <Stat colorScheme={'blue'}>
          <StatLabel>Revenue</StatLabel>
          <StatNumber>
            <FormattedNumber currency={currency} value={Number(revenue || 0)} style="currency" />
          </StatNumber>
          <StatHelpText>
            <StatArrow type={Math.sign(23.36) > 0 ? 'increase' : 'decrease'} />
            {formatAsPercentage(-23.36)}
          </StatHelpText>
        </Stat>

        <Stat>
          <StatLabel>COGS</StatLabel>
          <StatNumber>
            <FormattedNumber currency={currency} value={Number(cogs || 0)} style="currency" />
          </StatNumber>
          <StatHelpText>
            <StatArrow type={Math.sign(cogsRate) > 0 ? 'increase' : 'decrease'} />

            {formatAsPercentage(cogsRate)}
          </StatHelpText>
        </Stat>

        <Stat>
          <StatLabel>Profit</StatLabel>
          <StatNumber>
            <FormattedNumber currency={currency} value={Number(profit || 0)} style="currency" />
          </StatNumber>
          <StatHelpText>
            <StatArrow type={Math.sign(-0.095) > 0 ? 'increase' : 'decrease'} />
            {formatAsPercentage(-0.095)}
          </StatHelpText>
        </Stat>
        <Stat>
          <StatLabel>Margin</StatLabel>
          <StatNumber>
            <FormattedNumber
              currency={currency}
              value={Number(profitMarkup || 0)}
              style="currency"
            />
          </StatNumber>
          <StatHelpText>
            <StatArrow type={Math.sign(profitMargin) > 0 ? 'increase' : 'decrease'} />
            {formatAsPercentage(profitMargin)}
          </StatHelpText>
        </Stat>
      </StatGroup>
    </Box>
  );
};

export default NonSplit;
