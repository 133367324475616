import React, { useState } from 'react';
import { ModelTypes } from '@inkcloud/icapi-types';

import { Modal } from 'semantic-ui-react';

type ExtractedContext = Exclude<ModelTypes.EmailParseLog['extractedContext'], undefined>[number];
type Html = Exclude<ModelTypes.EmailParseLog['html'], undefined>[number];

interface IExtractedModalProps {
  extractedContext: ExtractedContext;
  html: Html;
  onClose: () => void;
}

const iFrameStyle = {
  frameborder: '0',
  width: '100%',
  minHeight: 400,
  border: '1px solid #eaeaea',
};

export const ExtractedModal = (props: IExtractedModalProps) => {
  const { extractedContext = {}, html = '', onClose } = props;

  html?.replace(/[\r\n\t]+/gm, '');

  return (
    <Modal open onClose={onClose} closeIcon>
      <Modal.Header>
        <div>Email Parse Log</div>

        {/* <div style={{ maxHeight: "400px", overflow: "auto" }}>
          <pre style={{ fontSize: ".75em", fontWeight: "normal" }}>{JSON.stringify(extractedContext, null, 3)}</pre>
        </div> */}
      </Modal.Header>
      <Modal.Content>
        <iframe srcDoc={html} style={iFrameStyle}></iframe>
      </Modal.Content>
    </Modal>
  );
};
