import * as React from 'react';
import { Form, Input, TextArea, Dropdown, Button, Message } from 'semantic-ui-react';
// import provinces from 'provinces';
import countries from 'world-countries';

import { countryStateProvinces } from '../../../common';

const { useState, useEffect } = React;

interface ShipToProps {
  values: any;
  onClick: (v: any) => void;
  errorMessage: string;
}

export const ShipTo: React.FunctionComponent<ShipToProps> = (props) => {
  const { onClick, values, errorMessage } = props;

  const [shipTo, setShipTo] = useState(values);

  useEffect(() => {
    setShipTo(values);
  }, [values]);

  const handleChange = (v: any) => {
    setShipTo({ ...shipTo, ...v });
  };

  // let usStateProvinces = []
  // usStateProvinces = provinces.filter(p => p.country === 'US')
  //   .reduce((prev, next) => {
  //     prev = prev.concat([{ text: next.name, value: next.short }]);
  //     return prev;
  //   }, usStateProvinces);

  return (
    <>
      <Form>
        <Form.Field>
          <label>Name</label>
          <Input
            value={shipTo.name}
            onChange={(e, data) => handleChange({ ...shipTo, name: data.value })}
          />
        </Form.Field>
        <Form.Field>
          <label>Care Of</label>
          <Input
            value={shipTo.careOf}
            onChange={(e, data) => handleChange({ ...shipTo, careOf: data.value })}
          />
        </Form.Field>
        <Form.Field>
          <label>Country</label>
          <Dropdown
            selection
            search
            component={Form.Dropdown}
            value={shipTo.countryCode}
            onChange={(e, data) => handleChange({ ...shipTo, countryCode: data.value })}
            options={countries?.map((c: any) => ({ text: c.name.official, value: c.cca2 })) ?? []}
            fluid
          />
        </Form.Field>
        <Form.Field>
          <label>Address 1</label>
          <Input
            value={shipTo.address1}
            onChange={(e, data) => handleChange({ ...shipTo, address1: data.value })}
          />
        </Form.Field>
        <Form.Field>
          <label>Address 2</label>
          <Input
            value={shipTo.address2}
            onChange={(e, data) => handleChange({ ...shipTo, address2: data.value })}
          />
        </Form.Field>
        <Form.Field>
          <label>City</label>
          <Input
            value={shipTo.city}
            onChange={(e, data) => handleChange({ ...shipTo, city: data.value })}
          />
        </Form.Field>
        <Form.Field>
          {shipTo?.countryCode === 'US' ||
          shipTo?.countryCode === 'CA' ||
          shipTo?.countryCode === 'AU' ? (
            <>
              <label>{shipTo?.countryCode === 'AU' ? 'State/Territory' : 'State/Province'}</label>
              <Dropdown
                options={countryStateProvinces(shipTo?.countryCode)}
                fluid
                selection
                value={shipTo?.stateProvince ?? ''}
                onChange={(e, data) => handleChange({ ...shipTo, stateProvince: data.value })}
              />
            </>
          ) : (
            <>
              <label>State/Province</label>
              <Input
                value={shipTo.stateProvince}
                onChange={(e, data) => handleChange({ ...shipTo, stateProvince: data.value })}
              />
            </>
          )}
        </Form.Field>
        <Form.Field>
          <label>Postal Code</label>
          <Input
            value={shipTo.postalCode}
            onChange={(e, data) => handleChange({ ...shipTo, postalCode: data.value })}
          />
        </Form.Field>
        <Form.Field>
          <TextArea
            placeholder="Delivery Instruction"
            value={shipTo.deliveryInstructions}
            onChange={(e, data) => handleChange({ ...shipTo, deliveryInstructions: data.value })}
          />
        </Form.Field>
        <Button primary size={'small'} onClick={() => onClick(shipTo)} content={'Update'} />
      </Form>
      {errorMessage && <Message error content={errorMessage} />}
    </>
  );
};
