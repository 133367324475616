import * as React from 'react';
import { Button, Input, Dropdown, Segment, Form } from 'semantic-ui-react';
import { FeathersDropdown } from '@inkcloud/components';
import { Feathers } from '../../../bootstrap/feathers/feathers';

export interface FilterFormProps {
  value: any;
  onChange: Function;
  onReset: Function;
}

function initMapper(value) {
  const defaults = {
    entityAttribute: '',
    name: '',
  };

  return Object.keys(defaults).reduce((p, cur) => {
    if (!p[cur]) {
      p[cur] = defaults[cur];
    }
    return p;
  }, value);
}

const { useState, useEffect } = React;

export function FilterForm(props: FilterFormProps) {
  const { value: preInitValue } = props;

  const value = initMapper(preInitValue);

  const [attributes, setAttributes] = useState([]);

  const onChange = (field, value) => {
    props.onChange && props.onChange({ ...props.value, [field]: value });
  };

  useEffect(() => {
    const loadAttributes = async () => {
      try {
        const res: any = await Feathers.service('products-legacy/attributes').find({
          query: {
            $np: 1,
            $sort: { name: 1 },
            entityAttribute: value.entityAttribute,
          },
        });

        setAttributes(res?.map((r) => ({ key: r._id, value: r.name, text: r.name })));
      } catch (e) {}
    };

    if (value.entityAttribute) {
      loadAttributes();
    }
  }, [value.entityAttribute]);

  return (
    <Segment>
      <Form size={'small'}>
        <Form.Group widths={'equal'}>
          <Form.Field>
            <FeathersDropdown
              search
              selection
              clearable
              placeholder="Attribute Type.."
              serviceName="/products-legacy/attribute-types"
              query={{
                $np: 1,
                $sort: { name: 1 },
                isDeleted: { $ne: true },
                _id: { $ne: 'QUANTITY' },
              }}
              resultMapper={(r) => ({ key: r._id, value: r._id, text: r.name })}
              onChange={(e, data) => onChange('entityAttribute', data.value)}
            />
          </Form.Field>
          <Form.Field>
            <Dropdown
              search
              selection
              clearable
              placeholder="Name.."
              options={attributes}
              value={value.name}
              onChange={(e, data) => onChange('name', data.value)}
            />
          </Form.Field>
        </Form.Group>

        <Form.Field>
          <Button size={'tiny'} content="Reset Form" onClick={() => props.onReset()} />
        </Form.Field>
      </Form>
    </Segment>
  );
}
