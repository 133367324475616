import * as React from 'react';
import { ShippingAddress } from './ShippingAddressForm';

export interface ShippingAddressStaticProps {
  address: ShippingAddress;
}

export const ShippingAddressStatic = (props: ShippingAddressStaticProps) => {
  const { address } = props;
  return (
    <div>
      <div>{address.name}</div>
      <div>{address.careOf}</div>
      <div>{address.address1}</div>
      <div>{address.address2}</div>
      <div>
        {address.city} {address.stateProvince} {address.postalCode}
      </div>
    </div>
  );
};
