import * as React from 'react';
import { Modal, Button, Image } from 'semantic-ui-react';

interface IImageModalProps {
  isOpen: boolean;
  onClose: () => void;
  imgThumb: {
    url: string;
    name: string;
  };
}

export const ImageModal: React.FunctionComponent<IImageModalProps> = (props) => {
  const { isOpen, onClose, imgThumb } = props;

  return (
    <>
      <Modal size="large" open={isOpen} onClose={onClose} closeIcon>
        <Modal.Header>{imgThumb.name}</Modal.Header>
        <Modal.Content>
          <Image src={imgThumb.url} centered />
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={onClose} content="Close" />
        </Modal.Actions>
      </Modal>
    </>
  );
};
