import { SalesOrderDocument } from './SalesOrderDocument';
import { SalesOrderPage } from './SalesOrderPage';
import { BillTo } from './BillTo';
import { ShipTo } from './ShipTo';
import { Items } from './Items';
import { SalesOrderContext as Context } from './SalesOrderContext';
import PropertyList from '../shared/PropertyList';

export * from './SalesOrderDocument';
export * from './SalesOrderPage';
export * from './BillTo';
export * from './ShipTo';
export * from './Items';

export const Components = {
  SalesOrderDocument,
  SalesOrderPage,
  BillTo,
  ShipTo,
  Items,
  Context,
  PropertyList,
};
