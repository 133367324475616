import * as React from 'react';
import { Form, Button, Checkbox, Dropdown, Message } from 'semantic-ui-react';

import { To } from '@inkcloud/components';

import Feathers from '../../../bootstrap/feathers';

interface ShippingFormProps {
  id: string;
  shippingProps: {
    methods: any[];
    showShippingRates: boolean;
    shipFromControl: false;
    shipOutAddressInputMethod: string;
  };
}

const { useState, useEffect } = React;

const shipOutOptions = [
  { text: 'Open', value: 'open' },
  { text: 'Address Book', value: 'address-book' },
  { text: 'Read Only', value: 'read-only' },
];

export const ShippingForm: React.FunctionComponent<ShippingFormProps> = (props) => {
  const { id, shippingProps } = props;
  const [shipping, setShipping] = useState({
    showShippingRates: shippingProps.showShippingRates,
    shipFromControl: shippingProps.shipFromControl,
    shipOutAddressInputMethod: shippingProps.shipOutAddressInputMethod,
    methods: shippingProps.methods,
  });
  const [shippingMethods, setShippingMethods] = useState(shippingProps.methods || []);
  const [shippingOptions, setShippingOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const loadShippingMethods = async () => {
      setIsLoading(true);
      const result: any = await Feathers.service('tenant-settings/shipping-methods').find({
        query: {
          $np: 1,
          $sort: { name: 1 },
        },
      });
      setIsLoading(false);
      setShippingOptions(result);
    };
    loadShippingMethods();
  }, []);

  const handleChange = (data: any) =>
    setShipping({
      ...shipping,
      [data.name]: data.checked !== undefined ? data.checked : data.value,
    });

  const handleSubmit = async () => {
    setIsLoading(true);
    const [error, res] = await To(
      Feathers.service('tenant-settings/shipping').patch(id, shipping, { query: {} })
    );
    setIsLoading(false);

    error && setErrorMessage(error.message);
  };

  return (
    <Form loading={isLoading} onSubmit={handleSubmit}>
      {errorMessage && <Message error content={errorMessage} />}
      <Form.Field>
        <label>Ship Out Address</label>
        <Dropdown
          name={'shipOutAddressInputMethod'}
          options={shipOutOptions}
          selection
          value={shipping.shipOutAddressInputMethod}
          onChange={(e, data) => handleChange(data)}
        />
      </Form.Field>
      <Form.Field>
        <Checkbox
          name={'showShippingRates'}
          label={'Show Shipping Rates'}
          checked={shipping.showShippingRates}
          onChange={(e, data) => handleChange(data)}
        />
      </Form.Field>
      <Form.Field>
        <Checkbox
          name={'shipFromControl'}
          label={'Ship From Control'}
          checked={shipping.shipFromControl}
          onChange={(e, data) => handleChange(data)}
        />
      </Form.Field>
      <Form.Field>
        <label>Methods</label>
        <Dropdown
          fluid
          selection
          multiple
          name={'methods'}
          defaultValue={shipping.methods}
          options={
            shippingOptions.length > 0
              ? shippingOptions.map((s) => ({ text: s.text, value: s.key }))
              : []
          }
          onChange={(e, data) => handleChange(data)}
        />
      </Form.Field>
      <Button primary content="Save" />
    </Form>
  );
};
