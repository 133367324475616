// import * as React from 'react';
// import { Accordion, Button, Checkbox, Header, Icon, Grid, Label, Segment, Table } from 'semantic-ui-react';
// import { Link } from 'react-router-dom';
// import { FormattedDate, FormattedNumber, FormattedRelative } from 'react-intl';
// import { IndexWrapper, IndexWrappedProps } from './../../../bootstrap/IndexWrapper';
// import { ListViewPagination, ListFilterWarning } from './../../../common';
// import FilterForm from './../assign/FilterForm';

// const filterMapper = (filter) => {
//   return {
//     name: { $LIKE: filter && filter.name },
//   }
// }

// export function Container(props: IndexWrappedProps) {
//   if (!props.queryResult) {
//     return null;
//   }
//   const { data, skip, limit, total } = props.queryResult;
//   return (
//     <div>
//       <Header as='h1'>PM Approval</Header>
//       <Header attached={'top'} as='h5'>
//         Filter
//       </Header>
//       <Segment attached secondary>
//         <FilterForm
//           onSubmit={props.handleFilterSubmit}
//           handleClearFilter={props.handleClearFilter}
//           isFiltered={props.isFiltered}
//         />
//       </Segment>
//       <ListFilterWarning isFiltered={props.isFiltered} />
//       <Segment attached loading={props.isLoading}>
//         <ListViewPagination.Top
//           label={'New Production Batch'}
//           link={'/production/batches/add'}
//           hideAddButton={true}
//           {...props}
//         />
//         <Table size={'small'}>
//           <Table.Header>
//             <Table.Row>
//               <Table.HeaderCell>
//                 <Checkbox
//                   onChange={props.handleSelectAllClick}
//                 />
//               </Table.HeaderCell>
//               <Table.HeaderCell>ID</Table.HeaderCell>
//               <Table.HeaderCell>Customer</Table.HeaderCell>
//               <Table.HeaderCell>Category</Table.HeaderCell>
//               <Table.HeaderCell>Description</Table.HeaderCell>
//               <Table.HeaderCell>Status</Table.HeaderCell>
//               <Table.HeaderCell></Table.HeaderCell>

//             </Table.Row>
//           </Table.Header>
//           <Table.Body>
//             {data.map(i => {
//               if (!i.attributes) {
//                 return <div>N/A</div>
//               }
//               const sourceLang = i.metadata.find(a => a.key == 'tr_source_name');
//               const destinationLang = i.metadata.find(a => a.key == 'tr_dest_name');
//               const langMap = (
//                 <div>
//                   <Label size={'mini'} key={sourceLang?.key ?? ''}>{sourceLang?.value ?? ''}</Label>
//                   <Icon name={'long arrow alternate right'} />
//                   <Label size={'mini'} key={destinationLang?.key ?? ''}>{destinationLang.value ?? ''}</Label>
//                 </div>
//               );

//               return (
//                 <Table.Row key={i._id}>
//                   <Table.Cell>
//                     <Checkbox
//                       value={i._id}
//                       checked={props.selected[i._id] || false}
//                       onChange={props.handleSemanticCheckboxClick}
//                     />
//                   </Table.Cell>
//                   <Table.Cell>
//                     {`${i.humanId}`}
//                   </Table.Cell>
//                   <Table.Cell  >
//                     {i.customer.organizationName ? i.customer.organizationName : `${i.customer.primaryContact.firstName} ${i.customer.primaryContact.lastName}`}
//                   </Table.Cell>

//                   <Table.Cell>
//                     {i.category ? i.category.name : 'Custom'}
//                   </Table.Cell>
//                   <Table.Cell width={'6'}>
//                     {langMap}
//                   </Table.Cell>
//                   <Table.Cell>
//                     {i.productionStatus && <Label size={'mini'} content={i.productionStatus.description} />}
//                   </Table.Cell>
//                   <Table.Cell>
//                     <Link to={'/translation/assign/' + i._id}>
//                       <Button primary size={'mini'}>Review for Approval</Button>
//                     </Link>
//                   </Table.Cell>
//                 </Table.Row>
//               )
//             })}
//           </Table.Body>
//         </Table>
//         <ListViewPagination.Bottom
//           hideDeleteButton={true}
//           label={'Delete'}
//           {...props}
//         />
//       </Segment>
//     </div>
//   );
// }

// export default IndexWrapper(Container, 'orderItems', {
//   filterMapper,
//   query: {
//     $populate: ['customer', 'category', 'attributes.value'],
//     $sort: { createdAt: -1 },
//     'productionStatus.jobStatus': 'pending-pm-approval',
//   }
// });

import * as React from 'react';
import { Button, Segment, Popup, Label, Icon } from 'semantic-ui-react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { FormattedDate, FormattedTime } from 'react-intl';
import { getFilterDefinition } from './../assign/FilterForm2';

import {
  useList,
  ListPage,
  basicQueryMap,
  EmptyWrapper,
  PaginatorWrapper,
} from '@inkcloud/components';

const { useState } = React;

interface ListProps {}

const serviceName = 'order-items';
const pathPrefix = '/translation/pm-approval';

const filterQueryMap = (values) => {
  let filterRows = basicQueryMap(values);

  filterRows = {
    ...filterRows,
  };

  return filterRows;
};

export const List: React.FunctionComponent<ListProps & RouteComponentProps<any>> = (props) => {
  const {
    match: { params },
    location,
    history,
  } = props;

  // const { state, reload, handleDelete, selectedItems, clearAll, setChecked, someSelected, toggleAll, filter, setFilter, sort, handleSort } = useList({
  //   feathers: ['find', serviceName, [{
  //     query: {
  //       $populate: ['customer', 'category', 'attributes.value'],
  //       $sort: { createdAt: -1 },
  //       'productionStatus.jobStatus': 'pending-pm-approval',
  //     }
  //   }]],
  //   filterMap: filterQueryMap,
  //   debounce: 150,
  //   location,
  //   history,
  //   sort: { createdAt: -1 }
  // }) as any

  const useListValues = useList({
    feathers: [
      'find',
      serviceName,
      [
        {
          query: {
            $populate: ['customer', 'category', 'attributes.value'],
            'productionStatus.jobStatus': 'pending-pm-approval',
          },
        },
      ],
    ],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  const tableDefinition = [
    {
      key: 'createdAt',
      label: 'Date',
      sortable: true,
      cell: (v) =>
        v && (
          <Popup
            size="mini"
            content={<FormattedTime value={v} />}
            trigger={
              <div>
                <FormattedDate value={v} />
              </div>
            }
          />
        ),
    },
    {
      key: 'humanId',
      label: 'ID',
      sortable: true,
      cell: (v, r) => v,
    },
    {
      key: '',
      label: 'Customer',
      cell: (v, r) => (
        <>
          <Link to={`/customers/${r._id}`}>
            {r.customer?.organizationName ??
              `${r.customer?.primaryContact?.firstName ?? ''} ${
                r.customer?.primaryContact?.lastName ?? ''
              }`}
          </Link>
        </>
      ),
    },
    {
      key: 'category',
      label: 'Category',
      cell: (v, r) => (r.category ? r.category.name : 'Custom'),
    },
    {
      key: '',
      label: 'Description',
      cell: (v, r) => {
        const sourceLang = r?.metadata?.find((a) => a.key == 'tr_source_name');
        const destinationLang = r?.metadata?.find((a) => a.key == 'tr_dest_name');
        return (
          <div>
            <Label size={'mini'} key={sourceLang?.key ?? ''}>
              {sourceLang?.value ?? ''}
            </Label>
            <Icon name={'long arrow alternate right'} />
            <Label size={'mini'} key={destinationLang?.key ?? ''}>
              {destinationLang.value ?? ''}
            </Label>
          </div>
        );
      },
    },
    {
      key: '',
      label: 'Status',
      sortable: true,
      cell: (v, r) =>
        r.productionStatus && (
          <Label size={'mini'} content={r.productionStatus?.description ?? ''} />
        ),
    },
    {
      key: '',
      label: '',
      sortable: false,
      cell: (v, r) => (
        <Popup
          size="mini"
          content="Review for Approval"
          trigger={
            <Link to={'/translation/assign/' + r._id}>
              <Button size={'mini'} icon={'magnify'} />
            </Link>
          }
        />
      ),
      textAlign: 'right',
    },
  ];

  // const { queryResult, isLoading: isStateLoading } = state as any;

  // const resolvedQueryResult = queryResult?.data ? queryResult.data : queryResult;

  return (
    <ListPage
      header="PM Approval"
      subheader="List"
      useListValues={useListValues}
      history={history}
      pathPrefix={pathPrefix}
      tableDefintion={tableDefinition}
      withCheckboxes={true}
      headerActions={null}
      getFilterDefinition={getFilterDefinition}
    />
    // <div>
    //   <Segment>
    //     <EmptyWrapper
    //       queryResult={resolvedQueryResult}
    //       isStateLoading={isStateLoading}
    //     >
    //       <PaginatorWrapper.Top filter={filter} reload={reload} data={(queryResult as any)} pathPrefix={`${pathPrefix}`} />
    //       <ListTable
    //         data={resolvedQueryResult}
    //         definition={tableDefinition}
    //         selectedItems={selectedItems}
    //         setChecked={setChecked}
    //         sort={sort}
    //         sortClickFunction={handleSort}
    //         toggleAll={toggleAll}
    //         withCheckboxes={true}
    //       />

    //       <PaginatorWrapper.Bottom
    //         reload={reload}
    //         data={queryResult as any}
    //         pathPrefix={`${pathPrefix}`}
    //       >
    //         {someSelected && (
    //           <Button
    //             primary
    //             type="button"
    //             content="Delete"
    //             onClick={handleDelete}
    //           />
    //         )}
    //       </PaginatorWrapper.Bottom>

    //     </EmptyWrapper>
    //   </Segment>
    // </div>
  );
};
