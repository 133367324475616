import React, { useEffect, useState, useImperativeHandle, useRef, forwardRef } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Text,
  Alert,
  Box,
  Input,
  Stack,
  Button,
} from '@chakra-ui/react';

import DatePicker from 'react-datepicker';
import { Select } from 'chakra-react-select';

import type { ModelTypes } from '@inkcloud/icapi-types';

import type { BasicInfoType } from '../types';
import { Feathers } from '../../../../bootstrap/feathers/feathers';

import RequestorModal from './RequestorModal';

export const statusOptions = [
  { label: 'Draft', value: 'draft' },
  { label: 'Open', value: 'open' },
  { label: 'Estimate Sent', value: 'estimate-sent' },
];

export const paperSuppliedByOptions = [
  { label: 'Vendor', value: 'vendor' },
  { label: 'Self', value: 'self' },
  { label: 'Not Aapplicable', value: 'not-applicable' },
];

export const filesSuppliedOptions = [
  { label: 'Press Ready PDF', value: 'press-ready-pdf' },
  { label: 'Native Files', value: 'native-files' },
  { label: 'Exact Repeat', value: 'exact-repeat' },
];

export const booleanOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

export const optionsFinder = (
  options: { label: string; value: string | number | boolean }[],
  value:
    | string
    | number
    | boolean
    | ModelTypes.Rfq['paperSuppliedBy']
    | ModelTypes.Rfq['paperSuppliedBy']
) => options.find((o) => o.value === value);

type optionsType = { label: string; value: string }[];

const schema = Yup.object()
  .shape({
    customer: Yup.string().required('Client is required'),
    // allowableOverrunPercentage: Yup.number().typeError('Allowable Overrun %: A number is required'),
    // allowableUnderrunPercentage: Yup.number().typeError(
    //   'Allowable Underrun %: A number is required'
    // ), DISABLED FOR NOW
  })
  .required();

interface IEditableInfoProps {
  basicInfo: BasicInfoType;
  usersOptions: { label: string; value: string }[];
  onChange: (v: BasicInfoType) => void;
  onNext?: (values: BasicInfoType) => void;
  isSubmitting?: boolean;
  onChangeUserOptions: (v: { label: string; value: string }) => void;
}

const EditableInfo = forwardRef((props: IEditableInfoProps, ref) => {
  const { basicInfo, onNext, onChangeUserOptions, usersOptions, onChange, isSubmitting } = props;

  const {
    register,
    watch,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm<BasicInfoType>({
    defaultValues: basicInfo,
    resolver: yupResolver(schema),
  });

  const estimateDue = new Date();
  estimateDue.setHours(15);
  estimateDue.setMinutes(0);

  // eslint-disable-next-line no-undef
  const submitRef = useRef<HTMLFormElement>(null);

  const [customers, setCustomers] = useState<optionsType>([]);

  const [isModalOpen, setIsModalOpen] = useState({
    requestor: false,
  });

  useImperativeHandle(ref, () => ({
    handleNext: () => submitRef?.current?.requestSubmit(),
    handleSave: () => {
      // console.log('getValues()', getValues())
      submitRef?.current?.requestSubmit();
      const values = getValues();

      if (!values.customer) {
        return false;
      }

      const newValues = {
        ...(values || {}),

        csr: values.csr || null,
        customer: values.customer || null,
        requestor: values.requestor || null,
        assignedTo: values.assignedTo || null,
        estimateDueDate: values.estimateDueDate || estimateDue,
        paperSuppliedBy: values.paperSuppliedBy || paperSuppliedByOptions[0]?.value,
        filesSupplied: values.filesSupplied || filesSuppliedOptions[0]?.value,
        hardProof: values?.hardProof ?? booleanOptions[0]?.value,
        eProof: values?.eProof ?? booleanOptions[0]?.value,
      };

      return newValues;
    },
  }));

  const loadCustomers = async () => {
    try {
      const res = await Feathers.service('customers').find({
        query: {
          $np: 1,
          $sort: { organizationName: 1, billingClientName: 1, 'primaryContact.firstName': 1 },
        },
      });
      setCustomers(
        res
          .filter((c) => c?.organizationName)
          .map((c) => {
            let label = `${c?.organizationName ?? ''}`;
            if (c.billingClientName) {
              label = `${label} - ${c.billingClientName ?? ''}`;
              if (c.billingClientId) {
                label = `${label} (${c.billingClientId ?? ''})`;
              }
            } else if (c?.primaryContact?.firstName && c?.primaryContact?.firstName !== '_') {
              label = `${label} - ${c?.primaryContact?.firstName ?? ''} ${
                c?.primaryContact?.lastName ?? ''
              }`;
            }

            return {
              organizationName: c?.organizationName,
              primaryContact: c?.primaryContact,
              _id: c?._id,
              label,
              value: c._id,
            };
          })
      );
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    loadCustomers();
  }, []);
  const watchCustomer = watch('customer');

  const handleSaveForm: SubmitHandler<BasicInfoType> = (values: BasicInfoType) => {
    const newValues = {
      ...values,
      csr: values.csr || null,



      // EMERGENCY FIX. TRYING TO FIX RFQ
      // customer: values.customer || null,
      customer: watchCustomer,

      requestor: values.requestor || null,
      assignedTo: values.assignedTo || null,
      estimateDueDate: values.estimateDueDate || estimateDue,
      paperSuppliedBy: values.paperSuppliedBy || paperSuppliedByOptions[0]?.value,
      filesSupplied: values.filesSupplied || filesSuppliedOptions[0]?.value,
      hardProof: values?.hardProof ?? booleanOptions[0]?.value,
      eProof: values?.eProof ?? booleanOptions[0]?.value,
    };

    onChange(newValues as BasicInfoType);
    onNext?.(newValues as BasicInfoType);
  };

  const watchEstimateDueDate = watch('estimateDueDate');
  const watchReqCustDate = watch('requestedCustomerShipDate');
  const watchRequestor = watch('requestor');

  const errMsg = Object.keys(errors)?.length ? (
    <Alert borderRadius={2} status="error" mb={3} alignItems="flex-start" flexDirection="column">
      {Object.keys(errors).map((e, index) => (
        <Box key={index}>{<Text>{errors[e]?.message}</Text>}</Box>
      ))}
    </Alert>
  ) : null;

  return (
    <>
      <form ref={submitRef} onSubmit={handleSubmit(handleSaveForm)}>
        <Stack spacing={7} mb={3} direction="row">
          <FormControl>
            <FormLabel> Project Name</FormLabel>
            <Input type="text" {...register('projectName')} />
          </FormControl>
          <FormControl>
            <FormLabel>Client</FormLabel>
            <Select
              {...register('customer')}
              onChange={(v) => setValue('customer', v as any)}
              isClearable
              useBasicStyles
              options={customers}
              value={customers?.find((o) => o.value === watchCustomer?._id)}
            />
          </FormControl>
        </Stack>
        <Stack spacing={7} mb={3} direction="row">
          <FormControl>
            <FormLabel>Assigned To</FormLabel>
            <Select
              onChange={(v) => setValue('assignedTo', v?.value as string)}
              isClearable
              useBasicStyles
              options={usersOptions}
              defaultValue={usersOptions?.find(
                (o) => o.value === ((basicInfo.assignedTo as any)?._id || basicInfo.assignedTo)
              )}
            />
          </FormControl>
          <FormControl>
            <FormLabel>
              Requestor{' '}
              <Button
                onClick={() => setIsModalOpen({ ...isModalOpen, requestor: true })}
                variant="link"
                colorScheme="blue"
              >
                Add New
              </Button>
            </FormLabel>
            <Select
              onChange={(v) => setValue('requestor', v?.value)}
              isClearable
              useBasicStyles
              options={usersOptions}
              value={usersOptions?.find((o) => {
                const value =
                  typeof watchRequestor === 'string' ? watchRequestor : watchRequestor?._id;

                return o.value === value;
              })}
            />
          </FormControl>
        </Stack>
        <Stack spacing={7} mb={3} direction="row">
          <FormControl>
            <FormLabel>Estimate Due Date</FormLabel>
            <DatePicker
              selected={watchEstimateDueDate ? new Date(watchEstimateDueDate) : estimateDue}
              onChange={(v) => setValue('estimateDueDate', v ? String(v) : (null as any))}
              dateFormat="MM/dd/yyyy h:mm a"
              showTimeSelect
              timeFormat="h:mm a"
              className="formMinHeight"
            />
          </FormControl>
          <FormControl>
            <FormLabel>Delivery Date</FormLabel>
            <DatePicker
              selected={watchReqCustDate ? new Date(watchReqCustDate) : null}
              onChange={(v) => setValue('requestedCustomerShipDate', v ? String(v) : (null as any))}
              dateFormat="MM/dd/yyyy"
              className="formMinHeight"
            />
          </FormControl>
        </Stack>
        <Stack spacing={7} mb={3} direction="row">
          <FormControl>
            <FormLabel> Paper Supplied By</FormLabel>
            <Select
              useBasicStyles
              // {...register("paperSuppliedBy")}
              onChange={(v) =>
                setValue('paperSuppliedBy', v?.value as ModelTypes.Rfq['paperSuppliedBy'])
              }
              options={paperSuppliedByOptions}
              defaultValue={
                optionsFinder(paperSuppliedByOptions, basicInfo.paperSuppliedBy) ??
                paperSuppliedByOptions[0]
              }
            />
          </FormControl>
          <FormControl>
            <FormLabel>Files Supplied</FormLabel>
            <Select
              useBasicStyles
              // {...register("filesSupplied")}
              onChange={(v) =>
                setValue('filesSupplied', v?.value as ModelTypes.Rfq['filesSupplied'])
              }
              options={filesSuppliedOptions}
              defaultValue={
                optionsFinder(filesSuppliedOptions, basicInfo.filesSupplied) ??
                filesSuppliedOptions[0]
              }
            />
          </FormControl>
        </Stack>
        <Stack spacing={7} mb={3} direction="row">
          <FormControl>
            <FormLabel>Turnaround Time Description</FormLabel>
            <Input
              {...register('turnaroundDescription')}
              onChange={(e) => setValue('turnaroundDescription', e.target.value)}
            />
          </FormControl>
        </Stack>
        {/* <Stack spacing={7} mb={3} direction="row">
          <FormControl>
            <FormLabel> Hard Proof</FormLabel>
            <Select
              // {...register("hardProof")}
              // onChange={(v) => setValue('hardProof', (v?.value as ModelTypes.Rfq['hardProof']))}
              onChange={(v) => setValue('hardProof', Boolean(v?.value))}
              options={booleanOptions}
              defaultValue={optionsFinder(booleanOptions, basicInfo.hardProof) ?? booleanOptions[0]}
            />
          </FormControl>
          <FormControl>
            <FormLabel>E Proof</FormLabel>
            <Select
              // {...register("eProof")}
              // onChange={(v) => setValue('eProof', Boolean(v?.value)(v?.value as ModelTypes.Rfq['eProof']))}
              onChange={(v) => setValue('eProof', Boolean(v?.value))}
              options={booleanOptions}
              defaultValue={optionsFinder(booleanOptions, basicInfo.eProof) ?? booleanOptions[0]}
            />
          </FormControl>
        </Stack> */}
        {/*

        HIDE FOR NOW

        <Stack spacing={7} mb={3} direction="row">
          <FormControl isInvalid={!!errors.allowableOverrunPercentage}>
            <FormLabel> Allowable Overrun %</FormLabel>
            <Input type="text" {...register('allowableOverrunPercentage')} />
            {errors.allowableOverrunPercentage && (
              <FormErrorMessage>{errors.allowableOverrunPercentage.message}</FormErrorMessage>
            )}
          </FormControl>
          <FormControl isInvalid={!!errors.allowableUnderrunPercentage}>
            <FormLabel>Allowable Underrun %</FormLabel>
            <Input type="text" {...register('allowableUnderrunPercentage')} />
            {errors.allowableUnderrunPercentage && (
              <FormErrorMessage>{errors.allowableUnderrunPercentage.message}</FormErrorMessage>
            )}
          </FormControl>
        </Stack>

        */}
      </form>

      {errMsg}
      {/* <Button
        onClick={handleSubmit(handleSave)}
        isLoading={isSubmitting}
        isDisabled={isSubmitting}
      >Save</Button> */}

      {isModalOpen.requestor && (
        <RequestorModal
          onChangeRequestor={(v) => setValue('requestor', v)}
          onChangeUserOptions={onChangeUserOptions}
          onClose={() => setIsModalOpen({ ...isModalOpen, requestor: false })}
        />
      )}
    </>
  );
});

export default EditableInfo;
