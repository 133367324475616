// import * as React from 'react';
// import { Button, Checkbox, Header, Segment, Table, Label, Dropdown, Popup } from 'semantic-ui-react';
// import { Link, RouteComponentProps } from 'react-router-dom';
// import { FormattedDate } from 'react-intl';
// import { useList, PageHeaderRow, PreLoader, PaginatorWrapper } from '@inkcloud/components';
// import { FilterForm } from './FilterForm';

// interface ListProps { }

// const serviceName = 'microsites';

// const filterQueryMap = (values) => {
//   const filterMap: any = {
//     name: values.name ? { $LIKE: values.name } : undefined,
//     key: values.key ? { $LIKE: values.key } : undefined,
//     domain: values.domain ? { $LIKE: values.domain } : undefined,
//   };

//   return [].reduce((m, field) => ({ ...m, [field]: values[field] ? values[field] : undefined }), filterMap);
// };

// export const List: React.FunctionComponent<ListProps & RouteComponentProps> = (props) => {
//   const { match: { params }, location, history, } = props;
//   const { state, reload, handleDelete, selectedItems, clearAll, setChecked, someSelected, toggleAll, filter, setFilter, sort, handleSort } = useList({
//     feathers: ['find', serviceName, [{ query: { $select: ['createdAt', 'name', 'key', 'domain', 'customer'] } }]],
//     filterMap: filterQueryMap,
//     debounce: 150,
//     location,
//     history,
//     sort: { name: 1 }
//   })

//   const { queryResult, isLoading: isStateLoading } = state as any;

//   if (!queryResult) {
//     return null;
//   }

//   const pathPrefix = `/settings/microsites`;

//   return (
//     <div>
//       <PageHeaderRow header='Microsites' subheader=''>
//         <Link to={`${pathPrefix}/add`}><Button content="New" primary size={'mini'} /></Link>
//       </PageHeaderRow>

//       <FilterForm value={filter} onChange={(v) => setFilter(v)} onReset={() => setFilter({})} />

//       <Segment loading={state.isLoading}>
//         <PaginatorWrapper.Top filter={filter} reload={reload} data={(queryResult as any)} pathPrefix={`${pathPrefix}`} />
//         <Table size={'small'} sortable>
//           <Table.Header>
//             <Table.Row>
//               <Table.HeaderCell><input type="checkbox" onChange={toggleAll} /></Table.HeaderCell>
//               <Table.HeaderCell sorted={sort.createdAt ? sort.createdAt : null} onClick={() => handleSort('createdAt')}>
//                 Created
//               </Table.HeaderCell>
//               <Table.HeaderCell sorted={sort.name ? sort.name : null} onClick={() => handleSort('name')}>
//                 Name
//               </Table.HeaderCell>
//               <Table.HeaderCell sorted={sort.key ? sort.key : null} onClick={() => handleSort('key')}>
//                 Key
//               </Table.HeaderCell>
//               <Table.HeaderCell sorted={sort.domain ? sort.domain : null} onClick={() => handleSort('domain')}>
//                 Domain
//               </Table.HeaderCell>
//               <Table.HeaderCell></Table.HeaderCell>
//             </Table.Row>
//           </Table.Header>
//           <Table.Body>
//             {queryResult && (queryResult as any).data.map(r => (
//               <Table.Row key={r._id}>
//                 <Table.Cell>
//                   <input type="checkbox" value={r._id} checked={selectedItems[r._id] || false} onChange={setChecked} />
//                 </Table.Cell>
//                 <Table.Cell>
//                   <FormattedDate value={r.createdAt} />
//                 </Table.Cell>
//                 <Table.Cell>
//                   {r.name}
//                 </Table.Cell>

//                 <Table.Cell>
//                   <Label size="tiny" basic >{r.key}</Label>
//                 </Table.Cell>
//                 <Table.Cell>
//                   <Label size="tiny" >{r.domain}</Label>
//                 </Table.Cell>
//                 <Table.Cell textAlign="right">

//                   <Popup
//                     size='mini'
//                     content="Customers"
//                     trigger={
//                       <Link to={`/settings/microsites/${r._id}/customers`}>
//                         <Button size={'mini'} icon={'user'} />
//                       </Link>
//                     }
//                   />
//                   <Popup
//                     size='mini'
//                     content="Groups"
//                     trigger={
//                       <Link to={`/settings/microsites/${r._id}/groups`}>
//                         <Button size={'mini'} icon={'users'} />
//                       </Link>
//                     }
//                   />
//                   <Popup
//                     size='mini'
//                     content="Content Pages"
//                     trigger={
//                       <Link to={`/settings/microsites/${r._id}/content/pages`}>
//                         <Button size={'mini'} icon={'content'} />
//                       </Link>
//                     }
//                   />

//                   <Popup
//                     size='mini'
//                     content="Edit"
//                     trigger={
//                       <Link to={`/settings/microsites/${r._id}`}>
//                         <Button size={'mini'} icon={'edit'} />
//                       </Link>
//                     }
//                   />

//                   <Popup
//                     size='mini'
//                     content="More Actions..."
//                     trigger={
//                       <Dropdown className="icon mini" scrolling position='left' direction='left' button icon='ellipsis vertical'>
//                         <Dropdown.Menu className='ui mini' >
//                           <Dropdown.Item icon='zoom-in' content={'Details'} as={Link} to={`/settings/microsites/${r._id}`} />
//                           <Dropdown.Item icon='edit' content={'Edit'} as={Link} to={`/settings/microsites/${r._id}/edit`} />
//                           <Dropdown.Divider />

//                           <Dropdown.Item icon='user' content={'Customers'} as={Link} to={`/settings/microsites/${r._id}/customers`} />
//                           <Dropdown.Item icon='users' content={'Groups'} as={Link} to={`/settings/microsites/${r._id}/groups`} />
//                           <Dropdown.Item icon='address book' content={'Address Book'} as={Link} to={`/settings/microsites/${r._id}/address-book`} />
//                           <Dropdown.Divider />

//                             <Dropdown.Item icon='content' content={'Content Banners'} as={Link} to={`/settings/microsites/${r._id}/content/banners`} />
//                             <Dropdown.Item icon='content' content={'Content Images'} as={Link} to={`/settings/microsites/${r._id}/content/images`} />
//                             <Dropdown.Item icon='content' content={'Content Live Edit'} as={Link} to={`/settings/microsites/${r._id}/content/edit`} />
//                             <Dropdown.Item icon='content' content={'Content Menus'} as={Link} to={`/settings/microsites/${r._id}/content/menus`} />
//                             <Dropdown.Item icon='content' content={'Content Pages'} as={Link} to={`/settings/microsites/${r._id}/content/pages`} />

//                           <Dropdown.Divider />

//                           <Dropdown.Item icon='bookmark' content={'Admin Dashboard Menus'} as={Link} to={`/settings/microsites/${r._id}/menus`} />
//                           <Dropdown.Item icon='mail' content={'Approval Email'} as={Link} to={`/settings/microsites/${r._id}/approval-email`} />
//                           <Dropdown.Item icon='mail square' content={'Contact Form Submissions'} as={Link} to={`/settings/microsites/${r._id}/contact-form-submissions`} />
//                           <Dropdown.Item icon='mail' content={'Notifications'} as={Link} to={`/settings/microsites/${r._id}/notifications`} />
//                           <Dropdown.Item icon='map marker alternate' content={'Pickup Locations'} as={Link} to={`/settings/microsites/${r._id}/pickup-locations`} />
//                           <Dropdown.Item icon='warehouse' content={'Products'} as={Link} to={`/settings/microsites/${r._id}/products`} />
//                           <Dropdown.Item icon='mail' content={'Product Notification'} as={Link} to={`/settings/microsites/${r._id}/product-notification`} />

//                         </Dropdown.Menu>
//                       </Dropdown>
//                     }
//                   />

//                 </Table.Cell>
//               </Table.Row>
//             ))}
//           </Table.Body>
//         </Table>
//         <PaginatorWrapper.Bottom reload={reload} data={(queryResult as any)} pathPrefix={`${pathPrefix}`}>
//           {someSelected && <Button primary type="button" content="Delete" onClick={handleDelete} />}
//         </PaginatorWrapper.Bottom>
//       </Segment>
//     </div>
//   );
// }

import * as React from 'react';
import {
  Button,
  Box,
  ButtonGroup,
  IconButton,
  Tooltip,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
} from '@chakra-ui/react';

import { Link, RouteComponentProps } from 'react-router-dom';
import { FormattedDate, FormattedTime } from 'react-intl';

import {
  FaEdit,
  FaSquare,
  FaEllipsisV,
  FaUserAlt,
  FaUsers,
  FaBook,
  FaEnvelope,
  FaRegEnvelope,
  FaStickyNote,
  FaBell,
  FaMapMarkerAlt,
  FaBox,
  FaEnvelopeOpenText,
} from 'react-icons/fa';

import { useList, ListPage, basicQueryMap } from '@inkcloud/components';
import getFilterDefinition from './FilterForm';

interface ListProps {}

const serviceName = 'microsites';

const filterQueryMap = (values) => basicQueryMap(values, {});

const pathPrefix = `/settings/microsites`;

export default function List(props: ListProps & RouteComponentProps) {
  const {
    match: { params },
    location,
    history,
  } = props;

  const useListValues = useList({
    feathers: [
      'find',
      serviceName,
      [{ query: { $select: ['createdAt', 'name', 'key', 'domain', 'customer'] } }],
    ],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  const tableDefintion = [
    {
      key: 'createdAt',
      label: 'Date',
      sortable: true,
      cell: (v) => (
        <Tooltip hasArrow aria-label="time" label={<FormattedTime value={v} />}>
          <Box>
            <FormattedDate value={v} />
          </Box>
        </Tooltip>
      ),
    },
    {
      key: 'name',
      label: 'Name',
      cell: (v) => v,
    },
    {
      key: 'key',
      label: 'Key',
      cell: (v) => v,
    },
    {
      key: 'domain',
      label: 'Domain',
      cell: (v, r) => v,
    },

    {
      key: '',
      label: '',
      cell: (v, r) => (
        <ButtonGroup spacing={1}>
          <Tooltip label="Edit Details">
            <Link to={`${pathPrefix}/${r._id}/edit`}>
              <IconButton colorScheme="gray" aria-label="Edit" icon={<FaEdit />} />
            </Link>
          </Tooltip>
          <Tooltip label="View Details">
            <Link to={`${pathPrefix}/${r._id}`}>
              <IconButton colorScheme="gray" aria-label="Edit" icon={<FaStickyNote />} />
            </Link>
          </Tooltip>
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label="Options"
              icon={<FaEllipsisV />}
              variant="outline"
            />
            <MenuList>
              <MenuItem
                as={Link}
                to={`/settings/microsites/${r._id}/customers`}
                icon={<FaUserAlt />}
              >
                Customers
              </MenuItem>
              <MenuItem as={Link} to={`/settings/microsites/${r._id}/groups`} icon={<FaUsers />}>
                Groups
              </MenuItem>
              <MenuItem
                as={Link}
                to={`/settings/microsites/${r._id}/allotments`}
                icon={<FaUsers />}
              >
                Allotments
              </MenuItem>
              <MenuItem
                as={Link}
                to={`/settings/microsites/${r._id}/address-book`}
                icon={<FaBook />}
              >
                Address Book
              </MenuItem>
              <MenuItem
                as={Link}
                to={`/settings/microsites/${r._id}/approval-email`}
                icon={<FaEnvelope />}
              >
                Approval Email
              </MenuItem>
              <MenuItem
                as={Link}
                to={`/settings/microsites/${r._id}/contact-form-submissions`}
                icon={<FaRegEnvelope />}
              >
                Contact Form Submissions
              </MenuItem>
              <MenuItem
                as={Link}
                to={`/settings/microsites/${r._id}/notifications`}
                icon={<FaBell />}
              >
                Notifications
              </MenuItem>
              <MenuItem
                as={Link}
                to={`/settings/microsites/${r._id}/pickup-locations`}
                icon={<FaMapMarkerAlt />}
              >
                Pickup Locations
              </MenuItem>
              <MenuItem as={Link} to={`/settings/microsites/${r._id}/products`} icon={<FaBox />}>
                Products
              </MenuItem>
              <MenuItem
                as={Link}
                to={`/settings/microsites/${r._id}/product-notification`}
                icon={<FaEnvelopeOpenText />}
              >
                Product Notification
              </MenuItem>
              <MenuDivider />
              <MenuItem
                as={Link}
                to={`/settings/microsites/${r._id}/content/banners`}
                icon={<FaSquare />}
              >
                Content Banners
              </MenuItem>
              <MenuItem
                as={Link}
                to={`/settings/microsites/${r._id}/content/Images`}
                icon={<FaSquare />}
              >
                Content Images
              </MenuItem>
              <MenuItem
                as={Link}
                to={`/settings/microsites/${r._id}/content/edit`}
                icon={<FaSquare />}
              >
                Content Live Edit
              </MenuItem>
              <MenuItem
                as={Link}
                to={`/settings/microsites/${r._id}/content/menus`}
                icon={<FaSquare />}
              >
                Content Menus
              </MenuItem>
              <MenuItem
                as={Link}
                to={`/settings/microsites/${r._id}/content/Pages`}
                icon={<FaSquare />}
              >
                Content Pages
              </MenuItem>
            </MenuList>
          </Menu>
        </ButtonGroup>
      ),
      textAlign: 'right',
    },
  ];

  const headerActions = (
    <>
      <Link to={`${pathPrefix}/add`}>
        <Button colorScheme="blue">New</Button>
      </Link>
    </>
  );

  return (
    <Box>
      <ListPage
        header="Microsites"
        subheader=""
        useListValues={useListValues}
        history={history}
        pathPrefix={pathPrefix}
        headerActions={headerActions}
        tableDefintion={tableDefintion}
        withCheckboxes={true}
        getFilterDefinition={getFilterDefinition}
      />
    </Box>
  );
}
