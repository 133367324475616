import * as React from 'react';
import { TextProps, chakra } from '@chakra-ui/react';

interface IPropertyListProps {
  children: React.ReactNode;
  fontSize?: TextProps['fontSize'];
}

const defaultValue = {
  fontSize: 'sm' as TextProps['fontSize'],
}

export const Context = React.createContext(defaultValue)

const PropertyList: React.FunctionComponent<IPropertyListProps> = (props) => {
  const { children, fontSize = 'sm' } = props;

  const initialValue = {
    ...defaultValue,
    fontSize,
  };

  return (
    <Context.Provider value={initialValue}>
      <chakra.dl>
        {children}
      </chakra.dl>
    </Context.Provider>
  );
};

export default PropertyList;
