import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Segment, Table, Dropdown } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { PageHeaderRow, PreLoader, useFeathers } from '@inkcloud/components';
import feathers, { icapi } from '../../bootstrap/feathers';
import RulesEngineRule from './RulesEngineRule';

export interface ConnectedProps {
  user?: any;
}

interface IDetailProps {}

const serviceName = `/rules-engine/rules`;
const pathPrefix = `/rules-engine`;
const service = feathers.service(serviceName);
const commonParams = {
  query: {
    // $populate: [
    //   'customer',
    //   'micrositeUser',
    //   'items.vendor',
    //   'privateNotes.createdBy',
    // ]
  },
};

const PreDetail: React.FunctionComponent<
  IDetailProps & ConnectedProps & RouteComponentProps<{ id?: string }>
> = (props) => {
  const {
    match: {
      params: { id },
    },
    history,
    user,
  } = props;

  const isEdit = !!id;
  const [state, reload] = useFeathers('get', serviceName, [id, commonParams], true);
  const [errorMessage, setErrorMessage] = React.useState('');

  const [ruleDefinitions, setRuleDefinitions] = React.useState(null);
  const [selectedEngineType, setSelectedEngineType] = React.useState(null);

  React.useEffect(() => {
    const loadDefinitions = async () => {
      const definitions = await icapi.service('rules-engine/definitions').find();
      setRuleDefinitions(definitions);
    };

    loadDefinitions();
  }, []);

  const definitionList = ruleDefinitions?.map((rd) => ({ text: rd.key, value: rd.key })) ?? [];

  const selectedEngineDefinition = ruleDefinitions?.find((rd) => rd.key === selectedEngineType);

  const handleSubmit = async (values) => {
    setErrorMessage('');
    try {
      if (isEdit) {
        await service.patch(id, values, commonParams);
      } else {
        await service.create(values, commonParams);
      }
      history.push(pathPrefix);
    } catch (e) {
      const errMsg =
        e?.code < 500 ? e.message : 'We are experiencing technical difficulties. Please try again';
      setErrorMessage(errMsg);
    }
  };

  const pageTitle = isEdit ? 'Edit' : 'New';

  if (isEdit && !state.data) {
    return <PreLoader />;
  }

  const initialData = { ...state.data };
  return (
    <div>
      <PageHeaderRow header={`${pageTitle} Rules Engine`} subheader="Rule" subheaderId={''}>
        <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
      </PageHeaderRow>

      <Segment>
        <label>1. Select a an engine: </label>
        <Dropdown
          options={definitionList}
          onChange={(e, data) => setSelectedEngineType(data.value)}
        />
      </Segment>

      <RulesEngineRule
        definition={selectedEngineDefinition as any}
        value={{}}
        onChange={(v) => {}}
      />
    </div>
  );
};

const mapStateToProps = (state): ConnectedProps => ({
  user: state.auth.user || {},
});

export const Detail = connect(mapStateToProps)(PreDetail as any);
