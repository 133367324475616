import * as React from 'react';
import { Button, Segment, Message, Loader, Dimmer, Modal } from 'semantic-ui-react';
import Feathers from './../bootstrap/feathers';

export interface InjectedProps {
  printHandleOpen: any;
}

export interface ExternalProps {}

export interface PrintModalState {
  isModalOpen: boolean;
  payload: any;
  isLoading: boolean;
  downloadUrl: string;
  message: string;
}

export enum PrintTypes {
  JobTicketSingle,
  JobTicketFull,
  OrderReceipt,
  PickList,
  PackingSlip,
  Invoice,
  OrderItemLabel,
  PurchaseOrder,
  SalesOrder,
  Estimate,
}
export const printModalWrapper = function printModalWrapper<OriginalProps>(Component) {
  return class PrintModal extends React.Component<ExternalProps & OriginalProps, PrintModalState> {
    constructor(props: OriginalProps) {
      super(props);

      this.state = {
        isModalOpen: false,
        payload: null,
        isLoading: false,
        downloadUrl: null,
        message: '',
      };
    }

    printHandleOpen = (type, payload) => {
      this.setState({ ...this.state, isModalOpen: true, isLoading: true, payload });

      let requestPromise = null;
      if (type === PrintTypes.JobTicketFull) {
        requestPromise = Feathers.service('/printable/job-ticket').create({
          orderId: payload.orderId,
        });
      } else if (type === PrintTypes.JobTicketSingle) {
        requestPromise = Feathers.service('/printable/job-ticket').create({
          orderId: payload.orderId,
          orderItemId: payload.orderItemId,
        });
      } else if (type === PrintTypes.OrderItemLabel) {
        requestPromise = Feathers.service('/printable/order-item-label').create({
          orderId: payload.orderId,
          orderItemId: payload.orderItemId,
        });
      } else if (type === PrintTypes.OrderReceipt) {
        requestPromise = Feathers.service('/printable/order-receipts').create({
          orderId: payload.orderId,
        });
      } else if (type === PrintTypes.PickList) {
        requestPromise = Feathers.service('/printable/pick-list').create({
          orderId: payload.orderId,
        });
      } else if (type === PrintTypes.PackingSlip) {
        requestPromise = Feathers.service('/printable/packing-slip').create({
          orderId: payload.orderId,
        });
      } else if (type === PrintTypes.Invoice) {
        requestPromise = Feathers.service('/printable/invoice').create({
          orderId: payload.orderId,
        });
      } else if (type === PrintTypes.PackingSlip) {
        requestPromise = Feathers.service('/printable/packing-slip').create({
          orderId: payload.orderId,
        });
      } else if (type === PrintTypes.PurchaseOrder) {
        requestPromise = Feathers.service('/printable/purchase-order').create({
          id: payload._id,
        });
      } else if (type === PrintTypes.SalesOrder) {
        requestPromise = Feathers.service('/printable/sales-order').create({
          id: payload._id,
        });
      } else if (type === PrintTypes.Estimate) {
        requestPromise = Feathers.service('/printable/estimate').create({
          id: payload._id,
        });
      } else {
        throw Error(`Unknown type ${type}`);
      }

      requestPromise.then((res: any) => {
        this.setState({
          ...this.state,
          isModalOpen: true,
          isLoading: false,
          downloadUrl: res.url,
          message: res.message,
        });
      });
    };

    handleClose = (e, data) => {
      this.setState({
        ...this.state,
        isModalOpen: false,
        payload: null,
        isLoading: false,
        downloadUrl: null,
        message: '',
      });
    };

    handleDownloadClick = (e) => {
      this.handleClose(null, null);
    };

    render() {
      return (
        <div>
          <Component printHandleOpen={this.printHandleOpen} {...this.props} />
          <Modal open={this.state.isModalOpen} onClose={this.handleClose} closeIcon>
            <Modal.Header>Print</Modal.Header>
            <Modal.Content>
              {this.state.isLoading && (
                <Dimmer active>
                  <Loader inline="centered" content="Loading" />
                </Dimmer>
              )}
              {!this.state.isLoading && this.state.downloadUrl && (
                <>
                  <a
                    target="_blank"
                    onClick={this.handleDownloadClick}
                    href={this.state.downloadUrl}
                  >
                    <Button>Download PDF</Button>
                  </a>
                  <hr />
                  <embed
                    src={this.state.downloadUrl}
                    type="application/pdf"
                    height={400}
                    width={'100%'}
                  />
                </>
              )}

              {!this.state.isLoading && !this.state.downloadUrl && (
                <Message warning content={this.state.message} />
              )}
            </Modal.Content>
          </Modal>
        </div>
      );
    }
  };
};
