import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  Alert,
  Checkbox,
  Stack,
  Text,
} from '@chakra-ui/react';

import type { ModelTypes } from '@inkcloud/icapi-types';

import feathers from '../../bootstrap/feathers';

interface IDuplicateModalProps {
  data: NonNullable<ModelTypes.Rfq>;
  onClose: () => void;
}

const fieldsToDuplicate = [
  { value: 'vendorCosts', label: 'Vendor Costs' },
  { value: 'paperCosts', label: 'Paper Costs' },
  { value: 'additionalCosts', label: 'Additional Costs' },
];

const serviceName = `/rfqs`;
const service = feathers.service(serviceName);

/*
function calculateEstimateQuantity(estimateQuantity, rfq, rfqItemId: ) {
  estimateQuantity.subtotalCosts = rfq.responses
  ?.filter(r => ((q.selectedVendors as any)).includes(((r.vendor as any)._id)))
  .reduce((acc, cur) => {
    // console.log('^^^^^^ in reduce', cur);
    const thisItem = cur.items?.find(i2 => i2.item === rfqItem._id)?.quantities?.find(q2 => q2.quantity === q.quantity);
    const totalPaperCost = q.paperCostM?.reduce((acc2, cur2) => acc2 + Number(cur2.costTotal ?? 0.00), 0) ?? 0.00;
    return acc + (thisItem?.price ?? 0.00) + totalPaperCost;
  }, 0);

  estimateQuantity.paperCostTotal = estimateQuantity.paperCostM?.reduce((acc, cur) => acc + Number(cur.costTotal ?? 0.00), 0) ?? 0.00;
  estimateQuantity.totalCosts = (estimateQuantity.subtotalCosts ?? 0.00) + (estimateItem.additionalCosts?.reduce((acc, cur) => acc + Number(cur?.quantities?.find(q3 => q3.quantity === estimateQuantity.quantity)?.amount ?? 0.00), 0) ?? 0.00);
  estimateQuantity.subtotalPrice = Number(estimateQuantity.totalCosts ?? 0.00) + Number(estimateQuantity.markupAmount ?? 0.00);
  estimateQuantity.totalPrice = estimateQuantity.subtotalPrice + Number((estimateQuantity.taxAmount ?? 0.00));

  return q;
}
*/

const PreDuplicateModal = (props: IDuplicateModalProps & RouteComponentProps) => {
  const { data, onClose, history } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [checkField, setCheckField] = useState<Record<string, boolean>>({});

  useEffect(() => {
    const allFields = fieldsToDuplicate?.reduce((acc, cur) => {
      acc[cur.value] = true;

      return acc;
    }, {});

    setCheckField(allFields);
  }, []);

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = e.target;

    setCheckField({ ...checkField, [name]: checked });

    // if (checked) {
    //   setFields([...fields, value])
    //   setCheckField(({...checkField, [name]: checked}))
    // } else {
    //   setFields(fields.filter((field) => field !== value))
    // }
  };

  const handleToggleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;

    const allFields = fieldsToDuplicate?.reduce((acc, cur) => {
      acc[cur.value] = checked;

      return acc;
    }, {});

    setCheckField(allFields);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    setErrorMessage('');
    try {
      // const payload = Object.keys(checkField)?.reduce((acc, key) => {
      //   if (checkField[key]) {
      //     acc[key] = data[key]
      //   }
      //   return acc
      // }, {})

      // console.log('checkField', checkField);

      const payload: ModelTypes.Rfq = {
        ...data,
        _id: undefined,
        createdAt: undefined,
        updatedAt: undefined,
        humanId: undefined,
        salesOrderNum: undefined,
        salesOrder: undefined,
        status: undefined,
        isRfqSent: undefined,
        rfqSentAt: undefined,
        isEstimateSent: undefined,
        estimateSentAt: undefined,
        isPOCreated: undefined,
        isSalesOrderCreated: undefined,
        privateNotes: [],
        notes: undefined,
        estimateDueDate: undefined,
        deliveryDate: undefined,
      };

      // console.log('payload', payload);

      if (!checkField.vendorCosts) {
        payload.responses =
          payload.responses?.map((r) => {
            const a = '';
            return {
              ...r,
              _id: undefined,
              status: 'pending',
              respondedAt: undefined,
              estimateNumber: undefined,
              createdAt: undefined,
              updatedAt: undefined,
              items:
                r.items?.map((i) => ({
                  ...i,
                  _id: undefined,
                  createdAt: undefined,
                  updatedAt: undefined,
                  notes: undefined,
                  quantities:
                    i.quantities?.map((q) => ({
                      ...q,
                      _id: undefined,
                      price: undefined,
                      createdAt: undefined,
                      updatedAt: undefined,
                      components:
                        q.components?.map((c) => ({
                          ...c,
                          _id: undefined,
                          numSheets: undefined,
                          sheetSize: { width: undefined, height: undefined },
                          createdAt: undefined,
                          updatedAt: undefined,
                        })) ?? [],
                    })) ?? [],
                })) ?? [],
            };
          }) ?? [];
      }

      if (!checkField.paperCosts) {
        if (payload.estimate) {
          payload.estimate.items =
            payload.estimate?.items?.map((i) => ({
              ...i,
              _id: undefined,
              quantities:
                i.quantities?.map((q) => ({
                  ...q,
                  _id: undefined,
                  paperCostM:
                    q.paperCostM?.map((p) => ({
                      ...p,
                      _id: undefined,
                      createdAt: undefined,
                      updatedAt: undefined,
                      cost: undefined,
                      costTotal: undefined,
                    })) ?? [],
                })) ?? [],
            })) ?? [];
        }
      }

      if (!checkField.additionalCosts) {
        if (payload.estimate) {
          payload.estimate.items =
            payload.estimate?.items?.map((i) => ({
              ...i,
              additionalCosts: [],
            })) ?? [];
        }
      }

      if (payload.estimate) {
        payload.estimate.respondedAt = undefined;
        payload.estimate.createdAt = undefined;
        payload.estimate.updatedAt = undefined;
        payload.estimate.response = undefined;
        payload.estimate.respondedAt = undefined;
        payload.estimate.createdOrder = undefined;
        payload.estimate.createdOrderAt = undefined;
        payload.estimate.createdOrderHumanId = undefined;
        payload.estimate.externalJobNumber = undefined;
        payload.estimate.clientPo = undefined;
        payload.estimate.costCenter = undefined;
        payload.estimate.awardedSuppliers = [];
      }

      // Recalculate the estimate calcualted fields

      // De-populate the responses.vendor field to just the ID
      payload.responses =
        payload.responses?.map((r) => ({
          ...r,
          vendor: (r.vendor as any)?._id,
        })) ?? [];



      const res = await service.create(
        {
          // customer: data?.customer,
          // micrositeUser: data?.micrositeUser,
          // allowableOverrunPercentage: data?.allowableOverrunPercentage,
          // allowableUnderrunPercentage: data?.allowableUnderrunPercentage,
          ...payload,
        },
        { query: {} }
      );

      onClose();

      history.push(`/rfqs/${res?._id}`);
    } catch (e) {
      const errMsg = e?.code < 500 ? e?.message : 'Something went wrong. Please try again later.';
      setErrorMessage(errMsg);
    }
    setIsSubmitting(false);
  };

  const isAllChecked = Object.keys(checkField)?.every((c) => checkField[c]);

  const someIsChecked = Object.keys(checkField)?.some((c) => checkField[c]);

  return (
    <Modal size="2xl" isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>RFQ {data?.humanId || ''}</ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <Stack spacing={5} mb={3} direction="column">
            <FormControl>
              <div>
                This RFQ will be duplicated. You can opt out of copying over the following fields:
              </div>
              <Checkbox isChecked={isAllChecked} onChange={handleToggleCheck}>
                <Text as="b">{isAllChecked ? 'Unselect' : 'Select'} All</Text>
              </Checkbox>
            </FormControl>
            {fieldsToDuplicate?.map((field, index: number) => (
              <FormControl key={index}>
                <Checkbox
                  isChecked={checkField[field.value]}
                  name={field.value}
                  value={field.value}
                  onChange={handleFieldChange}
                >
                  {field.label}
                </Checkbox>
              </FormControl>
            ))}
          </Stack>

          {errorMessage && <Alert status="error">{errorMessage}</Alert>}
        </ModalBody>

        <ModalFooter>
          <Button
            isDisabled={isSubmitting}
            isLoading={isSubmitting}
            colorScheme="blue"
            mr={3}
            onClick={handleSubmit}
          >
            Submit
          </Button>
          <Button isDisabled={isSubmitting} mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
const DuplicateModal = withRouter(PreDuplicateModal);
export default DuplicateModal;
