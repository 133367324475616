import * as React from 'react';
import { Button, Comment, Form, Header, Modal, Image, TextArea, Message } from 'semantic-ui-react';

interface NotesAddModalProps {
  open: boolean;
  errorMessage?: string;
  handleClose: any;
  noteContent: string;
  noteContentChange: any;
  onAddClick: any;
}
export const NotesAddModal = (props: NotesAddModalProps) => {
  return (
    <div>
      <Modal
        size="small"
        open={props.open}
        closeIcon="close"
        closeOnEscape={true}
        // closeOnRootNodeClick={true}
        onClose={props.handleClose}
      >
        <Modal.Header>Add a Note</Modal.Header>
        <Modal.Content>
          {props.errorMessage && <Message error content={props.errorMessage} />}
          <form className="ui reply small form">
            <div className="field">
              <TextArea
                rows={'3'}
                style={{ minHeight: 130 }}
                value={props.noteContent}
                onChange={props.noteContentChange}
              />
            </div>
            <Button
              primary
              icon="edit"
              onClick={props.onAddClick}
              content="Save Note"
              attached="bottom"
              size="mini"
              color="blue"
            />
          </form>
        </Modal.Content>
      </Modal>
    </div>
  );
};
