import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Segment, Form, Dropdown } from 'semantic-ui-react';
import { PageHeaderRow, PreLoader, useFeathers } from '@inkcloud/components';
import Feathers from '../../../bootstrap/feathers';
import { NotificationRulesForm } from './NotificationRulesForm';

import { humanize } from 'underscore.string';
import { connect } from 'react-redux';

interface DetailProps {
  products: { value: string; text: string }[];
  categories: { value: string; text: string }[];
}

export interface ConnectedProps {
  user?: any;
}

const { useState, useEffect } = React;

export const PreDetail: React.FunctionComponent<
  DetailProps & ConnectedProps & RouteComponentProps<{ micrositeId?: string; id?: string }>
> = (props) => {
  const {
    match: {
      params: { micrositeId, id },
    },
    history,
    user,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState({ settings: '', tenant: '', keys: '' });
  const [setting, setSetting] = useState(null);
  const [responseMessage, setResponseMessage] = useState('');

  useEffect(() => {
    const loadSetting = async () => {
      try {
        setErrorMessage({ ...errorMessage, settings: '' });
        setIsLoading(true);
        const res: any = await Feathers.service(
          `/microsites/notification-settings/${micrositeId}`
        ).find({
          query: {
            templateKey: id,
          },
        });

        setSetting({ ...res, templateKey: id });
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        setErrorMessage({
          ...errorMessage,
          settings:
            e.code && e.code < 500
              ? e.message
              : 'We are experiencing technical difficulties. Please try again',
        });
      }
    };

    loadSetting();
  }, []);

  const handleSubmit = async (values: any) => {
    const payload = {
      microsite: micrositeId,
      templateKey: values.templateKey,
      templateDetails: {
        ...values,
      },
    };

    if (!values?.defaultEmail?.email || !values?.defaultEmail?.name) {
      delete payload.templateDetails?.defaultEmail;
    }

    try {
      setResponseMessage('');
      setErrorMessage({ ...errorMessage, tenant: '' });
      setIsLoading(true);
      const res: any = await Feathers.service('/microsites/notification-settings').create(payload, {
        query: {},
      });
      setIsLoading(false);

      if (res.result) {
        setResponseMessage(res.message);
      }
    } catch (e) {
      setIsLoading(false);
      setErrorMessage({
        ...errorMessage,
        tenant:
          e.code && e.code < 500
            ? e.message
            : 'We are experiencing technical difficulties. Please try again',
      });
    }
  };

  return (
    <div>
      <PageHeaderRow
        header={`Edit ${humanize(id)}`}
        subheader="Notification Settings"
        subheaderId={''}
      >
        <Form size="mini">
          <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
        </Form>
      </PageHeaderRow>

      <NotificationRulesForm
        initialValues={setting}
        onSubmit={handleSubmit}
        errorMessage={errorMessage}
        responseMessage={responseMessage}
        micrositeId={micrositeId}
        isLoading={isLoading}
      />
    </div>
  );
};

const mapStateToProps = (state): ConnectedProps => ({
  user: state.auth.user || {},
});

export const Detail = connect(mapStateToProps)(PreDetail as any);
