import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Segment, Popup, Button, Label } from 'semantic-ui-react';

import { ListTable, useList, PageHeaderRow, EmptyWrapper } from '@inkcloud/components';
import { FilterForm } from './FilterForm';

const serviceName = '/dash-home/production-pipeline';

const filterQueryMap = (values) => {
  const filterMap: any = {
    microsite: (values?.microsite?.value !== 'all' && values?.microsite?.value) || undefined,
  };

  return [].reduce(
    (m, field) => ({ ...m, [field]: values[field] ? values[field] : undefined }),
    filterMap
  );
};

export const List: React.FunctionComponent<RouteComponentProps> = (props) => {
  const {
    match: { params },
    location,
    history,
  } = props;

  const {
    state,
    reload,
    handleDelete,
    selectedItems,
    clearAll,
    setChecked,
    someSelected,
    toggleAll,
    filter,
    setFilter,
    sort,
    handleSort,
  } = useList({
    feathers: [
      'find',
      serviceName,
      [
        {
          query: {
            $np: 1,
          },
        },
      ],
    ],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  const modifiedRows = (value: string, label: string) => {
    const defaultRow = [
      {
        selectedFact: 'productionStatus.jobStatus',
        selectedOperator: 'is',
        value: [{ value, label }],
      },
    ];

    const msValue = filter?.microsite?.value;

    msValue &&
      msValue !== 'all' &&
      defaultRow.unshift({
        selectedFact: 'microsite',
        selectedOperator: 'is',
        value: [{ value: msValue, label: filter?.microsite?.text }],
      });

    return defaultRow;
  };

  const defintion = [
    {
      key: 'count',
      label: 'Count',
      cell: (v, r) => (
        <Label circular color="green">
          {r.count}
        </Label>
      ),
    },
    {
      key: 'status',
      label: 'Production Status',
      cell: (v, r) => <div>{r.description}</div>,
    },
    {
      key: '',
      label: '',
      cell: (v, r) => (
        <Popup
          size="mini"
          content="View"
          trigger={
            <Button
              size="mini"
              icon="magnify"
              onClick={() =>
                history.push({
                  pathname: `/orders`,
                  state: {
                    filter: {
                      rows: modifiedRows(r.status, r.description),
                    },
                  },
                })
              }
            />
          }
        />
      ),
      textAlign: 'right',
    },
  ];

  const { queryResult, isLoading: isStateLoading } = state as any;

  return (
    <>
      <PageHeaderRow header="Pipeline" subheader="Production" />
      <Segment>
        <FilterForm value={filter} onChange={(v) => setFilter(v)} onReset={() => setFilter({})} />
      </Segment>

      <Segment>
        <EmptyWrapper queryResult={queryResult} isStateLoading={isStateLoading}>
          <ListTable
            definition={defintion}
            data={queryResult}
            sort={sort}
            sortClickFunction={(v) => console.log('got sort value', v)}
            selectedItems={[]}
            setChecked={(v) => console.log('got set checked', v)}
            withCheckboxes={false}
            toggleAll={() => console.log('got a toggle all')}
          />
        </EmptyWrapper>
      </Segment>
    </>
  );
};
