import * as React from 'react';
import Dropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown';
import Feathers from './../bootstrap/feathers';

export const StringToLike = (field) => {
  return (filter) => {
    if (filter[field] && typeof filter[field] !== 'object') {
      filter[field] = { $LIKE: filter[field] };
    }
    return filter;
  };
};

export const makeCancelable = (promise) => {
  let hasCanceled_ = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then((val) => (hasCanceled_ ? reject({ isCanceled: true }) : resolve(val)));
    promise.catch((error) => (hasCanceled_ ? reject({ isCanceled: true }) : reject(error)));
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled_ = true;
    },
  };
};

interface EntityDropdownProps {
  attributeTypeValue?: string;
  onChange?: Function;
  onBlur?: Function;
  placeholder?: string;
  search?: boolean;
  multiple: boolean;
  initialValue?: any;
  value?: any;
  serviceParams?: any;
  disabled: boolean;
}

interface EntityDropdownState {
  items: any[];
  isLoading: boolean;
  serviceParams: any;
}

const mapResults = (row) => ({ value: row._id, text: row.name });
const defaultResultMap = (row) => ({ key: row.name, value: row._id, text: row.name });
const defaultSearchMap = (qs) => ({ name: { $LIKE: qs } });

export const EntityDropdownWrapper2 = (serviceName, initServiceParams, options): any => {
  const resultMap = options.resultMap || defaultResultMap;
  const searchMap = options.searchMap || defaultSearchMap;

  const EntityDropdown = class EntityDropdown extends React.Component<
    EntityDropdownProps,
    EntityDropdownState
  > {
    remoteService;
    remotePromise;

    constructor(props) {
      super(props);
      this.onValueChange = this.onValueChange.bind(this);
      this.onBlur = this.onBlur.bind(this);
      this.handleSearchChange = this.handleSearchChange.bind(this);

      this.state = {
        items: [],
        isLoading: false,
        serviceParams: initServiceParams,
      };

      this.remoteService = Feathers.service(serviceName);
    }

    componentDidMount() {
      if (this.props.serviceParams) {
        this.loadRemoteItems(this.props.serviceParams);
      } else if (initServiceParams) {
        this.loadRemoteItems(initServiceParams);
      }
    }

    componentWillUnmount() {
      if (this.remotePromise) {
        this.remotePromise.cancel();
      }
    }

    componentWillReceiveProps(nextProps) {
      if (this.props.serviceParams !== nextProps.serviceParams && nextProps.serviceParams) {
        this.loadRemoteItems(nextProps.serviceParams);
      }
    }

    loadRemoteItems(serviceParams, qs = null) {
      let serviceParamsCopy = { ...serviceParams };
      if (qs) {
        const qsQuery = searchMap(qs);
        serviceParamsCopy.query = { ...serviceParamsCopy.query, ...qsQuery };
      }
      const servicePromise = this.remoteService.find(serviceParamsCopy);
      this.remotePromise = makeCancelable(servicePromise);

      this.setState({ ...this.state, isLoading: true });
      this.remotePromise.promise
        .then((res) => {
          const data = res.data ? res.data : res;
          // If its not updating due to search, overwrite existing items
          if (qs === null) {
            this.setState({ ...this.state, items: data.map(resultMap), isLoading: false });
          } else {
            // Otherwise merge new items into existing
            const itemsCopy = this.state.items.slice();
            const itemsToMergeIn = data.map(resultMap).filter((r) => {
              return itemsCopy.find((i) => i.value === r.value) ? false : true;
            });

            this.setState({
              ...this.state,
              items: itemsCopy.concat(itemsToMergeIn),
              isLoading: false,
            });
          }
        })
        .catch((err) => {
          if (!err.isCaneled) {
            throw err;
          }
        });
    }

    handleSearchChange(e, value) {
      this.setState(Object.assign(this.state, { loading: true }));
      if (this.props.serviceParams) {
        this.loadRemoteItems(this.props.serviceParams, e.target.value);
      } else {
        this.loadRemoteItems(initServiceParams, e.target.value);
      }
    }

    onValueChange(e, value) {
      this.props.onChange && this.props.onChange(e, value);
    }

    onBlur(e, value) {
      let v = !this.props.value && this.props.multiple ? [] : '';
      // this.props.onBlur && this.props.onBlur(v);
    }

    render() {
      let v = this.props.value;
      if (this.props.multiple && !Array.isArray(this.props.value)) {
        v = [];
      }

      // deal with value being
      if (v === '') {
        v = null;
      }

      const { serviceParams, value, ...passOnProps } = this.props;
      return (
        <Dropdown
          {...passOnProps}
          // defaultValue={v}
          value={v}
          onChange={this.onValueChange}
          onBlur={this.onBlur}
          onSearchChange={this.handleSearchChange}
          search={this.props.search || true}
          selection
          fluid={true}
          placeholder={this.props.placeholder}
          multiple={this.props.multiple}
          options={this.state.items}
          loading={this.state.isLoading}
          disabled={this.props.disabled}
        />
      );
    }
  };

  return EntityDropdown;
};
