// import * as React from 'react';
// import { Link, RouteComponentProps } from 'react-router-dom';
// import { FormattedDate, FormattedTime } from 'react-intl';
// import { Segment, Table, Message } from 'semantic-ui-react';
// import { useList, PageHeaderRow, EmptyWrapper } from '@inkcloud/components';
// import { connect } from 'react-redux';
// import * as numeral from 'numeral';
// import { FilterForm } from './FilterForm';
// import Feathers from './../../../bootstrap/feathers';

// export interface ConnectedProps {
//   tenant?: any;
// }
// interface ListProps {}

// const filterQueryMap = (values) => {
//   //moment(value).tz(tzOfTenant).toISOString() <-- if it's available

//   const filterMap: any = {
//     start: values.start ? values.start.toISOString() : undefined,
//     end: values.end ? values.end.toISOString() : undefined,
//   };

//   return [].reduce(
//     (m, field) => ({
//       ...m,
//       [field]: values[field] ? values[field] : undefined,
//     }),
//     filterMap
//   );
// };

// const serviceName = '/reports/v2/payments';

// const { useState, useEffect } = React;

// export const PreList: React.FunctionComponent<
//   ListProps & ConnectedProps & RouteComponentProps<any>
// > = (props) => {
//   const [filter, setFilter] = useState({});
//   const [errorMessage, setErrorMessage] = useState('');
//   const [isLoading, setIsLoading] = useState(false);

//   const [state, setState] = useState(null);

//   const filterMapped = filterQueryMap(filter);

//   const handleSubmit = async () => {
//     setErrorMessage('');
//     setIsLoading(true);

//     const payload = {
//       tenant: props.tenant,
//       ...filterMapped,
//     };
//     try {
//       const res: any = await Feathers.service(serviceName).create(payload, { query: {} });
//       setState(res);
//       setIsLoading(false);
//     } catch (e) {
//       setIsLoading(false);
//       e.code && e.code < 500
//         ? setErrorMessage(e.message)
//         : setErrorMessage('We are experiencing technical difficulties. Please try again');
//     }
//   };

//   return (
//     <>
//       <PageHeaderRow header="Payment" subheader="Report" />
//       <FilterForm
//         value={filter}
//         onChange={(v) => setFilter(v)}
//         onReset={() => setFilter({})}
//         onSubmit={handleSubmit}
//       />
//       <EmptyWrapper queryResult={state} isStateLoading={isLoading}>
//         <Segment loading={isLoading}>
//           {errorMessage && <Message error content={errorMessage} />}
//           <Table size={'small'} sortable>
//             <Table.Header>
//               <Table.Row>
//                 <Table.HeaderCell>Timestamp</Table.HeaderCell>
//                 <Table.HeaderCell>Invoice</Table.HeaderCell>
//                 <Table.HeaderCell>Name</Table.HeaderCell>
//                 <Table.HeaderCell>Transaction</Table.HeaderCell>
//                 <Table.HeaderCell>Promo Code</Table.HeaderCell>
//                 <Table.HeaderCell>Payment Type</Table.HeaderCell>
//                 <Table.HeaderCell>Amount</Table.HeaderCell>
//               </Table.Row>
//             </Table.Header>
//             <Table.Body>
//               {state &&
//                 state.length > 0 &&
//                 state.map((r: any, index: number) => (
//                   <Table.Row key={index}>
//                     <Table.Cell>
//                       <FormattedDate value={r.createdAt} /> <FormattedTime value={r.createdAt} />
//                     </Table.Cell>
//                     <Table.Cell>
//                       <Link to={`/orders/${r.orderId}`}>{r.invoiceId}</Link>
//                     </Table.Cell>
//                     <Table.Cell>{r.name}</Table.Cell>
//                     <Table.Cell>{r.type === 'REFUND' ? `(${r.type})` : r.type}</Table.Cell>
//                     <Table.Cell>{r.promoCode}</Table.Cell>
//                     <Table.Cell>{r.method}</Table.Cell>
//                     <Table.Cell>{numeral(r.amount).format('$0,0.00')}</Table.Cell>
//                   </Table.Row>
//                 ))}
//             </Table.Body>
//           </Table>
//         </Segment>
//       </EmptyWrapper>
//     </>
//   );
// };

// const mapStateToProps = (state): ConnectedProps => ({
//   tenant: state.auth.user.business || {},
// });

// export const List = connect(mapStateToProps)(PreList as any);

import React, { useEffect, useState } from 'react';
import {
  Text,
  Box,
  Table,
  Tbody,
  Tr,
  Th,
  Td,
  Flex,
  Spinner,
  Button,
  Thead,
} from '@chakra-ui/react';

import format from 'date-fns/format';
import addYears from 'date-fns/addYears';

import { Link, RouteComponentProps, useHistory } from 'react-router-dom';
import { EmptyWrapper, PageHeaderRow } from '@inkcloud/components';
import { useSelector } from 'react-redux';
import { FormattedDate, FormattedNumber, FormattedTime } from 'react-intl';

import Feathers from '../../../bootstrap/feathers';
import FilterForm from '../FilterForm2';

const initValue = { start: null, end: null };

export default function SalesTeam() {
  const history = useHistory();

  const tenant = useSelector(
    ({
      globals,
    }: {
      globals: {
        tenant: { internationalization: { locale: string; currency: string }; _id: string };
      };
    }) => globals.tenant
  );

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [filter, setFilter] = useState<{
    start: null | Date;
    end: null | Date;
  }>(initValue);

  const [data, setData] = useState<
    {
      invoice: string;
      invoiceId: number;
      createdAt: string;
      name: string;
      promoCode: string;
      type: string;
      method: string;
      amount: number;
    }[]
  >([]);
  const [errorMessage, setErrorMessage] = useState('');

  const start = filter.start ? filter.start.toISOString() : undefined;
  const end = filter.end ? filter.end.toISOString() : undefined;

  const loadData = async () => {
    setErrorMessage('');
    setIsSubmitting(true);
    setData([]);

    try {
      const res = await Feathers.service('/reports/v2/payments').create({
        start,
        end,
        tenant: tenant?._id,
      });
      setData(res);
    } catch (e) {
      const errMsg =
        e?.code < 500 ? e?.message : 'We are experiencing technical difficulties. Please try again';
      setErrorMessage(errMsg);
      setData([]);
    }

    setIsSubmitting(false);
  };

  const maxDate = addYears(filter?.start as Date, 1);
  const exceedDate = filter?.start && filter?.end ? filter.end > maxDate : false;

  useEffect(() => {
    if (filter?.start && filter?.end && !exceedDate) {
      loadData();
    }
  }, [filter]);

  const handleReset = () => {
    setData([]);
    setFilter(initValue);
  };

  console.log('filter', filter);

  return (
    <div>
      <PageHeaderRow header="Payment" subheader="Reports">
        <Button onClick={() => history.goBack()}>Back</Button>
      </PageHeaderRow>

      <Box p={3} mb={3} borderWidth={1} borderRadius="md" bg="white">
        <FilterForm
          values={filter}
          isSubmitting={isSubmitting}
          hasTime={true}
          errorMessage={errorMessage}
          onChange={(v) => {
            setErrorMessage('');
            setFilter(v);
          }}
          onReset={handleReset}
        />
      </Box>
      <EmptyWrapper queryResult={data} isStateLoading={isSubmitting}>
        <Box p={3} borderWidth={1} borderRadius="md" bg="white">
          <Table>
            <Thead>
              <Tr>
                <Th>Timestamp</Th>
                <Th>Invoice</Th>
                <Th>Name</Th>
                <Th>Transaction</Th>
                <Th>Promo Code</Th>
                <Th>Payment Type</Th>
                <Th isNumeric>Amount</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data?.map((d, index: number) => (
                <Tr key={index}>
                  <Td>
                    <FormattedDate value={d.createdAt} /> <FormattedTime value={d.createdAt} />
                  </Td>
                  <Td>
                    <Link to={`/orders/${d.invoice}`}>{d.invoiceId}</Link>
                  </Td>
                  <Td>{d?.name}</Td>
                  <Td>{d.type === 'REFUND' ? `(${d.type})` : d.type}</Td>
                  <Td>{d?.promoCode}</Td>
                  <Td>{d?.method}</Td>
                  <Td isNumeric>
                    <FormattedNumber
                      value={d.amount}
                      style="currency"
                      currency={tenant.internationalization.currency}
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </EmptyWrapper>
    </div>
  );
}
