import React, { useEffect, useState } from 'react';
import { useForm, useFieldArray, SubmitHandler, NonUndefined } from 'react-hook-form';
import type { ModelTypes } from '@inkcloud/icapi-types';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { GenericFilter } from '@inkcloud/components';

import {
  Box,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Stack,
  Button,
  Alert,
  IconButton,
  Text,
  Heading,
} from '@chakra-ui/react';

import { RiCloseFill } from 'react-icons/ri';

import { Select, MultiValue } from 'chakra-react-select';
import { Feathers } from '../../../bootstrap/feathers/feathers';

import FilterDefinition from './FilterDefinition';

import StatusModal from './StatusModal';

type tempType = {
  _id?: string;
  name: string;
  viewType: string;
  columns?: { id: string; name: string; priority: number; columndID?: string }[];
  filter?: any;
  exportFields: string[];
  vendor: string;
};

interface IDataViewFormProps {
  initValues: tempType; // Todo ModelTypes.DataView
  onSubmit: (v: tempType) => void;
  errorMessage: string;
  isSubmitting?: boolean;
}

const viewTypeOptions = [
  { value: 'list', label: 'List' },
  { value: 'grid', label: 'Grid' },
];

const exportFieldOptions = [
  { value: 'createdAt', label: 'Created At' },
  { value: 'customerName', label: 'Customer Name' },
  { value: 'customerJobName', label: 'Customer Ref' },
  { value: 'cost', label: 'Cost' },

  { value: 'estimatedCompletionDate', label: 'Estimated Completion Date' },

  { value: 'intakeChannel', label: 'Intake Channel' },
  { value: 'statusLastChangedAt', label: 'Status Last Changed At' },

  { value: 'orderItemId', label: 'Order Item ID' },
  { value: 'metadataMerged', label: 'Metadata Merged' },
  { value: 'orderItemId', label: 'Order Item ID' },

  { value: 'quantity', label: 'Quantity' },

  { value: 'productionStatus', label: 'Production Status' },
  { value: 'productionChannel', label: 'Production Channel' },

  { value: 'sku', label: 'SKU' },
];

const schema = Yup.object().shape({
  name: Yup.string().required('Name is required').trim(),
});

export default function DataViewForm(props: IDataViewFormProps) {
  const { initValues, errorMessage, isSubmitting, onSubmit } = props;

  const [isOpenModal, setIsOpenModal] = useState(false);

  const updatedInitValues = {
    ...initValues,
    columns: initValues.columns?.map((c) => ({ ...c, columnID: c.id })) || [],
    viewType: initValues?._id ? initValues.viewType : viewTypeOptions[0].value,
  };

  const {
    control,
    watch,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IDataViewFormProps['initValues']>({
    defaultValues: updatedInitValues,
    resolver: yupResolver(schema),
  });

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control,
    name: 'columns',
  });

  const [vendorsOptions, setVendorsOptions] = useState<{ label: string; value: string }[]>([]);

  const loadVendors = async () => {
    try {
      const res: { data: NonNullable<ModelTypes.ProductionVendors[]> } = await Feathers.service(
        '/production/vendors'
      ).find({
        query: {},
      });

      setVendorsOptions(
        res?.data?.map((v) => ({ label: v.companyName as string, value: v._id as string }))
      );
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    loadVendors();
  }, []);

  const handSave: SubmitHandler<tempType> = (data) => onSubmit(data);

  const watchViewType = watch('viewType');
  const watchFilter = watch('filter');
  const watchVendor = watch('vendor');

  return (
    <>
      <Stack spacing={7} mb={3}>
        <FormControl isInvalid={!!errors?.name}>
          <FormLabel>Name</FormLabel>
          <Input {...register('name')} />
          {errors?.name && <FormErrorMessage>{errors.name.message}</FormErrorMessage>}
        </FormControl>
      </Stack>
      <Stack spacing={7} mb={3}>
        <FormControl>
          <FormLabel>View Type</FormLabel>
          <Select
            value={viewTypeOptions.find((v) => v.value === watchViewType)}
            options={viewTypeOptions}
            onChange={(e) => setValue('viewType', e?.value as string)}
          />
        </FormControl>
      </Stack>
      {watchViewType === 'grid' && (
        <Box borderWidth="1px" borderRadius="md" p={3} mb={3}>
          <Button type="button" mb={3} onClick={() => setIsOpenModal(true)}>
            Status Book
          </Button>
          <Heading as="h6" size="xs" mb={3}>
            Columns
          </Heading>
          {fields.map((field, index) => (
            <Stack key={field.id} direction="row" spacing={7} mb={3}>
              <FormControl>
                <FormLabel>ID</FormLabel>
                <Input {...register(`columns.${index}.id`)} />
              </FormControl>
              <FormControl>
                <FormLabel>Name</FormLabel>
                <Input {...register(`columns.${index}.name`)} />
              </FormControl>
              <FormControl>
                <FormLabel>Priority</FormLabel>
                <Input {...register(`columns.${index}.priority`)} />
              </FormControl>
              <FormControl flex={1}>
                <FormLabel>&nbsp;</FormLabel>
                <IconButton
                  type="button"
                  variant="outline"
                  aria-label="remove field"
                  onClick={() => remove(index)}
                  icon={<RiCloseFill />}
                />
              </FormControl>
            </Stack>
          ))}

          <Button
            type="button"
            variant="outline"
            onClick={() => append({ id: '', name: '', priority: fields?.length })}
          >
            Add new column
          </Button>
        </Box>
      )}

      <Box borderWidth="1px" borderRadius="md" p={3} mb={3}>
        <Heading as="h6" size="xs" mb={3}>
          Filter
        </Heading>
        <GenericFilter
          factDefinitionFunction={FilterDefinition}
          value={watchFilter}
          onChange={(v) => setValue('filter', v)}
        />
      </Box>

      <Stack spacing={7} mb={3}>
        <FormControl>
          <FormLabel>Export Fields</FormLabel>
          <Select
            options={exportFieldOptions}
            isMulti
            defaultValue={initValues.exportFields?.map((v) =>
              exportFieldOptions.find((e) => e.value === v)
            )}
            onChange={(e) =>
              setValue(
                'exportFields',
                e?.map((v) => v?.value || '')
              )
            }
          />
        </FormControl>
      </Stack>

      <Stack spacing={7} mb={3}>
        <FormControl>
          <FormLabel>Vendor</FormLabel>
          <Select
            options={vendorsOptions}
            value={vendorsOptions.find((v) => v.value === watchVendor)}
            onChange={(e) => setValue('vendor', e?.value as string)}
          />
        </FormControl>
      </Stack>

      {errorMessage && (
        <Alert status="error" mb={3}>
          {errorMessage}
        </Alert>
      )}

      <Button
        colorScheme="blue"
        isDisabled={isSubmitting}
        isLoading={isSubmitting}
        onClick={handleSubmit(handSave)}
      >
        Save
      </Button>

      {isOpenModal && (
        <StatusModal
          filledFields={fields}
          add={(v) => {
            append(v);
            setIsOpenModal(false);
          }}
          onClose={() => setIsOpenModal(false)}
        />
      )}
    </>
  );
}
