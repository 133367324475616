import * as React from 'react';
import { useDropzone } from 'react-dropzone';
import { connect } from 'react-redux';
import { Segment, Divider, Button } from 'semantic-ui-react';
import axios from '../../../../../bootstrap/axios';
const { useState, useCallback } = React;

declare var APPENV;

interface ConnectProps {
  tenantId?: string;
}

interface ImageUploadProps {
  showFiles?: boolean;
  multiple?: boolean;
  api?: string;
  micrositeId?: string;
  onUpload: (files: any) => void;
  btnLabel?: string;
}

export const ImageUploadPre: React.FunctionComponent<ImageUploadProps & ConnectProps> = (props) => {
  const { showFiles = true, multiple = true, btnLabel = 'Upload Image' } = props;
  const [files, setFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);

  const api = props.api ? props.api : '/content/images';

  const onDrop = useCallback(async (acceptedFiles) => {
    setFiles(files.concat(acceptedFiles));

    acceptedFiles.map(async (f) => {
      const formData = new FormData();
      formData.append('file', f);
      formData.append('tenantId', props.tenantId);
      if (props.micrositeId) {
        formData.append('microsite', props.micrositeId);
      }

      setIsUploading(true);
      try {
        const response = await axios.post(`${APPENV.api.connection}${api}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: localStorage.getItem('feathers-jwt'),
          },
        });

        if (props.api) {
          props.onUpload && props.onUpload(response.data);
        }

        props.onUpload && props.onUpload(response.data.details);
      } catch (e) {}

      setIsUploading(false);
    });
  }, []);

  const handleRemoveClick = (idx: number) => {
    const updatedFiles = [...files];
    updatedFiles.splice(idx, 1);
    setFiles(updatedFiles);
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const rootProps = getRootProps() as any;
  return (
    <Segment textAlign="center" size={'small'} {...rootProps}>
      <input {...getInputProps({ multiple })} />
      {isDragActive ? (
        <p>Drop the files here ... </p>
      ) : (
        <p>Drag 'n' drop some files here, or click to select files</p>
      )}
      <Button
        disabled={isUploading}
        loading={isUploading}
        icon={'upload'}
        content={btnLabel}
        primary
        type="button"
      />
      {showFiles && (
        <>
          <Divider section />
          <div>
            {files.map((f, idx) => (
              <div key={idx}>
                <Button
                  type="button"
                  icon="close"
                  basic
                  size="mini"
                  onClick={(e) => {
                    handleRemoveClick(idx);
                    e.stopPropagation();
                  }}
                />{' '}
                {f.name}{' '}
              </div>
            ))}
          </div>
        </>
      )}
    </Segment>
  );
};

const mapStateToProps = (state) => ({
  tenantId: state.auth.user.business,
});

export const ImageUpload = connect(mapStateToProps, null)(ImageUploadPre);
