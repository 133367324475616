import * as React from 'react';
import { Button, Form, Input, Dropdown, Divider } from 'semantic-ui-react';
import { Formik, Form as FormikForm, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { SemanticField } from '../../../../common/Form';

interface InviteFormProps {
  initialValues: any;
  handleSubmit: Function;
  isSubmitting?: boolean;
  handleCloseClick: any;
}

export const InviteForm: React.FC<InviteFormProps> = (props) => {
  const { handleCloseClick } = props;
  const InventoryProductSchema = Yup.object().shape({
    email: Yup.string().required().email(),
  });

  return (
    <Formik
      initialValues={{ operation: 'RECEIVE', ...props.initialValues }}
      validationSchema={InventoryProductSchema}
      onSubmit={(values, { setSubmitting }) => {
        props.handleSubmit({ ...values });
      }}
    >
      {(props) => {
        const { values, errors, isSubmitting } = props;

        return (
          <FormikForm className={`ui form ${isSubmitting && 'loading'}`}>
            <Form.Field>
              <SemanticField name={'email'} label="Email" component={Form.Input} />
              <ErrorMessage component="div" name="email" />
            </Form.Field>
            <Form.Field>
              <SemanticField name={'firstName'} label="First Name" component={Form.Input} />
              <ErrorMessage component="div" name="firstName" />
            </Form.Field>
            <Form.Field>
              <SemanticField name={'lastName'} label="Last Name" component={Form.Input} />
              <ErrorMessage component="div" name="lastName" />
            </Form.Field>
            <Divider />
            <Form.Field>
              <SemanticField name={'group'} label="Group" component={Form.Input} />
              <ErrorMessage component="div" name="group" />
            </Form.Field>
            <Divider />

            <Button primary type="submit" disabled={isSubmitting}>
              Send Invite
            </Button>
            <Button type="button" disabled={isSubmitting} onClick={handleCloseClick}>
              Cancel
            </Button>
          </FormikForm>
        );
      }}
    </Formik>
  );
};
