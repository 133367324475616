import React, { useEffect, useState } from 'react';

import {
  Heading,
  Icon,
  Stack,
  StackDivider,
  Flex,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Spinner,
  Box,
  Alert,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Badge,
  HStack,
  Divider,
  Tooltip,
  Checkbox,
} from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import type { ModelTypes } from '@inkcloud/icapi-types';
import { FormattedDate, FormattedTime } from 'react-intl';

import { FaRegFile, FaCheckCircle } from 'react-icons/fa';
import type { AttachmentType } from '../../Detail';

import feathers, { icapi } from '../../../../bootstrap/feathers';

import { SegmentCard, TruncateText } from '../../../../common';
import AttachDocModal from '../../common/AttachDocModal';

const round4th = (v: any) => Number.parseFloat(v).toFixed(4);

interface IOrderReviewProps {
  title: string;
  subTitle?: string;
  orderId: string;
  isReadOnly?: boolean;
}

type OrderType = ModelTypes.Orders & {
  customer?: {
    organizationName: string;
    billingClientName: string;
  };
};
const labels = ['Invoice', 'Shipping'];
const baseUrl = `https://storage.googleapis.com/icstorage/`;

function currencyFormat(v, locale = 'en-US', currency = 'USD') {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  }).format(v);
}

export default function OrderReview(props: IOrderReviewProps) {
  const { orderId, title, subTitle, isReadOnly } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState({
    invoice: false,
    billing: false,
    delete: false,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isBillingModalOpen, setIsBillingModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    invoice: '',
    billing: '',
    delete: '',
  });

  const [rfq, setRfq] = useState<ModelTypes.Rfq>({});

  // const [attachments, setAttachments] = useState<{ [key: string]: boolean }>({});
  const [attachments, setAttachments] = useState<NonNullable<ModelTypes.Orders['attachments']>>([]);
  const [selectedAttachments, setSelectedAttachments] = useState<{ [key: string]: boolean }>({});

  // const [resolverShipTo, setResolverShipTo] = useState<ModelTypes.Rfq['shipTo']>({})

  const [order, setOrder] = useState<OrderType>({});

  const [isAttachDocModalOpen, setIsAttachDocModalOpen] = useState(false);
  const [docType, setDocType] = useState('');
  const [isDocUploaded, setIsDocUploaded] = useState(false);

  const loadOrder = async () => {
    setIsLoading(true);
    try {
      const res = (await icapi.service('orders').get(orderId, {
        query: {
          $populate: ['customer', 'attachments.uploadLog'],
        },
      })) as OrderType;

      setOrder(res);

      const att = res?.attachments?.reduce((acc, cur) => {
        acc[cur?._id ?? ''] = false;

        return acc;
      }, {});

      // setAttachments(att as { [key: string]: boolean });
      setAttachments(res?.attachments ?? []);

      if (res.createdFromRfq) {
        const rfqResponse = await icapi.service('rfqs').get(res.createdFromRfq, {
          query: {},
        });

        setRfq(rfqResponse);
      }
    } catch (e) {
      console.error(e);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (orderId) {
      loadOrder();
    }
  }, [orderId]);

  const reviewAmounts: { type: string; description: string; amount: string }[] = [];

  const lines: { description: string; quantity: number; price: number; unitPrice: string }[] = [];
  if (rfq) {
    rfq.estimate?.customerSelectedQuantities?.forEach((quantity, index) => {
      const estimateItem = rfq.estimate?.items?.find((item) => item.item === quantity.item);
      const foundQuantity = estimateItem?.quantities?.find((q) => q.quantity === quantity.quantity);
      const rfqItem = rfq?.items?.find((item) => item._id === quantity.item);

      const qty = foundQuantity?.quantity ?? 0;
      const price = foundQuantity?.subtotalPrice ?? 0;

      lines.push({
        description: rfqItem?.description ?? '',
        quantity: qty,
        price,
        unitPrice: round4th(price / qty),
      });

      estimateItem?.additionalCosts?.forEach((cost) => {
        const foundCostQuantity = cost?.quantities?.find((q) => q.quantity === quantity.quantity);
        const priceAddCost = foundCostQuantity?.amount ?? 0;
        const unitPriceAddCost = priceAddCost / qty;

        if (!cost.description && priceAddCost === 0) {
          return;
        }

        lines.push({
          description: cost?.description ?? '',
          quantity: 1,
          price: priceAddCost,
          unitPrice: round4th(unitPriceAddCost),
        });
      });
    });
  }

  const shipment = order?.actualShipments?.[0] ? order.actualShipments[0] : order.shipment;
  const shipToArr = [
    shipment?.shipToName,
    shipment?.shipToCareOf,
    shipment?.shipToAddress1,
    shipment?.shipToAddress2,
    shipment?.shipToCity,
    shipment?.shipToStateProvince,
    shipment?.shipToPostalCode,
    shipment?.shipToCountryCode,
  ];

  let shipTo = shipToArr?.filter((v) => v).join(' ');

  if (shipTo) {
    const {
      city = '',
      stateProvince,
      postalCode,
      countryCode,
    } = (rfq?.shipTo as NonNullable<ModelTypes.Rfq['shipTo']>) || {};
    shipTo = `${city} ${stateProvince} ${postalCode} ${countryCode}`;
  }

  const handleSend = async () => {
    // force deploy 222
    setIsSubmitting({
      ...isSubmitting,
      invoice: true,
    });
    setErrorMessage({
      ...errorMessage,
      invoice: '',
    });
    try {
      const res = await feathers.service('custom/archway/rfq-payable-email').create({ order });
      setIsModalOpen(false);
      loadOrder();
    } catch (e) {
      const errMsg = e?.code < 500 ? e?.message : 'Something went wrong. Please try again.';
      setErrorMessage({
        ...errorMessage,
        invoice: errMsg,
      });
    }

    setIsSubmitting({
      ...isSubmitting,
      invoice: false,
    });
  };

  const isInvoiceUploaded = attachments?.find((a) => a?.label === 'Invoice');
  const isShippingUploaded = attachments?.find((a) => a?.label === 'Shipping');

  const filteredAttachments = attachments?.filter((a) =>
    labels?.find((l) => l === a.label)
  ) as AttachmentType;

  // eslint-disable-next-line no-undef
  const handleChangeAttachment = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = e.target;

    setSelectedAttachments({
      ...selectedAttachments,
      [id]: checked,
    });
  };

  const handleSendBilling = async () => {
    setIsSubmitting({
      ...isSubmitting,
      billing: true,
    });
    setErrorMessage({
      ...errorMessage,
      billing: '',
    });
    try {
      await feathers.service('custom/archway/rfq-client-billing').create({ order });
      setIsBillingModalOpen(false);
      loadOrder();
    } catch (e) {
      const errMsg = e?.code < 500 ? e?.message : 'Something went wrong. Please try again.';
      setErrorMessage({
        ...errorMessage,
        billing: '',
      });
    }
    setIsSubmitting({
      ...isSubmitting,
      billing: false,
    });
  };

  const handleDeleteAttach = async () => {
    setIsSubmitting({
      ...isSubmitting,
      delete: true,
    });

    setErrorMessage({
      ...errorMessage,
      delete: '',
    });
    const payload = attachments?.reduce((acc, cur) => {
      if (!selectedAttachments[cur._id as string]) {
        acc.push(cur as AttachmentType);
      }

      return acc;
    }, [] as AttachmentType[]);

    try {
      const res = await feathers.service('orders').patch(
        order._id,
        { attachments: payload },
        {
          query: {
            $populate: 'attachments.uploadLog',
          },
        }
      );

      const updatedAttachments = res?.attachments;

      const updatedSelectedAttachments = updatedAttachments?.reduce((acc, cur) => {
        if (!selectedAttachments[cur._id as string]) {
          acc[cur._id as string] = selectedAttachments[cur._id as string];
        }
        return acc;
      }, {});

      setSelectedAttachments(updatedSelectedAttachments);

      setAttachments(updatedAttachments);
      // loadOrder();
    } catch (e) {
      const errMsg = e?.code < 500 ? e?.message : 'Something went wrong. Please try again.';
      setErrorMessage({
        ...errorMessage,
        delete: '',
      });
    }

    setIsSubmitting({
      ...isSubmitting,
      invoice: false,
    });
  };

  const hasAttachSelected =
    Object.keys(selectedAttachments)?.some((a) => selectedAttachments[a]) &&
    attachments?.length > 0;

  return (
    <SegmentCard title={title} subtitle={subTitle}>
      {isLoading ? (
        <Flex justifyContent="center">
          <Spinner />
        </Flex>
      ) : (
        <Stack divider={<StackDivider />}>
          <Flex justifyContent="space-between">
            {/* <Heading as="h3" size="md" textAlign="left">
              Order #{order?.humanId}
            </Heading> */}

            <Box>
              {order.supplierDocsSentToAP && (
                <>
                  <Tooltip
                    label={
                      <>
                        <FormattedDate value={order.supplierDocsSentToAPAt} />{' '}
                        <FormattedTime value={order.supplierDocsSentToAPAt} />
                      </>
                    }
                  >
                    <Badge colorScheme="green">
                      <CheckIcon mr={1} /> Docs Sent to AP
                    </Badge>
                  </Tooltip>
                </>
              )}
              {order.supplierDocsSentToBilling && (
                <>
                  <Tooltip
                    label={
                      <>
                        <FormattedDate value={order.supplierDocsSentToBillingAt} />{' '}
                        <FormattedTime value={order.supplierDocsSentToBillingAt} />
                      </>
                    }
                  >
                    <Badge ml={2} colorScheme="green">
                      <CheckIcon mr={1} /> Docs Sent to Billing
                    </Badge>
                  </Tooltip>
                </>
              )}
            </Box>
          </Flex>
          <div>
            <HStack divider={<Divider />}>
              <Table variant="simple" size={'sm'}>
                <Thead>
                  <Tr>
                    <Th>Supplier Doc</Th>
                    <Th>Status</Th>
                    <Th>Timestamp</Th>
                    <Th></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td>Invoice Document</Td>
                    <Td>
                      <Badge colorScheme={isInvoiceUploaded ? 'green' : 'red'}>
                        {isInvoiceUploaded ? 'Uploaded' : 'Not Uploaded'}
                      </Badge>
                    </Td>
                    <Td>
                      {isInvoiceUploaded && (
                        <>
                          <FormattedDate value={isInvoiceUploaded?.attachedAt} />{' '}
                          <FormattedTime value={isInvoiceUploaded?.attachedAt} />
                        </>
                      )}
                    </Td>
                    <Td>
                      <Button
                        onClick={() => {
                          setDocType('Invoice');
                          setIsDocUploaded(!!isInvoiceUploaded);
                          setIsAttachDocModalOpen(true);
                        }}
                        colorScheme="blue"
                      >
                        {isInvoiceUploaded ? 'Update' : 'Attach'} Document
                      </Button>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Shipping Document</Td>
                    <Td>
                      <Badge colorScheme={isShippingUploaded ? 'green' : 'red'}>
                        {isShippingUploaded ? 'Uploaded' : 'Not Uploaded'}
                      </Badge>
                    </Td>
                    <Td>
                      {isShippingUploaded && (
                        <>
                          <FormattedDate value={isShippingUploaded?.attachedAt} />{' '}
                          <FormattedTime value={isShippingUploaded?.attachedAt} />
                        </>
                      )}
                    </Td>
                    <Td>
                      <Button
                        onClick={() => {
                          setDocType('Shipping');
                          setIsDocUploaded(!!isShippingUploaded);
                          setIsAttachDocModalOpen(true);
                        }}
                        colorScheme="blue"
                      >
                        {isShippingUploaded ? 'Update' : 'Attach'} Document
                      </Button>
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </HStack>
          </div>
          <Box>
            <Button
              isDisabled={!isInvoiceUploaded && !isShippingUploaded}
              onClick={() => setIsModalOpen(true)}
              colorScheme={order.supplierDocsSentToAP ? 'gray' : 'blue'}
            >
              Review Supplier Invoice
            </Button>

            <Button
              mx={4}
              isDisabled={!isInvoiceUploaded && !isShippingUploaded}
              onClick={() => setIsBillingModalOpen(true)}
              colorScheme={order.supplierDocsSentToBilling ? 'gray' : 'blue'}
            >
              Send for Billing
            </Button>
          </Box>
        </Stack>
      )}

      {isModalOpen && (
        <Modal size="lg" isOpen={true} onClose={() => setIsModalOpen(false)}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Supplier Invoice Review</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Table size="sm">
                <Tbody>
                  {filteredAttachments?.map((attachment, index) => (
                    <Tr key={index} verticalAlign="top">
                      <Td w="20px">
                        <Checkbox
                          key={attachment._id}
                          id={attachment?._id}
                          onChange={handleChangeAttachment}
                        ></Checkbox>
                      </Td>

                      <Td>
                        <Text
                          as="a"
                          display="inline-block"
                          fontWeight="bold"
                          href={`${baseUrl}${attachment.uploadLog?.cloudFilename}`}
                          title={attachment?.uploadLog?.originalFilename ?? ''}
                          target="_blank"
                        >
                          <Text noOfLines={1}>
                            <Icon as={FaRegFile} />{' '}
                            {TruncateText(attachment?.uploadLog?.originalFilename ?? '', 20)}
                          </Text>
                        </Text>
                        <Text size="xs" color="gray">
                          <FormattedDate value={attachment?.uploadLog?.createdAt} />{' '}
                          <FormattedTime value={attachment?.uploadLog.createdAt} />
                        </Text>
                      </Td>
                      <Td>
                        <Text fontWeight="bold">{attachment?.label ?? ''}</Text>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
              {/* <Stack divider={<StackDivider />}>
                {filteredAttachments?.map((attachment, index) => (
                  <Box key={index}>
                    <Text fontWeight="bold">{attachment?.label ?? ''}</Text>
                    <Flex alignItems="center">
                      <a href={`${baseUrl}${attachment.uploadLog?.cloudFilename}`} target="_blank">
                        <Icon as={FaRegFile} /> {attachment?.uploadLog?.originalFilename ?? ''}
                      </a>
                    </Flex>
                  </Box>
                ))}
              </Stack> */}

              {errorMessage.invoice ||
                (errorMessage.delete && (
                  <Alert status="error" mt={3}>
                    {errorMessage.invoice || errorMessage.delete}
                  </Alert>
                ))}
            </ModalBody>

            <ModalFooter justifyContent="space-between">
              <Box>
                {hasAttachSelected && (
                  <Button
                    isDisabled={isSubmitting.delete}
                    isLoading={isSubmitting.delete}
                    onClick={handleDeleteAttach}
                  >
                    Delete
                  </Button>
                )}
              </Box>
              <Box>
                <Button mr={3} onClick={() => setIsModalOpen(false)}>
                  Close
                </Button>
                <Button
                  isDisabled={
                    isReadOnly ||
                    isSubmitting.invoice ||
                    (!isInvoiceUploaded && !isShippingUploaded)
                  }
                  isLoading={isSubmitting.invoice}
                  colorScheme="blue"
                  onClick={handleSend}
                >
                  Approve Supplier Invoices
                </Button>
              </Box>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}

      {isBillingModalOpen && (
        <Modal size="2xl" isOpen={true} onClose={() => setIsBillingModalOpen(false)}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Billing Data Preview</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Table variant={'simple'} size={'sm'}>
                <Tbody>
                  <Tr>
                    <Td>Order</Td>
                    <Td>{order?.humanId}</Td>
                  </Tr>
                  <Tr>
                    <Td>Client</Td>
                    <Td>{`${order?.customer?.organizationName ?? ''} ${
                      order?.customer?.billingClientName ?? ''
                    }`}</Td>
                  </Tr>
                  <Tr>
                    <Td>Client PO#</Td>
                    <Td>{order?.po}</Td>
                  </Tr>
                  <Tr>
                    <Td>Ship To</Td>
                    <Td>{shipTo}</Td>
                  </Tr>
                  {/* <Tr>
                    <Td>Date of Shipment</Td>
                    <Td>5/15/2023</Td>
                  </Tr> */}
                </Tbody>
              </Table>
              {lines?.length > 0 ? (
                <Table size={'sm'} mt={8}>
                  <Thead>
                    <Tr>
                      <Th>Description</Th>
                      <Th isNumeric>Billable Price</Th>
                      <Th isNumeric>Quantity</Th>
                      <Th isNumeric>Unit Price</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {lines
                      // .filter((l) => l.description && l.price)
                      .map(({ description, price, unitPrice, quantity }, index) => (
                        <Tr key={index}>
                          <Td>{description}</Td>
                          <Td isNumeric>{currencyFormat(price)}</Td>
                          <Td isNumeric>{quantity}</Td>
                          <Td isNumeric>{currencyFormat(unitPrice)}</Td>
                        </Tr>
                      ))}
                  </Tbody>
                </Table>
              ) : (
                <Box mt={6} p={3} borderRadius="md" borderWidth="1px" bg="gray.50">
                  <Flex alignItems="center" justifyContent="center">
                    <Box textAlign="center">
                      <Icon as={FaRegFile} color="gray" fontSize="3xl" />
                      <Text mt={3}>No lines to bill</Text>
                    </Box>
                  </Flex>
                </Box>
              )}

              {errorMessage.billing && (
                <Alert status="error" mt={3}>
                  {errorMessage.billing}
                </Alert>
              )}
            </ModalBody>

            <ModalFooter>
              <Button mr={3} onClick={() => setIsBillingModalOpen(false)}>
                Close
              </Button>
              <Button
                isDisabled={
                  isReadOnly || isSubmitting.billing || (!isInvoiceUploaded && !isShippingUploaded)
                }
                isLoading={isSubmitting.billing}
                colorScheme="blue"
                onClick={handleSendBilling}
              >
                Send for Billing
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
      {isAttachDocModalOpen && (
        <AttachDocModal
          orderId={orderId}
          docType={docType}
          toUpdate={isDocUploaded}
          onUpdateAttachment={setAttachments}
          attachments={attachments}
          // onReload={() => setIsLoading(true)}
          onClose={() => {
            setDocType('');
            setIsAttachDocModalOpen(false);
          }}
        />
      )}
    </SegmentCard>
  );
}
