import React, { useState } from 'react';

import { useHistory, useParams } from 'react-router-dom';
import {
  Button,

  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,

  Modal,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  Flex,
  Center,
  Spinner,
} from '@chakra-ui/react';

import JobSchedulerJobForm from './JobSchedulerJobForm';
import { Feathers } from '../../../bootstrap/feathers/feathers';

export default function JobSchedulerModal(props) {
  const history = useHistory();

  const { isOpen, onClose } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (v) => {
    setIsSubmitting(true);
    setErrorMessage('');

    try {
      let payload = v;

      if (v.type !== 'offline') {
        const res = await Feathers.service('order-items').find({
          query: {
            humanId: { $LIKE: v?.orderItem },
          },
        });

        payload = {
          orderItem: res?.data?.[0]?._id,
          workflowTemplates: v?.workflowTemplates,
        };
      }
      const res = await Feathers.service('job-scheduler/jobs').create(payload);
      history.push('/job-scheduler');
    } catch (e) {
      const errMsg = e?.code < 500 ? e?.message : 'Something went wrong. Please try again later.';
      setErrorMessage(errMsg);
    }
    setIsSubmitting(false);
  };

  return (
    <Drawer isOpen={isOpen} onClose={onClose} size={'md'}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader>New Job</DrawerHeader>
        <DrawerCloseButton />
        <DrawerBody>
          <JobSchedulerJobForm
            errorMessage={errorMessage}
            isSubmitting={isSubmitting}
            onSubmit={handleSubmit}
          />
        </DrawerBody>

        <DrawerFooter>
          <Button mr={3} onClick={onClose}>
            Close
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
