import * as React from 'react';
import { Icon } from 'semantic-ui-react';
import { connect } from 'react-redux';

import { Languages } from './../../../common';

import { ImageUpload } from './../../products/products/stock-product/StockForm/ImageUpload';

import AttributeSet from './AttributeSet';
import { QuantitySelect } from './QuantitySelect';
import { TurnaroundSelect } from './TurnaroundSelect';
import { AddOnSet } from './AddOnSet';
import { Price } from './Price';
import { NumberOfSets } from './NumberOfSets';
import { DesignSelect } from './DesignSelect';

const baseUrl = `https://storage.googleapis.com/icstorage/`;

function generateResults({ data, selectedAttributes, selectedQuantity }, cb = () => null) {
  const results = data.orderedAttributeTypes.reduce(
    (prev, ctx, index) => {
      // group by current type
      let grouped = prev.products.map((p) => p.attributes[ctx.id]);

      // remove duplicates
      if (grouped.length > 0 && grouped[0] !== undefined) {
        const filteredAttrs = grouped.reduce((groupedAttrs, product) => {
          if (product && product.valueId) {
            if (groupedAttrs.find((x) => x.valueId === product.valueId)) {
              return groupedAttrs;
            }
            const populatedAttribute = data.attributeValueLookup[product.valueId];
            groupedAttrs.push({
              ...product,
              name: populatedAttribute.valueName,
            });
          }
          return groupedAttrs;
        }, []);

        let selectedAttr;
        const foundSelected = selectedAttributes.find((a) => a.attributeType === ctx.id);
        if (foundSelected) {
          const foundInner = filteredAttrs.find((a) => a.valueId === foundSelected.value);
          if (foundInner) {
            selectedAttr = foundInner;
          } else {
            selectedAttr = filteredAttrs[0];
          }
        } else {
          selectedAttr = filteredAttrs[0];
        }

        prev.selected.push(selectedAttr);
        prev.attributes.push({
          attributeTypeId: ctx.id,
          attributeType: ctx,
          attributes: filteredAttrs,
          selected: selectedAttr,
        });

        if (true /*index > 0*/) {
          prev.products = prev.products.filter((p) => {
            if (p.attributes[ctx.id] && p.attributes[ctx.id].valueId) {
              return p.attributes[ctx.id].valueId === selectedAttr.valueId;
            }
          });
        }
      }

      return prev;
    },
    {
      selected: [],
      attributes: [],
      products: data.products,
      attributeTypes: data.orderedAttributeTypes,
    }
  );

  results.selectedProduct = results.products[0];

  if (!results.selectedProduct) {
    return results;
  }
  // next bit of logic is expecting an array. Putting it here to be safe.
  if (!results.selectedProduct.priceRows) {
    results.selectedProduct.priceRows = [];
  }
  const quantities = results.selectedProduct.priceRows.map((p) => ({
    value: `${p.value}`,
    label: `${p.value}`,
  }));
  results.quantities = quantities;
  if (!selectedQuantity || !quantities.find((q) => q.value === selectedQuantity.value)) {
    if (quantities[0]) {
      const qtyString = `${quantities[0].value}`;
      selectedQuantity = { value: qtyString, label: qtyString };
    }

    if (!selectedQuantity && results.selectedProduct.priceRows.length === 0) {
      if (selectedQuantity === null) {
        selectedQuantity = 1;
      }
    }
  }

  results.selectedQuantity = selectedQuantity;

  return results;
}

export interface ConnectedProps {
  user: any;
  tenantDomainName: any;
}

export interface CalculatorProps /* extends InjectedIntlProps */ {
  category: string;
  tenant?: string;
  // feathers: any;
  isCustomTrimEnabled?: boolean;
  data: any;
  onView?: (values: any) => void;
  onAdd?: (values: any) => void;
  isTranslationSite: boolean;
}
export interface CalculatorState {
  // data: any;
  results: any;
  selectedAttributes: { attributeType: string; value: string }[];
  selectedQuantity: { value: string; label: string };
  selectedTurnaround: { value: string; label: string };
  selectedAddOns: any;
  customTrimChecked: boolean;
  customTrimWidth: number;
  customTrimHeight: number;
  numOfSets: number;
  wantDesign: { value: number; label: string };
  destinationLanguage: { value: string; text: string };
  sourceLanguage: { value: string; text: string };
  files: any[];
}

export class CalculatorPre extends React.Component<
  CalculatorProps & ConnectedProps,
  CalculatorState
> {
  constructor(props: CalculatorProps & ConnectedProps) {
    super(props);

    const isBadger = props.user.business === '534e57d124f90511218b4567';

    this.state = {
      // data: null,
      results: null,
      selectedAttributes: [],
      selectedQuantity: null,
      selectedTurnaround: null,
      selectedAddOns: null,
      customTrimChecked: false,
      customTrimWidth: 0.0,
      customTrimHeight: 0.0,
      numOfSets: 1,
      wantDesign: isBadger ? { value: 1, label: 'Wants Design' } : { value: 0, label: 'No Design' },
      destinationLanguage: { value: '', text: '' },
      sourceLanguage: { value: '', text: '' },
      files: [],
    };
  }

  componentDidUpdate(prevProps: CalculatorProps, prevState) {
    if (prevProps.data !== this.props.data) {
      this.dispatchGenerate(() => {
        this.props.onView &&
          this.props.onView({
            product: this.state.results.products[0].id,
            default: true,
          });
      });
    }
  }

  dispatchGenerate = (cb = () => null) => {
    const results = generateResults({
      data: this.props.data,
      selectedAttributes: this.state.selectedAttributes,
      selectedQuantity: this.state.selectedQuantity,
    });

    // if outside of params, update width and height.
    const foundSize = results.selected.find((a) => a.eaId === 'SIZE');
    let customMax = {};

    if (foundSize) {
      const { customTrimWidth, customTrimHeight } = this.state;

      const isLarger = customTrimWidth > foundSize.width || customTrimHeight > foundSize.height;
      const isSmaller = customTrimWidth < 1.5 || customTrimHeight < 1.5;

      if (isLarger || isSmaller) {
        customMax = { customTrimWidth: foundSize.width, customTrimHeight: foundSize.height };
      }
    }

    // let wantsDesign = false;
    // if (results.selectedProduct && result.selectedProduct.) {

    // }

    this.setState({ ...this.state, results, ...customMax }, () => cb());
  };

  populateAttribute(id) {
    return this.props.data.attributeValueLookup[id];
  }

  getDefaultSelect(attributeType: string) {
    return Object.keys(this.props.data.attributeValueLookup)
      .map((e) => this.props.data.attributeValueLookup[e])
      .filter((a) => a.eaId === attributeType)
      .sort((a, b) => a.priority - b.priority)
      .shift();
  }

  handleAttributeChange = ({ attributeType, value }) => {
    let updatedSelectedAttributes = [...this.state.selectedAttributes];
    if (this.state.selectedAttributes.find((a) => a.attributeType === attributeType)) {
      updatedSelectedAttributes = this.state.selectedAttributes.map((a) => {
        if (a.attributeType === attributeType) {
          return { attributeType, value };
        }

        return a;
      });
    } else {
      updatedSelectedAttributes.push({ attributeType, value });
    }

    this.setState(
      {
        ...this.state,
        selectedAttributes: updatedSelectedAttributes,
      },
      () => this.dispatchGenerate()
    );
  };

  handleCustomTrimChange = ({ width, height, checked }) => {
    this.setState({
      ...this.state,
      customTrimChecked: checked,
      customTrimWidth: width,
      customTrimHeight: height,
    });
  };

  handleQuantityChange = (value) => {
    this.setState(
      {
        ...this.state,
        selectedQuantity: value,
      },
      () => this.dispatchGenerate()
    );
  };

  handleTurnaroundChange = (value) => {
    this.setState(
      {
        ...this.state,
        selectedTurnaround: value,
      },
      () => this.dispatchGenerate()
    );
  };

  handleDesignChange = (value) => {
    this.setState(
      {
        ...this.state,
        wantDesign: value,
      },
      () => this.dispatchGenerate()
    );
  };

  handleNumSetsChange = (value) => {
    this.setState({
      ...this.state,
      numOfSets: value,
    });
  };

  getStandardTurnaroundOptions(product) {
    if (!product.timeMin && !product.timeMax) {
      return { label: `Standard Turnaround`, value: 'standard' };
    }

    let str;
    if (product.timeMin === product.timeMax) {
      const pluralized = product.timeMin === 1 ? 'day' : 'days';
      str = `${product.timeMin} ${pluralized}`;
    } else {
      const pluralized = product.timeMax === 1 ? 'day' : 'days';
      str = `${product.timeMin} - ${product.timeMax} ${pluralized}`;
    }
    return { label: `Standard - ${str} `, value: 'standard' };
  }

  handleLanguageChange = (data: any) => {
    const foundLang = data.options.find((o) => o.value === data.value);

    this.setState({
      ...this.state,
      [data.name]: foundLang,
    });
  };

  handleFileschange = (file: any) => {
    const newFiles = this.state.files.concat(file);

    this.setState({
      ...this.state,
      files: newFiles,
    });
  };

  handleRemoveFile = (uploadLog: string) => {
    const filteredFiles = this.state.files.filter((file) => file.uploadLog !== uploadLog);
    this.setState({
      ...this.state,
      files: filteredFiles,
    });
  };

  handleAddOnChange = (values) => {
    this.setState({
      ...this.state,
      selectedAddOns: values,
    });
  };

  handleAddToCart = () => {
    const { selectedAddOns } = this.state;
    const mapAddOns =
      selectedAddOns &&
      Object.keys(selectedAddOns).map((a, index) => ({ [a]: selectedAddOns[a].value }));

    const mapFiles = this.state.files?.reduce((acc, file) => {
      if (file) {
        acc.push(file.uploadLog);
      }

      return acc;
    }, []);

    const filteredMeta = [
      {
        key: 'tr_source_name',
        value: this.state.sourceLanguage.text,
      },
      {
        key: 'tr_source_id',
        value: this.state.sourceLanguage.value,
      },
      {
        key: 'tr_dest_name',
        value: this.state.destinationLanguage.text,
      },
      {
        key: 'tr_dest_id',
        value: this.state.destinationLanguage.value,
      },
    ].filter((meta) => meta.value);

    const payload = {
      categoryId: this.props.category,
      productId: this.state.results.selectedProduct.id,
      quantity: this.state.results.selectedQuantity,
      isCustomTrim: this.state.customTrimChecked,
      customTrimWidth: this.state.customTrimWidth,
      customTrimHeight: this.state.customTrimHeight,
      turnaround: this.state.selectedTurnaround,
      addOns: mapAddOns,
      wantDesign: this.state.wantDesign.value,
      numSets: this.state.numOfSets,
      miscUploads: mapFiles,
      metadata: filteredMeta,
    };

    this.props.onAdd(payload);
  };
  public render() {
    let price = 0;
    let turnaroundOptions = [];
    let turnaroundDefault;

    if (!this.state.results || !this.state.results.products.length) {
      return null;
    }

    const product = this.state.results.selectedProduct;
    const selectedQuantity = this.state.results.selectedQuantity;
    const quantities = this.state.results.quantities;
    turnaroundDefault =
      this.state.selectedTurnaround ||
      this.getStandardTurnaroundOptions(this.state.results.selectedProduct);
    turnaroundOptions = [this.getStandardTurnaroundOptions(this.state.results.selectedProduct)];
    if (this.state.results.selectedProduct.isRushEnabled) {
      turnaroundOptions.push({ label: 'Next Day', value: 'next-day' });
    }

    const foundPriceRow = this.state.results.selectedProduct.priceRows.find(
      (p) => `${p.value}` === selectedQuantity.value
    );
    if (foundPriceRow) {
      price = foundPriceRow.price;
      if (turnaroundDefault.value === 'next-day') {
        price = price * 1.5;
      }
    }

    const hasCustomSizeError =
      this.state.customTrimChecked &&
      (!Number(this.state.customTrimWidth) || !Number(this.state.customTrimHeight));

    return (
      <div>
        {/* { this.state.results && JSON.stringify(this.state.results.products)} */}
        {this.state.results && (
          <AttributeSet
            attributes={this.state.results.attributes}
            onChange={this.handleAttributeChange}
            onCustomTrimChange={this.handleCustomTrimChange}
            customTrimChecked={this.state.customTrimChecked}
            customTrimWidth={this.state.customTrimWidth}
            customTrimHeight={this.state.customTrimHeight}
          />
        )}
        <QuantitySelect
          options={quantities}
          selected={selectedQuantity}
          onChange={this.handleQuantityChange}
        />
        {this.props.isTranslationSite ? (
          <>
            <br />
            <Languages onChange={this.handleLanguageChange} />
            <br />
            <ImageUpload
              onUpload={(v) => {
                if (v) {
                  const f = v.files?.files?.[0];
                  this.handleFileschange(f);
                }
              }}
              showFiles={false}
              api="/uploads-misc"
            />

            {this.state.files?.map((file: any, index: number) => (
              <p key={index}>
                <a href={`${baseUrl}${file.filename}`} target="_blank">
                  <Icon name="file alternate outline" />
                  {file.originalFilename}
                </a>{' '}
                <Icon name="close" onClick={() => this.handleRemoveFile(file.uploadLog)} />
              </p>
            ))}
          </>
        ) : (
          <>
            <AddOnSet
              addOnSettings={product.addOnSettings}
              onChange={this.handleAddOnChange}
              addOns={this.props.data.addOns}
              selectedAddOns={this.state.selectedAddOns}
            />
            <DesignSelect onChange={this.handleDesignChange} selected={this.state.wantDesign} />
            <TurnaroundSelect
              options={turnaroundOptions}
              selected={turnaroundDefault}
              onChange={this.handleTurnaroundChange}
            />
            <NumberOfSets numSets={this.state.numOfSets} onChange={this.handleNumSetsChange} />
          </>
        )}
        {/* <div>num sets {this.state.numOfSets}</div> */}
        <hr />
        <Price
          product={product}
          wantsDesign={this.state.wantDesign}
          selectedTurnaround={turnaroundDefault}
          selectedQuantity={selectedQuantity}
          selectedAddOns={this.state.selectedAddOns}
          addOns={this.props.data.addOns}
        />
        <div>
          {selectedQuantity && (
            <button
              className="ui button primary"
              onClick={this.handleAddToCart}
              disabled={hasCustomSizeError}
            >
              Add
            </button>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state): ConnectedProps => ({
  user: state.auth.user || {},
  tenantDomainName: state.auth.domainName,
});

const mapDispatchToProps = null;

export const Calculator = connect<ConnectedProps, any, CalculatorProps>(
  mapStateToProps,
  mapDispatchToProps
)(CalculatorPre as any);
