import * as React from 'react';
import { Modal, Button, Message, Form, Tab } from 'semantic-ui-react';
import Feathers from '../../../bootstrap/feathers';

import brace from 'brace';
import AceEditor from 'react-ace';
import 'brace/mode/html';
import 'brace/theme/monokai';

interface CustomThemeFormProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (v: any) => void;
  initialValue: any;
  isSubmitting: boolean;
  errorMessage: string;
  responseMessage: string;
}

const { useState, useEffect } = React;

export const CustomThemeForm: React.FunctionComponent<CustomThemeFormProps> = (props) => {
  const { isOpen, onClose, onSubmit, isSubmitting, errorMessage, responseMessage, initialValue } =
    props;

  const [values, setValues] = useState({
    css: initialValue?.customCSS || '',
    js: initialValue?.customJS || '',
    html: initialValue?.customHTML || '',
  });

  const handleChange = (data: any) => {
    setValues({ ...values, ...data });
  };

  const panes = [
    {
      menuItem: 'Custom CSS',
      render: () => (
        <Tab.Pane key="css">
          <AceEditor
            mode="css"
            theme="monokai"
            onChange={(v) => handleChange({ css: v })}
            name="css"
            editorProps={{ $blockScrolling: true }}
            value={values.css}
            width={'100%'}
            showGutter={false}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Custom JS',
      render: () => (
        <Tab.Pane key="js">
          <AceEditor
            mode="javascript"
            theme="monokai"
            onChange={(v) => handleChange({ js: v })}
            name="js"
            editorProps={{ $blockScrolling: true }}
            value={values.js}
            width={'100%'}
            showGutter={false}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Custom Head HTML',
      render: () => (
        <Tab.Pane key="html">
          <AceEditor
            mode="html"
            theme="monokai"
            onChange={(v) => handleChange({ html: v })}
            name="html"
            editorProps={{ $blockScrolling: true }}
            value={values.html}
            width={'100%'}
            showGutter={false}
          />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <>
      <Modal open={isOpen} onClose={onClose} closeIcon>
        <Modal.Header>Custom Theme</Modal.Header>
        <Modal.Content>
          <Form
            className={`${isSubmitting ? 'loading' : ''} ${responseMessage ? 'success' : ''} ${
              errorMessage ? 'error' : ''
            }`}
          >
            <Tab panes={panes} />
            {errorMessage && <Message error content={errorMessage} />}
            {responseMessage && <Message success content={responseMessage} />}
            <br />
            <Button
              primary
              content="Save"
              disabled={isSubmitting}
              onClick={() => onSubmit(values)}
            />
          </Form>
        </Modal.Content>
      </Modal>
    </>
  );
};
