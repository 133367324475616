import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Alert,
  Text,
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from '@chakra-ui/react';
import type { ModelTypes } from '@inkcloud/icapi-types';

import { icapi } from '../../bootstrap/feathers';

interface ISendModalProps {
  open?: boolean;
  onClose: () => void;
  onReload?: () => void;
  rfqId: string;
  suppliersId?: string[] | undefined;
  vendor?: { _id: string; name: string };
}

export const SendModal: React.FunctionComponent<ISendModalProps> = (props) => {
  const { onClose, onReload, rfqId, vendor, suppliersId } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [users, setUsers] = useState<ModelTypes.User[]>([]);

  useEffect(() => {
    if (suppliersId?.length) {
      const loadSuppliersUsers = async () => {
        try {
          const res = (await icapi.service('users').find({
            query: {
              vendor: suppliersId,
            },
          })) as { data: ModelTypes.User[] };

          setUsers(res?.data ?? []);
        } catch (e) {
          console.error(e);
        }
      };

      loadSuppliersUsers();
    }
  }, []);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    setErrorMessage('');
    try {
      await (icapi.service('rfqs/sends') as any).create({
        rfqId,
        vendors: vendor ? [vendor?._id] : undefined,
      });

      onReload?.();
      onClose();
    } catch (e) {
      const errMsg = e?.code < 500 ? e?.message : 'An error occurred';

      setErrorMessage(errMsg);
    }

    setIsSubmitting(false);
  };
  return (
    <Modal isOpen onClose={onClose} size={'xl'}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Send RFQ</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {users?.length > 0 && (
            <Box mb={3} p={3} borderWidth="1px" borderRadius="md">
              <Table variant="simple" size="sm">
                <Thead>
                  <Tr>
                    <Th>Name</Th>
                    <Th>Email</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {users.map((user) => (
                    <Tr key={user._id}>
                      <Td>
                        {user?.firstName ?? ''} {user?.lastName ?? ''}
                      </Td>
                      <Td>{user?.email ?? ''}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Box>
          )}
          <Text>
            Do you want to send RFQ to {vendor?.name ?? "all participating supplier's users"}?
          </Text>
          {errorMessage && <Alert status="error">{errorMessage}</Alert>}
        </ModalBody>

        <ModalFooter>
          <Button
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
            colorScheme="blue"
            onClick={handleSubmit}
          >
            Send
          </Button>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
