import * as React from 'react';
import { Button, Form, Input, Dropdown, Message } from 'semantic-ui-react';
import { Formik, Form as FormikForm, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { SemanticField } from '../../../common/Form';
import { slugify2 as slugifyKey } from '../../../common';

interface VariantTypeFormProps {
  initialValues: any;
  onSubmit: Function;
  isSubmitting?: boolean;
  errorMessage: string;
}

const sortByOptions = [
  { value: 'name', text: 'Name' },
  { value: 'priority', text: 'Priority' },
];

export const VariantTypeForm: React.FC<VariantTypeFormProps> = (props) => {
  const { errorMessage } = props;

  const CustomerContactSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
  });

  return (
    <Formik
      initialValues={props.initialValues}
      validationSchema={CustomerContactSchema}
      onSubmit={(values, { setSubmitting }) => {
        props.onSubmit(values);
      }}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
          setFieldValue,
          setFieldTouched,
        } = props;

        const handleSlugify = (v) => setFieldValue('key', slugifyKey(v));

        return (
          <FormikForm
            className={`ui form ${isSubmitting && !errorMessage ? 'loading' : ''} ${
              errorMessage ? 'error' : ''
            }`}
          >
            <Form.Group widths="equal">
              <Form.Field>
                <SemanticField
                  name={'name'}
                  label="Name"
                  component={Form.Input}
                  onChange={(v) => {
                    if (!values?._id) {
                      setFieldValue('key', slugifyKey(v));
                    }
                  }}
                />
                <ErrorMessage
                  render={(msg) => <Message negative size="tiny" content={msg} />}
                  name="name"
                />
              </Form.Field>
              <Form.Field>
                <SemanticField
                  name={'key'}
                  label="Key"
                  component={Form.Input}
                  onChange={handleSlugify}
                />
                <ErrorMessage
                  render={(msg) => <Message negative size="tiny" content={msg} />}
                  name="key"
                />
              </Form.Field>
              <Form.Field>
                <SemanticField name={'priority'} label="Priority" component={Form.Input} />
                <ErrorMessage component="div" name="priority" />
              </Form.Field>
              <Form.Field>
                <SemanticField
                  fluid
                  selection
                  name={'valueSortBy'}
                  label="Sort By"
                  options={sortByOptions}
                  component={Form.Dropdown}
                />
              </Form.Field>
            </Form.Group>

            {errorMessage && <Message error content={errorMessage} />}

            <Button primary type="submit" disabled={isSubmitting && !errorMessage}>
              Save
            </Button>
          </FormikForm>
        );
      }}
    </Formik>
  );
};
