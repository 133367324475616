import * as React from 'react';
import { RouteComponentProps, withRouter, Link, Switch, Route } from 'react-router-dom';
import { DetailWrapper, DetailWrappedProps } from '../../bootstrap/DetailWrapper';
import { FormattedDate, FormattedNumber } from 'react-intl';
import {
  InjectedFormProps,
  Field,
  reduxForm,
  change,
  formValueSelector,
  FormProps,
} from 'redux-form';
import { services } from '../../bootstrap/feathers';

import { Header, Segment } from 'semantic-ui-react';
// import PortalFormCompoment from './MicrositeFormComponent';
import PortalProductFormComponent from './MicrositeProductFormComponent';

type PortalProductEditProps = DetailWrappedProps & RouteComponentProps<any> & InjectedFormProps;

interface PortalProductEditState {}

class PortalProductEditComponent extends React.Component<
  PortalProductEditProps,
  PortalProductEditState
> {
  productsDropdown = null;
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if (this.props.match && this.props.match.params.itemId) {
      this.props.getPortalProduct(this.props.match.params.itemId);
    }
  }

  handleSubmit = (values) => {
    values.XMPieProducts = values.XMPieProducts.map((p) => ({ XMPieProductId: `${p}` }));
    this.props.patchPortalProduct(this.props.portalProduct._id, values).then((result) => {
      this.props.history.push(`/settings/portals/${this.props.match.params.id}`);
    });
  };

  render() {
    const { handleSubmit, pristine, reset, submitting } = this.props;
    const serviceParams = {
      query: {
        portal: this.props.match.params.id,
        product: this.props.match.params.itemId,
      },
    };

    const portalProduct = { ...this.props.portalProduct };
    if (!this.props.portalProduct) {
      return null;
    }
    if (this.props.portalProduct) {
      portalProduct.XMPieProducts = portalProduct.XMPieProducts.map((p) => p.XMPieProductId).filter(
        (p) => p
      );
    }

    return (
      <div>
        <h2 className="ui dividing header">Portal Product Edit</h2>
        {this.props.data ? (
          <PortalProductFormComponent
            initialValues={portalProduct}
            storeId={this.props.data.XMPieStore}
            onSubmit={this.handleSubmit}
          />
        ) : null}
      </div>
    );
  }
}

export default DetailWrapper(PortalProductEditComponent, 'tenants/portals', {
  submitRedirect: '/settings/portals',
  mapStateToProps: (state) => ({
    portalProduct: state['products-legacy/portal-products'].data,
  }),
  mapDispatchToProps: {
    getPortalProduct: services['products-legacy/portal-products'].get,
    patchPortalProduct: services['products-legacy/portal-products'].patch,
  },
});
