import { Operator, generateOperatorOptions } from '@inkcloud/components';
import Feathers from '../../../bootstrap/feathers';

export async function getFilterDefinition() {
  let definition = [
    {
      label: 'Date:Created',
      value: 'createdAt',
      operators: generateOperatorOptions([Operator.Between]),
      type: 'date',
    },
    {
      label: 'Date:Estimated Completion Date',
      value: 'estimatedCompletionDate',
      operators: generateOperatorOptions([Operator.Between]),
      type: 'date',
    },
    // {
    //   label: 'Date:Arrive By',
    //   value: 'arriveBy',
    //   operators: generateOperatorOptions([Operator.Between]),
    //   type: 'date',
    // },
    {
      label: 'Customer Reference',
      value: 'customerJobName',
      operators: generateOperatorOptions([Operator.Like, Operator.Is, Operator.IsNot]),
    },
    {
      label: 'ID',
      value: 'humanId',
      operators: generateOperatorOptions([Operator.Like, Operator.Is]),
    },

    {
      label: 'Production Status',
      value: 'productionStatus.jobStatus',
      operators: generateOperatorOptions([Operator.Is, Operator.IsNot]),
      valueOptions: [],
      asyncValueOptions: async () =>
        Feathers.service('order-item-statuses')
          .find({
            query: {
              $np: '1',
              $sort: { name: 1 },
            },
          })
          .then((results) => results.map((r) => ({ value: r._id, label: r.name }))),
      type: 'multi-select',
    },

    {
      label: 'Quantity',
      value: 'quantity',
      operators: generateOperatorOptions([Operator.Is]),
      valueOptions: [],
      type: 'multi-select-create',
    },
  ];

  definition = definition.sort((a: any, b: any) => a.label.localeCompare(b.label));

  return definition;
}
