import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { PageHeaderRow, PreLoader, useFeathers } from '@inkcloud/components';
import { connect } from 'react-redux';
import { CSVLink } from 'react-csv';
// force deploy
import {
  Badge,
  Box,
  Button,
  Flex,
  Heading,
  Text,
  Spacer,
  IconButton,
  Card,
  Menu,
  MenuList,
  MenuButton,
  MenuItem,
  MenuDivider,
  Table as ChakraTable,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Tfoot,
  TableContainer,
  ButtonGroup,
  Tooltip,
  Alert,
  Tag,
  HStack,
  Stack,
  Divider,
  Textarea,
  Checkbox,
  RadioGroup,
  Radio,
  Switch,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';

import { DeleteIcon, EditIcon } from '@chakra-ui/icons';

import type { ServiceTypes, ModelTypes } from '@inkcloud/icapi-types';

import { FaEllipsisV, FaPrint, FaRegEnvelope, FaCopy } from 'react-icons/fa';

import { FormattedDateHover } from '../../common';

import feathers, { icapi } from '../../bootstrap/feathers';
import { currencyFormat } from './helpers';
import { IndexWrappedProps } from '../../bootstrap/IndexWrapper';

import { connectOrderItemModals } from '../../common/OrderItemCard2';
import type { ItemsType } from './SupplierDetail';
import SupplierChangeModal from './SupplierChangeModal';
import ReviewModal from './ReviewModal';
import Wizard from './Wizard';

type ICEntity<T extends keyof ServiceTypes> = Awaited<ReturnType<ServiceTypes[T]['get']>>;

// const location: ModelTypes.InventoryLocations

// export type Location = ICEntity<''>
export type Product = ICEntity<'products-legacy'>;
export type Receiving = ICEntity<'purchase-orders/receivings'>;
export type PurchaseOrders = ICEntity<'purchase-orders'> & {
  receiving: Receiving;
  notes: string;
  // items: {
  //   location: Location
  // }[]
};

type PopulatedVendorBillingReconciliations = ModelTypes.VendorBillingReconciliations &
  Omit<ModelTypes.VendorBillingReconciliations, 'items'> & {
    vendor: ModelTypes.ProductionVendors;
    items: Array<
      NonNullable<ModelTypes.VendorBillingReconciliations['items']>[number] & {
        changeExplanation: string;
        changeAmount: number;
      }
    >;
  };

export type itemsType = Array<
  NonNullable<ModelTypes.VendorBillingReconciliations['items']>[number] & {
    isDeleted: boolean;
  }
>;

export interface ConnectedProps {
  user?: any;
}

interface IDetailProps {}

export type AttachmentType = Array<
  NonNullable<ModelTypes.Rfq['attachments']>[number] & {
    uploadLog: ModelTypes.UploadLog;
  }
>;
// type itemType = itemsType[number] &
//   Omit<ItemsType[number], 'amount'> & {
//     amount: number | string;
//   };

function csvData(items: itemsType) {
  const data = items.map((item) => ({
    order: item?.orderHumanId || '-',
    item: item?.orderItemHumanId || '-',
    description:
      item?.type === 'item' ? (item as any)?.orderItem?.productKey : (item as any)?.description,
    qty: (item as any)?.orderItem?.quantity || '-',
    amount: currencyFormat(item.amount) as any,
    changeAmount: item?.changeAmount !== undefined ? currencyFormat(item?.changeAmount) : '-',
    explanation: (item as any)?.changeExplanation || '-',
  }));

  const total = items?.reduce((acc, item) => acc + (item?.amount ?? 0.0), 0);
  const totalChange = items?.reduce(
    (acc, item) =>
      acc + ((item?.changeAmount !== undefined ? item.changeAmount : item?.amount) ?? 0.0),
    0
  );

  data.push({
    order: 'Total',
    item: '',
    description: '',
    qty: '',
    amount: total ? (currencyFormat(total) as any) : undefined,
    changeAmount: totalChange ? currencyFormat(totalChange) : '',
    explanation: '',
  });
  return data;
}

const headers = [
  { label: 'Order', key: 'order' },
  { label: 'Type', key: 'type' },
  { label: 'Item', key: 'item' },
  { label: 'Description', key: 'description' },
  { label: 'Quantity', key: 'qty' },
  { label: 'Amount', key: 'amount' },
  { label: 'Change Amount', key: 'changeAmount' },
  { label: 'Explanation', key: 'explanation' },
];

const serviceName = `/vendor-billing-reconcillations`;
const pathPrefix = `/supplier-billing-reconciliation`;
const service = feathers.service(serviceName);
const commonParams = {
  query: {
    $populate: [
      'vendor',
      'items.orderItem',
      // 'items.product',
      // 'receivings.receivedBy',
      // 'receivings.items.product',
      // 'receivings.items.location',
      // 'privateNotes.createdBy',
    ],
  },
};

const { useState } = React;

const baseUrl = `https://storage.googleapis.com/icstorage/`;

const PreDetail: React.FunctionComponent<
  IDetailProps & ConnectedProps & RouteComponentProps<{ id?: string }>
> = (props) => {
  const {
    match: {
      params: { id },
    },
    history,
    user,
  } = props;

  const isEdit = !!id;
  // const [state, reload] = useFeathers('get', serviceName, [id, commonParams], true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isOpenModal, setIsOpenModal] = useState(false);

  const [data, setData] = useState<PopulatedVendorBillingReconciliations | null>(null);
  const [attachments, setAttachments] = useState<AttachmentType>([]);
  const [items, setItems] = useState<itemsType>([]);

  const [isReject, setIsReject] = useState(false);
  const [rejectionReason, setRejectionReason] = useState('');

  const [isChangeModalOpen, setIsChangeModalOpen] = useState(false);
  const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<itemsType[number]>();

  const [isFilteredChangesOnly, setIsFilteredChangesOnly] = useState(false);

  const loadData = async () => {
    try {
      const res = await feathers.service('vendor-billing-reconcillations').get(id, {
        query: {
          $populate: ['vendor', 'items.orderItem', 'attachments.uploadLog'],
        },
      });
      setData(res);
      setAttachments(res?.attachments);
      setRejectionReason(res?.rejectionReason);
      setIsReject(!!res?.rejectionReason);
      setItems(res?.items);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  const handleSave = async (redirect = false) => {
    setErrorMessage('');
    setIsSubmitting(true);
    try {
      await feathers.service('vendor-billing-reconcillations/approve').create({
        vendorBillingReconciliation: id,
        items,
        isReject,
        rejectionReason: isReject ? rejectionReason : undefined,
      });
      if (redirect) {
        history.push(pathPrefix);
      }

      setIsReviewModalOpen(false);
    } catch (e) {
      const errMsg =
        e?.code < 500 ? e.message : 'We are experiencing technical difficulties. Please try again';
      setErrorMessage(errMsg);
    }
    setIsSubmitting(false);
  };

  const handleApprovalResponse = async (response, reason, redirect = false) => {
    setErrorMessage('');
    setIsSubmitting(true);
    try {
      await feathers.service('vendor-billing-reconcillations/approve').create({
        vendorBillingReconciliation: id,
        items,
        response,
        rejectionReason: response ? reason : undefined,
      });

      history.push(pathPrefix);
      setIsReviewModalOpen(false);
    } catch (e) {
      const errMsg =
        e?.code < 500 ? e.message : 'We are experiencing technical difficulties. Please try again';
      setErrorMessage(errMsg);
    }
    setIsSubmitting(false);
  };

  const handleSendToBilling = async () => {
    setErrorMessage('');
    setIsSubmitting(true);
    try {
      await feathers.service('vendor-billing-reconcillations/transfer').create({
        vendorBillingReconciliation: id,
      });

      history.push(pathPrefix);

      setIsReviewModalOpen(false);
    } catch (e) {
      const errMsg =
        e?.code < 500 ? e.message : 'We are experiencing technical difficulties. Please try again';
      setErrorMessage(errMsg);
    }
    setIsSubmitting(false);
  };

  const handleChange = (item: NonNullable<itemsType>[number]) => {
    const newItems = items?.map((i) => (i._id === item._id ? item : i));
    setItems(newItems);
  };
  // const handleReject = async (values) => {
  //   setErrorMessage('');
  //   try {
  //     await feathers.service('vendor-billing-reconcillations/approve').create({
  //       vendorBillingReconciliation: id,
  //       isReject: true,
  //       rejectionReason,
  //     });

  //     history.push(pathPrefix);
  //   } catch (e) {
  //     const errMsg =
  //       e?.code < 500 ? e.message : 'We are experiencing technical difficulties. Please try again';
  //     setErrorMessage(errMsg);
  //   }
  // };

  // const pageTitle = isEdit ? 'Edit' : 'New';

  if (isEdit && !data) {
    return <PreLoader />;
  }

  // const data: PopulatedVendorBillingReconciliations = { ...state.data };

  const handleToggleItems = (e) => {
    const { checked } = e.target;

    const newItems = items?.map((item) => ({ ...item, isDeleted: checked }));
    setItems(newItems);
  };

  const handleToggleItem = (item) => (e) => {
    const { checked } = e.target;

    const newItems = items?.map((i) => (i?._id === item?._id ? { ...i, isDeleted: checked } : i));

    setItems(newItems);
  };
  // console.log('items', items);

  const allSelected = items?.length > 0 && items?.every((item) => item?.isDeleted);

  let statusColor = 'orange';
  if ((data as any)?.status === 'complete') {
    statusColor = 'green';
  }

  const total = items
    ?.filter((item) => !isFilteredChangesOnly || item?.changeAmount !== undefined)
    ?.reduce((acc, item) => acc + (item?.amount ?? 0.0), 0);

  const totalChange = items
    ?.filter((item) => !isFilteredChangesOnly || item?.changeAmount !== undefined)
    ?.reduce(
      (acc, item) =>
        acc + ((item?.changeAmount !== undefined ? item.changeAmount : item?.amount) ?? 0.0),
      0
    );

  const sellAmountTotal = items
    ?.filter((item) => !isFilteredChangesOnly || item?.changeAmount !== undefined)
    ?.reduce((acc, item) => acc + (item?.billingAmount ?? 0.0), 0);

  const hasChanges = items?.some((item) => item?.changeAmount !== undefined);

  const invoiceDoc = attachments?.find((a) => a?.label === 'Invoice');

  const hasInvoiceDoc = !!invoiceDoc;

  const pdfUrl = `${baseUrl}${invoiceDoc?.uploadLog?.cloudFilename}`;

  const handleArchive = async () => {
    await (icapi.service('vendor-billing-reconcillations') as any).patch(data?._id, {
      status: 'billing-complete',
    });

    loadData();
  };

  return (
    <Box>
      <Flex mb={16} alignItems={'center'}>
        <Box>
          <Heading fontWeight={'normal'} size="xl">
            Billing Reconciliation
          </Heading>
        </Box>
        <Badge ml={4} colorScheme={statusColor} fontSize={'large'}>
          {data?.humanId ? data?.status ?? 'Draft' : ''}
        </Badge>
        <Spacer />
        <Box>
          {data?.status === 'approved-ready-to-bill' && (
            <Button onClick={handleArchive}>Archive Reconciliation</Button>
          )}
        </Box>
        {id && <></>}
      </Flex>

      <Box bgColor={'white'} padding={8} mb={8} borderWidth={1} borderColor={'gray.200'}>
        <HStack spacing={16}>
          <Box>Date Range: {data?.rangeDescription}</Box>
          <Box>Supplier: {data?.vendor?.companyName}</Box>
          <Box>
            Billing Status: <Badge>{data?.billingStatus ?? 'Pending'}</Badge>
          </Box>
        </HStack>
      </Box>
      <Box mb={4}>
        {data && (
          <Wizard
            billingReconciliation={data}
            handleSubmitBilling={handleSendToBilling}
            handleReconciliationApproval={handleApprovalResponse}
            handleViewSupplierInvoice={() => setIsReviewModalOpen(true)}
            isLoading={isSubmitting}
          />
        )}
      </Box>
      <Box maxWidth={1200}>
        <Box bgColor={'white'} padding={8} mb={8} borderWidth={1} borderColor={'gray.200'}>
          <Flex mb={4}>
            <Heading size={'md'} mb={4}>
              Billing Items
            </Heading>

            <Box ml={8}>
              <FormControl display="flex" alignItems="center">
                <FormLabel htmlFor="email-alerts" mb="0">
                  Show only rows with changes
                </FormLabel>
                <Switch
                  id="filter-changes-only"
                  isChecked={isFilteredChangesOnly}
                  onChange={(e) => setIsFilteredChangesOnly(e.target.checked)}
                />
              </FormControl>
            </Box>
            <Spacer />
            <Box>
              <CSVLink
                // className="chakra-button ui button small"
                data={csvData(items)}
                filename="billing-reconciliation.csv"
                headers={headers}
                target="_blank"
              >
                Download as CSV{' '}
              </CSVLink>
            </Box>
          </Flex>
          <Divider />
          <TableContainer maxHeight={500} overflowY={'unset'}>
            <ChakraTable size="sm">
              <Thead position={'sticky'} top={0} bgColor={'white'} zIndex={2}>
                <Tr>
                  <Th>
                    <Checkbox
                      defaultChecked={allSelected}
                      isChecked={allSelected}
                      onChange={handleToggleItems}
                    />
                  </Th>
                  <Th>Order</Th>
                  <Th>Item</Th>
                  <Th>Description</Th>
                  <Th isNumeric>Quantity</Th>
                  <Th isNumeric>Cost </Th>
                  <Th isNumeric>Change Cost</Th>
                  {/* <Th>Explanation</Th> */}
                  <Th isNumeric>Sell Amount</Th>
                  <Th isNumeric></Th>
                </Tr>
              </Thead>
              <Tbody>
                {items
                  ?.filter((item) => !isFilteredChangesOnly || item?.changeAmount !== undefined)
                  ?.map((item, idx) => (
                    <>
                      <Tr key={idx}>
                        <Td bg={(item as any)?.changeAmount ? 'yellow.100' : 'white'}>
                          <Checkbox
                            px={0}
                            isChecked={item?.isDeleted}
                            checked={item?.isDeleted}
                            onChange={handleToggleItem(item)}
                            defaultChecked={item?.isDeleted}
                          />
                        </Td>
                        <Td
                          bg={(item as any)?.changeAmount ? 'yellow.100' : 'white'}
                          style={{ width: 85 }}
                        >
                          <Link to={`/orders/${item.order}`}>
                            <Text
                              as={item?.isDeleted ? 'del' : 'p'}
                              color={item?.isDeleted ? 'gray.300' : 'inherit'}
                            >
                              {item?.orderHumanId ?? '-'}
                            </Text>
                          </Link>
                        </Td>
                        <Td
                          bg={(item as any)?.changeAmount ? 'yellow.100' : 'white'}
                          style={{ width: 100 }}
                        >
                          <Link to={`/orders/${item.order}`}>
                            <Text
                              as={item?.isDeleted ? 'del' : 'p'}
                              color={item?.isDeleted ? 'gray.300' : 'inherit'}
                            >
                              {item?.orderItemHumanId
                                ? item?.orderItemHumanId?.length > 10
                                  ? item?.orderItemHumanId?.slice(-6)
                                  : item?.orderItemHumanId
                                : '-'}
                            </Text>
                          </Link>
                        </Td>
                        <Td bg={(item as any)?.changeAmount ? 'yellow.100' : 'white'}>
                          <Text
                            as={item?.isDeleted ? 'del' : 'p'}
                            color={item?.isDeleted ? 'gray.300' : 'inherit'}
                            textTransform={'uppercase'}
                            fontFamily={item?.type === 'item' ? 'mono' : undefined}
                          >
                            {item?.type === 'item'
                              ? (item as any)?.orderItem?.productKey
                              : (item as any)?.description}
                          </Text>
                        </Td>
                        <Td
                          bg={(item as any)?.changeAmount ? 'yellow.100' : 'white'}
                          style={{ width: 75 }}
                          isNumeric
                        >
                          <Text
                            as={item?.isDeleted ? 'del' : 'p'}
                            color={item?.isDeleted ? 'gray.300' : 'inherit'}
                          >
                            {(item as any)?.orderItem?.quantity ?? '-'}
                          </Text>
                        </Td>
                        <Td bg={(item as any)?.changeAmount ? 'yellow.100' : 'white'} isNumeric>
                          <Text
                            as={(item as any)?.changeAmount || item?.isDeleted ? 'del' : 'p'}
                            color={item?.isDeleted ? 'gray.300' : 'inherit'}
                          >
                            {currencyFormat(item.amount ?? 0.0)}
                          </Text>
                        </Td>
                        <Td bg={(item as any)?.changeAmount ? 'yellow.100' : 'white'} isNumeric>
                          <Text
                            as={item?.isDeleted ? 'del' : 'p'}
                            color={item?.isDeleted ? 'gray.300' : 'inherit'}
                          >
                            {(item as any)?.changeAmount
                              ? currencyFormat((item as any)?.changeAmount ?? 0)
                              : '-'}
                          </Text>
                        </Td>
                        {/* <Td bg={(item as any)?.changeAmount ? 'yellow.100' : 'white'}>
                        <Text
                          as={item?.isDeleted ? 'del' : 'p'}
                          color={item?.isDeleted ? 'gray.300' : 'inherit'}
                        >
                          {(item as any)?.changeExplanation ?? '-'}
                        </Text>
                      </Td> */}
                        <Td bg={(item as any)?.changeAmount ? 'yellow.100' : 'white'} isNumeric>
                          <Text
                            as={item?.isDeleted ? 'del' : 'p'}
                            color={item?.isDeleted ? 'gray.300' : 'inherit'}
                          >
                            {(item as any)?.billingAmount
                              ? currencyFormat((item as any)?.billingAmount ?? 0)
                              : '-'}
                          </Text>
                        </Td>
                        <Td bg={(item as any)?.changeAmount ? 'yellow.100' : 'white'}>
                          <Tooltip hasArrow aria-label="time" label={'Click to change an amount'}>
                            <IconButton
                              aria-label="edit"
                              size={'xs'}
                              colorScheme="gray"
                              variant={'ghost'}
                              mr={1}
                              icon={<EditIcon />}
                              onClick={() => {
                                setSelectedItem(item);
                                setIsChangeModalOpen(true);
                              }}
                            />
                          </Tooltip>
                        </Td>
                        {/* <Td bg={(item as any)?.changeAmount ? 'yellow.100' : 'white'} isNumeric>
                    <Button colorScheme={'gray'} size={'xs'}>
                      Flag Change
                    </Button>
                  </Td> */}
                      </Tr>
                      {item.changeExplanation && item.changeExplanation !== '' && (
                        <Tr key={`${idx}-explanation`}>
                          <Td bg={(item as any)?.changeAmount ? 'yellow.100' : 'white'}></Td>
                          <Td bg={(item as any)?.changeAmount ? 'yellow.100' : 'white'}></Td>
                          <Th bg={(item as any)?.changeAmount ? 'yellow.100' : 'white'}>
                            Explanation
                          </Th>
                          <Td bg={(item as any)?.changeAmount ? 'yellow.100' : 'white'} colSpan={6}>
                            {item?.changeExplanation ?? '-'}
                          </Td>
                        </Tr>
                      )}
                    </>
                  ))}
              </Tbody>
              <Tfoot>
                <Tr>
                  <Th colSpan={5}>Total</Th>
                  <Th isNumeric>
                    <Text as={hasChanges ? 'del' : 'p'}>{currencyFormat(total ?? 0.0)}</Text>
                    {hasChanges && <Text>{currencyFormat(totalChange ?? 0.0)}</Text>}
                  </Th>
                  <Th></Th>
                  <Th isNumeric>{currencyFormat(sellAmountTotal ?? 0.0)}</Th>
                  <Th></Th>
                </Tr>
              </Tfoot>
            </ChakraTable>
          </TableContainer>
        </Box>

        {errorMessage && (
          <Alert status="error" mb={3}>
            {errorMessage}
          </Alert>
        )}

        {/* {data?.status !== 'billing-complete' && (
          <Box bgColor={'white'} padding={8} mb={8} borderWidth={1} borderColor={'gray.200'}>
            <RadioGroup onChange={(e) => setIsReject(e === '0')} value={isReject ? '0' : '1'}>
              <Stack direction="row">
                <Radio value="1">Approve</Radio>
                <Radio value="0">Reject</Radio>
              </Stack>
            </RadioGroup>

            {isReject && (
              <Textarea
                mt={4}
                placeholder="Rejection Reason..."
                value={rejectionReason}
                onChange={(e) => setRejectionReason(e.target.value)}
              ></Textarea>
            )}
            <ButtonGroup>
              <Button
                isDisabled={isSubmitting}
                isLoading={isSubmitting}
                colorScheme="blue"
                mt={4}
                onClick={() => handleSave(true)}
              >
                {!isReject ? 'Approve Supplier Invoice ' : 'Reject Supplier Invoice'}
              </Button>

              <Button
                isDisabled={isSubmitting}
                isLoading={isSubmitting}
                colorScheme="gray"
                mt={4}
                onClick={handleSendToBilling}
              >
                Submit to Billing
              </Button>
            </ButtonGroup>
          </Box>
        )} */}
      </Box>
      {isChangeModalOpen && (
        <SupplierChangeModal
          key={selectedItem?._id}
          item={selectedItem}
          onChange={handleChange}
          onClose={() => {
            setSelectedItem(undefined);
            setIsChangeModalOpen(false);
          }}
        />
      )}

      {isReviewModalOpen && (
        <ReviewModal
          pdfUrl={pdfUrl}
          data={{ items, totalChange, total, hasChanges }}
          isSubmitting={isSubmitting}
          errorMessage={errorMessage}
          onApprove={handleSave}
          onClose={() => setIsReviewModalOpen(false)}
        />
      )}
    </Box>
  );
};

const mapStateToProps = (state): ConnectedProps => ({
  user: state.auth.user || {},
});

export const Detail = connect(mapStateToProps)(
  connectOrderItemModals<IndexWrappedProps>(PreDetail)
);
