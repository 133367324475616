import * as React from 'react';

export interface NumberOfSetsProps {
  onChange: (value) => void;
  numSets: number;
}
const style = { marginTop: 12 };

export function NumberOfSets(props: NumberOfSetsProps) {
  return (
    <div className="ui input fluid " style={style}>
      <label>Number of Sets</label>
      <input
        placeholder="Number of sets..."
        type="text"
        value={props.numSets}
        onChange={(e) => props.onChange(e.target.value)}
      />
    </div>
  );
}
