import * as React from 'react';
import Feathers from '../../../bootstrap/feathers';
import { PageHeaderRow } from '@inkcloud/components';
import { Button, Form, Message, Segment } from 'semantic-ui-react';
import { Formik, Form as FormikForm, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { SemanticField } from '../../../common/Form';
import { usStateProvinces } from '../../../common';

export interface PickupLocationsFormProps {
  initialValues: any;
  onSubmit: (v: any) => void;
  errorMessage: string;
}

const PickupLocationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  address1: Yup.string().required('Required'),
  address2: Yup.string(),
  city: Yup.string().required('Required'),
  stateProvince: Yup.string().required('Required'),
  postalCode: Yup.string().required('Required'),
  isDeleted: Yup.boolean(),
});

export const PickupLocationsForm: React.FunctionComponent<PickupLocationsFormProps> = (props) => {
  const { initialValues, onSubmit, errorMessage } = props;

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={PickupLocationSchema}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values);
        }}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            setFieldValue,
            setFieldTouched,
          } = props;

          return (
            <FormikForm className={`ui form ${isSubmitting && !errorMessage && 'loading'}`}>
              <Form.Group widths="equal">
                <Form.Field>
                  <SemanticField name={'name'} label="Name" component={Form.Input} />
                  <ErrorMessage component="div" name="name" />
                </Form.Field>
              </Form.Group>

              <Form.Group widths="equal">
                <Form.Field>
                  <SemanticField name={'address1'} label="Address 1" component={Form.Input} />
                  <ErrorMessage component="div" name="address1" />
                </Form.Field>
                <Form.Field>
                  <SemanticField name={'address2'} label="Address 2" component={Form.Input} />
                  <ErrorMessage component="div" name="address2" />
                </Form.Field>
              </Form.Group>

              <Form.Group widths="equal">
                <Form.Field>
                  <SemanticField name={'city'} label="City" component={Form.Input} />
                  <ErrorMessage component="div" name="city" />
                </Form.Field>

                <Form.Field>
                  <SemanticField
                    name={'stateProvince'}
                    component={Form.Dropdown}
                    options={usStateProvinces}
                    fluid
                    selection
                    search
                    label="State/Province"
                    placeholder="State/Province..."
                  />
                  <ErrorMessage component="div" name="stateProvince" />
                </Form.Field>
                <Form.Field>
                  <SemanticField name={'postalCode'} label="Postal Code" component={Form.Input} />
                  <ErrorMessage component="div" name="postalCode" />
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal"></Form.Group>
              <Button primary type="submit" disabled={isSubmitting && !errorMessage}>
                Save
              </Button>
            </FormikForm>
          );
        }}
      </Formik>
      {errorMessage && <Message error content={errorMessage} />}
    </>
  );
};
