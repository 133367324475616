import * as React from 'react';
import {
  Collapse,
  Box,
  Badge,
  Flex,
  Heading,
  HStack,
  Spacer,
  Stack,
  Tag,
  TagLabel,
  TagRightIcon,
  Text,
} from '@chakra-ui/react';

import { CheckIcon, MinusIcon, WarningTwoIcon } from '@chakra-ui/icons';

import { useDrag } from 'react-dnd';
import { format, isBefore, parse } from 'date-fns';

export interface JobCardData {
  _id: string;
  jobNumber: string;
  customerName: string;
  description: string;
  size?: string;
  quantity?: number | string;
  stock?: string;
  needsPressCheck?: boolean;
  workflowTemplateName: string;
  dueDate?: string;
  columnId: string;
  steps: any[];
}

interface IJobCardProps {
  isDisabled?: boolean;
  data: JobCardData;
  onClick?: (_id: string, data: any) => void;
}

const JobCard: React.FunctionComponent<IJobCardProps> = (props) => {
  const a = '';
  const { isDisabled, data, onClick } = props;
  const {
    jobNumber,
    customerName,
    description,
    size,
    stock,
    quantity,
    needsPressCheck,
    workflowTemplateName,
    dueDate,
  } = data;
  const [isHover, setIsHover] = React.useState(false);

  const [{ isDragging }, drag, dragPreview] = useDrag(() => ({
    // "type" is required. It is used by the "accept" specification of drop targets.
    type: 'BOX',
    item: { _id: data._id, steps: data.steps },
    // The collect function utilizes a "monitor" instance (see the Overview for what this is)
    // to pull important pieces of state from the DnD system.
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  // console.log({ isDragging });
  const cursor: React.CSSProperties['cursor'] = isDisabled ? 'not-allowed' : 'grab';

  const isOverdue = dueDate ? isBefore(new Date(dueDate), Date.now()) : false;

  return (
    <Box
      boxShadow={'md'}
      width={291}
      mb={2}
      p={3}
      borderWidth={1}
      borderColor={isOverdue ? 'red.200' : 'gray.200'}
      cursor={cursor}
      bgColor={isOverdue ? 'red.50' : 'white'}
      onMouseOver={() => setIsHover(true)}
      onMouseOut={() => setIsHover(false)}
      onClick={() => {
        if (onClick) {
          onClick(data._id, data);
        }
      }}
      ref={drag}
      style={{ opacity: isDragging ? 0.5 : 1 }}
    >
      <Flex>
        <Heading
          color={'blue.300'}
          textTransform={'uppercase'}
          size={'sm'}
          as={'h3'}
          fontWeight={'semibold'}
        >
          {customerName}
        </Heading>
        <Spacer />
        <Heading color={'blue.500'} textTransform={'uppercase'} size={'sm'} as={'h3'}>
          {jobNumber}
        </Heading>
      </Flex>
      <Box mb={2}>
        <Text fontSize={'xs'}>{description}</Text>
      </Box>
      {/* <Divider my={2} /> */}
      <Flex mb={2}>
        <HStack spacing={4}>
          <Box>
            <Text fontSize={'xs'} fontWeight={'normal'} textTransform={'uppercase'}>
              Qty{' '}
            </Text>
            <Text fontSize={'xs'}>{quantity}</Text>
          </Box>

          <Box>
            <Text fontSize={'xs'} fontWeight={'normal'} textTransform={'uppercase'}>
              Size{' '}
            </Text>
            <Text fontSize={'xs'}>{size}</Text>
          </Box>

          <Box>
            <Text fontSize={'xs'} fontWeight={'normal'} textTransform={'uppercase'}>
              Stock{' '}
            </Text>
            <Text fontSize={'xs'}>{stock}</Text>
          </Box>
        </HStack>
      </Flex>

      <Collapse in={isHover} animateOpacity>
        <Box my={3}>
          <HStack spacing={2}>
            <Tag size={'sm'} variant="outline" colorScheme="green">
              <TagLabel>Print</TagLabel>
              <TagRightIcon as={CheckIcon} color={'green'} />
            </Tag>
            <Tag size={'sm'} variant="outline" colorScheme="gray">
              <TagLabel>Cut</TagLabel>
              <TagRightIcon as={MinusIcon} color={'gray'} />
            </Tag>
          </HStack>
        </Box>
      </Collapse>

      <Flex>
        <Box>
          <Text
            textTransform={'uppercase'}
            fontSize={'xs'}
            color={isOverdue ? 'red' : undefined}
            fontWeight={isOverdue ? 'bold' : 'semibold'}
          >
            {dueDate && `Due ${format(new Date(dueDate as unknown as Date), 'M/d').toString()}`}{' '}
            {isOverdue && <WarningTwoIcon color={'yellow.500'} />}
          </Text>
        </Box>
        <Spacer />
        <Box>
          <Stack direction="row">
            {needsPressCheck && (
              <Badge variant={'outline'} colorScheme={'red'}>
                Press Check
              </Badge>
            )}
            <Badge variant={'outline'} colorScheme={'green'}>
              {workflowTemplateName}
            </Badge>
          </Stack>
        </Box>
      </Flex>
    </Box>
  );
};

export default JobCard;
