import React, { useState, useRef } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { FormattedDate, FormattedTime } from 'react-intl';
import { useSelector } from 'react-redux';

import {
  Button,
  Box,
  IconButton,
  Tooltip,
  Badge,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  ButtonGroup,
  Alert,
  Table,
  Tbody,
  Tr,
  Th,
  Td,
  Icon,
} from '@chakra-ui/react';

import { FaFileUpload, FaEdit, FaRegFile } from 'react-icons/fa';

import {
  useList,
  ListPage,
  basicQueryMap,
  generateOperatorOptions,
  Operator,
} from '@inkcloud/components';

import type { ModelTypes } from '@inkcloud/icapi-types';
import Feathers from '../../bootstrap/feathers';
import UploadMisc from '../../common/UploadMisc';

const serviceName = '/proofs/v2';
const pathPrefix = '/cost-estimating';

const filterQueryMap = (values) => basicQueryMap(values, {});

const baseUrl = `https://storage.googleapis.com/icstorage/`;

const statusOptions = [
  { label: 'Awaiting Artwork', value: 'awaiting-artwork' },
  { label: 'Awaiting Proof Generation', value: 'awaiting-proof-generation' },
  { label: 'Pending Approval', value: 'pending-approval' },
  { label: 'Approved', value: 'approved' },
  { label: 'Rejected', value: 'rejected' },
];

export async function getFilterDefinition() {
  const definition = [
    {
      label: 'Status',
      value: 'status',
      operators: generateOperatorOptions([Operator.Is]),
      valueOptions: statusOptions,
      type: 'select',
    },

    {
      label: 'Date:Created',
      value: 'createdAt',
      operators: generateOperatorOptions([Operator.Between]),
      type: 'date',
    },
    {
      label: 'ID',
      value: 'humanId',
      operators: generateOperatorOptions([Operator.Is]),
    },
    // {
    //   label: 'Type',
    //   value: 'type',
    //   operators: generateOperatorOptions([Operator.Like, Operator.Is]),
    // },
  ];

  return definition.sort((a: any, b: any) => a.label.localeCompare(b.label));
}

type UploadType = {
  filename: string;
  uploadLog: ModelTypes.UploadLog;
};

export type ProofType = ModelTypes.Proof & {
  orderItem: ModelTypes.OrderItems;
  upload: UploadType;
};

const headerActions = (
  <>
    <Link to={`${pathPrefix}/add`}>
      <Button colorScheme="blue" size="sm">
        New
      </Button>
    </Link>
  </>
);

export default function List(props: RouteComponentProps) {
  const {
    match: { params },
    location,
    history,
  } = props;
  const authUser = useSelector((state: any) => state.auth.user);

  const [isDisabled, setIsDisabled] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState({
    approve: false,
    reject: false,
    upload: false,
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [uploadLog, setUploadLog] = useState('');

  // eslint-disable-next-line no-undef
  const labelRef = useRef<HTMLInputElement>(null);

  const isVendor = !!authUser?.vendor;

  const useListValues = useList({
    feathers: [
      'find',
      serviceName,
      [
        {
          query: {
            $populate: ['orderItem', 'upload.uploadLog'],
            status: !isVendor ? 'pending-approval' : undefined,
          },
        },
      ],
    ],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  const [isModalOpen, setIsModalOpen] = useState({
    approval: false,
  });

  const [selectedItem, setSelectedItem] = useState<ProofType | null>(null);

  const tableDefinition = [
    {
      key: 'humanId',
      label: 'ID',
      sortable: true,
      cell: (v, r) => v,
    },
    {
      key: 'createdAt',
      label: 'Date',
      sortable: true,
      cell: (v) => (
        <Tooltip hasArrow aria-label="time" label={<FormattedTime value={v} />}>
          <Box as="span">
            <FormattedDate value={v} />
          </Box>
        </Tooltip>
      ),
    },

    {
      key: '',
      label: 'Order ID',
      sortable: true,
      cell: (v, r) => r?.orderItem?.humanId,
    },
    {
      key: 'type',
      label: 'Type',
      sortable: true,
      cell: (v) => 'Electronic',
    },
    {
      key: 'status',
      label: 'Status',
      sortable: true,
      cell: (v) => v && <Badge variant="outline">{v}</Badge>,
    },
    {
      key: 'response',
      label: 'Response',
      sortable: true,
      cell: (v) =>
        v && (
          <Badge colorScheme={v === 'rejected' ? 'orange' : 'green'} variant="outline">
            {v}
          </Badge>
        ),
    },

    {
      key: '',
      label: '',
      sortable: false,
      cell: (v, r) => (
        <ButtonGroup spacing={2}>
          <Tooltip label={isVendor ? 'Upload Proof' : 'Approval'}>
            <IconButton
              colorScheme="gray"
              aria-label="Edit"
              icon={isVendor ? <FaFileUpload /> : <FaEdit />}
              onClick={() => {
                setSelectedItem(r);
                setIsModalOpen({ ...isModalOpen, approval: true });
              }}
            />
          </Tooltip>
        </ButtonGroup>
      ),
      textAlign: 'right',
    },
  ];

  const headerActions = (
    <>
      <Link to={`${pathPrefix}/add`}>{!isVendor && <Button colorScheme="blue">New</Button>}</Link>
    </>
  );

  const handleSubmit = async (response?: string) => {
    setIsSubmitting({
      ...isSubmitting,
      approve: response === 'approved',
      reject: response === 'rejected',
      upload: response === undefined,
    });
    setIsDisabled(true);
    setErrorMessage('');
    try {
      if (isVendor) {
        const res = await Feathers.service('/proofs/v2/upload').create({
          proofId: selectedItem?._id,
          uploadLog,
        });
      } else {
        const payload = {
          ...selectedItem,
          status: 'responded',
          response,
        };
        const res = await Feathers.service(serviceName).patch(selectedItem?._id, payload);
      }

      setIsModalOpen({ ...isModalOpen, approval: false });
      useListValues.reload();
    } catch (e) {
      const errMsg = e?.code < 500 ? e?.message : 'Something went wrong';
      setErrorMessage(errMsg);
    }
    setIsSubmitting({
      ...isSubmitting,
      approve: false,
      reject: false,
      upload: false,
    });
    setIsDisabled(false);
  };

  return (
    <div>
      <ListPage
        header="Cost Estimates"
        subheader="List"
        useListValues={useListValues}
        history={history}
        pathPrefix={pathPrefix}
        tableDefintion={tableDefinition}
        headerActions={headerActions}
        withCheckboxes={!isVendor}
        getFilterDefinition={getFilterDefinition}
      />
    </div>
  );
}
