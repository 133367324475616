import React, { useState, useEffect } from 'react';
// import { Modal, Form, Button, Message } from 'semantic-ui-react'
// import { FeathersDropdown } from '@inkcloud/components'

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  IconButton,
  Box,
  Heading,
  Alert,
  Flex,
  Text,
} from '@chakra-ui/react';

import { FaEdit } from 'react-icons/fa';

import { Select, OptionsOrGroups } from 'chakra-react-select';

import type { ModelTypes } from '@inkcloud/icapi-types';
import { PreLoader, EmptyWrapper } from '@inkcloud/components';

import Feathers from '../../../bootstrap/feathers';

import NoteContainer from '../../../common/notes2/index';
import AddNote from '../../../common/AddNote';

import Note from '../../purchase-orders/PurchaseOrder/Note';

import StagingLocationModal from './StagingLocationModal';

import { OrderItemComponent2, OrderItemModalsProps } from '../../../common/OrderItemCard2';

interface OrderItemModalProps {
  onClose: () => void;
  stagingLocations: ModelTypes.FulfillmentStagingLocations[];
  orderItem: ModelTypes.OrderItems & {
    stagingLocation?: string;
    stagingLocationContainId?: string;
    stagingLocationId?: string;
    stagingLocationQty?: number;
  };
  onReload?: () => void;
}

type orderItemType = {
  data: ModelTypes.OrderItems[];
};

export default function OrderItemModal(props: OrderItemModalProps) {
  const { onClose, orderItem, stagingLocations, onReload } = props;

  const [status, setStatus] = useState({ value: '', label: '' });
  const [isLoading, setIsLoading] = useState({
    submit: false,
    notes: false,
  });
  const [isOrderLoading, setIsOrderLoading] = useState(false);
  const [isOrderItemLoading, setIsOrderItemLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    notes: '',
    submit: '',
  });

  const [orderItems, setOrderItems] = useState<orderItemType>({ data: [] });
  const [notes, setNotes] = useState([]);

  const [statuses, setStatuses] = useState<OptionsOrGroups<{ value: string; label: string }, any>>(
    []
  );

  const [selectedLocation, setSelectedLocation] = useState(orderItem?.stagingLocation ?? '');

  const [isStagingLocationModalOpen, setIsStagingLocationModalOpen] = useState(false);
  const [isAddNoteModalOpen, setIsAddNoteModalOpen] = useState(false);

  const [isReloadNote, setIsReloadNote] = useState(false);

  const loadStatuses = async () => {
    try {
      const res = await Feathers.service('/order-item-statuses').find({
        query: { $np: 1, $sort: { name: 1 } },
      });

      const mapped = res?.map((r) => ({ value: r._id, label: r.name }));

      setStatuses(mapped);
      const statusValue = mapped?.find(
        (s) => s.value === (orderItem?.productionStatus as any)?.jobStatus
      );

      setStatus(statusValue);
    } catch (e) {
      console.error(e);
    }
  };

  const loadOrder = async (id: string) => {
    setIsOrderLoading(true);
    try {
      const res = await Feathers.service('orders').get(id, {
        query: {
          $populate: ['notes.user'],
          $select: ['notes'],
        },
      });

      setNotes(res?.notes);
    } catch (e) {
      console.error(e);
    }

    setIsOrderLoading(false);

    setIsReloadNote(false);
  };

  const loadOrderItems = async () => {
    setIsOrderItemLoading(true);
    try {
      const res: any = await Feathers.service('order-items').find({
        query: {
          humanId: orderItem?.humanId,
          $populate: [
            'attributes.attribute',
            'attributes.value',
            'category',
            'customer',
            'micrositeUser',
            'productionStatus.jobStatus',
            'miscUploads',
            'order',
            'order.notes.user',
          ],
        },
      });

      setOrderItems(res);

      if (res?.data[0]?.order?.notes?.length) {
        loadOrder(res?.data[0]?.order?._id);
      }
    } catch (err) {
      console.log(err);
    }

    setIsOrderItemLoading(false);
  };

  useEffect(() => {
    loadOrderItems();

    loadStatuses();
  }, [orderItem]);

  useEffect(() => {
    if (isReloadNote) {
      loadOrder(orderItem?.order as string);
    }
  }, [isReloadNote]);

  const handleSubmit = async () => {
    setIsLoading({
      ...isLoading,
      submit: true,
    });
    setErrorMessage({
      ...errorMessage,
      submit: '',
    });
    try {
      await Feathers.service('/order-items/status-changer').update(orderItem._id, {
        status: status.value,
      });
      onReload?.();
      onClose();
    } catch (e) {
      const errMsg = e?.code < 500 ? e.message : 'Something went wrong';
      setErrorMessage({
        ...errorMessage,
        submit: errMsg,
      });
    }
    setIsLoading({
      ...isLoading,
      submit: false,
    });
  };

  // orders/notes
  // {
  //   "orderId": "63e5f97eaef999804854a4ea",
  //   "content": "test"
  // }

  // console.log('statuses', statuses)
  // console.log('selectedLocation', selectedLocation)

  const handleAddNote = async (note: string) => {
    setErrorMessage({
      ...errorMessage,
      notes: '',
    });

    setIsLoading({
      ...isLoading,
      notes: true,
    });

    try {
      const res = await Feathers.service('/orders/notes').create({
        orderId: orderItem?.order,
        content: note,
      });

      setIsReloadNote(true);
      setIsAddNoteModalOpen(false);
    } catch (e) {
      const errMsg = e?.code < 500 ? e.message : 'Something went wrong';
      setErrorMessage({
        ...errorMessage,
        notes: errMsg,
      });
    }

    setIsLoading({
      ...isLoading,
      notes: false,
    });
  };

  const locationOptions = stagingLocations?.map((s) => ({
    value: s._id as string,
    label: s.name as string,
  }));

  const hasStagingLocation = orderItem?.stagingLocation;

  return (
    <Modal isOpen={true} onClose={onClose} size="5xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Order Info</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <EmptyWrapper queryResult={orderItems} isStateLoading={isOrderItemLoading}>
            <Box>
              {isOrderLoading ? (
                <PreLoader />
              ) : (
                <Box mb={3}>
                  <Note
                    title="Notes"
                    actionButtonLabel="Add Note"
                    notes={notes}
                    onOpenNoteModal={() => setIsAddNoteModalOpen(true)}
                  />
                </Box>
              )}

              <Box mb={2}>
                <Select
                  useBasicStyles
                  options={statuses}
                  value={status}
                  onChange={(v: any) => setStatus(v)}
                />
              </Box>

              <Box mb={2}>
                <Alert status="info">
                  {hasStagingLocation ? (
                    <>This item is staged in {hasStagingLocation} </>
                  ) : (
                    'This item is not currently staged'
                  )}{' '}
                  <IconButton
                    ml={3}
                    aria-label="remove"
                    icon={<FaEdit />}
                    onClick={() => setIsStagingLocationModalOpen(true)}
                  />
                </Alert>
              </Box>

              {orderItems?.data?.map((i, index: number) => (
                <OrderItemComponent2
                  key={index}
                  orderItem={i}
                  showRightMenu={false}
                  handleArtworkManagementClick={() => {}}
                  printHandleOpen={() => {}}
                  preflightHandleOpen={() => {}}
                  statusChangeHandleOpen={() => {}}
                  // highlightQuantity={i.business === '610c663d1d8c987ce3b881b3'}

                  // onTurnaroundSubmit={handleTurnaroundSubmit}
                />
              ))}
            </Box>
          </EmptyWrapper>

          {errorMessage.submit && <Alert status="error">{errorMessage.submit}</Alert>}
        </ModalBody>

        <ModalFooter>
          <Button mr={3} onClick={onClose}>
            Close
          </Button>
          <Button
            isDisabled={isLoading.submit}
            isLoading={isLoading.submit}
            colorScheme="blue"
            onClick={handleSubmit}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>

      {isAddNoteModalOpen && (
        <AddNote
          isSubmitting={isLoading.notes}
          errorMessage={errorMessage.notes}
          onSubmit={handleAddNote}
          onClose={() => setIsAddNoteModalOpen(false)}
        />
      )}

      {isStagingLocationModalOpen && (
        <StagingLocationModal
          key={orderItem?._id}
          id={orderItem?._id as string}
          stagingLocation={{
            _id: orderItem?.stagingLocationId as string,
            containId: orderItem?.stagingLocationContainId as string,
            name: orderItem?.stagingLocation as string,
            qty: orderItem?.stagingLocationQty as number,
          }}
          locationOptions={locationOptions}
          onReload={onReload}
          onClose={() => setIsStagingLocationModalOpen(false)}
        />
      )}
    </Modal>
  );
}
