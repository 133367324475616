// import * as React from 'react';
// import { Button, Checkbox, List as List2, Segment, Table, Label, Popup, Divider } from 'semantic-ui-react';
// import { Link, RouteComponentProps } from 'react-router-dom';
// import { FormattedDate } from 'react-intl';
// import { useList, PageHeaderRow, PreLoader, PaginatorWrapper } from '@inkcloud/components';
// // import { SubMenu } from './../SubMenu';

// interface ListProps { }

// const serviceName = 'store-front/content/menus';

// export const List: React.FunctionComponent<ListProps & RouteComponentProps<{ micrositeId: string }>> = (props) => {
//   const { match: { params }, location, history, } = props;
//   const { state, reload, handleDelete, selectedItems, clearAll, setChecked, someSelected, toggleAll, filter, setFilter, sort, handleSort } = useList({
//     feathers: ['find', serviceName, [{ query: { $select: ['createdAt', 'name', 'key', 'menuItems'] } }]],
//     filterMap: v => v,
//     debounce: 150,
//     location,
//     history,
//     sort: { createdAt: -1 }
//   })

//   const { queryResult, isLoading: isStateLoading } = state as any;

//   if (!queryResult) {
//     return null;
//   }

//   const pathPrefix = `/settings/content-management`;

//   return (
//     <div>
//       <PageHeaderRow header='Menus' subheader='Settings'>
//         {/* <SubMenu match={props.match} history={history} /> */}
//         <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
//         <Link to={`${pathPrefix}/menus/add`}><Button content="New" primary size={'mini'} /></Link>
//       </PageHeaderRow>

//       <Segment loading={state.isLoading}>
//         <PaginatorWrapper.Top filter={filter} reload={reload} data={(queryResult as any)} pathPrefix={`${pathPrefix}/content/menus/`} />
//         <Table size={'small'} sortable>
//           <Table.Header>
//             <Table.Row>
//               <Table.HeaderCell><input type="checkbox" onChange={toggleAll} /></Table.HeaderCell>
//               <Table.HeaderCell sorted={sort.createdAt ? sort.createdAt : null} onClick={() => handleSort('createdAt')}>
//                 Created
//               </Table.HeaderCell>
//               <Table.HeaderCell sorted={sort.name ? sort.name : null} onClick={() => handleSort('name')}>
//                 Name
//               </Table.HeaderCell>
//               <Table.HeaderCell sorted={sort.key ? sort.key : null} onClick={() => handleSort('key')}>
//                 Key
//               </Table.HeaderCell>
//               <Table.HeaderCell>Items</Table.HeaderCell>
//               <Table.HeaderCell />
//             </Table.Row>
//           </Table.Header>
//           <Table.Body>
//             {queryResult && (queryResult as any).data.map(r => (
//               <Table.Row key={r._id}>
//                 <Table.Cell>
//                   <input type="checkbox" value={r._id} checked={selectedItems[r._id] || false} onChange={setChecked} />
//                 </Table.Cell>
//                 <Table.Cell>
//                   <FormattedDate value={r.createdAt} />
//                 </Table.Cell>
//                 <Table.Cell>
//                   {r.name}
//                 </Table.Cell>

//                 <Table.Cell>
//                   <Label size="tiny" basic >{r.key}</Label>
//                 </Table.Cell>

//                 <Table.Cell>
//                   <List2>
//                     {r.menuItems.map(mi => <List2.Item key={mi._id} >
//                       {mi.label}{mi.url ? ` - ${mi.url}` : ''}
//                       <List2>
//                         {
//                           mi?.menuItems?.map((sub, index) => <List2.Item key={index}>
//                             {sub.label} {sub.url ? ` - ${sub.url}` : ''}
//                           </List2.Item>)
//                         }
//                       </List2>
//                     </List2.Item>
//                     )}
//                   </List2>
//                 </Table.Cell>

//                 <Table.Cell textAlign="right">
//                   <Popup
//                     size='mini'
//                     content="Edit"
//                     trigger={
//                       <Link to={`${pathPrefix}/menus/${r._id}`}>
//                         <Button size={'mini'} icon={'edit'} />
//                       </Link>
//                     }
//                   />
//                 </Table.Cell>
//               </Table.Row>
//             ))}
//           </Table.Body>
//         </Table>
//         <PaginatorWrapper.Bottom reload={reload} data={(queryResult as any)} pathPrefix={`${pathPrefix}/content/menus/`}>
//           {someSelected && <Button primary type="button" content="Delete" onClick={handleDelete} />}
//         </PaginatorWrapper.Bottom>
//       </Segment>
//     </div>
//   );
// }

import * as React from 'react';
import { Button, Box, List as ListChakra, ListItem, IconButton, Tooltip } from '@chakra-ui/react';

import { Link, RouteComponentProps } from 'react-router-dom';
import { FormattedDate, FormattedTime } from 'react-intl';

import { FaEdit, FaPencilAlt } from 'react-icons/fa';

import { useList, ListPage, basicQueryMap } from '@inkcloud/components';
// import getFilterDefinition from './FilterForm'

interface ListProps {}

const serviceName = 'store-front/content/menus';

const pathPrefix = `/settings/content-management`;

const filterQueryMap = (values) => {};

export default function List(props: ListProps & RouteComponentProps) {
  const {
    match: { params },
    location,
    history,
  } = props;

  const useListValues = useList({
    feathers: [
      'find',
      serviceName,
      [{ query: { $select: ['createdAt', 'name', 'key', 'menuItems'] } }],
    ],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  const tableDefintion = [
    {
      key: 'createdAt',
      label: 'Date',
      sortable: true,
      cell: (v) => (
        <Tooltip hasArrow aria-label="time" label={<FormattedTime value={v} />}>
          <Box>
            <FormattedDate value={v} />
          </Box>
        </Tooltip>
      ),
    },
    {
      key: 'name',
      label: 'Name',
      cell: (v) => v,
    },
    {
      key: 'key',
      label: 'Key',
      cell: (v) => v,
    },
    {
      key: '',
      label: 'Items',
      cell: (v, r) => (
        <>
          <ListChakra spacing={3}>
            {r?.menuItems?.map((mi) => (
              <ListItem key={mi._id}>
                {mi.label}
                {mi.url ? ` - ${mi.url}` : ''}
                <ListChakra spacing={3}>
                  {mi?.menuItems?.map((sub, index) => (
                    <ListItem key={index}>
                      {sub.label} {sub.url ? ` - ${sub.url}` : ''}
                    </ListItem>
                  ))}
                </ListChakra>
              </ListItem>
            ))}
          </ListChakra>
        </>
      ),
    },

    {
      key: '',
      label: '',
      cell: (v, r) => (
        <Tooltip label="Edit Details">
          <Link to={`${pathPrefix}/menus/${r._id}`}>
            <IconButton colorScheme="gray" aria-label="Edit" icon={<FaEdit />} />
          </Link>
        </Tooltip>
      ),
      textAlign: 'right',
    },
  ];

  const headerActions = (
    <>
      <Link to={`${pathPrefix}/menus/add`}>
        <Button colorScheme="blue">New</Button>
      </Link>
    </>
  );

  return (
    <Box>
      <ListPage
        header="Menus"
        subheader="Settings"
        useListValues={useListValues}
        history={history}
        pathPrefix={pathPrefix}
        headerActions={headerActions}
        tableDefintion={tableDefintion}
        withCheckboxes={true}
        getFilterDefinition={null}
      />
    </Box>
  );
}
