import * as React from 'react';
import { Modal, Form, TextArea, Button, Message } from 'semantic-ui-react';
import { Formik, Form as FormikForm, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import feathers from '../../bootstrap/feathers';

interface EmailModalProps {
  open: boolean;
  onClose: () => void;
  type: string;
  humanId: string;
  invoiceId: string;
  customer: any;
  business: string;
  openBalance?: number;
  title?: string;
}

const { useState, useEffect } = React;

const EmailSchema = Yup.object().shape({
  to: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required('Name is required'),
      email: Yup.string().email('Email must be valid.').required('Email is required'),
    })
  ),
  message: Yup.string().required('Message is required'),
});

export const EmailModal: React.FunctionComponent<EmailModalProps> = (props) => {
  const { open, onClose, customer, humanId, business, invoiceId, openBalance, type } = props;
  const [businessName, setBusinessName] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const loadBusiness = async () => {
      const result: any = await feathers.service('tenant-settings').get(business, {
        query: {
          $select: ['publishedContactInfo'],
        },
      });
      setBusinessName(result.publishedContactInfo.businessName);
    };
    loadBusiness();
  }, []);

  const { firstName, lastName } = customer?.primaryContact;

  let message = '';
  let title = props?.title || '';

  if (type === 'payment') {
    message = `Dear ${firstName || ''},

Your Invoice #${humanId} has an open balance of ${openBalance}.
    
Thank you,
${businessName}`;
    title = 'Email Payment Request';
  } else {
    message = `Dear ${firstName || ''},

Your Invoice #${humanId} is attached as a PDF.
    
Thank you,
${businessName}`;
    title = 'Email Invoice';
  }

  const initValue = {
    to: [
      {
        name: `${firstName || ''} ${lastName || ''}`,
        email: customer.email,
      },
    ],
    message,
    invoiceId,
    attachPDF: type !== 'payment',
    includePaymentLink: type === 'payment',
  };

  title = props?.title ?? '';
  const handleSubmit = async (values: any) => {
    setIsSubmitting(true);
    const newMessage = values.message.replace(/(?:\r\n|\r|\n)/g, '<br />');

    const payload = {
      ...values,
      message: newMessage,
    };

    if (type === 'rfq') {
      delete payload.to;
    }

    try {
      await feathers.service('invoices/email').create(payload);

      setIsSubmitting(false);
      onClose();
    } catch (e) {
      const errMsg = e?.code
        ? e.message
        : 'We are experiencing technical difficulties. Please try again.';
      setErrorMessage(errMsg);
    }
  };

  return (
    <Formik
      initialValues={initValue}
      enableReinitialize={true}
      validationSchema={EmailSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      {(innerProps) => {
        const { values } = innerProps;

        // console.log('values', values);

        return (
          <Modal
            size="tiny"
            open={open}
            onClose={() => {
              onClose();
              setErrorMessage('');
            }}
            closeIcon
          >
            <Modal.Header>{title}</Modal.Header>
            <Modal.Content>
              {errorMessage && <Message error content={errorMessage} />}
              <FormikForm className="ui form">
                {type !== 'rfq' && (
                  <>
                    <Form.Field>
                      <label>To Name</label>
                      <Field name={`to[${0}].name`} />
                      <ErrorMessage name={`to[${0}].name`} />
                    </Form.Field>
                    <Form.Field>
                      <label>To Email</label>
                      <Field name={`to[${0}].email`} />
                      <ErrorMessage name={`to[${0}].email`} />
                    </Form.Field>
                  </>
                )}
                <Form.Field>
                  <label>Your Message</label>
                  <Field name="message" render={({ field }) => <TextArea {...field} />} />
                  <ErrorMessage name={'message'} />
                </Form.Field>
                <Button
                  type="submit"
                  primary
                  content="Send Email"
                  loading={isSubmitting && !errorMessage}
                  disabled={isSubmitting && !errorMessage}
                />
              </FormikForm>
            </Modal.Content>
          </Modal>
        );
      }}
    </Formik>
  );
};
