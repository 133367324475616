import * as React from 'react';
import { Formik, Form as FormikForm, Field, FieldArray, FieldProps } from 'formik';
import { Segment, Form, Radio, Popup, Menu, Button } from 'semantic-ui-react';

interface NotificationFormProps {
  values: any;
  action: string;
  users: any;
  title: string;
  withEmail?: boolean;
  setFieldValue: (name: string, value: any) => void;
}

export const NotificationForm: React.FunctionComponent<NotificationFormProps> = (props) => {
  const { values, action, withEmail, users, title, setFieldValue } = props as any;

  return (
    <>
      <Menu attached="top" pointing secondary>
        <Menu.Item name={title} active={true} />
      </Menu>
      <Segment attached="bottom">
        <FieldArray
          name={`email.${action}`}
          render={(arrayHelpers) => (
            <div>
              {values &&
                values.length > 0 &&
                values.map((f, index) => (
                  <div key={index}>
                    {!('email' in f) && (
                      <Form.Group>
                        <Field
                          name={`email.${action}.${index}.user`}
                          render={({ field }: FieldProps<any>) => (
                            <Form.Dropdown
                              placeholder="Select a user"
                              fluid
                              search
                              style={{ minWidth: '320px' }}
                              selection
                              {...field}
                              options={
                                (users.length > 0 &&
                                  users.map((u) => ({
                                    value: u._id,
                                    text: `${u.firstName} ${u.lastName}`,
                                  }))) ||
                                []
                              }
                              onChange={(e, data) =>
                                setFieldValue(`email.${action}.${index}.user`, data.value)
                              }
                            />
                          )}
                        />

                        <Popup
                          content="Remove recipient"
                          trigger={
                            <Button
                              type="button"
                              onClick={() => arrayHelpers.remove(index)}
                              content={'-'}
                            />
                          }
                        />
                      </Form.Group>
                    )}
                  </div>
                ))}

              <Popup
                content={'Add User'}
                trigger={
                  <Button
                    type="button"
                    onClick={() =>
                      arrayHelpers.push({
                        type: 'email',
                        user: '',
                      })
                    }
                    content={'Add User'}
                    disabled={values && values.filter((v) => 'user' in v).length >= users.length}
                  />
                }
              />
            </div>
          )}
        />

        {withEmail && (
          <FieldArray
            name={`email.${action}`}
            render={(arrayHelpers) => (
              <div>
                <br />
                {values &&
                  values.length > 0 &&
                  values.map((f, index) => (
                    <div key={index}>
                      {!('user' in f) && (
                        <Form.Group>
                          <Field
                            name={`email.${action}.${index}.email`}
                            render={({ field }: FieldProps<any>) => (
                              <Form.Input
                                placeholder="Enter an email"
                                fluid
                                style={{ minWidth: '320px' }}
                                {...field}
                                onChange={(e, data) =>
                                  setFieldValue(`email.${action}.${index}.email`, data.value)
                                }
                              />
                            )}
                          />
                          <Field
                            name={`email.${action}.${index}.name`}
                            render={({ field }: FieldProps<any>) => (
                              <Form.Input
                                placeholder="Enter a name"
                                fluid
                                style={{ minWidth: '320px' }}
                                {...field}
                                onChange={(e, data) =>
                                  setFieldValue(`email.${action}.${index}.name`, data.value)
                                }
                              />
                            )}
                          />

                          <Popup
                            content="Remove recipient"
                            trigger={
                              <Button
                                type="button"
                                onClick={() => arrayHelpers.remove(index)}
                                content={'-'}
                              />
                            }
                          />
                        </Form.Group>
                      )}
                    </div>
                  ))}
                <Popup
                  content={'Add Email'}
                  trigger={
                    <Button
                      type="button"
                      onClick={() =>
                        arrayHelpers.push({
                          type: 'email',
                          email: '',
                          name: '',
                        })
                      }
                      content={'Add Email'}
                    />
                  }
                />
              </div>
            )}
          />
        )}
      </Segment>
    </>
  );
};
