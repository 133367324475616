import * as React from 'react';
import { Switch, Route, RouteComponentProps } from 'react-router-dom';

import { List } from './List';
import { Detail } from './Detail';

import feathers from '../../../bootstrap/feathers';
const { useEffect, useState } = React;

const routes: React.FunctionComponent<RouteComponentProps> = (props) => {
  const {
    match: {},
  } = props;
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const loadProducts = async () => {
      const products = (await feathers.service('products-legacy').find({
        query: {
          $np: 1,
          $sort: { staticName: 1 },
          $select: ['staticName', 'key'],
        },
      })) as any[];
      setProducts(
        products.map((p) => ({
          value: p._id,
          text: `${p.staticName} ${p.key ? '- ' + p.key : ''}`,
        }))
      );
    };

    loadProducts();
  }, []);

  useEffect(() => {
    const loadCategories = async () => {
      const products = (await feathers.service('products-legacy/categories').find({
        query: {
          $np: 1,
          $sort: { name: 1 },
          $select: ['name', 'key'],
        },
      })) as any[];
      setCategories(products.map((c) => ({ value: c._id, text: c.name })));
    };

    loadCategories();
  }, []);

  return (
    <Switch>
      <Route
        exact
        path="/content/pages"
        render={(componentProps) => (
          <List {...componentProps} products={products} categories={categories} />
        )}
      />
      <Route
        exact
        path="/content/pages/add"
        render={(componentProps) => (
          <Detail {...componentProps} products={products} categories={categories} />
        )}
      />
      <Route
        exact
        path="/content/pages/:id"
        render={(componentProps) => (
          <Detail {...componentProps} products={products} categories={categories} />
        )}
      />
    </Switch>
  );
};
export default routes;
