// import * as React from 'react';
// import { connect } from 'react-redux';
// import {Button, Form } from 'semantic-ui-react';
// import {InjectedFormProps, Field, reduxForm, change, formValueSelector, FormProps} from 'redux-form';
// import { Link } from 'react-router-dom';
// import { slugify } from 'underscore.string';
// import {
//   semanticFormField, semanticFormToggleField, semanticFormRadioField, semanticFormDropdownField,
//   validateRequired, normalizeBoolean, formatBoolean
// } from './../../../common';
// import Feathers from './../../../bootstrap/feathers';
// import { EntityDropdownWrapper2 } from './../../EntityDropdown';

// type BatchFormProps = {
//   isEdit: boolean;
// } & InjectedFormProps

// const validate = (values) => {
//   let errors = validateRequired(['batchType'])(values);

//   if (values.batchType === 'imposition') {
//     const impositionValidationErrors = validateRequired([
//       'orientationType',
//       'paper',
//       'finalSheetCount',
//       'alottedWasteCount'
//     ])(values);
//     errors = {...errors, ...impositionValidationErrors };
//   }

//   return errors;
// }

// const paperAttributes = EntityDropdownWrapper2('/products-legacy/attributes', {
//   query: {
//     $np: 1,
//     $sort: { name : 1 },
//     entityAttribute: 'PAPER',
//   }
// }, {
//   resultMap: (row) => ({value: row._id, text: row.name}),
//   searchMap: (qs) => ({name: {$LIKE: qs}})
// });

// const resourcesDropdown = EntityDropdownWrapper2('/production/resources', {
//   query: {
//     $np: 1,
//     $sort: { name : 1 },
//   }
// }, {
//   resultMap: (row) => ({value: row._id, text: row.name}),
//   searchMap: (qs) => ({name: {$LIKE: qs}})
// });

// export function BatchForm (props: BatchFormProps) {
//   const {handleSubmit, pristine, reset, submitting} = props;
//   const onNameChange = (e, data) => props.changeFieldValue('hrCode', slugify(data));
//   return (
//       <Form onSubmit={handleSubmit}>
//           <div>
//             <Form.Field>
//               <label>Batch Type</label>
//             </Form.Field>
//             <Form.Group >
//               <Field
//                 name='batchType'
//                 component={semanticFormRadioField}
//                 as={Form.Radio}
//                 value='imposition'
//                 label={"Imposition"}
//                 type="radio"
//               />
//               <Field
//                 name='batchType'
//                 component={semanticFormRadioField}
//                 as={Form.Radio}
//                 value='virtual'
//                 label={"Virtual"}
//                 type="radio"
//               />
//             </Form.Group>
//           </div>

//           <div>
//             <Form.Field>
//               <label>Orientation Type</label>
//             </Form.Field>
//             <Form.Group >
//               <Field
//                 name='orientationType'
//                 component={semanticFormRadioField}
//                 as={Form.Radio}
//                 value='sheet-wise'
//                 label={"Sheet-wise"}
//                 type="radio"
//               />
//               <Field
//                 name='orientationType'
//                 component={semanticFormRadioField}
//                 as={Form.Radio}
//                 value='work-and-turn'
//                 label={"Work and Turn"}
//                 type="radio"
//               />
//               <Field
//                 name='orientationType'
//                 component={semanticFormRadioField}
//                 as={Form.Radio}
//                 value='work-and-tumble'
//                 label={"Work and Tumble"}
//                 type="radio"
//               />
//             </Form.Group>
//           </div>

//           <Field
//           name='resource'
//           component={semanticFormDropdownField}
//           as={resourcesDropdown}
//           multiple={false}
//           label='Resource'
//           placeholder='Resource...'
//         />

//           <Field
//           name='paper'
//           component={semanticFormDropdownField}
//           as={paperAttributes}
//           multiple={false}
//           label='Paper'
//           placeholder='Paper...'
//         />

//         <Form.Group widths={'equal'}>
//           <Field
//             name='finalSheetCount'
//             label='Final Sheet count'
//             component={semanticFormField}
//             as={Form.Input}
//           />
//           <Field
//             name='alottedWasteCount'
//             component={semanticFormField}
//             as={Form.Input}
//             label='Alotted Waste Count'
//           />
//         </Form.Group>

//         <Form.Group widths={'equal'}>
//           <Field
//             name='sheetWidth'
//             label='Sheet Width'
//             component={semanticFormField}
//             as={Form.Input}
//           />
//           <Field
//             name='sheetHeight'
//             component={semanticFormField}
//             as={Form.Input}
//             label='Sheet Height'
//           />
//         </Form.Group>

//         <Button primary disabled={ pristine || submitting }>Submit</Button>
//         <Link to={'/production/batches'}><Button type="button">Back</Button></Link>
//       </Form>
//     );
// }

// const formName = 'productsCategory'
// const reduxedForm: any = reduxForm({
//   form: formName,
//   // enableReinitialize: true,
//   validate,
// })(BatchForm);

// const mapStateToProps = undefined;
// const mapDispatchToProps = (dispatch) => ({changeFieldValue: (field, value) => dispatch(change(formName, field, value))});
// export default connect(
//     mapStateToProps,
//     mapDispatchToProps
// )(reduxedForm) as any;

import * as React from 'react';
import { Button, Form, Message, Header } from 'semantic-ui-react';
import {
  Formik,
  Form as FormikForm,
  Field,
  ErrorMessage,
  FormikProps,
  FieldProps,
  FieldArray,
  ArrayHelpers,
} from 'formik';
import * as Yup from 'yup';
import { FeathersDropdown } from '@inkcloud/components';
import { SemanticField } from '../../../common/Form';

interface BatchFormProps {
  initialValues: any;
  onSubmit: Function;
  isSubmitting?: boolean;
  errorMessage?: string;
}

export const BatchForm: React.FC<BatchFormProps> = (props) => {
  const { errorMessage } = props;

  const BatchSchema = Yup.object().shape({
    orientationType: Yup.string().when('batchType', {
      is: 'imposition',
      then: Yup.string().required('Required'),
    }),
    paper: Yup.string().when('batchType', {
      is: 'imposition',
      then: Yup.string().required('Required'),
    }),
    finalSheetCount: Yup.string().when('batchType', {
      is: 'imposition',
      then: Yup.string().required('Required'),
    }),
    alottedWasteCount: Yup.string().when('batchType', {
      is: 'imposition',
      then: Yup.string().required('Required'),
    }),
  });

  return (
    <Formik
      initialValues={props.initialValues}
      validationSchema={BatchSchema}
      onSubmit={(values, { setSubmitting }) => {
        props.onSubmit(values);
      }}
    >
      {(props: FormikProps<any>) => {
        const {
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
          setFieldValue,
          setFieldTouched,
        } = props;

        return (
          <FormikForm
            className={`ui form ${isSubmitting && !errorMessage ? 'loading' : ''} ${
              errorMessage ? 'error' : ''
            }`}
          >
            <Form.Field>
              <label>Batch Type</label>
              <Form.Group inline>
                <Form.Radio
                  label="Imposition"
                  name="batchType"
                  value="imposition"
                  checked={values.batchType === 'imposition'}
                  onBlur={handleBlur}
                  onChange={() => setFieldValue('batchType', 'imposition')}
                />
                <Form.Radio
                  label="Virtual"
                  name="batchType"
                  value="virtual"
                  checked={values.batchType === 'virtual'}
                  onBlur={handleBlur}
                  onChange={() => setFieldValue('batchType', 'virtual')}
                />
              </Form.Group>
            </Form.Field>

            <Form.Field>
              <label>Orientation Type</label>
              <Form.Group inline>
                <Form.Radio
                  label="Sheet Wise"
                  name="orientationType"
                  value="sheet-wise"
                  checked={values.orientationType === 'sheet-wise'}
                  onChange={() => setFieldValue('orientationType', 'sheet-wise')}
                />
                <Form.Radio
                  label="Work and Turn"
                  name="orientationType"
                  value="work-and-turn"
                  checked={values.orientationType === 'work-and-turn'}
                  onChange={() => setFieldValue('orientationType', 'work-and-turn')}
                />
                <Form.Radio
                  label="Work and Tumple"
                  name="orientationType"
                  value="work-and-tumble"
                  checked={values.orientationType === 'work-and-tumble'}
                  onChange={() => setFieldValue('orientationType', 'work-and-tumble')}
                />
              </Form.Group>
              <ErrorMessage
                render={(msg) => <Message negative size="tiny" content={msg} />}
                name="orientationType"
              />
            </Form.Field>

            <Form.Field>
              <label>Resource</label>
              <Field
                name="resource"
                render={({ field }: FieldProps<any>) => (
                  <FeathersDropdown
                    search
                    selection
                    placeholder="Resource..."
                    serviceName="/production/resources"
                    query={{ $np: 1, $sort: { name: 1 } }}
                    resultMapper={(r) => ({ value: r._id, text: r.name })}
                    {...field}
                    onChange={(e, data) =>
                      field.onChange({ target: { value: data.value, name: field.name } } as any)
                    }
                  />
                )}
              />
            </Form.Field>
            <Form.Field>
              <label>Paper</label>
              <Field
                name="paper"
                render={({ field }: FieldProps<any>) => (
                  <FeathersDropdown
                    search
                    selection
                    placeholder="Paper..."
                    serviceName="/products-legacy/attributes"
                    query={{ $np: 1, $sort: { name: 1 }, entityAttribute: 'PAPER' }}
                    resultMapper={(r) => ({ value: r._id, text: r.name })}
                    {...field}
                    onChange={(e, data) =>
                      field.onChange({ target: { value: data.value, name: field.name } } as any)
                    }
                  />
                )}
              />
              <ErrorMessage
                render={(msg) => <Message negative size="tiny" content={msg} />}
                name="paper"
              />
            </Form.Field>

            <Form.Group widths="equal">
              <Form.Field>
                <SemanticField
                  name="finalSheetCount"
                  label="Final Sheet Count"
                  component={Form.Input}
                />
                <ErrorMessage
                  render={(msg) => <Message negative size="tiny" content={msg} />}
                  name="finalSheetCount"
                />
              </Form.Field>
              <Form.Field>
                <SemanticField
                  name="alottedWasteCount"
                  label="Alotted Waste Count"
                  component={Form.Input}
                />
                <ErrorMessage
                  render={(msg) => <Message negative size="tiny" content={msg} />}
                  name="alottedWasteCount"
                />
              </Form.Field>
            </Form.Group>

            <Form.Group widths="equal">
              <Form.Field>
                <SemanticField name="sheetWidth" label="Sheet Width" component={Form.Input} />
              </Form.Field>
              <Form.Field>
                <SemanticField name="sheetHeight" label="Sheet Height" component={Form.Input} />
              </Form.Field>
            </Form.Group>

            {errorMessage && <Message error content={errorMessage} />}

            <Button primary type="submit" disabled={isSubmitting && !errorMessage} content="Save" />
          </FormikForm>
        );
      }}
    </Formik>
  );
};
