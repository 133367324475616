import { InvoiceDocument } from './InvoiceDocument';
import { InvoicePage } from './InvoicePage';
import { BillTo } from './BillTo';
import { ShipTo } from './ShipTo';
import { Items } from './Items';
import { InvoiceContext as Context } from './InvoiceContext';
import PropertyList from '../shared/PropertyList';

export * from './InvoiceDocument';
export * from './InvoicePage';
export * from './BillTo';
export * from './ShipTo';
export * from './Items';

export const Components = {
  InvoiceDocument,
  InvoicePage,
  BillTo,
  ShipTo,
  Items,
  Context,
  PropertyList,
};
