import * as React from 'react';
import { Switch, Route } from 'react-router-dom';

import Ledger from './Ledger';
import Inventory from './Inventory';
import InventoryLocations from './locations';
import Receive from './receive';

const routes = (props) => (
  <Switch>
    <Route exact path="/products/inventory" component={Inventory} />
    <Route exact path="/products/inventory/locations" component={InventoryLocations} />
    <Route exact path="/products/:productId/inventory-ledger" component={Ledger} />
    <Route exact path="/products/inventory/receive" children={<Receive />} />

    {/* <Route exact path='/products/attributes/add' component={Detail} />
    <Route exact path='/products/attributes/:id' component={Detail} /> */}
  </Switch>
);

export default routes;
