import { Operator, generateOperatorOptions } from '@inkcloud/components';
import { optionLanguages } from './../../../common/translations/languages';

export async function getFilterDefinition() {
  let definition = [
    {
      label: 'First Name',
      value: 'firstName',
      operators: generateOperatorOptions([Operator.Like]),
    },
    {
      label: 'Last Name',
      value: 'lastName',
      operators: generateOperatorOptions([Operator.Like]),
    },
    {
      label: 'Source Languages',
      value: 'languages.source',
      operators: generateOperatorOptions([Operator.Is, Operator.IsNot]),
      valueOptions: optionLanguages.map((r) => ({ value: r.value, label: r.text })),
      type: 'multi-select',
    },
    {
      label: 'Destination Languages',
      value: 'languages.destinations',
      operators: generateOperatorOptions([Operator.Is, Operator.IsNot]),
      valueOptions: optionLanguages.map((r) => ({ value: r.value, label: r.text })),
      type: 'multi-select',
    },
  ];

  return definition;
}
