import * as React from 'react';
import { Button, Segment, Message } from 'semantic-ui-react';
import { MenuForm } from './MenuForm';
import feathers from './../../../../bootstrap/feathers';
import { PageHeaderRow, PreLoader } from '@inkcloud/components';
import { Link } from 'react-router-dom';

import { slugify } from 'underscore.string';

import { RouteComponentProps } from 'react-router-dom';
import { useFeathers } from '@inkcloud/components';

import { connect } from 'react-redux';

interface DetailProps {}

export interface ConnectedProps {
  user?: any;
}

const { useState, useEffect } = React;

const serviceName = 'store-front/content/menus';
const service = feathers.service(serviceName);
const commonParams = { query: {} };
const pathPrefix = `/settings/content-management/menus`;

export const PreDetail: React.FunctionComponent<
  DetailProps & ConnectedProps & RouteComponentProps<{ id?: string }>
> = (props) => {
  const {
    match: {
      params: { id },
    },
    history,
    user,
  } = props;

  const [errorMessage, setErrorMessage] = useState('');

  const isEdit = !!id;
  const [state, reload] = useFeathers('get', serviceName, [id, commonParams], true);

  const handleSubmit = async (values) => {
    const payload = {
      ...values,
      tenant: user.business,
      key: slugify(values?.key ?? ''),
    };
    try {
      setErrorMessage('');
      isEdit
        ? await service.patch(id, payload, commonParams)
        : await service.create(payload, commonParams);
      history.push(pathPrefix);
    } catch (e) {
      e.code && e.code < 500
        ? setErrorMessage(e.message)
        : setErrorMessage('We are experiencing technical difficulties. Please try again');
    }
  };
  const pageTitle = isEdit ? 'Edit' : 'New';

  if (isEdit && !state.data) {
    return <PreLoader />;
  }

  const initialData = { ...state.data };

  return (
    <>
      <PageHeaderRow header={`${pageTitle} Menu`} subheader="" subheaderId="">
        <Link to={`/settings/content-management/menus`}>
          <Button content="Back" size={'mini'} />
        </Link>
      </PageHeaderRow>
      <Segment>
        <MenuForm initialValues={initialData} onSubmit={handleSubmit} errorMessage={errorMessage} />
        {errorMessage && <Message error content={errorMessage} />}
      </Segment>
    </>
  );
};
const mapStateToProps = (state): ConnectedProps => ({
  user: state.auth.user || {},
});

export const Detail = connect(mapStateToProps)(PreDetail as any);
