import * as React from 'react';
import { Button, Form, Input, Message } from 'semantic-ui-react';
import { Formik, Form as FormikForm, FieldProps, FieldArray, Field, ErrorMessage } from 'formik';
import AsyncSelect from 'react-select/async';
import * as Yup from 'yup';

import { FeathersDropdown, useDebounce } from '@inkcloud/components';
import { SemanticField } from '../../../common/Form';
import { slugify2 as slugifyKey } from '../../../common';

import Feathers from '../../../bootstrap/feathers';

interface FulfillmentFormProps {
  initialValues: any;
  onSubmit: Function;
  isSubmitting?: boolean;
  errorMessage: string;
}

const FulfillmentSchema = Yup.object().shape({
  orderItem: Yup.string().required('Required'),
  quantity: Yup.string().required('Required'),
});

export const FulfillmentForm = (props: FulfillmentFormProps) => {
  const { errorMessage } = props;

  // const [options, setOptions] = React.useState([])

  const mapOptionsToValue = (options: any) =>
    options?.map((o) => ({
      label: o?.humanId,
      value: o?._id,
    }));

  const handleLoadOrders: any = async function (e, callback) {
    try {
      const res: any = await Feathers.service('order-items').find({
        query: {
          humanId: { $LIKE: e },
          $sort: { name: 1 },
          $np: 1,
        },
      });

      callback(mapOptionsToValue(res));

      // return res?.map((item) => ({ label: item.humanId, value: item._id }))
    } catch (er) {
      console.error(er);
    }
  };

  return (
    <Formik
      initialValues={props.initialValues}
      validationSchema={FulfillmentSchema}
      onSubmit={(values, { setSubmitting }) => {
        props.onSubmit(values);
      }}
    >
      {(innerProps) => {
        const {
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
          setFieldValue,
          setFieldTouched,
        } = innerProps;

        return (
          <FormikForm
            className={`ui form ${props.isSubmitting && !errorMessage ? 'loading' : ''} ${
              errorMessage ? 'error' : ''
            }`}
          >
            <Form.Field>
              <label>Order Item</label>
              <AsyncSelect
                cacheOptions
                onChange={(e) => setFieldValue('orderItem', e?.value)}
                placeholder="Search order items..."
                loadOptions={handleLoadOrders}
                isClearable={true}
                noOptionsMessage={(v) =>
                  !v.inputValue ? 'Start typing an ID to search...' : 'No options'
                }
                styles={{
                  container: (provided, state) => ({
                    ...provided,
                    // border: '1px solid red',
                    flexGrow: 2,
                    // border: 'none',
                    width: '100%',
                    // flexGrow: 1,
                    // borderRight: '1px solid #eaeaea',
                    padding: 4,
                  }),
                  valueContainer: (provided, state) => ({
                    ...provided,
                    // border: '1px solid none',
                  }),
                  control: (provided, state) => ({
                    ...provided,
                    border: '1px solid #eaeaea',
                  }),
                }}
              />
              <ErrorMessage
                render={(msg) => <Message negative size="tiny" content={msg} />}
                name="orderItem"
              />
            </Form.Field>
            <Form.Field>
              <SemanticField name={`quantity`} label="Quantity" component={Form.Input} />
              <ErrorMessage
                render={(msg) => <Message negative size="tiny" content={msg} />}
                name="quantity"
              />
            </Form.Field>
            <Form.Field>
              <SemanticField name={`notes`} label="Notes" component={Form.TextArea} />
            </Form.Field>

            {errorMessage && <Message error content={errorMessage} />}

            <Button primary type="submit" disabled={isSubmitting && !errorMessage}>
              Save
            </Button>
          </FormikForm>
        );
      }}
    </Formik>
  );
};
