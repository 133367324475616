import * as React from 'react';
import {
  Box,
  Text,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  ButtonGroup,
  Button,
  IconButton,
  Portal,
} from '@chakra-ui/react';
import { BellIcon } from '@chakra-ui/icons';
import { Paginated } from '@feathersjs/feathers';
import { ModelTypes } from '@inkcloud/icapi-types';

import Feathers, { icapi, socket } from '../../bootstrap/feathers';
import MessageItemList from './Messages/MessageItemList';

interface IUserMessagesProps {
  userId: string;
}

const UserMessages: React.FunctionComponent<IUserMessagesProps> = (props) => {
  const { userId } = props;

  const [hasNewMessages, setHasNewMessages] = React.useState(false);
  const [messages, setMessages] = React.useState<ModelTypes.UserMessage[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const loadMessages = async () => {
    const result = (await icapi.service('user-messages').find({
      query: {
        $select: ['_id'],
        recipientUser: userId,
        isRead: { $ne: true },
      },
    })) as Paginated<ModelTypes.UserMessage>;

    setHasNewMessages(result.total > 0);
  };

  const loadMessagesFull = async () => {
    setIsLoading(true);
    const result = (await icapi.service('user-messages').find({
      query: {
        recipientUser: userId,
        $sort: { createdAt: -1 },
      },
    })) as Paginated<ModelTypes.UserMessage>;

    setMessages(result.data);
    setIsLoading(false);
  };

  const handleMarkRead = async (id: string) => {
    await icapi.service('user-messages').patch(id, { isRead: true });
    setMessages(messages.map((m) => (m._id === id ? { ...m, isRead: true } : m)));
  };

  // Real-time: Subscribe to any changes and reload
  React.useEffect(() => {
    const handler = (msg) => {
      // console.log('User Messages: ', msg);
      setHasNewMessages(true);
    };
    loadMessages();
    // socket.service('user-messages').on('patched', handler);
    socket.service('user-messages').on('created', handler);
    return () => {
      // socket.service('user-messages').removeListener('patched', handler)
      socket.service('user-messages').removeListener('created', handler);
    };
  }, []);

  const handleMarkAll = async () => {
    await (icapi.service('user-messages/all-read') as any).create({ isRead: true });
    setHasNewMessages(false);
    loadMessagesFull();
  };

  return (
    <Box mr={4}>
      <Popover offset={[-20, 10]} isLazy>
        <PopoverTrigger>
          <IconButton
            icon={<BellIcon />}
            aria-label="Notifications"
            size="sm"
            variant={'solid'}
            colorScheme={hasNewMessages ? 'blue' : 'gray'}
          />
        </PopoverTrigger>
        <Portal>
          <PopoverContent>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverHeader>Notifications</PopoverHeader>
            <PopoverBody maxH={500} overflowY={'scroll'}>
              <MessageItemList
                userId={userId}
                messages={messages}
                onMarkRead={handleMarkRead}
                loadMessagesFull={loadMessagesFull}
                isLoading={isLoading}
              />
            </PopoverBody>
            <PopoverFooter display="flex" justifyContent="flex-end">
              <ButtonGroup size="sm">
                <Button size={'sm'} variant="outline" onClick={handleMarkAll}>
                  Mark all as read
                </Button>
              </ButtonGroup>
            </PopoverFooter>
          </PopoverContent>
        </Portal>
      </Popover>
    </Box>
  );
};

export default UserMessages;
