import * as React from 'react';
import { Switch, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { List } from './List';
import TabbedList from './TabbedList';
import SupplierList from './SupplierList';
import { Detail } from './Detail';
import Create from './Create';
import { Detail as RFQResponse } from './DetailRFQResponse';

export default function routes(props) {
  const authUser = useSelector((state: any) => state.auth.user);
  const isVendor = !!authUser?.vendor;

  return (
    <Switch>
      <Route exact path="/rfqs" component={isVendor ? SupplierList : TabbedList} />
      {/* <Route exact path="/rfqs/v2" component={isVendor ? SupplierList : TabbedList} /> */}
      <Route exact path="/rfqs/in-production" component={isVendor ? SupplierList : TabbedList} />
      <Route exact path="/rfqs/in-billing" component={isVendor ? SupplierList : TabbedList} />
      <Route exact path="/rfqs/all" component={isVendor ? SupplierList : TabbedList} />
      <Route exact path="/rfqs/archived" component={isVendor ? SupplierList : TabbedList} />
      <Route path="/rfqs/add" component={Create} />
      <Route path="/rfqs/:id" component={isVendor ? RFQResponse : Detail} />
    </Switch>
  );
}
