import * as React from 'react';
import { IndexWrapper, IndexWrappedProps } from './../../../../bootstrap/IndexWrapper';
import { services } from './../../../../bootstrap/feathers';
import { RouteComponentProps } from 'react-router-dom';
import { BatchItems } from './BatchItems';
import * as moment from 'moment';

export interface ContainerProps {
  productionBatch: any;
  loadProductionBatch: Function;
  saveProductionBatchItems: Function;
}

type ExtendedContainerProps = IndexWrappedProps &
  ContainerProps &
  RouteComponentProps<{ id: string }>;

export interface ContainerState {
  pendingItems: any[];
  stagedItems: any[];
  isLoaded: Boolean;
}

class Container extends React.Component<ExtendedContainerProps, ContainerState> {
  constructor(props: ExtendedContainerProps) {
    super(props);

    this.state = {
      pendingItems: [],
      stagedItems: [],
      isLoaded: false,
    };
  }

  sortByHumanId(a, b) {
    const [orderA, itemA] = a.humanId.split('-');
    const [orderB, itemB] = b.humanId.split('-');
    if (orderA === orderB) {
      return parseInt(itemA) - parseInt(itemB);
    }

    return parseInt(orderA) - parseInt(orderB);
  }

  componentDidMount() {
    this.props.loadProductionBatch(this.props.match.params.id, {
      query: {
        $populate: ['resource', 'paper', 'items.orderItem'],
      },
    });
  }

  componentWillReceiveProps(next) {
    const { productionBatch } = this.props;
    if (!productionBatch) {
      return;
    }
    let batchItems = productionBatch && productionBatch.items ? productionBatch.items : [];
    if (
      (!this.props.queryResult && next.queryResult) ||
      (!this.state.isLoaded && next.queryResult)
    ) {
      const mappedQueryResult = next.queryResult.map((oi) => {
        const size = oi.attributes && oi.attributes.find((a) => a.attribute === 'SIZE');

        return {
          _id: oi._id,
          humanId: oi.humanId,
          revenue: oi.revenue,
          size: {
            name: size && size.value && size.value.name,
            width: size && size.value ? parseInt(size.value.width) : 0,
            height: size && size.value ? parseInt(size.value.height) : 0,
          },
          dueDate: oi.estimatedCompletionDate && moment(oi.estimatedCompletionDate).format('DD-MM'),
        };
      });
      const pendingItems = mappedQueryResult
        .filter((i) => {
          const found = batchItems.find((oi) => oi._id === i._id);
          return found ? false : true;
        })
        .sort(this.sortByHumanId);

      const stagedItems = batchItems
        .map((i) => {
          return mappedQueryResult.find((oi) => oi._id === i.orderItem._id);
        })
        .sort(this.sortByHumanId);

      this.setState({
        ...this.state,
        pendingItems: mappedQueryResult,
        stagedItems: stagedItems,
        isLoaded: true,
      });
    }
  }

  handleStageDrop = (orderItem) => {
    // If user drops on same Segment, do not add a duplicate
    if (this.state.stagedItems.find((oi) => oi._id === orderItem._id)) {
      return;
    }
    this.setState({
      ...this.state,
      pendingItems: this.state.pendingItems.filter((oi) => oi._id !== orderItem._id),
      stagedItems: this.state.stagedItems.concat([orderItem].sort(this.sortByHumanId)),
    });
  };

  handleUnstageDrop = (orderItem) => {
    // If user drops on same Segment, do not add a duplicate
    if (this.state.pendingItems.find((oi) => oi._id === orderItem._id)) {
      return;
    }
    this.setState({
      ...this.state,
      stagedItems: this.state.stagedItems.filter((oi) => oi._id !== orderItem._id),
      pendingItems: this.state.pendingItems.concat([orderItem].sort(this.sortByHumanId)),
    });
  };

  handleSubmit = () => {
    this.props
      .saveProductionBatchItems(this.props.productionBatch._id, {
        items: this.state.stagedItems.map((i) => ({
          orderItem: i._id,
          numUps: 1,
        })),
      })
      .then(() => {
        this.props.history.push('/production/batches');
      });
  };

  render() {
    return (
      <div>
        <BatchItems
          productionBatch={this.props.productionBatch}
          handleStageDrop={this.handleStageDrop}
          handleUnstageDrop={this.handleUnstageDrop}
          onSubmit={this.handleSubmit}
          pendingItems={this.state.pendingItems}
          stagedItems={this.state.stagedItems}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  productionBatch: state.productionBatches.data,
});

const mapDispatchToProps = {
  loadProductionBatch: services.productionBatches.get,
  saveProductionBatchItems: services.productionBatches.patch,
};

const filterMapper = (filter) => {
  return {
    name: { $LIKE: filter && filter.name },
  };
};

export default IndexWrapper(Container, 'productionPendingOrderItems', {
  filterMapper,
  query: {
    $populate: ['attributes.value'],
    $sort: { createdAt: -1 },
  },
  mapStateToProps,
  mapDispatchToProps,
});
