import * as React from 'react';
import moment from 'moment';

import { Link, RouteComponentProps } from 'react-router-dom';
import { FormattedDate, FormattedTime } from 'react-intl';
import { useList, PageHeaderRow, PaginatorWrapper, EmptyWrapper } from '@inkcloud/components';
import {
  Segment,
  Grid,
  Image,
  Button,
  Divider,
  Label,
  Popup,
  Icon,
  Table,
} from 'semantic-ui-react';

import { connect } from 'react-redux';

export interface ConnectedProps {
  tenant?: any;
}

interface ListProps {}

const filterQueryMap = (values) => {
  const filterMap: any = {
    filename: values.filename ? { $LIKE: values.filename } : undefined,
    mimeType: values.mimeType ? values.mimeType : undefined,
    createdAt: {
      $gte: values.start ? moment(values.start).format('YYYY-MM-DD') : undefined,
      $lte: values.end ? moment(values.end).format('YYYY-MM-DD') : undefined,
    },
  };

  return [].reduce(
    (m, field) => ({ ...m, [field]: values[field] ? values[field] : undefined }),
    filterMap
  );
};

const serviceName = '/store-front/microsites/content/banners';

const baseUrl = 'https://storage.googleapis.com/icstorage/';

export const PreList: React.FunctionComponent<
  ListProps & RouteComponentProps<{ micrositeId: string }> & ConnectedProps
> = (props) => {
  const {
    match: {
      params: { micrositeId },
    },
    location,
    history,
  } = props;

  const {
    state,
    reload,
    handleDelete,
    selectedItems,
    clearAll,
    setChecked,
    someSelected,
    toggleAll,
    filter,
    setFilter,
    sort,
    handleSort,
  } = useList({
    feathers: ['find', serviceName, [{ query: { microsite: micrositeId } }]],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: {},
  });

  const pathPrefix = `/settings/microsites/${micrositeId}/content/banners`;

  const { queryResult, isLoading: isStateLoading } = state as any;

  return (
    <>
      <PageHeaderRow header="Banners" subheader="Microsite">
        <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
        <Link to={`${pathPrefix}/add`}>
          <Button content="New" primary size={'mini'} />
        </Link>
      </PageHeaderRow>

      <EmptyWrapper queryResult={queryResult} isStateLoading={isStateLoading}>
        <Segment loading={state.isLoading}>
          <PaginatorWrapper.Top
            filter={filter}
            reload={reload}
            data={queryResult as any}
            pathPrefix={`${pathPrefix}`}
          />
          <Table size={'small'} compact>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  <input type="checkbox" onChange={toggleAll} />
                </Table.HeaderCell>
                <Table.HeaderCell>Created</Table.HeaderCell>
                <Table.HeaderCell>Banner Image</Table.HeaderCell>

                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Alt Text</Table.HeaderCell>
                <Table.HeaderCell>Link</Table.HeaderCell>
                <Table.HeaderCell>Published</Table.HeaderCell>
                <Table.HeaderCell>Start Date</Table.HeaderCell>
                <Table.HeaderCell>Expire Date</Table.HeaderCell>
                <Table.HeaderCell>Priority</Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {(queryResult as any)?.data?.map((r: any, index: number) => (
                <Table.Row key={r._id}>
                  <Table.Cell>
                    <input
                      type="checkbox"
                      value={r._id}
                      checked={selectedItems[r._id] || false}
                      onChange={setChecked}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    {r.createdAt && (
                      <Popup
                        size="mini"
                        content={<FormattedTime value={r.createdAt} />}
                        trigger={<FormattedDate value={r.createdAt} />}
                      />
                    )}
                  </Table.Cell>
                  <Table.Cell collapsing>
                    <Image size="tiny" src={`${baseUrl}${r.banner}`} />
                  </Table.Cell>

                  <Table.Cell>{r.name}</Table.Cell>
                  <Table.Cell>{r.altText}</Table.Cell>
                  <Table.Cell>{r.url}</Table.Cell>
                  <Table.Cell>{r.isPublished && <Icon name="check circle outline" />}</Table.Cell>
                  <Table.Cell>
                    {r.startDate && (
                      <Popup
                        size="mini"
                        content={<FormattedTime value={r.startDate} />}
                        trigger={<FormattedDate value={r.startDate} />}
                      />
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {r.expireDate && (
                      <Popup
                        size="mini"
                        content={<FormattedTime value={r.expireDate} />}
                        trigger={<FormattedDate value={r.expireDate} />}
                      />
                    )}
                  </Table.Cell>
                  <Table.Cell>{r.priority}</Table.Cell>

                  <Table.Cell textAlign="right">
                    <Popup
                      size="mini"
                      content="Edit"
                      trigger={
                        <Link to={`${pathPrefix}/${r._id}`}>
                          <Button size={'mini'} icon={'edit'} />
                        </Link>
                      }
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
          <PaginatorWrapper.Bottom
            reload={reload}
            data={queryResult as any}
            pathPrefix={`${pathPrefix}`}
          >
            {someSelected && (
              <Button primary type="button" content="Delete" onClick={handleDelete} />
            )}
          </PaginatorWrapper.Bottom>

          {/* {
          isOpenModal && <ImageUploadModal
            isOpen={isOpenModal}
            onClose={() => { setIsOpenModal(false); setIsImageManager(false) }}
            onReload={reload}
            isImageManager={isImageManager}
            onIsImageManager={() => setIsImageManager(false)}
          />
        } */}
          {/* {
          isSelectOpenModal && <ImageSelectModal
            isOpen={isSelectOpenModal}
            onClose={() => setIsSelectOpenModal(false)}
            onReload={reload}
          />
        } */}
        </Segment>
      </EmptyWrapper>
    </>
  );
};

const mapStateToProps = (state): ConnectedProps => ({
  tenant: state.auth.user.business || {},
});

export const List = connect(mapStateToProps)(PreList as any);
