import { ServiceTypes } from '@inkcloud/icapi-types';

export type OrderFulfillmentSummary = Awaited<ReturnType<ServiceTypes['orders/fulfillment-summary']['create']>>

export interface PickListState {
  isLoading: boolean;
  orderFulfillmentSummary: OrderFulfillmentSummary | null;
  pickListScanLogId: string | null;
  skuScans: {
    quantity: number;
    pickedQuantity: number;
    locationId: string;
    location: string;
    productId: string;
    sku: string;
    description: string;
    orderItemId: string;
    orderItemHumanId: string;
  }[];
  currentPickIndex: number;
  successMessage?: string;
  errorMessage?: {
    order?: string;
    submit?: string
    item?: string;
  };
}

export type PickListAction =
  | { type: 'set-order-fulfillment-summary', payload: { orderFulfillmentSummary: OrderFulfillmentSummary, pickListScanLogId: string } }
  | { type: 'set-is-loading' }
  | { type: 'increment-sku-scan', payload: { sku: string } }
  | { type: 'loading-order-fulfillment-summary', payload: boolean }
  | { type: 'set-success-message', payload: { successMessage: string } }
  | { type: 'clear-success-message' }
  | { type: 'reset' }
  | {
    type: 'set-error-message', payload: {
      order?: string,
      submit?: string,
      item?: string;
    }
  };

export const initialState: PickListState = {
  isLoading: false,
  orderFulfillmentSummary: null,
  skuScans: [],
  currentPickIndex: 0,
  pickListScanLogId: null,
}

export const initializer = (): PickListState => {
  const a = '';
  return {
    ...initialState,
  }
}

export const reducer: React.Reducer<PickListState, any> = (state, action: PickListAction) => {
  const { type } = action;
  const { currentPickIndex, skuScans } = state;
  switch (type) {
    case 'reset':
      return initializer();
    case 'set-success-message':
      return { ...initializer(), successMessage: action.payload.successMessage };
    case 'set-error-message':
      return {
        ...state, errorMessage: {
          order: action.payload.order,
          submit: action.payload.submit,
          item: action.payload.item
        }
      };
    case 'clear-success-message':
      return { ...state, successMessage: undefined };
    case 'loading-order-fulfillment-summary':
      return { ...state, orderFulfillmentSummary: null, isLoading: action.payload };

    case 'set-order-fulfillment-summary': {
      const { payload } = action;
      const { orderFulfillmentSummary, pickListScanLogId } = payload;

      if (!orderFulfillmentSummary) {
        throw new Error('orderFulfillmentSummary is required by action type "set-order-fulfillment-summary"');
      }

      const skuScansUpdated = orderFulfillmentSummary.items?.reduce((acc: PickListState['skuScans'], item) => {
        const locationSku = item.inventoryLocations?.map((location) => ({
          locationId: (location as any).sourceLocation?._id,
          location: (location as any).sourceLocation?.name ?? 'n/a',
          productId: item.orderItem?.product as any,
          sku: item.orderItem?.productKey ?? '-',
          description: item.orderItem?.description ?? '',
          orderItemId: item.orderItem?._id as any,
          orderItemHumanId: item.orderItem?.humanId ?? '',
          pickedQuantity: 0,
          quantity: (location as any).amount, // Location has "amount tacked on by inventory query. Not shown in typings. TODO improve
        })) ?? [] as PickListState['skuScans']

        return [...acc, ...locationSku];
      }, []) as PickListState['skuScans'];

      skuScansUpdated?.sort((a, b) => a.location.localeCompare(b.location));

      return { ...state, orderFulfillmentSummary, pickListScanLogId, skuScans: [...skuScansUpdated], isLoading: false };
    }

    case 'increment-sku-scan': {
      const { payload } = action;
      const { sku } = payload;
      if (!sku) {
        throw new Error('sku is required by action type "increment-sku-scan"');
      }

      if (skuScans[currentPickIndex].sku !== sku) {
        // TODO: This is an unexpected SKU. Display error
      }


      let skuScansUpdated = [...skuScans];
      if (skuScans[currentPickIndex].pickedQuantity + 1 > skuScans[currentPickIndex].quantity) {
        // TODO: Too many picks, Display error
      } else {
        skuScansUpdated = skuScans.map((scan, index) => {
          if (index === currentPickIndex) {
            return { ...scan, pickedQuantity: scan.pickedQuantity + 1 };
          }

          return scan;
        });

      }


      let updatedPickIndex = currentPickIndex;
      if (skuScansUpdated[currentPickIndex].pickedQuantity === skuScansUpdated[currentPickIndex].quantity && currentPickIndex + 1 < skuScansUpdated.length) {
        updatedPickIndex = currentPickIndex + 1;
      }

      return { ...state, skuScans: skuScansUpdated, currentPickIndex: updatedPickIndex, errorMessage: { ...state.errorMessage, item: '' } };
    }

    default:
      throw new Error(`${action.type} is not a valid action type for PickListScanner reducer`);
  }
}

export default reducer;
