import * as React from 'react';
import { Segment, Modal, Image, Icon, Message } from 'semantic-ui-react';

interface ImageModalProps {
  isOpen: boolean;
  imageUrl: string;
  onClose: (v: any) => void;
}

export const ImageModal: React.FunctionComponent<ImageModalProps> = (props) => {
  const { isOpen, imageUrl, onClose } = props;

  return (
    <Modal open={isOpen} onClose={() => onClose('')} closeIcon>
      <Modal.Content>
        <a
          href={imageUrl}
          target="_blank"
          style={{ position: 'absolute', right: '2em', zIndex: 2 }}
        >
          <Icon name="external" />
        </a>
        <Image src={imageUrl} style={{ margin: '0 auto' }} />
      </Modal.Content>
    </Modal>
  );
};
