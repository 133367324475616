import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Feathers from '../../../bootstrap/feathers';
import { PageHeaderRow, To } from '@inkcloud/components';
import { Form, Button, Checkbox, Segment, Message } from 'semantic-ui-react';

interface DetailProps {}

declare var ENV;

const { useEffect, useState } = React;

export const Detail: React.FunctionComponent<
  DetailProps & RouteComponentProps<{ micrositeId: string }>
> = (props) => {
  const {
    match: { params },
    history,
  } = props as any;

  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [urlPrefix, setUrlPrefix] = useState({ key: '', domainName: '' });

  useEffect(() => {
    const loadTenant = async () => {
      const [error, res] = await To(
        Feathers.service('/microsites').get(params.micrositeId, { query: {} })
      );

      if (res) {
        setUrlPrefix({
          key: res.key,
          domainName: res.domain,
        });
      } else {
        error.code && error.code < 500
          ? setErrorMessage(error.message)
          : setErrorMessage('We are experiencing technical difficulties. Please try again');
      }
    };

    loadTenant();
  }, [params.micrositeId]);

  const handleLiveEdit = async () => {
    setIsLoading(true);
    setErrorMessage('');
    const [error, res] = await To(
      Feathers.service('/store-front/content/pages/edit').find({ query: {} })
    );
    setIsLoading(false);

    const isRootLevel = urlPrefix.domainName.split('.').length === 2;

    if (res) {
      if (ENV === 'development' || ENV === 'staging') {
        window.open(`http://${urlPrefix.key}.localdev.inkcloud9.com:3000${res.url}`, '_blank');
      } else {
        // window.open(`https://www.${isRootLevel ? 'www.' : ''}${urlPrefix.domainName}${res.url}`, '_blank');
        window.open(`https://${urlPrefix.domainName}${res.url}`, '_blank');
      }
    } else {
      error.code && error.code < 500
        ? setErrorMessage(error.message)
        : setErrorMessage('We are experiencing technical difficulties. Please try again');
    }
  };

  return (
    <>
      <PageHeaderRow header="Page Edit" subheader="Content" subheaderId={''}>
        <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
      </PageHeaderRow>
      <Segment textAlign="center" padded="very">
        {errorMessage && <Message error content={errorMessage} style={{ textAlign: 'left' }} />}
        <Button
          loading={isLoading}
          primary
          content="Launch Live Edit Mode"
          onClick={handleLiveEdit}
        />
      </Segment>
    </>
  );
};
