import Feathers from '../feathers';

// const dispatch = useDispatch()

enum actionTypes {
  SAVE_USERS = 'PRESENCE_SAVE_USERS',
  SAVE_STATUSES = 'PRESENCE_SAVE_STATUSES',
  SAVE_TENANT = 'PRESENCE_SAVE_TENANT',
  SAVE_CATEGORIES = 'PRESENCE_SAVE_CATEGORIES',
  SAVE_ATTRIBUTES = 'PRESENCE_SAVE_ATTRIBUTES',
  SAVE_ADDONS = 'PRESENCE_SAVE_ADDONS',
}

const initialState = {
  isLoading: false,
  tenant: {
    countryCode: 'US',
    currency: 'USD',
    locale: 'en_US',
  },
  statuses: [],
  categories: [],
  attributes: [],
  addOns: [],
};

export function loadOrderItemStatuses() {
  return (dispatch) => {
    Feathers.service('/order-item-statuses')
      .find({ query: { $np: 1, $sort: { name: 1 }, $select: ['_id', 'name'] } })
      .then((statuses) => {
        dispatch({ type: actionTypes.SAVE_STATUSES, statuses });
      });
  };
}

export function loadCategories() {
  return (dispatch) => {
    Feathers.service('/products-legacy/categories')
      .find({ query: { $np: 1, $sort: { name: 1 }, $select: ['_id', 'name', 'hrCode'] } })
      .then((categories) => {
        dispatch({ type: actionTypes.SAVE_CATEGORIES, categories });
      });
  };
}

export function loadAttributes() {
  return (dispatch) => {
    Feathers.service('/products-legacy/attributes')
      .find({
        query: {
          $np: 1,
          $sort: { name: 1 },
          $select: ['_id', 'name', 'height', 'width', 'hrCode', 'entityAttribute'],
        },
      })
      .then((attributes) => {
        dispatch({ type: actionTypes.SAVE_ATTRIBUTES, attributes });
      });
  };
}

export function loadAddOns() {
  return (dispatch) => {
    Feathers.service('/products-legacy/add-ons-base')
      .find({
        query: {
          $np: 1,
          $sort: { name: 1 },
          $select: ['_id', 'name'],
        },
      })
      .then((addOns) => {
        // console.log('global add ons', addOns)

        dispatch({ type: actionTypes.SAVE_ADDONS, addOns });
      });
  };
}

export function globalReducer(state = initialState, action) {
  switch (action.type) {
    // case actionTypes.SAVE_STATUSES:
    //   return { ...state, statuses: action.statuses }

    // case actionTypes.SAVE_CATEGORIES:
    //   return { ...state, categories: action.categories }

    // case actionTypes.SAVE_ATTRIBUTES:
    //   return { ...state, attributes: action.attributes }

    case actionTypes.SAVE_ADDONS:
      return { ...state, addOns: action.addOns };

    case actionTypes.SAVE_TENANT:
      return { ...state, tenant: action.tenant };

    default:
      return state;
  }
}
