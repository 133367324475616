// import * as React from 'react';
// import { Button, Header, Message, Segment } from 'semantic-ui-react';
// import { RouteComponentProps } from 'react-router-dom';
// import { DetailWrapper, DetailFeaturesProps } from './../../../bootstrap/DetailWrapper';
// import {BatchForm} from './BatchForm';

// export function Detail (props: DetailFeaturesProps ) {
//   const { data } = props;
//   return (
//     <div>
//       <Header as={'h1'}>Production Batch Detail
//         <Header.Subheader>
//           {data && `ID ${data.humanId}`}
//         </Header.Subheader>
//       </Header>
//       <Segment attached>
//         <BatchForm
//           initialValues={data}
//           onSubmit={props.handleSubmit}
//           isEdit={!!props.match.params.id}
//         />
//       </Segment>
//     </div>
//   );
// }

// export default DetailWrapper(Detail, 'productionBatches', {
//   submitRedirect: '/production/batches'
// });

// import * as React from 'react';
// import { Button, Header, Message, Segment } from 'semantic-ui-react';
// import { RouteComponentProps } from 'react-router-dom';
// import { DetailWrapper, DetailFeaturesProps } from './../../../bootstrap/DetailWrapper';
// import ChannelForm from './ChannelForm';

// export function Detail (props: DetailFeaturesProps ) {
//   const { data } = props;
//   return (
//     <div>
//       <Header as={'h1'}>Production Channel Detail
//         <Header.Subheader>
//           {data && `${data.name}`}
//         </Header.Subheader>
//       </Header>
//       <Segment attached>
//         <ChannelForm
//           initialValues={data}
//           onSubmit={props.handleSubmit}
//           isEdit={!!props.match.params.id}
//         />
//       </Segment>
//     </div>
//   );
// }

// export default DetailWrapper(Detail, 'productionChannels', {
//   submitRedirect: '/production/channels'
// });

// production/channels

import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Segment } from 'semantic-ui-react';
import { PageHeaderRow, PreLoader, useFeathers } from '@inkcloud/components';
import feathers from '../../../bootstrap/feathers';
import { BatchForm } from './BatchForm';

interface DetailProps {
  products: { value: string; text: string }[];
  categories: { value: string; text: string }[];
}

const serviceName = 'production/batches';
const pathPrefix = `/production/batches`;
const service = feathers.service(serviceName);
const commonParams = { query: {} };

const { useState } = React;

export const Detail: React.FunctionComponent<DetailProps & RouteComponentProps<{ id?: string }>> = (
  props
) => {
  const {
    match: {
      params: { id },
    },
    history,
  } = props;

  const [errorMessage, setErrorMessage] = useState('');

  const isEdit = !!id;
  const [state, reload] = useFeathers('get', serviceName, [id, commonParams], true);
  const handleSubmit = async (values) => {
    try {
      isEdit
        ? await service.patch(id, values, commonParams)
        : await service.create(values, commonParams);
      history.push(pathPrefix);
    } catch (e) {
      const errMsg =
        e?.code < 500 ? e.message : 'We are experiencing technical difficulties. Please try again';

      setErrorMessage(errMsg);
    }
  };

  const pageTitle = isEdit ? 'Edit' : 'New';

  const initialData = { ...state.data };
  return (
    <div>
      <PageHeaderRow header={`${pageTitle} Batch`} subheader="Production" subheaderId={''}>
        <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
      </PageHeaderRow>
      <Segment>
        {(!isEdit || state.data) && (
          <BatchForm
            initialValues={initialData}
            errorMessage={errorMessage}
            onSubmit={handleSubmit}
          />
        )}
      </Segment>
    </div>
  );
};
