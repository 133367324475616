import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  Stack,
  Alert,
} from '@chakra-ui/react';
import { Option } from 'react-select';
import { AsyncSelect } from 'chakra-react-select';
import DatePicker from 'react-datepicker';
import { useHistory } from 'react-router';
import { Feathers } from '../../bootstrap/feathers/feathers';

interface IAddModalProps {
  onClose: () => void;
}

export async function customerLoad(e: string, callback) {
  try {
    const res = await Feathers.service('customers').find({
      query: {
        $np: 1,
        $select: ['organizationName', 'primaryContact.firstName', 'primaryContact.lastName'],
        $sort: { organizationName: 1 },
        $or: [
          { organizationName: { $LIKE: e } },
          { 'primaryContact.firstName': { $LIKE: e } },
          { 'primaryContact.lastName': { $LIKE: e } },
        ],
      },
    });
    callback(
      res?.map((r) => ({
        value: r._id,
        label: `${r?.organizationName ? `${r?.organizationName} -` : ''} ${
          r?.primaryContact?.firstName ?? ''
        } ${r?.primaryContact?.lastName ?? ''}`,
      }))
    );
  } catch (er) {
    console.error(er);
  }
}
export async function supplierLoad(e: string, callback) {
  try {
    const res = await Feathers.service('production/vendors').find({
      query: {
        $np: 1,
        $select: ['companyName'],
        $sort: { companyName: 1 },
        companyName: { $LIKE: e },
      },
    });
    callback(res?.map((r) => ({ value: r._id, label: r.companyName })));
  } catch (er) {
    console.error(er);
  }
}

export default function AddModal(props: IAddModalProps) {
  const { onClose } = props;

  const history = useHistory();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [vendor, setVendor] = useState<{ label: string; value: string }>();
  const [customer, setCustomer] = useState<{ label: string; value: string }>();

  const handleSubmit = async () => {
    setErrorMessage('');
    setIsSubmitting(true);
    try {
      const res = await Feathers.service('vendor-billing-reconcillations').create({
        startDate: startDate.toISOString().slice(0, 10),
        endDate: endDate.toISOString().slice(0, 10),
        vendor: vendor?.value,
        customer: customer?.value,
      });
      history.push(`${history.location.pathname}/${res._id}`);
    } catch (e) {
      const errMsg =
        e?.code < 500
          ? e?.message
          : 'We are having trouble processing your request. Please try again later.';
      setErrorMessage(errMsg);
    }
    setIsSubmitting(false);
  };
  console.log({ startDate, endDate, vendor, customer });

  const hasErrors = !startDate || !endDate || !vendor;

  return (
    <Modal isOpen={true} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>New Supplier Invoice Reconcilliation</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack direction="row" spacing={2}>
            <FormControl mb={3} isInvalid={!startDate}>
              <DatePicker
                selected={startDate}
                selectsStart
                endDate={endDate}
                onChange={(date) => setStartDate(date as Date)}
              />
            </FormControl>
            <FormControl mb={3} isInvalid={!endDate}>
              <DatePicker
                selected={endDate}
                selectsEnd
                startDate={startDate}
                minDate={startDate}
                onChange={(date) => setEndDate(date as Date)}
              />
            </FormControl>
          </Stack>
          <FormControl mb={3} isInvalid={!vendor}>
            <AsyncSelect
              useBasicStyles
              cacheOptions
              isClearable
              value={vendor}
              onChange={(e: Option) => setVendor(e)}
              placeholder="Select a supplier..."
              loadOptions={supplierLoad as any}
              noOptionsMessage={(v) => (!v.inputValue ? 'Start typing to search...' : 'No options')}
            />
          </FormControl>
          <FormControl mb={3}>
            <AsyncSelect
              useBasicStyles
              cacheOptions
              isClearable
              value={customer}
              onChange={(e: Option) => setCustomer(e)}
              placeholder="Select a customer... (optional)"
              loadOptions={customerLoad as any}
              noOptionsMessage={(v) => (!v.inputValue ? 'Start typing to search...' : 'No options')}
            />
          </FormControl>
          {errorMessage && (
            <Alert status="error" my={3}>
              {errorMessage}
            </Alert>
          )}
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="blue"
            isDisabled={hasErrors || isSubmitting}
            isLoading={isSubmitting}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
