import * as React from 'react';
import { RouteComponentProps, Link } from 'react-router-dom';
import { FormattedDate, FormattedTime } from 'react-intl';
import {
  useList,
  ListPage,
  PageHeaderRow,
  PaginatorWrapper,
  EmptyWrapper,
} from '@inkcloud/components';
import { Segment, Image, Button, Popup, Table } from 'semantic-ui-react';

import { Feathers } from '../../../../bootstrap/feathers/feathers';

interface ListProps {}

const filterQueryMap = (values) => {
  const filterMap: any = {};

  return [].reduce(
    (m, field) => ({ ...m, [field]: values[field] ? values[field] : undefined }),
    filterMap
  );
};

const serviceName = '/promo-codes';
const pathPrefix = `/settings/promo/codes`;

const { useState, useEffect } = React;

export const List: React.FunctionComponent<ListProps & RouteComponentProps> = (props) => {
  const {
    match: { params },
    location,
    history,
  } = props;

  const [campaigns, setCampaigns] = useState([]);

  useEffect(() => {
    const loadCampaigns = async () => {
      try {
        const res: any = await Feathers.service('/promo-codes-campaigns').find({
          query: {},
        });

        setCampaigns(res.data);
      } catch (e) {}
    };

    loadCampaigns();
  }, []);

  const useListValues = useList({
    feathers: [
      'find',
      serviceName,
      [
        {
          query: {
            $sort: { createdAt: -1 },
          },
        },
      ],
    ],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  const headerActions = (
    <>
      <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
      <Link to="/settings/promo-codes/campaigns">
        <Button size="mini" primary content="Campaigns" />
      </Link>
      <Link to={`${pathPrefix}/add`}>
        <Button size="mini" primary content="New" />
      </Link>
    </>
  );

  const tableDefintion = [
    {
      key: 'createdAt',
      label: 'Date',
      sortable: true,
      cell: (v, r) => (
        <Popup
          size="mini"
          content={<FormattedTime value={r.createdAt} />}
          trigger={
            <div>
              <FormattedDate value={r.createdAt} />
            </div>
          }
        />
      ),
    },
    {
      key: 'promoCode',
      label: 'Promo Code',
      cell: (v, r) => v,
    },
    {
      key: 'campaign',
      label: 'Campaign',
      cell: (v) => campaigns?.find((c) => c._id === v)?.name,
    },
    {
      key: '',
      label: 'Start Date',
      cell: (v, r) =>
        r.startDate && (
          <>
            <FormattedDate value={r.startDate} /> <FormattedTime value={r.startDate} />
          </>
        ),
    },
    {
      key: '',
      label: 'Expiration Date',
      cell: (v, r) =>
        r.expirationDate && (
          <>
            <FormattedDate value={r.expirationDate} /> <FormattedTime value={r.expirationDate} />
          </>
        ),
    },
    {
      key: '',
      label: '',
      cell: (v, r) => (
        <>
          <Popup
            size="mini"
            content="View Details"
            align
            trigger={
              <Link to={`${pathPrefix}/${r._id}`}>
                <Button size="mini" icon="edit" />
              </Link>
            }
          />
        </>
      ),
      textAlign: 'right',
    },
  ];

  return (
    <>
      <ListPage
        header="Promo Code"
        subheader="Settings"
        useListValues={useListValues}
        history={history}
        pathPrefix={pathPrefix}
        tableDefintion={tableDefintion}
        withCheckboxes={true}
        headerActions={headerActions}
      />
    </>
  );
};
