export function extendWithFilter(serviceName, services) {
  if (typeof serviceName !== 'string') {
    return;
  }
  const SERVICE_NAME = `SERVICES_${serviceName.toUpperCase()}_`;

  return (state, action) => {
    switch (action.type) {
      case `${SERVICE_NAME}SET_FILTER`:
        return {...state, filter: action.filter, isFiltered: true };

      case `${SERVICE_NAME}CLEAR_FILTER`:
        return {...state, filter: null, isFiltered: false };

      case `${SERVICE_NAME}SET_SELECTED`:
        let selectedSet = Object.assign({}, state.selected);
        selectedSet[action.id] = true;
        return {...state, selected: selectedSet };

      case `${SERVICE_NAME}SET_UNSELECTED`:
        const selectedNotSet = Object.assign({}, state.selected);
        selectedNotSet[action.id] = false;
        return {...state, selected: selectedNotSet };

      case `${SERVICE_NAME}SET_ALL_SELECTED`:
        let selectedAllSet = {};
        if (Array.isArray(state.queryResult)) {
          selectedAllSet = state.queryResult.reduce((prev, cur) => {
            prev[cur._id] = true;
            return prev;
          }, {})
        } else {
          selectedAllSet = state.queryResult.data.reduce((prev, cur) => {
            prev[cur._id] = true;
            return prev;
          }, {})
        }
        return {...state, selected: selectedAllSet, allSelected: true };

      case `${SERVICE_NAME}SET_ALL_UNSELECTED`:
        let selectedAllUnselectedSet = {};
        return {...state, selected: selectedAllUnselectedSet, allSelected: false };

      default:
        if (!services[serviceName]) {
          throw Error(`Service name '${serviceName}' does not have a reducer`);
        }
        return services[serviceName].reducer(state, action);
    }
  }
}
