import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Modal, Table, Button, Input, Checkbox } from 'semantic-ui-react';

import {
  PaginatorWrapper,
  useList,
  GenericFilter,
  basicQueryMap,
  EmptyWrapper,
} from '@inkcloud/components';

import { ProductName } from '../../../../common/ProductName';
import { Feathers } from '../../../../bootstrap/feathers/feathers';

import { getFilterDefinition } from './FilterForm';

interface ProductModalProps {
  isOpen: boolean;
  title?: string;
  onClose: () => void;
  onAddingProduct: (v: boolean) => void;
  products: string[];
  setFieldValue: (fieldName: string, value: any) => void;
  criterias?: any[];
}

const serviceName = '/products-legacy';

const { useState, useEffect } = React;

const filterQueryMap = (values) => {
  let filterRows = basicQueryMap(values, {
    name: 'staticName',
  });

  // Map attributes as needed by API
  const prefix = 'attributes:';
  const attributes = Object.keys(filterRows)
    .filter((r) => r.startsWith(prefix))
    .reduce((acc, cur) => ({ ...acc, [cur.slice(prefix.length)]: filterRows[cur]?.$in }), {});

  // Delete the original attribute valuess so they are not submitted
  Object.keys(filterRows)
    .filter((r) => r.startsWith(prefix))
    .forEach((r) => delete filterRows[r]);

  const stockTypes = ['stock', 'stock-parent', 'stock-child'];
  filterRows = {
    ...filterRows,
    addOns: filterRows.addOns
      ? { $elemMatch: { id: filterRows.addOns, isEnabled: true } }
      : undefined,
    attributes: attributes,
    hasVariants:
      filterRows.type === 'stock-parent'
        ? true
        : filterRows.type === 'stock-child'
        ? { $ne: true }
        : undefined,
    inventory: stockTypes.includes(filterRows.type)
      ? true
      : filterRows.type === 'on-demand'
      ? { $ne: true }
      : undefined,
    isComposite: filterRows.type === 'composite' ? true : undefined,
  };

  return filterRows;
};

export const PreProductModal: React.FunctionComponent<
  ProductModalProps & RouteComponentProps<{ id: string }>
> = (props) => {
  const {
    isOpen,
    onClose,
    products,
    setFieldValue,
    location,
    history,
    match: {
      params: { id },
    },
    onAddingProduct,
    title,
    criterias,
  } = props;

  const pathPrefix = `/settings/promo/codes/${id}`;
  const [productIDs, setProductIDs] = useState(products || []);

  const {
    state,
    reload,
    handleDelete,
    selectedItems,
    clearAll,
    setChecked,
    setSelectedItems,
    someSelected,
    toggleAll,
    filter,
    setFilter,
    sort,
    handleSort,
  } = useList({
    feathers: [
      'find',
      serviceName,
      [
        {
          query: {
            $populate: ['attributes.value', 'category'],
            $populateAddOns: 1,
          },
        },
      ],
    ],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  useEffect(() => {
    if (criterias) {
      setFilter({ rows: criterias });
    }
  }, [criterias]);

  const { queryResult, isLoading: isStateLoading } = state as any;

  const handleToggleProduct = (id: string) => {
    const foundId = productIDs.find((prodId) => prodId === id);

    if (foundId) {
      const filtered = productIDs.filter((prodId) => prodId !== id);

      setProductIDs(filtered);
    } else {
      setProductIDs([...productIDs, id]);
    }
  };

  const handleSelect = () => {
    setFieldValue('products', productIDs);
    onAddingProduct(false);
    onClose();
  };

  const isSpecificProduct = title === 'Specific Products';

  return (
    <Modal open={isOpen} onClose={onClose} closeIcon>
      <Modal.Header>{title || 'Products'}</Modal.Header>
      <Modal.Content>
        <GenericFilter
          factDefinitionFunction={getFilterDefinition}
          value={filter}
          onChange={(v) => {
            setFilter({ ...v });
          }}
        />
        {filter?.rows?.length > 0 && (
          <Button
            primary
            content="Select Criteria"
            onClick={() => {
              setFieldValue('criteria', filter.rows);
              onClose();
            }}
          />
        )}
        {isSpecificProduct && (
          <EmptyWrapper queryResult={queryResult} isStateLoading={isStateLoading}>
            <PaginatorWrapper.Top
              filter={filter}
              reload={reload}
              data={queryResult as any}
              pathPrefix={`${pathPrefix}/`}
            />
            <Table size="small">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell />
                  <Table.HeaderCell>Product</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {queryResult?.data?.map((prod, index) => {
                  const matchedId = productIDs.find((prodId) => prodId === prod._id);
                  return (
                    <Table.Row key={index}>
                      <Table.Cell>
                        <Checkbox
                          defaultChecked={matchedId ? true : false}
                          onClick={() => handleToggleProduct(prod._id)}
                        />
                      </Table.Cell>
                      <Table.Cell>{ProductName(prod, true)}</Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
            <PaginatorWrapper.Bottom
              reload={reload}
              data={queryResult as any}
              pathPrefix={`${pathPrefix}`}
            >
              {productIDs?.length > 0 && (
                <Button
                  primary
                  content={`Select Product${productIDs?.length > 1 ? 's' : ''}`}
                  onClick={handleSelect}
                />
              )}
            </PaginatorWrapper.Bottom>
          </EmptyWrapper>
        )}
      </Modal.Content>
    </Modal>
  );
};

export const ProductModal = withRouter(PreProductModal);
