import * as React from 'react';
import { Switch, Route } from 'react-router-dom';

import List from './List';
// import Detail from './Detail';

const routes = (props) => (
  <Switch>
    <Route exact path="/production/status-grid" component={List} />
  </Switch>
);

export default routes;
