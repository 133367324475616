import * as React from 'react';
import { Button, Dropdown, Label, LabelDetail, Segment, Message } from 'semantic-ui-react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { humanize } from 'underscore.string';
import { parseISO, isValid } from 'date-fns';
import { FormattedDate, FormattedTime } from 'react-intl';
import { PageHeaderRow, PreLoader, useFeathers } from '@inkcloud/components';
import { StockProductForm } from './StockForm';

import Feathers from '../../../../bootstrap/feathers';

import { CreateVariantsModal } from './CreateVariantsModal';
import { CreateVersionModal } from './CreateVersionModal';
import { ProductTypeModal } from '../ProductTypeModal';
import { DuplicateProductModal } from '../DuplicateProductModal';

import NotesComponent from '../../../../common/notes2/index';

const { useState } = React;

const serviceName = '/products-legacy';
const pathPrefix = `/products/products`;
const service = Feathers.service(serviceName);
const commonParams = {
  query: {
    $populate: [
      'category',
      'notes.user',
      'documents.contentImage',
      'images.contentImage',
      'parent',
      'versionOf',
    ],
  },
};

export const Detail: React.FunctionComponent<RouteComponentProps<{ id?: string }>> = (props) => {
  const {
    match: {
      params: { id },
    },
    location,
    history,
  } = props as any;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isVersionModalOpen, setIsVersionModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isNoteModalOpen, setIsNoteModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    submit: '',
    notes: '',
    standAlone: '',
  });

  const [isProductTypeModalOpen, setIsProductTypeModalOpen] = useState(false);
  const [isProductDuplicateModalOpen, setIsProductDuplicateModalOpen] = useState(false);

  const isEdit = !!id;
  const [state, reload] = useFeathers('get', serviceName, [id, commonParams], true);

  const handleSubmit = async (values: any) => {
    setErrorMessage({ ...errorMessage, submit: '' });

    if (!isEdit) {
      values.inventory = true;
    }

    const payload = {
      ...values,
      category: (values?.category?._id ? values?.category?._id : values?.category) || null,
      startAt: isValid(values?.startAt) ? values?.startAt : parseISO(values?.startAt) || null,
      expireAt: isValid(values?.expireAt) ? values?.expireAt : parseISO(values?.expireAt) || null,
      isTaxable: values?.isTaxable ?? true,
      parent: values.parent === undefined ? undefined : values.parent,
      key: values?.key?.replace(/-+$/, ''), // Trim (-) hyphen from end of text
    };

    if (values.parent === undefined || values.parent === null) {
      payload.$unset = {
        parent: 1
      }
    }

    try {
      setIsSubmitting(true);
      const res = isEdit
        ? await service.patch(id, payload, commonParams)
        : await service.create(payload, commonParams);

      if (res.result !== undefined && !res.result) {
        setErrorMessage(res.message || res.errorMessage);
      }

      history.replace({
        pathname: pathPrefix,
        state: {
          ...(location?.state || {}),
        },
      });
      setIsSubmitting(false);
    } catch (e) {
      setIsSubmitting(false);
      const errMsg =
        e.code && e.code < 500
          ? e.message
          : 'We are experiencing technical difficulties. Please try again';
      setErrorMessage({ ...errorMessage, submit: errMsg });
    }
  };

  const handleNoteSubmit = async (value) => {
    setErrorMessage({ ...errorMessage, notes: '' });
    try {
      const res: any = await Feathers.service('/products-legacy/notes').create(
        {
          productId: id,
          content: value,
        },
        { query: {} }
      );

      if (res) {
        reload();
        setIsNoteModalOpen(false);
      }
    } catch (e) {
      const errMsg =
        e.code && e.code < 500
          ? e.message
          : 'We are experiencing technical difficulties. Please try again';
      setErrorMessage({ ...errorMessage, notes: errMsg });
    }
  };

  const handleConvertToStandAlone = async () => {
    setErrorMessage({ ...errorMessage, standAlone: '' });

    try {
      const res: any = await service.patch(
        id,
        {
          versionOf: null,
          version: null,
        },
        commonParams
      );

      window.location.reload();
    } catch (e) {
      const errMsg =
        e.code && e.code < 500
          ? e.message
          : 'We are experiencing technical difficulties. Please try again';
      setErrorMessage({ ...errorMessage, standAlone: errMsg });
    }
  };

  const pageTitle = isEdit ? 'Edit' : 'New';

  if (isEdit && !state.data) {
    return <PreLoader />;
  }

  const initialData = { ...state.data, isTaxable: state.data?.isTaxable ?? true };

  return (
    <div>
      <PageHeaderRow
        header={`${pageTitle} ${
          initialData?.parent
            ? `Variant of ${humanize(initialData?.parent?.staticName ?? '')} - ${
                initialData?.parent?.key ?? ''
              }`
            : ''
        }`}
        subheader="Stock Product"
      >
        {isEdit && (
          <>
            <Label name='Created'  outline size="small"  >
              Created
              <LabelDetail><FormattedDate value={initialData?.createdAt} /></LabelDetail>
            </Label>
            {initialData?.version > 1 && (
              <>
                <Label
                  size="small"

                  detail={`${initialData.version} / ${initialData?.staticName}`}
                  content={'Version'}
                  color="green"
                ></Label>
              </>
            )}

            <Dropdown
              className="icon mini"
              position="left"
              direction="left"
              button
              icon="ellipsis horizontal"
            >
              <Dropdown.Menu>
                {initialData?.version > 1 && (
                  <Dropdown.Item
                    content={'Convert to Standalone Product'}
                    onClick={handleConvertToStandAlone}
                  />
                )}

                <Dropdown.Item
                  content={'Duplicate Product'}
                  onClick={() => setIsProductDuplicateModalOpen(true)}
                />
                <Dropdown.Item
                  content={'Change Product Type'}
                  onClick={() => setIsProductTypeModalOpen(true)}
                />
                <Dropdown.Item
                  content={'Create Version'}
                  onClick={() => setIsVersionModalOpen(true)}
                />
                <Dropdown.Item content={'Create Variants'} onClick={() => setIsModalOpen(true)} />
                <Dropdown.Item content={'Create Note'} onClick={() => setIsNoteModalOpen(true)} />
              </Dropdown.Menu>
            </Dropdown>

            {/* <Button
              size="mini"
              onClick={() => setIsProductDuplicateModalOpen(true)}
              content="Duplicate Product"
            />
            <Button
              size="mini"
              onClick={() => setIsProductTypeModalOpen(true)}
              content="Change Product Type" />
            <Button
              content="Create Version"
              size={"mini"}
              onClick={() => setIsVersionModalOpen(true)}
            />
            <Button
              content="Create Variants"
              size={"mini"}
              onClick={() => setIsModalOpen(true)}
            />
            <Button
              content="Create Note"
              size={"mini"}
              onClick={() => setIsNoteModalOpen(true)}
            /> */}
          </>
        )}
        <Link
          to={{
            pathname: '/products/products',
            state: {
              ...(location?.state || {}),
            },
          }}
        >
          <Button content="Back" size={'mini'} />
        </Link>
      </PageHeaderRow>
      {errorMessage?.standAlone && <Message error content={errorMessage.standAlone} />}

      {isEdit && (
        <Segment>
          <NotesComponent
            isModalOpen={isNoteModalOpen}
            notes={initialData?.notes || []}
            onSubmit={handleNoteSubmit}
            handleNoteClose={() => setIsNoteModalOpen(false)}
            errorMessage={errorMessage.notes}
            maxWidth={'100%'}
          />
        </Segment>
      )}

      {(!isEdit || state.data) && (
        <StockProductForm
          isEdit={isEdit}
          initialValues={initialData}
          handleSubmit={handleSubmit}
          isSubmitting={isSubmitting}
          errorMessage={errorMessage.submit}
        />
      )}

      {isEdit && isProductTypeModalOpen && (
        <ProductTypeModal
          isOpen={isProductTypeModalOpen}
          onClose={() => setIsProductTypeModalOpen(false)}
          product={state.data}
          redirect={pathPrefix}
          history={history}
        />
      )}

      {isProductDuplicateModalOpen && (
        <DuplicateProductModal
          isOpen={isProductDuplicateModalOpen}
          onClose={() => setIsProductDuplicateModalOpen(false)}
          product={state.data}
        />
      )}

      <CreateVariantsModal
        open={isModalOpen}
        product={initialData}
        handleClose={() => setIsModalOpen(false)}
        handleSubmit={() => {
          setIsModalOpen(false);
          history.push('/products/products');
        }}
      />
      <CreateVersionModal
        open={isVersionModalOpen}
        product={initialData}
        handleClose={() => setIsVersionModalOpen(false)}
        handleSubmit={() => setIsVersionModalOpen(false)}
      />
    </div>
  );
};
