import * as React from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { Form, Input, Select } from 'semantic-ui-react';
// import Select from 'react-select'

const { useState } = React;

export type SelectOptions = { key: string; value: string }[];

export interface IValueInputProps {
  value: any;
  type: string;
  onChange: (v: any) => void;
  options: SelectOptions;
  placeholder?: string;
}

export const formatToDateString = (v) => moment(v).format('MM/DD/YYYY');

export const ValueInput: React.FunctionComponent<IValueInputProps> = (props) => {
  const { value, type, placeholder = '', options, onChange } = props;
  const [date, setDate] = useState();
  const [selectValue, setSelectValue] = useState(type === 'multi-select' ? [] : '');
  const [inputValue, setInputValue] = useState('');

  const handleDateChange = (v) => {
    setDate(v as any);
    onChange({ [value]: formatToDateString(v) });
  };

  const handleSelectChange = (e, data) => {
    setSelectValue(data.value);
    onChange({ [value]: data.value });
  };

  const handleInputChange = (e) => {
    setInputValue(e.target?.value);
    onChange({ [value]: e.target?.value });
  };

  let el = null;
  if (type === 'date' || type === 'datepicker') {
    el = (
      <>
        <Form.Field>
          <DatePicker
            name={value}
            selected={date}
            autoComplete="off"
            placeholderText={placeholder}
            onChange={handleDateChange}
            selectsStart
            startDate={date}
            className="formMinHeight"
          />
        </Form.Field>
      </>
    );
  } else if (type === 'select' || type === 'multi-select') {
    const components = {
      DropdownIndicator: null,
    };
    el = (
      <>
        <Form.Field>
          <Select
            multiple={type === 'multi-select'}
            clearable
            name={value}
            // components={components}
            onChange={handleSelectChange}
            placeholder={placeholder}
            options={options}
            value={selectValue}
            // styles={{
            //   option: (provided, state) => ({
            //     ...provided,
            //     padding: 5
            //   })
            // }}
          />
        </Form.Field>
      </>
    );
  } else {
    el = (
      <>
        <Form.Field>
          <input
            type="text"
            name={value}
            value={inputValue}
            placeholder={placeholder}
            onChange={handleInputChange}
            className="formMinHeight"
          />
        </Form.Field>
      </>
    );
  }

  return el;
};
