import * as React from 'react';
import { Switch, Route } from 'react-router-dom';

import Pages from './pages';
import Edit from './edit';

const routes = (props) => (
  <Switch>
    <Route path="/content/pages" component={Pages} />
    <Route path="/content/edit" component={Edit} />
  </Switch>
);

export default routes;
