import * as React from 'react';
import { Button, Grid, Header, Segment, Sticky } from 'semantic-ui-react';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import * as numeral from 'numeral';
import DropSegment from './DropSegment';
import { BatchInfoTable } from './BatchInfoTable';

export interface BatchItemsProps {
  productionBatch: any;
  pendingItems: any[];
  stagedItems: any[];
  onSubmit: any;
  handleUnstageDrop: any;
  handleStageDrop: any;
}

class BatchItemsPre extends React.PureComponent<BatchItemsProps, any> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleContextRef = (contextRef) => this.setState({ contextRef });

  render() {
    const { contextRef } = this.state;
    const { props } = this;
    const { pendingItems = [], stagedItems = [] } = props;
    const { handleStageDrop, handleUnstageDrop } = props;
    const pendingArea = pendingItems.reduce((prev, cur) => {
      if (!cur) {
        return prev;
      }
      return prev + cur.size.width * cur.size.height;
    }, 0);

    const stagingArea = stagedItems.reduce((prev, cur) => {
      if (!cur) {
        return prev;
      }
      return prev + cur.size.width * cur.size.height;
    }, 0);

    const pendingRevenue = pendingItems.reduce((prev, cur) => {
      if (!cur) {
        return prev;
      }

      return prev + cur.revenue;
    }, 0);

    const stagingRevenue = stagedItems.reduce((prev, cur) => {
      if (!cur) {
        return prev;
      }
      return prev + cur.revenue;
    }, 0);

    return (
      <div>
        <DndProvider backend={HTML5Backend}>
          <Header as="h1">Production Batch Items</Header>
          <BatchInfoTable productionBatch={props.productionBatch} />
          <Segment>
            <Button primary onClick={props.onSubmit} style={{ marginBottom: 15 }}>
              Save Batch
            </Button>
            <Grid columns={2} stretched style={{ height: '100%' }}>
              <Grid.Column stretched verticalAlign={'top'} style={{ height: '100%' }}>
                <div ref={this.handleContextRef}>
                  <Segment>
                    Revenue: {numeral(pendingRevenue).format('$0,0.00')}, Area: {pendingArea} sq in
                  </Segment>
                  <DropSegment
                    style={{ height: '100%' }}
                    title={'Pending Items'}
                    items={pendingItems}
                    onDrop={handleUnstageDrop}
                  />
                </div>
              </Grid.Column>
              <Grid.Column stretched verticalAlign={'top'} style={{ height: '100%' }}>
                <Sticky context={contextRef}>
                  <Segment>
                    Revenue: {numeral(stagingRevenue).format('$0,0.00')}, Area: {stagingArea} sq in
                  </Segment>
                  <DropSegment
                    style={{ maxHeight: 500, minHeight: 100, overflowY: 'scroll' }}
                    title={'Items in Batch'}
                    items={stagedItems}
                    onDrop={handleStageDrop}
                  />
                </Sticky>
              </Grid.Column>
            </Grid>
          </Segment>
        </DndProvider>
      </div>
    );
  }
}

export const BatchItems = BatchItemsPre;
