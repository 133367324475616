// import * as React from 'react';
// import { Button, Segment, Table, Popup, Label } from 'semantic-ui-react';
// import { Link, RouteComponentProps } from 'react-router-dom';
// import { FormattedDate, FormattedTime } from 'react-intl';
// // import { FilterForm } from './FilterForm';

// import { useList, PageHeaderRow, PreLoader, EmptyWrapper, PaginatorWrapper } from '@inkcloud/components';

// interface ListProps { }

// const serviceName = '/tenant-settings/notification-settings';
// const pathPrefix = '/settings/notification-rules';

// const filterQueryMap = (values) => { };

// export const List: React.FunctionComponent<ListProps & RouteComponentProps<any>> = (props) => {
//   const { match: { params }, location, history } = props;

//   const { state, reload, handleDelete, selectedItems, clearAll, setChecked, someSelected, toggleAll, filter, setFilter, sort, handleSort } = useList({
//     feathers: ['find', serviceName, [{ query: {} }]],
//     filterMap: filterQueryMap,
//     debounce: 150,
//     location,
//     history,
//     sort: { createdAt: -1 }
//   })

//   const { queryResult, isLoading: isStateLoading } = state as any;

//   if (!queryResult) {
//     return null;
//   }

//   return (
//     <div>
//       <PageHeaderRow header="Notification Rules" subheader="Settings">
//         <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
//         <Link to={`${pathPrefix}/add`}>
//           <Button content="New" primary size={'mini'} />
//         </Link>
//       </PageHeaderRow>

//       <EmptyWrapper queryResult={queryResult} isStateLoading={isStateLoading}>
//         <Segment loading={state.isLoading}>
//           <PaginatorWrapper.Top filter={filter} reload={reload} data={(queryResult as any)} pathPrefix={`${pathPrefix}`} />
//           <Table size={'small'} sortable>
//             <Table.Header>
//               <Table.Row>

//                 <Table.HeaderCell>
//                   Key
//               </Table.HeaderCell>
//                 <Table.HeaderCell>
//                   Enable
//               </Table.HeaderCell>
//                 <Table.HeaderCell />
//               </Table.Row>
//             </Table.Header>
//             <Table.Body>
//               {queryResult && (queryResult as any).map((r: any, index: number) => (
//                 <Table.Row key={index} verticalAlign="top">

//                   <Table.Cell>
//                     {r.templateKey}
//                   </Table.Cell>
//                   <Table.Cell>
//                     <Label color={r.enable ? 'green' : 'red'} content={r.enable ? 'True' : 'False'} />
//                   </Table.Cell>
//                   <Table.Cell textAlign="right">
//                     <Popup
//                       size='mini'
//                       content="Edit"
//                       trigger={
//                         <Link to={`${pathPrefix}/${r.templateKey}`}>
//                           <Button size={'mini'} icon={'edit'} />
//                         </Link>
//                       }
//                     />
//                   </Table.Cell>

//                 </Table.Row>
//               ))}
//             </Table.Body>
//           </Table>
//           <PaginatorWrapper.Bottom reload={reload} data={(queryResult as any)} pathPrefix={`${pathPrefix}`}>
//             {someSelected && <Button primary type="button" content="Delete" onClick={handleDelete} />}
//           </PaginatorWrapper.Bottom>
//         </Segment>
//       </EmptyWrapper>
//     </div>
//   );
// }

import * as React from 'react';
import { Button, Box, IconButton, ButtonGroup, Tag, Tooltip } from '@chakra-ui/react';

import { Link, RouteComponentProps } from 'react-router-dom';

import { FaEdit } from 'react-icons/fa';

import { useList, ListPage } from '@inkcloud/components';

interface ListProps {}

const serviceName = '/tenant-settings/notification-settings';
const pathPrefix = '/settings/notification-rules';

const filterQueryMap = (values) => {};

export default function List(props: ListProps & RouteComponentProps) {
  const {
    match: { params },
    location,
    history,
  } = props;

  const useListValues = useList({
    feathers: ['find', serviceName, [{ query: {} }]],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  const tableDefintion = [
    {
      key: '',
      label: 'Key',
      cell: (v, r) => r?.templateKey,
    },

    {
      key: 'enable',
      label: 'Enable',
      cell: (v) => v && <Tag colorScheme="green">True</Tag>,
    },

    {
      key: '',
      label: '',
      cell: (v, r) => (
        <Tooltip label="Edit Details">
          <Link to={`${pathPrefix}/${r.templateKey}`}>
            <IconButton colorScheme="gray" aria-label="Edit" icon={<FaEdit />} />
          </Link>
        </Tooltip>
      ),
      textAlign: 'right',
    },
  ];

  const headerActions = (
    <ButtonGroup spacing={1}>
      <Button onClick={() => history.goBack()} size="sm">
        Back
      </Button>
      <Link to={`${pathPrefix}/add`}>
        <Button colorScheme="blue">New</Button>
      </Link>
    </ButtonGroup>
  );

  return (
    <Box>
      <ListPage
        header="Notification Rules"
        subheader="Settings"
        useListValues={useListValues}
        history={history}
        pathPrefix={pathPrefix}
        headerActions={headerActions}
        tableDefintion={tableDefintion}
        withCheckboxes={false}
        getFilterDefinition={null}
      />
    </Box>
  );
}
