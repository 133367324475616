import * as React from 'react';
import { Label, Input, Dropdown, Form, Message } from 'semantic-ui-react';
import { ImageField } from './ImageField';

import Select from 'react-select';

type FieldType =
  | 'text'
  | 'textarea'
  | 'select'
  | 'dropdown'
  | 'list'
  | 'hidden'
  | 'image'
  | 'qrcode';

interface Field {
  key: string;
  label: string;
  type: FieldType;
  value?: any;
  data?: any;
  selectAllowOther?: boolean;
  tempValue?: string;
  imageOptions?: { width: number; height: number };
}

interface IFieldsProps {
  fields: Field[];
  onChange: (v: any) => void;
}

export const Fields: React.FunctionComponent<IFieldsProps> = (props) => {
  const { onChange } = props;

  const handleChange = (field: string, value: any, concat?: { type: string; value: any }) => {
    const mappedFields = props.fields.map((f) => {
      if (
        field === 'cell' ||
        field === 'fax' ||
        field === 'phone' ||
        field === 'Phone' ||
        field === 'Cell' ||
        field === 'Fax' ||
        field === 'HiringPhoneNumber'
      ) {
        const cleaned = ('' + value).replace(/\D/g, '');
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
          value = match[1] + '-' + match[2] + '-' + match[3];
        }
      }

      // if (f.key === field) {
      //   if (concat) {
      //     return ({ ...f, [concat.type]: concat.value })
      //   }
      //   return { ...f, value: value };
      // }

      if (f.key === field) {
        const isValueOther =
          typeof value === 'string' ? value === 'other' : value?.find((v) => v?.value === 'other');

        if (concat) {
          return { ...f, [concat.type]: concat.value };
        }

        if (f.selectAllowOther && isValueOther) {
          return { ...f, tempValue: 'other', value };
        }
        return { ...f, tempValue: '', value };
      }

      return f;
    });

    onChange(mappedFields);
  };

  const handleSelectAllowOtherChange = (field: string, value: any) => {
    const mappedFields = props.fields.map((f) => {
      if (f.key === field) {
        const isValueOther =
          typeof value === 'string' ? value === 'other' : value?.find((v) => v?.value === 'other');

        if (f.selectAllowOther && isValueOther) {
          return { ...f, tempValue: 'other', value };
        }

        return { ...f, tempValue: '', value };
      } else if (f.type === 'hidden') {
        // Clear this value since it maybe from a previous spread
        return { ...f, value: '' };
      }
      return f;
    });

    onChange(mappedFields);
  };

  const spreadChange = (field: string, value: any, spread: any) => {
    let mappedFields = props.fields.map((f) => {
      if (f.key === field) {
        return { ...f, value: value };
      }

      return f;
    });

    mappedFields = mappedFields.map((f) => {
      if (spread?.[f?.key] !== undefined) {
        return { ...f, value: spread[f.key] };
      }

      return f;
    });

    onChange(mappedFields);
  };

  if (!props.fields) {
    return null;
  }
  return (
    <Form columns="equal">
      {props.fields.map((f: any, idx) => {
        let input;

        const prepend = f?.concat?.prepend;
        const append = f?.concat?.append;

        const isPrepend = prepend?.enabled;
        const isPrependStatic = prepend?.type === 'static';
        const isAppend = append?.enabled;
        const isAppendStatic = append?.type === 'static';

        if (f.type === 'dynamic-date') {
          return null;
        }

        if (f.type === 'select') {
          let addedEmpty = [...f.data];
          // addedEmpty.unshift({ Account: '', Location: 'Select a Location...' });
          input = (
            <>
              <select
                style={{
                  borderTopLeftRadius: isPrepend ? 0 : '.28571429rem',
                  borderTopRightRadius: isAppend ? 0 : '.28571429rem',
                  borderBottomLeftRadius: isPrepend ? 0 : '.28571429rem',
                  borderBottomRightRadius: isAppend ? 0 : '.28571429rem',
                }}
                value={`${f.selectAllowOther && f.tempValue === 'other' ? f?.tempValue : f.value}`}
                onChange={(e: any) => {
                  f.selectAllowOther
                    ? handleSelectAllowOtherChange(f.key, e.target.value)
                    : spreadChange(
                        f.key,
                        e.target.value,
                        f.data.find((d) => `${d.id}` === e.target.value)
                      );
                }}
              >
                {addedEmpty.map((r, idx) => (
                  <option key={idx} value={r.id}>
                    {r.label}{' '}
                  </option>
                ))}
                {f.selectAllowOther && <option value="other">Other</option>}
              </select>
            </>
          );
        } else if (f.type === 'multi-select') {
          input = (
            <Select
              value={f.value}
              isMulti
              placeholder=""
              options={
                f.selectAllowOther ? f?.data?.concat({ value: 'other', label: 'Other' }) : f?.data
              }
              onChange={(e: any) => handleChange(f.key, e)}
            />
          );
        } else if (f.type === 'textarea') {
          input = (
            <>
              <textarea
                rows={4}
                value={f.value}
                maxLength={f.maxChars}
                onChange={(e: any) => {
                  handleChange(f.key, e.target.value);
                }}
              />
              {f?.value?.length >= f?.maxChars && (
                <Message
                  style={{ display: 'block !important' }}
                  warning
                  size="tiny"
                  content="Is alraady in max length"
                />
              )}
            </>
          );
        } else if (f.type === 'list') {
          let addedEmpty = [...f.data];
          addedEmpty.unshift({ value: '', text: 'Select...' });
          input = (
            <select
              value={`${f.value}`}
              onChange={(e: any) => {
                handleChange(f.key, e.target.value);
              }}
            >
              {addedEmpty.map((r, idx) => (
                <option key={idx} value={r.value}>
                  {r.text}
                </option>
              ))}
            </select>
          );
        } else if (f.type === 'image') {
          input = (
            <ImageField
              imageOptions={f.imageOptions}
              onChange={(v) => handleChange(f.key, v)}
              value={f.value}
            />
          );
        } else {
          input = (
            // <Input labelPosition={isAppend ? 'right' : 'left'} type='text'>
            //   {
            //     isPrepend && <Label> {isPrependStatic ? prepend?.value : <Dropdown
            //       defaultValue={f.prependValue}
            //       options={prepend?.selectOptions?.map((option => ({ text: option?.label, value: option?.value })))}
            //       onChange={(e, data) => handleChange(f.key, '', ({ type: 'prependValue', value: data.value }))}
            //     />}
            //     </Label>
            //   }
            <input value={f.value} onChange={(e) => handleChange(f.key, e.target.value)} />
            //   {
            //     isAppend && <Label> {isAppendStatic ? append?.value : <Dropdown
            //       defaultValue={f.appendValue}
            //       options={append?.selectOptions?.map((option => ({ text: option?.label, value: option?.value })))}
            //       onChange={(e, data) => handleChange(f.key, '', ({ type: 'appendValue', value: data.value }))}
            //     />}
            //     </Label>
            //   }
            // </Input>
          );
        }

        return (
          <React.Fragment key={idx}>
            <Form.Group>
              <Form.Field width="4">
                <label>{f.label}</label>
              </Form.Field>
              <Form.Field width="12">
                {f.type === 'multi-select' || f.type === 'textarea' ? (
                  input
                ) : (
                  <Input labelPosition={isAppend ? 'right' : 'left'} type="text">
                    {isPrepend && (
                      <Label>
                        {' '}
                        {isPrependStatic ? (
                          prepend?.value
                        ) : (
                          <Dropdown
                            defaultValue={f.prependValue}
                            options={prepend?.selectOptions?.map((option) => ({
                              text: option?.label,
                              value: option?.value,
                            }))}
                            onChange={(e, data) =>
                              handleChange(f.key, '', { type: 'prependValue', value: data.value })
                            }
                          />
                        )}
                      </Label>
                    )}
                    {input}
                    {isAppend && (
                      <Label
                        style={{
                          borderTopLeftRadius: isAppend ? 0 : '.28571429rem',
                          borderBottomLeftRadius: isAppend ? 0 : '.28571429rem',
                        }}
                      >
                        {' '}
                        {isAppendStatic ? (
                          append?.value
                        ) : (
                          <Dropdown
                            defaultValue={f.appendValue}
                            options={append?.selectOptions?.map((option) => ({
                              text: option?.label,
                              value: option?.value,
                            }))}
                            onChange={(e, data) =>
                              handleChange(f.key, '', { type: 'appendValue', value: data.value })
                            }
                          />
                        )}
                      </Label>
                    )}
                  </Input>
                )}
              </Form.Field>
            </Form.Group>
            {f?.description && (
              <Form.Group>
                <Form.Field width="4" />
                <Form.Field width="12">
                  <p>
                    <small>{f.description}</small>
                  </p>
                </Form.Field>
              </Form.Group>
            )}
            {f.selectAllowOther && f.tempValue === 'other' && (
              <Form.Group>
                <Form.Field width="4" />
                <Form.Field width="12">
                  <input
                    type="text"
                    value={f.type === 'multi-select' ? f?.valueExtension : f.value}
                    onChange={(e: any) => {
                      const isMultiSelect = f.type === 'multi-select';

                      const mappedFields = props.fields.map((field) => {
                        if (f.key === field.key) {
                          return {
                            ...field,
                            value: !isMultiSelect ? e.target.value : field.value,
                            valueExtension: isMultiSelect ? e.target.value : undefined,
                          };
                        }

                        return field;
                      });

                      props.onChange(mappedFields);
                    }}
                  />
                </Form.Field>
              </Form.Group>
            )}
          </React.Fragment>
        );
      })}
    </Form>
  );
};
