import React, { useState, useEffect } from 'react';
import { RouteComponentProps, withRouter, useHistory } from 'react-router-dom';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  Alert,
  Checkbox,
  Stack,
  Text,
  List,
  ListItem,
  ListIcon,
  OrderedList,
  UnorderedList,
} from '@chakra-ui/react';

import type { ModelTypes } from '@inkcloud/icapi-types';

import feathers, { icapi } from '../../bootstrap/feathers';

interface IDuplicateModalProps {
  data: NonNullable<ModelTypes.SalesOrder>;
  onClose: () => void;
}

// const fieldsToDuplicate = [
//   { value: 'billTo', label: 'Bill To' },
//   { value: 'shipTo', label: 'Ship To' },
//   { value: 'shipping', label: 'Shipping' },
//   { value: 'production', label: 'Production' },
//   { value: 'payment', label: 'Payment' },
//   { value: 'soDescription', label: 'Sales Order Description' },
//   { value: 'items', label: 'Items' },
// ]

const serviceName = `sales-orders`;
const service = icapi.service(serviceName);

const DuplicateModal = (props: IDuplicateModalProps) => {
  const { data, onClose } = props;

  const history = useHistory();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  // const [checkField, setCheckField] = useState<Record<string, boolean>>({})

  // useEffect(() => {
  //   const allFields = fieldsToDuplicate?.reduce((acc, cur) => {
  //     acc[cur.value] = true

  //     return acc
  //   }, {})

  //   setCheckField(allFields)
  // }, [])

  // const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const { checked, name } = e.target

  //   setCheckField({ ...checkField, [name]: checked })
  // }

  // const handleToggleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const { checked } = e.target

  //   const allFields = fieldsToDuplicate?.reduce((acc, cur) => {
  //     acc[cur.value] = checked

  //     return acc
  //   }, {})

  //   setCheckField(allFields)
  // }

  const handleSubmit = async () => {
    setIsSubmitting(true);
    setErrorMessage('');
    try {
      const payload = {
        ...data,
        _id: undefined,
        createdAt: undefined,
        updatedAt: undefined,
        humanId: undefined,
        shipDate: '',
        customerPo: '',
        items: data.items?.map((item) => ({ ...item, _id: undefined })),
        metadata: [],
      };

      const res = await service.create(payload, { query: {} });
      onClose();

      history.push(`/sales-orders/${res?._id}`);
    } catch (e) {
      const errMsg = e?.code < 500 ? e?.message : 'Something went wrong. Please try again later.';
      setErrorMessage(errMsg);
    }
    setIsSubmitting(false);
  };

  // const isAllChecked = Object.keys(checkField)?.every((c) => checkField[c])
  // const someIsChecked = Object.keys(checkField)?.some((c) => checkField[c])

  return (
    <Modal size="2xl" isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Sales Order {data?.humanId || ''}</ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <Stack spacing={5} mb={3} direction="column">
            {/* <FormControl>
              <Checkbox isChecked={isAllChecked} onChange={handleToggleCheck}>
                <Text as="b">{isAllChecked ? 'Unselect' : 'Select'} All</Text>
              </Checkbox>
            </FormControl> */}
            <div>
              This sales order will be duplicated except for the following fields:
              <UnorderedList>
                <ListItem>Ship Date</ListItem>
                <ListItem>Customer PO</ListItem>
                <ListItem>Metadata</ListItem>
              </UnorderedList>
            </div>
          </Stack>

          {errorMessage && <Alert status="error">{errorMessage}</Alert>}
        </ModalBody>

        <ModalFooter>
          <Button
            isDisabled={isSubmitting}
            isLoading={isSubmitting}
            colorScheme="blue"
            mr={3}
            onClick={handleSubmit}
          >
            Submit
          </Button>
          <Button isDisabled={isSubmitting} mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
// const DuplicateModal = withRouter(PreDuplicateModal)
export default DuplicateModal;
