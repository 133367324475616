import * as React from 'react';
import { Button, Input, Dropdown, Segment, Form } from 'semantic-ui-react';
import { FeathersDropdown } from '@inkcloud/components';

export interface FilterFormProps {
  value: any;
  onChange: Function;
  onReset: Function;
}

function initMapper(value) {
  const defaults = {
    status: '',
    searchOrderItemHumanId: '',
    assignedDesigner: '',
  };

  return Object.keys(defaults).reduce((p, cur) => {
    if (!p[cur]) {
      p[cur] = defaults[cur];
    }
    return p;
  }, value);
}

const statusOptions = [
  { text: 'Awaiting Initial Requirements', value: 'awaiting-initial-reqs' },
  { text: 'Reviewing Requirements', value: 'reviewing-reqs' },
  { text: 'In Design', value: 'in-design' },
  { text: 'Pending Customer Approval', value: 'pending-customer-approval' },
  { text: 'Customer Reviewing', value: 'customer-reviewing' },
  { text: 'Complete', value: 'complete' },
  { text: 'Void', value: 'void' },
];

export function FilterForm(props: FilterFormProps) {
  const { value: preInitValue } = props;

  const value = initMapper(preInitValue);

  const onChange = (field, value) => {
    props.onChange && props.onChange({ ...props.value, [field]: value });
  };

  return (
    <Segment>
      <Form size={'small'}>
        <Form.Group widths={'equal'}>
          <Form.Field>
            <Dropdown
              selection
              fluid
              clearable
              placeholder="Select Job Data's Status..."
              options={statusOptions}
              value={value.status}
              onChange={(e, data) => onChange('status', data.value)}
            />
          </Form.Field>
          <Form.Field>
            <Input
              value={value.searchOrderItemHumanId}
              placeholder="Order Item ID"
              onChange={(e, data) => onChange('searchOrderItemHumanId', data.value)}
            />
          </Form.Field>
          <Form.Field>
            <FeathersDropdown
              selection
              fluid
              search
              clearable
              placeholder="Assigned Designer"
              serviceName="/users/get-by-role"
              query={{
                $sort: { firstName: 1 },
                $np: 1,
                key: 'designer',
              }}
              resultMapper={(r) => ({
                value: r._id,
                text: `${r?.firstName ?? ''} ${r?.lastName ?? ''}`,
              })}
              value={value.assignedDesigner}
              onChange={(e, data) => onChange('assignedDesigner', data.value)}
            />
          </Form.Field>
        </Form.Group>

        <Form.Field>
          <Button size={'tiny'} content="Reset Form" onClick={() => props.onReset()} />
        </Form.Field>
      </Form>
    </Segment>
  );
}
