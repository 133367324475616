import * as React from 'react';
import { chunk } from 'lodash-es';
import { Grid, Message } from 'semantic-ui-react';

import AttributeSelect from './AttributeSelect';
import { SizeCustomTrim } from './SizeCustomTrim';

export interface AttributeSetProps {
  customTrimChecked: boolean;
  customTrimWidth: number;
  customTrimHeight: number;
  attributes: any[];
  onChange: (value: { attributeType: string; value: string }) => void;
  onCustomTrimChange: (value: { width: number; height: number; checked: boolean }) => void;
}

export interface AttributeSetState {}

export default class AttributeSet extends React.Component<AttributeSetProps, AttributeSetState> {
  constructor(props: AttributeSetProps) {
    super(props);

    this.state = {};
  }

  handleCustomTrimChange = (value) => {
    this.props.onCustomTrimChange(value);
  };
  public render() {
    const chunkedArr = chunk(this.props.attributes, 2);
    const attributesChunked = chunkedArr.map((chunk, index) => {
      return (
        <Grid.Row key={`eaChunk${index}`}>
          {chunk.map((a) => (
            <Grid.Column key={a.attributeTypeId}>
              <label style={{ paddingBottom: 2 }}>{a.attributeType.label}</label>
              <AttributeSelect
                selected={a.selected}
                options={a.attributes.map((a) => ({ value: a.valueId, label: a.name }))}
                onChange={this.props.onChange}
                attributeType={a.attributeTypeId}
              />
              {a.attributeTypeId === 'SIZE' && (
                <React.Fragment>
                  <SizeCustomTrim
                    checked={this.props.customTrimChecked}
                    width={this.props.customTrimWidth}
                    height={this.props.customTrimHeight}
                    selectedSize={a.selected}
                    onChange={this.handleCustomTrimChange}
                  />
                </React.Fragment>
              )}
            </Grid.Column>
          ))}
        </Grid.Row>
      );
    });

    return <Grid columns="equal">{attributesChunked}</Grid>;
  }
}
