// import * as React from 'react';
// import { Accordion, Button, Checkbox, Header, Grid, Label, Segment, Table } from 'semantic-ui-react';
// import { Link } from 'react-router-dom';
// import { FormattedDate, FormattedNumber, FormattedRelative } from 'react-intl';
// import { IndexWrapper, IndexWrappedProps } from './../../../bootstrap/IndexWrapper';
// import { ListViewPagination, ListFilterWarning } from './../../../common';
// // import FilterForm from './FilterForm';

// const filterMapper = (filter) => {
//   return {
//     name: { $LIKE: filter && filter.name },
//   }
// }

// export function Container(props: IndexWrappedProps) {
//   if (!props.queryResult) {
//     return null;
//   }
//   const { data, skip, limit, total } = props.queryResult;
//   return (
//     <div>
//       <Header as='h1'>Production View - In Production</Header>
//       <Header attached={'top'} as='h5'>
//         Filter
//       </Header>
//       <Segment attached secondary>
//         {/* <FilterForm
//           onSubmit={props.handleFilterSubmit}
//           handleClearFilter={props.handleClearFilter}
//           isFiltered={props.isFiltered}
//           /> */}
//       </Segment>
//       <ListFilterWarning isFiltered={props.isFiltered} />
//       <Segment attached loading={props.isLoading}>
//         <ListViewPagination.Top
//           hideAddButton={true}
//           label={'New Production Resource'}
//           link={'/production/resources/add'}
//           {...props}
//         />
//         <Table size={'small'}>
//           <Table.Header>
//             <Table.Row>
//               <Table.HeaderCell>
//                 <Checkbox
//                   onChange={props.handleSelectAllClick}
//                 />
//               </Table.HeaderCell>
//               <Table.HeaderCell>ID</Table.HeaderCell>
//               <Table.HeaderCell>Customer</Table.HeaderCell>
//               <Table.HeaderCell>Category</Table.HeaderCell>
//               <Table.HeaderCell>Description</Table.HeaderCell>
//               <Table.HeaderCell>Status</Table.HeaderCell>
//               <Table.HeaderCell></Table.HeaderCell>
//             </Table.Row>
//           </Table.Header>
//           <Table.Body>
//             {data.map(i => (
//               <Table.Row key={i._id}>
//                 <Table.Cell>
//                   <Checkbox
//                     value={i._id}
//                     checked={props.selected[i._id] || false}
//                     onChange={props.handleSemanticCheckboxClick}
//                   />
//                 </Table.Cell>
//                 <Table.Cell>
//                   <Link to={`/orders/${i?.order}`}>{i?.humanId}</Link>
//                 </Table.Cell>

//                 <Table.Cell  >
//                   <Link to={`/customers/${i.customer?._id}`}>
//                     {i.customer.organizationName ? i.customer.organizationName : `${i.customer.primaryContact.firstName} ${i.customer.primaryContact.lastName}`}
//                   </Link>

//                 </Table.Cell>

//                 <Table.Cell>
//                   {i.category ? i.category.name : 'Custom'}
//                 </Table.Cell>
//                 <Table.Cell width={'6'}>
//                   {i.attributes && i.attributes.reduce((prev, next) => `${prev} ${next.value.name}`, '')}
//                 </Table.Cell>
//                 <Table.Cell>
//                   {i.productionStatus && <Label size={'mini'} content={i.productionStatus.description} />}
//                 </Table.Cell>

//               </Table.Row>
//             ))}
//           </Table.Body>
//         </Table>
//         <ListViewPagination.Bottom
//           hideDeleteButton={true}
//           label={'Delete'}
//           {...props}
//         />
//       </Segment>
//     </div>
//   );
// }

// export default IndexWrapper(Container, 'orderItems', {
//   filterMapper,
//   query: {
//     $sort: { createdAt: 1 },
//     $populate: ['attributes.value', 'category', 'customer', 'productionStatus.jobStatus'],
//     'productionStatus.jobStatus': {
//       $nin: [
//         'VOID',
//         'READY_FOR_PICKUP',
//         'PICKED_UP',
//         'APPROVAL_PENDING',
//         'ARTWORK_EXCEPTION',
//         'FILES_NOT_RECEIVED',
//         'FILES_PARTIAL_RECEIVED',
//         'FILES_RECEIVED',
//         'SHIPPED',
//         'SHIPMENT_PENDING',
//         'SHIPPED_COMPLETE',
//       ]
//     },
//   }
// });

import * as React from 'react';
import { Label, Segment } from 'semantic-ui-react';
import { Link, RouteComponentProps } from 'react-router-dom';

import {
  useList,
  ListTable,
  PageHeaderRow,
  EmptyWrapper,
  basicQueryMap,
  PaginatorWrapper,
} from '@inkcloud/components';

interface InProductionProps {}

const serviceName = 'order-items';
const pathPrefix = '/production/view/in-production';

const filterQueryMap = (values) => {
  let filterRows = basicQueryMap(values);

  filterRows = {
    ...filterRows,
  };

  return filterRows;
};

export const InProduction: React.FunctionComponent<InProductionProps & RouteComponentProps<any>> = (
  props
) => {
  const {
    match: { params },
    location,
    history,
  } = props;

  const {
    state,
    reload,
    handleDelete,
    selectedItems,
    clearAll,
    setChecked,
    someSelected,
    toggleAll,
    filter,
    setFilter,
    sort,
    handleSort,
  } = useList({
    feathers: [
      'find',
      serviceName,
      [
        {
          query: {
            $populate: ['attributes.value', 'category', 'customer', 'productionStatus.jobStatus'],
            'productionStatus.jobStatus': {
              $nin: [
                'VOID',
                'READY_FOR_PICKUP',
                'PICKED_UP',
                'APPROVAL_PENDING',
                'ARTWORK_EXCEPTION',
                'FILES_NOT_RECEIVED',
                'FILES_PARTIAL_RECEIVED',
                'FILES_RECEIVED',
                'SHIPPED',
                'SHIPMENT_PENDING',
                'SHIPPED_COMPLETE',
              ],
            },
          },
        },
      ],
    ],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: 1 },
  }) as any;

  const tableDefinition = [
    {
      key: 'humanId',
      label: 'ID',
      sortable: true,
      cell: (v, r) => v && <Link to={`/orders/${r.order}`}>{v}</Link>,
    },
    {
      key: 'customer',
      label: 'Customer',
      cell: (v, r) => (
        <Link to={`/customers/${r.customer?._id}`}>
          {r.customer?.organizationName ||
            `${r.customer?.primaryContact?.firstName ?? ''} ${
              r.customer?.primaryContact?.lastName ?? ''
            }`}
        </Link>
      ),
    },
    { key: 'category', label: 'Category', cell: (v, r) => r?.category?.name },
    {
      key: '',
      label: 'Description',
      cell: (v, r) => r?.attributes?.reduce((prev, next) => `${prev} ${next.value.name}`, ''),
    },

    {
      key: '',
      label: 'Status',
      cell: (v, r) => <Label size="mini">{r?.productionStatus?.description}</Label>,
    },
  ];

  const { queryResult, isLoading: isStateLoading } = state as any;

  const resolvedQueryResult = queryResult?.data ? queryResult.data : queryResult;

  return (
    <div>
      <PageHeaderRow header="In Production" subheader="Production View" />
      <Segment>
        <EmptyWrapper queryResult={resolvedQueryResult} isStateLoading={isStateLoading}>
          <PaginatorWrapper.Top
            filter={filter}
            reload={reload}
            data={queryResult as any}
            pathPrefix={`${pathPrefix}`}
          />
          <ListTable
            data={resolvedQueryResult}
            definition={tableDefinition}
            selectedItems={selectedItems}
            setChecked={setChecked}
            sort={sort}
            sortClickFunction={handleSort}
            toggleAll={toggleAll}
            withCheckboxes={false}
          />

          <PaginatorWrapper.Bottom
            reload={reload}
            data={queryResult as any}
            pathPrefix={`${pathPrefix}`}
          />
        </EmptyWrapper>
      </Segment>
    </div>
  );
};
