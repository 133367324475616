import * as React from 'react';
import { Button, Grid, Input, Message, Icon, Form, Modal, Checkbox } from 'semantic-ui-react';

import Feathers from './../../../bootstrap/feathers';

import { CustomQuoteForm } from './CustomQuoteForm';

import { payloadMapper } from './helper';

interface ItemModalProps {
  item: any;
  data: any;
  id: string;
  addOns: any[];
  isOpen: boolean;
  onReload: () => void;
  onClose: () => void;
}

const baseUrl = `https://storage.googleapis.com/icstorage/`;

const { useState, useEffect } = React;

export const ItemModal: React.FunctionComponent<ItemModalProps> = (props) => {
  const { data, item, id, isOpen, onReload, onClose } = props;

  // attributes
  const [attributes, setAttributes] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (values: any) => {
    const payload = payloadMapper(values, id);

    try {
      const res: any = await Feathers.service('/custom-quotes/modify-item').create(payload, {
        query: {},
      });
      if (res) {
        onReload();
        onClose();
      }
    } catch (e) {
      e.code && e.code < 500
        ? setErrorMessage(e.message)
        : setErrorMessage('We are experiencing technical difficulties. Please try again');
    }
  };

  return (
    <Modal open={isOpen} onClose={onClose} closeIcon>
      <Modal.Content>
        <CustomQuoteForm
          id={id}
          item={{ ...item, selectedShippingMethod: data.selectedShippingMethod }}
          onSubmit={handleSubmit}
          errorMessage={errorMessage}
        />
      </Modal.Content>
    </Modal>
  );
};
