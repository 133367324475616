/* eslint-disable prefer-template */
/* eslint-disable no-undef */
import * as React from 'react';
import * as numeral from 'numeral';
import {
  Icon,
  Button,
  Form,
  FormField,
  Checkbox,
  CheckboxProps,
  Input,
  Message,
  Popup,
} from 'semantic-ui-react';
import { InputProps } from 'semantic-ui-react/dist/commonjs/elements/Input/Input';
import { PaymentData, PaymentMethod } from '../../types';
import Feathers from '../../bootstrap/feathers';

import { CreditCardFormComponent, CreditCardFormDataProps } from './CreditCardFormComponent';

export interface PaymentHandlerProps {
  customer?: string;
  onChange: {
    (data: PaymentData): void;
  };
}

export type PaymentComponentProps = PaymentData & PaymentHandlerProps;

export interface PaymentComponentState {}

const { useEffect, useState } = React;

const PaymentComponent: React.FunctionComponent<PaymentComponentProps & PaymentComponentState> = (
  props: any
) => {
  const [methods, setMethods] = useState([]);

  // const [cardTokens, setCardTokens] = useState(props.customer?.creditCardTokens || []);
  const [creditMemo, setCreditMemo] = useState({
    creditMemoAmountAvailable: 0,
    creditAmount: 0,
    debitAmount: 0,
  });

  const { customer } = props;

  const cardTokens = props.customer?.creditCardTokens || [];

  // console.log('cardTokens', cardTokens);
  // console.log('payment component props', props);

  useEffect(() => {
    const { onChange, children, ...dataToDispatch } = props;
    const loadSettings = async (id: string) => {
      const res = await Feathers.service('/tenant-settings/payment/').get(id, {
        query: {},
      });

      // const tokens = await Feathers.service('/customers/credit-card-tokens/').get(customer._id, {
      //   query: {},
      // });
      // setCardTokens(tokens);

      if (res) {
        setMethods(res.methods);

        const ccType =
          (dataToDispatch.availableCreditCardTokens &&
            dataToDispatch.availableCreditCardTokens.length > 0) ||
          cardTokens?.length > 0;

        const defaultPaymentMethod =
          res?.methods?.find((m) => m === 'CREDIT_CARD') || res?.methods?.length === 0;

        onChange?.({
          ...dataToDispatch,
          paymentMethod: defaultPaymentMethod ? PaymentMethod.CreditCard : PaymentMethod.None,
          creditCardType: ccType ? 'stored' : 'new',
          creditCardToken: ccType ? cardTokens?.[0]?.paymentProfileId : '',
        });
      }
    };

    const loadCreditMemo = async (id) => {
      const res: any = await Feathers.service('/credit-memo/balance/').get(id, { query: {} });
      setCreditMemo(res);
    };

    if (customer) {
      const id = typeof customer === 'string' ? customer : customer._id;
      loadCreditMemo(id);
    }

    if (customer && customer.business) {
      const id = typeof customer.business === 'string' ? customer.business : customer.business._id;
      loadSettings(id);
    }
  }, [customer]);

  const money = (value) => numeral(value).format('$0,0.00');
  const handlePaymentMethod = (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
    const { onChange, children, ...dataToDispatch } = props;

    onChange?.({
      ...dataToDispatch,
      paymentMethod: data.value as PaymentMethod,
    });
  };

  const handleDescriptionChange = (event: React.FormEvent<HTMLInputElement>, data: InputProps) => {
    const { onChange, children, ...dataToDispatch } = props;

    onChange?.({
      ...dataToDispatch,
      optionalDescription: data.value,
    });
  };

  const handleCreditCardChange = (data: CreditCardFormDataProps) => {
    const { onChange, children, ...dataToDispatch } = props;
    onChange?.({
      ...dataToDispatch,
      ...data,
    });
  };

  const paymentDescription = (placeholder = 'Optional note...') => (
    <Input
      style={{ marginLeft: 35 }}
      name="description"
      placeholder={placeholder}
      size={'mini'}
      fluid={false}
      onChange={handleDescriptionChange}
    />
  );

  const { amountDue } = props;
  let paymentMethodComponent;

  if (amountDue && amountDue !== 0) {
    paymentMethodComponent = (
      <div>
        <Message
          warning
          attached={methods && methods.length > 1}
          content={
            'A ' + (amountDue > 0 ? 'payment' : 'refund') + ' of ' + money(amountDue) + ' is owed'
          }
        />

        {
          <Form className="attached fluid segment">
            <Form.Field>How do you want to process the open balance:</Form.Field>
            <Form.Field>
              {amountDue > 0 && creditMemo.creditMemoAmountAvailable >= amountDue && (
                <Checkbox
                  radio
                  label={`Charge to Credit Memo - ${numeral(
                    creditMemo.creditMemoAmountAvailable
                  ).format('$0,0.00')}`}
                  name="creditMemo"
                  value={'credit_memo'}
                  checked={props.paymentMethod === 'credit_memo'}
                  onChange={handlePaymentMethod}
                />
              )}
              {!(amountDue > 0) && (
                <Checkbox
                  radio
                  label={`Refund to Credit Memo`}
                  name="creditMemo"
                  value={'credit_memo'}
                  checked={props.paymentMethod === 'credit_memo'}
                  onChange={handlePaymentMethod}
                />
              )}
            </Form.Field>
            {
              <Form.Field>
                <Checkbox
                  radio
                  label={(amountDue > 0 ? 'Charge' : 'Refund') + ' to Credit Card'}
                  name="paymentMethod"
                  value={PaymentMethod.CreditCard}
                  checked={props.paymentMethod === PaymentMethod.CreditCard}
                  onChange={handlePaymentMethod}
                />
              </Form.Field>
            }
            {
              <Form.Field inline>
                <Checkbox
                  radio
                  label={(amountDue > 0 ? 'Charge' : 'Refund') + ' by Check'}
                  name="paymentMethod"
                  value={PaymentMethod.Check}
                  checked={props.paymentMethod === PaymentMethod.Check}
                  onChange={handlePaymentMethod}
                />
                <Popup
                  trigger={<Icon color={'grey'} name={'help circle'} />}
                  content={
                    amountDue > 0
                      ? 'Use this if you have received a check from the customer and want to record the payment. Optionally you can enter in the check number'
                      : 'Use this method if you will refund by writing a check to the customer. Optionally you can enter in the check number'
                  }
                  on={'hover'}
                />
                {props.paymentMethod === PaymentMethod.Check &&
                  paymentDescription('Optional check #...')}
              </Form.Field>
            }
            {
              <Form.Field inline>
                <Checkbox
                  radio
                  label={(amountDue > 0 ? 'Charge' : 'Refund') + ' with cash'}
                  name="paymentMethod"
                  value={PaymentMethod.Cash}
                  checked={props.paymentMethod === PaymentMethod.Cash}
                  onChange={handlePaymentMethod}
                />
                <Popup
                  trigger={<Icon color={'grey'} name={'help circle'} />}
                  content={
                    amountDue > 0
                      ? 'Use this method if you have received cash from the customer. If you have issued a receipt you can optionally enter in that number'
                      : 'Use this method if you will be refunding the customer with cash. You can optionally enter in a receipt number.'
                  }
                  on={'hover'}
                />

                {props.paymentMethod === PaymentMethod.Cash &&
                  paymentDescription('Optional receipt #...')}
              </Form.Field>
            }
            {
              <Form.Field inline>
                <Checkbox
                  radio
                  label={(amountDue > 0 ? 'Charge' : 'Refund') + ' offline'}
                  name="paymentMethod"
                  value={PaymentMethod.Offline}
                  checked={props.paymentMethod === PaymentMethod.Offline}
                  onChange={handlePaymentMethod}
                />
                <Popup
                  trigger={<Icon color={'grey'} name={'help circle'} />}
                  content={
                    amountDue > 0
                      ? 'Use this method if you have charged the amount directly with your payment gateway or processed a credit card by machine. You can optionally enter in the transaction ID'
                      : 'Use this method if you have refunded the amount directly with your payment gateway. You can optionally enter in the transaction ID'
                  }
                  on={'hover'}
                />
                {props.paymentMethod === PaymentMethod.Offline &&
                  paymentDescription('Optional transaction #...')}
              </Form.Field>
            }
            {amountDue > 0 && (
              <Form.Field>
                <Checkbox
                  radio
                  label="No payment at this time. (Leave open balance)"
                  name="paymentMethod"
                  value={PaymentMethod.None}
                  checked={props.paymentMethod === PaymentMethod.None}
                  onChange={handlePaymentMethod}
                />
              </Form.Field>
            )}
          </Form>
        }

        {props.amountDue > 0 &&
        (props.paymentMethod === PaymentMethod.CreditCard ||
          props.paymentMethod === 'CREDIT_CARD') ? (
          <div style={{ marginTop: 30 }}>
            <CreditCardFormComponent
              creditCard={props.creditCard}
              availableCreditCardTokens={
                props.availableCreditCardTokens && props.availableCreditCardTokens.length > 0
                  ? props.availableCreditCardTokens
                  : cardTokens
              }
              creditCardToken={props.creditCardToken}
              creditCardType={props.creditCardType}
              onChange={handleCreditCardChange}
            />
          </div>
        ) : null}
      </div>
    );
  } else {
    paymentMethodComponent = (
      <Message attached content={'Difference of $0.00 does not require any payment.'} />
    );
  }

  return <div>{paymentMethodComponent}</div>;
};

export default PaymentComponent;
