// import * as React from 'react';
// import { Button, Header, Segment } from 'semantic-ui-react';
// import { Link, RouteComponentProps } from 'react-router-dom';
// import { DetailWrapper, DetailFeaturesProps } from './../../../bootstrap/DetailWrapper';
// import AddressBookForm from './AddressBookForm';
// import { PageHeaderRow, PreLoader } from '@inkcloud/components';

// export function Detail (props: DetailFeaturesProps ) {
//   const { data } = props;
//   return (
//     <div>
//       <PageHeaderRow header={props.data ? props.data.name : 'New'} subheader='Address Book'>
//         <Link to={`/admin/address-book`}><Button content="Back" size={'mini'} /></Link>
//       </PageHeaderRow>
//       <Segment >
//       <AddressBookForm
//         form="addressBookForm"
//         initialValues={data}
//         onSubmit={props.handleSubmit}
//         isEdit={!!props.match.params.id}
//       />
//     </Segment>
//     </div>
//   );
// }

// export default DetailWrapper(Detail, 'addressBook', {
//   submitRedirect: '/admin/address-book'
// });

import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Segment } from 'semantic-ui-react';
import { PageHeaderRow, PreLoader, useFeathers } from '@inkcloud/components';
import feathers from './../../../bootstrap/feathers';
import { AddressBookForm } from './AddressBookForm';

interface DetailProps {
  products: { value: string; text: string }[];
  categories: { value: string; text: string }[];
}

const serviceName = 'address-book';
const service = feathers.service(serviceName);
const commonParams = { query: {} };

export const Detail: React.FunctionComponent<
  DetailProps & RouteComponentProps<{ id?: string; micrositeId: string }>
> = (props) => {
  const {
    match: {
      params: { id, micrositeId },
    },
    history,
  } = props;

  const pathPrefix = `/admin/address-book`;

  const isEdit = !!id;
  const [state, reload] = useFeathers('get', serviceName, [id, commonParams], true);
  const handleSubmit = async (values) => {
    isEdit
      ? await service.patch(id, values, commonParams)
      : await service.create(values, commonParams);
    history.push(pathPrefix);
  };

  const pageTitle = isEdit ? 'Edit' : 'New';

  if (isEdit && !state.data) {
    return <PreLoader />;
  }

  const initialData = { ...state.data, microsite: micrositeId };
  return (
    <div>
      <PageHeaderRow header={`${pageTitle} Book`} subheader="Address" subheaderId={''}>
        <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
      </PageHeaderRow>
      <Segment>
        {(!isEdit || state.data) && (
          <AddressBookForm initialValues={initialData} onSubmit={handleSubmit} />
        )}
      </Segment>
    </div>
  );
};
