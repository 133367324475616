import * as React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Header, Icon, Label, Message, Segment, Grid } from 'semantic-ui-react';
import type { ModelTypes } from '@inkcloud/icapi-types';
import { PageHeaderRow, useDebounce } from '@inkcloud/components';
import feathers from '../../bootstrap/feathers';
import Payment from './../invoice-adjuster/PaymentComponent';
import { PaymentData, PaymentMethod, PaymentCreditCardTypes, NewPaymentData } from './../../types';

import { ProductAddModal } from './components/TempProductAdd';

import {
  AssignedCustomer,
  AssignSalesPersonModal,
  CartContents,
  // Payment,
  SelectCustomerModal,
  ShipMethods,
  ShipTo,
  ShipRates,
  Summary,
  PickupLocation,
  PO,
  PromoCode,
  CostCenter,
} from './components';

interface IOrderBuilderProps {}

declare var localStorage: any;

const lastCartKey = 'last-cart';

const { useEffect, useState } = React;

const initialErrorValues = {
  shipTo: '',
  itemsRemove: '',
  assignSalesPerson: '',
};

type cartType = NonNullable<
  ModelTypes.Carts &
    Partial<{
      salesPerson: {
        firstName: string;
        lastName: string;
      };
      tenant: {
        country: string;
      };
    }>
>;

export const OrderBuilder: React.FunctionComponent<
  IOrderBuilderProps & RouteComponentProps<any>
> = (props) => {
  const [cart, setCart] = useState<cartType>();
  const [isLoading, setIsLoading] = useState(false);
  const [isCartLoading, setIsCartLoading] = useState(false);
  const [isAssignPersonLoading, setIsAssignPersonLoading] = useState(false);
  const [isAssignCustomerModalOpen, setIsAssignCustomerModalOpen] = useState(false);
  const [isAssignSalesPersonModal, setIsAssignSalesPersonModal] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isCustomItemLoading, setIsCustomItemLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState({});
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [customer, setCustomer] = useState(null);
  const [micrositeCustomer, setMicrositeCustomer] = useState(null);
  const [paymentData, setPaymentData] = useState(() => {
    const pd = NewPaymentData();
    pd.paymentMethod = PaymentMethod.None;
    return pd;
  });
  const [creditCardType, setCreditCardType] = useState(null);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErroMessage] = useState(initialErrorValues);
  const [errorCardMessage, setErrorCardMessage] = useState('');
  const [microsites, setMicrosites] = useState([]);

  const [discount, setDiscount] = useState(cart?.discount ?? 0);

  const [isTranslationSite, setIsTranslationSite] = useState(false);

  const loadCustomer = async (id: string) => {
    const updatedCustomer = await feathers.service('customers').get(id, {
      query: {
        $populate: ['business'],
      },
    });

    setCustomer(updatedCustomer);
  };

  const loadMicrositeCustomer = async (id: string) => {
    if (id) {
      const updatedMicrositeCustomer = await feathers.service('/microsites/customers').get(id, {
        query: {
          $populate: ['microsite'],
        },
      });

      setMicrositeCustomer(updatedMicrositeCustomer);
    }
  };

  const handleAssignCustomer = async (id: string, microsite?: string, micrositeUser?: string) => {
    setIsAssignCustomerModalOpen(false);
    const updatedCart = (await feathers.service('cart/initialize').create({
      customer: id,
      microsite,
      micrositeUser,
    })) as any;

    localStorage.setItem(lastCartKey, updatedCart._id);
    setCart(updatedCart);

    setCustomer(null);
    loadCustomer(updatedCart.customer);

    setMicrositeCustomer(null);
    // setSelectedMicrosite(null)
    if (microsite) {
      // setSelectedMicrosite(microsite)
      loadMicrositeCustomer(updatedCart.micrositeUser);
    }

    if (!updatedCart.selectedShippingMethod && updatedCart.availableShippingMethods) {
      handleShipMethodChange(
        updatedCart.availableShippingMethods.length > 0 &&
          updatedCart.availableShippingMethods[0].key,
        updatedCart
      );
    } else if (
      updatedCart.selectedShippingMethod &&
      updatedCart.availableShippingMethods &&
      !updatedCart.availableShippingMethods.includes(updatedCart.selectedShippingMethod.key)
    ) {
      handleShipMethodChange(
        updatedCart.availableShippingMethods.length && updatedCart.availableShippingMethods[0].key,
        updatedCart
      );
    }
  };

  const handleSelectedItemsChange = async (ids) => {
    setSelectedItems(ids);
  };

  const handleSelectAll = (e) => {
    setIsSelectAll(e.target.checked);

    const selected = cart?.items?.reduce((items, item) => {
      items[item._id] = e.target.checked;
      return items;
    }, {});

    setSelectedItems({ ...selectedItems, ...selected });
  };

  const handleItemsRefresh = async () => {
    try {
      setIsCartLoading(true);
      const updatedCart = await feathers.service(`cart/`).get(cart._id, {
        query: {
          $populate: [
            'items.category',
            'attributes.attributeId',
            'attributes.type',
            'salesPerson',
            'items.miscUploads',
          ],
        },
      });
      setIsCartLoading(false);
      setCart(updatedCart);
    } catch (e) {
      setIsCartLoading(false);
    }
  };

  const handleItemsAdd = async (data: any) => {
    try {
      setIsCartLoading(true);
      const updatedCart = await feathers.service(`/cart/add-item`).create({
        cartId: cart._id,
        ...(data || {}),
        product: data.productId,
        category: data.categoryId,
        value: data.quantity && data.quantity.value ? data.quantity.value : data.quantity,
        $allowHidden: 1,
      });
      setCart(updatedCart);
      handleItemsRefresh();
      setIsCartLoading(false);
    } catch (e) {
      setIsCartLoading(false);
    }
  };

  const handleItemsRemove = async () => {
    setErroMessage({
      ...errorMessage,
      itemsRemove: '',
    });
    try {
      setIsCartLoading(true);
      const updatedCart = await feathers.service(`/cart/remove-item`).create({
        cartId: cart._id,
        itemIds: Object.keys(selectedItems).filter((key) => selectedItems[key]),
      });
      setIsCartLoading(false);
      setSelectedItems({});
      setCart(updatedCart);
      setIsSelectAll(false);
    } catch (e) {
      setIsCartLoading(false);
      setErroMessage({
        ...errorMessage,
        itemsRemove:
          e.code && e.code < 500
            ? e.message
            : 'We are experiencing technical difficulties. Please try again',
      });
    }
  };

  const handleShipToAddressSubmit = async (address: any) => {
    setErroMessage({
      ...errorMessage,
      shipTo: '',
    });
    try {
      setIsLoading(true);
      const updatedCart = await feathers.service('/cart/set-shipto').create({
        cartId: cart._id,
        address,
      });

      setIsLoading(false);
      if (updatedCart) {
        setCart(updatedCart);
      }
    } catch (e) {
      setIsLoading(false);
      setErroMessage({
        ...errorMessage,
        shipTo:
          e.code && e.code < 500
            ? e.message
            : 'We are experiencing technical difficulties. Please try again',
      });
    }
  };

  const handleShipFromAddressChange = async (address: any) => {
    const updatedCart = await feathers.service('/cart/set-shipfrom').create({
      cartId: cart._id,
      address,
    });

    setCart(updatedCart);
  };

  const handleAssignSalesPerson = async (id: any) => {
    setErroMessage({
      ...errorMessage,
      assignSalesPerson: '',
    });
    try {
      setIsAssignPersonLoading(true);
      const updatedCart = await feathers.service('/cart/assign-salesperson').create({
        cartId: cart._id,
        salesPersonId: id,
      });
      setCart(updatedCart);
      setIsAssignPersonLoading(false);
      setIsAssignSalesPersonModal(false);
    } catch (e) {
      setIsAssignPersonLoading(false);
      setErroMessage({
        ...errorMessage,
        assignSalesPerson:
          e.code && e.code < 500
            ? e.message
            : 'We are experiencing technical difficulties. Please try again',
      });
    }
  };

  const handleShipMethodChange = async (shipMethod: string, overrideCart = null) => {
    const cartId = overrideCart ? overrideCart._id : cart._id;
    const updatedCart = await feathers.service(`/cart/set-shipping-method`).create({
      cartId: cartId,
      method: shipMethod,
    });

    setCart(updatedCart);
  };

  const debounceDiscount = useDebounce(discount, 350);

  const handleDiscountChange = (value: number) => {
    setDiscount(value);
  };

  const handleShipRateChange = async (shipRate: string) => {
    const updatedCart = await feathers.service(`/cart/set-ship-service`).create({
      cartId: cart?._id,
      shippingService: shipRate,
    });

    setCart(updatedCart);
  };

  const handleCheckout = async () => {
    setIsSubmitting(true);
    const mappedPaymentData: PaymentData & { paymentMethod: any; card?: any } = { ...paymentData };

    // console.log('mappedPaymentData', mappedPaymentData);

    mappedPaymentData.card = mappedPaymentData.creditCard;
    // NOTE: Temporarily map since the method enums don't match on backend.
    if (mappedPaymentData.paymentMethod === 'none') {
      mappedPaymentData.paymentMethod = 'NO_PAYMENT';
    }

    if (mappedPaymentData.creditCardType === 'stored') {
      delete mappedPaymentData.card;
      delete mappedPaymentData.creditCard;
      // delete mappedPaymentData.creditCardToken;
    }

    // TODO: The following lines were copied from Store Front ---------------------------------------------------------------

    const payload: any = {
      // tenant: this.req.tenant.id,
      cartId: cart?._id,
      ...mappedPaymentData,
      paymentMethod: cart?.total === 0 ? 'NO_PAYMENT' : mappedPaymentData.paymentMethod,
      amountDue: cart?.total,
      cardToken: mappedPaymentData.creditCardToken,
      token: mappedPaymentData.creditCardToken,
      skipPayment: paymentData.paymentMethod === 'none' || cart?.total === 0,
      sendNotification: true,
    };

    // if (paymentData.card) {
    //   payload.card = paymentData.card;

    //   payload.card.firstName = paymentData.card.name.substr(0,paymentData.card.name.indexOf(' '))
    //   payload.card.lastName = paymentData.card.name.substr(paymentData.card.name.indexOf(' ')+1);
    // }

    // if (this.req.tenant.microsite) {
    //   payload.microsite = this.req.tenant.microsite;
    // }
    // TODO: The following lines were copied from Store Front END ---------------------------------------------------------------

    setErrorCardMessage('');
    setIsLoading(true);
    try {
      const order: any = await feathers.service(`/cart/checkout`).create(payload);

      if (order.result !== undefined && !order.result) {
        setErrorCardMessage(order.message);
        setIsLoading(false);
        setIsSubmitting(false);
        return;
      } else {
        localStorage.removeItem(lastCartKey);
      }

      if (
        customer.business.domainName === 'badger.inkcloud9.com' ||
        customer.business.domainName === 'oecgraphics.com'
      ) {
        const jobData: any = await feathers.service('badger/jobdata').find({
          query: {
            $findOne: 1,
            order: order.details.orderId,
            $sort: { humanId: 1 },
          },
        });

        props.history.push(`badger/job-data/${jobData.job}`);
      } else {
        props.history.push(`orders/${order.details.orderId}`);
      }

      setErrorCardMessage('');
      setIsLoading(false);
      setIsSubmitting(false);
    } catch (e) {
      const errMsg =
        e?.code < 500 ? e.message : 'We are experiencing technical difficulties. Please try again';
      setErrorCardMessage(errMsg);
    }
    setIsSubmitting(false);
    setIsLoading(false);
  };

  const handleCustomItemSubmit = async (v: any, attributes: any) => {
    setIsCustomItemLoading(true);

    const payload = {
      cartId: cart?._id,
      attributes,
      ...v,
    };

    const updatedCart = await feathers.service('cart/add-item').create({
      ...payload,
    });
    setCart(updatedCart);
    setIsAddModalOpen(false);
    setIsCustomItemLoading(false);
  };

  useEffect(() => {
    const loadLastCart = async (id: string) => {
      try {
        const lastCart: any = await feathers.service('cart').get(id, {
          query: {
            convertedToOrder: { $ne: true },
            $populate: [
              'items.category',
              'items.miscUploads',
              'attributes.attributeId',
              'attributes.type',
              'salesPerson',
              'tenant',
            ],
          },
        });

        if (!lastCart.convertedToOrder) {
          setCart(lastCart);
          setDiscount(lastCart?.discount || 0);
          loadCustomer((lastCart as any).customer);
          loadMicrositeCustomer((lastCart as any).micrositeUser);
        }
      } catch (e) {
        // if there isn't a last cart, do nothing
      }
    };

    const lastCartId = localStorage.getItem(lastCartKey);

    const loadMicrosites = async () => {
      try {
        const res: any = await feathers.service('/microsites').find({
          query: {
            $sort: { name: 1 },
            $np: 1,
          },
        });
        setMicrosites(res);
      } catch (e) {}
    };

    loadMicrosites();

    if (lastCartId) {
      loadLastCart(lastCartId);
    }

    const loadTenantSettings = async () => {
      try {
        const res: any = await feathers
          .service('/tenant-settings/general/')
          .get(cart.tenant, { query: {} });

        if (res) {
          setIsTranslationSite(res?.isTranslationSite ?? false);
        }
      } catch (e) {
        //
      }
    };

    if (cart) {
      loadTenantSettings();
    }
  }, []);

  useEffect(() => {
    const loadDiscount = async () => {
      try {
        const updatedCart = await feathers.service(`/cart/set-discount`).create({
          cartId: cart?._id,
          discount,
        });

        setCart(updatedCart);
      } catch (error) {}
    };

    loadDiscount();
  }, [debounceDiscount]);

  const someSelected = Object.keys(selectedItems).reduce(
    (acc, key) => acc || selectedItems[key],
    false
  );

  let updatedPaymemnt = { ...paymentData };
  if (cart) {
    updatedPaymemnt = { ...updatedPaymemnt, amountDue: cart?.total };
  }

  return (
    <div>
      <PageHeaderRow header="Order Builder" subheader="">
        {/* <Link to="/customers/add">
          <Button content="New" primary size={'mini'} />
        </Link> */}
      </PageHeaderRow>

      <Grid stackable>
        <Grid.Row columns={'equal'} stretched>
          <Grid.Column>
            <Segment loading={isLoading} padded>
              <Label attached="top">Bill To</Label>
              {!cart && (
                <Button
                  size="mini"
                  primary
                  content="Select Customer"
                  onClick={() => setIsAssignCustomerModalOpen(true)}
                />
              )}
              {cart && (
                <Grid columns="two">
                  <Grid.Row>
                    <Grid.Column>
                      {customer && (
                        <AssignedCustomer microsite={micrositeCustomer} customer={customer} />
                      )}
                    </Grid.Column>
                    <Grid.Column>
                      <Button
                        size="mini"
                        primary={!customer}
                        content={customer ? 'Change Customer' : 'Select Customer'}
                        onClick={() => setIsAssignCustomerModalOpen(true)}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              )}
            </Segment>
          </Grid.Column>
          {cart && (
            <Grid.Column>
              <Segment>
                <Label attached="top">Sales Person</Label>
                <Grid>
                  <Grid.Row columns={'equal'}>
                    {cart.salesPerson && (
                      <Grid.Column>
                        {`${cart.salesPerson.firstName ? cart.salesPerson.firstName : ''} ${
                          cart.salesPerson.lastName ? cart.salesPerson.lastName : ''
                        }`}
                      </Grid.Column>
                    )}

                    <Grid.Column>
                      <Button
                        size="tiny"
                        color={cart.salesPerson ? null : 'blue'}
                        content={`${cart.salesPerson ? 'Change ' : 'Assign '} Sales Person`}
                        onClick={() => setIsAssignSalesPersonModal(true)}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
            </Grid.Column>
          )}
        </Grid.Row>
      </Grid>

      {cart && (
        <React.Fragment>
          {cart?.items?.length === 0 && (
            <Segment loading={isLoading} placeholder>
              <Header icon>
                <Icon name="cart" />
                Cart is empty
              </Header>
              <Button primary onClick={() => setIsAddModalOpen(true)}>
                Add Item
              </Button>
            </Segment>
          )}

          {cart?.items?.length > 0 && (
            <Segment loading={isCartLoading} padded>
              <br />
              <Label attached="top">Cart Contents</Label>
              <div>
                <Button
                  primary
                  size="mini"
                  content="Add "
                  onClick={() => setIsAddModalOpen(true)}
                />
              </div>
              <React.Fragment>
                <br />
                <CartContents
                  items={cart.items}
                  cartId={cart._id}
                  onSelectedItemsChange={handleSelectedItemsChange}
                  onRefresh={handleItemsRefresh}
                  selectedItems={selectedItems}
                  onSelectAll={handleSelectAll}
                  isSelectAll={isSelectAll}
                />
                {/* {hasError &&
                <Message
                  error
                  content={errorMessage}
                />
              } */}
                {errorMessage.itemsRemove && <Message error content={errorMessage.itemsRemove} />}
                {someSelected && (
                  <>
                    <br />
                    <Button content="Remove" onClick={handleItemsRemove} disabled={isLoading} />
                  </>
                )}
              </React.Fragment>
            </Segment>
          )}

          {cart?.items?.length > 0 && (
            <React.Fragment>
              <Segment loading={isLoading} padded>
                <Label attached="top">Ship Method</Label>
                <ShipMethods
                  availableMethods={cart.availableShippingMethods}
                  selectedMethod={cart.selectedShippingMethod}
                  onChange={handleShipMethodChange}
                />
              </Segment>

              {(cart?.selectedShippingMethod === 'SHIPPING_SHIP_OUT' ||
                cart?.selectedShippingMethod === 'SHIPPING_SHIPOUT_AND_DIGITAL_DELIVERY') && (
                <Grid stackable>
                  <Grid.Row>
                    <Grid.Column width={10}>
                      <Segment loading={isLoading} padded>
                        <Label attached="top">Ship To / Ship From</Label>
                        <ShipTo
                          values={{
                            ...(cart.shipTo || {}),
                            countryCode: cart?.shipTo?.countryCode ?? cart?.tenant?.country,
                          }}
                          errorMessage={errorMessage.shipTo}
                          onClick={handleShipToAddressSubmit}
                        />
                      </Segment>
                    </Grid.Column>
                    <Grid.Column width={6}>
                      <Segment loading={isLoading} padded>
                        <Label attached="top">Ship Rates</Label>
                        <ShipRates
                          availableShippingRates={cart?.availableShippingRates}
                          selectedShippingService={cart?.selectedShippingService as string}
                          onChange={handleShipRateChange}
                        />
                      </Segment>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              )}

              {cart?.selectedShippingMethod === 'SHIPPING_WILL_CALL' && (
                <PickupLocation
                  id={cart?._id}
                  micrositeCustomer={micrositeCustomer}
                  initLocation={cart.selectedPickupLocation}
                />
              )}

              <Grid stackable>
                <Grid.Row columns="equal">
                  <Grid.Column>
                    <Segment padded>
                      <Label attached="top">PO</Label>
                      <PO cart={cart} />
                    </Segment>
                  </Grid.Column>
                  <Grid.Column>
                    <Segment padded>
                      <Label attached="top">Cost Center</Label>
                      <CostCenter cart={cart} />
                    </Segment>
                  </Grid.Column>
                </Grid.Row>
              </Grid>

              <Segment padded>
                <Label attached="top">Promo Code</Label>
                <PromoCode cart={cart} onSetCart={setCart} />
              </Segment>

              <Segment padded>
                <Label attached="top">Summary</Label>
                <Summary cart={cart} discount={discount} onChange={handleDiscountChange} />
              </Segment>

              <Payment {...updatedPaymemnt} customer={customer} onChange={setPaymentData} />
              {/* {this.state.errorMessage !== '' && <Message name='errorMessage'
            error
            icon={'warning circle'}
            content={this.state.errorMessage}
          />} */}

              {errorCardMessage && <Message error content={errorCardMessage} />}
              <br />
              <Button
                primary
                fluid
                content="Create Order"
                onClick={handleCheckout}
                disabled={isLoading}
                loading={isLoading}
              />
            </React.Fragment>
          )}
        </React.Fragment>
      )}

      {isAssignCustomerModalOpen && (
        <SelectCustomerModal
          open={isAssignCustomerModalOpen}
          handleClose={() => setIsAssignCustomerModalOpen(false)}
          handleSubmit={handleAssignCustomer}
          microsites={microsites}
        />
      )}

      {isAssignSalesPersonModal && (
        <AssignSalesPersonModal
          open={isAssignSalesPersonModal}
          isSubmitting={isAssignPersonLoading}
          onClose={() => setIsAssignSalesPersonModal(false)}
          onSubmit={handleAssignSalesPerson}
          errorMessage={errorMessage.assignSalesPerson}
        />
      )}

      {isAddModalOpen && (
        <ProductAddModal
          open={isAddModalOpen}
          isCustomItemLoading={isCustomItemLoading}
          handleClose={() => setIsAddModalOpen(false)}
          handleSubmit={handleItemsAdd}
          isTranslationSite={isTranslationSite}
          handleCustomItemSubmit={handleCustomItemSubmit}
        />
      )}
    </div>
  );
};
