import * as React from 'react';
import { Button, Popup, Label } from 'semantic-ui-react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { FormattedDate, FormattedTime } from 'react-intl';
import {
  useList,
  ListPage,
  basicQueryMap,
  generateOperatorOptions,
  Operator,
} from '@inkcloud/components';

import { Truncate } from './../../../common';
import { Feathers } from '../../../bootstrap/feathers/feathers';

interface ListProps {}

const serviceName = '/store-front/content/testimonials';
const pathPrefix = `/customers/testimonials`;

const filterQueryMap = (values) => basicQueryMap(values, {});

export async function getFilterDefinition() {
  const definition = [
    {
      label: 'Date:Created',
      value: 'createdAt',
      operators: generateOperatorOptions([Operator.Between]),
      type: 'date',
    },
  ];

  return definition.sort((a: any, b: any) => a.label.localeCompare(b.label));
}

const { useState } = React;

export const List: React.FunctionComponent<ListProps & RouteComponentProps<any>> = (props) => {
  const {
    match: { params },
    location,
    history,
  } = props;

  const useListValues = useList({
    feathers: [
      'find',
      serviceName,
      [
        {
          query: {
            $populate: ['customer'],
          },
        },
      ],
    ],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  const [isSubmitting, setIsSubmitting] = useState({
    publish: false,
  });
  const [selectedTestimonial, setSelectedTestimonial] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const handlePublish = async (values: any) => {
    setIsSubmitting({
      ...isSubmitting,
      publish: true,
    });

    const payload = {
      ...values,
      isPublished: true,
    };
    try {
      const res: any = await Feathers.service('/store-front/content/testimonials').patch(
        values._id,
        payload,
        { query: {} }
      );
      setSelectedTestimonial(null);

      useListValues.reload();
    } catch (e) {
      const errMsg =
        e?.code < 500 ? e.message : 'We are experiencing technical difficulties. Please try again';
      setErrorMessage(errMsg);
    }

    setIsSubmitting({
      ...isSubmitting,
      publish: false,
    });
  };

  const tableDefinition = [
    {
      key: 'createdAt',
      label: 'Date',
      sortable: true,
      cell: (v) =>
        v && (
          <Popup
            size="mini"
            content={<FormattedTime value={v} />}
            trigger={
              <div>
                <FormattedDate value={v} />
              </div>
            }
          />
        ),
    },
    {
      key: 'customer',
      label: 'Customer',
      sortable: true,
      cell: (v, r) => (
        <Link to={r.customer?._id ?? ''}>
          {r.customer?.primaryContact?.firstName ?? ''}
          {r.customer?.primaryContact?.lastName ?? ''}
        </Link>
      ),
    },
    {
      key: 'rating',
      label: 'Rating',
      sortable: true,
      cell: (v, r) => v,
    },
    {
      key: 'text',
      label: 'Review',
      sortable: true,
      cell: (v, r) => <Truncate text={v} />,
    },
    {
      key: '',
      label: 'Status',
      sortable: true,
      cell: (v, r) => <>{r?.isPublished && <Label>Published</Label>}</>,
    },
    {
      key: '',
      label: '',
      sortable: false,
      cell: (v, r) => (
        <>
          <Popup
            size="mini"
            content="Publish"
            trigger={
              <Button
                onClick={() => {
                  handlePublish(r);
                  setSelectedTestimonial(r._id);
                }}
                loading={isSubmitting.publish && selectedTestimonial === r._id}
                size={'mini'}
                icon={'arrow alternate circle up'}
              />
            }
          />
          <Popup
            size="mini"
            content="Edit"
            trigger={
              <Link to={`${pathPrefix}/${r._id}`}>
                <Button size={'mini'} icon={'edit'} />
              </Link>
            }
          />
        </>
      ),
      textAlign: 'right',
    },
  ];

  const headerActions = (
    <>
      <Link to={`${pathPrefix}/add`}>
        <Button content="New" primary size={'mini'} />
      </Link>
    </>
  );

  return (
    <div>
      <ListPage
        header="Testimonials"
        subheader="List"
        useListValues={useListValues}
        history={history}
        pathPrefix={pathPrefix}
        tableDefintion={tableDefinition}
        withCheckboxes={true}
        headerActions={headerActions}
        getFilterDefinition={getFilterDefinition}
      />
    </div>
  );
};
