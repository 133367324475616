import * as React from 'react';
import { FormattedDate } from 'react-intl';
import { Box, Tag, Text } from '@chakra-ui/react';

const infoRowStyle = { paddingTop: 2, paddingBottom: 2 };

const renderInfo = (label: string, text: string | React.ReactNode) => (
  <div className="ui two column grid">
    <div className="row" style={infoRowStyle}>
      <div className="six wide column">
        <label>{label}</label>
      </div>
      <div className="ten wide column">{text}</div>
    </div>
  </div>
);

export interface InfoTableProps {
  data: any;
}

export function InfoTable(props: InfoTableProps) {
  const primaryContact = props.data.primaryContact || {
    firstName: '',
    lastName: '',
  };

  let customerName = '';
  if (props.data.organizationName) {
    customerName = props.data.organizationName;
  } else if (props.data.primaryContact) {
    customerName = `${primaryContact.firstName} ${primaryContact.lastName}`;
  }

  return (
    <div className="ui four column stackable padded grid ">
      <div className="eight wide column">
        {props.data.organizationName && renderInfo('Company', customerName)}
        {renderInfo('Contact', `${primaryContact.firstName} ${primaryContact.lastName}`)}
        {props.data.email &&
          renderInfo('Email', <a href={`mailto:${props.data.email}`}>{props.data.email}</a>)}
        {props.data.phone && renderInfo('Phone', props.data.phone)}
        {props.data.createdAt &&
          renderInfo('Sign Up', <FormattedDate value={props.data.createdAt} />)}
        {props.data.isTaxExemptApproved &&
          renderInfo('Sales Tax', props.data.isTaxExemptApproved ? 'Exempt' : 'Not Exempt')}
        {props.data.abn && renderInfo('ABN', props.data.abn)}
        {props.data.platform && renderInfo('Platform', props.data.platform)}
      </div>
      <div className="eight wide column">
        {props.data.foreignId && renderInfo('Foreign ID', props.data.foreignId)}

        {props.data.tags?.length > 0 &&
          renderInfo(
            'Tags',
            <React.Fragment>
              {props.data.tags.map((item, index) => (
                <Tag key={index} size="sm" mr={2} mb={2}>
                  {item}
                </Tag>
              ))}
            </React.Fragment>
          )}
        {props.data.metadata?.length > 0 &&
          renderInfo(
            'Metadata',
            <React.Fragment>
              {props.data.metadata.map((item, index) => (
                <Box key={index}>
                  <Text fontFamily={'monospace'} fontSize={9} as="strong" color={'gray.600'}>
                    {' '}
                    {item?.key}:{' '}
                  </Text>
                  <Text fontFamily={'monospace'} fontSize={9}>
                    {item?.value}
                  </Text>
                </Box>
              ))}
            </React.Fragment>
          )}
        {renderInfo(
          'Address',
          <React.Fragment>
            <div>{props.data.address1}</div>
            <div>{props.data.address2}</div>
            <div>
              {props.data.city} {props.data.stateProvince}, {props.data.postalCode}
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
}
