import React, { useState } from 'react';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Checkbox,
  FormErrorMessage,
  FormHelperText,
  Alert,
  Button,
  Stack,
} from '@chakra-ui/react';

import { AsyncSelect } from 'chakra-react-select';

import feathers from '../../../bootstrap/feathers';

interface IReAssignModalProps {
  isOpen: boolean;
  salesPersonId: string;
  onReload: () => void;
  onClose: () => void;
}

type optionType = { label: string; value: string };

const mapOptionsToValue = (options: any) =>
  options?.map((o) => ({
    label: `${o?.firstName ?? ''} ${o?.lastName ?? ''}`,
    value: o?._id,
  }));

export const ReAssignModal: React.FunctionComponent<IReAssignModalProps> = (props) => {
  const { isOpen, salesPersonId, onReload, onClose } = props;

  const [values, setValues] = useState({
    orders: false,
    customers: false,
    salesPeople: false,
    reassignTo: '',
    salesPersonId,
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleProductOptions: any = async (e: string, callback) => {
    try {
      if (e) {
        const res = await feathers.service('/sales-people').find({
          query: {
            $sort: { firstName: 1 },
            $or: [{ firstName: { $LIKE: e } }, { lastName: { $LIKE: e } }],
            _id: { $ne: salesPersonId },
            $np: 1,
          },
        });
        callback(mapOptionsToValue(res));
      }
    } catch (er) {
      console.error(er);
    }
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    setErrorMessage('');
    try {
      const res: any = await feathers
        .service('/sales-people/reassign')
        .create(values, { query: {} });

      onReload();
      onClose();
    } catch (e) {
      const errMsg =
        e?.code < 500 ? e.message : 'We are experiencing technical difficulties. Please try again';
      setErrorMessage(errMsg);
    }
    setIsSubmitting(false);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Re-assign Sales rep</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={7} mb={3} direction="row">
            <FormControl>
              <Checkbox
                name="salesPeople"
                onChange={(e) => setValues({ ...values, [e.target.name]: e.target.checked })}
              >
                Sales reps (Managed by this user)
              </Checkbox>
            </FormControl>
          </Stack>
          <Stack spacing={7} mb={3} direction="row">
            <FormControl>
              <Checkbox
                name="customers"
                onChange={(e) => setValues({ ...values, [e.target.name]: e.target.checked })}
              >
                Customers
              </Checkbox>
            </FormControl>
          </Stack>
          <Stack spacing={7} mb={3} direction="row">
            <FormControl>
              <Checkbox
                name="orders"
                onChange={(e) => setValues({ ...values, [e.target.name]: e.target.checked })}
              >
                Orders
              </Checkbox>
            </FormControl>
          </Stack>
          <Stack spacing={7} mb={3} direction="row">
            <FormControl>
              <FormLabel>Re-assign to:</FormLabel>
              <AsyncSelect
                cacheOptions
                isClearable
                placeholder=""
                onChange={(e: optionType | null) =>
                  setValues({ ...values, reassignTo: e?.value as string })
                }
                loadOptions={handleProductOptions}
                noOptionsMessage={(v) =>
                  !v.inputValue ? 'Start typing name to search...' : 'No options'
                }
              />
            </FormControl>
          </Stack>

          {/* <Form className={`${errorMessage ? 'error' : ''}`}>
          <Form.Field>
            <Checkbox
              name="salesPeople"
              label="Sales reps (Managed by this user)"
              onChange={(e, data) => setValues({ ...values, [data.name]: data.checked })} />
          </Form.Field>
          <Form.Field>
            <Checkbox
              name="customers"
              label="Customers"
              onChange={(e, data) => setValues({ ...values, [data.name]: data.checked })} />
          </Form.Field>
          <Form.Field>
            <Checkbox
              name="orders"
              label="Orders"
              onChange={(e, data) => setValues({ ...values, [data.name]: data.checked })} />
          </Form.Field>
          <Form.Field>
            <label>Re-assign to:</label>
            <FeathersDropdown
              search selection clearable
              serviceName='/sales-people'
              name="reassignTo"
              query={{
                $sort: { firstName: 1 },
                _id: { $ne: salesPersonId },
                $np: 1
              }}
              resultMapper={(r) => ({ value: r._id, text: `${r?.firstName ?? ''} ${r?.lastName ?? ''}` })}
              onChange={(e, data) => setValues({ ...values, [data.name]: data.value })}
            />
          </Form.Field>
          
        
        </Form> */}

          {errorMessage && <Alert status="error">{errorMessage}</Alert>}
        </ModalBody>

        <ModalFooter>
          <Button mr={3} onClick={onClose}>
            Close
          </Button>
          <Button
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
            colorScheme="blue"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
