import * as React from 'react';
import { Formik, Form as FormikForm, ErrorMessage } from 'formik';
import { Button, Form, Message } from 'semantic-ui-react';
import { SemanticField } from '../../../common/Form';
import { To } from '@inkcloud/components';

import Feathers from '../../../bootstrap/feathers';

import * as Yup from 'yup';

interface PackagingFormProps {
  id: string;
  packaging: any;
}

const stategyOptions = [
  { value: 'no-combine', text: 'No Combine' },
  { value: 'combine', text: 'Combine' },
];

const { useState } = React;

export const PackagingForm: React.FunctionComponent<PackagingFormProps> = (props) => {
  const { packaging, id } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const PackagingSchema = Yup.object().shape({
    combineThreshold: Yup.number().required().typeError('Must a number'),
    maxWeightPerBox: Yup.number().required().typeError('Must a number'),
  });

  const handleSubmit = async (value: any) => {
    setIsLoading(true);
    const [error, res] = await To(
      Feathers.service('tenant-settings/packaging').patch(id, value, { query: {} })
    );
    setIsLoading(false);

    error && setErrorMessage(error.message);
  };

  return (
    <>
      {errorMessage && <Message error content={errorMessage} />}
      <Formik
        initialValues={packaging}
        enableReinitialize={true}
        validationSchema={PackagingSchema}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            setFieldValue,
            setFieldTouched,
          } = props;

          return (
            <>
              <FormikForm className={`ui form ${isLoading && 'loading'}`}>
                <Form.Field>
                  <SemanticField
                    component={Form.Dropdown}
                    fluid
                    selection
                    name={'selectedStrategy'}
                    options={stategyOptions}
                    label="Strategy"
                  />
                </Form.Field>
                <Form.Field>
                  <SemanticField
                    fluid
                    name="combineThreshold"
                    component={Form.Input}
                    label="Combine Threshold"
                  />
                  <ErrorMessage name="combineThreshold" />
                </Form.Field>
                <Form.Field>
                  <SemanticField
                    fluid
                    name="maxWeightPerBox"
                    component={Form.Input}
                    label="Max weight Per box"
                  />
                  <ErrorMessage name="maxWeightPerBox" />
                </Form.Field>
                <Form.Field>
                  <Button type="submit" primary content="Save" />
                </Form.Field>
              </FormikForm>
            </>
          );
        }}
      </Formik>
    </>
  );
};
