import React, { useState } from 'react';
import { feathersAuthentication, feathersModeSwitch, socket } from '../../bootstrap/feathers';
import { store } from '../../bootstrap/store/index';
import { AbilityContext, ability, rolesToPermissionsList } from '../../common/permissions';
import { LoginForm } from './LoginForm';

export default function LoginFormContainer() {
  const [errorMessage, setErrorMessage] = useState('');

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (values) => {
    const payload: any = {
      strategy: 'local',
      email: values?.email?.trim(),
      password: values?.password?.trim(),
    };

    const devHost = '.localdev.inkcloud9.com';
    const mainHost = '.inkcloud9.app';
    if (window.location.hostname === 'localhost') {
      payload.key = values.key ? values.key : '';
    } else if (window.location.hostname.endsWith(devHost)) {
      payload.key = window.location.hostname.slice(0, devHost.length * -1);
    } else if (window.location.hostname.endsWith(mainHost)) {
      payload.key = window.location.hostname.slice(0, mainHost.length * -1);
    } else {
      payload.domain = window.location.hostname;
    }

    setErrorMessage('');
    setIsSubmitting(true);

    try {
      const r = await feathersAuthentication.authenticate(payload)?.payload?.promise;

      if (r) {
        store.dispatch(feathersAuthentication.authenticate());
      }

      ability.update(rolesToPermissionsList(r?.user?.roles2));

      socket.authenticate();
    } catch (err) {
      const errMsg =
        err?.name === 'NotAuthenticated'
          ? 'Username and/or password incorrect'
          : '500 Internal Server Error';
      setErrorMessage(errMsg);
    }

    setIsSubmitting(false);
  };

  return (
    <LoginForm onSubmit={handleSubmit} errorMessage={errorMessage} isSubmitting={isSubmitting} />
  );
}

