import React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { FormattedDate, FormattedTime } from 'react-intl';

import {
  Button,
  Box,
  ButtonGroup,
  IconButton,
  Tooltip,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
  InputGroup,
  Input,
  InputRightElement,
  Alert,
  Image,
  Stack,
  Tag,
} from '@chakra-ui/react';

import { FaEdit, FaEllipsisV, FaUserAlt, FaChevronCircleRight, FaFileImage } from 'react-icons/fa';

import {
  useList,
  PageHeaderRow,
  EmptyWrapper,
  ListTable,
  PaginatorWrapper,
  GenericFilter,
  basicQueryMap,
} from '@inkcloud/components';
import type { ModelTypes } from '@inkcloud/icapi-types';

import getFilterDefinition from './FilterForm';
import { PreviewModal } from './preview';
import { DesignTemplateModal } from './DesignTemplateModal';

import { DuplicateModal } from './DuplicateModal';

import Feathers from '../../../bootstrap/feathers';

interface ListProps {}

const { useState, useEffect } = React;

export const serviceName = 'design-templates';

const filterQueryMap = (values) => basicQueryMap(values, {});

interface IPriorityInput {
  id: string;
  value: any;
}

export const PriorityInput = (props) => {
  const { id, value: initialValue } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState(initialValue);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleChange = async (e) => {
    setValue(e.target.value);
  };

  const handleClick = async (e) => {
    setIsLoading(true);
    setErrorMessage('');
    try {
      await Feathers.service('design-templates').patch(id, {
        priority: value,
      });

      props?.onReload();
    } catch (err) {
      const errMsg =
        e?.code < 500 ? e?.message : 'We are experiencing technical difficulties. Please try again';

      setErrorMessage(errMsg);
    }

    setIsLoading(false);
  };

  return (
    <Box>
      <InputGroup size="md" mb={3}>
        <Input
          isInvalid={!!errorMessage}
          onChange={handleChange}
          value={value}
          isDisabled={isLoading}
          placeholder="Priority"
          pr="2.5rem"
        />
        <InputRightElement width="2.5rem">
          <IconButton
            isLoading={isLoading}
            colorScheme="gray"
            aria-label="Edit"
            icon={<FaChevronCircleRight />}
            onClick={handleClick}
          />
        </InputRightElement>
      </InputGroup>
      {errorMessage && <Alert status="error">{errorMessage}</Alert>}
    </Box>
  );
};

export default function List(props: ListProps & RouteComponentProps) {
  const {
    match: { params },
    location,
    history,
  } = props as any;
  const {
    state,
    reload,
    handleDelete,
    selectedItems,
    clearAll,
    setChecked,
    someSelected,
    toggleAll,
    filter,
    setFilter,
    sort,
    handleSort,
  } = useList({
    feathers: [
      'find',
      serviceName,
      [
        {
          query: {
            $sort: { priority: 1, name: 1 },
            $includeFieldData: 1,
            $populate: ['templateUpload', 'previews'],
          },
        },
      ],
    ],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { priority: 1, name: 1 },
  }) as any;

  const [selectedDesignTemplate, setSelectedDesignTemplate] =
    useState<ModelTypes.DesignTemplates>();
  const [isPreviewModalOpen, setisPreviewModalOpen] = useState(false);

  const [isDesignTemplateModalOpen, setIsDesignTemplateModalOpen] = useState(false);
  const [isDuplicateModaOpen, setIsDuplicateModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState({
    duplicate: false,
  });

  const [errorMessage, setErrorMessage] = useState({
    duplicate: '',
  });

  useEffect(() => {
    if (location?.state?.isDesignTemplateModalOpen === false) {
      setIsDesignTemplateModalOpen(false);
    }
  }, [location.state]);

  const handleDownload = async (id: string) => {
    const result: any = await Feathers.service('design-templates/download/').get(id, { query: {} });
    window.open(result.url, '_blank');
  };

  const pathPrefix = `/admin/design-templates`;

  const handleDuplicate = async () => {
    setErrorMessage({
      ...errorMessage,
      duplicate: '',
    });

    setIsSubmitting({
      ...isSubmitting,
      duplicate: true,
    });

    const payload = { ...(selectedDesignTemplate || {}) };

    delete payload._id;

    payload.templateUpload = undefined;
    payload.packageContents = [];
    payload.thumbnails = [];
    payload.createdAt = undefined;
    payload.updatedAt = undefined;

    payload.name = `${payload.name} Copy`;
    payload.key = `${payload.key}-copy`;

    try {
      const res: any = await Feathers.service(serviceName).create(payload, { query: {} });

      if (!res?.result) {
        setErrorMessage({
          ...errorMessage,
          duplicate: res?.message,
        });
      }

      setIsDuplicateModalOpen(false);
      setSelectedDesignTemplate({});
      reload();
    } catch (e) {
      const message =
        e.code && e.code < 500
          ? e.message
          : 'We are experiencing technical difficulties. Please try again';

      setErrorMessage({
        ...errorMessage,
        duplicate: message,
      });
    }

    setIsSubmitting({
      ...isSubmitting,
      duplicate: false,
    });
  };

  const tableDefinition = [
    {
      key: 'createdAt',
      label: 'Date',
      sortable: true,
      cell: (v) => (
        <Tooltip hasArrow aria-label="time" label={<FormattedTime value={v} />}>
          <Box>
            <FormattedDate value={v} />
          </Box>
        </Tooltip>
      ),
    },
    {
      key: 'name',
      label: 'Name',
      sortable: true,
      cell: (v) => v,
    },
    {
      key: '',
      label: 'Package Filename',
      sortable: true,
      cell: (v, r) => <>{r?.templateUpload?.originalFilename}</>,
    },
    {
      key: 'tags',
      label: 'Tags',
      sortable: true,
      cell: (v) => (
        <Stack direction="row" spacing={2}>
          {v?.map((t, idx) => (
            <Tag key={idx} size={'mini'}>
              {t}
            </Tag>
          ))}
        </Stack>
      ),
    },
    {
      key: '',
      label: 'Priority',
      sortable: true,
      cell: (v, r) => (
        <>
          <PriorityInput id={r._id} value={r.priority} onReload={reload} />
        </>
      ),
    },
    {
      key: '',
      label: 'Placeholder',
      sortable: true,
      cell: (v, r) => (
        <Stack direction="row">
          {r?.previews?.map((p, idx) => (
            <Image key={idx} src={`https://storage.googleapis.com/icstorage/${p.cloudFilename}`} />
          ))}
        </Stack>
      ),
    },
    {
      key: '',
      label: '',
      sortable: false,
      cell: (v, r) => (
        <>
          <ButtonGroup spacing={1}>
            <Tooltip label="Edit Details">
              <Link to={`${pathPrefix}/${r._id}`}>
                <IconButton colorScheme="gray" aria-label="Edit" icon={<FaEdit />} />
              </Link>
            </Tooltip>
            <Menu>
              <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<FaEllipsisV />}
                variant="outline"
              />
              <MenuList>
                <MenuItem
                  onClick={() => {
                    setisPreviewModalOpen(true);
                    setSelectedDesignTemplate(r);
                  }}
                  icon={<FaFileImage />}
                >
                  Preview
                </MenuItem>
                <MenuItem onClick={() => handleDownload(r._id)} icon={<FaUserAlt />}>
                  Download Package
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setSelectedDesignTemplate(r);
                    setIsDuplicateModalOpen(true);
                  }}
                  icon={<FaUserAlt />}
                >
                  Duplicate Template
                </MenuItem>
              </MenuList>
            </Menu>
          </ButtonGroup>
        </>
      ),
      textAlign: 'right',
    },
  ];

  const { queryResult, isLoading: isStateLoading } = state as any;

  const resolvedQueryResult = queryResult?.data ? queryResult.data : queryResult;

  return (
    <div>
      <PageHeaderRow header="Design Templates" subheader="List">
        <ButtonGroup spacing={1}>
          <Link
            to={{
              pathname: `${pathPrefix}/set-order`,
              state: {
                isDesignTemplateModalOpen: true,
              },
            }}
          >
            <Button type="button" size="sm">
              Set Order
            </Button>
          </Link>

          <Link to={`${pathPrefix}/add`}>
            <Button colorScheme="blue" size="sm">
              New
            </Button>
          </Link>
        </ButtonGroup>
      </PageHeaderRow>

      <Box p={3} mb={3} borderRadius="md" borderWidth="1px">
        <GenericFilter
          isAutoFocus={true}
          factDefinitionFunction={getFilterDefinition}
          value={filter}
          onChange={(v) => setFilter({ ...v })}
        />
      </Box>

      <EmptyWrapper queryResult={resolvedQueryResult} isStateLoading={isStateLoading}>
        <PaginatorWrapper.Top
          filter={filter}
          reload={reload}
          data={queryResult as any}
          pathPrefix={`${pathPrefix}`}
        />
        <ListTable
          data={resolvedQueryResult}
          definition={tableDefinition}
          selectedItems={selectedItems}
          setChecked={setChecked}
          sort={sort}
          sortClickFunction={handleSort}
          toggleAll={toggleAll}
          withCheckboxes={true}
        />

        <PaginatorWrapper.Bottom reload={reload} data={queryResult as any} pathPrefix={pathPrefix}>
          {someSelected && (
            <Button type="button" colorScheme="blue" onClick={handleDelete}>
              Delete
            </Button>
          )}
        </PaginatorWrapper.Bottom>
        {isDesignTemplateModalOpen && <DesignTemplateModal />}

        {isPreviewModalOpen && (
          <PreviewModal
            onSubmit={() => {}}
            onClose={() => setisPreviewModalOpen(false)}
            designTemplate={selectedDesignTemplate}
          />
        )}
        {isDuplicateModaOpen && (
          <DuplicateModal
            isOpen={isDuplicateModaOpen}
            onClose={() => {
              setIsDuplicateModalOpen(false);
              setSelectedDesignTemplate({});
            }}
            onSubmit={handleDuplicate}
            name={selectedDesignTemplate?.name ?? ''}
            isSubmitting={isSubmitting.duplicate}
            errorMessage={errorMessage.duplicate}
          />
        )}
      </EmptyWrapper>
    </div>
  );
}
