import * as React from 'react';
import Dropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown';

export interface AppProps {
  input: any;
  onChange: any;
}

export interface AppState {
  tags: any[];
}

export default class App extends React.Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);
    this.state = {
      tags: [],
    };
  }

  componentDidMount() {
    if (this.props.input) {
      this.initTags(this.props.input.value);
    }
  }

  componentWillReceiveProps(next) {
    if (next && next.input && next.input.value !== this.props.input.value) {
      this.initTags(next.input.value);
    }
  }

  initTags(tags = []) {
    if (!Array.isArray(tags)) {
      tags = [];
    }

    this.setState({
      ...this.state,
      tags: tags.sort((a, b) => a - b).map((q) => ({ text: q, value: q })),
    });
  }

  onQuantityAdd = (e, data) => {
    const { input } = this.props;
    const foundTag = this.state.tags.find((q) => q.value === data.value);
    if (!foundTag) {
      const tags = [...this.state.tags];
      tags.push({ text: data.value, value: data.value });
      this.setState({ ...this.state, tags });
      input.onChange(tags.map((t) => t.value));
    }
  };

  onChange = (e, data) => {
    const { input } = this.props;
    input.onChange(data.value);
  };

  render() {
    const normalizedValue = Array.isArray(this.props.input.value) ? this.props.input.value : [];
    return (
      <Dropdown
        selection
        fluid
        allowAdditions={true}
        onAddItem={this.onQuantityAdd}
        search={true}
        placeholder={'Enter quantities'}
        multiple={true}
        options={this.state.tags}
        onChange={this.onChange}
        value={normalizedValue}
      />
    );
  }
}
