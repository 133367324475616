import React, { useState } from 'react';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Alert,
  Text,
} from '@chakra-ui/react';

import feathers, { icapi } from '../../bootstrap/feathers';

interface IResetQuoteModalProps {
  rfqId: string;
  vendor?: { _id: string; name: string };
  onReload: () => void;
  onClose: () => void;
}

export default function ResetQuoteModal(props: IResetQuoteModalProps) {
  const { rfqId, vendor, onReload, onClose } = props;

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (v) => {
    setIsSubmitting(true);
    setErrorMessage('');
    try {
      const r = await feathers.service('rfqs/responses').remove(rfqId, {
        query: {
          vendor: vendor?._id,
        },
      });
      onReload?.();
      onClose();
    } catch (e) {
      const errMsg = e?.code < 500 ? e?.message : 'An error occurred. Please try again later.';
      setErrorMessage(errMsg);
    }

    setIsSubmitting(false);
  };

  return (
    <Modal isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Reset Quote</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>Are you sure you want to reset quote from {vendor?.name ?? ''}</Text>
          {errorMessage && <Alert status="error">{errorMessage}</Alert>}
        </ModalBody>

        <ModalFooter>
          <Button isDisabled={isSubmitting} onClick={onClose} mr={3}>
            Close
          </Button>
          <Button
            isDisabled={isSubmitting}
            isLoading={isSubmitting}
            colorScheme="blue"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
