import * as React from 'react';
import { RouteComponentProps, withRouter, Link, Switch, Route } from 'react-router-dom';
import { IndexWrapper, IndexWrappedProps } from '../../bootstrap/IndexWrapper';
import { Button, Header, Icon, Segment } from 'semantic-ui-react';
import ProductFilterComponent from './ProductFilter';
import { Paginator } from '../../common';
import { FeathersPage } from '@inkcloud/ui-base';
import { services } from '../../bootstrap/feathers';

const attributeTagStyle = {
  marginBottom: '6px',
};

interface AdditionalProps {
  findPortalProducts: Function;
  portalProducts: any;
  addPortalProduct: Function;
  deletePortalProduct: Function;
}

type PortalProductAddProps = IndexWrappedProps & RouteComponentProps<any> & AdditionalProps;

interface PortalProductAddState {
  isLoading: boolean;
}

class PortalProductAddComponent extends React.Component<
  PortalProductAddProps,
  PortalProductAddState
> {
  constructor(props) {
    super(props);
    this.handleMultiAdd = this.handleMultiAdd.bind(this);
    this.isAdded = this.isAdded.bind(this);
    this.handleClickRemove = this.handleClickRemove.bind(this);
    this.handleClickAdd = this.handleClickAdd.bind(this);
    this.state = { isLoading: false };
  }

  componentDidMount() {
    this.loadPortalProducts();
  }

  loadPortalProducts() {
    return this.props.findPortalProducts({
      query: {
        portal: this.props.match.params.id,
      },
    });
  }

  isAdded(id) {
    if (!this.props.portalProducts /*|| !this.props.portalProducts.data*/) {
      return false;
    }

    return !!this.props.portalProducts.find((p) => p.product._id === id);
  }

  handleClickRemove(e) {
    const foundPortalProduct = this.props.portalProducts.find(
      (p) => p.product._id === e.currentTarget.value
    );
    if (foundPortalProduct) {
      this.setState({ ...this.state, isLoading: true });
      this.props.deletePortalProduct(foundPortalProduct._id).then(() => {
        this.setState({ ...this.state, isLoading: false });
        this.loadPortalProducts();
      });
    }
  }

  handleClickAdd(e) {
    const id = e.currentTarget.value;
    const foundProduct = this.props.queryResult.data.find((p) => p._id === id);
    if (foundProduct) {
      this.setState({ ...this.state, isLoading: true });
      const categoryId = foundProduct.category._id;
      const productId = foundProduct._id;

      this.props
        .addPortalProduct({
          portal: this.props.match.params.id,
          category: categoryId,
          product: productId,
          isPublished: true,
        })
        .then(() => {
          this.setState({ ...this.state, isLoading: false });
          this.loadPortalProducts();
        });
    } else {
    }
  }

  handleMultiAdd() {
    this.setState({ ...this.state, isLoading: true });
    // TODO - May want to use async to limit in case the user chooses a large set
    if (this.props.selected) {
      const createPromises = Object.keys(this.props.selected).map((id) => {
        const foundProduct = this.props.queryResult.data.find((p) => p._id === id);
        const categoryId = foundProduct.category._id;
        const productId = foundProduct._id;

        return this.props.addPortalProduct({
          portal: this.props.match.params.id,
          category: categoryId,
          product: productId,
          isPublished: true,
        });
      });
      Promise.all(createPromises)
        .then(() => {
          this.setState({ ...this.state, isLoading: false });

          this.loadPortalProducts();
        })
        .catch((err) => {
          console.log(err);
          throw err;
        });
    }
  }

  render() {
    const props = this.props;
    let pageData = props.queryResult || {};
    let { skip, limit, total } = pageData;

    let filteredWarning = (
      <div className="ui mini warning icon message">
        <div className="header">
          <i className="filter icon"></i>
          You are viewing filtered results
        </div>
      </div>
    );

    let listView = (
      <div>
        {props.isFiltered ? filteredWarning : null}

        <Segment loading={props.isLoading || this.state.isLoading} clearing={true}>
          <Paginator
            className={'right floated'}
            prelink="/"
            skip={skip}
            rowsPerPage={limit}
            totalResult={total}
            onClick={props.onPageChange}
          />
          <Link className="ui button mini" to={`/settings/portals/${this.props.match.params.id}`}>
            Back
          </Link>
          <br />
          <table className="ui small table segment" style={{ marginTop: 30 }}>
            <thead>
              <tr>
                <th></th>
                <th>ID</th>
                <th>Category</th>
                <th>Description</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {props.queryResult &&
                props.queryResult.data &&
                props.queryResult.data.map((item: any, _: any) => {
                  let attributes;
                  let attributesSection;
                  if (item.attributes) {
                    attributes = item.attributes.map((row) => (
                      <a key={row.value._id} className="ui  mini label" style={attributeTagStyle}>
                        {row.value.name}
                      </a>
                    ));
                    attributesSection = <div className="ui mini labels">{attributes}</div>;
                  }

                  return (
                    <tr key={item._id}>
                      <td>
                        <input
                          type="checkbox"
                          checked={props.selected[item._id] || false}
                          value={item._id}
                          onChange={props.handleCheckboxClick}
                        />
                      </td>
                      <td>{item.humanId}</td>
                      <td>{item.category && item.category.name}</td>
                      <td>
                        {item.description !== null ? <div>{item.description}</div> : null}
                        {attributesSection}
                      </td>
                      <td>
                        {this.isAdded(item._id) ? (
                          <Button
                            size={'mini'}
                            icon
                            value={item._id}
                            onClick={this.handleClickRemove}
                          >
                            <Icon name="remove"></Icon>
                          </Button>
                        ) : (
                          <Button
                            primary
                            size={'mini'}
                            icon
                            value={item._id}
                            onClick={this.handleClickAdd}
                          >
                            <Icon name="add"></Icon>
                          </Button>
                        )}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          <Button onClick={this.handleMultiAdd}>Add Products to Portal</Button>
          <div className="ui basic segment clearing">
            <Paginator
              className={'right floated '}
              prelink="/"
              skip={skip}
              rowsPerPage={limit}
              totalResult={total}
              onClick={props.onPageChange}
            />
          </div>
        </Segment>
      </div>
    );

    return (
      <div>
        <h2 className="ui dividing header">Portals - Add Product</h2>
        <ProductFilterComponent onSubmit={this.props.handleFilterSubmit} />
        <div>
          <h2 className="ui dividing header">Products</h2>
          {listView}
        </div>
      </div>
    );
  }
}

export default IndexWrapper(PortalProductAddComponent, 'products', {
  query: {
    $populate: ['category', 'attributes.value'],
    $sort: { category: 1 },
    isDisabled: { $ne: true },
  },
  mapStateToProps: (state) => ({
    portalProducts: state['products-legacy/portal-products'].queryResult,
  }),
  mapDispatchToProps: {
    findPortalProducts: services['products-legacy/portal-products'].find,
    addPortalProduct: services['products-legacy/portal-products'].create,
    deletePortalProduct: services['products-legacy/portal-products'].remove,
  },
  filterMapper: (filterIn) => {
    const filterOut = Object.assign({}, filterIn);
    if (filterOut.name) {
      filterOut.name = { $like: filterOut.name };
      delete filterOut.name;
    }

    if (filterOut.attributes) {
      const flattenedAttributes = Object.keys(filterOut.attributes).reduce((prev, cur) => {
        prev = prev.concat(filterOut.attributes[cur]);
        return prev;
      }, []);

      filterOut['attributes.value'] = { $in: flattenedAttributes };
      delete filterOut.attributes;
    }

    return filterOut;
  },
});
