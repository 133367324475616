import * as React from 'react';
import { Dropdown, TextArea, Icon, Label, Menu, Table, Loader } from 'semantic-ui-react';
import * as Cleave from 'cleave.js/react';
import NumberFormat from 'react-number-format';
import { debounce } from 'lodash-es';

export interface LineItemsProps {
  lineItems: any[];
  originalSalesTax: Number;
  originalDiscount: Number;
  salesTax: Number;
  discount: Number;
  handleAmountChange: Function;
  handleIsTaxableChange: Function;
  handleDescriptionChange: Function;
  isTaxRecalculating: boolean;
}

export interface LineItemsState {}

export default class LineItems extends React.Component<LineItemsProps, LineItemsState> {
  constructor(props: LineItemsProps) {
    super(props);

    this.state = {};
  }

  render() {
    const li =
      this.props.lineItems &&
      this.props.lineItems.map((li) => {
        return (
          <Table.Row key={li._id}>
            <Table.Cell width="six">
              <TextArea
                autoHeight
                value={li.description}
                onChange={this.props.handleDescriptionChange(li._id)}
              />
              {/* {li.description} */}
            </Table.Cell>
            <Table.Cell width="three">
              <Dropdown
                fluid
                options={[
                  { text: 'Taxable', value: 'true' },
                  { text: 'Not Taxable', value: 'false' },
                ]}
                value={li.isTaxable ? 'true' : 'false'}
                onChange={this.props.handleIsTaxableChange(li._id)}
              />
              {/* {li.isTaxable ? 'Taxable' : 'Not Taxable'} */}
            </Table.Cell>
            <Table.Cell width="three" textAlign="right">
              <NumberFormat
                value={li.originalAmount || 0.0}
                decimalScale={2}
                displayType={'text'}
                fixedDecimalScale={true}
                thousandSeparator={true}
                prefix={'$'}
              />
            </Table.Cell>
            <Table.Cell width="four" textAlign="right">
              <Cleave
                placeholder="Enter amount..."
                name={`amount[${li._id}]`}
                value={li.amount}
                onChange={this.props.handleAmountChange(li._id)}
                style={{ textAlign: 'right' }}
                options={{
                  numeral: true,
                  rawValueTrimPrefix: true,
                  numeralPositiveOnly: true,
                  numeralThousandsGroupStyle: 'thousand',
                  prefix: '$',
                }}
              />
            </Table.Cell>
          </Table.Row>
        );
      });

    return (
      <form className="ui form">
        <Table celled size={'small'}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Description</Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.HeaderCell textAlign="right">Original Amount</Table.HeaderCell>
              <Table.HeaderCell textAlign="right">Updated Amount</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {li}
            <Table.Row>
              <Table.Cell width="six">Discount</Table.Cell>
              <Table.Cell width="three"></Table.Cell>
              <Table.Cell width="three" textAlign="right">
                <NumberFormat
                  value={(this.props.originalDiscount || 0.0) as any}
                  decimalScale={2}
                  displayType={'text'}
                  fixedDecimalScale={true}
                  thousandSeparator={true}
                  prefix={'$'}
                />
              </Table.Cell>
              <Table.Cell width="four" textAlign="right">
                <NumberFormat
                  value={(this.props.originalDiscount || 0.0) as any}
                  decimalScale={2}
                  displayType={'text'}
                  fixedDecimalScale={true}
                  thousandSeparator={true}
                  prefix={'$'}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width="six">Sales Tax</Table.Cell>
              <Table.Cell width="three"></Table.Cell>
              <Table.Cell width="three" textAlign="right">
                <NumberFormat
                  value={(this.props.originalSalesTax || 0.0) as any}
                  decimalScale={2}
                  displayType={'text'}
                  fixedDecimalScale={true}
                  thousandSeparator={true}
                  prefix={'$'}
                />
              </Table.Cell>
              <Table.Cell width="four" textAlign="right">
                {this.props.isTaxRecalculating ? (
                  <Loader active inline="centered" size={'mini'} />
                ) : (
                  <NumberFormat
                    value={(this.props.salesTax || 0.0) as any}
                    decimalScale={2}
                    displayType={'text'}
                    fixedDecimalScale={true}
                    thousandSeparator={true}
                    prefix={'$'}
                  />
                )}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </form>
    );
  }
}
