import * as React from 'react';
import { Formik, Form as FormikForm, Field, FieldArray, ErrorMessage, FieldProps } from 'formik';
import { Button, Form, Message, Dropdown } from 'semantic-ui-react';
import { SemanticField } from '../../../common/Form';

import * as Yup from 'yup';

interface TestimonialFormProps {
  initialValues: any;
  errorMessage: string;
  onSubmit: (v: any) => void;
}

const ratingsOption = [];

for (let i: number = 1; i <= 5; i++) {
  ratingsOption.push({ text: i, value: i });
}

const { useState } = React;

export const TestimonialForm: React.FunctionComponent<TestimonialFormProps> = (props) => {
  const { initialValues, errorMessage, onSubmit } = props;

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values);
        }}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            setFieldValue,
            setFieldTouched,
          } = props;

          return (
            <FormikForm
              className={`ui form ${isSubmitting && !errorMessage && 'loading'}`}
              style={{ height: '100%' }}
            >
              <Form.Group widths="equal">
                <Form.Field>
                  <SemanticField fluid name="firstName" component={Form.Input} label="First Name" />
                </Form.Field>
                <Form.Field>
                  <SemanticField fluid name="lastName" component={Form.Input} label="Last Name" />
                </Form.Field>
              </Form.Group>
              <Form.Field>
                <SemanticField
                  fluid
                  name="isPublished"
                  component={Form.Checkbox}
                  label="Is Published"
                />
              </Form.Field>
              <Form.Field>
                <SemanticField fluid name="text" component={Form.TextArea} label="Text" />
              </Form.Field>
              <Form.Field>
                <label>Rating</label>
                <Field
                  name="rating"
                  render={({ field }: FieldProps<any>) => (
                    <Dropdown
                      search
                      selection
                      fluid
                      options={ratingsOption}
                      name={field.name}
                      onBlur={field.onBlur}
                      defaultValue={field?.value ?? ratingsOption[0].value}
                      onChange={(e, data) =>
                        field.onChange({ target: { value: data.value, name: field.name } } as any)
                      }
                    />
                  )}
                />
              </Form.Field>

              <Form.Field>
                <Button primary type="submit" disabled={isSubmitting && !errorMessage}>
                  Save
                </Button>
              </Form.Field>
            </FormikForm>
          );
        }}
      </Formik>

      {errorMessage && <Message error content={errorMessage} />}
    </>
  );
};
