import * as React from 'react';
import { Button, Form, Message } from 'semantic-ui-react';
import * as slugify from 'underscore.string/slugify';

import { VariantTypeDropdown } from './VariantType';
import { VariantValueDropdown } from './VariantValue';
import feathers from '../../../../../../bootstrap/feathers';

import * as Cleave from 'cleave.js/react';

import { useDebounce } from '@inkcloud/components';

const { useState, useEffect } = React;

export type Variant = { type: string; value: string | string[] };
export interface VariantsProps {
  value: Variant[];
  onChange: (value: Variant[]) => void;
  multipleValues?: boolean;
  isDisabled?: boolean;
}

const loadVariantTypes = async () => {
  return feathers.service('/products-legacy/variant-types').find({
    query: {
      $sort: { name: 1 },
      $np: 1,
    },
  }) as any;
};

const loadVariantValues = async () => {
  return feathers.service('/products-legacy/variant-type-values').find({
    query: {
      $sort: { name: 1 },
      $np: 1,
    },
  }) as any;
};

export const Variants = (props: VariantsProps) => {
  const { onChange, value = [], multipleValues = false, isDisabled = false } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [variantTypes, setVariantTypes] = useState([]);
  const [variantValues, setVariantValues] = useState([]);

  const initTypes = async () => {
    setIsLoading(true);
    const results = await loadVariantTypes();
    setIsLoading(false);
    setVariantTypes(
      results.map((r) => ({ value: r._id, text: r.name, valueSortBy: r?.valueSortBy ?? 'name' }))
    );
  };

  const initValues = async () => {
    setIsLoading(true);
    const results = await loadVariantValues();
    setIsLoading(false);
    setVariantValues(
      results.map((r) => ({ value: r._id, text: r.name, type: r.type, priority: r?.priority }))
    );
  };

  const handleAddVariantType = async (name) => {
    const type = (await feathers.service('/products-legacy/variant-types').create({
      name,
      key: slugify(name),
    })) as any;

    const addedTypes = props.value?.map((v) =>
      v.type === type.name ? { ...v, type: type._id } : v
    );

    props.onChange(addedTypes);
    initTypes();
  };

  const handleAddVariantValue = async (type: string, name: string) => {
    try {
      const value = (await feathers.service('/products-legacy/variant-type-values').create({
        type,
        name,
        key: slugify(name),
      })) as any;

      const addedValues = props.value?.map((v) =>
        v.type === value.type ? { ...v, value: [...v.value, value._id] } : v
      );

      props.onChange(addedValues);

      initValues();
    } catch (e) {}
  };

  const handleDelete = async (index) => {
    props.onChange(props.value.filter((v, i) => i !== index));
  };

  useEffect(() => {
    initTypes();
    initValues();
  }, []);

  // disabled the already selected types
  const filteredVariantTypes = variantTypes?.map((type) => {
    const found = value?.find((val) => val.type === type.value);

    if (found) {
      return { ...type, disabled: true };
    }

    return type;
  });

  return (
    <>
      {value.map((v, i) => {
        return (
          <Form.Group widths="equal" key={i}>
            <Form.Field>
              <VariantTypeDropdown
                options={filteredVariantTypes as any}
                loading={isLoading}
                value={v.type}
                onAddItem={handleAddVariantType}
                isDisabled={isDisabled}
                onChange={(val) =>
                  onChange(value.map((r, idx) => (i !== idx ? r : { ...v, type: val })))
                }
              />
            </Form.Field>
            <Form.Field>
              <VariantValueDropdown
                // variantType={v.type}
                variant={variantTypes?.find((type) => type.value === v.type)}
                options={variantValues}
                loading={isLoading}
                value={v.value}
                multiple={multipleValues}
                onAddItem={handleAddVariantValue}
                isDisabled={isDisabled}
                onChange={(val) =>
                  onChange(value.map((r, idx) => (i !== idx ? r : { ...v, value: val })))
                }
              />
            </Form.Field>

            {!isDisabled && (
              <Form.Field>
                <Button type="button" icon="delete" onClick={() => handleDelete(i)} />
              </Form.Field>
            )}
          </Form.Group>
        );
      })}
    </>
  );
};

export interface VariantPriceProps {
  data: any;
}

const serviceName = '/products-legacy';

export const VariantPrice: React.FunctionComponent<VariantPriceProps> = (props) => {
  const { data } = props;

  const [value, setValue] = useState(data?.price || 0);
  const [isLoading, setIsLoading] = useState(false);
  const [isChanging, setIsChanging] = useState(false);
  const debounceValue = useDebounce(value, 750);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const product = async () => {
      setIsLoading(true);
      setIsChanging(false);
      try {
        const newValue = {
          ...data,
          price: debounceValue,
        };

        const res: any = await feathers
          .service(serviceName)
          .patch(data._id, newValue, { query: {} });
      } catch (e) {
        e.code && e.code < 500
          ? setErrorMessage(e.message)
          : setErrorMessage('We are experiencing technical difficulties. Please try again');
      }

      setIsLoading(false);
    };

    if (isChanging) {
      product();
    }
  }, [debounceValue]);

  const handleChange = (price) => {
    setIsChanging(true);
    setValue(price);
  };

  return (
    <>
      <div className={`ui ${isLoading ? 'loading' : ''} icon input`}>
        <Cleave
          options={{
            numeral: true,
            rawValueTrimPrefix: true,
            numeralPositiveOnly: true,
            numeralThousandsGroupStyle: 'thousand',
            prefix: '$',
          }}
          value={value}
          onChange={(e) => handleChange(e.target.rawValue)}
        />
      </div>
      {errorMessage && <Message error content={errorMessage} />}
    </>
  );
};
