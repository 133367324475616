import * as React from 'react';
import { Button, Form, Message } from 'semantic-ui-react';
import { Formik, Form as FormikForm, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { SemanticField } from '../../../../common/Form';
import { icapi } from '../../../../bootstrap/feathers';

export interface WorkflowStationFormProps {
  initialValues: any;
  onSubmit: (v: any) => void;
  statusOptions: Array<{ text: string; value: string }>;
  vendorOptions: Array<{ text: string; value: string }>;
  errorMessage: string;
}

const WorkflowStationFormSchema = Yup.object().shape({
  transitionToStatus: Yup.string().required('Required'),
});

export const WorkflowStationForm: React.FunctionComponent<WorkflowStationFormProps> = (props) => {
  const { initialValues, onSubmit, statusOptions, vendorOptions, errorMessage } = props;

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={WorkflowStationFormSchema}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values);
        }}
      >
        {(innerProps) => {
          const {
            values,
            touched,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            setFieldValue,
            setFieldTouched,
          } = innerProps;

          return (
            <FormikForm className={`ui form ${isSubmitting && !errorMessage && 'loading'}`}>
              <Form.Group widths="equal">
                <SemanticField fluid name="name" label="Name" component={Form.Input} />
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field>
                  <SemanticField
                    fluid
                    selection
                    name="transitionToStatus"
                    label="Transition To Status"
                    component={Form.Dropdown}
                    options={statusOptions}
                  />
                  <ErrorMessage
                    render={(msg) => <Message negative size="tiny" content={msg} />}
                    name="transitionToStatus"
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field>
                  <SemanticField
                    fluid
                    name="isScanInAndOut"
                    label="Require Scan Out"
                    component={Form.Checkbox}
                  />
                </Form.Field>
              </Form.Group>
              {values?.isScanInAndOut && (
                <Form.Group widths="equal">
                  <Form.Field>
                    <SemanticField
                      fluid
                      selection
                      name="scanOutStatus"
                      label="Scan Out Status"
                      component={Form.Dropdown}
                      options={statusOptions}
                    />
                  </Form.Field>
                </Form.Group>
              )}
              <Form.Group widths="equal">
                <Form.Field>
                  <SemanticField
                    fluid
                    selection
                    name="notesToOperator"
                    label="Notes To Operator"
                    component={Form.TextArea}
                    options={statusOptions}
                  />
                </Form.Field>
              </Form.Group>

              <Form.Field>
                <SemanticField
                  fluid
                  selection
                  name="vendor"
                  label="Vendor"
                  clearable
                  component={Form.Dropdown}
                  options={vendorOptions}
                />
              </Form.Field>
              <Button primary type="submit" disabled={isSubmitting && !errorMessage}>
                Save
              </Button>
            </FormikForm>
          );
        }}
      </Formik>
      {errorMessage && <Message error content={errorMessage} />}
    </>
  );
};
