// import * as React from 'react'
// import {
//   Button,
//   Form,
//   Popup,
//   Dropdown,
//   Header,
//   Segment,
//   Message,
//   Modal,
//   Grid,
//   Table,
//   Radio,
// } from 'semantic-ui-react'
// import { Formik, Form as FormikForm, Field, FieldArray, FieldProps, ErrorMessage } from 'formik'
// import * as Yup from 'yup'
// import { SemanticField } from '../../../common/Form'
// import Feathers from './../../../bootstrap/feathers'
// import { slugify2 as slugifyKey } from '../../../common'

// interface MenuFormProps {
//   initialValues: any
//   onSubmit: Function
//   isSubmitting?: boolean
//   microsite?: string
//   pages: any[]
//   groups: any[]
//   errorMessage?: string
// }

// export const initialMapper = (values) => {
//   return {
//     ...values,
//     name: values?.name || '',
//     key: values?.key || '',
//     menuItems:
//       values?.menuItems?.map((m) => ({
//         ...m,
//         typeOfLink: m?.page ? 'page' : 'external',
//         menuItems: m?.menuItems?.map((sub) => ({
//           ...sub,
//           typeOfLink: sub?.page ? 'page' : 'external',
//         })),
//       })) || [],
//   }
// }

// export const submissionMapper = (values) => {
//   return {
//     ...values,
//     menuItems: values?.menuItems?.map((m) => ({
//       ...m,
//       key: m.label,
//       url: (m?.typeOfLink === 'external' && m?.url) || '',
//       page: (m?.typeOfLink === 'page' && m?.page) || null,
//       htmlTarget: m?.htmlTarget === '_blank' ? '_blank' : '_self',
//       menuItems: m.menuItems?.map((sub) => ({
//         ...sub,
//         key: sub.label,
//         htmlTarget: sub?.htmlTarget === '_blank' ? '_blank' : '_self',
//         url: (sub?.typeOfLink === 'external' && sub?.url) || '',
//         page: (sub?.typeOfLink === 'page' && sub?.page) || null,
//       })),
//     })),
//   }
// }

// const targetOptions = [
//   { text: 'Self', value: '_self' },
//   { text: 'Open in New Browser Tab/Window', value: '_blank' },
// ]

// const { useState } = React

// export const MenuForm: React.FC<MenuFormProps> = (outerProps) => {
//   const PageSchema = Yup.object().shape({
//     name: Yup.string().required('Required'),
//     key: Yup.string()
//       .required('Required')
//       .test('is-not-used', `Key is already in use`, function (value) {
//         if (
//           outerProps.initialValues &&
//           outerProps.initialValues._id &&
//           outerProps.initialValues.key === value
//         ) {
//           return Promise.resolve(true)
//         }

//         return Feathers.service<any>('/store-front/microsites/content/menus')
//           .find({ query: { microsite: outerProps.microsite, key: value } })
//           .then((results: any) => results?.data?.length === 0)
//       }),
//     menuItems: Yup.array().of(
//       Yup.object().shape({
//         label: Yup.string().required('Required'),
//         priority: Yup.string().required('Required'),
//         url: Yup.string(),
//         menuItems: Yup.array().of(
//           Yup.object().shape({
//             label: Yup.string().required('Required'),
//             priority: Yup.string().required('Required'),
//             url: Yup.string(),
//           })
//         ),
//       })
//     ),
//   })

//   const [isOpenModal, setIsOpenModal] = useState(false)
//   const [activeIndex, setActiveIndex] = useState(null)

//   return (
//     <Formik
//       initialValues={initialMapper(outerProps.initialValues)}
//       enableReinitialize={true}
//       validationSchema={PageSchema}
//       onSubmit={(values, { setSubmitting }) => {
//         outerProps.onSubmit(submissionMapper(values))
//       }}
//     >
//       {(props) => {
//         const {
//           values,
//           touched,
//           errors,
//           dirty,
//           isSubmitting,
//           handleChange,
//           handleBlur,
//           handleSubmit,
//           handleReset,
//           setFieldValue,
//           setFieldTouched,
//         } = props

//         const handleSlugify = (v) => setFieldValue('hrCode', slugifyKey(v))

//         const showNestedError = (subIndex: number, field: string) => {
//           const errorMessage = errors?.menuItems?.[activeIndex]?.menuItems?.[subIndex]?.[field]
//           return errorMessage
//         }

//         // const hasSubMenuError = errors?.menuItems?.[activeIndex]?.menuItems?.length > 0

//         const pageOptions = outerProps?.pages?.map((row) => {
//           const name = row.category
//             ? `Category(${row?.category?.name ?? ''})`
//             : row.product
//             ? `Product(${row?.product?.staticName ?? ''})`
//             : ''
//           return { value: row._id, text: `${name} - ${row.name}` }
//         })

//         return (
//           <FormikForm
//             className={`ui form ${isSubmitting && !outerProps.errorMessage && 'loading'}`}
//           >
//             <Form.Group widths="equal">
//               <Form.Field>
//                 <SemanticField
//                   name={'name'}
//                   label="Name"
//                   component={Form.Input}
//                   onChange={(v) => {
//                     if (!values?._id) {
//                       setFieldValue('key', slugifyKey(v))
//                     }
//                   }}
//                 />
//                 <ErrorMessage
//                   render={(msg) => <Message negative size="tiny" content={msg} />}
//                   name="name"
//                 />
//               </Form.Field>
//               <Form.Field>
//                 <SemanticField
//                   name={'key'}
//                   label="Key"
//                   component={Form.Input}
//                   onChange={handleSlugify}
//                 />
//                 <ErrorMessage
//                   render={(msg) => <Message negative size="tiny" content={msg} />}
//                   name="key"
//                 />
//               </Form.Field>
//             </Form.Group>

//             <FieldArray
//               name="menuItems"
//               render={({ move, swap, push, insert, unshift, pop, remove }) => (
//                 <React.Fragment>
//                   {values?.menuItems?.length > 0 && (
//                     <Header as="h5" attached="top">
//                       Menu Items
//                     </Header>
//                   )}
//                   {values?.menuItems?.map(({ label, page, priority }, index) => (
//                     <Segment
//                       className={`${
//                         index === values?.menuItems?.length - 1 ? 'bottom' : ''
//                       } attached`}
//                       key={index}
//                     >
//                       <Form.Group inline>
//                         <Form.Field>
//                           <label>Type of Link</label>
//                         </Form.Field>
//                         <Form.Field>
//                           <Form.Radio
//                             label="Page"
//                             name={`menuItems.[${index}].typeOfLink`}
//                             value="page"
//                             checked={
//                               values.menuItems?.[index]?.typeOfLink === 'page' ? true : false
//                             }
//                             onChange={(e, data) => {
//                               setFieldValue(data.name, data.value)
//                               setFieldValue(`menuItems[${index}].url`, '')
//                               setFieldValue(`menuItems[${index}].label`, '')
//                             }}
//                           />
//                         </Form.Field>
//                         <Form.Field>
//                           <Form.Radio
//                             label="External URL"
//                             name={`menuItems.[${index}].typeOfLink`}
//                             value="external"
//                             checked={
//                               values.menuItems?.[index]?.typeOfLink === 'external' ? true : false
//                             }
//                             onChange={(e, data) => {
//                               setFieldValue(data.name, data.value)
//                               setFieldValue(`menuItems[${index}].label`, '')
//                               setFieldValue(`menuItems[${index}].page`, '')
//                             }}
//                           />
//                         </Form.Field>
//                       </Form.Group>
//                       <Form.Group widths="equal">
//                         {values.menuItems?.[index]?.typeOfLink === 'page' && (
//                           <Form.Field>
//                             <label>Page</label>
//                             <SemanticField
//                               name={`menuItems[${index}].page`}
//                               component={Dropdown}
//                               options={pageOptions}
//                               fluid
//                               selection
//                               clearable
//                               label="Page"
//                               search
//                               onChange={(e) =>
//                                 setFieldValue(
//                                   `menuItems[${index}].label`,
//                                   (outerProps?.pages?.find((p) => p._id === e)?.name ||
//                                     outerProps?.pages?.find((p) => p._id === e)?.staticName) ??
//                                     ''
//                                 )
//                               }
//                             />
//                             <ErrorMessage
//                               render={(msg) => <Message negative size="tiny" content={msg} />}
//                               name={`menuItems[${index}].page`}
//                             />
//                           </Form.Field>
//                         )}
//                         <Form.Field>
//                           <label>Label</label>
//                           <SemanticField
//                             fluid
//                             name={`menuItems[${index}].label`}
//                             component={Form.Input}
//                           />
//                           <ErrorMessage
//                             render={(msg) => <Message negative size="tiny" content={msg} />}
//                             name={`menuItems[${index}].label`}
//                           />
//                         </Form.Field>

//                         {values.menuItems?.[index]?.typeOfLink === 'external' && (
//                           <Form.Field>
//                             <label>URL</label>
//                             <SemanticField
//                               fluid
//                               name={`menuItems[${index}].url`}
//                               component={Form.Input}
//                             />
//                           </Form.Field>
//                         )}

//                         <Form.Field>
//                           <label>Target</label>
//                           <Field
//                             name={`menuItems[${index}].htmlTarget`}
//                             render={({ field }: FieldProps<any>) => (
//                               <Dropdown
//                                 search
//                                 selection
//                                 clearable
//                                 fluid
//                                 options={targetOptions}
//                                 name={field.name}
//                                 onBlur={field.onBlur}
//                                 defaultValue={field?.value ?? targetOptions[0].value}
//                                 onChange={(e, data) =>
//                                   field.onChange({
//                                     target: { value: data.value, name: field.name },
//                                   } as any)
//                                 }
//                               />
//                             )}
//                           />
//                         </Form.Field>

//                         <Form.Field>
//                           <label>Priority</label>
//                           <SemanticField
//                             fluid
//                             name={`menuItems[${index}].priority`}
//                             component={Form.Input}
//                           />
//                           <ErrorMessage
//                             render={(msg) => <Message negative size="tiny" content={msg} />}
//                             name={`menuItems[${index}].priority`}
//                           />
//                         </Form.Field>
//                         <Form.Field>
//                           <label>Visibility</label>
//                           <SemanticField
//                             name={`menuItems[${index}].visibleToGroups`}
//                             component={Dropdown}
//                             options={outerProps.groups}
//                             fluid
//                             selection
//                             clearable
//                             multiple
//                             search
//                           />
//                           <ErrorMessage
//                             render={(msg) => <Message negative size="tiny" content={msg} />}
//                             name={`menuItems[${index}].visibleToGroups`}
//                           />
//                         </Form.Field>

//                         <div style={{ padding: '0 1em' }}>
//                           <label>Hidden</label>
//                           <div style={{ marginTop: '.35em' }}>
//                             <SemanticField
//                               fluid
//                               name={`menuItems[${index}].isHidden`}
//                               component={Form.Checkbox}
//                               toggle
//                             />
//                           </div>
//                         </div>

//                         {!values?.menuItems?.[index]?.menuItems?.length && (
//                           <div>
//                             <label>&nbsp;</label>
//                             <Popup
//                               content="Add sub menu items"
//                               position="top right"
//                               trigger={
//                                 <Button
//                                   icon="plus"
//                                   type="button"
//                                   style={{ marginTop: '.35rem' }}
//                                   onClick={() => {
//                                     setIsOpenModal(true)
//                                     setActiveIndex(index)
//                                   }}
//                                 />
//                               }
//                             />
//                           </div>
//                         )}

//                         {isOpenModal && activeIndex === index && (
//                           <Modal
//                             open={isOpenModal}
//                             size="large"
//                             // closeOnDimmerClick={!hasSubMenuError}
//                             // closeOnEscape={!hasSubMenuError}
//                             onClose={() => {
//                               // if (!hasSubMenuError) {
//                               //   setIsOpenModal(false)
//                               //   setActiveIndex(null)
//                               // }
//                               setIsOpenModal(false)
//                               setActiveIndex(null)
//                             }}
//                             closeIcon
//                           >
//                             <Modal.Header>Select options</Modal.Header>
//                             <Modal.Content>
//                               <FieldArray
//                                 name={`menuItems.${index}.menuItems`}
//                                 render={(arrayHelpers) => (
//                                   <div className="ui form">
//                                     {values?.menuItems?.[index]?.menuItems?.map(
//                                       (option: string, idx: number) => (
//                                         <div key={idx}>
//                                           <Form.Group inline>
//                                             <Form.Field>
//                                               <label>Type of Link</label>
//                                             </Form.Field>
//                                             <Form.Field>
//                                               <Radio
//                                                 label="Page"
//                                                 name={`menuItems[${index}].menuItems.${idx}.typeOfLink`}
//                                                 value="page"
//                                                 checked={
//                                                   values.menuItems?.[index]?.menuItems?.[idx]
//                                                     ?.typeOfLink === 'page'
//                                                 }
//                                                 onChange={(e, data) => {
//                                                   setFieldValue(data.name, data.value)
//                                                   setFieldValue(
//                                                     `menuItems[${index}].menuItems.${idx}.url`,
//                                                     ''
//                                                   )
//                                                   setFieldValue(
//                                                     `menuItems[${index}].menuItems.${idx}.label`,
//                                                     ''
//                                                   )
//                                                 }}
//                                               />
//                                             </Form.Field>
//                                             <Form.Field>
//                                               <Radio
//                                                 label="External URL"
//                                                 name={`menuItems[${index}].menuItems.${idx}.typeOfLink`}
//                                                 value="external"
//                                                 checked={
//                                                   values.menuItems?.[index]?.menuItems?.[idx]
//                                                     ?.typeOfLink === 'external'
//                                                 }
//                                                 onChange={(e, data) => {
//                                                   setFieldValue(data.name, data.value)
//                                                   setFieldValue(
//                                                     `menuItems[${index}].menuItems.${idx}.label`,
//                                                     ''
//                                                   )
//                                                   setFieldValue(
//                                                     `menuItems[${index}].menuItems.${idx}.page`,
//                                                     ''
//                                                   )
//                                                 }}
//                                               />
//                                             </Form.Field>
//                                           </Form.Group>
//                                           <Form.Group widths="equal" key={idx}>
//                                             {values.menuItems[index].menuItems?.[idx]
//                                               ?.typeOfLink === 'page' && (
//                                               <Form.Field>
//                                                 <label>Page</label>
//                                                 <SemanticField
//                                                   name={`menuItems[${index}].menuItems.${idx}.page`}
//                                                   component={Dropdown}
//                                                   options={pageOptions}
//                                                   fluid
//                                                   selection
//                                                   clearable
//                                                   label="Page"
//                                                   search
//                                                   onChange={(e) =>
//                                                     setFieldValue(
//                                                       `menuItems[${index}].menuItems.${idx}.label`,
//                                                       (outerProps?.pages?.find((p) => p._id === e)
//                                                         ?.name ||
//                                                         outerProps?.pages?.find((p) => p._id === e)
//                                                           ?.staticName) ??
//                                                         ''
//                                                     )
//                                                   }
//                                                 />
//                                               </Form.Field>
//                                             )}
//                                             <Form.Field>
//                                               <label>Label</label>
//                                               <SemanticField
//                                                 fluid
//                                                 name={`menuItems[${index}].menuItems.${idx}.label`}
//                                                 component={Form.Input}
//                                               />
//                                               {showNestedError(idx, 'label') && (
//                                                 <Message
//                                                   negative
//                                                   size="tiny"
//                                                   content={showNestedError(idx, 'label')}
//                                                 />
//                                               )}
//                                             </Form.Field>

//                                             {values.menuItems[index].menuItems?.[idx]
//                                               ?.typeOfLink === 'external' && (
//                                               <Form.Field>
//                                                 <label>URL</label>
//                                                 <SemanticField
//                                                   fluid
//                                                   name={`menuItems[${index}].menuItems.${idx}.url`}
//                                                   component={Form.Input}
//                                                 />
//                                               </Form.Field>
//                                             )}
//                                             <Form.Field>
//                                               <label>Target</label>
//                                               <Field
//                                                 name={`menuItems[${index}].menuItems.${idx}.htmlTarget`}
//                                                 render={({ field }: FieldProps<any>) => (
//                                                   <Dropdown
//                                                     search
//                                                     selection
//                                                     clearable
//                                                     fluid
//                                                     options={targetOptions}
//                                                     name={field.name}
//                                                     onBlur={field.onBlur}
//                                                     defaultValue={
//                                                       field?.value ?? targetOptions[0].value
//                                                     }
//                                                     onChange={(e, data) =>
//                                                       field.onChange({
//                                                         target: {
//                                                           value: data.value,
//                                                           name: field.name,
//                                                         },
//                                                       } as any)
//                                                     }
//                                                   />
//                                                 )}
//                                               />
//                                             </Form.Field>

//                                             <Form.Field>
//                                               <label>Priority</label>
//                                               <SemanticField
//                                                 fluid
//                                                 name={`menuItems[${index}].menuItems.${idx}.priority`}
//                                                 component={Form.Input}
//                                               />
//                                               {showNestedError(idx, 'priority') && (
//                                                 <Message
//                                                   negative
//                                                   size="tiny"
//                                                   content={showNestedError(idx, 'priority')}
//                                                 />
//                                               )}
//                                             </Form.Field>
//                                             <Form.Field>
//                                               <label>Visibility</label>
//                                               <SemanticField
//                                                 name={`menuItems[${index}].menuItems.${idx}.visibility`}
//                                                 component={Dropdown}
//                                                 options={outerProps.groups}
//                                                 fluid
//                                                 selection
//                                                 clearable
//                                                 multiple
//                                                 search
//                                               />
//                                             </Form.Field>

//                                             <div style={{ padding: '0 1em' }}>
//                                               <label>Hidden</label>
//                                               <div style={{ marginTop: '.35em' }}>
//                                                 <SemanticField
//                                                   name={`menuItems[${index}].menuItems.${idx}.isHidden`}
//                                                   component={Form.Checkbox}
//                                                   toggle
//                                                 />
//                                               </div>
//                                             </div>

//                                             <div>
//                                               <div className="field">
//                                                 <label>&nbsp;</label>
//                                                 <Button
//                                                   type="button"
//                                                   icon="remove"
//                                                   onClick={() => arrayHelpers.remove(idx)}
//                                                 />
//                                               </div>
//                                             </div>
//                                           </Form.Group>
//                                         </div>
//                                       )
//                                     )}
//                                     <Button
//                                       type="button"
//                                       size="tiny"
//                                       onClick={() =>
//                                         arrayHelpers.push({
//                                           label: '',
//                                           priority: '',
//                                           typeOfLink: 'page',
//                                           page: '',
//                                         })
//                                       }
//                                       content="Add Item"
//                                     />
//                                   </div>
//                                 )}
//                               />
//                             </Modal.Content>
//                             <Modal.Actions>
//                               <Button
//                                 type="button"
//                                 size="tiny"
//                                 content="Done"
//                                 // disabled={hasSubMenuError}
//                                 onClick={() => {
//                                   setIsOpenModal(false)
//                                   setActiveIndex(null)
//                                 }}
//                               />
//                             </Modal.Actions>
//                           </Modal>
//                         )}

//                         <div>
//                           <label>&nbsp;</label>
//                           <Button icon="remove" type="button" onClick={() => remove(index)} />
//                         </div>
//                       </Form.Group>
//                       {values?.menuItems?.[index]?.menuItems?.length > 0 && (
//                         <>
//                           <Segment.Group size="tiny">
//                             <Segment>
//                               <Grid
//                                 columns={2}
//                                 padded="vertically"
//                                 style={{ alignItems: 'center' }}
//                               >
//                                 <Grid.Column style={{ paddingTop: 0, paddingBottom: 0 }}>
//                                   <strong>Sub Menu Items</strong>
//                                 </Grid.Column>
//                                 <Grid.Column
//                                   textAlign="right"
//                                   style={{ paddingTop: 0, paddingBottom: 0 }}
//                                 >
//                                   <Popup
//                                     content="Edit sub menu items"
//                                     position="top right"
//                                     trigger={
//                                       <Button
//                                         icon="edit"
//                                         type="button"
//                                         size="tiny"
//                                         onClick={() => {
//                                           setIsOpenModal(true)
//                                           setActiveIndex(index)
//                                         }}
//                                       />
//                                     }
//                                   />
//                                 </Grid.Column>
//                               </Grid>
//                             </Segment>
//                             <Segment secondary>
//                               <Table>
//                                 <Table.Header>
//                                   <Table.Row>
//                                     <Table.HeaderCell>Label</Table.HeaderCell>
//                                     <Table.HeaderCell>Hidden</Table.HeaderCell>
//                                     <Table.HeaderCell>Priority</Table.HeaderCell>
//                                   </Table.Row>
//                                 </Table.Header>
//                                 <Table.Body>
//                                   {values.menuItems[index].menuItems.map((mi) => (
//                                     <Table.Row key={mi._id}>
//                                       <Table.Cell>{mi.label}</Table.Cell>
//                                       <Table.Cell>
//                                         {mi.isHidden ? 'It is hidden' : 'Not hidden'}
//                                       </Table.Cell>
//                                       <Table.Cell>{mi.priority}</Table.Cell>
//                                     </Table.Row>
//                                   ))}
//                                 </Table.Body>
//                               </Table>
//                             </Segment>
//                           </Segment.Group>
//                         </>
//                       )}
//                     </Segment>
//                   ))}

//                   <Button
//                     type="button"
//                     content="Add menu item"
//                     size="tiny"
//                     onClick={() => {
//                       push({
//                         label: '',
//                         page: '',
//                         priority: '',
//                         typeOfLink: 'page',
//                       })
//                     }}
//                   />
//                 </React.Fragment>
//               )}
//             />
//             <br />
//             <br />
//             <Button primary type="submit" disabled={isSubmitting && !outerProps.errorMessage}>
//               Save
//             </Button>
//           </FormikForm>
//         )
//       }}
//     </Formik>
//   )
// }

import React, { useState, useMemo, useEffect } from 'react';
import {
  Box,
  Heading,
  Input,
  RadioGroup,
  Radio,
  Switch,
  Stack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  IconButton,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Alert,
} from '@chakra-ui/react';
import { useForm, useFieldArray, useWatch, Control } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import * as Yup from 'yup';

import { Select } from 'chakra-react-select';

import { RiCloseFill, RiAddFill, RiEditBoxLine } from 'react-icons/ri';
import type { ModelTypes } from '@inkcloud/icapi-types';

import Feathers from '../../../bootstrap/feathers';
import { slugify2 as slugifyKey } from '../../../common';

import SubMenuForm from './SubMenuForm';

interface MenuFormProps {
  initialValues: NonNullable<ModelTypes.ContentMenu> & {
    menuItems: ModelTypes.ContentMenu['menuItems'] &
      { visibleToGroups?: string[]; typeOfLink: string }[];
  };
  // initialValues: any
  onSubmit: (values: any) => void;
  isSubmitting?: boolean;
  microsite?: string;
  pages: any[];
  groups: any[];
  errorMessage?: string;
}

export const initialMapper = (
  values: NonNullable<
    ModelTypes.ContentMenu & {
      menuItems: ModelTypes.ContentMenu['menuItems'] &
        { visibleToGroups?: string[]; typeOfLink: string }[];
    }
  >
) => ({
  ...values,
  name: values?.name || '',
  key: values?.key || '',
  menuItems:
    values?.menuItems?.map((m) => ({
      ...m,
      typeOfLink: m?.page ? 'page' : 'external',
      menuItems: m?.menuItems?.map((sub) => ({
        ...sub,
        typeOfLink: sub?.page ? 'page' : 'external',
      })),
    })) || [],
});

export const submissionMapper = (values) => ({
  ...values,
  menuItems: values?.menuItems?.map((m) => ({
    ...m,
    key: m.label,
    url: (m?.typeOfLink === 'external' && m?.url) || '',
    page: (m?.typeOfLink === 'page' && m?.page) || null,
    htmlTarget: m?.htmlTarget === '_blank' ? '_blank' : '_self',
    menuItems: m.menuItems?.map((sub) => ({
      ...sub,
      key: sub.label,
      htmlTarget: sub?.htmlTarget === '_blank' ? '_blank' : '_self',
      url: (sub?.typeOfLink === 'external' && sub?.url) || '',
      page: (sub?.typeOfLink === 'page' && sub?.page) || null,
    })),
  })),
});

const targetOptions = [
  { label: 'Self', value: '_self' },
  { label: 'Open in New Browser Tab/Window', value: '_blank' },
];

const validateKey = async (value: string, initialValues: any, microsite: string) => {
  if (initialValues?.key === value) {
    return true;
  }

  return Feathers.service<any>(`/store-front/microsites/content/menus`)
    .find({ query: { microsite, key: value } })
    .then((results: any) => results.data?.length === 0)
    .catch((e) => {
      const errMsg =
        e?.code < 500 ? e.message : 'We are experiencing technical difficulties. Please try again';

      return {
        values: {
          key: value,
        },
        errors: e.inner.reduce(
          (allErrors, currentError) => ({
            ...allErrors,
            [currentError.path]: {
              type: currentError.type ?? 'validation',
              message: currentError.message,
            },
          }),
          {}
        ),
      };
    });
};

export const MenuForm = (props: MenuFormProps) => {
  const {
    initialValues,
    onSubmit,
    isSubmitting,
    microsite = '',
    pages,
    groups,
    errorMessage,
  } = props;

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [nestedIndex, setNestedIndex] = useState<number | null>(null);

  const schema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string().required('Required'),
        key: Yup.string()
          .required('Required')
          .test('is-not-used', `Key is already in use`, async (value) => {
            const t = await validateKey(value, initialValues, microsite);

            return t as boolean;
          }),
        // .test('is-not-used', `Key is already in use`, async (value: string) {
        //   if (initialValues?._id && initialValues?.key === value) {
        //     return Promise.resolve(true)
        //   }

        //   return Feathers.service<ModelTypes.ContentMenu>('/store-front/microsites/content/menus')
        //     .find({ query: { microsite, key: value } })
        //     .then((results: any) => results?.data?.length === 0)
        // }),
        menuItems: Yup.array().of(
          Yup.object().shape({
            label: Yup.string().required('Required'),
            priority: Yup.string().required('Required'),
            url: Yup.string(),
            menuItems: Yup.array().of(
              Yup.object().shape({
                label: Yup.string().required('Required'),
                priority: Yup.string().required('Required'),
                url: Yup.string(),
              })
            ),
          })
        ),
      }),
    []
  );

  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
    watch,
    trigger,
    reset,
  } = useForm({
    defaultValues: initialMapper(initialValues),
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset(initialValues);
  }, [groups, pages]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'menuItems' as any,
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box p={3} bg="white">
        <Stack direction="row" spacing={7} mb={3}>
          <FormControl isInvalid={!!errors.name}>
            <FormLabel>Name</FormLabel>
            <Input
              {...register('name')}
              onChange={(e) => {
                setValue('name', e.target.value);
                setValue('key', slugifyKey(e.target.value));
              }}
            />
            {errors?.name && <FormErrorMessage>{errors?.name?.message}</FormErrorMessage>}
          </FormControl>
          <FormControl isInvalid={!!errors.key}>
            <FormLabel>Key</FormLabel>
            <Input {...register('key')} />
            {errors?.key && <FormErrorMessage>{errors?.key?.message}</FormErrorMessage>}
          </FormControl>
        </Stack>
        <Heading as="h4" size="xs" mb={3}>
          Menu Items
        </Heading>
        {fields.map((item, index) => {
          const typeOfLink =
            watch(`menuItems.${index}.typeOfLink`) === 'external' ? 'external' : 'page';

          const watchVisibleToGroups = watch(`menuItems.${index}.visibleToGroups` as any) || [];

          const hasSubMenu = watch(`menuItems.${index}.menuItems`)?.length > 0;

          const subMenuItems = watch(`menuItems.${index}.menuItems`) || [];

          const err = errors?.menuItems?.[index];

          return (
            <Box
              key={item.id}
              p={3}
              mb={3}
              border="1px solid var(--chakra-colors-gray-200)"
              borderRadius="md"
            >
              <FormControl as="fieldset">
                <RadioGroup defaultValue={typeOfLink}>
                  <Stack direction="row" spacing={7} mb={3}>
                    <Heading as="h4" size="xs" mt={1}>
                      Type of Link
                    </Heading>
                    <Radio
                      value="page"
                      onChange={(e) => setValue(`menuItems.${index}.typeOfLink`, e.target.value)}
                    >
                      Page
                    </Radio>
                    <Radio
                      value="external"
                      onChange={(e) => {
                        setValue(`menuItems.${index}.typeOfLink`, e.target.value);
                        setValue(`menuItems.${index}.page`, undefined);
                      }}
                    >
                      Link
                    </Radio>
                  </Stack>
                </RadioGroup>
              </FormControl>
              <Stack direction="row" spacing={7} mb={3}>
                {typeOfLink === 'external' ? (
                  <>
                    <FormControl isInvalid={!!err?.label}>
                      <FormLabel>Label</FormLabel>
                      <Input {...register(`menuItems.${index}.label`)} />
                      {err?.label && <FormErrorMessage>{err?.label?.message}</FormErrorMessage>}
                    </FormControl>
                    <FormControl>
                      <FormLabel>URL</FormLabel>
                      <Input {...register(`menuItems.${index}.url`)} />
                      {err?.url && <FormErrorMessage>{err?.url?.message}</FormErrorMessage>}
                    </FormControl>
                  </>
                ) : (
                  <>
                    <FormControl>
                      <FormLabel>Page</FormLabel>
                      <Select
                        size="sm"
                        options={pages}
                        name={`menuItems.${index}.page`}
                        onChange={(e) => {
                          setValue(`menuItems.${index}.page`, e.value);
                          setValue(`menuItems.${index}.label`, e.label);
                        }}
                        value={pages.find((o) => o.value === watch(`menuItems.${index}.page`))}
                      />
                    </FormControl>
                    <FormControl isInvalid={!!err?.label}>
                      <FormLabel>Label</FormLabel>
                      <Input {...register(`menuItems.${index}.label`)} />
                      {err?.label && <FormErrorMessage>{err?.label?.message}</FormErrorMessage>}
                    </FormControl>
                  </>
                )}

                <FormControl>
                  <FormLabel>Target</FormLabel>
                  <Select
                    size="sm"
                    options={targetOptions}
                    onChange={(e) => setValue(`menuItems.${index}.htmlTarget`, e?.value)}
                    value={targetOptions.find(
                      (o) => o.value === watch(`menuItems.${index}.htmlTarget`)
                    )}
                  />
                </FormControl>
                <FormControl isInvalid={!!err?.priority}>
                  <FormLabel>Priority</FormLabel>
                  <Input {...register(`menuItems.${index}.priority`)} />
                  {err?.priority && <FormErrorMessage>{err?.priority?.message}</FormErrorMessage>}
                </FormControl>

                <FormControl>
                  <FormLabel>Visibility</FormLabel>
                  <Select
                    size="sm"
                    isMulti
                    options={groups}
                    onChange={(e) => setValue(`menuItems.${index}.visibleToGroups` as any, e)}
                    defaultValue={watchVisibleToGroups?.map((v) =>
                      groups.find((g) => g.value === v)
                    )}
                  />
                </FormControl>

                <FormControl flex={1}>
                  <FormLabel>Hidden</FormLabel>
                  <Switch {...register(`menuItems.${index}.isHidden`)} />
                </FormControl>

                {!hasSubMenu && (
                  <FormControl flex={1}>
                    <FormLabel>&nbsp;</FormLabel>
                    <IconButton
                      type="button"
                      variant="outline"
                      aria-label="add sub menu"
                      onClick={() => {
                        setIsMenuOpen(!isMenuOpen);
                        setNestedIndex(index);
                      }}
                      icon={<RiAddFill />}
                    />
                  </FormControl>
                )}
                <FormControl flex={1}>
                  <FormLabel>&nbsp;</FormLabel>
                  <IconButton
                    type="button"
                    variant="outline"
                    aria-label="remove field"
                    onClick={() => remove(index)}
                    icon={<RiCloseFill />}
                  />
                </FormControl>
              </Stack>

              {subMenuItems?.length > 0 && (
                <Box
                  p={3}
                  mb={3}
                  border="1px solid var(--chakra-colors-gray-200)"
                  borderRadius="md"
                >
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={7}
                    mb={3}
                    pb={3}
                    borderBottom="1px solid var(--chakra-colors-gray-200)"
                  >
                    <Heading as="h4" size="xs">
                      Sub Menu Items
                    </Heading>
                    <IconButton
                      type="button"
                      aria-label="edit sub menu"
                      onClick={() => {
                        if (isValid) {
                          setIsMenuOpen(!isMenuOpen);
                          setNestedIndex(index);
                        } else {
                          trigger();
                        }
                      }}
                      icon={<RiEditBoxLine />}
                    />
                  </Stack>
                  <Stack direction="row" justifyContent="space-between" spacing={7} mb={3}>
                    <Heading as="h5" size="xs" w="50%" mb={3}>
                      Label
                    </Heading>
                    <Heading as="h5" size="xs" w="25%" mb={3}>
                      Hidden
                    </Heading>
                    <Heading as="h5" size="xs" w="25%" mb={3}>
                      Priority
                    </Heading>
                  </Stack>
                  {subMenuItems?.map((subMenuItem, subIndex) => (
                    <Stack
                      key={subIndex}
                      direction="row"
                      justifyContent="space-between"
                      spacing={7}
                      mb={3}
                    >
                      <Box w="50%">{subMenuItem.label}</Box>
                      <Box w="25%">{subMenuItem.isHidden ? 'It is hidden' : 'Not hidden'}</Box>
                      <Box w="25%">{subMenuItem.priority}</Box>
                    </Stack>
                  ))}
                </Box>
              )}

              {isMenuOpen && nestedIndex === index && (
                <Modal size="6xl" isOpen={isMenuOpen} onClose={() => {}}>
                  <ModalOverlay />
                  <ModalContent>
                    <ModalHeader>Sub Menu Item</ModalHeader>
                    <ModalBody>
                      <SubMenuForm
                        nestIndex={index}
                        {...{ control, register, watch, setValue, pages, groups, errors, trigger }}
                      />
                    </ModalBody>

                    <ModalFooter>
                      <Button
                        colorScheme="blue"
                        onClick={async () => {
                          const check = await trigger();
                          setIsMenuOpen(!check);
                        }}
                      >
                        Done
                      </Button>
                    </ModalFooter>
                  </ModalContent>
                </Modal>
              )}
            </Box>
          );
        })}
        <Box mb={3}>
          <Button type="button" variant="outline" onClick={() => append({ htmlTarget: '_self' })}>
            Add menu item
          </Button>
        </Box>

        {errorMessage && (
          <Alert status="error" mb={3}>
            {errorMessage}
          </Alert>
        )}

        <Button isLoading={isSubmitting} isDisabled={isSubmitting} type="submit" colorScheme="blue">
          Save
        </Button>
      </Box>
    </form>
  );
};
