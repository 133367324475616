import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { PageHeaderRow, To } from '@inkcloud/components';
import Feathers from './../../../bootstrap/feathers';

import { Heading } from './../Heading';

import { Button } from 'semantic-ui-react';

import { ApprovalEmailForm } from './ApprovalEmailForm';

interface DetailProps {}

const { useState, useEffect } = React;

export const Detail: React.FunctionComponent<
  DetailProps & RouteComponentProps<{ micrositeId?: string }>
> = (props) => {
  const {
    match: {
      params: { micrositeId },
    },
    history,
  } = props;

  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState({ customers: '', settings: '', microsites: '' });
  const [setting, setSetting] = useState(null);

  useEffect(() => {
    const loadUsers = async () => {
      const [error, res] = await To(
        Feathers.service('microsites/customers').find({
          query: { isAdmin: true, microsite: micrositeId },
        })
      );

      if (!error) {
        setUsers(res.data);
      } else {
        setErrorMessage({ ...errorMessage, customers: 'Problem getting the users.' });
      }
    };

    const loadSetting = async () => {
      setIsLoading(true);
      const [error, res] = await To(
        Feathers.service('/microsites').get(micrositeId, {
          query: {
            $select: ['settings.orderApprovalNotificationRecipients'],
          },
        })
      );
      setIsLoading(false);
      if (!error) {
        setSetting(res.settings.orderApprovalNotificationRecipients);
      } else {
        setErrorMessage({ ...errorMessage, microsites: error.message });
      }
    };

    loadSetting();
    loadUsers();
  }, []);

  const handleSubmit = async (values: any) => {
    setIsLoading(true);
    const [error, res] = await To(
      Feathers.service('/microsites/order-approval-recipient-settings').create(
        {
          micrositeId,
          ...values,
        },
        { query: {} }
      )
    );

    if (!error) {
      setIsLoading(false);
      setErrorMessage({ ...errorMessage, settings: '' });
      history.goBack();
    } else {
      setIsLoading(false);
      setErrorMessage({ ...errorMessage, settings: error.message });
    }
  };

  return (
    <>
      <PageHeaderRow header={`Approval`} subheader={`${setting ? 'Edit' : 'New'} Email`}>
        <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
      </PageHeaderRow>

      <Heading id={micrositeId} />
      <ApprovalEmailForm
        initialValue={setting}
        errorMessage={errorMessage}
        users={users}
        isLoading={isLoading}
        onSubmit={handleSubmit}
      />
    </>
  );
};
