import * as React from 'react';
import {
  Box,
  Text,
  Button,
  ButtonGroup,
  Checkbox,
  HStack,
  Heading,
  Input,
  Flex,
  IconButton,
} from '@chakra-ui/react';
import { PlusSquareIcon, CloseIcon } from '@chakra-ui/icons';
import { Divider } from 'semantic-ui-react';

import { AsyncSelect } from 'chakra-react-select';
import type { Option } from 'react-select';
import DateRangePicker from '../../../prototypes/common/form/DateRangePicker';
import { Feathers } from '../../../bootstrap/feathers/feathers';

interface IReportInputsProps {
  // startDate: Date;
  // endDate: Date;
  filters: any;
  splits?: string[];
  onChangeFilter: (filters: any) => void;
  onResetFilter: () => void;
  onChange: (splits: string[]) => void;
}

const filterOptions = [
  { name: 'period', label: 'Time' },
  { name: 'user', label: 'User' },
  { name: 'status', label: 'Status' },
];
async function loadUsers(e: string, callback) {
  const res = await Feathers.service('users').find({
    query: {
      $select: ['firstName', 'lastName'],
      $sort: { firstName: 1 },
      $or: [{ firstName: { $LIKE: e } }, { lastName: { $LIKE: e } }],
    },
  });

  return callback(
    res?.data?.map((r) => ({
      label: `${r?.firstName ?? ''} ${r?.lastName ?? ''}`,
      value: r._id,
    }))
  );
}

async function loadStatuses(e: string, callback) {
  const res = await Feathers.service('order-item-statuses').find({
    query: {
      $select: ['name'],
      $sort: { name: 1 },
      name: { $LIKE: e },
    },
  });

  return callback(
    res?.data?.map((r) => ({
      label: r?.name,
      value: r._id,
    }))
  );
}
const ReportInputs: React.FunctionComponent<IReportInputsProps> = (props) => {
  const { filters, splits, onResetFilter, onChangeFilter, onChange } = props;
  const [dateRange, setDateRange] = React.useState({ startDate: new Date(), endDate: new Date() });

  // Make it more convenient to know if a split is selected or not
  const currentSplits = splits?.reduce((acc, s) => ({ ...acc, [s]: true }), {} as any) ?? {};

  // Helper render button color if split is selected
  const buttonColor = (split: string) => (currentSplits[split] ? 'blue' : undefined);

  // handle click and dispatch onChange prop
  const handleSplitChange = (split: string) => {
    const updatedPayload = { ...currentSplits, [split]: !currentSplits[split] };

    // Time Splits are mutually exclusive. You cannot pick mor than one at a time.
    if (split.startsWith('time')) {
      Object.keys(updatedPayload)
        .filter((k) => k !== split && k.startsWith('time'))
        .forEach((k) => {
          updatedPayload[k] = false;
        });
    }
    onChange(updatedPayload);
  };
  return (
    <Box>
      <Box>
        <Flex mb={2} alignItems="center" justifyContent="space-between">
          <Heading size={'sm'}>Filters</Heading>
          <IconButton
            aria-label="clear splits"
            icon={<CloseIcon />}
            onClick={() => onResetFilter()}
          />
        </Flex>
        <ButtonGroup spacing={2} variant={'outline'} mb={3}>
          {filterOptions.map((item) => (
            <Button
              key={item.name}
              colorScheme={filters?.[item.name]?.isActive ? 'blue' : undefined}
              leftIcon={<PlusSquareIcon />}
              onClick={() => {
                const { isActive = false, value } = filters?.[item.name] || {};

                onChangeFilter({
                  [item.name]: {
                    start: item.name === 'period' ? filters?.period?.start : undefined,
                    end: item.name === 'period' ? filters?.period?.end : undefined,
                    value: isActive ? value : undefined,
                    isActive: !isActive,
                  },
                });
              }}
            >
              {item.label}
            </Button>
          ))}
        </ButtonGroup>
        {filterOptions.map((item) => {
          if (filters?.[item.name]?.isActive) {
            if (item.name === 'period') {
              return (
                <Box mb={3}>
                  <DateRangePicker
                    size="var(--chakra-sizes-8)"
                    startDate={filters?.period.start}
                    endDate={filters?.period?.end}
                    onChange={() => null}
                    onChangeRange={(value) => {
                      const { startDate, endDate } = value;

                      onChangeFilter({
                        period: {
                          ...filters?.period,
                          start: startDate,
                          end: endDate,
                        },
                      });
                    }}
                    // startDate={dateRange.startDate}
                    // endDate={dateRange.endDate}
                    // onChange={(date, key: string) => {
                    //   console.log('dateRange', { ...dateRange, [key]: date });

                    //   onChangeFilter({ ...dateRange, [key]: date });
                    //   setDateRange((v) => ({ ...v, [key]: date }));
                    // }}
                    labelSize="sm"
                  />{' '}
                </Box>
              );
            }
            return (
              <Box mb={3} key={item.name}>
                <Text mb={2} fontSize="sm">
                  <Text as="strong">{item.label}</Text>
                </Text>
                <AsyncSelect
                  useBasicStyles
                  isClearable
                  loadOptions={item.name === 'status' ? loadStatuses : loadUsers}
                  placeholder={`Select a ${item.name === 'status' ? 'status' : 'user'}`}
                  onChange={(e: Option) =>
                    onChangeFilter({ [item.name]: { ...filters?.[item.name], value: e } })
                  }
                />
              </Box>
            );
          }
          return null;
        })}
      </Box>
      <Divider />
      <Box>
        <Flex mb={2} alignItems="center" justifyContent="space-between">
          <Heading size={'sm'}>Splits</Heading>
          <IconButton aria-label="clear splits" icon={<CloseIcon />} onClick={() => onChange([])} />
        </Flex>
        <ButtonGroup spacing={2} variant={'outline'}>
          <Button
            colorScheme={buttonColor('time-day')}
            leftIcon={<PlusSquareIcon />}
            onClick={() => handleSplitChange('time-day')}
          >
            Time - Day
          </Button>
          <Button
            colorScheme={buttonColor('time-week')}
            leftIcon={<PlusSquareIcon />}
            onClick={() => handleSplitChange('time-week')}
          >
            Time - Week
          </Button>
          <Button
            colorScheme={buttonColor('time-month')}
            leftIcon={<PlusSquareIcon />}
            onClick={() => handleSplitChange('time-month')}
          >
            Time - Month
          </Button>
          <Button
            colorScheme={buttonColor('time-quarter')}
            leftIcon={<PlusSquareIcon />}
            onClick={() => handleSplitChange('time-quarter')}
          >
            Time - Quarter
          </Button>
          <Button
            colorScheme={buttonColor('time-year')}
            leftIcon={<PlusSquareIcon />}
            onClick={() => handleSplitChange('time-year')}
          >
            Time - Year
          </Button>

          <Button
            colorScheme={buttonColor('status')}
            leftIcon={<PlusSquareIcon />}
            onClick={() => handleSplitChange('status')}
          >
            Status
          </Button>
          <Button
            colorScheme={buttonColor('user')}
            leftIcon={<PlusSquareIcon />}
            onClick={() => handleSplitChange('user')}
          >
            User
          </Button>
        </ButtonGroup>
      </Box>
    </Box>
  );
};

export default ReportInputs;
