import * as React from 'react';
import { Form, Message } from 'semantic-ui-react';
import Feathers from '../../../bootstrap/feathers';

interface IPromoCodeProps {
  cart: any;
  onSetCart: (cart: any) => void;
}

const { useState } = React;

export const PromoCode: React.FunctionComponent<IPromoCodeProps> = (props) => {
  const { cart, onSetCart } = props;
  const [promoCode, setPromoCode] = useState(cart?.appliedPromoCode ?? '');
  const [errorMessage, setErrorMessage] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    setErrorMessage('');
    setResponseMessage('');

    try {
      const res: any = await Feathers.service('/cart/set-promo-code').create(
        { cartId: cart._id, promoCode },
        { query: {} }
      );

      onSetCart(res);

      setResponseMessage('Successfully applied the Promo Code.');
    } catch (e) {
      const errMsg =
        e?.code < 500 ? e.message : 'We are experiencing technical difficulties. Please try again';
      setErrorMessage(errMsg);
    }

    setIsSubmitting(false);
  };

  return (
    <>
      <Form loading={isSubmitting}>
        <Form.Input name="po" value={promoCode} onChange={(e, data) => setPromoCode(data.value)} />
        <Form.Button
          primary
          size="mini"
          disabled={!promoCode?.trim()}
          onClick={handleSubmit}
          content="Submit"
        />
      </Form>
      {errorMessage && <Message size="tiny" error content={errorMessage} />}
      {responseMessage && <Message size="tiny" success content={responseMessage} />}
    </>
  );
};
