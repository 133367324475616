import * as React from 'react';
import { Input } from '@chakra-ui/react';
import { Table } from 'semantic-ui-react';

interface PartialShipOutProps {
  pendingItems: any[];
  onChange: (pendingItems: any[]) => void;
}

export const PartialShipOut: React.FunctionComponent<PartialShipOutProps> = (props) => {
  const filteredItems = props?.pendingItems?.filter(
    (item) => item.orderItem?.productionStatus?.jobStatus !== 'VOID'
  );

  const isAllSelected = filteredItems?.every((item) => item.selected);
  return (
    <React.Fragment>
      <Table size={'small'}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              <input
                type="checkbox"
                checked={isAllSelected}
                onChange={(e) => {
                  const updatedItems = filteredItems?.map((pi) => ({
                    ...pi,
                    selected: e.target.checked,
                  }));

                  props.onChange(updatedItems);
                }}
              />
            </Table.HeaderCell>
            <Table.HeaderCell>ID</Table.HeaderCell>
            <Table.HeaderCell>Description</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>Quantity</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {filteredItems?.map((item, idx) => (
            <Table.Row key={idx}>
              <Table.Cell>
                <input
                  type="checkbox"
                  checked={item?.selected}
                  onChange={(e) => {
                    const updatedItems = props?.pendingItems?.map((pi) => ({
                      ...pi,
                      selected:
                        pi?.orderItem?._id === item?.orderItem?._id
                          ? e.target.checked
                          : pi?.selected,
                    }));
                    props.onChange(updatedItems);
                  }}
                />
              </Table.Cell>
              <Table.Cell>{item?.orderItem?.humanId}</Table.Cell>
              <Table.Cell>
                <div>{item?.orderItem?.staticName}</div>
                <div style={{ whiteSpace: 'pre-wrap' }}>{item?.orderItem?.description}</div>
              </Table.Cell>
              <Table.Cell>{item?.orderItem?.productionStatus?.description}</Table.Cell>
              <Table.Cell>
                <Input
                  type="text"
                  value={item?.shippingQuantity}
                  placeholder={'Quantity Shipped...'}
                  onChange={(e) => {
                    const updatedItems = props?.pendingItems?.map((pi) => {
                      let shippingQuantity;
                      if (pi?.orderItem?._id === item?.orderItem?._id) {
                        shippingQuantity =
                          Number(e.target.value) <= pi?.orderItem?.quantity
                            ? e.target.value
                            : pi?.orderItem?.quantity;
                      }
                      return {
                        ...pi,
                        shippingQuantity,
                      };
                    });
                    props.onChange(updatedItems);
                  }}
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </React.Fragment>
  );
};
