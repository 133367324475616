import * as React from 'react';
import { Switch, Route } from 'react-router-dom';

import UpdateTurnaround from './update-turnaround';

const routes = (props) => (
  <Switch>
    <Route exact path="/orders/items/:id/update-turnaround" component={UpdateTurnaround} />
  </Switch>
);

export default routes;
