import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Segment, Input, Form } from 'semantic-ui-react';
import { PageHeaderRow, PreLoader, useFeathers } from '@inkcloud/components';
// import brace from "brace";
// import AceEditor from "react-ace";
// import "brace/mode/html";
// import "brace/theme/monokai";
import feathers from '../../../bootstrap/feathers';

import { ContentEmailForm } from './../../microsites/content-email-templates/ContentEmailForm';

interface DetailProps {}

const { useState, useEffect } = React;

const serviceName = '/notification/template';
const service = feathers.service(serviceName);
const commonParams = { query: {} };

export const Detail: React.FunctionComponent<
  DetailProps & RouteComponentProps<{ id?: string; key?: string }>
> = (props) => {
  const {
    match: {
      params: { id, key },
    },
    location,
    history,
  } = props;
  const isEdit = !!id;
  // const [state, reload] = useFeathers('get', serviceName, [id, commonParams], true);
  const [content, setContent] = useState({ isMjml: false, subject: '', template: '', key: '' });
  const [isSubmitting, setIsSubmitting] = useState(false);
  // const [tenantId, setTenantId] = useState('')
  const [errorMessage, setErrorMessage] = useState('');
  const [errorTemplateMessage, setTemplateErrorMessage] = useState('');

  // const [keys, setKeys] = useState([])
  // const [keyList, setKeyList] = useState([])

  // useEffect(() => {
  //   state && state.data && setContent({ ...content, ...state.data })
  // }, [state])

  const pathPrefix = `/settings/notification-templates`;

  useEffect(() => {
    const loadDefaultTemplate = async () => {
      setTemplateErrorMessage('');
      try {
        const result: any = await feathers.service('/notification/default-template').find({
          query: {
            key,
          },
        });
        setContent({
          ...content,
          template: result.defaultTemplate,
          subject: result.subject,
          key: result.key,
        });
      } catch (e) {
        e.code && e.code < 500
          ? setTemplateErrorMessage(e.message)
          : setTemplateErrorMessage('We are experiencing technical difficulties. Please try again');
        setContent({ ...content, template: '' });
      }
    };

    const loadTemplate = async () => {
      try {
        const result: any = await feathers.service('/notification/template').get(id, { query: {} });
        setContent({
          isMjml: result.isMjml,
          template: result.template,
          subject: result.subject,
          key: result.key,
        });
      } catch (e) {
        e.code && e.code < 500
          ? setTemplateErrorMessage(e.message)
          : setTemplateErrorMessage('We are experiencing technical difficulties. Please try again');
        setContent({ ...content, template: '' });
      }
    };

    // const loadKeyLists = async () => {
    //   try {
    //     const result: any = await feathers.service('/notification/template-list').create({}, { query: {} })
    //   setKeyList(result)
    //   } catch (e) {

    //   }
    // }

    if (isEdit) {
      loadTemplate();
    } else {
      loadDefaultTemplate();
    }
  }, []);

  // useEffect(() => {
  //   if (!isEdit) {
  //     const loadKeys = async () => {
  //       const result: any = await feathers.service('/notification/template').find({
  //         query: {
  //           $select: ['key']
  //         }
  //       })
  //       setKeys(result.data)
  //     }

  //     const loadKeyLists = async () => {
  //       const result: any = await feathers.service('/notification/template-list').create({}, { query: {} })
  //       setKeyList(result)
  //     }

  //     loadKeys()
  //     loadKeyLists()
  //   }
  // }, [])

  const handleChange = (v: any) => {
    setContent({ ...content, ...v });
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const values = {
      // ...state.data,
      isMjml: content.isMjml,
      subject: content?.subject ?? '',
      template: content?.template ?? '',
      key: content?.key ?? '',
    };
    try {
      isEdit
        ? await service.patch(id, values, commonParams)
        : await service.create(
            {
              ...values,
              tenant: id,
              type: 'customer',
            },
            commonParams
          );
      setIsSubmitting(false);
      history.push(pathPrefix);
    } catch (e) {
      setIsSubmitting(false);
      e.code && e.code < 500
        ? setErrorMessage(e.message)
        : setErrorMessage('We are experiencing technical difficulties. Please try again');
    }
  };

  const pageTitle = isEdit ? 'Edit' : 'New';

  // if (isEdit && !state.data) {
  //   return <PreLoader />
  // }

  return (
    <div>
      <PageHeaderRow
        header={`${pageTitle} Email Template`}
        subheader="Notification"
        subheaderId={''}
      >
        <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
      </PageHeaderRow>

      {
        <ContentEmailForm
          initialData={{ ...content }}
          isEdit={isEdit}
          isSubmitting={isSubmitting}
          onChange={handleChange}
          onSubmit={handleSubmit}
          errorMessage={errorMessage}
          errorTemplateMessage={errorTemplateMessage}
        />
      }
    </div>
  );
};
