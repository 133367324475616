import * as React from 'react';
import { PageHeaderRow, PreLoader, useFeathers } from '@inkcloud/components';
import { Segment, Button } from 'semantic-ui-react';
import Feathers from '../../../bootstrap/feathers';
import { Tenant } from './TenantSetting';
import { SlackInstallButton } from '../SlackInstallButton';

interface DetailProps {}

const { useState } = React;

export const Detail: React.FunctionComponent<DetailProps> = (props) => {
  const [isOpenStatusFilterModal, setIsOpenStatusFilterModal] = useState(false);

  const slackRedirectUri = 'http://localhost:8032/slack/install';

  const state = localStorage.getItem('feathers-jwt');

  return (
    <>
      <PageHeaderRow header={`Settings`} subheader="Tenant" subheaderId={''}>
        <Button
          size="mini"
          content="Filter Status"
          onClick={() => setIsOpenStatusFilterModal(true)}
        />
      </PageHeaderRow>
      <Tenant
        isOpenStatusFilterModal={isOpenStatusFilterModal}
        onCloseStatusFilterModal={() => setIsOpenStatusFilterModal(false)}
      />

      <hr />
      <div>
        <SlackInstallButton />
      </div>
    </>
  );
};
