import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Box, Button } from '@chakra-ui/react';
import { PageHeaderRow, PreLoader, useFeathers } from '@inkcloud/components';
import feathers from '../../../bootstrap/feathers';
import GLAccountForm from './GLAccountForm';

interface DetailProps {}

const pathPrefix = `/settings/gl-accounts`;
const serviceName = '/general-ledger-accounts';
const service = feathers.service(serviceName);
const commonParams = { query: {} };

export default function Detail(props: DetailProps & RouteComponentProps<{ id?: string }>) {
  const {
    match: {
      params: { id },
    },
    history,
  } = props;

  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [state, reload] = useFeathers('get', serviceName, [id, commonParams], true);

  const isEdit = !!id;

  const handleSubmit = async (values) => {
    setIsSubmitting(true);
    setErrorMessage('');

    try {
      if (isEdit) {
        await service.patch(id, values, commonParams);
      } else {
        await service.create(values, { query: {} });
      }

      history.push(pathPrefix);
    } catch (e) {
      const errMSg =
        e?.code < 500 ? e.message : 'We are experiencing technical difficulties. Please try again';
      setErrorMessage(errMSg);
    }
    setIsSubmitting(false);
  };

  if (isEdit && !state.data) {
    return <PreLoader />;
  }

  const pageTitle = isEdit ? 'Edit' : 'New';

  const initialData = { ...state.data };

  return (
    <div>
      <PageHeaderRow header={`${pageTitle} GL Accounts`} subheader="Settings" subheaderId={''}>
        <Button size="sm" onClick={() => history.goBack()}>
          Back
        </Button>
      </PageHeaderRow>
      <Box p={3} mb={3} borderRadius="md" borderWidth="1px">
        {
          <GLAccountForm
            initialValues={initialData}
            isSubmitting={isSubmitting}
            errorMessage={errorMessage}
            onSubmit={handleSubmit}
          />
        }
      </Box>
    </div>
  );
}
