import * as React from 'react';
import { Button, Input, Dropdown, Segment, Form } from 'semantic-ui-react';
import { FeathersDropdown } from '@inkcloud/components';

export interface FilterFormProps {
  value: any;
  onChange: Function;
  onReset: Function;
}

function initMapper(value) {
  const defaults = {
    name: '',
    type: '0',
    warehouse: '',
  };

  return Object.keys(defaults).reduce((p, cur) => {
    if (!p[cur]) {
      p[cur] = defaults[cur];
    }
    return p;
  }, value);
}

const typeOptions = [
  { text: 'All', value: '0' },
  { text: 'Bin', value: '1' },
  { text: 'Warehouse', value: '2' },
  { text: 'Virtual', value: '3' },
];

export const FilterForm: React.FunctionComponent<FilterFormProps> = (props) => {
  const { value: preInitValue } = props;

  const value = initMapper(preInitValue);

  const onChange = (field, value) => {
    props.onChange && props.onChange({ ...props.value, [field]: value });
  };

  return (
    <Segment>
      <Form size={'small'}>
        <Form.Group widths={'equal'}>
          <Form.Field>
            <Input
              placeholder="Name..."
              value={value.name}
              onChange={(e, data) => onChange('name', data.value)}
            />
          </Form.Field>
          <Form.Field>
            <Dropdown
              selection
              fluid
              clearable
              placeholder="Type..."
              options={typeOptions}
              value={value.type}
              onChange={(e, data) => onChange('type', data.value)}
            />
          </Form.Field>

          <Form.Field>
            <FeathersDropdown
              search
              selection
              clearable
              placeholder="Warehouse..."
              serviceName="/inventory/locations"
              value={value.warehouse}
              query={{
                $sort: { name: 1 },
                $select: ['_id', 'name'],
                parentLocation: { $exists: false },
                virtual: { $ne: true },
                $np: 1,
              }}
              resultMapper={(r) => ({ key: r._id, value: r._id, text: r.name })}
              onChange={(e, data) => onChange('warehouse', data.value)}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group widths={'equal'}></Form.Group>
        <Form.Field>
          <Button size="mini" content="Reset Form" onClick={() => props.onReset()} />
        </Form.Field>
      </Form>
    </Segment>
  );
};
