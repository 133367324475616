import * as React from 'react';
import { Button, Form, Segment } from 'semantic-ui-react';

const { useState } = React;

export interface FilterFormProps {
  value: any;
  onChange: Function;
  onReset: Function;
}

function initMapper(value) {
  const defaults = {
    name: value.name || '',
  };

  return Object.keys(defaults).reduce((p, cur) => {
    if (!p[cur]) {
      p[cur] = defaults[cur];
    }
    return p;
  }, value);
}

export function FilterForm(props: FilterFormProps) {
  const { value: preInitValue, onChange } = props;
  const [isSetup, setIsSetup] = useState(false);
  const handleChange = (field, value) => onChange({ ...props.value, [field]: value });
  const value = initMapper(preInitValue);

  return (
    <Segment>
      <Form size={'small'}>
        <Form.Group widths={'equal'}>
          <input
            type="text"
            placeholder="Name..."
            value={value.name}
            onChange={(e) => handleChange('name', e.target.value)}
          />
          <Button type="button" value="Reset Form" onClick={() => props.onReset()} />
        </Form.Group>
      </Form>
    </Segment>
  );
}
