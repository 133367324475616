import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Segment, Table } from 'semantic-ui-react';
import { PageHeaderRow, FeathersDropdown, PreLoader, useFeathers } from '@inkcloud/components';
import feathers from './../../../bootstrap/feathers';
import { AddOnPricingForm } from './AddOnPricingForm';

interface DetailProps {}

const serviceName = 'products-legacy/add-ons';
const service = feathers.service(serviceName);
const commonParams = { query: {} };

export const Detail: React.FunctionComponent<DetailProps & RouteComponentProps<any>> = (props) => {
  const {
    match: {
      params: { id },
    },
    history,
  } = props;

  const pathPrefix = `/products/add-on-pricing`;

  const isEdit = !!id;
  const [state, reload] = useFeathers('get', serviceName, [id, commonParams], true);

  const handleSubmit = async (values: any) => {
    isEdit
      ? await service.patch(id, values, commonParams)
      : await service.create(values, commonParams);
    history.push(pathPrefix);
  };

  if (isEdit && !state.data) {
    return <PreLoader />;
  }

  const initialData = { ...state.data };
  return (
    <div>
      <PageHeaderRow
        header={`${isEdit ? `Edit ${initialData.addOnId}` : 'New'}  Add Ons`}
        subheader="Product"
        subheaderId={''}
      >
        <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
      </PageHeaderRow>

      <Segment>
        {(!isEdit || state.data) && (
          <AddOnPricingForm isEdit={isEdit} initialValues={initialData} onSubmit={handleSubmit} />
        )}
      </Segment>
    </div>
  );
};
