import * as React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Segment, Button, Table, Label, Popup, Form, Dropdown } from 'semantic-ui-react';
import { FormattedDate } from 'react-intl';
import { PageHeaderRow, useList, PaginatorWrapper, EmptyWrapper } from '@inkcloud/components';

import Feathers from './../../../bootstrap/feathers';

import { FilterForm } from './FilterForm';
import { ExportModal } from '../../reports/ExportModal';
import { ProductModal } from './ProductModal';

interface ListProps {}

const filterQueryMap = (values) => {
  const filterMap: any = {
    name: values.name ? { $LIKE: values.name } : undefined,
    key: values.key ? { $LIKE: values.key } : undefined,
    category: values.category ? values.category : undefined,
    groupPermissions: values.groupPermissions ? { $in: values.groupPermissions } : undefined,
  };

  return [].reduce(
    (m, field) => ({ ...m, [field]: values[field] ? values[field] : undefined }),
    filterMap
  );
};

function loadData(values, isExport = false) {
  const params: any = { query: {} };
  if (isExport) {
    params.query.$export = 1;
  }
  return Feathers.service('/microsites/products/group-permissions-audit').create(
    {
      ...values,
    },
    params
  );
}

const serviceName = 'microsites/products';
const pathPrefix = '';

const sortableByOptions = [{ text: 'Date', value: 'createdAt' }];

const sortByOptions = [
  { text: 'Descending', value: -1 },
  { text: 'Ascending', value: 1 },
];

const { useState } = React;

export const List: React.FunctionComponent<ListProps & RouteComponentProps<any>> = (props) => {
  const { micrositeId } = props.match.params;

  const {
    match: { params },
    location,
    history,
  } = props;
  const {
    state,
    reload,
    handleDelete,
    selectedItems,
    clearAll,
    setChecked,
    someSelected,
    toggleAll,
    filter,
    setFilter,
    sort,
    handleSort,
  } = useList({
    feathers: [
      'find',
      serviceName,
      [
        {
          query: {
            microsite: micrositeId,
            isPublished: true,
            $populate: ['product', 'category', 'groupPermissions'],
          },
        },
      ],
    ],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  const { queryResult, isLoading: isStateLoading } = state as any;

  const [isLoading, setIsLoading] = useState(false);
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [exportUrl, setExportUrl] = useState('');
  const [isProductModalOpen, setIsProductModalOpen] = useState(false);
  const [productId, setProductId] = useState('');
  const [productKey, setProductKey] = useState('');
  const [productName, setProductName] = useState('');

  const [sortableBy, setSortableBy] = useState('createdAt');
  const [sortBy, setSortBy] = useState(sort[sortableBy] === 'ascending' ? 1 : -1);

  const handleExportClick = async () => {
    setIsLoading(true);
    const res: any = await loadData({ microsites: [micrositeId] }, true);
    setIsExportModalOpen(true);
    setExportUrl(res.url);
    setIsLoading(false);
  };

  const handleClose = () => {
    setIsLoading(false);
    setExportUrl(null);
    setIsExportModalOpen(false);
  };

  return (
    <>
      <PageHeaderRow header="Products" subheader="Microsite">
        <Link to={`/settings/microsites/${micrositeId}`}>
          <Button content="Back" size={'mini'} />
        </Link>
        <Button
          size="mini"
          secondary
          content="Permissions Audit"
          loading={isLoading}
          onClick={handleExportClick}
        />
      </PageHeaderRow>

      <FilterForm
        value={filter}
        onChange={(v) => setFilter(v)}
        onReset={() => setFilter({})}
        microsite={micrositeId}
      />

      <EmptyWrapper queryResult={queryResult} isStateLoading={isStateLoading}>
        <Segment>
          <PaginatorWrapper.Top
            filter={filter}
            reload={reload}
            data={queryResult as any}
            pathPrefix={`${pathPrefix}`}
          >
            <Form size="tiny">
              <Form.Group inline>
                <Form.Field>
                  <Dropdown
                    options={sortableByOptions}
                    value={sortableBy}
                    selection
                    onChange={(e, data) => {
                      setSortableBy((data as any).value);
                      setSortBy(sort[(data as any).value] !== 'ascending' ? 1 : -1);
                      handleSort((data as any).value);
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <Dropdown
                    options={sortByOptions}
                    value={sortBy}
                    selection
                    onChange={(e, data) => {
                      setSortBy(sort[sortableBy] !== 'ascending' ? 1 : -1);
                      handleSort(sortableBy);
                    }}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          </PaginatorWrapper.Top>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  <input type="checkbox" onChange={toggleAll} />
                </Table.HeaderCell>
                <Table.HeaderCell>Created</Table.HeaderCell>
                <Table.HeaderCell>Category</Table.HeaderCell>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Key</Table.HeaderCell>
                <Table.HeaderCell>Permissions</Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {queryResult?.data?.map((r: any, index: number) => (
                <Table.Row key={index}>
                  <Table.Cell>
                    <input
                      type="checkbox"
                      value={r._id}
                      checked={selectedItems[r._id] || false}
                      onChange={setChecked}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <FormattedDate value={r.createdAt} />
                  </Table.Cell>
                  <Table.Cell>{r.category && r.category.name}</Table.Cell>
                  <Table.Cell>{r.name}</Table.Cell>
                  <Table.Cell>{r.key}</Table.Cell>
                  <Table.Cell>
                    {r.groupPermissions && r.groupPermissions.length > 0
                      ? r.groupPermissions.map((g, gdx) => (
                          <Label key={gdx} size="mini" content={g.name} />
                        ))
                      : 'None Set'}
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    <Popup
                      size="mini"
                      content="Edit Permissions"
                      trigger={
                        <Button
                          size={'mini'}
                          icon={'edit'}
                          onClick={() => {
                            setProductId(r._id);
                            setProductName(r.name);
                            setProductKey(r.key);
                            setIsProductModalOpen(true);
                          }}
                        />
                      }
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
          <PaginatorWrapper.Bottom
            reload={reload}
            data={queryResult as any}
            pathPrefix={`${pathPrefix}`}
          >
            {someSelected && (
              <Button primary type="button" content="Delete" onClick={handleDelete} />
            )}
          </PaginatorWrapper.Bottom>
        </Segment>
      </EmptyWrapper>

      {isProductModalOpen && (
        <ProductModal
          isOpen={isProductModalOpen}
          micrositeId={props.match.params.micrositeId}
          id={productId}
          productName={productName}
          productKey={productKey}
          onReload={reload}
          onClose={() => {
            setProductId('');
            setProductName('');
            setProductKey('');
            setIsProductModalOpen(false);
          }}
        />
      )}

      {isExportModalOpen && (
        <ExportModal
          isModalOpen={isExportModalOpen}
          handleClose={handleClose}
          exportUrl={exportUrl}
        />
      )}
    </>
  );
};
