import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Segment, Table, Message } from 'semantic-ui-react';
import { PageHeaderRow, PreLoader, useFeathers } from '@inkcloud/components';
import feathers from '../../../../bootstrap/feathers';
import PageForm from './PageForm';

interface DetailProps {
  products: { value: string; text: string }[];
  categories: { value: string; text: string }[];
}

const serviceName = 'store-front/content/pages';
const service = feathers.service(serviceName);
const commonParams = {
  query: {
    $populate: ['category', 'product'],
  },
};
const pathPrefix = `/settings/content-management/pages`;

const { useState, useEffect } = React;

export const Detail: React.FunctionComponent<DetailProps & RouteComponentProps<{ id?: string }>> = (
  props
) => {
  const {
    match: {
      params: { id },
    },
    history,
    products,
    categories,
  } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const isEdit = !!id;
  const [state, reload] = useFeathers('get', serviceName, [id, commonParams], true);
  const handleSubmit = async (values) => {
    const url = values?.url?.replace(/-+$/, ''); // Trim (-) hyphen from end of text
    setIsSubmitting(true);
    values.url = url?.charAt(0) === '/' ? url : `/${url}`;

    try {
      setErrorMessage('');

      if (isEdit) {
        await service.patch(id, values, commonParams);
      } else {
        await service.create(values, commonParams);
      }
      history.push(pathPrefix);
    } catch (e) {
      const errMsg =
        e?.code < 500 ? e?.message : 'We are experiencing technical difficulties. Please try again';
      setErrorMessage(errMsg);
    }

    setIsSubmitting(false);
  };

  const pageTitle = isEdit ? 'Edit' : 'New';

  if (isEdit && !state.data) {
    return <PreLoader />;
  }

  const initialData = { ...state.data };
  return (
    <div>
      <PageHeaderRow header={`${pageTitle} Page`} subheader="Settings" subheaderId={''}>
        <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
      </PageHeaderRow>

      <Segment>
        <PageForm
          errorMessage={errorMessage}
          initialValues={initialData}
          isSubmitting={isSubmitting}
          onSubmit={handleSubmit}
        />
      </Segment>
    </div>
  );
};
