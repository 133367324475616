import * as React from 'react';
import { Formik, Form as FormikForm, Field, FieldArray, ErrorMessage, FieldProps } from 'formik';
import { Button, Form, Message, Header, Segment } from 'semantic-ui-react';
import { FeathersDropdown, GenericFilter } from '@inkcloud/components';
import * as Yup from 'yup';

import { SemanticField } from '../../common/Form';

import { getFilterDefinition } from '../orders/FilterForm2';

interface LateJobDetectorFormProps {
  initialValues: any;
  errorMessage: string;
  onSubmit: (v: any) => void;
  filter: any;
  onChangeFilter: (v: any) => void;
}

export const LateJobDetectorForm: React.FunctionComponent<LateJobDetectorFormProps> = (
  outerProps
) => {
  const { initialValues, errorMessage, onSubmit, filter, onChangeFilter } = outerProps;

  const LateJobDetectorSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    timeThreshold: Yup.object().shape(
      {
        sinceCreated: Yup.number().when('sinceStatusChange', {
          is: (sinceStatusChange) => !sinceStatusChange,
          then: Yup.number()
            .required('Required')
            .typeError('Must be a number')
            .positive()
            .moreThan(-1, 'Must be positive a number'),
        }),
        sinceStatusChange: Yup.number().when('sinceCreated', {
          is: (sinceCreated) => !sinceCreated,
          then: Yup.number()
            .required('Required')
            .typeError('Must be a number')
            .positive()
            .moreThan(-1, 'Must be positive a number'),
        }),
      },
      ['sinceCreated', 'sinceStatusChange']
    ),
  });

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={LateJobDetectorSchema}
        onSubmit={(values, { setSubmitting }) => {}}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            setFieldValue,
            setFieldTouched,
          } = props;

          return (
            <>
              <div
                className={`ui form ${isSubmitting && !errorMessage && 'loading'} ${
                  errorMessage ? 'error' : ''
                }`}
                style={{ height: '100%' }}
              >
                <Header as="h5" attached="top" block>
                  Name of Detector
                </Header>
                <Segment attached="bottom">
                  <Form.Group widths="equal">
                    <Form.Field>
                      <SemanticField
                        fluid
                        name="name"
                        component={Form.Input}
                        label="What do you want to name this detector?"
                      />
                      <ErrorMessage
                        render={(msg) => <Message negative size="tiny" content={msg} />}
                        name="name"
                      />
                    </Form.Field>
                  </Form.Group>
                </Segment>

                <Header as="h5" attached="top" block>
                  Order Item Criteria
                </Header>
                <Segment attached="bottom">
                  <p>Only order items meeting the following criteria will be checked</p>
                  <div style={{ marginLeft: '-10px', marginRight: '-10px' }}>
                    <GenericFilter
                      factDefinitionFunction={getFilterDefinition}
                      value={initialValues?.criteria || {}}
                      onChange={(v) => {
                        onChangeFilter(v);
                      }}
                    />
                  </div>

                  <p>
                    Time thresholds are in hours and are triggered when criteria is greater than
                    value
                  </p>
                  <Form.Field>
                    <SemanticField
                      fluid
                      name="timeThreshold.sinceCreated"
                      component={Form.Input}
                      label="Time since created"
                    />
                    <ErrorMessage
                      render={(msg) => <Message negative size="tiny" content={msg} />}
                      name="timeThreshold.sinceCreated"
                    />
                  </Form.Field>
                  <Form.Field>
                    <SemanticField
                      fluid
                      name="timeThreshold.sinceStatusChange"
                      component={Form.Input}
                      label="Time since last status change"
                    />
                    <ErrorMessage
                      render={(msg) => <Message negative size="tiny" content={msg} />}
                      name="timeThreshold.sinceStatusChange"
                    />
                  </Form.Field>
                  <Form.Group widths="equal">
                    <Form.Field>
                      <SemanticField
                        fluid
                        toggle
                        name="isEnabled"
                        component={Form.Checkbox}
                        label="Active"
                      />
                    </Form.Field>
                  </Form.Group>
                </Segment>
                <Segment>
                  <Form.Field>
                    <Field
                      name="vendor"
                      render={({ field }: FieldProps<any>) => (
                        <FeathersDropdown
                          search
                          selection
                          clearable
                          placeholder="Optional Select a supplier..."
                          serviceName="production/vendors"
                          query={{
                            $sort: { companyName: 1 },
                            $np: 1,
                          }}
                          resultMapper={(r) => ({
                            key: r._id,
                            value: r._id,
                            text: `${r.companyName}`,
                          })}
                          {...field}
                          // defaultValue={values.manager && !values.manager.isDeleted ? values.manager._id : null}
                          onChange={(e, data) =>
                            field.onChange({ target: { value: data.value, name: 'vendor' } } as any)
                          }
                        />
                      )}
                    />
                  </Form.Field>
                  <label>
                    Only select a vendor if you want the late job detector to be visible in the
                    vendor's IC dashboard
                  </label>
                </Segment>

                {errorMessage && <Message error content={errorMessage} />}
                <Form.Field>
                  <Button
                    primary
                    type="button"
                    disabled={isSubmitting && !errorMessage}
                    onClick={() => onSubmit(values)}
                  >
                    Save
                  </Button>
                </Form.Field>
              </div>
            </>
          );
        }}
      </Formik>
    </>
  );
};
