import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Segment, Input, Form } from 'semantic-ui-react';
import { PageHeaderRow, PreLoader, useFeathers } from '@inkcloud/components';
// import brace from "brace";
// import AceEditor from "react-ace";
// import "brace/mode/html";
// import "brace/theme/monokai";
import feathers from '../../../bootstrap/feathers';

import { ContentEmailForm } from './ContentEmailForm';

import { humanize } from 'underscore.string';

interface DetailProps {}

const { useState, useEffect } = React;

const serviceName = 'notification/template';
const service = feathers.service(serviceName);
const commonParams = { query: {} };

export const Detail: React.FunctionComponent<
  DetailProps & RouteComponentProps<{ id?: string; micrositeId?: string; key?: string }>
> = (props) => {
  const {
    match: {
      params: { id, micrositeId, key },
    },
    location,
    history,
  } = props;
  const isEdit = !!id;
  const [content, setContent] = useState({ isMjml: true, subject: '', template: '', key: '' });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [tenantId, setTenantId] = useState('');

  const [errorMessage, setErrorMessage] = useState('');
  const [errorTemplateMessage, setTemplateErrorMessage] = useState('');

  useEffect(() => {
    const loadTenant = async () => {
      const result: any = await feathers.service('tenants').find({ query: {} });
      setTenantId(result._id);
    };
    const loadTemplate = async () => {
      try {
        const result: any = await feathers.service('/notification/template').get(id, {
          query: {
            microsite: micrositeId,
          },
        });
        setContent({
          isMjml: result.isMjml,
          template: result.template,
          subject: result.subject,
          key: result.key,
        });
      } catch (e) {
        e.code && e.code < 500
          ? setTemplateErrorMessage(e.message)
          : setTemplateErrorMessage('We are experiencing technical difficulties. Please try again');
        setContent({ ...content, template: '' });
      }
    };

    const loadDefaultTemplate = async () => {
      setTemplateErrorMessage('');
      try {
        const result: any = await feathers.service('/notification/default-template').find({
          query: {
            key,
            microsite: micrositeId,
          },
        });
        if (result) {
          setContent({
            ...content,
            isMjml: result?.mjml ?? true,
            template: result.defaultTemplate,
            subject: result.subject,
            key: result.key,
          });
        }
      } catch (e) {
        e.code && e.code < 500
          ? setTemplateErrorMessage(e.message)
          : setTemplateErrorMessage('We are experiencing technical difficulties. Please try again');
        setContent({ ...content, template: '' });
      }
    };

    if (isEdit) {
      loadTemplate();
    } else {
      loadDefaultTemplate();
    }

    loadTenant();
  }, []);

  const pathPrefix = `/settings/microsites/${micrositeId}/notifications`;

  const handleChange = (v: any) => {
    setContent({ ...content, ...v });
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const values = {
      // ...state.data,
      isMjml: content.isMjml ?? true,
      subject: content && content.subject ? content.subject : '',
      template: content && content.template ? content.template : '',
    };
    try {
      isEdit
        ? await service.patch(id, values, commonParams)
        : await service.create(
            {
              ...values,
              tenant: tenantId,
              microsite: micrositeId,
              type: 'micrositeUser',
              key: content?.key,
            },
            commonParams
          );
      setIsSubmitting(false);
      history.push(pathPrefix);
    } catch (e) {
      setIsSubmitting(false);
      e.code && e.code < 500
        ? setErrorMessage(e.message)
        : setErrorMessage('We are experiencing technical difficulties. Please try again');
    }
  };

  const pageTitle = isEdit ? 'Edit' : 'New';

  // if (isEdit && !state.data) {
  //   return <PreLoader />
  // }

  // const initialData = { ...state.data, microsite: micrositeId };
  return (
    <div>
      <PageHeaderRow
        header={`Edit ${humanize(content?.key ?? '')}`}
        subheader="Notification Template"
        subheaderId={''}
      >
        <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
      </PageHeaderRow>

      {
        <ContentEmailForm
          initialData={{ ...content }}
          isEdit={isEdit}
          isSubmitting={isSubmitting}
          onChange={handleChange}
          onSubmit={handleSubmit}
          errorMessage={errorMessage}
          errorTemplateMessage={errorTemplateMessage}
        />
      }
    </div>
  );
};
