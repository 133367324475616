import * as React from 'react';
import { Document } from '@react-pdf/renderer';
import { PurchaseOrderContext, PurchaseOrderProvider } from './PurchaseOrderContext';

export interface IPurchaseOrderDocumentProps {
  _initialState: any;
  render: (v: any) => any;
  children: React.ReactNode;
}

export const mockOptions = {
  title: 'Purchase Order',
  creator: 'Ink Cloud',
  producer: 'Ink Cloud',
};

export const PurchaseOrderDocument: React.FunctionComponent<IPurchaseOrderDocumentProps> = (
  props
) => {
  const { title, creator, producer } = mockOptions;
  const { children, _initialState, render } = props;

  // console.log('_initialState in PurchaseOrderDocument', _initialState);

  // delete _initialState.styles

  return (
    <PurchaseOrderProvider initialState={_initialState}>
      <Document title={title} creator={creator} producer={producer}>
        {render ? render(_initialState) : children}
      </Document>
    </PurchaseOrderProvider>
  );
};
