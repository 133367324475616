import * as React from 'react';
import { Modal, Button, Loader } from 'semantic-ui-react';
import styled from '@emotion/styled';
import Feathers from './../../../bootstrap/feathers';

const LoaderWrapper = styled.div`
  display: inline-block;

  & > .loader {
    &:before {
      border-color: rgba(0, 0, 0, 0.1) !important;
    }

    &:after {
      border-color: #767676 transparent transparent !important;
    }
  }
`;

interface IAttributeCheckModalProps {
  isOpen: boolean;
  onClose: () => void;
  attribute: any;
}

const { useState, useEffect } = React;

export const AttributeCheckModal: React.FunctionComponent<IAttributeCheckModalProps> = (props) => {
  const { isOpen, onClose, attribute } = props;

  const [hiddenCount, setHiddenCount] = useState();
  const [visibleCount, setVisibleCount] = useState();

  const [isLoading, setIsLoading] = useState({
    hidden: false,
    visible: false,
  });

  useEffect(() => {
    const loadProducts = async (isHidden: boolean) => {
      setIsLoading({
        hidden: true,
        visible: true,
      });
      try {
        const res: any = await Feathers.service('/products-legacy').find({
          query: {
            attributes: {
              [`${attribute?.entityAttribute?._id}`]: [attribute?._id],
            },
            isHidden: isHidden ? true : { $ne: true },
            $np: 1,
          },
        });

        if (isHidden) {
          setHiddenCount(res?.length);
        } else {
          setVisibleCount(res?.length);
        }
      } catch (e) {}

      setIsLoading({
        hidden: false,
        visible: false,
      });
    };

    loadProducts(true);
    loadProducts(false);
  }, [attribute?._id]);

  return (
    <Modal size="tiny" open={isOpen} onClose={onClose} closeIcon>
      <Modal.Header>
        Usage Check ({attribute?.entityAttribute?.name ?? ''} - {attribute?.name ?? ''})
      </Modal.Header>
      <Modal.Content>
        <div>
          Used by{' '}
          {isLoading.visible ? (
            <LoaderWrapper>
              <Loader size="tiny" active inline />
            </LoaderWrapper>
          ) : (
            <strong>{visibleCount}</strong>
          )}{' '}
          visible products
        </div>
        <br />
        <div>
          Used by{' '}
          {isLoading.hidden ? (
            <LoaderWrapper>
              <Loader size="tiny" active inline />
            </LoaderWrapper>
          ) : (
            <strong>{hiddenCount}</strong>
          )}{' '}
          hidden products
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button size="tiny" content="Close" onClick={onClose} />
      </Modal.Actions>
    </Modal>
  );
};
