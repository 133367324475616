import * as React from 'react';
import { InjectedFormProps, Field, reduxForm, formValueSelector, FormProps } from 'redux-form';
import { Button, Form } from 'semantic-ui-react';
import {
  semanticFormField,
  semanticFormToggleField,
  semanticFormDropdownField,
} from './../../../common';
import { EntityDropdownWrapper2 } from './../../EntityDropdown';

const srcLangDropdown = EntityDropdownWrapper2(
  '/products-legacy/attributes',
  {
    query: {
      $np: 1,
      $sort: { name: 1 },
      entityAttribute: { $eq: 'SRC_LANG' },
    },
  },
  {
    resultMap: (row) => ({ value: row._id, text: row.name }),
    searchMap: (qs) => ({ name: { $LIKE: qs } }),
  }
);

const destLangDropdown = EntityDropdownWrapper2(
  '/products-legacy/attributes',
  {
    query: {
      $np: 1,
      $sort: { name: 1 },
      entityAttribute: { $eq: 'DEST_LANG' },
    },
  },
  {
    resultMap: (row) => ({ value: row._id, text: row.name }),
    searchMap: (qs) => ({ name: { $LIKE: qs } }),
  }
);

const categoryDropdown = EntityDropdownWrapper2(
  '/products-legacy/categories',
  {
    query: {
      $np: 1,
      $sort: { name: 1 },
      $populate: ['entityAttributes'],
    },
  },
  {
    resultMap: (row) => ({ value: row._id, text: row.name }),
    searchMap: (qs) => ({ name: { $LIKE: qs } }),
  }
);

const statusDropdown = EntityDropdownWrapper2(
  '/order-item-statuses',
  {
    query: {
      $np: 1,
      $sort: { name: 1 },
    },
  },
  {
    resultMap: (row) => ({ value: row._id, text: row.name }),
    searchMap: (qs) => ({ name: { $LIKE: qs } }),
  }
);

export function FilterForm(props: InjectedFormProps) {
  const { handleSubmit, pristine, reset, submitting } = props;
  return (
    <Form size={'tiny'} onSubmit={handleSubmit}>
      <Form.Group widths={'equal'}>
        <Field
          name="source"
          component={semanticFormDropdownField}
          as={srcLangDropdown}
          multiple={true}
          label="Source Languages"
          placeholder="Source Languages..."
        />
        <Field
          name="destination"
          component={semanticFormDropdownField}
          as={destLangDropdown}
          multiple={true}
          label="Destination Languages"
          placeholder="Destination Languages..."
        />
      </Form.Group>
      <Form.Group widths={'equal'}>
        <Field
          name="category"
          component={semanticFormDropdownField}
          as={categoryDropdown}
          multiple={true}
          label="Product Category"
          placeholder="Product Category..."
        />
        <Field
          name="status"
          component={semanticFormDropdownField}
          as={statusDropdown}
          multiple={true}
          label="Status"
          placeholder="Status..."
        />
      </Form.Group>
      <Form.Group inline>
        <Form.Button inline size={'tiny'} disabled={pristine || submitting}>
          Filter
        </Form.Button>
        <Form.Button
          inline
          size={'tiny'}
          type={'button'}
          disabled={!props.isFiltered}
          onClick={() => props.reset() && props.handleClearFilter()}
        >
          Clear Filter
        </Form.Button>
      </Form.Group>
    </Form>
  );
}

export default reduxForm({
  form: 'translatorsFilter',
})(FilterForm);
