import * as React from 'react';
import { Modal, Button, Checkbox, Form, Message } from 'semantic-ui-react';
import { Feathers } from '../../../bootstrap/feathers/feathers';

interface IVisibilityModalProps {
  item: any;
  onReload: () => void;
  onClose: () => void;
}

const { useState } = React;

export const VisibilityModal: React.FunctionComponent<IVisibilityModalProps> = (props) => {
  const { onClose, onReload, item } = props;

  const [isHidden, setIsHidden] = useState(item.isHidden);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const payload = {
      ...item,
      isHidden,
    };
    try {
      const res: any = await Feathers.service('/products-legacy').patch(item._id, payload, {});

      onReload();
      onClose();
    } catch (e) {
      const errMsg =
        e.code && e.code < 500
          ? e.message
          : 'We are experiencing technical difficulties. Please try again';
      setErrorMessage(errMsg);
    }

    setIsSubmitting(false);
  };

  return (
    <Modal open size="mini" onClose={onClose} closeIcon>
      <Modal.Header>{item?.staticName ?? ''}</Modal.Header>
      <Modal.Content>
        <Form error={!!errorMessage}>
          <Form.Field>
            <Checkbox
              toggle
              label="Hide on Store Front"
              checked={isHidden}
              onClick={(e, data) => setIsHidden(data.checked)}
            />
          </Form.Field>
          {errorMessage && <Message error content={errorMessage} />}

          <Button
            primary
            disabled={isSubmitting}
            loading={isSubmitting}
            content="Save"
            onClick={handleSubmit}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button content="Close" onClick={onClose} />
      </Modal.Actions>
    </Modal>
  );
};
