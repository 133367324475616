import * as React from 'react';
import { Button, Segment, Popup } from 'semantic-ui-react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { FormattedDate, FormattedTime } from 'react-intl';
import { NotificationContentModal } from './NotificationContentModal';

import {
  useList,
  ListPage,
  basicQueryMap,
  generateOperatorOptions,
  Operator,
} from '@inkcloud/components';

const { useState } = React;

interface ListProps {}

const serviceName = 'notification/v2/log';
const pathPrefix = '/admin/notification-log';

const filterQueryMap = (values) => {
  // const filterMap: any = {
  //   from: values.from ? { $LIKE: values.from } : undefined,
  //   subject: values.subject ? { $LIKE: values.subject } : undefined,
  // };

  // return [].reduce((m, field) => ({ ...m, [field]: values[field] ? values[field] : undefined }), filterMap);

  return basicQueryMap(values, {});
};

export async function getFilterDefinition() {
  let definition = [
    {
      label: 'From',
      value: 'from',
      operators: generateOperatorOptions([Operator.Like]),
    },
    {
      label: 'Subject',
      value: 'subject',
      operators: generateOperatorOptions([Operator.Like]),
    },
  ];

  return definition.sort((a: any, b: any) => a.label.localeCompare(b.label));
}

export const List: React.FunctionComponent<ListProps & RouteComponentProps<any>> = (props) => {
  const [content, setContent] = useState('');
  const [isContentModalOpen, setIsContentModalOpen] = useState(false);

  const {
    match: { params },
    location,
    history,
  } = props;

  const tableDefinition = [
    {
      key: 'createdAt',
      label: 'Date',
      sortable: true,
      cell: (v) =>
        v && (
          <Popup
            size="mini"
            content={<FormattedTime value={v} />}
            trigger={
              <div>
                <FormattedDate value={v} />
              </div>
            }
          />
        ),
    },
    {
      key: 'from',
      label: 'From',
      sortable: true,
      cell: (v, r) => (
        <>
          <div>{r?.from?.email}</div>
          <div>{r?.from?.name}</div>
        </>
      ),
    },
    {
      key: 'to',
      label: 'To',
      sortable: true,
      cell: (v, r) =>
        r?.from && (
          <>
            {r?.to?.map((to: any, index: number) => to?.email)?.join(', ')}
            <div style={{ fontSize: 10 }}>
              {r?.cc?.map((c: any, index: number) => (
                <div key={index} style={{ marginRight: 5 }}>
                  CC: {c.email}
                </div>
              ))}
            </div>
            <div style={{ fontSize: 10 }}>
              {r?.bcc?.map((b: any, index: number) => (
                <div key={index} style={{ marginRight: 5 }}>
                  BCC: {b.email}
                </div>
              ))}
            </div>
          </>
        ),
    },
    {
      key: 'subject',
      label: 'Subject',
      sortable: true,
      cell: (v, r) => v,
    },
    {
      key: '',
      label: '',
      sortable: false,
      cell: (v, r) => (
        <Popup
          size="mini"
          content="View"
          trigger={<Button icon="magnify" size="mini" onClick={() => handleViewClick(r)} />}
        />
      ),
      textAlign: 'right',
    },
  ];

  const useListValues = useList({
    feathers: ['find', serviceName, [{ query: {} }]],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  const handleViewClick = (row) => {
    setContent(row.content);
    setIsContentModalOpen(true);
  };
  return (
    <div>
      <ListPage
        header={'Log'}
        subheader="Notification"
        useListValues={useListValues}
        history={history}
        pathPrefix={pathPrefix}
        tableDefintion={tableDefinition}
        withCheckboxes={false}
        headerActions={null}
        getFilterDefinition={getFilterDefinition}
      />
      <NotificationContentModal
        open={isContentModalOpen}
        onClose={() => setIsContentModalOpen(false)}
        content={content}
      />
    </div>
  );
};
