import * as React from 'react';
import { Button, Form, Message } from 'semantic-ui-react';
import { Formik, Form as FormikForm, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { SemanticField } from '../../../common/Form';
import { slugify2 as slugifyKey } from '../../../common';

export interface ProductionChannelsFormProps {
  initialValues: any;
  onSubmit: (v: any) => void;
  errorMessage: string;
}

const ProductionChannelsSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  key: Yup.string().required('Required'),
});

export const ProductionChannelsForm: React.FunctionComponent<ProductionChannelsFormProps> = (
  props
) => {
  const { initialValues, onSubmit, errorMessage } = props;

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={ProductionChannelsSchema}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values);
        }}
      >
        {(innerProps) => {
          const {
            values,
            touched,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            setFieldValue,
            setFieldTouched,
          } = innerProps;

          const handleSlugify = (v) => setFieldValue('key', slugifyKey(v));

          return (
            <FormikForm className={`ui form ${isSubmitting && !errorMessage && 'loading'}`}>
              <Form.Group widths="equal">
                <Form.Field>
                  <SemanticField
                    name={'name'}
                    label="Name"
                    component={Form.Input}
                    onChange={(v) => {
                      if (!values?._id) {
                        setFieldValue('key', slugifyKey(v));
                      }
                    }}
                  />
                  <ErrorMessage
                    render={(msg) => <Message negative size="tiny" content={msg} />}
                    name="name"
                  />
                </Form.Field>
                <Form.Field>
                  <SemanticField
                    name={'key'}
                    label="Key"
                    component={Form.Input}
                    onChange={handleSlugify}
                  />
                  <ErrorMessage
                    render={(msg) => <Message negative size="tiny" content={msg} />}
                    name="key"
                  />
                </Form.Field>
              </Form.Group>
              <Button primary type="submit" disabled={isSubmitting && !errorMessage}>
                Save
              </Button>
            </FormikForm>
          );
        }}
      </Formik>
      {errorMessage && <Message error content={errorMessage} />}
    </>
  );
};
