import * as React from 'react';
import { Table } from 'semantic-ui-react';
import { humanize } from 'underscore.string';

import { readableAddOns } from './OrderItemCard2';

import {
  addOnFields,
  rcOptions,
  dynamicAddOns,
  bcslitOptions,
} from './../components/order-builder/calculator/AddOnSet';

interface DetailTableProps {
  description?: string;
  attributes?: any;
  quantity?: number;
  addOns?: any[];
}

const allAddOns = [
  ...addOnFields,
  ...dynamicAddOns,
  { key: 'rc', options: rcOptions },
  { key: 'bcslit', options: bcslitOptions },
];

export const DetailTable: React.FunctionComponent<DetailTableProps> = (props) => {
  const { description, attributes, quantity, addOns } = props;

  return (
    <>
      <Table basic="very" size="small" compact="very">
        <Table.Body>
          {attributes &&
            attributes.length > 0 &&
            attributes.map((a: any, idx: number) => (
              <Table.Row key={idx}>
                <Table.Cell style={{ borderTop: 0 }}>
                  <strong>{humanize(a.attribute._id ? a.attribute._id : a.attribute)}</strong>
                </Table.Cell>
                <Table.Cell style={{ borderTop: 0 }}>{a.value.name}</Table.Cell>
              </Table.Row>
            ))}
          {addOns && addOns.length > 0 && (
            <Table.Row>
              <Table.Cell style={{ borderTop: 0 }}>
                <strong>Add Ons</strong>
              </Table.Cell>
              {addOns.map((a: any, idx: number) => {
                let readable =
                  a.addOnId && readableAddOns(addOns, allAddOns, a.addOnId, a.primaryData);
                return (
                  <Table.Cell key={idx} style={{ borderTop: 0 }}>
                    {readable}
                    {idx < addOns.length - 1 && ', '}
                  </Table.Cell>
                );
              })}
            </Table.Row>
          )}
          {quantity >= 0 && (
            <Table.Row>
              <Table.Cell style={{ borderTop: 0 }}>
                <strong>Quantity</strong>
              </Table.Cell>
              <Table.Cell style={{ borderTop: 0 }}>{quantity}</Table.Cell>
            </Table.Row>
          )}
          {description && (
            <Table.Row>
              <Table.Cell style={{ borderTop: 0 }}>
                <strong>Description</strong>
              </Table.Cell>
              <Table.Cell style={{ borderTop: 0 }}>{description}</Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </>
  );
};
