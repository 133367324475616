import * as React from 'react';
import { Box, Button, HStack, IconButton, Tag, Tooltip } from '@chakra-ui/react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { FormattedDate, FormattedTime } from 'react-intl';
import {
  useList,
  PageHeaderRow,
  PreLoader,
  PaginatorWrapper,
  ListPage,
  generateOperatorOptions,
  Operator,
  basicQueryMap,
} from '@inkcloud/components';
import { FaEdit } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import { currencySymbol } from '../../../../common';

interface ListProps {
  products: { value: string; text: string }[];
  categories: { value: string; text: string }[];
}

const serviceName = 'user-allotment-policies';

const filterQueryMap = (values) => basicQueryMap(values, {});

export default async function getFilterDefinition() {
  const definition = [
    {
      label: 'Name',
      value: 'name',
      operators: generateOperatorOptions([Operator.Like]),
    },
  ];

  return definition.sort((a: any, b: any) => a.label.localeCompare(b.label));
}

export const List: React.FunctionComponent<
  ListProps & RouteComponentProps<{ micrositeId: string }>
> = (props) => {
  const {
    match: { params },
    location,
    history,
  } = props;

  const pathPrefix = `/settings/microsites/${params.micrositeId}/allotment-policies`;

  const useListValues = useList({
    feathers: [
      'find',
      serviceName,
      [
        {
          query: {
            microsite: params.micrositeId,
            $populate: ['products'],
            $sort: { createdAt: -1 },
          },
        },
      ],
    ],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  const tenant = useSelector(
    ({
      globals,
    }: {
      globals: { tenant: { internationalization: { currency: string; locale: string } } };
    }) => globals.tenant.internationalization
  );

  const tableDefintion = [
    {
      key: 'createdAt',
      label: 'Date',
      sortable: true,
      cell: (v) => (
        <Tooltip hasArrow aria-label="time" label={<FormattedTime value={v} />}>
          <Box>
            <FormattedDate value={v} />
          </Box>
        </Tooltip>
      ),
    },
    {
      key: 'name',
      label: 'Name',
      cell: (v) => v,
    },
    {
      key: 'description',
      label: 'Description',
      cell: (v) => v,
    },
    {
      key: 'amount',
      label: 'Amount',
      textAlign: 'right',
    },
    {
      key: 'expDays',
      label: 'Exp Days',
      cell: (v, r) => <>{`${v} day${v > 1 ? 's' : ''}`}</>,
    },
    {
      key: '',
      label: '',
      cell: (v, r) => (
        <Tooltip label="Edit Details">
          <Link to={`${pathPrefix}/${r._id}`}>
            <IconButton colorScheme="gray" aria-label="Edit" icon={<FaEdit />} />
          </Link>
        </Tooltip>
      ),
      textAlign: 'right',
    },
  ];

  const headerActions = (
    <>
      <Button mr={2} onClick={() => history.goBack()}>
        Back
      </Button>
      <Link to={`${pathPrefix}/add`}>
        <Button colorScheme="blue">New</Button>
      </Link>
    </>
  );
  return (
    <div>
      <ListPage
        header="Allotment Policies"
        subheader="Microsites"
        useListValues={useListValues}
        history={history}
        pathPrefix={pathPrefix}
        headerActions={headerActions}
        tableDefintion={tableDefintion}
        withCheckboxes={true}
        getFilterDefinition={getFilterDefinition}
      />
    </div>
  );
};
