import * as React from 'react';
import { Formik, Form as FormikForm, Field, ErrorMessage, FieldProps, FieldArray } from 'formik';
import { Button, Form, Checkbox, Message } from 'semantic-ui-react';
import { SemanticField } from '../../../common/Form';

import Feathers from '../../../bootstrap/feathers';

interface GeneralFormProps {
  id: string;
  general: any;
}

const { useState } = React;

export const GeneralForm: React.FunctionComponent<GeneralFormProps> = (props) => {
  const { id } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (value: any) => {
    try {
      setIsLoading(true);
      const res: any = await Feathers.service('tenant-settings/general').patch(id, value, {
        query: {},
      });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      e.code && e.code < 500
        ? setErrorMessage(e.message)
        : setErrorMessage('We are experiencing technical difficulties. Please try again');
    }
  };

  return (
    <>
      {errorMessage && <Message error content={errorMessage} />}
      <Formik
        initialValues={props.general || {}}
        enableReinitialize={true}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values);
        }}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            setFieldValue,
            setFieldTouched,
          } = props;

          return (
            <FormikForm className={`ui form ${isLoading && 'loading'}`}>
              <div className="field">
                <label>Domain</label>
                <div>
                  <FieldArray
                    name="approvedDomains"
                    render={(arrayHelpers) => (
                      <>
                        {values &&
                          values.approvedDomains &&
                          values.approvedDomains.length > 0 &&
                          values.approvedDomains.map((cc, index) => (
                            <Form.Group widths="equal" key={index}>
                              <SemanticField
                                component={Form.Input}
                                name={`approvedDomains.${index}.domain`}
                              />

                              <div>
                                <Button type="button" onClick={() => arrayHelpers.remove(index)}>
                                  X
                                </Button>
                              </div>
                            </Form.Group>
                          ))}
                        <Form.Group>
                          <div className="field">
                            <Button type="button" onClick={() => arrayHelpers.push({ domain: '' })}>
                              Add New
                            </Button>
                          </div>
                        </Form.Group>
                      </>
                    )}
                  />
                </div>
              </div>
              <Button primary type="submit" disabled={isLoading}>
                Save
              </Button>
            </FormikForm>
          );
        }}
      </Formik>
    </>
  );
};
