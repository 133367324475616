import * as React from 'react';
import { Form, Modal, Button, Message } from 'semantic-ui-react';
import { FeathersDropdown } from '@inkcloud/components';
import Feathers from '../../bootstrap/feathers';
const { useState, useEffect } = React;

interface AssignDesignerModalProps {
  id: string;
  humanId: string;
  assignedDesignerId: string;
  isModalOpen: boolean;
  handleClose: () => void;
  onReload: () => void;
}

export const AssignDesignerModal: React.FunctionComponent<AssignDesignerModalProps> = (props) => {
  const { isModalOpen, handleClose, id, humanId, assignedDesignerId, onReload } = props;

  const [designerId, setDesignerId] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (v: any) => {
    setDesignerId(v);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const res: any = await Feathers.service('order-items/assign-designer').create(
        {
          orderItemId: id,
          userId: designerId,
        },
        { query: {} }
      );

      onReload();
      handleClose();
      setIsLoading(false);
      setErrorMessage('');
    } catch (e) {
      setIsLoading(false);
      e.code && e.code < 500
        ? setErrorMessage(e.message)
        : setErrorMessage('We are experiencing technical difficulties. Please try again');
    }
  };

  return (
    <Modal size={'tiny'} open={isModalOpen} onClose={handleClose} closeIcon>
      <Modal.Header as="h5">Assign a Designer - {humanId}</Modal.Header>
      <Modal.Content>
        {errorMessage && <Message error content={errorMessage} />}
        <Form>
          <Form.Group>
            <FeathersDropdown
              search
              selection
              lazyLoad
              fluid
              placeholder="Select"
              serviceName="/users/get-by-role"
              query={{
                $sort: { firstName: 1 },
                $np: 1,
                key: 'designer',
              }}
              resultMapper={(r) => ({ value: r._id, text: r.firstName + ' ' + r.lastName })}
              defaultValue={assignedDesignerId}
              onChange={(e, data) => handleChange(data.value as string)}
            />
          </Form.Group>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button primary onClick={handleSubmit} loading={isLoading} content={'Submit'} />
      </Modal.Actions>
    </Modal>
  );
};
