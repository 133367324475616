import React, { useState } from 'react';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Alert,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Stack,
} from '@chakra-ui/react';
import type { ModelTypes } from '@inkcloud/icapi-types';

interface IPasswordModalProps {
  onClose: () => void;
  data: ModelTypes.User;
  isSubmitting?: boolean;
  errorMessage: string;
  onSubmit: (v: ModelTypes.User) => void;
}

export default function PasswordModal(props: IPasswordModalProps) {
  const { onClose, data, isSubmitting, errorMessage, onSubmit } = props;

  const [password, setPassword] = useState('');
  const [rePassword, setRePassword] = useState('');

  const handleSubmit = () => {
    const values = {
      ...data,
      password,
    };
    onSubmit(values);
  };

  const misMatch = password && rePassword && password !== rePassword;

  return (
    <Modal isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Update Password</ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <Stack spacing={7} mb={3} direction="column">
            <FormControl isInvalid={!!misMatch}>
              <FormLabel>Password</FormLabel>
              <Input type="password" onChange={(e) => setPassword(e.target.value)} />
            </FormControl>
            <FormControl isInvalid={!!misMatch}>
              <FormLabel>Confirm Password</FormLabel>
              <Input type="password" onChange={(e) => setRePassword(e.target.value)} />
              {misMatch && (
                <FormErrorMessage>Password and Confirm Password doesn't match.</FormErrorMessage>
              )}
            </FormControl>
          </Stack>

          {errorMessage && (
            <Alert mb={3} status="error">
              {errorMessage}
            </Alert>
          )}
        </ModalBody>

        <ModalFooter>
          <Button isDisabled={isSubmitting} mr={3} onClick={onClose}>
            Close
          </Button>
          <Button
            isLoading={isSubmitting}
            isDisabled={isSubmitting || !!misMatch}
            colorScheme="blue"
            onClick={handleSubmit}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
