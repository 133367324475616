import * as React from 'react';
import { useHistory, useLocation } from 'react-router';
import {
  Box,
  Heading,
  Flex,
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
  Button,
  Alert,
} from '@chakra-ui/react';
import { humanize } from 'underscore.string';
import { Feathers } from '../../../bootstrap/feathers/feathers';
import DateRangePicker from '../../../prototypes/common/form/DateRangePicker';

interface IPurchasingReportProps {}

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const PurchasingReport: React.FunctionComponent<IPurchasingReportProps> = (props) => {
  const query = useQuery();
  const [data, setData] = React.useState([]);
  const [headers, setHeaders] = React.useState<
    { label: string; key: string; isNumeric?: boolean; isCurrency?: boolean }[]
  >([]);
  // const [dateRange, setDateRange] = React.useState({
  //   start: new Date(),
  //   end: new Date(),
  // });
  const [start, setStart] = React.useState(new Date());
  const [end, setEnd] = React.useState(new Date());

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');

  const reportKey = query.get('reportKey') ?? '';

  const loadData = async () => {
    setIsSubmitting(true);
    setErrorMessage('');
    try {
      const result = await Feathers.service('reports/v3/rfq').find({
        query: {
          reportKey,
          startDate: start,
          endDate: end,
        },
      });

      setData(result.data);
      setHeaders(result.headers);
    } catch (e) {
      const errMsg = e?.code < 500 ? e?.message : 'Something went wrong';
      setErrorMessage(errMsg);
    }
    setIsSubmitting(false);
  };

  // React.useEffect(() => {
  //   loadData();
  // }, [reportKey]);

  return (
    <Box>
      <Flex mb={16} alignItems={'center'}>
        <Box>
          <Heading fontWeight={'normal'} size="xl">
            Purchasing - {humanize(reportKey)?.toUpperCase()}
          </Heading>
        </Box>
      </Flex>

      <Box backgroundColor={'white'} borderWidth={1} borderColor={'gray.200'} p={8}>
        <Flex
          p={4}
          bgColor={'gray.50'}
          borderWidth={1}
          borderRadius="md"
          borderColor={'gray.200'}
          alignContent={'baseline'}
          alignItems={'flex-end'}
        >
          <DateRangePicker
            startDate={start}
            endDate={end}
            onChange={(v, name) => {
              if (name === 'startDate') {
                setStart(v);
              } else {
                setEnd(v);
              }
            }}
          />
          <Button
            isDisabled={isSubmitting}
            isLoading={isSubmitting}
            ml={4}
            size={'md'}
            colorScheme="blue"
            onClick={() => loadData()}
          >
            Submit
          </Button>
        </Flex>
        {errorMessage && (
          <Alert status="error" my={3}>
            {errorMessage}
          </Alert>
        )}
        {data.length > 0 && (
          <Table mt={4}>
            <Thead>
              <Tr>
                {headers?.map((header, index) => (
                  <Th key={index} isNumeric={header.isNumeric}>
                    {header.label}
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {data?.map((row, index) => (
                <Tr key={index}>
                  {headers?.map((header, idx) => (
                    <Td key={idx} isNumeric={header.isNumeric}>
                      {header.isCurrency
                        ? new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                          }).format(row[header.key] ?? 0.0)
                        : row[header.key]}
                    </Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
          </Table>
        )}
      </Box>
    </Box>
  );
};

export default PurchasingReport;
