import * as React from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { Text, Box, Table, Tbody, Tr, Th, Td, Flex, Spinner, Button } from '@chakra-ui/react';
// import { Segment, Table, Button, Message } from 'semantic-ui-react';
import { useList, PageHeaderRow, EmptyWrapper } from '@inkcloud/components';
import { CSVLink } from 'react-csv';
// import moment from 'moment';

import { FormattedNumber } from 'react-intl';

import format from 'date-fns/format';
import addYears from 'date-fns/addYears';

// import * as numeral from 'numeral';
import { useSelector } from 'react-redux';

import FilterForm from '../FilterForm2';

import Feathers from '../../../bootstrap/feathers';

// interface ListProps {}

const { useState, useEffect } = React;

const headers = [
  { label: 'Organization', key: 'organization' },
  { label: 'Customer', key: 'customer' },
  { label: 'Sales Person', key: 'salesPerson' },
  { label: 'Amount', key: 'amount' },
];

// export const List: React.FunctionComponent<ListProps & RouteComponentProps<any>> = (props) => {
//   const {
//     match: { params },
//     location,
//     history,
//   } = props;

//   const [filter, setFilter] = useState({}) as any;
//   const [data, setData] = useState([]);

//   const loadReport = async (values: any, isExport: boolean) => {
//     const queryParams = {
//       startDate: values.start ? moment(values.start).format('YYYY-MM-DD') : undefined,
//       endDate: values.end ? moment(values.end).format('YYYY-MM-DD') : undefined,
//       stateProvince:
//         values.stateProvince && values.stateProvince.length > 0 ? values.stateProvince : undefined,
//       $export: isExport ? true : undefined,
//     };

//     return await Feathers.service('/reports').find({
//       query: {
//         action: 'customerSales',
//         $populate: ['customer.salesPerson'],
//         ...queryParams,
//       },
//     });
//   };

//   const [isLoading, setIsLoading] = useState(false);

//   useEffect(() => {
//     const loadData = async () => {
//       setIsLoading(true);
//       try {
//         const res = await loadReport(filter, false);
//         setData(res.data);
//         setIsLoading(false);
//       } catch (e) {
//         setIsLoading(false);
//         setData([]);
//       }
//     };

//     loadData();
//   }, [filter]);

//   const mappedData =
//     data &&
//     data.length > 0 &&
//     data.map((d) => ({
//       organization: d.customer && d.customer.organizationName,
//       customer:
//         d.customer &&
//         d.customer.primaryContact &&
//         `${d.customer.primaryContact.firstName} ${d.customer.primaryContact.lastName}`,
//       salesPerson: d.salesPerson && `${d.salesPerson.firstName} ${d.salesPerson.lastName}`,
//       amount: d.revenue,
//     }));
//   const start = filter && filter.start ? moment(filter.start).format('YYYY-MM-DD') : '';
//   const end = filter && filter.end ? moment(filter.end).format('YYYY-MM-DD') : '';

//   return (
//     <>
//       <PageHeaderRow header="Customer Sales" subheader="Report" />

//       <FilterForm
//         value={filter}
//         hasStateProvinceFilter={true}
//         onChange={(v) => setFilter(v)}
//         onReset={() => setFilter({})}
//       />
//       <EmptyWrapper queryResult={data} isStateLoading={isLoading}>
//         <Segment loading={isLoading}>
//           <div style={{ textAlign: 'right' }}>
//             {data && data.length > 0 && (
//               <CSVLink
//                 className="ui button"
//                 data={mappedData}
//                 filename={`${start}-to-${end}-customer-sales-export.csv`}
//                 headers={headers}
//                 target="_blank"
//               >
//                 Export Data
//               </CSVLink>
//             )}
//           </div>
//           <Table size={'small'} sortable>
//             <Table.Header>
//               <Table.Row>
//                 <Table.HeaderCell>Customer</Table.HeaderCell>
//                 <Table.HeaderCell>Sales Person</Table.HeaderCell>
//                 <Table.HeaderCell>Amount</Table.HeaderCell>
//               </Table.Row>
//             </Table.Header>
//             <Table.Body>
//               {data &&
//                 data.length > 0 &&
//                 data.map((r: any, index: number) => (
//                   <Table.Row key={index}>
//                     <Table.Cell>
//                       <div>
//                         <strong>{r.customer && r.customer.organizationName}</strong>
//                       </div>
//                       {r.customer &&
//                         r.customer.primaryContact &&
//                         `${r.customer.primaryContact.firstName} ${r.customer.primaryContact.lastName}`}
//                     </Table.Cell>
//                     <Table.Cell>
//                       {r.salesPerson ? `${r.salesPerson.firstName} ${r.salesPerson.lastName}` : ''}
//                     </Table.Cell>
//                     <Table.Cell>{numeral(r.revenue).format('$0,0.00')}</Table.Cell>
//                   </Table.Row>
//                 ))}
//             </Table.Body>
//           </Table>
//         </Segment>
//       </EmptyWrapper>
//     </>
//   );
// };

const initValue = { start: null, end: null, stateProvince: [] };

export default function SalesTeam() {
  const history = useHistory();

  const tenant = useSelector(
    ({
      globals,
    }: {
      globals: { tenant: { internationalization: { locale: string; currency: string } } };
    }) => globals.tenant.internationalization
  );

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [filter, setFilter] = useState<{
    start: null | Date;
    end: null | Date;
    stateProvince: { label: string; value: string }[];
  }>(initValue);

  const [data, setData] = useState<
    {
      customer?: {
        organizationName?: string;
        primaryContact?: { firstName?: string; lastName?: string };
      };
      salesPerson?: { firstName?: string; lastName?: string };
      revenue: string;
    }[]
  >([]);
  const [errorMessage, setErrorMessage] = useState('');

  const start = filter.start ? format(filter.start, 'yyyy-MM-dd') : undefined;
  const end = filter.end ? format(filter.end, 'yyyy-MM-dd') : undefined;

  const loadData = async () => {
    setErrorMessage('');
    setIsSubmitting(true);
    setData([]);

    const stateProvince = filter.stateProvince?.length
      ? filter.stateProvince?.map((s) => s.value)
      : undefined;

    try {
      const res = await Feathers.service('/reports').find({
        query: {
          action: 'customerSales',
          startDate: start,
          endDate: end,

          stateProvince,
        },
      });
      setData(res?.data);
    } catch (e) {
      const errMsg =
        e?.code < 500 ? e?.message : 'We are experiencing technical difficulties. Please try again';
      setErrorMessage(errMsg);
      setData([]);
    }

    setIsSubmitting(false);
  };

  const maxDate = addYears(filter?.start as Date, 1);
  const exceedDate = filter?.start && filter?.end ? filter.end > maxDate : false;

  useEffect(() => {
    if (filter?.start && filter?.end && !exceedDate) {
      loadData();
    }
  }, [filter]);

  const mappedData =
    data?.map((d) => ({
      organization: d?.customer?.organizationName,
      customer:
        d?.customer?.primaryContact &&
        `${d.customer.primaryContact?.firstName ?? ''} ${
          d.customer.primaryContact?.lastName ?? ''
        }`,
      salesPerson:
        d.salesPerson && `${d.salesPerson?.firstName ?? ''} ${d.salesPerson?.lastName ?? ''}`,
      amount: d.revenue,
    })) || [];

  const handleReset = () => {
    setData([]);
    setFilter(initValue);
  };

  return (
    <>
      <PageHeaderRow header="Customer Sales" subheader="Report">
        <Button onClick={() => history.goBack()} marginRight={start && end ? 2 : undefined}>
          Back
        </Button>
        {start && end && (
          <CSVLink
            className="ui button mini"
            data={mappedData}
            filename={`${start}-${end}-sales-team-export.csv`}
            headers={headers}
            target="_blank"
          >
            Export Data
          </CSVLink>
        )}
      </PageHeaderRow>
      <Box p={3} mb={3} borderWidth={1} borderRadius="md" bg="white">
        <FilterForm
          values={filter}
          hasStateProvince={true}
          isMultiStateProvince={true}
          isSubmitting={isSubmitting}
          errorMessage={errorMessage}
          onChange={(v) => {
            setErrorMessage('');
            setFilter(v);
          }}
          onReset={handleReset}
        />
      </Box>

      <EmptyWrapper queryResult={data} isStateLoading={isSubmitting}>
        <Box p={3} borderWidth={1} borderRadius="md" bg="white">
          <Table>
            <Tbody>
              {data?.map((d, index: number) => (
                <Tr key={index}>
                  <Td>{d?.customer?.organizationName}</Td>
                  <Td>
                    {d?.customer?.primaryContact?.firstName ?? ''}{' '}
                    {d?.customer?.primaryContact?.lastName ?? ''}
                  </Td>
                  <Td isNumeric>
                    <FormattedNumber
                      currency={tenant?.currency}
                      value={Number(d?.revenue ?? 0)}
                      style="currency"
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </EmptyWrapper>
    </>
  );
}
