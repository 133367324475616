import * as React from 'react';
import { Button, Box, ButtonGroup, IconButton, Tooltip } from '@chakra-ui/react';

import { Link, RouteComponentProps } from 'react-router-dom';
import { FormattedDate, FormattedTime } from 'react-intl';

import { FaEdit, FaPencilAlt } from 'react-icons/fa';
// import { useList, PageHeaderRow, PaginatorWrapper } from '@inkcloud/components';
import { useList, ListPage, basicQueryMap } from '@inkcloud/components';
import getFilterDefinition from './FilterForm';
// import { SubMenu } from './../SubMenu';

interface ListProps {}

const serviceName = 'store-front/content/pages';

// const filterQueryMap = (values) => {
//   const filterMap: any = {
//     name: values.name ? { $LIKE: values.name } : undefined,
//     url: values.url ? { $LIKE: values.url } : undefined,
//     template: values.template ? { $LIKE: values.template } : undefined,
//   }

//   return ['type', 'product', 'category'].reduce(
//     (m, field) => ({ ...m, [field]: values[field] ? values[field] : undefined }),
//     filterMap
//   )
// }

const filterQueryMap = (values) => {
  let filterRows = basicQueryMap(values, {});

  filterRows = {
    ...filterRows,
    type: filterRows?.type?.$elemMatch.value,
  };

  return filterRows;
};

const pathPrefix = `/settings/content-management/pages`;

export default function List(props: ListProps & RouteComponentProps) {
  const {
    match: { params },
    location,
    history,
  } = props;

  // const { state, reload, handleDelete, selectedItems, clearAll, setChecked, someSelected, toggleAll, filter, setFilter, sort, handleSort } = useList({
  //   feathers: ['find', serviceName, [{ query: { microsite: params.micrositeId, $sort: { url: 1 }, $select: ['createdAt', 'name', 'url', 'type', 'template'] } }]],
  //   filterMap: filterQueryMap,
  //   debounce: 150,
  //   location,
  //   history,
  //   sort: { createdAt: -1 }
  // })

  const useListValues = useList({
    feathers: [
      'find',
      serviceName,
      [
        {
          query: {
            $sort: { url: 1 },
            $select: ['createdAt', 'name', 'url', 'type', 'template'],
          },
        },
      ],
    ],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  const tableDefintion = [
    {
      key: 'createdAt',
      label: 'Date',
      sortable: true,
      cell: (v) => (
        <Tooltip hasArrow aria-label="time" label={<FormattedTime value={v} />}>
          <Box>
            <FormattedDate value={v} />
          </Box>
        </Tooltip>
      ),
    },
    {
      key: 'name',
      label: 'Name',
      cell: (v) => v,
    },
    {
      key: 'url',
      label: 'URL',
      cell: (v) => v,
    },
    {
      key: 'template',
      label: 'Template',
      cell: (v, r) => v,
    },

    {
      key: '',
      label: '',
      cell: (v, r) => (
        <ButtonGroup spacing={1}>
          <Tooltip label="Edit Details">
            <Link to={`${pathPrefix}/${r._id}`}>
              <IconButton colorScheme="gray" aria-label="Edit" icon={<FaEdit />} />
            </Link>
          </Tooltip>
          <Tooltip label="Editable">
            <Link to={`${pathPrefix}/editable/${r._id}`}>
              <IconButton colorScheme="gray" aria-label="Edit" icon={<FaPencilAlt />} />
            </Link>
          </Tooltip>
        </ButtonGroup>
      ),
      textAlign: 'right',
    },
  ];

  const headerActions = (
    <>
      <Link to={`${pathPrefix}/add`}>
        <Button colorScheme="blue">New</Button>
      </Link>
    </>
  );

  return (
    <Box>
      <ListPage
        header="Pages"
        subheader="Settings"
        useListValues={useListValues}
        history={history}
        pathPrefix={pathPrefix}
        headerActions={headerActions}
        tableDefintion={tableDefintion}
        withCheckboxes={true}
        getFilterDefinition={getFilterDefinition}
      />

      {/* <PageHeaderRow header='Pages' subheader='Settings'>

        <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
        <Link to={`${pathPrefix}/add`}><Button content="New" primary size={'mini'} /></Link>
      </PageHeaderRow>

      <FilterForm products={products} categories={categories} value={filter} onChange={(v) => setFilter(v)} onReset={() => setFilter({})} />

      <Segment loading={state.isLoading}>
        <PaginatorWrapper.Top filter={filter} reload={reload} data={(queryResult as any)} pathPrefix={`${pathPrefix}`} />
        <Table size={'small'} sortable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell><input type="checkbox" onChange={toggleAll} /></Table.HeaderCell>
              <Table.HeaderCell sorted={sort.createdAt ? sort.createdAt : null} onClick={() => handleSort('createdAt')}>
                Created
              </Table.HeaderCell>
              <Table.HeaderCell sorted={sort.name ? sort.name : null} onClick={() => handleSort('name')}>
                Name
              </Table.HeaderCell>
              <Table.HeaderCell sorted={sort.url ? sort.url : null} onClick={() => handleSort('url')}>
                URL
              </Table.HeaderCell>
              <Table.HeaderCell sorted={sort.template ? sort.template : null} onClick={() => handleSort('template')}>
                Template
              </Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {queryResult && (queryResult as any).data.map(r => (
              <Table.Row key={r._id}>
                <Table.Cell>
                  <input type="checkbox" value={r._id} checked={selectedItems[r._id] || false} onChange={setChecked} />
                </Table.Cell>
                <Table.Cell>
                  <FormattedDate value={r.createdAt} />
                </Table.Cell>
                <Table.Cell>
                  {r.name}
                </Table.Cell>

                <Table.Cell>
                  <Label size="tiny" basic >{decodeURIComponent(r.url)}</Label>
                </Table.Cell>
                <Table.Cell>
                  <Label size="tiny" >{r.template}</Label>
                </Table.Cell>
                <Table.Cell textAlign="right">
                  <Popup
                    size='mini'
                    content="Edit"
                    trigger={
                      <Link to={`${pathPrefix}/${r._id}`}>
                        <Button size={'mini'} icon={'edit'} />
                      </Link>
                    }
                  />
                  <Popup
                    size='mini'
                    content="Editable"
                    trigger={
                      <Link to={`${pathPrefix}/editable/${r._id}`}>
                        <Button size={'mini'} icon={'pencil square'} />
                      </Link>
                    }
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <PaginatorWrapper.Bottom reload={reload} data={(queryResult as any)} pathPrefix={`${pathPrefix}`}>
          {someSelected && <Button primary type="button" content="Delete" onClick={handleDelete} />}
        </PaginatorWrapper.Bottom>
      </Segment> */}
    </Box>
  );
}
