/* eslint-disable no-undef */

import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Alert, Box, FormControl, Button, Input, FormLabel, Text } from '@chakra-ui/react';
import icLogo from '../../images/ic-logo.svg';

interface LoginFormProps {
  onSubmit: (values: { email: string; password: string; key?: string }) => void;
  errorMessage: string;
  isSubmitting: boolean;
}

export function LoginForm(props: LoginFormProps) {
  const { errorMessage, isSubmitting, onSubmit } = props;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [key, setKey] = useState('');

  const mode = localStorage.getItem('mode');
  const showKey = window.location.hostname === 'localhost';

  const handleModeToggle = (e, data) => {
    if (data.checked) {
      localStorage.setItem('mode', 'sandbox');
      return window.location.reload();
    }

    localStorage.setItem('mode', 'live');
    return window.location.reload();
  };

  return (
    <div>
      <form
        className="ui segment raised top attached form"
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit({ email, password, key });
        }}
      >
        <div style={{ textAlign: 'center', marginBottom: 18 }}>
          <object width="100" style={{ margin: 'auto' }} type="image/svg+xml" data={icLogo}>
            SVG not supported
          </object>
          <br />
        </div>
        <FormControl mb={3}>
          <FormLabel>Email address</FormLabel>
          <Input type="email" onChange={(e) => setEmail(e.target.value)} />
        </FormControl>

        <FormControl mb={3}>
          <FormLabel>Password</FormLabel>
          <Input type="password" onChange={(e) => setPassword(e.target.value)} />
        </FormControl>
        {showKey && (
          <FormControl mb={3}>
            <FormLabel>Key</FormLabel>
            <Input onChange={(e) => setKey(e.target.value)} />
          </FormControl>
        )}
        {errorMessage && (
          <Alert status="error" mb={3}>
            {errorMessage}
          </Alert>
        )}
        <Box mb={3}>
          <Text fontSize="sm">
            <Link to="/forgot-password">Forgot Password?</Link>
          </Text>
        </Box>
        <Button
          colorScheme="blue"
          isDisabled={isSubmitting}
          isLoading={isSubmitting}
          type="submit"
          width="full"
        >
          Login
        </Button>
      </form>
    </div>
  );
}
