import React, { useState, FC, memo, useEffect } from 'react';
import {
  PageHeaderRow,
  PaginatorWrapper,
  GenericFilter,
  basicQueryMap,
} from '@inkcloud/components';

import { Link, useHistory, useLocation } from 'react-router-dom';
import { FormattedDate, FormattedTime } from 'react-intl';
import {
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  Spinner,
  Flex,
  useCheckbox,
  Text,
  Button,
  IconButton,
  Icon,
  Switch,
  HStack,
  FormControl,
  FormLabel,
  SimpleGrid,
  Heading,
  Divider,
} from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { RiSwapBoxLine, RiCheckboxLine, RiCheckboxBlankLine } from 'react-icons/ri';

import { Select, Options } from 'chakra-react-select';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  Header,
  Table as TableType,
  Column,
  RowData,
  ColumnOrderState,
} from '@tanstack/react-table';
import type { ModelTypes } from '@inkcloud/icapi-types';

import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import feathers from '../../../bootstrap/feathers';

import { StatusChangerModal } from '../../../common/StatusChangerModal';

import { getFilterDefinition } from './FilterDefinition';

import OrderItemModal from './OrderItemModal';

type gridColumnType = { id: string; _id: string; name: string; priority: number };
interface ITestWorkflowTableProps {}

interface WorkflowRow extends ModelTypes.OrderItems {
  statusChanger: string;
  stagingLocation: string;
}

declare module '@tanstack/table-core' {
  interface ColumnMeta<TData extends RowData, TValue> {
    status?: string;
    name?: string;
    bg?: string;
  }
}

interface ICustomCheckboxProps {
  columnIds?: string[];
  checked: boolean;
  text: string;
  onChange: () => void;
}

const checkValidValues = (filterRow, valueToCheck) => {
  const values =
    filterRow &&
    Object?.values(filterRow)
      ?.concat.apply([], filterRow)
      ?.filter((f) => f !== valueToCheck)
      ?.reduce(
        (m, field) => ({ ...m, [field]: values[field] ? values[field] : undefined }),
        filterRow
      );

  return values;
};

export const filterQueryMap = (values) => {
  let filterRows = basicQueryMap(values, {
    name: 'staticName',
  });

  // Map attributes as needed by API
  const prefix = 'attributes:';
  const attributes = Object.keys(filterRows)
    .filter((r) => r.startsWith(prefix))
    .reduce((acc, cur) => ({ ...acc, [cur.slice(prefix.length)]: filterRows[cur]?.$in }), {});

  // Delete the original attribute valuess so they are not submitted
  Object.keys(filterRows)
    .filter((r) => r.startsWith(prefix))
    .forEach((r) => delete filterRows[r]);

  filterRows = {
    ...filterRows,
    addOns: filterRows.addOns ? { $elemMatch: { addOnId: filterRows.addOns } } : undefined,
    attributes,
    // parent: filterRows.type === 'stock-child' ? true : filterRows.type === 'stock-parent' ? { $ne: true } : undefined,
    // approvalStatus:
    //   filterRows?.approvalStatus?.$elemMatch?.value === 'pending'
    //     ? 'pending'
    //     : filterRows?.approvalStatus?.$elemMatch?.value === 'rejected'
    //     ? 'rejected'
    //     : { $ne: 'pending' },
    // metadata: filterRows.metadata ? { $elemMatch: { key: filterRows.metadata?.key, value: filterRows.metadata?.value }} : undefined,
    type: undefined,
  };

  return filterRows;
};

const CustomCheckbox = memo((props: ICustomCheckboxProps) => (
  <Box
    as="label"
    display="flex"
    flexDirection="row"
    alignItems="start"
    gridColumnGap={2}
    px={3}
    py={1}
    cursor="pointer"
  >
    <input type="checkbox" onChange={() => props.onChange()} hidden />
    {props?.checked ? (
      <Icon as={RiCheckboxLine} w={6} h={6} color="blue.500" />
    ) : (
      <Icon as={RiCheckboxBlankLine} w={6} h={6} color="blue.500" />
    )}
    <Text color="gray.700" fontSize="sm">
      {props?.text ?? ''}
    </Text>
  </Box>
));

let colorArray = {};

const reorderColumn = (
  draggedColumnId: string,
  targetColumnId: string,
  columnOrder: string[]
): ColumnOrderState => {
  columnOrder.splice(
    columnOrder.indexOf(targetColumnId),
    0,
    columnOrder.splice(columnOrder.indexOf(draggedColumnId), 1)[0] as string
  );

  return [...columnOrder];
};
const fixedColumns = [
  'humanId',
  'quantity',
  'statusChanger',
  'createdAt',
  'estimatedCompletionDate',
  'stagingLocation',
];

const DraggableColumnHeader: FC<{
  header: Header<ModelTypes.JobStatuses, unknown>;
  table: TableType<any>;
}> = ({ header, table }) => {
  const { getState, setColumnOrder } = table;
  const { columnOrder } = getState();
  const { column } = header;

  const [, dropRef] = useDrop({
    accept: 'column',
    drop: (draggedColumn: Column<ModelTypes.JobStatuses>) => {
      const newColumnOrder = reorderColumn(draggedColumn.id, column.id, columnOrder);
      setColumnOrder(newColumnOrder);
    },
  });

  const [{ isDragging }, dragRef, previewRef] = useDrag({
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    item: () => column,
    type: 'column',
  });

  // console.log({ isDragging })

  return (
    <Th ref={dropRef} style={{ opacity: isDragging ? 0.5 : 1 }} bg={'white'}>
      <div ref={previewRef}>
        <button ref={dragRef}>🟰</button>
        {header.isPlaceholder
          ? null
          : flexRender(header.column.columnDef.header, header.getContext())}
      </div>
    </Th>
  );
};

const columnHelper = createColumnHelper<WorkflowRow>();

function buildColumns(statuses: gridColumnType[] | undefined, bgColors: string[]) {
  const sortedStatuses = statuses?.sort((a, b) => a.priority - b.priority);

  const columns = sortedStatuses?.map((status, idx) =>
    columnHelper.accessor(status._id as any, {
      id: status._id as string,
      cell: (info) => {
        const rowOriginal = info.row.original as any;
        const foundLog = rowOriginal.statusLog?.findLast((s) => s.status?.jobStatus === status.id);

        if (foundLog) {
          return (
            <>
              {foundLog?.createdBy?.firstName ?? ''} {foundLog?.createdBy?.lastName ?? ''}
            </>
          );
        }

        return null;
      },
      header: () => <span>{status.name}</span>,
      meta: {
        status: status.id,
        name: status.name,
        bg: bgColors?.[idx] ?? 'transparent',
      },
    })
  );

  return [
    columnHelper.accessor('statusChanger', {
      id: 'statusChanger',
      cell: (info) => info.getValue(),
      header: () => <span></span>,
    }),
    columnHelper.accessor('humanId', {
      id: 'humanId',
      cell: (info) => <Link to={`/orders/${info.row.original?.order}`}>{info.getValue()}</Link>,
      header: () => (
        <>
          <span>Order #</span>
        </>
      ),
    }),
    columnHelper.accessor('createdAt', {
      id: 'createdAt',
      cell: (info) => (
        <>
          <FormattedDate value={info.getValue()} /> <FormattedTime value={info.getValue()} />
        </>
      ),
      header: () => <span>Order Date</span>,
    }),
    columnHelper.accessor('estimatedCompletionDate', {
      id: 'estimatedCompletionDate',
      cell: (info) => (
        <>
          <FormattedDate value={info.getValue()} />
        </>
      ),
      header: () => <span>Due Date</span>,
    }),
    columnHelper.accessor('quantity', {
      id: 'quantity',
      cell: (info) => info.getValue(),
      header: () => <span>QTY</span>,
    }),
    columnHelper.accessor('customerJobName', {
      id: 'customerJobName',
      cell: (info) => info.getValue(),
      header: () => <span>Ref</span>,
      meta: {
        name: 'Ref',
      },
    }),
    columnHelper.accessor('productKey', {
      id: 'productKey',
      cell: (info) => info.getValue(),
      header: () => <span>SKU</span>,
      meta: {
        name: 'SKU',
      },
    }),

    ...(columns ?? []),

    columnHelper.accessor('stagingLocation', {
      id: 'stagingLocation',
      cell: (info) => info.getValue(),
      header: () => <span>Staging Location</span>,
      enablePinning: true,
    }),
  ];
}

interface IListProps {}

const limit = 50;

const pathPrefix = `/production/status-grid`;

const List: React.FunctionComponent<IListProps> = (props) => {
  const [tableInfo, setTableInfo] = React.useState({ data: [], columns: [] });
  const [columnOrder, setColumnOrder] = React.useState<ColumnOrderState>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [columnVisibility, setColumnVisibility] = useState<Record<string, boolean>>({});
  // console.log('###### being rendered');
  const [isColumnToggleOpen, setIsColumnToggleOpen] = useState(false);

  const [filter, setFilter] = useState<any>({
    rows: [{ selectedFact: 'customerJobName', selectedOperator: 'is', value: '' }],
  });

  const { search } = useLocation();
  const history = useHistory();

  const page = Number(search?.replace(/[^0-9\.]+/g, ''));

  const skip = (page > 0 ? page - 1 : 0) * limit;

  const [queryResult, setQueryResult] = useState<any>({});

  const [stagingLocations, setStagingLocations] = useState<
    ModelTypes.FulfillmentStagingLocations[]
  >([]);

  const [gridViewOptions, setGridViewOptions] = useState<
    { label: string; value: string; columns: []; filter: {} }[]
  >([]);
  const [selectedView, setSelectedView] = useState({
    label: '',
    value: '',
    columns: [],
    filter: {},
  });

  const [selectedItem, setSelectedItem] = useState<ModelTypes.OrderItems>('');
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
  const [isReload, setIsReload] = useState(false);

  const loadStagingLocations = async () => {
    const res = await feathers.service('fulfillment-staging-locations').find({ query: {} });
    setStagingLocations(res?.data);
  };

  useEffect(() => {
    const loadViews = async () => {
      const res = await feathers.service('data-views').find({
        query: {
          viewType: 'grid',
        },
      });

      setGridViewOptions(
        res?.data?.map((v) => ({
          label: v.name,
          value: v._id,
          columns: v.columns,
          filter: v.filter,
        }))
      );
    };

    loadStagingLocations();
    loadViews();
  }, []);

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);

      const foundColumn: gridColumnType[] | undefined = selectedView.columns;

      const mappedColumn = foundColumn?.map((c: any) => c.id) || [];

      // console.log({ foundColumn, mappedColumn });

      try {
        const orderItems = await feathers.service('order-items').find({
          query: {
            $select: [
              'createdAt',
              'category',
              'estimatedCompletionDate',
              'quantity',
              'productionStatus',
              'humanId',
              'metadata',
              'customerJobName',
              'order',
              'productKey',
            ],
            $populate: ['category'],
            $populateStatusHistory: 1,
            'productionStatus.jobStatus': {
              $in: mappedColumn,
            },
            productionChannel: 'print',
            ...filterQueryMap(filter),
            ...filterQueryMap(selectedView?.filter),
            $skip: skip,
            $limit: limit,
            $sort: { estimatedCompletionDate: 1, humanId: 1 },
          },
        });

        if (!(history.location.state as any)?.bgColors?.length) {
          const bgColors = foundColumn?.map((_) => `hsl(${Math.random() * 360}, 100%, 75%)`);
          history.push({ ...history.location, state: { bgColors } });
        }

        const columns = buildColumns(foundColumn, (history.location.state as any)?.bgColors ?? []);

        setQueryResult(orderItems);

        let resStagingLocations;

        if (isReload) {
          resStagingLocations = await feathers
            .service('fulfillment-staging-locations')
            .find({ query: {} });
          setStagingLocations(resStagingLocations?.data);
        }

        // console.log('resStagingLocations?.data', resStagingLocations?.data)

        const updatedOrderItems = orderItems.data?.map((item) => {
          const found = (resStagingLocations?.data ?? stagingLocations)?.filter((s) =>
            s?.contains?.find((c) => c.orderItem === item._id)
          );

          // if (found?.[0]?.contains?.find((c) => c.orderItem === '6409643d7e59444ddb82a6a4')) {
          //   console.log('found', found)
          // }

          const groupBy =
            item?.metadata?.find((m) => m.key === 'Misc03')?.value || item?.category?.name;

          colorArray[groupBy] = colorArray[groupBy] || `hsl(${Math.random() * 360}, 100%, 75%)`;

          const updatedItem = {
            ...item,
            stagingLocation: found?.[0]?.name,
            stagingLocationId: found?.[0]?._id,
            stagingLocationQty:
              found?.[0]?.contains?.find((c) => c.orderItem === item._id)?.quantity ??
              item?.quantity,
            stagingLocationContainId: found?.[0]?.contains?.find((c) => c.orderItem === item._id)
              ?._id,
          };

          if (isStatusModalOpen && selectedItem._id === item._id) {
            setSelectedItem(updatedItem);
          }

          return updatedItem;
        });

        // console.log('updatedOrderItems', updatedOrderItems)

        setTableInfo({
          columns,
          data: updatedOrderItems,
        });

        setColumnOrder(columns?.map((m) => m.id));
      } catch (e) {
        console.error(e);
      }

      setIsReload(false);
      setIsLoading(false);
    };

    if ((selectedView?.value && gridViewOptions?.length) || isReload || filter?.rows?.[0]?.value) {
      // loadStagingLocations()
      loadData();
    }
  }, [isReload, search, selectedView?.value, gridViewOptions?.length, filter]);

  const table = useReactTable({
    data: tableInfo.data,
    columns: tableInfo.columns,
    state: {
      columnOrder,
      columnVisibility,
    },
    onColumnVisibilityChange: setColumnVisibility,
    onColumnOrderChange: setColumnOrder,
    getCoreRowModel: getCoreRowModel(),
  });

  // const handleLocalStorage = (isVisible: boolean, columnId: string) => {
  //   const localStoredIds: string[] = JSON.parse(localStorage.getItem('statusGridIds') || '[]')
  //   const hasStoredIds = localStoredIds?.length

  //   const newIds = hasStoredIds ? localStoredIds : columnOrder

  //   setColumnVisibility((prev) => ({ ...prev, [columnId]: !isVisible }))

  //   const ids = isVisible ? newIds.filter((f) => f !== columnId) : [...newIds, columnId]
  //   localStorage.setItem('statusGridIds', JSON.stringify(ids))
  // }

  // console.log(filterQueryMap(filter))

  // console.log('selectedItem', selectedItem)

  console.log({ colorArray });

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <Box>
          <PageHeaderRow header="Status Grid" subheader="Production" />
          <br />

          <Box p={3} bg="white" borderRadius="md">
            <Box position="relative" zIndex="dropdown">
              <Select
                useBasicStyles
                options={gridViewOptions}
                onChange={(v) => {
                  setSelectedView(v);
                  history.replace({
                    search: `?page=1`,
                  });
                }}
              />

              {selectedView?.value && gridViewOptions?.length && (
                <Box px={3} mt={4} mx={-3}>
                  <GenericFilter
                    factDefinitionFunction={getFilterDefinition}
                    value={filter}
                    onChange={(v) => {
                      setFilter({ ...v });
                    }}
                  />
                </Box>
              )}
            </Box>

            {isLoading ? (
              <Flex justify="center" my={3}>
                <Spinner />
              </Flex>
            ) : (
              <>
                {tableInfo?.data?.length > 0 && (
                  <>
                    <Button
                      mt={2}
                      variant="ghost"
                      colorScheme="blue"
                      leftIcon={isColumnToggleOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                      onClick={() => setIsColumnToggleOpen(!isColumnToggleOpen)}
                    >
                      Column Visibility
                    </Button>
                    {/* <HStack spacing={8} mt={5}> */}

                    {isColumnToggleOpen && (
                      <Box bgColor={'gray.50'} p={8}>
                        <Heading size={'sm'}>Column Visiblity</Heading>
                        <Divider mb={4} />
                        <SimpleGrid minChildWidth="120px" spacing="30px">
                          {table
                            .getAllLeafColumns()
                            .filter((c) => (c as any)?.columnDef?.id !== 'statusChanger')
                            .map((column) => (
                              // <Box key={column.id} width={100}>
                              <FormControl
                                size={'sm'}
                                key={column.id}
                                display="flex"
                                alignItems="center"
                              >
                                <FormLabel size={'sm'} htmlFor="email-alerts" mb="0">
                                  {(column as any)?.columnDef?.header()}
                                </FormLabel>
                                <Switch
                                  size={'sm'}
                                  {...{
                                    isChecked: column.getIsVisible(),
                                    onChange: column.getToggleVisibilityHandler(),
                                  }}
                                />
                              </FormControl>
                              // </Box>
                            ))}
                        </SimpleGrid>
                      </Box>
                    )}
                  </>
                )}
                <Box mt={tableInfo?.data?.length ? 5 : 0} overflowY="auto" maxHeight="750px">
                  <TableContainer overflowX="unset" overflowY="unset">
                    <Table size={'sm'}>
                      <Thead position={'sticky'} top={0} zIndex={'docked'}>
                        {/* <Tr bg="white">
                          {table.getAllLeafColumns().map((column) => (
                            <Th key={column.id}>
                              <Switch
                                {...{
                                  isChecked: column.getIsVisible(),
                                  onChange: column.getToggleVisibilityHandler(),
                                }}
                              />
                              {(column as any)?.columnDef?.header()}
                            </Th>
                          ))}
                        </Tr> */}
                        {table.getHeaderGroups().map((headerGroup) => (
                          <Tr key={headerGroup.id}>
                            {headerGroup.headers.map((header) => {
                              if (fixedColumns.includes(header.column.id)) {
                                return (
                                  <Th key={header.id} w="70px" bg={'white'}>
                                    {header.isPlaceholder
                                      ? null
                                      : flexRender(
                                          header.column.columnDef.header,
                                          header.getContext()
                                        )}
                                  </Th>
                                );
                              }
                              return (
                                <DraggableColumnHeader
                                  key={header.id}
                                  header={header as never}
                                  table={table as never}
                                />
                              );
                            })}
                          </Tr>
                        ))}
                      </Thead>

                      <Tbody>
                        {table.getRowModel().rows.map((row) => (
                          <Tr key={row.id}>
                            {row.getVisibleCells().map((cell) => {
                              const noBg = ['humanId', 'metaCode'];

                              if (cell.column.id === 'statusChanger') {
                                return (
                                  <Td key={cell.id}>
                                    <IconButton
                                      aria-label="Change status"
                                      icon={<RiSwapBoxLine />}
                                      type="button"
                                      onClick={() => {
                                        setSelectedItem(cell.row.original);
                                        setIsStatusModalOpen(true);
                                      }}
                                    />
                                  </Td>
                                );
                              }

                              const cellOriginal = cell.row.original as any;

                              const foundLog = cellOriginal.statusLog?.find(
                                (s) => s.status?.jobStatus === cell.column?.columnDef?.meta?.status
                              );

                              const colorBy =
                                cellOriginal?.metadata?.find((m) => m?.key === 'Misc03')?.value ||
                                cellOriginal?.category?.name;

                              const { bg: bgMeta, status } = cell.column?.columnDef?.meta ?? {};

                              let bg = 'transparent';
                              if (
                                colorArray[colorBy] &&
                                status === cellOriginal.productionStatus?.jobStatus
                              ) {
                                bg = colorArray[colorBy];
                                // if (status === cellOriginal.productionStatus?.jobStatus) {
                                // bg = 'blue.300';
                              } else if (foundLog?.status?.jobStatus === status) {
                                bg = 'blue.50';
                              } else {
                                bg = 'transparent';
                              }

                              // = cell.column?.columnDef?.meta?.status === cellOriginal.productionStatus?.jobStatus ? bgMeta : 'gray.200';
                              // const bg =
                              //   foundLog?.status?.jobStatus === status ? bgMeta : 'transparent'

                              const isFixed = [...fixedColumns, 'customerJobName'].includes(
                                cell.column.id
                              );

                              return (
                                <Td key={cell.id} bg={bg}>
                                  {foundLog?.status?.jobStatus === status || isFixed
                                    ? flexRender(cell.column.columnDef.cell, cell.getContext())
                                    : null}
                                </Td>
                              );
                            })}
                            .
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </Box>

                <Box mt={3}>
                  <PaginatorWrapper.Bottom
                    reload={() => setIsReload(true)}
                    data={queryResult as any}
                    pathPrefix={`${pathPrefix}`}
                  />
                </Box>
              </>
            )}
          </Box>

          {/* <div>{JSON.stringify(data)}</div>
        <div className="h-4" />
        <button onClick={() => rerender()} className="border p-2">
          Rerender3
        </button> */}
        </Box>
      </DndProvider>
      {isStatusModalOpen && (
        <OrderItemModal
          key={selectedItem?._id}
          orderItem={selectedItem}
          stagingLocations={stagingLocations}
          onClose={() => {
            setSelectedItem('');
            setIsStatusModalOpen(false);
          }}
          onReload={() => setIsReload(true)}
        />
      )}
    </>
  );
};

export default List;
