import React from 'react';
import { Text, Image, View, StyleSheet } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import { ColumnDef } from './Table';

const builtInStyles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    fontSize: 12,
    alignItems: 'flex-start',
    minHeight: 30,
  },
  py: {
    paddingLeft: 6,
    paddingRight: 6,
  },
});

interface ITableRow<T> {
  styles?: { [key: string]: Style | Style[] };
  definition: ColumnDef<T>[];
  items: any[];
  locale: string;
  currency: string;
}

export default function TableRow<T>(props: ITableRow<T>) {
  const { styles = {}, definition, items, locale, currency } = props;
  const { containerStyle = {} } = styles;

  return (
    <View>
      {items?.map((i, idx) => (
        <View key={idx} style={builtInStyles.container}>
          {definition.map((d, idx2) => {
            if (d.type === 'image') {
              return i?.[d?.key] ? (
                <View
                  key={idx2}
                  style={{
                    width: d?.width,
                    maxWidth: '100%',
                    ...builtInStyles.py,
                    textAlign: 'center',
                  }}
                >
                  <Image style={{ maxWidth: '100%', maxHeight: 'auto' }} src={i?.[d?.key]} />
                </View>
              ) : (
                <Text
                  key={idx2}
                  style={[d?.style, { ...builtInStyles.py, width: d?.width }]}
                ></Text>
              );
            }

            if (d.type === 'text-currency') {
              const value = new Intl.NumberFormat(locale, { style: 'currency', currency }).format(
                i?.[d?.key] ?? 0.0
              );

              return (
                <Text key={idx2} style={[d?.style, { ...builtInStyles.py, width: d?.width }]}>
                  {value}
                </Text>
              );
            }

            return (
              <Text key={idx2} style={[d?.style, { ...builtInStyles.py, width: d?.width }]}>
                {i?.[d?.key] ?? ''}
              </Text>
            );
          })}
        </View>
      ))}
    </View>
  );
}
