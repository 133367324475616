import * as React from 'react';
import { Button, Form, Input, Segment, Image, Message, Header } from 'semantic-ui-react';
import { Formik, Form as FormikForm, Field, FieldProps, FieldArray, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';

import { SemanticField } from '../../../../common/Form';

import { ImageUploadModal } from '../banners/ImageUploadModal';

interface ThemeFormProps {
  isSubmitting: boolean;
  errorMessage: string;
  initialValues: any;
  onSubmit: (v: any) => void;
}

const { useState, useEffect } = React;

const ThemeSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  Theme: Yup.string().required('Required'),
  altText: Yup.string().required('Required'),
  priority: Yup.number().typeError('Priority must be a number'),
});

export const ThemeForm: React.FunctionComponent<ThemeFormProps> = (props) => {
  const { initialValues } = props;
  const [fieldName, setFieldName] = useState(null);
  const [isOpenModal, setIsOpenModal] = useState(false);

  let logoUrl = '';
  let faviconUrl = '';
  return (
    <>
      <Formik
        initialValues={initialValues}
        // validationSchema={ThemeSchema}
        onSubmit={(values, { setSubmitting }) => {
          props.onSubmit(values);
        }}
      >
        {(formProps) => {
          const {
            values,
            touched,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            setFieldValue,
            setFieldTouched,
          } = formProps;

          // console.log('values', values);

          if (typeof values.logo === 'string') {
            logoUrl = values.logoUrl;
          } else {
            logoUrl = values.logo?.publicUrl ?? '';
          }
          if (typeof values.favicon === 'string') {
            faviconUrl = values.faviconUrl;
          } else {
            faviconUrl = values.favicon?.publicUrl ?? '';
          }

          return (
            <FormikForm
              className={`ui form ${props.isSubmitting ? 'loading' : ''} ${
                props.errorMessage ? 'error' : ''
              }`}
            >
              <Header attached="top">Logo</Header>
              <Segment attached="bottom">
                <Form.Field>
                  <div>
                    {values.logo && (
                      <Image
                        bordered
                        size="medium"
                        centered
                        label={{
                          as: 'a',
                          corner: 'left',
                          icon: 'check circle outline',
                        }}
                        src={`https://storage.googleapis.com/icstorage/${logoUrl}`}
                      />
                    )}
                  </div>
                </Form.Field>
                <ErrorMessage
                  render={(msg) => <Message negative size="tiny" content={msg} />}
                  name="logo"
                />
                <Form.Group widths="equal">
                  <Form.Field>
                    <Button
                      type="button"
                      primary
                      content="Upload/Select Image"
                      onClick={() => {
                        setFieldName('logo');
                        setIsOpenModal(true);
                      }}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <SemanticField
                      name="dashboardCustomLogo"
                      label="Use logo in dashboard"
                      component={Form.Checkbox}
                    />
                  </Form.Field>
                </Form.Group>
              </Segment>

              <Header attached="top">Favicon</Header>
              <Segment attached="bottom">
                <Form.Field>
                  <div>
                    {values?.favicon && (
                      <Image
                        bordered
                        size="medium"
                        centered
                        label={{
                          as: 'a',
                          corner: 'left',
                          icon: 'check circle outline',
                        }}
                        src={`https://storage.googleapis.com/icstorage/${faviconUrl}`}
                      />
                    )}
                  </div>
                </Form.Field>
                <ErrorMessage
                  render={(msg) => <Message negative size="tiny" content={msg} />}
                  name="favicon"
                />
                <Form.Group widths="equal">
                  <Form.Field>
                    <Button
                      type="button"
                      primary
                      content="Upload/Select Image"
                      onClick={() => {
                        setFieldName('favicon');
                        setIsOpenModal(true);
                      }}
                    />
                  </Form.Field>
                </Form.Group>
              </Segment>
              {props.errorMessage && <Message error content={props.errorMessage} />}
              <Form.Field>
                <Button primary type="submit" content="Submit" disabled={props.isSubmitting} />
              </Form.Field>
              {isOpenModal && (
                <ImageUploadModal
                  isOpen={isOpenModal}
                  onClose={() => {
                    setFieldName(null);
                    setIsOpenModal(false);
                  }}
                  setFieldValue={setFieldValue}
                  fieldName={fieldName}
                />
              )}
            </FormikForm>
          );
        }}
      </Formik>
    </>
  );
};
