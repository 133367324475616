import * as React from 'react';
import { Modal, Button, Divider, Form, Message } from 'semantic-ui-react';

const { useState } = React;

interface CostCenterUpdateModalProps {
  open: boolean;
  onClose: () => void;
  costCenter: string;
  onSubmit: (value: string) => void;
  errorMessage: string;
  isSubmitting?: boolean;
}

export const CostCenterUpdateModal: React.FunctionComponent<CostCenterUpdateModalProps> = ({
  open,
  onClose,
  costCenter,
  onSubmit,
  errorMessage,
  isSubmitting,
}) => {
  const [newCostCenter, setNewCostCenter] = useState('');

  return (
    <Modal size={'mini'} open={open} onClose={onClose}>
      <Modal.Header>Update Cost Center</Modal.Header>
      <Modal.Content>
        {costCenter && (
          <>
            <div>Current Cost Center: {costCenter}</div>
            <Divider />
          </>
        )}
        <Form.Field>
          <Form.Input
            fluid
            placeholder="New Cost Center..."
            value={newCostCenter}
            onChange={(e, data) => setNewCostCenter(data.value)}
          />
        </Form.Field>
        {errorMessage && <Message error content={errorMessage} />}
      </Modal.Content>
      <Modal.Actions>
        <Button negative onClick={onClose}>
          Cancel
        </Button>
        <Button
          positive
          size="mini"
          content="Update"
          loading={isSubmitting}
          disabled={isSubmitting}
          onClick={() => onSubmit(newCostCenter)}
        />
      </Modal.Actions>
    </Modal>
  );
};
