// import * as React from 'react'
// import { RouteComponentProps } from 'react-router-dom'
// import {
//   Formik,
//   Form as FormikForm,
//   Field,
//   FieldArray,
//   FieldProps,
//   ErrorMessage
// } from "formik";
// import {
//   Segment,
//   Form,
//   Message,
//   Button,
//   Menu,
//   Checkbox,
//   Dropdown
// } from "semantic-ui-react";
// import * as Yup from 'yup';
// import { SemanticField } from '../../../common/Form';
// import { NotificationForm } from './NotificationForm'
// import { humanize } from "underscore.string";
// import Feathers from '../../../bootstrap/feathers';

// interface NotificationRulesFormProps {
//   initialValues: any;
//   responseMessage: string;
//   errorMessage: any;
//   isLoading?: boolean;
//   templateKeys?: any[];
//   onSubmit: (v: any, key: string) => void;
// }

// const initialMapper = (values) => {

//   return {
//     ...values,
//     defaultEmail: {
//       email: values?.defaultEmail?.email || '',
//       name: values?.defaultEmail?.name || '',
//     },
//     advanced: !!values?.replyTo || !!values?.defaultEmail?.email || !!values?.defaultEmail?.name
//   }
// }

// const { useState, useEffect } = React

// export const NotificationRulesForm: React.FunctionComponent<NotificationRulesFormProps> = props => {
//   const { initialValues, errorMessage, responseMessage, isLoading, onSubmit } = props as any

//   const [validateError, setValidateError] = useState('')
//   const [templateKeyOptions, setTemplateKeyOptions] = useState([])

//   const NotificationSchema = Yup.object().shape({
//     templateKey: Yup.string()
//       .required('Required')
//       .test('is-not-used', `Key is already in use`, function (value) {
//         if (props.initialValues && (props.initialValues._id && (props.initialValues.templateKey === value))) {
//           return Promise.resolve(true);
//         }

//         return Feathers.service<any>(`/tenant-settings/notification-settings`)
//           .find({ query: { templateKey: value } })
//           .then((results: any) => results.result === false)
//           .catch(e => {
//             if (e.code && e.code < 500) {
//               setValidateError(e.message)
//             }
//             setValidateError('We are experiencing technical difficulties. Please try again')
//           })
//       }),
//     replyTo: Yup.string().email('Must be valid email'),
//     defaultEmail: Yup.object().shape({
//       email: Yup
//         .string()
//         .email('Must be valid email')
//         .when('name', {
//           is: value => value && value.length > 0,
//           then: Yup.string().required(
//             "Default from email required when setting a default from name"
//           ),
//           otherwise: Yup.string().email('Must be valid email')
//         }),
//       name: Yup
//         .string()
//         .when('email', {
//           is: value => value && value.length > 0,
//           then: Yup.string().required(
//             "Default from name required when setting a default from email"
//           ),
//           otherwise: Yup.string()
//         })
//     }, ['name', 'email']),
//     recipients: Yup.array().of(
//       Yup.object().shape({
//         email: Yup.string().required('Required email'),
//         name: Yup.string().required('Required name')
//       })
//     ),
//     cc: Yup.array().of(
//       Yup.object().shape({
//         email: Yup.string().required('Required email'),
//         name: Yup.string().required('Required name')
//       })
//     ),
//     bcc: Yup.array().of(
//       Yup.object().shape({
//         email: Yup.string().required('Required email'),
//         name: Yup.string().required('Required name')
//       })
//     ),
//     enable: Yup.boolean()
//   });

//   const validateKey = async (value) => {

//     let error;
//     if (!value) {
//       error = 'Required'
//     } else if (initialValues && (initialValues._id && (initialValues.templateKey === value))) {
//       return Promise.resolve(true);
//     } else {

//       try {
//         const res = await Feathers.service(`/tenant-settings/notification-settings`).find({ query: { templateKey: value } })

//         if (res && res.result !== undefined && res.result === false) {
//           error = ''
//         } else {
//           error = 'Key is already in use'
//         }
//       } catch (e) {
//         if (e.code && e.code < 500) {
//           setValidateError(e.message)
//         }
//         setValidateError('We are experiencing technical difficulties. Please try again')
//       }
//     }

//     return error;
//   }

//   useEffect(() => {
//     const loadKeys = async () => {
//       try {
//         const res: any = await Feathers.service('/notification/template-list?keysOnly=1&filter=customer').create({}, { query: {} })

//         setTemplateKeyOptions(res && res.length > 0 && res.map(t => ({ key: t, value: t, text: t ? humanize(t) : '' })))
//       } catch (e) {
//         // setErrorMessage({ ...errorMessage, keys: e.code && e.code < 500 ? e.message : 'We are experiencing technical difficulties. Please try again' })
//       }
//     }

//     loadKeys()
//   }, [])

//   return (
//     <Segment>
//       {
//         validateError && <Message error content={validateError} />
//       }
//       <Formik
//         initialValues={initialMapper(initialValues)}
//         enableReinitialize={true}
//         validationSchema={NotificationSchema}
//         onSubmit={(values: any) => {
//           if (!values.advanced) {
//             delete values?.replyTo
//             delete values?.defaultEmail
//           }
//           onSubmit(values)
//         }}
//       >
//         {props => {
//           const {
//             values,
//             touched,
//             errors,
//             dirty,
//             isSubmitting,
//             handleChange,
//             handleBlur,
//             handleSubmit,
//             handleReset,
//             setFieldValue,
//             setFieldTouched
//           } = props as any;

//           return (
//             <FormikForm className={`ui form ${isLoading && "loading"}`}>
//               <Form.Group widths='equal'>
//                 <Form.Field>
//                   <label>Notification Type</label>
//                   <Field
//                     name='templateKey'
//                     render={({ field }: FieldProps<any>) => (
//                       <>
//                         <Dropdown
//                           fluid selection search lazyLoad
//                           placeholder='Key'

//                           {...field}
//                           options={templateKeyOptions}
//                           onChange={(e, data) => field.onChange({ target: { value: data.value, name: 'templateKey' } } as any)}
//                         />
//                         {(errors.templateKey && errors.templateKey !== true) && <Message negative size="tiny" content={errors.templateKey} />}
//                       </>
//                     )}
//                   />
//                 </Form.Field>
//               </Form.Group>

//               <NotificationForm
//                 values={values && values.recipients}
//                 action='recipients'
//                 title='Recipients'
//                 setFieldValue={setFieldValue}
//               />
//               <NotificationForm
//                 values={values && values.cc}
//                 action='cc'
//                 title='CC'
//                 setFieldValue={setFieldValue}
//               />
//               <NotificationForm
//                 values={values && values.bcc}
//                 action='bcc'
//                 title='BCC'
//                 setFieldValue={setFieldValue}
//               />
//               <Form.Group>
//                 <SemanticField label='Enable' toggle name='enable' component={Form.Checkbox} />
//                 <ErrorMessage render={msg => <Message negative size="tiny" content={msg} />} name="enable" />
//               </Form.Group>
//               <Form.Group>
//                 <SemanticField label='Advanced' toggle name='advanced' component={Form.Checkbox} />
//               </Form.Group>
//               {
//                 values.advanced && <>
//                   <Form.Group widths='equal'>
//                     <Form.Field>
//                       <SemanticField label='Reply to' placeholder='Enter an email' autoComplete='off-new' name='replyTo' component={Form.Input} />
//                       <ErrorMessage render={msg => <Message negative size="tiny" content={msg} />} name="replyTo" />
//                     </Form.Field>
//                   </Form.Group>
//                   <Menu attached="top" pointing secondary>
//                     <Menu.Item name='Default From Email' active={true} />
//                   </Menu>
//                   <Segment attached="bottom">
//                     <Form.Group widths='equal'>
//                       <div className="field">
//                         <SemanticField placeholder='Enter an email' name='defaultEmail.email' autoComplete='off-new' component={Form.Input} />
//                         <ErrorMessage render={msg => <Message negative size="tiny" content={msg} />} name="defaultEmail.email" />
//                       </div>
//                       <div className="field">
//                         <SemanticField placeholder='Enter a name' name='defaultEmail.name' autoComplete='off-new' component={Form.Input} />
//                         <ErrorMessage render={msg => <Message negative size="tiny" content={msg} />} name="defaultEmail.name" />
//                       </div>
//                     </Form.Group>
//                   </Segment>
//                 </>
//               }
//               <Button primary type="submit" content="Submit" />
//             </FormikForm>
//           );
//         }}
//       </Formik>
//       {
//         (errorMessage.settings || errorMessage.tenant) && <Message error content={errorMessage.settings || errorMessage.tenant} />
//       }

//       {
//         responseMessage && <Message success content={responseMessage} />
//       }
//     </Segment>
//   )
// }

import React, { useEffect, useState, useMemo } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';

import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Stack,
  Box,
  Button,
  Heading,
  IconButton,
  Switch,
  Alert,
} from '@chakra-ui/react';

import { RiCloseFill } from 'react-icons/ri';

import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { humanize } from 'underscore.string';

import {
  useList,
  useDebounce,
  PageHeaderRow,
  PreLoader,
  PaginatorWrapper,
  EmptyWrapper,
} from '@inkcloud/components';

import { Select } from 'chakra-react-select';

import Feathers from '../../../bootstrap/feathers';

import { notificationTemplateType } from './Detail';

type initialValuesType = notificationTemplateType & {
  bcc: emailType[];
  cc: emailType[];
  recipients: emailType[];
};
interface INotificationRulesFormProps {
  initialValues?: notificationTemplateType;
  onSubmit: (values: initialValuesType) => void;
  errorMessage: string;
  isSubmitting: boolean;
}

type emailType = { name: string; email: string };

const validateKey = async (value: string, initialValues: any) => {
  if (initialValues?.templateKey === value) {
    return true;
  }

  return Feathers.service<any>(`/tenant-settings/notification-settings`)
    .find({ query: { templateKey: value } })
    .then((results: any) => results.result === false)
    .catch((e) => {
      const errMsg =
        e?.code < 500 ? e.message : 'We are experiencing technical difficulties. Please try again';

      return {
        values: {
          templateKey: value,
        },
        errors: e.inner.reduce(
          (allErrors, currentError) => ({
            ...allErrors,
            [currentError.path]: {
              type: currentError.type ?? 'validation',
              message: currentError.message,
            },
          }),
          {}
        ),
      };
    });
};

export default function NotificationRulesForm(props: INotificationRulesFormProps) {
  const { initialValues, onSubmit, errorMessage, isSubmitting } = props;

  const debounceValue = useDebounce(validateKey, 350);

  const schema = useMemo(
    () =>
      Yup.object().shape({
        templateKey: Yup.string()
          .required('Required')
          .test('is-not-used', `Key is already in use`, async (value, values) => {
            const t = await validateKey(value, initialValues);

            return t as boolean;
          }),
        replyTo: Yup.string().email('Must be valid email'),
        defaultEmail: Yup.object().shape(
          {
            email: Yup.string()
              .email('Must be valid email')
              .when('name', {
                is: (value) => value && value.length > 0,
                then: Yup.string().required(
                  'Default from email required when setting a default from name'
                ),
                otherwise: Yup.string().email('Must be valid email'),
              }),
            name: Yup.string().when('email', {
              is: (value) => value && value.length > 0,
              then: Yup.string().required(
                'Default from name required when setting a default from email'
              ),
              otherwise: Yup.string(),
            }),
          },
          ['name', 'email']
        ),
        recipients: Yup.array().of(
          Yup.object().shape({
            email: Yup.string().required('Required email'),
            name: Yup.string().required('Required name'),
          })
        ),
        cc: Yup.array().of(
          Yup.object().shape({
            email: Yup.string().required('Required email'),
            name: Yup.string().required('Required name'),
          })
        ),
        bcc: Yup.array().of(
          Yup.object().shape({
            email: Yup.string().required('Required email'),
            name: Yup.string().required('Required name'),
          })
        ),
        enable: Yup.boolean(),
      }),
    []
  );

  const [templateKeyOptions, setTemplateKeyOptions] = useState<{ label: string; value: string }[]>(
    []
  );

  const {
    control,
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm<initialValuesType>({
    defaultValues: {
      ...initialValues,
      advanced:
        !!initialValues?.replyTo ||
        !!initialValues?.defaultEmail?.email ||
        !!initialValues?.defaultEmail?.name,
    },
    resolver: yupResolver(schema),
  });

  const { onChange, onBlur, name, ref } = register('templateKey');

  const {
    fields: bccFields,
    append: bccAppend,
    remove: bccRemove,
  } = useFieldArray({
    control,
    name: 'bcc',
  });

  const {
    fields: ccFields,
    append: ccAppend,
    remove: ccRemove,
  } = useFieldArray({
    control,
    name: 'cc',
  });

  const {
    fields: recipientsFields,
    append: recipientsAppend,
    remove: recipientsRemove,
  } = useFieldArray({
    control,
    name: 'recipients',
  });

  useEffect(() => {
    const loadKeys = async () => {
      try {
        const res: any = await Feathers.service(
          '/notification/template-list?keysOnly=1&filter=customer'
        ).create({}, { query: {} });

        setTemplateKeyOptions(res?.map((t) => ({ value: t, label: t ? humanize(t) : '' })));
      } catch (e) {
        console.error(e);
      }
    };

    loadKeys();
  }, []);

  useEffect(() => {
    if (initialValues?.templateKey) {
      reset({
        ...initialValues,
        advanced:
          !!initialValues?.replyTo ||
          !!initialValues?.defaultEmail?.email ||
          !!initialValues?.defaultEmail?.name,
      });
    }
  }, [initialValues]);

  const watchTemplateKey = watch('templateKey');
  const watchEnable = watch('enable');
  const watchAdvanced = watch('advanced');

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box bg="white" borderWidth="1px" borderRadius="md" p={3} mt={3}>
        <Stack mb={3}>
          <FormControl isInvalid={!!errors.templateKey}>
            <FormLabel>Notification Type</FormLabel>
            <Select
              name={name}
              ref={ref}
              placeholder=""
              value={templateKeyOptions?.find((o) => o.value === watchTemplateKey)}
              options={templateKeyOptions}
              onChange={(e) => setValue('templateKey', e?.value as string)}
            />
            {errors?.templateKey && (
              <FormErrorMessage>{errors?.templateKey?.message}</FormErrorMessage>
            )}
          </FormControl>
        </Stack>

        <Box borderWidth="1px" borderRadius="md" p={3} mb={3}>
          <Heading as="h6" size="xs" mb={3}>
            Recipients
          </Heading>
          {recipientsFields?.map((field, index) => (
            <Stack key={field.id} direction="row" spacing={7} mb={3}>
              <FormControl isInvalid={!!errors.recipients}>
                <FormLabel>Name</FormLabel>
                <Input {...register(`recipients.${index}.name`)} />
                {errors?.recipients && (
                  <FormErrorMessage>{errors?.recipients?.[index]?.name?.message}</FormErrorMessage>
                )}
              </FormControl>
              <FormControl isInvalid={!!errors.recipients}>
                <FormLabel>Email</FormLabel>
                <Input {...register(`recipients.${index}.email`)} />
                {errors?.recipients && (
                  <FormErrorMessage>{errors?.recipients?.[index]?.email?.message}</FormErrorMessage>
                )}
              </FormControl>
              <FormControl flex={1}>
                <FormLabel>&nbsp;</FormLabel>
                <IconButton
                  type="button"
                  variant="outline"
                  aria-label="remove field"
                  onClick={() => recipientsRemove(index)}
                  icon={<RiCloseFill />}
                />
              </FormControl>
            </Stack>
          ))}

          <Button
            type="button"
            variant="outline"
            onClick={() => recipientsAppend({ name: '', email: '' })}
          >
            Add new Recipients
          </Button>
        </Box>

        <Box borderWidth="1px" borderRadius="md" p={3} mb={3}>
          <Heading as="h6" size="xs" mb={3}>
            CC
          </Heading>
          {ccFields?.map((field, index) => (
            <Stack key={field.id} direction="row" spacing={7} mb={3}>
              <FormControl isInvalid={!!errors.cc}>
                <FormLabel>Name</FormLabel>
                <Input {...register(`cc.${index}.name`)} />
                {errors?.cc && (
                  <FormErrorMessage>{errors?.cc?.[index]?.name?.message}</FormErrorMessage>
                )}
              </FormControl>
              <FormControl isInvalid={!!errors.cc}>
                <FormLabel>Email</FormLabel>
                <Input {...register(`cc.${index}.email`)} />
                {errors?.cc && (
                  <FormErrorMessage>{errors?.cc?.[index]?.email?.message}</FormErrorMessage>
                )}
              </FormControl>
              <FormControl flex={1}>
                <FormLabel>&nbsp;</FormLabel>
                <IconButton
                  type="button"
                  variant="outline"
                  aria-label="remove field"
                  onClick={() => ccRemove(index)}
                  icon={<RiCloseFill />}
                />
              </FormControl>
            </Stack>
          ))}

          <Button type="button" variant="outline" onClick={() => ccAppend({ name: '', email: '' })}>
            Add new CC
          </Button>
        </Box>

        <Box borderWidth="1px" borderRadius="md" p={3} mb={3}>
          <Heading as="h6" size="xs" mb={3}>
            BCC
          </Heading>
          {bccFields?.map((field, index) => (
            <Stack key={field.id} direction="row" spacing={7} mb={3}>
              <FormControl isInvalid={!!errors.bcc}>
                <FormLabel>Name</FormLabel>
                <Input {...register(`bcc.${index}.name`)} />
                {errors?.bcc && (
                  <FormErrorMessage>{errors?.bcc?.[index]?.name?.message}</FormErrorMessage>
                )}
              </FormControl>
              <FormControl isInvalid={!!errors.bcc}>
                <FormLabel>Email</FormLabel>
                <Input {...register(`bcc.${index}.email`)} />
                {errors?.bcc && (
                  <FormErrorMessage>{errors?.bcc?.[index]?.email?.message}</FormErrorMessage>
                )}
              </FormControl>
              <FormControl flex={1}>
                <FormLabel>&nbsp;</FormLabel>
                <IconButton
                  type="button"
                  variant="outline"
                  aria-label="remove field"
                  onClick={() => bccRemove(index)}
                  icon={<RiCloseFill />}
                />
              </FormControl>
            </Stack>
          ))}

          <Button
            type="button"
            variant="outline"
            onClick={() => bccAppend({ name: '', email: '' })}
          >
            Add new BCC
          </Button>
        </Box>

        <Stack mb={3}>
          <FormControl>
            <FormLabel>
              <Switch
                isChecked={watchEnable}
                mr={2}
                onChange={(e) => setValue('enable', e.target.checked)}
              />
              Enable
            </FormLabel>
          </FormControl>
        </Stack>
        <Stack mb={3}>
          <FormControl>
            <FormLabel>
              <Switch
                isChecked={watchAdvanced}
                mr={2}
                onChange={(e) => setValue('advanced', e.target.checked)}
              />
              Advanced
            </FormLabel>
          </FormControl>
        </Stack>
        {watchAdvanced && (
          <>
            <Stack direction="row" spacing={7} mb={3}>
              <FormControl isInvalid={!!errors.replyTo}>
                <FormLabel>Name</FormLabel>
                <Input {...register('replyTo')} />
                {errors?.replyTo && <FormErrorMessage>{errors?.replyTo?.message}</FormErrorMessage>}
              </FormControl>
            </Stack>
            <Box borderWidth="1px" borderRadius="md" p={3} mb={3}>
              <Heading as="h6" size="xs" mb={3}>
                Default From Email
              </Heading>
              <Stack direction="row" spacing={7} mb={3}>
                <FormControl isInvalid={!!errors.defaultEmail}>
                  <FormLabel>Name</FormLabel>
                  <Input {...register('defaultEmail.name')} />
                  {errors?.defaultEmail && (
                    <FormErrorMessage>{errors?.defaultEmail?.name?.message}</FormErrorMessage>
                  )}
                </FormControl>
                <FormControl isInvalid={!!errors.defaultEmail}>
                  <FormLabel>Email</FormLabel>
                  <Input {...register('defaultEmail.email')} />
                  {errors?.defaultEmail && (
                    <FormErrorMessage>{errors?.defaultEmail?.email?.message}</FormErrorMessage>
                  )}
                </FormControl>
              </Stack>
            </Box>
          </>
        )}
        {errorMessage && (
          <Alert mb={3} status="error">
            {errorMessage}
          </Alert>
        )}
        <Button type="submit" isLoading={isSubmitting} isDisabled={isSubmitting}>
          Save
        </Button>
      </Box>
    </form>
  );
}
