import * as React from 'react';
import { Button, Form, Dropdown, Message } from 'semantic-ui-react';

import { To } from '@inkcloud/components';

import Feathers from '../../../bootstrap/feathers';

interface PaymentFormProps {
  payments: any;
}

const { useState, useEffect } = React;

export const PaymentForm: React.FunctionComponent<PaymentFormProps> = (props) => {
  const { payments } = props;
  const [paymentMethods, setPaymentMethods] = useState(payments.methods || []);
  const [paymentOptions, setPaymentOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const loadPaymentMethods = async () => {
      setIsLoading(true);
      const result: any = await Feathers.service('tenant-settings/payment-methods').find({
        query: {
          $np: 1,
          $sort: { name: 1 },
        },
      });
      setIsLoading(false);
      setPaymentOptions(result);

      if (payments && payments.methods && payments.methods.length < 1) {
        const defaultMethods = (result && result.length > 0 && result.map((p) => p.key)) || [];
        setPaymentMethods(defaultMethods);
      }
    };
    loadPaymentMethods();
  }, []);

  const handleChange = (value: any) => setPaymentMethods(value);

  const handleSubmit = async (value: any) => {
    setIsLoading(true);
    const [error, res] = await To(
      Feathers.service('tenant-settings/payment-methods').create(value, { query: {} })
    );
    setIsLoading(false);
    error && setErrorMessage(error.message);
  };

  // Temporary Hack not sure why Dropdown is not showing the default values when I use the "paymentMethods"
  const temp = ['CASH', 'CREDIT_CARD', 'CREDIT_MEMO', 'CHECK', 'OFFLINE', 'NO_PAYMENT'];

  return (
    <>
      {errorMessage && <Message error content={errorMessage} />}
      <Form loading={isLoading} onSubmit={() => handleSubmit({ paymentMethods })}>
        <Form.Field>
          <Dropdown
            fluid
            selection
            multiple
            lazyLoad
            defaultValue={paymentMethods && paymentMethods.length > 0 ? paymentMethods : temp}
            options={
              paymentOptions && paymentOptions.length > 0
                ? paymentOptions.map((p) => ({ text: p.text, value: p.key }))
                : []
            }
            onChange={(e, data) => handleChange(data.value)}
          />
        </Form.Field>

        <Form.Field>
          <Button primary type="submit">
            Save
          </Button>
        </Form.Field>
      </Form>
    </>
  );
};
