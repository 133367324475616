import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Formik, Form as FormikForm, Field, FieldArray, FieldProps } from 'formik';
import { Segment, Form, Message, Button } from 'semantic-ui-react';
import { NotificationForm } from './NotificationForm';

interface ProductNotificationFormProps {
  initialValue: any;
  users: any;
  errorMessage: any;
  isLoading: boolean;
  onSubmit: (v: any) => void;
}

export const ProductNotificationForm: React.FunctionComponent<ProductNotificationFormProps> = (
  props
) => {
  const { initialValue, users, errorMessage, isLoading, onSubmit } = props as any;

  return (
    <Segment>
      {(errorMessage.customers || errorMessage.settings || errorMessage.microsites) && (
        <Message
          error
          content={errorMessage.customers || errorMessage.settings || errorMessage.microsites}
        />
      )}
      <Formik
        initialValues={initialValue || {}}
        enableReinitialize={true}
        onSubmit={(values: any) => {
          onSubmit(values);
        }}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            setFieldValue,
            setFieldTouched,
          } = props as any;

          return (
            <FormikForm className={`ui form ${isLoading && 'loading'}`}>
              <NotificationForm
                values={values && values.email && values.email.to}
                action="to"
                title="To Recipients"
                users={users}
                setFieldValue={setFieldValue}
              />
              <NotificationForm
                values={values && values.email && values.email.cc}
                action="cc"
                title="CC Recipients"
                users={users}
                withEmail={true}
                setFieldValue={setFieldValue}
              />
              <NotificationForm
                values={values && values.email && values.email.bcc}
                action="bcc"
                title="BCC Recipients"
                users={users}
                setFieldValue={setFieldValue}
              />
              <Button primary content="Submit" />
            </FormikForm>
          );
        }}
      </Formik>
    </Segment>
  );
};
