import * as React from 'react';
import { Page } from '@react-pdf/renderer';
import { PageSize, Orientation } from '@react-pdf/types';
import { OrderItemLabelContext } from './OrderItemLabelContext';

export interface IOrderItemLabelPageProps {
  children: React.ReactNode;
}

export const OrderItemLabelPage: React.FunctionComponent<IOrderItemLabelPageProps> = (props) => {
  const ctx = React.useContext(OrderItemLabelContext);
  const {
    config: { size, orientation },
  } = ctx;
  const { children } = props;
  // console.log('passing size', size);
  return (
    <Page size={size} orientation={orientation}>
      {children}
    </Page>
  );
};
