import React, { useEffect, useState } from 'react';
import { Box, HStack, Tag, Tooltip, IconButton, Button } from '@chakra-ui/react';
import { useHistory, Link } from 'react-router-dom';
import { FormattedDate, FormattedTime } from 'react-intl';
import { ListPage, basicQueryMap, useList } from '@inkcloud/components';

import { FaEdit } from 'react-icons/fa';

const pathPrefix = '/job-scheduler/workflow-templates';

const serviceName = 'job-scheduler/workflow-templates';

const filterQueryMap = (values) => basicQueryMap(values, {});

export default function List(props) {
  const history = useHistory();
  const { location } = history;

  const useListValues = useList({
    feathers: ['find', serviceName, [{ query: {$populate: ['type']} }]],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  const tableDefintion = [
    {
      key: 'createdAt',
      label: 'Date',
      sortable: true,
      cell: (v) => (
        <Tooltip hasArrow aria-label="time" label={<FormattedTime value={v} />}>
          <Box>
            <FormattedDate value={v} />
          </Box>
        </Tooltip>
      ),
    },
    {
      key: 'type',
      label: 'Type',
      cell: (v, r) => r.type?.name ?? '-',
    },
    {
      key: 'name',
      label: 'Name',
      cell: (v) => v,
    },
    {
      key: '',
      label: '',
      cell: (v, r) => (
        <>
          <Tooltip label="Edit Workstation Template">
            <Link to={`${pathPrefix}/${r._id}`}>
              <IconButton colorScheme="gray" aria-label="Edit" icon={<FaEdit />} />
            </Link>
          </Tooltip>
        </>
      ),
      textAlign: 'right',
    },
  ];

  const headerActions = (
    <Link to={`${pathPrefix}/add`}>
      <Button colorScheme="blue">New </Button>
    </Link>
  );

  return (
    <>
      <Box>
        <ListPage
          header=""
          subheader=""
          useListValues={useListValues}
          history={history}
          pathPrefix={pathPrefix}
          headerActions={headerActions}
          tableDefintion={tableDefintion}
          withCheckboxes={true}
          getFilterDefinition={null}
        />
      </Box>
    </>
  );
}
