import React, { useState } from 'react';
import {
  TableContainer,
  Table,
  Tag,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Tooltip,
  IconButton,
  Stack,
  StackDivider,
  Flex,
  Box,
  Heading,
  HStack,
  Badge,
  Spacer,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Icon,
  Button,
} from '@chakra-ui/react';

import { FaEllipsisV, FaRegEnvelope, FaExchangeAlt, FaSyncAlt, FaUserTie } from 'react-icons/fa';

import { FormattedDate, FormattedTime } from 'react-intl';

import type { ModelTypes } from '@inkcloud/icapi-types';

import { SendModal } from '../../SendModal';
import SupplierResponseModal from '../../SupplierResponseModal';
import ResetQuoteModal from '../../ResetQuoteModal';

import { SegmentCard } from '../../../../common';
import type { AttributeType } from '../../common/types';

// import { initVendorType } from '../../Create'

interface IParticipatingSuppliersProps {
  vendors: { label: string; value: string }[];
  selectedVendors: ModelTypes.Rfq['vendors'];
  attributes: AttributeType[];
  // rfqId?: string
  rfq?: ModelTypes.Rfq;
  actionButtonLabel?: string;
  onReload?: () => void;
  onEditClick?: () => void;
}

const currentDate = new Date();

function dateCompare(dueDate: Date) {
  return currentDate > new Date(dueDate) ? 'red' : 'yellow';
}

export default function ParticipatingSuppliers(props: IParticipatingSuppliersProps) {
  const {
    selectedVendors,
    attributes,
    vendors,
    rfq,
    actionButtonLabel = 'Edit',
    onReload,
    onEditClick,
  } = props;

  const [isSendModalOpen, setIsSendModalOpen] = useState(false);
  const [isResponseModalOpen, setIsResponseModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState({
    send: false,
    response: false,
    reset: false,
  });
  const [selectedVendor, setSelectedVendor] = useState<{ _id: string; name: string }>({
    _id: '',
    name: '',
  });

  const [selectedResponse, setSelectedResponse] = useState({});

  // if (!selectedVendors?.length) return null

  // const selectedVendors =

  /**
   * There is some confusion here. We have both `vendors` and `responses` and both have a status and response date
   * It seems the backend is only updated `responses` once respnose and not `vendors`. Merging below
   * to unify until we make this clearer
   */
  // const vendorList = rfq?.responses?.map((r) => {
  //   const updated: any = { ...r }
  //   const foundVendor = rfq.vendors?.find((v) => v.vendor === (r.vendor as any)._id)
  //   if (foundVendor) {
  //     updated.services = foundVendor.services
  //     updated.poDueDate = foundVendor.poDueDate
  //     updated.vendor = updated.vendor?._id
  //   }

  //   return updated
  // })

  const vendorList = rfq?.responses?.reduce((acc: any, cur: any) => {
    const foundVendor = rfq.vendors?.find((v) => v.vendor === (cur.vendor as any)?._id);

    if (foundVendor) {
      acc.push({
        ...cur,
        services: foundVendor.services,
        poDueDate: foundVendor.poDueDate,
        vendor: cur.vendor?._id,
      });
    }
    return acc;
  }, []);

  return (
    <SegmentCard
      title="Participating Suppliers"
      subtitle="Manage Suppliers"
      actionButtonLabel={selectedVendors?.length ? actionButtonLabel : ''}
      actionButtonClick={onEditClick}
    >
      {vendors?.length ? (
        <Stack spacing={4} divider={<StackDivider />}>
          {vendorList?.map((s, index: number) => (
            <Flex key={index} flex={1} align={'center'}>
              <Box>
                <Heading size={'xs'}>{vendors?.find((v) => v.value === s?.vendor)?.label} </Heading>
                <HStack mt={1}>
                  {s?.services?.map((sv, idx: number) => (
                    <Tag key={idx}>{sv}</Tag>
                  ))}
                </HStack>
              </Box>
              <Spacer />
              <Box mr={4} textAlign="right">
                <Badge
                  colorScheme={
                    s?.status === 'pending' ? dateCompare(s?.poDueDate as Date) : 'green'
                  }
                >
                  {s?.status}
                </Badge>
                {s?.status === 'pending' && (
                  <Text fontSize={'xs'} casing={'uppercase'}>
                    Due:{' '}
                    {s?.poDueDate && (
                      <>
                        <FormattedDate value={s?.poDueDate} />{' '}
                        <FormattedTime value={s?.poDueDate} />
                      </>
                    )}
                  </Text>
                )}
                {s?.status !== 'pending' && (
                  <Text fontSize={'xs'} casing={'uppercase'}>
                    Response: {s?.respondedAt && <FormattedDate value={s?.respondedAt} />}
                  </Text>
                )}
              </Box>

              {rfq?._id && actionButtonLabel && (
                <Menu>
                  <MenuButton
                    as={IconButton}
                    aria-label="Options"
                    icon={<FaEllipsisV />}
                    variant="outline"
                  />
                  <MenuList>
                    <MenuItem
                      icon={<FaRegEnvelope />}
                      onClick={() => {
                        setIsModalOpen({
                          ...isModalOpen,
                          send: true,
                        });
                        setSelectedVendor({
                          _id: s.vendor as string,
                          name: vendors?.find((v) => v.value === s?.vendor)?.label as string,
                        });
                      }}
                    >
                      Send RFQ
                    </MenuItem>
                    <MenuItem
                      icon={<FaExchangeAlt />}
                      onClick={() => {
                        const response =
                          rfq?.responses?.find((r) => (r?.vendor as any)?._id === s.vendor) ?? {};
                        setIsModalOpen({
                          ...isModalOpen,
                          response: true,
                        });
                        setSelectedResponse(response);
                      }}
                    >
                      Edit Supplier's Response
                    </MenuItem>
                    {['responded', 'responded-with-changes'].includes(s?.status) && (
                      <MenuItem
                        icon={<FaSyncAlt />}
                        onClick={() => {
                          setSelectedVendor({
                            _id: s.vendor as string,
                            name: vendors?.find((v) => v.value === s?.vendor)?.label as string,
                          });
                          setIsModalOpen({
                            ...isModalOpen,
                            reset: true,
                          });
                        }}
                      >
                        Reset Supplier's Quote
                      </MenuItem>
                    )}
                  </MenuList>
                </Menu>
              )}
            </Flex>
          ))}
        </Stack>
      ) : (
        <Flex alignItems="center" justifyContent="center">
          <Box p={5} textAlign="center">
            <Icon as={FaUserTie} boxSize={10} />
            <Text>Suppliers have not been selected</Text>
            <Button isDisabled={!actionButtonLabel} colorScheme="blue" mt={3} onClick={onEditClick}>
              Choose Suppliers…
            </Button>
          </Box>
        </Flex>
      )}

      {isModalOpen.response && (
        <SupplierResponseModal
          rfq={rfq || {}}
          response={selectedResponse}
          attributes={attributes}
          onReload={() => onReload?.()}
          onClose={() => {
            setIsModalOpen({
              ...isModalOpen,
              response: false,
            });
            setSelectedResponse({});
          }}
        />
      )}

      {isModalOpen.reset && (
        <ResetQuoteModal
          rfqId={rfq?._id as string}
          vendor={selectedVendor}
          onReload={() => onReload?.()}
          onClose={() => {
            setIsModalOpen({
              ...isModalOpen,
              reset: false,
            });
            setSelectedVendor({ _id: '', name: '' });
          }}
        />
      )}

      {isModalOpen.send && (
        <SendModal
          rfqId={rfq?._id as string}
          vendor={selectedVendor}
          onClose={() => {
            setIsModalOpen({
              ...isModalOpen,
              send: false,
            });
            setSelectedVendor({ _id: '', name: '' });
          }}
        />
      )}
    </SegmentCard>
  );
}
