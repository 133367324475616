import { Box, Button, Input } from '@chakra-ui/react';
import * as React from 'react';

interface IFilePickerProps {
  accept?: string | string[];
  onChange: (file: NonNullable<HTMLInputElement['files']>[0] | null) => void;
}

const FilePicker: React.FunctionComponent<IFilePickerProps> = (props) => {
  const { accept, onChange } = props;

  let acceptResolved;
  if (accept && Array.isArray(accept)) {
    acceptResolved = accept.join(',');
  } else if (accept) {
    acceptResolved = accept;
  }

  return (
    <Input
      variant={'filled'}
      type={'file'}
      {...props}
      accept={acceptResolved}
      onChange={(e) => onChange(e.target?.files ? e.target?.files[0] : null)}
    />
  );
};

export default FilePicker;
