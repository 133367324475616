import React, { useEffect, useState, useContext, useRef } from 'react';
import { FormattedDate, FormattedTime, FormattedRelativeTime } from 'react-intl';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Sector,
} from 'recharts';

import {
  Accordion,
  Icon,
  Grid,
  Segment,
  Popup,
  Statistic,
  Menu,
  Table,
  Label,
  Message,
} from 'semantic-ui-react';
import {
  Button,
  Table as ChakraTable,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Tag,
  Heading,
  HStack,
  VStack,
  Box,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  Divider,
  Alert,
  AlertIcon,
  Spinner,
} from '@chakra-ui/react';
import { PageHeaderRow, EmptyWrapper } from '@inkcloud/components';
import { useSelector, useDispatch } from 'react-redux';
import type { ModelTypes } from '@inkcloud/icapi-types';

import { Link } from 'react-router-dom';

import { IndexWrapper, IndexWrappedProps } from '../bootstrap/IndexWrapper';
import {
  OrderItemComponent2,
  connectOrderItemModals,
  OrderItemModalsProps,
} from '../common/OrderItemCard2';

import Feathers, { services } from '../bootstrap/feathers';
// force build
export interface OrderItemProps extends OrderItemModalsProps {
  loadCustomerOrderItems: Function;
}

export interface ConnectedProps {
  user?: any;
}

const ignoreTenants = ['534e57d124f90511218b4567', '5c45b4e1a6df4566bf1e5985'];

type RFQtype = {
  data: ModelTypes.Rfq[] & {
    tenant: { displayBusinessName: string };
  };
  total: number;
  limit: number;
  skip: number;
};

const colors = [
  // '#3182CE', '#E53E3E', '#38A169', '#DD6B20', '#805AD5', '#00B5D8', '#D69E2E',
  // '#1A365D', '#2A4365', '#2C5282', '#2B6CB0', '#3182CE', '#4299E1', '#63B3ED', '#90CDF4', '#BEE3F8', '#EBF8FF'
  '#2B6CB0',
  '#6B46C1',
  '#2C7A7B',
  '#68D391',
  '#B7791F',
  '#C05621',
  '#C53030',
];

function truncate(str, n) {
  return str?.length > n ? `${str.substr(0, n - 4)}...` : str;
}

interface StatCardProps {
  label: string;
  number: number | string;
  helpText?: string;
}

const StatCard: React.FunctionComponent<StatCardProps> = (props) => {
  const { label, number, helpText = '' } = props;

  return (
    <Stat>
      <StatLabel>{label}</StatLabel>
      <StatNumber>{number}</StatNumber>
      {helpText && <StatHelpText>{helpText}</StatHelpText>}
    </Stat>
  );
};

interface ActiveRfqByStatusProps {
  data: { status: string; count: number }[];
}
const ActiveRfqByStatus: React.FunctionComponent<ActiveRfqByStatusProps> = (props) => {
  const { data } = props;
  const a = '';

  const mappedData = data.map((d, i) => ({
    name: d.status,
    value: d.count,
  }));

  return (
    <ResponsiveContainer width={'100%'} height={200}>
      <PieChart width={400} height={200}>
        <Tooltip />
        <Pie
          data={mappedData}
          dataKey="value"
          cx="50%"
          cy="50%"
          outerRadius={60}
          label={(entry) => entry.name}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
          ))}
        </Pie>
        {/* <Pie data={data02} dataKey="value" cx="50%" cy="50%" innerRadius={70} outerRadius={90} fill="#82ca9d" label /> */}
      </PieChart>
    </ResponsiveContainer>
  );
};

interface RfqAssignedLoadProps {
  data: {
    user: string;
    'rfq-received': number;
    'sent-to-suppliers': number;
    'supplier-response-received': number;
    'estimate-sent': number;
    'estimate-response-received': number;
  }[];
}
const RfqAssignedLoad: React.FunctionComponent<RfqAssignedLoadProps> = (props) => {
  const { data } = props;

  const mappedData = data.map((d) => ({ ...d, name: truncate(d.user, 16) ?? 'Unassigned' }));

  return (
    <ResponsiveContainer width={'100%'} height={200}>
      <BarChart
        layout="vertical"
        barSize={20}
        width={500}
        height={200}
        data={mappedData}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        {/* <XAxis /> */}
        <XAxis type="number" />
        {/* <YAxis dataKey="name"  /> */}
        <YAxis dataKey="name" type="category" scale="band" fontSize={10} />
        <Tooltip />
        <Legend />
        <Bar dataKey="rfq-received" stackId="a" fill={colors[0]} />
        <Bar dataKey="sent-to-suppliers" stackId="a" fill={colors[1]} />
        <Bar dataKey="supplier-response-received" stackId="a" fill={colors[2]} />
        <Bar dataKey="estimate-sent" stackId="a" fill={colors[3]} />
        <Bar dataKey="estimate-response-received" stackId="a" fill={colors[4]} />
        <Bar dataKey="order-awarded" stackId="a" fill={colors[5]} />
      </BarChart>
    </ResponsiveContainer>
  );
};

function PreContainer(props: IndexWrappedProps & OrderItemModalsProps) {
  const authUser = useSelector((state: any) => state.auth.user);
  const isVendor = !!authUser?.vendor;
  const showStats = !ignoreTenants.includes(authUser?.business ?? '');

  const reloadRef = useRef(null);

  const [stats, setStats] = useState<Record<string, any>>({});
  const [rfq, setRFQ] = useState<RFQtype>();
  const [lateJobDetector, setLateJobDetector] = useState<ModelTypes.LateJobDetectors[]>([]);
  const [supplierBillingReconciliationNotice, setSupplierBillingReconciliationNotice] =
    useState<boolean>(false);
  const [prodPipeline, setProdPipeline] = useState<
    { count: number; description: string; priority: number; status: string }[]
  >([]);

  const [orderItems, setOrderItems] = useState<Exclude<ModelTypes.OrderItems[], undefined>>([]);
  const [isReload, setIsReload] = useState(false);

  const [isStatsLoading, setIsStatsLoading] = useState(false);
  const [isRFQLoading, setIsRFQLoading] = useState(false);
  const [isOrderItemsLoading, setIsOrderItemsLoading] = useState(false);
  const [isPipelineLoading, setIsPipelineLoading] = useState(false);
  const [isLateJobDetectorLoading, setIsLateJobDetectorLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState<Record<string, string>>({
    orderItems: '',
    pipeline: '',
    lateJobDetector: '',
  });

  const loadOrderItems = async () => {
    setIsOrderItemsLoading(true);
    try {
      const res = await Feathers.service('/order-items').find({
        query: {
          'productionStatus.jobStatus': { $ne: 'VOID' },
          $populate: [
            { path: 'attributes.attribute', select: 'name' },
            { path: 'attributes.value', select: 'name' },
            { path: 'category', select: 'name' },
            { path: 'customer', select: 'organizationName primaryContact' },
            { path: 'micrositeUser', select: 'firstName lastName' },
            { path: 'productionChannel', select: 'name' },
            // 'productionStatus.jobStatus',
            { path: 'vendor', select: 'companyName' },
            'miscUploads',
          ],
          $populateProductImages: 1,
          $sort: { createdAt: -1 },
          $limit: 15,
        },
      });
      setOrderItems(res?.data);
    } catch (e) {
      console.error(e);
    }
    setIsOrderItemsLoading(false);
    setIsReload(false);
  };

  const loadStats = async () => {
    setIsStatsLoading(true);
    try {
      const res = await Feathers.service('/dash-home/stats').find({ query: {} });
      setStats(res);
    } catch (e) {
      console.error(e);
    }
    setIsStatsLoading(false);
  };

  const loadSupplierBillingReconciliations = async () => {
    if (!isVendor) {
      return;
    }

    const res = await Feathers.service('vendor-billing-reconcillations').find({
      query: {
        status: 'awaiting-supplier-response',
      },
    });

    if (res.total > 0) {
      setSupplierBillingReconciliationNotice(true);
    }
  };

  const loadRFQ = async () => {
    setIsRFQLoading(true);
    try {
      const res = await Feathers.service('/rfqs').find({
        query: {
          status: isVendor ? { $ne: 'draft' } : undefined,
          $populate: [
            'customer',
            'micrositeUser',
            { path: 'tenant', select: 'displayBusinessName' },
          ],
          $sort: { createdAt: -1 },
        },
      });
      setRFQ(res);
    } catch (e) {
      console.error(e);
    }
    setIsRFQLoading(false);
  };

  const loadLateJobDetector = async () => {
    setErrorMessage({
      ...errorMessage,
      lateJobDetector: '',
    });
    setIsLateJobDetectorLoading(true);
    try {
      const res = await Feathers.service('/late-job-detectors').find({
        query: {
          $sort: { createdAt: -1 },
        },
      });
      setLateJobDetector(res.data);
    } catch (e) {
      const errMsg = e?.code < 500 ? e.message : 'Something went wrong';
      setErrorMessage({
        ...errorMessage,
        lateJobDetector: errMsg,
      });
    }
    setIsLateJobDetectorLoading(false);
  };

  const loadPipeLine = async () => {
    setErrorMessage({
      ...errorMessage,
      pipeline: '',
    });
    setIsPipelineLoading(true);

    try {
      const res = await Feathers.service('/dash-home/production-pipeline').find({ query: {} });
      setProdPipeline(res);
    } catch (e) {
      const errMsg = e?.code < 500 ? e.message : 'Something went wrong';
      setErrorMessage({
        ...errorMessage,
        pipeline: errMsg,
      });
    }

    setIsPipelineLoading(false);
  };

  useEffect(() => {
    if (!reloadRef.current) return;

    if (isReload) {
      loadOrderItems();
    }
  }, [isReload]);

  useEffect(() => {
    loadLateJobDetector();

    if (isVendor) {
      loadRFQ();
      loadSupplierBillingReconciliations();
    }

    if (!isVendor && showStats) {
      loadStats();
    }

    loadPipeLine();
    loadOrderItems();
  }, []);

  // console.count('render')

  const sortedByGroup = orderItems
    ?.reduce((acc: ModelTypes.OrderItems[], cur) => {
      const humanId = cur?.humanId?.split('-')[0] ?? '';

      const found = acc?.find((t) => Object.keys(t).find((s) => s === humanId));

      if (!found) {
        acc.push({
          [humanId]: [cur],
        });
      } else {
        const foundIdx = acc.findIndex((a) => Object.keys(a)[0] === Object.keys(found)[0]);

        const sortedItems = [...acc[foundIdx][humanId], cur]?.sort(
          (a, b) => Number(a.humanId.split('-')[1]) - Number(b.humanId.split('-')[1])
        );

        acc.splice(foundIdx, 1, {
          [humanId]: sortedItems,
        });
      }

      return acc;
    }, [])
    ?.sort((a, b) => Number(Object.keys(b)[0]) - Number(Object.keys(a)[0]));

  const lateJobDetectorPanels = lateJobDetector?.map((l: any, index: number) => ({
    key: index,
    title: {
      content: (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span>{l.name}</span>
          <span>
            <Popup
              trigger={
                <Label basic size="tiny">
                  <FormattedDate value={l.lastRanAt} />
                </Label>
              }
              content="Last Ran At"
            />
          </span>
          <span>
            {l.lastRunResult !== 'fail' ? (
              <Icon name="check circle outline" color="green" />
            ) : (
              <Icon name="exclamation triangle" color="red" />
            )}
          </span>
        </div>
      ),
      // icon: 'none'
    },
    content: {
      content: (
        <>
          <EmptyWrapper
            queryResult={l?.failedOrderItems}
            isStateLoading={isLateJobDetectorLoading}
            attached="bottom"
          >
            <Segment attached="bottom" style={{ minHeight: '100px' }}>
              <Table basic="very" compact size="small">
                <Table.Body>
                  {l?.failedOrderItems?.map((oi: any, idx: number) => (
                    <Table.Row key={idx}>
                      <Table.Cell collapsing>
                        <Link to={{ pathname: `/orders/${oi?.order}` }}>{oi.orderItemHumanId}</Link>
                      </Table.Cell>
                      <Table.Cell collapsing>
                        <Label basic size={'tiny'} style={{ marginRight: 10 }}>
                          <Icon name="signal" />
                          {oi?.orderItemStatusDescription}
                        </Label>
                      </Table.Cell>
                      <Table.Cell collapsing>{oi.sinceHours} hours</Table.Cell>
                      {/* <Table.Cell collapsing textAlign="right">
                            {oi?.lastRanAt}
                          </Table.Cell> */}
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </Segment>
          </EmptyWrapper>
        </>
      ),
    },
  }));

  return (
    <>
      {!isVendor && showStats && (
        <React.Fragment>
          <Menu attached="top" pointing secondary>
            <Menu.Item name="At a Glance" active={true} />
          </Menu>
          <Segment attached="bottom">
            <Grid columns={3} stackable>
              <Grid.Column>
                <Segment color="blue" style={{ textAlign: 'center' }}>
                  <Statistic
                    color="blue"
                    label="Accounts"
                    value={`${stats?.countCustomerToday ?? 0} / ${stats?.countCustomerMonth ?? 0}`}
                    size="tiny"
                  />
                </Segment>
              </Grid.Column>
              <Grid.Column>
                <Segment color="blue" style={{ textAlign: 'center' }}>
                  <Statistic
                    color="blue"
                    label="Orders"
                    value={`${stats?.countOrderItemToday ?? 0} / ${
                      stats?.countOrderItemMonth ?? 0
                    }`}
                    size="tiny"
                  />
                </Segment>
              </Grid.Column>
              <Grid.Column>
                <Segment color="blue" style={{ textAlign: 'center' }}>
                  <Statistic
                    color="blue"
                    label="Revenue"
                    value={`$${stats?.revenueToday ?? 0} / $${stats?.revenueMonth ?? 0}`}
                    size="tiny"
                  />
                </Segment>
              </Grid.Column>
            </Grid>
          </Segment>
        </React.Fragment>
      )}
      <br />
      {!isVendor &&
        (stats?.activeRfqsByStatus?.reduce((acc: any, cur: any) => cur.count + acc, 0) ?? 0) >
          0 && (
          <Box
            pos="relative"
            bgColor={'white'}
            p={8}
            borderWidth={1}
            borderColor={'gray.200'}
            mb={8}
          >
            {isStatsLoading && (
              <Box
                pos="absolute"
                left={0}
                top={0}
                display="flex"
                alignItems="center"
                justifyContent="center"
                w="100%"
                h="100%"
                zIndex={2}
                bg="white"
              >
                <Spinner size="lg" />
              </Box>
            )}
            <Box>
              <Heading as={'h5'} size={'md'} mb={4}>
                Purchasing
              </Heading>
              <Divider />
              <HStack mt={4}>
                <Box w="25%">
                  <VStack divider={<Divider />} align={'flex-start'}>
                    <StatCard
                      label="RFQs Active"
                      number={
                        stats?.activeRfqsByStatus?.reduce((acc, cur) => cur.count + acc, 0) ?? 0
                      }
                      helpText="Now"
                    />
                    <StatCard
                      label="RFQs Created"
                      number={stats?.rfqsCreated ?? 0}
                      helpText="This Month"
                    />
                  </VStack>
                </Box>
                {/* <StatCard label='Created' number={35} helpText='This Month'  /> */}
                <Box w="75%" textAlign={'center'}>
                  <Heading as={'h5'} size={'sm'}>
                    Active RFQ Status Breakdown
                  </Heading>
                  <ActiveRfqByStatus data={stats?.activeRfqsByStatus ?? []} />
                </Box>
              </HStack>
              <Divider />
              <HStack mt={4}>
                <Box w="25%">
                  <VStack divider={<Divider />} align={'flex-start'}>
                    <StatCard
                      label="RFQ Avg Response"
                      number={stats?.rfqAvgResponseTime ?? 0}
                      helpText="This Month (Days)"
                    />
                    <StatCard
                      label="RFQ Time to Award"
                      number={`${
                        stats?.rfqTimeToAward === 0 ? 'N/A' : `${stats?.rfqTimeToAward ?? 0} days`
                      }`}
                      helpText={stats?.rfqTimeToAward === 0 ? '' : 'This Month (Days)' ?? ''}
                    />
                  </VStack>
                </Box>
                <Box w="75%" textAlign={'center'}>
                  <Heading as={'h5'} size={'sm'}>
                    RFQ Load
                  </Heading>
                  <RfqAssignedLoad data={stats?.rfqCountByAssigned ?? []} />
                </Box>
              </HStack>
            </Box>
          </Box>
        )}
      {isVendor && supplierBillingReconciliationNotice && (
        <Alert status="warning" mb={8}>
          <AlertIcon />
          You have a billing reconciliation to submit.{' '}
          <Link to="/supplier-billing-reconciliation">
            <Button size={'sm'} variant={'link'}>
              Click here to review
            </Button>
          </Link>
        </Alert>
      )}

      <Grid stackable={true}>
        <Grid.Column width={12}>
          {/* {isVendor && (
            <>
              <Menu attached="top" pointing secondary>
                <Menu.Item name="Recent RFQs" active={true} />
              </Menu>
              <EmptyWrapper queryResult={rfq} isStateLoading={isRFQLoading} attached="bottom">
                <Segment attached="bottom">
                  <TableContainer>
                    <ChakraTable size="sm">
                      <Thead>
                        <Tr>
                          <Th>ID</Th>
                          <Th>Date</Th>
                          <Th>Customer</Th>
                          <Th>Project Name</Th>
                          <Th>Status</Th>
                          <Th></Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {rfq?.data?.map((r) => (
                          <Tr key={r._id}>
                            <Td>
                              <Link to={`/rfqs/response/${r._id}`}>{r?.humanId ?? ''}</Link>
                            </Td>
                            <Td>
                              <Popup
                                size="mini"
                                content={<FormattedTime value={r?.createdAt} />}
                                trigger={
                                  <div>
                                    <FormattedDate value={r?.createdAt} />
                                  </div>
                                }
                              />
                            </Td>
                            <Td>{(r?.tenant as any)?.displayBusinessName ?? ''}</Td>
                            <Td>{r?.projectName ?? ''}</Td>
                            <Td>
                              <Tag>{r?.status ?? ''}</Tag>
                            </Td>
                            <Td textAlign="right">
                              <Link to={`/rfqs/response/${r._id}`}>
                                <Icon name="external alternate" />
                              </Link>
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </ChakraTable>
                  </TableContainer>
                </Segment>
              </EmptyWrapper>
            </>
          )} */}

          <Menu attached="top" pointing secondary>
            <Menu.Item name="Recent Orders" active={true} />
          </Menu>
          <EmptyWrapper
            queryResult={orderItems}
            isStateLoading={isOrderItemsLoading}
            attached="bottom"
          >
            <Segment attached="bottom">
              {sortedByGroup?.map((group) =>
                Object?.keys(group)?.map((key, idx) =>
                  group[key]?.map((g) => (
                    <OrderItemComponent2
                      key={g._id}
                      isVendor={isVendor}
                      orderItem={g}
                      handleArtworkManagementClick={(e, data) =>
                        props.artworkHandleOpen(data.orderitem)
                      }
                      printHandleOpen={(e, data) => props.printHandleOpen(data.type, data.payload)}
                      preflightHandleOpen={(e, data) => props.preflightHandleOpen(data.orderitem)}
                      statusChangeHandleOpen={() => {}}
                      onReload={() => setIsReload(true)}
                      // onStatusChangerSubmit={this.handleStatusChangerSubmit}
                      // onTurnaroundSubmit={this.handleTurnaroundSubmit}
                    />
                  ))
                )
              )}
            </Segment>
          </EmptyWrapper>
        </Grid.Column>
        <Grid.Column width={4}>
          {errorMessage.Pipeline && <Message error content={errorMessage.Pipeline} />}
          <Menu attached="top" pointing secondary>
            <Menu.Item name="Production Pipeline" active={true} />
            <div className="right menu" style={{ alignItems: 'center', paddingRight: '1em' }}>
              <Link to="/production-pipeline">View All</Link>
            </div>
          </Menu>
          <EmptyWrapper
            queryResult={prodPipeline}
            isStateLoading={isPipelineLoading}
            attached="bottom"
          >
            <Segment attached="bottom" style={{ minHeight: '100px' }}>
              <Table basic="very" compact size="small">
                <Table.Body>
                  {prodPipeline?.map((p, index: number) => (
                    <Table.Row key={index}>
                      <Table.Cell collapsing>
                        <Link
                          to={{
                            pathname: '/orders',
                            state: { filter: { productionStatus: [p.status] } },
                          }}
                        >
                          <Label circular color="green">
                            {p.count}
                          </Label>
                        </Link>
                      </Table.Cell>
                      <Table.Cell>
                        <Link
                          to={{
                            pathname: '/orders',
                            state: {
                              filter: {
                                rows: [
                                  {
                                    selectedFact: 'productionStatus.jobStatus',
                                    selectedOperator: 'is',
                                    value: [{ value: p.status, label: p.description }],
                                  },
                                ],
                              },
                            },
                          }}
                        >
                          {p.description}
                        </Link>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </Segment>
          </EmptyWrapper>

          <Menu attached="top" pointing secondary>
            <Menu.Item name="Late Job Detector" active={true} />
          </Menu>
          <EmptyWrapper
            queryResult={lateJobDetector}
            isStateLoading={isLateJobDetectorLoading}
            attached="bottom"
          >
            <Segment attached="bottom" style={{ minHeight: '100px' }}>
              <Accordion panels={lateJobDetectorPanels} fluid styled />
            </Segment>
          </EmptyWrapper>
        </Grid.Column>
      </Grid>
    </>
  );
}

const Detail = connectOrderItemModals<IndexWrappedProps>(PreContainer);

export default Detail;
