import * as React from 'react';
import Feathers from '../../../bootstrap/feathers';
import { Button, Form, Modal, Message } from 'semantic-ui-react';
import { Formik, Form as FormikForm, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { SemanticField } from '../../../common/Form';
import { usStateProvinces } from '../../../common';

export interface PickupLocationModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedLocation: string;
}

const { useState, useEffect } = React;

const PickupLocationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  address1: Yup.string().required('Required'),
  address2: Yup.string(),
  city: Yup.string().required('Required'),
  stateProvince: Yup.string().required('Required'),
  postalCode: Yup.string().required('Required'),
  isDeleted: Yup.boolean(),
});

export const PickupLocationModal: React.FunctionComponent<PickupLocationModalProps> = (props) => {
  const { isOpen, onClose, selectedLocation } = props;

  const [errorMessage, setErrorMessage] = useState('');
  const [initialData, setInitialData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const loadLocation = async () => {
      try {
        setErrorMessage('');
        setIsLoading(true);
        const res: any = await Feathers.service('/pickup-locations').get(selectedLocation, {
          query: {},
        });
        setInitialData(res);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setErrorMessage(error.message);
      }
    };

    if (selectedLocation) {
      loadLocation();
    }
  }, [selectedLocation]);

  const handleSubmit = async (values: any) => {
    try {
      selectedLocation
        ? await Feathers.service('/pickup-locations').patch(selectedLocation, values, { query: {} })
        : await Feathers.service('/pickup-locations').create(values, { query: {} });
      onClose();
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  return (
    <Modal open={isOpen} onClose={onClose} closeIcon>
      <Modal.Header>Add Pick-up Locations</Modal.Header>
      <Modal.Content style={{ textAlign: 'left' }}>
        {errorMessage && <Message error content={errorMessage} />}
        <Formik
          initialValues={initialData}
          enableReinitialize={true}
          validationSchema={PickupLocationSchema}
          onSubmit={(values, { setSubmitting }) => handleSubmit(values)}
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              dirty,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset,
              setFieldValue,
              setFieldTouched,
            } = props;

            return (
              <FormikForm
                className={`ui form ${isSubmitting || (isLoading && !errorMessage && 'loading')}`}
              >
                <Form.Group widths="equal">
                  <Form.Field>
                    <SemanticField name={'name'} label="Name" component={Form.Input} />
                    <ErrorMessage component="div" name="name" />
                  </Form.Field>
                </Form.Group>

                <Form.Group widths="equal">
                  <Form.Field>
                    <SemanticField name={'address1'} label="Address 1" component={Form.Input} />
                    <ErrorMessage component="div" name="address1" />
                  </Form.Field>
                  <Form.Field>
                    <SemanticField name={'address2'} label="Address 2" component={Form.Input} />
                    <ErrorMessage component="div" name="address2" />
                  </Form.Field>
                </Form.Group>

                <Form.Group widths="equal">
                  <Form.Field>
                    <SemanticField name={'city'} label="City" component={Form.Input} />
                    <ErrorMessage component="div" name="city" />
                  </Form.Field>

                  <Form.Field>
                    <SemanticField
                      name={'stateProvince'}
                      component={Form.Dropdown}
                      options={usStateProvinces}
                      fluid
                      selection
                      search
                      label="State/Province"
                      placeholder="State/Province..."
                    />
                    <ErrorMessage component="div" name="stateProvince" />
                  </Form.Field>
                  <Form.Field>
                    <SemanticField name={'postalCode'} label="Postal Code" component={Form.Input} />
                    <ErrorMessage component="div" name="postalCode" />
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal"></Form.Group>
                <Button primary type="submit" disabled={isSubmitting && !errorMessage}>
                  Save
                </Button>
              </FormikForm>
            );
          }}
        </Formik>
      </Modal.Content>
    </Modal>
  );
};
