import * as React from 'react';
import { Divider, Modal, ModalProps, Table } from 'semantic-ui-react';
import Feathers from '../../../../bootstrap/feathers';
import { AdjustmentForm } from '../AdjustmentForm';

export interface ReceiveModalProps {
  handleSubmit: Function;
  handleClose: ModalProps['onClose'];
  open: boolean;
  incomingTicket: any;
  errorMessage?: string;
  // lastLocation: string | null;
}

const initialState = { isInitialized: false, locations: [], lastLocation: null };
type State = typeof initialState;

export class ReceiveModal extends React.Component<ReceiveModalProps, State> {
  state = initialState;
  async componentDidMount() {
    // const locations = await Feathers.service('/inventory/locations').find({ query: { $np: 1}}) as any[];
    const [locations, productLocations] = (await Promise.all([
      Feathers.service('/inventory/locations').find({ query: { $np: 1 } }),
      this.loadInventoryLocations(),
    ])) as any;

    const lastLocation =
      productLocations &&
      productLocations.productLocations &&
      productLocations.productLocations.length
        ? productLocations.productLocations[0].id
        : null;

    this.setState({
      ...this.state,
      isInitialized: true,
      lastLocation,
      locations: locations.map((l) => ({ value: l._id, text: l.name })),
    });
  }

  async loadInventoryLocations() {
    return Feathers.service('/inventory/operations').create({
      operation: 'PRODUCT_TOTAL_STOCK_PER_LOCATION',
      details: {
        productId: this.props.incomingTicket.product._id,
      },
    }) as any;
  }

  public render() {
    const { props } = this;
    const { incomingTicket } = props;

    const initialValues = {
      amount: incomingTicket.units,
      price: incomingTicket.price,
      destination: this.state.lastLocation || '',
    };

    return (
      <Modal size={'small'} open={props.open} onClose={props.handleClose}>
        <Modal.Header>Incoming Inventory</Modal.Header>
        <Modal.Content>
          <Table size="small">
            <Table.Body>
              <Table.Row>
                <Table.Cell>Microsite</Table.Cell>
                <Table.Cell>{incomingTicket.microsite.name}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Product</Table.Cell>
                <Table.Cell>{`${incomingTicket.product.key} - ${incomingTicket.product.staticName}`}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Arrival Date</Table.Cell>
                <Table.Cell>{incomingTicket.arrivalDate}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          <Divider />
          {this.state.isInitialized && (
            <AdjustmentForm
              initialValues={initialValues}
              handleSubmit={props.handleSubmit}
              handleCloseClick={this.props.handleClose}
              locations={this.state.locations}
              errorMessage={props.errorMessage}
            />
          )}
        </Modal.Content>
        <Modal.Actions></Modal.Actions>
      </Modal>
    );
  }
}
