import React, { useEffect, useState } from 'react';
import { Button, Segment, Popup, Label, Dropdown } from 'semantic-ui-react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { FormattedDate, FormattedTime, FormattedNumber } from 'react-intl';

import {
  useList,
  ListPage,
  basicQueryMap,
  generateOperatorOptions,
  Operator,
} from '@inkcloud/components';
import { useSelector } from 'react-redux';

import feathers from '../../bootstrap/feathers';

import { calculateSummary } from './SalesOrder/helpers';

import EmailModal from './EmailModal';

import DuplicateModal from './DuplicateModal';

interface ListProps {}

const serviceName = '/sales-orders';
const pathPrefix = '/sales-orders';

const filterQueryMap = (values) => basicQueryMap(values, {});

export async function getFilterDefinition() {
  const definition = [
    {
      label: 'Date:Created',
      value: 'createdAt',
      operators: generateOperatorOptions([Operator.Between]),
      type: 'date',
    },
    {
      label: 'Project Name',
      value: 'soDescription',
      operators: generateOperatorOptions([Operator.Like, Operator.Is]),
    },
    {
      label: 'Customer PO',
      value: 'customerPo',
      operators: generateOperatorOptions([Operator.Like, Operator.Is]),
    },
    {
      label: 'ID',
      value: 'humanId',
      operators: generateOperatorOptions([Operator.Is]),
    },
  ];

  return definition.sort((a: any, b: any) => a.label.localeCompare(b.label));
}

export const List: React.FunctionComponent<ListProps & RouteComponentProps<any>> = (props) => {
  const {
    match: { params },
    location,
    history,
  } = props;

  const authUser = useSelector((state: any) => state.auth.user);

  const [tenant, setTenant] = useState({ countryCode: '', currency: '', locale: '' });

  useEffect(() => {
    const loadTenant = async (id: string) => {
      try {
        const res = await feathers.service('/tenant-settings').get(id, {
          query: {
            $select: ['internationalization'],
          },
        });

        setTenant({ ...res.internationalization });
      } catch (error) {
        console.error(error);
      }
    };

    if (authUser?._id) {
      loadTenant(authUser.business);
    }
  }, [authUser?._id]);

  const [isModalOpen, setIsModalOpen] = useState({
    send: false,
    duplicate: false,
  });

  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [shippingServicesOptions, setShippingServicesOptions] = useState<Record<string, string>[]>(
    []
  );

  const tableDefinition = [
    {
      key: 'humanId',
      label: 'ID',
      sortable: true,
      cell: (v, r) => v,
    },
    {
      key: 'createdAt',
      label: 'Date',
      sortable: true,
      cell: (v) =>
        v && (
          <Popup
            size="mini"
            content={<FormattedTime value={v} />}
            trigger={
              <div>
                <FormattedDate value={v} />
              </div>
            }
          />
        ),
    },
    {
      key: 'soDescription',
      label: 'Project Name',
      sortable: true,
      cell: (v, r) => v,
    },
    {
      key: '',
      label: 'Customer',
      sortable: true,
      cell: (v, r) => {
        if (r?.micrositeUser) {
          return r?.micrositeUser?.companyName
            ? r?.micrositeUser?.companyName
            : `${r?.micrositeUser?.firstName ?? ''} ${r?.micrositeUser?.lastName ?? ''}`;
        }

        return (
          <div>
            <div>{r?.customer?.organizationName ?? ''}</div>
            <div>
              {' '}
              {r?.customer?.primaryContact?.firstName ?? ''}{' '}
              {r?.customer?.primaryContact?.lastName ?? ''}
            </div>
          </div>
        );
      },
    },
    {
      key: 'customerPo',
      label: 'Customer PO#',
      sortable: true,
      cell: (v, r) => v,
    },
    {
      key: '',
      label: 'Ship To City',
      sortable: true,
      cell: (v, r) => r?.shipTo?.city ?? '',
    },
    {
      key: '',
      label: 'Delivery Method',
      sortable: true,
      cell: (v, r) =>
        shippingServicesOptions?.find((s) => s.value === r?.shipping?.shippingService)?.label ?? '',
    },
    {
      key: '',
      label: 'Total',
      sortable: true,
      cell: (v, r) => (
        <FormattedNumber
          value={calculateSummary(r?.items, r?.taxRate).total}
          style="currency"
          currency={tenant?.currency ?? 'USD'}
        />
      ),
      textAlign: 'right',
    },
    {
      key: '',
      label: '',
      sortable: false,
      cell: (v, r) => (
        <React.Fragment>
          <Popup
            size="mini"
            content="More Actions..."
            trigger={
              <Dropdown
                className="icon mini"
                scrolling
                position="left"
                direction="left"
                button
                icon="ellipsis vertical"
              >
                <Dropdown.Menu className="ui mini">
                  <Dropdown.Item
                    icon="mail"
                    content={'Send'}
                    onClick={() => {
                      setIsModalOpen({
                        ...isModalOpen,
                        send: true,
                      });
                      setSelectedItem(r);
                    }}
                  />
                  <Dropdown.Item
                    icon="copy"
                    content={'Duplicate'}
                    onClick={() => {
                      setIsModalOpen({
                        ...isModalOpen,
                        duplicate: true,
                      });
                      setSelectedItem(r);
                    }}
                  />
                  {/* <Dropdown.Item icon="print" content={'Print'} /> */}
                </Dropdown.Menu>
              </Dropdown>
            }
          />
          <Popup
            size="mini"
            content="Edit"
            trigger={
              <Link to={`${pathPrefix}/${r._id}`}>
                <Button size={'mini'} icon={'edit'} />
              </Link>
            }
          />
        </React.Fragment>
      ),
      textAlign: 'right',
    },
  ];

  const useListValues = useList({
    feathers: [
      'find',
      serviceName,
      [
        {
          query: {
            $populate: ['customer', 'micrositeUser'],
          },
        },
      ],
    ],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  useEffect(() => {
    const loadShippingServicesOptions = async () => {
      const result: any = await feathers.service('tenant-settings/shipping-services-options').find({
        query: {
          $np: 1,
          $sort: { name: 1 },
          all: 1,
        },
      });

      const mappedResult = () =>
        result?.map((r) => ({ ...r, value: r.service, label: r.serviceLabel }));

      setShippingServicesOptions(mappedResult);
    };

    loadShippingServicesOptions();
  }, []);

  const headerActions = (
    <>
      <Link to={`${pathPrefix}/add`}>
        <Button content="New" primary size={'mini'} />
      </Link>
    </>
  );

  return (
    <div>
      <ListPage
        header={'Sales Orders'}
        subheader="List"
        useListValues={useListValues}
        history={history}
        pathPrefix={pathPrefix}
        tableDefintion={tableDefinition}
        withCheckboxes={true}
        headerActions={headerActions}
        getFilterDefinition={getFilterDefinition}
      />

      {isModalOpen.send && (
        <EmailModal
          salesOrderId={selectedItem._id}
          humanId={selectedItem.humanId}
          contact={{
            email: selectedItem?.customer?.email,
            name: `${selectedItem?.customer?.primaryContact?.firstName ?? ''} ${
              selectedItem?.customer?.primaryContact?.lastName ?? ''
            }`,
          }}
          onClose={() => {
            setIsModalOpen({ ...isModalOpen, send: false });
            setSelectedItem(null);
          }}
        />
      )}
      {isModalOpen.duplicate && (
        <DuplicateModal
          data={selectedItem}
          onClose={() => {
            setIsModalOpen({ ...isModalOpen, duplicate: false });
            setSelectedItem(null);
          }}
        />
      )}
    </div>
  );
};
