import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Box, Button, Textarea, Alert } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { FormControl } from '../../../common/v3/Form/FormControl';
import DatePickerInput from '../../../common/v3/Form/DatePickerInput';

const yupSchema = yup.object().shape({
  dateOf: yup.date().required(),
  description: yup.string().required(),
});

interface TurnaroundHolidayFormProps {
  initialValues: {
    dateOf: Date;
    description: string;
  };
  isSubmitting: boolean;
  errorMessage: string;
  onSubmit: (data: any) => void;
}

export default function TurnaroundHolidayForm(props: TurnaroundHolidayFormProps) {
  const { initialValues, isSubmitting, errorMessage, onSubmit } = props;

  const useFormReturn = useForm({
    mode: 'onBlur',
    defaultValues: initialValues,
    resolver: yupResolver(yupSchema),
  });

  const { handleSubmit } = useFormReturn;

  return (
    <>
      <FormProvider {...useFormReturn}>
        <FormControl
          field={'dateOf'}
          label="Date"
          placeholder={'Date...'}
          render={(fieldProps) => <DatePickerInput {...fieldProps} />}
        />
        <FormControl
          field={'description'}
          label="Description"
          render={(fieldProps) => <Textarea {...fieldProps}></Textarea>}
        />
      </FormProvider>

      {errorMessage && <Alert status="error">{errorMessage}</Alert>}

      <Button
        isDisabled={isSubmitting}
        isLoading={isSubmitting}
        colorScheme="blue"
        onClick={handleSubmit((data) => onSubmit(data))}
      >
        Submit
      </Button>
    </>
  );
}
