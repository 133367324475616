import * as React from 'react';
import { Switch, Route } from 'react-router-dom';

import { Detail } from './Detail';
import List from './List';

const routes = (props) => (
  <Switch>
    <Route exact path="/settings/notification-templates/" component={List} />
    <Route exact path="/settings/notification-templates/add" component={Detail} />
    <Route exact path="/settings/notification-templates/:key" component={Detail} />
    <Route exact path="/settings/notification-templates/:key/:id" component={Detail} />
  </Switch>
);

export default routes;
