// import * as React from 'react';
// import { Button, Input, Dropdown, Segment, Form } from 'semantic-ui-react';
// import { FeathersDropdown } from '@inkcloud/components';

// export interface FilterFormProps {
//   value: any;
//   onChange: Function;
//   onReset: Function;
//   products: any[];
//   categories: any[];
// }

// function initMapper(value) {
//   const defaults = {
//     name: '',
//     url: '',
//     template: '',
//     types: [],
//     type: '',
//     product: '',
//   }

//   return Object.keys(defaults).reduce((p, cur) => {
//     if (!p[cur]) {
//       p[cur] = defaults[cur];
//     }
//     return p;
//   }, value)
// }

// const pageTypeOptions = [
//   { value: 'basic', text: 'Basic' },
//   { value: 'category', text: 'Category' },
//   { value: 'product', text: 'Product' },
// ];

// export const FilterForm: React.FunctionComponent<FilterFormProps> = (props) => {
//   const { value: preInitValue, onChange, onReset } = props;
//   const value = initMapper(preInitValue);
//   const handleChange = (field, value) => onChange({ ...props.value, [field]: value });

//   return (

//     <Segment>
//       <Form size={'small'}>
//         <Form.Group widths={'equal'}>
//           <Form.Field>
//             <Input placeholder="Name..." value={value.name} onChange={(e, data) => handleChange('name', data.value)} />
//           </Form.Field>
//           <Form.Field>
//             <Form.Input placeholder="URL..." value={value.url} onChange={(e, data) => handleChange('url', data.value)} />
//           </Form.Field>
//           <Form.Field>
//             <Form.Input placeholder="Template..." value={value.template} onChange={(e, data) => handleChange('template', data.value)} />
//           </Form.Field>

//         </Form.Group>
//         <Form.Group widths={'equal'}>
//           <Form.Field>
//             <Form.Dropdown
//               selection search clearable
//               placeholder="Page Type..."
//               options={pageTypeOptions}
//               value={value.type}
//               onChange={(e, data) => handleChange('type', data.value)}
//             />
//           </Form.Field>
//           <Form.Field>
//             <Form.Dropdown
//               selection search clearable lazyLoad
//               placeholder="Category"
//               options={props.categories}
//               value={value.category}
//               onChange={(e, data) => handleChange('category', data.value)}
//             />
//           </Form.Field>
//           <Form.Field>
//             <Form.Dropdown
//               selection search clearable lazyLoad
//               placeholder="Product"
//               options={props.products}
//               value={value.product}
//               onChange={(e, data) => handleChange('product', data.value)}
//             />
//           </Form.Field>
//         </Form.Group>
//         <Form.Field >
//           <Button size="mini" content="Reset Form" onClick={() => onReset()} />
//         </Form.Field>
//       </Form>
//     </Segment>
//   );
// };

import {
  useList,
  ListPage,
  basicQueryMap,
  generateOperatorOptions,
  Operator,
} from '@inkcloud/components';

import Feathers from '../../../../bootstrap/feathers';

const pageTypeOptions = [
  { value: 'basic', label: 'Basic' },
  { value: 'category', label: 'Category' },
  { value: 'product', label: 'Product' },
];

export default async function getFilterDefinition() {
  const definition = [
    {
      label: 'Name',
      value: 'name',
      operators: generateOperatorOptions([Operator.Like]),
    },
    {
      label: 'URL',
      value: 'url',
      operators: generateOperatorOptions([Operator.Like]),
    },
    {
      label: 'Template',
      value: 'template',
      operators: generateOperatorOptions([Operator.Like]),
    },
    {
      label: 'Category',
      value: 'category',
      operators: generateOperatorOptions([Operator.Is]),
      valueOptions: [],
      asyncValueOptions: async () =>
        Feathers.service('products-legacy/categories')
          .find({
            query: {
              $sort: { name: 1 },
              $np: 1,
              $select: ['_id', 'name', 'hrCode'],
            },
          })
          .then((results) => results.map((r) => ({ value: r._id, label: r.name }))),
      type: 'multi-select',
    },
    {
      label: 'Product',
      value: 'product',
      operators: generateOperatorOptions([Operator.Is]),
      valueOptions: [],
      asyncValueOptions: async () =>
        Feathers.service('products-legacy/categories')
          .find({
            query: {
              $sort: { name: 1 },
              $np: 1,
              $select: ['_id', 'staticName', 'key'],
            },
          })
          .then((results) =>
            results.filter(
              (r) =>
                (r?.key || r?.staticName) && {
                  value: r._id,
                  label: `${r?.staticName} ${r?.key ? `- ${r.key}` : ''}`,
                }
            )
          ),
      type: 'multi-select',
    },
    {
      label: 'Page Type',
      value: 'type',
      operators: generateOperatorOptions([Operator.Is]),
      valueOptions: pageTypeOptions,
      type: 'select',
    },
  ];

  return definition.sort((a: any, b: any) => a.label.localeCompare(b.label));
}
