import * as React from 'react';
import {
  Button,
  Checkbox,
  Dimmer,
  Loader,
  Header,
  Grid,
  Message,
  Menu,
  Table,
  Segment,
} from 'semantic-ui-react';

export interface SummaryProps {
  subtotal: number;
  salesTax: number;
  discount: number;
  total: number;
}

export default class Summary extends React.PureComponent<SummaryProps, any> {
  render() {
    return (
      <div>
        <Table>
          <Table.Body>
            <Table.Row>
              <Table.Cell>Total</Table.Cell>
              <Table.Cell textAlign={'right'}>$39.99</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>
    );
  }
}
