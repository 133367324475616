import * as React from 'react';
import { Document } from '@react-pdf/renderer';
import { ModelTypes } from '@inkcloud/icapi-types';
import { SalesOrderContext, SalesOrderProvider } from './SalesOrderContext';

type OrderItemPopulated = Exclude<ModelTypes.OrderItems, 'attributes' | 'product'> & {
  attributes: { value: ModelTypes.ProductAttributeTypesLegacy }[];
  product: ModelTypes.ProductLegacy;
  unitPrice: number;
  revenue: number;
};

export type SalesOrderDocumentContext = Omit<ModelTypes.Orders, 'invoice' | 'customer'> & {
  invoice: ModelTypes.Invoices;
  customer: ModelTypes.Customers;
  orderItems: OrderItemPopulated[];

  tenantInfo: {
    locale: string;
    currency: string;
    timezone: string;
  };
};
interface InitialState {
  config: any;
  dataCtx: SalesOrderDocumentContext;
  styles: any;
}
export interface ISalesOrderDocumentProps {
  _initialState: any;
  render: (v: InitialState) => any;
  children?: React.ReactNode;
}

export const mockOptions = {
  title: 'Sales Order',
  creator: 'Ink Cloud',
  producer: 'Ink Cloud',
};

export const SalesOrderDocument: React.FunctionComponent<ISalesOrderDocumentProps> = (props) => {
  const { title, creator, producer } = mockOptions;
  const { children, _initialState, render } = props;

  return (
    <SalesOrderProvider initialState={_initialState}>
      <Document title={title} creator={creator} producer={producer}>
        {render ? render(_initialState) : children}
      </Document>
    </SalesOrderProvider>
  );
};
