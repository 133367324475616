import * as React from 'react';
import { Switch, Route } from 'react-router-dom';

import Batches from './batches';
import Outsource from './outsource';
import View from './view';
import ShortRunBarcode from './short-run-barcode';
import PressControl from './press-control';
import WorkflowStations from './workflow-stations';
import StatusGrid from './status-grid';

const routes = (props) => (
  <Switch>
    <Route path="/production/batches" component={Batches} />
    <Route path="/production/outsource" component={Outsource} />
    <Route path="/production/view" component={View} />
    <Route path="/production/short-run-barcode" component={ShortRunBarcode} />
    <Route path="/production/press-control" component={PressControl} />
    <Route path="/production/workflow-stations" component={WorkflowStations} />
    <Route path="/production/status-grid" component={StatusGrid} />
  </Switch>
);

export default routes;
