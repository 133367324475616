import * as React from 'react';
import { Switch, Route } from 'react-router-dom';
import feathers from '../../../../bootstrap/feathers';
import { List } from './List';
import { Detail } from './Detail';

const { useState, useEffect } = React;

const routes = (props) => {
  const [statusOptions, setStatusOptions] = useState([]);
  const [vendorOptions, setVendorOptions] = useState([]);

  useEffect(() => {
    const loadStatuses = async () => {
      try {
        const res: any = await feathers.service('order-item-statuses').find({
          query: {
            $sort: { name: 1 },
            $np: 1,
          },
        });

        setStatusOptions(res?.map((r) => ({ value: r._id, text: r.name })));
      } catch (e) {
        console.log(e);
      }
    };

    const loadVendors = async () => {
      const results = await (feathers.service('production/vendors') as any).find({
        query: { $np: 1, $sort: { companyName: 1 } },
      });
      setVendorOptions(results.map((m) => ({ ...m, text: m.companyName, value: m._id })));
    };

    loadStatuses();
    loadVendors();
  }, []);

  return (
    <Switch>
      <Route
        exact
        path="/settings/production/workflow-stations"
        component={(innerProps) => <List {...innerProps} statusOptions={statusOptions} />}
      />
      <Route
        exact
        path="/settings/production/workflow-stations/add"
        component={(innerProps) => (
          <Detail {...innerProps} statusOptions={statusOptions} vendorOptions={vendorOptions} />
        )}
      />
      <Route
        exact
        path="/settings/production/workflow-stations/:id"
        component={(innerProps) => (
          <Detail {...innerProps} statusOptions={statusOptions} vendorOptions={vendorOptions} />
        )}
      />
    </Switch>
  );
};

export default routes;
