import * as React from 'react';
import { useHistory, useLocation } from 'react-router';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Sector,
} from 'recharts';

import { colors } from './colors';

export interface ActiveRfqByStatusProps {
  data: { status: string; count: number }[];
}
export const ActiveRfqByStatus: React.FunctionComponent<ActiveRfqByStatusProps> = (props) => {
  const { data } = props;
  const a = '';

  const mappedData = data.map((d, i) => ({
    name: d.status,
    value: d.count,
  }));

  return (
    <ResponsiveContainer width={'100%'} height={200}>
      <PieChart width={400} height={200}>
        <Tooltip />
        <Pie
          data={mappedData}
          dataKey="value"
          cx="50%"
          cy="50%"
          outerRadius={60}
          label={(entry) => entry.name}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
          ))}
        </Pie>
        {/* <Pie data={data02} dataKey="value" cx="50%" cy="50%" innerRadius={70} outerRadius={90} fill="#82ca9d" label /> */}
      </PieChart>
    </ResponsiveContainer>
  );
};
