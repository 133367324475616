import * as React from 'react';
import { Button, Segment, Table, Popup, Label } from 'semantic-ui-react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { FormattedDate, FormattedTime } from 'react-intl';
// import { FilterForm } from './FilterForm';

import { Heading } from './../Heading';

import {
  useList,
  PageHeaderRow,
  PreLoader,
  EmptyWrapper,
  PaginatorWrapper,
} from '@inkcloud/components';

interface ListProps {}

const filterQueryMap = (values) => {};

export const List: React.FunctionComponent<ListProps & RouteComponentProps<any>> = (props) => {
  const {
    match: { params },
    location,
    history,
  } = props;

  const serviceName = `/microsites/notification-settings/${params.micrositeId}`;
  const pathPrefix = `/settings/microsites/${params.micrositeId}/notification-rules`;

  const {
    state,
    reload,
    handleDelete,
    selectedItems,
    clearAll,
    setChecked,
    someSelected,
    toggleAll,
    filter,
    setFilter,
    sort,
    handleSort,
  } = useList({
    feathers: ['find', serviceName, [{ query: { microsite: params.micrositeId } }]],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  const { queryResult, isLoading: isStateLoading } = state as any;

  if (!queryResult) {
    return null;
  }

  return (
    <div>
      <PageHeaderRow header="Notification Rules" subheader="Settings">
        <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
        <Link to={`${pathPrefix}/add`}>
          <Button content="New" primary size={'mini'} />
        </Link>
      </PageHeaderRow>

      <Heading id={params.micrositeId} />

      <EmptyWrapper
        queryResult={queryResult && queryResult.length > 0 && queryResult}
        isStateLoading={isStateLoading}
      >
        <Segment loading={state.isLoading}>
          <PaginatorWrapper.Top
            filter={filter}
            reload={reload}
            data={queryResult as any}
            pathPrefix={`${pathPrefix}`}
          />
          <Table size={'small'} sortable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Key</Table.HeaderCell>
                <Table.HeaderCell>Enable</Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {queryResult &&
                queryResult.length > 0 &&
                (queryResult as any).map((r: any, index: number) => (
                  <Table.Row key={index} verticalAlign="top">
                    <Table.Cell>{r.templateKey}</Table.Cell>
                    <Table.Cell>
                      <Label
                        color={r.enable ? 'green' : 'red'}
                        content={r.enable ? 'True' : 'False'}
                      />
                    </Table.Cell>
                    <Table.Cell textAlign="right">
                      <Popup
                        size="mini"
                        content="Edit"
                        trigger={
                          <Link to={`${pathPrefix}/${r.templateKey}`}>
                            <Button size={'mini'} icon={'edit'} />
                          </Link>
                        }
                      />
                    </Table.Cell>
                  </Table.Row>
                ))}
            </Table.Body>
          </Table>
          <PaginatorWrapper.Bottom
            reload={reload}
            data={queryResult as any}
            pathPrefix={`${pathPrefix}`}
          >
            {someSelected && (
              <Button primary type="button" content="Delete" onClick={handleDelete} />
            )}
          </PaginatorWrapper.Bottom>
        </Segment>
      </EmptyWrapper>
    </div>
  );
};
