import * as React from 'react';
import { Button, Input, Dropdown, Segment, Form } from 'semantic-ui-react';
import { FeathersDropdown } from '@inkcloud/components';
import DatePicker from 'react-datepicker';
import Feathers from './../../bootstrap/feathers';
import { values } from 'redux-form';

const { useState, useEffect } = React;

export interface FilterFormProps {
  value: any;
  onChange: Function;
  onReset: Function;
}

let attr = [];

let attrFields = [];

function initMapper(value) {
  let defaults = {
    trackingNumber: '',
    amount: '',
    humanId: '',
  };

  return Object.keys(defaults).reduce((p, cur) => {
    if (!p[cur]) {
      p[cur] = defaults[cur];
    }
    return p;
  }, value);
}

export function FilterForm(props: FilterFormProps) {
  const { value: preInitValue } = props;

  let value = initMapper(preInitValue);
  const { start, end } = value;

  const onChange = (field, value) => {
    props.onChange && props.onChange({ ...props.value, [field]: value });
  };

  return (
    <>
      <Form size={'small'}>
        <Form.Group widths={'equal'}>
          <Form.Field>
            <Input
              placeholder="Tracking Number..."
              value={value.trackingNumber}
              onChange={(e, data) => onChange('trackingNumber', data.value)}
            />
          </Form.Field>
          <Form.Field>
            <Input
              placeholder="Order ID..."
              value={value.humanId}
              onChange={(e, data) => onChange('humanId', data.value)}
            />
          </Form.Field>
          <Form.Field>
            <Input
              placeholder="Amount..."
              value={value.amount}
              onChange={(e, data) => onChange('amount', data.value)}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group widths={'equal'}>
          <Form.Field>
            <DatePicker
              dateFormat="MM/dd/yyyy"
              placeholderText="Start"
              selected={start}
              selectsStart
              startDate={start}
              endDate={end}
              onChange={(date) => onChange('start', date)}
            />
          </Form.Field>
          <Form.Field>
            <DatePicker
              dateFormat="MM/dd/yyyy"
              placeholderText="End"
              selected={end}
              selectsEnd
              startDate={start}
              endDate={end}
              onChange={(date) => onChange('end', date)}
            />
          </Form.Field>
        </Form.Group>
        <Form.Field>
          <Button size={'tiny'} content="Reset Form" onClick={() => props.onReset()} />
        </Form.Field>
      </Form>
    </>
  );
}
