import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Segment } from 'semantic-ui-react';
import { PageHeaderRow, PreLoader, useFeathers } from '@inkcloud/components';
import feathers from './../../../bootstrap/feathers';
import { DesignTemplateForm } from './DesignTemplateForm';

import { DuplicateModal } from './DuplicateModal';

interface DetailProps {
  products: { value: string; text: string }[];
  categories: { value: string; text: string }[];
}

const serviceName = 'design-templates';
const service = feathers.service(serviceName);
const commonParams = { query: { $populate: ['templateUpload'] } };

const { useState } = React;

export const Detail: React.FunctionComponent<
  DetailProps & RouteComponentProps<{ id?: string; micrositeId: string }>
> = (props) => {
  const {
    match: {
      params: { id, micrositeId },
    },
    history,
  } = props;
  const pathPrefix = `/admin/design-templates`;

  const [errorMessage, setErrorMessage] = useState({
    designTemplate: '',
    duplicate: '',
  });
  const [isDuplicateModaOpen, setIsDuplicateModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState({
    duplicate: false,
  });

  const isEdit = !!id;
  const [state, reload] = useFeathers('get', serviceName, [id, commonParams], true);
  const handleSubmit = async (values) => {
    setErrorMessage({
      ...errorMessage,
      designTemplate: '',
    });

    values = {
      ...values,
      key: values?.key?.replace(/-+$/, ''), // Trim (-) hyphen from end of text
    };

    try {
      const res: any = isEdit
        ? await service.patch(id, values, commonParams)
        : await service.create(values, commonParams);

      if (!res?.result) {
        setErrorMessage({
          ...errorMessage,
          designTemplate: res?.message,
        });
      }

      history.push(pathPrefix);
    } catch (e) {
      const message =
        e.code && e.code < 500
          ? e.message
          : 'We are experiencing technical difficulties. Please try again';

      setErrorMessage({
        ...errorMessage,
        designTemplate: message,
      });
    }
  };

  const pageTitle = isEdit ? 'Edit' : 'New';

  if (isEdit && !state.data) {
    return <PreLoader />;
  }

  let initialData;

  initialData = {
    name: '',
    key: '',
    defaultValue: '',
    clearIfFieldIsEmpty: '',
    description: '',
    tags: [],
    fields: [],
    previews: [],
  };
  if (isEdit) {
    const normalizedFields = state.data.fields.map((f) => {
      if (!f.type) {
        f.type = 'text';
      }

      return f;
    });

    initialData = { ext: 'idml', tags: [], ...state.data, fields: normalizedFields };
  }

  const handleDuplicate = async () => {
    setErrorMessage({
      ...errorMessage,
      duplicate: '',
    });

    setIsSubmitting({
      ...isSubmitting,
      duplicate: true,
    });

    const payload = { ...initialData };

    delete payload._id;

    payload.templateUpload = undefined;
    payload.packageContents = [];
    payload.thumbnails = [];
    payload.createdAt = undefined;
    payload.updatedAt = undefined;

    payload.name = `${payload.name} Copy`;
    payload.key = `${payload.key}-copy`;

    try {
      const res: any = await service.create(payload, { query: {} });

      if (!res?.result) {
        setErrorMessage({
          ...errorMessage,
          duplicate: res?.message,
        });
      }

      setIsDuplicateModalOpen(false);
      history.push(pathPrefix);
    } catch (e) {
      const message =
        e.code && e.code < 500
          ? e.message
          : 'We are experiencing technical difficulties. Please try again';

      setErrorMessage({
        ...errorMessage,
        duplicate: message,
      });
    }

    setIsSubmitting({
      ...isSubmitting,
      duplicate: false,
    });
  };

  return (
    <div>
      <PageHeaderRow header={`${pageTitle} Design Template`} subheader="" subheaderId={''}>
        {isEdit && (
          <Button content="Duplicate" size={'mini'} onClick={() => setIsDuplicateModalOpen(true)} />
        )}
        <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
      </PageHeaderRow>
      <Segment>
        {(!isEdit || state.data) && (
          <DesignTemplateForm
            id={id}
            initialValues={initialData}
            errorMessage={errorMessage.designTemplate}
            history={history}
            onSubmit={handleSubmit}
          />
        )}
      </Segment>

      {isDuplicateModaOpen && (
        <DuplicateModal
          isOpen={isDuplicateModaOpen}
          onClose={() => setIsDuplicateModalOpen(false)}
          onSubmit={handleDuplicate}
          isSubmitting={isSubmitting.duplicate}
          errorMessage={errorMessage.duplicate}
        />
      )}
    </div>
  );
};
