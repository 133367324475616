// import * as React from 'react';
// import { RouteComponentProps } from 'react-router-dom';
// import { connect } from 'react-redux';
// import { Header, Segment, Divider, Button, Form, Dropdown, Message } from 'semantic-ui-react'
// import { services } from '../../../bootstrap/feathers';
// import AuthorizeNetForm from './AuthorizenetForm';
// import StripeForm from './StripeForm';
// import FirstDataForm from './FirstDataForm';
import { PaymentAdapters } from './../../../types';
// import { PageHeaderRow } from '@inkcloud/components';

// const gateways = [
//   { text: 'Authorize.net', value: PaymentAdapters.AuthorizeNet },
//   { text: 'Stripe', value: PaymentAdapters.Stripe },
//   { text: 'FirstData', value: PaymentAdapters.FirstData },
// ]

// export interface IndexComponentProps {
//   history: any;
//   tenant?: string;
//   get?: Function;
//   patch?: Function;
//   settings?: {
//     data: {
//       merchantGateway: {
//         selectedAdapter: string,
//         adapters: any[];
//       }
//     }
//   }
// }

// export interface IndexComponentState {
//   selectedAdapter: string;
//   updatedSuccessful: boolean;
// }

// class IndexComponent extends React.Component<IndexComponentProps, IndexComponentState, RouteComponentProps> {
//   constructor(props: IndexComponentProps) {
//     super(props);
//     this.state = {
//       selectedAdapter: null,
//       updatedSuccessful: false,
//     }
//   }

//   componentWillReceiveProps(next: IndexComponentProps) {
//     if (next.settings.data) {
//       this.setState({
//         ...this.state,
//         selectedAdapter: next.settings.data.merchantGateway.selectedAdapter,
//       });
//     }
//   }

//   componentDidMount() {
//     this.props.get(this.props.tenant)
//       .then(result => {
//       })
//   }

//   handleSelectedAdapterChange = (event, data) => {
//     this.setState({
//       ...this.state,
//       selectedAdapter: data.value,
//       updatedSuccessful: false,
//     })
//   }

//   handleSubmit = (values) => {
//     const payload = {
//       merchantGateway: {
//         selectedAdapter: this.state.selectedAdapter,
//         adapters: {
//           [this.state.selectedAdapter]: {
//             ...values,
//             key: this.state.selectedAdapter,
//           },
//         },
//       }
//     };

//     this.props.patch(this.props.tenant, payload)
//       .then(() => this.setState({ ...this.state, updatedSuccessful: true }))
//   }

//   render() {
//     if (!this.props.settings || !this.props.settings.data) {
//       return null;
//     }

//     const { merchantGateway } = this.props.settings.data;

//     if (!merchantGateway.adapters) {
//       return null;
//     }
//     const foundAdapterKey = Object.keys(merchantGateway.adapters).find(k => this.state.selectedAdapter === merchantGateway.adapters[k].key);
//     const foundAdapter = merchantGateway.adapters[foundAdapterKey];
//     return (
//       <div>
//         <PageHeaderRow header="Payment Processing" subheader="Settings">
//           <Button content="Back" size={'mini'} onClick={() => this.props.history.goBack()} />
//         </PageHeaderRow>
//         <Segment>
//           {this.state.updatedSuccessful &&
//             <Message positive>
//               <Message.Header>Update Successful</Message.Header>
//               <p>Your settings have been successfully updated</p>
//             </Message>
//           }
//           <Dropdown
//             value={this.state.selectedAdapter} selection fluid
//             options={gateways}
//             placeholder="Select your payment gateway"
//             onChange={this.handleSelectedAdapterChange}
//           />
//           <Divider />
//           {
//             this.state.selectedAdapter === PaymentAdapters.AuthorizeNet &&
//             <AuthorizeNetForm
//               form="settingsPaymentAuthorizeNet"
//               onSubmit={this.handleSubmit}
//               initialValues={{ ...foundAdapter }}
//             />
//           }
//           {
//             this.state.selectedAdapter === PaymentAdapters.Stripe &&
//             <StripeForm
//               form="settingsPaymentStripe"
//               onSubmit={this.handleSubmit}
//               initialValues={{ ...foundAdapter }}
//             />
//           }
//           {
//             this.state.selectedAdapter === PaymentAdapters.FirstData &&
//             <FirstDataForm
//               form="settingsPaymentFirstData"
//               onSubmit={this.handleSubmit}
//               initialValues={{ ...foundAdapter }}
//             />
//           }
//         </Segment>
//       </div>
//     );
//   }
// }

// const mapStateToProps = (state) => ({
//   settings: state.tenantSettingsPayment,
//   tenant: state.auth.user.business,
// })

// const mapDispatchToProps = {
//   get: services.tenantSettingsPayment.get,
//   patch: services.tenantSettingsPayment.patch,
// };

// export default connect(mapStateToProps, mapDispatchToProps)(IndexComponent as any);

import * as React from 'react';
import { Switch, Route } from 'react-router-dom';

import { Detail } from './Detail';

const routes = (props) => (
  <Switch>
    <Route exact path="/settings/payment-processing" component={Detail} />
  </Switch>
);

export default routes;
