import * as React from 'react';
import * as superagent from 'superagent';
import Dropzone from 'react-dropzone';
import RichTextEditor from 'react-rte';
import {
  Button,
  Dimmer,
  Loader,
  Icon,
  Header,
  Grid,
  Message,
  Menu,
  Segment,
} from 'semantic-ui-react';

declare var APPENV;

const dropzoneStyle = {
  // width: 200,
  minHeight: 75,
  borderWidth: 2,
  borderColor: '#eaeaea',
  borderStyle: 'dashed',
  borderRadius: 0,
  width: '100%',
  marginTop: 18,
};

const uploadUrl = '/uploads-general';

export interface MessageAddProps {
  tenantId: string;
  onSave: Function;
}

export interface MessageAddState {
  files: any[];
  message: any;
  completedUploadIds: any[];
}

export default class MessageAdd extends React.Component<MessageAddProps, MessageAddState> {
  constructor(props: MessageAddProps) {
    super(props);
    this.state = {
      message: RichTextEditor.createEmptyValue(),
      files: [],
      completedUploadIds: [],
    };
  }

  onChange = (value) => {
    this.setState({ ...this.state, message: value });
  };

  onDrop = (acceptedFiles, rejectedFiles) => {
    // do stuff with files...
    let req = superagent.post(`${APPENV.api.connection}${uploadUrl}`);
    // req.send(`tenant=${this.props.tenantId}`)
    req.timeout(1000 * 60 * 10);
    acceptedFiles.forEach((file) => {
      /* TODO - TAKE FIELDS PROPS AND MAP HERE */
      // req.field('id', this.props.orderItemId)
      req.field('tenantId', this.props.tenantId);
      req.attach('miscFile', file, file.name);
      this.setState({ ...this.state, files: this.state.files.concat([file]) });
    });
    req.end((err, res) => {
      this.setState({
        ...this.state,
        files: this.state.completedUploadIds.concat(res.body.map((f) => f._id)),
      });

      // this.setState({...this.state, files: this.state.files.map( f => (f.isComplete: true )})
      // this.setState({...this.state, isUploading: false});
      //   if (!err) {
      //       this.setState({...this.state, isComplete: true});
      //       this.props.onUpload({ res: res.body });
      //   }
    });
  };

  /*
      const { orderItemId, content, attachments, authorType } = data;

      */
  onSave = () => {
    const payload = {
      content: this.state.message.toString('html'),
      attachments: this.state.completedUploadIds,
    };
    this.props.onSave && this.props.onSave(payload);
  };

  render() {
    return (
      <div>
        <RichTextEditor value={this.state.message} onChange={this.onChange} />
        {/* <Dropzone onDrop={this.onDrop} style={dropzoneStyle}>
          <div style={{textAlign: 'center', padding: 10 }}>
            <Icon size={'large'} name="upload" />
            <br/>
            <div style={{fontSize: 12}}>Drag and drop or click to upload</div>
          </div>
          <ul>
            {
              this.state.files.map(f => <li key={f.name}>{f.name} - {f.size} bytes</li>)
            }
           </ul>
        </Dropzone> */}
        <Button fluid primary onClick={this.onSave}>
          Save
        </Button>
      </div>
    );
  }
}
