import * as React from 'react';
import Feathers from '../../../bootstrap/feathers';

interface IAssignedCustomerProps {
  customer: any;
  microsite: any;
}

export const AssignedCustomer: React.FunctionComponent<IAssignedCustomerProps> = (props) => {
  const { customer, microsite } = props;

  const msName = microsite && microsite.microsite && microsite.microsite.name;

  return (
    <div>
      {customer && !microsite && (
        <>
          <div>{customer.organizationName}</div>
          <div>
            {customer.primaryContact
              ? `${customer.primaryContact.firstName} ${customer.primaryContact.lastName}`
              : ''}
          </div>
          <div>{customer.address1}</div>
          <div>{customer.address2}</div>
          <div>
            {customer.city} {customer.stateProvince} {customer.postalCode}
          </div>
        </>
      )}

      {microsite && (
        <>
          <div>{msName ? `Microsite: ${msName}` : ''}</div>
          <div>
            {microsite.firstName} {microsite.lastName}
          </div>
          <div>{microsite.address && microsite.address.address1}</div>
          <div>{microsite.address && microsite.address.address2}</div>
          <div>
            {microsite.address && microsite.address.city}{' '}
            {microsite.address && microsite.address.stateProvince}{' '}
            {microsite.address && microsite.address.postalCode}
          </div>
        </>
      )}
    </div>
  );
};
