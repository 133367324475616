import * as React from 'react';
import { Button, Checkbox, Form, Message, Segment } from 'semantic-ui-react';
import { Formik, Form as FormikForm, Field, FieldProps, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import brace from 'brace';
import AceEditor from 'react-ace';
import 'brace/mode/javascript';
import 'brace/mode/json';
import 'brace/theme/monokai';

import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';

import { SemanticField } from '../../../common/Form';
import Result from './PrintableTester/Result';

interface PrintableFormProps {
  initialValues: any;
  onSubmit: (v: any) => void;
  isSubmitting?: boolean;
  errorMessage: string;
}

export const documentTypeOptions = [
  {
    text: 'Invoice',
    value: 'invoice',
  },
  {
    text: 'Purchase Order',
    value: 'purchase-order',
  },
  {
    text: 'Sales Order',
    value: 'sales-order',
  },
  {
    text: 'Packing Slip',
    value: 'packing-slip',
  },
  {
    text: 'Pick List',
    value: 'pick-list',
  },
  {
    text: 'Job Ticket',
    value: 'job-ticket',
  },
  {
    text: 'Estimate',
    value: 'estimate',
  },
  {
    text: 'Order Item Label',
    value: 'order-item-label',
  },
];

export const PrintableForm: React.FunctionComponent<PrintableFormProps> = (props) => {
  const { errorMessage, initialValues } = props;

  const PrintableSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
  });

  // const loadTemplateData

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={PrintableSchema}
      onSubmit={(values, { setSubmitting }) => {
        props.onSubmit(values);
      }}
    >
      {(innerProps) => {
        const {
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
          setFieldValue,
          setFieldTouched,
        } = innerProps;

        // console.log('values', values)

        return (
          <FormikForm
            className={`ui form ${props.isSubmitting && !errorMessage && 'loading'} ${
              errorMessage ? 'error' : ''
            }`}
          >
            <Form.Group widths="equal">
              <Form.Field>
                <SemanticField name="name" label="Name" component={Form.Input} />
                <ErrorMessage
                  render={(msg) => <Message negative size="tiny" content={msg} />}
                  name="name"
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field>
                <SemanticField name="description" label="Description" component={Form.TextArea} />
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field>
                <SemanticField
                  selection
                  name="documentType"
                  label="Document Type"
                  options={documentTypeOptions}
                  component={Form.Dropdown}
                />
              </Form.Field>
            </Form.Group>

            <Tabs>
              <TabList>
                <Tab>Source</Tab>
                <Tab>Styles</Tab>
              </TabList>

              <TabPanels>
                <TabPanel>
                  <Form.Group widths="equal">
                    <Form.Field>
                      <Field
                        name="source"
                        render={({ field: fieldProp }: FieldProps<any>) => (
                          <>
                            <AceEditor
                              mode="javascript"
                              theme="monokai"
                              onChange={(data) => {
                                // console.log('raw data', data)
                                fieldProp.onChange({
                                  target: { value: data, name: fieldProp.name },
                                });
                              }}
                              setOptions={{ wrap: true }}
                              name="data-editor"
                              editorProps={{ $blockScrolling: true }}
                              value={fieldProp?.value}
                              width={'100%'}
                              showGutter
                            />
                          </>
                        )}
                      />
                      {/* <SemanticField
                name='source'
                label='Source'
                component={Form.TextArea} /> */}
                    </Form.Field>
                  </Form.Group>
                </TabPanel>
                <TabPanel>
                  <Form.Group widths="equal">
                    <Form.Field>
                      <Field
                        name="styles"
                        render={({ field: fieldProp }: FieldProps<any>) => (
                          <>
                            <AceEditor
                              mode="json"
                              theme="monokai"
                              // onChange={(data) => {
                              //   console.log('raw data', data)
                              //   // console.log('parse data', JSON.parse(data))
                              //   // fieldProp.onChange({ target: { value: JSON.parse(data), name: fieldProp.name } })
                              // }}
                              // focus={hasDataError}
                              setOptions={{ wrap: true }}
                              onBlur={(e, code) => {
                                const anno = code?.session?.$annotations;
                                // console.log('code', code)

                                const data = code?.getValue();
                                const hasError = anno?.some((a) => a.type === 'error');

                                if (!hasError) {
                                  try {
                                    fieldProp.onChange({
                                      target: {
                                        value: JSON.parse(data),
                                        name: fieldProp.name,
                                      },
                                    });
                                  } catch (err) {
                                    console.log('err', err);
                                  }
                                }
                              }}
                              name="data-editor"
                              editorProps={{ $blockScrolling: true }}
                              value={JSON.stringify(fieldProp?.value || {}, null, 2)}
                              width={'100%'}
                              showGutter
                            />
                          </>
                        )}
                      />
                      {/* <SemanticField
                name='source'
                label='Source'
                component={Form.TextArea} /> */}
                    </Form.Field>
                  </Form.Group>
                </TabPanel>
              </TabPanels>
            </Tabs>

            {values?.source && (
              <Result
                documentType={values?.documentType}
                source={values?.source}
                styles={values?.styles}
              />
            )}

            <Form.Group widths="equal">
              <Form.Field>
                <SemanticField
                  toggle
                  name="isDefault"
                  label="Is Default"
                  component={Form.Checkbox}
                />
              </Form.Field>
            </Form.Group>

            <Button primary type="submit">
              Save
            </Button>
          </FormikForm>
        );
      }}
    </Formik>
  );
};
