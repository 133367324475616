import React, { useState } from 'react';

import { FormControl, FormLabel, FormErrorMessage, FormHelperText } from '@chakra-ui/react';
import { CreatableSelect } from 'chakra-react-select';

import { ActionMeta, OnChangeValue } from 'react-select';

interface IQuantitiesInputProps {
  values: number[] | undefined;
  onChange: (v: IQuantitiesInputProps['values']) => void;
}

const components = {
  DropdownIndicator: null,
};

export default function Creatable(props: IQuantitiesInputProps) {
  const { onChange, values = [] } = props;
  const [quantity, setQuantity] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');

  // console.log('quantity value', value);

  const handleAddClick = () => {
    setErrorMessage('');
    // validate quantity
    const normalizedQuantity = quantity.replace(/[^0-9]/g, '');

    if (!normalizedQuantity) {
      setErrorMessage('Enter a valid quantity');
      return;
    }

    // onChange(values.concat(quantity).map(v => Number(v)).sort((a, b) => a - b).map(v => String(v)))
    setQuantity('');
  };

  const handleInputChange = (v: string, actionMeta: ActionMeta) => {
    setErrorMessage('');

    if (actionMeta.action === 'menu-close') return;

    const normalizedQuantity = v?.replace(/[^0-9]/g, '');

    if (!normalizedQuantity && normalizedQuantity !== '') {
      setErrorMessage('Enter a valid quantity');

      return;
    }

    setQuantity(normalizedQuantity);
  };

  const handleChange = (newValue: OnChangeValue, actionMeta: ActionMeta) => {
    if (actionMeta.action === 'clear' || !newValue) {
      onChange([]);
      return;
    }

    onChange(newValue?.map((v) => v.value));
  };

  const handleKeyDown = (event) => {
    setErrorMessage('');

    if (event.key === 'Enter' || event.key === 'Tab') {
      const normalizedQuantity = quantity.replace(/[^0-9]/g, '');

      const isDuplicate = values?.includes(Number(normalizedQuantity));

      if (!normalizedQuantity || isDuplicate || Number(normalizedQuantity) === 0) {
        setErrorMessage(
          `${isDuplicate ? "You can't enter a duplicate value" : 'Enter a valid quantity'}`
        );
        if (isDuplicate) setQuantity('');
        return;
      }
      onChange(
        values
          .concat(Number(normalizedQuantity))
          .map((v) => Number(v))
          .sort((a, b) => a - b)
          .map((v) => Number(v))
      );

      setQuantity('');
      event.preventDefault();
    }
  };

  return (
    <FormControl isInvalid={!!errorMessage}>
      <CreatableSelect
        components={components}
        isClearable
        isMulti
        menuIsOpen={false}
        inputValue={quantity}
        onChange={handleChange}
        onInputChange={handleInputChange}
        onKeyDown={handleKeyDown}
        placeholder=""
        value={values?.map((v) => ({ value: v, label: v }))}
      />

      {errorMessage && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
    </FormControl>
  );
}
