import * as React from 'react';
import { Link } from 'react-router-dom';
import { Table, Label } from 'semantic-ui-react';

import { DetailTable } from '../../common/DetailTable';

interface InfoProps {
  orderItem: any;
  revision: number;
}

export const Info: React.FunctionComponent<InfoProps> = (props) => {
  const { orderItem, revision } = props;

  const {
    order,
    humanId,
    description,
    attributes,
    addOns,
    quantity,
    customTrimHeight,
    customTrimWidth,
  } = orderItem;

  return (
    <>
      <Table basic="very">
        <Table.Body>
          <Table.Row>
            <Table.HeaderCell>Order Item:</Table.HeaderCell>
            <Table.Cell>
              <Link to={`/orders/${order}`}>{humanId}</Link>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell>Revision:</Table.HeaderCell>
            <Table.Cell>
              <Label circular size={'small'} color={'teal'}>
                {revision}
              </Label>
            </Table.Cell>
          </Table.Row>
          <Table.Row verticalAlign="top">
            <Table.HeaderCell>Details:</Table.HeaderCell>
            <Table.Cell>
              <DetailTable
                quantity={quantity}
                attributes={attributes}
                addOns={addOns}
                description={description}
              />
              <Table size="small" basic="very" compact style={{ marginTop: '-1em' }}>
                <Table.Body>
                  {customTrimWidth && (
                    <Table.Row>
                      <Table.Cell collapsing style={{ borderTop: 0 }}>
                        <strong>Custom width</strong>
                      </Table.Cell>
                      <Table.Cell style={{ borderTop: 0 }}>{customTrimWidth}</Table.Cell>
                    </Table.Row>
                  )}
                  {customTrimHeight && (
                    <Table.Row>
                      <Table.Cell collapsing style={{ borderTop: 0 }}>
                        <strong>Custom height</strong>
                      </Table.Cell>
                      <Table.Cell style={{ borderTop: 0 }}>{customTrimHeight}</Table.Cell>
                    </Table.Row>
                  )}
                </Table.Body>
              </Table>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </>
  );
};
