import * as React from 'react';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { Table, Image, Icon, Popup, Button } from 'semantic-ui-react';
import { useList, EmptyWrapper, PaginatorWrapper } from '@inkcloud/components';

import { VariantPrice } from './VariantsInput';

export interface VariantTableProps {
  id: string;
  attached?: string;
  type?: string;
}

const filterQueryMap = (values) => {};

const serviceName = '/products-legacy';

const basedUrl = 'https://storage.googleapis.com/icstorage/';

export const PreVariantTable: React.FunctionComponent<VariantTableProps & RouteComponentProps> = (
  props
) => {
  const { id, attached, type } = props;

  const isOnDemand = type === 'on-demand';

  const { match, location, history } = props;

  const pathPrefix = `/products/${isOnDemand ? '' : 'stock-'}products/${
    isOnDemand ? '' : 'edit/'
  }${id}`;

  const {
    state,
    reload,
    handleDelete,
    selectedItems,
    clearAll,
    setChecked,
    someSelected,
    toggleAll,
    filter,
    setFilter,
    sort,
    handleSort,
  } = useList({
    feathers: [
      'find',
      serviceName,
      [
        {
          query: {
            parent: id,
            $populate: ['variantValues.value'],
          },
        },
      ],
    ],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  const { queryResult, isLoading: isStateLoading } = state as any;

  return (
    <>
      <EmptyWrapper queryResult={queryResult} isStateLoading={isStateLoading} attached={attached}>
        <Table className={`${attached && `${attached} attached`}`}>
          <Table.Body>
            {queryResult?.data?.map((child: any, index: number) => {
              const variants = child?.variantValues
                ?.map((variant) => variant?.value?.name)
                .join(', ');
              return (
                <Table.Row key={index}>
                  <Table.Cell>
                    <input
                      type="checkbox"
                      value={child._id}
                      checked={selectedItems[child._id] || false}
                      onChange={setChecked}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <Link
                      to={`/products/${isOnDemand ? '' : 'stock-'}products/${
                        isOnDemand ? '' : 'edit/'
                      }${child._id}`}
                    >
                      {child.key}
                    </Link>
                  </Table.Cell>
                  <Table.Cell>
                    {child?.images?.[0]?.url ? (
                      <Popup
                        size="tiny"
                        content="Click to view full size"
                        trigger={
                          <a href={`${basedUrl}${child.images?.[0]?.url}`} target="_blank">
                            <Image src={`${basedUrl}${child.images?.[0]?.url}`} size="mini" />
                          </a>
                        }
                      />
                    ) : (
                      <Icon name="image" size="large" />
                    )}
                  </Table.Cell>
                  <Table.Cell>{variants}</Table.Cell>
                  <Table.Cell textAlign="right">
                    <VariantPrice data={child} />
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
        <PaginatorWrapper.Bottom
          reload={reload}
          data={queryResult as any}
          pathPrefix={`${pathPrefix}`}
        >
          {someSelected && <Button primary type="button" content="Delete" onClick={handleDelete} />}
        </PaginatorWrapper.Bottom>
      </EmptyWrapper>
    </>
  );
};

export const VariantTable = withRouter(PreVariantTable);
