import * as React from 'react';
import { Button, Form, Message, Icon } from 'semantic-ui-react';
import { Formik, Form as FormikForm, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { SemanticField } from '../../../../common/Form';
import { ImageUpload } from '../../../products/products/stock-product/StockForm/ImageUpload';

export interface MiscDocumentsFormProps {
  initialValues: any;
  onSubmit: (v: any) => void;
  errorMessage: string;
}

const MiscDocumentsFormSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
});

export const MiscDocumentsForm: React.FunctionComponent<MiscDocumentsFormProps> = (props) => {
  const { initialValues, onSubmit, errorMessage } = props;

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={MiscDocumentsFormSchema}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values);
        }}
      >
        {(innerProps) => {
          const {
            values,
            touched,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            setFieldValue,
            setFieldTouched,
          } = innerProps;

          const fileName = values?.fileName || values?.uploadLog?.originalFilename;

          return (
            <FormikForm className={`ui form ${isSubmitting && !errorMessage && 'loading'}`}>
              <Form.Group widths="equal">
                <SemanticField fluid name="name" label="Name" component={Form.Input} />
                <ErrorMessage
                  render={(msg) => <Message negative size="tiny" content={msg} />}
                  name="name"
                />
              </Form.Group>

              <ImageUpload
                onUpload={(file) => {
                  if (file) {
                    setFieldValue('uploadLog', file?.files?.files?.[0]?.uploadLog);
                    setFieldValue('fileName', file?.files?.files?.[0]?.originalFilename);
                  }
                }}
                showFiles={false}
                multiple={false}
                api="/uploads-misc"
              />
              <div>
                {fileName && (
                  <>
                    <div>
                      <Icon name="file outline" /> {fileName}{' '}
                    </div>
                    <br />
                  </>
                )}
              </div>

              <Form.Field>
                <SemanticField
                  fluid
                  name="designatedPrinterKey"
                  label="Designated Printer"
                  component={Form.Dropdown}
                  selection
                  placeholder={'Select a designated printer...'}
                  options={[
                    { text: 'Printer A', value: 'printer-a' },
                    { text: 'Printer B', value: 'printer-b' },
                    { text: 'Printer C', value: 'printer-c' },
                    { text: 'Printer D', value: 'printer-d' },
                  ]}
                />
                <Message
                  content={
                    'Once you have defined designated printer classes, you can assign to actual printers in the Document Printer tool.'
                  }
                />
                <ErrorMessage
                  render={(msg) => <Message negative size="tiny" content={msg} />}
                  name="designatedPrinterKey"
                />
              </Form.Field>

              <Button primary type="submit" disabled={isSubmitting && !errorMessage}>
                Save
              </Button>
            </FormikForm>
          );
        }}
      </Formik>
      {errorMessage && <Message error content={errorMessage} />}
    </>
  );
};
