import * as React from 'react';
import { FormattedDate } from 'react-intl';
import { Table, Label, Button, Popup } from 'semantic-ui-react';
import { EmptyWrapper } from '@inkcloud/components';

import { VoidModal } from './VoidModal';

interface ActualShipmentProps {
  actualShipments: any[];
  isStateLoading: boolean;
  setIsInvoiceReload: (v: boolean) => void;
  onSubmit: (v: string) => void;
  onPrintModal: () => void;
}

const { useState } = React;

export const ActualShipment: React.FunctionComponent<ActualShipmentProps> = (props) => {
  const { actualShipments, isStateLoading, setIsInvoiceReload, onSubmit, onPrintModal } = props;

  const filteredShipments =
    actualShipments?.filter((a) => a.isVoid === undefined || a.isVoid === false) ?? [];

  const [isVoidModalOpen, setIsVoidModalOpen] = useState(false);
  const [selectedShipment, setSelectedShipment] = useState(null);

  return (
    <EmptyWrapper queryResult={filteredShipments} isStateLoading={isStateLoading}>
      <Table size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Date</Table.HeaderCell>
            <Table.HeaderCell>Carrier/Service</Table.HeaderCell>
            <Table.HeaderCell>Ship to Address</Table.HeaderCell>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.HeaderCell>Items</Table.HeaderCell>
            <Table.HeaderCell>Is Voided</Table.HeaderCell>
            <Table.HeaderCell>Tracking Number</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {actualShipments &&
            actualShipments.length > 0 &&
            actualShipments.map((a: any, index: number) => (
              <Table.Row key={index}>
                <Table.Cell>
                  <FormattedDate value={a.createdAt} />
                </Table.Cell>
                <Table.Cell>{a.serviceDescription}</Table.Cell>
                <Table.Cell>
                  {a.shipToAddress1} {a.shipToCity} {a.shipToStateProvince}
                  {a.shipToPostalCode}
                </Table.Cell>
                <Table.Cell>{a.type}</Table.Cell>
                <Table.Cell>
                  <Label.Group size="mini">
                    {a.orderItems &&
                      a.orderItems.length > 0 &&
                      a.orderItems
                        .sort(
                          (a, b) =>
                            a.orderItemId &&
                            parseInt(a.orderItemId.humanId.split('-')[1]) -
                              parseInt(b.orderItemId.humanId.split('-')[1])
                        )
                        .map((oi: any, idx: number) => (
                          <Label key={idx}>{oi.orderItemId && oi.orderItemId.humanId}</Label>
                        ))}
                  </Label.Group>
                </Table.Cell>
                <Table.Cell>{a.isVoid ? 'Yes' : 'No'}</Table.Cell>
                <Table.Cell>{a.trackingNumber}</Table.Cell>
                <Table.Cell textAlign="right">
                  <Popup
                    size="mini"
                    content="Print Packing Slip"
                    trigger={
                      <Button
                        size="mini"
                        icon={'print'}
                        onClick={() => {
                          onSubmit(a._id);
                          onPrintModal();
                        }}
                      />
                    }
                  />
                  <Popup
                    size="mini"
                    content="Void"
                    trigger={
                      <Button
                        size="mini"
                        icon="ban"
                        onClick={() => {
                          setSelectedShipment(a);
                          setIsVoidModalOpen(true);
                        }}
                      />
                    }
                  />
                </Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
      {isVoidModalOpen && (
        <VoidModal
          isOpen={isVoidModalOpen}
          selectedShipment={selectedShipment}
          setIsInvoiceReload={setIsInvoiceReload}
          onClose={() => {
            setSelectedShipment(null);
            setIsVoidModalOpen(false);
          }}
        />
      )}
    </EmptyWrapper>
  );
};
