import * as React from 'react';
import { Button, Segment, Table, Popup } from 'semantic-ui-react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { FormattedDate, FormattedTime } from 'react-intl';
// import { FilterForm } from "./FilterForm";

import {
  useList,
  PageHeaderRow,
  PreLoader,
  PaginatorWrapper,
  EmptyWrapper,
} from '@inkcloud/components';

interface ListProps {}

const serviceName = '/sales-tax/tax-exemption-review';
const pathPrefix = '/customers/tax-exemption-requests';

const { useState } = React;

export const filterQueryMap = (values) => {
  const filterMap: any = {};

  return [].reduce(
    (m, field) => ({
      ...m,
      [field]: values[field] ? values[field] : undefined,
    }),
    filterMap
  );
};

export const List: React.FunctionComponent<ListProps & RouteComponentProps<any>> = (props) => {
  const {
    match: { params },
    location,
    history,
  } = props;
  const {
    state,
    reload,
    handleDelete,
    selectedItems,
    clearAll,
    setChecked,
    someSelected,
    toggleAll,
    filter,
    setFilter,
    sort,
    handleSort,
  } = useList({
    feathers: [
      'find',
      serviceName,
      [
        {
          query: {
            $sort: { createdAt: -1 },
            $populate: ['customer'],
          },
        },
      ],
    ],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  const { queryResult, isLoading: isStateLoading } = state as any;

  if (!queryResult) {
    return null;
  }

  return (
    <div>
      <PageHeaderRow header="Tax Exemption Request" subheader="List" />

      {/* <FilterForm
        value={filter}
        onChange={v => setFilter(v)}
        onReset={() => setFilter({})}
      /> */}
      <EmptyWrapper queryResult={queryResult} isStateLoading={isStateLoading}>
        <Segment loading={state.isLoading}>
          <PaginatorWrapper.Top
            filter={filter}
            reload={reload}
            data={queryResult as any}
            pathPrefix={`${pathPrefix}`}
          />
          <Table size={'small'} sortable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  <input type="checkbox" onChange={toggleAll} />
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={sort.createdAt ? sort.createdAt : null}
                  onClick={() => handleSort('createdAt')}
                >
                  Date
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={sort.humanId ? sort.humanId : null}
                  onClick={() => handleSort('humanId')}
                >
                  ID
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={sort.organizationName ? sort.organizationName : null}
                  onClick={() => handleSort('organizationName')}
                >
                  Name
                </Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {queryResult &&
                (queryResult as any).data.map((r) => (
                  <Table.Row key={r._id}>
                    <Table.Cell>
                      <input
                        type="checkbox"
                        value={r._id}
                        checked={selectedItems[r._id] || false}
                        onChange={setChecked}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <Popup
                        size="mini"
                        content={<FormattedTime value={r.createdAt} />}
                        trigger={
                          <div>
                            <FormattedDate value={r.createdAt} />
                          </div>
                        }
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <Link to={`/customers/${r.customer?._id}`}>{r.customer?.humanId}</Link>
                    </Table.Cell>
                    <Table.Cell>
                      <Link to={`/customers/${r.customer?._id}`}>
                        {r.customer?.organizationName
                          ? r.customer.organizationName
                          : `${r.customer?.primaryContact?.firstName ?? ''} ${
                              r.customer?.primaryContact?.lastName ?? ''
                            }`}
                      </Link>
                    </Table.Cell>
                    <Table.Cell textAlign="right">
                      <Popup
                        content="Edit"
                        trigger={
                          <Link to={'/customers/tax-exemption-requests/' + r._id}>
                            <Button size={'mini'} icon="edit" />
                          </Link>
                        }
                      />
                    </Table.Cell>
                  </Table.Row>
                ))}
            </Table.Body>
          </Table>
          <PaginatorWrapper.Bottom
            reload={reload}
            data={queryResult as any}
            pathPrefix={`${pathPrefix}`}
          >
            {someSelected && (
              <Button primary type="button" content="Delete" onClick={handleDelete} />
            )}
          </PaginatorWrapper.Bottom>
        </Segment>
      </EmptyWrapper>
    </div>
  );
};
