import * as React from 'react';
import { Page } from '@react-pdf/renderer';
import { PageSize, Orientation } from '@react-pdf/types';
import { InvoiceContext } from './InvoiceContext';

export interface IInvoicePageProps {
  children: React.ReactNode;
}

export const InvoicePage: React.FunctionComponent<IInvoicePageProps> = (props) => {
  const ctx = React.useContext(InvoiceContext);
  const {
    config: { size, orientation },
  } = ctx;
  const { children } = props;
  return (
    <Page size={size} orientation={orientation}>
      {children}
    </Page>
  );
};
