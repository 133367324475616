import * as React from 'react';
import {
  Button,
  Header,
  Form,
  Input,
  Image,
  Modal,
  ModalProps,
  Table,
  Divider,
} from 'semantic-ui-react';
import { Flex, Spinner, Alert } from '@chakra-ui/react';

import { FeathersDropdown } from '@inkcloud/components';
import Feathers from '../../../../bootstrap/feathers';
import { Calculator } from '../../calculator';
import { CustomItem } from '../../custom-item';

interface IProductAddModalProps {
  isCustomItemLoading: boolean;
  handleSubmit: (v: string) => void;
  handleCustomItemSubmit: (v: any, a: any) => void;
  handleClose: ModalProps['onClose'] | any;
  open: boolean;
  isTranslationSite: boolean;
}
const { useState, useEffect } = React;

export const ProductAddModal: React.FunctionComponent<IProductAddModalProps> = (props) => {
  const {
    open,
    isTranslationSite,
    isCustomItemLoading,
    handleClose,
    handleSubmit,
    handleCustomItemSubmit,
  } = props;

  const [selectedCategory, setSelectedCategory] = useState('');
  const [data, setData] = useState(null);
  const [isCustom, setIsCustom] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const loadData = async (cat) => {
    setIsLoading(true);
    setErrorMessage('');
    try {
      const latestData = await Feathers.service('products-legacy/calculator').create({
        category: cat,
        $includeHidden: 1,
      });

      setData(latestData);
    } catch (e) {
      const errMsg = e?.code < 500 ? e?.message : 'Something went wrong. Please try again later.';
      setErrorMessage(errMsg);
    }
    setIsLoading(false);
  };

  return (
    <Modal open={open} onClose={handleClose} closeIcon>
      <Modal.Header>Choose a product</Modal.Header>
      <Modal.Content>
        {!isCustom && !selectedCategory && (
          <React.Fragment>
            <FeathersDropdown
              search
              selection
              fluid
              lazyLoad
              placeholder="Category..."
              serviceName="products-legacy/categories"
              query={{ $sort: { name: 1 }, $np: 1 }}
              resultMapper={(r) => ({ value: r._id, text: r.name })}
              value={selectedCategory}
              onChange={(e, d) => {
                setSelectedCategory(d.value as string);
                loadData(d.value);
              }}
            />
            <Divider hidden />
            <Button content="Add Custom Item" onClick={() => setIsCustom(true)} />
          </React.Fragment>
        )}
        {isLoading && (
          <Flex justifyContent="center" py={5}>
            <Spinner />
          </Flex>
        )}

        {errorMessage && (
          <Alert status="error" my={4}>
            {errorMessage}
          </Alert>
        )}

        {selectedCategory && (
          <React.Fragment>
            <Calculator
              category={selectedCategory}
              data={data}
              isTranslationSite={isTranslationSite}
              onAdd={(v) => {
                handleSubmit(v);
                handleClose();
              }}
            />
          </React.Fragment>
        )}

        {isCustom && (
          <React.Fragment>
            <Header as="h4">Custom</Header>
            <CustomItem
              open={open}
              isCustomItemLoading={isCustomItemLoading}
              onSubmit={handleCustomItemSubmit}
            />
          </React.Fragment>
        )}
      </Modal.Content>
    </Modal>
  );
};
