import * as React from 'react';
import { Modal, Table, Message, Button } from 'semantic-ui-react';

import { CartItem } from './Item';

interface ModifyCartModalProps {
  open: boolean;
  onClose: () => void;
  customerId: string;
}

export const ModifyCartModal: React.FunctionComponent<ModifyCartModalProps> = (props) => {
  const { open, onClose, customerId } = props;

  return (
    <Modal open={open} onClose={onClose} closeIcon>
      <Modal.Header>Edit Active Cart Price</Modal.Header>
      <Modal.Content>
        <CartItem id={customerId} onClose={onClose} />
      </Modal.Content>
    </Modal>
  );
};
