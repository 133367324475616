import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { PageHeaderRow, PreLoader, useFeathers } from '@inkcloud/components';

import { Edit } from './Edit';
import { Add } from './Add';

interface DetailProps {}

export const Detail: React.FunctionComponent<DetailProps & RouteComponentProps<{ id?: string }>> = (
  props
) => {
  const {
    match: {
      params: { id },
    },
    history,
  } = props;

  return (
    <>
      <PageHeaderRow header="Custom Quote" subheader="Customer" subheaderId={''} />

      {id ? <Edit id={id} /> : <Add {...history} />}
    </>
  );
};
