import * as React from 'react';
import { PageSize, Orientation } from '@react-pdf/types';
import { InvoiceDocumentContext } from './InvoiceDocument';

export const InvoiceContext = React.createContext<InvoiceContextInterface>(null);

interface IInvoiceProviderProps {
  initialState: any;
  children: React.ReactNode;
}

interface InvoiceContextInterface {
  dataCtx: InvoiceDocumentContext;
  styles: any;
  config: {
    size: PageSize;
    orientation: Orientation;
  };
}

const config: InvoiceContextInterface['config'] = {
  size: 'A4',
  orientation: 'portrait',
};

export const InvoiceProvider: React.FunctionComponent<IInvoiceProviderProps> = (props) => {
  const { children, initialState } = props;

  // console.log('initial state in this one!!!', initialState);
  const defaultCtx = initialState;
  return <InvoiceContext.Provider value={defaultCtx}>{children}</InvoiceContext.Provider>;
};
