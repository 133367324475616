import React, { useState, useEffect } from 'react';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Alert,
  Box,
  Flex,
  Heading,
  Stack,
  Text,
  Spacer,
  RadioGroup,
  Radio,
  Textarea,
  Divider,
  HStack,
  Tag,
  AlertTitle,
  AlertDescription,
  Grid,
  GridItem,
} from '@chakra-ui/react';

import type { ModelTypes } from '@inkcloud/icapi-types';

import RfqVendorResponse from './RFQ/RfqVendorResponse';

import ItemResponse from './RFQ2/ItemResponse';

import feathers, { icapi } from '../../bootstrap/feathers';

import type { ResponseItemsType, AttributeType } from './common/types';

import { SegmentCard } from '../../common';

type Response = Exclude<ModelTypes.Rfq['responses'], undefined>[number];

interface ISupplierResponseModalProps {
  rfq: ModelTypes.Rfq;
  response: Response;
  attributes: AttributeType[];
  onClose: () => void;
  onReload: () => void;
}

function noQuantity(msg: string) {
  this.message = msg;
  this.code = 406;
}

const responses = ['no-bid', 'responded', 'responded-with-changes'];

const statuses = ['responded', 'responded-with-changes', 'no-bid']


export default function SupplierResponseModal(props: ISupplierResponseModalProps) {
  const { rfq, attributes, response, onClose, onReload } = props;

  const [data, setData] = useState<ModelTypes.Rfq>({});

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [changeMessage, setChangeMessage] = useState(response?.changeMessage ?? '');

  const [responseSubmit, setResponseSubmit] = useState(
    responses.find((r) => r === response.status) || 'responded'
  );

  const areAllItemsHasQty = data?.responses?.[0]?.items
    ?.reduce((acc, cur) => {
      if (!cur?.isOptedOut) {
        const quantities = cur?.quantities?.filter((qty) => !qty?.isOptedOut);
        const hasQty = quantities?.every?.((qty) => (Number(qty?.price) ?? 0) > 0);
        // console.log('hasQty', hasQty)
        acc.push(!!hasQty);
      }

      return acc;
    }, [] as Boolean[])
    ?.every((v) => v);

  const handleSave = async (v) => {
    setIsSubmitting(true);
    setErrorMessage('');

    try {
      if (!areAllItemsHasQty && responseSubmit !== 'no-bid') {
        // eslint-disable-next-line new-cap
        throw new noQuantity('Please input prices for each item');
      }

      const d = data.responses?.filter((r) => r._id === response._id)[0];
      const r = await feathers.service('rfqs/responses').patch(rfq._id, {
        ...d,
        vendor: (d?.vendor as any)?._id,
        status: responseSubmit,
        changeMessage,
      });

      onReload();
      onClose();
    } catch (e) {
      const errMsg = e?.code < 500 ? e?.message : 'An error occurred. Please try again later.';
      setErrorMessage(errMsg);
    }

    setIsSubmitting(false);
  };

  // const handleChange = (v) => {
  //   setData({
  //     ...data,
  //     responses: data.responses?.map((r) =>
  //       (r as any)._id === v._id ? { ...v, status: 'responded' } : r
  //     ),
  //   })
  // }

  const handleChange = (newResponse) => {
    const updatedResponses = data.responses?.map((r) => ({
      ...r,
      items: r.items?.map((i) => {
        const foundItem = newResponse?.find((n) => n.item === i.item);

        if (foundItem) {
          return foundItem;
        }

        return i;
      }),
    }));

    setData({
      ...data,
      responses: updatedResponses,
    });
  };

  useEffect(() => {
    if (rfq) {
      setData(rfq);
    }
  }, [rfq]);

  const updatedResponse = data.responses?.find((r) => r._id === response._id) ?? {};

  const updatedItems = data.items?.filter((item) =>
    updatedResponse?.items?.find((i) => i !== item._id)
  );

  const allItemsAreOptedOut = (data?.responses?.[0]?.items as ResponseItemsType)?.every(
    (i) => i?.isOptedOut
  );


  const isDisabledSubmit = allItemsAreOptedOut || !statuses?.find((s) => s === responseSubmit)


  return (
    <Modal isOpen={true} onClose={onClose} size="full">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Edit Supplier's Response - {(updatedResponse?.vendor as any)?.companyName ?? ''}
        </ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          {/* {updatedResponse?.status === 'responded' && (

          )} */}
          {/* {updatedResponse?.status === 'no-bid' && (
            <Alert status="warning" mb={3}>
              Supplier has declined to bid
            </Alert>
          )} */}
          {/* {updatedResponse?.status === 'pending' && ( */}
          {/* // <>
            //   <Alert status="warning" mb={3}>
            //     RFQ is open. Supplier has not responded
            //   </Alert> */}

          <Box mb={8}>
            <SegmentCard title="RFQ Info">
              <Text fontSize={'medium'}>How would you like to proceed?</Text>
              <br />
              <RadioGroup
                onChange={(e) => {
                  setResponseSubmit(e as NonNullable<Response['status']>);
                  setErrorMessage('');
                  setChangeMessage('');
                }}
                value={responseSubmit}
              >
                <Stack direction="row">
                  <Radio value="responded">Respond with quote </Radio>
                  <Radio value="responded-with-changes">Respond with changes</Radio>
                  <Radio value="no-bid">Decline bid</Radio>
                </Stack>
              </RadioGroup>

              {responseSubmit === 'responded-with-changes' && (
                <>
                  <br />
                  <Text>Describe any changes to specs and proceed to fill out costs below</Text>
                  <Textarea
                    placeholder="Describe changes to specs..."
                    value={changeMessage}
                    onChange={(e) => setChangeMessage(e.target.value)}
                  />
                  <br />
                </>
              )}
              {responseSubmit === 'no-bid' && (
                <>
                  <br />
                  <Text>Optionally provide a reason for not bidding </Text>
                  <Textarea
                    placeholder="No-bid reason..."
                    value={changeMessage}
                    onChange={(e) => setChangeMessage(e.target.value)}
                  />
                  <br />
                </>
              )}
              {responseSubmit === 'no-bid' && (
                <>
                  <Button
                    colorScheme="green"
                    mt={4}
                    mb={4}
                    mr={2}
                    onClick={handleSave}
                    isDisabled={isSubmitting}
                    isLoading={isSubmitting}
                  >
                    Submit Response
                  </Button>
                  {errorMessage && (
                    <Alert my={3} status="error">
                      {errorMessage}
                    </Alert>
                  )}
                </>
              )}
            </SegmentCard>
          </Box>

          {updatedItems?.map((item, index) => (
            <ItemResponse
              index={index}
              responseItems={updatedResponse.items as any}
              item={item}
              key={item._id}
              attributes={attributes}
              // isReadOnly={isReadOnly}
              isMenuHidden={true}
              onChange={handleChange}
            />
          ))}
          {errorMessage && <Alert status="error">{errorMessage}</Alert>}
        </ModalBody>

        <ModalFooter>
          <Button
            isLoading={isSubmitting}
            isDisabled={isSubmitting || isDisabledSubmit}
            colorScheme="blue"
            mr={3}
            onClick={handleSave}
          >
            Save
          </Button>
          <Button mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
