import * as React from 'react';
import { Switch, Route } from 'react-router-dom';

import { BrandingForm } from './BrandingForm';

const routes = (props) => (
  <Switch>
    <Route exact path="/settings/branding" component={BrandingForm} />
  </Switch>
);

export default routes;
