import * as React from 'react';
import { Box, Flex, Divider, Heading, Tag, Text, Spacer } from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';

interface IPickItemCardProps {
  isActive?: boolean;
  quantity: number;
  pickedQuantity: number;
  sku: string;
  location: string;
  orderItemHumanId: string;
  description: string;
  onSelect: () => void;
  // Can use right click to simulate a scan for testing or can be used for other purposes later.
  onScan?: (scanCapture: string) => void;
}

const PickItemCard: React.FunctionComponent<IPickItemCardProps> = (props) => {
  const {
    isActive,
    quantity,
    pickedQuantity = 0,
    location,
    sku,
    orderItemHumanId,
    description,
    onSelect,
    onScan,
  } = props;

  // const righClickHandler = (event) => {
  //   event.preventDefault();
  //   if (onRightClick && isActive) {
  //     onRightClick();
  //   }
  // }

  const elementRef = React.useRef<HTMLDivElement>(null);
  const scanInput = React.useRef('');

  React.useEffect(() => {
    if (elementRef.current) {
      // elementRef.current.addEventListener('contextmenu', righClickHandler);
      elementRef.current?.focus();

      return () => {
        // elementRef.current?.removeEventListener('contextmenu', righClickHandler);
      };
    }

    return () => {};
  }, [elementRef.current, isActive]);

  const isDone = pickedQuantity === quantity;
  let borderColor = 'gray.200';

  if (isDone) {
    borderColor = 'green.200';
  } else if (isActive) {
    borderColor = 'blue.200';
  }
  return (
    <Box
      bgColor={isActive ? 'white' : 'gray.50'}
      borderWidth={isActive ? 2 : 1}
      mx={isActive ? 0 : 4}
      my={isActive ? 0 : 2}
      px={2}
      py={2}
      tabIndex={isActive ? 0 : undefined}
      borderColor={borderColor}
      onClick={onSelect}
      ref={elementRef}
      onKeyDown={(e) => {
        e.preventDefault();
       if (e.key === 'Enter' && isActive) {
          onScan?.(scanInput.current);
          scanInput.current = '';
        } else {
          if (e.key === 'Shift') {
            // ignore shift keys
            return;
          }
          scanInput.current = `${scanInput.current}${e.key}`.trim();
        }
      }}
      onBlur={(e) => {
        elementRef.current?.focus();
      }}
    >
      <Heading color={'blue.600'} textTransform={'uppercase'} as={'h5'} size={'xs'}>
        {sku}
      </Heading>
      <Flex alignContent={'baseline'} alignItems={'baseline'}>
        <Text fontSize={'sm'} textTransform={'uppercase'}>
          Location
        </Text>
        <Tag>{location}</Tag>
        <Spacer />
        <Text size={'xs'} fontWeight={'normal'} color={isDone ? 'green' : undefined}>
          {isDone && <CheckCircleIcon color={'green'} mr={2} />}
          Picked {pickedQuantity} of {quantity}
        </Text>
      </Flex>

      {isActive && (
        <>
          <Divider my={2} />
          <Text fontSize={'xs'} fontWeight={'semibold'}>
            {orderItemHumanId}
          </Text>
          <Text fontSize={'xs'}>{description}</Text>
        </>
      )}
    </Box>
  );
};

export default PickItemCard;
