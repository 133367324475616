import React, { useState, useRef, forwardRef } from 'react';
import {
  Box,
  Input,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
  useToken,
} from '@chakra-ui/react';
import DatePicker from 'react-datepicker';
import { FaChevronDown } from 'react-icons/fa';
import {
  startOfMonth,
  endOfMonth,
  subMonths,
  previousMonday,
  previousSunday,
  startOfWeek,
  endOfWeek,
  startOfQuarter,
  endOfQuarter,
  endOfYear,
  subDays,
} from 'date-fns';

interface IDateRangePickerProps {
  startDate: Date;
  endDate: Date;
  onChange: (value: Date, name: string) => void;
  onChangeRange?: (values: { [key: string]: Date }) => void;
  locale?: string;
  size?: string;
  label?: string;
  labelSize?: string;
}

function styleFunc(name: string) {
  if (name === 'startDate') {
    return {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    };
  }

  return {
    borderRadius: 0,
    borderLeft: 0,
  };
}

export default function DateRangePicker(props: IDateRangePickerProps) {
  const [sm] = useToken('sizes', ['8']);

  const {
    label = 'Date range:',
    labelSize = 'md',
    startDate,
    endDate,
    onChange,
    onChangeRange,
    locale = 'en',
    size = sm,
  } = props;

  const startDateRef = useRef(null);
  const endDateRef = useRef(null);

  const [range, setRange] = useState('');

  const CustomInput = forwardRef(({ value, name, onClick }: any, ref: any) => (
    <Input
      size={size}
      height={size}
      style={{
        ...styleFunc(name),
        minHeight: size,
      }}
      ref={ref}
      defaultValue={value}
      onClick={onClick}
      autoFocus
    />
  ));

  return (
    <Box>
      <Text mb={2} fontSize={labelSize}>
        <Text as="strong">{label}</Text> {range}{' '}
      </Text>
      <Box display="inline-flex">
        <Box>
          <DatePicker
            ref={startDateRef}
            name="startDate"
            onChange={(date: Date) => onChange(date, 'startDate')}
            selected={startDate}
            selectsStart
            locale={locale}
            customInput={<CustomInput />}
          />
        </Box>
        <Box>
          <DatePicker
            ref={endDateRef}
            name="endDate"
            onChange={(date: Date) => onChange(date, 'endDate')}
            selected={endDate}
            selectsEnd
            minDate={startDate}
            locale={locale}
            customInput={<CustomInput />}
          />
        </Box>
        <Box>
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label="Select Date"
              icon={<FaChevronDown />}
              borderStartRadius={0}
              height={size}
              minW={size}
              verticalAlign="top"
            ></MenuButton>
            <MenuList>
              <MenuItem
                onClick={() => {
                  const today = new Date();
                  onChange(today, 'startDate');
                  onChange(today, 'endDate');
                  onChangeRange?.({ startDate: today, endDate: today });
                  setRange('Today');
                }}
              >
                Today
              </MenuItem>
              <MenuItem
                onClick={() => {
                  const yesterday = subDays(new Date(), 1);
                  onChange(yesterday, 'startDate');
                  onChange(yesterday, 'endDate');

                  onChangeRange?.({ startDate: yesterday, endDate: yesterday });
                  setRange('Yesterday');
                }}
              >
                Yesterday
              </MenuItem>
              <MenuItem
                onClick={() => {
                  const start = startOfWeek(new Date(), { weekStartsOn: 1 });
                  const end = endOfWeek(new Date(), { weekStartsOn: 1 });
                  onChange(start, 'startDate');
                  onChange(end, 'endDate');
                  onChangeRange?.({ startDate: start, endDate: end });
                  setRange('This Week');
                }}
              >
                This Week
              </MenuItem>
              <MenuItem
                onClick={() => {
                  const end = previousSunday(new Date());
                  const start = previousMonday(end);
                  onChange(start, 'startDate');
                  onChange(end, 'endDate');
                  onChangeRange?.({ startDate: start, endDate: end });
                  setRange('Last Week');
                }}
              >
                Last Week
              </MenuItem>
              <MenuItem
                onClick={() => {
                  const start = startOfMonth(new Date());
                  const end = endOfMonth(new Date());
                  onChange(start, 'startDate');
                  onChange(end, 'endDate');
                  onChangeRange?.({ startDate: start, endDate: end });
                  setRange('This Month');
                }}
              >
                This Month
              </MenuItem>
              <MenuItem
                onClick={() => {
                  const start = subMonths(startOfMonth(new Date()), 1);
                  const end = subMonths(endOfMonth(new Date()), 1);
                  onChange(start, 'startDate');
                  onChange(end, 'endDate');
                  onChangeRange?.({ startDate: start, endDate: end });
                  setRange('Last Month');
                }}
              >
                Last Month
              </MenuItem>
              <MenuItem
                onClick={() => {
                  const start = startOfQuarter(new Date());
                  const end = endOfQuarter(new Date());
                  onChange(start, 'startDate');
                  onChange(end, 'endDate');
                  onChangeRange?.({ startDate: start, endDate: end });
                  setRange('This Quarter');
                }}
              >
                This Quarter
              </MenuItem>
              <MenuItem
                onClick={() => {
                  const start = subMonths(startOfQuarter(new Date()), 3);
                  const end = subMonths(endOfQuarter(new Date()), 3);
                  onChange(start, 'startDate');
                  onChange(end, 'endDate');
                  onChangeRange?.({ startDate: start, endDate: end });
                  setRange('Last Quarter');
                }}
              >
                Last Quarter
              </MenuItem>
              <MenuItem
                onClick={() => {
                  const start = new Date(new Date().getFullYear(), 0, 1);
                  const end = new Date();
                  onChange(start, 'startDate');
                  onChange(end, 'endDate');
                  onChangeRange?.({ startDate: start, endDate: end });
                  setRange('This Year to Date');
                }}
              >
                This year to date
              </MenuItem>
              <MenuItem
                onClick={() => {
                  const start = new Date(new Date().getFullYear() - 1, 0, 1);
                  const end = new Date();
                  onChange(start, 'startDate');
                  onChange(end, 'endDate');
                  onChangeRange?.({ startDate: start, endDate: end });
                  setRange('Last Year to Date');
                }}
              >
                Last year to date
              </MenuItem>
              <MenuItem
                onClick={() => {
                  const start = new Date(new Date().getFullYear(), 0, 1);
                  const end = endOfYear(new Date());
                  onChange(start, 'startDate');
                  onChange(end, 'endDate');
                  onChangeRange?.({ startDate: start, endDate: end });
                  setRange('This Year');
                }}
              >
                This Year
              </MenuItem>
              <MenuItem
                onClick={() => {
                  const start = new Date(new Date().getFullYear() - 1, 0, 1);
                  const end = endOfYear(new Date(new Date().getFullYear() - 1, 0, 1));
                  onChange(start, 'startDate');
                  onChange(end, 'endDate');
                  onChangeRange?.({ startDate: start, endDate: end });
                  setRange('Last Year');
                }}
              >
                Last Year
              </MenuItem>
              <MenuItem
                onClick={() => {
                  (startDateRef as any)?.current?.setOpen(true);
                  setRange('');
                  (endDateRef as any)?.current?.setFocus(false);
                }}
              >
                Custom
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
      </Box>
    </Box>
  );
}
