import * as React from 'react';
import {
  Button,
  Form,
  Header,
  Input,
  Image,
  Grid,
  Message,
  Segment,
  Modal,
  Dropdown,
  Table,
} from 'semantic-ui-react';
import {
  Formik,
  Form as FormikForm,
  Field,
  ErrorMessage,
  FieldArray,
  FastField,
  FastFieldProps,
  FieldProps,
} from 'formik';
import { Select } from 'chakra-react-select';
import * as Yup from 'yup';
import { parseISO } from 'date-fns';
import DatePicker from 'react-datepicker';
import { groupBy, DownloadZip } from '../../../../../common';
import { SemanticField } from '../../../../../common/Form';
import { QuantitySettings } from './QuantitySettings';
import { initialMapper, submissionMapper } from './helpers';
import { PriceTiers } from './PriceTiers';
import { ImageUpload } from './ImageUpload';
import { Variants } from './variants/VariantsInput';
import { Composite } from './Composite';
import { FeathersDropdown } from '@inkcloud/components';

import { withRouter, RouteComponentProps } from 'react-router-dom';

import Feathers from './../../../../../bootstrap/feathers';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { Card } from './Card';

import { VariantTable } from './variants/VariantTable';

import { slugify2 as slugifyKey } from './../../../../../common';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertToRaw, convertFromHTML } from 'draft-js';

import { PagePublish } from './,,/../../../PagePublish';

import draftToHtml from 'draftjs-to-html';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { ModelTypes } from '@inkcloud/icapi-types';

interface StockProductFormProps {
  initialValues: any;
  handleSubmit: Function;
  isSubmitting?: boolean;
  isEdit: boolean;
  errorMessage?: string;
}

export const editorStyle = {
  padding: '1em',
  borderStyle: 'solid',
  borderWidth: '1px',
  borderColor: '#dddddd',
  borderRadius: '.28571429rem',
};

const periodOptions = [
  { text: 'Day', value: 'day' },
  { text: 'Week', value: 'week' },
  { text: 'Month', value: 'month' },
  { text: 'Quarter', value: 'quarter' },
  { text: 'Year', value: 'year' },
];
const customFieldOptions = [
  { text: 'Text', value: 'text' },
  { text: 'Select', value: 'select' },
  { text: 'Multi Select', value: 'multi-select' },
  { text: 'Datepicker', value: 'datepicker' },
  { text: 'Checkbox', value: 'checkbox' },
  { text: 'File', value: 'file' },
];

// for some reason `SemanticField` removes field in object when value is empty
const CustomTextArea = ({ field, ...props }) => (
  <>
    {props?.label && <label>{props?.label}</label>}
    <textarea {...field} rows={3}>
      {field.value}
    </textarea>
  </>
);

const OptionsField = ({ name }: any) => (
  <div className="ui form">
    {' '}
    <Field
      name={name}
      render={({ field }: FieldProps<any>) => (
        <div className="field">
          <FieldArray name={`${field.name}`}>
            {(arrayHelpers) => (
              <React.Fragment>
                <div>
                  {field.value?.map((option, index) => (
                    <Form.Group key={index} widths="equal">
                      <Form.Field>
                        <label>Key</label>
                        <Field name={`${field.name}[${index}].key`} />
                        <ErrorMessage
                          render={(msg) => <Message negative size="tiny" content={msg} />}
                          name={`${field.name}[${index}].key`}
                        />
                      </Form.Field>
                      <Form.Field>
                        <label>Value</label>
                        <Field name={`${field.name}[${index}].value`} />
                        <ErrorMessage
                          render={(msg) => <Message negative size="tiny" content={msg} />}
                          name={`${field.name}[${index}].value`}
                        />
                      </Form.Field>
                      <div>
                        <div className="field">
                          <label>&nbsp;</label>
                          <Button
                            type="button"
                            icon="remove"
                            onClick={() => arrayHelpers.remove(index)}
                          />
                        </div>
                      </div>
                    </Form.Group>
                  ))}
                </div>
                <Button
                  type="button"
                  size="tiny"
                  onClick={() => {
                    arrayHelpers.push({ key: '', value: '' });
                  }}
                  content="Add Field"
                />
              </React.Fragment>
            )}
          </FieldArray>
        </div>
      )}
    />
  </div>
);

const baseUrl = 'https://storage.googleapis.com/icstorage';

const { useState, useEffect } = React;

export const PreStockProductForm: React.FC<StockProductFormProps & RouteComponentProps> = (
  props
) => {
  const [isCustomFieldOpenModal, setIsCustomFieldOpenModal] = useState(false);
  const [modalIndex, setModalIndex] = useState(null);
  const [glAccounts, setGLAccounts] = useState(null);
  const [categories, setCategories] = useState<
    { key: string; text: string; value: string; category: ModelTypes.ProductCategoryLegacy }[]
  >([]);

  const blocksFromHTML = convertFromHTML(`${props?.initialValues?.description ?? ''}`);

  useEffect(() => {
    const loadGLAccounts = async () => {
      try {
        const res: any = await Feathers.service('/general-ledger-accounts').find({
          query: {
            // $np: 1
          },
        });

        setGLAccounts(groupBy(res?.data));
      } catch (e) {}
    };
    const loadCategories = async () => {
      try {
        const res: any = await Feathers.service('/products-legacy/categories').find({
          query: {
            $sort: { name: 1 },
            $select: ['_id', 'name', 'internalName', 'pricingMethod'],
            $np: 1,
          },
        });
        setCategories(
          res?.map((c: any) => ({
            isQuantitiesFixed: c?.isQuantitiesFixed,
            text: `${c.name}${c.internalName ? ` (${c.internalName})` : ''}`,
            value: c?._id,
            category: c,
          }))
        );
      } catch (e) {
        console.error(e);
      }
    };

    loadCategories();
    loadGLAccounts();
  }, []);
  // console.log('categories', categories)
  const [editorState, setEditorState] = React.useState(
    EditorState.createWithContent(
      ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap)
    )
  );

  const InventoryProductSchema = Yup.object().shape({
    shortDescription: Yup.string(),
    staticName: Yup.string(),
    price: Yup.number().typeError('Must be a number'),
    cost: Yup.number().typeError('Must be a number'),
    category: Yup.string().required(),
    leadTimeDays: Yup.number().typeError('Must be a number'),
    customFieldsSchema: Yup.array().of(
      Yup.object().shape({
        key: Yup.string().required('Required'),
        label: Yup.string().required('Required'),
        type: Yup.string().required('Required'),
        options: Yup.array()
          .of(
            Yup.object().shape({
              key: Yup.string(),
              value: Yup.string(),
            })
          )
          .when('type', {
            is: (value) => value === 'select' || value === 'multi-select',
            then: Yup.array().of(
              Yup.object().shape({
                key: Yup.string().required('Required'),
                value: Yup.string().required('Required'),
              })
            ),
            otherwise: Yup.array(),
          }),
      })
    ),
    metadata: Yup.array().of(
      Yup.object().shape({
        key: Yup.string().required('Required'),
      })
    ),
    key: Yup.string().test('is-not-used', `Key is already in use`, function (value) {
      if (props.initialValues && props.initialValues._id && props.initialValues.key === value) {
        return Promise.resolve(true);
      }
      return Feathers.service<any>('/products-legacy/')
        .find({ query: { key: value } })
        .then((results: any) => results.data.length === 0);
    }),
  });

  return (
    <Formik
      initialValues={initialMapper(props.initialValues)}
      enableReinitialize={true}
      validationSchema={InventoryProductSchema}
      onSubmit={(values, { setSubmitting }) => {
        props.handleSubmit(submissionMapper(values));
      }}
    >
      {(formProps) => {
        const {
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
          setFieldValue,
          setFieldTouched,
        } = formProps;

        const handleAddition = (e, data: any) => {
          const foundTag = values?.tags?.find((tag) => tag === data.value);

          if (!foundTag) {
            setFieldValue('tags', [...(values.tags || []), data.value]);
          }
        };
        const categoryValue = values?.category?._id || values?.category;

        const handleSlugify = (v) => setFieldValue('key', slugifyKey(v));

        const hasNumPages = values?.attributes?.find((a) => a.attribute === 'NUM_PAGES');

        const isVariantChild = !!values?.parent;

        // console.log('values', values);
        const isQuantitiesFixed = values?.category?.pricingMethod === '1';
        // console.log('isQuantitiesFixed', isQuantitiesFixed)

        const handleEditorChange = (editorStateArg) => {
          setEditorState(editorStateArg);
          setFieldValue('description', draftToHtml(convertToRaw(editorState.getCurrentContent())));
        };

        const startAt =
          typeof values?.startAt === 'string' ? parseISO(values?.startAt) : values?.startAt;
        const expireAt =
          typeof values?.expireAt === 'string' ? parseISO(values?.expireAt) : values?.expireAt;

        return (
          <FormikForm className={`ui form ${!!props.errorMessage && 'error'}`} autoComplete="off">
            <Segment loading={props.isSubmitting && !!props.errorMessage && true}>
              <Segment secondary>
                <Form.Field>
                  <label>Defintion Type</label>
                  <Form.Group inline>
                    <Form.Radio
                      label="General"
                      name="isMicrositeSpecific"
                      // value={'true'}
                      checked={!values.isMicrositeSpecific}
                      onBlur={handleBlur}
                      onChange={() => {
                        setFieldValue('isMicrositeSpecific', false);
                        setFieldValue('microsite', undefined);
                      }}
                      disabled={values?._id}
                    />
                    <Form.Radio
                      label="Microsite Specific"
                      name="isMicrositeSpecific"
                      // value={'false'}
                      checked={values.isMicrositeSpecific || values.microsite !== undefined}
                      onBlur={handleBlur}
                      onChange={() => setFieldValue('isMicrositeSpecific', true)}
                      disabled={values?._id}
                    />
                  </Form.Group>
                  {(values.isMicrositeSpecific || values.microsite !== undefined) && (
                    <Form.Field>
                      <Field
                        name="microsite"
                        render={({ field }: FieldProps<any>) => (
                          <>
                            {/* <label>Microsite</label> */}
                            <FeathersDropdown
                              search
                              selection
                              fluid
                              clearable
                              placeholder="Select Microsite..."
                              serviceName="/microsites"
                              disabled={values?._id}
                              query={{
                                $np: 1,
                                $sort: { name: 1 },
                                $select: ['_id', 'name'],
                              }}
                              resultMapper={(r) => ({ value: r._id, text: r.name })}
                              {...field}
                              onChange={(e, data) =>
                                field.onChange({
                                  target: { value: data.value, name: 'microsite' },
                                } as any)
                              }
                            />
                          </>
                        )}
                      />
                    </Form.Field>
                  )}
                </Form.Field>
              </Segment>

              <Form.Group widths="equal">
                <Form.Field>
                  <SemanticField
                    name="staticName"
                    label="Product Name"
                    component={Form.Input}
                    autoComplete="off"
                    onChange={(v) => {
                      if (!values?._id) {
                        setFieldValue('key', slugifyKey(v));
                      }
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <SemanticField
                    name={'key'}
                    label="SKU"
                    component={Form.Input}
                    autoComplete="off"
                    onChange={handleSlugify}
                  />
                  <ErrorMessage
                    render={(msg) => <Message negative size="tiny" content={msg} />}
                    name="key"
                  />
                </Form.Field>
                <Form.Field>
                  <SemanticField
                    name={'vanitySku'}
                    label="Vanity SKU"
                    component={Form.Input}
                    autoComplete="off"
                    // onChange={handleSlugify}
                  />
                  <ErrorMessage
                    render={(msg) => <Message negative size="tiny" content={msg} />}
                    name="key"
                  />
                  <Segment size="mini" compact secondary basic>
                    Not required and not used for reports. This value can be shown on the store
                    front if the customer prefers the sku shown to be in a special format that
                    differs from internal format.
                  </Segment>
                </Form.Field>
                <Form.Field>
                  <SemanticField
                    name={'aliasSku'}
                    label="Alias SKU"
                    component={Form.Input}
                    autoComplete="off"
                    // onChange={handleSlugify}
                  />
                  <ErrorMessage
                    render={(msg) => <Message negative size="tiny" content={msg} />}
                    name="key"
                  />
                  <Segment size="mini" compact secondary basic>
                    Not required and not used for reports. Can be used to store 3rd party's SKU for
                    mapping products in an integration
                  </Segment>
                </Form.Field>
              </Form.Group>

              <div className="field">
                <label>Category</label>
                {/* <Select
                  useBasicStyles
                  placeholder=""
                  value={values?.category?._id || values?.category}
                  options={categories?.map((c) => ({ label: c.name, value: c }))}
                  // onChange={(e) =>
                  //   field.onChange({
                  //     target: { value: e.value, name: 'category' },
                  //   } as any)
                  // }
                /> */}
                <Form.Field>
                  <Dropdown
                    selection
                    search
                    clearable
                    name="category"
                    component={Form.Dropdown}
                    disabled={isVariantChild}
                    value={values?.category?._id || values?.category}
                    options={categories as any}
                    onChange={(e, data) =>
                      setFieldValue(
                        'category',
                        categories?.find((c) => c.value === data.value)?.category
                      )
                    }
                  />
                </Form.Field>
                {/* <SemanticField
                  selection
                  search
                  clearable
                  name="category"
                  component={Form.Dropdown}
                  disabled={isVariantChild}
                  value={t?.value}
                  options={categories}
                  // value={field?.value?._id || field?.value}
                  onChange={(e, data) => setFieldValue('category', data.value)}
                />
                <FastField
                  name="category"
                  render={({ field }: FieldProps<any>) => {
                    console.log('field', field)

                    const value = field?.value?._id || field?.value

                    return (
                      <FeathersDropdown
                        search
                        fluid
                        selection
                        clearable
                        disabled={isVariantChild}
                        placeholder="Category..."
                        serviceName="/products-legacy/categories"
                        query={{
                          $sort: { name: 1 },
                          $select: ['_id', 'name', 'internalName', 'pricingMethod'],
                          $np: 1,
                        }}
                        resultMapper={(r) => ({
                          key: r._id,
                          value: r,
                          text: `${r.name}${r.internalName ? ` (${r.internalName})` : ''}`,
                        })}
                        {...field}
                        value={categories?.find((c) => c._id === value) || value}
                        onChange={(e, data) =>
                          field.onChange({
                            target: { value: data.value, name: 'category' },
                          } as any)
                        }
                      />
                    )
                  }}
                /> */}
                <ErrorMessage
                  render={(msg) => <Message negative size="tiny" content={msg} />}
                  name="category"
                />
              </div>

              <Form.Field>
                {/* <SemanticField
                name={"description"}
                label="Description"
                component={Form.TextArea}
              /> */}
                {/* <FastField
                  name="description"
                  label="Description"
                  component={CustomTextArea}
                /> */}
                {/* <SemanticField
                name={"shortDescription"}
                label="Short Description"
                component={Form.TextArea}
              /> */}
                <label>Description</label>
                <Editor
                  editorState={editorState}
                  wrapperStyle={editorStyle}
                  editorStyle={{
                    ...editorStyle,
                    maxHeight: '500px',
                    paddingTop: '.5em',
                    paddingBottom: '.5em',
                  }}
                  onEditorStateChange={handleEditorChange}
                />
              </Form.Field>

              <Form.Field>
                <FastField
                  name="shortDescription"
                  label="Short Description"
                  component={CustomTextArea}
                />
              </Form.Field>

              <Form.Field>
                <SemanticField
                  name="vendorDescription"
                  label="Vendor Description"
                  component={Form.TextArea}
                  autoComplete="off"
                />
              </Form.Field>

              <Form.Field>
                <label>Documents</label>

                <DndProvider backend={HTML5Backend}>
                  <FieldArray
                    name="documents"
                    render={(arrayHelpers) => (
                      <div>
                        {values?.documents?.length > 0 && (
                          <Segment>
                            <Image.Group size="small">
                              {values.documents.map((document: any, index: number) => (
                                <Card
                                  key={document._id}
                                  index={index}
                                  id={document._id}
                                  contentImage={document.contentImage}
                                  fileName={document?.filename || document?.contentImage?.filename}
                                  src={`${baseUrl}/${document.url}`}
                                  remove={() => arrayHelpers.remove(index)}
                                  move={(a, b) => arrayHelpers.move(a, b)}
                                />
                              ))}
                            </Image.Group>
                          </Segment>
                        )}

                        <ImageUpload
                          onUpload={(files) => {
                            const newFiles = files.map((f) => ({
                              url: f.publicUrl,
                              contentImage: f._id,
                              filename: f.filename,
                            }));

                            arrayHelpers.push(newFiles[0]);
                          }}
                          btnLabel="Upload Documents"
                        />
                      </div>
                    )}
                  />
                </DndProvider>
              </Form.Field>
              <Form.Field>
                <label>Images</label>

                <DndProvider backend={HTML5Backend}>
                  <FieldArray
                    name="images"
                    render={(arrayHelpers) => (
                      <div>
                        {values?.images?.length > 0 && (
                          <Segment>
                            <Image.Group size="small">
                              {values.images.map((image: any, index: number) => (
                                <Card
                                  key={image._id}
                                  index={index}
                                  id={image._id}
                                  contentImage={image.contentImage}
                                  src={`${baseUrl}/${image.url}`}
                                  remove={() => arrayHelpers.remove(index)}
                                  move={(a, b) => arrayHelpers.move(a, b)}
                                />
                              ))}
                            </Image.Group>
                            <Button
                              type="button"
                              icon="download"
                              content="Download All"
                              onClick={() =>
                                DownloadZip(values.images.map((img) => `${baseUrl}/${img.url}`))
                              }
                            />
                          </Segment>
                        )}

                        <ImageUpload
                          onUpload={(files) => {
                            const newFiles = files.map((f) => ({
                              url: f.publicUrl,
                              contentImage: f,
                            }));

                            arrayHelpers.push(newFiles[0]);
                          }}
                        />
                      </div>
                    )}
                  />
                </DndProvider>
              </Form.Field>

              <Form.Field>
                <SemanticField
                  name={'leadTimeDays'}
                  label="Lead Time (Days)"
                  component={Form.Input}
                />
                <ErrorMessage
                  render={(msg) => <Message negative size="tiny" content={msg} />}
                  name="leadTimeDays"
                />
              </Form.Field>

              <Header attached="top" as="h5">
                Automation
              </Header>
              <Segment attached="bottom">
                <Form.Group widths="equal">
                  <Form.Field>
                    <label>Show</label>
                    <DatePicker
                      dateFormat="MM/dd/yyyy h:mm aa"
                      selected={startAt}
                      selectsStart
                      showTimeSelect
                      startDate={startAt}
                      endDate={expireAt}
                      onChange={(date: Date) => setFieldValue('startAt', date)}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Hide</label>
                    <DatePicker
                      dateFormat="MM/dd/yyyy h:mm aa"
                      selected={expireAt}
                      selectsEnd
                      showTimeSelect
                      startDate={startAt}
                      endDate={expireAt}
                      onChange={(date: Date) => setFieldValue('expireAt', date)}
                    />
                  </Form.Field>
                </Form.Group>
              </Segment>

              {!isVariantChild && (
                <Form.Field>
                  <SemanticField
                    name={'isComposite'}
                    label="This is a composite product"
                    component={Form.Checkbox}
                  />
                </Form.Field>
              )}

              {values.isComposite && (
                <React.Fragment>
                  <Form.Field>
                    <SemanticField
                      name="compositeUserCanEnterQuantities"
                      label="Can User Enter Quantities"
                      component={Form.Checkbox}
                    />
                  </Form.Field>
                  <Form.Field>
                    <SemanticField
                      name={'syncCompositeProduct'}
                      label="Sync Composite Product"
                      component={Form.Checkbox}
                    />
                  </Form.Field>
                  <Header as="h5" attached="top">
                    Composite Included Products
                  </Header>
                  <Segment attached="bottom">
                    <FastField
                      name="compositeProducts"
                      render={({ field }: FieldProps<any>) => (
                        <Composite
                          value={field.value}
                          onChange={(value) => {
                            field.onChange({
                              target: { value, name: field.name },
                            });
                          }}
                        />
                      )}
                    />
                  </Segment>
                </React.Fragment>
              )}

              <Header as="h5" attached="top">
                Custom Fields
              </Header>
              <Segment attached="bottom">
                <Form.Field>
                  <FieldArray
                    name="customFieldsSchema"
                    render={(arrayHelpers) => (
                      <div>
                        {values?.customFieldsSchema?.map((custom, index) => (
                          <Form.Group widths="equal" key={index}>
                            <Form.Field>
                              <label>Label</label>
                              <Field name={`customFieldsSchema[${index}].label`} />
                              <ErrorMessage
                                render={(msg) => <Message negative size="tiny" content={msg} />}
                                name={`customFieldsSchema[${index}].label`}
                              />
                            </Form.Field>
                            <Form.Field>
                              <label>Key</label>
                              <Field name={`customFieldsSchema[${index}].key`} />
                              <ErrorMessage
                                render={(msg) => <Message negative size="tiny" content={msg} />}
                                name={`customFieldsSchema[${index}].key`}
                              />
                            </Form.Field>
                            <Form.Field>
                              <SemanticField
                                name={`customFieldsSchema[${index}].type`}
                                label="Type"
                                component={Form.Dropdown}
                                selection
                                search
                                options={customFieldOptions}
                              />
                              {(custom.type === 'select' || custom.type === 'multi-select') && (
                                <>
                                  <div style={{ marginTop: '.5em' }}></div>
                                  <Button
                                    type="button"
                                    size="tiny"
                                    onClick={() => {
                                      setIsCustomFieldOpenModal(true);
                                      setModalIndex(index);
                                    }}
                                    content={
                                      custom?.options?.length > 0 ? 'Edit Options' : 'Add Options'
                                    }
                                  />
                                </>
                              )}
                              {isCustomFieldOpenModal && modalIndex === index && (
                                <Modal
                                  open={isCustomFieldOpenModal && modalIndex === index}
                                  onClose={() => {
                                    setIsCustomFieldOpenModal(false);
                                    setModalIndex(null);
                                  }}
                                  closeIcon
                                >
                                  <Modal.Header>Add Options</Modal.Header>
                                  <Modal.Content>
                                    <OptionsField name={`customFieldsSchema[${index}].options`} />
                                  </Modal.Content>
                                  <Modal.Actions>
                                    <Button
                                      type="button"
                                      content="Done"
                                      primary
                                      onClick={() => {
                                        setIsCustomFieldOpenModal(false);
                                        setModalIndex(null);
                                      }}
                                    />
                                    <Button
                                      type="button"
                                      content="Close"
                                      onClick={() => {
                                        setIsCustomFieldOpenModal(false);
                                        setModalIndex(null);
                                      }}
                                    />
                                  </Modal.Actions>
                                </Modal>
                              )}
                            </Form.Field>
                            <Form.Field>
                              <label>&nbsp;</label>
                              <SemanticField
                                name={`customFieldsSchema[${index}].isRequired`}
                                label="Required"
                                component={Form.Checkbox}
                              />
                            </Form.Field>
                            <div>
                              <div className="field">
                                <label>&nbsp;</label>
                                <Button
                                  type="button"
                                  icon="remove"
                                  onClick={() => arrayHelpers.remove(index)}
                                />
                              </div>
                            </div>
                          </Form.Group>
                        ))}
                        <Button
                          type="button"
                          size="tiny"
                          content="Add Field"
                          onClick={() => arrayHelpers.push({ type: 'text' })}
                        />
                      </div>
                    )}
                  />
                </Form.Field>
              </Segment>

              {values?.publishedToMicrosites?.length && (
                <>
                  <Header attached="top" as="h5">
                    Published Pages
                  </Header>
                  <Segment attached="bottom">
                    <PagePublish
                      productId={values?._id}
                      publishedToMicrosites={values?.publishedToMicrosites}
                    />
                  </Segment>
                </>
              )}

              <Header as="h5" attached="top">
                Metadata
              </Header>
              <Segment attached="bottom">
                {!values?.metadata?.length && <div>Metadata not defined</div>}
                <Form.Field>
                  <FieldArray
                    name="metadata"
                    render={(arrayHelpers) => (
                      <div>
                        {values?.metadata?.map((custom, index) => (
                          <Form.Group widths="equal" key={index}>
                            <Form.Field>
                              <label>Key</label>
                              <Field name={`metadata[${index}].key`} />
                              {(errors?.metadata as any)?.[index]?.key && (
                                <Message
                                  negative
                                  size="tiny"
                                  content={(errors?.metadata as any)?.[index]?.key}
                                />
                              )}
                            </Form.Field>
                            <Form.Field>
                              <label>Value</label>
                              <Field name={`metadata[${index}].value`} />
                            </Form.Field>
                            <div>
                              <div className="field">
                                <label>&nbsp;</label>
                                <Button
                                  type="button"
                                  icon="remove"
                                  onClick={() => arrayHelpers.remove(index)}
                                />
                              </div>
                            </div>
                          </Form.Group>
                        ))}
                        <Button
                          type="button"
                          size="tiny"
                          content="Add"
                          onClick={() => arrayHelpers.push({ key: '' })}
                        />
                      </div>
                    )}
                  />
                </Form.Field>
              </Segment>

              <Header attached="top" as="h5">
                Connect Design Templates
              </Header>
              <Segment attached="bottom">
                <FastField
                  name="designTemplates"
                  render={({ field }: FieldProps<any>) => (
                    <FeathersDropdown
                      search
                      fluid
                      selection
                      clearable
                      multiple
                      placeholder="Design Templates..."
                      serviceName="/design-templates"
                      query={{
                        $sort: { name: 1 },
                        $np: 1,
                      }}
                      resultMapper={(r) => ({
                        key: r._id,
                        value: r._id,
                        text: r?.name ?? '',
                      })}
                      {...field}
                      onChange={(e, data: any) => {
                        field.onChange({
                          target: { value: data.value, name: field.name },
                        } as any);
                        if (data?.value?.length) {
                          setFieldValue('artworkSlots', [{ sequence: 'MULTIPAGEPDF' }]);
                        } else {
                          setFieldValue('artworkSlots', []);
                        }
                      }}
                    />
                  )}
                />
              </Segment>

              <Header as="h5" attached="top">
                General Ledger
              </Header>
              <Segment attached="bottom">
                <Form.Group widths="equal">
                  <Form.Field>
                    <label>GL Income Account</label>
                    <Field
                      name="glIncomeAccount"
                      render={({ field }: FieldProps<any>) => (
                        <Dropdown
                          selection
                          clearable
                          options={glAccounts?.income?.map((g) => ({
                            text: g.accountNumber,
                            value: g._id,
                          }))}
                          noResultsMessage="Not found"
                          {...field}
                          onChange={(e, data: any) => {
                            field.onChange({
                              target: { value: data.value, name: field.name },
                            } as any);
                          }}
                        />
                      )}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>GL Expense Account</label>
                    <Field
                      name="glExpenseAccount"
                      render={({ field }: FieldProps<any>) => (
                        <Dropdown
                          selection
                          clearable
                          options={glAccounts?.['cost-of-goods']?.map((g) => ({
                            text: g.accountNumber,
                            value: g._id,
                          }))}
                          noResultsMessage="Not found"
                          {...field}
                          onChange={(e, data: any) => {
                            field.onChange({
                              target: { value: data.value, name: field.name },
                            } as any);
                          }}
                        />
                      )}
                    />
                  </Form.Field>
                </Form.Group>
              </Segment>

              <Header as="h5" attached="top">
                UOM and Fulfillment
              </Header>
              <Segment attached="bottom">
                <Form.Field>
                  <label>Unit of Measurement Label</label>
                  <FastField name="unitOfMeasure" />
                  <ErrorMessage
                    render={(msg) => <Message negative size="tiny" content={msg} />}
                    name="unitOfMeasure"
                  />
                  <Segment size="mini" compact secondary basic>
                    Shown to users when needing to describe how products are packed. This does NOT
                    affect inventory count or do any actual converting.
                  </Segment>
                </Form.Field>

                <Form.Field>
                  <label>Unit of Measurement Conversion Factor</label>
                  <FastField name="unitOfMeasureConversion" />
                  <ErrorMessage
                    render={(msg) => <Message negative size="tiny" content={msg} />}
                    name="unitOfMeasureConversion"
                  />
                  <Segment size="mini" compact secondary basic>
                    Convert from the units a product is sold in to a different unit type it is
                    stocked in. <strong>Caution</strong>: This will affect inventory count
                  </Segment>
                </Form.Field>
                <hr />
                <Form.Field>
                  <label>Fulfillment Report Conversion Factor</label>
                  <FastField name="fulfillmentConversionFactor" />
                  <ErrorMessage
                    render={(msg) => <Message negative size="tiny" content={msg} />}
                    name="fulfillmentConversionFactor"
                  />
                  <Segment size="mini" compact secondary basic>
                    Normally this is not used unless you want to convert fulfillment counts into
                    another unit type. This only affects fulfilment reporting
                  </Segment>
                </Form.Field>
              </Segment>
              <br />
              <Form.Field>
                <label>RFQ #</label>
                <FastField name="rfqNum" />
              </Form.Field>
              <br />
              <Form.Field>
                <label>Weight</label>
                <FastField name="weightUnit" />
                <ErrorMessage
                  render={(msg) => <Message negative size="tiny" content={msg} />}
                  name="weightUnit"
                />
              </Form.Field>
              <Form.Field>
                <label>Quantity for weight</label>
                <FastField name="weightBasis" />
                <ErrorMessage
                  render={(msg) => <Message negative size="tiny" content={msg} />}
                  name="weightBasis"
                />
              </Form.Field>
              <Form.Field>
                <label>Packaging Instructions</label>
                <FastField name="packagingInstructions" />
                <ErrorMessage
                  render={(msg) => <Message negative size="tiny" content={msg} />}
                  name="packagingInstructions"
                />
              </Form.Field>


              <Form.Field>
                <label>GL Code</label>
                <FastField name="glCode" />
                <ErrorMessage
                  render={(msg) => <Message negative size="tiny" content={msg} />}
                  name="glCode"
                />
                <Segment size="mini" compact secondary basic>
                  Optional General Ledger code that can be used in accounting and reporting
                </Segment>
              </Form.Field>

              {isQuantitiesFixed ? (
                <>
                  <Header as="h5" attached="top">
                    Quantities &amp; Pricing
                  </Header>
                  <Segment attached="bottom">
                    <Form.Field>
                      <FieldArray
                        name="prices"
                        render={(arrayHelpers) => (
                          <Table>
                            <Table.Header>
                              <Table.Row>
                                <Table.HeaderCell>Quantity</Table.HeaderCell>
                                <Table.HeaderCell>Cost</Table.HeaderCell>
                                <Table.HeaderCell>Price</Table.HeaderCell>
                                <Table.HeaderCell textAlign="right" />
                              </Table.Row>
                            </Table.Header>
                            <Table.Body>
                              {values?.prices?.map((price, index) => (
                                <Table.Row key={index}>
                                  <Table.Cell>
                                    <Field name={`prices.${index}.unitValue`} />
                                  </Table.Cell>
                                  <Table.Cell>
                                    <Field name={`prices.${index}.cost`} />
                                  </Table.Cell>
                                  <Table.Cell>
                                    <Field name={`prices.${index}.price`} />
                                  </Table.Cell>
                                  <Table.Cell textAlign="right">
                                    <Button
                                      type="button"
                                      size="mini"
                                      icon="trash"
                                      onClick={() => arrayHelpers.remove(index)}
                                    />
                                  </Table.Cell>
                                </Table.Row>
                              ))}
                              <Table.Row>
                                <Table.Cell>
                                  <Button
                                    type="button"
                                    size="mini"
                                    content="Add"
                                    onClick={() => arrayHelpers.push('')}
                                  />
                                </Table.Cell>
                                <Table.Cell />
                                <Table.Cell />
                                <Table.Cell />
                              </Table.Row>
                            </Table.Body>
                          </Table>
                        )}
                      />
                    </Form.Field>
                  </Segment>
                </>
              ) : (
                <>
                  <Header as="h5" attached="top">
                    Quantity Settings
                  </Header>
                  <Segment attached="bottom">
                    <Form.Field>
                      <FastField
                        name="quantitySettings"
                        render={({ field }: FieldProps<any>) => (
                          <QuantitySettings
                            value={field.value}
                            onChange={(value) =>
                              field.onChange({
                                target: { value, name: field.name },
                              })
                            }
                          />
                        )}
                      />
                    </Form.Field>
                  </Segment>
                  {/* {(!(values as any)?.prices || (values as any)?.prices?.length === 0) && (
                    <> */}
                  <Form.Field>
                    <SemanticField name={'price'} label="Price" component={Form.Input} />
                    <ErrorMessage
                      render={(msg) => <Message negative size="tiny" content={msg} />}
                      name="price"
                    />
                  </Form.Field>
                  <Form.Field>
                    <SemanticField name={'cost'} label="Cost" component={Form.Input} />
                    <ErrorMessage
                      render={(msg) => <Message negative size="tiny" content={msg} />}
                      name="cost"
                    />
                  </Form.Field>
                  {/* </>
                  )} */}
                </>
              )}

              {/* <Form.Field>
              <label>Quantity Type</label>
              <Form.Group inline>
                <Form.Radio
                  label="Single"
                  name="quantityType"
                  value={values.quantityType}
                  checked={values.quantityType === 'single'}
                  onBlur={handleBlur}
                  onChange={() => setFieldValue('quantityType', 'single')}
                />
                <Form.Radio
                  label="Range"
                  name="quantityType"
                  value={values.quantityType}
                  checked={values.quantityType === 'range'}
                  onBlur={handleBlur}
                  onChange={() => setFieldValue('quantityType', 'range')}
                />
              </Form.Group>
            </Form.Field> */}

              {/* {values.quantityType === 'range' && */}

              {/* } */}

              <br />

              <div className="field">
                <label>Production Channel</label>
                <FastField
                  name="productionChannel"
                  render={({ field }: FieldProps<any>) => (
                    <FeathersDropdown
                      search
                      fluid
                      selection
                      clearable
                      placeholder="Production Channel..."
                      serviceName="/production/channels"
                      query={{
                        $sort: { name: 1 },
                        $np: 1,
                      }}
                      resultMapper={(r) => ({
                        key: r._id,
                        value: r._id,
                        text: `${r?.name ?? ''} - ${r.key}`,
                      })}
                      {...field}
                      onChange={(e, data) =>
                        field.onChange({
                          target: { value: data.value, name: field.name },
                        } as any)
                      }
                    />
                  )}
                />
              </div>

              <div className="field">
                <label>Preferred Supplier</label>
                <FastField
                  name="productionChannel"
                  render={({ field }: FieldProps<any>) => (
                    <FeathersDropdown
                      search
                      fluid
                      selection
                      clearable
                      placeholder="Preferred Supplier..."
                      serviceName="/production/vendors"
                      query={{
                        $sort: { companyName: 1 },
                        $np: 1,
                      }}
                      resultMapper={(r) => ({
                        key: r._id,
                        value: r._id,
                        text: r?.companyName ?? 'N/A',
                      })}
                      {...field}
                      onChange={(e, data) =>
                        field.onChange({
                          target: { value: data.value, name: field.name },
                        } as any)
                      }
                    />
                  )}
                />
              </div>

              {props.isEdit && values.hasVariants && values.inventory && (
                <>
                  <Header as="h5" content="Child Variants" attached="top" />
                  <VariantTable id={values._id} attached="bottom" />
                </>
              )}

              <Form.Field>
                <SemanticField
                  name={'isAdditionalPerPieceEnabled'}
                  label="Enable Additional Per Piece "
                  component={Form.Checkbox}
                />
              </Form.Field>

              {values.isAdditionalPerPieceEnabled && (
                <Form.Field>
                  <label>Additional Per Piece Price</label>
                  <FastField name="additionalPerPiecePrice" />
                  {/* <SemanticField
                  name={"additionalPerPiecePrice"}
                  label="Additional Per Piece Price"
                  component={Form.Input}
                /> */}
                  <ErrorMessage
                    render={(msg) => <Message negative size="tiny" content={msg} />}
                    name="additionalPerPiecePrice"
                  />
                </Form.Field>
              )}

              <Header as="h5" attached="top">
                Price Tiers
              </Header>
              <Segment attached="bottom">
                <Field
                  name="priceTiers"
                  render={({ field }) => (
                    <PriceTiers
                      value={field.value}
                      onChange={(value) => {
                        field.onChange({ target: { value, name: field.name } });
                      }}
                    />
                  )}
                />
              </Segment>

              <br />

              <Form.Field>
                <SemanticField
                  name={'tags'}
                  label="Tags"
                  component={Form.Dropdown}
                  selection
                  search
                  clearable
                  multiple
                  allowAdditions
                  options={
                    values?.tags?.map((tag: string) => ({ text: tag, value: tag, key: tag })) || []
                  }
                  onAddItem={handleAddition}
                  noResultsMessage="Not found"
                />
              </Form.Field>

              <Form.Field></Form.Field>
              {hasNumPages && (
                <Form.Field>
                  <SemanticField
                    name={'pricePerPageEnabled'}
                    label="Price per page"
                    component={Form.Checkbox}
                  />
                </Form.Field>
              )}

              {/* <Form.Field>
                <SemanticField
                  name={"isSellable"}
                  label="Sellable"
                  component={Form.Checkbox}
                />
                <ErrorMessage render={msg => <Message negative size="tiny" content={msg} />} name="isSellable" />
              </Form.Field> */}

              <Form.Field>
                <SemanticField
                  name={'triggerOrderApproval'}
                  label="Trigger required approval"
                  component={Form.Checkbox}
                />
                <ErrorMessage
                  render={(msg) => <Message negative size="tiny" content={msg} />}
                  name="triggerOrderApprovall"
                />
              </Form.Field>

              {/* <Form.Field>
                <SemanticField
                  name={"manageStockLevel"}
                  label="Manage Stock Level"
                  component={Form.Checkbox}
                />
                <ErrorMessage component="div" name="manageStockLevel" />
              </Form.Field> */}

              <Form.Field>
                <SemanticField
                  name={'inventorySyncPriceToInventory'}
                  label="Inventory Sync Price To Inventory"
                  component={Form.Checkbox}
                />
              </Form.Field>

              <Form.Field>
                <SemanticField
                  name={'shouldExcludeFromHandlingFees'}
                  label="Exclude from Handling Fee Calculation"
                  component={Form.Checkbox}
                />
              </Form.Field>

              <Form.Field>
                <SemanticField
                  name={'isCustomerOwned'}
                  label="Customer Owned Product"
                  component={Form.Checkbox}
                />
              </Form.Field>

              {/* {
                isVariantChild && <>
                  <Header as="h5" attached="top">
                    Is a Variant
                  </Header>
                  <Segment attached="bottom">

                    <React.Fragment>
                      <div className="field">
                        <label>Parent Product</label>
                        <div className="ui input">
                          <input type="text" disabled={true} value={values?.parent?.staticName} />
                        </div>
                      </div>

                    </React.Fragment>
                    <Field
                      name="variantValues"
                      render={({ field }) => {
                        return (
                          <Variants
                            value={field.value}
                            onChange={value =>
                              field.onChange({ target: { value, name: field.name } })
                            }
                            isDisabled={true}
                          />
                        );
                      }}
                    />
                  </Segment>
                </>
              } */}

              {/* {props.isEdit && !isVariantChild && values?.isComposite !== true && ( */}
              {values?.isComposite !== true && (
                <>
                  <Header as="h5" attached="top">
                    Order Limits
                  </Header>
                  <Segment attached="bottom">
                    <Form.Group widths="equal">
                      <Form.Field>
                        <SemanticField
                          name="orderLimitsPerUser.value"
                          label="Value"
                          component={Form.Input}
                        />
                      </Form.Field>
                      <Form.Field>
                        <SemanticField
                          name="orderLimitsPerUser.period"
                          label="Period"
                          selection
                          fluid
                          clearable
                          component={Form.Dropdown}
                          options={periodOptions}
                        />
                      </Form.Field>
                      <Form.Field>
                        <label>&nbsp;</label>
                        <SemanticField
                          name="orderLimitsPerUser.isAdminsExcluded"
                          label="Exclude Admins from Limits"
                          component={Form.Checkbox}
                        />
                      </Form.Field>
                      <Form.Field>
                        <label>&nbsp;</label>
                        <SemanticField
                          name="orderLimitsPerUser.excludeFromOrderItemLimit"
                          label="Exclude From Order Item Limit"
                          component={Form.Checkbox}
                        />
                      </Form.Field>
                      <Form.Field>
                        <label>&nbsp;</label>
                        <SemanticField
                          name="orderLimitsPerUser.enabled"
                          label="Enable"
                          component={Form.Checkbox}
                        />
                      </Form.Field>
                    </Form.Group>
                  </Segment>
                </>
              )}

              {!props.isEdit && (
                <React.Fragment>
                  <Header as="h5" attached="top">
                    Inventory
                  </Header>
                  <Segment attached="bottom">
                    <Form.Group widths="equal">
                      <Form.Field>
                        <SemanticField
                          name={'initialStock'}
                          label="Initial Stock"
                          component={Form.Input}
                        />
                        <ErrorMessage
                          render={(msg) => <Message negative size="tiny" content={msg} />}
                          name="initialStock"
                        />
                      </Form.Field>

                      <div className="field">
                        <label>Inital Location</label>
                        <Field
                          name="initialLocation"
                          render={({ field }: FieldProps<any>) => (
                            <FeathersDropdown
                              search
                              fluid
                              selection
                              clearable
                              placeholder="Select a location..."
                              serviceName="/inventory/locations"
                              query={{
                                $sort: { name: 1 },
                                $select: ['_id', 'name'],
                                virtual: { $ne: true },
                                $np: 1,
                              }}
                              resultMapper={(r) => ({
                                key: r._id,
                                value: r._id,
                                text: r.name,
                              })}
                              {...field}
                              onChange={(e, data) =>
                                field.onChange({
                                  target: { value: data.value, name: 'initialLocation' },
                                } as any)
                              }
                            />
                          )}
                        />
                      </div>
                      <Form.Field>
                        <SemanticField
                          name={'initialCost'}
                          label="Initial Cost"
                          component={Form.Input}
                        />
                        <ErrorMessage
                          render={(msg) => <Message negative size="tiny" content={msg} />}
                          name="initialCost"
                        />
                      </Form.Field>
                    </Form.Group>
                  </Segment>
                </React.Fragment>
              )}

              {!props.isEdit && (
                <React.Fragment>
                  <Header as="h5" attached="top">
                    Content Page
                  </Header>
                  <Segment attached="bottom">
                    <Form.Field>
                      <SemanticField
                        name={'createContentPage'}
                        label="Create Content Page"
                        component={Form.Checkbox}
                      />
                    </Form.Field>

                    {values.createContentPage && (
                      <Form.Group widths="equal">
                        <Form.Field>
                          <SemanticField name={'page.name'} label="Name" component={Form.Input} />
                          <ErrorMessage
                            render={(msg) => <Message negative size="tiny" content={msg} />}
                            name="page.name"
                          />
                        </Form.Field>

                        <Form.Field>
                          <SemanticField name={'page.title'} label="Title" component={Form.Input} />
                          <ErrorMessage
                            render={(msg) => <Message negative size="tiny" content={msg} />}
                            name="page.title"
                          />
                        </Form.Field>
                        <Form.Field>
                          <SemanticField
                            name={'page.template'}
                            label="Template"
                            component={Form.Input}
                          />
                          <ErrorMessage
                            render={(msg) => <Message negative size="tiny" content={msg} />}
                            name="page.template"
                          />
                        </Form.Field>
                        <Form.Field>
                          <SemanticField name={'page.url'} label="URL" component={Form.Input} />
                          <ErrorMessage
                            render={(msg) => <Message negative size="tiny" content={msg} />}
                            name="page.url"
                          />
                        </Form.Field>
                      </Form.Group>
                    )}
                  </Segment>
                </React.Fragment>
              )}

              {/* {!props.isEdit && (
                <FieldArray
                  name="variantOptions"
                  render={({
                    move,
                    swap,
                    push,
                    insert,
                    unshift,
                    pop,
                    remove
                  }) => (
                    <React.Fragment>
                      <Header as="h5" attached="top">
                        Create Variants
                      </Header>
                      <Segment attached="bottom">
                        {values.variantOptions.map(({ key, val }, index) => (
                          <Form.Group widths="equal" key={index}>
                            <Form.Field>
                              <Field
                                name={`variantOptions[${index}].key`}
                                placeholder={"Type..."}
                              />
                            </Form.Field>
                            <Form.Field>
                              <Field
                                name={`variantOptions[${index}].value`}
                                placeholder={"Comma separated values..."}
                              />
                            </Form.Field>
                            <Button
                              type="button"
                              icon="remove"
                              onClick={() => remove(index)}
                            />
                          </Form.Group>
                        ))}

                        <Button
                          type="button"
                          icon="plus"
                          size="tiny"
                          onClick={() => push({ key: "", value: "" })}
                        />
                      </Segment>
                    </React.Fragment>
                  )}
                />
              )} */}
            </Segment>

            <Header as="h4" content="Store Front" subheader="Cart" attached="top" />
            <Segment secondary attached="bottom">
              <Grid divided="vertically">
                <Grid.Row>
                  <Grid.Column width="3">Sort Priority</Grid.Column>
                  <Grid.Column width="13">
                    <FastField name="priority" />
                    <span>
                      Used to control the sort appearance on the store front. Sorts from smallest to
                      biggest values
                    </span>
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column width="3">Taxable</Grid.Column>
                  <Grid.Column width="13">
                    <SemanticField
                      name={'isTaxable'}
                      toggle
                      label="Is Taxable"
                      component={Form.Checkbox}
                    />
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column width="3">Shippable</Grid.Column>
                  <Grid.Column width="13">
                    <SemanticField
                      name={'isShippable'}
                      toggle
                      label="Is Shippable"
                      component={Form.Checkbox}
                    />
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column width="3">Hide on Store Front</Grid.Column>
                  <Grid.Column width="13">
                    <SemanticField
                      name={'isHidden'}
                      toggle
                      label="Hide on Store Front"
                      component={Form.Checkbox}
                    />
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column width="3">Backorders Disabled</Grid.Column>
                  <Grid.Column width="13">
                    <SemanticField
                      name={'backordersDisabled'}
                      label="This setting is used to override enabled backorders at higher"
                      toggle
                      component={Form.Checkbox}
                    />
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column width="3">Enable Low Inventory Settings</Grid.Column>
                  <Grid.Column width="13">
                    <SemanticField
                      name={'inventoryLowEmailNotificationEnabled'}
                      label="Enable Low Inventory Settings"
                      toggle
                      component={Form.Checkbox}
                    />
                    {values.inventoryLowEmailNotificationEnabled && (
                      <>
                        {/* <Header as="h5" attached="top">
                          Low Inventory Settings
            </Header> */}
                        <Segment>
                          <Form.Group widths="equal">
                            <Form.Field>
                              <SemanticField
                                name={'inventoryLowThreshold'}
                                label="Inventory Low Threshold"
                                component={Form.Input}
                                autoComplete="off"
                              />
                              <ErrorMessage
                                render={(msg) => <Message negative size="tiny" content={msg} />}
                                name="inventoryLowThreshold"
                              />
                            </Form.Field>
                            <Form.Field>
                              <SemanticField
                                name={'inventoryLowEmailNotify.name'}
                                label="Notify Name"
                                component={Form.Input}
                                autoComplete="off"
                              />
                              <ErrorMessage
                                render={(msg) => <Message negative size="tiny" content={msg} />}
                                name="inventoryLowEmailNotify.name"
                              />
                            </Form.Field>
                            <Form.Field>
                              <SemanticField
                                name={'inventoryLowEmailNotify.email'}
                                label="Notify Email"
                                component={Form.Input}
                                autoComplete="off"
                              />
                              <ErrorMessage
                                render={(msg) => <Message negative size="tiny" content={msg} />}
                                name="inventoryLowEmailNotify.email"
                              />
                            </Form.Field>
                          </Form.Group>
                        </Segment>
                      </>
                    )}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>

            {props.errorMessage && (
              <>
                <Message error content={props.errorMessage} /> <br />{' '}
              </>
            )}

            <Button
              primary
              type="submit"
              disabled={
                (props.isSubmitting && !props.errorMessage) || Object.keys(errors).length > 0
              }
            >
              Save
            </Button>
          </FormikForm>
        );
      }}
    </Formik>
  );
};

export const StockProductForm = withRouter(PreStockProductForm);
