import React from 'react';
import {
  Box,
  Heading,
  Input,
  RadioGroup,
  Radio,
  Switch,
  Stack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  IconButton,
  Button,
} from '@chakra-ui/react';
import { useFieldArray } from 'react-hook-form';

import { Select } from 'chakra-react-select';

import { RiCloseFill, RiAddFill } from 'react-icons/ri';

const targetOptions = [
  { label: 'Self', value: '_self' },
  { label: 'Open in New Browser Tab/Window', value: '_blank' },
];

export default function SubMenuForm({
  nestIndex,
  control,
  register,
  watch,
  setValue,
  pages,
  groups,
  errors,
  trigger,
}) {
  const { fields, remove, append } = useFieldArray({
    control,
    name: `menuItems.${nestIndex}.menuItems`,
  });

  return (
    <>
      {fields.map((item, index) => {
        const subTypeOfLink =
          watch(`menuItems.${nestIndex}.menuItems.${index}.typeOfLink`) === 'external'
            ? 'external'
            : 'page';

        const subVisibleToGroups =
          watch(`menuItems.${nestIndex}.menuItems.${index}.visibleToGroups`) || [];

        const err = errors?.menuItems?.[nestIndex]?.menuItems?.[index];

        return (
          <Box key={item.id}>
            <FormControl as="fieldset">
              <RadioGroup defaultValue={subTypeOfLink}>
                <Stack direction="row" spacing={7} mb={3}>
                  <FormLabel as="legend">Type of Link</FormLabel>
                  <Radio
                    value="page"
                    onChange={(e) =>
                      setValue(
                        `menuItems.${nestIndex}.menuItems.${index}.typeOfLink`,
                        e.target.value
                      )
                    }
                  >
                    Page
                  </Radio>
                  <Radio
                    value="external"
                    onChange={(e) => {
                      setValue(
                        `menuItems.${nestIndex}.menuItems.${index}.typeOfLink`,
                        e.target.value
                      );
                      setValue(`menuItems.${nestIndex}.menuItems.${index}.page`, undefined);
                    }}
                  >
                    Link
                  </Radio>
                </Stack>
              </RadioGroup>
            </FormControl>
            <Stack direction="row" spacing={7} mb={3}>
              {subTypeOfLink === 'external' ? (
                <>
                  <FormControl isInvalid={!!err?.label}>
                    <FormLabel>Label</FormLabel>
                    <Input {...register(`menuItems.${nestIndex}.menuItems.${index}.label`)} />
                    {err?.label && <FormErrorMessage>{err?.label?.message}</FormErrorMessage>}
                  </FormControl>
                  <FormControl>
                    <FormLabel>URL</FormLabel>
                    <Input {...register(`menuItems.${nestIndex}.menuItems.${index}.url`)} />
                  </FormControl>
                </>
              ) : (
                <>
                  <FormControl>
                    <FormLabel>Page</FormLabel>
                    <Select
                      size="sm"
                      options={pages}
                      name={`menuItems.${nestIndex}.menuItems.${index}.page`}
                      onChange={(e) => {
                        setValue(`menuItems.${nestIndex}.menuItems.${index}.page`, e.value);
                        setValue(`menuItems.${nestIndex}.menuItems.${index}.label`, e.label);
                      }}
                      value={pages.find(
                        (o) => o.value === watch(`menuItems.${nestIndex}.menuItems.${index}.page`)
                      )}
                    />
                  </FormControl>
                  <FormControl isInvalid={!!err?.label}>
                    <FormLabel>Label</FormLabel>
                    <Input {...register(`menuItems.${nestIndex}.menuItems.${index}.label`)} />
                    {err?.label && <FormErrorMessage>{err?.label?.message}</FormErrorMessage>}
                  </FormControl>
                </>
              )}

              <FormControl>
                <FormLabel>Target</FormLabel>
                <Select
                  size="sm"
                  options={targetOptions}
                  onChange={(e) =>
                    setValue(`menuItems.${nestIndex}.menuItems.${index}.htmlTarget`, e?.value)
                  }
                  value={targetOptions.find(
                    (o) => o.value === watch(`menuItems.${nestIndex}.menuItems.${index}.htmlTarget`)
                  )}
                />
              </FormControl>
              <FormControl isInvalid={!!err?.priority}>
                <FormLabel>Priority</FormLabel>
                <Input {...register(`menuItems.${nestIndex}.menuItems.${index}.priority`)} />
                {err?.priority && <FormErrorMessage>{err?.priority?.message}</FormErrorMessage>}
              </FormControl>

              <FormControl>
                <FormLabel>Visibility</FormLabel>
                <Select
                  size="sm"
                  isMulti
                  options={groups}
                  onChange={(e) =>
                    setValue(`menuItems.${nestIndex}.menuItems.${index}.visibleToGroups`, e)
                  }
                  defaultValue={subVisibleToGroups?.map((v) => groups.find((g) => g.value === v))}
                />
              </FormControl>

              <FormControl flex={1}>
                <FormLabel>Hidden</FormLabel>
                <Switch {...register(`menuItems.${nestIndex}.menuItems.${index}.isHidden`)} />
              </FormControl>

              <FormControl flex={1}>
                <FormLabel>&nbsp;</FormLabel>
                <IconButton
                  type="button"
                  variant="outline"
                  aria-label="remove field"
                  onClick={() => remove(index)}
                  icon={<RiCloseFill />}
                />
              </FormControl>
            </Stack>
          </Box>
        );
      })}

      <Box mb={3}>
        <Button type="button" variant="outline" onClick={() => append({ htmlTarget: '_self' })}>
          Add menu item
        </Button>
      </Box>
    </>
  );
}
