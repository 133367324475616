import * as React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Segment, Table, Message } from 'semantic-ui-react';
import { PageHeaderRow, PreLoader, useFeathers, EmptyWrapper } from '@inkcloud/components';
import * as numeral from 'numeral';

import Feathers from './../../bootstrap/feathers';

interface DetailProps {}

const serviceName = '/reports';

const { useState, useEffect } = React;

export const Detail: React.FunctionComponent<
  DetailProps & RouteComponentProps<{ id?: string; location?: any }>
> = (props) => {
  const {
    match: {
      params: { id },
    },
    history,
    location,
  } = props;

  const [invoices, setInvoices] = useState([]);
  const [name, setName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const search = new URLSearchParams(location.search);
  const action = search.get('action');
  const range = search.get('range');

  const commonParams = {
    query: {
      action,
      range,
      customer: id,
    },
  };

  useEffect(() => {
    const loadInvoice = async () => {
      try {
        setIsLoading(true);
        const res: any = await Feathers.service(serviceName).find(commonParams);

        setIsLoading(false);
        setInvoices(res.invoices);
        setName(res.name);
      } catch (e) {
        setIsLoading(false);
        e.code && e.code < 500
          ? setErrorMessage(e.message)
          : setErrorMessage('We are experiencing technical difficulties. Please try again');
      }
    };

    loadInvoice();
  }, [id]);

  let rangeText;

  if (range === 'balance0To30days') {
    rangeText = '1 to 30 days';
  } else if (range === 'balance31To60days') {
    rangeText = '31 to 60 days';
  } else if (range === 'balance61To90days') {
    rangeText = '61 to 90 days';
  } else {
    rangeText = '91 days or more';
  }

  const total =
    invoices &&
    invoices.length > 0 &&
    invoices.reduce((sub, cur) => {
      return sub + cur.openBalance;
    }, 0);

  return (
    <div>
      <PageHeaderRow header={rangeText} subheader="Open Invoice" subheaderId={''}>
        <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
      </PageHeaderRow>

      {errorMessage && <Message error content={errorMessage} />}
      <EmptyWrapper queryResult={invoices} isStateLoading={isLoading}>
        <Segment loading={isLoading}>
          <Table size={'small'} sortable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>ID</Table.HeaderCell>
                <Table.HeaderCell>Customer</Table.HeaderCell>
                <Table.HeaderCell>Date</Table.HeaderCell>
                <Table.HeaderCell>Amount</Table.HeaderCell>
                <Table.HeaderCell>Open Balance </Table.HeaderCell>
                <Table.HeaderCell>Aging </Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {invoices &&
                invoices.length > 0 &&
                invoices.map((r: any, index: number) => (
                  <Table.Row key={index}>
                    <Table.Cell>{r.invoice}</Table.Cell>
                    <Table.Cell>
                      <Link to={`/customers/${id}`}>{name}</Link>
                    </Table.Cell>
                    <Table.Cell>{r.date}</Table.Cell>
                    <Table.Cell>{numeral(r.amount).format('$0,0.00')}</Table.Cell>
                    <Table.Cell>{numeral(r.openBalance).format('$0,0.00')}</Table.Cell>
                    <Table.Cell>
                      {r.aging} {r.aging > 1 ? 'days' : 'day'}
                    </Table.Cell>
                    <Table.Cell textAlign="right">
                      <Link
                        to={`/accounting/invoices/${r.invoiceId}?customer=${id}&humanId=${r.invoice}`}
                      >
                        <Button size="mini">View Invoice</Button>
                      </Link>

                      <Link to={`/invoice/payments/${id}`}>
                        <Button size="mini">Make Payment</Button>
                      </Link>
                    </Table.Cell>
                  </Table.Row>
                ))}
              {invoices && invoices.length > 0 && (
                <Table.Row>
                  <Table.Cell>
                    <strong>Total Open</strong>
                  </Table.Cell>
                  <Table.Cell />
                  <Table.Cell />
                  <Table.Cell />
                  <Table.Cell>
                    {numeral(total).format('$0,0.00')}
                    {}
                  </Table.Cell>
                  <Table.Cell />
                  <Table.Cell />
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </Segment>
      </EmptyWrapper>
    </div>
  );
};
