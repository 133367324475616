import * as React from 'react';
import { Label, Popup, Icon } from 'semantic-ui-react';
import { Link, RouteComponentProps } from 'react-router-dom';
import {
  useList,
  ListPage,
  generateOperatorOptions,
  Operator,
  basicQueryMap,
} from '@inkcloud/components';
import {
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
  ButtonGroup,
} from '@chakra-ui/react';
import { EditIcon } from '@chakra-ui/icons';
import { FaEllipsisV } from 'react-icons/fa';

import { FormattedDate } from 'react-intl';
import type { ModelTypes } from '@inkcloud/icapi-types';

import { FormattedDateHover } from '../../../common';
import Feathers from '../../../bootstrap/feathers';
import { ExportModal } from '../../reports/ExportModal';
import { InviteModal } from './Invite';

import { LoginAsModal } from './LoginAs';
import { UpdatePasswordModal } from './UpdatePassword';
import { ResetPasswordModal } from './ResetPassword';
import { ClearCartModal } from '../../customers/Detail/ClearCartModal';

interface ListProps {}

const { useState, useEffect } = React;

const serviceName = 'microsites/customers';

export const filterQueryMap = (values) => {
  const filterMap: any = {
    firstName: values.firstName ? { $LIKE: values.firstName } : undefined,
    lastName: values.lastName ? { $LIKE: values.lastName } : undefined,
    email: values.email ? { $LIKE: values.email } : undefined,
    groups: values.groups ? { $in: values.groups } : undefined,
  };

  return [].reduce(
    (m, field) => ({ ...m, [field]: values[field] ? values[field] : undefined }),
    filterMap
  );
};

const filterQueryMap2 = (values) => basicQueryMap(values, {});

export async function getFilterDefinition(microsite: string) {
  const definition = [
    {
      label: 'First Name...',
      value: 'firstName',
      operators: generateOperatorOptions([Operator.Like]),
    },
    {
      label: 'Last Name...',
      value: 'lastName',
      operators: generateOperatorOptions([Operator.Like]),
    },
    {
      label: 'Email...',
      value: 'email',
      operators: generateOperatorOptions([Operator.Like]),
    },

    {
      label: 'Groups',
      value: 'groups',
      operators: generateOperatorOptions([Operator.Is, Operator.IsNot]),
      valueOptions: [],
      asyncValueOptions: async () =>
        Feathers.service('microsites/customer/groups')
          .find({
            query: {
              $np: 1,
              $select: ['_id', 'name'],
              $sort: { name: 1 },
              microsite,
            },
          })
          .then((results) => results.map((r) => ({ value: r._id, label: r.name }))),
      type: 'multi-select',
    },
  ];

  return definition.sort((a: any, b: any) => a.label.localeCompare(b.label));
}

export const List: React.FunctionComponent<
  ListProps & RouteComponentProps<{ micrositeId: string }>
> = (props) => {
  const {
    match: {
      params: { micrositeId },
    },
    location,
    history,
  } = props;

  const useListValues = useList({
    feathers: [
      'find',
      serviceName,
      [
        {
          query: {
            microsite: micrositeId,
            $populate: ['groups'],
            $select: [
              'createdAt',
              'firstName',
              'lastName',
              'email',
              'groups',
              'isAdmin',
              'active',
              'disabled',
            ],
          },
        },
      ],
    ],
    filterMap: filterQueryMap2,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const [inviteSuccess, setInviteSuccess] = useState(false);
  const [dataExport, setDataExport] = useState({ url: '', isModalOpen: false, loading: false });

  const [isLoadingExport, setIsLoadingExport] = useState(false);
  const [errorMessageExport, setErrorMessageExport] = useState('');

  const [isLoginAsModalOpen, setIsLoginAsModalOpen] = useState(false);
  const [isResetPasswordModalOpen, setIsResetPasswordModalOpen] = useState(false);
  const [isOrderLimitIncreaseModalOpen, setIsOrderLimitIncreaseModalOpen] = useState(false);
  const [isUpdatePasswordModalOpen, setIsUpdatePasswordModalOpen] = useState(false);
  const [isClearCartModalOpen, setIsClearCartModalOpen] = useState(false);

  const [micrositeCustomer, setMicrositeCustomer] = useState<
    NonNullable<ModelTypes.MicrositeCustomers>
  >({});

  const [microsite, setMicrosite] = useState<NonNullable<ModelTypes.Microsites>>({});

  const [isSubmitting, setIsSubmitting] = useState({
    clearCart: false,
    updatePassword: false,
    resetPassword: false,
    orderLimitIncrease: false,
  });

  const [errorMessage, setErrorMessage] = useState({
    clearCart: '',
    updatePassword: '',
    resetPassword: '',
    orderLimitIncrease: '',
  });

  useEffect(() => {
    const loadMicrosite = async () => {
      try {
        const res = await Feathers.service('/microsites').get(micrositeId);

        setMicrosite(res);
      } catch (e) {
        console.log(e);
      }
    };

    loadMicrosite();
  }, [micrositeId]);

  const pathPrefix = `/settings/microsites/${micrositeId}`;

  const handleClose = () => {
    setDataExport({ ...dataExport, loading: false, url: '', isModalOpen: false });
  };

  const handleUpdatePassword = async ({ password, confirmPassword }) => {
    setIsSubmitting({ ...isSubmitting, updatePassword: true });
    setErrorMessage({ ...errorMessage, updatePassword: '' });
    try {
      const res = await Feathers.service('/microsite-user/password').create({
        microsite: micrositeId,
        email: micrositeCustomer.email,
        password,
        confirmPassword,
      });

      setIsUpdatePasswordModalOpen(false);
      setIsSubmitting({ ...isSubmitting, updatePassword: false });
      setMicrositeCustomer({});
    } catch (e) {
      setIsSubmitting({ ...isSubmitting, updatePassword: true });
      setErrorMessage({
        ...errorMessage,
        updatePassword:
          e.code && e.code < 500
            ? e.message
            : 'We are experiencing technical difficulties. Please try again',
      });
    }
  };

  const handleResetPassword = async () => {
    const { domain } = microsite;
    setIsSubmitting({ ...isSubmitting, resetPassword: true });
    setErrorMessage({ ...errorMessage, resetPassword: '' });
    try {
      const res = await Feathers.service('/microsite-user/reset-password').create({
        microsite: micrositeId,
        email: micrositeCustomer.email,
        url: `https://${domain}/reset-password-update`,
      });

      setIsSubmitting({ ...isSubmitting, resetPassword: false });
      if (!res.result) {
        setErrorMessage({ ...errorMessage, resetPassword: res.message || res.errorMessage });
      } else {
        setIsResetPasswordModalOpen(false);
        setMicrositeCustomer({});
      }
    } catch (e) {
      setIsSubmitting({ ...isSubmitting, resetPassword: true });
      setErrorMessage({
        ...errorMessage,
        resetPassword:
          e.code && e.code < 500
            ? e.message
            : 'We are experiencing technical difficulties. Please try again',
      });
    }
  };

  const handleClearCartSubmit = async () => {
    setIsSubmitting({ ...isSubmitting, clearCart: true });
    setErrorMessage({ ...errorMessage, clearCart: '' });

    try {
      const res: any = await Feathers.service('/cart/clear-active').create({
        micrositeCustomer: micrositeCustomer._id,
      });

      if (res.result !== undefined && res.result === false) {
        setErrorMessage({ ...errorMessage, clearCart: res.errorMessage || res.message });
      } else {
        setIsSubmitting({ ...isSubmitting, clearCart: false });
        setIsClearCartModalOpen(false);
        setMicrositeCustomer({});
      }
    } catch (e) {
      setIsSubmitting({ ...isSubmitting, clearCart: false });
      setErrorMessage({
        ...errorMessage,
        clearCart:
          e.code && e.code < 500
            ? e.message
            : 'We are experiencing technical difficulties. Please try again',
      });
    }
  };

  const handleExportClick = async () => {
    setIsLoadingExport(true);
    setErrorMessageExport('');

    try {
      const res: any = await Feathers.service('/reports/v3/results').create({
        reportKey: 'ms-customer-export',
        reportName: 'MS Customer Export',
        query: {},
        microsite,
      });

      history.push('/reports/results');
    } catch (e) {
      setErrorMessageExport('Error while exporting.');
    }

    setIsLoadingExport(false);
  };

  const closeModal = () => setIsInviteModalOpen(false);

  const handleInviteSubmit = async (values) => {
    const payload = {
      microsite: micrositeId,
      ...values,
    };

    await Feathers.service('/microsites/customer/invite').create(payload);

    setInviteSuccess(true);
    useListValues?.reload();
    closeModal();
  };

  const tableDefinition = [
    {
      key: 'createdAt',
      label: 'Date',
      sortable: true,
      cell: (v) => <FormattedDateHover value={v} />,
    },
    {
      key: 'firstName',
      label: 'Name',
      sortable: true,
      cell: (v, r) => `${r?.firstName ?? ''} ${r?.lastName ?? ''}`,
    },
    {
      key: '',
      label: 'Groups',
      sortable: false,
      cell: (v, r) =>
        r?.groups?.map((g, idx) => (
          <Label size="tiny" key={idx}>
            {g?.name ?? ''}
          </Label>
        )),
    },
    {
      key: '',
      label: 'Status',
      sortable: false,
      cell: (v, r) => {
        let content;
        let color;
        let init;

        if (r.disabled) {
          content = 'Disabled';
          color = 'red';
          init = 'D';
        } else {
          content = 'Invited';
          color = 'yellow';
          init = 'I';
          if (r.active) {
            content = 'Active';
            color = 'green';
            init = 'A';
          }
        }

        return (
          <>
            <Popup
              size="mini"
              content={content}
              trigger={
                <Label size="tiny" color={color}>
                  {init}
                </Label>
              }
            />
            {r.isAdmin && (
              <Popup
                size="mini"
                content={'Admin'}
                trigger={<Icon color="blue" size={'large'} name="user circle outline" />}
              />
            )}
          </>
        );
      },
    },
    {
      key: '',
      label: '',
      sortable: false,
      cell: (v, r) => (
        <>
          <Menu isLazy>
            <MenuButton as={IconButton} icon={<FaEllipsisV />} variant={'outline'}></MenuButton>
            <MenuList>
              <MenuItem
                onClick={() => {
                  setIsLoginAsModalOpen(true);
                  setMicrositeCustomer(r);
                }}
              >
                Login As
              </MenuItem>

              <MenuItem
                as={Link}
                to={{
                  pathname: `${pathPrefix}/customers/order-limits`,
                  state: {
                    micrositeCustomer: r._id,
                    microsite: micrositeId,
                    IsOrderLimitIncreaseModalOpen: true,
                  },
                }}
              >
                Order Limit Increase
              </MenuItem>

              <MenuDivider />
              <MenuItem
                onClick={() => {
                  setIsUpdatePasswordModalOpen(true);
                  setMicrositeCustomer(r);
                }}
              >
                Update Password
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setIsResetPasswordModalOpen(true);
                  setMicrositeCustomer(r);
                }}
              >
                Reset Password
              </MenuItem>
              <MenuDivider />
              <MenuItem
                onClick={() => {
                  setIsClearCartModalOpen(true);
                  setMicrositeCustomer(r);
                }}
              >
                Clear Active Carts
              </MenuItem>
            </MenuList>
          </Menu>

          {/* <Dropdown
          className="icon mini"
          position="left"
          direction="left"
          button
          icon="ellipsis horizontal"
        >
          <Dropdown.Menu>
            <Dropdown.Item
              icon="cart"
              content={"Clear Active Carts"}
              onClick={() => {
                setIsClearCartModalOpen(true)
                setMicrositeCustomer(r)
              }}
            />
            <Dropdown.Item
              icon="user"
              content={"Log In As"}
              onClick={() => {
                setIsLoginAsModalOpen(true)
                setMicrositeCustomer(r)
              }}
            />
            <Dropdown.Item
              icon="lock"
              content={"Update Password"}
              onClick={() => {
                setIsUpdatePasswordModalOpen(true)
                setMicrositeCustomer(r)
              }}
            />
            <Dropdown.Item
              icon="redo"
              content={"Reset Password"}
              onClick={() => {
                setIsResetPasswordModalOpen(true)
                setMicrositeCustomer(r)
              }}
            />
            <Dropdown.Item
              icon="level up alternate"
              as={Link}
              content={"Order Limit Increase"}
              to={{
                pathname: `${pathPrefix}/customers/order-limits`,
                state: {
                  micrositeCustomer: r._id,
                  microsite: micrositeId,
                  IsOrderLimitIncreaseModalOpen: true
                }
              }}
            />

          </Dropdown.Menu>
        </Dropdown> */}
          {/* <Popup
          size='mini'
          content="Edit"
          trigger={
            <Link to={`/settings/microsites/${micrositeId}/customers/${r._id}`}>
              <Button size={'mini'} icon={'edit'} />
            </Link>
          }
        /> */}

          <IconButton
            ml={2}
            aria-label={'Edit'}
            icon={<EditIcon />}
            as={Link}
            variant={'outline'}
            size={'sm'}
            to={`/settings/microsites/${micrositeId}/customers/${r._id}`}
          >
            Edit
          </IconButton>
        </>
      ),
      textAlign: 'right',
    },
  ];

  const headerActions = (
    <>
      <ButtonGroup spacing={2}>
        <Button as={Link} to={pathPrefix}>
          Back
        </Button>
        <Button onClick={handleExportClick} isLoading={isLoadingExport}>
          Export
        </Button>
        <Button onClick={() => setIsInviteModalOpen(true)}>Invite Customer</Button>
        <Button as={Link} to={`${pathPrefix}/customers/add`}>
          New
        </Button>
      </ButtonGroup>
    </>
  );

  return (
    <div>
      <ListPage
        header="Customers"
        subheader="Microsite"
        useListValues={useListValues}
        history={history}
        pathPrefix={`${pathPrefix}/customers`}
        tableDefintion={tableDefinition}
        withCheckboxes={true}
        headerActions={headerActions}
        getFilterDefinition={() => getFilterDefinition(micrositeId)}
      />

      {isInviteModalOpen && (
        <InviteModal
          open={isInviteModalOpen}
          handleSubmit={handleInviteSubmit}
          handleClose={closeModal}
        />
      )}
      {dataExport.isModalOpen && (
        <ExportModal
          isModalOpen={dataExport.isModalOpen}
          handleClose={handleClose}
          exportUrl={dataExport.url}
        />
      )}

      {isLoginAsModalOpen && (
        <LoginAsModal
          microsite={microsite}
          micrositeCustomer={micrositeCustomer}
          isOpen={isLoginAsModalOpen}
          onClose={() => {
            setIsLoginAsModalOpen(false);
            setMicrositeCustomer({});
          }}
        />
      )}

      {isUpdatePasswordModalOpen && (
        <UpdatePasswordModal
          open={isUpdatePasswordModalOpen}
          errorMessage={errorMessage.updatePassword}
          isSubmitting={isSubmitting.updatePassword}
          onClose={() => {
            setIsUpdatePasswordModalOpen(false);
            setMicrositeCustomer({});
          }}
          onSubmit={handleUpdatePassword}
        />
      )}

      {isResetPasswordModalOpen && (
        <ResetPasswordModal
          isOpen={isResetPasswordModalOpen}
          onClose={() => {
            setIsResetPasswordModalOpen(false);
            setMicrositeCustomer({});
          }}
          onSubmit={handleResetPassword}
          errorMessage={errorMessage.resetPassword}
        />
      )}

      {isClearCartModalOpen && (
        <ClearCartModal
          open={isClearCartModalOpen}
          onConfirm={handleClearCartSubmit}
          errorMessage={errorMessage.clearCart}
          isSubmitting={isSubmitting.clearCart}
          onClose={() => {
            setIsClearCartModalOpen(false);
            setMicrositeCustomer({});
          }}
        />
      )}
    </div>
  );
};
