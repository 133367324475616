import * as React from 'react';
import { connect } from 'react-redux';
import {
  InjectedFormProps,
  Field,
  FieldArray,
  reduxForm,
  change,
  formValueSelector,
  FormProps,
} from 'redux-form';
import { Link } from 'react-router-dom';
import { slugify } from 'underscore.string';
import { services } from '../../bootstrap/feathers';
import { semanticFormDropdownField, validateRequired } from '../../common';
import { EntityDropdownWrapper2 } from '../EntityDropdown';

const validate = validateRequired(['name', 'key']);

type PortalProductFormProps = {} & InjectedFormProps;

interface PortalProductFormState {
  productServiceParams: any;
  attributeComponents: any;
  storeId: string;
}

const productsDropdown = EntityDropdownWrapper2('/xmpie/products', null, {
  resultMap: (row) => {
    return { value: `${row.productId}`, text: row.name };
  },
  searchMap: (qs) => ({ name: { $LIKE: qs } }),
});

class PortalProductFormComponent extends React.Component<
  PortalProductFormProps,
  PortalProductFormState
> {
  constructor(props) {
    super(props);
    this.state = {
      productServiceParams: { query: { $sort: { name: 1 }, isDisabled: { $ne: true } } },
      attributeComponents: [],
      storeId: null,
    };
  }

  render() {
    const { pristine, submitting } = this.props;
    if (!this.props.storeId) {
      return null;
    }

    const serviceParams = {
      query: {
        $sort: { name: 1 },
        storeId: this.props.storeId,
      },
    };

    return (
      <form className="ui segment top attached tiny form" onSubmit={this.props.handleSubmit}>
        <Field
          name="XMPieProducts"
          component={semanticFormDropdownField}
          as={productsDropdown}
          search
          fluid
          multiple
          label="XMPie Product"
          placeholder="XMPie Product..."
          serviceParams={serviceParams}
        />

        <Link className="ui button" to="/settings/portals/">
          Back
        </Link>
        <button className="ui button primary" disabled={pristine || submitting} type="submit">
          Submit
        </button>
      </form>
    );
  }
}

const formName = 'PortalProductForm';
const reduxedForm: any = reduxForm({
  form: formName,
  enableReinitialize: true,
  validate,
})(PortalProductFormComponent);

const mapDispatchToProps = (dispatch) => ({
  changeFieldValue: (field, value) => dispatch(change(formName, field, value)),
});
export default connect(null, mapDispatchToProps)(reduxedForm) as any;
