import * as React from 'react';
import UpdateTurnaroundForm from './UpdateTurnarondForm';
import { Button, Card, Grid, Header, Label, Message, Segment, Table } from 'semantic-ui-react';
import { DetailWrapper, DetailFeaturesProps } from './../../../../bootstrap/DetailWrapper';
import feathers from './../../../../bootstrap/feathers';

export interface ContainerProps {}

export interface ContainerState {}

class Container extends React.Component<DetailFeaturesProps, ContainerState> {
  constructor(props: DetailFeaturesProps) {
    super(props);

    this.state = {};
  }

  handleSubmit = (values) => {
    feathers
      .service('/order-items/turnaround')
      .create({
        timeMax: values.turnaround,
        orderItemId: this.props.data._id,
      })
      .then(() => {
        this.props.history.push('/');
      });
  };

  renderInfoTable() {
    if (!this.props.data) {
      return null;
    }

    const { data } = this.props;
    return (
      <Segment size={'small'}>
        <Grid columns={'2'} divided>
          <Grid.Row>
            <Grid.Column>
              <Table basic="very" celled size={'small'}>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>ID</Table.Cell>
                    <Table.Cell>{data.humanId}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Customer</Table.Cell>
                    <Table.Cell>
                      {data.customer.organizationName
                        ? data.customer.organizationName
                        : `${data.customer.primaryContact.firstName} ${data.customer.primaryContact.lastName}`}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Turnaround</Table.Cell>
                    <Table.Cell>{data.timeMax}</Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Grid.Column>
            <Grid.Column>
              <Table basic="very" celled size={'small'}>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>Category</Table.Cell>
                    <Table.Cell>{data.category ? data.category.name : 'Custom'}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Description</Table.Cell>
                    <Table.Cell>
                      {data.attributes &&
                        data.attributes.reduce((prev, next) => `${prev} ${next.value.name}`, '')}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Status</Table.Cell>
                    <Table.Cell>
                      {data.productionStatus && (
                        <Label size={'mini'} content={data.productionStatus.description} />
                      )}
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }

  render() {
    if (!this.props.data) {
      return null;
    }
    const initialValues = { turnaround: this.props.data.timeMax };
    return (
      <div>
        <Header as="h1">Update Turnaround Time</Header>
        <Segment>
          {this.renderInfoTable()}
          <UpdateTurnaroundForm initialValues={initialValues} onSubmit={this.handleSubmit} />
        </Segment>
      </div>
    );
  }
}

export default DetailWrapper(Container, 'orderItems', {
  submitRedirect: '/',
  query: {
    $populate: ['customer', 'category', 'attributes.value'],
  },
});
