import React from 'react';

import { Switch, Route } from 'react-router-dom';

import List from './List';
import Detail from './Detail';

export default function route() {
  return (
    <Switch>
      <Route exact path="/settings/turnaround-holidays" component={List} />
      <Route exact path="/settings/turnaround-holidays/add" component={Detail} />
      <Route exact path="/settings/turnaround-holidays/:id" component={Detail} />
    </Switch>
  );
}
