import * as React from 'react';
import {
  Button,
  Grid,
  Input,
  Message,
  Icon,
  Form,
  Modal,
  Checkbox,
  Segment,
} from 'semantic-ui-react';
import { Formik, Form as FormikForm, Field, FieldArray, FieldProps, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import * as Cleave from 'cleave.js/react';

import countries from 'world-countries';
import provinces from 'provinces';

import { useSelector } from 'react-redux';

import { humanize } from 'underscore.string';
import { FeathersDropdown } from '@inkcloud/components';
import { Languages } from '../../../common';

import { SelectCustomerModal } from '../../order-builder/components/SelectCustomerModal';
import { AssignedCustomer } from '../../order-builder/components/AssignedCustomer';

import { SemanticField } from '../../../common/Form';

import Feathers from '../../../bootstrap/feathers';
import { ImageUpload } from '../../products/products/stock-product/StockForm/ImageUpload';

export interface CustomQuoteFormProps {
  id: string;
  errorMessage: string;
  item: any;
  onSubmit: (v: any) => void;
}

const baseUrl = `https://storage.googleapis.com/icstorage/`;

const { useState, useEffect } = React;

export const shippingOptions = [
  { value: 'SHIPPING_SHIP_OUT', text: 'Ship Out' },
  { value: 'SHIPPING_LOCAL_DELIVERY', text: 'Local Delivery' },
  { value: 'SHIPPING_WILL_CALL', text: 'Will-Call' },
];

const initialStateShipTo = {
  name: '',
  careOf: '',
  address1: '',
  address2: '',
  city: '',
  stateProvince: '',
  postalCode: '',
  countryCode: '',
};

export const lookup = [
  {
    single: ['PAPER', 'COATING', 'COLOR'],
  },
  {
    multi: ['COVER_PAPER', 'COVER_COATING', 'INSIDE_PAPER', 'INSIDE_COATING', 'NUM_PAGES'],
  },
  {
    wide: ['MATERIAL', 'COLOR'],
  },
];

export const attr = (type: string, attributes: any[]) => {
  return lookup.reduce((res, raw) => {
    const values = Object.values(raw)[0];
    const key = Object.keys(raw)[0];

    const productType = key === type;

    if (productType) {
      const resultAttr = values.reduce((acc, cur) => {
        const group = attributes.filter((attr) => attr.entityAttribute === cur && attr);

        if (group.length > 0) {
          const filteredAttr = group
            .reduce((filtered, item) => {
              if (filtered.find((f) => f.hrCode === item.hrCode)) {
                return filtered;
              }

              filtered.push(item);

              return filtered;
            }, [])
            .sort(function (a, b) {
              const nameA = a.name.toLowerCase();
              const nameB = b.name.toLowerCase();
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              return 0;
            });

          acc.push({
            attributeTypeId: cur,
            attributes: [...filteredAttr, { others: 'Others' }],
          });
        }

        return acc;
      }, []);

      res.push(resultAttr);
    }

    return res;
  }, []);
};
let attributeValueLookup = [];

export const unitTypeOptions = [
  { text: 'inch', value: 'inch' },
  { text: 'cm', value: 'cm' },
  { text: 'foot', value: 'foot' },
  { text: 'meter', value: 'meter' },
];

export const addOnsOptions = [
  { value: 'RC', text: 'Round Corners' },
  { value: 'FS', text: 'Foil Stamping' },
  { value: 'SC', text: 'Scoring' },
  { value: 'EM', text: 'Embossing' },
  { value: 'addOnsOther', text: 'Other' },
];

export const initialMapper = (values) => {
  if (values) {
    let newValues = {
      ...values,
      addOns: [
        ...values.addOns,
        {
          addOnId: (values?.addOnsOther && 'addOnsOther') || '',
        },
      ],
    };

    if (!values.addOnsOther) {
      const newAddOns = newValues?.addOns?.filter((a) => a.addOnId !== '');
      newValues = {
        ...newValues,
        addOns: newAddOns,
      };
    }
    return newValues;
  }
};

export const CustomQuoteForm: React.FunctionComponent<CustomQuoteFormProps> = (props: any) => {
  const { id, item, errorMessage, onSubmit } = props;

  const isEdit = !!id;

  const tenant = useSelector(
    ({ globals }: { globals: { tenant: { internationalization: { countryCode: string } } } }) =>
      globals.tenant.internationalization
  );

  const user = useSelector(({ auth }: { auth: { user: { business: string } } }) => auth.user);

  const [attributes, setAttributes] = useState([]);
  const [initAttributes, setInitAttributes] = useState(null);
  const [methods, setMethods] = useState(null);
  const [customerId, setCustomerId] = useState('');
  const [customer, setCustomer] = useState('');

  const [isTranslationSite, setIsTranslationSite] = useState(false);

  const [isSelectCustomerModalOpen, setIsSelectCustomerModalOpen] = useState(false);

  useEffect(() => {
    const loadAttributes = async () => {
      try {
        const res = await Feathers.service('/products-legacy/attributes').find({
          query: {
            $np: 1,
            $sort: { name: 1 },
          },
        });

        if (res) {
          const initial = item?.type?.split('-')[0] ?? 'single';

          attributeValueLookup = attr(initial, res);
          setAttributes(res);

          if (!isEdit && attributeValueLookup) {
            const initValue = attributeValueLookup[0]?.map((attr) => {
              const value = {
                name: attr?.attributes[0]?.name ?? '',
                attribute: attr?.attributes[0]?.hrCode ?? '',
                attributeId: attr?.attributes[0]?._id ?? '',
                type: attr?.attributes[0]?.entityAttribute ?? '',
              };
              return value;
            });

            setInitAttributes(initValue);
          }
        }
      } catch (error) {}
    };

    const loadTenantSettings = async () => {
      try {
        const res: any = await Feathers.service('/tenant-settings').get(user.business, {
          query: {
            // $select: ['shipping']
          },
        });

        if (res) {
          setMethods(res.shipping?.methods);
          setIsTranslationSite(res.general?.isTranslationSite);
        }
      } catch (e) {}
    };

    loadTenantSettings();
    // if (!isEdit) {
    // }

    loadAttributes();
  }, []);

  useEffect(() => {
    const loadCustomer = async (id: string) => {
      try {
        const res: any = await Feathers.service('/customers').get(id, { query: {} });

        if (res) {
          setCustomer(res);
        }
      } catch (e) {}
    };

    if (customerId) {
      loadCustomer(customerId);
    }
  }, [customerId]);

  const initValue = isEdit
    ? initialMapper({
        ...item,
        isSameDimension: item.type === 'single-page' || item.type === 'others',
      })
    : {
        ...item,
        attributes: initAttributes,
        selectedShippingMethod: methods?.length > 0 ? methods[0] : '',
        isTranslationSite,
      };

  return (
    <Formik
      initialValues={{
        ...initValue,
        shipTo: {
          ...initValue?.shipTo,
          countryCode: initValue?.shipTo?.countryCode || tenant?.countryCode,
        },
      }}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values);
      }}
      enableReinitialize={true}
    >
      {(innerProps) => {
        const {
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
          setFieldValue,
          setFieldTouched,
        } = innerProps;

        // console.log('values', values);

        const handleProductTypeChange = (value: string) => {
          setFieldValue('type', value);
          setFieldValue('finishedDimensions', '');

          if (value === 'single-page' || value === 'packaging') {
            attributeValueLookup = attr('single', attributes);
          } else if (value === 'multi-page') {
            attributeValueLookup = attr('multi', attributes);
          } else if (value === 'wide-format') {
            attributeValueLookup = attr('wide', attributes);
          }

          if (value === 'single-page' || value === 'others') {
            setFieldValue('isSameDimension', true);
          } else {
            setFieldValue('isSameDimension', false);
          }

          if (attributeValueLookup) {
            const initValue = attributeValueLookup[0]?.map((attr) => {
              const value = {
                name: attr?.attributes[0]?.name ?? '',
                attribute: attr?.attributes[0]?.hrCode ?? '',
                attributeId: attr?.attributes[0]?._id ?? '',
                type: attr?.attributes[0]?.entityAttribute ?? '',
              };
              return value;
            });

            setFieldValue('attributes', item.type === value ? item.attributes : initValue);
          }
        };

        const handleShippingMethodChange = (data: any) => {
          setFieldValue('selectedShippingMethod', data.value);
        };

        const handleSelectCustomer = (id: string) => {
          setFieldValue('customer', id);
          setIsSelectCustomerModalOpen(false);
          setCustomerId(id);
        };

        const handleLanguageChange = (data: any) => {
          const foundLang = data.options.find((o) => o.value === data.value);

          setFieldValue(data.name, foundLang.text);
        };

        const usStates = provinces
          .filter((p) => p.country === 'US')
          .map((c: any) => ({ text: c.name, value: c.short }));

        const caProvinces = provinces
          .filter((p) => p.country === 'CA')
          .map((c: any) => ({ text: c.name, value: c.short }));

        const sourceLang = values?.metadata?.find((meta) => meta?.key === 'tr_source_name');
        const destinationLang = values?.metadata?.find((meta) => meta?.key === 'tr_dest_name');

        const isTranslationDisable =
          isTranslationSite && !isEdit && (!values?.sourceLanguage || !values?.destinationLanguage);

        const showForm = (!isEdit && values.customer) || isEdit;

        return (
          <FormikForm
            className={`ui form ${isSubmitting && !errorMessage && 'loading'} ${
              errorMessage ? 'error' : ''
            }`}
          >
            {!isEdit && (
              <Segment>
                <Form.Group widths="equal" style={{ marginBottom: 0 }}>
                  {customer && (
                    <Form.Field>
                      <AssignedCustomer customer={customer} microsite={''} />
                    </Form.Field>
                  )}
                  <Form.Field>
                    <Button
                      primary
                      size="mini"
                      type="button"
                      content="Select Customer"
                      onClick={() => setIsSelectCustomerModalOpen(true)}
                    />
                  </Form.Field>

                  {isSelectCustomerModalOpen && (
                    <SelectCustomerModal
                      handleSubmit={handleSelectCustomer}
                      handleClose={() => setIsSelectCustomerModalOpen(false)}
                      open={isSelectCustomerModalOpen}
                      microsites={[]}
                    />
                  )}
                </Form.Group>
              </Segment>
            )}
            {showForm && (
              <div className={`${!isEdit ? 'ui segment' : ''}`}>
                <Form.Field>
                  <label>Initial Status</label>
                  <Field
                    name="initialStatus"
                    render={({ field }: FieldProps<any>) => (
                      <FeathersDropdown
                        search
                        selection
                        clearable
                        serviceName="/order-item-statuses"
                        query={{
                          $sort: { name: 1 },
                          $np: 1,
                        }}
                        resultMapper={(r) => ({ key: r._id, value: r._id, text: r.name })}
                        {...field}
                        onChange={(e, data) =>
                          field.onChange({
                            target: { value: data.value, name: 'initialStatus' },
                          } as any)
                        }
                      />
                    )}
                  />
                </Form.Field>
                <div className="field" style={{ marginBottom: 0 }}>
                  <label>Product Type</label>
                </div>
                <Form.Group widths="equal">
                  <Form.Field>
                    <Form.Radio
                      name="type"
                      label="Single Page"
                      value="single-page"
                      checked={values.type === 'single-page'}
                      onBlur={handleBlur}
                      onChange={() => handleProductTypeChange('single-page')}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Form.Radio
                      name="type"
                      label="Multi Page"
                      value="multi-page"
                      checked={values.type === 'multi-page'}
                      onBlur={handleBlur}
                      onChange={() => handleProductTypeChange('multi-page')}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Form.Radio
                      name="type"
                      label="Packaging"
                      value="packaging"
                      checked={values.type === 'packaging'}
                      onBlur={handleBlur}
                      onChange={() => handleProductTypeChange('packaging')}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Form.Radio
                      name="type"
                      label="Wide Format"
                      value="wide-format"
                      checked={values.type === 'wide-format'}
                      onBlur={handleBlur}
                      onChange={() => handleProductTypeChange('wide-format')}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Form.Radio
                      name="type"
                      label="Others"
                      value="others"
                      checked={values.type === 'others'}
                      onBlur={handleBlur}
                      onChange={() => handleProductTypeChange('others')}
                    />
                  </Form.Field>
                </Form.Group>

                <Grid>
                  <Grid.Column width={6}>
                    <label>
                      <strong>Flat Dimensions</strong>
                    </label>
                    <Form.Group widths="equal">
                      <Form.Field>
                        <SemanticField
                          name="flatDimensions.width"
                          label="Width"
                          component={Form.Input}
                          fluid
                        />
                      </Form.Field>
                      <Form.Field>
                        <SemanticField
                          name="flatDimensions.height"
                          label="Height"
                          component={Form.Input}
                          fluid
                        />
                      </Form.Field>
                    </Form.Group>
                  </Grid.Column>
                  <Grid.Column width={10}>
                    <label>
                      <strong>Finished Dimensions</strong>
                    </label>
                    <Form.Group widths="equal">
                      {
                        <>
                          {values.type === 'packaging' ? (
                            <>
                              <Form.Field>
                                <SemanticField
                                  name="finishedDimensions.width"
                                  label="Width"
                                  component={Form.Input}
                                  fluid
                                />
                              </Form.Field>
                              <Form.Field>
                                <SemanticField
                                  name="finishedDimensions.length"
                                  label="Length"
                                  component={Form.Input}
                                  fluid
                                />
                              </Form.Field>
                              <Form.Field>
                                <SemanticField
                                  name="finishedDimensions.depth"
                                  label="Depth"
                                  component={Form.Input}
                                  fluid
                                />
                              </Form.Field>
                            </>
                          ) : (
                            values.type !== 'wide-format' &&
                            !values.isSameDimension && (
                              <>
                                <Form.Field>
                                  <SemanticField
                                    name="finishedDimensions.width"
                                    label="Width"
                                    component={Form.Input}
                                    fluid
                                  />
                                </Form.Field>
                                <Form.Field>
                                  <SemanticField
                                    name="finishedDimensions.height"
                                    label="Height"
                                    component={Form.Input}
                                    fluid
                                  />
                                </Form.Field>
                              </>
                            )
                          )}
                          {values.type !== 'wide-format' && values.type !== 'packaging' && (
                            <Form.Field>
                              <label>&nbsp;</label>
                              <SemanticField
                                name="isSameDimension"
                                label="Same as Flat dimensions"
                                component={Form.Checkbox}
                              />
                            </Form.Field>
                          )}
                        </>
                      }
                    </Form.Group>
                  </Grid.Column>
                </Grid>

                <Form.Group widths="equal">
                  <Form.Field>
                    <SemanticField
                      name="flatDimensions.unitType"
                      label="Unit"
                      component={Form.Dropdown}
                      selection
                      fluid
                      options={unitTypeOptions}
                    />
                  </Form.Field>
                </Form.Group>

                <Form.Group widths="equal">
                  <Form.Field>
                    <SemanticField
                      name="customerReferenceName"
                      label="Reference"
                      component={Form.Input}
                      fluid
                    />
                  </Form.Field>
                </Form.Group>

                {values.type !== 'others' && (
                  <FieldArray
                    name="attributes"
                    render={(arrayHelpers) => (
                      <div className="field">
                        {attributeValueLookup[0]?.map((attr: any, index: number) => {
                          const isDefaultType = item.type === values.type;

                          const attributeId = !isDefaultType
                            ? values.attributes[index]?.attributeId ?? ''
                            : item?.attributes?.[index]?.attributeId ?? '';

                          const defaultValue = isEdit
                            ? attr?.attributes.find((att) => att._id === attributeId)?._id ?? ''
                            : attr.attributes[0]._id;

                          const attrOptions =
                            attr?.attributes?.map((option) => ({
                              text: option.name ? option.name : option.others,
                              value: option._id ? option._id : option.others,
                            })) ?? [];

                          const attributesTemplate = (
                            <Form.Group widths="equal" key={attr.attributeTypeId}>
                              <Form.Field>
                                <Form.Dropdown
                                  name={`attributes[${index}].attributeId`}
                                  label={humanize(attr.attributeTypeId)}
                                  selection
                                  fluid
                                  options={attrOptions}
                                  defaultValue={defaultValue || 'Others'}
                                  onChange={(e, data) => {
                                    const found =
                                      attr.attributes?.find((a) => a._id === data.value) ?? '';

                                    if (found) {
                                      const value = {
                                        name: found.name,
                                        attributeId: found._id,
                                        type: found.entityAttribute,
                                      };

                                      arrayHelpers.replace(index, value);
                                    } else {
                                      const value = {
                                        others: data.value,
                                        type: attr.attributeTypeId,
                                      };

                                      arrayHelpers.replace(index, value);
                                    }
                                  }}
                                />
                              </Form.Field>
                              {values?.attributes?.[index]?.others && (
                                <Form.Field>
                                  <Form.Input
                                    name={`attributes[${index}].others`}
                                    label={`\u00A0`}
                                    defaultValue={values?.attributes?.[index]?.others ?? ''}
                                    onChange={(e, data) => {
                                      const value = {
                                        others: data.value,
                                        type: attr.attributeTypeId,
                                      };

                                      arrayHelpers.replace(index, value);
                                    }}
                                  />
                                </Form.Field>
                              )}
                            </Form.Group>
                          );

                          return attributesTemplate;
                        }) ?? null}
                      </div>
                    )}
                  />
                )}
                <Form.Group widths="equal">
                  <Form.Field>
                    <SemanticField name="quantity" label="Quantity" component={Form.Input} />
                  </Form.Field>
                </Form.Group>

                <label>
                  <strong>Turnaround Time</strong>
                </label>
                <Message size="small">
                  Enter turnaround time in days. Minimum and maximum can be equal or two different
                  values
                </Message>
                <Form.Group widths="equal">
                  <Form.Field>
                    <SemanticField
                      name="timeMin"
                      label="Minimum Turnaround Time"
                      component={Form.Input}
                    />
                  </Form.Field>
                  <Form.Field>
                    <SemanticField
                      name="timeMax"
                      label="Maximum Turnaround Time"
                      component={Form.Input}
                    />
                  </Form.Field>
                </Form.Group>

                {values.type === 'single-page' && (
                  <>
                    <FieldArray
                      name="addOns"
                      render={(arrayHelpers) => (
                        <div className="field">
                          <label>Add Ons</label>
                          <Form.Group widths="equal">
                            {addOnsOptions?.map((addOn, index) => (
                              <Form.Field key={index}>
                                <div className="ui checkbox">
                                  <Checkbox
                                    label={addOn.text}
                                    value={addOn.value}
                                    checked={
                                      (values?.addOns?.find((a) => a.addOnId === addOn.value) ??
                                        false) ||
                                      (values.addOnsOther && addOn.value === 'addOnsOther')
                                    }
                                    onChange={(e, data) => {
                                      if (data.checked) {
                                        arrayHelpers.push({ addOnId: addOn.value });
                                      } else {
                                        const idx = values.addOns.findIndex(
                                          (a) => a.addOnId === addOn.value
                                        );
                                        arrayHelpers.remove(idx);
                                      }
                                    }}
                                  />
                                </div>
                              </Form.Field>
                            ))}
                          </Form.Group>
                        </div>
                      )}
                    />

                    {(values.addOns?.find((a) => a.addOnId === 'addOnsOther') ||
                      values.addOnsOther) && (
                      <Form.Group widths="equal">
                        <Form.Field>
                          <SemanticField
                            name="addOnsOther"
                            label="Other Add Ons"
                            component={Form.Input}
                          />
                        </Form.Field>
                      </Form.Group>
                    )}
                  </>
                )}

                {isTranslationSite && (
                  <>
                    <Languages
                      sourceName={sourceLang?.value ?? ''}
                      destinationName={destinationLang?.value ?? ''}
                      sourceLabel="Source Language"
                      destinationLabel="Destination Language"
                      onChange={handleLanguageChange}
                    />
                    <br />
                  </>
                )}

                <Form.Group widths="equal">
                  {values.selectedShippingMethod !== 'SHIPPING_DIGITAL_DELIVERY' && (
                    <Form.Field>
                      <SemanticField name="weight" label="Weight" component={Form.Input} />
                    </Form.Field>
                  )}
                  <Form.Field>
                    <label>Price</label>
                    <Cleave
                      name="price"
                      options={{
                        numeral: true,
                        rawValueTrimPrefix: true,
                        numeralPositiveOnly: true,
                        numeralThousandsGroupStyle: 'thousand',
                        prefix: '$',
                      }}
                      value={values?.price || ''}
                      onChange={(e) => setFieldValue('price', e.target.rawValue)}
                    />
                  </Form.Field>
                </Form.Group>

                <Form.Group widths="equal">
                  <Form.Field>
                    <SemanticField
                      name="additionalInfo"
                      label="Additional Note"
                      component={Form.TextArea}
                    />
                  </Form.Field>
                </Form.Group>

                {!isEdit && (
                  <>
                    <div className="field" style={{ marginBottom: 0 }}>
                      <label>Shipping </label>
                    </div>
                    <Form.Group widths="equal">
                      {methods?.map((method: any, index: number) => (
                        <Form.Field key={index}>
                          <Form.Radio
                            name="selectedShippingMethod"
                            label={humanize(method)}
                            value={method}
                            checked={values.selectedShippingMethod === method}
                            onBlur={handleBlur}
                            onChange={(e, data) => {
                              handleShippingMethodChange(data);
                            }}
                          />
                        </Form.Field>
                      ))}
                    </Form.Group>
                  </>
                )}

                {(values?.selectedShippingMethod === 'SHIPPING_SHIPOUT_AND_DIGITAL_DELIVERY' ||
                  values?.selectedShippingMethod === 'SHIPPING_SHIP_OUT') &&
                  !isEdit && (
                    <>
                      <Form.Group widths="equal">
                        <Form.Field>
                          <SemanticField
                            name="shipTo.name"
                            label="Name"
                            component={Form.Input}
                            fluid
                          />
                        </Form.Field>
                        <Form.Field>
                          <SemanticField
                            name="shipTo.careOf"
                            label="Care Of"
                            component={Form.Input}
                            fluid
                          />
                        </Form.Field>
                      </Form.Group>
                      <Form.Group widths="equal">
                        <Form.Field>
                          <SemanticField
                            name="shipTo.countryCode"
                            label="Country"
                            selection
                            search
                            component={Form.Dropdown}
                            options={countries.map((c: any) => ({
                              text: c.name.official,
                              value: c.cca2,
                            }))}
                            fluid
                          />
                        </Form.Field>
                      </Form.Group>
                      <Form.Group widths="equal">
                        <Form.Field>
                          <SemanticField
                            name="shipTo.address1"
                            label="Address 1"
                            component={Form.Input}
                            fluid
                          />
                        </Form.Field>
                      </Form.Group>
                      <Form.Group widths="equal">
                        <Form.Field>
                          <SemanticField
                            name="shipTo.address2"
                            label="Address 2"
                            component={Form.Input}
                            fluid
                          />
                        </Form.Field>
                      </Form.Group>
                      <Form.Group widths="equal">
                        <Form.Field>
                          <SemanticField
                            name="shipTo.city"
                            label="City"
                            component={Form.Input}
                            fluid
                          />
                        </Form.Field>
                        <Form.Field>
                          {values?.shipTo?.countryCode === 'US' ||
                          values?.shipTo?.countryCode === 'CA' ||
                          values?.shipTo?.countryCode === 'AU' ? (
                            <SemanticField
                              name="shipTo.stateProvince"
                              label={
                                values?.shipTo?.countryCode === 'AU'
                                  ? 'State/Territory'
                                  : 'State/Province'
                              }
                              selection
                              search
                              component={Form.Dropdown}
                              autoComplete="off"
                              options={values.shipTo.countryCode === 'US' ? usStates : caProvinces}
                              fluid
                            />
                          ) : (
                            <SemanticField
                              name="shipTo.stateProvince"
                              label="State/Province"
                              component={Form.Input}
                              fluid
                            />
                          )}
                        </Form.Field>
                        <Form.Field>
                          <SemanticField
                            name="shipTo.postalCode"
                            label="Postal Code"
                            component={Form.Input}
                            fluid
                          />
                        </Form.Field>
                      </Form.Group>
                    </>
                  )}

                <div className="field" style={{ marginBottom: 0 }}>
                  <label>Attachment </label>
                </div>

                <FieldArray
                  name="miscUploads"
                  render={(arrayHelpers) => (
                    <>
                      <ImageUpload
                        onUpload={(files) => {
                          if (files) {
                            arrayHelpers.insert(
                              values.miscUploads.length,
                              files?.files?.files?.[0]
                            );
                          }
                        }}
                        showFiles={false}
                        api="/uploads-misc"
                      />

                      {values?.miscUploads?.map((upload: any, index: number) => (
                        <p key={index}>
                          <a href={`${baseUrl}${upload?.cloudFilename}`} target="_blank">
                            <Icon name="file alternate outline" />
                            {upload?.originalFilename}
                          </a>{' '}
                          <Icon name="close" onClick={() => arrayHelpers.remove(index)} />
                        </p>
                      )) ?? ''}
                    </>
                  )}
                />

                {errorMessage && <Message error content={errorMessage} />}

                <Button
                  primary
                  type="submit"
                  disabled={
                    (isSubmitting && !errorMessage) ||
                    (!isEdit && !values.customer) ||
                    isTranslationDisable
                  }
                  content="Save"
                />
              </div>
            )}
          </FormikForm>
        );
      }}
    </Formik>
  );
};
