import * as React from 'react';
import { Switch, Route } from 'react-router-dom';

import { CartForm } from './CartForm';

const routes = (props) => (
  <Switch>
    <Route exact path="/settings/cart" component={CartForm} />
  </Switch>
);

export default routes;
