import * as React from 'react';
import { Form, Checkbox, Icon, Popup } from 'semantic-ui-react';
import Select, { components } from 'react-select';
import { Feathers } from '../../../../bootstrap/feathers/feathers';

export interface ICustomerPickerProps {
  style: React.CSSProperties;
  value: {
    mode: string;
    microsite: string;
    customer: string;
    customerData: any;
    micrositeUser: string;
  };
  onChange: (value: any) => void;
  loadCustomers: (inputValue: string) => Promise<any>;
  loadMicrositeCustomers: (inputValue: string) => Promise<any>;
  microsites: { label: string; value: string }[];
}

const CustomerModeHelp = (
  <Popup
    content="Create a sales order for a microsite customer or your direct customer"
    trigger={<Icon name="help circle" />}
  />
);

export function CustomerPicker(props: ICustomerPickerProps) {
  const { value, onChange, loadCustomers, loadMicrositeCustomers, microsites, style } = props;
  const { mode, microsite, customer } = value;
  const [customers, setCustomers] = React.useState<any>([]);

  React.useEffect(() => {
    const loadMSCustomers = async () => {
      const results = await loadMicrositeCustomers('');
      setCustomers(results);
    };

    const loadTenantCustomers = async () => {
      const results = await loadCustomers('');

      setCustomers(results);
    };

    const loadMicrosite = async () => {
      const res = await Feathers.service('/microsites').get(microsite, { query: {} });
      // console.log('load ms value', value);
      onChange({ ...value, customer: res?.customer });
    };

    if (mode === 'microsite' && microsite) {
      loadMSCustomers();
      loadMicrosite();
    } else {
      loadTenantCustomers();
    }
  }, [microsite, mode]);

  const customerOptions =
    mode === 'customer'
      ? customers?.map((c) => {
          let label = `${c?.organizationName} ? ${c.organizationName}`;
          if (c.billingClientName) {
            label = `${label} - ${c.billingClientName ?? ''}`;
          } else if (c?.primaryContact?.firstName) {
            label = `${label} - ${c?.primaryContact?.firstName ?? ''} ${
              c?.primaryContact?.lastName ?? ''
            }`;
          }

          return {
            label,
            value: c._id,
          };
        })
      : customers;

  return (
    <div style={style}>
      <Form>
        <Form.Field>Select a customer mode {CustomerModeHelp}</Form.Field>
        <Form.Field inline>
          <Checkbox
            radio
            label="Microsite"
            name="mode"
            value="microsite"
            checked={mode === 'microsite' || !mode}
            onChange={(e, { value: v }) =>
              onChange({
                ...value,
                mode: v,
                microsite: null,
                micrositeUser: null,
                customer: null,
                customerData: {},
              })
            }
          />
          <Checkbox
            radio
            label="Top Level Customer"
            name="mode"
            value="customer"
            checked={mode === 'customer'}
            onChange={(e, { value: v }) =>
              onChange({
                ...value,
                mode: v,
                microsite: null,
                micrositeUser: null,
                customer: null,
                customerData: {},
              })
            }
          />
        </Form.Field>
        <div style={{ display: 'flex', gap: 10 }}>
          {mode === 'microsite' && (
            <Select
              onChange={(v) => {
                onChange({ ...value, microsite: v.value });
              }}
              placeholder="Search microsites..."
              isClearable={true}
              options={microsites}
              value={microsites?.find((m) => m.value === value.microsite)}
              styles={{
                container: (provided, state) => ({
                  ...provided,
                  flexGrow: 1,
                }),
                valueContainer: (provided, state) => ({
                  ...provided,
                }),
                control: (provided, state) => ({
                  ...provided,
                }),
              }}
            />
          )}
          {
            <Select
              isDisabled={mode === 'microsite' && !microsite}
              onChange={(v) => {
                onChange({
                  ...value,
                  customer: mode !== 'microsite' ? v.value : value.customer,
                  customerData: customers?.find((c) => c.value === v.value),
                  micrositeUser: mode === 'microsite' ? v.value : null,
                });
              }}
              placeholder="Search customers..."
              options={customerOptions}
              isClearable={true}
              value={
                customers?.find(
                  (c) => c.value === (mode === 'microsite' ? value.micrositeUser : value.customer)
                ) || null
              }
              styles={{
                container: (provided, state) => ({
                  ...provided,
                  flexGrow: 1,
                }),
                valueContainer: (provided, state) => ({
                  ...provided,
                }),
                control: (provided, state) => ({
                  ...provided,
                }),
              }}
            />
          }{' '}
        </div>
      </Form>
    </div>
  );
}
