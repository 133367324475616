import * as React from 'react';
import type { Identifier, XYCoord } from 'dnd-core';
import { useDrag, useDrop } from 'react-dnd';

const { useRef } = React;

const ItemTypes = {
  CARD: 'card',
};

interface CardOwnProps<E extends React.ElementType> {
  id?: string;
  index: number;
  move: (v1: number, v2: number) => void;
  style?: React.CSSProperties;
  children: React.ReactNode;
  as?: E;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

type CardProps<E extends React.ElementType> = CardOwnProps<E> &
  Omit<React.ComponentProps<E>, keyof CardOwnProps<E>>;

export const Card = <E extends React.ElementType = 'div'>({
  id,
  index,
  style,
  move,
  children,
  as,
  ...props
}: CardProps<E>) => {
  const Component = as || 'div';
  const ref = useRef<any>(null);
  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // console.log('dragIndex', dragIndex)
      // console.log('hoverIndex', hoverIndex)

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      if (move) {
        move(dragIndex, hoverIndex);
      }
      // moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [{ opacity }, drag] = useDrag(
    () => ({
      item: () => ({ id, index }),
      type: ItemTypes.CARD,
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.5 : 1,
      }),
    }),
    []
  );
  // const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
    <Component
      ref={ref}
      data-handler-id={handlerId}
      style={{ ...style, opacity, cursor: 'grab' }}
      className={`card-dnd ${props.className}`}
    >
      {children}
    </Component>
  );
};
