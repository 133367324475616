import { PackingSlipDocument } from './PackingSlipDocument';
import { PackingSlipPage } from './PackingSlipPage';
import Address from '../shared/Address';
import { BillTo } from './BillTo';
import { ShipTo } from './ShipTo';
import { Items } from './Items';
import { PackingSlipContext as Context } from './PackingSlipContext';
import BarCode from '../shared/Barcode';
import PropertyList from '../shared/PropertyList';

export * from './PackingSlipDocument';
export * from './PackingSlipPage';
export * from './BillTo';
export * from './ShipTo';
export * from './Items';

export const Components = {
  PackingSlipDocument,
  PackingSlipPage,
  BillTo,
  ShipTo,
  Items,
  Context,
  Address,
  BarCode,
  PropertyList,
};
