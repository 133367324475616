import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import { Button, Dropdown, Label, Menu, Image } from 'semantic-ui-react'
import {
  Box,
  Flex,
  Spacer,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  IconButton,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';

import { FiMenu } from 'react-icons/fi';
import Feathers, { services, feathersAuthentication } from '../../bootstrap/feathers';
import QuickSearch from '../../../../command-center/src/components/nav/QuickSearch';
import UserMessages from './UserMessages';
import LogInAsModal from '../customers/LogInAsModal';
import QRModal from './QRModal';

// let containerStyle = {
//   margin: '0 0 0 222px'
// };

export interface ConnectedProps {
  user?: any;
  tenantDomainName?: any;
  mode: string;
}

export interface TopNavProps {
  onOpen?: () => void;
  logout?: any;
  leftMargin: any;
  history: any;
}

type CombinedProps = TopNavProps & ConnectedProps;

export interface TopNavState {}

// const autocompleteAsync = (settings, callback) => {
//   Feathers.service('/customers').find({
//     query: {
//       organizationName: { '$LIKE': settings.urlData.query },
//       '$select': [
//         '_id', 'organizationName', 'city', 'stateProvince', 'humanId'
//       ]
//     }
//   }).then((res: any) => {
//     let results = res.data.map(row => ({
//       title: row.organizationName,
//       description: row.city + ', ' + row.stateProvince + ' #' + row.humanId + ')',
//       id: row._id
//     }));
//     let response = {
//       results,
//       success: true
//     };

//     callback(response);
//   })
//     .catch(err => console.dir('we got this ERRROR!!!!', err));
// };

// export class TopNavBase extends React.Component<CombinedProps, TopNavState> {
//   dropMenu: any;
//   searchElement: any;
//   constructor(props: CombinedProps) {
//     super(props);
//     this.handleLogout = this.handleLogout.bind(this);
//   }

//   handleLogout() {
//     this.props.logout();
//   }

//   render() {
//     const fullName = this.props.user ? `${this.props.user.firstName} ${this.props.user.lastName}` : '';
//     const trigger = (
//       <span>
//         <Image avatar src={'https://api.adorable.io/avatars/285/' + this.props.user.email + '.png'} /> {fullName} <i className='dropdown icon'></i>
//       </span>
//     );

//     const options = [
//       { key: 'user', text: 'Account', icon: 'user' },
//       { key: 'settings', text: 'Settings', icon: 'settings' },
//       { key: 'sign-out', text: 'Sign Out', icon: 'sign out' },
//     ]

//     const sandboxLabel = this.props.mode === 'sandbox' ?
//       <Menu.Item><Label color="yellow">Sandbox Mode</Label></Menu.Item> : '';

//     return (
//       <Menu fixed={'top'} size='tiny' style={{ paddingLeft: this.props.leftMargin - 13.74 }} >
//         {/* <Menu.Menu >
//         <Menu.Item>
//           <Dropdown item trigger={trigger} options={options} icon={null} />
//         </Menu.Item>
//         </Menu.Menu> */}
//         <Menu.Item>
//           <QuickSearch feathers={Feathers} tenantInput={false} history={this.props.history} />
//         </Menu.Item>

//         <Menu.Menu position='right'>
//           {sandboxLabel}
//           <Dropdown item trigger={trigger} icon={null} >
//             <Dropdown.Menu>
//               <Dropdown.Item onClick={this.props.logout}>Logout</Dropdown.Item>
//             </Dropdown.Menu>
//           </Dropdown>
//         </Menu.Menu>
//       </Menu>
//     );
//   }
// }

// const mapStateToProps = (state): ConnectedProps => ({
//   user: state.auth.user || {},
//   tenantDomainName: state.auth.domainName,
//   mode: state.mode,
// });

// const mapDispatchToProps = {
//   logout: feathersAuthentication.logout,
// };

// export default connect<ConnectedProps, any, TopNavProps>(mapStateToProps, mapDispatchToProps)(TopNavBase as any);

declare const ENV;

const hostName = window?.location?.hostname;

export default function TopNav(props: TopNavProps) {
  const dispatch = useDispatch();
  // const authUser = useSelector((state: any) => state.auth.user);

  const globalState = useSelector(
    ({
      globals,
      auth,
    }: {
      globals: {
        tenant: {
          publishedContactInfo: { domainName: string };
        };
      };
      auth: {
        user: {
          _id: string;
          business: string;
          key?: string;
          firstName: string;
          lastName: string;
        };
      };
    }) => ({
      tenant: globals.tenant.publishedContactInfo,
      authUser: auth?.user,
    })
  );

  const { authUser } = globalState;

  const [url, setUrl] = useState('');
  const [isModalOpen, setIsModalOpen] = useState({
    loginAs: false,
  });

  const [errorMessage, setErrorMessage] = useState({
    loginAs: '',
  });
  const [isLoading, setIsLoading] = useState({
    loginAs: false,
  });

  const isLargerThanTablet = window.innerWidth > 768;

  const isSandbox = window?.location?.hostname?.endsWith('.app.inkcloud9.dev');
  const isStaging = hostName?.endsWith('.stg.inkcloud9.dev');

  const handleLoginAs = async () => {
    setErrorMessage({ ...errorMessage, loginAs: '' });
    setIsLoading({ ...isLoading, loginAs: true });
    setIsModalOpen({ ...isModalOpen, loginAs: true });

    try {
      const res = await Feathers.service('/users/lia-token').create({
        id: authUser._id,
      });

      const subDomain = authUser?.key || window.location.hostname?.split('.')?.[0];

      const lia = '/lia';

      if (res) {
        if (ENV === 'development' || ENV === 'staging') {
          setUrl(`http://${subDomain}.localdev.inkcloud9.com:8080${lia}?jwt=${res.token}`);
        } else if (isSandbox || isStaging) {
          setUrl(`https:/${hostName}${lia}?jwt=${res.token}`);
        } else {
          setUrl(`https:/${hostName}${lia}?jwt=${res.token}`);
        }
      }
    } catch (e) {
      const errMsg = e?.code < 500 ? e?.message : 'Something went wrong. Please try again later.';
      setErrorMessage({
        ...errorMessage,
        loginAs: errMsg,
      });
    }
    setIsLoading({ ...isLoading, loginAs: false });
  };

  return (
    <Box
      position="sticky"
      zIndex={100}
      top={0}
      pl={props?.leftMargin}
      bg="white"
      borderBottomWidth={1}
      borderBottomColor="gray.200"
    >
      <Flex p={3}>
        <Flex>
          {!isLargerThanTablet && (
            <IconButton mr={3} aria-label="mobile menu" icon={<FiMenu />} onClick={props?.onOpen} />
          )}
          <QuickSearch feathers={Feathers} />
        </Flex>
        {(isSandbox || isStaging) && (
          <Box bgColor={'yellow.200'} ml={8} px={4} py={1}>
            You are viewing the {isSandbox ? 'sandbox' : 'staging'} environment
          </Box>
        )}

        <Spacer />
        <UserMessages userId={authUser._id} />
        <Menu size="md">
          <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
            {authUser?.firstName ?? ''} {authUser?.lastName ?? ''}
          </MenuButton>
          <MenuList>
            <MenuItem onClick={handleLoginAs}>Log in to Mobile Device</MenuItem>
            <MenuItem onClick={() => dispatch(feathersAuthentication.logout())}>Logout</MenuItem>
          </MenuList>
        </Menu>
      </Flex>

      {isModalOpen.loginAs && (
        <QRModal
          url={url}
          isLoading={isLoading.loginAs}
          errorMessage={errorMessage.loginAs}
          onClose={() => setIsModalOpen({ ...isModalOpen, loginAs: false })}
        />
      )}
    </Box>
  );
}
