import * as React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import {
  Button,
  Box,
  HStack,
  IconButton,
  Tooltip,
  Badge,
  Icon,
  Text,
  TabList,
  Tab,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { FormattedDate, FormattedTime } from 'react-intl';
import { WarningTwoIcon, CheckIcon } from '@chakra-ui/icons';
import { FaEdit, FaCheckCircle, FaTimesCircle } from 'react-icons/fa';

import {
  useList,
  basicQueryMap,
  PaginatorWrapper,
  EmptyWrapper,
  ListTable,
  generateOperatorOptions,
  Operator,
  ListPage,
} from '@inkcloud/components';

import { Can } from '../../../common/permissions';
import { statusToColor, TruncatedText } from './helpers';
import { Feathers } from '../../../bootstrap/feathers/feathers';

export async function getFilterDefinition() {
  const definition = [
    {
      label: 'Assigned To',
      value: 'createdFromRfq.assignedTo',
      operators: generateOperatorOptions([Operator.Is]),
      asyncValueOptions: async () =>
        Feathers.service('users')
          .find({
            query: {
              $sort: { firstName: 1 },
              $np: 1,
              type: { $ne: 'vendor' },
              vendor: { $exists: false },
            },
          })
          .then((results) =>
            results.map((r) => ({
              value: r._id,
              label: `${r?.firstName ?? ''} ${r?.lastName ?? ''}`,
            }))
          ),
      type: 'select',
    },
    {
      label: 'Requestor',
      value: 'createdFromRfq.requestor',
      operators: generateOperatorOptions([Operator.Is]),
      asyncValueOptions: async () =>
        Feathers.service('users')
          .find({
            query: {
              $sort: { firstName: 1 },
              $np: 1,
              type: { $ne: 'vendor' },
              vendor: { $exists: false },
            },
          })
          .then((results) =>
            results.map((r) => ({
              value: r._id,
              label: `${r?.firstName ?? ''} ${r?.lastName ?? ''}`,
            }))
          ),
      type: 'select',
    },
    {
      label: 'ID',
      value: 'humanId',
      operators: generateOperatorOptions([Operator.Is]),
    },
    {
      label: 'Date:Created',
      value: 'createdAt',
      operators: generateOperatorOptions([Operator.Between]),
      type: 'date',
    },
    {
      label: 'Project Name',
      value: 'createdFromRfq.projectName',
      operators: generateOperatorOptions([Operator.Like, Operator.Is]),
    },
    {
      label: 'Supplier Docs Sent to AP',
      value: 'supplierDocsSentToAP',
      operators: generateOperatorOptions([Operator.Is]),
      valueOptions: [
        { label: 'Yes', value: 1 },
        { label: 'No', value: 'false' },
      ],
      type: 'select',
    },
    {
      label: 'Supplier Docs Sent to Billing',
      value: 'supplierDocsSentToBilling',
      operators: generateOperatorOptions([Operator.Is]),
      valueOptions: [
        { label: 'Yes', value: 1 },
        { label: 'No', value: 'false' },
      ],
      type: 'select',
    },
  ];

  definition.sort((a: any, b: any) => a.label.localeCompare(b.label));

  return definition;
}

interface IInBillingProps {}

const serviceName = '/orders';
const pathPrefix = '/rfqs/in-billing';
const paginatorPrefix = '/rfqs/in-billing';

const filterQueryMap = (values: any, baseQuery: any = {}) => {
  let filterRows = basicQueryMap(values, {});

  const {
    requestor,
    assignedTo,
    customer,
    status,
    supplierDocsSentToAP,
    supplierDocsSentToBilling,
    projectName,
  } = filterRows;

  // console.log('filterRows', filterRows)

  let docsRequire;
  let docsRequireBilling;

  if (supplierDocsSentToAP?.$elemMatch?.value) {
    docsRequire = supplierDocsSentToAP?.$elemMatch?.value === 1 ? 'true' : 'false';
  }

  if (supplierDocsSentToBilling?.$elemMatch?.value) {
    docsRequireBilling = supplierDocsSentToBilling?.$elemMatch?.value === 1 ? 'true' : 'false';
  }

  filterRows = {
    ...filterRows,
    'createdFromRfq.projectName': projectName?.$LIKE ? { $LIKE: projectName?.$LIKE } : projectName,
    'createdFromRfq.status': status?.$elemMatch.value ?? baseQuery.status,
    'createdFromRfq.assignedTo': assignedTo?.$elemMatch.value,
    'createdFromRfq.requestor': requestor?.$elemMatch.value,
    supplierDocsSentToAP: docsRequire,
    supplierDocsSentToBilling: docsRequireBilling,
  };

  return filterRows;
};

const InBilling: React.FunctionComponent<IInBillingProps & RouteComponentProps<any>> = (props) => {
  const {
    match: { params },
    location,
    history,
  } = props;

  const useListValues = useList({
    feathers: [
      'find',
      serviceName,
      [
        {
          query: {
            isVoid: { $ne: true },
            createdFromRfq: { $exists: true },
            productionStatus: 'complete',
            $and: [
              { supplierDocsSentToAP: { $ne: true } },
              { supplierDocsSentToBilling: { $ne: true } },
            ],

            // 'estimate.createdOrder': { $exists: false },
            // status: { $ne: 'estimate-declined'},
            // name: 'adfasfsf',
            $populate: [
              'customer',
              // 'micrositeUser'
              {
                path: 'createdFromRfq',
                populate: [
                  'assignedTo',
                  'requestor',
                  // 'customer',
                  'micrositeUser',
                  // 'estimate.createdOrder',
                ],
              },
              // { path: 'tenant', select: 'displayBusinessName' },
              // { path: 'createdOrder', select: 'productionStatus' },
            ],
          },
        },
      ],
    ],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  const {
    state,
    reload,
    handleDelete,
    selectedItems,
    clearAll,
    setChecked,
    someSelected,
    toggleAll,
    filter,
    setFilter,
    sort,
    handleSort,
  } = useListValues;
  const { queryResult, isLoading: isStateLoading } = state as any;
  const resolvedQueryResult = queryResult?.data ? queryResult.data : queryResult;

  const tableDefinition = [
    {
      key: 'humanId',
      label: 'ID',
      sortable: true,
      cell: (v, r) => v,
    },
    {
      key: 'createdAt',
      label: 'Date',
      sortable: true,
      cell: (v) => (
        <Tooltip hasArrow aria-label="time" label={<FormattedTime value={v} />}>
          <Box as="span">
            <FormattedDate value={v} />
          </Box>
        </Tooltip>
      ),
    },
    {
      key: '',
      label: 'Customer',
      sortable: true,
      cell: (v, r) => {
        if (r?.micrositeUser) {
          return r?.micrositeUser?.companyName
            ? r?.micrositeUser?.companyName
            : `${r?.micrositeUser?.firstName ?? ''} ${r?.micrositeUser?.lastName ?? ''}`;
        }

        const name =
          r?.customer?.organizationName ??
          `${r?.customer?.primaryContact?.firstName ?? ''} ${
            r?.customer?.primaryContact?.lastName ?? ''
          }`;

        // return name
        return <TruncatedText text={name} />;
      },
    },
    {
      key: 'assignedTo',
      label: 'Assignee',
      sortable: true,
      cell: (v, r) => (
        <TruncatedText
          text={`${r?.createdFromRfq?.assignedTo?.firstName ?? ''} ${
            r?.createdFromRfq?.assignedTo?.lastName ?? ''
          }`}
        />
      ),
    },
    {
      key: 'requestor',
      label: 'Requestor',
      sortable: true,
      cell: (v, r) => (
        <TruncatedText
          text={`${r?.createdFromRfq?.requestor?.firstName ?? ''} ${
            r?.createdFromRfq?.requestor?.lastName ?? ''
          }`}
        />
      ),
    },
    {
      key: 'projectName',
      label: 'Project Name',
      sortable: true,
      cell: (v, r) => <TruncatedText text={r.createdFromRfq?.projectName} />,
    },
    // {
    //   key: 'jobNumber',
    //   label: 'Job #',
    //   sortable: true,
    //   cell: (v, r) => <TruncatedText limit={10} text={`${r.estimate?.externalJobNumber ?? ''}`} />,
    // },

    // {
    //   key: '',
    //   label: 'Order Status',
    //   sortable: true,
    //   cell: (v, r) =>
    //     r?.estimate?.createdOrder?.humanId && (
    //       <>
    //       <Link to={`/orders/${r?.estimate?.createdOrder?._id}`}>
    //         {/* {r?.estimate?.createdOrder?.humanId} */}
    //         <Badge variant="outline">{r?.estimate?.createdOrder?.productionStatus ?? ''}</Badge>
    //       </Link>
    //       { r?.estimate?.createdOrder?.supplierDocsRequireReview && (
    //         <Tooltip label='Supplier has uploaded documents. Click to Review'>
    //           <Link to={`${pathPrefix}/${r._id}`}>
    //             <Icon ml={2} boxSize={4} as={WarningTwoIcon} color="red.500" />
    //           </Link>
    //         </Tooltip>
    //       ) }

    //       </>
    //     ),
    // },
    {
      key: 'status',
      label: 'Docs Uploaded',
      sortable: false,
      cell: (v, r) => (
        <div>
          {r.attachments?.some((a) => ['Invoice', 'Shipping'].find((l) => l === a.label)) ? (
            <span>
              <FaCheckCircle color={'green'} />
            </span>
          ) : (
            <FaTimesCircle color="red" />
          )}
        </div>
      ),
    },
    {
      key: 'supplierDocsSentToAP',
      label: 'Sent to AP',
      sortable: true,
      cell: (v, r) => (
        <div>
          {r.supplierDocsSentToAP ? (
            <Tooltip
              label={
                <span>
                  <FormattedDate value={r.supplierDocsSentToAPAt} />
                  <FormattedTime value={r.supplierDocsSentToAPAt} />
                </span>
              }
            >
              <span>
                <FaCheckCircle color={'green'} />
              </span>
            </Tooltip>
          ) : (
            <FaTimesCircle color="red" />
          )}
        </div>
      ),
    },
    {
      key: 'supplierDocsSentToBilling',
      label: 'Sent to Billing',
      sortable: true,
      cell: (v, r) => (
        <div>
          {r.supplierDocsSentToBilling ? (
            <Tooltip
              label={
                <span>
                  <FormattedDate value={r.supplierDocsSentToBillingAt} />
                  <FormattedTime value={r.supplierDocsSentToBillingAt} />
                </span>
              }
            >
              <span>
                <FaCheckCircle color={'green'} />
              </span>
            </Tooltip>
          ) : (
            <FaTimesCircle color="red" />
          )}
        </div>
      ),
    },

    {
      key: '',
      label: '',
      sortable: false,
      cell: (v, r) => (
        <Can do={'read'} on={'dashboard.rfqs'}>
          <Tooltip label="View RFQ">
            <Link to={`/rfqs/${r.createdFromRfq?._id}`}>
              <IconButton
                variant={'outline'}
                colorScheme="gray"
                aria-label="Edit"
                icon={<FaEdit />}
              />
            </Link>
          </Tooltip>
        </Can>
      ),
      textAlign: 'right',
    },
  ];

  return (
    <Box bgColor={'white'}>
      <Box>Order/RFQs shown on this tab are complete and shipped</Box>
      {/* <EmptyWrapper queryResult={resolvedQueryResult} isStateLoading={isStateLoading}>
        <Segment >
          <PaginatorWrapper.Top filter={filter} reload={reload} data={(queryResult as any)} pathPrefix={`${paginatorPrefix}`} />
          <ListTable
            data={resolvedQueryResult}
            definition={tableDefinition}
            selectedItems={selectedItems as any[]}
            setChecked={setChecked}
            sort={sort}
            sortClickFunction={handleSort}
            toggleAll={toggleAll as any}
            withCheckboxes={true}
          />
          <PaginatorWrapper.Bottom reload={reload} data={(queryResult as any)} pathPrefix={`${paginatorPrefix}`}>
             {someSelected && <Button primary type="button" content={selectedItemsBtnLabel || "Delete"} onClick={handleSelectedItems ? () => handleSelectedItems(selectedItems) : handleDelete} />}
          </PaginatorWrapper.Bottom>
        </Segment> *
      </EmptyWrapper> */}

      <ListPage
        header=""
        subheader=""
        useListValues={useListValues}
        history={history}
        pathPrefix={pathPrefix}
        tableDefintion={tableDefinition}
        withCheckboxes={true}
        getFilterDefinition={getFilterDefinition}
      />
    </Box>
  );
};

export default InBilling;
