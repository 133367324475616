import * as React from 'react';
import {
  Accordion,
  Button,
  Checkbox,
  Header,
  Icon,
  Grid,
  Label,
  Segment,
  Table,
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { IndexWrapper, IndexWrappedProps } from './../../../bootstrap/IndexWrapper';
import { ListViewPagination, ListFilterWarning } from './../../../common';
import FilterForm from './../assign/FilterForm';

export const filterMapper = (filterIn) => {
  const filterOut = Object.assign({}, filterIn);

  let filterAttributes = [];
  if (filterOut.source && filterOut.source.length > 0) {
    filterAttributes = filterAttributes.concat(filterOut.source);
    delete filterOut.source;
  }

  if (filterOut.destination && filterOut.destination.length > 0) {
    filterAttributes = filterAttributes.concat(filterOut.destination);
    delete filterOut.destination;
  }

  if (filterAttributes.length > 0) {
    filterOut['attributes.value'] = { $in: filterAttributes };
  }

  if (filterOut.status) {
    filterOut['productionStatus.jobStatus'] = { $in: filterOut.status };
    delete filterOut.status;
  }

  if (filterOut.category) {
    filterOut.category = { $in: filterOut.category };
  }

  return filterOut;
};

export function Container(props: IndexWrappedProps) {
  if (!props.queryResult) {
    return null;
  }
  const { data, skip, limit, total } = props.queryResult;

  const handleFile = (path: string) => {
    event.preventDefault();
    const baseUrl = `https://storage.googleapis.com/`;

    const file = `${baseUrl}${path}`;
    window.open(file, '_blank');
  };

  return (
    <div>
      <Header as="h1">Orders</Header>
      <Header attached={'top'} as="h5">
        Filter
      </Header>
      <Segment attached secondary>
        <FilterForm
          onSubmit={props.handleFilterSubmit}
          handleClearFilter={props.handleClearFilter}
          isFiltered={props.isFiltered}
        />
      </Segment>
      <ListFilterWarning isFiltered={props.isFiltered} />
      <Segment attached loading={props.isLoading}>
        <ListViewPagination.Top
          label={'New Production Batch'}
          link={'/production/batches/add'}
          hideAddButton={true}
          {...props}
        />
        <Table size={'small'}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                <Checkbox onChange={props.handleSelectAllClick} />
              </Table.HeaderCell>
              <Table.HeaderCell>ID</Table.HeaderCell>
              <Table.HeaderCell>Customer</Table.HeaderCell>
              <Table.HeaderCell>Category</Table.HeaderCell>
              <Table.HeaderCell>Description</Table.HeaderCell>
              <Table.HeaderCell>Files</Table.HeaderCell>
              <Table.HeaderCell>Translator</Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data.map((i) => {
              const sourceLang = i.metadata.find((m) => m.key === 'tr_source_name');
              const destinationLang = i.metadata.find((m) => m.key === 'tr_dest_name');
              const langMap = (
                <div>
                  <Label size={'mini'} key={sourceLang && sourceLang._id}>
                    {sourceLang && sourceLang.value}
                  </Label>
                  <Icon name={'long arrow alternate right'} />
                  <Label size={'mini'} key={destinationLang && destinationLang._id}>
                    {destinationLang && destinationLang.value}
                  </Label>
                </div>
              );

              return (
                <Table.Row key={i._id}>
                  <Table.Cell>
                    <Checkbox
                      value={i._id}
                      checked={props.selected[i._id] || false}
                      onChange={props.handleSemanticCheckboxClick}
                    />
                  </Table.Cell>
                  <Table.Cell>{`${i.humanId}`}</Table.Cell>
                  <Table.Cell>
                    <Link to={`/customers/${i.customer._id}`}>
                      {i.customer.organizationName
                        ? i.customer.organizationName
                        : `${i.customer.primaryContact.firstName} ${i.customer.primaryContact.lastName}`}
                    </Link>
                  </Table.Cell>

                  <Table.Cell>{i.category ? i.category.name : 'Custom'}</Table.Cell>
                  <Table.Cell width={'6'}>
                    {i.metadata && i.metadata.length > 0 && langMap}
                  </Table.Cell>
                  <Table.Cell>
                    {i.miscUploads &&
                      i.miscUploads.length > 0 &&
                      i.miscUploads.map((m: any, index: number) => (
                        <div key={index}>
                          <a
                            href=""
                            style={{ display: 'inline-block', marginRight: '.5rem' }}
                            onClick={() => handleFile(`${m.cloudBucket}/${m.cloudFilename}`)}
                          >
                            <Icon name="linkify" /> {m.originalFilename}
                          </a>
                        </div>
                      ))}
                  </Table.Cell>
                  <Table.Cell>
                    {i.translator && i.translator.assigned && (
                      <Link to={`/admin/translators/${i.translator.assigned._id}`}>
                        {`${i.translator.assigned.firstName} ${i.translator.assigned.lastName}`}
                      </Link>
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {i.productionStatus && (
                      <Label size={'mini'} content={i.productionStatus.description} />
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    <Link to={'/translation/orders/' + i.order}>
                      <Button size={'mini'}>Details</Button>
                    </Link>
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
        <ListViewPagination.Bottom hideDeleteButton={true} label={'Delete'} {...props} />
      </Segment>
    </div>
  );
}

export default IndexWrapper(Container, 'orderItems', {
  filterMapper,
  query: {
    $populate: [
      'customer',
      'category',
      'attributes.value',
      'translator.assigned',
      'productionStatus.jobStatus',
      'miscUploads',
    ],
    $sort: { createdAt: -1 },
  },
});
