import * as React from 'react';
import { Switch, Route } from 'react-router-dom';

import List from './List';
import { Detail } from './Detail';

const routes = (props) => (
  <Switch>
    <Route exact path="/settings/production/production-channels" component={List} />
    <Route exact path="/settings/production/production-channels/add" component={Detail} />
    <Route exact path="/settings/production/production-channels/:id" component={Detail} />
  </Switch>
);

export default routes;
