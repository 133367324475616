import * as React from 'react';
import { Switch, Route } from 'react-router-dom';

import { List } from './List';
import { Detail } from './Detail';

const routes = (props) => (
  <>
    <Switch>
      <Route exact path="/settings/promo-codes/campaigns" component={List} />
      <Route exact path="/settings/promo-codes/campaigns/add" component={Detail} />
      <Route exact path="/settings/promo-codes/campaigns/:id" component={Detail} />
    </Switch>
  </>
);

export default routes;
