// import * as React from 'react'
// import { Formik, Form as FormikForm, ErrorMessage } from 'formik'
// import { Button, Form, Message, Dropdown } from 'semantic-ui-react'
// import countries from 'world-countries'

// import { useSelector } from 'react-redux'
// import * as Yup from 'yup'
// import { SemanticField } from '../../../common/Form'
// import { countryStateProvinces } from '../../../common'

// interface ContactFormProps {
//   initialValues: any
//   errorMessage: string
//   onSubmit: (v: any) => void
// }

// export const ContactForm: React.FunctionComponent<ContactFormProps> = (props) => {
//   const { initialValues, errorMessage, onSubmit } = props
//   const tenant = useSelector(
//      ({ globals }: { globals: { tenant: {internationalization: { countryCode: string }} } }) => globals.tenant.internationalization
//   )

//   const ContactInfoSchema = Yup.object().shape({
//     email: Yup.string().email(),
//     businessName: Yup.string(),
//     domainName: Yup.string(),
//     phone: Yup.string().required(),
//     fax: Yup.string(),
//     address: Yup.object().shape({
//       address1: Yup.string(),
//       address2: Yup.string(),
//       city: Yup.string(),
//       stateProvince: Yup.string(),
//       postalCode: Yup.number(),
//     }),
//   })

//   return (
//     <>
//       <Formik
//         initialValues={{
//           ...initialValues,
//           address: {
//             ...initialValues?.address,
//             countryCode: initialValues?.address?.countryCode ?? tenant.countryCode,
//           },
//         }}
//         validationSchema={ContactInfoSchema}
//         enableReinitialize={true}
//         onSubmit={(values, { setSubmitting }) => {
//           onSubmit(values)
//         }}
//       >
//         {(innerProps) => {
//           const {
//             values,
//             touched,
//             errors,
//             dirty,
//             isSubmitting,
//             handleChange,
//             handleBlur,
//             handleSubmit,
//             handleReset,
//             setFieldValue,
//             setFieldTouched,
//           } = innerProps

//           return (
//             <FormikForm
//               className={`ui form ${isSubmitting && !errorMessage && 'loading'}`}
//               style={{ height: '100%' }}
//             >
//               <Form.Field>
//                 <SemanticField
//                   fluid
//                   name="businessName"
//                   component={Form.Input}
//                   label="Business Name"
//                 />
//               </Form.Field>
//               <Form.Field>
//                 <SemanticField fluid name="email" component={Form.Input} label="Email" />
//                 <ErrorMessage name="email" />
//               </Form.Field>
//               <Form.Field>
//                 <SemanticField fluid name="phone" component={Form.Input} label="Phone Number" />
//                 <ErrorMessage name="phone" />
//               </Form.Field>
//               <Form.Group widths="equal">
//                 <Form.Field>
//                   <SemanticField
//                     name="address.countryCode"
//                     label="Country"
//                     selection
//                     search
//                     component={Form.Dropdown}
//                     options={
//                       countries?.map((c: any) => ({ text: c.name.official, value: c.cca2 })) ?? []
//                     }
//                     fluid
//                   />
//                 </Form.Field>
//               </Form.Group>
//               <Form.Group widths="equal">
//                 <Form.Field>
//                   <SemanticField
//                     fluid
//                     name="address.address1"
//                     component={Form.Input}
//                     label="Address 1"
//                   />
//                 </Form.Field>
//                 <Form.Field>
//                   <SemanticField
//                     fluid
//                     name="address.address2"
//                     component={Form.Input}
//                     label="Address 2"
//                   />
//                 </Form.Field>
//               </Form.Group>
//               <Form.Group widths="equal">
//                 <Form.Field>
//                   {values?.address?.countryCode === 'US' ||
//                   values?.address?.countryCode === 'CA' ||
//                   values?.address?.countryCode === 'AU' ? (
//                     <>
//                       <label>
//                         {values?.address?.countryCode === 'AU'
//                           ? 'State/Territory'
//                           : 'State/Province'}
//                       </label>
//                       <Dropdown
//                         name={'address.stateProvince'}
//                         options={countryStateProvinces(values?.address?.countryCode)}
//                         fluid
//                         selection
//                         search
//                         value={values?.address?.stateProvince ?? ''}
//                         onChange={(e, data) => setFieldValue(data.name, data.value)}
//                       />
//                     </>
//                   ) : (
//                     <SemanticField
//                       name={'address.stateProvince'}
//                       component={Form.Input}
//                       fluid
//                       label="State/Province"
//                       placeholder="State/Province..."
//                     />
//                   )}
//                 </Form.Field>
//                 <Form.Field>
//                   <SemanticField fluid name="address.city" component={Form.Input} label="City" />
//                 </Form.Field>
//                 <Form.Field>
//                   <SemanticField
//                     fluid
//                     name="address.postalCode"
//                     component={Form.Input}
//                     label="Zip"
//                   />
//                 </Form.Field>
//               </Form.Group>
//               <Form.Field>
//                 <Button primary type="submit" disabled={isSubmitting && !errorMessage}>
//                   Save
//                 </Button>
//               </Form.Field>
//             </FormikForm>
//           )
//         }}
//       </Formik>

//       {errorMessage && <Message error content={errorMessage} />}
//     </>
//   )
// }

import React, { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';

import { Select, AsyncSelect, MultiValue } from 'chakra-react-select';

import type { ModelTypes } from '@inkcloud/icapi-types';

import {
  Box,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Stack,
  Button,
  Alert,
} from '@chakra-ui/react';

import countries from 'world-countries';
import { countryStateProvinces } from '../../../common';

type contactType = ModelTypes.TenantSettings['publishedContactInfo'];
interface IContactFormProps {
  initialValues: contactType;
  onSubmit: (v: contactType) => void;
  errorMessage: string;
  isSubmitting?: boolean;
}

const countryInclude = ['US', 'CA', 'AU'];

const countryOptions = countries?.map((c) => ({ label: c.name?.official, value: c.cca2 }));

const stateProvinceOptions = (watchCountry: string) =>
  countryStateProvinces(watchCountry)?.map((c: { value: string; text: string }) => ({
    value: c.value,
    label: c.text,
  })) || [];

export default function ContactForm(props: IContactFormProps) {
  const { initialValues, errorMessage, isSubmitting, onSubmit } = props;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    control,
    watch,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<NonNullable<IContactFormProps['initialValues']>>({
    defaultValues: initialValues,
  });

  const handSave: SubmitHandler<NonNullable<IContactFormProps['initialValues']>> = (data) =>
    onSubmit(data);

  const watchCountry = watch('address.countryCode') as string;

  return (
    <>
      <Stack spacing={7} mb={3} direction="row">
        <FormControl>
          <FormLabel>Business Name</FormLabel>
          <Input type="text" {...register('businessName')} />
        </FormControl>
      </Stack>
      <Stack spacing={7} mb={3} direction="row">
        <FormControl>
          <FormLabel>Email</FormLabel>
          <Input type="text" {...register('email')} />
        </FormControl>
        <FormControl>
          <FormLabel>Phone</FormLabel>
          <Input type="text" {...register('phone')} />
        </FormControl>
      </Stack>
      <Stack spacing={7} mb={3} direction="row">
        <FormControl>
          <FormLabel>Country</FormLabel>
          <Select
            {...register('address.countryCode')}
            value={countryOptions?.find((c) => c.value === watchCountry)}
            onChange={(e) => {
              setValue('address.countryCode', e?.value);
              setValue('address.stateProvince', '');
            }}
            placeholder=""
            options={countryOptions}
          />
        </FormControl>
      </Stack>
      <Stack spacing={7} mb={3} direction="row">
        <FormControl>
          <FormLabel>Address 1</FormLabel>
          <Input type="text" {...register('address.address1')} />
        </FormControl>
        <FormControl>
          <FormLabel>Address 2</FormLabel>
          <Input type="text" {...register('address.address2')} />
        </FormControl>
      </Stack>
      <Stack spacing={7} mb={3} direction="row">
        <FormControl>
          <FormLabel>City</FormLabel>
          <Input type="text" {...register('address.city')} />
        </FormControl>
        <FormControl>
          <FormLabel>State/Province</FormLabel>
          {countryInclude.includes(watchCountry) ? (
            <Select
              {...register('address.stateProvince')}
              defaultValue={stateProvinceOptions(watchCountry)?.find(
                (c) => c.value === initialValues?.address?.stateProvince
              )}
              onChange={(e) => setValue('address.stateProvince', e?.value)}
              placeholder=""
              options={stateProvinceOptions(watchCountry)}
            />
          ) : (
            <Input type="text" {...register('address.stateProvince')} />
          )}
        </FormControl>
        <FormControl>
          <FormLabel>Postal Code</FormLabel>
          <Input type="text" {...register('address.postalCode')} />
        </FormControl>
      </Stack>

      {errorMessage && (
        <Alert status="error" mb={3}>
          {errorMessage}
        </Alert>
      )}

      <Button
        colorScheme="blue"
        isDisabled={isSubmitting}
        isLoading={isSubmitting}
        onClick={handleSubmit(handSave)}
      >
        Save
      </Button>
    </>
  );
}
