import * as React from 'react';
import { Switch, Route } from 'react-router-dom';

import { List } from './List';
import Detail from './Detail';
import { OrderLimitIncreaseModal } from './OrderLimitIncrease';

const routes = (props) => (
  <Switch>
    <Route exact path="/settings/microsites/:micrositeId/customers" component={List} />
    <Route
      exact
      path="/settings/microsites/:micrositeId/customers/order-limits"
      component={OrderLimitIncreaseModal}
    />
    <Route exact path="/settings/microsites/:micrositeId/customers/add" component={Detail} />
    <Route exact path="/settings/microsites/:micrositeId/customers/:id" component={Detail} />
  </Switch>
);

export default routes;
