import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useFeathers } from '@inkcloud/components';
import { feathers } from '../../../bootstrap';
import { HeroStackForm } from './HeroStackForm';

const serviceName = 'store-front/content/hero-stacks';
const pathPrefix = '/store-front-content/hero-stacks';
const service = feathers.service(serviceName);
const commonParams = { query: { include: ['questions'] } };
export const Detail: React.FunctionComponent<RouteComponentProps<{ id?: string }>> = (props) => {
  const {
    match: {
      params: { id },
    },
    history,
  } = props;
  const isEdit = !!id;
  const [state, reload] = useFeathers('get', serviceName, [id, commonParams], true);
  const handleSubmit = async (values) => {
    isEdit
      ? await service.patch(id, values, commonParams)
      : await service.create(values, commonParams);
    history.push(pathPrefix);
  };

  return (
    <div>
      <h1>Hero Stacks</h1>
      {(!isEdit || state.data) && (
        <HeroStackForm initialValues={state.data} onSubmit={handleSubmit} />
      )}
    </div>
  );
};
