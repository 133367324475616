import * as React from 'react';
import { Formik, Form as FormikForm, ErrorMessage } from 'formik';
import { Button, Form, Message, Dropdown } from 'semantic-ui-react';

import { SemanticField } from '../../../common/Form';

interface FulfillmentFormProps {
  initialValues: any;
  errorMessage: string;
  vendorOptions: Array<{ text: string; value: string }>;

  onSubmit: (v: any) => void;
}

const { useState } = React;

export const FulfillmentForm: React.FunctionComponent<FulfillmentFormProps> = (props) => {
  const { initialValues, errorMessage, onSubmit, vendorOptions } = props;

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values);
        }}
      >
        {(innerProps) => {
          const {
            values,
            touched,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            setFieldValue,
            setFieldTouched,
          } = innerProps;

          return (
            <FormikForm
              className={`ui form ${isSubmitting && !errorMessage && 'loading'}`}
              style={{ height: '100%' }}
            >
              <Form.Field>
                <SemanticField fluid name="name" component={Form.Input} label="Name" />
              </Form.Field>

              <Form.Field>
                <SemanticField
                  fluid
                  selection
                  name="vendor"
                  label="Vendor"
                  clearable
                  component={Form.Dropdown}
                  options={vendorOptions}
                />
              </Form.Field>

              <Button primary type="submit" disabled={isSubmitting && !errorMessage}>
                Save
              </Button>
            </FormikForm>
          );
        }}
      </Formik>

      {errorMessage && <Message error content={errorMessage} />}
    </>
  );
};
