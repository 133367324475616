import * as React from 'react';
import { Link } from 'react-router-dom';
import { Button, Grid } from 'semantic-ui-react';
import { Paginator } from './Paginator';
import { IndexWrappedProps } from './../bootstrap/IndexWrapper';

type IndexPicked = Pick<
  IndexWrappedProps,
  'queryResult' | 'onPageChange' | 'selected' | 'handleRemove' | 'handleRefresh'
>;

type ListViewTopProps = IndexPicked & {
  label: string;
  link: string;
  leftButton?: any;
  hideAddButton?: Boolean;
};

type ListViewBottomProps = IndexPicked & {
  label: string;
  isLoading?: boolean;
  hideDeleteButton?: Boolean;
};

export const Top = (props: ListViewTopProps) => {
  if (!props.queryResult) {
    return null;
  }
  const { data, skip, limit, total } = props.queryResult;
  const { label, link, hideAddButton = false, leftButton } = props;
  return (
    <Grid doubling stackable>
      <Grid.Column width={'4'}>
        {leftButton && leftButton}
        {!leftButton && !hideAddButton && (
          <Link to={link}>
            <Button primary size={'small'}>
              {label}
            </Button>
          </Link>
        )}
      </Grid.Column>
      <Grid.Column width={'12'} textAlign={'right'}>
        <Button basic size={'tiny'} icon={'refresh'} onClick={props.handleRefresh} />
        <Paginator
          prelink="/"
          skip={skip}
          rowsPerPage={limit}
          totalResult={total}
          onClick={props.onPageChange}
        />
      </Grid.Column>
    </Grid>
  );
};

export const Bottom = (props: ListViewBottomProps) => {
  if (!props.queryResult) {
    return null;
  }
  const { data, skip, limit, total } = props.queryResult;
  const { label, isLoading, hideDeleteButton = false } = props;

  const deleteEnabled = Object.keys(props.selected).reduce((prev, cur) => {
    return prev + (props.selected[cur] ? 1 : 0);
  }, 0);

  return (
    <Grid>
      <Grid.Column width={'4'}>
        {!hideDeleteButton && (
          <Button
            size={'small'}
            loading={props.isLoading}
            disabled={!deleteEnabled}
            onClick={(e, val) => props.handleRemove(e, val)}
          >
            {label}
          </Button>
        )}
      </Grid.Column>
      <Grid.Column width={'12'} textAlign={'right'}>
        <Paginator
          prelink="/"
          skip={skip}
          rowsPerPage={limit}
          totalResult={total}
          onClick={props.onPageChange}
        />
      </Grid.Column>
    </Grid>
  );
};
