import * as React from 'react';
import { Button, Form, Input, Dropdown, Popup } from 'semantic-ui-react';
import { Formik, Form as FormikForm, Field, FieldProps, FieldArray, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { SemanticField } from './../../../../common/Form';

import AceEditor from 'react-ace';
import 'brace/mode/html';
import 'brace/theme/monokai';

interface ContentFormProps {
  initialValues: any;
  onSubmit: Function;
  isSubmitting?: boolean;
  errorMessage: string;
}

export const ContentForm: React.FC<ContentFormProps> = (outerProps) => {
  return (
    <Formik
      initialValues={outerProps.initialValues}
      onSubmit={(values, { setSubmitting }) => {
        outerProps.onSubmit(values);
      }}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
          setFieldValue,
          setFieldTouched,
        } = props;

        return (
          <FormikForm
            className={`ui form ${isSubmitting && !outerProps.errorMessage && 'loading'}`}
          >
            <FieldArray
              name="editableRegions"
              render={(arrayHelpers) => (
                <div style={{ width: '100%', padding: '0 .5em' }}>
                  {values?.editableRegions?.map((m, index) => (
                    <div key={index}>
                      <Form.Group>
                        <Form.Field>
                          <label>{values?.editableRegions?.[index]?.key ?? ''}</label>
                        </Form.Field>
                      </Form.Group>
                      <Form.Group>
                        <Form.Field width={16}>
                          <Field
                            name={`editableRegions.${index}.content`}
                            render={({ field }: FieldProps<any>) => (
                              <>
                                <AceEditor
                                  mode="html"
                                  theme="monokai"
                                  editorProps={{ $blockScrolling: true }}
                                  onChange={(data) => setFieldValue(field.name, data)}
                                  name={field.name}
                                  value={field.value}
                                  width={'100%'}
                                  showGutter={false}
                                />
                              </>
                            )}
                          />
                        </Form.Field>
                      </Form.Group>
                    </div>
                  ))}
                </div>
              )}
            />

            <Button primary type="submit" disabled={isSubmitting && !outerProps.errorMessage}>
              Save
            </Button>
          </FormikForm>
        );
      }}
    </Formik>
  );
};
