import * as React from 'react';
import { PageSize, Orientation } from '@react-pdf/types';
import { BillToValue, ShipToValue } from '../shared';

export const SalesOrderContext = React.createContext<SalesOrderContextInterface>(null);

interface ISalesOrderProviderProps {
  initialState: any;
  children?: React.ReactNode;
}

interface SalesOrderContextInterface {
  dataCtx: SalesOrderDataContextInterface;
  styles: any;
  config: {
    size: PageSize;
    orientation: Orientation;
  };
  tenantInfo: any;
}

interface SalesOrderDataContextInterface {
  salesOrder: {
    po?: string;
    billTo: BillToValue;
    shipTo: ShipToValue;
    items: any[];
  };
}

const config: SalesOrderContextInterface['config'] = {
  size: 'A4',
  orientation: 'portrait',
};

// const dataCtx: SalesOrderDataContextInterface = {
//   SalesOrderNumber: 'UR40623',
//   po: 'OL152868',
//   items: [{
//     image: 'https://storage.googleapis.com/icstorage/test/UR_BL13.jpeg',
//     sku: 'UR1002_84x105_FB_Pa',
//     desc: "ad sunt culpa occaecat qui",
//     name: 'In Wonderland - Frame Black - With Mat - 84x105 - Portrait',
//     quantity: 1,
//     rate: 405.89,
//   },
//   {
//     sku: 'UR1002_84x105_FB_P',
//     desc: "cillum quis sunt qui aute",
//     name: 'In Wonderland - Frame Black - With Mat - 84x105 - Portrait',
//     quantity: 1,
//     rate: 373.11,
//   },
//   {
//     sku: 'UR1002_84x105_FB_P',
//     desc: "ea commodo labore culpa irure",
//     name: 'In Wonderland - Frame Black - With Mat - 84x105 - Portrait',
//     quantity: 1,
//     rate: 458.61,
//   },
//   {
//     sku: 'UR1002_84x105_FB_P',
//     desc: "nisi consequat et adipisicing dolor",
//     name: 'In Wonderland - Frame Black - With Mat - 84x105 - Portrait',
//     quantity: 1,
//     rate: 725.24,
//   },
//   {
//     sku: 'UR1002_84x105_FB_P',
//     desc: "proident cillum anim elit esse",
//     name: 'In Wonderland - Frame Black - With Mat - 84x105 - Portrait',
//     quantity: 1,
//     rate: 141.02,
//   }
// ],
//   billTo: {
//     company: 'ACME225555',
//     name: 'John',
//     address1: '1234 Main St',
//     address2: 'Suite 123',
//     city: 'Los Angeles',
//     stateProvince: 'CA',
//     postalCode: '90021',
//     countryCode: 'US'
//   },
//   shipTo: {
//     name: 'ACME33333',
//     careOf: 'John',
//     address1: '1234 Main St',
//     address2: 'Suite 123',
//     city: 'Los Angeles',
//     stateProvince: 'CA',
//     postalCode: '90021',
//     countryCode: 'US'
//   }
// }

export const SalesOrderProvider: React.FunctionComponent<ISalesOrderProviderProps> = (props) => {
  const { children, initialState } = props;

  // console.log('initial state in this one!!!', initialState);
  const defaultCtx = initialState;
  return <SalesOrderContext.Provider value={defaultCtx}>{children}</SalesOrderContext.Provider>;
};
