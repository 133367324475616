import * as React from 'react';
import { Header, Grid, Label, Divider, List } from 'semantic-ui-react';
interface BillShipProps {
  values: any;
}

export const BillShip: React.FunctionComponent<BillShipProps> = (props) => {
  const {
    values: { customer, microsite, invoice, shipment },
  } = props;

  // MS doesn't have billing address so for now we use the customer field to fill in

  let billToTemplate;

  if (microsite) {
    billToTemplate = (
      <List>
        {customer.organizationName ? (
          <List.Item>{customer.organizationName}</List.Item>
        ) : (
          <List.Item>
            {customer.primaryContact.firstName} {customer.primaryContact.lastName}
          </List.Item>
        )}
        {customer.address1 && <List.Item>{customer.address1}</List.Item>}
        {customer.address2 && <List.Item>{customer.address2}</List.Item>}
        <List.Item>
          {customer.city} {customer.stateProvince}, {customer.postalCode}
        </List.Item>
        <List.Item>
          <strong>Customer</strong> #{customer.humanId}
        </List.Item>
      </List>
    );
  } else {
    billToTemplate = (
      <List>
        {invoice?.billingAddress?.companyName ? (
          <List.Item>{invoice?.billingAddress?.companyName}</List.Item>
        ) : (
          <List.Item>{invoice?.billingAddress?.name}</List.Item>
        )}
        {invoice?.billingAddress?.address1 && (
          <List.Item>{invoice?.billingAddress?.address1}</List.Item>
        )}
        {invoice?.billingAddress?.address2 && (
          <List.Item>{invoice?.billingAddress?.address2}</List.Item>
        )}
        <List.Item>
          {invoice?.billingAddress?.city} {invoice?.billingAddress?.stateProvince},{' '}
          {invoice?.billingAddress?.postalCode}
        </List.Item>
      </List>
    );
  }

  return (
    <>
      <Divider hidden />
      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column>
            <Header as="h5">Bill To:</Header>
            {billToTemplate}
          </Grid.Column>
          <Grid.Column>
            {shipment && shipment.type && shipment.type !== 'pickup' && (
              <>
                <Header as="h5">Ship To:</Header>
                <List>
                  {shipment.shipToName && <List.Item>{shipment.shipToName}</List.Item>}
                  {shipment.shipToCareOf && <List.Item>{shipment.shipToCareOf}</List.Item>}
                  {shipment.shipToAddress1 && <List.Item>{shipment.shipToAddress1}</List.Item>}
                  {shipment.shipToAddress2 && <List.Item>{shipment.shipToAddress2}</List.Item>}
                  <List.Item>
                    {shipment.shipToCity} {shipment.shipToStateProvince},{' '}
                    {shipment.shipToPostalCode}
                  </List.Item>
                  <List.Item>
                    {shipment.serviceDescription && <strong>Service: </strong>}{' '}
                    {shipment.serviceDescription}
                  </List.Item>
                </List>
              </>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};
