import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Modal,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  Flex,
  Center,
  Spinner,
} from '@chakra-ui/react';
import type { ModelTypes } from '@inkcloud/icapi-types';

import JobSchedulerWorkflowTemplateForm from './JobSchedulerWorkflowTemplateForm';

import List from './List';

import { Feathers } from '../../../bootstrap/feathers/feathers';

interface IJobSchedulingWorkflowTemplatesModalProps {
  id?: string;
  isOpen: boolean;
  onClose: () => void;
}

const pathPrefix = '/job-scheduler/workflow-templates';

const initialValues = {
  name: '',
  type: '',
  description: '',
  steps: [{ column: { label: '', value: '' }, resourceType: undefined, resource: '' }],
};

const JobSchedulingWorkflowTemplatesModal: React.FunctionComponent<
  IJobSchedulingWorkflowTemplatesModalProps
> = (props) => {
  const { isOpen, onClose } = props;

  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const isEdit = !!id;
  const isAdd = history.location.pathname.includes('add');

  const [resources, setResources] = useState<ModelTypes.ProductionResources[]>([]);
  const [columns, setColumns] = useState<any[]>([]);
  // const [columns, setColumns] = useState<ModelTypes.JobSchedulerColumns[]>([]);

  const [template, setTemplate] = useState(initialValues);

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const loadResources = async () => {
      try {
        const res = await Feathers.service('production/resources').find({
          query: {
            $np: 1,
          },
        });
        setResources(res);
        // console.log('resources res', res);
      } catch (e) {
        console.error(e);
      }
    };

    const loadColumns = async () => {
      try {
        const res = await Feathers.service('job-scheduler/columns').find({
          query: {
            isGlobal: {
              $ne: true,
            },
            $sort: { sortOrder: 1 },
          },
        });

        setColumns(res?.data);
      } catch (e) {
        console.error(e);
      }
    };

    loadResources();
    loadColumns();
  }, []);

  useEffect(() => {
    const loadTemplate = async () => {
      setIsLoading(true);
      try {
        const res = await Feathers.service('job-scheduler/workflow-templates').get(id, {
          query: {
            $populate: ['type', 'steps.resource', 'steps.column'],
          },
        });

        setTemplate({
          ...res,
          type: { label: res?.type?.name, value: res?.type?._id },
          steps: res?.steps?.map((s) => ({
            column: { label: s?.column?.name, value: s?.column?._id },
            resourceType: s?.resourceType,
            resource: s?.resource?._id
              ? { label: s?.resource?.name, value: s?.resource?._id }
              : null,
          })),
        });
      } catch (e) {
        console.log('e');
      }

      setIsLoading(false);
    };

    if (id) {
      loadTemplate();
    }
  }, [id]);

  const handleSubmit = async (v) => {
    setIsSubmitting(true);
    setErrorMessage('');

    const payload = v;

    payload.type = v?.type?.value;

    payload.steps = v?.steps?.map((s, idx) => ({
      column: s?.column?.value,
      resource: s?.resourceType ? s?.resource?.value || undefined : undefined,
      resourceType: s?.resourceType || undefined,
      $unset: {
        'steps.$[idx].resource': !s?.resourceType || !s?.resource?.value ? '1' : undefined,
      },
    }));

    try {
      if (isEdit) {
        const res = await Feathers.service('job-scheduler/workflow-templates').patch(id, payload);
      } else {
        const res = await Feathers.service('job-scheduler/workflow-templates').create(payload);
      }

      history.push(pathPrefix);
    } catch (e) {
      const errMsg = e?.code < 500 ? e?.message : 'Something went wrong. Please try again later.';
      setErrorMessage(errMsg);
    }
    setIsSubmitting(false);
  };

  // console.log('history', history, 'id', id);

  return (
    <Drawer isOpen={isOpen} onClose={onClose} size={'xl'}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader>Job Scheduler Workflow Templates</DrawerHeader>
        <DrawerCloseButton />
        <DrawerBody>
          {isAdd || isEdit ? (
            <>
              <Flex justifyContent="end">
                <Button
                  onClick={() => {
                    setTemplate(initialValues);
                    history.goBack();
                  }}
                >
                  Back
                </Button>
              </Flex>
              {isLoading ? (
                <Center>
                  <Spinner />
                </Center>
              ) : (
                <JobSchedulerWorkflowTemplateForm
                  initialValues={template}
                  columns={columns}
                  resources={resources}
                  errorMessage={errorMessage}
                  isSubmitting={isSubmitting}
                  onSubmit={(v) => {
                    handleSubmit(v);
                    // console.log('v', v);
                  }}
                />
              )}
            </>
          ) : (
            <List />
          )}
        </DrawerBody>

        <DrawerFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Close
          </Button>
          {!isAdd && (
            <Button colorScheme="blue" onClick={() => history.push(`${pathPrefix}/add`)}>
              Add Workflow Template
            </Button>
          )}
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default JobSchedulingWorkflowTemplatesModal;
