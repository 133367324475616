import * as React from 'react';
import { Button, Image, Segment, Table, Popup, Icon } from 'semantic-ui-react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { FormattedDate, FormattedTime } from 'react-intl';
// import { FilterForm } from './FilterForm';

import { useList, PageHeaderRow, EmptyWrapper, PaginatorWrapper } from '@inkcloud/components';

interface ListProps {}

const serviceName = '/starter-templates';
const pathPrefix = '/settings/starter-templates';

const filterQueryMap = (values) => {
  const filterMap: any = {
    firstName: values.firstName ? { $LIKE: values.firstName } : undefined,
    lastName: values.lastName ? { $LIKE: values.lastName } : undefined,
    email: values.email ? { $LIKE: values.email } : undefined,
  };

  return [].reduce(
    (m, field) => ({ ...m, [field]: values[field] ? values[field] : undefined }),
    filterMap
  );
};

export const List: React.FunctionComponent<ListProps & RouteComponentProps<any>> = (props) => {
  const {
    match: { params },
    location,
    history,
  } = props;
  const {
    state,
    reload,
    handleDelete,
    selectedItems,
    clearAll,
    setChecked,
    someSelected,
    toggleAll,
    filter,
    setFilter,
    sort,
    handleSort,
  } = useList({
    feathers: [
      'find',
      serviceName,
      [
        {
          query: {
            $populate: ['upload'],
          },
        },
      ],
    ],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  const { queryResult, isLoading: isStateLoading } = state as any;

  if (!queryResult) {
    return null;
  }

  return (
    <div>
      <PageHeaderRow header="Starter Templates" subheader="Settings">
        <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
        <Link to={`${pathPrefix}/add`}>
          <Button content="New" primary size={'mini'} />
        </Link>
      </PageHeaderRow>

      {/* <FilterForm value={filter} onChange={(v) => setFilter(v)} onReset={() => setFilter({})} /> */}
      <EmptyWrapper queryResult={queryResult.data} isStateLoading={isStateLoading}>
        <Segment loading={state.isLoading}>
          <PaginatorWrapper.Top
            filter={filter}
            reload={reload}
            data={queryResult as any}
            pathPrefix={`${pathPrefix}`}
          />
          <Table size={'small'} sortable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  <input type="checkbox" onChange={toggleAll} />
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={sort.createdAt ? sort.createdAt : null}
                  onClick={() => handleSort('createdAt')}
                >
                  Created
                </Table.HeaderCell>
                <Table.HeaderCell>Description</Table.HeaderCell>
                <Table.HeaderCell>Finish Size</Table.HeaderCell>
                <Table.HeaderCell>Flat Size</Table.HeaderCell>
                <Table.HeaderCell />
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {queryResult &&
                (queryResult as any).data.map((r: any, index: number) => (
                  <Table.Row key={index} verticalAlign="top">
                    <Table.Cell>
                      <input
                        type="checkbox"
                        value={r._id}
                        checked={selectedItems[r._id] || false}
                        onChange={setChecked}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <Popup
                        size="mini"
                        content={<FormattedTime value={r.createdAt} />}
                        trigger={
                          <div>
                            <FormattedDate value={r.createdAt} />
                          </div>
                        }
                      />
                    </Table.Cell>
                    <Table.Cell>{r.desc}</Table.Cell>
                    <Table.Cell>
                      {r.finishSize?.width ?? ''} x {r.finishSize?.height ?? ''}
                    </Table.Cell>
                    <Table.Cell>
                      {r.flatSize?.width ?? ''} x {r.flatSize?.height ?? ''}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      {r.upload?.contentType === 'image/jpeg' ||
                      r.upload?.contentType === 'image/jpg' ||
                      r.upload?.contentType === 'image/png' ||
                      r.upload?.contentType === 'image/gif' ? (
                        <Image
                          bordered
                          centered
                          size="small"
                          src={`https://storage.googleapis.com/${r.upload?.cloudBucket ?? ''}/${
                            r.upload?.cloudFilename ?? ''
                          }`}
                        />
                      ) : (
                        <a
                          href={`https://storage.googleapis.com/${r.upload?.cloudBucket ?? ''}/${
                            r.upload?.cloudFilename ?? ''
                          }`}
                          target="_blank"
                        >
                          <Icon name="file outline" size="big" /> {r.upload?.originalFilename}
                        </a>
                      )}
                    </Table.Cell>
                    <Table.Cell textAlign="right">
                      <Popup
                        size="mini"
                        content="Edit"
                        trigger={
                          <Link to={`${pathPrefix}/${r._id}`}>
                            <Button size={'mini'} icon={'edit'} />
                          </Link>
                        }
                      />
                    </Table.Cell>
                  </Table.Row>
                ))}
            </Table.Body>
          </Table>
          <PaginatorWrapper.Bottom
            reload={reload}
            data={queryResult as any}
            pathPrefix={`${pathPrefix}`}
          >
            {someSelected && (
              <Button primary type="button" content="Delete" onClick={handleDelete} />
            )}
          </PaginatorWrapper.Bottom>
        </Segment>
      </EmptyWrapper>
    </div>
  );
};
