import * as React from 'react';
import { Switch, Route } from 'react-router-dom';

import { List } from './List';
import { Detail } from './Detail';

const routes = (props) => (
  <Switch>
    <Route exact path="/settings/pickup-locations" component={List} />
    <Route exact path="/settings/pickup-locations/add" component={Detail} />
    <Route exact path="/settings/pickup-locations/:id" component={Detail} />
  </Switch>
);

export default routes;
