import React, { useEffect, useState, useContext, useRef } from 'react';
import { IntlProvider } from 'react-intl';

import { Route, Switch, RouteComponentProps } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import feathers from '../bootstrap/feathers';
import { ResponsiveContainer } from './ResponsiveContainer';

import Index from './IndexComponent';
import MicrositesIndex from './microsites';
// import Badger from './badger';
import Flex from './flex';
import InvoiceAdjusterIndex from './invoice-adjuster';
import ShippingAdjusterIndex from './shipping-adjuster';
import InvoicePayments from './invoice-payments';
import SettingsIndex from './settings';
import Admin from './admin';
import Content from './content';
import Products from './products';
import Orders from './orders';
import Proofs from './proofs';
import SalesOrdersLink from './sales-orders';
import RFQsLink from './rfqs';
import RFQsIncoming from './rfqs/incoming';

import DocumentPrinter from './document-printer';
import PurchaseOrdersLink from './purchase-orders';
import OrderApproval from './order-approval';
import OrderRejected from './order-rejected';
import OrderVoided from './order-voided';
// import ManageDesignJobs from "./manage-design-jobs";
import DesignJobs from './design-jobs';
import LateJobDetectors from './late-job-detector';
import Customers from './customers';
import Production from './production';
import Translation from './translation';
import Estimates from './estimates';
import Accounting from './accounting';
// force deploy 11
import StatusUpdate from './status-update';

import ImageManager from './image-manager';

import JobScheduler from './job-scheduling';

import RulesEngine from './rules-engine';

import Reports from './reports';

import StoreFrontContent from './store-front-content';
import OrderBuilder from './order-builder';

import ShipmentLog from './shipment-log';

import PickListScanner from './pick-list-scanner';

import ProductionPipeLine from './production-pipeline';
import CostEstimating from './cost-estimating';

import SupplierBillingReconciliation from './supplier-billing-reconciliation';

import PageNotFound from './PageNotFound';

import { usePageViews } from '../bootstrap/AnalyticsTracker';

const leftMargin = 195;

const appContentStyle = {
  leftMargin,
  background: '#f3f3f3',
  marginTop: '1.5em',
  marginRight: '1.5em',
  marginBottom: '1.5em',
  marginLeft: leftMargin,
};

const sidebarStyle = {
  background: '#1B1C1D',
};

interface AppProps {
  isAuthenticated: boolean;
  tenantId?: string;
  loadOrderItemStatuses?: any;
  loadCategories?: any;
  loadAttributes?: any;
  loadAddOns?: any;
  // router?: any;
}

interface AppState {
  visible: boolean;
  domainName: string;
  locale: string;
}

const rootSwitch = (
  <Switch>
    <Route exact path="/" component={Index} />
    <Route path="/settings/microsites" component={MicrositesIndex} />
    <Route path="/invoice/adjuster/:id" component={InvoiceAdjusterIndex} />
    <Route path="/shipping/adjuster/:id" component={ShippingAdjusterIndex} />
    <Route path="/invoice/payments/:id" component={InvoicePayments} />
    <Route path="/settings" component={SettingsIndex} />

    <Route path="/admin" component={Admin} />

    <Route path="/products" component={Products} />

    <Route path="/orders" component={Orders} />
    <Route path="/proofs" component={Proofs} />
    <Route path="/sales-orders" component={SalesOrdersLink} />
    <Route path="/rfqs" component={RFQsLink} />

    <Route path="/purchase-orders" component={PurchaseOrdersLink} />
    <Route path="/supplier-billing-reconciliation" component={SupplierBillingReconciliation} />
    <Route path="/order-approval" component={OrderApproval} />
    <Route path="/order-rejected" component={OrderRejected} />
    <Route path="/order-voided" component={OrderVoided} />
    <Route path="/design-jobs" component={DesignJobs} />
    <Route path="/document-printer" component={DocumentPrinter} />
    <Route path="/late-job-detectors" component={LateJobDetectors} />
    <Route path="/customers" component={Customers} />

    <Route path="/production" component={Production} />
    <Route path="/translation" component={Translation} />
    <Route path="/estimates" component={Estimates} />
    <Route path="/accounting" component={Accounting} />
    <Route path="/cost-estimating" component={CostEstimating} />

    <Route path="/status-update" component={StatusUpdate} />

    <Route path="/rules-engine" component={RulesEngine} />

    <Route path="/job-scheduler" component={JobScheduler} />

    <Route path="/image-manager" component={ImageManager} />

    <Route path="/content" component={Content} />
    <Route path="/reports" component={Reports} />

    {/* Disabling for now since not in use */}
    {/* <Route path="/badger" component={Badger} /> */}
    <Route path="/flex" component={Flex} />
    <Route path="/order-builder" component={OrderBuilder} />

    <Route path="/shipment-log" component={ShipmentLog} />
    <Route path="/pick-list-scanner" component={PickListScanner} />

    <Route path="/production-pipeline" component={ProductionPipeLine} />

    <Route path="/store-front-content" component={StoreFrontContent} />

    <Route path="*">
      <PageNotFound />
    </Route>

  </Switch>
);

export function App() {
  const authUser = useSelector((state: any) => state.auth.user);
  const mountRef = useRef(null);
  const [locale, setLocale] = useState('en-US');
  const dispatch = useDispatch();
  usePageViews();

  const loadAddOns = async () => {
    try {
      const res = await feathers.service('/products-legacy/add-ons-base').find({
        query: {
          $np: 1,
          $sort: { name: 1 },
          $select: ['_id', 'name'],
        },
      });

      dispatch({ type: 'PRESENCE_SAVE_ADDONS', addOns: res });
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    loadAddOns();
  }, []);

  useEffect(() => {
    const loadSettings = async (tenantId: string) => {
      try {
        const res: any = await feathers.service('/tenant-settings').get(tenantId, {
          query: {
            $select: ['internationalization', 'labelMaps', 'publishedContactInfo'],
          },
        });
        // console.log('res', res);

        dispatch({
          type: 'PRESENCE_SAVE_TENANT',
          tenant: { ...(res || {}), _id: tenantId },
        });
        setLocale(res?.internationalization?.locale?.replace('_', '-') ?? 'en-US');
      } catch (e) {
        console.error(e);
      }
    };

    if (authUser?.business && !mountRef.current) {
      loadSettings(authUser.business);
    }
  }, [authUser?.business]);

  return (
    <IntlProvider locale={locale} key={locale}>
      <ResponsiveContainer leftMargin={leftMargin}>
        {rootSwitch}
      </ResponsiveContainer>
    </IntlProvider>
  );
}
