import React, { useState, useEffect } from 'react';
import {
  Header,
  Segment,
  Icon,
  Form,
  Popup,
  Divider,
  Dropdown,
  Button,
  Message,
} from 'semantic-ui-react';

import {
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  Spinner,
  Flex,
  useCheckbox,
  Text,
  Heading,
  Button as ChakraButton,
  IconButton,
  // Icon,
} from '@chakra-ui/react';

import { Select } from 'chakra-react-select';

import { Link, RouteComponentProps } from 'react-router-dom';
import {
  useList,
  PageHeaderRow,
  PaginatorWrapper,
  EmptyWrapper,
  GenericFilter,
  basicQueryMap,
} from '@inkcloud/components';
import { connect } from 'react-redux';

import { IndexWrapper, IndexWrappedProps } from '../../bootstrap/IndexWrapper';
import {
  OrderItemComponent2,
  connectOrderItemModals,
  OrderItemModalsProps,
} from '../../common/OrderItemCard2';
import Feathers, { socket } from '../../bootstrap/feathers';
import BatchPrintModal from './BatchPrintModal';

import { getFilterDefinition } from './FilterForm2';

// simport { GenericFilter } from '@inkcloud/components'

interface ListProps {}

export interface ConnectedProps {
  user?: any;
}

// TODO: value should come from the row values
const checkValidValues = (filterRow, valueToCheck) => {
  const values =
    filterRow &&
    Object?.values(filterRow)
      ?.concat.apply([], filterRow)
      ?.filter((f) => f !== valueToCheck)
      ?.reduce(
        (m, field) => ({ ...m, [field]: values[field] ? values[field] : undefined }),
        filterRow
      );

  return values;
};

export const filterQueryMap = (values) => {
  let filterRows = basicQueryMap(values, {
    name: 'staticName',
  });

  // Map attributes as needed by API
  const prefix = 'attributes:';
  const attributes = Object.keys(filterRows)
    .filter((r) => r.startsWith(prefix))
    .reduce((acc, cur) => ({ ...acc, [cur.slice(prefix.length)]: filterRows[cur]?.$in }), {});

  // Delete the original attribute valuess so they are not submitted
  Object.keys(filterRows)
    .filter((r) => r.startsWith(prefix))
    .forEach((r) => delete filterRows[r]);
  // console.log('filterRows', filterRows);

  filterRows = {
    ...filterRows,
    microsite: checkValidValues(filterRows.microsite, 'all'),
    addOns: filterRows.addOns ? { $elemMatch: { addOnId: filterRows.addOns } } : undefined,
    attributes,
    // parent: filterRows.type === 'stock-child' ? true : filterRows.type === 'stock-parent' ? { $ne: true } : undefined,
    approvalStatus:
      filterRows?.approvalStatus?.$elemMatch?.value === 'pending'
        ? 'pending'
        : filterRows?.approvalStatus?.$elemMatch?.value === 'rejected'
        ? 'rejected'
        : { $ne: 'pending' },

    isPriority:
      filterRows?.isPriority?.$elemMatch?.value === 'priority'
        ? { $eq: true }
        : filterRows?.isPriority?.$elemMatch?.value === 'not-priority'
        ? { $ne: true }
        : undefined,
    // metadata: filterRows.metadata ? { $elemMatch: { key: filterRows.metadata?.key, value: filterRows.metadata?.value }} : undefined,
    type: undefined,
    itemType: filterRows.itemType?.$elemMatch?.value,
  };

  return filterRows;
};

const sortableByOptions = [
  { text: 'Date', value: 'createdAt' },
  { text: 'Approval Date', value: 'approvedAt' },
  { text: 'Arrive By', value: 'arriveBy' },
  { text: 'Order Number', value: 'humanId' },
];

const sortByOptions = [
  { text: 'Descending', value: -1 },
  { text: 'Ascending', value: 1 },
];

const serviceName = 'order-items';
const pathPrefix = '/orders';

const PreList: React.FunctionComponent<
  ListProps & OrderItemModalsProps & ConnectedProps & RouteComponentProps<any>
> = (props) => {
  const {
    match: { params },
    location,
    history,
    user,
  } = props;
  const isVendor = !!user.vendor;
  const {
    state,
    reload,
    handleDelete,
    selectedItems,
    clearAll,
    setChecked,
    someSelected,
    toggleAll,
    filter,
    setFilter,
    sort,
    handleSort,
  } = useList({
    feathers: [
      'find',
      serviceName,
      [
        {
          query: {
            'productionStatus.jobStatus': !isVendor ? { $ne: 'VOID' } : undefined,
            $populate: [
              { path: 'attributes.attribute', select: 'name' },
              { path: 'attributes.value', select: 'name' },
              { path: 'category', select: 'name' },
              { path: 'customer', select: 'organizationName primaryContact' },
              { path: 'micrositeUser', select: 'firstName lastName' },
              { path: 'productionChannel', select: 'name' },
              { path: 'vendor', select: 'companyName' },
              // 'productionStatus.jobStatus',
              'miscUploads',
            ],
            $populateProductImages: 1,
            $sort: { createdAt: -1 },
          },
        },
      ],
    ],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  const [gridViewOptions, setGridViewOptions] = useState<
    { label: string; value: string; filter: {} }[]
  >([]);

  const [isBatchPrintModalOpen, setIsBatchPrintModalOpen] = React.useState(false);

  const [sortableBy, setSortableBy] = useState('createdAt');
  const [sortBy, setSortBy] = useState(sort[sortableBy] === 'ascending' ? 1 : -1);

  const [exportFields, setExportFields] = useState([]);

  const [isSubmitting, setIsSubmitting] = useState({
    duplicate: false,
    export: false,
  });

  const [errorMessage, setErrorMessage] = useState({
    duplicate: '',
    export: '',
  });

  useEffect(() => {
    const loadViews = async () => {
      const res = await Feathers.service('data-views').find({
        query: {
          viewType: 'list',
        },
      });

      setGridViewOptions(
        res?.data?.map((v) => ({
          label: v.name,
          value: v._id,
          columns: v.columns,
          filter: v.filter,
          exportFields: v.exportFields,
        }))
      );
    };

    loadViews();
  }, []);

  // Real-time: Subscribe to any changes and reload
  // React.useEffect(() => {
  //   const handler = (msg) => reload();
  //   socket.service('order-items').on('patched', handler);
  //   socket.service('order-items').on('created', handler);
  //   return () => {
  //     socket.service('order-items').removeListener('patched', handler)
  //     socket.service('order-items').removeListener('created', handler)
  //   };
  // }, [])

  const handleArtworkManagementClick = (e, data) => {
    props.artworkHandleOpen(data.orderitem);
  };

  const printHandleOpen = (e, data) => {
    props.printHandleOpen(data.type, data.payload);
  };

  const preflightHandleOpen = (e, data) => {
    props.preflightHandleOpen(data.orderitem);
  };

  const statusChangeHandleOpen = (e, data) => {
    // props.statusChangeHandleOpen(data.orderitem);
  };

  const isFiltered = Object.keys(filter)?.reduce(
    (prev, field) =>
      prev ||
      !!(Array.isArray(filter[field]) ? filter[field]?.some((f) => f.value) : filter[field]),
    false
  );

  let queryFilter;
  if (isFiltered) {
    queryFilter = filter?.rows?.reduce(
      (acc, cur) =>
        Object.assign(acc, {
          [cur.selectedFact?.replace(/\./g, '')]: Array.isArray(cur.value)
            ? cur.value?.map((v) => v.value)
            : cur.value?.value,
        }),
      {}
    );
  }

  const handleExport = async () => {
    setErrorMessage({
      ...errorMessage,
      export: '',
    });
    setIsSubmitting({
      ...isSubmitting,
      export: true,
    });
    try {
      const res: any = await Feathers.service('/reports/v3/results').create({
        reportKey: 'order-item-data',
        reportName: 'Order Item Export',
        query: filterQueryMap(filter),
        exportFields,
      });

      history.push('/reports/results');
    } catch (e) {
      setErrorMessage({
        ...errorMessage,
        export: 'Error while exporting.',
      });
    }

    setIsSubmitting({
      ...isSubmitting,
      export: false,
    });
  };

  const { queryResult, isLoading: isStateLoading } = state as any;

  // console.log('gridViewOptions', gridViewOptions);

  return (
    <>
      <PageHeaderRow header="Items" subheader="Order">
        <Form>
          <Form.Field inline>
            <Button
              type="button"
              size="mini"
              content="Batch Print"
              onClick={() => setIsBatchPrintModalOpen(true)}
            />
            {isFiltered && queryResult?.data?.length > 0 && (
              <>
                <Button
                  type="button"
                  content="Export Order Item Data"
                  size="mini"
                  onClick={handleExport}
                  loading={isSubmitting.export}
                  disabled={isSubmitting.export}
                />{' '}
              </>
            )}
            <label>View: </label>
            {!isVendor && (
              <Popup
                size="mini"
                content="Per Order"
                trigger={
                  <Link to={'/orders/overall'}>
                    <Icon name="th list" size="large" color="grey" />
                  </Link>
                }
              />
            )}
            <Popup
              size="mini"
              content="Per Order Item"
              position="top right"
              trigger={<Icon name="th" size="large" color="blue" />}
            />
          </Form.Field>
        </Form>
      </PageHeaderRow>
      <Box borderRadius="md" mb={4} borderWidth="1px" bg="white">
        <Box py={2} px={3} mb={5} bg="gray.100">
          <Heading as="h4" size="md" fontWeight="medium">
            Filter by the following
          </Heading>
        </Box>
        <Box py={3} px={5} mb={3}>
          {gridViewOptions.length > 0 && (
            <Box mb={3}>
              <Select
                useBasicStyles
                options={gridViewOptions}
                placeholder="Select data view..."
                onChange={(v) => {
                  setFilter({ ...(v?.filter ?? {}) });
                  setExportFields(((v as any)?.exportFields as any) ?? []);
                  history.replace({
                    search: `?page=1`,
                  });
                }}
              />
            </Box>
          )}
          <GenericFilter
            factDefinitionFunction={getFilterDefinition}
            value={filter}
            onChange={(v) => {
              setFilter({ ...v });
            }}
          />
        </Box>
      </Box>
      {/* <Header as="h5" attached="top" block>
        Filter by the following
      </Header>
      <Segment attached>
        <Box position="relative" zIndex="dropdown">
          {gridViewOptions.length > 0 && (
            <Select
              options={gridViewOptions}
              placeholder="Select data view..."
              onChange={(v) => {
                setFilter({ ...(v?.filter ?? {}) });
                setExportFields(((v as any)?.exportFields as any) ?? []);
                history.replace({
                  search: `?page=1`,
                });
              }}
            />
          )}

          <Box mt={4} mx={-3}>
            <GenericFilter
              factDefinitionFunction={getFilterDefinition}
              value={filter}
              onChange={(v) => {
                // console.log('v', v)
                setFilter({ ...v });
              }}
            />
          </Box>
        </Box>
      </Segment> */}

      <Segment>
        <EmptyWrapper queryResult={queryResult} isStateLoading={isStateLoading}>
          <PaginatorWrapper.Top
            filter={filter}
            reload={reload}
            data={queryResult as any}
            pathPrefix={`${pathPrefix}/`}
          >
            <Form size="tiny">
              <Form.Group inline>
                <Form.Field>
                  <Dropdown
                    options={sortableByOptions}
                    value={sortableBy}
                    selection
                    onChange={(e, data) => {
                      setSortableBy((data as any).value);
                      setSortBy(sort[(data as any).value] !== 'ascending' ? 1 : -1);
                      handleSort((data as any).value);
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <Dropdown
                    options={sortByOptions}
                    value={sortBy}
                    selection
                    onChange={(e, data) => {
                      setSortBy(sort[sortableBy] !== 'ascending' ? 1 : -1);
                      handleSort(sortableBy);
                    }}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          </PaginatorWrapper.Top>
          {errorMessage.export && <Message error content={errorMessage.export} />}
          <Divider hidden />
          {/* <Checkbox onChange={(e, data) => handleAllCheckboxesChange(data.checked)} /> */}

          {queryResult &&
            queryResult.data &&
            queryResult.data.map((i: any, index: number) => (
              <OrderItemComponent2
                key={index}
                orderItem={i}
                isVendor={isVendor}
                handleArtworkManagementClick={handleArtworkManagementClick}
                printHandleOpen={printHandleOpen}
                preflightHandleOpen={preflightHandleOpen}
                statusChangeHandleOpen={statusChangeHandleOpen}
                // onStatusChangerSubmit={handleStatusChangerSubmit}
                onReload={reload}
                // onTurnaroundSubmit={handleTurnaroundSubmit}
              />
            ))}

          <Divider hidden />

          <PaginatorWrapper.Bottom
            reload={reload}
            data={queryResult as any}
            pathPrefix={`${pathPrefix}`}
          />
        </EmptyWrapper>
        {isBatchPrintModalOpen && (
          <BatchPrintModal
            mode="order-item"
            open={isBatchPrintModalOpen}
            onClose={() => setIsBatchPrintModalOpen(false)}
            data={queryResult?.data as any}
          />
        )}
      </Segment>
    </>
  );
};

const mapStateToProps = (state): ConnectedProps => ({
  user: state.auth.user || {},
});

export const List = connect(
  mapStateToProps,
  {}
)(connectOrderItemModals<IndexWrappedProps>(PreList) as any);
