import * as React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { useList, PageHeaderRow, PaginatorWrapper } from '@inkcloud/components';
import { Segment, Grid, Image, Button, Divider, Label, Popup, Icon } from 'semantic-ui-react';
import moment from 'moment';

import { FilterForm } from './FilterForm';
import { ImageUploadModal } from './ImageUploadModal';

export interface ConnectedProps {
  tenant?: any;
}

interface ListProps {}

const filterQueryMap = (values) => {
  const filterMap: any = {
    filename: values.filename ? { $LIKE: values.filename } : undefined,
    mimeType: values.mimeType ? values.mimeType : undefined,
    createdAt: {
      $gte: values.start ? moment(values.start).format('YYYY-MM-DD') : undefined,
      $lte: values.end ? moment(values.end).format('YYYY-MM-DD') : undefined,
    },
  };

  return [].reduce(
    (m, field) => ({ ...m, [field]: values[field] ? values[field] : undefined }),
    filterMap
  );
};

const serviceName = '/content';
const pathPrefix = `/settings/content-management/image-manager`;

const { useState } = React;

export const List: React.FunctionComponent<ListProps & RouteComponentProps> = (props) => {
  const {
    match: { params },
    location,
    history,
  } = props;
  const {
    state,
    reload,
    handleDelete,
    selectedItems,
    clearAll,
    setChecked,
    someSelected,
    toggleAll,
    filter,
    setFilter,
    sort,
    handleSort,
  } = useList({
    feathers: ['find', serviceName, [{ query: {} }]],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  const [isOpenModal, setIsOpenModal] = useState(false);

  const { queryResult, isLoading: isStateLoading } = state as any;

  const handleDownloadImage = (url: string) => {
    window.open(`https://storage.googleapis.com/icstorage/${url}`, '_blank');
  };

  return (
    <>
      <PageHeaderRow header="Image Manager" subheader="Settings">
        <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
        <Button content="Upload" primary size={'mini'} onClick={() => setIsOpenModal(true)} />
      </PageHeaderRow>
      <Segment>
        <FilterForm value={filter} onChange={(v) => setFilter(v)} onReset={() => setFilter({})} />
      </Segment>
      <Segment loading={state.isLoading}>
        <PaginatorWrapper.Top
          filter={filter}
          reload={reload}
          data={queryResult as any}
          pathPrefix={`${pathPrefix}`}
        />
        <Grid>
          <Grid.Row columns={5}>
            {queryResult &&
              queryResult.data &&
              (queryResult as any).data.map((r: any, index: number) => (
                <Grid.Column key={index}>
                  <Segment style={{ height: '280px' }}>
                    <Popup
                      size="mini"
                      trigger={
                        <div>
                          <Label as="a" color="blue" size="tiny" ribbon>
                            {r.filename}
                          </Label>
                          {r.extension === 'pdf' ? (
                            <div style={{ margin: '.5em 0' }}>
                              <Icon name="file pdf" size="huge" />
                            </div>
                          ) : (
                            <Image
                              src={`https://storage.googleapis.com/icstorage/${r.publicUrl}`}
                              style={{ maxHeight: '180px', margin: '.5em 0' }}
                            />
                          )}

                          <Grid>
                            <Grid.Column width={8} textAlign="left">
                              <input
                                type="checkbox"
                                value={r._id}
                                checked={selectedItems[r._id] || false}
                                onChange={setChecked}
                              />
                            </Grid.Column>
                            <Grid.Column width={8} textAlign="right">
                              {r.publicUrl && (
                                <Popup
                                  content={`https://storage.googleapis.com/icstorage/${r.publicUrl}`}
                                  on="click"
                                  openOnTriggerClick={true}
                                  closeOnTriggerBlur={false}
                                  closeOnPortalMouseLeave={false}
                                  hoverable={true}
                                  pinned={true}
                                  trigger={<Button size="mini" icon="linkify" />}
                                />
                              )}
                              {r.publicUrl && (
                                <Button
                                  size="mini"
                                  icon="download"
                                  onClick={() => handleDownloadImage(r.publicUrl)}
                                />
                              )}
                            </Grid.Column>
                          </Grid>
                        </div>
                      }
                    >
                      <Popup.Content>
                        <div>{r.size}kb </div>
                        <div>{r.mimeType}</div>
                      </Popup.Content>
                    </Popup>
                  </Segment>
                  <Divider hidden />
                </Grid.Column>
              ))}
          </Grid.Row>
        </Grid>
        <PaginatorWrapper.Bottom
          reload={reload}
          data={queryResult as any}
          pathPrefix={`${pathPrefix}`}
        >
          {someSelected && <Button primary type="button" content="Delete" onClick={handleDelete} />}
        </PaginatorWrapper.Bottom>

        {
          <ImageUploadModal
            isOpen={isOpenModal}
            onClose={() => setIsOpenModal(false)}
            onReload={reload}
          />
        }
      </Segment>
    </>
  );
};
