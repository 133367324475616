import React, { useState } from 'react';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Alert,
  Box,
} from '@chakra-ui/react';

import UploadMisc from './UploadMisc';

import Feathers from '../bootstrap/feathers';

interface IAttachDocModalProps {
  orderId: string;
  onClose: () => void;
  onReload: () => void;
}

export default function AttachDocModal(props: IAttachDocModalProps) {
  const { orderId, onClose, onReload } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [invoice, setInvoice] = useState({
    label: 'Invoice',
    uploadLog: '',
  });
  const [shipping, setShipping] = useState({
    label: 'Shipping',
    uploadLog: '',
  });

  const handleSubmit = async () => {
    setErrorMessage('');
    setIsSubmitting(true);
    try {
      const resAll = [invoice, shipping].map(async (payload) =>
        Feathers.service('orders/attachments').create({
          label: payload.label,
          orderId,
          uploadLog: payload.uploadLog,
        })
      );

      await Promise.all(resAll);

      onReload();

      onClose();
    } catch (e) {
      const errMsg = e?.code < 500 ? e?.message : 'Something went wrong. Please try again.';
      setErrorMessage(errMsg);
    }
    setIsSubmitting(false);
  };

  return (
    <Modal isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Attach Documents</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box>
            <UploadMisc
              onUpload={(file) => setInvoice({ ...invoice, uploadLog: file?.uploadLog })}
              isMultiple={false}
              isDraggable={false}
              btnLabel="Upload Invoice"
            />
          </Box>
          <Box>
            <UploadMisc
              onUpload={(file) => setShipping({ ...shipping, uploadLog: file?.uploadLog })}
              isMultiple={false}
              isDraggable={false}
              btnLabel="Upload Shipping Doc"
            />
          </Box>

          {errorMessage && (
            <Alert status="error" mt={3}>
              {errorMessage}
            </Alert>
          )}
        </ModalBody>

        <ModalFooter>
          <Button isDisabled={isSubmitting} mr={3} onClick={onClose}>
            Close
          </Button>
          <Button
            isDisabled={isSubmitting}
            isLoading={isSubmitting}
            colorScheme="blue"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
