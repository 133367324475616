import * as React from 'react';
import { Modal, Button, Message } from 'semantic-ui-react';

interface ClearCartModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  isSubmitting?: boolean;
  errorMessage?: string;
}

export const ClearCartModal: React.FunctionComponent<ClearCartModalProps> = (props) => {
  const { open, onClose, onConfirm, isSubmitting, errorMessage } = props;

  return (
    <Modal size="tiny" open={open} onClose={onClose} closeIcon>
      <Modal.Header>Clear Active Carts</Modal.Header>
      <Modal.Content>
        Are you sure you want to clear your active carts?
        {errorMessage && <Message error size="tiny" content={errorMessage} />}
      </Modal.Content>
      <Modal.Actions>
        <Button size="tiny" negative content="Decline" onClick={onClose} />
        <Button
          size="tiny"
          positive
          content="Confirm"
          disabled={isSubmitting}
          loading={isSubmitting}
          onClick={onConfirm}
        />
      </Modal.Actions>
    </Modal>
  );
};
