import * as React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { FormattedDate, FormattedTime } from 'react-intl';
import { Table, Segment, Button, Popup } from 'semantic-ui-react';
import {
  useList,
  EmptyWrapper,
  PreLoader,
  PaginatorWrapper,
  PageHeaderRow,
} from '@inkcloud/components';

interface ListProps {}

const serviceName = 'chat-transcripts';
const pathPrefix = '/flex/live-chat';

const filterQueryMap = (values) => {};

export const List: React.FunctionComponent<ListProps & RouteComponentProps<any>> = (props) => {
  const {
    match: { params },
    location,
    history,
  } = props;
  const {
    state,
    reload,
    handleDelete,
    selectedItems,
    clearAll,
    setChecked,
    someSelected,
    toggleAll,
    filter,
    setFilter,
    sort,
    handleSort,
  } = useList({
    feathers: ['find', serviceName, [{ query: {} }]],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  const { queryResult, isLoading: isStateLoading } = state as any;

  if (!queryResult) {
    return <PreLoader />;
  }

  return (
    <>
      <PageHeaderRow header="Live Chat" subheader="Flex" />
      <EmptyWrapper queryResult={queryResult} isStateLoading={isStateLoading}>
        <Segment>
          <PaginatorWrapper.Top
            filter={filter}
            reload={reload}
            data={queryResult as any}
            pathPrefix={`${pathPrefix}/`}
          />
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Created At</Table.HeaderCell>
                <Table.HeaderCell>Customer/Visitor Name</Table.HeaderCell>
                <Table.HeaderCell>Email</Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {(queryResult as any).data.map((r: any, index: number) => (
                <Table.Row key={index}>
                  <Table.Cell>
                    <FormattedDate value={r.createdAt} /> <FormattedTime value={r.createdAt} />
                  </Table.Cell>
                  <Table.Cell>{r.visitorName}</Table.Cell>
                  <Table.Cell>{r.visitorEmail}</Table.Cell>
                  <Table.Cell>
                    <Link to={`${pathPrefix}/${r._id}`}>
                      <Popup
                        content="View Details"
                        trigger={<Button size="tiny" icon="file alternate" />}
                      />
                    </Link>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
          <PaginatorWrapper.Bottom
            reload={reload}
            data={queryResult as any}
            pathPrefix={`${pathPrefix}`}
          />
        </Segment>
      </EmptyWrapper>
    </>
  );
};
