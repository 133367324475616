import * as React from 'react';
import { Form, Radio } from 'semantic-ui-react';

interface IShipMethodsProps {
  availableMethods: { key: string; description: string }[];
  selectedMethod: string;
  onChange: (v: string) => void;
}

export const ShipMethods: React.FunctionComponent<IShipMethodsProps> = (props) => {
  const { availableMethods, selectedMethod, onChange } = props;
  return (
    <Form>
      <Form.Group inline>
        {availableMethods &&
          availableMethods.map((m) => (
            <Form.Field key={m.key}>
              <Radio
                label={m.description}
                name="shipMethods"
                value={m.key}
                checked={m.key === selectedMethod}
                onChange={() => onChange(m.key)}
              />
            </Form.Field>
          ))}
      </Form.Group>
    </Form>
  );
};
