export const calculateSummary = (lineItems: any, taxRate?: number) =>
  lineItems?.reduce(
    (acc, li) => {
      const updatedAcc = { ...acc };
      // const extendedAmount = Number(li.amount) * Number(li.quantity)
      const extendedAmount = Number(li.amount);
      if (li.isTaxable) {
        updatedAcc.taxable += extendedAmount;
        updatedAcc.salesTax += Number(extendedAmount) * (Number(taxRate ?? 0.0) / 100);
        updatedAcc.total = updatedAcc.taxable + updatedAcc.nonTaxable + updatedAcc.salesTax;
      } else {
        updatedAcc.nonTaxable += extendedAmount;
        updatedAcc.total = updatedAcc.taxable + updatedAcc.nonTaxable + updatedAcc.salesTax;
      }

      return updatedAcc;
    },
    { taxable: 0.0, nonTaxable: 0.0, salesTax: 0.0, total: 0.0 }
  );

export const initMapper = (values: any, type: string, orderType?: string) => {
  if (type === 'otherInfo') {
    return {
      csr: values?.csr ?? '',
      shipDate: values?.shipDate ?? '',
      customerPo: values?.customerPo ?? '',
      soDescription: values?.soDescription ?? '',
      rfqNum: values?.rfqNum ?? '',
    };
  }

  if (type === 'customerPicker') {
    let customerData = {};
    if (values?.microsite) {
      customerData = {
        ...(values?.micrositeUser || {}),
      };
    } else {
      customerData = {
        ...(values?.customer || {}),
      };
    }

    return {
      mode: values?.microsite || !values?._id ? 'microsite' : 'customer',
      customer: values?.customer?._id ?? '',
      customerData,
      microsite: values?.microsite ?? null,
      micrositeUser: values?.micrositeUser?._id ?? null,
      orderType: values?.orderType || 'purchase',
    };
  }

  if (type === 'items') {
    // console.log('values', values);

    // console.log('!!values?.createdFromRfq ?? false', !!values?.createdFromRfq ?? false);

    return (
      values?.items?.map((item) => ({
        ...(item || {}),
        isCreatedFromRfq: !!values?.createdFromRfq ?? false,
        product: {
          label: item?.product?.key,
          value: item?.product?._id,
          name: item?.product?.name,
          prices: item?.product?.prices,
          amount: orderType === 'sales' ? item?.product?.price ?? 0 : item?.product?.cost ?? 0,
        },
      })) || []
    );
  }

  return values;
};
