import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Modal, Message, Button, Form } from 'semantic-ui-react';
import { Feathers } from '../../../bootstrap/feathers/feathers';

import { humanize } from 'underscore.string';
import { chunk } from 'lodash-es';
import { ValueInput } from './ValueInput';

interface QueryField {
  type: 'text' | 'select' | 'multi-select' | 'datepicker';
  key: string;
  label: string;
  options?: { key: string; value: string }[];
}

export interface iCustomsReportModalProps {
  isOpen: boolean;
  onClose: () => void;
  data: {
    _id: string;
    microsite?: string;
    reportKey: string;
    reportName: string;
    queryFields: QueryField[];
  };
}

const { useState } = React;

const PreCustomsReportModal: React.FunctionComponent<
  iCustomsReportModalProps & RouteComponentProps
> = (props) => {
  const { isOpen, data, onClose, history } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [filter, setFilter] = useState({});

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      const payload = {
        reportKey: data?.reportKey,
        reportName: data?.reportName,
        microsite: data?.microsite,
        query: filter,
      };
      const res: any = await Feathers.service('/reports/v3/results').create(payload, { query: {} });

      if (res) {
        history.push('/reports/results');
      }
    } catch (e) {
      e.code && e.code < 500
        ? setErrorMessage(e.message)
        : setErrorMessage('We are experiencing technical difficulties. Please try again');
    }

    setIsSubmitting(false);
  };

  const handleValueChange = (value) => {
    setFilter({ ...filter, ...value });
  };

  const chunckQuery = chunk(data?.queryFields, 2);

  const valueInputTemplate = chunckQuery?.map((chunk, index) => {
    const inputTemplate = chunk?.map((query, index) => {
      return (
        <ValueInput
          key={index}
          value={query.key}
          placeholder={query.label}
          onChange={handleValueChange}
          type={query.type}
          options={
            query?.options?.map((option) => ({ ...option, text: humanize(option?.value) })) || []
          }
        />
      );
    });

    return (
      <Form.Group key={index} widths="equal">
        {inputTemplate}
      </Form.Group>
    );
  });

  return (
    <Modal open={isOpen} onClose={onClose} closeIcon>
      <Modal.Header>
        Custom Report - {data?.reportName ?? ''} {data?.reportKey ? `(${data?.reportKey})` : ''}
      </Modal.Header>
      <Modal.Content>
        <Form>{valueInputTemplate}</Form>

        {errorMessage && <Message error content={errorMessage} />}
      </Modal.Content>

      <Modal.Actions>
        <Button size="tiny" type="button" negative onClick={props.onClose}>
          Cancel
        </Button>
        <Button
          size="tiny"
          type="button"
          positive
          content="Submit Report"
          onClick={handleSubmit}
          loading={isSubmitting}
        />
      </Modal.Actions>
    </Modal>
  );
};

export const CustomsReportModal = withRouter(PreCustomsReportModal);
