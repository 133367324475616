import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Segment } from 'semantic-ui-react';
import { PageHeaderRow, PreLoader, useFeathers } from '@inkcloud/components';
import feathers from './../../../bootstrap/feathers';
import { SalesPersonForm } from './SalesPersonForm';

interface DetailProps {}

const serviceName = 'sales-people';
const service = feathers.service(serviceName);
const commonParams = { query: { $populate: ['manager'] } };

export const Detail: React.FunctionComponent<DetailProps & RouteComponentProps<{ id?: string }>> = (
  props
) => {
  const {
    match: {
      params: { id },
    },
    history,
  } = props;

  const pathPrefix = `/admin/sales-people`;

  const isEdit = !!id;
  const [state, reload] = useFeathers('get', serviceName, [id, commonParams], true);
  const handleSubmit = async (values: any) => {
    if (values.user === '') {
      values.user = null;
    }

    if (values.manager === '') {
      values.manager = null;
    }
    isEdit
      ? await service.patch(id, values, commonParams)
      : await service.create(values, commonParams);
    history.push(pathPrefix);
  };

  const pageTitle = isEdit ? 'Edit' : 'New';

  if (isEdit && !state.data) {
    return <PreLoader />;
  }

  const initialData = { ...state.data };

  if (initialData.user) {
    initialData.linkToUser = true;
  }

  return (
    <div>
      <PageHeaderRow header={`${pageTitle} Sales People`} subheader="List" subheaderId={''}>
        <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
      </PageHeaderRow>
      <Segment>
        {(!isEdit || state.data) && (
          <SalesPersonForm initialValues={initialData} onSubmit={handleSubmit} />
        )}
      </Segment>
    </div>
  );
};
