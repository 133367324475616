import * as React from 'react';
import {
  Flex,
  FormControl as ChakraFormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  InputProps,
  FormHelperText,
  Tooltip,
  FormControlProps as ChakraFormControlProps,
  useToken,
} from '@chakra-ui/react';
import { UseFormReturn, useFormContext, useController } from 'react-hook-form';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { getProperty } from 'dot-prop';

interface IFormPatternProps {
  formReturn: UseFormReturn;
  size: InputProps['size'];
  formPath?: string;
}

export interface FormControlProps extends ChakraFormControlProps {
  field: string;
  size?: InputProps['size'];
  height?: InputProps['height'];
  mb?: number;
  placeholder?: string;
  label: string;
  tooltip?: string;
  helpText?: string;
  key?: string;
  formPath?: string;
  display?: string;
  alignItems?: string;
  render: (fieldProps: any) => React.ReactNode;
  required?: boolean;
}

export const FormControl = (props: FormControlProps) => {
  const [sm] = useToken('sizes', ['8']);

  const {
    tooltip,
    field: fieldName,
    helpText,
    size = 'sm',
    height = 'auto',
    minHeight = sm,
    mb = 6,
    key,
    label,
    placeholder,
    formPath,
    render,
    required = false,
    display,
    alignItems,
  } = props;

  const pathTo = (path: string) => `${formPath ? `${formPath}.` : ''}${path}`;

  // if (!useFormContext()) {
  //   return null;
  // }

  // const {
  //   formState: { errors },
  // } = useFormContext();
  const {
    field,
    formState: { errors },
  } = useController({ name: pathTo(fieldName), rules: { required } });
  const getFieldError = (path: string) => getProperty(errors, pathTo(path));

  const getFieldErrorMessage = (path: string) => {
    const fieldError = getFieldError(path);
    return fieldError ? fieldError.message?.toString() : '';
  };

  const hasError = !!getFieldError(fieldName);

  return (
    <ChakraFormControl
      size={size}
      isRequired={required}
      isInvalid={hasError}
      mb={mb}
      display={display}
      alignItems={alignItems}
    >
      {label && tooltip ? (
        <Flex justifyItems={'center'} alignContent={'center'} alignItems={'center'}>
          <FormLabel size={size} htmlFor={fieldName}>
            {label}
          </FormLabel>
          <FormLabel requiredIndicator={<></>}>
            <Tooltip label={tooltip} aria-label={'A tooltip'}>
              <InfoOutlineIcon />
            </Tooltip>
          </FormLabel>
        </Flex>
      ) : (
        <FormLabel size={size} htmlFor={fieldName}>
          {label}
        </FormLabel>
      )}
      {render({ ...field, isInvalid: hasError, placeholder, height, minHeight, size, id: field })}
      {!hasError && helpText && <FormHelperText>{helpText}</FormHelperText>}
      <FormErrorMessage>{getFieldErrorMessage(fieldName)}</FormErrorMessage>
    </ChakraFormControl>
  );
};

export default FormControl;
