import * as React from 'react';
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react';

import { JobCardData } from './JobCard';
import PropertyList from '../../common/v3/PropertyList/PropertyList';
import PropertyListProperty from '../../common/v3/PropertyList/PropertyListProperty';


interface IJobSchedulerDetailDrawerProps {
  job: JobCardData;
  isOpen: boolean;
  onClose: () => void;
}

export const JobSchedulerDetailDrawer: React.FunctionComponent<IJobSchedulerDetailDrawerProps> = (props) => {
  const { isOpen, onClose, job } = props;

  const { jobNumber, customerName, description, size, stock, quantity, needsPressCheck, workflowTemplateName, dueDate } = job ?? {};

  return (
    <Drawer
        isOpen={isOpen}
        placement='right'
        onClose={onClose}
        // finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Job Details</DrawerHeader>

          <DrawerBody>
            {/* <Input placeholder='Type here...' /> */}
            details go here

            <PropertyList>
              <PropertyListProperty label='Job #' value={jobNumber} />
              <PropertyListProperty label='Customer' value={customerName} />
              <PropertyListProperty label='Description' value={description} />
              <PropertyListProperty label='Size' value={size} />
              <PropertyListProperty label='Stock' value={stock} />
              <PropertyListProperty label='Quantity' value={quantity} />

            </PropertyList>
          </DrawerBody>

          {/* <DrawerFooter>
            <Button variant='outline' mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme='blue'>Save</Button>
          </DrawerFooter> */}
        </DrawerContent>
      </Drawer>
  );
};

export default JobSchedulerDetailDrawer;
