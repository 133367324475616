import * as React from 'react';
import { useMediaQuery } from '@chakra-ui/react';
import { Grid } from 'semantic-ui-react';
import LoginFormContainer from './LoginFormContainer';

const loginStyle = {
  height: '100%',
  backgroundImage:
    'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("https://storage.googleapis.com/inkcloud-assets/login-bg1.jpg")',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
};

// redirectTo={props.location.query.redirect}

const Container = (props) => {
  const [isLargerThanTablet] = useMediaQuery('(min-width: 768px)');
  return (
    <div style={loginStyle}>
      {isLargerThanTablet ? (
        <Grid
          style={{ height: '100%' }}
          verticalAlign="middle"
          columns={4}
          stretched
          centered
          doubling
        >
          <Grid.Row>
            <Grid.Column>
              {/* <LoginFormContainer match={props.match} /> */}
              <LoginFormContainer />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      ) : (
        <Grid style={{ height: '100%' }} verticalAlign="middle" columns={1} stretched centered>
          <Grid.Row>
            <Grid.Column>
              <LoginFormContainer />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
      {/* <Responsive  style={{height: '100%' }} {...Responsive.onlyMobile}>
      <Grid style={{height: '100%' }} verticalAlign='middle' columns={1} stretched centered >
        <Grid.Row>
          <Grid.Column>
            <LoginFormContainer match={props.match} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Responsive>
     <Responsive  style={{height: '100%' }} minWidth={Responsive.onlyTablet.minWidth}>
      <Grid style={{height: '100%' }} verticalAlign='middle' columns={4} stretched centered doubling>
        <Grid.Row>
          <Grid.Column>
            <LoginFormContainer match={props.match} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Responsive> */}
    </div>
  );
};

export default Container;
