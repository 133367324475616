export const initialMapper = (values) => ({
  ...values,
  // If null, set a default quantitySettings
  isHidden: values.isHidden || false,
  backordersDisabled: values.backordersDisabled || false,
  quantitySettings: values.quantitySettings || { min: 1, start: 99999, step: 1 },
  unitOfMeasure: values.unitOfMeasure || '',
  unitOfMeasureConversion: values.unitOfMeasureConversion || '',

  price: values.price || '0.00',
  page: {
    name: '',
    title: '',
    template: 'product.html',
    url: '',
  },
  inventoryLowThreshold: values.inventoryLowThreshold || '',
  inventoryLowEmailNotify: {
    name: values.inventoryLowEmailNotify ? values.inventoryLowEmailNotify.name : '',
    email: values.inventoryLowEmailNotify ? values.inventoryLowEmailNotify.email : '',
  },
  category: values?.category || '',
  images: values.images || [],
  priceTiers: values.priceTiers || [],
  variantOptions: values.variantOptions || [{ key: 'Size', value: 'Medium' }],
  variants: values.variants || [],
  parent: values.parent || '',
  hasParent: values.parent ? true : false,
  compositeProducts: values.compositeProducts || [],
  isComposite: values.isComposite || false,
});

export const submissionMapper = (values) => {
  const updatedValues = {
    ...values,
    inventory: true,
    productionChannel: values.productionChannel || null,
  };

  if (!values.createContentPage) {
    updatedValues.page = undefined;
  }

  if (!values.parent) {
    updatedValues.parent = undefined;
    updatedValues.variantValues = [];
  }

  return updatedValues;
};
