import * as React from 'react';
import { Table, Button } from 'semantic-ui-react';
import Feathers from '../../../../bootstrap/feathers';

import { EmptyWrapper } from '@inkcloud/components';

import { ProductName } from '../../../../common/ProductName';

interface SelectedProductsProps {
  productIDs: string[];
  setFieldValue: (fieldName: string, value: string[]) => void;
  isRemovingProduct: boolean;
  onRemovingProduct: (v: boolean) => void;
}

const { useState, useEffect } = React;

export const SelectedProducts: React.FunctionComponent<SelectedProductsProps> = (props) => {
  const { setFieldValue, productIDs, isRemovingProduct, onRemovingProduct } = props;
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const loadProducts = async () => {
      setIsLoading(true);
      try {
        // const res: any = await Feathers.service('/products-legacy').find({
        //   query: {
        //     $populate: ['attributes.value', 'category', 'parent'],
        //     _id: productIDs
        //   }
        // })

        const res: any = productIDs?.map(async (prodId: string) =>
          Feathers.service('/products-legacy').get(prodId, {
            query: {
              $populate: ['attributes.value', 'category', 'parent'],
              _id: prodId,
            },
          })
        );

        const resAll: any = await Promise.all(res);

        setProducts(resAll);
      } catch (e) {
        console.error(e);
      }
      setIsLoading(false);
    };

    if (productIDs?.length > 0 && !isRemovingProduct) {
      loadProducts();
    }
  }, [productIDs]);

  const handleRemove = (id: string) => {
    const filtered = products?.filter((prod) => prod._id !== id);
    const filteredIDs = filtered?.map((prod) => prod._id);
    setProducts(filtered);
    setFieldValue('products', filteredIDs);
    onRemovingProduct(true);
  };

  return (
    <>
      <EmptyWrapper queryResult={products} isStateLoading={isLoading}>
        <Table size="small">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Product</Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {products?.map((prod, index) => (
              <Table.Row key={index}>
                <Table.Cell>{ProductName(prod, true)}</Table.Cell>
                <Table.Cell textAlign="right">
                  <Button
                    type="button"
                    size="tiny"
                    icon="close"
                    onClick={() => handleRemove(prod._id)}
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </EmptyWrapper>
    </>
  );
};
