import React from 'react';
import {
  Icon,
  Stack,
  StackDivider,
  Flex,
  Avatar,
  Text,
  Spacer,
  Box,
  TableContainer,
  Table,
  Tbody,
  Tr,
  Td,
  Th,
} from '@chakra-ui/react';

import { Link } from 'react-router-dom';

import { FormattedDate } from 'react-intl';

import type { PurchaseOrderType } from './PurchaseOrder';

import { SegmentCard } from '../../../common';

interface IInfoProps {
  title?: string;
  subTitle?: string;
  actionButtonLabel?: string;
  info: PurchaseOrderType;
  onEditClick: () => void;
}

export default function Info(props: IInfoProps) {
  const {
    info,
    title = 'Info',
    subTitle = 'PO Specifications',
    actionButtonLabel = 'Edit',
    onEditClick,
  } = props;

  return (
    <SegmentCard
      title={title}
      subtitle={subTitle}
      actionButtonLabel={actionButtonLabel}
      actionButtonClick={onEditClick}
    >
      <TableContainer>
        <Table size="sm">
          <Tbody>
            <Tr>
              <Th>Project Name</Th>
              <Td>{info?.projectName ?? ''}</Td>
            </Tr>
            <Tr>
              <Th>Created At</Th>
              <Td>{info?.createdAt && <FormattedDate value={info?.createdAt} />}</Td>
            </Tr>
            {/* <Tr>
              <Th>Foreign ID</Th>
              <Td>{info?.foreignId && <FormattedDate value={info?.foreignId} />}</Td>
            </Tr>
 */}
            <Tr>
              <Th>Print Consultant</Th>
              <Td>{info?.csr ?? ''}</Td>
            </Tr>
            {info?.createdFromRfq && (
              <Tr>
                <Th>RFQ</Th>
                <Td>
                  <Link to={`/rfqs/${info?.createdFromRfq}`}>{info?.rfqNum ?? ''}</Link>
                </Td>
              </Tr>
            )}
            <Tr>
              <Th>Attn</Th>
              <Td>{info?.attn ?? ''}</Td>
            </Tr>
            {/* <Tr>
              <Th>Client PO</Th>
              <Td>{info?.clientPO ?? ''}</Td>
            </Tr> */}
            <Tr>
              <Th>Sales order</Th>
              <Td>{info?.salesOrderNum ?? ''}</Td>
            </Tr>
            <Tr>
              <Th>Notes to Vendor</Th>
              <Td>{info?.notesToVendor ?? ''}</Td>
            </Tr>
            {/* <Tr>
              <Th>Due Date</Th>
              <Td>{info?.poDueDate && <FormattedDate value={info?.poDueDate} />}</Td>
            </Tr> */}
            <Tr>
              <Th>Job Delivery Date</Th>
              <Td>{info?.soShipDate && <FormattedDate value={info?.soShipDate} />}</Td>
            </Tr>
            <Tr>
              <Th>Cost Center</Th>
              <Td>{info?.costCenter ?? ''}</Td>
            </Tr>
            <Tr>
              <Th>Job Number</Th>
              <Td>{info?.jobNumber ?? ''}</Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
    </SegmentCard>
  );
}
