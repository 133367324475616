import * as React from 'react';
import { Checkbox, Radio, Input, Form, Button, Modal } from 'semantic-ui-react';
// import { Formik, Form as FormikForm, Field, FieldArray, FieldProps, ErrorMessage } from 'formik';

import { connect, getIn, Field, FieldArray, FormikProps, FieldProps } from 'formik';

import { SemanticField } from '../../../common/Form';

interface iConcatFieldProps extends Partial<FormikProps<any>> {
  type: string;
  field: any;
  index: number;
  onChange: (value: any) => void;
}

const { useState } = React;

export const ConcatField = connect(({ type, field, index }: iConcatFieldProps) => {
  const concatField = field?.concat?.[type];

  const [isOpenModal, setIsOpenModal] = useState(false);

  return (
    <>
      <Form.Group>
        <Form.Field>
          <SemanticField
            name={`fields[${index}].concat.${type}.enabled`}
            fluid
            component={Form.Checkbox}
            label={type === 'prepend' ? 'Prepend' : 'Append'}
          />
        </Form.Field>
      </Form.Group>

      {concatField?.enabled && (
        <>
          <Form.Group widths="equal">
            <Form.Field>
              <Field
                name={`fields[${index}].concat.${type}.type`}
                render={({ field }: FieldProps<any>) => (
                  <>
                    <Radio
                      label="Static"
                      name={`fields[${index}].concat.${type}.type`}
                      value="static"
                      checked={concatField?.type === 'static'}
                      onChange={(e, data) => {
                        field.onChange({ target: { value: data.value, name: field.name } } as any);
                      }}
                    />
                  </>
                )}
              />
            </Form.Field>
            <Form.Field>
              <Field
                name={`fields[${index}].concat.${type}.type`}
                render={({ field }: FieldProps<any>) => (
                  <>
                    <Radio
                      label="Select"
                      value="select"
                      checked={concatField?.type === 'select'}
                      onChange={(e, data) => {
                        setIsOpenModal(true);
                        field.onChange({ target: { value: data.value, name: field.name } } as any);
                      }}
                    />
                  </>
                )}
              />
            </Form.Field>
          </Form.Group>

          {concatField?.type === 'static' && (
            <SemanticField
              name={`fields[${index}].concat.${type}.value`}
              fluid
              component={Form.Input}
              label={type === 'prepend' ? 'Prepend Static Value' : 'Append Static Value'}
            />
          )}

          {concatField?.type === 'select' && (
            <div className="fields">
              <div className="field">
                <Button
                  type="button"
                  size="tiny"
                  content="Add select options"
                  onClick={() => setIsOpenModal(true)}
                />
              </div>
            </div>
          )}

          {isOpenModal && (
            <Modal open={isOpenModal} onClose={() => setIsOpenModal(false)} size="tiny" closeIcon>
              <Modal.Header>Select options</Modal.Header>
              <Modal.Content>
                <FieldArray
                  name={`fields[${index}].concat.${type}.selectOptions`}
                  render={(arrayHelpers) => (
                    <div className="ui form">
                      {concatField?.selectOptions?.map((option: string, idx: number) => (
                        <Form.Group widths="equal" key={idx}>
                          <Form.Field>
                            <label>Label</label>
                            <SemanticField
                              name={`fields[${index}].concat.${type}.selectOptions[${idx}].label`}
                              fluid
                              component={Form.Input}
                            />
                          </Form.Field>
                          <Form.Field>
                            <label>Value</label>
                            <SemanticField
                              name={`fields[${index}].concat.${type}.selectOptions[${idx}].value`}
                              fluid
                              component={Form.Input}
                            />
                          </Form.Field>

                          <div>
                            <div className="field">
                              <label>&nbsp;</label>
                              <Button
                                type="button"
                                icon="remove"
                                onClick={() => arrayHelpers.remove(idx)}
                              />
                            </div>
                          </div>
                        </Form.Group>
                      ))}
                      <Button
                        type="button"
                        size="tiny"
                        onClick={() => arrayHelpers.push({ value: '', label: '' })}
                        content="Add Field"
                      />
                    </div>
                  )}
                />
              </Modal.Content>
              <Modal.Actions>
                <Button
                  type="button"
                  size="tiny"
                  content="Done"
                  onClick={() => setIsOpenModal(false)}
                />
              </Modal.Actions>
            </Modal>
          )}
        </>
      )}
    </>
  );
});
