import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Button,
  Stack,
  Alert,
} from '@chakra-ui/react';

import { Select } from 'chakra-react-select';

import type { ModelTypes } from '@inkcloud/icapi-types';

import { useForm, SubmitHandler } from 'react-hook-form';
import { Feathers } from '../../../../bootstrap/feathers/feathers';

interface IRequestorModalProps {
  onChangeUserOptions: (v: { label: string; value: string }) => void;
  onChangeRequestor: (v: string) => void;
  onClose: () => void;
}

export default function RequestorModal(props: IRequestorModalProps) {
  const { onChangeUserOptions, onChangeRequestor, onClose } = props;

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<ModelTypes.User>();

  const [userRoles, setUserRoles] = useState<ModelTypes.UserRoles[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const loadUserRoles = async () => {
    try {
      const res = await Feathers.service('/user-roles').find({
        query: {
          $np: 1,
        },
      });
      setUserRoles(res?.map((r) => ({ label: r.name, value: r._id, tenant: r.tenant })));
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    loadUserRoles();
  }, []);

  const handleSave = async (value) => {
    setIsSubmitting(true);
    setErrorMessage('');

    const payload = {
      ...value,
      password: new Date(Date.now()).toISOString(),
    };

    try {
      const res = await Feathers.service('user').create(payload);

      onChangeUserOptions({
        label: `${res?.firstName ?? ''} ${res?.lastName ?? ''}`,
        value: res._id,
      });

      onChangeRequestor(res._id);

      onClose();
    } catch (e) {
      const errMsg = e?.code < 500 ? e.message : 'Something went wrong. Please try again later.';
      setErrorMessage(errMsg);
    }

    setIsSubmitting(false);
  };

  return (
    <Modal isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>New Requestor</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={7} mb={3} direction="row">
            <FormControl>
              <FormLabel>First Name</FormLabel>
              <Input {...register('firstName')} />
            </FormControl>
            <FormControl>
              <FormLabel>Last Name</FormLabel>
              <Input {...register('lastName')} />
            </FormControl>
          </Stack>
          <Stack spacing={7} mb={3} direction="row">
            <FormControl>
              <FormLabel>Email address</FormLabel>
              <Input {...register('email')} />
            </FormControl>
          </Stack>
          <Stack spacing={7} mb={3} direction="row">
            <FormControl>
              <FormLabel>Roles</FormLabel>
              <Select
                isMulti
                onChange={(val) => {
                  const values = val?.map((v) => (v as { value: string }).value);
                  setValue('roles2', values);
                }}
                options={userRoles}
                defaultValue={userRoles}
              />
            </FormControl>
          </Stack>

          {errorMessage && <Alert status="error">{errorMessage}</Alert>}
        </ModalBody>

        <ModalFooter>
          <Button isDisabled={isSubmitting} mr={3} onClick={onClose}>
            Close
          </Button>
          <Button
            isDisabled={isSubmitting}
            isLoading={isSubmitting}
            colorScheme="blue"
            onClick={handleSubmit(handleSave)}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
