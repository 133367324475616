import React from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import TableHeader from './TableHeader';
import TableRow from './TableRow';
// import InvoiceTableBlankSpace from './TableBlankSpace'

export interface ColumnDef<T> {
  key: T;
  type?: 'text' | 'image' | 'text-currency';
  style?: { [key: string]: Style };
  header?: string;
  headerStyle?: Style;
  width: Style['width'];
}

export interface ITableProps<T> {
  styles?: { [key: string]: Style };
  definition: ColumnDef<T>[];
  items: any[];
  locale: string;
  currency: string;
}

const builtInStyles = StyleSheet.create({
  container: {
    // flexDirection: 'row',
    // flexWrap: 'wrap',
    // marginTop: 24,
    // borderWidth: 0,
    // borderColor: 'none',
    // padding: 10,
    // margin: 10
  },
});

export function Table<T>(props: ITableProps<T>) {
  const { styles = {}, definition, items, locale, currency } = props;
  const { containerStyle = {} } = styles;

  return (
    <View style={[builtInStyles.container]}>
      <TableHeader definition={definition} />
      <TableRow definition={definition} items={items} locale={locale} currency={currency} />
    </View>
  );
}
