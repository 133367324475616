import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import { ColumnDef } from './Table';

const builtInStyles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    // fontSize: 12,
    paddingTop: 10,
    paddingBottom: 10,
    marginBottom: 10,
    borderTop: '1px solid #dfdfdf',
    borderBottom: '2px solid #dddddd',
    // backgroundColor: '#bff0fd',
    // borderBottomWidth: 1,
    fontWeight: 'bold',
    alignItems: 'flex-start',
    // height: 48,
    textAlign: 'left',
    // fontStyle: 'bold',
    flexGrow: 1,
  },
  image: {
    width: '10%',
    // flexWrap: 'wrap'
    // borderRightColor: borderColor,
    // borderRightWidth: 1,
  },
  sku: {
    width: '25%',
    // borderRightColor: borderColor,
    // borderRightWidth: 1,
  },
  qty: {
    width: '10%',
    // borderRightColor: borderColor,
    // borderRightWidth: 1,
  },
  name: {
    width: '25%',
    flexWrap: 'wrap',
  },
  unitPrice: {
    width: '10%',
    flexWrap: 'wrap',
    textAlign: 'right',
  },
  tax: {
    width: '10%',
    flexWrap: 'wrap',
    textAlign: 'left',
  },
  subtotal: {
    width: '10%',
    flexWrap: 'wrap',
    textAlign: 'right',
  },
  py: {
    paddingLeft: 6,
    paddingRight: 6,
  },
});

interface ITableHeader<T> {
  styles?: { [key: string]: Style | Style[] };
  definition: ColumnDef<T>[];
}

export default function TableHeader<T>(props: ITableHeader<T>) {
  const { styles = {}, definition } = props;
  const { containerStyle = {} } = styles;

  return (
    <View style={builtInStyles.container}>
      {definition?.map((d, idx) => (
        <Text key={idx} style={[d.headerStyle, { width: d.width, ...builtInStyles.py }]}>
          {d.header}
        </Text>
      ))}
    </View>
  );
}
