import React, { useState } from 'react';
// import { Modal, Table, Message, Button } from 'semantic-ui-react'

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Alert,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react';

import Feathers from '../../bootstrap/feathers';

import { PurchaseOrders, Product } from './Detail';

interface IVoidModalProps {
  onClose: () => void;
  onReload: () => void;
  selected: PurchaseOrders;
  data: PurchaseOrders;
}

export const VoidModal = (props: IVoidModalProps) => {
  const { onClose, selected, onReload, data } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');

  const handleVoid = async () => {
    setIsSubmitting(true);

    setErrorMessage('');
    try {
      const res = await Feathers.service('purchase-orders/receivings').remove(selected._id, {
        query: {},
      });
      onReload();
      onClose();
    } catch (e) {
      const errMsg =
        e?.code < 500 ? e.message : 'We are experiencing technical difficulties. Please try again';

      setErrorMessage(errMsg);
    }

    setIsSubmitting(false);
  };

  return (
    <Modal isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Are you sure you want to void this?</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <TableContainer>
            <Table size="sm">
              <Thead>
                <Tr>
                  <Th>Sku</Th>
                  <Th isNumeric>Received</Th>
                  <Th textAlign="center">Location</Th>
                </Tr>
              </Thead>
              <Tbody>
                {selected?.items?.map((item, index: number) => (
                  <Tr key={index} verticalAlign="top">
                    <Td>{(item as any)?.sku ?? ''}</Td>
                    <Td isNumeric>{item?.amount}</Td>
                    <Td textAlign="center">{(item as any)?.location?.name}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
          {errorMessage && (
            <Alert mt={3} status="error">
              {errorMessage}
            </Alert>
          )}
        </ModalBody>

        <ModalFooter>
          <Button
            isDisabled={isSubmitting}
            isLoading={isSubmitting}
            colorScheme="blue"
            mr={3}
            onClick={handleVoid}
          >
            Confirm
          </Button>
          <Button isDisabled={isSubmitting} variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>

    // <Modal
    //   open
    //   onClose={onClose}
    //   size="tiny"
    //   closeIcon>
    //   <Modal.Header>
    //     Are you sure you want to void this?
    //   </Modal.Header>
    //   <Modal.Content>
    //     <Table size="small" compact>
    //       <Table.Header>
    //         <Table.Row>
    //           <Table.HeaderCell width={4}>SKU</Table.HeaderCell>
    //           <Table.HeaderCell width={4}>Ordered</Table.HeaderCell>
    //           <Table.HeaderCell width={4}>Received</Table.HeaderCell>
    //           <Table.HeaderCell width={4}>Location</Table.HeaderCell>
    //         </Table.Row>
    //       </Table.Header>
    //       <Table.Body>
    //         {
    //           selected?.items?.map((item, index: number) => {
    //             const foundOrdered = data?.items?.find(i => i._id === (item as any).purchaseOrderItem)

    //             return (
    //               <Table.Row key={index} verticalAlign="top">
    //                 <Table.Cell>{(item?.product as Product)?.key}</Table.Cell>
    //                 <Table.Cell>{foundOrdered?.quantity}</Table.Cell>
    //                 <Table.Cell>{item?.amount}</Table.Cell>
    //                 <Table.Cell>{(item as any)?.location?.name}</Table.Cell>
    //               </Table.Row>
    //             )
    //           })
    //         }
    //       </Table.Body>
    //     </Table>

    //     {
    //       errorMessage && <Message error content={errorMessage} />
    //     }
    //   </Modal.Content>
    //   <Modal.Actions>
    //     <Button
    //       primary
    //       loading={isSubmitting}
    //       disabled={isSubmitting}
    //       content="Confirm"
    //       onClick={handleVoid}
    //     />
    //     <Button
    //       disabled={isSubmitting}
    //       content="Cancel" />
    //   </Modal.Actions>
    // </Modal>
  );
};
