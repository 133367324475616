import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { To, EmptyWrapper } from '@inkcloud/components';

import {
  Modal,
  Icon,
  Message,
  Button,
  List,
  Table,
  Input,
  Header,
  Segment,
} from 'semantic-ui-react';
import Feathers from '../../bootstrap/feathers';
import { BarcodeScanner } from './BarcodeScanner';

import PickListScannerV2 from './v2/PickListScanner';
import { ListScanner } from './ListScanner';

interface PickListScannerProps {
  user: any;
}

const { useState, useEffect, useRef } = React;

const PickListScanner: React.FunctionComponent<PickListScannerProps & RouteComponentProps> = (
  props
) => {
  const { user, history } = props;

  const [orderItem, setOrderItem] = useState('');
  const [humanId, setHumanId] = useState('');
  const [pickList, setPickList] = useState([]);
  const [temp, setTemp] = useState([]);
  const [errorMessageScanLog, setErrorMessageScanLog] = useState('');
  const [errorMessageOrder, setErrorMessageOrder] = useState('');
  const [errorMessagePickList, setErrorMessagePickList] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isItemsLoading, setIsItemsLoading] = useState(false);

  const tempSKU = useRef('');
  const tempLocation = useRef('');
  // let scanInput: any = useRef();
  let scanInput = useRef(null);
  const [rowIndicator, setRowIndicator] = useState('');

  useEffect(() => {
    let unmounted = false;

    // console.log('scanInput', scanInput);
    // if (scanInput) {
    //   (scanInput as any).current.focus();
    // }
    // BarcodeScanner.subscribe((r: string) => console.log(r));

    const load = async () => {
      // console.log('load()');
      const [error, resOrder] = await To(
        Feathers.service('/orders').find({
          query: {
            business: user.business,
            humanId,
          },
        })
      );

      // console.log('got order', resOrder);
      if (!unmounted && resOrder.data.length > 0) {
        setErrorMessageOrder('');
        setOrderItem(resOrder.data[0]._id);
        setIsItemsLoading(true);

        // console.log('about to do pack list scanner');
        const [error2, resPickList] = await To(
          Feathers.service('/pick-list/scanner').create({
            orderId: resOrder.data[0]._id,
          })
        );

        // console.log({ error2, resPickList });

        if (resPickList) {
          setIsItemsLoading(false);
          setErrorMessagePickList('');
          const sorted = resPickList.sort((a, b) => a.orderItemSuffix - b.orderItemSuffix);
          setPickList(sorted);
          setTemp(sorted);

          // console.log('scannInput', scanInput);
          // if (scanInput) {
          //   (scanInput as any).current.focus();
          //   // console.log('scannInput', scanInput);
          //   (scanInput as any).current.inputRef.current.focus();
          //   (scanInput as any).current.inputRef.current.value = '';
          // }
        } else {
          setIsItemsLoading(false);
          setErrorMessagePickList(error2.message);
        }
      } else {
        setErrorMessageOrder('No Human ID was found!');
        (scanInput as any).current.inputRef.current.value = '';
        setHumanId('');
      }
    };

    if (humanId) {
      setPickList([]);
      setTemp([]);
      setErrorMessageOrder('');
      setErrorMessageScanLog('');
      setErrorMessagePickList('');
      load();
    }

    return () => {
      // BarcodeScanner.unsubscribe()
      unmounted = true;
    };
  }, [humanId]);

  const handleScan = async (e: any) => {
    // console.log('handling scan');
    if (e.key === 'Enter') {
      // console.log('got an enter');
      const { value } = e.target;
      setErrorMessageScanLog('');

      if (!orderItem) {
        if (value.indexOf('-') !== -1) {
          setHumanId(value.split('-')[0]);
        } else {
          setHumanId(value);
        }
      } else {
        const skuIndex = pickList.findIndex((n) => n.sku === value);
        const locationIndex = pickList.findIndex((n) => n.location === value);

        // console.log('this is not order item lets deetect sku or location', {
        //   skuIndex,
        //   locationIndex,
        // });

        if (skuIndex >= 0) {
          tempSKU.current = value;
          if (!tempLocation.current) {
            const found = temp.find((t) => t.sku === value);
            setRowIndicator(found && found.orderItemId);
          }
        } else if (locationIndex >= 0) {
          tempLocation.current = value;
          if (!tempSKU.current) {
            const found = temp.find((t) => t.location === value);
            setRowIndicator(found && found.orderItemId);
          }
        } else {
          setErrorMessageScanLog('No SKU or Location found!');
        }

        if (tempSKU.current /* && tempLocation.current */) {
          const newPickList = [...temp];

          const index = newPickList.findIndex(
            (n) => n.sku === tempSKU.current /* && n.location === tempLocation.current */
          );

          if (index >= 0) {
            newPickList.splice(index, 1);
            setTemp(newPickList);
          } else {
            temp.length > 0 &&
              setErrorMessageScanLog("SKU and Location doesn't match!. Please scan again.");
          }

          // if (scanInput) {
          //   (scanInput as any).current.focus();
          //   // console.log('scannInput', scanInput);
          //   (scanInput as any).current.inputRef.current.focus();
          //   (scanInput as any).current.inputRef.current.value = '';
          // }

          setRowIndicator('');
          tempSKU.current = '';
          tempLocation.current = '';
        }
      }
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const [error, res] = await To(
      Feathers.service('/pick-list/scanner-log').create({
        order: orderItem,
        scannedBy: user._id,
        items: pickList,
        completed: true,
      })
    );

    if (!error) {
      setIsLoading(false);
      setErrorMessageScanLog('');
      setSuccessMessage('Saved!');
      setTimeout(() => {
        setSuccessMessage('');
        history.push('/');
      }, 5000);
    } else {
      setIsLoading(false);
      setErrorMessageScanLog(error.message);
    }
  };

  return (
    <Modal open={true} onClose={() => history.push('/')} closeIcon>
      <Modal.Header>Pick List Scanner</Modal.Header>
      <Modal.Content>
        <PickListScannerV2 />

      </Modal.Content>
      {/* <Modal.Content>
        <div style={{ textAlign: 'center' }}>
          <Header as="h4">Scan Pick List Barcode</Header>
          <Icon name="barcode" size="massive" />
        </div>
        {successMessage && <Message success content={successMessage} />}
        <div style={{ textAlign: 'center' }}>
          <Input size={'large'} onKeyPress={handleScan} ref={scanInput as any} />
        </div>

        {(errorMessageScanLog || errorMessageOrder || errorMessagePickList) && (
          <Message
            error
            content={errorMessageScanLog || errorMessageOrder || errorMessagePickList}
          />
        )}
        <Segment loading={isItemsLoading} style={{ border: 0, borderShadow: 'none', padding: 0 }}>
          {pickList.length > 0 && (
            <>
              <Message attached>
                <List divided verticalAlign="middle">
                  <List.Item>
                    {pickList.length > 0 && temp.length < 1 && (
                      <List.Content floated="right">
                        <Button
                          content="Save Scan"
                          loading={isLoading}
                          onClick={handleSubmit}
                          primary
                        />
                      </List.Content>
                    )}
                    <List.Content style={{ paddingTop: '.75em' }}>
                      {' '}
                      Order {humanId} - {user && `${user.firstName} ${user.lastName}`}
                    </List.Content>
                  </List.Item>
                </List>
              </Message>
              <Table attached="bottom" compact size="small">
                <Table.Body>
                  {pickList.map((p: any, index: number) => {
                    const found =
                      temp.length < pickList.length &&
                      temp.findIndex((t) => t.orderItemId === p.orderItemId);

                    const bg = rowIndicator === p.orderItemId ? '#fbfb9d' : 'none';

                    return (
                      <ListScanner key={index} bg={bg} found={found} humanId={humanId} value={p} />
                    );
                  })}
                </Table.Body>
              </Table>
            </>
          )}
        </Segment>
      </Modal.Content> */}
    </Modal>
  );
};

const mapStateToProps = (state): PickListScannerProps => ({
  user: state.auth.user,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PickListScanner as any);
