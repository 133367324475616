import * as React from 'react';
import {
  Accordion,
  Button,
  Checkbox,
  Header,
  Grid,
  Label,
  Segment,
  Table,
} from 'semantic-ui-react';
import Feathers from '../../../bootstrap/feathers';
import { RouteComponentProps, withRouter, Link, Switch, Route } from 'react-router-dom';
import { Shipping } from '../../../common';

export interface ContainerProps {}

export interface ContainerState {
  order: any;
  shipTo: Shipping.ShippingAddress;
  shipFrom: Shipping.ShippingAddress;
}

export default withRouter(
  class Container extends React.Component<
    ContainerProps & RouteComponentProps<any>,
    ContainerState
  > {
    constructor(props: ContainerProps & RouteComponentProps<any>) {
      super(props);

      this.state = {
        order: null,
        shipTo: null,
        shipFrom: null,
      };
    }

    async componentDidMount() {
      const order: any = await Feathers.service('/orders').get(this.props.match.params.id, {
        query: { $populateOrderItems: '1' },
      });

      const { shipment } = order;
      const shipFromAddress = {
        name: shipment.shipFromName,
        careOf: shipment.shipFromCareOf,
        address1: shipment.shipFromAddress1,
        address2: shipment.shipFromAddress2,
        city: shipment.shipFromCity,
        stateProvince: shipment.shipFromStateProvince,
        postalCode: shipment.shipFromPostalCode,
        countryCode: shipment.shipFromCountryCode || 'US',
      };

      const shipToAddress = {
        name: shipment.shipToName,
        careOf: shipment.shipToCareOf,
        address1: shipment.shipToAddress1,
        address2: shipment.shipToAddress2,
        city: shipment.shipToCity,
        stateProvince: shipment.shipToStateProvince,
        postalCode: shipment.shipToPostalCode,
        countryCode: shipment.shipToCountryCode || 'US',
      };

      this.setState({ ...this.setState, order, shipFrom: shipFromAddress, shipTo: shipToAddress });
    }

    handleShipToChange = (values) => {
      this.setState({ ...this.state, shipTo: values });
    };

    handleShipFromChange = (values) => {
      this.setState({ ...this.state, shipFrom: values });
    };

    public render() {
      if (!this.state.order) {
        return null;
      }

      return (
        <div>
          <Header as="h1">Ship Out Advanced</Header>
          <Grid columns="equal">
            <Grid.Column>
              <label>Ship To</label>
              <Shipping.ShippingAddressStatic address={this.state.shipTo} />
              <Shipping.ShippingAddressForm
                address={this.state.shipTo}
                onChange={this.handleShipToChange}
              />
            </Grid.Column>
            <Grid.Column>
              <label>Ship From</label>
              <Shipping.ShippingAddressStatic address={this.state.shipFrom} />
              <Shipping.ShippingAddressForm
                address={this.state.shipFrom}
                onChange={this.handleShipFromChange}
              />
            </Grid.Column>
          </Grid>
        </div>
      );
    }
  }
);
