import { Operator, generateOperatorOptions } from '@inkcloud/components';
import Feathers from '../../bootstrap/feathers';

export async function getFilterDefinition() {
  const attributeTypes: any = await Feathers.service('/products-legacy/attribute-types').find({
    query: {
      $np: 1,
      $sort: { name: 1 },
      isDeleted: { $ne: true },
      _id: { $ne: 'QUANTITY' },
    },
  });

  const attributeFilters = attributeTypes
    .filter((attr) => attr._id !== 'QUANTITY')
    .map((attr) => ({
      label: `Attribute:${attr.name}`,
      value: `attributes:${attr._id}`,
      operators: generateOperatorOptions([Operator.Is]),
      valueOptions: [],
      asyncValueOptions: async () =>
        Feathers.service('products-legacy/attributes')
          .find({
            query: {
              $np: '1',
              $sort: { name: 1 },
              entityAttribute: attr._id,
            },
          })
          .then((results) => results.map((r) => ({ value: r._id, label: r.name }))),
      type: 'multi-select',
    }));

  let definition = [
    {
      label: 'Add Ons',
      value: 'addOns',
      operators: generateOperatorOptions([Operator.Is]),
      valueOptions: [],
      asyncValueOptions: async () =>
        Feathers.service('products-legacy/add-ons-base')
          .find({
            query: {
              $sort: { name: 1 },
            },
          })
          .then((results) => results.map((r) => ({ value: r._id, label: r.name }))),
      type: 'multi-select',
    },
    {
      label: 'Approval Status',
      value: 'approvalStatus',
      operators: generateOperatorOptions([Operator.Is, Operator.IsNot]),
      valueOptions: [
        { value: 'pending', label: 'Pending' },
        { value: 'approved', label: 'Approved' },
        { value: 'rejected', label: 'Rejected' },
      ],
      type: 'select',
    },

    {
      label: 'Category',
      value: 'category',
      operators: generateOperatorOptions([Operator.Is, Operator.IsNot]),
      valueOptions: [],
      asyncValueOptions: async () =>
        Feathers.service('products-legacy/categories')
          .find({
            query: {
              $np: '1',
              $sort: { name: 1 },
            },
          })
          .then((results) => results.map((r) => ({ value: r._id, label: r.name }))),
      type: 'multi-select',
    },
    {
      label: 'Date:Created',
      value: 'createdAt',
      operators: generateOperatorOptions([Operator.Between]),
      type: 'date',
    },
    {
      label: 'Date:Updated',
      value: 'updatedAt',
      operators: generateOperatorOptions([Operator.Between]),
      type: 'date',
    },
    {
      label: 'Date:Estimated Completion Date',
      value: 'estimatedCompletionDate',
      operators: generateOperatorOptions([Operator.Between]),
      type: 'date',
    },
    {
      label: 'Date:Arrive By',
      value: 'arriveBy',
      operators: generateOperatorOptions([Operator.Between]),
      type: 'date',
    },
    {
      label: 'Product SKU',
      value: 'productKey',
      operators: generateOperatorOptions([Operator.Like, Operator.Is, Operator.IsNot]),
    },
    {
      label: 'Description',
      value: 'description',
      operators: generateOperatorOptions([Operator.Like, Operator.Is, Operator.IsNot]),
    },
    {
      label: 'Customer Reference',
      value: 'customerJobName',
      operators: generateOperatorOptions([Operator.Like, Operator.Is, Operator.IsNot]),
    },
    {
      label: 'ID',
      value: 'humanId',
      operators: generateOperatorOptions([Operator.Like, Operator.Is]),
    },
    {
      label: 'Intake Channel',
      value: 'intakeChannel',
      operators: generateOperatorOptions([Operator.Is, Operator.IsNot]),
      valueOptions: [],
      asyncValueOptions: async () =>
        Feathers.service('intake-channels')
          .find({
            query: {
              $np: '1',
              $sort: { name: 1 },
            },
          })
          .then((results) => results.map((r) => ({ value: r._id, label: r.name }))),
      type: 'multi-select',
    },

    {
      label: 'Metadata',
      value: 'metadata',
      operators: generateOperatorOptions([Operator.Is]),
      type: 'metadata',
    },

    {
      label: 'Microsite',
      value: 'microsite',
      operators: generateOperatorOptions([Operator.Is, Operator.IsNot]),
      valueOptions: [],
      asyncValueOptions: async () =>
        Feathers.service('microsites')
          .find({
            query: {
              $np: '1',
              $sort: { name: 1 },
            },
          })
          .then((results) => results.map((r) => ({ value: r._id, label: r.name }))),
      type: 'multi-select',
    },
    {
      label: 'Priority',
      value: 'isPriority',
      operators: generateOperatorOptions([Operator.Is, Operator.IsNot]),
      valueOptions: [
        { value: 'priority', label: 'Priority' },
        { value: 'not-priority', label: 'Not Priority' },
      ],
      type: 'select',
    },

    {
      label: 'Production Status',
      value: 'productionStatus.jobStatus',
      operators: generateOperatorOptions([Operator.Is, Operator.IsNot]),
      valueOptions: [],
      asyncValueOptions: async () =>
        Feathers.service('order-item-statuses')
          .find({
            query: {
              $np: '1',
              $sort: { name: 1 },
            },
          })
          .then((results) => results.map((r) => ({ value: r._id, label: r.name }))),
      type: 'multi-select',
    },
    {
      label: 'Production Channel',
      value: 'productionChannel',
      operators: generateOperatorOptions([Operator.Is, Operator.IsNot]),
      valueOptions: [],
      asyncValueOptions: async () =>
        Feathers.service('production/channels')
          .find({
            query: {
              $np: '1',
              $sort: { name: 1 },
            },
          })
          .then((results) => results.map((r) => ({ value: r._id, label: r.name }))),
      type: 'multi-select',
    },
    {
      label: 'Sales Person',
      value: 'salesPerson',
      operators: generateOperatorOptions([Operator.Is, Operator.IsNot]),
      valueOptions: [],
      asyncValueOptions: async () =>
        Feathers.service('sales-people')
          .find({
            query: {
              $np: '1',
              $sort: { name: 1 },
            },
          })
          .then((results) =>
            results.map((r) => ({ value: r._id, label: `${r.firstName} ${r.lastName}` }))
          ),
      type: 'multi-select',
    },

    {
      label: 'Sales Person:Manager',
      value: 'manager',
      operators: generateOperatorOptions([Operator.Is, Operator.IsNot]),
      valueOptions: [],
      asyncValueOptions: async () =>
        Feathers.service('sales-people')
          .find({
            query: {
              $np: '1',
              isManager: true,
              $sort: { name: 1 },
            },
          })
          .then((results) =>
            results.map((r) => ({ value: r._id, label: `${r.firstName} ${r.lastName}` }))
          ),
      type: 'multi-select',
    },

    {
      label: 'Quantity',
      value: 'quantity',
      operators: generateOperatorOptions([Operator.Is]),
      valueOptions: [],
      type: 'multi-select-create',
    },

    {
      label: 'Type',
      value: 'itemType',
      operators: generateOperatorOptions([Operator.Is, Operator.IsNot]),
      valueOptions: [
        { value: 'on-demand', label: 'On-Demand' },
        { value: 'stock', label: 'Stock' },
      ],
      type: 'select',
    },
    {
      label: 'Weight',
      value: 'weight',
      operators: generateOperatorOptions([
        Operator.Is,
        Operator.IsNot,
        Operator.GreaterThan,
        Operator.LessThan,
      ]),
    },
  ];

  definition = definition
    .concat(attributeFilters)
    .sort((a: any, b: any) => a.label.localeCompare(b.label));

  return definition;
}
