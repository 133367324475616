import * as React from 'react';
import { Switch, Route } from 'react-router-dom';

import List from './List';

export default function routes(props) {
  return (
    <Switch>
      <Route exact path="/proofs" component={List} />
    </Switch>
  );
}
