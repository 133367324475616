import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Segment } from 'semantic-ui-react';
import { PageHeaderRow, PreLoader, useFeathers } from '@inkcloud/components';
import feathers from '../../../../bootstrap/feathers';
import { VendorForm } from './VendorForm';

interface DetailProps {}

const serviceName = 'production/vendors';
const service = feathers.service(serviceName);
const commonParams = { query: {} };

export const Detail: React.FunctionComponent<DetailProps & RouteComponentProps<{ id?: string }>> = (
  props
) => {
  const {
    match: {
      params: { id },
    },
    history,
  } = props;

  const pathPrefix = `/settings/production/suppliers`;

  const isEdit = !!id;
  const [state, reload] = useFeathers('get', serviceName, [id, commonParams], true);
  const handleSubmit = async (values) => {
    if (isEdit) {
      await service.patch(id, values, commonParams);
    } else {
      await service.create(values, commonParams);
    }

    history.push(pathPrefix);
  };

  const pageTitle = isEdit ? 'Edit' : 'New';

  if (isEdit && !state.data) {
    return <PreLoader />;
  }

  const initialData = { ...state.data };
  return (
    <div>
      <PageHeaderRow header={'Suppliers'} subheader="Settings" subheaderId={''}>
        <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
      </PageHeaderRow>
      <Segment>
        {(!isEdit || state.data) && (
          <VendorForm initialValues={initialData} onSubmit={handleSubmit} />
        )}
      </Segment>
    </div>
  );
};
