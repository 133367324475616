import * as React from 'react';
import { Button, Input, Dropdown, Segment, Form } from 'semantic-ui-react';
import { FeathersDropdown } from '@inkcloud/components';
import DatePicker from 'react-datepicker';
import Feathers from './../../bootstrap/feathers';
import { values } from 'redux-form';

const { useState, useEffect } = React;

export interface FilterFormProps {
  value: any;
  onChange: Function;
  onReset: Function;
}

let attr = [];

let attrFields = [];

function initMapper(value) {
  let defaults = {
    // addOns: [],
    itemType: [],
    category: [],
    quantity: [],
    ...attrFields,
    productionStatus: [],
    customerJobName: '',
  };

  return Object.keys(defaults).reduce((p, cur) => {
    if (!p[cur]) {
      p[cur] = defaults[cur];
    }
    return p;
  }, value);
}

const itemTypeOptions = [
  { value: 'on-demand', text: 'On Demand' },
  { value: 'stock', text: 'Stock' },
];

export function FilterForm(props: FilterFormProps) {
  const [attributes, setAttributes] = useState([]);

  useEffect(() => {
    const loadAttributes = async () => {
      const result: any = await Feathers.service('/products-legacy/attributes').find({
        query: {
          $np: 1,
          $sort: { name: 1 },
          entityAttribute: { $ne: 'QUANTITY' },
          isDeleted: { $ne: true },
          $populate: ['entityAttribute'],
        },
      });
      setAttributes(result);
    };

    loadAttributes();
  }, []);

  const groupedAttributeTypes =
    attributes &&
    attributes.reduce((prev, cur) => {
      if (!prev.find((ea) => ea?._id === cur.entityAttribute?._id)) {
        prev.push(cur.entityAttribute);

        const found = attr.includes(cur.entityAttribute?._id);

        if (!found) {
          attr.push(cur.entityAttribute?._id);
          attrFields = attr.reduce((obj, item) => ((obj[item] = []), obj), {});
        }
      }

      return prev;
    }, []);

  const { value: preInitValue } = props;

  let value = initMapper(preInitValue);
  const { start, end } = value;

  const [quantities, setQuantities] = useState(value.quantity);

  const onChange = (field, value) => {
    props.onChange && props.onChange({ ...props.value, [field]: value });
  };

  const handleAddition = (e, val) => {
    const foundQty = quantities.find((q) => q.value === val.value);

    if (!foundQty) {
      setQuantities([...quantities, { text: val.value, value: val.value, key: val.value }]);
    }
  };

  return (
    <>
      <Form size={'small'}>
        <Form.Group widths={'equal'}>
          <Form.Field>
            <FeathersDropdown
              search
              selection
              clearable
              multiple
              placeholder="Category..."
              serviceName="/products-legacy/categories"
              query={{ $sort: { name: 1 }, $populate: ['entityAttributes'], $np: 1 }}
              resultMapper={(r) => ({ value: r._id, text: r.name })}
              value={value.category}
              onChange={(e, data) => onChange('category', data.value)}
            />
          </Form.Field>

          <Form.Field>
            <Input
              placeholder="Ref name..."
              value={value.customerJobName}
              onChange={(e, data) => onChange('customerJobName', data.value)}
            />
          </Form.Field>
          <Form.Field>
            <DatePicker
              dateFormat="MM/dd/yyyy"
              selected={start}
              selectsStart
              placeholderText="Start Date"
              startDate={start}
              endDate={end}
              onChange={(date) => onChange('start', date)}
            />
          </Form.Field>
          <Form.Field>
            <DatePicker
              dateFormat="MM/dd/yyyy"
              selected={end}
              selectsEnd
              placeholderText="End Date"
              startDate={start}
              endDate={end}
              onChange={(date) => onChange('end', date)}
            />
          </Form.Field>
        </Form.Group>

        <Form.Group widths="equal" style={{ flexWrap: 'wrap', marginBottom: 0 }}>
          {groupedAttributeTypes &&
            groupedAttributeTypes.map((ea: any, index: number) => (
              <Form.Field key={index} width={4} style={{ marginBottom: '1em' }}>
                {attrFields && (
                  <Dropdown
                    search
                    selection
                    clearable
                    multiple
                    options={attributes
                      .filter((a) => a?.entityAttribute?._id === ea?._id)
                      .map((c) => ({ text: c.name, value: c._id }))}
                    value={value[ea?._id]}
                    placeholder={ea?.name}
                    onChange={(e, data) => onChange(ea._id, data.value)}
                  />
                )}
              </Form.Field>
            ))}
        </Form.Group>

        <Form.Group widths={'equal'}>
          {/* <Form.Field>
            <FeathersDropdown
              search selection clearable multiple
              placeholder="Add Ons..."
              serviceName='/order-item-statuses'
              query={{ $sort: { name: 1 }, $np: 1 }}
              resultMapper={(r) => ({ value: r._id, text: r.name })}
              value={value.addOns}
              onChange={(e, data) => onChange('addOns', data.value)}
            />
          </Form.Field> */}
          <Form.Field>
            <Dropdown
              search
              selection
              clearable
              multiple
              value={value.quantity}
              allowAdditions
              placeholder="Enter Quantities"
              options={quantities}
              noResultsMessage="Enter Quantities"
              onAddItem={handleAddition}
              onChange={(e, data) => onChange('quantity', data.value)}
            />
          </Form.Field>

          <Form.Field>
            <FeathersDropdown
              search
              selection
              clearable
              multiple
              placeholder="Production Status..."
              serviceName="/order-item-statuses"
              query={{ $sort: { name: 1 }, $np: 1 }}
              resultMapper={(r) => ({ value: r._id, text: r.name })}
              value={value.productionStatus}
              onChange={(e, data) => onChange('productionStatus', data.value)}
            />
          </Form.Field>
          <Form.Field>
            <Dropdown
              search
              selection
              clearable
              multiple
              placeholder="Item Type..."
              options={itemTypeOptions}
              value={value.itemType}
              onChange={(e, data) => onChange('itemType', data.value)}
            />
          </Form.Field>
        </Form.Group>
        <Form.Field>
          <Button size={'tiny'} content="Reset Form" onClick={() => props.onReset()} />
        </Form.Field>
      </Form>
    </>
  );
}
