import * as React from 'react';
import { FormattedDate, FormattedTime } from 'react-intl';
import { Segment, Grid, Feed, Divider, Image, Icon, Message } from 'semantic-ui-react';

import { ImageModal } from './ImageModal';

interface NoteProps {
  values: any;
  name?: any;
  user: any;
  isBadger: boolean;
  userType: string;
}

const { useState } = React;

export const Note: React.FunctionComponent<NoteProps> = (props) => {
  const { values, user, userType, isBadger, name } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState('');

  const handleImageModal = (v: any) => {
    setIsOpen(!isOpen);
    setImageUrl(v);
  };

  let userName = null;

  const customerName =
    values && values.sender === 'customer' && `${values.user?.firstName} ${values.user?.lastName}`;

  if (values && values.sender === 'designer') {
    userName = `${values.user && values.user.firstName} ${values.user && values.user.lastName}`;
  } else {
    if (values.sender === 'system') {
      userName = 'System';
    } else {
      userName = customerName;
    }
  }

  let indication = null;

  if (values && values.sender === 'designer') {
    indication = 'Message to Customer';
  } else {
    if (values.sender === 'system') {
      indication = 'Initial Requirements';
    } else {
      indication = 'Message from Customer';
    }
  }

  if (values && values.isPrivate) {
    indication = 'Private Note';
  }

  if (values && values.isRevision) {
    indication = `Revision #${values.revisionOrdinal}`;
  }

  const prefixURL = 'https://storage.googleapis.com/icstorage/';

  return (
    <Segment>
      <Feed>
        <Feed.Event>
          <Feed.Label icon="user circle" />
          <Feed.Content>
            <Feed.Summary>
              <Grid>
                <Grid.Row columns={2}>
                  <Grid.Column>
                    <p>
                      <a>{userName}</a>
                    </p>
                  </Grid.Column>
                  <Grid.Column textAlign="right">
                    <p>{indication}</p>
                    <small>
                      <Feed.Date>
                        {' '}
                        <FormattedDate value={values.createdAt} />{' '}
                        <FormattedTime value={values.createdAt} />
                      </Feed.Date>
                    </small>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Feed.Summary>

            <Divider hidden />
            <p style={{ whiteSpace: 'pre-wrap' }}>{values.content}</p>
            <Image.Group size="tiny">
              {values.attachments &&
                values.attachments.map((attached: any, index: number) =>
                  attached.originalFilenameExtension === 'jpg' ||
                  attached.originalFilenameExtension === 'jpeg' ||
                  attached.originalFilenameExtension === 'png' ? (
                    <Image
                      key={index}
                      src={`${prefixURL}${attached.cloudFilename}`}
                      onClick={() => handleImageModal(`${prefixURL}${attached.cloudFilename}`)}
                      style={{ cursor: 'pointer' }}
                    />
                  ) : (
                    <a
                      key={index}
                      href={`${prefixURL}${attached.cloudFilename}`}
                      style={{ fontSize: '1rem' }}
                      target="_blank"
                    >
                      <Icon name="download" /> {attached.originalFilename}
                    </a>
                  )
                )}
            </Image.Group>
            {values.isPrivate && (
              <Message
                warning
                size={'tiny'}
                content={'This is a private message. Not visible to the customer'}
              />
            )}

            {<ImageModal isOpen={isOpen} imageUrl={imageUrl} onClose={handleImageModal} />}
          </Feed.Content>
        </Feed.Event>
      </Feed>
    </Segment>
  );
};
