import * as React from 'react';
import { access } from 'fs';
import feathers from '../../../../bootstrap/feathers';

const { useEffect, useContext, useState } = React;

interface Props {
  fields: any;
  designTemplateId: string;
  open: boolean;
  // addClick: any;
}

export const Preview = (props: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [previewUrls, setPreviewUrls] = useState(null);

  useEffect(() => {
    const loadPreview = async () => {
      setIsLoading(true);
      try {
        const res: any = await feathers.service('design-templates/preview').create({
          designTemplateId: props.designTemplateId,
          fields: props.fields.reduce((acc, f) => {
            let normalizedKey = f.key;
            if (f.type === 'image') {
              normalizedKey = `image:${f.key}`;
            } else if (f.type === 'qrcode') {
              normalizedKey = `qrcode:${f.key}`;
            }
            // const normalizedKey = f.type === 'image' ? `image:${f.key}` : f.key;
            let newValue;
            const hasPrepend = f?.prependValue;
            const hasAppend = f?.appendValue;

            const shouldClear =
              f.clearIfFieldIsEmpty &&
              f.clearIfFieldIsEmpty !== '' &&
              !!props.fields.find(
                (r) => r.key === f.clearIfFieldIsEmpty && (r.value === '' || !r.value)
              );
            if (shouldClear) {
              f.value = '';
            }

            if (f.defaultValue && (!f.value || f.value === '') && !shouldClear) {
              f.value = f.defaultValue;
            }

            if (f?.value) {
              if (f?.type === 'multi-select') {
                let valArr = f?.value?.reduce((innerAcc, cur) => {
                  if (cur.value !== 'other') {
                    innerAcc.push(cur.value);
                  }
                  return innerAcc;
                }, []);

                const hasOther = f?.value?.find((v) => v.value === 'other');

                valArr = f?.valueExtension && hasOther ? [...valArr, f.valueExtension] : valArr;
                newValue = valArr?.join(f?.multiSelectSeperator || ', ') ?? '';
              } else {
                newValue = `${hasPrepend ?? ''}${f.value}${hasAppend ?? ''}`;
              }
            }

            return { ...acc, [normalizedKey]: newValue };
          }, {}),
        });

        setIsLoading(false);
        setPreviewUrls(res.urls);
      } catch (e) {
        setIsLoading(false);
        setPreviewUrls(null);
      }
    };

    loadPreview(); // TODO: Is this being called when  modal is closed?
  }, [props.open]);

  return (
    <React.Fragment>
      <div className="cart-design-template-preview" style={{ fontSize: 13 }}>
        {isLoading && <div style={{ textAlign: 'center', padding: 15 }}>Generating Preview...</div>}
        {!isLoading && previewUrls === null && (
          <div style={{ textAlign: 'center', padding: 15 }}>Preview temporarily unavailable</div>
        )}
        {!isLoading && previewUrls !== null && (
          <div>
            {previewUrls &&
              previewUrls.map((url: any, idx: number) => (
                <div key={idx} className="card">
                  <div className="card-body">
                    <div style={{ textAlign: 'center', paddingBottom: 15 }}>
                      <img src={url} style={{ maxWidth: '100%' }} />
                    </div>
                  </div>
                </div>
              ))}
          </div>
        )}
      </div>
      <br />

      {/* { errorMessage && <div className="alert alert-danger">{errorMessage}</div> } */}

      {/* {!isLoading && <button className="btn btn-primary" onClick={() => props.addClick() }>Add to Cart</button> } */}
    </React.Fragment>
  );
};
