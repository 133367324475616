import * as React from 'react';
import {
  Button,
  Dimmer,
  Loader,
  Label,
  Icon,
  Header,
  Grid,
  Message,
  Table,
  Comment,
  Menu,
  Segment,
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { FormattedDate, FormattedNumber, FormattedRelativeTime } from 'react-intl';

export interface OrderItemCardProps extends React.PropsWithChildren {
  orderItem: any;
}

export interface OrderItemCardState {}

export default class OrderItemCard extends React.Component<OrderItemCardProps, OrderItemCardState> {
  constructor(props: OrderItemCardProps) {
    super(props);

    this.state = {};
  }

  render() {
    const oi = this.props.orderItem;
    let customerName = 'N/A';
    if (oi.customer.organizationName && oi.customer.organizationName !== '') {
      customerName = oi.customer.organizationName;
    } else {
      if (oi.customer.primaryContact) {
        customerName = `${oi.customer.primaryContact.firstName} ${oi.customer.primaryContact.lastName}`;
      }
    }

    const sourceLang = oi.metadata.find((m) => m.key === 'tr_source_name');
    const destinationLang = oi.metadata.find((m) => m.key === 'tr_dest_name');
    const langMap = (
      <div>
        <Label size={'mini'} key={sourceLang && sourceLang._id}>
          {sourceLang && sourceLang.value}
        </Label>
        <Icon name={'long arrow alternate right'} />
        <Label size={'mini'} key={destinationLang && destinationLang._id}>
          {destinationLang && destinationLang.value}
        </Label>
      </div>
    );

    const handleFile = (path: string) => {
      event.preventDefault();
      const baseUrl = `https://storage.googleapis.com/`;

      const file = `${baseUrl}${path}`;
      window.open(file, '_blank');
    };

    return (
      <Segment
        key={oi._id}
        attached="bottom"
        style={{ paddingLeft: 0, paddingRight: 0, maxHeight: 170, marginBottom: 20 }}
      >
        <Grid>
          <Grid.Row style={{ paddingTop: 0, paddingBottom: 0 }}>
            <Grid.Column width={4} style={{ paddingRight: 0 }}>
              <Table definition compact style={{ border: 0, fontSize: 11 }} size={'small'}>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>Date</Table.Cell>
                    <Table.Cell>
                      <FormattedDate value={oi.createdAt} />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Customer</Table.Cell>
                    <Table.Cell>
                      <Link to={`/customers/${oi.customer._id}`}>{customerName}</Link>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Ref</Table.Cell>
                    <Table.Cell>
                      {oi.customerJobName ? oi.customerJobName : '(Empty job name)'}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Est Completion</Table.Cell>
                    <Table.Cell>
                      {oi.estimatedCompletionDate && (
                        <div>
                          <FormattedDate value={oi.estimatedCompletionDate} />
                          {/* <Icon name="exclamation triangle" color="red" size="small"/> */}
                        </div>
                      )}
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Grid.Column>
            <Grid.Column width={5} style={{ paddingRight: 0 }}>
              <Table definition compact style={{ border: 0, fontSize: 11 }} size={'small'}>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>Translation</Table.Cell>
                    <Table.Cell>{oi.metadata && oi.metadata.length > 0 && langMap}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      File{oi.miscUploads && oi.miscUploads.length > 1 ? 's' : ''}
                    </Table.Cell>
                    <Table.Cell>
                      {oi.miscUploads &&
                        oi.miscUploads.length > 0 &&
                        oi.miscUploads.map((m: any, index: number) => (
                          <a
                            href=""
                            key={index}
                            style={{ display: 'inline-block', marginRight: '.5rem' }}
                            onClick={() => handleFile(`${m.cloudBucket}/${m.cloudFilename}`)}
                          >
                            <Icon name="linkify" /> {m.originalFilename}
                          </a>
                        ))}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Translator</Table.Cell>
                    <Table.Cell>
                      {oi.translator && oi.translator.assigned && (
                        <Link to={`/admin/translators/${oi.translator.assigned._id}`}>
                          {`${oi.translator.assigned.firstName} ${oi.translator.assigned.lastName}`}
                        </Link>
                      )}
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>Status</Table.Cell>
                    <Table.Cell>
                      {oi.productionStatus && (
                        <Label size={'mini'} content={oi.productionStatus.description} />
                      )}
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Grid.Column>

            <Grid.Column width={3}>{this.props.children}</Grid.Column>
            <Grid.Column width={3}>
              <div>
                <Comment.Group minimal size="small" style={{ overflowY: 'auto', height: 130 }}>
                  {oi.messageThread &&
                    oi.messageThread.length &&
                    oi.messageThread.map((m: any) => (
                      <Comment key={m._id} style={{ height: 35 }}>
                        <Comment.Avatar as="a" src={'https://api.adorable.io/avatars/285/'} />
                        <Comment.Content>
                          <Comment.Author as="a">
                            {m.author.type === 'tenant' ? 'LF' : 'Translator'}
                          </Comment.Author>
                          <Comment.Metadata>
                            <span>
                              <FormattedRelativeTime value={m.createdAt} />
                            </span>
                          </Comment.Metadata>
                          <Comment.Text
                            dangerouslySetInnerHTML={{ __html: m.content }}
                          ></Comment.Text>
                        </Comment.Content>
                      </Comment>
                    ))}
                </Comment.Group>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}
