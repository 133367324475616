import * as React from 'react';
import {
  Button,
  Checkbox,
  Header,
  Segment,
  Table,
  Label,
  Popup,
  List as List2,
} from 'semantic-ui-react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { FormattedDate } from 'react-intl';
import { useList, PageHeaderRow, EmptyWrapper, PaginatorWrapper } from '@inkcloud/components';
// import { SubMenu } from '../SubMenu';

interface ListProps {}

const serviceName = 'store-front/microsites/content/menus';

export const List: React.FunctionComponent<
  ListProps & RouteComponentProps<{ micrositeId: string }>
> = (props) => {
  const {
    match: { params },
    location,
    history,
  } = props;
  const {
    state,
    reload,
    handleDelete,
    selectedItems,
    clearAll,
    setChecked,
    someSelected,
    toggleAll,
    filter,
    setFilter,
    sort,
    handleSort,
  } = useList({
    feathers: [
      'find',
      serviceName,
      [
        {
          query: {
            microsite: params.micrositeId,
            $select: ['createdAt', 'name', 'key', 'menuItems'],
          },
        },
      ],
    ],
    filterMap: (v) => v,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  const { queryResult, isLoading: isStateLoading } = state as any;

  const pathPrefix = `/settings/microsites/${params.micrositeId}`;

  return (
    <div>
      <PageHeaderRow header="Menus" subheader="Microsite">
        {/* <SubMenu match={props.match} history={history} /> */}
        <Link to={pathPrefix}>
          <Button content="Back" size={'mini'} />
        </Link>
        <Link to={`${pathPrefix}/content/menus/add`}>
          <Button content="New" primary size={'mini'} />
        </Link>
      </PageHeaderRow>

      <EmptyWrapper queryResult={queryResult} isStateLoading={state.isLoading}>
        <Segment loading={state.isLoading}>
          <PaginatorWrapper.Top
            filter={filter}
            reload={reload}
            data={queryResult as any}
            pathPrefix={`${pathPrefix}/content/menus/`}
          />
          <Table size={'small'} sortable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  <input type="checkbox" onChange={toggleAll} />
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={sort.createdAt ? sort.createdAt : null}
                  onClick={() => handleSort('createdAt')}
                >
                  Created
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={sort.name ? sort.name : null}
                  onClick={() => handleSort('name')}
                >
                  Name
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={sort.key ? sort.key : null}
                  onClick={() => handleSort('key')}
                >
                  Key
                </Table.HeaderCell>
                <Table.HeaderCell>Items</Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {queryResult &&
                (queryResult as any).data.map((r) => (
                  <Table.Row key={r._id}>
                    <Table.Cell>
                      <input
                        type="checkbox"
                        value={r._id}
                        checked={selectedItems[r._id] || false}
                        onChange={setChecked}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <FormattedDate value={r.createdAt} />
                    </Table.Cell>
                    <Table.Cell>{r.name}</Table.Cell>

                    <Table.Cell>
                      <Label size="tiny" basic>
                        {r.key}
                      </Label>
                    </Table.Cell>

                    <Table.Cell>
                      <List2>
                        {r.menuItems.map((mi) => (
                          <List2.Item key={mi._id}>
                            {mi.label}
                            {mi.url ? ` - ${mi.url}` : ''}
                            <List2>
                              {mi?.menuItems?.map((sub, index) => (
                                <List2.Item key={index}>
                                  {sub.label} {sub.url ? ` - ${sub.url}` : ''}
                                </List2.Item>
                              ))}
                            </List2>
                          </List2.Item>
                        ))}
                      </List2>
                    </Table.Cell>

                    <Table.Cell textAlign="right">
                      <Popup
                        size="mini"
                        content="Edit"
                        trigger={
                          <Link
                            to={`/settings/microsites/${props.match.params.micrositeId}/content/menus/${r._id}`}
                          >
                            <Button size={'mini'} icon={'edit'} />
                          </Link>
                        }
                      />
                    </Table.Cell>
                  </Table.Row>
                ))}
            </Table.Body>
          </Table>
          <PaginatorWrapper.Bottom
            reload={reload}
            data={queryResult as any}
            pathPrefix={`${pathPrefix}/content/menus/`}
          >
            {someSelected && (
              <Button primary type="button" content="Delete" onClick={handleDelete} />
            )}
          </PaginatorWrapper.Bottom>
        </Segment>
      </EmptyWrapper>
    </div>
  );
};
