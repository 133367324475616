// import * as React from "react";
// import { Modal, Form, Button, TextArea, Input, Message } from "semantic-ui-react"
// import { Feathers } from "../../bootstrap/feathers/feathers"

// interface IEmailModalProps {
//   contact?: {
//     name: string
//     email: string
//   }
//   salesOrderId: string;
//   onClose: () => void;
// }

// export const EmailModal = (props: IEmailModalProps) => {
//   const { salesOrderId, onClose } = props

//   const [name, setName] = React.useState("");
//   const [email, setEmail] = React.useState("");
//   const [message, setMessage] = React.useState("")
//   const [errorMessage, setErrorMessage] = React.useState("")
//   const [isSubmitting, setIsSubmitting] = React.useState(false)

//   const handleSubmit = async () => {
//     setIsSubmitting(true)
//     setErrorMessage("")

//     try {
//       const payload = {
//         to: {
//           email,
//           name,
//         },
//         message,
//         salesOrderId
//       }

//       const res: any = await Feathers.service("/sales-orders/emails").create(payload)
//       onClose()
//     } catch (e) {
//       const errMsg = e?.code < 500 ? e.message : "Something went wrong. Please try again later."
//       setErrorMessage(errMsg)
//     }
//     setIsSubmitting(false)
//   }

//   return (
//     <Modal size="tiny" open={true} onClose={onClose} closeIcon>
//       <Modal.Header>Email Sales Order</Modal.Header>
//       <Modal.Content className={`ui form ${errorMessage ? 'error' : ''}`}>
//       <Form.Field>
//           <label>Name</label>
//           <Input onChange={(e, data) => setName((data as any).value)} />
//         </Form.Field>
//         <Form.Field>
//           <label>Email</label>
//           <Input onChange={(e, data) => setEmail((data as any).value)} />
//         </Form.Field>
//         <Form.Field>
//           <label>Message</label>
//           <TextArea onChange={(e, data) => setMessage((data as any).value)} />
//         </Form.Field>
//         {
//           errorMessage && <Message error>{errorMessage}</Message>
//         }
//         <Button primary onClick={handleSubmit} loading={isSubmitting}>Send Email</Button>
//       </Modal.Content>
//     </Modal>
//   )
// }

import React, { useState, useRef } from 'react';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  Textarea,
  FormControl,
  FormLabel,
  Box,
  Heading,
  Alert,
} from '@chakra-ui/react';

import { icapi } from '../../bootstrap/feathers';

interface ISendModalProps {
  onClose: () => void;
  salesOrderId: string;
  contact?: {
    name?: string;
    email?: string;
  };
  humanId: string;
}

type payLoadType = {
  to?: {
    email?: string;
    name?: string;
  };
  message?: string;
  salesOrderId: string;
};

export default function EmailModal(props: ISendModalProps) {
  const { onClose, contact, salesOrderId, humanId = '' } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const toNameRef = useRef<HTMLInputElement>(null);
  const toEmailRef = useRef<HTMLInputElement>(null);
  const messageRef = useRef<HTMLTextAreaElement>(null);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    setErrorMessage('');

    try {
      const payload: payLoadType = {
        to: {
          email: toEmailRef?.current?.value,
          name: toNameRef?.current?.value,
        },
        message: messageRef?.current?.value,
        salesOrderId,
      };

      const res = await (icapi.service('/sales-orders/emails') as any).create(payload);
      onClose();
    } catch (e) {
      const errMsg = e?.code < 500 ? e.message : 'Something went wrong. Please try again later.';
      setErrorMessage(errMsg);
    }
    setIsSubmitting(false);
  };

  return (
    <>
      <Modal isOpen={true} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Email Sales Order - {humanId}</ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            <FormControl mb={3}>
              <FormLabel>Name</FormLabel>
              <Input ref={toNameRef} defaultValue={contact?.name ?? ''} />
            </FormControl>
            <FormControl mb={3}>
              <FormLabel>Email address</FormLabel>
              <Input type="email" ref={toEmailRef} defaultValue={contact?.email ?? ''} />
            </FormControl>

            <FormControl mb={3}>
              <FormLabel>Message</FormLabel>
              <Textarea
                ref={messageRef}
                defaultValue={`Please see attached Sales Order #${humanId}`}
              ></Textarea>
            </FormControl>

            {errorMessage && (
              <Alert status="error" mb={3}>
                {errorMessage}
              </Alert>
            )}
          </ModalBody>

          <ModalFooter>
            <Button mr={3} onClick={onClose}>
              Close
            </Button>
            <Button
              colorScheme="purple"
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
              onClick={handleSubmit}
            >
              Send
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
