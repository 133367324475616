import * as React from 'react';
import { Button, Form, Message, Segment, Header, Image } from 'semantic-ui-react';
import { Formik, Form as FormikForm, Field, ErrorMessage, FieldArray, FieldProps } from 'formik';
import * as Yup from 'yup';
import { FeathersDropdown } from '@inkcloud/components';
import { SemanticField } from '../../../common/Form';
import { slugify2 as slugifyKey } from '../../../common';

import { ImageUpload } from '../products/stock-product/StockForm/ImageUpload';

interface AttributeFormProps {
  initialValues: any;
  onSubmit: Function;
  errorMessage?: string;
}

const baseUrl = 'https://storage.googleapis.com/icstorage';

export const AttributeForm: React.FC<AttributeFormProps> = (outerProps) => {
  const { errorMessage } = outerProps;
  const AttributeSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    hrCode: Yup.string().required('Required'),
    width: Yup.string()
      .nullable(true)
      .when('entityAttribute', {
        is: (value) => value === 'SIZE',
        then: Yup.string().required('Required'),
        otherwise: Yup.string(),
      }),
    height: Yup.string()
      .nullable(true)
      .when('entityAttribute', {
        is: (value) => value === 'SIZE',
        then: Yup.string().required('Required'),
        otherwise: Yup.string(),
      }),
  });

  return (
    <Formik
      initialValues={outerProps.initialValues}
      enableReinitialize={true}
      validationSchema={AttributeSchema}
      onSubmit={(values, { setSubmitting }) => {
        outerProps.onSubmit(values);
      }}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
          setFieldValue,
          setFieldTouched,
        } = props;

        const handleSlugify = (v) => setFieldValue('hrCode', slugifyKey(v));

        return (
          <FormikForm
            className={`ui form ${isSubmitting && !errorMessage ? 'loading' : ''} ${
              errorMessage ? 'error' : ''
            }`}
          >
            <Form.Group widths="equal">
              <Form.Field>
                <Field
                  name="entityAttribute"
                  render={({ field }: FieldProps<any>) => (
                    <FeathersDropdown
                      search
                      selection
                      placeholder="Types..."
                      serviceName="/products-legacy/attribute-types"
                      query={{
                        $sort: { name: 1 },
                        $np: 1,
                      }}
                      resultMapper={(r) => ({ key: r._id, value: r._id, text: `${r.name}` })}
                      {...field}
                      onChange={(e, data) => {
                        if (data.value !== 'SIZE') {
                          setFieldValue('width', null);
                          setFieldValue('height', null);
                        }

                        if (data.value !== 'PAPER') {
                          setFieldValue('thickness', null);
                        }

                        field.onChange({ target: { value: data.value, name: field.name } } as any);
                      }}
                    />
                  )}
                />
              </Form.Field>
            </Form.Group>

            {values?.entityAttribute === 'SIZE' && (
              <Form.Group widths="equal">
                <Form.Field>
                  <SemanticField name="width" label="Width" component={Form.Input} />
                  <ErrorMessage
                    render={(msg) => <Message negative size="tiny" content={msg} />}
                    name="width"
                  />
                </Form.Field>
                <Form.Field>
                  <SemanticField name="height" label="Height" component={Form.Input} />
                  <ErrorMessage
                    render={(msg) => <Message negative size="tiny" content={msg} />}
                    name="height"
                  />
                </Form.Field>
              </Form.Group>
            )}

            <Form.Group widths="equal">
              <Form.Field>
                <SemanticField name="name" label="Name" component={Form.Input} />
                <ErrorMessage
                  render={(msg) => <Message negative size="tiny" content={msg} />}
                  name="name"
                />
              </Form.Field>
              <Form.Field>
                <SemanticField
                  name="hrCode"
                  label="Key"
                  component={Form.Input}
                  onChange={handleSlugify}
                />
                <ErrorMessage
                  render={(msg) => <Message negative size="tiny" content={msg} />}
                  name="hrCode"
                />
              </Form.Field>
              <Form.Field>
                <SemanticField name="priority" label="Priority" component={Form.Input} />
              </Form.Field>
              {values?.entityAttribute === 'PAPER' && (
                <Form.Field>
                  <SemanticField name="thickness" label="Thickness" component={Form.Input} />
                  <ErrorMessage
                    render={(msg) => <Message negative size="tiny" content={msg} />}
                    name="thickness"
                  />
                </Form.Field>
              )}
            </Form.Group>

            <Form.Group widths="equal">
              <Form.Field>
                <label>Images</label>

                <FieldArray
                  name="images"
                  render={(arrayHelpers) => (
                    <div>
                      {values?.images?.length > 0 && (
                        <Segment>
                          <Image.Group size="small">
                            {values.images.map((image: any, index: number) => (
                              <div className="ui fluid image">
                                <a
                                  className="ui right corner label"
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  <i aria-hidden="true" className="times icon"></i>
                                </a>
                                <Image
                                  key={image._id}
                                  id={image._id}
                                  contentImage={image.contentImage}
                                  src={`${baseUrl}/${image.url}`}
                                />
                              </div>
                            ))}
                          </Image.Group>
                        </Segment>
                      )}

                      <ImageUpload
                        // multiple={true}
                        onUpload={(files) => {
                          const newFiles = files.map((f) => ({
                            url: f.publicUrl,
                            contentImage: f._id,
                            filename: f.filename,
                          }));

                          newFiles.forEach((f) => arrayHelpers.push(f));
                          // arrayHelpers.push(newFiles[0])
                        }}
                        btnLabel="Upload image"
                      />
                    </div>
                  )}
                />
              </Form.Field>
              <Form.Field>
                <label>Images Secondary</label>
                <FieldArray
                  name="imagesSecondary"
                  render={(arrayHelpers) => (
                    <div>
                      {values?.imagesSecondary?.length > 0 && (
                        <Segment>
                          <Image.Group size="small">
                            {values.imagesSecondary.map((image: any, index: number) => (
                              <div className="ui fluid image">
                                <a
                                  className="ui right corner label"
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  <i aria-hidden="true" className="times icon"></i>
                                </a>
                                <Image
                                  key={image._id}
                                  id={image._id}
                                  contentImage={image.contentImage}
                                  src={`${baseUrl}/${image.url}`}
                                />
                              </div>
                            ))}
                          </Image.Group>
                        </Segment>
                      )}

                      <ImageUpload
                        // multiple={true}
                        onUpload={(files) => {
                          const newFiles = files.map((f) => ({
                            url: f.publicUrl,
                            contentImage: f._id,
                            filename: f.filename,
                          }));

                          newFiles.forEach((f) => arrayHelpers.push(f));
                          // arrayHelpers.push(newFiles[0])
                        }}
                        btnLabel="Upload image"
                      />
                    </div>
                  )}
                />
              </Form.Field>
            </Form.Group>

            <Form.Group widths="equal">
              <Form.Field>
                <SemanticField name="description" label="Description" component={Form.TextArea} />
              </Form.Field>
            </Form.Group>

            <Form.Group widths="equal">
              <Form.Field>
                <SemanticField
                  name="shortDescription"
                  label="Short Description"
                  component={Form.TextArea}
                />
              </Form.Field>
            </Form.Group>

            <Form.Group widths="equal">
              <Form.Field>
                <SemanticField
                  name="productionNotes"
                  label="Production Notes"
                  component={Form.TextArea}
                />
              </Form.Field>
            </Form.Group>

            <Form.Group widths="equal">
              <Form.Field>
                <SemanticField name="color" label="Color" component={Form.Input} />
                <label>Must be in RGB hex format. ie #EAEAEA</label>
              </Form.Field>
            </Form.Group>

            <Segment>
              <Form.Field>
                <SemanticField
                  name={'hideOnStoreFront'}
                  label="Hide on Store Front"
                  component={Form.Checkbox}
                />
                <ErrorMessage component="div" name="hideOnStoreFront" />
                <div>This will hide the attribute from any built-in store front components</div>
              </Form.Field>
            </Segment>

            <Segment>
              <SemanticField
                name="isOutOfStockManual"
                label="Set out of stock manually"
                component={Form.Checkbox}
              />
            </Segment>
            <Header as="h4" attached="top">
              Metadata
            </Header>
            <Segment attached="bottom">
              {!values?.metadata?.length && (
                <Message content={'Metadata not defined. To add metadata click Add'} />
              )}
              <Form.Field>
                <FieldArray
                  name="metadata"
                  render={(arrayHelpers) => (
                    <div>
                      {values?.metadata?.map((custom, index) => (
                        <Form.Group widths="equal" key={index}>
                          <Form.Field>
                            <label>Key</label>
                            <Field name={`metadata[${index}].key`} />
                            {(errors?.metadata as any)?.[index]?.key && (
                              <Message
                                negative
                                size="tiny"
                                content={(errors?.metadata as any)?.[index]?.key}
                              />
                            )}
                          </Form.Field>
                          <Form.Field>
                            <label>Value</label>
                            <Field name={`metadata[${index}].value`} />
                          </Form.Field>
                          <div>
                            <div className="field">
                              <label>&nbsp;</label>
                              <Button
                                type="button"
                                icon="remove"
                                onClick={() => arrayHelpers.remove(index)}
                              />
                            </div>
                          </div>
                        </Form.Group>
                      ))}
                      <Button
                        type="button"
                        size="tiny"
                        content="Add"
                        onClick={() => arrayHelpers.push({ key: '' })}
                      />
                    </div>
                  )}
                />
              </Form.Field>
            </Segment>

            {errorMessage && <Message error content={errorMessage} />}
            <Button primary type="submit" disabled={isSubmitting && !errorMessage}>
              Save
            </Button>
          </FormikForm>
        );
      }}
    </Formik>
  );
};
