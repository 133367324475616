import React from 'react';
import { Icon, Stack, StackDivider, Flex, Avatar, Text, Spacer, Box } from '@chakra-ui/react';
import type { ModelTypes } from '@inkcloud/icapi-types';
import { FormattedDate, FormattedTime } from 'react-intl';
import { BiNote } from 'react-icons/bi';
import { SegmentCard } from '../../../common';

interface INoteProps {
  title: string;
  subTitle?: string;
  notes: ModelTypes.PurchaseOrder['privateNotes'] | string;
  actionButtonLabel?: string;
  onOpenNoteModal: () => void;
  maxH?: number;
}

export default function Note(props: INoteProps) {
  const {
    notes,
    title,
    maxH = 200,
    subTitle,
    actionButtonLabel = 'Add Note',
    onOpenNoteModal,
  } = props;

  const isNoteEmpty = Array.isArray(notes) ? !notes?.length : !notes;

  return (
    <SegmentCard
      title={title}
      subtitle={subTitle}
      actionButtonLabel={actionButtonLabel}
      actionButtonClick={onOpenNoteModal}
    >
      <Stack
        maxH={maxH}
        overflow="auto"
        divider={!isNoteEmpty && Array.isArray(notes) ? <StackDivider /> : <></>}
      >
        {!isNoteEmpty && Array.isArray(notes) ? (
          notes?.map((note) => {
            const name = `${(note?.createdBy as any)?.firstName ?? ''} ${
              (note?.createdBy as any)?.lastName ?? ''
            }`;

            return (
              <Flex alignItems={'center'}>
                <Avatar size={'sm'} name={name} />
                <Text
                  fontSize={'xs'}
                  // noOfLines={5}
                  // textOverflow={'ellipsis'}
                  ml={4}
                  whiteSpace="break-spaces"
                >
                  {note?.content ?? ''}
                </Text>
                <Spacer />
                <Box style={{ textAlign: 'right' }}>
                  <Text color={'gray.700'} fontSize={'xs'}>
                    <FormattedDate value={note?.createdAt} />
                  </Text>
                  <Text color={'gray.400'} fontSize={'xx-small'}>
                    <FormattedTime value={note?.createdAt} />
                  </Text>
                </Box>
              </Flex>
            );
          })
        ) : (
          <Text whiteSpace="break-spaces">{notes as string}</Text>
        )}

        {isNoteEmpty && (
          <Flex alignItems="center" justifyContent="center">
            <Box p={5} textAlign="center">
              <Icon as={BiNote} boxSize={10} />
              <Text>There aren't any notes</Text>
            </Box>
          </Flex>
        )}
      </Stack>
    </SegmentCard>
  );
}
