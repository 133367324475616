import * as React from 'react';
import { Alert, Box, Button, IconButton, Input, Stack, Textarea } from '@chakra-ui/react';
import { AsyncSelect } from 'chakra-react-select';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import FormControl from '../../../../common/v3/Form/FormControl';
import { Feathers } from '../../../../bootstrap/feathers/feathers';
import { SegmentCard } from '../../../../common';
import { CloseIcon } from '@chakra-ui/icons';

export async function loadProductOptions(e): Promise<any> {
  try {
    const res = await Feathers.service('products-legacy').find({
      query: {
        $np: 1,
        $sort: { staticName: 1 },
        $or: [{ key: { $LIKE: e } }, { staticName: { $LIKE: e } }],
        // $select: ['staticName', 'key'],
        $limit: 20,
      },
    });

    return res?.map((r) => ({
      label: `${r?.staticName ? `${r.staticName} - ` : ''} ${r?.key ?? ''}`,
      value: r?._id,
    }));
  } catch (er) {
    console.error(er);
    throw er;
  }
}
type AllottmentFormProps = {
  initialValues: any;
  onSubmit: (v: any) => void;
  isSubmitting?: boolean;
  errorMessage?: string;
};

export default function AllottmentForm(props: AllottmentFormProps) {
  const { initialValues, onSubmit, isSubmitting, errorMessage } = props;
  const methods = useForm({
    defaultValues: initialValues,
  });

  const { control, handleSubmit } = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'products',
  });

  return (
    <>
      <FormProvider {...methods}>
        <FormControl field="name" label="Name" render={(fieldProps) => <Input {...fieldProps} />} />
        <FormControl
          field="description"
          label="Description"
          render={(fieldProps) => <Textarea {...fieldProps} />}
        />

        <Box mb={3}>
          <SegmentCard title="Products" subtitle="">
            {fields.map((field, index) => (
              <Stack direction="row" key={field.id}>
                <FormControl
                  field={`products.${index}.products`}
                  label="Products"
                  render={(fieldProps) => (
                    <AsyncSelect
                      {...fieldProps}
                      loadOptions={loadProductOptions}
                      isMulti
                      isClearable
                      isSearchable
                      useBasicStyles
                      cacheOptions
                      defaultOptions
                    />
                  )}
                />

                <FormControl
                  field={`products.${index}.amount`}
                  label="amount"
                  required={true}
                  render={(fieldProps) => <Input {...fieldProps} />}
                />
                <Box>
                  <IconButton
                    mt={7}
                    aria-label="Remove"
                    icon={<CloseIcon />}
                    onClick={() => {
                      remove(index);
                    }}
                  >
                    Remove
                  </IconButton>
                </Box>
              </Stack>
            ))}

            <Button
              onClick={() => {
                append({});
              }}
            >
              Add Product
            </Button>
          </SegmentCard>
        </Box>
        <FormControl
          field="expDays"
          label="Exp Days"
          render={(fieldProps) => <Input {...fieldProps} />}
        />
        {errorMessage && (
          <Alert my={4} status="error">
            {errorMessage}
          </Alert>
        )}
        <Button
          isDisabled={isSubmitting}
          isLoading={isSubmitting}
          colorScheme="blue"
          onClick={handleSubmit(onSubmit)}
        >
          Save
        </Button>
      </FormProvider>
    </>
  );
}
