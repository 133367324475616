import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import { feathersAuthentication } from '../feathers'
import { inkcloudReducers } from './inkcloud-reducers'
import { modeReducer } from './modeReducer'
import { globalReducer } from './globalReducers'
import { filterReducers } from './filterReducers'

const reducers: any = {
  auth: feathersAuthentication.reducer, // For Feathers Reduxify Auth
  form: formReducer, // For Redux Form
  mode: modeReducer,
  globals: globalReducer,
  filters: filterReducers,
  ...inkcloudReducers, // spread in the Ink Cloud Reducers
}

export default combineReducers(reducers)
