import * as React from 'react';
import { Button, Form, Message, Menu, Segment, Dropdown } from 'semantic-ui-react';
import { Formik, Form as FormikForm, Field, FieldProps, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { PaymentAdapters } from './../../../types';
import { SemanticField } from './../../../common/Form';

import { StyleModal } from './StyleModal';

interface PaymentProcessingFormProps {
  initialValues: any;
  onSubmit: Function;
  errorMessage: string;
}

const initMapper = (values: any) => {
  const foundAdapterKey =
    values?.adapters &&
    Object.keys(values?.adapters)?.find((k) => values.selectedAdapter === values.adapters[k].key);

  const foundAdapter = values?.adapters?.[foundAdapterKey];

  return {
    ...foundAdapter,
    paypal: {
      ...(values?.adapters?.paypal || {}),
    },
    selectedAdapter: values.selectedAdapter,
  };
};

const gatewaysOptions = [
  { text: 'Authorize.net', value: PaymentAdapters.AuthorizeNet },
  { text: 'Stripe', value: PaymentAdapters.Stripe },
  { text: 'FirstData', value: PaymentAdapters.FirstData },
];

const enableFundingOptions = [
  { text: 'Card', value: 'card' },
  { text: 'PayPal Credit', value: 'credit' },
  { text: 'Pay Later', value: 'paylater' },
  { text: 'Venmo', value: 'venmo' },
  { text: 'Bancontact', value: 'bancontact' },
  { text: 'BLIK', value: 'blik' },
  { text: 'eps', value: 'eps' },
  { text: 'giropay', value: 'giropay' },
  { text: 'iDEAL', value: 'ideal' },
  { text: 'Mercado Pago', value: 'mercadopago' },
  { text: 'MyBank', value: 'mybank' },
  { text: 'Przelewy24', value: 'p24' },
  { text: 'SEPA-Lastschrift', value: 'sepa' },
  { text: 'Sofort', value: 'sofort' },
];

const styleTemplate = (object: object, key: string) => {
  const value = object?.[key?.toLocaleLowerCase()];

  if (value) {
    return (
      <div key={key}>
        {key}: {value}
      </div>
    );
  }

  return null;
};

const { useState } = React;

export const PaymentProcessingForm: React.FunctionComponent<PaymentProcessingFormProps> = (
  props
) => {
  const { errorMessage } = props;

  const [isOpenStylesModal, setIsOpenStylesModal] = useState(false);

  const PaymentProcessingchema = Yup.object().shape({});

  return (
    <Formik
      initialValues={initMapper(props.initialValues)}
      enableReinitialize={true}
      validationSchema={PaymentProcessingchema}
      onSubmit={(values, { setSubmitting }) => {
        props.onSubmit(values);
      }}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
          setFieldValue,
          setFieldTouched,
        } = props;

        return (
          <FormikForm
            className={`ui form ${props.isSubmitting && !errorMessage && 'loading'} ${
              errorMessage ? 'error' : ''
            }`}
          >
            <Form.Group widths="equal">
              <Form.Field>
                <SemanticField
                  name="selectedAdapter"
                  selection
                  component={Form.Dropdown}
                  options={gatewaysOptions}
                />
              </Form.Field>
            </Form.Group>

            {values.selectedAdapter === 'stripe' && (
              <>
                <Form.Group widths="equal">
                  <Form.Field>
                    <SemanticField name="secret" label="Secret Key" component={Form.Input} />
                  </Form.Field>
                  <Form.Field>
                    <SemanticField
                      name="publishable"
                      label="Publishable Key"
                      component={Form.Input}
                    />
                  </Form.Field>
                </Form.Group>
              </>
            )}

            {values.selectedAdapter === 'authorizeNet' && (
              <>
                <Form.Group widths="equal">
                  <Form.Field>
                    <SemanticField name="login" label="Login" component={Form.Input} />
                  </Form.Field>
                  <Form.Field>
                    <SemanticField name="clientKey" label="Client Key" component={Form.Input} />
                  </Form.Field>
                  <Form.Field>
                    <SemanticField
                      name="transaction"
                      label="Transaction Key"
                      component={Form.Input}
                    />
                  </Form.Field>
                </Form.Group>
              </>
            )}

            {values.selectedAdapter === 'firstData' && (
              <>
                <Form.Group widths="equal">
                  <Form.Field>
                    <SemanticField name="gatewayId" label="Gateway ID" component={Form.Input} />
                  </Form.Field>
                  <Form.Field>
                    <SemanticField name="password" label="Password" component={Form.Input} />
                  </Form.Field>
                </Form.Group>
              </>
            )}

            <Menu attached="top" pointing secondary>
              <Menu.Item name="Paypal" active={true} />
            </Menu>
            <Segment attached="bottom">
              <Form.Group widths="equal">
                <Form.Field>
                  <SemanticField name="paypal.clientId" label="Client ID" component={Form.Input} />
                </Form.Field>
                <Form.Field>
                  <SemanticField
                    name="paypal.enableFunding"
                    label="Enable Funding"
                    selection
                    multiple
                    component={Form.Dropdown}
                    defaultValue={[enableFundingOptions[0].value]}
                    options={enableFundingOptions}
                  />
                </Form.Field>
                <Form.Field>
                  <label>&nbsp;</label>
                  <SemanticField
                    name="paypal.isEnabled"
                    label="Is Enabled"
                    component={Form.Checkbox}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Styles</label>
                  {styleTemplate(values?.paypal?.style, 'Layout')}
                  {styleTemplate(values?.paypal?.style, 'Color')}
                  {styleTemplate(values?.paypal?.style, 'Shape')}
                  {styleTemplate(values?.paypal?.style, 'Label')}
                  <Button size="tiny" type="button" onClick={() => setIsOpenStylesModal(true)}>
                    Edit Styles
                  </Button>
                </Form.Field>
              </Form.Group>
            </Segment>

            {isOpenStylesModal && (
              <StyleModal
                isOpen={isOpenStylesModal}
                onClose={() => setIsOpenStylesModal(false)}
                values={values?.paypal?.style}
                onChange={setFieldValue}
              />
            )}

            {errorMessage && <Message error content={errorMessage} />}

            <Button primary type="submit">
              Save
            </Button>
          </FormikForm>
        );
      }}
    </Formik>
  );
};
