import * as React from 'react';
import { Page } from '@react-pdf/renderer';
import { PageSize, Orientation } from '@react-pdf/types';
import { PackingSlipContext } from './PackingSlipContext';

export interface IPackingSlipPageProps {
  children: React.ReactNode;
}

export const PackingSlipPage: React.FunctionComponent<IPackingSlipPageProps> = (props) => {
  const ctx = React.useContext(PackingSlipContext);
  const {
    config: { size, orientation },
  } = ctx;
  const { children } = props;
  return (
    <Page size={size} orientation={orientation}>
      {children}
    </Page>
  );
};
