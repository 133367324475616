import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Segment, Table, Message } from 'semantic-ui-react';
import { PageHeaderRow, PreLoader, useFeathers } from '@inkcloud/components';
import feathers from '../../../../bootstrap/feathers';
import { ThemeForm } from './ThemeForm';

import { CustomThemeForm } from './../../../microsites/custom/CustomThemeForm';

interface PreDetailProps {}

const serviceName = '/tenant-settings/theme';
const service = feathers.service(serviceName);
const commonParams = { query: { $populate: ['logo', 'favicon'] } };
const pathPrefix = `/settings/content-management`;

const { useState, useEffect } = React;

export const Detail: React.FunctionComponent<
  PreDetailProps & RouteComponentProps<{ id: string }>
> = (props) => {
  const {
    match: {
      params: { id },
    },
    history,
  } = props;

  const [errorMessage, setErrorMessage] = useState({
    theme: '',
    custom: '',
  });

  const [responseMessage, setResponseMessage] = useState({
    theme: '',
    custom: '',
  });

  const isEdit = !!id;
  const [state, reload] = useFeathers('get', serviceName, [id, commonParams], true);

  const [isSubmitting, setIsSubmitting] = useState({
    theme: false,
    custom: false,
  });
  const [isOpenModalTheme, setIsOpenModalTheme] = useState(false);

  const handleSubmit = async (values: any) => {
    const payload = {
      logo: values.logo,
      favicon: values.favicon,
      dashboardCustomLogo: values.dashboardCustomLogo,
    };

    setIsSubmitting({
      ...isSubmitting,
      theme: true,
    });
    try {
      setErrorMessage({
        ...errorMessage,
        theme: '',
      });
      await service.patch(id, payload, commonParams);
      history.push(pathPrefix);
    } catch (e) {
      setIsSubmitting({
        ...isSubmitting,
        theme: false,
      });
      e.code && e.code < 500
        ? setErrorMessage(e.message)
        : setErrorMessage({
            ...errorMessage,
            theme: 'We are experiencing technical difficulties. Please try again',
          });
    }
  };

  const handleSubmitCustom = async (values: { css: string; js: string; html: string }) => {
    setIsSubmitting({
      ...isSubmitting,
      custom: true,
    });
    setResponseMessage({
      ...responseMessage,
      custom: '',
    });
    setErrorMessage({
      ...errorMessage,
      custom: '',
    });
    try {
      const res = await feathers.service('/tenant-settings/theme/').patch(
        id,
        {
          customCSS: values.css,
          customJS: values.js,
          customHTML: values.html,
        },
        { query: {} }
      );
      reload();
      setIsSubmitting({
        ...isSubmitting,
        custom: false,
      });
      setResponseMessage({
        ...responseMessage,
        custom: 'Successfully Saved!',
      });
    } catch (e) {
      setIsSubmitting({
        ...isSubmitting,
        custom: false,
      });
      e.code && e.code < 500
        ? setErrorMessage(e.message)
        : setErrorMessage({
            ...errorMessage,
            custom: 'We are experiencing technical difficulties. Please try again',
          });
    }
  };

  if (isEdit && !state.data) {
    return <PreLoader />;
  }

  const initialData = state.data;

  return (
    <div>
      <PageHeaderRow header="Theme" subheader="Settings" subheaderId={''}>
        <Button
          content={'Edit Theme'}
          size="mini"
          onClick={() => {
            setIsOpenModalTheme(true);
            setResponseMessage({
              ...responseMessage,
              custom: '',
            });
          }}
        />
        <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
      </PageHeaderRow>

      <Segment>
        {(!isEdit || state.data) && (
          <ThemeForm
            errorMessage={errorMessage.theme}
            initialValues={initialData}
            isSubmitting={isSubmitting.theme}
            onSubmit={handleSubmit}
          />
        )}

        {isOpenModalTheme && (
          <CustomThemeForm
            isOpen={isOpenModalTheme}
            initialValue={initialData}
            onSubmit={handleSubmitCustom}
            isSubmitting={isSubmitting.custom}
            onClose={() => setIsOpenModalTheme(false)}
            responseMessage={responseMessage.custom}
            errorMessage={errorMessage.custom}
          />
        )}
      </Segment>
    </div>
  );
};
