import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Feathers from '../../../bootstrap/feathers';
import { Button, Form, Table, Message, Segment, Popup } from 'semantic-ui-react';
import { PickupLocationModal } from './PickupLocationModal';
import { PageHeaderRow } from '@inkcloud/components';

import { Heading } from './../Heading';

export interface DetailProps {}

const { useState, useEffect } = React;
export const Detail: React.FunctionComponent<DetailProps & RouteComponentProps> = (props) => {
  const {
    match: { params },
    history,
  } = props as any;
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [locations, setLocations] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  const [selectedLocation, setSelectedLocation] = useState('');

  useEffect(() => {
    const loadLocations = async () => {
      setErrorMessage('');
      try {
        setIsLoading(true);
        const res: any = await Feathers.service('/pickup-locations').find({
          query: { $np: 1, microsite: params.micrositeId },
        });
        setLocations(res);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    loadLocations();
  }, [isOpenModal, isSubmitting]);

  const handleDelete = async (id: string) => {
    setIsSubmitting(true);
    try {
      const res: any = await Feathers.service('/pickup-locations').remove(id, {
        query: {
          microsite: params.micrositeId,
        },
      });
      setIsSubmitting(false);
    } catch (e) {
      setIsSubmitting(false);
      e.code && e.code < 500
        ? setErrorMessage(e.message)
        : setErrorMessage('We are experiencing technical difficulties. Please try again');
    }
  };

  const handleToggleModal = (id: string) => {
    setIsOpenModal(!isOpenModal);
    setSelectedLocation(id);
  };

  return (
    <>
      <PageHeaderRow header={`Pickup Locations`} subheader="MICROSITE" subheaderId={''}>
        <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
        <Button primary content="Add Location" size={'mini'} onClick={() => setIsOpenModal(true)} />
      </PageHeaderRow>

      <Heading id={params.micrositeId} />
      <Segment>
        <Form loading={isLoading || isSubmitting} style={{ minHeight: '30px' }}>
          {errorMessage && <Message error content={errorMessage} />}

          {locations &&
            locations.length > 0 &&
            locations.map((l: any, index: number) => (
              <Form.Field key={index}>
                <Table basic="very" compact>
                  <Table.Body>
                    <Table.Row verticalAlign="top">
                      <Table.Cell collapsing>
                        <Popup
                          size="mini"
                          content="Delete"
                          trigger={
                            <Button
                              size="mini"
                              icon={'trash'}
                              onClick={() => handleDelete(l._id)}
                            />
                          }
                        />
                      </Table.Cell>
                      <Table.Cell>
                        {l.name}
                        <br />
                        {l.address1} <br />
                        {l.address2 ? `${l.address2}, ` : ' '}
                        {l.city} {l.stateProvince}, {l.postalCode}
                      </Table.Cell>
                      <Table.Cell textAlign="right">
                        <Popup
                          size="mini"
                          content="Edit Pick-up Location"
                          trigger={
                            <Button
                              size={'mini'}
                              icon={'edit'}
                              onClick={() => handleToggleModal(l._id)}
                            />
                          }
                        />
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Form.Field>
            ))}
        </Form>
      </Segment>

      {isOpenModal && (
        <PickupLocationModal
          isOpen={isOpenModal}
          onClose={() => {
            setIsOpenModal(false);
            setSelectedLocation('');
          }}
          selectedLocation={selectedLocation}
          id={params.micrositeId}
        />
      )}
    </>
  );
};
