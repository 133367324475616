import * as React from 'react';
import { Button, Checkbox, Header, Segment, Table, Label, Popup } from 'semantic-ui-react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { FormattedDate } from 'react-intl';
import { useList, PageHeaderRow, EmptyWrapper, PaginatorWrapper } from '@inkcloud/components';
import { FilterForm } from './FilterForm';
import Feathers from '../../../bootstrap/feathers';

import { humanize } from 'underscore.string';

interface ListProps {}

const serviceName = 'notification/template';

const filterQueryMap = (values) => {
  const filterMap: any = {
    key: values.key ? { $LIKE: values.key } : undefined,
    subject: values.subject ? { $LIKE: values.subject } : undefined,
  };

  return [].reduce(
    (m, field) => ({ ...m, [field]: values[field] ? values[field] : undefined }),
    filterMap
  );
};

const { useState, useEffect } = React;

export const List: React.FunctionComponent<ListProps & RouteComponentProps<any>> = (props) => {
  const {
    match: { params },
    location,
    history,
  } = props;
  // const { state, reload, handleDelete, selectedItems, clearAll, setChecked, someSelected, toggleAll, filter, setFilter, sort, handleSort } = useList({
  //   feathers: ['find', serviceName, [{ query: { microsite: params.micrositeId, $sort: { url: 1 }, $select: ['createdAt', 'key', 'subject', 'isMjml'] } }]],
  //   filterMap: filterQueryMap,
  //   debounce: 150,
  //   location,
  //   history,
  //   sort: { key: -1 }
  // })

  // const { queryResult, isLoading: isStateLoading } = state as any;

  const [keyList, setKeyList] = useState([]);
  const [activeList, setActiveList] = useState([]);
  const [settings, setSettings] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const loadKeyList = async () => {
      setIsLoading(true);
      try {
        const res: any = await Feathers.service('/notification/template-list').create(
          {},
          {
            query: {
              microsite: params.micrositeId,
              $np: 1,
              keysOnly: '',
            },
          }
        );
        const sorted = res.micrositeUser?.sort();
        setKeyList(sorted);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
      }
    };

    const loadSettings = async () => {
      try {
        const res: any = await Feathers.service(
          `/microsites/notification-settings/${params.micrositeId}`
        ).find({ query: {} });

        setSettings(res);
      } catch (e) {}
    };

    const loadKeyActive = async () => {
      try {
        const res: any = await Feathers.service('/notification/template').find({
          query: { microsite: params.micrositeId },
        });
        const sorted = res.data.sort((a, b) => (a.key > b.key ? 1 : b.key > a.key ? -1 : 0));
        setActiveList(sorted);
      } catch (e) {}
    };

    loadSettings();
    loadKeyActive();
    loadKeyList();
  }, []);

  // if (!queryResult) {
  //   return <PreLoader />;
  // }

  const pathPrefix = `/settings/microsites/${params.micrositeId}`;

  const findKey = (key: string) => activeList.find((active) => active.key === key);
  const isDisabled = (key: string) => settings.find((setting) => setting.templateKey === key);

  return (
    <div>
      <PageHeaderRow header="Notifications" subheader="Microsite">
        <Link to={pathPrefix}>
          <Button content="Back" size={'mini'} />
        </Link>
      </PageHeaderRow>

      <EmptyWrapper queryResult={keyList} isStateLoading={isLoading}>
        <Segment loading={isLoading}>
          <Table size={'small'} sortable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Template</Table.HeaderCell>
                <Table.HeaderCell>Description</Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {keyList?.map((r: any, index: number) => (
                <Table.Row key={index}>
                  <Table.Cell>{r?.name}</Table.Cell>
                  <Table.Cell>
                    {findKey(r?.key) ? (
                      <Label color="green">Custom</Label>
                    ) : (
                      <Label color="orange">Default</Label>
                    )}
                    {isDisabled(r?.key)?.enable === false && <Label content="Disabled" />}
                  </Table.Cell>
                  <Table.Cell>{r?.description}</Table.Cell>
                  <Table.Cell textAlign="right">
                    <Popup
                      size="mini"
                      content="Custom Templates"
                      trigger={
                        <Link
                          to={`/settings/microsites/${
                            props.match.params.micrositeId
                          }/notifications/${r?.key}${
                            findKey(r?.key) ? `/${findKey(r?.key)._id}` : ''
                          }`}
                        >
                          <Button size={'mini'} icon={'edit'} />
                        </Link>
                      }
                    />
                    <Popup
                      size="mini"
                      content="Settings"
                      trigger={
                        <Link
                          to={`/settings/microsites/${props.match.params.micrositeId}/notification-rules/${r?.key}`}
                        >
                          <Button size={'mini'} icon={'edit'} />
                        </Link>
                      }
                    />
                  </Table.Cell>
                </Table.Row>
              )) ?? null}
            </Table.Body>
          </Table>
        </Segment>
      </EmptyWrapper>
    </div>
  );
};
