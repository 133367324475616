import React from 'react';
import {
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  Icon,
  TableContainer,
} from '@chakra-ui/react';

import { RiCheckboxCircleFill, RiCheckboxBlankCircleLine, RiCloseCircleLine } from 'react-icons/ri';
import {
  CheckCircleIcon,
  NotAllowedIcon,
  AttachmentIcon,
  DragHandleIcon,
  CloseIcon,
  SmallCloseIcon,
} from '@chakra-ui/icons';

import { Select } from 'chakra-react-select';

import { ProductSelect, NumberInput, TextArea } from './Inputs';

import { ISemanticOptions } from '../primitives';

import { Card } from '../../../../common/Card';

const styleTd: React.CSSProperties = {
  borderRight: '1px solid #eaeaea',
  verticalAlign: 'top',
};

export interface ILineItemRowProps {
  lineItems: any;
  value: {
    id?: string;
    isCreatedFromRfq?: boolean;
    product?: any | string;
    description: string;
    quantity: number;
    amount: number;
    receivedOrShipped?: number;
    isTaxable: boolean;
    glAccount: string;
    taxRate: number;
    total: number;
  };
  onChange: (value: any) => void;
  onReOrder: (value: any) => void;
  onRemove: () => void | null;
  productLoad: (inputValue: string) => Promise<{ label: string; value: string }[]>;
  onAddRow?: () => void | null;
  isDisabled?: boolean;
  isGeneralLedgerHidden?: boolean;
  hideTax?: boolean;
  index: number;
  glAccounts: Array<{ label: string; value: string }>;
  mode?: 'purchase-order' | 'sales-order';
}

export const rowStyle: React.CSSProperties = {
  display: 'flex',
  minHeight: 35,
  // flex: 1,
  border: '1px solid #eaeaea',
  alignItems: 'stretch',
  alignContent: 'stretch',
  gap: 0,
};

const taxStyle: React.CSSProperties = {
  border: '1px solid #eaeaea',
  // flexGrow: 1,
  width: 100,
  // borderRight: '1px solid #eaeaea',
  padding: 2,
  textAlign: 'right',
};

export function LineItemRow(props: ILineItemRowProps) {
  const {
    lineItems,
    index,
    value,
    onReOrder,
    onChange,
    onRemove,
    productLoad,
    onAddRow,
    glAccounts,
    isDisabled = false,
    hideTax = false,
    isGeneralLedgerHidden = false,
    mode,
  } = props;

  let formattedTotal: any = value.total;
  if (`${Number(formattedTotal)}` !== `${Number(formattedTotal).toFixed(2)}`) {
    formattedTotal = Number(formattedTotal).toFixed(2);
  }

  const handleReOrder = (dragIndex: number, hoverIndex: number) => {
    const newLineItems = [...lineItems];

    const element = newLineItems[dragIndex];
    newLineItems.splice(dragIndex, 1);
    newLineItems.splice(hoverIndex, 0, element);

    onReOrder(newLineItems);
  };

  const handleTabPress = (e) => {
    if (onAddRow) {
      if (e.key === 'Tab') {
        // e.preventDefault();

        onAddRow();
      }
    }
  };

  const iconTaxable = value.isTaxable ? RiCheckboxCircleFill : RiCheckboxBlankCircleLine;
  // console.log({ value })

  return (
    <>
      {!isDisabled && (
        <Card
          index={index}
          move={isDisabled ? () => null : handleReOrder}
          as="td"
          style={{ borderRight: '1px solid #eaeaea', textAlign: 'center' }}
        >
          <DragHandleIcon />
        </Card>
      )}
      <Td style={{ ...styleTd, minWidth: '175px' }}>
        <ProductSelect
          value={value.product}
          onChange={(v) => {
            let newValue;

            // console.log('v', v)
            if (v === null) {
              newValue = {
                ...value,
                product: '',
              };
            } else {
              newValue = {
                ...value,
                product: v,
                description: value?.description || v.name,
                amount: value.isCreatedFromRfq ? value?.amount : v.amount,
                quantity: !value.quantity ? 1 : value.quantity,
                isTaxable: true,
                prices: v.prices,
              };
            }
            newValue.total = newValue.amount; /* * newValue.quantity */
            onChange(newValue);
          }}
          isDisabled={isDisabled}
          productLoad={productLoad}
        />
      </Td>
      <Td style={styleTd}>
        <TextArea
          value={value.description}
          onChange={(v) => onChange({ ...value, description: v })}
          isDisabled={isDisabled}
        />
      </Td>
      {!isGeneralLedgerHidden && (
        <Td style={{ ...styleTd, minWidth: '150px' }}>
          <Select
            size={'sm'}
            useBasicStyles
            isDisabled={isDisabled}
            options={glAccounts}
            value={glAccounts.find(
              (g) => g.value === (value?.product?.glAccount || value?.glAccount)
            )}
            onChange={(e) => onChange({ ...value, glAccount: e?.value })}
          />
          {/* <Dropdown
          searchable
          clearable
          selection
          options={glAccounts}
          error={!value?.glAccount}
          disabled={isDisabled}
          value={value?.product?.glAccount || value?.glAccount}
          onChange={(e, data) => onChange({ ...value, glAccount: data.value })}
        /> */}
        </Td>
      )}

      <Td style={{ ...styleTd, maxWidth: '45px' }}>
        <NumberInput
          value={value.quantity}
          isMoney={false}
          onChange={(v) => {
            // const foundAmount = value?.product?.prices?.find((p) => p.unitValue === Number(v))
            // let { amount = 0 } = value

            // if (!value?.isCreatedFromRfq && foundAmount) {
            //   amount = mode === 'purchase-order' ? foundAmount.cost : foundAmount.price
            // } else {
            //   amount = value?.isCreatedFromRfq ? value.amount : value?.product?.amount || 0
            // }

            // console.log('amount', amount)

            onChange({ ...value, quantity: v });
          }}
          isDisabled={isDisabled}
        />
      </Td>
      {/* <Td style={{ ...styleTd, width: '45px' }} isNumeric>
        {value.receivedOrShipped}
      </Td> */}
      <Td style={{ ...styleTd, width: '45px' }} isNumeric>
        <NumberInput
          value={value.amount}
          isMoney={true}
          onChange={(v) => {
            onChange({ ...value, amount: v, total: Number(v) });
          }}
          isDisabled={isDisabled}
        />
      </Td>
      {/* <Td style={{ ...styleTd, width: '45px' }} isNumeric>
        {formattedTotal}
      </Td> */}
      {!hideTax && (
        <Td style={styleTd} textAlign="center">
          <Icon
            style={{ cursor: isDisabled ? 'default' : 'pointer' }}
            onClick={() => onChange({ ...value, isTaxable: !value.isTaxable })}
            as={iconTaxable}
            w={5}
            h={5}
            color="blue.500"
          />
        </Td>
      )}
      {!isDisabled && (
        <Td style={{ ...styleTd, maxWidth: '20px', borderRight: undefined, textAlign: 'center' }}>
          <Icon as={SmallCloseIcon} w={5} h={5} style={{ cursor: 'pointer' }} onClick={onRemove} />
        </Td>
      )}
    </>
  );
}
