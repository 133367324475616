import * as React from 'react';
import { Menu, Header, Segment, Grid, List, Divider } from 'semantic-ui-react';
import { PageHeaderRow } from '@inkcloud/components';
import { Switch, Route, Link, RouteComponentProps } from 'react-router-dom';

import { SlackInstallButton } from '../admin/SlackInstallButton';
import QBInstallButton from '../admin/QBInstallButton';
import XeroInstallButton from '../admin/XeroInstallButton';

import General from './general';
import PaymentProcessing from './payment-processing/IndexComponent';
import SalesTax from './sales-tax';
import ShippingCarriers from './shipping-carriers/IndexComponent';
import CarrierAccount from './carrier-account';
import Calculator from './calculator';

import ContactInformation from './contact';
import Branding from './branding';
import Localization from './localization';
import PickupLocations from './pickup-locations';

import Shipping from './shipping-settings';

import PaymentOptions from './payment-options';

import TeamMembers from './team-members';
import MemberRoles from './member-roles';
import GLAccounts from './gl-accounts';
import SalesTeam from './sales-team';

import ProductionChannels from './production/production-channels';
import WorkflowStations from './production/workflow-stations';
import MiscDocuments from './production/misc-documents';
import Suppliers from './production/suppliers';
import Resources from './production/resources';

import ContentManagement from './content-management';
import ContentManagementPages from './content-management/pages';
import ContentManagementImageManager from './content-management/image-manager';
import ContentManagementBanners from './content-management/banners';
import ContentManagementMenus from './content-management/menus';
import ContentManagementTheme from './content-management/theme';

import Cart from './cart';
import NotificationRules from './notification-rules';
import NotificationTemplates from './notification-templates';

import StarterTemplates from './starter-templates';

import PromoCodes from './promo-code/codes';
import PromoCodeCampaigns from './promo-code/campaigns';

import Imports from './imports';
import IntakeChannels from './intake-channels';

import TurnaroundHolidays from './turnaround-holidays';

import FulfillmentStagingLocation from './fulfillment-staging-locations';

import Production from './production';

import PriceLists from './price-lists';

import WebhookSubscriptions from './webhook-subscriptions';
import Printables from './printables';

import DataViews from './data-views';

import Labels from './labels';

// const SettingsMenu = (props) => {
//   return (
//     <Menu pointing secondary vertical>
//       <Link style={{ color: '#21ba45' }} to="/settings/general">
//         <Menu.Item name='General' active={props.pathname === '/settings/general'} />
//       </Link>
//       <Link style={{ color: '#21ba45' }} to="/settings/shipping">
//         <Menu.Item name='Shipping' active={props.pathname === '/settings/shipping'} />
//       </Link>
//       <Link style={{ color: '#21ba45' }} to="/settings/payment">
//         <Menu.Item name='Payment' active={props.pathname === '/settings/payment'} />
//       </Link>
//       <Link style={{ color: '#21ba45' }} to="/settings/sales-tax">
//         <Menu.Item name='Sales Tax' active={props.pathname === '/settings/sales-tax'} />
//       </Link>
//       <Link style={{ color: '#21ba45' }} to="/settings/calculator">
//         <Menu.Item name='Calculator' active={props.pathname === '/settings/calculator'} />
//       </Link>
//     </Menu>
//   );
// };

// const Routes = (props) => (
//   <Switch>
//     <Route exact path='/settings/general' component={General} />
//     <Route exact path='/settings/payment' component={Payment} />
//     <Route exact path='/settings/sales-tax' component={SalesTax} />
//     <Route exact path='/settings/shipping' component={Shipping} />
//     <Route exact path='/settings/calculator' component={Calculator} />
//   </Switch>
// );

const IndexComponent = (props) => (
  <div>
    <PageHeaderRow header={`Settings`} subheader="" subheaderId={''} />
    <Segment padded="very">
      <Grid stackable columns={3}>
        <Grid.Column>
          <Header as="h5" color="grey">
            YOUR BUSINESS
          </Header>
          <Divider hidden />
          <List relaxed="very">
            <List.Item>
              <List.Header as="h3">
                <Link style={{ color: '#21ba45' }} to="/settings/contact-information">
                  Contact Information
                </Link>
              </List.Header>
              <List.Description>
                Address, phone, email and other public contact information
              </List.Description>
            </List.Item>
            {/* <List.Item>
              <List.Header as='h3'><Link style={{ color: '#21ba45' }} to="/settings/branding">Branding</Link></List.Header>
              <List.Description>
                Address, phone, email and other public contact information
              </List.Description>
            </List.Item> */}
            <List.Item>
              <List.Header as="h3">
                <Link style={{ color: '#21ba45' }} to="/settings/localization">
                  Localization
                </Link>
              </List.Header>
              <List.Description>Country, Currency and Locale settings</List.Description>
            </List.Item>
            <List.Item>
              <List.Header as="h3">
                <Link style={{ color: '#21ba45' }} to="/settings/team-members">
                  Team Members
                </Link>
              </List.Header>
              <List.Description>Manage team members</List.Description>
            </List.Item>
            <List.Item>
              <List.Header as="h3">
                <Link style={{ color: '#21ba45' }} to="/settings/member-roles">
                  Member Roles
                </Link>
              </List.Header>
              <List.Description>Define user permission roles</List.Description>
            </List.Item>
            <List.Item>
              <List.Header as="h3">
                <Link style={{ color: '#21ba45' }} to="/settings/sales-team">
                  Sales Team
                </Link>
              </List.Header>
              <List.Description>Add and manage sales team</List.Description>
            </List.Item>
            <List.Item>
              <List.Header as="h3">
                <Link style={{ color: '#21ba45' }} to="/settings/gl-accounts">
                  GL Accounts
                </Link>
              </List.Header>
              <List.Description>Define GL accounts for accounting</List.Description>
            </List.Item>
            <List.Item>
              <List.Header as="h3">
                <Link style={{ color: '#21ba45' }} to="/settings/intake-channels">
                  Intake Channels
                </Link>
              </List.Header>
              <List.Description>Define Intake Channels</List.Description>
            </List.Item>
            <List.Item>
              <List.Header as="h3">
                <Link style={{ color: '#21ba45' }} to="/settings/price-lists">
                  Price Lists
                </Link>
              </List.Header>
              <List.Description>Define Customer Price Lists</List.Description>
            </List.Item>
          </List>
          <SlackInstallButton /> <br />
          <QBInstallButton /> <br />
          <XeroInstallButton />
          {/* <Divider hidden />
          <Header as='h5' color='grey'>INVENTORY</Header>
          <Divider hidden />
          <List>
            <List.Item>
              <List.Header as='h3'><Link style={{ color: '#21ba45' }} to="/settings/low-inventory-notifications">Low Inventory Notifcations</Link></List.Header>
              <List.Description>
                Default settings for low inventory notifications
              </List.Description>
            </List.Item>
          </List> */}
        </Grid.Column>

        <Grid.Column>
          <Header as="h5" color="grey">
            SHIPPING AND PICKUP
          </Header>
          <Divider hidden />
          <List relaxed="very">
            <List.Item>
              <List.Header as="h3">
                <Link style={{ color: '#21ba45' }} to="/settings/pickup-locations">
                  Pickup Locations
                </Link>
              </List.Header>
              <List.Description>
                Set up pick up locations with addresses and define pickup times
              </List.Description>
            </List.Item>

            <List.Item>
              <List.Header as="h3">
                <Link style={{ color: '#21ba45' }} to="/settings/shipping-carriers">
                  Shipping Carriers
                </Link>
              </List.Header>
              <List.Description>
                Control carrier integrations that can ge used to get shipping rates, print labels
                and track packages.
              </List.Description>
            </List.Item>

            <List.Item>
              <List.Header as="h3">
                <Link style={{ color: '#21ba45' }} to="/settings/carrier-account">
                  Carrier Account and Settings
                </Link>
              </List.Header>
              <List.Description>
                {/* Control carrier integrations that can ge used to get shipping rates, print labels and track packages. */}
              </List.Description>
            </List.Item>

            <List.Item>
              <List.Header as="h3">
                <Link style={{ color: '#21ba45' }} to="/settings/shipping">
                  Shipping Settings
                </Link>
              </List.Header>
              <List.Description>
                Setup rules for combined shipping logic, blind shipping behavior and more
              </List.Description>
            </List.Item>
          </List>

          <Divider hidden />
          <Header as="h5" color="grey">
            PAYMENT AND SALES TAX
          </Header>
          <Divider hidden />
          <List relaxed="very">
            <List.Item>
              <List.Header as="h3">
                <Link style={{ color: '#21ba45' }} to="/settings/payment-processing">
                  Payment Processing
                </Link>
              </List.Header>
              <List.Description>Update payment processing settings and API keys</List.Description>
            </List.Item>
            <List.Item>
              <List.Header as="h3">
                <Link style={{ color: '#21ba45' }} to="/settings/payment-options">
                  Payment Options
                </Link>
              </List.Header>
              <List.Description>Control COD and other payment options</List.Description>
            </List.Item>
            <List.Item>
              <List.Header as="h3">
                <Link style={{ color: '#21ba45' }} to="/settings/sales-tax">
                  Sales Tax
                </Link>
              </List.Header>
              <List.Description>Define tax nexuses and set rates</List.Description>
            </List.Item>
          </List>

          <Divider hidden />
          <Header as="h5" color="grey">
            OTHER
          </Header>
          <Divider hidden />

          <List relaxed="very">
            <List.Item>
              <List.Header as="h3">
                <Link style={{ color: '#21ba45' }} to="/settings/notification-rules">
                  Notification Rules
                </Link>
              </List.Header>
              <List.Description>
                Control extra recipients for certain notifications
              </List.Description>
            </List.Item>
            <List.Item>
              <List.Header as="h3">
                <Link style={{ color: '#21ba45' }} to="/settings/notification-templates">
                  Notification Templates
                </Link>
              </List.Header>
              <List.Description>
                Override and customize email notification templates
              </List.Description>
            </List.Item>

            <List.Item>
              <List.Header as="h3">
                <Link style={{ color: '#21ba45' }} to="/settings/imports">
                  Imports
                </Link>
              </List.Header>
              <List.Description>
                Import and update products, categories, and attributes
              </List.Description>
            </List.Item>

            <List.Item>
              <List.Header as="h3">
                <Link style={{ color: '#21ba45' }} to="/settings/webhook-subscriptions">
                  Webhook Subscriptions
                </Link>
              </List.Header>
              <List.Description>
                Setup webhooks to notify external systems of events
              </List.Description>
            </List.Item>
            <List.Item>
              <List.Header as="h3">
                <Link style={{ color: '#21ba45' }} to="/settings/printable-docs-templates">
                  Printable Docs Template
                </Link>
              </List.Header>
              <List.Description>Manage your printable document templates</List.Description>
            </List.Item>
            <List.Item>
              <List.Header as="h3">
                <Link style={{ color: '#21ba45' }} to="/settings/turnaround-holidays">
                  Turnaround Holidays
                </Link>
              </List.Header>
              <List.Description>Turnaround Holidays</List.Description>
            </List.Item>
            <List.Item>
              <List.Header as="h3">
                <Link style={{ color: '#21ba45' }} to="/rules-engine">
                  Rules Engines
                </Link>
              </List.Header>
              <List.Description>Define business rules</List.Description>
            </List.Item>
            <List.Item>
              <List.Header as="h3">
                <Link style={{ color: '#21ba45' }} to="/settings/labels">
                  Labels
                </Link>
              </List.Header>
              <List.Description>Labels</List.Description>
            </List.Item>
          </List>
        </Grid.Column>

        <Grid.Column>
          <Header as="h5" color="grey">
            STORE FRONT
          </Header>
          <Divider hidden />

          <List relaxed="very">
            {/* <List.Item>
              <List.Header as='h3'><Link style={{ color: '#21ba45' }} to="/settings/access-policy">Access Policy</Link></List.Header>
              <List.Description>
                Set site access and sign up policies
              </List.Description>
            </List.Item> */}
            <List.Item>
              <List.Header as="h3">
                <Link style={{ color: '#21ba45' }} to="/settings/cart">
                  Cart Settings
                </Link>
              </List.Header>
              <List.Description>Control cart features such as Save for Later</List.Description>
            </List.Item>
            {/* <List.Item>
              <List.Header as='h3'><Link style={{ color: '#21ba45' }} to="/settings/analytics">Analytics</Link></List.Header>
              <List.Description>
                Update Google Analytics, Mixpanel, and Segment integrations
              </List.Description>
            </List.Item> */}
            <List.Item>
              <List.Header as="h3">
                <Link style={{ color: '#21ba45' }} to="/settings/content-management">
                  Content Management
                </Link>
              </List.Header>
              <List.Description>Update page content</List.Description>
            </List.Item>
            <List.Item>
              <List.Header as="h3">
                <Link style={{ color: '#21ba45' }} to="/settings/general">
                  General
                </Link>
              </List.Header>
              <List.Description>General Settings</List.Description>
            </List.Item>
            <List.Item>
              <List.Header as="h3">
                <Link style={{ color: '#21ba45' }} to="/settings/general/order-notes">
                  Order Notes
                </Link>
              </List.Header>
              <List.Description>Order Notes Notification Recipients</List.Description>
            </List.Item>
            <List.Item>
              <List.Header as="h3">
                <Link style={{ color: '#21ba45' }} to="/settings/starter-templates">
                  Starter Templates
                </Link>
              </List.Header>
              <List.Description>Starter Templates</List.Description>
            </List.Item>
            <List.Item>
              <List.Header as="h3">
                <Link style={{ color: '#21ba45' }} to="/settings/promo/codes">
                  Promo code
                </Link>
              </List.Header>
              <List.Description>Promo Code</List.Description>
            </List.Item>
          </List>

          <Divider hidden />
          <Header as="h5" color="grey">
            PRODUCTION
          </Header>
          <Divider hidden />

          <List relaxed="very">
            <List.Item>
              <List.Header as="h3">
                <Link style={{ color: '#21ba45' }} to="/settings/production/suppliers">
                  Suppliers
                </Link>
              </List.Header>
              <List.Description>Manage Suppliers</List.Description>
            </List.Item>

            <List.Item>
              <List.Header as="h3">
                <Link style={{ color: '#21ba45' }} to="/settings/production/production-channels">
                  Production Channels
                </Link>
              </List.Header>
              <List.Description>
                Definite production channels for use in workflow rules
              </List.Description>
            </List.Item>

            <List.Item>
              <List.Header as="h3">
                <Link style={{ color: '#21ba45' }} to="/settings/production/workflow-stations">
                  Workflow Stations
                </Link>
              </List.Header>
              <List.Description>
                Manage stations in your workflow for scanning and job status automation
              </List.Description>
            </List.Item>

            <List.Item>
              <List.Header as="h3">
                <Link style={{ color: '#21ba45' }} to="/settings/production/misc-documents">
                  Misc Documents
                </Link>
              </List.Header>
              <List.Description>
                Manage documents that can be used in workflow and fulfillment
              </List.Description>
            </List.Item>

            <List.Item>
              <List.Header as="h3">
                <Link style={{ color: '#21ba45' }} to="/settings/production/resources">
                  Resources
                </Link>
              </List.Header>
              <List.Description>Manage production resources</List.Description>
            </List.Item>

            <List.Item>
              <List.Header as="h3">
                <Link style={{ color: '#21ba45' }} to="/settings/fulfillment-staging-locations">
                  Fulfillment Staging Locations
                </Link>
              </List.Header>
              <List.Description>Manage fulfillment staging locations</List.Description>
            </List.Item>

            <List.Item>
              <List.Header as="h3">
                <Link style={{ color: '#21ba45' }} to="/settings/data-views">
                  Data Views
                </Link>
              </List.Header>
              <List.Description>Manage commonly used data views</List.Description>
            </List.Item>
          </List>
        </Grid.Column>
      </Grid>
    </Segment>
  </div>
);

const routes = (props) => (
  <Switch>
    <Route exact path="/settings" component={IndexComponent} />
    <Route path="/settings/contact-information" component={ContactInformation} />
    <Route path="/settings/branding" component={Branding} />
    <Route path="/settings/localization" component={Localization} />
    <Route path="/settings/team-members" component={TeamMembers} />
    <Route path="/settings/member-roles" component={MemberRoles} />
    <Route path="/settings/gl-accounts" component={GLAccounts} />
    <Route path="/settings/sales-team" component={SalesTeam} />

    <Route path="/settings/pickup-locations" component={PickupLocations} />
    <Route path="/settings/shipping-carriers" component={ShippingCarriers} />
    <Route path="/settings/carrier-account" component={CarrierAccount} />
    <Route path="/settings/shipping" component={Shipping} />

    <Route path="/settings/turnaround-holidays" component={TurnaroundHolidays} />
    <Route path="/settings/notification-rules" component={NotificationRules} />
    <Route path="/settings/notification-templates" component={NotificationTemplates} />
    <Route path="/settings/imports" component={Imports} />
    <Route path="/settings/webhook-subscriptions" component={WebhookSubscriptions} />
    <Route path="/settings/intake-channels" component={IntakeChannels} />

    <Route path="/settings/payment-options" component={PaymentOptions} />

    <Route path="/settings/payment-processing" component={PaymentProcessing} />

    <Route path="/settings/sales-tax" component={SalesTax} />

    <Route path="/settings/production/production-channels" component={ProductionChannels} />
    <Route path="/settings/production/workflow-stations" component={WorkflowStations} />
    <Route path="/settings/production/misc-documents" component={MiscDocuments} />
    <Route path="/settings/production/suppliers" component={Suppliers} />
    <Route path="/settings/production/resources" component={Resources} />

    <Route path="/settings/cart" component={Cart} />

    <Route path="/settings/content-management" component={ContentManagement} />
    <Route path="/settings/content-management/pages" component={ContentManagementPages} />
    <Route
      path="/settings/content-management/image-manager"
      component={ContentManagementImageManager}
    />
    <Route path="/settings/content-management/banners" component={ContentManagementBanners} />
    <Route path="/settings/content-management/menus" component={ContentManagementMenus} />
    <Route path="/settings/content-management/theme" component={ContentManagementTheme} />
    <Route path="/settings/general" component={General} />

    <Route path="/settings/starter-templates" component={StarterTemplates} />

    <Route path="/settings/promo/codes" component={PromoCodes} />
    <Route path="/settings/promo-codes/campaigns" component={PromoCodeCampaigns} />

    <Route path="/settings/fulfillment-staging-locations" component={FulfillmentStagingLocation} />

    <Route path="/settings/price-lists" component={PriceLists} />
    <Route path="/settings/printable-docs-templates" component={Printables} />

    <Route path="/settings/data-views" component={DataViews} />
    <Route path="/settings/labels" component={Labels} />
  </Switch>
);

export default routes;
