import * as React from 'react';
import { Link } from 'react-router-dom';
import { Button, Segment } from 'semantic-ui-react';
import { RouteComponentProps } from 'react-router-dom';
import { PageHeaderRow, PreLoader, useFeathers } from '@inkcloud/components';
import Feathers from './../../../bootstrap/feathers';
import { TestimonialForm } from './TestimonialForm';

import { connect } from 'react-redux';

export interface ConnectedProps {
  user?: any;
}

interface DetailProps {}

const serviceName = '/store-front/content/testimonials';
const pathPrefix = `/customers/testimonials`;
const service = Feathers.service(serviceName);
const commonParams = {
  query: {
    $populate: ['customer'],
  },
};

const { useState } = React;

const PreDetail: React.FunctionComponent<
  DetailProps & RouteComponentProps<{ id?: string }> & ConnectedProps
> = (props) => {
  const {
    match: {
      params: { id },
    },
    history,
    user,
  } = props;

  const [errorMessage, setErrorMessage] = useState({
    submit: '',
  });

  const isEdit = !!id;
  const [state, reload] = useFeathers('get', serviceName, [id, commonParams], true);

  const handleSubmit = async (values: any) => {
    setErrorMessage({ ...errorMessage, submit: '' });

    const payload = {
      ...values,
      tenant: user?.business,
      customerName: `${values?.firstName ?? ''} ${values?.lastName ?? ''}`,
    };
    try {
      const res = isEdit
        ? await service.patch(id, payload, commonParams)
        : await service.create(payload, commonParams);

      if (res.result !== undefined && !res.result) {
        setErrorMessage(res.message || res.errorMessage);
      }

      history.push(pathPrefix);
    } catch (e) {
      const errMsg =
        e.code && e.code < 500
          ? e.message
          : 'We are experiencing technical difficulties. Please try again';
      setErrorMessage({ ...errorMessage, submit: errMsg });
    }
  };

  if (isEdit && !state.data) {
    return <PreLoader />;
  }

  const pageTitle = isEdit ? 'Edit' : 'New';

  const initialData = {
    ...state.data,
    firstName: state?.data?.customer?.primaryContact?.firstName ?? '',
    lastName: state?.data?.customer?.primaryContact?.lastName ?? '',
  };
  return (
    <>
      <PageHeaderRow header={`${pageTitle} Testimonial`} subheader="Customer" subheaderId={''}>
        <Link to={pathPrefix}>
          <Button content="Back" size={'mini'} />
        </Link>
      </PageHeaderRow>
      <Segment>
        <TestimonialForm
          initialValues={initialData}
          errorMessage={errorMessage.submit}
          onSubmit={handleSubmit}
        />
      </Segment>
    </>
  );
};

const mapStateToProps = (state): ConnectedProps => ({
  user: state.auth.user || {},
});

export const Detail = connect(mapStateToProps)(PreDetail as any);
