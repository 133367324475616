import * as React from 'react';
import type { ModelTypes } from '@inkcloud/icapi-types';
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Header,
  Message,
  Modal,
  ModalProps,
  Segment,
  Table,
} from 'semantic-ui-react';
import Feathers from '../../../../bootstrap/feathers';

const { useState, useEffect } = React;

interface MicrositePublishModalProps {
  handleSubmit: Function;
  handleClose: ModalProps['onClose'];
  selectedProduct: NonNullable<ModelTypes.ProductLegacy>;
  // productId: string;
  // productKey: string;
  // staticName: string;
  microsites: any[] | null;
  onReload?: () => void;
  /**
   * The following props is this component can be reused on microsite product list page.
   * It allows us to preselect the  microsite
   */
  selectedMicrosite?: any;
}

function loadMicrositeProducts(productId) {
  return Feathers.service('/microsites/products').find({
    query: {
      product: productId,
      $np: 1,
      $populate: ['microsite'],
      isPublished: true,
    },
  });
}

export const MicrositePublishModal: React.FunctionComponent<MicrositePublishModalProps> = (
  props
) => {
  const { microsites, selectedProduct, onReload } = props;
  const [micrositeProducts, setMicrositeProducts] = useState<
    ModelTypes.MicrositeProducts & Partial<{ microsite: { name: string }; _id: string }>[]
  >([]);
  // Initialize selected microsite  with prop if passed.
  const [selectedMicrosite, setSelectedMicrosite] = useState(props.selectedMicrosite || null);
  const [createPage, setCreatePage] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState({
    unpublish: false,
    publish: false,
  });
  const [id, setId] = useState('');
  const [responseMessage, setResponseMessage] = useState({
    publish: '',
  });

  const [errorMessage, setErrorMessage] = useState('');

  const handlePublish = async () => {
    setResponseMessage({
      ...responseMessage,
      publish: '',
    });

    setIsSubmitting({
      ...isSubmitting,
      publish: true,
    });

    setErrorMessage('');

    try {
      const micrositeProduct: any = await Feathers.service('microsites/products/publish').create({
        microsite: selectedMicrosite,
        product: selectedProduct?._id,
      });

      if (createPage) {
        await Feathers.service('/store-front/microsites/content/pages').create({
          business: selectedMicrosite,
          microsite: selectedMicrosite,
          product: selectedProduct?._id,
          micrositeProduct: micrositeProduct._id,
          type: 'product',
          name: selectedProduct?.staticName,
          url: `/product/${selectedProduct?.key}`,
          template: 'product.html',
          title: selectedProduct?.staticName,
        });
      }

      const result = await loadMicrositeProducts(selectedProduct?._id);

      setResponseMessage({
        ...responseMessage,
        publish: 'Product published',
      });
      setMicrositeProducts(result);
      setSelectedMicrosite(null);

      onReload?.();
    } catch (e) {
      const errMsg = e?.code < 500 ? e?.message : 'Something went wrong. Please try again later.';
      setErrorMessage(errMsg);
    }

    setCreatePage(false);
    setIsSubmitting({
      ...isSubmitting,
      publish: false,
    });
  };

  const handleUnPublish = async (micrositeProductId) => {
    setResponseMessage({
      ...responseMessage,
      publish: '',
    });
    setIsSubmitting({
      ...isSubmitting,
      unpublish: true,
    });

    setId(micrositeProductId);

    try {
      await Feathers.service('microsites/products/unpublish').create({
        micrositeProductId,
      });
      const results = await loadMicrositeProducts(selectedProduct);
      setMicrositeProducts(results);
      setIsSubmitting({
        ...isSubmitting,
        unpublish: false,
      });
      setId('');

      onReload?.();
    } catch (e) {
      setIsSubmitting({
        ...isSubmitting,
        unpublish: false,
      });
      setId('');
    }
  };

  useEffect(() => {
    const load = async () => {
      const results = await loadMicrositeProducts(selectedProduct);
      setMicrositeProducts(results);
    };

    if (selectedProduct) {
      load();
    }
  }, [selectedProduct]);

  return (
    <Modal size={'small'} open onClose={props.handleClose} closeIcon>
      <Modal.Header>Microsite Publish</Modal.Header>
      <Modal.Content>
        <div>
          This product ({selectedProduct?.key || ''}{' '}
          {selectedProduct?.staticName ? `- ${selectedProduct?.staticName}` : ''}) is published to
          the following microsites
        </div>
        {micrositeProducts.length === 0 && <Message warning size="tiny" content="None" />}
        <Table size={'small'}>
          <Table.Body>
            {micrositeProducts.map((mp, idx) => (
              <Table.Row key={idx}>
                <Table.Cell>{mp?.microsite?.name ?? ''}</Table.Cell>
                <Table.Cell>
                  <Button
                    size="mini"
                    loading={isSubmitting.unpublish && id === mp._id}
                    content="Unpublish"
                    onClick={() => handleUnPublish(mp._id)}
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>

        <Divider />
        {responseMessage.publish && (
          <Message success size="tiny" content={responseMessage.publish} />
        )}
        <Header as="h6" attached="top" content="Publish" />
        <Form as={Segment} attached>
          <Form.Group inline>
            <Form.Field>
              <Form.Dropdown
                selection
                search
                options={microsites.map((m: any) => ({ value: m._id, text: m.name }))}
                onChange={(e, data) => setSelectedMicrosite(data.value)}
                value={selectedMicrosite}
              />
            </Form.Field>
            <Form.Field>
              <Checkbox
                toggle
                disabled={!selectedMicrosite}
                label={'Create Microsite Page'}
                checked={createPage}
                onChange={(e, data) => setCreatePage(!!data.checked)}
              />
            </Form.Field>
            <Form.Field>
              <Form.Button
                primary
                disabled={!selectedMicrosite}
                loading={isSubmitting.publish}
                content={'Publish'}
                onClick={() => handlePublish()}
              />
            </Form.Field>
          </Form.Group>
        </Form>
        {errorMessage && <Message error content={errorMessage} />}
      </Modal.Content>
      <Modal.Actions></Modal.Actions>
    </Modal>
  );
};
