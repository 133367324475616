import * as React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { FormattedDate, FormattedTime } from 'react-intl';
import { Segment, Table, Button, Label } from 'semantic-ui-react';
import { useList, PageHeaderRow, PaginatorWrapper, EmptyWrapper } from '@inkcloud/components';
import moment from 'moment';

import Feathers from './../../bootstrap/feathers';

import NumberFormat from 'react-number-format';

import { RefundModal } from './RefundModal';

import { FilterForm } from './FilterForm';

interface ListProps {}

const { useState } = React;

const filterQueryMap = (values) => {
  const filterMap: any = {
    'actualShipments.trackingNumber': values.trackingNumber ? values.trackingNumber : undefined,
    'actualShipments.amount': values.amount ? values.amount : undefined,
    humanId: values.humanId ? values.humanId : undefined,
    createdAt: {
      $gte: values.start ? moment(values.start).format('YYYY-MM-DD') : undefined,
      $lte: values.end ? moment(values.end).format('YYYY-MM-DD') : undefined,
    },
  };

  return [].reduce(
    (m, field) => ({ ...m, [field]: values[field] ? values[field] : undefined }),
    filterMap
  );
};

const serviceName = 'orders';
const pathPrefix = '/shipment-log';

export const List: React.FunctionComponent<ListProps & RouteComponentProps<any>> = (props) => {
  const { history, location } = props;
  const {
    state,
    reload,
    handleDelete,
    selectedItems,
    clearAll,
    setChecked,
    someSelected,
    toggleAll,
    filter,
    setFilter,
    sort,
    handleSort,
  } = useList({
    feathers: [
      'find',
      serviceName,
      [
        {
          query: {
            $shippedOrdersOnly: 1,
            $sort: { createdAt: -1 },
          },
        },
      ],
    ],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  const [isOpenRefundModal, setIsOpenRefundModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [orderId, setOrderId] = useState('');
  const [actualShipmentId, setActualShipmentId] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleOpenRefundModal = (orderId: string, actualShipmentId: string) => {
    setOrderId(orderId);
    setActualShipmentId(actualShipmentId);
    setIsOpenRefundModal(true);
  };

  const handleSubmitRefund = async () => {
    setIsLoading(true);
    setErrorMessage('');
    try {
      const res = await Feathers.service('orders/void-shipment').create({
        orderId,
        actualShipmentId,
      });
      if (res) {
        reload();
        setIsLoading(false);
        setIsOpenRefundModal(false);
      }
    } catch (e) {
      setIsLoading(false);
      e.code && e.code < 500
        ? setErrorMessage(e.message)
        : setErrorMessage('We are experiencing technical difficulties. Please try again');
    }
  };

  const { queryResult, isLoading: isStateLoading } = state as any;

  return (
    <>
      <PageHeaderRow header="Log" subheader="Shipment" />
      <Segment>
        <FilterForm value={filter} onChange={(v) => setFilter(v)} onReset={() => setFilter({})} />
      </Segment>
      <Segment>
        <EmptyWrapper queryResult={queryResult} isStateLoading={isStateLoading}>
          <PaginatorWrapper.Top
            filter={filter}
            reload={reload}
            data={queryResult as any}
            pathPrefix={`${pathPrefix}/`}
          />
          <Table size="small">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>ID</Table.HeaderCell>
                <Table.HeaderCell>Created</Table.HeaderCell>
                <Table.HeaderCell>Service Description</Table.HeaderCell>
                <Table.HeaderCell>Ship To Address</Table.HeaderCell>

                <Table.HeaderCell>Tracking</Table.HeaderCell>
                <Table.HeaderCell>Amount</Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {queryResult &&
                (queryResult as any).data.map(
                  (r: any) =>
                    r.actualShipments.length > 0 &&
                    r.actualShipments.map((a: any, index: number) => (
                      <Table.Row key={index}>
                        <Table.Cell>
                          <Link to={`/orders/${r?._id}`}>{r?.humanId}</Link>
                        </Table.Cell>
                        <Table.Cell>
                          <FormattedDate value={a.createdAt} />
                          <br />
                          <small>
                            <FormattedTime value={a.createdAt} />
                          </small>
                        </Table.Cell>
                        <Table.Cell>{a.serviceDescription}</Table.Cell>
                        <Table.Cell>
                          {a.shipToName}
                          <br />
                          {a.shipToCareOf && (
                            <>
                              {a.shipToCareOf} <br />
                            </>
                          )}
                          {a.shipToAddress1} {a.shipToAddress2} <br />
                          {a.shipToCity}, {a.shipToStateProvince} {a.shipToPostalCode}
                        </Table.Cell>
                        <Table.Cell>{a.trackingNumber}</Table.Cell>
                        <Table.Cell>
                          <NumberFormat
                            value={a.amount || 0.0}
                            decimalScale={2}
                            displayType={'text'}
                            fixedDecimalScale={true}
                            thousandSeparator={true}
                            prefix={'$'}
                          />
                        </Table.Cell>
                        <Table.Cell>
                          {a.isVoid ? (
                            <Label size="tiny" content="Refunded" />
                          ) : (
                            a.easypostShipmentId && (
                              <Button
                                primary
                                size="mini"
                                content="Refund"
                                onClick={() => handleOpenRefundModal(r._id, a._id)}
                              />
                            )
                          )}
                        </Table.Cell>
                      </Table.Row>
                    ))
                )}
            </Table.Body>
          </Table>
          <PaginatorWrapper.Bottom
            reload={reload}
            data={queryResult as any}
            pathPrefix={`${pathPrefix}`}
          />
        </EmptyWrapper>

        {isOpenRefundModal && (
          <RefundModal
            isOpen={isOpenRefundModal}
            isLoading={isLoading}
            onClose={() => setIsOpenRefundModal(false)}
            onSubmit={handleSubmitRefund}
            errorMessage={errorMessage}
          />
        )}
      </Segment>
    </>
  );
};
