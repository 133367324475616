import React, { useState } from 'react';
// import { Modal, Loader, Segment, Label, Button, Message } from 'semantic-ui-react';
import {
  Button,
  Modal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  Alert,
} from '@chakra-ui/react';
import type { ModelTypes } from '@inkcloud/icapi-types';
import feathers, { icapi } from '../../../bootstrap/feathers';
import { SelectedQuantitiesState } from '../RFQ2/Manage/SelectedQuantities';

import type { PONumbersType, AwardJobType } from '../Detail';

interface IRfqConvertToPurchaseOrderModalProps {
  open: boolean;
  onClose: () => void;
  rfq: ModelTypes.Rfq;
  finalConversion: any;
  awardJob: AwardJobType;
  selectedQuantities: SelectedQuantitiesState;
  onReload: () => void;
}

const RfqConvertToPurchaseOrderModal: React.FunctionComponent<
  IRfqConvertToPurchaseOrderModalProps
> = (props) => {
  const { open, onClose, onReload, rfq, selectedQuantities, awardJob } = props;

  const items =
    (selectedQuantities &&
      Object.keys(selectedQuantities).map((key) => ({
        _id: key,
        quantity: selectedQuantities[key],
      }))) ||
    [];

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleCreatePurchaseOrder = async () => {
    setErrorMessage('');
    setIsSubmitting(true);
    try {
      await (icapi.service('rfqs/conversions') as any).create({
        rfq: rfq._id,
        items,
        vendorPurchaseOrderNumbers: awardJob.poNumbers,
        costCenter: awardJob.costCenter,
        externalJobNumber: awardJob.externalJobNumber,
        clientPo: awardJob.clientPo,
      });

      onReload();
      onClose();
    } catch (e) {
      const errMsg = e?.code < 500 ? e?.message : 'An error occurred. Please try again later.';

      setErrorMessage(errMsg);
    }
    setIsSubmitting(false);
  };

  return (
    <Modal isOpen={open} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create Purchase Order(s)</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          This action will create one or more purchase orders. Please confirm.
          {errorMessage && <Alert status="error">{errorMessage}</Alert>}
        </ModalBody>

        <ModalFooter>
          <Button
            isDisabled={isSubmitting}
            isLoading={isSubmitting}
            colorScheme="blue"
            mr={3}
            onClick={handleCreatePurchaseOrder}
          >
            Create
          </Button>
          <Button isDisabled={isSubmitting} variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default RfqConvertToPurchaseOrderModal;
