import React, { useEffect, useState } from 'react';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Box,
  Input,
  Button,
  Flex,
  Alert,
} from '@chakra-ui/react';
import { useHistory } from 'react-router';
import type { ModelTypes } from '@inkcloud/icapi-types';
import { useSelector, useDispatch } from 'react-redux';
import { Feathers } from '../../bootstrap/feathers/feathers';
import icLogo from '../../images/ic-logo.svg';
import { feathersAuthentication } from '../../bootstrap/feathers';

const resetStyle = {
  height: '100%',
  backgroundImage:
    'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("https://storage.googleapis.com/inkcloud-assets/login-bg1.jpg")',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
};

export default function ForgotPassword() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [password, setPassword] = useState('');
  const [rePassword, setRePassword] = useState('');

  const [responseMessage, setResponseMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [user, setUser] = useState<ModelTypes.User>({});

  const jwtParams = new URLSearchParams(window.location.search).get('jwt');

  const auth = async () => {
    try {
      const res = await Feathers.authenticate({ strategy: 'jwt', accessToken: jwtParams } as any);
      dispatch(feathersAuthentication.authenticate());
      setUser(res?.user ?? {});
    } catch (e) {
      const errMsg = e?.code < 500 ? e?.message : 'Something went wrong. Please try again later.';

      const expiredJwt = errMsg === 'jwt expired';
      setErrorMessage(expiredJwt ? 'The link you followed has expired.' : errMsg);
    }
  };

  useEffect(() => {
    if (jwtParams) {
      auth();
    }
  }, [jwtParams]);

  const handleReset = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    setResponseMessage('');
    setIsSubmitting(true);

    const payload = { password };
    try {
      const res = await Feathers.service('/user').patch(user?._id, payload);

      // window.location.reload()
      history.push('/');
    } catch (e) {
      const errMsg = e?.code < 500 ? e?.message : 'Something went wrong. Please try again later.';
      setErrorMessage(errMsg);
    }

    setIsSubmitting(false);
  };

  const expiredJwt = errorMessage === 'jwt expired';
  const isDisabled = !password?.length || !rePassword?.length || expiredJwt;
  const misMatch = password !== rePassword;

  return (
    <Flex h="100vh" alignItems="center" justifyContent="center" style={resetStyle}>
      <Box w="100%" maxW="325px" bg="white" borderRadius="md" py={3} px={4}>
        <Box mb={6} textAlign="center">
          <object width="100" style={{ margin: 'auto' }} type="image/svg+xml" data={icLogo}>
            SVG not supported
          </object>
        </Box>
        <form onSubmit={handleReset}>
          <Box mb={3}>
            <FormControl isInvalid={!!misMatch}>
              <FormLabel fontWeight="bold">New password</FormLabel>
              <Input
                isDisabled={expiredJwt}
                type="password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormControl>
          </Box>
          <Box mb={3}>
            <FormControl isInvalid={!!misMatch}>
              <FormLabel fontWeight="bold">Re-type password</FormLabel>
              <Input
                isDisabled={expiredJwt}
                type="password"
                onChange={(e) => setRePassword(e.target.value)}
              />
              {misMatch && (
                <FormErrorMessage>Password and Confirm Password doesn't match.</FormErrorMessage>
              )}
            </FormControl>
          </Box>
          {responseMessage && (
            <Alert status="info" mb={3}>
              {responseMessage}
            </Alert>
          )}
          {errorMessage && (
            <Alert status="error" mb={3}>
              {errorMessage}
            </Alert>
          )}
          <Box mb={3}>
            <Button
              type="submit"
              isDisabled={(isDisabled || isSubmitting || !!misMatch) && user?._id !== undefined}
              isLoading={isSubmitting}
              colorScheme="blue"
              w="full"
            >
              Reset
            </Button>
          </Box>
        </form>
      </Box>
    </Flex>
  );
}
