import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Segment } from 'semantic-ui-react';
import { PageHeaderRow, PreLoader, useFeathers } from '@inkcloud/components';
import feathers from '../../../../bootstrap/feathers';
import AllottmentForm from './AllottmentPolicyForm';

interface DetailProps {
  products: { value: string; text: string }[];
  categories: { value: string; text: string }[];
}

const serviceName = 'user-allotment-policies';
const service = feathers.service(serviceName);
const commonParams = {
  query: {
    $populate: ['products.products'],
  },
};

export const Detail: React.FunctionComponent<
  DetailProps & RouteComponentProps<{ id?: string; micrositeId: string }>
> = (props) => {
  const {
    match: {
      params: { id, micrositeId },
    },
    history,
  } = props;

  const pathPrefix = `/settings/microsites/${micrositeId}/allotment-policies`;

  const isEdit = !!id;
  const [state, reload] = useFeathers('get', serviceName, [id, commonParams], true);

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');

  const handleSubmit = async (values) => {
    setIsSubmitting(true);
    setErrorMessage('');

    console.log('values:', values);
    try {
      const payload = {
        ...values,
        products: values?.products?.map((product) => ({
          products: product?.products?.map((p) => p.value),
          amount: product?.amount,
        })),
        microsite: micrositeId,
      };

      if (isEdit) {
        await service.patch(id, payload, commonParams);
      } else {
        await service.create(payload, commonParams);
      }
      history.push(pathPrefix);
    } catch (e) {
      console.log('Error:', e);
      const errMsg =
        e?.code < 500
          ? e?.message
          : 'We are sorry, but something went wrong. Please try again later.';
      setErrorMessage(errMsg);
    }
    setIsSubmitting(false);
  };

  const pageTitle = isEdit ? 'Edit' : 'New';

  if (isEdit && !state.data) {
    return <PreLoader />;
  }

  const initialData = {
    ...state.data,
    products: state.data?.products?.map((product) => ({
      products:
        product?.products?.map((p) => ({
          label: `${p?.staticName ? `${p.staticName} - ` : ''} ${p?.key ?? ''}`,
          value: p,
        })) ?? null,
      amount: product?.amount,
    })),
    microsite: micrositeId,
  };
  return (
    <div>
      <PageHeaderRow
        header={`${pageTitle} Allotment Policy`}
        subheader="Microsite"
        subheaderId={''}
      >
        <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
      </PageHeaderRow>
      <Segment>
        {(!isEdit || state.data) && (
          <AllottmentForm
            initialValues={initialData}
            onSubmit={handleSubmit}
            errorMessage={errorMessage}
            isSubmitting={isSubmitting}
          />
        )}
      </Segment>
    </div>
  );
};
