import React, { useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Box, Button, Input, Textarea, Alert } from '@chakra-ui/react';
import { AsyncSelect, Select } from 'chakra-react-select';
import type { Option } from 'react-select';
import { yupResolver } from '@hookform/resolvers/yup';
import type { ModelTypes } from '@inkcloud/icapi-types';
import * as yup from 'yup';

import { FormControl } from '../../../common/v3/Form/FormControl';
import DatePickerInput from '../../../common/v3/Form/DatePickerInput';

import { JobSchedulingWorkflowTemplateSteps } from './JobSchedulerWorkflowTemplateSteps';
import { Feathers } from '../../../bootstrap/feathers/feathers';

export async function typeOptions(e): Promise<any> {
  try {
    const res = await Feathers.service('job-scheduler/workflow-template-types').find({
      query: {
        $np: 1,
        $sort: { name: 1 },
        name: e ? { $LIKE: e } : undefined,
        $select: ['name'],
      },
    });

    return res?.map((r) => ({ label: r?.name, value: r?._id }));
  } catch (er) {
    console.error(er);
    throw er;
  }
}
const yupSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  description: yup.string(),
  steps: yup.array().of(
    yup.object().shape({
      column: yup.object().shape({
        label: yup.string().required('Column name is required'),
        value: yup.string().required('Column name is required'),
      }),
      // column: yup.string().required('Column name is required'),
    })
  ),
});

interface JobSchedulingWorkflowTemplateFormProps {
  initialValues: {
    name: string;
    type: string;
    description?: string;
    steps: any[];
  };
  columns: any[];
  // columns: ModelTypes.JobSchedulerColumns[];
  resources: ModelTypes.ProductionResources[];
  isSubmitting: boolean;
  errorMessage: string;
  onSubmit: (data: any) => void;
}

export default function JobSchedulingWorkflowTemplateForm(
  props: JobSchedulingWorkflowTemplateFormProps
) {
  const { initialValues, isSubmitting, errorMessage, onSubmit, columns, resources } = props;

  const useFormReturn = useForm({
    mode: 'onBlur',
    defaultValues: initialValues,
    resolver: yupResolver(yupSchema),
  });

  useEffect(() => {
    useFormReturn.reset(initialValues);
  }, [initialValues]);

  const { handleSubmit, setValue } = useFormReturn;

  return (
    <>
      <Box bgColor={'white'} p={8}>
        <FormProvider {...useFormReturn}>
          <Box width={360}>
            <FormControl
              field={'name'}
              label="Name"
              required={true}
              render={(fieldProps) => <Input {...fieldProps} />}
            />

            <FormControl
              field="type"
              label="Type"
              required={true}
              render={(fieldProps) => (
                <AsyncSelect
                  useBasicStyles
                  placeholder="Select or start typing to search..."
                  value={fieldProps.value}
                  cacheOptions
                  defaultOptions
                  loadOptions={typeOptions}
                  onChange={(e: Option) => setValue('type', e)}
                />
              )}
            />
            <FormControl
              field={'description'}
              label="Description"
              render={(fieldProps) => <Textarea {...fieldProps}></Textarea>}
            />
          </Box>
        </FormProvider>

        <Box>
          <JobSchedulingWorkflowTemplateSteps
            useFormReturn={useFormReturn}
            columns={columns}
            resources={resources}
          />
        </Box>

        {errorMessage && (
          <Alert mb={2} status="error">
            {errorMessage}
          </Alert>
        )}

        <Button
          isDisabled={isSubmitting}
          isLoading={isSubmitting}
          colorScheme="blue"
          onClick={handleSubmit((data) => onSubmit(data))}
        >
          Submit
        </Button>
      </Box>
    </>
  );
}
