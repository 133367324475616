import React, { useRef } from 'react';
import { Segment, Label, Message, Form, Input, Dropdown } from 'semantic-ui-react';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Box,
  Alert,
  Button,
} from '@chakra-ui/react';

import { Formik, Form as FormikForm, Field, FieldArray, ErrorMessage, FieldProps } from 'formik';
import * as Yup from 'yup';
import countries from 'world-countries';
import { SemanticField } from '../../../../common/Form';

import { countryStateProvinces, SegmentCard } from '../../../../common';
import { IBillTo, emptyBillTo } from '../primitives';

import AddressForm from './AddressForm';

const BillToSchema = Yup.object().shape({
  name: Yup.string(),
  careOf: Yup.string(),
  countryCode: Yup.string().required('Required'),
  address1: Yup.string().required('Required'),
  address2: Yup.string(),
  city: Yup.string().required('Required'),
  stateProvince: Yup.string().required('Required'),
  postalCode: Yup.string().required('Required'),
});

type handlerRef = {
  handleSave: () => void;
};
export interface IBillToProps {
  value: IBillTo;
  onChange: (value: IBillTo) => void;
  actionButtonLabel?: string;
  isEditable?: boolean;
}

export function BillTo(props: IBillToProps) {
  const { value = {}, isEditable = true, actionButtonLabel = 'Edit', onChange } = props;
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const addressRef = useRef<handlerRef>(null);

  // console.log('addressRef', addressRef)

  return (
    <>
      <SegmentCard
        title="Bill To"
        subtitle="Billing Address"
        actionButtonLabel={actionButtonLabel}
        actionButtonClick={() => setIsModalOpen(true)}
      >
        <Box>{value?.name}</Box>
        <Box>{value?.careOf}</Box>
        <Box>{value?.address1}</Box>
        <Box>{value?.address2}</Box>
        <Box>
          {value?.city} {value?.stateProvince} {value?.postalCode} {value?.countryCode}{' '}
        </Box>

        {!value && <Alert status="warning"> Click edit to enter in a bill to address</Alert>}
        {/* {isEditable && (
          <Button
            style={{ alignSelf: 'end', marginTop: 10 }}
            fluid
            compact
            size="small"
            content={'Edit Bill To'}
            onClick={() => setIsModalOpen(true)}
          />
        )} */}
      </SegmentCard>

      {isModalOpen && (
        <Modal isOpen={true} onClose={() => setIsModalOpen(false)}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Edit Bill To</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <AddressForm
                ref={addressRef}
                address={value}
                type="billTo"
                onChange={(data) => {
                  onChange(data);
                  setIsModalOpen(false);
                }}
              />
              {/* <Formik
                initialValues={value}
                enableReinitialize={true}
                validationSchema={BillToSchema}
                onSubmit={(values, { setSubmitting }) => {
                  onChange(values)
                  setIsModalOpen(false)
                }}
              >
                {(formikProps) => {
                  const {
                    values,
                    touched,
                    errors,
                    dirty,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    handleReset,
                    setFieldValue,
                    setFieldTouched,
                  } = formikProps

                  return (
                    <>
                      <Form size={'small'}>
                        <Form.Field>
                          <SemanticField
                            fluid
                            name="name"
                            component={Form.Input}
                            autoComplete="new-company"
                            label="Company Name"
                          />
                        </Form.Field>
                        <Form.Field>
                          <SemanticField
                            fluid
                            name="careOf"
                            component={Form.Input}
                            autoComplete="new-name"
                            label="Name"
                          />
                          <ErrorMessage
                            render={(msg) => <Message negative size="tiny" content={msg} />}
                            name="name"
                          />
                        </Form.Field>
                        <Form.Field>
                          <SemanticField
                            fluid
                            name="countryCode"
                            selection
                            search
                            component={Form.Dropdown}
                            autoComplete="new-countryCode"
                            options={
                              countries?.map((c: any) => ({
                                text: c.name.official,
                                value: c.cca2,
                              })) ?? []
                            }
                            label="Country"
                          />
                          <ErrorMessage
                            render={(msg) => <Message negative size="tiny" content={msg} />}
                            name="countryCode"
                          />
                        </Form.Field>
                        <Form.Field>
                          <SemanticField
                            fluid
                            name="address1"
                            component={Form.Input}
                            autoComplete="new-address1"
                            label="Address 1"
                          />
                          <ErrorMessage
                            render={(msg) => <Message negative size="tiny" content={msg} />}
                            name="address1"
                          />
                        </Form.Field>
                        <Form.Field>
                          <SemanticField
                            fluid
                            name="address2"
                            component={Form.Input}
                            autoComplete="new-address2"
                            label="Address 2"
                          />
                        </Form.Field>
                        <Form.Field>
                          <SemanticField
                            fluid
                            name="city"
                            component={Form.Input}
                            autoComplete="new-city"
                            label="City"
                          />
                          <ErrorMessage
                            render={(msg) => <Message negative size="tiny" content={msg} />}
                            name="city"
                          />
                        </Form.Field>
                        <Form.Group widths="equal">
                          <Form.Field>
                            {values?.countryCode === 'US' ||
                            values?.countryCode === 'CA' ||
                            values?.countryCode === 'AU' ? (
                              <>
                                <label>
                                  {values?.countryCode === 'AU'
                                    ? 'State/Territory'
                                    : 'State/Province'}
                                </label>
                                <Dropdown
                                  name={'stateProvince'}
                                  options={countryStateProvinces(values?.countryCode)}
                                  fluid
                                  selection
                                  search
                                  autoComplete="new-stateProvince"
                                  value={values?.stateProvince ?? ''}
                                  onChange={(e, data) => setFieldValue(data.name, data.value)}
                                />
                              </>
                            ) : (
                              <SemanticField
                                name={'stateProvince'}
                                component={Form.Input}
                                autoComplete="new-stateProvince"
                                fluid
                                label="State/Province"
                              />
                            )}

                            <ErrorMessage
                              render={(msg) => <Message negative size="tiny" content={msg} />}
                              name="stateProvince"
                            />
                          </Form.Field>
                          <Form.Field>
                            <SemanticField
                              fluid
                              name="postalCode"
                              component={Form.Input}
                              autoComplete="new-postalCode"
                              label="Postal Code"
                            />
                            <ErrorMessage
                              render={(msg) => <Message negative size="tiny" content={msg} />}
                              name="postalCode"
                            />
                          </Form.Field>
                        </Form.Group>
                      </Form>
                    </>
                  )
                }}
              </Formik> */}
            </ModalBody>

            <ModalFooter>
              <Button mr={3} onClick={() => setIsModalOpen(false)}>
                Close
              </Button>
              <Button colorScheme="blue" onClick={() => addressRef?.current?.handleSave()}>
                Save
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
}
