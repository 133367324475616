import * as React from 'react';
import { Button, Checkbox, Header, Segment, Table, Label, Popup } from 'semantic-ui-react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { FormattedDate } from 'react-intl';
import { useList, PageHeaderRow, PreLoader, PaginatorWrapper } from '@inkcloud/components';
import { FilterForm } from './FilterForm';

interface ListProps {
  products: { value: string; text: string }[];
  categories: { value: string; text: string }[];
}

const serviceName = 'microsites/address-books';

const filterQueryMap = (values) => {
  const filterMap: any = {
    name: values.name ? { $LIKE: values.name } : undefined,
    address1: values.address1 ? { $LIKE: values.address1 } : undefined,
    city: values.city ? { $LIKE: values.city } : undefined,
    stateProvince: values.stateProvince ? { $LIKE: values.stateProvince } : undefined,
    postalCode: values.postalCode ? { $LIKE: values.postalCode } : undefined,
  };

  return [].reduce(
    (m, field) => ({ ...m, [field]: values[field] ? values[field] : undefined }),
    filterMap
  );
};

export const List: React.FunctionComponent<
  ListProps & RouteComponentProps<{ micrositeId: string }>
> = (props) => {
  const {
    match: { params },
    location,
    history,
  } = props;
  const {
    state,
    reload,
    handleDelete,
    selectedItems,
    clearAll,
    setChecked,
    someSelected,
    toggleAll,
    filter,
    setFilter,
    sort,
    handleSort,
  } = useList({
    feathers: ['find', serviceName, [{ query: { microsite: params.micrositeId } }]],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { name: 1 },
  });

  const { queryResult, isLoading: isStateLoading } = state as any;

  if (!queryResult) {
    return null;
  }

  const pathPrefix = `/settings/microsites/${params.micrositeId}`;

  return (
    <div>
      <PageHeaderRow header="Address Book" subheader="Microsite">
        {/* <SubMenu match={props.match} history={history} /> */}
        <Link to={pathPrefix}>
          <Button content="Back" size={'mini'} />
        </Link>
        <Link to={`${pathPrefix}/address-book/add`}>
          <Button content="New" primary size={'mini'} />
        </Link>
      </PageHeaderRow>

      <FilterForm value={filter} onChange={(v) => setFilter(v)} onReset={() => setFilter({})} />

      <Segment loading={state.isLoading}>
        <PaginatorWrapper.Top
          filter={filter}
          reload={reload}
          data={queryResult as any}
          pathPrefix={`${pathPrefix}/address-book/`}
        />
        <Table size={'small'} sortable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                <input type="checkbox" onChange={toggleAll} />
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={sort.createdAt ? sort.createdAt : null}
                onClick={() => handleSort('createdAt')}
              >
                Created
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={sort.name ? sort.name : null}
                onClick={() => handleSort('name')}
              >
                Name
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={sort.address1 ? sort.address1 : null}
                onClick={() => handleSort('address1')}
              >
                Address
              </Table.HeaderCell>

              <Table.HeaderCell
                sorted={sort.city ? sort.city : null}
                onClick={() => handleSort('city')}
              >
                City
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={sort.stateProvince ? sort.stateProvince : null}
                onClick={() => handleSort('stateProvince')}
              >
                State
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={sort.postalCode ? sort.postalCode : null}
                onClick={() => handleSort('postalCode')}
              >
                Postal Code
              </Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {queryResult &&
              (queryResult as any).data.map((r) => (
                <Table.Row key={r._id}>
                  <Table.Cell>
                    <input
                      type="checkbox"
                      value={r._id}
                      checked={selectedItems[r._id] || false}
                      onChange={setChecked}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <FormattedDate value={r.createdAt} />
                  </Table.Cell>
                  <Table.Cell>{r.name}</Table.Cell>
                  <Table.Cell>
                    {r.address1} {r.address2}
                  </Table.Cell>
                  <Table.Cell>{r.city}</Table.Cell>
                  <Table.Cell>{r.stateProvince}</Table.Cell>
                  <Table.Cell>{r.postalCode}</Table.Cell>
                  <Table.Cell textAlign="right">
                    <Popup
                      size="mini"
                      content="Edit"
                      trigger={
                        <Link
                          to={`/settings/microsites/${props.match.params.micrositeId}/address-book/${r._id}`}
                        >
                          <Button size={'mini'} icon={'edit'} />
                        </Link>
                      }
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
        <PaginatorWrapper.Bottom
          reload={reload}
          data={queryResult as any}
          pathPrefix={`${pathPrefix}/address-book/`}
        >
          {someSelected && <Button primary type="button" content="Delete" onClick={handleDelete} />}
        </PaginatorWrapper.Bottom>
      </Segment>
    </div>
  );
};
