import React, { useRef, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  Alert,
} from '@chakra-ui/react';
import { Feathers } from '../../bootstrap/feathers/feathers';

type IdModalProps = {
  id: string;
  humanId: string | number;
  foreignId: string;
  onClose: () => void;
  onReload: () => void;
};

export default function IdModal(props: IdModalProps) {
  const { id, humanId, foreignId, onClose, onReload } = props;

  // eslint-disable-next-line no-undef
  const inputRef = useRef<HTMLInputElement>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const handleSubmit = async () => {
    setIsSubmitting(true);
    setErrorMessage('');
    try {
      const res = await Feathers.service('purchase-orders').patch(id, {
        foreignId: inputRef?.current?.value,
        humanId: foreignId === humanId ? inputRef?.current?.value : humanId,
      });

      onClose();
      onReload();
    } catch (e) {
      const errMsg = e?.code < 500 ? e?.message : 'Something went wrong. Please try again later.';
      setErrorMessage(errMsg);
    }
    setIsSubmitting(false);
  };

  return (
    <Modal isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit PO ID</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Input ref={inputRef} defaultValue={humanId} />
          {errorMessage && (
            <Alert status="error" mt={3}>
              {errorMessage}
            </Alert>
          )}
        </ModalBody>

        <ModalFooter>
          <Button isDisabled={isSubmitting} mr={3} onClick={onClose}>
            Close
          </Button>
          <Button
            isDisabled={isSubmitting}
            isLoading={isSubmitting}
            colorScheme="blue"
            onClick={handleSubmit}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
