import * as React from 'react';
import { Tooltip, Text } from '@chakra-ui/react';

import { FormattedDate, FormattedTime } from 'react-intl';

interface IDateWithHoverProps {
  value: any;
}

const DateWithHover: React.FunctionComponent<IDateWithHoverProps> = (props) => {
  const { value } = props;

  // Invalid date, skip rendering
  if (!value) {
    return null;
  }
  return (
    <Tooltip
      hasArrow
      label={<FormattedTime value={value} />}
      padding={4}
      bg="gray.200"
      color="black"
    >
      <Text>
        <FormattedDate value={value} />
      </Text>
    </Tooltip>
  );
};

export default DateWithHover;
