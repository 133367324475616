import * as React from 'react';
import { Button, Segment, Table, Popup, Message } from 'semantic-ui-react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { FormattedDate, FormattedTime } from 'react-intl';
import { FilterForm } from './FilterForm';

import { AddUserModal } from './AddUserModal';

import Feathers from './../../../../bootstrap/feathers';

import { useList, PageHeaderRow, EmptyWrapper, PaginatorWrapper } from '@inkcloud/components';

interface ListProps {}

const serviceName = '/tenant-settings/general/order-notes-notification-recipients';
const pathPrefix = '/settings/general/order-notes';

const filterQueryMap = (values) => {
  const filterMap: any = {
    firstName: values.firstName ? { $LIKE: values.firstName } : undefined,
    lastName: values.lastName ? { $LIKE: values.lastName } : undefined,
    email: values.email ? { $LIKE: values.email } : undefined,
  };

  return [].reduce(
    (m, field) => ({ ...m, [field]: values[field] ? values[field] : undefined }),
    filterMap
  );
};

const { useState } = React;

export const List: React.FunctionComponent<ListProps & RouteComponentProps<any>> = (props) => {
  const {
    match: { params },
    location,
    history,
  } = props;

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    add: '',
    remove: '',
  });

  const {
    state,
    reload,
    handleDelete,
    selectedItems,
    clearAll,
    setChecked,
    someSelected,
    toggleAll,
    filter,
    setFilter,
    sort,
    handleSort,
  } = useList({
    feathers: ['find', serviceName, [{ query: {} }]],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { firstName: -1 },
  });

  const { queryResult, isLoading: isStateLoading } = state as any;

  if (!queryResult) {
    return null;
  }

  const handleUserDelete = async (id: string) => {
    setErrorMessage({ ...errorMessage, remove: '' });
    try {
      const res: any = await Feathers.service(serviceName).remove(id);
      if (res) {
        reload();
      }
    } catch (e) {
      e.code && e.code < 500
        ? setErrorMessage({ ...errorMessage, remove: e.message })
        : setErrorMessage({
            ...errorMessage,
            remove: 'We are experiencing technical difficulties. Please try again',
          });
    }
  };

  const handleSubmit = async (v: string) => {
    setErrorMessage({ ...errorMessage, add: '' });
    setIsSubmitting(true);
    try {
      const res: any = await Feathers.service(serviceName).create({ userId: v });

      if (res) {
        setIsSubmitting(false);
        setIsOpenModal(false);
        reload();
      }
    } catch (e) {
      setIsSubmitting(false);
      e.code && e.code < 500
        ? setErrorMessage({ ...errorMessage, add: e.message })
        : setErrorMessage({
            ...errorMessage,
            add: 'We are experiencing technical difficulties. Please try again',
          });
    }
  };

  return (
    <div>
      <PageHeaderRow header="Order Notes Notification Recipients " subheader="Settings">
        <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
        <Button
          content="Add Recipient"
          primary
          size={'mini'}
          onClick={() => setIsOpenModal(true)}
        />
      </PageHeaderRow>

      {/* <FilterForm value={filter} onChange={(v) => setFilter(v)} onReset={() => setFilter({})} /> */}

      <EmptyWrapper queryResult={queryResult} isStateLoading={isStateLoading}>
        <Segment loading={state.isLoading}>
          <PaginatorWrapper.Top
            filter={filter}
            reload={reload}
            data={queryResult as any}
            pathPrefix={`${pathPrefix}`}
          />
          <Table size={'small'} sortable>
            <Table.Header>
              <Table.Row>
                {/* <Table.HeaderCell><input type="checkbox" onChange={toggleAll} /></Table.HeaderCell> */}
                <Table.HeaderCell>First Name</Table.HeaderCell>
                <Table.HeaderCell>Last Name</Table.HeaderCell>
                <Table.HeaderCell>Email</Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {queryResult &&
                (queryResult as any)?.map(
                  (r) =>
                    (
                      <Table.Row key={r._id} verticalAlign="top">
                        {/* <Table.Cell>
                    <input type="checkbox" value={r._id} checked={selectedItems[r._id] || false} onChange={setChecked} />
                  </Table.Cell> */}
                        <Table.Cell>{r.firstName}</Table.Cell>
                        <Table.Cell>{r.lastName}</Table.Cell>
                        <Table.Cell>{r.email}</Table.Cell>
                        <Table.Cell>
                          <Popup
                            size="mini"
                            content="Remove"
                            trigger={
                              <Button
                                size={'mini'}
                                icon={'trash'}
                                onClick={() => handleUserDelete(r._id)}
                              />
                            }
                          />
                        </Table.Cell>
                      </Table.Row>
                    ) ?? null
                )}
            </Table.Body>
          </Table>
          {errorMessage.remove && <Message error content={errorMessage.remove} />}
          <PaginatorWrapper.Bottom
            reload={reload}
            data={queryResult as any}
            pathPrefix={`${pathPrefix}`}
          >
            {someSelected && (
              <Button primary type="button" content="Delete" onClick={handleDelete} />
            )}
          </PaginatorWrapper.Bottom>
        </Segment>
      </EmptyWrapper>

      {isOpenModal && (
        <AddUserModal
          isOpen={isOpenModal}
          onClose={() => setIsOpenModal(false)}
          isSubmitting={isSubmitting}
          errorMessage={errorMessage.add}
          onSubmit={handleSubmit}
        />
      )}
    </div>
  );
};
