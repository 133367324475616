import * as React from 'react';
import { Form, Input, Dropdown, Button, Message } from 'semantic-ui-react';
import countries from 'world-countries';
import { usStateProvinces, countryStateProvinces } from '../../common';

interface ShipAddressFormProps {
  values: any;
  address: string;
  isLoadingAddress: boolean;
  isDisabled: boolean;
  onSaveAddress: (v: any) => void;
  setFieldValue: (name: string, value: any) => void;
  errorMessage: any;
}

export const ShipAddressForm: React.FunctionComponent<ShipAddressFormProps> = (props) => {
  const {
    values,
    address,
    isLoadingAddress,
    isDisabled,
    onSaveAddress,
    setFieldValue,
    errorMessage,
  } = props;

  const error = errorMessage && errorMessage[address];

  const countryCodeValue = values.shippingDetails[address].countryCode;
  return (
    <>
      <Dropdown
        name="countryCode"
        label="Country"
        selection
        search
        component={Form.Dropdown}
        options={countries?.map((c: any) => ({ text: c.name.official, value: c.cca2 })) ?? []}
        value={values.shippingDetails[address].countryCode ?? ''}
        fluid
        onChange={(e, data) => setFieldValue(`shippingDetails.${address}.countryCode`, data.value)}
      />

      <Form.Group widths="equal">
        <Form.Field>
          <label>Name</label>
          <Input
            name={`shippingDetails.${address}.name`}
            value={values.shippingDetails[address].name}
            onChange={(e, data) => setFieldValue(`shippingDetails.${address}.name`, data.value)}
          />
          {error && error.name && <Message error size="tiny" content={error.name} />}
        </Form.Field>
        {address !== 'shipFrom' && (
          <Form.Field>
            <label>Care of</label>
            <Input
              name={`shippingDetails.${address}.careOf`}
              value={values.shippingDetails[address].careOf}
              onChange={(e, data) => setFieldValue(`shippingDetails.${address}.careOf`, data.value)}
            />
          </Form.Field>
        )}
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field>
          <label>Address 1</label>
          <Input
            name={`shippingDetails.${address}.address1`}
            value={values.shippingDetails[address].address1}
            onChange={(e, data) => setFieldValue(`shippingDetails.${address}.address1`, data.value)}
          />
          {error && error.address1 && <Message error size="tiny" content={error.address1} />}
        </Form.Field>
        <Form.Field>
          <label>Address 2</label>
          <Input
            name={`shippingDetails.${address}.address2`}
            value={values.shippingDetails[address].address2}
            onChange={(e, data) => setFieldValue(`shippingDetails.${address}.address2`, data.value)}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field>
          <label>City</label>
          <Input
            name={`shippingDetails.${address}.city`}
            value={values.shippingDetails[address].city}
            onChange={(e, data) => setFieldValue(`shippingDetails.${address}.city`, data.value)}
          />
          {error && error.city && <Message error size="tiny" content={error.city} />}
        </Form.Field>

        <Form.Field>
          {countryCodeValue === 'US' || countryCodeValue === 'CA' || countryCodeValue === 'AU' ? (
            <>
              <label>{countryCodeValue === 'AU' ? 'State/Territory' : 'State/Province'}</label>
              <Dropdown
                name={`shippingDetails.${address}.stateProvince`}
                options={countryStateProvinces(countryCodeValue)}
                fluid
                selection
                value={values?.shippingDetails[address].stateProvince ?? ''}
                onChange={(e, data) => setFieldValue(data.name, data.value)}
              />
            </>
          ) : (
            <Input
              name={`shippingDetails.${address}.stateProvince`}
              component={Form.Input}
              fluid
              // label='State/Province'
              placeholder="State/Province..."
              onChange={(e, data) =>
                setFieldValue(`shippingDetails.${address}.stateProvince`, data.value)
              }
            />
          )}
        </Form.Field>

        <Form.Field>
          <label>Postal Code</label>
          <Input
            name={`shippingDetails.${address}.postalCode`}
            value={values.shippingDetails[address].postalCode}
            onChange={(e, data) =>
              setFieldValue(`shippingDetails.${address}.postalCode`, data.value)
            }
          />
          {error && error.postalCode && <Message error size="tiny" content={error.postalCode} />}
        </Form.Field>
      </Form.Group>

      <Button
        type="button"
        loading={isLoadingAddress}
        disabled={!isDisabled}
        content="Save Address"
        onClick={() => onSaveAddress(values)}
      />
    </>
  );
};
