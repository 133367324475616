import * as React from 'react';
import { Document } from '@react-pdf/renderer';
import type { ModelTypes } from '@inkcloud/icapi-types';
import { InvoiceContext, InvoiceProvider } from './InvoiceContext';

type OrderItemPopulated = Omit<ModelTypes.OrderItems, 'attributes' | 'product'> & {
  attributes: { value: ModelTypes.ProductAttributeTypesLegacy }[];
  product: ModelTypes.ProductLegacy;
  unitPrice: number;
  revenue: number;
};

export type InvoiceDocumentContext = Omit<ModelTypes.Orders, 'invoice' | 'customer'> & {
  invoice: ModelTypes.Invoices;
  customer: ModelTypes.Customers;
  orderItems: OrderItemPopulated[];

  tenantInfo: {
    locale: string;
    currency: string;
    timezone: string;
  };
};

interface InitialState {
  config: any;
  dataCtx: InvoiceDocumentContext;
  styles: any;
}

export interface IInvoiceDocumentProps {
  _initialState?: InitialState;
  render: (v: InitialState) => any;
  children: React.ReactNode;
}

export const mockOptions = {
  title: 'Invoice',
  creator: 'Ink Cloud',
  producer: 'Ink Cloud',
};

export const InvoiceDocument: React.FunctionComponent<IInvoiceDocumentProps> = (props) => {
  const { title, creator, producer } = mockOptions;
  const { children, _initialState, render } = props;

  return (
    <InvoiceProvider initialState={_initialState}>
      <Document title={title} creator={creator} producer={producer}>
        {render ? render(_initialState) : children}
      </Document>
    </InvoiceProvider>
  );
};
