import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Segment, Table } from 'semantic-ui-react';
import { PageHeaderRow, PreLoader, useFeathers } from '@inkcloud/components';
import { connect } from 'react-redux';
import { FulfillmentForm } from './FulfillmentForm';
import feathers from '../../../bootstrap/feathers';

interface DetailProps {
  products: { value: string; text: string }[];
  categories: { value: string; text: string }[];
  vendorOptions: Array<{ value: string; text: string }>;
}

export interface ConnectedProps {
  user?: any;
}

const pathPrefix = `/settings/fulfillment-staging-locations`;
const serviceName = '/fulfillment-staging-locations';
const service = feathers.service(serviceName);
const commonParams = {
  query: {},
};
const { useState } = React;
export const PreDetail: React.FunctionComponent<
  DetailProps & ConnectedProps & RouteComponentProps<{ id?: string }>
> = (props) => {
  const {
    match: {
      params: { id },
    },
    history,
    vendorOptions,
  } = props;

  const [errorMessage, setErrorMessage] = useState('');
  const isEdit = !!id;
  const [state, reload] = useFeathers('get', serviceName, [id, commonParams], true);

  const handleSubmit = async (values) => {
    try {
      setErrorMessage('');

      if (isEdit) {
        await service.patch(id, values, commonParams);
      } else {
        await service.create(values, commonParams);
      }

      history.push(pathPrefix);
    } catch (e) {
      const errMsg = e?.code < 500 ? e?.message : 'Something went wrong. Please try again later.';
      setErrorMessage(errMsg);
    }
  };

  if (isEdit && !state.data) {
    return <PreLoader />;
  }

  const initialData = { ...state.data };
  return (
    <div>
      <PageHeaderRow header={`Fulfillment Staging Locations`} subheader="Settings">
        <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
      </PageHeaderRow>

      <Segment>
        <FulfillmentForm
          vendorOptions={vendorOptions}
          initialValues={initialData}
          errorMessage={errorMessage}
          onSubmit={handleSubmit}
        />
      </Segment>
    </div>
  );
};

const mapStateToProps = (state): ConnectedProps => ({
  user: state.auth.user || {},
});

export const Detail = connect(mapStateToProps)(PreDetail as any);
