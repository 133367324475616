import * as React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Popup } from 'semantic-ui-react';
import { FormattedDate, FormattedTime } from 'react-intl';
import { useList, ListPage, basicQueryMap, EmptyWrapper } from '@inkcloud/components';
import Feathers from '../../bootstrap/feathers';

import { OrderApprovalModal } from './OrderApprovalModal';
import { getFilterDefinition } from './../orders/FilterForm2';

const { useState, useEffect } = React;

interface ListProps {}

const checkValidValues = (filterRow, valueToCheck) => {
  const values =
    filterRow &&
    Object?.values(filterRow)
      ?.concat.apply([], filterRow)
      ?.filter((f) => f !== valueToCheck)
      ?.reduce(
        (m, field) => ({ ...m, [field]: values[field] ? values[field] : undefined }),
        filterRow
      );

  return values;
};

const filterQueryMap = (values) => {
  let filterRows = basicQueryMap(values, {
    name: 'staticName',
  });

  // Map attributes as needed by API
  const prefix = 'attributes:';
  const attributes = Object.keys(filterRows)
    .filter((r) => r.startsWith(prefix))
    .reduce((acc, cur) => ({ ...acc, [cur.slice(prefix.length)]: filterRows[cur]?.$in }), {});

  // Delete the original attribute valuess so they are not submitted
  Object.keys(filterRows)
    .filter((r) => r.startsWith(prefix))
    .forEach((r) => delete filterRows[r]);

  filterRows = {
    ...filterRows,
    microsite: checkValidValues(filterRows.microsite, 'all'),
    addOns: filterRows.addOns ? { $elemMatch: { addOnId: filterRows.addOns } } : undefined,
    attributes: attributes,
    type: undefined,
  };

  return filterRows;
};

const serviceName = 'orders';
const pathPrefix = `/order-approval`;

export const List: React.FunctionComponent<ListProps & RouteComponentProps<any>> = (props) => {
  const {
    match: { params },
    location,
    history,
  } = props;
  // const { state, reload, handleDelete, selectedItems, clearAll, setChecked, someSelected, toggleAll, filter, setFilter, sort, handleSort } = useList({
  //   feathers: ['find', serviceName, [{
  //     query: {
  //       approvalStatus: 'pending', $populate: ['category', 'customer', 'manager', 'invoice'], $populateOrderItems: 1, $orderItemRevenue: 1
  //     }
  //   }]],
  //   filterMap: undefined,
  //   debounce: 150,
  //   location,
  //   history,
  //   sort: { createdAt: -1 }
  // });

  const useListValues = useList({
    feathers: [
      'find',
      serviceName,
      [
        {
          query: {
            approvalStatus: 'pending',
            $populate: ['category', 'customer', 'manager', 'invoice', 'microsite', 'micrositeUser'],
            $populateOrderItems: 1,
            $orderItemRevenue: 1,
            isVoid: { $ne : true },
          },
        },
      ],
    ],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [id, setId] = useState(null);

  const [orderValue, setOrderValue] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [toApproveItems, setToApproveItems] = useState(null);

  let search = new URLSearchParams(location.search);
  let orderId = search.get('order');

  useEffect(() => {
    if (orderId) {
      const loadOrderItem = async () => {
        try {
          const result = await Feathers.service(serviceName).get(orderId, {
            query: {
              approvalStatus: 'pending',
              $populate: ['category', 'customer', 'manager', 'invoice'],
              $populateOrderItems: 1,
              $orderItemRevenue: 1,
            },
          });

          if (result) {
            setIsModalOpen(true);
            setId(orderId);
            setOrderValue(result);
          }
        } catch (er) {
          history.push(pathPrefix);
        }
      };

      loadOrderItem();
    }
  }, [orderId]);

  const handleOpenModal = (values: any) => {
    setId(values._id);
    setIsModalOpen(true);
    setOrderValue(values);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setId(null);
    setOrderValue(null);
    setToApproveItems(null);
    if (orderId) {
      history.push(pathPrefix);
    }
  };

  const handleToApproveItems = (item: any) => {
    const foundItem = toApproveItems?.find((a) => a._id === item._id);

    if (!foundItem) {
      setToApproveItems([...(toApproveItems || []), item]);
    } else {
      const filteredItems = toApproveItems?.filter((a) => a._id !== item._id);
      setToApproveItems(filteredItems);
    }
  };

  const handleToggleAll = (e: any, data: any) => {
    const { checked } = data;
    checked ? setToApproveItems(orderValue?.orderItems || []) : setToApproveItems([]);
  };

  const approvedItems =
    toApproveItems?.length > 0 && toApproveItems?.length !== orderValue?.orderItems?.length
      ? toApproveItems
      : undefined;

  const handleChangeStatus = async (status: string, rejectionReason?: string) => {
    setErrorMessage('');
    setIsSubmitting(true);
    try {
      const result = await Feathers.service('orders/approval').create(
        {
          orderId: id,
          approvalStatus: status,
          approvedItems: approvedItems?.map((a) => a._id),
          rejectionReason,
        },
        { query: {} }
      );
      if (result) {
        handleCloseModal();
        useListValues.reload();
        setIsSubmitting(false);
        setToApproveItems(null);
      }
    } catch (e) {
      setIsSubmitting(false);
      e.code && e.code < 500
        ? setErrorMessage(e.message)
        : setErrorMessage('We are experiencing technical difficulties. Please try again');
    }
  };

  if (!orderId) {
    // const { queryResult, isLoading: isStateLoading } = state as any;

    // if (!queryResult) {
    //   return <PreLoader />
    // }

    const headerActions = (
      <Link to="/order-rejected">
        <Button size="mini" content="View Rejected" />
      </Link>
    );

    const tableDefintion = [
      {
        key: 'createdAt',
        label: 'Date',
        sortable: true,
        cell: (v, r) => (
          <Popup
            size="mini"
            content={<FormattedTime value={r.createdAt} />}
            trigger={
              <div>
                <FormattedDate value={r.createdAt} />
              </div>
            }
          />
        ),
      },
      {
        key: '',
        label: 'ID',
        cell: (v, r) => <Link to={`/orders/${r?._id}`}>{r?.humanId}</Link>,
      },
      {
        key: '',
        label: 'Customer',
        cell: (v, r) => (
          <Link to={`/customers/${r.customer?._id}`}>
            {r.microsite?.name
              ? `${r.microsite?.name} / ${r.micrositeUser?.firstName ?? ''} ${
                  r.micrositeUser?.lastName ?? ''
                }`
              : r.customer?.primaryContact?.firstName ?? ''}{' '}
            {r.customer?.primaryContact?.lastName ?? ''}
          </Link>
        ),
      },
      {
        key: '',
        label: 'Manager',
        cell: (v, r) => (
          <>
            {r.manager?.isManager && `${r.manager?.firstName ?? ''} ${r.manager?.lastName ?? ''}`}
          </>
        ),
      },
      {
        key: '',
        label: '',
        cell: (v, r) => (
          <>
            <Popup
              size="mini"
              content="Open Modal"
              trigger={
                <Button
                  size={'mini'}
                  icon={'window restore outline'}
                  onClick={() => handleOpenModal(r)}
                />
              }
            />
          </>
        ),
        textAlign: 'right',
      },
    ];

    return (
      <div>
        <ListPage
          header="Pending Approval"
          subheader="Order"
          useListValues={useListValues}
          history={history}
          pathPrefix={pathPrefix}
          tableDefintion={tableDefintion}
          getFilterDefinition={getFilterDefinition}
          withCheckboxes={false}
          headerActions={headerActions}
        />
        {id && (
          <OrderApprovalModal
            isModalOpen={isModalOpen}
            values={orderValue}
            onClose={handleCloseModal}
            onReload={useListValues.reload}
            errorMessage={errorMessage}
            onChangeStatus={handleChangeStatus}
            isSubmitting={isSubmitting}
            onToggleAll={handleToggleAll}
            onToApproveItems={handleToApproveItems}
            toApproveItems={toApproveItems}
          />
        )}
      </div>
    );
  }

  return (
    <>
      {orderValue && orderValue.approvalStatus === 'pending' ? (
        <OrderApprovalModal
          isModalOpen={isModalOpen}
          values={orderValue}
          errorMessage={errorMessage}
          onClose={handleCloseModal}
          onReload={useListValues.reload}
          onChangeStatus={handleChangeStatus}
          isSubmitting={isSubmitting}
          onToggleAll={handleToggleAll}
          onToApproveItems={handleToApproveItems}
          toApproveItems={toApproveItems}
        />
      ) : (
        <EmptyWrapper queryResult={null} isStateLoading={false}>
          {' '}
        </EmptyWrapper>
      )}
    </>
  );
};
