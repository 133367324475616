import * as React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import {
  Button,
  Box,
  IconButton,
  Tooltip,
  Badge,
  Icon,
  Text,
  TabList,
  Tab,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { FormattedDate, FormattedTime } from 'react-intl';
import { WarningTwoIcon } from '@chakra-ui/icons';
import { FaEdit, FaList } from 'react-icons/fa';

import {
  useList,
  ListPage,
  basicQueryMap,
  generateOperatorOptions,
  Operator,
  PaginatorWrapper,
  EmptyWrapper,
  ListTable,
  IListTableProps,
  GenericFilter,
} from '@inkcloud/components';

import { Can } from '../../../common/permissions';
import { orderStatusToColor, TruncatedText } from './helpers';
import { Feathers } from '../../../bootstrap/feathers/feathers';

interface IInProductionProps {}

const serviceName = '/orders';
const pathPrefix = '/rfqs/in-production';
const paginatorPrefix = '/rfqs/in-production';

export async function getFilterDefinition() {
  const definition = [
    {
      label: 'Assigned To',
      value: 'createdFromRfq.assignedTo',
      operators: generateOperatorOptions([Operator.Is]),
      asyncValueOptions: async () =>
        Feathers.service('users')
          .find({
            query: {
              $sort: { firstName: 1 },
              $np: 1,
              type: { $ne: 'vendor' },
              vendor: { $exists: false },
            },
          })
          .then((results) =>
            results.map((r) => ({
              value: r._id,
              label: `${r?.firstName ?? ''} ${r?.lastName ?? ''}`,
            }))
          ),
      type: 'select',
    },
    {
      label: 'Requestor',
      value: 'createdFromRfq.requestor',
      operators: generateOperatorOptions([Operator.Is]),
      asyncValueOptions: async () =>
        Feathers.service('users')
          .find({
            query: {
              $sort: { firstName: 1 },
              $np: 1,
              type: { $ne: 'vendor' },
              vendor: { $exists: false },
            },
          })
          .then((results) =>
            results.map((r) => ({
              value: r._id,
              label: `${r?.firstName ?? ''} ${r?.lastName ?? ''}`,
            }))
          ),
      type: 'select',
    },
    {
      label: 'ID',
      value: 'humanId',
      operators: generateOperatorOptions([Operator.Is]),
    },
    {
      label: 'Date:Created',
      value: 'createdAt',
      operators: generateOperatorOptions([Operator.Between]),
      type: 'date',
    },
    {
      label: 'Project Name',
      value: 'createdFromRfq.projectName',
      operators: generateOperatorOptions([Operator.Like, Operator.Is]),
    },
    {
      label: 'Status',
      value: 'productionStatus',
      operators: generateOperatorOptions([Operator.Is]),
      valueOptions: [
        {
          label: 'Not Started',
          value: 'not-started',
        },
        {
          label: 'Not Applicable',
          value: 'not-applicable',
        },
        {
          label: 'Partial',
          value: 'partial',
        },
        {
          label: 'In Progress',
          value: 'in-progress',
        },
        {
          label: 'Complete',
          value: 'complete',
        },
      ],
      type: 'select',
    },
  ];

  definition.sort((a: any, b: any) => a.label.localeCompare(b.label));

  return definition;
}

const filterQueryMap = (values: any, baseQuery: any = {}) => {
  let filterRows = basicQueryMap(values, {});

  const { requestor, assignedTo, customer, status, jobNumber, productionStatus } = filterRows;

  filterRows = {
    ...filterRows,
    'createdFromRfq.customer': customer?.$elemMatch.value,
    'createdFromRfq.status': status?.$elemMatch.value ?? baseQuery.status,
    'createdFromRfq.assignedTo': assignedTo?.$elemMatch.value,
    'createdFromRfq.requestor': requestor?.$elemMatch.value,
    // productionStatus: productionStatus?.$elemMatch?.value ? productionStatus?.$elemMatch?.value : productionStatus,
  };

  return filterRows;
};

const InProduction: React.FunctionComponent<IInProductionProps & RouteComponentProps<any>> = (
  props
) => {
  const {
    match: { params },
    location,
    history,
  } = props;

  const useListValues = useList({
    feathers: [
      'find',
      serviceName,
      [
        {
          query: {
            isVoid: { $ne: true },
            createdFromRfq: { $exists: true },
            productionStatus: { $ne: 'complete' },
            $populate: [
              'customer',
              {
                path: 'createdFromRfq',
                populate: [
                  'assignedTo',
                  'requestor',
                  'customer',
                  // 'estimate.createdOrder',
                ],
              },
              // { path: 'tenant', select: 'displayBusinessName' },
              // { path: 'createdOrder', select: 'productionStatus' },
            ],
          },
        },
      ],
    ],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  // const {
  //   state,
  //   reload,
  //   handleDelete,
  //   selectedItems,
  //   clearAll,
  //   setChecked,
  //   someSelected,
  //   toggleAll,
  //   filter,
  //   setFilter,
  //   sort,
  //   handleSort,
  // } = useListValues
  // const { queryResult, isLoading: isStateLoading } = state as any
  // const resolvedQueryResult = queryResult?.data ? queryResult.data : queryResult

  const tableDefinition = [
    {
      key: 'humanId',
      label: 'ID',
      sortable: true,
      cell: (v, r) => v,
    },
    {
      key: 'createdAt',
      label: 'Date',
      sortable: true,
      cell: (v) => (
        <Tooltip hasArrow aria-label="time" label={<FormattedTime value={v} />}>
          <Box as="span">
            <FormattedDate value={v} />
          </Box>
        </Tooltip>
      ),
    },
    {
      key: '',
      label: 'Customer',
      sortable: true,
      cell: (v, r) => {
        if (r?.micrositeUser) {
          return r?.micrositeUser?.companyName
            ? r?.micrositeUser?.companyName
            : `${r?.micrositeUser?.firstName ?? ''} ${r?.micrositeUser?.lastName ?? ''}`;
        }

        const name =
          r?.customer?.organizationName ??
          `${r?.customer?.primaryContact?.firstName ?? ''} ${
            r?.customer?.primaryContact?.lastName ?? ''
          }`;

        // return name
        return <TruncatedText text={name} />;
      },
    },
    {
      key: 'assignedTo',
      label: 'Assignee',
      sortable: true,
      cell: (v, r) => (
        <TruncatedText
          text={`${r?.createdFromRfq?.assignedTo?.firstName ?? ''} ${
            r?.createdFromRfq?.assignedTo?.lastName ?? ''
          }`}
        />
      ),
    },
    {
      key: 'requestor',
      label: 'Requestor',
      sortable: true,
      cell: (v, r) => (
        <TruncatedText
          text={`${r?.createdFromRfq?.requestor?.firstName ?? ''} ${
            r?.createdFromRfq?.requestor?.lastName ?? ''
          }`}
        />
      ),
    },
    {
      key: 'projectName',
      label: 'Project Name',
      sortable: true,
      cell: (v, r) => <TruncatedText text={r.createdFromRfq?.projectName ?? ''} />,
    },
    {
      key: 'jobNumber',
      label: 'Job #',
      sortable: true,
      cell: (v, r) => (
        <TruncatedText limit={10} text={`${r.createdFromRfq?.estimate?.externalJobNumber ?? ''}`} />
      ),
    },

    {
      key: '',
      label: 'Order Status',
      sortable: true,
      cell: (v, r) => (
        <>
          <Link to={`/orders/${r?._id}`}>
            {/* {r?.estimate?.createdOrder?.humanId} */}
            <Badge colorScheme={orderStatusToColor(r?.productionStatus ?? '')} variant="outline">
              {r?.productionStatus ?? ''}
            </Badge>
          </Link>
          {r?.estimate?.createdOrder?.supplierDocsRequireReview && (
            <Tooltip label="Supplier has uploaded documents. Click to Review">
              <Link to={`/rfqs/${r.createdFromRfq?._id}`}>
                <Icon ml={2} boxSize={4} as={WarningTwoIcon} color="red.500" />
              </Link>
            </Tooltip>
          )}
        </>
      ),
    },
    // {
    //   key: 'status',
    //   label: 'RFQ Status',
    //   sortable: true,
    //   cell: (v) => (
    //     <Badge colorScheme={statusToColor(v)} variant="outline">
    //       {v}
    //     </Badge>
    //   ),
    // },

    {
      key: '',
      label: '',
      sortable: false,
      cell: (v, r) => (
        <>
          <Can do={'read'} on={'dashboard.rfqs'}>
            <Tooltip label="View RFQ">
              <Link to={`/rfqs/${r.createdFromRfq?._id}`}>
                <IconButton
                  variant={'outline'}
                  mr={2}
                  colorScheme="gray"
                  aria-label="View"
                  icon={<FaList />}
                />
              </Link>
            </Tooltip>
          </Can>
          <Can do={'read'} on={'dashboard.orders'}>
            <Tooltip label="View Order">
              <Link to={`/orders/${r._id}`}>
                <IconButton colorScheme="gray" aria-label="View" icon={<FaEdit />} />
              </Link>
            </Tooltip>
          </Can>
        </>
      ),
      textAlign: 'right',
    },
  ];

  return (
    <Box bgColor={'white'}>
      {/* <EmptyWrapper queryResult={resolvedQueryResult} isStateLoading={isStateLoading}>
        <Segment >
          <PaginatorWrapper.Top filter={filter} reload={reload} data={(queryResult as any)} pathPrefix={`${paginatorPrefix}`} />
          <ListTable
            data={resolvedQueryResult}
            definition={tableDefinition}
            selectedItems={selectedItems as any[]}
            setChecked={setChecked}
            sort={sort}
            sortClickFunction={handleSort}
            toggleAll={toggleAll as any}
            withCheckboxes={true}
          />
          <PaginatorWrapper.Bottom reload={reload} data={(queryResult as any)} pathPrefix={`${paginatorPrefix}`}>
             {someSelected && <Button primary type="button" content={selectedItemsBtnLabel || "Delete"} onClick={handleSelectedItems ? () => handleSelectedItems(selectedItems) : handleDelete} />}
          </PaginatorWrapper.Bottom>
        </Segment> *
      </EmptyWrapper> */}

      <ListPage
        header=""
        subheader=""
        useListValues={useListValues}
        history={history}
        pathPrefix={pathPrefix}
        tableDefintion={tableDefinition}
        withCheckboxes={true}
        getFilterDefinition={getFilterDefinition}
      />
    </Box>
  );
};

export default InProduction;
