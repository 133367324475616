import React, { useRef, useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Radio,
  RadioGroup,
  Stack,
  Checkbox,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Switch,
  Input,
  Alert,
  Box,
} from '@chakra-ui/react';
import Cleave from 'cleave.js/react';
import DatePicker from 'react-datepicker';
import { useSelector } from 'react-redux';
import type { ModelTypes } from '@inkcloud/icapi-types';

import Feathers from '../../bootstrap/feathers';

import { PartialShipOut } from './PartialShipOut';
import LocalDelivery, { initValues as localDeliveryValues } from './LocalDelivery';
import { currencySymbol, InputWrapper } from '../../common';

type payloadType = {
  trackingNumber: string;
  actualCost: string | null;
  actualCostAdjusted: string | null;
  orderItems: undefined;
  isReship: boolean;
  extraRecipient: {
    name: string;
    email: string;
  };
  shipmentType: string;
};

interface IShipOutModalProps {
  order: ModelTypes.Orders;
  onClose: () => void;
  onReload: () => void;
  // onSubmit: (v: payloadType) => void;
  errorMessage?: string;
}

type shipType = 'shipment' | 'pickup' | 'local delivery';

type handlerRef = {
  handleSubmit: () => void;
};

type pendingItemsType = {
  orderItemId: string;
  selected: boolean;
  quantity: number;
  shippingQuantity: number;
}[];

export default function ShipOutModal(props: IShipOutModalProps) {
  const { onClose, onReload, order } = props;

  const tenant = useSelector(
    ({ globals }: { globals: { tenant: { currency; locale; countryCode: string } } }) =>
      globals.tenant
  );

  // const [tenant, setTenant] = useState<{ locale: string; currency: string; countryCode: string }>()

  const localRef = useRef<handlerRef>(null);

  const [isPartial, setIsPartial] = useState(false);
  const [pendingItems, setPendingItems] = useState<pendingItemsType>([]);
  const [isReship, setIsReship] = useState(false);
  const [shipmentType, setShipmentType] = useState<shipType>('shipment');
  const [trackingNumber, setTrackingNumber] = useState('');
  const [actualCost, setActualCost] = useState(0);
  const [actualCostAdjusted, setActualCostAdjusted] = useState('');
  const [extraRecipient, setExtraRecipient] = useState({ name: '', email: '' });

  const [shipDate, setShipDate] = useState(new Date());

  const [hasExtraRecipient, setHasExtraRecipient] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const loadOpenData = async (orderId) => {
    const res = (await Feathers.service('/orders/shipment-open').create({
      orderId,
    })) as any;

    setPendingItems(
      res?.shipmentDetails?.pending?.map((p) => ({
        ...p,
        orderItemId: p.orderItem._id,
        selected: false,
        shippingQuantity: p.quantity,
      }))
    );
  };

  // const loadTenantSettings = async (id: string) => {
  //   try {
  //     const res = await Feathers.service('/tenant-settings').get(id, {
  //       query: {
  //         $select: ['internationalization'],
  //       },
  //     })
  //     setTenant(res?.internationalization)
  //   } catch (e) {
  //     console.error(e)
  //   }
  // }

  useEffect(() => {
    if (order._id) {
      loadOpenData(order._id);
    }
  }, [order?._id]);

  // useEffect(() => {
  //   if (ctx?.user?.business) {
  //     loadTenantSettings(ctx?.user?.business)
  //   }
  // }, [ctx?.user?.business])

  const handleSubmit = async (values?: typeof localDeliveryValues) => {
    setErrorMessage('');

    const filteredItems = pendingItems?.reduce(
      (acc: { orderItemId: string; quantity: number }[], cur) => {
        if (cur.selected) {
          acc.push({
            orderItemId: cur.orderItemId,
            quantity: Number(cur?.shippingQuantity ?? cur.quantity),
          });
        }

        return acc;
      },
      []
    );

    const isLocal = shipmentType === 'local delivery';
    const isShip = shipmentType === 'shipment';
    const isPickup = shipmentType === 'pickup';
    const orderItems = isPartial ? filteredItems : undefined;

    const updatedShipmentType = isLocal ? 'local-delivery' : shipmentType;

    const payload = {
      orderId: order._id,
      trackingNumber: isShip ? trackingNumber : '',
      actualCost: isShip ? actualCost : null,
      actualCostAdjusted: isShip ? actualCostAdjusted : null,
      orderItems,
      isReship: isPickup ? false : isReship,
      additionalEmail: isShip && hasExtraRecipient ? extraRecipient : undefined,
      shipmentType: updatedShipmentType,
      shipTo: isLocal ? values : undefined,
      createdAt: shipDate,
    };

    setIsSubmitting(true);
    try {
      const res = await Feathers.service('/shipping/mark-shipped').create(payload);

      if (!res.result) {
        setErrorMessage(res?.message);
        setIsSubmitting(false);
        return;
      }

      onReload();
      onClose();
    } catch (e) {
      const errMsg = e?.code < 500 ? e?.message : 'Something went wrong. Please try again later.';
      setErrorMessage(errMsg);
    }

    setIsSubmitting(false);
  };

  // console.log({ pendingItems });
  // console.log({ shipmentType });

  // console.log({ localRef });
  // console.log({ order });
  // console.log({ localDelivery });
  // console.log({ ctx });
  // console.log({ tenant });

  return (
    <Modal isOpen size="3xl" onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Ship Out</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <RadioGroup mb={3} onChange={(v: shipType) => setShipmentType(v)} value={shipmentType}>
            <Stack direction="row">
              <Radio value="shipment">Shipment</Radio>
              <Radio value="pickup">Pickup</Radio>
              <Radio value="local delivery">Local Delivery</Radio>
            </Stack>
          </RadioGroup>

          <Box mb={3}>
            <DatePicker
              dateFormat="MM/dd/yyyy"
              selected={shipDate}
              placeholderText="Ship Date"
              onChange={(date) => setShipDate(date as Date)}
            />
          </Box>
          {shipmentType === 'shipment' && (
            <>
              <Box p={3} mb={3} borderRadius="md" borderWidth="1px">
                <Stack columnGap={7} mb={3} direction="row">
                  <FormControl>
                    <FormLabel>Tracking Number</FormLabel>
                    <Input onChange={(e) => setTrackingNumber(e.target.value)} />
                    <FormErrorMessage></FormErrorMessage>
                  </FormControl>
                  <FormControl>
                    <FormLabel>Actual Cost</FormLabel>
                    <InputWrapper height="var(--chakra-sizes-9)">
                      <Cleave
                        style={{ textAlign: 'right' }}
                        className={'form-control-cleave'}
                        onChange={(e) => setActualCost(Number(e.target.rawValue) || 0)}
                        options={{
                          numeral: true,
                          rawValueTrimPrefix: true,
                          numeralPositiveOnly: true,
                          numeralThousandsGroupStyle: 'thousand',
                          prefix: currencySymbol(tenant?.currency, tenant?.locale),
                        }}
                      />
                    </InputWrapper>
                    {/* <Input onChange={(e) => setActualCost(e.target.value)} /> */}
                    <FormErrorMessage></FormErrorMessage>
                  </FormControl>
                  <FormControl>
                    <FormLabel>Adjusted Actual Cost</FormLabel>
                    <Input onChange={(e) => setActualCostAdjusted(e.target.value)} />
                    <FormErrorMessage></FormErrorMessage>
                  </FormControl>
                </Stack>
              </Box>
              <FormControl mb={3}>
                <FormLabel>
                  <Switch
                    isChecked={isReship}
                    mr={2}
                    onChange={(e) => setIsReship(e.target.checked)}
                  />
                  Mark as Re-ship
                </FormLabel>
              </FormControl>

              <FormControl mb={3}>
                <FormLabel>
                  <Switch
                    isChecked={hasExtraRecipient}
                    mr={2}
                    onChange={(e) => setHasExtraRecipient(e.target.checked)}
                  />
                  Extra Email Recipient
                </FormLabel>
              </FormControl>

              {hasExtraRecipient && (
                <Box p={3} mb={3} borderRadius="md" borderWidth="1px">
                  <Stack columnGap={7} mb={3} direction="row">
                    <FormControl>
                      <FormLabel>Name</FormLabel>
                      <Input
                        name="name"
                        onChange={(e) =>
                          setExtraRecipient({ ...extraRecipient, [e.target.name]: e.target.value })
                        }
                      />
                      <FormErrorMessage></FormErrorMessage>
                    </FormControl>
                    <FormControl>
                      <FormLabel>Email</FormLabel>
                      <Input
                        name="email"
                        onChange={(e) =>
                          setExtraRecipient({ ...extraRecipient, [e.target.name]: e.target.value })
                        }
                      />
                      <FormErrorMessage></FormErrorMessage>
                    </FormControl>
                  </Stack>
                </Box>
              )}
            </>
          )}
          <FormControl mb={3}>
            <FormLabel>
              <Switch
                mr={2}
                isDisabled={!pendingItems?.length}
                onChange={(e) => {
                  setIsPartial(e.target.checked);
                  if (pendingItems?.length === 1) {
                    setPendingItems([{ ...pendingItems[0], selected: true }]);
                  }
                }}
              />
              Partial {shipmentType}
            </FormLabel>
          </FormControl>

          {isPartial && <PartialShipOut onChange={setPendingItems} pendingItems={pendingItems} />}

          {shipmentType === 'local delivery' && (
            <Box p={3} mb={3} borderRadius="md" borderWidth="1px">
              <LocalDelivery ref={localRef} tenant={tenant} onChange={(v) => handleSubmit(v)} />
            </Box>
          )}

          {errorMessage && <Alert status="error">{errorMessage}</Alert>}
        </ModalBody>

        <ModalFooter>
          <Button mr={3} onClick={onClose}>
            Close
          </Button>
          <Button
            colorScheme="purple"
            isDisabled={isSubmitting}
            isLoading={isSubmitting}
            onClick={() => {
              if (shipmentType === 'local delivery') {
                localRef?.current?.handleSubmit();
              } else {
                handleSubmit();
              }
            }}
          >
            Mark Shipped
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
