import * as React from 'react';
import { Icon, Modal, Form, Button, Message } from 'semantic-ui-react';

interface GenerateModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  isSubmitting: boolean;
  errorGenerate: string;
}

export const GenerateModal: React.FunctionComponent<GenerateModalProps> = (props) => {
  const { isOpen, onClose, onSubmit, errorGenerate, isSubmitting } = props;

  return (
    <Modal size={'tiny'} open={isOpen} onClose={onClose} closeIcon>
      <Modal.Header>Re-trigger Generation</Modal.Header>
      <Modal.Content>
        <p>Are you sure you want to re-trigger generation?</p>

        {errorGenerate && <Message error content={errorGenerate} />}
      </Modal.Content>
      <Modal.Actions>
        <Button color="red" content="Cancel" disabled={isSubmitting} onClick={onClose} />
        <Button
          loading={isSubmitting}
          disabled={isSubmitting}
          color="green"
          onClick={onSubmit}
          content="Okay"
        />
      </Modal.Actions>
    </Modal>
  );
};
