import * as React from 'react';
import { connect } from 'react-redux';
import {
  InjectedFormProps,
  Field,
  reduxForm,
  change,
  formValueSelector,
  FieldArray,
  FormProps,
} from 'redux-form';
import { chunk } from 'lodash-es';
import { Link } from 'react-router-dom';
import { slugify } from 'underscore.string';
import { Button, Dropdown, Form, Header, Message, Radio, Segment } from 'semantic-ui-react';
import { EntityDropdownWrapper2 } from './../EntityDropdown';
import {
  semanticFormDropdownField,
  semanticFormField,
  semanticFormToggleField,
  semanticFormCheckboxGroupField,
  semanticFormRadioGroupField,
  semanticFormCheckboxField,
  semanticFormRadioField,
  validateRequired,
  normalizeBoolean,
  formatBoolean,
} from './../../common';
import Feathers from './../../bootstrap/feathers';
import DropdownTags from './../../common/DropdownTags';

type AppProps = {
  isEdit: boolean;
} & InjectedFormProps;

interface AppState {
  categoryDropdown: any;
  categories: any[];
  attributes: any[];
  addOns: any[];
  quantities: any[];
}
const validate = (values) => {
  let errors = validateRequired(['category'])(values);

  if (values.isArtworkRequired) {
    if (!values.artworkSlots || values.artworkSlots.length === 0) {
      errors.artworkSlots = 'Select one or more artwork sequences';
    }
  }

  if (values.isShippable) {
    const shippableErrors = validateRequired(['weightUnit', 'weightBasis'])(values);
    errors = { ...errors, ...shippableErrors };
  }

  if (values.isDesignEnabled) {
    const designErrors = validateRequired(['designPrice'])(values);
    errors = { ...errors, ...designErrors };
  }

  return errors;
};

const asyncValidate = (values, dispatch, props: AppProps, blurredField) => {
  if (blurredField === 'hrCode') {
    if (!props.isEdit || (props.initialValues && props.initialValues.hrCode !== values.hrCode)) {
      return Feathers.service<any>('/products-legacy/attributes')
        .find({ query: { hrCode: values.hrCode } })
        .then((results: any) => {
          if (results.data.length > 0) {
            throw { hrCode: 'That key is taken' };
          }
        });
    }
  }
  return Promise.resolve();
};

class ProductForm extends React.Component<AppProps, AppState> {
  constructor(props) {
    super(props);
    this.state = {
      categoryDropdown: null,
      categories: [],
      attributes: [],
      addOns: [],
      quantities: [],
    };
  }
  componentDidMount() {
    Feathers.service('/products-legacy/attributes')
      .find({
        query: {
          $np: 1,
          $sort: { name: 1 },
          $populate: ['entityAttribute'],
        },
      })
      .then((results: any) => {
        this.setState({ ...this.state, attributes: results });
      });

    Feathers.service('/products-legacy/add-ons-base')
      .find({
        query: {
          $sort: { name: 1 },
        },
      })
      .then((results: any) => {
        this.setState({ ...this.state, addOns: results });
      });

    Feathers.service('/products-legacy/categories')
      .find({
        query: {
          $np: 1,
          $sort: { name: 1 },
          $populate: ['entityAttributes'],
        },
      })
      .then((results: any) => {
        this.setState({
          ...this.state,
          categories: results
            .concat([{ _id: 'other', name: 'Other' }])
            .sort((a, b) => a.name - b.name),
        });
      });
  }

  componentWillReceiveProps(next) {
    if (next.quantities && this.props.quantities !== next.quantities) {
      this.setState({
        ...this.state,
        quantities: next.quantities.map((q) => ({ text: String(q), value: String(q) })),
      });
    }
  }

  onQuantityAdd = (e, value) => {
    const foundQty = this.state.quantities.find((q) => q.value === value.value);
    if (!foundQty) {
      const quantities = [...this.state.quantities];
      quantities.push({ text: value.value, value: value.value });
      this.setState({ ...this.state, quantities });
      let v = this.props.quantities;
      if (!Array.isArray(v)) {
        v = [];
        this.props.changeFieldValue('quantities', v);
      }
    }
  };

  renderAttributes() {
    const foundCategory = this.state.categories.find((c) => c._id === this.props.category);
    if (!foundCategory) {
      return null;
    }
    const chunkedArr = chunk(foundCategory.entityAttributes, 2);

    return chunkedArr.map((chunk, index) => {
      const ea = chunk.map((ea) => {
        return (
          <Field
            key={ea._id}
            name={`attributes[${ea._id}]`}
            component={semanticFormDropdownField}
            as={Dropdown}
            options={this.state.attributes
              .filter((a) => a.entityAttribute._id === ea._id)
              .map((c) => ({ text: c.name, value: c._id }))}
            multiple={false}
            selection
            fluid
            placeholder={ea.name}
          />
        );
      });
      return (
        <Form.Group key={`eaChunk${index}`} widths="equal">
          {ea}
        </Form.Group>
      );
    });
  }

  renderSizeParams() {
    const foundCategory = this.state.categories.find((c) => c._id === this.props.category);
    if (!foundCategory) {
      return null;
    }

    if (foundCategory.isSizesFixed) {
      return null;
    }

    return (
      <div>
        <Header attached="top" as="h5">
          Shipping Settings
        </Header>
        <Segment attached>
          <Form.Group widths={'equal'}>
            <Field
              name="maximumWidth"
              component={semanticFormField}
              as={Form.Input}
              label={'Minimum Width'}
            />
            <Field
              name="maximumWidth"
              component={semanticFormField}
              as={Form.Input}
              label={'Maximum Width'}
            />
          </Form.Group>
          <Form.Group widths={'equal'}>
            <Field
              name="minimumHeight"
              component={semanticFormField}
              as={Form.Input}
              label={'Minimum Height'}
            />
            <Field
              name="maximumHeight"
              component={semanticFormField}
              as={Form.Input}
              label={'Maximum Height'}
            />
          </Form.Group>
        </Segment>
      </div>
    );
  }

  render() {
    const artworkSequenceOptions = [
      { label: 'Front', value: 'FRONT' },
      { label: 'Back', value: 'BACK' },
      { label: 'Spot UV Front', value: 'SPOTUVFRONT' },
      { label: 'Spot UV Back', value: 'SPOTUVBACK' },
      { label: 'Multi-page PDF', value: 'MULTIPAGEPDF' },
      { label: 'Die Line', value: 'DIELINE' },
    ];

    const artworkOptions = [
      { label: 'Requires artwork', value: 'true' },
      { label: 'Artwork not required', value: 'false' },
    ];

    let showQuantities = false;
    const foundCategory = this.state.categories.find((c) => c._id === this.props.category);
    if (foundCategory) {
      showQuantities = foundCategory.isQuantitiesFixed;
    }

    return (
      <Form onSubmit={this.props.handleSubmit}>
        <Header attached="top" as="h5">
          Select a Category
        </Header>
        <Segment attached>
          <Field
            name="category"
            component={semanticFormDropdownField}
            as={Dropdown}
            options={this.state.categories.map((c) => ({ text: c.name, value: c._id }))}
            multiple={false}
            selection
            fluid
            // /label='Category'
            placeholder="Category"
          />

          {this.props.category && (
            <Field
              name="categoryOther"
              component={semanticFormField}
              as={Form.Input}
              label={'Other'}
            />
          )}
        </Segment>

        {this.props.category && (
          <Segment basic padded={false} style={{ padding: 0 }}>
            <Header attached="top" as="h5">
              Select all attributes
            </Header>
            <Segment attached>{this.renderAttributes()}</Segment>
          </Segment>
        )}

        {this.renderSizeParams()}

        {showQuantities && (
          <div>
            <Header attached="top" as="h5">
              Quantites
            </Header>
            <Segment attached>
              <Field name="quantities" component={DropdownTags} />
              <Message size="small">Enter each quantity and hit enter.</Message>
            </Segment>
          </div>
        )}

        <Header attached="top" as="h5">
          Select Add-ons
        </Header>
        <Segment attached>
          <Field
            name="addOns"
            component={semanticFormDropdownField}
            as={Dropdown}
            options={this.state.addOns.map((c) => ({ text: c.name, value: c._id }))}
            multiple={true}
            selection
            fluid
            // /label='Category'
            placeholder="Add Ons..."
          />
        </Segment>

        <Header attached="top" as="h5">
          Artwork Settings
        </Header>
        <Segment attached>
          <Field
            name="isArtworkRequired"
            component={semanticFormRadioGroupField}
            label={'Require artwork upload'}
            options={artworkOptions}
            normalize={normalizeBoolean}
            format={formatBoolean}
          />
          {this.props.isArtworkRequired && (
            <Field
              name="artworkSlots"
              component={semanticFormCheckboxGroupField}
              options={artworkSequenceOptions}
              label={'What files are required?'}
            />
          )}
        </Segment>

        <Header attached="top" as="h5">
          Shipping Settings
        </Header>
        <Segment attached>
          <Form.Group>
            <Field
              name="isShippable"
              component={semanticFormRadioGroupField}
              label={''}
              options={[
                { label: 'Product is shippable', value: 'true' },
                { label: 'Not a shippable product', value: 'false' },
              ]}
              normalize={normalizeBoolean}
              format={formatBoolean}
            />
          </Form.Group>

          {this.props.isShippable && (
            <Form.Group widths={'equal'}>
              <Field
                name="weightUnit"
                component={semanticFormField}
                as={Form.Input}
                label={'Weight'}
              />
              <Field
                name="weightBasis"
                component={semanticFormField}
                as={Form.Input}
                label={'Quantity for weight'}
              />
            </Form.Group>
          )}
        </Segment>

        <Header attached="top" as="h5">
          Other Settings
        </Header>
        <Segment attached="bottom">
          <Field
            name="isNameStatic"
            component={semanticFormRadioGroupField}
            label={''}
            options={[
              { label: 'Generate from attributes', value: 'false' },
              { label: 'Enter a static name', value: 'true' },
            ]}
            normalize={normalizeBoolean}
            format={formatBoolean}
          />

          {this.props.isNameStatic && (
            <Field
              name="staticName"
              component={semanticFormField}
              as={Form.Input}
              label={'Static name'}
            />
          )}

          <Field
            name="description"
            component={semanticFormField}
            as={Form.TextArea}
            label={'Optional Description'}
            type="textarea"
          />

          <Field
            name="isCustomTrimEnabled"
            component={semanticFormToggleField}
            as={Form.Checkbox}
            label={'Enable custom trim'}
            type="checkbox"
          />

          <Field
            name="isDesignEnabled"
            component={semanticFormToggleField}
            as={Form.Checkbox}
            label={'Enable design service'}
            type="checkbox"
          />
          {this.props.isDesignEnabled && (
            <Field
              name="designPrice"
              component={semanticFormField}
              as={Form.Input}
              label={'Design price'}
            />
          )}
        </Segment>
        <Button primary disabled={this.props.pristine || this.props.submitting}>
          Submit
        </Button>
        <Link to={'/products/products'}>
          <Button type="button">Back</Button>
        </Link>
      </Form>
    );
  }
}

const formName = 'product';
const reduxedForm: any = reduxForm({
  form: formName,
  validate,
  // asyncValidate,
  // asyncBlurFields: [ 'hrCode' ]
})(ProductForm as any);

const selector = formValueSelector(formName);

const mapStateToProps = (state) => ({
  category: selector(state, 'category'),
  quantities: selector(state, 'quantities'),
  isArtworkRequired: selector(state, 'isArtworkRequired'),
  isShippable: selector(state, 'isShippable'),
  isDesignEnabled: selector(state, 'isDesignEnabled'),
  isNameStatic: selector(state, 'isNameStatic'),
});

const mapDispatchToProps = (dispatch) => ({
  changeFieldValue: (field, value) => dispatch(change(formName, field, value)),
});
export default connect(mapStateToProps, mapDispatchToProps)(reduxedForm) as any;
