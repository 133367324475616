/* eslint-disable no-nested-ternary */

import React, { useState } from 'react';
import { Badge, Button, Tag, Tooltip } from '@chakra-ui/react';
import { RepeatIcon } from '@chakra-ui/icons';
import { Link, RouteComponentProps } from 'react-router-dom';
import {
  useList,
  ListPage,
  basicQueryMap,
  generateOperatorOptions,
  Operator,
} from '@inkcloud/components';
import feathers, { icapi } from '../../../bootstrap/feathers';
import DateWithHover from '../../../common/DateWithHover';

import AddModal from './AddModal';

interface ListProps {}

const serviceName = 'artwork-transports';
const pathPrefix = '/admin/artwork-transporter';

const filterQueryMap = (values) => basicQueryMap(values, {});

export async function getFilterDefinition() {
  const definition = [
    {
      label: 'Date:Created',
      value: 'createdAt',
      operators: generateOperatorOptions([Operator.Between]),
      type: 'date',
    },
    {
      label: 'Order Item ID',
      value: 'orderItemHumanId',
      operators: generateOperatorOptions([Operator.Like]),
    },
    {
      label: 'Production Channel',
      value: 'productionChannel',
      operators: generateOperatorOptions([Operator.Is, Operator.IsNot]),
      valueOptions: [],
      asyncValueOptions: async () =>
        feathers
          .service('production/channels')
          .find({
            query: {
              $np: '1',
              $sort: { name: 1 },
            },
          })
          .then((results) => results.map((r) => ({ value: r._id, label: r.name }))),
      type: 'multi-select',
    },
    {
      label: 'Status',
      value: 'status',
      operators: generateOperatorOptions([Operator.Is, Operator.IsNot]),
      valueOptions: [
        { value: 'pending', label: 'pending' },
        { value: 'in-progress', label: 'In Progress' },
        { value: 'complete', label: 'Complete' },
        { value: 'failed', label: 'Failed' },
      ],
      type: 'select',
    },
    {
      label: 'Write To',
      value: 'writeTo',
      operators: generateOperatorOptions([Operator.Like]),
    },
  ];

  return definition.sort((a: any, b: any) => a.label.localeCompare(b.label));
}

export const List: React.FunctionComponent<ListProps & RouteComponentProps<any>> = (props) => {
  const {
    match: { params },
    location,
    history,
  } = props;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const useListValues = useList({
    feathers: ['find', serviceName, [{ query: { $populate: ['orderItem', 'productionChannel'] } }]],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  const handleDuplicateTransport = (artworkTransport) => async () => {
    await feathers.service('artwork-transports').create({
      order: artworkTransport.order,
      orderItem: artworkTransport.orderItem._id,
      orderItemHumanId: artworkTransport.orderItem.humanId,
      url: artworkTransport.url,
      writeTo: artworkTransport.writeTo,
      productionChannel: artworkTransport.productionChannel?._id,
    });

    useListValues.reload();
  };

  const tableDefinition = [
    {
      key: 'createdAt',
      label: 'Date',
      sortable: true,
      cell: (v) => v && <DateWithHover value={v} />,
    },
    {
      key: 'orderItem',
      label: 'Order Item',
      sortable: true,
      cell: (v, r) => r.orderItem?.humanId,
    },
    {
      key: 'writeTo',
      label: 'Write To',
      sortable: true,
      cell: (v, r) => v,
    },
    {
      key: 'productionChannel',
      label: 'Production Channel',
      sortable: true,
      cell: (v, r) =>
        v?.name ? (
          <Tag colorScheme="blue" variant={'outline'}>
            {v.name}
          </Tag>
        ) : null,
    },
    {
      key: 'status',
      label: 'Status',
      sortable: true,
      cell: (v, r) => (
        <Badge
          mr={4}
          colorScheme={r.status === 'failed' ? 'red' : r.status === 'complete' ? 'green' : 'yellow'}
        >
          {v}
        </Badge>
      ),
    },
    {
      key: 'productionChannel',
      label: '',
      cell: (v, r) => (
        <Tooltip label="Requeue this transport record" aria-label="Trigger additional Transport">
          <Button
            size="sm"
            variant={'outline'}
            colorScheme={'blue'}
            leftIcon={<RepeatIcon />}
            onClick={handleDuplicateTransport(r)}
          >
            Requeue
          </Button>
        </Tooltip>
      ),
    },
  ];

  const headerActions = (
    <Button colorScheme="blue" size="sm" onClick={() => setIsModalOpen(true)}>
      New
    </Button>
  );

  return (
    <>
      <ListPage
        header={'Artwork Transporter'}
        subheader="Log"
        useListValues={useListValues}
        history={history}
        pathPrefix={pathPrefix}
        tableDefintion={tableDefinition}
        withCheckboxes={true}
        headerActions={headerActions}
        getFilterDefinition={getFilterDefinition}
      />

      {isModalOpen && (
        <AddModal onReload={() => useListValues.reload()} onClose={() => setIsModalOpen(false)} />
      )}
    </>
  );
};
