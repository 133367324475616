import * as React from 'react';
import { Modal, TextArea, Form, Message, Button, Icon } from 'semantic-ui-react';
import Feathers from './../../../bootstrap/feathers';
// import { Attachment } from './Attachment'

import { ImageUpload } from './../../products/products/stock-product/StockForm/ImageUpload';

import { connect } from 'react-redux';

interface ConnectProps {
  user?: any;
}

interface MessageModalProps {
  id: string;
  isOpen: boolean;
  onClose: () => void;
  onReload: () => void;
  itemId: string;
}

const { useState } = React;

export const MessageModalPre: React.FunctionComponent<MessageModalProps & ConnectProps> = (
  props: any
) => {
  const { id, isOpen, onClose, onReload, itemId, user } = props;

  const [content, setContent] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isSending, setIsSending] = useState(false);

  const handleUploadAttachment = (files: any) => {
    setUploadedFiles((uploadedFiles) => uploadedFiles.concat(files));
  };

  const handleRemoveFile = (id: string) => {
    const filtered = uploadedFiles?.filter((f) => f._id !== id) ?? [];

    setUploadedFiles(filtered);
  };

  const handleSubmit = async (e) => {
    setResponseMessage('');
    setErrorMessage('');
    setIsSending(true);
    const fileIds = uploadedFiles?.map((f) => f._id) ?? [];

    let payload: any = {
      customQuoteId: id,
      user: user._id,
      content,
      attachments: fileIds,
    };

    if (itemId) {
      payload = {
        ...payload,
        itemId,
      };
    }

    try {
      const res: any = await Feathers.service('/custom-quotes/add-message').create(payload, {
        query: {},
      });

      if (res) {
        setContent('');
        setUploadedFiles([]);
        setResponseMessage(res.message);
        setIsSending(false);
        onReload();
      }
    } catch (e) {
      setIsSending(false);
      e.code && e.code < 500
        ? setErrorMessage(e.message)
        : setErrorMessage('We are experiencing technical difficulties. Please try again');
    }
  };

  return (
    <>
      <Modal open={isOpen} onClose={onClose} closeIcon>
        <Modal.Header>Add new message</Modal.Header>
        <Modal.Content>
          <Form className={`${errorMessage ? 'error' : ''} ${responseMessage ? 'success' : ''}`}>
            <TextArea value={content} onChange={(e, data: any) => setContent(data.value)} />

            {/* <Attachment onUpload={files => {
            handleUploadAttachment(files)
          }}
            showFiles={false}
          /> */}

            <ImageUpload
              onUpload={(files) => {
                if (files) {
                  handleUploadAttachment(files);
                }
              }}
              showFiles={false}
              api="/uploads-general"
            />
            <div>
              {/* {
              uploadedFiles?.map((file: any, index: number) => <Button key={index} size='mini' icon labelPosition='right'>{file.originalFilename} <Icon name='close' onClick={() => handleRemoveFile(file._id)} /></Button>) ?? null
            } */}
              {uploadedFiles?.map((upload: any, index: number) => (
                <p key={index}>
                  <Icon name="file alternate outline" />
                  {upload?.originalFilename}
                  <Icon name="close" onClick={() => handleRemoveFile(upload._id)} />
                </p>
              )) ?? ''}
            </div>
            <br />
            {responseMessage && <Message success content={responseMessage} />}
            {errorMessage && <Message error content={errorMessage} />}
            <Button
              primary
              onClick={handleSubmit}
              disabled={isSending}
              loading={isSending}
              content="Submit"
            />
          </Form>
        </Modal.Content>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export const MessageModal = connect(mapStateToProps, null)(MessageModalPre);
