// import * as React from 'react';
// import { Form, Segment, Modal, Button, Message } from 'semantic-ui-react';
// import { AgGridReact } from 'ag-grid-react';
// import { ColumnApi, GridApi } from 'ag-grid';

// import { Link, RouteComponentProps } from 'react-router-dom';
// import { PageHeaderRow } from '@inkcloud/components';
// import { ReportInputs } from '../ReportInputForm';
// import Feathers from '../../../bootstrap/feathers';
// import { ExportModal } from '../ExportModal';

// const { useState } = React;

// interface ShippedOrdersFlattenedReportProps extends RouteComponentProps<any> {}

// function loadData(values, isExport = false) {
//   const params: any = { query: {} };
//   // if (isExport) {
//   //   params.query.$export = 1;
//   // }

//   console.log('values', values);
//   return Feathers.service('/reports/v3/results').create(
//     {
//       query: {
//         start: values.start,
//         end: values.end,
//       },
//       microsite: values.microsites[0],
//       reportKey: 'ms-shipped-orders',
//       reportName: 'MS Shipped Orders',
//     },
//     params
//   );
// }

// let containerStyle = {
//   height: 500,
//   // /width: 500
// };

// function orderItemHumanIdComparator(valueA, valueB, nodeA, nodeB, isInverted) {
//   const arrA = valueA.split('-');
//   const arrB = valueB.split('-');

//   const diff = Number(arrA[0]) - Number(arrB[0]);
//   if (diff !== 0) {
//     return diff;
//   } else {
//     return Number(arrA[1]) - Number(arrB[1]);
//   }
// }

// export const ShippedOrdersFlattenedReport: React.FunctionComponent<
//   ShippedOrdersFlattenedReportProps & RouteComponentProps
// > = (props) => {
//   const [microsites, setMicrosites] = useState([]);
//   const [isLoading, setIsLoading] = useState(false);
//   const [micrositeList, setMicrositeLists] = useState(null);
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   const [values, setValues] = useState({ start: null, end: null, microsites: [] });
//   const [data, setData] = useState(null);

//   const [errorMessage, setErrorMessage] = useState('');

//   const handleSubmit = async () => {
//     setErrorMessage('');
//     try {
//       setIsLoading(true);
//       const result = await loadData(values, false);
//       // setData(result);
//       setIsLoading(false);
//       props.history.push('/reports/results');
//     } catch (e) {
//       setIsLoading(false);
//       e.code && e.code < 500
//         ? setErrorMessage(e.message)
//         : setErrorMessage('We are experiencing technical difficulties. Please try again');
//     }
//   };

//   React.useEffect(() => {
//     const loadMicrosites = async () => {
//       const list = (await Feathers.service('/microsites').find({
//         query: { $sort: { name: 1 }, $np: 1 },
//       })) as any;
//       setMicrositeLists(list.map((m) => ({ value: m._id, text: m.name })));
//     };

//     loadMicrosites();
//   }, []);

//   return (
//     <div>
//       <PageHeaderRow header="Shipped Orders Flattened" subheader="Reports"></PageHeaderRow>
//       <Message
//         warning
//         header="Reminder"
//         content="The date range is for when the order shipped and NOT when the order was created."
//       />
//       <ReportInputs
//         onSubmit={handleSubmit}
//         showOperations={false}
//         values={values}
//         onChange={(v) => setValues(v)}
//         micrositeList={micrositeList}
//       />
//       <Segment loading={isLoading}>
//         {!data && !errorMessage && <Message info content="Submit Form Above to view report data" />}
//         {errorMessage && <Message error content={errorMessage} />}
//         <br />
//       </Segment>
//     </div>
//   );
// };

import React, { useState } from 'react';
import { Box, Button } from '@chakra-ui/react';

import { useHistory } from 'react-router-dom';
import { PageHeaderRow } from '@inkcloud/components';
import Feathers from '../../../bootstrap/feathers';
import FilterForm from '../FilterForm2';

function loadData(values) {
  const params: any = { query: {} };

  const product = values?.products?.map((p) => p.value);

  return Feathers.service('/reports/v3/results').create(
    {
      query: {
        start: values.start,
        end: values.end,
        product,
      },
      microsite: values?.microsites?.value,
      reportKey: 'ms-shipped-orders',
      reportName: 'MS Shipped Orders',
    },
    params
  );
}

export default function ShippedOrdersFlattenedReport() {
  const history = useHistory();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [values, setValues] = useState({ start: null, end: null, microsites: [] });

  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async () => {
    setErrorMessage('');
    try {
      setIsSubmitting(true);
      const res = await loadData(values);

      history.push('/reports/results');
    } catch (e) {
      const errMsg =
        e?.code < 500 ? e?.message : 'We are experiencing technical difficulties. Please try again';
      setErrorMessage(errMsg);
    }
    setIsSubmitting(false);
  };

  return (
    <div>
      <PageHeaderRow header="Shipped Orders Flattened" subheader="Reports">
        <Button onClick={() => history.goBack()}>Back</Button>
      </PageHeaderRow>

      <Box p={3} borderWidth={1} borderRadius="md" bg="white">
        <FilterForm
          values={values}
          isSubmitting={isSubmitting}
          hasMicrosites={true}
          errorMessage={errorMessage}
          onChange={(v) => {
            setErrorMessage('');
            setValues(v);
          }}
          onSubmit={handleSubmit}
        />
      </Box>
    </div>
  );
}
