// import * as React from 'react';
// import { Button, Input, Dropdown, Segment, Form } from 'semantic-ui-react';
// import { FeathersDropdown } from '@inkcloud/components';

// export interface FilterFormProps {
//   value: any;
//   onChange: Function;
//   onReset: Function;
// }

// function initMapper(value) {
//   const defaults = {
//     name: '',
//     key: '',
//     tags: [],
//     microsite: [],
//   }

//   return Object.keys(defaults).reduce((p, cur) => {
//     if (!p[cur]) {
//       p[cur] = defaults[cur];
//     }
//     return p;
//   }, value)
// }

// export const FilterForm: React.FunctionComponent<FilterFormProps> = (props) => {
//   const { value: preInitValue, onChange, onReset } = props;

//   const value = initMapper(preInitValue);
//   const handleChange = (field, value) => onChange({ ...props.value, [field]: value });

//   const handleAddition = (e, data: any) => {

//     const foundTag = value.tags.find(tag => tag === data.value);

//     if (!foundTag) {
//       value.tags.concat(data.value)
//     }
//   }

//   return (

//     <Segment>
//       <Form size={'small'}>
//         <Form.Group widths={'equal'}>
//           <Form.Field>
//             <Input placeholder="Name..." value={value.name} onChange={(e, data) => handleChange('name', data.value)} />
//           </Form.Field>
//           <Form.Field>
//             <Form.Input placeholder="Key..." value={value.key} onChange={(e, data) => handleChange('key', data.value)} />
//           </Form.Field>
//           <Form.Field>
//             <Dropdown
//               value={value.tags}
//               selection search clearable multiple allowAdditions
//               placeholder='Tags...'
//               options={value.tags.length > 0 ? value.tags.map((tag: string) => ({ text: tag, value: tag, key: tag })) : []}
//               onAddItem={handleAddition}
//               onChange={(e, data) => handleChange('tags', data.value)}
//               noResultsMessage='Not found'
//             />

//           </Form.Field>
//           <Form.Field>
//             <FeathersDropdown
//               selection fluid search clearable multiple
//               placeholder="Microsites"
//               serviceName='/microsites'
//               query={{ $select: ['name'], $sort: { name: 1 }, $np: 1 }}
//               resultMapper={(r) => ({ value: r._id, text: r.name })}
//               value={value.microsite}
//               onChange={(e, data) => handleChange('microsite', data.value)}
//             />
//           </Form.Field>
//         </Form.Group>
//         <Form.Group widths={'equal'}>
//           <Form.Field >
//             <Button size="mini" content="Reset Form" onClick={() => onReset()} />
//           </Form.Field>
//         </Form.Group>
//       </Form>
//     </Segment>
//   );
// };

import { generateOperatorOptions, Operator } from '@inkcloud/components';

import Feathers from '../../../bootstrap/feathers';

export default async function getFilterDefinition() {
  const definition = [
    {
      label: 'Name',
      value: 'name',
      operators: generateOperatorOptions([Operator.Like]),
    },
    {
      label: 'Key',
      value: 'key',
      operators: generateOperatorOptions([Operator.Like]),
    },
    {
      label: 'Tags',
      value: 'tags',
      operators: generateOperatorOptions([Operator.Is]),
      valueOptions: [],
      type: 'multi-select-create',
    },
    {
      label: 'Microsite',
      value: 'microsite',
      operators: generateOperatorOptions([Operator.Is]),
      valueOptions: [],
      asyncValueOptions: async () =>
        Feathers.service('/microsites')
          .find({
            query: {
              $select: ['name'],
              $sort: { name: 1 },
              $np: 1,
            },
          })
          .then((results) => results.map((r) => ({ value: r._id, label: r.name }))),
      type: 'multi-select',
    },
  ];

  return definition.sort((a: any, b: any) => a.label.localeCompare(b.label));
}
