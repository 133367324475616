import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { PageHeaderRow, PreLoader, useFeathers } from '@inkcloud/components';
import { Box, Button } from '@chakra-ui/react';

import { Feathers } from '../../../bootstrap/feathers/feathers';
import LabelForm from './LabelForm';

const serviceName = 'tenant-settings';
const service = Feathers.service(serviceName);
const commonParams = {
  query: {
    $select: ['labelMaps'],
  },
};
export default function Detail() {
  const authUser = useSelector((state: any) => state.auth.user);

  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [state, reload] = useFeathers('get', serviceName, [authUser.business, commonParams], true);

  const handleSubmit = async (value) => {
    setErrorMessage('');
    setIsSubmitting(true);
    try {
      const res = await service.patch(authUser.business, value);
      history.goBack();
    } catch (e) {
      const errMsg = e?.code < 500 ? e?.message : 'Something went wrong';
      setErrorMessage(errMsg);
    }
    setIsSubmitting(false);
  };

  if (!state.data) {
    return <PreLoader />;
  }

  return (
    <>
      <PageHeaderRow header="Labels" subheader="Settings" subheaderId={''}>
        <Button onClick={() => history.goBack()}> Back </Button>
      </PageHeaderRow>
      <Box bg="white" borderWidth="1px" borderRadius="md" p={3} mt={3}>
        <LabelForm
          initialValues={state?.data ?? {}}
          isSubmitting={isSubmitting}
          errorMessage={errorMessage}
          onSubmit={handleSubmit}
        />
      </Box>
    </>
  );
}
