import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Segment } from 'semantic-ui-react';
import { PageHeaderRow, PreLoader, useFeathers } from '@inkcloud/components';
import feathers from '../../../bootstrap/feathers';
import { ShippingForm } from './ShippingForm';
import { connect } from 'react-redux';

interface DetailProps {}

export interface ConnectedProps {
  user?: any;
}

const { useState } = React;

const pathPrefix = `/settings/shipping`;
const serviceName = '/tenant-settings';
const commonParams = { query: { $select: ['shipping'] } };

export const PreDetail: React.FunctionComponent<
  DetailProps & ConnectedProps & RouteComponentProps<{ id?: string }>
> = (props) => {
  const { history, user } = props;

  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [state, reload] = useFeathers('get', serviceName, [user.business, commonParams], true);

  const handleSubmit = async (values) => {
    values.allowedShippingServicesOnCart = values.allowedShippingServicesOnCart?.map(
      (s) => s.value
    );

    try {
      setErrorMessage('');
      setIsLoading(true);
      await feathers
        .service('tenant-settings/shipping')
        .patch(user.business, values, { query: {} });
      setIsLoading(false);
      history.push('/settings');
    } catch (e) {
      setIsLoading(false);
      e.code && e.code < 500
        ? setErrorMessage(e.message)
        : setErrorMessage('We are experiencing technical difficulties. Please try again');
    }
  };

  if (!state.data) {
    return <PreLoader />;
  }

  const initialData = { ...state.data };
  return (
    <div>
      <PageHeaderRow header="Shipping" subheader="Settings">
        <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
      </PageHeaderRow>
      <Segment>
        {
          <ShippingForm
            shippingProps={initialData && initialData.shipping}
            isLoading={isLoading}
            user={user}
            errorMessage={errorMessage}
            onSubmit={handleSubmit}
          />
        }
      </Segment>
    </div>
  );
};

const mapStateToProps = (state): ConnectedProps => ({
  user: state.auth.user || {},
});

export const Detail = connect(mapStateToProps)(PreDetail as any);
