/**
 * Reduxify Feathers services easily here. Also reduxify Feathers authentication
 */
import { feathersConfigure } from '@inkcloud/components';

import reduxifyServices, { getServicesStatus as getStatus } from 'feathers-redux';
import feathersReduxifyAuthentication from 'feathers-reduxify-authentication';
import { Application } from '@feathersjs/feathers';
import { ServiceTypes } from '@inkcloud/icapi-types';
import { Feathers as app, feathersModeSwitch as feathersModeSwitchPre, socket } from './feathers';
import { apiServices } from './apiServices';
import { ability, rolesToPermissionsList } from '../../common/permissions';

export { socket } from './feathers';

app.service('/authentication').hooks({
  after(hook) {
    // console.log('after auth hook', hook.result);
    hook.result.data = {
      ...(hook.result.user || {}),
      key: hook.result.key,
    };

    // console.log('jwt', localStorage['feathers-jwt']);
    // socket.authenticate().then(response => {
    //   console.log('socket auth response!', response);
    // });

    ability.update(rolesToPermissionsList(hook.result.user.roles2));
    return hook;
  },
} as any);

export default app;
export const icapi = app as Application<ServiceTypes>;
export const services = reduxifyServices(app, apiServices, {});
export const getServicesStatus = getStatus;
export const feathersModeSwitch = feathersModeSwitchPre;
export const feathersAuthentication = feathersReduxifyAuthentication(
  app,
  {
    isUserAuthorized: (user) => {
      return true;
      // return user.isVerified
    },
  } // WE INSIST USER IS 'verified' TO AUTHENTICATE
);

feathersConfigure(app);
