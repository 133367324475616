import * as React from 'react';
import { Page, Text, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';

export interface BillToValue {
  company?: string;
  name?: string;
  address1?: string;
  address2?: string;
  city?: string;
  stateProvince?: string;
  postalCode?: string;
  countryCode?: string;
}

export interface IBillToProps {
  style?: Style;
  textStyle?: Style;
  value: BillToValue;
}

export function BillTo(props: IBillToProps) {
  const { style = {}, textStyle = {}, value } = props;

  const {
    company = '',
    name = '',
    address1 = '',
    address2 = '',
    city = '',
    stateProvince = '',
    postalCode = '',
    countryCode = '',
  } = value || {};

  return (
    <View style={style}>
      {company ? <Text style={textStyle}>{company}</Text> : <Text></Text>}
      {name ? <Text style={textStyle}>{name}</Text> : <Text></Text>}
      {address1 ? <Text style={textStyle}>{address1}</Text> : <Text></Text>}
      {address2 ? <Text style={textStyle}>{address2}</Text> : <Text></Text>}
      {
        <Text style={textStyle}>
          {city} {stateProvince} {postalCode}
        </Text>
      }
      {countryCode ? <Text style={textStyle}>{countryCode}</Text> : <Text></Text>}
    </View>
  );
}
