// import * as React from 'react';
// import { Button, Header, Message, Segment } from 'semantic-ui-react';
// import { RouteComponentProps } from 'react-router-dom';
// import { DetailWrapper, DetailFeaturesProps } from './../../../bootstrap/DetailWrapper';
// import {AttributeForm} from './AttributeForm';
// import { PageHeaderRow, PreLoader } from '@inkcloud/components';
// import { Link } from 'react-router-dom';

// export function Detail (props: DetailFeaturesProps ) {
//   const { data } = props;
//   return (
//     <div>
//       <PageHeaderRow header={!!props.match.params.id ? 'Edit' : 'New'} subheader='Product Attribute' subheaderId={props.data && props.data.humanId}>
//         <Link to='/products/attributes'><Button content="Back" size={'mini'} /></Link>
//       </PageHeaderRow>
//       <Segment >
//         <AttributeForm
//           initialValues={data}
//           onSubmit={props.handleSubmit}
//           isEdit={!!props.match.params.id}
//         />
//       </Segment>
//     </div>
//   );
// }

// export default DetailWrapper(Detail, 'productsAttributes', {
//   submitRedirect: '/products/attributes'
// });

import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Segment, Message } from 'semantic-ui-react';
import { PageHeaderRow, PreLoader, useFeathers } from '@inkcloud/components';
import feathers from '../../../bootstrap/feathers';
import { AttributeForm } from './AttributeForm';

interface DetailProps {
  products: { value: string; text: string }[];
  categories: { value: string; text: string }[];
}

const serviceName = '/products-legacy/attributes';
const service = feathers.service(serviceName);
const commonParams = { query: {} };

const { useState } = React;

export const Detail: React.FunctionComponent<DetailProps & RouteComponentProps<{ id?: string }>> = (
  props
) => {
  const {
    match: {
      params: { id },
    },
    history,
    products,
    categories,
  } = props;

  const pathPrefix = `/products/attributes`;

  const [errorMessage, setErrorMessage] = useState('');

  const isEdit = !!id;
  const [state, reload] = useFeathers('get', serviceName, [id, commonParams], true);
  const handleSubmit = async (values) => {
    try {
      setErrorMessage('');
      isEdit
        ? await service.patch(id, values, commonParams)
        : await service.create(values, commonParams);
      history.push(pathPrefix);
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const pageTitle = isEdit ? 'Edit' : 'New';

  if (isEdit && !state.data) {
    return <PreLoader />;
  }

  const initialData = { ...state.data };
  return (
    <div>
      <PageHeaderRow header={`${pageTitle} Attribute`} subheader="Product" subheaderId={''}>
        <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
      </PageHeaderRow>

      <Segment>
        {(!isEdit || state.data) && (
          <AttributeForm
            errorMessage={errorMessage}
            initialValues={initialData}
            onSubmit={handleSubmit}
          />
        )}
        {errorMessage && <Message error content={errorMessage} />}
      </Segment>
    </div>
  );
};
