import * as React from 'react';
import { Button, Checkbox, Header, Segment, Table, Label, Popup } from 'semantic-ui-react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { FormattedDate } from 'react-intl';
import Feathers from '../../../bootstrap/feathers';
import { useList, PageHeaderRow, EmptyWrapper, PaginatorWrapper } from '@inkcloud/components';
import { FilterForm } from './FilterForm';
import { ExportModal } from '../../reports/ExportModal';

interface ListProps {}

const serviceName = 'microsites/customer/groups';

const filterQueryMap = (values) => {
  const filterMap: any = {
    name: values.name ? { $LIKE: values.name } : undefined,
  };

  return [].reduce(
    (m, field) => ({ ...m, [field]: values[field] ? values[field] : undefined }),
    filterMap
  );
};

export const List: React.FunctionComponent<
  ListProps & RouteComponentProps<{ micrositeId: string }>
> = (props) => {
  const {
    match: { params },
    location,
    history,
  } = props;
  const [isLoading, setIsLoading] = React.useState(false);
  const [isExportModalOpen, setIsExportModalOpen] = React.useState(false);
  const [exportUrl, setExportUrl] = React.useState(null);
  const {
    state,
    reload,
    handleDelete,
    selectedItems,
    clearAll,
    setChecked,
    someSelected,
    toggleAll,
    filter,
    setFilter,
    sort,
    handleSort,
  } = useList({
    feathers: ['find', serviceName, [{ query: { microsite: params.micrositeId } }]],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { name: 1 },
  });

  const { queryResult, isLoading: isStateLoading } = state as any;

  const handleExportClick = async () => {
    setIsLoading(true);
    const result = await Feathers.service('microsites/customers-group-export').create(
      { microsite: params.micrositeId },
      { query: { $export: '1' } }
    );
    setIsLoading(false);
    setExportUrl(result.url);
    setIsExportModalOpen(true);
  };

  const handleClose = () => {
    setIsLoading(false);
    setExportUrl(null);
    setIsExportModalOpen(false);
  };

  // if (!queryResult) {
  //   return null;
  // }

  const pathPrefix = `/settings/microsites/${params.micrositeId}`;

  return (
    <div>
      <PageHeaderRow header="Groups" subheader="Microsite">
        {/* <SubMenu match={props.match} history={history} /> */}
        <Link to={pathPrefix}>
          <Button content="Back" size={'mini'} />
        </Link>
        <Button
          content="Permissions Export"
          primary
          size={'mini'}
          onClick={handleExportClick}
          disabled={isLoading}
          loading={isLoading}
        />

        <Link to={`${pathPrefix}/groups/add`}>
          <Button content="New" primary size={'mini'} />
        </Link>
      </PageHeaderRow>

      <FilterForm value={filter} onChange={(v) => setFilter(v)} onReset={() => setFilter({})} />
      <EmptyWrapper queryResult={queryResult} isStateLoading={state.isLoading}>
        <Segment loading={state.isLoading}>
          <PaginatorWrapper.Top
            filter={filter}
            reload={reload}
            data={queryResult as any}
            pathPrefix={`${pathPrefix}/groups/`}
          />
          <Table size={'small'} sortable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  <input type="checkbox" onChange={toggleAll} />
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={sort.createdAt ? sort.createdAt : null}
                  onClick={() => handleSort('createdAt')}
                >
                  Created
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={sort.name ? sort.name : null}
                  onClick={() => handleSort('name')}
                >
                  Name
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={sort.globalPermissions ? sort.globalPermissions : null}
                  onClick={() => handleSort('globalPermissions')}
                >
                  Global Permissions
                </Table.HeaderCell>
                <Table.HeaderCell>Permissions</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {queryResult &&
                (queryResult as any).data.map((r) => (
                  <Table.Row key={r._id}>
                    <Table.Cell>
                      <input
                        type="checkbox"
                        value={r._id}
                        checked={selectedItems[r._id] || false}
                        onChange={setChecked}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <FormattedDate value={r.createdAt} />
                    </Table.Cell>
                    <Table.Cell>{r.name}</Table.Cell>
                    <Table.Cell>{r.globalPermissions ? 'Global' : 'Regular'}</Table.Cell>
                    <Table.Cell>
                      {r?.permissions?.map((p) => (
                        <div>
                          <Label
                            size="mini"
                            detail={p?.action?.length ? `[${p.action.join(', ')}]` : ''}
                            content={p.resource}
                          />
                        </div>
                      ))}
                    </Table.Cell>
                    <Table.Cell textAlign="right">
                      <Popup
                        size="mini"
                        content="Edit"
                        trigger={
                          <Link
                            to={`/settings/microsites/${props.match.params.micrositeId}/groups/${r._id}`}
                          >
                            <Button size={'mini'} icon={'edit'} />
                          </Link>
                        }
                      />
                    </Table.Cell>
                  </Table.Row>
                ))}
            </Table.Body>
          </Table>
          <PaginatorWrapper.Bottom
            reload={reload}
            data={queryResult as any}
            pathPrefix={`${pathPrefix}/groups/`}
          >
            {someSelected && (
              <Button primary type="button" content="Delete" onClick={handleDelete} />
            )}
          </PaginatorWrapper.Bottom>
        </Segment>
      </EmptyWrapper>
      <ExportModal
        isModalOpen={isExportModalOpen}
        handleClose={handleClose}
        exportUrl={exportUrl}
      />
    </div>
  );
};
