import * as React from 'react';
import { Box, Flex, Spacer, Heading, Button, Text, Center, Spinner, Alert } from '@chakra-ui/react';
import { ServiceTypes } from '@inkcloud/icapi-types';
import { useSelector } from 'react-redux';
import PickItemCard from './PickItemCard';
import { icapi } from '../../../bootstrap/feathers';
import PickListScannerPrompt from './PickListScannerPrompt';
import reducer, { initialState, initializer, PickListState, PickListAction } from './reducer';
import { useSkuScan } from './useSkuScan';

interface IPickListScannerProps { }

type ScannerLogItems = Awaited<ReturnType<ServiceTypes['pick-list-scanner-logs']['create']>>;

const PickListScanner: React.FunctionComponent<IPickListScannerProps> = (props) => {
  const [state, dispatch] = React.useReducer(reducer, initialState, initializer);
  // holds ref to response of setTimeout to hide success message after some time
  const timerRef = React.useRef<any>(null);

  const authUser = useSelector((state: any) => state.auth.user);

  React.useEffect(() => {
    // clear a timeout if one is set
    return () => clearTimeout(timerRef.current);
  }, []);

  // const handleSkuScan = (v) => {
  //   // console.log('got this sku scan', v)
  //   dispatch({ type: 'increment-sku-scan', payload: { sku: v } });
  // };

  // // Enable Sku Scan if order is loaded, Otherwise disable it
  // useSkuScan(handleSkuScan, !!state.orderFulfillmentSummary);

  const handleInitialScan = async (v: string) => {
    let order;
    dispatch({ type: 'set-error-message', payload: '' });

    dispatch({ type: 'loading-order-fulfillment-summary', payload: true });

    // If the scan value includes a dash, then we need to strip it off
    let lookupV = v;
    if (v.includes('-')) {
      [lookupV] = v.split('-');
    }

    try {
      order = await icapi.service('orders').find({
        query: {
          $findOne: '1',
          humanId: lookupV,
        },
      });
    } catch (error) {
      dispatch({ type: 'loading-order-fulfillment-summary', payload: false });
      if (error.code === 404) {
        dispatch({ type: 'set-error-message', payload: { order: 'Order not found' } });
        // TODO: handle a NOT FOUND
      } else {
        const errMsg = 'We are experiencing technical difficulties. Please try again';
        dispatch({ type: 'set-error-message', payload: { order: errMsg } });
        // HANDLE OTHER ERROR. Maybe network outage, etc.
      }

      return;
    }

    const orderFulfillmentSummary = await icapi
      .service('orders/fulfillment-summary')
      .create({ order: order._id });

    try {
      const pickListScanLog = await icapi.service('pick-list-scanner-logs').create({
        order: orderFulfillmentSummary.order?._id,
        // items: mappedItems,
        completed: false,
      });

      const filteredOrderFulfillmentSummary = { ...orderFulfillmentSummary };
      filteredOrderFulfillmentSummary.items =
        orderFulfillmentSummary?.items
          ?.filter((i) => i.orderItem?.itemType === 'stock')
          ?.filter((i) => i.status !== 'backorder' && i.status !== 'fulfilled') ?? [];

      // console.log('pickListScanLog', pickListScanLog);
      dispatch({
        type: 'set-order-fulfillment-summary',
        payload: {
          orderFulfillmentSummary: filteredOrderFulfillmentSummary,
          pickListScanLogId: pickListScanLog._id,
        },
      });
    } catch (error) {
      if (error.code === 400) {
        dispatch({ type: 'loading-order-fulfillment-summary', payload: false });
        dispatch({ type: 'set-error-message', payload: { order: 'Order already scanned' } });
      }
    }
  };

  const handleSubmit = async (isComplete = true) => {
    dispatch({ type: 'set-error-message', payload: { submit: '' } });
    try {
      // if (!state.orderFulfillmentSummary) {
      //   throw Error(`Cannot submit pick list. No orderFulfillmentSummary loaded.`)
      // }

      const mappedItems = state.skuScans.map((item) => ({
        orderItem: item.orderItemId,
        orderItemHumanId: item.orderItemHumanId,
        location: item.locationId,
        locationName: item.location,
        product: item.productId,
        productKey: item.sku,
        productDescription: item.description,
        toBePickedQuantity: item.quantity,
        pickedQuantity: item.pickedQuantity,
      }));

      await icapi.service('pick-list-scanner-logs').patch(state.pickListScanLogId, {
        items: mappedItems,
        completed: isComplete,
      });

      // Reset state and set success maged
      dispatch({
        type: 'set-success-message',
        payload: {
          successMessage: `Order #${state?.orderFulfillmentSummary?.order?.humanId
            } pick list saved ${isComplete ? 'complete' : 'incomplete'}`,
        },
      });
      // After 7 seconds, clear the success message

      timerRef.current = setTimeout(() => dispatch({ type: 'clear-success-message' }), 7000);
    } catch (e) {
      const errMsg =
        e?.code < 500 ? e?.message : 'We are experiencing technical difficulties. Please try again';
      dispatch({ type: 'set-error-message', payload: { submit: errMsg } });
    }
  };

  const numComplete = state.skuScans.filter((card) => card.pickedQuantity === card.quantity).length;
  const isComplete = numComplete === state.skuScans.length;
  const hasNoItems = state.orderFulfillmentSummary?.items?.length === 0;
  // console.log('state', state);
  return (
    <Box height={'100%'}>
      {!state.orderFulfillmentSummary && !state.isLoading && (
        <PickListScannerPrompt
          onSubmit={handleInitialScan}
          lastMessage={state.successMessage}
          errorMessage={state?.errorMessage?.order}
        />
      )}
      {state.isLoading && (
        <Center h="600px">
          <Spinner />
        </Center>
      )}
      {state.orderFulfillmentSummary && (
        <Flex height={'100%'} flexDir={'column'}>
          <Flex mb={4}>
            <Heading textTransform={'uppercase'} as={'h5'} size={'sm'}>
              Order #{state.orderFulfillmentSummary.order?.humanId}
            </Heading>
            <Spacer />
            <Heading textTransform={'uppercase'} as={'h5'} size={'xs'}>
              {' '}
              {authUser?.firstName ?? ''} {authUser?.lastName ?? ''}
            </Heading>
          </Flex>
          {/* <Divider mb={4} /> */}
          {hasNoItems ? (
            <Alert status="warning">Order does not have any available items to pick</Alert>
          ) : (
            <Box
              height={500}
              borderWidth={1}
              borderColor={'gray.200'}
              bgColor={'gray.50'}
              overflowY="auto"
              px={2}
            >
              {state.skuScans.map((card, index) => (
                <PickItemCard
                  isActive={state.currentPickIndex === index}
                  key={index}
                  quantity={card.quantity}
                  pickedQuantity={card.pickedQuantity}
                  description={card.description}
                  sku={card.sku}
                  location={card.location}
                  orderItemHumanId={card.orderItemHumanId}
                  onSelect={() => null} // do nothing
                  onScan={(scanCapture: string) => {
                    if (scanCapture.toLowerCase() === card.sku.toLowerCase()) {
                      dispatch({ type: 'increment-sku-scan', payload: { sku: card.sku } });
                    } else {
                      dispatch({
                        type: 'set-error-message',
                        payload: {
                          item: `${scanCapture} does not match current item SKU "${card.sku}`,
                        },
                      });
                    }
                  }}
                />
              ))}
            </Box>
          )}
          <Box>
            <Box my={2} borderWidth={1} borderColor={'blue.200'} bgColor={'blue.50'} px={4} py={2}>
              <Text>
                Picked {numComplete} of {state.skuScans.length} items
              </Text>
            </Box>
            {state.errorMessage?.item && (
              <Alert status="error" my={2}>
                {state.errorMessage.item}
              </Alert>
            )}

            {state.errorMessage?.submit && (
              <Alert status="error" my={2}>
                {state.errorMessage.submit}
              </Alert>
            )}
            <Flex gap={2}>
              <Button flex="1" onClick={() => dispatch({ type: 'reset' })}>
                Cancel
              </Button>
              <Button
                flex="1"
                colorScheme={'gray'}
                isDisabled={isComplete}
                onClick={() => handleSubmit(false)}
              >
                Save Incomplete
              </Button>

              <Button
                flex="1"
                colorScheme={'blue'}
                isDisabled={!isComplete || hasNoItems}
                onClick={() => handleSubmit(true)}
              >
                Save Pick
              </Button>
            </Flex>
          </Box>
        </Flex>
      )}
    </Box>
  );
};

export default PickListScanner;
