// import * as React from 'react';
// import { Button, Form, Input, Dropdown, Popup } from 'semantic-ui-react';
// import { Formik, Form as FormikForm, Field, FieldProps, FieldArray, ErrorMessage } from 'formik';
// import * as Yup from 'yup';
// import { FeathersDropdown } from '@inkcloud/components';
// import Feathers from '../../../../bootstrap/feathers';
// import { SemanticField } from '../../../../common/Form';
// import { slugify2 as slugifyUrl } from '../../../../common'

// interface PageFormProps {
//   initialValues: any;
//   onSubmit: Function;
//   isSubmitting?: boolean;
//   products: { value: string, text: string }[];
//   categories: { value: string, text: string }[];
//   errorMessage: string;
// }

// declare var ENV;

// export const initialMapper = (values) => ({
//   ...values,
//   name: values.name || '',
//   title: values.title || '',
//   template: values.template || '',
//   url: values.url || '',
//   type: values.type || 'basic',
// })

// export const submitMapper = (values: any) => {

//   switch (values.type) {
//     case 'basic':
//       values.category = null
//       values.product = null
//       break;
//     case 'product':
//       values.category = null
//       break;
//     default:
//       values.product = null
//   }

//   return values
// }

// export const PageForm: React.FC<PageFormProps> = (outerProps) => {
//   const PageScheme = Yup.object().shape({
//     name: Yup.string().required('Required'),
//     // product: Yup.string().notRequired(),
//     // category: Yup.string().notRequired(),
//   });

//   return (
//     <Formik
//       initialValues={initialMapper(outerProps.initialValues)}
//       validationSchema={PageScheme}
//       onSubmit={(values, { setSubmitting }) => {
//         outerProps.onSubmit(submitMapper(values));
//       }}
//     >{(props) => {
//       const { values,
//         touched,
//         errors,
//         dirty,
//         isSubmitting,
//         handleChange,
//         handleBlur,
//         handleSubmit,
//         handleReset,
//         setFieldValue,
//         setFieldTouched
//       } = props;

//       const handlePopulateCategory = (e: string) => {
//         if (e) {
//           const found: any = outerProps?.categories?.find((c: any) => c._id === e)

//           setFieldValue('name', found?.name)
//           setFieldValue('title', found?.name)
//           setFieldValue('url', `/products/${found?.hrCode ?? ''}`)
//         }
//       }

//       const handlePopulateProduct = (e: string) => {
//         if (e) {
//           const found: any = outerProps?.products?.find((c: any) => c._id === e)

//           setFieldValue('name', found?.staticName)
//           setFieldValue('title', found?.staticName)
//           setFieldValue('url', `/product/${found?.key ?? ''}`)
//         }
//       }

//       const handleSlugify = (v) => setFieldValue('url', slugifyUrl(v, true))

//       return (
//         <FormikForm className={`ui form ${isSubmitting && !outerProps.errorMessage && 'loading'}`}>
//           <Form.Field>
//             <label>Page Type</label>
//             <Form.Group inline>
//               <Form.Radio
//                 label="Basic"
//                 name="type"
//                 value={values.type}
//                 checked={values.type === 'basic'}
//                 onBlur={handleBlur}
//                 onChange={() => setFieldValue('type', 'basic')}
//               />
//               <Form.Radio
//                 label="Category"
//                 name="type"
//                 value={values.type}
//                 checked={values.type === 'category'}
//                 onBlur={handleBlur}
//                 onChange={() => setFieldValue('type', 'category')}
//               />
//               <Form.Radio
//                 label="Product"
//                 name="type"
//                 value={values.type}
//                 checked={values.type === 'product'}
//                 onBlur={handleBlur}
//                 onChange={() => setFieldValue('type', 'product')}
//               />
//               <Form.Radio
//                 label="Calculator"
//                 name="type"
//                 value={values.type}
//                 checked={values.type === 'product-calculator'}
//                 onBlur={handleBlur}
//                 onChange={() => setFieldValue('type', 'product-calculator')}
//               />
//               <ErrorMessage component="div" name="type" />
//             </Form.Group>
//           </Form.Field>

//           <Form.Group widths="equal">
//             {(values.type === 'category' || values.type === 'product-calculator') &&
//               <div className="field">
//                 <SemanticField
//                   name={'category'}
//                   component={Form.Dropdown}
//                   selection search clearable lazyLoad
//                   options={outerProps?.categories?.map((r: any) => ({ value: r?._id ?? '', text: `${r?.name ?? ''}${r?.hrCode ? ` - ${r.hrCode}` : ''}` }))}
//                   fluid
//                   placeholder='Category...'
//                   onChange={handlePopulateCategory}
//                 />
//               </div>
//             }
//             {values.type === 'product' &&
//               <div className="field">
//                 <SemanticField
//                   name={'product'}
//                   component={Form.Dropdown}
//                   selection search clearable lazyLoad
//                   options={outerProps?.products?.map((r: any) => ({ value: r?._id ?? '', text: `${r?.staticName ?? ''}${r?.key ? ` - ${r.key}` : ''}` }))}
//                   fluid
//                   placeholder='Product...'
//                   onChange={handlePopulateProduct}
//                 />
//               </div>
//             }
//           </Form.Group>

//           {
//             values.type && <>
//               <Form.Group widths="equal">
//                 <Form.Field>
//                   <SemanticField name={'name'} label='Name' component={Form.Input} />
//                   <ErrorMessage component="div" name="name" />
//                 </Form.Field>
//                 <Form.Field>
//                   <SemanticField name={'title'} label='Title' component={Form.Input} />
//                   <ErrorMessage component="div" name="title" />
//                 </Form.Field>
//               </Form.Group>

//               <Form.Group widths="equal">
//                 <Form.Field>

//                   {
//                     (ENV === 'development' || ENV === 'staging') ?
//                       <SemanticField name={'template'} label='Template' component={Form.Input} /> :
//                       <>
//                         <label>Template</label>
//                         <Field
//                           name='template'
//                           render={({ field }: FieldProps<any>) => (
//                             <FeathersDropdown
//                               fluid search selection
//                               serviceName='/store-front/content/pages/list'
//                               query={{ $sort: { name: 1 }, $np: 1 }}
//                               resultMapper={(r) => ({ value: r, text: r })}
//                               {...field}
//                               onChange={(e, data: any) => field.onChange({ target: { value: data.value, name: 'template' } } as any)}
//                             />)}
//                         />
//                       </>
//                   }

//                   <ErrorMessage component="div" name="template" />
//                 </Form.Field>
//                 <Form.Field>
//                   <SemanticField name={'url'} label='URL' component={Form.Input} onChange={handleSlugify} />
//                   <ErrorMessage component="div" name="url" />
//                 </Form.Field>
//               </Form.Group>

//               <Form.Group>
//                 <FieldArray
//                   name="meta"
//                   render={arrayHelpers => (
//                     <div style={{ width: '100%', padding: '0 .5em' }}>
//                       <Form.Field>
//                         <label>Meta</label>
//                       </Form.Field>
//                       {values.meta && values.meta.length > 0 && (
//                         values.meta.map((m, index) => (
//                           <div key={index}>
//                             <Form.Group>
//                               <Form.Field width={16}><Field name={`meta.${index}.key`} placeholder='Key' /></Form.Field>
//                               <Form.Field width={16}><Field name={`meta.${index}.value`} placeholder='Value' /></Form.Field>
//                               <Popup content='Remove meta' trigger={<Button
//                                 type="button"
//                                 onClick={() => arrayHelpers.remove(index)}
//                                 content={"-"}
//                               />} />
//                             </Form.Group>

//                           </div>
//                         ))
//                       )}

//                       <Popup content='Add meta' trigger={<Button
//                         type="button"
//                         onClick={() => arrayHelpers.push({})}
//                         content={"+"}

//                       />} />

//                     </div>
//                   )}
//                 />
//               </Form.Group>

//             </>
//           }

//           <Form.Field>
//             <SemanticField
//               name="isHidden"
//               label="Is Hidden"
//               toggle
//               component={Form.Checkbox} />

//           </Form.Field>
//           <Form.Field>
//             <SemanticField
//               name="requireAuth"
//               label="Require Authentication"
//               toggle
//               component={Form.Checkbox} />

//           </Form.Field>
//           <Button primary type="submit" disabled={isSubmitting && !outerProps.errorMessage}>Save</Button>
//         </FormikForm>
//       )
//     }}
//     </Formik>
//   );
// };

import React, { useEffect, useState } from 'react';
import { useForm, useFieldArray, SubmitHandler } from 'react-hook-form';
import type { ModelTypes } from '@inkcloud/icapi-types';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  Box,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Stack,
  Button,
  Alert,
  Switch,
  Radio,
  RadioGroup,
  IconButton,
  Heading,
} from '@chakra-ui/react';

import { FaTimes } from 'react-icons/fa';

import { AsyncSelect, Select } from 'chakra-react-select';
import feathers from '../../../../bootstrap/feathers';

import { slugify2 as slugifyUrl } from '../../../../common';

declare const ENV: string;
interface IPageFormProps {
  initialValues: ModelTypes.ContentPage;
  onSubmit: (v: ModelTypes.ContentPage) => void;
  errorMessage: string;
  isSubmitting?: boolean;
  isEdit?: boolean;
}

const schema = Yup.object().shape({
  name: Yup.string().required('First name is required').trim(),
});

const handleProductOptions: any = async (e: string, callback) => {
  try {
    const res = await feathers.service('/products-legacy').find({
      query: {
        $sort: { staticName: 1 },
        $select: ['staticName', 'key'],
        $or: [{ staticName: { $LIKE: e } }, { key: { $LIKE: e } }],
        $np: '1',
      },
    });
    callback(
      res?.map((d: ModelTypes.ProductLegacy) => ({
        label: `${d?.staticName ? `${d.staticName} - ` : ''}${d?.key ?? ''}`,
        value: d?._id,
      }))
    );
  } catch (er) {
    console.error(er);
  }
};

const handleCategoryOptions: any = async (e: string, callback) => {
  try {
    const res = await feathers.service('/products-legacy/categories').find({
      query: {
        $sort: { name: 1 },
        $select: ['name', 'hrCode'],
        $or: [{ name: { $LIKE: e }, hrCode: { $LIKE: e } }],
      },
    });
    callback(
      res?.data?.map((d: ModelTypes.ProductCategoryLegacy) => ({
        label: `${d?.name ? `${d.name} - ` : ''}${d?.hrCode ?? ''}`,
        value: d?._id,
      }))
    );
  } catch (er) {
    console.error(er);
  }
};

export default function PageForm(props: IPageFormProps) {
  const { initialValues, errorMessage, isEdit, isSubmitting, onSubmit } = props;

  const {
    control,
    watch,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IPageFormProps['initialValues']>({
    defaultValues: initialValues,
    resolver: yupResolver(schema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'meta' as any,
  });

  const [templates, setTemplates] = useState<any[]>([]);

  useEffect(() => {
    const loadTemplates = async () => {
      try {
        const res = await feathers.service('/store-front/content/pages/list').find({
          query: { $sort: { name: 1 }, $np: 1 },
        });

        // console.log('res', res);

        setTemplates(res?.map((d: any) => ({ value: d, label: d })));
      } catch (e) {
        console.error(e);
      }
    };

    loadTemplates();
  }, []);

  const handSave: SubmitHandler<ModelTypes.ContentPage> = (data) => {
    const updateData = {
      ...data,
      product: data.type === 'product' ? (data.product as any)?.value : null,
      category:
        data.type === 'category' || data.type === 'product-calculator'
          ? (data.category as any)?.value
          : null,
    };
    onSubmit(updateData);
  };

  const watchType = watch('type');
  const watchProduct = watch('product') as any;
  const watchCategory = watch('category') as any;
  const watchTemplate = watch('template') as any;

  return (
    <Box p={3} mb={3} borderRadius="md" borderWidth="1px">
      <RadioGroup onChange={(e) => setValue('type', e)} value={watchType} mb={3}>
        <Stack direction="row">
          <Radio value="basic">Basic</Radio>
          <Radio value="category">Category</Radio>
          <Radio value="product">Product</Radio>
          <Radio value="product-calculator">Calculator</Radio>
        </Stack>
      </RadioGroup>
      {watchType === 'product' && (
        <Stack spacing={7} mb={3}>
          <FormControl>
            <AsyncSelect
              useBasicStyles
              cacheOptions
              isClearable
              value={
                (watchProduct?._id || watchProduct?.value) && {
                  value: watchProduct?._id || watchProduct?.value,
                  label: watchProduct?.staticName || watchProduct?.label,
                }
              }
              {...register('product')}
              onChange={(e) => setValue('product', e as any)}
              placeholder="Start typing name to search..."
              defaultOptions={!!watchProduct?.length}
              loadOptions={handleProductOptions}
              noOptionsMessage={(v) =>
                !v.inputValue ? 'Start typing product name to search...' : 'No options'
              }
            />{' '}
          </FormControl>
        </Stack>
      )}

      {(watchType === 'category' || watchType === 'product-calculator') && (
        <Stack spacing={7} mb={3}>
          <FormControl>
            <AsyncSelect
              useBasicStyles
              cacheOptions
              isClearable
              value={
                (watchCategory?._id || watchCategory?.value) && {
                  value: watchCategory?._id || watchCategory?.value,
                  label: watchCategory?.name || watchCategory?.label,
                }
              }
              {...register('category')}
              onChange={(e) => setValue('category', e as any)}
              placeholder="Start typing to search..."
              defaultOptions={!!watchCategory?.length}
              loadOptions={handleCategoryOptions}
              noOptionsMessage={(v) => (!v.inputValue ? 'Start typing to search...' : 'No options')}
            />{' '}
          </FormControl>
        </Stack>
      )}

      <Stack spacing={7} mb={3} direction="row">
        <FormControl isInvalid={!!errors?.name}>
          <FormLabel>Name</FormLabel>
          <Input
            {...register('name')}
            onChange={(e) => {
              setValue('name', e.target.value);
              setValue('url', slugifyUrl(e.target.value, true));
            }}
          />
          {errors?.name && <FormErrorMessage>{errors.name.message}</FormErrorMessage>}
        </FormControl>
        <FormControl>
          <FormLabel>Title</FormLabel>
          <Input {...register('title')} />
        </FormControl>
      </Stack>

      <Stack spacing={7} mb={3} direction="row">
        <FormControl>
          <FormLabel>Template</FormLabel>
          {ENV === 'development' || ENV === 'staging' ? (
            <Input {...register('template')} />
          ) : (
            <Select
              useBasicStyles
              value={templates?.find((t) => t.value === watchTemplate)}
              onChange={(e) => setValue('template', e.value)}
              options={templates}
            />
          )}
        </FormControl>
        <FormControl>
          <FormLabel>URL</FormLabel>
          <Input {...register('url')} />
        </FormControl>
      </Stack>

      <Box borderWidth="1px" borderRadius="md" p={3} mb={3}>
        <Heading as="h6" size="xs" mb={3}>
          Meta
        </Heading>
        {fields?.map((field, index) => (
          <Stack key={field.id} direction="row" spacing={7} mb={3}>
            <FormControl>
              <FormLabel>Key</FormLabel>
              <Input {...register(`meta.${index}.key`)} />
            </FormControl>
            <FormControl>
              <FormLabel>Value</FormLabel>
              <Input {...register(`meta.${index}.value`)} />
            </FormControl>

            <FormControl flex={1}>
              <FormLabel>&nbsp;</FormLabel>
              <IconButton
                type="button"
                variant="outline"
                aria-label="remove field"
                onClick={() => remove(index)}
                icon={<FaTimes />}
              />
            </FormControl>
          </Stack>
        ))}
        <Button type="button" variant="outline" onClick={() => append({ key: '', value: '' })}>
          Add new meta
        </Button>
      </Box>

      <Stack spacing={7} mb={3} direction="row">
        <FormControl display="flex" alignItems="center">
          <FormLabel htmlFor="isHidden">Is Hidden</FormLabel>
          <Switch id="isHidden" {...register('isHidden')} />
        </FormControl>
      </Stack>

      <Stack spacing={7} mb={3} direction="row">
        <FormControl display="flex" alignItems="center">
          <FormLabel htmlFor="requireAuth" mb={0}>
            Require Authentication
          </FormLabel>
          <Switch id="requireAuth" {...register('requireAuth')} />
        </FormControl>
      </Stack>

      {errorMessage && (
        <Alert status="error" mb={3}>
          {errorMessage}
        </Alert>
      )}

      <Button
        colorScheme="blue"
        isDisabled={isSubmitting}
        isLoading={isSubmitting}
        onClick={handleSubmit(handSave)}
      >
        Save
      </Button>
    </Box>
  );
}
