import React, { useState, useEffect } from 'react';

import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  InputGroup,
  InputRightElement,
  Box,
  Table,
  Tbody,
  Tr,
  Th,
  Td,
  Spinner,
  Alert,
  Text,
} from '@chakra-ui/react';

import { useDebounce } from '@inkcloud/components';

import type { ModelTypes } from '@inkcloud/icapi-types';

import { Feathers } from '../../../bootstrap/feathers/feathers';

interface IAddModalProps {
  onReload: () => void;
  onClose: () => void;
}

type OrderItemsType = ModelTypes.OrderItems & {
  category?: ModelTypes.ProductCategoryLegacy;
  attributes?: Array<
    NonNullable<ModelTypes.OrderItems['attributes']>[number] & {
      attribute?: {
        name?: string;
      };
      value?: {
        name?: string;
      };
    }
  >;
};

export default function AddModal(props: IAddModalProps) {
  const { onReload, onClose } = props;

  const [humanId, setHumanId] = useState('');
  const [destination, setDestination] = useState('');
  const [url, setUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [orderItem, setOrderItem] = useState<OrderItemsType | null>(null);

  const loadOrderItem = async (id: string) => {
    setIsLoading(true);
    try {
      const res = await Feathers.service('/order-items').find({
        query: {
          humanId: { $LIKE: id },
          $populate: ['attributes.attribute', 'attributes.value', 'category', 'product'],
        },
      });

      setOrderItem(res.data?.[0] ?? null);
      setDestination(res.data?.[0]?.product?.artworkTransporterDestination ?? null);
    } catch (e) {
      console.error(e);
    }

    setIsLoading(false);
  };

  const debounceValue = useDebounce(humanId, 350);

  useEffect(() => {
    if (debounceValue) {
      loadOrderItem(debounceValue);
    }
  }, [debounceValue]);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    setErrorMessage('');
    try {
      const res = await Feathers.service('/artwork-transports').create({
        orderItem: orderItem?._id,
        writeTo: destination,
        url,
      });

      onReload();
      onClose();
    } catch (e) {
      const errMsg = e?.code < 500 ? e?.message : 'Something went wrong. Please try again later.';
      setErrorMessage(errMsg);
    }

    setIsSubmitting(false);
  };

  return (
    <Modal isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Artwork Transport</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box mb={3}>
            <InputGroup>
              <Input onChange={(e) => setHumanId(e.target.value)} placeholder="Order Item ID" />
              {isLoading && <InputRightElement children={<Spinner size="xs" />} />}
            </InputGroup>
          </Box>

          {!orderItem && humanId && !isLoading && (
            <Alert status="warning" mt={3}>
              No order item found with ID {humanId}
            </Alert>
          )}

          {orderItem && (
            <>
              <Box mb={3}>
                <Input
                  defaultValue={destination}
                  onChange={(e) => setDestination(e.target.value)}
                  placeholder="Destination"
                />
              </Box>
              <Box mb={3}>
                <Input onChange={(e) => setUrl(e.target.value)} placeholder="URL" />
              </Box>

              <Table size="sm">
                <Tbody>
                  <Tr>
                    <Th>ID</Th>
                    <Td>{orderItem?.humanId}</Td>
                  </Tr>
                  <Tr>
                    <Th>Category</Th>
                    <Td>{orderItem?.category?.name}</Td>
                  </Tr>
                  <Tr>
                    <Th>Description</Th>
                    <Td>{orderItem?.description}</Td>
                  </Tr>
                  <Tr>
                    <Th>Attributes</Th>
                    <Td>
                      {orderItem?.attributes?.map((attr, idx) => (
                        <Box key={idx}>
                          <Text fontWeight="bold">{(attr?.attribute as any)?.name ?? ''}</Text>{' '}
                          {(attr?.value as any)?.name ?? ''}
                        </Box>
                      ))}
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </>
          )}

          {errorMessage && (
            <Alert status="error" mt={3}>
              {errorMessage}
            </Alert>
          )}
        </ModalBody>

        <ModalFooter>
          <Button mr={3} onClick={onClose}>
            Close
          </Button>
          <Button
            colorScheme="blue"
            isDisabled={isSubmitting || !orderItem}
            isLoading={isSubmitting}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
