import React, { useEffect, useState } from 'react';
import { useForm, useFieldArray, SubmitHandler } from 'react-hook-form';
import type { ModelTypes } from '@inkcloud/icapi-types';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { GenericFilter } from '@inkcloud/components';

import {
  Box,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Stack,
  Button,
  Alert,
  IconButton,
  Text,
  Heading,
} from '@chakra-ui/react';

import { RiCloseFill } from 'react-icons/ri';

import { AsyncSelect } from 'chakra-react-select';
import feathers from '../../../../../bootstrap/feathers';

interface IUserFormProps {
  initialValues: ModelTypes.User;
  onSubmit: (v: ModelTypes.User) => void;
  errorMessage: string;
  isSubmitting?: boolean;
  isEdit?: boolean;
}

const viewTypeOptions = [
  { value: 'list', label: 'List' },
  { value: 'grid', label: 'Grid' },
];

const schema = Yup.object().shape({
  firstName: Yup.string().required('First name is required').trim(),
  lastName: Yup.string().required('Last name is required').trim(),
  email: Yup.string().required('Email is required').trim(),
});

const mapOptionsToValue = (options: any) =>
  options?.map((o) => ({
    label: o?.name,
    value: o?._id,
  }));

const handleProductOptions: any = async (e: string, callback) => {
  try {
    let res;
    if (e) {
      res = await feathers.service('/user-roles').find({
        query: {
          $sort: { name: 1 },
          $or: [{ name: { $LIKE: e } }, { key: { $LIKE: e } }],
        },
      });
      callback(mapOptionsToValue(res?.data));
    }
  } catch (er) {
    console.error(er);
  }
};

export default function UserForm(props: IUserFormProps) {
  const { initialValues, errorMessage, isEdit, isSubmitting, onSubmit } = props;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    control,
    watch,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IUserFormProps['initialValues']>({
    defaultValues: initialValues,
    resolver: yupResolver(schema),
  });

  const handSave: SubmitHandler<ModelTypes.User> = (data) => {
    const updateData = {
      ...data,
      roles2: data?.roles2?.map(({ value }: any) => value) ?? [],
    };
    onSubmit(updateData);
  };

  const watchRoles = watch('roles2');

  return (
    <Box p={3} mb={3} borderRadius="md" borderWidth="1px">
      <Stack spacing={7} mb={3} direction="row">
        <FormControl isInvalid={!!errors?.firstName}>
          <FormLabel>First Name</FormLabel>
          <Input {...register('firstName')} />
          {errors?.firstName && <FormErrorMessage>{errors.firstName.message}</FormErrorMessage>}
        </FormControl>
        <FormControl isInvalid={!!errors?.lastName}>
          <FormLabel>Last Name</FormLabel>
          <Input {...register('lastName')} />
          {errors?.lastName && <FormErrorMessage>{errors.lastName.message}</FormErrorMessage>}
        </FormControl>
      </Stack>

      <Stack spacing={7} mb={3} direction="row">
        <FormControl isInvalid={!!errors?.email}>
          <FormLabel>Email</FormLabel>
          <Input {...register('email')} />
          {errors?.email && <FormErrorMessage>{errors.email.message}</FormErrorMessage>}
        </FormControl>
        {!isEdit && (
          <FormControl isInvalid={!!errors?.password}>
            <FormLabel>Password</FormLabel>
            <Input type="password" {...register('password')} />
            {errors?.password && <FormErrorMessage>{errors.password.message}</FormErrorMessage>}
          </FormControl>
        )}
      </Stack>

      <Stack spacing={7} mb={3} direction="row">
        <FormControl isInvalid={!!errors?.firstName}>
          <FormLabel>Phone</FormLabel>
          <Input {...register('phone')} />
          {errors?.phone && <FormErrorMessage>{errors.phone.message}</FormErrorMessage>}
        </FormControl>
        <FormControl isInvalid={!!errors?.lastName}>
          <FormLabel>Phone Extension</FormLabel>
          <Input {...register('phoneExt')} />
          {errors?.phoneExt && <FormErrorMessage>{errors.phoneExt.message}</FormErrorMessage>}
        </FormControl>
      </Stack>

      <Stack spacing={7} mb={3} direction="row">
        <FormControl isInvalid={!!errors?.firstName}>
          <FormLabel>Roles</FormLabel>

          {/* resultMapper={(r) => ({ key: r._id, value: r._id, text: `${r.name}` })} */}
          <AsyncSelect
            cacheOptions
            isClearable
            isMulti
            value={watchRoles?.map((r: any) => ({
              value: r?._id || r?.value,
              label: r?.name || r?.label,
            }))}
            {...register('roles2')}
            onChange={(e) => setValue('roles2', e as any)}
            placeholder=""
            defaultOptions={!!watchRoles?.length}
            loadOptions={handleProductOptions}
            noOptionsMessage={(v) =>
              !v.inputValue ? 'Start typing role to search...' : 'No options'
            }
          />
          {errors?.roles2 && <FormErrorMessage>{errors.roles2.message}</FormErrorMessage>}
        </FormControl>
      </Stack>

      {errorMessage && (
        <Alert status="error" mb={3}>
          {errorMessage}
        </Alert>
      )}

      <Button
        colorScheme="blue"
        isDisabled={isSubmitting}
        isLoading={isSubmitting}
        onClick={handleSubmit(handSave)}
      >
        Save
      </Button>
    </Box>
  );
}
