import * as React from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { Button, Form, Message, Input, Dropdown } from 'semantic-ui-react';
import { Formik, Form as FormikForm, Field, FieldProps, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { SemanticField } from '../../../../common/Form';

import { SelectedProducts } from '../product/SelectedProducts';
import { SelectedCriterias } from '../product/SelectedCriterias';

import { ProductModal } from '../product/ProductModal';

import { Feathers } from '../../../../bootstrap/feathers/feathers';

interface PromoCodeFormProps {
  initialValues: any;
  onSubmit: Function;
  isSubmitting?: boolean;
  errorMessage: string;
}

const { useState, useEffect } = React;

const initialMapper = (values) => ({
  ...values,
  promoCode: values?.promoCode || '',
  description: values?.description || '',
  value: values?.value || '',
  startDate: values?.startDate || '',
  expirationDate: values?.expirationDate || '',
  maxCartItemsRestricted: values?.maxCartItems && values?.maxCartItems > 0,
});

export const PromoCodeForm: React.FunctionComponent<PromoCodeFormProps> = (props) => {
  const { errorMessage } = props;

  const [isOpenProductModal, setIsOpenProductModal] = useState(false);
  const [isRemovingProduct, setIsRemovingProduct] = useState(false);
  const [campaigns, setCampaigns] = useState([]);

  const PromoCodeSchema = Yup.object().shape({
    promoCode: Yup.string().required('Required'),
    value: Yup.number().typeError('Value must be a number').nullable(),
    valueMaxShipping: Yup.number().typeError('Value must be a number'),
    description: Yup.string().required('Required'),
    startDate: Yup.string().required('Required'),
    expirationDate: Yup.string().required('Required'),
    maxCartItems: Yup.number().typeError('Must be a number').nullable(),
    customerCreateAgeDays: Yup.number()
      .moreThan(0, 'Must be greater than 0')
      .typeError('Must be a number'),
  });

  useEffect(() => {
    const loadCampaigns = async () => {
      try {
        const res: any = await Feathers.service('/promo-codes-campaigns').find({ query: {} });

        setCampaigns(res.data);
      } catch (e) {}
    };

    loadCampaigns();
  }, []);

  return (
    <Formik
      initialValues={initialMapper({ ...props.initialValues, minRequirements: 'none' })}
      enableReinitialize={true}
      validationSchema={PromoCodeSchema}
      onSubmit={(values, { setSubmitting }) => {
        props.onSubmit(values);
      }}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
          setFieldValue,
          setFieldTouched,
        } = props;

        const isPercentage =
          values.type === 'percentage' || values.type === 'percentage-and-free-shipping';

        return (
          <FormikForm
            className={`ui form ${props.isSubmitting && !errorMessage && 'loading'} ${
              errorMessage ? 'error' : ''
            }`}
          >
            {campaigns?.length > 0 && (
              <Form.Group widths="equal">
                <Form.Field>
                  <label>Campaign</label>
                  <Dropdown
                    name="campaign"
                    label="Campaigns"
                    clearable
                    selection
                    value={values?.campaign ?? ''}
                    options={campaigns?.map((c) => ({ text: c.name, value: c._id }))}
                    onChange={(e, data) => setFieldValue(data.name, data.value)}
                  />
                </Form.Field>
              </Form.Group>
            )}

            <Form.Group widths="equal">
              <Form.Field>
                <label>Promo Code</label>
                <Input
                  name="promoCode"
                  // label={campaigns?.find(c => c._id === values.campaign)?.codePrefix}
                  value={values?.promoCode ?? ''}
                  onChange={(e, data) => setFieldValue(data.name, data.value)}
                />
                <ErrorMessage
                  render={(msg) => <Message negative size="tiny" content={msg} />}
                  name="promoCode"
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field>
                <SemanticField name="description" label="Description" component={Form.TextArea} />
                <ErrorMessage
                  render={(msg) => <Message negative size="tiny" content={msg} />}
                  name="description"
                />
              </Form.Field>
            </Form.Group>

            <label
              style={{ display: 'inline-block', marginBottom: '.35em', fontSize: '.92857143em' }}
            >
              <strong>Type</strong>
            </label>
            <Form.Group widths="equal">
              <Form.Field>
                <Form.Radio
                  label="Percentage"
                  name="type"
                  checked={values.type === 'percentage' || values.type === undefined}
                  onBlur={handleBlur}
                  onChange={() => setFieldValue('type', 'percentage')}
                />
              </Form.Field>
            </Form.Group>

            <Form.Group widths="equal">
              <Form.Field>
                <Form.Radio
                  label="Fixed"
                  name="type"
                  checked={values.type === 'fixed'}
                  onBlur={handleBlur}
                  onChange={() => setFieldValue('type', 'fixed')}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field>
                <Form.Radio
                  label="Free Shipping"
                  name="type"
                  checked={values.type === 'free-shipping'}
                  onBlur={handleBlur}
                  onChange={() => {
                    setFieldValue('type', 'free-shipping');
                    setFieldValue('value', null);
                  }}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field>
                <Form.Radio
                  label="Fixed and Free Shipping"
                  name="type"
                  checked={values.type === 'fixed-and-free-shipping'}
                  onBlur={handleBlur}
                  onChange={() => setFieldValue('type', 'fixed-and-free-shipping')}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field>
                <Form.Radio
                  label="Percentage and Free Shipping"
                  name="type"
                  checked={values.type === 'percentage-and-free-shipping'}
                  onBlur={handleBlur}
                  onChange={() => setFieldValue('type', 'percentage-and-free-shipping')}
                />
              </Form.Field>
            </Form.Group>
            {(values.type === 'free-shipping' ||
              values.type === 'fixed-and-free-shipping' ||
              values.type === 'percentage-and-free-shipping') && (
              <Form.Group widths="two">
                <Form.Field>
                  <SemanticField
                    name="valueMaxShipping"
                    label="Maximum Shipping Value"
                    component={Form.Input}
                  />
                </Form.Field>
              </Form.Group>
            )}

            {values.type !== 'free-shipping' && (
              <Form.Group widths="two">
                <Form.Field>
                  {isPercentage ? (
                    <SemanticField
                      key="percent"
                      name="value"
                      label="Discount"
                      icon="percent"
                      component={Form.Input}
                    />
                  ) : (
                    <SemanticField
                      key="fixed"
                      name="value"
                      label="Discount"
                      icon="dollar"
                      iconPosition="left"
                      component={Form.Input}
                    />
                  )}

                  <ErrorMessage
                    render={(msg) => <Message negative size="tiny" content={msg} />}
                    name="value"
                  />
                </Form.Field>
              </Form.Group>
            )}

            <label
              style={{ display: 'inline-block', marginBottom: '.35em', fontSize: '.92857143em' }}
            >
              <strong>Applies To</strong>
            </label>
            <Form.Group widths="equal">
              <Form.Field>
                <Form.Radio
                  label="Entire Order"
                  name="appliesTo"
                  checked={values.appliesTo === 'entire-order'}
                  onBlur={handleBlur}
                  onChange={() => setFieldValue('appliesTo', 'entire-order')}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field>
                <Form.Radio
                  label="Specific Criteria"
                  name="appliesTo"
                  checked={values.appliesTo === 'product-match-conditions'}
                  onBlur={handleBlur}
                  onChange={() => setFieldValue('appliesTo', 'product-match-conditions')}
                />
              </Form.Field>
            </Form.Group>
            {values.appliesTo === 'product-match-conditions' && (
              <Form.Group widths="equal">
                <Form.Field>
                  <Button
                    type="button"
                    size="mini"
                    content={values?.criteria?.length > 0 ? 'Modify' : 'Browse'}
                    onClick={() => {
                      setIsOpenProductModal(true);
                      setIsRemovingProduct(false);
                    }}
                  />
                  <SelectedCriterias criterias={values.criteria} setFieldValue={setFieldValue} />
                </Form.Field>
              </Form.Group>
            )}
            <Form.Group widths="equal">
              <Form.Field>
                <Form.Radio
                  label="Specific Product"
                  name="appliesTo"
                  checked={values.appliesTo === 'specific-products'}
                  onBlur={handleBlur}
                  onChange={() => setFieldValue('appliesTo', 'specific-products')}
                />
              </Form.Field>
            </Form.Group>

            {values.appliesTo === 'specific-products' && (
              <Form.Group widths="equal">
                <Form.Field>
                  <Button
                    type="button"
                    size="mini"
                    content={values?.products?.length > 0 ? 'Modify' : 'Browse'}
                    onClick={() => {
                      setIsOpenProductModal(true);
                      setIsRemovingProduct(false);
                    }}
                  />

                  <SelectedProducts
                    productIDs={values?.products}
                    setFieldValue={setFieldValue}
                    isRemovingProduct={isRemovingProduct}
                    onRemovingProduct={() => setIsRemovingProduct(true)}
                  />
                </Form.Field>
              </Form.Group>
            )}

            <label
              style={{ display: 'inline-block', marginBottom: '.35em', fontSize: '.92857143em' }}
            >
              <strong>Minimum Requirements</strong>
            </label>
            <Form.Group widths="equal">
              <Form.Field>
                <Form.Radio
                  label="None"
                  name="minRequirements"
                  checked={values.minRequirements === 'none'}
                  onBlur={handleBlur}
                  onChange={() => setFieldValue('minRequirements', 'none')}
                />
              </Form.Field>
            </Form.Group>

            <Form.Group widths="equal">
              <Form.Field>
                <label>Start Date</label>
                <Field
                  name="startDate"
                  render={({ field }: FieldProps<any>) => (
                    <>
                      <DatePicker
                        dateFormat="MM/dd/yyyy h:mm a"
                        selectsStart
                        name="startDate"
                        autoComplete="off"
                        showTimeSelect
                        onBlur={handleBlur}
                        timeCaption="time"
                        timeFormat="h:mm a"
                        startDate={(values.startDate && new Date(values.startDate)) || undefined}
                        endDate={
                          (values.expirationDate && new Date(values.expirationDate)) || undefined
                        }
                        popperClassName={'zTop'}
                        selected={
                          typeof field.value === 'string'
                            ? Date.parse(moment(field.value).toISOString())
                            : field.value
                        }
                        onChange={(date) =>
                          field.onChange({ target: { value: date, name: 'startDate' } } as any)
                        }
                      />
                    </>
                  )}
                />
                <ErrorMessage
                  render={(msg) => <Message negative size="tiny" content={msg} />}
                  name="startDate"
                />
              </Form.Field>
              <Form.Field>
                <label>Expire Date</label>
                <Field
                  name="expirationDate"
                  render={({ field }: FieldProps<any>) => (
                    <>
                      <DatePicker
                        dateFormat="MM/dd/yyyy h:mm a"
                        selectsEnd
                        name="expirationDate"
                        autoComplete="off"
                        showTimeSelect
                        onBlur={handleBlur}
                        timeCaption="time"
                        timeFormat="h:mm a"
                        startDate={(values.startDate && new Date(values.startDate)) || undefined}
                        endDate={
                          (values.expirationDate && new Date(values.expirationDate)) || undefined
                        }
                        minDate={(values.startDate && new Date(values.startDate)) || undefined}
                        popperClassName={'zTop'}
                        selected={
                          typeof field.value === 'string'
                            ? Date.parse(moment(field.value).toISOString())
                            : field.value
                        }
                        onChange={(date) =>
                          field.onChange({ target: { value: date, name: 'expirationDate' } } as any)
                        }
                      />
                    </>
                  )}
                />
                <ErrorMessage
                  render={(msg) => <Message negative size="tiny" content={msg} />}
                  name="expirationDate"
                />
              </Form.Field>
            </Form.Group>

            <Form.Group widths="equal">
              <Form.Field>
                <SemanticField
                  name="singleUse"
                  label="Single use only"
                  toggle
                  component={Form.Checkbox}
                />
              </Form.Field>
            </Form.Group>

            <Form.Group widths="equal">
              <Form.Field>
                <SemanticField
                  name="singleUsePerCustomer"
                  label="Single use Per Customer"
                  toggle
                  component={Form.Checkbox}
                />
              </Form.Field>
            </Form.Group>

            <Form.Group widths="equal">
              <Form.Field>
                <SemanticField
                  name="singleUsePerMSCustomer"
                  label="Single use Per MS Customer"
                  toggle
                  component={Form.Checkbox}
                />
              </Form.Field>
            </Form.Group>

            <Form.Group widths="equal">
              <Form.Field>
                <SemanticField
                  name="firstTimeOrdersOnly"
                  label="First time orders only"
                  toggle
                  component={Form.Checkbox}
                />
              </Form.Field>
            </Form.Group>

            <Form.Group widths="equal">
              <Form.Field>
                <SemanticField
                  name="customerCreationAgeRestricted"
                  label="Only customers who signed up in the last X days"
                  toggle
                  component={Form.Checkbox}
                />
              </Form.Field>
            </Form.Group>

            {values.customerCreationAgeRestricted && (
              <Form.Group widths="equal">
                <Form.Field>
                  <SemanticField
                    name="customerCreateAgeDays"
                    label="Number of Days"
                    component={Form.Input}
                  />
                  <ErrorMessage
                    render={(msg) => <Message negative size="tiny" content={msg} />}
                    name="customerCreateAgeDays"
                  />
                </Form.Field>
              </Form.Group>
            )}

            <Form.Group widths="equal">
              <Form.Field>
                <SemanticField
                  name="maxCartItemsRestricted"
                  label="Restrict to a maximum number of cart items"
                  toggle
                  component={Form.Checkbox}
                />
              </Form.Field>
            </Form.Group>

            {values.maxCartItemsRestricted && (
              <Form.Group widths="equal">
                <Form.Field>
                  <SemanticField
                    name="maxCartItems"
                    label="Max Cart Items"
                    component={Form.Input}
                  />
                  <ErrorMessage
                    render={(msg) => <Message negative size="tiny" content={msg} />}
                    name="maxCartItems"
                  />
                </Form.Field>
              </Form.Group>
            )}


            {errorMessage && <Message error content={errorMessage} />}

            {isOpenProductModal && (
              <ProductModal
                isOpen={isOpenProductModal}
                title={
                  values.appliesTo === 'specific-products'
                    ? 'Specific Products'
                    : 'Specific Criteria'
                }
                onClose={() => setIsOpenProductModal(false)}
                products={values?.products ?? []}
                setFieldValue={setFieldValue}
                onAddingProduct={() => setIsRemovingProduct(false)}
                criterias={
                  values.appliesTo === 'product-match-conditions' ? values?.criteria : null
                }
              />
            )}

            <Button primary type="submit">
              Save
            </Button>
          </FormikForm>
        );
      }}
    </Formik>
  );
};
