import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Label, Loader } from 'semantic-ui-react';
import { FormattedDate, FormattedTime } from 'react-intl';
import {
  useList,
  ListPage,
  generateOperatorOptions,
  Operator,
  basicQueryMap,
} from '@inkcloud/components';

import { socket } from '../../../bootstrap/feathers';
import { Feathers } from '../../../bootstrap/feathers/feathers';

interface IListProps {}

const filterQueryMap = (values) => basicQueryMap(values, {});
export async function getFilterDefinition() {
  const definition = [
    {
      label: 'Date:Created',
      value: 'createdAt',
      operators: generateOperatorOptions([Operator.Between]),
      type: 'date',
    },
    {
      label: 'Date:Updated',
      value: 'updatedAt',
      operators: generateOperatorOptions([Operator.Between]),
      type: 'date',
    },
    {
      label: 'Name',
      value: 'reportName',
      operators: generateOperatorOptions([Operator.Like, Operator.Is, Operator.IsNot]),
    },
    {
      label: 'Microsite',
      value: 'microsite',
      operators: generateOperatorOptions([Operator.Is]),
      valueOptions: [],
      asyncValueOptions: async () =>
        Feathers.service('/microsites')
          .find({
            query: {
              $select: ['name'],
              $sort: { name: 1 },
              $np: 1,
            },
          })
          .then((results) => results.map((r) => ({ value: r._id, label: r.name }))),
      type: 'multi-select',
    },
  ];

  return definition.sort((a: any, b: any) => a.label.localeCompare(b.label));
}

export const List: React.FunctionComponent<IListProps & RouteComponentProps> = (props) => {
  const {
    match: { params },
    location,
    history,
  } = props;
  const serviceName = '/reports/v3/results';
  const pathPrefix = `/reports/results`;

  const tableDefintion = [
    {
      key: 'createdAt',
      label: 'Date',
      cell: (v, r) => (
        <>
          <FormattedDate value={r.createdAt} /> <FormattedTime value={r.createdAt} />
        </>
      ),
    },
    { key: 'microsite', label: 'Microsite', cell: (v, r) => <span>{v?.key}</span> },
    { key: 'reportName', label: 'Name' },
    {
      key: 'requestedBy',
      label: 'Requested By',
      cell: (v, r) => (
        <span>
          {v?.firstName} {v?.lastName}
        </span>
      ),
    },
    {
      key: 'status',
      label: 'Status',
      cell: (v, r) => (
        <>
          <Label content={v} />
          {r.status === 'in-progress' && <Loader active inline size="small" />}
        </>
      ),
    },
    {
      key: '',
      label: 'Execution',
      cell: (v, r) => (
        <>
          {r.startedAt && (
            <Label size="tiny">
              Start
              <Label.Detail>
                <FormattedDate value={r.startedAt} /> <FormattedTime value={r.startedAt} />
              </Label.Detail>
            </Label>
          )}
          {r.completedAt && (
            <Label size="tiny">
              Complete
              <Label.Detail>
                <FormattedDate value={r.completedAt} /> <FormattedTime value={r.completedAt} />
              </Label.Detail>
            </Label>
          )}
        </>
      ),
    },
    {
      key: 'actions',
      label: '',
      cell: (v, r) =>
        r.status === 'complete' ? (
          <a target={'_blank'} href={r.resultUrl}>
            <Button size={'mini'} icon={'download'} />
          </a>
        ) : (
          <span></span>
        ),
      textAlign: 'right',
    },
  ];

  const headerActions = <React.Fragment></React.Fragment>;

  const useListValues = useList({
    feathers: ['find', serviceName, [{ query: { $populate: ['microsite', 'requestedBy'] } }]],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  // Real-time: Subscribe to any changes and reload
  React.useEffect(() => {
    const handler = (msg) => useListValues.reload();
    socket.service('reports/v3/results').on('patched', handler);
    socket.service('reports/v3/results').on('created', handler);
    return () => {
      socket.service('reports/v3/results').removeListener('patched', handler);
      socket.service('reports/v3/results').removeListener('created', handler);
    };
  }, []);

  return (
    <ListPage
      header={'Results'}
      subheader="Report"
      useListValues={useListValues}
      history={history}
      pathPrefix={pathPrefix}
      tableDefintion={tableDefintion}
      withCheckboxes={true}
      headerActions={headerActions}
      getFilterDefinition={getFilterDefinition}
    />
  );
};
