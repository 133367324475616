import React, { useEffect, useState } from 'react';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Alert,
  FormControl,
} from '@chakra-ui/react';

import { Select, SingleValue } from 'chakra-react-select';
import type { ModelTypes } from '@inkcloud/icapi-types';
import { Feathers } from '../bootstrap/feathers/feathers';

interface IProductionChannelModalProps {
  onClose: () => void;
  onReload?: () => void;
  item: ModelTypes.OrderItems | null;
}

const serviceName = 'production/channels';

export default function ProductionChannelModal(props: IProductionChannelModalProps) {
  const { item, onReload, onClose } = props;

  const [channels, setChannels] = useState<{ label: string; value: string }[]>([]);

  const [selectedChannel, setSelectedChannel] = useState<{ label: string; value: string }>({
    label: '',
    value: '',
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const loadProductionChannels = async () => {
    try {
      const res = await Feathers.service(serviceName).find({
        query: {
          $np: 1,
          $sort: { name: 1 },
        },
      });

      const options = res?.map((r) => ({ label: r.name, value: r._id }));

      setChannels(options);
      setSelectedChannel(options?.find((o) => o.value === item?.productionChannel));
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    loadProductionChannels();
  }, []);

  const handleSubmit = async () => {
    setErrorMessage('');
    setIsSubmitting(true);

    try {
      const res = await Feathers.service('order-items').patch(item?._id, {
        productionChannel: selectedChannel?.value,
      });

      onReload?.();
    } catch (e) {
      const errMsg =
        e?.code < 500 ? e?.message : 'We are experiencing technical difficulties. Please try again';

      setErrorMessage(errMsg);
    }

    setIsSubmitting(false);
  };

  return (
    <Modal isOpen onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Update Production Channel - {item?.humanId ?? ''}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl mb={3}>
            <Select
              options={channels}
              value={selectedChannel}
              onChange={(e: SingleValue<any>) => setSelectedChannel(e)}
            />
          </FormControl>

          {errorMessage && <Alert status="error">{errorMessage}</Alert>}
        </ModalBody>

        <ModalFooter>
          <Button
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
            colorScheme="blue"
            onClick={handleSubmit}
            mr={3}
          >
            Save
          </Button>

          <Button variant="ghost" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
