import * as React from 'react';
import moment from 'moment';
import { Link, RouteComponentProps } from 'react-router-dom';
import { useList, PageHeaderRow, EmptyWrapper, PaginatorWrapper } from '@inkcloud/components';
import { Segment, Grid, Image, Button, Divider, Label, Popup, Icon } from 'semantic-ui-react';
import { FilterForm } from './FilterForm';
import { ImageUploadModal } from './ImageUploadModal';

export interface ConnectedProps {
  tenant?: any;
}

interface ListProps {}

const filterQueryMap = (values) => {
  const filterMap: any = {
    filename: values.filename ? { $LIKE: values.filename } : undefined,
    mimeType: values.mimeType ? values.mimeType : undefined,
    createdAt: {
      $gte: values.start ? moment(values.start).format('YYYY-MM-DD') : undefined,
      $lte: values.end ? moment(values.end).format('YYYY-MM-DD') : undefined,
    },
  };

  return [].reduce(
    (m, field) => ({ ...m, [field]: values[field] ? values[field] : undefined }),
    filterMap
  );
};

const serviceName = '/content';

const { useState } = React;

export const List: React.FunctionComponent<
  ListProps & RouteComponentProps<{ micrositeId: string; id?: string }>
> = (props) => {
  const {
    match: {
      params: { micrositeId },
    },
    history,
    location,
  } = props;
  const {
    state,
    reload,
    handleDelete,
    selectedItems,
    clearAll,
    setChecked,
    someSelected,
    toggleAll,
    filter,
    setFilter,
    sort,
    handleSort,
  } = useList({
    feathers: ['find', serviceName, [{ query: { microsite: micrositeId } }]],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });
  const pathPrefix = `/settings/microsites/${micrositeId}/content/images`;

  const [isOpenModal, setIsOpenModal] = useState(false);

  const { queryResult, isLoading: isStateLoading } = state as any;

  const handleDownloadImage = (url: string) => {
    window.open(`https://storage.googleapis.com/icstorage/${url}`, '_blank');
  };

  return (
    <>
      <PageHeaderRow header="Content Images" subheader="Microsite">
        {/* <SubMenu match={props.match} history={history} /> */}
        <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
        <Button content="Upload" primary size={'mini'} onClick={() => setIsOpenModal(true)} />
      </PageHeaderRow>
      <Segment>
        <FilterForm value={filter} onChange={(v) => setFilter(v)} onReset={() => setFilter({})} />
      </Segment>
      <EmptyWrapper queryResult={queryResult} isStateLoading={state.isLoading}>
        <Segment loading={state.isLoading}>
          <PaginatorWrapper.Top
            filter={filter}
            reload={reload}
            data={queryResult as any}
            pathPrefix={`${pathPrefix}`}
          />
          <Grid>
            <Grid.Row columns={5}>
              {(queryResult as any)?.data?.map((r: any, index: number) => (
                <Grid.Column key={index}>
                  <Segment style={{ height: '280px' }}>
                    <Popup
                      size="mini"
                      trigger={
                        <div>
                          <Label as="a" color="blue" size="tiny" ribbon>
                            {r.filename}
                          </Label>
                          {r.extension === 'pdf' ? (
                            <div style={{ margin: '.5em 0' }}>
                              <Icon name="file pdf" size="huge" />
                            </div>
                          ) : (
                            <Image
                              src={`https://storage.googleapis.com/icstorage/${r.publicUrl}`}
                              style={{ maxHeight: '180px', margin: '.5em 0' }}
                            />
                          )}

                          <Grid>
                            <Grid.Column width={8} textAlign="left">
                              <input
                                type="checkbox"
                                value={r._id}
                                checked={selectedItems[r._id] || false}
                                onChange={setChecked}
                              />
                            </Grid.Column>
                            <Grid.Column width={8} textAlign="right">
                              {r.publicUrl && (
                                <Button
                                  size="mini"
                                  icon="download"
                                  onClick={() => handleDownloadImage(r.publicUrl)}
                                />
                              )}
                            </Grid.Column>
                          </Grid>
                        </div>
                      }
                    >
                      <Popup.Content>
                        <div>{r.size}kb </div>
                        <div>{r.mimeType}</div>
                      </Popup.Content>
                    </Popup>
                  </Segment>
                  <Divider hidden />
                </Grid.Column>
              ))}
            </Grid.Row>
          </Grid>
          <PaginatorWrapper.Bottom
            reload={reload}
            data={queryResult as any}
            pathPrefix={`${pathPrefix}`}
          >
            {someSelected && (
              <Button primary type="button" content="Delete" onClick={handleDelete} />
            )}
          </PaginatorWrapper.Bottom>
        </Segment>
      </EmptyWrapper>

      {isOpenModal && (
        <ImageUploadModal
          isOpen={isOpenModal}
          onClose={() => setIsOpenModal(false)}
          onReload={reload}
          micrositeId={micrositeId}
        />
      )}
    </>
  );
};
