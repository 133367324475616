import * as React from 'react';
import { VStack, StackDivider, Text, Box, Spinner } from '@chakra-ui/react';
import { Paginated } from '@feathersjs/feathers';
import { ModelTypes } from '@inkcloud/icapi-types';
import MessageItem from './MessageItem';
import { Feathers, icapi } from '../../../bootstrap/feathers/feathers';

interface IMessageItemListProps {
  userId: string;
  messages: ModelTypes.UserMessage[];
  onMarkRead: (id: string) => void;
  loadMessagesFull: () => void;
  isLoading: boolean;
}

const MessageItemList: React.FunctionComponent<IMessageItemListProps> = (props) => {
  const { userId, messages, onMarkRead, loadMessagesFull, isLoading } = props;

  React.useEffect(() => {
    loadMessagesFull();
  }, []);

  return (
    <VStack divider={<StackDivider borderColor="gray.200" />} spacing={4} align="stretch">
      {!isLoading &&
        messages.map((m) => <MessageItem key={m._id} onMarkRead={onMarkRead} value={m} />)}

      {isLoading && <Spinner />}
      {!isLoading && messages.length === 0 && (
        <Box p={4}>
          <Text fontSize={'medium'}>Your inbox is empty</Text>
        </Box>
      )}
    </VStack>
  );
};

export default MessageItemList;
