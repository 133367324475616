import * as React from 'react';
import { Header } from 'semantic-ui-react';

import Feathers from './../../bootstrap/feathers';

export interface HeadingProps {
  id: string;
  title?: string;
  size?: 'small' | 'large' | 'medium' | 'tiny' | 'huge';
}

const { useState, useEffect } = React;

export const Heading: React.FunctionComponent<HeadingProps> = (props) => {
  const { id, size = 'medium' } = props;
  const [title, setTitle] = useState('');

  useEffect(() => {
    const loadMicrosite = async () => {
      try {
        const res: any = await Feathers.service('/microsites').get(id, { query: {} });

        if (res) {
          setTitle(res?.name);
        }
      } catch (e) {}
    };

    if (id && !props.title) {
      loadMicrosite();
    }
  }, [id]);

  return (
    <h2
      style={{
        fontFamily: 'Lato',
        fontWeight: 400,
        fontSize: 22,
        color: 'gray',
        marginTop: '.5em',
      }}
    >
      {title || props.title}
    </h2>
  );
};
