import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  FormControl,
  FormLabel,
  Alert,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { Option } from 'react-select';
import type { ModelTypes } from '@inkcloud/icapi-types';
import { Feathers } from '../bootstrap/feathers/feathers';

interface IUpdateSupplierModalProps {
  item: ModelTypes.OrderItems & {
    vendor?: {
      _id: string;
      companyName: string;
    };
  };
  onReload: () => void;
  onClose: () => void;
}

export default function UpdateSupplierModal(props: IUpdateSupplierModalProps) {
  const { item, onReload, onClose } = props;
  const [supplier, setSupplier] = useState<{ label: string; value: string }>();
  const [suppliers, setSuppliers] = useState<{ label: string; value: string }[]>([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const loadSupplier = async () => {
      setIsLoading(true);
      try {
        const res = await Feathers.service('production/vendors').find({
          query: {
            $select: ['_id', 'companyName'],
            $np: 1,
          },
        });

        const options = res?.map((r) => ({ label: r.companyName, value: r._id }));
        setSuppliers(options);
        setSupplier(options?.find((s) => s.value === item?.vendor?._id));
      } catch (e) {
        console.error(e);
      }
      setIsLoading(false);
    };

    loadSupplier();
  }, []);

  const handleUpdate = async () => {
    setIsSubmitting(true);
    setErrorMessage('');
    try {
      const res = await Feathers.service('order-items/vendor').create({
        orderItemId: item?._id,
        vendor: supplier?.value,
        $unset: supplier?.value ? undefined : { vendor: 1 },
      });

      onReload();
    } catch (e) {
      const errMsg = e?.code < 500 ? e?.message : 'Something went wrong';
      setErrorMessage(errMsg);
    }
    setIsSubmitting(false);
  };

  return (
    <Modal isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {item?.vendor ? 'Current' : 'Assign'} Supplier{' '}
          {item?.vendor && `- ${item?.vendor?.companyName ?? ''}`}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <Select
              useBasicStyles
              isLoading={isLoading}
              isClearable
              placeholder=""
              value={supplier}
              options={suppliers}
              onChange={(e: Option) => setSupplier(e)}
            />
          </FormControl>{' '}
          {errorMessage && (
            <Alert status="error" mt={3}>
              {errorMessage}
            </Alert>
          )}
        </ModalBody>

        <ModalFooter>
          <Button isDisabled={isSubmitting} mr={3} onClick={onClose}>
            Close
          </Button>
          <Button
            isDisabled={isSubmitting}
            isLoading={isSubmitting}
            colorScheme="blue"
            onClick={handleUpdate}
          >
            Update
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
