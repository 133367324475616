import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Form, Checkbox, Message, Segment } from 'semantic-ui-react';
import { PageHeaderRow, PreLoader, useFeathers } from '@inkcloud/components';

import Feathers from '../../../bootstrap/feathers';
import { SalesTaxForm } from './SalesTaxForm';

import { connect } from 'react-redux';

interface DetailProps {}

export interface ConnectedProps {
  user?: any;
}

const { useState, useEffect } = React;

const serviceName = 'tenant-settings/tax';
const commonParams = { query: {} };

export const PreDetail: React.FunctionComponent<
  DetailProps & ConnectedProps & RouteComponentProps<{ id?: string }>
> = (props) => {
  const {
    match: {
      params: { id },
    },
    history,
    user,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [state, reload] = useFeathers('get', serviceName, [user.business, commonParams], true);

  const isEdit = !!id;

  const handleSubmit = async (value: any) => {
    setIsLoading(true);
    try {
      const res: any = await Feathers.service(serviceName).patch(user.business, value, {
        query: {},
      });
      setIsLoading(false);
      history.push('/settings');
    } catch (e) {
      setIsLoading(false);
      e.code && e.code < 500
        ? setErrorMessage(e.message)
        : setErrorMessage('We are experiencing technical difficulties. Please try again');
    }
  };

  if (isEdit && !state.data) {
    return <PreLoader />;
  }

  const initialData = { ...state.data };

  return (
    <>
      <PageHeaderRow header="Sales Tax" subheader="Settings">
        <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
      </PageHeaderRow>
      <Segment>
        {(!isEdit || state.data) && (
          <SalesTaxForm
            initialValues={initialData}
            errorMessage={errorMessage}
            isSubmitting={isLoading}
            onSubmit={handleSubmit}
          />
        )}
      </Segment>
    </>
  );
};

const mapStateToProps = (state): ConnectedProps => ({
  user: state.auth.user || {},
});

export const Detail = connect(mapStateToProps)(PreDetail as any);
