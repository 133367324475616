import * as React from 'react';
import { Style } from '@react-pdf/types';
import { Table, ITableProps } from '../shared/Table';
import { InvoiceContext } from './InvoiceContext';

export interface IItemsProps {
  style?: Style;
  textStyle?: Style;
  hide?: Array<string>;
}

const definition: ITableProps<any>['definition'] = [
  {
    key: 'image',
    type: 'image',
    width: '10%',
    header: 'Image',
  },
  {
    key: 'sku',
    width: '25%',
    header: 'SKU',
  },
  {
    key: 'quantity',
    width: '10%',
    header: 'QTY',
  },
  {
    key: 'name',
    width: '25%',
    header: 'Name',
  },
  {
    // key: 'unitPrice',
    key: 'rate',
    width: '10%',
    header: 'Unit Price inc GST',
    type: 'text-currency',
  },
  {
    key: 'tax',
    width: '10%',
    header: 'Tax',
    type: 'text-currency',
  },
  {
    key: 'subTotal',
    width: '10%',
    header: 'Subtotal inc GST',
    type: 'text-currency',
  },
];

export function Items(props: IItemsProps) {
  const ctx = React.useContext(InvoiceContext);
  const {
    dataCtx: { orderItems, tenantInfo },
  } = ctx;
  const { style, hide = [] } = props;

  let newItems = orderItems.map((oi) => {
    const thumbnailUrl =
      oi.uploadSlots && oi.uploadSlots[0] ? oi.uploadSlots[0].thumbnailUrl : null;

    // ea
    return {
      sku: oi.productKey,
      quantity: oi.quantity,
      image: thumbnailUrl,
      name: oi.product?.staticName,
      rate: oi.unitPrice ?? 0.0,
      tax: 0.0,
      subTotal: oi.revenue ?? 0.0,
    };
  });

  let newDefinition = definition;

  if (hide?.length) {
    newItems = newItems?.filter((item) => {
      const keys = Object.keys(item);
      const found = hide.find((h) => keys.includes(h));

      delete item[found];

      return item;
    });

    newDefinition = definition?.filter((d) => !hide.includes(d.key));
  }

  return (
    <div>
      <Table
        // style={style}
        definition={newDefinition}
        items={newItems}
        locale={tenantInfo.locale}
        currency={tenantInfo.currency}
      />
    </div>
  );
}
