import * as React from 'react';
import { Segment, Table, Form, Button, Modal, Popup, Message } from 'semantic-ui-react';
import { Formik, Form as FormikForm, Field, FieldArray, ErrorMessage, FieldProps } from 'formik';
import * as Yup from 'yup';
import { SemanticField } from '../../../common/Form';

import * as Cleave from 'cleave.js/react';

interface QuantityPriceModalProps {
  value: any;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (v: any) => void;
  errorMessage?: string;
}

const quantityPricesScehma = Yup.object().shape({
  quantityPrices: Yup.array().of(
    Yup.object().shape({
      quantity: Yup.number().typeError('Quantity must be a number'),
      price: Yup.number().typeError('Price must be a number'),
    })
  ),
});

export const QuantityPriceModal: React.FunctionComponent<QuantityPriceModalProps> = (props) => {
  const { value, isOpen, onClose, onSubmit, errorMessage } = props;

  const { quantityPrices, addOnId } = value;

  if (!isOpen) {
    return null;
  }

  return (
    <Formik
      initialValues={{ quantityPrices: quantityPrices }}
      validationSchema={quantityPricesScehma}
      onSubmit={(values) => onSubmit(values)}
    >
      {(props) => {
        const { values, isSubmitting } = props;

        return (
          <FieldArray
            name="quantityPrices"
            render={(arrayHelpers) => (
              <Modal open={isOpen} onClose={onClose} closeIcon>
                <Modal.Header>{addOnId}</Modal.Header>
                <Modal.Content>
                  <FormikForm className={`ui form small ${isSubmitting && 'loading'}`}>
                    <Table size="small" basic="very">
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell />
                          <Table.HeaderCell>Quantity</Table.HeaderCell>
                          <Table.HeaderCell>Price</Table.HeaderCell>
                          <Table.HeaderCell />
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {values && values.quantityPrices && values.quantityPrices.length > 0 ? (
                          values &&
                          values.quantityPrices &&
                          (values as any).quantityPrices.map((qp: any, index: number) => (
                            <Table.Row key={index}>
                              <Table.Cell>
                                <Popup
                                  size="mini"
                                  content="Remove"
                                  trigger={
                                    <Button
                                      type="button"
                                      size="small"
                                      icon="trash alternate outline"
                                      onClick={() => arrayHelpers.remove(index)}
                                    />
                                  }
                                />
                              </Table.Cell>
                              <Table.Cell>
                                <SemanticField
                                  name={`quantityPrices[${index}].quantity`}
                                  component={Form.Input}
                                />
                                <ErrorMessage
                                  component="div"
                                  name={`quantityPrices[${index}].quantity`}
                                />
                              </Table.Cell>
                              <Table.Cell>
                                <SemanticField
                                  name={`quantityPrices[${index}].price`}
                                  component={Form.Input}
                                />
                                <ErrorMessage
                                  component="div"
                                  name={`quantityPrices[${index}].price`}
                                />
                              </Table.Cell>
                              <Table.Cell>
                                <Popup
                                  size="mini"
                                  content="Add Row"
                                  trigger={
                                    <Button
                                      type="button"
                                      size="small"
                                      icon="plus"
                                      onClick={() => arrayHelpers.insert(index, '')}
                                    />
                                  }
                                />
                              </Table.Cell>
                            </Table.Row>
                          ))
                        ) : (
                          <Table.Row key="1">
                            <Table.Cell>
                              <Popup
                                size="mini"
                                content="Add Row"
                                trigger={
                                  <Button
                                    type="button"
                                    icon="plus"
                                    onClick={() => arrayHelpers.push({ quantity: '', price: '' })}
                                  />
                                }
                              />
                            </Table.Cell>
                          </Table.Row>
                        )}
                      </Table.Body>
                    </Table>
                    {errorMessage && <Message error content={errorMessage} />}

                    <div style={{ textAlign: 'right' }}>
                      <Button type="submit" primary content="Submit" />
                    </div>
                  </FormikForm>
                </Modal.Content>
              </Modal>
            )}
          />
        );
      }}
    </Formik>
  );
};
