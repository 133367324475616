import * as React from 'react';
import { Switch, Route } from 'react-router-dom';
// force publish
import { Detail } from './Detail';
import { OpenInvoices } from './OpenInvoices';
import { Invoices } from './Invoices';
import { InvoiceDetail } from './InvoiceDetail';
import { VoidInvoice } from './VoidInvoices';
import { ErrorBoundary } from '../ErrorBoundary';

const routes = (props) => (
  <Switch>
    <Route exact path="/accounting/open-invoices" component={OpenInvoices} />
    <Route exact path="/accounting/open-invoices/:id" component={Detail} />
    <Route exact path="/accounting/invoices" component={Invoices} />
    <Route exact path="/accounting/invoices/:id" component={InvoiceDetail} />
    <Route exact path="/accounting/void-invoices/:id" component={VoidInvoice} />
  </Switch>
);

export default routes;
