import * as React from 'react';
import {
  Button,
  Input,
  Dimmer,
  Divider,
  Form,
  Header,
  Icon,
  Loader,
  Modal,
  ModalProps,
  Segment,
  Table,
} from 'semantic-ui-react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { Feathers } from '../../../../bootstrap/feathers/feathers';
const { useState, useEffect } = React;

interface CreateVersionModalProps {
  handleSubmit: Function;
  handleClose: ModalProps['onClose'];
  open: boolean;
  product: any;
}

type CombinedProps = CreateVersionModalProps & RouteComponentProps<any>;

export const CreateVersionModalPre: React.FunctionComponent<CombinedProps> = (props) => {
  const { product } = props;
  const [variants, setVariants] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const newProduct = (await Feathers.service('/products-legacy/version/new').create({
      product: props.product._id,
    })) as any;

    props.history.push(`/products/stock-products/edit/${newProduct._id}`);
    window.location.reload();
    // setIsSubmitting(false);
    // close this modal and redirect to edit this new version
    // props.handleClose();
  };

  return (
    <Modal size={'small'} open={props.open} onClose={props.handleClose}>
      <Modal.Header>Create Version</Modal.Header>
      <Modal.Content>
        <Segment>
          <Dimmer active={isSubmitting} inverted>
            <Loader inverted>Creating Version</Loader>
          </Dimmer>
          <p>To create a version, click the button below</p>
          <Button type="button" content="Create version" onClick={() => handleSubmit()} />
        </Segment>
      </Modal.Content>
      <Modal.Actions></Modal.Actions>
    </Modal>
  );
};

export const CreateVersionModal = withRouter(CreateVersionModalPre);
