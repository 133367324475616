import * as React from 'react';
import { Link } from 'react-router-dom';

import { Table, Label, Message, Button } from 'semantic-ui-react';

import NumberFormat from 'react-number-format';
import { Box, Icon, Heading, Spinner, Center } from '@chakra-ui/react';
import { FaThList } from 'react-icons/fa';

import type { ModelTypes } from '@inkcloud/icapi-types';
import Feathers from '../../bootstrap/feathers';

import { EmailModal } from './EmailModal';

interface InvoiceItemProps {
  humanId?: string;
  invoiceId: string;
  customer?: any;
  business?: string;
  reload?: boolean;
  isAbleToRefund?: boolean;
}

const { useState, useEffect } = React;

export const InvoiceItem: React.FunctionComponent<InvoiceItemProps> = (props) => {
  const { humanId, invoiceId, customer, business, reload, isAbleToRefund = false } = props;

  const [invoice, setInvoice] = useState<
    NonNullable<ModelTypes.Invoices> & {
      invoiceItems: {
        description: string;
        amount: number;
      }[];
      appliedCoupon: {
        promoCode: string;
      };
      openBalance: number;
      isVoid: boolean;
    }
  >();
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailPayment, setIsEmailPayment] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const loadInvoiceItems = async () => {
      setIsLoading(true);
      try {
        const res = await Feathers.service('invoices').get(invoiceId, {
          query: {
            $populateInvoiceItems: 1,
            $populateOpenBalance: 1,
            $populate: ['appliedCoupon'],
          },
        });

        setInvoice(res);
      } catch (e) {
        const errMsg =
          e?.code < 500
            ? e.message
            : 'We are experiencing technical difficulties. Please try again';
        setErrorMessage(errMsg);
      }
      setIsLoading(false);
    };

    if (invoiceId) {
      loadInvoiceItems();
    }
  }, [reload, invoiceId]);

  let subTotal = 0;

  const getAmount = (a: number) => {
    subTotal += a;
  };

  if (isLoading) {
    return (
      <Center mt={8}>
        <Spinner />
      </Center>
    );
  }
  if (!invoice) {
    return (
      <Box
        p={8}
        background="gray.100"
        borderRadius="md"
        textAlign="center"
        w="100%"
        borderWidth="1px"
      >
        <Icon as={FaThList} size={8} />

        <Heading size="md" mt="0">
          No invoice history found
        </Heading>
      </Box>
    );
  }
  return (
    <>
      {errorMessage && <Message error content={errorMessage} />}
      {invoice?.isClosed ? (
        <Label color="red">Closed</Label>
      ) : (
        isAbleToRefund === true && !invoice?.isVoid && (
          <Message info>
            <Link to={`/invoice/payments/${invoice.customer}`}>
              <Button
                primary
                size="tiny"
                content={invoice.openBalance > 0 ? 'Pay Invoice' : 'Refund Balance'}
              />
            </Link>
            <Button
              size="tiny"
              content="Email Payment Request"
              onClick={() => setIsEmailPayment(true)}
            />
            This invoice has an open balance of{' '}
            <NumberFormat
              value={invoice.openBalance || 0.0}
              decimalScale={2}
              displayType={'text'}
              fixedDecimalScale={true}
              thousandSeparator={true}
              prefix={'$'}
            />
          </Message>
        )
      )}
      <Table size="small" striped compact>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>#</Table.HeaderCell>
            <Table.HeaderCell>Description</Table.HeaderCell>
            <Table.HeaderCell textAlign="right">Amount</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {invoice?.invoiceItems?.map((item: any, index: number) => (
            <Table.Row key={index}>
              <Table.Cell>{index + 1}</Table.Cell>
              <Table.Cell>
                <span style={{ whiteSpace: 'pre-wrap' }}>{item.description}</span>
              </Table.Cell>
              <Table.Cell textAlign="right">
                <>
                  {getAmount(item.amount)}
                  <NumberFormat
                    value={item.amount || 0.0}
                    decimalScale={2}
                    displayType={'text'}
                    fixedDecimalScale={true}
                    thousandSeparator={true}
                    prefix={'$'}
                  />
                </>
              </Table.Cell>
            </Table.Row>
          ))}
          <Table.Row>
            <Table.Cell colSpan={2}>
              <strong>Subtotal</strong>
            </Table.Cell>
            <Table.Cell textAlign="right">
              <NumberFormat
                value={subTotal || 0.0}
                decimalScale={2}
                displayType={'text'}
                fixedDecimalScale={true}
                thousandSeparator={true}
                prefix={'$'}
              />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell colSpan={2}>
              <strong>Discount</strong>
            </Table.Cell>
            <Table.Cell textAlign="right">
              {invoice?.appliedCoupon && (
                <>
                  <strong>Promo Code: </strong>
                  <Label>{invoice.appliedCoupon?.promoCode}</Label> &nbsp;&nbsp;
                </>
              )}
              <NumberFormat
                value={invoice.discount || 0.0}
                decimalScale={2}
                displayType={'text'}
                fixedDecimalScale={true}
                thousandSeparator={true}
                prefix={'$'}
              />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell colSpan={2}>
              <strong>Sales Tax</strong>
            </Table.Cell>
            <Table.Cell textAlign="right">
              <NumberFormat
                value={invoice.salesTax || 0.0}
                decimalScale={2}
                displayType={'text'}
                fixedDecimalScale={true}
                thousandSeparator={true}
                prefix={'$'}
              />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell colSpan={2}>
              <strong>Total</strong>
            </Table.Cell>
            <Table.Cell textAlign="right">
              <strong>
                <NumberFormat
                  value={invoice.total || 0.0}
                  decimalScale={2}
                  displayType={'text'}
                  fixedDecimalScale={true}
                  thousandSeparator={true}
                  prefix={'$'}
                />
              </strong>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

      {isEmailPayment && (
        <EmailModal
          open={isEmailPayment}
          onClose={() => setIsEmailPayment(false)}
          type="payment"
          humanId={humanId as string}
          invoiceId={invoiceId}
          customer={customer}
          business={business as string}
          openBalance={invoice.openBalance}
        />
      )}
    </>
  );
};
