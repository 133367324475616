import * as React from 'react';
import { Form, Button, Checkbox, Dropdown, Message, Segment } from 'semantic-ui-react';
// import { FeathersDropdown } from '@inkcloud/components'
import Feathers from '../../../bootstrap/feathers';

import { groupByKey } from './../../../common';

import Select from 'react-select';

interface ShippingFormProps {
  shippingProps: {
    methods: any[];
    showShippingRates: boolean;
    shipFromControl: false;
    allowedShippingServicesOnCart: string[];
    shipOutAddressInputMethod: string;
    isIntlShippingEnabled: boolean;
    addressVerification: 'verify' | 'verify-strict';
  };
  errorMessage: string;
  onSubmit: (v: any) => void;
  isLoading: boolean;
  user: any;
}

const { useState, useEffect } = React;

const shipOutOptions = [
  { text: 'Open', value: 'open' },
  { text: 'Address Book', value: 'address-book' },
  { text: 'Read Only', value: 'read-only' },
];

const addressVerificationOptions = [
  { text: 'Verify', value: 'verify' },
  { text: 'Verify Strict', value: 'verify-strict' },
];

const selectStyles = {
  menu: (styles) => ({ ...styles, zIndex: 99999 }),
};

export const ShippingForm: React.FunctionComponent<ShippingFormProps> = (props) => {
  const { onSubmit, isLoading, errorMessage } = props;
  const { shippingProps } = props;

  const [shipping, setShipping] = useState({
    showShippingRates: shippingProps.showShippingRates,
    shipFromControl: shippingProps.shipFromControl,
    shipOutAddressInputMethod: shippingProps.shipOutAddressInputMethod,
    allowedShippingServicesOnCart: shippingProps?.allowedShippingServicesOnCart ?? [],
    methods: shippingProps.methods,
    isIntlShippingEnabled: shippingProps?.isIntlShippingEnabled,
    addressVerification: shippingProps?.addressVerification,
  });
  const [shippingOptions, setShippingOptions] = useState([]);

  const [shippingServicesOptions, setShippingServicesOptions] = useState([]);

  useEffect(() => {
    const loadShippingMethods = async () => {
      const result: any = await Feathers.service('tenant-settings/shipping-methods').find({
        query: {
          $np: 1,
          $sort: { name: 1 },
        },
      });
      setShippingOptions(result);
    };
    loadShippingMethods();

    const loadShippingServicesOptions = async () => {
      const result: any = await Feathers.service('tenant-settings/shipping-services-options').find({
        query: {
          $np: 1,
          $sort: { name: 1 },
          all: 1,
        },
      });

      const mappedResult = () =>
        result?.map((r) => ({ ...r, value: r.service, label: r.serviceLabel }));

      setShippingServicesOptions(mappedResult);
      setShipping({
        ...shipping,
        allowedShippingServicesOnCart:
          shipping?.allowedShippingServicesOnCart?.map((s) =>
            mappedResult()?.find((o) => o.service === s)
          ) ?? [],
      });
    };

    loadShippingServicesOptions();
  }, []);

  const handleChange = (data: any) =>
    setShipping({
      ...shipping,
      [data.name]: data.checked !== undefined ? data.checked : data.value,
    });

  const handleShippingChange = (values: any) => {
    setShipping({
      ...shipping,
      allowedShippingServicesOnCart: values,
    });
  };

  return (
    <>
      <Form loading={isLoading} onSubmit={() => onSubmit(shipping)}>
        <Form.Field>
          <label>Ship Out Address</label>
          <Dropdown
            name={'shipOutAddressInputMethod'}
            options={shipOutOptions}
            selection
            value={shipping?.shipOutAddressInputMethod}
            onChange={(e, data) => handleChange(data)}
          />
        </Form.Field>
        <Form.Field>
          <label>Address Verification</label>
          <Dropdown
            name={'addressVerification'}
            options={addressVerificationOptions}
            selection
            value={shipping?.addressVerification ?? addressVerificationOptions[0].value}
            onChange={(e, data) => handleChange(data)}
          />
        </Form.Field>
        <Form.Field>
          <Checkbox
            name={'showShippingRates'}
            label={'Show Shipping Rates'}
            checked={shipping?.showShippingRates}
            onChange={(e, data) => handleChange(data)}
          />
        </Form.Field>
        <Form.Field>
          <Checkbox
            name={'shipFromControl'}
            label={'Ship From Control'}
            checked={shipping?.shipFromControl}
            onChange={(e, data) => handleChange(data)}
          />
        </Form.Field>
        <Form.Field>
          <Checkbox
            name={'isIntlShippingEnabled'}
            label={'International Shipping Enabled'}
            checked={shipping?.isIntlShippingEnabled}
            onChange={(e, data) => handleChange(data)}
          />
        </Form.Field>
        <Form.Field>
          <label>Allow Shipping Services On Cart</label>

          <Select
            isMulti
            name="allowedShippingServicesOnCart"
            placeholder=""
            styles={selectStyles}
            options={groupByKey(shippingServicesOptions, 'carrier', 'carrierLabel')}
            value={shipping.allowedShippingServicesOnCart}
            onChange={handleShippingChange}
          />
          {/* <FeathersDropdown
            search selection clearable multiple loading
            name='allowedShippingServicesOnCart'
            serviceName='/tenant-settings/shipping-services-options'
            query={{}}
            resultMapper={(r) => ({ value: r, text: humanize(r) })}
            onChange={(e, data) => handleChange(data)}
            value={shipping.allowedShippingServicesOnCart}
          /> */}
        </Form.Field>
        <Form.Field>
          <label>Methods</label>
          <Dropdown
            selection
            multiple
            name={'methods'}
            defaultValue={shipping && shipping.methods}
            options={
              shippingOptions && shippingOptions.length > 0
                ? shippingOptions.map((s) => ({ text: s.text, value: s.key }))
                : []
            }
            onChange={(e, data) => handleChange(data)}
          />
        </Form.Field>
        <Button primary content="Save" />
      </Form>
      {errorMessage && <Message error content={errorMessage} />}
    </>
  );
};
