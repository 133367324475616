import * as React from 'react';
import { useDropzone } from 'react-dropzone';
import { connect } from 'react-redux';
import { Segment, Divider, Button } from 'semantic-ui-react';
import axios from '../../../bootstrap/axios';
const { useState, useCallback } = React;

declare var APPENV;

interface ConnectProps {
  tenantId?: string;
}

interface ArtworkVariantProps {
  multiple?: boolean;
  onUpload: (files: any) => void;
}

export const ArtworkVariantPre: React.FunctionComponent<ArtworkVariantProps & ConnectProps> = (
  props
) => {
  const [files, setFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const onDrop = useCallback(async (acceptedFiles) => {
    setFiles(files.concat(acceptedFiles));

    acceptedFiles.map(async (f) => {
      let formData = new FormData();
      formData.append('file', f);
      formData.append('tenantId', props.tenantId);

      const response = await axios.post(
        `${APPENV.api.connection}/products-legacy/artwork-variant-artwork-upload`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: localStorage.getItem('feathers-jwt'),
          },
        }
      );
      props.onUpload && props.onUpload(response.data);
    });
  }, []);

  const handleRemoveClick = (idx: number) => {
    const updatedFiles = [...files];
    updatedFiles.splice(idx, 1);
    setFiles(updatedFiles);
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const rootProps = getRootProps() as any;
  return (
    <div {...rootProps}>
      <input {...getInputProps()} />
      {files.length === 0 && (
        <Button size={'small'} icon={'upload'} content="Upload Artwork" primary type="button" />
      )}
      {files.length > 0 && (
        <div>
          {files.map((f, idx) => (
            <div key={idx}>
              <Button
                type="button"
                icon="close"
                basic
                size="mini"
                onClick={(e) => {
                  handleRemoveClick(idx);
                  e.stopPropagation();
                }}
              />{' '}
              {f.name}{' '}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  tenantId: state.auth.user.business,
});

export const ArtworkVariantUpload = connect(mapStateToProps, {})(ArtworkVariantPre);
