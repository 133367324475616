import * as React from 'react';
import { Switch, Route } from 'react-router-dom';

import Assign from './assign';
import Orders from './orders';
import PMApproval from './pm-approval';
import Estimates from './estimates';

const routes = (props) => (
  <Switch>
    <Route path="/translation/assign" component={Assign} />
    <Route path="/translation/orders" component={Orders} />
    <Route path="/translation/pm-approval" component={PMApproval} />
    <Route path="/translation/estimates" component={Estimates} />
  </Switch>
);

export default routes;
