import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Formik, Form as FormikForm, Field, FieldArray, FieldProps, ErrorMessage } from 'formik';
import { Segment, Form, Message, Button, Menu, Accordion } from 'semantic-ui-react';
import * as Yup from 'yup';
import { SemanticField } from '../../../common/Form';
import { NotificationForm } from './NotificationForm';

interface NotificationRulesFormProps {
  initialValues: any;
  responseMessage: string;
  errorMessage: any;
  isLoading?: boolean;
  templateKeys?: any[];
  micrositeId: string;
  onSubmit: (v: any) => void;
}

const initialMapper = (values) => {
  return {
    ...values,
    defaultEmail: {
      email: values?.defaultEmail?.email || '',
      name: values?.defaultEmail?.name || '',
    },
  };
};

const { useState, useEffect } = React;

export const NotificationRulesForm: React.FunctionComponent<NotificationRulesFormProps> = (
  props
) => {
  const { initialValues, errorMessage, responseMessage, isLoading, onSubmit } = props as any;

  const [validateError, setValidateError] = useState('');
  // const [templateKeyOptions, setTemplateKeyOptions] = useState([])

  const NotificationSchema = Yup.object().shape({
    // templateKey: Yup.string()
    //   .required('Required')
    //   .test('is-not-used', `Key is already in use`, function (value) {
    //     if (props.initialValues && (props.initialValues._id && (props.initialValues.templateKey === value))) {
    //       return Promise.resolve(true);
    //     }

    //     return Feathers.service<any>(`/microsites/notification-settings/${props.micrositeId}`)
    //       .find({ query: { templateKey: value } })
    //       .then((results: any) => results.result === false)
    //       .catch(e => {
    //         if (e.code && e.code < 500) {
    //           setValidateError(e.message)
    //         }
    //         setValidateError('We are experiencing technical difficulties. Please try again.')
    //       })
    //   }),
    replyTo: Yup.string().email('Must be valid email'),
    defaultEmail: Yup.object().shape(
      {
        email: Yup.string()
          .email('Must be valid email')
          .when('name', {
            is: (value) => value && value.length > 0,
            then: Yup.string().required(
              'Default from email required when setting a default from name'
            ),
            otherwise: Yup.string().email('Must be valid email'),
          }),
        name: Yup.string().when('email', {
          is: (value) => value && value.length > 0,
          then: Yup.string().required('Default name required when setting a default email'),
          otherwise: Yup.string(),
        }),
      },
      ['name', 'email']
    ),
    recipients: Yup.array().of(
      Yup.object().shape({
        email: Yup.string().required('Required email'),
        name: Yup.string().required('Required name'),
      })
    ),
    cc: Yup.array().of(
      Yup.object().shape({
        email: Yup.string().required('Required email'),
        name: Yup.string().required('Required name'),
      })
    ),
    bcc: Yup.array().of(
      Yup.object().shape({
        email: Yup.string().required('Required email'),
        name: Yup.string().required('Required name'),
      })
    ),
    enable: Yup.boolean(),
  });

  // useEffect(() => {
  //   const loadKeys = async () => {
  //     try {
  //       const res: any = await Feathers.service('/notification/template-list?keysOnly=1&filter=micrositeUser').create({}, { query: {} })

  //       setTemplateKeyOptions(res && res.length > 0 && res.map(t => ({ key: t, value: t, text: t ? humanize(t) : '' })))
  //     } catch (e) {
  //       // setErrorMessage({ ...errorMessage, keys: e.code && e.code < 500 ? e.message : 'We are experiencing technical difficulties. Please try again' })
  //     }
  //   }

  //   loadKeys()
  // }, [])

  return (
    <Segment>
      {validateError && <Message error content={validateError} />}
      <Formik
        initialValues={initialMapper(initialValues)}
        enableReinitialize={true}
        validationSchema={NotificationSchema}
        onSubmit={(values: any) => {
          props.onSubmit(values);
        }}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            setFieldValue,
            setFieldTouched,
          } = props as any;

          return (
            <FormikForm className={`ui form ${isLoading && 'loading'}`}>
              <NotificationForm
                values={values && values.recipients}
                action="recipients"
                title="Recipients"
                setFieldValue={setFieldValue}
              />
              <NotificationForm
                values={values && values.cc}
                action="cc"
                title="CC"
                setFieldValue={setFieldValue}
              />
              <NotificationForm
                values={values && values.bcc}
                action="bcc"
                title="BCC"
                setFieldValue={setFieldValue}
              />
              <Form.Group>
                <SemanticField
                  label="Enable"
                  toggle
                  name="enable"
                  component={Form.Checkbox}
                  checked={values?.enable ?? true}
                />
                <ErrorMessage
                  render={(msg) => <Message negative size="tiny" content={msg} />}
                  name="enable"
                />
              </Form.Group>

              <Accordion
                as={Form.Field}
                panels={[
                  {
                    key: 'advanced',
                    title: 'Advanced',
                    content: {
                      content: (
                        <>
                          <Form.Group widths="equal">
                            <Form.Field>
                              <SemanticField
                                label="Reply to"
                                placeholder="Enter an email"
                                autoComplete="off-new"
                                name="replyTo"
                                component={Form.Input}
                              />
                              <ErrorMessage
                                render={(msg) => <Message negative size="tiny" content={msg} />}
                                name="replyTo"
                              />
                            </Form.Field>
                          </Form.Group>
                          <Menu attached="top" pointing secondary>
                            <Menu.Item name="Default From Email" active={true} />
                          </Menu>
                          <Segment attached="bottom">
                            <Form.Group widths="equal">
                              <div className="field">
                                <SemanticField
                                  placeholder="Enter an email"
                                  name="defaultEmail.email"
                                  autoComplete="off-new"
                                  component={Form.Input}
                                />
                                <ErrorMessage
                                  render={(msg) => <Message negative size="tiny" content={msg} />}
                                  name="defaultEmail.email"
                                />
                              </div>
                              <div className="field">
                                <SemanticField
                                  placeholder="Enter a name"
                                  name="defaultEmail.name"
                                  autoComplete="off-new"
                                  component={Form.Input}
                                />
                                <ErrorMessage
                                  render={(msg) => <Message negative size="tiny" content={msg} />}
                                  name="defaultEmail.name"
                                />
                              </div>
                            </Form.Group>
                          </Segment>
                        </>
                      ),
                    },
                  },
                ]}
              />
              <Button primary type="submit" content="Submit" />
            </FormikForm>
          );
        }}
      </Formik>
      {(errorMessage.settings || errorMessage.tenant) && (
        <Message error content={errorMessage.settings || errorMessage.tenant} />
      )}

      {responseMessage && <Message success content={responseMessage} />}
    </Segment>
  );
};
