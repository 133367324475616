import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
  Button,
  Segment,
  Divider,
  Grid,
  Header,
  Label,
  Menu,
  Popup,
  Message,
} from 'semantic-ui-react';
import { PageHeaderRow } from '@inkcloud/components';
import feathers from './../../../bootstrap/feathers';
import { FormattedDate, FormattedTime } from 'react-intl';

interface DetailProps {
  products: { value: string; text: string }[];
  categories: { value: string; text: string }[];
}

const serviceName = '/sales-tax/tax-exemption-review';
const service = feathers.service(serviceName);
const commonParams = { query: {} };
const pathPrefix = `/customers/tax-exemption-requests`;

const { useState, useEffect } = React;

const initErrorMessage = { load: '', submit: '', file: '' };

export const Detail: React.FunctionComponent<DetailProps & RouteComponentProps<{ id?: string }>> = (
  props
) => {
  const {
    match: {
      params: { id },
    },
    history,
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [customer, setCustomer] = useState(null) as any;
  const [request, setRequest] = useState(null) as any;
  const [errorMessage, setErrorMessage] = useState(initErrorMessage);
  const [responseMessage, setResponseMessage] = useState('');

  useEffect(() => {
    const loadTaxReview = async () => {
      try {
        setIsLoading(true);
        setErrorMessage({ ...errorMessage, load: '' });
        const res: any = await service.get(id, {
          query: {
            $populate: ['customer', 'resellerPermitUpload', 'signedAgreementUpload'],
          },
        });

        setRequest({
          createdAt: res.createdAt,
          status: res.status,
          resellerPermitNumber: res.resellerPermitNumber,
          resellerPermitUpload: res.resellerPermitUpload,
          signedAgreementUpload: res.signedAgreementUpload,
          stateProvince: res.stateProvince,
        });
        setCustomer(res.customer);
        setIsLoading(false);
      } catch (error) {
        const message =
          error.code < 500
            ? "Customer's tax exemption request not found."
            : 'We are experiencing technical difficulties. Please try again';
        setErrorMessage({ ...errorMessage, load: message });
        setIsLoading(false);
      }
    };

    loadTaxReview();
  }, [id, isSubmitting]);

  const handleRequestSubmit = async (value: string) => {
    try {
      setResponseMessage('');
      setIsSubmitting(true);
      setErrorMessage(initErrorMessage);
      const res: any = await service.patch(
        id,
        {
          status: value,
        },
        commonParams
      );
      setIsSubmitting(false);
      setResponseMessage(value && value.charAt(0).toUpperCase() + value.slice(1));
    } catch (error) {
      setIsSubmitting(false);
      const message =
        error.code < 500
          ? error.message
          : 'We are experiencing technical difficulties. Please try again';
      setErrorMessage({ ...errorMessage, submit: message });
    }
  };

  const handlePreview = async (id: string) => {
    try {
      setErrorMessage(initErrorMessage);
      const res: any = await feathers.service('/file-uploads-signed').get(id, { query: {} });
      window.open(res);
    } catch (error) {
      const message =
        error.code < 500
          ? error.message
          : 'We are experiencing technical difficulties. Please try again';
      setErrorMessage({ ...errorMessage, file: message });
    }
  };

  return (
    <div>
      <PageHeaderRow header="Tax Exemption Request" subheader="Customer" subheaderId={''}>
        <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
      </PageHeaderRow>
      <Divider hidden />
      {errorMessage.load && <Message error content={errorMessage.load} />}
      <Menu attached="top" pointing secondary>
        <Menu.Item name="Contact Information" active={true} />
      </Menu>
      <Segment loading={isLoading} style={{ minHeight: '50px' }} attached="bottom">
        {customer && (
          <>
            <Grid columns={2}>
              <Grid.Column>
                <Grid>
                  {customer.organizationName
                    ? customer.organizationName && (
                        <Grid.Row>
                          <Grid.Column width={3}>Contact:</Grid.Column>
                          <Grid.Column width={13}>{customer.organizationName}</Grid.Column>
                        </Grid.Row>
                      )
                    : customer.primaryContact && (
                        <Grid.Row>
                          <Grid.Column width={3}>Contact:</Grid.Column>
                          <Grid.Column width={13}>
                            {customer.primaryContact.firstName} {customer.primaryContact.lastName}
                          </Grid.Column>
                        </Grid.Row>
                      )}

                  {customer.email && (
                    <Grid.Row>
                      <Grid.Column width={3}>Email:</Grid.Column>
                      <Grid.Column width={13}>
                        <a href={`mailto:${customer.email}`}>{customer.email}</a>
                      </Grid.Column>
                    </Grid.Row>
                  )}

                  {customer.phone && (
                    <Grid.Row>
                      <Grid.Column width={3}>Phone:</Grid.Column>
                      <Grid.Column width={13}>{customer.phone}</Grid.Column>
                    </Grid.Row>
                  )}
                </Grid>
              </Grid.Column>

              <Grid.Column>
                <Grid>
                  {customer.address1 && (
                    <Grid.Row>
                      <Grid.Column width={3}>Address:</Grid.Column>
                      <Grid.Column width={13}>
                        <div>{customer.address1}</div>
                        <div>{customer.address2}</div>
                        <div>
                          {customer.city}{' '}
                          {customer.stateProvince ? `${customer.stateProvince}, ` : ''}{' '}
                          {customer.postalCode}
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                  )}
                </Grid>
              </Grid.Column>
            </Grid>
          </>
        )}
      </Segment>
      <Menu attached="top" pointing secondary>
        <Menu.Item name="Submitted Request" active={true} />
      </Menu>
      <Segment loading={isLoading} style={{ minHeight: '50px' }} attached="bottom">
        {request && (
          <>
            <Label
              ribbon
              color={
                request.status !== 'pending'
                  ? request.status === 'rejected'
                    ? 'red'
                    : 'green'
                  : 'orange'
              }
              content={
                (request.status &&
                  request.status.charAt(0).toUpperCase() + request.status.slice(1)) ||
                'Pending'
              }
            />
            <Divider hidden />
            <Grid celled>
              {request.createdAt && (
                <Grid.Row>
                  <Grid.Column width={3}>Date:</Grid.Column>
                  <Grid.Column width={13}>
                    <Popup
                      content={<FormattedTime value={request.createdAt} />}
                      trigger={<FormattedDate value={request.createdAt} />}
                    />
                  </Grid.Column>
                </Grid.Row>
              )}

              {request.stateProvince && (
                <Grid.Row>
                  <Grid.Column width={3}>State:</Grid.Column>
                  <Grid.Column width={13}>{request.stateProvince}</Grid.Column>
                </Grid.Row>
              )}

              {request.resellerPermitNumber && (
                <Grid.Row>
                  <Grid.Column width={3}>ID:</Grid.Column>
                  <Grid.Column width={13}>{request.resellerPermitNumber}</Grid.Column>
                </Grid.Row>
              )}
            </Grid>
            <Divider hidden />
            {errorMessage.file && <Message error content={errorMessage.file} />}
            <>
              {request.resellerPermitUpload && (
                <Button
                  basic
                  icon="file alternate"
                  content="Certificate"
                  onClick={() => handlePreview(request.resellerPermitUpload._id)}
                />
              )}
              {request.signedAgreementUpload && (
                <Button
                  basic
                  icon="file alternate"
                  content="Signed Agreement"
                  onClick={() => handlePreview(request.signedAgreementUpload._id)}
                />
              )}
            </>
          </>
        )}
        {errorMessage.submit && <Message error content={errorMessage.submit} />}
        {responseMessage && <Message success content={responseMessage} />}
        <Divider hidden />
        <div>
          <Button disabled={!!errorMessage.load} onClick={() => handleRequestSubmit('rejected')}>
            Reject
          </Button>
          <Button
            disabled={!!errorMessage.load}
            color="blue"
            onClick={() => handleRequestSubmit('approved')}
          >
            Approve
          </Button>
        </div>
      </Segment>

      {/* <Segment>
        {(!isEdit || state.data) && <BrandForm initialValues={initialData} onSubmit={handleSubmit} /> }
      </Segment> */}
    </div>
  );
};
