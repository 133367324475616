import * as React from 'react';
import {
  Button,
  Form,
  Input,
  Message,
  Dropdown,
  Label,
  Segment,
  Icon,
  Header,
} from 'semantic-ui-react';
import { Formik, Form as FormikForm, Field, FieldArray, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { SemanticField } from '../../../common/Form';
import { feathers } from '../../../bootstrap';
import { slugify2 as slugifyKey } from './../../../common';

const { useEffect, useState } = React;

interface DashboardUserFormProps {
  initialValues: any;
  onSubmit: Function;
  isSubmitting?: boolean;
  errorMessage?: string;
}
const DashboardUserSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
});

function getActions(permissions, permission) {
  if (!permissions) {
    return [];
  }
  const found = permissions.find((p) => p.name === permission);

  return found ? found.availableActions : [];
}

export const UserRoleForm: React.FC<DashboardUserFormProps> = (props) => {
  const { errorMessage } = props;
  const [permissions, setPermissions] = useState(null);
  const [selectedPermission, setSelectedPermission] = useState(null);

  useEffect(() => {
    const loadPermissions = async () => {
      const perms = await feathers.service('user-permissions').find({});
      setPermissions(perms);
    };

    loadPermissions();
  }, []);

  const initialValues = props.initialValues;
  if (!initialValues.permissions) {
    initialValues.permissions = [];
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={DashboardUserSchema}
      onSubmit={(values, { setSubmitting }) => {
        props.onSubmit(values);
      }}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
          setFieldValue,
          setFieldTouched,
        } = props;

        const handleSlugify = (v) => setFieldValue('key', slugifyKey(v));

        return (
          <FormikForm
            className={`ui form small ${isSubmitting && !errorMessage && 'loading'} ${
              errorMessage ? 'error' : ''
            }`}
          >
            <Form.Group widths="equal">
              <Form.Field>
                <SemanticField
                  name={'name'}
                  label="Name"
                  component={Form.Input}
                  onChange={(v) => {
                    if (!values?._id) {
                      setFieldValue('key', slugifyKey(v));
                    }
                  }}
                />
                <ErrorMessage component="div" name="name" />
              </Form.Field>
              <Form.Field>
                <SemanticField
                  name={'key'}
                  label="Key"
                  component={Form.Input}
                  onChange={handleSlugify}
                />
                <ErrorMessage component="div" name="key" />
              </Form.Field>
              <Form.Field>
                <SemanticField name={'description'} label="Description" component={Form.Input} />
                <ErrorMessage component="div" name="description" />
              </Form.Field>
            </Form.Group>

            <Header content="Permissions" />
            <Segment>
              <FieldArray
                name="permissions"
                render={(arrayHelpers) => (
                  <div>
                    {permissions && permissions.length !== 0 && (
                      <React.Fragment>
                        <Form.Group inline>
                          <Dropdown
                            size="mini"
                            clearable
                            selection
                            search
                            options={permissions.map((p) => ({
                              key: p.name,
                              value: p.name,
                              text: p.name,
                            }))}
                            placeholder={'Select a permission to add'}
                            value={selectedPermission}
                            onChange={(e, data) => setSelectedPermission(data.value)}
                          />
                          <Button
                            content={'Add'}
                            size={'mini'}
                            type="button"
                            onClick={() => {
                              arrayHelpers.push({ actions: [], resource: selectedPermission });
                              setSelectedPermission(null);
                            }}
                          />
                        </Form.Group>
                      </React.Fragment>
                    )}

                    {values.permissions && values.permissions.length > 0 ? (
                      values.permissions.map((permission, index) => (
                        <div key={index}>
                          <Form.Group widths="equal">
                            <Form.Field>
                              <Dropdown
                                size={'mini'}
                                selection
                                multiple
                                value={permission.action}
                                placeholder="Select actions..."
                                options={getActions(permissions, permission.resource).map((a) => ({
                                  value: a,
                                  text: a,
                                }))}
                                onChange={(e, data) => {
                                  arrayHelpers.replace(index, {
                                    ...permission,
                                    action: data.value,
                                  });
                                }}
                              />
                            </Form.Field>
                            <Form.Field>
                              <Label size="small" content={permission.resource} />
                              <Button
                                basic
                                size="mini"
                                icon="remove"
                                onClick={() => arrayHelpers.remove(index)}
                              />
                            </Form.Field>
                          </Form.Group>
                        </div>
                      ))
                    ) : (
                      <div>No permissions added</div>
                    )}
                  </div>
                )}
              />

              {errorMessage && <Message error content={errorMessage} />}
            </Segment>
            <Button primary type="submit" disabled={isSubmitting && !errorMessage}>
              Save
            </Button>
          </FormikForm>
        );
      }}
    </Formik>
  );
};
