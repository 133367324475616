import * as React from 'react';
import { Modal, Button, Message } from 'semantic-ui-react';
import { FeathersDropdown } from '@inkcloud/components';

interface AddUserModalProps {
  isOpen: boolean;
  isSubmitting: boolean;
  errorMessage: string;
  onClose: () => void;
  onSubmit: (v: string) => void;
}

const { useState } = React;

export const AddUserModal: React.FunctionComponent<AddUserModalProps> = (props) => {
  const { isOpen, isSubmitting, errorMessage, onClose, onSubmit } = props;

  const [userId, setUserId] = useState('');

  return (
    <Modal size="tiny" open={isOpen} onClose={onClose} closeIcon>
      <Modal.Header>Add Recepient</Modal.Header>
      <Modal.Content>
        <FeathersDropdown
          search
          selection
          fluid
          placeholder="User..."
          serviceName="/users"
          query={{
            $sort: { firstName: 1 },
            $np: 1,
          }}
          resultMapper={(r) => ({ key: r._id, value: r._id, text: `${r.firstName} ${r.lastName}` })}
          onChange={(e, data: any) => setUserId(data.value)}
        />
        {errorMessage && <Message error content={errorMessage} />}
      </Modal.Content>
      <Modal.Actions>
        <Button
          primary
          content="Submit"
          loading={isSubmitting}
          disabled={isSubmitting && !errorMessage}
          onClick={() => onSubmit(userId)}
        />
      </Modal.Actions>
    </Modal>
  );
};
