import * as React from 'react';
import Select from 'react-select';

export interface QuantitySelectProps {
  options: any[];
  selected: any;
  onChange: Function;
}

const style = { marginTop: 12 };
export interface QuantitySelectState {}

export class QuantitySelect extends React.Component<QuantitySelectProps, QuantitySelectState> {
  constructor(props: QuantitySelectProps) {
    super(props);

    this.state = {};
  }

  handleChange = (value) => {
    this.props.onChange(value);
  };

  public render() {
    if (this.props.options.length) {
      return (
        <div style={style}>
          <label>Quantity</label>
          <Select
            options={this.props.options}
            defaultValue={this.props.options.find((o) => o.value === this.props.selected)}
            value={this.props.selected}
            onChange={this.handleChange}
          />
        </div>
      );
    }

    return (
      <div style={style}>
        <label>Quantity</label>
        <div className="ui input fluid ">
          <input
            type="text"
            value={this.props.selected}
            // defaultValue={this.props.options.find(o => o.value === this.props.selected)}
            // value={this.props.selected}
            onChange={(e) => this.handleChange(e.target.value)}
          />
        </div>
      </div>
    );
  }
}
