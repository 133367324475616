import * as React from 'react';
import { Form, Checkbox, Segment, Header } from 'semantic-ui-react';

interface ShippingRateProps {
  rates: any[];
  value: string;
  setFieldValue: (name: string, value: any) => void;
}

export const ShippingRates: React.FunctionComponent<ShippingRateProps> = (props) => {
  const { rates, value, setFieldValue } = props;

  return (
    <>
      {rates &&
        rates.length > 0 &&
        rates.map((s: any, index: number) => (
          <Form.Field key={index}>
            <Checkbox
              radio
              label={`${s.description} - $${s.carrierRates}`}
              name="serviceKey"
              value={s.serviceKey}
              checked={value ? value === s.serviceKey : index === 0}
              onChange={(e, data) => setFieldValue('serviceKey', data.value)}
            />
          </Form.Field>
        ))}
    </>
  );
};
