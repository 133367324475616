import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Segment, Menu } from 'semantic-ui-react';
import { PageHeaderRow, PreLoader, useFeathers } from '@inkcloud/components';
import feathers from '../../bootstrap/feathers';

import { ContactForm } from './ContactForm';

import { LocalDeliveryList } from './local-delivery/LocalDeliverList';

import { CreditCard } from './stored-cards/CreditCard';
import { SettingForm } from './SettingForm';

import { UpdatePasswordModal } from './Detail/UpdatePassword';
import { ResetPasswordModal } from './Detail/ResetPasswordModal';

interface DetailFormProps {
  products: { value: string; text: string }[];
  categories: { value: string; text: string }[];
}

const serviceName = 'customers';
const service = feathers.service(serviceName);
const commonParams = { query: {} };

const { useState, useEffect } = React;

let initialData;

export const DetailForm: React.FunctionComponent<
  DetailFormProps & RouteComponentProps<{ id?: string }>
> = (props) => {
  const {
    match: {
      params: { id },
    },
    history,
  } = props;

  const [domainName, setDomainName] = useState('');
  const [isSubmitting, setIsSubmitting] = useState({
    updatePass: false,
    reset: false,
    form: false,
  });
  const [isCreditCardModalOpen, setIsCreditCardModalOpen] = useState(false);
  const [isLocalDeliveryModalOpen, setIsLocalDeliveryModalOpen] = useState(false);
  const [isResetPasswordModalOpen, setIsResetPasswordModalOpen] = useState(false);
  const [isUpdatePasswordModalOpen, setIsUpdatePasswordModalOpen] = useState(false);

  const [errorMessage, setErrorMessage] = useState({
    form: '',
    updatePass: '',
    reset: '',
  });

  useEffect(() => {
    const loadTenant = async () => {
      try {
        const res = await feathers
          .service('tenants')
          .get(initialData?.business, { query: { $select: ['domainName'] } });
        setDomainName(res?.domainName);
      } catch (error) {
        console.error(error);
      }
    };

    if (initialData) {
      loadTenant();
    }
  }, [initialData]);

  const pathPrefix = `/customers`;

  const isEdit = !!id;
  const [state, reload] = useFeathers('get', serviceName, [id, commonParams], true);
  const handleSubmit = async (values) => {
    setIsSubmitting({
      ...isSubmitting,
      form: true,
    });

    const payload = {
      ...values,
      tags: values?.tags?.map((t) => t?.value),
    };

    try {
      if (isEdit) {
        await service.patch(id, payload, commonParams);
      } else {
        await service.create(payload, commonParams);
      }

      history.push(pathPrefix);
    } catch (e) {
      setErrorMessage({
        ...errorMessage,
        form:
          e.code && e.code < 500
            ? e.message
            : 'We are experiencing technical difficulties. Please try again',
      });
    }

    setIsSubmitting({
      ...isSubmitting,
      form: false,
    });
  };

  const pageTitle = isEdit ? 'Edit' : 'New';

  if (isEdit && !state.data) {
    return <PreLoader />;
  }

  initialData = { ...state.data };

  let customerName = '';
  if (initialData?.organizationName) {
    customerName = initialData?.organizationName;
  } else if (initialData?.primaryContact) {
    const { primaryContact } = initialData;
    customerName = `${primaryContact?.firstName} ${primaryContact?.lastName}`;
  }

  const handleResetPassword = async () => {
    try {
      setIsSubmitting({
        ...isSubmitting,
        reset: true,
      });
      const res = await feathers.service('/customers/reset-password').create({
        tenant: initialData?.business,
        email: initialData?.email,
        url: `https://${domainName}/reset-password-update`,
      });

      if (!res.result) {
        setErrorMessage({
          ...errorMessage,
          reset: res.message,
        });
        setIsSubmitting({
          ...isSubmitting,
          reset: false,
        });
      } else {
        setIsResetPasswordModalOpen(false);
      }
    } catch (e) {
      setErrorMessage({
        ...errorMessage,
        reset:
          e.code && e.code < 500
            ? e.message
            : 'We are experiencing technical difficulties. Please try again',
      });

      setIsSubmitting({
        ...isSubmitting,
        reset: false,
      });
    }
  };

  const handleUpdatePassword = async ({ password, confirmPassword }) => {
    try {
      setIsSubmitting({
        ...isSubmitting,
        updatePass: true,
      });
      const res = await feathers.service('/customers').patch(id, {
        password,
        confirmPassword,
      });

      setIsUpdatePasswordModalOpen(false);
      setIsSubmitting({
        ...isSubmitting,
        updatePass: false,
      });
    } catch (e) {
      setErrorMessage({
        ...errorMessage,
        updatePass:
          e.code && e.code < 500
            ? e.message
            : 'We are experiencing technical difficulties. Please try again',
      });

      setIsSubmitting({
        ...isSubmitting,
        updatePass: false,
      });
    }
  };

  return (
    <div>
      <PageHeaderRow
        header={customerName || 'Customer'}
        subheader={`${pageTitle} `}
        subheaderId={initialData?.humanId}
      >
        <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
        {isEdit && (
          <>
            <Button
              content="Reset Password"
              size={'mini'}
              onClick={() => setIsResetPasswordModalOpen(true)}
            />
            <Button
              content="Update Password"
              size={'mini'}
              onClick={() => setIsUpdatePasswordModalOpen(true)}
            />
          </>
        )}
      </PageHeaderRow>

      <Segment>
        {(!isEdit || state.data) && (
          <ContactForm
            initialValues={initialData}
            isSubmitting={isSubmitting.form}
            onSubmit={handleSubmit}
            errorMessage={errorMessage.form}
          />
        )}
      </Segment>

      {isEdit && (
        <>
          <Menu attached="top" pointing secondary>
            <Menu.Item name="Stored Credit Cards" active={true} />
            <Menu.Menu position="right">
              <div
                style={{ paddingRight: '1.1rem', paddingTop: '0.3rem', paddingBottom: '0.3rem' }}
              >
                <Button
                  content="Add Credit Card"
                  size={'tiny'}
                  onClick={() => setIsCreditCardModalOpen(true)}
                />
              </div>
            </Menu.Menu>
          </Menu>

          <CreditCard
            customer={initialData}
            open={isCreditCardModalOpen}
            onClose={() => setIsCreditCardModalOpen(false)}
          />

          <Segment>
            <SettingForm history={history} initialValues={initialData} />
          </Segment>

          <Menu attached="top" pointing secondary>
            <Menu.Item name="Local Delivery Addresses" active={true} />
            <Menu.Menu position="right">
              <div
                style={{ paddingRight: '1.1rem', paddingTop: '0.3rem', paddingBottom: '0.3rem' }}
              >
                <Button
                  content="New Delivery Address"
                  size={'tiny'}
                  onClick={() => setIsLocalDeliveryModalOpen(true)}
                />
              </div>
            </Menu.Menu>
          </Menu>

          <LocalDeliveryList
            id={id}
            isOpen={isLocalDeliveryModalOpen}
            setIsOpen={() => setIsLocalDeliveryModalOpen(true)}
            onClose={() => setIsLocalDeliveryModalOpen(false)}
          />

          {isResetPasswordModalOpen && (
            <ResetPasswordModal
              isOpen={isResetPasswordModalOpen}
              onClose={() => setIsResetPasswordModalOpen(false)}
              onSubmit={handleResetPassword}
              isSubmitting={isSubmitting.reset}
              errorMessage={errorMessage.reset}
            />
          )}

          {isUpdatePasswordModalOpen && (
            <UpdatePasswordModal
              open={isUpdatePasswordModalOpen}
              onClose={() => setIsUpdatePasswordModalOpen(false)}
              onSubmit={handleUpdatePassword}
              isSubmitting={isSubmitting.updatePass}
              errorMessage={errorMessage.updatePass}
            />
          )}
        </>
      )}
    </div>
  );
};
