/* eslint-disable no-undef */
import React, { useState, useRef } from 'react';
import {
  Box,
  ButtonGroup,
  Button,
  Stack,
  Table,
  Alert,
  Tr,
  Th,
  Td,
  Thead,
  Tbody,
  FormControl,
  FormErrorMessage,
  Text,
  Input,
  Textarea,
  Grid,
  GridItem,
  RadioGroup,
  Radio,
} from '@chakra-ui/react';

import { Select } from 'chakra-react-select';
import type { ModelTypes } from '@inkcloud/icapi-types';

import type { AwardJobType, PONumbersType } from '../../Detail';

import { SegmentCard } from '../../../../common';
import { Feathers } from '../../../../bootstrap/feathers/feathers';

export type SelectedQuantitiesState = { [key: string]: string };

export type ResponseType = NonNullable<ModelTypes.Rfq['estimate']>['response'];
interface IAwardJobProps {
  estimate: ModelTypes.Rfq['estimate'];
  // estimateItems: NonNullable<ModelTypes.Rfq['estimate']>['items']
  selectedQuantities: SelectedQuantitiesState;
  onChange: (selectedQuantities: SelectedQuantitiesState) => void;
  suppliers: { _id: string; name: string }[];
  id: string;
  onChangeSupplier: (value: AwardJobType) => void;
  title: string;
  subTitle: string;
  onConvertToPOClick: () => void;

  onReload: () => void;
}

function supplierFinder(selectedQuantities, estimateItems, suppliers) {
  return Object.keys(selectedQuantities)?.reduce((acc: IAwardJobProps['suppliers'], cur) => {
    const found = estimateItems
      ?.find((item) => item.item === cur)
      ?.quantities?.find((q) => q.quantity === Number(selectedQuantities[cur]));

    if (found) {
      const normalizeSuppliers = found?.selectedVendors?.map((s) => ({
        _id: s,
        name: suppliers?.find((sup) => sup._id === s)?.name as string,
      }));

      const t = normalizeSuppliers?.filter((n) => !acc.find((a) => a?._id === n._id));

      acc.push(...(t || []));
    }

    return acc;
  }, []);
}

export default function AwardJob(props: IAwardJobProps) {
  const {
    estimate,
    // estimateItems,
    selectedQuantities,
    onChange,
    onChangeSupplier,
    title,
    subTitle,
    suppliers,
    onConvertToPOClick,
    onReload,
  } = props;

  const estimateItems = estimate?.items;

  // console.log('selectd quantities first', selectedQuantities)
  // console.log('estimateItems', estimateItems)
  // console.log('suppliers', suppliers)

  const [changeMessage, setChangeMessage] = useState('');
  const [declineReason, setDeclineReason] = useState('');

  const [response, setResponse] = useState<ResponseType>('accepted');

  const [poNumbers, setPONumbers] = useState<PONumbersType[]>([]);
  const [foreignItemNumbers, setForeignItemNumbers] = useState<
    { item: string; foreignItemNumber: string }[]
  >([]);

  const costCenterRef = useRef<HTMLInputElement>(null);

  const externalJobNumberRef = useRef<HTMLInputElement>(null);

  const clientPoRef = useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    setForeignItemNumbers(
      estimateItems?.map((item) => ({ item: item.item as any, foreignItemNumber: '' })) ?? []
    );
  }, [estimateItems]);

  const hasSelectedQuantities = Object.keys(selectedQuantities).length > 0;

  const updatedSuppliers = supplierFinder(selectedQuantities, estimateItems, suppliers);

  const handleChangePO = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;

    const newPONumbers = updatedSuppliers.map((s) => ({
      vendor: s?._id,
      purchaseOrderForeignId:
        s?._id === id
          ? Number(value)
          : (poNumbers?.find((p) => p?.vendor === s?._id)?.purchaseOrderForeignId as string),
    }));

    setPONumbers(newPONumbers);
  };

  const customerSelectedQuantities = Object.keys(selectedQuantities).map((key) => ({
    item: key,
    quantity: Number(selectedQuantities[key]),
  }));

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const items =
    (selectedQuantities &&
      Object.keys(selectedQuantities).map((key) => ({
        _id: key,
        quantity: selectedQuantities[key],
      }))) ||
    [];

  const isDeclined = response === 'declined';

  const handleSubmit = async () => {
    setErrorMessage('');
    setIsSubmitting(true);
    try {
      await Feathers.service('rfqs').patch(props.id, {
        estimate: {
          ...estimate,
          customerSelectedQuantities,
          response,
          changeMessage: response === 'accepted-with-changes' ? changeMessage : undefined,
          declineReason: response === 'declined' ? declineReason : undefined,
        },
        status: isDeclined ? 'estimate-declined' : undefined,
      });

      if (!isDeclined) {
        await Feathers.service('rfqs/conversions').create({
          rfq: props.id,
          items,
          vendorPurchaseOrderNumbers: poNumbers,
          costCenter: costCenterRef.current?.value,
          externalJobNumber: externalJobNumberRef.current?.value,
          clientPo: clientPoRef.current?.value,
        });
      }

      onReload();
    } catch (e) {
      const errMsg = e?.code < 500 ? e?.message : 'An error occurred. Please try again later.';

      setErrorMessage(errMsg);
    }
    setIsSubmitting(false);
  };

  const areAllFilledUp = poNumbers.length > 0 && poNumbers?.every((p) => p?.purchaseOrderForeignId);

  const isSubmitDisabed =
    ((response === 'accepted' || response === 'accepted-with-changes') &&
      (!hasSelectedQuantities || !areAllFilledUp)) ||
    isSubmitting;

  return (
    <SegmentCard title={title} subtitle={subTitle}>
      <Stack direction={['column', 'column', 'column', 'column', 'row']} spacing={8}>
        <Box>
          <RadioGroup onChange={(e) => setResponse(e as ResponseType)} value={response} mb={3}>
            <Stack direction="row">
              <Radio value="accepted">Accepted </Radio>
              <Radio value="accepted-with-changes">Accepted with changes</Radio>
              <Radio value="declined">Declined</Radio>
            </Stack>
          </RadioGroup>
          {(response === 'accepted-with-changes' || isDeclined) && (
            <Textarea
              key={response}
              defaultValue={isDeclined ? declineReason : changeMessage}
              onChange={(e) =>
                isDeclined ? setDeclineReason(e.target.value) : setChangeMessage(e.target.value)
              }
              placeholder={isDeclined ? 'Reason for decline...' : 'Describe changes...'}
            />
          )}
          {!isDeclined && (
            <Table size="sm" mt={2}>
              <Thead>
                <Tr>
                  <Th>Item</Th>
                  <Th>Quantity</Th>
                </Tr>
              </Thead>
              <Tbody>
                {estimateItems?.map((item, index) => {
                  const options = (item?.quantities?.map((q) => ({
                    value: q.quantity,
                    label: q.quantity,
                  })) ?? []) as any;

                  return (
                    <Tr key={index}>
                      <Td>Item {index + 1}</Td>
                      <Td>
                        <Select
                          size="sm"
                          useBasicStyles
                          options={[{ value: 'Skip item', label: 'Skip item' }, ...options]}
                          defaultValue={{ value: 'Skip item', label: 'Skip item' }}
                          value={
                            selectedQuantities[item.item as string]
                              ? {
                                  value: selectedQuantities[item.item as string],
                                  label: selectedQuantities[item.item as string],
                                }
                              : undefined
                          }
                          onChange={(e) => {
                            if (e?.value === 'Skip item') {
                              const newSelectedQuantities = { ...selectedQuantities };
                              delete newSelectedQuantities[item.item as string];

                              onChange(newSelectedQuantities);
                              return;
                            }

                            onChange({
                              ...selectedQuantities,
                              [item.item as string]: `${e?.value}`,
                            });
                          }}
                        />
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          )}
        </Box>
        <Box>
          {!isDeclined && hasSelectedQuantities && (
            <>
              <Text textAlign="center" mt={2}>
                The following purchase orders will be created
              </Text>
              <Table>
                <Thead>
                  <Tr>
                    <Th>Supplier</Th>
                    <Th>PO Number</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {updatedSuppliers?.map((supplier) => {
                    const found = poNumbers?.find((p) => p.vendor === supplier._id);

                    const isError =
                      found === undefined ||
                      found?.purchaseOrderForeignId === undefined ||
                      isNaN(Number(found?.purchaseOrderForeignId));

                    const errNumberMsg =
                      found !== undefined && isNaN(Number(found?.purchaseOrderForeignId));
                    return (
                      <Tr key={supplier._id}>
                        <Td>{supplier.name} PO#</Td>
                        <Td pr={0}>
                          <FormControl isInvalid={isError}>
                            <Input
                              type="text"
                              id={supplier._id}
                              placeholder={'Foreign PO #...'}
                              onChange={handleChangePO}
                            />
                            {isError && (
                              <FormErrorMessage>
                                {errNumberMsg ? 'Must be a number' : 'PO Number is required'}
                              </FormErrorMessage>
                            )}
                          </FormControl>
                        </Td>
                      </Tr>
                    );
                  })}
                  <Tr>
                    <Td>Cost Center</Td>
                    <Td pr={0}>
                      <Input ref={costCenterRef} />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Job Number</Td>
                    <Td pr={0}>
                      <Input ref={externalJobNumberRef} />
                    </Td>
                  </Tr>
                  {foreignItemNumbers?.map((item, idx) => {
                    const label = `Item ${idx + 1} #/SKU`;

                    return (
                      <Tr>
                        <Td>{label}</Td>
                        <Td pr={0}>
                          <Input
                            value={item.foreignItemNumber}
                            onChange={(e) =>
                              setForeignItemNumbers(
                                foreignItemNumbers.map((fi) =>
                                  fi.item === item.item
                                    ? { ...fi, foreignItemNumber: e.target.value }
                                    : fi
                                )
                              )
                            }
                          />
                        </Td>
                      </Tr>
                    );
                  })}

                  <Tr>
                    <Td>Client PO</Td>
                    <Td pr={0}>
                      <Input ref={clientPoRef} />
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </>
          )}
        </Box>
      </Stack>

      {errorMessage && (
        <Alert status="error" mt={3}>
          {errorMessage}
        </Alert>
      )}

      <ButtonGroup display="flex" justifyContent="end" mt={3}>
        <Button
          isDisabled={isSubmitDisabed}
          isLoading={isSubmitting}
          colorScheme="blue"
          onClick={() => {
            handleSubmit();
            // onConvertToPOClick()
            // onChangeSupplier({
            //   poNumbers,
            //   costCenter: costCenterRef.current?.value,
            //   externalJobNumber: externalJobNumberRef.current?.value,
            //   clientPo: clientPoRef.current?.value,
            // })
          }}
        >
          {isDeclined ? 'Submit' : 'Award Job'}
        </Button>
      </ButtonGroup>
    </SegmentCard>
  );
}
