import axios from 'axios';

// declare const window: any;
declare global {
  interface Window{
  }
}

axios.defaults.withCredentials = true;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.withCredentials = true;

export default axios;
