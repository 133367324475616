import * as React from 'react';
import { Switch, Route } from 'react-router-dom';

import { List } from './List';
import { DesignJob } from './Detail';

const route = (props) => (
  <Switch>
    <Route exact path="/design-jobs" component={List} />
    <Route exact path="/design-jobs/:id" component={DesignJob} />
  </Switch>
);

export default route;
