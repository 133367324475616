import * as React from 'react';
import {
  Badge,
  Box,
  Button,
  Flex,
  Heading,
  Text,
  Spacer,
  IconButton,
  Menu,
  MenuList,
  MenuButton,
  MenuItem,
  MenuDivider,
  Table as ChakraTable,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  TableContainer,
  ButtonGroup,
  Tooltip,
  Icon,
  Tag,
} from '@chakra-ui/react';

// force deploy
import Costing from '../../prototypes/costing/Costing';

interface IDetailProps {}

const Detail: React.FunctionComponent<IDetailProps> = (props) => {
  const a = '';

  return (
    <Box>
      <Flex mb={16} alignItems={'center'}>
        <Box>
          <Heading fontWeight={'normal'} size="xl">
            Costing Estimate
          </Heading>
        </Box>
        {/* <Badge
          ml={4}
          colorScheme={initialData?.status !== 'open' ? 'orange' : 'green'}
          fontSize={'large'}
        >
          {initialData?.humanId ? initialData?.status ?? 'Draft' : ''}
        </Badge> */}
        <Spacer />
      </Flex>

      <Box p={8} mb={16} bgColor={'white'}>
        <Costing />
        <div style={{ width: 700 }}></div>
      </Box>
    </Box>
  );
};

export default Detail;
