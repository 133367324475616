import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Segment, Table, Message } from 'semantic-ui-react';
import { PageHeaderRow, PreLoader, useFeathers } from '@inkcloud/components';
import feathers from './../../bootstrap/feathers';
import { MicrositeFormComponent } from './MicrositeFormComponent';

import { slugify } from 'underscore.string';

import { CustomThemeForm } from './custom/CustomThemeForm';

import { ImageUploadModal } from './../settings/content-management/banners/ImageUploadModal';

interface DetailProps {
  products: { value: string; text: string }[];
  categories: { value: string; text: string }[];
}

const serviceName = '/microsites';
const service = feathers.service(serviceName);
const commonParams = {
  query: {
    $populate: ['settings.logo'],
  },
};

const pathPrefix = `/settings/microsites`;

const initialValues = {
  logo: '',
  imgUrl: '',
};

const { useState, useEffect } = React;

export const Detail: React.FunctionComponent<DetailProps & RouteComponentProps<{ id?: string }>> = (
  props
) => {
  const {
    match: {
      params: { id },
    },
    history,
  } = props;

  const isEdit = !!id;
  const [state, reload] = useFeathers('get', serviceName, [id, commonParams], true);

  const [values, setValues] = useState({
    logo: '',
    imgUrl: '',
  });

  const [responseMessage, setResponseMessage] = useState({
    logo: '',
    favicon: '',
    custom: '',
  });
  const [isUploadLoading, setIsUploadLoading] = useState({
    logo: false,
    favicon: false,
  });
  const [isOpenModalTheme, setIsOpenModalTheme] = useState(false);
  const [isOpenModalLogo, setIsOpenModalLogo] = useState(false);
  const [fieldName, setFieldName] = useState(null);
  const [errorMessage, setErrorMessage] = useState({
    whole: '',
    logo: '',
    favicon: '',
    custom: '',
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (values: any) => {
    const isBuiltInDomain = values?.domainType === 'builtInDomain';

    const payload = {
      ...values,
      key: values?.key?.replace(/-+$/, ''),
      domain: isBuiltInDomain ? `${values.key}.inkcloud9.site` : values?.domain?.replace(/\s/g, ''),
      settings: {
        ...values.settings,
        shippingMethods:
          values?.settings?.shippingMethods?.length < 1
            ? ['SHIPPING_SHIP_OUT']
            : values?.settings?.shippingMethods,
        paymentMethods: values?.settings?.payment?.methods,
        compositeTopLevelLimit: Number(!!values?.settings?.compositeTopLevelLimit),
        allowedShippingServicesOnCart: values?.settings?.allowedShippingServicesOnCart?.map(
          (s) => s?.value
        ),
      },
    };

    try {
      setErrorMessage({ ...errorMessage, whole: '' });
      isEdit
        ? await service.patch(id, payload, commonParams)
        : await service.create(payload, commonParams);
      history.push(pathPrefix);
    } catch (e) {
      const errMsg =
        e?.code < 500 ? e?.message : 'We are experiencing technical difficulties. Please try again';
      setErrorMessage({ ...errorMessage, whole: errMsg });
    }
  };

  const pageTitle = isEdit ? 'Edit' : 'New';

  if (isEdit && !state.data) {
    return <PreLoader />;
  }

  const handleSubmitLogoFavicon = async (value: { logo?: string; favicon?: string }) => {
    const { logo, favicon } = value;
    try {
      setIsUploadLoading({
        logo: true,
        favicon: true,
      });
      setResponseMessage({
        ...responseMessage,
        logo: '',
        favicon: '',
      });
      setErrorMessage({
        ...errorMessage,
        logo: '',
        favicon: '',
      });
      const res = await feathers.service('/microsites/settings').create(
        {
          micrositeId: id,
          logo,
          favicon,
        },
        { query: {} }
      );
      reload();
      setValues(initialValues);

      setResponseMessage({
        ...responseMessage,
        logo: logo ? 'Successfully added a logo' : '',
        favicon: favicon ? 'Successfully added a favicon' : '',
      });
    } catch (e) {
      e.code && e.code < 500
        ? setErrorMessage({
            ...errorMessage,
            logo: logo ? e.message : '',
            favicon: favicon ? e.message : '',
          })
        : setErrorMessage({
            ...errorMessage,
            logo: 'We are experiencing technical difficulties. Please try again',
          });
    }
    setIsUploadLoading({
      logo: false,
      favicon: false,
    });
  };

  const handleSubmitCustom = async (values: { css: string; js: string; html: string }) => {
    setIsSubmitting(true);
    setResponseMessage({
      ...responseMessage,
      custom: '',
    });
    setErrorMessage({
      ...errorMessage,
      custom: '',
    });
    try {
      const res = await feathers.service('/microsites/custom-scripts').create(
        {
          micrositeId: id,
          ...values,
        },
        { query: {} }
      );
      setIsSubmitting(false);
      reload();

      setResponseMessage({
        ...responseMessage,
        custom: 'Successfully Saved!',
      });
    } catch (e) {
      setIsSubmitting(false);
      const errMsg =
        e?.code < 500 ? e?.message : 'We are experiencing technical difficulties. Please try again';
      setErrorMessage({ ...errorMessage, custom: errMsg });
    }
  };

  const handleLogoChange = (fieldName, value) => {
    setValues((values) => ({ ...values, [fieldName]: value }));

    if (fieldName === 'logo' && value) {
      handleSubmitLogoFavicon({ logo: value });
    }
  };

  const initialData = {
    settings: {
      shippingMethods: ['SHIPPING_SHIP_OUT'],
      signUpRequiredCaptcha: true,
      private: true,
      allowSavedItems: true,
      showShippingRates: true,
      consolidateCartItems: true,
    },
    ...state.data,
  };

  const initialDataTheme = initialData?.settings;

  return (
    <div>
      <PageHeaderRow header={`${pageTitle} `} subheader="Microsite">
        <Button
          primary
          loading={isUploadLoading.logo}
          content="Upload Logo"
          size="mini"
          onClick={() => {
            setFieldName('logo');
            setIsOpenModalLogo(true);
          }}
        />
        <Button
          primary
          loading={isUploadLoading.favicon}
          content="Upload Favicon"
          size="mini"
          onClick={() => {
            setFieldName('favicon');
            setIsOpenModalLogo(true);
          }}
        />
        <Button
          content={'Edit Theme'}
          size="mini"
          onClick={() => {
            setIsOpenModalTheme(true);
            setResponseMessage({
              ...responseMessage,
              custom: '',
            });
          }}
        />
        <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
      </PageHeaderRow>
      {(responseMessage.logo || responseMessage.favicon) && (
        <Message success size="tiny" content={responseMessage.logo || responseMessage.favicon} />
      )}
      {(errorMessage.logo || errorMessage.logo) && (
        <Message error size="tiny" content={errorMessage.logo || errorMessage.logo} />
      )}

      {(!isEdit || state.data) && (
        <MicrositeFormComponent
          initialValues={initialData}
          onSubmit={handleSubmit}
          errorMessage={errorMessage.whole}
        />
      )}

      {isOpenModalLogo && (
        <ImageUploadModal
          isOpen={isOpenModalLogo}
          onClose={() => {
            setFieldName(null);
            setIsOpenModalLogo(false);
          }}
          setFieldValue={handleLogoChange}
          fieldName={fieldName}
          micrositeId={id}
        />
      )}

      {isOpenModalTheme && (
        <CustomThemeForm
          isOpen={isOpenModalTheme}
          initialValue={initialDataTheme}
          onSubmit={handleSubmitCustom}
          isSubmitting={isSubmitting}
          onClose={() => setIsOpenModalTheme(false)}
          responseMessage={responseMessage.custom}
          errorMessage={errorMessage.custom}
        />
      )}
    </div>
  );
};
