// import * as React from 'react';
// import { connect } from 'react-redux';
// import { Button, Form } from 'semantic-ui-react';
// import { InjectedFormProps, Field, reduxForm, change, formValueSelector, FormProps } from 'redux-form';
// import { Link } from 'react-router-dom';
// import { slugify } from 'underscore.string';
// import {
//   semanticFormField, semanticFormToggleField, semanticFormRadioField, semanticFormDropdownField,
//   validateRequired, normalizeBoolean, formatBoolean
// } from './../../../common';
// import Feathers from './../../../bootstrap/feathers';

// type ResourceFormProps = {
//   isEdit: boolean;
// } & InjectedFormProps

// const validate = validateRequired(['name', 'type']);

// const typeOptions = [
//   { text: 'Press', value: 'press' },
//   { text: 'Coater', value: 'coater' },
//   { text: 'Cutter', value: 'cutter' },
// ]

// export function ResourceForm(props: ResourceFormProps) {
//   const { handleSubmit, pristine, reset, submitting } = props;
//   return (
//     <Form onSubmit={handleSubmit}>
//       <Form.Group widths={'equal'}>
//         <Field
//           name='name'
//           label='Name'
//           component={semanticFormField}
//           as={Form.Input}
//         />
//         <Field
//           name='type'
//           selection fluid
//           component={semanticFormField}
//           as={Form.Dropdown}
//           options={typeOptions}
//           label='Type'
//         />
//       </Form.Group>

//       <Button primary disabled={pristine || submitting}>Submit</Button>
//       <Link to={'/production/resources'}><Button type="button">Back</Button></Link>
//     </Form>
//   );
// }

// const formName = 'productionResourceForm'
// export default reduxForm({
//   form: formName,
//   enableReinitialize: true,
//   validate,
// })(ResourceForm);

import * as React from 'react';
import { Formik, Form as FormikForm, Field, FieldArray, ErrorMessage } from 'formik';
import { Button, Form, Message, Popup } from 'semantic-ui-react';
import * as Yup from 'yup';
import { SemanticField } from '../../../../common/Form';

interface ResourceFormProps {
  initialValues: any;
  errorMessage: string;
  isLoading: boolean;
  vendorOptions: Array<{ text: string; value: string }>;

  onSubmit: (v: any) => void;
}

const typeOptions = [
  { text: 'Bindery', value: 'bindery' },
  { text: 'Coater', value: 'coater' },
  { text: 'Coiling', value: 'coiling' },
  { text: 'Collating', value: 'collating' },

  { text: 'Cutter', value: 'cutter' },
  { text: 'Die Cutter', value: 'die-cutter' },
  { text: 'Digital Press', value: 'digital-press' },
  { text: 'Folder', value: 'folder' },
  { text: 'Gluing', value: 'gluing' },

  { text: 'Laminator', value: 'laminator' },
  { text: 'Mounting', value: 'mounting' },
  { text: 'Offset Press', value: 'offset-press' },
  { text: 'Other', value: 'other' },
  { text: 'Press', value: 'press' },
  { text: 'Router', value: 'router' },
  { text: 'Stitcher', value: 'stitcher' },

  { text: 'Wide Format Cutting', value: 'wide-format-cutting' },
  { text: 'Wide Format Laminating', value: 'wide-format-laminating' },
  { text: 'Wide Format Plotter', value: 'wide-format-plotter' },
  { text: 'Wide Format Press', value: 'wide-format-press' },


];

const resourceSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  type: Yup.string().required('Required'),
});

export const ResourceForm: React.FunctionComponent<ResourceFormProps> = (props) => {
  const { initialValues, errorMessage, vendorOptions, isLoading, onSubmit } = props;

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={resourceSchema}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values);
        }}
      >
        {(innerProps) => {
          const {
            values,
            touched,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            setFieldValue,
            setFieldTouched,
          } = innerProps;

          return (
            <FormikForm
              className={`ui form ${isLoading && !errorMessage && 'loading'} ${
                errorMessage ? 'error' : ''
              }`}
              style={{ height: '100%' }}
            >
              <Form.Group widths="equal">
                <Form.Field>
                  <SemanticField fluid name="name" component={Form.Input} label="Name" />
                  <ErrorMessage
                    render={(msg) => <Message negative size="tiny" content={msg} />}
                    name="name"
                  />
                </Form.Field>
                <Form.Field>
                  <SemanticField
                    fluid
                    selection
                    name="type"
                    component={Form.Dropdown}
                    options={typeOptions}
                    label="Type"
                  />
                  <ErrorMessage
                    render={(msg) => <Message negative size="tiny" content={msg} />}
                    name="type"
                  />
                </Form.Field>
              </Form.Group>

              <Form.Field>
                <SemanticField
                  fluid
                  selection
                  name="vendor"
                  label="Vendor"
                  clearable
                  component={Form.Dropdown}
                  options={vendorOptions}
                />
              </Form.Field>

              {errorMessage && <Message error content={errorMessage} />}
              <Form.Field>
                <Button primary type="submit" disabled={isLoading && !errorMessage}>
                  Save
                </Button>
              </Form.Field>
            </FormikForm>
          );
        }}
      </Formik>
    </>
  );
};
