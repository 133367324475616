import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Message, Dropdown } from 'semantic-ui-react';
import { PageHeaderRow, PreLoader, useFeathers } from '@inkcloud/components';
import { connect } from 'react-redux';
import feathers from '../../bootstrap/feathers';
import { SalesOrder } from './SalesOrder/SalesOrder';
import { PrintTypes } from '../../common/PrintModal';

import EmailModal from './EmailModal';

import DuplicateModal from './DuplicateModal';

import { IndexWrapper, IndexWrappedProps } from '../../bootstrap/IndexWrapper';

import {
  OrderItemComponent2,
  connectOrderItemModals,
  OrderItemModalsProps,
} from '../../common/OrderItemCard2';

export interface ConnectedProps {
  user?: any;
}

interface IDetailProps {}

const serviceName = `/sales-orders`;
const pathPrefix = `/sales-orders`;
const service = feathers.service(serviceName);
const commonParams = {
  query: {
    $populate: ['customer', 'micrositeUser', 'items.product'],
  },
};

const { useState } = React;

const PreDetail: React.FunctionComponent<
  IDetailProps & ConnectedProps & RouteComponentProps<{ id?: string }>
> = (props) => {
  const {
    match: {
      params: { id },
    },
    history,
    user,
  } = props;
  const isEdit = !!id;

  const [state, reload] = useFeathers('get', serviceName, [id, commonParams], true);
  const [errorMessage, setErrorMessage] = useState('');

  const [isModalOpen, setIsModalOpen] = useState({
    send: false,
    duplicate: false,
  });

  const printHandleOpenOrderLevel = (e, data) => {
    (props as any).printHandleOpen(data.modaltype, data.payload);
  };

  const handleSubmit = async (values) => {
    setErrorMessage('');
    try {
      if (isEdit) {
        await service.patch(id, values, commonParams);
      } else {
        await service.create(values, commonParams);
      }
      history.push(pathPrefix);
    } catch (e) {
      const errMsg =
        e?.code < 500 ? e.message : 'We are experiencing technical difficulties. Please try again';
      setErrorMessage(errMsg);
    }
  };

  const pageTitle = isEdit ? 'Edit' : 'New';

  if (isEdit && !state.data) {
    return <PreLoader />;
  }

  const initialData = { ...state.data };
  return (
    <div>
      <PageHeaderRow
        header={`${pageTitle} Sales Orders`}
        subheader="Order"
        subheaderId={state.data?.humanId ?? ''}
      >
        <Dropdown
          className="icon primary mini"
          scrolling
          position="left"
          direction="left"
          button
          icon="ellipsis horizontal"
        >
          <Dropdown.Menu className="ui mini">
            {id && (
              <Dropdown.Item
                icon="mail"
                content={'Send'}
                onClick={() =>
                  setIsModalOpen({
                    ...isModalOpen,
                    send: true,
                  })
                }
              />
            )}
            {id && (
              <Dropdown.Item
                icon="copy"
                content={'Duplicate'}
                onClick={() =>
                  setIsModalOpen({
                    ...isModalOpen,
                    duplicate: true,
                  })
                }
              />
            )}
            <Dropdown.Item
              icon="print"
              content={'Print Sales Order'}
              payload={{ _id: state?.data?._id }}
              modaltype={PrintTypes.SalesOrder}
              onClick={printHandleOpenOrderLevel}
            />
          </Dropdown.Menu>
        </Dropdown>

        <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
      </PageHeaderRow>
      <SalesOrder
        user={user}
        initialValues={initialData}
        errorMessage={errorMessage}
        onSubmit={handleSubmit}
      />

      {id && isModalOpen.send && (
        <EmailModal
          humanId={initialData?.humanId}
          contact={{
            name: `${initialData?.customer?.primaryContact?.firstName ?? ''} ${
              initialData?.customer?.primaryContact?.lastName ?? ''
            }`,
            email: initialData?.customer?.email,
          }}
          salesOrderId={id}
          onClose={() => {
            setIsModalOpen({ ...isModalOpen, send: false });
          }}
        />
      )}
      {id && isModalOpen.duplicate && (
        <DuplicateModal
          data={state.data}
          onClose={() => {
            setIsModalOpen({ ...isModalOpen, duplicate: false });
          }}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state): ConnectedProps => ({
  user: state.auth.user || {},
});

export const Detail = connect(mapStateToProps)(
  connectOrderItemModals<IndexWrappedProps>(PreDetail)
);
