import React, { useState } from 'react';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  IconButton,
  Heading,
  Alert,
  Text,
  Input,
  Grid,
  GridItem,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';

import { Select, OptionsOrGroups } from 'chakra-react-select';

import Feathers from '../../../bootstrap/feathers';

interface IStagingLocationModalProps {
  stagingLocation: {
    _id: string;
    containId: string;
    name: string;
    qty: number;
  };
  id: string;
  locationOptions: { label: string; value: string }[];
  onClose: () => void;
  onReload?: () => void;
}

const serviceName = 'fulfillment-staging-locations';

export default function StagingLocationModal(props: IStagingLocationModalProps) {
  const { stagingLocation, id, locationOptions, onClose, onReload } = props;

  const [isLoading, setIsLoading] = useState({
    remove: false,
    submit: false,
  });
  const [errorMessage, setErrorMessage] = useState({
    remove: '',
    submit: '',
  });

  const [quantity, setQuantity] = useState(stagingLocation.qty);
  const [location, setLocation] = useState(stagingLocation._id ?? '');

  const handleRemove = async () => {
    setErrorMessage({
      ...errorMessage,
      remove: '',
    });
    setIsLoading({
      ...isLoading,
      remove: true,
    });
    try {
      const resRemove = await Feathers.service(`${serviceName}/staged`).remove(
        stagingLocation.containId
      );

      onReload?.();
      onClose();
      // onCloseParentModal()
    } catch (e) {
      const errMsg = e?.code < 500 ? e?.message : 'Something went wrong. Please try again later.';

      setErrorMessage({
        ...errorMessage,
        remove: errMsg,
      });
    }
    setIsLoading({
      ...isLoading,
      remove: false,
    });
  };

  const handleSubmit = async () => {
    setErrorMessage({
      ...errorMessage,
      submit: '',
    });
    setIsLoading({
      ...isLoading,
      submit: true,
    });
    try {
      const res = await Feathers.service(`${serviceName}/staged`).create({
        quantity,
        orderItem: id,
        fulfillmentStagingLocation: location,
      });

      if (stagingLocation.containId) {
        const resRemove = await Feathers.service(`${serviceName}/staged`).remove(
          stagingLocation.containId
        );
      }

      await Feathers.service('/order-items/status-changer').update(id, {
        status: 'SHIPMENT_PENDING',
      });

      onReload?.();
      onClose();
      // onCloseParentModal()
      // patch is not supported
      // const res = await Feathers.service(`${serviceName}/staged`).patch(
      //   location,
      //   {
      //     quantity,
      //     orderItem: id,
      //   },
      //   { query: {} }
      // )
    } catch (e) {
      const errMsg = e?.code < 500 ? e?.message : 'Something went wrong. Please try again later.';

      setErrorMessage({
        ...errorMessage,
        submit: errMsg,
      });
    }
    setIsLoading({
      ...isLoading,
      submit: false,
    });
  };

  return (
    <Modal isOpen={true} isCentered onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Staging Location {stagingLocation?.name ? `- ${stagingLocation.name}` : ''}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Grid templateColumns="repeat(2, 1fr)" gap={7}>
            <GridItem flex="1">
              <FormControl>
                <FormLabel>Quantity to Stage</FormLabel>
                <Input value={quantity} onChange={(e) => setQuantity(Number(e.target.value))} />
              </FormControl>
            </GridItem>
            <GridItem flex="1">
              <FormControl>
                <FormLabel>Location</FormLabel>
                <Select
                  useBasicStyles
                  options={locationOptions}
                  value={locationOptions?.find((o) => o.value === location)}
                  onChange={(v: any) => setLocation(v.value)}
                />
              </FormControl>
            </GridItem>
          </Grid>

          {errorMessage.remove ||
            (errorMessage.submit && (
              <Alert status="error">{errorMessage.remove || errorMessage.submit}</Alert>
            ))}
        </ModalBody>

        <ModalFooter>
          <Button mr={3} onClick={onClose}>
            Close
          </Button>
          {stagingLocation._id && (
            <Button
              mr={3}
              isDisabled={isLoading.remove || isLoading.submit}
              isLoading={isLoading.remove}
              colorScheme="red"
              onClick={handleRemove}
            >
              Delete
            </Button>
          )}

          <Button
            isDisabled={isLoading.remove || isLoading.submit}
            isLoading={isLoading.submit}
            colorScheme="blue"
            onClick={handleSubmit}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
