import * as React from 'react';
import Select from 'react-select';

export interface AttributeSelectProps {
  options: any[];
  selected: any;
  onChange: Function;
  attributeType: string;
}

export interface AttributeSelectState {}

export default class AttributeSelect extends React.Component<
  AttributeSelectProps,
  AttributeSelectState
> {
  constructor(props: AttributeSelectProps) {
    super(props);

    this.state = {};
  }

  handleChange = (value) => {
    this.props.onChange({
      attributeType: this.props.attributeType,
      value: value.value,
    });
  };

  public render() {
    return (
      <div>
        <Select
          options={this.props.options}
          defaultValue={this.props.options.find((o) => o.value === this.props.selected.valueId)}
          value={this.props.options.find((o) => o.value === this.props.selected.valueId)}
          onChange={this.handleChange}
        />
      </div>
    );
  }
}
