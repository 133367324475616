import * as React from 'react';
import { Switch, Route } from 'react-router-dom';

import List from './List';
import { Detail } from './Detail';

import Users from './users';

const routes = (props) => (
  <Switch>
    <Route exact path="/settings/production/suppliers" component={List} />
    <Route exact path="/settings/production/suppliers/add" component={Detail} />
    <Route exact path="/settings/production/suppliers/:id" component={Detail} />
    <Route path="/settings/production/suppliers/:id/users" component={Users} />
  </Switch>
);

export default routes;
