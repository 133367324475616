import * as React from 'react';
import {
  Button,
  Header,
  Form,
  TextArea,
  Divider,
  Grid,
  Input,
  Image,
  Modal,
  Message,
} from 'semantic-ui-react';
import MessageAdd from './MessageAdd';

export interface PMApprovalProps {
  open: boolean;
  orderItem: any;
  handleClose: any;
  handleSubmit: any;
  handleNeedsRevision: any;
}

export interface PMApprovalState {
  revisionComments: string;
}

export default class PMApproval extends React.Component<PMApprovalProps, PMApprovalState> {
  constructor(props: PMApprovalProps) {
    super(props);

    this.state = {
      revisionComments: '',
    };
  }

  handleSubmit = (values) => {
    this.props.handleSubmit(values);
  };

  handleNeedsRevision = (e, data) => {
    this.props.handleNeedsRevision({ revisionComments: this.state.revisionComments });
  };

  render() {
    return (
      <Modal open={this.props.open} onClose={this.props.handleClose} closeIcon>
        <Modal.Header>PM Approval</Modal.Header>
        <Modal.Content>
          <Message size={'small'}>
            Clicking the button below will approve the job to enter the certification stage.
          </Message>
          <Button fluid primary onClick={this.handleSubmit}>
            Approve
          </Button>
          <Divider />
          <Form>
            <Form.TextArea
              placeholder={'Comments...'}
              onChange={(e, data: any) =>
                this.setState({ ...this.state, revisionComments: data.value })
              }
              value={this.state.revisionComments}
            />
            <Button fluid content="Need Revision" />
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
}
