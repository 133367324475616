import * as React from 'react';
import { Button, Checkbox, Header, Segment, Table, Label, Popup } from 'semantic-ui-react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { FormattedDate } from 'react-intl';
import moment from 'moment';

import {
  useList,
  PageHeaderRow,
  PreLoader,
  PaginatorWrapper,
  EmptyWrapper,
} from '@inkcloud/components';
import { FilterForm } from './FilterForm';

interface InvoicesProps {}

const serviceName = 'invoices';
const pathPrefix = `/accounting/invoices`;

const filterQueryMap = (values) => {
  const filterMap: any = {
    createdAt: {
      $gte: values.start ? moment(values.start).format('YYYY-MM-DD') : undefined,
      $lte: values.end ? moment(values.end).format('YYYY-MM-DD') : undefined,
    },
  };

  return [].reduce(
    (m, field) => ({ ...m, [field]: values[field] ? values[field] : undefined }),
    filterMap
  );
};

export const Invoices: React.FunctionComponent<InvoicesProps & RouteComponentProps<any>> = (
  props
) => {
  const {
    match: { params },
    location,
    history,
  } = props;
  const {
    state,
    reload,
    handleDelete,
    selectedItems,
    clearAll,
    setChecked,
    someSelected,
    toggleAll,
    filter,
    setFilter,
    sort,
    handleSort,
  } = useList({
    feathers: ['find', serviceName, [{ query: { $populate: ['customer'] } }]],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  const { queryResult, isLoading: isStateLoading } = state as any;

  if (!queryResult) {
    return null;
  }

  return (
    <div>
      <PageHeaderRow header="Invoices" subheader="Accounting" />

      <FilterForm value={filter} onChange={(v) => setFilter(v)} onReset={() => setFilter({})} />
      <EmptyWrapper queryResult={queryResult} isStateLoading={isStateLoading}>
        <Segment loading={state.isLoading}>
          <PaginatorWrapper.Top
            filter={filter}
            reload={reload}
            data={queryResult as any}
            pathPrefix={`${pathPrefix}/`}
          />
          <Table size={'small'} sortable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  sorted={sort.createdAt ? sort.createdAt : null}
                  onClick={() => handleSort('createdAt')}
                >
                  Created
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={sort.humanId ? sort.humanId : null}
                  onClick={() => handleSort('humanId')}
                >
                  ID
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={sort.lineAmount ? sort.lineAmount : null}
                  onClick={() => handleSort('lineAmount')}
                >
                  Name
                </Table.HeaderCell>
                <Table.HeaderCell>Total</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {queryResult &&
                (queryResult as any).data.map(
                  (r: {
                    _id: string | number | string[];
                    createdAt: any;
                    humanId: string;
                    customer: any;
                    total: number;
                    order: string;
                  }) => (
                    <Table.Row key={(r as any)._id}>
                      <Table.Cell>
                        <FormattedDate value={r.createdAt} />
                      </Table.Cell>
                      <Table.Cell>
                        <Link to={`/orders/${r?.order}`}>{r?.humanId}</Link>
                      </Table.Cell>
                      <Table.Cell>
                        <Link to={`/customers/${r.customer?._id}`}>
                          {r.customer?.primaryContact
                            ? `${r.customer.primaryContact?.firstName} ${r.customer.primaryContact?.lastName}`
                            : r.customer?.organizationName}
                        </Link>
                      </Table.Cell>
                      <Table.Cell>${r.total}</Table.Cell>
                    </Table.Row>
                  )
                )}
            </Table.Body>
          </Table>
          <PaginatorWrapper.Bottom
            reload={reload}
            data={queryResult as any}
            pathPrefix={`${pathPrefix}`}
          >
            {someSelected && (
              <Button primary type="button" content="Delete" onClick={handleDelete} />
            )}
          </PaginatorWrapper.Bottom>
        </Segment>
      </EmptyWrapper>
    </div>
  );
};
