import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { PageHeaderRow, PreLoader, useFeathers } from '@inkcloud/components';
import { connect } from 'react-redux';

import {
  Badge,
  Box,
  Button,
  Flex,
  Heading,
  Text,
  Spacer,
  IconButton,
  Menu,
  MenuList,
  MenuButton,
  MenuItem,
  MenuDivider,
  Table as ChakraTable,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  TableContainer,
  ButtonGroup,
  Tooltip,
  Icon,
  Tag,
} from '@chakra-ui/react';

import {
  CheckCircleIcon,
  NotAllowedIcon,
  AttachmentIcon,
  DragHandleIcon,
  CloseIcon,
  SmallCloseIcon,
} from '@chakra-ui/icons';

import type { ServiceTypes } from '@inkcloud/icapi-types';

import { FaEllipsisV, FaPrint, FaRegEnvelope, FaCopy } from 'react-icons/fa';

import { FormattedDateHover } from '../../common';

import feathers from '../../bootstrap/feathers';
// import { PurchaseOrdersForm } from './PurchaseOrdersForm'
import { PurchaseOrder } from './PurchaseOrder';
import { ReceivedModal } from './ReceivedModal';
// import PrintablePreviewModal from '../settings/printables/PrintablePreviewModal';
import { VoidModal } from './VoidModal';
import { SendModal } from './SendModal';
import IdModal from './IdModal';
import { PrintTypes } from '../../common/PrintModal';

import { EmailModal } from './EmailModal';

import DuplicateModal from './DuplicateModal';

import { IndexWrappedProps } from '../../bootstrap/IndexWrapper';

import { connectOrderItemModals } from '../../common/OrderItemCard2';

type ICEntity<T extends keyof ServiceTypes> = Awaited<ReturnType<ServiceTypes[T]['get']>>;

// const location: ModelTypes.InventoryLocations

// export type Location = ICEntity<''>
export type Product = ICEntity<'products-legacy'>;
export type Receiving = ICEntity<'purchase-orders/receivings'>;
export type PurchaseOrders = ICEntity<'purchase-orders'> & {
  receiving: Receiving;
  notes: string;
  // items: {
  //   location: Location
  // }[]
};

export interface ConnectedProps {
  user?: any;
}

interface IDetailProps {}

const serviceName = `/purchase-orders`;
const pathPrefix = `/purchase-orders`;
const service = feathers.service(serviceName);
const commonParams = {
  query: {
    $populate: [
      'vendor',
      'items.product',
      'receivings.receivedBy',
      'receivings.items.product',
      'receivings.items.location',
      'privateNotes.createdBy',
    ],
  },
};

const { useState } = React;

const PreDetail: React.FunctionComponent<
  IDetailProps & ConnectedProps & RouteComponentProps<{ id?: string }>
> = (props) => {
  const {
    match: {
      params: { id },
    },
    history,
    user,
  } = props;

  const isEdit = !!id;
  const [state, reload] = useFeathers('get', serviceName, [id, commonParams], true);
  const [errorMessage, setErrorMessage] = useState('');
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isSendModalOpen, setIsSendModalOpen] = useState(false);
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [isDuplicateModalOpen, setIsDuplicateModalOpen] = useState(false);
  const [isIdModalOpen, setIsIdModalOpen] = useState(false);

  const [isVoidModalOpen, setIsVoidModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleSubmit = async (values: PurchaseOrders) => {
    setErrorMessage('');
    try {
      if (isEdit) {
        await service.patch(id, values, commonParams);
      } else {
        await service.create(values, commonParams);
      }
      history.push(pathPrefix);
    } catch (e) {
      const errMsg =
        e?.code < 500 ? e.message : 'We are experiencing technical difficulties. Please try again';
      setErrorMessage(errMsg);
    }
  };

  const printHandleOpenOrderLevel = (modaltype: PrintTypes, payload: { _id: string }) => {
    (props as any).printHandleOpen(modaltype, payload);
  };

  const pageTitle = isEdit ? 'Edit' : 'New';

  if (isEdit && !state.data) {
    return <PreLoader />;
  }

  const initialData: PurchaseOrders = { ...state.data };

  initialData.items = initialData?.items?.map((item) => {
    const alreadyReceivedCount =
      initialData?.receivings
        ?.filter((r) => !r.isVoid)
        .reduce((acc, cur) => {
          const foundReceiveItemsSum =
            cur.items
              .filter((i) => i.purchaseOrderItem === item._id)
              .reduce((receiveTotal, receiveItem) => receiveTotal + receiveItem.amount, 0) || 0;

          return acc + foundReceiveItemsSum;
        }, 0) || 0;

    return { ...item, receivedOrShipped: alreadyReceivedCount };
  });

  const receiveItems = initialData?.items
    ?.map((item) => {
      const alreadyReceivedCount =
        initialData?.receivings
          ?.filter((r) => !r.isVoid)
          .reduce((acc, cur) => {
            const foundReceiveItemsSum =
              cur.items
                .filter((i) => i.purchaseOrderItem === item._id)
                .reduce((receiveTotal, receiveItem) => receiveTotal + receiveItem.amount, 0) || 0;

            return acc + foundReceiveItemsSum;
          }, 0) || 0;

      const remainingQuantity = item.quantity - alreadyReceivedCount;
      const location = initialData?.receivings?.find(
        (r) => r?.items?.find((i) => i.purchaseOrderItem === item?._id)?.location
      )?.items?.[0]?.location;

      return {
        purchaseOrderItem: item._id,
        quantity: item?.quantity,
        shippedOrReceived: alreadyReceivedCount,
        amount: remainingQuantity,
        location,
        remainingQuantity,
        key: (item?.product as Product)?.key,
        inventory: (item?.product as Product)?.inventory,
      };
    })
    .filter((item) => item.remainingQuantity > 0);

  const receiving = {
    purchaseOrder: id,
    items: receiveItems,
    notes: initialData?.notes,
  };

  // console.log('receiveItems', receiveItems)

  // console.log('initialData', initialData)

  // const receivingItems = initialData?.receivings?.reduce((acc, cur) => {

  //   const items = cur?.items?.map((item) => {}

  //   return acc
  // }, [])

  // const receivingItems = initialData?.receivings?.map((r) => {
  //   const updatedItems = r?.items?.map((item) => {
  //     const foundOrdered: any = initialData?.items?.find((i) => i._id === item.purchaseOrderItem)

  //     return { ...item, sku: foundOrdered?.product?.key }
  //   })

  //   return { ...r, items: updatedItems }
  // })

  // console.log('receivingItems', receivingItems)

  return (
    <Box>
      <Flex mb={16} alignItems={'center'}>
        <Box>
          <Heading fontWeight={'normal'} size="xl">
            Purchase Order {initialData?.humanId ? `#${initialData?.humanId}` : '- New'}
          </Heading>
        </Box>
        <Badge
          ml={4}
          colorScheme={initialData?.status !== 'open' ? 'orange' : 'green'}
          fontSize={'large'}
        >
          {initialData?.humanId ? initialData?.status ?? 'Draft' : ''}
        </Badge>
        <Spacer />
        {id && (
          <>
            <ButtonGroup variant="outline" spacing="2" mr={2}>
              {/* <Button colorScheme="blue" onClick={() => setIsOpenModal(true)}>
                Receive
              </Button> */}
              <Button colorScheme="blue" onClick={() => setIsSendModalOpen(true)}>
                Send
              </Button>
            </ButtonGroup>
            <Menu size="sm">
              <MenuButton
                as={IconButton}
                aria-label="Actions"
                icon={<FaEllipsisV />}
                variant="solid"
                colorScheme={'blue'}
              />
              <MenuList>
                {initialData && (
                  <>
                    <MenuItem icon={<FaPrint />} onClick={() => setIsIdModalOpen(true)}>
                      Edit PO #
                    </MenuItem>
                    <MenuDivider />
                  </>
                )}
                <MenuItem icon={<FaCopy />} onClick={() => setIsDuplicateModalOpen(true)}>
                  Duplicate
                </MenuItem>
              </MenuList>
            </Menu>
          </>
        )}
      </Flex>

      {isOpenModal && (
        <ReceivedModal
          onClose={() => setIsOpenModal(false)}
          receiving={receiving}
          onReload={reload}
        />
      )}

      {isSendModalOpen && (
        <SendModal
          open={isSendModalOpen}
          onReload={reload}
          onClose={() => {
            // reload()
            setIsSendModalOpen(false);
          }}
          purchaseOrderId={state.data._id}
          purchaseOrder={state.data}
          contact={{
            email: (initialData?.vendor as any)?.email,
            name: `${(initialData?.vendor as any)?.firstName ?? ''} ${
              (initialData?.vendor as any)?.lastName ?? ''
            }`,
          }}
        />
      )}

      {/* {isSendModalOpen && <PrintablePreviewModal
          documentType={'purchase-order'}
          renderParams={{id: initialData._id }}
          onClose={() => setIsSendModalOpen(false)}
        />} */}
      <PurchaseOrder
        user={user}
        onReload={reload}
        initialValues={initialData}
        errorMessage={errorMessage}
        onSubmit={handleSubmit}
      />

      {isVoidModalOpen && (
        <VoidModal
          selected={selectedItem}
          data={initialData}
          onClose={() => {
            setIsVoidModalOpen(false);
            setSelectedItem(null);
          }}
          onReload={reload}
        />
      )}

      {isDuplicateModalOpen && (
        <DuplicateModal data={initialData} onClose={() => setIsDuplicateModalOpen(false)} />
      )}

      {isIdModalOpen && (
        <IdModal
          id={initialData._id as string}
          humanId={initialData.humanId as string | number}
          foreignId={initialData.foreignId as string}
          onClose={() => setIsIdModalOpen(false)}
          onReload={reload}
        />
      )}

      {/* <Box flex={1} mb={8} borderWidth={1} borderColor={'gray.300'}>
        <Flex flex={1} align={'center'} py={4} px={6} bgColor={'gray.100'}>
          <Box>
            <Heading size="sm">Recieve History</Heading>
            <Text fontSize={'sm'}>All items received for this purchase order</Text>
          </Box>
        </Flex>
        <Box p={6}>
          {receivingItems?.length > 0 ? (
            <TableContainer>
              <ChakraTable size="sm">
                <Thead>
                  <Tr>
                    <Th>By</Th>
                    <Th>Date</Th>
                    <Th>Sku</Th>
                    <Th isNumeric>Received</Th>
                    <Th textAlign="center">Location</Th>
                    <Th></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {receivingItems?.map((r, idx) => (
                    <React.Fragment key={idx}>
                      <Tr key={idx}>
                        <Td rowSpan={r?.items?.length}>{`${(r.receivedBy as any).firstName} ${
                          (r.receivedBy as any).lastName
                        }`}</Td>
                        <Td rowSpan={r?.items?.length}>
                          <FormattedDateHover value={r.createdAt} />
                        </Td>
                        {r?.items?.length > 0 && (
                          <>
                            <Td>{r?.items?.[0]?.sku ?? ''}</Td>
                            <Td isNumeric>{r.items?.[0].amount}</Td>
                            <Td textAlign="center">{(r.items?.[0].location as any)?.name}</Td>
                          </>
                        )}
                        <Td textAlign="right" rowSpan={r?.items?.length}>
                          {r?.isVoid ? (
                            <Tag>Voided</Tag>
                          ) : (
                            <Tooltip label="Void">
                              <Icon
                                w={5}
                                h={5}
                                style={{ cursor: 'pointer' }}
                                as={SmallCloseIcon}
                                onClick={() => {
                                  setIsVoidModalOpen(true)
                                  setSelectedItem(r)
                                }}
                              />
                            </Tooltip>
                          )}
                        </Td>
                      </Tr>

                      {r?.items?.map((item, index: number) => {
                        if (index === 0) return null

                        return (
                          <Tr key={index}>
                            <Td>{item?.sku ?? ''}</Td>
                            <Td isNumeric>{item?.amount}</Td>
                            <Td textAlign="center">{(item?.location as any)?.name}</Td>
                          </Tr>
                        )
                      })}
                    </React.Fragment>
                  ))}
                </Tbody>
              </ChakraTable>
            </TableContainer>
          ) : (
            <Text>There is no receive history for this purchase order</Text>
          )}
        </Box>
      </Box> */}

      {id && isEmailModalOpen && (
        <EmailModal
          purchaseOrderId={id}
          onReload={reload}
          onClose={() => setIsEmailModalOpen(false)}
        />
      )}
    </Box>
  );
};

const mapStateToProps = (state): ConnectedProps => ({
  user: state.auth.user || {},
});

export const Detail = connect(mapStateToProps)(
  connectOrderItemModals<IndexWrappedProps>(PreDetail)
);
