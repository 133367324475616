import React, { useEffect, useState } from 'react';
import { Button, Box, IconButton, Tooltip, Badge } from '@chakra-ui/react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { FormattedDate, FormattedTime } from 'react-intl';

import { FaEdit } from 'react-icons/fa';

import {
  useList,
  ListPage,
  basicQueryMap,
  generateOperatorOptions,
  Operator,
} from '@inkcloud/components';
import { useSelector } from 'react-redux';

import feathers from '../../bootstrap/feathers';

interface ListProps {}

const serviceName = '/rfqs';
const pathPrefix = '/rfqs';

const filterQueryMap = (values) => {
  let filterRows = basicQueryMap(values, {});

  const { status } = filterRows;

  filterRows = {
    ...filterRows,
    'responses.status': status?.$elemMatch.value,
  };

  delete filterRows.status;

  return filterRows;
};

const statusOptions = [
  { label: 'Pending', value: 'pending' },
  { label: 'Responded', value: 'responded' },
];

export async function getFilterDefinition() {
  const definition = [
    {
      label: 'Date:Created',
      value: 'createdAt',
      operators: generateOperatorOptions([Operator.Between]),
      type: 'date',
    },
    {
      label: 'Project Name',
      value: 'projectName',
      operators: generateOperatorOptions([Operator.Like, Operator.Is]),
    },
    {
      label: 'Status',
      value: 'status',
      operators: generateOperatorOptions([Operator.Is]),
      valueOptions: statusOptions,
      type: 'select',
    },
    {
      label: 'ID',
      value: 'humanId',
      operators: generateOperatorOptions([Operator.Is]),
    },
  ];

  return definition.sort((a: any, b: any) => a.label.localeCompare(b.label));
}

const currentDate = new Date();

function dateCompare(dueDate: Date) {
  return currentDate > new Date(dueDate) ? 'red' : 'yellow';
}

export default function List(props: ListProps & RouteComponentProps) {
  const {
    match: { params },
    location,
    history,
  } = props;

  const authUser = useSelector((state: any) => state.auth.user);

  const tableDefinition = [
    {
      key: 'humanId',
      label: 'ID',
      sortable: true,
      cell: (v, r) => v,
    },
    {
      key: 'createdAt',
      label: 'Date',
      sortable: true,
      cell: (v) => (
        <Tooltip hasArrow aria-label="time" label={<FormattedTime value={v} />}>
          <Box as="span">
            <FormattedDate value={v} />
          </Box>
        </Tooltip>
      ),
    },
    {
      key: 'projectName',
      label: 'Project Name',
      sortable: true,
    },
    {
      key: 'status',
      label: 'Status',
      sortable: true,
      cell: (v, r) => (
        <React.Fragment>
          <Badge
            variant="outline"
            colorScheme={
              r?.responses[0]?.status === 'pending'
                ? dateCompare(r?.estimateDueDate as Date)
                : 'green'
            }
          >
            {r.responses[0]?.status}
          </Badge>
        </React.Fragment>
      ),
    },
    {
      key: '',
      label: '',
      sortable: false,
      cell: (v, r) => (
        <Tooltip label="Editable">
          <Link to={`${pathPrefix}/${r._id}`}>
            <IconButton colorScheme="gray" aria-label="Edit" icon={<FaEdit />} />
          </Link>
        </Tooltip>
      ),
      textAlign: 'right',
    },
  ];

  const useListValues = useList({
    feathers: [
      'find',
      serviceName,
      [
        {
          query: {},
        },
      ],
    ],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  return (
    <ListPage
      header={'RFQs'}
      subheader="List"
      useListValues={useListValues}
      history={history}
      pathPrefix={pathPrefix}
      tableDefintion={tableDefinition}
      withCheckboxes={false}
      getFilterDefinition={getFilterDefinition}
    />
  );
}
