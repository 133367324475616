import React, { useEffect, useReducer } from 'react';
import { Box, HStack, useEditable } from '@chakra-ui/react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import withScrolling, {
  createVerticalStrength,
  createHorizontalStrength,
} from 'react-dnd-scrolling';
import JobSchedulingColumn from './JobSchedulerColumn';
import { JobCardData } from './JobCard';
import { Feathers } from '../../bootstrap/feathers/feathers';

export interface JobSchedulingColumnDefinition {
  id: string;
  name: string;
  sortOrder: number;
}

export interface IJobSchedulingBoardProps {
  hideEmptyColumns?: boolean;
  columns: JobSchedulingColumnDefinition[];
  jobs: JobCardData[];
  onJobSelected?: (_id: string, data: any) => void;
}

const Scrollzone = withScrolling('div');
const linearHorizontalStrength = createHorizontalStrength(200);
const linearVerticalStrength = createVerticalStrength(150);

function ease(val) {
  const t = (val + 1) / 2; // [-1, 1] -> [0, 1]
  const easedT = t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
  return easedT * 2 - 1; // [0, 1] -> [-1, 1]
}

function hStrength(box, point) {
  return ease(linearHorizontalStrength(box, point));
}

function vStrength(box, point) {
  return ease(linearVerticalStrength(box, point));
}

function init(initialArgs) {
  // console.log('initialArgs', initialArgs);
  const { columns, jobs } = initialArgs;
  columns?.sort((a, b) => a?.sortOrder - b?.sortOrder);
  const columnDataRows = columns?.map((c) => ({
    column: c,
    jobs: jobs.filter((j) => j?.column === c?._id),
  }));

  // console.log({ columns, jobs, columnDataRows }, 'init');

  return {
    columns,
    jobs,
    columnDataRows,
  };
}

function reducer(state, action) {
  // console.log({ state, action });
  const { jobId, newColumnId, selectedJob } = action.payload;
  const jobs = state?.jobs?.map((j) => {
    if (j._id === jobId) {
      return {
        ...j,
        column: newColumnId,
      };
    }
    return j;
  });
  switch (action.type) {
    case 'setJobColumn':
      return {
        ...state,
        columnDataRows: init({ columns: state.columns, jobs }).columnDataRows,
        jobs,
      };

    case 'setSelectedJob':
      return {
        ...state,
        selectedJob,
      };
    case 'clearSelectedJob':
      return {
        ...state,
        selectedJob: undefined,
      };

    case 'init':
      return init(action.payload);
    default:
    // return init(state);
  }

  throw Error(`Unknown action ${action.tyoe}`);
}

const JobSchedulingBoard: React.FunctionComponent<IJobSchedulingBoardProps> = (props) => {
  const { columns, jobs, hideEmptyColumns, onJobSelected } = props;

  const [state, dispatch] = useReducer(reducer, { columns, jobs }, init);

  const { columnDataRows } = state;

  const setJobColumn = async (jobId: string, newColumnId: string) => {
    dispatch({ type: 'setJobColumn', payload: { jobId, newColumnId } });
    try {
      await Feathers.service('job-scheduler/jobs').patch(jobId, { column: newColumnId });
    } catch (e) {
      console.error(e);
    }
  };
  useEffect(() => {
    if (columns.length && jobs.length) {
      dispatch({ type: 'init', payload: { columns, jobs } });
    }
  }, [columns, jobs]);

  // if (columns.length === 0) {
  //   return <div>No columns defined</div>;
  // }

  // console.log({ state });

  return (
    <Box my={4} p={3} bg="white" borderColor={'gray.200'} borderWidth={1} minH="72vh">
      <HStack spacing={4} alignContent={'start'} alignItems={'flex-start'}>
        <DndProvider backend={HTML5Backend}>
          <Scrollzone
            horizontalStrength={hStrength}
            verticalStrength={vStrength}
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              overflowX: 'auto',
              width: '100%',
              maxHeight: '70vh',
            }}
          >
            {columnDataRows
              ?.filter((c) => !props.hideEmptyColumns || c.jobs.length > 0)
              ?.map((c, index) => (
                <JobSchedulingColumn
                  key={c.column._id}
                  index={index}
                  id={c.column._id}
                  isGlobal={c.column?.isGlobal ?? false}
                  columnTitle={c.column.name}
                  jobs={c.jobs}
                  updateJobColumn={setJobColumn}
                  onClick={(_id: string, data: any) => {
                    if (onJobSelected) {
                      onJobSelected(_id, data);
                    }
                  }}
                />
              ))}
          </Scrollzone>
        </DndProvider>
      </HStack>
    </Box>
  );
};

export default JobSchedulingBoard;
