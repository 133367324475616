import * as React from 'react';
import { Modal } from 'semantic-ui-react';
import Feathers from '../../../bootstrap/feathers';

import { ProductPermissionsForm } from './ProductPermissionsForm';

interface ProductModalProps {
  isOpen: boolean;
  onClose: () => void;
  onReload?: () => void;
  micrositeId?: string;
  id?: string;
  productName?: string;
  productKey?: string;
}

const serviceName = '/microsites/products';
const service = Feathers.service(serviceName);
const commonParams = { query: {} };

const { useState, useEffect } = React;

export const ProductModal: React.FunctionComponent<ProductModalProps> = (props) => {
  const { micrositeId, productName, productKey, isOpen, onClose, onReload, id } = props;

  const [data, setData] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const loadProduct = async () => {
      const res: any = await Feathers.service('/microsites/products').get(id, {
        query: {
          $select: ['groupPermissions'],
        },
      });

      setData(res);
    };

    loadProduct();
  }, [id]);

  const handleSubmit = async (values) => {
    setIsSubmitting(true);
    setErrorMessage('');
    const payload = {
      groupPermissions: values.groupPermissions,
    };

    try {
      const res: any = await service.patch(id, payload, commonParams);

      if (res.result !== undefined && res.result === false) {
        setErrorMessage(res.message || res.errorMessage);
      }
      setIsSubmitting(false);
      onClose();
      onReload();
    } catch (e) {
      setIsSubmitting(false);
      e.code && e.code < 500
        ? setErrorMessage(e.message)
        : setErrorMessage('We are experiencing technical difficulties. Please try again');
    }
  };

  return (
    <Modal open={isOpen} onClose={onClose} closeIcon>
      <Modal.Header>
        {productName} - {productKey}
      </Modal.Header>
      <Modal.Content>
        <ProductPermissionsForm
          initialValues={data}
          onSubmit={handleSubmit}
          isSubmitting={isSubmitting}
          errorMessage={errorMessage}
          micrositeId={micrositeId}
        />
      </Modal.Content>
    </Modal>
  );
};
