import React, { useEffect } from 'react';
// import { services } from '././../bootstrap/feathers';
// import { feathersAuthentication } from './../bootstrap/feathers';
import { Box, Flex, Spinner } from '@chakra-ui/react';

import app from '../bootstrap/feathers';

export default function LoginAs() {
  useEffect(() => {
    app.logout().then(() => {
      localStorage.clear();
      window.localStorage.removeItem('feathers-jwt');
      window.localStorage.setItem('feathers-jwt', '');
      const url = new URL(window.location.href);
      const jwt = url.searchParams.get('jwt');
      const r = url.searchParams.get('r');
      const m = url.searchParams.get('mode');

      localStorage.setItem('feathers-jwt', jwt as string);
      if (['production', 'sandbox'].includes(m as string)) {
        localStorage.setItem('mode', m as string);
      }

      setTimeout(() => {
        window.location.href = r || '/';
      }, 500);
    });
  }, []);

  return (
    <Flex alignItems="center" justifyContent="center" minH="100vh">
      <Box display="inline-block">
        <Spinner /> Redirecting...
      </Box>
    </Flex>
  );
}
