import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Segment, Form, Dropdown, Message } from 'semantic-ui-react';
import { PageHeaderRow } from '@inkcloud/components';
import Feathers from '../../../bootstrap/feathers';
import { connect } from 'react-redux';

interface DetailProps {}

export interface ConnectedProps {
  user?: any;
}

const { useState, useEffect } = React;

const pathPrefix = `/settings/payment-options`;
const serviceName = '/tenant-settings';
const commonParams = { query: { $select: ['payment'] } };

export const PreDetail: React.FunctionComponent<
  DetailProps & ConnectedProps & RouteComponentProps<{ id?: string }>
> = (props) => {
  const { history, user } = props;

  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [paymentOptions, setPaymentOptions] = useState([]);
  const [methods, setMethods] = useState([]);

  useEffect(() => {
    const LoadOptions = async () => {
      const result: any = await Feathers.service('tenant-settings').get(user.business, {
        query: {
          $select: ['payment'],
        },
      });

      setMethods(
        result && result.payment && result.payment.methods && result.payment.methods.length > 0
          ? result.payment.methods
          : []
      );
    };

    const loadPaymentMethods = async () => {
      const result: any = await Feathers.service('tenant-settings/payment-methods').find({
        query: {
          $np: 1,
          $sort: { name: 1 },
        },
      });
      setPaymentOptions(result);
    };

    loadPaymentMethods();
    LoadOptions();
  }, []);

  const handleChange = (value: any) => {
    setMethods(value);
  };

  const handleSubmit = async (values) => {
    try {
      setErrorMessage('');
      setIsLoading(true);
      await Feathers.service('tenant-settings/payment-methods').create(
        { paymentMethods: methods },
        { query: {} }
      );
      setIsLoading(false);
      history.push('/settings');
    } catch (e) {
      setIsLoading(false);
      e.code && e.code < 500
        ? setErrorMessage(e.message)
        : setErrorMessage('We are experiencing technical difficulties. Please try again');
    }
  };

  return (
    <div>
      <PageHeaderRow header={`Payment Options`} subheader="Settings" subheaderId={''}>
        <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
      </PageHeaderRow>

      <Segment>
        <Form loading={isLoading} onSubmit={() => handleSubmit({ methods })}>
          <Form.Field>
            <Dropdown
              fluid
              selection
              multiple
              name="methods"
              value={methods}
              options={
                paymentOptions && paymentOptions.length > 0
                  ? paymentOptions.map((p) => ({ text: p.text, value: p.key }))
                  : []
              }
              onChange={(e, data) => handleChange(data.value)}
            />
          </Form.Field>

          <Form.Field>
            <Button primary type="submit">
              Save
            </Button>
          </Form.Field>
        </Form>
        {errorMessage && <Message error content={errorMessage} />}
      </Segment>
    </div>
  );
};

const mapStateToProps = (state): ConnectedProps => ({
  user: state.auth.user || {},
});

export const Detail = connect(mapStateToProps)(PreDetail as any);
