import { Operator, generateOperatorOptions } from '@inkcloud/components';
import Feathers from '../../bootstrap/feathers';

export async function getFilterDefinition() {
  let definition = [
    {
      label: 'Date:Approved',
      value: 'approvedAt',
      operators: generateOperatorOptions([Operator.Between]),
      type: 'date',
    },
    {
      label: 'Date:Created',
      value: 'createdAt',
      operators: generateOperatorOptions([Operator.Between]),
      type: 'date',
    },
    {
      label: 'Date:Updated',
      value: 'updatedAt',
      operators: generateOperatorOptions([Operator.Between]),
      type: 'date',
    },
    {
      label: 'Foreign ID',
      value: 'foreignId',
      operators: generateOperatorOptions([Operator.Is]),
    },

    {
      label: 'ID',
      value: 'humanId',
      operators: generateOperatorOptions([Operator.Like, Operator.Is]),
    },
    {
      label: 'Microsite',
      value: 'microsite',
      operators: generateOperatorOptions([Operator.Is, Operator.IsNot]),
      valueOptions: [],
      asyncValueOptions: async () =>
        Feathers.service('microsites')
          .find({
            query: {
              $np: '1',
              $sort: { name: 1 },
            },
          })
          .then((results) => results.map((r) => ({ value: r._id, label: r.name }))),
      type: 'multi-select',
    },
    {
      label: 'PO',
      value: 'po',
      operators: generateOperatorOptions([Operator.Like, Operator.Is]),
    },

    {
      label: 'Sales Person',
      value: 'salesPerson',
      operators: generateOperatorOptions([Operator.Is, Operator.IsNot]),
      valueOptions: [],
      asyncValueOptions: async () =>
        Feathers.service('sales-people')
          .find({
            query: {
              $np: '1',
              $sort: { name: 1 },
            },
          })
          .then((results) =>
            results.map((r) => ({ value: r._id, label: `${r.firstName} ${r.lastName}` }))
          ),
      type: 'multi-select',
    },

    {
      label: 'Sales Person:Manager',
      value: 'manager',
      operators: generateOperatorOptions([Operator.Is, Operator.IsNot]),
      valueOptions: [],
      asyncValueOptions: async () =>
        Feathers.service('sales-people')
          .find({
            query: {
              $np: '1',
              isManager: true,
              $sort: { name: 1 },
            },
          })
          .then((results) =>
            results.map((r) => ({ value: r._id, label: `${r.firstName} ${r.lastName}` }))
          ),
      type: 'multi-select',
    },
    {
      label: 'Tracking Number',
      value: 'actualShipments.trackingNumber',
      operators: generateOperatorOptions([Operator.Like, Operator.Is]),
    },
  ];

  return definition;
}
