import * as React from 'react';
import { Button, Segment, Table, Popup } from 'semantic-ui-react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { FormattedDate, FormattedTime } from 'react-intl';
import { humanize } from 'underscore.string';

import {
  useList,
  PageHeaderRow,
  PreLoader,
  PaginatorWrapper,
  EmptyWrapper,
} from '@inkcloud/components';

interface ListProps {}

const serviceName = '/microsites/contact-us-entries';
const pathPrefix = '/customers/contact-form-submissions';

const { useState } = React;

export const filterQueryMap = (values) => {
  const filterMap: any = {};

  return [].reduce(
    (m, field) => ({
      ...m,
      [field]: values[field] ? values[field] : undefined,
    }),
    filterMap
  );
};

export const List: React.FunctionComponent<ListProps & RouteComponentProps<any>> = (props) => {
  const {
    match: {
      params: { id },
    },
    location,
    history,
  } = props;
  const {
    state,
    reload,
    handleDelete,
    selectedItems,
    clearAll,
    setChecked,
    someSelected,
    toggleAll,
    filter,
    setFilter,
    sort,
    handleSort,
  } = useList({
    feathers: [
      'find',
      serviceName,
      [
        {
          query: {
            microsite: id,
          },
        },
      ],
    ],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  const { queryResult, isLoading: isStateLoading } = state as any;

  if (!queryResult) {
    return null;
  }

  return (
    <div>
      <PageHeaderRow header="Contact Form Submissions" subheader="List">
        <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
      </PageHeaderRow>

      <EmptyWrapper queryResult={queryResult} isStateLoading={isStateLoading}>
        <Segment loading={state.isLoading}>
          <PaginatorWrapper.Top
            filter={filter}
            reload={reload}
            data={queryResult as any}
            pathPrefix={`${pathPrefix}`}
          />
          <Table size="small" compact sortable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  <input type="checkbox" onChange={toggleAll} />
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={sort.createdAt ? sort.createdAt : null}
                  onClick={() => handleSort('createdAt')}
                  collapsing
                >
                  Date
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={sort.name ? sort.name : null}
                  onClick={() => handleSort('name')}
                >
                  Name
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={sort.email ? sort.email : null}
                  onClick={() => handleSort('email')}
                >
                  Email
                </Table.HeaderCell>
                <Table.HeaderCell>Content</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {queryResult &&
                (queryResult as any).data.map((r) => (
                  <Table.Row key={r._id} verticalAlign="top">
                    <Table.Cell>
                      <input
                        type="checkbox"
                        value={r._id}
                        checked={selectedItems[r._id] || false}
                        onChange={setChecked}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <Popup
                        size="mini"
                        content={<FormattedTime value={r.createdAt} />}
                        trigger={
                          <div>
                            <FormattedDate value={r.createdAt} />
                          </div>
                        }
                      />
                    </Table.Cell>
                    <Table.Cell>{r.name}</Table.Cell>
                    <Table.Cell>
                      {r.email ? <a href={`mailto:${r.email}`}>{r.email}</a> : ''}
                    </Table.Cell>
                    <Table.Cell>
                      {r?.customFields?.map((custom: any, index: number) => (
                        <div key={index}>
                          <div>
                            <strong>{humanize(custom?.key ?? '')} </strong>
                          </div>
                          <div>{custom?.value ?? ''}</div>
                        </div>
                      ))}
                      <div>
                        <strong>Message</strong>
                      </div>
                      <div>{r.message}</div>
                    </Table.Cell>
                  </Table.Row>
                ))}
            </Table.Body>
          </Table>
          <PaginatorWrapper.Bottom
            reload={reload}
            data={queryResult as any}
            pathPrefix={`${pathPrefix}`}
          >
            {someSelected && (
              <Button primary type="button" content="Delete" onClick={handleDelete} />
            )}
          </PaginatorWrapper.Bottom>
        </Segment>
      </EmptyWrapper>
    </div>
  );
};
