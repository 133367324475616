import React, { useEffect, useState } from 'react';

import { useHistory, useParams } from 'react-router-dom';
import {
  Button,
  Modal,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  Flex,
  Center,
  Spinner,
} from '@chakra-ui/react';
import JobSchedulerWorkflowTemplateTypeForm from './JobWorkflowTemplateTypeForm';
import { Feathers } from '../../../bootstrap/feathers/feathers';
import List from './List';

interface IJobWorkflowTemplateTypeModalProp {
  isOpen: boolean;
  onClose: () => void;
}

export default function JobWorkflowTemplateTypeModal(props: IJobWorkflowTemplateTypeModalProp) {
  const { isOpen, onClose } = props;
  const history = useHistory();

  const { id } = useParams<{ id: string }>();

  const isEdit = !!id;
  const isAdd = history.location.pathname.includes('add');

  const [type, setType] = useState({ name: '' });

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const loadView = async () => {
      setIsLoading(true);
      try {
        const res = await Feathers.service('job-scheduler/workflow-template-types').get(id, {
          query: {},
        });

        setType(res);
      } catch (e) {
        console.log('e');
      }

      setIsLoading(false);
    };

    if (id) {
      loadView();
    }
  }, [id]);

  const handleSubmit = async (v) => {
    setIsSubmitting(true);
    setErrorMessage('');
    const payload = v;

    payload.columns = v?.columns?.map((c) => c.value);
    try {
      const res = isEdit
        ? await Feathers.service('job-scheduler/workflow-template-types').patch(id, payload)
        : await Feathers.service('job-scheduler/workflow-template-types').create(payload);
      history.push('/job-scheduler/workflow-template-types');
    } catch (e) {
      const errMsg = e?.code < 500 ? e?.message : 'Something went wrong. Please try again later.';

      setErrorMessage(errMsg);
    }
    setIsSubmitting(false);
  };
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size={'2xl'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Workflow Template Types</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {isAdd || isEdit ? (
              <>
                <Flex justifyContent="end">
                  <Button
                    onClick={() => {
                      setType({ name: '' });
                      history.goBack();
                    }}
                  >
                    Back
                  </Button>
                </Flex>
                {isLoading ? (
                  <Center>
                    <Spinner />
                  </Center>
                ) : (
                  <JobSchedulerWorkflowTemplateTypeForm
                    key={id}
                    initialValues={isEdit ? type : { name: '' }}
                    errorMessage={errorMessage}
                    isSubmitting={isSubmitting}
                    onSubmit={handleSubmit}
                  />
                )}
              </>
            ) : (
              <List />
            )}
          </ModalBody>

          <ModalFooter>
            <Button mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
