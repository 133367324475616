import * as React from 'react';
import { Switch, Route } from 'react-router-dom';

import { List } from './List';
import { Detail } from './Detail';
import { VendorDetail } from './VendorDetail';

const routes = (props) => (
  <Switch>
    <Route exact path="/purchase-orders" component={List} />
    <Route path="/purchase-orders/vendor-detail/:id" component={VendorDetail} />
    <Route path="/purchase-orders/add" component={Detail} />
    <Route path="/purchase-orders/:id" component={Detail} />
  </Switch>
);

export default routes;
