import React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { FormattedDate, FormattedTime } from 'react-intl';
import { Button, Box, IconButton, HStack, Tag, Tooltip, ButtonGroup } from '@chakra-ui/react';
import { humanize } from 'underscore.string';

import { FaEdit } from 'react-icons/fa';

import {
  useList,
  ListPage,
  basicQueryMap,
  generateOperatorOptions,
  Operator,
} from '@inkcloud/components';

interface ListProps {}

const serviceName = 'general-ledger-accounts';
const pathPrefix = `/settings/gl-accounts`;

const filterQueryMap = (values) => {};

export default function List(props: ListProps & RouteComponentProps) {
  const {
    match: { params },
    location,
    history,
  } = props;

  const tableDefinition = [
    {
      key: 'createdAt',
      label: 'Date',
      sortable: true,
      cell: (v) => (
        <Tooltip hasArrow aria-label="time" label={<FormattedTime value={v} />}>
          <Box as="span">
            <FormattedDate value={v} />
          </Box>
        </Tooltip>
      ),
    },
    {
      key: 'name',
      label: 'Name',
      sortable: true,
    },
    {
      key: 'accountType',
      label: 'Account Type',
      sortable: true,
      cell: (v, r) => humanize(v),
    },
    {
      key: 'accountNumber',
      label: 'Account Number',
      sortable: true,
      cell: (v, r) => v,
    },
    {
      key: '',
      label: '',
      sortable: false,
      cell: (v, r) => (
        <Tooltip label="Edit Details">
          <Link to={`${pathPrefix}/${r._id}`}>
            <IconButton colorScheme="gray" aria-label="Edit" icon={<FaEdit />} />
          </Link>
        </Tooltip>
      ),
      textAlign: 'right',
    },
  ];

  const useListValues = useList({
    feathers: ['find', serviceName, [{ query: {} }]],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  const headerActions = (
    <>
      <ButtonGroup spacing={1}>
        <Button type="button" size="sm" onClick={() => history.goBack()}>
          Back
        </Button>
        <Link to={`${pathPrefix}/add`}>
          <Button size="sm" colorScheme="blue">
            New
          </Button>
        </Link>
      </ButtonGroup>
    </>
  );

  return (
    <div>
      <ListPage
        header="GL Accounts"
        subheader="Settings"
        useListValues={useListValues}
        history={history}
        pathPrefix={pathPrefix}
        tableDefintion={tableDefinition}
        withCheckboxes={true}
        headerActions={headerActions}
        getFilterDefinition={null}
      />
    </div>
  );
}
