import * as React from 'react';
import { Button, Header, Form, Input, Image, Modal } from 'semantic-ui-react';
import MessageAdd from './MessageAdd';

export interface MessageThreadProps {
  open: boolean;
  orderItem: any;
  handleClose: any;
  handleSubmit: any;
}

export interface MessageThreadState {}

export default class MessageThread extends React.Component<MessageThreadProps, MessageThreadState> {
  constructor(props: MessageThreadProps) {
    super(props);

    this.state = {};
  }

  handleSubmit = (values) => {
    this.props.handleSubmit(values);
  };

  render() {
    return (
      <Modal open={this.props.open} onClose={this.props.handleClose} closeIcon>
        <Modal.Header>Add to Message Thread</Modal.Header>
        <Modal.Content>
          {this.props.orderItem && (
            <MessageAdd tenantId={this.props.orderItem.business} onSave={this.handleSubmit} />
          )}
        </Modal.Content>
      </Modal>
    );
  }
}
