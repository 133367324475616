import * as React from 'react';
import feathers from '../../../bootstrap/feathers';
import { Dropdown, Checkbox, Form, Divider, Button, Input } from 'semantic-ui-react';
import { Formik, Form as FormikForm, Field, FieldArray, ErrorMessage } from 'formik';
import { SemanticField } from '../../../common/Form';
import * as Yup from 'yup';

import * as Cleave from 'cleave.js/react';

const { useEffect, useState } = React;

export interface ICustomItemProps {
  open: boolean;
  isCustomItemLoading: boolean;
  onSubmit: (v: any, a: any) => void;
}

const customItemScheme = Yup.object().shape({
  price: Yup.number().required('Must specify a price').typeError('Must be number only.'),
  designPrice: Yup.number().typeError('Must be number only.'),
  numSets: Yup.number().typeError('Must be number only.'),
  value: Yup.number().required().typeError('Must specify a number'),
  isShippable: Yup.boolean(),
  weight: Yup.number().when('isShippable', {
    is: true,
    then: Yup.number().required('Must specify a weight').typeError('Must be number only.'),
  }),
});

export function CustomItem(props: ICustomItemProps) {
  const { isCustomItemLoading, onSubmit } = props;
  const [attributesGrouped, setAttributesGrouped] = useState(null);

  useEffect(() => {
    const loadAttributes = async () => {
      const attributes: any = await feathers.service('products-legacy/attributes').find({
        query: {
          $np: 1,
          $populate: ['entityAttribute'],
          $sort: { name: 1 },
        },
      });

      let groupedByAttribute = attributes.reduce((acc, attr) => {
        if (!acc[attr.entityAttribute._id]) {
          acc[attr.entityAttribute._id] = {
            _id: attr.entityAttribute._id,
            label: attr.entityAttribute.name,
            options: [],
            checked: false,
            selected: '',
          };
        }

        acc[attr.entityAttribute._id].options.push({ value: attr._id, text: attr.name });

        return acc;
      }, {});

      const finalAttributesGrouped = Object.keys(groupedByAttribute)
        .map((k) => groupedByAttribute[k])
        .filter((a) => a.options); // filter out empty attribute types

      setAttributesGrouped(finalAttributesGrouped);
    };

    loadAttributes();
  }, []);

  const attributes =
    attributesGrouped &&
    attributesGrouped
      .filter((a: any, index: number) => a.checked && a.selected && a.selected)
      .map((f: any) => f.selected);

  const slots = [
    { value: 'MULTIPAGEPDF', label: 'Multi-Page PDF' },
    { value: 'FRONT', label: 'Front' },
    { value: 'BACK', label: 'Back' },
    { value: 'SPOTUVFRONT', label: 'Spot UV Front' },
    { value: 'SPOTUVBACK', label: 'Spot UV Back' },
    { value: 'FOIL_MASK_FRONT', label: 'Foil Mask Front' },
    { value: 'EMBOSS_MASK_FRONT', label: 'Emboss Mask Front' },
    { value: 'DIELINE', label: 'Die Line' },
  ];

  const initialValues = {
    artworkSlots: [],
    wantDesign: 0,
    isShippable: true,
    weight: '',
    value: 1,
    numSets: 1,
    price: '',
  };

  return (
    <>
      <div className={'ui form tiny'}>
        {attributesGrouped &&
          attributesGrouped.map((fa: any, index: number) => (
            <Form.Group key={index}>
              <Form.Field width={5}>
                <Checkbox
                  onClick={(e, data) =>
                    setAttributesGrouped(
                      attributesGrouped.map((ag) =>
                        ag._id === fa._id ? { ...fa, checked: data.checked } : ag
                      )
                    )
                  }
                  label={fa.label}
                />
              </Form.Field>
              {fa.checked && (
                <Form.Field width={5}>
                  <Dropdown
                    fluid
                    selection
                    search
                    clearable
                    options={fa.options}
                    onChange={(e, data) =>
                      setAttributesGrouped(
                        attributesGrouped.map((ag) =>
                          ag._id === fa._id ? { ...fa, selected: data.value } : ag
                        )
                      )
                    }
                    value={fa.selected}
                  />
                </Form.Field>
              )}
            </Form.Group>
          ))}
      </div>

      <Formik
        initialValues={initialValues}
        validationSchema={customItemScheme}
        onSubmit={(value) => onSubmit(value, attributes)}
      >
        {(props) => {
          const { values, setFieldValue } = props;

          return (
            <FormikForm className={`ui form tiny ${isCustomItemLoading && 'loading'}`}>
              <Form.Field>
                <label>Description</label>
                <Field name="description" component="textarea" />
              </Form.Field>
              <Form.Group widths="equal">
                <Form.Field>
                  <label>Price</label>
                  <Field
                    name="price"
                    render={({ field }) => (
                      <Cleave
                        {...field}
                        style={{ textAlign: 'right' }}
                        onChange={(e) => setFieldValue('price', e.target.rawValue)}
                        options={{
                          numeral: true,
                          rawValueTrimPrefix: true,
                          numeralPositiveOnly: true,
                          numeralThousandsGroupStyle: 'thousand',
                          prefix: '$',
                        }}
                      />
                    )}
                  />
                  <ErrorMessage name="price" />
                </Form.Field>

                <Form.Field>
                  <label>Quantity</label>
                  <Field name="value" />
                  <ErrorMessage name="value" />
                </Form.Field>
              </Form.Group>

              <Form.Group>
                <Form.Field width={3}>
                  <SemanticField
                    name={'isShippable'}
                    label="Shippable"
                    toggle
                    component={Form.Checkbox}
                  />
                </Form.Field>
                {values.isShippable && (
                  <Form.Field width={5}>
                    <Field name="weight" placeholder="Weight" />
                    <ErrorMessage name="weight" />
                  </Form.Field>
                )}
              </Form.Group>

              <Form.Group>
                <Form.Field width={3}>
                  <Field
                    name="wantDesign"
                    render={({ field, form }) => (
                      <>
                        <Checkbox
                          label="Want Design"
                          toggle
                          {...field}
                          onChange={(e, data) => {
                            if (data.checked) {
                              form.setFieldValue(data.name, 1);
                            } else {
                              form.setFieldValue(data.name, 0);
                            }
                          }}
                        />
                      </>
                    )}
                  />
                </Form.Field>
                {values.wantDesign === 1 && (
                  <Form.Field width={5}>
                    <Field
                      name="designPrice"
                      placeholder="Design Price"
                      render={({ field }) => (
                        <Cleave
                          {...field}
                          style={{ textAlign: 'right' }}
                          onChange={(e) => setFieldValue('designPrice', e.target.rawValue)}
                          options={{
                            numeral: true,
                            rawValueTrimPrefix: true,
                            numeralPositiveOnly: true,
                            numeralThousandsGroupStyle: 'thousand',
                            prefix: '$',
                          }}
                        />
                      )}
                    />
                  </Form.Field>
                )}
              </Form.Group>

              <label>Artwork Slots</label>
              <FieldArray
                name="artworkSlots"
                render={(arrayHelpers) => (
                  <>
                    {slots.map((s: any, index: number) => (
                      <Form.Field key={index}>
                        <Checkbox
                          name="artworkSlots"
                          label={s.label}
                          value={s.value}
                          onChange={(e, data) => {
                            if (data.checked) {
                              arrayHelpers.push(s.value);
                            } else {
                              const idx = values.artworkSlots.indexOf(s.value);
                              arrayHelpers.remove(idx);
                            }
                          }}
                        />
                      </Form.Field>
                    ))}
                  </>
                )}
              />

              <Form.Field width={6}>
                <label>Number of sets</label>
                <Field name="numSets" />
                <ErrorMessage name="numSets" />
              </Form.Field>
              <Button type="submit" primary content="Add to Cart" />
            </FormikForm>
          );
        }}
      </Formik>
    </>
  );
}
