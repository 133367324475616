import React, { useEffect, useState } from 'react';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import {
  Box,
  Button,
  Input,
  Textarea,
  Alert,
  Checkbox,
  Radio,
  RadioGroup,
  Stack,
  // Select,
  Switch,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import type { ModelTypes } from '@inkcloud/icapi-types';
import * as yup from 'yup';

import type { Option } from 'react-select';
import { Select } from 'chakra-react-select';

import { FormControl } from '../../../common/v3/Form/FormControl';
import DatePickerInput from '../../../common/v3/Form/DatePickerInput';
import { Feathers } from '../../../bootstrap/feathers/feathers';

const yupSchema = yup.object().shape({
  workflowTemplates: yup.array().of(yup.string().required('Required')),
});

interface JobSchedulingColumnFormProps {
  isSubmitting: boolean;
  errorMessage: string;
  onSubmit: (data: any) => void;
}

export default function JobSchedulingColumnForm(props: JobSchedulingColumnFormProps) {
  const { isSubmitting, errorMessage, onSubmit } = props;

  const initialValues = {
    workflowTemplates: [],
    // column: '',
    type: 'offline',
    needsPressCheck: false,
  };

  const [templates, setTemplates] = useState<any[]>([]);
  const [columns, setColumns] = useState<ModelTypes.JobSchedulerColumns[]>([]);

  const useFormReturn = useForm({
    mode: 'onBlur',
    defaultValues: initialValues,
    resolver: yupResolver(yupSchema),
  });

  useEffect(() => {
    const loadWorkflowTemplates = async () => {
      try {
        const res = await Feathers.service('job-scheduler/workflow-templates').find({
          query: {
            $np: 1,
          },
        });

        setTemplates(res?.map((r) => ({ label: r?.name, value: r?._id })) || []);
      } catch (e) {
        console.error(e);
      }
    };

    loadWorkflowTemplates();
  }, []);

  const {
    handleSubmit,

    register,
    setValue,
    watch,
  } = useFormReturn;

  const handleSave: SubmitHandler<{
    workflowTemplates: { workflowTemplateName: string }[];
    // column: string;
    type: string;
    needsPressCheck: boolean;
  }> = (data) => {

    const mappedData = {
      ...data,
      workflowTemplate: data?.workflowTemplates?.[0],
      workflowTemplates: data?.workflowTemplates?.map((t) => ({ workflowTemplate: t })),
      // column: (data?.column as Option)?.value,
    };

    onSubmit(mappedData);
  };

  return (
    <>
      <Box bgColor={'white'} p={8}>
        <FormProvider {...useFormReturn}>
          <Box width={360}>
            <RadioGroup
              onChange={(e) => {
                // console.log('e', e);

                setValue('workflowTemplates', []);
                setValue('type', e);
              }}
              value={watch('type')}
              mb={3}
            >
              <Stack direction="row">
                <Radio value="offline">Offline Job</Radio>
                <Radio value="ic-job">IC Job</Radio>
              </Stack>
            </RadioGroup>
            {watch('type') === 'offline' ? (
              <React.Fragment key="offline">
                <FormControl
                  field="customerName"
                  label="Name"
                  render={(fieldProps) => <Input {...fieldProps} />}
                />
                <FormControl
                  field="workflowTemplate"
                  label="Workflow Template"
                  required
                  render={(fieldProps) => (
                    <Select
                      useBasicStyles
                      isClearable
                      isMulti
                      {...fieldProps}
                      placeholder=""
                      onChange={(e: Option) =>
                        // console.log(
                        //   'e',
                        //   e?.map((t) => t.value)
                        // )
                        setValue(
                          'workflowTemplates',
                          e?.map((t) => t?.value)
                        )
                      }
                      options={templates}
                    />
                  )}
                />
                <FormControl
                  field="jobNumber"
                  label="Job Number"
                  render={(fieldProps) => <Input {...fieldProps} />}
                />

                <FormControl
                  field="customerJobName"
                  label="Job Name"
                  render={(fieldProps) => <Input {...fieldProps} />}
                />
                <FormControl
                  field="description"
                  label="Description"
                  render={(fieldProps) => <Textarea {...fieldProps}></Textarea>}
                />
                <FormControl
                  field="quantity"
                  label="Quantity"
                  render={(fieldProps) => <Input type="number" {...fieldProps} />}
                />
                <FormControl
                  field="size"
                  label="Size"
                  render={(fieldProps) => <Input {...fieldProps} />}
                />
                <FormControl
                  field="stock"
                  label="Stock"
                  render={(fieldProps) => <Input {...fieldProps} />}
                />
                <FormControl
                  field="needsPressCheck"
                  label="Needs Press Check"
                  render={(fieldProps) => <Switch {...fieldProps} />}
                />
                <FormControl
                  field="dueDate"
                  label="Due Date"
                  render={(fieldProps) => (
                    <DatePickerInput {...fieldProps} selected={fieldProps.value} />
                  )}
                />
              </React.Fragment>
            ) : (
              <React.Fragment key="ic-job">
                <FormControl
                  field="orderItem"
                  label="Order Item ID"
                  render={(fieldProps) => <Input {...fieldProps} />}
                />
                <FormControl
                  field="workflowTemplate"
                  label="Workflow Template"
                  required
                  render={(fieldProps) => (
                    <Select
                      useBasicStyles
                      isClearable
                      isMulti
                      {...fieldProps}
                      placeholder=""
                      onChange={(e: Option) =>
                        // console.log(
                        //   'e',
                        //   e?.map((t) => t.value)
                        // )
                        setValue(
                          'workflowTemplates',
                          e?.map((t) => t?.value)
                        )
                      }
                      options={templates}
                    />
                  )}
                />
              </React.Fragment>
            )}
          </Box>
        </FormProvider>

        {errorMessage && (
          <Alert mb={2} status="error">
            {errorMessage}
          </Alert>
        )}

        <Button
          isDisabled={isSubmitting}
          isLoading={isSubmitting}
          colorScheme="blue"
          onClick={handleSubmit(handleSave)}
        >
          Submit
        </Button>
      </Box>
    </>
  );
}
