import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Segment } from 'semantic-ui-react';
import { PageHeaderRow, PreLoader, useFeathers } from '@inkcloud/components';
import feathers from '../../../bootstrap/feathers';
import AllottmentForm from './AllottmentForm';

interface DetailProps {
  products: { value: string; text: string }[];
  categories: { value: string; text: string }[];
}

const serviceName = 'user-allotments';

const commonParams = {
  query: {
    $populate: ['micrositeUser', 'allottmentPolicy', 'products'],
  },
};

export const Detail: React.FunctionComponent<
  DetailProps & RouteComponentProps<{ id?: string; micrositeId: string }>
> = (props) => {
  const {
    match: {
      params: { id, micrositeId },
    },
    history,
  } = props;

  const pathPrefix = `/settings/microsites/${micrositeId}/allotments`;

  const isEdit = !!id;
  const [state, reload] = useFeathers('get', serviceName, [id, commonParams], true);

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const handleSubmit = async (values) => {
    setIsSubmitting(true);
    setErrorMessage('');

    const service = feathers.service(
      values?.allotmentPolicy?.value ? `user-allotment-policies/conversion` : serviceName
    );

    try {
      const payload = {
        ...values,
        $type: values?.$type === 'all' ? 'all' : undefined,
        micrositeUser: values?.micrositeUser?.value,
        allotmentPolicy: values?.allotmentPolicy?.value,
        microsite: micrositeId,
        expiresAt: !values?.allottmentPolicy?.value ? values?.expiresAt : undefined,
        products: !values?.allottmentPolicy?.value
          ? values?.products?.map((p) => p?.value)
          : undefined,
        // $unset: {
        //   micrositeUser: !values.micrositeUser?.value ? '1' : undefined,
        //   allottmentPolicy: !values.allottmentPolicy?.value ? '1' : undefined,
        // },
      };

      if (isEdit) {
        await service.patch(id, payload, commonParams);
      } else {
        await service.create(payload, commonParams);
      }
      history.push(pathPrefix);
    } catch (e) {
      const errMsg =
        e?.code < 500
          ? e?.message
          : 'We are sorry, but something went wrong. Please try again later.';
      setErrorMessage(errMsg);
    }

    setIsSubmitting(false);
  };

  const pageTitle = isEdit ? 'Edit' : 'New';

  if (isEdit && !state.data) {
    return <PreLoader />;
  }

  const initialData = {
    ...state.data,
    products: state.data?.products?.map((p) => ({
      label: `${p?.staticName ? `${p.staticName} - ` : ''} ${p?.key ?? ''}`,
      value: p,
    })),
    microsite: micrositeId,
    micrositeUser: state.data?.micrositeUser?._id
      ? {
        value: state.data?.micrositeUser?._id,
        label: `${state.data?.micrositeUser?.firstName ?? ''} ${state.data?.micrositeUser?.lastName ?? ''
          }`,
      }
      : null,
    allottmentPolicy: state.data?.allottmentPolicy?._id
      ? {
        value: state.data?.allottmentPolicy?._id,
        label: state.data?.allottmentPolicy?.name ?? '',
      }
      : null,
  };
  return (
    <div>
      <PageHeaderRow header={`${pageTitle} Allotments`} subheader="Microsite" subheaderId={''}>
        <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
      </PageHeaderRow>
      <Segment>
        {(!isEdit || state.data) && (
          <AllottmentForm
            initialValues={initialData}
            onSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            errorMessage={errorMessage}
          />
        )}
      </Segment>
    </div>
  );
};
