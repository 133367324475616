import React from 'react';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  Button,
} from '@chakra-ui/react';

interface ExportModalProps {
  isModalOpen: boolean;
  handleClose: () => void;
  exportUrl: string;
}

export const ExportModal: React.FunctionComponent<ExportModalProps> = (props) => {
  const { isModalOpen, handleClose, exportUrl } = props;
  return (
    <Modal isOpen={isModalOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Download Export</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>Your export is ready. Click below to download.</Text>
        </ModalBody>

        <ModalFooter>
          <Button mr={3} onClick={handleClose}>
            Close
          </Button>
          <Button as="a" href={exportUrl} colorScheme="blue">
            Download
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
