import React from 'react';
import { Box, HStack, Tag, Tooltip, IconButton, Button, Input, Alert } from '@chakra-ui/react';
import { useForm, FormProvider } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import type { ModelTypes } from '@inkcloud/icapi-types';
import * as yup from 'yup';
import FormControl from '../../../common/v3/Form/FormControl';

const yupSchema = yup.object().shape({
  name: yup.string().required('Required'),
});

interface IJobSchedulerWorkflowTemplateTypeFormProps {
  initialValues: { name: string };
  isSubmitting: boolean;
  errorMessage: string;
  onSubmit: (data: any) => void;
}

export default function JobSchedulerWorkflowTemplateTypeForm(
  props: IJobSchedulerWorkflowTemplateTypeFormProps
) {
  const { initialValues, isSubmitting, errorMessage, onSubmit } = props;

  const useFormReturn = useForm({
    mode: 'onBlur',
    defaultValues: initialValues,
    resolver: yupResolver(yupSchema),
  });

  const { handleSubmit } = useFormReturn;

  return (
    <Box bgColor={'white'} p={8}>
      <FormProvider {...useFormReturn}>
        <Box width={360}>
          <FormControl
            field="name"
            label="Name"
            required
            render={(fieldProps) => <Input {...fieldProps} />}
          />
        </Box>
      </FormProvider>
      {errorMessage && (
        <Alert mb={3} status="error">
          {errorMessage}
        </Alert>
      )}

      <Button
        isDisabled={isSubmitting}
        isLoading={isSubmitting}
        colorScheme="blue"
        onClick={handleSubmit((data) => onSubmit(data))}
      >
        Submit
      </Button>
    </Box>
  );
}
