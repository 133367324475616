import * as React from 'react';
import { Comment, Header } from 'semantic-ui-react';
import type { ModelTypes } from '@inkcloud/icapi-types';
import { FormattedTime, FormattedDate } from 'react-intl';
import { Icon } from '@chakra-ui/react';
import { HiOutlineUserCircle } from 'react-icons/hi';
import { NotesAddModal } from './NotesModal';

export interface NoteContainerProps {
  userField?: string;
  notes: ModelTypes.Orders['notes'];
  errorMessage?: string;
  isModalOpen: boolean;
  onSubmit: any;
  handleNoteClose: any;
  maxWidth?: any;
  fixedHeight?: any; // not needed but build was failing so added.
  hidePlaceholder?: boolean;
}

export interface NoteContainerState {
  noteContent: string;
}

export default class NoteContainer extends React.Component<NoteContainerProps, NoteContainerState> {
  constructor(props: NoteContainerProps) {
    super(props);

    this.state = {
      noteContent: '',
    };
  }

  handleContentChanged = (e, data) => {
    this.setState({ ...this.state, noteContent: data.value });
  };

  handleSubmit = (e, data) => {
    this.props.onSubmit(this.state.noteContent);
    this.setState({ ...this.state, noteContent: '' });
  };

  render() {
    let comments = [];
    const { userField = 'user', hidePlaceholder = false } = this.props;

    if (this.props.notes && this.props.notes.length > 0) {
      comments =
        this.props.notes?.map((row) => (
          <Comment key={row._id} style={{ display: 'flex' }}>
            <Icon boxSize={8} mr={3} as={HiOutlineUserCircle} />

            <Comment.Content>
              <Comment.Author as="a">
                {row[userField]
                  ? `${row[userField].firstName ?? ''} ${row[userField]?.lastName ?? ''}`
                  : null}
              </Comment.Author>
              <Comment.Metadata>
                <span>
                  <FormattedDate value={row.createdAt} />
                </span>
                <span>
                  <FormattedTime value={row.createdAt} />
                </span>
              </Comment.Metadata>
              <Comment.Text>{row.content}</Comment.Text>
            </Comment.Content>
          </Comment>
        )) ?? [];
    }

    if (comments.length === 0) {
      return (
        <div>
          {hidePlaceholder ? null : <Header as="h6">Leave a Note</Header>}
          {/* {addButton} */}
          <NotesAddModal
            open={this.props.isModalOpen}
            noteContent={this.state.noteContent}
            noteContentChange={this.handleContentChanged}
            // handleOpenClick={this.handleOpenClick}
            errorMessage={this.props.errorMessage}
            onAddClick={this.handleSubmit}
            handleClose={this.props.handleNoteClose}
          />
        </div>
      );
    }

    return (
      <div>
        <Comment.Group
          minimal
          size="small"
          style={{ overflowY: 'auto', height: 130, maxWidth: this.props.maxWidth || 'auto' }}
        >
          {comments}
        </Comment.Group>
        <NotesAddModal
          open={this.props.isModalOpen}
          noteContent={this.state.noteContent}
          noteContentChange={this.handleContentChanged}
          // handleOpenClick={this.handleOpenClick}
          onAddClick={this.handleSubmit}
          handleClose={this.props.handleNoteClose}
        />
      </div>
    );
  }
}
