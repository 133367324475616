import * as React from 'react';
import {
  Button,
  Form,
  Segment,
  Message,
  Menu,
  Grid,
  Header,
  Dropdown,
  Label,
  Modal,
} from 'semantic-ui-react';
import { Formik, Form as FormikForm, Field, FieldArray, FieldProps, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { FeathersDropdown } from '@inkcloud/components';
import Select from 'react-select';

import countries from 'world-countries';
import { SemanticField } from '../../../common/Form';
import { groupByKey } from '../../../common';
import Feathers from '../../../bootstrap/feathers';

interface GroupFormProps {
  initialValues: any;
  onSubmit: Function;
  micrositeId: string;
  isSubmitting?: boolean;
  errorMessage: string;
}

const selectStyles = {
  menu: (styles) => ({ ...styles, zIndex: 99999 }),
};

const paymentMethodOptions = [
  { text: 'Cash', value: 'CASH' },
  { text: 'Credit Card', value: 'CREDIT_CARD' },
  { text: 'Gift Card', value: 'GIFT_CARD' },
  { text: 'Credit Memo', value: 'CREDIT_MEMO' },
  { text: 'Check', value: 'CHECK' },
  { text: 'Offline', value: 'OFFLINE' },
  { text: 'No Payment', value: 'NO_PAYMENT' },
];

const periodOptions = [
  { text: 'Day', value: 'day' },
  { text: 'Week', value: 'week' },
  { text: 'Month', value: 'month' },
  { text: 'Quarter', value: 'quarter' },
  { text: 'Year', value: 'year' },
];

function getActions(permissions, permission) {
  if (!permissions) {
    return [];
  }
  const found = permissions.find((p) => p.name === permission);

  return found ? found.availableActions : [];
}
const { useEffect, useState } = React;

export const GroupForm: React.FC<GroupFormProps> = (props) => {
  const { errorMessage, micrositeId } = props;

  const CustomerContactSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    // key: Yup.string().required('Required'),
    orderLimitsPerUser: Yup.array().of(
      Yup.object().shape({
        value: Yup.number().typeError('Must be a number'),
      })
    ),
  });

  const tenant = useSelector(
    ({ globals }: { globals: { tenant: { internationalization: { countryCode: string } } } }) =>
      globals.tenant.internationalization
  );

  const [shippingServicesOptions, setShippingServicesOptions] = useState([]);

  const [permissions, setPermissions] = useState([]);
  const [selectedPermission, setSelectedPermission] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const loadPermissions = async () => {
      const perms = await Feathers.service('user-permissions').find({});
      setPermissions(perms);
    };

    loadPermissions();
  }, []);
  useEffect(() => {
    const loadShippingServicesOptions = async () => {
      const result: any = await Feathers.service('tenant-settings/shipping-services-options').find({
        query: {
          $np: 1,
          $sort: { name: 1 },
          all: 1,
        },
      });

      const mappedResult = () =>
        result?.map((r) => ({ ...r, value: r.service, label: r.serviceLabel }));

      setShippingServicesOptions(mappedResult);
    };

    loadShippingServicesOptions();
  }, []);

  const initMapper = (values: any) => {
    const mappedValues = {
      ...values,
      paymentMethods: values?.paymentMethods ?? [],
      allowedShippingServicesOnCart: values?.allowedShippingServicesOnCart?.map((s) =>
        shippingServicesOptions?.find((o) => o.service === s)
      ),
      shipping3rdParty: {
        ...(values?.shipping3rdParty ?? {}),
        payment: {
          ...(values.shipping3rdParty?.payment ?? {}),
          countryCode: values.shipping3rdParty?.payment?.countryCode ?? tenant?.countryCode,
        },
      },
    };

    return mappedValues;
  };

  return (
    <Formik
      initialValues={initMapper(props.initialValues)}
      validationSchema={CustomerContactSchema}
      enableReinitialize={true}
      onSubmit={(values, { setSubmitting }) => {
        props.onSubmit(values);
      }}
    >
      {(innerProps) => {
        const {
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
          setFieldValue,
          setFieldTouched,
        } = innerProps;

        return (
          <FormikForm
            className={`ui form ${isSubmitting && !errorMessage && 'loading'} ${
              errorMessage ? 'error' : ''
            }`}
          >
            <Segment>
              <Form.Group widths="equal">
                <Form.Field>
                  <SemanticField name={'name'} label="Name" component={Form.Input} />
                  <ErrorMessage component="div" name="name" />
                </Form.Field>
                {/* <Form.Field>
              <SemanticField name={'key'} label='Key' component={Form.Input}  />
              <ErrorMessage component="div" name="key" />
            </Form.Field> */}
              </Form.Group>

              <Form.Field>
                <label>Can Order For Free Categories</label>
                <Field
                  name={`canOrderForFreeProductCategories`}
                  render={({ field }: FieldProps<any>) => (
                    <>
                      <FeathersDropdown
                        selection
                        fluid
                        search
                        multiple
                        serviceName="/products-legacy/categories"
                        query={{
                          $np: 1,
                          $sort: { name: 1 },
                          $select: ['name', 'internalName', '_id'],
                        }}
                        resultMapper={(r) => ({
                          value: r._id,
                          text: r.internalName ? r.internalName : r.name,
                        })}
                        {...field}
                        onChange={(e, data) =>
                          field.onChange({ target: { value: data.value, name: field.name } } as any)
                        }
                      />
                    </>
                  )}
                />
              </Form.Field>
              <Form.Field>
                <label>Allowed Shipping Services On Cart</label>
                <Field
                  name="allowedShippingServicesOnCart"
                  render={({ field }: FieldProps<any>) => (
                    <Select
                      isMulti
                      {...field}
                      placeholder=""
                      options={groupByKey(shippingServicesOptions, 'carrier', 'carrierLabel')}
                      styles={selectStyles}
                      onChange={(e) =>
                        field.onChange({ target: { value: e, name: field.name } } as any)
                      }
                    />
                  )}
                />
              </Form.Field>

              <Form.Group widths="equal">
                <SemanticField
                  name="paymentMethods"
                  component={Form.Dropdown}
                  label="Methods"
                  selection
                  multiple
                  options={paymentMethodOptions}
                />
              </Form.Group>

              <Menu attached="top" pointing secondary>
                <Menu.Item name="Overall Order Limits" active={true} />
              </Menu>
              <Segment attached="bottom">
                <Form.Group widths="equal">
                  <Form.Field>
                    <SemanticField name={`orderLimitValue`} label="Value" component={Form.Input} />
                    <ErrorMessage
                      render={(msg) => <Message negative size="tiny" content={msg} />}
                      name={`orderLimit`}
                    />
                  </Form.Field>
                  <Form.Field>
                    <SemanticField
                      name={`orderLimitPeriod`}
                      label="Period"
                      selection
                      fluid
                      clearable
                      component={Form.Dropdown}
                      options={periodOptions}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>&nbsp;</label>
                    <SemanticField
                      name={`orderLimitIsAdminsExcluded`}
                      label="Exclude Admins from Limits"
                      component={Form.Checkbox}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>&nbsp;</label>
                    <SemanticField
                      name={`orderLimitEnabled`}
                      label="Enable"
                      component={Form.Checkbox}
                    />
                  </Form.Field>
                </Form.Group>
              </Segment>

              <Menu attached="top" pointing secondary>
                <Menu.Item name="Overall Order Item Limits" active={true} />
              </Menu>
              <Segment attached="bottom">
                <Form.Group widths="equal">
                  <Form.Field>
                    <SemanticField
                      name={`orderItemLimitValue`}
                      label="Value"
                      component={Form.Input}
                    />
                    <ErrorMessage
                      render={(msg) => <Message negative size="tiny" content={msg} />}
                      name={`orderItemLimit`}
                    />
                  </Form.Field>
                  <Form.Field>
                    <SemanticField
                      name={`orderItemLimitPeriod`}
                      label="Period"
                      selection
                      fluid
                      clearable
                      component={Form.Dropdown}
                      options={periodOptions}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>&nbsp;</label>
                    <SemanticField
                      name={`orderItemLimitIsAdminsExcluded`}
                      label="Exclude Admins from Limits"
                      component={Form.Checkbox}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>&nbsp;</label>
                    <SemanticField
                      name={`orderItemLimitEnabled`}
                      label="Enable"
                      component={Form.Checkbox}
                    />
                  </Form.Field>
                </Form.Group>
              </Segment>

              <Menu attached="top" pointing secondary>
                <Menu.Item name="Per Product Order Limits" active={true} />
              </Menu>
              <Segment attached="bottom">
                <FieldArray
                  name="orderLimitsPerUser"
                  render={(arrayHelpers) => (
                    <>
                      {values?.orderLimitsPerUser?.map((ol, index) => (
                        <Form.Group widths="equal" key={index}>
                          <Form.Field>
                            <label>Product (Deprecate)</label>
                            <Field
                              name={`orderLimitsPerUser.${index}.product`}
                              render={({ field }: FieldProps<any>) => (
                                <>
                                  <FeathersDropdown
                                  clearable
                                    selection
                                    fluid
                                    search
                                    serviceName="/products-legacy"
                                    query={{
                                      $np: 1,
                                      $sort: { staticName: 1 },
                                      $select: ['staticName', 'key'],
                                    }}
                                    resultMapper={(r) => ({
                                      key: r._id,
                                      value: r._id,
                                      text: `${r?.staticName} (${r.key})` || '',
                                    })}
                                    {...field}
                                    onChange={(e, data) =>
                                      field.onChange({
                                        target: { value: data.value, name: field.name },
                                      } as any)
                                    }
                                  />
                                </>
                              )}
                            />
                          </Form.Field>
                          <Form.Field>
                            <label>Products</label>
                            <Field
                              name={`orderLimitsPerUser.${index}.products`}
                              render={({ field }: FieldProps<any>) => (
                                <>
                                  <FeathersDropdown
                                    multiple
                                    selection
                                    fluid
                                    search
                                    serviceName="/products-legacy"
                                    query={{
                                      $np: 1,
                                      $sort: { staticName: 1 },
                                      $select: ['staticName', 'key'],
                                    }}
                                    resultMapper={(r) => ({
                                      key: r._id,
                                      value: r._id,
                                      text: `${r?.staticName} (${r.key})` || '',
                                    })}
                                    {...field}
                                    onChange={(e, data) =>
                                      field.onChange({
                                        target: { value: data.value, name: field.name },
                                      } as any)
                                    }
                                  />
                                </>
                              )}
                            />
                          </Form.Field>

                          <Form.Field>
                            <SemanticField
                              name={`orderLimitsPerUser.${index}.value`}
                              label="Value"
                              component={Form.Input}
                            />
                            <ErrorMessage
                              render={(msg) => <Message negative size="tiny" content={msg} />}
                              name={`orderLimitsPerUser.${index}.value`}
                            />
                          </Form.Field>
                          <Form.Field>
                            <SemanticField
                              name={`orderLimitsPerUser.${index}.period`}
                              label="Period"
                              selection
                              fluid
                              component={Form.Dropdown}
                              options={periodOptions}
                            />
                          </Form.Field>
                          <Form.Field>
                            <label>&nbsp;</label>
                            <SemanticField
                              name={`orderLimitsPerUser.${index}.isAdminsExcluded`}
                              label="Exclude Admins from Limits"
                              component={Form.Checkbox}
                            />
                          </Form.Field>
                          <Form.Field>
                            <label>&nbsp;</label>
                            <SemanticField
                              name={`orderLimitsPerUser.${index}.enabled`}
                              label="Enable"
                              component={Form.Checkbox}
                            />
                          </Form.Field>

                          <div>
                            <div className="field">
                              <label>&nbsp;</label>
                            </div>
                            <Button
                              icon="remove"
                              type="button"
                              size={'mini'}
                              onClick={() => arrayHelpers.remove(index)}
                            />
                          </div>
                        </Form.Group>
                      ))}
                      <Form.Group>
                        <div className="field">
                          <Button
                            type="button"
                            size="tiny"
                            onClick={() =>
                              arrayHelpers.push({
                                value: '',
                                period: '',
                                isAdminsExcluded: false,
                                enabled: false,
                              })
                            }
                          >
                            Add New
                          </Button>
                        </div>
                      </Form.Group>
                    </>
                  )}
                />
              </Segment>

              <Form.Group widths="equal">
                <Form.Field>
                  <label>Allotment Policies</label>
                  <Field
                    name="allottmentPolicies"
                    render={({ field }: FieldProps<any>) => (
                      <>
                        <FeathersDropdown
                          selection
                          fluid
                          search
                          multiple
                          clearable
                          serviceName="user-allotment-policies"
                          query={{
                            $np: 1,
                            $sort: { createdAt: -1 },
                            microsite: micrositeId,
                            $populate: ['product'],
                          }}
                          resultMapper={(r) => ({
                            key: r._id,
                            value: r._id,
                            text: `${r?.name ?? ''}`,
                          })}
                          {...field}
                          onChange={(e, data) =>
                            field.onChange({
                              target: { value: data.value, name: field.name },
                            } as any)
                          }
                        />
                      </>
                    )}
                  />
                </Form.Field>
              </Form.Group>

              <Header content="Permissions" />
              <Segment>
                <Button type="button" onClick={() => setIsModalOpen(true)}>
                  {values?.permissions?.length ? 'Edit' : 'Add'} Permissions
                </Button>
              </Segment>
            </Segment>

            <Header as="h4" content="" subheader="" attached="top" />
            <Segment secondary attached="bottom">
              <Grid>
                <Grid.Row>
                  <Grid.Column width="3">Arrive By Date Enabled</Grid.Column>
                  <Grid.Column width="13">
                    <SemanticField
                      component={Form.Checkbox}
                      toggle
                      name="isArriveByDayEnabled"
                      label="Enables arrive by date"
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <Grid>
                <Grid.Row>
                  <Grid.Column width="3">Global Permissions</Grid.Column>
                  <Grid.Column width="13">
                    <SemanticField
                      component={Form.Checkbox}
                      toggle
                      name="globalPermissions"
                      label="Enables Global Permissions"
                    />
                    <ErrorMessage component="div" name="globalPermissions" />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <Grid>
                <Grid.Row>
                  <Grid.Column width="3">Should Auto Apply Allotments</Grid.Column>
                  <Grid.Column width="13">
                    <SemanticField
                      component={Form.Checkbox}
                      toggle
                      name="shouldAutoApplyAllotments"
                      label="Should Auto Apply Allotments"
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <Grid>
                <Grid.Row>
                  <Grid.Column width="3">Can Order For Free</Grid.Column>
                  <Grid.Column width="13">
                    <SemanticField
                      component={Form.Checkbox}
                      toggle
                      name="canOrderForFree"
                      label="Enables Order For Free"
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <Grid>
                <Grid.Row>
                  <Grid.Column width="3">3rd Party Shipping</Grid.Column>
                  <Grid.Column width="13">
                    <SemanticField
                      name={'shipping3rdParty.isEnabled'}
                      toggle
                      label="Enables 3rd Party Shipping"
                      component={Form.Checkbox}
                    />
                    {values?.shipping3rdParty?.isEnabled && (
                      <>
                        <Form.Group widths="equal">
                          <SemanticField
                            component={Form.Input}
                            label="Account"
                            name="shipping3rdParty.payment.account"
                          />
                        </Form.Group>
                        <Form.Group widths="equal">
                          <SemanticField
                            name="shipping3rdParty.payment.countryCode"
                            label="Country"
                            selection
                            search
                            component={Form.Dropdown}
                            options={
                              countries?.map((c: any) => ({
                                text: c.name.official,
                                value: c.cca2,
                              })) ?? []
                            }
                            fluid
                          />
                        </Form.Group>
                        <Form.Group widths="equal">
                          <SemanticField
                            component={Form.Input}
                            label="Postal Code"
                            name="shipping3rdParty.payment.postalCode"
                          />
                        </Form.Group>
                      </>
                    )}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>

            {isModalOpen && (
              <Modal open onClose={() => setIsModalOpen(false)} className="form">
                <Modal.Header>Permissions</Modal.Header>
                <Modal.Content>
                  <FieldArray
                    name="permissions"
                    render={(arrayHelpers) => (
                      <div>
                        {permissions?.length !== 0 && (
                          <React.Fragment>
                            <Form.Group inline>
                              <Dropdown
                                size="mini"
                                clearable
                                selection
                                search
                                options={permissions.map((p) => ({
                                  key: p.name,
                                  value: p.name,
                                  text: p.name,
                                }))}
                                placeholder={'Select a permission to add'}
                                value={selectedPermission}
                                onChange={(e, data) => setSelectedPermission(data.value)}
                              />
                              <Button
                                content={'Add'}
                                size={'mini'}
                                type="button"
                                onClick={() => {
                                  arrayHelpers.push({ actions: [], resource: selectedPermission });
                                  setSelectedPermission(null);
                                }}
                              />
                            </Form.Group>
                          </React.Fragment>
                        )}

                        {values?.permissions?.map((permission, index) => (
                          <React.Fragment key={index}>
                            <Form.Group widths="equal">
                              <Form.Field>
                                <Dropdown
                                  size={'mini'}
                                  selection
                                  multiple
                                  value={permission.action}
                                  placeholder="Select actions..."
                                  options={getActions(permissions, permission.resource).map(
                                    (a) => ({
                                      value: a,
                                      text: a,
                                    })
                                  )}
                                  onChange={(e, data) => {
                                    arrayHelpers.replace(index, {
                                      ...permission,
                                      action: data.value,
                                    });
                                  }}
                                />
                              </Form.Field>
                              <Form.Field>
                                <Label size="small" content={permission.resource} />
                                <Button
                                  basic
                                  size="mini"
                                  icon="remove"
                                  onClick={() => arrayHelpers.remove(index)}
                                />
                              </Form.Field>
                            </Form.Group>
                          </React.Fragment>
                        ))}
                      </div>
                    )}
                  />
                </Modal.Content>
                <Modal.Actions>
                  <Button onClick={() => setIsModalOpen(false)}>Close</Button>
                </Modal.Actions>
              </Modal>
            )}

            {errorMessage && <Message error content={errorMessage} />}

            <Button primary type="submit" disabled={isSubmitting}>
              Save
            </Button>
          </FormikForm>
        );
      }}
    </Formik>
  );
};
