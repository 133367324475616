import * as React from 'react';
import { Button, Input, Dropdown, Segment, Form } from 'semantic-ui-react';
import { FeathersDropdown } from '@inkcloud/components';

export interface FilterFormProps {
  value: any;
  onChange: Function;
  onReset: Function;
}

function initMapper(value) {
  const defaults = {
    vendor: '',
    status: '',
  };

  return Object.keys(defaults).reduce((p, cur) => {
    if (!p[cur]) {
      p[cur] = defaults[cur];
    }
    return p;
  }, value);
}

export function FilterForm(props: FilterFormProps) {
  const { value: preInitValue } = props;

  const value = initMapper(preInitValue);

  const onChange = (field, value) => {
    props.onChange && props.onChange({ ...props.value, [field]: value });
  };

  return (
    <Segment>
      <Form size={'small'}>
        <Form.Group widths="equal">
          <Form.Field>
            <FeathersDropdown
              search
              selection
              clearable
              placeholder="Vendor..."
              serviceName="/production/vendors"
              query={{ $sort: { companyName: 1 } }}
              resultMapper={(r) => ({ value: r._id, text: r.companyName })}
              value={value.vendor}
              onChange={(e, data) => onChange('vendor', data.value)}
            />
          </Form.Field>
          <Form.Field>
            <Form.Dropdown
              search
              selection
              clearable
              placeholder="Status..."
              options={[
                { value: 'draft', text: 'Draft' },
                { value: 'dispatched', text: 'Dispatched' },
                { value: 'in-progress', text: 'In Progress' },
                { value: 'received', text: 'Received' },
              ]}
              value={value.status}
              onChange={(e, data) => onChange('status', data.value)}
            />
          </Form.Field>
        </Form.Group>

        <Form.Field>
          <Button size={'tiny'} content="Reset Form" onClick={() => props.onReset()} />
        </Form.Field>
      </Form>
    </Segment>
  );
}
