import * as React from 'react';
import { Modal } from 'semantic-ui-react';

import feathers from '../../bootstrap/feathers';

import { ContactForm } from '../customers/ContactForm';

interface ICustomerFormModalProps {
  selectNewCustomer: (v: string) => void;
  onReload: () => void;
  onClose: () => void;
}

const { useState } = React;

export const CustomerFormModal = (props: ICustomerFormModalProps) => {
  const { selectNewCustomer, onReload, onClose } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (values) => {
    setIsSubmitting(true);
    setErrorMessage('');
    try {
      const res: any = await feathers.service('customers').create(values, { query: {} });
      selectNewCustomer(res?._id);
      onReload();
      onClose();
    } catch (e) {
      const errMsg =
        e?.code < 500 ? e.message : 'We are experiencing technical difficulties. Please try again';

      setErrorMessage(errMsg);
    }

    setIsSubmitting(false);
  };

  return (
    <Modal open onClose={onClose} closeIcon>
      <Modal.Header>New Customer</Modal.Header>
      <Modal.Content>
        <ContactForm
          initialValues={{}}
          isSubmitting={isSubmitting}
          onSubmit={handleSubmit}
          errorMessage={errorMessage}
        />
      </Modal.Content>
    </Modal>
  );
};
