import React, { useRef, useState } from 'react';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Alert,
  Box,
  FormControl,
  FormLabel,
  Input,
} from '@chakra-ui/react';

import UploadMisc from '../../common/UploadMisc';

import Feathers from '../../bootstrap/feathers';

interface IAttachDocModalProps {
  vendorReconciliationId: string;
  onClose: () => void;
  onReload: () => void;
}

export default function AttachDocModal(props: IAttachDocModalProps) {
  const { vendorReconciliationId, onClose, onReload } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [uploadLog, setUploadLog] = useState('');

  const handleSubmit = async () => {
    setErrorMessage('');
    setIsSubmitting(true);
    try {
      const res = await Feathers.service('vendor-billing-reconciliations/attachments').create({
        label: 'Invoice',
        vendorReconciliationId,
        uploadLog,
      });

      onReload();

      onClose();
    } catch (e) {
      const errMsg = e?.code < 500 ? e?.message : 'Something went wrong. Please try again.';
      setErrorMessage(errMsg);
    }
    setIsSubmitting(false);
  };

  return (
    <Modal isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Attach Invoice Document</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box>
            <UploadMisc
              onUpload={(file) => setUploadLog(file?.uploadLog)}
              isMultiple={false}
              isDraggable={false}
              btnLabel="Upload Document"
            />
          </Box>

          {errorMessage && (
            <Alert status="error" mt={3}>
              {errorMessage}
            </Alert>
          )}
        </ModalBody>

        <ModalFooter>
          <Button isDisabled={isSubmitting} mr={3} onClick={onClose}>
            Close
          </Button>
          <Button
            isDisabled={isSubmitting}
            isLoading={isSubmitting}
            colorScheme="blue"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
