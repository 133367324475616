import React, { useEffect, useState } from 'react';

import { useHistory, useLocation } from 'react-router';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  Tooltip,
  IconButton,
} from '@chakra-ui/react';

import { FaCheck } from 'react-icons/fa';

import type { ModelTypes } from '@inkcloud/icapi-types';
import { useList, ListPage, basicQueryMap } from '@inkcloud/components';

// import { Feathers } from '../../../../bootstrap/feathers/feathers'

type AddressBookType = ModelTypes.AddressBook;

type AddressBookModalProps = {
  onClose: () => void;
  onSelect: (v: AddressBookType) => void;
};

const filterQueryMap = (values) => basicQueryMap(values, {});

const pathPrefix = '/rfqs/add';

export default function AddressBookModal(props: AddressBookModalProps) {
  const { onSelect, onClose } = props;

  // const [addresses, setAddresses] = useState<AddressBookType[]>([])

  // const loadAddressBook = async () => {
  //   try {
  //     const res = await Feathers.service('/address-book').find({
  //       query: {
  //         $sort: { name: 1 },
  //       },
  //     })

  //     setAddresses(res.data)
  //   } catch (e) {
  //     console.error(e)
  //   }
  // }

  // useEffect(() => {
  //   loadAddressBook()
  // }, [])

  const history = useHistory();
  const location = useLocation();

  const useListValues = useList({
    feathers: [
      'find',
      '/address-book',
      [
        {
          query: {
            $populate: ['customer'],
          },
        },
      ],
    ],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { name: 1 },
  });

  const tableDefinition = [
    {
      key: 'name',
      label: 'Name',
    },
    {
      key: '',
      label: 'Address',
      cell: (v, r) => (
        <>
          <Text>{r.address1}</Text>
          <Text>{r.address2}</Text>
        </>
      ),
    },
    {
      key: 'city',
      label: 'City',
    },
    {
      key: 'stateProvince',
      label: 'State',
    },
    {
      key: 'postalCode',
      label: 'Postal Code',
    },
    {
      key: '',
      label: '',
      sortable: false,
      cell: (v, r) => (
        <>
          <Tooltip label="Select">
            <IconButton
              colorScheme="gray"
              aria-label="Select"
              onClick={() => {
                onSelect(r);
                onClose();
              }}
              size="sm"
              icon={<FaCheck />}
            />
          </Tooltip>
        </>
      ),
      textAlign: 'right',
    },
  ];

  return (
    <Modal size="4xl" isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Address Book</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <ListPage
            header=""
            subheader=""
            useListValues={useListValues}
            history={history}
            pathPrefix={pathPrefix}
            tableDefintion={tableDefinition}
            withCheckboxes={false}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
