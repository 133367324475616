import * as React from 'react';
import { Modal, Button, Divider, Form, Message } from 'semantic-ui-react';
import { createPortal } from 'react-dom';

const { useState } = React;

interface NotificationContentModalProps {
  open: boolean;
  onClose: () => void;
  content: string;
  children?: React.ReactNode;
}
const iFrameStyle = {
  frameborder: '0',
  width: '100%',
  height: 400,
  border: '1px solid #eaeaea',
};

export const NotificationContentModal: React.FunctionComponent<NotificationContentModalProps> = ({
  open,
  onClose,
  children,
  content,
  ...props
}) => {
  const [contentRef, setContentRef] = useState(null);
  const mountNode = contentRef && contentRef.contentWindow.document.body;

  if (contentRef) {
    contentRef.contentWindow.document.write(content);
  }
  if (!open) {
    return null;
  }

  return (
    <Modal size={'large'} open={open} onClose={onClose}>
      <Modal.Header>Notification Content</Modal.Header>
      <Modal.Content>
        <iframe {...props} ref={setContentRef} style={iFrameStyle}></iframe>
      </Modal.Content>
      <Modal.Actions>
        <Button negative onClick={onClose}>
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
