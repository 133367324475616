import * as React from 'react';
import { useMediaQuery } from '@chakra-ui/react';
import DesktopContainer from './DesktopContainer';
import MobileContainer from './MobileContainer';

export interface ResponsiveContainerProps {
  leftMargin: any;
  // history: any;
  children?: React.ReactNode;
}

export function ResponsiveContainer(props: ResponsiveContainerProps) {
  const [isLargerThanTablet] = useMediaQuery('(min-width: 768px)');
  return (
    <div>
      <DesktopContainer {...props} />
      {/* {
        isLargerThanTablet ? <DesktopContainer {...props} /> : <MobileContainer {...props} />
      } */}
      {/* <Responsive minWidth={Responsive.onlyTablet.minWidth}>
        <DesktopContainer {...props}>{props.children}</DesktopContainer>
      {/* </Responsive> */}
      {/* <Responsive maxWidth={Responsive.onlyTablet.minWidth}>
        <MobileContainer {...props}>{props.children}</MobileContainer>
      </Responsive> */}
    </div>
  );
}
