import * as React from 'react';
import { Dropdown } from 'semantic-ui-react';

export const optionLanguages = [
  { value: 'af', text: 'Afrikaans' },
  { value: 'sq', text: 'Albanian' },
  { value: 'ar', text: 'Arabic' },
  { value: 'hy', text: 'Armenian' },
  { value: 'az', text: 'Azeri' },
  { value: 'be', text: 'Belarusan' },
  { value: 'bn', text: 'Bengali' },
  { value: 'bs', text: 'Bosnian' },
  { value: 'bg', text: 'Bulgarian' },
  { value: 'my', text: 'Burmese' },
  { value: 'km', text: 'Cambodian' },
  { value: 'ce', text: 'Cantonese' },
  { value: 'ca', text: 'Catalan' },
  { value: 'zh', text: 'Chinese' },
  { value: 'chi', text: 'Chinese - Simplified' },
  { value: 'zho', text: 'Chinese - Traditional' },
  { value: 'cr', text: 'Croatian' },
  { value: 'cs', text: 'Czech' },
  { value: 'da', text: 'Danish' },
  { value: 'nl', text: 'Dutch' },
  { value: 'en', text: 'English' },
  { value: 'et', text: 'Estonian' },
  { value: 'fs', text: 'Farsi' },
  { value: 'fi', text: 'Finnish' },
  { value: 'fr', text: 'French' },
  { value: 'ka', text: 'Georgian' },
  { value: 'de', text: 'German' },
  { value: 'el', text: 'Greek' },
  { value: 'gu', text: 'Gujarati' },
  { value: 'he', text: 'Hebrew' },
  { value: 'hi', text: 'Hindi' },
  { value: 'hu', text: 'Hungarian' },
  { value: 'is', text: 'Icelandic' },
  { value: 'id', text: 'Indonesian' },
  { value: 'it', text: 'Italian' },
  { value: 'ja', text: 'Japanese' },
  { value: 'kn', text: 'Kannada' },
  { value: 'rw', text: 'Kinyarwanda' },
  { value: 'ko', text: 'Korean' },
  { value: 'ku', text: 'Kurdish' },
  { value: 'lo', text: 'Lao' },
  { value: 'la', text: 'Latin' },
  { value: 'lt', text: 'Lithuanian' },
  { value: 'mk', text: 'Macedonian' },
  { value: 'ms', text: 'Malay' },
  { value: 'ml', text: 'Malayalam' },
  { value: 'ma', text: 'Mandarin' },
  { value: 'mr', text: 'Marathi' },
  { value: 'ne', text: 'Nepali' },
  { value: 'no', text: 'Norwegian' },
  { value: 'ps', text: 'Pashtu' },
  { value: 'fa', text: 'Persian' },
  { value: 'pl', text: 'Polish' },
  { value: 'pt', text: 'Portuguese' },
  { value: 'pa', text: 'Punjabi' },
  { value: 'ro', text: 'Romanian' },
  { value: 'ru', text: 'Russian' },
  { value: 'sr', text: 'Serbian' },
  { value: 'sk', text: 'Slovak' },
  { value: 'sl', text: 'Slovene' },
  { value: 'so', text: 'Somali' },
  { value: 'es', text: 'Spanish' },
  { value: 'sv', text: 'Swedish' },
  { value: 'tl', text: 'Tagalog' },
  { value: 'ta', text: 'Tamil' },
  { value: 'te', text: 'Telugu' },
  { value: 'th', text: 'Thai' },
  { value: 'ti', text: 'Tigrinya' },
  { value: 'tr', text: 'Turkish' },
  { value: 'uk', text: 'Ukrainian' },
  { value: 'ur', text: 'Urdu' },
  { value: 'vi', text: 'Vietnamese' },
];

export interface LanguagesProps {
  onChange: (v: any) => void;
  customStyle?: any;
  sourceLabel?: string;
  destinationLabel?: string;
  sourceName?: string;
  destinationName?: string;
}

const findlanguage = (name: string) => {
  const found = optionLanguages.find((l) => l.text === name);

  return found;
};

export const Languages: React.FunctionComponent<LanguagesProps> = (props) => {
  const { onChange, customStyle, sourceLabel, destinationLabel, sourceName, destinationName } =
    props;

  return (
    <div className="ui form">
      <div style={customStyle} className="field">
        <label>{sourceLabel}</label>
        <Dropdown
          fluid
          selection
          search
          name="sourceLanguage"
          placeholder="Source Language"
          options={optionLanguages}
          defaultValue={findlanguage(sourceName)?.value ?? ''}
          onChange={(e, data: any) => onChange(data)}
        />
      </div>

      <div style={customStyle} className="field">
        <label>{destinationLabel}</label>
        <Dropdown
          fluid
          selection
          search
          name="destinationLanguage"
          placeholder="Destination Language"
          options={optionLanguages}
          defaultValue={findlanguage(destinationName)?.value ?? ''}
          onChange={(e, data: any) => onChange(data)}
        />
      </div>
    </div>
  );
};
