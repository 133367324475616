import * as React from 'react';
import {
  Icon,
  Label,
  Menu,
  Table,
  Segment,
  Button,
  Dimmer,
  Loader,
  Message,
} from 'semantic-ui-react';

interface OrderDetailsComponentProps {
  order: any;
}

export const OrderDetails: React.FunctionComponent<OrderDetailsComponentProps> = (props) => {
  if (!props.order) {
    return null;
  }

  const { customer } = props.order;
  const name = `${customer.primaryContact.firstName} ${customer.primaryContact.lastName}`;

  return (
    <div>
      <Table definition attached="top">
        <Table.Body>
          <Table.Row>
            <Table.Cell width="4">Order</Table.Cell>
            <Table.Cell>{props.order.humanId}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell width="4">Customer</Table.Cell>
            <Table.Cell>{customer.organizationName || name}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </div>
  );
};
