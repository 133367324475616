/* eslint-disable no-undef */
import React, { useState, useRef } from 'react';

import { PDFViewer, Image } from '@react-pdf/renderer';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  Textarea,
  FormControl,
  FormLabel,
  Box,
  Heading,
  Text,
  Flex,
  Alert,
} from '@chakra-ui/react';

import type { ModelTypes } from '@inkcloud/icapi-types';

import { icapi } from '../../bootstrap/feathers';

import Result from '../settings/printables/PrintableTester/Result';

interface ISendModalProps {
  onClose: () => void;
  onReload: () => void;
  rfq: NonNullable<ModelTypes.Rfq> & { requestor: ModelTypes.User };
}

type payLoadType = {
  to: {
    name: string;
    email: string;
  };
  rfq: string;
};

export default function SendEstimateModal(props: ISendModalProps) {
  const { onClose, onReload, rfq } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const toNameRef = useRef<HTMLInputElement>(null);
  const toEmailRef = useRef<HTMLInputElement>(null);
  const ccNameRef = useRef<HTMLInputElement>(null);
  const ccEmailRef = useRef<HTMLInputElement>(null);
  const messageRef = useRef<HTMLTextAreaElement>(null);

  const name = `${rfq?.requestor?.firstName ?? ''} ${rfq?.requestor?.lastName ?? ''}`;
  const email = rfq?.requestor?.email ?? '';
  const handleSubmit = async () => {
    setIsSubmitting(true);
    setErrorMessage('');

    const payload: payLoadType = {
      to: {
        name,
        email,
      },
      rfq: rfq?._id ?? '',
    };

    try {
      await (icapi.service('/rfqs/estimate-sends') as any).create(payload);

      onReload();
      onClose();
    } catch (e) {
      const errMsg = e?.code < 500 ? e?.message : 'Something went wrong. Please try again later.';
      setErrorMessage(errMsg);
    }

    setIsSubmitting(false);
  };

  const hasRequestor = rfq?.requestor?._id;

  return (
    <>
      <Modal size="6xl" isOpen={true} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Send Estimate</ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            {hasRequestor ? (
              <Box p={3} mb={3} borderWidth="1px" borderRadius="md">
                <Flex p={3} justifyContent="space-between">
                  <Box>
                    <Heading color="gray.500" textTransform="uppercase" as="h4" size="sm">
                      Name
                    </Heading>
                    <Text>
                      {rfq?.requestor?.firstName ?? ''} {rfq?.requestor?.lastName ?? ''}
                    </Text>
                  </Box>
                  <Box>
                    <Heading color="gray.500" textTransform="uppercase" as="h4" size="sm">
                      Email
                    </Heading>
                    <Text>
                      {rfq?.requestor?.email ? (
                        <a href={`mailto:${rfq?.requestor?.email}`}>{rfq?.requestor?.email}</a>
                      ) : (
                        ''
                      )}
                    </Text>
                  </Box>
                </Flex>
              </Box>
            ) : (
              <Alert status="warning" mb={3}>
                To send Estimate, set a requestor
              </Alert>
            )}
            {/* <Box p={3} mb={3} borderWidth="1px" borderRadius="md">
              <Heading size="xs">To</Heading>
              <FormControl mb={3}>
                <FormLabel>Name</FormLabel>
                <Input ref={toNameRef} defaultValue={contact?.name ?? ''} />
              </FormControl>
              <FormControl>
                <FormLabel>Email address</FormLabel>
                <Input type="email" ref={toEmailRef} defaultValue={contact?.email ?? ''} />
              </FormControl>
            </Box>
            <Box p={3} mb={3} borderWidth="1px" borderRadius="md">
              <Heading size="xs">CC</Heading>
              <FormControl mb={3}>
                <FormLabel>Name</FormLabel>
                <Input ref={ccNameRef} />
              </FormControl>
              <FormControl>
                <FormLabel>Email address</FormLabel>
                <Input type="email" ref={ccEmailRef} />
              </FormControl>
            </Box> */}
            <Result
              documentType="estimate"
              source=""
              styles={{}}
              humanId={String(rfq?.humanId) ?? ''}
            />

            {errorMessage && (
              <Alert status="error" mb={3}>
                {errorMessage}
              </Alert>
            )}
          </ModalBody>

          <ModalFooter>
            <Button mr={3} onClick={onClose}>
              Close
            </Button>
            <Button
              colorScheme="purple"
              isDisabled={isSubmitting || !hasRequestor}
              isLoading={isSubmitting}
              onClick={handleSubmit}
            >
              Send
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
