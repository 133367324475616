import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { FormattedDate, FormattedTime } from 'react-intl';
import { Comment, Icon, Segment, Table, Button, Divider, Menu } from 'semantic-ui-react';
import { Feathers } from '../../../bootstrap/feathers/feathers';
import { PreLoader, PageHeaderRow } from '@inkcloud/components';

interface DetailProps {}

const { useState, useEffect } = React;

export const Detail: React.FunctionComponent<DetailProps & RouteComponentProps<any>> = (props) => {
  const {
    match: {
      params: { id },
    },
    location,
    history,
  } = props;

  const [transcript, setTranscript] = useState(null);

  useEffect(() => {
    const loadTranscript = async () => {
      const result = await Feathers.service('chat-transcripts').get(id, { query: {} });

      setTranscript(result);
    };

    loadTranscript();
  }, []);

  if (!transcript) {
    return <PreLoader />;
  }

  return (
    <>
      <PageHeaderRow header={`Transcript`} subheader="Live Chat" subheaderId={''}>
        <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
      </PageHeaderRow>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Customer/Visitor Name</Table.HeaderCell>
            <Table.HeaderCell>Visitor Email</Table.HeaderCell>
            <Table.HeaderCell>Agent Name</Table.HeaderCell>
            <Table.HeaderCell>Agent Email</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>{transcript.visitorName}</Table.Cell>
            <Table.Cell>{transcript.visitorEmail}</Table.Cell>
            <Table.Cell>Flex CSR Agent</Table.Cell>
            <Table.Cell>flex@inkcloud9.com</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

      <Menu attached="top" pointing secondary>
        <Menu.Item name="Conversation" active={true} />
      </Menu>
      <Segment attached="bottom" padded>
        <Comment.Group style={{ maxWidth: '100%' }}>
          {(transcript as any).chatMessages.map((c: any, index: number) => (
            <Comment key={index}>
              <div className={'avatar'}>
                <Icon name={c.isVisitorMsg ? 'user circle' : 'user circle outline'} size="large" />
              </div>
              <Comment.Content>
                <Comment.Author as="a">{c.isVisitorMsg ? c.username : 'CSR Agent'}</Comment.Author>
                <Comment.Metadata>
                  <FormattedDate value={c.sentAt} />
                  at <FormattedTime value={c.sentAt} />
                </Comment.Metadata>
                <Comment.Text>{c.text}</Comment.Text>
                {transcript.chatMessages.length - 1 !== index && <Divider />}
              </Comment.Content>
            </Comment>
          ))}
        </Comment.Group>
      </Segment>
    </>
  );
};
