import React, { useEffect, useState } from 'react';
import { FormattedDate } from 'react-intl';

import Cleave from 'cleave.js/react';
// import { Table } from 'semantic-ui-react';

import {
  Checkbox,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  Button,
  Alert,
  Box,
} from '@chakra-ui/react';

import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';

import type { ModelTypes } from '@inkcloud/icapi-types';

import Feathers from '../../bootstrap/feathers';
import { InputWrapper, currencySymbol } from '../../common';

interface AppliedPaymentProps {
  payments: any;
  onReload: () => void;
}

type PaymentType = Array<ModelTypes.PaymentAppliedRecords & { checked?: boolean }>;

export const AppliedPayment: React.FunctionComponent<AppliedPaymentProps> = (props) => {
  const { payments, onReload } = props;

  const tenant = useSelector(
    ({
      globals,
    }: {
      globals: { tenant: { internationalization: { currency: string; locale: string } } };
    }) => globals.tenant.internationalization
  );
  const [selectedPayment, setSelectedPayment] = useState<PaymentType>();

  useEffect(() => {
    setSelectedPayment(payments);
  }, [payments]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // eslint-disable-next-line no-undef
  const handleSelect = (id: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;

    setSelectedPayment(selectedPayment?.map((p) => (p._id === id ? { ...p, checked } : p)));
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    setErrorMessage('');
    try {
      const res: any = selectedPayment?.map(
        async (sp) =>
          sp?.checked &&
          (sp?.amount as number) > 0 &&
          Feathers.service('payments/applied').patch(sp?._id, sp)
      );

      await Promise.all(res);

      onReload();
    } catch (e) {
      const errMsg = e?.code < 500 ? e?.message : 'Something went wrong, please try again later.';
      setErrorMessage(errMsg);
    }
    setIsSubmitting(false);
  };

  const partial = payments?.reduce((total: number, p: any) => {
    const direction = p?.payment?.type === 'CHARGE' ? 1 : -1;
    return total + p?.amount * direction;
  }, 0.0);

  const isAllChecked = selectedPayment?.every((p) => p?.checked);

  return (
    <>
      <Table variant="simple" size="sm">
        <Thead>
          <Tr>
            {/* <Th>
              <Checkbox
                isChecked={isAllChecked}
                onChange={(e) =>
                  setSelectedPayment(
                    selectedPayment?.map((p) => ({ ...p, checked: e.target.checked }))
                  )
                }
              ></Checkbox>
            </Th> */}
            <Th>Date</Th>
            <Th>Charage</Th>
            <Th>Description</Th>
            <Th>Payment</Th>
            <Th isNumeric>Amount</Th>
          </Tr>
        </Thead>
        <Tbody>
          {selectedPayment?.map((p: any, index: number) => (
            <Tr key={index}>
              {/* <Td>
                <Checkbox
                  onChange={handleSelect(p._id)}
                  defaultChecked={p?.checked}
                  isChecked={p?.checked}
                ></Checkbox>
              </Td> */}
              <Td>
                <FormattedDate value={p?.payment?.createdAt} />
              </Td>

              <Td>{p?.payment?.type}</Td>
              <Td>{p?.payment?.description}</Td>
              <Td>{p?.payment?._id}</Td>
              <Td isNumeric>
                {p?.payment?.type === 'REFUND' && '('}
                {p?.checked ? (
                  <InputWrapper>
                    <Cleave
                      style={{ textAlign: 'right' }}
                      className={'form-control-cleave'}
                      options={{
                        numeral: true,
                        rawValueTrimPrefix: true,
                        numeralPositiveOnly: true,
                        numeralThousandsGroupStyle: 'thousand',
                        prefix: currencySymbol(tenant?.currency, tenant?.locale),
                      }}
                      value={p?.amount || 0.0}
                      onChange={(e) =>
                        setSelectedPayment(
                          selectedPayment?.map((payment) =>
                            payment._id === p._id
                              ? {
                                  ...payment,
                                  checked: true,
                                  amount: Number(e.target.rawValue) || 0,
                                }
                              : payment
                          )
                        )
                      }
                    />
                  </InputWrapper>
                ) : (
                  <NumberFormat
                    value={p?.amount || 0.0}
                    decimalScale={2}
                    displayType={'text'}
                    fixedDecimalScale={true}
                    thousandSeparator={true}
                    prefix={'$'}
                  />
                )}
                {p?.payment?.type === 'REFUND' && ')'}
              </Td>
            </Tr>
          ))}
        </Tbody>
        <Tfoot>
          <Tr>
            <Th colSpan={4}>Total</Th>
            <Th isNumeric>
              <NumberFormat
                value={partial || 0.0}
                decimalScale={2}
                displayType={'text'}
                fixedDecimalScale={true}
                thousandSeparator={true}
                prefix={'$'}
              />
            </Th>
          </Tr>
        </Tfoot>
      </Table>
      {/* {errorMessage && (
        <Alert status="error" my={3}>
          {errorMessage}
        </Alert>
      )}

      <Box textAlign="right">
        <Button
          colorScheme="blue"
          onClick={handleSubmit}
          isLoading={isSubmitting}
          disabled={!selectedPayment?.length}
        >
          Submit
        </Button>
      </Box> */}
    </>
  );
};
