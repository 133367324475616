import * as React from 'react';
import { Form, Input, Checkbox, Dropdown, Button } from 'semantic-ui-react';
import Feathers from './../../bootstrap/feathers';
import { FeathersDropdown } from '@inkcloud/components';

interface SettingFormProps {
  initialValues: any;
  history: any;
}

const { useState } = React;

export const SettingForm: React.FunctionComponent<SettingFormProps> = (props) => {
  const { initialValues, history } = props;

  const [value, setValue] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (v: any) => {
    setValue({ ...value, ...v });
  };

  const handleSubmit = async (e: any) => {
    setIsLoading(true);

    if ((value as any).salesPerson === '') {
      (value as any).salesPerson = null;
    }

    if ((value as any).priceListId === '') {
      (value as any).priceListId = null;
    }

    await Feathers.service('customers').patch(initialValues._id, value, { query: {} });

    history.push(`/customers/${initialValues._id}`);
  };

  return (
    <Form loading={isLoading}>
      <Form.Field>
        <label>Resale Permit</label>
        <Input
          defaultValue={initialValues && initialValues.resalePermitNumber}
          onChange={(e, data) => handleChange({ resalePermitNumber: data.value })}
        />
      </Form.Field>
      <Form.Group widths={'equal'}>
        {/* <Form.Field>
          <Checkbox defaultChecked={initialValues.isAllowedCOD} label='Allow COD Payment' onClick={(e, data) => handleChange({ isAllowedCOD: data.checked })} />
        </Form.Field> */}
        <Form.Field>
          <Checkbox
            defaultChecked={initialValues.isTaxExemptApproved}
            label="Sales Tax Exempt"
            onClick={(e, data) => handleChange({ isTaxExemptApproved: data.checked })}
          />
        </Form.Field>
        <Form.Field>
          <Checkbox
            defaultChecked={initialValues.isDisabled}
            label="Disable Customer"
            onClick={(e, data) => handleChange({ isDisabled: data.checked })}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group widths={'equal'}>
        <Form.Field>
          <label>Price List</label>
          <FeathersDropdown
            search
            selection
            lazyLoad
            fluid
            clearable
            serviceName="products-legacy/price-lists"
            query={{ $sort: { name: 1 }, $np: 1 }}
            resultMapper={(r) => ({ value: r._id, text: r.name })}
            defaultValue={initialValues.priceListId}
            onChange={(e, data) => handleChange({ priceListId: data.value as any })}
          />
        </Form.Field>
        <Form.Field>
          <label>Sales Person</label>
          <FeathersDropdown
            search
            selection
            lazyLoad
            fluid
            clearable
            serviceName="sales-people"
            query={{ $sort: { firstName: 1 }, $np: 1 }}
            resultMapper={(r) => ({ value: r._id, text: `${r.firstName} ${r.lastName}` })}
            defaultValue={initialValues.salesPerson}
            onChange={(e, data) => handleChange({ salesPerson: data.value as any })}
          />
        </Form.Field>
      </Form.Group>

      <Button primary content="Save" onClick={handleSubmit} />
    </Form>
  );
};
