import * as React from 'react';
// import { Button, Header, Form, Input, Image, Message, Modal } from 'semantic-ui-react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Center,
  Spinner,
  Alert,
} from '@chakra-ui/react';
import { QRCodeSVG } from 'qrcode.react';

export interface LogInAsModalProps {
  onClose: any;
  isLoading: boolean;
  url: string;
  errorMessage?: string;
  hideLoginButton?: boolean;
  showQRCode?: boolean;
  label?: string;
}

export interface LogInAsModalState {}

export default function LogInAsModal(props) {
  const {
    url,
    isLoading,
    label = 'Login As Customer',
    showQRCode = false,
    hideLoginButton = false,
    errorMessage,
    onClose,
  } = props;

  return (
    <Modal isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{label}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Center>
            {isLoading ? (
              <Spinner />
            ) : (
              showQRCode && (
                <QRCodeSVG
                  value={url}
                  fgColor="#000"
                  imageSettings={{
                    src: 'https://inkcloud9.com/static/ic-logo-clean-sm-dd7562a05ae8438f32f09ff676a0b9c5.png',
                    excavate: true,
                    width: 30,
                    height: 18,
                  }}
                />
              )
            )}
          </Center>
          {errorMessage && (
            <Alert my={4} status="error">
              {errorMessage}
            </Alert>
          )}
        </ModalBody>

        <ModalFooter>
          <Button onClick={onClose}>Close</Button>
          {!hideLoginButton && (
            <Button
              isDisabled={isLoading}
              colorScheme="blue"
              ml={3}
              onClick={(e) => window.open(url, '_blank')}
            >
              Login
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
