import * as React from 'react';
import { Switch, Route } from 'react-router-dom';

import { AdjusterComponent } from './IndexComponent';

const routes = (props) => (
  <Switch>
    <Route exact path="/shipping/adjuster/:orderId" component={AdjusterComponent} />
  </Switch>
);

export default routes;
