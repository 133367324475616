import * as React from 'react';
import { Button, Form, Input, Dropdown } from 'semantic-ui-react';
import { Formik, Form as FormikForm, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { SemanticField } from '../../../common/Form';
import { usStateProvinces } from './../../../common';
import Feathers from './../../../bootstrap/feathers';
import { EntityDropdownWrapper2 } from '../../EntityDropdown';

interface InventoryProductFormProps {
  initialValues: any;
  handleSubmit: Function;
  isSubmitting?: boolean;
}

const customersDropdown = EntityDropdownWrapper2(
  '/products-legacy/categories',
  {
    query: {
      // $np: 1,
      $sort: { name: 1 },
      $select: ['_id', 'name'],
    },
  },
  {
    resultMap: (row) => {
      return { value: row._id, text: row.name };
    },
  }
);

export const InventoryProductForm: React.FC<InventoryProductFormProps> = (props) => {
  const InventoryProductSchema = Yup.object().shape({
    category: Yup.string().required(),
    staticName: Yup.string().required(),
    key: Yup.string().required('Required'),
    weight: Yup.number().typeError('Must be number only.'),
    // .test('is-not-used', `Key is already in use`, function(value) {
    //   if (props.initialValues && (props.initialValues._id && (props.initialValues.key === value))) {
    //     return Promise.resolve(true);
    //   }

    //   return Feathers.service<any>('/products-legacy/')
    //     .find({query: {key: value }})
    //     .then((results: any) => results.data.length === 0)
    // }),
  });

  return (
    <Formik
      initialValues={props.initialValues}
      validationSchema={InventoryProductSchema}
      onSubmit={(values, { setSubmitting }) => {
        props.handleSubmit({
          ...values,
          inventory: true,
          weightBasis: values.weight,
          weightUnit: 1,
        });
      }}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
          setFieldValue,
          setFieldTouched,
        } = props;

        return (
          <FormikForm className={`ui form ${isSubmitting && 'loading'}`}>
            <Form.Field>
              <SemanticField name={'staticName'} label="Name" component={Form.Input} />
              <ErrorMessage component="div" name="staticName" />
            </Form.Field>

            <Form.Field>
              <SemanticField name={'key'} label="Key" component={Form.Input} />
              <ErrorMessage component="div" name="key" />
            </Form.Field>
            <div className="field">
              <label>Category</label>
              <SemanticField
                name={'category'}
                component={customersDropdown}
                fluid
                selection
                label="Category"
                placeholder="Category..."
              />
            </div>
            <Form.Field>
              <SemanticField name={'weight'} label="Weight" component={Form.Input} />
              <ErrorMessage component="div" name="weight" />
            </Form.Field>

            <Button primary type="submit" disabled={isSubmitting}>
              Save
            </Button>
          </FormikForm>
        );
      }}
    </Formik>
  );
};
