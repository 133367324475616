// import * as React from 'react';
// import { Form, Segment, Modal, Button, Message } from 'semantic-ui-react';
// import { AgGridReact } from 'ag-grid-react';
// import { ColumnApi, GridApi } from 'ag-grid';

// import { Link, RouteComponentProps } from 'react-router-dom';
// import { PageHeaderRow } from '@inkcloud/components';
// import { ReportInputs } from '../ReportInputForm';
// import Feathers from '../../../bootstrap/feathers';
// import { ExportModal } from '../ExportModal';

// const { useState } = React;

// interface OrdersFlattenedReportProps extends RouteComponentProps<any> {}

// function loadData(values, isExport = false) {
//   const params: any = { query: {} };
//   // if (isExport) {
//   //   params.query.$export = 1;
//   // }
//   // return Feathers.service('/reports/v2/microsite/orders-flattened').create({
//   //   ...values
//   // }, params)

//   return Feathers.service('/reports/v3/results').create(
//     {
//       query: {
//         start: values.start,
//         end: values.end,
//         product: values.products,
//       },
//       microsite: values.microsites[0],
//       reportKey: 'ms-orders-flattened',
//       reportName: 'MS Orders Flattened',
//     },
//     params
//   );
// }

// let containerStyle = {
//   height: 500,
//   // /width: 500
// };

// // .concat([{ field: 'quantity', headerName: 'Quantity', pinned: 'right', cellStyle: rightAlignStyle}] as any)
// // .concat([{ field: 'price', headerName: 'Price', pinned: 'right', editable: true, minWidth: 100, cellStyle: {...rightAlignStyle, 'background': '#f8ffff' }}] as any);

// export const OrdersFlattenedReport: React.FunctionComponent<OrdersFlattenedReportProps> = (
//   props
// ) => {
//   const [microsites, setMicrosites] = useState([]);
//   const [isLoading, setIsLoading] = useState(false);
//   const [micrositeList, setMicrositeLists] = useState(null);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [exportUrl, setExportUrl] = useState(null);

//   const [values, setValues] = useState({ start: null, end: null, microsites: [] });

//   const [errorMessage, setErrorMessage] = useState('');

//   let columnApi;
//   let gridApi;

//   const handleSubmit = async () => {
//     try {
//       setIsLoading(true);
//       const result = await loadData(values, false);
//       // setData(result);
//       setIsLoading(false);
//       props.history.push('/reports/results');
//     } catch (e) {
//       setIsLoading(false);
//       e.code && e.code < 500
//         ? setErrorMessage(e.message)
//         : setErrorMessage('We are experiencing technical difficulties. Please try again');
//     }
//   };

//   React.useEffect(() => {
//     const loadMicrosites = async () => {
//       const list = (await Feathers.service('/microsites').find({
//         query: { $sort: { name: 1 }, $np: 1 },
//       })) as any;
//       setMicrositeLists(list.map((m) => ({ value: m._id, text: m.name })));
//     };

//     loadMicrosites();
//   }, []);

//   return (
//     <div>
//       <PageHeaderRow header="Orders Flattened" subheader="Reports"></PageHeaderRow>
//       <ReportInputs
//         onSubmit={handleSubmit}
//         showOperations={false}
//         values={values}
//         onChange={(v) => setValues(v)}
//         micrositeList={micrositeList}
//         showProducts={true}
//       />
//       <Segment loading={isLoading}>
//         {!errorMessage && <Message info content="Submit Form Above to view report data" />}
//         {errorMessage && <Message error content={errorMessage} />}
//       </Segment>
//     </div>
//   );
// };

import React, { useState } from 'react';
import { Box, Button } from '@chakra-ui/react';

import { useHistory } from 'react-router-dom';
import { PageHeaderRow } from '@inkcloud/components';
import Feathers from '../../../bootstrap/feathers';
import FilterForm from '../FilterForm2';

function loadData(values) {
  const params: any = { query: {} };

  const product = values?.products?.map((p) => p.value);

  return Feathers.service('/reports/v3/results').create(
    {
      query: {
        start: values.start,
        end: values.end,
        product,
      },
      microsite: values?.microsites?.value,
      reportKey: 'ms-orders-flattened',
      reportName: 'MS Orders Flattened',
    },
    params
  );
}

export default function OrdersFlattenedReport() {
  const history = useHistory();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [values, setValues] = useState({ start: null, end: null, microsites: [] });

  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async () => {
    setErrorMessage('');
    try {
      setIsSubmitting(true);
      const res = await loadData(values);

      history.push('/reports/results');
    } catch (e) {
      const errMsg =
        e?.code < 500 ? e?.message : 'We are experiencing technical difficulties. Please try again';
      setErrorMessage(errMsg);
    }
    setIsSubmitting(false);
  };

  return (
    <div>
      <PageHeaderRow header="Orders Flattened" subheader="Reports">
        <Button onClick={() => history.goBack()}>Back</Button>
      </PageHeaderRow>

      <Box p={3} borderWidth={1} borderRadius="md" bg="white">
        <FilterForm
          values={values}
          isSubmitting={isSubmitting}
          hasMicrosites={true}
          hasProducts={true}
          isMultiProducts={true}
          errorMessage={errorMessage}
          onChange={(v) => {
            setErrorMessage('');
            setValues(v);
          }}
          onSubmit={handleSubmit}
        />
      </Box>
    </div>
  );
}
