import * as React from 'react';
import {
  Accordion,
  Button,
  Label,
  Dropdown,
  Checkbox,
  Header,
  Icon,
  Image,
  Grid,
  Modal,
  Popup,
  Form,
  Segment,
  Table,
  TableRow,
  Message,
} from 'semantic-ui-react';

interface ModalStatusProps {
  // id: string;
  // status: string;
  // isOpen: boolean;
  humanId: string | null;
  statusValue: string | null;
  errorMessage?: string;
  onClose: () => void;
  onChangeStatus: (v: any) => void;
}
const statusOptions = [
  { text: 'Awaiting Artwork', value: 'awaiting-artwork' },
  { text: 'Canceled', value: 'canceled' },
  { text: 'Pending Dispatch', value: 'pending-dispatch' },
  { text: 'Queued At Press', value: 'queued-at-press' },
  { text: 'Received At Press', value: 'received-at-press' },
  { text: 'Printing Complete', value: 'print-complete' },
  { text: 'Printing Aborted', value: 'print-aborted' },
  { text: 'Cutting Complete', value: 'cutting-complete' },
  { text: 'In Progress', value: 'in-progress' },
  { text: 'Status Draft', value: 'STATUS_DRAFT' },
  { text: 'Status Final', value: 'STATUS_FINAL' },
  { text: 'Status Dispatched', value: 'STATUS_DISPATCHED' },
];

export const ModalStatus: React.FunctionComponent<ModalStatusProps> = (props) => {
  const { statusValue, onClose, onChangeStatus, humanId, errorMessage } = props;
  return (
    <Modal
      size={'tiny'}
      open
      // trigger={<Button size={'tiny'} icon={'exchange'} onClick={() => onChangeModal(id,status)} />}
      // onOpen={() => onChangeModal(id, status)}
      onClose={onClose}
      closeIcon
    >
      <Modal.Header>Change Status {humanId ? `- ${humanId}` : ''}</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field>
            <Dropdown
              selection
              fluid
              options={statusOptions}
              defaultValue={statusValue}
              onChange={(e, data) => onChangeStatus(data.value)}
            />
          </Form.Field>
        </Form>
        {errorMessage && <Message error content={errorMessage} />}
      </Modal.Content>
    </Modal>
  );
};
