import * as React from 'react';
import { Switch, Route } from 'react-router-dom';

import AddressBook from './address-book';
import DashboardUsers from './dashboard-users';
import DesignTemplates from './design-templates';
import SalesPeople from './sales-people';
import TenantSettings from './tenant-settings';
import Translators from './translators';
import NotificationLog from './notification-log';
import EmailParseLog from './email-parse-log';
import UserRoles from './user-roles';
import ArtworkTransporter from './artwork-transporter';

const routes = (props) => (
  <Switch>
    <Route path="/admin/address-book" component={AddressBook} />
    <Route path="/admin/dashboard-users" component={DashboardUsers} />

    <Route path="/admin/user-roles" component={UserRoles} />

    <Route path="/admin/design-templates" component={DesignTemplates} />

    <Route path="/admin/sales-people" component={SalesPeople} />
    <Route path="/admin/tenant-settings" component={TenantSettings} />
    <Route path="/admin/translators" component={Translators} />
    <Route path="/admin/notification-log" component={NotificationLog} />
    <Route path="/admin/email-parse/log" component={EmailParseLog} />
    <Route path="/admin/artwork-transporter" component={ArtworkTransporter} />
  </Switch>
);

export default routes;
