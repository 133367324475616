import * as React from 'react';
// import AsyncSelect from 'react-select/async';
import { Badge, Box, Flex, Text, Spacer, Tag } from '@chakra-ui/react';

import { AsyncSelect } from 'chakra-react-select';

export interface ISelectProps {
  value: any;
  onChange: (value: any) => void;
  productLoad: (inputValue: string) => Promise<{ label: string; value: string }[]>;
  isDisabled?: boolean;
}

const CustomOption = ({
  data,
  innerProps,
  isDisabled,
  getValue,
  selectOption,
  options,
  selectProps,
}) => {
  if (isDisabled) {
    return null;
  }

  let badgeLabel = data.isStock ? `${data.inventoryCount} In Stock` : 'On-Demand';
  let badgeColor = 'green';
  if (data.isStock && data.inventoryCount < 1) {
    badgeLabel = 'Out Of Stock';
    badgeColor = 'red';
  }

  return (
    <Flex {...innerProps} p={4} verticalAlign={'top'}>
      <Box>
        <Text as={'strong'} color={'gray.700'}>
          {data.label}{' '}
        </Text>
        <br />
      </Box>
      <Spacer />
      <Box>
        <Badge colorScheme={badgeColor}>{badgeLabel}</Badge>
      </Box>
    </Flex>
  );
};

export function ProductSelect(props: ISelectProps) {
  const { value, productLoad, onChange, isDisabled = false } = props;

  return (
    <AsyncSelect
      size={'sm'}
      useBasicStyles
      onChange={(v) => onChange(v)}
      placeholder="Search products..."
      loadOptions={productLoad}
      isDisabled={isDisabled}
      isClearable={true}
      value={value}
      noOptionsMessage={(v) => (!v.inputValue ? 'Start typing a SKU to search...' : 'No options')}
      // defaultValue={defaultValue}
      components={{
        Option: CustomOption,
      }}
      styles={{
        container: (provided, state) => ({
          ...provided,
          // border: '1px solid red',
          flexGrow: 2,
          // border: 'none',
          width: '100%',
          // flexGrow: 1,
          // borderRight: '1px solid #eaeaea',
          padding: 4,
        }),
        valueContainer: (provided, state) => ({
          ...provided,
          // border: '1px solid none',
        }),
        control: (provided, state) => ({
          ...provided,
          border: '1px solid #eaeaea',
        }),
      }}
    />
  );
}
