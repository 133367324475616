// import * as React from 'react';
// import { Accordion, Button, Checkbox, Header, Icon, Grid, Label, Popup, Segment, Table } from 'semantic-ui-react';
// import { Link } from 'react-router-dom';
// import { FormattedDate, FormattedNumber, FormattedRelative } from 'react-intl';
// import { IndexWrapper, IndexWrappedProps } from './../../../bootstrap/IndexWrapper';
// import { ListViewPagination, ListFilterWarning } from './../../../common';
// import FilterForm from './FilterForm';

// import { optionLanguages } from './../../../common/translations/languages'

// export const filterMapper = (filterIn) => {
//   const filterOut = Object.assign({}, filterIn);
//   filterOut.firstName = { $LIKE: filterOut.firstName };
//   filterOut.lastName = { $LIKE: filterOut.lastName };

//   if (filterOut.source) {
//     filterOut['languages.source'] = { $in: filterOut.source };
//     delete filterOut.source;
//   }

//   if (filterOut.destinations) {
//     filterOut['languages.destinations'] = {
//       $elemMatch: {
//         $in: filterOut.destinations,
//       }
//     }
//     delete filterOut.destinations;
//   }

//   return filterOut;
// }

// export function Container(props: IndexWrappedProps) {
//   if (!props.queryResult) {
//     return null;
//   }
//   const { data, skip, limit, total } = props.queryResult;
//   return (
//     <div>
//       <Header as='h1'>Translators</Header>
//       <Header attached={'top'} as='h5'>
//         Filter
//       </Header>
//       <Segment attached secondary>
//         <FilterForm
//           onSubmit={props.handleFilterSubmit}
//           handleClearFilter={props.handleClearFilter}
//           isFiltered={props.isFiltered}
//         />
//       </Segment>
//       <ListFilterWarning isFiltered={props.isFiltered} />
//       <Segment attached loading={props.isLoading}>
//         <ListViewPagination.Top
//           label={'New Translator'}
//           link={'/admin/translators/add'}
//           {...props}
//         />
//         <Table size={'small'}>
//           <Table.Header>
//             <Table.Row>
//               <Table.HeaderCell>
//                 <Checkbox
//                   onChange={props.handleSelectAllClick}
//                 />
//               </Table.HeaderCell>
//               <Table.HeaderCell>Created</Table.HeaderCell>
//               <Table.HeaderCell>Name</Table.HeaderCell>
//               <Table.HeaderCell>Email</Table.HeaderCell>
//               <Table.HeaderCell>Languages</Table.HeaderCell>
//               <Table.HeaderCell>Status</Table.HeaderCell>
//               <Table.HeaderCell></Table.HeaderCell>
//             </Table.Row>
//           </Table.Header>
//           <Table.Body>
//             {data?.map((i, index) => (
//               <Table.Row key={`${i._id}${index}`}>
//                 <Table.Cell>
//                   <Checkbox
//                     value={i._id}
//                     checked={props.selected[i._id] || false}
//                     onChange={props.handleSemanticCheckboxClick}
//                   />
//                 </Table.Cell>
//                 <Table.Cell>
//                   <FormattedDate value={i.createdAt} />
//                 </Table.Cell>
//                 <Table.Cell>
//                   {`${i.firstName} ${i.lastName}`}
//                 </Table.Cell>
//                 <Table.Cell>
//                   {i.email}
//                 </Table.Cell>
//                 <Table.Cell>
//                   {
//                     i.languages?.map(l => (
//                       <div key={l.source._id}>
//                         <Label size={'mini'} >{optionLanguages?.find(o => o.value === l.source)?.text ?? ''}</Label>
//                         <Icon name={'long arrow alternate right'} />{l.destinations?.map(d => <Label size={'mini'} key={d._id}>{optionLanguages?.find(o => o.value === d)?.text ?? ''}</Label>) ?? ''}
//                       </div>
//                     )) ?? ''
//                   }
//                 </Table.Cell>
//                 <Table.Cell>
//                   <Label basic color={'blue'} size={'small'}>{i.status}</Label>
//                   {i.status === 'invited' &&
//                     <Popup
//                       trigger={<Button icon={'linkify'} size={'mini'} />}
//                       hoverable
//                       content={`https://translateportal.inkcloud9.com/onboard/${i.invitationCode}`}
//                     />
//                   }
//                 </Table.Cell>
//                 <Table.Cell>
//                   <Link to={'/admin/translators/' + i._id}>
//                     <Button size={'mini'}>Edit</Button>
//                   </Link>
//                 </Table.Cell>
//               </Table.Row>
//             )) ?? null}
//           </Table.Body>
//         </Table>
//         <ListViewPagination.Bottom
//           label={'Delete'}
//           {...props}
//         />
//       </Segment>
//     </div>
//   );
// }

// export default IndexWrapper(Container, 'translators', {
//   filterMapper,
//   query: {
//     // $populate: ['languages.source', 'languages.destinations'],
//     $sort: { createdAt: -1 },
//   },
// });

import * as React from 'react';
import { Button, Form, Icon, Popup, Label } from 'semantic-ui-react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { FormattedDate, FormattedTime } from 'react-intl';

import { useList } from '@inkcloud/components';

import { optionLanguages } from './../../../common/translations/languages';

import { ListPage, basicQueryMap } from '@inkcloud/components';
import { getFilterDefinition } from './FilterForm2';

interface ListProps {}

const filterQueryMap = (values) => {
  let filterRows = basicQueryMap(values);

  filterRows = {
    ...filterRows,
  };

  return filterRows;
};

const serviceName = 'translators';
const pathPrefix = '/admin/translators';

export const List: React.FunctionComponent<ListProps & RouteComponentProps<any>> = (props) => {
  const {
    match: { params },
    location,
    history,
  } = props;

  const useListValues = useList({
    feathers: [
      'find',
      serviceName,
      [
        {
          query: {
            $sort: { createdAt: -1 },
          },
        },
      ],
    ],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  const headerActions = (
    <>
      <Link to={`${pathPrefix}/add`}>
        <Button size="mini" primary content="New" />
      </Link>
    </>
  );

  const tableDefintion = [
    {
      key: 'createdAt',
      label: 'Date',
      sortable: true,
      cell: (v, r) => (
        <Popup
          size="mini"
          content={<FormattedTime value={r.createdAt} />}
          trigger={
            <div>
              <FormattedDate value={r.createdAt} />
            </div>
          }
        />
      ),
    },
    {
      key: '',
      label: 'Name',
      cell: (v, r) => (
        <Link to={`/translator/${r?._id}`}>
          {r.firstName} {r.lastName}
        </Link>
      ),
    },
    {
      key: 'email',
      label: 'Email',
      cell: (v, r) => v,
    },
    {
      key: '',
      label: 'Languages',
      cell: (v, r: any) =>
        r.languages?.map((l) => (
          <div key={l.source._id}>
            <Label size={'mini'}>
              {optionLanguages?.find((o) => o.value === l.source)?.text ?? ''}
            </Label>
            <Icon name={'long arrow alternate right'} />
            {l.destinations?.map((d) => (
              <Label size={'mini'} key={d._id}>
                {optionLanguages?.find((o) => o.value === d)?.text ?? ''}
              </Label>
            )) ?? ''}
          </div>
        )),
    },
    {
      key: 'status',
      label: 'Status',
      cell: (v, r) => (
        <>
          <Label basic color={'blue'} size={'small'}>
            {r.status}
          </Label>
          {r.status === 'invited' && (
            <Popup
              trigger={<Button icon={'linkify'} size={'mini'} />}
              hoverable
              content={`https://translateportal.inkcloud9.com/onboard/${r.invitationCode}`}
            />
          )}
        </>
      ),
    },
    {
      key: '',
      label: '',
      cell: (v, r) => (
        <>
          <Popup
            size="mini"
            content="View Details"
            align
            trigger={
              <Link to={`${pathPrefix}/${r._id}`}>
                <Button size="mini" icon="edit" />
              </Link>
            }
          />
        </>
      ),
      textAlign: 'right',
    },
  ];

  return (
    <>
      <ListPage
        header="Translators"
        subheader="List"
        useListValues={useListValues}
        history={history}
        pathPrefix={pathPrefix}
        tableDefintion={tableDefintion}
        withCheckboxes={true}
        headerActions={headerActions}
        getFilterDefinition={getFilterDefinition}
      />
    </>
  );
};
