import React, { useState, useId } from 'react';
import { AddIcon, QuestionIcon, MinusIcon } from '@chakra-ui/icons';
import type { ModelTypes } from '@inkcloud/icapi-types';
import { Select } from 'chakra-react-select';

import {
  IconButton,
  InputRightElement,
  InputGroup,
  Table as ChakraTable,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  Box,
  TableContainer,
  Input,
  Button,
  Tooltip,
  useToast,
  Flex,
  Icon,
} from '@chakra-ui/react';

import { BiNotepad, BiChat, BiMailSend } from 'react-icons/bi';

import {
  // Column,
  // Table,
  ColumnDef,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  flexRender,
  RowData,
  Cell,
  Row,
} from '@tanstack/react-table';

import { NumberInput } from '../../sales-orders/SalesOrder/LineItemGrid/Inputs';

import VendorResponseModal from '../SupplierResponseModal';

import { SendModal } from '../SendModal';

import type { EstimateType, EstimateItemType, EstimateAdditionalType } from '../common/types';

type optionsType = { label: string; value: string }[];

declare module '@tanstack/react-table' {
  interface TableMeta<TData extends RowData> {
    // updatePaperCostM: (row: Row<EstimateRow>, id: string, value: number) => void
    // updatePaperCostM2: (vendorId: string, itemId: string, quantity: number, componentId: string, cost: number) => void,
    updateData: (rowIndex: number, columnId: string, value: unknown) => void;
    updateCostField: (row: Row<EstimateRow>, id: string, value: number) => void;
    updateAdditionalCostsDescription: (row: Row<EstimateRow>, id: string, value: string) => void;
    updateAdditionalCostsAmount: (row: Row<EstimateRow>, id: string, value: string) => void;
    addAdditionalCosts: (
      row: Row<EstimateRow>,
      id: string,
      value: string,
      includedInMarkup: boolean
    ) => void;
    removeAdditionalCosts: (row: Row<EstimateRow>, id: string, value: string) => void;
  }
}

type Response = Exclude<ModelTypes.Rfq['responses'], undefined>[number];
type Item = Exclude<Response['items'], undefined>[number];

const editableFields = [
  'Markup %',
  'Markup $',
  'Tax Rate',
  'Recommended Sell',
  'Min Sell',
  'Minimum Sell',
];

// const round4th = (v: any) => Math.round(v * 1000) / 1000

const round4th = (v: any) => Number.parseFloat(v).toFixed(4);

interface EstimateRow {
  vendor: {
    _id: string;
    name: string;
    rowSpan?: number;
  };
  item?: string;
  component?: string;
  description: string;
  componentDescription?: string;
  componentRowSpan?: number;
  firstVendorRow?: boolean;
  lastVendorRow?: boolean;
  // additionalCostsIndex?: number
  additionalCostsIndex?: string;
  additionalCostsDescription?: string;
  additionalCostFirstItem?: boolean;
  includedInMarkup?: boolean;
  bgColor?: string;
  paddingTop?: string;
  paddingBottom?: string;
  fontStyle?: string;
  fontWeight?: string;
  fontSize?: string;
  quantities: {
    [key: string]: {
      quantity: number;
      price: number;
      isLowest?: boolean;
    };
  };
  descriptionColumnSpan?: number;
  isSelectVendorRow?: boolean;
  estimateField?: string; //
  // vendor: string;
  // vendorName: string;
  // [key: string]: string;
}

interface IEstimateConversion2Props {
  rfq: ModelTypes.Rfq;
  rfqItemId: string;
  // estimate: NonNullable<ModelTypes.Rfq['estimate']>
  estimate: EstimateType;
  response?: Response;
  // vendors: optionsType
  vendors: any;
  isReadOnly?: boolean;
  onSubmit: (v: ModelTypes.Rfq['estimate']) => void;
  onChange: (v: ModelTypes.Rfq['estimate']) => void;
  onReload: () => void;
  showRecommendedSell?: boolean;
  showMinSell?: boolean;
  totalLabel?: string;
}

const spanStyle: React.CSSProperties = {
  textAlign: 'right',
  height: '100%',
  display: 'block',
  overflow: 'auto',
  padding: '0.5em 0.7em',
  background: '#f1f1f157',
  // position: 'absolute',
  // left: 0,
  // top: 0,
};

// Give our default column cell renderer editing superpowers!
const defaultColumn: Partial<ColumnDef<EstimateRow>> = {
  cell: ({ getValue, row, column: { id }, table }) => {
    const { index } = row;
    const value = getValue<string>();

    const bgColor = row.original.quantities[id]?.isLowest ? '#90ee90' : row.original.bgColor;
    return <span style={{ ...spanStyle, background: bgColor }}>{value}</span>;
  },
};

function currencyFormat(v, locale = 'en-US', currency = 'USD') {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  }).format(v);
}

function currencySymbol(locale = 'en-US', currency = 'USD') {
  return new Intl.NumberFormat(locale, { style: 'currency', currency })
    .formatToParts(1)
    .find((x) => x.type === 'currency').value;
}

function buildHeaders(
  rfq: ModelTypes.Rfq,
  item: Item,
  vendors: any,
  isReadOnly: boolean
): ColumnDef<EstimateRow>[] {
  // console.log('!!!!!!!!!!!!!!in build headers', vendors)

  const columns: ColumnDef<EstimateRow>[] = [
    {
      header: '',
      id: 'vendor',
      accessorFn: (row) => row.vendor.name,
      cell: ({ getValue, row, column: { id }, table }) => <>{row.original.vendor.name}</>,
    },
    {
      header: '',
      id: 'description',
      accessorFn: (row) => row.description,
    },
    {
      header: '',
      id: 'componentDescription',
      accessorFn: (row) => row.componentDescription,
      cell: ({ getValue, row, column: { id }, table }) => {
        const { index } = row;
        let initialValue = getValue();
        initialValue = row.original.additionalCostsDescription;

        if (row.original.description === 'Additional Costs') {
          initialValue = row.original.additionalCostsDescription;
        }

        // We need to keep and update the state of the cell normally
        const [value, setValue] = React.useState<any>(initialValue);

        // If the initialValue is changed externally, sync it up with our state
        React.useEffect(() => {
          setValue(initialValue);
        }, [initialValue]);

        const onBlur = (e) => {
          table.options.meta?.updateAdditionalCostsDescription(row, id, value);
        };

        const onAddAdditionalCostClick =
          (includedInMarkup = true) =>
          (e) => {
            // console.log('id', id)

            table.options.meta?.addAdditionalCosts(row, id, value, includedInMarkup);
          };

        const onRemoveAdditionaCostClick = (e) => {
          table.options.meta?.removeAdditionalCosts(row, id, value);
        };

        // console.log('@@!@!@!@!!@!@!@', {
        //   id,
        //   rowOriginal: row.original,
        //   initialValue,
        //   value,
        // })

        if (row.original.description === 'Additional Costs') {
          // console.log('(((((((( Rendering additional costs index', {
          //   index, id, value, initialValue, rowOriginal: row.original,
          // })

          return (
            <Box display="flex" alignItems="center">
              <Tooltip label="Describe the cost. Example: Freight">
                <Icon size="xs" as={QuestionIcon} />
              </Tooltip>
              <InputGroup size={'sm'}>
                <Input
                  type="text"
                  // size={'sm'}
                  pr="3.5rem"
                  placeholder={'Description...'}
                  value={value}
                  onBlur={onBlur}
                  onChange={(e) => setValue(e.target.value)}
                />

                {row.original.additionalCostFirstItem && (
                  <InputRightElement width="3.5rem">
                    <IconButton
                      size={'xs'}
                      h="1.25rem"
                      aria-label="Add Line"
                      icon={<AddIcon />}
                      onClick={onAddAdditionalCostClick(row.original.includedInMarkup)}
                    />
                  </InputRightElement>
                )}
                {/* {row.original.additionalCostsIndex !== 0 && (
                <InputRightElement width='3.5rem'>
                  <IconButton
                    size={'xs'}
                    h='1.25rem'
                    aria-label='Remove Line'
                    icon={<MinusIcon />}
                    onClick={onRemoveAdditionaCostClick}
                  />
                </InputRightElement>
                )} */}
              </InputGroup>
            </Box>
          );
        }

        return (
          <span style={{ ...spanStyle, backgroundColor: undefined }}>
            {row.original.componentDescription ?? ''}
          </span>
        );
      },
    },
    {
      header: 'Quantities',
      accessorFn: (row) => row.vendor.name,
      columns:
        item?.quantities?.map((q) => ({
          id: String(q),
          header: String(q),
          meta: {
            quantity: true,
          },
          accessorFn: (row) => row.quantities[q as any]?.price,
          cell: ({ getValue, row, column: { id }, table }) => {
            const { index } = row;
            const initialValue = getValue();

            // We need to keep and update the state of the cell normally
            const [value, setValue] = React.useState(initialValue);

            // When the input is blurred, we'll call our table meta's updateData function
            const onBlur = (v) => {
              // console.log('we got a blur here', row.original, v)
              // console.log('row.original.description', row.original.description, row.original);
              if (row.original.componentDescription === 'Paper Cost M') {
                table.options.meta?.updatePaperCostM(row, id, value);

                // row.original.vendor._id,
                // row.original.item as string,
                // row.original.quantities[id].quantity,
                // row.original.component as string,
                // value as number
                // );
              }

              table.options.meta?.updateData(index, id, value);

              if (editableFields.includes(row.original.description as string)) {
                //  update
                table.options.meta?.updateCostField?.(row, id, v);
              }

              if (row.original.estimateField === 'selectedVendors') {
                //  update
                table.options.meta?.updateCostField(row, id, v);
              }

              if (row.original.description === 'Additional Costs') {
                //  update
                table.options.meta?.updateAdditionalCostsAmount(row, id, v);
              }
            };

            // If the initialValue is changed external, sync it up with our state
            React.useEffect(() => {
              // console.log('here we got a cange to our initial value');
              setValue(initialValue);
            }, [initialValue]);

            const bgColor = row.original.quantities[id]?.isLowest
              ? '#90ee90'
              : row.original.bgColor;

            // console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!', { value, vendors: vendors })
            // console.log('row.original', row.original);

            // This is the select vendor row.
            if (row.original.isSelectVendorRow) {
              // console.log('$%$%%%%$%$', { value, vendors })

              // console.log('q', q)

              // const options = vendors.get()?.[q as string]

              return (
                <Select
                  size="sm"
                  isDisabled={isReadOnly}
                  // options={vendors[item._id as string][q]}
                  // value={vendors[item._id as string][q].filter(
                  //   (v) => value && value.includes && value?.includes(v.value)
                  // )}
                  options={vendors.get(item._id as string).get(q)}
                  value={vendors
                    .get(item._id as string)
                    .get(q)
                    .filter((v) => value && value.includes && value?.includes(v.value))}
                  placeholder="Select Suppliers(s)..."
                  // isClearable={true}
                  isMulti={true}
                  isClearable
                  useBasicStyles
                  onChange={(v) => {
                    setValue(v);
                    onBlur(v.map((v2: any) => v2.value));
                  }}
                />
              );
            }

            if (
              row.original.componentDescription === 'Sheet Size' ||
              row.original.componentDescription === 'Num Sheets'
            ) {
              return <span style={{ ...spanStyle, background: bgColor }}>{value}</span>;
            }

            if (
              (id === 'vendor' ||
                id === 'description' ||
                row.original.description === 'Unit Cost' ||
                id === 'componentDescription') &&
              row.original.description !== 'Additional Costs'
            ) {
              return <span style={{ ...spanStyle, background: bgColor }}>{value}</span>;
            }

            if (row.original.description === 'Per Unit') {
              return (
                <span style={{ ...spanStyle }}>
                  {currencySymbol()}
                  {value}
                </span>
              );
            }

            if (
              !row.original.componentDescription?.startsWith('Paper Cost M') &&
              !editableFields.includes(row.original.description) &&
              row.original.description !== 'Additional Costs'
            ) {
              return (
                <span style={{ ...spanStyle, background: bgColor }}>
                  {value ? currencyFormat(Number(value)) : '-'}
                </span>
              );
            }

            return (
              <Box pr=".7em">
                <NumberInput
                  isMoney={row.original.description !== 'Markup %'}
                  isDisabled={isReadOnly}
                  value={(value ?? 0.0) as any}
                  onChange={(v) => setValue(v)}
                  onBlur={onBlur}
                />
              </Box>
            );
          },
        })) || [],
    },
  ];

  return columns;
}

function buildData(
  rfq: ModelTypes.Rfq,
  estimate: ModelTypes.Rfq['estimate'],
  item: Item,
  showRecommendedSell = false,
  showMinSell = false,
  totalLabel = 'price'
) {
  const data: EstimateRow[] = [];
  const bgColors = ['#F7FAFC', 'white'];

  // start of estimate rows -------------------------------------------------------------

  // console.log('!!!!!!!!', {
  //   estimate
  // })
  const estimateItem = estimate?.items?.find((i) => i.item === item._id);

  const additionalCostAbove =
    estimateItem?.additionalCosts?.filter(
      (ac: EstimateAdditionalType) => ac?.includedInMarkup !== false
    ) ?? [];

  const additionalCostBelow =
    estimateItem?.additionalCosts?.filter(
      (ac: EstimateAdditionalType) => ac?.includedInMarkup === false
    ) ?? [];

  // console.log('item', item)
  // console.log('estimateItem', estimateItem)

  // Select a vendor row
  data.push({
    vendor: {
      _id: '',
      name: '',
    },
    bgColor: 'yellow.50',
    paddingTop: '18px',
    paddingBottom: '18px',
    fontWeight: 'bold',
    description: 'Select Supplier(s)',
    estimateField: 'selectedVendors',
    componentDescription: '',
    descriptionColumnSpan: 2,
    quantities:
      estimateItem?.quantities?.reduce((acc, cur) => {
        // console.log('select vendors value', cur.quantity, cur.selectedVendors)
        acc[cur.quantity as any] = {
          quantity: cur.quantity as any,
          price: (cur?.selectedVendors as any) ?? 0.0,
        };

        return acc;
      }, {}) ?? ({} as any),
    isSelectVendorRow: true,
  });

  data.push({
    vendor: {
      _id: '',
      name: '',
    },
    // bgColor,
    description: 'Subtotal Costs',
    // estimateField: 'selectedVendors',
    componentDescription: '',
    descriptionColumnSpan: 2,
    quantities:
      estimateItem?.quantities?.reduce((acc, cur) => {
        // console.log('cur', cur)

        // console.log('select vendors value', cur.quantity, cur.selectedVendors);
        acc[cur.quantity as any] = {
          quantity: cur.quantity as any,
          price: cur.subtotalCosts ?? 0.0,
        };

        // console.log('acc', acc)

        return acc;
      }, {}) ?? ({} as any),
  });

  additionalCostAbove?.forEach((ac: EstimateAdditionalType, idx) => {
    data.push({
      vendor: {
        _id: '',
        name: '',
      },
      // bgColor,
      description: 'Additional Costs',
      // additionalCostsIndex: idx,
      additionalCostsIndex: ac._id ?? ac?.tempId,
      additionalCostsDescription: ac?.description,
      additionalCostFirstItem: idx === 0,
      // estimateField: 'selectedVendors',
      componentDescription: '',
      descriptionColumnSpan: 2,
      quantities:
        ac?.quantities?.reduce((acc, cur) => {
          // console.log('select vendors value', cur.quantity, cur.selectedVendors);
          acc[cur.quantity as any] = {
            quantity: cur.quantity as any,
            price: cur.amount ?? 0.0,
          };

          return acc;
        }, {}) ?? ({} as any),
    });
  });

  data.push({
    vendor: {
      _id: '',
      name: '',
    },
    // bgColor,
    description: 'Total Costs',
    // estimateField: 'selectedVendors',
    componentDescription: '',
    descriptionColumnSpan: 2,
    quantities:
      estimateItem?.quantities?.reduce((acc, cur) => {
        // console.log('select vendors value', cur.quantity, cur.selectedVendors);
        acc[cur.quantity as any] = {
          quantity: cur.quantity as any,
          price: cur.totalCosts ?? 0.0,
        };

        return acc;
      }, {}) ?? ({} as any),
  });

  // Markup %
  data.push({
    vendor: {
      _id: '',
      name: '',
    },
    // bgColor,
    description: 'Markup %',
    estimateField: 'markupPercentage',
    componentDescription: '',
    descriptionColumnSpan: 2,
    quantities:
      estimateItem?.quantities?.reduce((acc, cur) => {
        acc[cur.quantity as any] = {
          quantity: cur.quantity as any,
          price: (cur?.markupPercentage as any) ?? 0.0,
        };

        return acc;
      }, {}) ?? ({} as any),
  });

  // Markup $
  data.push({
    vendor: {
      _id: '',
      name: '',
    },
    // bgColor,
    description: 'Markup $',
    estimateField: 'markupAmount',
    componentDescription: '',
    descriptionColumnSpan: 2,
    quantities:
      estimateItem?.quantities?.reduce((acc, cur) => {
        acc[cur.quantity as any] = {
          quantity: cur.quantity as any,
          price: (cur?.markupAmount as any) ?? 0.0,
        };

        return acc;
      }, {}) ?? ({} as any),
  });

  // Per Unit
  data.push({
    vendor: {
      _id: '',
      name: '',
    },
    // bgColor,
    description: 'Per Unit',
    componentDescription: '',
    descriptionColumnSpan: 2,
    quantities:
      estimateItem?.quantities?.reduce((acc, cur) => {
        acc[cur.quantity as any] = {
          quantity: cur.quantity as any,
          price: round4th((cur.subtotalPrice ?? 0.0) / (cur.quantity ?? 1)),
        };

        return acc;
      }, {}) ?? ({} as any),
  });

  // Subtotal Price
  data.push({
    vendor: {
      _id: '',
      name: '',
    },
    // bgColor,
    description: 'Subtotal Price',
    componentDescription: '',
    descriptionColumnSpan: 2,
    quantities:
      estimateItem?.quantities?.reduce((acc, cur) => {
        acc[cur.quantity as any] = {
          quantity: cur.quantity as any,
          price: cur.subtotalPrice ?? 0.0,
        };

        return acc;
      }, {}) ?? ({} as any),
  });

  // Below markup additional costs
  // if (additionalCostBelow?.length > 0) {

  // } else {
  //   data.push({
  //     vendor: {
  //       _id: '',
  //       name: '',
  //     },
  //     // bgColor,
  //     description: 'Additional Costs',
  //     additionalCostsIndex: additionalCostAbove?.length ?? 0,
  //     additionalCostsDescription: '',
  //     includedInMarkup: false,
  //     // estimateField: 'selectedVendors',
  //     componentDescription: '',
  //     descriptionColumnSpan: 2,
  //     quantities:
  //       estimateItem?.quantities?.reduce((acc, cur) => {
  //         acc[cur.quantity as any] = {
  //           quantity: cur.quantity as any,
  //           price: cur.subtotalPrice ?? 0.0,
  //         }

  //         return acc
  //       }, {}) ?? ({} as any),
  //   })
  // }

  additionalCostBelow?.forEach((ac: EstimateAdditionalType, idx) => {
    data.push({
      vendor: {
        _id: '',
        name: '',
      },
      // bgColor,
      description: 'Additional Costs',
      // additionalCostsIndex: (estimateItem?.additionalCosts?.length as number) - 1,
      additionalCostsIndex: ac._id ?? ac?.tempId,
      additionalCostsDescription: ac?.description,
      includedInMarkup: false,
      additionalCostFirstItem: idx === 0,
      // estimateField: 'selectedVendors',
      componentDescription: '',
      descriptionColumnSpan: 2,
      quantities:
        ac?.quantities?.reduce((acc, cur) => {
          // console.log('additonal cost below', cur)
          acc[cur.quantity as any] = {
            quantity: cur.quantity as any,
            price: cur.amount ?? 0.0,
          };

          return acc;
        }, {}) ?? ({} as any),
    });
  });

  // // Tax Rate
  // data.push({
  //   vendor: {
  //     _id: '',
  //     name: '',
  //   },
  //   // bgColor,
  //   description: 'Tax Rate',
  //   estimateField: 'taxRate',
  //   componentDescription: '',
  //   descriptionColumnSpan: 2,
  //   quantities:
  //     estimateItem?.quantities?.reduce((acc, cur) => {
  //       acc[cur.quantity as any] = {
  //         quantity: cur.quantity as any,
  //         price: cur.taxRate ?? 0.0,
  //       }

  //       return acc
  //     }, {}) ?? ({} as any),
  // })

  // // Tax Amount
  // data.push({
  //   vendor: {
  //     _id: '',
  //     name: '',
  //   },
  //   // bgColor,
  //   description: 'Tax Amount',
  //   estimateField: 'taxAmount',
  //   componentDescription: '',
  //   descriptionColumnSpan: 2,
  //   quantities:
  //     estimateItem?.quantities?.reduce((acc, cur) => {
  //       acc[cur.quantity as any] = {
  //         quantity: cur.quantity as any,
  //         price: cur.taxAmount ?? 0.0,
  //       }

  //       return acc
  //     }, {}) ?? ({} as any),
  // })

  // Total
  data.push({
    vendor: {
      _id: '',
      name: '',
    },
    fontSize: 'medium',
    fontWeight: 'bold',
    description: totalLabel,
    componentDescription: '',
    descriptionColumnSpan: 2,
    quantities:
      estimateItem?.quantities?.reduce((acc, cur) => {
        acc[cur.quantity as any] = {
          quantity: cur.quantity as any,
          price: cur.totalPrice ?? 0.0,
        };

        return acc;
      }, {}) ?? ({} as any),
  });

  if (showMinSell) {
    // Min Sell
    data.push({
      vendor: {
        _id: '',
        name: '',
      },
      description: 'Min Sell',
      estimateField: 'minSell',
      componentDescription: '',
      descriptionColumnSpan: 2,
      quantities:
        estimateItem?.quantities?.reduce((acc, cur) => {
          acc[cur.quantity as any] = {
            quantity: cur.quantity as any,
            price: cur.minSell ?? 0.0,
          };

          return acc;
        }, {}) ?? ({} as any),
    });
  }

  if (showRecommendedSell) {
    // Recommended Sell
    data.push({
      vendor: {
        _id: '',
        name: '',
      },
      description: 'Recommended Sell',
      estimateField: 'recommendedSell',
      componentDescription: '',
      descriptionColumnSpan: 2,
      quantities:
        estimateItem?.quantities?.reduce((acc, cur) => {
          acc[cur.quantity as any] = {
            quantity: cur.quantity as any,
            price: cur.recommendedSell ?? 0.0,
          };

          return acc;
        }, {}) ?? ({} as any),
    });
  }

  return data;
}

const initVendorRes = { _id: '', name: '' };

export default function ItemCosts(props: IEstimateConversion2Props) {
  const {
    rfq,
    rfqItemId,
    estimate,
    onSubmit,
    vendors,
    onChange,
    isReadOnly = false,
    showRecommendedSell,
    totalLabel = 'Price',
    showMinSell,
    onReload,
  } = props;

  const [selectedVendorResponse, setSelectedVendorResponse] = useState(initVendorRes);
  const [selectedResponse, setSelectedResponse] = useState({});

  const [isSendModalOpen, setIsSendModalOpen] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState<{ _id: string; name: string }>({
    _id: '',
    name: '',
  });

  const uuid = `${useId()}-${new Date().valueOf()}`;

  const rfqItem = rfq?.items?.find((i) => i._id === rfqItemId);

  // console.log('top most vendors', vendors)

  // console.log({rfq, rfqItemId });
  if (!rfqItem) {
    return null;
  }
  // const rfqItem = rfq?.items[0];
  const [data, setData] = React.useState(() =>
    buildData(rfq, estimate, rfqItem as any, showRecommendedSell, showMinSell, totalLabel)
  );
  const columns = React.useMemo<ColumnDef<EstimateRow>[]>(
    () => buildHeaders(rfq, rfqItem as any, vendors, isReadOnly),
    [vendors]
  );

  React.useEffect(() => {
    setData(buildData(rfq, estimate, rfqItem as any, showRecommendedSell, showMinSell, totalLabel));
  }, [estimate, showMinSell]);

  const table = useReactTable({
    data,
    columns,
    defaultColumn,
    getCoreRowModel: getCoreRowModel(),

    meta: {
      updatePaperCostM: (row: Row<EstimateRow>, id, cost: number) => {
        const { vendor, item, component, quantities } = row.original;
        const { quantity } = quantities[id];
        // console.log('!!!!! UPDATING PAPER COST', { rowOriginal: row.original, id, cost })
        const lookupItem = rfq.responses
          ?.find((r) => (r.vendor as any)._id === vendor._id)
          ?.items?.find((i) => i.item === item);
        if (estimate?.items) {
          const updatedEstimate = { ...estimate };
          updatedEstimate.items = updatedEstimate?.items?.map((estimateItem) => {
            // console.log('!!!!! UPDATING PAPER COST', `comparing: ${estimateItem.item} === ${item}`)
            if (estimateItem.item === item) {
              estimateItem.quantities = estimateItem.quantities?.map((q) => {
                if (q.quantity === quantity) {
                  const numSheets = lookupItem?.quantities
                    ?.find((q2) => q2.quantity === quantity)
                    ?.components?.find((c) => c.component === component)?.numSheets;

                  q.paperCostM =
                    q.paperCostM?.map((p) => {
                      // console.log('@#$#$#$#$#$# comparing', `${p.vendor} === ${vendor._id}`)
                      const a = '';
                      return {
                        ...p,
                        cost: p.component === component && p.vendor === vendor._id ? cost : p.cost,
                        costTotal:
                          p.component === component && p.vendor === vendor._id
                            ? (Number(cost) * Number(numSheets)) / 1000
                            : p.costTotal,
                      };
                    }) ?? [];
                }

                // console.log('!!! UPDATING PAPER COST', { q })

                // console.log('q q ', q)
                // console.log('q.selectedVendors ', q.selectedVendors)

                if (q.selectedVendors && q.selectedVendors.length > 0) {
                  // console.log('SETTESING SUBTOTAL COSTS', {
                  //   qSelectedVendors: q.selectedVendors,
                  //   rfqResponses: rfq.responses,
                  // });

                  q.subtotalCosts = rfq.responses
                    ?.filter((r) => (q.selectedVendors as any).includes((r.vendor as any)._id))
                    .reduce((acc, cur) => {
                      // console.log('^^^^^^ in reduce', cur);
                      const thisItem = cur.items
                        ?.find((i2) => i2.item === rfqItem._id)
                        ?.quantities?.find((q2) => q2.quantity === q.quantity);
                      const totalPaperCost =
                        // q.paperCostM?.reduce(
                        //   (acc2, cur2) => acc2 + Number(cur2.costTotal ?? 0.0),
                        //   0
                        // ) ?? 0.0
                        q.paperCostM?.reduce((acc2, cur2) => {
                          const found = (cur.vendor as any)?._id === cur2.vendor;

                          if (found) {
                            acc2 += Number(cur2.costTotal ?? 0.0);
                          }
                          return acc2;
                        }, 0) ?? 0.0;

                      // console.log('q acc', acc)
                      // console.log('q thisItem', thisItem)
                      // console.log('q totalPaperCost', totalPaperCost)
                      return acc + (thisItem?.price ?? 0.0) + totalPaperCost;
                    }, 0);

                  q.paperCostTotal =
                    q.paperCostM?.reduce((acc, cur) => acc + Number(cur.costTotal ?? 0.0), 0) ??
                    0.0;
                  q.totalCosts =
                    (q.subtotalCosts ?? 0.0) +
                    (estimateItem.additionalCosts?.reduce(
                      (acc, cur) =>
                        acc +
                        Number(
                          cur?.quantities?.find((q3) => q3.quantity === q.quantity)?.amount ?? 0.0
                        ),
                      0
                    ) ?? 0.0);
                  q.subtotalPrice = Number(q.totalCosts ?? 0.0) + Number(q.markupAmount ?? 0.0);
                  q.totalPrice = q.subtotalPrice + Number(q.taxAmount ?? 0.0);
                }

                return q;
              });
            }

            return estimateItem;
          });
          // console.log('!!!!!!!!!!!!!!updatedEsstimate', updatedEstimate);
          // update the state.
          // setData(buildData(rfq, rfq?.items[0] as any));
          // setData({ ...data, ...rfq });
          onChange(updatedEstimate as any);
        }
      },
      updateData: (rowIndex, columnId, value) => {
        setData((old: any[]) =>
          old.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...old[rowIndex]!,
                [columnId]: value,
              };
            }
            return row;
          })
        );
      },
      updateCostField(row: Row<EstimateRow>, id, value: number) {
        // console.log('got an updateCostField update', { row, id, value });

        if (estimate?.items) {
          const updatedEstimate = { ...estimate };
          if (estimate) {
            updatedEstimate.items = estimate?.items?.map((i) => {
              if (i.item === rfqItem._id) {
                i.quantities = i.quantities?.map((q) => {
                  if (q.quantity === row.original.quantities[id as string].quantity) {
                    // console.log('********** Setting the value', value);
                    q[row.original.estimateField as string] = value as any;

                    if (row.original.estimateField === 'selectedVendors') {
                      q.subtotalCosts = rfq.responses
                        ?.filter((r) => (value as any).includes((r.vendor as any)?._id))
                        .reduce((acc, cur) => {
                          // console.log('asdasd cur', cur)

                          const thisItem = cur.items
                            ?.find((i2) => i2.item === rfqItem._id)
                            ?.quantities?.find((q2) => q2.quantity === q.quantity);

                          const totalPaperCost =
                            q.paperCostM?.reduce((acc2, cur2) => {
                              const found = (cur.vendor as any)?._id === cur2.vendor;

                              if (found) {
                                acc2 += Number(cur2.costTotal ?? 0.0);
                              }

                              // console.log('q acc2', acc2)
                              return acc2;
                            }, 0) ?? 0.0;

                          // console.log(
                          //   'q acc + (thisItem?.price ?? 0.0) + totalPaperCost',
                          //   acc + (thisItem?.price ?? 0.0) + totalPaperCost
                          // )

                          return acc + (thisItem?.price ?? 0.0) + totalPaperCost;
                        }, 0);

                      // @TODO: RECALCULATE total costs across all vendors
                      // q.totalCosts
                    }

                    q.totalCosts =
                      (q.subtotalCosts ?? 0.0) +
                      (i.additionalCosts
                        ?.filter((ac: EstimateAdditionalType) => ac?.includedInMarkup !== false)
                        ?.reduce(
                          (acc, cur) =>
                            acc +
                            Number(
                              cur?.quantities?.find((q3) => q3.quantity === q.quantity)?.amount ??
                                0.0
                            ),
                          0
                        ) ?? 0.0);

                    if (row.original.estimateField === 'markupPercentage') {
                      const markupAmount = Number(q.totalCosts) * (Number(value) / 100);

                      // console.log('markupAmount', markupAmount)

                      q.markupAmount = Number(markupAmount?.toFixed(2));
                    }

                    if (row.original.estimateField === 'markupAmount') {
                      const markupPercentage = (Number(value) / Number(q.totalCosts)) * 100;

                      // console.log('markupPercentage', markupPercentage)

                      q.markupPercentage = Number(markupPercentage?.toFixed(2));
                    }

                    if (row.original.estimateField === 'taxRate') {
                      q.taxAmount = Number(q.subtotalPrice) * (Number(value) / 100);
                    }

                    if (row.original.estimateField === 'recommendedSell') {
                      q.recommendedSell = Number(value);
                    }

                    const additionalCostBelow =
                      i.additionalCosts
                        ?.filter((ac: EstimateAdditionalType) => ac?.includedInMarkup === false)
                        ?.reduce(
                          (acc, cur) =>
                            acc +
                            Number(
                              cur?.quantities?.find((q3) => q3.quantity === q.quantity)?.amount ??
                                0.0
                            ),
                          0
                        ) ?? 0.0;

                    q.subtotalPrice = Number(q.totalCosts ?? 0.0) + Number(q.markupAmount ?? 0.0);
                    q.totalPrice =
                      q.subtotalPrice + Number(q.taxAmount ?? 0.0) + additionalCostBelow;
                  }

                  return q;
                });
              }

              return i;
            });

            // console.log('updatedEstimate', updatedEstimate)

            onChange(updatedEstimate as any);
          }
        }
      },
      updateAdditionalCostsDescription: (row: Row<EstimateRow>, id, value: string) => {
        if (estimate?.items) {
          const updatedEstimate = { ...estimate };
          if (estimate) {
            updatedEstimate.items = estimate?.items?.map((i) => {
              if (i.item === rfqItem._id) {
                i.additionalCosts = i.additionalCosts?.map((q: EstimateAdditionalType, idx) => {
                  if (row.original.additionalCostsIndex === (q?._id ?? q.tempId)) {
                    q.description = value;
                  }

                  return q;
                });
              }

              return i;
            });

            onChange(updatedEstimate as any);
          }
        }
      },

      updateAdditionalCostsAmount: (row: Row<EstimateRow>, id, value: string) => {
        // console.log('update on the update additional costs!!!');
        if (estimate?.items) {
          const updatedEstimate = { ...estimate };
          if (estimate) {
            updatedEstimate.items = estimate?.items?.map((i) => {
              if (i.item === rfqItem._id) {
                i.additionalCosts = i.additionalCosts?.map((q: EstimateAdditionalType, idx) => {
                  if (row.original.additionalCostsIndex === (q?._id ?? q.tempId)) {
                    console.log('id', id, 'idx', idx, 'value', value);

                    q.quantities =
                      q.quantities?.map((q2) => {
                        if (Number(q2.quantity) === Number(id)) {
                          q2.amount = Number(value);
                        }

                        return q2;
                      }) ?? [];
                  }

                  return q;
                });

                i.quantities = i.quantities?.map((q) => {
                  if (Number(q.quantity) === Number(id)) {
                    const allAdditionalCostsBelow =
                      i.additionalCosts
                        ?.filter((ac: EstimateAdditionalType) => ac?.includedInMarkup === false)
                        ?.reduce(
                          (acc, cur) =>
                            acc +
                            Number(
                              cur?.quantities?.find((q3) => q3.quantity === q.quantity)?.amount ??
                                0.0
                            ),
                          0
                        ) ?? 0;

                    console.log('allAdditionalCostsBelow', allAdditionalCostsBelow);

                    q.totalCosts =
                      (q.subtotalCosts ?? 0.0) +
                      (i.additionalCosts
                        ?.filter((ac: EstimateAdditionalType) => ac?.includedInMarkup !== false)
                        ?.reduce(
                          (acc, cur) =>
                            acc +
                            Number(
                              cur?.quantities?.find((q3) => q3.quantity === q.quantity)?.amount ??
                                0.0
                            ),
                          0
                        ) ?? 0.0);
                    q.subtotalPrice = Number(q.totalCosts ?? 0.0) + Number(q.markupAmount ?? 0.0);
                    q.totalPrice =
                      allAdditionalCostsBelow + q.subtotalPrice + Number(q.taxAmount ?? 0.0);
                  }

                  return q;
                });
              }

              return i;
            });

            onChange(updatedEstimate as any);
          }
        }
      },
      addAdditionalCosts(row: Row<EstimateRow>, id, value: string, includedInMarkup: boolean) {
        if (estimate?.items) {
          const updatedEstimate = { ...estimate };
          if (estimate) {
            updatedEstimate.items = estimate?.items?.map((i) => {
              if (i.item === rfqItem._id) {
                i.additionalCosts = i.additionalCosts ?? [];
                i.additionalCosts.push({
                  description: '',
                  includedInMarkup,
                  tempId: uuid,
                  quantities:
                    i.quantities?.map((q) => ({
                      quantity: q.quantity,
                      amount: 0,
                    })) ?? [],
                });
              }

              return i;
            });

            onChange(updatedEstimate);
          }
        }
      },
      removeAdditionalCosts: (row: Row<EstimateRow>, id, value: string) => {
        if (estimate?.items) {
          const updatedEstimate = { ...estimate };
          if (estimate) {
            updatedEstimate.items = estimate?.items?.map((i) => {
              const updatedItem = { ...i };
              if (i.item === rfqItem._id) {
                const updatedAdditionalCosts = [...(updatedItem.additionalCosts ?? [])];
                updatedItem.additionalCosts = updatedAdditionalCosts.filter(
                  (q, idx) => row.original.additionalCostsIndex !== q._id
                );

                updatedItem.quantities = updatedItem.quantities?.map((q) => {
                  if (Number(q.quantity) === Number(id)) {
                    q.totalCosts =
                      (q.subtotalCosts ?? 0.0) +
                      (i.additionalCosts?.reduce(
                        (acc, cur) =>
                          acc +
                          Number(
                            cur?.quantities?.find((q3) => q3.quantity === q.quantity)?.amount ?? 0.0
                          ),
                        0
                      ) ?? 0.0);
                    q.subtotalPrice = Number(q.totalCosts ?? 0.0) + Number(q.markupAmount ?? 0.0);
                    q.totalPrice = q.subtotalPrice + Number(q.taxAmount ?? 0.0);
                  }

                  return q;
                });
              }

              return updatedItem;
            });

            onChange(updatedEstimate as any);
          }
        }
      },
    },
  });
  const toast = useToast();
  return (
    <div>
      <TableContainer>
        <ChakraTable size="sm" pb={100} mb={100} style={{ tableLayout: 'fixed' }}>
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  const placeholder = '';
                  // header.column.id
                  // console.log('header.column.id', header.column.id)
                  return (
                    <Th
                      // width={'1'}
                      key={header.id}
                      colSpan={header.column.id === 'Quantities' ? header.colSpan : undefined}
                      w={header.column.id === 'Quantities' ? 'auto' : '100px'}
                      style={{ textAlign: 'right' }}
                      bgColor={'gray.100'}
                    >
                      {/* {header.isPlaceholder ? null : (
                        <div>{flexRender(header.column.columnDef.header, header.getContext())}</div>
                      )} */}
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.map((row) => {
              const placeholder = '';
              return (
                <Tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    let rowSpan;
                    let colSpan;
                    let note;

                    if (cell.column.id === 'vendor') {
                      note = rfq?.responses?.reduce((acc, cur) => {
                        // console.log('r', r)

                        if ((cur?.vendor as any)?._id === row.original.vendor._id) {
                          acc =
                            cur?.items?.find((i) => rfqItem?._id === i.item && i?.notes)?.notes ??
                            '';
                        }

                        return acc;
                      }, '');

                      rowSpan = cell.row.original.vendor.rowSpan;
                    }

                    if (cell.column.id === 'vendor' && !rowSpan) {
                      return null;
                    }

                    if (
                      cell.column.id === 'description' &&
                      cell.row.original.description?.startsWith('Component') &&
                      !cell.row.original.componentRowSpan
                    ) {
                      return null;
                    }

                    if (
                      cell.column.id === 'description' &&
                      cell.row.original.descriptionColumnSpan
                    ) {
                      colSpan = cell.row.original.descriptionColumnSpan;
                    }

                    if (
                      cell.column.id === 'description' &&
                      cell.row.original.componentRowSpan &&
                      cell.row.original.description.startsWith('Component')
                    ) {
                      rowSpan = cell.row.original.componentRowSpan;
                    }

                    let maxWidth;
                    if ((cell.column.columnDef.meta as any)?.quantity) {
                      maxWidth = '100px';
                    }

                    if (cell.column.id === 'vendor' || cell.column.id === 'description') {
                      maxWidth = '75px';
                    }

                    const toastId = cell.row.original.vendor._id;

                    const isCellVendor = cell.column.id === 'vendor';

                    let cellNote;

                    return (
                      <Td
                        bgColor={cell.row.original.bgColor}
                        fontStyle={cell.row.original.fontStyle}
                        fontWeight={cell.row.original.fontWeight}
                        key={cell.id}
                        rowSpan={rowSpan}
                        colSpan={colSpan}
                        style={{
                          padding: 0,
                          background: cell.row.original.bgColor,
                          paddingTop: cell.row.original.paddingTop ?? 0,
                          paddingBottom: cell.row.original.paddingBottom ?? 0,
                          borderTop: cell.row.original.firstVendorRow
                            ? '1px solid #CBD5E0'
                            : undefined,
                          borderBottom: cell.row.original.lastVendorRow
                            ? '2px solid #CBD5E0'
                            : undefined,
                          maxWidth,
                        }}
                        // style={{ backgroundColor: isLowest(cell.row.original.vendor._id, itemLookup?._id as string, q.quantity as number, rfq) ? '#90ee90' : 'white' }}
                      >
                        <Box>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Box>
                        <Flex>{cellNote}</Flex>
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </ChakraTable>
      </TableContainer>
    </div>
  );
}
