import * as React from 'react';
import { Button, Form, Message, Segment } from 'semantic-ui-react';
import { Formik, Form as FormikForm, Field, FieldProps, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { SemanticField } from './../../../../common/Form';

interface PromoCodeCampaignFormProps {
  initialValues: any;
  onSubmit: Function;
  isSubmitting?: boolean;
  errorMessage: string;
  microsite?: string;
}

const { useState } = React;

export const PromoCodeCampaignForm: React.FunctionComponent<PromoCodeCampaignFormProps> = (
  props
) => {
  const { errorMessage, microsite } = props;

  const PromoCodeCampaignSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    codePrefix: Yup.string(),
  });

  return (
    <Formik
      initialValues={props.initialValues}
      enableReinitialize={true}
      validationSchema={PromoCodeCampaignSchema}
      onSubmit={(values, { setSubmitting }) => {
        props.onSubmit(values);
      }}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
          setFieldValue,
          setFieldTouched,
        } = props;

        return (
          <FormikForm
            className={`ui form ${props.isSubmitting && !errorMessage && 'loading'} ${
              errorMessage ? 'error' : ''
            }`}
          >
            <Form.Group widths="equal">
              <Form.Field>
                <SemanticField name="name" label="Campaign" component={Form.Input} />
                <ErrorMessage
                  render={(msg) => <Message negative size="tiny" content={msg} />}
                  name="name"
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field>
                <SemanticField name="codePrefix" label="Code Prefix" component={Form.Input} />
              </Form.Field>
            </Form.Group>
            <Button primary type="submit">
              Save
            </Button>
          </FormikForm>
        );
      }}
    </Formik>
  );
};
