import * as React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { FormattedDate, FormattedTime } from 'react-intl';
import { Button, Label, Icon, Popup, Loader } from 'semantic-ui-react';

import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
} from '@chakra-ui/react';

import {
  useList,
  ListPage,
  basicQueryMap,
  generateOperatorOptions,
  Operator,
} from '@inkcloud/components';

import { Feathers } from '../../../bootstrap/feathers/feathers';

export interface ListProps {
  statusOptions: Array<{ value: string; text: string }>;
}

const serviceName = '/data-views';
const pathPrefix = `/settings/data-views`;

const filterQueryMap = (values) => {
  let filterRows = basicQueryMap(values);

  filterRows = {
    ...filterRows,
    vendor: filterRows?.vendor?.$elemMatch?.value,
  };
  return filterRows;
};

async function getFilterDefinition() {
  const definition = [
    {
      label: 'Name',
      value: 'name',
      operators: generateOperatorOptions([Operator.Like]),
    },
    {
      label: 'Vendor',
      value: 'vendor',
      operators: generateOperatorOptions([Operator.Is]),
      valueOptions: [],
      asyncValueOptions: async () =>
        Feathers.service('/production/vendors')
          .find({
            query: {
              $sort: { companyName: 1 },
            },
          })
          .then((res) => res.data?.map((r) => ({ value: r._id, label: r.companyName }))),
      type: 'select',
    },
  ];

  return definition.sort((a: any, b: any) => a.label.localeCompare(b.label));
}

export default function List(props: RouteComponentProps) {
  const {
    match: { params },
    location,
    history,
  } = props;

  const useListValues = useList({
    feathers: [
      'find',
      serviceName,
      [
        {
          query: {
            $populate: ['vendor'],
            $sort: { createdAt: -1 },
          },
        },
      ],
    ],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  const tableDefinition = [
    {
      key: 'createdAt',
      label: 'Date',
      sortable: true,
      cell: (v, r) => (
        <Popup
          size="mini"
          content={<FormattedTime value={r.createdAt} />}
          trigger={
            <div>
              <FormattedDate value={r.createdAt} />
            </div>
          }
        />
      ),
    },
    { key: 'name', label: 'Name' },
    { key: 'vendor', label: 'Vendor', cell: (v, r) => r.vendor?.companyName },
    {
      key: '',
      label: '',
      cell: (v, r) => (
        <>
          <Popup
            size="mini"
            content="Edit"
            align
            trigger={
              <Link to={`${pathPrefix}/${r._id}`}>
                <Button size="mini" icon="edit" />
              </Link>
            }
          />
        </>
      ),
      textAlign: 'right',
    },
  ];

  const headerActions = (
    <>
      <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
      <Link to={`${pathPrefix}/add`}>
        <Button size="mini" primary content="New" />
      </Link>
    </>
  );

  return (
    <>
      <ListPage
        header="Data Views"
        subheader="Settings"
        useListValues={useListValues}
        history={history}
        pathPrefix={pathPrefix}
        tableDefintion={tableDefinition}
        withCheckboxes={true}
        headerActions={headerActions}
        getFilterDefinition={getFilterDefinition}
      />
    </>
  );
}
