/* eslint-disable no-undef */

import React, { useState, useRef } from 'react';
import { CSVLink } from 'react-csv';
import Cleave from 'cleave.js/react';

import {
  Box,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Stack,
  Button,
  Alert,
  Textarea,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  Text,
  Flex,
  Heading,
  Checkbox,
  Icon,
  useDisclosure,
  ButtonGroup,
} from '@chakra-ui/react';

import { FaBox, FaTrash } from 'react-icons/fa';

import { FormattedNumber } from 'react-intl';
import { useSelector } from 'react-redux';
import type { ModelTypes } from '@inkcloud/icapi-types';
import { forEachLimit, mapLimit, asyncify } from 'async';
import OverrideProductModal from './OverrideProductModal';
import ImportFromCSVModal from './ImportFromCSVModal';
import { ReturnData } from '../../../common/CSVUploadPreview/CSVUploadPreview';
import { InputWrapper, currencySymbol } from '../../../common';
import { icapi } from '../../../bootstrap/feathers';

type ProdLegacyType = ModelTypes.ProductLegacy;

type PriceListType = Omit<ModelTypes.PriceListsLegacy, 'productOverrides'> & {
  productOverrides: {
    product: ModelTypes.ProductLegacy;
    price: number;
    selected?: boolean;
  }[];
  isFreeShippingEnabled?: boolean;
};

interface GLAccountFormProps {
  initialValues: PriceListType;
  isSubmitting: boolean;
  errorMessage: string;
  onSubmit: (v: PriceListType) => void;
}

const exportCsvHeaders = [
  { label: 'Price List Name', key: 'priceListName' },
  { label: 'Product ID', key: 'productId' },
  { label: 'SKU', key: 'key' },
  { label: 'Description', key: 'description' },
  { label: 'Price', key: 'price' },
  { label: 'Override Price', key: 'overridePrice' },
];

export default function PriceListsForm(props: GLAccountFormProps) {
  const { initialValues, onSubmit, isSubmitting, errorMessage } = props;
  const { isOpen, onClose, onOpen } = useDisclosure();

  const tenant = useSelector(
    ({
      globals,
    }: {
      globals: { tenant: { internationalization: { currency: string; locale: string } } };
    }) => globals.tenant.internationalization
  );

  const [name, setName] = useState(initialValues?.name);
  const [description, setDescription] = useState(initialValues?.description);
  const percentageRef = useRef<HTMLInputElement>(null);
  const freeShippingRef = useRef<HTMLInputElement>(null);

  const [productOverrides, setProductOverrides] = useState(initialValues?.productOverrides ?? []);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [selectedProduct, setSelectedProduct] = useState([]);

  const handleAddProduct = ({
    product,
    price,
  }: {
    product: ModelTypes.ProductLegacy;
    price: number;
  }) => {
    const newProductOverrides: any = [...productOverrides];

    newProductOverrides.push({
      product,
      price,
    });

    setProductOverrides(newProductOverrides);
    setIsModalOpen(false);
  };

  const handleRemoveProduct = () => {
    const newProductOverrides: any = productOverrides?.filter((p: any) => !p.selected);

    setProductOverrides(newProductOverrides);
  };

  const handleToggleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;

    const newProductOverrides: any = productOverrides?.map((prod) => ({
      product: prod.product,
      price: prod.price,
      selected: checked,
    }));

    setProductOverrides(newProductOverrides);
  };

  const handleToggleSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, id } = e.target;

    const newProductOverrides: any = productOverrides?.map((prod: any) => ({
      product: prod.product,
      price: prod.price,
      selected: (prod?.product as ProdLegacyType)._id === id ? checked : prod?.selected,
    }));

    setProductOverrides(newProductOverrides);
  };

  const handleCSVImport = async (data: ReturnData) => {
    // console.log('got csv import', data);

    // Check each row for a productId. If not, lookup by SKU
    const p = new Promise((resolve, reject) => {
      mapLimit(
        data.data,
        2,
        asyncify(async (d: any) => {
          try {
            let product;

            if (!d.productId) {
              product = (await icapi.service('products-legacy').find({
                query: {
                  key: d.sku,
                  $findOne: '1',
                },
              })) as ModelTypes.ProductLegacy;
            } else {
              product = (await icapi
                .service('products-legacy')
                .get(d.productId)) as ModelTypes.ProductLegacy;
            }

            return {
              product,
              price: d.overridePrice,
            };
          } catch (error) {
            //  TODO: Handle error
            console.log('pricelist handlecsv map error', error);
            throw error;
          }
        }),
        (err, results) => {
          if (err) {
            return reject(err);
          }

          return resolve(results);
        }
      );
    });

    const mappedResults = await p;

    // console.log('mapped results', mappedResults);

    setProductOverrides(mappedResults as any);
  };

  const areAllSelected =
    productOverrides?.every((p: any) => p?.selected === true) && productOverrides?.length > 0;

  const hasSelected = productOverrides?.some((p: any) => p?.selected === true);

  const isDisabled = !name?.trim() || !description?.trim();

  const csvData = () => {
    // console.log('csvData!!!!!!');
    const data =
      initialValues.productOverrides?.map((r: any) => ({
        priceListName: initialValues.name,
        key: r.product?.key,
        productId: r.product?._id,
        description: r.product?.description,
        price: r.product?.price,
        overridePrice: r.price,
      })) ?? [];
    return data;
  };

  return (
    <>
      <Stack spacing={7} mb={3} direction="row">
        <FormControl isInvalid={!name?.trim()}>
          <FormLabel>Name</FormLabel>
          <Input
            type="text"
            name="name"
            onChange={(e) => setName(e.target.value)}
            defaultValue={initialValues?.name}
          />
          {!name?.trim() && <FormErrorMessage>Required</FormErrorMessage>}
        </FormControl>
      </Stack>

      <Stack spacing={7} mb={3} direction="row">
        <FormControl isInvalid={!description?.trim()}>
          <FormLabel>Description</FormLabel>
          <Textarea
            name="description"
            onChange={(e) => setDescription(e.target.value)}
            defaultValue={initialValues?.description}
          />
          {!description?.trim() && <FormErrorMessage>Required</FormErrorMessage>}
        </FormControl>
      </Stack>
      <Stack spacing={7} mb={4} direction="row">
        <FormControl>
          <FormLabel>Percentage</FormLabel>
          <Input
            ref={percentageRef}
            type="text"
            name="defaultPercentage"
            defaultValue={initialValues?.defaultPercentage}
          />
        </FormControl>
      </Stack>

      <Box mb={4} p={3} borderWidth={1} borderRadius="md">
        <Heading as="h5" size="sm" mb={3}>
          Product Overrides
        </Heading>
        <Flex justifyContent="end" mb={3}>
          <ButtonGroup spacing={2}>
            <Button onClick={() => onOpen()}>Import from CSV</Button>
            <Button
              as={CSVLink}
              // className="chakra-button ui button small"
              data={csvData()}
              filename="pricelist-product-overrides.csv"
              headers={exportCsvHeaders}
              target="_blank"
            >
              Download as CSV{' '}
            </Button>

            <Button colorScheme="blue" onClick={() => setIsModalOpen(true)}>
              Add Product Override
            </Button>
          </ButtonGroup>
        </Flex>
        {productOverrides?.length ? (
          <Table variant="simple" mb={3}>
            <Thead>
              <Tr>
                <Th>
                  <Checkbox isChecked={areAllSelected} onChange={handleToggleSelectAll} />
                </Th>
                <Th>SKU</Th>
                <Th>Description</Th>
                <Th isNumeric>Product Price</Th>
                <Th isNumeric>Override Price</Th>
              </Tr>
            </Thead>
            <Tbody>
              {productOverrides?.map((p: any, index: number) => (
                <Tr key={`${(p?.product as ProdLegacyType)?._id}${index}`}>
                  <Td>
                    <Checkbox
                      isChecked={p?.selected}
                      id={(p?.product as ProdLegacyType)?._id}
                      onChange={handleToggleSelect}
                    />
                  </Td>
                  <Td>{(p?.product as ProdLegacyType).key}</Td>
                  <Td>{(p?.product as ProdLegacyType)?.description}</Td>
                  <Td isNumeric>
                    <FormattedNumber
                      style="currency"
                      value={(p?.product as ProdLegacyType).price ?? 0}
                      currency={tenant?.currency ?? 'USD'}
                    />
                  </Td>
                  <Td isNumeric>
                    <InputWrapper>
                      <Cleave
                        style={{ textAlign: 'right' }}
                        className={'form-control-cleave'}
                        options={{
                          numeral: true,
                          rawValueTrimPrefix: true,
                          numeralPositiveOnly: true,
                          numeralThousandsGroupStyle: 'thousand',
                          prefix: currencySymbol(tenant?.currency, tenant?.locale),
                        }}
                        value={p?.price ?? 0}
                        onChange={(e) => {
                          const newProductOverrides = productOverrides?.map(
                            (prod, idx: number) => ({
                              product: prod.product,
                              price: idx === index ? Number(e.target.rawValue) : prod.price,
                            })
                          );
                          setProductOverrides(newProductOverrides);
                        }}
                      />
                    </InputWrapper>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        ) : (
          <Flex
            mb={3}
            p={8}
            alignItems="center"
            justifyContent="center"
            bg="gray.50"
            borderRadius="md"
          >
            <Box textAlign="center">
              <Text mb={2} color="gray.700">
                No Products
              </Text>
              <Icon as={FaBox} w={12} h={12} color="gray.500" />
            </Box>
          </Flex>
        )}
        {productOverrides?.length > 0 && (
          <Button isDisabled={!hasSelected} onClick={handleRemoveProduct}>
            Remove
          </Button>
        )}
      </Box>
      <Stack spacing={7} mb={4} direction="row">
        <FormControl>
          <Checkbox
            ref={freeShippingRef}
            type="text"
            name="isFreeShippingEnabled"
            defaultChecked={initialValues?.isFreeShippingEnabled}
          >
            Enabled Free Shipping
          </Checkbox>
        </FormControl>
      </Stack>

      {errorMessage && (
        <Alert status="error" mb={3}>
          {errorMessage}
        </Alert>
      )}

      <Button
        colorScheme="blue"
        isDisabled={isSubmitting || isDisabled}
        isLoading={isSubmitting}
        onClick={() =>
          onSubmit({
            name,
            description,
            isFreeShippingEnabled: freeShippingRef?.current?.checked,
            defaultPercentage: Number(percentageRef?.current?.value),
            productOverrides: productOverrides?.map((p) => ({
              product: (p?.product as ProdLegacyType)?._id,
              price: p?.price,
            })) as any,
            // })) as { product: string; price: number }[],
          })
        }
      >
        Save
      </Button>

      {isModalOpen && (
        <OverrideProductModal onClose={() => setIsModalOpen(false)} onSave={handleAddProduct} />
      )}

      {isOpen && (
        <ImportFromCSVModal isOpen={isOpen} onClose={onClose} onSubmit={handleCSVImport} />
      )}
    </>
  );
}
