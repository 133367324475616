// import * as React from 'react'
// import { Button, Label, Segment, Table, Popup } from 'semantic-ui-react'
// import { Link, RouteComponentProps } from 'react-router-dom'
// import { FormattedDate, FormattedTime } from 'react-intl'

// import {
//   useList,
//   PageHeaderRow,
//   ListTable,
//   PaginatorWrapper,
//   EmptyWrapper,
// } from '@inkcloud/components'

// import { FilterForm } from './FilterForm'

// import Feathers from '../../../bootstrap/feathers'

// import { ExportModal } from '../../reports/ExportModal'

// interface ListProps {}

// const serviceName = 'users'
// const pathPrefix = '/settings/team-members'

// const filterQueryMap = (values) => {
//   const filterMap: any = {
//     firstName: values.firstName ? { $LIKE: values.firstName } : undefined,
//     lastName: values.lastName ? { $LIKE: values.lastName } : undefined,
//     email: values.email ? { $LIKE: values.email } : undefined,
//   }

//   return [].reduce(
//     (m, field) => ({ ...m, [field]: values[field] ? values[field] : undefined }),
//     filterMap
//   )
// }

// const { useState } = React

// export const List: React.FunctionComponent<ListProps & RouteComponentProps<any>> = (props) => {
//   const {
//     match: { params },
//     location,
//     history,
//   } = props
//   const {
//     state,
//     reload,
//     handleDelete,
//     selectedItems,
//     clearAll,
//     setChecked,
//     someSelected,
//     toggleAll,
//     filter,
//     setFilter,
//     sort,
//     handleSort,
//   } = useList({
//     feathers: [
//       'find',
//       serviceName,
//       [{ query: { $populate: ['roles2'], vendor: { $exists: false } } }],
//     ],
//     filterMap: filterQueryMap,
//     debounce: 150,
//     location,
//     history,
//     sort: { createdAt: -1 },
//   }) as any

//   const [isSubmitting, setSubmitting] = useState(false)
//   const [isModalOpen, setIsModalOpen] = useState(false)
//   const [exportUrl, setExportUrl] = useState('')

//   const { queryResult, isLoading: isStateLoading } = state as any

//   const handleExport = async (all: string) => {
//     setSubmitting(true)

//     const res: any = await Feathers.service('report/v2/team-export?$export=1').create({
//       all: true,
//     })

//     if (res) {
//       setIsModalOpen(true)
//       setExportUrl(res.url)
//       setSubmitting(false)
//     }
//   }

//   const tableDefinition = [
//     {
//       key: 'createdAt',
//       label: 'Created',
//       cell: (v) => (
//         <Popup
//           size="mini"
//           content={<FormattedTime value={v} />}
//           trigger={
//             <div>
//               <FormattedDate value={v} />
//             </div>
//           }
//         />
//       ),
//     },
//     {
//       key: 'firstName',
//       label: 'First Name',
//       cell: (v) => v,
//     },
//     {
//       key: 'lastName',
//       label: 'Last Name',
//       cell: (v) => v,
//     },
//     {
//       key: 'email',
//       label: 'Email',
//       cell: (v) => v,
//     },
//     {
//       key: 'roles',
//       label: 'Roles',
//       cell: (v, r) => (
//         <>{r.roles2 && r.roles2.map((r) => <Label size="mini" content={r.name} />)}</>
//       ),
//     },
//     {
//       key: '',
//       label: '',
//       cell: (v, r) => (
//         <Popup
//           size="mini"
//           content="Edit"
//           trigger={
//             <Link to={`${pathPrefix}/${r._id}`}>
//               <Button size={'mini'} icon={'edit'} />
//             </Link>
//           }
//         />
//       ),
//       textAlign: 'right',
//     },
//   ]

//   const resolvedQueryResult = queryResult?.data ? queryResult.data : queryResult

//   return (
//     <div>
//       <PageHeaderRow header="Settings" subheader="Team Members">
//         <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
//         <Link to={`${pathPrefix}/add`}>
//           <Button content="New" primary size={'mini'} />
//         </Link>
//       </PageHeaderRow>

//       <FilterForm
//         value={filter}
//         onChange={(v) => setFilter(v)}
//         onExport={handleExport}
//         isSubmitting={isSubmitting}
//         onReset={() => setFilter({})}
//       />

//       <EmptyWrapper queryResult={queryResult} isStateLoading={isStateLoading}>
//         <PaginatorWrapper.Top
//           filter={filter}
//           reload={reload}
//           data={queryResult as any}
//           pathPrefix={`${pathPrefix}`}
//         />
//         <Segment>
//           <ListTable
//             data={resolvedQueryResult}
//             definition={tableDefinition}
//             selectedItems={selectedItems}
//             setChecked={setChecked}
//             sort={sort}
//             sortClickFunction={handleSort}
//             toggleAll={toggleAll}
//             withCheckboxes={true}
//           />
//         </Segment>

//         <PaginatorWrapper.Bottom reload={reload} data={queryResult as any} pathPrefix={pathPrefix}>
//           {someSelected && <Button primary type="button" content="Delete" onClick={handleDelete} />}
//         </PaginatorWrapper.Bottom>

//         {exportUrl && (
//           <ExportModal
//             isModalOpen={isModalOpen}
//             exportUrl={exportUrl}
//             handleClose={() => {
//               setIsModalOpen(false)
//               setExportUrl('')
//             }}
//           />
//         )}
//       </EmptyWrapper>
//     </div>
//   )
// }

import React, { useState } from 'react';

import { Link, RouteComponentProps } from 'react-router-dom';
import { FormattedDate, FormattedTime } from 'react-intl';
import { Button, Box, IconButton, HStack, Tag, Tooltip, ButtonGroup } from '@chakra-ui/react';

import { FaEdit, FaTrash } from 'react-icons/fa';

import {
  useList,
  ListPage,
  basicQueryMap,
  generateOperatorOptions,
  Operator,
} from '@inkcloud/components';

import feathers from '../../../bootstrap/feathers';

import { ExportModal } from '../../reports/ExportModal';

interface ListProps {}

const serviceName = 'users';
const pathPrefix = '/settings/team-members';

const filterQueryMap = (values) => basicQueryMap(values, {});

export async function getFilterDefinition() {
  const definition = [
    {
      label: 'First Name',
      value: 'firstName',
      operators: generateOperatorOptions([Operator.Like]),
    },
    {
      label: 'Last Name',
      value: 'lastName',
      operators: generateOperatorOptions([Operator.Like]),
    },
    {
      label: 'Email',
      value: 'email',
      operators: generateOperatorOptions([Operator.Like]),
    },
  ];

  return definition.sort((a: any, b: any) => a.label.localeCompare(b.label));
}

export default function List(props: ListProps & RouteComponentProps) {
  const {
    match: { params },
    location,
    history,
  } = props;

  const [isSubmitting, setSubmitting] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [exportUrl, setExportUrl] = useState('');

  const handleExport = async () => {
    setSubmitting(true);

    try {
      const res: any = await feathers.service('report/v2/team-export?$export=1').create({
        all: true,
      });

      if (res) {
        setIsModalOpen(true);
        setExportUrl(res.url);
      }
    } catch (e) {
      console.error(e);
    }

    setSubmitting(false);
  };

  const useListValues = useList({
    feathers: [
      'find',
      serviceName,
      [
        {
          query: {
            $populate: ['roles2'],
            vendor: { $exists: false },
          },
        },
      ],
    ],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  const tableDefinition = [
    {
      key: 'createdAt',
      label: 'Date',
      sortable: true,
      cell: (v) => (
        <Tooltip hasArrow aria-label="time" label={<FormattedTime value={v} />}>
          <Box as="span">
            <FormattedDate value={v} />
          </Box>
        </Tooltip>
      ),
    },
    {
      key: 'firstName',
      label: 'First Name',
      cell: (v) => v,
    },
    {
      key: 'lastName',
      label: 'Last Name',
      cell: (v) => v,
    },
    {
      key: 'email',
      label: 'Email',
      cell: (v) => v,
    },
    {
      key: 'roles2',
      label: 'Roles',
      cell: (v, r) => (
        <HStack spacing={1}>
          {v?.map((role, index) => (
            <Tag size="sm" key={index} variant="solid">
              {role?.name ?? ''}
            </Tag>
          ))}
        </HStack>
      ),
    },
    {
      key: '',
      label: '',
      cell: (v, r) => (
        <Tooltip label="Edit Details">
          <Link to={`${pathPrefix}/${r._id}`}>
            <IconButton colorScheme="gray" aria-label="Edit" icon={<FaEdit />} />
          </Link>
        </Tooltip>
      ),
      textAlign: 'right',
    },
  ];

  const headerActions = (
    <ButtonGroup spacing={1}>
      <Button
        isDisabled={isSubmitting}
        isLoading={isSubmitting}
        type="button"
        colorScheme="blue"
        size="sm"
        onClick={handleExport}
      >
        Export All
      </Button>
      <Button type="button" size="sm" onClick={() => history.goBack()}>
        Back
      </Button>
      <Link to={`${pathPrefix}/add`}>
        <Button size="sm" colorScheme="blue">
          New
        </Button>
      </Link>
    </ButtonGroup>
  );

  return (
    <div>
      <ListPage
        header={'Team Members'}
        subheader="Settings"
        useListValues={useListValues}
        history={history}
        pathPrefix={pathPrefix}
        tableDefintion={tableDefinition}
        withCheckboxes={true}
        headerActions={headerActions}
        getFilterDefinition={getFilterDefinition}
      />

      {exportUrl && (
        <ExportModal
          isModalOpen={isModalOpen}
          exportUrl={exportUrl}
          handleClose={() => {
            setIsModalOpen(false);
            setExportUrl('');
          }}
        />
      )}
    </div>
  );
}
