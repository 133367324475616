import React from 'react';

import {
  Grid as GridChakra,
  Button,
  GridItem,
  Box,
  Alert,
  Flex,
  Heading,
  Text,
  FormControl,
  Checkbox,
  Spacer,
} from '@chakra-ui/react';

import type { ModelTypes } from '@inkcloud/icapi-types';

import feathers from '../../../bootstrap/feathers';
import { LineItemGrid, randomGenerator } from '../../sales-orders/SalesOrder/LineItemGrid';
import { VendorPicker, IVendorPickerProps } from './VendorPicker';
import { BillTo, ShipTo } from '../../sales-orders/SalesOrder/AddressComponents';
import { IBillTo, IShipTo } from '../../sales-orders/SalesOrder/primitives';
import { initMapper, calculateSummary } from '../../sales-orders/SalesOrder/helpers';

import { AddNote } from '../../../common';
import Note from './Note';

import Info from './Info';
import EditableInfo from './Editables/EditableInfo';

const emtpyAddress = {
  address1: '',
  address2: '',
  city: '',
  stateProvince: '',
  postalCode: '',
  countryCode: '',
};

const emptyBillTo = {
  company: '',
  name: '',
  ...emtpyAddress,
};

const emptyShipTo = {
  name: '',
  careOf: '',
  ...emtpyAddress,
};

export type PurchaseOrderType = ModelTypes.PurchaseOrder & {
  jobNumber?: string;
  costCenter?: string;
  // clientPO?: string
  attn?: string;
};

const statusOptions = [
  { label: 'Draft', value: 'draft' },
  { label: 'Pending Approval', value: 'pending-approval' },
  { label: 'Rejected', value: 'rejected' },
  { label: 'Open', value: 'open' },
  { label: 'Partial Received', value: 'partial-received' },
  { label: 'Received', value: 'received' },
];

export interface IPurchaseOrderProps {
  user?: any;
  initialValues: any;
  errorMessage: string;
  onReload?: () => void;
  onSubmit?: (v) => void;
}

export function PurchaseOrder(props: IPurchaseOrderProps) {
  const { user, errorMessage, initialValues, onSubmit, onReload } = props;
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isNoteSubmitting, setIsNoteSubmitting] = React.useState(false);
  const [lineItems, setLineItems] = React.useState(initMapper(initialValues, 'items', 'sales'));
  const [vendor, setVendor] = React.useState({
    vendor: initialValues?.vendor?._id,
    vendorData: {
      ...(initialValues?.vendor || {}),
    },
  });
  const [vendors, setVendors] = React.useState([]);
  const [users, setUsers] = React.useState<{ label: string; value: string }[]>([]);
  const [billTo, setBillTo] = React.useState<IBillTo>(emptyBillTo);
  const [shipTo, setShipTo] = React.useState<IShipTo>(emptyShipTo);
  const [otherInfo, setOtherInfo] = React.useState<PurchaseOrderType>({
    csr: initialValues?.csr,
    rfqNum: initialValues?.rfqNum,
    salesOrderNum: initialValues?.salesOrderNum,
    projectName: initialValues?.projectName,
    notesToVendor: initialValues.notesToVendor,
    poDueDate: initialValues?.poDueDate,
    soShipDate: initialValues?.soShipDate,
    status: initialValues?.status,
    foreignId: initialValues?.foreignId,
    createdAt: initialValues?.createdAt,
    // clientPO: initialValues?.clientPO,
    attn: initialValues?.attn,
    costCenter: initialValues?.costCenter,
    jobNumber: initialValues?.jobNumber,
  });

  const [shouldRequestVendorAcknowledge, setShouldRequestVendorAcknowledge] = React.useState(false);

  // modal
  const [isOpenModal, setIsOpenModal] = React.useState({
    note: false,
    info: false,
  });

  const [errorMessages, setErrorMessages] = React.useState({
    note: '',
  });

  const [glAccounts, setGlAccounts] = React.useState([]);

  React.useEffect(() => {
    setOtherInfo({
      csr: initialValues?.csr,
      rfqNum: initialValues?.rfqNum,
      salesOrderNum: initialValues?.salesOrderNum ?? '',
      projectName: initialValues?.projectName,
      notesToVendor: initialValues.notesToVendor,
      poDueDate: initialValues?.poDueDate,
      soShipDate: initialValues?.soShipDate,
      status: initialValues?.status,
      foreignId: initialValues?.foreignId,

      costCenter: initialValues?.costCenter,
      jobNumber: initialValues?.jobNumber,
    });
  }, [initialValues?._id]);

  React.useEffect(() => {
    (async () => {
      const res: any = await feathers.service('general-ledger-accounts').find({
        query: {
          accountType: 'cost-of-goods',
        },
      });

      setGlAccounts(
        res?.data?.map((d) => ({ label: `${d.accountNumber} ${d.name} `, value: d._id }))
      );
    })();

    const loadUsers = async () => {
      const results = await feathers
        .service('users')
        .find({ query: { $np: 1, $sort: { firstName: 1 } } });
      setUsers(results.map((m) => ({ label: `${m.firstName} ${m.lastName}`, value: m._id })));

      setOtherInfo({
        ...otherInfo,
        csr: results?.find((r) => r._id === user?._id)?._id ?? '',
      });
    };

    const loadVendors = async () => {
      const results = await feathers
        .service('production/vendors')
        .find({ query: { $np: 1, $sort: { companyName: 1 } } });
      setVendors(results.map((m) => ({ ...m, label: m.companyName, value: m._id })));
    };

    const loadTenantSettings = async () => {
      const results = await feathers
        .service('tenant-settings')
        .get(user.business, { query: { $select: ['publishedContactInfo'] } });

      const {
        publishedContactInfo: { address, businessName },
      } = results;
      const p = {
        name: businessName,
        address1: address?.address1,
        address2: address?.address2,
        city: address?.city,
        stateProvince: address?.stateProvince,
        postalCode: address?.postalCode,
        countryCode: address?.countryCode,
      };
      setBillTo(p);
      setShipTo(p);
    };

    if (!initialValues?._id) {
      loadTenantSettings();
    } else {
      setBillTo(initialValues.billTo);
      setShipTo(initialValues.shipTo);
    }
    loadVendors();
    loadUsers();
  }, []);

  React.useEffect(() => {
    setLineItems(initMapper(initialValues, 'items', 'purchase'));
  }, [initialValues]);

  const loadProducts = async (inputValue) => {
    const baseQuery = {
      $np: 1,
      $select: [
        'key',
        'staticName',
        'glExpenseAccount',
        'prices',
        'price',
        'cost',
        'inventory',
        'inventoryCount',
      ],
      key: { $LIKE: inputValue },
      $sort: { key: 1 },
    };
    const results = await feathers.service('products-legacy').find({ query: { ...baseQuery } });
    return results.map((m) => ({
      label: m.key,
      value: m._id,
      name: m.staticName,
      glAccount: m?.glExpenseAccount,
      prices: m?.prices,
      amount: Number.parseFloat(m?.price || m?.cost || 0).toFixed(4),
      isStock: m?.inventory,
      inventoryCount: m?.inventoryCount,
    }));
  };

  const handlePickedVendor = (v: IVendorPickerProps['value'] & { vendorData: any }) => {
    setVendor({
      vendor: v.vendor,
      vendorData: {
        ...(v?.vendorData || {}),
      },
    });
  };

  const handleAddLine = () =>
    setLineItems(
      lineItems.concat({
        id: randomGenerator(),
        amount: 0,
        quantity: 0,
        total: 0,
        description: '',
        product: '',
      })
    );
  const isVendorSelected = !!vendor;
  const summary = calculateSummary(lineItems);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const payload = {
      vendor: vendor?.vendor,
      billTo,
      shipTo,
      shouldRequestVendorAcknowledge,
      items:
        lineItems?.map((i) => ({
          ...i,
          product: i?.product?.product || i?.product?.value,
          glAccount: i?.product?.glAccount || i?.glAccount,
        })) ?? [],
      ...(otherInfo || {}),
      salesOrderNum: otherInfo?.salesOrderNum || undefined,
      status:
        lineItems?.length > initialValues?.items?.length ? 'open' : otherInfo?.status || undefined,
    };
    onSubmit?.(payload);
  };

  const handleNoteSubmit = async (value) => {
    setErrorMessages({ ...errorMessages, note: '' });
    setIsNoteSubmitting(true);
    try {
      await feathers.service('purchase-orders/notes').create({
        purchaseOrderId: initialValues?._id,
        content: value,
      });

      setIsOpenModal({
        ...isOpenModal,
        note: false,
      });

      onReload?.();
    } catch (e) {
      const errMsg =
        e.code && e.code < 500
          ? e.message
          : 'We are experiencing technical difficulties. Please try again';
      setErrorMessages({ ...errorMessages, note: errMsg });
    }
    setIsNoteSubmitting(false);
  };

  const handleInfoSubmit = async (values) => {
    setErrorMessages({ ...errorMessages, note: '' });
    // setIsNoteSubmitting(true)
    try {
      await feathers.service('purchase-orders').patch(initialValues?._id, {
        ...values,
      });

      // setIsOpenModal({
      //   ...isOpenModal,
      //   note: false,
      // })

      onReload?.();
    } catch (e) {
      const errMsg =
        e.code && e.code < 500
          ? e.message
          : 'We are experiencing technical difficulties. Please try again';
      setErrorMessages({ ...errorMessages, note: errMsg });
    }
    // setIsNoteSubmitting(false)
  };

  // const status = statusOptions?.find((u) => u?.value === otherInfo?.status)?.label

  // const hasError = lineItems?.find((l) => !l.glAccount)

  return (
    <>
      <GridChakra templateColumns={{ lg: 'repeat(3, 1fr)' }} gap={8}>
        <GridItem colSpan={{ lg: 2 }}>
          <Box mb={8}>
            <GridChakra templateColumns={{ lg: 'repeat(2, 1fr)' }} gap={8}>
              <GridItem>
                <BillTo value={billTo} onChange={setBillTo} />
              </GridItem>
              <GridItem>
                <ShipTo value={shipTo} onChange={setShipTo} />
              </GridItem>
            </GridChakra>
          </Box>
          <Box mb={8}>
            <Info
              info={{
                ...otherInfo,
                createdFromRfq: initialValues?.createdFromRfq,
                csr: users?.find((u) => u?.value === otherInfo?.csr)?.label,
              }}
              onEditClick={() =>
                setIsOpenModal({
                  ...isOpenModal,
                  info: true,
                })
              }
            />

            {isOpenModal.info && (
              <EditableInfo
                info={otherInfo}
                usersOptions={users}
                onChange={(v) => {
                  setOtherInfo(v);
                  handleInfoSubmit(v);
                }}
                onClose={() =>
                  setIsOpenModal({
                    ...isOpenModal,
                    info: false,
                  })
                }
              />
            )}
          </Box>
        </GridItem>
        <GridItem colSpan={{ lg: 1 }}>
          <Note
            title="Notes"
            subTitle="Internal Notes"
            notes={initialValues?.privateNotes ?? []}
            onOpenNoteModal={() =>
              setIsOpenModal({
                ...isOpenModal,
                note: true,
              })
            }
          />
          {isOpenModal.note && (
            <AddNote
              onSubmit={handleNoteSubmit}
              errorMessage={errorMessages?.note}
              isSubmitting={isNoteSubmitting}
              onClose={() =>
                setIsOpenModal({
                  ...isOpenModal,
                  note: false,
                })
              }
            />
          )}
        </GridItem>
      </GridChakra>
      <Box flex={1} mb={8} borderWidth={1} borderColor={'gray.300'}>
        <Flex flex={1} align={'center'} py={4} px={6} bgColor={'gray.100'}>
          <Box>
            <Heading size="sm">Vendor</Heading>
            <Text fontSize={'sm'}>Vendor's Info</Text>
          </Box>
        </Flex>
        <Box p={4}>
          <GridChakra templateColumns={{ lg: 'repeat(4, 1fr)' }} gap={8}>
            <GridItem>
              <VendorPicker
                style={{}}
                value={vendor}
                onChange={(v) => handlePickedVendor(v)}
                vendors={vendors}
              />
            </GridItem>
            <GridItem>
              <Text fontSize={'xs'}>Phone: {initialValues?.vendor?.phone}</Text>
            </GridItem>
            <GridItem>
              <Text fontSize={'xs'}>Email: {initialValues?.vendor?.email}</Text>
            </GridItem>
            <GridItem>
              <FormControl>
                <Checkbox
                  type="email"
                  defaultChecked={initialValues?.shouldRequestVendorAcknowledge}
                  onChange={(e) => setShouldRequestVendorAcknowledge(e.target.checked)}
                >
                  Request Vendor Acknowledge
                </Checkbox>
              </FormControl>
            </GridItem>
          </GridChakra>
        </Box>
      </Box>

      <Box flex={1} mb={8} borderWidth={1} borderColor={'gray.300'}>
        <Flex flex={1} align={'center'} py={4} px={6} bgColor={'gray.100'}>
          <Box>
            <Heading size="sm">Items</Heading>
            <Text fontSize={'sm'}>Line Items</Text>
          </Box>
          <Spacer />
          <Button
            isDisabled={!isVendorSelected || (isSubmitting && !errorMessage)}
            // isDisabled={!isVendorSelected || (isSubmitting && !errorMessage) || !!hasError}
            isLoading={isSubmitting && !errorMessage}
            colorScheme="blue"
            onClick={handleSubmit}
          >
            Save
          </Button>
        </Flex>
        <Box pt={6}>
          <LineItemGrid
            isTaxableDefault={false}
            lineItems={lineItems}
            isGeneralLedgerHidden={true}
            onChange={(v) => {
              setLineItems(v);
            }}
            productLoad={loadProducts}
            glAccounts={glAccounts}
            isDisabled={!isVendorSelected}
            hideTax={true}
            summary={summary}
            onAddLine={handleAddLine}
          />
        </Box>
      </Box>

      {errorMessage && <Alert status="error">{errorMessage}</Alert>}
    </>
  );
}
