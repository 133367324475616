import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Segment } from 'semantic-ui-react';
import { PageHeaderRow, PreLoader, useFeathers } from '@inkcloud/components';
import feathers from '../../../bootstrap/feathers';
import { StarterTemplatesForm } from './StarterTemplatesForm';

interface DetailProps {}

const pathPrefix = `/settings/starter-templates`;
const serviceName = '/starter-templates';
const service = feathers.service(serviceName);
const commonParams = { query: { $populate: ['upload'] } };

const { useState } = React;

export const Detail: React.FunctionComponent<DetailProps & RouteComponentProps<{ id?: string }>> = (
  props
) => {
  const {
    match: {
      params: { id },
    },
    history,
  } = props;

  const [errorMessage, setErrorMessage] = useState('');

  const isEdit = !!id;
  const [state, reload] = useFeathers('get', serviceName, [id, commonParams], true);

  const handleSubmit = async (values) => {
    let payload: any = {
      tenant: values.tenant,
      products: values.products,
      desc: values.desc,
      fileType: values.fileType,
      finishSize: {
        width: values.finishSize.width,
        height: values.finishSize.height,
      },
      flatSize: {
        width: values?.width ?? 0,
        height: values?.height ?? 0,
      },
      upload: values?.upload ?? '',
    };

    if (values.sameAsFinish) {
      payload.flatSize = {
        width: values.finishSize.width,
        height: values.finishSize.height,
      };
    }

    try {
      isEdit
        ? await service.patch(id, payload, commonParams)
        : await service.create(payload, commonParams);

      history.push(pathPrefix);
    } catch (e) {
      e.code && e.code < 500
        ? setErrorMessage(e.message)
        : setErrorMessage('We are experiencing technical difficulties. Please try again');
    }
  };

  const pageTitle = isEdit ? 'Edit' : 'New';

  if (isEdit && !state.data) {
    return <PreLoader />;
  }

  const initialData = { ...state.data };
  return (
    <div>
      <PageHeaderRow
        header={`${pageTitle} Starter Templates`}
        subheader="Settings"
        subheaderId={''}
      >
        <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
      </PageHeaderRow>
      <Segment>
        {(!isEdit || state.data) && (
          <StarterTemplatesForm
            initialValues={initialData}
            errorMessage={errorMessage}
            onSubmit={handleSubmit}
          />
        )}
      </Segment>
    </div>
  );
};
