import * as React from 'react';
import { Button, Form, Message, Dropdown, Divider } from 'semantic-ui-react';
import { Formik, Form as FormikForm, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import countries from 'world-countries';
import { useSelector } from 'react-redux';
import { SemanticField } from '../../../../common/Form';
import { countryStateProvinces } from '../../../../common';

interface VendorFormProps {
  initialValues: any;
  onSubmit: Function;
  isSubmitting?: boolean;
}

const CustomerContactSchema = Yup.object().shape({
  companyName: Yup.string().required('Required'),
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  address1: Yup.string().required('Required'),
  city: Yup.string().required('Required'),
  countryCode: Yup.string().required('Required'),
  stateProvince: Yup.string().required('Required'),
  postalCode: Yup.string().required('Required'),
  phone: Yup.string().required('Required'),
  email: Yup.string().required('Required'),
});

const initialMapper = (values) => ({
  companyName: values?.companyName ?? '',
  firstName: values?.firstName ?? '',
  lastName: values?.lastName ?? '',
  address1: values?.address1 ?? '',
  city: values?.city ?? '',
  countryCode: values?.countryCode ?? '',
  stateProvince: values?.stateProvince ?? '',
  postalCode: values?.postalCode ?? '',
  phone: values?.phone ?? '',
  email: values?.email ?? '',
  tags: values?.tags ?? [],
  foreignId: values?.foreignId ?? '',
  terms: values?.terms ?? '',
  ...values,
});

export const VendorForm: React.FC<VendorFormProps> = (props) => {
  const tenant = useSelector(
    ({ globals }: { globals: { tenant: { internationalization: { countryCode: string } } } }) =>
      globals.tenant.internationalization
  );

  return (
    <Formik
      initialValues={initialMapper({
        ...props.initialValues,
        countryCode: props.initialValues?.countryCode ?? tenant.countryCode,
      })}
      validationSchema={CustomerContactSchema}
      onSubmit={(values, { setSubmitting }) => {
        props.onSubmit(values);
      }}
    >
      {(innerProps) => {
        const {
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
          setFieldValue,
          setFieldTouched,
        } = innerProps;

        const handleAddition = (e, data: any) => {
          const foundTag = values?.tags?.find((tag) => tag === data.value);

          if (!foundTag) {
            setFieldValue('tags', [...(values.tags || []), data.value]);
          }
        };

        return (
          <FormikForm className={`ui form ${isSubmitting && 'loading'}`}>
            <Form.Group widths="equal">
              <Form.Field>
                <SemanticField name={'companyName'} label="Company Name" component={Form.Input} />
                <ErrorMessage
                  render={(msg) => <Message negative size="tiny" content={msg} />}
                  name="companyName"
                />
              </Form.Field>

              <Form.Field>
                <SemanticField name={'firstName'} label="First Name" component={Form.Input} />
                <ErrorMessage
                  render={(msg) => <Message negative size="tiny" content={msg} />}
                  name="firstName"
                />
              </Form.Field>

              <Form.Field>
                <SemanticField name={'lastName'} label="Last Name" component={Form.Input} />
                <ErrorMessage
                  render={(msg) => <Message negative size="tiny" content={msg} />}
                  name="lastName"
                />
              </Form.Field>
            </Form.Group>
            <Divider section />
            <Form.Group widths="equal">
              <Form.Field>
                <SemanticField name={'email'} label="Email" component={Form.Input} />
                <ErrorMessage
                  render={(msg) => <Message negative size="tiny" content={msg} />}
                  name="email"
                />
              </Form.Field>
              <Form.Field>
                <SemanticField name={'phone'} label="Phone" component={Form.Input} />
                <ErrorMessage
                  render={(msg) => <Message negative size="tiny" content={msg} />}
                  name="phone"
                />
              </Form.Field>
            </Form.Group>

            <Divider section />

            <Form.Group widths="equal">
              <Form.Field>
                <SemanticField
                  name="countryCode"
                  label="Country"
                  selection
                  search
                  component={Form.Dropdown}
                  options={
                    countries?.map((c: any) => ({ text: c.name.official, value: c.cca2 })) ?? []
                  }
                  fluid
                />
                <ErrorMessage
                  render={(msg) => <Message negative size="tiny" content={msg} />}
                  name="countryCode"
                />
              </Form.Field>
            </Form.Group>

            <Form.Group widths="equal">
              <Form.Field>
                <SemanticField name={'address1'} label="Address 1" component={Form.Input} />
                <ErrorMessage
                  render={(msg) => <Message negative size="tiny" content={msg} />}
                  name="address1"
                />
              </Form.Field>
              <Form.Field>
                <SemanticField name={'address2'} label="Address 2" component={Form.Input} />
                <ErrorMessage
                  render={(msg) => <Message negative size="tiny" content={msg} />}
                  name="address2"
                />
              </Form.Field>
            </Form.Group>

            <Form.Group widths="equal">
              <Form.Field>
                <SemanticField name={'city'} label="City" component={Form.Input} />
                <ErrorMessage
                  render={(msg) => <Message negative size="tiny" content={msg} />}
                  name="city"
                />
              </Form.Field>

              <Form.Field>
                {values?.countryCode === 'US' ||
                values?.countryCode === 'CA' ||
                values?.countryCode === 'AU' ? (
                  <>
                    <label>
                      {values?.countryCode === 'AU' ? 'State/Territory' : 'State/Province'}
                    </label>
                    <Dropdown
                      name={'stateProvince'}
                      options={countryStateProvinces(values?.countryCode)}
                      fluid
                      selection
                      value={values?.stateProvince ?? ''}
                      onChange={(e, data) => setFieldValue(data.name, data.value)}
                    />
                  </>
                ) : (
                  <SemanticField
                    name={'stateProvince'}
                    component={Form.Input}
                    fluid
                    label="State/Province"
                    placeholder="State/Province..."
                  />
                )}
                <ErrorMessage
                  render={(msg) => <Message negative size="tiny" content={msg} />}
                  name="stateProvince"
                />
              </Form.Field>
              <Form.Field>
                <SemanticField name={'postalCode'} label="Postal Code" component={Form.Input} />
                <ErrorMessage
                  render={(msg) => <Message negative size="tiny" content={msg} />}
                  name="postalCode"
                />
              </Form.Field>
            </Form.Group>

            <Form.Field>
              <SemanticField name={'foreignId'} label="Foreign ID" component={Form.Input} />
              <ErrorMessage
                render={(msg) => <Message negative size="tiny" content={msg} />}
                name="foreignId"
              />
            </Form.Field>

            <Form.Field>
              <SemanticField name={'terms'} label="Terms" component={Form.Input} />
              <ErrorMessage
                render={(msg) => <Message negative size="tiny" content={msg} />}
                name="terms"
              />
            </Form.Field>

            <Form.Field>
              <SemanticField
                name={'tags'}
                label="Tags"
                component={Form.Dropdown}
                selection
                search
                clearable
                multiple
                allowAdditions
                options={
                  values?.tags?.map((tag: string) => ({ text: tag, value: tag, key: tag })) || []
                }
                onAddItem={handleAddition}
                noResultsMessage="Not found"
              />
            </Form.Field>

            <Button primary type="submit" disabled={isSubmitting}>
              Save
            </Button>
          </FormikForm>
        );
      }}
    </Formik>
  );
};
