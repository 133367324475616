import * as React from 'react';
import { Button, Segment, Table, Popup, Grid, Icon } from 'semantic-ui-react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { FormattedDate, FormattedTime } from 'react-intl';
import { humanize } from 'underscore.string';
import {
  useList,
  ListPage,
  basicQueryMap,
  generateOperatorOptions,
  Operator,
} from '@inkcloud/components';

interface ListProps {}

const serviceName = '/custom-quotes';
const pathPrefix = `/customers/custom-quotes`;

const filterQueryMap = (values) => {
  return basicQueryMap(values, {});
};

export const hasMetadataLang = (item: any) =>
  item?.metadata?.find((meta) => meta.key === 'tr_source_name') ?? '';

export async function getFilterDefinition() {
  let definition = [
    {
      label: 'Date:Created',
      value: 'createdAt',
      operators: generateOperatorOptions([Operator.Between]),
      type: 'date',
    },
    {
      label: 'Date:Updated',
      value: 'updatedAt',
      operators: generateOperatorOptions([Operator.Between]),
      type: 'date',
    },
  ];

  return definition.sort((a: any, b: any) => a.label.localeCompare(b.label));
}

export const List: React.FunctionComponent<ListProps & RouteComponentProps<any>> = (props) => {
  const {
    match: { params },
    location,
    history,
  } = props;

  const tableDefinition = [
    {
      key: 'humanId',
      label: 'ID',
      cell: (v) => v,
    },
    {
      key: 'createdAt',
      label: 'Date',
      sortable: true,
      cell: (v) =>
        v && (
          <Popup
            size="mini"
            content={<FormattedTime value={v} />}
            trigger={
              <div>
                <FormattedDate value={v} />
              </div>
            }
          />
        ),
    },
    {
      key: 'customer',
      label: 'Customer',
      sortable: true,
      cell: (v, r) => (
        <Link to={r.customer?._id ?? ''}>
          {r.customer?.primaryContact?.firstName ?? ''} {r.customer?.primaryContact?.lastName ?? ''}
        </Link>
      ),
    },
    {
      key: 'status',
      label: 'Status',
      sortable: true,
      cell: (v, r) => <>{humanize(v)}</>,
    },
    {
      key: '',
      label: '',
      sortable: false,
      cell: (v, r) => (
        <Popup
          size="mini"
          content="Edit"
          trigger={
            <Link to={`${pathPrefix}/${r._id}`}>
              <Button size={'mini'} icon={'edit'} />
            </Link>
          }
        />
      ),
      textAlign: 'right',
    },
  ];

  const useListValues = useList({
    feathers: [
      'find',
      serviceName,
      [
        {
          query: {
            $populate: [
              'items.uploadedAttachments',
              'items.messageThread.attachments',
              'messageThread.attachments',
              'customer',
            ],
          },
        },
      ],
    ],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  const headerActions = (
    <>
      <Link to="/customers/custom-quotes/add">
        <Button content="New" primary size={'mini'} />
      </Link>
    </>
  );

  return (
    <div>
      <ListPage
        header="Custom Quotes"
        subheader="List"
        useListValues={useListValues}
        history={history}
        pathPrefix={pathPrefix}
        tableDefintion={tableDefinition}
        withCheckboxes={false}
        headerActions={headerActions}
        getFilterDefinition={getFilterDefinition}
      />
    </div>
  );
};
