import React, { useEffect, useState } from 'react';
import { Mode, useForm } from 'react-hook-form';
import {
  Radio,
  RadioGroup,
  Input,
  Button as ChakraButton,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Stack,
  Flex,
  Textarea,
  Alert,
} from '@chakra-ui/react';
import { Button, Form, Segment, Dropdown, Message, Menu } from 'semantic-ui-react';
import { Formik, Form as FormikForm, Field, FieldArray, FieldProps, ErrorMessage } from 'formik';
import Cleave from 'cleave.js/react';
import { useSelector } from 'react-redux';
import type { Option } from 'react-select';
import { Select } from 'chakra-react-select';
import DatePicker from 'react-datepicker';
import type { ModelTypes } from '@inkcloud/icapi-types';
// import moment from 'moment';
import { format } from 'date-fns';

import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { SemanticField } from '../../../../common/Form';
// import { FeathersDropdown } from '@inkcloud/components';
import { Feathers } from '../../../../bootstrap/feathers/feathers';
import { InputWrapper, currencySymbol } from '../../../../common';

interface OrderLimitIncreaseFormProps {
  microsite: string;
  initialValues: any;
  onSubmit: (v: any) => void;
  isSubmitting?: boolean;
  errorMessage: string;
}

// const { useState, useEffect } = React;
const OrderLimitIncreaseSchema = Yup.object().shape({
  // product: Yup.string().when('prodType', {
  //   is: (value) => value === 'single',
  //   then: Yup.string().required('Required'),
  // }),
  product: Yup.string(),
  products: Yup.array().of(Yup.string()),
  amount: Yup.string().required('Required'),
  startDate: Yup.string().required('Required').typeError('Required'),
  endDate: Yup.string().required('Required').typeError('Required'),
});

type TypeMSProducts = Omit<ModelTypes.MicrositeProducts, 'product'> & {
  product: ModelTypes.ProductLegacy;
};

export default function OrderLimitIncreaseForm2(props: OrderLimitIncreaseFormProps) {
  const { microsite, initialValues, isSubmitting, errorMessage, onSubmit } = props;

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(OrderLimitIncreaseSchema),
  });

  const [products, setProducts] = useState<TypeMSProducts[]>([]);

  useEffect(() => {
    const loadProducts = async () => {
      try {
        const res = await Feathers.service('/microsites/products').find({
          query: {
            $np: 1,
            $sort: { name: 1 },
            $select: ['name', 'key', 'product'],
            $populate: ['product'],
            microsite,
          },
        });
        // remove this filter, not applicabl ebecause of order item limits
        // const filteredProducts = res?.filter((prod) => prod?.product?.orderLimitsPerUser?.value);
        setProducts(
          res?.map((d) => ({
            value: d?.product?._id,
            label: `${d?.name ?? ''} [${d?.key ?? '  '}]`,
            product: d?.product,
          }))
        );
      } catch (err) {
        console.error(err);
      }
    };

    loadProducts();
  }, []);

  const tenant = useSelector(
    ({ globals }: { globals: { tenant: { currency; locale; countryCode: string } } }) =>
      globals.tenant
  );

  const watchProdType = watch('prodType');
  const watchType = watch('type');
  const watchStartDate = watch('startDate');
  const watchEndDate = watch('endDate');

  return (
    <form
      onSubmit={handleSubmit((values) => {
        if (watchProdType === 'all') {
          const mappedProducts = products?.map((prod) => ({
            ...values,
            product: prod?.product?._id,
            amount:
              values?.type === 'relative'
                ? (prod?.product?.orderLimitsPerUser?.value || 0) * values?.amount
                : values?.amount,
          }));

          onSubmit(mappedProducts);
        } else if (watchProdType === 'order-items') {
          values.product = undefined;
          onSubmit([values]);
        } else {
          const found = products?.find((p) => p?.product?._id === values.product);
          const amount =
            values?.type === 'relative'
              ? (found?.product?.orderLimitsPerUser?.value || 0) * values?.amount
              : values?.amount;


          onSubmit([{ ...values, amount }]);
        }
      })}
    >
      <Stack direction="row" mb={3}>
        <FormControl>
          <FormLabel>Product Type</FormLabel>
          <RadioGroup onChange={(e) => setValue('prodType', e)} value={watchProdType}>
            <Stack direction="row">
              <Radio value="single">Single Product</Radio>
              <Radio value="all">All Products</Radio>
              <Radio value="order-items">Order Items</Radio>
            </Stack>
          </RadioGroup>
        </FormControl>
        <FormControl>
          <FormLabel>Type</FormLabel>
          <RadioGroup onChange={(e) => setValue('type', e)} value={watchType}>
            <Stack direction="row">
              <Radio value="absolute">Absolute</Radio>
              <Radio value="relative">Relative</Radio>
            </Stack>
          </RadioGroup>
        </FormControl>
      </Stack>

      <Stack direction="row" mb={3}>
        {watchProdType === 'single' && (
          <>
          <FormControl isInvalid={!!errors.product}>
            <FormLabel>Product (Deprecate)</FormLabel>
            <Select
              useBasicStyles
              isClearable
              isSearchable
              placeholder=""
              options={products}
              onChange={(e: Option) => setValue('product', e?.value)}
            />
            {errors?.product && (
              <FormErrorMessage>{errors?.product?.message?.toString()}</FormErrorMessage>
            )}
          </FormControl>
          <FormControl isInvalid={!!errors.products}>
            <FormLabel>Products</FormLabel>
            <Select
              isMulti
              useBasicStyles
              isClearable
              isSearchable
              placeholder=""
              options={products}
              onChange={(e: Option) => {
                setValue('products', e.map((p) => p.value))
              }}
            />
            {errors?.products && (
              <FormErrorMessage>{errors?.products?.message?.toString()}</FormErrorMessage>
            )}
          </FormControl>
          </>
        )}
        <FormControl isInvalid={!!errors?.amount}>
          <FormLabel>Amount</FormLabel>
          <InputWrapper height="var(--chakra-sizes-9)">
            <Cleave
              style={{ textAlign: 'right' }}
              className={'form-control-cleave'}
              onChange={(e) => setValue('amount', Number(e.target.rawValue) || 0)}
              options={{
                numeral: true,
                rawValueTrimPrefix: true,
                numeralPositiveOnly: true,
                numeralThousandsGroupStyle: 'thousand',
                prefix: currencySymbol(tenant?.currency, tenant?.locale),
              }}
            />
          </InputWrapper>
          {errors?.amount && (
            <FormErrorMessage>{errors?.amount?.message?.toString()}</FormErrorMessage>
          )}
        </FormControl>
      </Stack>
      <Stack direction="row" mb={3}>
        <FormControl isInvalid={!!errors.startDate}>
          <FormLabel>Start Date</FormLabel>
          <DatePicker
            dateFormat="MM/dd/yyyy"
            autoComplete="off"
            selected={watchStartDate}
            onChange={(date) => setValue('startDate', date)}
          />
          {errors?.startDate && (
            <FormErrorMessage>{errors?.startDate?.message?.toString()}</FormErrorMessage>
          )}
        </FormControl>
        <FormControl isInvalid={!!errors.endDate}>
          <FormLabel>End Date</FormLabel>
          <DatePicker
            dateFormat="MM/dd/yyyy"
            autoComplete="off"
            selected={watchEndDate}
            onChange={(date) => setValue('endDate', date)}
          />
          {errors?.endDate && (
            <FormErrorMessage>{errors?.endDate?.message?.toString()}</FormErrorMessage>
          )}
        </FormControl>
      </Stack>
      <FormControl mb={3}>
        <FormLabel>Notes</FormLabel>
        <Textarea {...register('notes')}></Textarea>
      </FormControl>
      {errorMessage && (
        <Alert status="error" mb={3}>
          {errorMessage}
        </Alert>
      )}
      <ChakraButton
        type="submit"
        colorScheme="blue"
        isLoading={isSubmitting}
        isDisabled={isSubmitting}
      >
        Save
      </ChakraButton>
    </form>
  );
}
