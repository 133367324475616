import React, { useState, useRef, useId } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { PageHeaderRow, PreLoader, useFeathers } from '@inkcloud/components';
import type { ModelTypes } from '@inkcloud/icapi-types';
import { useSelector } from 'react-redux';
import Fraction from 'fraction.js';

import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Heading,
  Card,
  CardHeader,
  CardBody,
  Alert,
  AlertIcon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Badge,
  Spacer,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  IconButton,
  ButtonGroup,
  Stack,
  StackDivider,
  Text,
  Grid,
  GridItem,
  Wrap,
  WrapItem,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react';
import { FormattedDate } from 'react-intl';

import { FaEllipsisV, FaRegEnvelope, FaPrint, FaRegCopy, FaFileUpload } from 'react-icons/fa';

import feathers, { icapi } from '../../bootstrap/feathers';

import ItemEstimate from './RFQ/ItemEstimate';
import ItemCosts from './RFQ/ItemCosts';

// import EstimateConversion2 from './RFQ/EstimateConversion2'
import RfqVendorResponseModal from './RFQ/RfqVendorResponseModal';
import RfqConvertToPurchaseOrderModal from './RFQ/RfqConvertToPurchaseOrderModal';
import RfqConvertToSalesOrderModal from './RFQ/RfqConvertToSalesOrderModal';

import BasicInfo from './RFQ2/Manage/BasicInfo';
import BillToShipTo from './RFQ2/Manage/BillToShipTo';
import ParticipatingSuppliers from './RFQ2/Manage/ParticipatingSuppliers';
import ConvertedEntities from './RFQ2/Manage/ConvertedEntities';
import Specs from './RFQ2/Manage/Specs';
import Attachments from './RFQ2/Manage/Attachments';
import EstimateResponse from './RFQ2/Manage/EstimateResponse';
import OrderReview from './RFQ2/Manage/OrderReview';

import { SendModal } from './SendModal';
import SendEstimateModal from './SendEstimateModal';
import AttachDocModal from './AttachDocModal';

import type {
  AttributeType,
  EstimateType,
  EstimateAdditionalType,
  BasicInfoType,
} from './common/types';

import EditableInfo from './common/EditableInfo';
import { PrintTypes } from '../../common/PrintModal';
import Supplier from './common/Supplier';
import EditableBillToShipTo from './common/EditableShipTo';
import EditableSpec from './common/EditableSpec';

import Note from '../purchase-orders/PurchaseOrder/Note';

import DuplicateModal from './DuplicateModal';
import SelectedQuantities, { SelectedQuantitiesState } from './RFQ2/Manage/SelectedQuantities';

import AwardJob from './RFQ2/Manage/AwardJob';

import { IndexWrappedProps } from '../../bootstrap/IndexWrapper';

import { connectOrderItemModals } from '../../common/OrderItemCard2';

import { isEstimatePastDue } from './ListTabs/helpers';
import {
  SegmentCard,
  AddNote,
  UploadMisc,
  numberToFraction,
  fractionToNumber,
  stripPar,
  validateNumberFraction,
} from '../../common';
// import { SegmentCard, AddNote, UploadMisc } from '../../common';

interface IDetailProps {}

const serviceName = `/rfqs`;
const pathPrefix = `/rfqs`;
const service = feathers.service(serviceName);
const commonParams = {
  query: {
    $populate: [
      'attachments',
      'attachments.uploadLog',
      'customer',
      'micrositeUser',
      'items.vendor',
      'privateNotes.createdBy',
      'responses.vendor',
      'csr',
      'assignedTo',
      'requestor',
      'items.category',
    ],
  },
};

type handlerRef = {
  handleNext: () => void;
  handleSave: () => void;
};

function noQuantity(msg: string) {
  this.message = msg;
  this.code = 406;
}

type optionsType = { label: string; value: string }[];

type PurchaseOrderType = NonNullable<
  ModelTypes.PurchaseOrder & { type: string; vendor?: { companyName: string } }
>[];
type SalesOrderType = NonNullable<
  ModelTypes.SalesOrder & { type: string; customer?: { organizationName: string } }
>[];

export type ItemsType = Array<
  NonNullable<ModelTypes.Rfq['items']>[number] & {
    category?: {
      name: string;
      hrCode: string;
      _id: string;
      label?: string;
      value?: string;
    };
    notes?: string;
    eProof?: boolean;
    hardProof?: boolean;
    liveSample?: boolean;
    pressCheck?: boolean;
    numHardProof?: number;
  }
>;
export type AttachmentType = Array<
  NonNullable<ModelTypes.Rfq['attachments']>[number] & { uploadLog: ModelTypes.UploadLog }
>;
export type ResponseType = NonNullable<ModelTypes.Rfq['estimate']>['response'];

export type VendorsResponded = { _id: string; companyName: string };
export type PONumbersType = {
  vendor: string;
  purchaseOrderForeignId: string | number;
};

export type EstimateResponseType = ModelTypes.Rfq['estimate'] & {
  changeMessage: string | undefined;
  declineReason: string | undefined;
};

export type AwardJobType = {
  costCenter?: string;
  externalJobNumber?: string;
  poNumbers?: PONumbersType[];
  clientPo?: string;
};
const regex = new RegExp(/^\d+((?=[ ]*)[ ]?\d+\/\d+|(?=.*)\.?\d*)$/);

export const PreDetail: React.FunctionComponent<
  IDetailProps & RouteComponentProps<{ id?: string }>
> = (props) => {
  const {
    match: {
      params: { id },
    },
    history,
  } = props;

  const authUser = useSelector((state: any) => state.auth.user);

  // console.log('authUser', authUser)

  const isEdit = !!id;
  const [state, reload] = useFeathers('get', serviceName, [id, commonParams], true);
  const [errorMessage, setErrorMessage] = React.useState({
    specs: '',
    info: '',
    vendors: '',
    billShip: '',
    note: '',
    privateNote: '',
    estimate: '',
    attach: '',
  });

  // eslint-disable-next-line no-undef
  const estimateRef = useRef<HTMLDivElement>(null);

  const [additionalCostItems, setAdditionalCostItems] = React.useState([]);
  const [isEditResponseModalOpen, setIsEditResponseModalOpen] = React.useState(false);
  const [isConvertToPurchaseOrderOpen, setIsConvertToPurchaseOrderOpen] = React.useState(false);
  const [isConvertToSalesOrderOpen, setIsConvertToSalesOrderOpen] = React.useState(false);
  const [isSendModalOpen, setIsSendModalOpen] = useState(false);
  const [isSendEstimateModalOpen, setIsSendEstimateModalOpen] = useState(false);

  const [estimate, setEstimate] = useState<EstimateType>({});

  const [selectedQuantities, setSelectedQuantities] = useState<SelectedQuantitiesState>({});
  const [poNumbers, setPONumbers] = useState<PONumbersType[]>([]);

  const [awardJob, setAwardJob] = useState<AwardJobType>({
    costCenter: '',
    externalJobNumber: '',
    poNumbers: [],
    clientPo: '',
  });

  const [response, setResponse] = useState<ResponseType>('accepted');

  const [selectedItemIndex, setSelectedItemIndex] = useState(0);

  const initialEstimate = state.data?.estimate;

  const uuid = `${useId()}-${new Date().valueOf()}`;

  React.useEffect(() => {
    if (state.data?.estimate) {
      // We always want to add a blank additional costs line in
      const updatedEstimate: EstimateType = { ...state.data?.estimate };
      // const hasNoAdditionalCostBelow = updatedEstimate.items?.every((item) => item?.additionalCosts?.every((i) => i.includedInMarkup))

      // console.log('hasNoAdditionalCostBelow', hasNoAdditionalCostBelow)

      updatedEstimate.items = updatedEstimate.items?.map((item, index, items) => {
        // If there aren't any lines, add an empty line so user can enter additional costs
        if (item.additionalCosts?.length === 0) {
          (item.additionalCosts as EstimateAdditionalType[]).push({
            description: '',
            tempId: uuid,
            includedInMarkup: true,
            quantities: item.quantities?.map((q) => ({ quantity: q.quantity, amount: 0 })),
          });
        }

        const hasNoAdditionalCostBelow = (item?.additionalCosts as EstimateAdditionalType[])?.every(
          (i) => i.includedInMarkup !== false
        );

        // console.log('hasNoAdditionalCostBelow', hasNoAdditionalCostBelow)

        if (hasNoAdditionalCostBelow) {
          (item?.additionalCosts as EstimateAdditionalType[])?.push({
            description: '',
            tempId: `${uuid}-${index}`,
            includedInMarkup: false,
            quantities: item.quantities?.map((q) => ({ quantity: q.quantity, amount: 0 })),
          });
        }

        return {
          ...(item || {}),
          additionalCosts: item?.additionalCosts?.map((i: EstimateAdditionalType) => ({
            ...i,
            includedInMarkup: i?.includedInMarkup ?? true,
          })),
        };
      });

      const customerSelectedQuantities = updatedEstimate?.customerSelectedQuantities?.reduce(
        (acc, cur) => {
          acc[cur?.item ?? ''] = cur.quantity;

          return acc;
        },
        {}
      ) as SelectedQuantitiesState;

      setResponse(updatedEstimate?.response ?? 'accepted');
      setSelectedQuantities(customerSelectedQuantities);

      // console.log('UPDATED ESTIAMTE', updatedEstimate);
      setEstimate(updatedEstimate);
    }
  }, [initialEstimate]);

  const addressRef = useRef<handlerRef>(null);
  const vendorRef = useRef<handlerRef>(null);
  const infoRef = useRef<handlerRef>(null);
  const specRef = useRef<handlerRef>(null);

  const [vendors, setVendors] = useState<optionsType>([]);
  const [users, setUsers] = useState<optionsType>([]);

  const [attributes, setAttributes] = useState<AttributeType[]>([]);

  const [purchaseOrders, setPurchaseOrders] = useState<PurchaseOrderType>([]);
  const [salesOrders, setSalesOrders] = useState<SalesOrderType>([]);
  const [showRecommendedSell, setShowRecommendedSell] = useState<boolean>(false);
  const [showMinSell, setShowMinSell] = useState<boolean>(false);
  const [totalLabel, setTotalLabel] = useState<string>('Total');

  const [tabIndex, setTabIndex] = useState(0);

  type CustomerSelectedQuantities = { [rfqItemId: string]: string }[];
  // const [customerSelectedQuantities, setCustomerSelectedQuantities] = useState<CustomerSelectedQuantities>({})

  const [isModalOpen, setIsModalOpen] = React.useState({
    specs: false,
    info: false,
    vendors: false,
    billShip: false,
    duplicate: false,
    note: false,
    privateNote: false,
    attach: false,
  });

  const [isSubmitting, setIsSubmitting] = React.useState({
    specs: false,
    info: false,
    vendors: false,
    billShip: false,
    note: false,
    privateNote: false,
    estimate: false,
    attach: false,
  });

  const [isDone, setIsDone] = useState({
    specs: false,
  });

  const [finalConversion, setFinalConversion] = React.useState(null);

  const loadPO = async () => {
    try {
      const res = await feathers.service('purchase-orders').find({
        query: {
          $np: 1,
          createdFromRfq: id,
          $populate: ['vendor'],
        },
      });

      setPurchaseOrders(res?.map((r) => ({ ...r, type: 'po' })));
    } catch (e) {
      console.error(e);
    }
  };
  const loadSO = async () => {
    try {
      const res = await feathers.service('orders').find({
        query: {
          // $np: 1,
          createdFromRfq: id,
          $populate: ['customer'],
          isVoid: { $ne: true },
        },
      });

      setSalesOrders(res?.data.map((r) => ({ ...r, type: 'so' })));
    } catch (e) {
      console.error(e);
    }
  };

  const loadRequestorRoles = async () => {
    try {
      if (!state.data?.requestor) {
        return;
      }

      if (!state.data?.requestor?.roles2.length) {
        return;
      }

      const res = await feathers.service('user-roles').find({
        query: {
          _id: { $in: state.data?.requestor?.roles2 },
          $np: 1,
        },
      });

      // console.log('loading requestor roles', res)
      if (res.find((r) => r.name === 'AW Sales' || r.name === 'IB Sales')) {
        // console.log('yes to show!!!!!')
        setShowMinSell(true);
      }

      if (res.find((r) => r.name === 'AW Sales')) {
        // setTotalLabel('Recommended Sell');
        // console.log('################# setting total label');
      }

      if (res.find((r) => r.name === 'IB Sales')) {
        setShowMinSell(true);
        setShowRecommendedSell(true);
        setTotalLabel('Cost');
      }
    } catch (e) {
      console.error(e);
    }
  };

  React.useEffect(() => {
    const loadAttributes = async () => {
      try {
        const res = await feathers.service('products-legacy/attributes').find({
          query: {
            $np: 1,
            $sort: { name: 1 },
            $populate: ['entityAttribute'],
          },
        });

        setAttributes(
          res.map((r) => ({
            _id: r._id,
            name: r.name,
            typeId: r.entityAttribute._id,
            typeName: r.entityAttribute.name,
          }))
        );
      } catch (e) {
        console.error(e);
      }
    };

    const loadVendors = async () => {
      try {
        const res = await feathers.service('production/vendors').find({
          query: {
            $np: 1,
            $sort: { companyName: 1 },
          },
        });
        setVendors(res?.map((m) => ({ label: m.companyName, value: m._id })));
      } catch (e) {
        console.error(e);
      }
    };

    const loadUsers = async () => {
      try {
        const res = await feathers.service('users').find({
          query: {
            $np: 1,
            $sort: { firstName: 1 },
            vendor: { $exists: false },
            type: { $ne: 'vendor' },
          },
        });
        setUsers(res.map((m) => ({ label: `${m.firstName} ${m.lastName}`, value: m._id })));
      } catch (e) {
        console.error(e);
      }
    };

    loadAttributes();
    loadVendors();
    loadUsers();
    loadPO();
    loadSO();
    loadRequestorRoles();

    if (state.data) {
      setAdditionalCostItems(state.data.additionalCostItems ?? [{ description: '', amount: 0.0 }]);
    }
  }, [state.data]);

  const handleSaveEdit = async (values, key: string | undefined = undefined) => {
    setErrorMessage({
      ...errorMessage,
      [key as string]: '',
    });

    const areAllItemsHasQty = values?.items?.every((i) => i?.quantities?.length > 0);

    setIsDone({ ...isDone, [key as string]: false });
    setIsSubmitting({ ...isSubmitting, info: true, [key as string]: true });

    try {
      if (key !== 'info' && values?.items?.length && !areAllItemsHasQty) {
        // eslint-disable-next-line new-cap
        throw new noQuantity('Please add at least one quantity for each item');
      }

      await service.patch(id, values, commonParams);

      reload();
      if (key) {
        setIsModalOpen({ ...isModalOpen, [key]: false });
        setIsDone({ ...isDone, [key as string]: true });
      }
    } catch (e) {
      const errMsg =
        e?.code < 500 ? e.message : 'We are experiencing technical difficulties. Please try again';
      setErrorMessage({
        ...errorMessage,
        [key as string]: errMsg,
      });
    }

    setIsSubmitting({ ...isSubmitting, info: false, [key as string]: false });
  };

  const handleSaveEstimate = async () => {
    setIsSubmitting({ ...isSubmitting, estimate: true });
    setErrorMessage({ ...errorMessage, estimate: '' });
    try {
      await feathers.service('rfqs').patch(id, {
        estimate,
      });
    } catch (e) {
      const errMsg =
        e?.code < 500 ? e.message : 'We are experiencing technical difficulties. Please try again';
      setErrorMessage({ ...errorMessage, estimate: errMsg });
    }

    setIsSubmitting({ ...isSubmitting, estimate: false });
  };

  const handleEstimateSubmit = async (values) =>
    feathers.service('rfqs').patch(id, {
      estimate: values,
    });

  const handleEstimateResponseSubmit = async (values) =>
    feathers.service('rfqs').patch(id, {
      estimate: values,
      status: 'estimate-response-received',
    });

  // const handleFinalEstimateSubmit = async (values) => {
  //   console.log('final conversion', values)
  //   setFinalConversion(values)
  // }

  // const handleSend = async () => {
  //   await (icapi.service('rfqs') as any).patch(id, {
  //     status: 'open',
  //   })
  //   history.push(pathPrefix)
  // }

  const printHandleOpenOrderLevel = (modaltype: PrintTypes, payload: { _id: string }) => {
    (props as any).printHandleOpen(modaltype, payload);
  };

  const pageTitle = isEdit ? 'Edit' : 'New';

  if (isEdit && !state.data) {
    return <PreLoader />;
  }

  if (vendors.length === 0) {
    return <PreLoader />;
  }

  // console.log([canConvert, selectedQuantities])
  const initialData: ModelTypes.Rfq & {
    attachments: { filename: string }[];
    assignedTo?: { _id?: string };
    notes?: string;
    turnaroundTime?: number;
    turnaroundDescription?: string;
  } = { ...state.data };

  // const participatingVendorIds = initialData.vendors?.map((v) => v?.vendor).filter((v) => v) ?? [];

  // const respondedVendors =
  //   initialData.responses
  //     ?.filter((r) => r.status === 'responded' || r.status === 'responded-with-changes')
  //     .map((r) => ((r.vendor as any)?._id ? (r.vendor as any)._id : r.vendor)) ?? []

  // const participatingVendorList = vendors
  //   .filter((v) => participatingVendorIds.includes(v.value))
  //   .map((v) => ({
  //     ...v,
  //     isDisabled: !respondedVendors.includes(v.value),
  //   }))

  // console.log('initialData', initialData)
  // console.log('initialData.responses', initialData.responses)
  // console.log('respondedVendors', respondedVendors)
  // console.log('participatingVendorList', participatingVendorList)

  const participatingVendorList = initialData?.items?.reduce((acc, item) => {
    // acc[item._id as string] = initialData.responses?.reduce((innerAcc, res) => {
    //   // console.log('response', res)

    //   if (res.status === 'responded' || res.status === 'responded-with-changes') {
    //     const foundItem = res.items?.find((i) => i?.item === item?._id)

    //     if (foundItem) {
    //       // eslint-disable-next-line array-callback-return
    //       foundItem?.quantities?.map((q) => {
    //         if (innerAcc[q?.quantity as number]) {
    //           innerAcc[q?.quantity as number]?.push({
    //             label: (res.vendor as { _id?: string; companyName?: string })?.companyName ?? '',
    //             value: (res.vendor as { _id?: string; companyName?: string })?._id,
    //             isDisabled: !q?.price,
    //           })
    //         } else {
    //           innerAcc[q?.quantity as number] = [
    //             {
    //               label: (res.vendor as { _id?: string; companyName?: string })?.companyName ?? '',
    //               value: (res.vendor as { _id?: string; companyName?: string })?._id,
    //               isDisabled: !q?.price,
    //             },
    //           ]
    //         }
    //       })

    //     }
    //   }

    //   return innerAcc
    // }, new Map)

    const responses = initialData.responses?.reduce((innerAcc, res) => {
      // console.log('response', res)

      if (res.status === 'responded' || res.status === 'responded-with-changes') {
        const foundItem = res.items?.find((i) => i?.item === item?._id);

        if (foundItem) {
          // eslint-disable-next-line array-callback-return
          foundItem?.quantities?.map((q) => {
            // if (innerAcc[q?.quantity as number]) {
            //   innerAcc[q?.quantity as number]?.push({
            //     label: (res.vendor as { _id?: string; companyName?: string })?.companyName ?? '',
            //     value: (res.vendor as { _id?: string; companyName?: string })?._id,
            //     isDisabled: !q?.price,
            //   })
            // } else {
            //   innerAcc[q?.quantity as number] = [
            //     {
            //       label: (res.vendor as { _id?: string; companyName?: string })?.companyName ?? '',
            //       value: (res.vendor as { _id?: string; companyName?: string })?._id,
            //       isDisabled: !q?.price,
            //     },
            //   ]
            // }

            if (innerAcc.get(q?.quantity as number)) {
              // console.log('has qty', innerAcc.get(q?.quantity as number))

              innerAcc.set(q?.quantity as number, [
                ...(innerAcc.get(q?.quantity as number) || []),
                {
                  label: (res.vendor as { _id?: string; companyName?: string })?.companyName ?? '',
                  value: (res.vendor as { _id?: string; companyName?: string })?._id,
                  isDisabled: !q?.price,
                },
              ]);
            } else {
              innerAcc.set(q?.quantity as number, [
                {
                  label: (res.vendor as { _id?: string; companyName?: string })?.companyName ?? '',
                  value: (res.vendor as { _id?: string; companyName?: string })?._id,
                  isDisabled: !q?.price,
                },
              ]);
            }
          });
        }
      }

      // console.log('innerAcc', innerAcc)

      return innerAcc;
    }, new Map());

    acc.set(item._id, responses);

    // console.log('res r', r)

    return acc;
  }, new Map());

  // console.log('participatingVendorList', participatingVendorList)

  const handlePrivateNoteSubmit = async (value) => {
    setErrorMessage({ ...errorMessage, privateNote: '' });

    setIsSubmitting({ ...isSubmitting, privateNote: true });
    try {
      await feathers.service('rfqs/notes').create({
        rfqId: initialData?._id,
        content: value,
      });

      setIsModalOpen({
        ...isModalOpen,
        privateNote: false,
      });

      reload();
    } catch (e) {
      const errMsg =
        e.code && e.code < 500
          ? e.message
          : 'We are experiencing technical difficulties. Please try again';
      setErrorMessage({ ...errorMessage, privateNote: errMsg });
    }

    setIsSubmitting({ ...isSubmitting, privateNote: false });
  };

  const handleNoteSubmit = async (value) => {
    setErrorMessage({ ...errorMessage, note: '' });

    setIsSubmitting({ ...isSubmitting, note: true });
    try {
      await service.patch(
        id,
        {
          notes: value,
        },
        commonParams
      );

      setIsModalOpen({
        ...isModalOpen,
        note: false,
      });

      reload();
    } catch (e) {
      const errMsg =
        e.code && e.code < 500
          ? e.message
          : 'We are experiencing technical difficulties. Please try again';
      setErrorMessage({ ...errorMessage, note: errMsg });
    }

    setIsSubmitting({ ...isSubmitting, note: false });
  };

  // console.log('initialData', initialData)
  // console.log('selectedQuantities', selectedQuantities)

  const filtedResponse = initialData?.responses?.filter((r) => r.status === 'responded') ?? [];

  const showAwardJob =
    initialData?.status !== 'rfq-received' &&
    initialData?.status !== 'draft' &&
    initialData?.status !== 'open' &&
    initialData?.status !== 'sent-to-suppliers' &&
    initialData?.status !== 'supplier-response-received' &&
    response !== 'declined';

  const showEstimateResponse =
    initialData?.status !== 'rfq-received' &&
    initialData?.status !== 'draft' &&
    initialData?.status !== 'sent-to-suppliers' &&
    initialData?.status !== 'supplier-response-received' &&
    initialData?.status !== 'open';

  // @NOTE "open" and "sent-to-suppliers" are for backward compatibility. We will deprecate "open" in the future.
  const showNoticeA = initialData?.status === 'open' || initialData?.status === 'sent-to-suppliers';
  const showNoticeB =
    initialData?.status === 'supplier-response-received' && filtedResponse?.length > 0;

  // console.log('items', initialData?.items)

  // const isSpecBtnDisabled = initialData?.items?.some((i) => i?.quantities?.length === 0)

  // console.log('isSpecBtnDisabled', isSpecBtnDisabled)
  // console.log('specRef', specRef)

  const hasAssignedTo = initialData?.assignedTo;
  let isTheSameUserToAssignee = hasAssignedTo?._id === authUser?._id;

  let isReadOnly = false;

  if (hasAssignedTo !== undefined && hasAssignedTo !== null) {
    isReadOnly = true;

    if (isTheSameUserToAssignee) {
      isReadOnly = false;
    }
  }

  const notYetAwarded = initialData?.status !== 'order-awarded';

  // If the current user is an admin (no roles), allow full access
  if (authUser?.roles2?.length === 0) {
    isReadOnly = false;
    isTheSameUserToAssignee = true;
  }

  // const initialDataItems = initialData?.items?.map((item) => ({
  //   ...item,
  //   components: item?.components?.map((c) => ({
  //     ...c,
  //     flatSize: {
  //       width: c?.flatSize?.width ? new Fraction(c?.flatSize?.width).toFraction(true) : undefined,
  //       height: c?.flatSize?.height
  //         ? new Fraction(c?.flatSize?.height).toFraction(true)
  //         : undefined,
  //     },
  //     finishSize: {
  //       width: c?.finishSize?.width
  //         ? new Fraction(c?.finishSize?.width).toFraction(true)
  //         : undefined,
  //       height: c?.finishSize?.height
  //         ? new Fraction(c?.finishSize?.height).toFraction(true)
  //         : undefined,
  //       // width: c?.finishSize?.width ? numberToFraction(c?.finishSize?.width) : undefined,
  //       // height: c?.finishSize?.height ? numberToFraction(c?.finishSize?.height) : undefined,
  //     },
  //   })),
  // }));

  // console.log('additionalCosts', estimate?.items?.[0]?.additionalCosts)

  // console.log('initialData', initialData)
  // console.log('isReadOnly', isReadOnly)
  // console.log('hasAssignedTo', hasAssignedTo)
  // console.log('isTheSameUserToAssignee', isTheSameUserToAssignee)

  // /^(?!.*\d+(?:\.\d+){2})\d*\s*\.?\d*\/?\d*\.?\d*$/gm
  // /^(?!.*\d+(?:\.\d+){2})\d*\s*\.?\d*\/?\d*\.?\d*$/gm

  // const regex = new RegExp(/^(?!.*\d+(?:\.\d+){2})\d*\s*\d*\/?\d*\.?\d*$/g)
  // /^(?!.*\d+(?:\.\d+){2})\d*\s*\d*\/?\d*\.?\d*$/gm
  // console.log('fraction', String(new Fraction(4.3).toFraction(true)));
  return (
    <Box>
      <Flex mb={16} alignItems={'center'}>
        <Box>
          <Heading fontWeight={'normal'} size="xl">
            RFQ #{initialData.humanId}
          </Heading>
        </Box>
        <Badge ml={4} colorScheme={'green'} fontSize={'large'}>
          {initialData?.status}
        </Badge>
        <Spacer />
        <ButtonGroup variant="outline" spacing="2" mr={2}>
          <Button colorScheme="gray" onClick={() => history.goBack()}>
            Back
          </Button>
          {!isReadOnly &&
          (initialData.status === 'rfq-received' || initialData.status === 'open') ? (
            <Button
              isDisabled={initialData?.vendors?.length === 0}
              colorScheme={initialData.status === 'rfq-received' ? 'blue' : 'gray'}
              onClick={() => setIsSendModalOpen(true)}
            >
              Send to Suppliers
            </Button>
          ) : null}
          {!isReadOnly &&
            (initialData.status === 'estimate-sent' ||
              initialData.status === 'supplier-response-received') && (
              <Button
                isDisabled={initialData?.vendors?.length === 0}
                colorScheme={'blue'}
                onClick={() => {
                  handleSaveEstimate();
                  setIsSendEstimateModalOpen(true);
                }}
              >
                Send Estimate
              </Button>
            )}
        </ButtonGroup>

        {!isReadOnly && (
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label="Actions"
              icon={<FaEllipsisV />}
              variant="solid"
              colorScheme={'blue'}
            />

            <MenuList>
              <MenuItem
                icon={<FaRegEnvelope />}
                onClick={() => {
                  handleSaveEstimate();
                  setIsSendEstimateModalOpen(true);
                }}
              >
                Send Estimate
              </MenuItem>
              {/* <MenuItem
                icon={<FaPrint />}
                onClick={() =>
                  printHandleOpenOrderLevel(PrintTypes.Estimate, { _id: state?.data?._id })
                }
              >
                Print Estimate
              </MenuItem> */}

              {id && (
                <>
                  {/* <MenuItem
                  icon={<FaFileUpload />}
                  onClick={() => setIsModalOpen({ ...isModalOpen, attach: true })}
                >
                  Attach File
                </MenuItem> */}
                  <MenuDivider />
                  <MenuItem
                    icon={<FaRegCopy />}
                    onClick={() =>
                      setIsModalOpen({
                        ...isModalOpen,
                        duplicate: true,
                      })
                    }
                  >
                    Duplicate
                  </MenuItem>
                </>
              )}
            </MenuList>
          </Menu>
        )}
      </Flex>

      {isEstimatePastDue(initialData) && (
        <Alert mb={3} status="error">
          <AlertIcon />
          <AlertTitle>Estimate is past due</AlertTitle>
          <AlertDescription>
            <FormattedDate value={initialData.estimateDueDate} />
          </AlertDescription>
        </Alert>
      )}

      {showNoticeA && !isReadOnly && (
        <Alert status="warning" mb={4}>
          <AlertIcon />
          RFQ has been sent to suppliers. Waiting for responses.
        </Alert>
      )}

      {showNoticeB && !isReadOnly && (
        <Alert status="info" mb={4}>
          <AlertIcon />
          RFQ has been sent to suppliers.{' '}
          {initialData?.responses?.filter((r) => r?.status !== 'pending')?.length} out of{' '}
          {initialData?.responses?.length} suppliers have responded.{' '}
          <Button
            ml={4}
            size={'xs'}
            colorScheme={'blue'}
            variant={'outline'}
            onClick={() => window.scrollTo(0, (estimateRef?.current?.offsetTop ?? 0) - 53)}
          >
            View Responses
          </Button>
        </Alert>
      )}

      <Stack direction={{ base: 'column', lg: 'row' }} spacing={8}>
        <Box w={{ base: '100%', lg: '50%' }}>
          <Box>
            {/* {(showEstimateResponse || showAwardJob) && isTheSameUserToAssignee && notYetAwarded && (
            // <Grid
            //   // templateColumns={{ lg: `repeat(${showAwardJob ? '2' : '1'}, 1fr)` }}
            //   templateColumns={{ lg: '1fr' }}
            //   gap={8}
            //   mb={8}
            // >
              {/* {showEstimateResponse && (
                <GridItem>
                  <EstimateResponse
                    title="Estimate Response"
                    subTitle=""
                    estimate={initialData.estimate as any}
                    selectedQuantities={selectedQuantities}
                    onChange={(v) => setSelectedQuantities(v)}
                    onEstimateSubmit={handleEstimateResponseSubmit}
                    onResponseChange={(v) => setResponse(v)}
                    response={response}
                    suppliers={
                      initialData?.responses
                        ?.filter((r) => r.status === 'responded')
                        ?.map((r) => ({
                          _id: (r.vendor as any)._id,
                          name: (r.vendor as any).companyName,
                        })) as { _id: string; name: string }[]
                    }
                  />
                </GridItem>
              )}
              {/* {showAwardJob && (
                <GridItem>
                  <AwardJob
                    title="Award Job"
                    subTitle="Award Job"
                    estimate={initialData.estimate}
                    estimateItems={initialData.estimate?.items}
                    selectedQuantities={selectedQuantities}
                    onChange={(v) => setSelectedQuantities(v)}
                    onReload={reload}
                    onChangeSupplier={(v) => {
                      console.log('award job', v)

                      setAwardJob(v)
                    }}
                    suppliers={
                      filtedResponse?.map((r) => ({
                        _id: (r.vendor as any)._id,
                        name: (r.vendor as any).companyName,
                      })) as { _id: string; name: string }[]
                    }
                    onConvertToPOClick={() => setIsConvertToPurchaseOrderOpen(true)}
                  />
                </GridItem>
              )}
          //   </Grid>
          // )}
                  */}

            {isTheSameUserToAssignee && notYetAwarded && showAwardJob && (
              <Box mb={3}>
                <AwardJob
                  title="Award Job"
                  subTitle="Award Job"
                  estimate={initialData.estimate}
                  // estimateItems={initialData.estimate?.items}
                  selectedQuantities={selectedQuantities}
                  onChange={(v) => setSelectedQuantities(v)}
                  onReload={reload}
                  id={id as string}
                  onChangeSupplier={(v) => {
                    // console.log('award job', v);

                    setAwardJob(v);
                  }}
                  suppliers={
                    filtedResponse?.map((r) => ({
                      _id: (r.vendor as any)._id,
                      name: (r.vendor as any).companyName,
                    })) as { _id: string; name: string }[]
                  }
                  onConvertToPOClick={() => setIsConvertToPurchaseOrderOpen(true)}
                />
              </Box>
            )}
            {initialData?.estimate?.createdOrder && (
              <Box mb={8}>
                <OrderReview
                  title="Order Review"
                  subTitle=""
                  isReadOnly={isReadOnly}
                  orderId={initialData.estimate?.createdOrder as string}
                />
              </Box>
            )}

            <Box mb={8}>
              <Specs
                items={initialData.items as ItemsType}
                attributes={attributes}
                title="Items"
                subTitle="RFQ Specifications"
                actionButtonLabel={isReadOnly ? '' : 'Edit'}
                isDone={isDone.specs}
                isSubmitting={isSubmitting.specs}
                onEditClick={() => setIsModalOpen({ ...isModalOpen, specs: true })}
                onEditItem={(itemIndex) => {
                  setSelectedItemIndex(itemIndex);
                  setIsModalOpen({ ...isModalOpen, specs: true });
                }}
                onDeleteItem={(items) => handleSaveEdit({ ...initialData, items }, 'specs')}
              />
            </Box>

            <ParticipatingSuppliers
              vendors={vendors.filter((v) =>
                initialData?.vendors?.some((s) => s.vendor === v.value)
              )}
              selectedVendors={initialData?.vendors}
              // rfqId={initialData._id}
              rfq={initialData}
              attributes={attributes}
              onReload={reload}
              actionButtonLabel={isReadOnly ? '' : 'Edit'}
              onEditClick={() => setIsModalOpen({ ...isModalOpen, vendors: true })}
            />
            <br />
          </Box>
        </Box>
        <Box w={{ base: '100%', lg: '50%' }}>
          <Box>
            <Box mb={8}>
              <BasicInfo
                isReadOnly={isReadOnly}
                shipTo={initialData.shipTo}
                shipToEditClick={() => {
                  setIsModalOpen({ ...isModalOpen, billShip: true });
                }}
                // basicInfo={
                //   initialData as Omit<ModelTypes.Rfq, 'csr' | 'requestor'> & {
                //     csr: string | { firstName: string; lastName: string }
                //     requestor: string | { firstName: string; lastName: string }
                //     turnaroundTime?: number
                //   } as any /* build failing, not sure why. @Elme Please check */
                // }
                basicInfo={
                  {
                    csr: initialData?.csr,
                    requestor: initialData?.requestor,
                    projectName: initialData?.projectName,
                    customer: initialData?.customer as any,
                    requestedCustomerShipDate: initialData?.requestedCustomerShipDate,
                    assignedTo: initialData?.assignedTo,
                    estimateDueDate: initialData?.estimateDueDate,
                    paperSuppliedBy: initialData?.paperSuppliedBy,
                    filesSupplied: initialData?.filesSupplied,
                    turnaroundTime: initialData?.turnaroundTime,
                    turnaroundDescription: initialData?.turnaroundDescription,
                    status: initialData?.status,
                  } as BasicInfoType
                }
                title="Info"
                // shipTo={{ shipTo: initialData.shipTo as ModelTypes.Rfq['shipTo'] }}
                subTitle="RFQ Specifications"
                actionButtonLabel={isReadOnly ? '' : 'Edit'}
                onEditClick={() => setIsModalOpen({ ...isModalOpen, info: true })}
              />
              <Divider />
            </Box>
            <Box mb={8}>
              <Attachments
                title="Attachments"
                subTitle="Attach Files"
                actionButtonLabel={isReadOnly ? '' : 'Edit'}
                attachments={initialData?.attachments as AttachmentType}
                onChange={(v) =>
                  handleSaveEdit({
                    ...initialData,
                    attachments: v,
                  })
                }
                onOpenUpload={() => setIsModalOpen({ ...isModalOpen, attach: true })}
              />
            </Box>
            <Box mb={8}>
              <Note
                title="Notes"
                subTitle=" Notes"
                actionButtonLabel={isReadOnly ? '' : 'Edit'}
                notes={initialData?.notes}
                onOpenNoteModal={() => setIsModalOpen({ ...isModalOpen, note: true })}
              />
            </Box>
            <Box mb={8}>
              <Note
                title="Notes"
                subTitle="Internal Notes"
                actionButtonLabel={isReadOnly ? '' : 'Edit'}
                notes={initialData?.privateNotes}
                onOpenNoteModal={() => setIsModalOpen({ ...isModalOpen, privateNote: true })}
              />
            </Box>
            <Box mb={8}>
              <ConvertedEntities
                title="Linked Entities"
                subTitle=""
                data={{ po: purchaseOrders, so: salesOrders }}
              />
            </Box>
          </Box>
        </Box>
      </Stack>

      {estimate && filtedResponse?.length > 0 && (
        <Box mb={8} ref={estimateRef} id="estimate">
          <SegmentCard title="Estimate" subtitle="Estimate">
            {(initialData as any).items.map((ei, idx) => (
              <Box key={idx}>
                <SegmentCard
                  title={`Item ${idx + 1} - ${ei?.sku ?? ''}`}
                  subtitle="Costs"
                  bgColor={'blue.100'}
                >
                  <Box mb={6}>
                    <ItemEstimate
                      rfq={initialData}
                      rfqItemId={ei._id}
                      estimate={estimate}
                      onReload={() => reload()}
                      onSubmit={handleEstimateSubmit}
                      onChange={(v) => {
                        // console.log('change', v)
                        setEstimate(v as any);
                      }}
                      isReadOnly={isReadOnly}
                      vendors={participatingVendorList}
                    />

                    <ItemCosts
                      rfq={initialData}
                      rfqItemId={ei._id}
                      estimate={estimate}
                      onReload={() => reload()}
                      onSubmit={handleEstimateSubmit}
                      onChange={(v) => {
                        // console.log('change', v)
                        setEstimate(v as any);
                      }}
                      vendors={participatingVendorList}
                      isReadOnly={isReadOnly}
                      showRecommendedSell={showRecommendedSell}
                      showMinSell={showMinSell}
                      totalLabel={totalLabel}
                    />
                  </Box>
                </SegmentCard>

                <hr />
              </Box>
            ))}

            {errorMessage?.estimate && (
              <Alert my={3} status="error">
                {errorMessage.estimate}
              </Alert>
            )}

            <Button
              isDisabled={isSubmitting.estimate || isReadOnly}
              isLoading={isSubmitting.estimate}
              mt={2}
              size="md"
              colorScheme="blue"
              onClick={handleSaveEstimate}
            >
              Save Estimate
            </Button>
          </SegmentCard>
        </Box>
      )}

      {/* {!initialData.isRfqSent && (
        <Banner status="warning">
          <BannerIcon />
          <BannerTitle>
            RFQ is in draft. <Button onClick={() => setIsSendModalOpen(true)}>Click Here</Button> to
            send RFQ to vendors.
          </BannerTitle>
        </Banner>
      )} */}

      {isModalOpen.specs && (
        <Modal
          size="2xl"
          isOpen={true}
          onClose={() => {
            setSelectedItemIndex(0);
            setIsModalOpen({ ...isModalOpen, specs: false });
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Edit Specs</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={4}>
              <EditableSpec
                ref={specRef}
                rfqId={initialData._id}
                attributes={attributes}
                items={initialData.items as ItemsType}
                isEdit={isEdit}
                selectedItemIndex={selectedItemIndex}
                isSubmitting={isSubmitting.specs}
                onReload={reload}
                onErrorMessage={(v) => setErrorMessage({ ...errorMessage, specs: v })}
                onChange={(v) => {
                  // const t = v?.some((i) =>
                  //   i?.components?.some(
                  //     (c) =>
                  //       (c?.flatSize?.width && !regex.test(String(c?.flatSize?.width))) ||
                  //       (c?.flatSize?.height && !regex.test(String(c?.flatSize?.height))) ||
                  //       (c?.finishSize?.width && !regex.test(String(c?.finishSize?.width))) ||
                  //       (c?.finishSize?.height && !regex.test(String(c?.finishSize?.height)))
                  //   )
                  // );
                  // console.log('v', v, 't', t);
                  // if (t) {
                  //   setErrorMessage({
                  //     ...errorMessage,
                  //     specs: 'Please enter valid value',
                  //   });
                  // } else {
                  //   setErrorMessage({
                  //     ...errorMessage,
                  //     specs: '',
                  //   });
                  handleSaveEdit({ ...initialData, items: v }, 'specs');
                  // }
                }}
              />
              {errorMessage?.specs && (
                <Alert borderRadius={2} status="error">
                  {errorMessage.specs}
                </Alert>
              )}
            </ModalBody>
            <ModalFooter>
              <Button
                isLoading={isSubmitting.specs}
                isDisabled={isSubmitting.specs}
                onClick={() => specRef?.current?.handleSave()}
              >
                Save
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
      {isModalOpen.info && (
        <Modal
          size="3xl"
          isOpen={true}
          onClose={() => setIsModalOpen({ ...isModalOpen, info: false })}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Edit Info</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={4}>
              <EditableInfo
                ref={infoRef}
                basicInfo={initialData as any}
                usersOptions={users}
                onChange={(v) => {
                  handleSaveEdit(v, 'info');
                }}
                onChangeUserOptions={(v) => setUsers((prev) => [...prev, v])}
              />
              {errorMessage?.info && (
                <Alert borderRadius={2} status="error">
                  {errorMessage.info}
                </Alert>
              )}
            </ModalBody>
            <ModalFooter>
              <Button
                isLoading={isSubmitting.info}
                isDisabled={isSubmitting.info}
                onClick={() => infoRef?.current?.handleNext()}
              >
                Save
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}

      {isModalOpen.billShip && (
        <Modal
          size="lg"
          isOpen={true}
          onClose={() => {
            setTabIndex(0);
            setIsModalOpen({ ...isModalOpen, billShip: false });
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Edit Bill To / Ship To</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={4}>
              <EditableBillToShipTo
                ref={addressRef}
                billTo={initialData.billTo as ModelTypes.Rfq['billTo'] & { company?: string }}
                shipTo={initialData.shipTo as NonNullable<ModelTypes.Rfq['shipTo']>}
                isSubmitting={isSubmitting.billShip}
                onChange={(v) => {
                  handleSaveEdit(v, 'billShip');
                }}
                tabIndex={tabIndex}
              />
              {errorMessage?.billShip && (
                <Alert borderRadius={2} status="error">
                  {errorMessage.billShip}
                </Alert>
              )}
            </ModalBody>
            <ModalFooter>
              <Button
                isLoading={isSubmitting.billShip}
                isDisabled={isSubmitting.billShip}
                onClick={() => addressRef?.current?.handleSave()}
              >
                Save
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}

      {isModalOpen.vendors && (
        <Modal
          size="5xl"
          isOpen={true}
          onClose={() => setIsModalOpen({ ...isModalOpen, vendors: false })}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              {initialData.vendors?.length ? 'Edit' : 'Add'} Participating Suppliers
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={4}>
              <Supplier
                ref={vendorRef}
                items={initialData.items as ItemsType}
                vendors={vendors}
                estimateDue={initialData.estimateDueDate}
                selectedVendors={initialData?.vendors as NonNullable<ModelTypes.Rfq['vendors']>}
                isSubmitting={isSubmitting.vendors}
                onChange={(v) => {
                  handleSaveEdit({ ...initialData, vendors: v }, 'vendors');
                }}
              />
              {errorMessage?.vendors && (
                <Alert borderRadius={2} status="error">
                  {errorMessage.vendors}
                </Alert>
              )}
            </ModalBody>
            <ModalFooter>
              <Button
                isLoading={isSubmitting.vendors}
                isDisabled={isSubmitting.vendors}
                onClick={() => vendorRef?.current?.handleSave()}
              >
                Save
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}

      {/* {isEditResponseModalOpen && (
          <RfqVendorResponseModal
            rfq={initialData}
            onClose={() => {
              setIsEditResponseModalOpen(false)
              console.log(
                '!!!!  %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% reload'
              )
              reload()
            }}
          />
        )} */}

      {/* <RfqConvertToPurchaseOrderModal
          open={isConvertToPurchaseOrderOpen}
          onClose={() => setIsConvertToPurchaseOrderOpen(false)}
          rfq={initialData}
          // finalConversion={finalConversion}
          selectedQuantities={selectedQuantities}
        />
        <RfqConvertToSalesOrderModal
          open={isConvertToSalesOrderOpen}
          onClose={() => setIsConvertToSalesOrderOpen(false)}
          rfq={initialData}
          // finalConversion={finalConversion}
          selectedQuantities={selectedQuantities}
        /> */}

      {/* {
        (id && isModalOpen.send) && <EmailModal
          rfqId={id}
          onClose={() => {
            setIsModalOpen({ ...isModalOpen, send: false })
          }}
        />
      } */}
      {/* </div> */}

      {isSendModalOpen && (
        <SendModal
          open={isSendModalOpen}
          onReload={reload}
          onClose={() => {
            reload();
            setIsSendModalOpen(false);
          }}
          suppliersId={initialData.vendors?.map((v) => v.vendor) as string[]}
          rfqId={state.data._id}
        />
      )}

      {isSendEstimateModalOpen && (
        <SendEstimateModal
          onClose={() => {
            setIsSendEstimateModalOpen(false);
          }}
          onReload={reload}
          rfq={state.data}
        />
      )}

      {isModalOpen.duplicate && (
        <DuplicateModal
          data={initialData}
          onClose={() => setIsModalOpen({ ...isModalOpen, duplicate: false })}
        />
      )}

      {isConvertToPurchaseOrderOpen && (
        <RfqConvertToPurchaseOrderModal
          open={isConvertToPurchaseOrderOpen}
          onClose={() => setIsConvertToPurchaseOrderOpen(false)}
          rfq={initialData}
          finalConversion={finalConversion}
          awardJob={awardJob}
          selectedQuantities={selectedQuantities}
          onReload={() => {
            reload();
            loadPO();
          }}
        />
      )}

      {(isModalOpen.privateNote || isModalOpen.note) && (
        <AddNote
          onSubmit={isModalOpen.privateNote ? handlePrivateNoteSubmit : handleNoteSubmit}
          isSubmitting={isSubmitting.privateNote || isSubmitting.note}
          errorMessage={errorMessage.privateNote || errorMessage.note}
          onClose={() =>
            setIsModalOpen({
              ...isModalOpen,
              privateNote: false,
              note: false,
            })
          }
        />
      )}

      {isModalOpen.attach && (
        <AttachDocModal
          rfqId={id as string}
          onClose={() => setIsModalOpen({ ...isModalOpen, attach: false })}
          onReload={reload}
        />
      )}
    </Box>
  );
};

export const Detail = connectOrderItemModals<IndexWrappedProps>(PreDetail);
