import * as React from 'react';
import * as numeral from 'numeral';

export interface PriceProps {
  product: any;
  wantsDesign: any;
  selectedTurnaround: any;
  selectedQuantity: any;
  selectedAddOns: any;
  addOns: any;
}

export function Price(props: PriceProps) {
  let price;

  const { product, selectedTurnaround, selectedQuantity, selectedAddOns, addOns, wantsDesign } =
    props;

  if (!props.product || !selectedQuantity) {
    return null;
  }

  if (props.product.priceRows && props.product.priceRows.length) {
    const { priceRows } = product;

    const foundPriceRow = priceRows.find((p) => `${p.value}` === selectedQuantity.value);
    if (foundPriceRow) {
      price = foundPriceRow.price;
    }
  } else {
    price = product.price * selectedQuantity;
  }

  if (props.product.priceBreaks && props.product.priceBreaks.length) {
    // Ensure the breaks are sorted in ascending order
    const sortedPriceBreaks = props.product.priceBreaks.sort(
      (a, b) => a.breakAmount - b.breakAmount
    );
    price = sortedPriceBreaks.reduce((acc, priceBreak) => {
      if (priceBreak.breakAmount <= selectedQuantity) {
        return priceBreak.price * selectedQuantity;
      }

      return acc;
    }, price);
  }

  if (selectedTurnaround.value === 'next-day') {
    price = price * 1.5;
  }

  // Calculate Add Ons Prices
  let addOnPrices = [];

  if (selectedAddOns) {
    addOnPrices = Object.keys(selectedAddOns).reduce((prev, cur) => {
      // Only enabled
      if (selectedAddOns[cur].value) {
        let amount = 0.0;

        const quantity = selectedQuantity.value;
        if (
          addOns[cur.toUpperCase()].quantityPrices &&
          addOns[cur.toUpperCase()].quantityPrices[quantity]
        ) {
          amount = addOns[cur.toUpperCase()].quantityPrices[quantity];
        } else {
          const p = addOns[cur.toUpperCase()];
          amount = p.setup + (quantity * p.rate) / 1000;
          if (amount < p.minimum) {
            amount = p.minimum;
          }
        }

        prev.push({ label: selectedAddOns[cur].label, amount });
      }

      return prev;
    }, []);
  }

  const total =
    price +
    addOnPrices.reduce((prev, cur) => prev + cur.amount, 0) +
    (wantsDesign.value === 1 && product.designPrice);
  return (
    <div>
      <table style={{ width: '100%' }}>
        <tbody>
          <tr>
            <td>Price</td>
            <td align="right">{numeral(price).format('$0,0.00')}</td>
          </tr>
          {addOnPrices.map((a, idx) => (
            <tr key={idx}>
              <td>{a.label}</td>
              <td align="right">{numeral(a.amount).format('$0,0.00')}</td>
            </tr>
          ))}

          {wantsDesign.value === 1 && (
            <tr>
              <td>Design Price</td>
              <td align="right">{numeral(product.designPrice).format('$0,0.00')}</td>
            </tr>
          )}
          <tr>
            <td>Total</td>
            <td align="right">{numeral(total).format('$0,0.00')}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
