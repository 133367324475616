import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Segment } from 'semantic-ui-react';
import { PageHeaderRow, PreLoader, useFeathers } from '@inkcloud/components';
import feathers from './../../../bootstrap/feathers';
import { DashboardUserForm } from './DashboardUserForm';
import { UpdatePasswordModal } from './UpdatePassword';

interface DetailProps {
  products: { value: string; text: string }[];
  categories: { value: string; text: string }[];
}

const { useState } = React;

const serviceName = 'user';
const service = feathers.service(serviceName);
const commonParams = { query: {} };

export const Detail: React.FunctionComponent<DetailProps & RouteComponentProps<{ id?: string }>> = (
  props
) => {
  const {
    match: {
      params: { id },
    },
    history,
  } = props;

  const pathPrefix = `/admin/dashboard-users`;

  const isEdit = !!id;
  const [state, reload] = useFeathers('get', serviceName, [id, commonParams], true);
  const handleSubmit = async (values) => {
    isEdit
      ? await service.patch(id, values, commonParams)
      : await service.create(values, commonParams);
    history.push(pathPrefix);
  };

  const [isOpen, setIsOpen] = useState(false);

  const handleUpdatePassword = async ({ password, confirmPassword }) => {
    const response = await feathers.service('/user').patch(id, {
      password,
      confirmPassword,
    });

    setIsOpen(false);
  };

  const pageTitle = isEdit ? 'Edit' : 'New';

  if (isEdit && !state.data) {
    return <PreLoader />;
  }

  const initialData = { ...state.data };
  return (
    <div>
      <PageHeaderRow header={`${pageTitle} User`} subheader="Dashboard" subheaderId={''}>
        <Button content="Update Password" size={'mini'} onClick={() => setIsOpen(true)} />
        <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
      </PageHeaderRow>
      <Segment>
        {(!isEdit || state.data) && (
          <DashboardUserForm initialValues={initialData} isEdit={isEdit} onSubmit={handleSubmit} />
        )}
      </Segment>
      {isOpen && (
        <UpdatePasswordModal onClose={() => setIsOpen(false)} onSubmit={handleUpdatePassword} />
      )}
    </div>
  );
};
