import React, { forwardRef, useEffect, useId, useState, useRef, useImperativeHandle } from 'react';
import DatePicker from 'react-datepicker';
import {
  Flex,
  FormControl,
  FormLabel,
  Text,
  Spacer,
  Stack,
  IconButton,
  Button,
  Box,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import { FiX } from 'react-icons/fi';

import { Select } from 'chakra-react-select';

import type { ModelTypes } from '@inkcloud/icapi-types';

import type { ItemsType } from '../Detail';

import type { AttributeType } from './types';

interface ISupplierProps {
  items?: ItemsType;
  estimateDue?: string;
  vendors: { label: string; value: string }[];
  selectedVendors: Array<
    NonNullable<ModelTypes.Rfq['vendors']>[number] & { id?: string; itemsToQuote?: string[] }
  >;
  onChange: (value: any) => void;
  isSubmitting?: boolean;
}

const staticServices = [
  'Bindery',
  'Design',
  'Finishing',
  'Freight',
  'Mailing',
  'Paper',
  'Print',
  'Promo Item',
  'UV / Laminating',
  'Warehousing',
];

function DueDate(poDueDate: string, estimateDue: string) {
  if (poDueDate) {
    return new Date(poDueDate);
  }
  if (estimateDue) {
    return new Date(estimateDue);
  }

  return null;
}

const serviceOptions = staticServices.map((s) => ({ value: s, label: s }));

const Supplier = forwardRef((props: ISupplierProps, ref) => {
  const { onChange, items, estimateDue, vendors, selectedVendors } = props;

  const i = `${useId()}-${new Date().valueOf()}`;

  const [values, setValues] = useState(selectedVendors);

  useEffect(() => {
    setValues(selectedVendors);
  }, [selectedVendors]);

  const handleChange = (idx: number, keyValue: any) => {
    const updatedSelectedVendors = values?.map((s, index) =>
      index === idx ? { ...s, ...keyValue } : s
    );

    setValues(updatedSelectedVendors);
  };

  const handleReOrder = (dragIndex: number, hoverIndex: number) => {
    const newSelectedVendors = [...(selectedVendors || [])];

    const element = newSelectedVendors[dragIndex];
    newSelectedVendors.splice(dragIndex, 1);
    newSelectedVendors.splice(hoverIndex, 0, element);
    setValues(newSelectedVendors);
  };

  const handleRemove = (idx: number) => {
    setValues(values?.filter((_, index) => idx !== index));
  };

  const handleAdd = () => setValues([...(values || []), { id: i }]);

  const handleSave = () => {
    const updatedValues = values?.map((v) => ({
      ...(v || {}),
      poDueDate: v?.poDueDate ?? estimateDue,
    }));

    return onChange(updatedValues);
  };

  useImperativeHandle(ref, () => ({
    handleSave: () => handleSave(),
  }));

  // console.log('supplier values', values)
  // console.log('supplier items', items)

  const itemOptions = items?.map((item) => ({ value: item?._id, label: item?.sku }));

  // console.log('itemOptions', itemOptions)

  const hasItem = (items?.length ?? 0) > 0;

  return (
    <>
      <Flex mb={3} gap={5}>
        <Box flex={1}>
          <Text fontWeight="medium">Supplier</Text>
        </Box>
        <Box flex={1}>
          <Text fontWeight="medium">Due Date</Text>
        </Box>
        {hasItem && (
          <Box flex={1}>
            <Text fontWeight="medium">Items</Text>
          </Box>
        )}
        <Box flex={1}>
          <Text fontWeight="medium">Services</Text>
        </Box>
        <Box>
          <IconButton aria-label="remove supplier" icon={<FiX />} opacity={0} cursor="unset" />
        </Box>
      </Flex>
      {values?.length === 0 && (
        <Alert status="warning" mb={3}>
          <AlertIcon />
          No supplier yet!
        </Alert>
      )}

      {values?.map((s, idx: number) => (
        <Flex key={idx} mb={3} gap={5}>
          <Box flex={1}>
            <FormControl>
              <Select
                size="sm"
                useBasicStyles
                onChange={(v) => handleChange(idx, { vendor: typeof v === 'object' && v?.value })}
                placeholder="Search supplier..."
                options={vendors}
                defaultValue={vendors?.find((v) => v.value === s?.vendor)}
              />
            </FormControl>
          </Box>
          <Box flex={1}>
            <FormControl size="sm">
              <DatePicker
                onChange={(date) => handleChange(idx, { poDueDate: date })}
                selected={DueDate(s?.poDueDate as string, estimateDue as string)}
                dateFormat="MM/dd/yyyy h:mm a"
                showTimeSelect
                className="sm"
              />
            </FormControl>
          </Box>
          {hasItem && (
            <Box flex={1}>
              <FormControl>
                <Select
                  size="sm"
                  useBasicStyles
                  onChange={(services) => {
                    handleChange(idx, { itemsToQuote: services?.map((v) => v?.value) });
                  }}
                  isMulti
                  placeholder=""
                  options={itemOptions}
                  value={itemOptions?.map((v) =>
                    s?.itemsToQuote?.find((iq: any) => iq === v.value)
                      ? { label: v.label, value: v.value }
                      : null
                  )}
                />
              </FormControl>
            </Box>
          )}
          <Box flex={1}>
            <FormControl>
              <Select
                size="sm"
                useBasicStyles
                onChange={(services) => {
                  handleChange(idx, { services: services?.map((v) => v?.value) });
                }}
                isMulti
                // isDisabled={isDisabled}
                placeholder="Select Services..."
                options={serviceOptions}
                value={serviceOptions?.map((v) =>
                  s?.services?.find((ss: any) => ss === v.value)
                    ? { label: v.value, value: v.value }
                    : null
                )}
              />
            </FormControl>
          </Box>
          <Box>
            <IconButton
              aria-label="remove supplier"
              onClick={() => handleRemove(idx)}
              icon={<FiX />}
            />
          </Box>
        </Flex>
      ))}

      <Box mb={3}>
        <Button size="sm" type="button" onClick={handleAdd}>
          Add new supplier
        </Button>
      </Box>
      {/* <Box mb={3}>
        <Button size="sm" type="button" onClick={handleSave}>Save</Button>
      </Box> */}
    </>
  );
});

export default Supplier;
