import * as React from 'react';
import { Switch, Route } from 'react-router-dom';

import { List } from './List';
import { Approval } from './Approval';
import TaxExemptionRequests from './tax-exemption-requests';
import ContactFormSubmissions from './contact-form-submissions';
import CustomQuotes from './custom-quotes';
import Testimonials from './testimonials';
import Detail from './Detail';
import { DetailForm } from './DetailForm';
import ProductLibrary from './product-library/List';
// import Add from './Add';

// import Edit from './Edit';
import LocalDelivery from './local-delivery';
import PaymentCardsAdd from './stored-cards/Container';
// import { CreditCard } from './stored-cards/CreditCard';

const routes = (props) => (
  <Switch>
    <Route exact path="/customers" component={List} />
    <Route exact path="/customers/approval" component={Approval} />
    <Route path="/customers/tax-exemption-requests" component={TaxExemptionRequests} />
    <Route path="/customers/contact-form-submissions" component={ContactFormSubmissions} />
    <Route path="/customers/custom-quotes" component={CustomQuotes} />
    <Route path="/customers/testimonials" component={Testimonials} />
    <Route exact path="/customers/add" component={DetailForm} />
    <Route exact path="/customers/:id" component={Detail} />
    <Route exact path="/customers/:id/edit" component={DetailForm} />
    <Route exact path="/customers/:id/customer-product-library" component={ProductLibrary} />
    <Route exact path="/customers/:id/local-delivery" component={LocalDelivery} />
    {/* <Route exact path='/customers/:id/payment-cards/add' component={PaymentCardsAdd} /> */}
    {/* <Route exact path='/customers/:id/payment-cards/add' component={CreditCard} /> */}
  </Switch>
);

export default routes;
