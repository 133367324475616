import * as React from 'react';
import { Box, useToken } from '@chakra-ui/react'
import { BarChart, Bar, ComposedChart, Scatter, LabelList, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';

interface IVisualizationProps {
  results: any;
}

const currencyFormat = (v: any) => (new Intl.NumberFormat('en-US', {currency: 'USD', style: 'currency'}).format(v));
const capitalize = (v: any) => `${v.charAt(0).toUpperCase()}${v.slice(1)}`;
const Visualization: React.FunctionComponent<IVisualizationProps> = (props) => {
  const { results  } = props;
  const { data } = results;

  const [
    blue700, blue600, green700, green600, orange600, orange700, gray600, gray700,
  ] = useToken('colors', [
    'blue.700', 'blue.400', 'green.700', 'green.400', 'orange.600', 'orange.700', 'gray.400', 'gray.700'
  ]);

  const stackedData = data.map((d: any) => ({ ...d, cogs: -d.cogs }));

  return (
    <Box height="400" mb={8} >
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart
          width={500}
          height={400}
          data={stackedData}
          stackOffset="sign"
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <Legend
            fontSize={8}
            verticalAlign="top"
            height={36}
            formatter={(v) => capitalize(v)}
          />
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis

            fontSize={10}
            dataKey="date"
            tickFormatter={(v, i) => v.slice(0,10)}
          />
          <YAxis
            fontSize={10}
            tickFormatter={currencyFormat}
          />
          <Tooltip
            formatter={currencyFormat}
            labelFormatter={v => v.slice(0,10)}

          />
          <Bar type="monotone" dataKey="revenue" stackId="1" stroke={blue700} fill={blue600}>
            <LabelList dataKey="revenue" position="top" formatter={currencyFormat}  fontSize={9} />
          </Bar>
            {/* <LabelList dataKey="profit" position="top" formatter={currencyFormat}  fontSize={8} /> */}
          {/* </Scatter> */}

          <Bar type="monotone" dataKey="cogs" stackId="1" stroke={gray700} fill={gray600}>
            <LabelList dataKey="cogs" position="top" formatter={currencyFormat}  fontSize={9}/>
          </Bar>
          {/* <Scatter dataKey="profit" legendType={'plainline'} stroke={green700} fill={green600} color='#fff'  >
            <LabelList dataKey="profit" position="left" formatter={currencyFormat}  fill={'#fff'} fontSize={9}/>
          </Scatter> */}
          {/* <Bar type="monotone" dataKey="profit" stackId="1" stroke={green700} fill={green600} /> */}
        </ComposedChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default Visualization;
