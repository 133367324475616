import * as React from 'react';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Box,
  Tag,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from '@chakra-ui/react';

import { ModelTypes } from '@inkcloud/icapi-types';

interface IRelatedProductsTableProps {
  products: ModelTypes.ProductLegacy[];
}

const RelatedProductsTable: React.FunctionComponent<IRelatedProductsTableProps> = (props) => {
  const { products } = props;
  return (
    <Box bg={'white'} padding={8}>
      <TableContainer maxHeight={350} overflowY={'scroll'} overflowX={'scroll'}>
        <Table size={'sm'} variant="simple">
          <Thead>
            <Tr>
              <Th>Type</Th>
              <Th>SKU</Th>
              <Th>Limit</Th>
              <th>Period</th>
              <th>Status</th>
            </Tr>
          </Thead>
          <Tbody>
            {products.map((p) => {
              const a = '';
              return (
                <Tr key={p._id}>
                  <Td>
                    {p.hasVariants && (
                      <Tag size={'sm'} colorScheme={'blue'}>
                        Parent
                      </Tag>
                    )}
                    {p.parent && (
                      <Tag size={'sm'} colorScheme={'blue'}>
                        Child Variant
                      </Tag>
                    )}
                  </Td>
                  <Td>{p.key}</Td>
                  <Td>{p.orderLimitsPerUser?.value ?? '-'}</Td>
                  <Td>{p.orderLimitsPerUser?.period ?? '-'}</Td>
                  <Td>
                    {p.orderLimitsPerUser?.enabled && (
                      <Tag size={'sm'} colorScheme={'green'}>
                        Limit Enabled
                      </Tag>
                    )}
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default RelatedProductsTable;
