import { generateOperatorOptions, Operator } from '@inkcloud/components';

import { Feathers } from '../../../bootstrap/feathers/feathers';

const statusOptions = [
  { label: 'RFQ Received', value: 'rfq-received' },

  { label: 'Sent to Suppliers', value: 'sent-to-suppliers' },
  { label: 'Supplier Response Received', value: 'supplier-response-received' },

  { label: 'Estimate Sent', value: 'estimate-sent' },
  { label: 'Estimate Response Received', value: 'estimate-response-received' },
  { label: 'Order Awarded', value: 'order-awarded' },

  { label: 'Draft', value: 'draft' },
  { label: 'Open', value: 'open' },
  { label: 'Committed', value: 'committed' },
];

export default async function getFilterDefinition() {
  const definition = [
    {
      label: 'Assigned To',
      value: 'assignedTo',
      operators: generateOperatorOptions([Operator.Is]),
      asyncValueOptions: async () =>
        Feathers.service('users')
          .find({
            query: {
              $sort: { firstName: 1 },
              $np: 1,
              type: { $ne: 'vendor' },
              vendor: { $exists: false },
            },
          })
          .then((results) =>
            results.map((r) => ({
              value: r._id,
              label: `${r?.firstName ?? ''} ${r?.lastName ?? ''}`,
            }))
          ),
      type: 'select',
    },
    {
      label: 'Requestor',
      value: 'requestor',
      operators: generateOperatorOptions([Operator.Is]),
      asyncValueOptions: async () =>
        Feathers.service('users')
          .find({
            query: {
              $sort: { firstName: 1 },
              $np: 1,
              type: { $ne: 'vendor' },
              vendor: { $exists: false },
            },
          })
          .then((results) =>
            results.map((r) => ({
              value: r._id,
              label: `${r?.firstName ?? ''} ${r?.lastName ?? ''}`,
            }))
          ),
      type: 'select',
    },
    {
      label: 'ID',
      value: 'humanId',
      operators: generateOperatorOptions([Operator.Is]),
    },
    {
      label: 'Date:Created',
      value: 'createdAt',
      operators: generateOperatorOptions([Operator.Between]),
      type: 'date',
    },
    {
      label: 'Project Name',
      value: 'projectName',
      operators: generateOperatorOptions([Operator.Like, Operator.Is]),
    },
    {
      label: 'Status',
      value: 'status',
      operators: generateOperatorOptions([Operator.Is]),
      valueOptions: statusOptions,
      type: 'select',
    },
  ];

  definition.sort((a: any, b: any) => a.label.localeCompare(b.label));

  return definition;
}
