import * as React from 'react';
import { Button, Dimmer, Dropdown, Message, Icon, Loader, Modal, Segment } from 'semantic-ui-react';
import Feathers from './../bootstrap/feathers';

export interface PDFPopupProps {
  // load: Function;
  payload?: any;
  service: string;
  trigger: any;
}

export interface PDFPopupState {
  isModalOpen: boolean;
  isLoading: boolean;
  downloadUrl: string;
  errorMessage: string;
}

export class PDFPopup extends React.Component<PDFPopupProps, PDFPopupState> {
  constructor(props: PDFPopupProps) {
    super(props);
    this.state = {
      isModalOpen: false,
      isLoading: true,
      downloadUrl: null,
      errorMessage: '',
    };
  }

  onModelOpen = async (e, data) => {
    //'/printable/job-ticket'
    this.setState({ ...this.state, isModalOpen: true, isLoading: true, errorMessage: '' });
    try {
      const res: any = await Feathers.service(this.props.service)
        .create(this.props.payload)
        .then((result: any) => {
          this.setState({ ...this.state, isLoading: false, downloadUrl: result.url });
        });

      if (res?.result !== undefined && res?.result === false) {
        this.setState({
          ...this.state,
          isLoading: false,
          errorMessage: res.message || res.errorMessage,
        });
      }
    } catch (e) {
      const msg =
        e.code && e.code < 500
          ? e.message
          : 'We are experiencing technical difficulties. Please try again';
      this.setState({ ...this.state, isLoading: false, errorMessage: msg });
    }
  };

  onDownloadClick = (e, data) => {
    window.open(this.state.downloadUrl, '_blank');
    this.setState({ ...this.state, isModalOpen: false, isLoading: false, downloadUrl: null });
  };

  render() {
    return (
      <Modal
        closeIcon
        open={this.state.isModalOpen}
        onClose={() => this.setState({ ...this.state, isModalOpen: false })}
        trigger={this.props.trigger}
        onOpen={this.onModelOpen}
      >
        <Modal.Content>
          <Modal.Description>
            <Dimmer active={this.state.isLoading} inverted>
              <Loader active={this.state.isLoading} indeterminate>
                Generating PDF
              </Loader>
            </Dimmer>
            {this.state.isLoading && <div style={{ minHeight: '60px' }}></div>}
            {this.state.downloadUrl && !this.state.isLoading && (
              <Segment basic textAlign={'center'}>
                <Button primary size={'large'} onClick={this.onDownloadClick}>
                  Print PDF
                </Button>
              </Segment>
            )}
            {this.state.errorMessage && <Message error content={this.state.errorMessage} />}
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}
