import React, { useEffect, useState } from 'react';

import { useHistory, useParams } from 'react-router-dom';
import {
  Button,
  Modal,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  Flex,
  Center,
  Spinner,
} from '@chakra-ui/react';
import JobManagementForm from './JobViewManagementForm';
import { Feathers } from '../../../bootstrap/feathers/feathers';
import List from './List';

interface IJobManagementModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const initialValues = {
  filter: {},
  columns: [],
};

export default function JobManagementModal(props: IJobManagementModalProps) {
  const { isOpen, onClose } = props;
  const history = useHistory();

  const { id } = useParams<{ id: string }>();

  const isEdit = !!id;
  const isAdd = history.location.pathname.includes('add');
  const [view, setView] = useState(initialValues);

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const loadView = async () => {
      setIsLoading(true);
      try {
        const res = await Feathers.service('job-scheduler/views').get(id, {
          query: {
            $populate: ['columns'],
          },
        });

        const filteredColumns = res?.columns?.reduce((acc, cur) => {
          if (!cur?.isGlobal) {
            acc.push({ label: cur?.name, value: cur?._id });
          }

          return acc;
        }, []);
        setView({
          ...res,
          columns: filteredColumns || [],
        });
      } catch (e) {
        console.log('e');
      }

      setIsLoading(false);
    };

    if (id) {
      loadView();
    }
  }, [id]);

  const handleSubmit = async (v) => {
    setIsSubmitting(true);
    setErrorMessage('');
    const payload = v;

    payload.columns = v?.columns?.map((c) => c.value);
    try {
      const res = isEdit
        ? await Feathers.service('job-scheduler/views').patch(id, payload)
        : await Feathers.service('job-scheduler/views').create(payload);
      history.push('/job-scheduler/views');
    } catch (e) {
      const errMsg = e?.code < 500 ? e?.message : 'Something went wrong. Please try again later.';

      setErrorMessage(errMsg);
    }
    setIsSubmitting(false);
  };
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size={'6xl'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Manage Views</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {isAdd || isEdit ? (
              <>
                <Flex justifyContent="end">
                  <Button
                    onClick={() => {
                      setView(initialValues);
                      history.goBack();
                    }}
                  >
                    Back
                  </Button>
                </Flex>
                {isLoading ? (
                  <Center>
                    <Spinner />
                  </Center>
                ) : (
                  <JobManagementForm
                    initialValues={view}
                    errorMessage={errorMessage}
                    isSubmitting={isSubmitting}
                    onSubmit={handleSubmit}
                  />
                )}
              </>
            ) : (
              <List />
            )}
          </ModalBody>

          <ModalFooter>
            <Button mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
