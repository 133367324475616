import * as React from 'react';
import { useHistory, useLocation } from 'react-router';

import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Sector,
} from 'recharts';

import { colors } from './colors';

function truncate(str, n) {
  return str?.length > n ? `${str.substr(0, n - 4)}...` : str;
}

interface RfqAssignedLoadProps {
  data: {
    user: string;
    'rfq-received': number;
    'sent-to-suppliers': number;
    'supplier-response-received': number;
    'estimate-sent': number;
    'estimate-response-received': number;
  }[];
}
export const RfqAssignedLoad: React.FunctionComponent<RfqAssignedLoadProps> = (props) => {
  const { data } = props;

  const mappedData = data.map((d) => ({ ...d, name: truncate(d.user, 16) ?? 'Unassigned' }));

  return (
    <ResponsiveContainer width={'100%'} height={200}>
      <BarChart
        layout="vertical"
        barSize={20}
        width={500}
        height={200}
        data={mappedData}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        {/* <XAxis /> */}
        <XAxis type="number" />
        {/* <YAxis dataKey="name"  /> */}
        <YAxis dataKey="name" type="category" scale="band" fontSize={10} />
        <Tooltip />
        <Legend />
        <Bar dataKey="rfq-received" stackId="a" fill={colors[0]} />
        <Bar dataKey="sent-to-suppliers" stackId="a" fill={colors[1]} />
        <Bar dataKey="supplier-response-received" stackId="a" fill={colors[2]} />
        <Bar dataKey="estimate-sent" stackId="a" fill={colors[3]} />
        <Bar dataKey="estimate-response-received" stackId="a" fill={colors[4]} />
        <Bar dataKey="order-awarded" stackId="a" fill={colors[5]} />
      </BarChart>
    </ResponsiveContainer>
  );
};
