import * as React from 'react';
import { Button, Segment, Table, Popup, Label } from 'semantic-ui-react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { FormattedDate, FormattedTime } from 'react-intl';
// import { FilterForm } from './FilterForm';

import { useList, PageHeaderRow, EmptyWrapper, PaginatorWrapper } from '@inkcloud/components';

interface ListProps {}

const serviceName = '/shipping/v2/easypost-carrier-accounts';
const pathPrefix = '/settings/carrier-account';

const filterQueryMap = (values) => {};

export const List: React.FunctionComponent<ListProps & RouteComponentProps<any>> = (props) => {
  const {
    match: { params },
    location,
    history,
  } = props;

  const {
    state,
    reload,
    handleDelete,
    selectedItems,
    clearAll,
    setChecked,
    someSelected,
    toggleAll,
    filter,
    setFilter,
    sort,
    handleSort,
  } = useList({
    feathers: ['find', serviceName, [{ query: {} }]],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  const { queryResult, isLoading: isStateLoading } = state as any;

  if (!queryResult) {
    return null;
  }

  return (
    <div>
      <PageHeaderRow header="Carrier Account" subheader="Settings">
        <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
      </PageHeaderRow>

      <EmptyWrapper queryResult={queryResult.data} isStateLoading={isStateLoading}>
        <Segment loading={state.isLoading}>
          <PaginatorWrapper.Top
            filter={filter}
            reload={reload}
            data={(queryResult as any).data}
            pathPrefix={`${pathPrefix}`}
          />
          <Table size={'small'} sortable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Date</Table.HeaderCell>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Description</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {(queryResult as any)?.data.map((r: any, index: number) => (
                <Table.Row key={index} verticalAlign="top">
                  <Table.Cell>
                    {r.createdAt && (
                      <Popup
                        size="mini"
                        content={<FormattedTime value={r.createdAt} />}
                        trigger={<FormattedDate value={r.createdAt} />}
                      />
                    )}
                  </Table.Cell>
                  <Table.Cell>{r.name}</Table.Cell>
                  <Table.Cell>{r.description}</Table.Cell>
                </Table.Row>
              )) ?? []}
            </Table.Body>
          </Table>
        </Segment>
      </EmptyWrapper>
    </div>
  );
};
