/* eslint-disable no-undef */
import React, { useEffect, useState, useRef } from 'react';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Switch,
  Alert,
  Divider,
  AlertIcon,
} from '@chakra-ui/react';

import { Select } from 'chakra-react-select';

import type { ModelTypes } from '@inkcloud/icapi-types';

import { icapi } from '../../bootstrap/feathers';

import Result from '../settings/printables/PrintableTester/Result';

interface ISendModalProps {
  open: boolean;
  onClose: () => void;
  onReload?: () => void;
  purchaseOrderId: string;
  contact?: {
    name?: string;
    email?: string;
  };
  purchaseOrder: ModelTypes.PurchaseOrder & { vendor?: { _id: string } };
}

type payLoadType = {
  to?: {
    email?: string;
    name?: string;
  };
  cc?: {
    email?: string;
    name?: string;
  };
  message?: string;
  purchaseOrderId: string;
};

export const SendModal: React.FunctionComponent<ISendModalProps> = (props) => {
  const { onClose, onReload, purchaseOrderId, contact, purchaseOrder } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [customerOptions, setCustomerOptions] = useState<{ label: string; value: string }[]>([]);
  const [customer, setCustomer] = useState<string | undefined>();
  const [convertToOrder, setConvertToOrder] = useState(
    !(purchaseOrder.salesOrder || purchaseOrder.createdFromRfq)
  );

  const [users, setUsers] = useState<ModelTypes.User[]>([]);

  const loadCustomers = async () => {
    try {
      const res = (await icapi.service('customers').find({
        query: {
          $np: 1,
          $sort: { organizationName: 1, billingClientName: 1, 'primaryContact.firstName': 1 },
        },
      })) as ModelTypes.Customers[];

      const filteredData = res?.reduce((acc: { label: string; value: string }[], cur) => {
        if (cur?.organizationName) {
          let label = `${cur?.organizationName ?? ''}`;
          if (cur.billingClientName) {
            label = `${label} - ${cur?.billingClientName ?? ''}`;
            if (cur.billingClientId) {
              label = `${label} (${cur?.billingClientId ?? ''})`;
            }
          } else if (cur?.primaryContact?.firstName && cur?.primaryContact?.firstName !== '_') {
            label = `${label} - ${cur?.primaryContact?.firstName ?? ''} ${
              cur?.primaryContact?.lastName ?? ''
            }`;
          }

          acc.push({
            label,
            value: cur._id ?? '',
          });
        }

        return acc;
      }, []);

      setCustomerOptions(filteredData);
      // setCustomer(filteredData?.[0]?.value)
    } catch (e) {
      console.error(e);
    }
  };

  const loadSuppliersUsers = async () => {
    if (!purchaseOrder.vendor) {
      return;
    }

    try {
      const res = (await icapi
        .service('users')
        .find({ query: { vendor: purchaseOrder.vendor?._id } })) as { data: ModelTypes.User[] };

      setUsers(res?.data ?? []);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    loadSuppliersUsers();

    loadCustomers();
  }, []);

  const handleSubmit = async () => {
    setIsSubmitting(true);

    setErrorMessage('');

    const payload = {
      purchaseOrderId,
      convertToOrder,
      customer,
    };

    if (!convertToOrder) {
      payload.customer = undefined;
    }

    try {
      await icapi.service('purchase-orders/sends').create(payload);

      onReload?.();
      onClose();
    } catch (e) {
      const errMsg = e?.code < 500 ? e?.message : 'Something went wrong. Please try again later.';

      setErrorMessage(errMsg);
    }

    setIsSubmitting(false);
  };

  return (
    <>
      <Modal isOpen={true} size="6xl" onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Send Puchase Order</ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            {!purchaseOrder.vendor && (
              <Alert status="error" my={3}>
                This PO does not have a vendor selected. Please select a vendor before sending.
              </Alert>
            )}

            <Box p={3} borderWidth="1px" borderRadius="md" mb={3}>
              <Table variant="simple" size="sm">
                <Thead>
                  <Tr>
                    <Th>Name</Th>
                    <Th>Email</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {users?.map((user) => (
                    <Tr key={user._id}>
                      <Td>
                        {user?.firstName ?? ''} {user?.lastName ?? ''}
                      </Td>
                      <Td>{user?.email ?? ''}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Box>
            {!purchaseOrder.salesOrder && !purchaseOrder.createdFromRfq && (
              <Box p={8} borderWidth="1px" borderRadius="md" mb={3} bgColor={'gray.50'}>
                <Alert status="info" mb={8}>
                  <AlertIcon />
                  When sending this PO, you can also generate an order. To do so, select a client
                  below.
                </Alert>
                <FormControl display="flex" alignItems="center" size={'lg'}>
                  <FormLabel size={'lg'}>Create an Order</FormLabel>
                  <Switch
                    size={'lg'}
                    colorScheme={'blue'}
                    defaultChecked={true}
                    onChange={(e) => setConvertToOrder(e.target.checked)}
                  />
                </FormControl>

                {convertToOrder && (
                  <>
                    <Divider py={8} />
                    <FormControl>
                      <FormLabel>Select Client</FormLabel>
                      <Select
                        placeholder={'Select a client...'}
                        onChange={(v) => setCustomer(v?.value ?? '')}
                        isClearable
                        useBasicStyles
                        options={customerOptions}
                        value={customerOptions?.find((o) => o.value === customer)}
                      />
                    </FormControl>
                  </>
                )}
              </Box>
            )}
            <Result
              documentType="purchase-order"
              source=""
              styles={{}}
              humanId={String(purchaseOrder?.humanId) ?? ''}
            />

            {errorMessage && (
              <Alert status="error" my={3}>
                {errorMessage}
              </Alert>
            )}
          </ModalBody>

          <ModalFooter>
            <Button mr={3} onClick={onClose}>
              Close
            </Button>
            <Button
              colorScheme="purple"
              isDisabled={isSubmitting || (convertToOrder && !customer) || !purchaseOrder.vendor}
              isLoading={isSubmitting}
              onClick={handleSubmit}
            >
              Send
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
