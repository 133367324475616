// import * as React from 'react';
// import { Button, Checkbox, Header, Segment, Table, Label, Popup } from 'semantic-ui-react';
// import { Link, RouteComponentProps } from 'react-router-dom';
// import { FormattedDate } from 'react-intl';
// import { useList, PageHeaderRow, PreLoader, PaginatorWrapper, EmptyWrapper } from '@inkcloud/components';
// import { FilterForm } from './../../microsites/content-email-templates/FilterForm';
// import Feathers from '../../../bootstrap/feathers';

// interface ListProps { }

// const serviceName = '/notification/template';

// const filterQueryMap = (values) => {
//   const filterMap: any = {
//     key: values.key ? { $LIKE: values.key } : undefined,
//     subject: values.subject ? { $LIKE: values.subject } : undefined,
//   };

//   return [].reduce((m, field) => ({ ...m, [field]: values[field] ? values[field] : undefined }), filterMap);
// };

// const { useState, useEffect } = React

// export const List: React.FunctionComponent<ListProps & RouteComponentProps> = (props) => {
//   // const { match: { params }, location, history } = props;
//   // const { state, reload, handleDelete, selectedItems, clearAll, setChecked, someSelected, toggleAll, filter, setFilter, sort, handleSort } = useList({
//   //   feathers: ['find', serviceName, [{ query: { $sort: { url: 1 }, $select: ['createdAt', 'key', 'subject', 'isMjml'] } }]],
//   //   filterMap: filterQueryMap,
//   //   debounce: 150,
//   //   location,
//   //   history,
//   //   sort: { key: -1 }
//   // })

//   // const { queryResult, isLoading: isStateLoading } = state as any;
//   const [keyList, setKeyList] = useState([])
//   const [activeList, setActiveList] = useState([])
//   const [isLoading, setIsLoading] = useState(false)

//   useEffect(() => {

//     const loadKeyList = async () => {
//       setIsLoading(true)
//       try {
//         const res: any = await Feathers.service('/notification/template-list').create({}, { query: { $np: 1, } })
//         const sorted = res.sort()
//         setKeyList(sorted)
//         setIsLoading(false)
//       } catch (e) {
//         setIsLoading(false)

//       }
//     }

//     const loadKeyActive = async () => {

//       try {
//         const res: any = await Feathers.service('/notification/template').find({ query: {} })
//         const sorted = res.data.sort((a, b) => (a.key > b.key) ? 1 : ((b.key > a.key) ? -1 : 0));
//         setActiveList(sorted)
//       } catch (e) {

//       }
//     }

//     loadKeyActive()
//     loadKeyList()
//   }, [])

//   // if (!queryResult) {
//   //   return <PreLoader />;
//   // }

//   const pathPrefix = `/settings/notification-templates`;

//   const findKey = (key: string) => activeList.find(active => active.key === key)

//   return (
//     <div>
//       <PageHeaderRow header='Email Templates' subheader='Notification'>
//         <Link to={'/settings'}><Button content="Back" size={'mini'} /></Link>
//         {/* <Link to={`${pathPrefix}/add`}><Button primary content="New" size={'mini'} /></Link> */}
//       </PageHeaderRow>

//       {/* <FilterForm value={filter} onChange={(v) => setFilter(v)} onReset={() => setFilter({})} /> */}
//       <EmptyWrapper queryResult={keyList} isStateLoading={isLoading}>
//         <Segment loading={isLoading}>
//           {/* <PaginatorWrapper.Top filter={filter} reload={reload} data={(queryResult as any)} pathPrefix={`${pathPrefix}`} /> */}
//           <Table size={'small'} sortable>
//             <Table.Header>
//               <Table.Row>
//                 <Table.HeaderCell>
//                   Key
//               </Table.HeaderCell>
//                 <Table.HeaderCell>Status</Table.HeaderCell>
//                 <Table.HeaderCell />
//               </Table.Row>
//             </Table.Header>
//             <Table.Body>
//               {keyList?.map(r => (
//                 <Table.Row key={r}>
//                   <Table.Cell>
//                     {r}
//                   </Table.Cell>
//                   <Table.Cell>
//                     {findKey(r) ? <Label color="green">Custom</Label> : <Label color="orange">Default</Label>}
//                   </Table.Cell>
//                   <Table.Cell textAlign="right">
//                     <Popup
//                       size='mini'
//                       content="Edit"
//                       trigger={
//                         <Link to={`/settings/notification-templates/${r}${findKey(r) ? `/${findKey(r)._id}` : ''}`}>
//                           <Button size={'mini'} icon={'edit'} />
//                         </Link>
//                       }
//                     />
//                   </Table.Cell>
//                 </Table.Row>
//               )) ?? null}
//             </Table.Body>
//           </Table>
//           {/* <PaginatorWrapper.Bottom reload={reload} data={(queryResult as any)} pathPrefix={`${pathPrefix}`}>
//             {someSelected && <Button primary type="button" content="Delete" onClick={handleDelete} />}
//           </PaginatorWrapper.Bottom> */}
//         </Segment>
//       </EmptyWrapper>
//     </div>
//   );
// }

// import * as React from 'react';
// import { Button, Segment, Table, Popup, Label } from 'semantic-ui-react';
// import { Link, RouteComponentProps } from 'react-router-dom';
// import { FormattedDate, FormattedTime } from 'react-intl';
// // import { FilterForm } from './FilterForm';

// import { useList, PageHeaderRow, PreLoader, EmptyWrapper, PaginatorWrapper } from '@inkcloud/components';

// interface ListProps { }

// const serviceName = '/tenant-settings/notification-settings';
// const pathPrefix = '/settings/notification-rules';

// const filterQueryMap = (values) => { };

// export const List: React.FunctionComponent<ListProps & RouteComponentProps<any>> = (props) => {
//   const { match: { params }, location, history } = props;

//   const { state, reload, handleDelete, selectedItems, clearAll, setChecked, someSelected, toggleAll, filter, setFilter, sort, handleSort } = useList({
//     feathers: ['find', serviceName, [{ query: {} }]],
//     filterMap: filterQueryMap,
//     debounce: 150,
//     location,
//     history,
//     sort: { createdAt: -1 }
//   })

//   const { queryResult, isLoading: isStateLoading } = state as any;

//   if (!queryResult) {
//     return null;
//   }

//   return (
//     <div>
//       <PageHeaderRow header="Notification Rules" subheader="Settings">
//         <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
//         <Link to={`${pathPrefix}/add`}>
//           <Button content="New" primary size={'mini'} />
//         </Link>
//       </PageHeaderRow>

//       <EmptyWrapper queryResult={queryResult} isStateLoading={isStateLoading}>
//         <Segment loading={state.isLoading}>
//           <PaginatorWrapper.Top filter={filter} reload={reload} data={(queryResult as any)} pathPrefix={`${pathPrefix}`} />
//           <Table size={'small'} sortable>
//             <Table.Header>
//               <Table.Row>

//                 <Table.HeaderCell>
//                   Key
//               </Table.HeaderCell>
//                 <Table.HeaderCell>
//                   Enable
//               </Table.HeaderCell>
//                 <Table.HeaderCell />
//               </Table.Row>
//             </Table.Header>
//             <Table.Body>
//               {queryResult && (queryResult as any).map((r: any, index: number) => (
//                 <Table.Row key={index} verticalAlign="top">

//                   <Table.Cell>
//                     {r.templateKey}
//                   </Table.Cell>
//                   <Table.Cell>
//                     <Label color={r.enable ? 'green' : 'red'} content={r.enable ? 'True' : 'False'} />
//                   </Table.Cell>
//                   <Table.Cell textAlign="right">
//                     <Popup
//                       size='mini'
//                       content="Edit"
//                       trigger={
//                         <Link to={`${pathPrefix}/${r.templateKey}`}>
//                           <Button size={'mini'} icon={'edit'} />
//                         </Link>
//                       }
//                     />
//                   </Table.Cell>

//                 </Table.Row>
//               ))}
//             </Table.Body>
//           </Table>
//           <PaginatorWrapper.Bottom reload={reload} data={(queryResult as any)} pathPrefix={`${pathPrefix}`}>
//             {someSelected && <Button primary type="button" content="Delete" onClick={handleDelete} />}
//           </PaginatorWrapper.Bottom>
//         </Segment>
//       </EmptyWrapper>
//     </div>
//   );
// }

import React, { useState, useEffect } from 'react';
import {
  Button,
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  Spinner,
  Tag,
  Tooltip,
  Flex,
  Icon,
  Text,
  ButtonGroup,
  Heading,
  Spacer,
} from '@chakra-ui/react';

import { Link, RouteComponentProps } from 'react-router-dom';

import { FaEdit } from 'react-icons/fa';
import { BiNote } from 'react-icons/bi';

import { PageHeaderRow } from '@inkcloud/components';

import type { ModelTypes } from '@inkcloud/icapi-types';

import Feathers from '../../../bootstrap/feathers';

interface ListProps {}

const pathPrefix = '/settings/notification-templates';

export default function List(props: ListProps & RouteComponentProps) {
  const {
    match: { params },
    location,
    history,
  } = props;

  const [keyList, setKeyList] = useState<string[]>([]);
  const [activeList, setActiveList] = useState<ModelTypes.NotificationTemplates[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadKeyList = async () => {
      try {
        const res: any = await Feathers.service('/notification/template-list').create(
          {},
          { query: { $np: 1 } }
        );
        const sorted = res.sort();
        setKeyList(sorted);
      } catch (e) {
        console.error(e);
      }
      setIsLoading(false);
    };

    const loadKeyActive = async () => {
      try {
        const res: any = await Feathers.service('/notification/template').find({ query: {} });
        const sorted = res.data.sort((a, b) => {
          if (a.key > b.key) {
            return 1;
          }
          return -1;
        });
        setActiveList(sorted);
      } catch (e) {
        console.error(e);
      }
    };

    loadKeyActive();
    loadKeyList();
  }, []);

  const findKey = (key: string) => activeList?.find((active) => active.key === key);

  if (isLoading) {
    return (
      <Flex justifyContent="center" p={8}>
        <Spinner />
      </Flex>
    );
  }

  return (
    <Box>
      <PageHeaderRow header="Email Templates" subheader="Settings">
        <ButtonGroup variant="outline" spacing="2" mr={2}>
          <Button onClick={() => history.goBack()} size="sm">
            Back
          </Button>
        </ButtonGroup>
      </PageHeaderRow>

      {keyList?.length ? (
        <Table variant="striped" size="sm">
          <Thead>
            <Tr>
              <Th>Key</Th>
              <Th>Status</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {keyList.map((key, index) => (
              <Tr key={index}>
                <Td>{key}</Td>
                <Td>
                  {findKey(key) ? (
                    <Tag colorScheme="green">Custom</Tag>
                  ) : (
                    <Tag colorScheme="orange">Default</Tag>
                  )}
                </Td>
                <Td textAlign="right">
                  <Tooltip label="Edit Details">
                    <Link to={`${pathPrefix}/${key}${findKey(key) ? `/${findKey(key)?._id}` : ''}`}>
                      <IconButton colorScheme="gray" aria-label="Edit" icon={<FaEdit />} />
                    </Link>
                  </Tooltip>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      ) : (
        <Flex alignItems="center" justifyContent="center">
          <Box p={5} textAlign="center">
            <Icon as={BiNote} boxSize={10} />
            <Text>There's no data</Text>
          </Box>
        </Flex>
      )}
    </Box>
  );
}
