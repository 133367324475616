import * as React from 'react';
import { Switch, Route } from 'react-router-dom';

import { List } from './List';
import { List as ListOverall } from './List-overall';
import { Detail } from './Detail';
import OrderItems from './items';
import OrderBuilder from './builder';
import ShipOutAdvanced from './ship-out';

const routes = (props) => (
  <Switch>
    <Route exact path="/orders" component={List} />
    <Route exact path="/orders/overall" component={ListOverall} />
    <Route path="/orders/builder" component={OrderBuilder} />
    <Route path="/orders/items" component={OrderItems} />
    <Route exact path="/orders/ship-out/:id" component={ShipOutAdvanced} />
    <Route exact path="/orders/:id" component={Detail} />
  </Switch>
);

export default routes;
