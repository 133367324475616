// import * as React from 'react';
// import { Form, Segment, Button, Message } from 'semantic-ui-react';
// import { AgGridReact } from 'ag-grid-react';
// import { ColumnApi, GridApi } from 'ag-grid';

// import { Link, RouteComponentProps } from 'react-router-dom';
// import { PageHeaderRow } from '@inkcloud/components';
// import { ReportInputs } from '../ReportInputForm';
// import Feathers from '../../../bootstrap/feathers';
// import { ExportModal } from '../ExportModal';

// import 'ag-grid-community/dist/styles/ag-grid.css';
// import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

// const { useState } = React;

// interface InventoryLocationsProps extends RouteComponentProps<any> {}

// function loadData(values, isExport = false) {
//   const params: any = { query: {} };

//   delete values.end;
//   delete values.start;

//   if (values.microsites && values.microsites.length === 0) {
//     delete values.microsites;
//   }

//   if (isExport) {
//     params.query.$export = 1;
//   }

//   return Feathers.service('/reports/v2/microsite/inventory-location').create(
//     {
//       ...values,
//     },
//     params
//   );
// }

// let containerStyle = {
//   height: 500,
//   // /width: 500
// };

// const columns = [
//   { headerName: 'SKU', field: 'key' },
//   { headerName: 'Category', field: 'category' },
//   { headerName: 'Name', field: 'staticName' },
//   { headerName: 'Description', field: 'description' },
//   { headerName: 'Total Inventory', field: 'totalInventory' },
//   { headerName: 'Total Allocated', field: 'totalAllocated' },
//   { headerName: 'Location Name', field: 'locationName' },
//   { headerName: 'Location ID', field: 'locationId' },
//   { headerName: 'Total Inventory In Location', field: 'totalInventoryInLocation' },
// ];

// export const InventoryLocations: React.FunctionComponent<InventoryLocationsProps> = (props) => {
//   const [microsites, setMicrosites] = useState([]);
//   const [isLoading, setIsLoading] = useState(false);
//   const [micrositeList, setMicrositeLists] = useState(null);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [exportUrl, setExportUrl] = useState(null);

//   const [values, setValues] = useState({ start: null, end: null, microsites: [] });
//   const [data, setData] = useState(null);

//   const [errorMessage, setErrorMessage] = useState('');

//   let columnApi;
//   let gridApi;

//   const handleSubmit = async () => {
//     try {
//       setIsLoading(true);
//       const result = await loadData(values);
//       setData(result);
//       setIsLoading(false);
//     } catch (e) {
//       setIsLoading(false);
//       e.code && e.code < 500
//         ? setErrorMessage(e.message)
//         : setErrorMessage('We are experiencing technical difficulties. Please try again');
//     }
//   };

//   const onGridReady = (params: any) => {
//     gridApi = params.api;
//     columnApi = params.columnApi;
//     columnApi.autoSizeColumns(columns.map((c) => c.field));
//   };

//   React.useEffect(() => {
//     const loadMicrosites = async () => {
//       const list = (await Feathers.service('/microsites').find({
//         query: { $sort: { name: 1 }, $np: 1 },
//       })) as any;
//       setMicrositeLists(list.map((m) => ({ value: m._id, text: m.name })));
//     };

//     loadMicrosites();
//   }, []);

//   const handleExportClick = async () => {
//     setIsLoading(true);
//     const result = (await loadData(values, true)) as any;
//     setIsLoading(true);
//     setExportUrl(result.url);
//     setIsModalOpen(true);
//   };

//   const handleClose = () => {
//     setIsLoading(false);
//     setExportUrl(null);
//     setIsModalOpen(false);
//   };

//   return (
//     <div>
//       <PageHeaderRow header="Inventory Locations" subheader="Reports"></PageHeaderRow>
//       <ReportInputs
//         hideDates={true}
//         showOperations={false}
//         onSubmit={handleSubmit}
//         values={values}
//         onChange={(v) => setValues(v)}
//         micrositeList={micrositeList}
//       />
//       <Segment loading={isLoading}>
//         {!data && !errorMessage && <Message info content="Submit Form Above to view report data" />}
//         {errorMessage && <Message error content={errorMessage} />}

//         {data && data.length > 0 && (
//           <div style={{ textAlign: 'right' }}>
//             <Button primary content="Export Data" onClick={handleExportClick} />
//           </div>
//         )}
//         <br />
//         <div style={containerStyle} className="ag-theme-alpine">
//           {data && (
//             <AgGridReact
//               gridOptions={
//                 {
//                   useExternalSorting: false,
//                   enableSorting: true,
//                   columnDefs: columns,
//                 } as any
//               }
//               rowData={data}
//               onGridReady={onGridReady}
//               defaultColDef={{ resizable: true }}
//             />
//           )}
//         </div>
//       </Segment>
//       <ExportModal isModalOpen={isModalOpen} handleClose={handleClose} exportUrl={exportUrl} />
//     </div>
//   );
// };

import React, { useState } from 'react';
import { Box, Button } from '@chakra-ui/react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import { useHistory } from 'react-router-dom';
import { EmptyWrapper, PageHeaderRow } from '@inkcloud/components';
import Feathers from '../../../bootstrap/feathers';
import FilterForm from '../FilterForm2';
import { ExportModal } from '../ExportModal';

const columns = [
  { headerName: 'SKU', field: 'key' },
  { headerName: 'Category', field: 'category' },
  { headerName: 'Name', field: 'staticName' },
  { headerName: 'Description', field: 'description' },
  { headerName: 'Total Inventory', field: 'totalInventory' },
  { headerName: 'Total Allocated', field: 'totalAllocated' },
  { headerName: 'Location Name', field: 'locationName' },
  { headerName: 'Location ID', field: 'locationId' },
  { headerName: 'Total Inventory In Location', field: 'totalInventoryInLocation' },
];

let columnApi;
let gridApi;

const onGridReady = (params: any) => {
  gridApi = params.api;
  columnApi = params.columnApi;
  columnApi.autoSizeColumns(columns.map((c) => c.field));
};

function loadData(values, exportData = false) {
  const params: any = { query: {} };

  const microsites = values?.microsites?.map((p) => p.value);
  if (exportData) {
    params.query.$export = 1;
  }

  return Feathers.service('/reports/v2/microsite/inventory-location').create(
    {
      microsites,
    },
    params
  );
}

export default function InventoryLocations() {
  const history = useHistory();

  const [data, setData] = useState([]);
  const [exportUrl, setExportUrl] = useState('');

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isExporting, setIsExporting] = useState(false);

  const [values, setValues] = useState({
    start: null,
    end: null,
    microsites: undefined,
    tags: [],
    products: [],
    operations: '',
  });

  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async () => {
    setErrorMessage('');
    try {
      setIsSubmitting(true);
      const res = await loadData(values);
      setData(res);
    } catch (e) {
      const errMsg =
        e?.code < 500 ? e?.message : 'We are experiencing technical difficulties. Please try again';
      setErrorMessage(errMsg);
    }
    setIsSubmitting(false);
  };

  const handleExport = async () => {
    setErrorMessage('');
    try {
      setIsExporting(true);
      const res = await loadData(values, true);
      setExportUrl(res.url);
      setIsModalOpen(true);
    } catch (e) {
      const errMsg =
        e?.code < 500 ? e?.message : 'We are experiencing technical difficulties. Please try again';
      setErrorMessage(errMsg);
    }
    setIsExporting(false);
    setIsSubmitting(false);
  };

  return (
    <>
      <PageHeaderRow header="Inventory Locations" subheader="Reports">
        <Button onClick={() => history.goBack()} mr={2}>
          Back
        </Button>
        <Button
          colorScheme="blue"
          onClick={handleExport}
          isDisabled={isSubmitting || isExporting || !data?.length}
          isLoading={isExporting}
        >
          Export Data
        </Button>
      </PageHeaderRow>

      <Box p={3} mb={3} borderWidth={1} borderRadius="md" bg="white">
        <FilterForm
          values={values}
          isSubmitting={isSubmitting}
          hasMicrosites
          isMultiMicrosites
          isDateRequired={false}
          errorMessage={errorMessage}
          onChange={(v) => {
            setErrorMessage('');
            setValues(v);
          }}
          onSubmit={handleSubmit}
        />
      </Box>

      <Box p={3} borderWidth={1} borderRadius="md" bg="white">
        <EmptyWrapper queryResult={data} isStateLoading={isSubmitting}>
          <Box h={data?.length ? 500 : 0} className="ag-theme-alpine">
            {data?.length > 0 && (
              <AgGridReact
                gridOptions={
                  {
                    useExternalSorting: false,
                    enableSorting: true,
                    columnDefs: columns,
                  } as any
                }
                rowData={data}
                onGridReady={onGridReady}
                defaultColDef={{ resizable: true }}
              />
            )}
          </Box>
        </EmptyWrapper>
      </Box>

      {isModalOpen && (
        <ExportModal
          isModalOpen={isModalOpen}
          handleClose={() => {
            setIsModalOpen(false);
            setExportUrl('');
          }}
          exportUrl={exportUrl}
        />
      )}
    </>
  );
}
