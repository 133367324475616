import * as React from 'react';
import { Form, Input, Button, Popup } from 'semantic-ui-react';
import Feathers from '../../../bootstrap/feathers';
import { useDebounce } from '@inkcloud/components';

interface ReferenceProps {
  cartId: string;
  itemId: string;
  customerReferenceName: string;
}

const { useState, useEffect } = React;

export const Reference: React.FunctionComponent<ReferenceProps> = (props) => {
  const { cartId, itemId, customerReferenceName } = props;
  const [value, setValue] = useState({ customerReferenceName });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isChanging, setIsChanging] = useState(false);

  const debounceValue = useDebounce(value, 350);
  const handleChange = (v: any) => {
    setIsChanging(true);
    setValue({ ...value, ...v });
  };

  const submit = async () => {
    setIsSubmitting(true);
    setIsChanging(false);
    await Feathers.service('cart/update-reference-name').create({
      cartId,
      itemId,
      ...value,
    });
    setIsSubmitting(false);
  };

  const handleSubmit = () => submit();

  useEffect(() => {
    if (isChanging) {
      submit();
    }
  }, [debounceValue]);

  return (
    <Form onSubmit={handleSubmit}>
      <Input
        size="mini"
        loading={isSubmitting}
        defaultValue={customerReferenceName}
        placeholder={'Job reference...'}
        onChange={(e, data) => handleChange({ customerReferenceName: data.value })}
      />
    </Form>
  );
};
