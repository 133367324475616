import * as React from 'react';
import { Switch, Route } from 'react-router-dom';

import { List } from './List';
import { Detail } from './Detail';
import BatchItems from './items';
import Status from './status';

const routes = (props) => (
  <Switch>
    <Route exact path="/production/batches" component={List} />
    <Route exact path="/production/batches/add" component={Detail} />
    <Route exact path="/production/batches/:id" component={Detail} />
    <Route exact path="/production/batches/:id/items" component={BatchItems} />
    <Route exact path="/production/batches/:id/status" component={Status} />
  </Switch>
);

export default routes;
