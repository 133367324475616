import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Textarea,
  Alert,
} from '@chakra-ui/react';

interface IAddNoteProps {
  title?: string;
  onClose: () => void;
  errorMessage?: string;
  isSubmitting?: boolean;
  onSubmit: (value: string) => void;
}

const AddNote = (props: IAddNoteProps, ref) => {
  const { errorMessage, title = 'Add a Note', isSubmitting = false, onSubmit, onClose } = props;
  const [noteContent, setNoteContent] = useState('');

  const handleSave = () => onSubmit(noteContent);

  return (
    <Modal isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <Textarea onChange={(e) => setNoteContent(e.target.value)} cols={6} />

          {errorMessage && (
            <Alert mt={3} status="error">
              {errorMessage}
            </Alert>
          )}
        </ModalBody>

        <ModalFooter>
          <Button isDisabled={isSubmitting} mr={3} onClick={onClose}>
            Close
          </Button>
          <Button
            isDisabled={isSubmitting}
            isLoading={isSubmitting}
            colorScheme="blue"
            onClick={handleSave}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddNote;
