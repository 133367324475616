import * as React from 'react';
import { Form, Button, Message } from 'semantic-ui-react';
import { PreLoader, To } from '@inkcloud/components';
import Feathers from './../../../bootstrap/feathers';

interface InternationalizationFormProps {
  value: any;
  id: string;
}

const mapKeys = (obj) =>
  obj ? Object.keys(obj).map((key) => ({ value: key, text: obj[key] })) : [];

const { useState, useEffect } = React;

export const InternationalizationForm: React.FunctionComponent<InternationalizationFormProps> = (
  props
) => {
  const { value, id } = props;

  const [payload, setPayload] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [internationalization, setInternationalization] = useState(null);

  useEffect(() => {
    const loadInternationalization = async () => {
      setIsLoading(true);
      const [error, res] = await To(
        Feathers.service('tenant-settings/intenationalization-options').find({ query: {} })
      );
      setInternationalization(res);

      setIsLoading(false);
      if (error) {
        setErrorMessage(error.message);
      }
    };

    loadInternationalization();
  }, []);

  const handleChange = (data) => {
    const { name, value } = data;
    setPayload({ ...payload, [name]: value });
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const [error, res] = await To(
      Feathers.service('/tenant-settings/internationalization/').patch(id, payload, { query: {} })
    );

    setIsLoading(false);
    if (error) {
      setErrorMessage(error.message);
    }
  };

  if (!internationalization) {
    return <PreLoader />;
  }

  const { locale, currency, countryCode } = internationalization;

  return (
    <Form loading={isLoading}>
      {errorMessage && <Message error content={errorMessage} />}

      <Form.Select
        fluid
        name="locale"
        label="Locale"
        defaultValue={value && value.locale}
        options={mapKeys(locale)}
        placeholder="Locale..."
        onChange={(e, data) => handleChange(data)}
      />
      <Form.Select
        fluid
        name="currency"
        label="Currency"
        defaultValue={value && value.currency}
        options={mapKeys(currency)}
        placeholder="Currency..."
        onChange={(e, data) => handleChange(data)}
      />
      <Form.Select
        fluid
        name="countryCode"
        label="Country Code"
        defaultValue={value && value.countryCode}
        options={mapKeys(countryCode)}
        placeholder="Country Code"
        onChange={(e, data) => handleChange(data)}
      />

      <Button primary disabled={isLoading} content="Save" onClick={handleSubmit} />
    </Form>
  );
};
