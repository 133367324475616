import * as React from 'react';
import {
  Button,
  Checkbox,
  Dimmer,
  Loader,
  Header,
  Grid,
  Message,
  Menu,
  Table,
  Segment,
} from 'semantic-ui-react';
import Items from './Items';
import Summary from './Summary';
import Payment from './../../invoice-adjuster/PaymentComponent';
import {
  PaymentData,
  PaymentMethod,
  PaymentCreditCardTypes,
  NewPaymentData,
} from './../../../types';

export interface ContainerProps {}

export interface ContainerState {
  items: any[];
  selectedToDelete: any[];
  paymentData: PaymentData;
}

export default class Container extends React.Component<ContainerProps, ContainerState> {
  constructor(props: ContainerProps) {
    super(props);
    this.state = {
      items: [
        {
          _id: '232323',
        },
        {
          _id: '99999',
        },
      ],
      selectedToDelete: [],
      paymentData: NewPaymentData(),
    };

    this.state.paymentData.amountDue = 50.0;
  }

  handleRemoveCheckboxClick = (e, data) => {};

  handlePaymentChange = (value) => {
    this.setState({ ...this.state, paymentData: value });
  };

  render() {
    return (
      <div>
        order builder
        <Items
          items={this.state.items}
          selectedToDelete={this.state.selectedToDelete}
          handleRemoveCheckboxClick={this.handleRemoveCheckboxClick}
        />
        <Summary subtotal={0.0} salesTax={0.0} discount={0.0} total={0.0} />
        <Payment {...this.state.paymentData} onChange={this.handlePaymentChange} />
        <Segment>
          <Button primary content={'Create Order'} />
        </Segment>
      </div>
    );
  }
}
