import * as React from 'react';
import {
  Avatar,
  Box,
  Flex,
  Icon,
  IconButton,
  Link,
  Spacer,
  Text,
  CloseButton,
} from '@chakra-ui/react';

import { CloseIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import { FormattedDate, FormattedTime } from 'react-intl';
import { ModelTypes } from '@inkcloud/icapi-types';

interface IMessageItemProps {
  value: ModelTypes.UserMessage;
  onMarkRead: (userMessageId: string) => void;
  onMarkDelete?: (userMessageId: string) => void;
}

const MessageItem: React.FunctionComponent<IMessageItemProps> = (props) => {
  const { value, onMarkRead, onMarkDelete } = props;

  return (
    <Box>
      <Flex onClick={() => onMarkRead(value._id as string)} _hover={{ cursor: 'grab' }}>
        <Avatar name={value.actionByLabel} size={'sm'} />
        <Box ml={4}>
          <Text fontSize={'sm'}>
            {value.actionByLabel} {value.message}
          </Text>
          {/* <Link>
            <Text fontSize={'xs'} ><Text as='b'>{value.subjectLabel}</Text>
            <Text as='sub'></Text><ExternalLinkIcon ml={2} mx='2px' /></Text>
          </Link> */}
        </Box>
        <Spacer />
        <Box>
          <Flex>
            <Spacer />
            {!value.isRead && (
              <Icon viewBox="0 0 200 200" color="blue.500" mt={1} mr={2}>
                <path
                  fill="currentColor"
                  d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
                />
              </Icon>
            )}

            {/* <CloseButton onClick={() => onMarkRead(_id)} /> */}
            {/* <IconButton icon={<CloseIcon />} aria-label='Close' size='xs' variant={'ghost'} /> */}
          </Flex>
        </Box>
      </Flex>
      <Box textAlign={'right'}>
        <Text color={'gray.700'} fontSize="xs" as="i">
          <FormattedDate value={value.createdAt} />{' '}
        </Text>{' '}
        <Text fontSize="xs" color={'gray.400'} as="i">
          <FormattedTime value={value.createdAt} />
        </Text>
      </Box>
    </Box>
  );
};

export default MessageItem;
