export function filterReducers(state: any = {}  , action: any) {
  const updatedPage = { ...state};
  switch(action.type) {
    case `LIST_SET_FILTER`:
      updatedPage[action.namespace] = {
        filter: action.filter,
        isFiltered: true,
      }
      return {...state, ...updatedPage };

    case `LIST_CLEAR_FILTER`:
      updatedPage[action.namespace] = {
        filter: {},
        isFiltered: false,
      }
      return {...state, ...updatedPage };

    default:
      return state;
  }
}
