import React, { useState, useEffect, useMemo } from 'react';
import {
  Stack,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Button,
  IconButton,
  Flex,
  Text,
} from '@chakra-ui/react';

import { FaTimes } from 'react-icons/fa';

import { Select } from 'chakra-react-select';

import type { ModelTypes } from '@inkcloud/icapi-types';

import type { AttributeType } from '../types';

type attrType = Exclude<
  NonNullable<NonNullable<ModelTypes.Rfq['items']>[number]['components']>[number]['attributes'],
  undefined | null
>;

interface IAttributesProps {
  attributes: AttributeType[];
  // values: attrType;
  values: any;
  onChange: (value: IAttributesProps['values']) => void;
}

export default function Attributes(props: IAttributesProps) {
  const { attributes, values, onChange } = props;

  const [checkedTypes, setCheckedTypes] = useState({});

  useEffect(() => {
    const t = values?.reduce((acc, cur) => {
      if (cur.type) {
        acc[cur.type] = true;
      }

      if (cur.otherType) {
        acc[cur.otherType] = true;
      }

      return acc;
    }, {});

    setCheckedTypes(t);
  }, []);

  // console.log('attributes', attributes)

  const attributeTypes = useMemo(
    () =>
      [...(values || []), ...(attributes || [])]?.reduce((acc, cur) => {
        if (!acc[cur.otherType] && cur.otherType !== undefined) {
          // console.log('acc[cur.otherType]', acc[cur.otherType])

          acc[cur.otherType] = {
            name: cur.otherType,
            type: 'other',
            value: cur.otherValue,
          };
        } else if (!acc[cur.typeId] && cur.typeId !== undefined) {
          acc[cur.typeId] = {
            name: cur.typeName,
            attributes: [{ value: 'OTHER', label: 'Other...' }],
          };
        }

        if (acc[cur.typeId]) {
          acc[cur.typeId].attributes = acc[cur.typeId]?.attributes?.concat([
            { value: cur._id, label: cur.name },
          ]);
        }

        const t = Object.keys(acc)?.sort((a, b) => (a > b ? 1 : -1));

        const sorted = t?.reduce((innerAcc, innerCur) => {
          innerAcc[innerCur] = acc[innerCur];

          return innerAcc;
        }, {});

        return sorted;
      }, {}),
    [attributes, values]
  );

  const handleChange = (value: Record<string, string>) => {
    let newValues;

    const isOtherType = value.otherType;

    const found = values?.find((v) =>
      isOtherType ? v.otherType === value.otherType : v.type === value.type
    );

    if (found) {
      newValues = values?.map((v) => {
        if (isOtherType && v.otherType === value.otherType) {
          return { ...v, otherValue: value.otherValue };
        }
        if (value.type !== undefined && v.type === value.type) {
          return { ...v, ...value };
        }

        return v;
      });
    } else {
      newValues = [...(values || []), value];
    }

    onChange(newValues);
  };

  // eslint-disable-next-line no-undef
  const handleCheck = (attribute: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;

    // console.log('attribute', attribute, 'checked', checked)

    setCheckedTypes({ ...checkedTypes, [attribute]: checked });

    if (!checked) {
      const newValues = values?.filter((v: Record<string, string>) => v.type !== attribute);
      onChange(newValues);
    }
  };

  const handleRemove = (attribute: string) => () => {
    const newValues = values?.filter((v: Record<string, string>) => v.otherType !== attribute);
    onChange(newValues);
  };

  const handleAdd = () => {
    const newValues = [...(values || []), { otherType: '', otherValue: '' }];
    onChange(newValues);
  };

  return (
    <>
      {/* <Button size="sm" onClick={handleAdd}>
        Add Other
      </Button> */}
      {attributeTypes &&
        Object.keys(attributeTypes)
          ?.filter((k) => k !== 'SIZE')
          .map((key, idx) => {
            const a = attributeTypes[key];

            const isOther = values?.find(
              (v) => v?.type === key && (v?.value === 'OTHER' || v?.value === undefined)
            );

            const found = values?.find((v) => v?.type === key);
            const attrName = a.attributes?.find(
              (attr) => attr.value === (found?.value || 'OTHER')
            )?.label;

            // console.log('isOther', isOther, 'found', found, 'attrName', attrName)
            // console.log('a', a)

            const isOtherType = a.type === 'other';

            return (
              <Stack key={key} spacing={7} mb={3} direction="row">
                <FormControl>
                  {isOtherType ? (
                    <Flex alignItems="center">
                      <IconButton
                        size="xs"
                        aria-label="remove"
                        icon={<FaTimes />}
                        mr={1}
                        onClick={handleRemove(key)}
                      />
                      <Text fontSize="sm">{a.name}</Text>
                    </Flex>
                  ) : (
                    <Checkbox isChecked={checkedTypes?.[key]} onChange={handleCheck(key)}>
                      {a.name}
                    </Checkbox>
                  )}
                </FormControl>
                {checkedTypes?.[key] && a.type !== 'other' && (
                  <FormControl>
                    <Select
                      useBasicStyles
                      options={a.attributes}
                      defaultValue={
                        found
                          ? { label: attrName, value: found?.value || found?.otherValue }
                          : undefined
                      }
                      onChange={(e) =>
                        handleChange({
                          type: key,
                          value: e?.value,
                        })
                      }
                    />
                  </FormControl>
                )}
                {(isOther || isOtherType) && (
                  <FormControl>
                    <Input
                      defaultValue={
                        isOtherType ? a.value : values?.find((v) => v?.type === key)?.otherValue
                      }
                      onChange={(e) => {
                        let args = {
                          type: key,
                          value: a.type !== 'other' && isOther.value,
                          otherValue: e.target.value,
                        } as {
                          type?: string;
                          value?: string;
                          otherValue: string;
                          otherType?: string;
                        };

                        if (isOtherType) {
                          args = {
                            otherType: key,
                            otherValue: e.target.value,
                          };
                        }
                        // console.log('args', args)
                        handleChange(args);
                      }}
                    />
                  </FormControl>
                )}
              </Stack>
            );
          })}

      {/* {Object.keys(attributeTypes)?.length > 10 && (
        <Button size="sm" onClick={handleAdd}>
          Add Other
        </Button>
      )} */}
    </>
  );
}
