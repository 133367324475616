import * as React from 'react';
import { Button, Header, Form, Input, Image, Modal, Message, Icon } from 'semantic-ui-react';
import * as superagent from 'superagent';
import Dropzone from 'react-dropzone';
declare var APPENV;

import MessageAdd from './MessageAdd';
const uploadUrl = '/uploads-general';

const dropzoneStyle = {
  // width: 200,
  minHeight: 75,
  borderWidth: 2,
  borderColor: '#eaeaea',
  borderStyle: 'dashed',
  borderRadius: 0,
  width: '100%',
  marginTop: 18,
  marginBottom: 18,
};

export interface PMApprovalProps {
  open: boolean;
  orderItem: any;
  handleClose: any;
  handleSubmit: any;
}

export interface PMApprovalState {
  files: any[];
  completedUploadIds: any[];
  sendEmail: boolean;
}

export default class PMApproval extends React.Component<PMApprovalProps, PMApprovalState> {
  constructor(props: PMApprovalProps) {
    super(props);

    this.state = {
      files: [],
      completedUploadIds: [],
      sendEmail: false,
    };
  }

  handleSubmit = (values) => {
    this.props.handleSubmit({
      sendEmail: this.state.sendEmail,
    });
  };

  onDrop = (acceptedFiles, rejectedFiles) => {
    // do stuff with files...
    let req = superagent.post(`${APPENV.api.connection}${uploadUrl}`);
    // req.send(`tenant=${this.props.tenantId}`)
    req.timeout(1000 * 60 * 10);
    acceptedFiles.forEach((file) => {
      /* TODO - TAKE FIELDS PROPS AND MAP HERE */
      // req.field('id', this.props.orderItemId)
      // req.field('tenantId', this.props.tenantId);
      req.attach('miscFile', file, file.name);
      this.setState({ ...this.state, files: this.state.files.concat([file]) });
    });
    req.end((err, res) => {
      this.setState({
        ...this.state,
        files: this.state.completedUploadIds.concat(res.body.map((f) => f._id)),
      });

      // this.setState({...this.state, files: this.state.files.map( f => (f.isComplete: true )})
      // this.setState({...this.state, isUploading: false});
      //   if (!err) {
      //       this.setState({...this.state, isComplete: true});
      //       this.props.onUpload({ res: res.body });
      //   }
    });
  };

  render() {
    return (
      <Modal open={this.props.open} onClose={this.props.handleClose} closeIcon>
        <Modal.Header>Certification</Modal.Header>
        <Modal.Content>
          <Message size={'small'}>
            Attach certified files below. You can optionally send an email to the customer as well.
          </Message>
          <Form.Checkbox
            checked={this.state.sendEmail}
            onClick={() => this.setState({ ...this.state, sendEmail: !this.state.sendEmail })}
            label={'Send certification email to customer'}
          />
          {/* <Dropzone onDrop={this.onDrop} style={dropzoneStyle}>
            <div style={{textAlign: 'center', padding: 10 }}>
              <Icon size={'large'} name="upload" />
              <br/>
              <div style={{fontSize: 12}}>Drag and drop or click to upload</div>
            </div>
            <ul>
              {
                this.state.files.map(f => <li key={f.name}>{f.name} - {f.size} bytes</li>)
              }
            </ul>
          </Dropzone> */}
          <Button fluid primary onClick={this.handleSubmit}>
            Save and Send Certification
          </Button>
        </Modal.Content>
      </Modal>
    );
  }
}
