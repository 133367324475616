import * as React from 'react';
import { Modal, Form, Dropdown, Button } from 'semantic-ui-react';

interface StyleModalProps {
  isOpen: boolean;
  onClose: () => void;
  values: any;
  onChange: (name: string, value: string) => void;
}

const layoutOptions = [
  { text: 'Vertical', value: 'vertical' },
  { text: 'Horizontal', value: 'horizontal' },
];

const colorOptions = [
  { text: 'Gold', value: 'gold' },
  { text: 'Blue', value: 'blue' },
  { text: 'Silver', value: 'silver' },
  { text: 'White', value: 'white' },
  { text: 'Black', value: 'black' },
];

const shapeOptions = [
  { text: 'Rectangle', value: 'rect' },
  { text: 'Pill', value: 'pill' },
];

const labelOptions = [
  { text: 'Paypal', value: 'paypal' },
  { text: 'Checkout', value: 'checkout' },
];

export const StyleModal: React.FunctionComponent<StyleModalProps> = (props) => {
  const { isOpen, onClose, values, onChange } = props;

  return (
    <Modal size="tiny" open={isOpen} onClose={onClose} closeIcon>
      <Modal.Header>Styles</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Group widths="equal">
            <Form.Field>
              <label>Layout</label>
              <Dropdown
                name="layout"
                selection
                fluid
                component={Form.Dropdown}
                options={layoutOptions}
                value={values?.layout || layoutOptions[0].value}
                onChange={(e, data: any) => onChange(`paypal.style.${data.name}`, data.value)}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field>
              <label>Color</label>
              <Dropdown
                name="color"
                selection
                fluid
                component={Form.Dropdown}
                options={colorOptions}
                value={values?.color || colorOptions[0].value}
                onChange={(e, data: any) => onChange(`paypal.style.${data.name}`, data.value)}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field>
              <label>Shape</label>
              <Dropdown
                name="shape"
                selection
                fluid
                component={Form.Dropdown}
                options={shapeOptions}
                value={values?.shape || shapeOptions[0].value}
                onChange={(e, data: any) => onChange(`paypal.style.${data.name}`, data.value)}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field>
              <label>Label</label>
              <Dropdown
                name="label"
                selection
                fluid
                component={Form.Dropdown}
                options={labelOptions}
                value={values?.label || labelOptions[0].value}
                onChange={(e, data: any) => onChange(`paypal.style.${data.name}`, data.value)}
              />
            </Form.Field>
          </Form.Group>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button type="button" content="Done" onClick={onClose} />
      </Modal.Actions>
    </Modal>
  );
};
