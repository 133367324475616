import React from 'react';
import {
  Box,
  Button,
  Divider,
  Heading,
  IconButton,
  Input,
  // Select,
  Stack,
  FormControl as ChakraFormControl,
  FormErrorMessage,
} from '@chakra-ui/react';
import { DragHandleIcon, DeleteIcon } from '@chakra-ui/icons';
import { UseFormReturn, useFieldArray } from 'react-hook-form';
import { AsyncSelect, PropsValue, Select } from 'chakra-react-select';
import type { ModelTypes } from '@inkcloud/icapi-types';

// import { Select } from 'chakra-react-select';
import FormControl from '../../../common/v3/Form/FormControl';
import { Feathers } from '../../../bootstrap/feathers/feathers';

export interface IJobSchedulingWorkflowTemplateStepsProps {
  useFormReturn: UseFormReturn<{
    name: string;
    type: string;
    description?: string;
    steps: {
      column: string;
      resourceType?: null | string;
      resource?: undefined | string;
    }[];
  }>;
  columns: any[];
  // columns: ModelTypes.JobSchedulerColumns[];
  resources: ModelTypes.ProductionResources[];
}

export async function columnOptions(e): Promise<any> {
  try {
    const res = await Feathers.service('job-scheduler/columns').find({
      query: {
        // isGlobal: {
        //   $ne: true,
        // },
        name: e ? { $LIKE: e } : undefined,
        $sort: { name: 1 },
      },
    });

    return res?.data?.map((r) => ({ label: r?.name, value: r?._id }));
  } catch (er) {
    console.error(er);
    throw er;
  }
}

const resourceTypeOptions = [
  { label: 'Bindery', value: 'bindery' },
  { label: 'Coater', value: 'coater' },
  { label: 'Coiling', value: 'coiling' },
  { text: 'Collating', value: 'collating' },

  { label: 'Cutter', value: 'cutter' },
  { label: 'Die Cutter', value: 'die-cutter' },
  { label: 'Digital Press', value: 'digital-press' },
  { label: 'Folder', value: 'folder' },
  { label: 'Gluing', value: 'gluing' },
  { label: 'Laminator', value: 'laminator' },
  { label: 'Mounting', value: 'mounting' },
  { label: 'Offset Press', value: 'offset-press' },
  { label: 'Other', value: 'other' },
  { label: 'Press', value: 'press' },
  { label: 'Router', value: 'router' },
  { label: 'Stitcher', value: 'stitcher' },
  { label: 'Wide Format Cutting', value: 'wide-format-cutting' },
  { label: 'Wide Format Laminating', value: 'wide-format-laminating' },
  { label: 'Wide Format Plotter', value: 'wide-format-plotter' },
  { label: 'Wide Format Press', value: 'wide-format-press' },
];

export function JobSchedulingWorkflowTemplateSteps(
  props: IJobSchedulingWorkflowTemplateStepsProps
) {
  const { useFormReturn, columns, resources } = props;
  const {
    register,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useFormReturn;

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'steps', // unique name for your Field Array
  });

  return (
    <Box borderWidth={1} bgColor={'gray.50'} borderColor={'gray.200'} p={4} mb={4}>
      <Heading size={'sm'}>Workflow Steps</Heading>
      <Divider mb={8} />

      <Box mb={4}>
        <Stack gap={4} direction="row" mb={3}>
          <Box w="45px"></Box>
          <Box flex={1}>
            <Heading as="h4" size="sm">
              Column
            </Heading>
          </Box>
          <Box flex={1}>
            <Heading as="h4" size="sm">
              Resource Type
            </Heading>
          </Box>
          <Box flex={1}>
            <Heading as="h4" size="sm">
              Resource (Optional)
            </Heading>
          </Box>
          <Box w="45px"></Box>
        </Stack>
        {fields.map((field, index) => {
          const watchResourceType = watch(`steps.${index}.resourceType`);

          const resourceOptions = resources?.reduce(
            (acc: { label: string; value: string }[], cur) => {
              if (cur.type === watchResourceType) {
                acc.push({ label: cur?.name ?? '', value: cur?._id ?? '' });
              }

              return acc;
            },
            []
          );

          const watchColumn = watch(`steps.${index}.column`);

          return (
            <Stack
              key={index}
              gap={4}
              direction="row"
              verticalAlign={'top'}
              mb={2}
              borderWidth={1}
              bgColor={'white'}
              p={4}
            >
              <IconButton
                aria-label="Drag"
                size={'sm'}
                variant={'outline'}
                icon={<DragHandleIcon />}
                cursor={'grab'}
              />

              <Box w="100%">
                <ChakraFormControl isInvalid={!!errors?.steps?.[index]?.column}>
                  {/* <Select {...register(`steps.${index}.column`)} placeholder="" size="sm">
                    <option value=""></option>
                    {columns?.map((c) => (
                      <option key={c._id} value={c._id}>
                        {c.name}
                      </option>
                    ))}
                  </Select> */}
                  <AsyncSelect
                    useBasicStyles
                    placeholder=""
                    value={watchColumn as any}
                    cacheOptions
                    defaultOptions
                    loadOptions={columnOptions}
                    onChange={(e: { label: string; value: string } | null) =>
                      setValue(`steps.${index}.column`, e as any)
                    }
                  />
                  {errors?.steps?.[index]?.column && (
                    <FormErrorMessage>{errors?.steps?.[index]?.column?.message}</FormErrorMessage>
                  )}
                </ChakraFormControl>
              </Box>
              <Box w="100%">
                <Select
                  useBasicStyles
                  isClearable
                  placeholder=""
                  value={{
                    label: resourceTypeOptions?.find((c) => c.value === watchResourceType)?.label,
                    value: watchResourceType,
                  }}
                  options={resourceTypeOptions as any}
                  onChange={(e: { label: string; value: string } | any) =>
                    setValue(`steps.${index}.resourceType`, e?.value as any)
                  }
                />
                {/* <Select
                  size={'sm'}
                  useBasicStyles
                  isClearable
                  defaultValue={watchResourceType}
                  {...register(`steps.${index}.resourceType`)}
                  onChange={(e) => {
                    if (!e) {
                      setValue(`steps.${index}.resource`, null);
                    }
                    setValue(`steps.${index}.resourceType`, e);
                  }}
                  placeholder=""
                  options={resourseTypeOptions}
                /> */}
              </Box>
              <Box w="100%">
                {/* <Select
                  isDisabled={!watchResourceType}
                  {...register(`steps.${index}.resource`)}
                  placeholder=""
                  size="sm"
                >
                  <option value={undefined}></option>
                  {resourceOptions?.map((c) => (
                    <option key={c.value} value={c.value}>
                      {c.label}
                    </option>
                  ))}
                </Select>  */}
                <Select
                  useBasicStyles
                  size="sm"
                  isDisabled={!watchResourceType}
                  isClearable
                  options={resourceOptions as any}
                  placeholder=""
                  value={watch(`steps.${index}.resource`)}
                  onChange={(e) => {
                    setValue(`steps.${index}.resource`, e as any);
                  }}
                />
              </Box>

              {/* <Input size={'sm'} {...register(`steps.${index}.resource`)} /> */}

              <IconButton
                aria-label="Delete"
                size={'sm'}
                icon={<DeleteIcon />}
                onClick={() => remove(index)}
              />
            </Stack>
          );
        })}
      </Box>

      <Button
        size={'sm'}
        onClick={() => append({ column: '', resourceType: null, resource: undefined })}
      >
        Add Step
      </Button>
    </Box>
  );
}
