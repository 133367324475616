import { Box, Center, Heading, Button, Text } from '@chakra-ui/react';
import * as React from 'react';
import { Link } from 'react-router-dom';

interface IPageNotFoundProps {
}

const PageNotFound: React.FunctionComponent<IPageNotFoundProps> = (props) => {
  const a = '';

  return (
    <Box textAlign={'center'}>
      <Heading size={'2xl'} mb={'10'}>Page Not Found</Heading>
      <Center>
        <Text size={'lg'}>Sorry, we couldn't find the page you were looking for.</Text>
      </Center>
      <Center>
      <Text size={'lg'}>Check the page address or return to <Button as={Link} variant={'link'} to={'/'}>Dashboard Home</Button></Text>
      </Center>
    </Box>
  );
};

export default PageNotFound;
