import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Segment } from 'semantic-ui-react';
import { PageHeaderRow, PreLoader, useFeathers } from '@inkcloud/components';
import feathers from '../../../bootstrap/feathers';
import { PrintableForm } from './PrintableForm';

interface DetailProps {}

const { useState } = React;

const pathPrefix = `/settings/printable-docs-templates`;
const serviceName = '/printable-docs/templates';
const service = feathers.service(serviceName);
const commonParams = { query: {} };

export const Detail: React.FunctionComponent<DetailProps & RouteComponentProps<{ id?: string }>> = (
  props
) => {
  const {
    history,
    match: {
      params: { id },
    },
  } = props;

  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [state, reload] = useFeathers('get', serviceName, [id, commonParams], true);

  const isEdit = !!id;

  const handleSubmit = async (values) => {
    setErrorMessage('');
    setIsLoading(true);
    try {
      const payload = {
        ...values,
      };
      if (isEdit) {
        await service.patch(id, payload, commonParams);
      } else {
        await service.create(payload, commonParams);
      }
      history.push(pathPrefix);
    } catch (e) {
      const errMsg = e?.code < 500 ? e.message : 'Something went wrong. Please try again later';
      setErrorMessage(errMsg);
    }

    setIsLoading(false);
  };

  if (isEdit && !state.data) {
    return <PreLoader />;
  }

  const initialData = { ...state.data };
  return (
    <div>
      <PageHeaderRow header="Printable Docs Templates" subheader="Settings" subheaderId={''}>
        <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
      </PageHeaderRow>
      <Segment>
        {
          <PrintableForm
            initialValues={initialData}
            isSubmitting={isLoading}
            errorMessage={errorMessage}
            onSubmit={handleSubmit}
          />
        }
      </Segment>
    </div>
  );
};
