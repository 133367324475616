import * as React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { FormattedDate, FormattedTime } from 'react-intl';
import { Button, Label, Icon, Popup, Loader } from 'semantic-ui-react';
import { humanize } from 'underscore.string';

import {
  useList,
  ListPage,
  basicQueryMap,
  generateOperatorOptions,
  Operator,
} from '@inkcloud/components';

import { documentTypeOptions } from './PrintableForm';

export interface ListProps {
  statusOptions: Array<{ value: string; text: string }>;
}

const serviceName = '/printable-docs/templates';
const pathPrefix = `/settings/printable-docs-templates`;

const filterQueryMap = (values) => {
  let filterRows = basicQueryMap(values);

  filterRows = {
    ...filterRows,
    documentType: filterRows?.documentType?.$elemMatch?.value,
  };
  return filterRows;
};

async function getFilterDefinition() {
  const definition = [
    {
      label: 'Name',
      value: 'name',
      operators: generateOperatorOptions([Operator.Like]),
    },
    {
      label: 'Description',
      value: 'description',
      operators: generateOperatorOptions([Operator.Like]),
    },
    {
      label: 'Document Type',
      value: 'documentType',
      operators: generateOperatorOptions([Operator.Is]),
      valueOptions: documentTypeOptions?.map((doc) => ({ value: doc.value, label: doc.text })),
      type: 'select',
    },
  ];

  return definition.sort((a: any, b: any) => a.label.localeCompare(b.label));
}

export const List: React.FunctionComponent<ListProps & RouteComponentProps> = (props) => {
  const {
    match: { params },
    location,
    history,
    statusOptions,
  } = props;

  const useListValues = useList({
    feathers: [
      'find',
      serviceName,
      [
        {
          query: {
            $sort: { createdAt: -1 },
          },
        },
      ],
    ],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  const tableDefinition = [
    {
      key: 'createdAt',
      label: 'Date',
      sortable: true,
      cell: (v, r) => (
        <Popup
          size="mini"
          content={<FormattedTime value={r.createdAt} />}
          trigger={
            <div>
              <FormattedDate value={r.createdAt} />
            </div>
          }
        />
      ),
    },
    { key: 'name', label: 'Name' },
    { key: 'description', label: 'Description' },
    {
      key: 'documentType',
      label: 'Document Type',
      cell: (v, r) => <>{humanize(v)}</>,
    },
    {
      key: '',
      label: '',
      cell: (v, r) => (
        <>
          <Popup
            size="mini"
            content="Edit"
            align
            trigger={
              <Link to={`${pathPrefix}/${r._id}`}>
                <Button size="mini" icon="edit" />
              </Link>
            }
          />
        </>
      ),
      textAlign: 'right',
    },
  ];

  const headerActions = (
    <>
      <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
      <Link to={`${pathPrefix}/add`}>
        <Button size="mini" primary content="New" />
      </Link>
    </>
  );

  return (
    <>
      <ListPage
        header="Printable Docs Templates"
        subheader="Settings"
        useListValues={useListValues}
        history={history}
        pathPrefix={pathPrefix}
        tableDefintion={tableDefinition}
        withCheckboxes={true}
        headerActions={headerActions}
        getFilterDefinition={getFilterDefinition}
      />
    </>
  );
};
