import * as React from 'react';
import { Button, Table, Input, Checkbox, Segment, Popup, Message } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { useList, EmptyWrapper } from '@inkcloud/components';
import Feathers, { services, feathersAuthentication } from '../../../bootstrap/feathers';
import CreditCardModal from './CreditCardModal';

interface CreditCardProps {
  customer: any;
  open: boolean;
  onClose: () => void;
}

const { useState, useEffect } = React;

export const CreditCard: React.FunctionComponent<CreditCardProps> = (props) => {
  const { customer, open, onClose } = props;
  const [cardsInfo, setCardsInfo] = useState([]);
  const [isReload, setIsReloadCard] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');
  const [errorDeleteMessage, setErrorDeleteMessage] = useState('');

  const [stripeClientSecret, setStripeClientSecret] = useState('');
  const [stripePromise, setStripePromise] = useState();

  const tenant = useSelector((state: any) => state.globals.tenant);

  // console.log('tenant', tenant);

  useEffect(() => {
    const loadStripePromise = async () => {
      const res = await Feathers.service('tenant-settings').get(tenant?._id, {
        query: { $select: ['payment'] },
      });

      const setupIntent = await Feathers.service('payment-gateway/v2/stripe/setup-intent').create({
        icCustomerId: customer._id,
        // payment_method_types: ['card'],
      });

      // console.log('use effect setupIntent', setupIntent);

      setStripeClientSecret(setupIntent?.client_secret);

      // console.log('res', res);

      if (res?.payment?.merchantGateway?.adapters?.stripe?.publishable) {
        const stripe: any = loadStripe(
          `${res?.payment?.merchantGateway?.adapters?.stripe?.publishable}`
        );

        setStripePromise(stripe);
      }
    };

    if (tenant?._id) {
      loadStripePromise();
    }
  }, [tenant?._id]);

  useEffect(() => {
    const loadCardsInfo = async () => {
      try {
        const res: any = await Feathers.service('customers').get(customer._id, {
          query: { $select: ['creditCardTokens'] },
        });
        setCardsInfo(res.creditCardTokens);
        setIsReloadCard(false);
      } catch (error) {
        setIsReloadCard(false);
      }
    };

    loadCardsInfo();
  }, [isReload]);

  const handleSubmit = async (data = {}) => {
    setIsSubmit(true);
    setErrorMessage('');
    try {
      const response: any = await Feathers.service('payment-gateway/v2/create').create(
        {
          customer: customer._id,
          tenant: customer.business,
          ...data,
        },
        { query: {} }
      );

      if (response.result) {
        onClose();
        setIsReloadCard(true);
        setErrorMessage('');
      } else {
        setErrorMessage(response.message);
      }
    } catch (e) {
      const errMsg =
        e?.code < 500 ? e?.message : 'We are experiencing technical difficulties. Please try again';

      setErrorMessage(errMsg);
    }
    setIsSubmit(false);
  };

  const handleDelete = async (paymentProfileId: string) => {
    setIsReloadCard(true);
    setErrorDeleteMessage('');
    try {
      await Feathers.service('/payment-gateway/v2/delete').create(
        {
          customer: customer._id,
          token: paymentProfileId,
        },
        { query: {} }
      );
    } catch (e) {
      const errMsg =
        e?.code < 500 ? e?.message : 'We are experiencing technical difficulties. Please try again';

      setErrorMessage(errMsg);
    }
    setIsReloadCard(false);
  };

  return (
    <>
      <EmptyWrapper
        attached="bottom"
        icon="credit card outline"
        queryResult={cardsInfo?.length > 0}
        isStateLoading={isReload}
      >
        <Segment attached="bottom">
          {errorDeleteMessage && <Message error content={errorDeleteMessage} />}
          <Table size="small">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Card Type</Table.HeaderCell>
                <Table.HeaderCell>Last Four</Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {cardsInfo?.map((info: any, index: number) => (
                <Table.Row key={index}>
                  <Table.Cell>{info.cardType}</Table.Cell>
                  <Table.Cell>{info.lastFour}</Table.Cell>
                  <Table.Cell textAlign="right">
                    <Popup
                      size="mini"
                      content="Delete Card"
                      trigger={
                        <Button
                          size="mini"
                          icon="trash"
                          onClick={() => handleDelete(info.paymentProfileId)}
                        />
                      }
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Segment>
      </EmptyWrapper>

      {open && (
        <CreditCardModal
          stripeClientSecret={stripeClientSecret}
          stripePromise={stripePromise}
          isSubmitting={isSubmit}
          errorMessage={errorMessage}
          onClose={() => {
            onClose();
            setErrorMessage('');
          }}
          onSubmit={handleSubmit}
        />
      )}
    </>
  );
};
