import * as React from 'react';
import {
  Accordion,
  Button,
  Checkbox,
  Header,
  Grid,
  Label,
  Segment,
  Table,
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { FormattedDate } from 'react-intl';
import { IndexWrapper, IndexWrappedProps } from './../../bootstrap/IndexWrapper';
import { ListViewPagination, ListFilterWarning } from './../../common';
// import FilterForm from './FilterForm';

const filterMapper = (filter) => {
  return {
    organizationName: { $LIKE: filter && filter.name },
    // 'primaryContact.firstName': { $LIKE: filter && filter.name },
    // 'primaryContact.lastName': { $LIKE: filter && filter.name }
  };
};

export function Container(props: IndexWrappedProps) {
  const { queryResult } = props;
  return (
    <div>
      <Header as="h1">Estimates</Header>
      <Header attached={'top'} as="h5">
        Filter
      </Header>
      <Segment attached secondary>
        {/* <FilterForm
          onSubmit={props.handleFilterSubmit}
          handleClearFilter={props.handleClearFilter}
          isFiltered={props.isFiltered}
          /> */}
      </Segment>
      <ListFilterWarning isFiltered={props.isFiltered} />
      <Segment attached loading={props.isLoading}>
        <ListViewPagination.Top label={'New Estimate'} link={'/estimates/add'} {...props} />
        <Table size={'small'}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                <Checkbox onChange={props.handleSelectAllClick} />
              </Table.HeaderCell>
              <Table.HeaderCell>Date</Table.HeaderCell>
              <Table.HeaderCell>ID</Table.HeaderCell>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {queryResult &&
              queryResult.data.map((i) => (
                <Table.Row key={i._id}>
                  <Table.Cell>
                    <Checkbox
                      value={i._id}
                      checked={props.selected[i._id] || false}
                      onChange={props.handleSemanticCheckboxClick}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <FormattedDate value={i.createdAt} />
                  </Table.Cell>
                  <Table.Cell>{`${i.humanId}`}</Table.Cell>
                  <Table.Cell>
                    {i.organizationName
                      ? i.organizationName
                      : `${i.primaryContact.firstName} ${i.primaryContact.lastName}`}
                  </Table.Cell>
                  <Table.Cell>
                    <Link to={'/customers/' + i._id}>
                      <Button size={'mini'}>Details</Button>
                    </Link>
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
        <ListViewPagination.Bottom label={'Delete'} {...props} />
      </Segment>
    </div>
  );
}

export default IndexWrapper(Container, 'estimates', {
  filterMapper,
  query: {
    $sort: { createdAt: -1 },
    $populate: ['customer'],
  },
});
