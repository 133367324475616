import * as React from 'react';
import { Table, Form } from 'semantic-ui-react';
import * as numeral from 'numeral';
import * as Cleave from 'cleave.js/react';

interface ISummaryProps {
  cart: any;
  discount: number;
  onChange: (v: number) => void;
}

export const Summary: React.FunctionComponent<ISummaryProps> = (props) => {
  const { cart, discount, onChange } = props;
  return (
    <Table>
      <Table.Body>
        <Table.Row>
          <Table.Cell>Subtotal</Table.Cell>
          <Table.Cell textAlign="right">{numeral(cart.subtotal).format('$0,0.00')}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Shipping</Table.Cell>
          <Table.Cell textAlign="right">{numeral(cart.shipping).format('$0,0.00')}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Sales Tax</Table.Cell>
          <Table.Cell textAlign="right">{numeral(cart.salesTax).format('$0,0.00')}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Discount</Table.Cell>
          <Table.Cell textAlign="right">
            {/* ({numeral(cart.discount).format('$0,0.00')}) */}
            <Form>
              <div className={'ui tiny input fluid'} style={{ justifyContent: 'flex-end' }}>
                <Cleave
                  style={{ maxWidth: '300px', textAlign: 'right' }}
                  value={discount}
                  onChange={(e) => onChange(e.target.rawValue)}
                  options={{
                    numeral: true,
                    rawValueTrimPrefix: true,
                    numeralPositiveOnly: true,
                    numeralThousandsGroupStyle: 'thousand',
                    prefix: '$',
                  }}
                />
              </div>
            </Form>
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Total</Table.Cell>
          <Table.Cell textAlign="right">{numeral(cart.total).format('$0,0.00')}</Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
};
