import * as React from 'react';
import { useDropzone } from 'react-dropzone';
import { connect } from 'react-redux';
import { Segment, Divider, Button } from 'semantic-ui-react';
import axios from '../../../bootstrap/axios';
const { useState, useCallback } = React;

declare var APPENV;

export interface ConnectProps {
  tenantId?: string;
}

export interface PackageUploadProps {
  multiple?: boolean;
  buttonLabel?: string;
  endpoint?: string;
  onUpload: (files: any) => void;
  onUploading: (v: boolean) => void;
}

export const PackageUploadPre: React.FunctionComponent<PackageUploadProps & ConnectProps> = (
  props
) => {
  const {
    buttonLabel = 'Upload Image',
    endpoint = 'design-templates/templates-upload',
    onUploading,
  } = props;
  const [files, setFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const onDrop = useCallback(async (acceptedFiles) => {
    setFiles(files.concat(acceptedFiles));
    setIsUploading(true);
    onUploading(true);

    acceptedFiles.map(async (f) => {
      let formData = new FormData();
      formData.append('file', f);
      formData.append('tenantId', props.tenantId);

      const response = await axios.post(`${APPENV.api.connection}/${endpoint}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: localStorage.getItem('feathers-jwt'),
        },
      });
      props.onUpload && props.onUpload(response.data);
      setIsUploading(false);
      onUploading(false);
    });
  }, []);

  const handleRemoveClick = (idx: number) => {
    const updatedFiles = [...files];
    updatedFiles.splice(idx, 1);
    setFiles(updatedFiles);
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const rootProps = getRootProps() as any;
  return (
    <Segment loading={isUploading} textAlign="center" size={'small'} {...rootProps}>
      <input {...getInputProps({ multiple: props.multiple })} />
      {isDragActive ? (
        <p>Drop the files here ... </p>
      ) : (
        <p>Drag 'n' drop some files here, or click to select files</p>
      )}
      <Button icon={'upload'} content={buttonLabel} primary type="button" />
      <Divider section />
      <div>
        {files.map((f, idx) => (
          <div key={idx}>
            <Button
              type="button"
              icon="close"
              basic
              size="mini"
              onClick={(e) => {
                handleRemoveClick(idx);
                e.stopPropagation();
              }}
            />{' '}
            {f.name}{' '}
          </div>
        ))}
      </div>
    </Segment>
  );
};

const mapStateToProps = (state) => ({
  tenantId: state.auth.user.business,
});

export const PackageUpload = connect(mapStateToProps, {})(PackageUploadPre);
