import * as React from 'react';
import { Button, Input, Dropdown, Segment, Form } from 'semantic-ui-react';
import { FeathersDropdown } from '@inkcloud/components';
import DatePicker from 'react-datepicker';
import Feathers from './../../bootstrap/feathers';
import { values } from 'redux-form';

const { useState, useEffect } = React;

export interface FilterFormProps {
  value: any;
  onChange: Function;
  onReset: Function;
}

function initMapper(value) {
  let defaults = {
    microsite: { value: 'all', text: 'All' },
  };

  return Object.keys(defaults).reduce((p, cur) => {
    if (!p[cur]) {
      p[cur] = defaults[cur];
    }
    return p;
  }, value);
}

export function FilterForm(props: FilterFormProps) {
  const [microsites, setMicrosites] = useState([]);

  useEffect(() => {
    const loadMicrosites = async () => {
      const res: any = await Feathers.service('/microsites').find({
        query: {
          $np: 1,
          $sort: { name: 1 },
          $select: ['name'],
        },
      });
      setMicrosites(res?.map((r) => ({ value: r._id, text: r.name })));
    };

    loadMicrosites();
  }, []);

  const { value: preInitValue } = props;

  let value = initMapper(preInitValue);

  const onChange = (field, value) => {
    props.onChange && props.onChange({ ...props.value, [field]: value });
  };

  const optionsMicrosite = [{ value: 'all', text: 'All' }, ...(microsites || [])];

  return (
    <>
      <Form size={'small'}>
        <Form.Group widths={'equal'}>
          <Form.Field>
            <Dropdown
              search
              selection
              placeholder="Microsite..."
              options={optionsMicrosite}
              value={value.microsite.value}
              onChange={(e, data) =>
                onChange('microsite', {
                  value: data.value,
                  text: optionsMicrosite?.find((ms) => ms.value === data.value)?.text,
                })
              }
            />
          </Form.Field>
        </Form.Group>
        <Form.Field>
          <Button size={'tiny'} content="Reset Form" onClick={() => props.onReset()} />
        </Form.Field>
      </Form>
    </>
  );
}
