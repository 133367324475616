import * as React from 'react';
import { Switch, Route } from 'react-router-dom';
import List from './List';

const routes = (props) => (
  <Switch>
    <Route path="/reports/customer-sales" component={List} />
  </Switch>
);

export default routes;
