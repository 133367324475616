import * as React from 'react';
import { Button, Checkbox, Form, Message } from 'semantic-ui-react';
import { Formik, Form as FormikForm, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { SemanticField } from '../../../common/Form';
import feathers from '../../../bootstrap/feathers';

export interface WebhookSubscriptionsFormProps {
  initialValues: any;
  onSubmit: (v: any) => void;
  errorMessage: string;
  events: Array<{ name: string; description: string }>;
}

const WebhookSubscriptionsFormSchema = Yup.object().shape({
  url: Yup.string().required('Required'),
});

const { useEffect, useState } = React;

export const WebhookSubscriptionsForm = (props: WebhookSubscriptionsFormProps) => {
  const { initialValues, onSubmit, events, errorMessage } = props;

  const [microsites, setMicrosites] = useState<{ text: string; value: string }[]>([]);

  useEffect(() => {
    const loadMS = async () => {
      try {
        const res: any = await feathers.service('/microsites').find({
          query: {
            $sort: { name: 1 },
            $select: ['_id', 'name'],
            $np: 1,
          },
        });

        setMicrosites(res?.map((ms) => ({ text: ms.name, value: ms._id })));
      } catch (e) {
        console.log(e);
      }
    };

    loadMS();
  }, []);

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={WebhookSubscriptionsFormSchema}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values);
        }}
      >
        {(innerProps) => {
          const {
            values,
            touched,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            setFieldValue,
            setFieldTouched,
          } = innerProps;

          return (
            <FormikForm className={`ui form ${isSubmitting && !errorMessage && 'loading'}`}>
              <Form.Group widths="equal">
                <SemanticField fluid name="name" label="Name" component={Form.Input} />
                <ErrorMessage
                  render={(msg) => <Message negative size="tiny" content={msg} />}
                  name="name"
                />
              </Form.Group>
              <Form.Group widths="equal">
                <SemanticField
                  fluid
                  name="description"
                  label="Description"
                  component={Form.TextArea}
                />
              </Form.Group>
              {microsites?.length > 0 && (
                <Form.Group widths="equal">
                  <Form.Field>
                    <SemanticField
                      fluid
                      selection
                      name="microsite"
                      label="Microsite"
                      component={Form.Dropdown}
                      options={microsites}
                    />
                  </Form.Field>
                </Form.Group>
              )}
              <Form.Group widths="equal">
                <Form.Field>
                  <SemanticField fluid name="url" label="URL" component={Form.Input} />
                  <ErrorMessage
                    render={(msg) => <Message negative size="tiny" content={msg} />}
                    name="url"
                  />
                </Form.Field>
              </Form.Group>
              {events?.length ? <label>Events</label> : null}
              {events?.map((event: { name: string; description: string }, index: number) => (
                <Form.Group key={index} widths="equal">
                  <Form.Field>
                    <Checkbox
                      label={event.description}
                      defaultChecked={values?.events?.includes(event.name)}
                      onChange={(e, data) => {
                        if (data.checked) {
                          const value = [...(values?.events || []), event.name];
                          setFieldValue(`events`, value);
                        } else {
                          const value = values?.events?.filter((ev: string) => ev !== event.name);
                          setFieldValue(`events`, value);
                        }
                      }}
                    />
                  </Form.Field>
                </Form.Group>
              ))}

              <Form.Group widths="equal">
                <Form.Field>
                  <SemanticField
                    fluid
                    name="status"
                    selection
                    label="Status"
                    options={[
                      { text: 'Disabled', value: 'disabled' },
                      { text: 'Active', value: 'active' },
                      { text: 'Failing', value: 'failing' },
                    ]}
                    component={Form.Dropdown}
                  />
                </Form.Field>
              </Form.Group>

              <Button primary type="submit" disabled={isSubmitting && !errorMessage}>
                Save
              </Button>
            </FormikForm>
          );
        }}
      </Formik>
      {errorMessage && <Message error content={errorMessage} />}
    </>
  );
};
