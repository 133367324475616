import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from '@chakra-ui/react';
// import { Modal, Button, Divider, Popup, Segment, Table } from 'semantic-ui-react';
import { FormattedDate } from 'react-intl';
import { useList, ListPage, basicQueryMap } from '@inkcloud/components';
import * as async from 'async';
import Feathers from '../../../../bootstrap/feathers';
import OrderLimitIncreaseForm from './OrderLimitIncreaseForm';

const { useState, useEffect } = React;

const filterQueryMap = (values) => basicQueryMap(values, {});

interface PreOrderLimitIncreaseModalProps {
  errorMessage?: string;
  isSubmitting?: boolean;
}

const PreOrderLimitIncreaseModal: React.FunctionComponent<
  PreOrderLimitIncreaseModalProps & RouteComponentProps
> = (props) => {
  const {
    location,
    history,
    match: { params },
  } = props as any;
  const [increase, setIncreases] = useState([]);

  const microsite = params?.micrositeId;

  const { IsOrderLimitIncreaseModalOpen, micrositeCustomer } = location?.state || {};

  const pathPrefix = `/settings/microsites/${microsite}/customers`;

  const goBack = () =>
    history.push(pathPrefix, { micrositeCustomer: null, isOrderLimitIncreaseModalOpen: false });

  if (!IsOrderLimitIncreaseModalOpen) {
    goBack();
  }

  const serviceName = 'products-legacy/order-limit-increase';

  const useListValues = useList({
    feathers: [
      'find',
      serviceName,
      [
        {
          query: {
            micrositeCustomer,
            $populate: [{
              path: 'product', select: 'key',
            }, {
              path: 'products', select: 'key',
            }],
          },
        },
      ],
    ],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  const tableDefinition = [
    {
      key: '',
      label: 'Product',
      cell: (v, r) => <>{[r.product?.key ?? '', ...(r.products?.map(p => p.key)) ?? []].map((r, idx) => <span key={idx}>{r}</span>)}</>,
    },
    {
      key: 'amount',
      label: 'Amount',
      cell: (v) => v,
    },
    {
      key: '',
      label: 'Date Range',
      sortable: true,
      cell: (v, r) => (
        <div>
          <FormattedDate value={r.startDate} />
          <div>
            <FormattedDate value={r.endDate} />
          </div>
        </div>
      ),
    },
    {
      key: 'notes',
      label: 'Notes',
      cell: (v) => <div style={{ whiteSpace: 'pre-wrap' }}>{v}</div>,
    },
  ];

  const [errorMessage, setErrorMessage] = useState('');

  const handleOrderLimitIncrease = async (products: any) => {
    setErrorMessage('');

    // console.log('handleOrderLimitIncrease', products);

    async.forEachLimit(
      products,
      2,
      (prod: any, cb) => {
        Feathers.service('products-legacy/order-limit-increase')
          .create({
            microsite,
            micrositeCustomer,
            product: prod.product,
            products: prod.products,
            amount: prod.amount,
            notes: prod.notes,
            startDate: new Date(prod.startDate)?.toISOString(),
            endDate: new Date(prod.endDate)?.toISOString(),
          })
          .then(() => {
            cb();
          })
          .catch((e) => {
            const errMsg =
              e?.code < 500
                ? e.message
                : 'We are experiencing technical difficulties. Please try again';
            setErrorMessage(errMsg);
          });
      },
      (e) => {
        goBack();
        // history.push(`/settings/microsites/${microsite}/customers`);
      }
    );

    // try {

    //   const res: any = products?.map(async (prod: any) => (
    //     await Feathers.service('products-legacy/order-limit-increase').create({
    //       microsite,
    //       micrositeCustomer,
    //       product: prod.product,
    //       amount: prod.amount,
    //       notes: prod.notes,
    //       startDate: prod.startDate,
    //       endDate: prod.endDate,
    //     })
    //   ))

    //   await Promise.all(res)

    //   // setIsOrderLimitIncreaseModalOpen(false)
    //   // setMicrositeCustomer(null)
    //   history.push(`/settings/microsites/${microsite}/customers`)
    // } catch (e) {
    //   const errMsg = e?.code < 500 ? e.message : 'We are experiencing technical difficulties. Please try again'
    //   setErrorMessage(errMsg)
    // }
  };

  return (
    <Modal size="6xl" isOpen={true} onClose={goBack}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Microsite Customer - Order Limit Increase</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <OrderLimitIncreaseForm
            microsite={microsite}
            initialValues={{
              prodType: 'single',
              type: 'absolute',
            }}
            onSubmit={handleOrderLimitIncrease}
            errorMessage={errorMessage}
          />
          <ListPage
            header=""
            subheader=""
            useListValues={useListValues}
            history={history}
            pathPrefix={`${pathPrefix}/order-limits`}
            tableDefintion={tableDefinition}
            withCheckboxes={false}
            headerActions={null}
          />
        </ModalBody>

        <ModalFooter>
          <Button onClick={goBack}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
    // <Modal size="small" open={IsOrderLimitIncreaseModalOpen} onClose={goBack}>
    //   <Modal.Header>Microsite Customer - Order Limit Increase</Modal.Header>
    //   <Modal.Content>
    //     <div>Use this to give a user an order limit increase for a specific amount of time</div>
    //     <Divider />
    //     <Segment>
    //     </Segment>
    //   </Modal.Content>
    //   <Modal.Actions>
    //     <Button size="tiny" negative onClick={goBack}>
    //       Cancel
    //     </Button>
    //   </Modal.Actions>
    // </Modal>
  );
};

export const OrderLimitIncreaseModal = withRouter(PreOrderLimitIncreaseModal);
