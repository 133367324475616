import * as React from 'react';
import { Switch, Route } from 'react-router-dom';

import { List } from './List';
import { Detail } from './Detail';

const routes = (props) => (
  <Switch>
    <Route exact path="/settings/imports" component={(innerProps) => <List {...innerProps} />} />
    <Route
      exact
      path="/settings/imports/add"
      component={(innerProps) => <Detail {...innerProps} />}
    />
    <Route
      exact
      path="/settings/imports/:id"
      component={(innerProps) => <Detail {...innerProps} />}
    />
  </Switch>
);
export default routes;
