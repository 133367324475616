import React, { useEffect, useState, useRef, useMemo } from 'react';
import {
  Box,
  TableContainer,
  Table,
  Tag,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Grid,
  GridItem,
  Heading,
  Button,
  HStack,
  Wrap,
  WrapItem,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Icon,
  Divider,
  Flex,
  Spacer,
} from '@chakra-ui/react';

import { FaEllipsisV, FaEdit, FaTrash, FaCheckCircle, FaRegCircle } from 'react-icons/fa';

import type { ModelTypes } from '@inkcloud/icapi-types';

import type { AttributeType } from '../../common/types';
import type { ItemsType } from '../../Detail';

import { SegmentCard } from '../../../../common';

interface ISpecsProps {
  items: ItemsType;
  attributes: AttributeType[];
  title: string;
  subTitle: string;
  actionButtonLabel?: string;
  isSubmitting?: boolean;
  isDone?: boolean;
  onEditClick: () => void;
  onEditItem?: (index: number) => void;
  onDeleteItem: (value: ItemsType) => void;
}

type attributeSpecType = {
  [key: string]: {
    name: string;
    attributes: { value: string; label: string }[];
  };
};

const specItemLabelStyle: React.CSSProperties = {
  // maxWidth: '35%',
  maxWidth: '230px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  verticalAlign: 'top',
};

const specItemTopLevelLabelStyle: React.CSSProperties = {
  // maxWidth: '35%',
  maxWidth: '135px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  verticalAlign: 'top',
};

export function attributeFinder(
  attributeTypes: attributeSpecType = {},
  attr: Record<string, string>
) {
  return {
    typeName: attributeTypes?.[attr?.type]?.name,
    name: attributeTypes?.[attr?.type]?.attributes?.find((a) => a.value === attr.value)?.label,
  };
}

const Specs: React.FunctionComponent<ISpecsProps> = (props) => {
  const {
    items,
    attributes,
    title,
    subTitle,
    actionButtonLabel = 'Edit',
    isDone,
    isSubmitting,
    onEditClick,
    onEditItem,
    onDeleteItem,
  } = props;

  const [isDeleteDiagOpen, setIsDeleteDiagOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<ItemsType[number]>();

  const cancelRef = useRef(null);

  const attributeTypes = useMemo(
    () =>
      attributes?.reduce((acc, cur) => {
        if (!acc[cur.typeId]) {
          acc[cur.typeId] = {
            name: cur.typeName,
            attributes: [{ value: 'OTHER', label: 'Other...' }],
          };
        }

        acc[cur.typeId].attributes = acc[cur.typeId].attributes.concat([
          { value: cur._id, label: cur.name },
        ]);

        return acc;
      }, {}),
    [attributes]
  );

  // console.log('attributeTypes', attributeTypes);

  useEffect(() => {
    if (isDone) {
      setIsDeleteDiagOpen(false);
    }
  }, [isDone]);

  const handleDeleteItem = () => {
    const filteredItem = items?.filter((item) => item._id !== selectedItem?._id);

    onDeleteItem(filteredItem);
    setIsDeleteDiagOpen(true);
  };

  return (
    <SegmentCard
      title={title}
      subtitle={subTitle}
      actionButtonLabel={actionButtonLabel}
      actionButtonClick={onEditClick}
    >
      {items?.map((item, index) => (
        <React.Fragment key={index}>
          <Flex>
            <Heading size={'xs'}>Item {index + 1}</Heading>
            <Spacer />
            {actionButtonLabel && (
              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label="Options"
                  icon={<FaEllipsisV />}
                  variant="outline"
                />
                <MenuList>
                  <MenuItem onClick={() => onEditItem?.(index)} icon={<FaEdit />}>
                    Edit Item
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setSelectedItem(item);
                      setIsDeleteDiagOpen(true);
                    }}
                    icon={<FaTrash />}
                  >
                    Remove Item
                  </MenuItem>
                </MenuList>
              </Menu>
            )}
          </Flex>
          <Divider />
          <Box>
            <TableContainer whiteSpace="unset">
              <Table size="sm">
                <Tbody>
                  {item?.category && (
                    <Tr>
                      <Th style={specItemTopLevelLabelStyle}>Category</Th>
                      <Td>{`${item.category?.name} - ${item.category?.hrCode}`}</Td>
                    </Tr>
                  )}
                  <Tr>
                    <Th style={specItemTopLevelLabelStyle}>SKU</Th>
                    <Td>{item.sku}</Td>
                  </Tr>
                  <Tr>
                    <Th>Quantities</Th>
                    <Td>
                      <HStack>
                        {item.quantities?.map((q, idx) => (
                          <Tag key={idx} size={'sm'}>
                            {q}
                          </Tag>
                        ))}
                      </HStack>
                    </Td>
                  </Tr>
                  {item.numHardProof && (
                    <Tr>
                      <Th style={specItemTopLevelLabelStyle}># of Hard Proof</Th>
                      <Td>{item.numHardProof}</Td>
                    </Tr>
                  )}
                  <Tr>
                    <Th style={specItemTopLevelLabelStyle}>Proof</Th>
                    <Td>
                      <Wrap>
                        <WrapItem>
                          <Icon
                            as={item?.eProof ? FaCheckCircle : FaRegCircle}
                            color={item?.eProof ? 'green' : ''}
                            mr="1"
                          />
                          Electronic{' '}
                        </WrapItem>
                        <WrapItem>
                          <Icon
                            as={item?.hardProof ? FaCheckCircle : FaRegCircle}
                            color={item?.hardProof ? 'green' : ''}
                            mr="1"
                          />
                          Hard
                        </WrapItem>
                        <WrapItem>
                          <Icon
                            as={item?.liveSample ? FaCheckCircle : FaRegCircle}
                            color={item?.liveSample ? 'green' : ''}
                            mr="1"
                          />
                          Live Sample
                        </WrapItem>
                        <WrapItem>
                          <Icon
                            as={item?.pressCheck ? FaCheckCircle : FaRegCircle}
                            color={item?.pressCheck ? 'green' : ''}
                            mr="1"
                          />
                          Press Check
                        </WrapItem>
                      </Wrap>
                    </Td>
                  </Tr>
                  <Tr>
                    <Th style={specItemTopLevelLabelStyle}>Description</Th>
                    <Td>{item.description}</Td>
                  </Tr>
                  <Tr>
                    <Th style={specItemTopLevelLabelStyle}>Packaging Instructions</Th>
                    <Td>{item.packagingInstructions}</Td>
                  </Tr>
                  {item?.notes && (
                    <Tr>
                      <Th style={specItemTopLevelLabelStyle}>Notes</Th>
                      <Td>{item.notes}</Td>
                    </Tr>
                  )}
                  {item.components?.map((c, idx) => (
                    <Tr key={idx}>
                      <Th
                        bgColor={'gray.50'}
                        verticalAlign={'top'}
                        style={specItemTopLevelLabelStyle}
                      >
                        Component {idx + 1} Specs
                      </Th>
                      <Td bgColor={'gray.50'}>
                        <Table size="sm">
                          <Tbody>
                            <Tr>
                              <Th>Description</Th>
                              <Td>{c.description}</Td>
                            </Tr>
                            <Tr>
                              <Th>Notes</Th>
                              <Td>{c.notes}</Td>
                            </Tr>
                            <Tr>
                              <Th>Sizes</Th>
                              <Td>
                                <Table>
                                  <Tbody>
                                    <Tr>
                                      <Td>
                                        <Heading
                                          style={{ textTransform: 'uppercase' }}
                                          color={'gray.600'}
                                          letterSpacing={'wider'}
                                          colorScheme={'gray'}
                                          fontSize={10}
                                        >
                                          Flat
                                        </Heading>
                                        <Text>
                                          {c.flatSize?.width} x {c.flatSize?.height}
                                        </Text>
                                      </Td>
                                      <Td>
                                        <Heading
                                          style={{ textTransform: 'uppercase' }}
                                          color={'gray.600'}
                                          letterSpacing={'wider'}
                                          colorScheme={'gray'}
                                          fontSize={10}
                                        >
                                          Finished
                                        </Heading>
                                        <Text>
                                          {c.finishSize?.width} x {c.finishSize?.height}
                                        </Text>
                                      </Td>
                                    </Tr>
                                  </Tbody>
                                </Table>
                              </Td>
                            </Tr>

                            <Tr>
                              <Th style={{ verticalAlign: 'top' }}>Ink Specs</Th>
                              <Td>{c.inkSpecs}</Td>
                            </Tr>
                            {c.attributes
                              ?.sort((a, b) => {
                                if (a?.type && b?.type) {
                                  return a.type > b.type ? 1 : -1;
                                }

                                return 0;
                              })
                              ?.map((a, innerIdx: number) => {
                                const t = attributeFinder(attributeTypes, a);
                                const { name = '', typeName = '' } = t || {};

                                return (
                                  <Tr key={innerIdx}>
                                    <Th style={specItemLabelStyle}>{a?.otherType ?? typeName}</Th>
                                    <Td>{a?.otherValue ?? name}</Td>
                                  </Tr>
                                );
                              })}
                          </Tbody>
                        </Table>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        </React.Fragment>
      ))}
      {isDeleteDiagOpen && (
        <AlertDialog
          isOpen={true}
          leastDestructiveRef={cancelRef}
          onClose={() => setIsDeleteDiagOpen(false)}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Delete Item - {selectedItem?.sku ?? ''}
              </AlertDialogHeader>

              <AlertDialogBody>
                Are you sure? You can't undo this action afterwards.
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button
                  ref={cancelRef}
                  onClick={() => {
                    setIsDeleteDiagOpen(false);
                    setSelectedItem(undefined);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  isDisabled={isSubmitting}
                  isLoading={isSubmitting}
                  colorScheme="red"
                  onClick={handleDeleteItem}
                  ml={3}
                >
                  Delete
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      )}
    </SegmentCard>
  );
};

export default Specs;
