import * as React from 'react';
import { Button, Segment, Menu, Divider, Message, Table, Popup } from 'semantic-ui-react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { FormattedNumber, FormattedDate, FormattedTime } from 'react-intl';
import { useList, PageHeaderRow, PaginatorWrapper, EmptyWrapper, To } from '@inkcloud/components';
import moment from 'moment';
import { IndexWrapper, IndexWrappedProps } from '../../../bootstrap/IndexWrapper';
import {
  OrderItemComponent2,
  connectOrderItemModals,
  OrderItemModalsProps,
} from '../../../common/OrderItemCard2';
import { FilterForm } from '../../orders/FilterForm';

import Feathers, { services, socket } from '../../../bootstrap/feathers';
import NotesComponent from '../../../common/notes2/index';
import { DetailWrapper, DetailFeaturesProps } from '../../../bootstrap/DetailWrapper';

import { InfoTable } from './InfoTable';

import LogInAsModal from '../LogInAsModal';

import { CreditMemoModal } from '../CreditMemoModal';
import { ClearCartModal } from './ClearCartModal';
import { ModifyCartModal } from '../ModifyCartModal';

declare let localStorage: any;

declare let ENV;

interface ListProps {
  data: any;
}

const filterQueryMap = (values) => {
  let newValues: any = {};

  if (values) {
    newValues = {
      ...values,
    };

    delete newValues.customerJobName;
    delete newValues.category;
    delete newValues.itemType;
    delete newValues.productionStatus;
    delete newValues.quantity;
    delete newValues.start;
    delete newValues.end;
  }

  const filterMap: any = {
    attributes: {
      ...newValues,
    },
    category: values.category ? values.category : undefined,
    itemType: values.itemType ? values.itemType : undefined,
    'productionStatus.jobStatus': values.productionStatus ? values.productionStatus : undefined,
    customerJobName: values.customerJobName ? { $LIKE: values.customerJobName } : undefined,
    quantity: values.quantity ? values.quantity : undefined,
    createdAt: {
      $gte: values.start ? moment(values.start).format('YYYY-MM-DD') : undefined,
      $lte: values.end ? moment(values.end).format('YYYY-MM-DD') : undefined,
    },
  };

  return [].reduce(
    (m, field) => ({
      ...m,
      [field]: values[field] ? values[field] : undefined,
    }),
    filterMap
  );
};

export const groupOrderItems = (data: any) =>
  data?.reduce((acc, cur) => {
    const found = acc?.find((t) => Object.keys(t)?.find((s) => s === cur?.humanId?.split('-')[0]));

    if (!found) {
      acc.push({
        [cur?.humanId?.split('-')[0]]: [cur],
      });
    } else {
      const foundIdx = acc.findIndex((a) => Object.keys(a)[0] === Object.keys(found)[0]);

      const sortedItems = [...acc[foundIdx][cur.humanId.split('-')[0]], cur]?.sort(
        (a, b) => Number(a.humanId.split('-')[1]) - Number(b.humanId.split('-')[1])
      );

      acc.splice(foundIdx, 1, {
        [cur.humanId.split('-')[0]]: sortedItems,
      });
    }

    return acc;
  }, []) ?? [];

const serviceName = 'order-items';
const pathPrefix = '/customers';

export interface CustomerDetailProps extends OrderItemModalsProps {
  loadCustomerOrderItems: Function;
  deleteStoredCard: Function;
  saveCustomerNotes: Function;
  orderItems: any;
  reload: Function;
}

const { useState, useEffect } = React;

const isSandbox = window?.location?.hostname?.endsWith('.app.inkcloud9.dev');

const PreList: React.FunctionComponent<
  ListProps & OrderItemModalsProps & CustomerDetailProps & RouteComponentProps<any>
> = (props) => {
  const [tenant, setTenant] = useState<any>(null);
  const [availableCredit, setAvailableCredit] = useState<number>(0);
  const [isReloadingCreditMemo, setIsReloadingCreditMemo] = useState<boolean>(true);

  const {
    match: { params },
    location,
    history,
    data,
  } = props;
  const {
    state,
    reload,
    handleDelete,
    selectedItems,
    clearAll,
    setChecked,
    someSelected,
    toggleAll,
    filter,
    setFilter,
    sort,
    handleSort,
  } = useList({
    feathers: [
      'find',
      serviceName,
      [
        {
          query: {
            customer: params.id,
            $populate: [
              { path: 'attributes.attribute', select: 'name' },
              { path: 'attributes.value', select: 'name' },
              { path: 'category', select: 'name' },
              { path: 'customer', select: 'organizationName primaryContact' },
              { path: 'micrositeUser', select: 'firstName lastName' },
              { path: 'productionChannel', select: 'name' },
              { path: 'vendor', select: 'companyName' },
              // 'productionStatus.jobStatus',
              'miscUploads',
            ],
            // sort: { humandId: 1 }
          },
        },
      ],
    ],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  // console.log('data', data)

  useEffect(() => {
    const loadTenant = async () => {
      try {
        const res: any = await Feathers.service('/tenant-settings').get(data.business, {
          query: {},
        });

        setTenant(res);
      } catch (e) {
        console.error(e);
      }
    };
    if (data?.business) {
      loadTenant();
    }
  }, [data?.business]);

  React.useEffect(() => {
    const loadAvailableCredit = async () => {
      try {
        const res: any = await Feathers.service('/credit-memo/balance').get(params.id);

        setAvailableCredit(res?.creditMemoAmountAvailable ?? 0);
      } catch (e) {
        console.error(e);
      }

      setIsReloadingCreditMemo(false);
    };

    if (isReloadingCreditMemo) loadAvailableCredit();
  }, [params.id, isReloadingCreditMemo]);

  const { state: stateCreditMemo, reload: reloadCreditMemo } = useList({
    feathers: [
      'find',
      'credit-memo',
      [
        {
          query: {
            customer: params.id,
            $sort: { createdAt: -1 },
            $populate: ['invoice'],
          },
        },
      ],
    ],
    filterMap: () => {},
    debounce: 150,
    location,
    history,
    sort: { priority: 1, name: 1 },
  });

  const [isLiaModalOpen, setIsLiaModalOpen] = useState(false);
  const [isCreditMemoModalOpen, setIsCreditMemoModalOpen] = useState(false);
  const [isClearCartModalOpen, setIsClearCartModalOpen] = useState(false);
  const [isModifyCartModalOpen, setIsModifyCartModalOpen] = useState(false);
  const [liaUrl, setLiaUrl] = useState('');
  const [selectedInfoMenu, setSelectedInfoMenu] = useState('Contact');
  const [isNotesModalOpen, setIsNotesModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState({ newOrder: false, loginAs: false });
  const [errorMessage, setErrorMessage] = useState({ newOrder: '', loginAs: '' });

  // Real-time: Subscribe to any changes and reload
  useEffect(() => {
    const handler = (msg) => reload();
    socket.service('order-items').on('patched', handler);
    socket.service('order-items').on('created', handler);
    return () => {
      socket.service('order-items').removeListener('patched', handler);
      socket.service('order-items').removeListener('created', handler);
    };
  }, []);

  const handleArtworkManagementClick = (e, data) => {
    props.artworkHandleOpen(data.orderitem);
  };

  const printHandleOpen = (e, data) => {
    props.printHandleOpen(data.type, data.payload);
  };

  const preflightHandleOpen = (e, data) => {
    props.preflightHandleOpen(data.orderitem);
  };

  const statusChangeHandleOpen = (e, data) => {
    // props.statusChangeHandleOpen(data.orderitem);
  };

  const handleInfoMenuClick = (e, data) => setSelectedInfoMenu(data.name);

  const handleNoteSubmit = (value) => {
    props
      .saveCustomerNotes({
        customerId: params.id,
        content: value,
      })
      .then((result) => {
        setIsNotesModalOpen(false);
        props.reload();
      });
  };

  const handleClearCartSubmit = async () => {
    const res: any = await Feathers.service('/cart/clear-active').create({
      tenant: data.business,
      customer: params.id,
    });

    if (res) {
      setIsClearCartModalOpen(false);
    }
  };

  const domainName = tenant?.publishedContactInfo?.domainName.split('.');

  const handleLogInAs = async (e) => {
    setIsLiaModalOpen(true);

    try {
      const res: any = await Feathers.service('/customer-link-token').create({
        payload: {
          cid: data._id,
          tid: data.business,
        },
      });
      const url = `http://${domainName.slice(
        0,
        domainName.length - 1
      )}.localdev.inkcloud9.com:3000${res.url}`;

      if (ENV === 'development' || ENV === 'staging') {
        setLiaUrl(url);
      } else {
        (window as any).open(url);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleNewLogInAs = async () => {
    setIsLiaModalOpen(true);
    setErrorMessage({ ...errorMessage, loginAs: '' });
    setIsLoading({ ...isLoading, loginAs: true });

    try {
      const res = await Feathers.service('/customer/login-as-user').create({
        tenant: data.business,
        customer: params.id,
      });

      if (res) {
        if (ENV === 'development' || ENV === 'staging') {
          const url = `http://${domainName.slice(
            0,
            domainName.length - 1
          )}.localdev.inkcloud9.com:3000${res.url}`;

          setLiaUrl(url);
        } else if (isSandbox) {
          setLiaUrl(
            `https://${domainName.slice(0, domainName.length - 1).join()}.site.inkcloud9.dev${
              res.url
            }`
          );
        } else {
          setLiaUrl(`https://www.${tenant.publishedContactInfo.domainName}${res.url}`);
        }
      }
    } catch (e) {
      const errMsg = e?.code < 500 ? e?.message : 'Something went wrong. Please try again later.';
      setErrorMessage({
        ...errorMessage,
        loginAs: errMsg,
      });
    }
    setIsLoading({ ...isLoading, loginAs: false });
  };

  const handleOrderBuilder = async () => {
    setIsLoading({ ...isLoading, newOrder: true });
    setErrorMessage({ ...errorMessage, newOrder: '' });

    const [error, res] = await To(
      Feathers.service('cart/initialize').create({
        customer: params.id,
      }) as any
    );

    if (res) {
      localStorage.setItem('last-cart', res._id);
      history.push('/order-builder');
    } else {
      setErrorMessage({ ...errorMessage, newOrder: error.message });
    }
    setIsLoading({ ...isLoading, newOrder: false });
  };

  const { queryResult, isLoading: isStateLoading } = state as any;

  if (!queryResult || !data) {
    return null;
  }

  const { primaryContact } = data;
  let customerName = '';
  if (data.organizationName) {
    customerName = data.organizationName;
  } else if (data.primaryContact) {
    customerName = `${primaryContact.firstName} ${primaryContact.lastName}`;
  }

  const customerId = params.id;

  // const sortedOrderItems = queryResult?.data?.map((i: any) => ({
  //   ...i,
  //   priority: parseInt(i.humanId.split("-")[1])
  // }))?.sort((a, b) => a.priority - b.priority);

  const sortedOrderItems = groupOrderItems(queryResult?.data);

  return (
    <>
      {errorMessage.newOrder && <Message error content={errorMessage.newOrder} />}
      <PageHeaderRow header={customerName} subheader="Customer" subheaderId={data && data.humanId}>
        <Button
          content="Create New Order"
          size={'mini'}
          loading={isLoading.newOrder}
          onClick={handleOrderBuilder}
        />
        <Button
          content="Clear Active Carts"
          size={'mini'}
          onClick={() => setIsClearCartModalOpen(true)}
        />

        <Button
          content="Modify Cart"
          size={'mini'}
          onClick={() => setIsModifyCartModalOpen(true)}
        />
        <Link
          to={{
            pathname: `/customers/${params.id}/customer-product-library`,
            search: `?name=${customerName}&test=test`,
            state: {
              name: customerName,
              test: 'test',
            },
            key: `${params.id}`,
          }}
        >
          <Button content="Customer Product Library" size={'mini'} />
        </Link>
        <Button
          content="Credit Memo"
          size={'mini'}
          onClick={() => setIsCreditMemoModalOpen(true)}
        />
        <Link to={`/customers/${params.id}/edit`}>
          <Button content="Edit" size={'mini'} />
        </Link>
        {/* <Button onClick={handleLogInAs} size="mini">
            Login As
          </Button> */}
        <Button onClick={handleNewLogInAs} size="mini">
          Login As
        </Button>
        <Link to="/customers">
          <Button content="Back" size={'mini'} />
        </Link>
      </PageHeaderRow>

      <Message info>
        Available Credit:{' '}
        <FormattedNumber
          currency={tenant?.internationalization?.currency}
          value={availableCredit}
          style="currency"
        />
      </Message>

      <Menu pointing secondary size="small">
        <Menu.Item
          name="Contact"
          active={selectedInfoMenu === 'Contact'}
          onClick={handleInfoMenuClick}
        />
        <Menu.Item
          name="Shipping"
          active={selectedInfoMenu === 'Shipping'}
          onClick={handleInfoMenuClick}
        />
        <Menu.Item
          name="Sales Tax"
          active={selectedInfoMenu === 'Sales Tax'}
          onClick={handleInfoMenuClick}
        />
        <Menu.Item
          name="Stored Cards"
          active={selectedInfoMenu === 'Stored Cards'}
          onClick={handleInfoMenuClick}
        />
        <Menu.Item
          name="Credit Memo"
          active={selectedInfoMenu === 'Credit Memo'}
          onClick={handleInfoMenuClick}
        />
        <Menu.Menu position="right">
          <Menu.Item name="Notes" active={true} />
          <Menu.Item icon={'add'} onClick={() => setIsNotesModalOpen(true)} />
        </Menu.Menu>
      </Menu>

      {selectedInfoMenu !== 'Credit Memo' ? (
        <div
          className="ui two column divided padded white grid"
          style={{ background: 'white', fontSize: 12 }}
        >
          <div className="ten wide column">
            {selectedInfoMenu === 'Contact' && <InfoTable data={data} />}
          </div>
          <div className="six wide column">
            <NotesComponent
              isModalOpen={isNotesModalOpen}
              notes={data?.notes ?? {}}
              onSubmit={handleNoteSubmit}
              handleNoteClose={() => setIsNotesModalOpen(false)}
            />
          </div>
        </div>
      ) : (
        <div className="ui d padded white grid" style={{ background: 'white', fontSize: 12 }}>
          <div className=" column">
            <EmptyWrapper
              queryResult={stateCreditMemo.queryResult}
              isStateLoading={stateCreditMemo.isLoading}
            >
              <PaginatorWrapper.Top
                filter={filter}
                reload={reloadCreditMemo}
                data={stateCreditMemo.queryResult as any}
                pathPrefix={`${pathPrefix}/${params.id}/`}
              />
              <Divider hidden />

              <Table>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>ID</Table.HeaderCell>
                    <Table.HeaderCell>Date</Table.HeaderCell>
                    <Table.HeaderCell textAlign="right">Amount</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {(stateCreditMemo.queryResult as any)?.data?.map((i: any, index: number) => (
                    <Table.Row key={index}>
                      <Table.Cell>{i?.invoice?.humanId ?? ''}</Table.Cell>
                      <Table.Cell>
                        <Popup
                          size="mini"
                          content={<FormattedTime value={i.createdAt} />}
                          trigger={
                            <div>
                              <FormattedDate value={i.createdAt} />
                            </div>
                          }
                        />
                      </Table.Cell>
                      <Table.Cell textAlign="right">
                        {i.transactionType !== 'CREDIT' && '('}
                        <FormattedNumber
                          currency={tenant?.internationalization?.currency}
                          value={i?.amount}
                          style="currency"
                        />
                        {i.transactionType !== 'CREDIT' && ')'}
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>

              <Divider hidden />

              <PaginatorWrapper.Bottom
                reload={reload}
                data={stateCreditMemo.queryResult as any}
                pathPrefix={`${pathPrefix}/${params.id}/`}
              />
            </EmptyWrapper>
          </div>
        </div>
      )}

      <Menu attached="top" pointing secondary>
        <Menu.Item name="order items" active={true} />
      </Menu>
      <Segment attached="bottom">
        <FilterForm value={filter} onChange={(v) => setFilter(v)} onReset={() => setFilter({})} />
        <EmptyWrapper queryResult={queryResult} isStateLoading={isStateLoading}>
          <PaginatorWrapper.Top
            filter={filter}
            reload={reload}
            data={queryResult as any}
            pathPrefix={`${pathPrefix}/${params.id}/`}
          />
          <Divider hidden />

          {sortedOrderItems?.map((group) =>
            Object?.keys(group)?.map((key, idx) =>
              group[key]?.map((g) => (
                <OrderItemComponent2
                  key={g._id}
                  orderItem={g}
                  handleArtworkManagementClick={handleArtworkManagementClick}
                  printHandleOpen={printHandleOpen}
                  preflightHandleOpen={preflightHandleOpen}
                  statusChangeHandleOpen={statusChangeHandleOpen}
                  onReload={reload}
                />
              ))
            )
          )}

          {/* {
              sortedOrderItems?.map((i: any, index: number) => (

                <OrderItemComponent2
                  key={index}
                  orderItem={i}
                  handleArtworkManagementClick={handleArtworkManagementClick}
                  printHandleOpen={printHandleOpen}
                  preflightHandleOpen={preflightHandleOpen}
                  statusChangeHandleOpen={statusChangeHandleOpen}
                  // onStatusChangerSubmit={handleStatusChangerSubmit}
                  onReload={reload}
                // onTurnaroundSubmit={handleTurnaroundSubmit}
                />
              ))} */}

          <Divider hidden />

          <PaginatorWrapper.Bottom
            reload={reload}
            data={queryResult as any}
            pathPrefix={`${pathPrefix}/${params.id}/`}
          />
        </EmptyWrapper>
      </Segment>

      {isLiaModalOpen && (
        <LogInAsModal
          customer={data}
          isLoading={isLoading.loginAs}
          errorMessage={errorMessage.loginAs}
          onClose={() => {
            setIsLiaModalOpen(false);
            setLiaUrl('');
          }}
          url={liaUrl}
        />
      )}

      {isCreditMemoModalOpen && (
        <CreditMemoModal
          customer={data._id}
          open={isCreditMemoModalOpen}
          onClose={() => setIsCreditMemoModalOpen(false)}
          onReload={() => setIsReloadingCreditMemo(true)}
        />
      )}

      {isModifyCartModalOpen && (
        <ModifyCartModal
          open={isModifyCartModalOpen}
          customerId={customerId}
          onClose={() => setIsModifyCartModalOpen(false)}
        />
      )}

      {isClearCartModalOpen && (
        <ClearCartModal
          open={isClearCartModalOpen}
          onConfirm={handleClearCartSubmit}
          onClose={() => setIsClearCartModalOpen(false)}
        />
      )}
    </>
  );
};

export const List = connectOrderItemModals<IndexWrappedProps & CustomerDetailProps>(PreList);

export default DetailWrapper(List, 'customers', {
  query: {
    $populate: ['notes.user'],
  },
  mapStateToProps: (state: any) => ({
    orderItems: state?.orderItems,
  }),
  mapDispatchToProps: {
    loadCustomerOrderItems: services.orderItems.find,
    saveCustomerNotes: services.customersNotes.create,
    deleteStoredCard: services.paymentGatewayDeleteCard.create,
  },
});
