import * as React from 'react';
import { Button, Form, Icon, Popup, Label } from 'semantic-ui-react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { FormattedDate, FormattedTime } from 'react-intl';

import { useList, ListPage, basicQueryMap } from '@inkcloud/components';
import Feathers, { socket } from './../../bootstrap/feathers';
import { IndexWrappedProps } from './../../bootstrap/IndexWrapper';
import { connectOrderItemModals, OrderItemModalsProps } from '../../common/OrderItemCard2';

import { getFilterDefinition } from './OrdersOverallFilter';
import BatchPrintModal from './BatchPrintModal';

interface ListProps {}

const filterQueryMap = (values) => {
  let filterRows = basicQueryMap(values);

  filterRows = {
    ...filterRows,
  };

  return filterRows;
};

const serviceName = 'orders';
const pathPrefix = '/orders/overall';

const PreList: React.FunctionComponent<
  ListProps & OrderItemModalsProps & RouteComponentProps<any>
> = (props) => {
  const {
    match: { params },
    location,
    history,
  } = props;

  const [isBatchPrintModalOpen, setIsBatchPrintModalOpen] = React.useState(false);

  const useListValues = useList({
    feathers: [
      'find',
      serviceName,
      [
        {
          query: {
            $populate: ['customer', 'microsite', 'micrositeUser'],
            $populateOrderItems: 1,
            $sort: { createdAt: -1 },
          },
        },
      ],
    ],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  const headerActions = (
    <>
      <Form>
        <Form.Field inline>
          <Button
            size="mini"
            content="Batch Print"
            onClick={() => setIsBatchPrintModalOpen(true)}
          />
          <label>View: </label>
          <Popup
            size="mini"
            content="Per Order"
            trigger={<Icon name="th list" size="large" color="blue" />}
          />
          <Popup
            size="mini"
            content="Per Order Item"
            position="top right"
            trigger={
              <Link to={'/orders'}>
                <Icon name="th" size="large" color="grey" />
              </Link>
            }
          />
        </Form.Field>
      </Form>
    </>
  );

  const tableDefintion = [
    { key: 'humanId', label: 'ID', sortable: true, cell: (v, r) => <span>{r?.humanId}</span> },
    {
      key: 'createdAt',
      label: 'Date',
      sortable: true,
      cell: (v, r) => (
        <Popup
          size="mini"
          content={<FormattedTime value={r.createdAt} />}
          trigger={
            <div>
              <FormattedDate value={r.createdAt} />
            </div>
          }
        />
      ),
    },
    {
      key: 'customer',
      label: 'Customer',
      cell: (v, r) => (
        <Link to={`/customers/${r.customer?._id}`}>
          {r.customer?.organizationName ||
            `${r.customer?.primaryContact?.firstName ?? ''} ${
              r.customer?.primaryContact?.lastName ?? ''
            }`}
        </Link>
      ),
    },
    {
      key: 'psuedoStatus',
      label: 'Completed Shipments',
      sortable: false,
      cell: (v, r) => (
        <>
          <span>{r?.actualShipments?.length > 0 ? `${r?.actualShipments?.length}` : '0'}</span>
          {r?.orderItems?.length > 0 ? (
            <Label.Group as="span" size="tiny" style={{ marginLeft: '5px' }}>
              {r?.orderItems
                ?.reduce((acc, cur) => {
                  const found = acc?.find(
                    (a) => a?.productionStatus?.jobStatus === cur?.productionStatus?.jobStatus
                  );
                  if (!found) {
                    acc.push(cur);
                  }

                  return acc?.sort((a, b) => {
                    const nameA = a?.productionStatus?.jobStatus?.toLowerCase();
                    const nameB = b?.productionStatus?.jobStatus?.toLowerCase();
                    if (nameA < nameB) {
                      return -1;
                    }
                    if (nameA > nameB) {
                      return 1;
                    }
                    return 0;
                  });
                }, [])
                ?.map((o: any, index: number) => (
                  <Label as="a" key={index}>
                    {o?.productionStatus?.description}
                  </Label>
                ))}
            </Label.Group>
          ) : (
            ''
          )}
        </>
      ),
    },

    {
      key: '',
      label: '',
      cell: (v, r) => (
        <div style={{ width: '160px', marginLeft: 'auto' }}>
          <Popup
            size="mini"
            content="View Details"
            align
            trigger={
              <Link to={`/orders/${r._id}`}>
                <Button size="mini" icon="magnify" />
              </Link>
            }
          />
          <Popup
            size="mini"
            content="Print Pick List"
            align
            trigger={
              <Button
                size="mini"
                icon="print"
                onClick={() => props.printHandleOpen(3, { orderId: r._id })}
              />
            }
          />
          <Popup
            size="mini"
            content="Print Ticket"
            align
            trigger={
              <Button
                size="mini"
                icon="print"
                onClick={() => props.printHandleOpen(1, { orderId: r._id })}
              />
            }
          />
          <Popup
            size="mini"
            content="Print Will-Call/Delivery Receipt"
            align
            trigger={
              <Button
                size="mini"
                icon="print"
                onClick={() => props.printHandleOpen(2, { orderId: r._id })}
              />
            }
          />
        </div>
      ),
      textAlign: 'right',
    },
  ];

  return (
    <>
      <ListPage
        header="Orders"
        subheader="Order"
        useListValues={useListValues}
        history={history}
        pathPrefix={pathPrefix}
        tableDefintion={tableDefintion}
        withCheckboxes={false}
        headerActions={headerActions}
        getFilterDefinition={getFilterDefinition}
      />

      {isBatchPrintModalOpen && (
        <BatchPrintModal
          mode="order"
          open={isBatchPrintModalOpen}
          onClose={() => setIsBatchPrintModalOpen(false)}
          data={(useListValues?.state?.queryResult as any)?.data}
        />
      )}
    </>
  );
};

export const List = connectOrderItemModals<IndexWrappedProps>(PreList);
