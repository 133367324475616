import * as React from 'react';
import { Switch, Route, RouteComponentProps } from 'react-router-dom';

import JobschedulerList from './JobSchedulerList';
import JobschedulerWorkflowTemplatesModal from './workflow-templates/JobSchedulerWorkflowTemplatesModal';

import JobschedulerWorkflowTemplateTypesModal from './workflow-template-types/JobWorkflowTemplateTypeModal';

import JobSchedulerWorkflowColumnsModal from './columns/JobSchedulerWorkflowColumnsModal';

import JobSchedulerModal from './jobs/JobSchedulerModal';
import JobViewManagementModal from './view-managements/JobViewManagementModal';

const WorkflowTemplateModal = (props: RouteComponentProps<{ id: string }>) => {
  const { history, match } = props;
  const handleOnClose = () => history.push('/job-scheduler');

  return (
    <JobschedulerWorkflowTemplatesModal
      isOpen={true}
      onClose={handleOnClose}
      id={match.params.id}
    />
  );
};
const WorkflowTemplateTypeModal = (props: RouteComponentProps<{ id: string }>) => {
  const { history, match } = props;
  const handleOnClose = () => history.push('/job-scheduler');

  return <JobschedulerWorkflowTemplateTypesModal isOpen={true} onClose={handleOnClose} />;
};

const ColumnsModal = (props: RouteComponentProps<{ id: string }>) => {
  const { history, match } = props;
  const handleOnClose = () => history.push('/job-scheduler');

  return <JobSchedulerWorkflowColumnsModal isOpen={true} onClose={handleOnClose} />;
};

const JobsModal = (props: RouteComponentProps<{ id: string }>) => {
  const { history, match } = props;
  const handleOnClose = () => history.push('/job-scheduler');

  return <JobSchedulerModal isOpen={true} onClose={handleOnClose} />;
};

const ViewModal = (props: RouteComponentProps<{ id: string }>) => {
  const { history, match } = props;
  const handleOnClose = () => history.push('/job-scheduler');

  return <JobViewManagementModal isOpen={true} onClose={handleOnClose} />;
};

const routes = (props2: RouteComponentProps) => (
  <>
    <Switch>
      <Route path="/job-scheduler" component={JobschedulerList} />
    </Switch>
    <Switch>
      <Route exact path="/job-scheduler/job/add" component={JobsModal} />
    </Switch>
    <Switch>
      <Route exact path="/job-scheduler/views" component={ViewModal} />
      <Route exact path="/job-scheduler/views/add" component={ViewModal} />
      <Route exact path="/job-scheduler/views/:id" component={ViewModal} />
    </Switch>
    <Switch>
      <Route exact path="/job-scheduler/columns" component={ColumnsModal} />
      <Route exact path="/job-scheduler/columns/add" component={ColumnsModal} />
      <Route exact path="/job-scheduler/columns/:id" component={ColumnsModal} />
    </Switch>
    <Switch>
      <Route exact path="/job-scheduler/workflow-templates" component={WorkflowTemplateModal} />
      <Route exact path="/job-scheduler/workflow-templates/add" component={WorkflowTemplateModal} />
      <Route exact path="/job-scheduler/workflow-templates/:id" component={WorkflowTemplateModal} />
    </Switch>
    <Switch>
      <Route
        exact
        path="/job-scheduler/workflow-template-types"
        component={WorkflowTemplateTypeModal}
      />
      <Route
        exact
        path="/job-scheduler/workflow-template-types/add"
        component={WorkflowTemplateTypeModal}
      />
      <Route
        exact
        path="/job-scheduler/workflow-template-types/:id"
        component={WorkflowTemplateTypeModal}
      />
    </Switch>
  </>
);

export default routes;
