import * as React from 'react';
import { Button, Form, Input, Segment, Image, Message } from 'semantic-ui-react';
import { Formik, Form as FormikForm, Field, FieldProps, FieldArray, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import { SemanticField } from '../../../../common/Form';

import { ImageUploadModal } from './ImageUploadModal';

interface BannerFormProps {
  isSubmitting: boolean;
  errorMessage: string;
  initialValues: any;
  onSubmit: (v: any) => void;
}

const { useState, useEffect } = React;

const BannerSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  banner: Yup.string().required('Required'),
  altText: Yup.string().required('Required'),
  priority: Yup.number().typeError('Priority must be a number'),
});

export const BannerForm: React.FunctionComponent<BannerFormProps> = (props) => {
  const { initialValues } = props;
  const [isOpenModal, setIsOpenModal] = useState(false);

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={BannerSchema}
        onSubmit={(values, { setSubmitting }) => {
          props.onSubmit(values);
        }}
      >
        {(formProps) => {
          const {
            values,
            touched,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            setFieldValue,
            setFieldTouched,
          } = formProps;

          return (
            <FormikForm className={`ui form ${props.isSubmitting && 'loading'}`}>
              <Form.Field>
                <SemanticField name="name" label="Name" component={Form.Input} />
                <ErrorMessage
                  render={(msg) => <Message negative size="tiny" content={msg} />}
                  name="name"
                />
              </Form.Field>
              <Form.Field>
                {values.banner && (
                  <Image
                    bordered
                    label={{
                      as: 'a',
                      corner: 'left',
                      icon: 'check circle outline',
                    }}
                    src={`https://storage.googleapis.com/icstorage/${values.banner}`}
                  />
                )}
              </Form.Field>
              <ErrorMessage
                render={(msg) => <Message negative size="tiny" content={msg} />}
                name="banner"
              />
              <Form.Group widths="equal">
                <Form.Field>
                  <Button
                    type="button"
                    primary
                    content="Upload/Select Image"
                    onClick={() => setIsOpenModal(true)}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Field>
                <SemanticField name="altText" label="Alt Text" component={Form.Input} />
                <ErrorMessage
                  render={(msg) => <Message negative size="tiny" content={msg} />}
                  name="altText"
                />
              </Form.Field>
              <Form.Field>
                <SemanticField name="className" label="HTML Class" component={Form.Input} />
                <ErrorMessage
                  render={(msg) => <Message negative size="tiny" content={msg} />}
                  name="altText"
                />
              </Form.Field>
              <Form.Field>
                <SemanticField name="url" label="Link" component={Form.Input} />
              </Form.Field>
              <Form.Group widths="equal">
                <Form.Field>
                  <label>Start Date</label>
                  <Field
                    name="startDate"
                    render={({ field }: FieldProps<any>) => (
                      <>
                        <DatePicker
                          dateFormat="MM/dd/yyyy"
                          selected={
                            typeof field.value === 'string'
                              ? Date.parse(moment(field.value).toISOString())
                              : field.value
                          }
                          onChange={(date) =>
                            field.onChange({ target: { value: date, name: 'startDate' } } as any)
                          }
                        />
                      </>
                    )}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Expire Date</label>
                  <Field
                    name="expireDate"
                    render={({ field }: FieldProps<any>) => (
                      <>
                        <DatePicker
                          dateFormat="MM/dd/yyyy"
                          selected={
                            typeof field.value === 'string'
                              ? Date.parse(moment(field.value).toISOString())
                              : field.value
                          }
                          onChange={(date) =>
                            field.onChange({ target: { value: date, name: 'expireDate' } } as any)
                          }
                        />
                      </>
                    )}
                  />
                </Form.Field>
              </Form.Group>

              <Form.Field>
                <SemanticField name="priority" label="Priority" component={Form.Input} />
                <ErrorMessage
                  render={(msg) => <Message negative size="tiny" content={msg} />}
                  name="priority"
                />
              </Form.Field>
              <Form.Field>
                <SemanticField name="isPublished" label="Is Published" component={Form.Checkbox} />
              </Form.Field>
              <Form.Field>
                <Button
                  primary
                  type="submit"
                  content="Submit"
                  disabled={!values.banner || props.isSubmitting}
                />
              </Form.Field>
              {isOpenModal && (
                <ImageUploadModal
                  isOpen={isOpenModal}
                  onClose={() => setIsOpenModal(false)}
                  setFieldValue={setFieldValue}
                />
              )}
            </FormikForm>
          );
        }}
      </Formik>
    </>
  );
};
