import 'core-js';

import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider, useSelector } from 'react-redux';
import { BrowserRouter, useLocation } from 'react-router-dom';
import { CompatRouter } from 'react-router-dom-v5-compat';
import { SaasProvider } from '@saas-ui/react';
import { withRouter } from 'react-router';
import { IntlProvider } from 'react-intl';
import theme from './theme';
import { store } from './bootstrap/store/index';
import { Routes } from './routes';
import feathersApp, { services, feathersAuthentication, socket } from './bootstrap/feathers'; // eslint-disable-line no-unused-vars
import * as misc from './bootstrap/misc';
// import { AnalyticsTracker } from './bootstrap/AnalyticsTracker';
import 'semantic-ui-css/semantic.min.css';
import { AbilityContext, ability, rolesToPermissionsList } from './common/permissions';
import './styles.less';

// force rebuild
declare const ENV: string;
declare const HMR: number;

type Props = any & { children: React.ReactNode };

const ProviderCasted = Provider as unknown as React.ComponentType<Props>;

if ((ENV === 'development' || HMR === 1) && module.hot) {
  module.hot.accept();
}

const url = window.location.hostname;

const tenantKey = url.split('.')[0];

if (localStorage['feathers-jwt']) {
  if (!window.location.pathname.endsWith('/lia')) {
    const loadInit = async () => {
      try {
        const res = await feathersAuthentication.authenticate()?.payload?.promise;

        const payload = {
          accessToken: res?.authentication?.accessToken,

          strategy: res?.authentication?.strategy ?? 'local',

          key: tenantKey,
        };

        store.dispatch(feathersAuthentication.authenticate(payload));

        if (res?.user?.roles) {
          const rr = ability.update(rolesToPermissionsList(res?.user?.roles2));
        }
        socket
          .authenticate()
          .then((sar) => { })
          .catch((err) => {
            console.log('err', err);
          });
      } catch (e) {
        store.dispatch(feathersAuthentication.logout());
      }
    };

    loadInit();
  }
}

function ScrollToTop({children}) {
  const { pathname, key, state } = useLocation<{top: any}>();

  React.useEffect(() => {
    window.scrollTo(0, state?.top ?? 0);
  }, [key, state?.top]);

  return children;
}

// const ScrollToTopWithRouter = withRouter(ScrollToTop);

const container = document.getElementById('root');
if (container) {
  const root = createRoot(container); // createRoot(container!) if you use TypeScri
  root.render(
    <SaasProvider theme={theme}>
      <ProviderCasted store={store}>
        <BrowserRouter>
          <CompatRouter>
            <ScrollToTop>
              {/* <AnalyticsTracker /> */}
              <AbilityContext.Provider value={ability}>{Routes}</AbilityContext.Provider>
            </ScrollToTop>
          </CompatRouter>
        </BrowserRouter>
      </ProviderCasted>
    </SaasProvider>
  );
}
