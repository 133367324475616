import * as React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Popup } from 'semantic-ui-react';
import { FormattedDate, FormattedTime } from 'react-intl';
import { ListTable, ListPage, PageHeaderRow, EmptyWrapper, useList } from '@inkcloud/components';

import { CustomsReportModal } from './CustomsReportModal';

interface IListProps {}

const { useState } = React;

export const List: React.FunctionComponent<IListProps & RouteComponentProps> = (props) => {
  const { match, history, location } = props;

  const serviceName = '/reports/v3/custom-reports';
  const pathPrefix = `/reports/customs`;
  const filterQueryMap = (values) => {};

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [custom, setCustom] = useState(null);

  const tableDefinition = [
    {
      key: 'createdAt',
      label: 'Date',
      sortable: true,
      cell: (v) =>
        v && (
          <Popup
            size="mini"
            content={<FormattedTime value={v} />}
            trigger={
              <div>
                <FormattedDate value={v} />
              </div>
            }
          />
        ),
    },
    { key: 'reportKey', label: 'Key', sortable: true },
    { key: 'reportName', label: 'Name', sortable: true },
    {
      key: '',
      label: '',
      sortable: false,
      cell: (v, r) => (
        <Popup
          size="mini"
          content="Run Report"
          trigger={
            <Button
              size={'mini'}
              icon={'list'}
              onClick={() => {
                setIsOpenModal(true);
                setCustom(r);
              }}
            />
          }
        />
      ),
      textAlign: 'right',
    },
  ];

  const {
    state,
    reload,
    handleDelete,
    selectedItems,
    clearAll,
    setChecked,
    someSelected,
    toggleAll,
    filter,
    setFilter,
    sort,
    handleSort,
  } = useList({
    feathers: ['find', serviceName, [{ query: {} }]],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: {},
  });

  const { queryResult, isLoading: isStateLoading } = state as any;

  const resolvedQueryResult = queryResult?.data ? queryResult.data : queryResult;

  return (
    <>
      <PageHeaderRow header="Customs" subheader="Report" />

      <EmptyWrapper queryResult={resolvedQueryResult} isStateLoading={isStateLoading}>
        <ListTable
          data={resolvedQueryResult}
          definition={tableDefinition}
          selectedItems={[selectedItems]}
          setChecked={(v) => setChecked(v)}
          sort={sort}
          sortClickFunction={handleSort}
          toggleAll={() => toggleAll}
          withCheckboxes={false}
        />
      </EmptyWrapper>

      {isOpenModal && (
        <CustomsReportModal
          isOpen={isOpenModal}
          data={custom}
          onClose={() => {
            setIsOpenModal(false);
            setCustom(null);
          }}
        />
      )}
    </>
  );
};
