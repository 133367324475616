import * as React from 'react';
import {
  Modal,
  ModalProps,
  Segment,
  Table,
  Input,
  Dropdown,
  Button,
  Message,
} from 'semantic-ui-react';
import { EmptyWrapper } from '@inkcloud/components';
import { Checkbox } from '@chakra-ui/react';
import type { ModelTypes } from '@inkcloud/icapi-types';
import Feathers from '../../../../bootstrap/feathers';

export interface ContentsModalProps {
  handleClose: () => void;
  open: boolean;
  location: string;
  locationName: string;
  onReload: () => void;
}

// const initialState = { products: [] };

// type State = typeof initialState;
// export class ContentsModal extends React.Component<ContentsModalProps, State> {
//   state = initialState;
//   async componentDidMount() {
//     const products = await Feathers.service('/inventory/operations').create({
//       operation: "GET_PRODUCTS_ON_LOCATION",
//       details: {
//         location: this.props.location
//       },
//     }) as any[];

//     this.setState({...this.state, products });
//   }
//   public render() {
//     const { props } = this;

//     return (
//       <Modal size={'small'} open={props.open} onClose={props.handleClose}>
//         <Modal.Header>Location Content - <strong>{this.props.locationName}</strong></Modal.Header>
//         <Modal.Content>
//           { this.state.products && this.state.products.length > 0 &&
//           <Table size={'small'}>
//             <Table.Header>
//               <Table.Row>
//                 <Table.HeaderCell>Name</Table.HeaderCell>
//                 <Table.HeaderCell>Key</Table.HeaderCell>
//                 <Table.HeaderCell>Stock Available</Table.HeaderCell>
//               </Table.Row>
//             </Table.Header>
//             <Table.Body>
//               { this.state.products && this.state.products.map(({product, totalStocksOnLocation }, idx) => {
//                 return (
//                   <Table.Row key={idx}>
//                   <Table.Cell>
//                     <Checkbox />
//                   </Table.Cell>
//                   <Table.Cell>
//                     {product.staticName}
//                   </Table.Cell>
//                   <Table.Cell>
//                     {product.key}
//                   </Table.Cell>
//                   <Table.Cell>
//                     { totalStocksOnLocation}
//                   </Table.Cell>
//                 </Table.Row>
//                 );
//               })}
//             </Table.Body>
//           </Table>
//           }
//           { (!this.state.products || this.state.products.length === 0) && (
//               <Segment>Nothing found at location</Segment>
//             )}
//         </Modal.Content>
//       </Modal>
//     );
//   }
// }

const { useEffect, useState } = React;
type NoUndefinedField<T> = { [P in keyof T]-?: NoUndefinedField<NonNullable<T[P]>> };

type productType = NonNullable<{
  product: ModelTypes.ProductLegacy & { _id: string };
  totalStocksOnLocation: number;
  totalStocksToMove: number;
}>;

export const ContentsModal: React.FunctionComponent<ContentsModalProps> = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts] = useState<productType[]>([]);
  const [locations, setLocations] = useState<ModelTypes.InventoryLocations[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<{
    [key: string]: boolean;
  }>({});
  const [selectedLocation, setSelectedLocation] = useState('');

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const loadProduct = async () => {
      setIsLoading(true);
      try {
        const res = await Feathers.service('/inventory/operations').create({
          operation: 'GET_PRODUCTS_ON_LOCATION',
          details: {
            location: props.location,
          },
        });

        const t = res?.map((r) => ({ ...r, totalStocksToMove: r.totalStocksOnLocation }));

        setProducts(t);
      } catch (e) {
        console.error(e);
      }
      setIsLoading(false);
    };

    const loadLocations = async () => {
      try {
        const res: any = await Feathers.service('/inventory/locations').find({
          query: {
            $np: 1,
            _id: { $ne: props.location },
          },
        });

        setLocations(res);
        setSelectedLocation(res?.[0]?._id);
      } catch (e) {
        console.error(e);
      }
    };

    loadLocations();
    loadProduct();
  }, []);

  const handleSelectedProducts = (id: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;

    if (checked) {
      setSelectedProducts({
        ...selectedProducts,
        [id]: true,
      });
    } else {
      const { [id]: _, ...rest } = selectedProducts;

      setSelectedProducts(rest);
    }

    // if (checked) {
    //   setSelectedProducts([...(selectedProducts || {}), id])
    // } else {
    //   const filtered = selectedProducts?.filter((s) => s !== id)
    //   setSelectedProducts(filtered)
    // }
  };

  const handleToggleAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;

    if (checked) {
      const all = products?.reduce((acc, cur) => {
        acc[cur.product?._id] = true;
        return acc;
      }, {});

      setSelectedProducts(all);
    } else {
      setSelectedProducts({});
    }
  };

  const handleAmountChange = (id: string) => (e, data: any) => {
    const updatedProducts = products.map(({ product, totalStocksOnLocation, totalStocksToMove }) =>
      product._id === id
        ? {
            product,
            totalStocksOnLocation,
            totalStocksToMove: Number(data.value),
          }
        : {
            product,
            totalStocksOnLocation,
            totalStocksToMove,
          }
    );

    setProducts(updatedProducts);
  };

  const handleSubmit = async () => {
    setErrorMessage('');
    setIsSubmitting(true);
    try {
      const filteredProducts = products?.filter(
        ({ product, totalStocksOnLocation, totalStocksToMove }) => {
          const found = selectedProducts[product?._id];

          if (found) {
            return { product, totalStocksOnLocation, totalStocksToMove };
          }
        }
      );

      const res: any = await Promise.all(
        filteredProducts?.map(async ({ product, totalStocksOnLocation, totalStocksToMove }) =>
          Feathers.service('/inventory').create({
            product: product._id,
            operation: 'MOVEMENT',
            destination: selectedLocation,
            source: props.location,
            amount:
              totalStocksToMove > totalStocksOnLocation ? totalStocksOnLocation : totalStocksToMove,
          })
        )
      );

      props.onReload();
      props.handleClose();
    } catch (e) {
      const errMsg =
        e?.code < 500 ? e.message : 'We are experiencing technical difficulties. Please try again';

      setErrorMessage(errMsg);
    }

    setIsSubmitting(false);
  };

  const areAllSelected = products?.length === Object.keys(selectedProducts)?.length;

  return (
    <Modal size={'small'} open={props.open} onClose={props.handleClose}>
      <Modal.Header>
        Location Content - <strong>{props?.locationName ?? ''}</strong>
      </Modal.Header>
      <Modal.Content>
        <EmptyWrapper queryResult={products} isStateLoading={isLoading}>
          {products?.length > 0 && (
            <>
              <Table size={'small'}>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>
                      <Checkbox isChecked={areAllSelected} onChange={handleToggleAll}></Checkbox>
                    </Table.HeaderCell>
                    <Table.HeaderCell>SKU</Table.HeaderCell>
                    <Table.HeaderCell>Count</Table.HeaderCell>
                    <Table.HeaderCell />
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {products?.map(({ product, totalStocksToMove, totalStocksOnLocation }, idx) => (
                    <Table.Row key={idx}>
                      <Table.Cell>
                        <Checkbox
                          isChecked={!!selectedProducts[product?._id]}
                          onChange={handleSelectedProducts(product?._id)}
                        ></Checkbox>
                      </Table.Cell>
                      <Table.Cell>{product?.key}</Table.Cell>
                      <Table.Cell>{totalStocksOnLocation}</Table.Cell>
                      <Table.Cell>
                        <Input
                          value={totalStocksToMove}
                          onChange={handleAmountChange(product?._id)}
                        />
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
              <label>
                <strong>Move to location:</strong>
              </label>
              <Dropdown
                selection
                fluid
                search
                value={selectedLocation}
                options={locations?.map((l) => ({ value: l._id, text: l.name }))}
                onChange={(e, data: any) => setSelectedLocation(data?.value)}
              />
              <br />
              <div>
                <Button
                  type="button"
                  size="mini"
                  primary
                  content="Save"
                  loading={isSubmitting}
                  disabled={Object.keys(selectedProducts)?.length < 1 || isSubmitting}
                  onClick={handleSubmit}
                />
                <Button type="button" size="mini" content="Cancel" onClick={props.handleClose} />
              </div>
              {errorMessage && <Message error content={errorMessage} />}
            </>
          )}
        </EmptyWrapper>
      </Modal.Content>
    </Modal>
  );
};
