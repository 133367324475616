import * as React from 'react';
import { Button, Form, Input, Dropdown, Message } from 'semantic-ui-react';
import { Formik, Form as FormikForm, Field, ErrorMessage, FieldProps } from 'formik';
import * as Yup from 'yup';
import { FeathersDropdown } from '@inkcloud/components';
import { SemanticField } from '../../../common/Form';

interface AdjustmentFormProps {
  initialValues: any;
  handleSubmit: Function;
  isSubmitting?: boolean;
  handleCloseClick: any;
  locations: any[];
  sources?: any[];
  prodLocations?: any[];
  errorMessage?: string;
}

const { useState } = React;

export const AdjustmentForm: React.FC<AdjustmentFormProps> = (outerProps) => {
  const { handleCloseClick, sources, prodLocations, errorMessage } = outerProps;

  const [sourceId, setSourceId] = useState('');
  const selectedSource = prodLocations?.find((p) => p.id === sourceId);
  const InventoryProductSchema = Yup.object().shape({
    source: Yup.string().when('operation', {
      is: (v) => v === 'MOVEMENT',
      then: Yup.string().required('Source is required.'),
    }),
    amount: Yup.number()
      .when('source', {
        is: (v) => v !== undefined,
        then: Yup.number()
          .max(
            selectedSource && selectedSource.total,
            `Count must be less than or equal to ${selectedSource && selectedSource.total}`
          )
          .positive()
          .required(),
      })
      .when('operation', {
        is: (v) => v === 'RECEIVE',
        then: Yup.number().positive('Must be positive').required(),
      }),
    selectedProduct: Yup.string().when('transferBackorder', {
      is: (v) => v === true,
      then: Yup.string().required('Product is required.'),
    }),
    price: Yup.number().when('operation', {
      is: (v) => v === 'RECEIVE',
      then: Yup.number().required('Price is required.'),
    }),
    destination: Yup.string().required('Destination is required.'),
  });

  // check if source has value

  let operationOptions: { value: string; text: string }[] = [];

  if (sources && sources.length > 0) {
    operationOptions = [
      { value: 'RECEIVE', text: 'Receive Inventory' },
      { value: 'ADJUSTMENT', text: 'Adjustment' },
      { value: 'MOVEMENT', text: 'Move Inventory' },
    ];
  } else {
    operationOptions = [
      { value: 'RECEIVE', text: 'Receive Inventory' },
      { value: 'ADJUSTMENT', text: 'Adjustment' },
    ];
  }

  const destinationLocation = outerProps.locations?.filter(
    (l) => l.text.toLowerCase() !== 'received'
  );

  return (
    <Formik
      initialValues={{ operation: 'RECEIVE', source: '', ...outerProps.initialValues }}
      validationSchema={InventoryProductSchema}
      enableReinitialize={true}
      onSubmit={(values, { setSubmitting }) => {
        const newValues = { ...values };
        if (values.operation !== 'MOVEMENT') {
          delete newValues.source;
        }
        outerProps.handleSubmit({ ...newValues });
      }}
    >
      {(props) => {
        const { values, errors, setFieldValue, isSubmitting } = props;

        setSourceId(values.source);

        return (
          <FormikForm
            className={`ui form ${isSubmitting && !errorMessage ? 'loading' : ''} ${
              errorMessage ? 'error' : ''
            }`}
          >
            <Form.Field>
              <Field
                name="operation"
                render={({ field }: FieldProps<any>) => (
                  <Dropdown
                    selection
                    fluid
                    options={operationOptions}
                    {...field}
                    onChange={(e, data) => {
                      field.onChange({ target: { value: data.value, name: field.name } } as any);
                      setFieldValue('transferBackorder', false);
                    }}
                  />
                )}
              />
              <ErrorMessage
                render={(msg) => <Message negative size="tiny" content={msg} />}
                name="operation"
              />
            </Form.Field>
            <Form.Field>
              <SemanticField name={'amount'} label="Count" component={Form.Input} />
              <ErrorMessage
                render={(msg) => <Message negative size="tiny" content={msg} />}
                name="amount"
              />
            </Form.Field>
            {values.operation === 'MOVEMENT' && (
              <Form.Field>
                <SemanticField
                  name={'source'}
                  label="Source"
                  selection
                  fluid
                  search
                  component={Form.Dropdown}
                  options={sources}
                />
                <ErrorMessage
                  render={(msg) => <Message negative size="tiny" content={msg} />}
                  name="source"
                />
              </Form.Field>
            )}
            <Form.Field>
              <label>Destination</label>
              <Field
                name="destination"
                render={({ field }: FieldProps<any>) => (
                  <Dropdown
                    selection
                    fluid
                    search
                    options={destinationLocation}
                    {...field}
                    onChange={(e, data) => {
                      field.onChange({ target: { value: data.value, name: field.name } } as any);
                    }}
                  />
                )}
              />
              {/* <SemanticField
                name={'destination'}
                label="Destination"
                selection
                fluid
                search
                component={Form.Dropdown}
                options={destinationLocation}
              /> */}
              <ErrorMessage
                render={(msg) => <Message negative size="tiny" content={msg} />}
                name="destination"
              />
            </Form.Field>
            {values.operation === 'RECEIVE' && (
              <React.Fragment>
                <Form.Field>
                  <SemanticField name={'price'} label="Price" component={Form.Input} />
                  <ErrorMessage
                    render={(msg) => <Message negative size="tiny" content={msg} />}
                    name="price"
                  />
                </Form.Field>
                <Form.Field>
                  <SemanticField
                    name={'transferBackorder'}
                    label="Transfer Backorder"
                    component={Form.Checkbox}
                  />
                </Form.Field>
                {values.transferBackorder && (
                  <Form.Field>
                    <Field
                      name="selectedProduct"
                      render={({ field }: FieldProps<any>) => (
                        <FeathersDropdown
                          search
                          selection
                          clearable
                          placeholder="Product..."
                          serviceName="/products-legacy"
                          query={{
                            $np: 1,
                            $sort: { staticName: 1 },
                            $select: ['staticName', 'key'],
                          }}
                          resultMapper={(r) => ({
                            key: r._id,
                            value: r._id,
                            text: `${r?.staticName ?? ''} ${r.staticName && r.key ? ' - ' : ''} ${
                              r?.key ?? ''
                            }`,
                          })}
                          {...field}
                          onChange={(e, data) =>
                            field.onChange({
                              target: { value: data.value, name: field.name },
                            } as any)
                          }
                        />
                      )}
                    />
                  </Form.Field>
                )}
              </React.Fragment>
            )}
            {errors?.selectedProduct && (
              <Message negative size="tiny" content={<>{errors?.selectedProduct}</>} />
            )}
            <Form.Field>
              <SemanticField name={'notes'} label="Notes" component={Form.TextArea} />
              <ErrorMessage
                render={(msg) => <Message negative size="tiny" content={msg} />}
                name="notes"
              />
            </Form.Field>

            <Form.Field>
              <SemanticField
                name={'tags'}
                label="Tags"
                component={Form.Dropdown}
                selection
                search
                clearable
                multiple
                allowAdditions
                options={
                  values.tags && values.tags.length > 0
                    ? values.tags.map((tag: string) => ({ text: tag, value: tag, key: tag }))
                    : []
                }
                // onAddItem={handleAddition}
                noResultsMessage="Not found"
              />
            </Form.Field>

            {errorMessage && <Message error content={errorMessage} />}

            <Button primary type="submit" disabled={isSubmitting && !errorMessage}>
              Save
            </Button>
            <Button
              type="button"
              disabled={isSubmitting && !errorMessage}
              onClick={handleCloseClick}
            >
              Cancel
            </Button>
          </FormikForm>
        );
      }}
    </Formik>
  );
};
