import * as React from 'react';
import { Box, Input, Table, Thead, Tr, Td, Th, Tbody, Tfoot } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import Cleave from 'cleave.js/react';
import { currencyFormatted } from './helper';

const costRows = [
  {
    name: 'Sheeting',
    units: 'yards',
    cost: '$55.00 ',
    total: '0.0275',
  },
];

export interface MaterialCostRow {
  key: string;
  name: string;
  units: string;
  costPerUnit: number;
  totalUnits: number;
  costTotal?: number;
  // costPerPiece?: number;
}

interface IMaterialCostTableProps {
  materialCosts: MaterialCostRow[];
  quantity: number;
  onChange(value: MaterialCostRow[]): void;
}

const MaterialCostTable: React.FunctionComponent<IMaterialCostTableProps> = (props) => {
  const { materialCosts = [], quantity, onChange } = props;
  const tenant = useSelector(
    ({ globals }: { globals: { tenant: { currency: string; locale: string } } }) => globals.tenant
  );
  console.log('tenant', tenant);
  console.log('materialCosts', materialCosts);
  const total = materialCosts.reduce(
    (acc, row) => acc + (row.costPerUnit * (row.totalUnits ?? 0)) / quantity,
    0
  );
  return (
    <Table size={'sm'} mb={3}>
      <Thead>
        <Tr>
          <Th>Name</Th>
          <Th>Units</Th>
          <Th>Cost Per Unit</Th>
          <Th isNumeric>Total Units</Th>
          <Th isNumeric>Cost Each</Th>
        </Tr>
      </Thead>
      <Tbody>
        {materialCosts.map((row, index) => (
          <Tr key={index}>
            <Td>{row.name}</Td>
            <Td>{row.units}</Td>
            <Td isNumeric>
              <Box
                sx={{
                  '.form-control-cleave': {
                    width: '100%',
                    outline: '2px solid transparent',
                    pos: 'relative',
                    fontSize: 'var(--chakra-fontSizes-sm)',
                    height: 'var(--chakra-sizes-8)',
                    border: '1px solid',
                    borderColor: 'var(--chakra-colors-gray-300)',
                    background: 'inherit',
                    paddingInlineStart: 'var(--chakra-sizes-3)',
                    paddingInlineEnd: 'var(--chakra-sizes-3)',
                    borderRadius: 'var(--chakra-radii-md)',
                  },
                }}
              >
                <Cleave
                  style={{ textAlign: 'right' }}
                  className={'form-control-cleave'}
                  value={row.costPerUnit ?? 0.0}
                  onChange={(e) => {
                    const newCosts = [...materialCosts];
                    newCosts[index] = {
                      ...newCosts[index],
                      costPerUnit: Number(e.target.rawValue),
                    };
                    onChange(newCosts);
                  }}
                  options={{
                    numeral: true,
                    rawValueTrimPrefix: true,
                    numeralPositiveOnly: true,
                    numeralThousandsGroupStyle: 'thousand',
                    prefix: '$',
                  }}
                />
              </Box>
            </Td>
            <Td isNumeric>{row.totalUnits || 0.0}</Td>
            <Td isNumeric>
              {currencyFormatted(
                (row.costPerUnit * (row.totalUnits || 0)) / quantity,
                tenant?.locale,
                tenant?.currency
              )}
            </Td>
          </Tr>
        ))}
      </Tbody>
      <Tfoot>
        <Tr>
          <Th colSpan={4}>Total</Th>
          <Th isNumeric>{currencyFormatted(total || 0, tenant?.locale, tenant?.currency)}</Th>
        </Tr>
      </Tfoot>
    </Table>
  );
};

export default MaterialCostTable;
