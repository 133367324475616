// import * as React from 'react';
// import { RouteComponentProps } from 'react-router-dom'
// import { Button, Segment, Message } from 'semantic-ui-react';
// import { DetailWrapper, DetailFeaturesProps } from './../../../bootstrap/DetailWrapper';
// import { MenuForm } from './MenuForm';
// import feathers from './../../../bootstrap/feathers';
// import { PageHeaderRow, PreLoader } from '@inkcloud/components';
// import { Link } from 'react-router-dom';

// import { slugify } from 'underscore.string'

// const initialState = { microsite: null, pages: null, groups: null, data: {}, errorMessage: '' };
// type State = typeof initialState;

// const serviceName = '/store-front/microsites/content/menus'

// class Detail extends React.Component<DetailFeaturesProps, State, RouteComponentProps> {
//   state: State = { microsite: null, pages: null, groups: null, data: {}, errorMessage: '' };
//   async componentDidMount() {
//     this.loadMicrosite();
//     this.loadPages();
//     this.loadMenu();
//     this.loadGroups();
//   }

//   async loadPages() {
//     const pages = await feathers.service('/store-front/microsites/content/pages').find({
//       query: {
//         $np: 1,
//         $sort: { name: 1 },
//         $select: ['_id', 'name', 'staticName'],
//         microsite: this.props.match.params.micrositeId,
//         $populate: ['category', 'product'],
//       }
//     }) as any;
//     this.setState({
//       ...this.state,
//       pages
//       // pages: pages.map(row => {
//       //   const name = row.category ? `Category(${row?.category?.name ?? ''})` : row.product ? `Product(${row?.product?.staticName ?? ''})` : '';
//       //   return ({ value: row._id, text: `${name} - ${row.name}` });
//       // })
//     });

//   }

//   async loadGroups() {
//     try {
//       const groups = await feathers.service('/microsites/customer/groups').find({
//         query: {
//           $np: 1,
//           $sort: { name: 1 },
//           microsite: this.props.match.params.micrositeId,
//         }
//       });
//       this.setState({ ...this.state, groups: groups?.map(group => ({ value: group._id, text: group?.name })) });
//     } catch (e) {

//     }
//   }

//   async loadMicrosite() {
//     const microsite = await feathers.service('/microsites').get(this.props.match.params.micrositeId);
//     this.setState({ ...this.state, microsite });
//   }

//   async loadMenu() {
//     if (this.props.match.params.id) {

//       const res = await feathers.service(serviceName).get(this.props.match.params.id);
//       this.setState({ ...this.state, data: res });

//     }

//   }

//   handleSubmit = async (values) => {
//     values = {
//       ...values,
//       key: values?.key?.replace(/-+$/, '') // Trim (-) hyphen from end of text
//     }

//     try {
//       this.setState({ ...this.state, errorMessage: '' });

//       this.props.match.params.id ?
//         await feathers.service(serviceName).patch(this.props.match.params.id, values, { query: {} }) : await feathers.service(serviceName).create(values, { query: {} });
//       this.props.history.push(`/settings/microsites/${this.props.match.params.micrositeId}/content/menus`);
//     } catch (e) {
//       this.setState({ ...this.state, errorMessage: e.code && e.code < 500 ? e.message : 'We are experiencing technical difficulties. Please try again' });
//     }
//   }

//   render() {

//     // const { data } = this.props;
//     if (!this.state.microsite || (this.props.match.params.id && !this.state.data || this.state.pages === null)) {
//       return <PreLoader />
//     }

//     const initialData = { ...this.state.data, microsite: this.props.match.params.micrositeId };
//     const pageTitle = this.props.match.params.id ? 'Edit' : 'New';

//     return (
//       <div>
//         <PageHeaderRow header={`${pageTitle} Menu`} subheader='Microsite' subheaderId={this.state.microsite.humanId}>
//           <Link to={`/settings/microsites/${this.props.match.params.micrositeId}/content/menus`}><Button content="Back" size={'mini'} /></Link>
//         </PageHeaderRow>
//         <Segment>
//           <MenuForm
//             pages={this.state.pages}
//             microsite={this.props.match.params.micrositeId}
//             groups={this.state.groups}
//             initialValues={initialData}
//             onSubmit={this.handleSubmit}
//             errorMessage={this.state.errorMessage}
//           />
//           {
//             this.state.errorMessage && <Message negative content={this.state.errorMessage} />
//           }
//         </Segment>
//       </div>
//     );
//   }
// }

// export default Detail

// // export default DetailWrapper(Detail, 'micrositeContentMenus', {
// //   submitRedirect: (props) => `/settings/microsites/${props.match.params.micrositeId}/content/menus`,
// // });

import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Box, Button } from '@chakra-ui/react';
import { PageHeaderRow, PreLoader, useFeathers } from '@inkcloud/components';
import type { ModelTypes } from '@inkcloud/icapi-types';

import { MenuForm } from './MenuForm';

import feathers from '../../../bootstrap/feathers';

const serviceName = '/store-front/microsites/content/menus';
const service = feathers.service(serviceName);
const commonParams = {};

export default function Detail(props: RouteComponentProps<{ micrositeId: string; id: string }>) {
  const {
    match: {
      params: { micrositeId, id },
    },
    history,
  } = props;

  const [pages, setPages] = useState<ModelTypes.ContentPage[]>([]);
  const [groups, setGroups] = useState<ModelTypes.MicrositeCustomerGroups[]>([]);

  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const isEdit = !!id;

  const [state, reload] = useFeathers('get', serviceName, [id, commonParams], true);

  useEffect(() => {
    const loadPages = async () => {
      try {
        const res = (await feathers.service('/store-front/microsites/content/pages').find({
          query: {
            $np: 1,
            $sort: { name: 1 },
            $select: ['_id', 'name', 'staticName'],
            microsite: micrositeId,
            $populate: ['category', 'product'],
          },
        })) as any;

        setPages(res?.map((r) => ({ label: r?.name, value: r?._id, product: r?.product })));
      } catch (e) {
        console.error(e);
      }
    };

    const loadGroups = async () => {
      try {
        const res = await feathers.service('/microsites/customer/groups').find({
          query: {
            $np: 1,
            $sort: { name: 1 },
            microsite: micrositeId,
          },
        });

        setGroups(res?.map((r) => ({ label: r?.name, value: r?._id })));
      } catch (e) {
        console.error(e);
      }
    };

    loadPages();
    loadGroups();
  }, []);

  const handleSubmit = async (values) => {
    setErrorMessage('');
    setIsSubmitting(true);

    try {
      const payload = {
        ...values,
        key: values?.key?.replace(/-+$/, ''),
        menuItems: values?.menuItems?.map((m) => ({
          ...m,
          key: m.label,
          url: (!m?.page && m?.url) || '',
          page: m?.page || null,
          htmlTarget: m?.htmlTarget === '_blank' ? '_blank' : '_self',
          visibleToGroups: m?.visibleToGroups?.map((g) => (typeof g === 'object' ? g.value : g)),
          menuItems: m.menuItems?.map((sub) => ({
            ...sub,
            key: sub.label,
            htmlTarget: sub?.htmlTarget === '_blank' ? '_blank' : '_self',
            url: (!sub?.page && sub?.url) || '',
            page: sub?.page || null,
            visibleToGroups: sub?.visibleToGroups?.map((g) =>
              typeof g === 'object' ? g.value : g
            ),
          })),
        })),
      };

      if (isEdit) {
        await feathers.service(serviceName).patch(id, payload, { query: {} });
      } else {
        await feathers
          .service(serviceName)
          .create({ ...payload, microsite: micrositeId }, { query: {} });
      }

      history.push(`/settings/microsites/${micrositeId}/content/menus`);
    } catch (e) {
      const errMsg =
        e?.code < 500 ? e?.message : 'We are experiencing technical difficulties. Please try again';
      setErrorMessage(errMsg);
    }

    setIsSubmitting(false);
  };

  if (isEdit && !state.data) {
    return <PreLoader />;
  }

  return (
    <Box>
      <PageHeaderRow
        header={`${isEdit ? 'Edit' : 'New'} Menu`}
        subheader="Microsite"
        subheaderId={''}
      >
        <Link to={`/settings/microsites/${micrositeId}/content/menus`}>
          <Button variant="solid" size="sm">
            Back
          </Button>
        </Link>
      </PageHeaderRow>

      <Box mt={3}>
        <MenuForm
          initialValues={state.data}
          microsite={micrositeId}
          onSubmit={handleSubmit}
          errorMessage={errorMessage}
          isSubmitting={isSubmitting}
          pages={pages}
          groups={groups}
        />
      </Box>
    </Box>
  );
}
