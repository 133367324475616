import * as React from 'react';
import { Switch, Route, RouteComponentProps } from 'react-router-dom';

import List from './List';
import { Detail } from './Detail';

import Editable from './editable';

import feathers from '../../../../bootstrap/feathers';

const { useEffect, useState } = React;

const routes: React.FunctionComponent<RouteComponentProps> = (props) => {
  const { match } = props;
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);

  // useEffect(() => {
  //   const loadProducts = async () => {
  //     const products = await feathers.service('products-legacy').find({
  //       query: {
  //         $np: 1, $sort: { staticName: 1 },
  //         $select: ['staticName', 'key'],
  //       }
  //     }) as any[];

  //     const filteredProd = products.reduce((filtered, prod) => {
  //       if (prod?.staticName || prod?.key) {
  //         // let separator = ""
  //         // if (prod.key && prod.staticName) {
  //         //   separator = " - "
  //         // }
  //         // filtered.push({ value: prod._id, text: `${prod.staticName ? prod.staticName : ''} ${separator} ${prod.key ? prod.key : ''}` })
  //         filtered.push(prod)
  //       }
  //       return filtered
  //     }, [])

  //     setProducts(filteredProd)
  //   }

  //   const loadCategories = async () => {
  //     const categories = await feathers.service('products-legacy/categories').find({
  //       query: {
  //         $np: 1, $sort: { name: 1 },
  //         $select: ['name', 'hrCode'],
  //       }
  //     }) as any[];
  //     // setCategories(categories.map(c => ({ value: c._id, text: c.name })));
  //     setCategories(categories);
  //   }

  //   loadCategories();
  //   loadProducts();
  // }, [])

  return (
    <Switch>
      <Route
        exact
        path="/settings/content-management/pages"
        render={(componentProps) => <List {...componentProps} />}
      />
      <Route
        exact
        path="/settings/content-management/pages/add"
        render={(componentProps) => (
          <Detail {...componentProps} products={products} categories={categories} />
        )}
      />
      <Route
        exact
        path="/settings/content-management/pages/:id"
        render={(componentProps) => (
          <Detail {...componentProps} products={products} categories={categories} />
        )}
      />
      <Route exact path="/settings/content-management/pages/editable/:id" component={Editable} />
    </Switch>
  );
};
export default routes;
