import * as React from 'react';
import { Switch, Route } from 'react-router-dom';

import { List } from './List';

const routes = (props) => (
  <Switch>
    <Route exact path="/products/inventory/locations" component={List} />
    {/* <Route exact path='/products/attributes/add' component={Detail} />
    <Route exact path='/products/attributes/:id' component={Detail} /> */}
  </Switch>
);

export default routes;
